import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  DateStart: { label: 'Формування файлу, за період з', name: 'Дата1' },
  DateFin: { label: 'по', name: 'Дата2' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп.вищ. рівня', name: 'ФинансовыйОрган' },
  PathFolder: { label: 'Папка', name: 'ВыбПуть' },
  f866: { label: 'Кодова сторінка 866', name: 'фл866' },
  TZ: { label: 'ТЗ', name: 'ТЗ' },
  FileCode: { label: 'Розширення файлу', name: 'КодФайлСрцвыплаты' },
  FileName: { label: 'Назва файлу', name: 'имяФайла' },
  LongKBP: { label: 'Код КБП у 200 символів', name: 'ДлинныйКБП' },
  SignVariant: { label: 'Варіант підпису (для відправки в ЛОГІКА)', name: 'Подпись' },
  Boss: { label: 'Руководитель', name: 'Руководитель' },
  Buh: { label: 'Финансист', name: 'Финансист' },
  TypeRep: { label: 'Тип звітності', name: 'Отчетность' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
};

/**
 * @const
 */
const definition = {
  name: 'exportSocialPayment',
  backendName: 'ЭкспортСоцВыплатНовый',
  frontend: 'dp/exportSocialPayment',
  label: 'Формування файлу з даними за соціальними виплатами',
  columns,
};

export default definition;
