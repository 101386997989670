import allocationDetail from './allocation_detail';
import allocationYear from './allocation_year';
import allocationExtract from './allocation_extract';
import analyzeIndicatorsSF from './analyzeIndicatorsSF';
import assigmentplaning from './assigmentPlaning';
import commoninStitutionStructureBfn from './bfnCommoninStitutionstructure';
// import commonRepPanel from '../reportsPanel';
import compareOutcomes from './compareOutcomes';
import estimate from './estimate';
import extendedFinrequestsAnalisys from './extendedFinrequestsAnalisys';
import finanalisys from './finanalisys';
import financingCard from './financing_card';
import findocanalisys from './findocanalisys';
import finrequstsAnalizys from './finrequsts_analizys';
import planFactOutcomes from './planFactOutcomes';
import reduceIndicatorsSF from './reduceIndicatorsSF';
import summaryOffer from './summary_offer';
import treasuryFileAnalyze from './treasuryFileAnalyze';
import ussagePlan from './ussage_plan';

const rep = {
  allocationDetail,
  allocationYear,
  allocationExtract,
  analyzeIndicatorsSF,
  assigmentplaning,
  commoninStitutionStructureBfn,
  // commonRepPanel,
  compareOutcomes,
  estimate,
  extendedFinrequestsAnalisys,
  finanalisys,
  financingCard,
  findocanalisys,
  finrequstsAnalizys,
  planFactOutcomes,
  reduceIndicatorsSF,
  summaryOffer,
  treasuryFileAnalyze,
  ussagePlan,
};

export default rep;
