import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  classifier: {
    label: 'Классифікатор',
    name: 'Классификатор',
    visible: true,
  },
  allOthers: { label: 'Інші надходження', name: 'ВсеДругие', visible: true },
  owner: {
    label: 'Бюджет',
    name: 'Владелец',
    visible: true,
  },
};

const tables = {
  fulKEKV: {
    name: 'ПолныеКЕКВ',
    label: 'Повні КЕКВ',
    columns: {
      kekvClassifier: { label: 'Класифікатор', name: 'Классификатор' },
    },
  },
};

export const kekvClassifierForPAShort = {
  label: 'Класифікатор КЕКВ. Скорочений (для плану використання)',
  name: 'kekvClassifierForPAShort',
  backendName: 'КлассификаторКЕКВКраткийДляПА',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/kekvClassifierForPAShort',
  listColumns: [...(new Set([...reqListerColumns, 'owner', 'classifier', 'allOthers']))],
  tables,
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kekvClassifierForPAShort;
