import React, { memo } from 'react';

import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf0appr'} highlighted={highlights.includes('zf0appr')}>
            {totals.zf0appr.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf0'} highlighted={highlights.includes('zf0')}>
            {totals.zf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf0div'} highlighted={highlights.includes('zf0div')}>
            {totals.zf0div.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf1appr'} highlighted={highlights.includes('zf1appr')}>
            {totals.zf1appr.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf1'} highlighted={highlights.includes('zf1')}>
            {totals.zf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf1div'} highlighted={highlights.includes('zf1div')}>
            {totals.zf1div.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf0appr'} highlighted={highlights.includes('sf0appr')}>
            {totals.sf0appr.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf0'} highlighted={highlights.includes('sf0')}>
            {totals.sf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf0div'} highlighted={highlights.includes('sf0div')}>
            {totals.sf0div.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf1appr'} highlighted={highlights.includes('sf1appr')}>
            {totals.sf1appr.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf1'} highlighted={highlights.includes('sf1')}>
            {totals.sf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf1div'} highlighted={highlights.includes('sf1div')}>
            {totals.sf1div.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  totals: PropTypes.shape({
    sum0appr: PropTypes.number,
    zf0appr: PropTypes.number,
    zf0: PropTypes.number,
    zf1: PropTypes.number,
    sf0appr: PropTypes.number,
    sum0div: PropTypes.number,
    zf0div: PropTypes.number,
    sf0: PropTypes.number,
    sf1: PropTypes.number,
    sf0div: PropTypes.number,
    sum1appr: PropTypes.number,
    zf1appr: PropTypes.number,
    sum0: PropTypes.number,
    sum1: PropTypes.number,
    sf1appr: PropTypes.number,

    sum1div: PropTypes.number,
    zf1div: PropTypes.number,
    sf1div: PropTypes.number,

  }).isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
