import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, tooltipFormatter } from '../../../../components/blanks/helpers/utils';
import useDiagramShower from '../diagramHook';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
]);

const colorMonths = [
  '#f9848b',
  '#ffc857',
  '#a9e4a0',
  '#56bedb',
  '#51d887',
  '#4dc0d9',
  '#87ceff',
  '#d1e7ff',
  '#ffc857',
  '#f9848b',
  '#a9e4a0',
  '#56bedb',
];

function ChartOptions({ data }) {
  const seriesData = Object.values(data).filter((item) => item.repr).map((el) => ({
    name: el.repr,
    type: 'bar',
    stack: 'total',
    label: {
      show: true,
      color: '#fff',
      formatter: (params) => params.value.toLocaleString('uk', moneysOneDigitStingOptions),
    },
    emphasis: {
      focus: 'series',
    },
    data: [el.fact, el.plan],
  }));
  const yAxisData = ['Факт', 'План'];

  const getOption = () => ({
    color: colorMonths,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('primary') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter,
    },
    toolbox: {
      feature: {
        magicType: {
          type: ['stack', 'tiled'],
        },
      },
      right: 0,
    },
    legend: {
      data: seriesData.map((el) => el.name),
      textStyle: {
        color: getColor('gray-600'),
      },
      left: 0,
    },
    xAxis: {
      type: 'value',
      name: 'тис.грн.',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-500'),
      },
      splitLine: {
        lineStyle: {
          show: true,
          color: getColor('gray-200'),
        },
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      name: 'дані у тис.грн.',
      axisLine: {
        lineStyle: {
          show: true,
          color: getColor('gray-300'),
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-500'),
        formatter: (value) => value.substring(0, 4),
      },
    },
    series: seriesData,
    grid: {
      right: 15,
      left: 5,
      bottom: 5,
      top: '15%',
      containLabel: true,
    },
  });
  const { containerChartRef, show } = useDiagramShower({});

  return (
    <div ref={containerChartRef} style={{ height: '21.88rem' }}>
      {show && (
      <ReactEChartsCore
        echarts={echarts}
        option={getOption()}
      />
      )}
    </div>
  );
}

ChartOptions.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ChartOptions;
