import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={8} className="d-flex align-items-center justify-content-center">
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'code'}
          highlighted={highlights.includes('code')}
          onToggleHighlight={() => onToggleHighlight('code')}
        >
          Показник
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'start_y'}
              highlighted={highlights.includes('start_y')}
              onToggleHighlight={() => onToggleHighlight('start_y')}
            >
              {fields.start_y.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'finish_y'}
              highlighted={highlights.includes('finish_y')}
              onToggleHighlight={() => onToggleHighlight('finish_y')}
            >
              {fields.finish_y.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'middl_y_pl'}
              highlighted={highlights.includes('middl_y_pl')}
              onToggleHighlight={() => onToggleHighlight('middl_y_pl')}
            >
              {fields.middl_y_pl.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'middl_y_ex'}
              highlighted={highlights.includes('middl_y_ex')}
              onToggleHighlight={() => onToggleHighlight('middl_y_ex')}
            >
              {fields.middl_y_ex.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
