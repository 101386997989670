export const listOfTypes = [
  { id: 1, name: 'Річний', type: 'R4' },
  { id: 2, name: 'Січень', type: 'M1' },
  { id: 3, name: 'Лютий', type: 'M2' },
  { id: 4, name: '1-й квартал', type: 'K1' },
  { id: 5, name: 'Квітень', type: 'M4' },
  { id: 6, name: 'Травень', type: 'M5' },
  { id: 7, name: '2-й квартал', type: 'K2' },
  { id: 8, name: 'Липень', type: 'M7' },
  { id: 9, name: 'Серпень', type: 'M8' },
  { id: 10, name: '3-й квартал', type: 'K3' },
  { id: 11, name: 'Жовтень', type: 'M10' },
  { id: 12, name: 'Листопад', type: 'M11' },
  { id: 13, name: '4-й квартал', type: 'K4' },
];

export const dopFilters = [
  { id: 1, type: 'ROV', name: 'Розпис видатків' },
  { id: 2, type: 'RZV', name: 'Зміни до розпису видатків' },
  { id: 3, type: 'ROT', name: 'Розпис доходів' },
  { id: 4, type: 'RZT', name: 'Зміни до розпису доходів' },
  { id: 5, type: 'ROF', name: 'Розпис фінансування' },
  { id: 6, type: 'RZF', name: 'Зміни до розпису фінансування' },
  { id: 7, type: 'RON', name: 'Розпис надання кредитів' },
  { id: 8, type: 'RZN', name: 'Зміни до розпису надання кредитів' },
  { id: 9, type: 'ROP', name: 'Розпис повернення кредитів' },
  { id: 10, type: 'RZP', name: 'Зміни до розпису повернення кредитів' },
];

export const defaultEndDate = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
  const day = new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate();
  const endDate = `${year}-${month}-${day}`;
  return endDate;
};

export const defaultBeginDate = () => {
  const year = new Date().getFullYear();
  const beginDate = `${year}-01-01`;
  return beginDate;
};
