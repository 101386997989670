import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OrderedMap, Map } from 'immutable';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  changeField, DCSelectField, DCDeleteField, DCAddField, DCToggleField,
} from '../../../../actions/reportEditor';
import AvailableFields from '../AvailableFields';
import SelectionView from './selectionView';
import { fieldTypes } from '../../../../constants/meta/enums/DataComposition/fieldTypes';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import { StyledPanelsContainer } from '../StyledComponents';
import storePathParam from '../../../../common/storePathParam';
import NavButtons from '../navButtons';

const AVPATH = [
  'dataCompositionSettings',
  'SelectionAvailableFields',
];

const PATH = [
  'dataCompositionSettings',
  'Selection',
];

class Selection extends PureComponent {
  static propTypes = {
    availableFields: PropTypes.instanceOf(Map).isRequired,
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.instanceOf(OrderedMap).isRequired,
  };

  onDragEnd = ({
    destination, draggableId, source,
  }) => {
    const { availableFields, dispatch, data } = this.props;
    if (destination) {
      if (destination.droppableId === 'DC.Selection' && source.droppableId === 'DC.AvailableFields') {
        const draggableField = availableFields.getIn(['visibleItems', draggableId]);
        const addableFields = new Map().set(draggableId, draggableField);
        const parentId = data.filter((r) => r.get('Index') === destination.index).reduce((R, r, k) => k, '');
        const dropInGroup = data.getIn([parentId, 'Type']) === fieldTypes.SelectedFieldGroup;
        const addParams = {
          parentId: dropInGroup ? parentId || null : data.getIn([parentId, 'Parent'], null),
          index: destination.index + 1,
        };
        this.addFieldsToStructure(addableFields, addParams);
      } else if (destination.droppableId === 'DC.AvailableFields' && source.droppableId === 'DC.Selection') {
        dispatch(DCDeleteField(PATH, draggableId));
      }
    }
  };

  toggleSelection = (e, rowId) => {
    const { data, dispatch } = this.props;
    dispatch(changeField([...PATH, rowId, 'Use'], !data.getIn([rowId, 'Use'], false)));
  };

  fieldSelectionHandler = (e, rowId) => {
    const { dispatch } = this.props;
    e.stopPropagation();
    dispatch(DCSelectField(PATH, rowId, false));
  };

  fieldDeletionHandler = () => {
    const { dispatch } = this.props;
    dispatch(DCDeleteField(PATH));
  };

  addFieldsToStructure = (fields, addParams) => {
    const { dispatch } = this.props;
    const addableFields = fields.map((row) => new Map({
      Type: fieldTypes.SelectedField,
      Tittle: '',
      Field: row.get('Field'),
      ViewMode: DC.viewModes.QuickAccess,
    }));
    dispatch(DCAddField(PATH, addableFields, addParams.parentId, addParams.afterKey));
  };

  fieldAddHandler = () => {
    const { availableFields, data } = this.props;

    const addParams = data.reduce((R, row, key) => {
      if (row.get('_SELECTED', false)) {
        return {
          parentId: row.get('Type') === fieldTypes.SelectedFieldGroup ? key : row.get('Parent', null),
          afterKey: row.get('Type') === fieldTypes.SelectedFieldGroup ? null : key,
        };
      }
      return R;
    }, { parentId: null, afterKey: null });

    this.addFieldsToStructure(availableFields.get('visibleItems', new Map())
      .filter((row) => row.get('_SELECTED', false)), addParams);
  };

  toggleAvailabeFields = (e, field) => {
    const { dispatch } = this.props;
    dispatch(DCToggleField(AVPATH, field));
  };

  selectHandler = (e, field) => {
    const { dispatch } = this.props;
    dispatch(DCSelectField([...AVPATH, 'visibleItems'], field, e.ctrlKey));
  };

  render() {
    const { availableFields, data } = this.props;
    const leftSelected = !!availableFields.get('visibleItems', new Map()).filter((row) => row.get('_SELECTED', false)).size;
    const rightSelected = !!data.filter((row) => row.get('_SELECTED', false)).size;
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
      >
        <StyledPanelsContainer>
          <AvailableFields
            items={availableFields.get('visibleItems', new Map())}
            selectHandler={this.selectHandler}
            choiceHandler={this.fieldAddHandler}
            toggleHandler={this.toggleAvailabeFields}
          />
          <NavButtons
            canRight={leftSelected}
            canLeft={rightSelected}
            onRight={this.fieldAddHandler}
            onLeft={this.fieldDeletionHandler}
          />
          <SelectionView
            availableFields={availableFields.get('items')}
            data={data}
            selectHandler={this.fieldSelectionHandler}
            toggleUseHandler={this.toggleSelection}
            deleteHandler={this.fieldDeletionHandler}
          />
        </StyledPanelsContainer>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => {
  const { name } = storePathParam(state);
  return {
    data: state.getIn([`rep/${name}/reportEditor`, ...PATH], Map({})),
    availableFields: state.getIn([`rep/${name}/reportEditor`, ...AVPATH], Map({})),
  // values: state.getIn(['reporteditor', 'item', 'DataCompositionSettings', '_values'], Map({})),
  };
};

// const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps)(Selection);
