export function uint8array(string) {
  const byteArray = new Uint8Array(string.length * 2);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < string.length; i++) {
    byteArray[i * 2] = string.charCodeAt(i); // & 0xff;
    // eslint-disable-next-line no-bitwise
    byteArray[i * 2 + 1] = string.charCodeAt(i) >> 8; // & 0xff;
  }
  return byteArray;
  // return Buffer.from(string, 'utf16le');
}
