const frontendURL = '/doc/forecastprepareadd2/';
const backendURL = '/api/forecasts/forecastprepareadd2header/';
const name = 'Пропозиція до прогнозу місцевого бюджету (Форма ПП-1)(Додаток 2)';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
