import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/draftAnnualCredit';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly,
  highlights, onSR, FI, CSUinH, budget,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  const TargetedProgramsFIlter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.csu.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onTargetedProgramsChange = useCallback(
    (e, v) => onRowChange(e, { [tableMD.columns.tProg.name]: v }),
    [onRowChange],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        {!CSUinH
          && (
            <Row>
              <Col
                xl={3}
                sm={12}
              >
                <TableRowCell column="csu" highlighted={highlights.includes('csu')}>
                  <ItemPicker
                    value={row[tableMD.columns.csu.name]}
                    modelType="cat"
                    modelName="csu"
                    onChange={onCSUChange}
                    filter={CSUFIlter}
                    noHierarchy
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          )}
        <Row className="align-items-center">
          <Col>
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={onKFKChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KPKVCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="tProg" highlighted={highlights.includes('tProg')}>
              <ItemPicker
                value={row[tableMD.columns.tProg.name]}
                modelType="cat"
                modelName="targetedPrograms"
                onChange={onTargetedProgramsChange}
                filter={TargetedProgramsFIlter}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              <NumberInput
                value={row[tableMD.columns.sum1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum1.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.zf1.name]: v,
                  [tableMD.columns.sum1.name]: v + row[tableMD.columns.sf1.name],
                  [tableMD.columns.zf.name]: v + row[tableMD.columns.zf2.name],
                  [tableMD.columns.sum.name]: v
                  + row[tableMD.columns.sf1.name]
                  + row[tableMD.columns.sum2.name],
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sf1.name]: v,
                  [tableMD.columns.sum1.name]: v + row[tableMD.columns.zf1.name],
                  [tableMD.columns.sf.name]: v + row[tableMD.columns.sf2.name],
                  [tableMD.columns.sum.name]: v
                  + row[tableMD.columns.zf1.name]
                  + row[tableMD.columns.sum2.name],

                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="br1" highlighted={highlights.includes('br1')}>
              <NumberInput
                value={row[tableMD.columns.br1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum2.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.zf2.name]: v,
                  [tableMD.columns.sum2.name]: v + row[tableMD.columns.sf2.name],
                  [tableMD.columns.zf.name]: v + row[tableMD.columns.zf1.name],
                  [tableMD.columns.sum.name]: v
                  + row[tableMD.columns.sf2.name]
                  + row[tableMD.columns.sum1.name],
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sf2.name]: v,
                  [tableMD.columns.sum2.name]: v + row[tableMD.columns.zf2.name],
                  [tableMD.columns.sf.name]: v + row[tableMD.columns.sf1.name],
                  [tableMD.columns.sum.name]: v
                  + row[tableMD.columns.zf2.name]
                  + row[tableMD.columns.sum1.name],
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="br2" highlighted={highlights.includes('br2')}>
              <NumberInput
                value={row[tableMD.columns.br2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableRowCell column="zf" highlighted={highlights.includes('zf')}>
              <NumberInput
                value={row[tableMD.columns.zf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.zf.name]: v,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sf" highlighted={highlights.includes('sf')}>
              <NumberInput
                value={row[tableMD.columns.sf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sf.name]: v,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="br" highlighted={highlights.includes('br')}>
              <NumberInput
                value={row[tableMD.columns.br.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  CSUinH: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  budget: PropTypes.shape({
  }).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
