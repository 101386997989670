import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  maсNum: { label: 'МакетНомер', name: 'МакетНомер' },
  shKEKV: { label: 'флСокрКЕКВ', name: 'флСокрКЕКВ' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Дата', name: 'Дата' },
      col2: { label: 'Месяц', name: 'Месяц' },
      col3: { label: 'Год', name: 'Год' },
      col4: { label: 'Фонд', name: 'Фонд' },
      col5: { label: 'Напрям', name: 'Напрям' },
      col6: { label: 'Сумма', name: 'Сумма' },
      col7: { label: 'НомерТабл', name: 'НомерТабл' },
      col8: { label: 'Сумма2', name: 'Сумма2' },
      col9: { label: 'Валюта', name: 'Валюта' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'liquidityPrint',
  backendName: 'ПечатьИнформацияЛиквидности',
  label: 'Інформація щодо ліквідності',
  frontend: 'dp/liquidityPrint',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
