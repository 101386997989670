import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'ju6MrxaIZaI',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  deptIn: {
    name: 'ДолгВнутренний',
    label: 'Внутрішній борг',
    columns: {
      deptInGF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнутреннийЗФ_Отчет' },
      deptInGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнутреннийЗФ_Утверждено' },
      deptInGF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План' },
      deptInGF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План1' },
      deptInGF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План2' },
      deptInSF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнутреннийСФ_Отчет' },
      deptInSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнутреннийСФ_Утверждено' },
      deptInSF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План' },
      deptInSF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План1' },
      deptInSF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План2' },
    },
  },
  deptOut: {
    name: 'ДолгВнешний',
    label: 'Зовнішній борг',
    columns: {
      deptOutGF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнешнийЗФ_Отчет' },
      deptOutGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнешнийЗФ_Утверждено' },
      deptOutGF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План' },
      deptOutGF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План1' },
      deptOutGF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План2' },
      deptOutSF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнешнийСФ_Отчет' },
      deptOutSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнешнийСФ_Утверждено' },
      deptOutSF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План' },
      deptOutSF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План1' },
      deptOutSF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План2' },
    },
  },

};

/**
 * @const
 */
const definition = {
  label: 'Показники місцевого боргу (Додаток 4)',
  name: 'forecastAdd4',
  backendName: 'ПрогнозДод4',
  columns,
  frontend: 'doc/forecastAdd4',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
