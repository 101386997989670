import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useCtxMenu = (options, onClose) => {
  const ref = useRef();

  useEffect(
    () => {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();
        if (document.body.scrollWidth > options.left + width) {
          ref.current.style.left = `${options.left}px`;
          ref.current.style.right = null;
        } else {
          ref.current.style.right = `${document.body.scrollWidth - options.left}px`;
          ref.current.style.left = null;
        }
        if (document.body.scrollHeight > options.top + height) {
          ref.current.style.top = `${options.top}px`;
          ref.current.style.bottom = null;
        } else {
          ref.current.style.bottom = `${document.body.scrollHeight - options.top}px`;
          ref.current.style.top = null;
        }
      }
    },
    [options.left, options.top],
  );

  const onDocumentClick = useCallback(
    (e) => {
      if (options.visible && ref.current && !ref.current.contains(e.target)) onClose(e);
    },
    [onClose, options.visible],
  );

  useEffect(
    () => {
      if (options.visible) {
        document.addEventListener('click', onDocumentClick);
      }
      return () => {
        document.removeEventListener('click', onDocumentClick);
      };
    },
    [onDocumentClick, options.isVisible, options.visible],
  );
  return {
    ref,
  };
};
