import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  printReportParams: {
    label: 'Друкувати параметри звіту',
    name: 'флПечатьСодержанияФильтра',
  },
};

const definition = {
  label: 'Оборотно-сальдова відомість по казнач. рахункам',
  name: 'TurnoverSheetTreasureAccount',
  columns,
  frontend: 'rep/TurnoverSheetTreasureAccount',
  backendName: 'ОборотноСальдоваяПоКазнСчетам',
  resultColumns: baseResultColumns,
};

export default definition;
