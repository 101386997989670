import React from 'react';

function ClosedFolder() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#fffead" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 15.5V6.5H10L9 4.5H3.5V15.5C8.57682 15.5 11.4232 15.5 16.5 15.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ClosedFolder;
