import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const NewBasedGrid = styled(BasedGrid)`
  grid-template-columns: 3% 10% 10% 6% 17% 8% 11%;
`;

const generateNewGrid = () => styled(NewBasedGrid)`
      grid-template-areas:  "num       document budget KPKV fondObject  year  currentAccountFI   sum  s1 s4 s7 s10"
                            "selection document FI     KFK  elementKFK  note  currentAccountCSU  sum  s2 s5 s8 s11"
                            "selection period   CSU    KEKV elementKEKV note  signMainMovement   sum  s3 s6 s9 s12";
                            `;

class GridTemplate extends Component {
  static propTypes = {
    isHeader: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  };

  static defaultProps = {
    isHeader: false,
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = { GridTemplate: generateNewGrid(props) };
  }

  render() {
    const {
      children, isHeader,
    } = this.props;

    const Grid = this.state.GridTemplate;
    return (
      <Grid isHeader={isHeader}>
        {children}
      </Grid>
    );
  }
}

export default GridTemplate;
