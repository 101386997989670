import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment, faLock, faLockOpen,
} from '@fortawesome/free-solid-svg-icons';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd2';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';
import DocsCommandPanel from '../../../../../newEditor/commandPanels/docstp';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tableMeta = md.tables.docs;

function DocsTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const {
    tableActions,
  } = useLowerDocs({
    tableName: tableMeta.name,
    onChange: actions.onChange,
    approvedName: tableMeta.columns.appr.name,
  });

  return (
    <>
      <DocsCommandPanel tableActions={tableActions} readOnly={readOnly} />
      <>
        {tableData.map((doc, rowId) => (
          <Card key={doc[tableMeta.columns.doc.name].id}>
            <Card.Body>
              <Card.Title>
                <Link to={`/${md.frontend}/${doc[tableMeta.columns.doc.name].id}`}>
                  {doc[tableMeta.columns.doc.name].repr}
                </Link>
              </Card.Title>
              <div className="d-flex">
                <Button
                  variant={doc[tableMeta.columns.appr.name] ? 'outline-success' : 'outline-danger'}
                  onClick={(e) => tableActions.onToggleApproveDoc(e, rowId)}
                >
                  <FontAwesomeIcon icon={doc[tableMeta.columns.appr.name] ? faLock : faLockOpen} className="me-2" />
                  {doc[tableMeta.columns.appr.name] ? 'Отримано' : 'Не отримано'}
                </Button>
              </div>
              {doc[tableMeta.columns.note.name] && (
                <Card.Text>
                  <hr />
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  {doc[tableMeta.columns.note.name]}
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        ))}
      </>
    </>
  );
}

DocsTP.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({
      [tableMeta.columns.doc.name]: referencePropType,
      [tableMeta.columns.note.name]: PropTypes.string,
      [tableMeta.columns.appr.name]: PropTypes.bool,
    })),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

DocsTP.defaultProps = {
  readOnly: false,
};

export default DocsTP;
