import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import meta from '../../../../meta';
import { CatCommandPanel } from '../../../basicEditor';
import HistoricalValues from './history';
import useEditorParams from '../../../newEditor/hook/params';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';

const BACKENDURL = meta.cat.genderage.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { title, subtitle } = useMemo(
    () => {
      if (!data || !data.history) return { title: '', subtitle: '' };
      return {
        title: data.history.code,
        subtitle: data.history.name,
      };
    },
    [data],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={title}
      subtitle={subtitle}
    >
      <HistoricalValues
        id={id}
        data={data}
        actions={actions}
        fields={fields}
        errors={fieldErrors}
      />

    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
