import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  varProg: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  repCSU: { label: 'Розп. нищ. рівня/отримувач', name: 'Распорядитель' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  yearPlan: { label: 'Рік проекту', name: 'ГодЗатрат' },
  inKfk: { label: 'За КБП (КБПМБ)', name: 'ПоказыватьПоКФК' },
  startDate: { label: 'Враховувати дані з', name: 'ДатаНач' },
  finDate: { label: 'по', name: 'ДатаКон' },
  in1000: { label: 'В тыс грн', name: 'ВТысГрн' },
  inCSU: { label: 'За виконавцями', name: 'ПоИсполнителям' },
  inPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  varReport: { label: 'Вариант отчета', name: 'ВариантОтчета' },
  repCSU2: { label: 'Розп. нищ. рівня/отр.', name: 'фРаспорядитель' },
  platAproved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  kfk: { label: 'КФК', name: 'фКФК' },
  elKfk: { label: 'За елементами КПКВ', name: 'фЭлементКФК' },
  targetProg: { label: 'Цільова програма', name: 'фЦелеваяПрограмма' },
  repeatEveryPage: { label: 'Повторювати шапку на кожній сторінці', name: 'ПовторятьПриПечатиШапку' },
  inKbp: { label: 'По КПБ', name: 'ПоКПБ' },
  inPlan: { label: 'формувати за планами асигнувань та змінами до них', name: 'флПоПланам' },
  inGroup: { label: 'Довільне групування', name: 'ВидДопСвойства' },
  inAnyGroup: { label: 'За довільним групування', name: 'флДопСвойства' },
  sessiondesigion: { label: 'Рішення сесії для відправки в  ЛОГІКА', name: 'РешениеСессии' },
  copecks: { label: 'Без десяткових знаків (копійок)', name: 'БезКопеек' },
};

const tables = {
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Використання', name: 'Использование' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      ElGroup: { label: 'Элемент группа', name: 'ЭлементГруппа' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 7 (Місцеві/регіональні програми)',
  name: 'SessionAdd7',
  columns,
  tables,
  frontend: 'rep/SessionAdd7',
  backendName: 'РешениеСессии07',
  resultColumns: baseResultColumns,
};

export default definition;
