import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноза', visible: true },
  csu: { label: 'Роспорядник', name: 'Распорядитель', visible: false },
  csuA: { label: 'Виконавець', name: 'РаспорядительИ', visible: false },
  noteZF: { label: 'Підстави та обґрунтування пропозицій щодо додаткових коштів загального фонду', name: 'ДоходыЗФ_Основание', visible: false },
  noteSF: { label: 'Підстави та обґрунтування пропозицій щодо додаткових коштів спеціального фонду', name: 'ДоходыСФ_Основание', visible: false },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  usePCMCatalog: {
    label: 'Використовувати довідник показників з  ПЦМ', name: 'ИспользоватьСправочникиПЦМ', visible: true, type: 'bool',
  },
  isGet: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  inFill: {
    label: 'Доповнити існуючі дані даними з завантажених документів', name: 'Дописать', visible: true, type: 'bool',
  },
};

/**
 * @const
 */
const tables = {
  tar: {
    name: 'ЦелиГосПолитики',
    label: 'Зміна показників досягнення цілей',
    columns: {
      tar: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      name: { label: 'Од вим', name: 'ЕдИзм' },
      task: { label: 'Напрямок', name: 'Задание' },
      indicator: { label: 'Показник', name: 'Показатели' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
    },
  },
  incomes: {
    name: 'Доходы',
    label: 'Додаткові видатки/надання кредитів',
    columns: {
      elementKPKV: { label: 'Елемент КПКВ', name: 'ЕлементКПКВ' },
      kekv: { label: 'КЕКВ', name: 'КЕКВ' },
      KPKVCode: { label: 'Код КПКВ', name: 'КДБКод' },
      kekvCode: { label: 'Код КЕКВ', name: 'КЕКВКод' },
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
      incomesGF_Add: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Дополнительно' },
      incomesGF_Add1: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Дополнительно1' },
      incomesGF_Add2: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Дополнительно2' },
      incomesSF_Add: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Дополнительно' },
      incomesSF_Add1: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Дополнительно1' },
      incomesSF_Add2: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Дополнительно2' },
      incomesSF_noteZF: { label: 'Підстави та обґрунтування пропозицій щодо додаткових коштів загального фонду', name: 'ДоходыЗФ_Основание' },
      incomesSF_noteSF: { label: 'Підстави та обґрунтування пропозицій щодо додаткових коштів спеціального фонду', name: 'ДоходыСФ_Основание' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Пропозиція до прогнозу місцевого бюджету (Форма БП-3) (Додаток 4)',
  name: 'prepareForecastAdd4',
  backendName: 'ПрогнозДодП4',
  columns,
  frontend: 'doc/prepareForecastAdd4',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'appr',
      'year',
      'isGet',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
