export const typeMessageRecipients = {
  all: {
    label: 'Всім',
    name: 'Всем',
  },
  active: {
    label: 'Активним',
    name: 'Активным',
  },
  inlist: {
    label: 'За списком',
    name: 'ПоСписку',
  },
};
