import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { FinProposalRows, FinRequestsRows, PlanningRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
// import LoadRequestProposalButton from '../../common/loadRequestsProposalDocs';
import LoadRequestProposalButton from './load';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { PrependAccount, PrependFond, PrependKVK } from '../../styles/klassifiers';
import useEditorParams from '../../../newEditor/hook/params';
import ApprovedInfo from '../../common/approvedInfo';
import { WinManagerContext } from '../../../../providers';
import ExportFinancialsToDBFButton from '../../../services/ExportFinancialsToDBF/modalButton';
import { TYPE_VALUES } from '../../../instances/individual/cat/treasureaccount/consts';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.finorder.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { setWindowsHint } = useContext(WinManagerContext);

  useEffect(
    () => {
      const header = (
        <p>
          <strong> Сума: </strong>
          {data?.amount?.toLocaleString('uk')}
          {' '}
          <br />
          <strong> Розпорядження №:  </strong>
          {data?.number}
          {data?.invoice && (
            <>
              <strong> Статус: </strong>
              {data?.invoice}
            </>
          )}
        </p>
      );

      const body = (
        <p>

          <strong> Рахунок: </strong>
          {data?.authority_acc?.repr}
          <strong> , №: </strong>
          {data?.authority_acc?.code}
          <br />
          {data?.description}
          <br />
          <strong> Фонд: </strong>
          {data?.fond?.repr}
          <br />
          <strong> Створено: </strong>
          {data?.created_at ? data.created_at.slice(0, 10) : ''}
          <br />
          <strong> Змінено: </strong>
          {data?.updated_at ? data.updated_at.slice(0, 10) : ''}
          <br />
          <strong> Автор: </strong>
          {data?.author?.repr}
          {' '}
          <br />
          {data?.comment ? `Коментар: ${data.comment}` : ''}
        </p>
      );
      setWindowsHint(`${meta.doc.finorder.frontendURL}${id}/`, { header, body });
    },
    [
      data?.amount, data?.author?.repr, data?.authority_acc?.code,
      data?.authority_acc?.repr, data.comment, data?.created_at,
      data?.description, data?.fond?.repr, data.invoice, data?.number,
      data?.updated_at, data.year, id, setWindowsHint,
    ],
  );

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const authorityAccProps = editorHooks.useItemInputProps('authority_acc', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const commentProps = editorHooks.useTextInputProps('comment', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const importAllNotesProps = editorHooks.useCheckboxInputProps('import_all_notes', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isRefundProps = editorHooks.useCheckboxInputProps('is_refund', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isRefundByMonthProps = editorHooks.useCheckboxInputProps('is_refund_by_month', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const monthRefundProps = editorHooks.useSelectorInputProps('month_refund', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isTransferCfSfProps = editorHooks.useCheckboxInputProps('is_transfer_cfsf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const numberTransferCfsfProps = editorHooks.useStringInputProps('number_transfer_cfsf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const acc3142Props = editorHooks.useItemInputProps('acc3142', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const acc3261Props = editorHooks.useItemInputProps('acc3261', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const acc3262Props = editorHooks.useItemInputProps('acc3262', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const accCostProps = editorHooks.useItemInputProps('acc_cost', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps(
    'disposer',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  const authorityAccFilter = useMemo(
    () => ({
      disposer: data.authority ? data.authority.id : null,
      fond: data.fond ? data.fond.id : null,
    }),
    [data.authority, data.fond],
  );

  const useFinProposal = !!data.use_finproposal;

  // const overrideFilter = useMemo(
  //   () => ({
  //     authority: data.authority,
  //     fond: data.fond,
  //     year: data.year,
  //     planKind: data.plan_kind,
  //     ...(data.authority_acc ? { authority_acc: data.authority_acc } : {}),
  //   }),
  //   [data],
  // );
  const overrideFilter = useMemo(
    () => [
      ['authority', '=', data.authority, true],
      ['fond', '=', data.fond, true],
      ['year', '=', data.year, true],
      ['plan_kind', '=', data.plan_kind, true],
      ...(data.authority_acc ? [['authority_acc', '=', data.authority_acc, true]] : []),
    ],
    [data],
  );
  const accKind = useMemo(
    () => {
      if (data && data.authority && data.authority.is_FO) return TYPE_VALUES.CUMULATIVE.value;
      return TYPE_VALUES.SPECIAL.value;
    },
    [data],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          id={id}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.finorder.backendURL}
          repr={data.repr}
        >
          <ExportFinancialsToDBFButton
            doc={[id]}
            backendURL={meta.doc.finorder.backendURL}
            disabled={isNew || changed}
          />
        </DocCommandPanel>
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                  )}
                />
              </Col>
              {data.disposer_in_header && (
              <Col>
                <EditorControls.ItemPicker
                  {...disposerProps}
                  required
                  noHierarchy
                  filter={{ parent: data.authority.id }}
                  prepend={(
                    <PrependKVK>
                      {data.kvk && data.kvk.repr}
                    </PrependKVK>
                    )}
                />
              </Col>
              )}
              <Col>
                <EditorControls.ItemPicker
                  {...authorityAccProps}
                  filter={authorityAccFilter}
                  disabled={!data.fond}
                  description={!data.fond ? 'Спочатку треба заповнити поле "Елемент фонду"' : null}
                  defaultAccKind={accKind}
                  prepend={(
                    <PrependAccount>
                      {data.authority_acc && data.authority_acc.code}
                    </PrependAccount>
                  )}
                />
              </Col>
              <Col>
                <LoadRequestProposalButton
                  disabled={!permissions.canChange || !data.fond}
                  overrideFilter={overrideFilter}
                  docDate={data.doc_date}
                  useFinProposal={useFinProposal}
                  onLoadHandler={(rows, clear) => actions.onDraft(data, {
                    load_finrequest_row: {
                      rows,
                      clear,
                    },
                  })}
                />
              </Col>
            </Row>
            <Row>
              {!data.is_transfer_cfsf && (
              <>
                <Col>
                  <EditorControls.CheckboxInput
                    {...isRefundProps}
                    disabled={data.is_refund_by_month}
                  />
                </Col>
                {data.is_refund && (
                <Col>
                  <EditorControls.SelectorInput {...monthRefundProps} required />
                </Col>
                )}
                {!data.is_refund && (
                <Col>
                  <EditorControls.CheckboxInput {...isRefundByMonthProps} />
                </Col>
                )}
              </>
              )}

              <Col>
                <EditorControls.CheckboxInput {...isTransferCfSfProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...importAllNotesProps} />
              </Col>
              {data.is_transfer_cfsf && (
              <Col sm={2}>
                <EditorControls.StringInput {...numberTransferCfsfProps} />
              </Col>
              )}
              {data.is_transfer_cfsf && (
              <Col>
                <EditorControls.ItemPicker
                  {...acc3142Props}
                  filter={authorityAccFilter}
                  prepend={(
                    <PrependAccount>
                      {data.acc3142 && data.acc3142.code}
                    </PrependAccount>
                  )}
                />
              </Col>
              )}
              {data.is_transfer_cfsf && (
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    {...acc3261Props}
                    filter={authorityAccFilter}
                    prepend={(
                      <PrependAccount>
                        {data.acc3261 && data.acc3261.code}
                      </PrependAccount>
                  )}
                  />
                </Col>
                <Col>
                  <EditorControls.ItemPicker
                    {...acc3262Props}
                    filter={authorityAccFilter}
                    prepend={(
                      <PrependAccount>
                        {data.acc3262 && data.acc3262.code}
                      </PrependAccount>
                  )}
                  />
                </Col>
                <Col>
                  <EditorControls.ItemPicker
                    {...accCostProps}
                    filter={authorityAccFilter}
                    prepend={(
                      <PrependAccount>
                        {data.acc_cost && data.acc_cost.code}
                      </PrependAccount>
                  )}
                  />
                </Col>
              </Row>
              )}
              <ApprovedInfo
                approved={data.is_financed}
                approvedText={data.invoice}
                type="financed"
                url={`${meta.doc.finorder.backendURL}${data.id}/`}
                position={2}
              />
            </Row>

          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="finorder">
          {fields && fields.finorderrow_set && (
          <Tab eventKey="finorder" title={fields.finorderrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
                readOnly={!permissions.canChange}
                isRefundByMonth={data.is_refund_by_month}
                isRefund={data.is_refund}
                isTransferCfSf={data.is_transfer_cfsf}
                isTransfer={data.is_transfer}
                useFinProposal={useFinProposal}
              />
            </TabContainer>
          </Tab>
          )}
          {fields && fields.finorderrequest_set && !useFinProposal && (
            <Tab eventKey="#" title={fields.finorderrequest_set.label}>
              <TabContainer>
                <FinRequestsRows
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.finorderproposal_set && useFinProposal && (
            <Tab eventKey="#" title={fields.finorderproposal_set.label}>
              <TabContainer>
                <FinProposalRows
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        readOnly={!permissions.canChange}
        onChange={actions.onChange}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
