import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: {
    label: 'Назва',
    name: 'Наименование',
    visible: true,
  },
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'XVVK-Ad8fjk',
  },
  owner: { name: 'Владелец', label: 'Бюджет', visible: false },
  budget: { name: 'Бюджет', label: 'Бюджет', visible: true },
  FI: { name: 'ФинансовыйОрган', label: 'Фінансовий орган', visible: true },
  PY: { name: 'PY', label: 'на' },
  KLB: { name: 'KLB', label: '(код бюджету)' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Це проект рішення', name: 'Прогноз' },
  ADD1: { name: 'ADD1', label: 'Додаток 1' },
  ADD2: { name: 'ADD2', label: 'Додаток 2' },
  ADD3: { name: 'ADD3', label: 'Додаток 3' },
  ADD4: { name: 'ADD4', label: 'Додаток 4' },
  ADD5: { name: 'ADD5', label: 'Додаток 5' },
  ADD6: { name: 'ADD6', label: 'Додаток 6' },
  ADD7: { name: 'ADD7', label: 'Додаток 7' },
  ADD8: { name: 'ADD8', label: 'Додаток 8' },
  ADD9: { name: 'ADD9', label: 'Додаток 9' },
  ADD10: { name: 'ADD10', label: 'Додаток 10' },
  ADD11: { name: 'ADD11', label: 'Додаток 11' },
  ADD12: { name: 'ADD12', label: 'Додаток 12' },
  docId: { label: 'id документа', name: 'id' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
};

export const planing = {
  label: 'Прогноз (з додатками)',
  name: 'planing',
  backendName: 'ПрогнозТекст',
  defaultOrder,
  columns,
  frontend: 'cat/planing',
  listColumns: [...(new Set([...reqListerColumns, 'owner']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default planing;
