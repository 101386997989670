import ListerFactory from '../../../newLister';
import { SCHEMAS_API_URL } from '../../../basicReport/hooks/consts';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { CatListerCommandPanel } from '../../../newLister/tableComponent/commandpanel';

export const BACKEND_URL = SCHEMAS_API_URL;

const params = {
  backendURL: BACKEND_URL,
  viewType: 'selector',
};

const SchemaSelector = ListerFactory(params)(CatTableListerFactory({
  CommandPanel: CatListerCommandPanel,
}));

export default SchemaSelector;
