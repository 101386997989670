import React, { forwardRef, useContext } from 'react';
import { ButtonToolbar, Navbar } from 'react-bootstrap';
import ListerContext from '../../../newLister/context';
import { ShowDeletedToggler } from '../../../newLister/tableComponent/commandpanel/buttons';
import { SearchInput } from '../../../newLister/tableComponent/commandpanel/elements';

const ElementTaskCommandPanel = forwardRef((_, ref) => {
  const {
    actions, permissions, showDeleted, searchString,
  } = useContext(ListerContext);

  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>
    </Navbar>
  );
});

export default ElementTaskCommandPanel;
