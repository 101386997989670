import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/articlesDebtSocialPayments';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'kfk';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="kbpClassifier" highlighted={highlights.includes('kbpClassifier')}>
          <ItemPicker
            value={row[tableMD.columns.kbpClassifier.name]}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.kbpClassifier.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.kbpClassifier.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
