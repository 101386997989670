import styled from 'styled-components';
import React from 'react';

import { ArrowIcon } from '../../assets/icons';

export const DashButton = styled.button`
      background: linear-gradient(180deg, #E5EDF7 0%, #BDD3EC 100%);
      padding: 4px 4px 2px 4px;
      border-radius: 4px;
      box-sizing: border-box;
      outline: none;
      border: none;
      cursor: pointer;
      margin-right: 12px;
      &:hover{
      background: #bdd3ec;
      };
`;
export function HideDashboard(props) {
  return (
    <DashButton {...props}>
      <ArrowIcon />
    </DashButton>
  );
}
