import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes3';

const meta = md.tables.TABL_42;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'code'}
            highlighted={highlights.includes('code')}
            onToggleHighlght={() => onToggleHighlght('code')}
          >
            {meta.columns.code.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'codeDod'}
            highlighted={highlights.includes('codeDod')}
            onToggleHighlght={() => onToggleHighlght('codeDod')}
          >
            {meta.columns.codeDod.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'name'}
            highlighted={highlights.includes('name')}
            onToggleHighlght={() => onToggleHighlght('name')}
          >
            {meta.columns.name.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesLegal1'}
            highlighted={highlights.includes('taxesLegal1')}
            onToggleHighlght={() => onToggleHighlght('taxesLegal1')}
          >
            {meta.columns.taxesLegal1.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesNatural1'}
            highlighted={highlights.includes('taxesNatural1')}
            onToggleHighlght={() => onToggleHighlght('taxesNatural1')}
          >
            {meta.columns.taxesNatural1.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesLegal2'}
            highlighted={highlights.includes('taxesLegal2')}
            onToggleHighlght={() => onToggleHighlght('taxesLegal2')}
          >
            {meta.columns.taxesLegal2.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesNatural2'}
            highlighted={highlights.includes('taxesNatural2')}
            onToggleHighlght={() => onToggleHighlght('taxesNatural2')}
          >
            {meta.columns.taxesNatural2.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesLegal3'}
            highlighted={highlights.includes('taxesLegal3')}
            onToggleHighlght={() => onToggleHighlght('taxesLegal3')}
          >
            {meta.columns.taxesLegal3.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesNatural3'}
            highlighted={highlights.includes('taxesNatural3')}
            onToggleHighlght={() => onToggleHighlght('taxesNatural3')}
          >
            {meta.columns.taxesNatural3.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesLegal4'}
            highlighted={highlights.includes('taxesLegal4')}
            onToggleHighlght={() => onToggleHighlght('taxesLegal4')}
          >
            {meta.columns.taxesLegal4.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'taxesNatural4'}
            highlighted={highlights.includes('taxesNatural4')}
            onToggleHighlght={() => onToggleHighlght('taxesNatural4')}
          >
            {meta.columns.taxesNatural4.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
