import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Nav, Button, Card, Modal, Badge, Alert,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/registrationForm';
import { useEditor, useInitParams } from '../../../newEditor';
import { modelName, modelType } from '../def';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import RegistrationForm from './editor';
import Logica from '../../../authentication';
import PrintModal from '../printModal';
import { logikaLogout } from '../../../../actions/auth';

const selector = (state) => ({
  ticket: state.getIn(['auth', 'logikaTicket'], null),
});

function RegistrationFormEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason, copyFrom, defaults,
  } = useInitParams(search);
  const {
    data, loading, err, changed, permissions, actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  const { ticket } = useSelector(selector);
  const [logikaLoginOpened, setLogikaLoginOpened] = useState(false);
  const dispatch = useDispatch();
  useEffect(
    () => {
      const r1 = data[md.columns.response1.name];
      const r2 = data[md.columns.response2.name];
      if (typeof (r1) === 'string' && typeof (r2) === 'string' && r1.length > 100 && r2.length > 100) {
        dispatch(logikaLogout());
      }
    },
    [data, dispatch],
  );
  const { success, error } = useMemo(
    () => {
      const r1 = data[md.columns.response1.name];
      const r2 = data[md.columns.response2.name];
      if (typeof (r1) === 'string' && typeof (r2) === 'string' && r1.length > 100 && r2.length > 100) {
        return {
          success: false,
          error: 'Помилка авторизації на сервері Logica. Спробуйте авторизуватись ще раз.',
        };
      }
      const err1 = r1 && typeof (r1) === 'object' ? r1.errorMessage : null;
      const err2 = r2 && typeof (r2) === 'object' ? r2.errorMessage : null;
      const st1 = r1 && typeof (r1) === 'object' ? r1.success : false;
      const st2 = r2 && typeof (r2) === 'object' ? r2.success : false;
      if (err1 || err2) {
        return {
          success: false,
          error: `${err1 || ''} ${err2 || ''}`,
        };
      }
      if (st1 && st2) {
        return {
          error: null,
          success: true,
        };
      }
      return {
        error: null,
        success: false,
      };
    },
    [data],
  );
  const onClearMessages = useCallback(
    () => actions.onChange({
      [md.columns.response1.name]: null,
      [md.columns.response2.name]: null,
    }),
    [actions],
  );
  // eslint-disable-next-line no-confusing-arrow
  const selectedRows = useMemo(() => id === 'create' ? [] : [id], [id]);
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
        loading={loading}
      />
      <DocEditorCommandPanel
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      >
        <PrintModal selectedRows={selectedRows} />
      </DocEditorCommandPanel>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
        <Nav className="m-2">
          <div className="d-flex flex-fill gap-2 align-items-center">
            <Button
              variant="primary"
              onClick={() => {
                actions.onSR('FILL_OUT');
              }}
            >
              1. Заповнити даними з системи
            </Button>
            <Button
              variant={!ticket ? 'secondary' : 'outline-secondary'}
              disabled={!!ticket}
              onClick={() => {
                setLogikaLoginOpened(true);
              }}
            >
              2. Авторизація у LOGICA
            </Button>
            <Button
              variant={ticket && id !== 'create' ? 'success' : 'outline-success'}
              onClick={async () => {
                await actions.onChange({
                  [md.columns.response1.name]: null,
                  [md.columns.response2.name]: null,
                });
                await actions.onSR('SET_REGISTRATION_DATA', { ticket });
              }}
              disabled={!ticket || id === 'create'}
            >
              3. Відправити до LOGICA
            </Button>
            <Button
              variant={ticket ? 'warning' : 'outline-warning'}
              onClick={async () => {
                await actions.onChange({
                  [md.columns.response1.name]: null,
                  [md.columns.response2.name]: null,
                });
                await actions.onSR('GET_REGISTRATION_DATA', { ticket });
              }}
              disabled={!ticket}
            >
              Заповнити даними з Logica
            </Button>
          </div>
          <div>
            <Badge variant="info">{data[md.columns.Role.name]}</Badge>
          </div>
        </Nav>
        {error && (
          <Alert variant="danger" onClose={onClearMessages} dismissible>{error}</Alert>
        )}
        {success && (
          <Alert variant="success" onClose={onClearMessages} dismissible>
            Операція виконана вдало!
          </Alert>
        )}
        <Card.Header>
          <RegistrationForm data={data} actions={actions} permissions={permissions} />
        </Card.Header>

        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
      <Modal
        show={logikaLoginOpened}
        size="lg"
        onHide={() => {
          setLogikaLoginOpened(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Logica onAuth={() => setLogikaLoginOpened(false)} />
        </Modal.Body>
      </Modal>

    </>
  );
}

RegistrationFormEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

RegistrationFormEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { RegistrationFormEditor as StdEditor };

export default getRouterEditor(md)(RegistrationFormEditor);
