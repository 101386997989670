import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS, Map } from 'immutable';
import { compose } from 'redux';
// import { Alert, Button } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import TableEditor from '../../tableEditor';
import { MainContainer, ResultSegment } from '../styledComponentsDp';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import DpContainer from '../dpContainer';
import { emptyUid } from '../../../constants/meta/common';

const md = meta.dp.countExpensesOnEducationPrinting;

const PrintingEditor = ({ onClose }) => {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);
  const navigate = useNavigate();
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const signVariantValue = useMemo(
    () => headerForm.get('SignVariant', new Map()).toJS(),
    [headerForm],
  );
  // const docApproved = useMemo(
  //   () => headerForm.get('DocApproved', false),
  //   [headerForm],
  // );
  const signApprooved = useMemo(
    () => headerForm.get('signApprooved', false),
    [headerForm],
  );
  const signVariantValueFilled = signVariantValue.id && signVariantValue.id !== emptyUid
    && signApprooved;

  const signMFO = useMemo(
    () => headerForm.get('signMFO', null),
    [headerForm],
  );

  const existDocSendToLogica = useMemo(
    () => headerForm.get('existDocSendToLogica', null),
    [headerForm],
  );

  const [opened, setOpened] = useState(true);
  const [openedEx, setOpenedEx] = useState(true);

  useEffect(
    () => {
      const logicaDocId = headerForm.getIn(['docSendToLogica', 'id'], emptyUid);
      if (logicaDocId !== emptyUid) {
        navigate(`${meta.doc.docTransferToLogica.frontend}/${logicaDocId}/`);
        onClose();
      }
    },
    [dispatch, headerForm, navigate, onClose],
  );

  const SettingsButton = (
    <div>
      <span>
        Налаштування не передбачено
      </span>
    </div>
  );

  return (
    <MainContainer>
      <DpContainer
        Settings={SettingsButton}
        backendName={md.backendName}
        // reportVariants={reportVariants}
      >
        <div className="d-flex gap-2">
          <div className="flex-fill">
            <EditorControls.ItemPicker
              label={headerForm.get('signVariantLabel', false) || md.columns.SignVariant.label}
              required
              value={signVariantValue}
              onChange={(e, v) => {
                dispatch(actions.changeField(['headerForm', 'SignVariant'], fromJS(v)));
                dispatch(actions.processServerReq('RESET_SignVariant'));
              }}
              modelType="cat"
              modelName="signatureVariants"
            />
          </div>
          {signMFO !== null && (
            <Modal show={opened} onHide={() => setOpened(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Увага !</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {signMFO}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={() => setOpened(false)}>ОК</Button>
              </Modal.Footer>
            </Modal>
          )}
          {existDocSendToLogica !== null && (
            <Modal show={openedEx} onHide={() => setOpenedEx(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Увага !</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {existDocSendToLogica}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={() => dispatch(actions.processServerReq('CreateDispatchDocNext'))}>Так</Button>
                <Button variant="danger" onClick={() => setOpenedEx(false)}>Ні</Button>
              </Modal.Footer>
            </Modal>
          )}
          <Button
            variant="success"
            onClick={() => {
              dispatch(actions.processServerReq('CreateLogicaDoc'));
            }}
            // disabled={!signVariantValueFilled || !docApproved}
            disabled={!signVariantValueFilled}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-1" />
            Передати в Logica
          </Button>
        </div>
        {/* {!docApproved && ( */}
        {/*  <Alert variant="warning"> */}
        {/*    Для відравки в Logica потрібне затвердження документа у розпорядника вищого рівня */}
        {/*  </Alert> */}
        {/* )} */}
        <ResultSegment>
          <TableEditor mountNode={resultMountNode} />
        </ResultSegment>
      </DpContainer>
    </MainContainer>
  );
};

PrintingEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = compose(
  // connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null,
    },
  ),
);

export default enchance(PrintingEditor);
