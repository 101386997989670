import {
  directions, defaultColumns,
} from '../common';

const measurements = {
  period: {
    label: 'Період', name: 'Период', visible: true, type: 'date',
  },
  element: { label: 'Деталізація КПКВ', name: 'Элемент', visible: true },
};

const resources = {
  classifier: { label: 'Деталізація КПКВ розп. вищ. рівня', name: 'Классификатор', visible: true },
};

const props = {
};

const columns = {
  ...defaultColumns,
  id: { label: 'id', name: '_id', visible: false },
  ...measurements,
  ...resources,
  ...props,
};

const defaultOrder = [
  {
    column: columns.period.name,
    direction: directions.ascending,
  },
];

export const infoRegKbp7ElemBetween = {
  label: 'Відповідність між элементами КБП7',
  name: 'kbp7ElemBetween',
  columns,
  backendName: 'СоответствиеЭлементовКФК',
  defaultOrder,
  frontend: 'infoRegs/kbp7ElemBetween',
  listColumns: [
    ...new Set([
      'element',
      'classifier',
      'period',
    ]),
  ],
  useListLazyLoad: true,
};

export default infoRegKbp7ElemBetween;
