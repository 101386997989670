/* eslint-disable no-confusing-arrow */
import React, {
  useMemo,
  useState,
  useRef,
  forwardRef,
  useCallback,
  useEffect,
  Suspense,
  useContext,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup, Dropdown,
  Modal, Nav, Spinner,
} from 'react-bootstrap';
import { faCheck, faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';
import { debounce } from 'lodash';
import styled from 'styled-components';
import Feedback from 'react-bootstrap/Feedback';
import { useSelector } from 'react-redux';
import meta from '../../../../constants/meta';
import { ItemField, valuePropType } from './itemField';
import { DataList } from './datalist';
import api from '../../../../api/req';
import mfapi from '../../../../minfin/api/req';
import catSelectors from '../../../../containers/catalogs/selectors';
import docSelectors from '../../../../containers/documents/selectors';
import { useMD } from '../../../../containers/newLister/hooks/md';
import { comparisonTypes, selectionMode } from '../../../../constants/meta/common';
import minfin, { componentOnMinfin } from '../../../../constants/minfin';
import { soSelector } from '../../../../containers/documents/_common/selectors';
import OldSelector from '../../../../containers/lister/selector';
// eslint-disable-next-line import/no-named-as-default
import { AppContext } from '../../../../minfin/providers/authProvider';
import { transformFilter } from '../../../../minfin/api/utils';

const SERVER_REQUEST_TIMEOUT = 500;

const DDT = styled(Button)`
  &:after{
    display: none;
  }
`;

const CHAPTERPS = {
  cat: 'Справочник',
  doc: 'Документ',
};

export const ItemPicker = forwardRef(({
  value, filter, choiceSettings, params, canErase, onChange, disabled, noHierarchy,
  onClick, onFocus, onBlur, onDropDown, autoSelect, required, placeholder,
  readOnly, modelType, modelName, errored,
  className, size,
  prepend, tabIndex,
}, ref) => {
  const MetaInfo = useMD(modelType, modelName);
  const sessionParams = useSelector(soSelector);
  const onMinFin = useMemo(
    () => componentOnMinfin(meta[modelType][modelName], CHAPTERPS[modelType], sessionParams),
    [modelName, modelType, sessionParams],
  );
  const lFilter = useMemo(
    () => onMinFin ? transformFilter(filter, params) : filter,
    [filter, onMinFin, params],
  );
  const Selector = useMemo(() => {
    if (modelType && modelName) {
      if (!(modelType in meta) || !(modelName in meta[modelType])) return null;
      if (modelType in CHAPTERPS
            && onMinFin) {
        return minfin[meta[modelType][modelName].frontend].selector;
      }
      if (modelType === 'cat' && catSelectors[modelName]) {
        return catSelectors[modelName];
      }
      if (modelType === 'doc' && docSelectors[modelName]) {
        return docSelectors[modelName];
      }
      return OldSelector;
    }
    throw new Error('no modelType, modelName or backend props in ItemPicker');
  }, [modelName, modelType, onMinFin]);
  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);
  const abortCtrl = useRef(null);
  const [editText, setEditText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [items, setItems] = useState([]);
  const [activeListItem, setActiveListItem] = useState(null);
  const [listOpened, setListopened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [activeModalItem, setActiveModalItem] = useState(null);
  const { auth } = useContext(AppContext);

  const openModal = useCallback(
    () => {
      setModalOpened(true);
      setEditText(null);
    },
    [],
  );

  const change = useCallback(
    (e, item) => {
      if (Map.isMap(item)) {
        onChange(e, { id: item.get('id'), repr: item.get('repr') }, item.toJS());
      } else {
        onChange(e, { id: item.id, repr: item.repr }, item);
      }
    },
    [onChange],
  );

  const onDataListSelect = useCallback(
    (e, v) => {
      console.log(v);
      console.log(v);
      setListopened(false);
      change(e, v);
      setEditText(null);
    },
    [change],
  );

  const onFocusHandler = useCallback(
    (e) => {
      if (autoSelect && inputRef.current && !modalOpened && !listOpened) {
        inputRef.current.setSelectionRange(0, inputRef.current.value.length);
      }
      if (onFocus) onFocus(e);
    },
    [autoSelect, inputRef, listOpened, modalOpened, onFocus],
  );
  const onOk = useCallback(
    (e) => {
      setModalOpened(false);
      change(e, activeModalItem);
    },
    [activeModalItem, change],
  );

  const okEnabled = useMemo(() => {
    if (!activeModalItem) return false;
    if (Map.isMap(activeModalItem)) {
      if (choiceSettings === selectionMode.items) {
        return !activeModalItem.get('isGroup', false);
      } if (choiceSettings === selectionMode.folders) {
        return activeModalItem.get('isGroup', false);
      }
      return true;
    }
    if (onMinFin) {
      if (choiceSettings === selectionMode.items) {
        return !activeModalItem.is_group;
      } if (choiceSettings === selectionMode.folders) {
        return !!activeModalItem.is_group;
      }
      return true;
    }

    throw new Error('По новому еще не реалищовано!');
  }, [activeModalItem, choiceSettings, onMinFin]);

  const handleUserKeyPress = useCallback(
    (e) => {
      const { key } = e;
      if (inputRef.current.parentElement.contains(e.target)) {
        if (key === 'F4') {
          openModal(e);
          e.preventDefault();
        } else if (listOpened) {
          if (key === 'ArrowUp') {
            e.preventDefault();
            e.stopPropagation();
            setActiveListItem((i) => i ? i - 1 : items.length - 1);
          } else if (key === 'ArrowDown') {
            e.preventDefault();
            e.stopPropagation();
            setActiveListItem((i) => i < items.length - 1 ? i + 1 : 0);
          } else if (key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onDataListSelect(e, items[activeListItem].id);
          } else if (key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setListopened(false);
            setEditText(null);
          }
        } else if (modalOpened) {
          if (key === 'Enter' && e.ctrlKey && okEnabled) {
            e.preventDefault();
            onOk(e);
          } else if (key === 'Enter' && !e.ctrlKey) {
            // чтобы небыло перехода по ентеру
            e.preventDefault();
          }
        } else if (loading) {
          if (key === 'Enter' || key === 'Tab') {
            // чтобы небыло перехода по ентеру
            e.preventDefault();
          }
        }
      }
    },
    [activeListItem, inputRef, items, listOpened, loading, modalOpened, okEnabled, onDataListSelect, onOk, openModal],
  );

  const onFocusOut = useCallback(
    (e) => {
      if (inputRef.current.parentElement.contains(e.target)
        && !inputRef.current.parentElement.contains(e.relatedTarget)) {
        if (listOpened) {
          setListopened(false);
        }
        if (abortCtrl.current) {
          abortCtrl.current.abort();
        }
        setEditText(null);
        if (onBlur) onBlur(e);
      }
    },
    [inputRef, listOpened, onBlur],
  );

  useEffect(() => {
    const node = inputRef.current.parentElement;
    node.addEventListener('keydown', handleUserKeyPress);
    node.addEventListener('focusout', onFocusOut);
    return () => {
      node.removeEventListener('keydown', handleUserKeyPress);
      node.removeEventListener('focusout', onFocusOut);
    };
  }, [handleUserKeyPress, inputRef, onFocusOut]);

  const onSearch = useCallback(
    (text) => {
      const loader = async () => {
        abortCtrl.current = new AbortController();
        const response = await api.post$(
          `${modelType}/${meta[modelType][modelName].backendName}/getchoicedata`,
          {
            searchText: text,
            filter,
            choiceSettings,
          },
          abortCtrl.current,
        );
        abortCtrl.current = null;
        if (response.ok) {
          return response.json();
        }
        throw new Error(`${response.status} ${response.statusText}`);
      };
      setErr(null);
      setLoading(true);
      loader()
        .then(
          (data) => {
            setItems(data.ChoiceData);
            setActiveListItem(data.ChoiceData.length ? 0 : null);
            setListopened(!!data.ChoiceData.length);
          },
        )
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [abortCtrl, choiceSettings, filter, modelName, modelType],
  );

  const onMinFinSearch = useCallback(
    (text) => {
      const loader = async () => {
        abortCtrl.current = new AbortController();
        // const strFilter = Object.entries(lFilter).map(([k, v]) => `${k}=${v}`).join('&');
        const response = await mfapi.get(
          minfin[meta[modelType][modelName].frontend].meta.backendURL,
          auth,
          {
            ...lFilter,
            search: text,
            includeUids: true,
          },
          false,
          abortCtrl.current,
        );
        abortCtrl.current = null;
        if (response.ok) {
          return response.json();
        }
        throw new Error(`${response.status} ${response.statusText}`);
      };
      setErr(null);
      setLoading(true);
      loader()
        .then(
          (data) => {
            const choiceData = Array.isArray(data) ? data : data.results;
            setItems(choiceData.map((c) => ({ id: { id: c.uid, repr: c.repr }, repr: c.repr })));
            setActiveListItem(choiceData ? 0 : null);
            setListopened(!!choiceData.length);
          },
        )
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, lFilter, modelName, modelType],
  );

  useEffect(
    () => {
      if (onDropDown && listOpened) onDropDown();
    },
    [listOpened, onDropDown],
  );

  const onDataListClose = useCallback(
    (isOpen) => {
      if (!isOpen) {
        setActiveListItem(null);
        setListopened(false);
      }
    },
    [],
  );
  const getListChoice = useMemo(
    () => debounce(onMinFin ? onMinFinSearch : onSearch, SERVER_REQUEST_TIMEOUT),
    [onMinFin, onMinFinSearch, onSearch],
  );

  const onCancel = useCallback(
    () => setModalOpened(false),
    [],
  );

  const onSelect = (e, item) => {
    const cItem = onMinFin ? { ...item, id: item.uid } : item;
    setActiveModalItem(cItem);
  };

  const onChoice = (e, item) => {
    const cItem = onMinFin ? { ...item, id: item.uid } : item;
    setActiveModalItem(cItem);
    setModalOpened(false);
    if (okEnabled) {
      change(e, cItem);
      e.stopPropagation();
    }
  };

  const selectorValue = useMemo(
    () => new Map(value),
    [value],
  );
  return (
    <>
      <ItemField
        value={value}
        isLoading={loading}
        tabIndex={tabIndex}
        editText={editText}
        onChange={(e, v) => {
          setEditText(v);
          getListChoice(v);
        }}
        readOnly={disabled || readOnly}
        onClick={onClick}
        onFocus={onFocusHandler}
        ref={inputRef}
        errored={errored || !!err}
        className={className}
        prepend={prepend}
        required={required}
        placeholder={placeholder}
        size={size}
      >

        <Dropdown show={listOpened} onToggle={onDataListClose}>
          <Dropdown.Toggle
            as={DDT}
            onClick={openModal}
            variant="outline-secondary"
            disabled={readOnly || disabled}
            tabIndex="-1"
            style={{ zIndex: 'auto' }}
            size={size || 'sm'}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Dropdown.Toggle>
          {canErase
          && (
            <Button
              onClick={(e) => {
                onChange(e, null);
                setEditText(null);
              }}
              disabled={readOnly || disabled}
              variant="outline-secondary"
              tabIndex="-1"
              size={size || 'sm'}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
          {listOpened && (
            <DataList
              data={items}
              activeItem={activeListItem}
              show={listOpened}
              onSelect={onDataListSelect}
            />
          )}
        </Dropdown>

        {err && (
          <Feedback type="invalid" tooltip>{err}</Feedback>
        )}
      </ItemField>
      <Modal
        show={modalOpened}
        size="xl"
        scrollable
        centered
        onHide={onCancel}
        dialogClassName="mw-100"
        container={inputRef.current && inputRef.current.parentElement}
      >
        <Modal.Header>
          <Modal.Title>
            {MetaInfo.label}
          </Modal.Title>
          <Nav className="flex-fill">
            <ButtonGroup className="ms-auto">
              <Button variant="success" disabled={!okEnabled} onClick={onOk}>
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Обрати
              </Button>
              <Button variant="danger" onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} className="me-2" />
                Скасувати
              </Button>
            </ButtonGroup>
          </Nav>
        </Modal.Header>
        <Modal.Body className="py-0">

          {Selector && (
            <Suspense fallback={() => <Spinner animation="border" />}>
              <Selector
                modelType={modelType}
                modelName={modelName}
                filter={lFilter}
                params={params}
                choiceSettings={choiceSettings}
                value={selectorValue}
                onSelect={onSelect}
                onChoice={onChoice}
                noHierarchy={noHierarchy}
              />
            </Suspense>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
});

ItemPicker.propTypes = {
  value: valuePropType,
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    comparisonType: PropTypes.oneOf(Object.values(comparisonTypes)),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      valuePropType,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        valuePropType])),
    ]),
  })),
  choiceSettings: PropTypes.oneOf(Object.values(selectionMode)),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.string,
        repr: PropTypes.string,
      }),
    ]),
  })),
  canErase: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  noHierarchy: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onDropDown: PropTypes.func,
  readOnly: PropTypes.bool,
  modelType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  errored: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  autoSelect: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
};

ItemPicker.defaultProps = {
  value: { id: null, repr: '' },
  canErase: false,
  filter: [],
  params: [],
  choiceSettings: selectionMode.items,
  disabled: false,
  noHierarchy: false,
  onClick: null,
  onFocus: null,
  onBlur: null,
  onDropDown: null,
  readOnly: false,
  errored: false,

  className: null,
  prepend: null,
  autoSelect: true,
  required: false,
  placeholder: '',
  tabIndex: null,
  size: 'sm',
};

export default ItemPicker;
