import api from '../../../../minfin/api/req';

export const fillCountChild = async ({
  data,
  auth,
}) => {
  const idFo = data['ФинансовыйОрган'].id;

  console.log('data[ФинансовыйОрган]', data['ФинансовыйОрган']);

  const url = '/api/bfn/bfngetchildcount/';

  const r = await api.get(`${url}`, auth, { idFo });

  if (r.ok) {
    const count = await r.json();
    console.log('get count !!!!', count);
    return count;
  }
  console.log('Not Ok fillCountChild');
  return 2017;
};

export default fillCountChild;
