import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../../constants/backend/propTypes';
import CiatAppContext from './context';

function CiatAppContextProvider({
  children, currentUser, tokens, logoutHandler, refreshHandler, currentUserRefresh, minfinSrv,
}) {
  const [settings, setSettings] = useState({});
  const getAuth = useCallback(
    () => ({
      access: tokens.current.accessToken,
      onRefresh: refreshHandler,
      minfinSrv,
    }),
    [minfinSrv, refreshHandler, tokens],
  );
  const refreshCurrentUser = useCallback(
    () => (
      currentUserRefresh(tokens.current.accessToken, tokens.current.refreshToken)
    ),
    [currentUserRefresh, tokens],
  );

  const onSetSettings = useCallback(
    (key, value) => setSettings((oldSettigs) => ({ ...oldSettigs, [key]: value })),
    [],
  );

  const appContextValue = useMemo(
    () => ({
      currentUser,
      logoutHandler,
      refreshHandler,
      refreshCurrentUser,
      setSettings: onSetSettings,
      settings,
      auth: getAuth,
    }),
    [
      currentUser, logoutHandler, refreshHandler, refreshCurrentUser,
      onSetSettings, settings, getAuth,
    ],
  );

  return (
    <CiatAppContext.Provider value={appContextValue}>
      {children}
    </CiatAppContext.Provider>
  );
}

CiatAppContextProvider.propTypes = {
  children: childrenPropType.isRequired,
  currentUser: PropTypes.shape({
  }).isRequired,
  tokens: PropTypes.shape({
    current: PropTypes.shape({
      accessToken: PropTypes.string,
      refreshToken: PropTypes.string,
    }),
  }).isRequired,
  logoutHandler: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  currentUserRefresh: PropTypes.func.isRequired,
  minfinSrv: PropTypes.string.isRequired,
};
export default CiatAppContextProvider;
