import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/estimate';
import {
  StyledLabel,
  TwoColumnsGridContainer,
  MyContainerFormWithNColumns,
  CommandPanelColor,
} from '../../../components/Form/styledForm';

import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import StyledTPPane from '../../../components/tableParts/TPPane';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import DateInput from '../../../components/fields/dates';
import meta from '../../../constants/meta';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const NineColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 10% 10% 8% 8% 8% 8% 8% 8% 8% 8%;
  grid-column-gap: 2%;
`;

const SixColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 10% 15% 15% 15% 15% 15%;
  grid-column-gap: 2%;
`;

const FourColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 23% 23% 23% 23%;
  grid-column-gap: 2%;
`;
const TwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 25% 25%;
  grid-column-gap: 2%;
`;

const StringInput4 = Fields.GetTextInput(4);
const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};
const ReportTypesKEKV = {
  none: { label: 'Не враховувати', value: null },
  assig: { label: 'Асигнування', value: 'Ассигнование' },
  credit: { label: 'Кредитування', value: 'Кредитование' },
};
const ShowKVKTypes = {
  csu: { label: 'ГолРозпорядник', value: 0 },
  csu2: { label: 'Розпорядник', value: 1 },
  kvk: { label: 'КВК', value: 2 },
};

const TypeVariant = {
  d1: { label: 'Весь рік', name: 0 },
  d2: { label: 'І Квартал', name: 1 },
  d3: { label: 'ІІ Квартал', name: 2 },
  d4: { label: 'І-е півріччя', name: 3 },
  d5: { label: 'ІІІ Квартал', name: 4 },
  d6: { label: '9 місяців', name: 5 },
  d7: { label: 'ІV Квартал', name: 6 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());
  const FO = headerForm.get('FO', new Map());

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'Estimate';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const SettingsButton = (
    <div>
      <MyContainerFormWithNColumns columns={3}>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
            />
          ) : (
            <Fields.ItemField
              value={headerForm.get('budget', new Map())}
            />
          )}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('FO', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'FO'], v));
                dispatch(changeField(['headerForm', 'signVariant'], new Map()));
              }}
            />
          ) : (
            <Fields.ItemField
              value={headerForm.get('FO', new Map())}
            />
          )}
        </div>
      </MyContainerFormWithNColumns>
      <SixColumnsGrid>
        <div>
          <StyledLabel>{md.columns.yearCost.label}</StyledLabel>
          <Fields.YearField
            value={headerForm.get('yearCost', 0)}
            onChange={(e, value) => dispatch(changeField(['headerForm', 'yearCost'], value))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.datePrint.label}</StyledLabel>
          <DateInput
            value={headerForm.get('datePrint', null)}
            onChange={(e, value) => dispatch(changeField(['headerForm', 'datePrint'], value))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.ReportKind.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('ReportKind', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'ReportKind'], v))}
            values={Object.values(ReportTypes)}
            name="ReportKind"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.PlanKind.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('PlanKind', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'PlanKind'], v))}
            values={Object.values(enums.PlaningKind).map((_e) => (
              { label: _e.label, value: _e.name }))}
            name="PlanKind"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.CostKind.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('CostKind', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'CostKind'], v))}
            values={Object.values(ReportTypesKEKV)}
            name="CostKind"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.showCSUas.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('showCSUas', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUas'], v))}
            values={Object.values(ShowKVKTypes)}
            name="showCSUas"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.quart.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('quart')}
            options={Object.values(TypeVariant)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'quart'], v))}
          />
        </div>
      </SixColumnsGrid>
      <NineColumnsGrid>
        <Fields.CheckboxField
          value={headerForm.get('allKEKV', true)}
          label={md.columns.allKEKV.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'allKEKV'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('onlyKEKV', true)}
          label={md.columns.onlyKEKV.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'onlyKEKV'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('headApproved', true)}
          label={md.columns.headApproved.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'headApproved'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('redu', true)}
          label={md.columns.redu.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'redu'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('approvedPlan', true)}
          label={md.columns.approvedPlan.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'approvedPlan'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('head', true)}
          label={md.columns.head.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'head'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('redForm', true)}
          label={md.columns.redForm.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'redForm'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('redCsu', true)}
          label={md.columns.redCsu.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'redCsu'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('in1000', true)}
          label={md.columns.in1000.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
        />
        <div>
          <Fields.CheckboxField
            value={headerForm.get('noCop', true)}
            label={md.columns.noCop.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'noCop'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('flCop', true)}
            label={md.columns.flCop.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'flCop'], v))}
          />
        </div>
      </NineColumnsGrid>
      <FourColumnsGrid>
        <Fields.CheckboxField
          value={headerForm.get('projectEstimate', true)}
          label={md.columns.projectEstimate.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'projectEstimate'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('flKDBKFB', true)}
          label={md.columns.flKDBKFB.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'flKDBKFB'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('consolidated', true)}
          label={md.columns.consolidated.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
        />
      </FourColumnsGrid>
      <TwoColumnsGrid>
        <div>
          <StyledLabel>{md.columns.emptyNumber.label}</StyledLabel>
          <StringInput4
            textLeft
            value={headerForm.get('emptyNumber', '')}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'emptyNumber'], v));
            }}
          />
        </div>
      </TwoColumnsGrid>
      <FourColumnsGrid>
        <div>
          <StyledLabel>{md.columns.elKFK.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('elKFK', new Map())}
            modelType="cat"
            modelName="elementKFK"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'elKFK'], v))}
            canCleared
          />
        </div>
        <div>
          <StyledLabel>{md.columns.kbp4.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('kbp4', new Map())}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'kbp4'], v))}
            canCleared
          />
        </div>
        <div>
          <StyledLabel>{md.columns.kbp.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('kbp', new Map())}
            modelType="cat"
            modelName="kbp7Classifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'kbp'], v))}
            canCleared
          />
        </div>
        <div>
          <StyledLabel>{md.columns.csuUp.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('csuUp', new Map())}
            modelType="cat"
            modelName="csu"
            noHierarchy
            onChange={(e, v) => dispatch(changeField(['headerForm', 'csuUp'], v))}
            canCleared
          />
        </div>
      </FourColumnsGrid>
      <DCControls.Filter
        filterChoice={new Map()
          .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
          .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
          .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
          .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
          .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
      />
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>
      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
