import React from 'react';
import PropTypes from 'prop-types';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function SettingsButton({
  content, onClick, disabled, active,
}) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      title={content}
      icon={faGear}
      active={active}
    />
  );
}

SettingsButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

SettingsButton.defaultProps = {
  onClick: () => null,
  content: 'Налаштування',
  disabled: false,
  active: false,
};

export default SettingsButton;
