import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import DateRange from '../../../components/fields/DateRangeField';
import TPDocs from './tablepart/TPDocs';
import enums from '../../../constants/meta/enums';
import {
  CommandPanelColor,
  ContainerButton,
  FrameDiv,
  StyledLabel,
  TwoColumnsGridContainer,
  ThreeColumnsGridContainer,
} from '../../../components/Form/styledForm';
import InputWithDropdown from '../../../components/styledInputs/inputWithDropdown';

const md = meta.dp.requestsLoadingPlaningDoc;

const planingKindsOptions = Object.values(enums.PlaningKind);

const ContainerBut = styled(ContainerButton)`
  margin: 20px 0 5px 0;
`;

class RequestsLoadingPlaningDocEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    onOK: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
    onOK: null,
  };

  loadHandler = () => {
    const { onOK } = this.props;

    if (onOK) {
      onOK((store) => {
        const checkedDocs = store.getIn([`dp/${md.name}/dpEditor`, 'tables/docsTable']).filter((row) => row.get('Check', false)).reduce((v, row) => v.set(row.getIn(['Document', 'id']), 'true'), new Map());
        return ({
          data: store.getIn([`dp/${md.name}/dpEditor`, 'tables/documentNotes']).filter((row) => checkedDocs.has(row.getIn(['Document', 'id']))),
        });
      });
    }
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;

    return (
      <FrameDiv>
        <ThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRange
              value={headerForm.get('period', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'period'], value));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('budget', new Map())}
            />
          </div>
          <div>
            <StyledLabel>Вид планування</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('planingMode')}
              options={planingKindsOptions}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'planingMode'], value));
              }}
            />
          </div>
        </ThreeColumnsGridContainer>
        <TwoColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.FI.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('FI', new Map())}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.elementFond.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('elementFond', new Map())}
            />
          </div>
        </TwoColumnsGridContainer>
        <Fields.CheckboxField
          label={md.columns.isRectificationPlan.label}
          value={headerForm.get('isRectificationPlan', false)}
          onChange={(e, value) => {
            dispatch(actions.changeField(['headerForm', 'isRectificationPlan'], value));
          }}
        />
        <ContainerBut>
          <CommandPanelColor
            label="1. Заповинити"
            onClick={() => dispatch(actions.processServerReq('FILL_TABLE'))}
          />
          <CommandPanelColor
            label="2. Завантажити"
            onClick={this.loadHandler}
          />
        </ContainerBut>
      </FrameDiv>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // requestsLoadingPlaningDoc',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: TPDocs, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(RequestsLoadingPlaningDocEditor);
