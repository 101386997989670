import React, {
  memo, useCallback,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'directionsGBFact';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
          <ItemPicker
            value={row[tableMD.columns.elementKFK.name]}
            modelType="cat"
            modelName="elementKFK"
            onChange={(e, v) => onRowChange(
              e,
              { [tableMD.columns.elementKFK.name]: v },
            )}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum2.name]: value,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2.name]: value,
                  [tableMD.columns.sum2.name]: value
                  + (row[tableMD.columns.sf2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
                  <NumberInput
                    value={row[tableMD.columns.sf2.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf2.name]: value,
                      [tableMD.columns.sum2.name]: value
                      + (row[tableMD.columns.zf2.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableRowCell column="br2" highlighted={highlights.includes('br2')}>
                  <NumberInput
                    value={row[tableMD.columns.br2.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.br2.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              <NumberInput
                value={row[tableMD.columns.sum1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum1.name]: value,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1.name]: value,
                  [tableMD.columns.sum1.name]: value
                  + (row[tableMD.columns.sf1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
                  <NumberInput
                    value={row[tableMD.columns.sf1.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf1.name]: value,
                      [tableMD.columns.sum1.name]: value
                      + (row[tableMD.columns.zf1.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableRowCell column="br1" highlighted={highlights.includes('br1')}>
                  <NumberInput
                    value={row[tableMD.columns.br1.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.br1.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
