import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL10;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG1S'}
              highlighted={highlights.includes('T10RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG1S')}
            >
              {meta.columns.T10RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG21'}
              highlighted={highlights.includes('T10RXXXXG21')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG21')}
            >
              {meta.columns.T10RXXXXG21.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG22'}
              highlighted={highlights.includes('T10RXXXXG22')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG22')}
            >
              {meta.columns.T10RXXXXG22.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG23'}
              highlighted={highlights.includes('T10RXXXXG23')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG23')}
            >
              {meta.columns.T10RXXXXG23.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG24'}
              highlighted={highlights.includes('T10RXXXXG24')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG24')}
            >
              {meta.columns.T10RXXXXG24.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG25'}
              highlighted={highlights.includes('T10RXXXXG25')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG25')}
            >
              {meta.columns.T10RXXXXG25.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T10RXXXXG26'}
              highlighted={highlights.includes('T10RXXXXG26')}
              onToggleHighlght={() => onToggleHighlght('T10RXXXXG26')}
            >
              {meta.columns.T10RXXXXG26.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
