/* eslint-disable react/jsx-props-no-spreading,no-case-declarations,no-confusing-arrow */
import React, {
  memo, useMemo, forwardRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import def from '../../../../constants/meta';
import getValueArray from './valueArray__';
import { comparisonTypes, selectionMode } from '../../../../constants/meta/common';
import {
  ItemPicker, NumberInput, StringInput, SelectorInput, CheckboxInput, DateInput,
} from '../../../../components/bootstrap_components/controls';
import { SListGroupItem } from '../styled';
import MultiValueInput from './multiValueInput';
import ValueArrayInput from './valueArray';
import { filterValuePropType } from '../../hooks/filter';

const FilterItem = forwardRef(
  ({
    id, item, toggleUseHandler, onClickHandler, onDoubleClickHandler,
    dragHandleProps, draggableProps, filterChoice, isActive,
    onChangeValue, onChangeComparisson,
    isArray, title, allowedComparations, errored, type,
  }, ref) => {
    const onChangeItemValue = useCallback(
      (e, v) => onChangeValue(id, v),
      [id, onChangeValue],
    );
    const VC = useMemo(
      () => {
        const getComponentByType = (_type) => {
          if (!_type) {
            return {
              component: () => <span>Не доступно</span>,
              props: {
                value: false,
              },
              defaultValue: false,
            };
          }

          const modelType = _type.ModelType;
          const otherProps = _type.ModelName;
          switch (modelType) {
            case 'cat': // Справочник
              const modelName = Object.values(def.cat).reduce((R, d) => d.backendName === otherProps ? d.name : R, '');

              return {
                component: ItemPicker,
                props: {
                  canErase: false,
                  modelType: 'cat',
                  modelName,
                  noHierarchy: false,
                  value: null,
                  filter: filterChoice.leftValue,
                  choiceSettings: selectionMode.foldersNItems,
                },
                defaultValue: new Map(),
              };
            case 'N':
            // eslint-disable-next-line no-unused-vars
              const [_, fractionSize] = otherProps.split(',');
              return {
                component: NumberInput,
                props: {
                  value: null,
                  precision: fractionSize,
                },
                defaultValue: 0,
              };
            case 'S':
              return {
                component: StringInput,
                props: {
                  value: '',
                },
                defaultValue: '',
              };
            case 'B':
              return {
                component: SelectorInput,
                props: {
                  value: false,
                  values: [{ display_name: 'Так', value: true }, { display_name: 'Ні', value: false }],
                },
                defaultValue: false,
              };
            case 'DT':
              return {
                component: DateInput,
                props: {
                  value: '',
                },
                defaultValue: '',
              };
            default:
              throw new Error(`Type ${type} is not supported by Item Filter Editor`);
            // return null;
          }
        };

        const { component, props, defaultValue } = getComponentByType(type);
        if (isArray) {
          return React.createElement(
            getValueArray(component),
            {
              defaultValue,
              ...props,
              onChange: (e, v) => onChangeItemValue(v),
              value: item.RightValue || defaultValue,
              active: isActive,
            },
          );
        }
        return React.createElement(
          component,
          {
            ...props,
            onChange: (e, v) => onChangeItemValue(v),
            value: item.RightValue || defaultValue,
            active: isActive,
          },
        );
      },
      [filterChoice.leftValue, isActive, isArray, item.RightValue, onChangeItemValue, type],
    );

    const onChangeComparissonValue = useCallback(
      (e, v) => onChangeComparisson(id, v),
      [id, onChangeComparisson],
    );

    const onClck = useCallback((e) => {
      e.stopPropagation();
      onClickHandler(id);
    }, [id, onClickHandler]);

    return (
      <SListGroupItem
        ref={ref}
        active={isActive}
        onClick={onClck}
        variant={errored ? 'danger' : ''}
        onDoubleClick={() => onDoubleClickHandler(id)}
        {...dragHandleProps}
        {...draggableProps}
      >
        <Row className="align-items-center">
          <Col sm={4}>
            <CheckboxInput
              id={`use-checker-${id}`}
              value={item.Use}
              onChange={() => toggleUseHandler(id)}
              label={title}
            />
          </Col>
          <Col sm={4}>
            <SelectorInput
              values={allowedComparations}
              value={item.ComparisonType}
              onChange={onChangeComparissonValue}
            />
          </Col>
          <Col sm={4}>
            {isArray ? (
              <ValueArrayInput
                type={type}
                value={item.RightValue}
                onChange={onChangeItemValue}
                filter={filterChoice.leftValue}
                collapsed={!isActive}
              />
            ) : (
              <MultiValueInput
                type={type}
                value={item.RightValue}
                onChange={onChangeItemValue}
                filter={filterChoice.leftValue}
              />

            )}
            {/* {VC} */}
          </Col>
        </Row>
      </SListGroupItem>
    );
  },
);

FilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    Use: PropTypes.bool,
    ComparisonType: PropTypes.oneOf(Object.values(comparisonTypes)),
    RightValue: PropTypes.oneOfType([
      filterValuePropType,
      PropTypes.arrayOf(filterValuePropType),
    ]),
  }).isRequired,
  toggleUseHandler: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  onDoubleClickHandler: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.shape({}).isRequired,
  draggableProps: PropTypes.shape({}).isRequired,
  filterChoice: PropTypes.shape({}),
  isActive: PropTypes.bool,
  onChangeValue: PropTypes.func.isRequired,
  onChangeComparisson: PropTypes.func.isRequired,
  isArray: PropTypes.bool,
  title: PropTypes.string.isRequired,
  allowedComparations: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOf(Object.values(comparisonTypes)).isRequired,
    display_name: PropTypes.string.isRequired,
  })).isRequired,
  errored: PropTypes.bool,
  type: PropTypes.shape({
    ModelType: PropTypes.oneOf(['doc', 'cat', 'B', 'N', 'S']),
    ModelName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

FilterItem.defaultProps = {
  filterChoice: {}, // Параметры выбора
  isActive: false,
  isArray: false,
  errored: false,
  type: null,
};

export default memo(FilterItem);
