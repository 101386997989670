import HeaderComponent from './Header';
import RowComponent from './RowComponent';

const TableComponents = {
  HeaderComponent,
  RowComponent,

};

export default TableComponents;
