import React from 'react';
import Editor from './defaultEditor';

function FactExchange() {
  return (
    <Editor
      title="Завантажити фактичні видатки"
      backendUrl="/api/cityplans/treasurydbf/"
    />
  );
}

export default FactExchange;
