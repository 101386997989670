import React, { useCallback, useMemo } from 'react';
import {
  Accordion, Card, Col, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faComment, faUser } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { decimalToStr } from '../../../../../common/funcs';
import { EditorControls } from '../../../../basicEditor/editorControls';
import DocDetails from '../tableList/docDetails';
import { fieldsPropTypes } from '../dataPropTypes';
import AccHeader from './AccHeader';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

function CardList({
  items, selectedItems, fields, setSelectedItems, docName, mode,
}) {
  const docs = useMemo(
    () => {
      const docIds = new Set(items.map((d) => d[docName].id));
      return [...docIds].map((dId) => {
        const docItems = items.filter((item) => item[docName].id === dId);
        return {
          ...docItems[0][docName],
          use: docItems.map((row) => row.id).some((id) => selectedItems.includes(id)),
          amount: docItems.reduce((R, item) => (R + (mode === 'finorder' ? item.balance : item.amount)), 0),
          rows: docItems,
        };
      });
    },
    [docName, items, mode, selectedItems],
  );

  const onToggleDoc = useCallback(
    (docId) => {
      const docLines = items.filter((item) => item[docName].id === docId).map((item) => item.id);
      if (docs.filter((d) => d.use).map((d) => d.id).includes(docId)) {
        setSelectedItems((s) => s.filter((id) => !docLines.includes(id)));
      } else {
        setSelectedItems((s) => [...s, ...docLines]);
      }
    },
    [docName, docs, items, setSelectedItems],
  );

  return (
    <div className="p-1 border bg-light flex-fill overflow-auto">
      {docs.map((doc) => (
        <Card
          key={doc.id}
          border={doc.use ? 'primary' : 'light'}
          className="mb-2"
        >
          <Accordion>
            <Accordion.Item eventKey={doc.id}>
              <Card.Header
                className="d-flex w-100  m-0 align-items-center border-bottom btn"
              >
                 <EditorControls.CheckboxInput
                  onChange={() => onToggleDoc(doc.id)}
                  value={doc.use}
                />
                <AccHeader eventKey={doc.id} className="flex-fill">
                  <Card.Title className="flex-grow-1 d-flex justify-content-between me-2 align-items-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={(
                        <Tooltip>
                          Натисніть щоб подивитися деталі
                        </Tooltip>
                        )}
                    >
                      <h4
                        className="text-warning mb-0"
                      >
                        {doc.repr}
                      </h4>
                    </OverlayTrigger>
                    <h4 className="text-success me-2 mb-0">
                      {decimalToStr(doc.amount, '0,00')}
                    </h4>
                    <strong>
                      {[...new Set(doc.rows
                        .map((row) => row.kbp7.repr))].join(', ')}
                    </strong>
                  </Card.Title>
                </AccHeader>
              </Card.Header>
              <Accordion.Body>
                <Card.Body>
                  <DocDetails
                    rows={doc.rows}
                    fields={fields}
                    mode={mode}
                  />
                  {doc.comment && (
                  <Card.Text>
                    <hr />
                    <FontAwesomeIcon icon={faComment} className="me-2" />
                    {doc.comment}
                    <hr />
                  </Card.Text>
                  )}
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Card.Footer className="text-muted">
            <Row sm={2}>
              <Col>
                {doc.authority && (
                <Card.Text>
                  <FontAwesomeIcon icon={faBuilding} className="me-2" />
                  {doc.authority.repr}
                </Card.Text>
                )}
              </Col>
              <Col className="text-end">
                {doc.author && (
                <Card.Text>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  {doc.author.repr}
                </Card.Text>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      ))}

    </div>
  );
}

CardList.propTypes = {
  fields: fieldsPropTypes.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    header: foreignPropType,
    finrequest: foreignPropType,
    finproposal: foreignPropType,
  })).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  docName: PropTypes.oneOf(['header', 'finrequest', 'finproposal']).isRequired,
  mode: PropTypes.oneOf(['finrequest', 'finorder']).isRequired,
};

export default CardList;
