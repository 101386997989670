import React, {
  forwardRef, memo, useState,
} from 'react';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';

registerLocale('uk', uk);

const RangeDaterPicker = forwardRef(({ onChange }, ref) => {
  const dt = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(dt.setDate(dt.getDate() + 7));
  return (
    <DatePicker
      selected={startDate}
      onChange={(dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        onChange(dates);
      }}
      onChangeRaw={((e) => {
        onChange(e, { startDate, endDate });
      })}
      startDate={startDate}
      formatWeekDay={(day) => day.slice(0, 3)}
      endDate={endDate}
      selectsRange
      dateFormat="MMM dd"
      className="form-control"
      locale="uk"
      ref={ref}
    />
  );
});

RangeDaterPicker.propTypes = {
  // value: PropTypes.number,
  onChange: PropTypes.func,
};

RangeDaterPicker.defaultProps = {
  // value: 0,
  onChange: () => null,
};

export default memo(RangeDaterPicker);
