import React, {
  useContext,
  useState,
} from 'react';
import {
  Button, Container, Nav,
} from 'react-bootstrap';
import { faRocket, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DKSUDates from './DKSUDates';
import DKSUData from './DKSUData';
import LogcaLoginModal from '../LogikaLogin/LogcaLoginModal';
import { Loader } from '../../../components/bootStrap';
import IconAlert from '../../../components/blanks/common/IconAlert';
import useDKSUData from './hook';
import PageHeader from '../../../components/blanks/common/PageHeader';
import metaSrv from '../../../meta/srv/ImportTreasureFiles';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { WinManagerContext } from '../../../providers';

function Editor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const [show, setShow] = useState(false);
  const {
    loading, err, getCurrentDKSUData, logicaPingSuccess, year, setYear, res, setRes, setErr,
    datesRes, getDKSUDates, dataType, setDataType, setDate, date, datesByType,
    getDKSUData, lastDownload,
  } = useDKSUData();

  return (
    <>
      <PageHeader
        title={metaSrv.name}
        description="Завантаження казначейських файлів з системи Логіка."
        className="mb-3"
      >
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => {
              dellComponentFromWindowsManager();
            }}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <LogcaLoginModal />
      {loading && (
        <Loader text="Завантаження" />
      )}
      <Nav className="gap-2">
        <Button onClick={getCurrentDKSUData} variant="outline-success" className="me-2" disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faRocket} className="me-2" />
          Оновити все
        </Button>
        <Button onClick={() => setShow(true)} variant="outline-warning" disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faTools} className="me-2" />
          Оновити з додатковими налаштуваннями
        </Button>
      </Nav>

      {res && <IconAlert variant="success" dismissible onClose={() => setRes(null)}>{res}</IconAlert>}
      {err && <IconAlert variant="danger" dismissible onClose={() => setErr(null)}>{err}</IconAlert>}

      {show && (
        <Container fluid className="mt-2 py-3  border rounded">
          <DKSUDates
            year={year}
            setYear={setYear}
            getDKSUDates={getDKSUDates}
            datesRes={datesRes}
            logicaPingSuccess={logicaPingSuccess}
          />
          {datesRes
                && (
                  <DKSUData
                    dataType={dataType}
                    setDataType={setDataType}
                    date={date}
                    setDate={setDate}
                    getDKSUData={getDKSUData}
                    datesByType={datesByType}
                    lastDownload={lastDownload}
                    logicaPingSuccess={logicaPingSuccess}
                    datesRes={datesRes}
                  />
                )}

        </Container>
      )}
    </>
  );
}
export default Editor;
