import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  csu: { label: 'Головний роспорядник', name: 'Распорядители', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  reportMonth: { label: 'Місяць звітності', name: 'МесяцОтчетности', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  R01G1: {
    label: 'Сума виплаченої заробіткої плати з утриманнями та нарахуваннями на неї за період, '
      + 'що передує звітному, протягом звітного місяця за рахунок освітньої субвенці КЕКВ 2110',
    name: 'R01G1',
    visible: false,
  },
  R01G2: {
    label: 'Сума виплаченої заробіткої плати з утриманнями та нарахуваннями на неї за період, '
      + 'що передує звітному, протягом звітного місяця за рахунок освітньої субвенці КЕКВ 2120',
    name: 'R01G2',
    visible: false,
  },
  R01G3: {
    label: 'Сума виплаченої заробіткої плати з утриманнями та нарахуваннями на неї за період, '
      + 'що передує звітному, протягом звітного місяця за рахунок місцевих бюджетів КЕКВ 2120',
    name: 'R01G3',
    visible: false,
  },
  R01G4: {
    label: 'Сума виплаченої заробіткої плати з утриманнями та нарахуваннями на неї за період, '
      + 'що передує звітному, протягом звітного місяця за рахунок місцевих бюджетів КЕКВ 2120',
    name: 'R01G4',
    visible: false,
  },
  R01G5: { label: 'Фактично зайняті ставки у звітному місяці, од. Всього', name: 'R01G5', visible: false },
  R01G6: { label: 'Фактично зайняті ставки у звітному місяці, од. у т.ч. вчителі', name: 'R01G6', visible: false },
  R01G7: { label: 'Фактично зайняті ставки у звітному місяці, од. у т.ч. інші педагогічні працівники', name: 'R01G7', visible: false },
  R01G8: { label: 'Фактично зайняті ставки у звітному місяці, од. у т.ч. непедагогічні працівники', name: 'R01G8', visible: false },
  R01G9: {
    label: 'Затверджено кошторисних призначень на '
      + 'звітний місяць, з них: за рахунок освітньої субвенці КЕКВ 2110',
    name: 'R01G9',
    visible: false,
  },
  R01G10: {
    label: 'Затверджено кошторисних призначень на '
      + 'звітний місяць, з них: за рахунок освітньої субвенці КЕКВ 2120',
    name: 'R01G10',
    visible: false,
  },
  R01G11: {
    label: 'Затверджено кошторисних призначень на '
      + 'звітний місяць, з них: за рахунок місцевих бюджетів КЕКВ 2110',
    name: 'R01G11',
    visible: false,
  },
  R01G12: {
    label: 'Затверджено кошторисних призначень на '
      + 'звітний місяць, з них: за рахунок місцевих бюджетів КЕКВ 2120',
    name: 'R01G12',
    visible: false,
  },
  R01G13: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Фактично зайняті ставки педагогічних працівників, од. Всього', name: 'R01G13', visible: false },
  R01G14: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Фактично зайняті ставки педагогічних працівників, од. у т.ч. вчителі', name: 'R01G14', visible: false },
  R01G15: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Фактично зайняті ставки педагогічних працівників, од. у т.ч. інші педагогічні працівники', name: 'R01G15', visible: false },
  R01G16: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) Всього', name: 'R01G16', visible: false },
  R01G17: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. фонд заробiтної плати за схемним окладом (включаючи підвищення)', name: 'R01G17', visible: false },
  R01G18: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки та доплати обов"язкового характеру', name: 'R01G18', visible: false },
  R01G19: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. в т.ч. надбавка за престижнiсть', name: 'R01G19', visible: false },
  R01G20: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки, що носять стимулюючий характер', name: 'R01G20', visible: false },
  R01G21: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. премiї', name: 'R01G21', visible: false },
  R01G22: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. допомога на оздоровлення', name: 'R01G22', visible: false },
  R01G23: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. грошова винагорода', name: 'R01G23', visible: false },
  R01G24: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. iншi види заробiтної плати', name: 'R01G24', visible: false },
  R01G25: { label: 'Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: Нарахування (КЕКВ 2120)', name: 'R01G25', visible: false },
  R01G26: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Фактично зайняті ставки, од. Всього', name: 'R01G26', visible: false },
  R01G27: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Фактично зайняті ставки, од. у т.ч. вчителі', name: 'R01G27', visible: false },
  R01G28: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Фактично зайняті ставки, од. у т.ч. інші педагогічні працівники', name: 'R01G28', visible: false },
  R01G29: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Фактично зайняті ставки, од. у т.ч. непедагогічні працівники', name: 'R01G29', visible: false },
  R01G30: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) Всього', name: 'R01G30', visible: false },
  R01G31: {
    label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. фонд '
      + 'заробiтної плати за схемним окладом (включаю чи підвищен)',
    name: 'R01G31',
    visible: false,
  },
  R01G32: {
    label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. Оплата праці педагогічних працівників (КЕКВ 2110) надбавки '
      + 'та доплати обов"язкового характеру',
    name: 'R01G32',
    visible: false,
  },
  R01G33: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. в т.ч. надбавка за престижнiсть', name: 'R01G33', visible: false },
  R01G34: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки, що носять стимулюючий характер', name: 'R01G34', visible: false },
  R01G35: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. премії', name: 'R01G35', visible: false },
  R01G36: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. допомога на оздоровлення', name: 'R01G36', visible: false },
  R01G37: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. грошова винагорода', name: 'R01G37', visible: false },
  R01G38: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. інші види заробітної плати', name: 'R01G38', visible: false },
  R01G39: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Нарахування на оплату праці педагогічних працівників (КЕКВ2120)', name: 'R01G39', visible: false },
  R01G40: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Оплата праці непедагогічних праціників (КЕКВ2110)', name: 'R01G40', visible: false },
  R01G41: { label: 'Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: Нарахування на оплату праці непедагогічних працівників (КЕКВ2120)', name: 'R01G41', visible: false },
  R01G42: {
    label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
      + 'інших педагогічних працівників (крімвчителів), в т.ч. за рахунок освітньої субвенції',
    name: 'R01G42',
    visible: false,
  },
  R01G43: {
    label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
      + 'інших педагогічних працівників (крімвчителів), в т.ч. за рахунок місцевих бюджетів',
    name: 'R01G43',
    visible: false,
  },
  R01G44: {
    label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
      + 'викладання годин варіативної складової, в т.ч. за рахунок освітньої субвенції',
    name: 'R01G44',
    visible: false,
  },
  R01G45: {
    label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
      + 'викладання годин варіативної складової, в т.ч. за рахунок місцевих бюджетів',
    name: 'R01G45',
    visible: false,
  },
  R01G46: { label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): роботи в умовах простою, в т.ч. за рахунок освітньої субвенції', name: 'R01G46', visible: false },
  R01G47: { label: 'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): роботи в умовах простою, в т.ч. за рахунок місцевих бюджетів', name: 'R01G47', visible: false },
  R01G48: { label: 'Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: Сума виплаченої заробітної плати у звітному місяці за рахунок освітньої субвенції', name: 'R01G48', visible: false },
  R01G49: { label: 'Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: Сума виплаченої заробітної плати у звітному місяці за рахунок місцевих бюджетів', name: 'R01G49', visible: false },
  R01G50: { label: 'Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: Сума заробітної плати, яка буде виплачена у місяці, наступному за звітним за рахунок освітньої субвенції', name: 'R01G50', visible: false },
  R01G51: { label: 'Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: Сума заробітної плати, яка буде виплачена у місяці, наступному за звітним за рахунок місцевих бюджетів', name: 'R01G51', visible: false },
  R01G52: { label: 'Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: Сплата нарахувань на заробітну плату у звітному місяці за рахунок освітньої субвенції', name: 'R01G52', visible: false },
  R01G53: { label: 'Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: Сплата нарахувань на заробітну плату у звітному місяці за рахунок місцевих бюджетів', name: 'R01G53', visible: false },
  R01G54: { label: 'Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: Сума нарахувань, яка буде виплачена у місяці, наступному за звітним за рахунок освітньої субвенції', name: 'R01G54', visible: false },
  R01G55: { label: 'Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: Сума нарахувань, яка буде виплачена у місяці, наступному за звітним за рахунок місцевих бюджетів', name: 'R01G55', visible: false },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  docLogica: { label: 'Документ відправки в Логіка', name: 'ДокОтправкиВЛогика', visible: false },
  isSentLogica: {
    label: 'Підготовлено відправку в Логіку', name: 'ОтправленВЛогику', visible: true, type: 'bool',
  },
  statusLogica: { label: 'СтатусЛогика', name: 'СтатусЛогика', visible: false },
  signLogica: { label: 'ПодписиЛогика', name: 'ПодписиЛогика', visible: false },
};

/**
 * @const
 */
const tables = {
  docs: {
    name: 'Доки',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Дані про видатки на оплату праці з нарахуваннями працівників закладів загальної середньої освіти',
  name: 'dataEducationPayment',
  backendName: 'ДанныеПоОплатеТрудаОбразование',
  columns,
  frontend: 'doc/dataEducationPayment',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'FI',
      'year',
      'appr',
      'isSentLogica',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
