import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import {
  InfoIcon,
  LogoCiatIcon,
  PhoneIcon,
  FaxIcon,
  MobilePhoneIcon,
  EmailIcon,
} from '../../assets/icons';
import api from '../../api/req';

const ButtonInfo = styled.button`
  position: relative;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  border: none;
  font: inherit;
  color: inherit;
  background: linear-gradient(180deg, #E5EDF7 0%, #BDD3EC 100%);
  border-radius: 4px;
  line-height: 0;
   &:hover{
     background: #bdd3ec;
  };
`;
const BoxInfo = styled.div`
    position: fixed;
    z-index: 10;
    // right: 5%;
    bottom: 8.4%;
    left: 5%;
    background: white;
    border: 1px solid #DDE2E9;
    border-radius: 0.3rem;
    padding: 10px;
    width: 400px;
    border: 1px solid #37a0a7;
    box-shadow: 0 0 66px 5px rgba(0,0,0,0.19);
    // 0 0 20px 8px rgba(0,0,0,0.19);
    ::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 10%;
        bottom: -30px;
        border: 15px solid;
        border-color: #ffffff transparent transparent #ffffff;
    }
`;
const InfoText = styled.span`
    color: #4D4D4D;
    font-size: 14px;
`;
const ContainerInfo = styled.div`
    display: grid;
    grid-template-columns: 10% 88%;
    grid-row-gap: 10px;
    padding: 10px;
    margin-top: 15px;
`;
const ContainerLogoLink = styled.a`
  display: flex;
  align-items: center;
`;
const LogoImg = styled.img`
  width: 50px;
`;
const TextCompany = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #386EAB;
  max-width: 100%;
  margin-left: 10px;
  :hover {
    text-decoration: underline;
    filter: grayscale(0.4);
  };
`;
const ContainerShine = styled.div`
  position: relative;
  overflow: hidden;
  :hover {
    filter: grayscale(0.4);
  };
`;
const rotate = keyframes`
  0% {
    transform: skewX(0deg) translateX(0px);
  }
  90% {
    transform: skewX(0deg) translateX(0px);
  }
  100% {
    transform: skewX(20deg) translateX(200px);
  }
`;
const Shine = styled.div`
  position: absolute;
  top: 0;
  left: -80px;
  height: 100px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(20deg) translateX(0);
  animation: ${rotate} 10s linear infinite;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  color: #5f5f5f;
  align-self: flex-end;
  outline: none;
  padding-bottom: 0;
  line-height: 0.5;
  cursor: pointer;
  &:hover{
    color: black;
  };
`;

class InfoDashboard extends PureComponent {
  static propTypes = {
    content: PropTypes.string,
    disabled: PropTypes.bool,
    ticket: PropTypes.string,
  };

  static defaultProps = {
    content: 'Інформація',
    disabled: false,
    ticket: 'Не авторизовано',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = state.bodyOverflow;
    }
    return { ...state, isVisible: state.isVisible };
  }

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      bodyOverflow: document.body.style.overflow,
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = this.state.bodyOverflow;
  }

  render() {
    const {
      content,
      disabled,
      ticket,
    } = this.props;
    const {
      isVisible,
    } = this.state;

    return (
      <div>
        <ButtonInfo
          onClick={() => this.setState({ isVisible: !isVisible })}
          disabled={disabled}
          title={content}
        >
          <InfoIcon />
        </ButtonInfo>
        {isVisible && createPortal(
          (
            <BoxInfo>
              <div style={{ display: 'grid', justifyContent: 'end' }}>
                <CloseButton
                  onClick={() => this.setState({ isVisible: !isVisible })}
                >
                  x
                </CloseButton>
              </div>
              <ContainerLogoLink href="https://ciat.net.ua/uk/" target="blank">
                <ContainerShine>
                  <LogoImg src={LogoCiatIcon} alt="ЦІАТ" title="ЦІАТ" />
                  <Shine />
                </ContainerShine>
                <TextCompany>
                  Центр інформаційних та аналітичних технологій
                </TextCompany>
              </ContainerLogoLink>
              <ContainerInfo>
                <img src={PhoneIcon} width="20px" alt="Телефон" title="Телефон" />
                <InfoText>
                  (044) 364-07-87,
                  <br />
                  (044) 364-07-84;
                </InfoText>
                <img src={FaxIcon} width="20px" alt="Факс" title="Факс" />
                <InfoText>(044) 424-04-34;</InfoText>
                <img src={MobilePhoneIcon} width="20px" alt="Мобільний" title="Мобільний" />
                <InfoText>
                  (067) 825-43-71,
                  <br />
                  (067) 825-43-72,
                  <br />
                  (066) 382-19-98,
                  <br />
                  (050) 858-07-59;
                </InfoText>
                <img src={EmailIcon} width="20px" alt="e-mail" title="e-mail" />
                <InfoText>
                  buh@ciat.net.ua (бухгалтерія),
                  <br />
                  support@ciat.net.ua (підтримка)
                </InfoText>
                {/* <span style={{ fontSize: '10px' }}> */}
                {/*  {Major} */}
                {/*  . */}
                {/*  {Minor} */}
                {/*  . */}
                {/*  {branch} */}
                {/*  . */}
                {/*  {commitId} */}
                {/*  <br /> */}
                {/*  {commitDate} */}
                {/* </span> */}
              </ContainerInfo>
              <ButtonInfo
                title="Надіслати технічну інформацію до служби підтримки"
                onClick={async () => {
                  await api.post$('logica/save_user_data/', { ticket });
                  this.setState({ isVisible: !isVisible });
                }}
              >
                <FontAwesomeIcon className="me-1" style={{ color: '#386EAB' }} icon={faShareFromSquare} />
                Надіслати технічну інформацію
              </ButtonInfo>
            </BoxInfo>
          ), document.body,
        )}
      </div>
    );
  }
}

export default InfoDashboard;
