import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  document: { label: 'Документ', name: 'Документ', visible: false },
  periodicity: {
    label: 'Перiодичнiсть',
    name: 'Периодичность',
    visible: true,
  },
  numberFormat: {
    label: 'ФорматНомера',
    name: 'ФорматНомера',
    visible: false,
  },
  objectRepresentation: {
    label: 'Документ',
    name: 'ПредставлениеОбъекта',
    visible: true,
  },
  forAllOthers: {
    label: 'Для всіх інших',
    name: 'ДляВсехДругих',
    visible: true,
  },
  treasuryAccount: {
    label: 'Казначейський рахунок',
    name: 'КазнСчет',
    visible: true,
  },
  owner: { label: 'Органiзацiя', name: 'Владелец', visible: true },
};

const tables = {
  general: {
    name: 'Пользователи',
    label: 'Користувачі',
    columns: {
      users: { label: 'Користувач', name: 'Пользователь' },
      nam: { label: '№', name: 'НомерСтроки' },
    },
  },
};

export const numbering = {
  label: 'Нумератори',
  name: 'numbering',
  backendName: 'Нумераторы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/numbering',
  listColumns: [...(new Set([...reqListerColumns, 'owner', 'objectRepresentation', 'forAllOthers', 'periodicity', 'treasuryAccount']))],
  tables,
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default numbering;
