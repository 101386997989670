import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  budget: foreignPropType,
  amount_total: PropTypes.number,
  nls: PropTypes.number,
  ostf: PropTypes.number,
  socred: PropTypes.number,
  sodeb: PropTypes.number,
  oste: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
