import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Sort: {
    label: 'Код сортування',
    name: 'Сортировка',
    visible: true,
  },
  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const typesOfLaborCosts = {
  label: 'Види витрат на оплату праці',
  name: 'typesOfLaborCosts',
  backendName: 'ВидыЗатратНаОплатуТруда',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/typesOfLaborCosts',
  listColumns: [...(new Set([...reqListerColumns, 'Sort']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default typesOfLaborCosts;
