import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { appearanceTypes } from '../constants';
import ARow from './row';
import { StyledTable } from '../../styled';

function ATable({ value, onChange }) {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th width={40}>&nbsp;</th>
          <th>Параметр</th>
          <th>Значення</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(appearanceTypes).map((at) => (
          <ARow
            key={at}
            value={value.get(at, new Map())}
            onChange={(e, v) => onChange(e, value.set(at, v))}
            label={appearanceTypes[at].label}
            type={appearanceTypes[at].type}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}

ATable.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(ATable);
