import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  AllOthers: {
    label: 'Інші видатки (5000)',
    name: 'ВсеДругие',
    visible: true,
    hint: 'Належать до "Іншиих видвтків" скорочених КЕКВ',
  },
  AllowNegativeBalance: {
    label:
        "Дозволити від'ємний залишок (для кредитування, в планах/змінах)",
    name: 'РазрешитьОтрицательныеОстатки',
    visible: false,
  },
  OnDate: { label: 'Код на дату', name: 'тКод', visible: true },
  KEKVType: {
    label: 'Асигнування/Кредитування',
    name: 'ВидСредств',
    visible: true,
  },
  DateKl: {
    label: 'Дата',
    name: 'ДатаКл',
    visible: true,
  },
  FullName: {
    label: 'Повна назва',
    name: 'ПолнНаименование',
    visible: true,
  },
  ListNamePeriod: {
    label: 'СписокНаименованияПериод',
    name: 'СписокНаименованияПериод',
    visible: true,
  },
  ListNameClassifier: {
    label: 'СписокНаименованияКлассификатор',
    name: 'СписокНаименованияКлассификатор',
    visible: true,
  },
  ListNameFullName: {
    label: 'СписокНаименованияНаименованиеПолное',
    name: 'СписокНаименованияНаименованиеПолное',
    visible: true,
  },
  ListNameCode: {
    label: 'СписокНаименованияКод',
    name: 'СписокНаименованияКод',
    visible: true,
  },
  ListNameInactive: {
    label: 'СписокНаименованияНеДействующий',
    name: 'СписокНаименованияНеДействующий',
    visible: true,
  },
};

export const kekvClassifier = {
  label: 'Класифікація економічна (КЕКВ)',
  name: 'kekvClassifier',
  backendName: 'КлассификаторКЕКВ',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/kekvClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'KEKVType',
      'AllOthers',
      'FullName',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kekvClassifier;
