const frontendURL = '/doc/passport/';
const backendURL = '/api/budgetrequest/passportbpheader/';
const name = 'Паспорт бюджетної програми';

const instance = {
  frontendURL,
  backendURL,
  name,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};

export default instance;
