import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, ButtonGroup, Modal, Nav, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretLeft, faCaretRight, faCheck, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import classNames from 'classnames';
import WinManagerContext from '../../minfin/providers/WinManagerContext/context';

const LinksContainer = styled.footer`
  //height: var(--windows-menu-line-height);
  overflow-y: hidden;
`;
function ManagerLink() {
  const paramWimM = useContext(WinManagerContext);

  const {
    dellComponentFromWindowsManager,
    linkComponents,
    currentURL,
    nextWindow,
    prevWindow,
    closeWarnings,
    windowsHint,
  } = paramWimM;
  const [modalUrl, setModalUrl] = useState(null);

  const linkComp = linkComponents || [];

  return (
    <footer
      className="d-flex flex-fill overflow-hidden "
    >
      <ButtonGroup size="sm" className="rounded-0">
        <Button
          variant="outline-secondary"
          onClick={() => prevWindow()}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
        <Button
          variant="outline-secondary"
          onClick={() => nextWindow()}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </Button>
      </ButtonGroup>
      <Nav className="flex-grow-1 flex-nowrap overflow-hidden" variant="tabs" fill>
        {
          linkComp.map(({ title, url }) => (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={windowsHint[url] ? (
                <Popover id={`tooltip-${url}`}>
                  <Popover.Header as="h3">{windowsHint[url]?.header}</Popover.Header>
                  <Popover.Body>
                    {windowsHint[url]?.body}
                  </Popover.Body>
                </Popover>
              )
                : (
                  <Popover id={`tooltip-${url}`}>
                    <Popover.Header as="h3">{title}</Popover.Header>
                  </Popover>
                )}
            >
              <Nav.Item
                key={`link-key-${url}`}
                className="overflow-hidden border-end "
              >
                <Nav.Link
                  as={Link}
                  to={url}
                  active={url === currentURL}
                  className={classNames('d-flex flex-nowrap py-1')}
                  title={windowsHint[url]}
                >
                  <span className="flex-grow-1 text-nowrap overflow-hidden">
                    {title}
                  </span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={(e) => {
                      e.preventDefault();
                      if (closeWarnings[url]) setModalUrl(url);
                      else dellComponentFromWindowsManager(url);
                    }}
                    size="sm"
                    className="text-muted ms-1"
                  />
                </Nav.Link>
              </Nav.Item>
            </OverlayTrigger>
          ))
        }
      </Nav>
      <Modal show={!!modalUrl} onHide={() => setModalUrl(null)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Закриття вікна
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Мабуть Ви забулися зберегти зміни. Продовжити?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-danger"
            onClick={() => {
              dellComponentFromWindowsManager(modalUrl);
              setModalUrl(null);
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="me-1" />
            Так
          </Button>
          <Button variant="falcon-success" onClick={() => setModalUrl(null)}>
            <FontAwesomeIcon icon={faTimes} className="me-1" />
            Ні
          </Button>
        </Modal.Footer>
      </Modal>
    </footer>
  );
}
//
export default ManagerLink;
