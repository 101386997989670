import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function Assignment({
  data, ...rest
}) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'assignment_text',
        },
      ],
    }),
    [],
  );
  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbpassignmentrow_set"
    />
  );
}

Assignment.propTypes = {
  data: PropTypes.shape({
    budget: foreignPropType,
  }).isRequired,
};

export default Assignment;
