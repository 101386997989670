import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col md={4}>
        <Row noGutters>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'kvk'}
              highlighted={highlights.includes('kvk')}
              onToggleHighlight={() => onToggleHighlight('kvk')}
            >
              {fields.kvk.label}
            </TableHeaderCell>
          </Col>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'krk'}
              highlighted={highlights.includes('krk')}
              onToggleHighlight={() => onToggleHighlight('krk')}
            >
              {fields.krk.label}
            </TableHeaderCell>
          </Col>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'kpk'}
              highlighted={highlights.includes('kpk')}
              onToggleHighlight={() => onToggleHighlight('kpk')}
            >
              {fields.krk.label}
            </TableHeaderCell>
          </Col>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'kekv'}
              highlighted={highlights.includes('kekv')}
              onToggleHighlight={() => onToggleHighlight('kekv')}
            >
              {fields.krk.label}
            </TableHeaderCell>
          </Col>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'tr'}
              highlighted={highlights.includes('tr')}
              onToggleHighlight={() => onToggleHighlight('tr')}
            >
              {fields.tr.label}
            </TableHeaderCell>
          </Col>
          <Col md={2}>
            <TableHeaderCell
              active={activeCol === 'kf'}
              highlighted={highlights.includes('kf')}
              onToggleHighlight={() => onToggleHighlight('kf')}
            >
              {fields.kf.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col md={8}>
        <Row noGutters md={8}>
          <Col>
            <TableHeaderCell
              active={activeCol === 'kp'}
              highlighted={highlights.includes('kp')}
              onToggleHighlight={() => onToggleHighlight('kp')}
            >
              {fields.kp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'vk'}
              highlighted={highlights.includes('vk')}
              onToggleHighlight={() => onToggleHighlight('vk')}
            >
              {fields.vk.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'vknp'}
              highlighted={highlights.includes('vknp')}
              onToggleHighlight={() => onToggleHighlight('vknp')}
            >
              {fields.vknp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'zknr'}
              highlighted={highlights.includes('zknr')}
              onToggleHighlight={() => onToggleHighlight('zknr')}
            >
              {fields.zknr.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'kv'}
              highlighted={highlights.includes('kv')}
              onToggleHighlight={() => onToggleHighlight('kv')}
            >
              {fields.kv.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'kvnp'}
              highlighted={highlights.includes('kvnp')}
              onToggleHighlight={() => onToggleHighlight('kvnp')}
            >
              {fields.kvnp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'zkrr'}
              highlighted={highlights.includes('zkrr')}
              onToggleHighlight={() => onToggleHighlight('zkrr')}
            >
              {fields.zkrr.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              active={activeCol === 'zfz'}
              highlighted={highlights.includes('zfz')}
              onToggleHighlight={() => onToggleHighlight('zfz')}
            >
              {fields.zfz.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
