import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/freeRem';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'form4';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sum.name;
      const colName2 = tableMD.columns.sumT.name;
      const colName3 = tableMD.columns.sumRasp.name;
      const colName4 = tableMD.columns.sumRaspSubv.name;

      const totColName = tableMD.columns.sumUnused.name;
      const totColName2 = tableMD.columns.prRasp.name;
      const totColName3 = tableMD.columns.prRaspAll.name;

      const tot = value - (row[colName3] || 0) - (row[colName4] || 0);
      const tot2 = ((row[colName3] || 0) / (value - (row[colName2] || 0))) * 100;
      const tot3 = (((row[colName3] || 0) + (row[colName4] || 0)) / value) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
      });
    },
    [onRowChange, row],
  );

  const onSumTChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumT.name;
      const colName2 = tableMD.columns.sum.name;
      const colName3 = tableMD.columns.sumRasp.name;

      const totColName2 = tableMD.columns.prRasp.name;

      const tot2 = ((row[colName3] || 0) / ((row[colName2] || 0) - value)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName2]: tot2,
      });
    },
    [onRowChange, row],
  );

  const onSumRaspSubvChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumRaspSubv.name;
      const colName2 = tableMD.columns.sumRasp.name;
      const colName3 = tableMD.columns.sum.name;

      const totColName = tableMD.columns.sumUnused.name;
      const totColName2 = tableMD.columns.prRaspAll.name;

      const tot = (row[colName3] || 0) - (row[colName2] || 0) - value;
      const tot2 = (((row[colName2] || 0) + value) / (row[colName3] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
      });
    },
    [onRowChange, row],
  );

  const onSumWorkChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumWork.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      // const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = (value / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumComChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumCom.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      // const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = (value / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumMedChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumMed.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      // const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = (value / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumProdChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumProd.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      // const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = (value / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumPChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumP.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      // const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = (value / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumOtherChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumOther.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      // const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = (value / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumOsvSubvChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumOsvSubv.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      // const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = (value / tot) * 100;
      const tot8 = ((row[ColName8] || 0) / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  const onSumMedSubvChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumMedSubv.name;

      const totColName = tableMD.columns.sumRasp.name;
      const totColName2 = tableMD.columns.prWork.name;
      const totColName3 = tableMD.columns.prCom.name;
      const totColName4 = tableMD.columns.prMed.name;
      const totColName5 = tableMD.columns.prProd.name;
      const totColName6 = tableMD.columns.prP.name;
      const totColName7 = tableMD.columns.prOsvSubv.name;
      const totColName8 = tableMD.columns.prMedSubv.name;
      const totColName9 = tableMD.columns.prOther.name;
      const ColName2 = tableMD.columns.sumWork.name;
      const ColName3 = tableMD.columns.sumCom.name;
      const ColName4 = tableMD.columns.sumMed.name;
      const ColName5 = tableMD.columns.sumProd.name;
      const ColName6 = tableMD.columns.sumP.name;
      const ColName7 = tableMD.columns.sumOsvSubv.name;
      const ColName8 = tableMD.columns.sumMedSubv.name;
      const ColName9 = tableMD.columns.sumOther.name;

      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      const tot2 = ((row[ColName2] || 0) / tot) * 100;
      const tot3 = ((row[ColName3] || 0) / tot) * 100;
      const tot4 = ((row[ColName4] || 0) / tot) * 100;
      const tot5 = ((row[ColName5] || 0) / tot) * 100;
      const tot6 = ((row[ColName6] || 0) / tot) * 100;
      const tot7 = ((row[ColName7] || 0) / tot) * 100;
      const tot8 = (value / tot) * 100;
      const tot9 = ((row[ColName9] || 0) / tot) * 100;

      const totColName10 = tableMD.columns.sumUnused.name;
      const totColName11 = tableMD.columns.prRasp.name;
      const totColName12 = tableMD.columns.prRaspAll.name;
      const ColName10 = tableMD.columns.sum.name;
      const ColName11 = tableMD.columns.sumRaspSubv.name;
      const ColName12 = tableMD.columns.sumT.name;
      const tot10 = (row[ColName10] || 0) - tot - (row[ColName11] || 0);
      const tot11 = (tot / ((row[ColName10] || 0) - (row[ColName12] || 0))) * 100;
      const tot12 = ((tot + (row[ColName11] || 0)) / (row[ColName10] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
        [totColName4]: tot4,
        [totColName5]: tot5,
        [totColName6]: tot6,
        [totColName7]: tot7,
        [totColName8]: tot8,
        [totColName9]: tot9,
        [totColName10]: tot10,
        [totColName11]: tot11,
        [totColName12]: tot12,
      });
    },
    [onRowChange, row],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="budget" highlighted={highlights.includes('budget')}>
              <ItemPicker
                value={row[tableMD.columns.budget.name]}
                modelType="cat"
                modelName="budgetTransfer"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.budget.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumT" highlighted={highlights.includes('sumT')}>
              <NumberInput
                value={row[tableMD.columns.sumT.name]}
                precision={2}
                onChange={(e, v) => onSumTChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumRasp" highlighted={highlights.includes('sumRasp')}>
              <NumberInput
                value={row[tableMD.columns.sumRasp.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumRasp.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumWork" highlighted={highlights.includes('sumWork')}>
              <NumberInput
                value={row[tableMD.columns.sumWork.name]}
                precision={2}
                onChange={(e, v) => onSumWorkChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prWork" highlighted={highlights.includes('prWork')}>
              <NumberInput
                value={row[tableMD.columns.prWork.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prWork.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumCom" highlighted={highlights.includes('sumCom')}>
              <NumberInput
                value={row[tableMD.columns.sumCom.name]}
                precision={2}
                onChange={(e, v) => onSumComChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prCom" highlighted={highlights.includes('prCom')}>
              <NumberInput
                value={row[tableMD.columns.prCom.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prCom.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumMed" highlighted={highlights.includes('sumMed')}>
              <NumberInput
                value={row[tableMD.columns.sumMed.name]}
                precision={2}
                onChange={(e, v) => onSumMedChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prMed" highlighted={highlights.includes('prMed')}>
              <NumberInput
                value={row[tableMD.columns.prMed.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prMed.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumProd" highlighted={highlights.includes('sumProd')}>
              <NumberInput
                value={row[tableMD.columns.sumProd.name]}
                precision={2}
                onChange={(e, v) => onSumProdChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prProd" highlighted={highlights.includes('prProd')}>
              <NumberInput
                value={row[tableMD.columns.prProd.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prProd.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumP" highlighted={highlights.includes('sumP')}>
              <NumberInput
                value={row[tableMD.columns.sumP.name]}
                precision={2}
                onChange={(e, v) => onSumPChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prP" highlighted={highlights.includes('prP')}>
              <NumberInput
                value={row[tableMD.columns.prP.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prP.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumOsvSubv" highlighted={highlights.includes('sumOsvSubv')}>
              <NumberInput
                value={row[tableMD.columns.sumOsvSubv.name]}
                precision={2}
                onChange={(e, v) => onSumOsvSubvChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prOsvSubv" highlighted={highlights.includes('prOsvSubv')}>
              <NumberInput
                value={row[tableMD.columns.prOsvSubv.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prOsvSubv.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumMedSubv" highlighted={highlights.includes('sumMedSubv')}>
              <NumberInput
                value={row[tableMD.columns.sumMedSubv.name]}
                precision={2}
                onChange={(e, v) => onSumMedSubvChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prMedSubv" highlighted={highlights.includes('prMedSubv')}>
              <NumberInput
                value={row[tableMD.columns.prMedSubv.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prMedSubv.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumOther" highlighted={highlights.includes('sumOther')}>
              <NumberInput
                value={row[tableMD.columns.sumOther.name]}
                precision={2}
                onChange={(e, v) => onSumOtherChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prOther" highlighted={highlights.includes('prOther')}>
              <NumberInput
                value={row[tableMD.columns.prOther.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prOther.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumRaspSubv" highlighted={highlights.includes('sumRaspSubv')}>
              <NumberInput
                value={row[tableMD.columns.sumRaspSubv.name]}
                precision={2}
                onChange={(e, v) => onSumRaspSubvChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumUnused" highlighted={highlights.includes('sumUnused')}>
              <NumberInput
                value={row[tableMD.columns.sumUnused.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumUnused.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="prRaspAll" highlighted={highlights.includes('prRaspAll')}>
              <NumberInput
                value={row[tableMD.columns.prRaspAll.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prRaspAll.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="prRasp" highlighted={highlights.includes('prRasp')}>
              <NumberInput
                value={row[tableMD.columns.prRasp.name]}
                precision={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prRasp.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.sumT.name]: PropTypes.number,
    [tableMD.columns.sumRasp.name]: PropTypes.number,
    [tableMD.columns.sumWork.name]: PropTypes.number,
    [tableMD.columns.prWork.name]: PropTypes.number,
    [tableMD.columns.sumCom.name]: PropTypes.number,
    [tableMD.columns.prCom.name]: PropTypes.number,
    [tableMD.columns.sumMed.name]: PropTypes.number,
    [tableMD.columns.prMed.name]: PropTypes.number,
    [tableMD.columns.sumProd.name]: PropTypes.number,
    [tableMD.columns.prProd.name]: PropTypes.number,
    [tableMD.columns.sumP.name]: PropTypes.number,
    [tableMD.columns.prP.name]: PropTypes.number,
    [tableMD.columns.sumOsvSubv.name]: PropTypes.number,
    [tableMD.columns.prOsvSubv.name]: PropTypes.number,
    [tableMD.columns.sumMedSubv.name]: PropTypes.number,
    [tableMD.columns.prMedSubv.name]: PropTypes.number,
    [tableMD.columns.sumOther.name]: PropTypes.number,
    [tableMD.columns.prOther.name]: PropTypes.number,
    [tableMD.columns.sumUnused.name]: PropTypes.number,
    [tableMD.columns.prRasp.name]: PropTypes.number,
    [tableMD.columns.prRaspAll.name]: PropTypes.number,
    [tableMD.columns.sumRaspSubv.name]: PropTypes.number,
    [tableMD.columns.budget.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
