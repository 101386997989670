import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { CiatAppContext } from '../../../providers';
import api from '../../../api/req';

const useFilter = ({
  apiUrl, defaultFilter, overrideFilter, filter = true,
}) => {
  const { auth } = useContext(CiatAppContext);

  const [error, setError] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loader = async () => {
      const r = await api.options(apiUrl, auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      return r.json();
    };
    loader().then((o) => setOptions(o));
  }, [apiUrl, auth]);

  // genarate filter names
  const filteringFields = useMemo(() => {
    if (!options) return [];
    const fields = options.actions.GET;
    return options.filtering_fields.map((ff) => {
      const field = fields[ff.name] || {};
      return {
        name: ff.name,
        type: field.type,
        resource: field.resource,
        label: ff.label || field.label,
      };
    });
  }, [options]);

  // genarate fields
  const fields = useMemo(() => {
    if (!options) return {};
    return options.actions.GET;
  }, [options]);

  // genarate filter values
  const [filters, setFilters] = useState(
    () => {
      const dd = { ...defaultFilter, ...overrideFilter };
      const ffs = Object.keys(dd)
        .reduce((R, ff) => ({ ...R, [ff]: { use: true, value: dd[ff] } }), {});

      return filteringFields
        .filter((ff) => !(ff.name in ffs))
        .reduce((R, ff) => ({
          ...R,
          [ff.name]: {
            value: null,
            use: false,
          },
        }), ffs);
    },
  );

  const [items, setItems] = useState([]);

  // genarate query params
  const qf = useMemo(
    () => Object.keys(filters)
      .filter((f) => (f in filters) && filters[f].use)
      .reduce((R, f) => {
        const v = filters[f].value;
        let vv;
        if (v && v.id) {
          vv = v.id;
        } else {
          vv = v;
        }
        return {
          ...R,
          [f]: vv,
        };
      }, {}),
    [filters],
  );

  // loading items
  useEffect(
    () => {
      if (options && filter) {
        const loader = async () => {
          const r = await api.get(apiUrl, auth, qf);
          if (!r.ok) {
            throw new Error(`${r.status} ${r.statusText}`);
          }
          return r.json();
        };
        setLoading(true);
        setError(null);
        loader()
          .then((d) => {
            const itms = 'results' in d ? d.results : d;
            setItems(itms.map((item, i) => ({ id: i + 1, ...item })));
          })
          .catch((e) => setError(e.message))
          .finally(() => setLoading(false));
      }
    },
    [apiUrl, auth, filter, options, qf],
  );

  const setFilterHandler = useCallback((name, value, use) => (
    !(name in overrideFilter)) && setFilters({
    ...filters,
    [name]: {
      value,
      use,
    },
  }), [filters, overrideFilter]);
  const displayFilters = useMemo(
    () => filteringFields
      .filter((ff) => !(ff.name in overrideFilter)),
    [filteringFields, overrideFilter],
  );

  return {
    error,
    filters,
    filteringFields: displayFilters,
    setFilterHandler,
    items,
    fields,
    loading,
    ready: !!options,
  };
};

export default useFilter;
