import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnBanDoc = {
  label: 'Заборона на внесення змін, щодо структури закладів дошкільної освіти',
  name: 'bfnBanDoc',
  backendName: 'refbandocbfn',
  defaultOrder,
  columns,
  frontend: 'cat/bfnBanDoc',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnBanDoc;
