import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { CiatAppContext, LogicaContext } from '../../../providers';
import { dateToUpload, getDateStr } from './consts';
import api from '../../../api/req';
import md from '../../../meta/srv/ImportTreasureFiles';
import checkTask from '../../../api/checktask';
import DKSUToast from './DKSUToast';

const useDKSUData = () => {
  const logicaPingSuccess = true;

  const [dataType, setDataType] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [res, setRes] = useState(null);
  const [datesRes, setDatesRes] = useState(null);

  const { auth } = useContext(CiatAppContext);
  const { ticket } = useContext(LogicaContext);

  const getDKSUData = useCallback(
    () => {
      const loader = async () => {
        const datetimes = [dateToUpload(date)];
        const params = {
          datetimes,
          attachedBudgets: true,
          ticket,
          year: year || new Date().getFullYear(),
        };

        if (dataType !== 'Всі') {
          params.datatype = dataType;
        }

        const r = await api.post(md.backendURL, auth, params);

        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        // eslint-disable-next-line camelcase
        const { task_id } = await r.json();
        // eslint-disable-next-line camelcase
        return task_id;
      };

      setLoading(true);
      setErr(null);
      setRes(null);
      loader()
        .then((taskId) => checkTask(taskId, auth))
        .then((r) => {
          if (r.result.errors) throw new Error(r.result.errors[0]);
          setRes('Дані завантажено успішно!');
        })
        .catch((e) => {
          setErr(e.message);
        })
        .finally(() => setLoading(false));
    },
    [auth, dataType, date, ticket, year],
  );

  const getDKSUDates = useCallback(() => {
    const loader = async () => {
      const response = await api.get(md.backendURL, auth, {
        ticket,
        year,
      });
      if (!response.ok) {
        if (response.status === 400) {
          const errData = await response.json();
          if (Array.isArray(errData)) throw new Error(errData.join(', '));
          throw new Error(Object.keys(errData).reduce((R, ek) => `${R} ${ek}: ${errData[ek].join(', ')}`, ''));
        }
        throw new Error(`${response.status} ${response.statusText}`);
      }
      return response.json();
    };
    setLoading(true);
    setErr(null);
    setRes(null);
    loader()
      .then((data) => {
        setDatesRes(data);
      })
      .catch((e) => {
        setErr(e.message);
      })
      .finally(() => setLoading(false));
  }, [auth, ticket, year]);

  const getCurrentDKSUData = useCallback(() => {
    const loader = async () => {
      const params = {
        ticket,
        year: year || new Date().getFullYear(),
      };

      const r = await api.post(md.backendURL, auth, params);

      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      // eslint-disable-next-line camelcase
      const { task_id } = await r.json();
      // eslint-disable-next-line camelcase
      return task_id;
    };

    setLoading(true);
    setErr(null);
    setRes(null);
    loader()
      .then((d) => checkTask(d, auth))
      .then((r) => {
        if (r.result.errors) throw new Error(r.result.errors[0]);
        setRes('Дані завантажено успішно!');
      })
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, ticket, year]);

  const datesByType = useMemo(
    () => {
      if (!datesRes) return {};
      const datatypes = Object.keys(datesRes.current_date);
      const allDates = [...new Set(
        datatypes.reduce((R, dt) => [...R, ...datesRes[dt].map((dr) => dr.datefile)], []),
      )].sort().reverse();
      const f = (data) => ({
        value: getDateStr(data),
        display_name: getDateStr(data),
      });
      return {
        Всі: allDates.map(f),
        ...(datatypes.reduce(
          (R, dt) => ({
            ...R,
            [dt]: datesRes[dt].reverse().map((dr) => f(dr.datefile)),
          }),
          {},
        )),
      };
    },
    [datesRes],
  );

  const lastDownload = (datesRes && dataType && (getDateStr(datesRes.current_date[dataType]) || '-'));

  return {
    logicaPingSuccess,
    loading,
    err,
    setErr,
    year,
    setYear,
    res,
    setRes,
    dataType,
    setDataType,
    date,
    setDate,
    //
    getCurrentDKSUData,
    getDKSUDates,
    datesRes,
    getDKSUData,
    datesByType,
    lastDownload,
  };
};

const TOAST_ID = 'DKSU_TOAST_ID';
export const useDKSUChecker = () => {
  const year = new Date().getFullYear();
  const { currentUser, auth } = useContext(CiatAppContext);
  const { ticket } = useContext(LogicaContext);
  const timerId = useRef(null);
  const apiChecker = useCallback(
    async () => {
      if (ticket && !currentUser.is_superuser) {
        const response = await api.get(md.backendURL, auth, { ticket, year });
        if (response.ok) {
          const data = await response.json();
          const news = Object.keys(data.current_date).filter((dt) => {
            const mainDate = new Date(data.current_date[dt]);
            const ns = data[dt].filter((d) => new Date(d.datefile) > mainDate);
            return !!ns.length;
          });
          return !!news.length;
        }
      }
      return false;
    },
    [auth, currentUser.is_superuser, ticket, year],
  );
  useEffect(
    () => {
      const checker = () => apiChecker().then((d) => {
        if (d) {
          toast.success(DKSUToast, {
            theme: 'colored',
            toastId: TOAST_ID,
            icon: () => (
              <FontAwesomeIcon icon={faMoneyBillTransfer} size="xl" />
            ),
          });
        }
        timerId.current = setTimeout(checker, 10 * 60 * 1000); // 10 минут
      });
      checker();
      return () => clearTimeout(timerId.current);
    },
    [apiChecker],
  );
};

export default useDKSUData;
