import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DocList from './docs';
import DocDetails from './docDetails';
import { fieldsPropTypes } from '../dataPropTypes';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

function TableList({
  items, selectedItems, fields, setSelectedItems, docName, mode,
}) {
  const docs = useMemo(
    () => {
      const docIds = new Set(items.map((d) => d[docName].id));
      return [...docIds].map((dId) => {
        const docItems = items.filter((item) => item[docName].id === dId);
        return {
          id: docItems[0][docName].id,
          repr: docItems[0][docName].repr,
          use: docItems.map((row) => row.id).some((id) => selectedItems.includes(id)),
        };
      });
    },
    [docName, items, selectedItems],
  );

  const [activeDoc, setActiveDoc] = useState(null);

  const onToggleDoc = useCallback(
    (docId) => {
      const docLines = items.filter((item) => item[docName].id === docId).map((item) => item.id);
      if (docs.filter((d) => d.use).map((d) => d.id).includes(docId)) {
        setSelectedItems((s) => s.filter((id) => !docLines.includes(id)));
      } else {
        setSelectedItems((s) => [...s, ...docLines]);
      }
    },
    [docName, docs, items, setSelectedItems],
  );

  const docDetails = useMemo(
    () => items.filter((item) => item[docName].id === activeDoc),
    [activeDoc, docName, items],
  );

  return (
    <div className="p-1 border bg-light flex-fill overflow-hidden">
      <Row className="h-100 overflow-hidden">
        <Col className="h-100 overflow-hidden">
          <DocList
            docs={docs}
            onSelectDoc={setActiveDoc}
            onToggleDoc={onToggleDoc}
            activeDoc={activeDoc}
          />
        </Col>
        <Col sm={8} className="h-100 overflow-hidden">
          <DocDetails
            rows={docDetails}
            fields={fields}
            mode={mode}
          />
        </Col>
      </Row>
    </div>
  );
}

TableList.propTypes = {
  fields: fieldsPropTypes.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    header: foreignPropType,
    finrequest: foreignPropType,
    finproposal: foreignPropType,
  })).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  docName: PropTypes.oneOf(['header', 'finrequest', 'finproposal']).isRequired,
  mode: PropTypes.oneOf(['finrequest', 'finorder']).isRequired,
};

export default TableList;
