import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
  StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/paymentTransfer';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'docData';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="num" highlighted={highlights.includes('num')}>
              <NumberInput
                value={row[tableMD.columns.num.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.num.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <Col className="border-right">
              <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKEKV.name]}
                  modelType="cat"
                  modelName="elementKEKV"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.elementKEKV.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <Col className="border-right">
              <TableRowCell column="elementKDB" highlighted={highlights.includes('elementKDB')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKDB.name]}
                  modelType="cat"
                  modelName="elementKDB"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.elementKDB.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="notes" highlighted={highlights.includes('notes')}>
              <StringInput
                value={row[tableMD.columns.notes.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.notes.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.num.name]: PropTypes.number,
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.notes.name]: PropTypes.string,
    [tableMD.columns.elementKEKV.name]: referencePropType,
    [tableMD.columns.elementKDB.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
