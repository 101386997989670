import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { ArrowUpButton } from '../../../components/bootStrap/buttons';
import TPCommandPanel from './commandPanel';

function TPExpandableCommandPanel({
  canModified, onAdd, onCopy, onDelete, selectedRows, children, onFillIn,
}) {
  return (
    <Navbar
      expand
      style={{
        padding: '0', position: 'sticky', zIndex: 1, top: '-10px', backgroundColor: 'white',
      }}
    >
      <TPCommandPanel
        canModified={canModified}
        onAdd={onAdd}
        onCopy={onCopy}
        onFillIn={onFillIn}
        onDelete={onDelete}
        selectedRows={selectedRows}
      >
        {children}
      </TPCommandPanel>
      <Navbar.Collapse className="justify-content-end" style={{ marginBottom: '10px' }}>
        {/* eslint-disable-next-line no-return-assign */}
        <ArrowUpButton onClick={() => document.getElementById('docBody').scrollTop = 0} />
      </Navbar.Collapse>

    </Navbar>
  );
}

TPExpandableCommandPanel.propTypes = {
  canModified: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onFillIn: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.number),
};

TPExpandableCommandPanel.defaultProps = {
  canModified: true,
  children: null,
  selectedRows: [],
};

export default TPExpandableCommandPanel;
