import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableListerCell from '../tablecell';
import { defaultColumns } from '../../../../../constants/meta/common';
import HierarchyTogglerCell from './hierarchyToggler';

function TableCatListerRow({
  row, columns, onFocus, selected, tabIndex, onContextMenu,
  permissions, actions, isOpened, className,
}) {
  const onClickHandler = useCallback(
    (e) => {
      onFocus(e, row.id);
    },
    [onFocus, row.id],
  );
  const clsName = useMemo(
    () => {
      let result = `catrow ${className}`;
      result += `${selected ? ' selected' : ''} `;
      result += `${row[defaultColumns.isDeleted.name] ? ' deleted' : ''} `;
      return result;
    },
    [className, row, selected],
  );
  const isFolder = row[defaultColumns.isGroup.name];
  const canBeOpened = isFolder || !permissions.foldersUsed;
  return (
    <tr
      data-id={row.id}
      onClick={onClickHandler}
      className={clsName}
      tabIndex={tabIndex}
      onDoubleClick={(e) => actions.onRowDoubleClick(e, row.id)}
    >
      {permissions.canHierarchy && (
        <HierarchyTogglerCell
          isFolder={isFolder}
          canBeOpened={canBeOpened}
          onClick={(e) => actions.onToggle(e, row.id)}
          opened={isOpened}
          level={row.level}
        />
      )}
      {columns.map((c) => (
        <TableListerCell
          key={c.key}
          value={row[c.name]}
          variants={c.variants}
          type={c.type}
          onContextMenu={(e) => onContextMenu(e, row.id)}
        />
      ))}
    </tr>

  );
}

TableCatListerRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'date', 'moneys', 'boolVariants', 'bool',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.number,
    [defaultColumns.isDeleted.name]: PropTypes.bool.isRequired,
    [defaultColumns.isGroup.name]: PropTypes.bool,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
    foldersUsed: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    enEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  isOpened: PropTypes.bool,
  className: PropTypes.string,
};

TableCatListerRow.defaultProps = {
  selected: false,
  isOpened: false,
  className: '',
};
export default memo(TableCatListerRow);
