import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { CiatAppContext } from '../../../../providers';
import api from '../../../../api/req';
import checkTask from '../../../../api/checktask';

const useFetchTransfertData = ({ cDt }) => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [transferProject, setTransferProject] = useState({
    zf_in: {
      total: 0,
      percent: 0,
    },
    zf_out: {
      total: 0,
      percent: 0,
    },
    transfer_in: [],
    transfer_out: [],

  });
  const { auth } = useContext(CiatAppContext);
  const fetchAllData = useCallback(() => {
    const loadTransferProject = async () => {
      const r = await api.get(`/api/dashboards/transfer_project/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    setErr(null);
    setIsLoading(true);

    Promise.all([
      loadTransferProject(),
    ])
      .then(([transferDataResult]) => {
        setTransferProject(transferDataResult.result);
      })
      .catch((error) => setErr(error.message))
      .finally(() => setIsLoading(false));
  }, [auth, cDt]);

  useEffect(
    () => {
      fetchAllData();
    },
    [fetchAllData],
  );
  return {
    isLoading, err, transferProject,
  };
};

export default useFetchTransfertData;
