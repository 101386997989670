import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL8;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG1S'}
              highlighted={highlights.includes('T8RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG1S')}
            >
              {meta.columns.T8RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG3'}
              highlighted={highlights.includes('T8RXXXXG3')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG3')}
            >
              {meta.columns.T8RXXXXG3.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG4'}
              highlighted={highlights.includes('T8RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG4')}
            >
              {meta.columns.T8RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG5'}
              highlighted={highlights.includes('T8RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG5')}
            >
              {meta.columns.T8RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG6'}
              highlighted={highlights.includes('T8RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG6')}
            >
              {meta.columns.T8RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG7'}
              highlighted={highlights.includes('T8RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG7')}
            >
              {meta.columns.T8RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG8'}
              highlighted={highlights.includes('T8RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG8')}
            >
              {meta.columns.T8RXXXXG8.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG9'}
              highlighted={highlights.includes('T8RXXXXG9')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG9')}
            >
              {meta.columns.T8RXXXXG9.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG10'}
              highlighted={highlights.includes('T8RXXXXG10')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG10')}
            >
              {meta.columns.T8RXXXXG10.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T8RXXXXG11'}
              highlighted={highlights.includes('T8RXXXXG11')}
              onToggleHighlght={() => onToggleHighlght('T8RXXXXG11')}
            >
              {meta.columns.T8RXXXXG11.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
