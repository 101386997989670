import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  Link: { label: 'Бюджетна програма', name: 'ЭлКФК' },
  clKFK: { label: 'Классификатор КФК', name: 'Классификатор' },
  clKPB: { label: 'Классификатор КПБ', name: 'КлассификаторКПБ' },
  clKPB7: { label: 'Классификатор КПБ7', name: 'КлассификаторКПБ7' },
  clKFKVK: { label: 'Классификатор КФКВК', name: 'КлассификаторКФКВК' },
  struct: { label: 'флСоздатьСтруктуру', name: 'флСоздатьСтруктуру' },
};

const tables = {
  csues: {
    name: 'Расп',
    label: 'Розпорядники',
    columns: {
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      check: { label: 'Відмітка', name: 'Выбран' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'omitElementKFK',
  backendName: 'ОпуститьЭлементКФК',
  frontend: 'dp/omitElementKFK',
  label: 'Створення бюджетних програм для розпорядників',
  columns,
  tables,
};

export default definition;
