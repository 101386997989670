import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  on3Years,
}) {
  const kdbProps = tableHooks.useItemInputProps('kdb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const firstYearZfProps = tableHooks.useNumberInputProps('first_year_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const secondYearZfProps = tableHooks.useNumberInputProps('second_year_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const thirdYearZfProps = tableHooks.useNumberInputProps('third_year_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const firstYearSfProps = tableHooks.useNumberInputProps('first_year_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const secondYearSfProps = tableHooks.useNumberInputProps('second_year_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const thirdYearSfProps = tableHooks.useNumberInputProps('third_year_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const firstYearBrProps = tableHooks.useNumberInputProps('first_year_br', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const secondYearBrProps = tableHooks.useNumberInputProps('second_year_br', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const thirdYearBrProps = tableHooks.useNumberInputProps('third_year_br', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const firstYearSumProps = tableHooks.useNumberInputProps('first_year_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const secondYearSumProps = tableHooks.useNumberInputProps('second_year_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const thirdYearSumProps = tableHooks.useNumberInputProps('third_year_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  firstYearSumProps.value = (firstYearZfProps.value || 0) + (firstYearSfProps.value || 0);
  secondYearSumProps.value = (secondYearZfProps.value || 0) + (secondYearSfProps.value || 0);
  thirdYearSumProps.value = (thirdYearZfProps.value || 0) + (thirdYearSfProps.value || 0);

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="treasure_date" highlighted={highlights.includes('treasure_date')}>
            <TableControls.ItemPicker {...kdbProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="first_year_zf" highlighted={highlights.includes('first_year_zf')}>
            <TableControls.NumberInput {...firstYearZfProps} />
          </TabelRowCell>
          {on3Years && (
          <>
            <TabelRowCell column="second_year_zf" highlighted={highlights.includes('second_year_zf')}>
              <TableControls.NumberInput {...secondYearZfProps} />
            </TabelRowCell>
            <TabelRowCell column="third_year_zf" highlighted={highlights.includes('third_year_zf')}>
              <TableControls.NumberInput {...thirdYearZfProps} />
            </TabelRowCell>
          </>
          )}
        </Col>
        <Col>
          <TabelRowCell column="first_year_sf" highlighted={highlights.includes('first_year_sf')}>
            <TableControls.NumberInput {...firstYearSfProps} />
          </TabelRowCell>
          {on3Years && (
          <>
            <TabelRowCell column="second_year_sf" highlighted={highlights.includes('second_year_sf')}>
              <TableControls.NumberInput {...secondYearSfProps} />
            </TabelRowCell>
            <TabelRowCell column="third_year_sf" highlighted={highlights.includes('third_year_sf')}>
              <TableControls.NumberInput {...thirdYearSfProps} />
            </TabelRowCell>
          </>
          )}
        </Col>
        <Col>
          <TabelRowCell column="first_year_br" highlighted={highlights.includes('first_year_br')}>
            <TableControls.NumberInput {...firstYearBrProps} />
          </TabelRowCell>
          {on3Years && (
          <>
            <TabelRowCell column="second_year_br" highlighted={highlights.includes('second_year_br')}>
              <TableControls.NumberInput {...secondYearBrProps} />
            </TabelRowCell>
            <TabelRowCell column="third_year_br" highlighted={highlights.includes('third_year_br')}>
              <TableControls.NumberInput {...thirdYearBrProps} />
            </TabelRowCell>
          </>
          )}
        </Col>
        <Col>
          <TabelRowCell column="first_year_total" highlighted={highlights.includes('first_year_total')}>
            <TableControls.NumberInput {...firstYearSumProps} />
          </TabelRowCell>
          {on3Years && (
            <>
              <TabelRowCell column="second_year_total" highlighted={highlights.includes('second_year_total')}>
                <TableControls.NumberInput {...secondYearSumProps} />
              </TabelRowCell>
              <TabelRowCell column="third_year_total" highlighted={highlights.includes('third_year_total')}>
                <TableControls.NumberInput {...thirdYearSumProps} />
              </TabelRowCell>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  on3Years: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  disposerInHeader: false,
  readOnly: false,
  on3Years: false,
  readOnlyFields: [],
};

export default memo(TRow);
