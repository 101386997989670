import React, { useMemo, memo } from 'react';
import PropTypes, { string } from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { AddRowButton, RemoveRowButton, FillInTheTableButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { fieldPropType } from '../hooks';
import FieldItem from './item';
import FieldProps from './props';

function FieldsPage({
  fields, activeField, onRenameField, onChangeField,
  onAddField, onRemoveField, onSetActiveField, tableFields, allowedCtypes, allowedFilters,
  allowedAggregates, onFillFields,
}) {
  const displayFields = useMemo(() => fields.map((t, itemKey) => ({
    ...t,
    itemKey,
  })), [fields]);
  const fValue = useMemo(
    () => fields.filter((t) => t.name === activeField).reduce((R, f) => f, {}),
    [activeField, fields],
  );
  const alowedTables = useMemo(
    () => Object.keys(tableFields).map((value) => ({ value, display_name: value })),
    [tableFields],
  );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({ value, display_name: value })),
    [allowedCtypes],
  );

  const displayAlowedAggregates = useMemo(
    () => allowedAggregates.map((value) => ({ value, display_name: value })),
    [allowedAggregates],
  );

  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3">
          <CommandPanel leftPart={(
            <>
              <AddRowButton
                title="Додати поле"
                content=""
                onClick={() => onAddField(Math.random().toString(36))}
              />
              <RemoveRowButton
                title="Видалити поле"
                content=""
                onClick={() => onRemoveField(activeField)}
                disabled={!activeField}
              />
              <FillInTheTableButton
                title="Заполнить поями таблиц"
                onClick={onFillFields}
              />
            </>
          )}
          />
          <ListGroup className="containerGroup">
            {displayFields.map((field) => (
              <FieldItem
                key={field.itemKey}
                active={field.name === activeField}
                onClick={() => onSetActiveField(field.name)}
                field={field}
                onRenameField={onRenameField}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <FieldProps
            onChange={(v) => onChangeField(activeField, v)}
            value={fValue}
            readOnly={!('name' in fValue)}
            alowedTables={alowedTables}
            allowedCtypes={displayAlowedCtypes}
            tableFields={tableFields}
            allowedFilters={allowedFilters}
            allowedAggregates={displayAlowedAggregates}
          />
        </Col>
      </Row>
    </Container>
  );
}

FieldsPage.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  activeField: PropTypes.string,
  onRenameField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onSetActiveField: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  allowedFilters: PropTypes.shape({}),
  allowedAggregates: PropTypes.arrayOf(string),
  onFillFields: PropTypes.func.isRequired,
};

FieldsPage.defaultProps = {
  activeField: null,
  allowedCtypes: [],
  allowedFilters: {},
  allowedAggregates: [],
};

export default memo(FieldsPage);
