/* eslint-disable no-unused-vars */
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Badge,
  Button, Card, Col, Container, Nav, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { CiatAppContext } from '../../../providers';
import { EditorControls } from '../../basicEditor/editorControls';

function UpdateRole({
  onClose, roleId, onRefresh, onSetLoading, onSetErr, allPermissions,
}) {
  const { auth } = useContext(CiatAppContext);
  const [role, setRole] = useState({});

  const onLoadStart = useCallback(() => {
    onSetLoading(true);
    onSetErr(null);
  }, [onSetErr, onSetLoading]);

  const onLoadEnd = useCallback(() => {
    onSetLoading(false);
  }, [onSetLoading]);
  const onError = useCallback((e) => onSetErr(e), [onSetErr]);

  const displayPerms = useMemo(
    () => {
      if (!role.permissions) return [];
      return allPermissions.map((p) => ({
        ...p,
        checked: p.key in role.permissions,
        alowed: role.permissions[p.key],
      }));
    },
    [allPermissions, role.permissions],
  );

  useEffect(() => {
    const loader = async () => {
      if (!roleId) {
        return {
          permissions: {},
          name: 'Нова роль',
        };
      }
      const r = await api.get(`/api/auth/role/${roleId}/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRole(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart, roleId]);

  const updateRole = useCallback(() => {
    onSetErr(null);
    const loader = async () => {
      let r = null;
      if (roleId) {
        r = await api.put(`/api/auth/role/${roleId}/`, auth, role);
      } else {
        r = await api.post('/api/auth/role/', auth, role);
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onRefresh();
        onClose();
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onClose, onError, onLoadEnd, onLoadStart, onRefresh, onSetErr, role, roleId]);

  const checkAll = useCallback(
    (v) => {
      if (v) {
        setRole((r) => ({
          ...r,
          permissions: allPermissions.reduce((R, p) => ({ ...R, [p.key]: true }), {}),
        }));
      } else {
        setRole((r) => ({
          ...r,
          permissions: {},
        }));
      }
    },
    [allPermissions],
  );
  const allowAll = useCallback(
    (v) => {
      setRole((r) => ({
        ...r,
        permissions: Object.keys(r.permissions).reduce((R, p) => ({ ...R, [p]: v }), {}),
      }));
    },
    [],
  );
  const onPermCheck = useCallback(
    (p, v) => {
      if (v) {
        setRole((r) => ({
          ...r,
          permissions: {
            ...r.permissions,
            [p]: true,
          },
        }));
      } else {
        setRole((r) => ({
          ...r,
          permissions: Object.keys(r.permissions)
            .filter((pp) => pp !== p)
            .reduce((R, pp) => ({ ...R, [pp]: r.permissions[pp] }), {}),
        }));
      }
    },
    [],
  );
  const onPermAllow = useCallback(
    (p, v) => {
      setRole((r) => ({
        ...r,
        permissions: {
          ...r.permissions,
          [p]: v,
        },
      }));
    },
    [],
  );
  return (
    <>
      <EditorControls.StringInput
        label="Назва ролі"
        value={role.name}
        onChange={(e, v) => setRole((o) => ({ ...o, name: v }))}
      />
      <EditorControls.TextInput
        label="Опис групи"
        value={role.description}
        onChange={(e, v) => setRole((o) => ({ ...o, description: v }))}
      />
      <h5 className="text-primary my-2">Права доступу:</h5>
      <Nav>
        <Button variant="falcon-success" className="me-2" onClick={() => checkAll(true)}>
          <FontAwesomeIcon className="me-2" icon={faCheckSquare} size="sm" />
          Включити всі
        </Button>
        <Button variant="falcon-warning" onClick={() => checkAll(false)}>
          <FontAwesomeIcon icon={faSquare} className="me-2" size="sm" />
          Виключити всі
        </Button>
        <Button variant="falcon-success" className="me-2" onClick={() => allowAll(true)}>
          <FontAwesomeIcon className="me-2" icon={faCheckSquare} size="sm" />
          Дозволити все
        </Button>
        <Button variant="falcon-warning" onClick={() => allowAll(false)}>
          <FontAwesomeIcon icon={faSquare} className="me-2" size="sm" />
          Заборонити все
        </Button>
      </Nav>
      <Container fluid className="mt-2">
        <Row sm={4}>
          {displayPerms.map((el) => (
            <Col key={el.key} className="my-2">
              <Card className="h-100">
                <Card.Header>
                  <Card.Title className="d-flex gap-2">
                    <EditorControls.CheckboxInput
                      label={el.label}
                      value={el.checked}
                      onChange={(e, v) => onPermCheck(el.key, v)}
                    />
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {el.checked ? (
                    <EditorControls.CheckboxInput
                      label={el.alowed ? (
                        <h4>
                          <span className="text-success">Дозволено</span>
                          <FontAwesomeIcon icon={faCheck} className="text-success ms-2" />
                        </h4>
                      ) : (
                        <h4>
                          <span className="text-danger">Заборонено</span>
                          <FontAwesomeIcon icon={faBan} className="text-danger ms-2" />
                        </h4>
                      )}
                      value={el.alowed}
                      onChange={(e, v) => onPermAllow(el.key, v)}
                    />
                  ) : (
                    <h5 className="text-muted">
                      Не регламентується
                    </h5>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="d-flex gap-2">
        <Button className="mt-2 me-2" variant="falcon-success" onClick={updateRole}>Зберегти</Button>
        <Button className="mt-2" variant="falcon-danger" onClick={() => onClose()}>Закрити</Button>
      </div>
    </>
  );
}

UpdateRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  roleId: PropTypes.number,
  onRefresh: PropTypes.func.isRequired,
  onSetLoading: PropTypes.func.isRequired,
  onSetErr: PropTypes.func.isRequired,
  allPermissions: PropTypes.arrayOf({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

UpdateRole.defaultProps = {
  roleId: null,
  allPermissions: [],
};

export default UpdateRole;
