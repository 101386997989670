import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  progVariant: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  startDate: { label: 'Початок періоду', name: 'НачалоПериода' },
  endDate: { label: 'Кінець періоду', name: 'ДатаОтчета' },
  yearPlan: { label: 'Рік планування', name: 'ГодЗатрат' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  reportsKind: { label: 'Вид плану', name: 'ПланСУточнением' },
  sessiondesigion: { label: 'Рішення сесії для відправки в  ЛОГІКА', name: 'РешениеСессии' },
  copecks: { label: 'Показувати копійки', name: 'Копейки' },
};

const tables = {
  tpJSON: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      Col1: { label: 'Кол1', name: 'Кол1' },
      Col2: { label: 'Кол2', name: 'Кол2' },
      Col3: { label: 'Кол3', name: 'Кол3' },
      Col4: { label: 'Кол4', name: 'Кол4' },
      Col5: { label: 'Кол5', name: 'Кол5' },
      Col6: { label: 'Кол6', name: 'Кол6' },
      Col7: { label: 'Кол7', name: 'Кол7' },
      Col8: { label: 'Кол8', name: 'Кол8' },
      Col9: { label: 'Кол9', name: 'Кол9' },
      Col10: { label: 'Кол10', name: 'Кол10' },
      Col11: { label: 'Кол11', name: 'Кол11' },
      Col12: { label: 'Кол12', name: 'Кол12' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 8   (Кредити, позики)',
  name: 'SessionAdd8Loan',
  columns,
  tables,
  frontend: 'rep/SessionAdd8Loan',
  backendName: 'РешениеСессии08Позики',
  resultColumns: baseResultColumns,
};

export default definition;
