import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';

const OutcomeSelector = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory());

export default OutcomeSelector;
