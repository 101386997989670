import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { calcPropType } from '../hooks';

function CalcItem({
  field, active, onRenameField, onClick,
}) {
  return (
    <ListGroupItem
      active={active}
      onClick={onClick}
    >
      <TableStingInput
        controlId={`input-field-${field.itemKey}`}
        value={field.name}
        onChange={(e, v) => onRenameField(field.name, v)}
      />
    </ListGroupItem>
  );
}

CalcItem.propTypes = {
  field: calcPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

CalcItem.defaultProps = {
  active: false,
};

export default CalcItem;
