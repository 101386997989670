import React, { useCallback, useContext, useState } from 'react';
import { fromJS } from 'immutable';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import OmitElementEditor from '../../../dataProcessors/omitElementKDB/editor';
import ListerContext from '../../../newLister/context';

function ListerOmitElementModal() {
  const [opened, setOpened] = useState(false);
  const {
    selectedRows,
  } = useContext(ListerContext);
  const mapStore = useCallback(
    () => fromJS({
      Link: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );

  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={!selectedRows.length}>
        <FontAwesomeIcon icon={faTasks} className="me-2" />
        Створити для підлеглих розпорядників
      </Button>
      <Modal show={opened} onHide={() => setOpened(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTasks} className="me-2" />
            Створення бюджетних програм для підлеглих розпорядників
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OmitElementEditor
            mapInitialOptions={{
              Link: 'ЭлКДБ',
            }}
            mapStore={mapStore}
          />

        </Modal.Body>

      </Modal>
    </>
  );
}

export default ListerOmitElementModal;
