import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const definition = {
  label: 'Аналіз фінансування захищених статей',
  name: 'AnalysisFinProtectItems',
  columns,
  frontend: 'rep/AnalysisFinProtectItems',
  backendName: 'АнализФинЗащищенныхСтатей',
  resultColumns: baseResultColumns,
};

export default definition;
