import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const Lister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

export default Lister;
