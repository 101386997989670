import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.wageStructure;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
  totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'wage'}
          highlighted={highlights.includes('wage')}
          onToggleHighlght={() => onToggleHighlght('wage')}
        >
          {meta.columns.wage.label}
        </TableHeaderCell>
        <Badge variant="primary" className="mx-2">{meta.columns.parent.label}</Badge>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum_2'}
              highlighted={highlights.includes('sum_2')}
              onToggleHighlght={() => onToggleHighlght('sum_2')}
            >
              Факт
              <Badge className="mx-1" variant="primary">{year - 2}</Badge>
              p.
              <Badge className="mx-1" variant="secondary">{totals.sum_2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf_2'}
              highlighted={highlights.includes('zf_2')}
              onToggleHighlght={() => onToggleHighlght('zf_2')}
            >
              {meta.columns.zf_2.label}
              <Badge className="mx-1" variant="secondary">{totals.zf_2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf_2'}
              highlighted={highlights.includes('sf_2')}
              onToggleHighlght={() => onToggleHighlght('sf_2')}
            >
              {meta.columns.sf_2.label}
              <Badge className="mx-1" variant="secondary">{totals.sf_2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsf_2'}
              highlighted={highlights.includes('zfsf_2')}
              onToggleHighlght={() => onToggleHighlght('zfsf_2')}
            >
              {meta.columns.zfsf_2.label}
              <Badge className="mx-1" variant="secondary">{totals.zfsf_2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum_1'}
              highlighted={highlights.includes('sum_1')}
              onToggleHighlght={() => onToggleHighlght('sum_1')}
            >
              Факт
              <Badge className="mx-1" variant="primary">{year - 1}</Badge>
              p.
              <Badge className="mx-1" variant="secondary">{totals.sum_1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf_1'}
              highlighted={highlights.includes('zf_1')}
              onToggleHighlght={() => onToggleHighlght('zf_1')}
            >
              {meta.columns.zf_1.label}
              <Badge className="mx-1" variant="secondary">{totals.zf_1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf_1'}
              highlighted={highlights.includes('sf_1')}
              onToggleHighlght={() => onToggleHighlght('sf_1')}
            >
              {meta.columns.sf_1.label}
              <Badge className="mx-1" variant="secondary">{totals.sf_1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsf_1'}
              highlighted={highlights.includes('zfsf_1')}
              onToggleHighlght={() => onToggleHighlght('zfsf_1')}
            >
              {meta.columns.zfsf_1.label}
              <Badge className="mx-1" variant="secondary">{totals.zfsf_1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum0'}
              highlighted={highlights.includes('sum0')}
              onToggleHighlght={() => onToggleHighlght('sum0')}
            >
              Проект
              <Badge className="mx-1" variant="warning">{year}</Badge>
              p.
              <Badge className="mx-1" variant="secondary">{totals.sum0.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {meta.columns.zf0.label}
              <Badge className="mx-1" variant="secondary">{totals.zf0.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {meta.columns.sf0.label}
              <Badge className="mx-1" variant="secondary">{totals.sf0.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsf0'}
              highlighted={highlights.includes('zfsf0')}
              onToggleHighlght={() => onToggleHighlght('zfsf0')}
            >
              {meta.columns.zfsf0.label}
              <Badge className="mx-1" variant="secondary">{totals.zfsf0.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum1'}
              highlighted={highlights.includes('sum1')}
              onToggleHighlght={() => onToggleHighlght('sum1')}
            >
              Прогноз
              <Badge className="mx-1" variant="success">{year + 1}</Badge>
              p.
              <Badge className="mx-1" variant="secondary">{totals.sum1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {meta.columns.zf1.label}
              <Badge className="mx-1" variant="secondary">{totals.zf1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {meta.columns.sf1.label}
              <Badge className="mx-1" variant="secondary">{totals.sf1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsf1'}
              highlighted={highlights.includes('zfsf1')}
              onToggleHighlght={() => onToggleHighlght('zfsf1')}
            >
              {meta.columns.zfsf1.label}
              <Badge className="mx-1" variant="secondary">{totals.zfsf1.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              Прогноз
              <Badge className="mx-1" variant="success">{year + 2}</Badge>
              p.
              <Badge className="mx-1" variant="secondary">{totals.sum2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {meta.columns.zf2.label}
              <Badge className="mx-1" variant="secondary">{totals.zf2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {meta.columns.sf2.label}
              <Badge className="mx-1" variant="secondary">{totals.sf2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsf2'}
              highlighted={highlights.includes('zfsf2')}
              onToggleHighlght={() => onToggleHighlght('zfsf2')}
            >
              {meta.columns.zfsf2.label}
              <Badge className="mx-1" variant="secondary">{totals.zfsf2.toLocaleString('uk', moneysStingOptions)}</Badge>
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
  totals: PropTypes.shape({
    zf2: PropTypes.number,
    zf1: PropTypes.number,
    zf0: PropTypes.number,
    zf_1: PropTypes.number,
    zf_2: PropTypes.number,
    sf2: PropTypes.number,
    sf1: PropTypes.number,
    sf0: PropTypes.number,
    sf_1: PropTypes.number,
    sf_2: PropTypes.number,
    zfsf2: PropTypes.number,
    zfsf1: PropTypes.number,
    zfsf0: PropTypes.number,
    zfsf_1: PropTypes.number,
    zfsf_2: PropTypes.number,
    sum2: PropTypes.number,
    sum1: PropTypes.number,
    sum0: PropTypes.number,
    sum_1: PropTypes.number,
    sum_2: PropTypes.number,
  }).isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
