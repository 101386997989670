import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import FinancingSourcesCommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const FinancingSourcesLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel: FinancingSourcesCommandPanel,
}));

export default FinancingSourcesLister;
