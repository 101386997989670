const frontendURL = '/rep/allocationyear/';
const name = 'Річний розпис (регламентований)';
const backendURL = '/api/authorityplans/printform/allocation_year/';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
