import {
  Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/docTransferToLogica';
import DocEditor from './editor';
import { modelType, modelName } from '../def';
import DocTabs from './tables';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import APMenu from './menu';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function DocEditorEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  function isReadOnly(data) {
    return !!data[md.columns.signed.name];
  }

  const {
    data,
    loading,
    err,
    changed,
    setChanged,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    readOnlyGetter: isReadOnly,
  });

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
        data={data}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
        <Card.Header>
          <DocEditor
            data={data}
            actions={actions}
            permissions={permissions}
            setChanged={setChanged}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <DocTabs
            data={data}
            actions={actions}
            permissions={permissions}
            setChanged={setChanged}
          />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

DocEditorEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

DocEditorEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { DocEditorEditor as StdEditor };

export default getRouterEditor(md)(DocEditorEditor);
