import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/paymentTransfer';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function PaymentTransfer({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const FI = data[md.columns.FI.name];
  const budget = data[md.columns.budget.name];

  const readOnly = !permissions.canChange;

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const budgetFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.rNo.label}
            value={data[md.columns.rNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.rNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.name}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
              });
              actions.onSR('CHANGE_FOND');
            }}
            modelType="cat"
            modelName="elementFond"
            readOnly={readOnly}
            filter={fondFilter}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.accountFrom.name}
            value={data[md.columns.accountFrom.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.accountFrom.name]: value,
            })}
            modelType="cat"
            modelName="bankAccounts"
            readOnly={readOnly}
            filter={fondFilter}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.accountTo.name}
            value={data[md.columns.accountTo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.accountTo.name]: value,
            })}
            modelType="cat"
            modelName="bankAccounts"
            readOnly={readOnly}
            filter={fondFilter}
            canErase
          />
        </Col>
        <Col>
          {data[md.columns.byElementsKFK.name] ? (
            <EditorControls.ItemPicker
              label={md.columns.elementKFK.label}
              value={data[md.columns.elementKFK.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.elementKFK.name]: value,
                });
              }}
              modelType="cat"
              modelName="elementKFK"
              filter={fondFilter}
              readOnly={readOnly}
            />
          ) : (
            <EditorControls.ItemPicker
              label={md.columns.elementKFK.label}
              value={data[md.columns.elementKFK.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.elementKFK.name]: value,
                });
              }}
              modelType="cat"
              modelName="kbp7Classifier"
              filter={budgetFilter}
              readOnly={readOnly}
            />
          )}
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`byElementsKFK-${data.id}`}
            label={md.columns.byElementsKFK.label}
            value={data[md.columns.byElementsKFK.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.byElementsKFK.name]: value,
              });
              actions.onSR('CHANGE_BY_ELEMENTS_KFK');
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

PaymentTransfer.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PaymentTransfer;
