import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/sessionAdd7';
import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  TwoColumnsGridContainer, CommandPanelColor,
} from '../../../components/Form/styledForm';
import StyledTPPane from '../../../components/tableParts/TPPane';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import ReportContainer from '../reportContainer';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const SixColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-column-gap: 2%;
`;

const FourColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15% ;
  grid-column-gap: 2%;
`;

const MyTwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 68%;
  grid-column-gap: 2%;
`;

const ReportTypes = {
  plan: { label: 'План', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'План+уточнення', value: 2 },
};

class ReportSessionAdd7Editor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    sessionOptions: new Map(),
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm', 'reportEditor', 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const SettingsButton = (
      <div>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('budget', new Map())}
              />
            )}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('FO', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => {
                  dispatch(changeField(['headerForm', 'FO'], v));
                  dispatch(changeField(['headerForm', 'signVariant'], new Map()));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('FO', new Map())}
              />
            )}
          </div>
        </MyContainerFormWithNColumns>
        <SixColumnsGrid>
          <div>
            <StyledLabel>{md.columns.repCSU2.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('repCSU2', new Map())}
              modelType="cat"
              modelName="csu"
              noHierarchy
              onChange={(e, v) => dispatch(changeField(['headerForm', 'repCSU2'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.elKfk.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('elKfk', new Map())}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'elKfk'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.targetProg.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('targetProg', new Map())}
              modelType="cat"
              modelName="targetedPrograms"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'targetProg'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.repCSU.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('repCSU', new Map())}
              modelType="cat"
              modelName="csu"
              noHierarchy
              onChange={(e, v) => dispatch(changeField(['headerForm', 'repCSU'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.varProg.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('varProg', new Map())}
              modelType="cat"
              modelName="variantBudgetDraft"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'varProg'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.varReport.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('varReport', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'varReport'], v))}
              values={Object.values(ReportTypes)}
              name="varReport"
            />
          </div>
        </SixColumnsGrid>
        <FourColumnsGrid>

          <div>
            <StyledLabel>{md.columns.yearPlan.label}</StyledLabel>
            <Fields.YearField
              value={headerForm.get('yearPlan', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'yearPlan'], v))}
              disableCalculator
            />
          </div>
          <Fields.CheckboxField
            value={headerForm.get('inPlan', true)}
            label={md.columns.inPlan.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'inPlan'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('repeatEveryPage', true)}
            label={md.columns.repeatEveryPage.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'repeatEveryPage'], v))}
          />
        </FourColumnsGrid>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
              noBorder
            />
          </div>
          <div>
            <StyledLabel style={{ marginBottom: '6px' }}>{md.columns.signVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('signVariant', new Map())}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
            />
          </div>
        </ContainerDiv>
        <MyTwoColumnsGrid>
          <div>
            <CommandPanelColor
              style={{ width: 'fit-content', alignSelf: 'end' }}
              label="Створити документ відправки в Logica"
              onClick={() => dispatch(processServerReq('UPLOAD_TO_LOGICA'))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sessiondesigion.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('sessiondesigion', new Map())}
              modelType="cat"
              modelName="resolutionSession"
              canCleared
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sessiondesigion'], v));
              }}
            />
          </div>
        </MyTwoColumnsGrid>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportSessionAdd7Editor);
