import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Назва вулиці', name: 'Наименование', visible: true },
  toponim: { label: 'Топонім', name: 'Топоним', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  owner: { label: 'Адміністративний район', name: 'Владелец', visible: true },
};

export const streets = {
  label: 'Вулиці',
  name: 'streets',
  backendName: 'Улицы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/streets',
  listColumns: [...(new Set(['name', 'toponim', 'owner', 'budget', ...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useFoldersLazyLoad: true,
};

export default streets;
