import React from 'react';
import PropTypes from 'prop-types';
import { InputWithDropdown } from '../../../../../../../../components/styledInputs';

const bools = {
  YES: {
    label: 'Так',
    name: true,
  },
  NO: {
    label: 'Ні',
    name: false,
  },
};

const enumValues = Object.values(bools);

function BoolSelector({ value, onChange, noBorder }) {
  return (
    <InputWithDropdown
      value={value}
      onChange={onChange}
      options={enumValues}
      noBorder={noBorder}
    />
  );
}

BoolSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(bools).map((v) => v.name)),
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
};

BoolSelector.defaultProps = {
  value: bools.YES.name,
  noBorder: false,
};

export default BoolSelector;
