import React, {
  memo, useCallback, useState,
} from 'react';
import {
  Col, Dropdown, DropdownButton, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/docRegistr';
import meta, { metaMaper } from '../../../../../../constants/meta';

const tablename = 'consist';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FO, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => {
      onChange(e, rowIndex, partData);
    },
    [onChange, rowIndex],
  );

  const getRowMeta = useCallback(() => {
    if (row[tableMD.columns.doc.name]) {
      return row[tableMD.columns.doc.name].currentType ? row[tableMD.columns.doc.name].currentType.modelName : 'ФинансовоеОбязательство';
    } return 'ФинансовоеОбязательство';
  }, [row]);

  const rowModelName = getRowMeta();

  const metaDoc = meta.doc[metaMaper.doc[rowModelName]];

  const [currentType, setCurrentType] = useState({
    modelName: metaDoc.modelName,
    modelType: metaDoc.modelType,
    repr: metaDoc.label,
  });

  const getAvailableType = () => {
    // For new row
    const types = [
      {
        id: 'ФинансовоеОбязательство',
        modelType: 'doc',
        repr: 'Фінансове зобов\'язання',
      },
      {
        id: 'ЮридическоеОбязательство',
        modelType: 'doc',
        repr: 'Юридичне зобов\'язання',
      },
      {
        id: 'ПлатежноеПоручениеИсходящее',
        modelType: 'doc',
        repr: 'Платіжне доручення',
      },
    ];

    const rowTypes = row[tableMD.columns.doc.name]
      ? row[tableMD.columns.doc.name].availableType
      : row[tableMD.columns.doc.name];

    if (rowTypes === undefined) return types;

    return rowTypes;
  };

  const rowAvailableType = getAvailableType();

  const availableType = (rowAvailableType ? rowAvailableType.map((el) => (
    { value: el, display_name: el.repr }
  ))
    : []);

  const onDocChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.doc.name]: v });
      onSR('CHANGE_DOC_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col md={10}>
            <TableRowCell column="doc" highlighted={highlights.includes('doc')}>
              <ItemPicker
                value={row[tableMD.columns.doc.name]}
                modelType={currentType.modelType}
                modelName={currentType.modelName}
                onChange={onDocChange}
                noHierarchy
                filter={
                  {
                    fieldName: md.columns.FI.name,
                    value: FO,
                  }
                }
                readOnly={readOnly}
                prepend={
                  (
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={currentType.repr}
                      drop="down"
                    >
                      {availableType.map((el) => (
                        <Dropdown.Item
                          onClick={() => {
                            setCurrentType(
                              {
                                modelName: metaMaper[el.value.modelType][el.value.id],
                                modelType: el.value.modelType,
                                repr: el.display_name,
                              },
                            );
                            onRowChange({}, {
                              [tableMD.columns.doc.name]: {},
                              [tableMD.columns.sum.name]: 0,
                            });
                            // onRowChange({}, { [tableMD.columns.sum.name]: 0 });
                          }}
                        >
                          {el.display_name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  )
                }
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>

      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  Budget: PropTypes.shape({
  }).isRequired,
  FO: PropTypes.shape({
  }).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
