import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  signVariant: { label: 'Варіант підпису', name: 'Подпись' },
  withoutDocDetails: { label: 'Без деталізації документів', name: 'флНеДетализировать' },
  byPPB: { label: 'За ПЦМ', name: 'флПЦМ' },
  variantNotes: { label: 'Примітка', name: 'ВариантПримечание' },
  bankAccount: { label: 'Рахунок міського бюджету', name: 'РСФинансовыйОрган' },
};

const definition = {
  label: 'Зведена пропозиція на фінансування за контрагентами',
  name: 'ConsolidatePartnersFinProposal',
  columns,
  frontend: 'rep/ConsolidatePartnersFinProposal',
  backendName: 'СводПредложенийФинансированиеПоКонтрагентам',
  resultColumns: baseResultColumns,
};

export default definition;
