import React from 'react';
import { Card, Col, OverlayTrigger, Popover, Row, Tooltip, Image } from 'react-bootstrap';
import target from '../../../../../../assets/icons/target.svg';
import networking from '../../../../../../assets/icons/networking.svg';

function HintForms() {
  return (
    <Card className="border-0 p-3">
      <Card.Title />
      <Card.Body className="p-1">
        <Row className="g-3">
          <Row className="g-2">
            <Col lg={6} className="text-center">
              <Image src={target} alt="" width={36} className="mb-3" />
              <h3 className="fs-0 text-primary">
                  Рекомендації щодо заповнення форм з питань ліквідності
                </h3>
            </Col>
            <Col lg={6} className="text-center">
              <Image src={networking} alt="" width={36} className="mb-3" />
              <h3 className="fs-0 text-primary">
                  Запитання - відповідь:</h3>
            </Col>
          </Row>
          <Row className="g-3">
            <Col lg={6} className="p-1">
              <h5>Подання інформації здійснюється за таким алгоритмом:</h5>
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                <p>
                  Розпорядники та одержувачі коштів місцевих бюджетів подають інформацію про очікувані помісячні
                  обсяги видатків розпорядникам вищого рівня, до мережі яких вони включені. Форми генеруються
                  програмним забезпеченням, яке використовується учасниками бюджетного процесу на місцевому рівні
                  та взаємодіє через відкритий програмний API-інтерфейс з ІАС “LOGICA”.
                </p>
              </li>
              <li>

                <p>
                  Місцеві фінансові органи узагальнюють інформацію отриману від розпорядників та
                  одержувачів коштів місцевих бюджетів в програмному забезпеченні місцевого рівня та подають
                  Міністерству
                  фінансів очікувані показники руху коштів місцевих бюджетів до останнього робочого дня місяця.
                </p>
              </li>
              <li>
                <p>
                  Уточнення інформації здійснюється в порядку, визначеному для її подання.
                </p>
              </li>
              <li>
                <p>
                  Види інформації, що подається:
                  <ol>
                    <ul>
                      <li>
                        очікувані помісячні обсяги видатків (Додаток 1);
                      </li>
                      <li>
                        очікувані поденні операції з тимчасово вільними коштами на депозитних
                        рахунках (Додаток 2);
                      </li>
                      <li>
                        очікувані поденні операції з управління місцевим боргом (Додаток 3).
                      </li>
                    </ul>
                  </ol>
                </p>
              </li>
              <li>
                Інформація про очікувані помісячні обсяги видатків (помісячні показники) подається
                на наступні три місяці у таких розрізах:
              </li>
              <ol style={{ listStyleType: 'circle' }}>
                <li>
                  за МФО банка отримувача:
                  <ol style={{ listStyleType: 'square' }}>
                    <ul>
                      <li>
                        платежі, що спрямовуються на рахунки в банківських установах (за межі
                        системи Казначейства);
                      </li>
                      <li>
                        платежі, що спрямовуються на рахунки в Казначействі;
                      </li>
                    </ul>
                  </ol>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip>
                        <Popover  border="primary light" placement='right' style={{ width: '22rem' }}>
                          <Popover.Header>
                        Скорочена економічна класифікація видатків бюджету включає:
                          </Popover.Header>
                          <Popover.Body className='text-start'>
                          <ul style={{ listStyleType: 'square' }}>
                          <li>оплату праці (код 2110)</li>
                          <li>нарахування на оплату праці (код 2120)</li>
                          <li>медикаменти та перев'язувальні матеріали (код 2220)</li>
                          <li>продукти харчування (код 2230)</li>
                          <li>оплату комунальних послуг та енергоносіїв (код 2270)</li>
                          <li>дослідження і розробки, окремі заходи розвитку по реалізації державних
                            (регіональних) програм (код 2281) </li>
                          <li>окремі заходи по реалізації державних (регіональних) програм, не віднесені до
                            заходів розвитку (код 2282)</li>
                          <li>соціальне забезпечення (код 2700)</li>
                        </ul>
                          <p>
                          Решта економічних кодів видатків відображаються загальною сумою за кодом 5000 "Інші видатки".
                          </p>

                          </Popover.Body>
                          </Popover>
                      </Tooltip>
                    )}
                  ><span style={{fontStyle: 'italic', textDecoration: 'underline'}}>
                    за скороченою економічною класифікацією видатків бюджету:
                    </span>
                  </OverlayTrigger>
                  <ol style={{ listStyleType: 'square' }}>
                    <ul>
                      <li> загального фонду за КЕКВ 2110; </li>
                      <li> загального фонду за КЕКВ 2220, 2230, 2270, 2281, 2282, 2700; </li>
                      <li>
                        загального фонду за КЕКВ 5000 (відповідно до наказу МФУ
                        від 28.01.2002 № 57), крім КЕКВ 2620 та3220;
                      </li>
                      <li>
                        спеціального фонду (крім видатків за рахунок доходів у натуральній формі та
                        КЕКВ 2120, 2400, 2620,3220).
                      </li>
                    </ul>
                  </ol>
                </li>
              </ol>
              <p>
                Для полегшення процесу прийняття рішень щодо очікуваних обсягів видатків для
                головних розпорядників у формі будуть зазначені попередні очікувані показники, які
                необхідно скоригувати з урахуванням наявної у них інформації.
              </p>
              <p>
                Інформація про видатки подається за тими КЕКВ за якими ці видатки будуть
                відображатися у звітності.
              </p>
              <li>
                <p>
                  Інформація про очікувані поденні операції з тимчасово вільними коштами на
                  депозитних рахунках (поденні показники) подається на наступні 6 місяців із
                  зазначенням дати здійснення та очікуваного обсягу операції з розміщення
                  на депозитах або повернення з депозитів за формою згідно з Додатком 2.
                </p>
              </li>
              <li>
                <p>
                  Інформація про очікувані поденні операції з управління місцевим боргом (поденні
                  показники) подається на наступні 6 місяців із зазначенням дати та обсягу операцій
                  із залучення запозичень, а також платежів з погашення та обслуговування
                  місцевого боргу у національній валюті за формою згідно з Додатком 3.
                </p>
              </li>
            </ul>
          </Col>
            <Col lg={6} className="p-1">
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                <p>
                  <h5>Чи необхідно включати КЕКВ 2120 до КЕКВ 5000? </h5>
                  Ні, ці видатки не потрібно враховувати адже КЕКВ 2120 не відноситься до КЕКВ 5000
                  відповідно
                  до наказу МФУ від 28.01.2002 № 57.
                </p>
              </li>
              <li>
                <p>
                  <h5>
                    Якщо за КЕКВ 2610 одержувач здійснює платежі з оплати праці, за яким кодом це
                    відображати?
                    {' '}
                  </h5>
                  За кодом 5000 як і всі інші платежі за КЕКВ 2610 без розподілу за напрямами
                  подальшого використання одержувачем по плану використання бюджетних коштів
                  (на зарплату,енергоносії і т.д.).
                </p>
              </li>
              <li>
                <p>
                  <h5>
                    Чи необхідно подавати очікувані показники видатків за рахунок власних
                    надходжень?
                  </h5>
                  Так, необхідно подавати інформацію за усіма визначеними КЕКВ, крім видатків
                  за рахунок надходжень у натуральній формі
                </p>
              </li>
              <li>
                <h5>
                  Чи необхідно подавати очікувані показники видатків за рахунок освітньої субвенції?
                </h5>
                Так, необхідно подавати інформацію про видатки за рахунок усіх надходжень до
                бюджету (в т.ч. субвенцій чи дотацій) окрім надходжень в натуральній формі.
              </li>
              <li>
                <p>
                  <h5>
                    Якщо розпорядники та одержувачі коштів не мають відповідного програмного
                    забезпечення, яке використовується учасниками бюджетного процесу на місцевому
                    рівні та взаємодіє через відкритий програмний API-інтерфейс з ІАС “LOGICA”?
                  </h5>
                  Головний розпорядник може самостійно заповнити зведені показники по підвідомчих
                  установах та закладах, зібравши відповідну інформацію в інший доступний спосіб,
                  наприклад через Google таблиці.
                </p>
              </li>
              <li>
                <p>
                  <h5>Чи може фінорган коригувати зведену в цілому інформацію з ГРК/РК? </h5>
                  Так, у випадку наявності у МФО уточненої інформації (наприклад: щойно прийняте
                  рішення про зміни до бюджету, щойно виписані, але ще не доведені ГРК довідки
                  про зміни розпису, заплановане скорочення видатків бюджету тощо) МФО може
                  подавати уточнені показники, які відрізняються від даних поданих ГРК/РК.
                </p>
              </li>
              <li>
                <p>
                  <h5>Чи потрібно включати податки і збори за КЕКВ 2110? </h5>
                  Так, податки і збори за КЕКВ 2110 відображаються у стовпцях 6, 7, 8 «платежі, що
                  спрямовуються
                  на рахунки в Казначействі».
                </p>
              </li>
            </ul>
          </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default HintForms;
