import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StyledTableHeaderCell } from './styles';

function TableHeaderCell({
  active, className, children, title, highlighted, onToggleHighlght,
}) {
  const clsName = `px-2 ${highlighted ? 'bg-warning' : ''} ${className}`;
  const c = (
    <StyledTableHeaderCell active={active} className={clsName}>
      <div role="button" onClick={onToggleHighlght}>
        {children}
      </div>
    </StyledTableHeaderCell>
  );
  return title ? (
    <OverlayTrigger
      placement="auto"
      overlay={(
        <Tooltip>
          {title}
        </Tooltip>
      )}
    >
      {c}
    </OverlayTrigger>
  ) : c;
}
TableHeaderCell.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.node,
  canHighlight: PropTypes.bool,
  highlighted: PropTypes.bool,
  onToggleHighlght: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  active: false,
  className: '',
  title: null,
  canHighlight: true,
  highlighted: false,
  onToggleHighlght: null,
};

export default TableHeaderCell;
