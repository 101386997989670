import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  period: { label: 'За період', name: 'ПериодДокументов' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  elementFond: { label: 'Елемент фонду', name: 'ЭлементФонд' },
  FI: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
  Total: { label: 'Разом', name: 'Всего' },
  DataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных' },
  Autor: { label: 'Автор', name: 'Автор' },
  planingMode: { label: 'Вид планування', name: 'ВидПлана' },
  isPlan: { label: 'План', name: 'флПлан' },
  isRectificationPlan: { label: 'Уточнення до плану', name: 'флУточненияПлана' },
};

/**
 * @const
 */
const tables = {
  docsTable: {
    name: 'Планы',
    label: 'Документи',
    columns: {
      Check: { label: 'М', name: 'М' },
      Document: { label: 'Документ', name: 'Документ' },
      Sum: { label: 'Сума за документом', name: 'Сумма' },
      Autor: { label: 'Підготував', name: 'Автор' },
      DocNo: { label: 'Номер', name: 'Номер' },
      Date: { label: 'Дата', name: 'Дата' },
      Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
  documentNotes: {
    name: 'СодержаниеДокумента',
    label: 'Зміст',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      ElementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      ElementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      Sum: { label: 'Сума', name: 'Сумма' },
      Sum1: { label: 'Сума1', name: 'Сумма1' },
      Sum2: { label: 'Сума2', name: 'Сумма2' },
      Sum3: { label: 'Сума3', name: 'Сумма3' },
      Sum4: { label: 'Сума4', name: 'Сумма4' },
      Sum5: { label: 'Сума5', name: 'Сумма5' },
      Sum6: { label: 'Сума6', name: 'Сумма6' },
      Sum7: { label: 'Сума7', name: 'Сумма7' },
      Sum8: { label: 'Сума8', name: 'Сумма8' },
      Sum9: { label: 'Сума9', name: 'Сумма9' },
      Sum10: { label: 'Сума10', name: 'Сумма10' },
      Sum11: { label: 'Сума11', name: 'Сумма11' },
      Sum12: { label: 'Сума12', name: 'Сумма12' },
      SumBR: { label: 'СумаБР', name: 'СуммаБР' },
      SumBR1: { label: 'СумаБР1', name: 'СуммаБР1' },
      SumBR2: { label: 'СумаБР2', name: 'СуммаБР2' },
      SumBR3: { label: 'СумаБР3', name: 'СуммаБР3' },
      SumBR4: { label: 'СумаБР4', name: 'СуммаБР4' },
      SumBR5: { label: 'СумаБР5', name: 'СуммаБР5' },
      SumBR6: { label: 'СумаБР6', name: 'СуммаБР6' },
      SumBR7: { label: 'СумаБР7', name: 'СуммаБР7' },
      SumBR8: { label: 'СумаБР8', name: 'СуммаБР8' },
      SumBR9: { label: 'СумаБР9', name: 'СуммаБР9' },
      SumBR10: { label: 'СумаБР10', name: 'СуммаБР10' },
      SumBR11: { label: 'СумаБР11', name: 'СуммаБР11' },
      SumBR12: { label: 'СумаБР12', name: 'СуммаБР12' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'requestsLoadingPlaningDoc',
  backendName: 'ЗагрузкаПлановыхДокументовWEB',
  label: 'Завантаження документів',
  columns,
  tables,
};

export default definition;
