/* eslint-disable camelcase */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer, StyledCellItem, StyledTableRow, StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

function Row({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors,
}) {
  // eslint-disable-next-line camelcase
  const zf_2 = tableHooks.useNumberInputProps('zf_2', row, fields, errors, onChangeRow, rowIndex);
  // eslint-disable-next-line camelcase
  const sf_2 = tableHooks.useNumberInputProps('sf_2', row, fields, errors, onChangeRow, rowIndex);

  // eslint-disable-next-line camelcase
  const zf_1 = tableHooks.useNumberInputProps('zf_1', row, fields, errors, onChangeRow, rowIndex);
  // eslint-disable-next-line camelcase
  const sf_1 = tableHooks.useNumberInputProps('sf_1', row, fields, errors, onChangeRow, rowIndex);

  const zf0 = tableHooks.useNumberInputProps('zf0', row, fields, errors, onChangeRow, rowIndex);
  const sf0 = tableHooks.useNumberInputProps('sf0', row, fields, errors, onChangeRow, rowIndex);

  const zf1 = tableHooks.useNumberInputProps('zf1', row, fields, errors, onChangeRow, rowIndex);
  const sf1 = tableHooks.useNumberInputProps('sf1', row, fields, errors, onChangeRow, rowIndex);

  const zf2 = tableHooks.useNumberInputProps('zf2', row, fields, errors, onChangeRow, rowIndex);
  const sf2 = tableHooks.useNumberInputProps('sf2', row, fields, errors, onChangeRow, rowIndex);

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <>
        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...zf_2}
                // onChange={(e, v) => amountChangeHandler(e, v, 'zf_2')}
              />
            </StyledCellItem>
            <StyledCellItem>
              <TableControls.NumberInput
                {...sf_2}
                // onChange={(e, v) => amountChangeHandler(e, v, 'sf_2')}
              />
            </StyledCellItem>
          </StyledCellContainer>
        </StyledTD>

        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...zf_1}
                // onChange={(e, v) => amountChangeHandler(e, v, 'zf_1')}
              />
            </StyledCellItem>
            <StyledCellItem>
              <TableControls.NumberInput
                {...sf_1}
                // onChange={(e, v) => amountChangeHandler(e, v, 'sf_1')}
              />
            </StyledCellItem>
          </StyledCellContainer>
        </StyledTD>

        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...zf0}
                // onChange={(e, v) => amountChangeHandler(e, v, 'zf0')}
              />
            </StyledCellItem>
            <StyledCellItem>
              <TableControls.NumberInput
                {...sf0}
                // onChange={(e, v) => amountChangeHandler(e, v, 'sf0')}
              />
            </StyledCellItem>
          </StyledCellContainer>
        </StyledTD>

        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...zf1}
                // onChange={(e, v) => amountChangeHandler(e, v, 'zf1')}
              />
            </StyledCellItem>
            <StyledCellItem>
              <TableControls.NumberInput
                {...sf1}
                // onChange={(e, v) => amountChangeHandler(e, v, 'sf1')}
              />
            </StyledCellItem>
          </StyledCellContainer>
        </StyledTD>

        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...zf2}
                // onChange={(e, v) => amountChangeHandler(e, v, 'zf2')}
              />
            </StyledCellItem>
            <StyledCellItem>
              <TableControls.NumberInput
                {...sf2}
                // onChange={(e, v) => amountChangeHandler(e, v, 'sf2')}
              />
            </StyledCellItem>
          </StyledCellContainer>
        </StyledTD>
      </>

    </StyledTableRow>
  );
}

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,

};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
