import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ComponentsHeader from '../../unionCompontnts/componentsHeader';

function HeaderComponent({
  dynamicHeaderName,
  headerCol,
  activeNameCell,
}) {
  const dHeaderName = useCallback(
    (elName, hCol) => {
      if (dynamicHeaderName) return dynamicHeaderName(elName, hCol);
      if (hCol[elName]) return hCol[elName].label;
      console.log('not found elName in headerCol', elName, hCol);
      return false;
    },
    [dynamicHeaderName],
  );
  return (
    <div className="grid-template header">
      <ComponentsHeader
        dynamicHeaderName={dHeaderName}
        headerCol={headerCol}
        activeNameCell={activeNameCell}
      />

    </div>
  );
}
export default memo(HeaderComponent);

HeaderComponent.propTypes = {
  headerCol: PropTypes.shape({}),
  activeNameCell: PropTypes.string,
  dynamicHeaderName: PropTypes.func,
};

HeaderComponent.defaultProps = {
  headerCol: {},
  activeNameCell: '',
  dynamicHeaderName: null,
};
