import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { soSelector } from '../../_common/selectors';
import md from '../../../../constants/meta/documents/payment';
import enums from '../../../../constants/meta/enums';
import {
  StyledLabel,
  ContainerStamp,
  ColorTextStamp,
  ImgContainerStamp,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const planingKindsOptions = Object.values(enums.PlaningKind);

function DocEditor({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);

  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];
  const FiFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.planingMode.name]: value,
              });
            }}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.rNo.label}
            value={data[md.columns.rNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.rNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.label}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
              });
              actions.onSR('CHANGE_FOND');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={[{ fieldName: 'Владелец', value: FI }]}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
              <EditorControls.ItemPicker
                label={md.columns.elementKFK.label}
                value={data[md.columns.elementKFK.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.elementKFK.name]: value,
                  });
                  actions.onSR('CHANGE_KFK');
                }}
                modelType="cat"
                modelName="elementKFK"
                filter={FiFilter}
                readOnly={readOnly}
              />
            </div>
            <div>
              <p />
              <StyledLabel style={{ marginLeft: '10px', color: 'red' }}>
                {data[md.columns.KPKVCode.name]}
              </StyledLabel>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.financeObligation.label}
            value={data[md.columns.financeObligation.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.financeObligation.name]: value,
              });
              actions.onSR('CHANGE_FIN_OBLIGATION');
            }}
            modelType="doc"
            modelName="financeObligation"
            filter={[{ fieldName: 'ФинансовыйОрган', value: FI }]}
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.ItemPicker
            label={md.columns.account.label}
            value={data[md.columns.account.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.legalObligation.name]: value,
              });
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={[{ fieldName: 'Владелец', value: FI }]}
            params={[{
              name: 'НаДату',
              value: data[md.columns.date.name],
            }]}
            readOnly={readOnly}
          />
        </Col>

      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.client.label}
            value={data[md.columns.client.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.client.name]: value,
              });
              await actions.onChange({
                [md.columns.clientAccount.name]: new Map(),
              });
              actions.onSR('CHANGE_CLIENT');
            }}
            modelType="cat"
            modelName="csuPartners"
            filter={FiFilter}
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.ItemPicker
            label={md.columns.clientAccount.label}
            value={data[md.columns.clientAccount.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.clientAccount.name]: value,
              });
            }}
            modelType="cat"
            modelName="partnerBankAccounts"
            filter={[{ fieldName: 'Владелец', value: data[md.columns.client.name] }]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>

        <Col>
          <EditorControls.CheckboxInput
            controlId={`isPaided-${data.id}`}
            label={md.columns.isPaided.label}
            value={data[md.columns.isPaided.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isPaided.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`dontFinance-${data.id}`}
            label={md.columns.dontFinance.label}
            value={data[md.columns.dontFinance.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dontFinance.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`dismCred-${data.id}`}
            label={md.columns.dismCred.label}
            value={data[md.columns.dismCred.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dismCred.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>

        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`received-${data.id}`}
              label={md.columns.received.label}
              value={data[md.columns.received.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.received.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>

        )}
        <ContainerStamp>
          {data[md.columns.isApproved.name] && (
            <ImgContainerStamp background={StampApproved}>
              <ColorTextStamp color="#4281c9">
                {data[md.columns.received.name]}
              </ColorTextStamp>
            </ImgContainerStamp>
          )}
        </ContainerStamp>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`budgetPayment-${data.id}`}
            label={md.columns.budgetPayment.label}
            value={data[md.columns.budgetPayment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budgetPayment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.paymentPurposeTemplate.label}
            value={data[md.columns.paymentPurposeTemplate.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.paymentPurposeTemplate.name]: value,
              });
              actions.onSR('CHANGE_PAYMENT');
            }}
            modelType="cat"
            modelName="paymentPurposeTemplate"
            filter={[{ fieldName: 'ФинансовыйОрган', value: FI }, { fieldName: 'ОплатаВБюджет', value: data[md.columns.budgetPayment.name] }]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.paymentPurpose.label}
            value={data[md.columns.paymentPurpose.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.paymentPurpose.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

DocEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.planingMode.name]: PropTypes.oneOf([
      enums.PlaningKind.temporary.name, enums.PlaningKind.permanent.name,
    ]),
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DocEditor;
