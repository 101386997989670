import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import { SaveButton, CloseButton } from '../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../components/bootStrap';

function CatCommandPanel({
  canSaved, modified, onSave, onClose, children,
}) {
  return (
    <CommandPanel>
      <ButtonGroup>
        <SaveButton onClick={onSave} disabled={!canSaved || !modified} />
        <CloseButton onClick={onClose} disabled={!onClose} />
      </ButtonGroup>
      {children}
    </CommandPanel>
  );
}

CatCommandPanel.propTypes = {
  canSaved: PropTypes.bool,
  modified: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

CatCommandPanel.defaultProps = {
  canSaved: true,
  modified: false,
  onClose: null,
  children: null,
};

export default CatCommandPanel;
