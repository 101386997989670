import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  printFinDate: { label: 'друкувати дату фінансування у примітці (реєстр фінансових)', name: 'ПечататьДатуФинансирования' },
  printFinKOATUU: { label: 'Друкувати код  КОАТУУ як код бюджету', name: 'печататьКОАТУУ' },
  printDateDoc: { label: 'Друкувати дату початку договору у поле "Дата  документа"', name: 'флПечататьДатуДоговора' },
  nameS: { label: 'Ім\'я та ПРІЗВИЩЕ', name: 'флИмяФамилия' },
  noAdd: { label: 'Не друкувати "додаток"', name: 'БезНаказ' },
};

/**
 * @const
 */
const definition = {
  name: 'docRegisterPrinting',
  backendName: 'ПечатьРеестров',
  label: 'Друк',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
