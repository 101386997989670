import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CommandPanelButton } from '../button';
import { ExitCommandButton } from '../Form/styledForm';
import Question from '../question';
import { newSave, addFiles } from '../../actions/editor';
import { dellComponentFromWindowsManager } from '../../actions/windowsManager';
import {
  SaveIcon, CloseIcon, ClipIcon, TriangleIcon,
} from '../../assets/icons';
import { saveModes } from '../../constants/meta/common';

const StyledCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
`;

const StyledExitCommandButton = styled(ExitCommandButton)`
:hover {filter: grayscale(1)}
`;

export function BasicMenu({
  dispatch,
  disabled,
  isChanged,
  extraContent = null,
  header = null,
  onClose,
  onSave,
}) {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        {header && (<h3 style={{ marginTop: 0 }}>{header}</h3>)}
        <div style={{ marginLeft: 'auto' }}>
          {isChanged ? (
            <Question
              triggerButton={(
                <StyledExitCommandButton
                  text="Закрити вікно"
                >
                  <StyledCloseIcon />
                </StyledExitCommandButton>
            )}
              header="Вихід без запису"
            // questionText="Зроблені зміни не збережені. Бажаєти вийти?"
              answers={[
                { label: 'Вийти без збереження', value: 'YES' },
                { label: 'Скасувати', value: 'NO' },
                { label: 'Зберегти зміни та вийти', value: 'YESnSAVE' },
              ]}
              onActionClick={(e, v) => {
                switch (v) {
                  case 'YES':
                    onClose();
                    dispatch(dellComponentFromWindowsManager(null));
                    break;
                  case 'NO':
                    break;
                  case 'YESnSAVE':

                    dispatch(newSave({
                      exitOnSave: true,
                      onClose,
                      onSave,
                      saveMode: saveModes.Write,
                    }));

                    break;
                  default:
                    throw new Error(`Unknow answer ${v}`);
                }
              }}
            />
          ) : (
            <StyledExitCommandButton
              text="Закрити вікно"
              onClick={() => {
                dispatch(dellComponentFromWindowsManager(null));
                onClose();
              }}
            >
              <StyledCloseIcon />
            </StyledExitCommandButton>
          )}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <CommandPanelButton
          disabled={disabled}
          onClick={() => {
            dispatch(newSave({
              exitOnSave: true,
              onClose,
              onSave,
              saveMode: saveModes.Posting,
            }));
          }}
          text="Провести та закрити!"
          label="Провести та закрити"
        />
        <CommandPanelButton
          text="Зберегти"
          disabled={disabled}
          onClick={() => dispatch(newSave({ onSave, onClose }))}
        >
          <SaveIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text="Провести"
          label="Провести"
          disabled={disabled}
          onClick={() => dispatch(newSave({ onSave, onClose, saveMode: saveModes.Posting }))}
        />
        <CommandPanelButton
          text="Відмінити проведення"
          label="Відмінити проведення"
          disabled={disabled}
          onClick={() => dispatch(newSave({ onSave, onClose, saveMode: saveModes.UndoPosting }))}
        />

        <CommandPanelButton
          text="Додані файли"
        // label="Додані файли"
          onClick={() => dispatch(addFiles())}
        >
          <ClipIcon height="20px" />
          <img style={{ paddingTop: '25%' }} src={TriangleIcon} alt="" />
        </CommandPanelButton>
        {extraContent}

      </div>
    </div>
  );
}

BasicMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  extraContent: PropTypes.node,
  header: PropTypes.string,
  isChanged: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
BasicMenu.defaultProps = {
  disabled: true,
  extraContent: null,
  header: null,
  isChanged: false,
};

export default BasicMenu;
