import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';
import FilteredTableCell from './cell';

function FilteredTableRow({
  onClick, selected, columns, row,
}) {
  const className = useMemo(
    () => (selected ? 'selected' : ''),
    [selected],
  );
  return (
    <tr
      onClick={(e) => onClick(e, row.id, row.code)}
      className={className}
    >
      <td>
        <FormCheck checked={selected} />
      </td>
      {columns.map((clmn) => (
        <FilteredTableCell
          key={clmn.code}
          selected={selected}
          clmn={clmn}
          value={row.analytics[clmn.code] || row[clmn.code]}
        />
      ))}
    </tr>
  );
}

FilteredTableRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    analytics: PropTypes.shape({

    }),
  }).isRequired,
};

FilteredTableRow.defaultProps = {
  selected: false,
};

export default memo(FilteredTableRow);
