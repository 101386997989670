/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DocTableListerHeader } from '../../../newLister/tableComponent/table/header';
import md from '../../../../constants/meta/documents/financeObligation';

function ListerHeader({ columns, ...rest }) {
  const newColumns = useMemo(() => [...columns, {
    key: 'files',
    name: md.columns.files.name,
    label: md.columns.files.label,
  }], [columns]);
  return (
    <DocTableListerHeader columns={newColumns} {...rest} />
  );
}

ListerHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default ListerHeader;
