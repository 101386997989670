import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows, LevelDownRows2 } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import LoadDocsButton from './loadLeveldown';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { PrependFond, PrependKVK } from '../../styles/klassifiers';
import ApprovedInfo from '../../common/approvedInfo';
import ExportChangesToDBFButton from '../../../services/ExportChangesToDBF/modalButton';
import useEditorParams from '../../../newEditor/hook/params';
import { WinManagerContext } from '../../../../providers';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.allocationplanchanges.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { setWindowsHint } = useContext(WinManagerContext);

  useEffect(
    () => {
      const header = (
        <p>
          <strong> Сума: </strong>
          {data?.amount?.toLocaleString('uk')}
          {data?.amount_br ? ` в т.ч. БР: ${data.amount_br}` : ''}
          {' '}
          <br />
          <strong> Зміни до плану №: </strong>
          {data?.number}
        </p>
      );

      const body = (
        <p>

          {data?.description}
          <br />
          <strong>Фонд: </strong>
          {data?.fond?.repr}
          <br />
          <strong>Бюджетний рік: </strong>
          {data?.year}
          <br />
          <strong>Створено: </strong>
          {data?.created_at ? data.created_at.slice(0, 10) : ''}
          <br />
          <strong>Змінено: </strong>
          {data?.updated_at ? data.updated_at.slice(0, 10) : ''}
          <br />
          <strong>Автор: </strong>
          {data?.author?.repr}
          <br />
          {data?.comment ? `Коментар: ${data.comment}` : ''}
        </p>
      );

      setWindowsHint(`${meta.doc.allocationplanchanges.frontendURL}${id}/`, { header, body });
    },
    [
      data?.amount, data.amount_br, data?.author?.repr, data.comment,
      data?.created_at, data?.description,
      data?.fond?.repr, data?.number, data?.updated_at, data?.year, id, setWindowsHint,
    ],
  );

  const numberProps = editorHooks.useStringInputProps(
    'number',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const yearProps = editorHooks.useNumberInputProps(
    'year',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const planKindProps = editorHooks.useSelectorInputProps(
    'plan_kind',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const disposerInHeaderProps = editorHooks.useCheckboxInputProps(
    'disposer_in_header',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const onlyYearProps = editorHooks.useCheckboxInputProps(
    'only_year',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const vt4brProps = editorHooks.useCheckboxInputProps(
    'vt4br',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const isCreditProps = editorHooks.useCheckboxInputProps(
    'is_credit',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const commentProps = editorHooks.useTextInputProps(
    'comment',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const fondItemProps = editorHooks.useItemInputProps(
    'fond_item',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const disposerProps = editorHooks.useItemInputProps(
    'disposer',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const internalProps = editorHooks.useCheckboxInputProps(
    'internal',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const ignoreBalancesProps = editorHooks.useCheckboxInputProps(
    'ignore_balances',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const approvedProps = editorHooks.useCheckboxInputProps(
    'approved',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const sourceOfCoverageProps = editorHooks.useSelectorInputProps(
    'source_of_coverage',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const executorProps = editorHooks.useItemInputProps(
    'executor',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const templateDocBasisProps = editorHooks.useItemInputProps(
    'template_doc_basis',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const noteBasisProps = editorHooks.useTextInputProps(
    'note_basis',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const templateDocBasisfilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const executorfilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          backendURL={meta.doc.allocationplanchanges.backendURL}
          repr={data.repr}
        >
          <ExportChangesToDBFButton
            doc={[id]}
            backendURL={meta.doc.allocationplanchanges.backendURL}
            disabled={isNew || changed}
          />
        </DocCommandPanel>
            )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col sm={2}>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col sm={4}>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                        )}
                />
              </Col>
              <Col sm={2}>
                <EditorControls.SelectorInput {...planKindProps} required />
              </Col>
              <Col>
                {data.disposer_in_header && (
                <EditorControls.ItemPicker
                  {...disposerProps}
                  filter={{ parent: data.authority.id }}
                  noHierarchy
                  prepend={(
                    <PrependKVK>
                      {data.kvk && data.kvk.repr}
                    </PrependKVK>
                            )}
                />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.SelectorInput {...sourceOfCoverageProps} required />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...executorProps} filter={executorfilter} />
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.allocationplanchanges.backendURL}${data.id}/`}
            />
            <Row>
              <Col sm={6}>
                <div className="d-flex justify-content-between mt-3 gap-2 flex-wrap">
                  {(!disposerInHeaderProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...disposerInHeaderProps} />
                  )}
                  {(!onlyYearProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...onlyYearProps} />
                  )}
                  {(!vt4brProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...vt4brProps} />
                  )}
                  {(!isCreditProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...isCreditProps} />
                  )}
                  {(!internalProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...internalProps} />
                  )}
                  {(!ignoreBalancesProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...ignoreBalancesProps} />
                  )}
                  {(!approvedProps.readOnly || !permissions.canChange) && (
                  <EditorControls.CheckboxInput {...approvedProps} />
                  )}
                </div>
                <LoadDocsButton
                  data={data}
                  onDraft={actions.onDraft}
                  disabled={!permissions.canChange || !data.fond_item}
                />
              </Col>
              <Col sm={6}>
                <EditorControls.ItemPicker
                  {...templateDocBasisProps}
                  filter={templateDocBasisfilter}
                  label={noteBasisProps.label}
                />
                <EditorControls.TextInput
                  {...noteBasisProps}
                  label="Підстава (текстом)"
                  rows={3}
                />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="allocationplanchanges" id="uncontrolled-tab-example">
          {fields && fields.allocationplanchangesrow_set && (
          <Tab eventKey="allocationplanchanges" title={fields.allocationplanchangesrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
          {fields && fields.allocationplanchangesleveldownrow_set && (
          <Tab eventKey="#" title={fields.allocationplanchangesleveldownrow_set.label}>
            <TabContainer>
              <LevelDownRows2
                data={data}
                actions={actions}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        readOnly={!permissions.canChange}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
