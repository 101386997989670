import api from '../../../../api/req';

export const fillRowsProcess = async ({
  data,
  tableName,
  onChange,
  url,
  auth,
  authorityId,
}) => {
  const updateTp = async (newTableData) => {
    await onChange(() => ({
      [tableName]: newTableData,
    }));
  };

  const r = await api.post(`${url}`, auth, {
    authority_id: authorityId,
    data,
  });
  if (r.ok) {
    const newTableData = await r.json();
    updateTp(newTableData);
  } else {
    // console.log('Not Ok newTableData');
  }
};

export default fillRowsProcess;
