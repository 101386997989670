function compare(a, b) {
  if (typeof a !== typeof b) return true;
  if (a === null && b === null) return false;
  if ((a !== null && b === null) || (a === null && b !== null)) return true;
  // eslint-disable-next-line no-use-before-define
  if (typeof a === 'object') return getObjectDiff(a, b);
  // eslint-disable-next-line no-use-before-define
  if (Array.isArray(a)) return getArrayDiff(a, b);
  return a !== b;
}

/**
 *
 * @param obj1 {{}}
 * @param obj2 {{}}
 * @return {string[]}
 */
export function getObjectDiff(obj1, obj2) {
  return [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])].reduce((result, key) => {
    if (!(key in obj2) || !(key in obj1)) {
      return [...result, key];
    }
    const d = compare(obj1[key], obj2[key]);
    if (d === false || (Array.isArray(d) && !d.length)) return result;
    if (d === true) return [...result, key];
    return [...result, key, ...d.map((dd) => `${key}.${dd}`)];
  }, []);
}

/**
 *
 * @param arr1 {[]}
 * @param arr2 {[]}
 * @return {string[]}
 */
export function getArrayDiff(arr1, arr2) {
  const maxA = arr1.length < arr2.length ? arr2 : arr1;
  return maxA.reduce((result, item, key) => {
    if (key >= arr1.length || key >= arr2.length) return [...result, key];
    const d = compare(arr1[key], arr2[key]);
    if (d === false) return result;
    if (d === true) return [...result, key];
    return [...result, key, ...d.map((dd) => `${key}.${dd}`)];
  }, []);
}
