import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup,
  Card, Nav, Navbar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faComment, faFilter, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import md from '../../../../constants/meta/dataprocessors/docSelectionForecast';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import { getURLByBackendModelType } from '../../../../constants/meta';

const tableMeta = md.tables.docs;

function Documents({
  data, onChange, onSR, onOk,
}) {
  const onToggleCheck = useCallback(
    (e, rowId, checked) => onChange((o) => ({
      ...o,
      [md.tables.docs.name]: [
        ...o[md.tables.docs.name].slice(0, rowId),
        { ...o[md.tables.docs.name][rowId], [md.tables.docs.columns.appr.name]: checked },
        ...o[md.tables.docs.name].slice(rowId + 1),
      ],

    })),
    [onChange],
  );

  const onCheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.docs.name]: o[md.tables.docs.name]
        .map((row) => ({ ...row, [md.tables.docs.columns.appr.name]: true })),
    })),
    [onChange],
  );
  const onUncheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.docs.name]: o[md.tables.docs.name]
        .map((row) => ({ ...row, [md.tables.docs.columns.appr.name]: false })),
    })),
    [onChange],
  );
  const onToggleAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.docs.name]: o[md.tables.docs.name]
        .map((row) => ({
          ...row,
          [md.tables.docs.columns.appr.name]: !row[md.tables.docs.columns.appr.name],
        })),
    })),
    [onChange],
  );
  const checkedDocs = data.filter((d) => d[tableMeta.columns.appr.name]);
  const checkedDocCount = checkedDocs.length;

  const getPath = (doc) => {
    const document = doc[tableMeta.columns.doc.name];
    const { modelType, modelName } = document;
    try {
      return `/${getURLByBackendModelType(modelType, modelName)}/${doc[tableMeta.columns.doc.name].id}`;
    } catch (e) {
      return '#';
    }
  };

  return (
    <>
      <Navbar variant="light" expand="sm" className="border rounded bg-light" sticky="top">
        <Nav className="w-100">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => onSR('FILL_TABLE')}>
              <FontAwesomeIcon icon={faFilter} className="me-2" />
              Відібрати
            </Button>
            <Button variant="outline-success" onClick={() => onOk()} disabled={!checkedDocCount}>
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              Завантажити
            </Button>
          </ButtonGroup>

          <ButtonGroup className="ms-2">
            <DefaultCPButton onClick={onCheckAll} label="Відмітити все" variant="outline-primary" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 " />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onUncheckAll} label="Зняти всі відмитки" variant="outline-secondary" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onToggleAll} label="Змінити все" variant="outline-success" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
          </ButtonGroup>
        </Nav>
      </Navbar>
      <>
        {data.map((doc, rowId) => (
          <Card
            key={doc[tableMeta.columns.doc.name].id}
            border={doc[tableMeta.columns.appr.name] ? 'primary' : 'light'}
          >
            <Card.Header className="d-flex">
              <Card.Title className="flex-grow-1">
                <Link to={getPath(doc)}>
                  {`${doc[tableMeta.columns.doc.name].repr} - Розпорядник ${doc[tableMeta.columns.FI.name].repr}`}
                </Link>
              </Card.Title>
              <EditorControls.CheckboxInput
                // label="Завантажувати"
                controlId={`check-for-document-${doc[tableMeta.columns.doc.name].id}`}
                onChange={(e, v) => onToggleCheck(e, rowId, v)}
                value={doc[tableMeta.columns.appr.name]}
              />
            </Card.Header>
            <Card.Body>
              {doc[tableMeta.columns.note.name] && (
                <Card.Text>
                  <hr />
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  {doc[tableMeta.columns.note.name]}
                  <hr />
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        ))}
      </>
    </>
  );
}

Documents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({

  })),
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  data: [],
};
export default Documents;
