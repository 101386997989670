import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  cancelRequest, generate, processServerReq,
} from '../../../actions/reportEditor';
import { CancelRequestButton } from '../../../components/button';
import StyledTPPane from '../../../components/tableParts/TPPane';
import Loading from '../../../components/styledLoading';
import LoadReportVariant from '../../catalogs/reportSettings/loadVariantButton';
import SaveReportVariant from '../../catalogs/reportSettings/saveVariantButton';
import VariantPanel from '../../variantSettings';
import { CommandPanelButtonText } from '../../../components/Form/styledForm';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

const ContainerButton = styled.div`
  display: inline-flex;
`;

const CommandPanelBut = styled(CommandPanelButtonText)`
  color: #4281C9;
  padding: 10.5px 10px;
  height: 25px;
  font-size: 0.65em;
`;

function ReportContainer({
  children, isProcessing, portalOpened, Settings, backendName, reportVariants,
}) {
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();

  const Header = (

    <div>
      <div style={{ marginRight: '10px' }}>Налаштування</div>

      <ContainerButton>
        <LoadReportVariant
          disabled={isProcessing}
          reportKey={`Отчеты.${backendName}.reactReport`}
          onLoad={(e, variant) => dispatch(processServerReq('LoadVariant', { variant }))}
          LoadButton={CommandPanelBut}
        />
        <SaveReportVariant
          disabled={isProcessing}
          reportKey={`Отчеты.${backendName}.reactReport`}
          onSave={(e, nameVariant, commentVariant) => dispatch(processServerReq('SaveVariant', { nameVariant, commentVariant }))}
          SaveButton={CommandPanelBut}
        />

      </ContainerButton>
    </div>
  );

  return (
    <div>

      <VariantPanel
        generateReport={() => {
          setUpdated(true);
          dispatch(generate());
        }}
        generateVariant={(variant) => {
          dispatch(processServerReq('LoadVariant_GENERATE', { variant }));
        }}
        deleteVariant={(variant) => {
          dispatch(processServerReq('DeleteVariant', { variant }));
        }}
        Settings={Settings}
        // backendName={backendName}
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        reportVariants={reportVariants}
        Header={Header}
      />

      {isProcessing && !updated && <Loading />}
      {isProcessing && updated && (
        <Loading>
          <>
            {/* <CancelRequestTitle>Воно того варте! </CancelRequestTitle> */}
            <CancelRequestButton
              onClick={() => {
                setUpdated(false);

                dispatch(cancelRequest());
              }}
            >
              Скасувати запит
            </CancelRequestButton>
          </>
        </Loading>
      )}
      {children}
    </div>

  );
}

ReportContainer.propTypes = {
  children: PropTypes.node,
  Settings: PropTypes.element,
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
  backendName: PropTypes.string.isRequired,
  reportVariants: PropTypes.instanceOf(Map),
};

ReportContainer.defaultProps = {
  isProcessing: false,
  portalOpened: false,
  children: null,
  Settings: null,
  reportVariants: null,
};

export default ReportContainer;
