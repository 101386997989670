import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R01G3: { label: 'R01G3', name: 'R01G3' },
  R01G4: { label: 'R01G3', name: 'R01G4' },
  R01G5: { label: 'R01G3', name: 'R01G5' },
  R01G6: { label: 'R01G3', name: 'R01G6' },
  R01G7: { label: 'R01G3', name: 'R01G7' },
  R02G3: { label: 'R01G3', name: 'R02G3' },
  R02G4: { label: 'R01G3', name: 'R02G4' },
  R02G5: { label: 'R01G3', name: 'R02G5' },
  R02G6: { label: 'R01G3', name: 'R02G6' },
  R02G7: { label: 'R01G3', name: 'R02G7' },
  R03G3: { label: 'R01G3', name: 'R03G3' },
  R03G4: { label: 'R01G3', name: 'R03G4' },
  R03G5: { label: 'R01G3', name: 'R03G5' },
  R03G6: { label: 'R01G3', name: 'R03G6' },
  R03G7: { label: 'R01G3', name: 'R03G7' },
  R04G3: { label: 'R01G3', name: 'R04G3' },
  R04G4: { label: 'R01G3', name: 'R04G4' },
  R04G5: { label: 'R01G3', name: 'R04G5' },
  R04G6: { label: 'R01G3', name: 'R04G6' },
  R04G7: { label: 'R01G3', name: 'R04G7' },
  R05G3: { label: 'R01G3', name: 'R05G3' },
  R05G4: { label: 'R01G3', name: 'R05G4' },
  R05G5: { label: 'R01G3', name: 'R05G5' },
  R05G6: { label: 'R01G3', name: 'R05G6' },
  R05G7: { label: 'R01G3', name: 'R05G7' },
  R06G3: { label: 'R01G3', name: 'R06G3' },
  R06G4: { label: 'R01G3', name: 'R06G4' },
  R06G5: { label: 'R01G3', name: 'R06G5' },
  R06G6: { label: 'R01G3', name: 'R06G6' },
  R06G7: { label: 'R01G3', name: 'R06G7' },
  R07G3: { label: 'R01G3', name: 'R07G3' },
  R07G4: { label: 'R01G3', name: 'R07G4' },
  R07G5: { label: 'R01G3', name: 'R07G5' },
  R07G6: { label: 'R01G3', name: 'R07G6' },
  R07G7: { label: 'R01G3', name: 'R07G7' },
  R08G3: { label: 'R01G3', name: 'R08G3' },
  R08G4: { label: 'R01G3', name: 'R08G4' },
  R08G5: { label: 'R01G3', name: 'R08G5' },
  R08G6: { label: 'R01G3', name: 'R08G6' },
  R08G7: { label: 'R01G3', name: 'R08G7' },
  R09G3: { label: 'R01G3', name: 'R09G3' },
  R09G4: { label: 'R01G3', name: 'R09G4' },
  R09G5: { label: 'R01G3', name: 'R09G5' },
  R09G6: { label: 'R01G3', name: 'R09G6' },
  R09G7: { label: 'R01G3', name: 'R09G7' },
  R10G3: { label: 'R01G3', name: 'R10G3' },
  R10G4: { label: 'R01G3', name: 'R10G4' },
  R10G5: { label: 'R01G3', name: 'R10G5' },
  R10G6: { label: 'R01G3', name: 'R10G6' },
  R10G7: { label: 'R01G3', name: 'R10G7' },
  R11G3: { label: 'R01G3', name: 'R11G3' },
  R11G4: { label: 'R01G3', name: 'R11G4' },
  R11G5: { label: 'R01G3', name: 'R11G5' },
  R11G6: { label: 'R01G3', name: 'R11G6' },
  R11G7: { label: 'R01G3', name: 'R11G7' },
  R12G3: { label: 'R01G3', name: 'R12G3' },
  R12G4: { label: 'R01G3', name: 'R12G4' },
  R12G5: { label: 'R01G3', name: 'R12G5' },
  R12G6: { label: 'R01G3', name: 'R12G6' },
  R12G7: { label: 'R01G3', name: 'R12G7' },
  R13G3: { label: 'R01G3', name: 'R13G3' },
  R13G4: { label: 'R01G3', name: 'R13G4' },
  R13G5: { label: 'R01G3', name: 'R13G5' },
  R13G6: { label: 'R01G3', name: 'R13G6' },
  R13G7: { label: 'R01G3', name: 'R13G7' },
  R14G3: { label: 'R01G3', name: 'R14G3' },
  R14G4: { label: 'R01G3', name: 'R14G4' },
  R14G5: { label: 'R01G3', name: 'R14G5' },
  R14G6: { label: 'R01G3', name: 'R14G6' },
  R14G7: { label: 'R01G3', name: 'R14G7' },
  R15G3: { label: 'R01G3', name: 'R15G3' },
  R15G4: { label: 'R01G3', name: 'R15G4' },
  R15G5: { label: 'R01G3', name: 'R15G5' },
  R15G6: { label: 'R01G3', name: 'R15G6' },
  R15G7: { label: 'R01G3', name: 'R15G7' },
  R16G3: { label: 'R01G3', name: 'R16G3' },
  R16G4: { label: 'R01G3', name: 'R16G4' },
  R16G5: { label: 'R01G3', name: 'R16G5' },
  R16G6: { label: 'R01G3', name: 'R16G6' },
  R16G7: { label: 'R01G3', name: 'R16G7' },
  R17G3: { label: 'R01G3', name: 'R17G3' },
  R17G4: { label: 'R01G3', name: 'R17G4' },
  R17G5: { label: 'R01G3', name: 'R17G5' },
  R17G6: { label: 'R01G3', name: 'R17G6' },
  R17G7: { label: 'R01G3', name: 'R17G7' },
  R18G3: { label: 'R01G3', name: 'R18G3' },
  R18G4: { label: 'R01G3', name: 'R18G4' },
  R18G5: { label: 'R01G3', name: 'R18G5' },
  R18G6: { label: 'R01G3', name: 'R18G6' },
  R18G7: { label: 'R01G3', name: 'R18G7' },
  R19G3: { label: 'R01G3', name: 'R19G3' },
  R19G4: { label: 'R01G3', name: 'R19G4' },
  R19G5: { label: 'R01G3', name: 'R19G5' },
  R19G6: { label: 'R01G3', name: 'R19G6' },
  R19G7: { label: 'R01G3', name: 'R19G7' },
  R20G3: { label: 'R01G3', name: 'R20G3' },
  R20G4: { label: 'R01G3', name: 'R20G4' },
  R20G5: { label: 'R01G3', name: 'R20G5' },
  R20G6: { label: 'R01G3', name: 'R20G6' },
  R20G7: { label: 'R01G3', name: 'R20G7' },
  R21G3: { label: 'R01G3', name: 'R21G3' },
  R21G4: { label: 'R01G3', name: 'R21G4' },
  R21G5: { label: 'R01G3', name: 'R21G5' },
  R21G6: { label: 'R01G3', name: 'R21G6' },
  R21G7: { label: 'R01G3', name: 'R21G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd1Printing',
  backendName: 'ПечатьПрогнозДод1',
  label: 'Загальні показники бюджету (Додаток 1)',
  frontend: 'dp/forecastAdd1Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
