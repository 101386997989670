import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function PublicPolicyTarget({ data, fromLogica, ...rest }) {
  const template = useMemo(
    () => {
      if (!fromLogica) {
        return {
          columns: [
            {
              field: 'public_policy_target',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
            },
          ],
        };
      }
      return {
        columns: [
          {
            field: 'public_policy_target_text',
          },
        ],
      };
    },
    [data.budget, fromLogica],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="passportbppublicpolicytarget_set"
    />
  );
}

PublicPolicyTarget.propTypes = {
  data: PropTypes.shape({
    budget: foreignPropType,
  }).isRequired,
  fromLogica: PropTypes.bool.isRequired,
};

export default PublicPolicyTarget;
