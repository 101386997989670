import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function FBRMenuItem() {
  return (
    <UseMenuHeader label="Облік фінансування коштів бюджету розвитку через банки">
      <Boxes items={NavPanel.fbr.boxes} />
    </UseMenuHeader>
  );
}

export default FBRMenuItem;
