import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FillButton from '../additionalCP/fillingData';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function Task({
  data, fromLogica, onDraft, ...rest
}) {
  const template = useMemo(
    () => {
      if (!fromLogica) {
        return {
          columns: [
            {
              field: 'assignment',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
            },
          ],
        };
      }
      return {
        columns: [
          {
            field: 'assignment_text',
          },
        ],
      };
    },
    [data.budget, fromLogica],
  );
  // TODO: onDraft
  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="passportbpassignmentrow_set"
      addCommands={(
        <FillButton
          onClick={() => onDraft(data, { add_assignment: true })}
        />
      )}
    />
  );
}

Task.propTypes = {
  data: PropTypes.shape({
    budget: foreignPropType,
  }).isRequired,
  fromLogica: PropTypes.bool.isRequired,
  onDraft: PropTypes.func.isRequired,
};

export default Task;
