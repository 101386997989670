import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  CSU: { label: 'Розпорядник', name: 'фРаспорядитель' },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  elementFond: { label: 'Елемент фонду', name: 'ЭлементФонд' },
  isDirect: { label: 'Напрями використання коштів', name: 'флНаправления' },
  isIndicators: { label: 'Показники', name: 'флПоказатели' },
  isRegionPrograms: { label: 'Державні/регіональні цільові програми', name: 'флРегГосПрораммы' },
  isSourcesInvestmentProjects: { label: 'Джерела фінансування інвестиційних програм', name: 'флИсточникиПрограмм' },

  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  year: { label: 'Рік', name: 'тГод' },
  KEKV: { label: 'КЕКВ', name: 'спКЕКВ' },
  KBP: { label: 'КБП', name: 'КБП' },
  KBP7: { label: 'КПКВ', name: 'КПКВ' },
  planingMode: { label: 'Вид планування', name: 'ВидПлана' },
  period: { label: 'Період', name: 'тПериод' },
  DataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных' },
  isTasks: { label: 'Завдання', name: 'флЗадания' },
  isTarget: { label: 'Цілі державної політики', name: 'флЦелиГосПолит' },
  selectionDoc: { label: 'ОтборДокумент', name: 'ОтборДокумент' },
  selectPlan: { label: 'Вибирати включно з планами асигнувань', name: 'флОтбиратьПланАссигнований' },
  attachedFiles: { label: 'Додані файли', name: 'ТаблицаПрикрепленныхФайлов', visible: false },
  loadAll: { label: 'Завантажувати отримані документи', name: 'ОтборУтвержденных', visible: false },
};

/**
 * @const
 */
const tables = {
  data: {
    name: 'Данные',
    label: 'Дані',
    columns: {
      Check: { label: 'М', name: 'М' },
      Document: { label: 'Документ', name: 'Документ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      KPKVCode: { label: 'КодКПКВ', name: 'КодКПКВ' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      Autor: { label: 'Автор', name: 'Автор' },
      Notes: { label: 'Примітка', name: 'Примечание' },
      Sum: { label: 'Сума', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'docSelection',
  backendName: 'ПодборДок',
  label: 'Відбір документів',
  columns,
  tables,
};

export default definition;
