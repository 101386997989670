import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  TextInput,
} from '../../../../../components/bootstrap_components/controls';
import md from '../../../../../constants/meta/catalogs/groundLaw';
import TableRow from '../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'tpParagr';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="paragr" highlighted={highlights.includes('paragr')}>
          <TextInput
            value={row[tableMD.columns.paragr.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.paragr.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>

      </Col>

    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.paragr.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
