/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert, ButtonGroup, Card } from 'react-bootstrap';

import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import md from '../../../../../../constants/meta/documents/energyIndicators';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import DefaultCPButton from '../../../../../../components/bootstrap_components/controls/defaultButton';

const tableMeta = md.tables.indicators;

function IndicatorsTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  return (
    <Card className="border-0">
      {data[md.columns.checkMessage.name] && (
        <Alert variant={data[md.columns.checkMessage.name] === 'OK' ? 'success' : 'warning'}>
          <Alert.Heading>
            {data[md.columns.checkMessage.name] === 'OK' ? 'Помилок не виявлено!' : data[md.columns.checkMessage.name] }
          </Alert.Heading>
        </Alert>
      )}
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          <ButtonGroup>
            <DefaultCPButton
              variant="info"
              label="Розрахувати"
              onClick={() => {
                actions.onSR('Calculate');
              }}
            />
            <DefaultCPButton
              variant="info"
              label="Перевірити"
              onClick={() => {
                actions.onSR('Check');
              }}
            />
          </ButtonGroup>
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            onSR={actions.onSR}
            readOnly={readOnly}
            highlights={highlights}
            autoCalculate={data[md.columns.autoCalculate.name]}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader} />
    </Card>
  );
}

IndicatorsTP.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

IndicatorsTP.defaultProps = {
  readOnly: false,
};

export default memo(IndicatorsTP);
