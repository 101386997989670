import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import ElementCommandPanel from './commandpanel';
import ListerSelector from './lister_selector';
import { modelType, modelName } from '../def';
import KBPRow from './row';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({
  CommandPanel: ElementCommandPanel,
  RowComponent: KBPRow,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
