import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { switchWindow } from '../../../actions/windowsManager';

const StyledBoxLink = styled(({ isChiefBuilding, line, ...r }) => <Link {...r} />)`
    display: block;
    font-size: 14px;
    color: #252525;
    text-decoration: none;
    margin: ${(props) => (props.isChiefBuilding ? '10px 0' : '5px 0')};
    border-bottom: ${(props) => props.line && '1px dotted lightblue'};
   
    :focus {outline:none;}
`;

function BoxLink({
  to, children, isChiefBuilding, line,
}) {
  const dispatch = useDispatch();
  return (
    <StyledBoxLink
      to={`/${to}/`}
      isChiefBuilding={isChiefBuilding}
      line={line}
      onClick={() => {
        dispatch(switchWindow(`/${to}/`));
      }}
    >
      {children}
    </StyledBoxLink>
  );
}

BoxLink.propTypes = {
  to: PropTypes.string.isRequired,
  isChiefBuilding: PropTypes.bool,
  line: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
BoxLink.defaultProps = {
  isChiefBuilding: false,
  line: false,
};

export default BoxLink;
