import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

const generateGName = (group, k) => `group-${k}`;

/**
 * Генерирует группировки, пригодные для отображения в редакторе
 * @param groups {{}[]}
 * @param metaFields {{ label: string }}
 * @param fields {{ label: string }}
 * @returns {[]}
 */
const useDisplayGroups = (groups, metaFields, fields) => useMemo(
  () => (groups.map((group, k) => ({
    name: generateGName(group, k),
    items: group.map((sGroup) => ({
      name: sGroup.key,
      label: sGroup.key in metaFields ? metaFields[sGroup.key].label : sGroup.key,
      errored: !(sGroup.key in metaFields),
      useHierarchy: !!sGroup.hierarchy,
      canHierarchy: (sGroup.key in metaFields) && (fields[metaFields[sGroup.key].key]
        ? !!fields[metaFields[sGroup.key].key].hierarchical_key
        : metaFields[sGroup.key].hierarchical_key),
    })),
  }))),
  [fields, groups, metaFields],
);

/**
 * Генерирует доступные поля группировок
 * @param metaFields {Object}
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableGroups = (metaFields, fields) => useMemo(
  () => Object.keys(metaFields)
    .filter((f) => metaFields[f].key in fields && fields[metaFields[f].key].key)
    .map((f) => ({ ...metaFields[f], name: f }))
    .sort((a, b) => (a.sort - b.sort)),
  [fields, metaFields],
);

/**
 * HOOK для работы с группировками отчетов
 * @param reportData {{options: {group: string[]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @param schemaKey {string}
 * @returns {{
 *  groups: Array,
 *  displayGroups: Array,
 *  availableGroups: Array,
 *  loadGroups: function,
 *  groupsHandlers: {
 *      addGroupHandler: function,
 *      removeGroupHandler: function,
 *      swapGroupRowHandler: function,
 *      insertSubgroupHandler: function,
 *      removeSubgroupHandler: function,
 *      clearAllGroupsHandler: function,
 *      onChangeHierarchyHandler: function,
 *  },
 *  }}
 */

export const useGroups = (reportData, schema, schemaKey = 'src') => {
  const [groups, setGroups] = useState([]);

  useEffect(
    () => setGroups(reportData.options.group || []),
    [reportData],
  );
  const metaFields = useMemo(
    () => (schema[schemaKey] ? schema[schemaKey].meta_fields : []),
    [schema, schemaKey],
  );
  const fields = useMemo(
    () => (schema[schemaKey] ? schema[schemaKey].fields : []),
    [schema, schemaKey],
  );

  const displayGroups = useDisplayGroups(groups, metaFields, fields);
  const availableGroups = useAvailableGroups(metaFields, fields);
  const handlers = useMemo(
    () => {
      const addGroupHandler = (fname, gname) => {
        const index = groups.reduce(
          (R, g, k) => (generateGName(g, k) === gname ? k : R),
          groups.length,
        );
        setGroups([
          ...groups.slice(0, index),
          [
            {
              key: fname,
              hierarchy: false,
            },
          ],
          ...groups.slice(index),
        ]);
      };

      const removeGroupHandler = (gname) => setGroups(
        groups.filter((g, k) => generateGName(g, k) !== gname),
      );

      const clearAllGroupsHandler = () => setGroups([]);

      const swapGroupRowHandler = (fromName, toName) => {
        const from = groups.reduce((R, g, k) => (generateGName(g, k) === fromName ? k : R), null);
        const to = groups.reduce(
          (R, g, k) => (generateGName(g, k) === toName ? k : R),
          groups.length,
        );

        if (from > to) {
          setGroups([
            ...groups.slice(0, to),
            groups[from],
            ...groups.slice(to, from),
            ...groups.slice(from + 1),
          ]);
        }
        if (from < to) {
          setGroups([
            ...groups.slice(0, from),
            ...groups.slice(from + 1, to),
            groups[from],
            ...groups.slice(to),
          ]);
        }
      };

      const insertSubgroupHandler = (fname, groupName) => {
        const gIndex = groups.reduce((R, g, k) => (generateGName(g, k) === groupName ? k : R), null);
        const subgroups = groups[gIndex];
        if (!(fname in subgroups)) {
          setGroups([
            ...groups.slice(0, gIndex),
            [
              ...subgroups,
              {
                key: fname,
                herarchy: false,
              },
            ],
            ...groups.slice(gIndex + 1),
          ]);
        }
      };

      const removeSubgroupHandler = (groupName, sgroupName) => {
        setGroups(
          groups.map(
            (g, k) => (
              generateGName(g, k) === groupName
                ? g.filter((sgroup) => sgroup.key !== sgroupName)
                : g
            ),
          ).filter((g) => Object.keys(g).length),
        );
      };

      const swapSubgroupHandler = (groupName, from, to) => {
        const gIndex = groups.reduce(
          (R, g, k) => (generateGName(g, k) === groupName ? k : R),
          null,
        );

        const min = Math.min(from, to);
        const max = Math.max(from, to);

        const subgroups = [
          ...groups[gIndex].slice(0, min),
          groups[gIndex][max],
          ...groups[gIndex].slice(min, max),
          ...groups[gIndex].slice(max + 1),
        ];
        setGroups([
          ...groups.slice(0, gIndex),
          subgroups,
          ...groups.slice(gIndex + 1),
        ]);
      };

      const onChangeHierarchyHandler = (groupName, sgroupName, useHierarchy) => {
        setGroups(
          groups.map(
            (g, k) => (
              generateGName(g, k) === groupName
                ? g.map(
                  (sgroup) => (
                    sgroup.key === sgroupName
                      ? {
                        ...sgroup,
                        hierarchy: useHierarchy,
                      }
                      : {
                        ...sgroup,
                        hierarchy: sgroup.hierarchy && !useHierarchy,
                      }
                  ),
                )
                : g
            ),
          ),
        );
      };

      return ({
        addGroupHandler,
        removeGroupHandler,
        swapGroupRowHandler,
        insertSubgroupHandler,
        removeSubgroupHandler,
        clearAllGroupsHandler,
        onChangeHierarchyHandler,
        swapSubgroupHandler,
      });
    },
    [groups],
  );

  return {
    groups,
    displayGroups,
    availableGroups,
    groupsHandlers: handlers,
    loadGroups: setGroups,
  };
};

export const availableGroupPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableGroupsPropType = PropTypes.arrayOf(availableGroupPropType);

export const subGroupPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
  useHierarchy: PropTypes.bool,
  canHierarchy: PropTypes.bool,
});

export const groupPropType = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(subGroupPropType),
});

export const getPreviousGroup = (displayGroups, name) => {
  const index = displayGroups.reduce((R, g, k) => (g.name === name ? k : R), 0);
  if (!index) return null;
  return displayGroups[index - 1].name;
};

export const groupsPropType = PropTypes.arrayOf(groupPropType);
