import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  CommandPanelColor, StyledLabel,
} from '../../../components/Form/styledForm';

const md = meta.dp.regInDBF;

class RegInDBFEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      // firstStart: true,
    };
  }

  // componentWillUnmount() {
  //   const { dispatch } = this.props;
  //   dispatch(actions.processServerReq('CLOSING'));
  // }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <MyContainerFormWithNColumns columns={1} style={{ marginTop: '10px' }}>
          <div>
            <StyledLabel>{md.columns.Document.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('Document', new Map())}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={3} style={{ marginTop: '10px' }}>
          <div>
            <Fields.CheckboxField
              label={md.columns.printKOATUU.label}
              value={headerForm.get('printKOATUU', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printKOATUU'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.codeWin.label}
              value={headerForm.get('codeWin', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'codeWin'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.order.label}
              value={headerForm.get('order', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'order'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportVDBFKiev',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(RegInDBFEditor);
