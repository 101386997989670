import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CommandPanelButton } from '../../../components/button';
import ProposalDeputiesEditor from '../../reports/proposalDeputies';
import { PrintIcon } from '../../../assets/icons';
import Modal from '../../../components/modal';

class ListerPrintModal extends PureComponent {
  static propTypes = {
    activeId: PropTypes.bool,
  };

  static defaultProps = {
    activeId: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
    };
  }

  render() {
    const {
      modalOpened,
    } = this.state;
    return (
      <Modal
        triggerButton={(
          <CommandPanelButton
            onClick={() => this.setState({ modalOpened: true })}
            text="Сформувати друковану форму"
          >
            <PrintIcon />
          </CommandPanelButton>
        )}
        header="Друкована формa"
        closeIcon
        opened={modalOpened}
        onClose={() => this.setState({ modalOpened: false })}
      >
        <ProposalDeputiesEditor />
      </Modal>
    );
  }
}

export default ListerPrintModal;
