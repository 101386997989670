import React, {
  Suspense, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import { emptyUid } from '../../../constants/meta/common';

import { soSelector } from '../../documents/_common/selectors';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.netFOPPrint;

function NetFOPPrintEditor({ onClose }) {
  const dispatch = useDispatch();
  const sessionOptions = useSelector(soSelector);
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );
  const canSendToLogica = useMemo(
    () => (headerForm.get('apprByRegion', false) && sessionOptions.get('approveByRegion', false))
      || (!sessionOptions.get('approveByRegion', false) && headerForm.get('apprByRegion', false)),
    [headerForm, sessionOptions],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  return (
    <MainContainer>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flex: '1 1 auto', marginRight: '20px' }}>
          <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
          <Fields.SelectorField
            modelType="cat"
            modelName="signatureVariants"
            value={headerForm.get('SignVariant', new Map())}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
          />
        </div>
        {/* <div style={{ flex: '1 1 auto', marginRight: '20px' }}> */}
        {/*  <StyledLabel>{md.columns.proj.label}</StyledLabel> */}
        {/*  <Fields.SelectorField */}
        {/*    modelType="cat" */}
        {/*    modelName="planing" */}
        {/*    value={headerForm.get('proj', new Map())} */}
        {/*    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'proj'], v))} */}
        {/*    // filter={[ */}
        {/*    //   { fieldName: 'Владелец', value: headerForm.get('FI') }, */}
        {/*    // ]} */}
        {/*  /> */}
        {/* </div> */}
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && (
          <CommandPanelButton
            label="Передати в Logica"
            onClick={() => {
              dispatch(actions.processServerReq('CreateDispatchDoc'));
            }}
            disabled={false}
          />
        )}
        {!canSendToLogica && (
          <Alert variant="warning" className="mb-0 w-100">
            Документ не затверджений, передача в Логіка заблокована, зверніться до спеціаліста,
            який має повноваження на затвердження документів
          </Alert>
        )}
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal
          styled={settingsOpened ? { transition: '3s' } : { transition: '3s' }}
          visible={settingsOpened}
          animation="overlay"
          width="very wide"
          direction="top"
          tertiary
          color="blue"
          basic
        >
          <div>
            <ContainerThreeColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
            </ContainerThreeColumn>
          </div>
        </FramePrintModal>
      )
        : (
          <Suspense fallback={<div>Завантаження...</div>}>
            <TableEditor mountNode={resultMountNode} />
          </Suspense>
        )}
    </MainContainer>
  );
}

NetFOPPrintEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

export default enchance(NetFOPPrintEditor);
