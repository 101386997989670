import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tab from './Tab';

export const TabUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  // border-bottom: 1px solid #DDE2E9;
   border-bottom: 1px solid #afbbcc;
`;

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      },
    } = this;

    return (
      <div>
        <TabUl>
          {children.map((child) => {
            const { label, disabled, empty } = child.props;
            return label && (
              <Tab
                activeTab={activeTab}
                empty={empty}
                disabled={disabled}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </TabUl>
        <div>
          {children.map((child) => {
            if (child.props.label !== activeTab) return null;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
