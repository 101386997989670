import {
  useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { DataComposition as DC } from '../../../constants/meta/enums/DataComposition';
import { useDCAvailable } from './available';
import { comparisonTypes } from '../../../constants/meta/enums/DataComposition/comparisonTypes';

function getTilte(item, availableField) {
  const result = item.Tittle;
  if (item.Type === DC.fieldTypes.Auto) {
    return 'Авто';
  }
  if (!availableField) return item.Tittle || item.LeftValue;

  return result ? `${result}(${availableField.Tittle})` : availableField.Tittle;
}

// eslint-disable-next-line import/prefer-default-export
export const useDCSFilter = ({
  reqParams, backendURL, onError,
  filter, onChange,
  availableFields, onChangeAvailables,
}) => {
  const [active, setActive] = useState(null);
  const availables = useDCAvailable({
    availableFields, reqParams, backendURL, section: 'FilterAvailableFields', onError, onChange: onChangeAvailables,
  });

  const displayFilter = useMemo(
    () => Object.keys(filter).map((k, index) => {
      const pt = filter[k].LeftValue.split('.');
      const path = pt.reduce((R, p, i) => [
        ...R,
        ...(i ? ['Items'] : []),
        pt.slice(0, i + 1).join('.'),
      ], []);
      // eslint-disable-next-line no-confusing-arrow
      const af = path.reduce((R, ak) => R ? R[ak] || null : null, availableFields);
      const isArray = [DC.comparisonTypes.inList.value,
        DC.comparisonTypes.inListByHierarchy.value,
        DC.comparisonTypes.notInList.value,
        DC.comparisonTypes.notInListByHierarchy.value,
      ].includes(filter[k].ComparisonType);
      const allowedComparations = af ? af.AvailableCompareTypes.reduce((R, ct) => {
        const type = Object.values(DC.comparisonTypes).filter((stdType) => stdType.value === ct);
        if (type.length) {
          return [
            ...R,
            {
              display_name: type[0].label,
              value: type[0].value,
            },
          ];
        }
        return R;
      }, []) : [];
      return {
        id: k,
        item: filter[k],
        af,
        index,
        isArray,
        title: getTilte(filter[k], af),
        allowedComparations,
        errored: !af,
        type: af ? af.ValueType[0] : null,
      };
    }),
    [availableFields, filter],
  );

  const onActivate = useCallback(
    // eslint-disable-next-line no-unused-vars
    (id, multiple) => {
      setActive(id);
    },
    [],
  );

  const toggleUseFilter = useCallback(
    (id) => onChange((f) => ({
      ...f,
      [id]: {
        ...f[id],
        Use: !f[id].Use,
      },
    })),
    [onChange],
  );
  const changeComparisson = useCallback(
    (id, v) => onChange((f) => {
      if (v === f[id].ComparisonType) return f;
      const isArray = [
        DC.comparisonTypes.inList.value,
        DC.comparisonTypes.inListByHierarchy.value,
        DC.comparisonTypes.notInList.value,
        DC.comparisonTypes.notInListByHierarchy.value,
      ].includes(v);
      if (isArray) {
        return {
          ...f,
          [id]: {
            ...f[id],
            ComparisonType: v,
            Use: true,
            RightValue: Array.isArray(f[id].RightValue)
              ? f[id].RightValue : [f[id].RightValue],
          },
        };
      }
      return {
        ...f,
        [id]: {
          ...f[id],
          ComparisonType: v,
          Use: true,
          RightValue: !Array.isArray(f[id].RightValue)
            ? f[id].RightValue : f[id].RightValue[0],
        },
      };
    }),
    [onChange],
  );
  const changeValue = useCallback(
    (id, v) => onChange((f) => ({
      ...f,
      [id]: {
        ...f[id],
        RightValue: v,
        Use: true,
      },
    })),
    [onChange],
  );

  const onAdd = useCallback(
    (key, newIndex = null) => {
      onChange((f) => {
        const keys = Object.keys(f);
        const nKey = newIndex === null || newIndex >= keys.length ? `${Math.max(...[0, ...keys.map((k) => Number(k))]) + 1}` : keys[newIndex];
        const newFilter = keys.reduce((R, k) => ({
          ...R,
          [Number(k) < Number(nKey) ? k : `${Number(k) + 1}`]: f[k],
        }), {
          [nKey]: {
            Type: 'FilterItem',
            LeftValue: key,
            ComparisonType: comparisonTypes.equal.value,
            RightValue: null,
            Use: false,
            Order: 0,
            Parent: null,
            Presentation: '',
            ViewMode: 'QuickAccess',
          },
        });
        return newFilter;
      });
    },
    [onChange],
  );
  const onDelete = useCallback(
    (id) => onChange((f) => Object.keys(f).reduce(
      // eslint-disable-next-line no-confusing-arrow
      (R, k) => k === id ? R : ({ ...R, [k]: f[k] }),
      {},
    )),
    [onChange],
  );
  const filters = useMemo(
    () => ({
      items: displayFilter,
      activeItem: active,
      onActivate,
      onToggleUse: toggleUseFilter,
      onChangeComparisson: changeComparisson,
      onChangeValue: changeValue,
      onAdd,
      onDelete,
    }),
    // eslint-disable-next-line max-len
    [active, changeComparisson, changeValue, displayFilter, onActivate, onAdd, onDelete, toggleUseFilter],
  );

  return {
    filters,
    availables,
  };
};

export const filterValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }),
]);
