import {
  defaultColumns,
  directions, hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  DateCl: { label: 'Дата', name: 'ДатаКл', visible: true },
  FullName: {
    label: 'Повне найменування на дату',
    name: 'ПолнНаименование',
    visible: true,
  },
  tCode: { label: 'Код на дату', name: 'тКод', visible: true },
  Folder: { label: 'Папка', name: 'Родитель', visible: true },
};

export const kfkvkClassifier = {
  label: 'Класифікація функціональна (КФК)',
  name: 'kfkvkClassifier',
  backendName: 'КлассификаторКФКВК',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/kfkvkClassifier',
  listColumns: [...new Set([...reqListerColumns, 'FullName'])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kfkvkClassifier;
