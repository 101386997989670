import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow className="px-4">
      <Col
        sm={6}
        className="d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
      <Col sm={3}>
        <Row>
          <Col>
            <TableHeaderCell
              className="d-flex flex-column justify-content-end"
              active={activeCol === 'fin_commitment_amount'}
              highlighted={highlights.includes('fin_commitment_amount')}
            >
              &nbsp;
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="d-flex flex-column justify-content-end"
              active={activeCol === 'balance'}
              highlighted={highlights.includes('balance')}
            >
              &nbsp;
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="d-flex flex-column justify-content-end text-end"
              active={activeCol === 'amount'}
              highlighted={highlights.includes('amount')}
            >
              {totals.amount.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col
        sm={3}
        className="d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
