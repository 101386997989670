import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

function SaveNProceedButton({ text, ...props }) {
  return <Button primary {...props}>{text}</Button>;
}

SaveNProceedButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

SaveNProceedButton.defaultProps = {
  text: 'Зберегти та закрити',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default SaveNProceedButton;
