import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import style from 'styled-components';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import { DateRangeField } from '../../../components/fields';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  FrameDiv,
} from '../../../components/Form/styledForm';
import session from '../../../api/session';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import AttachedFiles from '../attachedFiles/attachedFiles';
import { CommandPanelButton } from '../../../components/button';
import storePathParam from '../../../common/storePathParam';

const md = meta.dp.importTreasFiles;
const Text30 = Fields.GetTextInput(30);
const Text4 = Fields.GetTextInput(4);

const FormatFileAssigmentTypes = {
  VD: { value: 0, label: 'VD' },
  VDT: { value: 1, label: 'VDT' },
};

const ContainerColumns = style(MyContainerFormWithNColumns)`
  grid-template-columns: 39% 19%;
`;

class ImportTreasFilesEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    attachedFiles: PropTypes.instanceOf(List).isRequired,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.isAdmin = session.get('is_admin');
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      dispatch, headerForm, attachedFiles,
    } = this.props;
    return (
      <FrameDiv>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.Budget.label}</StyledLabel>
            {this.isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('Budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'Budget'], value));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('Budget', new Map())}
              />
            )}
          </div>
          <div>
            <StyledLabel>{md.columns.FI.label}</StyledLabel>
            {this.isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], value));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('FI', new Map())}
              />
            )}
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={5}>
          <div>
            <StyledLabel>{md.columns.FormatFileAssigment.label}</StyledLabel>
            <RadioButton
              values={Object.values(FormatFileAssigmentTypes)}
              value={headerForm.get('FormatFileAssigment', 0)}
              onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'FormatFileAssigment'], value))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.OnlyAssigment.label}
              value={headerForm.get('OnlyAssigment', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OnlyAssigment'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.OnlyTax.label}
              value={headerForm.get('OnlyTax', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OnlyTax'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.FileFI.label}
              value={headerForm.get('FileFI', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FileFI'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.LoadEstimate.label}
              value={headerForm.get('LoadEstimate', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'LoadEstimate'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.FormatFileAssigment.label}</StyledLabel>
            <Text30
              radius
              background
              value={headerForm.get('MaskFileTax', '')}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.Import2.label}
              value={headerForm.get('Import2', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Import2'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <ContainerColumns>
          <div>
            <StyledLabel>{md.columns.ReportPeriod.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('ReportPeriod', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'ReportPeriod'], value));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.CurrentYear.label}</StyledLabel>
            <Text4
              radius
              background
              value={headerForm.get('CurrentYear', '')}
            />
          </div>
        </ContainerColumns>
        <CommandPanelButton
          label="Обробити файли"
          text="Обробити файли"
          onClick={() => {
            attachedFiles.map((el) => dispatch(actions.processServerReq('uploadFiles', { files: new List([el]) })));
          }}
        />
        <div>
          <AttachedFiles
            noStyle
            positiveFiles={headerForm.get('PositiveFiles', new List())}
            restrictDelete
          />
        </div>

      </FrameDiv>
    );
  }
}

const mapStateDP = (store) => {
  const { node, name, id } = storePathParam(store);
  const nameDp = store.getIn([node, id, 'dpEditor'], name);
  const editorNode = store.get(`dp/${nameDp}/dpEditor`, new Map());

  return {
    ...mapState(store),
    attachedFiles: editorNode.get('AttachedFiles', new List()),
  };
};

const enchance = compose(
  connect(mapStateDP),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // importTreasFiles',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ImportTreasFilesEditor);
