import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import RowCard from './rowCardForHooks';
import './baseTablePart.css';
import HeaderComponent from './TablePart/Header';
import FooterComponent from './TablePart/Footer';
import useBaseTablePart from './hooks/useBaseTablePart';
import TablePartGrid from './tablePartGrid';

function BaseTP({
  tableName,
  data,
  arrBypassGrid,
  gridTemplateParam,
  headerCol,
  footerCol,
  componentsType,
  tableComponents,
  reFreshForm,
  containerRef,
}) {
  const { activeNameCell, processingKeyDown } = useBaseTablePart({
    tableName,
    data,
    arrBypassGrid,
  });

  const {
    isHeaderComponent,
    isRowComponent,
    isFooterComponent,
  } = tableComponents;
  return (
    // <div className="tablePartGrid" style={{ overflow: 'inherit' }}>
    <TablePartGrid gridTemplateParam={gridTemplateParam}>
      {isHeaderComponent && (
        <HeaderComponent
          headerCol={headerCol}
          activeNameCell={activeNameCell}
        />
      )}
      {isRowComponent && (
        <div className="body">
          {data.map((row, key) => (
            <RowCard
              key={`${tableName}${key}`}
              processingKeyDown={processingKeyDown}
              rowId={row.get('orderNo', key)}
              row={row}
              componentsType={componentsType}
              tableName={tableName}
              activeNameCell={row.get('IS_ACTIVE', false) ? activeNameCell : ''}
              arrBypassGrid={arrBypassGrid}
              reFreshForm={reFreshForm}
              containerRef={containerRef}
            />
          ))}
        </div>
      )}
      {isFooterComponent && (
        <FooterComponent
          data={data}
          activeNameCell={activeNameCell}
          gridTemplateParam={gridTemplateParam}
          footerCol={footerCol}
        />
      )}
    </TablePartGrid>
  );
}

BaseTP.propTypes = {
  data: PropTypes.instanceOf(List),
  componentsType: PropTypes.shape({ subProp: PropTypes.bool }),
  tableComponents: PropTypes.shape({
    isHeaderComponent: PropTypes.bool,
    isRowComponent: PropTypes.bool,
    isFooterComponent: PropTypes.bool,
  }),
  tableName: PropTypes.string,
  gridTemplateParam: PropTypes.shape({
    gridTemplateAreas: PropTypes.string,
    gridTemplateColumns: PropTypes.string,
  }),
  headerCol: PropTypes.shape({}),
  footerCol: PropTypes.shape({}),
  arrBypassGrid: PropTypes.arrayOf(PropTypes.string),
  reFreshForm: PropTypes.bool,
  containerRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }).isRequired,
};

BaseTP.defaultProps = {
  data: new List(),
  tableComponents: {},
  componentsType: {},
  tableName: '',
  gridTemplateParam: {},
  headerCol: {},
  footerCol: {},
  arrBypassGrid: [],
  reFreshForm: false,
};

export default BaseTP;
