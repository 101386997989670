import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.directionsGBFact;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'elementKFK'}
            highlighted={highlights.includes('elementKFK')}
            onToggleHighlght={() => onToggleHighlght('elementKFK')}
          >
            {meta.columns.elementKFK.label}
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'sum2'}
            highlighted={highlights.includes('sum2')}
            onToggleHighlght={() => onToggleHighlght('sum2')}
          >
            {year - 2}
            {' '}
            p.
          </TableHeaderCell>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {meta.columns.zf2.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <Row>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'sf2'}
                highlighted={highlights.includes('sf2')}
                onToggleHighlght={() => onToggleHighlght('sf2')}
              >
                {meta.columns.sf2.label}
              </TableHeaderCell>
            </Row>
            <Row>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'br2'}
                highlighted={highlights.includes('br2')}
                onToggleHighlght={() => onToggleHighlght('br2')}
              >
                {meta.columns.br2.label}
              </TableHeaderCell>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'sum1'}
            highlighted={highlights.includes('sum1')}
            onToggleHighlght={() => onToggleHighlght('sum1')}
          >
            {year - 1}
            {' '}
            p.
          </TableHeaderCell>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {meta.columns.zf1.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <Row>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'sf1'}
                highlighted={highlights.includes('sf1')}
                onToggleHighlght={() => onToggleHighlght('sf1')}
              >
                {meta.columns.sf1.label}
              </TableHeaderCell>
            </Row>
            <Row>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'br1'}
                highlighted={highlights.includes('br1')}
                onToggleHighlght={() => onToggleHighlght('br1')}
              >
                {meta.columns.br1.label}
              </TableHeaderCell>
            </Row>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
