import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';

const ElementCommandPanel = forwardRef((_, ref) => (
  <CatListerCommandPanel ref={ref}>

    <ButtonGroup />

  </CatListerCommandPanel>
));

ElementCommandPanel.propTypes = {
  actions: PropTypes.shape().isRequired,
  permissions: PropTypes.shape().isRequired,
  period: PropTypes.shape().isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

ElementCommandPanel.defaultProps = {
  selectedRows: [],
};
export default ElementCommandPanel;
