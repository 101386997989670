import React, {
  Fragment, memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { decimalToStr } from '../../../../../../common/funcs';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  budget,
  usePCM,
}) {
  const targetProps = tableHooks.useItemInputProps('target', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const targetFilter = useMemo(() => ({
    budget: budget ? budget.id : null,
  }), [budget]);
  const directionProps = tableHooks.useItemInputProps('direction', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const directionFilter = useMemo(() => ({
    budget: budget ? budget.id : null,
  }), [budget]);
  const indicatorProps = tableHooks.useItemInputProps('indicator', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const indicatorFilter = useMemo(() => ({
    owner: row.direction ? row.direction.id : null,
  }), [row.direction]);
  const goalIndicatorProps = tableHooks.useItemInputProps('goal_indicator', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountZf0Appr = tableHooks.useNumberInputProps('amount_zf0_appr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountSf0Appr = tableHooks.useNumberInputProps('amount_sf0_appr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountZf0Prop = tableHooks.useNumberInputProps('amount_zf0_prop', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountSf0Prop = tableHooks.useNumberInputProps('amount_sf0_prop', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountZf1Appr = tableHooks.useNumberInputProps('amount_zf1_appr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountSf1Appr = tableHooks.useNumberInputProps('amount_sf1_appr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountZf1Prop = tableHooks.useNumberInputProps('amount_zf1_prop', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountSf1Prop = tableHooks.useNumberInputProps('amount_sf1_prop', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  return (
    <>
      <Col
        className="p-0 ps-3"
      >
        <TabelRowCell column="target" highlighted={highlights.includes('target')}>
          <TableControls.ItemPicker
            {...targetProps}
            filter={targetFilter}
          />
        </TabelRowCell>
        {usePCM ? (
          <TabelRowCell column="direction" highlighted={highlights.includes('direction')}>
            <TableControls.ItemPicker
              {...directionProps}
              filter={directionFilter}
              canErase={false}
            />
          </TabelRowCell>
        ) : (
          <TabelRowCell column="goal_indicator" highlighted={highlights.includes('goal_indicator')}>
            <TableControls.ItemPicker {...goalIndicatorProps} canErase={false} />
          </TabelRowCell>
        )}
        {usePCM && (
        <TabelRowCell column="indicator" highlighted={highlights.includes('indicator')}>
          <TableControls.ItemPicker {...indicatorProps} filter={indicatorFilter} canErase={false} />
        </TabelRowCell>
        )}
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf0_appr"
          highlighted={highlights.includes('amount_zf0_appr')}
        >
          <TableControls.NumberInput
            {...amountZf0Appr}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_zf0_appr: v,
              amount_zf0_dif: Math.round((v - (row.amount_zf0_prop || 0)) * 100) / 100,
            })}
          />
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf0_appr"
          highlighted={highlights.includes('amount_sf0_appr')}
        >
          <TableControls.NumberInput
            {...amountSf0Appr}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_sf0_appr: v,
              amount_sf0_dif: Math.round((v - (row.amount_sf0_prop || 0)) * 100) / 100,
            })}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf0_prop"
          highlighted={highlights.includes('amount_zf0_prop')}
        >
          <TableControls.NumberInput
            {...amountZf0Prop}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_zf0_prop: v,
              amount_zf0_dif: Math.round(((row.amount_zf0_appr || 0) - v) * 100) / 100,
            })}
          />
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf0_prop"
          highlighted={highlights.includes('amount_sf0_prop')}
        >
          <TableControls.NumberInput
            {...amountSf0Prop}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_sf0_prop: v,
              amount_sf0_dif: Math.round(((row.amount_sf0_appr || 0) - v) * 100) / 100,
            })}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf0_dif"
          highlighted={highlights.includes('amount_zf0_dif')}
          className="justify-content-end"
        >
          {decimalToStr(row.amount_zf0_dif, '0,00')}
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf0_dif"
          highlighted={highlights.includes('amount_sf0_dif')}
          className="justify-content-end"
        >
          {decimalToStr(row.amount_sf0_dif, '0,00')}
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf1_appr"
          highlighted={highlights.includes('amount_zf1_appr')}
        >
          <TableControls.NumberInput
            {...amountZf1Appr}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_zf1_appr: v,
              amount_zf1_dif: Math.round((v - (row.amount_zf1_prop || 0)) * 100) / 100,
            })}
          />
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf1_appr"
          highlighted={highlights.includes('amount_sf1_appr')}
        >
          <TableControls.NumberInput
            {...amountSf1Appr}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_sf1_appr: v,
              amount_sf1_dif: Math.round((v - (row.amount_sf1_prop || 0)) * 100) / 100,
            })}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf1_prop"
          highlighted={highlights.includes('amount_zf1_prop')}
        >
          <TableControls.NumberInput
            {...amountZf1Prop}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_zf1_prop: v,
              amount_zf1_dif: Math.round(((row.amount_zf1_appr || 0) - v) * 100) / 100,
            })}
          />
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf1_prop"
          highlighted={highlights.includes('amount_sf1_prop')}
        >
          <TableControls.NumberInput
            {...amountSf1Prop}
            onChange={(e, v) => onChange(e, rowIndex, {
              amount_sf1_prop: v,
              amount_sf1_dif: Math.round(((row.amount_sf1_appr || 0) - v) * 100) / 100,
            })}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell
          column="amount_zf1_dif"
          highlighted={highlights.includes('amount_zf1_dif')}
          className="justify-content-end"
        >
          {decimalToStr(row.amount_zf1_dif, '0,00')}
        </TabelRowCell>
        <TabelRowCell
          column="amount_sf1_dif"
          highlighted={highlights.includes('amount_sf1_dif')}
          className="justify-content-end"
        >
          {decimalToStr(row.amount_sf1_dif, '0,00')}
        </TabelRowCell>
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  usePCM: PropTypes.bool,
  budget: foreignPropType,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  budget: null,
  usePCM: false,
};

export default memo(TRow);
