import React, {
  memo, useCallback,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'regionPrograms';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="targetedPrograms" highlighted={highlights.includes('targetedPrograms')}>
              <ItemPicker
                value={row[tableMD.columns.targetedPrograms.name]}
                modelType="cat"
                modelName="targetedPrograms"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.targetedPrograms.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.elementKFK.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sumTotal" highlighted={highlights.includes('sumTotal')}>
              <NumberInput
                value={row[tableMD.columns.sumTotal.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sumTotal.name]: value,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sumGenF" highlighted={highlights.includes('sumGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sumGenF.name]: value,
                  [tableMD.columns.sumTotal.name]: value
                  + (row[tableMD.columns.sumSpecF.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumSpecF" highlighted={highlights.includes('sumSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumSpecF.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sumSpecF.name]: value,
                  [tableMD.columns.sumTotal.name]: value
                  + (row[tableMD.columns.sumGenF.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
