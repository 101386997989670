import React, { forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import PrintButton from './pirntButton';

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  >
    <ButtonGroup>
      <PrintButton />
    </ButtonGroup>

  </DocListerCommandPanel>
));

export default CommandPanel;
