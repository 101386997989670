import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import Schema from './schemas';

const overrideSchemaPermissions = {
  canNew: false,
  canEdit: false,
  canDelete: false,
  canCopy: false,
};
function SelectSchemaButton({ content, icon, onSelect }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <CPButton
        content={content}
        icon={icon}
        onClick={() => setShow(true)}
      />
      <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
        <Modal.Header closeButton>Оберіть схему</Modal.Header>
        <Modal.Body>
          <Schema.Selector
            overridePermissions={overrideSchemaPermissions}
            onChoice={(e, v) => {
              onSelect(v.id);
              setShow(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

SelectSchemaButton.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
};

SelectSchemaButton.defaultProps = {
  content: null,
  icon: null,
};

export default SelectSchemaButton;
