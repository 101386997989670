import styled from 'styled-components';

export const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

export const MainContainer = styled.div`
  position: relative;
`;
