import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Order: { label: 'Порядок', name: 'Порядок', visible: false },
};
  // frontend: `${frontendPrefix}/StewardLevel`,
  //   backend: `${backendPrefix}/УровниРаспорядителя`,
  //   listColumns: [...reqListColumns, 'code', 'name'],
  //   hierarchy: false,
// label: 'УровниРаспорядителя',
//   name: 'УровниРаспорядителя',

export const stewardLevel = {
  label: 'Рівні розпорядників',
  name: 'stewardLevel',
  backendName: 'УровниРаспорядителя',
  defaultOrder,
  columns,
  frontend: 'cat/stewardLevel',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default stewardLevel;
