import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { CommandPanelButtonText } from '../../../../components/Form/styledForm';
import ExportVDBFKievEditor from '../../../dataProcessors/exportVDBFKiev/editor';

function DbfModal({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );

  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={selectedRows.length === 0}>
        ДБФ
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        triggerButton={
          <CommandPanelButtonText label="ДБФ" disabled={!selectedRows} />
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Вивантаження в ДБФ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExportVDBFKievEditor
            mapInitialOptions={{
              Document: 'Ссылка',
            }}
            mapStore={mapStore}
          />
        </Modal.Body>

      </Modal>
    </>

  );
}

DbfModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

DbfModal.defaultProps = {
  selectedRows: [],
};

export default DbfModal;
