import React, {
  memo, useMemo, useCallback,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { List, Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  CommandPanelColor, Message,
} from '../../../components/Form/styledForm';
import { OkIcon } from '../../../assets/icons';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';

const md = meta.dp.sendFinRequestToHOC;

function SendFinRequestToHOCEditor({ onSend }) {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);

  const displayAccounts = useMemo(
    () => headerForm.get('allowedAccounts', new List()).map((a) => ({ value: a.get('id'), label: a.get('repr') })),
    [headerForm],
  );
  const onAccountSelect = useCallback(
    (e, v) => {
      const repr = headerForm.get('allowedAccounts', new List()).filter((a) => a.get('id') === v).first().get('repr');
      dispatch(actions.changeField(['headerForm', 'account'], new Map({ id: v, repr })));
    },
    [dispatch, headerForm],
  );
  return (
    <FrameDiv>
      <MyContainerFormWithNColumns columns={1}>
        <div>
          <RadioButton
            value={headerForm.getIn(['account', 'id'], null)}
            onChange={onAccountSelect}
            values={displayAccounts}
          />
        </div>
      </MyContainerFormWithNColumns>
      {headerForm.get('OK', false) && (
        <Message>
          <OkIcon />
          &nbsp;
          Документи передані до розпорядника вищого рівня
        </Message>
      )}
      <CommandPanelColor
        style={{ marginTop: '10px' }}
        text="Відправити до розпорядника вищого рінвя"
        label="Відправити"
        disabled={headerForm.get('OK', false)}
        onClick={() => {
          dispatch(actions.processServerReq('SEND'));
        }}
      />
    </FrameDiv>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportPlansAndChangesToDBF',
    },
    {
      menu: () => null,
      tables: null,
    },
  ),
  memo,

);

export default enchance(SendFinRequestToHOCEditor);
