import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col className="text-right font-weight-bold">Разом</Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'gloans_zf'}
          highlighted={highlights.includes('gloans_zf')}
        >
          {totals.gloans_zf.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'gloans_sf'}
          highlighted={highlights.includes('gloans_sf')}
        >
          {totals.gloans_sf.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'gloans_db'}
          highlighted={highlights.includes('gloans_db')}
        >
          {totals.gloans_db.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'gloans_total'}
          highlighted={highlights.includes('gloans_total')}
        >
          {totals.gloans_total.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    gloans_db: PropTypes.number,
    gloans_zf: PropTypes.number,
    gloans_sf: PropTypes.number,
    gloans_total: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
