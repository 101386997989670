import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';

function TransfertsRowSet({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [...Array(3)].reduce((R, _, i) => [
        ...R,
        {
          field: `amount_${i + 1}_zf`,
          headerLabel: `План на ${data.year + i + 1}р. З.Ф.`,
        },
        {
          field: `amount_${i + 1}_sf`,
          headerLabel: `План на ${data.year + i + 1}р. С.Ф.`,
        },
      ], [
        {
          field: 'kdb',
        },
      ]),
    }),
    [data.year],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="forecastprepareadd1transferts_set"
    />
  );
}

TransfertsRowSet.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
  }).isRequired,
};

export default TransfertsRowSet;
