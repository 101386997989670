import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import RowComponent from './RowComponent';
import { PROPERTY_NAME, VALUE_NAME } from './consts';

function AnaliticsTable({ data, onChange }) {
  const onRowChange = useCallback(
    (e, i, value) => {
      onChange(e, data.map((row, rowIndex) => (rowIndex === i ? ({
        ...row,
        ...value,
      }) : row)));
    },
    [data, onChange],
  );
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Властивість</th>
          <th>Значення</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <RowComponent row={row} rowId={i} onChange={onRowChange} key={row[PROPERTY_NAME].repr} />
        ))}
      </tbody>
    </Table>
  );
}

AnaliticsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    [PROPERTY_NAME]: PropTypes.shape({
      id: PropTypes.string,
      repr: PropTypes.string,
    }),
    [VALUE_NAME]: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.string,
        repr: PropTypes.string,
      }),
    ]),
  })),
  onChange: PropTypes.func.isRequired,
};

AnaliticsTable.defaultProps = {
  data: [],
};

export default AnaliticsTable;
