import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import EditorCheckbox from '../../../basicEditor/editorControls/checkbox';

function BringDocuments({ data, allowed, onChange }) {
  const checked = useMemo(
    () => new Set(data.map((d) => d.bring_document.id)),
    [data],
  );
  const onChangeHanlder = useCallback(
    (e, id) => {
      if (checked.has(id)) return onChange(e, data.filter((dd) => dd.bring_document.id !== id));
      return onChange(e, [
        ...data,
        { bring_document: allowed.filter((al) => al.id === id).reduce((R, r) => r, {}) },
      ]);
    },
    [allowed, checked, data, onChange],
  );
  return (
    <div>
      {allowed.map((al) => (
        <EditorCheckbox
          key={al.id}
          controlId={`Check_${al.id}`}
          label={al.repr}
          value={checked.has(al.id)}
          onChange={(e) => onChangeHanlder(e, al.id)}
        />
      ))}
    </div>
  );
}

BringDocuments.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    bring_document: PropTypes.shape({
      id: PropTypes.number.isRequired,
      repr: PropTypes.string.isRequired,
    }),
  })),
  allowed: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func.isRequired,
};

BringDocuments.defaultProps = {
  data: [],
  allowed: [],
};

export default memo(BringDocuments);
