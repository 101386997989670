import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Card, Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import HistoryIndicator from './history';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorFooter from '../../../../components/Containers/editorFooter';
import typesOfChoices from '../../../../constants/typesOfChoices';

const BACKENDURL = meta.cat.pcmindicator.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data,
    actions,
    fieldErrors,
    headerReadOnlyFields,
    fields,
    nonFieldErrors,
    options,
    isNew,
    changed,
    permissions,
    loading,
    systemErrors,

  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const ownerProps = editorHooks.useItemInputProps('owner', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const unitProps = editorHooks.useItemInputProps('unit_of_measure', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const infosourceProps = editorHooks.useItemInputProps('infosource', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const taskClassifierProps = editorHooks.useItemInputProps('task_classifier', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const orderNoProps = editorHooks.useNumberInputProps('order_no', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const indicatorClassifierProps = editorHooks.useItemInputProps('indicator_classifier', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const traitTypeProps = editorHooks.useSelectorInputProps('trait_type', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const indicatorTypeProps = editorHooks.useSelectorInputProps('indicator_type', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dontShowInReportsProps = editorHooks.useCheckboxInputProps('dont_show_in_reports', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dontShowInApp1Props = editorHooks.useCheckboxInputProps('dont_show_in_app_1', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isMedianProps = editorHooks.useCheckboxInputProps('is_median', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
      owner: data.owner ? data.owner.id : null,
    }),
    [data.owner],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.history && data.history.name}
    >
      <Card style={{ marginBottom: '10px' }}>
        <Card.Body>
          <Container fluid className="pb-3">
            {data.is_group && (
            <div className="w-100 text-end">
              <Badge bg="info">Група</Badge>
            </div>
            )}
            <Row>
              <Col md={6}>
                <EditorControls.ItemPicker {...ownerProps} />
              </Col>
              <Col md={6}>
                <EditorControls.ItemPicker
                  {...parentProps}
                  filter={parentFilter}
                  typeOfChoice={typesOfChoices.onlyGroups}
                />
              </Col>
              <Col md={3}>
                <EditorControls.ItemPicker {...unitProps} />
              </Col>
              <Col md={3}>
                <EditorControls.ItemPicker {...infosourceProps} />
              </Col>
              <Col md={3}>
                <EditorControls.ItemPicker {...taskClassifierProps} />
              </Col>
              <Col md={3}>
                <EditorControls.ItemPicker {...indicatorClassifierProps} />
              </Col>
              <Col md={4}>
                <EditorControls.NumberInput {...orderNoProps} />
              </Col>
              <Col md={4}>
                <EditorControls.SelectorInput {...traitTypeProps} required />
              </Col>
              <Col md={4}>
                <EditorControls.SelectorInput {...indicatorTypeProps} required />
              </Col>
              <Col sm={12}>
                <EditorControls.CheckboxInput {...dontShowInReportsProps} />
                <EditorControls.CheckboxInput {...dontShowInApp1Props} />
                <EditorControls.CheckboxInput {...isMedianProps} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <HistoryIndicator
        id={id}
        data={data}
        actions={actions}
        fields={fields}
        readOnlyFields={headerReadOnlyFields}
        errors={fieldErrors}
      />
      <EditorFooter>
        <div className="text-muted">{data.budget ? data.budget.repr : ''}</div>
      </EditorFooter>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
