import PropTypes from 'prop-types';
import React from 'react';

import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import {
  TABL1TP, DocsTP, TABL2TP, TABL3TP,
} from './tabs';
import md from '../../../../constants/meta/documents/garanty';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function GarantyTabs({
  data, actions, permissions,
}) {
  const readOnly = !permissions.canChange;

  // const [key, setKey] = useState('form2');
  return (
    <Tabs>
      <Tab title={md.tables.TABL1.label} eventKey="TABL1">
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.PROJ_NAME.label}
              value={data[md.columns.PROJ_NAME.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.PROJ_NAME.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.EDRPOU_KRED.label}
              value={data[md.columns.EDRPOU_KRED.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.EDRPOU_KRED.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.KRED_NAME.label}
              value={data[md.columns.KRED_NAME.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.KRED_NAME.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.DateInput
              label={md.columns.DATE_CONCL.label}
              value={data[md.columns.DATE_CONCL.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.DATE_CONCL.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.POZ_NUM.label}
              value={data[md.columns.POZ_NUM.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.POZ_NUM.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
        </Row>
        <TABL1TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.TABL2.label} eventKey="TABL2">
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.PROJ_NAME.label}
              value={data[md.columns.PROJ_NAME.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.PROJ_NAME.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.EDRPOU_KRED.label}
              value={data[md.columns.EDRPOU_KRED.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.EDRPOU_KRED.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.KRED_NAME.label}
              value={data[md.columns.KRED_NAME.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.KRED_NAME.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
          <Col>
            <EditorControls.DateInput
              label={md.columns.DATE_CONCL.label}
              value={data[md.columns.DATE_CONCL.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.DATE_CONCL.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.NUM_POZ.label}
              value={data[md.columns.NUM_POZ.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.NUM_POZ.name]: value,
              })}
              readOnly={readOnly}
              rows={1}
            />
          </Col>
        </Row>
        <TABL2TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.TABL3.label} eventKey="TABL3">
        <TABL3TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Примітка" eventKey="note">
        <Container fluid className="mx-1 my-4">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

GarantyTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default GarantyTabs;
