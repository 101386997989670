import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowDownIcon, ArrowUpIcon } from '../../assets/icons';
import Button from '../styledAssigmButton/assigmButton';
import { CommandPanelButton } from '../button';
import { StyledDiv } from '../fields/DateRangeField';
import StyledTPPane from '../tableParts/TPPane';

export const DivForTableLabel = styled.div`
  border-bottom: 1px solid #afbbcc;
`;
export const FrameDiv = styled.div`
  // border: 1px solid #DDE2E9;
  border: 1px solid #afbbcc;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  // background: #e9f0f85e;
  background: #e9f0f8d1;
  & > :nth-last-child(2){
  margin: 0;
  grid-row-gap: 0;
  }
`;
// export const ResultSegment = styled(Frame)`
//   max-height: 100vh;
// `;
export const FramePrintModal = styled(FrameDiv)`
  margin: 0px;
  position: absolute;
  z-index: 1;
  background: #f4f6f8;
  width: 100%;
  box-shadow: 0px 5px 22px -1px rgba(191,206,219,1);
  border-top: 2px solid #b8b8dc;
`;
export const RecivedDiv = styled.div`
  font-weight: bolder;
  color: #ff000096;
  border: 2px dashed #ff000096;
  box-shadow: 0px 0px 9px 0px #c00;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  background: white;
`;
export const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 1.33%;
  grid-row-gap: 15px;
  align-items: end;
  margin-bottom: 10px;
`;

export const OneContainerForm = styled(ContainerForm)`
  grid-template-columns: 99%;
  margin-bottom: 10px;
  height: 40%;
  overflow: auto;
`;

export const ContainerTwoColumn = styled(ContainerForm)`
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
`;

export const ContainerThreeColumn = styled(ContainerForm)`
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
`;

export const ContainerFourColumn = styled(ContainerForm)`
`;

export const ContainerFiveColumn = styled(ContainerForm)`
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-column-gap: 1.25%;
`;

export const ContainerSevenColumn = styled(ContainerForm)`
  grid-template-columns: 13% 13% 13% 13% 13% 13% 13%;
  grid-column-gap: 1.5%;
`;

export const StyledLabel = styled.label`
  margin-bottom: 4px;
  display: block;
   
  font: Poppins sans-serif;
  line-height: 1.2;
  color: #495057;
  font-size: .95rem;
  font-weight: 500;
  
`;
export const StyledGreyLabel = styled(StyledLabel)`
  color: grey;
`;
export const SubText = styled.span`
  font-weight: normal;
  font-style: italic;
`;

export const StyledLabelForSingleTab = styled(StyledLabel)`
  background: linear-gradient(180deg,#E5EDF7 0%,#fcfdff 100%); 
  border: solid #afbbcc; 
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  font-weight: 700;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  width: fit-content;
`;
export const ExpandArrow = styled(ArrowDownIcon)`
  float: right;
  background: white;
  border-radius: 50%;
  border-left: 1.4px solid #afbbcc;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
`;
export const CollapseButton = styled(ArrowUpIcon)`
  float: right;
  margin-top: 5px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  // border-right: 1.4px solid #dde0e9;
  border-right: 1.4px solid #afbbcc;
  padding: 5px;
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  background: #15b615;
  color: white;
  font-size: 1em;
  border:1px solid;
  &:hover{
    background: #0e8d0e;
  };
  &:active{
    border: 1px solid black;
    background: #15b615;
  };
`;

export const TwoColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  align-items: start;
`;
export const TwoColGridContainer = styled(TwoColumnsGridContainer)`
  margin: 0px;
`;
export const ThreeColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 5%;
  align-items: start;
`;
export const HeaderColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 0.5fr 1fr 15%;
`;
export const FiveColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-column-gap: 1.25%;
`;
export const FourColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 33% 34% 15% 15%;
  grid-column-gap: 1%;
`;
export const SevenColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 10% 10% 10% 14% 14% 15% 17.5%;
  grid-column-gap: 1.58%;
  background: #f5f3f3;
  align-items: start;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
`;

export const EightColumnsGridContainer = styled(ContainerForm)`
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
  grid-column-gap: 1.58%;
  background: #f5f3f3;
  align-items: start;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
`;

export const SevenColumnsGrid = styled(SevenColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15% 10% 10% 10.5%;
`;

export const ContainerSettihgs = styled.div`
  background: #f5f3f3;
  align-items: start;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
`;
export const ExitCommandButton = styled(CommandPanelButton)`
  background: white; 
  &:hover{
  background: white 
  }
  
`;

export const CommandPanelButtonText = styled(CommandPanelButton)`
  padding: 8.5px 10px;
  font-size: 1em;
`;

export const FineButton = styled(CommandPanelButtonText)`
  display: flex;
  background: white;
  border: 1px solid #a2b4e8;
  color: #5e93d0;
  border-radius: 0.3rem;
`;

export const CommandPanelColor = styled(CommandPanelButtonText)`
  background: #15b615;
  color: white;
  border:1px solid;
  &:hover{
    background: #0e8d0e;
  };
  &:active{
    border: 1px solid black;
    background: #15b615;
  };
`;

export const NavButton = styled(Button)`
  font-size: 1em;
  color: #007bffa3;
`;

export const Title = styled.h3`
  color: #2185d0;
  margin: 15px 0 10px;
`;
export const SubTitle = styled(Title)`
  font-size: 14px;
  margin: 0 0 5px 0;
`;
export const ContainerButton = styled.div`
  display: inline-flex;
`;
export const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;
export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
export const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;
export const ContainerRow = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 10px;
`;
export const TwoRow = styled(ContainerRow)`
  grid-template-rows: auto auto;
`;
export const ThreeRow = styled(ContainerRow)`
  grid-template-rows: auto auto auto;
`;
export const FourRow = styled(ContainerRow)`
  grid-template-rows: auto auto auto auto;
`;
export const FiveRow = styled(ContainerRow)`
  grid-template-rows: auto auto auto auto auto;
`;

export function DivWithPropsLabel({ label, ...restProps }) {
  return <div {...restProps} />;
}

DivWithPropsLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export const MyContainerFormWithNColumns = styled(ContainerForm).attrs((props) => ({
  style: {
    gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
  },
}))`
`;

MyContainerFormWithNColumns.propTypes = {
  columns: PropTypes.number.isRequired,
};

export const Message = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(180deg,#E5EDF7 0%,#fcfdff 100%); 
  border: solid #DDE2E9; 
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  font-weight: 700;
  margin-bottom: -1px;
  padding: 30px;
  // width: 100%;
  font-size: 1.5em;    
`;

export const ErrorMessage = styled(Message)`
  color: red;
`;

export const ContainerStamp = styled.div`
  z-index: 1;
  position: relative;
`;
export const ContainerSt = styled.div`
  min-width: 260px;
  margin-top: -60px;
  height: 208px;
  right: 10%;
  transform: rotate(12deg);
`;
export const ImgContainerStamp = styled(ContainerSt).attrs((props) => ({
  style: {
    background: `url(${props.background}) 100% 100% no-repeat`,
  },
}))`
`;
ImgContainerStamp.propTypes = {
  background: PropTypes.string.isRequired,
};
export const TextStamp = styled.span`
  transform: rotate(-20.5deg);
  position: absolute;
  // bottom: 30%;
  bottom: 42px;
  text-align: center;
    //right: 2.5%;
  left: 10px;
  right: 10px;
  max-width: 280px;
  line-height: 1.2;
`;
export const ColorTextStamp = styled(TextStamp).attrs((props) => ({
  style: {
    color: `${props.color}`,
  },
}))`
`;
ColorTextStamp.propTypes = {
  color: PropTypes.string.isRequired,
};

export const ModalDiv = styled.div`
  top: 0;
  left:0;
  position: fixed;
  z-index: ${({ zIndexModal }) => (1050 + zIndexModal)};
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0,0,0,.5);
  padding: 1% 4%;
`;

export const ContentDiv = styled.div`
  width: 100%;
  height: 97vh;
  background: white;
  overflow-y: auto;
  padding: 10px 20px;
`;

export const ContainerVarButton = styled.div`
  text-align: right;
  margin-top: 30px;
`;

const ButtonPortal = styled.button.attrs({
  type: 'button',
})`
  font-weight: 300;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 13.5px;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  text-align: right;
  margin-left: 10px;
`;

export const PositiveButton = styled(ButtonPortal)`
  background: #15b615;
  ${(props) => (props.disabled
    ? 'filter: grayscale(1) opacity(.275);'
  + 'color: black;'
    : '')}
  &:hover{
    background: #0e8d0e;
    }
`;

export const NegativeButton = styled(ButtonPortal)`
  background: #ff4405;
   &:hover{
    background: #df4410;
    }
`;

export const Header = styled.h3`
  font-size: 20px;
  color: #008F21;
  font-family: Roboto, sans-serif;
  margin-top: 10px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
