import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import {
  PrependAccount, PrependKBP7, PrependKEKV,
} from '../../../../styles/klassifiers';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authorityId,
}) {
  const kbp7ItemProps = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7ItemFilter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const kekvItemProps = tableHooks.useItemInputProps('kekv_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kekvItemFilter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const treasureAccountProps = tableHooks.useItemInputProps('treasure_account', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const treasureAccountFilter = useMemo(() => ({ disposer: authorityId }), [authorityId]);

  const receiptsProps = tableHooks.useNumberInputProps('receipts', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const excludedProps = tableHooks.useNumberInputProps('excluded', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const receivedLoansProps = tableHooks.useNumberInputProps('received_loans', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const repaidedLoansProps = tableHooks.useNumberInputProps('repaided_loans', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const totalProps = tableHooks.useNumberInputProps('total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const natureIndexAmountProps = tableHooks.useNumberInputProps('nature_index_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <>
      <Col sm={12} md={4}>
        <TabelRowCell column="treasure_account" highlighted={highlights.includes('treasure_account')}>
          <TableControls.ItemPicker
            {...treasureAccountProps}
            filter={treasureAccountFilter}
            prepend={(
              <PrependAccount>
                <InputGroup.Text className="w-100">{row.treasure_account && row.treasure_account.code}</InputGroup.Text>
              </PrependAccount>
            )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col sm={12} className="border-right">
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker
                {...kbp7ItemProps}
                filter={kbp7ItemFilter}
                prepend={(
                  <PrependKBP7>
                    <InputGroup.Text className="w-100">{row.kbp7 && row.kbp7.repr}</InputGroup.Text>
                  </PrependKBP7>
                )}
              />
            </TabelRowCell>
          </Col>
          <Col sm={12} className="border-right">
            <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
              <TableControls.ItemPicker
                {...kekvItemProps}
                filter={kekvItemFilter}
                prepend={(
                  <PrependKEKV>
                    <InputGroup.Text className="w-100">{row.kekv && row.kekv.repr}</InputGroup.Text>
                  </PrependKEKV>
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TabelRowCell column="receipts" highlighted={highlights.includes('receipts')}>
              <TableControls.NumberInput
                {...receiptsProps}
              />
            </TabelRowCell>
            <TabelRowCell column="excluded" highlighted={highlights.includes('excluded')}>
              <TableControls.NumberInput
                {...excludedProps}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TabelRowCell column="received_loans" highlighted={highlights.includes('received_loans')}>
              <TableControls.NumberInput
                {...receivedLoansProps}
              />
            </TabelRowCell>
            <TabelRowCell column="repaided_loans" highlighted={highlights.includes('repaided_loans')}>
              <TableControls.NumberInput
                {...repaidedLoansProps}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TabelRowCell column="total" highlighted={highlights.includes('total')}>
              <TableControls.NumberInput
                {...totalProps}
              />
            </TabelRowCell>
            <TabelRowCell column="nature_index_amount" highlighted={highlights.includes('nature_index_amount')}>
              <TableControls.NumberInput
                {...natureIndexAmountProps}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
}

TRow.propTypes = {
  row: PropTypes.shape(dataPropTypes).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authorityId: PropTypes.number,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  isComposeSettingsChecked: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  active: false,
  activeCol: '',
  readOnly: false,
  authorityId: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  isComposeSettingsChecked: false,
  readOnlyFields: [],
};

export default memo(TRow);
