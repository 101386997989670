const frontendURL = '/srv/importPlans/';
const backendURL = '/api/logica/import-plans/';
const name = 'Плану асигнувань та Зміни до плану асигнувань';
const tableLabels = {
  klb: 'Код місцевого бюджету',
  edrpou: 'Код ЄДРПОУ',
  year: 'Затверджений рік',
  type: 'Тип розпису',
  prog_approved: 'Ознака проект затверджено',
  id: 'ID',
  title: 'Заголовок',
  add_date: 'Дата додавання',
};

const instance = {
  frontendURL,
  backendURL,
  name,
  tableLabels,
};

export default instance;
