import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'текДата' },
  year: { label: 'Рік', name: 'Год' },
  budget: { label: 'Бюджет', name: 'текБюджет' },
  FO: { label: 'Фiнансовий орган', name: 'текФинансовыйОрган' },
  industry: { label: 'Галузь', name: 'текГалузь' },
  typesRepair: { label: 'Вид ремонту', name: 'текВидРемонта' },
  typeWork: { label: 'Вид рабіт', name: 'текВидРабот' },
  adminDistrict: { label: 'Адмiнiстративний район', name: 'АдресРайон' },
  street: { label: 'Вулиця', name: 'АдресУлица' },
  houses: { label: 'Будинок', name: 'АдресДом' },
  fixingTitle: { label: 'Фiксування зоголовку', name: 'ФиксацияЗаголовка' },
  printingType: { label: 'Вид робіт', name: 'текПереключательВидРабот' },
};

const definition = {
  label: 'Адресний перелік з капітального ремонту об\'єктів ремонту по галузям',
  name: 'AddressListCR',
  columns,
  frontend: 'rep/AddressListCR',
  backendName: 'АдресныйПереченьСКД',
  resultColumns: baseResultColumns,
};

export default definition;
