import {
  StringInput, DateInput, NumberInput, Selector, TextInput, ItemField, ItemPicker, MultiValueInput,
} from '../../../components/Controls';
import TableCheckboxInput from './checkbox';
import tableHooks from './hooks';
import withTableControl from './tableControl';

export const TableStingInput = withTableControl(StringInput);
export const TableDateInput = withTableControl(DateInput);
export const TableNumberInput = withTableControl(NumberInput);
export const TableSelectorInput = withTableControl(Selector);
export { TableCheckboxInput };
export const TableTextInput = withTableControl(TextInput);
export const TableItemField = withTableControl(ItemField);
export const TableItemPicker = withTableControl(ItemPicker);
export const TableMultiValueInput = withTableControl(MultiValueInput);

export { tableHooks };

export const TableControls = {
  StringInput: TableStingInput,
  DateInput: TableDateInput,
  NumberInput: TableNumberInput,
  SelectorInput: TableSelectorInput,
  CheckboxInput: TableCheckboxInput,
  TextInput: TableTextInput,
  ItemField: TableItemField,
  ItemPicker: TableItemPicker,
  MultiValueInput: TableMultiValueInput,
};
