import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

function CardDropdown({
  btnRevealClass,
  drop,
  children,
}) {
  return (
    <Dropdown
      className="font-sans-serif btn-reveal-trigger"
      align="start"
      drop={drop}
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass,
        })}
      >
        <FontAwesomeIcon icon={faEllipsisH} className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            <Dropdown.Item>Детальний звіт за КПКВ</Dropdown.Item>
            <Dropdown.Item>Детальний звіт за ГРК</Dropdown.Item>
            <Dropdown.Item>Детальний звіт за КЕКВ</Dropdown.Item>
            {/* <Dropdown.Divider /> */}
            {/* <Dropdown.Item className="text-danger">Remove</Dropdown.Item> */}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
};

CardDropdown.defaultProps = {
  btnRevealClass: '',
  drop: '',
  children: null,
};

export default CardDropdown;
