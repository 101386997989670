import React, { forwardRef, useContext } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import DBFButton from './DBFButton';
import ListerContext from '../../../newLister/context';
import AssigmPrintModal from '../../assignmentPlanningSource/lister/PrintButtonAssign';
import PrintModal from '../../assignmentPlanningSource/lister/PrintButton';

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <ButtonGroup>
        <Dropdown>
          <Dropdown.Toggle>
            <FontAwesomeIcon icon={faPrint} />
          </Dropdown.Toggle>
          <Dropdown.Menu>

            <AssigmPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
            <PrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />

          </Dropdown.Menu>
        </Dropdown>
        <DBFButton selectedRows={selectedRows} />
      </ButtonGroup>

    </DocListerCommandPanel>
  );
});

export default CommandPanel;
