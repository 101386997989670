import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  DateInput,
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/treasuryStatementCosts';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col>
            <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
              <ItemPicker
                value={row[tableMD.columns.CSU.name]}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSU.name]: v })}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            {' '}
            <TableRowCell column="FO" highlighted={highlights.includes('FO')}>
              <ItemPicker
                value={row[tableMD.columns.FO.name]}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.FO.name]: v })}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableRowCell column="date" highlighted={highlights.includes('date')}>
              <DateInput
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.date.name]: v })}
                value={row[tableMD.columns.date.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KTKVCode" highlighted={highlights.includes('KTKVCode')}>
              <StringInput
                value={row[tableMD.columns.KTKVCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KTKVCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="Code" highlighted={highlights.includes('Code')}>
              <StringInput
                value={row[tableMD.columns.Code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Code.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="Fond" highlighted={highlights.includes('Fond')}>
              <ItemPicker
                value={row[tableMD.columns.Fond.name]}
                modelType="cat"
                modelName="elementFond"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Fond.name]: v })}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KVK" highlighted={highlights.includes('KVK')}>
              <ItemPicker
                value={row[tableMD.columns.KVK.name]}
                modelType="cat"
                modelName="kvkClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KVK.name]: v })}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KEKV" highlighted={highlights.includes('KEKV')}>
              <ItemPicker
                value={row[tableMD.columns.KEKV.name]}
                modelType="cat"
                modelName="kekvClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KEKV.name]: v })}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="FondCode" highlighted={highlights.includes('FondCode')}>
              <StringInput
                value={row[tableMD.columns.FondCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.FondCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KVKCode" highlighted={highlights.includes('KVKCode')}>
              <StringInput
                value={row[tableMD.columns.KVKCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KVKCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KEKVCode" highlighted={highlights.includes('KEKVCode')}>
              <StringInput
                value={row[tableMD.columns.KEKVCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KEKVCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumExecution" highlighted={highlights.includes('sumExecution')}>
              <NumberInput
                value={row[tableMD.columns.sumExecution.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumExecution.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumExecutionBegYear" highlighted={highlights.includes('sumExecutionBegYear')}>
              <NumberInput
                value={row[tableMD.columns.sumExecutionBegYear.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumExecutionBegYear.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumRetained" highlighted={highlights.includes('sumRetained')}>
              <NumberInput
                value={row[tableMD.columns.sumRetained.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumRetained.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumCashExpenses" highlighted={highlights.includes('sumCashExpenses')}>
              <NumberInput
                value={row[tableMD.columns.sumCashExpenses.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumCashExpenses.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumCashExpensesBegYear" highlighted={highlights.includes('sumCashExpensesBegYear')}>
              <NumberInput
                value={row[tableMD.columns.sumCashExpensesBegYear.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumCashExpensesBegYear.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumRemaining" highlighted={highlights.includes('sumRemaining')}>
              <NumberInput
                value={row[tableMD.columns.sumRemaining.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumRemaining.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFinObligations" highlighted={highlights.includes('sumFinObligations')}>
              <NumberInput
                value={row[tableMD.columns.sumFinObligations.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumFinObligations.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="Section" highlighted={highlights.includes('Section')}>
              <StringInput
                value={row[tableMD.columns.Section.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Section.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KP" highlighted={highlights.includes('KP')}>
              <StringInput
                value={row[tableMD.columns.KP.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KP.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
