import React, {
  useEffect, useCallback, useRef, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, FormGroup, InputGroup, ButtonGroup, Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import NewEditor from './newEditor';
import OldEditor from './oldEditor';
import { logikaLogin } from '../../actions/auth';
import { buildModes } from '../../constants/bases/common';
import { dellComponentFromWindowsManager, prevWindow } from '../../actions/windowsManager';
import { EditorControls } from '../../components/bootstrap_components/editorControls';

const selector = (state) => ({
  ticket: state.getIn(['auth', 'logikaTicket'], null),
});

export const widgetModes = {
  iit: 'iit',
  // diya: 'diya',
  ciat: 'ciat',
};

function Logica({ onAuth }) {
  const [widgetMode, setWidgetMode] = useState(null);
  const widgetModeRef = useRef();

  widgetModeRef.current = widgetMode;

  useEffect(
    () => {
      const savedValue = localStorage.getItem('logica_widget_type');
      if (!(savedValue in widgetModes)) {
        // default value
        setWidgetMode(widgetModes.ciat);
      } else {
        setWidgetMode(savedValue);
      }
      return () => localStorage.setItem('logica_widget_type', widgetModeRef.current);
    },
    [],
  );

  const [inputToken, setInputToken] = useState('');

  const { ticket } = useSelector(selector);
  const dispatch = useDispatch();

  const widgetUri = useMemo(
    () => {
      if (widgetMode === widgetModes.iit) return 'https://certs.ciat.net.ua/';
      if (widgetMode === widgetModes.diya) return 'https://certs2.ciat.net.ua/';
      return '';
    },
    [widgetMode],
  );

  useEffect(
    () => {
      if (!ticket) {
        const token = localStorage.getItem('ticket');
        if (token) {
          dispatch(logikaLogin(token));
        }
      }
    },
    [dispatch, ticket],
  );

  const logIn = useCallback(
    (t) => {
      dispatch(logikaLogin(t));
      if (onAuth) onAuth();
    },
    [dispatch, onAuth],
  );
  if (widgetMode === null) return null;
  return (
    <>
      {ticket && (
        <>
          <h3>Ви вже авторизовані</h3>
          <ButtonGroup>
            <Button onClick={() => {
              dispatch(logikaLogin(''));
            }}
            >
              Нова авторізація
            </Button>
          </ButtonGroup>
          {' '}
          <ButtonGroup>
            <Button onClick={() => {
              dispatch(prevWindow());
              dispatch(dellComponentFromWindowsManager('/dp/authentication/'));
            }}
            >
              Закрити
            </Button>
          </ButtonGroup>

        </>
      )}
      {!ticket && widgetMode !== widgetModes.ciat && (
        <OldEditor
          onLogin={logIn}
          ticket={ticket}
          widgetUri={widgetUri}
        />
      )}
      {!ticket && widgetMode === widgetModes.ciat && (
        <NewEditor
          onLogin={logIn}
        />
      )}

      {!ticket && process.env.NODE_ENV === buildModes.dev && (
        <div className="mt-4 bg-light p-3 border rounded">
          <FormGroup className="floated ">
            <Form.Label>Logika ticket</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Введите сюда token"
                value={inputToken}
                onChange={(e) => setInputToken(e.target.value)}
              />

              <Button variant="outline-secondary" disabled={!inputToken} onClick={() => logIn(inputToken)}>
                Login
              </Button>

            </InputGroup>
            <Form.Text muted>
              Это поле доступно только в режиме разработки. Клиентам оно показано не будет
            </Form.Text>

          </FormGroup>
        </div>
      )}
      <Card className="mt-5 mx-2">
        <Card.Body>
          <EditorControls.CheckboxInput
            controlId="IIT-widget"
            label="Використовувати віджет"
            value={widgetMode === widgetModes.iit}
            onChange={(e, v) => setWidgetMode(v ? widgetModes.iit : widgetModes.ciat)}
          />
        </Card.Body>
      </Card>
    </>
  );
}

Logica.propTypes = {
  onAuth: PropTypes.func,
};

Logica.defaultProps = {
  onAuth: null,
};

export default Logica;
