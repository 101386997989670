import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchInput({ value, onChange, disabled }) {
  return (
    <InputGroup>
      <FormControl
        className="search"
        value={value}
        onChange={(e) => onChange(e, e.target.value)}
        placeholder="Пошук (ctrl+F)"
        disabled={disabled}
      />

      <Button variant="outline-secondary" disabled={disabled} onClick={(e) => onChange(e, '')}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>

    </InputGroup>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  disabled: false,
  value: '',
};

export default SearchInput;
