import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Col, Row, InputGroup } from 'react-bootstrap';
import LoadRequestsModal from './loadRequestsModal';
import enums from '../../../../constants/meta/enums';
import md from '../../../../constants/meta/documents/financingRequest';
import { NumberOfMounth } from '../consts';
import {
  StyledLabel, ContainerSt,
} from '../../../../components/Form/styledForm';
import { StampApprovedJs, StampFinancedJs } from '../../../../assets/icons';
import { soSelector } from '../../_common/selectors';
import {comparisonTypes, emptyUid} from '../../../../constants/meta/common';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import BankAccountTypes from '../../../../constants/meta/enums/bankAccaountTypes';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function FinancingRequest({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const FI = data[md.columns.FI.name];
  const fondCl = data[md.columns.Fond.name];
  const date = data[md.columns.date.name];
  const formDisabled = data[md.columns.IsApproved.name] || data[md.columns.Financed.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const csuFilter = useMemo(() => [{ fieldName: '_Родитель', value: FI }], [FI]);

  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const readOnly = !permissions.canChange;
  // const useFOAccounts = headerForm.get('useFOAccounts', false);

  const ComposeSettingsChecked = data[md.columns.ComposeSettings.name];
  const FinancingReturnChecked = data[md.columns.FinancingReturn.name];
  const useFOAccounts = data[md.columns.useFOAccounts.name];

  const FOParent = sessionOptions.get('foParent', new Map());
  const isFinManagment = sessionOptions.get('isFinManagment', false);

  const doNotUseAccounts = data[md.columns.doNotUseAccounts.name];

  const FiAccFilter = useMemo(
    () => {
      const getAccKind = () => {
        if (isFinManagment || useFOAccounts) {
          return {
            comparisonType: comparisonTypes.equal,
            value: BankAccountTypes.boiler.name,
          };
        }
        if (doNotUseAccounts) {
          return {
            comparisonType: comparisonTypes.equal,
            value: BankAccountTypes.reg.name,
          };
        }
        return {
          comparisonType: comparisonTypes.notEqual,
          value: BankAccountTypes.boiler.name,
        };
      };
      return [
        { fieldName: 'ИспользуетсяДляОбязательств', value: false },
        {
          fieldName: 'ВидСчета',
          ...getAccKind(),
        },
        {
          fieldName: 'Владелец',
          value: useFOAccounts ? FOParent : FI,
        },
        { fieldName: 'Фонд', value: fondCl },
      ];
    },
    [FI, FOParent, doNotUseAccounts, fondCl, isFinManagment, useFOAccounts],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}

      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.RNo.label}
            value={data[md.columns.RNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        { data[md.columns.AccountingByClients.name]
          && (
            <Col>
              <EditorControls.StringInput
                label={md.columns.RNo2.label}
                value={data[md.columns.RNo2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RNo2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          )}
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.date.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.Year.label}
            value={data[md.columns.Year.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.Year.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            readOnly={readOnly || !sessionOptions.get('is_admin', false)}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.PlaningMode.label}
            value={data[md.columns.PlaningMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PlaningMode.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.FondObject.label}
            value={data[md.columns.FondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FondObject.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={FiOwnerFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.createDate.label}
            value={data[md.columns.createDate.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.executeDate.label}
            value={data[md.columns.executeDate.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.changesAuthor.label}
            value={data[md.columns.changesAuthor.name]}
            modelType="cat"
            modelName="users"
            readOnly
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-end ">
        <Col lg={8}>
          <EditorControls.ItemPicker
            label={md.columns.FIAccount.label}
            value={data[md.columns.FIAccount.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FIAccount.name]: value,
              });
              actions.onSR('CSU_ACCOUNT_ON_CHANGE');
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={FiAccFilter}
            disabled={formDisabled}
            params={onDateParams}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {data[md.columns.FIAccNo.name]}
              </InputGroup.Text>

            )}
          />
        </Col>
        {data[md.columns.IncludeCSUIntoHead.name] && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.CSU.label}
              value={data[md.columns.CSU.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.CSU.name]: value,
              })}
              modelType="cat"
              modelName="csu"
              filter={csuFilter}
              readOnly={readOnly}
              noHierarchy
              required
            />
          </Col>
        )}
        <Col>
          <LoadRequestsModal
            onOk={handlerSelector}
            disabled={formDisabled  || data[md.columns.FondObject.name]?.id === emptyUid}
            date={data[md.columns.date.name]}
            fondObject={data[md.columns.FondObject.name]}
            FI={data[md.columns.FI.name]}
            budget={data[md.columns.Budget.name]}
            importAllNotes={data[md.columns.ImportAllNotes.name]}
            importNotes={data[md.columns.ImportReqNotes.name]}
            CSU={data[md.columns.IncludeCSUIntoHead.name] ? data[md.columns.CSU.name] : null}
            llabel={data[md.columns.FondObject.name]?.id === emptyUid ? "Заповнити з даних розпорядників нижчого рівня. Увага! Необхідно обрати фонд."
                :
                "Заповнити з даних розпорядників нижчого рівня"}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CalcBalanceByFI-${data.id}`}
            label={md.columns.CalcBalanceByFI.label}
            value={data[md.columns.CalcBalanceByFI.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.CalcBalanceByFI.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            readOnly={readOnly || !data[md.columns.CalcBalanceByFIEnabled.name]}
          />
        </Col>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`IsApproved-${data.id}`}
              label={md.columns.IsApproved.label}
              value={data[md.columns.IsApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.IsApproved.name]: value,
              })}
            />
            <EditorControls.CheckboxInput
              controlId={`Financed-${data.id}`}
              label={md.columns.Financed.label}
              value={data[md.columns.Financed.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.Financed.name]: value,
              })}
            />
          </Col>
        )}
        <Col>
          { !data[md.columns.letMinusInRow.name] && (
            <>
              <EditorControls.CheckboxInput
                controlId={`FinancingReturn-${data.id}`}
                label={md.columns.FinancingReturn.label}
                value={data[md.columns.FinancingReturn.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FinancingReturn.name]: value,
                })}
                readOnly={readOnly}
                disabled={ComposeSettingsChecked}
              />
              {data[md.columns.FinancingReturn.name]
                ? (
                  <>
                    <StyledLabel>{md.columns.NumberOfMounthReturn.label}</StyledLabel>
                    <EditorControls.SelectorInput
                      value={data[md.columns.NumberOfMounthReturn.name]}
                      values={Object.values(NumberOfMounth)}
                      onChange={async (e, value) => {
                        await actions.onChange({
                          [md.columns.NumberOfMounthReturn.name]: value,
                        });
                        actions.onSR('RENEW_TOTALS_ALL');
                      }}
                      readOnly={readOnly}
                      disabled={formDisabled}
                    />
                  </>
                )
                : (
                  <EditorControls.CheckboxInput
                    controlId={`ComposeSettings-${data.id}`}
                    label={md.columns.ComposeSettings.label}
                    value={data[md.columns.ComposeSettings.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.ComposeSettings.name]: value,
                    })}
                    readOnly={readOnly}
                    disabled={FinancingReturnChecked}
                  />
                )}
            </>
          )}
        </Col>
        {data[md.columns.IsApproved.name] && !data[md.columns.Financed.name] && (
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.Received.name]} />
            </ContainerSt>
            {sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.Received.label}
                value={data[md.columns.Received.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Received.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.Financed.name] && !data[md.columns.IsApproved.name] && (
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampFinancedJs text={data[md.columns.FinReceived.name]} />
            </ContainerSt>
            {sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.FinReceived.label}
                value={data[md.columns.FinReceived.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FinReceived.name]: value,
                })}
              />
            )}
          </Col>
        )}
      </Row>
      {data[md.columns.Financed.name] && data[md.columns.IsApproved.name] && (
        <Row>
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampFinancedJs text={data[md.columns.FinReceived.name]} />
            </ContainerSt>
            {sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.FinReceived.label}
                value={data[md.columns.FinReceived.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FinReceived.name]: value,
                })}
              />
            )}
          </Col>
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.Received.name]} />
            </ContainerSt>
            {sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.Received.label}
                value={data[md.columns.Received.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Received.name]: value,
                })}
              />
            )}
          </Col>
        </Row>
      )}
    </EditorCollapse>
  );
}

FinancingRequest.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FinancingRequest;
