import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import TableComponents from './UsersTP';

const TABLENAME = 'users';

const STORE_PATH = 'dp/messageForUsers/dpEditor';

const mapState = (state) => ({
  data: state.getIn([STORE_PATH, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [STORE_PATH, `tables/${TABLENAME}/history`],
    new List(),
  ),
  FI: state.getIn([STORE_PATH, 'headerForm', 'FI']),
});

const TP = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
);

export default connect(mapState)(TP);
