import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'tlqyx7c73GA',
  },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  plan: { label: 'План/Факт', name: 'ПланФакт', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  isApproved: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  KPKV: { label: 'КПКВ', name: 'КПКВ', visible: true },
  actualVersion: { label: 'Версія довідника "Категорії працівників"', name: 'ВерсияСправочника', visible: true },
  dictVersion: { label: 'Оберіть версію довідника для заповнення табличної частини', name: 'ВерсияСправочника', visible: true },
  /// Дополнительные свойства
  hasNewVersion: { label: 'Є нова версія', name: 'ДоступенПереходНаНовуюВерсию' },
  allKBPallowed: { label: 'ДоступноЗаполнениеПоВсемКБП', name: 'ДоступноЗаполнениеПоВсемКБП' },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденоОбл', visible: true },
  regionInvoice: { label: 'Квитанція(область)', name: 'КвитанцияОбл', visible: false },
  versionIsOk: { label: 'Версія актуальна', name: 'ВерсияОк', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Зведення показників за мережею',
    columns: {
      category: { label: 'Категорія', name: 'Категория' },
      TPKVK: { label: 'ТПКВК', name: 'ТПКВК' },
      categoryName: { label: 'Код категорії', name: 'КатегорияКод' },
      category3: { label: 'Категорія 3 зн', name: 'Категория3зн' },
      unit: { label: 'Од. вим', name: 'ЕдИзм' },
      sum1: { label: 'Планові показники за мережею на початок року', name: 'Сумма1' },
      sum2: { label: 'Планові показники за мережею на кінець року', name: 'Сумма2' },
      sum3: { label: '-', name: 'Сумма3' },
      sum4: { label: 'Планові показники за мережею середньорічна кількість', name: 'Сумма4' },
      countRow: { label: 'Номер рядка для підрахунку в формулах', name: 'РядокРасчета', visible: false },
      isBanCountS: { label: 'Заборона внесення "Середньорічна кількість" (на початок)', name: 'флЗапретитьВноситьСреднеГодКолНач', visible: true },
      isBanFactS: { label: 'Заборона внесення "Фактична наявність" (на початок)', name: 'флЗапретитьВноситьФактНач', visible: true },
      isBanFactE: { label: 'Заборона внесення "Фактична наявність" (на кінець)', name: 'флЗапретитьВноситьФактКон', visible: true },
      statusSum: { label: 'Статус Сумм', name: 'статусСумм', visible: true },
      /// Дополнительные свойства
      status: { label: 'Статус строки', name: 'СтатусСтроки' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи розпорядників',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Мережа (план) (Додаток 3)',
  name: 'netCategoryPlan',
  backendName: 'МережаПоказателиПлан',
  columns,
  frontend: 'doc/netCategoryPlan',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'actualVersion',
      'FI',
      'author',
      'isApproved',
      'appr',
      'note',
      'KPKV',
      'budget',
      'year',
      'apprByRegion',
      'versionIsOk',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
