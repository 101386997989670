import React, {
  useRef, useState, useContext, useLayoutEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Collapse } from 'react-bootstrap';
import EndUser from './eusign';
import { LogicaContext } from '../../../providers';

function SignData({
  data, onAddSign,
}) {
  //
  const { widgetURI } = useContext(LogicaContext);
  const [err, setErr] = useState(null);
  const [showWidget, setShowWidget] = useState(true);
  const parentRef = useRef();
  const widgetRef = useRef();
  useLayoutEffect(
    () => {
      const EUSign = new EndUser(
        parentRef,
        widgetRef,
        widgetURI,
        EndUser.FormType.ReadPKey,
      );
      EUSign.ReadPrivateKey()
        .then(() => EUSign.SignData(
          data,
          true,
          true,
          EndUser.SignAlgo.DSTU4145WithGOST34311,
          null,
          EndUser.SignType.CAdES_X_Long,
        ))
        .then((signed) => {
          setShowWidget(false);
          onAddSign(signed);
        })
        .catch((e) => setErr(e.message));
      return () => EUSign.destroy();
    },
    [data, onAddSign, widgetURI],
  );

  return (
    <div className="h-100 w-100 d-flex flex-column">
      {err && (
        <Alert variant="danger">
          <Alert.Heading>
            При накладанні підпису виникла помилка:
          </Alert.Heading>
          {err}
        </Alert>
      )}
      <Collapse in={showWidget}>
        <div className="flex-fill d-flex flex-column">
          <div ref={parentRef} className="flex-fill" />
        </div>
      </Collapse>
    </div>
  );
}

SignData.propTypes = {
  data: PropTypes.string,
  onAddSign: PropTypes.func.isRequired,
};

SignData.defaultProps = {
  data: '',
};
export default memo(SignData);
