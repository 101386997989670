import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  activeCol, highlights, onToggleHighlight, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kdb'}
              highlighted={highlights.includes('kdb')}
              onToggleHighlight={() => onToggleHighlight('kdb')}
            >
              КДБ
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'person_budget'}
              highlighted={highlights.includes('person_budget')}
              onToggleHighlight={() => onToggleHighlight('person_budget')}
            >
              Бюджет
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              title={totals.zag_fond}
              className="text-center text-xl-left"
              active={activeCol === 'zag_fond'}
              highlighted={highlights.includes('zag_fond')}
              onToggleHighlight={() => onToggleHighlight('zag_fond')}
            >
              Заг. фонд
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.spec_fond}
              className="text-center text-xl-left"
              active={activeCol === 'spec_fond'}
              highlighted={highlights.includes('spec_fond')}
              onToggleHighlight={() => onToggleHighlight('spec_fond')}
            >
              Спец. фонд
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.total}
              className="text-center text-xl-left"
              active={activeCol === 'total'}
              highlighted={highlights.includes('total')}
              onToggleHighlight={() => onToggleHighlight('total')}
            >
              Загальна сума
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    spec_fond: PropTypes.number,
    zag_fond: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
