import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const indicatorProps = tableHooks.useItemInputProps('indicator', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const indicatorTypeProps = tableHooks.useSelectorInputProps('indicator_type', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const activityProps = tableHooks.useItemInputProps('activity', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountTotal0Props = tableHooks.useNumberInputProps('amount_total0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountTotal1Props = tableHooks.useNumberInputProps('amount_total1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountTotal2Props = tableHooks.useNumberInputProps('amount_total2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col>
      <Row noGutters>
        <Col lg={8}>
          <TabelRowCell column="indicator" highlighted={highlights.includes('indicator')}>
            <TableControls.ItemPicker {...indicatorProps} />
          </TabelRowCell>
          <TabelRowCell column="indicator_type" highlighted={highlights.includes('indicator_type')}>
            <TableControls.SelectorInput {...indicatorTypeProps} />
          </TabelRowCell>
          <TabelRowCell column="activity" highlighted={highlights.includes('activity')}>
            <TableControls.ItemPicker {...activityProps} />
          </TabelRowCell>
        </Col>
        <Col lg={4}>
          <TabelRowCell column="amount_total0" highlighted={highlights.includes('amount_total0')}>
            <TableControls.NumberInput {...amountTotal0Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_total1" highlighted={highlights.includes('amount_total1')}>
            <TableControls.NumberInput {...amountTotal1Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_total2" highlighted={highlights.includes('amount_total2')}>
            <TableControls.NumberInput {...amountTotal2Props} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
