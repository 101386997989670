import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualSpend';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.tvDoc;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prim'}
              highlighted={highlights.includes('prim')}
              onToggleHighlght={() => onToggleHighlght('prim')}
            >
              {meta.columns.prim.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'docP'}
              highlighted={highlights.includes('docP')}
              onToggleHighlght={() => onToggleHighlght('docP')}
            >
              {meta.columns.docP.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp'}
              highlighted={highlights.includes('kbp')}
              onToggleHighlght={() => onToggleHighlght('kbp')}
            >
              {meta.columns.kbp.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
          </Col>
        </Row>

      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
