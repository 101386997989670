import React from 'react';
import {
  MainIcon,
  PlanningIcon,
  ExecutingIcon,
  FinancingIcon,
  DirectoriesIcon,
  ServiceIcon,
  IncomeIcon,
  OCBIcon,
  LogicaLogo,
  // AnalyticalInfIcon,
  PCMIcon,
} from '../../../assets/icons';
import img1 from '../../../containers/reports/quickLinks/img/1.png';
import img2 from '../../../containers/reports/quickLinks/img/2.png';

import def from '..';

const forecastShowTrigger = (so) => so.get('is_admin') || so.get('isFinManagment');
const garantyShowTrigger = (so) => so.get('noAccessToGaranty');

const NavPanel = {
  main: {
    name: 'main',
    label: 'Головна',
    link: '/',
    icon: (<img src={MainIcon} alt="Main" title="Головна" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          // { def: def.doc.financingRequest, primary: true },
          // { def: def.doc.financing, primary: true },
          // { def: def.doc.changesToAssignmentPlanning, primary: true },
          // { def: def.doc.assignmentPlanning, primary: true },
          // { def: def.doc.indicatorPassport, primary: true },
        ],
      },
    ],

  },

  logica: {
    name: 'Logica',
    label: 'ЛОГІКА',
    link: '/logica/',
    // backgroundColor: '#00721f',
    icon: (<img src={LogicaLogo} alt="Main" title="Головна" />),
    boxes: [
      {
        name: 'Logica',
        label: 'ЛОГІКА',
        items: [
          { def: def.dp.authentication, primary: true },
          { def: def.doc.registrationForm, primary: true },
          { def: def.doc.docTransferToLogica, primary: true },
          { def: def.dp.documentsInLogica, primary: true },
          { def: def.dp.publicDocumentsInLogica, primary: true },
          { def: def.dp.relatedRegisterInLogica, primary: true },
          { def: def.dp.importFromLogica, primary: true },
          { def: def.doc.docTransferToLogicaSinging, primary: true },
        ],
      },
      {
        name: 'Taxes',
        label: 'Ставки та пільги',
        group: true,
        mainItems: [
          { label: 'Довідники', name: 'Cat', num: 2 },
          { label: 'Документи', name: 'Doc', num: 2 },
        ],
        items: [
          { def: def.cat.koatuu, primary: true, parent: 'Cat' },
          { def: def.cat.buildingClassifier, primary: true, parent: 'Cat' },
          { def: def.cat.landClassifier, primary: true, parent: 'Cat' },
          { def: def.cat.residenceKinds, primary: true, parent: 'Cat' },
          { def: def.cat.parkingKinds, primary: true, parent: 'Cat' },
          { def: def.cat.activityKinds, primary: true, parent: 'Cat' },
          { def: def.doc.taxes, primary: true, parent: 'Doc' },
          { def: def.doc.taxes2, primary: true, parent: 'Doc' },
          { def: def.doc.taxes2P, primary: true, parent: 'Doc' },
          { def: def.doc.taxes3, primary: true, parent: 'Doc' },
          { def: def.doc.taxes3P, primary: true, parent: 'Doc' },
          { def: def.doc.taxes4, primary: true, parent: 'Doc' },
          { def: def.doc.taxes5, primary: true, parent: 'Doc' },
          { def: def.doc.taxes6, primary: true, parent: 'Doc' },
          { def: def.doc.taxes7, primary: true, parent: 'Doc' },
          { def: def.doc.taxes8, primary: true, parent: 'Doc' },
          { def: def.doc.taxes11, primary: true, parent: 'Doc' },
        ],
      },
    ],
  },

  planing: {
    name: 'planning',
    label: 'Планування',
    link: '/planningDirectory/',
    icon: (<img src={PlanningIcon} alt="Planning" title="Планування" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.assignmentPlanning, primary: true },
          { def: def.doc.changesToAssignmentPlanning, primary: true, line: true },
          { def: def.doc.limitReference, primary: true },
          { def: def.doc.publicBudget, primary: false },
          { def: def.infoRegs.proposalDeputies, primary: false },
          { def: def.doc.assignmentPlanningSource, primary: false },
          { def: def.doc.changeToAssignPlanSource, primary: false },
          { def: def.doc.borderLimit, primary: false },
          { def: def.doc.changeBorderLimit, primary: false },
          { def: def.doc.expensesOnEducation, primary: false },
          { def: def.doc.liquidityVer2, primary: false },
          { def: def.doc.garanty, primary: false, showTrigger: garantyShowTrigger },
          { def: def.doc.expensesOnHealth, primary: false },
          { def: def.doc.transfertPlanning, primary: false },
          { def: def.doc.changeTransfertPlanning, primary: false },
          { def: def.doc.countExpensesOnEducation, primary: false },
          { def: def.doc.localDebt, primary: false },
          { def: def.doc.dataEducationPayment, primary: false },
        ],
      },
      {
        name: 'docLogica',
        label: 'Logica (Мережа, Вільні залишки)',
        items: [
          { def: def.doc.freeRem, primary: true },
          { def: def.doc.freeRem3Add, primary: true },
          { def: def.doc.freeRem4Add, primary: true },
          { def: def.doc.freeRem4Add2024, primary: false },
          { def: def.doc.netFOP, primary: false },
          { def: def.doc.netCategory, primary: false },
          { def: def.doc.netCategoryPlan, primary: false },
          { def: def.doc.net, primary: false },
          { def: def.doc.netChange, primary: false },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.AssigmentPlaning, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.CompareLevels, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.PlanOfUse, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.SourceAssigment, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.YearAssigment, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.PivotRequest, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.LimitSPR, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.MixIndicatorsSF, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.Estimate, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.GovPlanSpend, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.TreasureReports, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.Balans, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.RegionAssigmentsAnalisys, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.RegionAssigmentsFactAnalisys, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.AnalysisIndicatorsSF, primary: false, parent: 'generalAnalytics' },
          { def: def.dp.referRegistry, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.TreasureOffer, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.NetFOPreduce, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.NetFOPPlanreduce, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.TransfertPlanning, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.LiquidityMethod, primary: false, parent: 'generalAnalytics' },
        ],
      },

      {
        name: 'reportsAdd',
        label: 'Додатки до рішення сесії',
        items: [
          { def: def.rep.SessionAdd1, primary: true, parent: 'Session' },
          { def: def.rep.SessionAdd4, primary: true, parent: 'Session' },
          { def: def.rep.SessionAdd2, primary: true, parent: 'Session' },
          { def: def.rep.SessionAdd3, primary: true, parent: 'Session' },
          { def: def.rep.SessionAdd5Transfert, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd6BR, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd7, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd8Loan, primary: false, parent: 'Session' },
        ],
      },

      {
        name: 'draftAnnual',
        label: 'Рішення сесії',
        group: true,
        mainItems: [
          { label: 'Документи з Рішення сесії', name: 'Doc', num: 2 },
          { label: 'Додатки до Рішення сесії', name: 'Session', num: 1 },
        ],
        items: [
          { def: def.doc.draftAnnualRev, primary: true, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualRev, primary: true, parent: 'Doc' },
          { def: def.doc.draftAnnualSources, primary: false, parent: 'Doc' },
          { def: def.doc.changeToDraftAnnualSources, primary: false, parent: 'Doc' },
          { def: def.doc.draftAnnualSpend, primary: true, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualSpend, primary: true, parent: 'Doc' },
          { def: def.doc.draftAnnualCredit, primary: false, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualCredit, primary: false, parent: 'Doc' },
          { def: def.doc.draftAnnualTransfert, primary: false, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualTransfert, primary: false, parent: 'Doc' },
          { def: def.doc.draftAnnualBR, primary: false, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualBR, primary: false, parent: 'Doc' },
          { def: def.doc.draftAnnualLoan, primary: false, parent: 'Doc' },
          { def: def.doc.changeDraftAnnualLoan, primary: false, parent: 'Doc' },
          { def: def.doc.decisionNotification, primary: false, parent: 'Doc' },
          { def: def.cat.resolutionSession, primary: true, parent: 'Doc' },
          { def: def.rep.SessionAdd1, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd2, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd3, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd4, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd5Transfert, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd6BR, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd7, primary: false, parent: 'Session' },
          { def: def.rep.SessionAdd8Loan, primary: false, parent: 'Session' },
        ],
      },

      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.csu, primary: true },
          { def: def.cat.elementKFK, primary: true },
          { def: def.cat.elementKDB, primary: true },
          { def: def.cat.elementKEKV, primary: true },
          { def: def.cat.elementKFB, primary: true },
          { def: def.cat.elementFond, primary: false },
          { def: def.cat.budget, primary: false },
          { def: def.cat.budgetTransfer, primary: false },
          { def: def.cat.groundLaw, primary: false },
          { def: def.cat.target, primary: false },
          { def: def.cat.laws, primary: false },
          { def: def.cat.targetedPrograms, primary: false },
          { def: def.cat.variantBudgetDraft, primary: false },
          { def: def.cat.industry, primary: false },
          { def: def.cat.outcomeGroup, primary: false },
          { def: def.cat.deputies, primary: false },
          { def: def.cat.publicBudgetProjectAuthor, primary: false },
          { def: def.cat.publicBudgetProject, primary: false },
          { def: def.cat.industryDet, primary: false },
        ],
      },
    ],
  },
  execution: {
    name: 'executing',
    label: 'Виконання',
    link: '/executionDirectory/',
    icon: (<img src={ExecutingIcon} alt="Executing" title="Виконання" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.financingRequest, primary: true },
          { def: def.doc.financingPropose, primary: true, line: true },
          { def: def.doc.financing, primary: true },
          { def: def.doc.financingApplicationReversal, primary: true },
          { def: def.doc.financingCashbox, primary: true },
          { def: def.doc.socialPayments, primary: false },
          { def: def.doc.financingSources, primary: false },
          { def: def.doc.treasuryStatementCosts, primary: false },
          { def: def.doc.treasuryStatementFinObligation, primary: false },
          { def: def.doc.treasuryStatementLegalObligation, primary: false },
          { def: def.doc.incomingMoney, primary: false },

          { def: def.doc.legalObligation, primary: true },
          { def: def.doc.financeObligation, primary: true },
          { def: def.doc.payment, primary: true },
          { def: def.doc.docRegister, primary: true },
          { def: def.doc.paymentTransfer, primary: false },
          { def: def.doc.balancesAccounts, primary: false },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        group: true,
        mainItems: [
          { label: 'Казначейство', name: 'first', num: 2 },
          { label: 'Аналіз заявок і пропозицій', name: 'analysisAndProposals', num: 2 },
          { label: 'Загальна аналітика', name: 'generalAnalytics', num: 2 },
        ],
        items: [
          { def: def.rep.CostAnalysisPlanFact, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.RequestAnalysis, primary: true, parent: 'analysisAndProposals' },
          { def: def.rep.FinancingAnalysis, primary: true, parent: 'generalAnalytics' },
          { def: def.rep.CompareLevels, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.TreasureFilesAnalysis, primary: false, parent: 'first' },
          { def: def.rep.FinSourceAnalysis, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.PivotRequest, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.PaymentList, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.TurnoverSheetTreasureAccount, primary: false, parent: 'first' },
          { def: def.rep.DocFinAnalysis, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.AnalysisFinProtectItems, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.ConsolidateFinProposal, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.RequestAnalysisAdd, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.AnalysisSpentCashe, primary: false, parent: 'generalAnalytics' },
          { def: def.rep.TreasureReport, primary: false, parent: 'first' },
          { def: def.rep.TreasureReportStatement, primary: false, parent: 'first' },
          // { def: def.rep.TreasureReportStatementCut, primary: false, parent: 'first' },
          {
            def: def.dp.certificateOfAccountPrint, primary: false, parent: 'first', showTrigger: forecastShowTrigger,
          },
          { def: def.rep.PivotRequestKMDA, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.ConsolidatePartnersFinProposal, primary: false, parent: 'analysisAndProposals' },
          { def: def.rep.AssigmentPlaning, primary: true },
          { def: def.rep.RevenueAnalysis, primary: true },
          // { def: def.rep.RevenueAnalysisVariety, primary: false },
          { def: def.rep.Balans, primary: false },
          { def: def.rep.TreasureReports, primary: false, parent: 'first' },
          { def: def.rep.RevTreasureReport, primary: false, parent: 'first' },
          { def: def.rep.TreasureReportStatementCutKiev, primary: false, parent: 'first' },
          { def: def.rep.TreasureFilesObligationsAnalysis, primary: false, parent: 'first' },
          { def: def.rep.ExpensesBudget, primary: false, parent: 'generalAnalytics' },
        ],
      },
      {
        name: 'notNamed',
        label: 'Довiдники',
        items: [
          { def: def.cat.signatureVariants, primary: true },
          { def: def.cat.treasuryStatement, primary: true },
          { def: def.cat.articlesDebtSocialPayments, primary: true },
          { def: def.cat.noteSocialPayments, primary: true },
          { def: def.cat.partners, primary: false },
          { def: def.cat.symbolKdb, primary: false },
        ],
      },
    ],
  },
  pcm: {
    name: 'pcm',
    label: 'ПЦМ',
    link: '/pcmDirectory/',
    icon: (<img src={PCMIcon} alt="pcm" title="ПЦМ" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.budgetRequest, primary: true },
          { def: def.doc.indicatorPassport, primary: true },
          { def: def.doc.indicatorFact, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.PassportAnalisys, primary: true },
          { def: def.rep.ReportAnalysis, primary: true },
          { def: def.rep.PassportReportAnalysis, primary: false },
          { def: def.rep.BrAnalysisSKD, primary: false },
          { def: def.rep.IndicatorFactAnalysisSKD, primary: false },
          { def: def.rep.PassportAnalysisSKD, primary: false },
          { def: def.rep.ComparePassport, primary: false },
        ],
      },
      {
        name: 'notNamed',
        label: 'Довiдники',
        items: [
          { def: def.cat.target, primary: true },
          { def: def.cat.tasks, primary: true },
          { def: def.cat.tasks2019, primary: true },
          { def: def.cat.indicators, primary: true },
          { def: def.cat.units, primary: true },
          { def: def.cat.infoSources, primary: false },
          { def: def.cat.groundLaw, primary: false },
          { def: def.cat.laws, primary: false },
          { def: def.cat.targetedPrograms, primary: false },
          { def: def.cat.administrativeTerritorialUnits, primary: false },
          { def: def.cat.objectivesStatePolicy, primary: false },
        ],
      },
    ],
  },
  fbr: {
    name: 'fbr',
    label: 'Зобов\'язання',
    link: '/FBRDirectory/',
    icon: (<img src={FinancingIcon} alt="Financing" title="Фінансування бюджету розвитку" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.legalObligation, primary: true },
          { def: def.doc.financeObligation, primary: true },
          { def: def.doc.payment, primary: true },
          { def: def.doc.docRegister, primary: true },
          { def: def.doc.paymentTransfer, primary: false },
        ],
      },
      {
        name: 'FBRReports',
        label: 'Звіти',
        items: [
          { def: def.rep.ReportByBR, primary: true },
          { def: def.rep.ReportBalanceOfAccounts, primary: true },
          { def: def.rep.FinancingOfBRAnalysis, primary: false },
          { def: def.rep.NationalCurrencyNote, primary: false },
          { def: def.rep.FactExpence, primary: false },
          { def: def.rep.InfoAboutFinancing, primary: false },
          { def: def.dp.finPrintingBR, primary: false },
          { def: def.rep.CertNationalCurr, primary: false },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.csuPartners, primary: true },
          { def: def.cat.paymentPurposeTemplate, primary: true },
          { def: def.cat.partnerBanks, primary: true },
          { def: def.cat.submittedDocuments, primary: true },
        ],
      },
      {
        name: 'dataprocessors',
        label: 'Обробки',
        items: [
          { def: def.dp.exportVDBFKievDod21, primary: true },
          { def: def.dp.exportVDBFKievDod21csu, primary: true },
        ],
      },
    ],
  },
  objectsCapitalBuilding: {
    name: 'objectsCapitalBuilding',
    label: 'Об\'єкти кап. будівництва та ремонту',
    link: '/OCBDirectory/',
    icon: (<img src={OCBIcon} alt="objectsCapitalBuilding" title="Об'єкти кап. будівництва (ремонту)" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.financingRepairObjects, primary: true },
          { def: def.doc.investmentProposalObjects, primary: true },
          { def: def.doc.investmentProposalObjectsChanges, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.ObjBuildingAddressList, primary: true },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.typesRepair, primary: true },
          { def: def.cat.typesWork, primary: true },
          { def: def.cat.objectsBuilding, primary: true },
          { def: def.cat.objBuildingPVR, primary: false },
          { def: def.cat.objBuildingTypeDoc, primary: false },
          { def: def.cat.objBuildingTypePower, primary: false },
          { def: def.cat.objBuildingTypeSource, primary: false },
          { def: def.cat.objectivesStatePolicy, primary: false },
          { def: def.cat.typesRepairIndustry, primary: false },
          { def: def.cat.districts, primary: false },
          { def: def.cat.houses, primary: false },
          { def: def.cat.regions, primary: false },
          { def: def.cat.streets, primary: false },
          { def: def.cat.tenderProcType, primary: false },
        ],
      },
    ],
  },
  // analyticalInformation: {
  //   name: 'analyticalInformation',
  //   label: 'Аналітична інформація',
  //   link: '/analyticalInfDirectory/',
  //   icon: (<img src={AnalyticalInfIcon} alt="analyticalInformation"
  //   title="Аналітична інформація" />),
  //   boxes: [
  //     // {
  //     //   name: 'documents',
  //     //   label: 'Документи',
  //     //   items: [
  //     //   ],
  //     // },
  //     {
  //       name: 'reports',
  //       label: 'Звiти',
  //       items: [
  //         { def: def.rep.TreasureFilesAnalysis, primary: true },
  //       ],
  //     },
  //     // {
  //     //   name: 'notNamed',
  //     //   label: 'Довiдники',
  //     //   items: [
  //     //   ],
  //     // },
  //   ],
  // },
  income: {
    name: 'income',
    label: 'Доходи',
    link: '/incomeDirectory/',
    icon: (<img src={IncomeIcon} alt="income" title="Доходи" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.draftAnnualRev, primary: true },
          { def: def.doc.changeDraftAnnualRev, primary: true },
          { def: def.doc.assignmentPlanningRev, primary: true },
          { def: def.doc.changeToAssignPlanRev, primary: true },
          { def: def.doc.assignmentPlanningSource, primary: true },
          { def: def.doc.changeToAssignPlanSource, primary: false },
          { def: def.doc.treasuryStatementIncome, primary: false },
          { def: def.doc.treasuryStatementIncomeSB, primary: false },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.RevenueAnalysis, primary: true },
          { def: def.rep.Balans, primary: true },
          { def: def.rep.SourceAssigment, primary: true },
          { def: def.rep.RegionRevenuePlanAnalysis, primary: true },
          { def: def.rep.RegionRevenueFactAnalysis, primary: false },
          { def: def.rep.RegionRevenueFactDaily, primary: false },
          { def: def.rep.RegionRevenueStatement, primary: false },
          { def: def.rep.RevenuePlan, primary: false },
          // { def: def.rep.RevenueAnalysisVariety, primary: true },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.elementKDB, primary: true },
          { def: def.cat.elementKFB, primary: true },
        ],
      },
      {
        name: 'taxpayers',
        label: 'Аналіз доходів у розрізі платників податків ',
        items: [
          { def: def.dp.repTaxpayers, primary: true },
        ],
      },
    ],
    visible: ({ useIncomes }) => useIncomes,
  },
  social: {
    name: 'social',
    label: 'Соціальні виплати',
    link: '/socialDirectory/',
    icon: (<img src={OCBIcon} alt="social" title="Соціальні виплати" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.socialPayments, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.ReportSocial, primary: true },
          { def: def.rep.ReportSocial1, primary: true },
          { def: def.rep.ReportSocial2, primary: false },
          { def: def.dp.exportSocialPayment, primary: true },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.elementSocialPayment, primary: true },
          { def: def.cat.articlesDebtSocialPayments, primary: true },
        ],
      },
    ],
  },
  catalogs: {
    name: 'catalogs',
    label: 'Довiдники',
    link: '/catalogsDirectory/',
    icon: (<img src={DirectoriesIcon} alt="Directories" title="Довiдники" />),
    boxes: [
      {
        name: 'budgetClassifiers',
        label: 'Бюджетнi класифiкатори',
        items: [
          { def: def.cat.kbp7Classifier, primary: true },
          { def: def.cat.kekvClassifier, primary: true },
          // { def: def.cat.kfkClassifier, primary: true },
          // { def: def.cat.additionalPropertyKFK, primary: false },
          { def: def.cat.kvkClassifier, primary: true },
          { def: def.cat.kdbClassifier, primary: true },
          { def: def.cat.kbpClassifier, primary: false },
          { def: def.cat.fondClassifier, primary: false },
          { def: def.cat.kfbClassifier, primary: false },
          { def: def.cat.kekvClassifierFPShort, primary: false },
          { def: def.cat.kekvClassifierShort, primary: false },
          { def: def.cat.kekvClassifierForPAShort, primary: false },
          { def: def.cat.noteClassifier, primary: false },
          { def: def.cat.baseDoc, primary: false },
          { def: def.cat.coverageSourceClassifier, primary: false },
          { def: def.cat.kfkvkClassifier, primary: false },
          // { def: def.cat.elementKDB, primary: false },
          { def: def.cat.elementSocialPayment, primary: false },
          { def: def.cat.tarif, primary: false },
          { def: def.cat.categoriesFOP, primary: false },
          { def: def.cat.tasksClassifier, primary: false },
          // { def: def.cat.kekvClassifierForMinFinShort, primary: false },

        ],
      },
      {
        name: 'generalCatalogs',
        label: 'Загальнi довiдники',
        items: [
          { def: def.cat.csu, primary: true },
          { def: def.cat.budget, primary: true },
          { def: def.cat.banks, primary: true },
          { def: def.cat.bankAccounts, primary: true },
          { def: def.cat.officials, primary: true },
          { def: def.cat.signatureVariants, primary: false },
          { def: def.cat.statesContingents, primary: false },
          { def: def.cat.partnerBanks, primary: false },
          { def: def.cat.streets, primary: false },
          { def: def.cat.industry, primary: false },
          { def: def.cat.outcomeGroup, primary: false },
          { def: def.cat.users, primary: false, showTrigger: (so) => so.get('is_admin', false) },
          { def: def.cat.salaryStructure, primary: false },
          { def: def.cat.notes, primary: false },
          { def: def.cat.csuTransfer, primary: false },
          { def: def.cat.budgetTransfer, primary: false },
          { def: def.cat.currency, primary: false },
        ],
      },
      {
        name: 'classifierDetails',
        label: 'Деталiзацiя класифiкаторiв',
        items: [
          { def: def.cat.elementKFK, primary: true },
          { def: def.cat.elementKDB, primary: true },
          { def: def.cat.elementKEKV, primary: true },
          { def: def.cat.elementKFB, primary: true },
          { def: def.cat.elementFond, primary: true },
          { def: def.cat.coverageSource, primary: false },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [

          // { def: def.cat.statesContingents, primary: true },
          { def: def.cat.partners, primary: true },
          { def: def.cat.partnerBanks, primary: true },
          { def: def.cat.variantBudgetDraft, primary: true },

          { def: def.cat.csuPartners, primary: true },
          { def: def.cat.accessGroup, primary: false },
          { def: def.cat.groundLaw, primary: false },
          { def: def.cat.stewardLevel, primary: false },
          { def: def.cat.reportSettings, primary: false },
          { def: def.cat.recipients, primary: false },
          // { def: def.cat.objectsBuilding, primary: false },
          // { def: def.cat.typeAttachedFile, primary: false },
          { def: def.cat.signGroup, primary: false },
          { def: def.cat.signGroupByDocuments, primary: false },
          { def: def.cat.submittedDocuments, primary: false },
          { def: def.cat.artistsRoles, primary: false },
          { def: def.cat.docNotes, primary: false },
          { def: def.cat.docType, primary: false },
          { def: def.cat.energyCarriersIndicator, primary: false },
          { def: def.cat.explanations, primary: false },
          // { def: def.cat.houses, primary: false },
          { def: def.cat.typeTaxSFForRaitingZF, primary: false },
          { def: def.cat.typesOfLaborCosts, primary: false },
          { def: def.cat.stewardGroup, primary: false },
          // { def: def.cat.regions, primary: false },
          { def: def.cat.provisionOfNormativeLegalActs, primary: false },
          { def: def.cat.paymentPurposeTemplate, primary: false },
          { def: def.cat.partnerBankAccounts, primary: false },
          // { def: def.cat.modelProgramsClassifier, primary: false },
          // { def: def.cat.districts, primary: false },
          // { def: def.cat.typesRepairIndustry, primary: false },
          // { def: def.cat.objBuildingTypeDoc, primary: false },
          // { def: def.cat.tasksClassifier, primary: false },
          // { def: def.cat.indicators, primary: false },
        ],
      },
      {
        name: 'pcmClassifiers',
        label: 'ПЦМ',
        items: [
          { def: def.cat.target, primary: true },
          { def: def.cat.indicators, primary: true },
          { def: def.cat.tasks, primary: true },
          { def: def.cat.units, primary: true },
          { def: def.cat.infoSources, primary: true },
          { def: def.cat.groundLaw, primary: false },
          { def: def.cat.laws, primary: false },
          { def: def.cat.targetedPrograms, primary: false },
          { def: def.cat.indicatorsClassifier, primary: false },
          { def: def.cat.tasksClassifier, primary: false },
          { def: def.cat.objectivesStatePolicy, primary: false },
          { def: def.cat.tasks2019, primary: false },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          { def: def.rep.UsersMonitor, primary: true },
        ],
      },
      {
        name: 'documentsParameters',
        label: 'Параметри Документiв',
        items: [
          { def: def.cat.templateBaseDoc, primary: true },
          { def: def.cat.implementer, primary: true },
        ],
      },
      {
        name: 'numerators',
        label: 'Нумератори',
        items: [
          { def: def.cat.numbering, primary: true },
        ],
      },
      {
        name: 'forSessionDecisions',
        label: 'Для рiшення сессiї',
        items: [],
      },
      {
        name: 'additionalProperties',
        label: 'Додатковi властивостi',
        items: [
          { def: def.ChTP.budgetElChTP, primary: true },
          { def: def.ChTP.csuElChTP, primary: true },
          { def: def.ChTP.typesRepairElChTP, primary: false },
          { def: def.ChTP.addPropClassifiersChTP, primary: false },
        ],
      },
      {
        name: 'properties',
        label: 'Властивостi',
        items: [],
      },
    ],
  },
  forecast: {
    name: 'forecast',
    label: 'Прогноз',
    link: '/forecastDirectory/',
    icon: (<img src={OCBIcon} alt="forecast" title="Прогноз" />),
    // visible: ({ isGRK, isFinManagment, isAdmin }) => isAdmin || isGRK || isFinManagment,
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.forecastAdd1, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd2, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd3, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd4, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd5, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd6, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd7, primary: true },
          { def: def.doc.forecastAdd8, primary: false, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd9, primary: false, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd10, primary: false },
          { def: def.doc.forecastAdd11, primary: false, showTrigger: forecastShowTrigger },
          { def: def.doc.forecastAdd12, primary: false, showTrigger: forecastShowTrigger },
          { def: def.doc.decisionNotification, primary: false, showTrigger: forecastShowTrigger },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.forecastCredit, primary: true, showTrigger: forecastShowTrigger },
          { def: def.cat.forecastIndicatorBR, primary: true, showTrigger: forecastShowTrigger },
          { def: def.cat.indicatorsForTarget, primary: true },
        ],
      },
      {
        name: 'prepare',
        label: 'Підготовка до прогнозу',
        items: [
          { def: def.doc.prepareForecastAdd1, primary: true, showTrigger: forecastShowTrigger },
          { def: def.doc.prepareForecastAdd2, primary: true },
          { def: def.doc.prepareForecastAdd3, primary: true },
          { def: def.doc.prepareForecastAdd4, primary: true },
          { def: def.doc.prepareForecastAdd5, primary: true, showTrigger: forecastShowTrigger },
        ],
      },
      {
        name: 'reports',
        label: 'Звіти',
        items: [
          { def: def.rep.AnalysisGOV, primary: true, showTrigger: forecastShowTrigger },
          { def: def.rep.AnalysisForecastsPP3, primary: true },
          { def: def.cat.planing, primary: true, showTrigger: forecastShowTrigger },
        ],
      },
    ],
  },
  energy: {
    name: 'energy',
    label: 'Енергоносії',
    link: '/energyDirectory/',
    icon: (<img src={OCBIcon} alt="energy" title="Енергоносії" />),
    visible: (session, isAdmin) => isAdmin || session.fParams['ВключитьПодсистемуЭнергоносители'],
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.energyIndicators, primary: true },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.partners, primary: true },
          { def: def.cat.department, primary: true },
          { def: def.cat.energyCarriersIndicator, primary: true },
          { def: def.cat.reportsForms, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звіти',
        items: [
          { def: def.rep.EnergyReport, primary: true },
        ],
      },
    ],
  },
  services: {
    name: 'services',
    label: 'Сервiс',
    link: '/servicesDirectory/',
    icon: (<img src={ServiceIcon} alt="Service" title="Сервiс" />),
    boxes: [
      {
        name: 'someServices',
        label: 'Деякi сервiси',
        items: [
          { def: def.dp.exportDBF373, primary: true },
          { def: def.dp.exportPlanToDBF, primary: true },
          { def: def.dp.logikaImportTreasureFiles, primary: true },
          { def: def.dp.clientBankUGB, primary: false },
          { def: def.dp.attachedFiles, primary: false },
          { def: def.dp.exportVDBFKievDod21, primary: false },
          { def: def.dp.exportVDBFKievDod21csu, primary: false },
          { def: def.dp.importTreasFiles, primary: false },
          { def: def.dp.messageForUsers, primary: false },
          { def: def.dp.reportsCalendar, primary: false },
        ],
      },
      {
        name: 'Logica',
        label: 'ЛОГІКА',
        items: [
          { def: def.dp.authentication, primary: true },
          { def: def.doc.registrationForm, primary: true },
          { def: def.doc.docTransferToLogica, primary: true },
          { def: def.dp.documentsInLogica, primary: true },
          { def: def.dp.publicDocumentsInLogica, primary: true },
          { def: def.dp.relatedRegisterInLogica, primary: true },
        ],
      },
    ],
  },
  bfn: {
    name: 'bfn',
    label: 'БФН',
    link: '/bfn/',
    icon: (<img src={PlanningIcon} alt="bfn" title="БФН>" />),
    boxes: [
      {
        name: 'documents',
        label: 'Документи',
        items: [
          { def: def.doc.bfnInstitutionStructure, primary: true },
          { def: def.doc.bfnAllocation, primary: true },
        ],
      },
      {
        name: 'reports',
        label: 'Звiти',
        items: [
          // { def: def.rep.CommonRepPanel, primary: true },
          { def: def.rep.BfnCommoninStitutionstructure, primary: true },
        ],
      },
      {
        name: 'catalogs',
        label: 'Довiдники',
        items: [
          { def: def.cat.bfnAmount, primary: true },
          { def: def.cat.bfnGoalFondAmount, primary: true },
          { def: def.cat.bfnGroupAge, primary: true },
          { def: def.cat.bfnGroupFullness, primary: true },
          { def: def.cat.bfnGroupTypes, primary: true },
          { def: def.cat.bfnPeriod, primary: true },
          { def: def.cat.bfnBanDoc, primary: true },
          { def: def.cat.bfnGroupName, primary: true },
        ],
      },
    ],
    visible: (session) => session.fParams['ВключитьПодсистемуБФН'],
  },
};

export const NavPanelBuilding = {
  main: {
    name: 'mainBuilding',
    label: 'Головна',
    link: '/',
    icon: (<img src={MainIcon} alt="Main" title="Головна" />),
    boxes: [
      {
        name: 'unsorted',
        label: 'Об\'єкти будівництва',
        items: [
          { def: def.cat.objectsBuilding, primary: true },
          { def: def.rep.AddressListCR, primary: true },
          { def: def.rep.CountContractsTenders, primary: true },
        ],
      },
    ],
  },
};

export const Directors = {
  directors: {
    name: 'directors',
    label: 'Робоче місце керівника',
    link: '/directors/',
    icon: (<img src={PlanningIcon} alt="directors" title="DIRECTORS>" />),
    boxes: [
      {
        name: 'FinancingAnalysis',
        label: 'Аналіз фінансування',
        icon: img1,
        def: def.rep.FinancingAnalysis,
        primary: true,
      },
      {
        name: 'TreasureFilesAnalysis',
        label: 'Аналіз казначейських файлів',
        icon: img2,
        def: def.rep.TreasureFilesAnalysis,
        primary: true,
      },
    ],
  },
};

export default NavPanel;
