import React, {
  useCallback, useMemo, memo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { OrderedMap, Map } from 'immutable';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  changeField, DCSelectField, DCDeleteField, DCAddField, DCToggleField,
} from '../../../../actions/reportEditor';
import AvailableFields from '../AvailableFields';
import FilterView from './filterView';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import { StyledPanelsContainer } from '../StyledComponents';
import storePathParam from '../../../../common/storePathParam';
import NavButtons from '../navButtons';
import CheckBoxField from '../../../field/CheckBox';
import meta from '../../../../constants/meta';

const AVPATH = [
  'dataCompositionSettings',
  'FilterAvailableFields',
];

const PATH = [
  'dataCompositionSettings',
  'Filter',
];

export function CustomFilter({
  mainAvailableFields, allAvailableFields, data, onSelectField, onDeleteField, onAddField,
  onToggleField, onChangeField, path, avpath, filterChoice, mainGroupFields,
}) {
  const [showAdvansedGroup, setShowAdvansedGroup] = useState(false);

  const availableFields = showAdvansedGroup ? allAvailableFields : mainAvailableFields;

  const addFieldsToStructure = useCallback(
    (fields, addParams) => {
      const addableFields = fields.map((row) => new Map({
        Type: DC.fieldTypes.FilterItem,
        ComparisonType: DC.comparisonTypes.equal.value,
        LeftValue: row.get('Field'),
        ViewMode: DC.viewModes.QuickAccess,
      }));

      onAddField(path, addableFields, null, addParams.index);
    },
    [onAddField, path],
  );

  const onDragEnd = useCallback(
    ({
      destination, draggableId, source,
    }) => {
      if (destination) {
        if (destination.droppableId === 'DC.Filter' && source.droppableId === 'DC.AvailableFields') {
          const draggableField = availableFields.getIn(['visibleItems', draggableId]);
          const addableFields = new Map().set(draggableId, draggableField);
          const parentId = data.filter((r) => r.get('Index') === destination.index).reduce((R, r, k) => k, '');
          addFieldsToStructure(addableFields, { parentId, index: destination.index });
        } else if (destination.droppableId === 'DC.AvailableFields' && source.droppableId === 'DC.Filter') {
          onDeleteField(path, draggableId);
        }
      }
    },
    [addFieldsToStructure, availableFields, data, onDeleteField, path],
  );

  const toggleSelection = useCallback(
    (e, rowId) => onChangeField([...path, rowId, 'Use'], !data.getIn([rowId, 'Use'], false)),
    [data, onChangeField, path],
  );

  const fieldSelectionHandler = useCallback(
    (e, rowId) => {
      e.stopPropagation();
      onSelectField(path, rowId, false);
    },
    [onSelectField, path],
  );

  const fieldDeletionHandler = useCallback(
    () => onDeleteField(path),
    [onDeleteField, path],
  );

  const fieldAddHandler = useCallback(
    () => {
      const addParams = data.reduce((R, row, key) => {
        if (row.get('_SELECTED', false)) {
          return {
            afterKey: key,
          };
        }
        return R;
      }, { afterKey: null });
      addFieldsToStructure(availableFields.get('visibleItems', new Map())
        .filter((row) => row.get('_SELECTED', false)), addParams);
    },
    [addFieldsToStructure, availableFields, data],
  );

  const selectHandler = useCallback(
    (e, field) => onSelectField([...avpath, 'visibleItems'], field, e.ctrlKey),
    [avpath, onSelectField],
  );

  const toggleAvailabeFields = useCallback(
    (e, field) => onToggleField(avpath, field),
    [avpath, onToggleField],
  );

  const changeItem = useCallback(
    (e, rowId, newItem) => onChangeField([...path, rowId], newItem),
    [onChangeField, path],
  );

  const leftSelected = useMemo(() => !!availableFields.get('visibleItems', new Map()).filter((row) => row.get('_SELECTED', false)).size, [availableFields]);
  const rightSelected = useMemo(() => !!data.filter((row) => row.get('_SELECTED', false)).size, [data]);
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      {mainGroupFields && (
        <CheckBoxField
          value={showAdvansedGroup}
          label="Додаткові групування"
          onChange={() => {
            setShowAdvansedGroup(!showAdvansedGroup);
          }}
        />
      )}
      <StyledPanelsContainer>
        <AvailableFields
          items={availableFields.get('visibleItems', new Map())}
          selectHandler={selectHandler}
          choiceHandler={fieldAddHandler}
          toggleHandler={toggleAvailabeFields}
        />
        <NavButtons
          canRight={leftSelected}
          canLeft={rightSelected}
          onRight={fieldAddHandler}
          onLeft={fieldDeletionHandler}
        />
        <FilterView
          availableFields={availableFields.get('items')}
          data={data}
          selectHandler={fieldSelectionHandler}
          toggleUseHandler={toggleSelection}
          deleteHandler={fieldDeletionHandler}
          changeItem={changeItem}
          filterChoice={filterChoice}
        />
      </StyledPanelsContainer>
    </DragDropContext>
  );
}

CustomFilter.propTypes = {
  mainAvailableFields: PropTypes.instanceOf(Map),
  allAvailableFields: PropTypes.instanceOf(Map).isRequired,
  data: PropTypes.instanceOf(OrderedMap).isRequired,
  onSelectField: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onToggleField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  mainGroupFields: PropTypes.arrayOf(PropTypes.string),
  avpath: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterChoice: PropTypes.instanceOf(Map),
};

CustomFilter.defaultProps = {
  filterChoice: new Map(),
  mainAvailableFields: new Map(),
  mainGroupFields: null,
};

export const getMapStateToProps = (mountPoint, dataPath, availablePath) => (state) => ({
  data: state.getIn([mountPoint, ...dataPath], OrderedMap({})),
  availableFields: state.getIn([mountPoint, ...availablePath], Map({})),
  path: dataPath,
  avpath: availablePath,
});

export const getMapDispatchToProps = (actions) => (dispatch) => ({
  onDeleteField: (path, id) => dispatch(actions.DCDeleteField(path, id)),
  onChangeField: (path, value) => dispatch(actions.changeField(path, value)),
  onSelectField: (path, field, multiple) => dispatch(actions.DCSelectField(path, field, multiple)),
  onAddField: (path, fields, parentId, idx) => dispatch(
    actions.DCAddField(path, fields, parentId, idx),
  ),
  onToggleField: (path, field) => dispatch(actions.DCToggleField(path, field)),
});

const mapStateToProps = (state) => {
  const { name } = storePathParam(state);
  const modelType = state.getIn([`rep/${name}/reportEditor`, 'modelType'], '');
  const modelName = state.getIn([`rep/${name}/reportEditor`, 'modelName'], '');
  const { mainGroupFields } = meta[modelType][modelName];
  const allAvailableFields = state.getIn([`rep/${name}/reportEditor`, ...AVPATH], new Map());
  const availableFields = allAvailableFields.map((item) => item.filter((el) => {
    if (!mainGroupFields) return true;
    return mainGroupFields.includes(el.get('Field', ''));
  }));
  return {
    data: state.getIn([`rep/${name}/reportEditor`, ...PATH], OrderedMap({})),
    mainAvailableFields: availableFields,
    allAvailableFields,
    path: PATH,
    avpath: AVPATH,
    mainGroupFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDeleteField: (path, id) => dispatch(DCDeleteField(path, id)),
  onChangeField: (path, value) => dispatch(changeField(path, value)),
  onSelectField: (path, field, multiple) => dispatch(DCSelectField(path, field, multiple)),
  onAddField: (path, fields, parentId, idx) => dispatch(DCAddField(path, fields, parentId, idx)),
  onToggleField: (path, field) => dispatch(DCToggleField(path, field)),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
);

export default enhancer(CustomFilter);
