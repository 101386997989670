import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'bFBWn9Ng5G8',
  },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  KPKV: { label: 'КПКВ (заповнюється виключно на рівні ГРК)', name: 'КПКВ', visible: true },
  plan: { label: 'План/Факт', name: 'ПланФакт', visible: false },
  sector: { label: 'Галузь', name: 'Галузь', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  isApproved: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  apprGroup: { label: 'ГруппаПодписи', name: 'ГруппаПодписи', visible: false },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденоОбл', visible: true },
  regionInvoice: { label: 'Квитанція(область)', name: 'КвитанцияОбл', visible: false },
  fond: { label: 'Фонд', name: 'Фонд', visible: false },
  fondStr: { label: 'Фонд', name: 'ФондСтр', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Категорії працівників (додаток 1) за ЄТС',
    columns: {
      KPKVKMB: { label: 'Код Програмної класифікації видатків та кредитування місцевого бюджету', name: 'КПКВКМБ' },
      KPKVK: { label: 'Код Типової програмної класифікації видатків та кредитування місцевого бюджет', name: 'КПКВК' },
      tarif: { label: 'Тарифний розряд', name: 'ТарифРозряд' },
      num: { label: 'Кількість посад (ставок) за штатним розписом, од.', name: 'Кол_долж' },
      fact: { label: 'Кількість фактично зайнятих посад (ставок), од.', name: 'Факт_долж' },
      fop: { label: 'ФОП на рік (без нарахувань) план', name: 'ФОП_год' },
      okl: { label: 'за посадовими окладами з урахуванням підвищень', name: 'За_окладами' },
      adds: { label: 'на виплату надбавок та доплат, які носять обов’язковий характер', name: 'Добавки' },
      adds_no: { label: 'на виплату надбавок та доплат, які носять необов’язковий характер', name: 'Добавки_необяз' },
      adds_min: { label: 'на виплату доплати до мінімальної заробітної плати', name: 'Добавки_мини' },
      prem: { label: 'на виплату премій', name: 'Премии' },
      other: { label: 'Інші виплати (індексація, матеріальна допомога, грошова винагорода тощо)', name: 'Другие' },
      coun: { label: 'Нарахування на заробітну плату', name: 'Начисления' },
      tarifName: { label: 'Тарифний розряд', name: 'ТарифРозрядИмя' },
      tarifCat: { label: 'Тарифний розряд', name: 'ТарифРозрядСпр' },
    },
  },
  generalb: {
    name: 'ОсновнойРуководители',
    label: 'Категорії працівників (додаток 2) не за ЄТС',
    columns: {
      KPKVKMB: { label: 'Код Програмної класифікації видатків та кредитування місцевого бюджету', name: 'КПКВКМБ' },
      KPKVK: { label: 'Код Типової програмної класифікації видатків та кредитування місцевого бюджет', name: 'КПКВК' },
      tarif: { label: 'Тарифний розряд', name: 'ТарифРозряд' },
      num: { label: 'Кількість посад (ставок) за штатним розписом, од.', name: 'Кол_долж' },
      fact: { label: 'Кількість фактично зайнятих посад (ставок), од.', name: 'Факт_долж' },
      fop: { label: 'ФОП на рік (без нарахувань) план', name: 'ФОП_год' },
      okl: { label: 'за посадовими окладами з урахуванням підвищень', name: 'За_окладами' },
      adds: { label: 'на виплату надбавок та доплат, які носять обов’язковий характер', name: 'Добавки' },
      adds_no: { label: 'на виплатунадбавок тадоплат, які носять необов’язковий характер', name: 'Добавки_необяз' },
      adds_min: { label: 'на виплату доплати до мінімальної заробітної плати', name: 'Добавки_мини' },
      prem: { label: 'на виплату премій', name: 'Премии' },
      other: { label: 'Інші виплати (індексація, матеріальна допомога, грошова винагорода тощо)', name: 'Другие' },
      coun: { label: 'Нарахування на заробітну плату', name: 'Начисления' },
      tarifName: { label: 'Тарифний розряд', name: 'ТарифРозрядИмя' },
      tarifCat: { label: 'Тарифний розряд', name: 'ТарифРозрядСпр' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи розпорядників',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Мережа ФОП (Додаток 1, 2)',
  name: 'netFOP',
  backendName: 'МережаФОП',
  columns,
  frontend: 'doc/netFOP',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'appr',
      'note',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
