import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';

import tpDocumentsTP from './tablepart/aptpCSUTP';
import {
  StyledLabel,
  FrameDiv,
  CollapseButton,
  ExpandArrow, CommandPanelColor,
} from '../../../components/Form/styledForm';

import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';

const md = meta.dp.omitElementKFK;

class OmitElementKFBEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      isOpened: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  collapseMenu = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      this.state.isOpened
        ? (
          <FrameDiv>
            <div>
              <StyledLabel>{md.columns.Link.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Link', new Map())}
                modelType="cat"
                modelName="elementKFB"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'Link'], value));
                }}
              />
            </div>
            <div>
              <CommandPanelColor
                style={{ marginTop: '10px' }}
                text="Створити КДБ"
                label="Створити КДБ"
                onClick={() => dispatch(actions.processServerReq('CREATE_EL'))}
              />
            </div>
            <CollapseButton isOpened={this.state.isOpened} onClick={this.collapseMenu} title="Згорнути" />
          </FrameDiv>
        )
        : (
          <FrameDiv>
            <ExpandArrow isOpened={this.state.isOpened} onClick={this.collapseMenu} title="Розгорнути" />
          </FrameDiv>
        )
    );
  }
}

const TPDocumentsTPRenderer = getTPContainer(tpDocumentsTP, getResizableCP(<TPCommandPanel tableName="csues" />));

const tablesRender = () => (
  <TPDocumentsTPRenderer />
);

const connectedTablesRender = connect()(tablesRender);

const enchance = compose(

  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // omitElementKFK',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(OmitElementKFBEditor);
