import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  noKFK: { label: 'В цілому за розпорядниками (без деталізації по КПКВ)', name: 'БезУчетаКФК' },
  noGRK: { label: 'В цілому за розп. вищ.р. (без деталізації по розп.н.р.)', name: 'БезУчетаРаспорядителей' },
  signVariant: { label: 'Вариант подписи', name: 'ВариантПодписи' },
  progectVariant: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  yearSpend: { label: 'Рік проекту', name: 'ГодЗатрат' },
  kfkGroups: { label: 'КПКВ за галузями', name: 'ГруппыКФК' },
  dataLimits: { label: 'Розпис помісячних  обсягів  асигнувань', name: 'ДанныеПоЛимитам' },
  dataProgects: { label: 'Розпис видатків за економічною класифікацією та класифікацією кредитування', name: 'ДанныеПоПроекту' },
  dataElement: { label: 'у тому числі кошти, виділені на програми', name: 'ДанныеПоЭлементамБюджета' },
  chiefFin: { label: 'Нач фин', name: 'НачФин' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  dataPlan: { label: 'За даними розпису (а не з додатків 3, 3.1)', name: 'ПоДаннымРозписи' },
  byElementKFK: { label: 'За програмами', name: 'ПоДетализацииКФК' },
  oldForm: { label: 'За старою формою', name: 'СтараяФорма' },
  inclusive: { label: 'у тому числі кошти виділені на програми', name: 'ТекстВТомЧисле' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  fond: { label: 'Фонд', name: 'Фонд' },
  sighnKFK: { label: 'Підпис та шапка для кожного КПКВ', name: 'ПодписьКаждогоКФК' },
  quart: { label: 'З початку року по', name: 'сзКвартал' },
  byPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  nameBudget: {
    label: 'Лімітна довідка видана',
    name: 'ИмяБюджета',
  },
  forAllFonds: {
    label: 'Фонд',
    name: 'ДляВсехФондов',
  },
};

const tables = {
  tpDoc: {
    name: 'ДляОтправки',
    label: 'документи відправки в ЛОГІКА',
    columns: {
      csu: { label: 'Роспорядник', name: 'Расп' },
      KPKVMB: { label: 'КПКВМБ', name: 'КПКВМБ' },
      mark: { label: 'Відправлено', name: 'Отправлен' },
    },
  },
};

const definition = {
  label: 'Лімітні довідки',
  name: 'LimitSPR',
  columns,
  tables,
  frontend: 'rep/LimitSPR',
  backendName: 'ЛимитныеСправки',
  resultColumns: baseResultColumns,
};

export default definition;
