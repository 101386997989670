import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Row,
} from 'react-bootstrap';
import { useEditor, useInitParams } from '../../newEditor';
import meta from '../../../constants/meta';
import { elementKFK as elementKFKMeta } from '../../../constants/meta/catalogs/elementKFK';
import { getRouterEditor } from '../basic';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import { EditorItemPicker, EditorDateInput } from '../../../components/bootstrap_components/editorControls';
import RegEditorCommandPanel from '../../newEditor/commandPanels/inforeg';

const modelType = 'infoRegs';
const modelName = 'kbp7ElemBetween';

const def = meta[modelType][modelName];

// const props = {
//   // Дополнительные данные
//   HighLevelCSU: { label: 'Розпорядник вищого рівня', name: 'РаспорядительФУ' },
//   KBP7: { label: 'КБП7', name: 'КБП7' },
//   HighLevelElemets: { label: 'ЭлементыВУ', name: 'ЭлементыВУ' },
// };

function Kbp7ElemBetweenEditor({
  id, search, onClose, onSave,
}) {
  const {
    reason, copyFrom, isGroup, defaults,
  } = useInitParams(search);
  const {
    data, loading, err, changed,
    permissions, actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    isGroup,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  const [adata, setAData] = useState({
    kbp7: null,
    highLevelCSU: null,
  });

  const hlElementFilter = useMemo(
    () => ([
      {
        fieldName: elementKFKMeta.columns.owner.name,
        value: adata.highLevelCSU,
      },
      {
        fieldName: elementKFKMeta.columns.KBP7.name,
        value: adata.kbp7,
      },
    ]),
    [adata.highLevelCSU, adata.kbp7],
  );
  useEffect(
    () => {
      if (data && data[def.columns.element.name]) {
        actions.onSRWithoutContext('GetAddData', {
          element: data[def.columns.element.name],
          period: data[def.columns.period.name],
        })
          .then((r) => setAData({
            kbp7: r['КБП7'],
            highLevelCSU: r['РаспорядительВУ'],
          }))
          .catch((e) => console.error(e.message));
      }
    },
    [actions, data],
  );
  return (
    <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
      <Card.Header>
        <RegEditorCommandPanel
          changed={changed}
          onClose={onClose}
          permissions={permissions}
          actions={actions}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            {/* <StyledLabel>{def.columns.period.label}</StyledLabel> */}
            <EditorDateInput
              value={data[def.columns.period.name]}
              onChange={(e, v) => actions.onChange({ [def.columns.period.name]: v })}
              label={def.columns.period.label}
              required
              description="Дата, з якої діє цей запис. Як що цей запис діє з 'початку часів', використовуйте 01 січня 2000 року"
            />
          </Col>
          <Col>
            <EditorItemPicker
              id={`${data.id}-element`}
              value={data[def.columns.element.name]}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, v) => actions.onChange({
                [def.columns.element.name]: v,
                [def.columns.classifier.name]: null,
              })}
              label={def.columns.element.label}
            />
          </Col>
          <Col>
            <EditorItemPicker
              id={`${data.id}-classifier`}
              value={data[def.columns.classifier.name]}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, v) => actions.onChange({ [def.columns.classifier.name]: v })}
              label={def.columns.classifier.label}
              filter={hlElementFilter}
              noHierarchy
            />
          </Col>
        </Row>

      </Card.Body>
    </EditorWrapper>
  );
}

Kbp7ElemBetweenEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

Kbp7ElemBetweenEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};
export { Kbp7ElemBetweenEditor as StdEditor };

export default getRouterEditor(def)(Kbp7ElemBetweenEditor);
