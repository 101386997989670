import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/forecastAdd9';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'expenses';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.indicator.name]: v });
      await onSR('CHANGE_KDB_EXPENSES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="indicatorNum" highlighted={highlights.includes('indicatorNum')}>
              <TextInput
                value={row[tableMD.columns.indicatorNum.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.indicatorNum.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="indicator" highlighted={highlights.includes('indicator')}>
              <ItemPicker
                value={row[tableMD.columns.indicator.name]}
                modelType="cat"
                modelName="forecastIndicatorBR"
                onChange={onKDBChange}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="expensesSF_Report" highlighted={highlights.includes('expensesSF_Report')}>
              <NumberInput
                value={row[tableMD.columns.expensesSF_Report.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.expensesSF_Report.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="expensesSF_Approved" highlighted={highlights.includes('expensesSF_Approved')}>
              <NumberInput
                value={row[tableMD.columns.expensesSF_Approved.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.expensesSF_Approved.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="expensesSF_Plan" highlighted={highlights.includes('expensesSF_Plan')}>
              <NumberInput
                value={row[tableMD.columns.expensesSF_Plan.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.expensesSF_Plan.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="expensesSF_Plan1" highlighted={highlights.includes('expensesSF_Plan1')}>
              <NumberInput
                value={row[tableMD.columns.expensesSF_Plan1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.expensesSF_Plan1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="expensesSF_Plan2" highlighted={highlights.includes('expensesSF_Plan2')}>
              <NumberInput
                value={row[tableMD.columns.expensesSF_Plan2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.expensesSF_Plan2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.expensesGF_Report.name]: PropTypes.number,
    [tableMD.columns.expensesSF_Report.name]: PropTypes.number,
    [tableMD.columns.expensesGF_Approved.name]: PropTypes.number,
    [tableMD.columns.expensesSF_Approved.name]: PropTypes.number,
    [tableMD.columns.expensesGF_Plan.name]: PropTypes.number,
    [tableMD.columns.expensesSF_Plan.name]: PropTypes.number,
    [tableMD.columns.expensesGF_Plan1.name]: PropTypes.number,
    [tableMD.columns.expensesSF_Plan1.name]: PropTypes.number,
    [tableMD.columns.expensesGF_Plan2.name]: PropTypes.number,
    [tableMD.columns.expensesSF_Plan2.name]: PropTypes.number,
    [tableMD.columns.indicator.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  onSR: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
