import React, {
  useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import Selector from '../../lister/selector';
import { emptyUid } from '../../../constants/meta/common';
import {
  CommandPanelButtonText,
  ModalDiv,
  ContentDiv,
  Header,
  NegativeButton,
  PositiveButton,
  ContainerHeader,
} from '../../../components/Form/styledForm';
import Checkbox from '../../../components/styledCheckbox/toggleCheckbox';

const selector = (store) => ({
  currentFO: store.getIn(['auth', 'sessionOptions', 'fo']),
  currentBudget: store.getIn(['auth', 'sessionOptions', 'budget']),
});
function LoadReportVariant({
  onLoad, reportKey, disabled, LoadButton,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(false);
  const [item, setItem] = useState(new Map());

  const handleSelect = useCallback(
    (e, i) => setItem(i),
    [],
  );

  const handleChoice = useCallback(
    (e, i) => {
      if (!i.get('isGroup', false)) {
        onLoad(e, i);
        setModalOpened(false);
      }
    },
    [onLoad],
  );

  const { currentFO, currentBudget } = useSelector(selector);

  const filter = useMemo(
    () => ([
      { fieldName: 'ФинансовыйОрган', value: globalSettings ? { id: emptyUid, repr: '' } : currentFO },
      { fieldName: 'Бюджет', value: globalSettings ? { id: emptyUid, repr: '' } : currentBudget },
      { fieldName: 'Ключ', value: reportKey },
    ]),
    [currentBudget, currentFO, globalSettings, reportKey],
  );

  return (
    <>
      <LoadButton disabled={disabled} onClick={() => setModalOpened(true)}>
        Завантажити налаштування
      </LoadButton>

      {modalOpened
        && (
          <ModalDiv zIndexModal={1000}>
            <ContentDiv>
              <ContainerHeader>
                <Header>Оберіть варіант звіту для завантаження</Header>
                <div>
                  <PositiveButton
                    onClick={(e) => handleChoice(e, item)}
                    disabled={item.size === 0}
                  >
                    Обрати
                  </PositiveButton>
                  <NegativeButton onClick={() => setModalOpened(false)}>
                    Скасувати
                  </NegativeButton>
                </div>
              </ContainerHeader>
              <div>
                <Selector
                  modelType="cat"
                  modelName="reportSettings"
                  onSelect={handleSelect}
                  onChoice={handleChoice}
                  filter={filter}
                  CommandPanel={(
                    <Checkbox
                      value={globalSettings}
                      onChange={() => setGlobalSettings(!globalSettings)}
                      label={globalSettings ? 'Загальні налашлування' : 'Персональні налаштування'}
                      toggle
                    />
                  )}
                />
              </div>
            </ContentDiv>
          </ModalDiv>
        )}
    </>
  );
}

LoadReportVariant.propTypes = {
  onLoad: PropTypes.func.isRequired,
  reportKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  LoadButton: PropTypes.element,
};

LoadReportVariant.defaultProps = {
  disabled: false,
  LoadButton: CommandPanelButtonText,
};

// filter: this.getFilter(false),

// getFilter = (globalSettins) => ([
//   { fieldName: 'ФинансовыйОрган', value: globalSettins ? { id: emptyUid, repr: '' } : currentFO },
//   { fieldName: 'Бюджет', value: globalSettins ? { id: emptyUid, repr: '' } : currentBudget },
//   { fieldName: 'Ключ', value: this.props.reportKey },
// ]);

export default LoadReportVariant;
