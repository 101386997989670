/* eslint-disable react/no-array-index-key */
import React from 'react';

function ImportVpEditor() {
  return (
    <h1>
      ImportVp
    </h1>
  );
}

export default ImportVpEditor;
