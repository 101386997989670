import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  Button, ButtonGroup, Card,
  Col, Collapse, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import FilterGrid from '../../../../basicEditor/stdFilters/filterGrid';
import DocList from './docs';
import DocDetails from './docDetails';
import { EditorDateInput } from '../../../../basicEditor/editorControls';
import { CommandPanel, Loader } from '../../../../../components/bootStrap';
import {
  HeaderText, FooterText, FooterLabel, ContainerDiv,
} from '../../../../basicEditor/stdFilters/style';
import { CPButton } from '../../../../../components/bootStrap/buttons/styles';
import useFilter from '../../../../basicEditor/stdFilters/useFilter';
import { moneysStingOptions } from '../../../../../constants/moneys';

const API_URL = '/api/financials/finrequestrow/';

const LOCAL_FILTERS = ['begin_date', 'end_date'];

function FilterEditor({
  overrideFilter, defaultFilter,
  onClose, onOk,
}) {
  const {
    filteringFields, items, loading, filters, setFilterHandler, fields, error, ready,
  } = useFilter({ apiUrl: API_URL, defaultFilter, overrideFilter });

  const displayFilteringFields = useMemo(
    () => filteringFields.filter((ff) => !LOCAL_FILTERS.includes(ff.name)),
    [filteringFields],
  );

  const [docs, setDocs] = useState([]);
  const [activeDoc, setActiveDoc] = useState(null);
  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );
  useEffect(() => {
    const docIds = new Set(items.map((d) => d.header.id));
    const newDocs = [...docIds].map((dId) => {
      const f0 = items.filter((item) => item.header.id === dId)[0];
      return {
        id: f0.header.id,
        repr: f0.header.repr,
        use: true,
      };
    });
    setDocs(newDocs);
    if (docIds) {
      setActiveDoc([...docIds][0]);
    }
  }, [items]);

  const selectedItems = useMemo(
    () => docs.filter((d) => d.use)
      .reduce((R, d) => [...R, ...items.filter((i) => i.header.id === d.id)], []),
    [docs, items],
  );
  const totals = useMemo(
    () => ({
      docsCount: docs.filter((d) => d.use).length,
      sum: selectedItems.reduce((R, i) => R + i.amount, 0),
    }),
    [docs, selectedItems],
  );

  const docDetails = useMemo(
    () => items.filter((item) => item.header.id === activeDoc),
    [activeDoc, items],
  );

  const [showFilters, setShowFilters] = useState(false);

  return ready && (
    <>
      <Modal.Header closeButton>
        <HeaderText>Завантаження заявок</HeaderText>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <Loader text="Завантаження" />
        )}
        {error && (
          <Alert variant="danger">
            <Alert.Heading>
              {error}
            </Alert.Heading>
          </Alert>
        )}
        <Container fluid>
          <Row>
            <Col>
              <EditorDateInput
                controlId="begin_date"
                label="Відібрати документи за період з"
                required
                value={filters.begin_date.value}
                onChange={(e, v) => setFilterHandler('begin_date', v, true)}
              />
            </Col>
            <Col>
              <EditorDateInput
                controlId="end_date"
                label="по"
                required
                value={filters.end_date.value}
                onChange={(e, v) => setFilterHandler('end_date', v, true)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CommandPanel
                leftPart={(
                  <>
                    <CPButton
                      onClick={() => setShowFilters(!showFilters)}
                      title="Фільтрування"
                      icon={faFilter}
                    />
                    <ButtonGroup>
                      <CPButton
                        onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                        title="Обрати всі"
                        icon={faCheckSquare}
                      />
                      <CPButton
                        onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                        title="Зняти відмітки"
                        icon={faSquare}
                      />
                    </ButtonGroup>
                  </>
                )}
              />
              <Card className="border-0">
                <Collapse in={showFilters} appear>
                  <Card.Body className="p-2">
                    <FilterGrid
                      filters={filters}
                      filteringFields={displayFilteringFields}
                      onSetFilter={setFilterHandler}
                    />
                  </Card.Body>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <ContainerDiv>
            <Row>
              <Col>
                <DocList
                  docs={docs}
                  onSelectDoc={setActiveDoc}
                  onToggleDoc={onToggleDoc}
                  activeDoc={activeDoc}
                />
              </Col>
              <Col sm={8}>
                <DocDetails
                  rows={docDetails}
                  fields={fields}
                />
              </Col>
            </Row>
          </ContainerDiv>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <FooterText>
          Буде завантажено
          {' '}
          <FooterLabel>{`${totals.docsCount}`}</FooterLabel>
          {' '}
          {`документ${(() => {
            switch (totals.docsCount) {
              case 1:
                return '';
              case 2:
              case 3:
              case 4:
                return 'и';
              default:
                return 'ів';
            }
          })()} на суму`}
          <FooterLabel>{`${totals.sum.toLocaleString('uk', moneysStingOptions)}`}</FooterLabel>
          {' '}
          грн.
        </FooterText>
        <Button
          variant="success"
          onClick={() => onOk(selectedItems.map((i) => i.id))}
        >
          Завантажити
        </Button>
        <Button variant="dark" onClick={onClose}>Закрити</Button>
      </Modal.Footer>
    </>
  );
}

FilterEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  overrideFilter: PropTypes.shape({}),
  defaultFilter: PropTypes.shape({}),
};

FilterEditor.defaultProps = {
  overrideFilter: {},
  defaultFilter: {},
};

export default FilterEditor;
