const frontendURL = '/doc/forecastprepareadd1/';
const backendURL = '/api/forecasts/forecastprepareadd1header/';
const name = 'Орієнтовні граничні показники (Додаток 1)';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
