import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  owner: { label: 'Бюджет', name: 'Владелец', visible: false },
  TypeTaskProgramm: {
    label: 'Типове завдання програми',
    name: 'ТиповыеЗаданияПрограмм',
    visible: true,
  },
  IDMinFin: {
    label: 'ПК завдання (для обміну з Мінфіном)',
    name: 'ИдМинФин',
    visible: false,
  },
  TypeProgramm: {
    label: 'Типова бюджетна програма',
    name: 'ТиповаяПрограмма',
    visible: true,
  },
  NotActual: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: true,
  },
  LimitedOnKFK: {
    label: 'Дозволити використовувати для фіксованого переліку КБП',
    name: 'флОграничитьПоКФК',
    visible: false,
  },
  // Дополнительные свойства
  DateKl: {
    label: 'Дата',
    name: 'ДатаКл',
    visible: true,
  },
  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const tasks = {
  label: 'Напрями використання',
  name: 'Tasks',
  backendName: 'Задания',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/tasks',
  listColumns: [...(new Set([...reqListerColumns, 'TypeTaskProgramm', 'NotActual']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tasks;
