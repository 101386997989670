import { proxyApi } from '../constants/misc';
import store from '../store';
import { logout, refresh } from '../actions/auth';
import { tryRefresh } from './auth';

/**
 * @const
 * @type {Set<string>}
 */
const METHODS = new Set(['POST', 'PUT', 'DELETE', 'PATCH', 'GET']);

/**
 * @function
 * @param {object} params
 * @return {string}
 */
const paramConverter = (params) => Object.keys(params)
  .map((k) => `${k}=${params[k]}&`)
  .join('');

/**
 * @function
 * @param {string} method
 * @param {string} uri
 * @param {object} msg
 * @param {object} controller
 * @param {string} backend
 * @return {Promise<Response>}
 */
export const initReq = async (method, uri, msg, controller, backend = null) => {
  const accessToken = store.getState().getIn(['auth', 'accessToken']);
  const AP = store.getState().getIn(['auth', 'AP']);
  if (!accessToken) throw new Error('Невозможно получить token доступа к backend.');
  const accessPoint = backend || proxyApi[AP];

  const signal = controller ? controller.signal : null;

  const loader = async (t) => fetch(
    `${accessPoint}${uri}${t === 'GET' ? `?${paramConverter(msg)}` : ''}`,
    {
      signal,
      method,
      headers: {
        Authorization: `Bearer ${t}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      mode: 'cors',
      credentials: 'include',
      body: method === 'GET' ? null : JSON.stringify(msg),
    },
  );
  const r = await loader(accessToken);
  if (r.status === 403) {
    const refreshToken = store.getState().getIn(['auth', 'refreshToken']);
    const refreshResponse = await tryRefresh({ backEnd: accessPoint, refreshToken });
    if (refreshResponse.ok) {
      const d = await refreshResponse.json();
      const newToken = d.access_token;
      store.dispatch(refresh({ token: newToken }));
      return loader(newToken);
    }
    store.dispatch(logout());
  }
  return r;
};

/**
 * @function
 * @property {func} post$
 * @property {func} postCat$
 * @property {func} postDoc$
 * @property {func} postInfoReg$
 * @property {func} postAccReg$
 * @property {func} put$
 * @property {func} putCat$
 * @property {func} putDoc$
 * @property {func} putInfoReg$
 * @property {func} putAccReg$
 * @property {func} delete$
 * @property {func} deleteCat$
 * @property {func} deleteDoc$
 * @property {func} deleteInfoReg$
 * @property {func} deleteAccReg$
 * @property {func} patch$
 * @property {func} patchCat$
 * @property {func} patchDoc$
 * @property {func} patchInfoReg$
 * @property {func} patchAccReg$
 * @return {Promise<object>}
 */
const api = new Proxy(initReq, {
  get(target, propKey) {
    if ((propKey) === 'prototype') return {};
    const reqMethod = [...METHODS].find((el) => propKey.startsWith(el.toLowerCase()));

    if (!reqMethod) {
      throw new Error(`Unknown or disabled http-method "${propKey}". Use Force or [${[...METHODS]}], Luke.`);
    }

    const fn = propKey
      .substring(reqMethod.length)
      .replace(/([a-z])([A-Z])/g, '$1/$2')
      .toLowerCase();

    return (...args) => {
      const controller = args.find((item) => item && item.signal);
      /**
       * @const
       * @desc uri part from argument or empty-string
       * @type {string}
       */

      const partUri = args.shift() || '';

      /**
       * @const
       * @desc Prepared path for request with cutted '$/' at end.
       * @type {string}
       */
      const finalPath = partUri.length
        ? `${fn.slice(0, -1)}/${partUri}`
        : `${fn.slice(0, -1)}`;

      /**
       * @const
       * @type {object}
       */
      const requestData = args.shift() || {};

      // Use for debug request
      // console.log('REQUEST URI: ', partUri);

      return target(reqMethod, `api/req${finalPath}`, requestData, controller);
    };
  },
});

export default api;
