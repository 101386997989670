import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: {
    label: 'Найменування',
    name: 'Наименование',
    visible: true,
  },
  oowner: { label: 'Власник', name: 'Владелец', visible: false },
  Surname: { label: 'Прізвище', name: 'Фамилия', visible: false },
  Name: { label: 'Ім\'я', name: 'Имя', visible: false },
  Position: { label: 'Посада', name: 'Должность', visible: false },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  MiddleName: { label: 'По-батькові ', name: 'Отчество', visible: false },
  PhoneNumberWork: { label: 'Контактний номер телефону (робочий)', name: 'ТелефонРабочий', visible: false },
  PhoneNumberMob: { label: 'Контактний номер телефону (мобільний)', name: 'ТелефонМоб', visible: false },
  Email: { label: 'Адреса електронної пошти (Е-mail)', name: 'ЭлПочта', visible: false },
  reason: { label: 'Підстава, відповідно до якої особа виконує обов’язки керівника (у разі відсутності керівника)', name: 'Основание', visible: false },
  Date_kl: {
    label: 'станом на:',
    name: 'ДатаКл',
    visible: false,
  },
  role: { label: 'Роль', name: 'Роль', visible: true },

  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const officials = {
  label: 'Посадові особи',
  name: 'officials',
  backendName: 'ДолжностнЛица',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/officials',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default officials;
