import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  owner: { label: 'Власник', name: 'Владелец', visible: false },
  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const outcomeGroup = {
  label: 'Групи видатків',
  name: 'outcomeGroup',
  backendName: 'ГруппаРасх',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/outcomeGroup',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default outcomeGroup;
