import React from 'react';
import PropTypes from 'prop-types';
import { DivFooter } from '../styledTable/styledTable';

function ComponentsFooter({ activeNameCell, footerCol }) {
  return (
    <>
      {footerCol && Object.keys(footerCol).map((areaName) => (
        <DivFooter
          areaName={areaName}
          key={`Footer${areaName}`}
          isActive={areaName === activeNameCell}
        >
          {footerCol[areaName]}
        </DivFooter>
      ))}
    </>
  );
}

export default ComponentsFooter;

ComponentsFooter.propTypes = {
  activeNameCell: PropTypes.string,
  footerCol: PropTypes.shape({}),
};

ComponentsFooter.defaultProps = {
  activeNameCell: '',
  footerCol: {},
};
