import React from 'react';
import { Card } from 'react-bootstrap';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import CardDropdown from './CardDropdown';
import DynamicsRevenueChart from './DynamicsRevenueChart';

const dataDynamicsRevenue = {
  currentYear: [0, 5000, 18000, 40000, 58000, 65000, 90000, 110000, 140000],
  lastYear: [0, 10000, 24000, 35000, 45000, 53000, 57000, 68000, 79000],
};

function DynamicsRevenue() {
  return (
    <Card className="h-100">
      <CardHeader
        title="Порівняння надходжень з минулим роком"
        titleTag="h6"
        className="py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body>
        <DynamicsRevenueChart data={dataDynamicsRevenue} />
      </Card.Body>
    </Card>
  );
}

export default DynamicsRevenue;
