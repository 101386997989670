import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ETextInput } from '../../../../../../components/Controls/withErrors';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from '../factWorkerCategory/propTypes';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
  width: 100%
`;

function ConsequencesPage(props) {
  const {
    data, fields, errors, onChange, year,
  } = props;

  const yearDef = year || new Date().getFullYear();
  const year1 = `${(year || yearDef) + 1}`;
  const year2 = `${(year || yearDef) + 2}`;

  return (
    <>
      <StyledLabel>
        {`${fields.consequences0.label} на ${yearDef} рік`}
        <ETextInput
          value={data.consequences0}
          onChange={(e, v) => onChange({ consequences0: v })}
          readOnly={fields.consequences0.read_only}
          errors={errors.consequences0}
        />
      </StyledLabel>
      <StyledLabel>
        {`${fields.consequences0.label} на ${year1}-${year2} роки`}
        <ETextInput
          value={data.consequences123}
          onChange={(e, v) => onChange({ consequences123: v })}
          readOnly={fields.consequences123.read_only}
          errors={errors.consequences123}
        />
      </StyledLabel>
    </>
  );
}

ConsequencesPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  year: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

ConsequencesPage.defaultProps = {
  errors: [],
};

export default ConsequencesPage;
