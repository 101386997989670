import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from 'prop-types';
import React from 'react';
import { getColor } from '../../../../components/blanks/helpers/utils';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';
import useDiagramShower from '../diagramHook';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

const getOptions = (data) => ({
  color: [
    // getColor('info'),
    '#27bcfd',
    // success
    '#00d27a',
    // getColor('primary'),
    '#2c7be5',
    // getColor('danger'),
    '#e63757',
    // warning:
    // '#f5803e',
    // getColor('primary'),
    '#2c7be5',
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter(params) {
      return `<strong>${params.data.name}:</strong> ${params.percent}%`;
    },
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '67%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100'),
      },
      label: {
        show: false,
      },
      emphasis: {
        scale: false,
      },
      labelLine: { show: false },
      data: data.values.map((item) => ({
        name: item.name,
        value: item.amount,
      })),
    },
  ],
});

function CoreDetailChart({ data }) {
  const { containerChartRef, show } = useDiagramShower({});
  return (
    <div className="position-relative py-2" ref={containerChartRef}>
      {show && (
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions(data)}
        style={{ height: '15rem' }}
      />
      )}
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">Всього</p>
        <p className="fs-1 fs-sm-1 fs-md--1 fs-lg--1 fs-xl-1 mb-0 font-sans-serif fw-medium mt-n2">{data.total.toLocaleString('uk', moneysOneDigitStingOptions)}</p>
      </div>
    </div>
  );
}

CoreDetailChart.propTypes = {
  data: PropTypes.shape([]),
};

CoreDetailChart.defaultProps = {
  data: null,
};

export default CoreDetailChart;
