const frontendURL = '/rep/compareOutcomes/';
const name = 'Порівняння видатків між рівнями';
const sections = ['planning', 'execution'];

const instance = {
  frontendURL,
  name,
  sections,
};

export default instance;
