import { baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  signVariantLabel: { label: 'ПодписьТекст', name: 'ПодписьТекст' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  DocApproved: { label: 'Документ Затверджено', name: 'ДокументУтвержден' },
  signApprooved: { label: 'ПодписьСогласовано', name: 'ПодписьСогласовано' },
  signMFO: { label: 'ЕстьПодписиМФО', name: 'ЕстьПодписиМФО' },
  existDocSendToLogica: { label: 'ЕстьДокументОтправки', name: 'ЕстьДокументОтправки' },
};

const tables = {
};

/**
 * @const
 */
const definition = {
  name: 'expensesOnHealthPrinting',
  backendName: 'ПечатьИнформацияОРасходахНаЗдоровъе',
  label: 'Друк інформації про бюджетні видатки на охорону здоров\'я',
  frontend: 'dp/expensesOnHealthPrinting',
  columns,
  tables,
  resultColumns: baseResultColumns,
};

export default definition;
