import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnGroupAge = {
  label: 'Вік дітей у групі',
  name: 'bfnGroupAge',
  backendName: 'bfnGroupAge',
  defaultOrder,
  columns,
  frontend: 'cat/bfnGroupAge',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnGroupAge;
