// eslint-disable-next-line no-unused-vars
import React from 'react';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const FRLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

export default FRLister;
