/* eslint-disable no-case-declarations */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { ItemPicker } from '../../../../components/bootstrap_components/controls/itemField/itemPicker';
import { selectionMode } from '../../../../constants/meta/common';
import def from '../../../../constants/meta';
import {
  DateInput,
  NumberInput,
  SelectorInput,
  StringInput,
} from '../../../../components/bootstrap_components/controls';
import { filterValuePropType } from '../../hooks/filter';

function MultiValueInput({
  type, value, filter, onChange,
}) {
  if (!type) {
    return (
      <Alert variant="danger" className="mb-0">
        Не доступно
      </Alert>
    );
  }
  switch (type.ModelType) {
    case 'cat':
      const modelName = Object.values(def.cat).reduce(
      // eslint-disable-next-line no-confusing-arrow
        (R, d) => d.backendName === type.ModelName ? d.name : R, '');
      return (
        <ItemPicker
          value={value}
          onChange={onChange}
          modelType="cat"
          modelName={modelName}
          filter={filter}
          choiceSettings={selectionMode.foldersNItems}
        />
      );
    case 'N':
      const precision = type.ModelName.split(',')[1];
      return (
        <NumberInput
          value={value}
          onChange={onChange}
          precision={precision}
        />
      );
    case 'S':
      return (
        <StringInput
          value={value}
          onChange={onChange}
        />
      );
    case 'DT':
      return (
        <DateInput
          value={value}
          onChange={onChange}
        />
      );
    case 'B':
      return (
        <SelectorInput
          value={value}
          onChange={onChange}
          values={[{
            display_name: 'Так',
            value: true,
          }, {
            display_name: 'Ні',
            value: false,
          }]}
        />
      );
    default:
      return (
        <Alert variant="danger" className="mb-0">
          {`${type.ModelType}/${type.ModelName || ''} не підтримується. Зверніться до розробників`}
        </Alert>
      );
  }
}

MultiValueInput.propTypes = {
  type: PropTypes.shape({
    ModelType: PropTypes.oneOf(['cat', 'N', 'S', 'B', 'DT']).isRequired,
    ModelName: PropTypes.oneOfType([PropTypes.string]),
  }),
  value: PropTypes.oneOfType([
    filterValuePropType,
    PropTypes.arrayOf(filterValuePropType),
  ]),
  filter: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
};

MultiValueInput.defaultProps = {
  type: null,
  filter: [],
  value: null,
};

export default memo(MultiValueInput);
