import styled from 'styled-components';

const GridTemplate = styled.div`
  display: grid;
  grid-template-areas: "num IsApproved data number document sum Tags Author";
  grid-template-columns: 4% 7% 9% 8% 1fr 10% 1fr 9%;
  grid-auto-columns: 100px;
  grid-auto-rows: auto;
`;

export default GridTemplate;
