import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.investExtraMoney;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'constructionObject'}
            highlighted={highlights.includes('constructionObject')}
            onToggleHighlght={() => onToggleHighlght('constructionObject')}
          >
            {meta.columns.constructionObject.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'elementKEKV'}
            highlighted={highlights.includes('elementKEKV')}
            onToggleHighlght={() => onToggleHighlght('elementKEKV')}
          >
            {meta.columns.elementKEKV.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'KEKV'}
            highlighted={highlights.includes('KEKV')}
            onToggleHighlght={() => onToggleHighlght('KEKV')}
          >
            {meta.columns.KEKV.label}
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sfo'}
          highlighted={highlights.includes('sfo')}
          onToggleHighlght={() => onToggleHighlght('sfo')}
        >
          {meta.columns.sfo.label}
          на
          {year}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sf1'}
          highlighted={highlights.includes('sf1')}
          onToggleHighlght={() => onToggleHighlght('sf1')}
        >
          {meta.columns.sf1.label}
          на
          {year + 1}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sf2'}
          highlighted={highlights.includes('sf2')}
          onToggleHighlght={() => onToggleHighlght('sf2')}
        >
          {meta.columns.sf2.label}
          на
          {year + 2}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'act'}
          highlighted={highlights.includes('act')}
          onToggleHighlght={() => onToggleHighlght('act')}
        >
          {meta.columns.act.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'explanation'}
          highlighted={highlights.includes('explanation')}
          onToggleHighlght={() => onToggleHighlght('explanation')}
        >
          {meta.columns.explanation.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
