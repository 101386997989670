import AssignmentPrintingEditor from './assigmentPrinting/editor';
import AssigmentPrintingRevEditor from './assigmentPrintingRev/editor';
import AssigmentPrintingSourceEditor from './assigmentPrintingSource/editor';
import AttachedFilesEditor from './attachedFiles/editor';
import BudgetRequestPrintEditor from './budgetRequestPrint/editor';
import ChangeToAssignPlanRevEditor from './changeToAssignPlanRev/editor';
import ChangeToAssignPlanSourceEditor from './changeToAssignPlanSource/editor';
import ClientBankEditor from './clientBankUGB/editor';
import AssigmentPrintingEditor from './docRegistrPrinting/editor';
import DocSelectionEditor from './docSelection/editor';
import DocSelectionForecastEditor from './docSelectionForecast/editor';
import DocSelectionUpLevelEditor from './docSelectionUpLevel/editor';
import ExportDBF373Editor from './exportDBF373/editor';
import ExportPlansAndChangesToDBFEditor from './exportPlansAndChangesToDBF/editor';
import ExportPlanToDBFEditor from './exportPlanToDBF/editor';
import ExportVDBFKievEditor from './exportVDBFKiev/editor';
import ExportVDBFKievDod21Editor from './exportVDBFKievDod21/editor';
import ExportVDBFKievDod21csuEditor from './exportVDBFKievDod21csu/editor';
import FinPrintingEditor from './finPrinting/editor';
import ImportTreasFilesEditor from './importTreasFiles/editor';
import IndicatorPassportEditor from './indicatorPassport/editor';
import IndicatorsFactPrintEditor from './indicatorsFactPrint/editor';
import RequestsLoadingEditor from './requestsLoading/editor';
import RequestsLoadingPlaningDocEditor from './requestsLoadingPlaningDoc/editor';
import TreasureReportsDBFEditor from './treasureReportsDBF/editor';
import DigitalSing from './digitalSing';
import MessageForUsersEditor from './messageForUsers/editor';
import Authentication from '../authentication';
import InfoPaymentFinRequestEditor from './infoPaymentFinRequest/editor';
import SignData from '../signData';
import DocumentsInLogica from './documentsInLogica';
import PublicDocumentsInLogica from './publicDocumentsInLogica';
import SelfTesting from './selfTesting';
import LogikaImporterEditor from './logikaImportTreasureFiles';
import ExportSocialPaymentEditor from './exportSocialPayment/editor';
import CertificateOfAccountPrintEditor from './certificateOfAccountPrint/editor';
import ReferRegistryEditor from './referRegistry/editor';
import PeriodPlanPrintingEditor from './periodPlanPrinting/editor';
import RelatedRegisterInLogica from './relatedRegisterInLogica';
import ForecastAdd1PrintingEditor from './forecastAdd1Printing/editor';
import ForecastAdd2PrintingEditor from './forecastAdd2Printing/editor';
import ForecastAdd3PrintingEditor from './forecastAdd3Printing/editor';
import ForecastAdd4PrintingEditor from './forecastAdd4Printing/editor';
import ForecastAdd5PrintingEditor from './forecastAdd5Printing/editor';
import ForecastAdd6PrintingEditor from './forecastAdd6Printing/editor';
import ForecastAdd7PrintingEditor from './forecastAdd7Printing/editor';
import ForecastAdd8PrintingEditor from './forecastAdd8Printing/editor';
import ForecastAdd12PrintingEditor from './forecastAdd12Printing/editor';
import ForecastAdd11PrintingEditor from './forecastAdd11Printing/editor';
import ForecastAdd10PrintingEditor from './forecastAdd10Printing/editor';
import ForecastAdd9PrintingEditor from './forecastAdd9Printing/editor';
import PrepareForecastAdd1Editor from './prepareForecastAdd1/editor';
import PrepareForecastAdd2Editor from './prepareForecastAdd2/editor';
import PrepareForecastAdd5Editor from './prepareForecastAdd5/editor';
import PrepareForecastAdd4Editor from './prepareForecastAdd4/editor';
import PrepareForecastAdd3Editor from './prepareForecastAdd3/editor';
import FreeRemPrintEditor from './freeRemPrint/editor';
import PrintingFromLOGICAEditor from './printingFromLOGICA/editor';
import NetFOPPrintEditor from './netFOPPrint/editor';
import NetCategoryPrintEditor from './netCategoryPrint/editor';
import PrintPayment from './printPayment/editor';
import NetPrintEditor from './netPrint/editor';
import NetDBFEditor from './netDBF/editor';
import FinPrintingBREditor from './finPrintingBR/editor';
import PaymentTransferPrintEditor from './paymentTransferPrint/editor';
import MixIndicatorsSFPrintEditor from './mixIndicatorsSFPrint/editor';
import Taxes8PrintEditor from './taxes8Print/editor';
import Taxes2PrintEditor from './taxes2Print/editor';
import Taxes2PPrintEditor from './taxes2PPrint/editor';
import Taxes3PrintEditor from './taxes3Print/editor';
import Taxes3PPrintEditor from './taxes3PPrint/editor';
import Taxes4PrintEditor from './taxes4Print/editor';
import Taxes5PrintEditor from './taxes5Print/editor';
import Taxes6PrintEditor from './taxes6Print/editor';
import Taxes7PrintEditor from './taxes7Print/editor';
import Taxes81PrintEditor from './taxes81Print/editor';
import Taxes11PrintEditor from './taxes11Print/editor';
import RegInDBFEditor from './regInDBF/editor';
import ExpensesOnEducationPrintEditor from './expensesOnEducationPrinting/editor';
import ImportFromLogicaEditor from './importFromLogica/editor';
import RepTaxpayers from './repTaxpayers';
import LiquidityPrintEditor from './liquidityPrint/editor';
import GarantyPrintEditor from './garantyPrint/editor';
import ExpensesOnHealthPrintEditor from './expensesOnHealthPrinting/editor';
import CountExpensesOnEducationPrintEditor from './countExpensesOnEducationPrinting/editor';
import LocalDebtPrintEditor from './localDebtPrinting/editor';
import DataEducationPaymentPrintEditor from './dataEducationPaymentPrinting/editor';
import LiquidityPrintVer2Editor from './liquidityPrintVer2/editor';

const dpComponents = {
  assigmentPrinting: { editor: AssignmentPrintingEditor, isRoute: true },
  assigmentPrintingRev: { editor: AssigmentPrintingRevEditor, isRoute: false },
  assigmentPrintingSource: { editor: AssigmentPrintingSourceEditor, isRoute: false },
  attachedFiles: { editor: AttachedFilesEditor, isRoute: true },
  budgetRequestPrint: { editor: BudgetRequestPrintEditor, isRoute: false },
  changeToAssignPlanRev: { editor: ChangeToAssignPlanRevEditor, isRoute: false },
  changeToAssignPlanSource: { editor: ChangeToAssignPlanSourceEditor, isRoute: false },
  clientBankUGB: { editor: ClientBankEditor, isRoute: true },
  docRegisterPrinting: { editor: AssigmentPrintingEditor, isRoute: false },
  docSelection: { editor: DocSelectionEditor, isRoute: true },
  docSelectionForecast: { editor: DocSelectionForecastEditor, isRoute: true },
  docSelectionUpLevel: { editor: DocSelectionUpLevelEditor, isRoute: false },
  exportDBF373: { editor: ExportDBF373Editor, isRoute: true },
  exportPlansAndChangesToDBF: { editor: ExportPlansAndChangesToDBFEditor, isRoute: false },
  exportPlanToDBF: { editor: ExportPlanToDBFEditor, isRoute: true },
  exportVDBFKiev: { editor: ExportVDBFKievEditor, isRoute: false },
  exportVDBFKievDod21: { editor: ExportVDBFKievDod21Editor, isRoute: true },
  exportVDBFKievDod21csu: { editor: ExportVDBFKievDod21csuEditor, isRoute: true },
  finPrinting: { editor: FinPrintingEditor, isRoute: false },
  importTreasFiles: { editor: ImportTreasFilesEditor, isRoute: true },
  // indicatorFact: { editor: indicatorFactEditor, isRoute: true },
  indicatorPassport: { editor: IndicatorPassportEditor, isRoute: false },
  indicatorsFactPrint: { editor: IndicatorsFactPrintEditor, isRoute: false },
  requestsLoading: { editor: RequestsLoadingEditor, isRoute: false },
  requestsLoadingPlaningDoc: { editor: RequestsLoadingPlaningDocEditor, isRoute: false },
  treasureReportsDBF: { editor: TreasureReportsDBFEditor, isRoute: false },
  digitalSing: { editor: DigitalSing, isRoute: true },
  messageForUsers: { editor: MessageForUsersEditor, isRoute: true },
  authentication: { editor: Authentication, isRoute: true },
  infoPaymentFinRequest: { editor: InfoPaymentFinRequestEditor, isRoute: true },
  signData: { editor: SignData, isRoute: true },
  documentsInLogica: { editor: DocumentsInLogica, isRoute: true },
  publicDocumentsInLogica: { editor: PublicDocumentsInLogica, isRoute: true },
  selfTesting: { editor: SelfTesting, isRoute: true },
  logikaImportTreasureFiles: { editor: LogikaImporterEditor, isRoute: true },
  exportSocialPayment: { editor: ExportSocialPaymentEditor, isRoute: true },
  certificateOfAccountPrint: { editor: CertificateOfAccountPrintEditor, isRoute: true },
  referRegistry: { editor: ReferRegistryEditor, isRoute: true },
  periodPlanPrinting: { editor: PeriodPlanPrintingEditor, isRoute: true },
  relatedRegisterInLogica: { editor: RelatedRegisterInLogica, isRoute: true },
  forecastAdd1Printing: { editor: ForecastAdd1PrintingEditor, isRoute: true },
  forecastAdd2Printing: { editor: ForecastAdd2PrintingEditor, isRoute: true },
  forecastAdd3Printing: { editor: ForecastAdd3PrintingEditor, isRoute: true },
  forecastAdd4Printing: { editor: ForecastAdd4PrintingEditor, isRoute: true },
  forecastAdd5Printing: { editor: ForecastAdd5PrintingEditor, isRoute: true },
  forecastAdd6Printing: { editor: ForecastAdd6PrintingEditor, isRoute: true },
  forecastAdd7Printing: { editor: ForecastAdd7PrintingEditor, isRoute: true },
  forecastAdd8Printing: { editor: ForecastAdd8PrintingEditor, isRoute: true },
  forecastAdd12Printing: { editor: ForecastAdd12PrintingEditor, isRoute: true },
  forecastAdd11Printing: { editor: ForecastAdd11PrintingEditor, isRoute: true },
  forecastAdd10Printing: { editor: ForecastAdd10PrintingEditor, isRoute: true },
  forecastAdd9Printing: { editor: ForecastAdd9PrintingEditor, isRoute: true },
  prepareForecastAdd1: { editor: PrepareForecastAdd1Editor, isRoute: true },
  prepareForecastAdd2: { editor: PrepareForecastAdd2Editor, isRoute: true },
  prepareForecastAdd5: { editor: PrepareForecastAdd5Editor, isRoute: true },
  prepareForecastAdd4: { editor: PrepareForecastAdd4Editor, isRoute: true },
  prepareForecastAdd3: { editor: PrepareForecastAdd3Editor, isRoute: true },
  freeRemPrint: { editor: FreeRemPrintEditor, isRoute: true },
  printingFromLOGICA: { editor: PrintingFromLOGICAEditor, isRoute: true },
  netFOPPrint: { editor: NetFOPPrintEditor, isRoute: true },
  netCategoryPrint: { editor: NetCategoryPrintEditor, isRoute: true },
  netPrint: { editor: NetPrintEditor, isRoute: true },
  netDBF: { editor: NetDBFEditor, isRoute: true },
  printPayment: { editor: PrintPayment, isRoute: true },
  finPrintingBR: { editor: FinPrintingBREditor, isRoute: true },
  paymentTransferPrint: { editor: PaymentTransferPrintEditor, isRoute: true },
  mixIndicatorsSFPrint: { editor: MixIndicatorsSFPrintEditor, isRoute: true },
  taxes8Print: { editor: Taxes8PrintEditor, isRoute: true },
  taxes2Print: { editor: Taxes2PrintEditor, isRoute: true },
  taxes2PPrint: { editor: Taxes2PPrintEditor, isRoute: true },
  taxes3Print: { editor: Taxes3PrintEditor, isRoute: true },
  taxes3PPrint: { editor: Taxes3PPrintEditor, isRoute: true },
  taxes4Print: { editor: Taxes4PrintEditor, isRoute: true },
  taxes5Print: { editor: Taxes5PrintEditor, isRoute: true },
  taxes6Print: { editor: Taxes6PrintEditor, isRoute: true },
  taxes7Print: { editor: Taxes7PrintEditor, isRoute: true },
  taxes81Print: { editor: Taxes81PrintEditor, isRoute: true },
  taxes11Print: { editor: Taxes11PrintEditor, isRoute: true },
  regInDBF: { editor: RegInDBFEditor, isRoute: true },
  expensesOnEducationPrinting: { editor: ExpensesOnEducationPrintEditor, isRoute: true },
  importFromLogica: { editor: ImportFromLogicaEditor, isRoute: true },
  repTaxpayers: { editor: RepTaxpayers, isRoute: true },
  liquidityPrint: { editor: LiquidityPrintEditor, isRoute: true },
  garantyPrint: { editor: GarantyPrintEditor, isRoute: true },
  expensesOnHealthPrinting: { editor: ExpensesOnHealthPrintEditor, isRoute: true },
  countExpensesOnEducationPrinting: { editor: CountExpensesOnEducationPrintEditor, isRoute: true },
  localDebtPrinting: { editor: LocalDebtPrintEditor, isRoute: true },
  dataEducationPaymentPrinting: { editor: DataEducationPaymentPrintEditor, isRoute: true },
  liquidityPrintVer2: { editor: LiquidityPrintVer2Editor, isRoute: true },
};

export {
  dpComponents,
  AssignmentPrintingEditor,
  AssigmentPrintingRevEditor,
  AssigmentPrintingSourceEditor,
  AttachedFilesEditor,
  BudgetRequestPrintEditor,
  ChangeToAssignPlanRevEditor,
  ChangeToAssignPlanSourceEditor,
  ClientBankEditor,
  AssigmentPrintingEditor,
  DocSelectionEditor,
  DocSelectionForecastEditor,
  DocSelectionUpLevelEditor,
  ExportDBF373Editor,
  ExportPlansAndChangesToDBFEditor,
  ExportPlanToDBFEditor,
  ExportVDBFKievEditor,
  ExportVDBFKievDod21Editor,
  ExportVDBFKievDod21csuEditor,
  FinPrintingEditor,
  ImportTreasFilesEditor,
  // indicatorFactEditor,
  IndicatorPassportEditor,
  IndicatorsFactPrintEditor,
  RequestsLoadingEditor,
  RequestsLoadingPlaningDocEditor,
  TreasureReportsDBFEditor,
  DigitalSing,
  MessageForUsersEditor,
  Authentication,
  InfoPaymentFinRequestEditor,
  SignData,
  DocumentsInLogica,
  PublicDocumentsInLogica,
  SelfTesting,
  LogikaImporterEditor,
  ExportSocialPaymentEditor,
  CertificateOfAccountPrintEditor,
  ReferRegistryEditor,
  PeriodPlanPrintingEditor,
  RelatedRegisterInLogica,
  ForecastAdd1PrintingEditor,
  ForecastAdd2PrintingEditor,
  ForecastAdd3PrintingEditor,
  ForecastAdd4PrintingEditor,
  ForecastAdd5PrintingEditor,
  ForecastAdd6PrintingEditor,
  ForecastAdd7PrintingEditor,
  ForecastAdd8PrintingEditor,
  ForecastAdd12PrintingEditor,
  ForecastAdd11PrintingEditor,
  ForecastAdd10PrintingEditor,
  ForecastAdd9PrintingEditor,
  PrepareForecastAdd1Editor,
  PrepareForecastAdd2Editor,
  PrepareForecastAdd5Editor,
  PrepareForecastAdd4Editor,
  PrepareForecastAdd3Editor,
  FreeRemPrintEditor,
  PrintingFromLOGICAEditor,
  NetFOPPrintEditor,
  NetCategoryPrintEditor,
  NetPrintEditor,
  NetDBFEditor,
  PrintPayment,
  FinPrintingBREditor,
  PaymentTransferPrintEditor,
  MixIndicatorsSFPrintEditor,
  Taxes8PrintEditor,
  Taxes2PrintEditor,
  Taxes2PPrintEditor,
  Taxes3PrintEditor,
  Taxes3PPrintEditor,
  Taxes4PrintEditor,
  Taxes5PrintEditor,
  Taxes6PrintEditor,
  Taxes7PrintEditor,
  Taxes81PrintEditor,
  Taxes11PrintEditor,
  RegInDBFEditor,
  ExpensesOnEducationPrintEditor,
  ImportFromLogicaEditor,
  RepTaxpayers,
  LiquidityPrintEditor,
  GarantyPrintEditor,
  ExpensesOnHealthPrintEditor,
  CountExpensesOnEducationPrintEditor,
  LocalDebtPrintEditor,
  DataEducationPaymentPrintEditor,
};

export default dpComponents;
