/* eslint-disable react/no-array-index-key */
import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import Cell from './cell';

function RoxIdx({ rowIndex }) {
  return (
    <Badge bg="" text="secondary" className="fs--2">
      {rowIndex + 1}
    </Badge>
  );
}

RoxIdx.propTypes = {
  rowIndex: PropTypes.number.isRequired,
};

function TemplateRow({
  active,
  activeCol,
  row,
  fields,
  errors,
  onChangeRow,
  rowIndex,
  rowTemplate,
  highlights,
  onMoveRow,
}) {
  const rowSpan = rowTemplate.length;
  const ref = useRef(null);
  const numericRow = rowTemplate.map((el, idx) => {
    if (idx === 0) {
      return [
        {
          tableHook: 'numericRow',
          type: 'numericRow',
          props: { rowSpan },
        },
        ...el,
      ];
    }
    return el;
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: 'tpRow',
    item: { rowIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // eslint-disable-next-line no-unused-vars
  const [tpClass, setTpClass] = useState('');

  const [{ isOverCurrent }, dropRef] = useDrop({
    accept: 'tpRow',
    drop: (item) => {
      if (item.rowIndex !== rowIndex) {
        onMoveRow(item.rowIndex, rowIndex);
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item) => {
      if (item.rowIndex < rowIndex) {
        setTpClass('pb-4');
      } else if (item.rowIndex > rowIndex) {
        setTpClass('pt-4');
      } else {
        setTpClass('');
      }
    },
  });

  const dragDropRef = dragRef(dropRef(ref));

  return numericRow.map((tr, idx) => (
    <tr
      data-row={rowIndex}
      key={`trRow-${idx}`}
      ref={dragDropRef}
      className={classNames({ 'opacity-25': isDragging })}
    >
      {tr.map((cellProps, idxTr) => (
        <td
          key={`tdRow-${idxTr}`}
          className={classNames({ [tpClass]: isOverCurrent, tablePart: true })}
          {...cellProps.props}
        >
          <Cell
            highlighted={highlights.includes(cellProps.name)}
            cellProps={cellProps}
            rowIndex={rowIndex}
            fields={fields}
            row={row}
            errors={errors}
            onChangeRow={onChangeRow}
            active={active}
            activeCol={activeCol}
          />
        </td>
      ))}
    </tr>
  ));
}

TemplateRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func,
  onChangeRow: PropTypes.func.isRequired,
  rowTemplate: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  fields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
  row: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeCol: PropTypes.string,
  onMoveRow: PropTypes.func.isRequired,
};

TemplateRow.defaultProps = {
  active: false,
  selected: false,
  errors: null,
  activeCol: null,
  onActivateRow: null,
};

export default memo(TemplateRow);
