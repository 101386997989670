import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import WinManagerContext from '../../minfin/providers/WinManagerContext/context';

function WindowsManager() {
  const { linkComponents, currentURL, containerRefs } = useContext(WinManagerContext);
  const location = useLocation();
  const hideComponents = currentURL !== location.pathname;

  const linkComp = linkComponents || [];

  return (
    <div
      className={classNames('windows-manager scrollbar ', { 'flex-fill': !hideComponents, 'd-none': hideComponents })}
      id="docBody"
      style={{ display: 'flex' }}
    >
      {linkComp.map((el) => {
        const Comp = el.component;
        const { leftToolBar } = el.props;
        return (
          <div
            key={`windowed-component-of-${el.url}`}
            className={classNames('py-2 h-100', { 'd-none': el.url !== currentURL || hideComponents, 'w-100': !leftToolBar })}
            ref={containerRefs(el.url)}
          >
            <Comp {...el.props} />
          </div>
        );
      })}
    </div>
  );
}

export default WindowsManager;
