import { useCallback, useMemo, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useGroupEditor = ({
  onDelete, items, onRemoveSubItem, onSwap,
}) => {
  const [ctxMenu, setCtxMenu] = useState({ x: null, y: null, node: null });
  const [editDialog, setEditDialog] = useState({ opened: false, name: null });
  const ctxMenuopened = ctxMenu.x !== null;

  const onCloseCtxMenu = useCallback(
    () => setCtxMenu({ x: null, y: null, node: null }),
    [],
  );

  const onCtxMenu = useCallback(
    (e, node) => {
      setCtxMenu({ x: e.clientX + 2, y: e.clientY - 6, node });
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );
  const onDeleteItem = useCallback(
    () => {
      if (ctxMenu.node) onDelete(ctxMenu.node.dataset.name);
      onCloseCtxMenu();
    },
    [ctxMenu.node, onCloseCtxMenu, onDelete],
  );
  const onEditItem = useCallback(
    () => ctxMenu.node && ctxMenu.node.dataset.name
      && setEditDialog({ opened: true, name: ctxMenu.node.dataset.name }),
    [ctxMenu.node],
  );
  const onCloseEditDialog = useCallback(
    () => setEditDialog({ opened: false, name: null }),
    [],
  );
  const onRemoveSubGroup = useCallback(
    (name) => editDialog.name && onRemoveSubItem(editDialog.name, name),
    [editDialog.name, onRemoveSubItem],
  );
  const subItems = useMemo(
    () => items.filter((f) => f.name === editDialog.name).reduce((R, f) => f.items, []),
    [editDialog.name, items],
  );
  const onUpSubgroup = useCallback(
    (i) => editDialog.name && i > 0 && onSwap(editDialog.name, i, i - 1),
    [editDialog.name, onSwap],
  );
  const onDownSubgroup = useCallback(
    (i) => editDialog.name && i < (subItems.length - 1) && onSwap(editDialog.name, i, i + 1),
    [editDialog.name, onSwap, subItems.length],
  );
  return ({
    ctxMenu,
    ctxMenuopened,
    onCtxMenu,
    onCloseCtxMenu,
    onDeleteItem,
    onEditItem,
    editDialog,
    onCloseEditDialog,
    subItems,
    onRemoveSubGroup,
    onUpSubgroup,
    onDownSubgroup,
  });
};
