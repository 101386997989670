import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.descendantsDocuments;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col lg={1}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'isApproved'}
              highlighted={highlights.includes('isApproved')}
              onToggleHighlght={() => onToggleHighlght('isApproved')}
            >
              {meta.columns.isApproved.label}
            </TableHeaderCell>
          </Col>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'document'}
              highlighted={highlights.includes('document')}
              onToggleHighlght={() => onToggleHighlght('document')}
            >
              {meta.columns.document.label}
            </TableHeaderCell>
          </Col>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSU'}
              highlighted={highlights.includes('CSU')}
              onToggleHighlght={() => onToggleHighlght('CSU')}
            >
              {meta.columns.CSU.label}
            </TableHeaderCell>
          </Col>
          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'author'}
              highlighted={highlights.includes('author')}
              onToggleHighlght={() => onToggleHighlght('author')}
            >
              {meta.columns.author.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'notes'}
              highlighted={highlights.includes('notes')}
              onToggleHighlght={() => onToggleHighlght('notes')}
            >
              {meta.columns.notes.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
