import React from 'react';
import PropTypes from 'prop-types';
import { CollapseIcon } from '../../../icons';
import { StyledDropButton, StyledImg } from './styles';

export function CollapseButton({ content, onClick, disabled }) {
  return (
    <StyledDropButton
      style={{ marginRight: '0' }}
      onClick={onClick}
      disabled={disabled}
      title={content}
    >
      <StyledImg src={CollapseIcon} alt={content} />
    </StyledDropButton>
  );
}

CollapseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CollapseButton.defaultProps = {
  onClick: () => null,
  content: 'Згорнути',
  disabled: false,
};

export default CollapseButton;
