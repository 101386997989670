import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  notes: { label: 'Примітка', name: 'Комментарий', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  account: { label: 'Банківський рахунок розпорядника', name: 'БанковскийСчет', visible: true },
  client: { label: 'Постачальник', name: 'Контрагент', visible: true },
  elementKFK: { label: 'Деталізація КПКВ', name: 'ЭлементКФК', visible: true },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  isApproved: {
    label: 'Отримано',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  documentKind: { label: 'Вид документу', name: 'ВидДокумента', visible: true },
  reason: { label: 'Підстава', name: 'Основание', visible: false },
  rNo: { label: 'Реєстраційний номер', name: 'РегНомер', visible: true },
  KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ', visible: false },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  clientAccount: { label: 'Банківський рахунок постачальника', name: 'БанковскийСчетКонтрагента', visible: true },
  legalObligation: { label: 'Юридичне зобов\'язання', name: 'ЮридическоеОбязательство', visible: true },
  clientDocNo: { label: 'Номер документа контрагента', name: 'НомерДокументаКонтрагента', visible: false },
  clientDocDate: { label: 'Дата документа', name: 'ДатаДокументаКонтрагента', visible: false },
  deliveryDate: { label: 'Дата поставки товарів, послуг, робіт', name: 'ДатаПоставкиТоваровУслуг', visible: false },
  dueTime: { label: 'Термін сплати за договором', name: 'СрокОплатыПоДоговору', visible: false },
  finTime: { label: 'Дата фінансування', name: 'ДатаФинансирования', visible: false },
  limitation: { label: 'Обмеження оприлюднення', name: 'ИнфОграниченияОгласки', visible: false },
  // // Дополнитльеные реквизиты
  files: { label: 'Додані файли', name: 'Файлы' },
  elememtKDBVisible: { label: 'Джерело фінансування', name: 'ДанныеОбязательстваЭлементКДБВидимость', visible: false },
  activeObjectsBuilding: { label: 'Об\'єкт будівництва/ремонту', name: 'флОбъектыСтроительства' },
  typesRepair: { label: 'соотвВидРем', name: 'соотвВидРем' },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  codesKEKV: { label: 'КЕКВ', name: 'КодыКЕКВ', visible: true },
  isAccountHighLevelCSU: { label: 'Використовувати казначейські рахунки розпорядника вищого рівня', name: 'флКазнСчетаРаспВерхнегоУр' },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ДанныеОбязательства',
    label: 'Дані зобов\'язання',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      KEKVCode: { label: 'Код КЕКВ', name: 'КодКЕКВ' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      sum: { label: 'Сума', name: 'Сумма' },
      prepaid: { label: 'в т.ч. аванс', name: 'Аванс' },
      notes: { label: 'Примітка', name: 'Комментарий' },
      buildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
    },
  },
  presentedDocs: {
    name: 'СписокДокументов',
    label: 'Документи, що додаються',
    columns: {
      use: { label: 'М', name: 'Метка' },
      doc: { label: 'Документ, що додається', name: 'ПредьявляемыйДокумент' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Фінансове зобов\'язання',
  name: 'financeObligation',
  backendName: 'ФинансовоеОбязательство',
  columns,
  frontend: 'doc/financeObligation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'rNo',
      'documentKind',
      'sum',
      'account',
      'isApproved',
      'FI',
      'client',
      'clientAccount',
      'legalObligation',
      'codesKEKV',
      'elementKFK',
      'appr',
      'fondObject',
      'author',
      'budget',
      'files',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
