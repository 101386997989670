const Exchange = {
  ExchangePlan: {
    frontendURL: '/srv/exchangePlan/',
    backendURL: '/api/cityplans/importdbf/',
    name: 'Завантажити виписку планів',
  },
  ExchangeIncome: {
    frontendURL: '/srv/exchangeIncome/',
    backendURL: '/api/cityplans/revenuedbf/',
    name: 'Завантажити фактичні доходи',
  },
  ExchangeExpenses: {
    frontendURL: '/srv/exchangeExpenses/',
    backendURL: '/api/cityplans/treasurydbf/',
    name: 'Завантажити фактичні видатки',
  },
  ExchangeSocialPayment: {
    frontendURL: '/srv/exchangeSocialPayment/',
    backendURL: '/api/references/socialpayment/importdbf/',
    name: 'Завантажити виписку соціальних виплат',
  },
};

export default Exchange;
