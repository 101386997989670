import React, {
  useEffect, useMemo, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Overlay, Badge, Button,
} from 'react-bootstrap';
import { metaBackends } from '../../../meta';

const StyledDDMenu = styled.div`
  opacity: 1 !important;
  max-height: 30vh;
  max-width: 30vw;
  overflow: hidden auto;
  z-index: 1050;
`;

function ContextMenu({
  onCloseMenu, detail, onDetailProcessing, allowedDetails, opened, target,
}) {
  const menuRef = useRef(null);
  useEffect(
    () => {
      const clicker = (e) => {
        if (!e.composedPath().includes(menuRef.current)) {
          onCloseMenu(e);
        }
      };
      document.addEventListener('click', clicker);

      return function cleanup() {
        document.removeEventListener('click', clicker);
      };
    },
  );
  const to = useMemo(
    () => {
      if (!detail || !detail.currentItem.backend || !(detail.currentItem.backend in metaBackends)) {
        return null;
      }
      return `${metaBackends[detail.currentItem.backend].frontendURL}${detail.currentItem.id}`;
    },
    [detail],
  );
  return (
    <Overlay target={target} show={opened}>
      {({
        placement, arrowProps, show: _show, popper, ...props
      }) => (
        <StyledDDMenu
          className="bg-white border rounded"
          {...props}
        >
          <h5 className="text-primary text-center">Що зробити?</h5>
          <ul className="list-unstyled d-flex flex-column">
            {to && (
            <li className="border-bottom">
              <Button
                variant="outline-info"
                as={Link}
                to={to}
                onClick={onCloseMenu}
                className="w-100 text-nowrap text-truncate"
              >
                {`Відкрити ${detail.currentItem.repr}`}
              </Button>
            </li>
            )}
            {onDetailProcessing && allowedDetails.map((c) => (
              <li
                key={c.key}
              >
                <Button
                  variant="light"
                  onClick={(e) => onDetailProcessing(e, c)}
                  className="w-100 text-nowrap text-truncate"
                >
                  Розшифрувати по
                  <Badge variant="info" className="ms-1">{c.label}</Badge>
                </Button>
              </li>
            ))}
          </ul>
        </StyledDDMenu>
      )}
    </Overlay>
  );
}

ContextMenu.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  detail: PropTypes.shape({
    currentItem: PropTypes.shape({
      backend: PropTypes.string,
      id: PropTypes.number,
      repr: PropTypes.string,
    }).isRequired,
  }),
  onDetailProcessing: PropTypes.func,
  allowedDetails: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  opened: PropTypes.bool,
  target: PropTypes.shape({}),
};

ContextMenu.defaultProps = {
  onDetailProcessing: null,
  opened: false,
  target: null,
  detail: null,
};

export default ContextMenu;
