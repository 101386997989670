/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor } from '../../../../../../components/Form/styledForm';
import { emptyUid } from '../../../../../../constants/meta/common';

const tableMeta = md.tables.extraIndicators;

function ExtraIndicatorsTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const [selectedTask, setselectedTask] = useState(emptyUid);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });
  const tasksTP = data[md.tables.tasks.name] || [];
  const indicatorsTasks = [{
    display_name: 'Вимкнено відбір по напрямам',
    value: emptyUid,
  }];
  tasksTP.reduce(
    (el, o) => (o[md.tables.tasks.columns.tasks.name] ? (el.set(o[md.tables.tasks.columns.tasks.name].id, o[md.tables.tasks.columns.tasks.name])) : []),
    new Map(),
  )
    .forEach((c) => indicatorsTasks.push(
      {
        display_name: (c.repr === '') ? 'Не заповнено' : c.repr,
        value: (c.id === '') ? emptyUid : c,
      },
    ));
  const taskId = selectedTask === emptyUid ? selectedTask : selectedTask.id;

  return (
    <>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label="Відібрати показники за:"
            value={selectedTask}
            onChange={(e, value) => setselectedTask(value)}
            values={indicatorsTasks}
            readOnly={readOnly}
          />
        </Col>
        <Col style={{
          justifySelf: 'center',
          alignSelf: 'center',
        }}
        >
          <CommandPanelColor
            label={selectedTask === emptyUid ? ('Заповнити показники') : ('Заповнити показники по напряму')}
            onClick={() => actions.onSR('FILL_ADD_INDICATORS_BY_TASK', { taskId })}
          />
        </Col>
      </Row>
      <Card className="border-0">
        <TableHeader pinHeader={pinHeader}>
          <TPCommandPanel
            tableActions={tableActions}
            readOnly={readOnly}
            activeRow={activeRow}
            pinHeader={pinHeader}
            showFooter={showFooter}
          />
          <TPHeader
            activeCol={activeCol}
            highlights={highlights}
            onToggleHighlght={tableActions.onToggleHighlightColumn}
            year={data[md.columns.year.name]}
          />
        </TableHeader>
        <Card.Body className="p-0" ref={tableContainerRef}>
          {tableData.map((row, index) => (
            <TPRow
              key={index}
              row={row}
              rowIndex={index}
              active={activeRow === index}
              activeCol={activeRow === index ? activeCol : null}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              onSR={actions.onSR}
              selectedTask={selectedTask}
            />
          ))}
          <Placeholder onFocus={() => tableActions.onAddRow()} />
        </Card.Body>
      </Card>
    </>
  );
}

ExtraIndicatorsTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

ExtraIndicatorsTP.defaultProps = {
  readOnly: false,
};

export default memo(ExtraIndicatorsTP);
