import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R18G3: { label: 'R01G3', name: 'R94G3' },
  R18G4: { label: 'R01G3', name: 'R94G4' },
  R18G5: { label: 'R01G3', name: 'R94G5' },
  R18G6: { label: 'R01G3', name: 'R94G6' },
  R18G7: { label: 'R01G3', name: 'R94G7' },
  R29G3: { label: 'R01G3', name: 'R95G3' },
  R29G4: { label: 'R01G3', name: 'R95G4' },
  R29G5: { label: 'R01G3', name: 'R95G5' },
  R29G6: { label: 'R01G3', name: 'R95G6' },
  R29G7: { label: 'R01G3', name: 'R95G7' },
  R30G3: { label: 'R01G3', name: 'R96G3' },
  R30G4: { label: 'R01G3', name: 'R96G4' },
  R30G5: { label: 'R01G3', name: 'R96G5' },
  R30G6: { label: 'R01G3', name: 'R96G6' },
  R30G7: { label: 'R01G3', name: 'R96G7' },
  R28G3: { label: 'R01G3', name: 'R97G3' },
  R28G4: { label: 'R01G3', name: 'R97G4' },
  R28G5: { label: 'R01G3', name: 'R97G5' },
  R28G6: { label: 'R01G3', name: 'R97G6' },
  R28G7: { label: 'R01G3', name: 'R97G7' },
  R80G3: { label: 'R01G3', name: 'R98G3' },
  R80G4: { label: 'R01G3', name: 'R98G4' },
  R80G5: { label: 'R01G3', name: 'R98G5' },
  R80G6: { label: 'R01G3', name: 'R98G6' },
  R80G7: { label: 'R01G3', name: 'R98G7' },
  R81G3: { label: 'R01G3', name: 'R100G3' },
  R81G4: { label: 'R01G3', name: 'R100G4' },
  R81G5: { label: 'R01G3', name: 'R100G5' },
  R81G6: { label: 'R01G3', name: 'R100G6' },
  R81G7: { label: 'R01G3', name: 'R100G7' },
  R82G3: { label: 'R01G3', name: 'R101G3' },
  R82G4: { label: 'R01G3', name: 'R101G4' },
  R82G5: { label: 'R01G3', name: 'R101G5' },
  R82G6: { label: 'R01G3', name: 'R101G6' },
  R82G7: { label: 'R01G3', name: 'R101G7' },
  R102G3: { label: 'R01G3', name: 'R102G3' },
  R102G4: { label: 'R01G3', name: 'R102G4' },
  R102G5: { label: 'R01G3', name: 'R102G5' },
  R102G6: { label: 'R01G3', name: 'R102G6' },
  R102G7: { label: 'R01G3', name: 'R102G7' },
  R103G3: { label: 'R01G3', name: 'R103G3' },
  R103G4: { label: 'R01G3', name: 'R103G4' },
  R103G5: { label: 'R01G3', name: 'R103G5' },
  R103G6: { label: 'R01G3', name: 'R103G6' },
  R103G7: { label: 'R01G3', name: 'R103G7' },
  R104G3: { label: 'R01G3', name: 'R104G3' },
  R104G4: { label: 'R01G3', name: 'R104G4' },
  R104G5: { label: 'R01G3', name: 'R104G5' },
  R104G6: { label: 'R01G3', name: 'R104G6' },
  R104G7: { label: 'R01G3', name: 'R104G7' },
  R105G3: { label: 'R01G3', name: 'R105G3' },
  R105G4: { label: 'R01G3', name: 'R105G4' },
  R105G5: { label: 'R01G3', name: 'R105G5' },
  R105G6: { label: 'R01G3', name: 'R105G6' },
  R105G7: { label: 'R01G3', name: 'R105G7' },
  R99G3: { label: 'R01G3', name: 'R99G3' },
  R99G4: { label: 'R01G3', name: 'R99G4' },
  R99G5: { label: 'R01G3', name: 'R99G5' },
  R99G6: { label: 'R01G3', name: 'R99G6' },
  R99G7: { label: 'R01G3', name: 'R99G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd5Printing',
  backendName: 'ПечатьПрогнозДод5',
  label: 'Показники гарантованого Автономною Республікою Крим, обласною радою чи територіальною громадою міста боргу і надання місцевих гарантій (Додаток 5)',
  frontend: 'dp/forecastAdd5Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
