import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  Tabl71TP, Tabl72TP, Tabl73TP,
} from './tabs';

function Taxes5Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabl71TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
        <Col>
          <Tabl72TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
        <Col>
          <Tabl73TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );
}

Taxes5Tabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default Taxes5Tabs;
