import React from 'react';

function ClipIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 490 490"
      xmlSpace="preserve"
      {...props}
    >
      <g id="XMLID_817_">
        <g>
          <path
            d="M329.219,91.875c-4.228,0-7.656,3.428-7.656,7.656v298.595c0,42.216-34.345,76.562-76.562,76.562
			s-76.562-34.346-76.562-76.562V68.907c0-29.552,24.042-53.594,53.593-53.594s53.593,24.042,53.593,53.594v283.28
			c0,16.887-13.738,30.625-30.625,30.625c-16.887,0-30.625-13.739-30.625-30.625V99.531c0-4.228-3.427-7.656-7.656-7.656
			c-4.228,0-7.656,3.428-7.656,7.656v252.657c0,25.33,20.607,45.937,45.937,45.937c25.33,0,45.937-20.607,45.937-45.937V68.907
			C290.937,30.912,260.026,0,222.031,0s-68.906,30.912-68.906,68.907v329.218C153.125,448.785,194.34,490,245,490
			c50.66,0,91.875-41.215,91.875-91.875V99.531C336.875,95.303,333.448,91.875,329.219,91.875z"
            fill="#4281C9"
            stroke="#4281C9"
            strokeWidth="12"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export default ClipIcon;
