import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { tarif as md } from '../../../../constants/meta/catalogs/categoriesFOPVersions';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function CategoriesFOPVersions({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.nVersion.label}
            value={data[md.columns.nVersion.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.nVersion.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.planFact.label}
            value={data[md.columns.planFact.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planFact.name]: value,
            })}
            maxLength={2}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.orderName.label}
            value={data[md.columns.orderName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.orderName.name]: value,
            })}
            maxLength={50}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.isAppr.label}
            value={data[md.columns.isAppr.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isAppr.name]: value,
            })}
            maxLength={5}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

CategoriesFOPVersions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
    [md.columns.nVersion.name]: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CategoriesFOPVersions;
