import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.generalTPInd;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'indicators'}
            highlighted={highlights.includes('indicators')}
            onToggleHighlght={() => onToggleHighlght('indicators')}
          >
            {meta.columns.indicators.label}
            <div className="w-100 overflow-hidden">
              <Badge variant="primary">{meta.columns.tasks.label}</Badge>
              <Badge variant="info">{meta.columns.typeIndicators.label}</Badge>
              <Badge variant="success">{meta.columns.un.label}</Badge>
            </div>
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumTotal'}
              highlighted={highlights.includes('sumTotal')}
              onToggleHighlght={() => onToggleHighlght('sumTotal')}
            >
              {meta.columns.sumTotal.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF'}
              highlighted={highlights.includes('sumGenF')}
              onToggleHighlght={() => onToggleHighlght('sumGenF')}
            >
              {meta.columns.sumGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF'}
              highlighted={highlights.includes('sumSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF')}
            >
              {meta.columns.sumSpecF.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'note'}
          highlighted={highlights.includes('note')}
          onToggleHighlght={() => onToggleHighlght('note')}
        >
          {meta.columns.note.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
