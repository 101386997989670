import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ListerContext from '../../../context';
import api from '../../../../../api/req';
import { downloadFileFromJSON } from '../../../../../api/utils';
import { useMD } from '../../../hooks/md';

function ExportJSONButton({ className, modelType, modelName }) {
  const md = useMD(modelType, modelName);
  const {
    selectedRows,
  } = useContext(ListerContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const onLoad = useCallback(
    () => {
      const loader = async (id) => {
        const r = await api.post(`doc/${md.backendName}/`, { method: 'ExportJSON', id });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader(selectedRows[0])
        .then((d) => downloadFileFromJSON(d.content, d.filename))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [md.backendName, selectedRows],
  );
  useEffect(
    () => {
      if (err) console.log(err);
    },
    [err],
  );
  return (
    <Button
      title="Експорт в JSON"
      disabled={!selectedRows.length}
      onClick={onLoad}
      variant={err ? 'danger' : 'success'}
      className={className}
    >
      <FontAwesomeIcon icon={faDownload} spin={loading} />
    </Button>

  );
}

ExportJSONButton.propTypes = {
  className: PropTypes.string,
  modelType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
};

ExportJSONButton.defaultProps = {
  className: '',
};

export default ExportJSONButton;
