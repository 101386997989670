import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { ItemContainer, ItemText } from '../StyledComponents';
import { MinusIcon } from '../../../../assets/icons';
import Checkbox from '../../../../components/styledCheckbox/styledCheckbox';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import ContextMenuHOC from '../../../../components/contextMenu';

const active = {
  background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21',
};

const StyledItemContainer = styled(ItemContainer).attrs((props) => ({
  style: {
    ...(props.active ? active : {}),
    paddingLeft: `${props.level}em`,
  },
}))`
  & > :first-child{
    padding-right: 5px;
  }
`;

function StructureItem({
  id,
  item,
  availableFields,
  toggleUseHandler,
  onClickHandler,
  onDoubleClickHandler,
  level,
  iRef,
  dragHandleProps,
  draggableProps,

}) {
  const itemTitle = useMemo(
    () => {
      const getHierarchyModeName = (mode) => {
        switch (mode) {
          case DC.groupTypes.Hierarchy:
            return ' (Ієрархія)';
          case DC.groupTypes.HierarchyOnly:
            return ' (Тількі ієрархія)';
          default:
            return '';
        }
      };

      if (!item.get('GroupFields', new Map()).size) {
        return 'Детальні записи';
      }
      return item.get('GroupFields', new Map()).reduce(
        (R, Item) => {
          const path = Item.get('Field', '').split('.');
          const newPath = path
          // eslint-disable-next-line
                .reduce((R2, p, i) => R2.length ? [...R2, 'Items', `${path.slice(0, i).join('.')}.${p}`] : [p], []);
          const ct = availableFields.getIn([...newPath, 'Tittle'], Item.get('Field', ''));
          const cm = getHierarchyModeName(Item.get('GroupType', ''));
          return (`${R}${R ? ', ' : ''}${ct}${cm}`);
        },
        '',
      );
    },
    [availableFields, item],
  );
  return (
    <StyledItemContainer
      ref={iRef}
      level={level}
      active={item.get('_SELECTED', false)}
      onClick={(e) => onClickHandler(e, id)}
      onDoubleClick={(e) => onDoubleClickHandler(e, id)}
      {...dragHandleProps}
      {...draggableProps}
    >
      <MinusIcon />
      <Checkbox
        value={item.get('Use', false)}
        onChange={(e) => toggleUseHandler(e, id)}
      />
      <ItemText>
        {itemTitle}
      </ItemText>
    </StyledItemContainer>
  );
}
StructureItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  availableFields: PropTypes.instanceOf(Map),
  toggleUseHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
  onDoubleClickHandler: PropTypes.func,
  level: PropTypes.number,
  iRef: PropTypes.func,
  dragHandleProps: PropTypes.shape({}),
  draggableProps: PropTypes.shape({}),
};

StructureItem.defaultProps = {
  availableFields: new Map(),
  toggleUseHandler: () => null,
  onClickHandler: () => null,
  onDoubleClickHandler: () => null,
  level: 0,
  iRef: () => null,
  dragHandleProps: {},
  draggableProps: {},
};

export default ContextMenuHOC(StructureItem);
