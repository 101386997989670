import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  kdbClassifier: { label: 'КДБ', name: 'КДБ', visible: true },
  boiler: { label: 'Ознака котлового рахунку', name: 'тКотловой', visible: true },
  fondClassifier: { label: 'Фонд', name: 'Фонд', visible: true },
  notShow: { label: 'Не показувати у звітах', name: 'НеПоказывать', visible: true },
  sort: { label: 'Код для сортування', name: 'Сорт', visible: true },
};

export const treasuryStatement = {
  label: 'Рахунки для казначейської банківської виписки',
  name: 'treasuryStatement',
  backendName: 'КазнВыписка',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/treasuryStatement',
  listColumns: [...(new Set([...reqListerColumns, 'kdbClassifier', 'boiler', 'fondClassifier', 'notShow', 'sort']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default treasuryStatement;
