const frontendURL = '/doc/docRegister/';
const backendURL = '/api/obligations/documentregistryheader/';
const name = 'Реєстр документів';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
