const frontendURL = '/doc/disposersMap/';
const backendURL = '/api/merezha/disposers_map/';
const name = 'Мережа розпорядників';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
