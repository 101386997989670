import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';

function UserShow({ user }) {
  if (!user) return null;
  if (user.s_avatar) {
    return (
      <OverlayTrigger
        delay={400}
        placement="auto"
        overlay={(
          <Tooltip>
            <img
              className="rounded"
              src={`${process.env.REACT_APP_API_SERVER_ADDR}${user.m_avatar}`}
              alt={user.repr}
            />
            <div>
              {user.repr}
            </div>
          </Tooltip>
              )}
      >
        {({ ref, ...triggerHandler }) => (
          <div className="avatar avatar-s" {...triggerHandler}>
            <img
              ref={ref}
              src={`${process.env.REACT_APP_API_SERVER_ADDR}${user.s_avatar}`}
              alt={user.repr}
              title={user.repr}
              className="rounded-circle h-100"
            />
          </div>
        )}
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger
      delay={400}
      placement="auto"
      overlay={(
        <Tooltip>
          {user.repr}
        </Tooltip>
              )}
    >
      {({ ref, ...triggerHandler }) => (
        <FontAwesomeIcon icon={faUser} ref={ref} {...triggerHandler} />
      )}
    </OverlayTrigger>
  );
}

UserShow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
    s_avatar: PropTypes.string,
    m_avatar: PropTypes.string,
  }),
};

UserShow.defaultProps = {
  user: null,
};
export default UserShow;
