import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівник', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  addNum: { label: 'Додаток номер', name: 'НомерФормы' },
  dateO: { label: 'Дата актуальних даних ("станом на" для коштів від перевиконання)', name: 'ДатаОстатков' },
  in1000: { label: 'в тис.грн.', name: 'тисГрн' },
  Year: { label: 'Рік', name: 'Год' },
  addLogica: { label: 'Вкажіть додаток, який потрібно надіслати до Логіка', name: 'ДодатокДляОтправки' },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденОбластью' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол2', name: 'Кол2' },
      col3: { label: 'Кол3', name: 'Кол3' },
      col4: { label: 'Кол4', name: 'Кол4' },
      col5: { label: 'Кол5', name: 'Кол5' },
      col6: { label: 'Кол6', name: 'Кол6' },
      col7: { label: 'Кол7', name: 'Кол7' },
      col8: { label: 'Кол8', name: 'Кол8' },
      col9: { label: 'Кол9', name: 'Кол9' },
      col10: { label: 'Кол10', name: 'Кол10' },
      col11: { label: 'Кол11', name: 'Кол11' },
      col12: { label: 'Кол12', name: 'Кол12' },
      col14: { label: 'Кол14', name: 'Кол14' },
      col15: { label: 'Кол15', name: 'Кол15' },
      col16: { label: 'Кол16', name: 'Кол16' },
      col17: { label: 'Кол17', name: 'Кол17' },
      col18: { label: 'Кол18', name: 'Кол18' },
      col19: { label: 'Кол13', name: 'Кол19' },
      col20: { label: 'Кол20', name: 'Кол20' },
      col21: { label: 'Кол21', name: 'Кол21' },
      col22: { label: 'Кол22', name: 'Кол22' },
      col23: { label: 'Кол23', name: 'Кол23' },
      col24: { label: 'Кол24', name: 'Кол24' },
      col25: { label: 'Кол25', name: 'Кол25' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'freeRemPrint',
  backendName: 'ПечатьСвободныеОстатки',
  label: 'Друк інформації по вільних залишках',
  frontend: 'dp/freeRemPrint',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
