import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  kfb: foreignPropType,
  person_budget: foreignPropType,
  zag_fond: PropTypes.number,
  spec_fond: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
