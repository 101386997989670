import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import YouTube from 'react-youtube';
import Dropdown from 'react-bootstrap/Dropdown';
import { CPButton } from './styles';

const opts = {
  height: '390',
  width: '465',
  playerVars: {
    autoplay: 1,
  },
};

export function YouTubeButton({ urls }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (urls && urls.length) setSelectedVideo(urls[0].id);
  }, [urls]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  return (
    <>
      <CPButton
        title="Відтворити відео"
        onClick={handleShow}
        disabled={!urls || !urls.length}
        content="Навчальне відео"
        icon={faYoutube}
        variant="falcon-info"
      />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Відео</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {urls && urls.length > 0 ? (
            <>
              {urls.length > 1 && (
                <Dropdown>
                  <Dropdown.Toggle variant="falcon-info">
                    {selectedVideo ? selectedVideo.label : 'Виберіть відео'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {urls.map((url) => (
                      <Dropdown.Item
                        key={url.id}
                        onClick={() => handleVideoSelect(url.id)}
                      >
                        {url.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <YouTube videoId={selectedVideo} opts={opts} />
            </>
          ) : (
            'Немає відео'
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрити
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

YouTubeButton.propTypes = {
  urls: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

YouTubeButton.defaultProps = {
  urls: null,
};

export default YouTubeButton;
