import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/common';
import { typeIndicatorsTransation } from '../../../../../../constants/meta/enums/typeIndicators';
import { emptyUid } from '../../../../../../constants/meta/common';

const tablename = 'indicators';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, selectedTask,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const IndicatorOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: selectedTask,
    }],
    [selectedTask],
  );
  const showRow = (
    row[tableMD.columns.tasks.name] === undefined
      || (row[tableMD.columns.tasks.name]
      && (row[tableMD.columns.tasks.name].id === selectedTask.id
      || selectedTask === emptyUid)));

  return (
    <>
      {showRow && (
        <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
          <Col sm={5}>
            <TableRowCell column="indicator" highlighted={highlights.includes('indicator')}>
              <ItemPicker
                value={row[tableMD.columns.indicator.name]}
                modelType="cat"
                modelName="indicators"
                onChange={async (e, v) => {
                  await onRowChange(
                    e,
                    { [tableMD.columns.indicator.name]: v },
                  );
                  await onSR('CHANGE_INDICATORS_IN_TABLE', { rowId: rowIndex });
                }}
                filter={selectedTask === emptyUid ? [] : IndicatorOwnerFilter}
                readOnly={readOnly}
              />
              <div className="w-100 overflow-hidden">
                <Badge variant="primary">{row[tableMD.columns.tasks.name] && row[tableMD.columns.tasks.name].repr}</Badge>
                <Badge variant="info">{typeIndicatorsTransation[row[tableMD.columns.type.name]]}</Badge>
                <Badge variant="success">{row[tableMD.columns.units.name] && row[tableMD.columns.units.name].repr}</Badge>
              </div>
            </TableRowCell>
          </Col>

          <Col className="px-1 border-right">
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum_2" highlighted={highlights.includes('sum_2')}>
                  <NumberInput
                    value={row[tableMD.columns.sum_2.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sum_2.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="zf_2" highlighted={highlights.includes('zf_2')}>
                  <NumberInput
                    value={row[tableMD.columns.zf_2.name]}
                    inputClassName="text-primary"
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.zf_2.name]: value,
                      [tableMD.columns.sum_2.name]: value
                  + (row[tableMD.columns.sf_2.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
                  <NumberInput
                    value={row[tableMD.columns.sf_2.name]}
                    precision={2}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf_2.name]: value,
                      [tableMD.columns.sum_2.name]: value
                      + (row[tableMD.columns.zf_2.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col className="px-1 border-right">
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum_1" highlighted={highlights.includes('sum_1')}>
                  <NumberInput
                    value={row[tableMD.columns.sum_1.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sum_1.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="zf_1" highlighted={highlights.includes('zf_1')}>
                  <NumberInput
                    value={row[tableMD.columns.zf_1.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.zf_1.name]: value,
                      [tableMD.columns.sum_1.name]: value
                  + (row[tableMD.columns.sf_1.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
                  <NumberInput
                    value={row[tableMD.columns.sf_1.name]}
                    precision={2}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf_1.name]: value,
                      [tableMD.columns.sum_1.name]: value
                      + (row[tableMD.columns.zf_1.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col className="px-1 border-right">
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
                  <NumberInput
                    value={row[tableMD.columns.sum0.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sum0.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
                  <NumberInput
                    value={row[tableMD.columns.zf0.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.zf0.name]: value,
                      [tableMD.columns.sum0.name]: value
                  + (row[tableMD.columns.sf0.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
                  <NumberInput
                    value={row[tableMD.columns.sf0.name]}
                    precision={2}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf0.name]: value,
                      [tableMD.columns.sum0.name]: value
                      + (row[tableMD.columns.zf0.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col className="px-1 border-right">
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
                  <NumberInput
                    value={row[tableMD.columns.sum1.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sum1.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
                  <NumberInput
                    value={row[tableMD.columns.zf1.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.zf1.name]: value,
                      [tableMD.columns.sum1.name]: value
                  + (row[tableMD.columns.sf1.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
                  <NumberInput
                    value={row[tableMD.columns.sf1.name]}
                    precision={2}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf1.name]: value,
                      [tableMD.columns.sum1.name]: value
                      + (row[tableMD.columns.zf1.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
                  <NumberInput
                    value={row[tableMD.columns.sum2.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sum2.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
                  <NumberInput
                    value={row[tableMD.columns.zf2.name]}
                    precision={2}
                    inputClassName="text-primary"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.zf2.name]: value,
                      [tableMD.columns.sum2.name]: value
                  + (row[tableMD.columns.sf2.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12}>
                <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
                  <NumberInput
                    value={row[tableMD.columns.sf2.name]}
                    precision={2}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sf2.name]: value,
                      [tableMD.columns.sum2.name]: value
                      + (row[tableMD.columns.zf2.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </TableRow>
      )}
    </>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  selectedTask: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
