import React from 'react';

function AddFolderIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.5 13.5V15.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 14.5H15.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 11.5C12.8 11.5 11.5 12.8 11.5 14.5C11.5 14.9 11.6 15.2 11.7 15.5H3.5L2.5 6.5H8L9 8.5H14.5L15.2493 11.6248C14.9995 11.5499 14.7498 11.5 14.5 11.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 12.3V5.5H11L10 3.5H4.5V6.5H8" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C12.8431 11.5 11.5 12.8431 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default AddFolderIcon;
