import styled from 'styled-components';
import { Modal, ListGroup, Dropdown } from 'react-bootstrap';
import { StyledButton } from '../../../components/Styled/Buttons/styles';

export const ModalButton = styled(StyledButton)`
    background-color: var(--falcon-btn-falcon-background) !important;
    color: var(--falcon-btn-falcon-primary-color) !important;
    :hover {
        background-color: var(--falcon-btn-falcon-primary-active-background) !important;
    };
`;

export const SModal = styled(Modal)`
    padding-right: 0 !important;
    >div {
    margin: 0 !important;
    
    @media (min-width: 320px) and (max-width: 100vw) {
        max-width: 100vw !important;   
    }
    
        >div {
        width: 100vw !important;
        height: 100vh !important;
        border: none !important;
        border-radius: 0 !important;
        
         @media (min-width: 320px) and (max-width: 100vw) {
            max-height: 100vh !important; 
    }
    
        }
    }
`;
export const HeaderText = styled(Modal.Title)`
  color: var(--falcon-card-cap-color);
  font-weight: 700 !important;
`;

export const FooterText = styled.span`
    color: var(--falcon-card-cap-color);
    font-weight: 700;
`;

export const FooterLabel = styled.span`
    background-color: var(--falcon-card-cap-bg);
    color: var(--falcon-card-cap-color);
    border-radius: 4px;
    padding: 2px 3px;
`;

export const HeaderTable = styled.th`
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: var(--falcon-card-cap-bg);
    color: var(--falcon-card-cap-color);
`;

export const ContainerTable = styled.div`
    max-height: 63vh;
    overflow: auto;
`;

export const StyleDiv = styled.div`
    line-height: 1.25;
    overflow: hidden;
    padding: 5px;
    word-break: break-all;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
export const StyleTD = styled.td`
    padding: 0px !important;
    background: white;
`;
export const ContainerDiv = styled.div`
    padding: 5px;
    border: 1px solid #ced4da;
    background: #f7f7f7;
`;
export const HeaderItem = styled(ListGroup.Item)`
    position: sticky;
    z-index: 5;
    top: 0;
    background: red;
    background-color: var(--falcon-card-cap-bg)!important;
    color: var(--falcon-card-cap-color)!important;
    font-weight: 700;
`;

export const ModalDropdownToggle = styled(Dropdown.Toggle)`
    background-color: var(--falcon-btn-falcon-background) !important;
    color: var(--falcon-btn-falcon-primary-color) !important;
    :hover {
        background-color: var(--falcon-btn-falcon-primary-active-background) !important;
    };
`;
