import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  activeCol, highlights, onToggleHighlight, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kfb'}
              highlighted={highlights.includes('kfb')}
              onToggleHighlight={() => onToggleHighlight('kfb')}
            >
              КФБ
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.gloans_zf}
              className="text-center text-xl-left"
              active={activeCol === 'gloans_zf'}
              highlighted={highlights.includes('gloans_zf')}
              onToggleHighlight={() => onToggleHighlight('gloans_zf')}
            >
              Заг. фонд
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.gloans_sf}
              className="text-center text-xl-left"
              active={activeCol === 'gloans_sf'}
              highlighted={highlights.includes('gloans_sf')}
              onToggleHighlight={() => onToggleHighlight('gloans_sf')}
            >
              Спец. фонд
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.gloans_db}
              className="text-center text-xl-left"
              active={activeCol === 'gloans_db'}
              highlighted={highlights.includes('gloans_db')}
              onToggleHighlight={() => onToggleHighlight('gloans_db')}
            >
              В т.ч.БР
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.gloans_total}
              className="text-center text-xl-left"
              active={activeCol === 'gloans_total'}
              highlighted={highlights.includes('gloans_total')}
              onToggleHighlight={() => onToggleHighlight('gloans_total')}
            >
              Загальна сума
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    gloans_db: PropTypes.number,
    gloans_zf: PropTypes.number,
    gloans_sf: PropTypes.number,
    gloans_total: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
