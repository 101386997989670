import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
};

const tables = {
};

/**
 * @const
 */
const definition = {
  name: 'printing',
  backendName: 'ПечатьБМ02',
  label: 'Друк',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
