import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TabelRowCell({
  column, highlighted, children, className,
}) {
  return (
    <div
      data-col={column}
      className={classNames('d-flex py-0 px-0 position-relative', className, { 'bg-warning': highlighted, 'text-white': highlighted })}
    >
      {children}
    </div>
  );
}

TabelRowCell.propTypes = {
  column: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
};

TabelRowCell.defaultProps = {
  highlighted: false,
  className: '',
};

export default TabelRowCell;
