import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/investmentProposalObjectsChanges';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'objectsBuilding'}
              highlighted={highlights.includes('objectsBuilding')}
              onToggleHighlght={() => onToggleHighlght('objectsBuilding')}
            >
              {meta.columns.objBuilding.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'beginningDate'}
              highlighted={highlights.includes('beginningDate')}
              onToggleHighlght={() => onToggleHighlght('beginningDate')}
            >
              {meta.columns.beginningDate.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'endingDate'}
              highlighted={highlights.includes('endingDate')}
              onToggleHighlght={() => onToggleHighlght('endingDate')}
            >
              {meta.columns.endingDate.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finSource'}
              highlighted={highlights.includes('finSource')}
              onToggleHighlght={() => onToggleHighlght('finSource')}
            >
              {meta.columns.finSource.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'estimateSum'}
          highlighted={highlights.includes('estimateSum')}
          onToggleHighlght={() => onToggleHighlght('estimateSum')}
        >
          {meta.columns.estimateSum.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Очікуване виконання на початок наступного року:
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'totalSum'}
              highlighted={highlights.includes('totalSum')}
              onToggleHighlght={() => onToggleHighlght('totalSum')}
            >
              {meta.columns.totalSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yearSum'}
              highlighted={highlights.includes('yearSum')}
              onToggleHighlght={() => onToggleHighlght('yearSum')}
            >
              {meta.columns.yearSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearSum'}
              highlighted={highlights.includes('nextYearSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearSum')}
            >
              {meta.columns.nextYearSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearCriticalSum'}
              highlighted={highlights.includes('nextYearCriticalSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearCriticalSum')}
            >
              {meta.columns.nextYearCriticalSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearAddSum'}
              highlighted={highlights.includes('nextYearAddSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearAddSum')}
            >
              {meta.columns.nextYearAddSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Прогноз:
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'predictionSum1'}
              highlighted={highlights.includes('predictionSum1')}
              onToggleHighlght={() => onToggleHighlght('predictionSum1')}
            >
              на
              {year + 1}
              {' '}
              р.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'predictionSum2'}
              highlighted={highlights.includes('predictionSum2')}
              onToggleHighlght={() => onToggleHighlght('predictionSum2')}
            >
              на
              {year + 2}
              {' '}
              р.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deadlines'}
              highlighted={highlights.includes('deadlines')}
              onToggleHighlght={() => onToggleHighlght('deadlines')}
            >
              {meta.columns.deadlines.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'designer'}
              highlighted={highlights.includes('designer')}
              onToggleHighlght={() => onToggleHighlght('designer')}
            >
              {meta.columns.designer.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'contractor'}
              highlighted={highlights.includes('contractor')}
              onToggleHighlght={() => onToggleHighlght('contractor')}
            >
              {meta.columns.contractor.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'note'}
          highlighted={highlights.includes('note')}
          onToggleHighlght={() => onToggleHighlght('note')}
        >
          {meta.columns.note.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
