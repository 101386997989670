import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DocSelectionBoostraped from '../../../dataProcessors/docSelectionBoostrapedForecast';
import { referencePropType } from '../../../newEditor/propTypes';

function DocSelectionModal({
  onOk, period, readOnly, FI, KBP, version, nDoc, dDoc,
}) {
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const okHandler = useCallback(
    (data) => {
      setOpened(false);
      onOk(data);
    },
    [onOk],
  );

  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={readOnly} ref={ref}>
        <FontAwesomeIcon icon={faFileImport} className="me-2" />
        Заповнити з даних розпорядників нижчого рівня
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть документи для завантаження
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <DocSelectionBoostraped
            period={period}
            dataSource="Документ.МережаПоказателиПлан"
            onLoad={okHandler}
            FI={FI}
            KBP={KBP}
            version={version}
            nDoc={nDoc}
            dDoc={dDoc}
          />
        </Modal.Body>

      </Modal>
    </>
  );
}

DocSelectionModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  FI: referencePropType,
  KBP: referencePropType,
  version: referencePropType,
  period: PropTypes.number,
  readOnly: PropTypes.bool,
  dDoc: PropTypes.number,
  nDoc: PropTypes.string,
};

DocSelectionModal.defaultProps = {
  period: null,
  FI: null,
  KBP: null,
  version: null,
  readOnly: false,
  dDoc: null,
  nDoc: null,
};

export default DocSelectionModal;
