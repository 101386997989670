import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/limitReference';

const meta = md.tables.CSU;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          {/* <Col> */}
          {/*  <TableHeaderCell */}
          {/*    className="text-center text-xl-left" */}
          {/*    active={activeCol === 'check'} */}
          {/*    highlighted={highlights.includes('check')} */}
          {/*    onToggleHighlght={() => onToggleHighlght('check')} */}
          {/*  > */}
          {/*    {meta.columns.check.label} */}
          {/*  </TableHeaderCell> */}
          {/* </Col> */}
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'csu'}
              highlighted={highlights.includes('csu')}
              onToggleHighlght={() => onToggleHighlght('csu')}
            >
              {meta.columns.csu.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
