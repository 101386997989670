import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 *@const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  CodeKFB: { label: 'Код КФБ', name: 'КодКФБ', visible: true },
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  owner: {
    label: 'Фінансовий орган',
    name: 'Владелец',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  Mark: { label: 'Знак', name: 'Знак', visible: false },
  Steward: {
    label: 'Розпорядник',
    name: 'Распорядитель',
    visible: false,
  },
  DateCl: { label: 'станом на', name: 'ДатаКл', visible: false },
  Classifier: { label: 'КФБ', name: 'Классификатор', visible: true },
  ElemOfFinInstitution: {
    label: 'Елемент фін органу',
    name: 'ЭлементФинОргана',
    visible: false,
  },
  DateEl: { label: 'станом на', name: 'ДатаЭл', visible: true },
  parent: { label: 'Папка', name: '_Родитель', visible: false },

  flAutoFillName: {
    label: 'Автоматичне заповнення найменування ',
    name: 'флАтоматЗаполненияНаим',
    visible: false,
  },
  flAutoFillFullName: {
    label: 'Автоматичне заповнення повного найменування ',
    name: 'флАтоматЗаполненияПолнНаим',
    visible: false,
  },
};

/**
 * @const
 */
export const elementKFB = {
  label: 'Деталізація КФБ',
  name: 'elementKFB',
  backendName: 'ЭлементыКФБ',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/elementKFB',
  listColumns: [...new Set([
    'CodeKFB',
    ...reqListerColumns,
    'Classifier',
    'Steward',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default elementKFB;
