import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';

import {
  StyledLabel,
  MyContainerFormWithNColumns, CommandPanelColor, FrameDiv,
} from '../../../components/Form/styledForm';

import { emptyUid } from '../../../constants/meta/common';

import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import DateField from '../../../components/fields/dates';
import { InputWithDropdown } from '../../../components/styledInputs';
import enums from '../../../constants/meta/enums';
import Loading from '../../../components/styledLoading';
import DefaultCPButton from '../../../components/bootstrap_components/controls/defaultButton';
import { getFileNew, goToOldVersion } from '../../../actions/dpEditor';
import { getFile } from '../../../actions/reportEditor';

const md = meta.dp.exportPlanToDBF;

const TypeDocType = {
  KFK: { label: 'КФК', name: 0 },
  KFK7: { label: 'КБП 7зн', name: 1 },
  KFK4: { label: 'КБП 4зн', name: 2 },
};

const TypeChanges = {
  Plan: { label: 'Планування', name: 1 },
  Changes: { label: 'Зміни до розпису', name: 2 },
  Catalogs: { label: 'Довідники', name: 3 },
};

const planingKindsOptions = Object.values(enums.PlaningKind);

const TypePeriodType = {
  Year: { label: 'Річний розпис', name: 3 },
  Quart: { label: 'Квартальний розпис', name: 2 },
  Month: { label: 'Помісячний розпис', name: 1 },
};

function ExportPlanToDBF() {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const [logicaDocOpened, openLogicaDoc] = useState(false);
  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid && !logicaDocOpened) {
        dispatch(dellComponentFromWindowsManager(`/dp/exportPlanToDBF/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
        openLogicaDoc(true);
      }
    },
    [dispatch, docId, headerForm, navigate, logicaDocOpened, logikaId, openLogicaDoc],
  );

  return (
    <FrameDiv>
      {isProcessing
            && <Loading />}
      <MyContainerFormWithNColumns columns={3}>
        <MyContainerFormWithNColumns columns={2} style={{ marginBottom: '0px' }}>
          <div>
            <StyledLabel>{md.columns.Date1.label}</StyledLabel>
            <DateField
              label={md.columns.Date1.label}
              value={headerForm.get('Date1', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Date1'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.Date2.label}</StyledLabel>
            <DateField
              label={md.columns.Date2.label}
              value={headerForm.get('Date2', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Date2'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <div>
          <StyledLabel>{md.columns.Budget.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('Budget', new Map())}
            modelType="cat"
            modelName="budget"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'Budget'], value));
            }}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('FO', new Map())}
            modelType="cat"
            modelName="csu"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'FO'], value));
            }}
          />
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={4}>
        <div>
          <StyledLabel>{md.columns.fType.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('fType', false)}
            options={Object.values(TypeChanges)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fType'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.fKind.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('fKind', false)}
            options={Object.values(TypePeriodType)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fKind'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.fUtv.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('fUtv', false)}
            options={Object.values(planingKindsOptions)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fUtv'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.PCM.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('PCM', false)}
            options={Object.values(TypeDocType)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PCM'], v))}
          />
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={4}>
        <div>
          <Fields.CheckboxField
            label={md.columns.OneSumm.label}
            value={headerForm.get('OneSumm', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OneSumm'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.f866.label}
            value={headerForm.get('f866', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'f866'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.fSvod.label}
            value={headerForm.get('fSvod', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fSvod'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.UploadInside.label}
            value={headerForm.get('UploadInside', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'UploadInside'], v))}
          />
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={3}>
        <div>
          <Fields.CheckboxField
            label={md.columns.wSighn.label}
            value={headerForm.get('wSighn', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'wSighn'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.no250.label}
            value={headerForm.get('no250', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'no250'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.noSF.label}
            value={headerForm.get('noSF', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noSF'], v))}
          />
        </div>
      </MyContainerFormWithNColumns>
      {/* <CommandPanelColor */}
      {/*  style={{ marginTop: '10px' }} */}
      {/*  text="Натисніть щоб сформувати файл" */}
      {/*  label="Сформувати файл" */}
      {/*  onClick={this.getDBF} */}
      {/* /> */}
      <MyContainerFormWithNColumns columns={3}>
        <div>
          <CommandPanelColor
            style={{ marginTop: '10px' }}
            text="Зберегти ДБФ"
            label="Зберегти ДБФ"
            onClick={() => {
              dispatch(actions.getFile((store) => {
                store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

                return {
                  method: 'CreateDBF',
                };
              }));
            }}
            // onClick={() => {
            //   dispatch(actions.processServerReq('CreateDBF'));
            // }}
          />
        </div>
        <div>
          <CommandPanelColor
            style={{ marginTop: '10px' }}
            text="Створити документ відправки в Logica"
            label="Створити документ відправки в Logica"
            onClick={async () => {
              await openLogicaDoc(false);
              dispatch(actions.processServerReq('CreateDispatchDoc'));
            }}
          />
        </div>
        <div style={{ position: 'absolute', right: 10 }}>
          <DefaultCPButton
            onClick={() => {
              dispatch(goToOldVersion());
            }}
            label="До попередньої версії"
            disabled={isProcessing}
            icon={faArrowRight}
            variant="light"
            showLabel={false}
          />
        </div>
      </MyContainerFormWithNColumns>
      <div>
        <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('SignVariant', new Map())}
          modelType="cat"
          modelName="signatureVariants"
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
        />
      </div>
    </FrameDiv>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ExportPlanToDBF);
