import React, { useContext, useState } from 'react';
import {
  Button, Container, Nav,
} from 'react-bootstrap';
import { faFileCirclePlus, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogcaLoginModal from '../LogikaLogin/LogcaLoginModal';
import { Loader } from '../../../components/bootStrap';
import IconAlert from '../../../components/blanks/common/IconAlert';
import useLogicaData from './hook';
import { EditorControls } from '../../basicEditor/editorControls';
import DocsList from './tp/tablepart';
import metaSrv from '../../../meta/srv/ImportDataFromLogica';
import PageHeader from '../../../components/blanks/common/PageHeader';
import DropdownDocs from './dropdownDocs';
import { importDocs } from '../../../meta/srv';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { WinManagerContext } from '../../../providers';

function Editor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const [selectedIDs, setSelectedIDs] = useState({});
  const {
    loading, err, createData, logicaPingSuccess, year, setYear, res, setRes, setErr,
    datesRes, getData,
  } = useLogicaData();

  const [selectedDocs, setSelectedDocs] = useState(Object.keys(importDocs));

  return (
    <>
      <PageHeader
        title={metaSrv.name}
        description={metaSrv.name}
        className="mb-3"
      >
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => {
              dellComponentFromWindowsManager();
            }}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <LogcaLoginModal />
      {loading && (
        <Loader text="Завантаження" />
      )}
      <Nav className="gap-2 align-items-center">
        <EditorControls.YearInput
          label="Рік"
          controlId="year"
          required
          value={year}
          onChange={(e, v) => {
            setYear(v);
          }}
        />
        <DropdownDocs selectedDocs={selectedDocs} setSelectedDocs={setSelectedDocs} />
        <Button
          onClick={() => {
            setSelectedIDs({});
            getData(selectedDocs);
          }}
          variant="outline-success"
          className="me-2"
          disabled={!logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faFileArrowDown} className="me-2" />
          Отримати список доступних документів на
          {' '}
          {year}
          {' '}
          рік
        </Button>
        <Button
          onClick={() => createData(selectedIDs, selectedDocs)}
          variant="outline-primary"
          disabled={!logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faFileCirclePlus} className="me-2" />
          Завантажити документи
        </Button>
      </Nav>
      {res && (
      <IconAlert
        variant="success"
        dismissible
        onClose={() => {
          setRes(null);
          setSelectedIDs({});
        }}
      >
        {res}
      </IconAlert>
      )}
      {err && (
      <IconAlert
        variant="danger"
        dismissible
        onClose={() => {
          setErr(null);
          setSelectedIDs({});
        }}
      >
        {err}
      </IconAlert>
      )}

      <Container fluid className="mt-2 py-3  border rounded">
        <DocsList
          data={datesRes}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
        />
      </Container>
    </>
  );
}
export default Editor;
