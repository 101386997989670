import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField } from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';

const TABLENAME = 'data';

const coldef = md.dp.indicatorPassportPrinting.tables.data.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "strNumber Document";
  grid-template-columns: 50px 1fr;
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="strNumber">{coldef.strNumber.label}</DivHeader>
      <DivHeader areaName="Document">{coldef.Document.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="strNumber">
          <InputCodeStyled
            value={row.get('strNumber', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="Document">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="indicatorPassport"
            modelType="doc"
            value={row.get('Document', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'Document'], v));
            }}
            noHierarchy
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TableData = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TableData);
