import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  DateStart: { label: 'Період з', name: 'ДатаНачало' },
  DateFin: { label: 'по', name: 'ДатаОкончание' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп.вищ. рівня', name: 'ФинансовыйОрган' },
  DataType: { label: 'Тип даних', name: 'ТипДанных' },
  PlanType: { label: 'Види плану', name: 'ВидыПлана' },
  Year: { label: 'Рік бюджету', name: 'ГодЗатрат' },
  OnQuart: { label: 'Поквартально', name: 'флПоквартально' },
  OnMunth: { label: 'Помісячно', name: 'флПомесячно' },
  OnYear: { label: 'Річна', name: 'флПоГоду' },
  PeriodType: { label: 'Дані для вивантаження', name: 'ВидПериода' },
  NumQuert: { label: 'Номер кварталу', name: 'НомерКвартала' },
  OldVar: { label: 'Попередній варіант', name: 'флСтарыйВариант' },
  DocType: { label: 'Вид документу', name: 'ВидДокумента' },
  Svod: { label: 'Вивантажити всі бюджети', name: 'флСводныйБюджет' },
  SumFormat: { label: 'Вивантжувати суми', name: 'ФорматСумм' },
  NoInside: { label: 'Внутрішні не вивантажувати', name: 'БезВнутренних' },
  InputMonthNom: { label: 'Код "М" класифікації типу розпису ', name: 'СтавитьНомерМесяца' },
  AddEmptyFiles: { label: 'Доповнити пустими файлами', name: 'ДополнитьПустымиФайлами' },
  Registry: { label: 'Вивантажити реєстр змін', name: 'Реестр' },
  Coding: { label: 'Кодування Windows', name: 'Кодировка' },
  PCM: { label: 'За класифікаторами ПЦМ', name: 'ПЦМ' },
  SF111: { label: 'Записувати СФ як"111"', name: 'СФ111' },
  OnlyMain: { label: 'Вивантажувати у довідниках тільки головних розпорядників', name: 'ТолькоГлавные' },
  Var2: { label: 'Додатковий варіант вивантаження доходів', name: 'Вариант2' },
  br: { label: 'Розписати БР по місяцям', name: 'РасписатьБР' },
  f6Separate: { label: 'Записувати спецфонд інші кошти без бюджету розвитку окремо', name: 'ЗаписатьФонд6Отдельно' },
  all7: { label: 'Завжди дописувати підсумки по інших коштах спецфонду', name: 'ВсегдаДописывать7Фонд' },
  noKDB: { label: 'не заповнювати КДБ і КФБ', name: 'БезКДБКФБ' },
};

/**
 * @const
 */
const definition = {
  name: 'exportDBF373',
  backendName: 'ЭкспортВДБФ373',
  frontend: 'dp/exportDBF373',
  label: 'Експорт в ДБФ (УДК. №373)',
  columns,
};

export default definition;
