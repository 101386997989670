import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import getComponentByType from './getComponentByType';
import { PROPERTY_NAME, VALUE_NAME } from './consts';

function RowComponent({ row, rowId, onChange }) {
  const [VC, setVC] = useState(null);
  useEffect(() => {
    const r = getComponentByType(row['Тип'][0]);
    if (r === null) {
      setVC(null);
    } else {
      setVC(React.createElement(r.component, {
        ...r.props,
        value: row[VALUE_NAME],
        onChange: (e, v) => {
          onChange(e, rowId, { [VALUE_NAME]: v });
        },
      }));
    }
  }, [row, rowId, onChange]);
  return (
    <tr>
      <td>{rowId + 1}</td>
      <td>{row[PROPERTY_NAME].repr}</td>
      <td>
        {VC}
      </td>
    </tr>
  );
}

RowComponent.propTypes = {
  row: PropTypes.shape({}).isRequired,
  rowId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RowComponent;
