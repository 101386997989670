/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import {
  getTPController, ExpandableTPCommandPanel,
} from '../../../../../basicEditor';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';

function RevenuesRows({
  onAddRow, onChangeRow, onDeleteSelectedRows, onActivateRow,
  fields, data, errors, activeRow, selectedRows, readOnly,
  onHide, onExpand, expanded, year,
}) {
  return (
    <>
      <ExpandableTPCommandPanel
        onAdd={() => {
          onAddRow();
        }}
        onDelete={onDeleteSelectedRows}
        selectedRows={selectedRows}
        onHide={onHide}
        expanded={expanded}
        onExpand={onExpand}
        canModified={!readOnly}
      />

      <div>
        <Table bordered>
          <thead>
            <Header
              fields={fields}
              year={year}
            />
          </thead>
          <tbody>
            {data && data.map((row, rowIndex) => (
              <Row
                key={`rev-add1-frk-${rowIndex}`}
                rowIndex={rowIndex}
                active={activeRow === rowIndex}
                selected={selectedRows.includes(rowIndex)}
                onActivateRow={onActivateRow}
                onChangeRow={onChangeRow}
                row={row}
                fields={fields}
                errors={errors[rowIndex]}
              />
            ))}
          </tbody>
        </Table>
      </div>

    </>
  );
}
RevenuesRows.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  activeRow: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  onActivateRow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  year: PropTypes.number.isRequired,
};

RevenuesRows.defaultProps = {
  errors: [],
  activeRow: null,
  selectedRows: [],
  readOnly: true,
};

export default getTPController(RevenuesRows);
