import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup,
  Card, Nav, Navbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import md from '../../../../constants/meta/dataprocessors/docCreation';
import { emptyUid } from '../../../../constants/meta/common';

const tableMeta = md.tables.data;

function Documents({
  onSR, data,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [docId, setDocId] = useState(emptyUid);
  const csuIsEmpty = !data[md.columns.CSU.name] || data[md.columns.CSU.name].id === emptyUid;
  const newDocId = useMemo(
    () => data[md.columns.newDoc.name] && data[md.columns.newDoc.name].id,
    [data],
  );
  const tableData = data[md.tables.data.name] || [];

  useEffect(
    () => {
      if (newDocId && newDocId !== emptyUid) {
        const docType = data[md.columns.newDoc.name].modelName === 'ПланАссигнований' ? 'assignmentPlanning' : 'changesToAssignmentPlanning';
        navigate({
          pathname: `/doc/${docType}/${newDocId}/`,
        });
      }
    },
    [data, dispatch, docId, navigate, newDocId],
  );

  return (
    <>
      <Navbar variant="light" expand="sm" className="border rounded bg-light" sticky="top">
        <Nav className="w-100">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={() => onSR('CreateDoc', { docId })}
              disabled={csuIsEmpty || docId === emptyUid}
            >
              <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
              Створити документ
            </Button>
          </ButtonGroup>
        </Nav>
      </Navbar>
      <>
        {tableData.map((doc) => (
          <Card
            key={doc[tableMeta.columns.Document.name].id}
            onClick={(docId === doc[tableMeta.columns.Document.name].id)
              ? () => setDocId(emptyUid)
              : () => setDocId(doc[tableMeta.columns.Document.name].id)}
            className={docId === doc[tableMeta.columns.Document.name].id ? 'bg-info' : 'bgWhite'}
          >
            <Card.Header className="d-flex">
              <Card.Title className="flex-grow-1">
                {doc[tableMeta.columns.docRepr.name]}
              </Card.Title>
            </Card.Header>
          </Card>
        ))}
      </>
    </>
  );
}

Documents.propTypes = {
  onSR: PropTypes.func.isRequired,
  data: PropTypes.shape({
  }),
};

Documents.defaultProps = {
  data: {},
};
export default Documents;
