import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { font } from '../../constants/globalStyle';

import checkIcon from '../../assets/icons/radioCheck.svg';

export const StyleRadiobutton = styled.input`
    display:none;
    +label:before{
      ${({ checked }) => (checked ? `background:url("${checkIcon}") 0.2em 0.2em no-repeat white` : 'background: white')};
      content: "";
      display: inline-block;
      margin: -5px 4px 0 0;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      border: 1px solid #a9a5a5;
      border-radius: 50%;
      box-sizing: inherit;
      }    
`;

export const LabelRadiobutton = styled.label`
    line-height: 16px;
    ${font};
    margin-right: 15px;
   ${(props) => (props.disabled
    ? 'filter: grayscale(1) opacity(.275);'
    : `
       cursor: pointer;
       &> * { cursor: pointer; }`)}
  `;

class RadioButtons extends PureComponent {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape(
      { value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]) },
      { label: PropTypes.string },
    )).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: null,
    onChange: () => null,
    value: null,
  };

  handleClick = (e, value) => {
    const { props: { onChange, disabled } } = this;
    if (!disabled) onChange(e, value);
  };

  render() {
    const {
      props: {
        values, value, disabled,
      },
    } = this;
    return values.map((v) => (
      <div key={`Radio_${v.value}`}>
        <StyleRadiobutton
          type="radio"
          key={v.value}
          checked={v.value === value}
          disabled={disabled}
          readOnly
        />
        <LabelRadiobutton
          onClick={(e) => { this.handleClick(e, v.value); }}
          disabled={disabled}
        >
          {v.label}
        </LabelRadiobutton>
      </div>
    ));
  }
}

export default RadioButtons;
