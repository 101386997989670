import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: {
    label: 'Найменування',
    name: 'Наименование',
    visible: true,
  },
  owner: { name: 'Владелец', label: 'Власник', visible: true },

  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const accessGroup = {
  label: 'Групи доступа',
  name: 'accessGroup',
  backendName: 'ГруппыДоступа',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/accessGroup',
  listColumns: [...(new Set([...reqListerColumns, 'owner']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default accessGroup;
