import base from './base';
import LogicaReportType from '../enums/logicaReportTypes';

/**
 * @const
 */
const columns = {
  ...base.columns,
  sendJson: { label: 'Файл', name: 'Файл', visible: false },
  idTans: { label: 'ИД', name: 'ИД', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  createDate: { label: 'Дата створення', name: 'ДатаСоздания', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  sendDate: {
    label: 'Дата відправлення', name: 'ДатаОтправки', visible: true, type: 'date',
  },
  sendAuthor: { label: 'Відправив', name: 'Отправил', visible: false },
  typeReport: {
    label: 'Вид звіту',
    name: 'ВидОтчета',
    visible: true,
    type: 'variants',
    variants: Object.values(LogicaReportType).reduce((R, v) => ({ ...R, [v.name]: v.label }), {}),
  },
  htmlReport: { label: 'HTML', name: 'РезультатХТМЛ' },
  result: { label: 'Результат', name: 'Результат' },
  signList: { label: 'Підписи', name: 'Подписи' },
  response: { label: 'Відповідь сервісу "ЛОГІКА"', name: 'response' },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  signed: { label: 'Підписано', name: 'Подписали', visible: false },
  // Дополнитльеные реквизиты
  unsigned: { label: 'Непідписано', name: 'Неподписали', visible: false },
  logicaPDF: { label: 'ПДФ файл у Логіці', name: 'ИДЛогикаФайлПДФ', visible: true },
  logicaPDFPrognoz: { label: 'ПДФ файл у Логіці', name: 'ИДЛогикаФайлПДФPrognoz', visible: false },
  logicaPDFRish: { label: 'ПДФ файл у Логіці', name: 'ИДЛогикаФайлПДФRish', visible: false },
  namePDF: { label: 'Имя ПДФ', name: 'ИмяПДФ', visible: false },
  namePDFPrognoz: { label: 'Имя ПДФ', name: 'ИмяПДФPrognoz', visible: false },
  namePDFRish: { label: 'Имя ПДФ', name: 'ИмяПДФRish', visible: false },
  isMessageSent: { label: 'Повідомлення було відправлено', name: 'ГодОтправки', visible: false },
  filePdf: { label: 'Доданий файл', name: 'ФайлПДФСтрока', visible: false },
  filePdfPrognoz: { label: 'Текстова форма прогнозу', name: 'ФайлПДФPrognozСтрока', visible: false },
  filePdfRish: { label: 'Документ про схвалення прогнозу', name: 'ФайлПДФRishСтрока', visible: false },
  uncheck: { label: 'Не прошли проверку', name: 'НеПрошлиПроверку' },
  sessioDecigionSent: { label: 'Рішення сесії на цей рік відправвлялось', name: 'РешениеОтправлено', visible: false },
  signResult: { label: 'СигнатураПодписи', name: 'СигнатураПодписи', visible: false },
  htmlReportOriginal: { label: 'HTML', name: 'РезультатХТМЛОригинал' },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ДокументыИзБазы',
    label: 'Вихідні документи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      doc: { label: 'Документ', name: 'Док' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Документи Logica',
  name: 'docTransferToLogica',
  backendName: 'ОтправкаОчетовВЛогику',
  columns,
  frontend: 'doc/docTransferToLogica',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'sendDate',
      'typeReport',
      'FI',
      'signed',
      'unsigned',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
  resultColumns: { result: 'Результат' },
};

export default definition;
