import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../assets/icons';
import { CloseCommandButton } from './index';
import { CommandPanelColor } from '../Form/styledForm';
import { StyleInnerHeader } from '../styledMenuHeader/menuHeader';

const getSizeStyle = (size) => {
  if (size === 'small') {
    return `
      width: 60%;
    `;
  }
  if (size === 'middle') {
    return `
      width: 90%;
      height: 70%;
    `;
  }
  return `
    width: 100%;
    height: 100%;
  `;
};

const CommandPanelBut = styled(CommandPanelColor)`
  font-family: Roboto, sans-serif;
  padding: 6px 10px;
  font-size: 13.5px;
  margin-right: 0;
`;

export const StyleHeader = styled(StyleInnerHeader)`
  margin: 0px;
  
`;

const ModalWin = styled.div`
  transition: all 2.8s ease 3s;
  position: fixed; 
  width: 100%;
  height: 100%;
  // overflow: scroll;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;  
  z-index: ${({ zIndexModal }) => (zIndexModal + 30)};
  
`;

const ModalBody = styled.div`
  transition: all 2.8s ease 3s;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: scroll;
  padding: 30px 10 px;  
  ${({ size }) => getSizeStyle(size)}
`;

const ModalContent = styled.div`
  transition: all 2.8s ease 3s;
  overflow: scroll;
  background-color: #fafbfb;
  color: #000;
  padding 30px;  
  border-radius: 6px;
      width: 100%;
    height: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

function ModalPureJs({
  closeIcon,
  header,
  onClose,
  size,
  hideCloseIcon,
  zIndexModal,
  children,

}) {
  return (
    <ModalWin zIndexModal={zIndexModal}>
      <ModalBody size={size}>
        <ModalContent>
          <ContainerHeader>
            {!!header && (
            <StyleHeader>{header}</StyleHeader>
            )}
            {!hideCloseIcon
                      && (
                        <div>
                          {closeIcon ? (
                            <CloseCommandButton
                              onClick={onClose}
                              text="Закрити"
                            >
                              <CloseIcon />
                            </CloseCommandButton>
                          )
                            : (
                              <div>
                                <CommandPanelBut
                                  onClick={onClose}
                                  label="Застосувати"
                                />
                              </div>
                            )}
                        </div>
                      )}
          </ContainerHeader>
          {children}
        </ModalContent>
      </ModalBody>
    </ModalWin>
  );
}

ModalPureJs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  closeIcon: PropTypes.bool,
  header: PropTypes.string,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['small', 'middle', 'fullscreen']),
  hideCloseIcon: PropTypes.bool,
  zIndexModal: PropTypes.number,
};

ModalPureJs.defaultProps = {
  closeIcon: false,
  header: null,
  onClose: null,
  size: 'fullscreen',
  hideCloseIcon: false,
  zIndexModal: 0,
};

export default ModalPureJs;
