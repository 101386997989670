/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock, faLockOpen,
} from '@fortawesome/free-solid-svg-icons';
import { referencePropType } from '../../../../../newEditor/propTypes';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';
import DocsCommandPanel from '../../../../../newEditor/commandPanels/docstp';

const tableMeta = md.tables.fillingByLowestLevelCSU;

function DocsTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const {
    tableActions,
  } = useLowerDocs({
    tableName: tableMeta.name,
    onChange: actions.onChange,
    approvedName: tableMeta.columns.received.name,
  });

  return (
    <>
      <DocsCommandPanel tableActions={tableActions} readOnly={readOnly} />
      <>
        {tableData.map((doc, rowId) => (
          <Card key={doc[tableMeta.columns.doc.name].id}>
            <Card.Body>
              <Card.Title>
                <Link to={`/${md.frontend}/${doc[tableMeta.columns.doc.name].id}`}>
                  {/*{doc[tableMeta.columns.doc.name].repr}*/}
                  {`${doc[tableMeta.columns.doc.name].repr} - Розпорядник ${doc[tableMeta.columns.FI.name].repr}`}
                </Link>
              </Card.Title>
              <div className="d-flex">
                <Button
                  variant={doc[tableMeta.columns.received.name] ? 'outline-success' : 'outline-danger'}
                  onClick={(e) => tableActions.onToggleApproveDoc(e, rowId)}
                >
                  <FontAwesomeIcon icon={doc[tableMeta.columns.received.name] ? faLock : faLockOpen} className="me-2" />
                  {doc[tableMeta.columns.received.name] ? 'Отримано' : 'Не отримано'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </>
    </>
  );
}

DocsTP.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
    [tableMeta.columns.doc.name]: referencePropType,
    [tableMeta.columns.selection.name]: PropTypes.bool,
    [tableMeta.columns.received.name]: PropTypes.bool,

  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

DocsTP.defaultProps = {
  readOnly: false,
};

export default memo(DocsTP);
