import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  disposer: foreignPropType,
  kbp7_item: foreignPropType,
  target_programm: foreignPropType,
  kbp: foreignPropType,
  kbp7: foreignPropType,
  check_negative_sum: PropTypes.bool,
  total_zag_fond: PropTypes.number,
  consumption_expenditures_zf: PropTypes.number,
  job_payment_zf: PropTypes.number,
  utility_bills_zf: PropTypes.number,
  development_cost_zf: PropTypes.number,
  total_spec_fond: PropTypes.number,
  consumption_expenditures_sf: PropTypes.number,
  job_payment_sf: PropTypes.number,
  utility_bills_sf: PropTypes.number,
  development_cost_sf: PropTypes.number,
  note: PropTypes.string,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
