import React, {
  forwardRef, useCallback, useContext, useEffect, useState,
} from 'react';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/no-cycle
import { DocListerCommandPanel } from '../../../../newLister/tableComponent/commandpanel';
import api from '../../../../../api/req';
import AppContext from '../../../../../providers/CiatAppContext';
import { CPButton } from '../../../../../components/bootStrap/buttons';

const NetCategoryCommandPanel = forwardRef((p, ref) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [hasNewVersion, setHasNewVersion] = useState(false);
  const { auth } = useContext(AppContext);
  const checkNewVersion = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get('/api/merezha/uploadschema/', auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader()
        .then((d) => {
          setHasNewVersion(d.need_upload);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth],
  );
  useEffect(
    () => {
      checkNewVersion();
    },
    [checkNewVersion],
  );
  const updateCatalog = useCallback(
    () => {
      const loader = async () => {
        const r = await api.post('/api/merezha/uploadschema/', auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader()
        .then(() => {
          checkNewVersion();
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, checkNewVersion],
  );
  return (
    <DocListerCommandPanel>
      <CPButton
        icon={faBarsStaggered}
        content="Оновити довідник"
        ref={ref}
        animation={hasNewVersion}
        spin={loading}
        disabled={!hasNewVersion}
        // title={hasNewVersion ? 'Є оновлення' : 'Довідник в актуальному стані'}
        onClick={updateCatalog}
        title={err}
      />
    </DocListerCommandPanel>
  );
});

export default NetCategoryCommandPanel;
