import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { soSelector } from '../../_common/selectors';
import md from '../../../../constants/meta/documents/financeObligation';
import enums from '../../../../constants/meta/enums';
import {
  ContainerStamp,
  ColorTextStamp,
  ImgContainerStamp,
  Title,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { referencePropType } from '../../../newEditor/propTypes';

const TypeDocType = [
  { display_name: '-', value: 0 },
  { display_name: 'ПД', value: 1 },
  { display_name: 'НО', value: 2 },
];

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function DocEditor({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];
  const isAccountHighLevelCSU = data[md.columns.isAccountHighLevelCSU.name];
  const FiFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const documentKind = data[md.columns.documentKind.name];

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.rNo.label}
            value={data[md.columns.rNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.rNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.legalObligation.label}
            value={data[md.columns.legalObligation.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.legalObligation.name]: value,
              });
              actions.onSR('CHANGE_LEGAL_OBLIGATION');
            }}
            modelType="doc"
            modelName="legalObligation"
            filter={[{ fieldName: 'ФинансовыйОрган', value: FI }]}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.planingMode.name]: value,
              });
            }}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.documentKind.label}
            value={data[md.columns.documentKind.name]}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.deliveryDate.label}
            value={data[md.columns.deliveryDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.deliveryDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.dueTime.label}
            value={data[md.columns.dueTime.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dueTime.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.finTime.label}
            value={data[md.columns.finTime.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.finTime.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Card
              // bg="info"
              key="clientInfo"
              // text="white"
              style={{ width: '18rem' }}
              className="mb-2"
            >
              <Card.Header>{md.columns.fondObject.label}</Card.Header>
              <Card.Body>
                <Card.Text>
                  {data[md.columns.fondObject.name] ? data[md.columns.fondObject.name].repr : ''}
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card
              key="infoElementKFK"
              style={{ width: '18rem' }}
              className="mb-2"
            >
              <Card.Header>
                {md.columns.elementKFK.label}
                {' '}
                {data[md.columns.KPKVCode.name]}
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {data[md.columns.elementKFK.name] ? data[md.columns.elementKFK.name].repr : ''}
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Col>
        <Col>
          <Row>
            <EditorControls.ItemPicker
              label={md.columns.client.label}
              value={data[md.columns.client.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.client.name]: value,
                });

                if (data[md.columns.client.name]) actions.onSR('CHANGE_CLIENT');
              }}
              modelType="cat"
              modelName="csuPartners"
              filter={FiFilter}
              readOnly={readOnly}
            />
          </Row>
          <Row>
            <EditorControls.ItemPicker
              label={md.columns.account.label}
              value={data[md.columns.account.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.account.name]: value,
                });
              }}
              modelType="cat"
              modelName="bankAccounts"
              filter={[
                ...FiFilter,
                { fieldName: 'ВидСчета', value: isAccountHighLevelCSU ? 2 : 1 },
              ]}
              params={[{
                name: 'НаДату',
                value: data[md.columns.date.name],
              }]}
              readOnly={readOnly}
            />
          </Row>
          <Row>
            <EditorControls.SelectorInput
              label={md.columns.limitation.label}
              value={data[md.columns.limitation.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.limitation.name]: value,
                });
              }}
              values={TypeDocType}
              readOnly={readOnly}
            />
          </Row>
        </Col>
        <Col sm={6}>
          <Row>
            <Title>Дані контрагента</Title>
          </Row>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.clientDocDate.label}
                value={data[md.columns.clientDocDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.clientDocDate.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.clientDocNo.label}
                value={data[md.columns.clientDocNo.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.clientDocNo.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.clientAccount.label}
                value={data[md.columns.clientAccount.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.clientAccount.name]: value,
                  });
                }}
                modelType="cat"
                modelName="partnerBankAccounts"
                filter={[{ fieldName: 'Владелец', value: data[md.columns.client.name] }]}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <ContainerStamp>
                {data[md.columns.isApproved.name] && (
                  <ImgContainerStamp background={StampApproved}>
                    <ColorTextStamp color="#4281c9">
                      {data[md.columns.received.name]}
                    </ColorTextStamp>
                  </ImgContainerStamp>
                )}
              </ContainerStamp>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="align-items-end">
        {documentKind === enums.ObligationTypes.storno.name && (
          <Col>
            <EditorControls.StringInput
              label={md.columns.reason.label}
              value={data[md.columns.reason.name].repr}
              readOnly
            />
          </Col>
        )}
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`received-${data.id}`}
              label={md.columns.received.label}
              value={data[md.columns.received.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.received.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
      </Row>
    </EditorCollapse>
  );
}

DocEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.planingMode.name]: PropTypes.oneOf([
      enums.PlaningKind.temporary.name, enums.PlaningKind.permanent.name,
    ]),
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DocEditor;
