import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/costAnalysisPlanFact';
import DateRangeField from '../../../components/fields/DateRangeField';
import {
  ResultSegment,
  ThreeColumnsGridContainer,
  StyledLabel,
  SevenColumnsGridContainer,
  ContainerDiv,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import Fields from '../../field';
import CheckBoxField from '../../field/CheckBox';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import { ItemField } from '../../field/selectorField/item';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import Tabs from '../../../components/tabs/Tabs';
import StyledTPPane from '../../../components/tableParts/TPPane';
import ReportContainer from '../reportContainer';

const SixColumns = styled(SevenColumnsGridContainer)`
  grid-template-columns: 10% 10% 10% 20% 20% 20%;
  grid-column-gap: 2%;
`;
const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

const PeriodVariant = {
  allYear: { label: 'Весь рік', value: 0 },
  onMonthAndYear: { label: 'По місяць + рік', value: 1 },
  toMonthAndYear: { label: 'На місяць + рік', value: 2 },
  onlyMonth: { label: 'Тільки місяць', value: 3 },
  onlyYear: { label: 'Тільки річна', value: 4 },
};

class ReportCostAnalysisPlanFactEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    sessionOptions: new Map(),
    isProcessing: false,
    portalOpened: false,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;
    const FO = headerForm.get('FO', new Map());

    const Bookmarks = (
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledTPPane>
            <DCControls.Structure />
          </StyledTPPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledTPPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledTPPane>
        </DivWithPropsLabel>
      </Tabs>
    );

    const BudgetLabel = (isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disable
      />
    ));

    // const FoLabel = (isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    const FoLabel = (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
        filter={[
          { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
      />
    );
    // : (
    //     <ItemField
    //       value={headerForm.get('FO', new Map())}
    //       disable
    //     />
    //   ))

    const SettingsButton = (
      <div>
        <ThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>

          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
          <div>
            <CheckBoxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
            />
            <CheckBoxField
              label={md.columns.byHighLevelCSU.label}
              value={headerForm.get('byHighLevelCSU', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'byHighLevelCSU'], v));
                if (v) {
                  dispatch(changeField(['headerForm', 'bySubLevelCSU'], false));
                }
              }}
            />
          </div>
        </ThreeColumnsGridContainer>
        <SixColumns>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.periodVariant.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('periodVariant', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'periodVariant'], v))}
              values={Object.values(PeriodVariant)}
              name="periodVariant"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => ({ label: _e.label, value: _e.name }))}
              name="planType"
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('byApprovedDoc', false)}
              label={md.columns.byApprovedDoc.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byApprovedDoc'], v))}
            />
            <CheckBoxField
              value={headerForm.get('approved', false)}
              label={md.columns.approved.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
            />
            <CheckBoxField
              value={headerForm.get('signVariant', false)}
              label={md.columns.signVariant.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            />
            <CheckBoxField
              value={headerForm.get('byMonthsAccum', false)}
              label={md.columns.byMonthsAccum.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byMonthsAccum'], v))}
            />
            <CheckBoxField
              value={headerForm.get('in1000', false)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
            <CheckBoxField
              value={headerForm.get('byProposition', false)}
              label={md.columns.byProposition.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byProposition'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('approved', false)}
              label={md.columns.approved.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
            />
            <CheckBoxField
              value={headerForm.get('printReportParams', false)}
              label={md.columns.printReportParams.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'printReportParams'], v))}
            />
            <CheckBoxField
              value={headerForm.get('isDataTreasury', false)}
              label={md.columns.isDataTreasury.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'isDataTreasury'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('showClassifiersName', false)}
              label={md.columns.showClassifiersName.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showClassifiersName'], v))}
            />
            <CheckBoxField
              value={headerForm.get('showCSUNames', false)}
              label={md.columns.showCSUNames.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUNames'], v))}
            />
          </div>
        </SixColumns>
        <SixColumns>
          <div>
            <CheckBoxField
              value={headerForm.get('flFin', false)}
              label={md.columns.flFin.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flFin'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('flCsu', false)}
              label={md.columns.flCsu.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flCsu'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('flPlan', false)}
              label={md.columns.flPlan.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flPlan'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('flFact', false)}
              label={md.columns.flFact.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flFact'], v))}
            />
          </div>
        </SixColumns>
        {Bookmarks}
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportCostAnalysisPlanFactEditor);
