const frontendURL = '/doc/draftAnnualTransfert/';
const backendURL = '/api/planproject/transfertprojectheader/';
const name = 'Проект міжбюджетних трансфертів (Дод. 5 до РС)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
