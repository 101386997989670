import styled from 'styled-components';
import {
  font, hoverTable, tableHeader, tableInside,
} from '../../constants/globalStyle';
import GetNumberInput from '../../containers/field/NumberInput';

// eslint-disable-next-line no-confusing-arrow
export const BasedGrid = styled.div.attrs(({ isHeader }) => isHeader
  ? {
    style: { position: 'sticky', zIndex: '3' },
  } : {})`
  display: grid; 
  grid-template-columns: 100px 1fr  ;
  grid-auto-columns: 7%;
  grid-auto-rows: auto;
  top: 0;
  bottom: 0;
`;

export const DivHeader = styled.div.attrs(({ areaName, isActive }) => ({
  style: {
    gridArea: areaName,
    background: isActive
      ? 'linear-gradient(0deg, #66BC7A, #66BC7A), #008F21'
      : 'linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9',
  },
}))`
  overflow-wrap: break-word;
  ${tableHeader};
  ${tableInside};
`;

export const DivFooter = styled(DivHeader)`
  grid-area: ${(props) => props.areaName};
  text-align: right;
  overflow: hidden;
`;

export const DivCell = styled.div.attrs(({ isFocusCell, areaName }) => ({
  style: {
    gridArea: areaName,
    ...(isFocusCell && { background: 'linear-gradient(0deg, #66BC7A, #66BC7A), #008F21' }),
  },
}))`
  padding: 0.08em;
  ${tableInside};
  ${hoverTable};
`;
export const DivCheck = styled(DivCell)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DivCheckCell = styled.div.attrs(({ isFocusCell, areaName }) => ({
  style: {
    gridArea: areaName,
    ...(isFocusCell && { background: 'linear-gradient(0deg, #66BC7A, #66BC7A), #008F21' }),
  },
}))`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2px;
  ${tableInside};
  ${hoverTable};
`;

export const InputCodeStyled = styled.input.attrs(({ border, background, radius }) => ({
  style: {
    border: border ? '1px solid rgba(34,36,38,.15)' : 'none',
    backgroundColor: background ? 'white' : 'transparent',
    borderRadius: radius ? '4px' : '0px',
  },
}))`
  padding: 3px 2px;
  outline: none;
  width: 100%;
  ${font};
`;

export const SumInputStyled = styled(GetNumberInput(17, 2)).attrs(({ areaName }) => ({
  style: {
    gridArea: areaName,
  },
}))`
  padding: 0.08em;
  margin-right: 0.2em;
`;

// export const SumInpStyled = styled(GetNumInput(17, 2))`
//   padding: 0.08em;
//   margin-right: 0.2em;
//   grid-area: ${props => props.areaName};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: inherit;
  height: 100%;
`;

export const ContTable = styled.div`
  margin-top: 10px;
  flex: 1;
  overflow: inherit;
`;
