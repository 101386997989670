import React, { forwardRef, useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import PrintModal from './listerPrintModal';
import ListerContext from '../../../newLister/context';

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);
  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <ButtonGroup>
        <PrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
      </ButtonGroup>
      {/* <ButtonGroup> */}
      {/*  <SendToLogicaButton modelType={modelType} modelName={modelName} /> */}
      {/* </ButtonGroup> */}
    </DocListerCommandPanel>
  );
});
export default CommandPanel;
