import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
  StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'tasksSpent';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, budget,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col lg={3}>
            <TableRowCell column="tasks" highlighted={highlights.includes('tasks')}>
              <ItemPicker
                value={row[tableMD.columns.tasks.name]}
                modelType="cat"
                modelName="tasks"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.tasks.name]: v })}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={9}>
            <TableRowCell column="explanationDeviation" highlighted={highlights.includes('explanationDeviation')}>
              <StringInput
                value={row[tableMD.columns.explanationDeviation.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.explanationDeviation.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sumPlanGenF" highlighted={highlights.includes('sumPlanGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumPlanGenF.name]}
                precision={2}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPlanSpecF" highlighted={highlights.includes('sumPlanSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumPlanSpecF.name]}
                precision={2}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactGenF" highlighted={highlights.includes('sumFactGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumFactGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sumFactGenF.name]: v,
                  [tableMD.columns.sumFactTotal.name]: (row[tableMD.columns.sumFactSpecF.name] || 0)
                  + (v || 0),
                  [tableMD.columns.sumDeviationTotal.name]:
                  (v || 0) - (row[tableMD.columns.sumPlanSpecF.name] || 0)
                  - (row[tableMD.columns.sumPlanGenF.name] || 0)
                  + (row[tableMD.columns.sumFactSpecF.name] || 0),
                  [tableMD.columns.sumDeviationGenF.name]:
                  (v || 0)
                   - (row[tableMD.columns.sumPlanGenF.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactSpecF" highlighted={highlights.includes('sumFactSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumFactSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sumFactSpecF.name]: v,
                  [tableMD.columns.sumFactTotal.name]: (row[tableMD.columns.sumFactGenF.name] || 0)
                    + (v || 0),
                  [tableMD.columns.sumDeviationTotal.name]:
                  (v || 0)
                  - (row[tableMD.columns.sumPlanSpecF.name] || 0)
                  - (row[tableMD.columns.sumPlanGenF.name] || 0)
                  + (row[tableMD.columns.sumFactGenF.name] || 0),
                  [tableMD.columns.sumDeviationSpecF.name]:
                  (v || 0)
                  - (row[tableMD.columns.sumPlanSpecF.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumDeviationGenF" highlighted={highlights.includes('sumDeviationGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumDeviationGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumDeviationGenF.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumDeviationSpecF" highlighted={highlights.includes('sumDeviationSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumDeviationSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumDeviationSpecF.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sumPlanTotal" highlighted={highlights.includes('sumPlanTotal')}>
              <NumberInput
                value={row[tableMD.columns.sumPlanTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPlanTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactTotal" highlighted={highlights.includes('sumFactTotal')}>
              <NumberInput
                value={row[tableMD.columns.sumFactTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumFactTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumDeviationTotal" highlighted={highlights.includes('sumDeviationTotal')}>
              <NumberInput
                value={row[tableMD.columns.sumDeviationTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumDeviationTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,

  budget: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
