/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Accordion, Button,
  Card, Col, ListGroup, Row, Tab, Tabs,
} from 'react-bootstrap';
import { StyledLabel } from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/documents/investmentProposalObjects';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  BuildingState, GeneralTP, PVR, Power,
} from './tabs';

function InvestmentProposalObjects({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось
  const FI = data[md.columns.FI.name];
  const objectsBuildingOptions = useMemo(
    () => [{ fieldName: 'Заказчик', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;

  const beginningDate = new Date(data[md.columns.beginningDate.name]);
  const month = (date) => {
    const m = date.getMonth() + 1;
    if (m.toString().length === 1) {
      return `0${m}`;
    }
    return m;
  };
  const day = (date) => {
    const d = date.getDate();
    if (d.toString().length === 1) {
      return `0${d}`;
    }
    return d;
  };

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title="Основне" eventKey="general">
        <EditorCollapse>
          {sessionOptions.get('is_admin', false) && (
            <BudgetFOUserEditorRow
              onChange={actions.onChange}
              data={data}
              readOnly={readOnly}
            />
          )}
          <Row>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.number.label}
                value={data[md.columns.number.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.number.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.DateInput
                label={md.columns.date.label}
                value={data[md.columns.date.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.date.name]: value,
                  [md.columns.year.name]: new Date(value).getFullYear(),
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.YearInput
                label={md.columns.year.label}
                value={data[md.columns.year.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.year.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            {data[md.columns.isOdessaMode.name] && (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.projectVariant.label}
                  value={data[md.columns.projectVariant.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.year.name]: value,
                  })}
                  modelType="cat"
                  modelName="variantBudgetDraft"
                  readOnly={readOnly}
                />
              </Col>
            )}
            <Col>
              <EditorControls.CheckboxInput
                controlId={`isApproved-${data.id}`}
                label={md.columns.isApproved.label}
                value={data[md.columns.isApproved.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.isApproved.name]: value,
                })}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`flClosedDoc-${data.id}`}
                label={md.columns.flClosedDoc.label}
                value={data[md.columns.flClosedDoc.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.flClosedDoc.name]: value,
                })}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.objectBuilding.label}
                value={data[md.columns.objectBuilding.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.objectBuilding.name]: value,
                })}
                modelType="cat"
                modelName="objectsBuilding"
                values={objectsBuildingOptions}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.finSource.label}
                value={data[md.columns.finSource.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.finSource.name]: value,
                })}
                modelType="cat"
                modelName="objBuildingSourceFin"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.beginningDate.label}
                value={data[md.columns.beginningDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.beginningDate.name]: value,
                })}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.DateInput
                label={md.columns.endingDate.label}
                value={data[md.columns.endingDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.endingDate.name]: value,
                })}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.sumEstimates.label}
                precision={2}
                value={data[md.columns.sumEstimates.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.sumEstimates.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <StyledLabel>
                    { `01.01.${data[md.columns.year.name]}
                    -
                    31.12.${data[md.columns.year.name]}`}
                  </StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.NumberInput
                    label={md.columns.totalSum.label}
                    precision={2}
                    value={data[md.columns.totalSum.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.totalSum.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <StyledLabel>
                    {`Очікуване виконання на
                     ${day(beginningDate)}.${month(beginningDate)}.${beginningDate.getFullYear()}
                    -
                    31.12.${data[md.columns.year.name]}`}
                  </StyledLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.NumberInput
                    label={md.columns.yearSum.label}
                    precision={2}
                    value={data[md.columns.yearSum.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.yearSum.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title> Поточний рік </Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`Інвестиційна пропозиція на ${data[md.columns.year.name]} р.`}
                        precision={2}
                        value={data[md.columns.nextYearSum.name]}
                        readOnly
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`Граничний обсяг видатків на ${data[md.columns.year.name]} р.`}
                        precision={2}
                        value={data[md.columns.nextYearCriticalSum.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.nextYearCriticalSum.name]: value,
                          [md.columns.nextYearSum.name]:
                          data[md.columns.nextYearAddSum.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`Додаткова потреба на ${data[md.columns.year.name]} р.`}
                        precision={2}
                        value={data[md.columns.nextYearAddSum.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.nextYearAddSum.name]: value,
                          [md.columns.nextYearSum.name]:
                          data[md.columns.nextYearCriticalSum.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>{`Прогноз на ${data[md.columns.year.name] + 1} р.`}</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 1} р.`}
                        precision={2}
                        value={data[md.columns.predictionSum1.name]}
                        readOnly
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 1} р. (граничний потреба)`}
                        precision={2}
                        value={data[md.columns.predictionCriticalSum1.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.predictionCriticalSum1.name]: value,
                          [md.columns.predictionSum1.name]:
                          data[md.columns.predictionAddSum1.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 1} р. (додаткова потреба)`}
                        precision={2}
                        value={data[md.columns.predictionAddSum1.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.predictionAddSum1.name]: value,
                          [md.columns.predictionSum1.name]:
                          data[md.columns.predictionCriticalSum1.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>{`Прогноз на ${data[md.columns.year.name] + 2} р.`}</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 2} р.`}
                        precision={2}
                        value={data[md.columns.predictionSum2.name]}
                        readOnly
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 2} р. (граничний потреба)`}
                        precision={2}
                        value={data[md.columns.predictionCriticalSum2.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.predictionCriticalSum2.name]: value,
                          [md.columns.predictionSum2.name]:
                          data[md.columns.predictionAddSum2.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <EditorControls.NumberInput
                        label={`на ${data[md.columns.year.name] + 2} р. (додаткова потреба)`}
                        precision={2}
                        value={data[md.columns.predictionAddSum2.name]}
                        onChange={(e, value) => actions.onChange({
                          [md.columns.predictionAddSum2.name]: value,
                          [md.columns.predictionSum2.name]:
                          data[md.columns.predictionCriticalSum2.name] || 0 + value,
                        })}
                        readOnly={readOnly}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </EditorCollapse>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            ПВР
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <PVR data={data} actions={actions} readOnly={readOnly} />
          </Accordion.Collapse>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Інфо
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <GeneralTP data={data} actions={actions} readOnly={readOnly} />
          </Accordion.Collapse>
        </Accordion>
      </Tab>
      <Tab title="Потужності" eventKey="power">
        <Power data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Рівень будівельної готовності" eventKey="buildingState">
        <BuildingState data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

InvestmentProposalObjects.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default InvestmentProposalObjects;
