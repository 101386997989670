import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  swapDownTableRow, swapUpTableRow, addTableRow, copyTableRow, removeTableRow,
} from '../../actions/dpEditor';
import { CommandPanelButton } from '../button';
import {
  AddIcon, ArrowUpIcon, ArrowDownIcon, CopyIcon, RemoveIcon,
} from '../../assets/icons';

export const ContainerButton = styled.div`
  display: flex;
`;

class TPCommandPanel extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    tableName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    extraContent: PropTypes.element,
  };

  static defaultProps = {
    extraContent: null,
    disabled: false,
  };

  render() {
    const {
      tableName, dispatch, extraContent, disabled,
    } = this.props;
    return (
      <>
        <ContainerButton>
          <CommandPanelButton
            disabled={disabled}
            text="Додати"
            onClick={() => {
              dispatch(addTableRow(tableName));
            }}
          >
            {' '}
            <img src={AddIcon} alt="Додати" />
          </CommandPanelButton>
          <CommandPanelButton
            disabled={disabled}
            text="Перемістити вище"
            onClick={() => {
              dispatch(swapUpTableRow(tableName));
            }}
          >
            {' '}
            <ArrowUpIcon />
          </CommandPanelButton>
          <CommandPanelButton
            disabled={disabled}
            text="Перемістити нижче"
            onClick={() => {
              dispatch(swapDownTableRow(tableName));
            }}
          >
            {' '}
            <ArrowDownIcon />
          </CommandPanelButton>
          <CommandPanelButton
            disabled={disabled}
            text="Копіювати"
            onClick={() => {
              dispatch(copyTableRow(tableName));
            }}
          >
            {' '}
            <CopyIcon />
          </CommandPanelButton>
          <CommandPanelButton
            disabled={disabled}
            text="Видалити"
            onClick={() => {
              dispatch(removeTableRow(tableName));
            }}
          >
            {' '}
            <RemoveIcon />
          </CommandPanelButton>
        </ContainerButton>
        {extraContent}
      </>
    );
  }
}

export default connect()(TPCommandPanel);
