import React from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from '../components/blanks/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../components/blanks/common/advance-table/AdvanceTable';
// eslint-disable-next-line import/no-named-as-default
import AdvanceTableFooter from '../components/blanks/common/advance-table/AdvanceTableFooter';

// const columns = [
//   {
//     accessor: 'name',
//     Header: 'Name',
//   },
//   {
//     accessor: 'email',
//     Header: 'Email',
//   },
//   {
//     accessor: 'age',
//     Header: 'Age',
//   },
// ];
// const data = [
//   {
//     name: 'Anna',
//     email: 'anna@example.com',
//     age: 18,
//   },
//   {
//     name: 'Homer',
//     email: 'homer@example.com',
//     age: 35,
//   },
//   {
//     name: 'Oscar',
//     email: 'oscar@example.com',
//     age: 52,
//   },
// ];

function AdvanceReactTable({ data, columns, perPage }) {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      sortable
      pagination
      perPage={perPage}
    >
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden',
        }}
      />
      <div className="mt-3">
        <AdvanceTableFooter
          rowCount={data.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
}

export default AdvanceReactTable;

AdvanceReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  perPage: PropTypes.number,
};

AdvanceReactTable.defaultProps = {
  perPage: 5,
};
