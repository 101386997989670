import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
};

const definition = {
  label: 'Зведена завка на фінансування',
  name: 'PivotRequest',
  columns,
  frontend: 'rep/PivotRequest',
  backendName: 'СводнаяЗаявка',
  resultColumns: baseResultColumns,
};

export default definition;
