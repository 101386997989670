import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  FundsType: { label: 'Вид коштів', name: 'ВидСредств', visible: true },
  byDetailsKEKV: { label: 'Показати деталізацію КЕКВ', name: 'ВТЧДетализация' },
  typeVariantKEKV: { label: 'Розписати за', name: 'выбВариантКЕКВ' },
  forYear: { label: 'Річна форма', name: 'ГодоваяФорма' },
  year: { label: 'Рік звіту', name: 'ГодОтчета' },
  refDoc: { label: 'Документ', name: 'ДокументСсылка' },
  byShortKEKV: {
    label: 'Використовувати скорочені КЕКВ для плану використання',
    name: 'ИспользоватьКраткиеКЕКВДляПА',
  },
  notShowSubGroupCode: {
    label: 'Не показувати коди підгруп КЕКВ',
    name: 'НеПоказыватьКодиПодгрупп',
  },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  byDetailsElKEKV: { label: 'п.н. по деталізації', name: 'ПНПоЭлементамКЕКВ' },
  showCSU: { label: 'Показувати розпорядника як', name: 'ПоказыватьРаспКак' },
  byReducedKEKV: { label: 'Розшифрувати КЕКВ', name: 'РасшифровкаСокрКЕКВ' },
  consolidated: { label: 'Зведений', name: 'Сводный' },
  quarter: { label: 'Формувати за', name: 'сзКвартал' },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  notRepeatDetails: {
    label: 'Не показувати деталізацію КЕКВ якщо є тільки один елемент',
    name: 'УбратьДублиДетализацииКЕКВ',
  },
  onlyOnePage: {
    label: 'Вписати в сторінку',
    name: 'УместитьНаСтраницу',
  },
  allKEKV: { label: 'Всі КЕКВ', name: 'флВсеКЕКВ' },
  byPpb: {
    label: 'ПЦМ',
    name: 'флПЦМ',
  },
  widthColumn: {
    label: 'Ширина колонки',
    name: 'ШиринаКолонки',
  },
  hideKEKVgroups: { label: 'Приховати групи КЕКВ', name: 'флСпрятатьПодгруппы' },
  hideEmptyRows: { label: 'Приховати пусті рядки', name: 'флСпрятатьПустыеСтроки' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  datePrint: { label: 'Дата друку', name: 'ДатаПечати' },
  showKPKVAs: { label: 'Показувати КПКВ як', name: 'ПоказыватьКПКВКак' },
  in1000: { label: 'в тыс.грн.', name: 'флТысГрн' },
};

const definition = {
  label: 'План використання (регл.)',
  name: 'PlanOfUse',
  columns,
  frontend: 'rep/PlanOfUse',
  backendName: 'регпланиспользования',
  resultColumns: baseResultColumns,
};

export default definition;
