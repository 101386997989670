import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faWindowClose as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

function CloseButton({
  disabled, onClose, label, changed, onSave,
}) {
  const [opened, setOpened] = useState(false);

  const onButtonClick = useCallback(
    () => {
      if (!changed) return onClose();
      setOpened(true);
      return null;
    },
    [changed, onClose],
  );
  return (
    <>
      <DefaultCPButton
        icon={icon}
        onClick={onButtonClick}
        label={label}
        disabled={disabled}
      />
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Зміни ще не збережені
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Бажаєте зберегти зміни перед закриттям?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              setOpened(false);
              onSave(e);
            }}
          >
            Так
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => {
              setOpened(false);
              onClose(e);
            }}
          >
            Ні
          </Button>
          <Button
            variant="info"
            onClick={(e) => {
              setOpened(false);
            }}
          >
            Відміна
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CloseButton.propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  changed: PropTypes.bool,
  label: PropTypes.string,
};

CloseButton.defaultProps = {
  disabled: false,
  changed: false,
  label: 'Закрити',
};

export default CloseButton;
