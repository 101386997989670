import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'j6KRa-NAZlE',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
  dateStart: { label: 'за період з', name: 'ДатаНач', visible: false },
  dateFin: { label: 'по', name: 'ДатаКон', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  isApproved: { label: 'Затверджено', name: 'ДокументУтвержден', visible: false },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Граничні показники',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
      CSU: { label: 'ГРК', name: 'ГРК' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Граничні показники видатків бюджету та надання кредитів з бюджету головним розпорядникам коштів  (Додаток 6)',
  name: 'forecastAdd6',
  backendName: 'ПрогнозДод6',
  columns,
  frontend: 'doc/forecastAdd6',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
