import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Container } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/countExpensesOnEducation';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import LowerDocs from './tabs/docs';

function CountExpensesOnEducationTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="descendantsDocuments" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.descendantsDocuments.label} eventKey="descendantsDocuments">
        <LowerDocs data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />

        </Container>
      </Tab>
    </Tabs>
  );
}

CountExpensesOnEducationTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default CountExpensesOnEducationTabs;
