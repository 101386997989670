/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from '../lister/printModal';

function APMenu({ id, ...rest }) {
  const selectedRows = useMemo(
    () => [id],
    [id],
  );
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <PrintModal selectedRows={selectedRows} />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
