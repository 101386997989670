const frontendURL = '/srv/roleManagement/';
const backendURL = '/api/auth/role/';
const name = 'Управління Ролями';
const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  backendURL,
  name,
  getVisibility,
};

export default instance;
