import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function OCBMenuItem() {
  return (
    <UseMenuHeader label="Об'єкти капітального будіництва та ремонту">
      <Boxes items={NavPanel.objectsCapitalBuilding.boxes} />
    </UseMenuHeader>
  );
}

export default OCBMenuItem;
