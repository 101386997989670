import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';

import { csu as md } from '../../../../constants/meta/catalogs/CSU';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Limit = {
  first: { label: 'Помісячно', name: 'Помесячно' },
  second: { label: 'Помісячно з наростаючим підсумком', name: 'ПомесячноНакопительный' },
  third: { label: 'Річний', name: 'Годовой' },
  fifth: { label: 'Не перевіряти', name: 'НеПроверять' },
};

const planingKindsOptions = Object.values(Limit).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const radioInputValues = {
  first: { value: 1, label: 'Є юридичною особою' },
  second: { value: 2, label: 'Не є юридичною особою' },
};

function CSU({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  const mainPane = (
    <Container fluid className="border border-top-0">
      <h4 className="mt-2">Зв`язок з КВК, станом на вказану дату</h4>
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Classifier.label}
            value={data[md.columns.Classifier.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Classifier.name]: value,
            })}
            modelType="cat"
            modelName="kvkClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCl.label}
            value={data[md.columns.DateCl.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateCl.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.EDRPOU.label}
            value={data[md.columns.EDRPOU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.EDRPOU.name]: value,
            })}
            maxLength={8}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.StringInput
            label={md.columns.NetworkCode.label}
            value={data[md.columns.NetworkCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NetworkCode.name]: value,
            })}
            maxLength={6}
            readOnly={readOnly}
          />
        </Col>
        <Col _style={{ alignSelf: 'end' }}>
          <EditorControls.RadioInput
            label={md.columns.LegalEntity.label}
            value={data[md.columns.LegalEntity.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LegalEntity.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(radioInputValues).map(({ value, label }) => (
              { value, display_name: label }
            ))}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`regionBudget-${data.id}`}
            label={md.columns.regionBudget.label}
            value={data[md.columns.regionBudget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.regionBudget.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Bank.label}
            value={data[md.columns.Bank.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Bank.name]: value,
            })}
            modelType="cat"
            modelName="banks"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.KOATUU.label}
            value={data[md.columns.KOATUU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KOATUU.name]: value,
            })}
            maxLength={8}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.territory.label}
            value={data[md.columns.territory.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.territory.name]: value,
            })}
            modelType="cat"
            modelName="koatuu"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.territotyKoatuu.label}
            value={data[md.columns.territotyKoatuu.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.territotyKatottg.label}
            value={data[md.columns.territotyKatottg.name]}
            readOnly
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Budget.label}
            value={data[md.columns.Budget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Budget.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const secondPane = (
    <Container fluid className="border border-top-0">
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.StewardGroup.label}
            value={data[md.columns.StewardGroup.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.StewardGroup.name]: value,
            })}
            modelType="cat"
            modelName="stewardGroup"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Увімкнути контроль
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <EditorControls.CheckboxInput
                controlId={`DisableRefinancingCheck-${data.id}`}
                label={md.columns.DisableRefinancingCheck.label}
                value={data[md.columns.DisableRefinancingCheck.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableRefinancingCheck.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`ControlOfReTransferTheApplications-${data.id}`}
                label={md.columns.ControlOfReTransferTheApplications.label}
                value={data[md.columns.ControlOfReTransferTheApplications.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ControlOfReTransferTheApplications.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableRefinancingApplicationsCheck-${data.id}`}
                label={md.columns.DisableRefinancingApplicationsCheck.label}
                value={data[md.columns.DisableRefinancingApplicationsCheck.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableRefinancingApplicationsCheck.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`OnlyApprovedPlan-${data.id}`}
                label={md.columns.OnlyApprovedPlan.label}
                value={data[md.columns.OnlyApprovedPlan.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.OnlyApprovedPlan.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.DateInput
                label={md.columns.ForbidDate.label}
                value={data[md.columns.ForbidDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ForbidDate.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AllowAnaliseOfFreeBudget-${data.id}`}
                label={md.columns.AllowAnaliseOfFreeBudget.label}
                value={data[md.columns.AllowAnaliseOfFreeBudget.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AllowAnaliseOfFreeBudget.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DifferentNumerationInstructionsAndCostsTransfer-${data.id}`}
                label={md.columns.DifferentNumerationInstructionsAndCostsTransfer.label}
                value={data[md.columns.DifferentNumerationInstructionsAndCostsTransfer.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DifferentNumerationInstructionsAndCostsTransfer.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`BalanceSpecialFondForSteward-${data.id}`}
                label={md.columns.BalanceSpecialFondForSteward.label}
                value={data[md.columns.BalanceSpecialFondForSteward.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.BalanceSpecialFondForSteward.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AutoCreateDocForBalance-${data.id}`}
                label={md.columns.AutoCreateDocForBalance.label}
                value={data[md.columns.AutoCreateDocForBalance.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AutoCreateDocForBalance.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`TreasuryAccountsOfHighestLevelStewards-${data.id}`}
                label={md.columns.TreasuryAccountsOfHighestLevelStewards.label}
                value={data[md.columns.TreasuryAccountsOfHighestLevelStewards.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.TreasuryAccountsOfHighestLevelStewards.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AdditionalControlOfTreasuryAccounts-${data.id}`}
                label={md.columns.AdditionalControlOfTreasuryAccounts.label}
                value={data[md.columns.AdditionalControlOfTreasuryAccounts.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AdditionalControlOfTreasuryAccounts.name]: value,
                })}
                readOnly={readOnly}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Деталізація
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <EditorControls.CheckboxInput
                controlId={`DisableSortOfClassifierElementsChose-${data.id}`}
                label={md.columns.DisableSortOfClassifierElementsChose.label}
                value={data[md.columns.DisableSortOfClassifierElementsChose.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableSortOfClassifierElementsChose.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableSelectionOfKFKElementSelection-${data.id}`}
                label={md.columns.DisableSelectionOfKFKElementSelection.label}
                value={data[md.columns.DisableSelectionOfKFKElementSelection.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableSelectionOfKFKElementSelection.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableSelectionOfFondElementSelection-${data.id}`}
                label={md.columns.DisableSelectionOfFondElementSelection.label}
                value={data[md.columns.DisableSelectionOfFondElementSelection.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableSelectionOfFondElementSelection.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableSelectionOfKDBElementSelection-${data.id}`}
                label={md.columns.DisableSelectionOfKDBElementSelection.label}
                value={data[md.columns.DisableSelectionOfKDBElementSelection.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableSelectionOfKDBElementSelection.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableSelectionOfKFBElementSelection-${data.id}`}
                label={md.columns.DisableSelectionOfKFBElementSelection.label}
                value={data[md.columns.DisableSelectionOfKFBElementSelection.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableSelectionOfKFBElementSelection.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`UseSignGroup-${data.id}`}
                label={md.columns.UseSignGroup.label}
                value={data[md.columns.UseSignGroup.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.UseSignGroup.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AccountOfCommonFondByKDB-${data.id}`}
                label={md.columns.AccountOfCommonFondByKDB.label}
                value={data[md.columns.AccountOfCommonFondByKDB.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AccountOfCommonFondByKDB.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.DateInput
                label={md.columns.ForbidDateOfOutcomeProject.label}
                value={data[md.columns.ForbidDateOfOutcomeProject.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ForbidDateOfOutcomeProject.name]: value,
                })}
                readOnly={readOnly}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  const thirdPane = (
    <Container fluid className="border border-top-0">
      <Row className="py-3">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Фінансування
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="py-3">
                <Col>
                  <h4 className="mt-2">Передача коштів</h4>
                  <EditorControls.CheckboxInput
                    controlId={`FinancingFundsTransfer-${data.id}`}
                    label={md.columns.FinancingFundsTransfer.label}
                    value={data[md.columns.FinancingFundsTransfer.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingFundsTransfer.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                  <EditorControls.CheckboxInput
                    controlId={`FinancingIncludingKFK-${data.id}`}
                    label={md.columns.FinancingIncludingKFK.label}
                    value={data[md.columns.FinancingIncludingKFK.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingIncludingKFK.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                  <EditorControls.CheckboxInput
                    controlId={`FinancingIncludingKEKV-${data.id}`}
                    label={md.columns.FinancingIncludingKEKV.label}
                    value={data[md.columns.FinancingIncludingKEKV.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingIncludingKEKV.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`FinancingOrder-${data.id}`}
                    label={md.columns.FinancingOrder.label}
                    value={data[md.columns.FinancingOrder.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingOrder.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                  <EditorControls.CheckboxInput
                    controlId={`FinancingDistribution-${data.id}`}
                    label={md.columns.FinancingDistribution.label}
                    value={data[md.columns.FinancingDistribution.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingDistribution.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                  <EditorControls.CheckboxInput
                    controlId={`FinancingStewardInAHat-${data.id}`}
                    label={md.columns.FinancingStewardInAHat.label}
                    value={data[md.columns.FinancingStewardInAHat.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FinancingStewardInAHat.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row className="py-3">
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`ControlStewardNoteLength-${data.id}`}
                    label={md.columns.ControlStewardNoteLength.label}
                    value={data[md.columns.ControlStewardNoteLength.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.ControlStewardNoteLength.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.NumberInput
                    label={md.columns.NoteLengthFin.label}
                    value={data[md.columns.NoteLengthFin.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.NoteLengthFin.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.NumberInput
                    label={md.columns.NoteLength.label}
                    value={data[md.columns.NoteLength.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.NoteLength.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Пропозиція на фінансування
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <EditorControls.CheckboxInput
                controlId={`UseFinancialPropose-${data.id}`}
                label={md.columns.UseFinancialPropose.label}
                value={data[md.columns.UseFinancialPropose.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.UseFinancialPropose.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`CalculateRemainsOfApplicationByTheOffer-${data.id}`}
                label={md.columns.CalculateRemainsOfApplicationByTheOffer.label}
                value={data[md.columns.CalculateRemainsOfApplicationByTheOffer.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.CalculateRemainsOfApplicationByTheOffer.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`ShowRemainsFinForF5-${data.id}`}
                label={md.columns.ShowRemainsFinForF5.label}
                value={data[md.columns.ShowRemainsFinForF5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ShowRemainsFinForF5.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`PullInDocFinLowerLevel-${data.id}`}
                label={md.columns.PullInDocFinLowerLevel.label}
                value={data[md.columns.PullInDocFinLowerLevel.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PullInDocFinLowerLevel.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`PullInDocFinAppLowerLevel-${data.id}`}
                label={md.columns.PullInDocFinAppLowerLevel.label}
                value={data[md.columns.PullInDocFinAppLowerLevel.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PullInDocFinAppLowerLevel.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AccountFU-${data.id}`}
                label={md.columns.AccountFU.label}
                value={data[md.columns.AccountFU.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AccountFU.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`AccountToTheTable-${data.id}`}
                label={md.columns.AccountToTheTable.label}
                value={data[md.columns.AccountToTheTable.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AccountToTheTable.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`SortByAccountByApplicationsAreLoaded-${data.id}`}
                label={md.columns.SortByAccountByApplicationsAreLoaded.label}
                value={data[md.columns.SortByAccountByApplicationsAreLoaded.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.SortByAccountByApplicationsAreLoaded.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`DisableOrderAccountingNotes-${data.id}`}
                label={md.columns.DisableOrderAccountingNotes.label}
                value={data[md.columns.DisableOrderAccountingNotes.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DisableOrderAccountingNotes.name]: value,
                })}
                readOnly={readOnly}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Ліміти
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <EditorControls.SelectorInput
                    label={md.columns.PlanLimitCheckMethod.label}
                    value={data[md.columns.PlanLimitCheckMethod.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.PlanLimitCheckMethod.name]: value,
                    })}
                    values={planingKindsOptions}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.SelectorInput
                    label={md.columns.FactLimitCheckMethod.label}
                    value={data[md.columns.FactLimitCheckMethod.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.FactLimitCheckMethod.name]: value,
                    })}
                    values={planingKindsOptions}
                    readOnly={readOnly}
                  />
                </Col>
                <Col className="d-flex align-items-end">
                  <EditorControls.CheckboxInput
                    controlId={`NotShowScratchAndFinDuties-${data.id}`}
                    label={md.columns.NotShowScratchAndFinDuties.label}
                    value={data[md.columns.NotShowScratchAndFinDuties.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.NotShowScratchAndFinDuties.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ThisIsMainSteward-${data.id}`}
            label={md.columns.ThisIsMainSteward.label}
            value={data[md.columns.ThisIsMainSteward.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ThisIsMainSteward.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`StewardInAHat-${data.id}`}
            label={md.columns.StewardInAHat.label}
            value={data[md.columns.StewardInAHat.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.StewardInAHat.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CalculationRemainsByFinInstitution-${data.id}`}
            label={md.columns.CalculationRemainsByFinInstitution.label}
            value={data[md.columns.CalculationRemainsByFinInstitution.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CalculationRemainsByFinInstitution.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`UseKievForm-${data.id}`}
            label={md.columns.UseKievForm.label}
            value={data[md.columns.UseKievForm.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UseKievForm.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Prefix.label}
            value={data[md.columns.Prefix.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Prefix.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.StringInput
            label={md.columns.Sort.label}
            value={data[md.columns.Sort.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Sort.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.StewardLevel.label}
            value={data[md.columns.StewardLevel.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.StewardLevel.name]: value,
            })}
            modelType="cat"
            modelName="stewardLevel"
            readOnly={readOnly}
          />
        </Col>
        <Col className="d-flex align-items-end">
          <EditorControls.CheckboxInput
            controlId={`UseKievForm-${data.id}`}
            label={md.columns.AutoFillFinanceDocs.label}
            value={data[md.columns.AutoFillFinanceDocs.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AutoFillFinanceDocs.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.CheckboxInput
            controlId={`RecipientOfBudgetCosts-${data.id}`}
            label={md.columns.RecipientOfBudgetCosts.label}
            value={data[md.columns.RecipientOfBudgetCosts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RecipientOfBudgetCosts.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`AlwaysFormByLevelOfFinInstitution-${data.id}`}
            label={md.columns.AlwaysFormByLevelOfFinInstitution.label}
            value={data[md.columns.AlwaysFormByLevelOfFinInstitution.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AlwaysFormByLevelOfFinInstitution.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`OnlyRegisteredTreasuryAccounts-${data.id}`}
            label={md.columns.OnlyRegisteredTreasuryAccounts.label}
            value={data[md.columns.OnlyRegisteredTreasuryAccounts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.OnlyRegisteredTreasuryAccounts.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`AccountOfMovementOfTreasuryAccounts-${data.id}`}
            label={md.columns.AccountOfMovementOfTreasuryAccounts.label}
            value={data[md.columns.AccountOfMovementOfTreasuryAccounts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccountOfMovementOfTreasuryAccounts.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`PrintFormsMainSteward-${data.id}`}
            label={md.columns.PrintFormsMainSteward.label}
            value={data[md.columns.PrintFormsMainSteward.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PrintFormsMainSteward.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`allowCreatePayment-${data.id}`}
            label={md.columns.allowCreatePayment.label}
            value={data[md.columns.allowCreatePayment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.allowCreatePayment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col className="d-flex align-items-end">
          <EditorControls.CheckboxInput
            controlId={`UseIncomePart-${data.id}`}
            label={md.columns.UseIncomePart.label}
            value={data[md.columns.UseIncomePart.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UseIncomePart.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Finances.label}
            value={data[md.columns.Finances.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Finances.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TreasuryBudgetCode.label}
            value={data[md.columns.TreasuryBudgetCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TreasuryBudgetCode.name]: value,
            })}
            maxLength={3}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Card className="w-100">
          <Card.Header>
            <Card.Title>
              Назви (для друкованих форм асигнувань за установою)
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.CityName.label}
                  value={data[md.columns.CityName.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.CityName.name]: value,
                  })}
                  maxLength={150}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.AreaName.label}
                  value={data[md.columns.AreaName.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AreaName.name]: value,
                  })}
                  maxLength={150}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.RegionName.label}
                  value={data[md.columns.RegionName.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.RegionName.name]: value,
                  })}
                  maxLength={150}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <Row className="py-3">
        <Card className="w-100">
          <Card.Header>
            <Card.Title>
              Об&#8242;єкти капітального будівництва та ремонту
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <EditorControls.SelectorInput
                  label={md.columns.FinLimitCheckMethod.label}
                  value={data[md.columns.FinLimitCheckMethod.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.FinLimitCheckMethod.name]: value,
                  })}
                  values={planingKindsOptions}
                  readOnly={readOnly}
                />
              </Col>
              <Col className="d-flex align-items-end">
                <EditorControls.CheckboxInput
                  controlId={`ObjBuilding-${data.id}`}
                  label={md.columns.ObjBuilding.label}
                  value={data[md.columns.ObjBuilding.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.ObjBuilding.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.NameForPrinting.label}
            value={data[md.columns.NameForPrinting.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NameForPrinting.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.AdressReg.label}
            value={data[md.columns.AdressReg.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AdressReg.name]: value,
            })}
            modelType="cat"
            modelName="districts"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.AdressSt.label}
            value={data[md.columns.AdressSt.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AdressSt.name]: value,
            })}
            modelType="cat"
            modelName="streets"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.AdressHouse.label}
            value={data[md.columns.AdressHouse.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AdressHouse.name]: value,
            })}
            modelType="cat"
            modelName="houses"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.ActivityObjective.label}
            value={data[md.columns.ActivityObjective.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ActivityObjective.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="general">
        <Tab title="Основне" eventKey="general">
          {mainPane}
        </Tab>
        <Tab title="Налаштування доступу" eventKey="access">
          {secondPane}
        </Tab>
        <Tab title="Інше" eventKey="other">
          {thirdPane}
        </Tab>
      </Tabs>
    </Container>
  );
}

CSU.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CSU;
