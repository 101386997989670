import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  EditorSelectorInput,
  EditorStingInput,
  EditorMultiSelectorInput,
  EditorFormatInput,
  EditorCheckboxInput,
} from '../../../basicEditor/editorControls';
import { metaFieldPropType } from '../hooks';
import ChoicesTable from './choices';
import { metaBackends } from '../../../../meta';

const DIGITAL_CTYPES = ['Integer', 'Decimal'];
function MetaFieldProps({
  value, onChange, readOnly, allowedCtypes, allowedFields, allowedFonts, allowedMetaFields,
}) {
  const choicesUsed = !!value.choices;
  const onChangeChoicesUsed = useCallback(
    () => {
      if (choicesUsed) onChange({ choices: null });
      else onChange({ choices: [[null, 'Варіант А']] });
    },
    [choicesUsed, onChange],
  );
  const fieldFields = useMemo(
    () => {
      if (!value || !value.include) return [];
      return value.include.map((v) => ({ value: v, display_name: v }));
    },
    [value],
  );
  const resourceValues = useMemo(
    () => Object.keys(metaBackends).map((k) => ({ value: k, display_name: metaBackends[k].name })),
    [],
  );
  useEffect(() => {
    if (!('filtered' in value)) onChange({ filtered: true });
  }, [onChange, value]);
  return (
    <div className="containerField p-3 border rounded">
      <EditorStingInput
        controlId="metafield_label_input"
        label="Label"
        required
        description="Пользовательское название сущности"
        value={value.label}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ label: v })}
      />
      <EditorMultiSelectorInput
        controlId="metafield_include_input"
        label="Include"
        required
        description="Перечень полей (из раздела fields) которые включает данная сущность. При использовании данного поля в запрос будут включены все поля из include."
        value={value.include}
        values={allowedFields}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ include: v })}
      />
      <EditorSelectorInput
        controlId="metafield_key_input"
        label="Key"
        description="Поле, используемое для сравнения значений. Например для отбора или для вычисления условного оформления ."
        value={value.key}
        values={fieldFields}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ key: v })}
      />
      <EditorCheckboxInput
        label="Разрешить отбор по данному полю"
        description="Если включено, то поле будет присутсвовать в списке доступных полей для отбора"
        value={value.filtered}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ filtered: v })}
      />

      <EditorSelectorInput
        controlId="metafield_ordered_input"
        label="Ordered"
        description="Поле, используемое для сортировки"
        value={value.ordered}
        values={fieldFields}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ ordered: v })}
      />
      <EditorStingInput
        controlId="metafield_display_input"
        label="Display"
        required
        description="Является django- шаблоном для формирования представления значения."
        value={value.display}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ display: v })}
      />
      <EditorSelectorInput
        controlId="metafield_ctype_input"
        label="Ctype"
        description="Тип сущности"
        value={value.ctype}
        values={allowedCtypes}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ ctype: v, negative_red: false })}
      />
      <EditorSelectorInput
        controlId="metafield_resource_input"
        label="Resource"
        required
        description="Url для описания типа внутренней сущности системы. Например '/api/references/refkbp7/'"
        value={value.resource}
        readOnly={readOnly || value.ctype !== 'NestedObject'}
        onChange={(e, v) => onChange({ resource: v })}
        values={resourceValues}
      />
      <EditorCheckboxInput
        controlId="metafield_negative_red_input"
        description="Выделять отрицательные значения красным цветом"
        label="Отрицательное красным"
        value={value.negative_red}
        onChange={(e, v) => onChange({ negative_red: v })}
        disabled={readOnly || !DIGITAL_CTYPES.includes(value.ctype)}
      />
      <EditorFormatInput
        controlId="metafield_format_input"
        label="Оформление поля"
        value={value.format}
        onChange={(v) => onChange({ format: v ? { ...value.format, ...v } : null })}
        allowedFonts={allowedFonts}
        readOnly={readOnly}
      />
      <EditorSelectorInput
        controlId="metafield_parent_input"
        label="Parent"
        description="Батьківське поле"
        value={value.parent}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ parent: v })}
        values={allowedMetaFields}
      />
      <div>
        <EditorCheckboxInput
          label="Використовувати список вибору"
          value={choicesUsed}
          onChange={onChangeChoicesUsed}
          controlId="choices_used_id"
        />
        {choicesUsed && (
          <ChoicesTable
            value={value.choices}
            onChange={(e, v) => onChange({ choices: v })}
            ctype={value.ctype}
          />
        )}
      </div>

    </div>
  );
}

MetaFieldProps.propTypes = {
  value: metaFieldPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  allowedCtypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedMetaFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
};

MetaFieldProps.defaultProps = {
  readOnly: false,
  allowedFonts: [],
};

export default MetaFieldProps;
