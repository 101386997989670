import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  selectPass: { label: 'Папка', name: 'ВыбПуть' },
  fileAddress: { label: 'АдресФайла', name: 'АдресФайла' },
  in866: { label: 'Кодовая сторінка 866', name: 'фл866' },
  fileType: { label: 'Тип файлу для вивантаження', name: 'ТипФайла' },
  netLvl: { label: 'Рівень мережі', name: 'УровеньМережи' },
};

/**
 * @const
 */
const definition = {
  name: 'netDBF',
  backendName: 'ПечатьМережаДБФ',
  label: 'Мережа в ДБФ',
  frontend: 'dp/netDBF',
  columns,
};

export default definition;
