import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  ItemPicker,
  NumberInput,
  StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';

const tablename = 'sourcesInvestmentProjects';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, budget,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const genFond = row[md.tables.sourcesInvestmentProjects.columns.fond.name];

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col lg={1} className="text-center">
            <TableRowCell column="fond" highlighted={highlights.includes('fond')}>
              <CheckboxInput
                id={`sip-${row[tableMD.columns.fond.name]}`}
                value={row[tableMD.columns.fond.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.fond.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={3}>
            <TableRowCell column="targetedPrograms" highlighted={highlights.includes('targetedPrograms')}>
              <ItemPicker
                value={row[tableMD.columns.targetedPrograms.name]}
                modelType="cat"
                modelName="targetedPrograms"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.targetedPrograms.name]: v })}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={4}>
            <TableRowCell column="kdbClassifier" highlighted={highlights.includes('kdbClassifier')}>
              <ItemPicker
                value={row[tableMD.columns.kdbClassifier.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kdbClassifier.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={4}>
            <TableRowCell column="kfbClassifier" highlighted={highlights.includes('kfbClassifier')}>
              <ItemPicker
                value={row[tableMD.columns.kfbClassifier.name]}
                modelType="cat"
                modelName="kfbClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kfbClassifier.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sumResultGenF" highlighted={highlights.includes('sumResultGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumResultGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumResultGenF.name]: v })}
                readOnly={!genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumResultSpecF" highlighted={highlights.includes('sumResultSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumResultSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumResultSpecF.name]: v })}
                readOnly={genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumCashGenF" highlighted={highlights.includes('sumCashGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumCashGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumCashGenF.name]: v })}
                readOnly={!genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumCashSpecF" highlighted={highlights.includes('sumCashSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumCashSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumCashSpecF.name]: v })}
                readOnly={genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPlanGenF" highlighted={highlights.includes('sumPlanGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumPlanGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPlanGenF.name]: v })}
                readOnly={!genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPlanSpecF" highlighted={highlights.includes('sumPlanSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumPlanSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPlanSpecF.name]: v })}
                readOnly={genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactGenF" highlighted={highlights.includes('sumFactGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumFactGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumFactGenF.name]: v })}
                readOnly={!genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactSpecF" highlighted={highlights.includes('sumFactSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumFactSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumFactSpecF.name]: v })}
                readOnly={genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPrognosisGenF" highlighted={highlights.includes('sumPrognosisGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumPrognosisGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPrognosisGenF.name]: v })}
                readOnly={!genFond}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPrognosisSpecF" highlighted={highlights.includes('sumPrognosisSpecF')}>
              <NumberInput
                value={row[tableMD.columns.sumPrognosisSpecF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPrognosisSpecF.name]: v })}
                readOnly={genFond}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sumResultTotal" highlighted={highlights.includes('sumResultTotal')}>
              <NumberInput
                value={(row[tableMD.columns.sumResultGenF.name] || 0)
                + (row[tableMD.columns.sumResultSpecF.name] || 0)}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumResultTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumCashTotal" highlighted={highlights.includes('sumCashTotal')}>
              <NumberInput
                value={(row[tableMD.columns.sumCashSpecF.name] || 0)
                + (row[tableMD.columns.sumCashGenF.name] || 0)}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumCashTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPlanTotal" highlighted={highlights.includes('sumPlanTotal')}>
              <NumberInput
                value={(row[tableMD.columns.sumPlanSpecF.name] || 0)
                + (row[tableMD.columns.sumPlanGenF.name] || 0)}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPlanTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFactTotal" highlighted={highlights.includes('sumFactTotal')}>
              <NumberInput
                value={(row[tableMD.columns.sumFactGenF.name] || 0)
                + (row[tableMD.columns.sumFactSpecF.name] || 0)}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumFactTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPrognosisTotal" highlighted={highlights.includes('sumPrognosisTotal')}>
              <NumberInput
                value={(row[tableMD.columns.sumPrognosisSpecF.name] || 0)
                + (row[tableMD.columns.sumPrognosisGenF.name] || 0)}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumPrognosisTotal.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col lg={4}>
            <TableRowCell column="backgroundTemplate" highlighted={highlights.includes('backgroundTemplate')}>
              <ItemPicker
                value={row[tableMD.columns.backgroundTemplate.name]}
                modelType="cat"
                modelName="explanations"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.backgroundTemplate.name]: v },
                )}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={8}>
            <TableRowCell column="background" highlighted={highlights.includes('background')}>
              <StringInput
                value={row[tableMD.columns.background.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.background.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="explanationDeviation" highlighted={highlights.includes('explanationDeviation')}>
              <EditorControls.TextInput
                value={row[tableMD.columns.explanationDeviation.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.explanationDeviation.name]: v },
                )}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,

  budget: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
