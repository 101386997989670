import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Container, Tab, Tabs,
} from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/kdbClassifer';

function KDBClassifier({
  data,
  actions,
  permissions,
}) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            readOnly={readOnly}
            maxLength={8}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
        <Tab title="Головна" eventKey="general">
          <div className="py-2">
            <h6 className="font-weight-bold text-primary">
              Параметри вiдображення
              у звiтi, по варiантам
              (варiант визначається порядковим номером символа)
            </h6>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.parent.label}
                  value={data[md.columns.parent.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.parent.name]: value,
                  })}
                  modelType="cat"
                  modelName="kdbClassifier"
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <h6 className="font-weight-bold text-primary">
              Код та назва, станом на вказану дату
            </h6>
            <Row>
              <Col>
                <EditorControls.DateInput
                  label={md.columns.DateCl.label}
                  value={data[md.columns.DateCl.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.DateCl.name]: value,
                    });
                    actions.onSR('ПриИзменении_Дата');
                  }}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.OnDate.label}
                  value={data[md.columns.OnDate.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.OnDate.name]: value,
                  })}
                  readOnly={readOnly}
                  maxLength={8}
                />
              </Col>

              <Col>
                <EditorControls.StringInput
                  label={md.columns.Cart.label}
                  value={data[md.columns.Cart.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.Cart.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={2} md={3} sm={4} xs={6}>
                <EditorControls.CheckboxInput
                  controlId={`Transferts-${data.id}`}
                  label={md.columns.Transferts.label}
                  value={data[md.columns.Transferts.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.Transferts.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.FullName.label}
                  value={data[md.columns.FullName.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.FullName.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>

            </Row>
          </div>
        </Tab>
        <Tab title="Додатковi Властивостi" eventKey="addProps" disabled>
          <Container className="bg-light p-5" fluid>
            Незабаром буде
          </Container>
        </Tab>
      </Tabs>
    </Container>
  );
}

KDBClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KDBClassifier;
