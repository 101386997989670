import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/registrationForm';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.participantsGrk;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        md={4}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'CSU'}
          highlighted={highlights.includes('CSU')}
          onToggleHighlght={() => onToggleHighlght('CSU')}
        >
          {meta.columns.CSU.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'EDRPOU'}
          highlighted={highlights.includes('EDRPOU')}
          onToggleHighlght={() => onToggleHighlght('EDRPOU')}
        >
          {meta.columns.EDRPOU.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={1}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'Role'}
          highlighted={highlights.includes('Role')}
          onToggleHighlght={() => onToggleHighlght('Role')}
        >
          {meta.columns.Role.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'kvkClassifier'}
          highlighted={highlights.includes('kvkClassifier')}
          onToggleHighlght={() => onToggleHighlght('kvkClassifier')}
        >
          {meta.columns.kvkClassifier.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={1}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'CodeVV'}
          highlighted={highlights.includes('CodeVV')}
          onToggleHighlght={() => onToggleHighlght('CodeVV')}
        >
          {meta.columns.CodeVV.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'kbpClassifier'}
          highlighted={highlights.includes('kbpClassifier')}
          onToggleHighlght={() => onToggleHighlght('kbpClassifier')}
        >
          {meta.columns.kbpClassifier.label}
        </TableHeaderCell>
      </Col>
      <Col sm={12} className="px-1  d-flex flex-column justify-content-end">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'Name'}
          highlighted={highlights.includes('Name')}
          onToggleHighlght={() => onToggleHighlght('Name')}
        >
          {meta.columns.Name.label}
        </TableHeaderCell>
      </Col>

    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
