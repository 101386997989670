/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Styled from 'styled-components';
import TableRow from './TableRow';

const StyledTh = Styled.th`
      border: 1px solid #dddddd;
`;
const StyledTh5 = Styled.th`
width: 5%;
      border: 1px solid #dddddd;
`;

const StyledTh20 = Styled.th`
width: 20%;
border: 1px solid #dddddd;
`;
function TransfertToTable({ data }) {
  return (
    <Table className="fs--1 mb-0">
      <thead className="bg-200 text-800">
        <tr>
          <StyledTh5 className="text-center" scope="col">Код</StyledTh5>
          <StyledTh className="text-center" scope="col">
            Найменування
          </StyledTh>
          <StyledTh20 className="text-center" scope="col">Усього, тис.грн.</StyledTh20>
        </tr>
      </thead>
      <tbody>
        { data.map((row, index) => (
          <TableRow
            row={row}
            key={`rowTransfertTo-${index}`}
          />
        ))}
      </tbody>
    </Table>
  );
}

TransfertToTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
  })),
};

TransfertToTable.defaultProps = {
  data: [],
};

export default TransfertToTable;
