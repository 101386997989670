import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import { DocumentBody, Footer } from '../../../../components/Containers/docs';
import useEditor from '../../../newEditor/hook/editor';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import useEditorParams from '../../../newEditor/hook/params';
import { App1Rows, App2Rows, LowerDocs } from './tp';
import ApprovedInfo from '../../common/approvedInfo';
import LoadLevelDownButton from './loadLeveldown';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';

const planValues = [
  { value: true, display_name: 'План' },
  { value: false, display_name: 'Факт' },
];
function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    // eslint-disable-next-line no-unused-vars
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.netFop.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantProps = editorHooks.useItemInputProps('authority_sign_variant', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isPlanProps = editorHooks.useSelectorInputProps('is_plan', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const fondTypeProps = editorHooks.useSelectorInputProps('fond_type', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const dataRef = useRef();
  dataRef.current = data;

  const onLoadHandler = useCallback(
    (mode, documents) => {
      if (!mode) {
        actions.onDraft(
          dataRef.current,
          {
            load_level_down_documents: {
              documents,
            },
          },
        );
      } else {
        actions.onDraft(
          dataRef.current,
          {
            load_own_documents: {
              documents,
            },
          },
        );
      }
    },
    [actions],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.netFop.backendURL}
          id={id}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={meta.doc.netFop.backendURL}
            documnetId={data.id}
            disabled={!data.executed || changed}
          />
        </DocCommandPanel>
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid className="p-0">
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...isPlanProps} values={planValues} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...fondTypeProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...signVariantProps} filter={signVariantFilter} />
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.netFop.backendURL}${data.id}/`}
            />
            <LoadLevelDownButton
              authority={data.authority}
              docDate={data.doc_date}
              disabled={!permissions.canChange}
              onLoadHandler={onLoadHandler}
              id={data.id}
            />

          </Container>

        </HidableContainer>
        <LabelTab defaultActiveKey="app1">
          {fields && fields.app1_row_set && (
            <Tab eventKey="app1" title={fields.app1_row_set.label}>
              <TabContainer>
                <App1Rows
                  fields={fields}
                  data={data}
                  onChange={actions.onChange}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.app2_row_set && (
            <Tab eventKey="app2" title={fields.app2_row_set.label}>
              <TabContainer>
                <App2Rows
                  fields={fields}
                  data={data}
                  onChange={actions.onChange}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.lvldown_row_set && (
            <Tab eventKey="lvldown" title={fields.lvldown_row_set.label}>
              <TabContainer>
                <LowerDocs
                  data={data}
                  actions={actions}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>

  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};
export default Editor;
