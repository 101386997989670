import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/sessionAdd1';
import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  TwoColumnsGridContainer, CommandPanelColor,
} from '../../../components/Form/styledForm';
import StyledTPPane from '../../../components/tableParts/TPPane';
import DateInput from '../../../components/fields/dates';

import ReportContainer from '../reportContainer';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const TwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 60% 38%;
  grid-column-gap: 2%;
`;

const MyTwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 68%;
  grid-column-gap: 2%;
`;

class ReportSessionAdd1Editor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    sessionOptions: new Map(),
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm', 'reportEditor', 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const SettingsButton = (
      <div>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('budget', new Map())}
              />
            )}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('FO', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => {
                  dispatch(changeField(['headerForm', 'FO'], v));
                  dispatch(changeField(['headerForm', 'signVariant'], new Map()));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('FO', new Map())}
              />
            )}
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={5}>
          <div>
            <StyledLabel>{md.columns.dateRep.label}</StyledLabel>
            <DateInput
              value={headerForm.get('dateRep', null)}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'dateRep'], value))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.dateRep1.label}</StyledLabel>
            <DateInput
              value={headerForm.get('dateRep1', null)}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'dateRep1'], value))}
            />
          </div>
          <Fields.CheckboxField
            value={headerForm.get('fromVar', true)}
            label={md.columns.fromVar.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'fromVar'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('copecks', true)}
            label={md.columns.copecks.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'copecks'], v))}
          />
          <div>
            <StyledLabel>{md.columns.yearPlan.label}</StyledLabel>
            <Fields.YearField
              value={headerForm.get('yearPlan', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'yearPlan'], v))}
              disableCalculator
            />
          </div>
        </MyContainerFormWithNColumns>
        <TwoColumnsGrid>
          {/* <div> */}
          {/*  <StyledLabel>{md.columns.sessiondesigion.label}</StyledLabel> */}
          {/*  <Fields.SelectorField */}
          {/*    value={headerForm.get('sessiondesigion', new Map())} */}
          {/*    modelType="cat" */}
          {/*    modelName="templateBaseDoc" */}
          {/*    canCleared */}
          {/*    onChange={(e, v) => { */}
          {/*      dispatch(changeField(['headerForm', 'sessiondesigion'], v)); */}
          {/*    }} */}
          {/*  /> */}
          {/* </div> */}
          <div>
            <StyledLabel>{md.columns.progVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('progVariant', new Map())}
              modelType="cat"
              modelName="variantBudgetDraft"
              canCleared
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'progVariant'], v));
              }}
            />
          </div>
          <div>
            <br />
            <Fields.CheckboxField
              value={headerForm.get('flPlaN', true)}
              label={md.columns.flPlaN.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flPlaN'], v))}
            />
          </div>
        </TwoColumnsGrid>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
              noBorder
            />
          </div>
          <div>
            <StyledLabel style={{ marginBottom: '6px' }}>{md.columns.signVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('signVariant', new Map())}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
              // filter={[
              //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
            />
          </div>
        </ContainerDiv>
        <MyTwoColumnsGrid>
          <div>
            <CommandPanelColor
              style={{ width: 'fit-content', alignSelf: 'end' }}
              label="Створити документ відправки в Logica"
              onClick={() => dispatch(processServerReq('UPLOAD_TO_LOGICA'))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sessiondesigionLOGICA.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('sessiondesigionLOGICA', new Map())}
              modelType="cat"
              modelName="resolutionSession"
              canCleared
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sessiondesigionLOGICA'], v));
              }}
            />
          </div>
        </MyTwoColumnsGrid>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportSessionAdd1Editor);
