import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/tasks2019';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { selectionMode } from '../../../../constants/meta/common';

function Tasks2019Editor({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Tasks.label}
            value={data[md.columns.Tasks.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Tasks.name]: value,
            })}
            modelType="cat"
            modelName="tasks"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            readOnly={readOnly}
            maxLength={9}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={90}
          />
        </Col>
      </Row>
      <h6 className="font-weight-bold text-primary">
        Код та назва, станом на вказану дату
      </h6>
      <Row>
        <Col sm={3}>
          <EditorControls.DateInput
            label={md.columns.DateKl.label}
            value={data[md.columns.DateKl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateKl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col sm={2} className="d-flex align-items-end justify-content-center">
          <EditorControls.CheckboxInput
            controlId={`NotActual-${data.id}`}
            label={md.columns.NotActual.label}
            value={data[md.columns.NotActual.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NotActual.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="tasks2019"
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
    </Container>
  );
}

Tasks2019Editor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Tasks2019Editor;
