import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from '../../../../expensesProject/editor/tp/planningRows/propTypes';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  activeCol, highlights, onToggleHighlight, totals, disposerInHeader, fields,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col md={4} className="border-right">
        <Row>
          {!disposerInHeader && (
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
          </Col>
          )}
          <Col md={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7'}
              highlighted={highlights.includes('kbp7')}
              onToggleHighlight={() => onToggleHighlight('kbp7')}
            >
              {fields.kbp7.label}
            </TableHeaderCell>
          </Col>
          <Col md={8}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7_item'}
              highlighted={highlights.includes('kbp7_item')}
              onToggleHighlight={() => onToggleHighlight('kbp7_item')}
            >
              {fields.kbp7_item.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'target_program'}
              highlighted={highlights.includes('target_program')}
              onToggleHighlight={() => onToggleHighlight('target_program')}
            >
              Цільова програма
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col md={8}>
        <Row noGutters>
          <Col md={4} className="border-right">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-uppercase"
                  active={activeCol === 'subm'}
                >
                  Надання коштів
                </TableHeaderCell>
              </Col>
              <Col md={6}>
                <TableHeaderCell
                  title={totals.subm_zag_fund.toLocaleString('uk', moneysStingOptions)}
                  className="text-center text-xl-left"
                  active={activeCol === 'subm_zag_fund'}
                  highlighted={highlights.includes('subm_zag_fund')}
                  onToggleHighlight={() => onToggleHighlight('subm_zag_fund')}
                >
                  {fields.subm_zag_fund.label}
                </TableHeaderCell>
              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>
                    <TableHeaderCell
                      title={totals.subm_spec_fund}
                      className="text-center text-xl-left"
                      active={activeCol === 'subm_spec_fund'}
                      highlighted={highlights.includes('subm_spec_fund')}
                      onToggleHighlight={() => onToggleHighlight('subm_spec_fund')}
                    >
                      {fields.subm_spec_fund.label}
                    </TableHeaderCell>
                  </Col>
                  <Col sm={12}>
                    <TableHeaderCell
                      title={totals.subm_dev_fund}
                      className="text-center text-xl-left"
                      active={activeCol === 'subm_dev_fund'}
                      highlighted={highlights.includes('subm_dev_fund')}
                      onToggleHighlight={() => onToggleHighlight('subm_dev_fund')}
                    >
                      {fields.subm_dev_fund.label}
                    </TableHeaderCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="border-right">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-uppercase"
                  active={activeCol === 'refund'}
                >
                  Повернення коштів
                </TableHeaderCell>
              </Col>
              <Col md={6}>
                <TableHeaderCell
                  title={totals.refund_zag_fund}
                  className="text-center text-xl-left"
                  active={activeCol === 'refund_zag_fund'}
                  highlighted={highlights.includes('refund_zag_fund')}
                  onToggleHighlight={() => onToggleHighlight('refund_zag_fund')}
                >
                  {fields.refund_zag_fund.label}
                </TableHeaderCell>
              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>
                    <TableHeaderCell
                      title={totals.refund_spec_fund}
                      className="text-center text-xl-left"
                      active={activeCol === 'refund_spec_fund'}
                      highlighted={highlights.includes('refund_spec_fund')}
                      onToggleHighlight={() => onToggleHighlight('refund_spec_fund')}
                    >
                      {fields.refund_spec_fund.label}
                    </TableHeaderCell>
                  </Col>
                  <Col sm={12}>
                    <TableHeaderCell
                      title={totals.refund_dev_fund}
                      className="text-center text-xl-left"
                      active={activeCol === 'refund_dev_fund'}
                      highlighted={highlights.includes('refund_dev_fund')}
                      onToggleHighlight={() => onToggleHighlight('refund_dev_fund')}
                    >
                      {fields.subm_dev_fund.label}
                    </TableHeaderCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-uppercase"
                  active={activeCol === 'total'}
                >
                  Кредитування, усього
                </TableHeaderCell>
              </Col>
              <Col md={6}>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'zag_fund'}
                >
                  Заг. фонд
                </TableHeaderCell>
              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>

                    <TableHeaderCell
                      className="text-center text-xl-left"
                      active={activeCol === 'spec_fund'}
                    >
                      Спец. фонд
                    </TableHeaderCell>
                  </Col>
                  <Col sm={12}>

                    <TableHeaderCell
                      className="text-center text-xl-left"
                      active={activeCol === 'dev_fund'}
                    >
                      В т.ч. БР
                    </TableHeaderCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}
Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  disposerInHeader: PropTypes.bool.isRequired,
  totals: PropTypes.shape({
    refund_dev_fund: PropTypes.number,
    refund_spec_fund: PropTypes.number,
    refund_zag_fund: PropTypes.number,
    subm_dev_fund: PropTypes.number,
    subm_spec_fund: PropTypes.number,
    subm_zag_fund: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
