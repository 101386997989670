import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'bBe_LAqpnfc',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодЗатрат', visible: true },
  isApproved: { label: 'Затверджено', name: 'ДокументУтвержден', visible: false },
  isApproved7: { label: 'Затверджено по Додатку 7', name: 'ДокументУтвержденДод7', visible: false },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  invoice7: { label: 'Квитанція по Додатку 7', name: 'КвитанцияДод7', visible: false },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Основной',
    label: 'Граничні показники видатків бюджету за ТПКВК',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ЗФ_2' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ЗФ_1' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ЗФ0' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ЗФ1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ЗФ2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'СФ_2' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'СФ_1' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'СФ0' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'СФ1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'СФ2' },
      incomes_Report: { label: '20__ рік  (звіт)', name: 'Сумма_2' },
      incomes_Approved: { label: '20__ рік  (затверджено)', name: 'Сумма_1' },
      incomes_Plan: { label: '20__ рік  (план)', name: 'Сумма0' },
      incomes_Plan1: { label: '20__ рік  (план)', name: 'Сумма1' },
      incomes_Plan2: { label: '20__ рік  (план)', name: 'Сумма2' },
      KBP: { label: 'Код', name: 'КодКБП' },
      KBPName: { label: 'Найменування', name: 'НаименованиеКБП' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Граничні показники видатків бюджету за ТПКВК (Додаток 7)',
  name: 'forecastAdd7',
  backendName: 'ПрогнозГраничныеПоказателиРасхДод7',
  columns,
  frontend: 'doc/forecastAdd7',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
