import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, Dropdown, DropdownButton, FormControl, InputGroup, Modal,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import { logikaLogout } from '../../../actions/auth';
import Loader from '../../../components/styledWrappedLoader';
import RelatedRegisterTable from './table';
import LogicaPing from '../../Logica/Ping';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import md from '../../../constants/meta/dataprocessors/relatedRegisterInLogica';
import Logica from '../../authentication';

const StyledDiv = styled.div`
margin-bottom: 10px;
background-color: white;
display: flex`;

const selector = (state) => ({
  ticket: state.getIn(['auth', 'logikaTicket'], null),
});

function RelatedRegisterInLogica() {
  const dispatch = useDispatch();
  const { ticket } = useSelector(selector);
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  const [year, setYear] = useState(currentYear);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [displayResults, setDisplayResults] = useState([]);
  const [show, setShow] = useState(!ticket);

  useEffect(
    () => setShow(!ticket),
    [ticket],
  );

  const getResults = useCallback(async (role) => {
    setResults([]);
    setIsLoading(true);
    try {
      const res = await fetch(
        `https://logica.minfin.gov.ua/ws/api/_MINFIN.GETRELATEDREGISTERNEW?Bc=${role}&Year=${year}`,
        { headers: { Ticket: ticket, accept: 'application/json' } },
      );
      if (res.status === 401) {
        dispatch(logikaLogout());
        setIsLoading(false);
        return;
      }
      setError(null);
      const r = await res.json();
      if (r.result.length > 0) {
        setResults(r.result);
        setDisplayResults(r.result.sort((a, b) => a.Kvk - b.Kvk));
      } else { setError('За вказанимим параметрами нічого не знайдено'); }
    } catch (er) {
      setIsLoading(false);
      setError(er.message);
    }
    setIsLoading(false);
  }, [dispatch, ticket, year]);

  const store = useSelector((state) => state);
  const sessionOptions = store.getIn(['auth', 'sessionOptions'], new Map());
  const role = sessionOptions.get('fo_role', '');

  useEffect(
    () => {
      if (ticket) {
        if (role === '01') {
          getResults('02');
        } else getResults(role);
      }
    },
    // eslint-disable-next-line
  [ticket]);

  const onClearErr = useCallback(
    () => {
      setError(null);
    },
    [],
  );

  const handleSetYear = useCallback(async (e) => {
    setYear(e.target.value);
  }, []);

  const onSearch = useCallback((e) => {
    const { value } = e.target;
    setDisplayResults(results.filter((el) => el
      .Kvk.includes(value)));
  }, [results]);

  return (
    <Loader isLoading={isLoading}>

      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />

      {error && (
      <Alert variant="danger" dismissible onClose={onClearErr}>
        <Alert.Heading>
          {error}
        </Alert.Heading>
      </Alert>
      )}

      {!ticket && (
      <Modal
        show={show}
        size="lg"
        onHide={() => {
          console.log(md.frontend);
          setShow(false);
          navigate('/');
          dispatch(dellComponentFromWindowsManager(`/${md.frontend}/`));
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Logica />
        </Modal.Body>
      </Modal>
      )}

      <StyledDiv top="0" zIndex="2">

        <DropdownButton variant="outline-primary" id="dropdown-item-button" title={year} disabled={!logicaPingSuccess} className="me-2">
          <Dropdown.Item as="button" onClick={handleSetYear} value={currentYear - 1}>{currentYear - 1}</Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleSetYear} value={currentYear}>{currentYear}</Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleSetYear} value={currentYear + 1}>{currentYear + 1}</Dropdown.Item>
        </DropdownButton>

        <DropdownButton variant="outline-primary" id="dropdown-item-button" title="Роль учасника" disabled={!ticket || !logicaPingSuccess}>
          {role === '01' && <Dropdown.Item as="button" onClick={(e) => getResults(e.target.value)} value="01">МФО</Dropdown.Item>}
          {(role === '01' || role === '02') && <Dropdown.Item as="button" onClick={(e) => getResults(e.target.value)} value="02">ГРК</Dropdown.Item>}
          {(role === '01' || role === '02' || role === '03') && <Dropdown.Item as="button" onClick={(e) => getResults(e.target.value)} value="03">РК</Dropdown.Item>}
          <Dropdown.Item as="button" onClick={(e) => getResults(e.target.value)} value="04">ВВ</Dropdown.Item>
        </DropdownButton>

        {results.length > 0
          && (
            <InputGroup className="ms-2 mb-3" onChange={onSearch} style={{ width: '400px' }}>

              <InputGroup.Text>Пошук за КВК</InputGroup.Text>

              <FormControl />
            </InputGroup>
          )}
      </StyledDiv>

      {results.length > 0
        && <RelatedRegisterTable data={displayResults} />}
    </Loader>
  );
}

export default RelatedRegisterInLogica;
