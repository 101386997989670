import React from 'react';
import styled from 'styled-components';

export const ArrowUp = styled.div`
  transform: rotate(270deg);
  padding: 7px 5px;
  display: flex;
  width: fit-content;
`;

function ArrowUpIcon({ onClick, ...props }) {
  return (
    <ArrowUp onClick={onClick} {...props}>
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0L16 6L8 12V8H0V4H8V0Z" fill="#4281C9" />
      </svg>
    </ArrowUp>
  );
}

export default ArrowUpIcon;
