import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from 'react-bootstrap';
import {
  TaxZFTP, TaxSFTP, TaxSFvlTP, CreditTP, ExpTP, CreditsTP, DirectsTP, TransInTP, TransOutTP, InvestTP, TasksTP, BrTP, TaxZFTRTP, DocsTP, TaxSFOtherTP, TaxZFCreditTP,
} from './tabs';
import md from '../../../../constants/meta/documents/prepareForecastAdd3';

function PrepareForecastAdd3Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="taxZF" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.taxZF.label} eventKey="taxZF">
        <TaxZFTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.taxZFTR.label} eventKey="taxZFTR">
        <TaxZFTRTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.taxZFCredit.label} eventKey="taxZFCredit">
        <TaxZFCreditTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.taxSF.label} eventKey="taxSF">
        <TaxSFTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.taxSFvl.label} eventKey="taxSFvl">
        <TaxSFvlTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.taxSFOther.label} eventKey="taxSFOther">
        <TaxSFOtherTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.credit.label} eventKey="credit">
        <CreditTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.br.label} eventKey="br">
        <BrTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.exp.label} eventKey="exp">
        <ExpTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.credits.label} eventKey="credits">
        <CreditsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.directs.label} eventKey="directs">
        <DirectsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.transIn.label} eventKey="transIn">
        <TransInTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.transOut.label} eventKey="transOut">
        <TransOutTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.invest.label} eventKey="invest">
        <InvestTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.tasks.label} eventKey="tasks">
        <TasksTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.docs.label} eventKey="docs">
        <DocsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      {/* <Tab title={md.tables.bases.label} eventKey="bases"> */}
      {/*   <BasesTP data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
    </Tabs>
  );
}

PrepareForecastAdd3Tabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default PrepareForecastAdd3Tabs;
