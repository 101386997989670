import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import FilterItem from './filterItem';
import { StyledSegment, StyledList } from '../StyledComponents';

function FilterView({
  availableFields, data, selectHandler, deleteHandler, toggleUseHandler, changeItem, filterChoice,
}) {
  const items = useMemo(
    () => {
      const getItems = (parent = null) => {
        const showItems = data.filter((v) => v.get('Parent') === parent);
        return showItems.reduce((R, item, k) => {
          const childrenItems = getItems(k);
          const path = item.get('LeftValue', '').split('.');
          // eslint-disable-next-line no-confusing-arrow
          const newPath = path.reduce((R2, p, i) => R2.length ? [
            ...R2,
            'Items',
            `${path.slice(0, i).join('.')}.${p}`,
          ] : [p], []);
          const availableField = availableFields.getIn(newPath);
          if (!availableField) return R;
          return [
            ...R,
            {
              item,
              id: k,
              childrenItems,
              availableField,
            },
            // eslint-disable-next-line react/no-array-index-key

          ];
        }, []);
      };
      return getItems();
    },
    [availableFields, data],
  );

  return (
    <StyledSegment onClick={selectHandler}>
      <Droppable droppableId="DC.Filter">
        {(DropProvided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StyledList ref={DropProvided.innerRef} {...DropProvided.droppableProps}>
            {items.map(({
              item, id, childrenItems, availableField,
            }) => (
              <Draggable key={id} draggableId={id} index={item.get('Index', 0)}>
                {(DragProvided) => (
                  <FilterItem
                    ref={DragProvided.innerRef}
                    item={item}
                    id={id}
                    availableField={availableField}
                    toggleUseHandler={toggleUseHandler}
                    onClickHandler={selectHandler}
                    onDoubleClickHandler={deleteHandler}
                    onChangeItem={changeItem}
                    dragHandleProps={DragProvided.dragHandleProps}
                    draggableProps={DragProvided.draggableProps}
                    filterChoice={filterChoice}
                  >
                    {childrenItems}
                  </FilterItem>
                )}
              </Draggable>

            ))}
            {DropProvided.placeholder}
          </StyledList>
        )}
      </Droppable>
    </StyledSegment>
  );
}

FilterView.propTypes = {
  availableFields: PropTypes.instanceOf(OrderedMap).isRequired,
  data: PropTypes.instanceOf(OrderedMap).isRequired,
  selectHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  toggleUseHandler: PropTypes.func,
  changeItem: PropTypes.func,
  filterChoice: PropTypes.instanceOf(Map),
};

FilterView.defaultProps = {
  selectHandler: () => null,
  deleteHandler: () => null,
  toggleUseHandler: () => null,
  changeItem: () => null,
  filterChoice: new Map(),
};

export default memo(FilterView);
