/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function ComparationRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const tableName = 'allocationplanrow_set';
  const {
    tableData, tableActions, pinHeader,
    tableContainerRef, tableReadOnlyFields, tableErrors,
    tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'forecastprepareadd2comparation_set', readOnlyFields, errors, fields,
  });
  // Необходимые данные из шапки документа
  const {
    year, use_pcm_dictionary: usePCM, budget,
  } = data;

  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          year={year}
          usePCM={usePCM}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            tableName={tableName}
            onMoveRow={tableActions.onMoveRow}
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
             //
              budget={budget}
              usePCM={usePCM}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </TableContainer>
  );
}

ComparationRows.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    use_pcm_dictionary: PropTypes.bool,
    budget: foreignPropType,
    forecastprepareadd2comparation_set: dataPropTypes,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    forecastprepareadd2comparation_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    forecastprepareadd2comparation_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    forecastprepareadd2comparation_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

ComparationRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default ComparationRows;
