import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, Button,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { CloseAccs, IncomeRows } from './tp';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.fundsReciept.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const fondProps = editorHooks.useItemInputProps('fond', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disableControlProps = editorHooks.useItemInputProps('disable_control', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const accClosingProps = editorHooks.useItemInputProps('account_closing', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          backendURL={meta.doc.fundsReciept.backendURL}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.CheckboxInput {...disableControlProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...accClosingProps} />
              </Col>
            </Row>
            {data.account_closing && (
            <Button
              variant="outline-success"
              onClick={() => actions.onDraft(
                data,
                {
                  fill_account_closing: true,
                },
              )}
            >
              Заповнити залишками
            </Button>
            )}
          </Container>
        </HidableContainer>

        <LabelTab activeKey="#">
          {fields && fields.fundsreceiptsrow_set && !data.account_closing && (
            <Tab eventKey="#" title={fields.fundsreceiptsrow_set.label}>
              <TabContainer>
                <IncomeRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}

          {fields && fields.fundsreceiptscloseaccrow_set && data.account_closing && (
            <Tab eventKey="#" title={fields.fundsreceiptscloseaccrow_set.label}>
              <TabContainer>
                <CloseAccs
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        readOnly={!permissions.canChange}
        onChange={actions.onChange}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
