import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';

import { connect } from 'react-redux';
import meta from '../../constants/meta';
import { DateFieldWithModal } from '../../components/fields';
import actions from '../../actions/lister';
import {
  NewIcon, EditIcon, CopyIcon, RefreshIcon,
} from '../../assets/icons';
import CommandPanelButton from '../../components/button/commandPanelButton';
import RemoveIcon from '../../assets/icons/remove';
import Question from '../../components/question';

const ContainerDiv = styled.div`
 display: flex;
`;

class SelectorCP extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
    modelName: PropTypes.oneOf([
      ...new Set(Object.keys(meta)
        .reduce(
          (r, typeName) => [...r, ...Object.keys(meta[typeName])],
          [],
        )),
    ]).isRequired,
    items: PropTypes.instanceOf(OrderedMap).isRequired,
    period: PropTypes.instanceOf(Map).isRequired,
  };

  editItem = () => {
    const { items, modelType, modelName } = this.props;

    const idActiveItem = items.filter((row) => row.get('ACTIVE', false)).first().get('id', '');

    window.open(`${window.location.origin}#/${modelType}/${modelName}/${idActiveItem}/`, '_blank');
  };

  copyItem = () => {
    const { items, modelType, modelName } = this.props;

    const idActiveItem = items.filter((row) => row.get('ACTIVE', false)).first().get('id', '');

    window.open(`${window.location.origin}#/${modelType}/${modelName}/create/?copyFrom=${idActiveItem}/`, '_blank');
  };

  render() {
    const {
      period, modelType, modelName, dispatch,
    } = this.props;

    const a = actions.selector[modelType][modelName];

    return (
      <ContainerDiv>
        <CommandPanelButton
          text="Створити"
          onClick={() => {
            window.open(`${window.location.origin}#/${modelType}/${modelName}/create/`, '_blank');
          }}
        >
          <NewIcon />
        </CommandPanelButton>

        <CommandPanelButton
          text="Копіювати"
          onClick={() => this.copyItem()}
        >
          <CopyIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text="Редагувати"
          onClick={() => this.editItem()}
        >
          <EditIcon />
        </CommandPanelButton>
        <Question
          triggerButton={(
            <CommandPanelButton
              text="Видалити"
            >
              <RemoveIcon />
            </CommandPanelButton>
          )}
          header="Видалення"
          questionText="Ви дійсно бажаєте видалити цей елемент або елементи?"
          answers={[
            { value: 'YES', label: 'Так' },
            { value: 'NO', label: 'Ні' },
          ]}
          onActionClick={(e, v) => {
            if (v === 'YES') dispatch(a.remove());
          }}
        />
        <CommandPanelButton
          text="Оновити"
          onClick={() => {
            dispatch(a.load());
          }}
        >
          <img src={RefreshIcon} alt="Оновити" />
        </CommandPanelButton>
        {(modelType === 'doc')
          && (
            <DateFieldWithModal
              value={period}
              onChange={(e, v) => dispatch(a.setPeriod(v))}
            />
          )}
      </ContainerDiv>
    );
  }
}

const mapState = (store, ownProps) => {
  const STORE_PATH = `${ownProps.modelType}/${ownProps.modelName}/selector`;
  return {
    period: store.getIn([STORE_PATH, 'period'], new Map()),
    items: store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap()),
  };
};

const enhance = compose(connect(mapState));

export default enhance(SelectorCP);
