import React, { useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { appearanceTypes } from './constants';
import { StyledField } from '../styled';
import Modal from '../../../../../components/modal';
import ATable from './editor';

function AppearanceField({ value, onChange }) {
  const [opened, setOpened] = useState(false);
  const displayValue = useMemo(
    () => Object.keys(appearanceTypes)
      .filter((k) => value.has(k) && value.getIn([k, 'Use']))
      .reduce((R, k) => [...R, appearanceTypes[k].label], []).join(', '),
    [value],
  );
  return (
    <>
      <StyledField>
        <div>{displayValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Умова" closeIcon opened={opened} onClose={() => setOpened(false)} size="middle">
        <ATable
          value={value}
          onChange={onChange}
        />
      </Modal>
    </>
  );
}

AppearanceField.propTypes = {
  value: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
};

AppearanceField.defaultProps = {
  value: new Map(),
};

export default memo(AppearanceField);
