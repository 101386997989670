/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyledTableHeaderCell } from '../tablePart/styles';

function Header({
  headerTemplate, highlights, onToggleHighlight, activeCol, tableFields,
}) {
  // const rowSpan = headerTemplate.length;

  // const numericHeader = headerTemplate.map((el, idx) => {
  //   if (idx === 0) {
  //     return [
  //       {
  //         name: '№',
  //         props: { rowSpan, style: { padding: '5px', width: '0' } },
  //       },
  //       ...el,
  //     ];
  //   }
  //   return el;
  // });

  return (
    <thead>
      {headerTemplate.map((row, idxTr) => (
        <tr key={`numericTrHeader-${idxTr}`}>
          {(idxTr === 0) && (
          <th
            key="numericThHeader-number"
            rowSpan={headerTemplate.length}
            style={{ padding: '5px', width: '0' }}
          >
            №
          </th>
          )}
          {row.map((cell, idx) => (
            <th
              className={classNames(
                'p-1 gap-1',
                highlights.includes(cell.name) ? 'bg-soft-warning' : '',
              )}
              onClick={() => onToggleHighlight(cell.name)}
              key={`numericThHeader-${idx}`}
              {...cell.props}
            >
              <StyledTableHeaderCell active={activeCol === cell.name}>
                {tableFields && tableFields[cell.name]?.label}
              </StyledTableHeaderCell>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

Header.propTypes = {
  headerTemplate: PropTypes.arrayOf(PropTypes.shape).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onToggleHighlight: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  tableFields: PropTypes.shape().isRequired,
};

Header.defaultProps = {
  highlights: '',
  activeCol: '',
};

export default memo(Header);
