import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, StringInput, SelectorInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/registrationForm';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const ROLE_VALUES = [{
  display_name: 'МФО',
  value: '01',
},
{
  display_name: 'ГРК',
  value: '02',
},
{
  display_name: 'РК',
  value: '03',
},
{
  display_name: 'ВВ',
  value: '04',
},
{
  display_name: 'ОК',
  value: '05',
},
];
function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, FI, isMFO,
}) {
  const tableMeta = isMFO ? md.tables.participantsMfo : md.tables.participantsGrk;

  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMeta.columns.CSU.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex, tableMeta.columns.CSU.name],
  );
  const onKBPhange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMeta.columns.kbpClassifier.name]: v });
      await onSR('CHANGE_KBP_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex, tableMeta.columns.kbpClassifier.name],
  );
  const onKVKhange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMeta.columns.kvkClassifier.name]: v });
      await onSR('CHANGE_KVK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex, tableMeta.columns.kvkClassifier.name],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        md={4}
        sm={12}
      >
        <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
          <ItemPicker
            value={row[tableMeta.columns.CSU.name]}
            modelType="cat"
            modelName="csu"
            onChange={onCSUChange}
            filter={CSUFIlter}
            noHierarchy
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col
        md={2}
        sm={12}
      >
        <TableRowCell column="EDRPOU" highlighted={highlights.includes('EDRPOU')}>
          <StringInput
            value={row[tableMeta.columns.EDRPOU.name]}
            maxLength={8}
            onChange={(e, v) => onRowChange(e, { [tableMeta.columns.EDRPOU.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col
        md={1}
        sm={12}
      >
        <TableRowCell column="Role" highlighted={highlights.includes('Role')}>
          <SelectorInput
            value={row[tableMeta.columns.Role.name]}
            onChange={(e, v) => onRowChange(e, { [tableMeta.columns.Role.name]: v })}
            readOnly={readOnly}
            values={ROLE_VALUES}
          />
        </TableRowCell>
      </Col>
      <Col
        md={2}
        sm={12}
      >
        <TableRowCell column="kvkClassifier" highlighted={highlights.includes('kvkClassifier')}>
          <ItemPicker
            value={row[tableMeta.columns.kvkClassifier.name]}
            modelType="cat"
            modelName="kvkClassifier"
            onChange={onKVKhange}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {row[tableMeta.columns.KVK.name]}
              </InputGroup.Text>

            )}
          />
        </TableRowCell>
      </Col>
      <Col
        md={1}
        sm={12}
      >
        <TableRowCell column="CodeVV" highlighted={highlights.includes('CodeVV')}>
          <StringInput
            value={row[tableMeta.columns.CodeVV.name]}
            maxLength={1}
            onChange={(e, v) => onRowChange(e, { [tableMeta.columns.CodeVV.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col
        md={2}
        sm={12}
      >
        <TableRowCell column="kbpClassifier" highlighted={highlights.includes('kbpClassifier')}>
          <ItemPicker
            value={row[tableMeta.columns.kbpClassifier.name]}
            modelType="cat"
            modelName="kbpClassifier"
            onChange={onKBPhange}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {row[tableMeta.columns.KBP.name]}
              </InputGroup.Text>

            )}
          />
        </TableRowCell>
      </Col>
      <Col
        sm={12}
      >
        <TableRowCell column="Name" highlighted={highlights.includes('Name')}>
          <StringInput
            value={row[tableMeta.columns.Name.name]}
            onChange={(e, v) => onRowChange(e, { [tableMeta.columns.Name.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType.isRequired,
  isMFO: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
