import React from 'react';
import PropTypes from 'prop-types';
import { faPaperclip as icon } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FilesButton({
  onClick, disabled, countFiles,
}) {
  return (
    <ButtonGroup>
      <Button
        variant="primary"
        onClick={onClick}
        disabled={disabled}
        size="sm"
      >
        <FontAwesomeIcon icon={icon} />
        {' '}
        <Badge className="badge bg-warning">
          {countFiles}
        </Badge>
      </Button>

    </ButtonGroup>
  );
}

FilesButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  countFiles: PropTypes.number,
};

FilesButton.defaultProps = {
  disabled: false,
  onClick: null,
  countFiles: null,
};

export default FilesButton;
