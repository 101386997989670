import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/limitReference';
import { moneysStingOptions, months } from '../../../../../../constants/common';

const meta = md.tables.general;
const QUARTERS = [...Array(4)].map((_, i) => i);

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, inBR, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSU'}
              highlighted={highlights.includes('CSU')}
              onToggleHighlght={() => onToggleHighlght('CSU')}
            >
              {meta.columns.CSU.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKVCode'}
              highlighted={highlights.includes('KPKVCode')}
              onToggleHighlght={() => onToggleHighlght('KPKVCode')}
            >
              {meta.columns.KPKVCode.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col xl={8} sm={12} className="px-1">
        <Row xl={5} noGutters>
          {QUARTERS.map((q) => (
            <Col md={3} key={q} className="px-1 border-right">
              {[...Array(3)].map((_, m) => (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col className="text-center">
                    <TableHeaderCell
                      active={activeCol === `Sum${q * 3 + m + 1}`}
                      title={totals[`Sum${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}
                      onToggleHighlght={() => onToggleHighlght(`Sum${q * 3 + m + 1}`)}
                    >
                      {months[q * 3 + m]}
                    </TableHeaderCell>
                  </Col>
                  {inBR && (
                  <Col className="text-center font-italic">
                    <TableHeaderCell
                      active={activeCol === `SumBR${q * 3 + m + 1}`}
                      title={totals[`SumBR${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}
                      onToggleHighlght={() => onToggleHighlght(`SumBR${q * 3 + m + 1}`)}
                    >
                      В т.ч. БР
                    </TableHeaderCell>
                  </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Sum'}
              highlighted={highlights.includes('Sum')}
              onToggleHighlght={() => onToggleHighlght('Sum')}
            >
              {meta.columns.Sum.label}
            </TableHeaderCell>
            {inBR && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumBR'}
              highlighted={highlights.includes('SumBR')}
              onToggleHighlght={() => onToggleHighlght('SumBR')}
            >
              {meta.columns.SumBR.label}
            </TableHeaderCell>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  inBR: PropTypes.bool.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
