const frontendURL = '/rep/finanalisys/';
const backendURL = '/api/financials/printform/finanalisys/';
const name = 'Аналіз фінансування';
const sections = ['executing'];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
