const frontendURL = '/srv/stepByStep/';
const name = 'Крок з кроком';

const instance = {
  frontendURL,
  name,
  leftToolBar: true,
};

export default instance;
