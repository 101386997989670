import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Transferts: { label: 'Трансферт', name: 'Трансферт', visible: false },
  HideView: {
    label: 'Скрыть Отображение',
    name: 'СкрытьОтображение',
    visible: false,
  },
  OnDate: { label: 'Код на дату', name: 'тКод', visible: true },
  DateCl: { label: 'Дата', name: 'ДатаКл', visible: true },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  Cart: { label: 'Кошик на дату', name: 'Корзина', visible: true },
  FundsType: { label: 'Вид Средств', name: 'ВидСредств', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  ListPeriodsOfPeriods: {
    label: 'Список Периодика Период',
    name: 'СписокПериодикаПериод',
    visible: true,
  },
  ListPeriodFullName: {
    label: 'Список Периодика Классификатор',
    name: 'СписокПериодикаКлассификатор',
    visible: true,
  },
  ListPeriodTrashCan: {
    label: 'Список Периодика Корзина',
    name: 'СписокПериодикаКорзина',
    visible: true,
  },
  ListPeriodInactive: {
    label: 'Список Периодика Не Действующий',
    name: 'СписокПериодикаНеДействующий',
    visible: true,
  },
  ListPeriodCode: {
    label: 'Список Периодика Код',
    name: 'СписокПериодикаКод',
    visible: true,
  },
};

const footer = { ...columns };

export const kdbClassifier = {
  label: 'Класифiкацiя доходiв(КДБ)',
  name: 'kdbClassifier',
  backendName: 'КлассификаторКДБ',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  footer,
  frontend: 'cat/kdbClassifier',
  listColumns: [...new Set([...reqListerColumns, 'FundsType', 'FullName'])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kdbClassifier;
