import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.generalTPInd;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicators'}
              highlighted={highlights.includes('indicators')}
              onToggleHighlght={() => onToggleHighlght('indicators')}
            >
              {meta.columns.indicators.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'typeIndicators'}
              highlighted={highlights.includes('typeIndicators')}
              onToggleHighlght={() => onToggleHighlght('typeIndicators')}
            >
              {meta.columns.typeIndicators.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tasks'}
              highlighted={highlights.includes('tasks')}
              onToggleHighlght={() => onToggleHighlght('tasks')}
            >
              {meta.columns.tasks.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'units'}
              highlighted={highlights.includes('units')}
              onToggleHighlght={() => onToggleHighlght('units')}
            >
              {meta.columns.units.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanGenF'}
              highlighted={highlights.includes('sumPlanGenF')}
              onToggleHighlght={() => onToggleHighlght('sumPlanGenF')}
            >
              {meta.columns.sumPlanGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanSpecF'}
              highlighted={highlights.includes('sumPlanSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumPlanSpecF')}
            >
              {meta.columns.sumPlanSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactGenF'}
              highlighted={highlights.includes('sumFactGenF')}
              onToggleHighlght={() => onToggleHighlght('sumFactGenF')}
            >
              {meta.columns.sumFactGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactSpecF'}
              highlighted={highlights.includes('sumFactSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumFactSpecF')}
            >
              {meta.columns.sumFactSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationGenF'}
              highlighted={highlights.includes('sumDeviationGenF')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationGenF')}
            >
              {meta.columns.sumDeviationGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationSpecF'}
              highlighted={highlights.includes('sumDeviationSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationSpecF')}
            >
              {meta.columns.sumDeviationSpecF.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanTotal'}
              highlighted={highlights.includes('sumPlanTotal')}
              onToggleHighlght={() => onToggleHighlght('sumPlanTotal')}
            >
              {meta.columns.sumPlanTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactTotal'}
              highlighted={highlights.includes('sumFactTotal')}
              onToggleHighlght={() => onToggleHighlght('sumFactTotal')}
            >
              {meta.columns.sumFactTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationTotal'}
              highlighted={highlights.includes('sumDeviationTotal')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationTotal')}
            >
              {meta.columns.sumDeviationTotal.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
