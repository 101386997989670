import React from 'react';
import PropTypes from 'prop-types';

import FinancingTP from './tabs';

function ForecastAdd3Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <FinancingTP data={data} actions={actions} readOnly={readOnly} />
  );
}

ForecastAdd3Tabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default ForecastAdd3Tabs;
