import React from 'react';

function Document() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 5.5L12.5 2.5H4.5V17.5H15.5V5.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 6H12.5V3" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 5.5H9.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 7.5H10.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 11.5H13.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 13.5H9.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 9.5H13.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Document;
