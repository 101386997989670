import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'Период' },
  kind: { label: 'Вид звіту', name: 'ВидОтчета' },
};

const definition = {
  label: 'Анализ роспису доходів (у розрізі бюджетів)',
  name: 'RegionRevenuePlanAnalysis',
  columns,
  frontend: 'rep/RegionRevenuePlanAnalysis',
  backendName: 'АнализРосписиДоходовОбласногоБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
