/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef,
    tableReadOnlyFields, tableErrors, tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'cityplanbalancesonaccountsrow_set', readOnlyFields, errors, fields,
  });

  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            onMoveRow={tableActions.onMoveRow}
            tableName="cityplanbalancesonaccountsrow_set"
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </TableContainer>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    onlyYear: PropTypes.bool,
    vt4br: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: foreignPropType,
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    showKDBKFB: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    allocationplanrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
