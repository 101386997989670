import React, {
  Suspense, useMemo, useState,
} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { compose } from 'redux';

import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.taxesPrint;

const Taxes2PPrintEditor = () => {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  return (
    <MainContainer>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flex: '1 1 auto', marginRight: '20px' }}>
          <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
          <Fields.SelectorField
            modelType="cat"
            modelName="signatureVariants"
            value={headerForm.get('SignVariant', new Map())}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
          />
        </div>
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal
          styled={settingsOpened ? { transition: '3s' } : { transition: '3s' }}
          visible={settingsOpened}
          animation="overlay"
          width="very wide"
          direction="top"
          tertiary
          color="blue"
          basic
        >
          <div>
            <ContainerThreeColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
            </ContainerThreeColumn>
          </div>
          <div>
            <ContainerThreeColumn>
              <div>
                <StyledLabel>{md.columns.FO.label}</StyledLabel>
                <Fields.SelectorField
                  modelType="cat"
                  modelName="csu"
                  value={headerForm.get('FO', new Map())}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FO'], v))}
                  filter={[
                    { fieldName: 'Бюджет', value: headerForm.get('Budget', new Map()) },
                  ]}
                />
              </div>
            </ContainerThreeColumn>
          </div>
        </FramePrintModal>
      )
        : (
          <Suspense fallback={<div>Завантаження...</div>}>
            <TableEditor mountNode={resultMountNode} />
          </Suspense>
        )}
    </MainContainer>
  );
};

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(Taxes2PPrintEditor);
