// Ключи в разрезе которых считаются остатки
export const BALANCE_KEYS = ['CSU', 'elementKFK', 'elementKEKV'];

export const NumberOfMounth = {
  January: { value: 1, display_name: '01(січень)' },
  February: { value: 2, display_name: '02(лютий)' },
  March: { value: 3, display_name: '03(березень)' },
  April: { value: 4, display_name: '04(квітень)' },
  May: { value: 5, display_name: '05(травень)' },
  June: { value: 6, display_name: '06(червень)' },
  July: { value: 7, display_name: '07(липень)' },
  August: { value: 8, display_name: '08(серпень)' },
  September: { value: 9, display_name: '09(вересень)' },
  October: { value: 10, display_name: '10(жовтень)' },
  November: { value: 11, display_name: '11(листопад)' },
  December: { value: 12, display_name: '12(грудень)' },
};
