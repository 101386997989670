import PropTypes from 'prop-types';
import { getFieldsPropTypes } from '../../../../basicEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  id: PropTypes.number,
  header: PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }),
  disposer: foreignPropType,
  kbp7_item: foreignPropType,
  kekv_item: foreignPropType,
  amount_total: PropTypes.number,
};

export const fieldsPropTypes = PropTypes.shape(
  getFieldsPropTypes(dataPropTypes),
);
