import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import api from '../../../../api/req';
import checkTask from '../../../../api/checktask';
import { CiatAppContext } from '../../../../providers';

const useFetchRegionalProgramData = ({ cDt }) => {
  const [expensesProject, setExpensesProject] = useState({
    zf: { total: 0, percent: 0 },
    sf: { total: 0, percent: 0 },
    br: { total: 0, percent: 0 },
  });
  const [projectTarget, setProjectTarget] = useState([]);
  const [projectPlan, setProjectPlan] = useState([]);
  const [err, setErr] = useState(null);
  const { auth } = useContext(CiatAppContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllData = useCallback(() => {
    const loadExpensesProject = async () => {
      const r = await api.get(`/api/dashboards/expenses_project_by_fond/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadProjectTarget = async () => {
      const r = await api.get(`/api/dashboards/expenses_project_by_target_program/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadProjectPlan = async () => {
      const r = await api.get(`/api/dashboards/expenses_project_plan_fact/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    setErr(null);
    setIsLoading(true);

    Promise.all([
      loadExpensesProject(),
      loadProjectTarget(),
      loadProjectPlan(),
    ])
      .then(([expensesProjectResult, projectTargetResult, projectPlanResult]) => {
        setExpensesProject(expensesProjectResult.result);
        setProjectTarget(projectTargetResult.result);
        setProjectPlan(projectPlanResult.result);
      })
      .catch((error) => setErr(error.message))
      .finally(() => setIsLoading(false));
  }, [auth, cDt]);

  useEffect(
    () => {
      fetchAllData();
    },
    [fetchAllData],
  );

  return {
    expensesProject, projectTarget, projectPlan, err, isLoading,
  };
};

export default useFetchRegionalProgramData;
