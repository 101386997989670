import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import ElementCommandPanel from './commandpanel';
import ElementListerSelector from './lister_selector';
import { modelType, modelName } from '../def';
import KFKVKRow from './row';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({
  CommandPanel: ElementCommandPanel,
  RowComponent: KFKVKRow,
}));

function ElementLister(props) {
  return (
    <ElementListerSelector {...props}>
      <StdLister />
    </ElementListerSelector>
  );
}

export default ElementLister;
