import React, {
  forwardRef, memo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './headerResults';
import Row from './rowResults';
import { useReportResult } from './hooks';

const StyleTable = styled.table`
  border-collapse: collapse;
  td, th { 
    border: 1px solid var(--backgroundButton); 
    padding: .25rem .5rem;
  }
  
  th { position: sticky;
    top: 0;
    z-index: 3; 
  };
  @media print {
    position: inherit;
  }
 
    ${({ addStyles }) => addStyles}
        
`;

const Results = forwardRef(({ result, caption, onContextMenu }, ref) => {
  const {
    header, body, footer,
    groupRows, rowLevels, closedRowGroups, onToggleRowGroup, invisibleRows, onRowLevelToggle,
  } = useReportResult(result);
  return (
    <div>
      <div id="print-area-rep" ref={ref}>
        {caption && (
          <h3>{caption}</h3>
        )}
        <StyleTable
          addStyles={Object.keys(result.styles).map((s) => `.${s}  { ${result.styles[s]}}`)}
        >
          <Header
            lines={header}
            rowLevels={rowLevels}
            onRowLevelToggle={onRowLevelToggle}
          />
          <tfoot>
            {footer.map((row) => (
              <tr key={row[0].row_num}>
                <td className="d-print-none">&nbsp;</td>
                {row.map((cell) => (
                  <td
                    key={`row-${cell.row_num} col-${cell.col_num}`}
                    colSpan={cell.colspan}
                    rowSpan={cell.rowspan}
                    className={`FooterOfTable ${cell.className}`}
                  >
                    {cell.repr}
                  </td>

                ))}
              </tr>
            ))}
          </tfoot>
          <tbody>
            {body.map((cells) => (
              <Row
                key={cells[0].row_num}
                cells={cells}
                visible={!invisibleRows.includes(cells[0].row_num)}
                expanded={!closedRowGroups.includes(cells[0].row_num)}
                onExpandedToggle={onToggleRowGroup}
                onContextMenu={onContextMenu}
                isGroup={!!groupRows[cells[0].row_num]}
              />
            ))}

          </tbody>
        </StyleTable>
      </div>
    </div>
  );
});

Results.propTypes = {
  result: PropTypes.shape({
    meta: PropTypes.shape(),
    data: PropTypes.shape(),
    styles: PropTypes.shape(),
  }),
  caption: PropTypes.string,
  onContextMenu: PropTypes.func,
};

Results.defaultProps = {
  result: null,
  caption: null,
  onContextMenu: () => null,
};

export default memo(Results);
