import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import BasicEditor, { DCControls, mapState } from '../../reportEditor';
import {
  changeField,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/treasureFilesObligationsAnalysis';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  ContainerTwoColumn,
  StyledLabel, ContainerDiv,
} from '../../../components/Form/styledForm';
import ItemField from '../../field/selectorField/item/itemField';

import DateRangeField from '../../../components/fields/DateRangeField';
import ReportContainer from '../reportContainer';

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

function ReportTreasureFilesObligationsAnalysisEditor({
  dispatch,
  headerForm,
  sessionOptions,
  isProcessing,
  portalOpened,
  onReportDetails,
}) {
  const isAdmin = sessionOptions.get('is_admin');

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <ContainerTwoColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
      </ContainerTwoColumn>
      <Card>
        <Card.Title className="mt-4 ms-4">
          <StyledLabel>Відбір</StyledLabel>
        </Card.Title>
        <Card.Body>
          <DCControls.Filter />
        </Card.Body>
      </Card>
    </div>
  );
  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
          />
        </div>
      </ContainerDiv>
      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportTreasureFilesObligationsAnalysisEditor.propTypes = {
  dispatch: PropTypes.func,
  headerForm: PropTypes.instanceOf(Map),
  sessionOptions: PropTypes.instanceOf(Map),
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportTreasureFilesObligationsAnalysisEditor.defaultProps = {
  dispatch: () => null,
  headerForm: new Map(),
  isProcessing: false,
  sessionOptions: new Map(),
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportTreasureFilesObligationsAnalysisEditor);
