import { createStore, applyMiddleware, compose } from 'redux';
import { Map } from 'immutable';
import { thunk } from 'redux-thunk';
import reducers from '../reducers';

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancers(applyMiddleware(thunk));

const rootInitialState = new Map({});

const store = createStore(
  reducers,
  rootInitialState,
  middleware,
);

export default store;
