import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  orderNum: { label: 'Порядковий номер', name: 'ПорНом', visible: true },
};

export const forecastCredit = {
  label: 'Кредитування (для прогнозування)',
  name: 'forecastCredit',
  backendName: 'ПрогнозКредитование',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/forecastCredit',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default forecastCredit;
