import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  ColumnsWidth: { label: 'Ширина колонок (з сумами)', name: 'ШиринаКолонок' },
  YearMode: { label: 'Річна', name: 'флГодовая' },
  PrintNo5: { label: 'Друкувати номер додатку до Інструкції 5', name: 'флПечатьДод5' },
  PrintNo: { label: 'Друкувати номер додатку до Інструкції', name: 'флПечатьМинфин' },
};

const tables = {
  data: {
    name: 'ТчДок',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
      // Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'assigmentPrintingSource',
  backendName: 'ПечатьИсточники',
  frontend: '',
  label: 'Друк',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
