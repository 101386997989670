import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  planLowLevel: { label: 'Плани брати з розпорядників рівнем нижче', name: 'ПланыУровеньНиже' },
  showUnapproved: { label: 'Показувати незатверджені', name: 'ПоказыватьНеутвержденные' },
  byLawLevel: { label: 'Відображати дані по розпорядникам', name: 'флПоВсемРаспорядителям' },
};

const definition = {
  label: 'Аналіз фінансування бюджету розвитку (юридичні та фінансові зобов\'язання)',
  name: 'FinancingOfBRAnalysis',
  columns,
  frontend: 'rep/FinancingOfBRAnalysis',
  backendName: 'АнализФинансированияБюджетаРазвития',
  resultColumns: baseResultColumns,
};

export default definition;
