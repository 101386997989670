import React, { useState } from 'react';
import { List, Map } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature as icon, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import SendFinRequestToHOCEditor from '../../../dataProcessors/sendFinRequestToHOC/editor';
import { useStorePath } from '../../../../actions/editor2';
import { modelType, modelName } from '../def';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

function SendToHOCModal({ id, data }) {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <DefaultCPButton
        icon={faPaperPlane}
        onClick={() => setModalOpened(true)}
        label="Відправити до розпорядника вищого рівня"
      />
      <Modal
        show={modalOpened}
        onHide={() => setModalOpened(false)}
        dialogClassName="mw-100"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Відправка до розпорядника вищого рівня
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendFinRequestToHOCEditor
            onSend={() => setModalOpened(false)}
            mapInitialOptions={{
              finRequests: 'Заявки',
              Budget: 'Бюджет',
              FI: 'ФинансовыйОрган',
            }}
            mapStore={(node) => {
                return new Map()
                .set('finRequests', new List([
                  new Map()
                    .setIn(['finRequest', 'id'], id)
                    .setIn(['finRequest', 'repr'], data['repr']),
                ]))
                .set('Budget', data['Бюджет'])
                .set('FI', data['ФинансовыйОрган']);
            }}
          />
        </Modal.Body>

      </Modal>
    </>

  );
}

SendToHOCModal.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};
export default SendToHOCModal;
