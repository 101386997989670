import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/paymentList';
import DateRangeField from '../../../components/fields/DateRangeField';
import {
  ContainerDiv,
  ResultSegment,
  ContainerTwoColumn,
  StyledLabel,
  StyledPane,
  SevenColumnsGrid,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import CheckBoxField from '../../field/CheckBox';
import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer';

const SevenGridCol = styled(SevenColumnsGrid)`
  grid-template-columns: 14% 23% 20% 17% 6% 5% 5.5%;
  align-items: end;
`;

const DataType = {
  fo: { label: 'за даними розпоряджень', value: 1 },
  treasure: { label: 'за даними казн.виписок', value: 2 },
};

const DocType = {
  byHighLevelCSU: { label: 'За розпорядженнями (розподілами) на фінансування', value: 'ПоРаспоряжениям' },
  bySubLevelCSU: { label: 'За заявками (пропозиціями) на фінансування', value: 'ПоЗаявкам' },
};

class ReportPaymentListEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    sessionOptions: new Map(),
    isProcessing: false,
    portalOpened: false,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;
    const FO = headerForm.get('FO', new Map());
    const Bookmarks = (
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    );

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );

    const SettingsButton = (
      <div>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
        </ContainerTwoColumn>
        <SevenGridCol>
          <div>
            <StyledLabel>{md.columns.dataType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('dataType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'dataType'], v))}
              values={Object.values(DataType)}
              name="dataType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.docType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('docType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'docType'], v))}
              values={Object.values(DocType)}
              name="docType"
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('bySubLevelCSU', false)}
              label={md.columns.bySubLevelCSU.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'bySubLevelCSU'], v))}
            />
            <CheckBoxField
              value={headerForm.get('byHighLevelCSU', false)}
              label={md.columns.byHighLevelCSU.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byHighLevelCSU'], v))}
            />
          </div>
          <div>
            <CheckBoxField
              value={headerForm.get('atLoan', false)}
              label={md.columns.atLoan.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'atLoan'], v))}
            />
            <CheckBoxField
              value={headerForm.get('financedTreasure', false)}
              label={md.columns.financedTreasure.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'financedTreasure'], v))}
            />
            <CheckBoxField
              value={headerForm.get('printReportParams', false)}
              label={md.columns.printReportParams.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'printReportParams'], v))}
            />
          </div>
        </SevenGridCol>
        {Bookmarks}
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportPaymentListEditor);
