import React, {
  useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Row, Col, Tabs, TabContainer,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { Footer } from '../../../../components/Containers/docs';
import {
  UsingBudgetFunds,
  Task,
  Indicators,
  RegionalTargetProgram,
  PublicPolicyTarget,
  DocumentDisposerOfLowerLevel,
} from './tp';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { Control } from '../../../newEditor/hook/control';
import './tpStyle.css';
import { CiatAppContext } from '../../../../providers';
import useEditorParams from '../../../newEditor/hook/params';
import ReceivedStamp from '../../../../components/stamp/receivedStamp';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';

const BACKENDURL = meta.doc.budgetPassport.backendURL;
function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions,
    fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors,
    readOnlyFields, readOnly,
    printForms, headerProps,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { currentUser } = useContext(CiatAppContext);
  const { authority } = currentUser;
  const { budget } = authority;

  const authorityFilter = useMemo(
    () => ({
      authority: data.authority ? data.authority.id : authority.id,
    }),
    [authority.id, data.authority],
  );

  const disposerFilter = useMemo(
    () => ({
      parent: data.authority ? data.authority.id : authority.id,
    }),
    [authority.id, data.authority],
  );

  const budgetFilter = useMemo(
    () => ({
      budget: data.budget ? data.budget.id : budget.id,
    }),
    [budget.id, data.budget],
  );

  const fromLogica = !!data.logica_id;

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={`${data.repr} ${fromLogica ? '(затягнутий з сервісу Logica)' : ''}`}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      isLoading={loading}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          backendURL={BACKENDURL}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={BACKENDURL}
            documnetId={data.id}
            disabled={!(data.authority_sign_variant && data.executed && !changed)}
          />
        </DocCommandPanel>
      )}
    >
      <Tabs defaultActiveKey="note">
        <Tab eventKey="note" title="Загальне">
          <Row onClick={(e) => e.stopPropagation()}>
            <Col>
              <Control name="doc_date" headerProps={headerProps} />
            </Col>
            <Col>
              <Control name="year" headerProps={headerProps} typeControl="yearInput" />
            </Col>
            <Col>
              <Control
                name="disposer"
                headerProps={{
                  ...headerProps,
                  advancedProps: {
                    filter: disposerFilter,
                    noHierarchy: true,
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Control
                name="kbp7"
                headerProps={{
                  ...headerProps,
                  advancedProps: {
                    filter: budgetFilter,
                  },
                }}
              />
            </Col>
            <Col>
              <Control
                name="kbp"
                headerProps={{
                  ...headerProps,
                  readOnly: true,
                }}
              />
            </Col>
            <Col>
              <Control
                name="kfk"
                headerProps={{
                  ...headerProps,
                  readOnly: true,
                }}
              />
            </Col>
          </Row>
          { currentUser.is_superuser && (
          <Row>
            <Col>
              <Control name="received" headerProps={headerProps} />
            </Col>
            <Col />
          </Row>
          )}
          <TabContainer>
            <Control
              name="note"
              headerProps={headerProps}
              typeHook="text"
              typeControl="text"
            />
          </TabContainer>
          <Footer
            created={data.created_at}
            updated={data.updated_at}
            budget={data.budget}
            authority={data.authority}
            author={data.author}
            headerProps={headerProps}
          />
          {data.received && (
            <div className="stamp-container">
              <ReceivedStamp invoice={data.invoice} />
            </div>
          )}
        </Tab>
        <Tab eventKey="passportbppublicpolicytarget" title="Цілі держ.політики">
          <PublicPolicyTarget
            data={data}
            fields={fields}
            errors={fieldErrors}
            readOnlyFields={readOnlyFields}
            onChange={actions.onChange}
            readOnly={readOnly}
            fromLogica={fromLogica}
          />
        </Tab>
        <Tab eventKey="passportbproblem" title="Завдання">
          <TabContainer>
            <Task
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              onDraft={actions.onDraft}
              readOnly={readOnly}
              fromLogica={fromLogica}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="passportbpusingbudgetfunds" title="Напрями">
          <TabContainer>
            <UsingBudgetFunds
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              onDraft={actions.onDraft}
              readOnly={readOnly}
              fromLogica={fromLogica}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="passportbpindicator" title="Показники">
          <TabContainer>
            <Indicators
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              onDraft={actions.onDraft}
              readOnly={readOnly}
              budgetFilter={budgetFilter}
              tableName="passportbpindicatorrow_set"
              fromLogica={fromLogica}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="passportbpregionaltargetprogram" title="Рег.цільові програми">
          <TabContainer>
            <RegionalTargetProgram
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              readOnly={readOnly}
              fromLogica={fromLogica}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="grounds" title="Підстави">
          <TabContainer>
            <Row md={2}>
              <Col>
                <Control
                  name="authority_sign_variant"
                  headerProps={{
                    ...headerProps,
                    advancedProps: {
                      filter: authorityFilter,
                    },
                  }}
                />
              </Col>
              <Col>
                <Control
                  name="agreed_by"
                  headerProps={headerProps}
                  typeControl="text"
                  typeHookt="text"
                />
              </Col>
            </Row>
            <h5>Законодавчі підстави бюдж.програми</h5>
            <Row md={2}>
              <Col>
                <Control
                  name="law_realise_basis"
                  headerProps={headerProps}
                  typeControl="text"
                  typeHookt="text"
                />
              </Col>
              <Col>
                <Control
                  name="goal"
                  headerProps={headerProps}
                  typeControl="text"
                  typeHookt="text"
                />
              </Col>
            </Row>
            <h5>Наказ про затвердження бюдж.програми</h5>
            <Row>
              <Col>
                <Control
                  name="imperative1str"
                  headerProps={headerProps}
                  typeControl="text"
                  typeHookt="text"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Control
                  name="imperative_date"
                  headerProps={headerProps}
                  typeControl="date"
                  typeHookt="date"
                />
              </Col>
              <Col>
                <Control
                  name="imperative_number"
                  headerProps={headerProps}
                  typeControl="string"
                  typeHookt="string"
                />
              </Col>
            </Row>
          </TabContainer>

        </Tab>
        <Tab eventKey="documentDisposerOfLowerLevel" title="Документи нижнього рівня">
          <TabContainer>
            <DocumentDisposerOfLowerLevel
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              readOnly={readOnly}
              onDraft={actions.onDraft}
              tableName="passportbpdisposerleveldownrow_set"
            />
          </TabContainer>
        </Tab>
      </Tabs>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  options: { name: '' },
  // fieldErrors: {},
};

export default Editor;
