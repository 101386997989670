import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase as icon } from '@fortawesome/free-solid-svg-icons';
import { fromJS } from 'immutable';
import { BudgetRequestPrintEditor } from '../../../dataProcessors';

function PrintSubMenu({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );
  return (
    <>
      <Button variant="primary" onClick={() => setOpened(true)} size="sm">
        <FontAwesomeIcon icon={icon} className="me-2" />
        Друк
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Друкована формa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BudgetRequestPrintEditor
            mapInitialOptions={{
              Document: 'ДокументСсылка',
            }}
            mapStore={mapStore}
            onClose={() => setOpened(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

PrintSubMenu.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrintSubMenu;
