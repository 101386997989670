import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters>
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactLastYearGenF'}
              highlighted={highlights.includes('sumFactLastYearGenF')}
            >
              {totals.sumFactLastYearGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactLastYearSpecF'}
              highlighted={highlights.includes('sumFactLastYearSpecF')}
            >
              {totals.sumFactLastYearSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactGenF'}
              highlighted={highlights.includes('sumFactGenF')}
            >
              {totals.sumFactGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactSpecF'}
              highlighted={highlights.includes('sumFactSpecF')}
            >
              {totals.sumFactSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumDeviationGenF'}
              highlighted={highlights.includes('sumDeviationGenF')}
            >
              {totals.sumDeviationGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumDeviationSpecF'}
              highlighted={highlights.includes('sumDeviationSpecF')}
            >
              {totals.sumDeviationSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactLastYearTotal'}
              highlighted={highlights.includes('sumFactLastYearTotal')}
            >
              {totals.sumFactLastYearTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactTotal'}
              highlighted={highlights.includes('sumFactTotal')}
            >
              {totals.sumFactTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumDeviationTotal'}
              highlighted={highlights.includes('sumDeviationTotal')}
            >
              {totals.sumDeviationTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumFactLastYearGenF: PropTypes.number,
    sumFactLastYearSpecF: PropTypes.number,
    sumFactGenF: PropTypes.number,
    sumFactSpecF: PropTypes.number,
    sumDeviationGenF: PropTypes.number,
    sumDeviationSpecF: PropTypes.number,
    sumFactLastYearTotal: PropTypes.number,
    sumFactTotal: PropTypes.number,
    sumDeviationTotal: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
