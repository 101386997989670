import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from 'react-bootstrap';
import { GeneralTP, DocsTP } from './tabs';
import md from '../../../../constants/meta/documents/netCategory';

function ForecastAdd9Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange
    || data[md.columns.isApproved.name]
    || data[md.columns.appr.name];
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.docs.label} eventKey="docs">
        <DocsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

ForecastAdd9Tabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default ForecastAdd9Tabs;
