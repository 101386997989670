import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { BalansesRow } from './tp';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import ApprovedInfo from '../../common/approvedInfo';

function Editor({
  onSave, onClose, id,
}) {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms,
  } = useEditor({
    backendURL: meta.doc.balancesAccounts.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  // if (!data || !fields) return null;
  const number = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const date = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const authority = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const budget = editorHooks.useItemInputProps('budget', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          backendURL={meta.doc.balancesAccounts.backendURL}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...number} />
              </Col>
              <Col>
                <EditorControls.DateInput {...date} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...authority} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...budget} />
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.balancesAccounts.backendURL}${data.id}/`}
            />
          </Container>
        </HidableContainer>
        {fields && fields.cityplanbalancesonaccountsrow_set && (
          <BalansesRow
            data={data}
            fields={fields}
            errors={fieldErrors}
            readOnlyFields={readOnlyFields}
            onChange={actions.onChange}
            readOnly={!permissions.canChange}
          />
        )}

      </DocumentBody>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
