import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/taxes7';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';

function Taxes2({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const readOnly = !permissions.canChange;

  return (
    <EditorCollapse>
      <Row>
        {sessionOptions.get('is_admin', false) && (
          <BudgetFOUserEditorRow
            onChange={actions.onChange}
            data={data}
            readOnly={readOnly}
          />
        )}
        <Col sm={2}>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.tablePeriod.label}
            value={data[md.columns.tablePeriod.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.tablePeriod.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
          <Col>
          <EditorControls.CheckboxInput
            controlId={`additional-${data.id}`}
            label={md.columns.additional.label}
            value={data[md.columns.additional.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.additional.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

Taxes2.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Taxes2;
