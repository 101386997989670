import { buildModes } from './common';

// Тут только ОТГ !!!!

export const testDbOptions = [
  {
    text: 'MAX',
    value: '1c-dev-02.ciat.net.ua/Max',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Тестовая Роми та Вани',
    value: '1c-dev-02.ciat.net.ua/Roman_WEB',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Тестовая Ровенская область',
    value: '1c-dev-01.ciat.net.ua/Roman_Test',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'тестовая Филипповича, Ковель',
    value: '1c-dev-02.ciat.net.ua/BM_H',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'тестовая Филипповича, Kyiv',
    value: '1c-dev-02.ciat.net.ua/BM_H',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'тестовая Филипповича, Теплодар',
    value: '1c-dev-01.ciat.net.ua/Dev_Harbar',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'тестовая Филипповича, virtual host',
    value: '192.168.122.228/kovel',
    modes: [buildModes.dev],
    AP: 'local',
  },
  {
    text: 'тестовая Филипповича, dev-01',
    value: '1c-dev-01.ciat.net.ua/BM_H',
    modes: [buildModes.dev],
    AP: 'local',
  },
  {
    text: 'Тарас dev-01',
    value: '1c-dev-01.ciat.net.ua/Gusar_1',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова 01 *',
    value: 'srv52.ciat.net.ua/Taras_test3',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Бюджет Роман',
    value: '1c-dev-01.ciat.net.ua/Roman_BM',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Ковель (тест)',
    value: 'srv52.ciat.net.ua/Kovel_WEB',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Область',
    value: 'srv81.ciat.net.ua/SumyObl_BM/uk/',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Test_Dnepr',
    value: 'srv81.ciat.net.ua/Test_Dnepr/',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова Киев*',
    value: '1c-dev-02.ciat.net.ua/Masha_02',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова Хуст*',
    value: '1c-dev-02.ciat.net.ua/Max',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова Миколаїв*',
    value: '1c-dev-02.ciat.net.ua/Masha_01',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: '* тестова Калуш*',
    value: 'srv81.ciat.net.ua/Test_Shcherbakov',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: '* тестова 03*',
    value: '1c-dev-02.ciat.net.ua/BM_H',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова Тарас dev-02 *',
    value: '1c-dev-02.ciat.net.ua/Gusar_1',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: '* тестова Львів*',
    value: 'srv81.ciat.net.ua/Test_3',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тест Кременчуга',
    value: 'kremenchuk.ciat.net.ua/Test_04',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Тест Киева',
    value: 'kyiv.ciat.net.ua/KyivBM_Test',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Тест для Сірожи',
    value: '1c-dev-02.ciat.net.ua/X_BM/',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Київ тест доходи',
    value: 'sabosja.ciat.net.ua/sabosja/',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Сірожа test 04',
    value: 'srv81.ciat.net.ua/Test_04/',
    modes: [buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Тестова stage',
    value: '1c-dev-02.ciat.net.ua/X_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тестова СЕЛЯТИН',
    value: 'srv81.ciat.net.ua/Test_SeliatynskaBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
];
