import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноза', visible: true },
  bubgetTrans: { label: 'Найменування місцевого бюджету – отримувача міжбюджетного трансферту)', name: 'БюджетТранс', visible: false },
  csu: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  csuTrans: { label: 'Найменування місцевого фінансового органу отримувача', name: 'РаспорядительТранс', visible: false },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Доходы',
    columns: {
      kdb: { label: 'Найменування трансферту ', name: 'КДБ' },
      budget: { label: 'Найменування бюджету – отримувача міжбюджетного трансферту', name: 'Бюджет' },
      kdbCode: { label: 'Код', name: 'КДБКод' },
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Показники міжбюджетних трансфертів (іншим місцевим бюджетам) (Додаток 5)',
  name: 'prepareForecastAdd5',
  backendName: 'ПрогнозДодП5',
  columns,
  frontend: 'doc/prepareForecastAdd5',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'appr',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
