import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd3';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'taxZF';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.code.name]: v });
      await onSR('CHANGE_ZF_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum_2" highlighted={highlights.includes('sum_2')}>
              <NumberInput
                value={row[tableMD.columns.sum_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum_1" highlighted={highlights.includes('sum_1')}>
              <NumberInput
                value={row[tableMD.columns.sum_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
              <NumberInput
                value={row[tableMD.columns.sum0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              <NumberInput
                value={row[tableMD.columns.sum1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sum0.name]: PropTypes.number,
    [tableMD.columns.sum1.name]: PropTypes.number,
    [tableMD.columns.sum2.name]: PropTypes.number,
    [tableMD.columns.sum_2.name]: PropTypes.number,
    [tableMD.columns.sum_1.name]: PropTypes.number,
    [tableMD.columns.name.name]: PropTypes.string,
    [tableMD.columns.code.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
