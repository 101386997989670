import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import StringInput from '../../../../../../components/bootstrap_components/controls/stringInput';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'regulations';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="regulation" highlighted={highlights.includes('regulation')}>
              <ItemPicker
                value={row[tableMD.columns.regulation.name]}
                modelType="cat"
                modelName="groundLaw"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.regulation.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="article" highlighted={highlights.includes('article')}>
              <ItemPicker
                value={row[tableMD.columns.article.name]}
                modelType="cat"
                modelName="provisionOfNormativeLegalActs"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.article.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="KEKV" highlighted={highlights.includes('KEKV')}>
              <StringInput
                value={row[tableMD.columns.KEKV.name]}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKEKV.name]}
                modelType="cat"
                modelName="elementKEKV"
                filter={FIOwnerFilter}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.elementKEKV.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              <NumberInput
                value={row[tableMD.columns.sum1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum1.name]: value,
                })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="sum3" highlighted={highlights.includes('sum3')}>
              <NumberInput
                value={row[tableMD.columns.sum3.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum3.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="description" highlighted={highlights.includes('description')}>
              <NumberInput
                value={row[tableMD.columns.description.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.description.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
