import { buildModes } from './common';

// Тут только ОТГ !!!!

export const otgDbOptions = [
  {
    text: 'Балтська ТГ',
    value: 'srv81.ciat.net.ua/Baltska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Біла Церква ОТГ',
    value: 'srv81.ciat.net.ua/BilaTserkva_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Визирська ОТГ',
    value: 'srv81.ciat.net.ua/Vyzyrska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Вилоцька ТГ',
    value: 'srv81.ciat.net.ua/Vylok_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Вишківська ОТГ',
    value: 'srv81.ciat.net.ua/Vyshkivska_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Віньковецька ТГ',
    value: 'srv81.ciat.net.ua/VinkovetskaTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Горінчівська ОТГ',
    value: 'srv81.ciat.net.ua/HorinchivskaOTH_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Давидівська ОТГ',
    value: 'srv81.ciat.net.ua/DavydivkaOTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Демидівська ОТГ',
    value: 'srv81.ciat.net.ua/Demydivska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Долинська ОТГ',
    value: 'dolyna.ciat.net.ua/Dolynska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Довжанська ОТГ',
    value: 'srv81.ciat.net.ua/DovzhanskaOTH_BM/',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Драгівська СТГ',
    value: '043500271.ciat.net.ua/DrahivskoiOTH_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Киїнської СТГ',
    value: 'srv81.ciat.net.ua/Kyinka_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Клесівська ОТГ',
    value: 'srv81.ciat.net.ua/Kliasivska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Красносільська  ОТГ',
    value: 'srv81.ciat.net.ua/Krasnosilka_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Куяльницька ОТГ',
    value: 'srv81.ciat.net.ua/Kuialnyk_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Ладанська ОТГ',
    value: 'srv81.ciat.net.ua/Ladanska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Літовежа ОТГ',
    value: 'srv81.ciat.net.ua/LitoveshaOTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Мала Виска ОТГ',
    value: 'srv81.ciat.net.ua/MalaVyskaOTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Мирноград ОТГ',
    value: 'srv81.ciat.net.ua/Myrnogradsk_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Мурованська ОТГ',
    value: 'srv81.ciat.net.ua/Murovanska_OTG',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Новобілоуська сільська ТГ',
    value: 'srv81.ciat.net.ua/NovobilouskaBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Пилипецька СТГ',
    value: 'srv81.ciat.net.ua/PylypetskaOTH_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Подільська СТГ',
    value: 'srv81.ciat.net.ua/Podilsk_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Путильська СТГ',
    value: 'srv81.ciat.net.ua/Putyljsjka_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Селятинська  ОТГ',
    value: 'srv81.ciat.net.ua/Seliatynska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Скала-Подільська  ОТГ',
    value: 'srv81.ciat.net.ua/SkalaPodilska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Сокиряни ОТГ',
    value: 'srv81.ciat.net.ua/Sokyrjany_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чемеровецька  ОТГ',
    value: 'srv81.ciat.net.ua/Chemerivtsi_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Шацька ОТГ',
    value: 'srv81.ciat.net.ua/Shatska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Широківська  ОТГ',
    value: 'srv81.ciat.net.ua/Shyrokivska_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  }, {
    text: 'Хуст ОТГ',
    value: '254407281.ciat.net.ua/Khust_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Юрковецька ОТГ',
    value: 'srv81.ciat.net.ua/Yurkivtsi_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
];
