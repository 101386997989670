import React, {
  forwardRef, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Nav, Navbar,
} from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { childrenPropType } from '../../constants/backend/propTypes';

const CommandPanel = forwardRef(({
  sticky, className, leftPart, rightPart, ddItems, rtl,
}, pref) => {
  const ref = useRef(pref);
  const leftRef = useRef();
  const rightRef = useRef();
  const leftSizes = useRef({
    full: null,
    medium: null,
  });
  const rightSize = useRef(null);
  const [measured, setMeasured] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [size, setSize] = useState('full');

  // console.log(leftSizes, rightSize);

  const onResize = useCallback(
    () => {
      const s = leftRef.current.scrollWidth;
      const ss = ref.current.getBoundingClientRect().width;
      const pLeft = parseInt(getComputedStyle(ref.current, true).paddingLeft, 10);
      const pRight = parseInt(getComputedStyle(ref.current, true).paddingRight, 10);
      if (!measured && ref.current) {
        if (size === 'full' && !leftSizes.current.full && s) {
          leftSizes.current.full = s;
          rightSize.current = rightRef.current.scrollWidth;
          setSize('medium');
        } else if (size === 'medium' && !leftSizes.current.medium && s) {
          leftSizes.current.medium = s;
          setMeasured(true);
        }
      } else if (leftSizes.current.medium > ss - rightSize.current - pLeft - pRight) {
        if (size !== 'small') setSize('small');
      } else if (leftSizes.current.full > ss - rightSize.current - pLeft - pRight) {
        if (size !== 'medium') setSize('medium');
      } else if (size !== 'full') setSize('full');
    },
    [measured, size],
  );
  const observer = useMemo((() => new ResizeObserver(onResize)), [onResize]);
  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const node = ref.current;
      if (node) observer.observe(node);
      return () => {
        observer.disconnect();
      };
    },
    [observer],
  );

  return (
    <Navbar
      sticky={sticky}
      ref={ref}
      className={classNames(className, `w-auto w-md-100 size-${size}`)}
      expand={size !== 'small'}
    >
      <Navbar.Toggle className="ms-auto" />
      <Navbar.Collapse>
        <Nav className={classNames('gap-1 flex-nowrap', { 'me-auto': !rtl, 'ms-auto': rtl })} ref={leftRef}>
          {leftPart}
        </Nav>
        <Nav className="flex-nowrap gap-1" ref={rightRef}>
          {rightPart}
          {ddItems && (
            <Dropdown align="end" as={Nav} drop={size === 'small' ? 'end' : 'down'}>
              <Dropdown.Toggle variant="outiline-secondary" id="dropdown-basic" className="pt-0 pb-1 px-1">
                <FontAwesomeIcon icon={faBars} title="Ще" />
                <span className={classNames('ms-2', { 'd-none': size !== 'small' })}>
                  Додатково
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ddItems}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

CommandPanel.propTypes = {
  sticky: PropTypes.oneOf(['top']),
  className: PropTypes.string,
  leftPart: childrenPropType,
  rightPart: childrenPropType,
  ddItems: childrenPropType,
  rtl: PropTypes.bool,
};

CommandPanel.defaultProps = {
  sticky: null,
  className: '',
  leftPart: null,
  rightPart: null,
  ddItems: null,
  rtl: false,
};
export default CommandPanel;
