import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/objectivesStatePolicy';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { referencePropType } from '../../../newEditor/propTypes';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { selectionMode } from '../../../../constants/meta/common';

function ObjectivesStatePolicyEditor({ data, actions }) {
  return (
    <EditorCollapse>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLenght={9}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLenght={90}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            rows={6}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelName="objectivesStatePolicy"
            modelType="cat"
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

ObjectivesStatePolicyEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
    [md.columns.FullName.name]: PropTypes.string,
    [md.columns.owner.name]: referencePropType,
    [md.columns.parent.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default ObjectivesStatePolicyEditor;
