import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
};

export const additionalPropertyTypesRepair = {
  label: 'Додаткова властивість видів ремонту',
  name: 'additionalPropertyTypesRepair',
  backendName: 'ДопХВидовРемонта',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/additionalPropertyTypesRepair',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default additionalPropertyTypesRepair;
