import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor,
  Title,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import DateField from '../../../components/fields/dates';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import DocsTP from './tablepart/apdocsTP';
import FondsTP from './tablepart/tvFondsTP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';

const md = meta.dp.exportVDBFKiev;

const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(<TPCommandPanel tableName="tpDocuments" />));
const FondsTPRenderer = getTPContainer(FondsTP, getResizableCP(<TPCommandPanel tableName="TVFonds" />));

const ExportTypes = {
  Order: { label: 'Розпорядження', name: 0 },
  Distrib: { label: 'Розподіл', name: 1 },
};

const Transfers = {
  TypicalTrans: { label: 'Типовий/передача', name: 0 },
  Typical: { label: 'Типовий', name: 1 },
  Trans: { label: 'Передача', name: 2 },
};

class ExportVDBFKievEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      // firstStart: true,
    };
  }

  // componentWillUnmount() {
  //   const { dispatch } = this.props;
  //   dispatch(actions.processServerReq('CLOSING'));
  // }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <div>
          <StyledLabel>{md.columns.KindDoc.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('KindDoc', false)}
            options={Object.values(ExportTypes)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KindDoc'], v))}
          />
        </div>
        <MyContainerFormWithNColumns columns={5} style={{ marginTop: '10px' }}>
          <div>
            <Fields.CheckboxField
              label={md.columns.DBF373UD.label}
              value={headerForm.get('DBF373UD', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DBF373UD'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.Note160.label}
              value={headerForm.get('Note160', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Note160'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.DBF3732.label}
              value={headerForm.get('DBF3732', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DBF3732'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.CodingWin.label}
              value={headerForm.get('CodingWin', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CodingWin'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.FlTurnStrings.label}
              value={headerForm.get('FlTurnStrings', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FlTurnStrings'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.NoNote.label}
              value={headerForm.get('NoNote', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoNote'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.NameCSUShort.label}
              value={headerForm.get('NameCSUShort', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NameCSUShort'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.NoArhive.label}
              value={headerForm.get('NoArhive', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoArhive'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.FlDBF.label}
              value={headerForm.get('FlDBF', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FlDBF'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={4}>
          <div>
            <StyledLabel>{md.columns.Date.label}</StyledLabel>
            <DateField
              label={md.columns.Date.label}
              value={headerForm.get('Date', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Date'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.VDD.label}
              value={headerForm.get('VDD', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'VDD'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.UnderlieSymbol.label}
              value={headerForm.get('UnderlieSymbol', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'UnderlieSymbol'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.AccountInNameFolder.label}
              value={headerForm.get('AccountInNameFolder', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AccountInNameFolder'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.Variant2.label}
              value={headerForm.get('Variant2', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Variant2'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.DocNumINFile.label}
              value={headerForm.get('DocNumINFile', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DocNumINFile'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={6}>
          <div>
            <StyledLabel>{md.columns.Transfer.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('Transfer', false)}
              options={Object.values(Transfers)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Transfer'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.StartDt.label}</StyledLabel>
            <DateField
              value={headerForm.get('StartDt', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'StartDt'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.FinoshDt.label}</StyledLabel>
            <DateField
              label={md.columns.FinoshDt.label}
              value={headerForm.get('FinoshDt', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FinoshDt'], v))}
            />
          </div>
          <CommandPanelColor
            style={{ width: 'fit-content' }}
            text="Отримати документи за обраний період по обраним фондам"
            label="Заповнити"
            onClick={() => dispatch(actions.processServerReq('RENEW'))}
          />
          <Fields.CheckboxField
            label={md.columns.symbolForBR.label}
            value={headerForm.get('symbolForBR', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'symbolForBR'], v))}
          />
          <Fields.CheckboxField
            label={md.columns.TransFPlat.label}
            value={headerForm.get('TransFPlat', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'TransFPlat'], v))}
          />
        </MyContainerFormWithNColumns>
        {/* <MyContainerFormWithNColumns columns={1}> */}
        {/*  <StyledContainer style={{ maxHeight: '200px' }}> */}
        {/*    <GeneralTPRenderer /> */}
        {/*  </StyledContainer> */}
        {/* </MyContainerFormWithNColumns> */}
        <DocsTPRenderer />
        <MyContainerFormWithNColumns columns={1}>
          <Title style={{ margin: '20px 0 10px' }}>
            Додаткова інформація для запису у файл (заповнюється виключно за потребою користувача,
            у випадкунеобхідності запису у файл нестандартних значень)
          </Title>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.SighnBuh.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('SighnBuh', new Map())}
              modelType="cat"
              modelName="officials"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'SighnBuh'], value));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.SighnDirector.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('SighnDirector', new Map())}
              modelType="cat"
              modelName="officials"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'SighnDirector'], value));
              }}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.FO2.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('FO2', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'FO2'], value));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.Recipient.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('Recipient', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'Recipient'], value));
              }}
            />
          </div>
        </MyContainerFormWithNColumns>
        <StyledLabel>{md.columns.FieldFond.label}</StyledLabel>
        <Fields.CheckboxField
          label="1- загальний фонд, 2- плата за послуги бюджетних установ, 3 - інші джерела власних надходжень, 7 - інші кошти спеціального фонду"
          value={headerForm.get('FieldFond', false)}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FieldFond'], v))}
        />
        <Fields.CheckboxField
          label={md.columns.DevBudget.label}
          value={headerForm.get('DevBudget', false)}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DevBudget'], v))}
        />
        <div>
          <StyledLabel>Фонди</StyledLabel>
          <FondsTPRenderer />
        </div>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportVDBFKiev',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ExportVDBFKievEditor);
