import React from 'react';
import PropTypes from 'prop-types';
import { faHospital } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function DownloadDictionaryButton({
  disabled, onClick, label, href, download,
}) {
  return (
    <DefaultCPButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      icon={faHospital}
      variant="success"
      showLabel={false}
      href={href}
      download={download}
    />
  );
}

DownloadDictionaryButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.string,
};

DownloadDictionaryButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Завантажити довідник користувача',
  href: '',
  download: '',
};

export default DownloadDictionaryButton;
