const frontendURL = '/rep/financing_card/';
const backendURL = '/api/financials/printform/financing_card/';
const name = 'Картка фінансування';
const sections = ['executing'];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
