import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  Link: { label: 'ДокументСсылка', name: 'ДокументСсылка' },
  Variant: { label: 'Вид змін до розпису', name: 'Вариант' },
  DocList: { label: 'Список вибраних документів', name: 'СписокОтобранныхДокументов' },
  Pass: { label: 'Путь, name', name: 'Путь' },
  Reg: { label: 'Сформувати реэстр змін', name: 'ВРеестр' },
  PCM: { label: 'За класифікаторами ПЦМ', name: 'ПЦМ' },
  SF111: { label: 'Код спеціального фонду 111', name: 'СФ111' },
  DBFAddress: { label: 'АдресДБФ', name: 'АдресДБФ' },
  TVAddress: { label: 'АдресТЗ', name: 'АдресТЗ' },
  Date1: { label: 'Отримати документи з', name: 'Дата1' },
  Date2: { label: 'по', name: 'Дата2' },
  FileName: { label: 'Назва файлу', name: 'ИдФайла' },
  TempCatName: { label: 'ИмяВремКаталога', name: 'ИмяВремКаталога' },
  NameDBF: { label: 'ИмяДБФ', name: 'ИмяДБФ' },
  KaznCode: { label: 'КодКазны', name: 'КодКазны' },
  AddFile: { label: 'ПриставкаФайла', name: 'ПриставкаФайла' },
  NoArchive: { label: 'Не архівувати файли', name: 'БезАрхива' },
  OneFile: { label: 'Вивантажити в один файл', name: 'флВыгрузитьВОдинФайл' },
  InOrder: { label: 'згідно змін до наказу (БР проставлено як 4)', name: 'ПоНаказу' },
  planEst: { label: 'Тип звіту', name: 'ПланСмета' },
  flDNF: { label: 'Назва файлу довідки про надходження у натуральній формі  DNF_', name: 'флИмяФайлаИзмНат' },
  NoYearSumm: { label: 'Не вивантажувати річні суми ', name: 'БезГодСум' },
  codeInName: { label: 'Код розпорядника в імені файла ', name: 'КодВИмени' },
  onlyYear: { label: 'Тільки річні суми ', name: 'ТолькоГод' },
  NoYearSummIfZero: { label: 'Не записувати дані по яким річні суми дорівнюють "0" ', name: 'НеПоказСуммаГодНулевая' },
  NOM: { label: 'Значення для поля  NOM тобто номеру реєстру , якщо не вказати  значення  тоді  буде присвоєно номер довідки ', name: 'NOM' },
  NR: { label: 'Номер реєстру (при вивантаженні змін) ', name: 'NR' },
  nameFileChange: { label: 'Назва файлу змін  ZMINV_', name: 'флИмяФайлаИзм' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  UploadAsChanges: { label: 'Вивантажувати плановиі документи у форматі документів для змін', name: 'ВыгрузитьКакСправку' },
  assign: { label: 'Вид даних що вивантажуються', name: 'Ассигнования' },
  folders: { label: 'Створювати окрему папку для кожної довідки ', name: 'ПоПапкам' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  budgetTL_TERRA: { label: 'Друкувати (вивантажувати у файл) код  КОАТУУ як код бюджету', name: 'TL_TERRA' },
  isFO: { label: 'ЭтоФО', name: 'ЭтоФО' },
  inHeadCSU: { label: 'Записувати у файл дані по головному розпоряднику, а не по установі ', name: 'ПоГолРасп' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  f6Separate: { label: 'Записувати спецфонд інші кошти без бюджету розвитку окремо', name: 'ЗаписатьФонд6Отдельно' },
  all7: { label: 'Завжди дописувати підсумки по інших коштах спецфонду', name: 'ВсегдаДописывать7Фонд' },
  noKDB: { label: 'не заповнювати КДБ і КФБ', name: 'БезКДБКФБ' },
};

const tables = {
  tpTV: {
    name: 'тчТЗ',
    label: 'тчТЗ',
    columns: {
      DATA: { label: 'DATA', name: 'DATA' },
      KOD: { label: 'KOD', name: 'KOD' },
      KODROZ: { label: 'KODROZ', name: 'KODROZ' },
      DNUMB: { label: 'DNUMB', name: 'DNUMB' },
      DDATE: { label: 'DDATE', name: 'DDATE' },
      KVK: { label: 'KVK', name: 'KVK' },
      KRK: { label: 'KRK', name: 'KRK' },
      TPKVKMB: { label: 'TPKVKMB', name: 'TPKVKMB' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      KKD: { label: 'KKD', name: 'KKD' },
      KKF: { label: 'KKF', name: 'KKF' },
      KKFB: { label: 'KKFB', name: 'KKFB' },
      MONTH: { label: 'MONTH', name: 'MONTH' },
      SUMM: { label: 'SUMM', name: 'SUMM' },
      NOTE: { label: 'NOTE', name: 'NOTE' },
      KPK: { label: 'KPK', name: 'KPK' },
    },
  },
  docs: {
    name: 'СписокОтобранныхДокументов',
    label: 'СписокОтобранныхДокументов',
    columns: {
      mark: { label: 'Помітка', name: 'Пометка' },
      val: { label: 'Значение', name: 'Значение' },
      look: { label: 'Документ', name: 'Передставление' },
    },
  },
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Використання', name: 'Использование' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      Element: { label: 'Элемент группа', name: 'ЭлементГруппа' },
    },
  },
  tpTVChange: {
    name: 'ТЗИзм',
    label: 'ТЗИзм',
    columns: {
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      SRK: { label: 'SRK', name: 'SRK' },
      KVK: { label: 'KVK', name: 'KVK' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      UDK: { label: 'UDK', name: 'UDK' },
      VDK: { label: 'VDK', name: 'VDK' },
      KPK: { label: 'KPK', name: 'KPK' },
      KFK: { label: 'KFK', name: 'KFK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      SK: { label: 'SK', name: 'SK' },
      SUTVG: { label: 'SUTVG', name: 'SUTVG' },
      SUTVSG: { label: 'SUTVSG', name: 'SUTVSG' },
      SUTVS: { label: 'SUTVS', name: 'SUTVS' },
      S101: { label: 'S101', name: 'S101' },
      S102: { label: 'S102', name: 'S102' },
      S103: { label: 'S103', name: 'S103' },
      S104: { label: 'S104', name: 'S104' },
      SUTVD: { label: 'SUTVD', name: 'SUTVD' },
      S201: { label: 'S201', name: 'S201' },
      S202: { label: 'S202', name: 'S202' },
      SUTVI: { label: 'SUTVI', name: 'SUTVI' },
      SUTVR: { label: 'SUTVR', name: 'SUTVR' },
      SUTV1: { label: 'SUTV1', name: 'SUTV1' },
      SUTV2: { label: 'SUTV2', name: 'SUTV2' },
      SUTV3: { label: 'SUTV3', name: 'SUTV3' },
      SUTV4: { label: 'SUTV4', name: 'SUTV4' },
      SUTV5: { label: 'SUTV5', name: 'SUTV5' },
      SUTV6: { label: 'SUTV6', name: 'SUTV6' },
      SUTV7: { label: 'SUTV7', name: 'SUTV7' },
      SUTV8: { label: 'SUTV8', name: 'SUTV8' },
      SUTV9: { label: 'SUTV9', name: 'SUTV9' },
      SUTV10: { label: 'SUTV10', name: 'SUTV10' },
      SUTV11: { label: 'SUTV11', name: 'SUTV11' },
      SUTV12: { label: 'SUTV12', name: 'SUTV12' },
      NR: { label: 'NR', name: 'NR' },
      S203: { label: 'S203', name: 'S203' },
      S204: { label: 'S204', name: 'S204' },
      NOM: { label: 'NOM', name: 'NOM' },
      DAT: { label: 'DAT', name: 'DAT' },
    },
  },
  tpTVPlan: {
    name: 'ТЗПлан',
    label: 'ТЗПлан',
    columns: {
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      SRK: { label: 'SRK', name: 'SRK' },
      KVK: { label: 'KVK', name: 'KVK' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      UDK: { label: 'UDK', name: 'UDK' },
      VDK: { label: 'VDK', name: 'VDK' },
      KPK: { label: 'KPK', name: 'KPK' },
      KFK: { label: 'KFK', name: 'KFK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      SK: { label: 'SK', name: 'SK' },
      SUTVG: { label: 'SUTVG', name: 'SUTVG' },
      SUTVSG: { label: 'SUTVSG', name: 'SUTVSG' },
      SUTVS: { label: 'SUTVS', name: 'SUTVS' },
      S101: { label: 'S101', name: 'S101' },
      S102: { label: 'S102', name: 'S102' },
      S103: { label: 'S103', name: 'S103' },
      S104: { label: 'S104', name: 'S104' },
      SUTVD: { label: 'SUTVD', name: 'SUTVD' },
      S201: { label: 'S201', name: 'S201' },
      S202: { label: 'S202', name: 'S202' },
      SUTVI: { label: 'SUTVI', name: 'SUTVI' },
      SUTVR: { label: 'SUTVR', name: 'SUTVR' },
      SUTV1: { label: 'SUTV1', name: 'SUTV1' },
      SUTV2: { label: 'SUTV2', name: 'SUTV2' },
      SUTV3: { label: 'SUTV3', name: 'SUTV3' },
      SUTV4: { label: 'SUTV4', name: 'SUTV4' },
      SUTV5: { label: 'SUTV5', name: 'SUTV5' },
      SUTV6: { label: 'SUTV6', name: 'SUTV6' },
      SUTV7: { label: 'SUTV7', name: 'SUTV7' },
      SUTV8: { label: 'SUTV8', name: 'SUTV8' },
      SUTV9: { label: 'SUTV9', name: 'SUTV9' },
      SUTV10: { label: 'SUTV10', name: 'SUTV10' },
      SUTV11: { label: 'SUTV11', name: 'SUTV11' },
      SUTV12: { label: 'SUTV12', name: 'SUTV12' },
      NR: { label: 'NR', name: 'NR' },
      S203: { label: 'S203', name: 'S203' },
      S204: { label: 'S204', name: 'S204' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'exportPlansAndChangesToDBF',
  backendName: 'ЭкспортПлановИИзмененийВДБФ',
  frontend: 'dp/exportPlansAndChangesToDBF',
  label: 'Вивантаження змін в ДБФ',
  columns,
  tables,
};

export default definition;
