import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../../../components/styledAssigmButton/assigmButton';
import { UniArrows } from '../../../../assets/icons';

const SButtonGroups = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
`;

const SButton = styled(Button)`
  background: none;
  //width: 16px;
  //height: 12px;
`;
function NavButtons({
  canLeft, canRight,
  onLeft, onRight,
}) {
  return (
    <SButtonGroups>
      <SButton onClick={onRight} disabled={!canRight}>
        <UniArrows direction="right" />
      </SButton>
      <SButton onClick={onLeft} disabled={!canLeft}>
        <UniArrows direction="left" />
      </SButton>
    </SButtonGroups>
  );
}

NavButtons.propTypes = {
  canLeft: PropTypes.bool,
  canRight: PropTypes.bool,
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,

};

NavButtons.defaultProps = {
  canLeft: true,
  canRight: true,
};

export default NavButtons;
