import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function SelectButton({ content, onClick, disabled }) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faCheck}
    />
  );
}

SelectButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectButton.defaultProps = {
  onClick: () => null,
  content: 'Обрати',
  disabled: false,
};

export default SelectButton;
