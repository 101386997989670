import { useMemo } from 'react';

/**
 * Находим дубликаты строк по указанным ключам
 * @param rows {{}[]}
 * @param dublicateKeys {string[]}
 */
const useDublicates = ({ rows, dublicateKeys }) => {
  const dublicates = useMemo(
    () => {
      const getRowValues = (row, field) => {
        if (typeof row[field] === 'object' && row[field] && row[field].id) {
          return row[field].id;
        }
        return row[field];
      };
      const getSetValue = (row) => dublicateKeys.map((f) => String(getRowValues(row, f))).join('|');
      const s = new Set(rows.map(
        (row) => getSetValue(row),
      ));
      return [...s]
        .map((ss) => ({
          key: ss,
          count: rows.filter((row) => getSetValue(row) === ss).length,
        }))
        .filter((ss) => ss.count > 1);
    },
    [dublicateKeys, rows],
  );
  return {
    dublicates,
  };
};

export default useDublicates;
