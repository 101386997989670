import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  FI: { label: 'Розпорядник для якого потрібно заповнити деталізацію', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  TypeCat: { label: 'Довідник', name: 'СправочникДляСоздания' },
};

/**
 * @const
 */
const definition = {
  name: 'createElement',
  backendName: 'СоздатьЭлементКЕКВ',
  frontend: 'dp/createElement',
  label: 'Заповнення згідно класифікаторів',
  columns,
};

export default definition;
