import React, { forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import ListerPrintButton from './listerPrintButton';
import ListerDBFModal from './listerDBFButton';

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  >
    <ButtonGroup>
      <ListerPrintButton />
      <ListerDBFModal />
    </ButtonGroup>

  </DocListerCommandPanel>
));

export default CommandPanel;
