import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import File from '../../notifier/file';
import { deleteAttachedFile, upLoadAttachedFiles, addFiles } from '../../../actions/editor';
import { Title, SubTitle } from '../../../components/Form/styledForm';
import Loader from '../../../components/styledLoading';

import {
  DivStyledUpload,
  FileInput,
  FilesContainer,
  ContainerFile,
  CloseButton,
  ContainerHeader,
} from '../../../components/uploadFiles/styles';
import storePathParam from '../../../common/storePathParam';

function AttachedFilesList({
  dispatch,
  AttachedFiles,
  isLoadingAttachedFiles,
  isLocked,
  noStyle,
}) {
  // const { isLocked } =this.props;
  const handleLoadFile = (e) => {
    const files = [...e.target.files];

    Promise.all(files.map((f) => f)).then((results) => {
      const choiseFiles = results.reduce(
        (R, ffs, i) => [
          ...R,
          ...[{
            file: ffs,
            done: false,
            task: i,
          }],
        ],
        [],
      );

      dispatch(upLoadAttachedFiles(choiseFiles));
    });
  };

  const fileGetter = async (file) => [file];

  const scanFiles = (item, callback) => {
    if (item.isDirectory) {
      const DR = item.createReader();
      DR.readEntries((items) => {
        items.map((i) => this.scanFiles(i, callback));
      });
    } else {
      callback(item);
    }
  };

  const fileLoadHandler = (e) => {
    const { items } = e.dataTransfer;

    e.preventDefault();
    let f = [];
    Object.values(items).forEach((i) => scanFiles(i.webkitGetAsEntry(), (item) => {
      item.file((file) => {
        fileGetter(file).then((files) => {
          if (files) {
            f = [...f, ...files.filter((ff) => !!ff).map((fl, ii) => ({
              file: fl,
              done: false,
              task: ii,
            }))];
            dispatch(upLoadAttachedFiles(f));
          }
        });
      });
    }));
  };

  return (
    <ContainerFile
      onDragOver={(e) => e.preventDefault()}
      onDrop={fileLoadHandler}
      noStyle={noStyle}
    >
      <ContainerHeader>
        <Title>Додані файли</Title>
        <CloseButton
          noStyle={noStyle}
          text="Закрити"
          label="x"
          onClick={() => dispatch(addFiles())}
        />
      </ContainerHeader>
      {!isLoadingAttachedFiles ? (
        <Loader />
      ) : (
        <FilesContainer>
          {AttachedFiles.map((file) => (
            <File
              key={file.name}
              file={file}
              isLocked={isLocked}
              onDeleteFile={() => dispatch(deleteAttachedFile(file.get('fileName', '')))}
            />
          ))}
        </FilesContainer>
      )}
      <div>
        <SubTitle>
          Оберіть файли для завантаження або перетяніть їх до блакитної зони
        </SubTitle>
        <DivStyledUpload>
          <FileInput
            onChange={handleLoadFile}
          />
          <span>Оберіть файл</span>
        </DivStyledUpload>
      </div>
    </ContainerFile>
  );
}

AttachedFilesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  AttachedFiles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  isLoadingAttachedFiles: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  noStyle: PropTypes.bool,
};

AttachedFilesList.defaultProps = {
  noStyle: false,
};

const mapState = (store) => {
  const editorNode = store.getIn([storePathParam(store).node, storePathParam(store).id], new Map());
  return {
    AttachedFiles: editorNode.get('AttachedFiles', []),
    isLoadingAttachedFiles: editorNode.get('isLoadingAttachedFiles', false),
    isLocked: editorNode.get('isLocked', false),
  };
};

export default connect(mapState)(AttachedFilesList);
