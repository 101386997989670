import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  isForecast: { label: 'Це повідомлення про розгляд прогнозів радою', name: 'СообщениеПоПрогнозу', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  year: { label: 'Рік планування (для рішення сесії)', name: 'Год', visible: false },
  nDoc: { label: 'Номер документу', name: 'НомерДокумента', visible: true },
  proj: { label: 'Проект  рішення', name: 'Проект', visible: true },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  SignVariant: { label: 'Варіант підпису', name: 'Подпись' },
  docId: { label: 'id документа', name: 'id' },
  forecastYear: { label: 'Рік планування (для прогнозів)', name: 'ГодОтправкиПрогноза' },
};

/**
 * @const
 */

const definition = {
  label: 'Повідомлення про прийняття рішеня місцевою радою',
  name: 'decisionNotification',
  backendName: 'СообщениеОРешенииСессии',
  columns,
  frontend: 'doc/decisionNotification',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'isForecast',
      'nDoc',
      'proj',
      'budget',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
