import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/sourceAssigment';
import Fields from '../../field';
import GetNumberField from '../../field/NumberInput';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  SevenColumnsGridContainer,
  ThreeColumnsGridContainer,
  StyledLabel,
  ContainerDiv,
  TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import ItemField from '../../field/selectorField/item/itemField';
import DateRangeField from '../../../components/fields/DateRangeField';
import ReportContainer from '../reportContainer';

const YearInput = GetNumberField(4, 0, false);

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const FourColumns = styled(SevenColumnsGridContainer)`
  grid-template-columns: 15% 15% 20% 47%;
  grid-column-gap: 1%;
`;

class ReportSourceAssigmentEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    sessionOptions: new Map(),
    portalOpened: false,
    onReportDetails: null,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const ReportTypes = {
      plan: { label: 'Розпис', value: 1 },
      update: { label: 'Уточнення', value: 2 },
      all: { label: 'Розпис+уточнення', value: 3 },
    };

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );

    const ElemFond = headerForm.get('byDetails', false) && (
      <div>
        <StyledLabel>{md.columns.elementFond.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('elementFond', new Map())}
          modelType="cat"
          filter={headerForm.get('FO', new Map())}
          modelName="elementFond"
          onChange={(e, v) => dispatch(changeField(['headerForm', 'elementFond'], v))}
        />
      </div>
    );

    const SettingsButton = (
      <div>
        <ThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('consolidated', false)}
              label={md.columns.consolidated.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('approved', false)}
              label={md.columns.approved.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
            />
          </div>
        </ThreeColumnsGridContainer>
        <FourColumns>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => (
                { label: _e.label, value: _e.name }))}
              name="planType"
            />
            <Fields.CheckboxField
              value={headerForm.get('in1000', false)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('ignoreSign', false)}
              label={md.columns.ignoreSign.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'ignoreSign'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('byYear', false)}
              label={md.columns.byYear.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byYear'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byDetails', false)}
              label={md.columns.byDetails.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byDetails'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('incDB', false)}
              label={md.columns.incDB.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'incDB'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byDocuments', false)}
              label={md.columns.byDocuments.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byDocuments'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('changeSumSign', false)}
              label={md.columns.changeSumSign.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'changeSumSign'], v))}
            />
          </div>
          <div>
            <div>
              <TwoColumnsGridContainer>
                <div>
                  <StyledLabel>{md.columns.fondClassifier.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('fondClassifier', new Map())}
                    modelType="cat"
                    modelName="fondClassifier"
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'fondClassifier'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.kfbClassifier.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('kfbClassifier', new Map())}
                    modelType="cat"
                    modelName="kfbClassifier"
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'kfbClassifier'], v))}
                  />
                </div>
              </TwoColumnsGridContainer>
              {ElemFond}
            </div>
            <div>
              <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('signVariant', new Map())}
                modelType="cat"
                modelName="signatureVariants"
                onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
              />
            </div>
          </div>
        </FourColumns>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
          <div style={{ width: '25%' }}>
            <StyledLabel>{md.columns.year.label}</StyledLabel>
            <YearInput
              border
              radius
              value={headerForm.get('year', 5)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportSourceAssigmentEditor);
