const frontendURL = '/doc/incomingMoney/';
const backendURL = '/api/financials/fundsreceiptsheader/';
const name = 'Надходження коштів на рахунки';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
