import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';

function PublicPolicyTarget({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'public_policy_target_text',
        },
      ],
    }),
    [],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbppublicpolicytarget_set"
    />
  );
}

PublicPolicyTarget.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
};

export default PublicPolicyTarget;
