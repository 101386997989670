import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Map } from 'immutable';
import { ItemContainer, ItemText } from '../StyledComponents';
import Checkbox from '../../../../components/styledCheckbox/styledCheckbox';
import { SortUpIcon, SortDownIcon, MinusIcon } from '../../../../assets/icons';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';

const StyledItemContainer = Styled(ItemContainer)`
  & > :nth-child(2){
    flex-grow: 1;
  }
`;

class OrderItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    item: PropTypes.instanceOf(Map).isRequired,
    defaultFieldTittle: PropTypes.string,
    toggleUseHandler: PropTypes.func,
    onClickHandler: PropTypes.func,
    onDoubleClickHandler: PropTypes.func,
    onChangeSortHanlder: PropTypes.func,
    iRef: PropTypes.func,
  };

  static defaultProps = {
    defaultFieldTittle: '',
    toggleUseHandler: () => null,
    onClickHandler: () => null,
    onDoubleClickHandler: () => null,
    onChangeSortHanlder: () => null,
    iRef: () => null,
  };

  getItemIcon = (item) => {
    const { onChangeSortHanlder, id } = this.props;
    const onClick = (e) => {
      if (item.get('Type', '') !== DC.fieldTypes.Auto) {
        onChangeSortHanlder(
          e,
          id,
          item.get('OrderType', '') === DC.orderTypes.Ascending ? DC.orderTypes.Descending : DC.orderTypes.Ascending,
        );
      }
    };

    switch (item.get('itemType')) {
      case DC.fieldTypes.Auto:
        return (
          <MinusIcon onClick={onClick} />
        );
      default:
        return item.get('OrderType') === DC.orderTypes.Ascending ? (
          <SortUpIcon onClick={onClick} />
        ) : (
          <SortDownIcon onClick={onClick} />
        );
    }
  };

  getItemTitle = () => {
    const { item, defaultFieldTittle } = this.props;

    const itemTittle = item.get('Tittle', '');

    if (item.get('Type') === DC.fieldTypes.Auto) {
      return 'Авто';
    }
    return itemTittle ? `${itemTittle}(${defaultFieldTittle})` : defaultFieldTittle;
  };

  render() {
    const {
      id, item, toggleUseHandler, onClickHandler, onDoubleClickHandler,
      iRef,
      dragHandleProps,
      draggableProps,

    } = this.props;
    return (
      <StyledItemContainer
        active={item.get('_SELECTED', false)}
        onClick={(e) => onClickHandler(e, id)}
        onDoubleClick={(e) => onDoubleClickHandler(e, id)}
        ref={iRef}
        {...dragHandleProps}
        {...draggableProps}
      >
        <Checkbox
          value={item.get('Use', false)}
          onChange={(e) => toggleUseHandler(e, id)}
        />
        <ItemText>
          {this.getItemTitle()}
        </ItemText>
        {this.getItemIcon(item)}
      </StyledItemContainer>
    );
  }
}

export default OrderItem;
