const frontendURL = '/cat/bfnBanDoc/';
const backendURL = '/api/bfn/refbandocbfn/';
const name = 'Заборона на внесення змін, щодо структури закладів дошкільної освіти';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
