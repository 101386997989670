import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const kdbProps = tableHooks.useItemInputProps('kdb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const personBudgetProps = tableHooks.useItemInputProps('person_budget', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const zagFondProps = tableHooks.useNumberInputProps('zag_fond', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const specFondProps = tableHooks.useNumberInputProps('spec_fond', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const total = (zagFondProps.value || 0) + (specFondProps.value || 0);

  return (
    <>
      <Col sm={3}>
        <TabelRowCell column="kdb" highlighted={highlights.includes('kdb')}>
          <TableControls.ItemPicker {...kdbProps} />
        </TabelRowCell>
      </Col>
      <Col sm={3}>
        <TabelRowCell column="person_budget" highlighted={highlights.includes('person_budget')}>
          <TableControls.ItemPicker {...personBudgetProps} />
        </TabelRowCell>
      </Col>

      <Col>
        <TabelRowCell column="zag_fond" highlighted={highlights.includes('zag_fond')}>
          <TableControls.NumberInput {...zagFondProps} />
        </TabelRowCell>
      </Col>

      <Col>
        <TabelRowCell column="spec_fond" highlighted={highlights.includes('spec_fond')}>
          <TableControls.NumberInput {...specFondProps} />
        </TabelRowCell>
      </Col>

      <Col className="d-flex justify-content-end align-items-center font-weight-bold">
        <TabelRowCell column="total" highlighted={highlights.includes('total')}>
          {total.toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
