import React, {
  lazy, Suspense, useMemo, useState, useEffect, memo,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import Fields from '../../field';
import DateField from '../../../components/fields/dates';
import DateRangeField from '../../../components/fields/DateRangeField';
import Enums from '../../../constants/meta/enums';
import {
  MyContainerFormWithNColumns,
  StyledLabel,
  FramePrintModal,
  TwoColGridContainer, TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { SettingsIcon } from '../../../assets/icons';
import { InputWithDropdown } from '../../../components/styledInputs';
import { ContainerButtonTop } from '../../tableEditor/styles';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

const TableEditor = lazy(() => import('../../tableEditor'));

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.finPrintingBR;

const StringField3 = Fields.GetTextInput(3);
const StringField50 = Fields.GetTextInput(50);

const PageOrientationValues = Object.values(Enums.PageOrientation);

const CSUCodes = {
  EDRPOU: { label: 'ЄДРПОУ', name: 0 },
  ByNetwork: { label: 'За мережею', name: 1 },
};

const InFins = {
  fin: { label: 'Фінансування', name: 0 },
  finPlzt: { label: 'Фінансування + Платіжне доручення', name: 1 },
};

const MyTwoColumnsGridContainer = styled(TwoColumnsGridContainer)`
  grid-template-columns: 70% 20%;
  grid-column-gap: 2%;
`;

function FinPrintingBREditor() {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );
  return (
    <MainContainer>
      <div>
        <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
        <Fields.SelectorField
          modelType="cat"
          modelName="signatureVariants"
          value={headerForm.get('SignVariant', new Map())}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
          filter={[
            { fieldName: 'Владелец', value: headerForm.get('FI') },
          ]}
        />
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal>
          <TwoColGridContainer>
            <div>
              <StyledLabel>{md.columns.Budget.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Budget'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.FI.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FI'], v))}
              />
            </div>
          </TwoColGridContainer>
          <TwoColGridContainer>
            <div>
              <StyledLabel>{md.columns.Period.label}</StyledLabel>
              <DateRangeField
                value={headerForm.get('Period', new Map())}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Period'], v))}
                noBorder
              />
            </div>
            <div>
              <StyledLabel>{md.columns.ElFond.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('ElFond', new Map())}
                modelType="cat"
                modelName="elementFond"
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ElFond'], v))}
              />
            </div>
          </TwoColGridContainer>
          <MyContainerFormWithNColumns columns={5}>
            <div>
              <Fields.CheckboxField
                label={md.columns.ShowPrintTime.label}
                value={headerForm.get('ShowPrintTime', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowPrintTime'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.ShowGrid.label}
                value={headerForm.get('ShowGrid', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowGrid'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.ShowSumWords.label}
                value={headerForm.get('ShowSumWords', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowSumWords'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.NoNote.label}
                value={headerForm.get('NoNote', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoNote'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.NoKFB.label}
                value={headerForm.get('NoKFB', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoKFB'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
          <MyTwoColumnsGridContainer>
            <div>
              <StyledLabel>{md.columns.Accepted.label}</StyledLabel>
              <StringField50
                radius
                background
                textLeft
                value={headerForm.get('Accepted', '')}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Accepted'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.SignDate.label}</StyledLabel>
              <DateField
                value={headerForm.get('SignDate', 0)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignDate'], v))}
              />
            </div>
          </MyTwoColumnsGridContainer>
          <MyContainerFormWithNColumns columns={5}>
            <div>
              <StyledLabel>{md.columns.PageOrientation.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('PageOrientation', false)}
                options={PageOrientationValues}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PageOrientation'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.GroupTable.label}
                value={headerForm.get('GroupTable', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'GroupTable'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.CSUCode.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('CSUCode', false)}
                options={Object.values(CSUCodes)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUCode'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.MoneysCode.label}</StyledLabel>
              <StringField3
                radius
                background
                value={headerForm.get('MoneysCode', '')}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'MoneysCode'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.FillAllRows.label}
                value={headerForm.get('FillAllRows', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FillAllRows'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={5}>
            <Fields.CheckboxField
              label={md.columns.ByGeneralCSU.label}
              value={headerForm.get('ByGeneralCSU', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ByGeneralCSU'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.DIVCSUs.label}
              value={headerForm.get('DIVCSUs', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DIVCSUs'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.CSUTotals.label}
              value={headerForm.get('CSUTotals', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUTotals'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.KEKVTotals.label}
              value={headerForm.get('KEKVTotals', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKVTotals'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.CSUTotalDown.label}
              value={headerForm.get('CSUTotalDown', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUTotalDown'], v))}
            />
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={2}>
            <Fields.CheckboxField
              label={md.columns.ByFI.label}
              value={headerForm.get('ByFI', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ByFI'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.PrintKVKTotals.label}
              value={headerForm.get('PrintKVKTotals', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKVKTotals'], v))}
            />
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={2}>
            <Fields.CheckboxField
              label={md.columns.AdditionalKEKV.label}
              value={headerForm.get('AdditionalKEKV', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AdditionalKEKV'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.PrintKPKV.label}
              value={headerForm.get('PrintKPKV', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKPKV'], v))}
            />
          </MyContainerFormWithNColumns>
          <div>
            <StyledLabel>{md.columns.InFin.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('InFin', false)}
              options={Object.values(InFins)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'InFin'], v))}
            />
          </div>
        </FramePrintModal>
      ) : (
        <Suspense fallback={<div>Завантаження...</div>}>
          <TableEditor mountNode={resultMountNode} />
        </Suspense>
      )}
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: TPDocs, // Табличные части
    },
    memo,
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(FinPrintingBREditor);
