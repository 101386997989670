import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Alert, Container, Spinner,
} from 'react-bootstrap';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AppContext } from '../../providers/authProvider';
import api from '../../api/req';
import { HeaderText } from '../../components/Styled/Forms';
import CommandPanel from '../../components/bootStrap/commandPanel';
import { GenerateButton } from '../../components/bootStrap/buttons/generateButton';
import { CPButton } from '../../components/bootStrap/buttons/styles';
import { AutoHeightContainer } from '../../components/bootStrap';
import HtmlPrintForm from '../../components/bootStrap/htmlPrintForm/printResult';
import { childrenPropType } from '../../../common/proptypes';

const StyledDiv = styled.div`
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: right;
  background: white;
  box-shadow: #e0e0e0 5px 5px 5px;
  padding: 1rem;
`;

function HTMLPrntFormEditor({ printUrl, children, printData }) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [options, setOptions] = useState({});
  const [result, setResult] = useState(null);
  const { auth } = useContext(AppContext);
  useEffect(() => {
    const loader = async () => {
      const resp = await api.options(
        printUrl,
        auth,
      );
      if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText}`);
      return resp.json();
    };
    setErr(null);
    setLoading(true);
    loader()
      .then(((o) => {
        setOptions(o);
      }))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [printUrl, auth]);
  const generateReport = useCallback(
    () => {
      const loader = async () => {
        const resp = await api.post(
          printUrl,
          auth,
          printData,
        );
        if (!resp.ok && resp.status === 422) {
          const eData = await resp.json();
          if ('error_data' in eData) {
            const messages = eData.error_data.reduce((R, i) => [...R, ...i.messages], []).join('\n');
            throw new Error(messages);
          }
        }
        if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText}`);
        const res = await resp.json();
        return decodeURIComponent(escape(atob(res.result)));
      };
      setErr(null);
      setLoading(true);
      loader()
        .then(((r) => {
          setResult(r);
        }))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, printData, printUrl],
  );
  const onPrint = useCallback(
    () => window.print(),
    [],
  );
  return (
    <Container fluid>
      {options.description && (
        <HeaderText>
          {options.description}
        </HeaderText>
      )}
      {err && (
        <Alert variant="danger" onClose={() => setErr(null)} dismissible>
          <Alert.Heading>Вибачте, але щось пішло не так</Alert.Heading>
          <p>
            {err}
          </p>
        </Alert>
      )}
      {children}
      <CommandPanel>
        <GenerateButton
          disabled={loading}
          onClick={generateReport}
          content="Сформувати звіт"
        />
        <CPButton
          onClick={onPrint}
          title="Друк"
          icon={faPrint}
          disabled={!result}
        />
      </CommandPanel>
      {loading && (
        <StyledDiv className="border">
          <Spinner animation="border" className="me-2 text-dark" />
          <div>
            <div className="text-dark h5">Треба трохи зачекати, але воно того варте...</div>
            {/* <Button */}
            {/*  variant="link" */}
            {/*  className="text-danger small" */}
            {/*  onClick={handleGenerateReport} */}
            {/* > */}
            {/*  <FontAwesomeIcon icon={faTimes} className=" me-1" /> */}
            {/*  Скасувати запит */}
            {/* </Button> */}
          </div>
        </StyledDiv>
      )}
      <AutoHeightContainer>
        <HtmlPrintForm result={result} />
      </AutoHeightContainer>
    </Container>
  );
}

HTMLPrntFormEditor.propTypes = {
  printUrl: PropTypes.string.isRequired,
  children: childrenPropType,
  printData: PropTypes.shape({}),
};

HTMLPrntFormEditor.defaultProps = {
  children: null,
  printData: {},
};

export default memo(HTMLPrntFormEditor);
