import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BasedGrid = styled.div`
  display: grid; 
  grid-template-columns: 1fr  ;
  grid-auto-columns: 100px;
  grid-auto-rows: auto;
`;

const generateNewGrid = () => styled(BasedGrid)`
      grid-template-areas:  "projectPublicBudget id sum noteDanger publicBudgetProjectAuthor";
    `;

class GridTemplate extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = { GridTemplate: generateNewGrid(props) };
  }

  render() {
    const {
      children,
    } = this.props;

    const Grid = this.state.GridTemplate;
    return (
      <Grid>
        {children}
      </Grid>
    );
  }
}

export const DivHeader = styled.div`
  grid-area: ${(props) => props.areaName};
  border: 1px gray dotted ;
  margin: 5px;
`;

export const DivFooter = styled(DivHeader)`
  grid-area: ${(props) => props.areaName};
  text-align: right;
`;

export const DivCell = styled.div`
  padding: 0.08em;
  margin-right: 0.2em;
  grid-area: ${(props) => props.areaName};
`;

export default GridTemplate;
