import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'h8wwDrEbzZQ',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Доходи',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
    },
  },
  financing: {
    name: 'Финансирование',
    label: 'Фінансування',
    columns: {
      financingGF_Report: { label: '20__ рік  (звіт)', name: 'ФинансированиеЗФ_Отчет' },
      financingGF_Approved: { label: '20__ рік  (затверджено)', name: 'ФинансированиеЗФ_Утверждено' },
      financingGF_Plan: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План' },
      financingGF_Plan1: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План1' },
      financingGF_Plan2: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План2' },
      financingSF_Report: { label: '20__ рік  (звіт)', name: 'ФинансированиеСФ_Отчет' },
      financingSF_Approved: { label: '20__ рік  (затверджено)', name: 'ФинансированиеСФ_Утверждено' },
      financingSF_Plan: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План' },
      financingSF_Plan1: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План1' },
      financingSF_Plan2: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План2' },
    },
  },
  creditReturn: {
    name: 'ВозвращениеКредитов',
    label: 'Повернення кредитів',
    columns: {
      creditReturnGF_Report: { label: '20__ рік  (звіт)', name: 'ВозвращениеКредитовЗФ_Отчет' },
      creditReturnGF_Approved: { label: '20__ рік  (затверджено)', name: 'ВозвращениеКредитовЗФ_Утверждено' },
      creditReturnGF_Plan: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовЗФ_План' },
      creditReturnGF_Plan1: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовЗФ_План1' },
      creditReturnGF_Plan2: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовЗФ_План2' },
      creditReturnSF_Report: { label: '20__ рік  (звіт)', name: 'ВозвращениеКредитовСФ_Отчет' },
      creditReturnSF_Approved: { label: '20__ рік  (затверджено)', name: 'ВозвращениеКредитовСФ_Утверждено' },
      creditReturnSF_Plan: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовСФ_План' },
      creditReturnSF_Plan1: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовСФ_План1' },
      creditReturnSF_Plan2: { label: '20__ рік  (план)', name: 'ВозвращениеКредитовСФ_План2' },
    },
  },
  credit: {
    name: 'Кредитование',
    label: 'Кредитування',
    columns: {
      creditGF_Report: { label: '20__ рік  (звіт)', name: 'КредитованиеЗФ_Отчет' },
      creditGF_Approved: { label: '20__ рік  (затверджено)', name: 'КредитованиеЗФ_Утверждено' },
      creditGF_Plan: { label: '20__ рік  (план)', name: 'КредитованиеЗФ_План' },
      creditGF_Plan1: { label: '20__ рік  (план)', name: 'КредитованиеЗФ_План1' },
      creditGF_Plan2: { label: '20__ рік  (план)', name: 'КредитованиеЗФ_План2' },
      creditSF_Report: { label: '20__ рік  (звіт)', name: 'КредитованиеСФ_Отчет' },
      creditSF_Approved: { label: '20__ рік  (затверджено)', name: 'КредитованиеСФ_Утверждено' },
      creditSF_Plan: { label: '20__ рік  (план)', name: 'КредитованиеСФ_План' },
      creditSF_Plan1: { label: '20__ рік  (план)', name: 'КредитованиеСФ_План1' },
      creditSF_Plan2: { label: '20__ рік  (план)', name: 'КредитованиеСФ_План2' },
    },
  },
  expenses: {
    name: 'Расходы',
    label: 'Видатки',
    columns: {
      expensesGF_Report: { label: '20__ рік  (звіт)', name: 'РасходыЗФ_Отчет' },
      expensesGF_Approved: { label: '20__ рік  (затверджено)', name: 'РасходыЗФ_Утверждено' },
      expensesGF_Plan: { label: '20__ рік  (план)', name: 'РасходыЗФ_План' },
      expensesGF_Plan1: { label: '20__ рік  (план)', name: 'РасходыЗФ_План1' },
      expensesGF_Plan2: { label: '20__ рік  (план)', name: 'РасходыЗФ_План2' },
      expensesSF_Report: { label: '20__ рік  (звіт)', name: 'РасходыСФ_Отчет' },
      expensesSF_Approved: { label: '20__ рік  (затверджено)', name: 'РасходыСФ_Утверждено' },
      expensesSF_Plan: { label: '20__ рік  (план)', name: 'РасходыСФ_План' },
      expensesSF_Plan1: { label: '20__ рік  (план)', name: 'РасходыСФ_План1' },
      expensesSF_Plan2: { label: '20__ рік  (план)', name: 'РасходыСФ_План2' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Загальні показники бюджету (Додаток 1)',
  name: 'forecastAdd1',
  backendName: 'ПрогнозДод1',
  columns,
  frontend: 'doc/forecastAdd1',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
