import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  UkrName: { label: 'Назва валюти українська', name: 'ПолноеНаименованиеУкр', visible: true },
  EngName: { label: 'Назва валюти міжнародна', name: 'ПолноеНаименованиеАнгл', visible: true },
};

export const currency = {
  label: 'Статистична класифікація валют',
  name: 'currency',
  columns,
  backendName: 'КлассификаторВалют',
  defaultOrder,
  frontend: 'cat/currency',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'UkrName',
      'EngName',
    ]),
  ],
  hierarchy: false,
};

export default currency;
