import React, { useEffect, useMemo, useRef } from 'react';
import {
  Col, Container, Nav, Row, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PropTypes from 'prop-types';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
// eslint-disable-next-line import/no-cycle
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/reduceIndicatorsSF';
import ReportHeader from '../_common/reportHeader';

const URL = instance.backendURL;

function Rep({ instanceId }) {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL, instanceId });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const signvariantProps = editorHooks.useItemInputProps('signvariant', data, fields, fieldErrors, actions.onChange);
  const reportTypeProps = editorHooks.useSelectorInputProps('report_type', data, fields, fieldErrors, actions.onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const inThousandsProps = editorHooks.useCheckboxInputProps('in_thousands', data, fields, fieldErrors, actions.onChange);
  const emptyValuesAs = editorHooks.useSelectorInputProps('empty_value', data, fields, fieldErrors, actions.onChange);
  const moneyKindProps = editorHooks.useSelectorInputProps('money_kind', data, fields, fieldErrors, actions.onChange);
  const byKbp7Props = editorHooks.useCheckboxInputProps('by_kbp7', data, fields, fieldErrors, actions.onChange);
  const useKfbProps = editorHooks.useCheckboxInputProps('use_kfb', data, fields, fieldErrors, actions.onChange);
  const formProps = editorHooks.useSelectorInputProps('form', data, fields, fieldErrors, actions.onChange);
  const useKekvProps = editorHooks.useSelectorInputProps('use_kekv', data, fields, fieldErrors, actions.onChange);
  const reestrNumberProps = editorHooks.useStringInputProps('reestr_number', data, fields, fieldErrors, actions.onChange);
  const reestrDateProps = editorHooks.useDateInputProps('reestr_date', data, fields, fieldErrors, actions.onChange);

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const { onChange } = actions;
  const filtersLoaded = useRef(false);
  useEffect(
    () => {
      if (filtersLoaded.current) onChange(() => ({ filters }));
    },
    [filters, onChange],
  );
  useEffect(
    () => {
      if (!filtersLoaded.current && data.filters) {
        loadFilters(data.filters);
        filtersLoaded.current = true;
      }
    },
    [data.filters, filters, filters.length, loadFilters],
  );
  const isReestr = data.form === 3;
  const Wrapper = useMemo(
    () => (instanceId ? 'div' : ReportHeader),
    [instanceId],
  );
  return (
    <Wrapper title={instance.name}>
      <PrintFormContainer
        loading={loading}
        err={err}
        actions={actions}
        ref={resultRef}
        pageLayout={pageLayout}
        quickSettings={(
          <Row>
            <Col md={3}>
              <EditorControls.DateInput {...bdateProps} />
            </Col>
            <Col md={3}>
              <EditorControls.DateInput {...edateProps} />
            </Col>
            <Col>
              <EditorControls.ItemPicker {...signvariantProps} />
            </Col>
          </Row>
      )}
        settings={(
          <TabContainer defaultActiveKey="general">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="general">Налаштування</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="filters">Фільтрування</Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent className="flex-fill overflow-hidden">
              <TabPane eventKey="general" className="h-100 scrollbar">
                <Container fluid>
                  <Row>
                    <Col md={4}>
                      <EditorControls.SelectorInput
                        {...reportTypeProps}
                        disabled={isReestr}
                        value={isReestr ? 2 : reportTypeProps.value}
                      />
                      <EditorControls.SelectorInput {...planKindProps} />
                      <EditorControls.SelectorInput {...formProps} />
                      <EditorControls.SelectorInput {...useKekvProps} />
                    </Col>
                    <Col md={4}>
                      <EditorControls.ItemPicker {...authorityProps} />
                      <EditorControls.SelectorInput {...moneyKindProps} />
                      <EditorControls.SelectorInput {...emptyValuesAs} />
                      {isReestr && (
                        <div className="d-flex gap-2">
                          <div className="w-100">
                            <EditorControls.StringInput {...reestrNumberProps} />
                          </div>
                          <div className="w-100">
                            <EditorControls.DateInput {...reestrDateProps} />
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={4} className="border-left">
                      <EditorControls.CheckboxInput {...byKbp7Props} />
                      <EditorControls.CheckboxInput {...useKfbProps} />
                      <EditorControls.CheckboxInput {...inThousandsProps} />
                    </Col>
                  </Row>
                </Container>
              </TabPane>
              <TabPane eventKey="filters" className="h-100">

                <DndProvider backend={HTML5Backend}>
                  <FiltersEditor
                    filters={displayFilters}
                    filtersHandlers={filtersHandlers}
                    availableFilters={availableFilters}
                  />
                </DndProvider>
              </TabPane>
            </TabContent>
          </TabContainer>
      )}
      />
    </Wrapper>
  );
}

Rep.propTypes = {
  instanceId: PropTypes.number,
};

Rep.defaultProps = {
  instanceId: '',
};

export default Rep;
