import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import meta from '../../../../meta';
import HistoricalValues from './history';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { CiatAppContext } from '../../../../providers';
import EditorFooter from '../../../../components/Containers/editorFooter';

const BACKENDURL = meta.cat.task.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const title = useMemo(
    () => {
      if (!data || !data.history) return '';
      return data.history.name;
    },
    [data],
  );
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const budgetProps = editorHooks.useItemInputProps('budget', data, fields, fieldErrors, actions.onChange);

  const { currentUser } = useContext(CiatAppContext);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={title}
    >
      {data.is_group && (
      <div className="w-100 text-end">
        <Badge bg="info">Група</Badge>
      </div>
      )}
      <Row className="mb-2 border-bottom">
        <Col>
          <EditorControls.ItemPicker {...parentProps} />
        </Col>
        {currentUser.is_superuser && (
          <Col>
            <EditorControls.ItemPicker {...budgetProps} />
          </Col>
        )}
      </Row>
      <HistoricalValues
        id={id}
        data={data}
        actions={actions}
        fields={fields}
        errors={fieldErrors}
      />
      <EditorFooter>
        <div className="text-muted">{data.budget ? data.budget.repr : ''}</div>
      </EditorFooter>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
