import React from 'react';
import Lister from '../../lister/lister';

const modelName = 'budgetElChTP';
const modelType = 'ChTP';

function BudgetElChTPLister() {
  return (
    <Lister
      modelType={modelType}
      modelName={modelName}
    />
  );
}

export default BudgetElChTPLister;
