/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from './Flex';

const ReportsItem = ({ report }) => (
  <Col lg={6} xxl={4} >
    <Flex
      alignItems="center"
      className="px-4 py-x1 bg-light rounded-3 border position-relative"
    >
        <div style={{padding:'25px 0', display: 'flex'}}>
      <img src={report.icon} alt="" width="39" style={{marginRight: '15px'}}/>
      <div className="ms-3 my-x1">
        <h5 className="fs-0 fw-semi-bold mb-2">
          <a href={`/#/${report.def.frontend}/`} className="text-900 hover-primary stretched-link">
            {report.label}
          </a>
        </h5>
        {/*<h6 className="mb-0 text-600">{report.description}</h6>*/}
      </div>
        </div>
    </Flex>
  </Col>
);

ReportsItem.propTypes = {
  report: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    def: PropTypes.object.isRequired,
    primary: PropTypes.bool,
  }),
};

// const dispatch = useDispatch();

const Reports = ({items}) => (
  <Card>
    <Card.Header className="bg-light">
      <h6 className="mb-0">Звіти</h6>
    </Card.Header>
    <Card.Body>
      <h5 className="fs-0 mb-2">Звіти за видатками</h5>
      <Row className="g-3">
        {items.map((report, index) => (
          <ReportsItem
            key={index}
            report={report}
            // onClick={() => {
            //   dispatch(switchWindow(`/${report.def.frontend}/`));
            // }}
          />
        ))}
      </Row>
      {/*<h5 className="fs-0 mb-2 mt-5">Customer Satisfaction</h5>*/}
      {/*<Row className="g-3">*/}
      {/*  {reports.customerSatisfaction.map((report, index) => (*/}
      {/*    <ReportsItem key={index} report={report} />*/}
      {/*  ))}*/}
      {/*</Row>*/}
      {/*<h5 className="fs-0 mb-2 mt-5">Productivity</h5>*/}
      {/*<Row className="g-3">*/}
      {/*  {reports.productivity.map((report, index) => (*/}
      {/*    <ReportsItem key={index} report={report} />*/}
      {/*  ))}*/}
      {/*</Row>*/}
    </Card.Body>
  </Card>
);

export default Reports;
