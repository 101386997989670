import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.tasksSpent;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'tasks'}
                  highlighted={highlights.includes('tasks')}
                  onToggleHighlght={() => onToggleHighlght('tasks')}
                >
                  {meta.columns.tasks.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'elementKFK'}
                  highlighted={highlights.includes('elementKFK')}
                  onToggleHighlght={() => onToggleHighlght('elementKFK')}
                >
                  {meta.columns.elementKFK.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF'}
              highlighted={highlights.includes('sumGenF')}
              onToggleHighlght={() => onToggleHighlght('sumGenF')}
            >
              {meta.columns.sumGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumSpecF'}
                  highlighted={highlights.includes('sumSpecF')}
                  onToggleHighlght={() => onToggleHighlght('sumSpecF')}
                >
                  {meta.columns.sumSpecF.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumBR'}
                  highlighted={highlights.includes('sumBR')}
                  onToggleHighlght={() => onToggleHighlght('sumBR')}
                >
                  {meta.columns.sumBR.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumTotal'}
              highlighted={highlights.includes('sumTotal')}
              onToggleHighlght={() => onToggleHighlght('sumTotal')}
            >
              {meta.columns.sumTotal.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
