import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge, Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TotalCell = styled(TabelRowCell)`
  padding: 6px;
  text-align: right;
  font-weight: bold;
  color: var(--colorTextActiveRow);
`;

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authorityId, budgetId,
  disposerInHeader,
  separatelyLocalRegPrograms,
}) {
  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const disposerFilter = useMemo(
    () => ({ parent: authorityId }),
    [authorityId],
  );

  const kbp7ItemProps = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kbp7ItemFilter = useMemo(
    () => ({ owner: authorityId }),
    [authorityId],
  );

  const targetProgramProps = tableHooks.useItemInputProps(
    'target_programm',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const targetProgramFilter = useMemo(
    () => ({ owner: budgetId }),
    [budgetId],
  );

  const checkNegativeSumProps = tableHooks.useCheckboxInputProps(
    'check_negative_sum',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const totalZagFondProps = tableHooks.useNumberInputProps(
    'total_zag_fond',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const consumptionExpendituresZfProps = tableHooks.useNumberInputProps(
    'consumption_expenditures_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const utilityBillsZfProps = tableHooks.useNumberInputProps(
    'utility_bills_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const jobPaymentZfProps = tableHooks.useNumberInputProps(
    'job_payment_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const developmentCostZfProps = tableHooks.useNumberInputProps(
    'development_cost_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const totalSpecFondProps = tableHooks.useNumberInputProps(
    'total_spec_fond',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const consumptionExpendituresSfProps = tableHooks.useNumberInputProps(
    'consumption_expenditures_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const utilityBillsSfProps = tableHooks.useNumberInputProps(
    'utility_bills_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const totalDevelopmentBudgetProps = tableHooks.useNumberInputProps(
    'total_development_budget',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const jobPaymentSfProps = tableHooks.useNumberInputProps(
    'job_payment_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const developmentCostSfProps = tableHooks.useNumberInputProps(
    'development_cost_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const socEconimicalProgramsProps = tableHooks.useNumberInputProps(
    'soc_econimical_programs',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const noteProps = tableHooks.useStringInputProps(
    'note',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  return (
    <>
      <Col className="gx-1" sm={4}>
        <Row noGutters>
          {!disposerInHeader && (
          <Col sm={12}>
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposerProps}
                filter={disposerFilter}
                noHierarchy
                canErase={false}
              />
            </TabelRowCell>
          </Col>
          ) }
          <Col sm={separatelyLocalRegPrograms ? 12 : 6}>
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker {...kbp7ItemProps} filter={kbp7ItemFilter} />
            </TabelRowCell>
          </Col>
          {!separatelyLocalRegPrograms && (
          <Col sm={6}>
            <TabelRowCell column="target_programm" highlighted={highlights.includes('target_programm')}>
              <TableControls.ItemPicker
                {...targetProgramProps}
                filter={targetProgramFilter}
                canErase={false}
              />
            </TabelRowCell>
          </Col>
          )}
          <Col sm={3}>
            <TabelRowCell column="kbp" highlighted={highlights.includes('kbp')}>
              <Badge variant="info">{row.kbp && row.kbp.repr}</Badge>
            </TabelRowCell>
          </Col>
          <Col sm={3}>
            <TabelRowCell column="kbp7" highlighted={highlights.includes('kbp7')}>
              <Badge variant="info">{row.kbp7 && row.kbp7.repr}</Badge>
            </TabelRowCell>
          </Col>
          <Col sm={6}>
            <TabelRowCell column="check_negative_sum" highlighted={highlights.includes('check_negative_sum')}>
              <TableControls.CheckboxInput {...checkNegativeSumProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="gx-4" sm={3}>
        <Row noGutters>
          <Col className="gx-1" sm={12}>
            <TabelRowCell column="total_zag_fond" highlighted={highlights.includes('total_zag_fond')}>
              <TableControls.NumberInput {...totalZagFondProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={6}>
            <TabelRowCell column="consumption_expenditures_zf" highlighted={highlights.includes('consumption_expenditures_zf')}>
              <TableControls.NumberInput {...consumptionExpendituresZfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={6}>
            <TabelRowCell column="job_payment_zf" highlighted={highlights.includes('job_payment_zf')}>
              <TableControls.NumberInput {...jobPaymentZfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={6}>
            <TabelRowCell column="utility_bills_zf" highlighted={highlights.includes('utility_bills_zf')}>
              <TableControls.NumberInput {...utilityBillsZfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={6}>
            <TabelRowCell column="development_cost_zf" highlighted={highlights.includes('development_cost_zf')}>
              <TableControls.NumberInput {...developmentCostZfProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="gx-4" sm={4}>
        <Row noGutters>
          <Col className="gx-1" sm={12}>
            <TabelRowCell column="total_spec_fond" highlighted={highlights.includes('total_spec_fond')}>
              <TableControls.NumberInput {...totalSpecFondProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="consumption_expenditures_sf" highlighted={highlights.includes('consumption_expenditures_sf')}>
              <TableControls.NumberInput {...consumptionExpendituresSfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="job_payment_sf" highlighted={highlights.includes('job_payment_sf')}>
              <TableControls.NumberInput {...jobPaymentSfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="total_development_budget" highlighted={highlights.includes('total_development_budget')}>
              <TableControls.NumberInput {...totalDevelopmentBudgetProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="utility_bills_sf" highlighted={highlights.includes('utility_bills_sf')}>
              <TableControls.NumberInput {...utilityBillsSfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="development_cost_sf" highlighted={highlights.includes('development_cost_sf')}>
              <TableControls.NumberInput {...developmentCostSfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" sm={4}>
            <TabelRowCell column="soc_econimical_programs" highlighted={highlights.includes('soc_econimical_programs')}>
              <TableControls.NumberInput {...socEconimicalProgramsProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="gx-1" sm={1}>
        <Col>
          <TotalCell column="total" highlighted={highlights.includes('total')}>
            {((row.total_zag_fond || 0) + (row.total_spec_fond || 0)).toLocaleString('uk', moneysStingOptions)}
          </TotalCell>
        </Col>
        <Col>
          <TabelRowCell column="note" highlighted={highlights.includes('note')}>
            <TableControls.TextInput {...noteProps} rows={3} />
          </TabelRowCell>
        </Col>
      </Col>

    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  //
  authorityId: PropTypes.number,
  budgetId: PropTypes.number,
  disposerInHeader: PropTypes.bool,
  separatelyLocalRegPrograms: PropTypes.bool,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  //
  authorityId: null,
  budgetId: null,
  disposerInHeader: false,
  separatelyLocalRegPrograms: false,
};

export default memo(TRow);
