import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { EditorControls, editorHooks } from '../../../../../basicEditor/editorControls';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';

const StyledHeading = styled.h5`
  width: 100%;
  //color: #2185d0;
`;

const StyledDiv = styled.div`
  //background: #c1d4ff;
  padding: 10px;
  border-radius: 5px;
`;

function ConsequencesPage(props) {
  const {
    data, fields, errors, onChange,
  } = props;

  const cashDeviation = editorHooks.useStringInputProps('cash_deviation', data, fields, errors, onChange, false);
  const factDeviation = editorHooks.useStringInputProps('fact_profit_deviation', data, fields, errors, onChange, false);

  const finViolation = editorHooks.useStringInputProps('fin_violation', data, fields, errors, onChange, false);

  const finDiscipline = editorHooks.useStringInputProps('fin_discipline', data, fields, errors, onChange, false);

  const actuality = editorHooks.useStringInputProps('actuality', data, fields, errors, onChange, false);

  const utilityDescription = editorHooks.useStringInputProps('utility_description', data, fields, errors, onChange, false);

  const effectivenessDescription = editorHooks.useStringInputProps('effectiveness_description', data, fields, errors, onChange, false);

  const impactDescription = editorHooks.useStringInputProps('impact_description', data, fields, errors, onChange, false);

  return (
    <>
      <Row md={2}>
        <Col>
          <EditorControls.TextInput {...finViolation} rows={3} />
        </Col>
        <Col>
          <EditorControls.TextInput {...finDiscipline} rows={3} />
        </Col>
      </Row>
      <StyledDiv>
        <StyledHeading>Пояснення до п.5.5.</StyledHeading>
        <Row md={2}>
          <Col>
            <EditorControls.TextInput {...cashDeviation} rows={3} />
          </Col>
          <Col>
            <EditorControls.TextInput {...factDeviation} rows={3} />
          </Col>
        </Row>
      </StyledDiv>
      <StyledDiv>
        <StyledHeading>Узагальнений висновок щодо:</StyledHeading>
        <Row md={2}>
          <Col>
            <EditorControls.TextInput {...actuality} rows={3} />
          </Col>
          <Col>
            <EditorControls.TextInput {...utilityDescription} rows={3} />
          </Col>
          <Col>
            <EditorControls.TextInput {...effectivenessDescription} rows={3} />
          </Col>
          <Col>
            <EditorControls.TextInput {...impactDescription} rows={3} />
          </Col>
        </Row>
      </StyledDiv>
    </>
  );
}

ConsequencesPage.propTypes = {
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
};

ConsequencesPage.defaultProps = {
  errors: [],
  fields: null,
};

export default ConsequencesPage;
