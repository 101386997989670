import React from 'react';
import PropTypes from 'prop-types';
import { InputWithDropdown } from '../../../../../../../../components/styledInputs';

export const lineTypes = {
  LARGEDASHED: {
    label: 'Великий пунткир',
    name: 'LargeDashed',
  },
  DOUBLE: {
    label: 'Подвійна',
    name: 'Double',
  },
  NONE: {
    label: 'Немає лінії',
    name: 'None',
  },
  THINDASHED: {
    label: 'Рідкий пунткир',
    name: 'ThinDashed',
  },
  SOLID: {
    label: 'Суцільна',
    name: 'Solid',
  },
  DOTTED: {
    label: 'Точкова',
    name: 'Dotted',
  },
  THICKDASHED: {
    label: 'Частий пунткир',
    name: 'ThickDashed',
  },
};

const enumValues = Object.values(lineTypes);

function LineTypeSelector({ value, onChange }) {
  return (
    <InputWithDropdown
      value={value}
      onChange={onChange}
      options={enumValues}
    />
  );
}

LineTypeSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(lineTypes).map((v) => v.name)),
  onChange: PropTypes.func.isRequired,
};

LineTypeSelector.defaultProps = {
  value: lineTypes.NONE.name,
};

export default LineTypeSelector;
