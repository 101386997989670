import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import GridTemplate from './GridTemplate';
import { DivCell, DivCheckCell } from '../../../../../components/styledTable/styledTable';

import CheckboxStyled from '../../../../../components/styledCheckbox/styledCheckbox';
import { changeField } from '../../../../../actions/dpEditor';

import ItemField from '../../../../field/selectorField/item/itemField';

const TABLENAME = 'tvFonds';

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;

    return (
      <GridTemplate>
        <DivCheckCell areaName="selected">
          <CheckboxStyled
            value={row.get('selected', false)}
            onChange={(e, value) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'selected'], value))}
          />
        </DivCheckCell>
        <DivCell areaName="fond">
          <ItemField
            noBorder
            noBackground
            value={row.get('fond', new Map())}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
