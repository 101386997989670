const notifierActions = {
  newFile: '@NOTIFIER/STORE_FILE',
  downloadFile: '@NOTIFIER/DOWNLOAD_FILE',
  downloadAllFiles: '@NOTIFIER/DOWNLOAD_ALL_FILES',
  removeFile: '@NOTIFIER/REMOVE_FILE',
  clearFiles: '@NOTIFIER/CLEAR_FILES',
  loadMessages: 'LOAD_MESSAGES',
  loadMessagesStart: 'LOAD_MESSAGES_START',
  loadMessagesEnd: 'LOAD_MESSAGES_END',
  loadMessagesErr: 'LOAD_MESSAGES_ERR',
  updateMessages: 'UPDATE_MESSAGES',
};

export default notifierActions;
