import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import GridTemplate from './GridTemplate';
import { DivCell, SumInputStyled } from '../../../../../components/styledTable/styledTable';
import Fields from '../../../../field';
import DateInput from '../../../../../components/fields/dates';

const StringInut = Fields.GetTextInput();

const IncOutcOptions = {
  1: 'Надходження',
  2: 'Видаток',
};

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      row,
    } = this.props;

    return (
      <GridTemplate>
        <DivCell areaName="docno">
          <StringInut
            noBackground
            noBorder
            value={row.get('docNo', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="bankname">
          <StringInut
            noBackground
            noBorder
            value={row.get('bankName', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="incout">
          {IncOutcOptions[row.get('incOuntc', 1)]}
        </DivCell>
        <DivCell areaName="sum">
          <SumInputStyled
            value={row.get('sum', 0)}
            readOnly
          />
        </DivCell>
        <DivCell areaName="createdate">
          <DateInput
            value={row.get('creationDate', 0)}
            readOnly
          />
        </DivCell>
        <DivCell areaName="execdate">
          <DateInput
            value={row.get('executionDate', 0)}
            readOnly
          />
        </DivCell>
        <DivCell areaName="mfo">
          <StringInut
            value={row.get('mfo', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="account">
          <Fields.ItemField
            value={row.get('account', new Map())}
            readOnly
          />
        </DivCell>
        <DivCell areaName="client">
          <Fields.ItemField
            value={row.get('client', new Map())}
            readOnly
          />
        </DivCell>
        <DivCell areaName="edrpou">
          <StringInut
            value={row.get('edrpou', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="purpose">
          <Fields.TextAreaField
            value={row.get('purpose', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="state">
          <StringInut
            value={row.get('state', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="doc">
          <Fields.ItemField
            value={row.get('doc', new Map())}
            readOnly
          />
        </DivCell>
        <DivCell areaName="lineno">
          {row.get('lineNo', 0)}
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
