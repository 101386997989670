/**
 * @const
 */

const columns = {
  DocumentType: { label: 'Тип документу', name: 'ТипДокумента' },
  Ticket: { label: 'Тікет', name: 'Тикет' },
  Year: { label: 'Рік', name: 'Год' },
};

const tables = {
  Documents: {
    name: 'ДокументыДляЗагрузки',
    label: 'Документи, що завантажуються',
    columns: {
      BudgetCode: { label: 'Код бюджету', name: 'КодБюджета' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      EDRPOU: { label: 'Код за ЄДРПОУ', name: 'КодЕдрпоу' },
      FI: { label: 'Розпорядник', name: 'ФинансовыйОрган' },
      Id: { label: 'Идентификатор', name: 'Идентификатор' },
      Check: { label: 'М', name: 'М' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'importFromLogica',
  backendName: 'ЗагрузкаДокументовИзLogica',
  frontend: 'dp/importFromLogica',
  label: 'Імпорт документів з Logica',
  columns,
  tables,
};

export default definition;
