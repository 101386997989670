import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditorHOC, { mapState, getRouterEditor } from '../basic';
import { budgetElChTP as def } from '../../../constants/meta/ChTP/budgetElChTP';
import { editor as editorActions } from '../../../actions';
import { catEditorMenu as BasicMenu } from '../../../components/editorMenu';
import GetTextField from '../../field/TextInput';
import { StyledLabel, TwoRow, FourRow } from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';
import Fields from '../../field';

const TextField50 = GetTextField(50);

const getTypeValue = (headerForm, param) => headerForm.getIn(['typeValue', param], false) !== false;

const setTypeValue = (headerForm, value, param) => {
  const typeValue = headerForm.get('typeValue', new Map());
  if (value) { return typeValue.set(param, param); }
  return typeValue.delete(param);
};

function BudgetElChTP({ headerForm, dispatch }) {
  return (
    <>
      <TwoRow>
        <div>
          <StyledLabel>{def.columns.code.label}</StyledLabel>
          <TextField50
            value={headerForm.get('code', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'code'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.name.label}</StyledLabel>
          <TextField50
            value={headerForm.get('name', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'name'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.fo.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('fo', new Map())}
            modelName="csu"
            modelType="cat"
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'fo'], value))}
            canCleared
            noHierarchy
          />
        </div>

      </TwoRow>
      <ul />

      <FourRow>

        <CheckBoxField
          label="Текст"
          value={getTypeValue(headerForm, 'string')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'string')))}
        />

        <CheckBoxField
          label="Число"
          value={getTypeValue(headerForm, 'number')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'number')))}
        />

        <CheckBoxField
          label="Дата"
          value={getTypeValue(headerForm, 'date')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'date')))}
        />

        <CheckBoxField
          label='Відповідь на питання "Так/ні"'
          value={getTypeValue(headerForm, 'boolean')}

          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'boolean')))}
        />

        <CheckBoxField
          label="Додаткова властивість елемету КФК (Дов.)"
          value={getTypeValue(headerForm, 'Справочник.ДопХЭлементыКФК')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ДопХЭлементыКФК')))}
        />

        <CheckBoxField
          label="Бюджетна програма"
          value={getTypeValue(headerForm, 'Справочник.ЭлементыКФК')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ЭлементыКФК')))}
        />

        <CheckBoxField
          label="Довідник джерел фінансування об'єктів будівництва"
          value={getTypeValue(headerForm, 'Справочник.ОбъектыСтроительстваИсточникиФинансирования')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ОбъектыСтроительстваИсточникиФинансирования')))}
        />
      </FourRow>
    </>
  );
}

BudgetElChTP.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapState),
  EditorHOC(
    {
      type: 'ChTP',
      name: 'budgetElChTP',
    },
    {
      menu: BasicMenu,
    },
  ),
);

export const StdEditor = enhance(BudgetElChTP);

export default getRouterEditor(def)(StdEditor);
