import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/catalogs/signatureVariants';
import PeopleTP from './tabs';

function SignVariantsTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="variantOfSignature" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.variantOfSignature.label} eventKey="variantOfSignature">
        <PeopleTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

SignVariantsTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default SignVariantsTabs;
