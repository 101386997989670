import React from 'react';
import PropTypes from 'prop-types';
import useDetailsShower from './hooks/details';
import { DimableLoader, ErrorMessage } from '../../components/bootStrap';
import Results from './results';
import { RENDER_API_URL } from './hooks/consts';

function DetailsShower({ reportId, renderReportUrl, settings }) {
  const {
    loading,
    err,
    results,
  } = useDetailsShower({ reportId, settings, renderReportUrl });
  return (
    <>
      {err && (
        <ErrorMessage text={err} />
      )}

      <DimableLoader loading={loading} fullscreen>
        {results ? (
        // <div style={{ overflow: 'auto', height: '100%' }}>
          <Results
            result={results}
          />
        // </div>
        ) : <div style={{ height: '100px' }} />}

      </DimableLoader>
    </>
  );
}

DetailsShower.propTypes = {
  reportId: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  renderReportUrl: PropTypes.oneOf([RENDER_API_URL]).isRequired,
};

export default DetailsShower;
