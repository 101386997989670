/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import md from '../../../../constants/meta/catalogs/bankAccounts';
import enums from '../../../../constants/meta/enums';
import AccTypeSelector from './typeselector';

const StdSelector = ListerFactory({
  modelType: 'cat',
  modelName: 'bankAccounts',
  viewType: 'selector',
})(CatTableListerFactory({}));

const typeValues = Object.values(enums.BankAccaountTypes);

function BankAccounstSelector({ filter, ...restProps }) {
  const usedAccTypeFilter = useMemo(
    () => filter
      .filter((f) => f.fieldName === md.columns.AccountType.name)
      .reduce((R, r) => r, null),
    [filter],
  );
  const [kindValue, setKindValue] = useState(
    usedAccTypeFilter ? usedAccTypeFilter.value : enums.BankAccaountTypes.boiler.name,
  );
  const [onlyValid, setOnlyValid] = useState(true);
  const flt = useMemo(
    () => [
      ...filter.filter((f) => ![
        md.columns.AccountType.name,
        md.columns.Inactive.name,
      ].includes(f.fieldName)),
      ...([{
        fieldName: md.columns.AccountType.name,
        value: kindValue,
      }]),
      ...(onlyValid ? [{
        fieldName: md.columns.Inactive.name,
        value: false,
      }] : []),
    ],
    [filter, kindValue, onlyValid],
  );

  return (
    <>
      <AccTypeSelector
        typeValue={kindValue}
        typeValues={typeValues}
        onSetType={setKindValue}
        onlyValid={onlyValid}
        onSetOnlyValid={setOnlyValid}
      />
      <StdSelector filter={flt} {...restProps} noHierarchy />
    </>
  );
}

BankAccounstSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
  })),
};

BankAccounstSelector.defaultProps = {
  filter: [],
};

export default BankAccounstSelector;
