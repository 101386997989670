const frontendURL = '/rep/estimate/';
const backendURL = '/api/authorityplans/printform/estimate/';
const name = 'Кошторис';
const sections = '';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
