/* eslint-disable no-confusing-arrow */
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../components/styledWrappedLoader';
import init from '../../iitLibrary/eusign';

const Button = styled.button`
  background: linear-gradient(0deg, #bfd4ef, #d1e6fb, #dfeefd, #c7daf2);
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  padding: 5px 8px;
  font-weight: 700;
  width: 300px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  filter: ${({ disabled }) => disabled ? 'grayscale(1) opacity(.6)' : 'unset'};
  &:hover {
    background: #aac6e6;
  }
  :focus {
    outline: none;
  }
`;

function SignData({
  data, onAddSign, onClose, index, widgetUri, multiple,
}) {
  const EndUserRef = useRef(null);
  const EuSignRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const inputRef = useRef();
  const setInputFocus = useCallback(
    () => {
      if (inputRef.current) inputRef.current.focus();
    },
    [],
  );
  const signWidgetParent = useRef();
  const signDataBlock = useRef();
  const iframeId = useRef(Math.random().toString(36).substring(2));

  useEffect(() => {
    console.log(111, setInputFocus);
  }, [setInputFocus]);

  useEffect(() => {
    EndUserRef.current = init();

    // eslint-disable-next-line new-cap
    EuSignRef.current = new EndUserRef.current(
      signWidgetParent.current,
      iframeId,
      widgetUri,
      EndUserRef.current.FormType.ReadPKey,
    );
    //= ============================================================================

    // Створення об'єкту типу endUser для взаємодії з iframe,
    // який завантажує сторінку SignWidget

    EuSignRef.current
      .ReadPrivateKey()
      .then(() => {
        signWidgetParent.current.classList.add('d-none');
        signDataBlock.current.classList.remove('d-none');
        setInputFocus();
      })
      .catch((e) => {
        alert(
          `${'Виникла помилка при зчитуванні ос. ключа. Опис помилки: '}${
            e.message || e
          }`,
        );
      });

    document.body.addEventListener('click', setInputFocus);

    return () => {
      EuSignRef.current = null;
      document.body.removeEventListener('click', setInputFocus);
    };
  }, [setInputFocus, widgetUri]);

  //= ============================================================================
  // Накладання підпису на дані

  const onSign = (strSign) => {
    const external = true;
    const asBase64String = true;
    const signAlgo = EndUserRef.current.SignAlgo.DSTU4145WithGOST34311;
    const signType = EndUserRef.current.SignType.CAdES_X_Long;
    return (
      EuSignRef.current
        .SignData(strSign, external, asBase64String, signAlgo, null, signType)
        .catch((e) => {
          alert(
            `${'Виникла помилка при підписі даних. Опис помилки: '}${
              e.message || e
            }`,
          );
        })
    );
  };

  const handleClickFileDownload = useCallback(
    () => {
      setIsLoading(true);
      onSign(data)
        .then((sign) => {
          const file = atob(sign);
          const byteNumbers = new Array(file.length);
          for (let i = 0; i < file.length; i += 1) {
            byteNumbers[i] = file.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/x-pkcs7-signature' });
          const URL = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = URL;
          a.download = 'file.json.p7s';
          document.body.append(a);
          a.click();
        })
        .catch((e) => console.error(e.message))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [data],
  );

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    if (multiple) {
      const multipleSigning = async (files) => {
        const newArray = await Promise.all(Object.keys(files).map(async (docId) => {
          const sign = await onSign(files[docId]);
          return { docId, sign };
        }));
        return newArray;
      };
      multipleSigning(data)
        .then((resultArray) => {
          onAddSign(resultArray);
        })
        .catch((error) => {
          console.error('Error processing array:', error);
        });
    } else {
      const sign = await onSign(data);
      await onAddSign(index, sign);
    }
    setUpdated(true);
    setIsLoading(false);
  }, [data, index, multiple, onAddSign]);

  return (
    <div style={{ marginLeft: '15px' }}>
      {' '}
      <div
        ref={signWidgetParent}
        style={{ width: '700px', height: '500px' }}
      />
      <div ref={signDataBlock} className="d-none">
        <Loader isLoading={isLoading}>
          {!updated && (
            <>
              <Button
                onClick={() => handleClick(data)}
                ref={inputRef}
                style={{ marginRight: '50px' }}
              >
                {`Підписати документ${multiple ? 'и' : ''}`}
              </Button>
              {!multiple && (
                <Button onClick={handleClickFileDownload}>Завантажити підпис</Button>
              )}
            </>
          )}
          {updated && (
            <>
              <h2>{`Документ${multiple ? 'и' : ''} успішно підписано!`}</h2>
              <Button onClick={onClose}>
                {`Повернутись до ${multiple ? 'документу' : 'списку'}`}
              </Button>
            </>
          )}
        </Loader>
      </div>
    </div>
  );
}

SignData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  onAddSign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number,
  widgetUri: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

SignData.defaultProps = {
  index: null,
  multiple: false,
};

export default SignData;
