/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useMemo, useState } from 'react';
import {
  Badge, Dropdown, FormControl,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import forms from './forms';

const StyledDivMenu = styled.div`
    max-height: 50vh;
`;
function Tables({
  data, onClearFilter, setSelectedBs,
  onSetFilter, onSetOrder, selectedIds, setSelectedIds,
  activeForm, setActiveForm, displayList,
}) {
  const [formFilter, setFormFilter] = useState('');
  const displayTabs = useMemo(
    () => Object.keys(forms).map((formcode) => ({
      code: formcode,
      title: forms[formcode].title,
      Component: forms[formcode].component,
      defaultProps: forms[formcode].defaultProps,
    })).filter((f) => `${f.code} ${f.title}`.toUpperCase().includes(formFilter.toUpperCase())),
    [formFilter],
  );
  const activeTitle = activeForm ? forms[activeForm].title : 'Оберіть таблицю';
  const currentTab = activeForm ? forms[activeForm] : null;
  return (
    <>
      <Dropdown flip>
        <Dropdown.Toggle>
          {activeTitle}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <FormControl
            autoFocus
            className="mx-3 my-2 w-100"
            placeholder="Введіть код або назву форми для пошуку"
            value={formFilter}
            onChange={(e) => setFormFilter(e.target.value)}
          />
          <Dropdown.Divider />
          <StyledDivMenu className="overflow-auto">
            {displayTabs.map((tab) => (
              <Dropdown.Item
                key={tab.code}
                eventKey={tab.code}
                active={tab.code === activeForm}
                onClick={() => setActiveForm(tab.code)}
              >
                <span className="me-2">{tab.title}</span>
                <Badge variant="info">{tab.code}</Badge>
              </Dropdown.Item>
            ))}
          </StyledDivMenu>
        </Dropdown.Menu>
      </Dropdown>
      {currentTab && (
        <currentTab.component
          data={data}
          displayList={displayList.display_list}
          onClearFilter={onClearFilter}
          onSetFilter={onSetFilter}
          onSetOrder={onSetOrder}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          setSelectedBs={setSelectedBs}
          {...currentTab.defaultProps}
        />

      )}
    </>
  );
}

Tables.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.oneOf(Object.keys(forms)).isRequired,
  })).isRequired,
  displayList: PropTypes.shape({
    display_list: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onSetFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setSelectedBs: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeForm: PropTypes.string,
  setActiveForm: PropTypes.func.isRequired,
};

Tables.defaultProps = {
  displayList: { display_list: [] },
  activeForm: null,
};

export default memo(Tables);
