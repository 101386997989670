import React, { forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';
import CreateElementsModal from './createElemtsModal';

const ElementCommandPanel = forwardRef((_, ref) => (
  <CatListerCommandPanel ref={ref}>

    <ButtonGroup>
      <CreateElementsModal />
    </ButtonGroup>

  </CatListerCommandPanel>
));

export default ElementCommandPanel;
