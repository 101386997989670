import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/mixIndicatorsSF';
import {
  StyledLabel,
  TwoColumnsGridContainer,
  CommandPanelColor, ThreeColumnsGridContainer,
} from '../../../components/Form/styledForm';

import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import StyledTPPane from '../../../components/tableParts/TPPane';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import DateInput from '../../../components/fields/dates';
import ItemField from '../../field/selectorField/item/itemField';
import meta from '../../../constants/meta';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const MyThreeColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 20% 30% 30%;
  grid-column-gap: 10%;
  align-items: start;
`;

const MySixColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-column-gap: 2%;
  align-items: start;
`;

const MySevenColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12%;
  grid-column-gap: 2%;
  align-items: start;
`;

const StringInput20 = Fields.GetTextInput(20);
const StringInput10 = Fields.GetTextInput(10);
const NumberInput3 = Fields.GetNumberInput(3, 0);

const fundKEKVTypes = {
  none: { label: 'Не враховувати', name: '' },
  ...enums.FundKEKVKInds,
};

const fundKEKVOptions = Object.values(fundKEKVTypes).map((_enum) => ({ label: _enum.label, value: _enum.name }));

const planingKindsOptions = Object.values(enums.PlaningKind);

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

const FormTypes = {
  kazn: { label: 'Казначейство', value: 0 },
  mf: { label: 'Мінфін', value: 1 },
};

const ShowAsTypes = {
  code: { label: 'Код', value: 1 },
  codeTL_TERRA: { label: 'Код за TL_TERRA', value: 2 },
};

const ShowCSUAsTypes = {
  csu: { label: 'Розпорядник', value: false },
  KVK: { label: 'КВК', value: true },
};

const ShowKFKAsTypes = {
  kfk: { label: 'КПКВ', value: false },
  kfkel: { label: 'Бюджетна програма', value: true },
};

const ShowKVKTypes = {
  csu: { label: 'ГолРозпорядник', value: 0 },
  csu2: { label: 'Розпорядник', value: 1 },
  kvk: { label: 'КВК', value: 2 },
};

const TypeKEKVTypes = {
  short: { label: 'За скороченою економікою', value: 1 },
  long: { label: 'За повною економікою (всі коди)', value: 2 },
  full: { label: 'За повною економікою (задіяні коди)', value: 3 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());
  const FO = headerForm.get('FO', new Map());

  const BudgetLabel = sessionOptions.get('is_admin', false) ? (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    </div>
  ) : (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    </div>
  );

  const FoLabel = sessionOptions.get('is_admin', false) || headerForm.get('byHighLevelCSU', false) ? (
    <div>
      <StyledLabel>{md.columns.FO.label}</StyledLabel>
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    </div>
  ) : (
    <div>
      <StyledLabel>{md.columns.FO.label}</StyledLabel>
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    </div>
  );

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'MixIndicatorsSF';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const SettingsButton = (
    <div>
      <div>
        <TwoColumnsGridContainer>
          <div>
            {BudgetLabel}
          </div>
          <div>
            {FoLabel}
          </div>
        </TwoColumnsGridContainer>
        <MyThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.dateReg.label}</StyledLabel>
            <DateInput
              label={md.columns.dateReg.label}
              value={headerForm.get('dateReg', null)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'dateReg'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.regNo.label}</StyledLabel>
            <StringInput20
              value={headerForm.get('regNo', '')}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'regNo'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.typePlan.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('typePlan')}
              options={planingKindsOptions}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'typePlan'], v));
              }}
            />
          </div>
        </MyThreeColumnsGridContainer>
        <MySixColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.form.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('form', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'form'], v))}
              values={Object.values(FormTypes)}
              name="form"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.showAs.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('showAs', 1)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showAs'], v))}
              values={Object.values(ShowAsTypes)}
              name="showAs"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.typeKEKV.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('typeKEKV', 1)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'typeKEKV'], v))}
              values={Object.values(TypeKEKVTypes)}
              name="typeKEKV"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.varReport.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('varReport', 1)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'varReport'], v))}
              values={Object.values(ReportTypes)}
              name="varReport"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.typeMoney.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('typeMoney', 1)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'typeMoney'], v))}
              values={Object.values(fundKEKVOptions)}
              name="typeMoney"
            />
          </div>
        </MySixColumnsGridContainer>
        <MySevenColumnsGridContainer>
          <div>
            <Fields.CheckboxField
              label={md.columns.inKFK.label}
              value={headerForm.get('inKFK', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'inKFK'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.inKFKNoGroups.label}
              value={headerForm.get('inKFKNoGroups', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'inKFKNoGroups'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.WithKFB.label}
              value={headerForm.get('WithKFB', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'WithKFB'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.withDoc.label}
              value={headerForm.get('withDoc', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'withDoc'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.in1000.label}
              value={headerForm.get('in1000', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.inPCM.label}
              value={headerForm.get('inPCM', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'inPCM'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.dontShow.label}
              value={headerForm.get('dontShow', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'dontShow'], v))}
            />
          </div>
        </MySevenColumnsGridContainer>
        <MySevenColumnsGridContainer>
          <div>
            <Fields.CheckboxField
              label={md.columns.flNoKEKVHierarchy.label}
              value={headerForm.get('flNoKEKVHierarchy', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flNoKEKVHierarchy'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.flKPKVHierarchy.label}
              value={headerForm.get('flKPKVHierarchy', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flKPKVHierarchy'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.flNoKEKVCode.label}
              value={headerForm.get('flNoKEKVCode', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flNoKEKVCode'], v))}
            />
          </div>
        </MySevenColumnsGridContainer>
        <MySevenColumnsGridContainer>
          <div>
            <Fields.CheckboxField
              label={md.columns.noShowHead.label}
              value={headerForm.get('noShowHead', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'noShowHead'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.KOATUU.label}
              value={headerForm.get('KOATUU', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'KOATUU'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumVoid.label}</StyledLabel>
            <StringInput10
              value={headerForm.get('sumVoid', '')}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'sumVoid'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.widthCol.label}</StyledLabel>
            <NumberInput3
              value={headerForm.get('widthCol', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'widthCol'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.addName.label}
              value={headerForm.get('addName', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'addName'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.inPage.label}
              value={headerForm.get('inPage', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'inPage'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.flNoCop.label}
              value={headerForm.get('flNoCop', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'flNoCop'], v))}
            />
          </div>
        </MySevenColumnsGridContainer>
        <MyThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.showCSUAs.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('showCSUAs', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUAs'], v))}
              values={Object.values(ShowCSUAsTypes)}
              name="showCSUAs"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.showKVK.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('showKVK', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showKVK'], v))}
              values={Object.values(ShowKVKTypes)}
              name="showKVK"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.showKFKAs.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('showKFKAs', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showKFKAs'], v))}
              values={Object.values(ShowKFKAsTypes)}
              name="showKFKAs"
            />
          </div>
        </MyThreeColumnsGridContainer>
      </div>
      <StyledPane>
        <DCControls.Filter
          filterChoice={new Map()
            .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
            .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
            .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
            .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
            .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
        />
      </StyledPane>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
          <Fields.CheckboxField
            label={md.columns.flSN.label}
            value={headerForm.get('flSN', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'flSN'], v))}
          />
        </div>
      </ContainerDiv>
      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
