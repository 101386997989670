import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/indicators';
import TabMenu from './tabmenu';

function Indicator({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.SampleIndicator.label}
            value={data[md.columns.SampleIndicator.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SampleIndicator.name]: value,
            })}
            modelType="cat"
            modelName="indicatorsClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.TaskClassifier.label}
            value={data[md.columns.TaskClassifier.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TaskClassifier.name]: value,
            })}
            modelType="cat"
            modelName="tasksClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CalculateAverage-${data.id}`}
            label={md.columns.CalculateAverage.label}
            value={data[md.columns.CalculateAverage.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CalculateAverage.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`SerialNumber-${data.id}`}
            label={md.columns.SerialNumber.label}
            value={data[md.columns.SerialNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SerialNumber.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`Inactive-${data.id}`}
            label={md.columns.Inactive.label}
            value={data[md.columns.Inactive.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Inactive.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="tasks"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`NotShowInReports-${data.id}`}
            label={md.columns.NotShowInReports.label}
            value={data[md.columns.NotShowInReports.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NotShowInReports.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`NotShowInForm1-${data.id}`}
            label={md.columns.NotShowInForm1.label}
            value={data[md.columns.NotShowInForm1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NotShowInForm1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <TabMenu permissions={permissions} actions={actions} data={data} />
    </Container>
  );
}

Indicator.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Indicator;
