import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Alert, Button, Col, Modal, Row, Navbar, Form, Container, Dropdown, ButtonGroup,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { faSearchPlus, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logica from '../../authentication';
import md from '../../../constants/meta/dataprocessors/logikaImportTreasureFiles';
import ResultTable from './resultTable';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { changeField } from '../../../actions/dpEditor';
import { useLogikaLoadDKUFiles } from './hook';
import { DATATYPES, getDateStr } from './consts';

const selector = (state) => ({
  ticket: state.getIn(['auth', 'logikaTicket'], null),
});

function LogikaImporter({ headerForm }) {
  const { ticket } = useSelector(selector);
  const dispatch = useDispatch();

  const [show, setShow] = useState(!ticket);

  const onHeaderChange = useCallback(
    (e, values) => Object.keys(values).forEach((k) => dispatch(changeField(['headerForm', k], values[k]))),
    [dispatch],
  );

  useEffect(
    () => setShow(!ticket),
    [ticket],
  );

  const {
    loading, err, newDates, dates, onGetDates, onClearErr, logikaErrors, onLoadFiles,
  } = useLogikaLoadDKUFiles(headerForm, ticket);

  const navigate = useNavigate();
  const dataLoaded = !!Object.keys(dates).length;
  const allDates = useMemo(
    () => {
      const uniqDates = new Set(DATATYPES
        .filter((key) => dates[key])
        .reduce((R, key) => [
          ...R,
          ...dates[key].map((d) => d.datefile),
        ], []));
      return [...uniqDates].map((d) => d);
    },
    [dates],
  );

  const onLoadOneDay = useCallback(
    (date) => {
      const filezToLoad = DATATYPES
        .filter((key) => dates[key])
        .reduce((R, key) => ({ ...R, [key]: dates[key].filter((d) => d.datefile === date) }), {});
      onLoadFiles(filezToLoad);
    },
    [dates, onLoadFiles],
  );
  return (
    <>
      <Container fluid>
        <Navbar>
          <Button onClick={onGetDates} disabled={loading} variant="outline-primary">
            <FontAwesomeIcon icon={faSearchPlus} className="me-2" spin={loading} />
            Пошук нових даних
          </Button>
          <Dropdown as={ButtonGroup}>
            <Button
              variant="outline-success"
              onClick={() => onLoadFiles(newDates)}
              disabled={loading || !dataLoaded}
            >
              <FontAwesomeIcon icon={faRocket} className="me-2" spin={loading} />
              Оновити все!
            </Button>
            <Dropdown.Toggle split variant="outline-success" disabled={!dataLoaded} />
            <Dropdown.Menu>
              {allDates.map((dd) => (
                <Dropdown.Item key={dd} onClick={() => onLoadOneDay(dd)}>
                  {`Перезавантажити за ${getDateStr(dd)}`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>

          </Dropdown>

        </Navbar>
        {err && (
          <Alert dismissible onClose={onClearErr}>
            <Alert.Heading>
              На жаль щось пішло не так....
            </Alert.Heading>
            {err}
          </Alert>
        )}
        <Row>
          <Form.Group className="alwaysFloated" as={Col}>
            <Form.Label>{md.columns.year.label}</Form.Label>
            <Form.Control
              type="number"
              value={headerForm.get('year', 0)}
              onChange={(e, year) => onHeaderChange(e, { year: Number(e.target.value) })}
            />
          </Form.Group>
        </Row>
        <Row>
          {dataLoaded && (
            <ResultTable
              newData={newDates}
              data={dates}
              errs={logikaErrors}
              headerForm={headerForm}
              onLoadFiles={onLoadFiles}
            />
          )}

        </Row>
      </Container>
      {!ticket && (
        <Modal
          show={show}
          size="lg"
          onHide={() => {
            setShow(false);
            navigate(-1);
            dispatch(dellComponentFromWindowsManager(`/${md.frontend}/`));
          }}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Logica />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
  // );
}

LogikaImporter.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
};

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportVDBFKiev',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(LogikaImporter);
