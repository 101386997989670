/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'finsourceplanchangesrow_set', readOnlyFields, errors, fields,
  });
  // Необходимые данные из шапки документа
  const {
    only_year: onlyYear, vt4br, authority,
  } = data;

  const totals = useMemo(
    () => [...Array(12)].reduce((R, _, i) => ({
      ...R,
      [`amount_${i + 1}`]: tableData.reduce((R1, r) => R1 + (r[`amount_${i + 1}`] || 0), 0),
      [`amount_br_${i + 1}`]: tableData.reduce((R1, r) => R1 + (r[`amount_br_${i + 1}`] || 0), 0),
    }), {
      amount_total: tableData.reduce((R, r) => R + (r.amount_total || 0), 0),
      amount_br_total: tableData.reduce((R, r) => R + (r.amount_br_total || 0), 0),
    }),
    [tableData],
  );

  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          totals={totals}
          onlyYear={onlyYear}
          vt4br={vt4br}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            onMoveRow={tableActions.onMoveRow}
            tableName="finsourceplanchangesrow_set"
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              authority={authority}
              totals={totals}
              onlyYear={onlyYear}
              vt4br={vt4br}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        //
          onlyYear={onlyYear}
          vt4br={vt4br}
        />
      </TableFooter>
    </TableContainer>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    only_year: PropTypes.bool,
    vt4br: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: foreignPropType,
    finsourceplanchangesrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    finsourceplanchangesrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    finsourceplanchangesrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    finsourceplanchangesrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
