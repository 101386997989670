import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faBan, faBookDead as icon, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function ResetSingButton({
  disabled,
  onClick,
  label,
}) {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <DefaultCPButton
        icon={icon}
        onClick={() => setOpened(true)}
        label={label}
        disabled={disabled}
        variant="danger"
      />
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Підтвердження</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Будуть видалені
          {' '}
          <b>ВСІ</b>
          {' '}
          підписи документів. Бажаєте продовижти?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end gap-2">
          <Button
            variant="outline-danger"
            onClick={(e) => {
              onClick(e);
              setOpened(false);
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Так
          </Button>
          <Button variant="outline-success" onClick={() => setOpened(false)}>
            <FontAwesomeIcon icon={faBan} className="me-2" />
            Ні
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ResetSingButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ResetSingButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Скинути підписи',
};

export default ResetSingButton;
