import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.tasksComparing;

function TPHeader({
  totals,
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tasks'}
              highlighted={highlights.includes('tasks')}
              onToggleHighlght={() => onToggleHighlght('tasks')}
            >
              {meta.columns.tasks.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactLastYearGenF'}
              title={totals.sumFactLastYearGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactLastYearGenF')}
              onToggleHighlght={() => onToggleHighlght('sumFactLastYearGenF')}
            >
              {meta.columns.sumFactLastYearGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactLastYearSpecF'}
              title={totals.sumFactLastYearSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactLastYearSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumFactLastYearSpecF')}
            >
              {meta.columns.sumFactLastYearSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactGenF'}
              title={totals.sumFactGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactGenF')}
              onToggleHighlght={() => onToggleHighlght('sumFactGenF')}
            >
              {meta.columns.sumFactGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactSpecF'}
              title={totals.sumFactSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumFactSpecF')}
            >
              {meta.columns.sumFactSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationGenF'}
              title={totals.sumDeviationGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumDeviationGenF')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationGenF')}
            >
              {meta.columns.sumDeviationGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationSpecF'}
              title={totals.sumDeviationSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumDeviationSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationSpecF')}
            >
              {meta.columns.sumDeviationSpecF.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactLastYearTotal'}
              title={totals.sumFactLastYearTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactLastYearTotal')}
              onToggleHighlght={() => onToggleHighlght('sumFactLastYearTotal')}
            >
              {meta.columns.sumFactLastYearTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactTotal'}
              title={totals.sumFactTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactTotal')}
              onToggleHighlght={() => onToggleHighlght('sumFactTotal')}
            >
              {meta.columns.sumFactTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviationTotal'}
              title={totals.sumDeviationTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumDeviationTotal')}
              onToggleHighlght={() => onToggleHighlght('sumDeviationTotal')}
            >
              {meta.columns.sumDeviationTotal.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'explanationSpentChanges'}
              highlighted={highlights.includes('explanationSpentChanges')}
              onToggleHighlght={() => onToggleHighlght('explanationSpentChanges')}
            >
              {meta.columns.explanationSpentChanges.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'explanationIndicatorChanges'}
              highlighted={highlights.includes('explanationIndicatorChanges')}
              onToggleHighlght={() => onToggleHighlght('explanationIndicatorChanges')}
            >
              {meta.columns.explanationIndicatorChanges.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumFactLastYearGenF: PropTypes.number,
    sumFactLastYearSpecF: PropTypes.number,
    sumFactGenF: PropTypes.number,
    sumFactSpecF: PropTypes.number,
    sumDeviationGenF: PropTypes.number,
    sumDeviationSpecF: PropTypes.number,
    sumFactLastYearTotal: PropTypes.number,
    sumFactTotal: PropTypes.number,
    sumDeviationTotal: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
