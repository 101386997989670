import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledCtxContainer = styled.div.attrs(({ visible }) => ({
  style: {
    ...!visible && { display: 'none' },
  },
}))`
  position: absolute;
  z-index: 1000;
  font-size: .875rem;
`;
