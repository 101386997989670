import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/catalogs/additionalPropertyKFK';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function AdditionalPropertyKFKEditor({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  console.log(data);
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code2.label}
            value={data[md.columns.code2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code2.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code3.label}
            value={data[md.columns.code3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code3.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code4.label}
            value={data[md.columns.code4.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code4.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        {/* <Col> */}
        {/*  <EditorControls.NumberInput */}
        {/*    label={md.columns.level.label} */}
        {/*    maxLength={2} */}
        {/*    disableCalculator */}
        {/*    value={data[md.columns.level.name]} */}
        {/*    onChange={(e, value) => actions.onChange({ */}
        {/*      [md.columns.level.name]: value, */}
        {/*    })} */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        {/* </Col> */}
        <Col>
          <EditorControls.NumberInput
            label={md.columns.printLevel.label}
            disableCalculator
            value={data[md.columns.printLevel.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.printLevel.name]: value,
            })}
            maxLength={2}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelName="additionalPropertyKFK"
            modelType="cat"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.sorting.label}
            value={data[md.columns.sorting.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.sorting.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {/* <div> */}
      {/* <StyledLabel>{def.columns.owner.label}</StyledLabel> */}
      {/* <Fields.ItemField */}
      {/* modelName="additionalPropertyKFK" */}
      {/* modelType="cat" */}
      {/* value={headerForm.get('owner', new Map())} */}
      {/* /> */}
      {/* </div> */}
    </Container>
  );
}

AdditionalPropertyKFKEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AdditionalPropertyKFKEditor;
