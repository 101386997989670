import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'debtManagementAnalysis';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKEKV.name]}
                modelType="cat"
                modelName="elementKEKV"
                filter={FIOwnerFilter}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.elementKEKV.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="KEKV" highlighted={highlights.includes('KEKV')}>
              <ItemPicker
                value={row[tableMD.columns.KEKV.name]}
                modelType="cat"
                modelName="kekvClassifier"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.KEKV.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="applied2" highlighted={highlights.includes('applied2')}>
              <NumberInput
                value={row[tableMD.columns.applied2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.applied2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="cashBox" highlighted={highlights.includes('cashBox')}>
              <NumberInput
                value={row[tableMD.columns.cashBox.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.cashBox.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="applied1" highlighted={highlights.includes('applied1')}>
              <NumberInput
                value={row[tableMD.columns.applied1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.applied1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="payables2" highlighted={highlights.includes('payables2')}>
              <NumberInput
                value={row[tableMD.columns.payables2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.payables2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="payables1" highlighted={highlights.includes('payables1')}>
              <NumberInput
                value={row[tableMD.columns.payables1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.payables1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="borderAmount" highlighted={highlights.includes('borderAmount')}>
              <NumberInput
                value={row[tableMD.columns.borderAmount.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.borderAmount.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedzf2" highlighted={highlights.includes('creditRepaymentLiquidatedzf2')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedzf2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedzf2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedzf1" highlighted={highlights.includes('creditRepaymentLiquidatedzf1')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedzf1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedzf1.name]: value,
                })}
                readOnly={false}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedzf0" highlighted={highlights.includes('creditRepaymentLiquidatedzf0')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedzf0.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedzf0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedsf2" highlighted={highlights.includes('creditRepaymentLiquidatedsf2')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedsf2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedsf2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedsf1" highlighted={highlights.includes('creditRepaymentLiquidatedsf1')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedsf1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedsf1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="creditRepaymentLiquidatedsf0" highlighted={highlights.includes('creditRepaymentLiquidatedsf0')}>
              <NumberInput
                value={row[tableMD.columns.creditRepaymentLiquidatedsf0.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.creditRepaymentLiquidatedsf0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="receivables2" highlighted={highlights.includes('receivables2')}>
              <NumberInput
                value={row[tableMD.columns.receivables2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.receivables2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="receivables1" highlighted={highlights.includes('receivables1')}>
              <NumberInput
                value={row[tableMD.columns.receivables1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.receivables1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="receivables0" highlighted={highlights.includes('receivables0')}>
              <NumberInput
                value={row[tableMD.columns.receivables0.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.receivables0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="reason" highlighted={highlights.includes('reason')}>
              <TextInput
                value={row[tableMD.columns.reason.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.reason.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="takenActions" highlighted={highlights.includes('takenActions')}>
              <TextInput
                value={row[tableMD.columns.takenActions.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.takenActions.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
