import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DocSelectionModal from './docLoading/docSelectionModal';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/expensesOnHealth';
import { CommandPanelColor, ContainerSt, StyledLabel } from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { StampApprovedJs } from '../../../../assets/icons';
import { InputWithDropdown } from '../../../../components/styledInputs';
import meta from '../../../../constants/meta';

function ExpensesOnHealth({ data, actions, permissions }) {
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того, чтобы все хозяйство не перерендеривалось
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];

  const repMonth = {
    m1: { label: 'Січень', name: 1 },
    m2: { label: 'Лютий', name: 2 },
    m3: { label: 'Березень', name: 3 },
    m4: { label: 'Квітень', name: 4 },
    m5: { label: 'Травень', name: 5 },
    m6: { label: 'Червень', name: 6 },
    m7: { label: 'Липень', name: 7 },
    m8: { label: 'Серпень', name: 8 },
    m9: { label: 'Вересень', name: 9 },
    m10: { label: 'Жовтень', name: 10 },
    m11: { label: 'Листопад', name: 11 },
    m12: { label: 'Грудень', name: 12 },
  };

  const FIFilter = useMemo(
    () => [{
      fieldName: 'Родитель',
      value: FI,
    }],
    [FI],
  );

  const getDocLogicaId = (o) => {
    for (const i in o) {
      if (i === 'id') {
        return o[i];
      }
    }
    return null;
  };

  const getDocLogicaRepr = (o) => {
    for (const i in o) {
      if (i === 'repr') {
        return o[i];
      }
    }
    return null;
  };

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>{md.columns.reportMonth.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repMonth)}
            value={data[md.columns.reportMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reportMonth.name]: value,
            })}
          />
        </Col>
      </Row>
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <EditorControls.ItemPicker */}
      {/*      label={md.columns.csu.label} */}
      {/*      value={data[md.columns.csu.name]} */}
      {/*      onChange={(e, value) => { */}
      {/*        actions.onChange({ */}
      {/*          [md.columns.csu.name]: value, */}
      {/*        }); */}
      {/*      }} */}
      {/*      modelType="cat" */}
      {/*      modelName="csu" */}
      {/*      filter={FIFilter} */}
      {/*      readOnly={readOnly} */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
            onSR={actions.onSR}
          />
          <EditorControls.CheckboxInput
            controlId={`currentFO-${data.id}`}
            label={md.columns.currentFO.label}
            value={data[md.columns.currentFO.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.currentFO.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <CommandPanelColor
            label="Отримати дані з планів використання"
            onClick={() => actions.onSR('FILL_DOD3')}
          />
        </Col>
        <Col>
          <CommandPanelColor
            label="Отримати дані для контролю"
            onClick={() => actions.onSR('FILL_D')}
          />
        </Col>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
            {data[md.columns.isApproved.name] && sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.invoice.label}
                value={data[md.columns.invoice.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.invoice.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.invoice.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <StyledLabel>
            {md.columns.docLogica.label}
            <Link to={`${meta.doc.docTransferToLogica.frontend}/${getDocLogicaId(data[md.columns.docLogica.name])}`}>
              {getDocLogicaRepr(data[md.columns.docLogica.name])}
            </Link>
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.statusLogica.label]}
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.signLogica.label]}
          </StyledLabel>
        </Col>

      </Row>
    </EditorCollapse>
  );
}

ExpensesOnHealth.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
    [md.columns.invoice.name]: PropTypes.string,
    [md.columns.note.name]: PropTypes.string,
    [md.columns.author.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ExpensesOnHealth;
