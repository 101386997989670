import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'qqZdIOYj3ho',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Доходи',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
      KDB: { label: 'Код', name: 'КДБ' },
      KDBName: { label: 'Найиенування', name: 'КДБНаим' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Показники доходів бюджету (Додаток 2)',
  name: 'forecastAdd2',
  backendName: 'ПрогнозДод2',
  columns,
  frontend: 'doc/forecastAdd2',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
