import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ItemPicker } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/limitReference';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'CSU';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          {/* <Col> */}
          {/*  <TableRowCell column="check" highlighted={highlights.includes('check')}> */}
          {/*    <CheckboxInput */}
          {/*      id={`check-${row[tableMD.columns.strNumber.name]}`} */}
          {/*      readOnly={readOnly} */}
          {/*      value={row[tableMD.columns.check.name]} */}
          {/*      onChange={onCheckChange} */}
          {/*    /> */}
          {/*  </TableRowCell> */}
          {/* </Col> */}
          <Col>
            <TableRowCell column="csu" highlighted={highlights.includes('csu')}>
              <ItemPicker
                value={row[tableMD.columns.csu.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.csu.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="csu"
                filter={CSUFIlter}
                noHierarchy
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
