const frontendURL = '/srv/forecastsDashBoard/';
const backendURL = '/api/forecasts/forecasts_dashboard/';
const name = 'Прогнозування';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
