import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import HeaderCell from './headerCell';

const StyledTd = styled.td.attrs((props) => ({
  style: {
    color: props.color,
    fontWeight: props.fontWeight,
  },
}))`
`;

const StyledTh = styled.th`
color: #007bff;
position: sticky;
z-index: 1;
background-color: white;
top: 0;`;

function FilteredTable({
  typesOfFormList, data, columns, isAdmin, onSetFilter,
}) {
  return (
    <Table>
      <thead>
        <tr>
          <StyledTh>Тип форми</StyledTh>
          {columns.map((col) => (
            <HeaderCell
              key={col.label}
              col={col}
              isAdmin={isAdmin}
              onSetFilter={onSetFilter}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr
            key={row.IDREPORT}
          >
            <StyledTd>
              {typesOfFormList.find((t) => t.value === row.IDFORM.slice(0, -1)).name}
            </StyledTd>
            {columns.map((clmn) => (
              <Fragment key={clmn.name}>
                {(!clmn.onlyForAdmins || isAdmin) && (
                  <StyledTd>
                    {(clmn.link && row[clmn.name])
                      ? <a download href={row[clmn.name]} className="me-2 ms-2">Завантажити</a>
                      : row[clmn.name]}
                  </StyledTd>
                )}
              </Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

FilteredTable.propTypes = {
  typesOfFormList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default FilteredTable;
