import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ItemPicker, NumberInput, StringInput } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes8';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_101';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="code" highlighted={highlights.includes('code')}>
          <StringInput
            value={row[tableMD.columns.code.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.code.name]: v })}
            readOnly={readOnly}
            maxLength={19}
            required
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="name" highlighted={highlights.includes('name')}>
          <ItemPicker
            value={row[tableMD.columns.name.name]}
            onChange={async (e, v) => {
              await onRowChange(e, { [tableMD.columns.name.name]: v });
              await onSR('CHANGE_TAX_IN_TABLE101', { rowId: rowIndex });
            }}
            readOnly={readOnly}
            modelType="cat"
            modelName="activityKinds"
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="rate" highlighted={highlights.includes('rate')}>
          <NumberInput
            value={row[tableMD.columns.rate.name]}
            precision={4}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.rate.name]: v })}
            readOnly={readOnly}
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="period" highlighted={highlights.includes('period')}>
          <StringInput
            value={row[tableMD.columns.period.name]}
            maxLength={150}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.period.name]: v })}
            readOnly={readOnly}
            required
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
