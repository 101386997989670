import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.debtManagementAnalysis;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'elementKEKV'}
            highlighted={highlights.includes('elementKEKV')}
            onToggleHighlght={() => onToggleHighlght('elementKEKV')}
          >
            {meta.columns.elementKEKV.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'KEKV'}
            highlighted={highlights.includes('KEKV')}
            onToggleHighlght={() => onToggleHighlght('KEKV')}
          >
            {meta.columns.KEKV.label}
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'applied2'}
            highlighted={highlights.includes('applied2')}
            onToggleHighlght={() => onToggleHighlght('applied2')}
          >
            {meta.columns.applied2.label}
            {' '}
            {year - 2}
            {' '}
            p.
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'cashBox'}
            highlighted={highlights.includes('cashBox')}
            onToggleHighlght={() => onToggleHighlght('cashBox')}
          >
            {meta.columns.cashBox.label}
            {' '}
            {year - 2}
            {' '}
            p.
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'applied1'}
            highlighted={highlights.includes('applied1')}
            onToggleHighlght={() => onToggleHighlght('applied1')}
          >
            {meta.columns.applied1.label}
            {' '}
            {year - 1}
            {' '}
            p.
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'payables2'}
              highlighted={highlights.includes('payables2')}
              onToggleHighlght={() => onToggleHighlght('payables2')}
            >
              {meta.columns.payables2.label}
              {' '}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'payables1'}
              highlighted={highlights.includes('payables1')}
              onToggleHighlght={() => onToggleHighlght('payables1')}
            >
              {meta.columns.payables1.label}
              {' '}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'borderAmount'}
              highlighted={highlights.includes('borderAmount')}
              onToggleHighlght={() => onToggleHighlght('borderAmount')}
            >
              {meta.columns.borderAmount.label}
              {' '}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedzf2'}
              highlighted={highlights.includes('creditRepaymentLiquidatedzf2')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedzf2')}
            >
              {meta.columns.creditRepaymentLiquidatedzf2.label}
              {' '}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedzf1'}
              highlighted={highlights.includes('creditRepaymentLiquidatedzf1')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedzf1')}
            >
              {meta.columns.creditRepaymentLiquidatedzf1.label}
              {' '}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedzf0'}
              highlighted={highlights.includes('creditRepaymentLiquidatedzf0')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedzf0')}
            >
              {meta.columns.creditRepaymentLiquidatedzf0.label}
              {' '}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedsf2'}
              highlighted={highlights.includes('creditRepaymentLiquidatedsf2')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedsf2')}
            >
              {meta.columns.creditRepaymentLiquidatedsf2.label}
              {' '}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedsf1'}
              highlighted={highlights.includes('creditRepaymentLiquidatedsf1')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedsf1')}
            >
              {meta.columns.creditRepaymentLiquidatedsf1.label}
              {' '}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'creditRepaymentLiquidatedsf0'}
              highlighted={highlights.includes('creditRepaymentLiquidatedsf0')}
              onToggleHighlght={() => onToggleHighlght('creditRepaymentLiquidatedsf0')}
            >
              {meta.columns.creditRepaymentLiquidatedsf0.label}
              {' '}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'receivables2'}
              highlighted={highlights.includes('receivables2')}
              onToggleHighlght={() => onToggleHighlght('receivables2')}
            >
              {meta.columns.receivables2.label}
              {' '}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'receivables1'}
              highlighted={highlights.includes('receivables1')}
              onToggleHighlght={() => onToggleHighlght('receivables1')}
            >
              {meta.columns.receivables1.label}
              {' '}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'receivables0'}
              highlighted={highlights.includes('receivables0')}
              onToggleHighlght={() => onToggleHighlght('receivables0')}
            >
              {meta.columns.receivables0.label}
              {' '}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason'}
              highlighted={highlights.includes('reason')}
              onToggleHighlght={() => onToggleHighlght('reason')}
            >
              {meta.columns.reason.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'takenActions'}
              highlighted={highlights.includes('takenActions')}
              onToggleHighlght={() => onToggleHighlght('takenActions')}
            >
              {meta.columns.takenActions.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
