import React from 'react';
import PropTypes from 'prop-types';
import { InputWithDropdown } from '../../../../../../../../components/styledInputs';

const vericalAlign = {
  TOP: {
    label: 'Притиснути вверх',
    name: 'Top',
  },
  BOTTOM: {
    label: 'Притиснути вниз',
    name: 'Bottom',
  },
  CENTER: {
    label: 'Центрувати',
    name: 'Center',
  },
};

const enumValues = Object.values(vericalAlign);

function VerticalAlignSelector({ value, onChange, noBorder }) {
  return (
    <InputWithDropdown
      value={value}
      onChange={onChange}
      options={enumValues}
      noBorder={noBorder}
    />
  );
}

VerticalAlignSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(vericalAlign).map((v) => v.name)),
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
};

VerticalAlignSelector.defaultProps = {
  value: vericalAlign.TOP.name,
  noBorder: false,
};

export default VerticalAlignSelector;
