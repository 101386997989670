import PropTypes from 'prop-types';
import React from 'react';
import {
  Tab, Tabs,
} from 'react-bootstrap';
import {
  // Dod1TP,
  Dod2TP,
  Dod3TP,
  DocsTP,
  HintForms,
} from './tabs';
import md from '../../../../constants/meta/documents/liquidityVer2';
import Dod1 from './tabs/dod1';

function LiquidityTabs({
  data,
  actions,
  permissions,
}) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs className="m-0">
      <Tab title={md.tables.dod1.label} eventKey="dod1">
        <Dod1 actions={actions} data={data} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.dod2.label} eventKey="dod2">
        <Dod2TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.dod3.label} eventKey="dod3">
        <Dod3TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.docs.label} eventKey="docs">
        <DocsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title='Рекомендації щодо заповнення форм' eventKey="hint">
        <HintForms />
      </Tab>
    </Tabs>
  );
}

LiquidityTabs.propTypes = {
  data: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default LiquidityTabs;
