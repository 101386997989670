import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function getChildrens(itm) {
  function flatInnter(itms) {
    return itms.reduce((R, child) => {
      if (child.children) {
        return [...R, child.id, ...flatInnter(child.children)];
      }
      return [...R, child.id];
    }, []);
  }
  if (itm.children) {
    return flatInnter(itm.children);
  }
  return [];
}

function TreeviewListItem({
  item,
  openedItems,
  setOpenedItems,
  selectedItems,
  setSelectedItems,
  selection,
  currentItem,
  setCurrentItem,
}) {
  const [open, setOpen] = useState(openedItems.indexOf(item.id) !== -1);
  const [children, setChildren] = useState([]);
  const [firstChildren, setFirstChildren] = useState([]);
  const [childrenOpen, setChildrenOpen] = useState(false);
  const checkRef = useRef();

  const handleOnExiting = () => {
    setOpenedItems(openedItems.filter((openedItem) => openedItem !== item.id));
  };
  const handleEntering = () => {
    setOpenedItems([...openedItems, item.id]);
  };

  const handleSingleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, item.id]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item.id),
      );
    }
  };

  const handleParentCheckboxChange = (e) => {
    const filteredItems = selectedItems.filter(
      (selectedItem) => children.indexOf(selectedItem) === -1,
    );
    if (e.target.checked) {
      setSelectedItems([...filteredItems, ...children]);
    } else {
      setSelectedItems(filteredItems);
    }
  };

  useEffect(() => {
    setChildren(getChildrens(item));
    if (item.children) {
      setFirstChildren(item.children.map((child) => child.id));
    }
  }, [item]);

  useEffect(() => {
    setChildrenOpen(openedItems.some((itm) => firstChildren.indexOf(itm) !== -1));
  }, [children, firstChildren, openedItems]);

  useEffect(() => {
    const childrenSelected = selectedItems.some(
      (selectedItem) => children.indexOf(selectedItem) !== -1,
    );
    const allChildrenSelected = children.every(
      (child) => selectedItems.indexOf(child) !== -1,
    );
    if (childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = true;
    }
    if (!childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
    }
    if (allChildrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
      checkRef.current.checked = true;
    }
    if (!allChildrenSelected && checkRef.current) {
      checkRef.current.checked = false;
    }
  }, [selectedItems, children]);

  return (
    <li className="treeview-list-item">
      {item.children ? (
        <>
          <div className={classNames('toggle-container', { selected: currentItem === item.id })}>
            {selection && setSelectedItems && (
              <input
                type="checkbox"
                className="form-check-input"
                onChange={handleParentCheckboxChange}
                ref={checkRef}
              />
            )}
            <Button
              variant="link"
              className={classNames('collapse-toggle', {
                collapsed: open || item.expanded,
              })}
              onClick={() => {
                setOpen(!open);
                if (setCurrentItem) setCurrentItem(item.id);
              }}
            >
              <p
                className="treeview-text"
              >
                {item.icon && (
                // eslint-disable-next-line react/no-danger
                  <span
                    className={classNames('treeview-icon', item.iconClass)}
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                  />
                )}
                {item.faIcon && (
                  <FontAwesomeIcon className={classNames('treeview-icon me-1 ', item.iconClass)} icon={item.faIcon} />
                )}
                {item.name}
              </p>
            </Button>
          </div>
          <Collapse
            in={open}
            onExiting={handleOnExiting}
            onEntering={handleEntering}
          >
            <ul
              className={classNames('treeview-list ps-3', {
                'collapse-hidden': !open,
                'collapse-show treeview-border': open,
                'treeview-border-transparent': childrenOpen,
              })}
            >
              {item.children.map((nestedItem, index) => (
                <TreeviewListItem
                  key={nestedItem.id}
                  item={nestedItem}
                  index={index}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selection={selection}
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <div className={classNames('treeview-item', { selected: currentItem === item.id })}>
          {selection && (
            <input
              type="checkbox"
              className="form-check-input"
              onChange={handleSingleCheckboxChange}
              checked={selectedItems.indexOf(item.id) !== -1}
            />
          )}
          <Button
            variant="link"
            className="flex-1"
            onClick={() => {
              if (setCurrentItem) setCurrentItem(item.id);
            }}
          >
            <p className="treeview-text">
              {item.icon && (
              // eslint-disable-next-line react/no-danger
                <span
                  className={classNames('treeview-icon', item.iconClass)}
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                />
              )}
              {item.faIcon && (
                <FontAwesomeIcon
                  icon={item.faIcon}
                  className={classNames('treeview-icon me-1', item.iconClass)}
                  size="xs"
                />
              )}
              {item.name}
            </p>
          </Button>
        </div>
      )}
    </li>
  );
}

TreeviewListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    expanded: PropTypes.bool,
    name: PropTypes.node.isRequired,
    icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    iconClass: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    faIcon: PropTypes.shape({}),
  }).isRequired,
  openedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setOpenedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setSelectedItems: PropTypes.func,
  selection: PropTypes.bool,
  currentItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCurrentItem: PropTypes.func,
};

TreeviewListItem.defaultProps = {
  openedItems: [],
  selectedItems: [],
  selection: false,
  setSelectedItems: null,
  currentItem: null,
  setCurrentItem: null,
};

export default TreeviewListItem;
