const frontendURL = '/cat/bfnGroupAge/';
const backendURL = '/api/bfn/refgroupage/';
const name = 'Вік дітей у групі';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
