import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import GridTemplate from './GridTemplate';
import { DivCell, InputCodeStyled } from '../../../../../components/styledTable/styledTable';

function RowComponent({
  row, rowId, dispatch, FI,
  ...props
}) {
  return (
    <GridTemplate {...props}>
      <DivCell areaName="NumDoc">
        <InputCodeStyled
          value={row.get('NumDoc', '')}
        />
      </DivCell>
      <DivCell areaName="DocumentPresent">
        <InputCodeStyled
          noBackground
          noBorder
          value={row.get('DocumentPresent', '')}
          readOnly
        />
      </DivCell>
    </GridTemplate>
  );
}

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  FI: PropTypes.instanceOf(Map).isRequired,
};

export default RowComponent;
