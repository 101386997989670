import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Container, Badge, Table, Row, Col,
} from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  CommandPanelColor,
  StyledLabel,
} from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/documents/indicatorPassport';
import {
  DocsTP,
  IndicatorsTables,
  RegionProgramsTables,
  SourcesInvestmentProjects,
  TargetsOfStatePolicyTable,
  TasksSpentTable,
  TasksTable,
} from './tabs';

const sumOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

function IndicatorPassportTabs({
  data, actions, permissions,
}) {
  const readOnly = !permissions.canChange;
  const tableData = data[md.tables.tasksSpent.name] || [];
  const sumGF = tableData.reduce((R, row) => R + row[md.tables.tasksSpent.columns.sumGenF.name], 0);
  const sumSF = tableData.reduce(
    (R, row) => R + row[md.tables.tasksSpent.columns.sumSpecF.name],
    0,
  );

  return (
    <Tabs defaultActiveKey="targetsOfStatePolicy">
      <Tab title={md.tables.targetsOfStatePolicy.label} eventKey="targetsOfStatePolicy">
        <Container fluid className="border border-top-0">
          <TargetsOfStatePolicyTable data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
      <Tab title={md.tables.tasksSpent.label} eventKey="tasksSpent">
        <Container fluid className="border border-top-0">
          <TasksSpentTable data={data} actions={actions} readOnly={readOnly} />
          <Row className="mt-2 pt-2 border-top">
            <Col>
              <Table bordered hover striped size="sm">
                <thead>
                  <tr>
                    <th colSpan={3} scope="col">Рік</th>
                  </tr>
                  <tr>
                    <th scope="col">Фонд</th>
                    <th scope="col">Заг.ф</th>
                    <th scope="col">Спец.ф</th>
                  </tr>

                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Підсумок</th>
                    <td className="text-right">{sumGF.toLocaleString('uk-UA', sumOptions)}</td>
                    <td className="text-right">{sumSF.toLocaleString('uk-UA', sumOptions)}</td>
                  </tr>
                  <tr>
                    <th scope="row">План</th>
                    <td className="text-right">
                      {data[md.columns.sumGenF.name] ? data[md.columns.sumGenF.name]
                        .toLocaleString('uk-UA', sumOptions) : ''}
                    </td>
                    <td className="text-right">
                      {data[md.columns.sumSpecF.name] ? data[md.columns.sumSpecF.name]
                        .toLocaleString('uk-UA', sumOptions) : ''}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Різниця</th>
                    <td className="text-right">
                      {(data[md.columns.sumGenF.name] - sumGF).toLocaleString('uk-UA', sumOptions)}
                    </td>
                    <td className="text-right">
                      {(data[md.columns.sumSpecF.name] - sumSF).toLocaleString('uk-UA', sumOptions)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <EditorControls.TextInput
                label={md.columns.fullNameBP.label}
                value={data[md.columns.fullNameBP.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.fullNameBP.name]: value,
                })}
                rows={5}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title={md.tables.tasks.label} eventKey="tasks">
        <Container fluid className="border border-top-0">
          <TasksTable data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
      <Tab title={md.tables.generalTPInd.label} eventKey="generalTPInd">
        <IndicatorsTables data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.regionPrograms.label} eventKey="regionPrograms">
        <Container fluid className="border border-top-0">
          <RegionProgramsTables data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
      <Tab title={md.tables.sourcesInvestmentProjects.label} eventKey="sourcesInvestmentProjects">
        <Container fluid className="border border-top-0">
          <SourcesInvestmentProjects data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
      <Tab title="Підстави" eventKey="reason">
        <Container fluid className="mx-1 my-4">
          <Row sm={2}>
            <Col>
              <StyledLabel>{md.columns.KPKVCode.label}</StyledLabel>
              <h5>
                <Badge variant="info">{data[md.columns.KPKVCode.name]}</Badge>
              </h5>
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.signatureVariants.label}
                value={data[md.columns.signatureVariants.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.signatureVariants.name]: value,
                })}
                modelType="cat"
                modelName="signatureVariants"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <h4 className="text-primary">Мета та законодавчі підстави бюдж.програми</h4>
          <Row sm={2}>
            <Col>
              <EditorControls.TextInput
                label={md.columns.groundLaw.label}
                value={data[md.columns.groundLaw.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.groundLaw.name]: value,
                })}
                rows={5}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.TextInput
                label={md.columns.target.label}
                value={data[md.columns.target.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.target.name]: value,
                })}
                rows={5}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <h4 className="text-primary">Накази про затвердження бюдж.програми</h4>
          <Row sm={2}>
            <Col>
              <Row>
                <Col>
                  <EditorControls.StringInput
                    label={md.columns.orderN.label}
                    value={data[md.columns.orderN.name]}
                    precision={0}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.orderN.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.DateInput
                    label={md.columns.orderD.label}
                    value={data[md.columns.orderD.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.orderD.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <EditorControls.ItemPicker
                label={md.columns.laws1.label}
                value={data[md.columns.laws1.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.laws1.name]: value,
                  });
                  actions.onSR('CHANGE_laws1');
                }}
                modelType="cat"
                modelName="laws"
                readOnly={readOnly}
              />
              <EditorControls.TextInput
                label={md.columns.lawsStr1.label}
                value={data[md.columns.lawsStr1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.lawsStr1.name]: value,
                })}
                rows={5}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.laws2.label}
                value={data[md.columns.laws2.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.laws2.name]: value,
                  });
                  actions.onSR('CHANGE_laws2');
                }}
                modelType="cat"
                modelName="laws"
                readOnly={readOnly}
              />
              <EditorControls.TextInput
                label={md.columns.lawsStr2.label}
                value={data[md.columns.lawsStr2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.lawsStr2.name]: value,
                })}
                rows={5}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title={md.tables.descendantsDocuments.label} eventKey="descendantsDocuments">
        <DocsTP data={data} actions={actions} readOnly={readOnly}>
          <CommandPanelColor
            style={{ width: 'fit-content' }}
            label="Заповнити даними документів з закладки"
            onClick={() => actions.onSR('LOAD_FROM_DOC_SELECTION2')}
            disabled={readOnly}
          />
        </DocsTP>
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-4">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

IndicatorPassportTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    onSR: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};
export default IndicatorPassportTabs;
