/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Background({
  image, overlay, position, video, className, style,
}) {
  const bgStyle = { backgroundImage: `url(${image})`, ...style };
  if (typeof position === 'string') {
    bgStyle.backgroundPosition = position;
  } else if (typeof position === 'object') {
    // eslint-disable-next-line no-unused-expressions
    position.x && (bgStyle.backgroundPositionX = position.x);
    // eslint-disable-next-line no-unused-expressions
    position.y && (bgStyle.backgroundPositionY = position.y);
  }

  return (
    <div
      className={classNames(
        'bg-holder',
        {
          overlay,
          [`overlay-${overlay}`]: typeof overlay === 'string',
        },
        className,
      )}
      style={bgStyle}
    >
      {video && (
        <video className="bg-video" autoPlay loop muted playsInline>
          {video.map((src, index) => (
            <source
              key={`video_${index}`}
              src={src}
              type={`video/${src.split('.').pop()}`}
            />
          ))}
        </video>
      )}
    </div>
  );
}

Background.propTypes = {
  image: PropTypes.string.isRequired,
  overlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
    }),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  video: PropTypes.array,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Background.defaultProps = {
  overlay: '',
  position: '',
  video: [],
  className: '',
  style: {},
};

export default Background;
