import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_FIELD_STATE = {
  include: [],
  ctype: null,
  formula: '',
};

export const useCalcs = () => {
  const [calcs, setCalcs] = useState([]);

  const [activeCalc, setActiveCalc] = useState(null);

  const onAddCalc = useCallback(
    (name) => setCalcs((oldCalcs) => {
      if (oldCalcs.filter((oc) => oc.name === name).length) return oldCalcs;
      return [...oldCalcs, { name, ...DEFAULT_FIELD_STATE }];
    }),
    [],
  );

  const onRemoveCalc = useCallback(
    (cName) => {
      setCalcs((oldCalcs) => oldCalcs.filter((cf) => cf.name !== cName));
      if (activeCalc === cName) setActiveCalc(null);
    },
    [activeCalc],
  );

  const onRenameCalc = useCallback(
    (oldName, newName) => {
      if (!newName) return false; // Имя таблицы must have
      setCalcs((oldCalcs) => {
        if (oldCalcs
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldCalcs;
        return oldCalcs.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeCalc === oldName) setActiveCalc(newName);
      return true;
    },
    [activeCalc],
  );

  const onChangeCalc = useCallback(
    (tableName, value) => {
      setCalcs((oldCalcs) => oldCalcs
        .map((oc) => (oc.name !== tableName ? oc : { ...oc, ...value })));
    },
    [],
  );

  const calcHandlers = useMemo(
    () => ({
      onAddCalc,
      onRenameCalc,
      onRemoveCalc,
      onChangeCalc,
      setActiveCalc,
    }),
    [onAddCalc, onChangeCalc, onRemoveCalc, onRenameCalc],
  );

  return {
    calcs, setCalcs, activeCalc, calcHandlers,
  };
};

export const calcPropType = PropTypes.shape({
  name: PropTypes.string,
  ctype: PropTypes.string,
  include: PropTypes.arrayOf(PropTypes.string),
  formula: PropTypes.string,
});
