import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import {
  ListGroup, Card, Button, Collapse,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Field from './field';
import itemTypes from '../itemTypes';
import corner1 from '../../../../assets/img/illustrations/corner-3.png';
import Background from '../../../../components/blanks/common/Background';

function AvailableFieldList({
  onClick, activeField, cardTitle, cardText, onDblClick, onDragEnd,
  visibleItems, setOpenedItems, openedItems,
}) {
  // eslint-disable-next-line no-unused-vars
  const [_collected, ref] = useDrop({
    accept: [
      itemTypes.group, itemTypes.selection, itemTypes.order, itemTypes.filter, itemTypes.column,
    ],
    drop: (item) => onDragEnd(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });
  const [helpOpened, setHelpOpened] = useState(false);

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <Card className="rounded-0 border-bottom">
        <Background
          image={corner1}
          className="bg-card d-none d-sm-block rounded-0"
        />
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title>
              {cardTitle}
            </Card.Title>
            <Button onClick={() => setHelpOpened((o) => !o)} size="sm" variant="link" className="z-1">
              <FontAwesomeIcon icon={faQuestionCircle} bounce={helpOpened} />
            </Button>
          </div>
          <Collapse in={helpOpened}>
            <Card.Text>
              {cardText}
            </Card.Text>
          </Collapse>
        </Card.Body>
      </Card>
      <ListGroup ref={ref} className="flex-fill scrollbar fs--1">
        {visibleItems && visibleItems.map((f) => (
          <Field
            key={f.name}
            label={f.label}
            name={f.name}
            onClick={onClick}
            active={activeField === f.name}
            onDblClick={onDblClick}
            level={f.lvl}
            onToggleOpen={setOpenedItems}
            hasChild={f.hasChild}
            opened={openedItems.includes(f.name)}
          />
        ))}
      </ListGroup>
    </div>
  );
}

export const availablePropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
}));

AvailableFieldList.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeField: PropTypes.string,
  cardTitle: PropTypes.node.isRequired,
  cardText: PropTypes.node.isRequired,
  onDblClick: PropTypes.func,
  onDragEnd: PropTypes.func.isRequired,
  visibleItems: availablePropType,
  setOpenedItems: PropTypes.func.isRequired,
  openedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AvailableFieldList.defaultProps = {
  activeField: null,
  onDblClick: () => null,
  visibleItems: [],
};
export default AvailableFieldList;
