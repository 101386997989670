import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/kbp7Classifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const dateParam = 'НаДату';

const mapState = (store) => {
  const sessionBudget = store.getIn(['auth', 'sessionOptions', 'budget'], new Map());
  return {
    sessionBudget,
  };
};

function ListerSelector({
  filter, params, children, ...restProps
}) {
  const { sessionBudget } = useSelector(mapState);

  const [budget, setBudget] = useState(sessionBudget);

  const [currentDate, setCurrentDate] = useState(() => params.filter(
    (p) => p.name === dateParam,
  ).reduce((R, p) => p.value, new Date().valueOf()));

  const [showInactive, setShowInactive] = useState(false);

  const flt = useMemo(
    () => [
      ...filter.filter(
        (f) => ![md.columns.owner.name].includes(f.fieldName),
      ),
      {
        fieldName: md.columns.owner.name,
        value: budget,
      },
      ...(showInactive ? [] : [
        {
          fieldName: md.columns.Inactive.name,
          value: false,
        }]),
    ],
    [budget, filter, showInactive],
  );
  const prm = useMemo(
    () => [
      ...params.filter((p) => p.name !== dateParam),
      {
        name: dateParam,
        value: currentDate,
      },
    ],
    [currentDate, params],
  );

  const CH = cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
    params: prm,
  });

  return (
    <>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={budget}
            onChange={(e, v) => {
              setBudget(v);
            }}
            modelType="cat"
            modelName="budget"
          />

        </Col>
        <Col>
          <EditorControls.DateInput
            label="Показувати на дату"
            value={currentDate}
            onChange={(e, v) => setCurrentDate(v)}
          />
        </Col>
        <Col sm={2} className="d-flex align-items-end text-right">
          <EditorControls.CheckboxInput
            controlId="KBP7_Show_Inactives"
            label="Показати недіючи"
            value={showInactive}
            onChange={(e, v) => setShowInactive(v)}
            description="Якщо обрано, будуть показані діючи та недіючи коди"
          />
        </Col>
      </Row>
      {CH}
    </>
  );
}

ListerSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.instanceOf(Map),
    ]),
  })),
  children: PropTypes.element.isRequired,
};

ListerSelector.defaultProps = {
  filter: [],
  params: [],
};

export default ListerSelector;
