import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноза', visible: true },
  explaining_32: { label: '3.2. Пояснення щодо змін у структурі видатків та надання кредитів за бюджетними програмами', name: 'Объяснения_32', visible: false },
  explaining_42: { label: '4.2. Пояснення відмінностей від прогнозу місцевого бюджету, схваленого у попередньому бюджетному періоді', name: 'Объяснения_42', visible: false },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  usePCMCatalog: {
    label: 'Використовувати довідник показників з  ПЦМ', name: 'ИспользоватьСправочникиПЦМ', visible: true, type: 'bool',
  },
  isGet: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  inFill: {
    label: 'Доповнити існуючі дані даними з завантажених документів', name: 'Дописать', visible: true, type: 'bool',
  },
};

/**
 * @const
 */
const tables = {
  tar: {
    name: 'ЦелиГосПолитики',
    label: 'Цілі державної політики',
    columns: {
      tar: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      name: { label: 'Од вим', name: 'ЕдИзм' },
      indicator: { label: 'Показник', name: 'Показатели' },
      task: { label: 'Напрямок', name: 'Задание' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
    },
  },
  exp: {
    name: 'Расходы',
    label: 'Видатки',
    columns: {
      exp: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      KPKV: { label: 'Елемент КПКВ', name: 'ЭлементыКФК' },
      CSU: { label: 'Відповідальний виконавкець', name: 'Распорядители' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      br_2: { label: 'БР_2', name: 'БР_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      br_1: { label: 'БР_1', name: 'БР_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      br0: { label: 'БР0', name: 'БР0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      br1: { label: 'БР1', name: 'БР1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
      br2: { label: 'БР2', name: 'БР2' },
    },
  },
  com: {
    name: 'Сравнение',
    label: 'Порівняння',
    columns: {
      exp: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      KPKV: { label: 'Елемент КПКВ', name: 'ЭлементыКФК' },
      indicator: { label: 'Показники', name: 'Показатели' },
      task: { label: 'Напрямок', name: 'Задание' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      sum0appr: { label: 'Сумма0Утв', name: 'Сумма0Утв' },
      zf0appr: { label: 'ЗФ0Утв', name: 'ЗФ0Утв' },
      sf0appr: { label: 'СФ0Утв', name: 'СФ0Утв' },
      sum0div: { label: 'Сумма0Откл', name: 'Сумма0Откл' },
      zf0div: { label: 'ЗФ0Откл', name: 'ЗФ0Откл' },
      sf0div: { label: 'СФ0Откл', name: 'СФ0Откл' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      sum1appr: { label: 'Сумма1Утв', name: 'Сумма1Утв' },
      zf1appr: { label: 'ЗФ1Утв', name: 'ЗФ1Утв' },
      sf1appr: { label: 'СФ1Утв', name: 'СФ1Утв' },
      sum1div: { label: 'Сумма1Откл', name: 'Сумма1Откл' },
      zf1div: { label: 'ЗФ1Откл', name: 'ЗФ1Откл' },
      sf1div: { label: 'СФ1Откл', name: 'СФ1Откл' },
    },
  },
  comT: {
    name: 'СравнениеЦели',
    label: 'Порівняння  у розрізі цілей держ політики',
    columns: {
      exp: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      KPKV: { label: 'Елемент КПКВ', name: 'ЭлементыКФК' },
      indicator: { label: 'Показники', name: 'Показатели' },
      task: { label: 'Напрямок', name: 'Задание' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      sum0appr: { label: 'Сумма0Утв', name: 'Сумма0Утв' },
      zf0appr: { label: 'ЗФ0Утв', name: 'ЗФ0Утв' },
      sf0appr: { label: 'СФ0Утв', name: 'СФ0Утв' },
      sum0div: { label: 'Сумма0Откл', name: 'Сумма0Откл' },
      zf0div: { label: 'ЗФ0Откл', name: 'ЗФ0Откл' },
      sf0div: { label: 'СФ0Откл', name: 'СФ0Откл' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      sum1appr: { label: 'Сумма1Утв', name: 'Сумма1Утв' },
      zf1appr: { label: 'ЗФ1Утв', name: 'ЗФ1Утв' },
      sf1appr: { label: 'СФ1Утв', name: 'СФ1Утв' },
      sum1div: { label: 'Сумма1Откл', name: 'Сумма1Откл' },
      zf1div: { label: 'ЗФ1Откл', name: 'ЗФ1Откл' },
      sf1div: { label: 'СФ1Откл', name: 'СФ1Откл' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Пропозиція до прогнозу місцевого бюджету (Форма БП-1)(Додаток 2)',
  name: 'prepareForecastAdd2',
  backendName: 'ПрогнозДодП2',
  columns,
  frontend: 'doc/prepareForecastAdd2',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'appr',
      'year',
      'isGet',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
