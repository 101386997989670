import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FI: { label: 'Фінансовий орган', name: 'ТекущийФинансовыйОрган' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  ToDate: { label: 'Робоча дата', name: 'НаДату' },
  Account: { label: 'По рахунку', name: 'ПоСчету' },
  listDate: { label: 'Список дат', name: 'СписокДат' },
  endDate: { label: 'Кінець періоду', name: 'ДатаОкончания' },
  onlyPositive: { label: 'Тільки з позитивним залишком', name: 'флБезНулевых' },
  stYear: { label: 'За період', name: 'СначалаГода' },
  year: { label: 'Рік виписок', name: 'Год' },
  dateStart: { label: 'За період з:', name: 'ДатаНачалаОбщаяФорма' },
  dateFinish: { label: 'по:', name: 'ДатаОкончанияОбщаяФорма' },
  fond: { label: 'Фонд', name: 'Фонд' },
  mainForm: { label: 'Інформація про рух коштів на рахунках бюджету', name: 'ОбщаяФорма' },
};

const tables = {
  tvDates: {
    name: 'Даты',
    label: 'Даты',
    columns: {
      selected: { label: 'Обрано', name: 'Выбран' },
      date: { label: 'Дата', name: 'Дата' },
      dateS: { label: 'Дата', name: 'Дата_Стр' },
    },
  },
  tvAcc: {
    name: 'Счета',
    label: 'Доступні рахунки',
    columns: {
      selected: { label: 'Обрано', name: 'Выбран' },
      acc: { label: 'Рахунок', name: 'Рахунок' },
      nameS: { label: 'Назва', name: 'Наим' },
      codeS: { label: 'Номер', name: 'Номер' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'certificateOfAccountPrint',
  backendName: 'ПечатьСправкиПоСчетам',
  frontend: 'dp/certificateOfAccountPrint',
  label: 'Довідка про рух коштів на рахунку',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
