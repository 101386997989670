import doc, { doc1c } from './documents';
import cat, { cat1c } from './catalogs';
import dp from './dataprocessors';
import rep from './reports';
import { ChTP } from './ChTP';
import { infoRegs } from './infoRegs';

/**
 * @const
 * @type {object}
 * @property {cat} cat
 * @property {doc} doc
 * @property {ChTP} ChTP
 */
// eslint-disable-next-line no-underscore-dangle
const _meta = {
  cat,
  doc,
  dp,
  rep,
  ChTP,
  infoRegs,
};

const meta = Object.keys(_meta).reduce((R, modelType) => ({
  ...R,
  [modelType]: Object.keys(_meta[modelType]).reduce((RR, modelName) => ({
    ...RR,
    [modelName]: { modelType, modelName, ..._meta[modelType][modelName] },
  }), {}),
}), {});

export default meta;

const metaByType = new Proxy({}, {
  get(target, prop) {
    if (prop.toLowerCase() === 'справочник') {
      return cat1c;
    }
    if (prop.toLowerCase() === 'документ') {
      return doc1c;
    }
    throw new Error(`${prop} не знайдено в метаданих`);
  },
});

const metaByModelType = new Proxy({}, {
  get(target, prop) {
    if (prop.toLowerCase() === 'cat') {
      return cat1c;
    }
    if (prop.toLowerCase() === 'doc') {
      return doc1c;
    }
    throw new Error(`${prop} не знайдено в метаданих`);
  },
});

export const getURLByBackendType = (type) => {
  const ClassNType = type.split('.');
  if (ClassNType.length !== 2) {
    console.error(`${type} is not valid type`);
    return null;
  }
  return metaByType[ClassNType[0]][ClassNType[1]].frontend;
};

export const getURLByBackendModelType = (
  modelType,
  modelName,
) => metaByModelType[modelType][modelName].frontend;

// Object.keys(doc).sort().forEach((c) => console.log(c));

export const metaMaper = Object.keys(_meta).reduce((R, modelType) => ({
  ...R,
  [modelType]: Object.keys(_meta[modelType]).reduce((RR, modelName) => ({
    ...RR,
    [_meta[modelType][modelName].backendName]: modelName,
  }), {}),
}), {});
