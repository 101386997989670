import activity from './activity';
import assignment from './assignment';
import authoritysignvariant from './authoritysignvariant';
import banks from './banks';
import bfnAmount from './bfnAmount';
import bfnBanDoc from './bfnBanDoc';
import bfnGoalFondAmount from './bfnGoalFondAmount';
import bfnGroupAge from './bfnGroupAge';
import bfnGroupFullness from './bfnGroupFullness';
import bfnGroupName from './bfnGroupName';
import bfnGroupTypes from './bfnGroupTypes';
import bfnPeriod from './bfnPeriod';
import bringdocument from './bringdocument';
import budget from './budget';
import conrtragent from './conrtragent';
import contragentaccount from './contragentaccount';
import contragentbank from './contragentbank';
import dbUrl from './dbUrl';
import direction from './direction';
import disposers from './disposers';
import disposerscontragent from './disposerscontragent';
import documentbasis from './documentbasis';
import documentnote from './documentnote';
import executive from './executive';
import executor from './executor';
import explanation from './explanation';
import ExternalSource from './ExternalSource';
import fond from './fond';
import fonditem from './fonditem';
import genderage from './genderage';
import gendersegment from './gendersegment';
import genderstatus from './genderstatus';
import goalIndicator from './goalIndicator';
import indicator from './indicator';
import indicatorclassifier from './indicatorclassifier';
import infosource from './infosource';
import kbp from './kbp';
import kbp7 from './kbp7';
import kbp7item from './kbp7item';
import kdb from './kdb';
import kekv from './kekv';
import kekvitem from './kekvitem';
import kfb from './kfb';
import kfk from './kfk';
import kindwork from './kindwork';
import kvk from './kvk';
import kvk3 from './kvk3';
import law from './law';
import lawrealisebasis from './lawrealisebasis';
import lawregulation from './lawregulation';
import manual from './manual';
import minfinactivity from './minfinactivity';
import minfinindicator from './minfinindicator';
import note from './note';
import paymentpurposetemplate from './paymentpurposetemplate';
import pcmindicator from './pcmindicator';
import personBudget from './personBudget';
import projectvariants from './projectvariants';
import publicpolicytarget from './publicpolicytarget';
import remarknote from './remarknote';
import reportlabel from './reportlabel';
import responsiblePerson from './responsiblePerson';
import schema from './schema';
import shortkekv from './shortkekv';
import socialpayment from './socialpayment';
import sourceofcoverage from './sourceofcoverage';
import staff from './staff';
import structureoflaborcosts from './structureoflaborcosts';
import symbolKdb from './symbolKdb';
import targetprogram from './targetprogram';
import task from './task';
import taskclassifier from './taskclassifier';
import templateddocumentbasis from './templateddocumentbasis';
import treasureaccount from './treasureaccount';
import typesofentrysf from './typesofentrysf';
import unitsofmeasurement from './unitsofmeasurement';
import user from './user';
import workercategory from './workercategory';

const cat = {
  activity,
  assignment,
  authoritysignvariant,
  banks,
  bfnAmount,
  bfnBanDoc,
  bfnGoalFondAmount,
  bfnGroupAge,
  bfnGroupFullness,
  bfnGroupName,
  bfnGroupTypes,
  bfnPeriod,
  bringdocument,
  budget,
  conrtragent,
  contragentaccount,
  contragentbank,
  dbUrl,
  direction,
  disposers,
  disposerscontragent,
  documentbasis,
  documentnote,
  executive,
  executor,
  explanation,
  ExternalSource,
  fond,
  fonditem,
  genderage,
  gendersegment,
  genderstatus,
  goalIndicator,
  indicator,
  indicatorclassifier,
  infosource,
  kbp,
  kbp7,
  kbp7item,
  kdb,
  kekv,
  kekvitem,
  kfb,
  kfk,
  kindwork,
  kvk,
  kvk3,
  law,
  lawrealisebasis,
  lawregulation,
  manual,
  minfinactivity,
  minfinindicator,
  note,
  paymentpurposetemplate,
  pcmindicator,
  personBudget,
  projectvariants,
  publicpolicytarget,
  remarknote,
  reportlabel,
  responsiblePerson,
  schema,
  shortkekv,
  socialpayment,
  sourceofcoverage,
  staff,
  structureoflaborcosts,
  symbolKdb,
  targetprogram,
  task,
  taskclassifier,
  templateddocumentbasis,
  treasureaccount,
  typesofentrysf,
  unitsofmeasurement,
  user,
  workercategory,

};

export default cat;
