import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import {
  Accordion, Card, Col, Row, Button,
} from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/objectsBuilding';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { SearchInput } from '../../../../components/styledInputs';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

const filterValues = [
  { value: 1, label: 'Всі' },
  { value: 2, label: 'Тільки об\'єкти капітального будівництва' },
  { value: 3, label: 'Тільки ремонт' },
];

const mapState = (store) => {
  const isChiefBuilding = store.getIn(['is_chief_building'], false);

  return {
    isChiefBuilding,
  };
};

function ElementListerSelector({
  filter, params, children, ...restProps
}) {
  const { isChiefBuilding } = useSelector(mapState);

  const [districts, setDistricts] = useState(new Map());
  const [streets, setStreets] = useState(new Map());
  const [houses, setHouses] = useState(new Map());
  const [csuOwner, setCsuOwner] = useState(new Map());
  const [client, setClient] = useState(new Map());
  const [inputValue, setInputValue] = useState(new Map());
  const [fValue, setFValue] = useState(1);
  const [showArchive, setShowArchive] = useState(false);

  const fltRepair = useMemo(
    () => {
      switch (fValue) {
        case 2:
          return [{
            fieldName: `${md.columns.Repair.name}`,
            value: false,
          }];
        case 3:
          return [{
            fieldName: `${md.columns.Repair.name}`,
            value: true,
          }];
        default:
          return [];
      }
    },
    [fValue],
  );

  const fltAddress = useMemo(
    () => [
      ...(districts && districts.id ? [{
        fieldName: md.columns.Districts.name, value: districts,
      }] : []),
      ...(streets && streets.id ? [{ fieldName: md.columns.Street.name, value: streets }] : []),
      ...(houses && houses.id ? [{ fieldName: md.columns.Houses.name, value: houses }] : []),
      ...(csuOwner && csuOwner.id ? [{
        fieldName: md.columns.CsuOwner.name, value: csuOwner,
      }] : []),
      ...(client && client.id ? [{ fieldName: md.columns.Client.name, value: client }] : []),
    ],
    [districts, streets, houses, csuOwner, client],
  );
  const flt = useMemo(
    () => [
      ...filter,
      ...fltAddress,
      ...fltRepair,
      ...(showArchive ? [{ fieldName: md.columns.NotArchive.name, value: true }] : []),
    ],
    [filter, fltAddress, fltRepair, showArchive],
  );

  const CH = cloneElement(Children.only(children), {
    ...restProps,
    noHierarchy: fltAddress.length,
    filter: flt,
  });

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Знайти за адресою
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <Row>
              {isChiefBuilding && (
                <SearchInput
                  value={inputValue}
                  onChange={(e, value) => {
                    setInputValue(value);
                  }}
                />
              )}
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.Districts.label}
                  value={districts}
                  modelType="cat"
                  modelName="districts"
                  onChange={(e, value) => {
                    setDistricts(value);
                  }}
                  canErase
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.Street.label}
                  value={streets}
                  modelType="cat"
                  modelName="streets"
                  filter={districts && districts.id ? [{ fieldName: 'Владелец', value: districts }] : []}
                  onChange={(e, value) => {
                    setStreets(value);
                  }}
                  canErase
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.Houses.label}
                  value={houses}
                  modelType="cat"
                  modelName="houses"
                  filter={[{ fieldName: 'Владелец', value: streets }]}
                  onChange={(e, value) => {
                    setHouses(value);
                  }}
                  canErase
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.CsuOwner.label}
                  value={csuOwner}
                  modelType="cat"
                  modelName="csu"
                  noHierarchy
                  onChange={(e, value) => {
                    setCsuOwner(value);
                  }}
                  canErase
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.Client.label}
                  value={client}
                  modelType="cat"
                  modelName="csu"
                  onChange={(e, value) => {
                    setClient(value);
                  }}
                  canErase
                />
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <FilteredTabs values={filterValues} value={fValue} onChange={(e, v) => setFValue(v)} />
      <DefaultCPButton
        onClick={() => setShowArchive(!showArchive)}
        label="Не показувати архівні"
        variant={showArchive ? 'outline-info' : 'info'}
      />
      {CH}
    </Accordion>
  );
}

ElementListerSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.instanceOf(Map),
    ]),
  })),
  children: PropTypes.element.isRequired,
};

ElementListerSelector.defaultProps = {
  filter: [],
  params: [],
};

export default ElementListerSelector;
