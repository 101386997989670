import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import RemoveIcon from '../../assets/icons/remove';

function AddButton({ text, onClick, ...args }) {
  return (
    <Button onClick={onClick} {...args} title={text}>
      <RemoveIcon />
    </Button>
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

AddButton.defaultProps = {
  text: 'Видалити',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default AddButton;
