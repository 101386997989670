import { useState, useEffect, useCallback } from 'react';

export const gridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};
export const useBreakpoints = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const getPoint = useCallback((bp) => (width > gridBreakpoints[bp]), [width]);

  const [breakpoints, setBreakpoints] = useState({
    up: getPoint,
    down: getPoint,
  });
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    setBreakpoints({
      up: getPoint,
      down: getPoint,
    });
  }, [getPoint, width]);

  return { width, height, breakpoints };
};
