//= ===============================================================================

/**
 * Підключаємий модуль на мові програмування JS для взаємодії з iframe SignWidget
 */

// //================================================================================
//
// (function (root, factory) {
// 	if (typeof define === 'function' && define.amd) {
// 		define([], factory);
// 	} else if (typeof module === 'object' && module.exports) {
// 		module.exports = factory();
// 	} else {
// 		var a = factory();
// 		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
// 	}
// }(this, function () {
//
// //================================================================================
const init = () => {
  const s_debug = true;
  //= ===============================================================================

  /**
 * Конструктор для створення об'єкту для взаємодії з iframe SignWidget
 * @param parentId - Ідентифікатор батківського елементу для відображення iframe,
 * який завантажує сторінку SignWidget
 * @param id - Ідентифікатор iframe, який завантажує сторінку SignWidget
 * @param src - URI з адресою за якою розташована сторінка SignWidget
 * @param formType - Тип форми для відображення (див. константи EndUser.FormType)
 * @param formParams - Параметри форми для відображення (див. EndUser.FormParams)
 */
  const EndUser = function (parentId, id, src, formType, formParams) {
    this.sender = 'EndUserSignWidgetConnector';
    this.reciever = 'EndUserSignWidget';
    this.version = '20190917';
    this.parentId = parentId;
    this.id = id;
    this.src = src;
    this.formType = formType || 0;
    this.formParams = formParams || null;
    this.iframe = this._appendIframe(parentId, id, src);
    this.m_promises = [];
  };

  //--------------------------------------------------------------------------------

  /**
 * Деструктор для видалення об'єкту для взаємодії з iframe SignWidget
 */
  EndUser.prototype.destroy = function () {
    this._removeIframe(this.iframe, this.parentId);
    this.m_promises = [];
  };

  //= ===============================================================================

  EndUser.prototype._parseURL = function (url) {
    const urlRegEx = new RegExp([
      '^(https?:)//',
      '(([^:/?#]*)(?::([0-9]+))?)',
      '(/{0,1}[^?]*)',
    ].join(''));
    const match = url.match(urlRegEx);
    return match && {
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      origin: `${match[1]}//${match[2]}`,
    };
  };

  //--------------------------------------------------------------------------------

  EndUser.prototype._appendIframe = function (parentId, id, src) {
    const pThis = this;
    let srcParams = `?address=${
      pThis._parseURL(window.location.href).origin}`;

    srcParams += `&formType=${pThis.formType}`;
    srcParams += `&debug=${false}`;
    if (pThis.formParams) {
      for (const paramName in pThis.formParams) { srcParams += `&${paramName}=${pThis.formParams[paramName]}`; }
    }

    const iframe = document.createElement('iframe');
    // iframe.setAttribute('sandbox', 'allow-scripts allow-forms allow-same-origin allow-top-navigation');
    iframe.setAttribute('src', src + srcParams);

    iframe.setAttribute('id', id);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowtransparency', 'true');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    document.getElementById(parentId).appendChild(iframe);

    const { origin } = pThis._parseURL(src);
    iframe.listener = function (event) {
      if (event.origin !== origin) { return; }

      pThis._recieveMessage(event);
    };
    window.addEventListener('message', iframe.listener, false);

    return iframe;
  };

  //--------------------------------------------------------------------------------

  EndUser.prototype._removeIframe = function (iframe, parentId) {
    if (iframe == null) { return; }

    if (iframe.listener != null) {
      window.removeEventListener('message', iframe.listener);
      iframe.listener = null;
    }

    document.getElementById(parentId).removeChild(iframe);
  };

  //--------------------------------------------------------------------------------

  EndUser.prototype._postMessage = function (cmd, params, _resolve, _reject) {
    const pThis = this;

    let p = null;
    const msg = {
      sender: pThis.sender,
      reciever: pThis.reciever,
      id: -1,
      cmd,
      params,
    };

    if (typeof _resolve === 'undefined' && typeof _reject === 'undefined') {
      p = new Promise(((resolve, reject) => {
        console.log('!! _postMessage pThis !!', pThis);
        // console.log('pThis', pThis);

        msg.id = pThis.m_promises.push({
          resolve,
          reject,
          msg,
        });
      }));
    } else {
      msg.id = pThis.m_promises.push({
        resolve: _resolve,
        reject: _reject,
        msg,
      });
    }

    try {
      const signWidget = document.getElementById(pThis.id);
      console.log('signWidget.contentWindow.postMessage(msg...', msg);
      signWidget.contentWindow.postMessage(msg, '*'); // pThis.src
    } catch (e) {
      if (s_debug) { console.log(`Main page post message error: ${e}`); }
    }

    if (s_debug) { console.log(`Main page post message: ${msg}`); }

    return p;
  };

  //--------------------------------------------------------------------------------

  EndUser.prototype._recieveMessage = function (event) {
    const pThis = this;

    if (s_debug) { console.log(`Main page recieve message: ${event.data}`); }

    const { data } = event;
    if ((data.reciever !== pThis.sender)
		|| (data.sender !== pThis.reciever)) {
      return;
    }

    if (data.id === -1) {
      const promises = pThis.m_promises;
      pThis.m_promises = [];
      for (let i = 0; i < promises.length; i++) {
        pThis._postMessage(
          promises[i].msg.cmd,
          promises[i].msg.params,
          promises[i].resolve,
          promises[i].reject,
        );
      }

      return;
    }

    const p = pThis.m_promises[data.id - 1];
    if (!p) {
      return;
    }

    delete pThis.m_promises[data.id - 1];

    if (data.error) {
      p.reject(data.error);
    } else {
      p.resolve(data.result);
    }
  };

  //= ===============================================================================

  /**
 * Константи та функції підтримка яких буде видалена в наступних версіях
*/

  /**
 * Типи форм для відображення в SignWidget. Змінено на EndUser.FormType.
*/
  EndUser.FORM_TYPE_READ_PKEY = 1;
  EndUser.FORM_TYPE_MAKE_NEW_CERTIFICATE = 2;
  EndUser.FORM_TYPE_SIGN_FILE = 3;

  //= ===============================================================================

  /**
 * Типи форм для відображення в SignWidget (Для самодостатніх форм взаємодії
 * між веб-сайтом та iframe не передбачена):
 * - ReadPKey				- Зчитування ос. ключа. Форма призначена для
 * криптографічних операцій, які потребують ос. ключ користувача, наприклад
 * виконання накладання підпису, шифрування\розшифрування даних.
 * - MakeNewCertificate		- Формування нового сертифікату. Форма призначена для
 * формування нового сертифікату з використанням діючого ос. ключа користувача.
 * - SignFile				- Накладання підпису на файл. Форма призначена для
 * накладання підпису на файли та містить необхідні елементи з вибору файлів,
 * алгоритму та типу підпису. Самодостатня форма.
 * - ViewPKeyCertificates	- Відображення інформації про сертифікати ос. ключа.
 * Форма призначена для відображення інформації про сертифікати зчитаного
 * ос. ключа. Самодостатня форма.
*/
  EndUser.FormType = {
    ReadPKey:	1,
    MakeNewCertificate:	2,
    SignFile:	3,
    ViewPKeyCertificates:	4,
  };

  //--------------------------------------------------------------------------------

  /**
 * Типи алгоритмів підпису:
 * - DSTU4145WithGOST34311	- ДСТУ4145 з використанням алгоритму гешування ГОСТ34310
 * - RSAWithSHA				- RSA з використанням алгоритму гешування SHA256
 * - ECDSAWithSHA			- ECDSA з використанням алгоритму гешування SHA256
*/
  EndUser.SignAlgo = {
    DSTU4145WithGOST34311:	1,
    RSAWithSHA:	2,
    ECDSAWithSHA:	3,
  };

  //--------------------------------------------------------------------------------

  /**
 * Формат підпису:
 * - CAdES_BES		- базовий формат підпису. Включає позначку часу
 * від даних та сертифікат підписувача
 * - CAdES_T		- підис CAdES_BES, який додатково включає позначку
 * часу від ЕЦП
 * - CAdES_C		- підпис CAdES-T, який додатково включає посилання
 * на повний набір сертифікатів для перевірки підпису
 * - CAdES_X_Long	- підпис CAdES-C, який додатково включає повний набір
 * сертифікатів ЦСК для перевірки підпису, а також відповіді від OCSP сервера
 * зі статусом сертифіката підписувача
*/
  EndUser.SignType = {
    CAdES_BES:	1,
    CAdES_T:	4,
    CAdES_C:	8,
    CAdES_X_Long:	16,
  };

  //= ===============================================================================

  /**
 * Додаткові параметри форми відображення віджету:
 * - ownCAOnly		- зчитувати ос. ключі тільки свого ЦСК (першого в CAs.json).
 * Діалог вибору ЦСК не відображається
*/
  EndUser.FormParams = function () {
    this.ownCAOnly = false;
  };

  //= ===============================================================================

  /**
 * Зчитування ос. ключа користувача. Функція повинна викликатися до
 * функцій які використовують ос. ключ, наприклад SignHash, SignData.
 * @returns Promise<Array<object>> - Масив з інформацією про сертифікати ос. ключа
*/
  EndUser.prototype.ReadPrivateKey = function () {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('ReadPrivateKey', params);
  };

  //--------------------------------------------------------------------------------

  /**
 * Формування нового сертифікату для діючого ключа. Діючий ключ попередньо
 * повинен бути зчитаний функцією ReadPrivateKey, після чого необхідно викликати
 * функцію MakeNewCertificate для відображена форми обрання носія нового ключа
 * @param euParams <object> - Інформація про користувача, яку необхідно змінити
 * в новому сертифікаті. Доступні поля phone, EMail. Опціональний параметр
 * @returns Promise<void>
*/
  EndUser.prototype.MakeNewCertificate = function (euParams) {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('MakeNewCertificate', params);
  };

  //--------------------------------------------------------------------------------

  /**
 * Підпис геш значення
 * @param hash <Uint8Array | string> - геш значення для підпису у вигляді масиву
 * байт чи закодованій у вигляді строки BASE64.
 * @param asBase64String <boolean> - Признак необхідності повертати
 * підпис у вигляді строки BASE64. Опціональний параметр. За замовчанням - false.
 * @param signAlgo <number> - Алгоритм підпису. Можливі значення визначені в
 * EndUser.SignAlgo. За замовчанням - EndUser.SignAlgo.DSTU4145WithGOST34311.
 * @param signType <number> - Тип підпису. Можливі значення визначені в
 * EndUser.SignType. За замовчанням - EndUser.SignType.CAdES_BES.
 * @param previousSign <Uint8Array | string> - попередній підпис для геш значення,
 * до якого буде додано створений підпис. Додавання підпису можливе лише за умови
 * якщо алгоритми підписів (signAlgo) співпадають, та попередній підпис
 * не містить підписувача. Опціональний параметр. За замовчанням - null.
 * @returns Promise<Uint8Array | string> - Підпис
*/
  EndUser.prototype.SignHash = function (hash, asBase64String, signAlgo, signType, previousSign) {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('SignHash', params);
  };

  //--------------------------------------------------------------------------------

  /**
 * Підпис даних
 * @param data <Uint8Array | string> - дані для підпису. Дані, що передаються
 * у вигляді string автоматично конвертуються до типу Uint8Array з використанням
 * кодування UTF-8
 * @param external <boolean> - Признак необхідності формування зовнішнього
 * підпису (дані та підпис зберігаються окремо). Опціональний параметр.
 * За замовчанням - true.
 * @param asBase64String <boolean> - Признак необхідності повертати
 * підпис у вигляді строки BASE64. Опціональний параметр. За замовчанням - false.
 * @param signAlgo <number> - Алгоритм підпису. Можливі значення визначені в
 * EndUser.SignAlgo. За замовчанням - EndUser.SignAlgo.DSTU4145WithGOST34311.
 * @param previousSign <Uint8Array | string> - попередній підпис для даних,
 * до якого буде додано створений підпис. Додавання підпису можливе лише за умови
 * якщо алгоритми підписів (signAlgo) співпадають, та попередній підпис
 * не містить підписувача. Опціональний параметр. За замовчанням - null.
 * Для внутрішнього підпису (external = false) параметр data не використовується.
 * @param signType <number> - Тип підпису. Можливі значення визначені в
 * EndUser.SignType. За замовчанням - EndUser.SignType.CAdES_BES.
 * @returns Promise<Uint8Array | string> - Підпис
*/
  EndUser.prototype.SignData = function (data, external, asBase64String, signAlgo, previousSign, signType) {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('SignData', params);
  };

  //--------------------------------------------------------------------------------

  /**
 * Зашифрування даних з використанням алгоритму ГОСТ 28147-2009 та
 * протоколу розподілу ключів ДСТУ 4145-2002. Зчитаний ос. ключ повинен мати
 * сертифікат, який призначений для протоколів розподілу ключів в державних
 * алгоритмах та протоколах.
 * @param recipientsCerts <Array<Uint8Array>> - сертифікати отримувачів.
 * Сертифікати отримувачів повинні мати призначеня для протоколів розподілу
 * ключів в державних алогритмах та протоколах.
 * @param data <Uint8Array | string> - дані для зашифрування. Дані, що
 * передаються у вигляді string автоматично конвертуються до типу Uint8Array
 * з використанням кодування UTF-8
 * @param signData <boolean> - Признак необхідності додатково підписувати дані
 * (зашифровані дані спеціального формату з підписом, який автоматично
 * перевіряється при розшифруванні даних). Зчитаний ос. ключ повинен мати
 * сертифікат, який призначений для підпису даних за алгоритмом ДСТУ-4145.
 * Опціональний параметр. За замовчанням - false.
 * @param asBase64String <boolean> - Признак необхідності повертати
 * зашифровані дані у вигляді строки BASE64. Опціональний параметр.
 * За замовчанням - false.
 * @param useDynamicKey <boolean> - Признак необхідності зашифровувати дані з
 * використанням динамічного ключа відправника. Призначений для використання у
 * разі відсутності сертифікат відправника, який призначений для протоколів
 * розподілу ключів в державних алгоритмах та протоколах. Опціональний параметр.
 * За замовчанням - false.
 * @returns Promise<Uint8Array | string> - Зашифровані дані
*/
  EndUser.prototype.EnvelopData = function (recipientsCerts, data, signData, asBase64String, useDynamicKey) {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('EnvelopData', params);
  };

  //--------------------------------------------------------------------------------

  /**
 * Розшифрування даних з використанням алгоритму ГОСТ 28147-2009 та
 * протоколу розподілу ключів ДСТУ 4145-2002. Зчитаний ос. ключ повинен мати
 * сертифікат, який призначений для протоколів розподілу ключів в державних
 * алгоритмах та протоколах.
 * @param envelopedData <Uint8Array | string> - дані для розшифрування. Дані, що
 * передаються у вигляді string повинні бути закодовані з використанням кодування
 * BASE64
 * @param senderCert <Uint8Array> - Сертифікат відправника зашифрованих даних.
 * Опціональний параметр. За замовчанням - null.
 * @returns Promise<any> - Інформація про відправника та розшифровані дані
*/
  EndUser.prototype.DevelopData = function (envelopedData, senderCert) {
    const params = Array.prototype.slice.call(arguments);
    return this._postMessage('DevelopData', params);
  };

  //= ===============================================================================

  console.log('EndUser', EndUser);

  return EndUser;
};

export default init;

//= ===============================================================================
