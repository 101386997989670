import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const useTables = () => {
  const [tables, setTables] = useState([{ name: 'main', text: '' }]);
  const [showModal, setShowModal] = useState(false);
  const [key, setKey] = useState(null);

  const [activeTable, setActiveTable] = useState('main');

  const onAddTable = useCallback(
    (name) => setTables((oldTables) => {
      if (oldTables.filter((ot) => ot.name === name).length) return oldTables;
      setActiveTable(name);
      return [...oldTables, { name, text: { sql: '' } }];
    }),
    [],
  );

  const onRenameTable = useCallback(
    (oldName, newName) => {
      if (!newName) return false; // Имя таблицы must have
      setTables((oldTables) => {
        if (oldTables
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldTables;
        return oldTables.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeTable === oldName) setActiveTable(newName);
      return true;
    },
    [activeTable],
  );

  const onRemoveTable = useCallback(
    (tableName) => {
      setTables((oldTables) => {
        if (tableName === 'main') return oldTables;
        return oldTables.filter((ot) => ot.name !== tableName);
      });
      if (activeTable === tableName) setActiveTable('main');
    },
    [activeTable],
  );

  const onChangeTable = useCallback(
    (tableName, v, keyValue) => {
      setTables((oldTables) => oldTables
        .map((ot) => (ot.name !== tableName
          ? ot : { ...ot, text: { ...ot.text, [keyValue]: v } })));
    },
    [],
  );

  const tableHandlers = useMemo(() => ({
    onAddTable,
    onRenameTable,
    onRemoveTable,
    onChangeTable,
    setActiveTable,
    showModal,
    setShowModal,
    key,
    setKey,
  }), [key, onAddTable, onChangeTable, onRemoveTable, onRenameTable, showModal]);

  return {
    tables, setTables, activeTable, tableHandlers,
  };
};

export const tablePropType = PropTypes.shape({
  name: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
});
