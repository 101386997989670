const frontendURL = '/doc/liquidityVer2/';
const backendURL = '/api/authorityplans/liquidityheaderv2/';
const name = 'Інформація щодо ліквідності';
const printURL = '/api/authorityplans/printform/liquidityheaderv2/liquidity/';

const instance = {
  frontendURL,
  backendURL,
  name,
  printURL,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};

export default instance;
