import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LogicaContext from '../../minfin/providers/LogicaContext/context';
import { logikaLogin, logikaLogout } from '../../actions/auth';

function selector(store) {
  return {
    ticket: store.getIn(['auth', 'logikaTicket'], ''),
  };
}
function MinfinLogicaDriver({ children }) {
  const { ticket } = useSelector(selector);
  const dispatch = useDispatch();
  const [logicaData, setLogicaData] = useState(null);

  const onLogicaLogin = useCallback(
    (data) => {
      setLogicaData(data);
      dispatch(logikaLogin(data.ticket));
    },
    [dispatch],
  );
  const onLogicaLogout = useCallback(
    () => {
      setLogicaData(null);
      dispatch(logikaLogout());
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({
      data: logicaData,
      onLogin: onLogicaLogin,
      ticket,
      useWidgetDiya: false,
      setUseDigetDiya: () => null,
      onLogout: onLogicaLogout,
      widgetURI: 'https://certs.ciat.net.ua/',

    }),
    [logicaData, onLogicaLogin, onLogicaLogout, ticket],
  );

  return (
    <LogicaContext.Provider value={value}>
      {children}
    </LogicaContext.Provider>
  );
}

MinfinLogicaDriver.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default MinfinLogicaDriver;
