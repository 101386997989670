/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CoreDetailChart from './CoreDetailChart';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';

const numberFormat = new Intl.NumberFormat('uk');

function LeadItem({ item }) {
  return item && (
  <>
    <Col xl={9} lg={9} sm={9} xxl={9} className="d-flex align-items-center p-0 m-0">
      <h6 className="text-700 mb-0">{item.name}</h6>
    </Col>
    <Col xl={3} lg={3} sm={3} xxl={3} className="d-flex justify-content-end flex-column align-items-end">
      <p className="fs--1 text-500 mb-0 fw-semi-bold">
        {item.amount.toLocaleString('uk', moneysOneDigitStingOptions)}
      </p>
      <h6 className="text-700_ mb-0 fs--2 align-baseline text-nowrap text-info">
        {`${numberFormat.format(item.percent)}%`}
      </h6>
    </Col>
  </>
  );
}

function LeadItemTwoColumn({ data, dataLen }) {
  const twoColumnTable = [];
  for (let i = 0; i < dataLen; i += 2) {
    twoColumnTable.push(
      <Row className={classNames('p-0 m-0 py-1 border-bottom', {
        'border-bottom-0': ((i === dataLen - 1) || (i === dataLen - 2)),
      })}
      >
        <Col className="p-0 m-0 d-flex justify-content-between">
          <Col xl={8} lg={8} xxl={8} className="d-flex align-items-center p-0 m-0">
            <h6 className="text-700 mb-0">{data[i].name}</h6>
          </Col>
          <Col xl={4} lg={4} xxl={4} className="d-flex justify-content-end flex-column align-items-end">
            <p className="fs--1 text-500 mb-0 fw-semi-bold">
              {data[i].amount.toLocaleString('uk', moneysOneDigitStingOptions)}
            </p>
            <h6 className="text-700_ mb-0 fs--2 align-baseline text-nowrap text-info">
              {`${numberFormat.format(data[i].percent)}%`}
            </h6>
          </Col>
        </Col>
        {(data[i + 1]) && (
        <Col className="pl-1 m-0 d-flex justify-content-between">
          <Col xl={8} lg={8} xxl={8} className="d-flex align-items-center p-0 m-0">
            <h6 className="text-700 mb-0">{data[i + 1].name}</h6>
          </Col>
          <Col xl={4} lg={4} xxl={4} className="d-flex justify-content-end flex-column align-items-end">
            <p className="fs--1 text-500 mb-0 fw-semi-bold">
              {data[i + 1].amount.toLocaleString('uk', moneysOneDigitStingOptions)}
            </p>
            <h6 className="text-700_ mb-0 fs--2 align-baseline text-nowrap text-info">
              {`${numberFormat.format(data[i + 1].percent)}%`}
            </h6>
          </Col>
        </Col>
        )}

      </Row>,

    );
  }
  return (
    <>
      <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
      {twoColumnTable}
    </>
  );
}

LeadItemTwoColumn.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dataLen: PropTypes.number.isRequired,

};

function LeadItemOneColumn({ data, dataLen }) {
  return (
    <>
      <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
      {data && data.map((item, index) => (
        <Row className={classNames('border-bottom p-1', {
          'border-bottom-0 pb-0': index === dataLen - 1,
        })}
        >
          <LeadItem
            key={index}
            item={item}
          />
        </Row>
      ))}
    </>

  );
}

LeadItemOneColumn.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dataLen: PropTypes.number.isRequired,
};

function CoreDetail({ title, data }) {
  const dataLen = data.values.length;

  return (
    <Card className="h-100">
      <CardHeader
        title={title}
        titleTag="h5"
        className="border-200 border-bottom py-2"
      />
      <Card.Body>
        <div className="d-flex flex-column flex-center flex-fill">
          <Col xl={12} xxl={5} lg={12} md={12} className="mb-xxl-1">
            <CoreDetailChart
              data={data}
            />
          </Col>
          <Col xl={12} xxl={12} lg={12} md={12}>
            {(dataLen <= 10) && (
            <LeadItemOneColumn
              data={data.values}
              dataLen={dataLen}
            />
            )}
            {(dataLen > 10) && (
            <LeadItemTwoColumn
              data={data.values}
              dataLen={dataLen}
            />
            )}
          </Col>
        </div>
      </Card.Body>
      {/* <FalconCardFooterLink title="Primary" size="sm" to="#!" /> */}
    </Card>
  );
}

LeadItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
};

CoreDetail.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
      }),
    ),
  }),
};

CoreDetail.defaultProps = {
  title: '',
  data: {
    total: 0,
    values: [],
  },
};
export default CoreDetail;
