import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Map } from 'immutable';
import {
  RemoveIcon,
  XLSIcon,
  JPGIcon,
  TIFFIcon,
  PDFColorIcon,
  DOCXColorIcon,
  XLSColorIcon,
  FileColorIcon,
  YesIcon,
  Dbf,
} from '../../assets/icons';
import Item from '../../components/item';

const FileLink = Styled.a.attrs((props) => ({
  href: props.URL,
  download: props.filename,
}))`
  font-size: 0.9em;
  :hover{
    text-decoration: underline;
  };
`;

const ContainerRemove = Styled.div`
  justify-self: end;
  align-self: end;
  line-height: 0;
  cursor: pointer;
    *{stroke: red;
    }
`;

const ContainerClear = Styled.div`
  justify-self: end;
  align-self: end;
  line-height: 0;
  cursor: pointer;
    *{stroke: red;
    }
`;

const ContainerDelIcon = Styled.div`
  display: grid;
  grid-template-columns: 88% 7%;
  grid-gap: 5%;
`;

const Div = Styled.div`
 display: flex;
 flex-direction: row-reverse;
 color: #0014ff;
`;

const DeleteButton = Styled(ContainerClear)`
font-weight: 900`;

class File extends PureComponent {
  static propTypes = {
    file: PropTypes.instanceOf(Map).isRequired,
    iRef: PropTypes.shape({
      current: PropTypes.any,
    }).isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    isLocked: PropTypes.bool,
    positive: PropTypes.bool,
    removeForList: PropTypes.func,
    indx: PropTypes.number.isRequired,
  };

  static defaultProps = {
    isLocked: false,
    positive: false,
    removeForList: () => null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.HASH !== props.file.hashCode()) {
      const byteCharacters = atob(props.file.get('content', ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: props.file.get('type') });
      const URL = window.URL.createObjectURL(blob);
      return {
        HASH: props.file.hashCode(),
        URL,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      HASH: null,
      URL: null,
    };
  }

  componentWillUnmount() {
    const { URL } = this.state;
    if (URL) {
      window.URL.revokeObjectURL(URL);
    }
  }

  getIconFromMimeType = (MIMEType) => {
    switch (MIMEType) {
      case 'application/pdf':
        return (<img src={PDFColorIcon} alt="PDF" width="33px" />);
      case 'image/jpeg':
        return (<JPGIcon />);
      case 'image/tiff':
        return (<TIFFIcon />);
      case 'application/vnd.ms-excel':
        return (<img src={XLSColorIcon} alt="XLS" width="33px" />);
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (<XLSIcon />);
      case 'application/x-dbf':
        return (<img src={Dbf} alt="FileIcon" width="33px" />);
      case 'DBF':
        return (<img src={Dbf} alt="FileIcon" width="33px" />);
      case 'application/octet-stream':
        return (<img src={FileColorIcon} alt="FileIcon" width="33px" />);
      case 'application/msword':
        return (<img src={DOCXColorIcon} alt="word" width="33px" />);
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      default:
        return (<img src={FileColorIcon} alt="FileIcon" width="33px" />);
    }
  };

  render() {
    const {
      file, iRef, isLocked, onDeleteFile, positive, removeForList, indx,
    } = this.props;
    const { URL } = this.state;

    const extendFile = (fileName) => {
      const lenFileName = fileName.length;
      const extend = fileName.substring(lenFileName - 3);
      return extend.toUpperCase();
    };

    const getDescription = (description, fileName) => {
      if (description !== 'Без опису') return description;

      switch (fileName.substring(0, 2).toLowerCase()) {
        case 'ft':
          return 'Щоденна казнач.виписка за доходами';
        case 'vd':
          return 'Щоденна казнач.виписка за видатками';
        case 'fr':
          return 'Помісячні розписи за доходами, видатками, джерелами фінансування';
        case 'fz':
          return 'Уточнення до помісячного розпису за доходами, видатками, джерелами фінансування';
        default:
          return description;
      }
    };

    return (
      <Div>
        <DeleteButton
          onClick={() => removeForList(indx)}
          title="Вилучити"
        >
          x
        </DeleteButton>
        <Item
          header={(
            <FileLink URL={URL} filename={file.get('fileName', '')} ref={iRef}>
              {positive && (<img src={YesIcon} alt="file" />)}
              {file.get('fileName', '')}
            </FileLink>
          )}
          icon={this.getIconFromMimeType(file.get('type', '') ? '' : extendFile(file.get('fileName', '')))}
          content={(
            <ContainerDelIcon>
              <span>{getDescription(file.get('description', 'Без опису'), file.get('fileName', ''))}</span>

              {!isLocked && (
                <ContainerRemove title="Видалити">
                  <RemoveIcon
                    onClick={onDeleteFile}
                  />
                </ContainerRemove>
              )}
            </ContainerDelIcon>
          )}
        />
      </Div>
    );
  }
}

export default File;
