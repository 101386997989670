import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Button,
  Card, Col, Collapse, Container, Row,
} from 'react-bootstrap';
import { referencePropType } from '../../newEditor/propTypes';
import enums from '../../../constants/meta/enums';
import { useEditor } from '../../newEditor';
import { modelName, modelType } from './def';
import md from '../../../constants/meta/dataprocessors/requestsLoading';
import { soSelector } from '../../documents/_common/selectors';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import Documents from './tablepart';
import DCSFilter from '../../DCS/components/filter/filter';
import { useDCS } from '../../DCS/hooks';

const planingKindsOptions = Object.values(enums.PlaningKind)
  .map(({ name, label }) => ({ value: name, display_name: label }));

function RequestsLoadingBoostraped({
  onLoad, CSU, date, fondObject, FI, budget, importAllNotes, FIAccount, dataSource, importNotes, planingMode, implementer, Autor,
}) {
  const [showFilters, setShowFilters] = useState(false);

  const {
    data,
    loading,
    err,
    actions: { onSR, onChange, onErr },
  } = useEditor({
    modelType,
    modelName,
  });
  const tableDocs = useMemo(() => data[md.tables.docsTable.name] || [], [data]);
  const tableDocumentNotes = useMemo(() => data[md.tables.documentNotes.name] || [], [data]);
  useEffect(
    () => {
      onSR('INIT', {
        // is this ok?
        [md.columns.date.name]: date,
        [md.columns.DataSource.name]: dataSource,
        [md.columns.budget.name]: budget,
        [md.columns.ImportAllNotes.name]: false,
        [md.columns.FIAccount.name]: FIAccount,
        [md.columns.ImportFullNotes.name]: importNotes,
        [md.columns.CSU.name]: CSU,
        [md.columns.FI.name]: FI,
        [md.columns.fondObject.name]: fondObject,
        // is this ok?
        [md.columns.Transfer.name]: FI,
        [md.columns.planingMode.name]: planingMode,
        [md.columns.implementer.name]: implementer,
        [md.columns.Autor.name]: Autor,
      });
    },
    [CSU, FI, FIAccount, budget, dataSource, date, fondObject, implementer, importAllNotes, importNotes, onSR, planingMode, Autor],
  );
  const so = useSelector(soSelector);
  const isAdmin = so.get('is_admin', false);
  const dataFi = data[md.columns.FI.name];
  const elementFondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: dataFi }],
    [dataFi],
  );

  const onOk = useCallback(
    () => {
      const checkedDocs = tableDocs
        .filter((d) => d[md.tables.docsTable.columns.Check.name])
        .reduce(
          (v, row) => [...v, row[md.tables.docsTable.columns.Document.name].id],
          [],
        );
      return onLoad({
        data: tableDocumentNotes
          .filter((d) => checkedDocs.includes(d[md.tables.documentNotes.columns.Document.name].id))
          .map((s) => Object.keys(md.tables.documentNotes.columns)
            .reduce((a, k) => ({ ...a, [k]: s[md.tables.documentNotes.columns[k].name] }), {})),
      });
    },
    [onLoad, tableDocs, tableDocumentNotes],
  );

  const onChangeDCS = useCallback(
    (f) => onChange((d) => ({
      ...d,
      [md.columns.DCSettings.name]: f(d[md.columns.DCSettings.name]),
    })),
    [onChange],
  );
  const reqParams = useMemo(
    () => ({ FI, DataSource: dataSource }),
    [FI, dataSource],
  );

  const {
    filters, filterAvailables,
  } = useDCS({
    data: data[md.columns.DCSettings.name],
    onChange: onChangeDCS,
    reqParams,
    backendURL: `${modelType}/${md.backendName}/`,
    onError: onErr,
  });
  const filterCnts = filters.items.filter((f) => f.item.Use).length;

  return (
    <EditorWrapper loading={loading} onErrorDismis={() => onErr(null)} err={err}>
      <Card.Header>
        <Button variant="success" onClick={() => setShowFilters(!showFilters)}>
          <FontAwesomeIcon icon={faFilter} className="me-2" />
          {!showFilters ? 'Показати фільтрування' : 'Сховати фільтрування'}
          {filterCnts ? (
            <Badge className="ms-2" variant="warning" pill>{filterCnts}</Badge>
          ) : null}

        </Button>
      </Card.Header>
      <Card.Body>
        <Collapse in={!showFilters}>
          <Container fluid>
            {isAdmin && (
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    label={md.columns.FI.label}
                    required
                    modelType="cat"
                    modelName="csu"
                    value={data[md.columns.FI.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.FI.name]: value,
                    })}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <EditorControls.PeriodInput
                  label={md.columns.period.label}
                  required
                  value={data[md.columns.period.name]}
                  onChange={(e, v) => onChange({ [md.columns.period.name]: v })}
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.budget.label}
                  modelType="cat"
                  modelName="budget"
                  value={data[md.columns.budget.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.budget.name]: value,
                  })}
                  noHierarchy
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.fondObject.label}
                  modelType="cat"
                  modelName="elementFond"
                  value={data[md.columns.fondObject.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.fondObject.name]: value,
                  })}
                  filter={elementFondFilter}
                />
              </Col>
            </Row>

            {data[md.columns.isFinPropose.name] && (
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    label={md.columns.FIAccount.label}
                    value={data[md.columns.FIAccount.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.FIAccount.name]: value,
                    })}
                    modelType="cat"
                    modelName="bankAccounts"
                    values={planingKindsOptions}
                  />
                </Col>
                <Col>
                  <EditorControls.SelectorInput
                    label={md.columns.planingMode.label}
                    required
                    value={data[md.columns.planingMode.name]}
                    values={planingKindsOptions}
                    onChange={(e, v) => onChange({ [md.columns.planingMode]: v })}
                  />
                </Col>
                <Col>
                  <EditorControls.ItemPicker
                    label={md.columns.implementer.label}
                    value={data[md.columns.implementer.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.implementer.name]: value,
                    })}
                    modelType="cat"
                    modelName="implementer"
                    noHierarchy
                  />
                </Col>
                <Col>
                  <EditorControls.ItemPicker
                    label={md.columns.Autor.label}
                    value={data[md.columns.Autor.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.Autor.name]: value,
                    })}
                    modelType="cat"
                    modelName="users"
                    noHierarchy
                    ReadOnly
                  />
                </Col>
              </Row>
            )}
            <h6>Параметри відбору заявок</h6>
            <Row>
              <Col>
                {data[md.columns.isFinancing.name] ? (
                  <EditorControls.CheckboxInput
                    controlId="LoadLoaded"
                    label={md.columns.LoadLoaded.label}
                    value={data[md.columns.LoadLoaded.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.LoadLoaded.name]: value,
                    })}
                  />
                ) : (
                  <EditorControls.CheckboxInput
                    controlId={`LoadFinanced-${data.id}`}
                    label={md.columns.LoadFinanced.label}
                    value={data[md.columns.LoadFinanced.name]}
                    onChange={(e, value) => onChange({
                      [md.columns.LoadFinanced.name]: value,
                    })}
                  />
                )}
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`WithoutNotes-${data.id}`}
                  label={md.columns.WithoutNotes.label}
                  value={data[md.columns.WithoutNotes.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.WithoutNotes.name]: value,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col />
            </Row>
          </Container>
        </Collapse>
        <Collapse in={showFilters}>
          <div className="my-2 ">
            <DCSFilter
              filters={filters}
              availables={filterAvailables}
              // // filterChoice={} TODO: implement
              // availableFields={filterAvailables.items}
              // activeAvailable={filterAvailables.activeItem}
              // onActivateAvailable={filterAvailables.onActivate}
            />
          </div>
        </Collapse>
      </Card.Body>
      <Collapse in={!showFilters}>
        <Card.Body className="pt-0">
          <Documents
            onChange={onChange}
            data={tableDocs}
            content={tableDocumentNotes}
            onSR={onSR}
            onOk={onOk}
          />
        </Card.Body>
      </Collapse>
      {!isAdmin && (
        <Card.Footer>
          <FontAwesomeIcon icon={faCity} className="me-2" />
          {data[md.columns.FI.name] && data[md.columns.FI.name].repr}
        </Card.Footer>
      )}
    </EditorWrapper>
  );
}

RequestsLoadingBoostraped.propTypes = {
  onLoad: PropTypes.func.isRequired,
  date: PropTypes.number,
  fondObject: referencePropType,
  FI: referencePropType,
  budget: referencePropType,
  importAllNotes: PropTypes.bool,
  importNotes: PropTypes.bool,
  CSU: referencePropType,
  FIAccount: referencePropType,
  implementer: referencePropType,
  planingMode: referencePropType,
  dataSource: PropTypes.oneOf([
    'Документ.Финансирование', 'Документ.ФинансированиеЗаявка', 'Документ.ФинансированиеПредложение',
  ]),
};

RequestsLoadingBoostraped.defaultProps = {
  CSU: null,
  FIAccount: null,
  fondObject: null,
  FI: null,
  budget: null,
  date: null,
  importAllNotes: false,
  importNotes: false,
  implementer: null,
  planingMode: null,
  dataSource: 'Документ.Финансирование',
};

export default RequestsLoadingBoostraped;
