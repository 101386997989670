import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import actions from '../../../actions/favorites';
import {
  BoxLink,
  BoxMenuHeader,
  BoxMenuLineHr,
  BoxMenuPlaceholder,
  ElementsListContainer,
} from '../instancesForBoxMenu/index';
import session from '../../../api/session';
import { MyContainerFormWithNColumns } from '../../Form/styledForm';
import {
  ArrowRightIcon,
  FolderOpenBlueIcon,
  FolderCloseBlueIcon,
  StarAddFavorites,
  StarFavorites,
} from '../../../assets/icons';
import { fLSelector, soSelector } from '../../../containers/documents/_common/selectors';

const ButtonName = styled.span`
    font-size: 0.9rem;
    color: #386EAB;
    margin-right: 3px;
`;

const BoxContent = styled.div`
  min-height: 100px;
  max-height: ${(props) => (props.extended || props.showNav) && 'fit-content'}
`;

const GroupContainer = styled.div`
`;

const activeHeader = `
  border: 1px solid lightblue;
  border-radius: 4px 4px 0 0;
  background: #ecf2f9;
  border-bottom: none;
  font-weight: 600;
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 3px 5px;
  width: fit-content;
  ${({ active }) => (active
    ? 'color: #4183c4;' : 'color: #4262a1')};
  ${({ active }) => active
  && `${activeHeader}`
};
`;

const SubMenu = styled(MyContainerFormWithNColumns)`
  grid-row-gap: 0;
  align-items: start;
  padding: 5px 10px;
  margin: 0;
  background: #4281c91a;
  border: 1px solid lightblue;
  border-radius: 0 4px 4px;
  ${({ active }) => !active
  && 'display: none;'};
`;

const ArrowIcon = styled(ArrowRightIcon)`
  ${({ active }) => active
  && 'transform: rotate(90deg);'};
`;
const FolderIm = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const fadeIn = keyframes` 
  100% {
    transform: rotateY(360deg);
`;

export const Img = styled.img`
  margin: 5px 5px 5px 0;
  width: 13px;
  cursor: pointer;
    :hover {
      animation: ${fadeIn} ease infinite 2.6s;
    }
`;

function Box({
  items, label, group, mainItems,
}) {
  const dispatch = useDispatch();
  const [extended, setExtended] = useState(false);
  const [activeMainItem, setActiveMainItem] = useState('');
  const [showNav, setShowNav] = useState(true);

  const sessionOptions = useSelector(soSelector);
  const favList = useSelector(fLSelector);

  const isChiefBuilding = session.get('is_chief_building');

  const showMore = useCallback(() => setExtended((prev) => !prev), []);
  const a = actions;
  const activeItem = false;

  return (
    <BoxMenuPlaceholder
      isChiefBuilding={isChiefBuilding}
      showNav={group && showNav}
    >
      <BoxContent extended={extended} showNav={group && showNav}>
        <BoxMenuHeader>{label}</BoxMenuHeader>
        <BoxMenuLineHr />
        {group
          ? (
            <GroupContainer>
              {mainItems
                .map((mainItem) => (
                  <div key={mainItem.name} style={{ margin: '5px 0' }}>
                    <HeaderGroup
                      active={mainItem.name === activeMainItem}
                      onClick={() => {
                        if (mainItem.name === activeMainItem && showNav) {
                          setShowNav(false);
                          setActiveMainItem((prev) => !prev);
                        } else {
                          setShowNav(true);
                          setActiveMainItem(mainItem.name);
                        }
                      }}
                    >
                      {mainItem.name === activeMainItem
                        ? <FolderIm src={FolderOpenBlueIcon} alt="FolderOpenBlueIcon" />
                        : <FolderIm src={FolderCloseBlueIcon} alt="FolderCloseBlueIcon" />}
                      {mainItem.label || 'сторінка відсутня'}
                      <ArrowIcon
                        active={mainItem.name === activeMainItem}
                      />
                    </HeaderGroup>
                    <SubMenu
                      columns={mainItem.num}
                      active={mainItem.name === activeMainItem}
                    >
                      {items
                        .filter((item) => (!item.showTrigger
                          || item.showTrigger(sessionOptions))
                          && (item.parent === mainItem.name === !activeItem))
                        .map((item) => (
                          <div
                            key={item.def.name}
                            style={{ display: 'flex' }}
                          >
                            <div
                              aria-hidden="true"
                              onClick={() => dispatch(a.addToFavorites(`${item.def.modelType}/${item.def.modelName}`))}
                            >
                              {(favList.indexOf(`${item.def.modelType}/${item.def.modelName}`) === -1)
                                ? <Img src={StarAddFavorites} alt="StarAddFavorites" />
                                : <Img src={StarFavorites} alt="StarFavorites" />}
                            </div>
                            <BoxLink
                              line={item.line}
                              isChiefBuilding={isChiefBuilding}
                              key={item.def.name}
                              to={item.def.frontend}
                            >
                              {item.def.label || 'сторінка відсутня'}
                            </BoxLink>
                          </div>
                        ))}
                    </SubMenu>
                  </div>
                ))}
            </GroupContainer>
          )
          : (
            <ElementsListContainer extended={extended}>
              {items.filter((item) => (!item.showTrigger
                  || item.showTrigger(sessionOptions)) && (item.primary))
                .map((item) => (
                  <div
                    key={item.def.name}
                    style={{ display: 'flex' }}
                  >
                    <div
                      aria-hidden="true"
                      onClick={() => dispatch(a.addToFavorites(`${item.def.modelType}/${item.def.modelName}`))}
                    >
                      {(favList.indexOf(`${item.def.modelType}/${item.def.modelName}`) === -1)
                        ? <Img src={StarAddFavorites} alt="StarAddFavorites" />
                        : <Img src={StarFavorites} alt="StarFavorites" />}
                    </div>
                    <BoxLink
                      line={item.line}
                      isChiefBuilding={isChiefBuilding}
                      key={item.def.name}
                      to={item.def.frontend}
                    >
                      {item.def.label || 'сторінка відсутня'}
                    </BoxLink>
                  </div>
                ))}
            </ElementsListContainer>
          )}
      </BoxContent>
      {!group
            && (
              <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header variant="link" isChiefBuilding={isChiefBuilding} onClick={showMore}>
                    <ButtonName>
                      {extended ? 'Меньше' : 'Бiльше'}
                    </ButtonName>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ElementsListContainer extended={extended}>
                      {items
                        .filter((item) => (!item.showTrigger || item.showTrigger(sessionOptions))
                              && !item.primary).map(
                          (item) => (
                            <div key={item.def.name} style={{ display: 'flex' }}>
                              <div
                                aria-hidden="true"
                                onClick={() => dispatch(a.addToFavorites(`${item.def.modelType}/${item.def.modelName}`))}
                              >
                                {favList.indexOf(`${item.def.modelType}/${item.def.modelName}`) === -1 ? (
                                  <Img src={StarAddFavorites} alt="StarAddFavorites" />
                                ) : (
                                  <Img src={StarFavorites} alt="StarFavorites" />
                                )}
                              </div>
                              <BoxLink
                                line={item.line}
                                isChiefBuilding={isChiefBuilding}
                                key={item.def.name}
                                to={item.def.frontend}
                              >
                                {item.def.label || 'сторінка відсутня'}
                              </BoxLink>
                            </div>
                          ),
                        )}
                    </ElementsListContainer>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
    </BoxMenuPlaceholder>
  );
}

Box.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.bool,
  mainItems: PropTypes.arrayOf(PropTypes.object),
};

Box.defaultProps = {
  group: false,
  mainItems: [],
};

export default Box;
