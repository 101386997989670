import React from 'react';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import md from '../../../../../constants/meta';
import { DivHeader } from '../../../../../components/styledTable/styledTable';

const coldef = md.dp.exportVDBFKievDod21.tables.tvFonds.columns;

function HeaderComponent({ activeAreaName }) {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="selected">{coldef.selected.label}</DivHeader>
      <DivHeader
        areaName="fond"
        isActive={(activeAreaName === 'fond')}
      >
        {coldef.fond.label}
      </DivHeader>
    </GridTemplate>
  );
}

HeaderComponent.propTypes = {
  activeAreaName: PropTypes.string,
};

HeaderComponent.defaultProps = {
  activeAreaName: '',
};

export default HeaderComponent;
