import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import meta from '../../constants/meta';
import { directions } from '../../constants/meta/common';
import TableRow from './table/tableRow';
import Table from './table/tablegrid';
import TableHeader from './table/tableHeader';

class TableComponent extends PureComponent {
  static propTypes = {
    modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
    modelName: PropTypes.oneOf([
      ...new Set(Object.keys(meta).reduce(
        (r, typeName) => [...r, ...Object.keys(meta[typeName])],
        [],
      )),
    ]).isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onOrderChange: PropTypes.func,
    onToggle: PropTypes.func,
    onScroll: PropTypes.func,
    items: PropTypes.instanceOf(OrderedMap),
    orderBy: PropTypes.shape({
      column: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(directions)),
    }),
    iRef: PropTypes.shape(),
    columnSizes: PropTypes.instanceOf(Map),
    onResizeColumn: PropTypes.func,
    onResetColumnSize: PropTypes.func,
    onActionClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: (e, key) => console.log('Clicked ', key, e),
    onDoubleClick: (e, key) => console.log('Doble Clicked ', key, e),
    onOrderChange: (e, column) => console.log('Order changed', column, e),
    onToggle: (e, key) => console.log('Toggled  ', key, e),
    onScroll: (e) => console.log('Scrolled', e),
    onActionClick: (e, key) => console.log('Context ', key, e),
    items: new OrderedMap(),
    orderBy: {
      column: '',
      direction: directions.ascending,
    },
    iRef: React.createRef(),
    columnSizes: new Map(),
    onResizeColumn: null,
    onResetColumnSize: null,
  };

  constructor(props) {
    super(props);
    this.def = meta[props.modelType][props.modelName];
  }

  render() {
    const {
      onClick, onDoubleClick, onToggle,
      items, orderBy, onOrderChange, onScroll, iRef, columnSizes,
      onResetColumnSize, onResizeColumn, modelName, modelType,
      onActionClick,
    } = this.props;
    const tableContent = items.keySeq().map((row, key) => (
      <TableRow
        key={row}
        even={!(key % 2)}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        row={items.get(row)}
        listColumns={columnSizes}
        columns={this.def.columns}
        onToggle={onToggle}
        onResizeColumn={onResizeColumn}
        onResetColumnSize={onResetColumnSize}
        modelName={modelName}
        modelType={modelType}
        onActionClick={onActionClick}
      />
    ));
    return (
      <Table
        columnSizes={columnSizes}
        onScroll={onScroll}
        iRef={iRef}
      >
        <TableHeader
          columns={columnSizes}
          onResizeColumn={onResizeColumn}
          onResetColumnSize={onResetColumnSize}
          orderBy={orderBy}
          onColumnClick={onOrderChange}
          columnsDef={this.def.columns}
        />
        {tableContent}
      </Table>
    );
  }
}

export default TableComponent;
