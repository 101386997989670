import React from 'react';

import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

import store from '../../store';
import LoginManager from './login_mgr';

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <LoginManager />
      </Router>
    </Provider>
  );
}

export default Root;
