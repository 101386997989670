import { Map, List } from 'immutable';
import actionsTypes from '../constants/actions/notifier';
import storePathParam from '../common/storePathParam';
import api from '../api/req';
import irMeta from '../constants/meta/infoRegs';

// ////////////
//   FILES   //
// ////////////

/**
 * Добавляет файл к подготовленным файлам для скачивания
 * @param fileName {string} имя файла
 * @param content {string} base64-содержание
 * @param type {string} MIME-тип
 * @param description {string} описание файла
 * @return {function(*)}
 */
export const newFile = (fileName, content, type, description) => (dispatch, getState) => (
  dispatch({
    type: actionsTypes.newFile,
    payload: {
      fileName,
      content,
      type,
      description,
      model: storePathParam(getState()).name,
    },
  }));

export const removeFile = (index) => (dispatch) => (
  dispatch({
    type: actionsTypes.removeFile,
    payload: index,
  })
);

export const clearFiles = () => (dispatch, getState) => (
  dispatch({
    type: actionsTypes.clearFiles,
    model: storePathParam(getState()).name,
  })
);

// ////////////
// MESSAGES //
// ////////////

const loadMessagesError = (errorMsg) => (dispatch) => {
  dispatch({
    type: actionsTypes.loadMessagesErr,
    payload: errorMsg,
  });
};

const loadMessagesDone = (data) => (dispatch) => {
  if (!data) {
    return dispatch({
      type: actionsTypes.loadMessagesEnd,
      payload: new List(),
    });
  }

  const colummns = irMeta.messages.columns;

  const itemMaper = (id, itemData) => Object.keys(colummns)
    .filter((cn) => colummns[cn].name in itemData)
    .reduce(
      (R, cn) => R.set(cn, itemData[colummns[cn].name]),
      new Map({ id }),
    );

  const items = Object.keys(data).reduce((R, id) => R.push(itemMaper(id, data[id])), new List());

  return dispatch({
    type: actionsTypes.loadMessagesEnd,
    payload: items,
  });
};

export const loadMessages = () => (dispatch) => {
  dispatch({
    type: actionsTypes.loadMessagesStart,
    payload: null,
  });
  api.post$('messages')
    .then((response) => {
      if (response.ok) {
        response.json().then((data) => (
          dispatch(loadMessagesDone(data))
        ));
      } else {
        dispatch(loadMessagesError(`${response.status} ${response.statusText}`));
      }
    })
    .catch((error) => {
      dispatch(loadMessagesError(error.message));
    });
};

export const temporaryHideMessage = (msgId) => (dispatch, getState) => {
  const messages = getState().getIn(['notifier', 'messages', 'messages'], new List());
  return dispatch({
    type: actionsTypes.updateMessages,
    payload: messages.map((msg) => (msg.get('id') === msgId ? msg.set('showOnDashboard', false) : msg)),
  });
};

export const setMessageAsRead = (msgId) => (dispatch) => {
  dispatch({
    type: actionsTypes.loadMessagesStart,
    payload: null,
  });
  api.delete$('messages', { id: msgId })
    .then((response) => {
      if (response.ok) {
        dispatch(loadMessages());
      } else {
        dispatch(loadMessagesError(`${response.status} ${response.statusText}`));
      }
    })
    .catch((error) => {
      dispatch(loadMessagesError(error.message));
    });
};

const notifierActions = {
  newFile,
  removeFile,
  clearFiles,
  loadMessages,
  temporaryHideMessage,
  setMessageAsRead,
};

export default notifierActions;
