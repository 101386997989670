import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/limitReference';
import LimitReference from './editor';
import LimitReferenceTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';

import APMenu from './menu';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function LimitReferenceEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
        onSR={actions.onSR}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
        <Card.Header>
          <LimitReference data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <LimitReferenceTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

LimitReferenceEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

LimitReferenceEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { LimitReferenceEditor as StdEditor };

export default getRouterEditor(md)(LimitReferenceEditor);
