import React from 'react';

function SaveIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.5 17.5H2.5V2.5H15L17.5 5V17.5Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 2.5H6.5V7.5H13.5V2.5Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 11.5H5.5V17.5H14.5V11.5Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 4.5V5.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 13.5H7.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 15.5H7.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 17.5H2.5V2.5H15L17.5 5V17.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 2.5H6.5V7.5H13.5V2.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 11.5H5.5V17.5H14.5V11.5Z" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 4.5V5.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 13.5H7.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 15.5H7.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SaveIcon;
