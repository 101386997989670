import { useEffect, useState } from 'react';
import useSettings from './backend/settings';

const useServices = (
  backendURL,
  viewType,
) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const {
    onLoadSettings,
    settings,
    onSetSettings,

  } = useSettings(backendURL, viewType, setLoading, setErr);

  useEffect(
    () => {
      onLoadSettings();
    },
    [onLoadSettings],
  );

  return {
    settings,
    onSetSettings,
    loading,
    err,
  };
};

export default useServices;
