import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnGoalFondAmount = {
  label: 'Довідник "Цільовий фонд вирівнювання"',
  name: 'bfnGoalFondAmount',
  backendName: 'bfnGoalFondAmount',
  defaultOrder,
  columns,
  frontend: 'cat/bfnGoalFondAmount',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnGoalFondAmount;
