import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../components/styledCheckbox/styledCheckbox';

function CheckboxField({
  value, onChange, ...restProps
}) {
  return <Checkbox value={value} onChange={onChange} {...restProps} />;
}
CheckboxField.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxField.defaultProps = {
  value: false,
  onChange: () => null,
};

export default CheckboxField;
