import {
  useState, useCallback, useContext, useEffect,
} from 'react';
import api from '../../../../api/req';
import checkTask from '../../../../api/checktask';
import { CiatAppContext } from '../../../../providers';

const useFetchRevenueData = ({ cDt }) => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [revenueData, setRevenueData] = useState({
    zf: { total: 0, values: [] },
    sf: { total: 0, values: [] },
  });
  const [dataByFond, setDataByFond] = useState({
    zf: { percent: 0, total: 0 },
    sf: { percent: 0, total: 0 },
    br: { percent: 0, total: 0 },
  });
  const [dataCompare, setDataCompare] = useState({
    11010000: {
      2024: { amount: 0 },
      2023: { amount: 0 },
      2022: { amount: 0 },
    },
  });
  const [compareFact, setCompareFact] = useState({
    1: { plan: 0, fact: 0, repr: '' },
    2: { plan: 0, fact: 0, repr: '' },
    3: { plan: 0, fact: 0, repr: '' },
    plan: 0,
    fact: 0,
    difference: 0,
  });
  const [compareRevenuePlanFact, setCompareRevenuePlanFact] = useState([
    {
      kdb: {
        code: '30000000',
        name: 'Доходи від операцій з капіталом',
      },
      amount_total_plan: 26400000.0,
      amount_total_fact: 0.0,
      amount_zf_plan: 0.0,
      amount_zf_fact: 0.0,
      amount_sf_plan: 26400000.0,
      amount_sf_fact: 0.0,
      nested: [
        {
          kdb: {
            code: '31000000',
            name: 'Надходження від продажу основного капіталу',
          },
          amount_total_plan: 3400000.0,
          amount_total_fact: 0.0,
          amount_zf_plan: 0.0,
          amount_zf_fact: 0.0,
          amount_sf_plan: 3400000.0,
          amount_sf_fact: 0.0,
          nested: [
            [
              {
                kdb: {
                  code: '31030000',
                  name: 'Кошти від відчуження майна, що належить Автономній Республіці Крим та майна, що перебуває в комунальній власності',
                },
                amount_total_plan: 3400000.0,
                amount_total_fact: 0.0,
                amount_zf_plan: 0.0,
                amount_zf_fact: 0.0,
                amount_sf_plan: 3400000.0,
                amount_sf_fact: 0.0,
                nested: [],
              },
            ],
          ],
        },
        {
          kdb: {
            code: '33000000',
            name: 'Кошти від продажу землі і нематеріальних активів',
          },
          amount_total_plan: 23000000.0,
          amount_total_fact: 0.0,
          amount_zf_plan: 0.0,
          amount_zf_fact: 0.0,
          amount_sf_plan: 23000000.0,
          amount_sf_fact: 0.0,
          nested: [
            [
              {
                kdb: {
                  code: '33010000',
                  name: 'Кошти від продажу землі',
                },
                amount_total_plan: 23000000.0,
                amount_total_fact: 0.0,
                amount_zf_plan: 0.0,
                amount_zf_fact: 0.0,
                amount_sf_plan: 23000000.0,
                amount_sf_fact: 0.0,
                nested: [
                  [
                    {
                      kdb: {
                        code: '33010100',
                        name: 'Кошти від продажу земельних ділянок несільськогосподарського призначення, що перебувають у державній або комунальній власності, та земельних ділянок, які знаходяться на території Автономної Республіки Крим',
                      },
                      amount_total_plan: 23000000.0,
                      amount_total_fact: 0.0,
                      amount_zf_plan: 0.0,
                      amount_zf_fact: 0.0,
                      amount_sf_plan: 23000000.0,
                      amount_sf_fact: 0.0,
                      nested: [],
                    },
                  ],
                ],
              },
            ],
          ],
        },
      ],
    },
    {
      kdb: {
        code: '40000000',
        name: 'Офіційні трансферти',
      },
      amount_total_plan: 275904486.3,
      amount_total_fact: 20262300.0,
      amount_zf_plan: 264313355.0,
      amount_zf_fact: 20262300.0,
      amount_sf_plan: 11591131.3,
      amount_sf_fact: 0.0,
      nested: [
        {
          kdb: {
            code: '41000000',
            name: 'Від органів державного управління',
          },
          amount_total_plan: 252203400.0,
          amount_total_fact: 20262300.0,
          amount_zf_plan: 252203400.0,
          amount_zf_fact: 20262300.0,
          amount_sf_plan: 0.0,
          amount_sf_fact: 0.0,
          nested: [
            [
              {
                kdb: {
                  code: '41030000',
                  name: 'Субвенції  з державного бюджету місцевим бюджетам',
                },
                amount_total_plan: 252203400.0,
                amount_total_fact: 20262300.0,
                amount_zf_plan: 252203400.0,
                amount_zf_fact: 20262300.0,
                amount_sf_plan: 0.0,
                amount_sf_fact: 0.0,
                nested: [
                  [
                    {
                      kdb: {
                        code: '41033900',
                        name: 'Освітня субвенція з державного бюджету місцевим бюджетам',
                      },
                      amount_total_plan: 252203400.0,
                      amount_total_fact: 20262300.0,
                      amount_zf_plan: 252203400.0,
                      amount_zf_fact: 20262300.0,
                      amount_sf_plan: 0.0,
                      amount_sf_fact: 0.0,
                      nested: [],
                    },
                  ],
                ],
              },
            ],
          ],
        },
        {
          kdb: {
            code: '41040000',
            name: 'Дотації  з місцевих бюджетів іншим місцевим бюджетам',
          },
          amount_total_plan: 620739.0,
          amount_total_fact: 0.0,
          amount_zf_plan: 620739.0,
          amount_zf_fact: 0.0,
          amount_sf_plan: 0.0,
          amount_sf_fact: 0.0,
          nested: [
            [
              {
                kdb: {
                  code: '41040400',
                  name: 'Інші дотації з місцевого бюджету',
                },
                amount_total_plan: 620739.0,
                amount_total_fact: 0.0,
                amount_zf_plan: 620739.0,
                amount_zf_fact: 0.0,
                amount_sf_plan: 0.0,
                amount_sf_fact: 0.0,
                nested: [],
              },
            ],
          ],
        },
      ],
    },
    {
      kdb: {
        code: '41057700',
        name: 'автоматично створений',
      },
      amount_total_plan: 166320.0,
      amount_total_fact: 0.0,
      amount_zf_plan: 166320.0,
      amount_zf_fact: 0.0,
      amount_sf_plan: 0.0,
      amount_sf_fact: 0.0,
      nested: [],
    }]);
  const { auth } = useContext(CiatAppContext);

  const fetchAllData = useCallback(() => {
    const loadRevenueData = async () => {
      const r = await api.get(`/api/dashboards/plan_revenue/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadRevenueDataByFond = async () => {
      const r = await api.get(`/api/dashboards/plan_revenue_by_fond/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadRevenueCompare = async () => {
      const r = await api.get(`/api/dashboards/revenue_compare_years/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadRevenueCompareFact = async () => {
      const r = await api.get(`/api/dashboards/compare_plan_fact/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };
    const loadCompareRevenuePlanFact = async () => {
      const r = await api.get(`/api/dashboards/compare_revenue_plan_fact/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    setErr(null);
    setIsLoading(true);

    Promise.all([
      loadRevenueData(),
      loadRevenueDataByFond(),
      loadRevenueCompare(),
      loadRevenueCompareFact(),
      loadCompareRevenuePlanFact(),
    ])
      .then(([revenueDataResult,
        revenueDataByFondResult,
        revenueCompareResult,
        revenueCompareFactResult,
        compareRevenuePlanFactResult,
      ]) => {
        setRevenueData(revenueDataResult.result);
        setDataByFond(revenueDataByFondResult.result);
        setDataCompare(revenueCompareResult.result);
        setCompareFact(revenueCompareFactResult.result);
        setCompareRevenuePlanFact(compareRevenuePlanFactResult.result);
      })
      .catch((error) => setErr(error.message))
      .finally(() => setIsLoading(false));
  }, [auth, cDt]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return {
    isLoading, err, revenueData, dataByFond, dataCompare, compareFact, compareRevenuePlanFact,
  };
};

export default useFetchRevenueData;
