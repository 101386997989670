import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from '../../../../incomeProjectChanges/editor/tp/planningRows/propTypes';

function Header({
  activeCol, highlights, onToggleHighlight, totals, fields,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <Row noGutters>
          <Col lg={3} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7_item'}
              highlighted={highlights.includes('kbp7_item')}
              onToggleHighlight={() => onToggleHighlight('kbp7_item')}
            >
              {fields.kbp7_item.label}
            </TableHeaderCell>
          </Col>
          <Col lg={3} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'building_term'}
              highlighted={highlights.includes('building_term')}
              onToggleHighlight={() => onToggleHighlight('building_term')}
            >
              {fields.building_term.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'object_name'}
              highlighted={highlights.includes('object_name')}
              onToggleHighlight={() => onToggleHighlight('object_name')}
            >
              {fields.object_name.label}
            </TableHeaderCell>
          </Col>
          <Col lg={3} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'start_period_completed_percent'}
              highlighted={highlights.includes('start_period_completed_percent')}
              onToggleHighlight={() => onToggleHighlight('start_period_completed_percent')}
            >
              % виконання на початок періоду
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'end_period_completed_percent'}
              highlighted={highlights.includes('end_period_completed_percent')}
              onToggleHighlight={() => onToggleHighlight('end_period_completed_percent')}
            >
              % виконання на кінець періоду
            </TableHeaderCell>
          </Col>
          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'year_amount'}
              highlighted={highlights.includes('year_amount')}
              onToggleHighlight={() => onToggleHighlight('year_amount')}
              title={totals.year_amount}
            >
              {fields.year_amount.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'total_amount'}
              highlighted={highlights.includes('total_amount')}
              onToggleHighlight={() => onToggleHighlight('total_amount')}
              title={totals.total_amount}
            >
              {fields.total_amount.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    year_amount: PropTypes.number,
    total_amount: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
