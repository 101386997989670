import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NumberInput, ItemPicker, StringInput } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/limitReference';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'general';

const tableMD = md.tables[tablename];

const QUARTERS = [...Array(4)].map((_, i) => i);

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights, FI, inBR,
}) {
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumZFChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumZF.name;
      const colName2 = tableMD.columns.sum.name;

      const tot = value + (row[colName2] || 0) - (row[colName] || 0);

      onRowChange(e, {
        [colName]: value,
        [colName2]: tot,
      });
    },
    [onRowChange, row],
  );

  const onSumChange = useCallback(
    (e, month, isBR, value) => {
      const colName = tableMD.columns[`Sum${isBR ? 'BR' : ''}${month}`].name;
      const colNameBR = tableMD.columns[`SumBR${month}`].name;
      const totColName = tableMD.columns[`Sum${isBR ? 'BR' : ''}`].name;
      const tot = [...Array(12)].reduce(
        (R, _, i) => R + (row[tableMD.columns[`Sum${isBR ? 'BR' : ''}${i + 1}`].name] || 0),
        value - (row[colName] || 0),
      );
      const totColNameBr = tableMD.columns.SumBR.name;
      const totBr = [...Array(12)].reduce(
        (R, _, i) => R + (row[tableMD.columns[`SumBR${i + 1}`].name] || 0),
        value - (row[colName] || 0),
      );

      onRowChange(e, {
        [colName]: value,
        [colNameBR]: inBR ? value : 0,
        [totColName]: tot,
        [totColNameBr]: inBR ? totBr : 0,
      });
    },
    [inBR, onRowChange, row],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
              <ItemPicker
                value={row[tableMD.columns.CSU.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSU.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="csu"
                filter={CSUFIlter}
                noHierarchy
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.elementKFK.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="elementKFK"
                filter={FIOwnerFilter}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="KPKVCode" highlighted={highlights.includes('KPKVCode')}>
              <StringInput
                value={row[tableMD.columns.KPKVCode.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.KPKVCode.name]: v });
                  await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
                }}
                readOnly={readOnly}
                maxLength={7}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col xl={8} sm={12}>
        <Row xl={5} noGutters>
          {QUARTERS.map((q) => (
            <Col md={3} key={q}>
              {[...Array(3)].map((_, m) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col as={TableRowCell} column={`Sum${q * 3 + m + 1}`} highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}>
                    <NumberInput
                      value={row[tableMD.columns[`Sum${q * 3 + m + 1}`].name]}
                      precision={2}
                      onChange={(e, v) => onSumChange(e, q * 3 + m + 1, false, v)}
                      readOnly={readOnly}
                    />
                  </Col>
                  {inBR && (
                    <Col as={TableRowCell} column={`SumBR${q * 3 + m + 1}`} highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}>
                      <NumberInput
                        value={row[tableMD.columns[`SumBR${q * 3 + m + 1}`].name]}
                        precision={2}
                        // className="bg-light"
                        inputClassName="font-italic"
                        onChange={(e, v) => onSumChange(e, q * 3 + m + 1, true, v)}
                        readOnly={readOnly}
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className=" d-flex justify-content-start flex-row flex-sm-column">
            <NumberInput
              value={row[tableMD.columns.Sum.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, { [tableMD.columns.Sum.name]: v })}
              readOnly
            />
            {inBR && (
              <NumberInput
                value={row[tableMD.columns.SumBR.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumBR.name]: v })}
                readOnly
              />
            )}
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
  inBR: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
