import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel, ContainerTwoColumn, FramePrintModal, FiveColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled, InputStyled } from '../../field/selectorField/item/styles';
import enums from '../../documents/assignmentPlanningRev/enumsForPrintModal';
import { InputWithDropdown } from '../../../components/styledInputs';
import { SettingsIcon } from '../../../assets/icons';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.assigmentPrintingRev;

const SetPageOrientation = Object.values(enums.FormVariant);

const StringField4 = Fields.GetTextInput(4);

class AssigmentPrintingRevEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
    isProcessing: false,
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (state.firstStart && !props.isProcessing) {
  //     props.dispatch(actions.processServerReq('PRINT'));
  //     return { firstStart: false };
  //   }
  //   return null;
  // }

  constructor(props) {
    super(props);
    this.state = {
      ShowSettings: false,
      // firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, headerForm, isProcessing },
      state: { ShowSettings },
    } = this;

    return (
      <MainContainer>
        <ContainerButtonTop>
          <CommandPanelButton
            text="Сформувати"
            onClick={() => {
              this.setState({ ShowSettings: false });
              dispatch(actions.changeField(['headerForm', 'result'], new Map()));
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
          </CommandPanelButton>
          <CommandPanelButton
            text="Налаштування"
            onClick={() => { this.setState({ ShowSettings: !ShowSettings }); }}
          >
            <SettingsIcon />
          </CommandPanelButton>
        </ContainerButtonTop>
        {
          ShowSettings && (
            <FramePrintModal
              styled={ShowSettings ? { transition: '3s' } : { transition: '3s' }}
              visible={ShowSettings}
              animation="overlay"
              width="very wide"
              direction="top"
              tertiary
              color="blue"
              basic
            >
              <div>
                <ContainerTwoColumn>
                  <div>
                    <StyledLabel>{md.columns.Document.label}</StyledLabel>
                    {/* Проверить после написания ServerReq */}
                    <Fields.ItemField
                      value={headerForm.get('Document', new Map())}
                    />
                  </div>
                  <div>
                    <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
                    <Fields.SelectorField
                      value={headerForm.get('SignVariant', new Map())}
                      modelType="cat"
                      modelName="signatureVariants"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                      canCleared
                      // filter={[
                      //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
                      // ]}
                    />
                  </div>
                </ContainerTwoColumn>
                <FiveColumnsGridContainer>
                  <div>
                    <StyledLabel>{md.columns.Symbol0.label}</StyledLabel>
                    <StringField4
                      radius
                      background
                      value={headerForm.get('Symbol0', '')}
                      onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'Symbol0'], value))}
                    />
                  </div>
                  <div>
                    <StyledLabel>{md.columns.FormVariant.label}</StyledLabel>
                    <InputWithDropdown
                      value={headerForm.get('FormVariant', 0)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FormVariant'], v))}
                      options={SetPageOrientation}
                    />
                  </div>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('KDBdetailing', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KDBdetailing'], v))}
                      label={md.columns.KDBdetailing.label}
                    />
                    <Fields.CheckboxField
                      value={headerForm.get('Groups', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Groups'], v))}
                      label={md.columns.Groups.label}
                    />
                    <Fields.CheckboxField
                      value={headerForm.get('NameSurname', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NameSurname'], v))}
                      label={md.columns.NameSurname.label}
                    />
                  </div>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('AllClassifier', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AllClassifier'], v))}
                      label={md.columns.AllClassifier.label}
                    />
                    <Fields.CheckboxField
                      value={headerForm.get('FormCosts', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FormCosts'], v))}
                      label={md.columns.FormCosts.label}
                    />
                  </div>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('Currency', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Currency'], v))}
                      label={md.columns.Currency.label}
                    />
                    <Fields.CheckboxField
                      value={headerForm.get('DontShowBr', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DontShowBr'], v))}
                      label={md.columns.DontShowBr.label}
                    />
                  </div>
                </FiveColumnsGridContainer>
                <FiveColumnsGridContainer>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('DontShowAdditionHeader', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DontShowAdditionHeader'], v))}
                      label={md.columns.DontShowAdditionHeader.label}
                    />
                  </div>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('order938', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'order938'], v))}
                      label={md.columns.order938.label}
                    />
                  </div>
                </FiveColumnsGridContainer>
              </div>
            </FramePrintModal>
          )
        }
        <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />

      </MainContainer>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
    },
  ),

);

export default enchance(AssigmentPrintingRevEditor);
