import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, InputGroup, Badge,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import {
  PrependAccount,
  PrependFond, PrependKBP7,
} from '../../styles/klassifiers';
import RowSet from './tp/rowset';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.paymenttrans.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbp7ItemProps = editorHooks.useItemInputProps('kbp7_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbp7Props = editorHooks.useItemInputProps('kbp7', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const byItemProps = editorHooks.useCheckboxInputProps('by_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fromAccountProps = editorHooks.useItemInputProps('from_account', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const toAccountProps = editorHooks.useItemInputProps('to_account', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const executedByBankProps = editorHooks.useCheckboxInputProps('executed_by_bank', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const purposeProps = editorHooks.useStringInputProps('purpose', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentProps = editorHooks.useStringInputProps('comment', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  //
  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const kbp7ItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const accountFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.paymenttrans.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <div className="d-flex justify-content-end">
            <Badge variant="info" className="me-2">
              {data.year}
                  &nbsp;р.
            </Badge>
          </div>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col xl={2} lg={3}>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col xl={2} lg={4}>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col xl={3} lg={5}>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      <InputGroup.Text className="w-100">{data.fond && data.fond.code}</InputGroup.Text>
                    </PrependFond>
                  )}
                />
              </Col>
              <Col xl={2} lg={6} className="d-flex align-items-start justify-content-center flex-column">
                <EditorControls.CheckboxInput {...byItemProps} />
                <EditorControls.CheckboxInput {...executedByBankProps} className="me-2" />
              </Col>
              <Col xl={3} lg={6}>
                {data.by_item ? (
                  <EditorControls.ItemPicker
                    {...kbp7ItemProps}
                    filter={kbp7ItemFilter}
                    prepend={(
                      <PrependKBP7>
                        <InputGroup.Text className="w-100">{data.kbp7 && data.kbp7.repr}</InputGroup.Text>
                      </PrependKBP7>
                  )}
                  />
                ) : (
                  <EditorControls.ItemPicker {...kbp7Props} />
                )}
              </Col>
              <Col lg={6}>
                <EditorControls.ItemPicker
                  {...fromAccountProps}
                  filter={accountFilter}
                  prepend={(
                    <PrependAccount>
                      <InputGroup.Text className="w-100">
                        {data.from_account && data.from_account.code}
                      </InputGroup.Text>
                    </PrependAccount>
                )}
                />
              </Col>
              <Col lg={6}>
                <EditorControls.ItemPicker
                  {...toAccountProps}
                  filter={accountFilter}
                  prepend={(
                    <PrependAccount>
                      <InputGroup.Text className="w-100">
                        {data.to_account && data.to_account.code}
                      </InputGroup.Text>
                    </PrependAccount>
                )}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex" />
            </Row>

          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="paymenttransrow_set">
          {fields && fields.paymenttransrow_set && (
          <Tab eventKey="paymenttransrow_set" title={fields.paymenttransrow_set.label}>
            <TabContainer>
              <RowSet
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="notes" title="Призначення платежу">
            <TabContainer>
              <Row>
                <Col md={6}>
                  <EditorControls.TextInput {...purposeProps} />
                </Col>
                <Col md={6}>
                  <EditorControls.TextInput {...commentProps} />
                </Col>
              </Row>
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onSave={onSave}
        onClose={onClose}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
