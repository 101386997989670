import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dataPropTypes } from './propTypes';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { CPButton } from '../../../../../../components/bootStrap/buttons';

function EfficiencyCalculation({
  onChange, onDraft, data, ...rest
}) {
  const onChangeTP = useCallback((f) => {
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
      };
    });
  }, [onChange]);

  const template = useMemo(
    () => {
      const сolProps = {
        xxl: 4,
        xl: 4,
        lg: 12,
        sm: 12,
        xs: 4,
      };

      return {
        columns: [
          {
            field: 'activity',
            colProps: сolProps,
            controlProps: {
              typeOfChoice: typesOfChoices.onlyElements,
              filter: {
                budget: data.budget ? data.budget.id : null,
              },
            },
          },
          {
            colProps: сolProps,
            columns: [
              {
                field: 'effective_degree',
                colProps: { xs: 12 },
              },
              {
                field: 'effectiveness',
                colProps: { xs: 12 },
              },
              {
                field: 'adjustment',
                colProps: { xs: 12 },
              },
            ],
          },
          {
            field: 'low_effectiveness_explanation',
            colProps: сolProps,
            controlProps: {
              rows: 3,
            },
          },
        ],
      };
    },
    [data.budget],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbpactivityrow_set"
      onChange={onChangeTP}
      totals={[]}
      addCommands={(
        <CPButton
          content="Розрахувати ефективність"
          onClick={() => onDraft(
            data,
            {
              calculate_effectivity: {
                recalculate: true,
              },
            },
          )}
          variant="falcon-success"
          size="sm"
        >
          Розрахувати ефективність
        </CPButton>
      )}
    />
  );
}

EfficiencyCalculation.propTypes = {
  data: PropTypes.shape({
    executionbpactivityrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    budget: foreignPropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
};

EfficiencyCalculation.defaultProps = {
};

export default EfficiencyCalculation;
