import React from 'react';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const TABLENAME = 'filledFromDocument';
const coldef = md.doc.financingCashbox.tables[TABLENAME].columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="num">{coldef.num.label}</DivHeader>
      <DivHeader areaName="IsApproved">{coldef.IsApproved.label}</DivHeader>
      <DivHeader areaName="data">{coldef.data.label}</DivHeader>
      <DivHeader areaName="number">{coldef.number.label}</DivHeader>
      <DivHeader areaName="document">{coldef.document.label}</DivHeader>
      <DivHeader areaName="sum">{coldef.sum.label}</DivHeader>
      <DivHeader areaName="Tags">{coldef.Tags.label}</DivHeader>
      <DivHeader areaName="Author">{coldef.Author.label}</DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
