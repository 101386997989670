import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  targetprogram: foreignPropType,
  amount_plan_total: PropTypes.number,
  amount_plan_sf: PropTypes.number,
  amount_plan_zf: PropTypes.number,
  amount_fact_total: PropTypes.number,
  amount_fact_sf: PropTypes.number,
  amount_fact_zf: PropTypes.number,
  amount_diff_total: PropTypes.number,
  amount_diff_sf: PropTypes.number,
  amount_diff_zf: PropTypes.number,
  explanation: PropTypes.string,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
