import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';
import checkTask from '../../../../api/checktask';
import md from '../../../../meta/doc/transferToLogica';

const useSendLogika = ({ documnetId, url }) => {
  const navigate = useNavigate();
  const { auth } = useContext(CiatAppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleSendLogika = useCallback(() => {
    const send = async () => {
      const r = await api.post(`${url}${documnetId}/create_container_for_one_document/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    send()
      .then(({ task_id: taskId }) => checkTask(taskId, auth))
      .then((d) => {
        if (d.result.errors) throw new Error(d.result.errors.join(', '));
        navigate(`${md.frontendURL}${d.result.container.id}/`);
      })
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, documnetId, navigate, url]);
  return { handleSendLogika, loading, err };
};

export default useSendLogika;
