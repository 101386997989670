import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/planOfUse';
import {
  StyledLabel,
  CommandPanelColor, ThreeColumnsGridContainer, DivWithPropsLabel,
} from '../../../components/Form/styledForm';

import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import StyledTPPane from '../../../components/tableParts/TPPane';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';

import ItemField from '../../field/selectorField/item/itemField';
import Tabs from '../../../components/tabs/Tabs';
import DateInput from '../../../components/fields/dates';
import meta from '../../../constants/meta';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;
const StringInput4 = Fields.GetTextInput(4);
const NumberInput4 = Fields.GetNumberInput(4, 0, true);

const ThreeColumnsGrid = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 30% 10% 56%;
  grid-column-gap: 2%;
  margin-top: 10px;
`;

const TwoColumnsGrid = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 48% 50%;
  grid-column-gap: 2%;
`;

const SixColumnsGrid = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%;
`;

const ReportTypes = {
  plan: { label: 'Розпис', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'Розпис+уточнення', value: 2 },
};

const FundsTypes = {
  none: { label: 'Не враховувати', value: 0 },
  assign: { label: 'Асигнування', value: 1 },
  credit: { label: 'Кредитування', value: 2 },
};

const TypeVariantKEKV = {
  details: { label: 'Деталізацією КЕКВ', name: 0 },
  full: { label: 'Повним КЕКВ', name: 1 },
  short: { label: 'Скороченим КЕКВ', name: 2 },
};

const ShowCSU = {
  mainCSU: { label: 'Гол.Розпорядник', name: 0 },
  csu: { label: 'Розпорядник', name: 1 },
  kvk: { label: 'КВК', name: 2 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const reportVariants = headerForm.get('reportVariants', new Map());
  const FO = headerForm.get('FO', new Map());

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter
            filterChoice={new Map()
              .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
              .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
              .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
              .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
              .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
          />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="0" empty disabled />
    </Tabs>
  );

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'PlanOfUse';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const BudgetLabel = (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      {sessionOptions.get('is_admin', false) ? (
        <Fields.SelectorField
          value={headerForm.get('budget', new Map())}
          modelType="cat"
          modelName="budget"
          onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
        />
      ) : (
        <ItemField
          value={headerForm.get('budget', new Map())}
          disabled
        />
      )}
    </div>
  );

  const FoLabel = (
    <div>
      <StyledLabel>{md.columns.FO.label}</StyledLabel>
      {sessionOptions.get('is_admin', false) ? (
        <Fields.SelectorField
          value={headerForm.get('FO', new Map())}
          modelType="cat"
          modelName="csu"
          onChange={(e, v) => {
            dispatch(changeField(['headerForm', 'FO'], v));
            dispatch(changeField(['headerForm', 'signVariant'], new Map()));
          }}
        />
      ) : (
        <ItemField
          value={headerForm.get('FO', new Map())}
          disabled
        />
      )}
    </div>
  );

  const Quarter = (headerForm.get('planType') === 1) && (
    <div>
      <StyledLabel>{md.columns.quarter.label}</StyledLabel>
      <InputWithDropdown
        value={headerForm.get('quarter')}
        options={Object.values(enums.QuartalVariants)}
        onChange={(e, v) => dispatch(changeField(['headerForm', 'quarter'], v))}
      />
    </div>
  );

  const ByReducedKEKV = (headerForm.get('typeVariantKEKV') === 2) && (
    <Fields.CheckboxField
      value={headerForm.get('byReducedKEKV', false)}
      label={md.columns.byReducedKEKV.label}
      onChange={(e, v) => dispatch(changeField(['headerForm', 'byReducedKEKV'], v))}
    />
  );

  const changeDetailsKEKV = () => {
    dispatch(changeField(['headerForm', 'byShortKEKV'], headerForm.get('byDetailsKEKV', false)));
    dispatch(changeField(['headerForm', 'byDetailsElKEKV'], headerForm.get('byDetailsKEKV', false)));
  };

  const ByDetailsKEKV = (headerForm.get('typeVariantKEKV') !== 0) && (
    <Fields.CheckboxField
      value={headerForm.get('byDetailsKEKV', false)}
      label={md.columns.byDetailsKEKV.label}
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'byDetailsKEKV'], v));
        changeDetailsKEKV();
      }}
    />
  );

  const AllKEKV = (headerForm.get('typeVariantKEKV') !== 0) && (
    <Fields.CheckboxField
      value={headerForm.get('allKEKV', false)}
      label={md.columns.allKEKV.label}
      onChange={(e, v) => dispatch(changeField(['headerForm', 'allKEKV'], v))}
    />
  );

  const ByShortKEKV = (headerForm.get('typeVariantKEKV') === 2) && (
    <Fields.CheckboxField
      value={headerForm.get('byShortKEKV', false)}
      label={md.columns.byShortKEKV.label}
      onChange={(e, v) => dispatch(changeField(['headerForm', 'byShortKEKV'], v))}
    />
  );

  const SettingsButton = (
    <div>
      <div>
        <TwoColumnsGrid>
          <div>
            {BudgetLabel}
          </div>
          <div>
            {FoLabel}
          </div>
        </TwoColumnsGrid>
        <TwoColumnsGrid>
          <div>
            <StyledLabel>{md.columns.year.label}</StyledLabel>
            <Fields.YearField
              value={headerForm.get('year', 0)}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'year'], value))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.datePrint.label}</StyledLabel>
            <DateInput
              value={headerForm.get('datePrint', null)}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'datePrint'], value))}
            />
          </div>
        </TwoColumnsGrid>
        <SixColumnsGrid>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => ({ label: _e.label, value: _e.name }))}
              name="planType"
            />
          </div>
          <div>
            <div>
              <StyledLabel>{md.columns.FundsType.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('FundsType', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'FundsType'], v))}
                values={Object.values(FundsTypes)}
                name="FundsType"
              />
            </div>
            <div>
              <StyledLabel>{md.columns.typeVariantKEKV.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('typeVariantKEKV')}
                options={Object.values(TypeVariantKEKV)}
                onChange={(e, v) => {
                  dispatch(changeField(['headerForm', 'typeVariantKEKV'], v));
                  if (v === 0) {
                    dispatch(changeField(['headerForm', 'allKEKV'], false));
                    dispatch(changeField(['headerForm', 'byDetailsKEKV'], false));
                    changeDetailsKEKV();
                  }
                }}
              />
            </div>
            {Quarter}
          </div>
          <div>
            {ByReducedKEKV}
            <Fields.CheckboxField
              value={headerForm.get('notRepeatDetails', false)}
              label={md.columns.notRepeatDetails.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'notRepeatDetails'], v))}
            />
            {ByDetailsKEKV}
            <Fields.CheckboxField
              value={headerForm.get('byDetailsElKEKV', false)}
              label={md.columns.byDetailsElKEKV.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byDetailsElKEKV'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('notShowSubGroupCode', false)}
              label={md.columns.notShowSubGroupCode.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'notShowSubGroupCode'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.symbol0.label}</StyledLabel>
            <StringInput4
              radius
              background
              value={headerForm.get('symbol0', '')}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'symbol0'], v))}
            />
            <StyledLabel>{md.columns.widthColumn.label}</StyledLabel>
            <StringInput4
              radius
              background
              value={headerForm.get('widthColumn', '')}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'widthColumn'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('in1000', true)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.showCSU.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('showCSU')}
              options={Object.values(ShowCSU)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSU'], v))}
            />
            {ByShortKEKV}
            {AllKEKV}
            <Fields.CheckboxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.forYear.label}
              value={headerForm.get('forYear', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'forYear'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('onlyOnePage', false)}
              label={md.columns.onlyOnePage.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'onlyOnePage'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('hideKEKVgroups', false)}
              label={md.columns.hideKEKVgroups.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'hideKEKVgroups'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('hideEmptyRows', false)}
              label={md.columns.hideEmptyRows.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'hideEmptyRows'], v))}
            />
          </div>
        </SixColumnsGrid>
      </div>
      {Bookmarks}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>
      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
