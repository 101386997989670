import base from './base';

/**
 * @const
 * @type {object}
 */
export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `Сумма ${v + 1}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  fondObject: { label: 'Елемент фонд', name: 'ЭлементФонд', visible: true },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  classifier: { label: 'Класифiкатор', name: 'ПереченьКл', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  onlyYear: { label: 'Тільки річні суми', name: 'ТолькоГодовая', visible: false },
  includeBudgetAmplification: { label: 'в т.ч. Бюджет Розвитку', name: 'ВТЧБР', visible: false },
  year: { label: 'Рiк', name: 'Год', visible: true },
  refReason: {
    label: '',
    name: 'ОснованиеДляИзм',
    visible: false,
  },
  templateBaseDoc: { label: 'Підстава для змін', name: 'ШаблонОснованияДок', visible: true },
  implementer: { label: 'Виконавець', name: 'Исполнитель', visible: true },
  internal: { label: 'Внутрішня', name: 'Внутренняя', visible: true },
  noteList: { label: 'Перелік КДБ', name: 'ПереченьКл', visible: false },
  docTotalCost: {
    label: 'Сума',
    name: 'ИтСуммаДок',
    visible: true,
    type: 'moneys',
  },
  numberRegistryFI: {
    label: 'Номер довідки',
    name: 'РегНомер',
    visible: true,
  },
  isSignature: {
    label: 'Підписано',
    name: 'МаксПодпись',
    visible: true,
    type: 'bool',
  },
  // Дополнительные свойства
  thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      KDBCode: { label: 'Код ', name: 'КодКДБ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до розпису доходів',
  name: 'changeToAssignPlanRev',
  backendName: 'ИзменениеДоходов',
  columns,
  frontend: 'doc/changeToAssignPlanRev',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'year',
      'numberRegistryFI',
      'docTotalCost',
      'isSignature',
      'fondObject',
      'author',
      'internal',
      'classifier',
      'note',
      'budget',
      'FI',
      'includeBudgetAmplification',
      'refReason',
      'templateBaseDoc',
      'implementer',
      'noteList',
      'numberRegistryFI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
