import React from 'react';
import PropTypes from 'prop-types';
import StdReportEditor from './editor';

function StdRouterReportEditor({ id, url }) {
  return <StdReportEditor reportId={id} reportUrl={url} />;
}

StdRouterReportEditor.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default StdRouterReportEditor;
