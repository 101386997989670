import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Col, Row } from 'react-bootstrap';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import md from '../../../../constants/meta/documents/legalObligation';
import enums from '../../../../constants/meta/enums';
import {
  StyledLabel,
  ColorTextStamp,
  ImgContainerStamp,
  ContainerStamp,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import { soSelector } from '../../_common/selectors';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { referencePropType } from '../../../newEditor/propTypes';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const documentKindsOptions = Object.values(enums.ObligationTypes).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const procurementProcOptions = Object.values(enums.ProcurementProc).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function DocEditor({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);

  const readOnly = !permissions.canChange;

  const documentKind = data[md.columns.documentKind.name];
  const isAccountHighLevelCSU = data[md.columns.isAccountHighLevelCSU.name];

  const FI = data[md.columns.FI.name];

  const isAdmin = sessionOptions.get('is_admin', false);

  const FiFilter = useMemo(
    () => {
      if (FI) {
        return (isAdmin && (FI.id === '00000000-0000-0000-0000-000000000000')) ? [] : [{ fieldName: 'Владелец', value: FI }];
      }
      return [];
    },
    [FI, isAdmin],
  );

  return (
    <EditorCollapse>
      {isAdmin && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row className="align-items-end">
        {documentKind === enums.ObligationTypes.storno.name && (
          <Col>
            <EditorControls.StringInput
              label={md.columns.reason.label}
              value={data[md.columns.reason.name].repr}
              readOnly
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.rNo.label}
            value={data[md.columns.rNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.rNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.planingMode.name]: value,
              });
            }}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.SelectorInput
            label={md.columns.documentKind.label}
            value={data[md.columns.documentKind.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.documentKind.name]: value,
              });
            }}
            values={documentKindsOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.label}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
              });
            }}
            modelType="cat"
            modelName="elementFond"
            filter={FiFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
              <EditorControls.ItemPicker
                label={md.columns.elementKFK.label}
                value={data[md.columns.elementKFK.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.elementKFK.name]: value,
                  });
                  actions.onSR('CHANGE_KFK');
                }}
                modelType="cat"
                modelName="elementKFK"
                filter={FiFilter}
                readOnly={readOnly}
              />
            </div>
            <div>
              <p />
              <StyledLabel style={{ marginLeft: '10px', color: 'red' }}>
                {data[md.columns.KPKVCode.name]}
              </StyledLabel>
            </div>
          </div>
        </Col>

      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.client.label}
            value={data[md.columns.client.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.client.name]: value,
              });
              if (data[md.columns.client.name]) actions.onSR('CHANGE_CLIENT');
            }}
            modelType="cat"
            modelName="csuPartners"
            filter={FiFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.account.label}
            value={data[md.columns.account.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.account.name]: value,
              });
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={[
              ...FiFilter,
              { fieldName: 'ВидСчета', value: isAccountHighLevelCSU ? 2 : 1 },
            ]}
            params={[{
              name: 'НаДату',
              value: data[md.columns.date.name],
            }]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.procurementProc.label}
            value={data[md.columns.procurementProc.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.procurementProc.name]: value,
              });
            }}
            values={procurementProcOptions}
            readOnly={readOnly}
          />
        </Col>
        {data[md.columns.accountingByClients.name] && (
          <Col>
            <EditorControls.StringInput
              label={md.columns.procurementRes.label}
              value={data[md.columns.procurementRes.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.procurementRes.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        <Col>
          <EditorControls.StringInput
            label={md.columns.procPROZORRO.label}
            value={data[md.columns.procPROZORRO.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.procPROZORRO.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.contractNo.label}
            value={data[md.columns.contractNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.contractNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.DateInput
            label={md.columns.contractStartDate.label}
            value={data[md.columns.contractStartDate.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.contractStartDate.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.contractEndDate.label}
            value={data[md.columns.contractEndDate.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.contractEndDate.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.contractTime.label}
            value={data[md.columns.contractTime.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.contractTime.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>

      </Row>

      <Row>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`received-${data.id}`}
              label={md.columns.received.label}
              value={data[md.columns.received.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.received.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>

        )}

        <ContainerStamp>
          {data[md.columns.isApproved.name] && (
            <ImgContainerStamp background={StampApproved}>
              <ColorTextStamp color="#4281c9">
                {data[md.columns.received.name]}
              </ColorTextStamp>
            </ImgContainerStamp>
          )}
        </ContainerStamp>
      </Row>
    </EditorCollapse>
  );
}

DocEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.planingMode.name]: PropTypes.oneOf([
      enums.PlaningKind.temporary.name, enums.PlaningKind.permanent.name,
    ]),
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DocEditor;
