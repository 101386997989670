import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  activity: foreignPropType,
  amount_sf0: PropTypes.number,
  amount_total0: PropTypes.number,
  amount_zf0: PropTypes.number,
  description: PropTypes.string,
  general_indicator_code: PropTypes.string,
  indicator: foreignPropType,
  kvk3: foreignPropType,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
