import { useCallback, useMemo } from 'react';
import { useDCSFilter } from './filter';

const defaults = {
  Filter: {},
  FilterAvailableFields: {},
};

export const useDCS = ({
  data, onChange,
  // Параметры, которые всключаются в дополнительные запросы.
  // Служат для выбора на стороне сервера СхемыКомпоновкиДанных
  reqParams = {},
  backendURL,
  onError,
}) => {
  if (!backendURL) throw new Error('backendURL дожен быть установлен.');
  const usedData = useMemo(
    () => ({
      ...defaults,
      ...data,
    }),
    [data],
  );
  // console.log(data);
  const onChangeFilter = useCallback(
    (f) => onChange(
      (o) => ({ ...o, Filter: f(o.Filter) }),
    ),
    [onChange],
  );
  const onChangeFilterAvailables = useCallback(
    (f) => onChange(
      (o) => ({ ...o, FilterAvailableFields: f(o.FilterAvailableFields) }),
    ),
    [onChange],
  );

  const {
    filters, availables: filterAvailables,
  } = useDCSFilter({
    filter: usedData.Filter,
    availableFields: usedData.FilterAvailableFields,
    onChange: onChangeFilter,
    onChangeAvailables: onChangeFilterAvailables,
    reqParams,
    backendURL,
    onError,
  });
  return {
    filters,
    filterAvailables,
  };
};
