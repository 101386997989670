import React from 'react';
import md from '../../../../../meta/cat/kekv';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { CatTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_classifierLister/lister_selector';
import ClassifierRow from '../_classifierLister/classifierRow';
import ClassifierCP from '../_classifierLister/classifierCP';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(CatTableListerFactory({
  RowComponent: ClassifierRow,
  CommandPanel: ClassifierCP,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
