import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const StyledTableRow = styled(Row)`
  & .col {
  }  
  & .form-control{
    background-color: transparent;
  }
  & > * + * {
    //border-left: 1px solid rgba(0,0,0,.125);
  }
  &:nth-child(2n) {
    background-color: var(--falcon-light);
  }
  &.active {
    color: #495057;
    background-color: rgb(0 123 255 / 10%);
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
  &.active .input-group-text{
    background-color: transparent;
  }
  margin-left: 0!important; 
  margin-right: 0!important; 
`;

export const TableHeaderRow = styled(Row)`
  margin-left: 0!important; 
  margin-right: 0!important; 
  & > * {
    font-weight: 700;
  }
`;

export const StyledTableHeaderCell = styled.div.attrs(({ active, className }) => ({
  className: active ? `${className || ''} active` : className,
}))`
  padding: 0 .5rem;
  &.active {
    color: #495057;
    border-color: #80bdff;
    outline: 0;
    border-radius: var(--falcon-border-radius);
    box-shadow: var(--falcon-box-shadow-inset), 0 0 0 0.25rem rgba(44, 123, 229, 0.25);
  }
`;

export const StrNumberRibbon = styled.div`
  position: absolute;
  width: auto!important;
  left: 0;
  top: 0;
  background-color: var(--falcon-info);
  color: var(--falcon-white);
  text-align: center;
  padding: 0.3rem 0.8rem 0.3rem 0.5rem;
  border-radius: 0 0 100% 0;
  z-index: 1;
`;
