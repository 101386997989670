const frontendURL = '/rep/plan_ballancing/';
const name = 'Баланс планів асигнувань, доходів, та джерел фінансування';
const sections = 'planning';

const instance = {
  frontendURL,
  name,
  sections,
};

export default instance;
