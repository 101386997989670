import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import StringInput from './stringInput';
import { DateInput } from './dates';
import Selector from './selector';
import NumberInput from './numberInput';
// eslint-disable-next-line import/no-cycle
import { ItemPicker } from './itemField';
import { foreignPropType } from '../../constants/backend/propTypes';
import typesOfChoices from '../../constants/typesOfChoices';

function UnknowTypeComponent() {
  return <Badge variant="danger">Невідомий тип параметру</Badge>;
}

const MultiValueInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, onBlur, onChange, id,
  ctype, resource,
  errored, onlyGroups, prepend, append,
}, ref) => {
  const ParamsComponent = useMemo(() => {
    if (typeof ctype === 'string') {
      if (ctype.toLowerCase() === 'string') {
        return StringInput;
      }
      if (ctype.toLowerCase() === 'date') {
        return DateInput;
      }
      if (ctype.toLowerCase() === 'boolean') {
        return Selector;
      }
      if (ctype.toLowerCase() === 'integer') {
        return NumberInput;
      }
      if (ctype.toLowerCase() === 'decimal') {
        return NumberInput;
      }
      if (ctype.toLowerCase() === 'float') {
        return NumberInput;
      }
      if (ctype.toLowerCase() === 'nestedobject' && resource) {
        // eslint-disable-next-line import/no-cycle
        return ItemPicker;
      }
    }
    return UnknowTypeComponent;
  }, [ctype, resource]);

  const fcProps = useMemo(() => {
    const globalProps = {
      id,
      value,
      onChange,
      disabled,
      readOnly,
      onClick,
      onFocus,
      onBlur,
      errored,
      prepend,
      append,
    };
    if (typeof ctype === 'string') {
      if (ctype.toLowerCase() === 'boolean') {
        return {
          ...globalProps,
          values: [
            { value: true, display_name: 'Так' },
            { value: false, display_name: 'Ні' },
          ],
        };
      }
      if (ctype.toLowerCase() === 'date') {
        return {
          ...globalProps,
          variants: [
            { value: '@today', display_name: 'Сьогодні' },
            { value: '@yesterday', display_name: 'Вчора' },
            { value: '@tomorrow', display_name: 'Завтра' },
            { value: '@begin_of_the_year', display_name: 'На початок цього року' },
            { value: '@end_of_the_year', display_name: 'На кінець цього року' },
          ],
        };
      }
      if (ctype.toLowerCase() === 'nestedobject' && resource) {
        return {
          ...globalProps,
          backend: resource,
          canErase: true,
          typeOfChoice: onlyGroups ? typesOfChoices.onlyGroups : typesOfChoices.onlyElements,
        };
      }
    }
    return globalProps;
  }, [
    id, value, onChange, disabled, readOnly, onClick, onFocus, onBlur,
    errored, prepend, append, ctype, resource, onlyGroups,
  ]);
  return (
    <ParamsComponent
      ref={ref}
      {...fcProps}
    />
  );
});

MultiValueInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.number, foreignPropType,
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errored: PropTypes.bool,
  ctype: PropTypes.oneOf(['String', 'Date', 'Boolean', 'Integer', 'Decimal', 'NestedObject']).isRequired,
  resource: PropTypes.string,
  onlyGroups: PropTypes.bool,
  prepend: PropTypes.node,
  append: PropTypes.node,
};

MultiValueInput.defaultProps = {
  id: '',
  value: null,
  disabled: false,
  readOnly: false,
  onClick: null,
  onFocus: null,
  onBlur: null,
  errored: false,
  resource: null,
  onlyGroups: false,
  prepend: null,
  append: null,
};

export default MultiValueInput;
