import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor,
} from '../../../components/Form/styledForm';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import DocsTP from './tablepart/apdocsTP';
import storePathParam from '../../../common/storePathParam';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';

const md = meta.dp.treasureReportsDBF;

const NumInput4 = Fields.GetNumberInput(4, 0, false);
const StringInput10 = Fields.GetTextInput(10);

const ReportTypes = {
  plan: { label: 'Розпис', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'Розпис+уточнення', value: 2 },
};

const codeInNameTypes = {
  none: { label: 'Немає', value: 0 },
  csu: { label: 'Гол.розпорядник', value: 1 },
  sub: { label: 'Установа', value: 2 },
};

const assignTypes = {
  TranscriptKEKV1: { label: 'Асигнування', value: 2 },
  TranscriptKEKV2: { label: 'Надання кредитів', value: 3 },
  TranscriptKEKV3: { label: 'Повернення кредитів', value: 4 },
};

class TreasureReportsDBFEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      // firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <MyContainerFormWithNColumns columns={4}>
          <div>
            <StyledLabel>{md.columns.assign.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('assign', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'assign'], v))}
              values={Object.values(assignTypes)}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', 0)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.NR.label}</StyledLabel>
            <NumInput4
              border
              radius
              background
              value={headerForm.get('NR', 0)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NR'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.NOM.label}</StyledLabel>
            <StringInput10
              radius
              background
              value={headerForm.get('NOM', '')}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NOM'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={4}>
          <div>
            <StyledLabel>Код розпорядника в імені файла </StyledLabel>
            <RadioButton
              value={headerForm.get('codeInName', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'codeInName'], v))}
              values={Object.values(codeInNameTypes)}
              name="codeInName"
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('nameFileChange', false)}
              label={md.columns.nameFileChange.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'nameFileChange'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('UploadAsChanges', false)}
              label={md.columns.UploadAsChanges.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'UploadAsChanges'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('NoYearSummIfZero', false)}
              label={md.columns.NoYearSummIfZero.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoYearSummIfZero'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('onlyYear', false)}
              label={md.columns.onlyYear.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyYear'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('PCM', false)}
              label={md.columns.PCM.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PCM'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('inHeadCSU', false)}
              label={md.columns.inHeadCSU.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inHeadCSU'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('NoYearSumm', false)}
              label={md.columns.NoYearSumm.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoYearSumm'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(<TPCommandPanel tableName="tpDoc" />));

function TablesRender() {
  return <DocsTPRenderer />;
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

const connectedTablesRender = connect(mapStateTP)(TablesRender);

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportPlansAndChangesToDBF',
    },
    {
      menu: () => null,
      tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(TreasureReportsDBFEditor);
