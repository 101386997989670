import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/treasuryStatementLegalObligation';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'dateDoc'}
              highlighted={highlights.includes('dateDoc')}
              onToggleHighlght={() => onToggleHighlght('dateDoc')}
            >
              {meta.columns.dateDoc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'dateTerm'}
              highlighted={highlights.includes('dateTerm')}
              onToggleHighlght={() => onToggleHighlght('dateTerm')}
            >
              {meta.columns.dateTerm.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'dateR'}
              highlighted={highlights.includes('dateR')}
              onToggleHighlght={() => onToggleHighlght('dateR')}
            >
              {meta.columns.dateR.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'numberDoc'}
              highlighted={highlights.includes('numberDoc')}
              onToggleHighlght={() => onToggleHighlght('numberDoc')}
            >
              {meta.columns.numberDoc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KTKVCode'}
              highlighted={highlights.includes('KTKVCode')}
              onToggleHighlght={() => onToggleHighlght('KTKVCode')}
            >
              {meta.columns.KTKVCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Code'}
              highlighted={highlights.includes('Code')}
              onToggleHighlght={() => onToggleHighlght('Code')}
            >
              {meta.columns.Code.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FondCode'}
              highlighted={highlights.includes('FondCode')}
              onToggleHighlght={() => onToggleHighlght('FondCode')}
            >
              {meta.columns.FondCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KVKCode'}
              highlighted={highlights.includes('KVKCode')}
              onToggleHighlght={() => onToggleHighlght('KVKCode')}
            >
              {meta.columns.KVKCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KEKVCode'}
              highlighted={highlights.includes('KEKVCode')}
              onToggleHighlght={() => onToggleHighlght('KEKVCode')}
            >
              {meta.columns.KEKVCode.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumAdvance'}
              highlighted={highlights.includes('sumAdvance')}
              onToggleHighlght={() => onToggleHighlght('sumAdvance')}
            >
              {meta.columns.sumAdvance.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'edrpou'}
              highlighted={highlights.includes('edrpou')}
              onToggleHighlght={() => onToggleHighlght('edrpou')}
            >
              {meta.columns.edrpou.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nameCSU'}
              highlighted={highlights.includes('nameCSU')}
              onToggleHighlght={() => onToggleHighlght('nameCSU')}
            >
              {meta.columns.nameCSU.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'info'}
              highlighted={highlights.includes('info')}
              onToggleHighlght={() => onToggleHighlght('info')}
            >
              {meta.columns.info.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
