import {
  defaultColumns,
  defaultOrder,
  // hierarchyTypes,
  // reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  typesWork: {
    label: 'Вид робiт',
    name: 'КлассификаторВидовРабот',
    visible: true,
  },
};

const tables = {
  additionalProperties: {
    name: 'ДопСвойства',
    label: 'Додаткові властивості',
    columns: {
      period: { label: 'Перiод', name: 'Период' },
      propertyType: { label: 'Вид властивостi', name: 'ВидСвойства' },
      val: { label: 'Значення', name: 'Значение' },
    },
  },
};

export const typesRepair = {
  label: 'Довідник видів ремонту',
  name: 'TypesRepair',
  backendName: 'ОбъектыСтроительстваВидыРемонта',
  columns,
  defaultOrder,
  frontend: 'cat/typesRepair',
  listColumns: [
    ...(new Set([
      // ...reqListerColumns,
      'name',
      'typesWork',
    ]))],
  tables,
  // hierarchy: true,
  // hierarchyType: hierarchyTypes.foldersNItems,
};

export default typesRepair;
