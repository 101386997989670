import React, {
  useState, useEffect, useCallback, memo, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Collection, Map } from 'immutable';
import styled from 'styled-components';
import {
  FrameDiv, CollapseButton, ExpandArrow, ErrorMessage,
} from '../../components/Form/styledForm';
import {
  init,
  load,
  loadAttachedFiles,
  clearWarnings,
} from '../../actions/editor';
import def from '../../constants/meta/index';
import AttachedFiles from '../infoRegs/attachedFiles/index';
import Loader from '../../components/styledWrappedLoader';
import { CloseIcon } from '../../assets/icons';

export { default as getRouterEditor } from '../catalogs/routerEditor';

export const mapState = (store, ownProps) => {
  const winManagerState = store.get('windowsManager', new Map());
  const modelName = winManagerState.get('name', '');
  const modelType = winManagerState.get('type', '');
  const cProps = winManagerState.get('params', { id: '' });
  const { id } = (typeof cProps === 'undefined' ? { id: '' } : cProps);

  const editorNode = store.getIn([`${modelType}/${modelName}/editor`, id], new Map());
  return {
    hierarchyType: editorNode.get('hierarchyType', 'item'),
    sessionOptions: store.getIn(['auth', 'sessionOptions'], new Map()),
    canBeSaved: editorNode.get('canBeSaved', false),
    isLoading: editorNode.get('isLoading', true),
    isProcessing: editorNode.get('isProcessing', true),
    isChanged: editorNode.get('isChanged', false),
    isValidated: editorNode.get('isValidated', false),
    hasError: editorNode.get('hasError', false),
    errorMsg: editorNode.get('errorMsg', ''),
    headerForm: editorNode.get('headerForm', new Map()), // Шапка
    disabledFields: editorNode.get('disabledFields', new Collection.Set()),
    notes: editorNode.get('notes', ''),
    isOpenAttachedFiles: editorNode.get('isOpenAttachedFiles', true),
    AttachedFiles: editorNode.get('AttachedFiles', []),
    isLoadingAttachedFiles: editorNode.get('isLoadingAttachedFiles', true),
    warnings: editorNode.get('warnings', new Map()),
    ...ownProps,
  };
};

const WarningsContainer = styled.div`
  border: 1px solid #afbbcc;
  border-radius: .3em;  
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
  overflow: auto;
  position: sticky;
  bottom: 0;
  z-index: 12;
  background: #fddbdb;
  &>button {
    position: absolute;
    top: 4px;
    right: 10px;
    background: transparent;
    width: 20px;
    height: 20px;
    padding: 2px 1px;
    border-radius: 50%;
    cursor: pointer;
    border: none;      
  }
  &>button:hover, &>button:focus {
    border: 1px solid  #4281C9;
    outline: none;
  }
  &>span::before {
    content: '!!!';
    color: darkred;
    margin-right: 10px;
    font-weight: 700;
  }
`;

/*
 * @param modelNameNType {Object}
 * @param modelNameNType.name {string}
 * @param modelNameNType.type {string}
 * @param {extra} {Object}
 * @param {extra.menu} {React.Component}
 * @param {extra.tables} {React.Component}
 * @param {extra.footer} {React.Component}
 * @param {extra.canCollapsed} {bool}
 * @returns {function(<object>): *}
 * @constructor
 */

const EditorHOC = ({ name, type }, extra) => (WrappedComponent) => {
  function BasicEditor(props) {
    const [isOpened, setIsOpened] = useState(true);
    const {
      dispatch, id, search,
      warnings, isOpenAttachedFiles,
      isLoading, isProcessing, hasError, isLocked, isChanged, errorMsg,
      onClose, onSave,
      headerForm,
    } = props;
    useEffect(
      () => {
        const c = new URLSearchParams(search);
        const initParams = {

          ...(c.get('reason') ? { reason: c.get('reason') } : {}),
          isGroup: c.get('g'),
          copyFrom: c.get('copyFrom'),
        };
        dispatch(init(type, name, id, initParams));
        dispatch(load());
        dispatch(loadAttachedFiles());
      },
      [dispatch, id, search],
    );

    const collapseMenu = useCallback(
      () => setIsOpened((v) => !v),
      [],
    );

    const MenuWrapper = extra && extra.menu;
    const TablesWrapper = extra && extra.tables;
    const displayWarnings = warnings.reduce((R, w) => [
      ...R,
      ...w.reduce((R2, w2) => [...R2, w2.get('msg')], []),
    ], []);

    const footer = useMemo(
      () => (extra && extra.footer ? React.cloneElement(extra.footer, props) : null),
      [props],
    );
    return (
      <Loader isLoading={isLoading || isProcessing}>

        {MenuWrapper && (
          <MenuWrapper
            dispatch={dispatch}
            onClose={onClose}
            onSave={onSave}
            disabled={isLoading || hasError || isProcessing}
            isLocked={isLocked}
            isChanged={isChanged}
            header={type === 'doc' ? headerForm.get('repr') : `${def[type][name].label}: ${headerForm.get('repr')}`}
            id={headerForm.get('id')}
            modelName={name}
            modelType={type}
          />
        )}
        {hasError && (
          <ErrorMessage>
            {errorMsg}
          </ErrorMessage>
        )}
        <FrameDiv>

          {isOpenAttachedFiles && <AttachedFiles /> }

          {isOpened ? (
            <>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <WrappedComponent {...props} />
              {extra.canCollapsed && (
                <CollapseButton isOpened={isOpened} onClick={collapseMenu} title="Згорнути" />
              )}
            </>
          ) : (
            <ExpandArrow isOpened={isOpened} onClick={collapseMenu} title="Розгорнути" />
          )}
        </FrameDiv>
        <div style={{ clear: 'both' }} />
        {!isLoading && TablesWrapper && <TablesWrapper dispatch={dispatch} />}
        {footer}
        {displayWarnings.length > 0 && (
          <WarningsContainer>
            <button
              type="button"
              onClick={() => props.dispatch(clearWarnings())}
            >
              <CloseIcon />
            </button>
            {displayWarnings.map((w) => (
              <span>{w}</span>
            ))}
          </WarningsContainer>
        )}
      </Loader>
    );
  }

  BasicEditor.displayName = `Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  BasicEditor.propTypes = {
    id: PropTypes.string.isRequired, // id элемента
    search: PropTypes.string, // Параметры адресной строки (то что идет после ?)
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    isSaved: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isLocked: PropTypes.bool,
    canBeSaved: PropTypes.bool,
    isChanged: PropTypes.bool,
    repr: PropTypes.string,
    noStyles: PropTypes.bool,
    isOpenAttachedFiles: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
    headerForm: PropTypes.instanceOf(Map).isRequired,
    warnings: PropTypes.instanceOf(Map).isRequired,
  };

  BasicEditor.defaultProps = {
    repr: '',
    isSaved: true,
    isProcessing: false,
    isChanged: false,
    isLocked: false,
    canBeSaved: false,
    noStyles: false,
    search: '',
    errorMsg: null,
  };

  return memo(BasicEditor);
};

export default EditorHOC;
