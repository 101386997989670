import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { elementKFB as md } from '../../../../constants/meta/catalogs/elementKFB';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { Title } from '../../../../components/Form/styledForm';

const autoFillName = {
  first: { value: 1, display_name: 'З назви класифікатора' },
  second: { value: 2, display_name: 'З назви елементу вищого рівня' },
};

const signs = {
  plus: { value: 1, display_name: '+' },
  minus: { value: -1, display_name: '—' },
  zero: { value: 0, display_name: 'Не враховувати' },
};

function ElementKFB({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.flAutoFillName.label}
            value={data[md.columns.flAutoFillName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flAutoFillName.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(autoFillName)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.flAutoFillFullName.label}
            value={data[md.columns.flAutoFillFullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flAutoFillFullName.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(autoFillName)}
          />
        </Col>
      </Row>
      <Title>Зв`язок з КФБ, станом на вказану дату</Title>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Classifier.label}
            value={data[md.columns.Classifier.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.Classifier.name]: value,
              });
              actions.onSR('ПриИзменении_Классификатор');
            }}
            modelType="cat"
            modelName="kfbClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCl.label}
            value={data[md.columns.DateCl.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.DateCl.name]: value,
              });
              actions.onSR('ПриИзменении_ДатаКл');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.Mark.label}
            value={data[md.columns.Mark.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Mark.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(signs)}
          />
        </Col>
      </Row>
      <Title>Зв`язок елементів КФБ, станом на вказану дату</Title>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.ElemOfFinInstitution.label}
            value={data[md.columns.ElemOfFinInstitution.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.ElemOfFinInstitution.name]: value,
              });
              actions.onSR('ИзмЭлементФинОргана');
            }}
            modelType="cat"
            modelName="elementKFB"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateEl.label}
            value={data[md.columns.DateEl.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.DateEl.name]: value,
              });
              actions.onSR('ПриИзменении_ДатаКл');
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="elementKFB"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

ElementKFB.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ElementKFB;
