import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import {
  CommandPanelColor, ContainerThreeColumn,
  ContainerTwoColumn,
  StyledLabel, Title, TwoRow,
} from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/documents/indicatorFact';
import {
  TasksSpentTable,
  IndicatorsTables,
  RegionProgramsTables,
  SourcesInvestmentProjects,
  SourcesSpecFTable,
  DocsTpRendererTable,
  ExplanationLowEffectTable,
  TasksComparingTable,
} from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { Container } from '../../../../components/styledTable/styledTable';
import StyledTPPane from '../../../../components/tableParts/TPPane';
import TextInput from '../../../../components/bootstrap_components/controls/textInput';
import enums from '../../../../constants/meta/enums';
import EffectivityPrintModal from './effectivityPrintModal';

function IndicatorFactTabs({
  data, actions, permissions,
}) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title="Основне" eventKey="general">
        <Tabs defaultActiveKey="tasksSpent" className="m-0 mt-2 border-bottom">
          <Tab title={md.tables.tasksSpent.label} eventKey="tasksSpent">
            <TasksSpentTable data={data} actions={actions} readOnly={readOnly} />
            <div>
              <StyledLabel>{md.columns.fullNameKFK.label}</StyledLabel>
              <EditorControls.TextInput
                value={data[md.columns.fullNameKFK.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.fullNameKFK.name]: value,
                })}
                readOnly={readOnly}
                rows={5}
              />
            </div>
          </Tab>

          <Tab title={md.tables.generalTPInd.label} eventKey="generalTPInd">
            <IndicatorsTables data={data} actions={actions} readOnly={readOnly} />
            <div>
              <StyledLabel>{md.columns.analyzePerformanceIndicator.label}</StyledLabel>
              <TextInput
                value={data[md.columns.analyzePerformanceIndicator.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.analyzePerformanceIndicator.name]: value,
                })}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.analize.label}</StyledLabel>
              <TextInput
                value={data[md.columns.analize.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.analize.name]: value,
                })}
              />
            </div>
          </Tab>

          <Tab title={md.tables.regionPrograms.label} eventKey="regionPrograms">
            <RegionProgramsTables data={data} actions={actions} readOnly={readOnly} />
          </Tab>

          <Tab title={md.tables.sourcesInvestmentProjects.label} eventKey="sourcesInvestmentProjects">
            <SourcesInvestmentProjects data={data} actions={actions} readOnly={readOnly} />
          </Tab>

          <Tab title={md.tables.sourcesSpecF.label} eventKey="sourcesSpecF">
            <SourcesSpecFTable data={data} actions={actions} readOnly={readOnly} />
          </Tab>

          <Tab title={md.tables.descendantsDocuments.label} eventKey="descendantsDocuments">
            <CommandPanelColor
              style={{ width: 'fit-content' }}
              label="Заповнити даними з обраних документів"
              onClick={() => actions.onSR('LOAD_FROM_DOC_SELECTION')}
            />
            <EditorControls.CheckboxInput
              controlId={`isDeleteRows-${data.id}`}
              label={md.columns.isDeleteRows.label}
              value={data[md.columns.isDeleteRows.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isDeleteRows.name]: value,
              })}
              readOnly={readOnly}
            />
            <DocsTpRendererTable data={data} actions={actions} readOnly={readOnly} />
          </Tab>

          <Tab title="Примітка" eventKey="notes">
            <Container fluid className="mx-1 my-2">
              <EditorControls.TextInput
                label={md.columns.note.label}
                value={data[md.columns.note.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.note.name]: value,
                })}
                readOnly={readOnly}
                rows={5}
              />
            </Container>
          </Tab>
        </Tabs>
      </Tab>
      <Tab title={md.tables.explanationLowEffect.label} eventKey="explanationLowEffect">
        <ExplanationLowEffectTable data={data} actions={actions} readOnly={readOnly}>
          <CommandPanelColor
            style={{ width: 'fit-content' }}
            label="Розрахунок ефективності"
            onClick={() => actions.onSR('CALCULATION_EFFICIENCY_TASKS')}
          />
          <EffectivityPrintModal
            data={data}
            actions={actions}
          />
        </ExplanationLowEffectTable>
        <ContainerThreeColumn className="m-2">
          <div>
            <StyledLabel className="font-italic font-weight-bold">
              {md.columns.eficiencyBp.label}
              :
            </StyledLabel>
            <StyledLabel>
              {Object.values(enums.effectivenessTypes).filter(
                (s) => s.name === data[md.columns.eficiencyBp.name],
              ).pop()?.label}
            </StyledLabel>
          </div>
          <div>
            <StyledLabel className="font-italic font-weight-bold">
              {md.columns.degreeEfficiency.label}
              :
            </StyledLabel>
            <StyledLabel>{data[md.columns.degreeEfficiency.name]}</StyledLabel>
          </div>
          <div>
            <StyledLabel className="font-italic font-weight-bold">
              {md.columns.c_corrTot.label}
              :
            </StyledLabel>
            <StyledLabel>{data[md.columns.c_corrTot.name]}</StyledLabel>
          </div>
        </ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.explanationLowEffect.label}</StyledLabel>
          <TextInput
            value={data[md.columns.explanationLowEffect.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.explanationLowEffect.name]: value,
            })}
          />
        </div>
      </Tab>
      <Tab title={md.tables.tasksComparing.label} eventKey="tasksComparing">
        <TasksComparingTable data={data} actions={actions} readOnly={readOnly}>
          <CommandPanelColor
            style={{ width: 'fit-content' }}
            label="Заповнити"
            onClick={() => actions.onSR('FILL_COMPARING')}
          />
        </TasksComparingTable>
        <div>
          <StyledLabel>{md.columns.changeInCosts.label}</StyledLabel>
          <TextInput
            value={data[md.columns.changeInCosts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.changeInCosts.name]: value,
            })}
          />
        </div>
      </Tab>
      <Tab title="Висновки по програмі(608)" eventKey="conclusions">
        <StyledTPPane style={{ overflowY: 'auto' }}>
          <>
            <Title>Пояснення до п.5.5.</Title>
            <ContainerTwoColumn>
              <div>
                <StyledLabel>{md.columns.deviationPlanCash5_5.label}</StyledLabel>
                <TextInput
                  value={data[md.columns.deviationPlanCash5_5.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.deviationPlanCash5_5.name]: value,
                  })}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.deviationFactCash5_5.label}</StyledLabel>
                <TextInput
                  value={data[md.columns.deviationFactCash5_5.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.deviationFactCash5_5.name]: value,
                  })}
                />
              </div>
            </ContainerTwoColumn>
          </>
          <TwoRow style={{ margin: '10px 0' }}>
            <div>
              <StyledLabel>{md.columns.defectionFin.label}</StyledLabel>
              <TextInput
                value={data[md.columns.defectionFin.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.defectionFin.name]: value,
                })}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.disciplineFin.label}</StyledLabel>
              <TextInput
                value={data[md.columns.disciplineFin.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.disciplineFin.name]: value,
                })}
              />
            </div>
          </TwoRow>
          <>
            <Title>Узагальнений висновок щодо:</Title>
            <ContainerTwoColumn>
              <div>
                <StyledLabel>{md.columns.relevanceBp.label}</StyledLabel>
                <TextInput
                  value={data[md.columns.relevanceBp.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.relevanceBp.name]: value,
                  })}
                />
              </div>
              <div>
                <StyledLabel>ефективності бюджетної програми:</StyledLabel>
                <TextInput
                  value={data[md.columns.efficiencyBp.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.efficiencyBp.name]: value,
                  })}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.usefulnessBp.label}</StyledLabel>
                <TextInput
                  value={data[md.columns.usefulnessBp.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.usefulnessBp.name]: value,
                  })}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.effectsBp.label}</StyledLabel>
                <TextInput
                  value={data[md.columns.effectsBp.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.effectsBp.name]: value,
                  })}
                />
              </div>
            </ContainerTwoColumn>
          </>
        </StyledTPPane>
      </Tab>
    </Tabs>
  );
}

IndicatorFactTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default IndicatorFactTabs;
