import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Container, Alert,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/assignmentPlanningSource';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
// eslint-disable-next-line import/named
import { GeneralTP } from './tabs';

function AssignmentPlanningSourceTabs({
  data, actions, permissions, isUpdating, setIsUpdating, onSumChangeForBalance,
}) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        {data[md.columns.message.name] && (
          <Alert variant="info">
            {data[md.columns.message.name]}
          </Alert>
        )}
        <GeneralTP
          data={data}
          actions={actions}
          readOnly={readOnly}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          onSumChangeForBalance={onSumChangeForBalance}
        />
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

AssignmentPlanningSourceTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  onSumChangeForBalance: PropTypes.func.isRequired,
};

export default AssignmentPlanningSourceTabs;
