const getStdFetchParams = (access = null) => ({
  headers: {
    'Content-Type': 'application/json',
    ...(access ? { Authorization: `Bearer ${access}` } : {}),
  },
  mode: 'cors',
  credentials: 'include',
});

export async function loginMinfin(url, username, password) {
  const r = await fetch(`${url}api/token/`, {
    ...getStdFetchParams(),
    method: 'post',
    body: JSON.stringify({
      username,
      password,
    }),
  });
  if (!r.ok) {
    let errorMsg = '';
    try {
      const d = await r.json();
      const errors = Object.keys(d)
        .reduce((R, k) => [...R, d[k]], []);
      errorMsg = errors.reduce((R, e) => `${R} ${e}`, '');
    } catch {
      errorMsg = `${r.status} ${r.statusText}`;
    }
    throw new Error(errorMsg);
  }
  const tokens = await r.json();
  const resp = await fetch(`${url}api/auth/currentuser/`, {
    ...getStdFetchParams(tokens.access),
    method: 'get',

  });
  if (!resp.ok) {
    throw new Error(`${resp.status} ${resp.statusText}`);
  }
  const userData = await resp.json();
  return {
    tokens,
    userData: userData[0],
  };
}
