import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ForLink({
  to = '#!',
  icon = 'chevron-right',
  title,
  className,
  onClick,
}) {
  return (
    <Button
      variant="link"
      size="sm"
      as={Link}
      to={to}
      className={className}
      onClick={onClick}
    >
      {title}
      <FontAwesomeIcon icon={icon} className="ms-1 fs--2" />
    </Button>
  );
}

ForLink.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ForLink.defaultProps = {
  to: '',
  icon: '',
  className: '',
  onClick: null,
};

export default ForLink;
