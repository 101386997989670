const frontendURL = '/rep/allocationdetial/';
const backendURL = '/api/authorityplans/printform/allocation_detail/';
const name = 'Розпис (Витяг) асигнувань (регламентований)';

const instance = {
  frontendURL,
  name,
  backendURL,
};

export default instance;
