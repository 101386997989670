import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { moneysStingOptions } from '../../../../constants/moneys';

function TableListerCell({
  value, type, onContextMenu, choices,
}) {
  const dChoices = useMemo(
    () => {
      if (!Array.isArray(choices)) {
        return null;
      }
      return choices.reduce((R, c) => ({ ...R, [c.value]: c.display_name }), {});
    },
    [choices],
  );

  const displayValue = useMemo(
    () => {
      if (dChoices) return dChoices[value] || '???';
      if (type === 'nested object') {
        return value && value.repr;
      }
      if (type === 'decimal') {
        return parseFloat(value).toLocaleString('uk', moneysStingOptions);
      }
      if (type === 'date') {
        const d = new Date(value);
        return d.toLocaleString('uk', {
          day: '2-digit', month: '2-digit', year: 'numeric',
        });
      }
      if (type === 'datetime') {
        const d = new Date(value);
        return d.toLocaleString('uk', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      }
      if (type === 'boolean') {
        if (value) {
          return <FontAwesomeIcon icon={faCheck} className="text-success" />;
        }
        return <FontAwesomeIcon icon={faMinus} className="text-danger" />;
      }
      return value;
    },
    [dChoices, type, value],
  );
  return (
    <td className={classNames('pe-3', type)} title={typeof displayValue === 'string' ? displayValue : ''} onContextMenu={onContextMenu}>
      {displayValue}
    </td>

  );
}

const valuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }),
]);

TableListerCell.propTypes = {
  value: valuePropType,
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    display_name: PropTypes.string,
  })),
  type: PropTypes.oneOf([
    'string', 'email', 'url', 'integer', 'float', 'decimal', 'choice', 'date', 'datetime', 'nested object', 'boolean', 'field',
  ]),
  onContextMenu: PropTypes.func.isRequired,
};

TableListerCell.defaultProps = {
  value: null,
  type: 'string',
  choices: null,
};

export default memo(TableListerCell);
