const frontendURL = '/cat/elementKEKV/';
const backendURL = '/api/references/refkekvitem/';
const name = 'Елементи класифікації економічної (КЕКВ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
