import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import {
  Alert,
  Button, Modal, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { useMD } from '../../newLister/hooks/md';
import logikaMD from '../../../constants/meta/documents/docTransferToLogica';
import ListerContext from '../../newLister/context';
import api from '../../../api/req';

function SendToLogicaButton({
  className, modelType, modelName, hideSign, children, extraData,
}) {
  const [show, setShow] = useState(false);
  const [sign, setSign] = useState(null);
  const buttonRef = useRef();
  const md = useMD(modelType, modelName);
  const {
    selectedRows,
  } = useContext(ListerContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const navigate = useNavigate();
  const onLoad = useCallback(
    () => {
      const loader = async (id) => {
        const r = await api.post(`doc/${md.backendName}/`, {
          method: 'sendToLogika',
          id,
          ...(hideSign ? { } : { sign }),
          ...extraData,
        });
        if (r.status === 422) {
          const json = await r.json();
          // eslint-disable-next-line no-underscore-dangle
          if (json._Error) throw new Error(json._Error);
        }
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader(selectedRows[0])
        .then(({ logikaDoc }) => {
          navigate(`/${logikaMD.frontend}/${logikaDoc.id}/`);
          setShow(false);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [extraData, hideSign, navigate, md.backendName, selectedRows, sign],
  );
  useEffect(
    () => {
      if (err) console.log(err);
    },
    [err],
  );

  return (
    <>
      <Button
        variant={err ? 'danger' : 'success'}
        title="Відправити до Logica"
        ref={buttonRef}
        onClick={() => setShow(true)}
        disabled={!selectedRows.length}
        className={className}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Відправка до Logika</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {err && (
            <Alert variant="danger">{err}</Alert>
          )}
          {!hideSign && (
            <EditorControls.ItemPicker
              label="Варіант підпису"
              required
              value={sign}
              onChange={(e, v) => setSign(v)}
              modelType="cat"
              modelName="signatureVariants"
            />
          )}
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" disabled={!sign && !hideSign} onClick={onLoad}>
            {loading && <Spinner animation="grow" size="sm" className="me-2" /> }
            Відправити
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

SendToLogicaButton.propTypes = {
  className: PropTypes.string,
  modelType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  hideSign: PropTypes.bool,
  extraData: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

SendToLogicaButton.defaultProps = {
  className: '',
  hideSign: false,
  extraData: {},
  children: null,
};

export default SendToLogicaButton;
