import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/reportAnalysis';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  TwoColumnsGridContainer,
  StyledPane,
  DivWithPropsLabel,
  ResultSegment,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import ItemField from '../../field/selectorField/item/itemField';
import ReportContainer from '../reportContainer';

class ReportEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    sessionOptions: PropTypes.instanceOf(Map),
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    sessionOptions: new Map(),
    onReportDetails: null,
  };

  static displayName = `Report${md.name}Editor`;

  constructor(props) {
    super(props);
    this.resultMountNode = [`rep/${md.name}/reportEditor`, 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const { portalOpened } = this.props;

    const BudgetLabel = sessionOptions.get('is_admin', false) ? (
      <div>
        <StyledLabel>{md.columns.budget.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('budget', new Map())}
          modelType="cat"
          modelName="budget"
          onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
        />
      </div>
    ) : (
      <div>
        <StyledLabel>{md.columns.budget.label}</StyledLabel>
        <ItemField
          value={headerForm.get('budget', new Map())}
          disabled
        />
      </div>
    );

    const FoLabel = sessionOptions.get('is_admin', false) || headerForm.get('byHighLevelCSU', false) ? (
      <div>
        <StyledLabel>{md.columns.FO.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('FO', new Map())}
          modelType="cat"
          modelName="csu"
          onChange={(e, v) => {
            dispatch(changeField(['headerForm', 'FO'], v));
            dispatch(changeField(['headerForm', 'signVariant'], new Map()));
          }}
        />
      </div>
    ) : (
      <div>
        <StyledLabel>{md.columns.FO.label}</StyledLabel>
        <ItemField
          value={headerForm.get('FO', new Map())}
          disabled
        />
      </div>
    );
    const FO = headerForm.get('FO', new Map());
    const SettingsButton = (
      <div>
        <div>
          <TwoColumnsGridContainer>
            <div>
              {BudgetLabel}
            </div>
            <div>
              {FoLabel}
            </div>
          </TwoColumnsGridContainer>
        </div>
        <Tabs>
          <DivWithPropsLabel label="Группування">
            <StyledPane>
              <DCControls.Structure />
            </StyledPane>
          </DivWithPropsLabel>
          <DivWithPropsLabel label="Відбір">
            <StyledPane>
              <DCControls.Filter
                filterChoice={new Map()
                  .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                  .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                  .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                  .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                  .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
              />
            </StyledPane>
          </DivWithPropsLabel>
          <DivWithPropsLabel label="Обрані поля">
            <StyledPane>
              <DCControls.Selection />
            </StyledPane>
          </DivWithPropsLabel>
          <DivWithPropsLabel label="Сортування">
            <StyledPane>
              <DCControls.Order />
            </StyledPane>
          </DivWithPropsLabel>
        </Tabs>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <div style={{ marginTop: '10px' }}>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
