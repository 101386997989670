import React, { memo, useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import {
  ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faEdit,
} from '@fortawesome/free-regular-svg-icons';
import {
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { StyledCtxContainer } from './styles';
import { useCtxMenu } from './hook';
import ListerContext from '../../context';

const CatCtxMenu = ({ children }) => {
  const {
    ctxMenu, onHideCtxMenu, actions, permissions,
  } = useContext(ListerContext);
  const { ref } = useCtxMenu(ctxMenu, onHideCtxMenu);

  const onActionClickHanlder = useCallback(
    (act, e) => {
      switch (act) {
        case 'edit':
          actions.onEdit(e);
          break;
        case 'copy':
          actions.onCopy(e);
          break;
        case 'delete':
          actions.onDelete(e);
          break;
        default:
          throw new Error(`Unknow action ${act}`);
      }
      onHideCtxMenu(e);
    },
    [actions, onHideCtxMenu],
  );

  return createPortal(
    (
      <StyledCtxContainer ref={ref} visible={ctxMenu.visible}>
        <ListGroup onSelect={onActionClickHanlder} activeKey="">
          <ListGroup.Item action eventKey="edit" disabled={!permissions.canEdit}>
            <FontAwesomeIcon icon={faEdit} className="me-2" size="lg" />
            Змінити
          </ListGroup.Item>
          <ListGroup.Item action eventKey="copy" disabled={!permissions.canCopy}>
            <FontAwesomeIcon icon={faCopy} className="me-2" size="lg" />
            Копіювати
          </ListGroup.Item>
          <ListGroup.Item action eventKey="delete" disabled={!permissions.canDelete}>
            <FontAwesomeIcon icon={faTrash} className="me-2 text-danger" size="lg" />
            Видалити
          </ListGroup.Item>
          {children}

        </ListGroup>
      </StyledCtxContainer>
    ), document.body,
  );
};

export default memo(CatCtxMenu);
