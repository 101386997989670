import React from 'react';
import PropTypes from 'prop-types';

import { Container, Tab, Tabs } from 'react-bootstrap';
import { GeneralTP, AccCloseTP } from './tabs';
import md from '../../../../constants/meta/documents/incomingMoney';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function IncomingMoneyTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      {!data[md.columns.ClosingAcc.name] ? (
        <Tab title={md.tables.general.label} eventKey="general">
          <GeneralTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      ) : (
        <Tab title={md.tables.accClose.label} eventKey="accClose">
          <AccCloseTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      <Tab title="Примітка" eventKey="Prim">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.Prim.label}
            value={data[md.columns.Prim.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Prim.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

IncomingMoneyTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default IncomingMoneyTabs;
