import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../constants/meta/catalogs/groundLaw';
import { TableHeaderRow } from '../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.tpParagr;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col className="my-3">
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'paragr'}
          highlighted={highlights.includes('paragr')}
          onToggleHighlght={() => onToggleHighlght('paragr')}
        >
          {meta.columns.paragr.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
