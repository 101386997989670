import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, Popover, OverlayTrigger,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, headerProps,
  } = useEditor({
    backendURL: meta.doc.creditProject.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const projectVariantProps = editorHooks.useItemInputProps('project_variant', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const noteProps = editorHooks.useTextInputProps('note', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerInHeaderProps = editorHooks.useCheckboxInputProps('disposer_in_header', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const retSumsWMinus = editorHooks.useCheckboxInputProps('return_sums_with_minus', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.doc.creditProject.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...projectVariantProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(
                    <Popover id="tooltip-disposerInHeaderProps">
                      <Popover.Header as="h3">
                        Рекомендовано виносити Розпорядника в заголовок таблиці,
                        якщо він має видатки за трьома або більше КБП.
                      </Popover.Header>
                      <Popover.Body>
                        Цей перемикач дозволяє вносити інформацію як за одним Розпорядником
                        так і за всіма одночасно. Якщо інформація вноситься за всіма
                        Розпорядниками, до кожного рядка документа буде додано Розпорядника. Це
                        дозволить згрупувати дані щодо Додатку до Рішення в одному документі,
                        але збільшить його обсяг, що може ускладнити пошук
                        інформації по кожному окремому Розпоряднику. Внесення інформації за одним
                        Розпорядником зменшить обсяг документа та полегшить пошук, але збільшить
                        кількість документів у журналі.
                        Рекомендується виносити Розпорядника в заголовок таблиці, якщо
                        він має видатки за трьома або більше КБП
                      </Popover.Body>
                    </Popover>
                    )}
                >
                  <div className="mr-5">
                    <EditorControls.CheckboxInput {...disposerInHeaderProps} />
                  </div>
                </OverlayTrigger>
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...retSumsWMinus} />
              </Col>
            </Row>
            {data.disposer_in_header && (
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    {...disposerProps}
                    filter={{ parent: data.authority.id }}
                    noHierarchy
                  />
                </Col>
              </Row>
            )}
          </Container>
        </HidableContainer>

        <LabelTab
          defaultActiveKey="creditprojectheader"
          id="uncontrolled-tab-example"
        >
          {fields && fields.creditprojectrow_set && (
            <Tab
              eventKey="creditprojectheader"
              title="Основне"
            >
              <TabContainer>
                <PlanningRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>
          )}

          <Tab eventKey="note" title="Примітка">
            <TabContainer>
              <EditorControls.TextInput {...noteProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
