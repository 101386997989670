import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/netCategoryPlan';

const meta = md.tables.general;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col sm={8}>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'category'}
              highlighted={highlights.includes('category')}
              onToggleHighlght={() => onToggleHighlght('category')}
            >
              {meta.columns.category.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'countRow'}
              highlighted={highlights.includes('countRow')}
              onToggleHighlght={() => onToggleHighlght('countRow')}
            >
              {meta.columns.countRow.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'TPKVK'}
              highlighted={highlights.includes('TPKVK')}
              onToggleHighlght={() => onToggleHighlght('TPKVK')}
            >
              {meta.columns.TPKVK.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'unit'}
              highlighted={highlights.includes('unit')}
              onToggleHighlght={() => onToggleHighlght('unit')}
            >
              {meta.columns.unit.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum1'}
          highlighted={highlights.includes('sum1')}
          onToggleHighlght={() => onToggleHighlght('sum1')}
        >
          {meta.columns.sum1.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum2'}
          highlighted={highlights.includes('sum2')}
          onToggleHighlght={() => onToggleHighlght('sum2')}
        >
          {meta.columns.sum2.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum4'}
          highlighted={highlights.includes('sum4')}
          onToggleHighlght={() => onToggleHighlght('sum4')}
        >
          {meta.columns.sum4.label}
        </TableHeaderCell>
      </Col>
      {/* </Col> */}
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
