import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { statesContingents as md } from '../../../../constants/meta/catalogs/statesContingents';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function StatesContingents({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.fullName.label}
            value={data[md.columns.fullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.key.label}
            value={data[md.columns.key.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.key.name]: value,
            })}
            readOnly={readOnly}
            size={3}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="statesContingents"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`notIncludeInTotals-${data.id}`}
            label={md.columns.notIncludeInTotals.label}
            value={data[md.columns.notIncludeInTotals.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.notIncludeInTotals.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`doNotPrint-${data.id}`}
            label={md.columns.doNotPrint.label}
            value={data[md.columns.doNotPrint.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.doNotPrint.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

StatesContingents.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StatesContingents;
