import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import GetItemField from './getItemField';

import { DivStyled, DivLoadStyled, LoadStyled } from './styles';

const ItemField = React.forwardRef(({
  tabIndex, value, onChange, searchText, disabled, canFocused, onDoubleClick, required, rows,
  isFocusCell, loading, children, noBorder, noBackground, alignStart,
}, ref) => (
  <DivStyled
    ref={ref}
    noBorder={noBorder}
    alignStart={alignStart}
    noBackground={noBackground}
  >
    <GetItemField
      value={value}
      onChange={onChange}
      searchText={searchText}
      required={required}
      disabled={disabled}
      canFocused={canFocused}
      tabIndex={tabIndex}
      onDoubleClick={onDoubleClick}
      // rows - высота в строках
      rows={rows}
      noBorder={noBorder}
      noBackground={noBackground}
      isFocusCell={isFocusCell}
    />
    {loading && <LoadStyled><DivLoadStyled /></LoadStyled>}
    {children}
  </DivStyled>
));

export default (ItemField);

ItemField.propTypes = {
  loading: PropTypes.bool,
  noBorder: PropTypes.bool,
  noBackground: PropTypes.bool,
  alignStart: PropTypes.bool,
  tabIndex: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.any,
  ]),
  value: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func,
  searchText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFocused: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  rows: PropTypes.number,
  isFocusCell: PropTypes.bool,
};

ItemField.defaultProps = {
  loading: false,
  noBorder: false,
  noBackground: false,
  alignStart: false,
  children: null,
  onChange: () => null,
  searchText: null,
  disabled: false,
  required: false,
  canFocused: true,
  tabIndex: null,
  onDoubleClick: null,
  rows: 1,
  isFocusCell: false,

};
