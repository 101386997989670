import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  StyledDisplayToolbar, StyledDisplayForm, StyledDisplayFormula, StyledDisplayInput,
  StyledToolbar, StyledToolbarItem, StyledToolbarItemBS,
} from './styles';
import { CancelIcon } from '../../../../assets/icons';

const DisplayToolbar = forwardRef(({
  formula, input, isShowHistory, onBackspace, onHistory,
}, ref) => (
  <StyledDisplayToolbar>
    <StyledDisplayForm>
      <StyledDisplayFormula
        value={formula.join('')}
        readOnly
      />
      <StyledDisplayInput
        rows="1"
        value={input}
        readOnly
        ref={ref}
      />
    </StyledDisplayForm>
    <StyledToolbar>
      <StyledToolbarItem
        onClick={onHistory}
      >
        {isShowHistory ? 'Keypad' : 'History'}
      </StyledToolbarItem>
      <div>
        <StyledToolbarItemBS
          onClick={onBackspace}
        >
          <CancelIcon />
        </StyledToolbarItemBS>
      </div>
    </StyledToolbar>
  </StyledDisplayToolbar>
));

DisplayToolbar.propTypes = {
  formula: PropTypes.arrayOf(PropTypes.string).isRequired,
  input: PropTypes.string.isRequired,
  isShowHistory: PropTypes.bool.isRequired,
  onBackspace: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
};

export default DisplayToolbar;
