import React from 'react';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.doc.financingApplicationReversal.tables.general.columns;

const Months = [...Array(12).keys()];

function HeaderComponent({ activeAreaName }) {
  const sumColumns = Months.reduce((r, i, m) => ([...r,
    <DivHeader
      key={`s${m + 1}`}
      areaName={`s${m + 1}`}
      isActive={`s${m + 1}` === activeAreaName}
    >
      {coldef[`Sum${(m + 1)}`].label}
    </DivHeader>,
  ]), []);

  return (
    <GridTemplate
      activeAreaName={activeAreaName}
      isHeader
    >
      <DivHeader areaName="num">{coldef.num.label}</DivHeader>
      <DivHeader areaName="selection">{coldef.selection.label}</DivHeader>

      <DivHeader areaName="document">{coldef.document.label}</DivHeader>
      <DivHeader areaName="period">{coldef.period.label}</DivHeader>

      <DivHeader
        areaName="budget"
        isActive={(activeAreaName === 'budget')}
      >
        {coldef.budget.label}
      </DivHeader>
      <DivHeader
        areaName="FI"
        isActive={(activeAreaName === 'FI')}
      >
        {coldef.FI.label}
      </DivHeader>
      <DivHeader
        areaName="CSU"
        isActive={(activeAreaName === 'CSU')}
      >
        {coldef.CSU.label}
      </DivHeader>

      <DivHeader areaName="KPKV">{coldef.KPKV.label}</DivHeader>
      <DivHeader areaName="KFK">{coldef.KFK.label}</DivHeader>
      <DivHeader areaName="KEKV">{coldef.KEKV.label}</DivHeader>

      <DivHeader
        areaName="fondObject"
        isActive={(activeAreaName === 'fondObject')}
      >
        {coldef.fondObject.label}
      </DivHeader>
      <DivHeader
        areaName="elementKEKV"
        isActive={(activeAreaName === 'elementKEKV')}
      >
        {coldef.elementKEKV.label}
      </DivHeader>
      <DivHeader
        areaName="elementKFK"
        isActive={(activeAreaName === 'elementKFK')}
      >
        {coldef.elementKFK.label}
      </DivHeader>

      <DivHeader areaName="year">{coldef.year.label}</DivHeader>
      <DivHeader areaName="note">{coldef.note.label}</DivHeader>

      <DivHeader
        areaName="currentAccountFI"
        isActive={(activeAreaName === 'currentAccountFI')}
      >
        {coldef.currentAccountFI.label}
      </DivHeader>
      <DivHeader
        areaName="currentAccountCSU"
        isActive={(activeAreaName === 'currentAccountCSU')}
      >
        {coldef.currentAccountCSU.label}
      </DivHeader>
      <DivHeader areaName="signMainMovement">{coldef.signMainMovement.label}</DivHeader>

      <DivHeader areaName="sum">{coldef.Sum.label}</DivHeader>
      {sumColumns}
    </GridTemplate>
  );
}

HeaderComponent.propTypes = {
  activeAreaName: PropTypes.string,
};

HeaderComponent.defaultProps = {
  activeAreaName: '',
};

export default HeaderComponent;
