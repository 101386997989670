import React, { useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Immutable, { OrderedMap } from 'immutable';
import { StyledField } from '../styled';
import { comparisonTypes } from '../../../../../constants/meta/enums/DataComposition/comparisonTypes';
import Modal from '../../../../../components/modal';
import AppearanceFilter from './appearanceFilter';

function FilterField({
  value, mountPoint, rowKey,
}) {
  const [opened, setOpened] = useState(false);

  const displayValue = useMemo(
    () => value.filter((v) => v.get('Use'))
      .reduce((R, v) => {
        const lv = v.get('LeftValue');
        const ct = Object.values(comparisonTypes)
          .filter((c) => c.value === v.get('ComparisonType'))
          .reduce((_, r) => r.label, '???');

        // eslint-disable-next-line no-underscore-dangle
        const rv_ = v.get('RightValue');
        let rv;
        if (Immutable.isMap(rv_)) {
          rv = rv_.get('repr');
        } else {
          rv = rv_;
        }
        return [...R, `${lv} ${ct} ${rv}`];
      }, []).join(', '),
    [value],
  );
  return (
    <>
      <StyledField>
        <div>{displayValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Умова" closeIcon opened={opened} onClose={() => setOpened(false)} size="middle">
        <AppearanceFilter
          mountPoint={mountPoint}
          rowKey={rowKey}
        />
      </Modal>
    </>
  );
}

FilterField.propTypes = {
  value: PropTypes.instanceOf(OrderedMap).isRequired,
  mountPoint: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired,
};

export default memo(FilterField);
