import React, { useState } from 'react';
import { Map } from 'immutable';
import Widget from './widget';

import {
  DivStyledUpload,
  FileInput,
  ContainerFile,
  FilesContainer,
  ContainerHeader,
} from '../../../components/uploadFiles/styles';
import { Title, SubTitle } from '../../../components/Form/styledForm';
import File from '../../notifier/file';

function DigitalSing() {
  const [choiceFiles, setChoiceFiles] = useState([]);

  const scanFiles = (item, callback) => {
    if (item.isDirectory) {
      const DR = item.createReader();
      DR.readEntries((items) => {
        items.map((i) => this.scanFiles(i, callback));
      });
    } else {
      callback(item);
    }
  };

  const addToSatte = (choicesF) => {
    choicesF.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setChoiceFiles([new Map()
          .setIn(['file', 'content'], reader.result)
          .setIn(['file', 'name'], file.file.name)
          .set('description', file.file.name)
          .set('name', file.file.name)
          .setIn(['file', 'file'], file.file)
          .set('type', file.file.type)
          .setIn(['file', 'type'], file.file.type), ...choiceFiles]);
      };

      if (file.file) {
        reader.readAsDataURL(file.file);
      }
    });
  };

  const fileLoadHandler = (e) => {
    const { items } = e.dataTransfer;
    const fileGetter = async (file) => [file];

    e.preventDefault();
    let choicesF = [];
    Object.values(items).forEach((i) => scanFiles(i.webkitGetAsEntry(), (item) => {
      item.file((file) => {
        fileGetter(file).then((files) => {
          if (files) {
            choicesF = [...choicesF, ...files.filter((ff) => !!ff).map((fl, ii) => ({
              file: fl,
              done: false,
              task: ii,
            }))];

            addToSatte(choicesF);
          }
        });
      });
    }));
  };

  const handleLoadFile = (e) => {
    const files = [...e.target.files];

    Promise.all(files.map((f) => f)).then((results) => {
      const choicesF = results.reduce(
        (R, ffs) => [
          ...R,
          ...[{
            file: ffs,
          }],
        ],
        [],
      );
      addToSatte(choicesF);
    });
  };

  // SignInternal накладываем электронную подпись

  return (
    <>
      <Widget />

      <ContainerFile
        onDragOver={(e) => e.preventDefault()}
        onDrop={fileLoadHandler}
        noStyle
      >
        <ContainerHeader>
          <Title>Обрання файла</Title>
        </ContainerHeader>

        <FilesContainer>
          {choiceFiles.map((file, ind) => (
            <File
              key={file.name}
              file={file}
              isLocked={false}
              onDeleteFile={() => setChoiceFiles(choiceFiles.splice(ind, 1))}
            />
          ))}
        </FilesContainer>

        <div>
          <SubTitle>
            Оберіть файл з для підпису, або перетяніть його до блакитної зони
          </SubTitle>
          <DivStyledUpload>
            <FileInput
              onChange={handleLoadFile}
            />
            <span>Оберіть файл</span>
          </DivStyledUpload>
        </div>
      </ContainerFile>

    </>

  );
}

export default DigitalSing;
