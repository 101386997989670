/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import corner4 from '../../../assets/img/illustrations/corner-4.png';
import Background from './Background';
import { childrenPropType } from '../../../constants/backend/propTypes';

function PageHeader({
  subtitle,
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  ...rest
}) {
  return (
    <div>
      <Card {...rest}>
        <Background
          image={image}
          className="bg-card d-none d-sm-block"
          style={{
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
            position: 'absolute',
          }}
        />
        <Card.Body className="position-relative">
          <Row>
            <Col
              {...col}
              className="cardHeaderWidth"
            >
              {preTitle && <h6 className="text-600">{preTitle}</h6>}
              <TitleTag
                className="mb-0"
              >
                {title}
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                {!subtitle && (children)}
              </TitleTag>
              {subtitle && (children)}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

PageHeader.propTypes = {
  subtitle: childrenPropType,
  title: PropTypes.string,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

PageHeader.defaultProps = {
  col: { lg: 8 },
  image: corner4,
  titleTag: 'h3',
  subtitle: null,
  title: null,
};

export default PageHeader;
