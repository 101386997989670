import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  DateInput,
  ItemPicker,
  NumberInput, StringInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/investmentProposalObjectsChanges';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const objectsBuildingFIlter = useMemo(
    () => [{ fieldName: 'Заказчик', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="objectsBuilding" highlighted={highlights.includes('objectsBuilding')}>
              <ItemPicker
                value={row[tableMD.columns.objBuilding.name]}
                modelType="cat"
                modelName="objectsBuilding"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.objBuilding.name]: v })}
                filter={objectsBuildingFIlter}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="beginningDate" highlighted={highlights.includes('beginningDate')}>
              <DateInput
                value={row[tableMD.columns.beginningDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.beginningDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="endingDate" highlighted={highlights.includes('endingDate')}>
              <DateInput
                value={row[tableMD.columns.endingDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.endingDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="finSource" highlighted={highlights.includes('finSource')}>
              <ItemPicker
                value={row[tableMD.columns.finSource.name]}
                modelType="cat"
                modelName="objBuildingSourceFin"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.finSource.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableRowCell column="estimateSum" highlighted={highlights.includes('estimateSum')}>
          <NumberInput
            value={row[tableMD.columns.estimateSum.name]}
            precision={2}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.estimateSum.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="totalSum" highlighted={highlights.includes('totalSum')}>
              <NumberInput
                value={row[tableMD.columns.totalSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.totalSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="yearSum" highlighted={highlights.includes('yearSum')}>
              <NumberInput
                value={row[tableMD.columns.yearSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.yearSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="nextYearSum" highlighted={highlights.includes('nextYearSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.nextYearSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nextYearCriticalSum" highlighted={highlights.includes('nextYearCriticalSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearCriticalSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.nextYearCriticalSum.name]: v,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nextYearAddSum" highlighted={highlights.includes('nextYearAddSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearAddSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.nextYearAddSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="predictionSum1" highlighted={highlights.includes('predictionSum1')}>
              <NumberInput
                value={row[tableMD.columns.predictionSum1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.predictionSum1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="predictionSum2" highlighted={highlights.includes('predictionSum2')}>
              <NumberInput
                value={row[tableMD.columns.predictionSum2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.predictionSum2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="deadlines" highlighted={highlights.includes('deadlines')}>
              <StringInput
                value={row[tableMD.columns.deadlines.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.deadlines.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="designer" highlighted={highlights.includes('designer')}>
              <StringInput
                value={row[tableMD.columns.designer.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.designer.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="contractor" highlighted={highlights.includes('contractor')}>
              <StringInput
                value={row[tableMD.columns.contractor.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.contractor.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableRowCell column="note" highlighted={highlights.includes('note')}>
          <TextInput
            value={row[tableMD.columns.note.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.note.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.objBuilding.name]: referencePropType,
    [tableMD.columns.finSource.name]: referencePropType,
    [tableMD.columns.contractor.name]: PropTypes.string,
    [tableMD.columns.estimateSum.name]: PropTypes.number,
    [tableMD.columns.executionSum.name]: PropTypes.number,
    [tableMD.columns.designer.name]: PropTypes.string,
    [tableMD.columns.yearSum.name]: PropTypes.number,
    [tableMD.columns.nextYearSum.name]: PropTypes.number,
    [tableMD.columns.totalSum.name]: PropTypes.number,
    [tableMD.columns.nextYearCriticalSum.name]: PropTypes.number,
    [tableMD.columns.nextYearAddSum.name]: PropTypes.number,
    [tableMD.columns.predictionSum1.name]: PropTypes.number,
    [tableMD.columns.predictionSum2.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
