import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { changeField } from '../../../../../actions/dpEditor';
import GridTemplate from './GridTemplate';
import { DivCell, InputCodeStyled } from '../../../../../components/styledTable/styledTable';
import Fields from '../../../../field';

const TABLENAME = 'tpDoc';

function RowComponent({
  row, rowId, dispatch, FI,
  ...props
}) {
  return (
    <GridTemplate {...props}>
      <DivCell areaName="mark">
        <Fields.CheckboxField
          value={row.get('mark', false)}
          onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'mark'], v))}
        />
      </DivCell>
      <DivCell areaName="list">
        <InputCodeStyled
          noBackground
          noBorder
          value={row.get('list', '')}
          readOnly
        />
      </DivCell>
    </GridTemplate>
  );
}

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  FI: PropTypes.instanceOf(Map).isRequired,
};

export default RowComponent;
