import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/registrationForm';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import CSUDataTP from './tps/csudata';

const ROLES_VALUES = [
  { display_name: 'МФО', value: '01' },
  { display_name: 'ГРК', value: '02' },
  { display_name: 'РК', value: '03' },
  { display_name: 'ОК', value: '05' },
];

function RegistrationForm({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const readOnly = !permissions.canChange || data[md.columns.isApprovedLogica.name];
  const ownerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: data[md.columns.FI.name],
    }],
    [data],
  );
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      {readOnly
            && (
              <Alert variant="warning" className="mb-0 mx-auto">
                {data[md.columns.receivedLogica.name]}
              </Alert>
            )}
      <Row cols={3}>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.LocalCode.label}
            value={data[md.columns.LocalCode.name]}
            maxLength={15}
            onChange={(e, value) => actions.onChange({
              [md.columns.LocalCode.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="general"
        className="m-0 mt-2 border-bottom"
      >
        <Tab title="Загальні відомості" eventKey="general">
          <h4 className="text-primary mt-4">І. Загальні відомості</h4>
          <Row cols={3}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.EDRPOU.label}
                value={data[md.columns.EDRPOU.name]}
                maxLength={10}
                onChange={(e, value) => actions.onChange({
                  [md.columns.EDRPOU.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Name.label}
                value={data[md.columns.Name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Name.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.CodeDKSU.label}
                value={data[md.columns.CodeDKSU.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.CodeDKSU.name]: value,
                })}
                readOnly={readOnly}
                maxLength={6}
              />
            </Col>
          </Row>
          <Row cols={4}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.KOATUU.label}
                value={data[md.columns.KOATUU.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KOATUU.name]: value,
                })}
                readOnly={readOnly}
                maxLength={10}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.KATOTG.label}
                value={data[md.columns.KATOTG.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KATOTG.name]: value,
                })}
                readOnly={readOnly}
                maxLength={19}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PostCode.label}
                value={data[md.columns.PostCode.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PostCode.name]: value,
                })}
                readOnly={readOnly}
                maxLength={6}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Address.label}
                value={data[md.columns.Address.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Address.name]: value,
                })}
                readOnly={readOnly}
                maxLength={50}
              />
            </Col>
          </Row>
          <Row cols={5}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PhoneNumber.label}
                value={data[md.columns.PhoneNumber.name]}
                maxLength={15}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PhoneNumber.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Email.label}
                value={data[md.columns.Email.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Email.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.WebSite.label}
                value={data[md.columns.WebSite.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.WebSite.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Skype.label}
                value={data[md.columns.Skype.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Skype.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.SelectorInput
                label={md.columns.Role.label}
                value={data[md.columns.Role.name]}
                values={ROLES_VALUES}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Role.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <h4 className="text-primary">
              {md.columns.Director.label}
            </h4>
            <Col md={3}>
              <EditorControls.ItemPicker
                value={data[md.columns.Director.name]}
                modelType="cat"
                modelName="officials"
                onChange={(e, value) => actions.onChange({
                  [md.columns.Director.name]: value,
                })}
                filter={ownerFilter}
                readOnly={readOnly}
                noHierarchy
              />
            </Col>
          </Row>
          <Row md={4}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Position.label}
                value={data[md.columns.Position.name]}
                maxLength={150}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Position.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Surname.label}
                value={data[md.columns.Surname.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Surname.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.nameDirector.label}
                value={data[md.columns.nameDirector.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.nameDirector.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.MiddleName.label}
                value={data[md.columns.MiddleName.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.MiddleName.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PhoneNumberDirectorWork.label}
                value={data[md.columns.PhoneNumberDirectorWork.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PhoneNumberDirectorWork.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PhoneNumberDirectorMob.label}
                value={data[md.columns.PhoneNumberDirectorMob.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PhoneNumberDirectorMob.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.DirectorEmail.label}
                value={data[md.columns.DirectorEmail.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DirectorEmail.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.reasonDirector.label}
                value={data[md.columns.reasonDirector.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.reasonDirector.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <h4 className="text-primary">
              {md.columns.DirectorFs.label}
            </h4>
            <Col md={3}>
              <EditorControls.ItemPicker
                value={data[md.columns.DirectorFs.name]}
                modelType="cat"
                modelName="officials"
                onChange={(e, value) => actions.onChange({
                  [md.columns.DirectorFs.name]: value,
                })}
                filter={ownerFilter}
                readOnly={readOnly}
                noHierarchy
              />
            </Col>
          </Row>
          <Row md={4}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PositionFin.label}
                value={data[md.columns.PositionFin.name]}
                maxLength={150}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PositionFin.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.SurnameFs.label}
                value={data[md.columns.SurnameFs.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.SurnameFs.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.nameDirectorFs.label}
                value={data[md.columns.nameDirectorFs.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.nameDirectorFs.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.MiddleNameFs.label}
                value={data[md.columns.MiddleNameFs.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.MiddleNameFs.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PhoneNumberFsWork.label}
                value={data[md.columns.PhoneNumberFsWork.name]}
                maxLength={15}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PhoneNumberFsWork.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PhoneNumberFsMob.label}
                value={data[md.columns.PhoneNumberFsMob.name]}
                maxLength={15}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PhoneNumberFsMob.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.DirectorFsEmail.label}
                value={data[md.columns.DirectorFsEmail.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DirectorFsEmail.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.reasonDirectorFs.label}
                value={data[md.columns.reasonDirectorFs.name]}
                maxLength={50}
                onChange={(e, value) => actions.onChange({
                  [md.columns.reasonDirectorFs.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <h4 className="text-primary">
            IV. Установа, що здійснює функцію
            виконавчого органу
          </h4>
          <Row md={2}>
            <Col>
              <EditorControls.StringInput
                label={md.columns.EDRPOU_FO.label}
                value={data[md.columns.EDRPOU_FO.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.EDRPOU_FO.name]: value,
                })}
                readOnly={readOnly}
                maxLength={10}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.ExecutiveName.label}
                value={data[md.columns.ExecutiveName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ExecutiveName.name]: value,
                })}
                readOnly={readOnly}
                maxLength={50}
              />
            </Col>
          </Row>
        </Tab>
        <Tab
          title="Дані за розпорядниками"
          eventKey="csuData"
        >
          <CSUDataTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
        <Tab title="Примітка" eventKey="note">
          <div>
            <EditorControls.TextInput
              label={md.columns.note.label}
              value={data[md.columns.note.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.note.name]: value,
              })}
              readOnly={readOnly}
            />
          </div>
        </Tab>
      </Tabs>
    </EditorCollapse>
  );
}
RegistrationForm.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.LocalCode.name]: PropTypes.string,
    [md.columns.FI.name]: referencePropType,
    [md.columns.author.name]: referencePropType,
    [md.columns.EDRPOU.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default RegistrationForm;
