import React, {
  memo, useCallback,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
  TextInput,
  CheckboxInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'sourcesInvestmentProjects';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row className="w-100" noGutters>
          <Col sm={1}>
            <TableRowCell column="fond" highlighted={highlights.includes('fond')}>
              <CheckboxInput
                value={row[tableMD.columns.fond.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.fond.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="targetedPrograms" highlighted={highlights.includes('targetedPrograms')}>
                  <ItemPicker
                    value={row[tableMD.columns.targetedPrograms.name]}
                    modelType="cat"
                    modelName="targetedPrograms"
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.targetedPrograms.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableRowCell column="objectsBuilding" highlighted={highlights.includes('objectsBuilding')}>
                  <ItemPicker
                    value={row[tableMD.columns.objectsBuilding.name]}
                    modelType="cat"
                    modelName="objectsBuilding"
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.objectsBuilding.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>

          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="kdbClassifier" highlighted={highlights.includes('kdbClassifier')}>
                  <ItemPicker
                    value={row[tableMD.columns.kdbClassifier.name]}
                    modelType="cat"
                    modelName="kdbClassifier"
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.kdbClassifier.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableRowCell column="kfbClassifier" highlighted={highlights.includes('kfbClassifier')}>
                  <ItemPicker
                    value={row[tableMD.columns.kfbClassifier.name]}
                    modelType="cat"
                    modelName="kfbClassifier"
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.kfbClassifier.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100" noGutters>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sumCashTotal" highlighted={highlights.includes('sumCashTotal')}>
                  <NumberInput
                    value={row[tableMD.columns.sumCashTotal.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumCashTotal.name]: value,
                    })}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumCashGenF" highlighted={highlights.includes('sumCashGenF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumCashGenF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumCashGenF.name]: value,
                      [tableMD.columns.sumCashTotal.name]: value
                      + (row[tableMD.columns.sumCashSpecF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="sumCashSpecF" highlighted={highlights.includes('sumCashSpecF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumCashSpecF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumCashSpecF.name]: value,
                      [tableMD.columns.sumCashTotal.name]: value
                      + (row[tableMD.columns.sumCashGenF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sumPlanTotal" highlighted={highlights.includes('sumPlanTotal')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPlanTotal.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPlanTotal.name]: value,
                    })}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumPlanGenF" highlighted={highlights.includes('sumPlanGenF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPlanGenF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPlanGenF.name]: value,
                      [tableMD.columns.sumPlanTotal.name]: value
                      + (row[tableMD.columns.sumPlanSpecF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="sumPlanSpecF" highlighted={highlights.includes('sumPlanSpecF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPlanSpecF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPlanSpecF.name]: value,
                      [tableMD.columns.sumPlanTotal.name]: value
                      + (row[tableMD.columns.sumPlanGenF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sumPrognosisTotal" highlighted={highlights.includes('sumPrognosisTotal')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPrognosisTotal.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPrognosisTotal.name]: value,
                    })}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumPrognosisGenF" highlighted={highlights.includes('sumPrognosisGenF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPrognosisGenF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPrognosisGenF.name]: value,
                      [tableMD.columns.sumPrognosisTotal.name]: value
                      + (row[tableMD.columns.sumPrognosisSpecF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="sumPrognosisSpecF" highlighted={highlights.includes('sumPrognosisSpecF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumPrognosisSpecF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumPrognosisSpecF.name]: value,
                      [tableMD.columns.sumPrognosisTotal.name]: value
                      + (row[tableMD.columns.sumPrognosisGenF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="noteTemplate" highlighted={highlights.includes('noteTemplate')}>
              <ItemPicker
                value={row[tableMD.columns.noteTemplate.name]}
                modelType="cat"
                modelName="explanations"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.noteTemplate.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <TextInput
                value={row[tableMD.columns.note.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.note.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
