import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Орган місцевого самоврядування', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  text1: { label: 'Номер', name: 'Номер' },
  text2: { label: 'Дата прийняття', name: 'ДатаПрийняття' },
  text3: { label: 'Дата набрання чинності', name: 'ДатаНабранняЧинності' },
  text4: { label: 'Дата, з якої застосовуються ставки', name: 'ДатаЗЯкоїЗастосовуютьсяСтавки' },
};

/**
 * @const
 */
const definition = {
  name: 'taxes4Print',
  backendName: 'ПечатьНалогиИЛьготыДод4',
  label: 'Друк',
  frontend: 'dp/taxes4Print',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
