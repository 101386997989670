import React from 'react';
import Editor from './defaultEditor';

function RevenueExchange() {
  return (
    <Editor
      title="Завантажити фактичні доходи"
      backendUrl="/api/cityplans/revenuedbf/"
    />
  );
}

export default RevenueExchange;
