import React from 'react';
import WinManagerContext from './context';
import useWinManager from '../../../components/windowsManager/Hooks/useWinManager';
import { childrenPropType } from '../../constants/backend/propTypes';

function WinManagerContextProvider({ children }) {
  const winManager = useWinManager();
  return (
    <WinManagerContext.Provider value={winManager}>
      {children}
    </WinManagerContext.Provider>
  );
}

WinManagerContextProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default WinManagerContextProvider;
