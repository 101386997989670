import React from 'react';

function StarIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.46534 15.2446C9.17402 15.0915 8.82598 15.0915 8.53466 15.2446L4.91562 17.1473C4.18199 17.533 3.32456 16.91 3.46467 16.0931L4.15585 12.0632C4.21148 11.7389 4.10393 11.4079 3.86825 11.1781L0.940384 8.32416C0.346867 7.74562 0.674377 6.73765 1.4946 6.61846L5.54081 6.03051C5.86652 5.98319 6.14808 5.77862 6.29374 5.48347L8.10326 1.81699C8.47008 1.07374 9.52992 1.07374 9.89674 1.81699L11.7063 5.48347C11.8519 5.77862 12.1335 5.98319 12.4592 6.03051L16.5054 6.61846C17.3256 6.73765 17.6531 7.74562 17.0596 8.32416L14.1318 11.1781C13.8961 11.4079 13.7885 11.7389 13.8442 12.0632L14.5353 16.0931C14.6754 16.91 13.818 17.533 13.0844 17.1473L9.46534 15.2446Z" fill="white" stroke="#FFB827" />
    </svg>
  );
}

export default StarIcon;
