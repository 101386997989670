/* eslint-disable max-len,no-underscore-dangle */
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import api, { initReq } from '../../../../api/req';
import { useMD } from '../md';
import {
  comparisonTypes, emptyUid, hierarchyTypes, saveModes,
} from '../../../../constants/meta/common';
import useSettings from './settings';
import useMessages from './messages';
import { soSelector } from '../../../documents/_common/selectors';

/*
  Количество объектов на одной странице
 */
const PAGINATION_COUNT = 100;

/**
 *
 * @param page
 * @param modelType
 * @param useListLazyLoad
 * @returns {{}|{start: number, end: number}}
 */
function getLLs(page = 1, modelType, useListLazyLoad) {
  if (!useListLazyLoad) return {};
  if (modelType === 'doc') {
    return {
      start: -(page * PAGINATION_COUNT) + 1,
      end: -((page - 1) * PAGINATION_COUNT),
    };
  }
  if (modelType === 'cat') {
    return {
      start: (page - 1) * PAGINATION_COUNT,
      end: (page * PAGINATION_COUNT) - 1,
    };
  }
  if (modelType === 'infoRegs') {
    return {
      start: (page - 1) * PAGINATION_COUNT,
      end: (page * PAGINATION_COUNT) - 1,
    };
  }
  return {};
}

/**
 *
 * @param modelType {string}
 * @param modelName {string}
 * @param viewType {string}
 * @param filter {{}}
 * @param order {[{}]}
 * @param searchString {string}
 * @param noHierarchy {boolean}
 * @param params {[{}]}
 * @returns {{
 * meta: *,
 * visibleColumns: {
 *   name: string,
 *   label: string,
 *   key: string,
 *   type: string,
 * },
 * items: [{ id: string, deleted: boolean, executed: boolean }],
 * loading: boolean,
 * err: string,
 * pageInfo: {
 *  current: {
 *   currentPage: number,
 *   allLoaded: boolean,
 *   parents: string[],
 * }},
 * settings: {},
 * onReload: function,
 * onReloadWithCacheReset: function,
 * onNextPage: function,
 * onExecute: function,
 * onUnExecute: function,
 * permissions: {
 *   canNew: boolean,
 *   canEdit: boolean,
 *   canCopy: boolean,
 *   canDelete: boolean,
 *   canExecute: boolean,
 *   canUnexecute: boolean,
 * },
 * onDeleteItems: function,
 * onLoadSettings: function,
 * onSaveSettings: function,
 * loadPath: function,
 * onApproveItems: function,
 * onUnApproveItems: function,
 * onApproveStatus: function,
 * onApproveHistory: function,
 * approveStatus: {mode: number, status: {}, history: {}},
 * onApproveReset: function,
 * onCloseApproveStatus,
 * goToOldVersion: function,
 * messages: { title: string, text: string, variant: string }[],
 * clearMessages: function,
 * deleteMessage: function,
 * }}
 */

const useListerBackend = ({
  modelType, modelName, viewType,
  filter, order, searchString, noHierarchy, params,
}) => {
  // eslint-disable-next-line camelcase
  const so = useSelector(soSelector);
  const isAdmin = so.get('is_admin', false);
  const meta = useMD(modelType, modelName);

  const visibleColumns = useMemo(
    () => meta.listColumns
      .filter((c) => meta.columns[c].visible)
      .map((c) => ({ ...meta.columns[c], key: c })),
    [meta.columns, meta.listColumns],
  );

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [items, setItems] = useState([]);
  // состояние подписи документа (указанного)
  const [approveStatus, setApproveStatus] = useState({ results: {}, mode: null });

  const {
    messages, clearMessages, addMessage, deleteMessage,
  } = useMessages();
  // Текущая страница загрузки данных
  const pageInfo = useRef({
    currentPage: 1,
    allLoaded: false,
    parents: [emptyUid],
  });

  const {
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,

  } = useSettings(modelType, modelName, viewType, setLoading, setErr);

  const loadRequestController = useRef(null);

  const loadPath = useCallback(
    (id, repr, onSucces) => {
      const asyncLoader = async () => {
        const response = await api.post$(`${modelType}/${meta.backendName}/${id}/getPath`);
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Помилка при завантаженні шляху до  ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);

      if (modelType !== 'doc') {
        asyncLoader()
          .then((data) => onSucces(data))
          .catch((e) => setErr(e.message));
      } else {
        onSucces([id]);
      }
    },

    [meta, modelType],
  );

  const load = useCallback(
    (localParams) => {
      const asyncLoader = async () => {
        const lls = getLLs(localParams.page, modelType, meta.useListLazyLoad);
        loadRequestController.current = new AbortController();
        const response = await api.post$(`${modelType}/${meta.backendName}`, {
          selected_fields: meta.listColumns.map((c) => ({ name: meta.columns[c].name })),
          order: localParams.order,
          filter: localParams.filter,
          searchString: localParams.search,
          llSettings: lls,
          NoHierarchy: localParams.noHierarchy,
          useFoldersLazyLoad: !searchString && meta.useFoldersLazyLoad,
          params: localParams.params,
        }, loadRequestController.current);
        if (response.ok) {
          const data = await response.json();
          // eslint-disable-next-line no-underscore-dangle
          return Object.values(data).sort((a, b) => a._OrderNo - b._OrderNo);
        }
        throw new Error(`Помилка при завантаженні списку ${meta.label} (${response.status} ${response.statusText})`);
      };
      if (loadRequestController.current) loadRequestController.current.abort();
      setLoading(true);
      setErr(null);
      asyncLoader()
        .then((data) => setItems((oldData) => {
          setLoading(false);
          pageInfo.current = {
            ...pageInfo.current,
            currentPage: localParams.page,
            allLoaded: data.length < PAGINATION_COUNT,
          };
          if (localParams.clear) return data;
          const existedKeys = new Set(oldData.map((r) => r.id));
          if (modelType === 'doc' || modelType === 'infoRegs') {
            return [
              ...data.filter((r) => !existedKeys.has(r.id)),
              ...oldData,
            ];
          }
          return [
            ...oldData,
            ...data.filter((r) => !existedKeys.has(r.id)),
          ];
        }))
        .catch((e) => {
          if (e instanceof DOMException) {
            console.debug('aborted');
          } else {
            setErr(e.message);
            setLoading(false);
          }
        });
    },
    [meta.backendName, meta.columns, meta.label, meta.listColumns, meta.useFoldersLazyLoad, meta.useListLazyLoad, modelType, searchString],
  );

  const onReload = useCallback(
    () => {
      if (meta.useFoldersLazyLoad && !searchString && !noHierarchy) {
        load({
          filter: [
            ...filter,
            {
              fieldName: 'parent',
              comparisonType: comparisonTypes.inList,
              value: pageInfo.current.parents.map((id) => ({ id })),
            },
          ],
          order,
          page: 1,
          clear: true,
          search: searchString,
          noHierarchy,
          params,
        });
      } else {
        load({
          filter, order, page: 1, clear: true, search: searchString, noHierarchy, params,
        });
      }
    },
    [meta.useFoldersLazyLoad, searchString, noHierarchy, load, filter, order, params],
  );

  const onReloadWithCacheReset = useCallback(
    () => {
        const asyncLoader = async () => {
          const response = await initReq('post','api/user-cache/clear_user_cache', {
            type: modelType,
            name: meta.backendName,
          }, null);
          if (response.ok) {
            return true;
          }
          throw new Error(`Помилка при скидуванні кешу (${response.status} ${response.statusText})`);
        }
        setErr(null);
        asyncLoader()
          .then(onReload)
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false))
    },
    [meta.backendName, modelType, onReload]
  )

  const onNextPage = useCallback(
    () => {
      if (!pageInfo.current.allLoaded) {
        load({
          filter, order, page: pageInfo.current.currentPage + 1, clear: false, search: searchString, noHierarchy, params,
        });
      }
    },
    [filter, load, order, params, searchString, noHierarchy],
  );

  const onLoadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents.push(parentId);
      load({
        filter: [
          ...filter,
          { fieldName: 'parent', value: parentId },
        ],
        order,
        page: pageInfo.current.currentPage,
        clear: false,
        search: searchString,
        noHierarchy,
        params,
      });
    },
    [filter, load, order, searchString, noHierarchy, params],
  );

  const onUnloadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents = pageInfo.current.parents.filter((p) => p !== parentId);
      setItems((oi) => oi.filter(
        (item) => item.parentId === '' || pageInfo.current.parents.includes(item.parentId),
      ));
    },
    [],
  );

  const onDeleteItems = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const del = async (id, repr) => {
        const response = await api.delete$(`${modelType}/${meta.backendName}/${id}/`);
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => del(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [meta.backendName, modelType, onReload],
  );

  const onApproveItems = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const res = async (id, repr) => {
        const response = await api
          .put$(`${modelType}/${meta.backendName}/${id}/sign/`);
        if (response.ok) {
          const d = await response.json();
          return {
            [id]: {
              repr,
              status: d,
            },
          };
        }

        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка підписання документа ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка підписання документа ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((id) => res(id, reprs[id])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          const successResults = results.filter((r) => r.status === 'fulfilled');

          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            successResults.forEach((sr) => Object.keys(sr.value).forEach((id) => addMessage({
              text: sr.value[id].status.messages.join(', '),
              title: sr.value[id].repr,
              variant: sr.value[id].status.ok ? 'success' : 'warning',
            })));
            onReload();
          }
        });
    },
    [addMessage, meta.backendName, modelType, onReload],
  );

  const onUnApproveItems = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const res = async (id, repr) => {
        const response = await api
          .delete$(`${modelType}/${meta.backendName}/${id}/sign/`);
        if (response.ok) {
          const d = await response.json();
          return {
            [id]: {
              repr,
              status: d,
            },
          };
        }

        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка підписання документа ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка підписання документа ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((id) => res(id, reprs[id])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          const successResults = results.filter((r) => r.status === 'fulfilled');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            successResults.forEach((sr) => Object.keys(sr.value).forEach((id) => addMessage({
              text: sr.value[id].status.messages.join(', '),
              title: sr.value[id].repr,
              variant: sr.value[id].status.ok ? 'success' : 'warning',
            })));
            onReload();
          }
        });
    },
    [addMessage, meta.backendName, modelType, onReload],
  );

  const onApproveStatus = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const res = async (id, repr) => {
        const response = await api
          .get$(`${modelType}/${meta.backendName}/${id}/sign/`);
        if (response.ok) {
          const d = await response.json();
          return {
            [id]: {
              repr,
              status: d,
            },
          };
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка отримання статусу документа ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка отримання статусу ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => res(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          const successResults = results.filter((r) => r.status === 'fulfilled');
          setLoading(false);
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
          } else {
            setApproveStatus({
              mode: 1, // Статус
              results: successResults.reduce((R, r) => ({ ...R, ...r.value }), {}),
            });
          }
        });
    },
    [meta.backendName, modelType],
  );

  const onApproveHistory = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const res = async (id, repr) => {
        const response = await api
          .post$(`${modelType}/${meta.backendName}/${id}/sign/`);
        if (response.ok) {
          const d = await response.json();
          return {
            [id]: {
              repr,
              history: d,
            },
          };
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка отримання історії підписання документа ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка отримання історії підписання документа ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => res(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          const successResults = results.filter((r) => r.status === 'fulfilled');
          setLoading(false);
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
          } else {
            setApproveStatus({
              mode: 2, // Статус
              results: successResults.reduce((R, r) => ({ ...R, ...r.value }), {}),
            });
          }
        });
    },
    [meta.backendName, modelType],
  );

  const onApproveReset = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const res = async (id, repr) => {
        const response = await api
          .patch(`${modelType}/${meta.backendName}/${id}/sign/`);
        if (response.ok) {
          return true;
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка підписання документа ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка підписання документа ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => res(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          setLoading(false);
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
          } else {
            onReload();
          }
        });
    },
    [meta.backendName, modelType, onReload],
  );

  const onExecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const del = async (id, repr) => {
        const response = await api.put$(`${modelType}/${meta.backendName}/${id}/`, { savemode: saveModes.Posting });
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка при проведенні ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка при проведенні ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => del(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [meta.backendName, modelType, onReload],
  );

  const onUnexecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const del = async (id, repr) => {
        const response = await api.put$(`${modelType}/${meta.backendName}/${id}/`, { savemode: saveModes.UndoPosting });
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка при разпроведенні ${repr}: ${d._Error}`);
        }
        throw new Error(`Помилка при разпроведенні ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => del(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [meta.backendName, modelType, onReload],
  );

  const onCloseApproveStatus = useCallback(
    () => setApproveStatus(({ results }) => ({ mode: null, results })),
    [],
  );

  const goToOldVersion = useCallback(
    () => {
      const getOldVersionLink = async () => {
        const url = `${modelType}/${meta.backendName}`;
        const r = await api.get$(url);
        if (!r.ok) {
          let e;
          try {
            e = await r.text();
          } catch {
            e = `${r.status} ${r.statusText}`;
          }
          throw new Error(e);
        }
        return r.json();
      };
      setLoading(true);
      setErr(null);
      getOldVersionLink()
        .then((d) => {
          let e;
          try {
            window.open(d.oldVersionRef, '_blank').focus();
          } catch {
            e = 'В адресній строці необхідно дозволити відкривання нових вкладок';
          }
          throw new Error(e);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [meta.backendName, modelType],
  );

  const onLogicaSignItems = useCallback(
    /**
     *
     * @param signingByIds {string[]}
     * @param activeUnsignedPeople string
     */
    (signingByIds, activeUnsignedPeople) => {
      const res = async () => {
        const response = await api
          .put$('logica/groupSign/logicaSign', {
            signedItems: signingByIds,
            signer: activeUnsignedPeople,
          });
        if (response.ok) {
          const d = await response.json();
          return d;
        }

        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка підписання документів : ${d._Error}`);
        }
        throw new Error(`Помилка підписання документів (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      res()
        .then((results) => {
          addMessage({
            text: results.docs.join('; '),
            title: 'Підпис документів для сервісу Logica',
            variant: 'success',
          });
          onReload();
          setLoading(false);
        });
    },
    [addMessage, onReload],
  );

  const onLogicaSendItems = useCallback(
    /**
     *
     * @param selectedRows {string[]}
     * @param ticket string
     */
    (selectedRows, ticket) => {
      const res = async () => {
        const response = await api
          .put$('logica/groupSend/logicaSend', {
            selectedRows,
            ticket,
          });
        if (response.ok) {
          const d = await response.json();
          return d;
        }

        if (response.status === 422) {
          const d = await response.json();
          throw new Error(`Помилка відправки документів : ${d._Error}`);
        }
        throw new Error(`Помилка відправки документів (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      res()
        .then((results) => {
          const failedResults = results.failed;
          const successResults = results.success;

          if (failedResults.length) {
            addMessage({
              text: failedResults.join('; '),
              title: 'Відправлення до сервісу Logica не вдалось для документів:',
              variant: 'danger',
            });
          }
          if (successResults.length) {
            addMessage({
              text: successResults.join('; '),
              title: 'Відправлення документі до сервісу Logica',
              variant: 'success',
            });
          }
          onReload();
        });
    },
    [addMessage, onReload],
  );

  // Разрешения:
  /*
  Заполняются исключительно возможносятми.
  Здесь нет анализа на выбрано что-то или нет
   */
  const permissions = useMemo(
    () => ({
      canNew: meta.permissions
                && meta.permissions.canNew
        ? meta.permissions.canNew : true,
      canEdit: meta.permissions
                && meta.permissions.canEdit
        ? meta.permissions.canEdit : true,
      canCopy: meta.permissions
                && meta.permissions.canCopy
        ? meta.permissions.canCopy : true,
      canDelete: meta.permissions
                && meta.permissions.canDelete
        ? meta.permissions.canDelete : true,
      canExecute: meta.permissions
                && meta.permissions.canExecute
        ? meta.permissions.canExecute : modelType === 'doc',
      canUnexecute: meta.permissions
                && meta.permissions.canUnexecute
        ? meta.permissions.canUnexecute : modelType === 'doc',
      canHierarchy: [hierarchyTypes.foldersNItems, hierarchyTypes.onlyItems].includes(meta.hierarchyType) && !noHierarchy,
      foldersUsed: meta.hierarchyType === hierarchyTypes.foldersNItems && !noHierarchy,
      canNewFolder: meta.hierarchyType === hierarchyTypes.foldersNItems && !noHierarchy,
      canSign: meta.permissions
                && meta.permissions.canSign
        ? meta.permissions.canSign : modelType === 'doc',
      canResetSign: isAdmin,
    }),
    [isAdmin, meta.hierarchyType, meta.permissions, modelType, noHierarchy],
  );

  return {
    meta,
    visibleColumns,
    items,
    loading,
    err,
    pageInfo,
    onReload,
    onReloadWithCacheReset,
    onNextPage,
    onDeleteItems,
    onExecute,
    onUnexecute,
    permissions,
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,
    onLoadChildren,
    onUnloadChildren,
    loadPath,
    onApproveItems,
    onUnApproveItems,
    onApproveStatus,
    onApproveHistory,
    approveStatus,
    onApproveReset,
    onCloseApproveStatus,
    goToOldVersion,
    messages,
    clearMessages,
    deleteMessage,
    onLogicaSignItems,
    onLogicaSendItems,
  };
};

export default useListerBackend;
