import { faAddressCard, faPortrait } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export const PORTRAIT = 'PORTRAIT';
export const LANDSCAPE = 'LANDSCAPE';

export const pageLayouts = {
  [LANDSCAPE]: { label: 'ландшафтна', icon: faAddressCard },
  [PORTRAIT]: { label: 'портретна', icon: faPortrait },
};
