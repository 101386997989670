import React from 'react';

function ShowDeletedIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.5 3.5L3.5 16.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.8999 14.2C8.5999 14.4 9.2999 14.5 9.9999 14.5C14.0999 14.5 17.4999 11 17.4999 10C17.4999 9.50001 16.4999 8.20001 14.9999 7.10001" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.1 5.8C11.4 5.6 10.7 5.5 10 5.5C5.9 5.5 2.5 9.1 2.5 10C2.5 10.5 3.5 11.8 5 12.8" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3 7.5C10.2 7.5 10.1 7.5 10 7.5C8.6 7.5 7.5 8.6 7.5 10C7.5 10.1 7.5 10.2 7.5 10.3" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.69995 12.5C9.79995 12.5 9.89995 12.5 9.99995 12.5C11.4 12.5 12.5 11.4 12.5 10C12.5 9.90001 12.5 9.80001 12.5 9.70001" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ShowDeletedIcon;
