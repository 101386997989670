import React, { forwardRef, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup, Dropdown, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import md from '../../../../constants/meta';
import ListerContext from '../../../newLister/context';
import PrintModal from './listerPrintModal';

import { useEditor } from '../../../newEditor';
import { modelName, modelType } from '../def';
import DBFModal from '../editor/DBFModal';

const ListerCommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);
  const {
    actions,
  } = useEditor({
    modelType,
    modelName,
  });
  const currentItem = useMemo(
    () => selectedRows.reduce((R, r) => r, null),
    [selectedRows],
  );

  return (
    <DocListerCommandPanel
      ref={ref}
    >

      <ButtonGroup>
        <PrintModal selectedRows={selectedRows} />
        <DBFModal selectedRows={selectedRows} />
      </ButtonGroup>

      <ButtonGroup>
        <Dropdown>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="create_related">
                Створити на підставі
              </Tooltip>
            )}
          >
            <Dropdown.Toggle>
              <FontAwesomeIcon icon={faPaste} />
            </Dropdown.Toggle>
          </OverlayTrigger>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={`/${md.doc.financeObligation.frontend}/create?reason=${currentItem}`} disabled={!currentItem}>
              {md.doc.financeObligation.label}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/${md.doc.payment.frontend}/create?reason=${currentItem}`} disabled={!currentItem}>
              {md.doc.payment.label}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/${md.doc.docRegister.frontend}/create?reason=${currentItem}`} disabled={!currentItem}>
              {md.doc.docRegister.label}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>

    </DocListerCommandPanel>
  );
});

export default ListerCommandPanel;
