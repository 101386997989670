import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCalculator,
  faCheck,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { referencePropType } from '../../../newEditor/propTypes';
import DocSelectionBoostraped from '../../../dataProcessors/docSelectionBoostrapedForecast';

function LoadFromFact({
  onOk, readOnly, FI, KBP, disabled,
}) {
  const ref = useRef();
  const [qOpened, setQOpened] = useState(false);
  const [mOpened, setMOpened] = useState(false);
  const okHandler = useCallback(
    (data) => {
      setMOpened(false);
      onOk(data);
    },
    [onOk],
  );
  return (
    <>
      <Button onClick={() => setQOpened(true)} disabled={readOnly || disabled} ref={ref} variant="info">
        <FontAwesomeIcon icon={faCalculator} className="me-2" />
        Заповнити даними з факту
      </Button>
      <Modal
        show={qOpened}
        onHide={() => setQOpened(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faQuestion} bean className="me-2" />
            Увага
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ваші дані в цьому докуметі (якщо вони є) будуть
          заміщені даними з фактичних показників. Продовжити?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => {
              setQOpened(false);
              setMOpened(true);
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Так
          </Button>
          <Button variant="success" onClick={() => setQOpened(false)}>
            <FontAwesomeIcon icon={faBan} className="me-2" />
            Ні
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={mOpened}
        onHide={() => setMOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть документи для завантаження
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <DocSelectionBoostraped
            dataSource="Документ.МережаПоказателиПлан_ИзФакта"
            onLoad={okHandler}
            FI={FI}
            KBP={KBP}
            docCurrentType
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

LoadFromFact.propTypes = {
  onOk: PropTypes.func.isRequired,
  FI: referencePropType,
  KBP: referencePropType,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

LoadFromFact.defaultProps = {
  FI: null,
  KBP: null,
  readOnly: false,
  disabled: false,
};

export default LoadFromFact;
