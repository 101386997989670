import GroupEditor from './groups';
import ColumnsEditor from './columns';
import SelectionEditor from './selected';
import OrdersEditor from './orders';
import FiltersEditor from './filters';

export { availablePropType } from './available';

const ReportSettingsEditor = {
  GroupEditor,
  SelectionEditor,
  OrdersEditor,
  FiltersEditor,
  ColumnsEditor,
};

export default ReportSettingsEditor;
