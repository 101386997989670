import React from 'react';
import PropTypes from 'prop-types';
import { DivItem } from './styles';
import ContextMenuHOC from '../../../components/contextMenu';

function TableCell({
  children, active, onClick, onDoubleClick, negative, even, processed, modelType,
}) {
  return (
    <DivItem
      active={active}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      negative={negative}
      even={even}
      processed={processed}
      modelType={modelType}
      style={modelType === 'doc' && !processed ? { color: '#b8b8b8' } : {}}
    >
      {children}
    </DivItem>
  );
}

TableCell.propTypes = {
  children: PropTypes.element.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  negative: PropTypes.bool,
  even: PropTypes.bool.isRequired,
  processed: PropTypes.bool,
  modelType: PropTypes.objectOf('cat', 'doc').isRequired,
};

TableCell.defaultProps = {
  active: false,
  negative: false,
  processed: false,
};
export default ContextMenuHOC(TableCell);
