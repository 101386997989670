import React from 'react';

function DocumentDeleted() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 6H12.5V3" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 5.5L12.5 2.5H4.5V17.5H15.5V5.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path stroke="null" id="svg_3" fill="#FF0000" d="m9.944444,14.888888c2.117149,0 3.833332,-1.716183 3.833332,-3.833332c0,-2.117086 -1.716183,-3.833332 -3.833332,-3.833332c-2.117086,0 -3.833332,1.716247 -3.833332,3.833332c0,2.117149 1.716247,3.833332 3.833332,3.833332z" />
      <path d="M8.60005 12.3L10.9 10.3" stroke="white" strokeMiterlimit="12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.60005 10.3L10.9 12.3" stroke="white" strokeMiterlimit="12" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default DocumentDeleted;
