import React, {
  useState, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/docRegistr';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import CommandPanel from './commandpanel';
import enums from '../../../../constants/meta/enums';

const FOLister = ListerFactory({
  modelType: 'doc',
  modelName: 'docRegister',
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

const filterValues = Object.values(enums.docRegisterTypes)
  .map((r) => ({ value: r.name, label: r.label }));

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
});

function Lister() {
  const {
    FO, isAdmin,
  } = useSelector(mapState);

  const [fValue, setFValue] = useState(1);
  const flt = useMemo(
    () => {
      if (isAdmin) {
        return [{
          fieldName: 'ТипРеестра',
          value: fValue,
        }];
      }
      return [{
        fieldName: 'ТипРеестра',
        value: fValue,
      },
      {
        fieldName: md.columns.FI.name,
        value: FO.toJS(),
      },

      ];
    },
    [FO, fValue, isAdmin],
  );
  return (
    <>
      <FilteredTabs values={filterValues} value={fValue} onChange={(e, v) => setFValue(v)} />
      <FOLister filter={flt} />
    </>
  );
}

export default Lister;
