import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноза', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  csu: {
    label: 'Відповідальний виконавець', name: 'Исполнитель', visible: true,
  },
  kbp: {
    label: 'КТПКВК', name: 'КБП', visible: true,
  },
  kbpCode: {
    label: 'КТПКВК', name: 'КБПКод', visible: true,
  },
  goal: { label: 'Довідник Мета', name: 'Цель', visible: false },
  task: { label: 'Завдання', name: 'ЗаданияТекст', visible: false },
  baseText: { label: 'Підстави', name: 'ОснованияТекст', visible: false },
  baseTax: { label: 'Підстави та обґрунтування надходжень до СФ', name: 'ОснованиеДоходов', visible: false },
  baseCat: { label: 'Довідник законодавчих підстав', name: 'ОснованияСправочник', visible: false },
  goalText: { label: 'Мета', name: 'ЦельТекст', visible: false },
  useObjects: { label: 'Використовувати довідник об\'єктів', name: 'ИспользоватьОбъектыСтроительства', visible: false },
  isGet: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  inFill: {
    label: 'Доповнити існуючі дані даними з завантажених документів', name: 'Дописать', visible: true, type: 'bool',
  },
};

/**
 * @const
 */
const tables = {
  taxZF: {
    name: 'ДоходыЗФ',
    label: 'Надходження ЗФ',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  taxZFTR: {
    name: 'ДоходыЗФтрансферт',
    label: 'Надходження ЗФ (трансферти)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  taxZFCredit: {
    name: 'ДоходыЗФКредит',
    label: 'Надходження ЗФ (повернення кредитів)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  taxSF: {
    name: 'ДоходыСФ',
    label: 'Надходження СФ (трансферти)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  taxSFvl: {
    name: 'ДоходыСФвл',
    label: 'Надходження СФ (власні)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  credit: {
    name: 'ДоходыКредити',
    label: 'Надходження СФ (повернення кредитів)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  taxSFOther: {
    name: 'ДоходыСФДругие',
    label: 'Надходження СФ (інші надходження)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  transOut: {
    name: 'ТрансфертиИсх',
    label: 'Трансферти до інших бюджетів',
    columns: {
      code: { label: 'Код', name: 'КБП' },
      name: { label: 'Найменування', name: 'Наименование' },
      budget: { label: 'Найменування бюджету – отримувача міжбюджетного трансферту', name: 'Бюджет' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      br_2: { label: 'БР_2', name: 'БР_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      br_1: { label: 'БР_1', name: 'БР_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      br0: { label: 'БР0', name: 'БР0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      br1: { label: 'БР1', name: 'БР1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
      br2: { label: 'БР2', name: 'БР2' },
    },
  },
  br: {
    name: 'ДоходыСФбр',
    label: 'Надходження СФ (бюджет розвитку)',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  exp: {
    name: 'Расходы',
    label: 'Видатки',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'ПолноеНаименование' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      br_2: { label: 'БР_2', name: 'БР_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      br_1: { label: 'БР_1', name: 'БР_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      br0: { label: 'БР0', name: 'БР0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      br1: { label: 'БР1', name: 'БР1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
      br2: { label: 'БР2', name: 'БР2' },
    },
  },
  credits: {
    name: 'Кредит',
    label: 'Кредитування',
    columns: {
      code: { label: 'Код', name: 'Код' },
      name: { label: 'Найменування', name: 'ПолноеНаименование' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
    },
  },
  directs: {
    name: 'Напрямы',
    label: 'Напрями використання коштів',
    columns: {
      code: { label: 'Напрям', name: 'Напрям' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      br_2: { label: 'БР_2', name: 'БР_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      br_1: { label: 'БР_1', name: 'БР_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      br0: { label: 'БР0', name: 'БР0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      br1: { label: 'БР1', name: 'БР1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
      br2: { label: 'БР2', name: 'БР2' },
    },
  },
  transIn: {
    name: 'ТрансфертиВх',
    label: 'Трансферти з інших бюджетів',
    columns: {
      code: { label: 'Код', name: 'КДБ' },
      name: { label: 'Найменування', name: 'Наименование' },
      budget: { label: 'Найменування бюджету – надавача міжбюджетного трансферту', name: 'Бюджет' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      zf_2: { label: 'ЗФ_2', name: 'ЗФ_2' },
      sf_2: { label: 'СФ_2', name: 'СФ_2' },
      br_2: { label: 'БР_2', name: 'БР_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      zf_1: { label: 'ЗФ_1', name: 'ЗФ_1' },
      sf_1: { label: 'СФ_1', name: 'СФ_1' },
      br_1: { label: 'БР_1', name: 'БР_1' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      zf0: { label: 'ЗФ0', name: 'ЗФ0' },
      sf0: { label: 'СФ0', name: 'СФ0' },
      br0: { label: 'БР0', name: 'БР0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      zf1: { label: 'ЗФ1', name: 'ЗФ1' },
      sf1: { label: 'СФ1', name: 'СФ1' },
      br1: { label: 'БР1', name: 'БР1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      zf2: { label: 'ЗФ2', name: 'ЗФ2' },
      sf2: { label: 'СФ2', name: 'СФ2' },
      br2: { label: 'БР2', name: 'БР2' },
    },
  },
  invest: {
    name: 'Инвестпроект',
    label: 'Інвестиційні проекти',
    columns: {
      code: { label: 'Інвестиційний проект', name: 'Инвестпроект' },
      periodReal: { label: 'Період реалізації', name: 'ПериодРеализации' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum_2: { label: 'Сумма_2', name: 'Сумма_2' },
      sum_1: { label: 'Сумма_1', name: 'Сумма_1' },
      cost: { label: 'Вартість', name: 'Стоимость' },
      real: { label: 'Рівень готовності', name: 'Готовность' },
    },
  },
  tasks: {
    name: 'Задания',
    label: 'Завдання',
    columns: {
      task: { label: 'Завдання', name: 'Задание' },
    },
  },
  bases: {
    name: 'Основания',
    label: 'Підстави',
    columns: {
      base: { label: 'Підстава', name: 'Основание' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Пропозиція до прогнозу місцевого бюджету індивідуальна (Форма БП-2) (Додаток 3)',
  name: 'prepareForecastAdd3',
  backendName: 'ПрогнозДодП3',
  columns,
  frontend: 'doc/prepareForecastAdd3',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'csu',
      'kbpCode',
      'appr',
      'year',
      'isGet',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
