import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PrintingEditor from '../../../dataProcessors/freeRemPrint/editor';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

const mapInitialOptions = {
  Document: 'ДокументСсылка',
};

function PrintModal({ selectedRows, docType }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
        docType,
      },
    }),
    [docType, selectedRows],
  );
  const label = 'Друк';
  const onClose = useCallback(
    () => setOpened(false),
    [],
  );

  return (
    <>
      <ButtonGroup size="sm">
        <DefaultCPButton
          variant="info"
          onClick={() => setOpened(true)}
          disabled={selectedRows.length === 0}
          label={label}
          icon={faPrint}
        />
      </ButtonGroup>

      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друкована форма
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintingEditor
            mapInitialOptions={mapInitialOptions}
            mapStore={mapStore}
            onClose={onClose}
          />

        </Modal.Body>
      </Modal>
    </>
  );
}

PrintModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  docType: PropTypes.arrayOf(PropTypes.string),
};

PrintModal.defaultProps = {
  selectedRows: [],
  docType: 'prepareForecastAdd1',
};

export default PrintModal;
