import React, {
  memo, useCallback,
} from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const tablename = 'wageStructure';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col sm={2} className="overflow-hidden">
        <TableRowCell column="wage" highlighted={highlights.includes('wage')}>
          <ItemPicker
            value={row[tableMD.columns.wage.name]}
            modelType="cat"
            modelName="salaryStructure"
            onChange={async (e, v) => {
              await onRowChange(
                e,
                { [tableMD.columns.wage.name]: v },
              );
              await onSR('CHANGE_WAGE', { rowId: rowIndex });
            }}
            readOnly={readOnly}
          />
        </TableRowCell>
        <Badge variant="primary">{row[tableMD.columns.parent.name] && row[tableMD.columns.parent.name].repr}</Badge>
      </Col>
      <Col sm={2} className="px-1 border-right">
        <Row>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum_2" highlighted={highlights.includes('sum_2')}>
              {(row[tableMD.columns.sum_2.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf_2" highlighted={highlights.includes('zf_2')}>
              <NumberInput
                value={row[tableMD.columns.zf_2.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf_2.name]: value,
                  [tableMD.columns.sum_2.name]: value
                    + (row[tableMD.columns.sf_2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
              <NumberInput
                value={row[tableMD.columns.sf_2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf_2.name]: value,
                  [tableMD.columns.sum_2.name]: value
                        + (row[tableMD.columns.zf_2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsf_2" highlighted={highlights.includes('zfsf_2')}>
              <NumberInput
                value={row[tableMD.columns.zfsf_2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsf_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2} className="px-1 border-right">
        <Row>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum_1" highlighted={highlights.includes('sum_1')}>
              {(row[tableMD.columns.sum_1.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf_1" highlighted={highlights.includes('zf_1')}>
              <NumberInput
                value={row[tableMD.columns.zf_1.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf_1.name]: value,
                  [tableMD.columns.sum_1.name]: value
                    + (row[tableMD.columns.sf_1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
              <NumberInput
                value={row[tableMD.columns.sf_1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf_1.name]: value,
                  [tableMD.columns.sum_1.name]: value
                        + (row[tableMD.columns.zf_1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsf_1" highlighted={highlights.includes('zfsf_1')}>
              <NumberInput
                value={row[tableMD.columns.zfsf_1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsf_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2} className="px-1 border-right">
        <Row>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
              {(row[tableMD.columns.sum0.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf0.name]: value,
                  [tableMD.columns.sum0.name]: value
                  + (row[tableMD.columns.sf0.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf0.name]: value,
                  [tableMD.columns.sum0.name]: value
                      + (row[tableMD.columns.zf0.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsf0" highlighted={highlights.includes('zfsf0')}>
              <NumberInput
                value={row[tableMD.columns.zfsf0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsf0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2} className="px-1 border-right">
        <Row>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              {(row[tableMD.columns.sum1.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1.name]: value,
                  [tableMD.columns.sum1.name]: value
                  + (row[tableMD.columns.sf1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf1.name]: value,
                  [tableMD.columns.sum1.name]: value
                      + (row[tableMD.columns.zf1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsf1" highlighted={highlights.includes('zfsf1')}>
              <NumberInput
                value={row[tableMD.columns.zfsf1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsf1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              {(row[tableMD.columns.sum2.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2.name]: value,
                  [tableMD.columns.sum2.name]: value
                  + (row[tableMD.columns.sf2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf2.name]: value,
                  [tableMD.columns.sum2.name]: value
                      + (row[tableMD.columns.zf2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsf2" highlighted={highlights.includes('zfsf2')}>
              <NumberInput
                value={row[tableMD.columns.zfsf2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsf2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
