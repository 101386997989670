import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import StyledMenuHeader from '../../../components/styledMenuHeader/menuHeader';

function UseMenuHeader({ label, children }) {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <StyledMenuHeader inputValue={inputValue} setInputValue={setInputValue}>
        {label}
      </StyledMenuHeader>
      {!inputValue
            && children }
    </>
  );
}

UseMenuHeader.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

UseMenuHeader.defaultProps = {
  children: null,
};

export default UseMenuHeader;
