import React, {
  useCallback, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem } from '../styles';
import { Selector, Checkbox, MultiValueInput } from '../../../../components/Controls';
import PluralValues from './pluralValues';

const ContainerFilter = styled.div`
  display: grid;
  grid-template-columns: 25% 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  >div {
    margin-bottom: 0;
      &:last-child {
      >div div {margin-bottom: 0;}
      }
  }
`;

function FiltersItem({
  name, label, onClick, active, onDblClick, onHover, padded, errored,
  use, operation, value, allowedOperations, ctype, resource, isPlural,
  changeFilterUse, changeFilterOperation, changeFilterValue,
  choices, twoControls, showControl, onlyGroups,
}) {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.filter],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: itemTypes.filter },
    type: itemTypes.filter,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  drag(drop(ref));
  let variant = '';
  if (errored) variant = 'danger';
  else if (isOver) variant = 'success';

  const onChanfeUseHandler = useCallback(
    (e, v) => changeFilterUse(name, v),
    [changeFilterUse, name],
  );

  const onChangeOperationHandler = useCallback(
    (e, v) => changeFilterOperation(name, v),
    [changeFilterOperation, name],
  );

  const onChangeValueHandler = useCallback(
    (e, v) => {
      if (!twoControls) return changeFilterValue(name, v);
      if (!value || !Array.isArray(value) || value.length < 2) {
        return changeFilterValue(name, [v, null]);
      }
      return changeFilterValue(name, [v, value[1]]);
    },
    [changeFilterValue, name, twoControls, value],
  );

  const onChangeValue2Handler = useCallback(
    (e, v) => {
      if (!twoControls) return null;
      if (!value || !Array.isArray(value) || value.length < 2) {
        return changeFilterValue(name, [null, v]);
      }
      return changeFilterValue(name, [value[0], v]);
    },
    [changeFilterValue, name, twoControls, value],
  );

  const useChoices = !!choices;

  const selectorValues = useMemo(
    () => {
      if (!useChoices) return null;
      return choices.map(([v, l]) => ({ value: v, display_name: l }));
    },
    [choices, useChoices],
  );

  const v1 = useMemo(
    () => {
      if (twoControls && !Array.isArray(value)) return null;
      if (twoControls && Array.isArray(value)) return value[0];
      return value;
    },
    [twoControls, value],
  );
  const v2 = useMemo(
    () => {
      if (!twoControls) return null;
      if (twoControls && (!Array.isArray(value) || value.length) < 2) return null;
      return value[1];
    },
    [twoControls, value],
  );

  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={variant}
      style={style}
      title=""
    >

      <StyledSubItem padded={padded} />
      <ContainerFilter>
        <Checkbox
          id={`filter_check_${name}`}
          label={label}
          value={use}
          onChange={onChanfeUseHandler}
        />
        <Selector
          id={`filter_operation_${name}`}
          value={operation}
          values={allowedOperations}
          onChange={onChangeOperationHandler}
        />
        {isPlural && showControl && (
        <PluralValues
          value={v1}
          onChange={onChangeValueHandler}
          ctype={ctype}
          resource={resource}
          short={!active}
          onlyGroups={onlyGroups}
        />
        )}
        {!isPlural && showControl && !useChoices && (
        <MultiValueInput
          id={`filter_value_${name}`}
          onChange={onChangeValueHandler}
          value={v1}
          ctype={ctype}
          resource={resource}
          onlyGroups={onlyGroups}
        />
        )}
        {!isPlural && showControl && useChoices && (
          <Selector
            onChange={onChangeValueHandler}
            value={v1}
            values={selectorValues}
            id={`filter_value_${name}`}
          />
        )}
        {twoControls && !useChoices && (
          <MultiValueInput
            id={`filter_value_${name}_2`}
            onChange={onChangeValue2Handler}
            value={v2}
            ctype={ctype}
            resource={resource}
          />
        )}
      </ContainerFilter>
    </StyledFieldItem>
  );
}

FiltersItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  use: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number, repr: PropTypes.string,
    }), PropTypes.number, PropTypes.string, PropTypes.bool,
  ]),
  allowedOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  changeFilterUse: PropTypes.func.isRequired,
  changeFilterOperation: PropTypes.func.isRequired,
  changeFilterValue: PropTypes.func.isRequired,
  errored: PropTypes.bool,
  ctype: PropTypes.string.isRequired,
  resource: PropTypes.string,
  isPlural: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.number,
  ]))),
  showControl: PropTypes.bool,
  twoControls: PropTypes.bool,
  onlyGroups: PropTypes.bool,
};

FiltersItem.defaultProps = {
  label: '???',
  value: null,
  active: false,
  onDblClick: () => null,
  padded: false,
  errored: false,
  resource: null,
  isPlural: false,
  choices: null,
  showControl: true,
  twoControls: false,
  onlyGroups: false,
};

export default FiltersItem;
