import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { DivFooter } from '../../../../../components/styledTable/styledTable';
import GridTemplate from './GridTemplate';

function FooterComponent({ rows }) {
  const totals = rows.reduce((v, row) => v + (row.get('Check', false) ? row.get('Sum', 0) : 0), 0);
  return (
    <GridTemplate isHeader>
      <DivFooter areaName="1/1/3/4">Разом</DivFooter>
      <DivFooter areaName="sum">{totals.toFixed(2)}</DivFooter>
      <DivFooter areaName="author" />
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,
};

export default FooterComponent;
