const frontendURL = '/doc/changeToAssignPlanRev/';
const backendURL = '/api/authorityplans/revenueplanchangesheader/';
// const printURL = '';
const name = 'Зміни до розпису доходів';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
