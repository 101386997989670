/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { moneysOneDigitStingOptions } from '../../constants/moneys';

const StyleDiv = Styled.div`
position: relative;
padding-left: 20px;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    height: 0.4rem;
    width: 0.4rem;
    border-right: 2px solid;
    border-bottom: 2px solid;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: $transition-base;
    transform-origin: center;
    transition-property: transform;
  }

  &[aria-expanded='true']:before {
    transform: translateY(-50%) rotate(225deg);
  }
`;

// const StyledTr = Styled.tr`
// // text: ${({ level }) => (level ? 'column' : 'row')};
//  className="text-1100";
// `;

function getLevelColor(level) {
  switch (level) {
    case 1: return 'soft-secondary';
    case 2: return 'soft-success';
    case 6: return 'soft-info';
    case 3: return 'soft-warning';
    case 4: return 'soft-danger';
    case 5: return 'soft-primary';
    default: return '';
  }
}

function CollapsedTableRow({
  revenues, isOpen, toggle, level,
}) {
  return (
    <tr className={`bg-${getLevelColor(level)}`}>
      <td>
        <StyleDiv aria-expanded={isOpen} onClick={toggle}>
          <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
            {revenues.kdb.code}
          </div>
        </StyleDiv>
      </td>
      <td>
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.kdb.name}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_total_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_total_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_zf_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_zf_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_sf_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_sf_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
    </tr>
  );
}

CollapsedTableRow.propTypes = {
  revenues: PropTypes.shape({
    kdb: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    amount_total_fact: PropTypes.number,
    amount_total_plan: PropTypes.number,
    amount_zf_fact: PropTypes.number,
    amount_zf_plan: PropTypes.number,
    amount_sf_fact: PropTypes.number,
    amount_sf_plan: PropTypes.number,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
};

export default CollapsedTableRow;
