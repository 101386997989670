import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import md from '../../../../constants/meta/dataprocessors/requestsLoading';

const tableDocumentNotes = md.tables.documentNotes;
function Content({ data }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>{tableDocumentNotes.columns.CSU.label}</th>
          <th>{tableDocumentNotes.columns.ElementKFK.label}</th>
          <th>{tableDocumentNotes.columns.ElementKEKV.label}</th>
          <th>{tableDocumentNotes.columns.Sum.label}</th>
          <th>{tableDocumentNotes.columns.Notes.label}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((d) => (
          <tr key={d[tableDocumentNotes.columns.StrNumber.name]}>
            <td>{d[tableDocumentNotes.columns.CSU.name].repr}</td>
            <td>
              <span style={{ fontWeight: 'bold' }}>
                {d[tableDocumentNotes.columns.KPKVCode.name]}
              </span>
              :
              {d[tableDocumentNotes.columns.ElementKFK.name].repr}
            </td>
            <td>
              <span style={{ fontWeight: 'bold' }}>
                {d[tableDocumentNotes.columns.KEKVCode.name]}
              </span>
              :
              {d[tableDocumentNotes.columns.ElementKEKV.name].repr}
            </td>
            <td>
              <span style={{ fontWeight: 'bold' }}>
                {d[tableDocumentNotes.columns.Sum.name]}
              </span>
            </td>
            <td>{d[tableDocumentNotes.columns.Notes.name]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

Content.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Content;
