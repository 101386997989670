import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/energyReport';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  ContainerTwoColumn,
  StyledLabel, ContainerDiv,
} from '../../../components/Form/styledForm';
import ItemField from '../../field/selectorField/item/itemField';
import DateRangeField from '../../../components/fields/DateRangeField';
import ReportContainer from '../reportContainer';

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

function EnergyReportEditor({
  dispatch, headerForm, isProcessing, sessionOptions, portalOpened, onReportDetails,
}) {
  const isAdmin = sessionOptions.get('is_admin');
  const BudgetLabel = useMemo(
    () => (isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    )),
    [dispatch, headerForm, isAdmin],
  );

  const SettingsButton = useMemo(
    () => (
      <div>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <Fields.SelectorField
              value={headerForm.get('csu', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'csu'], v))}
            />
          </div>
        </ContainerTwoColumn>
        <ContainerTwoColumn>
          <div>
            <Fields.SelectorField
              value={headerForm.get('kbp', new Map())}
              modelType="cat"
              modelName="kbpClassifier"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'kbp'], v))}
            />
          </div>
          <div>
            <Fields.SelectorField
              value={headerForm.get('kekv', new Map())}
              modelType="cat"
              modelName="kekvClassifier"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'kekv'], v))}
            />
          </div>
        </ContainerTwoColumn>
        <ContainerTwoColumn>
          <div>
            <Fields.SelectorField
              value={headerForm.get('reportForm', new Map())}
              modelType="cat"
              modelName="reportsForms"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportForm'], v))}
            />
          </div>
          <div>
            <Fields.TextAreaField
              value={headerForm.get('text1', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'text1'], v))}
            />
          </div>
        </ContainerTwoColumn>
        <ContainerTwoColumn>
          <div>
            <Fields.TextAreaField
              value={headerForm.get('text2', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'text2'], v))}
            />
          </div>
          <div>
            <Fields.TextAreaField
              value={headerForm.get('text3', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'text3'], v))}
            />
          </div>
        </ContainerTwoColumn>
      </div>
    ),
    [BudgetLabel, dispatch, headerForm],
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}
EnergyReportEditor.propTypes = {
  dispatch: PropTypes.func,
  onReportDetails: PropTypes.func,
  headerForm: PropTypes.instanceOf(Map),
  sessionOptions: PropTypes.instanceOf(Map),
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
};
EnergyReportEditor.defaultProps = {
  dispatch: () => null,
  onReportDetails: null,
  headerForm: new Map(),
  isProcessing: false,
  sessionOptions: new Map(),
  portalOpened: false,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
  memo,
);

export default enhance(EnergyReportEditor);
