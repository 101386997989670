/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Map } from 'immutable';
import ListerFactory from '../../../newLister';
import { modelType, modelName } from '../def';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import md from '../../../../constants/meta/catalogs/indicators';
import ElementTaskCommandPanel from './commandpanel';

const IndicatorsLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({}));

const TasksLister = ListerFactory({
  modelType,
  modelName: 'tasks',
  viewType: 'selector',
})(CatTableListerFactory({
  CommandPanel: ElementTaskCommandPanel,
}));

function ElementLister() {
  const [task, setTask] = useState();
  const flt = useMemo(
    () => [
      {
        fieldName: md.columns.owner.name,
        value: task,
      },
    ],
    [task],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={4}>
          <TasksLister onSelect={(e, v) => setTask(Map.isMap(v) ? v.toJS() : v)} />
        </Col>
        <Col>
          <IndicatorsLister filter={flt} />
        </Col>
      </Row>
    </Container>
  );
}

export default ElementLister;
