import React from 'react';
import { TableControls, tableHooks } from '../../basicEditor/tableControls';

// eslint-disable-next-line
export const getComponentByType = (type , read_only = false) => {
  switch (type) {
    case 'string':
      return TableControls.StringInput;
    case 'email':
      return TableControls.StringInput;
    case 'nested object':
    // eslint-disable-next-line
    return read_only ? TableControls.ItemField : TableControls.ItemPicker;
    case 'field':
    // eslint-disable-next-line
    return read_only ? TableControls.ItemField : TableControls.ItemPicker;
    case 'integer':
      return TableControls.NumberInput;
    case 'float':
      return TableControls.NumberInput;
    case 'decimal':
      return TableControls.NumberInput;
    case 'choice':
      return TableControls.SelectorInput;
    case 'date':
      return TableControls.DateInput;
    case 'boolean':
      return TableControls.CheckboxInput;
    case 'text':
      return TableControls.TextInput;
    default:
      // eslint-disable-next-line no-console
      console.error(`Type ${type} not supported by editor controls`);
      return (<div style={{ color: 'red' }}>{`Type ${type} not supported by editor controls`}</div>);
  }
};

export const getTableHookByType = (type) => {
  switch (type) {
    case 'string':
      return tableHooks.useStringInputProps;
    case 'text':
      return tableHooks.useTextInputProps;
    case 'selector':
      return tableHooks.useSelectorInputProps;
    case 'email':
      return tableHooks.useStringInputProps;
    case 'nested object':
      return tableHooks.useItemInputProps;
    case 'field':
      return tableHooks.useItemInputProps;
    case 'integer':
      return tableHooks.useNumberInputProps;
    case 'float':
      return tableHooks.useNumberInputProps;
    case 'decimal':
      return tableHooks.useNumberInputProps;
    case 'choice':
      return tableHooks.useSelectorInputProps;
    case 'date':
      return tableHooks.useDateInputProps;
    case 'boolean':
      return tableHooks.useCheckboxInputProps;
    default:
      // eslint-disable-next-line no-console
      console.error(`Type ${type} not supported by tableHooks controls`);
      return (() => null);
  }
};

// eslint-disable-next-line
export default {
  getComponentByType,
  getTableHookByType,
};
