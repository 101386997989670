import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';

const tablename = 'listDoc';
const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="Year" highlighted={highlights.includes('Year')}>
          <EditorControls.YearInput
            value={row[tableMD.columns.Year.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.Year.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="NameDoc" highlighted={highlights.includes('NameDoc')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.NameDoc.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.NameDoc.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="Doc" highlighted={highlights.includes('Doc')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.Doc.name].repr}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.Doc.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
        {/* <TableRowCell column="Doc" highlighted={highlights.includes('Doc')}> */}
        {/*  <ItemPicker */}
        {/*    value={row[tableMD.columns.Doc.name]} */}
        {/*    modelType="doc" */}
        {/*    modelName={DocTypes[row[tableMD.columns.Doc.name].modelName]} */}
        {/*    onChange={(e, v) => onRowChange(e, { [tableMD.columns.Doc.name]: v })} */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        {/* </TableRowCell> */}
      </Col>
      <Col className="border-right">
        <TableRowCell column="DocSum" highlighted={highlights.includes('DocSum')}>
          <EditorControls.NumberInput
            value={row[tableMD.columns.DocSum.name]}
            precision={2}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.DocSum.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
