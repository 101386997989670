import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
  TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'sourcesSpecF';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSourcesTypeChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.name.name]: v });
      await onSR('CHANGE_ZF_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="sourcesType" highlighted={highlights.includes('sourcesType')}>
              <ItemPicker
                value={row[tableMD.columns.sourcesType.name]}
                modelType="cat"
                modelName="typeTaxSFForRaitingZF"
                onChange={onSourcesTypeChange}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumPlan" highlighted={highlights.includes('sumPlan')}>
              <NumberInput
                value={row[tableMD.columns.sumPlan.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumPlan.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumFact" highlighted={highlights.includes('sumFact')}>
              <NumberInput
                value={row[tableMD.columns.sumFact.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumFact.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sumDeviation" highlighted={highlights.includes('sumDeviation')}>
              <NumberInput
                value={(row[tableMD.columns.sumPlan.name] || 0)
                - (row[tableMD.columns.sumFact.name] || 0)}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumDeviation.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="explanation" highlighted={highlights.includes('explanation')}>
              <TextInput
                value={row[tableMD.columns.explanation.name]}
                rows={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.explanation.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
