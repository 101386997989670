import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InfoDiv = styled.div`
  box-shadow: 0 0 0 1px #b4b4e0 inset, 0 0 0 0 transparent;
  background-color: #f6f6ff;
  color: #38389f;
  padding: 1em 1.5em;
  margin: 1em 0;
  border-radius: 0.5em;
`;

const InfoHeader = styled.header`
  font-weight: 700;
`;

const InfoText = styled.p`
  white-space: pre-wrap
`;

export function InfoMessage({ header, text }) {
  return (
    <InfoDiv>
      <InfoHeader>{header}</InfoHeader>
      <InfoText>{text}</InfoText>
    </InfoDiv>
  );
}

InfoMessage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
};

InfoMessage.defaultProps = {
  header: 'Інформаційне повідомлення',
  text: 'Розробники забули щось тут написати',
};

export default InfoMessage;
