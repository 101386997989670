import {
  defaultOrder,
  defaultColumns,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
};

const tables = {
  user: {
    name: 'ПользователиГруппы',
    label: 'Користувачі групи',
    columns: {
      user: { label: 'Корисутвач', name: 'Пользователь' },
    },
  },
};

export const signGroup = {
  label: 'Группи пiдписiв',
  name: 'SignGroup',
  backendName: 'ГруппыПодписей',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/signGroup',
  listColumns: [...(new Set([...reqListerColumns]))],
  tables,
  hierarchy: false,
};

export default signGroup;
