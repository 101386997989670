const frontendURL = '/doc/budget-request/';
const backendURL = '/api/budgetrequest/budgetrequestheader/';
const name = 'Бюджетний запит';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
