import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CommandPanelButtonText } from '../Form/styledForm';
import Modal from '../modal';
import { SettingsIcon } from '../../assets/icons';

class ReportSettingsPortal extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { disabled, children } = this.props;
    return (
      <Modal
        triggerButton={(
          <CommandPanelButtonText disabled={disabled} text="Налаштування">
            <SettingsIcon />
          </CommandPanelButtonText>
         )}
        header="Налаштування"
      >
        {React.Children.only(children)}
      </Modal>
    );
  }
}

export default ReportSettingsPortal;
