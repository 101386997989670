import React, {} from 'react';
import PropTypes from 'prop-types';
import Resizer from '../../tableEditor/xresize';

const setResizable = (WrappedComponent) => {
  function ResizableDiv({
    children, onResizeColumn, onResetColumnSize, ...restProps
  }) {
    return (
      <WrappedComponent {...restProps}>
        {children}
        <Resizer
          onResize={onResizeColumn}
          onResetColumnSize={onResetColumnSize}
        />
      </WrappedComponent>
    );
  }

  ResizableDiv.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    onResizeColumn: PropTypes.func.isRequired,
    onResetColumnSize: PropTypes.func.isRequired,
  };

  ResizableDiv.defaultProps = {
    children: null,
  };

  return ResizableDiv;
};

export default setResizable;
