import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TabLi = styled.li`
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  color: ${({ empty }) => (empty ? 'white' : 'black')};
  ${({ active }) => (active
    ? 'background: linear-gradient(180deg,#c9d7e8 0%,#fcfdff 100%);'
  // 'border: solid #DDE2E9;' +
  + ' border: solid #afbbcc;'
  + 'border-width: 1px 1px 0 1px;'
  + 'border-radius: 4px 4px 0 0;'
  + 'font-weight: 700;' : '')}
  &:hover{
    background: ${({ empty }) => (empty ? 'unset' : 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21')};
    border-radius: 4px 4px 0 0;
   };
  filter: ${({ disabled }) => (disabled ? 'grayscale(1) opacity(.275)' : 'unset')};
`;

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    empty: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    empty: false,
  };

  onClick = () => {
    const { label, onClick, disabled } = this.props;
    if (!disabled) onClick(label);
  };

  render() {
    const {
      onClick,
      props: {
        activeTab,
        disabled,
        label,
        empty,
      },
    } = this;

    return (
      <TabLi
        active={activeTab === label}
        onClick={onClick}
        disabled={disabled}
        empty={empty}
      >
        {label}
      </TabLi>
    );
  }
}

export default Tab;
