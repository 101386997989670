import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/socialPayments';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

import { CommandPanelColor } from '../../../../components/Form/styledForm';
import enums from '../../../../constants/meta/enums';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const reportsTypeOptions = Object.values(enums.ReportsType).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function SocialPayments({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LoadDocs', loadData);
    },
    [actions],
  );

  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.typePlan.label}
            value={data[md.columns.typePlan.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.typePlan.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
            noHierarchy
            required
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.typeRep.label}
            value={data[md.columns.typeRep.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.typeRep.name]: value,
            })}
            values={reportsTypeOptions}
            readOnly={readOnly}
            noHierarchy
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`accMonth-${data.id}`}
            label={md.columns.accMonth.label}
            value={data[md.columns.accMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.accMonth.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`dontRound-${data.id}`}
            label={md.columns.dontRound.label}
            value={data[md.columns.dontRound.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dontRound.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`recountFactMonth-${data.id}`}
            label={md.columns.recountFactMonth.label}
            value={data[md.columns.recountFactMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.recountFactMonth.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`factMonth-${data.id}`}
            label={md.columns.factMonth.label}
            value={data[md.columns.factMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.factMonth.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`planMinusKassa-${data.id}`}
            label={md.columns.planMinusKassa.label}
            value={data[md.columns.planMinusKassa.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planMinusKassa.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <CommandPanelColor
        style={{ marginTop: '10px' }}
        label="Заповнити автоматично"
        onClick={() => actions.onSR('FILL_SOC_TABLE')}
      />
      <CommandPanelColor
        label="Обнулити заборгованість"
        onClick={() => actions.onSR('RESET_DEBT')}
      />
    </EditorCollapse>
  );
}

SocialPayments.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SocialPayments;
