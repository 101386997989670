const LogicaReportType = {
  budgetRequestF1: { name: 'БюджетныйЗапросФ1', label: 'Бюджетний запит Форма 1' },
  budgetRequestF2: { name: 'БюджетныйЗапросФ2', label: 'Бюджетний запит Форма 2' },
  budgetRequestF3: { name: 'БюджетныйЗапросФ3', label: 'Бюджетний запит Форма 3' },
  sessionDecision: { name: 'РешениеСессии1', label: 'Рішення сесії' },
  decisionNotification: { name: 'СообщениеОРешенииСессии', label: 'Повідомлення про прийняття рішеня органом місцевого самоврядування' },
  prepareForecastAdd1: { name: 'ПодготовкаПрогнозД1', label: 'Підготовка до прогнозу Дод1' },
  prepareForecastAdd2: { name: 'ПодготовкаПрогнозД2', label: 'Підготовка до прогнозу Дод2' },
  prepareForecastAdd3: { name: 'ПодготовкаПрогнозД3', label: 'Підготовка до прогнозу Дод3' },
  prepareForecastAdd4: { name: 'ПодготовкаПрогнозД4', label: 'Підготовка до прогнозу Дод4' },
  prepareForecastAdd5: { name: 'ПодготовкаПрогнозД5', label: 'Підготовка до прогнозу Дод5' },
  freeRem: { name: 'СвободныеОстатки', label: 'Інформація про вільні залишки Додаток 3' },
  forecast: { name: 'Прогноз', label: 'Прогноз' },
  indicatorFact: { name: 'ОтчетИсполненияПаспортБП', label: 'Звіт з виконання паспорту БП' },
  socialPayments: { name: 'Соцвыплаты', label: 'Соцвиплати' },
  assignmentPlanning: { name: 'ПланАссигнований', label: 'План асигнувань' },
  estimate: { name: 'Кошторис', label: 'Кошторис' },
  changeToCost: { name: 'РосписьПомесечнаяИзм', label: 'Зміни до помісячного розпису' },
  indicatorPassport: { name: 'ПаспортБП', label: 'Паспорт ' },
  // costYear: { name: 'РосписьГодовая', label: 'РосписьГодовая' },
  costMounth: { name: 'РосписьПомесечная', label: 'Роспис видатків' },
  taxesAdd2: { name: 'ПодаткиДод2', label: 'Ставки та пільги (Додаток 2)' },

  netFOP_GRK: { name: 'МережаПоказателиДод4ГРК', label: 'Мережа. дод 4 ГРК' },
  netFOP_FI: { name: 'МережаПоказатели', label: 'Мережа дод. 4 МФО' },
  netFOP_Plan_FI: { name: 'МережаПоказателиДод3МФО', label: 'Мережа дод. 3 МФО' },
  netFOP_Plan_GRK: { name: 'МережаПоказателиДод3ГРК', label: 'Мережа дод. 3 ГРК' },
  // freeRem4: { name: 'СвободныеОстатки4', label: 'Інформація про вільні залишки Додаток 4' },
  expensesOnHealth: { name: 'РасходыНаЗдоровъе', label: 'Видатки на охорону здоров\'я' },
  netCategoryDod3MF: { name: 'МережаПоказателиДод3МФО', label: 'Мережа показатели дод 3 МФО' },
  tax: { name: 'НалогиИЛьготы', label: 'Ставки та пільги' },
  expensesOnEducation: { name: 'РасходыНаСреднееОбразования', label: 'Видатки на середню освіту' },
  freeRem4: { name: 'СвободныеОстатки4', label: 'Інформація про вільні залишки Додаток 4' },
  eexp: { name: 'Витяг', label: 'Витяг' },
  liquidity: { name: 'ИнформацияЛиквидности', label: 'Інформація щодо ліквідності МФО' },
  liquidityGRK: { name: 'ИнформацияЛиквидностиГРК', label: 'Інформація щодо ліквідності ГРК' },
  liquidityRK: { name: 'ИнформацияЛиквидностиРК', label: 'Інформація щодо ліквідності РК' },
  cred: { name: 'ПланАссигнованийКредит', label: 'План кредитування' },
  credSvod: { name: 'ПланАссигнованийКредитСвод', label: 'План кредитування зведений' },
  plan: { name: 'ПланАссигнованийСвод', label: 'План асигнувань зведений' },
  planSF: { name: 'ПланАссигнованийСФ', label: 'План асигнувань СФ' },
  planSFSvod: { name: 'ПланАссигнованийСФСвод', label: 'План асигнувань СФ зведений' },
  garranty: { name: 'ГарантииДолги', label: 'Місцеві запозичення та гарантії' },
  svodPokSF: { name: 'СводПоказателейСФ', label: 'Зведення показників СФ' },
  plUseSvod: { name: 'ПланИспользованияСвод', label: 'План використання  зведений' },
  plUse: { name: 'ПланИспользования', label: 'План використання' },
  plUsePom: { name: 'ПланИспользованияПомес', label: 'План використання помісячний' },
  plUsePomSvod: { name: 'ПланИспользованияПомесСвод', label: 'План використання помісячний зведений' },
  expensesOnEducationRK: { name: 'РасходыНаСреднееОбразованияРК', label: 'Видатки на середню освіту (РК)' },
  educationCount: { name: 'РасчетнаяПотребностьОбразование', label: 'Розрахункова потреба освіта' },
  localDebt: { name: 'МестныеДолги', label: 'Місцеві запозичення' },
  educationData: { name: 'ДанныеПоОплатеТрудаОбразование', label: 'Дані по оплаті праці освіта' },
  freeRem42024: { name: 'СвободныеОстатки4_2024', label: 'Інформація про вільні залишки Додаток 4 2024р' },
  sessionDecision2: { name: 'РешениеСессии2', label: 'Рішення сесії дод 2' },
  sessionDecision3: { name: 'РешениеСессии3', label: 'Рішення сесії дод 3' },
  sessionDecision4: { name: 'РешениеСессии4', label: 'Рішення сесії дод 4' },
  sessionDecision5: { name: 'РешениеСессии5', label: 'Рішення сесії дод 5' },
  sessionDecision6: { name: 'РешениеСессии6', label: 'Рішення сесії дод 6' },
  sessionDecision7: { name: 'РешениеСессии7', label: 'Рішення сесії дод 7' },
  sessionDecision8: { name: 'РешениеСессии8', label: 'Рішення сесії дод 8' },
  limitReference: { name: 'ЛимитнаяСправка', label: 'Лімітна довідка' },
};

export default LogicaReportType;
