import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Nav, ButtonGroup,
} from 'react-bootstrap';
import {
  SaveButton,
  UndoButton,
  RedoButton,
  ExecuteNExitButton,
  ExecuteButton,
  UnExecuteButton,
  GoToOldVersionButton,
} from './buttons';
import { StyledButtonToolbar } from './styles';

const DocEditorCommandPanel = forwardRef(({
  children, actions, permissions, changed, loading,
}, ref) => (
  <Nav ref={ref} className="border rounded bg-white sticky-top">
    <StyledButtonToolbar className="me-auto" size="sm">
      <ButtonGroup size="sm">
        <ExecuteNExitButton
          onClick={actions.onExecuteNExit}
          disabled={!permissions.canClose || !permissions.canExecute || !changed}
        />
        <SaveButton
          onClick={actions.onSaveWithoutExit}
          disabled={!changed || !permissions.canSave}
        />
        <ExecuteButton
          onClick={actions.onExecute}
          disabled={!permissions.canExecute}
        />
        <UnExecuteButton
          onClick={actions.onUnexecute}
          disabled={!permissions.canUnexecute}
        />
      </ButtonGroup>
      <ButtonGroup size="sm">
        <UndoButton
          onClick={actions.onUndo}
          disabled={!permissions.canUndo || !changed || loading}
        />
        <RedoButton
          onClick={actions.onRedo}
          disabled={!permissions.canRedo || !changed || loading}
        />
      </ButtonGroup>
      {children}
    </StyledButtonToolbar>
    <ButtonGroup size="sm">
      <GoToOldVersionButton
        onGoToOldVersion={actions.onGoToOldVersion}
        onSaveNGoToOldVersion={actions.onSaveNGoToOldVersion}
        disabled={loading}
        isChanged={changed}
        loading={loading}
      />
    </ButtonGroup>
  </Nav>
));

DocEditorCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  actions: PropTypes.shape({
    onExecuteNExit: PropTypes.func.isRequired,
    onSaveWithoutExit: PropTypes.func.isRequired,
    onExecute: PropTypes.func.isRequired,
    onUnexecute: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onGoToOldVersion: PropTypes.func.isRequired,
    onSaveNGoToOldVersion: PropTypes.func.isRequired,
  }).isRequired,
  changed: PropTypes.bool,
  loading: PropTypes.bool,
  permissions: PropTypes.shape({
    canClose: PropTypes.bool,
    canSave: PropTypes.bool,
    canExecute: PropTypes.bool,
    canUnexecute: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
  }).isRequired,
};

DocEditorCommandPanel.defaultProps = {
  children: null,
  changed: false,
  loading: false,
};

export default memo(DocEditorCommandPanel);
