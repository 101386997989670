import { useCallback, useMemo, useState } from 'react';
import api from '../../../api/req';

export const useDCAvailable = ({
  availableFields, section, reqParams = {}, backendURL, onError, onChange,
}) => {
  const [active, setActive] = useState(null);
  const [openedItems, setOpenedItems] = useState([]);
  // TODO: opened, onOpen + уровни
  const displayAvailable = useMemo(
    // () => Object.keys(availableFields).map((k, i) => ({
    //   key: k,
    //   index: i,
    //   level: 0,
    //   item: availableFields[k],
    // })),
    () => {
      const getItems = (items = {}, level = 0) => Object.keys(items).reduce((R, k) => [
        ...R,
        {
          key: k,
          level,
          item: items[k],
        },
        ...(items[k].Items && openedItems.includes(k) ? getItems(items[k].Items, level + 1) : []),
      ], []);
      return getItems(availableFields).map((item, index) => ({ ...item, index }));
    },
    [availableFields, openedItems],
  );

  const getSubItems = useCallback(
    async (field) => {
      const r = await api.post$(`${backendURL}getAvailableParent/`, { ...reqParams, section, field });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [backendURL, reqParams, section],
  );

  const onToggle = useCallback(
    (id) => {
      setOpenedItems((ops) => {
        if (!ops.includes(id)) {
          const field = displayAvailable
            .filter((item) => item.key === id)
            .reduce((R, r) => r.item, null);
          if (!field.itemsCount) return ops;
          if (!field.Items) {
            getSubItems(id)
              .then((subItems) => {
                const pt = id.split('.');
                const path = pt.reduce((R, p, i) => [...R, pt.slice(0, i + 1).join('.'), 'Items'], []);
                const getV = (v, i = 0) => {
                  if (i + 1 === path.length) {
                    return {
                      ...v,
                      [path[i]]: subItems.Items,
                    };
                  }
                  const d = {
                    ...v,
                    [path[i]]: getV(v[path[i]], i + 1),
                  };
                  return d;
                };
                onChange((a) => getV(a));
              })
              .catch((e) => onError(e.message));
          }

          return [...ops, id];
        }
        return ops.filter((o) => o !== id);
      });
    },
    [displayAvailable, getSubItems, onChange, onError],
  );

  const onActivate = useCallback(
    (id, multiple = false) => setActive(id),
    [],
  );

  const availables = useMemo(
    () => ({
      items: displayAvailable,
      activeItem: active,
      onActivate,
      onToggle,
      openedItems,
    }),
    [active, displayAvailable, onActivate, onToggle, openedItems],
  );

  return availables;
};
