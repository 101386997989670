import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../../common/proptypes';

function AutoHeightContainer({ children, minHeight, rootId }) {
  const [height, setHeight] = useState(`${minHeight}px`);
  const ref = useRef();
  const onChangeSize = useCallback(
    (el) => {
      const cntCrds = el[0].target.getBoundingClientRect();
      const itemCrds = ref.current.getBoundingClientRect();
      const newHeight = cntCrds.height + cntCrds.top - itemCrds.top;
      setHeight(`${Math.max(newHeight, minHeight)}px`);
    },
    [minHeight],
  );
  const resizeObserver = useMemo(() => new ResizeObserver(onChangeSize), [onChangeSize]);
  useEffect(
    () => {
      const docBody = document.getElementById(rootId);
      if (docBody) {
        resizeObserver.observe(docBody);
        return () => resizeObserver.unobserve(docBody);
      }
      // eslint-disable-next-line no-console
      console.error(`Не удалось найти root-контайнер с id=${rootId}`);
      return null;
    },
    [resizeObserver, rootId],
  );

  const style = useMemo(
    () => ({ height }),
    [height],
  );
  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  );
}

AutoHeightContainer.propTypes = {
  children: childrenPropType.isRequired,
  minHeight: PropTypes.number,
  rootId: PropTypes.string,
};

AutoHeightContainer.defaultProps = {
  minHeight: 200,
  rootId: 'docBody',
};

export default AutoHeightContainer;
