import base from './base';

const columns = {
  ...base.columns,
  Author: { label: 'Автор', name: 'Автор', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  Fond: {
    label: 'Фонд',
    name: 'Фонд',
    visible: false,
  },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  Prim: { label: 'Примітка', name: 'Примечание', visible: true },
  RegNumber: { label: 'Вн.номер', name: 'РегНомер', visible: true },
  SumDoc: {
    label: 'Сума по документу', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  ClosingAcc: { label: 'Закриття рахунків', name: 'ЗакрытиеСчетов', visible: false },
  Year: { label: 'Рік', name: 'Год', visible: false },
  isSpecFond23: { label: 'ознака спец.фонду (2 або 3) для ФБР', name: 'Это23', visible: false },
  AutView: { label: 'Опис', name: 'АвтПримечание', visible: true },
  DateCreate: { label: 'Дата творення', name: 'ДатаСоздания', visible: false },
  NoControlOfCredits: { label: 'Відключити контроль залишків', name: 'ОтключитьКонтрольОстатков', visible: false },
  KEKVNatural: { label: 'КЕКВ (натуральна форма)', name: 'КЕКВНатура', visible: false },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Основне',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSUAccount: { label: 'Рахунок', name: 'РСРаспорядителя' },
      Sum: { label: 'Сумма', name: 'Сумма' },
      SumIn: { label: 'Надходження', name: 'СуммаПоступления' },
      SumGet: { label: 'Одержано', name: 'СуммаПолучено' },
      SumDel: { label: 'Погашено', name: 'СуммаПогашено' },
      SumOut: { label: 'Вилучено', name: 'СуммаИзъятия' },
      SumNatural: { label: 'в т.ч. в натуральних показниках', name: 'СуммаНатура' },
      ElKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      ElKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      ElKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      KPKV: { label: 'Код КПКВ', name: 'КодКПКВ' },
    },
  },
  accClose: {
    name: 'ЗакрытыеСчета',
    label: 'Закриття рахунків',
    columns: {
      CSUAccount: { label: 'Рахунок', name: 'РСРаспорядителя' },
      Sum: { label: 'Сумма', name: 'Сумма' },
      SumIn: { label: 'Надходження', name: 'СуммаПоступления' },
      SumGet: { label: 'Одержано', name: 'СуммаПолучено' },
      SumDel: { label: 'Погашено', name: 'СуммаПогашено' },
      SumOut: { label: 'Вилучено', name: 'СуммаИзъятия' },
      SumNatural: { label: 'в т.ч. в натуральних показниках', name: 'СуммаНатура' },
      KFK: { label: 'КФК', name: 'КФК' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      KDB: { label: 'КДБ', name: 'КДБ' },
      KPKV: { label: 'КПКВ', name: 'КБП7' },
    },
  },
};

const definition = {
  label: 'Надходження коштів на рахунки',
  name: 'incomingMoney',
  backendName: 'ПоступлениеДенег',
  columns,
  frontend: 'doc/incomingMoney',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'RegNumber',
      'SumDoc',
      'AutView',
      'FI',
      'Prim',
      'Author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
