import React, {
  PureComponent,
} from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { CheckboxButton } from '../../../components/button/index';
import { NumberSpan, DivTogglerItem, BoolContainer } from './styles';
import TableCell from './TableCell';
import setResizable from './ResizableDiv';
import meta from '../../../constants/meta';
import {
  YesIcon, CopyIcon, EditIcon, RemoveIcon, PostDocIcon, UndoPostDoc,
} from '../../../assets/icons/index';

const ResizableTableCell = setResizable(TableCell);

const ctxMenuOptionsDoc = [
  {
    label: 'Провести',
    action: 'posting',
    icon: <img src={PostDocIcon} alt="" />,
  }, {
    label: 'Відмінити проведення',
    action: 'undoPosting',
    icon: <img src={UndoPostDoc} alt="" />,
  }, {
    label: 'Видалити',
    action: 'remove',
    icon: <RemoveIcon />,
  }, {
    label: 'Копіювати',
    action: 'copyItem',
    icon: <CopyIcon />,
  }, {
    label: 'Змінити',
    action: 'editItem',
    icon: <EditIcon />,
  },
];

const ctxMenuOptionsCat = [
  {
    label: 'Видалити',
    action: 'remove',
    icon: <RemoveIcon />,
  }, {
    label: 'Копіювати',
    action: 'copyItem',
    icon: <CopyIcon />,
  }, {
    label: 'Змінити',
    action: 'editItem',
    icon: <EditIcon />,
  },
];

class listerTableRow extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    even: PropTypes.bool,
    // id: PropTypes.string.isRequired,
    listColumns: PropTypes.instanceOf(Map).isRequired,
    columns: PropTypes.shape().isRequired,
    row: PropTypes.instanceOf(Map).isRequired,
    iRef: PropTypes.shape(),
    onResizeColumn: PropTypes.func.isRequired,
    onResetColumnSize: PropTypes.func.isRequired,
    modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
    modelName: PropTypes.oneOf([
      ...new Set(Object.keys(meta).reduce(
        (r, typeName) => [...r, ...Object.keys(meta[typeName])],
        [],
      )),
    ]).isRequired,
    onActionClick: PropTypes.func,
  };

  static defaultProps = {
    even: false,
    iRef: null,
    onActionClick: () => null,
  };

  constructor(props) {
    super(props);
    this.RowRef = React.createRef();
  }

  componentDidMount() {
    const { row } = this.props;
    const isActive = row.get('ACTIVE', false);
    if (isActive) {
      this.RowRef.current.focus();
    }
  }

  getValue = (columnName, value) => {
    const { columns } = this.props;
    switch (columns[columnName].type) {
    // eslint-disable-next-line
    case 'date':
        const d = new Date(value);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        return d.toLocaleString('uk', {
          day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
        });
        // eslint-disable-next-line
    case 'moneys':
        const n = value ? Number.parseFloat(value) : 0;
        return (
          <NumberSpan>
            {n.toLocaleString('uk', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </NumberSpan>
        );
      case 'boolVariants':
        return (
          <div>
            {value ? (
              <span>{columns[columnName].variants[0]}</span>
            ) : (
              <span>{columns[columnName].variants[1]}</span>
            )}
          </div>
        );
      case 'bool':
        return (
          <BoolContainer>
            {value && (
            <img src={YesIcon} alt="YesIcon" />
            )}
          </BoolContainer>
        );
      default:
        return (value && value.get && value.getIn)
          ? (
            <span title={value.get('repr', '')}>
              {value.get('repr', '')}
            </span>
          )
          : (
            <span
              title={`${value}`}
            >
              {value}
            </span>
          );
    }
  };

  render() {
    const {
      onClick, onDoubleClick, onToggle, listColumns, row,
      even, iRef, onResizeColumn, onResetColumnSize, modelName, modelType,
      onActionClick,
    } = this.props;
    const id = row.get('id');
    const isActive = row.get('ACTIVE', false);
    const negative = row.get('isDeleted', false);
    const isProcessed = row.get('isProcessed', false);
    return (
      <>
        <DivTogglerItem
          key={`${id}-toggler`}
          active={isActive}
          onClick={(e) => onToggle(e, id)}
          onDoubleClick={(e) => onDoubleClick(e, id)}
          even={even}
          ref={iRef}
        >
          <CheckboxButton
            text="icon"
            style={{
              marginLeft: `${row.get('hierarchyLevel', 0) * 2}em`,
            }}
            row={row}
            isGroup={row.get('isGroup', false)}
            isDeleted={row.get('isDeleted', false)}
            TOGGLED={row.get('TOGGLED', false)}
            isProcessed={row.get('isProcessed', false)}
            ref={this.RowRef}
            onClick={(e) => onToggle(e, id)}
            modelType={modelType}
            modelName={modelName}
          />
        </DivTogglerItem>
        {listColumns.map((d, column) => (
          <ResizableTableCell
            ctxOptions={modelType === 'doc' ? ctxMenuOptionsDoc : ctxMenuOptionsCat}
            onActionClick={(value) => onActionClick(value, id)}
            key={`${id}-${column}`}
            active={isActive}
            onClick={(e) => onClick(e, id)}
            onDoubleClick={(e) => onDoubleClick(e, id)}
            negative={negative}
            even={even}
            onResizeColumn={(e, newWidth) => onResizeColumn(e, column, newWidth)}
            onResetColumnSize={(e) => onResetColumnSize(e, column)}
            processed={isProcessed}
            modelType={modelType}
          >
            {this.getValue(column, row.get(column))}
          </ResizableTableCell>
        )).toList()}
      </>
    );
  }
}

export default listerTableRow;
