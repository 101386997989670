import auth from './auth';
import editor from './editor';
import dpEditor from './dpEditor';
import reportEditor from './reportEditor';
import lister from './lister';
import app from './notifier';
import windowsManager from './windowsManager';

export { auth };
export { editor };
export { dpEditor };
export { reportEditor };
export { lister };
export { app };
export { windowsManager };

const actions = {
  auth,
  editor,
  dpEditor,
  reportEditor,
  lister,
  app,
  windowsManager,
};

export default actions;
