/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/changeToAssignPlanSource';
import { soSelector } from '../../../../_common/selectors';
import DefaultCPButton from '../../../../../../components/bootstrap_components/controls/defaultButton';

const tableMeta = md.tables.balancing;
const WrapperContainer = styled.div`
  ${({ isUpdating }) => (isUpdating ? '' : 'filter: blur(3px) saturate(25%);')}
`;
const LoaderConatiner = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

function BalancingTP({
  data, actions, isUpdating, setIsUpdating, tableData,
}) {
  const onlyYear = data[md.columns.onlyYear.name];
  const FI = data[md.columns.FI.name];

  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);

  return (
    <Card className="border-0">
      <TableHeader pinHeader>
        <ButtonGroup>
          <DefaultCPButton
            variant="info"
            label="Оновити/показати дані"
            onClick={() => {
              setIsUpdating(true);
              actions.onSR('FillBalance');
            }}
          />
        </ButtonGroup>
        <TPHeader />
      </TableHeader>
      <WrapperContainer isUpdating={isUpdating}>
        <Card.Body className="p-0">
          {tableData.map((row, index) => (
            <TPRow
              key={index}
              row={row}
              rowIndex={index}
              onSR={actions.onSR}
              onlyYear={onlyYear}
              FI={FI}
              isFilterCsuElemKFK={isFilterCsuElemKFK}
            />
          ))}
        </Card.Body>
      </WrapperContainer>
      {!isUpdating && (
        <LoaderConatiner>
          <h3>Необхідно оновити дані</h3>
        </LoaderConatiner>
      )}
    </Card>
  );
}

BalancingTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.onlyYear.name]: PropTypes.bool,
    [md.columns.includeBudgetAmplification.name]: PropTypes.bool,
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  tableData: PropTypes.arrayOf({}),
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
};

BalancingTP.defaultProps = {
  tableData: [],
};

export default memo(BalancingTP);
