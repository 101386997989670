import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/financingAnalysis';
import {
  ResultSegment,
  StyledLabel,
  CommandPanelButtonText,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel, ContainerThreeColumn, SevenColumnsGrid,
} from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';
import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import enums from '../../../constants/meta/enums';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer/variantReportContainer';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import DateRangeField from '../../../components/fields/DateRangeField';

const SevenGrid = styled(SevenColumnsGrid)`
  grid-template-columns: 15% 15% 15% 17% 10% 10% 8.5%;
`;

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

function ReportFinancingAnalysisEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
        <div>
          <CheckBoxField
            label={md.columns.consolidated.label}
            value={headerForm.get('consolidated', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
          />
          <CheckBoxField
            label={md.columns.byHighLevelCSU.label}
            value={headerForm.get('byHighLevelCSU', false)}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byHighLevelCSU'], v));
              if (v) {
                dispatch(changeField(['headerForm', 'bySubLevelCSU'], false));
              }
            }}
          />
          <CheckBoxField
            label={md.columns.bySubLevelCSU.label}
            value={headerForm.get('bySubLevelCSU', false)}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'bySubLevelCSU'], v));
              if (v) {
                dispatch(changeField(['headerForm', 'byHighLevelCSU'], false));
              }
            }}
          />
        </div>
      </ContainerThreeColumn>
      <SevenGrid>
        <div>
          <StyledLabel>{md.columns.reportType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('reportType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
            values={Object.values(ReportTypes)}
            name="reportType"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('planType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
            values={Object.values(enums.PlaningKind)
              .map((_e) => ({ label: _e.label, value: _e.name }))}
            name="planType"
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('in1000', false)}
            label={md.columns.in1000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
          <CheckBoxField
            value={headerForm.get('approved', false)}
            label={md.columns.approved.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
          />
          <CheckBoxField
            value={headerForm.get('isDataTreasury', false)}
            label={md.columns.isDataTreasury.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isDataTreasury'], v))}
          />
          <CheckBoxField
            value={headerForm.get('byProposition', false)}
            label={md.columns.byProposition.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'byProposition'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('printReportParams', false)}
            label={md.columns.printReportParams.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'printReportParams'], v))}
          />
          <CheckBoxField
            value={headerForm.get('showClassifiersName', false)}
            label={md.columns.showClassifiersName.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'showClassifiersName'], v))}
          />
          <CheckBoxField
            value={headerForm.get('showCSUNames', false)}
            label={md.columns.showCSUNames.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUNames'], v))}
          />
        </div>
      </SevenGrid>
      {Bookmarks}
      <div style={{ float: 'right' }}>
        <CommandPanelButtonText
          onClick={() => dispatch(processServerReq('STD_SETTINGS'))}
        >
          Встановити стандартні налаштування
        </CommandPanelButtonText>

      </div>
    </div>
  );

  console.log('reportVariants ===== ');
  console.log(reportVariants);
  console.log('reportVariants ===== ');

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            filter={[
              { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportFinancingAnalysisEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportFinancingAnalysisEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportFinancingAnalysisEditor);
