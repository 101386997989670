import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  Year: { label: 'Рік планування', name: 'ГодЗатрат' },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      zf: { label: 'Заг.ф.', name: 'ЗФ' },
      sf: { label: 'Спец.ф.', name: 'СФ' },
      br: { label: 'в т.ч.БР', name: 'БР' },
      sum: { label: 'Разом', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до проекту рішення сесії. Додаток 2 (Джерела фінансування)',
  name: 'changeToDraftAnnualSources',
  backendName: 'ИзменениеПроектаИсточниковФинансирования',
  columns,
  frontend: 'doc/changeToDraftAnnualSources',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'variantBudgetDraft',
      'Year',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
