/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/payment';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TPHeader from './header';
import TPRow from './row';
import Placeholder from '../../../../../../assets/icons/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';

const tableMeta = md.tables.general;

function GeneralTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const FI = data[md.columns.FI.name];
  const budget = data[md.columns.budget.name];
  const typesRepair = data[md.columns.typesRepair.name];

  const elememtKDBVisible = data[md.columns.elememtKDBVisible.name];
  const activeObjectsBuilding = data[md.columns.activeObjectsBuilding.name];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const totals = tableData.reduce((sum, row) => sum + (row[tableMeta.columns.sum.name] || 0), 0);
  const totalsP = tableData.reduce((sum, row) => sum + (row[tableMeta.columns.prepaid.name]
      || 0), 0);

  const getTypesRepair = useCallback((row) => {
    const buildObgect = row[md.tables.general.columns.buildObgect.name];
    return buildObgect ? typesRepair[buildObgect.id] : '';
  }, [typesRepair]);

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          <Button
            variant="primary"
            onClick={() => actions.onSR('FILL_BY_BALANCE')}
          >
            <FontAwesomeIcon icon={faFileUpload} className="me-2" />
            Заповнити
          </Button>
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          activeObjectsBuilding={activeObjectsBuilding}
          elememtKDBVisible={elememtKDBVisible}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            FI={FI}
            budget={budget}
            onSR={actions.onSR}
            activeObjectsBuilding={activeObjectsBuilding}
            getTypesRepair={getTypesRepair}
            elememtKDBVisible={elememtKDBVisible}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          totalsP={totalsP}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
    onSaveWithoutExit: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
