import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TotalCell = styled(TabelRowCell)`
  padding: 6px;
  text-align: right;
  font-weight: bold;
  color: var(--colorTextActiveRow);
`;

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  on3Years,
}) {
  const kdbProps = tableHooks.useItemInputProps(
    'kdb',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const generalFundProps = tableHooks.useNumberInputProps(
    'first_year_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const secondYearGeneralFundProps = tableHooks.useNumberInputProps(
    'second_year_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const thirdYearGeneralFundProps = tableHooks.useNumberInputProps(
    'third_year_zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const specialFundProps = tableHooks.useNumberInputProps(
    'first_year_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const secondYearSpecialFundProps = tableHooks.useNumberInputProps(
    'second_year_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const thirdYearSpecialFundProps = tableHooks.useNumberInputProps(
    'third_year_sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const developmentFundProps = tableHooks.useNumberInputProps(
    'first_year_br',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const secondYearDevelopmentFundProps = tableHooks.useNumberInputProps(
    'second_year_br',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const thirdYearDevelopmentFundProps = tableHooks.useNumberInputProps(
    'third_year_br',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const totals = useMemo(
    () => [
      ((row.first_year_zf || 0) + (row.first_year_sf || 0)).toLocaleString('uk', moneysStingOptions),
      ((row.second_year_zf || 0) + (row.second_year_sf || 0)).toLocaleString('uk', moneysStingOptions),
      ((row.third_year_zf || 0) + (row.third_year_sf || 0)).toLocaleString('uk', moneysStingOptions),
    ],
    [
      row.first_year_sf, row.first_year_zf,
      row.second_year_sf, row.second_year_zf,
      row.third_year_sf, row.third_year_zf,
    ],
  );
  return (
    <>
      <Col>
        <TabelRowCell column="kdb" highlighted={highlights.includes('kdb')}>
          <TableControls.ItemPicker {...kdbProps} />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="first_year_zf" highlighted={highlights.includes('first_year_zf')}>
          <TableControls.NumberInput {...generalFundProps} />
        </TabelRowCell>
        {on3Years && (
        <>
          <TabelRowCell column="second_year_zf" highlighted={highlights.includes('second_year_zf')}>
            <TableControls.NumberInput {...secondYearGeneralFundProps} />
          </TabelRowCell>
          <TabelRowCell column="third_year_zf" highlighted={highlights.includes('third_year_zf')}>
            <TableControls.NumberInput {...thirdYearGeneralFundProps} />
          </TabelRowCell>
        </>
        )}
      </Col>
      <Col>
        <TabelRowCell column="first_year_sf" highlighted={highlights.includes('first_year_sf')}>
          <TableControls.NumberInput {...specialFundProps} />
        </TabelRowCell>
        {on3Years && (
        <>
          <TabelRowCell column="second_year_sf" highlighted={highlights.includes('second_year_sf')}>
            <TableControls.NumberInput {...secondYearSpecialFundProps} />
          </TabelRowCell>
          <TabelRowCell column="third_year_sf" highlighted={highlights.includes('third_year_sf')}>
            <TableControls.NumberInput {...thirdYearSpecialFundProps} />
          </TabelRowCell>
        </>
        )}
      </Col>

      <Col>
        <TabelRowCell column="first_year_br" highlighted={highlights.includes('first_year_br')}>
          <TableControls.NumberInput {...developmentFundProps} />
        </TabelRowCell>
        {on3Years && (
        <>
          <TabelRowCell column="second_year_br" highlighted={highlights.includes('second_year_br')}>
            <TableControls.NumberInput
              {...secondYearDevelopmentFundProps}
            />
          </TabelRowCell>
          <TabelRowCell column="third_year_br" highlighted={highlights.includes('third_year_br')}>
            <TableControls.NumberInput {...thirdYearDevelopmentFundProps} />
          </TabelRowCell>
        </>
        )}
      </Col>
      <Col>
        <TotalCell>
          {totals[0]}
        </TotalCell>
        {on3Years && (
        <>
          <TotalCell>
            {totals[1]}
          </TotalCell>
          <TotalCell>
            {totals[2]}
          </TotalCell>
        </>
        )}
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  on3Years: PropTypes.bool,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  on3Years: false,
};

export default memo(TRow);
