import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const StyledLoaderContainer = styled.div`
  z-index: 1031;
`;

function Loader({ text }) {
  return (
    <StyledLoaderContainer className="position-fixed top-50 start-50 translate-middle p-3 shadow-lg rounded bg-white d-flex align-items-center" role="status">
      <Spinner animation="border" className="me-2" size="sm" />
      <span className="small">
        {text}
      </span>
    </StyledLoaderContainer>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Зачекайте, будь ласка',
};

export default Loader;
