import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  InputGroup, FormCheck,
} from 'react-bootstrap';

export const DropContainerButton = styled(InputGroup)`
   div button {
      background-color: ${(props) => (props.background ? props.background : 'var(--backgroundButton) !important')}
      color: var(--colorTextButton)!important;
        &:hover {
          background-color: var(--hoverButton) !important;
        };
    };
  div div {
    // transform: translate3d(0px, 38px, 0px) !important;
    right: 0px !important;
    left: auto !important;
    width: 100% !important;
    };
`;

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const Radio = forwardRef(({
  value, values, onChange, disabled, readOnly,
  required, errored, className,
}, ref) => (
  <div ref={ref} className={className} aria-required={required}>
    {values.map((v) => (
      <FormCheck
        className="m-2"
        key={v.value}
        type="radio"
        label={v.display_name}
        disabled={v.disabled || disabled || readOnly}
        isInvalid={errored}
        checked={v.value === value}
        onChange={(e) => onChange(e, v.value)}
      />
    ))}
  </div>
));

Radio.propTypes = {
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  errored: PropTypes.bool,
  className: PropTypes.string,
};

Radio.defaultProps = {
  value: null,
  disabled: false,
  readOnly: false,
  required: true,
  errored: false,
  className: null,
};

export default Radio;
