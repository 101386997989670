import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import Styled from 'styled-components';
import { comparisonTypes } from '../../../constants/meta/common';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';

const RadioContainer = Styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const viewModes = {
  self: { label: 'За розпорядником', value: 0 },
  child: { label: 'За розпорядником нижчого рівня', value: 1 },
  all: { label: 'Всі', value: 2 },
};

const viewModesValues = Object.values(viewModes);

/**
 * Компонент для фильтрации документов (свои / подчененные / все )
 * @param foFieldName
 * @param dispatch
 * @param actions
 * @param currentFO
 * @return {*}
 * @constructor
 */

function FOFilterHeaderComponent({
  foFieldName, dispatch, actions, currentFO,
}) {
  const [vMode, setVMode] = useState(viewModes.self.value);

  useEffect(
    () => {
      switch (vMode) {
        case viewModes.self.value:
          dispatch(actions.setFilter(new List([{
            fieldName: foFieldName,
            comparisonType: comparisonTypes.equal,
            value: currentFO,
          }])));
          break;
        case viewModes.child.value:
          dispatch(actions.setFilter(new List([{
            fieldName: `${foFieldName}.Родитель`,
            comparisonType: comparisonTypes.equal,
            value: currentFO,
          }])));
          break;
        default:
          dispatch(actions.setFilter(new List()));
      }
    },
    [actions, currentFO, dispatch, foFieldName, vMode],
  );

  return (
    <RadioContainer>
      <RadioButton
        name="APFilter"
        values={viewModesValues}
        value={vMode}
        onChange={(e, v) => setVMode(v)}
      />
    </RadioContainer>

  );
}

FOFilterHeaderComponent.propTypes = {
  foFieldName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    setFilter: PropTypes.func,
  }),
  currentFO: PropTypes.instanceOf(Map).isRequired,
};

FOFilterHeaderComponent.defaultProps = {
  foFieldName: 'ФинансовыйОрган',
  actions: PropTypes.shape({
    setFilter: () => null,
  }),
};

const mapState = (store) => ({
  currentFO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
});

export default connect(mapState)(FOFilterHeaderComponent);
