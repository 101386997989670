import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={12} md={4}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'treasure_account'}
          highlighted={highlights.includes('treasure_account')}
          onToggleHighlight={() => onToggleHighlight('treasure_account')}
        >
          {fields.treasure_account.label}
        </TableHeaderCell>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7'}
              highlighted={highlights.includes('kbp7')}
              onToggleHighlight={() => onToggleHighlight('kbp7')}
            >
              {fields.kbp7.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kekv'}
              highlighted={highlights.includes('kekv')}
              onToggleHighlight={() => onToggleHighlight('kekv')}
            >
              {fields.kekv.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'receipts'}
              highlighted={highlights.includes('receipts')}
              onToggleHighlight={() => onToggleHighlight('receipts')}
            >
              {fields.receipts.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'excluded'}
              highlighted={highlights.includes('excluded')}
              onToggleHighlight={() => onToggleHighlight('excluded')}
            >
              {fields.excluded.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'received_loans'}
              highlighted={highlights.includes('received_loans')}
              onToggleHighlight={() => onToggleHighlight('received_loans')}
            >
              {fields.received_loans.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'repaided_loans'}
              highlighted={highlights.includes('repaided_loans')}
              onToggleHighlight={() => onToggleHighlight('repaided_loans')}
            >
              {fields.repaided_loans.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'total'}
              highlighted={highlights.includes('total')}
              onToggleHighlight={() => onToggleHighlight('total')}
            >
              {fields.total.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nature_index_amount'}
              highlighted={highlights.includes('nature_index_amount')}
              onToggleHighlight={() => onToggleHighlight('nature_index_amount')}
            >
              {fields.nature_index_amount.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
