import React from 'react';
import GridTemplate from './GridTemplate';
import md from '../../../../../constants/meta';
import { DivHeader } from '../../../../../components/styledTable/styledTable';

const coldef = md.doc.publicBudget.tables.general.columns;

function HeaderComponent() {
  return (
    <GridTemplate>
      <DivHeader areaName="projectPublicBudget">{coldef.projectPublicBudget.label}</DivHeader>
      <DivHeader areaName="id">{coldef.id.label}</DivHeader>
      <DivHeader areaName="sum">{coldef.sum.label}</DivHeader>
      <DivHeader areaName="noteDanger">{coldef.noteDanger.label}</DivHeader>
      <DivHeader areaName="publicBudgetProjectAuthor">{coldef.publicBudgetProjectAuthor.label}</DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
