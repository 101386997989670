import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';

import PrintModal from '../lister/listerPrintModal';

function PrintSubMenu({ id }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <ButtonGroup>
      <PrintModal selectedRows={selectedRows} />
    </ButtonGroup>
  );
}

PrintSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PrintSubMenu;
