import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import styled from 'styled-components';
import { changeField, processServerReq } from '../../../../../actions/editor';
import GridTemplate from './GridTemplate';
import { DivCell, InputCodeStyled } from '../../../../../components/styledTable/styledTable';
import GetNumberInput from '../../../../field/NumberInput';
import Fields from '../../../../field';
import CheckBoxField from '../../../../field/CheckBox';
import DateInput from '../../../../../components/fields/dates';
import ItemField from '../../../../field/selectorField/item/itemField';
import InputWithDropdown from '../../../../../components/styledInputs/inputWithDropdown';

const YearInput = GetNumberInput(4, 0, false);

const TABLENAME = 'general';

const Months = [...Array(12).keys()];

const Sign = {
  first: { name: true, label: '+' },
  second: { name: false, label: '-' },
};

const SumInputStyled = styled(GetNumberInput(13, 2))`
  padding: 0.08em;
  margin-right: 0.2em;
  grid-area: ${(props) => props.areaName};
`;

class RowComponent extends Component {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    FI: PropTypes.instanceOf(Map).isRequired,
    date: PropTypes.number.isRequired,
    setDataRef: PropTypes.func,
    processingKeyDown: PropTypes.func,
    activeAreaName: PropTypes.string,
  };

  static defaultProps = {
    setDataRef: () => null,
    processingKeyDown: () => null,
    activeAreaName: '',
  };

  constructor(props) {
    super(props);

    const arrBypassGrid = [
      'budget', 'FI', 'CSU', 'fondObject', 'elementKFK',
      'elementKEKV', 'currentAccountFI', 'currentAccountCSU', 's1'];
    for (let i = 2; i < 13; i += 1) {
      arrBypassGrid.push(`s${i}`);
    }
    this.state = { arrBypassGrid };
  }

  setData = (rowId, indexCell) => {
    const { setDataRef } = this.props;
    setDataRef(rowId, indexCell, this.state.arrBypassGrid[indexCell]);
  };

  sumChanger = (fName, value) => {
    const { dispatch, rowId, row } = this.props;
    dispatch(changeField([`tables/${TABLENAME}`, rowId], row.set(fName, value).set('Sum', (row.get('Sum', 0) - row.get(fName, 0)) + value)));
  };

  render() {
    const {
      row, rowId, FI, dispatch, processingKeyDown,
      activeAreaName, date,
    } = this.props;

    const sumColumns = Months.reduce((r, i, m) => ([...r,
      <DivCell
        areaName={`s${m + 1}`}
        key={`${rowId}_s${m + 1}`}
        isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === `s${m + 1}`))}
      >
        <SumInputStyled
          value={this.props.row.get(`Sum${m + 1}`, 0)}
          onChange={(e, v) => {
            this.sumChanger(`Sum${m + 1}`, v);
          }}
          areaName={`s${m + 1}`}
          key={`${this.props.rowId}_s${m + 1}`}
          id={this.state.arrBypassGrid.indexOf(`s${m + 1}`)}
          rowId={rowId}
          setDataRef={this.setData}
          processingKeyDown={processingKeyDown}
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === `s${m + 1}`))}
        />
      </DivCell>,
    ]), []);

    return (
      <GridTemplate>
        <DivCell areaName="num">
          <InputCodeStyled
            value={row.get('num', '')}
            readOnly
          />
        </DivCell>
        <DivCell
          areaName="selection"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <CheckBoxField
            value={row.get('selection', false)}
            onChange={(e, value) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'selection'], value));
            }}
          />
        </DivCell>
        <DivCell areaName="document">
          <ItemField
            rows={3}
            noBorder
            noBackground
            value={row.get('document', new Map())}
          />
        </DivCell>
        <DivCell
          areaName="period"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'period'))}
        >
          <DateInput
            noBorder
            background
            value={row.get('period', null)}
            onChange={(e, value) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'period'], value));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('period'));
            }}
            id={this.state.arrBypassGrid.indexOf('period')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'period'))}
          />
        </DivCell>
        <DivCell
          areaName="budget"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'budget'))}
        >
          <Fields.SelectorField
            modelName="budget"
            modelType="cat"
            value={row.get('budget', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'budget'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('budget'));
            }}
            filter={[{ fieldName: 'Владелец', value: FI }]}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('budget')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'budget'))}
          />
        </DivCell>
        <DivCell
          areaName="FI"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'FI'))}
        >
          <Fields.SelectorField
            modelName="csu"
            modelType="cat"
            value={row.get('FI', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'FI'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('FI'));
            }}
            filter={[{ fieldName: 'Бюджет', value: row.get('budget') }]}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('FI')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'FI'))}
          />
        </DivCell>
        <DivCell
          areaName="CSU"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'CSU'))}
        >
          <Fields.SelectorField
            noBackground
            noBorder
            modelName="csu"
            modelType="cat"
            value={row.get('CSU', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'CSU'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('CSU'));
            }}
            filter={[{ fieldName: 'parent', value: FI }]}
            id={this.state.arrBypassGrid.indexOf('CSU')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'CSU'))}
          />
        </DivCell>
        <DivCell
          areaName="fondObject"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'fondObject'))}
        >
          <Fields.SelectorField
            noBackground
            noBorder
            modelName="elementFond"
            modelType="cat"
            value={row.get('fondObject', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'fondObject'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('fondObject'));
            }}
            filter={[{ fieldName: 'Родитель', value: FI }]}
            id={this.state.arrBypassGrid.indexOf('fondObject')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'fondObject'))}
          />
        </DivCell>
        <DivCell areaName="KPKV">
          <InputCodeStyled
            value={row.get('KPKV', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="KEKV">
          <InputCodeStyled
            value={row.get('KEKV', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="KFK">
          <InputCodeStyled
            value={row.get('KFK', '')}
            readOnly
          />
        </DivCell>
        <DivCell
          areaName="elementKFK"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKFK'))}
        >
          <Fields.SelectorField
            noBackground
            noBorder
            modelName="elementKFK"
            modelType="cat"
            value={row.get('elementKFK', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elementKFK'], v));
              dispatch(processServerReq('CHANGE_KFK_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('elementKFK'));
            }}
            filter={[{ fieldName: 'Владелец', value: FI }]}
            id={this.state.arrBypassGrid.indexOf('elementKFK')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKFK'))}
          />
        </DivCell>
        <DivCell
          areaName="elementKEKV"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKEKV'))}
        >
          <Fields.SelectorField
            noBackground
            noBorder
            modelName="elementKEKV"
            modelType="cat"
            value={row.get('elementKEKV', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elementKEKV'], v));
              // dispatch(ea.processServerReq('CHANGE_KEKV_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('elementKEKV'));
            }}
            filter={[{ fieldName: 'Владелец', value: FI }]}
            id={this.state.arrBypassGrid.indexOf('elementKEKV')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKEKV'))}
          />
        </DivCell>
        <DivCell areaName="year">
          <YearInput
            disabled={this.allDisabled}
            value={row.get('year', 5)}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'year'], v));
            }}
          />
        </DivCell>
        <DivCell
          areaName="note"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'note'))}
        >
          <Fields.SelectorField
            rows={3}
            noBorder
            noBackground
            modelName="notes"
            modelType="cat"
            value={row.get('note', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'note'], v));
              dispatch(processServerReq('CHANGE_NOTES_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('note'));
            }}
            // filter={[{ fieldName: 'Владелец', value: row.get('budget') }]}
            id={this.state.arrBypassGrid.indexOf('note')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'note'))}
          />
        </DivCell>
        <DivCell
          areaName="currentAccountFI"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'currentAccountFI'))}
        >
          <Fields.SelectorField
            modelName="bankAccounts"
            modelType="cat"
            value={row.get('currentAccountFI', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'currentAccountFI'], v));
              dispatch(processServerReq('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('currentAccountFI'));
            }}
            filter={[{ fieldName: 'Владелец', value: row.get('CSU', new Map()) }]}
            params={[{
              name: 'НаДату',
              value: date,
            }]}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('currentAccountFI')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'currentAccountFI'))}
          />
        </DivCell>
        <DivCell
          areaName="currentAccountCSU"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'currentAccountCSU'))}
        >
          <Fields.SelectorField
            modelName="bankAccounts"
            modelType="cat"
            value={row.get('currentAccountCSU', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'currentAccountCSU'], v));
              dispatch(processServerReq('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('currentAccountCSU'));
            }}
            filter={[{ fieldName: 'Владелец', value: row.get('CSU', new Map()) }]}
            params={[{
              name: 'НаДату',
              value: date,
            }]}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('currentAccountCSU')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'currentAccountCSU'))}
          />
        </DivCell>
        <DivCell
          areaName="signMainMovement"
        >
          <InputWithDropdown
            noBorder
            nobackground
            value={row.get('signMainMovement', '')}
            options={Object.values(Sign)}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'signMainMovement'], v));
            }}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInputStyled
            fluid
            value={row.get('Sum', 0)}
            onChange={(e, v) => {
              this.sumChanger('Sum1', v);
            }}
          />
        </DivCell>
        {sumColumns}
      </GridTemplate>
    );
  }
}

export default RowComponent;
