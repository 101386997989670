import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';

const meta = md.tables.works;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'ContractorCSU'}
          highlighted={highlights.includes('ContractorCSU')}
          onToggleHighlght={() => onToggleHighlght('ContractorCSU')}
        >
          {meta.columns.ContractorCSU.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'Year'}
          highlighted={highlights.includes('Year')}
          onToggleHighlght={() => onToggleHighlght('Year')}
        >
          {meta.columns.Year.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'PurchaseContractorDate'}
          highlighted={highlights.includes('PurchaseContractorDate')}
          onToggleHighlght={() => onToggleHighlght('PurchaseContractorDate')}
        >
          {meta.columns.PurchaseContractorDate.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'TenderContactNumber'}
          highlighted={highlights.includes('TenderContactNumber')}
          onToggleHighlght={() => onToggleHighlght('TenderContactNumber')}
        >
          {meta.columns.TenderContactNumber.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'ButtonProzzoro'}
          highlighted={highlights.includes('ButtonProzzoro')}
          onToggleHighlght={() => onToggleHighlght('ButtonProzzoro')}
        />
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'TenderDate'}
          highlighted={highlights.includes('TenderDate')}
          onToggleHighlght={() => onToggleHighlght('TenderDate')}
        >
          {meta.columns.TenderDate.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'TenderContactProcType'}
          highlighted={highlights.includes('TenderContactProcType')}
          onToggleHighlght={() => onToggleHighlght('TenderContactProcType')}
        >
          {meta.columns.TenderContactProcType.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'ContractStartDate'}
          highlighted={highlights.includes('ContractStartDate')}
          onToggleHighlght={() => onToggleHighlght('ContractStartDate')}
        >
          {meta.columns.ContractStartDate.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'ContractEndDate'}
          highlighted={highlights.includes('ContractEndDate')}
          onToggleHighlght={() => onToggleHighlght('ContractEndDate')}
        >
          {meta.columns.ContractEndDate.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'TenderAwardDate'}
          highlighted={highlights.includes('TenderAwardDate')}
          onToggleHighlght={() => onToggleHighlght('TenderAwardDate')}
        >
          {meta.columns.TenderAwardDate.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'ConractDocNumber'}
          highlighted={highlights.includes('ConractDocNumber')}
          onToggleHighlght={() => onToggleHighlght('ConractDocNumber')}
        >
          {meta.columns.ConractDocNumber.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'TypeDoc'}
          highlighted={highlights.includes('TypeDoc')}
          onToggleHighlght={() => onToggleHighlght('TypeDoc')}
        >
          {meta.columns.TypeDoc.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'SumDocContractor'}
          highlighted={highlights.includes('SumDocContractor')}
          onToggleHighlght={() => onToggleHighlght('SumDocContractor')}
        >
          {meta.columns.SumDocContractor.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom"
          active={activeCol === 'Note'}
          highlighted={highlights.includes('Note')}
          onToggleHighlght={() => onToggleHighlght('Note')}
        >
          {meta.columns.Note.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'NotCount'}
          highlighted={highlights.includes('NotCount')}
          onToggleHighlght={() => onToggleHighlght('NotCount')}
        >
          {meta.columns.NotCount.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
