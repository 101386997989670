import React, {
  Fragment,
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Form, ListGroup, ListGroupItem, Modal, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import api from '../../../../api/req';
import meta from '../../../../meta';
import { CiatAppContext } from '../../../../providers';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { Loader } from '../../../../components/bootStrap';
import useFormulaItems from './formula/useFormulaItems';
import Cursor from './formula/cursor';

const BUTTONS = [
  ['7', '8', '9', '(', ')'],
  ['4', '5', '6', '*', '/'],
  ['1', '2', '3', '+', '-'],
  ['0', '.', { label: 'del', key: 'Delete' }, { label: '<-', key: 'Backspace' }],
];
function FormulaEditor({
  id, budgetId, onChange, label, readOnly, required, value, errors,
}) {
  const { auth } = useContext(CiatAppContext);
  const [allowedIndicators, setAllowedIndicators] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const loadAllowedIndicators = useCallback(
    async () => {
      const params = {
        budget: budgetId,
        exclude: id ? [id] : [],
      };
      const r = await api.get(`${meta.cat.indicator.backendURL}allowed_indicators/`, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, budgetId, id],
  );

  useEffect(
    () => {
      setLoading(true);
      setErr(null);
      loadAllowedIndicators()
        .then((d) => setAllowedIndicators(d.map((a) => ({
          name: a.short_name,
          title: a.name,
          className: 'bg-primary text-white',
        }))))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [loadAllowedIndicators],
  );

  const {
    items, cursor, onClickHandler, onKeyDownHandler, onButtonHandler, onVariableClick,
    formulaRef, expression,
  } = useFormulaItems({ initialExpression: value, allowedVariables: allowedIndicators });

  const maxButtonsInRow = Math.max(...BUTTONS.map((row) => row.length));
  const onOk = useCallback(
    (e) => {
      setOpened(false);
      onChange(e, expression);
    },
    [expression, onChange],
  );
  useEffect(
    () => {
      if (opened) formulaRef.current.focus();
    },
    [formulaRef, opened],
  );
  return (
    <>
      <Form.Group>
        <Form.Label required={required}>
          {label}
        </Form.Label>
        <div className="input-group">
          <div
            className={classNames('form-control w_-100 d-flex flex-wrap align-items-baseline', { 'is-invalid': errors && errors.length })}
            role="textbox"
            tabIndex={0}
            ref={formulaRef}
            title={value}
            style={{ height: '125px' }}
          >
            {items.map((item, k) => (
              <Fragment key={item.key}>
                <span className={item.className} title={item.title} data-index={k}>
                  {item.value}
                </span>
              </Fragment>
            ))}
          </div>
          <Button variant="secondary" disabled={readOnly} onClick={() => setOpened(true)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          {errors && (
          <Form.Control.Feedback type="invalid">
            {errors.join(', ')}
          </Form.Control.Feedback>
          )}
        </div>
      </Form.Group>
      <Modal show={opened} onHide={() => setOpened(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Редактор формул</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {err && (
          <IconAlert variant="danger">
            {err}
          </IconAlert>
          )}
          {loading && (
          <Loader text="Завантаження" />
          )}
          <Row className="h-100 w-100">
            <Col sm={9} className="d-flex flex-column">
              <div
                className="rounded border w-100 d-flex flex-wrap gap_-1 align-items-baseline flex-fill"
                onClick={onClickHandler}
                onKeyDown={onKeyDownHandler}
                role="textbox"
                tabIndex={0}
                ref={formulaRef}
              >
                {items.map((item, k) => (
                  <Fragment key={item.key}>
                    {cursor === k && (<Cursor />)}
                    <span className={item.className} title={item.title} data-index={k}>
                      {item.value}
                    </span>
                  </Fragment>
                ))}
                {cursor === items.length && (<Cursor />)}
              </div>
              <table className="w-100">
                <tbody>
                  {BUTTONS.map((row, rowIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                    <tr key={rowIndex}>
                      {row.map((item, itemIndex) => (
                        <td
                    // eslint-disable-next-line react/no-array-index-key
                          key={itemIndex}
                          colSpan={!itemIndex ? maxButtonsInRow - row.length + 1 : 1}
                        >
                          <Button variant="outline-secondary" className="w-100" onClick={() => onButtonHandler(item.key || item)}>
                            {item.label || item}
                          </Button>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col sm={3} className="border rounded py-2">
              <h5 className="pb-1 mb-2 border-bottom text-secondary">Доступні показники:</h5>
              <ListGroup className="h-100 scrollbar">
                {allowedIndicators.map((ai) => (
                  <ListGroupItem
                    title={ai.title}
                    action
                    className={ai.className}
                    onDoubleClick={() => onVariableClick(ai.name)}
                  >
                    <div className="fw-bold">
                      {ai.name}
                    </div>
                    {ai.title}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOk}>
            <FontAwesomeIcon icon={faCheck} className="me-1" />
            ОК
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FormulaEditor.propTypes = {
  id: PropTypes.number,
  budgetId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
};

FormulaEditor.defaultProps = {
  id: null,
  budgetId: null,
  label: '',
  readOnly: false,
  required: false,
  value: '',
  errors: null,
};

export default FormulaEditor;
