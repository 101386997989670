import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/liquidity';

const meta = md.tables.dod1;
const getMonthName = (m) => {
  const d = new Date();
  d.setMonth(m - 1, 1);
  return d.toLocaleString('uk', { month: 'long' });
};

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  month1, month2, month3,
  year1, year2, year3,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={8} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'routeCode'}
              highlighted={highlights.includes('routeCode')}
              onToggleHighlght={() => onToggleHighlght('routeCode')}
            >
              {meta.columns.routeCode.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'route'}
              highlighted={highlights.includes('route')}
              onToggleHighlght={() => onToggleHighlght('route')}
            >
              {meta.columns.route.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fond'}
              highlighted={highlights.includes('fond')}
              onToggleHighlght={() => onToggleHighlght('fond')}
            >
              {meta.columns.fond.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {`${`${getMonthName(month1)} ${year1}р.`}`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum4'}
              highlighted={highlights.includes('sum4')}
              onToggleHighlght={() => onToggleHighlght('sum4')}
            >
              {`${`${getMonthName(month1)} ${year1} р. ${meta.columns.sum4.label}`}`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              {`${`${getMonthName(month2)} ${year2} р.`}`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum5'}
              highlighted={highlights.includes('sum5')}
              onToggleHighlght={() => onToggleHighlght('sum5')}
            >
              {`${`${getMonthName(month2)} ${year2} р. ${meta.columns.sum5.label}`}`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum3'}
              highlighted={highlights.includes('sum3')}
              onToggleHighlght={() => onToggleHighlght('sum3')}
            >
              {`${`${getMonthName(month3)} ${year3} р.`}`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum6'}
              highlighted={highlights.includes('sum6')}
              onToggleHighlght={() => onToggleHighlght('sum6')}
            >
              {`${`${getMonthName(month3)} ${year3} р. ${meta.columns.sum6.label}`}`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  month1: PropTypes.string,
  month2: PropTypes.string,
  month3: PropTypes.string,
  year1: PropTypes.number,
  year2: PropTypes.number,
  year3: PropTypes.number,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  month1: '',
  month2: '',
  month3: '',
  year1: 0,
  year2: 0,
  year3: 0,
  /// /
};

export default memo(TPHeader);
