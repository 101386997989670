const frontendURL = '/cat/manual/';
const backendURL = '/api/informations/pages/';
const name = 'Довідкова інформація';

const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  backendURL,
  name,
  getVisibility,
};

export default instance;
