const frontendURL = '/doc/budgetProject/';
const backendURL = '/api/planproject/devbudgetprojectheader/';
const name = 'Розподіл коштів бюджету розвитку (Дод. 6 до РС)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
