/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'devbudgetprojectrow_set', readOnlyFields, errors, fields,
  });

  const totals = useMemo(
    () => tableData.reduce(
      (Res, r) => ({
        year_amount: Res.year_amount + (r.year_amount || 0),
        total_amount: Res.total_amount + (r.total_amount || 0),
      }),
      {
        year_amount: 0,
        total_amount: 0,
      },
    ),
    [tableData],
  );

  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            onMoveRow={tableActions.onMoveRow}
            tableName="devbudgetprojectrow_set"
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              authorityId={data.authority.id}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </TableContainer>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    devbudgetprojectrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    authority: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.number,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    devbudgetprojectrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    devbudgetprojectrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    devbudgetprojectrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
