import { Map } from 'immutable';
import variants from '../../constants/meta/enums/periodSelection';

export const getDatesByVariant = (variant) => {
  const StartDate = new Date();
  const EndDate = new Date();

  // Квартал
  const q = ((StartDate.getMonth() - (StartDate.getMonth() % 3)) / 3) + 1;

  switch (variant) {
    case variants.Custom.name:
      return new Map();
    case variants.FromBeginningOfThisYear.name:
      StartDate.setMonth(0, 1);
      break;
    case variants.ThisMonth.name:
      StartDate.setDate(1);
      EndDate.setMonth(EndDate.getMonth() + 1, 0);
      break;
    case variants.ThisQuarter.name:
      StartDate.setMonth(((q - 1) * 3), 1);
      EndDate.setMonth((q * 3), 0);
      break;
    case variants.ThisWeek.name:
      StartDate.setDate(StartDate.getDate() - StartDate.getDay() + 1);
      EndDate.setDate(EndDate.getDate() - EndDate.getDay() + 7);
      break;
    case variants.ThisYear.name:
    // !!!!!
      StartDate.setMonth(0, 1);
      EndDate.setMonth(12, 0);
      break;
    case variants.Today.name:
    // !!!!!
      break;
    default:
      console.log('Unknow period type');
  }
  StartDate.setHours(0, -StartDate.getTimezoneOffset(), 0);
  EndDate.setHours(23, 59 - EndDate.getTimezoneOffset(), 59);
  return new Map({ StartDate: StartDate.valueOf(), EndDate: EndDate.valueOf() });
};

export default getDatesByVariant;
