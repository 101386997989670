const frontendURL = '/cat/task/';
const backendURL = '/api/references/refpcmtask/';
const name = 'Завдання';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
