import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: false },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  onState: { label: 'Станом на', name: 'СтаномНа', visible: false },
  month2: { label: 'Місяць', name: 'Месяц2' },
  month3: { label: 'Місяць', name: 'Месяц3' },
  month1: { label: 'Місяць', name: 'Месяц1' },
  year2: { label: 'Рік', name: 'Год2' },
  year3: { label: 'Рік', name: 'Год3' },
  year1: { label: 'Рік', name: 'Год1' },
  method: { label: 'Метод розрахунку', name: 'Метод1Дод' },
};

/**
 * @const
 */
const tables = {
  dod1: {
    name: 'Додаток1',
    label: 'Додаток 1',
    columns: {
      date: { label: 'Дата', name: 'Дата' },
      dateStr: { label: 'Дата', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Месяц' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'КЕКВ', name: 'Напрям' },
      sum: { label: 'місяць.рік', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд (загальний/ спеціальний)', name: 'Фонд' },
      fondStr: { label: 'Фонд (загальний/ спеціальний)', name: 'ФондСтр' },
      sum2: { label: 'місяць.рік', name: 'Сумма2' },
      sum3: { label: 'місяць.рік', name: 'Сумма3' },
      sum4: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма4' },
      sum5: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма5' },
      sum6: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма6' },
      year2: { label: 'Рік', name: 'Год2' },
      year3: { label: 'Рік', name: 'Год3' },
      month2: { label: 'Місяць', name: 'Месяц2' },
      month3: { label: 'Місяць', name: 'Месяц3' },
      routeCode: { label: 'Код', name: 'НапрямКод' },
    },
  },
  dod2: {
    name: 'Додаток2',
    label: 'Додаток 2',
    columns: {
      date: { label: 'Дата', name: 'Дата' },
      dateStr: { label: 'Дата', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Месяц' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг касових видатків', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд (загальний/ спеціальний)', name: 'Фонд' },
      fondStr: { label: 'Фонд (загальний/ спеціальний)', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  dod3: {
    name: 'Додаток3',
    label: 'Додаток 3',
    columns: {
      date: { label: 'Дата', name: 'Дата' },
      dateStr: { label: 'Дата', name: 'ДатаСтр' },
      month: { label: 'Місяць, у який очікуються надходження', name: 'Месяц' },
      year: { label: 'Рік, у який очікуються надходження', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг виконання місцевого бюджету за доходами, що не контролюються ДПС ', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  dod4: {
    name: 'Додаток4',
    label: 'Додаток 4',
    columns: {
      date: { label: 'Дата, у яку очікуються надходження', name: 'Дата' },
      dateStr: { label: 'Дата, у яку очікуються надходження', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг виконання місцевого бюджету за доходами, що не контролюються ДПС ', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  dod5: {
    name: 'Додаток5',
    label: 'Додаток 2',
    columns: {
      date: { label: 'Дата платежу', name: 'Дата' },
      dateStr: { label: 'Дата платежу', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг операцій Розміщення', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Очікуваний обсяг операцій Повернення', name: 'Сумма2' },
    },
  },
  dod6: {
    name: 'Додаток6',
    label: 'Додаток 6',
    columns: {
      date: { label: 'Дата здійснення повернення', name: 'Дата' },
      dateStr: { label: 'Дата здійснення повернення', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг повернення тимчасово-вільних коштів з депозитних рахунків', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  dod7: {
    name: 'Додаток7',
    label: 'Додаток 3',
    columns: {
      date: { label: 'Дата здійснення запозичення', name: 'Дата' },
      dateStr: { label: 'Дата здійснення запозичення', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Сума надходження', name: 'Сумма' },
      currency: { label: 'Код валют по ISO 4217 ', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  dod8: {
    name: 'Додаток8',
    label: 'Додаток 4',
    columns: {
      date: { label: 'Дата платежу', name: 'Дата' },
      dateStr: { label: 'Дата платежу', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Сума платежу', name: 'Сумма' },
      currency: { label: 'Код валют по ISO 4217 ', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Обсяг обслуговування', name: 'Сумма2' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи розпорядників',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація щодо ліквідності',
  name: 'liquidity',
  backendName: 'ИнформацияЛиквидности',
  columns,
  frontend: 'doc/liquidity',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
