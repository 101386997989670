const frontendURL = '/cat/templateBaseDoc/';
const backendURL = '/api/references/reftemplateddocumentbasis/';
const name = 'Підстава для довідок змін';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
