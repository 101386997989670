import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={6}>&nbsp;</Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'zag_fond'}
          highlighted={highlights.includes('zag_fond')}
        >
          {totals.zag_fond.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'spec_fond'}
          highlighted={highlights.includes('spec_fond')}
        >
          {totals.spec_fond.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'total'}
          highlighted={highlights.includes('total')}
        >
          {totals.total.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    spec_fond: PropTypes.number,
    zag_fond: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
