/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import AttachedFilesList from '../../../newEditor/attachedFiles';

function APMenu({
  id, data, loading, ...rest
}) {
  return (
    <DocEditorCommandPanel
      id={id}
      loading={loading}
      {...rest}
    >
      <AttachedFilesList
        data={data}
        loading={loading}
        {...rest}
        size="sm"
      />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape({}).isRequired,
};

APMenu.defaultProps = {
  loading: false,
};

export default APMenu;
