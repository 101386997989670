import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Col, Container, Row,
} from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { FieldPropType } from '../../../newEditor/propTypes';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryBudget({
  data, fields, actions, id, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew, err,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.budget.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });
  // console.log(historyData);
  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const fullNameProps = editorHooks.useStringInputProps('fullname', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const codeProps = editorHooks.useStringInputProps('code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const koatuuProps = editorHooks.useStringInputProps('koatuu', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const genetiveNameProps = editorHooks.useStringInputProps('genetivename', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const parentProps = editorHooks.useItemInputProps('parent', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const treasuryCodeProps = editorHooks.useStringInputProps('treasury_code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const areaCodeProps = editorHooks.useStringInputProps('area_code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const cityDistrictRegionProps = editorHooks.useStringInputProps('city_district_region', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const kindProps = editorHooks.useSelectorInputProps('kind', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const selfgnameProps = editorHooks.useStringInputProps('selfgname', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
    }),
    [],
  );

  const [code, setCode] = useState('');
  useEffect(() => {
    setCode(historyData.code || '');
  }, [historyData.code]);

  const onCodeChange = useCallback(
    (e, v) => {
      setCode(v);
      if (v.trim().length === 10) codeProps.onChange(e, v);
    },
    [codeProps],
  );

  const codeErrors = useMemo(
    () => [
      ...(codeProps.errors || []),
      ...(code.trim().length !== 10 ? ['Довжина коду має бути 10 символів'] : []),
    ],
    [code, codeProps.errors],
  );
  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
        <Container fluid className="pb-3">
          {historyData.is_group && (
          <div className="w-100 text-end">
            <Badge bg="info">Група</Badge>
          </div>
          )}
          {err && <IconAlert variant="danger">{err}</IconAlert>}
          <Row>
            <Col lg={2}>
              <EditorControls.StringInput
                {...codeProps}
                inputMask="9999999999"
                value={code}
                onChange={onCodeChange}
                errors={codeErrors}
              />
            </Col>
            <Col lg={2}>
              <EditorControls.StringInput {...koatuuProps} inputMask="9999999999" />
            </Col>
            <Col lg={2}>
              <EditorControls.StringInput {...treasuryCodeProps} inputMask="999" />
            </Col>
            <Col lg={1}>
              <EditorControls.StringInput {...areaCodeProps} inputMask="99" />
            </Col>
            <Col lg={5}>
              <EditorControls.StringInput {...nameProps} />
            </Col>
            <Col lg={6}>
              <EditorControls.StringInput {...fullNameProps} />
            </Col>
            <Col lg={6}>
              <EditorControls.StringInput {...selfgnameProps} />
            </Col>
            <Col lg={4}>
              <EditorControls.StringInput {...genetiveNameProps} />
            </Col>
            <Col lg={historyData.is_group ? 8 : 4}>
              <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
            </Col>
            {!historyData.is_group && (
            <Col lg={4}>
              <EditorControls.SelectorInput {...kindProps} required />
            </Col>
            )}
            <Col lg={12}>
              <EditorControls.StringInput {...cityDistrictRegionProps} />
            </Col>
            <Col className="d-flex align-items-end">
              <EditorControls.CheckboxInput {...isActiveProps} />
            </Col>
          </Row>
        </Container>
      </HistoryContainer>
    </>
  );
}
const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  fullname: PropTypes.string,
  genetivename: PropTypes.string,
  is_active: PropTypes.bool,
  parent: PropTypes.shape({ }),
  settings: PropTypes.shape({}),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryBudget.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.shape(dataPropTypes),
  }),
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape(fieldsPropTypes),
  }),
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}),
};

HistoryBudget.defaultProps = {
  data: { history: {} },
  fields: { history: {} },
  errors: null,
};

export default HistoryBudget;
