import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  Year: { label: 'Рік планування', name: 'ГодЗатрат' },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Перелік кредитів (позик) для інвестиційних проектів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KBP7Code: { label: 'Код КПКВ', name: 'КодКПКВ' },
      loaner: { label: 'Кредитор', name: 'Контрагент' },
      targetProg: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
      nomDateLoan: { label: 'Номер та дата договору', name: 'НомерДатаДоговора' },
      deadLineLoan: { label: 'Термін кредитування', name: 'СрокКредита' },
      currency: { label: 'Назва валюти', name: 'Валюта' },
      sumYear: { label: 'Обсяг кредиту (позики) у плановому році', name: 'СуммаКредитаНаГод' },
      sumTotal: { label: 'загальний обсяг залучення кредиту (позики)', name: 'СуммаПривлеченияКредита' },
      sum: { label: 'загальний обсяг кредиту (позики)', name: 'СуммаКредита' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до проекту рішення сесії. Додаток 8 (Кредити, позики)',
  name: 'changeDraftAnnualLoan',
  backendName: 'ИзмененияПроектаПозик',
  columns,
  frontend: 'doc/changeDraftAnnualLoan',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'variantBudgetDraft',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
