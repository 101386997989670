import React from 'react';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.dp.messageForUsers.tables.users.columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="send">{coldef.send.label}</DivHeader>
      <DivHeader areaName="user">{coldef.user.label}</DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
