/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// import { docEditorMenu2 as BasicMenu } from '../../../../components/editorMenu';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';

import md from '../../../../constants/meta/catalogs/planing';
import { emptyUid } from '../../../../constants/meta/common';
import logikaMD from '../../../../constants/meta/documents/docTransferToLogica';

// eslint-disable-next-line react/prop-types
function APMenu({ id, actions, ...rest }) {
  const navigate = useNavigate();
  const onSendToLigika = useCallback(
    // eslint-disable-next-line react/prop-types
    () => actions.onSR('CreateDispatchDoc').then((d) => {
      if (d && d[md.columns.docSendToLogica.name]
        && d[md.columns.docSendToLogica.name].id !== emptyUid) {
        navigate(`/${logikaMD.frontend}/${d[md.columns.docSendToLogica.name].id}`);
      }
    }),
    [actions, navigate],
  );
  return (
    <DocEditorCommandPanel
      id={id}
      actions={actions}
      {...rest}
    >
      <Button variant="success" onClick={onSendToLigika} disabled={id === 'create'} size="sm">
        <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
        Надіслати до LOGICA
      </Button>
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
