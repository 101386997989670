import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/elementFond';
import { StyledLabel } from '../../../../components/Form/styledForm';
import SelectorField from '../../../field/stdSelectorField';
import meta from '../../../../constants/meta';
import DateInput from '../../../../components/fields/dates';

const dateParam = 'НаДату';

const mapState = (store) => {
  const sessionBudget = store.getIn(['auth', 'sessionOptions', 'budget'], new Map());
  const sessionFO = store.getIn(['auth', 'sessionOptions', 'fo'], new Map());
  const isAdmin = store.getIn(['auth', 'sessionOptions', 'is_admin'], false);
  return {
    sessionBudget,
    sessionFO,
    isAdmin,
  };
};

function ElementListerSelector({
  filter, params, children, ...restProps
}) {
  const { sessionBudget, sessionFO, isAdmin } = useSelector(mapState);

  const [budget, setBudget] = useState(sessionBudget);
  const [fo, setFO] = useState(filter
    .filter((f) => f.fieldName === md.columns.owner.name)
    .reduce((R, f) => (Map.isMap(f.value) ? f.value : new Map(f.value)), sessionFO));
  const [csu, setCSU] = useState(new Map());
  const [currentDate, setCurrentDate] = useState(() => params.filter(
    (p) => p.name === dateParam,
  ).reduce((R, p) => p.value, new Date().valueOf()));

  const flt = useMemo(
    () => {
      const preparedFilter = filter
        .map((f, i) => {
          if (typeof f.value === 'object' && !Array.isArray(f.value) && !Map.isMap(f.value)) {
            return { ...f, value: new Map(f.value) };
          }
          return f;
        });
      return [
        ...preparedFilter.filter(
          (f) => ![md.columns.owner.name].includes(f.fieldName),
        ),
        {
          fieldName: md.columns.owner.name,
          value: fo,
        },
        ...csu.get('id') ? [{
          fieldName: md.columns.Steward.name,
          value: csu,
        }] : [],
      ];
    },
    [csu, filter, fo],
  );
  const prm = useMemo(
    () => [
      ...params.filter((p) => p.name !== dateParam),
      {
        name: dateParam,
        value: currentDate,
      },
    ],
    [currentDate, params],
  );

  const CH = cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
    params: prm,
  });

  return (
    <>
      <Row>
        <Col>
          <StyledLabel>Бюджет</StyledLabel>
          <SelectorField
            modelName="budget"
            modelType="cat"
            value={budget}
            disabled={!isAdmin}
            onChange={(e, v) => {
              setCSU(new Map());
              setFO(new Map());
              setBudget(v);
            }}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.owner.label}</StyledLabel>
          <SelectorField
            modelName="csu"
            modelType="cat"
            disabled={!isAdmin}
            value={fo}
            filter={[{
              fieldName: meta.cat.csu.columns.Budget.name,
              value: budget,
            }]}
            onChange={(e, v) => {
              setCSU(new Map());
              setFO(v);
            }}
          />
        </Col>
        <Col>
          <StyledLabel>Показувати на дату</StyledLabel>
          <DateInput
            value={currentDate}
            onChange={(e, v) => setCurrentDate(v)}
          />
        </Col>
      </Row>
      {CH}
    </>
  );
}

ElementListerSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.instanceOf(Map),
    ]),
  })),
  children: PropTypes.element.isRequired,
};

ElementListerSelector.defaultProps = {
  filter: [],
  params: [],
};

export default ElementListerSelector;
