import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Container fluid>
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'which_kekv'}
              highlighted={highlights.includes('which_kekv')}
              onToggleHighlight={() => onToggleHighlight('which_kekv')}
            >
              {fields.which_kekv.label}
            </TableHeaderCell>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_total'}
                  highlighted={highlights.includes('consolidated_plan_total')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_total')}
                >
                  {fields.consolidated_plan_total.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_pay'}
                  highlighted={highlights.includes('consolidated_plan_pay')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_pay')}
                >
                  {fields.consolidated_plan_pay.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_labor_payment'}
                  highlighted={highlights.includes('consolidated_plan_labor_payment')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_labor_payment')}
                >
                  {fields.consolidated_plan_labor_payment.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_medicines'}
                  highlighted={highlights.includes('consolidated_plan_medicines')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_medicines')}
                >
                  {fields.consolidated_plan_medicines.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_food'}
                  highlighted={highlights.includes('consolidated_plan_food')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_food')}
                >
                  {fields.consolidated_plan_food.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_services'}
                  highlighted={highlights.includes('consolidated_plan_services')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_services')}
                >
                  {fields.consolidated_plan_services.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_social'}
                  highlighted={highlights.includes('consolidated_plan_social')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_social')}
                >
                  {fields.consolidated_plan_social.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'consolidated_plan_other'}
                  highlighted={highlights.includes('consolidated_plan_other')}
                  onToggleHighlight={() => onToggleHighlight('consolidated_plan_other')}
                >
                  {fields.consolidated_plan_other.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_total'}
                  highlighted={highlights.includes('utilization_plan_total')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_total')}
                >
                  {fields.utilization_plan_total.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_pay'}
                  highlighted={highlights.includes('utilization_plan_pay')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_pay')}
                >
                  {fields.utilization_plan_pay.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_labor_payment'}
                  highlighted={highlights.includes('utilization_plan_labor_payment')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_labor_payment')}
                >
                  {fields.utilization_plan_labor_payment.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_medicines'}
                  highlighted={highlights.includes('utilization_plan_medicines')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_medicines')}
                >
                  {fields.utilization_plan_medicines.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_food'}
                  highlighted={highlights.includes('utilization_plan_food')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_food')}
                >
                  {fields.utilization_plan_food.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_services'}
                  highlighted={highlights.includes('utilization_plan_services')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_services')}
                >
                  {fields.utilization_plan_services.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_social'}
                  highlighted={highlights.includes('utilization_plan_social')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_social')}
                >
                  {fields.utilization_plan_social.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'utilization_plan_other'}
                  highlighted={highlights.includes('utilization_plan_other')}
                  onToggleHighlight={() => onToggleHighlight('utilization_plan_other')}
                >
                  {fields.utilization_plan_other.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_total'}
                  highlighted={highlights.includes('cash_expenses_total')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_total')}
                >
                  {fields.cash_expenses_total.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_pay'}
                  highlighted={highlights.includes('cash_expenses_pay')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_pay')}
                >
                  {fields.cash_expenses_pay.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_labor_payment'}
                  highlighted={highlights.includes('cash_expenses_labor_payment')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_labor_payment')}
                >
                  {fields.cash_expenses_labor_payment.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_medicines'}
                  highlighted={highlights.includes('cash_expenses_medicines')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_medicines')}
                >
                  {fields.cash_expenses_medicines.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_food'}
                  highlighted={highlights.includes('cash_expenses_food')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_food')}
                >
                  {fields.cash_expenses_food.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_services'}
                  highlighted={highlights.includes('cash_expenses_services')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_services')}
                >
                  {fields.cash_expenses_services.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_social'}
                  highlighted={highlights.includes('cash_expenses_social')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_social')}
                >
                  {fields.cash_expenses_social.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_other'}
                  highlighted={highlights.includes('cash_expenses_other')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_other')}
                >
                  {fields.cash_expenses_other.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
