import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  SelectorInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/netChange';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import enums from '../../../../../../constants/meta/enums';

const tablename = 'general';

const tableMD = md.tables[tablename];

const planingKindsOptions = Object.values(enums.PlaningKindChange).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onChIdChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.chId.name]: v });
  }, [onRowChange]);

  const onNoteChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.note.name]: v });
  }, [onRowChange]);

  const onTarifChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.csu.name]: v });
      await onSR('CHANGE_TARIF_IN_GENERAL', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const CSUAccountFIlter = useMemo(
    () => [
      { fieldName: 'Родитель', value: FI },
    ],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="csu" highlighted={highlights.includes('csu')}>
              <ItemPicker
                value={row[tableMD.columns.csu.name]}
                modelType="cat"
                modelName="csu"
                onChange={onTarifChange}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                filter={CSUAccountFIlter}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="chId" highlighted={highlights.includes('chId')}>
              <SelectorInput
                background="inherit"
                readOnly={readOnly}
                value={row[tableMD.columns.chId.name]}
                values={planingKindsOptions}
                onChange={onChIdChange}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <TextInput
                value={row[tableMD.columns.note.name]}
                onChange={onNoteChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.csu.name]: referencePropType,
    [tableMD.columns.chId.name]: referencePropType,
    [tableMD.columns.note.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  onSR: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
