import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar, Navbar } from 'react-bootstrap';
import {
  faPlusSquare,
  faCaretSquareUp,
  faCaretSquareDown,
  faClone,
  faMinusSquare,
  faSearchDollar,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../components/bootstrap_components/controls/defaultButton';

function TPCommandPanel({
  children,
  tableActions,
  readOnly,
  canAdd,
  canDelete,
  canMove,
  activeRow,
  pinHeader,
  showFooter,
}) {
  return (
    <Navbar className="bg-white border rounded-top border-top-0">
      <ButtonToolbar className="w-100" size="sm">
        <ButtonGroup className="me-1" size="sm">
          <DefaultCPButton
            onClick={() => tableActions.onAddRow()}
            disabled={readOnly || !canAdd}
            label="Додати (Insert)"
            icon={faPlusSquare}
          />
        </ButtonGroup>
        <ButtonGroup className="me-1" size="sm">
          <DefaultCPButton
            onClick={() => tableActions.onMoveUpRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити вище"
            icon={faCaretSquareUp}
          />
          <DefaultCPButton
            onClick={() => tableActions.onMoveDownRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити нижче"
            icon={faCaretSquareDown}
          />
        </ButtonGroup>
        <ButtonGroup className="me-1" size="sm">
          <DefaultCPButton
            onClick={() => tableActions.onCopyRow(activeRow)}
            disabled={readOnly || !canAdd || activeRow === null}
            label="Копіювати (F9)"
            icon={faClone}
          />
          <DefaultCPButton
            onClick={() => tableActions.onDeleteRow(activeRow)}
            disabled={readOnly || !canDelete || activeRow === null}
            label="Видалити (F8)"
            variant="danger"
            icon={faMinusSquare}
          />
        </ButtonGroup>
        {children}
        <ButtonGroup className="ms-auto" size="sm">
          <DefaultCPButton
            onClick={() => tableActions.onToggleFooter()}
            label="Показати підвал"
            variant={showFooter ? 'info' : 'outline-info'}
            icon={faSearchDollar}
          />
          <DefaultCPButton
            onClick={() => tableActions.onTogglePin()}
            label="Закріпити заговолок та підвал"
            variant={pinHeader ? 'info' : 'outline-info'}
            icon={faThumbtack}
          />
        </ButtonGroup>
      </ButtonToolbar>
    </Navbar>
  );
}

TPCommandPanel.propTypes = {
  tableActions: PropTypes.shape({
    onAddRow: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    onMoveUpRow: PropTypes.func.isRequired,
    onMoveDownRow: PropTypes.func.isRequired,
    onCopyRow: PropTypes.func.isRequired,
    onToggleFooter: PropTypes.func.isRequired,
    onTogglePin: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.symbol, PropTypes.arrayOf(PropTypes.symbol),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  canMove: PropTypes.bool,
  activeRow: PropTypes.number,
  showFooter: PropTypes.bool.isRequired,
  pinHeader: PropTypes.bool.isRequired,
};

TPCommandPanel.defaultProps = {
  readOnly: false,
  children: null,
  canAdd: true,
  canDelete: true,
  canMove: true,
  activeRow: null,
};

export default TPCommandPanel;
