import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/indicatorFact';
import {
  ColorTextStamp,
  ContainerStamp,
  ImgContainerStamp,
  StyledLabel,
  ContainerThreeColumn,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import { soSelector } from '../../_common/selectors';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from '../docSelectionModal';

function IndicatorFact({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);

  const isAdmin = sessionOptions.get('is_admin', false);
  const readOnly = !permissions.canChange;
  const budget = data[md.columns.budget.name];

  const FI = data[md.columns.FI.name];
  const csuFilter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIFilter = useMemo(() => [{
    fieldName: 'ФинансовыйОрган',
    value: FI,
  }], [FI]);
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );

  const ApprovedLabel = (
    <ContainerThreeColumn>
      {isAdmin && (
        <EditorControls.CheckboxInput
          controlId={`isApproved-${data.id}`}
          value={data[md.columns.isApproved.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.isApproved.name]: value,
          })}
          label={md.columns.isApproved.label}
          readOnly={readOnly}
        />
      )}
      {data[md.columns.isApproved.name] && isAdmin && (
        <div>
          <EditorControls.StringInput
            label={md.columns.received.label}
            value={data[md.columns.received.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.received.name]: value,
            })}
            readOnly={readOnly}
          />
        </div>
      )}
      <ContainerStamp>
        {data[md.columns.isApproved.name] && (
          <ImgContainerStamp background={StampApproved}>
            <ColorTextStamp color="#4281c9">
              {data[md.columns.received.name]}
            </ColorTextStamp>
          </ImgContainerStamp>
        )}
      </ContainerStamp>
    </ContainerThreeColumn>
  );

  return (
    <EditorCollapse>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.signatureVariants.label}
            value={data[md.columns.signatureVariants.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.signatureVariants.name]: value,
            })}
            modelType="cat"
            modelName="signatureVariants"
            readOnly={readOnly}
          />
        </Col>
        <Col style={{ alignSelf: 'end' }}>
          <StyledLabel>
            {md.columns.KBPName.label}
            :
            {' '}
            {data[md.columns.KBPName.name]}
          </StyledLabel>
          {data[md.columns.docByKBP7.name] ? (
            <StyledLabel>
              {md.columns.codClassifierKBP7.label}
              :
              {' '}
              {data[md.columns.codClassifierKBP7.name]}
            </StyledLabel>
          ) : (
            <StyledLabel>
              {md.columns.KFKCode.label}
              :
              {' '}
              {data[md.columns.KFKCode.name]}
            </StyledLabel>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.indicatorPassport.label}
            value={data[md.columns.indicatorPassport.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.indicatorPassport.name]: value,
              });
              actions.onSR('CHANGE_PASSPORT');
            }}
            filter={FIFilter}
            modelType="doc"
            modelName="indicatorPassport"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.indicatorFactLastYear.label}
            value={data[md.columns.indicatorFactLastYear.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.indicatorFactLastYear.name]: value,
              });
            }}
            filter={FIFilter}
            modelType="doc"
            modelName="indicatorFact"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.CSU.label}
            value={data[md.columns.CSU.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.CSU.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            filter={csuFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          { data[md.columns.docByKBP7.name] ? (
            <StyledLabel style={{ alignSelf: 'end' }}>
              {`${md.columns.KPKV.label}: ${data[md.columns.KPKV.name] && data[md.columns.KPKV.name].repr}`}
            </StyledLabel>
          ) : (
            <StyledLabel style={{ alignSelf: 'end' }}>
              {`${md.columns.elementKFK.label}: ${data[md.columns.elementKFK.name] && data[md.columns.elementKFK.name].repr}`}
            </StyledLabel>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            CSU={data[md.columns.CSU.name]}
            year={data[md.columns.year.name]}
            FI={FI}
            budget={budget}
            KPKV={data[md.columns.KPKV.name]}
            elementKFK={data[md.columns.elementKFK.name]}
          />
        </Col>
      </Row>
      {ApprovedLabel}
    </EditorCollapse>
  );
}

IndicatorFact.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default IndicatorFact;
