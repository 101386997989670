import styled from 'styled-components';
import { ListGroupItem } from 'react-bootstrap';

// eslint-disable-next-line import/prefer-default-export
export const SListGroupItem = styled(ListGroupItem)`
  &.active{
    background: rgb(0 123 255 / 10%);
    color: rgba(0,0,0,.87);
  }
`;
//   .attrs((props) => ({
//   style: { marginLeft: `${(props.level || 0) * 2}rem` },
// }))
