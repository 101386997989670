import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокСсылка' },
};

/**
 * @const
 */
const definition = {
  name: 'registrationFormPrinting',
  backendName: 'ПечатьКартаЗаявка',
  label: 'Друк',
  frontend: 'dp/registrationFormPrinting',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
