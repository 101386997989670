import {
  defaultColumns,
  reqListerColumns,
  hierarchyTypes, directions,
} from '../common';

const columns = {
  label: 'Показники бюджетних програм',
  name: 'Показатели',
  ...defaultColumns,
  SampleIndicator: {
    label: 'Типовий показник',
    name: 'ТиповойПоказатель',
    visible: true,
  },
  InfoSource: {
    label: 'Джерело інформації',
    name: 'ИсточникИнформации',
    visible: false,
  },
  SerialNumber: {
    label: '№ п/п',
    name: 'ПорядковыйНомер',
    visible: true,
  },
  IndicatorType: {
    label: 'Тип показника',
    name: 'ТипПоказателя',
    visible: true,
  },
  IdMinFin: {
    label: 'ПК показника (для обміну з Мінфіном)',
    name: 'ИдМинФин',
    visible: true,
  },
  Units: {
    label: 'Одиниця виміру (старий)', name: 'ЕдСтар', visible: true,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  Target: { label: 'Ціль', name: 'Цель', visible: false },
  Numerator: { label: 'Числитель', name: 'Числитель', visible: false },
  Denominator: {
    label: 'Знаменатель',
    name: 'Знаменатель',
    visible: false,
  },
  Subtrahend: { label: 'Вычитаемое', name: 'Вычитаемое', visible: false },
  Factor: { label: 'Множить на', name: 'Множитель', visible: false },
  NotShowInReports: {
    label: 'Не відображати у звітах (службовий, для обрахунку інших показників)',
    name: 'флНеОтображатьВОтчетах',
    visible: false,
  },
  CalculateAverage: {
    label: 'Обрахунок середніх витрат',
    name: 'флРасчетСреднего',
    visible: false,
  },
  Inactive: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: true,
  },
  Symptom: { label: 'Признак', name: 'Признак', visible: true },
  TaskClassifier: {
    label: 'Типове завдання програми',
    name: 'КлассификаторЗадания',
    visible: true,
  },
  FormatString: {
    label: 'Формат друку числового значення показника',
    name: 'ФорматнаяСтрока',
    visible: false,
  },
  Sign: { label: 'Знак', name: 'Знак', visible: false },
  NotShowInForm1: {
    label: 'Не відображати в Формі 1',
    name: 'флНеОтображатьВФорме1',
    visible: false,
  },
  NotShowInForm2: {
    label: 'Не відображати в Формі 2 та 3',
    name: 'флНеОтображатьВФорме2_3',
    visible: false,
  },
  DDiv: { label: 'Делитель', name: 'Делитель', visible: true },
  owner: { label: 'Напрям використання', name: 'Владелец', visible: true },
  Un: {
    label: 'Одиниця виміру', name: 'Ед', visible: true,
  },
};

const footer = { ...columns };

export const indicators = {
  label: 'Показники',
  name: 'indicators',
  columns,
  footer,
  backendName: 'Показатели',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'SerialNumber',
      'owner',
      'Inactive',
      'IndicatorType',
      'Un',
      'TaskClassifier',
      'SampleIndicator',
      'Symptom',
    ]),
  ],
  defaultOrder: [
    {
      column: defaultColumns.code.name,
      direction: directions.ascending,
    },
  ],
  frontend: 'cat/indicators',
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useFoldersLazyLoad: true,
};

export default indicators;
