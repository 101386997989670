import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dataPropTypes } from './propTypes';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { fieldsPropTypes } from '../regProgram/propTypes';

function InvestmentProgramRows({
  onChange, fromLogica, fields, data, ...rest
}) {
  const totals = Object.keys(fields.executionbpinvestmentprogramrow_set.child.children).filter((k) => k.includes('amount'));

  const onChangeTP = useCallback((f) => {
    const sumChanger = (oldData) => oldData.executionbpinvestmentprogramrow_set.map((row) => ({
      ...row,
      amount_plan_zf: (row.amount_plan_zf || 0),
      amount_plan_sf: (row.amount_plan_sf || 0),
      amount_plan_total: (row.amount_plan_zf || 0) + (row.amount_plan_sf || 0),
      amount_fact_zf: (row.amount_fact_zf || 0),
      amount_fact_sf: (row.amount_fact_sf || 0),
      amount_fact_total: (row.amount_fact_zf || 0) + (row.amount_fact_sf || 0),
      amount_executed_zf: (row.amount_executed_zf || 0),
      amount_executed_sf: (row.amount_executed_sf || 0),
      amount_executed_total: (row.amount_executed_zf || 0) + (row.amount_executed_sf || 0),
      amount_forecast_zf: (row.amount_forecast_zf || 0),
      amount_forecast_sf: (row.amount_forecast_sf || 0),
      amount_forecast_total: (row.amount_forecast_zf || 0) + (row.amount_forecast_sf || 0),
      amount_total_zf: (row.amount_total_zf || 0),
      amount_total_sf: (row.amount_total_sf || 0),
      amount_total: (row.amount_total_zf || 0) + (row.amount_total_sf || 0),
    }));
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
        executionbpinvestmentprogramrow_set: sumChanger(d),
      };
    });
  }, [onChange]);

  const template = useMemo(
    () => {
      const sumColProps = {
        xxl: 2,
        xl: 2,
        lg: 2,
        sm: 12,
      };
      const investmenttprogramColProps = {
        xxl: 2,
        xl: 2,
        lg: 2,
        sm: 12,
      };

      const sumcolumns = [
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_total_zf',
                  headerLabel: 'Заг. ф.',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_total_sf',
                  headerLabel: 'Спец. ф.',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_fact_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_fact_zf',
                  headerLabel: 'Заг. ф.',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_fact_sf',
                  headerLabel: 'Спец. ф.',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_plan_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_plan_zf',
                  headerLabel: 'Заг. ф.',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_plan_sf',
                  headerLabel: 'Спец. ф.',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_executed_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_executed_zf',
                  headerLabel: 'Заг. ф.',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_executed_sf',
                  headerLabel: 'Спец. ф.',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_forecast_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_forecast_zf',
                  headerLabel: 'Заг. ф.',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_forecast_sf',
                  headerLabel: 'Спец. ф.',
                },
              ],
            },
          ],
        },
      ];
      const row1 = [
        {
          colProps: investmenttprogramColProps,
          columns: [
            {
              field: 'targetprogram',
              colProps: { xs: 12 },
              headerLabel: 'Державна цільова програма',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
            },
            {
              field: 'kdb',
              colProps: { xs: 12 },
              headerLabel: 'КДБ',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
            },
            {
              field: 'kfb',
              colProps: { xs: 12 },
              headerLabel: 'КФБ',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
            },
          ],
        },
      ];

      return {
        columns: [
          {
            colProps: { xs: 12 },
            columns:
                [
                  ...row1,
                  ...sumcolumns,
                ],
          },
          {
            colProps: { xs: 12 },
            columns: [
              {
                field: 'explanation_description',
                headerLabel: 'Пояснення, що характеризують джерела фінансування',
                colProps: { xs: 6 },
                controlProps: { rows: 2 },
              },
              {
                field: 'cash_cost_diff',
                headerLabel: 'Пояснення щодо причин відхилення каси від плана',
                title: 'Пояснення щодо причин відхилення касових видатків'
                    + ' на виконання інвестиційного проекту (програми) від планового показника',
                colProps: { xs: 6 },
                controlProps: { rows: 2 },
              },
            ],
          },
        ],
      };
    },
    [data.budget],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbpinvestmentprogramrow_set"
      onChange={onChangeTP}
      fields={fields}
      totals={totals}
    />
  );
}

InvestmentProgramRows.propTypes = {
  data: PropTypes.shape({
    executionbpinvestmentprogramrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    budget: foreignPropType,
  }).isRequired,
  fields: PropTypes.shape({
    executionbpinvestmentprogramrow_set: fieldsPropTypes,
  }),
  onChange: PropTypes.func.isRequired,
  fromLogica: PropTypes.bool.isRequired,
};

InvestmentProgramRows.defaultProps = {
  fields: null,
};

export default InvestmentProgramRows;
