import PropTypes from 'prop-types';

export const colProps = {
  field: PropTypes.string,
  colProps: PropTypes.shape({}),
  headerColProps: PropTypes.shape({}),
  rowColProps: PropTypes.shape({}),
  controlProps: PropTypes.shape({}),
  headerLabel: PropTypes.string,
};

export const columnsPropType = PropTypes.arrayOf(PropTypes.oneOfType([
  PropTypes.shape(colProps),
  PropTypes.shape({
    colProps: PropTypes.shape({}),
    headerColProps: PropTypes.shape({}),
    rowColProps: PropTypes.shape({}),
    columns: PropTypes.arrayOf(PropTypes.shape(colProps)),
  }),
]));
