import React, { useCallback, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import HalfDoughnutChart from '../../../../components/Charts/HalfDoughnutChart';
import useDiagramShower from '../diagramHook';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';

function PlanFactRevenue({ data, totalPlan }) {
  const [height, setHeight] = React.useState(100);
  const onResize = useCallback(
    (el) => {
      const { width } = el.getBoundingClientRect();
      if (width > 200) {
        setHeight(width / 2);
      } else if (width > 100) {
        setHeight(width);
      } else {
        setHeight(width * 1.2);
      }
    },
    [],
  );
  const { containerChartRef, show } = useDiagramShower({ onResize });
  const tabledata = useMemo(
    () => [
      { title: 'План з початку року, наростаючим підсумком на поточний місяць', amount: data.plan / 1000 },
      { title: 'Надійшло з початку року, наростаючим підсумком на поточну дату', amount: data.fact / 1000 },
      { title: 'План на рік (довідково)', amount: totalPlan },
      // { title: 'Відхилення від плану', amount: data.difference },
    ],
    [data.fact, data.plan, totalPlan],
  );

  const pratPlan = (data.plan === 0 ? 100 : (data.fact / data.plan) * 100).toFixed(0);

  return (
    <div className="g-3 h-100">
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0">Виконання дохідної частини бюджету з початку року, тис. грн.</h6>
        </Card.Header>
        <Card.Body className="pt-0">
          <div
            className="d-flex justify-content-center mx-6"
          >
            <div
              className="position-relative d-flex justify-content-center align-items-end w-100"
              ref={containerChartRef}
              style={{ height: `${height}px` }}
            >
              {show && (
                <HalfDoughnutChart
                  color="primary"
                  colorTarget="warning"
                  target={data.plan}
                  reached={data.fact}
                  // totalPlan={totalPlan * 1000}
                />
              )}
              <div className="position-absolute top-100 start-50 translate-middle">
                <CountUp
                  start={0}
                  end={pratPlan}
                  duration={2.75}
                  className="d-inline-block mb-6 fs-1 fw-medium font-sans-serif"
                  suffix="%"
                  separator=","
                />
              </div>
            </div>
          </div>
          <div className="mx-1 mb-6">
            {tabledata.map((td) => (
              <Row className="g-0 m-3">
                <Col sm={7}>
                  <h6 className="mb-0 text-600 text-end text-sm-start">
                    {td.title}
                    :
                  </h6>
                </Col>
                <Col sm={5} className="d-flex justify-content-end align-items-end">
                  <h6 className="mb-0 text-800">{td?.amount?.toLocaleString('uk', moneysOneDigitStingOptions)}</h6>
                </Col>
              </Row>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

PlanFactRevenue.propTypes = {
  data: PropTypes.shape({
    plan: PropTypes.number,
    fact: PropTypes.number,
    difference: PropTypes.number,
  }).isRequired,
  totalPlan: PropTypes.number.isRequired,
};

export default PlanFactRevenue;
