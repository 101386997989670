import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.investPrograms;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
  transitionToOB,
  turnBuildObjOn,
  isKyiv,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col sm={2}>
        <Row noGutters>
          { turnBuildObjOn && (
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'constructionObject'}
              highlighted={highlights.includes('constructionObject')}
              onToggleHighlght={() => onToggleHighlght('constructionObject')}
            >
              {meta.columns.constructionObject.label}
            </TableHeaderCell>
          </Col>
          )}
          { (transitionToOB || !turnBuildObjOn) && (
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'targetS'}
              highlighted={highlights.includes('targetS')}
              onToggleHighlght={() => onToggleHighlght('targetS')}
            >
              {meta.columns.targetS.label}
            </TableHeaderCell>
          </Col>
          )}
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'constructionPeriod'}
              highlighted={highlights.includes('constructionPeriod')}
              onToggleHighlght={() => onToggleHighlght('constructionPeriod')}
            >
              {meta.columns.constructionPeriod.label}
            </TableHeaderCell>
          </Col>
          { turnBuildObjOn && (
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'costumer'}
              highlighted={highlights.includes('costumer')}
              onToggleHighlght={() => onToggleHighlght('costumer')}
            >
              {meta.columns.costumer.label}
            </TableHeaderCell>
          </Col>
          )}
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'basicMoney'}
              highlighted={highlights.includes('basicMoney')}
              onToggleHighlght={() => onToggleHighlght('basicMoney')}
            >
              {meta.columns.basicMoney.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={(turnBuildObjOn && isKyiv) ? 6 : 10}>
        <Row noGutters>
          <Col className="border-right px-1">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-muted"
                  active={activeCol === 'sum_2'}
                  highlighted={highlights.includes('sum_2')}
                  onToggleHighlght={() => onToggleHighlght('sum_2')}
                >
                  Факт
                  <Badge className="mx-1" variant="primary">{year - 2}</Badge>
                  p.
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                { !turnBuildObjOn && (
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'sf_2'}
                  highlighted={highlights.includes('sf_2')}
                  onToggleHighlght={() => onToggleHighlght('sf_2')}
                >
                  {meta.columns.sf_2.label}
                </TableHeaderCell>
                )}
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'ss_1'}
                  highlighted={highlights.includes('ss_1')}
                  onToggleHighlght={() => onToggleHighlght('ss_1')}
                >
                  {meta.columns.ss_1.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col className="border-right px-1">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-muted"
                  active={activeCol === 'sum_1'}
                  highlighted={highlights.includes('sum_1')}
                  onToggleHighlght={() => onToggleHighlght('sum_1')}
                >
                  Факт
                  <Badge className="mx-1" variant="primary">{year - 1}</Badge>
                  p.
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                { !turnBuildObjOn && (
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'sf_1'}
                  highlighted={highlights.includes('sf_1')}
                  onToggleHighlght={() => onToggleHighlght('sf_1')}
                >
                  {meta.columns.sf_1.label}
                </TableHeaderCell>
                ) }
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'ss_2'}
                  highlighted={highlights.includes('ss_2')}
                  onToggleHighlght={() => onToggleHighlght('ss_2')}
                >
                  {meta.columns.ss_2.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-muted"
                  active={activeCol === 'sum0'}
                  highlighted={highlights.includes('sum0')}
                  onToggleHighlght={() => onToggleHighlght('sum0')}
                >
                  Проект
                  <Badge className="mx-1" variant="warning">{year}</Badge>
                  p.
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                { !turnBuildObjOn && (
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'sf0'}
                  highlighted={highlights.includes('sf0')}
                  onToggleHighlght={() => onToggleHighlght('sf0')}
                >
                  {meta.columns.sf0.label}
                </TableHeaderCell>
                )}
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'ss_3'}
                  highlighted={highlights.includes('ss_3')}
                  onToggleHighlght={() => onToggleHighlght('ss_3')}
                >
                  {meta.columns.ss_3.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col className="border-right px-1">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-muted"
                  active={activeCol === 'sum1'}
                  highlighted={highlights.includes('sum1')}
                  onToggleHighlght={() => onToggleHighlght('sum1')}
                >
                  Прогноз
                  <Badge className="mx-1" variant="success">{year + 1}</Badge>
                  p.
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                { !turnBuildObjOn && (
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'sf1'}
                  highlighted={highlights.includes('sf1')}
                  onToggleHighlght={() => onToggleHighlght('sf1')}
                >
                  {meta.columns.sf1.label}
                </TableHeaderCell>
                )}
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'ss_4'}
                  highlighted={highlights.includes('ss_4')}
                  onToggleHighlght={() => onToggleHighlght('ss_4')}
                >
                  {meta.columns.ss_1.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col className="border-right px-1">
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-muted"
                  active={activeCol === 'sum2'}
                  highlighted={highlights.includes('sum2')}
                  onToggleHighlght={() => onToggleHighlght('sum2')}
                >
                  Прогноз
                  <Badge className="mx-1" variant="success">{year + 2}</Badge>
                  p.
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                { !turnBuildObjOn && (
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'sf2'}
                  highlighted={highlights.includes('sf2')}
                  onToggleHighlght={() => onToggleHighlght('sf2')}
                >
                  {meta.columns.sf2.label}
                </TableHeaderCell>
                )}
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center text-xl-left text-success"
                  active={activeCol === 'ss_5'}
                  highlighted={highlights.includes('ss_5')}
                  onToggleHighlght={() => onToggleHighlght('ss_5')}
                >
                  {meta.columns.ss_5.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          { isKyiv && (
          <Col sm={12} className="border-right border-top px-1">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'docKMR'}
              highlighted={highlights.includes('docKMR')}
              onToggleHighlght={() => onToggleHighlght('docKMR')}
            >
              {meta.columns.docKMR.label}
            </TableHeaderCell>
          </Col>
          )}
        </Row>
      </Col>
      { isKyiv && (
      <Col sm={4}>
        <Row noGutters>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'linkMCP'}
              highlighted={highlights.includes('linkMCP')}
              onToggleHighlght={() => onToggleHighlght('linkMCP')}
            >
              {meta.columns.linkMCP.label}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'docPKD'}
              highlighted={highlights.includes('docPKD')}
              onToggleHighlght={() => onToggleHighlght('docPKD')}
            >
              {meta.columns.docPKD.label}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'doc'}
              highlighted={highlights.includes('doc')}
              onToggleHighlght={() => onToggleHighlght('doc')}
            >
              {meta.columns.doc.label}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'basis'}
              highlighted={highlights.includes('basis')}
              onToggleHighlght={() => onToggleHighlght('basis')}
            >
              {meta.columns.basis.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      )}
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
  transitionToOB: PropTypes.bool,
  turnBuildObjOn: PropTypes.bool,
  isKyiv: PropTypes.bool.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
  transitionToOB: false,
  turnBuildObjOn: false,
};

export default memo(TPHeader);
