import PropTypes from 'prop-types';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  disposer: foreignPropType,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));

export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
