import {
  useCallback, useMemo,
} from 'react';

/**
 *
 * @param onChange {function}
 * @param tableName {string}
 * @param approvedName {string}
 *
 * @returns {{
 *    tableActions: {
 *          onApproveDoc(function(@param rowId {number}): void),
 *          onUnapproveDoc(function(@param rowId {number}): void),
 *          onToggleApproveDoc(function(@param rowId {number}): void),
 *          onApproveAllDocs(function(): void),
 *          onUnapproveAllDocs(function(): void),
 *          onToggleApproveAllDocs(function(): void),
 *    },
 * }}

*/
const useLowerDocs = ({
  onChange, tableName, approvedName,
}) => {
  const onApproveDoc = useCallback(
    async (e, rowIndex) => onChange((oldData) => ({
      ...oldData,
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        { ...oldData[tableName][rowIndex], [approvedName]: true },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [approvedName, onChange, tableName],
  );
  const onUnapproveDoc = useCallback(
    async (e, rowIndex) => onChange((oldData) => ({
      ...oldData,
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        { ...oldData[tableName][rowIndex], [approvedName]: false },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [approvedName, onChange, tableName],
  );
  const onToggleApproveDoc = useCallback(
    async (e, rowIndex) => onChange((oldData) => ({
      ...oldData,
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        {
          ...oldData[tableName][rowIndex],
          [approvedName]: !oldData[tableName][rowIndex][approvedName],
        },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [approvedName, onChange, tableName],
  );
  const onApproveAllDocs = useCallback(
    async () => onChange((oldData) => ({
      ...oldData,
      [tableName]: oldData[tableName].map((r) => ({ ...r, [approvedName]: true })),
    })),
    [approvedName, onChange, tableName],
  );
  const onUnapproveAllDocs = useCallback(
    async () => onChange((oldData) => ({
      ...oldData,
      [tableName]: oldData[tableName].map((r) => ({ ...r, [approvedName]: false })),
    })),
    [approvedName, onChange, tableName],
  );
  const onToggleApproveAllDocs = useCallback(
    async () => onChange((oldData) => ({
      ...oldData,
      [tableName]: oldData[tableName].map((r) => ({ ...r, [approvedName]: !r[approvedName] })),
    })),
    [approvedName, onChange, tableName],
  );

  const tableActions = useMemo(
    () => ({
      onApproveDoc,
      onUnapproveDoc,
      onToggleApproveDoc,
      onApproveAllDocs,
      onUnapproveAllDocs,
      onToggleApproveAllDocs,
    }),
    [onApproveAllDocs, onApproveDoc, onToggleApproveAllDocs,
      onToggleApproveDoc, onUnapproveAllDocs, onUnapproveDoc],
  );

  return {
    tableActions,
  };
};

export default useLowerDocs;
