import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/netFOP';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from './docSelectionModal';
import enums from '../../../../constants/meta/enums';
import { selectionMode } from '../../../../constants/meta/common';
import { ContainerSt } from '../../../../components/Form/styledForm';
import { StampApprovedJs, StampApprovedNew3Js } from '../../../../assets/icons';

const planingKindsOptions = Object.values(enums.PlanFact).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const fondKindsOptions = Object.values(enums.FondKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function NetFOP({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = sessionOptions.get('is_admin', false) ? !permissions.canChange : !permissions.canChange || data[md.columns.isApproved.name];

  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LoadDocs', loadData);
    },
    [actions],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.plan.label}
            value={data[md.columns.plan.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.plan.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label="Оберіть КПКВМБ, по якому бажаєте завантажити дані, якщо значення не обрано завантажаться документи по усим доступним КПКВМБ"
            value={data[md.columns.KPKV.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.KPKV.name]: value,
              });
            }}
            modelType="cat"
            modelName="kbp7Classifier"
            choiceSettings={selectionMode.items}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <EditorControls.ItemPicker */}
      {/*      label={md.columns.fond.label} */}
      {/*      value={data[md.columns.fond.name]} */}
      {/*      onChange={(e, value) => { */}
      {/*        actions.onChange({ */}
      {/*          [md.columns.fond.name]: value, */}
      {/*        }); */}
      {/*      }} */}
      {/*      modelType="cat" */}
      {/*      modelName="fondClassifier" */}
      {/*      choiceSettings={selectionMode.items} */}
      {/*      readOnly={readOnly} */}
      {/*      canErase */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.fondStr.label}
            value={data[md.columns.fondStr.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fondStr.name]: value,
            })}
            values={fondKindsOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            FI={data[md.columns.FI.name]}
            KBP7={data[md.columns.KPKV.name]}
            readOnly={readOnly}
            nDoc={data[md.columns.number.name]}
            dDoc={data[md.columns.date.name]}
            fondNetFOP={data[md.columns.fondStr.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {/*  <EditorControls.CheckboxInput */}
          {/*    controlId={`isApproved-${data.id}`} */}
          {/*    label={md.columns.isApproved.label} */}
          {/*    value={data[md.columns.isApproved.name]} */}
          {/*    onChange={(e, value) => actions.onChange({ */}
          {/*      [md.columns.isApproved.name]: value, */}
          {/*    })} */}
          {/*    readOnly={readOnly} */}
          {/*  /> */}
        </Col>
        {data[md.columns.isApproved.name] && (
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampApprovedNew3Js text={data[md.columns.invoice.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
      <Row>
        {/*  <EditorControls.CheckboxInput */}
        {/*    controlId={`isApproved-${data.id}`} */}
        {/*    label={md.columns.isApproved.label} */}
        {/*    value={data[md.columns.isApproved.name]} */}
        {/*    onChange={(e, value) => actions.onChange({ */}
        {/*      [md.columns.isApproved.name]: value, */}
        {/*    })} */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        <Col>
          {data[md.columns.appr.name] && (
            <Col style={{ zIndex: '1' }}>
              <ContainerSt>
                <StampApprovedJs text={data[md.columns.apprGroup.name]} />
              </ContainerSt>
            </Col>
          )}
        </Col>
        <Col>
          {data[md.columns.apprByRegion.name] && (
            <Col style={{ zIndex: '1' }}>
              <ContainerSt>
                <StampApprovedJs text={data[md.columns.regionInvoice.name]} />
              </ContainerSt>
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

NetFOP.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default NetFOP;
