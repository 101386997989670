import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import FilterField from './filterField';

function FilterGrid({ filteringFields, onSetFilter, filters }) {
  return (
    <Row className="p-3 pt-0">
      {filteringFields.map((ff) => (
        <Col key={ff.name} className="p-0">
          <FilterField
            desc={ff}
            value={filters[ff.name]}
            onSetFilter={onSetFilter}
          />
        </Col>
      ))}
    </Row>
  );
}

FilterGrid.propTypes = {
  filteringFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    resource: PropTypes.string,
  })).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default FilterGrid;
