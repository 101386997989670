import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'kekv_item'}
          highlighted={highlights.includes('kekv_item')}
          onToggleHighlight={() => onToggleHighlight('kekv_item')}
        >
          {fields.kekv_item.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'amount_total'}
          highlighted={highlights.includes('amount_total')}
          onToggleHighlight={() => onToggleHighlight('amount_total')}
        >
          {fields.amount_total.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'prepayment'}
          highlighted={highlights.includes('prepayment')}
          onToggleHighlight={() => onToggleHighlight('prepayment')}
        >
          {fields.prepayment.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'note'}
          highlighted={highlights.includes('note')}
          onToggleHighlight={() => onToggleHighlight('note')}
        >
          {fields.note.label}
        </TableHeaderCell>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
