import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  DateInput,
  TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/garanty';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL3';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onT3RXXXXG1SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T3RXXXXG1S.name]: v });
  }, [onRowChange]);

  const onT3RXXXXG2SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T3RXXXXG2S.name]: v });
  }, [onRowChange]);

  const onT3RXXXXG3SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T3RXXXXG3S.name]: v });
  }, [onRowChange]);

  const onT3RXXXXG4SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T3RXXXXG4S.name]: v });
  }, [onRowChange]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T3RXXXXG1S" highlighted={highlights.includes('T3RXXXXG1S')}>
              <TextInput
                onChange={onT3RXXXXG1SChange}
                value={row[tableMD.columns.T3RXXXXG1S.name]}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T3RXXXXG2S" highlighted={highlights.includes('T3RXXXXG2S')}>
              <DateInput
                value={row[tableMD.columns.T3RXXXXG2S.name]}
                onChange={onT3RXXXXG2SChange}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T3RXXXXG3S" highlighted={highlights.includes('T3RXXXXG3S')}>
              <TextInput
                value={row[tableMD.columns.T3RXXXXG3S.name]}
                onChange={onT3RXXXXG3SChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T3RXXXXG4S" highlighted={highlights.includes('T3RXXXXG4S')}>
              <TextInput
                onChange={onT3RXXXXG4SChange}
                value={row[tableMD.columns.T3RXXXXG4S.name]}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T3RXXXXG1S.name]: PropTypes.string,
    [tableMD.columns.T3RXXXXG2S.name]: PropTypes.string,
    [tableMD.columns.T3RXXXXG3S.name]: PropTypes.string,
    [tableMD.columns.T3RXXXXG4S.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
