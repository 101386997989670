import React, {
  useEffect, useState, useMemo, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Button, Row, Col, Spinner, Collapse, Tab, Tabs, Nav, ButtonGroup, Alert, Card,
} from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Map, fromJS } from 'immutable';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faWrench } from '@fortawesome/free-solid-svg-icons';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import meta from '../../../constants/meta';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import ProgramsTP from './tablepart/Programs';
import TpDoc from './tablepart/tpDoc1';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpDPCommandPanel';
import { emptyUid } from '../../../constants/meta/common';
import TableEditor from '../../tableEditor';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import DefaultCPButton from '../../../components/bootstrap_components/controls/defaultButton';

const nFormVar = [
  { display_name: 'Форма 1', value: 1 },
  { display_name: 'Форма 2', value: 2 },
  { display_name: 'Форма 3', value: 3 },
];

const md = meta.dp.budgetRequestPrint;

const ProgramsTPRenderer = getTPContainer(ProgramsTP, getResizableCP(<TPCommandPanel tableName="programs" />));
const TpDocTPRenderer = getTPContainer(TpDoc, getResizableCP(<TPCommandPanel tableName="tpDoc" />));

function BudgetRequestPrintEditor({ headerForm, isProcessing, onClose }) {
  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
        onClose();
        dispatch(dellComponentFromWindowsManager(`/doc/budgetRequest/${docId}/`));
      }
    },
    [dispatch, docId, navigate, logikaId, onClose],
  );

  const canSendToLogica = useMemo(
    () => headerForm.get('apprByFO', false),
    [headerForm],
  );

  return (
    <>
      {headerForm.get('forbidUnexecutingBRPrinting', false) && (
        <Card.Footer>
          <Alert
            variant="danger"
            // className="position-absolute fixed-bottom mb-0 rounded-0"
            dismissible
            // onClose={onClose}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="me-3" pull="left" size="4x" />
            <Alert.Heading>
              Увага!
              <br />
              Заборонено друкувати непроведений документ &quot;Бюджетний запит&quot;
            </Alert.Heading>
          </Alert>
        </Card.Footer>
      )}
      <Container fluid>
        {!headerForm.getIn(['signVar', 'repr'], '')
        && <h5 className="text-danger">Необхідно заповнити поле &#39;Варіант підпису&#39;</h5> }
        <Row>
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.signVar.label}
              value={headerForm.get('signVar', new Map()).toJS()}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'signVar'], fromJS(v)))}
            />
          </Col>
          <Col>
            <EditorControls.SelectorInput
              label={md.columns.nForm.label}
              value={headerForm.get('nForm', null)}
              values={nFormVar}
              onChange={(e, v) => {
                dispatch(actions.changeField(['headerForm', 'nForm'], v));
              }}
            />
          </Col>
        </Row>
        <div className="d-flex gap-1">
          <Button
            variant="primary"
            onClick={() => {
              setShowSettings(false);
              dispatch(actions.changeField(['headerForm', 'result'], new Map()));
              dispatch(actions.processServerReq('PRINT'));
            }}
            disabled={!headerForm.getIn(['signVar', 'repr'], '') || isProcessing || headerForm.get('forbidUnexecutingBRPrinting', false)}
          >
            {isProcessing && (<Spinner animation="border" size="sm" />)}
            Сформувати
          </Button>
          <Button
            variant="warning"
            text="Налаштування"
            onClick={() => setShowSettings((v) => !v)}
          >
            <FontAwesomeIcon icon={faWrench} className="me-2" />
            Налаштування
          </Button>
          {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && canSendToLogica && (
            <Button
              variant="success"
              onClick={() => {
                dispatch(actions.processServerReq('CreateDispatchDoc'));
              }}
            >
              Передати в Logica
            </Button>
          )}
          {!canSendToLogica
          && (
            <Alert variant="warning" className="mb-0 mx-auto">
              Документ не затверджений, передача в Логіка заблокована, зверніться до спеціаліста,
              який має повноваження на затвердження документів
            </Alert>
          )}
          {/* )} */}
        </div>
        <Collapse in={showSettings} unmountOnExit>
          <Tabs defaultActiveKey="page1" as={Nav}>
            <Tab title="Форма 1,2,3" eventKey="page1">
              <Container fluid className="border-right border-left">
                <Row>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.budget.label}
                      value={headerForm.get('budget', new Map()).toJS()}
                      modelType="cat"
                      modelName="budget"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'budget'], fromJS(v)))}
                    />
                  </Col>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.FI.label}
                      value={headerForm.get('FI', new Map()).toJS()}
                      modelType="cat"
                      modelName="csu"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FI'], fromJS(v)))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EditorControls.DateInput
                      label={md.columns.datePrint.label}
                      value={headerForm.get('datePrint', null)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'datePrint'], v))}
                    />
                  </Col>
                  <Col>
                    <EditorControls.DateInput
                      label={md.columns.selDate.label}
                      value={headerForm.get('selDate', null)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'selDate'], v))}
                    />
                  </Col>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.signVar.label}
                      value={headerForm.get('signVar', new Map()).toJS()}
                      modelType="cat"
                      modelName="signatureVariants"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'signVar'], fromJS(v)))}
                    />
                  </Col>
                  <Col className="d-flex flex-column justify-content-center">
                    <EditorControls.CheckboxInput
                      controlId="flName"
                      value={headerForm.get('flName', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'flName'], v))}
                      label={md.columns.flName.label}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.csu.label}
                      value={headerForm.get('csu', new Map()).toJS()}
                      modelType="cat"
                      modelName="csu"
                      noHierarchy
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'csu'], fromJS(v)))}
                    />
                  </Col>
                  <Col>
                    <EditorControls.YearInput
                      label={md.columns.year.label}
                      value={headerForm.get('year', 5)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'year'], v))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.classKBP.label}
                      value={headerForm.get('classKBP') != null ? headerForm.get('classKBP').toJS() : headerForm.get('classKBP')}
                      modelType="cat"
                      modelName="kbpClassifier"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'classKBP'], fromJS(v)))}
                      canErase
                    />
                  </Col>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.kbp7.label}
                      value={headerForm.get('kbp7') != null ? headerForm.get('kbp7').toJS() : headerForm.get('kbp7')}
                      modelType="cat"
                      modelName="kbp7Classifier"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'kbp7'], fromJS(v)))}
                      canErase
                    />
                  </Col>
                  <Col>
                    <EditorControls.ItemPicker
                      label={md.columns.programm.label}
                      value={headerForm.get('programm') != null ? headerForm.get('programm').toJS() : headerForm.get('programm')}
                      modelType="cat"
                      modelName="elementKFK"
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'programm'], fromJS(v)))}
                      canErase
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EditorControls.CheckboxInput
                      controlId="printTarget"
                      value={headerForm.get('printTarget', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printTarget'], v))}
                      label={md.columns.printTarget.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="elemKEKVForUsePlan"
                      value={headerForm.get('elemKEKVForUsePlan', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'elemKEKVForUsePlan'], v))}
                      label={md.columns.elemKEKVForUsePlan.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="elemKEKV"
                      value={headerForm.get('elemKEKV', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'elemKEKV'], v))}
                      label={md.columns.elemKEKV.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="groupKEKV"
                      value={headerForm.get('groupKEKV', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'groupKEKV'], v))}
                      label={md.columns.groupKEKV.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="factThisYear"
                      value={headerForm.get('factThisYear', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'factThisYear'], v))}
                      label={md.columns.factThisYear.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="printBP"
                      value={headerForm.get('printBP', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printBP'], v))}
                      label={md.columns.printBP.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="printNameKBP"
                      value={headerForm.get('printNameKBP', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printNameKBP'], v))}
                      label={md.columns.printNameKBP.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="in1000"
                      value={headerForm.get('in1000', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'in1000'], v))}
                      label={md.columns.in1000.label}
                    />
                  </Col>
                  <Col>
                    <EditorControls.CheckboxInput
                      controlId="notPrintPart5"
                      value={headerForm.get('notPrintPart5', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notPrintPart5'], v))}
                      label={md.columns.notPrintPart5.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="svodZP"
                      value={headerForm.get('svodZP', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'svodZP'], v))}
                      label={md.columns.svodZP.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="indicatorsForm1"
                      value={headerForm.get('indicatorsForm1', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'indicatorsForm1'], v))}
                      label={md.columns.indicatorsForm1.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="typeIndicatorsForm2"
                      value={headerForm.get('typeIndicatorsForm2', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'typeIndicatorsForm2'], v))}
                      label={md.columns.typeIndicatorsForm2.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="notPrintTime"
                      value={headerForm.get('notPrintTime', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notPrintTime'], v))}
                      label={md.columns.notPrintTime.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="notPrintNumDocs"
                      value={headerForm.get('notPrintNumDocs', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notPrintNumDocs'], v))}
                      label={md.columns.notPrintNumDocs.label}
                    />
                    <EditorControls.CheckboxInput
                      controlId="noTask"
                      value={headerForm.get('noTask', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noTask'], v))}
                      label={md.columns.noTask.label}
                    />
                  </Col>
                </Row>
                <ProgramsTPRenderer />
              </Container>
            </Tab>
            <Tab title="Список документів" eventKey="page2">
              <ButtonGroup>
                <DefaultCPButton
                  variant="info"
                  label="Заповнити"
                  onClick={() => {
                    dispatch(actions.processServerReq('FILL_DOCS'));
                  }}
                />
              </ButtonGroup>
              <TpDocTPRenderer />
            </Tab>
          </Tabs>
        </Collapse>
        <Collapse in={!showSettings} unmountOnExit>
          <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
        </Collapse>
      </Container>
    </>
  );
}

BudgetRequestPrintEditor.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  isProcessing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BudgetRequestPrintEditor.defaultProps = {
  isProcessing: false,
};

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  memo,
);

export default enchance(BudgetRequestPrintEditor);
