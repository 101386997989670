const favActions = {
  // 'INIT',
  toggleFavorites: 'TOGGLE_FAVORITES',
  loadFavoritesStart: 'LOAD_FAVORITES_START',
  loadFavoritesEnd: 'LOAD_FAVORITES_END',
  loadFavoritesError: 'LOAD_FAVORITES_ERROR',
  saveFavoritesStart: 'SAVE_FAVORITES_START',
  saveFavoritesEnd: 'SAVE_FAVORITES_END',
  saveFavoritesError: 'SAVE_FAVORITES_ERROR',
};

export default favActions;
