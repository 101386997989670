/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useMemo, memo, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { ItemContainer, ItemText } from '../StyledComponents';
import { MinusIcon, ClosedFolder, PlusIcon } from '../../../../assets/icons';

const IconDiv = styled.div`
  padding-right: .2rem;
`;

const AvailableFieldItem = forwardRef(({
  id, item, onClickHandler, onDoubleClickHandler,
  dragHandleProps, draggableProps, level, onIconClickHandler,
}, ref) => {
  const itemIcon = useMemo(
    () => {
      if (item.get('Folder', false)) {
        return (
          <IconDiv>
            <ClosedFolder />
          </IconDiv>
        );
      }
      if (!item.get('itemsCount')) {
        return (
          <IconDiv>
            <MinusIcon color="grey" />
          </IconDiv>
        );
      }
      if (item.get('_TOGGLED', false)) {
        return (
          <IconDiv onClick={(e) => onIconClickHandler(e, id)}>
            <MinusIcon color="green" onClick={(e) => onIconClickHandler(e, id)} />
          </IconDiv>
        );
      }
      return (
        <IconDiv onClick={(e) => onIconClickHandler(e, id)}>
          <PlusIcon color="green" />
        </IconDiv>
      );
    },
    [id, item, onIconClickHandler],
  );
  return (
    <ItemContainer
      ref={ref}
      active={item.get('_SELECTED', false)}
      level={level}
      onClick={(e) => onClickHandler(e, id)}
      onDoubleClick={(e) => onDoubleClickHandler(e, id)}
      {...dragHandleProps}
      {...draggableProps}
    >
      {itemIcon}
      <ItemText>
        {item.get('Tittle', '')}
      </ItemText>

    </ItemContainer>
  );
});

AvailableFieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  onClickHandler: PropTypes.func,
  onDoubleClickHandler: PropTypes.func,
  onIconClickHandler: PropTypes.func,
  dragHandleProps: PropTypes.shape({}).isRequired,
  draggableProps: PropTypes.shape({}).isRequired,
  level: PropTypes.number,
};

AvailableFieldItem.defaultProps = {
  onClickHandler: () => null,
  onDoubleClickHandler: () => null,
  onIconClickHandler: () => null,
  level: 0,
};

// class _AvailableFieldItem extends Component {
//
//   shouldComponentUpdate(nextProps) {
//     const {
//       id, item, draggableProps, dragHandleProps,
//     } = this.props;
//     return (
//       nextProps.id !== id
//       || !nextProps.item.equals(item)
//       || nextProps.draggableProps !== draggableProps
//       || nextProps.dragHandleProps !== dragHandleProps
//     );
//   }

export default memo(AvailableFieldItem);
