import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
  on3Years,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <Col
            className="px-1  d-flex flex-column justify-content-end"
          >
        &nbsp;
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'first_year_zf'}
              highlighted={highlights.includes('first_year_zf')}
            >
              {totals.first_year_zf.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'second_year_zf'}
                highlighted={highlights.includes('second_year_zf')}
              >
                {totals.second_year_zf.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'third_year_zf'}
                highlighted={highlights.includes('third_year_zf')}
              >
                {totals.third_year_zf.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'first_year_sf'}
              highlighted={highlights.includes('first_year_sf')}
            >
              {totals.first_year_sf.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'second_year_sf'}
                highlighted={highlights.includes('second_year_sf')}
              >
                {totals.second_year_sf.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'third_year_sf'}
                highlighted={highlights.includes('third_year_sf')}
              >
                {totals.third_year_sf.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'first_year_br'}
              highlighted={highlights.includes('first_year_br')}
            >
              {totals.first_year_br.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'second_year_br'}
                highlighted={highlights.includes('second_year_br')}
              >
                {totals.second_year_br.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'third_year_br'}
                highlighted={highlights.includes('third_year_br')}
              >
                {totals.third_year_br.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'first_year_total'}
              highlighted={highlights.includes('first_year_total')}
            >
              {totals.first_year_total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'second_year_total'}
                highlighted={highlights.includes('second_year_total')}
              >
                {totals.second_year_total.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'third_year_total'}
                highlighted={highlights.includes('third_year_total')}
              >
                {totals.third_year_total.toLocaleString('uk', moneysStingOptions)}
              </TableHeaderCell>
            </>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  on3Years: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    first_year_zf: PropTypes.number,
    second_year_zf: PropTypes.number,
    third_year_zf: PropTypes.number,
    first_year_sf: PropTypes.number,
    second_year_sf: PropTypes.number,
    third_year_sf: PropTypes.number,
    first_year_br: PropTypes.number,
    second_year_br: PropTypes.number,
    third_year_br: PropTypes.number,
    first_year_total: PropTypes.number,
    second_year_total: PropTypes.number,
    third_year_total: PropTypes.number,
  }).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
  on3Years: false,
};

export default memo(TPFooter);
