import AssignmentPlanningEditor from './assignmentPlanning/editor';
import AssignmentPlanningRevEditor from './assignmentPlanningRev/editor';
import AssignmentPlanningSourceEditor from './assignmentPlanningSource/editor';
// eslint-disable-next-line import/no-cycle
import BudgetRequestEditor from './budgetRequest/editor';
import ChangeDraftAnnualRevEditor from './changeDraftAnnualRev/editor';
import ChangeDraftAnnualSpendEditor from './changeDraftAnnualSpend/editor';
import ChangesToAssignmentPlanningEditor from './changesToAssignmentPlanning/editor';
import ChangeToAssignPlanRevEditor from './changeToAssignPlanRev/editor';
import ChangeToAssignPlanSourceEditor from './changeToAssignPlanSource/editor';
import DocRegisterEditor from './docRegister/editor';
import DraftAnnualRevEditor from './draftAnnualRev/editor';
import DraftAnnualSpendEditor from './draftAnnualSpend/editor';
import FinanceObligationEditor from './financeObligation/editor';
import FinancingEditor from './financing/editor';
import FinancingApplicationReversalEditor, { FinancingApplicationReversal, FinancingApplicationReversalTabs } from './financingApplicationReversal/editor';
import FinancingCashboxEditor, { FinancingCashbox, FinancingCashboxTabs } from './financingCashbox/editor';
import FinancingRepairObjectsEditor, { FinancingRepairObjects, FinancingRepairObjectsTabs } from './financingRepairObjects/editor';
import FinancingRequestEditor from './financingRequest/editor';
import FinancingProposeEditor from './financingPropose/editor';
import FinancingSourcesEditor from './financingSources/editor';
import IndicatorFactEditor from './indicatorFact/editor';
import IncomingMoneyEditor from './incomingMoney/editor';
import IndicatorPassportEditor from './indicatorPassport/editor';
import LegalObligationEditor from './legalObligation/editor';
import LimitReferenceEditor from './limitReference/editor';
import PaymentEditor from './payment/editor';
import PaymentTransferEditor from './paymentTransfer/editor';
import PublicBudgetEditor, { PublicBudget, PublicBudgetTabs } from './publicBudget/editor';
import TreasuryStatementCostsEditor from './treasuryStatementCosts/editor';
import TreasuryStatementIncomeEditor from './treasuryStatementIncome/editor';
import RegistrationFormEditor from './registrationForm/editor';
import InvestmentProposalObjectsEditor from './investmentProposalObjects/editor';
import InvestmentProposalObjectsChangesEditor from './investmentProposalObjectsChanges/editor';
import TransferToLogicaEditor from './docTransferToLogica/editor';
import DraftAnnualSourcesEditor from './draftAnnualSources/editor';
import ChangeToDraftAnnualSourcesEditor from './changeToDraftAnnualSources/editor';
import DraftAnnualCreditEditor from './draftAnnualCredit/editor';
import ChangeDraftAnnualCreditEditor from './changeDraftAnnualCredit/editor';
import DraftAnnualTransfertEditor from './draftAnnualTransfert/editor';
import ChangeDraftAnnualTransfertEditor from './changeDraftAnnualTransfert/editor';
import DraftAnnualLoanEditor from './draftAnnualLoan/editor';
import ChangeDraftAnnualLoanEditor from './changeDraftAnnualLoan/editor';

import DraftAnnualBREditor from './draftAnnualBR/editor';
import ChangeDraftAnnualBREditor from './changeDraftAnnualBR/editor';
import ForecastAdd1Editor from './forecastAdd1/editor';
import ForecastAdd2Editor from './forecastAdd2/editor';
import ForecastAdd3Editor from './forecastAdd3/editor';
import ForecastAdd4Editor from './forecastAdd4/editor';
import ForecastAdd5Editor from './forecastAdd5/editor';
import ForecastAdd6Editor from './forecastAdd6/editor';
import ForecastAdd7Editor from './forecastAdd7/editor';
import ForecastAdd8Editor from './forecastAdd8/editor';
import ForecastAdd12Editor from './forecastAdd12/editor';
import ForecastAdd11Editor from './forecastAdd11/editor';
import ForecastAdd10Editor from './forecastAdd10/editor';
import ForecastAdd9Editor from './forecastAdd9/editor';
import PrepareForecastAdd1Editor from './prepareForecastAdd1/editor';
import PrepareForecastAdd2Editor from './prepareForecastAdd2/editor';
import PrepareForecastAdd5Editor from './prepareForecastAdd5/editor';
import PrepareForecastAdd4Editor from './prepareForecastAdd4/editor';
import PrepareForecastAdd3Editor from './prepareForecastAdd3/editor';
import FreeRemEditor from './freeRem/editor';
import FreeRem3AddEditor from './freeRem3Add/editor';
import FreeRem4AddEditor from './freeRem4Add/editor';
import FreeRem4Add2024Editor from './freeRem4Add2024/editor';
import DecisionNotificationEditor from './decisionNotification/editor';
import NetFOPEditor from './netFOP/editor';
import NetCategoryEditor from './netCategory/editor';
import NetEditor from './net/editor';
import NetChangeEditor from './netChange/editor';
import SocialPaymentsEditor from './socialPayments/editor';
import TaxesEditor from './taxes/editor';
import Taxes2Editor from './taxes2/editor';
import Taxes2PEditor from './taxes2P/editor';
import Taxes3Editor from './taxes3/editor';
import Taxes3PEditor from './taxes3P/editor';
import Taxes4Editor from './taxes4/editor';
import Taxes5Editor from './taxes5/editor';
import Taxes6Editor from './taxes6/editor';
import Taxes7Editor from './taxes7/editor';
import Taxes8Editor from './taxes8/editor';
import Taxes11Editor from './taxes11/editor';
import BorderLimitEditor from './borderLimit/editor';
import ChangeBorderLimitEditor from './changeBorderLimit/editor';
import NetCategoryPlanEditor from './netCategoryPlan/editor';
import ExpensesOnEducationEditor from './expensesOnEducation/editor';
import GarantyEditor from './garanty/editor';
import EnergyIndicatorsEditor from './energyIndicators/editor';
import ExpensesOnHealthEditor from './expensesOnHealth/editor';
import TransfertPlanningEditor from './transfertPlanning/editor';
import TreasuryStatementIncomeSBEditor from './treasuryStatementIncomeSB/editor';
import ChangeTransfertPlanningEditor from './changeTransfertPlanning/editor';
import CountExpensesOnEducationEditor from './countExpensesOnEducation/editor';
import LocalDebtEditor from './localDebt/editor';
import DataEducationPaymentEditor from './dataEducationPayment/editor';
import LiquidityVer2Editor from './liquidityVer2/editor';
import LiquidityEditor from './liquidity/editor';
import TreasuryStatementFinObligationEditor from './treasuryStatementFinObligation/editor';
import TreasuryStatementLegalObligationEditor from './treasuryStatementLegalObligation/editor';

import AssigmentPlaningLister from './assignmentPlanning/lister';
import AssignmentPlanningRevLister from './assignmentPlanningRev/lister';
import AssignmentPlanningSourceLister from './assignmentPlanningSource/lister';
import BudgetRequestLister from './budgetRequest/lister';
import ChangeDraftAnnualRevLister from './changeDraftAnnualRev/lister';
import ChangeDraftAnnualSpendLister from './changeDraftAnnualSpend/lister';
import ChangesToAssignmentPlanningLister from './changesToAssignmentPlanning/lister';
import ChangeToAssignPlanRevLister from './changeToAssignPlanRev/lister';
import ChangeToAssignPlanSourceLister from './changeToAssignPlanSource/lister';
import DocRegisterLister from './docRegister/lister';
import DraftAnnualRevLister from './draftAnnualRev/lister';
import DraftAnnualSpendLister from './draftAnnualSpend/lister';
import FinanceObligationLister from './financeObligation/lister';
import FinancingLister from './financing/lister';
import FinancingApplicationReversalLister from './financingApplicationReversal/lister';
import FinancingCashboxLister from './financingCashbox/lister';
import FinancingProposeLister from './financingPropose/lister';
import FinancingRepairObjectsLister from './financingRepairObjects/lister';
import FinancingRequestLister from './financingRequest/lister';
import FinancingSourcesLister from './financingSources/lister';
import IndicatorFactLister from './indicatorFact/lister';
import IndicatorPassportLister from './indicatorPassport/lister';
import LegalObligationLister from './legalObligation/lister';
import LimitReferenceLister from './limitReference/lister';
import PaymentLister from './payment/lister';
import PaymentTransferLister from './paymentTransfer/lister';
import PublicBudgetLister from './publicBudget/lister';
import SocialPaymentsLister from './socialPayments/lister';
import TreasuryStatementCostsLister from './treasuryStatementCosts/lister';
import TreasuryStatementIncomeLister from './treasuryStatementIncome/lister';
import RegistrationFormLister from './registrationForm/lister';
import IncomingMoneyLister from './incomingMoney/lister';
import InvestmentProposalObjectsLister from './investmentProposalObjects/lister';
import InvestmentProposalObjectsChangesLister from './investmentProposalObjectsChanges/lister';
import TransferToLogicaLister from './docTransferToLogica/lister';
import DraftAnnualSourcesLister from './draftAnnualSources/lister';
import ChangeToDraftAnnualSourcesLister from './changeToDraftAnnualSources/lister';
import DraftAnnualCreditLister from './draftAnnualCredit/lister';
import ChangeDraftAnnualCreditLister from './changeDraftAnnualCredit/lister';
import DraftAnnualTransfertLister from './draftAnnualTransfert/lister';
import ChangeDraftAnnualTransfertLister from './changeDraftAnnualTransfert/lister';
import DraftAnnualLoanLister from './draftAnnualLoan/lister';
import ChangeDraftAnnualLoanLister from './changeDraftAnnualLoan/lister';

import DraftAnnualBRLister from './draftAnnualBR/lister';
import ChangeDraftAnnualBRLister from './changeDraftAnnualBR/lister';
import ForecastAdd1Lister from './forecastAdd1/lister';
import ForecastAdd2Lister from './forecastAdd2/lister';
import ForecastAdd3Lister from './forecastAdd3/lister';
import ForecastAdd4Lister from './forecastAdd4/lister';
import ForecastAdd5Lister from './forecastAdd5/lister';
import ForecastAdd6Lister from './forecastAdd6/lister';
import ForecastAdd7Lister from './forecastAdd7/lister';
import ForecastAdd8Lister from './forecastAdd8/lister';
import ForecastAdd12Lister from './forecastAdd12/lister';
import ForecastAdd11Lister from './forecastAdd11/lister';
import ForecastAdd10Lister from './forecastAdd10/lister';
import ForecastAdd9Lister from './forecastAdd9/lister';
import PrepareForecastAdd1Lister from './prepareForecastAdd1/lister';
import PrepareForecastAdd2Lister from './prepareForecastAdd2/lister';
import PrepareForecastAdd5Lister from './prepareForecastAdd5/lister';
import PrepareForecastAdd4Lister from './prepareForecastAdd4/lister';
import PrepareForecastAdd3Lister from './prepareForecastAdd3/lister';
import FreeRemLister from './freeRem/lister';
import FreeRem3AddLister from './freeRem3Add/lister';
import FreeRem4AddLister from './freeRem4Add/lister';
import FreeRem4Add2024Lister from './freeRem4Add2024/lister';
import DecisionNotificationLister from './decisionNotification/lister';
import NetFOPLister from './netFOP/lister';
import NetCategoryLister from './netCategory/lister';
import NetLister from './net/lister';
import NetChangeLister from './netChange/lister';
import TaxesLister from './taxes/lister';
import Taxes2Lister from './taxes2/lister';
import Taxes2PLister from './taxes2P/lister';
import Taxes3Lister from './taxes3/lister';
import Taxes3PLister from './taxes3P/lister';
import Taxes4Lister from './taxes4/lister';
import Taxes5Lister from './taxes5/lister';
import Taxes6Lister from './taxes6/lister';
import Taxes7Lister from './taxes7/lister';
import Taxes8Lister from './taxes8/lister';
import Taxes11Lister from './taxes11/lister';
import BorderLimitLister from './borderLimit/lister';
import ChangeBorderLimitLister from './changeBorderLimit/lister';
import NetCategoryPlanLister from './netCategoryPlan/lister';
import ExpensesOnEducationLister from './expensesOnEducation/lister';
import GarantyLister from './garanty/lister';
import EnergyIndicatorsLister from './energyIndicators/lister';
import ExpensesOnHealthLister from './expensesOnHealth/lister';
import TransfertPlanningLister from './transfertPlanning/lister';
import TreasuryStatementIncomeSBLister from './treasuryStatementIncomeSB/lister';
import ChangeTransfertPlanningLister from './changeTransfertPlanning/lister';
import CountExpensesOnEducationLister from './countExpensesOnEducation/lister';
import LocalDebtLister from './localDebt/lister';
import DataEducationPaymentLister from './dataEducationPayment/lister';
import DocTransferToLogikaListerSinging from './docTransferToLogicaSinging/lister';
import LiquidityVer2Lister from './liquidityVer2/lister';
import LiquidityLister from './liquidity/lister';
import TreasuryStatementFinObligationLister from './treasuryStatementFinObligation/lister';
import TreasuryStatementLegalObligationLister from './treasuryStatementLegalObligation/lister';

// need for router and tests
const docComponents = {
  assignmentPlanning: {
    editor: AssignmentPlanningEditor,
    lister: AssigmentPlaningLister,
  },
  assignmentPlanningRev: {
    editor: AssignmentPlanningRevEditor,
    lister: AssignmentPlanningRevLister,
  },
  assignmentPlanningSource: {
    editor: AssignmentPlanningSourceEditor,
    lister: AssignmentPlanningSourceLister,
  },
  budgetRequest: {
    editor: BudgetRequestEditor,
    lister: BudgetRequestLister,
  },
  changeDraftAnnualRev: {
    editor: ChangeDraftAnnualRevEditor,
    lister: ChangeDraftAnnualRevLister,
  },
  changesToAssignmentPlanning: {
    editor: ChangesToAssignmentPlanningEditor,
    lister: ChangesToAssignmentPlanningLister,
  },
  changeToAssignPlanRev: {
    editor: ChangeToAssignPlanRevEditor,
    lister: ChangeToAssignPlanRevLister,
  },
  changeToAssignPlanSource: {
    editor: ChangeToAssignPlanSourceEditor,
    lister: ChangeToAssignPlanSourceLister,
  },
  docRegister: {
    editor: DocRegisterEditor,
    lister: DocRegisterLister,
  },
  draftAnnualRev: {
    editor: DraftAnnualRevEditor,
    lister: DraftAnnualRevLister,
  },
  draftAnnualSpend: {
    editor: DraftAnnualSpendEditor,
    lister: DraftAnnualSpendLister,
  },
  financeObligation: {
    editor: FinanceObligationEditor,
    lister: FinanceObligationLister,
  },
  financing: {
    editor: FinancingEditor,
    lister: FinancingLister,
  },
  financingApplicationReversal: {
    editor: FinancingApplicationReversalEditor,
    lister: FinancingApplicationReversalLister,
    component: FinancingApplicationReversal,
    tabs: FinancingApplicationReversalTabs,
  },
  financingCashbox: {
    editor: FinancingCashboxEditor,
    lister: FinancingCashboxLister,
    component: FinancingCashbox,
    tabs: FinancingCashboxTabs,
  },
  financingPropose: {
    editor: FinancingProposeEditor,
    lister: FinancingProposeLister,
  },
  financingRepairObjects: {
    editor: FinancingRepairObjectsEditor,
    lister: FinancingRepairObjectsLister,
    component: FinancingRepairObjects,
    tabs: FinancingRepairObjectsTabs,
  },
  financingRequest: {
    editor: FinancingRequestEditor,
    lister: FinancingRequestLister,
  },
  financingSources: {
    editor: FinancingSourcesEditor,
    lister: FinancingSourcesLister,
  },
  indicatorFact: {
    editor: IndicatorFactEditor,
    lister: IndicatorFactLister,
  },
  indicatorPassport: {
    editor: IndicatorPassportEditor,
    lister: IndicatorPassportLister,
  },
  investmentProposalObjects: {
    editor: InvestmentProposalObjectsEditor,
    lister: InvestmentProposalObjectsLister,
  },
  investmentProposalObjectsChanges: {
    editor: InvestmentProposalObjectsChangesEditor,
    lister: InvestmentProposalObjectsChangesLister,
  },
  legalObligation: {
    editor: LegalObligationEditor,
    lister: LegalObligationLister,
    component: LegalObligationEditor,
  },
  limitReference: {
    editor: LimitReferenceEditor,
    lister: LimitReferenceLister,
  },
  payment: {
    editor: PaymentEditor,
    lister: PaymentLister,
  },
  paymentTransfer: {
    editor: PaymentTransferEditor,
    lister: PaymentTransferLister,
  },
  publicBudget: {
    editor: PublicBudgetEditor,
    lister: PublicBudgetLister,
    component: PublicBudget,
    tabs: PublicBudgetTabs,
  },
  treasuryStatementCosts: {
    editor: TreasuryStatementCostsEditor,
    lister: TreasuryStatementCostsLister,
  },
  treasuryStatementIncome: {
    editor: TreasuryStatementIncomeEditor,
    lister: TreasuryStatementIncomeLister,
  },
  incomingMoney: {
    editor: IncomingMoneyEditor,
    lister: IncomingMoneyLister,
  },
  registrationForm: {
    editor: RegistrationFormEditor,
    lister: RegistrationFormLister,
  },
  docTransferToLogica: {
    editor: TransferToLogicaEditor,
    lister: TransferToLogicaLister,
  },
  docTransferToLogicaSinging: {
    editor: TransferToLogicaEditor,
    lister: DocTransferToLogikaListerSinging,
  },
  draftAnnualSources: {
    editor: DraftAnnualSourcesEditor,
    lister: DraftAnnualSourcesLister,
  },
  changeToDraftAnnualSources: {
    editor: ChangeToDraftAnnualSourcesEditor,
    lister: ChangeToDraftAnnualSourcesLister,
  },
  draftAnnualCredit: {
    editor: DraftAnnualCreditEditor,
    lister: DraftAnnualCreditLister,
  },
  changeDraftAnnualCredit: {
    editor: ChangeDraftAnnualCreditEditor,
    lister: ChangeDraftAnnualCreditLister,
  },
  draftAnnualTransfert: {
    editor: DraftAnnualTransfertEditor,
    lister: DraftAnnualTransfertLister,
  },
  changeDraftAnnualTransfert: {
    editor: ChangeDraftAnnualTransfertEditor,
    lister: ChangeDraftAnnualTransfertLister,
  },
  draftAnnualLoan: {
    editor: DraftAnnualLoanEditor,
    lister: DraftAnnualLoanLister,
  },
  changeDraftAnnualLoan: {
    editor: ChangeDraftAnnualLoanEditor,
    lister: ChangeDraftAnnualLoanLister,
  },
  draftAnnualBR: {
    editor: DraftAnnualBREditor,
    lister: DraftAnnualBRLister,
  },
  changeDraftAnnualBR: {
    editor: ChangeDraftAnnualBREditor,
    lister: ChangeDraftAnnualBRLister,
  },
  forecastAdd1: {
    editor: ForecastAdd1Editor,
    lister: ForecastAdd1Lister,
  },
  forecastAdd2: {
    editor: ForecastAdd2Editor,
    lister: ForecastAdd2Lister,
  },
  forecastAdd3: {
    editor: ForecastAdd3Editor,
    lister: ForecastAdd3Lister,
  },
  forecastAdd4: {
    editor: ForecastAdd4Editor,
    lister: ForecastAdd4Lister,
  },
  forecastAdd5: {
    editor: ForecastAdd5Editor,
    lister: ForecastAdd5Lister,
  },
  forecastAdd6: {
    editor: ForecastAdd6Editor,
    lister: ForecastAdd6Lister,
  },
  forecastAdd7: {
    editor: ForecastAdd7Editor,
    lister: ForecastAdd7Lister,
  },
  forecastAdd8: {
    editor: ForecastAdd8Editor,
    lister: ForecastAdd8Lister,
  },
  forecastAdd12: {
    editor: ForecastAdd12Editor,
    lister: ForecastAdd12Lister,
  },
  forecastAdd11: {
    editor: ForecastAdd11Editor,
    lister: ForecastAdd11Lister,
  },
  forecastAdd10: {
    editor: ForecastAdd10Editor,
    lister: ForecastAdd10Lister,
  },
  forecastAdd9: {
    editor: ForecastAdd9Editor,
    lister: ForecastAdd9Lister,
  },
  prepareForecastAdd1: {
    editor: PrepareForecastAdd1Editor,
    lister: PrepareForecastAdd1Lister,
  },
  prepareForecastAdd2: {
    editor: PrepareForecastAdd2Editor,
    lister: PrepareForecastAdd2Lister,
  },
  prepareForecastAdd5: {
    editor: PrepareForecastAdd5Editor,
    lister: PrepareForecastAdd5Lister,
  },
  prepareForecastAdd4: {
    editor: PrepareForecastAdd4Editor,
    lister: PrepareForecastAdd4Lister,
  },
  prepareForecastAdd3: {
    editor: PrepareForecastAdd3Editor,
    lister: PrepareForecastAdd3Lister,
  },
  freeRem: {
    editor: FreeRemEditor,
    lister: FreeRemLister,
  },
  freeRem3Add: {
    editor: FreeRem3AddEditor,
    lister: FreeRem3AddLister,
  },
  freeRem4Add: {
    editor: FreeRem4AddEditor,
    lister: FreeRem4AddLister,
  },
  freeRem4Add2024: {
    editor: FreeRem4Add2024Editor,
    lister: FreeRem4Add2024Lister,
  },
  decisionNotification: {
    editor: DecisionNotificationEditor,
    lister: DecisionNotificationLister,
  },
  netFOP: {
    editor: NetFOPEditor,
    lister: NetFOPLister,
  },
  netCategory: {
    editor: NetCategoryEditor,
    lister: NetCategoryLister,
  },
  net: {
    editor: NetEditor,
    lister: NetLister,
  },
  netChange: {
    editor: NetChangeEditor,
    lister: NetChangeLister,
  },
  socialPayments: {
    editor: SocialPaymentsEditor,
    lister: SocialPaymentsLister,
  },
  changeDraftAnnualSpend: {
    editor: ChangeDraftAnnualSpendEditor,
    lister: ChangeDraftAnnualSpendLister,
  },
  // Компоненты исключительно на minfin-e
  bfnInstitutionStructure: {},
  bfnAllocation: {},
  balancesAccounts: {},
  taxes: {
    editor: TaxesEditor,
    lister: TaxesLister,
  },
  taxes2: {
    editor: Taxes2Editor,
    lister: Taxes2Lister,
  },
  taxes2P: {
    editor: Taxes2PEditor,
    lister: Taxes2PLister,
  },
  taxes3: {
    editor: Taxes3Editor,
    lister: Taxes3Lister,
  },
  taxes3P: {
    editor: Taxes3PEditor,
    lister: Taxes3PLister,
  },
  taxes4: {
    editor: Taxes4Editor,
    lister: Taxes4Lister,
  },
  taxes5: {
    editor: Taxes5Editor,
    lister: Taxes5Lister,
  },
  taxes6: {
    editor: Taxes6Editor,
    lister: Taxes6Lister,
  },
  taxes7: {
    editor: Taxes7Editor,
    lister: Taxes7Lister,
  },
  taxes8: {
    editor: Taxes8Editor,
    lister: Taxes8Lister,
  },
  taxes11: {
    editor: Taxes11Editor,
    lister: Taxes11Lister,
  },
  borderLimit: {
    editor: BorderLimitEditor,
    lister: BorderLimitLister,
  },
  changeBorderLimit: {
    editor: ChangeBorderLimitEditor,
    lister: ChangeBorderLimitLister,
  },
  netCategoryPlan: {
    editor: NetCategoryPlanEditor,
    lister: NetCategoryPlanLister,
  },
  expensesOnEducation: {
    editor: ExpensesOnEducationEditor,
    lister: ExpensesOnEducationLister,
  },
  liquidityVer2: {
    editor: LiquidityVer2Editor,
    lister: LiquidityVer2Lister,
  },
  liquidity: {
    editor: LiquidityEditor,
    lister: LiquidityLister,
  },
  garanty: {
    editor: GarantyEditor,
    lister: GarantyLister,
  },
  energyIndicators: {
    editor: EnergyIndicatorsEditor,
    lister: EnergyIndicatorsLister,
  },
  expensesOnHealth: {
    editor: ExpensesOnHealthEditor,
    lister: ExpensesOnHealthLister,
  },
  transfertPlanning: {
    editor: TransfertPlanningEditor,
    lister: TransfertPlanningLister,
  },
  treasuryStatementIncomeSB: {
    editor: TreasuryStatementIncomeSBEditor,
    lister: TreasuryStatementIncomeSBLister,
  },
  changeTransfertPlanning: {
    editor: ChangeTransfertPlanningEditor,
    lister: ChangeTransfertPlanningLister,
  },
  countExpensesOnEducation: {
    editor: CountExpensesOnEducationEditor,
    lister: CountExpensesOnEducationLister,
  },
  localDebt: {
    editor: LocalDebtEditor,
    lister: LocalDebtLister,
  },
  dataEducationPayment: {
    editor: DataEducationPaymentEditor,
    lister: DataEducationPaymentLister,
  },
  treasuryStatementFinObligation: {
    editor: TreasuryStatementFinObligationEditor,
    lister: TreasuryStatementFinObligationLister,
  },
  treasuryStatementLegalObligation: {
    editor: TreasuryStatementLegalObligationEditor,
    lister: TreasuryStatementLegalObligationLister,
  },
};

export const docSelectors = {
// Сюда совать правильные селекторы (неавтоматические)
};

export {
  docComponents,
  // ChangeDraftAnnualSpend,
  // DraftAnnualRev,
  // BudgetRequest,
  FinancingApplicationReversal,
  FinancingCashbox,
  FinancingRepairObjects,
  LegalObligationEditor,
  PublicBudget,
  TransferToLogicaEditor,
};

export default {
  docComponents,
  // BudgetRequest,
  // ChangeDraftAnnualSpend,
  // DraftAnnualRev,
  FinancingApplicationReversal,
  FinancingCashbox,
  FinancingRepairObjects,
  LegalObligationEditor,
  PublicBudget,
  TransferToLogicaEditor,
};
