import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd3';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'transIn';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.code.name]: v });
      await onSR('CHANGE_TR_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="code" highlighted={highlights.includes('code')}>
              <ItemPicker
                value={row[tableMD.columns.code.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={onKDBChange}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                required
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="name" highlighted={highlights.includes('name')}>
              <TextInput
                value={row[tableMD.columns.name.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.name.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="budget" highlighted={highlights.includes('budget')}>
              <ItemPicker
                value={row[tableMD.columns.budget.name]}
                modelType="cat"
                modelName="budgetTransfer"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.budget.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf_2" highlighted={highlights.includes('zf_2')}>
              <NumberInput
                value={row[tableMD.columns.zf_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
              <NumberInput
                value={row[tableMD.columns.sf_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf_1" highlighted={highlights.includes('zf_1')}>
              <NumberInput
                value={row[tableMD.columns.zf_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
              <NumberInput
                value={row[tableMD.columns.sf_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sf2.name]: PropTypes.number,
    [tableMD.columns.zf2.name]: PropTypes.number,
    [tableMD.columns.sf_2.name]: PropTypes.number,
    [tableMD.columns.zf_2.name]: PropTypes.number,
    [tableMD.columns.sf_1.name]: PropTypes.number,
    [tableMD.columns.zf_1.name]: PropTypes.number,
    [tableMD.columns.zf1.name]: PropTypes.number,
    [tableMD.columns.sf1.name]: PropTypes.number,
    [tableMD.columns.zf0.name]: PropTypes.number,
    [tableMD.columns.sf0.name]: PropTypes.number,
    [tableMD.columns.code.name]: referencePropType,
    [tableMD.columns.budget.name]: referencePropType,
    [tableMD.columns.name.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
