import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/investmentProposalObjectsChanges';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.pvr;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'PVR'}
              highlighted={highlights.includes('PVR')}
              onToggleHighlght={() => onToggleHighlght('PVR')}
            >
              {meta.columns.PVR.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finSource'}
              highlighted={highlights.includes('finSource')}
              onToggleHighlght={() => onToggleHighlght('finSource')}
            >
              {meta.columns.finSource.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'estimateSum'}
          highlighted={highlights.includes('estimateSum')}
          onToggleHighlght={() => onToggleHighlght('estimateSum')}
        >
          {meta.columns.estimateSum.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Очікуване виконання на початок наступного року:
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yearSum'}
              highlighted={highlights.includes('yearSum')}
              onToggleHighlght={() => onToggleHighlght('yearSum')}
            >
              {meta.columns.yearSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearSum'}
              highlighted={highlights.includes('nextYearSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearSum')}
            >
              {meta.columns.nextYearSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearCriticalSum'}
              highlighted={highlights.includes('nextYearCriticalSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearCriticalSum')}
            >
              {meta.columns.nextYearCriticalSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nextYearAddSum'}
              highlighted={highlights.includes('nextYearAddSum')}
              onToggleHighlght={() => onToggleHighlght('nextYearAddSum')}
            >
              {meta.columns.nextYearAddSum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'note'}
          highlighted={highlights.includes('note')}
          onToggleHighlght={() => onToggleHighlght('note')}
        >
          {meta.columns.note.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
