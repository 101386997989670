import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/docRegistr';
import enums from '../../../../constants/meta/enums';
import {
  ContainerStamp,
  ImgContainerStamp,
  ColorTextStamp,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import { referencePropType } from '../../../newEditor/propTypes';
import { soSelector } from '../../_common/selectors';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { comparisonTypes } from '../../../../constants/meta/common';
import BankAccountTypes from '../../../../constants/meta/enums/bankAccaountTypes';

const docTypesOptions = Object.values(enums.docRegisterTypes)
  .map(({ name, label }) => ({ value: name, display_name: label }));

function DocEditor({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];

  const FiAccFilter = useMemo(
    () => [
      {
        fieldName: 'ВидСчета',
        comparisonType: comparisonTypes.notEqual,
        value: BankAccountTypes.boiler.name,
      },
      {
        fieldName: 'Владелец',
        value: FI,
      },
    ],
    [FI],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.rNo.label}
            value={data[md.columns.rNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.rNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.registrType.label}
            value={data[md.columns.registrType.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.registrType.name]: value,
              });
            }}
            values={docTypesOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.beginDate.label}
            value={data[md.columns.beginDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.beginDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.endDate.label}
            value={data[md.columns.endDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.endDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.ItemPicker
            label={md.columns.account.label}
            value={data[md.columns.account.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.account.name]: value,
              });
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={FiAccFilter}
            params={[{
              name: 'НаДату',
              value: data[md.columns.date.name],
            }]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`received-${data.id}`}
              label={md.columns.received.label}
              value={data[md.columns.received.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.received.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>

        )}
        <ContainerStamp>
          {data[md.columns.isApproved.name] && (
            <ImgContainerStamp background={StampApproved}>
              <ColorTextStamp color="#4281c9">
                {data[md.columns.received.name]}
              </ColorTextStamp>
            </ImgContainerStamp>
          )}
        </ContainerStamp>
      </Row>
    </EditorCollapse>
  );
}

DocEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DocEditor;
