import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import {
  DocCommandPanel,
} from '../../../basicEditor';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { RevenuesRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import useEditorParams from '../../../newEditor/hook/params';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, headerProps,
  } = useEditor({
    backendURL: meta.doc.forecastAdd1.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, headerReadOnlyFields);

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, headerReadOnlyFields);

  const commentProps = editorHooks.useTextInputProps('note', data, fields, fieldErrors, actions.onChange, headerReadOnlyFields);

  const fieldsTabl = {
    ...fields.forecastadd1row_set.child.children,
  };

  delete fieldsTabl.table_name;

  const dataRew = data.forecastadd1row_set && data.forecastadd1row_set.map((row) => {
    // eslint-disable-next-line no-param-reassign
    row.table_name = 1;
    return row;
  });

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.doc.forecastAdd1.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.StringInput {...commentProps} />
              </Col>
            </Row>

          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="forecastAdd1" id="uncontrolled-tab-example">
          <Tab
            eventKey="forecastAdd1revenues"
            title={fields.forecastadd1row_set.label}
          >
            <TabContainer>
              <RevenuesRows
                data={dataRew}
                fields={fieldsTabl}
                readOnly={fields.forecastadd1row_set.read_only}
                errors={fieldErrors}
                onChange={(set) => actions.onChange({ forecastadd1row_set: set })}
                readOnlyFields={readOnlyFields}
                year={yearProps.value}
              />
            </TabContainer>
          </Tab>

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
