import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge, Button,
  ButtonGroup, Col, Collapse, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FooterText, SModal } from '../../../../basicEditor/stdFilters/style';
import IconAlert from '../../../../../components/blanks/common/IconAlert';
import { CommandPanel, Loader } from '../../../../../components/bootStrap';
import useFinrequestLoading from './hook';
import { useFilters } from '../../../../basicReport/hooks/filters';
import FiltersEditor from '../../../../basicReport/ReportSettingsEditor/filters';
import { CPButton } from '../../../../../components/bootStrap/buttons';
import { EditorCheckboxInput, EditorDateInput } from '../../../../basicEditor/editorControls';
import TableList from '../../../common/loadRequestsProposalDocs/tableList';
import CardList from '../../../common/loadRequestsProposalDocs/cardList';
import { decimalToStr } from '../../../../../common/funcs';

const FIELDS = {
  disposer: { label: 'Розпорядник' },
  kbp7: { label: 'КБП МБ' },
  kekv: { label: 'КЕКВ' },
  balance: { label: 'Залишок' },

};
function LoadDialog({
  onClose, overrideFilter, onOk, docDate,
}) {
  const {
    loading, err, options,
    selectedItems, setSelectedItems, items, params, setParams,
    load,
  } = useFinrequestLoading({ overrideFilter, docDate });
  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers,
  } = useFilters(reportData, options, 'schema');
  const [tableView, setTableView] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (Object.keys(options).length) load(filters, params);
  }, [filters, load, options, params]);
  const totals = useMemo(
    () => {
      const lines = items.filter((item) => item.document && selectedItems.includes(item.id));
      return {
        docsCount: new Set(lines.map((l) => l.document.id)).size,
        sum: lines.reduce((R, i) => (R + i.balance), 0),
      };
    },
    [items, selectedItems],
  );

  return (
    <SModal show onHide={onClose} scrollable size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Завантаження документів
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {err && (<IconAlert variant="danger">{err}</IconAlert>)}
        {loading && (<Loader />)}
        <Container fluid>
          <Row>
            <Col>
              <EditorDateInput
                controlId="begin_date"
                label="Відібрати документи за період з"
                required
                value={params.bdate}
                onChange={(e, v) => setParams((o) => ({ ...o, bdate: v }))}
              />
            </Col>
            <Col>
              <EditorDateInput
                controlId="end_date"
                label="по"
                required
                value={params.edate}
                onChange={(e, v) => setParams((o) => ({ ...o, edate: v }))}
              />
            </Col>
          </Row>
        </Container>
        <CommandPanel
          leftPart={(
            <>
              <CPButton
                onClick={() => setShowFilters(!showFilters)}
                title="Фільтрування"
                icon={faFilter}
              />
              <ButtonGroup>
                <CPButton
                  onClick={() => setSelectedItems(items.map((i) => i.id))}
                  title="Обрати всі"
                  icon={faCheckSquare}
                />
                <CPButton
                  onClick={() => setSelectedItems([])}
                  title="Зняти відмітки"
                  icon={faSquare}
                />
              </ButtonGroup>
            </>
            )}
          rightPart={(
            <EditorCheckboxInput
              value={tableView}
              onChange={() => setTableView((o) => !o)}
              label="Табличний перегляд"
              description="Ви можете переключитися між табличним та картковим переглядом і обрата найбільш зручніший для Вас"
            />
            )}
        />
        <Collapse in={showFilters}>
          <div>
            <DndProvider backend={HTML5Backend}>
              <FiltersEditor
                filters={displayFilters}
                filtersHandlers={filtersHandlers}
                availableFilters={availableFilters}
              />
            </DndProvider>
          </div>
        </Collapse>
        <Collapse in={!showFilters}>
          <div>
            {tableView ? (
              <TableList
                selectedItems={selectedItems}
                items={items}
                setSelectedItems={setSelectedItems}
                fields={FIELDS}
                docName="document"
                mode="finorder"
              />
            ) : (
              <CardList
                selectedItems={selectedItems}
                items={items}
                setSelectedItems={setSelectedItems}
                fields={FIELDS}
                docName="document"
                mode="finorder"
              />
            )}
          </div>
        </Collapse>
      </Modal.Body>
      <Modal.Footer>
        <FooterText>
          Буде завантажено
          {' '}
          <Badge bg="info">{`${totals.docsCount}`}</Badge>
          {' '}
          документів на суму
          {' '}
          <Badge bg="info">{decimalToStr(totals.sum, '0,00')}</Badge>
          {' '}
          грн.
        </FooterText>
        <Button
          variant="success"
          onClick={() => {
            onOk(items.filter((item) => selectedItems.includes(item.id)));
            onClose();
          }}
        >
          Завантажити
        </Button>
        <Button variant="warning" onClick={onClose}>Закрити</Button>
      </Modal.Footer>
    </SModal>

  );
}

LoadDialog.propTypes = {
  overrideFilter: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.shape({}), PropTypes.bool, PropTypes.number,
  ]))),
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  docDate: PropTypes.string,
};

LoadDialog.defaultProps = {
  overrideFilter: {},
  docDate: null,
};

export default LoadDialog;
