import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { CiatAppContext } from '../../../../providers';
import api from '../../../../api/req';

const useFetchOpenBudgetSettings = () => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    display_main: false,
    display_revenue: false,
    display_expenses: false,
    display_programs: false,
    display_transfers: false,
    display_investments: false,
    default_page: '',
    title: '',
    description: '',
  });

  const { auth } = useContext(CiatAppContext);

  const fetchAllData = useCallback(async () => {
    setErr(null);
    setIsLoading(true);

    try {
      const r = await api.get('/api/dashboards/open_budget_settings/', auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const data = await r.json();
      setSettings(data); // Обновляем состояние непосредственно полученными данными
    } catch (error) {
      setErr(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return {
    isLoading,
    err,
    settings,
  };
};

export default useFetchOpenBudgetSettings;
