import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/investmentProposalObjects';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tableMD = md.tables.pvr;

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const PVRFIlter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="PVR" highlighted={highlights.includes('PVR')}>
              <ItemPicker
                value={row[tableMD.columns.PVR.name]}
                modelType="cat"
                modelName="objBuildingPVR"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.PVR.name]: v })}
                filter={PVRFIlter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="finSource" highlighted={highlights.includes('finSource')}>
              <ItemPicker
                value={row[tableMD.columns.finSource.name]}
                modelType="cat"
                modelName="objBuildingSourceFin"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.finSource.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableRowCell column="estimateSum" highlighted={highlights.includes('estimateSum')}>
          <NumberInput
            value={row[tableMD.columns.estimateSum.name]}
            precision={2}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.estimateSum.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="yearSum" highlighted={highlights.includes('yearSum')}>
              <NumberInput
                value={row[tableMD.columns.yearSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.yearSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="nextYearSum" highlighted={highlights.includes('nextYearSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.nextYearSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nextYearCriticalSum" highlighted={highlights.includes('nextYearCriticalSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearCriticalSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.nextYearCriticalSum.name]: v,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nextYearAddSum" highlighted={highlights.includes('nextYearAddSum')}>
              <NumberInput
                value={row[tableMD.columns.nextYearAddSum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.nextYearAddSum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.PVR.name]: referencePropType,
    [tableMD.columns.finSource.name]: referencePropType,
    [tableMD.columns.estimateSum.name]: PropTypes.number,
    [tableMD.columns.yearSum.name]: PropTypes.number,
    [tableMD.columns.nextYearSum.name]: PropTypes.number,
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.nextYearCriticalSum.name]: PropTypes.number,
    [tableMD.columns.nextYearAddSum.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
