/* eslint-disable */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/req';

/* Все конечные точки, начинающиеся с auth, генерируются djoser.
/api/auth-dj/users/	Зарегистрировать нового пользователя
/api/auth-dj/users/me/	получить/обновить зарегистрированного пользователя
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len
/api/auth-dj/jwt/create/	создать JWT, передав действующему пользователю в запросе post эту конечную точку
/api/auth-dj/jwt/refresh/	получить новый JWT по истечении времени жизни ранее сгенерированного

/api/accounts/all-profiles/	получить все профили пользователей и создать новый
/api/accounts/profile/id/	подробный вид профиля пользователя

*/

function useAuth() {
  const clearSession = useCallback(
    (setAuth) => {
      window.localStorage.removeItem('refreshToken');
      setAuth({
        accessToken: null,
        refreshToken: null,
        currentUser: null,
      });
    },
    [],
  );

  const tryLogout = useCallback(
    (auth) => {
      api.get$('/api-auth/logout', () => ({ access: auth.accessToken })).then((r) => {
        if (r.ok) {
          clearSession();
        }
      });
    },
    [clearSession],
  );

  const tryRefresh = useCallback(
    async ({ auth, setAuth }) => {
      const refreshToken = auth.refreshToken || window.localStorage.getItem('refreshToken');
      if (refreshToken) {
        const r = await api.post$('/api/auth-dj/jwt/refresh/', () => null, { refresh: refreshToken });
        if (r.ok) {
          const d = await r.json();
          window.localStorage.setItem('refreshToken', refreshToken);
          setAuth({
            ...auth,
            accessToken: d.access,
            refreshToken,
          });
          // console.log('d.access', d.access);
          return () => ({
            access: d.access,
          });
        }
      }
      tryLogout();
      return false;
    },
    [tryLogout],
  );

  const getAuth = useCallback(
    (auth) => ({
      access: auth.accessToken,
      onRefresh: tryRefresh,
    }),
    [tryRefresh],
  );

  const tryCurrentUser = useCallback(async ({ access, refresh, setAuth }) => {
    const r = await api.get$('/api/auth-dj/users/me/', () => ({ access }));

    if (r.ok) {
      const d = await r.json();
      setAuth({
        currentUser: d,
        accessToken: access,
        refreshToken: refresh,
      });
      return true;
    }

    return false;
  }, []);

  const loginHandler = useCallback(async ({
    login, pass, setAuth, setErrMsg,
    // eslint-disable-next-line consistent-return
  }) => {
    const r = await api.post$('/api/auth-dj/jwt/create/', () => null, { username: login, password: pass });
    if (r.ok) {
      const d = await r.json();
      const { access, refresh } = d;
      if (await tryCurrentUser({ access, refresh, setAuth })) {
        window.localStorage.setItem('refreshToken', refresh);
      }

      return true;
    }
    if (r.status === 401) {
      try {
        r.json().then(
          (data) => {
            setErrMsg(data.detail);
          },
        );
      } catch {
        setErrMsg('Помилка авторизації');
      }
    }
  }, [tryCurrentUser]);

  const sendEmail = useCallback(
    async (email, setShowMsg) => {
      api.post$('/api/auth-dj/users/reset_password/', () => null, { email }).then((r) => {
        if (r.ok) {
          setShowMsg('На вказану адресу був надісланий електронний лист із посиланням для зміни пароля');
          return true;
        }
        setShowMsg(`Електронний лист із посиланням для зміни пароля НЕ було надіслано!. Опис проблеми: - "${r.status}. ${r.statusText}"`);
        return false;
      });
    },
    [],
  );

  const resetPassHandler = useCallback(async (email, setShowMsg) => {
    const r = await api.post$('/api/auth/check-email/', () => null, { email });
    if (r.ok) {
      await sendEmail(email, setShowMsg);
    } else if (r.status === 403) {
      r.text().then((data) => {
        setShowMsg(data);
      });
    } else {
      setShowMsg(`${r.status} ${r.statusText}`);
    }
  }, []);

  const ativationNewPassHandler = useCallback(
    async ({
      uid,
      token,
      new_password,
      re_new_password,
      setShowMsg,
    }) => {
      const param = {
        uid,
        token,
        new_password,
        re_new_password,
      };

      // eslint-disable-next-line consistent-return
      api.post$('/api/auth-dj/users/reset_password_confirm/', () => null, param).then((r) => {
        if (r.ok) {
          setShowMsg({msg: 'Пароль було змінено', isSuccess: true});
          return true;
        }

        if (r.status === 400) {
          r.json().then(
            (data) => {

              if (data.new_password) {
                const msg = data.new_password.reduce((msg, el) => `${msg} ${el}`, '`Пароль НЕ було змінено! Тому, що ... ');
                setShowMsg({msg, isSuccess: false});
                return msg;
              }

              if (data.token) {
                const msg = data.token.reduce((msg, el) => {
                  if (el === 'Invalid token for given user.') return `${msg} Лист втратив актуальність.`
                  return `${msg} ${el}`;
                }, '`Пароль НЕ було змінено! Тому, що ... ');


                setShowMsg({msg, isSuccess: false});
                return msg;
              }

              setShowMsg({msg: `Пароль НЕ було змінено!  Зверніться до адміністратора системи. 
              Опис проблеми: - ${r.status} ${r.statusText}`, isSuccess: false});

            },
          );
        } else {
          r.text().then((data) => {
            const msg = `Пароль НЕ було змінено! Зверніться до адміністратора системи. 
          Опис проблеми: - ${r.status} ${r.statusText} ${data}`
            setShowMsg({msg, isSuccess: false});
          })
        };
      });
    },
    [],
  );

  return {
    tryLogout,
    tryRefresh,
    getAuth,
    loginHandler,
    resetPassHandler,
    ativationNewPassHandler,
    clearSession,
  };
}

useAuth.propTypes = {
  auth: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    currentUser: PropTypes.object,
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
  }),
  setAuth: PropTypes.func,
};

useAuth.defaultProps = {
  auth: {
    currentUser: null,
    accessToken: '',
    refreshToken: '',
  },
  setAuth: () => null,
};

export default useAuth;
