// eslint-disable-next-line import/no-cycle
import FondLister from './lister';
import FondSelector from './selector';

const fond = {
  lister: FondLister,
  selector: FondSelector,
};

export default fond;
