import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, FormControl } from 'react-bootstrap';

export const StyledTh = styled.th`
position: sticky;
z-index: 1;
background-color: white;
top: 75px;`;

function HeaderCell({
  label, name, canFilter, data, onClearFilter, onSetFilter, onSetOrder, canOrder, canSearch,
}) {
  const [value, setValue] = useState('');

  const unqItems = useMemo(
    () => [...new Set(data.map((r) => {
      if (name === 'addDate' || name === 'lastEditDate' || name === 'docInOurBase') {
        return r[name];
      }
      return r.analytics[name];
    }))].sort((a, b) => b?.toString()?.localeCompare(a)),
    [data, name],
  );

  const [displayValue, setDisplayValue] = useState(unqItems);

  useEffect(() => {
    if (value) {
      return setDisplayValue(unqItems.filter((el) => el
        .toLowerCase().includes(value.toLowerCase())));
    }
    return setDisplayValue(unqItems);
  }, [unqItems, value]);

  return (
    <StyledTh>
      <Dropdown>
        <Dropdown.Toggle as="a">
          {label}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{
          maxHeight: '30vh',
          overflow: 'auto',
          background: 'white',
        }}
        >
          {canSearch && (
            <FormControl
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Пошук"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          )}
          {canOrder && (
            <>
              <Dropdown.Item onClick={(e) => onSetOrder(e, { [name]: 'DESC' })}>
                Сортувати за спаданням
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => onSetOrder(e, { [name]: 'ASC' })}>
                Сортувати за зростанням
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item onClick={(e) => onClearFilter(e, [name])}>Всі</Dropdown.Item>
          {canFilter && displayValue.map((i) => (
            <Dropdown.Item
              key={i}
              onClick={(e) => onSetFilter(e, { [name]: i })}
            >
              {(i)}
            </Dropdown.Item>
          ))}

        </Dropdown.Menu>
      </Dropdown>
    </StyledTh>
  );
}
HeaderCell.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  canFilter: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    analytics: PropTypes.shape({}).isRequired,
  })).isRequired,
  onSetFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  order: PropTypes.shape({}),
  canOrder: PropTypes.bool,
  canSearch: PropTypes.bool,
};

HeaderCell.defaultProps = {
  canFilter: false,
  order: null,
  canOrder: false,
  canSearch: false,
};

export default HeaderCell;
