import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
};

export const residenceKinds = {
  label: 'Довідник місць проживання',
  name: 'residenceKinds',
  backendName: 'МестаЖительства',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/residenceKinds',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default residenceKinds;
