const frontendURL = '/doc/financeObligation/';
const backendURL = '/api/obligations/finobligationheader/';
const name = 'Фінансові зобов\'язання';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
