/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import DBFModal from './DBFModal';
import PrintButton from '../lister/printButton';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';

function APMenu({ id, ...rest }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <ButtonGroup className="ms-1">
        <Dropdown>
          <Dropdown.Toggle size="sm">
            <FontAwesomeIcon icon={faPrint} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <PrintButton selectedRows={selectedRows} />
          </Dropdown.Menu>
        </Dropdown>
        <DBFModal selectedRows={selectedRows} />
      </ButtonGroup>
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
