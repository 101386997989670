const frontendURL = '/doc/netFOP/';
const backendURL = '/api/merezha/netfop/';
const name = 'Мережа ФОП (Дод. 1,2)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
