import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/analysisGOV';
import {
  ResultSegment,
  StyledLabel,
  TwoColGridContainer,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import Tabs from '../../../components/tabs/Tabs';

import ReportContainer from '../reportContainer/variantReportContainer';

import YearField from '../../field/yearInput';

const YearInput = YearField;

const TwoColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 40% 60% ;
`;

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const headerUpdater = (path) => (e, value) => {
    dispatch(changeField(['headerForm', path], value));
  };

  const SettingsButton = (
    <div>
      <TwoColContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
      </TwoColContainer>

      <div>
        <StyledLabel>{md.columns.year.label}</StyledLabel>
        <YearInput
          value={headerForm.get('year', 0)}
          onChange={(e, v) => {
            headerUpdater('year');
            dispatch(changeField(['headerForm', 'year'], v));
          }}
          disableCalculator
        />
      </div>

      {Bookmarks}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div style={{ display: 'flex' }}>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <YearInput
            value={headerForm.get('year', 0)}
            onChange={(e, v) => {
              headerUpdater('year');
              dispatch(changeField(['headerForm', 'year'], v));
            }}
            disableCalculator
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
