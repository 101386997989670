import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualLoan';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={5} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'csu'}
              highlighted={highlights.includes('csu')}
              onToggleHighlght={() => onToggleHighlght('csu')}
            >
              {meta.columns.csu.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KBP7Code'}
              highlighted={highlights.includes('KBP7Code')}
              onToggleHighlght={() => onToggleHighlght('KBP7Code')}
            >
              {meta.columns.KBP7Code.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'loaner'}
              highlighted={highlights.includes('loaner')}
              onToggleHighlght={() => onToggleHighlght('loaner')}
            >
              {meta.columns.loaner.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'targetProg'}
              highlighted={highlights.includes('targetProg')}
              onToggleHighlght={() => onToggleHighlght('targetProg')}
            >
              {meta.columns.targetProg.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nomDateLoan'}
              highlighted={highlights.includes('nomDateLoan')}
              onToggleHighlght={() => onToggleHighlght('nomDateLoan')}
            >
              {meta.columns.nomDateLoan.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deadLineLoan'}
              highlighted={highlights.includes('deadLineLoan')}
              onToggleHighlght={() => onToggleHighlght('deadLineLoan')}
            >
              {meta.columns.deadLineLoan.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'currency'}
              highlighted={highlights.includes('currency')}
              onToggleHighlght={() => onToggleHighlght('currency')}
            >
              {meta.columns.currency.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumTotal'}
              highlighted={highlights.includes('sumTotal')}
              onToggleHighlght={() => onToggleHighlght('sumTotal')}
            >
              {meta.columns.sumTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumYear'}
              highlighted={highlights.includes('sumYear')}
              onToggleHighlght={() => onToggleHighlght('sumYear')}
            >
              {meta.columns.sumYear.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
