import {
  useMemo, useState, useCallback, useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import windowsManagerActions from '../../../constants/actions/windowsManager';

const useWinManager = () => {
  const [linkComponents, setLinkComponents] = useState([]);
  const [windowsHint, setWinHint] = useState({});
  const [currentURL, setCurrentURL] = useState('');
  // Порядок окон для переключения (след, пред)
  const [windowsStack, setWindowsStack] = useState([]);
  const [listersTimeStamps, setListersTimeStamps] = useState({});
  const [closeWarnings, setCloseWarnings] = useState({});

  const navigate = useNavigate();
  const historyUrl = useLocation();
  const dispatch = useDispatch();
  const windowContainers = useRef({});

  const addComponentToWindowsManager = useCallback(
    (
      newComponent,
      newURL,
      newWindowTitle,
      type,
      name,
      newProps,
    ) => {
      const newComp = {
        title: newWindowTitle,
        component: newComponent,
        props: newProps,
        url: newURL,
        type,
        name,
      };

      setLinkComponents((oldComp) => [...oldComp, newComp]);
      setWindowsStack((o) => [
        newURL,
        ...o,
      ]);

      dispatch({
        type: windowsManagerActions.ADD_COMPONENT_TO_WINDOWS_MANAGER,
        payload: {
          params: {},
          currentComponent: newComponent,
          currentPathName: newURL,
          windowsName: newWindowTitle,
          type,
          name,
        },
      });
    },
    [dispatch],
  );

  /**
   * Получает следующее или предыдущее окно
   * @type {function(number): string}
   */
  const getNewPath = useCallback(
    (stepWin = 0) => {
      const idx = linkComponents.reduce((R, r, i) => (currentURL === r.url ? i : R), null);

      if (idx === null) return '';
      if (stepWin > 0) {
        return linkComponents.length > idx + stepWin
          ? linkComponents[idx + stepWin].url : linkComponents[0].url;
      }

      if (linkComponents.length === 1) {
        return '/';
      }

      return idx + stepWin >= 0
        ? linkComponents[idx + stepWin].url : linkComponents[linkComponents.length - 1].url;
    },
    [currentURL, linkComponents],
  );

  const switchWindow = useCallback(
    (switchURL) => {
      const oldUrl = currentURL;

      setCurrentURL(switchURL);
      setWindowsStack((o) => [
        switchURL,
        ...(oldUrl ? [oldUrl] : []),
        ...o.filter((u) => ![switchURL, oldUrl].includes(u)),
      ]);
      if (switchURL !== historyUrl.pathname) navigate(switchURL);
    },
    [currentURL, navigate, historyUrl.pathname],
  );

  const { currentComponent, currentProps } = useMemo(
    () => linkComponents.filter((l) => l.url === currentURL).reduce((R, r) => ({
      currentComponent: r.component,
      currentProps: r.props,
    }), {}),
    [currentURL, linkComponents],
  );

  const currentContainer = windowContainers.current[currentURL];

  const dellComponentFromWindowsManager = useCallback(
    (url = null) => {
      const delUrl = url === null ? currentURL : url;

      // Переключаем окошки только в том случае если закрываем текущее окно
      const newStack = windowsStack.filter((u) => u !== delUrl);
      if (currentURL === delUrl) {
        switchWindow(newStack.length === 0 ? '' : newStack[0]);
      }
      setLinkComponents((o) => o.filter((lc) => lc.url !== delUrl));
      setWindowsStack(newStack);
      setCloseWarnings(
        (old) => Object.keys(old)
          .filter((o) => o !== url)
          .reduce((R, o) => ({ ...R, [o]: old[o] }), {}),
      );
      windowContainers.current = Object.keys(windowContainers.current)
        .filter(k => k !== delUrl)
        .reduce((R, k) => ({ ...R, [k]: windowContainers.current[k] }), {});
    },
    [currentURL, switchWindow, windowsStack],
  );

  const nextWindow = useCallback(
    () => {
      const newPath = getNewPath(1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  const prevWindow = useCallback(
    () => {
      const newPath = getNewPath(-1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  // Посылает сигнал на обновление данных компоненту, зарегесрированному по указанному url
  const sendUpdateSignal = useCallback(
    (url) => {
      setListersTimeStamps((old) => ({ ...old, [url]: new Date().valueOf() }));
    },
    [],
  );

  // Говорит что это окно закрывать небезопасно
  const setCloseWarning = useCallback(
    (url, value) => {
      setCloseWarnings((old) => ({ ...old, [url]: value }));
    },
    [],
  );

  // Меняет заголовок указагного окна
  const setWindowTitle = useCallback(
    (url, title) => {
      setLinkComponents((old) => old.map((lk) => (lk.url === url ? ({ ...lk, title }) : lk)));
    },
    [],
  );
  const setWindowsHint = useCallback(
    (url, hint) => {
      setWinHint((old) => ({
        ...old,
        [url]: hint,
      }));
    },
    [],
  );

  const containerRefs = useCallback(
    (url) => (node) => {
      if (node) {
        windowContainers.current[url] = node;
      }
    },
    [],
  );


  const wmValue = useMemo(
    () => ({
      linkComponents,
      currentComponent,
      currentProps,
      currentURL,
      getNewPath,
      dellComponentFromWindowsManager,
      switchWindow,
      addComponentToWindowsManager,
      closeWarnings,
      listersTimeStamps,
      nextWindow,
      prevWindow,
      // hidenWinManager,
      sendUpdateSignal,
      setCloseWarning,
      setWindowTitle,
      windowsHint,
      setWindowsHint,
      containerRefs,
      currentContainer,
    }),
    [
      addComponentToWindowsManager, closeWarnings, currentComponent, currentProps,
      currentURL, dellComponentFromWindowsManager, getNewPath, linkComponents, listersTimeStamps,
      nextWindow, prevWindow, sendUpdateSignal, setCloseWarning, setWindowTitle, switchWindow,
      windowsHint, setWindowsHint, containerRefs, currentContainer,
    ],
  );

  return wmValue;
};

export default useWinManager;
