import React, { forwardRef } from 'react';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  />

));
export default CommandPanel;
