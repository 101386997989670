const frontendURL = '/cat/responsiblePerson/';
const backendURL = '/api/references/refresponsibleperson/';
const name = 'Посадові особи';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
