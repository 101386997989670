const frontendURL = '/doc/expensesproject/';
const backendURL = '/api/planproject/expensesprojectheader/';
// const printURL = '';
const name = 'Проект видатків (Дод. 3 до РС)';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
