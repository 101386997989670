import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/analysisForecastsPP3';
import {
  ResultSegment,
  StyledLabel,
  TwoColGridContainer,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import Tabs from '../../../components/tabs/Tabs';

import ReportContainer from '../reportContainer/variantReportContainer';

import YearField from '../../field/yearInput';

const YearInput = YearField;

const TwoColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 40% 60% ;
`;

const typeAnalysisList = {
  revenueZF: { name: 'ДоходыЗФ', label: 'Надходження ЗФ' },
  revenueZfTr: { name: 'ДоходыЗФтрансферт', label: 'Надходження ЗФ (трансферти)' },
  revenueSF: { name: 'ДоходыСФ', label: 'Надходження СФ (трансферти)' },
  revenueSfOther: { name: 'ДоходыСФДругие', label: 'Надходження СФ (інші надходження)' },
  revenueCredit: { name: 'ДоходыКредити', label: 'Надходження СФ (повернення кредитів)' },
  revenueSfBr: { name: 'ДоходыСФбр', label: 'Надходження СФ (бюджет розвитку)' },
  revenueSfOwn: { name: 'ДоходыСФвл', label: 'Надходження СФ (власні)' },
  spent: { name: 'Расходы', label: 'Видатки' },
  credit: { name: 'Кредит', label: 'Кредитування' },
  direct: { name: 'Напрямы', label: 'Напрями використання коштів' },
  transOut: { name: 'ТрансфертиИсх', label: 'Трансферти до інших бюджетів' },
  transIn: { name: 'ТрансфертиВх', label: 'Трансферти з інших бюджетів' },
  invest: { name: 'Инвестпроект', label: 'Інвестиційні проекти' },

};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const headerUpdater = (path) => (e, value) => {
    dispatch(changeField(['headerForm', path], value));
  };

  const getTypeAnalysisList = () => {
    const arrEl = Object.values(typeAnalysisList).filter((el) => (el.name === headerForm.get('typeAnalysis', '')));

    if (arrEl) {
      return (arrEl.length > 0) ? arrEl[0].label : '';
    } return '';
  };

  const SettingsButton = (
    <div>
      <TwoColContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
      </TwoColContainer>
      <TwoColContainer>
        <div>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <YearInput
            value={headerForm.get('year', 0)}
            onChange={(e, v) => {
              headerUpdater('year');
              dispatch(changeField(['headerForm', 'year'], v));
            }}
            disableCalculator
          />
        </div>
      </TwoColContainer>
      {Bookmarks}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div style={{ display: 'flex', 'align-items': 'center' }}>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <div style={{ margin: '0px 10px' }}>
            <YearInput
              value={headerForm.get('year', 0)}
              onChange={(e, v) => {
                headerUpdater('year');
                dispatch(changeField(['headerForm', 'year'], v));
              }}
              disableCalculator
            />
          </div>
          <DropdownButton
            as={InputGroup.Prepend}
            id="dropdown-split-variants-Info"
            variant="info"
            size="sm"
            title="Оберіть варіант звіту"
          >
            {Object.values(typeAnalysisList).map((s) => (
              <Dropdown.Item
                key={s.name}
                onClick={() => {
                  dispatch(changeField(['headerForm', 'typeAnalysis'], s.name));
                  dispatch(processServerReq('CHANGE_SKD'));
                }}
              >
                {s.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <StyledLabel>
            <div style={{ margin: '0px 10px' }}>
              {getTypeAnalysisList()}
            </div>
          </StyledLabel>

        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
