import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem } from '../styles';

function SelectionItem({
  name, label, onClick, active, onDblClick, onHover,
  padded, errored,
}) {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.selection],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: itemTypes.selection },
    type: itemTypes.selection,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  drag(drop(ref));
  let variant = '';
  if (errored) variant = 'danger';
  else if (isOver) variant = 'success';
  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={variant}
      style={style}
    >
      <StyledSubItem padded={padded} />
      {label}
    </StyledFieldItem>
  );
}

SelectionItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  errored: PropTypes.bool,
};

SelectionItem.defaultProps = {
  label: '???',
  active: false,
  onDblClick: () => null,
  padded: false,
  errored: false,
};

export default SelectionItem;
