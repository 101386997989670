import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Fields from '../../../../field';
import { changeField } from '../../../../../actions/editor';
import { ItemField } from '../../../../field/selectorField/item';
import { InputStyled } from '../../../../field/selectorField/item/styles';
import GridTemplate from './GridTemplate';
import {
  DivCell,
  SumInputStyled,
  DivCheckCell,
  InputCodeStyled,
} from '../../../../../components/styledTable/styledTable';
import DateInput from '../../../../../components/fields/dates';

const TABLENAME = 'filledFromDocument';

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const {
      row, rowId, dispatch, disabled,
    } = this.props;
    return (
      <GridTemplate>
        <DivCell areaName="num">
          <InputCodeStyled
            value={row.get('num', '')}
            readOnly
          />
        </DivCell>
        <DivCheckCell areaName="IsApproved">
          <Fields.CheckboxField
            value={row.get('IsApproved', false)}
            onChange={(e, { checked }) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'IsApproved'], checked))}
            disabled={disabled}
          />
        </DivCheckCell>
        <DivCell areaName="data">
          <DateInput
            noBorder
            noBackground
            readOnly
            value={row.get('data', new Map())}
          />
        </DivCell>
        <DivCell areaName="number">
          <InputStyled
            readOnly
            value={row.get('number', '')}
          />
        </DivCell>
        <DivCell areaName="document">
          <ItemField
            noBorder
            noBackground
            value={row.get('document', new Map())}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInputStyled
            disabled
            value={row.get('sum', 0)}
          />
        </DivCell>
        <DivCell areaName="Tags">
          <InputStyled readOnly value={row.get('Tags', '')} />
        </DivCell>
        <DivCell areaName="Author">
          <ItemField
            value={row.get('Author', new Map())}
            noBorder
            noBackground
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
