import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import {
  ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import { CommandPanelButtonText } from '../../../../../../components/Form/styledForm';

const tablename = 'works';

const tableMD = md.tables[tablename];

const DocTypes = {
  first: { label: 'Основной', name: 'Основной' },
  second: { label: 'Помісячно з наростаючим підсумком', name: 'ДопСоглашение' },
  third: { label: 'Експертиза', name: 'Экспертиза' },
  fourth: { label: 'Технагляд', name: 'Технагляд' },
  fifth: { label: 'Обладнання', name: 'Обладнання' },
  sixth: { label: 'Авторський нагляд', name: 'АвторскийНадзор' },
  seventh: { label: 'Технагляд додаткові роботи', name: 'ТехнадзорДопРаботы' },
  eighth: { label: 'Технагляд продовження', name: 'ТехнадзорПродолжение' },
  ninth: { label: 'Виготовлення ПКД', name: 'ВиготовленняПКД' },
};

const docTypesValues = Object.values(DocTypes).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="ContractorCSU" highlighted={highlights.includes('ContractorCSU')}>
          <ItemPicker
            value={row[tableMD.columns.ContractorCSU.name]}
            modelType="cat"
            modelName="csu"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ContractorCSU.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="Year" highlighted={highlights.includes('Year')}>
          <EditorControls.YearInput
            value={row[tableMD.columns.Year.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.Year.name]: value })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="PurchaseContractorDate" highlighted={highlights.includes('PurchaseContractorDate')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.PurchaseContractorDate.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.PurchaseContractorDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="TenderContactNumber" highlighted={highlights.includes('TenderContactNumber')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.TenderContactNumber.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.TenderContactNumber.name]: value,
            })}
            maxLength={25}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell
          // style={{ display: 'grid', justifyContent: 'center' }}
          column="ButtonProzzoro"
        >
          <CommandPanelButtonText
            label="Перейти в prozzoro"
            onClick={() => window.open(`https://prozorro.gov.ua/tender/${row[tableMD.columns.TenderContactNumber.name]}`, '_blank')}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="TenderDate" highlighted={highlights.includes('TenderDate')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.TenderDate.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.TenderDate.name]: value })}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="TenderContactProcType" highlighted={highlights.includes('TenderContactProcType')}>
          <ItemPicker
            value={row[tableMD.columns.TenderContactProcType.name]}
            modelType="cat"
            modelName="tenderProcType"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.TenderContactProcType.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="ContractStartDate" highlighted={highlights.includes('ContractStartDate')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.ContractStartDate.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.ContractStartDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="ContractEndDate" highlighted={highlights.includes('ContractEndDate')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.ContractEndDate.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.ContractEndDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="TenderAwardDate" highlighted={highlights.includes('TenderAwardDate')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.TenderAwardDate.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.TenderAwardDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="ConractDocNumber" highlighted={highlights.includes('ConractDocNumber')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.ConractDocNumber.name]}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.ConractDocNumber.name]: value,
            })}
            maxLength={25}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="TypeDoc" highlighted={highlights.includes('TypeDoc')}>
          <EditorControls.SelectorInput
            value={row[tableMD.columns.TypeDoc.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.TypeDoc.name]: value })}
            readOnly={readOnly}
            values={docTypesValues}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="SumDocContractor" highlighted={highlights.includes('SumDocContractor')}>
          <EditorControls.NumberInput
            value={row[tableMD.columns.SumDocContractor.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.SumDocContractor.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="Note" highlighted={highlights.includes('Note')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.Note.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.Note.name]: value })}
            maxLength={200}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="NotCount" highlighted={highlights.includes('NotCount')}>
          <EditorControls.CheckboxInput
            // controlId={`NotCount-${row.id}`}
            value={row[tableMD.columns.NotCount.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.NotCount.name]: value })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
