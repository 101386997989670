import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';

const PublicBudgetProjectLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({}));

export default PublicBudgetProjectLister;
