import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import corner1 from '../../../../assets/img/illustrations/corner-3.png';
import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import CoreDetail from './CoreDetail';
import Background from '../../../../components/blanks/common/Background';
import SoftBadge from '../../../../components/blanks/common/SoftBadge';
import DynamicKbp from './DynamicKbp';
import GrkPlanFactTab from './GrkPlanFactTab';
import { Loader } from '../../../../components/bootStrap';
import useFetchSpendData from '../Hooks/useFetchSpendData';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';

function Spend({ cDt, settings }) {
  const {
    isLoading,
    err,
    expensesFondData,
    expensesData,
    compareExpansesData,
    expensesByDisposer,
  } = useFetchSpendData({ cDt });
  const displayExpensesSettings = settings.display_expenses_settings;

  return (
    <>
      {isLoading && (
      <Loader text="Завантаження" />
      )}
      <Row className="g-3">
        {displayExpensesSettings.cardTotal && (
        <Col xxl={4} xl={4} md={6} lg={6} className="mt-0 d-flex flex-column gap-3 mb-2">
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner1}
            />
            <Card.Header>
              <div id="spend-zf">
                <h4 className="text-warning mb-0">Видатки загального фонду</h4>
                <div className="d-flex flex-wrap gap-2 align-items-baseline mt-3">
                  <SoftBadge bg="success" pill>
                    <FontAwesomeIcon icon={
                      expensesFondData.zf.percent > 0 ? faCaretUp : faCaretDown
                    }
                    />
                    {' '}
                    {expensesFondData.zf.percent}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від
                    {' '}
                    {parseInt(cDt.substring(0, 4), 10) - 1}
                    р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-warning',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={expensesFondData.zf.total}
                  duration={2.75}
                  suffix=" тис.грн."
                  err={err}
                  prefix=""
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
              {Object.keys(expensesFondData.zf)
                .filter((k) => typeof expensesFondData.zf[k] === 'object')
                .map((k) => (
                  <Row className="g-0 mt-5" key={k}>
                    <div className="d-flex align-items-center">
                      <Row className="g-0 mt-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <h5 className="pb-1 text-700 text-success">
                              {expensesFondData.zf[k].repr}
                            </h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-1 text-success">
                              {expensesFondData.zf[k].total.toLocaleString('uk', moneysOneDigitStingOptions)}
                              {' '}
                              тис.грн.
                            </p>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>
                ))}
            </Card.Body>
          </Card>
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner2}
            />
            <Card.Header>
              <div id="spend-sf">
                <h4 className="text-info mb-0">Видатки спеціального фонду</h4>
                <div className="d-flex flex-wrap gap-2 align-items-baseline mt-3">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={
                      expensesFondData.sf.percent > 0 ? faCaretUp : faCaretDown
                    }
                    />
                    {' '}
                    {expensesFondData.sf.percent}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від 2023р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative mt-3">
              <div
                className={classNames(
                  'text-info',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={expensesFondData.sf.total}
                  duration={2.75}
                  suffix=" тис.грн."
                  prefix=""
                  err={err}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
              {Object.keys(expensesFondData.zf)
                .filter((k) => typeof expensesFondData.sf[k] === 'object')
                .map((k) => (
                  <Row className="g-0 mt-5" key={k}>
                    <div className="d-flex align-items-center">
                      <Row className="g-0 mt-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <h5 className="pb-1 text-700 text-success">
                              {expensesFondData.sf[k].repr}
                            </h5>
                            <p className="font-sans-serif lh-1 mb-1 fs-1 text-success">
                              {expensesFondData.sf[k].total.toLocaleString('uk', moneysOneDigitStingOptions)}
                              {' '}
                              тис.грн.
                            </p>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>
                ))}
            </Card.Body>
          </Card>
        </Col>
        )}

        <Col xxl={displayExpensesSettings.cardTotal ? 4 : 6} xl={12} md={12} lg={12} className="mt-0  mb-2">
          <CoreDetail
            title="Видатки за програмною класифікацією"
            data={expensesData.kbp}
          />
        </Col>
        <Col lg={6} className="d-none d-lg-block d-xl-none mb-2">
          &nbsp;
        </Col>
        <Col xxl={displayExpensesSettings.cardTotal ? 4 : 6} xl={12} md={12} lg={12} className="mt-0 mb-2">
          <CoreDetail
            title="Видатки за економічною класифікацією"
            data={expensesData.kekv}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-0 d-none d-sm-flex">
        <Col md={12} lg={12} xxl={12}>
          <DynamicKbp
            data={compareExpansesData}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-0 d-none d-sm-flex">
        <Col md={12} lg={12} xxl={12}>
          <GrkPlanFactTab
            data={expensesByDisposer}
            cDt={new Date(cDt).toLocaleDateString('uk', { day: 'numeric', month: 'numeric', year: 'numeric' })}
          />
        </Col>
      </Row>
      {/* <SpendTable />  сделать когда будут данные */}
    </>
  );
}

Spend.propTypes = {
  cDt: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    display_expenses_settings: PropTypes.shape({
      cardTotal: PropTypes.bool,
    }),
  }).isRequired,
};

export default Spend;
