import React, {
  memo, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, Modal, SplitButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { referencePropType } from '../../../newEditor/propTypes';
import RequestsLoadingBoostraped from '../../../dataProcessors/requestsLoadingBoostraped';

const MODES = [
  { value: false, label: 'Заповнити за підлеглими документами', icon: faFileUpload },
  { value: true, label: 'Доповнити підлеглими документами', icon: faPlusSquare },
];

function LoadRequestsModal({
  onOk,
  disabled,
  add,
  onChange,
  date,
  fondObject,
  FI,
  budget,
  importAllNotes,
  CSU,
  FIAccount, llabel,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const okHandler = useCallback(
    (selector) => {
      setModalOpened(false);
      onOk(selector);
    },
    [onOk],
  );
  const { label, icon } = useMemo(
    () => MODES.filter((m) => m.value === add).reduce((R, m) => m, {}),
    [add],
  );

  return (
    <>
      <SplitButton
        variant="primary"
        onClick={() => setModalOpened(true)}
        disabled={disabled}
        title={(
          <>
            <FontAwesomeIcon icon={icon || faFileUpload} className="me-2" />
            {label + llabel}
          </>
        )}
      >
        {MODES.map((m) => (
          <Dropdown.Item
            key={m.label}
            active={m.value === add}
            onClick={(e) => {
              onChange(e, m.value);
              setModalOpened(true);
            }}
          >
            <FontAwesomeIcon icon={m.icon} className="me-2" />
            {m.label}
          </Dropdown.Item>
        ))}
      </SplitButton>
      <Modal
        show={modalOpened}
        size="xl"
        onHide={() => setModalOpened(false)}
        dialogClassName="mw-100"
        scrollable
        // container={containerRef.current}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть документи для завантаження
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <RequestsLoadingBoostraped
            date={date}
            fondObject={fondObject}
            onLoad={okHandler}
            FI={FI}
            budget={budget}
            importAllNotes={importAllNotes}
            CSU={CSU}
            FIAccount={FIAccount}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

LoadRequestsModal.propTypes = {
  date: PropTypes.number,
  onOk: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fondObject: referencePropType,
  FI: referencePropType,
  budget: referencePropType,
  importAllNotes: PropTypes.bool,
  CSU: referencePropType,
  FIAccount: referencePropType,
  add: PropTypes.bool,
  disabled: PropTypes.bool,
  llabel: PropTypes.string,
};

LoadRequestsModal.defaultProps = {
  importAllNotes: false,
  disabled: false,
  budget: null,
  FIAccount: null,
  CSU: null,
  fondObject: null,
  FI: null,
  date: null,
  add: false,
  llabel: null,
};

export default memo(LoadRequestsModal);
