import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CoreDetailKdbChart from './CoreDetailKdbChart';
import CardHeader from '../../../../../components/blanks/common/CardHeader';
import { moneysOneDigitStingOptions } from '../../../../../constants/moneys';

const numberFormat = new Intl.NumberFormat('uk');
function LeadItem({ item, isLast }) {
  return (
    <Row
      className={classNames('border-bottom py-1', {
        'border-bottom-0 pb-0': isLast,
      })}
    >
      <Col xl={8} lg={6} sm={8} xs={6} className="d-flex align-items-center">
        <h5 className="fs--0 text-700 mb-0">{item.kdb}</h5>
      </Col>
      <Col xl={4} lg={6} sm={4} xs={6} className="d-flex justify-content-end flex-column align-items-end">
        <p
          className="fs--0 text-700 mb-0 fw-semi-bold "
        >
          {item.amount.toLocaleString('uk', moneysOneDigitStingOptions)}
        </p>
        <h6 className="text-600_ mb-0 fs--2 align-baseline text-nowrap text-info">
          {`${numberFormat.format(item.percent)}%`}
        </h6>
      </Col>
    </Row>
  );
}

function CoreDetailKdb({ title = '', data = [], total = 0 }) {
  return (
    <Card className="h-100">
      <CardHeader
        title={title}
        titleTag="h5"
        className="border-200 border-bottom py-2"
      />
      <Card.Body as={Row}>
        <Col xl={12} md={5} className="mb-xxl-1">
          <CoreDetailKdbChart
            data={data}
            total={total}
          />
        </Col>
        <Col xl={12} md={7}>
          <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
          {data.map((item, index) => (
            <LeadItem
              key={item.kdb}
              item={item}
              isLast={index === data.length - 1}
            />
          ))}
        </Col>
      </Card.Body>
      {/* <FalconCardFooterLink title="Primary" size="sm" to="#!" /> */}
    </Card>
  );
}

LeadItem.propTypes = {
  item: PropTypes.shape({
    kdb: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }),
  isLast: PropTypes.bool.isRequired,
};

LeadItem.defaultProps = {
  item: [],
};

CoreDetailKdb.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number,
};
CoreDetailKdb.defaultProps = {
  title: '',
  data: [],
  total: 0,
};

export default CoreDetailKdb;
