import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DATATYPES, LIGIKA_URL_DATES, LIGIKA_URL_FILE } from './consts';
import { logikaLogout } from '../../../actions/auth';
import { processServerReq } from '../../../actions/dpEditor';

export const useLogikaLoadDKUFiles = (headerForm, ticket) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [dates, setDates] = useState({});
  const [logikaErrors, setLogikaErrors] = useState({});

  const getDates = useCallback(
    async (datatype, year) => {
      const response = await fetch(`${LIGIKA_URL_DATES}?DATATYPE=${datatype}&YEAR=${year}`, {
        method: 'GET',
        // mode: 'no-cors',
        // credentials: 'include',
        headers: {
          ticket,
        },
      });
      if (response.status === 401) {
        dispatch(logikaLogout());
        return null;
      } if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      if (!data.success && data.errorCode !== 'DATA_NOT_FOUND') {
        throw new Error(`Для ${datatype}: ${data.errorMessage} ${data.errorDescription}`);
      }

      return {
        key: datatype,
        data,
      };
    },
    [dispatch, ticket],
  );

  const onGetDates = useCallback(
    () => {
      dispatch(processServerReq('FILL'));
      setErr(null);
      setLoading(true);
      Promise.allSettled(DATATYPES.map((dt) => getDates(dt, headerForm.get('year'))))
        .then((results) => {
          const errors = results
            .filter((r) => r.status === 'rejected')
            .map((r) => r.reason);
          if (errors.length) setErr(errors.join(', '));
          const le = results
            .filter((r) => r.status === 'fulfilled' && !r.value.data.success)
            .reduce((R, r) => ({
              ...R,
              [r.value.key]: r.value.data.errorMessage,
            }), {});
          setLogikaErrors(le);
          const ld = results
            .filter((r) => r.status === 'fulfilled' && r.value.data.success)
            .reduce((R, r) => ({
              ...R,
              [r.value.key]: r.value.data.result,
            }), {});

          setDates(ld);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [dispatch, getDates, headerForm],
  );

  const newDates = useMemo(
    () => DATATYPES.reduce((R, dtype) => {
      const onecDate = new Date(headerForm.get(dtype, 0));
      return {
        ...R,
        [dtype]: dates[dtype] ? dates[dtype].filter((dt) => new Date(dt.datefile) > onecDate) : [],
      };
    }, {}),
    [dates, headerForm],
  );

  const onClearErr = useCallback(
    () => setErr(null),
    [],
  );

  const loadFile = useCallback(
    async (datatype, date, version) => {
      const response = await fetch(`${LIGIKA_URL_FILE}?DATATYPE=${datatype}&DATETIME=${date}&VERSION=${version}&ATTACHEDBUDGETS=True`, {
        method: 'GET',
        // mode: 'no-cors',
        // credentials: 'include',
        headers: {
          ticket,
        },
      });
      if (response.status === 401) {
        dispatch(logikaLogout());
        return null;
      } if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const logikaData = await response.json();
      if (!logikaData.success) {
        throw new Error(`${logikaData.errorMessage} ${logikaData.errorDescription}`);
      }

      const file = JSON.stringify(logikaData.result);
      dispatch(processServerReq('LOAD_FILE', {
        datatype,
        date,
        version,
        file,
      }));
      return {
        key: { datatype, date, version },
        data: true,
      };
    },
    [dispatch, ticket],
  );

  const onLoadFiles = useCallback(
    (loadDates) => {
      setErr(null);
      setLoading(true);
      const files = Object.keys(loadDates).reduce((R, datatype) => [
        ...R,
        ...loadDates[datatype].map((d) => {
          const dt = new Date(d.datefile);
          dt.setMinutes(-dt.getTimezoneOffset());
          return ({ datatype, date: dt.toJSON().substr(0, 10), version: d.version });
        }),
      ], []);
      Promise.allSettled(files.map((f) => loadFile(f.datatype, f.date, f.version)))
        .then(() => {
          // console.log(1);
          onGetDates();
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [loadFile, onGetDates],
  );

  return {
    loading,
    err,
    newDates,
    dates,
    onGetDates,
    onClearErr,
    logikaErrors,
    onLoadFiles,
  };
};
