import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import editor from '../../actions/editor';
import dpEditor from '../../actions/dpEditor';
import ComponentsRowTablePart from '../unionCompontnts/componentsTablePart';

function RowCard({
  row, rowId, tableName, processingKeyDown, componentsType, activeNameCell,
  arrBypassGrid, reFreshForm, containerRef,
}) {
  const dispatch = useDispatch();

  const { typeEditor } = componentsType;

  const activateTableRow = (typeEditor === 'dpEditor') ? dpEditor.activateTableRow : editor.activateTableRow;

  const activateRow = useCallback(
    (e) => dispatch(activateTableRow(tableName, rowId, e.ctrlKey)),
    [activateTableRow, dispatch, rowId, tableName],
  );
  const className = `grid-template ${row.get('IS_ACTIVE', false) ? ' active' : ''}`;
  return (
    <div className={className} onClick={activateRow}>
      <ComponentsRowTablePart
        row={row}
        tableName={tableName}
        rowId={rowId}
        processingKeyDown={processingKeyDown}
        componentsType={componentsType}
        activeNameCell={activeNameCell}
        arrBypassGrid={arrBypassGrid}
        reFreshForm={reFreshForm}
        containerRef={containerRef}
      />
    </div>
  );
}

export default memo(RowCard);
// export default whyUpdateMemo(RowCard);

RowCard.propTypes = {
  rowId: PropTypes.number,
  row: PropTypes.instanceOf(Map),
  tableName: PropTypes.string.isRequired,
  processingKeyDown: PropTypes.func.isRequired,
  componentsType: PropTypes.PropTypes.shape({
    typeEditor: PropTypes.string,
  }).isRequired,
  activeNameCell: PropTypes.string,
  arrBypassGrid: PropTypes.arrayOf(PropTypes.string).isRequired,
  reFreshForm: PropTypes.bool,
  containerRef: PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }).isRequired,

};

RowCard.defaultProps = {
  rowId: 0,
  row: new Map(),
  activeNameCell: null,
  reFreshForm: false,
};
