import React, {
  useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';
import useEditor from '../../../newEditor/hook/editor';
import meta from '../../../../meta';
import EditorContainer from '../../../newEditor/editorContainer';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import ManualEditor from '../../../services/MDEditor/editor';
import api, { backendUri } from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';

const BACKENDURL = meta.cat.manual.backendURL;
function MEditor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();

  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const { onChange } = actions;

  const titleProps = editorHooks.useStringInputProps('title', data, fields, fieldErrors, onChange);
  const urlChoicesProps = editorHooks.useSelectorInputProps('url_choices', data, fields, fieldErrors, onChange);

  const { auth } = useContext(CiatAppContext);

  const onInsertImage = useCallback(
    (file, mdApi) => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const response = await api.post('/api/informations/images/', auth, {
            image: {
              file: reader.result,
              filename: file.name,
            },
          });
          if (response.ok) {
            const d = await response.json();
            const markdownImage = `![Image Alt Text](${backendUri}${d.url})`;
            mdApi.replaceSelection(markdownImage);
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [auth],
  );
  const mdOnChange = useCallback(
    (v) => onChange({ content: v }),
    [onChange],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.title}
    >
      <Container fluid className="pb-3">
        <Row>
          <Col sm={12}>
            <EditorControls.StringInput {...titleProps} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <EditorControls.SelectorInput
              {...urlChoicesProps}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ManualEditor
              value={data.content}
              onChange={mdOnChange}
              onInsertImage={onInsertImage}
            />
          </Col>
        </Row>
      </Container>
    </EditorContainer>
  );
}

MEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

MEditor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default MEditor;
