import React from 'react';

function SettingsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 10C6 7.8 7.8 6 10 6C12.2 6 14 7.8 14 10C14 12.2 12.2 14 10 14" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10L3.5 16.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 17.5V16.5H2.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 17V15.5H3" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 12L11 9L8 10" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 15.4C7.9 15.6 8.3 15.7 8.7 15.8L9 17.5H11L11.3 15.9C12 15.7 12.7 15.5 13.3 15.1L14.7 16.1L16.1 14.7L15.1 13.3C15.5 12.7 15.8 12 15.9 11.3L17.5 11V9L15.9 8.7C15.7 8 15.5 7.3 15.1 6.7L16.1 5.3L14.6 4L13.2 5C12.6 4.6 11.9 4.3 11.2 4.2L11 2.5H9L8.7 4.1C8 4.3 7.4 4.6 6.8 5L5.4 4L4 5.4L5 6.8C4.6 7.4 4.3 8 4.1 8.7L2.5 9V11L4.1 11.3C4.2 11.7 4.3 12.1 4.5 12.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SettingsIcon;
