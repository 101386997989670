import React, {
  forwardRef, memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { StyledListerTable, StyledContainer } from './styles';
import ListerContext from '../../context';
import { Loader } from '../../../../components/bootStrap';
// eslint-disable-next-line import/no-cycle
import FilterPanel from '../filterPanel';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { WinManagerContext } from '../../../../providers';
import { CloseButton } from '../../../../components/bootStrap/buttons';

const TableListerFactory = ({
  CommandPanel,
  RowComponent,
  HeaderComponent,
  ContextMenuComponent,
}) => {
  // При необходимости переместить одно ищ указанныъ нижу свойств в
  // context - удалять из свойств. Т.е. свойство должно быть или в
  // props или в context, но никак и там и там.
  const TableLister = forwardRef(({
    visibleColumns, openedItems, onRowFocus, onSetOrder, order, loading, err,
    onReoload, onScroll, areaSize,
    columnSizes, onSetColumnSizes,
    maxItemLevel, commandPanelRef,
  }, ref) => {
    const {
      onShowCtxMenu, permissions, actions, selectedRows, items, options,
      localFilter, messages, filteringFields, searchString, metaOptions, filterOpened,
    } = useContext(ListerContext);

    const { selectMode } = options;
    const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
    return (
      <div className="bg-white rounded position-relative">
        {!selectMode && (
        <CloseButton
          onClick={() => {
            dellComponentFromWindowsManager();
          }}
        />
        )}
        {!!metaOptions && (
          <div>
            <h5 className="mx-3 pt-2 text-primary">{metaOptions.name}</h5>
            <CommandPanel
              ref={commandPanelRef}
            />
          </div>
        )}
        <div className="d-flex align-items-stretch overflow-hidden  ">
          {!!messages.length && messages.map((m) => (
            <IconAlert
              key={m.id}
              variant={m.variant || 'info'}
              dismissible
              onClose={() => actions.onDeleteMessage(m.id)}
              className="mx-3"
            >
              {m.title && (
                <Alert.Heading>{m.title}</Alert.Heading>
              )}
              {m.text}
            </IconAlert>
          ))}

          <StyledContainer
            width={areaSize.width}
            height={areaSize.height}
            onScroll={onScroll}
            ref={ref}
            className="w-100 scrollbar"
          >
            {loading && (
              <Loader text="Завантаження" />
            )}
            <StyledListerTable
              bordered
              size="sm"
              role="list"
            >
              <HeaderComponent
                columns={visibleColumns}
                onSetOrder={onSetOrder}
                order={order}
                columnSizes={columnSizes}
                onSetColumnSizes={onSetColumnSizes}
                permissions={permissions}
                maxItemLevel={maxItemLevel}
                localFilter={localFilter}
              />
              <tbody>
                {items.map((row, i) => (
                  <RowComponent
                    tabIndex={i + 1}
                    key={row.id}
                    row={row}
                    columns={visibleColumns}
                    onFocus={onRowFocus}
                    selected={selectedRows.includes(row.id)}
                    onContextMenu={ContextMenuComponent ? onShowCtxMenu : null}
                    permissions={permissions}
                    actions={actions}
                    isOpened={openedItems.includes(row.id)}
                  />
                ))}

              </tbody>
            </StyledListerTable>

            {ContextMenuComponent && (
              <ContextMenuComponent />
            )}
            {!items.length && !loading && !err && (
              <IconAlert variant="info" className="mx-3">
                <Alert.Heading>
                  Немає записів
                </Alert.Heading>
                {searchString
                  ? `На жаль за результатами пошуку ${searchString} нічого не знайшлось. Спробуйте перефразувати пошук.`
                  : 'Зараз ще немає записів, але ви можете створити першу.'}
              </IconAlert>
            )}
          </StyledContainer>
          <FilterPanel
            show={filterOpened}
            onToggleFilterOpened={actions.onToggleFilterOpened}
            filteringFields={filteringFields}
            filter={localFilter}
            onSetFilter={actions.onSetFilter}
            height={areaSize.height}
          />
        </div>
        {err && (
        <IconAlert dismissible onClose={onReoload} variant="danger" className="mx-3 fixed-bottom position-sticky">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: err }} />
        </IconAlert>
        )}

      </div>
    );
  });

  TableLister.propTypes = {
    visibleColumns: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    order: PropTypes.shape({
      column: PropTypes.string,
      // direction: PropTypes.oneOf(Object.values(directions)),
    }).isRequired,
    loading: PropTypes.bool,
    err: PropTypes.string,
    onRowFocus: PropTypes.func.isRequired,
    onSetOrder: PropTypes.func.isRequired,
    onReoload: PropTypes.func.isRequired,
    areaSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    onScroll: PropTypes.func.isRequired,
    columnSizes: PropTypes.shape({}),
    onSetColumnSizes: PropTypes.func.isRequired,
    openedItems: PropTypes.arrayOf(
      PropTypes.number,
    ),
    maxItemLevel: PropTypes.number,
    commandPanelRef: PropTypes.shape({}),
    filterOpened: PropTypes.bool.isRequired,
  };

  TableLister.defaultProps = {
    loading: false,
    err: null,
    areaSize: null,
    columnSizes: {},
    openedItems: [],
    maxItemLevel: 0,
    commandPanelRef: null,
  };
  return memo(TableLister);
};

export default TableListerFactory;
