import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import TableDocListerRow from '../../../../newLister/tableComponent/table/row/doctablerow';

/**
 *
 * @param recivedPropName {string}
 * @return {*}
 * @constructor
 */
function RecievedRowFactory(recivedPropName = 'approved') {
  function RecievedRow({ row, ...rest }) {
    const icon = useMemo(
      () => (row[recivedPropName] ? (
        <FontAwesomeIcon icon={faLock} title="Отримано" className="text-info" shake />
      ) : null),
      [row],
    );
    return (
      <TableDocListerRow {...rest} row={row} icon={icon} />
    );
  }

  RecievedRow.propTypes = {
    row: PropTypes.shape({
      [recivedPropName]: PropTypes.bool.isRequired,
    }).isRequired,
  };

  return memo(RecievedRow);
}

export default RecievedRowFactory;
