import React from 'react';

import ReportsPanel from '../../reportsPanel';

function Rep() {
  return (
    <ReportsPanel
      schemaId="allocation_plan"
    />
  );
}

export default Rep;
