import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Col, Alert, Row, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/req';
import AppContext from '../../../providers/CiatAppContext';
// eslint-disable-next-line import/no-cycle
import {
  EditorControls,
  EditorDateInput, EditorStingInput,
} from '../../basicEditor/editorControls';
import checkTask from '../../../api/checktask';
import md from '../../../meta/doc/transferToLogica';
import { CiatAppContext, WinManagerContext } from '../../../providers';
import PageHeader from '../../../components/blanks/common/PageHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { Loader } from '../../../components/bootStrap';

const types = [
  { value: 1, display_name: 'Рік' },
  { value: 2, display_name: 'Квартал' },
  { value: 3, display_name: 'Місяць' },
];

const rozpusType = {
  1: 'R4',
  2: 'K',
  3: 'M',
};

const BACKENDURL = '/api/authorityplans/send_changes_to_logica/';

function ExportChangesToLogicaEditor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const { auth } = useContext(AppContext);
  const { currentUser } = useContext(CiatAppContext);
  const isSuperuser = currentUser.is_superuser;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [type, setType] = useState(1); // variant
  const [authority, setAuthority] = useState(null); // authority
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [sufNumber, setSufNumber] = useState('');
  const [signVariant, setSignVariant] = useState();

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback(() => {
    const loader = async () => {
      const r = await api.get(BACKENDURL, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setType(parseInt(Object.entries(rozpusType).find(
          ([key, value]) => key && value[0] === rData.rozpys_type[0],
        )?.[0], 10));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  useEffect(
    () => {
      getInitial();
      setAuthority(currentUser.authority);
    },
    [currentUser.authority, getInitial],
  );

  const createTransferDoc = useCallback(
    () => {
      const creator = async () => {
        const params = {
          authority,
          begin_date: beginDate,
          end_date: endDate,
          sign_variant: signVariant,
          rozpys_type: `${rozpusType[type]}${sufNumber}`,
        };
        const r = await api.post(BACKENDURL, auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => d[k].join(', '), '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      creator()
        .then(({ task_id: taskId }) => checkTask(taskId, auth))
      // eslint-disable-next-line no-console
        .then((d) => {
          if (d.result.errors) {
            throw new Error(d.result.errors.join(', '));
          }
          navigate(`${md.frontendURL}${d.result.container.id}/`);
        })
        .catch((e) => setErr(e.message))
        .finally(() => onLoadEnd());
    },
    [onLoadStart, authority, beginDate, endDate,
      signVariant, type, sufNumber, auth, navigate, onLoadEnd],
  );

  return (
    <>
      <PageHeader
        title={md.name}
        // description=""
        className="mb-3"
      >
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => {
              dellComponentFromWindowsManager();
            }}
            title="Закрити"
          />
        </div>
      </PageHeader>
      {loading && <Loader />}
      {err && (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </Alert>
      )}
      <Card>
        <Card.Header>
          Параметри для заповнення документу
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <EditorControls.RadioInput
                value={type}
                values={types}
                onChange={(e, v) => setType(v)}
                className="d-flex gap-2"
              />
            </Col>
            {type !== 1 && (
            <Col>
              <EditorStingInput
                value={sufNumber}
                label={`Номер ${type === 2 ? 'квартала' : 'місяця'}`}
                onChange={(e, v) => setSufNumber(v)}
                required
              />
            </Col>
            )}
          </Row>
          <Row>
            <Col>
              {!isSuperuser && (
              <div className="flex-fill fst-italic d-flex align-items-center pt-3">
                <FontAwesomeIcon
                  className="me-1"
                  icon={faMapMarkerAlt}
                />
                {authority && authority.repr}
              </div>
              )}
              {isSuperuser && (
              <EditorControls.ItemPicker
                value={authority}
                onChange={(e, v) => setAuthority(v)}
                modelType="cat"
                label="Фінансовий орган"
                modelName="disposers"
                backend="/api/references/refdisposer/"
                required
              />
              )}
            </Col>
            <Col>
              <EditorControls.ItemPicker
                value={signVariant}
                onChange={(e, v) => setSignVariant(v)}
                modelType="cat"
                label="Підпис"
                modelName="authoritysignvariant"
                backend="/api/references/refauthoritysignvariant/"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorDateInput
                controlId="begin_date"
                label="Відібрати документи за період з"
                required
                value={beginDate}
                onChange={(e, v) => setBeginDate(v)}
              />
            </Col>
            <Col>
              <EditorDateInput
                controlId="end_date"
                label="по"
                required
                value={endDate}
                onChange={(e, v) => setEndDate(v)}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="falcon-success"
            onClick={createTransferDoc}
            disabled={!authority || !signVariant || (type > 1 && !sufNumber)}
            title="Створити документ відправки в LOGICA"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
            Створити документ відправки в LOGICA
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

ExportChangesToLogicaEditor.propTypes = {
};

export default ExportChangesToLogicaEditor;
