import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  planingKind: { label: 'Вид плану', name: 'ВидПлана', visible: true },
  year: { label: 'Рік', name: 'Год', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  nMark: { label: 'Номер довідки про зміни до помісячного розпису', name: 'НомерСправки', visible: false },
  baseMark: { label: 'Підстава до змін помісячного розпису', name: 'ОснованиеДляСправки', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  dCSU: { label: 'Розпорядники', name: 'ОписРасп', visible: true },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Розпис',
    columns: {
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      kbp: { label: 'КБП', name: 'КБП' },
      kekv: { label: 'КЕКВ', name: 'КЕКВ' },
      branches: { label: 'Галузь', name: 'Отрасль' },
      sumZF: { label: 'Заг. фонд', name: 'СуммаЗФ' },
      sum: { label: 'Сума усьго', name: 'Сумма' },
      sumSF: { label: 'Спец. фонд', name: 'СуммаСФ' },
      elKekv: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      sumBR: { label: 'в т.ч. БР', name: 'СуммаБР' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Граничний обсяг',
  name: 'borderLimit',
  backendName: 'ПредельныйОбъем',
  columns,
  frontend: 'doc/borderLimit',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'dCSU',
      'planingKind',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
