import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: false },
  CodeOnDate: { label: 'Код на дату', name: 'тКод', visible: true },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'НаименованиеПолное',
    visible: true,
  },
  Inactive: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: false,
  },
  Date_kl: {
    label: 'Дата',
    name: 'ДатаКл',
    visible: false,
  },
};

export const kvkClassifier = {
  label: 'Класифікація відомча (КВК)',
  name: 'kvkClassifier',
  backendName: 'КлассификаторКВК',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/kvkClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'FullName',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default kvkClassifier;
