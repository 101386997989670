import React from 'react';
import { Card } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import content from './content';
import { dellComponentFromWindowsManager } from '../../../../actions/windowsManager';

const url = 'https://zakon.rada.gov.ua/laws/show/z1372-21';



function CloseLink() {
  const dispatch = useDispatch();
  return (
    <Link
      className="d-flex justify-content-end me-2"
      to="/"
      onClick={() => {
        dispatch(dellComponentFromWindowsManager('/srv/reportsCalendar/'));
      }}
    >
      <h6>X</h6>
    </Link>
  );
}
const tableStyles = {
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    oddRow: {
      backgroundColor: "#f2f2f2",
    },
    evenRow: {
      backgroundColor: "#ffffff",
    },
    cell: {
      padding: "10px",
      border: "1px solid #ddd",
    },
  };

function Editor() {
  return (
    <Card>
      <CloseLink/>
      <Card.Header>
        <Card.Title>
          Перелік звітних форм та терміни їх надсилання до Logica, згідно
          наказу Мінфіну від 30.08.2021 № 488 (Редакція від 22.08.2024)
        </Card.Title>
        <Card.Link href={url} target="_blank" rel="noreferrer">
            Про затвердження Порядку обміну інформацією між Міністерством фінансів України
            та учасниками бюджетного процесу на місцевому рівні
        </Card.Link>
      </Card.Header>
      {content && content.map((item) => (
          <div className="ms-4 mb-2">
            <Card.Title>
              {item.header}
            </Card.Title>
              <Card.Body className="bg-light pb-0">
                  <tbody>
                  {item.items.map((el, index) => (
                      <tr
                        key={index}
                        style={
                        index % 2 === 0 ? tableStyles.evenRow : tableStyles.oddRow
                      }
                      >
                          <td style={tableStyles.cell}>{el.name}</td>
                          <td style={tableStyles.cell}>{el.description}</td>
                      </tr>
                  ))}
                  </tbody>
              </Card.Body>
          </div>
      ))}
    </Card>
  );
}

// ReportEditor.displayName = 'ReportEditor';

export default Editor;
