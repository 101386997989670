import PlaningKind from './planingKind';
import PageOrientation from './pageOrientation';
import Factor from './factor';
import TypeIndicators from './typeIndicators';
import FundKEKVKInds from './fundKEKVKinds';
import { DataComposition } from './DataComposition';
import QuartalVariants from '../../../containers/documents/assignmentPlanning/enumsForPrintModal/QuartalVariants';
import ObligationTypes from './obligationTypes';
import ProcurementProc from './procurementProc';
import docRegisterTypes from './docRegistrTypes';
import ObjBuildingTypeContract from './objBuildingTypeContract';
import SignatureTypes from './SignatureTypes';
import ReportsType from './reportsType';
import BankAccaountTypes from './bankAccaountTypes';
import PeriodSelection from './periodSelection';
import FillYears from './fillYears';
import { userMessageTypes } from './usermessages';
import { typeMessageRecipients } from './typeMessageRecipients';
import ReportsKind from './reportsKind';
import LogicaReportType from './logicaReportTypes';
import PlanFact from './planFact';
import TypeRepPlanSpend from './typeRepPlanSpend';
import PlaningKindChange from './planingKindChange';
import LiquidityCalcMethod from './liquidityCalcMethod';
import FondKind from './fondKind';
import effectivenessTypes from './effectivenessTypes';
import DocumentStornoReportTypes from './documentStornoReportTypes';

const enums = {
  PlaningKind,
  PageOrientation,
  Factor,
  TypeIndicators,
  FundKEKVKInds,
  DataComposition,
  QuartalVariants,
  ObligationTypes,
  ProcurementProc,
  docRegisterTypes,
  ObjBuildingTypeContract,
  SignatureTypes,
  ReportsType,
  ReportsKind,
  BankAccaountTypes,
  PeriodSelection,
  FillYears,
  userMessageTypes,
  typeMessageRecipients,
  LogicaReportType,
  PlanFact,
  TypeRepPlanSpend,
  PlaningKindChange,
  LiquidityCalcMethod,
  FondKind,
  effectivenessTypes,
  DocumentStornoReportTypes,
};

export default enums;
