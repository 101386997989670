import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_31: {
    name: 'Табл31',
    label: 'Табл 31',
    columns: {
      privilegeNumber: { label: 'Номер пільги 1.n', name: 'НомерЛьготы' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      privilegeName: { label: 'Назва пільги 1.n', name: 'НаимЛьготы' },
      privilegeAmount: { label: 'Розмір пільги 1.n', name: 'РазмерЛьготы' },
    },
  },
  TABL_32: {
    name: 'Табл32',
    label: 'Табл 32',
    columns: {
      privilegeNumber: { label: 'Номер пільги 2.n', name: 'НомерЛьготы' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      privilegeName: { label: 'Назва пільги 2.n', name: 'НаимЛьготы' },
      privilegeAmount: { label: 'Розмір пільги 2.n', name: 'РазмерЛьготы' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 3 (стар. 2П)',
  name: 'taxes2P',
  backendName: 'НалогиИЛьготыДод2П',
  columns,
  frontend: 'doc/taxes2P',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
