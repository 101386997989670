import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/forecastAdd1';
import {
  IncomesTP, FinancingTP, CreditReturnTP, CreditTP, ExpensesTP, DocsTP,
} from './tabs';

function ForecastAdd1Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="incomes" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.incomes.label} eventKey="incomes">
        <IncomesTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.financing.label} eventKey="financing">
        <FinancingTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.creditReturn.label} eventKey="creditReturn">
        <CreditReturnTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.credit.label} eventKey="credit">
        <CreditTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.expenses.label} eventKey="expenses">
        <ExpensesTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.docs.label} eventKey="docs">
        <DocsTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

ForecastAdd1Tabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default ForecastAdd1Tabs;
