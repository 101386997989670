import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const generateNewGrid = ({
  CSUinH,
}) => styled(BasedGrid)`
      grid-template-areas:
          "num ${!CSUinH ? 'KVKCode csu' : 'KPKVCode elementKFK'} BuildObgect CSUAccount    Balance Sum        "
          "num                              KPKVCode elementKFK   BuildObgect CSUAccountNo  Balance SumPayment "
          "num                              KEKVCode elementKEKV  TypeRepair  CSUAccountNo  Balance SumPayment ";
      
      grid-template-columns: 4% 8% 1fr 1fr 15% 10% 10%;
      grid-auto-columns: 10%;
      `;

class GridTemplate extends Component {
  static propTypes = {
    CSUinH: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    isHeader: PropTypes.bool,
  };

  static defaultProps = {
    CSUinH: false,
    children: null,
    isHeader: false,
  };

  constructor(props) {
    super(props);
    this.state = { GridTemplate: generateNewGrid(props) };
  }

  shouldComponentUpdate(nextProps) {
    const {
      CSUinH,
    } = this.props;
    if (nextProps.CSUinH !== CSUinH
    ) {
      this.setState({ GridTemplate: generateNewGrid(nextProps) });
    }
    return true;
  }

  render() {
    const {
      children, isHeader,
    } = this.props;

    const Grid = this.state.GridTemplate;
    return (
      <Grid isHeader={isHeader}>
        {children}
      </Grid>
    );
  }
}

export default GridTemplate;
