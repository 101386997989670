import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  year: { label: 'Період звіту', name: 'ГодЗатрат' },
  netCode: { label: 'Показувати мережу з код 000000', name: 'флПоказыватьКодМережаНоль' },
  treas: { label: 'Показувати дані за казною', name: 'флПоказыватьДанныеКазна' },
};

const definition = {
  label: 'Довідка щодо видатків бюджету (без власних надходжень)',
  name: 'ExpensesBudget',
  columns,
  frontend: 'rep/ExpensesBudget',
  backendName: 'РасходыБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
