import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { childrenPropType } from '../../../constants/backend/propTypes';

export const ItemField = forwardRef(({
  id, value, children, isLoading, editText, onChange, readOnly,
  onClick, onFocus, errored, className, prepend, append, size, onBlur,
}, ref) => (
  <InputGroup className={className} size={size || 'sm'}>
    {prepend}
    <FormControl
      id={id}
      ref={ref}
      value={editText === null ? ((value && value.repr) || '') : editText}
      readOnly={readOnly}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => onChange(e, e.target.value)}
      isInvalid={errored}
      size={size || 'sm'}
      title={editText === null && value && value.repr}
      autoComplete="off"
    />
    {append}
    {isLoading && (
      <InputGroup.Text>
        <Spinner animation="grow" size="sm" />
      </InputGroup.Text>
    )}
    {children}
  </InputGroup>
));

export const valuePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  repr: PropTypes.string,
});

ItemField.propTypes = {
  size: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  children: childrenPropType,
  value: valuePropType,
  editText: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errored: PropTypes.bool,
  className: PropTypes.string,
  prepend: childrenPropType,
  append: childrenPropType,
};

ItemField.defaultProps = {
  id: '',
  value: { id: null, repr: '' },
  size: 'sm',
  isLoading: false,
  children: null,
  editText: null,
  onChange: () => null,
  readOnly: false,
  onClick: null,
  onFocus: null,
  onBlur: null,
  errored: false,
  className: null,
  prepend: null,
  append: null,
};

export default ItemField;
