import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/analysisIndicatorsSF';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ThreeColumnsGridContainer,
  SevenColumnsGridContainer,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
  ResultSegment,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';

class AnalysisIndicatorsSFEditor extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    onReportDetails: null,
  };

  static displayName = `Report${md.name}Editor`;

  constructor(props) {
    super(props);
    this.resultMountNode = [`rep/${md.name}/reportEditor`, 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, onReportDetails,
      },
    } = this;

    const isConsolidated = headerForm.get('consolidated', false);
    const { portalOpened } = this.props;
    const FO = headerForm.get('FO', new Map());

    const BudgetLabel = (
      <div>
        <StyledLabel>{md.columns.budget.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('budget', new Map())}
          modelType="cat"
          modelName="budget"
          onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
        />
      </div>
    );

    const FOLabel = (
      <div>
        <StyledLabel>{md.columns.FO.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('FO', new Map())}
          disabled={isConsolidated}
          modelType="cat"
          modelName="csu"
          noHierarchy
          filter={[{ fieldName: 'parent', value: headerForm.get('FO', new Map()) }]}
          onChange={(e, v) => {
            dispatch(changeField(['headerForm', 'FO'], v));
          }}
        />
      </div>
    );

    const SettingsButton = (
      <div>
        <div>
          <ThreeColumnsGridContainer>
            <div>
              {BudgetLabel}
            </div>
            <div>
              {FOLabel}
            </div>
            <Fields.CheckboxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'consolidated'], v));
                // dispatch(processServerReq('CHANGE_CONSOLIDATED'));
              }}
            />
          </ThreeColumnsGridContainer>
          <SevenColumnsGridContainer>
            <div>
              <Fields.SelectorField
                value={headerForm.get('fond', new Map())}
                label={md.columns.fond.label}
                modelType="cat"
                modelName="fondClassifier"
                onChange={(e, v) => {
                  dispatch(changeField(['headerForm', 'fond'], v));
                }}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('in1000', false)}
                label={md.columns.in1000.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('byDocs', false)}
                label={md.columns.byDocs.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'byDocs'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('groupLines', false)}
                label={md.columns.groupLines.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'groupLines'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('symbol0', false)}
                label={md.columns.symbol0.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'symbol0'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('groupKEKV', false)}
                label={md.columns.groupKEKV.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'groupKEKV'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('consolidatedBudgets', false)}
                label={md.columns.consolidatedBudgets.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidatedBudgets'], v))}
              />
            </div>
          </SevenColumnsGridContainer>
        </div>
        <DivWithPropsLabel>
          <StyledPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledPane>
        </DivWithPropsLabel>
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        {' '}
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
              noBorder
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(AnalysisIndicatorsSFEditor);
