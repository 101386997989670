import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  activity: foreignPropType,
  amount_pr_fact_total: PropTypes.number,
  amount_pr_fact_sf: PropTypes.number,
  amount_pr_fact_zf: PropTypes.number,
  amount_fact_total: PropTypes.number,
  amount_fact_sf: PropTypes.number,
  amount_fact_zf: PropTypes.number,
  amount_diff_total: PropTypes.number,
  amount_diff_sf: PropTypes.number,
  amount_diff_zf: PropTypes.number,
  cost_change_explanation: PropTypes.string,
  inindicator_dynamics_explanation: PropTypes.string,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
