import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Number: {
    label: 'Номер по виборчому списку',
    name: 'Ном',
    visible: false,
  },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  SortNumber: {
    label: 'Номер для сортування',
    name: 'Сорт',
    visible: false,
  },
};

export const deputies = {
  label: 'Депутати',
  name: 'Deputies',
  backendName: 'Депутаты',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/deputies',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default deputies;
