import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { changeField } from '../../../../../actions/dpEditor';
import GridTemplate from './GridTemplate';
import {
  DivCell,
  DivCheckCell,
} from '../../../../../components/styledTable/styledTable';
import Fields from '../../../../field';

const StringInput = Fields.GetTextInput();

const TABLENAME = 'users';

function RowComponent({ row, rowId, dispatch }) {
  const onSendChange = useCallback(
    (e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'send'], v)),
    [dispatch, rowId],
  );
  return (
    <GridTemplate>
      <DivCheckCell areaName="send">
        <Fields.CheckboxField
          value={row.get('send', false)}
          onChange={onSendChange}
        />
      </DivCheckCell>
      <DivCell areaName="user">
        <StringInput
          noBackground
          noBorder
          value={row.getIn(['user', 'repr'], '')}
          readOnly
        />
      </DivCell>
    </GridTemplate>

  );
}

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(RowComponent);
