import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getColor } from '../../../../components/blanks/helpers/utils';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';
import valueFormatter from '../const/valueFormatter';
import useDiagramShower from '../diagramHook';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent,
]);

/// Функция для преобразования данных
const transformData = (data) => {
  const dtPlanFact = Object.keys(data).map((key) => {
    const el = data[key];
    return [
      el.repr,
      el.fact,
      el.plan,
    ];
  });

  return [
    ['Порівняння', 'Факт', 'План'],
    ...dtPlanFact,
  ];
};

const getOption = (data) => ({
  color: [getColor('primary'), getColor('gray-300')],
  dataset: { source: data },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter(params) {
      return `<div className="fw-semi-bold">${params.seriesName}</div>
              <div className="fs--1 text-600"><strong>${params.name}:</strong> ${params.value[params.componentIndex + 1].toLocaleString('uk', moneysOneDigitStingOptions)}</div>`;
    },
  },
  legend: {
    data: ['Факт', 'План'],
    left: 'left',
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') },
  },
  xAxis: {
    type: 'category',
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed',
      },
    },
    axisTick: false,
    boundaryGap: true,
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed',
      },
    },
    axisLine: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: (value) => valueFormatter(value),
    },
  },
  series: [
    {
      type: 'bar',
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary'),
      },
    },
    {
      type: 'bar',
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('gray-300'),
      },
    },
  ],
  grid: {
    right: '0',
    left: '85px',
    bottom: '10%',
    top: '12%',
  },
});

function TopProducts({ data }) {
  const chartData = transformData(data);
  const { containerChartRef, show } = useDiagramShower({});
  return (
    <Card className="h-100">
      <CardHeader
        title="Виконання дохідної частини бюджету за місяцями"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body className="h-100" ref={containerChartRef}>
        {show && (
          <ReactEChartsCore
            echarts={echarts}
            option={getOption(chartData)}
            style={{ height: '100%', minHeight: '17.75rem' }}
          />
        )}
      </Card.Body>
    </Card>
  );
}

TopProducts.propTypes = {
  data: PropTypes.shape([]).isRequired,
};

export default TopProducts;
