import styled from 'styled-components';

export const StyledTable = styled.table`
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  & thead td, & thead th {
    border-bottom: 2px solid #dee2e6; 
  } 
  & td, & th {
    padding: .75rem;
    border: 1px solid #dee2e6;
  }
  & tbody td:hover, & tbody th:hover {
    background: linear-gradient(0deg,#66BC7A,#66BC7A),#008F21;
  }
  & tbody tr.active td, & tbody tr.active th {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), rgb(0, 143, 33);
  }
`;

export const TableContainer = styled.div`
`;

export const CommandPanel = styled.div`
  display: flex;
`;

export const StyledField = styled.div`
  width: 100%;
  line-height: 1.21428571em;
  padding: 2.5px 2px;
  border-radius: .28571429rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  & div {
    width: calc(100% - 26px);
    overflow: hidden;  
  }
  & .change-button {
    padding: 2px 6px;
    border-radius: 4px;
    color: black;
    border: 1px solid #afbbcc;
    background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
    cursor: pointer;
    outline: none;
    width: 26px;
    height: 26px;
    margin-left: 10px;
    & .change-button:hover{
      background: #AAC6E6;
    }
   & .change-button:focus {
    outline:none;
   }
  }

  //filter: props => props.disabled && 'grayscale(1) blur(1px)'};
`;
