import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { CiatAppContext } from '../../../providers';
import Treeview from '../../../components/blanks/common/Treeview';
import { CPButton } from '../../../components/bootStrap/buttons';

function ItemLevelDownButton({ url, disabled, title }) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [opened, setOpened] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const displayItems = useMemo(
    () => {
      const makeLevel = (itms) => itms.map((item) => ({
        id: item.disposer.id,
        name: (
          <>
            <span className="fw-bold me-2">
              {item.disposer.repr}
            </span>
            -
            {item.item ? (
              <span className="ms-2 text-success">
                {item.item.repr}
              </span>
            ) : (
              <span className="ms-2 text-warning">
                Не створено
              </span>
            )}
          </>),
        // expanded: item.nested && item.nested.length > 0,
        children: item.nested ? makeLevel(item.nested) : null,
        disabled: !!item.item,
      }));
      return makeLevel(items);
    },
    [items],
  );
  const upperItems = useMemo(
    () => {
      const getUpper = (itms, parents) => itms.reduce((R, item) => ({
        ...R,
        [item.disposer.id]: { parents, item },
        ...(getUpper(item.nested || [], [...parents, item.disposer.id])),
      }), {});
      return getUpper(items, []);
    },
    [items],
  );

  const { auth } = useContext(CiatAppContext);

  const load = useCallback(
    async () => {
      const r = await api.get(`${url}leveldowns/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, url],
  );

  const create = useCallback(
    () => {
      const loader = async () => {
        const params = {
          disposers: selectedItems,
          createStructure: true,
        };
        const r = await api.post(`${url}leveldowns/`, auth, params);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(false);
      loader()
        .then(() => setOpened(false))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, selectedItems, url],
  );

  const onSetSelectedItems = useCallback(
    (newItems) => {
      setSelectedItems((oldItems) => {
        const addedItems = newItems.filter((itm) => !oldItems.includes(itm));
        // Добавляем те которые надо добавить - без создания на промежуточных уровнях не получится
        const itmsToAdd = addedItems.reduce((R, itm) => [
          ...R,
          ...upperItems[itm].parents.filter((uid) => !upperItems[uid].item.item),
        ], []).filter((itm) => !oldItems.includes(itm));
        const removedItems = oldItems.filter((itm) => !newItems.includes(itm));
        const getChldrns = (itms) => itms.reduce(
          (R, itm) => [
            ...R,
            itm,
            ...(upperItems[itm].item.nested
              ? getChldrns(upperItems[itm].item.nested.map((i) => i.disposer.id))
              : []),
          ],
          [],
        );
        // Удаляем те которые надо удалить - без создания на промежуточных уровнях не получится
        const itmsToRemove = getChldrns(removedItems);
        return [...newItems, ...itmsToAdd].filter((itm) => !itmsToRemove.includes(itm));
      });
    },
    [upperItems],
  );

  const onLoadClick = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      load()
        .then((d) => {
          setItems(d);
          setSelectedItems([]);
          setOpened(true);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [load],
  );
  return (
    <>
      <CPButton
        variant={err ? 'danger' : 'success'}
        onClick={onLoadClick}
        disabled={loading || disabled}
        content="Створити для підлеглих розпорядників"
        icon={faArrowDown}
      />
      <Modal show={opened} size="xl" onHide={() => setOpened(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            Створення елементів
            <span className="fw-bold fs-1">{title}</span>
            {' '}
            для розпорядників нижчого рівня
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Treeview
            data={displayItems}
            selection
            selectedItems={selectedItems}
            setSelectedItems={onSetSelectedItems}
            separated
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={create}>Створити</Button>
          <Button variant="secondary" onClick={() => setOpened(false)}>Закрити</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ItemLevelDownButton.propTypes = {
  url: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ItemLevelDownButton.defaultProps = {
  disabled: false,
};

export default ItemLevelDownButton;
