import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/financingRequest';
import FinancingRequest from './editor';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import APMenu from './menu';
import FinancingRequestTabs from './tables';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';

function isReadOnly(data) {
  return !!data[md.columns.IsApproved.name] || !!data[md.columns.Financed.name];
}

function FinancingRequestEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    readOnlyGetter: isReadOnly,
  });
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
        loading={loading}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
        data={data}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <FinancingRequest data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <FinancingRequestTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

FinancingRequestEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

FinancingRequestEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { FinancingRequest as StdEditor };

export default getRouterEditor(md)(FinancingRequestEditor);
