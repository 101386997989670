import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  fields, activeCol, highlights, onToggleHighlight, activeObjectsBuilding,
  disposerInHeader, totals, transferType, isRefundByMonth,
}) {
  return (
    <>
      {transferType === 2 && (
      <TableHeaderRow noGutters className="px-4">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount'}
          title={totals.toLocaleString('uk', moneysStingOptions)}
          highlighted={highlights.includes('amount')}
          onToggleHighlight={() => onToggleHighlight('amount')}
        >
          {fields.amount.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'remark_main'}
          highlighted={highlights.includes('remark_main')}
          onToggleHighlight={() => onToggleHighlight('remark_main')}
        >
          {fields.remark_main.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'remark_note'}
          highlighted={highlights.includes('remark_note')}
          onToggleHighlight={() => onToggleHighlight('remark_note')}
        >
          {fields.remark_note.label}
        </TableHeaderCell>
      </TableHeaderRow>
      )}
      {transferType !== 2 && (
      <TableHeaderRow noGutters className="px-4">
        <Col>
          {!disposerInHeader
          && (
            <Row noGutters>
              <Col
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer'}
                  highlighted={highlights.includes('disposer')}
                  onToggleHighlight={() => onToggleHighlight('disposer')}
                >
                  {fields.disposer.label}
                </TableHeaderCell>
              </Col>
              <Col
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer_acc'}
                  highlighted={highlights.includes('disposer_acc')}
                  onToggleHighlight={() => onToggleHighlight('disposer_acc')}
                >
                  {fields.disposer_acc.label}
                </TableHeaderCell>
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col
              className="d-flex flex-column border-right"
            >
              <Row noGutters>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'kbp7_item'}
                    highlighted={highlights.includes('kbp7_item')}
                    onToggleHighlight={() => onToggleHighlight('kbp7_item')}
                  >
                    {fields.kbp7_item.label}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'kekv_item'}
                    highlighted={highlights.includes('kekv_item')}
                    onToggleHighlight={() => onToggleHighlight('kekv_item')}
                  >
                    {fields.kekv_item.label}
                  </TableHeaderCell>
                  {disposerInHeader
          && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer_acc'}
              highlighted={highlights.includes('disposer_acc')}
              onToggleHighlight={() => onToggleHighlight('disposer_acc')}
            >
              {fields.disposer_acc.label}
            </TableHeaderCell>
          )}
                </Col>
              </Row>
            </Col>
            {activeObjectsBuilding
    && (
      <Col xl={2} className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'buildObgect'}
          highlighted={highlights.includes('buildObgect')}
          onToggleHighlight={() => onToggleHighlight('buildObgect')}
        >
          {fields.buildObgect.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'typeRepair'}
          highlighted={highlights.includes('typeRepair')}
          onToggleHighlight={() => onToggleHighlight('typeRepair')}
        >
          {fields.typeRepair.label}
        </TableHeaderCell>
      </Col>
    )}
            <Col xl={activeObjectsBuilding ? 6 : 8}>
              <Row noGutters>
                {transferType !== 1 && (
                <Col
                  className="border-right"
                >
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'plan_period_amount'}
                    highlighted={highlights.includes('plan_period_amount')}
                    onToggleHighlight={() => onToggleHighlight('plan_period_amount')}
                  >
                    {fields.plan_period_amount.label}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'fact_period_amount'}
                    highlighted={highlights.includes('fact_period_amount')}
                    onToggleHighlight={() => onToggleHighlight('fact_period_amount')}
                  >
                    {fields.fact_period_amount.label}
                  </TableHeaderCell>
                </Col>
                )}
                <Col
                  className="border-right"
                >
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'balance'}
                    highlighted={highlights.includes('balance')}
                    onToggleHighlight={() => onToggleHighlight('balance')}
                  >
                    {fields.balance.label}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'fin_commitment_amount'}
                    highlighted={highlights.includes('fin_commitment_amount')}
                    onToggleHighlight={() => onToggleHighlight('fin_commitment_amount')}
                  >
                    {fields.fin_commitment_amount.label}
                  </TableHeaderCell>
                </Col>
                <Col
                  className="border-right"
                >
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'register_acc_remain'}
                    highlighted={highlights.includes('register_acc_remain')}
                    onToggleHighlight={() => onToggleHighlight('register_acc_remain')}
                  >
                    {fields.register_acc_remain.label}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'treasury_acc_remain'}
                    highlighted={highlights.includes('treasury_acc_remain')}
                    onToggleHighlight={() => onToggleHighlight('treasury_acc_remain')}
                  >
                    {fields.treasury_acc_remain.label}
                  </TableHeaderCell>
                </Col>
                <Col
                  className="border-right"
                >
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'amount'}
                    title={totals.toLocaleString('uk', moneysStingOptions)}
                    highlighted={highlights.includes('amount')}
                    onToggleHighlight={() => onToggleHighlight('amount')}
                  >
                    {fields.amount.label}
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'finrequest'}
                    highlighted={highlights.includes('finrequest')}
                    onToggleHighlight={() => onToggleHighlight('finrequest')}
                  >
                    {fields.finrequest.label}
                  </TableHeaderCell>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {isRefundByMonth && (
            <Col xl={2}>
              <Row>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'return_funding'}
                    highlighted={highlights.includes('return_funding')}
                    onToggleHighlight={() => onToggleHighlight('return_funding')}
                  >
                    Вкл.
                  </TableHeaderCell>
                </Col>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'return_month'}
                    highlighted={highlights.includes('return_month')}
                    onToggleHighlight={() => onToggleHighlight('return_month')}
                  >
                    {fields.return_month.label}
                  </TableHeaderCell>
                </Col>
              </Row>
            </Col>
            )}
            <Col>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'remark_note'}
                highlighted={highlights.includes('remark_note')}
                onToggleHighlight={() => onToggleHighlight('remark_note')}
              >
                {fields.remark_note.label}
              </TableHeaderCell>
            </Col>
            <Col xl={3}>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'remark_template'}
                highlighted={highlights.includes('remark_template')}
                onToggleHighlight={() => onToggleHighlight('remark_template')}
              >
                {fields.remark_template.label}
              </TableHeaderCell>
            </Col>
            <Col xl={3}>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'purpose_of_payment'}
                highlighted={highlights.includes('purpose_of_payment')}
                onToggleHighlight={() => onToggleHighlight('purpose_of_payment')}
              >
                {fields.purpose_of_payment.label}
              </TableHeaderCell>
            </Col>
          </Row>
        </Col>
      </TableHeaderRow>
      )}
    </>

  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.number,
  disposerInHeader: PropTypes.bool,
  activeObjectsBuilding: PropTypes.bool,
  transferType: PropTypes.number,
  isRefundByMonth: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  totals: 0,
  transferType: 0,
};

export default Header;
