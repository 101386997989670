import PropTypes from 'prop-types';
import React from 'react';

import {
  Alert,
  Badge,
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import {
  Tabl1TP, Tabl2TP, Tabl3TP, Tabl4TP, Tabl5TP, Tabl6TP, Tabl7TP, Tabl8TP,
  Tabl91TP, Tabl92TP, Tabl93TP, Tabl101TP, Tabl102TP, Tabl103TP,
} from './tabs';
import md from '../../../../constants/meta/documents/localDebt';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function LocalDebtTabs({
  data, actions, permissions,
}) {
  const readOnly = !permissions.canChange;

  // const [key, setKey] = useState('form2');
  return (
    <Tabs>
      <Tab title="Додаток 1" eventKey="R01G2">
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 3}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G2.label}
              value={data[md.columns.R01G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G3.label}
              value={data[md.columns.R01G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G4.label}
              value={data[md.columns.R01G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G4.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G5.label}
              value={data[md.columns.R01G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G5.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G6.label}
              value={data[md.columns.R01G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G7.label}
              value={data[md.columns.R01G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G8.label}
              value={data[md.columns.R01G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G9.label}
              value={data[md.columns.R01G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G10.label}
              value={data[md.columns.R01G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G11.label}
              value={data[md.columns.R01G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R01G12.label}
              value={data[md.columns.R01G12.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R01G12.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 2}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G2.label}
              value={data[md.columns.R02G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G3.label}
              value={data[md.columns.R02G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G4.label}
              value={data[md.columns.R02G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G4.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G5.label}
              value={data[md.columns.R02G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G5.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G6.label}
              value={data[md.columns.R02G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G7.label}
              value={data[md.columns.R02G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G8.label}
              value={data[md.columns.R02G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G9.label}
              value={data[md.columns.R02G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G10.label}
              value={data[md.columns.R02G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G11.label}
              value={data[md.columns.R02G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R02G12.label}
              value={data[md.columns.R02G12.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R02G12.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 1}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G2.label}
              value={data[md.columns.R03G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G3.label}
              value={data[md.columns.R03G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G4.label}
              value={data[md.columns.R03G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G4.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G5.label}
              value={data[md.columns.R03G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G5.name]: value,
              })}
              readOnly
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G6.label}
              value={data[md.columns.R03G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G7.label}
              value={data[md.columns.R03G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G8.label}
              value={data[md.columns.R03G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G9.label}
              value={data[md.columns.R03G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G10.label}
              value={data[md.columns.R03G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G11.label}
              value={data[md.columns.R03G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R03G12.label}
              value={data[md.columns.R03G12.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R03G12.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name]}
              {' (план на рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G2.label}
              value={data[md.columns.R04G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G3.label}
              value={data[md.columns.R04G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G4.label}
              value={data[md.columns.R04G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G4.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G5.label}
              value={data[md.columns.R04G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G5.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G6.label}
              value={data[md.columns.R04G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G7.label}
              value={data[md.columns.R04G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G8.label}
              value={data[md.columns.R04G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G9.label}
              value={data[md.columns.R04G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G10.label}
              value={data[md.columns.R04G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G11.label}
              value={data[md.columns.R04G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R04G12.label}
              value={data[md.columns.R04G12.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R04G12.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 2" eventKey="R05G2">
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 3}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G2.label}
              value={data[md.columns.R05G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G3.label}
              value={data[md.columns.R05G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G4.label}
              value={data[md.columns.R05G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G4.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G5.label}
              value={data[md.columns.R05G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G5.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G6.label}
              value={data[md.columns.R05G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G7.label}
              value={data[md.columns.R05G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G8.label}
              value={data[md.columns.R05G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G9.label}
              value={data[md.columns.R05G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G10.label}
              value={data[md.columns.R05G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R05G11.label}
              value={data[md.columns.R05G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R05G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 2}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G2.label}
              value={data[md.columns.R06G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G3.label}
              value={data[md.columns.R06G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G4.label}
              value={data[md.columns.R06G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G4.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G5.label}
              value={data[md.columns.R06G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G5.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G6.label}
              value={data[md.columns.R06G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G7.label}
              value={data[md.columns.R06G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G8.label}
              value={data[md.columns.R06G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G9.label}
              value={data[md.columns.R06G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G10.label}
              value={data[md.columns.R06G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R06G11.label}
              value={data[md.columns.R06G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R06G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name] - 1}
              {' (факт за рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G2.label}
              value={data[md.columns.R07G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G3.label}
              value={data[md.columns.R07G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G4.label}
              value={data[md.columns.R07G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G4.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G5.label}
              value={data[md.columns.R07G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G5.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G6.label}
              value={data[md.columns.R07G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G7.label}
              value={data[md.columns.R07G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G8.label}
              value={data[md.columns.R07G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G9.label}
              value={data[md.columns.R07G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G10.label}
              value={data[md.columns.R07G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R07G11.label}
              value={data[md.columns.R07G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R07G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="mx-1" variant="info">
              {data[md.columns.year.name]}
              {' (план на рік) '}
            </Badge>
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G2.label}
              value={data[md.columns.R08G2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G2.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G3.label}
              value={data[md.columns.R08G3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G3.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G4.label}
              value={data[md.columns.R08G4.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G4.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G5.label}
              value={data[md.columns.R08G5.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G5.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G6.label}
              value={data[md.columns.R08G6.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G6.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G7.label}
              value={data[md.columns.R08G7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G7.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G8.label}
              value={data[md.columns.R08G8.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G8.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G9.label}
              value={data[md.columns.R08G9.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G9.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G10.label}
              value={data[md.columns.R08G10.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G10.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
          <Col>
            <EditorControls.NumberInput
              label={md.columns.R08G11.label}
              value={data[md.columns.R08G11.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.R08G11.name]: value,
              })}
              readOnly={readOnly}
              precision={3}
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 3" eventKey="R09G3">
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 3}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G3.label}
                value={data[md.columns.R09G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G4.label}
                value={data[md.columns.R09G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G5.label}
                value={data[md.columns.R09G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G6.label}
                value={data[md.columns.R09G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G7.label}
                value={data[md.columns.R09G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G8.label}
                value={data[md.columns.R09G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G9.label}
                value={data[md.columns.R09G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G10.label}
                value={data[md.columns.R09G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R09G11.label}
                value={data[md.columns.R09G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R09G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl1TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G3.label}
                value={data[md.columns.R10G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G4.label}
                value={data[md.columns.R10G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G5.label}
                value={data[md.columns.R10G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G6.label}
                value={data[md.columns.R10G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G7.label}
                value={data[md.columns.R10G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G8.label}
                value={data[md.columns.R10G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G9.label}
                value={data[md.columns.R10G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G10.label}
                value={data[md.columns.R10G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R10G11.label}
                value={data[md.columns.R10G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R10G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl2TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G3.label}
                value={data[md.columns.R11G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G4.label}
                value={data[md.columns.R11G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G5.label}
                value={data[md.columns.R11G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G6.label}
                value={data[md.columns.R11G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G7.label}
                value={data[md.columns.R11G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G8.label}
                value={data[md.columns.R11G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G9.label}
                value={data[md.columns.R11G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G10.label}
                value={data[md.columns.R11G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R11G11.label}
                value={data[md.columns.R11G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R11G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 2}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G3.label}
                value={data[md.columns.R12G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G4.label}
                value={data[md.columns.R12G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G5.label}
                value={data[md.columns.R12G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G6.label}
                value={data[md.columns.R12G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G7.label}
                value={data[md.columns.R12G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G8.label}
                value={data[md.columns.R12G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G9.label}
                value={data[md.columns.R12G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G10.label}
                value={data[md.columns.R12G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R12G11.label}
                value={data[md.columns.R12G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R12G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl3TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G3.label}
                value={data[md.columns.R13G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G4.label}
                value={data[md.columns.R13G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G5.label}
                value={data[md.columns.R13G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G6.label}
                value={data[md.columns.R13G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G7.label}
                value={data[md.columns.R13G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G8.label}
                value={data[md.columns.R13G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G9.label}
                value={data[md.columns.R13G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G10.label}
                value={data[md.columns.R13G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R13G11.label}
                value={data[md.columns.R13G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R13G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl4TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G3.label}
                value={data[md.columns.R14G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G4.label}
                value={data[md.columns.R14G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G5.label}
                value={data[md.columns.R14G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G6.label}
                value={data[md.columns.R14G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G7.label}
                value={data[md.columns.R14G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G8.label}
                value={data[md.columns.R14G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G9.label}
                value={data[md.columns.R14G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G10.label}
                value={data[md.columns.R14G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R14G11.label}
                value={data[md.columns.R14G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R14G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 1}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G3.label}
                value={data[md.columns.R15G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G4.label}
                value={data[md.columns.R15G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G5.label}
                value={data[md.columns.R15G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G6.label}
                value={data[md.columns.R15G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G7.label}
                value={data[md.columns.R15G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G8.label}
                value={data[md.columns.R15G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G9.label}
                value={data[md.columns.R15G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G10.label}
                value={data[md.columns.R15G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R15G11.label}
                value={data[md.columns.R15G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R15G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl5TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G3.label}
                value={data[md.columns.R16G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G4.label}
                value={data[md.columns.R16G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G5.label}
                value={data[md.columns.R16G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G6.label}
                value={data[md.columns.R16G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G7.label}
                value={data[md.columns.R16G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G8.label}
                value={data[md.columns.R16G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G9.label}
                value={data[md.columns.R16G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G10.label}
                value={data[md.columns.R16G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R16G11.label}
                value={data[md.columns.R16G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R16G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl6TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G3.label}
                value={data[md.columns.R17G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G4.label}
                value={data[md.columns.R17G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G5.label}
                value={data[md.columns.R17G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G6.label}
                value={data[md.columns.R17G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G7.label}
                value={data[md.columns.R17G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G8.label}
                value={data[md.columns.R17G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G9.label}
                value={data[md.columns.R17G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G10.label}
                value={data[md.columns.R17G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R17G11.label}
                value={data[md.columns.R17G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R17G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name]}
          {' (план на рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G3.label}
                value={data[md.columns.R18G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G4.label}
                value={data[md.columns.R18G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G5.label}
                value={data[md.columns.R18G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G6.label}
                value={data[md.columns.R18G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G7.label}
                value={data[md.columns.R18G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G8.label}
                value={data[md.columns.R18G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G9.label}
                value={data[md.columns.R18G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G10.label}
                value={data[md.columns.R18G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R18G11.label}
                value={data[md.columns.R18G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R18G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl7TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G3.label}
                value={data[md.columns.R19G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G4.label}
                value={data[md.columns.R19G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G5.label}
                value={data[md.columns.R19G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G6.label}
                value={data[md.columns.R19G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G7.label}
                value={data[md.columns.R19G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G8.label}
                value={data[md.columns.R19G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G9.label}
                value={data[md.columns.R19G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G10.label}
                value={data[md.columns.R19G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R19G11.label}
                value={data[md.columns.R19G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R19G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl8TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G3.label}
                value={data[md.columns.R20G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G4.label}
                value={data[md.columns.R20G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G4.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G5.label}
                value={data[md.columns.R20G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G6.label}
                value={data[md.columns.R20G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G7.label}
                value={data[md.columns.R20G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G7.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G8.label}
                value={data[md.columns.R20G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G9.label}
                value={data[md.columns.R20G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G10.label}
                value={data[md.columns.R20G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G10.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R20G11.label}
                value={data[md.columns.R20G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R20G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title="Додаток 4" eventKey="R21G3">
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 3}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G3.label}
                value={data[md.columns.R21G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G4.label}
                value={data[md.columns.R21G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G4.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G5.label}
                value={data[md.columns.R21G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G6.label}
                value={data[md.columns.R21G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G7.label}
                value={data[md.columns.R21G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G7.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G8.label}
                value={data[md.columns.R21G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl91TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G3.label}
                value={data[md.columns.R22G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G4.label}
                value={data[md.columns.R22G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G4.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G5.label}
                value={data[md.columns.R22G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G6.label}
                value={data[md.columns.R22G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G7.label}
                value={data[md.columns.R22G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G7.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G8.label}
                value={data[md.columns.R22G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl101TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом :
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G3.label}
                value={data[md.columns.R23G3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G3.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G4.label}
                value={data[md.columns.R23G4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G4.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G5.label}
                value={data[md.columns.R23G5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G5.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G6.label}
                value={data[md.columns.R23G6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G6.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G7.label}
                value={data[md.columns.R23G7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G7.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G8.label}
                value={data[md.columns.R23G8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G8.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 2}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G9.label}
                value={data[md.columns.R21G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G10.label}
                value={data[md.columns.R21G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G10.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G11.label}
                value={data[md.columns.R21G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G12.label}
                value={data[md.columns.R21G12.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G12.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G13.label}
                value={data[md.columns.R21G13.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G13.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G14.label}
                value={data[md.columns.R21G14.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G14.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl92TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G9.label}
                value={data[md.columns.R22G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G10.label}
                value={data[md.columns.R22G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G10.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G11.label}
                value={data[md.columns.R22G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G12.label}
                value={data[md.columns.R22G12.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G12.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G13.label}
                value={data[md.columns.R22G13.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G13.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G14.label}
                value={data[md.columns.R22G14.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G14.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl102TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом :
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G9.label}
                value={data[md.columns.R23G9.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G9.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G10.label}
                value={data[md.columns.R23G10.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G10.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G11.label}
                value={data[md.columns.R23G11.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G11.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G12.label}
                value={data[md.columns.R23G12.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G12.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G13.label}
                value={data[md.columns.R23G13.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G13.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G14.label}
                value={data[md.columns.R23G14.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G14.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name] - 1}
          {' (факт за рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G15.label}
                value={data[md.columns.R21G15.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G15.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G16.label}
                value={data[md.columns.R21G16.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G16.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G17.label}
                value={data[md.columns.R21G17.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G17.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G18.label}
                value={data[md.columns.R21G18.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G18.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G19.label}
                value={data[md.columns.R21G19.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G19.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G20.label}
                value={data[md.columns.R21G20.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G20.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl93TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G15.label}
                value={data[md.columns.R22G15.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G15.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G16.label}
                value={data[md.columns.R22G16.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G16.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G17.label}
                value={data[md.columns.R22G17.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G17.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G18.label}
                value={data[md.columns.R22G18.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G18.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G19.label}
                value={data[md.columns.R22G19.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G19.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G20.label}
                value={data[md.columns.R22G20.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G20.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl103TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом :
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G15.label}
                value={data[md.columns.R23G15.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G15.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G16.label}
                value={data[md.columns.R23G16.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G16.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G17.label}
                value={data[md.columns.R23G17.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G17.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G18.label}
                value={data[md.columns.R23G18.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G18.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G19.label}
                value={data[md.columns.R23G19.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G19.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G20.label}
                value={data[md.columns.R23G20.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G20.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Alert variant="info" className="mb-0 w-100">
          {data[md.columns.year.name]}
          {' (план на рік) '}
        </Alert>
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41030000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G21.label}
                value={data[md.columns.R21G21.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G21.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G22.label}
                value={data[md.columns.R21G22.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G22.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G23.label}
                value={data[md.columns.R21G23.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G23.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G24.label}
                value={data[md.columns.R21G24.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G24.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G25.label}
                value={data[md.columns.R21G25.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G25.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R21G26.label}
                value={data[md.columns.R21G26.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R21G26.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl93TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                41050000 всього, у т.ч.:
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G21.label}
                value={data[md.columns.R22G21.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G21.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G22.label}
                value={data[md.columns.R22G22.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G22.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G23.label}
                value={data[md.columns.R22G23.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G23.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G24.label}
                value={data[md.columns.R22G24.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G24.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G25.label}
                value={data[md.columns.R22G25.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G25.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R22G26.label}
                value={data[md.columns.R22G26.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R22G26.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
        <Tabl103TP data={data} actions={actions} readOnly={readOnly} />
        <Container fluid>
          <Row>
            <Col>
              <Badge className="mx-1" variant="info">
                Разом :
              </Badge>
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G21.label}
                value={data[md.columns.R23G21.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G21.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G22.label}
                value={data[md.columns.R23G22.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G22.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G23.label}
                value={data[md.columns.R23G23.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G23.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G24.label}
                value={data[md.columns.R23G24.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G24.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G25.label}
                value={data[md.columns.R23G25.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G25.name]: value,
                })}
                readOnly
                precision={3}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.R23G26.label}
                value={data[md.columns.R23G26.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R23G26.name]: value,
                })}
                readOnly={readOnly}
                precision={3}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

LocalDebtTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default LocalDebtTabs;
