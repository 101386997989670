import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { SModal } from '../../../basicEditor/stdFilters/style';
import FilterEditor from './filterEditor';
import { foreignPropType } from '../../../../constants/backend/propTypes';

function LoadLevelDownButton({
  overrideFilter, docDate, disabled,
  onLoadHandler, useFinProposal, mode,
}) {
  const [opened, setOpened] = useState(false);
  const [clear, setClear] = useState(true);

  const defaultFilter = useMemo(() => {
    const d = docDate ? new Date(docDate) : new Date();
    d.setDate(1);
    return {
      begin_date: d.toISOString().substr(0, 10),
      end_date: docDate,
      approved: false,
    };
  }, [docDate]);

  const docName = useMemo(
    () => {
      if (mode === 'finrequest') return 'header';
      if (useFinProposal) return 'finproposal';
      return 'finrequest';
    },
    [mode, useFinProposal],
  );

  return (
    <>
      <Dropdown as={ButtonGroup} className="mt-2">
        <Dropdown.Toggle variant="falcon-primary" disabled={disabled}>
          Завантаження заявок на фінансування
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setClear(true);
              setOpened(true);
            }}
            className="text-primary"
          >
            <FontAwesomeIcon icon={faFile} className="me-2" />
            {`Заповнити ${useFinProposal ? 'пропозиціями' : 'заявками'} на фінансування`}
            <br />
            <span className="fs--2 text-muted">
              За звичай це те що Вам потрібно
            </span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setClear(false);
              setOpened(true);
            }}
            className="text-warning"
          >
            <FontAwesomeIcon icon={faFileCirclePlus} className="me-2" />
            {`Доповнити ${useFinProposal ? 'пропозиціями' : 'заявками'} на фінансування`}
            <br />
            <span className="fs--2 text-muted">
              Використовується у випадку коли треба &quot;довантажити&quot; заявки
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FilterEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          onOk={(ids) => {
            onLoadHandler(ids, clear);
            setOpened(false);
          }}
          docName={docName}
          mode={mode}
        />
      </SModal>
    </>
  );
}

LoadLevelDownButton.propTypes = {
  overrideFilter: foreignPropType,
  docDate: PropTypes.string,
  disabled: PropTypes.bool,
  onLoadHandler: PropTypes.func,
  useFinProposal: PropTypes.bool,
  mode: PropTypes.oneOf(['finrequest', 'finorder']).isRequired,
};

LoadLevelDownButton.defaultProps = {
  overrideFilter: null,
  docDate: '',
  disabled: false,
  onLoadHandler: null,
  useFinProposal: false,
};

export default LoadLevelDownButton;
