import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../modal';
import { CommandPanelButtonText } from '../Form/styledForm';

const CommandPanelButText = styled(CommandPanelButtonText)`
  border: 1px solid #9eb4cd;
  color: #4281c9;
  justify-content: center;
`;
const StyledCommandContainer = styled.div`
  display: flex;
  justify-content: space-around;
  // margin-top: 45px;
  & > * {
    min-width: 100px;
  }
`;

class Question extends PureComponent {
  static propTypes = {
    header: PropTypes.string,
    questionText: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })).isRequired,
    triggerButton: PropTypes.node.isRequired,
    onActionClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    header: '',
    questionText: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
    };
  }

  render() {
    const {
      header, questionText, answers, triggerButton, onActionClick,
    } = this.props;
    const { modalOpened } = this.state;
    return (
      <Modal
        triggerButton={
          React.cloneElement(triggerButton, { onClick: () => this.setState({ modalOpened: true }) })
        }
        header={header}
        opened={modalOpened}
        size="small"
        hideCloseIcon
      >
        <span>{questionText}</span>
        <StyledCommandContainer>
          {answers.map((answ) => (
            <CommandPanelButText
              key={answ.value}
              label={answ.label}
              text={answ.label}
              onClick={(e) => {
                this.setState({ modalOpened: false });
                onActionClick(e, answ.value);
              }}
            />
          ))}
        </StyledCommandContainer>
      </Modal>
    );
  }
}

export default Question;
