import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CountUp from 'react-countup';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import InfoCard from './InfoCard';
import GreetingCard from './greetings/GreetingCard';
import EcomStat from './EcomStat';
import CoreDetailKekv from './CoreKekv/CoreDetailKekv';
import AccountsTabInfo from './AccountsTabInfo';
import PlanFactAccounts from './PlanFactAccounts';
import social from '../../../assets/img/icons/social.svg';
import home from '../../../assets/img/icons/home-heating.svg';
import operation from '../../../assets/img/icons/bread.svg';
import health from '../../../assets/img/icons/health-care.svg';
import other from '../../../assets/img/icons/other.svg';
import Revenue412 from './Revenue412Tab';
import DynamicsRevenue from './DynamicsRevenue';

const infoDisposerItems = [
  {
    title: 'Потреба (заявки на фінансування) загального фонду',
    amount: '15 450',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'standard',
    percent: 21.8,
    className: 'border-200 border-bottom border-end pb-4',
  },
  {
    title: 'Зареєстровані фінансові зобов\'язання загального фонду',
    amount: '1,054',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'warning',
    percent: 21.8,
    className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3',
  },
  {
    title: 'Залишки на рахунках розпоряників загального фонду',
    amount: '145.65',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'up',
    percent: 21.8,
    className:
      'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3',
  },
  {
    title: 'Потреба (заявки на фінансування) спеціального фонду',
    amount: '234 100.26',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'down',
    percent: 21.8,
    className:
      'border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0',
  },
  {
    title: 'Зареєстровані фінансові зобов\'язання спеціального фонду',
    amount: '365 234.53',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'up',
    percent: 21.8,
    className: 'border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3',
  },
  {
    title: 'Залишки на рахунках розпоряників спеціального фонду',
    amount: '861',
    protectAmount: '13 675',
    otherAmount: '2 675',
    type: 'pending',
    percent: 21.8,
    className: 'pb-0 pt-4 ps-3',
  },
];

const dataNeed = [
  {
    id: 1,
    title: 'Оплата працi',
    target: '12 520 000 414.12',
    img: social,
    amount: 20,
  },
  {
    id: 2,
    title: 'Оплата комунальних послуг та енергоносіїв',
    target: '256 189 789 144.55',
    img: home,
    amount: 30,
  },
  {
    id: 3,
    title: 'Продукти харчування',
    target: '25 351 486.25',
    img: operation,
    amount: 15,
  },
  {
    id: 4,
    title: 'Медикаменти',
    target: '5 6234 929.00',
    img: health,
    amount: 25,
  },
  {
    id: 5,
    title: 'Інші видатки',
    target: '25 351 486.25',
    img: other,
    amount: 10,
  },
];

function ChiefDashboard() {
  const [detailZf, setDetailZf] = useState(true);
  const [detailSf, setDetailSf] = useState(false);

  const callbackInfoCard = (pref) => {
    if (pref === 'Zf') {
      setDetailZf(!detailZf);
    }
    if (pref === 'Sf') {
      setDetailSf(!detailSf);
    }
  };

  return (
    <Row className="g-3 mb-0">
      <Col xxl={12} xl={12}>
        <Row className="g-3" />
        <GreetingCard />
        <Row className="g-3 mt-0">
          <Col md={4} lg={5} xxl={4} className="d-flex justify-content-between flex-column">
            <InfoCard
              id="CountUpZf"
              corner={corner1}
              subHeaderTxt="Кошти на рахунках загального фонду"
              footerLinkName={detailZf ? 'Сховати деталізацію ...' : 'Детальнійше ...'}
              footerLink={detailZf ? '#CountUpZf' : '#CoreDetailKekvZf'}
              callback={() => callbackInfoCard('Zf')}
            >
              <div
                className={classNames(
                  'text-warning',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={4345765334}
                  duration={2.75}
                  suffix="грн."
                  prefix=""
                  separator=" "
                  decimals={2}
                  decimal="."
                />
              </div>
            </InfoCard>
            <InfoCard
              id="CountUpSf"
              corner={corner2}
              subHeaderTxt="Кошти на рахунках спец.фонду"
              callback={() => callbackInfoCard('Sf')}
              footerLinkName={detailSf ? 'Сховати деталізацію ...' : 'Детальнійше ...'}
              footerLink={detailSf ? '#CountUpSf' : '#CoreDetailKekvSf'}
            >
              <div
                className={classNames(
                  'text-info',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={432342234}
                  duration={2.75}
                  suffix="грн."
                  prefix=""
                  separator=" "
                  decimals={2}
                  decimal="."
                />
              </div>
            </InfoCard>
          </Col>

          <Col xxl={8} xl={12}>
            <EcomStat
              data={infoDisposerItems}
            />
          </Col>
        </Row>
        {detailZf && (
        <Row className="g-3 mt-1">
          <div id="CoreDetailKekvZf" />
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Потреба (заявки на фінансування) загального фонду"
              data={dataNeed}
            />
          </Col>
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Зареєстровані фінансові зобов\'язання загального фонду"
              data={dataNeed}
            />
          </Col>
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Залишки на рахунках розпоряників загального фонду"
              data={dataNeed}
            />
          </Col>

        </Row>
        )}
        {detailSf && (
        <Row className="g-3 mt-1">
          <div id="CoreDetailKekvSf" />
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Потреба (заявки на фінансування) спеціального фонду"
              data={dataNeed}
            />
          </Col>
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Зареєстровані фінансові зобов\'язання загального фонду"
              data={dataNeed}
            />
          </Col>
          <Col md={6} lg={5} xxl={4}>
            <CoreDetailKekv
              title="Залишки на рахунках розпоряників загального фонду"
              data={dataNeed}
            />
          </Col>
        </Row>
        )}
        <Row className="g-3 mt-1">
          <Col md={12} lg={8}>
            <AccountsTabInfo />
          </Col>
          <Col>
            <PlanFactAccounts />
          </Col>

        </Row>
        <Row className="g-3 mt-1">
          <Col>
            <Revenue412 />
          </Col>
        </Row>
        <Row className="g-3 mt-1">
          <Col>
            <div id="DynamicsRevenue" />
            <DynamicsRevenue />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

// ChiefDashboard.propTypes = {
//   notifications: PropTypes.shape([]),
// };
//
// ChiefDashboard.defaultProps = {
//   notifications: [],
// };

export default ChiefDashboard;
