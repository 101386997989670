import React, {
  memo, useCallback,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
  StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'tasksSpent';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('FILL_DATA_IN_ROW_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="tasks" highlighted={highlights.includes('tasks')}>
                  <ItemPicker
                    value={row[tableMD.columns.tasks.name]}
                    modelType="cat"
                    modelName="tasks"
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.tasks.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKFK.name]}
                    modelType="cat"
                    modelName="elementKFK"
                    onChange={onKFKChange}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <TableRowCell column="sumGenF" highlighted={highlights.includes('sumGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  {
                    [tableMD.columns.sumGenF.name]: v,
                    [tableMD.columns.sumTotal.name]: v
                        + (row[tableMD.columns.sumSpecF.name] || 0),
                  },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumSpecF" highlighted={highlights.includes('sumSpecF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumSpecF.name]}
                    precision={2}
                    onChange={(e, v) => onRowChange(
                      e,
                      {
                        [tableMD.columns.sumSpecF.name]: v,
                        [tableMD.columns.sumTotal.name]: v
                        + (row[tableMD.columns.sumGenF.name] || 0),
                      },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumBR" highlighted={highlights.includes('sumBR')}>
                  <NumberInput
                    value={row[tableMD.columns.sumBR.name]}
                    precision={2}
                    onChange={(e, v) => onRowChange(
                      e,
                      { [tableMD.columns.sumBR.name]: v },
                    )}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <TableRowCell column="sumTotal" highlighted={highlights.includes('sumTotal')}>
              <NumberInput
                value={row[tableMD.columns.sumTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumTotal.name]: v },
                )}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <StringInput
                value={row[tableMD.columns.note.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.note.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
