import React from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/netFOPreduce';
import TableEditor from '../../tableEditor';
import {
  ResultSegment,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';

const node = `rep/${md.name}/reportEditor`;
const selector = (state) => state.get(node, new Map());

const mountPath = [node, 'result'];

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const state = useSelector(selector);
  const headerForm = state.get('headerForm', new Map());

  const isProcessing = state.get('isProcessing', true);

  const SettingsButton = (
    <Tabs defaultActiveKey="filter">
      {/* <Tab title="Группування" eventKey="group"> */}
      {/*  <DCControls.Structure /> */}
      {/* </Tab> */}
      <Tab title="Відбір" eventKey="filter">
        <DCControls.Filter />
      </Tab>
    </Tabs>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <Row className="mt-2" md={3}>
        <Col>
          <EditorControls.PeriodInput
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], fromJS(v)))}
            value={headerForm.get('period', new Map()).toJS()}
            label={md.columns.period.label}
          />
        </Col>
      </Row>

      <ResultSegment>
        <TableEditor mountNode={mountPath} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
