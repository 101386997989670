import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PrintingEditor from '../../../dataProcessors/expensesOnHealthPrinting/editor';

const mapInitialOptions = {
  Document: 'ДокументСсылка',
};

function PrintModal({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );
  const onClose = useCallback(() => setOpened(false), []);
  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={selectedRows.length === 0} size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Друк
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друк Документу
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintingEditor
            onClose={onClose}
            mapInitialOptions={mapInitialOptions}
            mapStore={mapStore}
          />

        </Modal.Body>
      </Modal>
    </>
  );
}

PrintModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

PrintModal.defaultProps = {
  selectedRows: [],
};

export default PrintModal;
