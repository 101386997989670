import React, {
  forwardRef, memo, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import YearPickerInput from './yearPickerInput';

const YearPicker = forwardRef(({
  id,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  readOnly,
  size,
  errors,
  className,
  errorAsTooltip,
}, ref) => {
  const cDate = useMemo(
    () => {
      if (!value) return null;
      const v = `${value}-01-01`;
      if (!Date.parse(v)) return null;
      return new Date(v);
    },
    [value],
  );
  const onChangeYear = useCallback(
    (v, e) => {
      onChange(e, v.getFullYear());
    },
    [onChange],
  );
  return (
    <DatePicker
      id={id}
      ref={ref}
      selected={cDate}
      onChange={onChangeYear}
      placeholderText="Оберіть рік"
      dateFormat="yyyy"
      locale="uk"
      yearNumber={9}
      showYearPicker
      disabled={disabled || readOnly}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      customInput={(
        <YearPickerInput
          size={size}
          errors={errors}
          errorAsTooltip={errorAsTooltip}
        />
      )}
    />
  );
});

YearPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  errorAsTooltip: PropTypes.bool,
};

YearPicker.defaultProps = {
  id: '',
  value: 0,
  onChange: () => null,
  disabled: false,
  readOnly: false,
  size: null,
  errors: null,
  onBlur: null,
  onFocus: null,
  className: null,
  errorAsTooltip: false,
};

export default memo(YearPicker);
