import React, {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  CommandPanelColor,
  DivWithPropsLabel,
  StyledPane,
  TwoColGridContainer,
  ThreeColumnsGridContainer, FramePrintModal, TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import DateInput from '../../../components/fields/dates';
import Tabs from '../../../components/tabs/Tabs';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import DataTP from './tablepart/Data';
import ProgramsTP from './tablepart/Programs';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import { emptyUid } from '../../../constants/meta/common';
import { InputWithDropdown } from '../../../components/styledInputs';

const YearInput = Fields.GetNumberInput(4, 0, false);
const Note = Fields.TextAreaField;

const MainContainer = styled.div`
  position: relative;
`;

const StyledWhitePane = styled(StyledPane)`
  background: white;
`;
const FourColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 20% 20% 20% ;
  grid-column-gap: 2%;
`;

const MyTwoColumnsGridContainer = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 20%;
  grid-column-gap: 5%;
`;

const StyledResponse = styled.h2`
 color: red;
 `;

const md = meta.dp.indicatorsFactPrint;

const DataTPRenderer = getTPContainer(DataTP, getResizableCP(<TPCommandPanel tableName="tpDoc" />));
const ProgramsTPRenderer = getTPContainer(ProgramsTP, getResizableCP(<TPCommandPanel tableName="programs" />));

// eslint-disable-next-line react/prop-types
function IndicatorsFactPrintEditor({ onClose }) {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  return (
    <MainContainer>
      {!headerForm.getIn(['signVariant', 'repr'], '')
      && <StyledResponse color="red">Необхiдно заповнити поле &#39;Варіант підпису&#39;</StyledResponse> }
      <div>
        <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('signVariant', new Map())}
          modelType="cat"
          modelName="signatureVariants"
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'signVariant'], v))}
          // filter={[
          //   { fieldName: 'Владелец', value: headerForm.get('FI', new Map()) },
          // ]}
          // noHierarchy
        />
      </div>
      <div>
        <StyledLabel>{md.columns.effPr.label}</StyledLabel>
        <InputWithDropdown
          value={headerForm.get('effPr', new Map())}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'effPr'], v))}
          options={[
            { label: 'Друк звіту з виконання БП', name: 3 },
            { label: 'Результат аналізу ефективності бюджетної програми', name: 0 },
            { label: 'Узагальнені результати аналізу ефективності бюджетніх програм', name: 1 },
            { label: 'Оцінка ефективності бюджетної програми', name: 2 },
          ]}
        />
      </div>
      <ContainerButtonTop>
        <CommandPanelButton
          text="Сформувати звіт"
          onClick={() => {
            setShowSettings(false);
            dispatch(actions.changeField(['headerForm', 'result'], new Map()));
            dispatch(actions.processServerReq('PRINT'));
          }}
          loading={isProcessing}
          disabled={!headerForm.getIn(['signVariant', 'repr'], '')}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати звіт'}
        </CommandPanelButton>
        <CommandPanelButton
          text="Налаштування"
          onClick={() => setShowSettings((v) => !v)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && (
          <CommandPanelButton
            label="Передати в Logica"
            onClick={() => {
              dispatch(actions.processServerReq('CreateDispatchDoc'));
            }}
          />
        )}
      </ContainerButtonTop>
      {
        showSettings && (
          <FramePrintModal
            styled={{ showSettings } ? { transition: '3s' } : { transition: '3s' }}
            visible={showSettings}
            animation="overlay"
            width="very wide"
            direction="top"
            tertiary
            color="blue"
            basic
          >
            <TwoColGridContainer>
              <div>
                <StyledLabel>{md.columns.budget.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('budget', new Map())}
                  modelType="cat"
                  modelName="budget"
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'budget'], v))}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.FI.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('FI', new Map())}
                  modelType="cat"
                  modelName="csu"
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FI'], v))}
                />
              </div>
            </TwoColGridContainer>
            <FourColumnsGrid>
              <div>
                <StyledLabel>{md.columns.selDate.label}</StyledLabel>
                <DateInput
                  value={headerForm.get('selDate', null)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'selDate'], v))}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.dateOn.label}</StyledLabel>
                <DateInput
                  value={headerForm.get('dateOn', null)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dateOn'], v))}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.year.label}</StyledLabel>
                <YearInput
                  border
                  radius
                  background
                  value={headerForm.get('year', 5)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'year'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  style={{ alignSelf: 'end' }}
                  value={headerForm.get('detProg', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'detProg'], v))}
                  label={md.columns.detProg.label}
                />
                <Fields.CheckboxField
                  style={{ alignSelf: 'end' }}
                  value={headerForm.get('noCopecs', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noCopecs'], v))}
                  label={md.columns.noCopecs.label}
                />
                <Fields.CheckboxField
                  style={{ alignSelf: 'end' }}
                  value={headerForm.get('targetFromPass', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'targetFromPass'], v))}
                  label={md.columns.targetFromPass.label}
                />
              </div>
            </FourColumnsGrid>
            <ThreeColumnsGridContainer>
              <div>
                <StyledLabel>{md.columns.csu.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('csu', new Map())}
                  modelType="cat"
                  modelName="csu"
                  noHierarchy
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'csu'], v))}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.kbp.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('kbp', new Map())}
                  modelType="cat"
                  modelName="kbpClassifier"
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'kbp'], v))}
                />
              </div>
              { headerForm.get('docByKBP7', false) ? (
                <div>
                  <StyledLabel>{md.columns.KPKV.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('KPKV', new Map())}
                    modelType="cat"
                    modelName="kbp7Classifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KPKV'], v))}
                  />
                </div>
              ) : (
                <div>
                  <StyledLabel>{md.columns.elementKFK.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('elementKFK', new Map())}
                    modelType="cat"
                    modelName="elementKFK"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'elementKFK'], v))}
                  />
                </div>
              )}
            </ThreeColumnsGridContainer>
            <ThreeColumnsGridContainer>
              <Fields.CheckboxField
                style={{ alignSelf: 'end' }}
                value={headerForm.get('printFrom', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printFrom'], v))}
                label={md.columns.printFrom.label}
              />
              <Fields.CheckboxField
                style={{ alignSelf: 'end' }}
                value={headerForm.get('printAutor', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printAutor'], v))}
                label={md.columns.printAutor.label}
              />
              <Fields.CheckboxField
                style={{ alignSelf: 'end' }}
                value={headerForm.get('printN', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printN'], v))}
                label={md.columns.printN.label}
              />
            </ThreeColumnsGridContainer>
            <MyTwoColumnsGridContainer>
              <CommandPanelColor
                label="Заповнити підпрограми"
                onClick={() => dispatch(actions.processServerReq('FILL_SUBROUTINES'))}
              />
              <CommandPanelColor
                label="Заповнити документами"
                onClick={() => dispatch(actions.processServerReq('FILL_DOCUMENTS'))}
              />
            </MyTwoColumnsGridContainer>
            <Tabs>
              <DivWithPropsLabel label="Програми / Підпрограми">
                <StyledWhitePane>
                  <ProgramsTPRenderer />
                </StyledWhitePane>
              </DivWithPropsLabel>
              <DivWithPropsLabel label="Документи">
                <StyledWhitePane>
                  <DataTPRenderer />
                </StyledWhitePane>
              </DivWithPropsLabel>
              <DivWithPropsLabel label="Реквізити">
                <StyledWhitePane>
                  <div>
                    <StyledLabel>{md.columns.targ.label}</StyledLabel>
                    <Note
                      value={headerForm.getIn(['targ'], '')}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'targ'], v))}
                    />
                  </div>
                </StyledWhitePane>
              </DivWithPropsLabel>
            </Tabs>
          </FramePrintModal>
        )
      }
      <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
    </MainContainer>
  );
}
IndicatorsFactPrintEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

export default enchance(IndicatorsFactPrintEditor);
