import {
  directions, periodTypes,
} from '../common';

const measurements = {
  date: { label: 'Дата', name: 'Дата', visible: false },
  user: { label: 'Отримувач', name: 'Пользователь', visible: true },
  actualDate: { label: 'Актуально до', name: 'КонечнаяДата', visible: true },
};

const resources = {

  message: { label: 'Повідомлення', name: 'Сообщение', visible: true },
  author: { label: 'Автор', name: 'АвторСообщения', visible: true },
  readed: { label: 'Прочитано', name: 'Прочитано', visible: true },
  showOnDashboard: { label: 'Показувати при страрті', name: 'ОповещениеНаСервере', visible: true },
};

const props = {
};

const columns = {
  id: { label: 'id', name: '_id', visible: false },
  ...measurements,
  ...resources,
  ...props,
};

const defaultOrder = [
  {
    column: columns.date,
    direction: directions.ascending,
  },
];

export const messages = {
  label: 'Повідомлення',
  name: 'messages',
  columns,
  backendName: 'ОповещениеПользователей',
  defaultOrder,
  frontend: 'infoRegs/messages',
  listColumns: [
    ...new Set([
      'date',
      'user',
      'actualDate',
      'message',
      'author',
      'readed',
      'showOnDashboard',
    ]),
  ],
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default messages;
