import React, { memo } from 'react';

import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf0'} highlighted={highlights.includes('zf0')}>
            {totals.zf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf1'} highlighted={highlights.includes('zf1')}>
            {totals.zf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'zf2'} highlighted={highlights.includes('zf2')}>
            {totals.zf2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf0'} highlighted={highlights.includes('sf0')}>
            {totals.sf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf1'} highlighted={highlights.includes('sf1')}>
            {totals.sf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'sf2'} highlighted={highlights.includes('sf2')}>
            {totals.sf2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  totals: PropTypes.shape({
    sum0appr: PropTypes.number,
    zf0: PropTypes.number,
    zf1: PropTypes.number,
    zf2: PropTypes.number,

    sf2: PropTypes.number,
    sf0: PropTypes.number,
    sf1: PropTypes.number,
  }).isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
