import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  Col, Dropdown, DropdownButton, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/legalObligation';
import meta, { metaMaper } from '../../../../../../constants/meta';
import nums from "../../../../../../constants/meta/enums";

const tableMD = md.tables.general;

function TPRow({
  row, active, rowIndex,
  onChange,
  activeCol, readOnly, highlights,
  FI, onSR,
  budget,
  activeObjectsBuilding, getTypesRepair, elememtKDBVisible, documentKind,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const getRowMeta = useCallback(() => {
    if (row[tableMD.columns.elementKDB.name]) {
      return row[tableMD.columns.elementKDB.name].currentType ? row[tableMD.columns.elementKDB.name].currentType.modelName : 'ЭлементыКДБ';
    } return 'ЭлементыКДБ';
  }, [row]);

  const rowModelName = getRowMeta();
  const rowModelType = 'cat';

  const metaObj = meta.cat[metaMaper[rowModelType][rowModelName]];

  const [currentType, setCurrentType] = useState({
    modelName: metaObj.modelName,
    modelType: metaObj.modelType,
    repr: metaObj.label,
  });

  const getAvailableType = useCallback(() => {
    const types = [
      {
        id: 'ЭлементыКДБ',
        modelType: 'cat',
        repr: 'Деталізація КДБ',
      },
      {
        id: 'ЭлементыКФБ',
        modelType: 'cat',
        repr: 'Деталізація КФБ',
      },
    ];

    const rowTypes = row[tableMD.columns.elementKDB.name]
      ? row[tableMD.columns.elementKDB.name].availableType
      : row[tableMD.columns.elementKDB.name];

    if (rowTypes === undefined) return types;

    return rowTypes;
  }, [row]);

  const rowAvailableType = getAvailableType();

  const availableType = rowAvailableType.map((el) => (
    { value: el, display_name: el.repr }
  ));

  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const budgetOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  const onKEKVChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKEKV.name]: v });
      await onSR('CHANGE_KEKV_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onBuildObgecthange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.buildObgect.name]: v });
      await onSR('CHANGE_FOND', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onElementKDBchange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKDB.name]: v });
      await onSR('CHANGE_FOND', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onFullNotesChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.notes.name]: v });
  }, [onRowChange]);

  const onPrepaidChange = useCallback((e, v) => {
    const sum = row[tableMD.columns.sum.name];

    onRowChange(e, { [tableMD.columns.prepaid.name]: (sum < v && documentKind !== nums.ObligationTypes.storno.name) ? sum : v });
  }, [documentKind, onRowChange, row]);

  const onSumChange = useCallback((e, v) => {
    const prepaid = row[tableMD.columns.prepaid.name];

    if (v < prepaid && documentKind !== nums.ObligationTypes.storno.name) onPrepaidChange(e, v);

    onRowChange(e, { [tableMD.columns.sum.name]: v });
  }, [documentKind, onPrepaidChange, onRowChange, row]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col lg={6}>
            <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKEKV.name]}
                modelType="cat"
                modelName="elementKEKV"
                onChange={onKEKVChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KEKVCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            {activeObjectsBuilding && (
              <TableRowCell column="activeObjectsBuilding" highlighted={highlights.includes('activeObjectsBuilding')}>
                <>
                  <ItemPicker
                    value={row[tableMD.columns.buildObgect.name]}
                    modelType="cat"
                    modelName="objectsBuilding"
                    onChange={onBuildObgecthange}
                    filter={budgetOwnerFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {getTypesRepair(row)}
                      </InputGroup.Text>

                    )}
                  />

                </>
              </TableRowCell>
            )}
            {elememtKDBVisible && (
              <TableRowCell column="elememtKDBVisible" highlighted={highlights.includes('elememtKDBVisible')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKDB.name]}
                  modelType={currentType.modelType}
                  modelName={currentType.modelName}
                  onChange={onElementKDBchange}
                  filter={FIOwnerFilter}
                  readOnly={readOnly}
                  prepend={
                    (
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={currentType.repr}
                        drop="right"
                      >
                        {availableType.map((el) => (
                          <Dropdown.Item
                            onClick={() => {
                              setCurrentType(
                                {
                                  modelName: metaMaper[el.value.modelType][el.value.id],
                                  modelType: el.value.modelType,
                                  repr: el.display_name,
                                },
                              );
                            }}
                          >
                            {el.display_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    )
                  }
                />
              </TableRowCell>
            )}
          </Col>
          <Col>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                onChange={onSumChange}
                precision={2}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prepaid" highlighted={highlights.includes('prepaid')}>
              <NumberInput
                value={row[tableMD.columns.prepaid.name]}
                onChange={onPrepaidChange}
                precision={2}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={4}>
            <TableRowCell column="notes" highlighted={highlights.includes('notes')}>
              <TextInput
                value={row[tableMD.columns.notes.name]}
                onChange={onFullNotesChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>

      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    availableType: PropTypes.shape([]),
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  budget: PropTypes.shape({
  }).isRequired,
  FI: PropTypes.shape({
  }).isRequired,
  onSR: PropTypes.func.isRequired,
  activeObjectsBuilding: PropTypes.bool,
  getTypesRepair: PropTypes.string,
  elememtKDBVisible: PropTypes.bool,
    documentKind: PropTypes.string,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  activeObjectsBuilding: false,
  getTypesRepair: '',
  elememtKDBVisible: false,
  documentKind: '',
};

export default memo(TPRow);
