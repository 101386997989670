import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import SelectionList from './selectionList';
import { availableSelectionsPropType, getPreviousSelection, selectionsPropType } from '../../hooks';

function SelectionEditor({ availableSelections, selections, selectionHandlers }) {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeSelection, setActiveSelection] = useState(null);

  const [openedSelections, setOpenedSelections] = useState([]);
  const visibleSelections = useMemo(() => availableSelections.reduce((R, col) => [
    ...R,
    col.parent === null && {
      ...col,
      lvl: 0,
      hasChild: !!openedSelections.filter((col2) => col2.parent === col.name).length,
    },
    ...openedSelections.filter((col2) => col2.parent === col.name && openedSelections
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1, hasChild: false })),
  ], []).filter((col) => col), [availableSelections, openedSelections]);

  const onActiveSelectionHandler = useCallback(
    (sname) => setActiveSelection(sname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedSelections = useCallback((val) => {
    if (openedSelections.includes(val)) {
      return setOpenedSelections((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedSelections((prev) => ([...prev, val]));
  }, [openedSelections]);

  const selectionDragEndHanlder = useCallback(
    ({ name, type }, sname) => {
      if (type === itemTypes.availableField) {
        selectionHandlers.addSelectionHandler(name, sname);
      } else if (type === itemTypes.selection) {
        selectionHandlers.swapSelectionsHandler(name, sname);
      }
    },
    [selectionHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.selection) {
        selectionHandlers.removeSelectionHandler(name);
        if (activeSelection === name) setActiveSelection(null);
      }
    },
    [activeSelection, selectionHandlers],
  );

  return (
    <div className="d-flex h-100 overflow-hidden">
      <div className="overflow-hidden flex-fill w-50">
        <AvailableFieldList
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={selectionHandlers.addSelectionHandler}
          cardTitle="Доступні поля"
          cardText={(
            <p className="text-justify mb-0">
              містить усі поля з джерела даних, які можна використовувати для звіту. Ці поля
              можна обирати, щоб структурувати, підсумовувати та аналізувати ваші дані.
              Перетягніть нижче наведені поля на область
              <b className="mx-1">&quot;Обрані поля&quot;</b>
              .
              Використання &quot;Доступних полів&quot; дозволяє швидко знаходити
              та додавати потрібні поля до вашого звіту, економлячи час та зусилля.
            </p>
          )}
          onDragEnd={avaDragEndHanlder}
          setOpenedItems={onSetOpenedSelections}
          visibleItems={visibleSelections}
          openedItems={openedSelections}
        />
      </div>
      <div className="overflow-hidden d-flex align-items-center px-2 ">
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => selectionHandlers.clearAllSelections()}
            disabled={!selections.length}
            title="Перемістити всі вліво"
          />
          <LeftButton
            onClick={() => {
              selectionHandlers.removeSelectionHandler(activeSelection);
              setActiveSelection(getPreviousSelection(selections, activeSelection));
            }}
            disabled={activeSelection === null}
            title="Перемістити вліво"
          />
          <RightButton
            onClick={() => selectionHandlers.addSelectionHandler(activeAvaField)}
            disabled={!activeAvaField}
            title="Перемістити вправо"
          />
        </ButtonGroup>
      </div>
      <div className="overflow-hidden flex-fill w-50">
        <SelectionList
          selections={selections}
          activeSelection={activeSelection}
          cardTitle="Обрані поля"
          cardText={(
            <p className="text-justify mb-0">
              дозволяють створювати звіти з різною структурою та підсумками, щоб відповідати
              вашим потребам в аналізі. Перетягування полів з
              <b className="mx-1">&quot;Доступних полів&quot;</b>
              до
              <b className="mx-1">&quot;Обрані поля&quot;</b>
              є простим та інтуїтивно зрозумілим способом налаштування вашого аналізу.
            </p>
          )}
          onClick={onActiveSelectionHandler}
          onDblClick={selectionHandlers.removeSelectionHandler}
          onDragEnd={selectionDragEndHanlder}
        />
      </div>
    </div>
  );
}

SelectionEditor.propTypes = {
  selections: selectionsPropType.isRequired,
  availableSelections: availableSelectionsPropType.isRequired,
  selectionHandlers: PropTypes.shape({
    clearAllSelections: PropTypes.func,
    removeSelectionHandler: PropTypes.func,
    addSelectionHandler: PropTypes.func,
    swapSelectionsHandler: PropTypes.func,
    changeFunctionHandler: PropTypes.func,
  }).isRequired,
};

export default SelectionEditor;
