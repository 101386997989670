import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  onState: { label: 'Звітня дата', name: 'ДатаСреза', visible: false },
  csu: { label: 'Розпорядник бюджетних коштів', name: 'Распорядитель' },
  kbp: { label: 'КБП', name: 'КБП' },
  kekv: { label: 'КЕКВ', name: 'КЕКВ' },
  reportForm: { label: 'Форма звіту', name: 'ФормаОтчета' },
  text1: { label: 'Рядок 1', name: 'Текст1' },
  text2: { label: 'Рядок 2', name: 'Текст2' },
  text3: { label: 'Рядок 3', name: 'Текст3' },
  period: { label: 'Перiод', name: 'Период' },
};

const definition = {
  label: 'Звіт за енергоносіями та ком. послугам',
  name: 'EnergyReport',
  columns,
  frontend: 'rep/EnergyReport',
  backendName: 'ОтчетПоЭнергоносителям',
  resultColumns: baseResultColumns,
};

export default definition;
