import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.workersAmount;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col sm={2} className="overflow-hidden">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'categories'}
          highlighted={highlights.includes('categories')}
          onToggleHighlght={() => onToggleHighlght('categories')}
        >
          {meta.columns.categories.label}
        </TableHeaderCell>
        <Badge variant="primary">{meta.columns.group.label}</Badge>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf2P'}
              highlighted={highlights.includes('zf2P')}
              onToggleHighlght={() => onToggleHighlght('zf2P')}
            >
              {meta.columns.zf2P.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf2P'}
              highlighted={highlights.includes('sf2P')}
              onToggleHighlght={() => onToggleHighlght('sf2P')}
            >
              {meta.columns.sf2P.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF2P'}
              highlighted={highlights.includes('zfsF2P')}
              onToggleHighlght={() => onToggleHighlght('zfsF2P')}
            >
              {meta.columns.zfsF2P.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf2F'}
              highlighted={highlights.includes('zf2F')}
              onToggleHighlght={() => onToggleHighlght('zf2F')}
            >
              {meta.columns.zf2F.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf2F'}
              highlighted={highlights.includes('sf2F')}
              onToggleHighlght={() => onToggleHighlght('sf2F')}
            >
              {meta.columns.sf2F.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF2F'}
              highlighted={highlights.includes('zfsF2F')}
              onToggleHighlght={() => onToggleHighlght('zfsF2F')}
            >
              {meta.columns.zfsF2F.label}
              {year - 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf1P'}
              highlighted={highlights.includes('zf1P')}
              onToggleHighlght={() => onToggleHighlght('zf1P')}
            >
              {meta.columns.zf1P.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf1P'}
              highlighted={highlights.includes('sf1P')}
              onToggleHighlght={() => onToggleHighlght('sf1P')}
            >
              {meta.columns.sf1P.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF1P'}
              highlighted={highlights.includes('zfsF1P')}
              onToggleHighlght={() => onToggleHighlght('zfsF1P')}
            >
              {meta.columns.zfsF1P.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf1F'}
              highlighted={highlights.includes('zf1F')}
              onToggleHighlght={() => onToggleHighlght('zf1F')}
            >
              {meta.columns.zf1F.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf1F'}
              highlighted={highlights.includes('sf1F')}
              onToggleHighlght={() => onToggleHighlght('sf1F')}
            >
              {meta.columns.sf1F.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF1F'}
              highlighted={highlights.includes('zfsF1F')}
              onToggleHighlght={() => onToggleHighlght('zfsF1F')}
            >
              {meta.columns.zfsF1F.label}
              {year - 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {meta.columns.zf0.label}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {meta.columns.sf0.label}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF0'}
              highlighted={highlights.includes('zfsF0')}
              onToggleHighlght={() => onToggleHighlght('zfsF0')}
            >
              {meta.columns.zfsF0.label}
              {year}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {meta.columns.zf1.label}
              {year + 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {meta.columns.sf1.label}
              {year + 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF1'}
              highlighted={highlights.includes('zfsF1')}
              onToggleHighlght={() => onToggleHighlght('zfsF1')}
            >
              {meta.columns.zfsF1.label}
              {year + 1}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-primary"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {meta.columns.zf2.label}
              {year + 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {meta.columns.sf2.label}
              {year + 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'zfsF2'}
              highlighted={highlights.includes('zfsF2')}
              onToggleHighlght={() => onToggleHighlght('zfsF2')}
            >
              {meta.columns.zfsF2.label}
              {year + 2}
              {' '}
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
