import React from 'react';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';
import { modelType, modelName } from '../def';
import ListerSelector from './listerSelector';

const FRLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

function Lister() {
  return (
    <ListerSelector>
      <FRLister />
    </ListerSelector>
  );
}

export default Lister;
