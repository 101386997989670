import meta
// , { metaBackends }
  from '../../../meta';
// eslint-disable-next-line import/no-cycle
import catListersSelectors from '../individual/cat';
import docListersSelectors from '../individual/doc';
import ListerFactory from '../../newLister';
// eslint-disable-next-line import/no-cycle
import { CatTableListerFactory, DocTableListerFactory } from '../../newLister/tableComponent';
import { CatListerCommandPanel } from '../../newLister/tableComponent/commandpanel';

/*

В случае добавления особенного селектора
необходимо его вклбючить сюда

 */
const catSelectors = Object.keys(catListersSelectors)
  .filter((c) => !!catListersSelectors[c].selector)
  .reduce((R, c) => ({ ...R, [c]: catListersSelectors[c].selector }), {});

const docSelectors = Object.keys(docListersSelectors)
  .filter((d) => !!docListersSelectors[d].selector)
  .reduce((R, d) => ({ ...R, [d]: docListersSelectors[d].selector }), {});

const stdSelectors = {
  cat: Object.keys(meta.cat)
    .filter((mName) => !Object.keys(catSelectors).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.cat[mName].backendURL,
        viewType: 'selector',
      })(CatTableListerFactory({
        CommandPanel: CatListerCommandPanel,
      })),
    }), catSelectors),
  doc: Object.keys(meta.doc)
    .filter((mName) => !Object.keys(docSelectors).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.doc[mName].backendURL,
        viewType: 'selector',
      })(DocTableListerFactory({
        CommandPanel: CatListerCommandPanel,
      })),
    }), docSelectors),
};

// export const SelectorsByBackend = Object.keys(metaBackends).reduce((R, r) => ({
//   ...R,
//   [r]: stdSelectors[metaBackends[r].modelType][metaBackends[r].modelName],
// }), {});
//

export default stdSelectors;
