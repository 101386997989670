import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function TargetRowSet({ data, ...rest }) {
  const budgetFilter = useMemo(
    () => ({
      budget: data.budget ? data.budget.id : 0,
    }),
    [data.budget],
  );
  const template = useMemo(
    () => {
      const amountColumns = [
        {
          field: 'amount_2',
          headerLabel: `${data.year - 2}р. (звіт))`,
        },
        {
          field: 'amount_1',
          headerLabel: `${data.year - 1}р. (затверджено))`,
        },
        {
          field: 'amount0',
          headerLabel: `${data.year}р. (план))`,
        },
        {
          field: 'amount1',
          headerLabel: `${data.year + 1}р. (план))`,
        },
        {
          field: 'amount2',
          headerLabel: `${data.year + 2}р. (план))`,
        },
      ];
      const cols = {
        columns: data.use_pcm_dictionary
          ? [
            { field: 'target', colProps: { sm: 12 }, controlProps: { filter: budgetFilter } },
            { field: 'direction', colProps: { sm: 6 }, controlProps: { canErase: false, filter: budgetFilter } },
            {
              field: 'indicator',
              colProps: { sm: 6 },
              controlProps: (row) => ({
                canErase: false,
                filter: { owner: row.direction ? row.direction.id : null },
              }),
            },
          ]
          : [
            { field: 'target', colProps: { sm: 12 }, controlProps: { filter: budgetFilter } },
            { field: 'goal_indicator', colProps: { sm: 12 }, controlProps: { canErase: false } }],
        colProps: { md: 4 },
      };
      return { columns: [cols, ...amountColumns] };
    },
    [budgetFilter, data.use_pcm_dictionary, data.year],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="forecastprepareadd2targets_set"
    />
  );
}

TargetRowSet.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    use_pcm_dictionary: PropTypes.bool,
    budget: foreignPropType,
  }).isRequired,
};

export default TargetRowSet;
