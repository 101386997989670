const frontendURL = '/cat/bfnPeriod/';
const backendURL = '/api/bfn/refperiodbfn/';
const name = 'Період обрахунку БФН';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
