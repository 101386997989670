import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { CommandPanelColor } from '../../../components/Form/styledForm';
import RequestsLoadingEditor from '../../dataProcessors/requestsLoading/editor';
import Modal from '../../../components/modal';
import storePathParam from '../../../common/storePathParam';

class LoadRequestsModal extends PureComponent {
  static propTypes = {
    onOk: PropTypes.func,
    // disabled: PropTypes.bool,
  };

  static defaultProps = {
    onOk: () => null,
    // disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = { modalOpened: false };
  }

  okHandler = (selector) => {
    this.setState({ modalOpened: false });
    this.props.onOk(selector);
  };

  render() {
    const {
      modalOpened,
    } = this.state;
    return (
      <Modal
        triggerButton={(
          <CommandPanelColor
            onClick={() => this.setState({ modalOpened: true })}
            // disabled={this.props.disabled}
            label="Заповнити за підлеглими документами"
          />
        )}
        header="Оберіть документи для завантаження"
        closeIcon
        opened={modalOpened}
        onClose={() => this.setState({ modalOpened: false })}
      >
        <RequestsLoadingEditor
          mapInitialOptions={{
            date: 'ДатаОтчета',
            fondObject: 'ЭлементФонд',
            FI: 'ФинансовыйОрган',
            dataSource: 'ИсточникДанных',
            budget: 'Бюджет',
            importAllNotes: 'ИмпортВсехПримечаний',
            importNotes: 'ИмпортПримечанийВЗаявку',
            CSU: 'Распорядитель',
          }}
          mapStore={(node) => new Map()
            .set('date', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'date']))
            .set('fondObject', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'FondObject']))
            .set('FI', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'FI']))
            .set('dataSource', 'Документ.ФинансированиеЗаявка')
            .set('budget', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'Budget']))
            .set('importAllNotes', false)
            .set('importNotes', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'DontOmitNotesRequerst']) && node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'ImportReqNotes']))
            .set('CSU', node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'IncludeCSUIntoHead']) ? node.getIn([storePathParam(node).node, storePathParam(node).id, 'headerForm', 'CSU']) : null)}
          onOK={this.okHandler}
        />
      </Modal>

    );
  }
}

export default LoadRequestsModal;
