import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function TablePartGrid({ gridTemplateParam, children }) {
  const TPG = useMemo(
    () => styled.div.attrs({
      style: { overflow: 'inherit' },
    })`
  & .grid-template {
      display: grid; 
      grid-auto-columns: ${gridTemplateParam.gridAutoColumns};
      grid-auto-rows: auto;
      top: 0;
      bottom: 0;
      grid-template-areas:  ${gridTemplateParam.gridTemplateAreas}; 
      grid-template-columns: ${gridTemplateParam.gridTemplateColumns};
      &:first-child{
        top: 54px;
        z-index: 1;
      }
      &:last-child{
        z-index: 0;
      }
  }
  & .grid-template.header{
    position: sticky;
    z-index: 3;
  }
  & .grid-template.active{
    border: 1px solid #AAC6E6;
    background: linear-gradient(0deg, #D9EEDE, #D9EEDE), #008F21;
  }
      `,
    [gridTemplateParam.gridTemplateAreas, gridTemplateParam.gridTemplateColumns, gridTemplateParam.gridAutoColumns],
  );
  return (
    <TPG>
      {children}
    </TPG>
  );
}

TablePartGrid.propTypes = {
  gridTemplateParam: PropTypes.shape({
    gridTemplateAreas: PropTypes.string,
    gridTemplateColumns: PropTypes.string,
    gridAutoColumns: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default memo(TablePartGrid);
