import { useMemo } from 'react';
import { metaBackends } from '../../../meta';

/**
 * Получает метаинформацию о меоди
 * @param backendURL {string}
 * @returns {{
 *    frontendURL: string,
 *    backendURL: string,
 *    name: string,
 *    useHierarchyPagination: boolean,
 * }}
 */

// eslint-disable-next-line import/prefer-default-export
export const useMD = (backendURL) => useMemo(
  () => {
    if (!Object.keys(metaBackends).includes(backendURL)) {
      throw new Error(`backendURL arg must be in ${Object.keys(metaBackends).join(',')}, but not ${backendURL}`);
    }
    return metaBackends[backendURL];
  },
  [backendURL],
);
