import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import CancelIcon from '../../assets/icons/cancel';

function CancelButton({ text, onClick, ...args }) {
  return (
    <Button onClick={onClick} {...args} title={text}>
      <CancelIcon />
    </Button>
  );
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

CancelButton.defaultProps = {
  text: 'Скасувати',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default CancelButton;
