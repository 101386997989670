const frontendURL = '/srv/treasureExport/';
const backendURL = '/api/treasuries/export/';
const name = 'Завантаження казначейських файлів';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
