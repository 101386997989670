import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Номер будинку', name: 'Наименование', visible: true },
  owner: { label: 'Вулиця', name: 'Владелец', visible: true },
  district: { label: 'Адміністративний район', name: 'Район', visible: true },
  toponim: { label: 'Топонім', name: 'Топоним', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  num: { label: 'Номер', name: 'Номер', visible: false },
  sort: { label: 'Сортувати , як...', name: 'Сорт', visible: false },
  idPS: { label: 'Ід. для виватнаження до ГІС', name: 'ИдГИС', visible: false },
};

export const houses = {
  label: 'Будинки',
  name: 'houses',
  backendName: 'Дома',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/houses',
  listColumns: [...(new Set(['budget', 'district', 'toponim', 'owner', ...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default houses;
