import React from 'react';
import { Badge, ListGroup, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../../constants/backend/propTypes';

function SelectAccModal({
  show, onHide, accounts, onSelect,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Оберіть рахунок</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {accounts.map((acc) => (
            <ListGroup.Item key={acc.id} action onClick={(e) => onSelect(e, acc)}>
              <Badge bg="dark">{acc.number}</Badge>
              {acc.repr}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

SelectAccModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(foreignPropType).isRequired,
  onSelect: PropTypes.func.isRequired,
};

SelectAccModal.defaultProps = {
  show: false,
};

export default SelectAccModal;
