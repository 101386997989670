const frontendURL = '/cat/bfnGroupTypes/';
const backendURL = '/api/bfn/refgrouptypes/';
const name = 'Тип груп закладу дошкільної освіти';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
