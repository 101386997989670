import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/revenuePlan';
import {
  ResultSegment,
  StyledLabel,
  TwoColGridContainer,
  ContainerDiv,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import ReportContainer from '../reportContainer/variantReportContainer';
import { InputWithDropdown } from '../../../components/styledInputs';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import DateInput from '../../../components/fields/dates';

const TwoColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 40% 60% ;
`;

const ThreeColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 33% 33% 33%;
`;

const FourColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 25% 25% 25% 25%;
`;

const FiveColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 20% 20% 20% 20% 20%;
`;

const StringInput200 = Fields.GetTextInput(200);

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

const PlaningKind = {
  permanent: { name: 0, label: 'Постійний' },
  temporary: { name: 1, label: 'Тимчасовий' },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FOLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'FO'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <TwoColContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FOLabel}
        </div>
      </TwoColContainer>
      <ThreeColContainer>
        <div>
          <StyledLabel>{md.columns.repType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('repType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'repType'], v))}
            values={Object.values(ReportTypes)}
            name="repType"
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('flBudgSepar', false)}
            label={md.columns.flBudgSepar.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'flBudgSepar'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('sessionDes', false)}
            label={md.columns.sessionDes.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'sessionDes'], v))}
          />
        </div>
      </ThreeColContainer>
      <FourColContainer>
        <div>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <Fields.YearField
            value={headerForm.get('year', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
            disableCalculator
          />
        </div>
        <div>
          <StyledLabel>{md.columns.dateIn.label}</StyledLabel>
          <DateInput
            value={headerForm.get('dateIn', null)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'dateIn'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.dateOut.label}</StyledLabel>
          <DateInput
            value={headerForm.get('dateOut', null)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'dateOut'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('planType', false)}
            options={Object.values(PlaningKind)}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'planType'], v));
            }}
          />
        </div>
      </FourColContainer>
      <ThreeColContainer>
        <div>
          <StyledLabel>{md.columns.fond.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('fond', new Map())}
            modelType="cat"
            modelName="fondClassifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'fond'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.elFond.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('elFond', new Map())}
            modelType="cat"
            modelName="elementFond"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'elFond'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.KDB.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('KDB', new Map())}
            modelType="cat"
            modelName="kdbClassifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'KDB'], v))}
          />
        </div>
      </ThreeColContainer>
      <FiveColContainer>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('consolid', false)}
            label={md.columns.consolid.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'consolid'], v))}
          />
        </div>
        {/* <div> */}
        {/*  <Fields.CheckboxField */}
        {/*    value={headerForm.get('basket', false)} */}
        {/*    label={md.columns.basket.label} */}
        {/*    onChange={(e, v) => dispatch(changeField(['headerForm', 'basket'], v))} */}
        {/*  /> */}
        {/* </div> */}
        <div>
          <Fields.CheckboxField
            value={headerForm.get('noAdd', false)}
            label={md.columns.noAdd.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'noAdd'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('in1000', false)}
            label={md.columns.in1000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('repeatH', false)}
            label={md.columns.repeatH.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'repeatH'], v))}
          />
        </div>
      </FiveColContainer>
      <FiveColContainer>
        <div>
          <StyledLabel style={{ marginBottom: '6px' }}>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('upCount', false)}
            label={md.columns.upCount.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'upCount'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('inBR', false)}
            label={md.columns.inBR.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'inBR'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('yearForm', false)}
            label={md.columns.yearForm.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'yearForm'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('flDetail', false)}
            label={md.columns.flDetail.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'flDetail'], v))}
          />
        </div>
      </FiveColContainer>
      <div>
        <StyledLabel>{md.columns.add1.label}</StyledLabel>
        <StringInput200
          radius
          background
          value={headerForm.get('add1', '')}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'add1'], v))}
        />
      </div>
      <div>
        <StyledLabel>{md.columns.textH.label}</StyledLabel>
        <StringInput200
          radius
          background
          value={headerForm.get('textH', '')}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'textH'], v))}
        />
      </div>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div style={{ display: 'flex', 'align-items': 'center' }} />
      </ContainerDiv>
      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
