import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  activity: foreignPropType,
  targetprogram: foreignPropType,
  fond: foreignPropType,
  kdb: foreignPropType,
  kfb: foreignPropType,
  amount_total: PropTypes.number,
  amount_total_sf: PropTypes.number,
  amount_total_zf: PropTypes.number,
  amount_fact_total: PropTypes.number,
  amount_fact_sf: PropTypes.number,
  amount_fact_zf: PropTypes.number,
  amount_plan_total: PropTypes.number,
  amount_plan_sf: PropTypes.number,
  amount_plan_zf: PropTypes.number,
  amount_executed_total: PropTypes.number,
  amount_executed_sf: PropTypes.number,
  amount_executed_zf: PropTypes.number,
  amount_forecast_total: PropTypes.number,
  amount_forecast_sf: PropTypes.number,
  amount_forecast_zf: PropTypes.number,
  explanation: PropTypes.string,
  explanation_description: PropTypes.string,
  cash_cost_diff: PropTypes.string,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
