import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },
  byAssignSource: {
    label: 'за даними розпису джерел фінансування',
    name: 'флПоДаннымРосписиИсточников',
  },
};

const definition = {
  label: 'Аналіз фінансування видатків що передані із заг. фонду до спец.',
  name: 'FinSourceAnalysis',
  columns,
  frontend: 'rep/FinSourceAnalysis',
  backendName: 'анализфинансированияисточники',
  resultColumns: baseResultColumns,
};

export default definition;
