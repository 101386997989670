import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { docEditorMenu as BasicMenu } from '../../../components/editorMenu/index';
import BasicEditor, { mapState, getRouterEditor } from '../../editor/basic';

import SelectorField from '../../field/stdSelectorField';
import StyledTPPane from '../../../components/tableParts/TPPane';
import { editor as editorActions, editor as actions } from '../../../actions/index';
import md from '../../../constants/meta/documents/financingRepairObjects';
import Tabs from '../../../components/tabs/Tabs';
import {
  ContainerFourColumn,
  ContainerThreeColumn,
  StyledGreyLabel,
  StyledLabel,
  SubText,
  Title,
  DivWithPropsLabel,
  HeaderColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { SumInputStyled } from '../../../components/styledTable/styledTable';
import { changeField } from '../../../actions/editor';
import Fields from '../../field';
import DateInput from '../../../components/fields/dates';
import GetTextField from '../../field/TextInput';
import storePathParam from '../../../common/storePathParam';

const NumberInput = GetTextField();

export class FinancingRepairObjects extends PureComponent {
  render() {
    const {
      props: { dispatch, headerForm, sessionOptions },
    } = this;

    const headerUpdater = (...path) => (e, value) => {
      dispatch(actions.changeField(['headerForm', ...path], value));
    };
    return (
      <div>
        <HeaderColumnsGridContainer>
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.budget.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('budget', new Map())}
                modelType="cat"
                modelName="budget"
                disabled
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], new Map()));
                  dispatch(actions.changeField(['headerForm', 'budget'], value));
                }}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.budget.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['budget', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.FI.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                filter={[{ fieldName: 'Бюджет', value: headerForm.get('budget') }]}
                onChange={headerUpdater('FI')}
                disabled
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.FI.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['FI', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.author.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('author', new Map())}
                modelType="cat"
                modelName="users"
                disabled
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.author.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['author', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
        </HeaderColumnsGridContainer>
        <ContainerFourColumn>
          <div>
            <StyledLabel>{md.columns.number.label}</StyledLabel>
            <NumberInput
              disabled
              background
              border
              radius
              value={headerForm.get('number', '')}
              readOnly
            />
          </div>
          <div>
            <StyledLabel>{md.columns.date.label}</StyledLabel>
            <DateInput value={headerForm.get('date', null)} onChange={headerUpdater('date')} />
          </div>
          <div>
            <StyledLabel>{md.columns.constructionObject.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('constructionObject', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'constructionObject'], value));
              }}
              modelType="cat"
              modelName="objectsBuilding"
            // filter={[{ fieldName: 'Владелец', value: headerForm.get('FI', new Map()) }]}
            />
          </div>

          {/* <div> */}
          {/*  <StyledLabel>{md.columns.objBuildingSourceFin.label}</StyledLabel> */}
          {/*  <SelectorField */}
          {/*    value={headerForm.get('objBuildingSourceFin', new Map())} */}
          {/*    onChange={(e, value) => { */}
          {/*      dispatch(actions.changeField(['headerForm', 'objBuildingSourceFin'], value)); */}
          {/*    }} */}
          {/*    modelType="cat" */}
          {/*    modelName="objBuildingSourceFin" */}
          {/*  /> */}
          {/* </div> */}
        </ContainerFourColumn>
        <Title>Розрахунок</Title>
        <ContainerFourColumn>
          <div>
            <StyledLabel>{md.columns.sumEstimates.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumEstimates', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumEstimates'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumByear.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumByear', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumByear'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumPlan.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumPlan', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumPlan'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumDebt.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumDebt', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumDebt'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumFact.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumFact', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumFact'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumFinanced.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumFinanced', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumFinanced'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumFinancedDebt.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumFinancedDebt', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumFinancedDebt'], v));
              }}
            />
          </div>
        </ContainerFourColumn>
        <Title>Касові видатки</Title>
        <ContainerThreeColumn>
          <div>
            <StyledLabel>{md.columns.sumCashByear.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumCashByear', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumCashByear'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumCashFyear.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumCashFyear', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumCashFyear'], v));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.sumCashBalance.label}</StyledLabel>
            <SumInputStyled
              border
              radius
              background
              value={headerForm.get('sumCashBalance', 0)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'sumCashBalance'], v));
              }}
            />
          </div>
        </ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.note.label}</StyledLabel>
          <Fields.TextAreaField
            value={headerForm.get('note', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'note'], value))}
          />
        </div>
      </div>
    );
  }
}

// const GeneralTPRenderer = getTPContainer(GeneralTP, getResizableCP(<TPCommandPanel tableName="debtManagementAnalysis" />));

FinancingRepairObjects.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map),
  sessionOptions: PropTypes.instanceOf(Map),
};

FinancingRepairObjects.defaultProps = {
  headerForm: new Map(),
  sessionOptions: new Map(),
};

export function FinancingRepairObjectsTabs() {
  return (
    <Tabs>
      <DivWithPropsLabel label={md.tables.general.label}>
        <StyledTPPane>
          {/* <GeneralTPRenderer /> */}
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="1" empty />
    </Tabs>
  );
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

const connectedTablesRender = connect(mapStateTP)(FinancingRepairObjectsTabs);

function APMenu(props) {
  return (
    <BasicMenu
      {...props}
    />
  );
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'doc',
      name: 'financingRepairObjects',
    },
    {
      menu: APMenu, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
      canCollapsed: true,
    },
  ),
  // wasModified,
  // hasLoader,
);

export const StdEditor = enhance(FinancingRepairObjects);

export default getRouterEditor(md)(StdEditor);
