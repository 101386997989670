import {
  defaultColumns,
  defaultOrder,
  // hierarchyTypes,
  // reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  typesWork: {
    label: 'Види робiт',
    name: 'Наименование',
    visible: true,
  },
  considerTR: {
    label: 'Враховувати вид ремонту в iєрархії',
    name: 'УчитыватьВидРемонтаВИерархии',
    visible: true,
  },
};

export const typesWork = {
  label: 'Довідник видів робіт',
  name: 'TypesWork',
  backendName: 'КлассификаторВидовРабот',
  columns,
  defaultOrder,
  frontend: 'cat/typesWork',
  listColumns: [
    ...(new Set([
      // ...reqListerColumns,
      'typesWork',
      'considerTR',
    ]))],
  // hierarchy: true,
  // hierarchyType: hierarchyTypes.foldersNItems,
};

export default typesWork;
