import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge, Button, Card, Navbar,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faCheck, faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { EditorControls } from '../../../minfin/containers/basicEditor/editorControls';
import { dpEditor as actions } from '../../../actions';

const tableName = 'Documents';
function Documents({ mountPoint }) {
  const selector = useCallback(
    (store) => store.getIn([mountPoint, `tables/${tableName}`], new List()),
    [mountPoint],
  );
  const data = useSelector(selector);
  const dispatch = useDispatch();
  return (
    <>
      <Navbar sticky="top" className="bg-white rounded border mb-3 gap-2">
        <Button variant="outline-primary" onClick={() => dispatch(actions.fillTable(tableName, ['Check'], true))}>
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Відмітити всі
        </Button>
        <Button variant="outline-secondary" onClick={() => dispatch(actions.fillTable(tableName, ['Check'], false))}>
          <FontAwesomeIcon icon={faSquare} className="me-2" />
          Зняти відмітки
        </Button>
      </Navbar>
      <>
        {data.map((doc, rowId) => (
          <Card key={doc.get('Id')}>
            <Card.Body>
              <Card.Title>
                <EditorControls.CheckboxInput
                  controlId={`CheckDoc_${doc.get('Id')}`}
                  value={doc.get('Check', false)}
                  label={doc.getIn(['Budget', 'repr'], '???')}
                  description={doc.get('BudgetCode')}
                  onChange={(e, v) => dispatch(actions.changeField([`tables/${tableName}`, rowId, 'Check'], v))}
                />
              </Card.Title>
            </Card.Body>
            <Card.Footer className="d-flex _justify-content-between text-muted">
              <Card.Text className="flex-grow-1">
                <FontAwesomeIcon icon={faBuilding} className="me-2" />
                <span>{doc.getIn(['FI', 'repr'], '???')}</span>
                <Badge variant="info">{doc.get('EDRPOU')}</Badge>
              </Card.Text>
            </Card.Footer>
          </Card>
        ))}
      </>
    </>
  );
}

Documents.propTypes = {
  mountPoint: PropTypes.string.isRequired,
};

export default memo(Documents);
