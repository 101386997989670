import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../basicEditor/editorControls';

const methodValues = [
  { value: 0, display_name: 'Не перевіряти' },
  { value: 1, display_name: 'Помісячно' },
  { value: 2, display_name: 'Помісячно з накопиченням' },
  { value: 3, display_name: 'Річний' },
];
function Settings({
  data, onChange, children,
}) {
  const settings = (data && data.settings) || {};
  const onChangeSettings = useCallback(
    (pd) => onChange((oldData) => ({ oldData, settings: { ...oldData.settings, ...pd } })),
    [onChange],
  );
  return (
    <Container fluid className="pb-3">
      <Row>
        <Col>
          <EditorControls.RadioInput
            label="Метод контролю планів"
            value={settings['МетодПроверкиЛимитовПлан']}
            values={methodValues}
            onChange={(e, v) => onChangeSettings({ МетодПроверкиЛимитовПлан: v })}
            description="Згідно цього методу виконується контроль планів"
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label="Метод контролю фінансування"
            value={settings['МетодПроверкиЛимитовФакт']}
            values={methodValues}
            onChange={(e, v) => onChangeSettings({ МетодПроверкиЛимитовФакт: v })}
            description="Згідно цього методу виконується контроль залишків планів"
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label="Метод контролю заявок"
            value={settings['МетодПроверкиЛимитовЗаявки']}
            values={methodValues}
            onChange={(e, v) => onChangeSettings({ МетодПроверкиЛимитовЗаявки: v })}
            description="Згідно цього методу виконується контроль залишків заявок на фінансування"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            label="Контроль залишків коштів на рахунках"
            value={settings['УчетДвиженийПоКазнСчетах']}
            onChange={(e, v) => onChangeSettings({ УчетДвиженийПоКазнСчетах: v })}
            description="Якщо увімкнено - виконується перевірка залишків коштів на рахунках"
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            label="Друкувати фінансування зі скор.назвою "
            value={settings.print_short_name}
            onChange={(e, v) => onChangeSettings({ print_short_name: v })}
            description="Якщо увімкнено - друкується фінансування зі скороченою назвою"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            label="Використовувати пропозицію на фінансування"
            value={settings.use_fin_proposal}
            onChange={(e, v) => onChangeSettings({ use_fin_proposal: v })}
            description="Якщо увімкнено - використовується пропозиція на фінансування"
          />
        </Col>
      </Row>
      { children }
    </Container>
  );
}

Settings.propTypes = {
  data: PropTypes.shape({
    disposer_in_header: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
      МетодПроверкиЛимитовПлан: PropTypes.oneOf([0, 1, 2, 3]),
      МетодПроверкиЛимитовФакт: PropTypes.oneOf([0, 1, 2, 3]),
      МетодПроверкиЛимитовЗаявки: PropTypes.oneOf([0, 1, 2, 3]),
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Settings.defaultProps = {
  children: null,
};

export default Settings;
