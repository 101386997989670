import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes, reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: {
    label: 'Бюджет',
    name: 'Владелец',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименованиеДляПользователя',
    visible: true,
  },
  Address: { label: 'Адрес', name: 'Адрес', visible: false },
  Author: { label: 'Автор', name: 'Автор', visible: false },
  Houses: { label: 'Будинок', name: 'АдресДом', visible: false },
  Districts: { label: 'Адміністративний район', name: 'АдресРайон', visible: false },
  Street: { label: 'Вулиця', name: 'АдресУлица', visible: false },
  TypeRepair: { label: 'ВиПрограммад ремонту', name: 'ВидРемонтаРеквизит', visible: false },
  ExpertOpinion: { label: 'Висновок експертизи (N, дата)', name: 'ВыснавокЭксперт', visible: false },
  Branch: { label: 'Галузь', name: 'Галузь', visible: true },
  GeneralDesignCompany: { label: 'Генеральна проектна організація', name: 'ГенПроектОрганиз', visible: false },
  DateIssueDoc: { label: 'Дата видачі документації', name: 'ДатаВыдачиДокументации', visible: false },
  TenderDate: { label: 'Дата засідання тендерного комітету та протоколу розкриття пропозицій', name: 'ДатаТендера' },
  ContractDate: { label: 'Дата договору про закупівлю', name: 'ДатаДоговору', visible: false },
  ConractNumber: { label: 'Номер договору про закупівлю', name: 'НомерДоговору' },
  PurchaseContractDate: { label: 'Дата договору про закупівлю', name: 'ДатаДоговоруПодряд', visible: false },
  StartDate: { label: 'Дата початку', name: 'ДатаНачала', visible: false },
  EndDate: { label: 'Дата завершення', name: 'ДатаОкончания', visible: false },
  CreateDate: { label: 'Дата створення', name: 'ДатаСоздания', visible: false },
  Deputy: { label: 'Депутат', name: 'Депутат', visible: false },
  AdditionalAgreementNumber: { label: 'Номер додаткової угоди', name: 'ДоговорПодрядРабот', visible: false },
  AdditionalAgreementDate: { label: 'Дата додаткової угоди', name: 'ДоговорПодрядРаботДата', visible: false },
  ContractDesignWorkNumber: { label: 'Додаткова угода, номер', name: 'ДоговорПроектРабот', visible: false },
  ContractDesignWorkDate: { label: 'Додаткова угода, дата', name: 'ДоговорПроектРаботДата', visible: false },
  ApprovalDocOfProject: { label: 'Документ про затвердження проекту будівництва (назва, N, дата)', name: 'ДокПроЗатвПроекта', visible: false },
  ApprovalDocOfProjectDate: { label: 'Документ про затвердження проекту будівництва (дата)', name: 'ДокПроЗатвПроектаДата', visible: false },
  CustomerDocument: { label: 'Документ про визначення замовника', name: 'ДокПроОпрЗаказч', visible: false },
  DocForUser: { label: 'Розпорядчий документ про необхідність будва (реконструкції).Зазначити назву, номер та дату', name: 'ДокументДляПользователя', visible: false }, // Нет такого реквизита на бэке
  Doc: { label: 'Документ про визначення замовника', name: 'Документ', visible: false },
  LandRightDoc: { label: 'Документ про оформлення права на земельну ділянку, дата та номер рішення КМР (тільки в разі нового будувництва)', name: 'ДокументНаПравоЗемельныйУчасток', visible: false },
  OwnershipProof: { label: 'Документ що підтверджує належність об\'єкта до комунаотної власності  територіальної громади м.Києва (крім нового будівництва)', name: 'ДокументНаПравоСобственности', visible: false },
  EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: false },
  CsuOwnerName: { label: 'Назва балансоутримувача', name: 'ВладелецИмя', visible: false },
  CsuOwner: { label: 'Балансоутримувач', name: 'РаспорядительВладелец', visible: false },
  Client: { label: 'Замовник', name: 'Заказчик', visible: true },
  LandArea: { label: 'Документ', name: 'ЗемУчасток', visible: false },
  CadastrNumber1: { label: 'Кадастровий номер', name: 'ЗемУчастокКадастр1', visible: false },
  CadastrNumber2: { label: 'Зем участок кадастр2', name: 'ЗемУчастокКадастр2', visible: false },
  CadastrNumber3: { label: 'Зем участок кадастр3', name: 'ЗемУчастокКадастр3', visible: false },
  CadastrNumber4: { label: 'Зем участок кадастр4', name: 'ЗемУчастокКадастр4', visible: false },
  CadastrNote: { label: 'Підстава', name: 'ЗемУчастокКадастрПрим', visible: false },
  LandAreaCondition: { label: 'Зем участок состояние', name: 'ЗемУчастокСостояние', visible: false },
  LandAreaDescription: { label: 'Зем участок текст', name: 'ЗемУчастокТекст', visible: false },
  Investor: { label: 'Інвестор', name: 'Инвестор', visible: false },
  Info: { label: 'Инфо', name: 'Инфо', visible: false },
  readinessDocInfo: { label: 'Інформація про стан готовності документації (%)', name: 'ИнфПроСтанГотовн', visible: false },
  RequestDate: { label: 'Клопотання дата', name: 'КлопотанняДата', visible: false },
  RequestNumber: { label: 'Клопотання номер', name: 'КлопотанняНомер', visible: false },
  Designer: { label: 'Проектант', name: 'КонтрагентРаспорядителяПроект', visible: false },
  Comment: { label: 'Коментар', name: 'МодКомментарий', visible: false },
  OnDate: { label: 'Дані актуальні на дату', name: 'НаДату', visible: false },
  NotShow: { label: 'Не показувати у додатку № 6 до рішення сесії', name: 'НеПоказВРеш', visible: false },
  // CostAddBase: {
  //   label: 'Збільшення кошторисної вартості (обґрунтування)  ' +
  //     '(Зазначити причини збільшення з обґрунтуванням по складових ' +
  //     'проектно-кошторисної документації та зазначити інформацію щодо ' +
  //     'термінів перезатвердження проекту будівництва.)',
  //   name: 'ОснованиеУвеличениястоимости',
  //   visible: false,
  // },
  CostAddBase: { label: 'Збільшення кошторисної вартості (обґрунтування)', name: 'ОснованиеУвеличениястоимости', visible: false },
  Base: { label: 'ОСОснование', name: 'ОСОснование', visible: false },
  Queue: { label: 'Черга будівництва', name: 'Очередь', visible: false },
  LetterNumber: { label: 'Подано на експертизу, номер листа', name: 'ПоданоНаЕксп', visible: false },
  LetterDate: { label: 'Подано на експертизу (дата)', name: 'ПоданоНаЕкспДата', visible: false },
  ContractorText: { label: 'Подрядчик текст', name: 'ПодрядчикТекст', visible: false },
  ElementKFK: { label: 'Програма', name: 'Программа', visible: false },
  DesignerText: { label: 'Проектант текст', name: 'ПроектантТекст', visible: false },
  Region: { label: 'Район', name: 'Район', visible: false },
  Repair: {
    label: 'Ознака ремонту', name: 'Ремонт', visible: true, type: 'boolVariants', variants: ['Ремонт', 'Будівництво'],
  },
  DecisionCityCouncil: { label: 'Рішення Київради про відв. зем. діл. (N, дата, пункт), або N клопотання, кадастр. справи', name: 'РешениеКиеврада', visible: false },
  Risks: { label: 'Ризики', name: 'Риски', visible: false },
  // Risks: {
  //   label: 'Ризики (Перелічити проблеми та ризики, які можуть виникнути ' +
  //     'в разі, якщо об\'єкт не буде споруджений, або в разі перенесення термінів ' +
  //     'виконання робіт на більш пізні строки (мінімум 10 пунктів).)',
  //   name: 'Риски',
  //   visible: false,
  // },
  Correspondence: { label: 'Відповідність стратегічним напрямам розвитку та цільовим програмам (зазначити пункт, розділ та назву міської та/або державної цільової програми, номер, дату та назву документа про її затвердження. Вказати відповідний розділ стратегії розвитку)', name: 'Соответствие', visible: false },
  // State: { label: 'Стан справ на об\'єкті, переліки виконаних робіт та робіт на наступний рік
  // (Надати конкретний перелік робіт, які вже виконані,
  // та перелік робіт, які необхідно виконати у наступному
  // році (з помісячним графіком по видах робіт, що відповідають проекту органі)',
  // name: 'Состояние',
  // visible: false },
  State: { label: 'Стан справ на об\'єкті, переліки виконаних робіт та робіт на наступний рік (Текстовий опис)', name: 'Состояние', visible: false },
  // SocialSignificance: { label: 'Соціальна значимість об\'єкта (Соціальний ефект від
  // впровадження об\'єкта повинен містити конкретні кількісні показники
  // (розрахунки скорочення експлуатаційних витрат та очікуваної економії після
  // реалізації проекту, екологічні наслідки, кількість мешканців,)',
  // name: 'СоцЗначимость',
  // visible: false },
  SocialSignificance: { label: 'Соціальна значимість об\'єкта (Текстовий опис)', name: 'СоцЗначимость', visible: false },
  ApprovedProjectStage: { label: 'Стадия проект затв', name: 'СтадияПроектЗатв', visible: false },
  ApprovedBuildingDoc: { label: 'Документ, який дає право на виконання будівельних робіт (Зазначити назву документа, номер, дату та назву організації, що його видала.)', name: 'СтройДокумент', visible: false },
  Sum: { label: 'Сума', name: 'Сумма', visible: false },
  SumPrognosis: { label: 'Згідно пропозиції', name: 'СуммаПропоз', visible: false },
  YearSum: { label: 'Сума корегування', name: 'СуммаГод', visible: false },
  SumContract: { label: 'Сума договору', name: 'СуммаДоговора', visible: false },
  SumContractor: { label: 'Сума договору підряду', name: 'СуммаДоговораПодряд', visible: false },
  PlannedPurchaseDate: { label: 'Дата оголошення про заплановану закупівлю', name: 'ТендерДатаОбъявления', visible: false },
  TenderProcName: { label: 'Назва процедури тендера', name: 'ТендерНазвание', visible: false },
  TenderProcContactName: { label: 'Предмет тендерної закупівлі', name: 'ТендерНазваниеПодряд', visible: true },
  TenderNumber: { label: 'Номер оголошення про заплановану закупівлю', name: 'ТендерНомер', visible: false },
  TenderProcType: { label: 'Тип процедури тендера', name: 'ТипПроцедурыТендера', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  TypeBuilding: { label: 'Характер будівництва', name: 'Характер', visible: false },
  Year: { label: 'Рік', name: 'Год' },

  // Дополнительные Реквизиты
  Plan: {
    label: 'План', name: 'План', visible: true, type: 'moneys',
  },
  Finance: {
    label: 'Профінансовано', name: 'Финансирование', visible: true, type: 'moneys',
  },
  Kassa: {
    label: 'Касові', name: 'Касса', visible: true, type: 'moneys',
  },
  DocFilter: { label: 'Відбір за ФО', name: 'флВидимость', visible: false },
  DocYear: { label: 'Рік', name: 'ГодДокументы', visible: false },
  NotArchive: { label: 'Не архівний', name: 'НЕАрхивный', visible: false },
};

const tables = {
  listDoc: {
    label: 'Документи по об\'єкту',
    name: 'СписокДок',
    columns: {
      Year: { label: 'Рік', name: 'Год' },
      Doc: { label: 'Документ', name: 'Документ' },
      NameDoc: { label: 'Назва документу', name: 'НазваниеДок' },
      DocSum: { label: 'Сума', name: 'СуммаПоДокументу' },
    },
  },
  listPRW: {
    label: 'ДОВІДКОВО. ПВР',
    name: 'СписокПВР',
    columns: {
      Year: { label: 'Рік', name: 'Год' },
      PRW: { label: 'Проектно-вишукувальні роботи', name: 'ПВР' },
      Source: { label: 'Джерело', name: 'Источник' },
    },
  },
  listTEM: {
    label: 'ДОВІДКОВО. Техніко-економічні показники',
    name: 'СписокТЭП',
    columns: {
      Year: { label: 'Рік', name: 'Год' },
      Tem: { label: 'Техніко-економічний показник', name: 'ТехЕконПоказатель' },
      Units: { label: 'Одиниця виміру', name: 'ЕдИзм' },
      Value: { label: 'Значення', name: 'Значение' },
    },
  },
  OrdinanceDocuments: {
    name: 'РаспорядительныйДокумент',
    label: 'Розпорядчі документи',
    columns: {
      ManageDoc: { label: 'Розпорядчий документ', name: 'РаспДокумент' },
      ObjBuildingTypeDoc: { label: 'Тип документу', name: 'ТипДок' },
      Note: { label: 'Примітка', name: 'Прим' },

    },
  },

  addressList: {
    name: 'СписокАдресов',
    label: 'Перелік адрес',
    columns: {
      Districts: { label: 'Адміністративний район', name: 'АдресРайон' },
      Street: { label: 'Вулиця', name: 'АдресУлица' },
      Houses: { label: 'Будинок', name: 'АдресДом' },
    },
  },
  works: {
    name: 'Работы',
    label: 'Роботи',
    columns: {
      ContractorCSU: { label: 'Контрагент', name: 'КонтрагентРаспорядителя' },
      Year: { label: 'Рік', name: 'Год' },
      TypeDoc: { label: 'Вид договору', name: 'ВидДоговора' },
      PurchaseContractorDate: { label: 'Дата оголошення про заплановану закупівлю', name: 'ТендерДатаОбъявленияПодряд' },
      TenderContactNumber: { label: 'Номер оголошення про заплановану закупівлю', name: 'ТендерНомерПодряд' },
      TenderDate: { label: 'Дата тендера', name: 'ДатаТендераПодряд' },
      ConractDocNumber: { label: 'Номер договору про закупівлю', name: 'НомерДоговоруПодряд' },
      TenderContactProcType: { label: 'Тип процедуры тендера підряд', name: 'ТипПроцедурыТендераПодряд' },
      TenderAwardDate: { label: 'Дата договору про закупівлю', name: 'ДатаДоговоруПодряд' },
      ContractStartDate: { label: 'Дата початку договору', name: 'ДоговорДатаНачала' },
      ContractEndDate: { label: 'Дата закінчення договору', name: 'ДоговорДатаОкончания' },
      SumDocContractor: { label: 'Сума договора підряда', name: 'СуммаДоговораПодряд' },
      Note: { label: 'Примітка', name: 'Примечание' },
      NotCount: { label: 'Не враховувати', name: 'НеУчитывать' },
    },
  },

  designers: {
    name: 'Проектанты',
    label: 'Проектанти',
    columns: {
      Designer: { label: 'Проектант', name: 'Проектант' },
      Note: { label: 'Примітка', name: 'Прим' },
    },
  },

  contractors: {
    name: 'Подрядчики',
    label: 'Підрядники',
    columns: {
      Contractor: { label: 'Підрядник', name: 'Подрядчик' },
      Note: { label: 'Примітка', name: 'Прим' },
    },
  },

  objectDocs: {
    name: 'Подрядчики',
    label: 'Підрядники',
    columns: {
      Doc: { label: 'Документ об`єкту', name: 'ДокументОбїекта' },
      Sum: { label: 'Сума', name: 'СуммаПоДокументы' },
    },
  },

  csuByEdrpou: {
    name: 'ТЗ_ЕДРПОУ',
    label: 'Розпорядники',
    columns: {
      Edrpou: { label: 'ЄДРПОУ', name: 'ЕДРПОУ' },
      Csu: { label: 'Розпорядник бюджетних коштів', name: 'Распорядитель' },
      CsuName: { label: 'Найменування розпорядника', name: 'РаспорядительИмя' },
      UpperLevelCsu: { label: 'Розпорядник вищ. рівня', name: 'РаспорядительВладелец' },
    },
  },
};

const footer = { ...columns };

export const objectsBuilding = {
  label: 'Об\'єкти капітального будівництва/ремонту',
  name: 'objectsBuilding',
  backendName: 'ОбъектыСтроительства',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/objectsBuilding',
  listColumns: [...new Set([...reqListerColumns, 'name', 'Repair', 'Branch', 'Client', 'Plan', 'Finance', 'Kassa'])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useFoldersLazyLoad: true,
};

export default objectsBuilding;
