import React, { forwardRef, useContext } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

import PrintModal from './printModalLister';

import ListerContext from '../../../newLister/context';
import SendToLogicaButton from '../../../Logica/sendToLogicaButton';
import { modelName, modelType } from '../def';

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <ButtonGroup>
        <Dropdown>
          <Dropdown.Toggle>
            <FontAwesomeIcon icon={faPrint} />
          </Dropdown.Toggle>
          <Dropdown.Menu>

            <PrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
      <ButtonGroup>
        <SendToLogicaButton modelType={modelType} modelName={modelName} />
      </ButtonGroup>

    </DocListerCommandPanel>
  );
});

export default CommandPanel;
