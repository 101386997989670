import React, {
  Suspense, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  ContainerFourColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import { emptyUid } from '../../../constants/meta/common';

import { InputWithDropdown } from '../../../components/styledInputs';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { soSelector } from '../../documents/_common/selectors';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.freeRemPrint;

const TypeForm = {
  f1: { label: 'Додаток 3', name: 1 },
  f2: { label: 'Додаток 4', name: 2 },
  f3: { label: 'Додаток 5', name: 3 },
  f4: { label: 'Додаток 8', name: 4 },
  f5: { label: 'Додаток 9', name: 5 },
};

const TypeFormLogica = {
  f1: { label: 'Додаток 3', name: 3 },
  f2: { label: 'Додаток 4', name: 4 },
};

function FreeRemPrintEditor({ onClose, showAllButtons }) {
  const dispatch = useDispatch();
  const sessionOptions = useSelector(soSelector);
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  const canSendToLogica = useMemo(
    () => (headerForm.get('apprByRegion', false) && sessionOptions.get('approveByRegion', false))
            || (!sessionOptions.get('approveByRegion', false)),
    [headerForm, sessionOptions],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  return (
    <MainContainer>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flex: '1 1 auto', marginRight: '20px' }}>
          <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
          <Fields.SelectorField
            modelType="cat"
            modelName="signatureVariants"
            value={headerForm.get('SignVariant', new Map())}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
          />
        </div>
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>

        { (showAllButtons || headerForm.get('addNum', 0) === 1) && (
          <CommandPanelButton
            text="Додаток 3"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 1));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 3'}
          </CommandPanelButton>
        )}
        { (showAllButtons || headerForm.get('addNum', 0) === 2) && (
          <CommandPanelButton
            text="Додаток 4"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 2));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 4'}
          </CommandPanelButton>
        )}
        { (showAllButtons || headerForm.get('addNum', 0) === 3) && (
          <CommandPanelButton
            text="Додаток 5"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 3));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 5'}
          </CommandPanelButton>
        )}
        { (showAllButtons || headerForm.get('addNum', 0) === 4) && (
          <CommandPanelButton
            text="Додаток 8"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 4));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 8'}
          </CommandPanelButton>
        )}
        { (showAllButtons || headerForm.get('addNum', 0) === 5) && (
          <CommandPanelButton
            text="Додаток 9"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 5));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 9'}
          </CommandPanelButton>
        )}

        { (showAllButtons || headerForm.get('addNum', 0) === 6) && (
          <CommandPanelButton
            text="Додаток 4"
            onClick={() => {
              dispatch(actions.changeField(['headerForm', 'addNum'], 6));
              setSettingsOpened(false);
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Додаток 4'}
          </CommandPanelButton>
        )}
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        {/* {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && ( */}
        <CommandPanelButton
          label="Передати в Logica"
          onClick={() => {
            dispatch(actions.processServerReq('CreateDispatchDoc'));
          }}
          disabled={!canSendToLogica || !headerForm.getIn(['SignVariant', 'repr'], '')}
        />
        {!canSendToLogica
            && (
              <Alert variant="warning" className="mb-0 mx-auto">
                Документ не затверджений, передача в Логіка заблокована, зверніться до спеціаліста,
                який має повноваження на затвердження документів
              </Alert>
            )}
        {/* )} */}
        {!headerForm.getIn(['SignVariant', 'repr'], '')
          && (
            <Alert variant="warning" className="mb-0 mx-auto">
              Для відправки в Логіка потрібно обрати варіант підпису
            </Alert>
          )}
        {/* )} */}
      </ContainerButtonTop>
      { showAllButtons && (
        <div>
          <StyledLabel>{md.columns.addLogica.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('addLogica', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'addLogica'], v))}
            options={Object.values(TypeFormLogica)}
          />
        </div>
      )}
      {settingsOpened ? (
        <FramePrintModal
          styled={settingsOpened ? { transition: '3s' } : { transition: '3s' }}
          visible={settingsOpened}
          animation="overlay"
          width="very wide"
          direction="top"
          tertiary
          color="blue"
          basic
        >
          <div>
            <ContainerFourColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
              { showAllButtons && (
                <div>
                  <StyledLabel>{md.columns.addNum.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('addNum', 0)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'addNum'], v))}
                    options={Object.values(TypeForm)}
                  />
                </div>
              )}
              <div>
                <EditorControls.DateInput
                  label={md.columns.dateO.label}
                  value={headerForm.get('dateO', null)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dateO'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  value={headerForm.get('in1000', false)}
                  label={md.columns.in1000.label}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'in1000'], v))}
                />
              </div>
            </ContainerFourColumn>
          </div>
        </FramePrintModal>
      )
        : (
          <Suspense fallback={<div>Завантаження...</div>}>
            <TableEditor mountNode={resultMountNode} />
          </Suspense>
        )}
    </MainContainer>
  );
}

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

FreeRemPrintEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  showAllButtons: PropTypes.bool,
};

FreeRemPrintEditor.defaultProps = {
  showAllButtons: true,
};

export default enchance(FreeRemPrintEditor);
