import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { paymentPurposeTemplate as md } from '../../../../constants/meta/catalogs/paymentPurposeTemplate';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import partnerBankAccounts from '../../../../constants/meta/catalogs/partnerBankAccounts';

function PaymentPurposeTemplate({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.template.label}
            value={data[md.columns.template.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.template.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`budgetPayment-${data.id}`}
            label={md.columns.budgetPayment.label}
            value={data[md.columns.budgetPayment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budgetPayment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.budgetPaymentCode.label}
            value={data[md.columns.budgetPaymentCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budgetPaymentCode.name]: value,
            })}
            modelType="cat"
            modelName="budgetPaymentCode"
            readOnly={readOnly}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.clientTAX.label}
            value={data[md.columns.clientTAX.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.clientTAX.name]: value,
            })}
            modelType="cat"
            modelName="csuPartners"
            readOnly={readOnly}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.acc.label}
            value={data[md.columns.acc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.acc.name]: value,
            })}
            modelType="cat"
            modelName="partnerBankAccounts"
            readOnly={readOnly}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.KOATUU.label}
            value={data[md.columns.KOATUU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KOATUU.name]: value,
            })}
            readOnly={readOnly}
            maxLength={50}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.messageInfo.label}
            value={data[md.columns.messageInfo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.messageInfo.name]: value,
            })}
            readOnly={readOnly}
            maxLength={140}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.addInfo.label}
            value={data[md.columns.addInfo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.addInfo.name]: value,
            })}
            readOnly={readOnly}
            maxLength={140}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.KDB.label}
            value={data[md.columns.KDB.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KDB.name]: value,
            })}
            modelType="cat"
            modelName="kdbClassifier"
            readOnly={readOnly}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.info.label}
            value={data[md.columns.info.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.info.name]: value,
            })}
            readOnly={readOnly}
            maxLength={140}
            disabled={!data[md.columns.budgetPayment.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="paymentPurposeTemplate"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

PaymentPurposeTemplate.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PaymentPurposeTemplate;
