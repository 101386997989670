import React, { forwardRef } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  >
    <ButtonGroup>
      <Dropdown>
        <Dropdown.Toggle>
          <FontAwesomeIcon icon={faPrint} />
        </Dropdown.Toggle>
      </Dropdown>
    </ButtonGroup>
  </DocListerCommandPanel>

));

export default CommandPanel;
