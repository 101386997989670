import styled from 'styled-components';

const StyledTPPane = styled.div`
  overflow: inherit;
  display: flex !important;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #afbbcc;
  border-top: none;
`;

export default StyledTPPane;
