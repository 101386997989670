import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';
import { compose } from 'redux';
import {
  Container, Nav, Row, Col, Button, Modal, Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import DateRange from '../../../components/fields/DateRangeField';
import TPDocs from './tablepart/TPDocs';
import enums from '../../../constants/meta/enums';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor, ContainerButton,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import storePathParam from '../../../common/storePathParam';
import {
  CustomFilter,
  getMapDispatchToProps,
  getMapStateToProps,
} from '../../reportEditor/DataComposition/Filter';
import TableEditor from '../../tableEditor';
import Loader from '../../../components/styledWrappedLoader';

const md = meta.dp.requestsLoading;

const planingKindsOptions = Object.values(enums.PlaningKind);

const ContainerBut = styled(ContainerButton)`
  margin: 20px 0 5px 0;
`;

function RequestsLoadingEditor({
  dispatch,
  headerForm,
  onOK,
  mountPoint,
}) {
  const selector = (state) => state.get(`dp/${md.name}/dpEditor`, new Map());
  const state = useSelector(selector);

  const isProcessing = state.get('isProcessing', false);

  const [modalOpened, setModalOpened] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const loadHandler = useCallback(
    () => onOK && onOK((store) => {
      const checkedDocs = store.getIn([`dp/${storePathParam(store).nameDp}/dpEditor`, 'tables/docsTable'])
        .filter((row) => row.get('Check', false))
        .reduce((v, row) => v.set(row.getIn(['Document', 'id']), 'true'), new Map());
      return ({
        data: store.getIn([`dp/${storePathParam(store).nameDp}/dpEditor`, 'tables/documentNotes'])
          .filter((row) => checkedDocs.has(row.getIn(['Document', 'id']))),
      });
    }),
    [onOK],
  );

  const printShortKEKV = () => {
    dispatch(actions.changeField(['headerForm', 'result'], new Map()));
    dispatch(actions.processServerReq('PRINT'));
    setModalOpened(true);
  };

  const RLCustomFilter = useMemo(
    () => connect(
      getMapStateToProps(
        mountPoint,
        ['headerForm', 'DCSettings', 'Filter'],
        ['headerForm', 'DCSettings', 'FilterAvailableFields'],
      ),
      getMapDispatchToProps(actions),
    )(CustomFilter),
    [mountPoint],
  );

  return (
    <Container fluid>
      <Row sm={4}>
        <Col>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRange
            value={headerForm.get('period', new Map())}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'period'], value));
            }}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          <Fields.ItemField
            value={headerForm.get('budget', new Map())}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.fondObject.label}</StyledLabel>
          <Fields.ItemField
            value={headerForm.get('fondObject', new Map())}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.FI.label}</StyledLabel>
          <Fields.ItemField
            value={headerForm.get('FI', new Map())}
          />
        </Col>
      </Row>
      {headerForm.get('isFinPropose', false) && (
        <Row sm={2}>
          <Col>
            <StyledLabel>{md.columns.FIAccount.label}</StyledLabel>
            <Fields.SelectorField
              modelType="cat"
              modelName="bankAccounts"
              value={headerForm.get('FIAccount', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'FIAccount'], value));
              }}
            />
          </Col>
          <Col>
            <StyledLabel>Вид планування</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('planingMode', '')}
              options={planingKindsOptions}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'planingMode'], value));
              }}
            />
          </Col>
        </Row>
      )}
      <h6>Параметри відбору заявок</h6>
      <Row sm={3}>
        <Col>
          {!headerForm.get('isFinancing', false) ? (
            <Fields.CheckboxField
              label={md.columns.LoadLoaded.label}
              value={headerForm.get('LoadLoaded', false)}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'LoadLoaded'], value));
              }}
            />
          ) : (
            <Fields.CheckboxField
              label={md.columns.LoadFinanced.label}
              value={headerForm.get('LoadFinanced', false)}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'LoadFinanced'], value));
              }}
            />
          )}
        </Col>
        <Col>
          <Fields.CheckboxField
            label={md.columns.WithoutNotes.label}
            value={headerForm.get('WithoutNotes', false)}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'WithoutNotes'], value));
            }}
          />
        </Col>
        <Col>
          <Button variant="success" onClick={() => setShowFilters(true)}>
            <FontAwesomeIcon icon={faFilter} className="me-2" />
            Фільтрування
          </Button>
          <Modal
            show={showFilters}
            onHide={() => setShowFilters(false)}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Фільтрування документів</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RLCustomFilter />
            </Modal.Body>

          </Modal>

        </Col>
      </Row>
      <Nav>
        <Button
          variant="success"
          onClick={() => dispatch(actions.processServerReq('FILL_TABLE'))}
        >
          <Badge pill variant="light" className="me-2">1</Badge>
          Заповнити
        </Button>
        <Button
          variant="success"
          onClick={loadHandler}
        >
          <Badge pill variant="light" className="me-2">2</Badge>
          Завантажити
        </Button>
        <Button
          variant="success"
          onClick={printShortKEKV}
        >
          Розрахувати суму за КЕКВ у обраних док.
        </Button>
        <Modal
          show={modalOpened}
          scrollable
          size="xl"
          onHide={() => setModalOpened(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Сума за КЕКВ у обраних документах
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Loader isLoading={isProcessing}>
              <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
            </Loader>
          </Modal.Body>
        </Modal>

      </Nav>
    </Container>
  );
}

RequestsLoadingEditor.propTypes = {
  dispatch: PropTypes.func,
  headerForm: PropTypes.instanceOf(Map),
  onOK: PropTypes.func,
  mountPoint: PropTypes.string.isRequired, // Точка мантирования данных (раздел store)
};

RequestsLoadingEditor.defaultProps = {
  dispatch: () => {
  },
  headerForm: null,
  onOK: null,
};

RequestsLoadingEditor.displayName = 'RequestsLoadingEditor';

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // requestsLoading',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: TPDocs, // Табличные части
    },
  ),
);

export default enchance(RequestsLoadingEditor);
