import React from 'react';
import PropTypes from 'prop-types';
import { faEdit as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function EditButton({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      icon={icon}
      onClick={onClick}
      label={label}
      disabled={disabled}
    />
  );
}

EditButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

EditButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Редагувати',
};

export default EditButton;
