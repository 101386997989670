import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  fond: { label: 'Фонд', name: 'Фонд' },
  year: { label: 'Рік', name: 'тГод' },
  dateIn: { label: 'з', name: 'НачДата' },
  dateOut: { label: 'по', name: 'КонДата' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  repType: { label: 'Вид звіту', name: 'ВариантОтчета' },
  KDB: { label: 'КДБ', name: 'КДБ' },
  elKDB: { label: 'Складова КДБ', name: 'ЭлементКДБ' },
  elFond: { label: 'Складова фонду', name: 'ЭлементФонд' },
  flDetail: { label: 'Деталізація', name: 'флДетализация' },
  flBudgSepar: { label: 'Бюджети окремо', name: 'флБюджетыРаздельно' },
  sessionDes: { label: 'Додаток до рішення сесії', name: 'Сессия' },
  consolid: { label: 'Виводити  "ЗВЕДЕНИЙ"', name: 'ВыводитьСводный' },
  basket: { label: 'Без підсумків за кошиками', name: 'НеПоказыватьИтогиПоКорзинам' },
  noAdd: { label: 'не показувати "Додаток"', name: 'БезДод' },
  in1000: { label: 'в тис. грн.', name: 'ВТысГрн' },
  repeatH: { label: 'Повторювати шапку на кожному листку', name: 'ПовторятьШапку' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  upCount: { label: 'Наростаючим підсумком', name: 'флНарастающийИтог' },
  inBR: { label: 'в т.ч. бюджет розвитку', name: 'флБР' },
  yearForm: { label: 'Річна форма', name: 'Годовой' },
  add1: { label: 'текст під "Додаток " 1"', name: 'Надпись' },
  textH: { label: 'текст для додаткового заголовку над основни', name: 'Заголовок2' },

};

const definition = {
  label: 'Уточнений розпис доходів',
  name: 'RevenuePlan',
  columns,
  frontend: 'rep/RevenuePlan',
  backendName: 'ПланДоходов',
  resultColumns: baseResultColumns,
};

export default definition;
