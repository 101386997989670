import React, { PureComponent } from 'react';

import { docComponents } from '../../containers/documents';
import { catComponents } from '../../containers/catalogs';
import { dpComponents } from '../../containers/dataProcessors';
import { repComponents } from '../../containers/reports';
import RadioButton from '../styledRadiobutton/styledRadiobutton';
import Button from '../styledAssigmButton';
import GetNumberInput from '../../containers/field/NumberInput';

const viewModes = {
  catLister: { label: 'Довідники - список', value: 0 },
  catEditor: { label: 'Довідники - елемент', value: 1 },
  docLister: { label: 'Документ - список', value: 2 },
  docEditor: { label: 'Документ - елемент', value: 3 },
  rep: { label: 'Звіти', value: 4 },
  dp: { label: 'Обробки', value: 5 },
};

export const SumInputStyled = GetNumberInput(2, 0);

class DeveloperService extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countCatL: 0,
      countCatE: 0,
      countDocL: 0,
      countDocE: 0,
      countRep: 0,
      countDp: 0,
      filterValue: 0,
    };
  }
  // create/

  render() {
    const cat = Object.keys(catComponents);
    const doc = Object.keys(docComponents);
    const rep = Object.keys(repComponents);
    const dp = Object.keys(dpComponents);

    const {
      countCatE, countCatL, filterValue, countDocE, countDocL, countRep, countDp,
    } = this.state;

    const CatLister = catComponents[cat[countCatL]].lister;
    const CatEditor = catComponents[cat[countCatE]].editor;
    const DocLister = docComponents[doc[countDocL]].lister;
    const DocEditor = docComponents[doc[countDocE]].editor;
    const RepEditor = repComponents[rep[countRep]].editor;
    const DpEditor = dpComponents[dp[countDp]].editor;
    return (
      <>
        <h1> Developer service </h1>
        <div style={{ display: 'flex', paddingBottom: '10px' }}>
          <RadioButton
            name="APFilter"
            values={Object.values(viewModes)}
            value={this.state.filterValue}
            onChange={(e, v) => {
              console.log('0 === viewModes.catLister.value', viewModes.catLister.value === 0);
              this.setState({ filterValue: v });
            }}
          />
        </div>

        {(filterValue === viewModes.catLister.value)
        && (
          <div>
            <h2>
              {' '}
              Catalogs Lister total:
              {cat.length}
              {' '}
              current component:
              {cat[countCatL]}
            </h2>
            <SumInputStyled
              value={countCatL}
              onChange={(e, value) => {
                const newCountCatL = (value < 0) ? 0
                  : ((value >= cat.length) ? (cat.length - 1) : value);
                this.setState({ countCatL: newCountCatL });
              }}
            />
            <div style={{ display: 'flex', paddingBottom: '10px' }}>
              current index
              {' '}
              {countCatL}
              <Button
                onClick={() => {
                  console.log('cat[countCatL] ', (countCatL - 1) < 0 ? cat[0] : cat[countCatL - 1]);
                  this.setState({ countCatL: ((countCatL - 1) < 0) ? 0 : (countCatL - 1) });
                }}
                title="< Back"
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  console.log('cat[countCatL] ', cat[countCatL + 1]);
                  this.setState({ countCatL: ((countCatL + 1) >= cat.length) ? (cat.length - 1) : (countCatL + 1) });
                }}
                title="Next >"
              >
                Next >
              </Button>
            </div>
            <CatLister />
          </div>
        )}
        { (filterValue === viewModes.catEditor.value)
        && (
          <div>
            <h2>
              {' '}
              Catalogs Editor total:
              {cat.length }
              {' '}
              current component:
              {cat[countCatE]}
            </h2>
            <SumInputStyled
              value={countCatE}
              onChange={(e, value) => {
              // console.log('e, value', e, value);
                this.setState({ countCatE: value });
              }}
            />
            <div style={{ display: 'flex', paddingBottom: '10px' }}>
              current index
              {' '}
              {countCatE}
              <Button
                onClick={() => {
                  console.log('cat[countCatE] ', cat[countCatE - 1]);
                  this.setState({ countCatE: countCatE - 1 });
                }}
                title="< Back"
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  console.log('cat[countCatE] ', cat[countCatE + 1]);
                  this.setState({ countCatE: countCatE + 1 });
                }}
                title="Next >"
              >
                Next >
              </Button>
            </div>
            <CatEditor matchId={{ params: 'create' }} />
          </div>
        )}
        { (filterValue === viewModes.docLister.value)
        && (
          <div>
            <h2>
              {' '}
              Doc Lister  total:
              {doc.length }
              {' '}
              current component:
              {doc[countDocL]}
            </h2>
            <div style={{ display: 'flex', paddingBottom: '10px' }}>
              <SumInputStyled
                value={countDocL}
                onChange={(e, value) => {
                  // console.log('e, value', e, value);
                  this.setState({ countDocL: value });
                }}
              />
              current index
              {' '}
              {countDocL}
              <Button
                onClick={() => {
                  console.log('doc[countDocL] ', doc[countDocL - 1]);
                  this.setState({ countDocL: countDocL - 1 });
                }}
                title="< Back"
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  console.log('doc[countDocL] ', doc[countDocL + 1]);
                  this.setState({ countDocL: countDocL + 1 });
                }}
                title="Next >"
              >
                Next >
              </Button>
            </div>
            <DocLister />
          </div>
        )}
        { (filterValue === viewModes.docEditor.value)
          && (
            <div>
              <h2>
                {' '}
                Doc Editor total:
                {doc.length }
                {' '}
                current component:
                {doc[countDocE]}
              </h2>
              <div style={{ display: 'flex', paddingBottom: '10px' }}>
                <SumInputStyled
                  value={countDocE}
                  onChange={(e, value) => {
                  // console.log('e, value', e, value);
                    this.setState({ countDocE: value });
                  }}
                />
                current index:
                {' '}
                {countDocE}
                <Button
                  onClick={() => {
                    console.log('doc[countDocE] ', doc[countDocE - 1]);
                    this.setState({ countDocE: countDocE - 1 });
                  }}
                  title="< Back"
                >
                  Back
                </Button>

                <Button
                  onClick={() => {
                    console.log('doc[countDocE] ', doc[countDocE + 1]);
                    this.setState({ countDocE: countDocE + 1 });
                  }}
                  title="Next >"
                >
                  Next >
                </Button>
              </div>
              <DocEditor matchId={{ params: 'create' }} />
            </div>
          )}
        { (filterValue === viewModes.rep.value)
          && (
            <div>
              <h2>
                {' '}
                Reports total:
                {rep.length }
                {' '}
                current component:
                {rep[countRep]}
              </h2>
              <div style={{ display: 'flex', paddingBottom: '10px' }}>
                <SumInputStyled
                  value={countRep}
                  onChange={(e, value) => {
                    this.setState({ countRep: value });
                  }}
                />
                current index:
                {' '}
                {countRep}
                <Button
                  onClick={() => {
                    console.log('rep[countRep] ', rep[countRep - 1]);
                    this.setState({ countRep: countRep - 1 });
                  }}
                  title="< Back"
                >
                  Back
                </Button>

                <Button
                  onClick={() => {
                    console.log('rep[countRep] ', rep[countRep + 1]);
                    this.setState({ countRep: countRep + 1 });
                  }}
                  title="Next >"
                >
                  Next >
                </Button>
              </div>
              <RepEditor />
            </div>
          )}
        {' '}
        { (filterValue === viewModes.dp.value)
          && (
            <div>
              <h2>
                {' '}
                Data processsor total:
                {dp.length }
                {' '}
                current component:
                {dp[countDp]}
              </h2>
              <div style={{ display: 'flex', paddingBottom: '10px' }}>
                <SumInputStyled
                  value={countDp}
                  onChange={(e, value) => {
                    this.setState({ countDp: value });
                  }}
                />
                current index:
                {' '}
                {countDp}
                <Button
                  onClick={() => {
                    console.log('dp[countDp] ', dp[countDp - 1]);
                    this.setState({ countDp: countDp - 1 });
                  }}
                  title="< Back"
                >
                  Back
                </Button>

                <Button
                  onClick={() => {
                    console.log('dp[countDp] ', dp[countDp + 1]);
                    this.setState({ countDp: countDp + 1 });
                  }}
                  title="Next >"
                >
                  Next >
                </Button>
              </div>
              <DpEditor />
            </div>
          )}
      </>
    );
  }
}
export default DeveloperService;
