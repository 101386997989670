import { faEye, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';
import md from '../../../../constants/meta/documents/indicatorFact';
import { CPButton } from '../../../../minfin/components/bootStrap/buttons';
import { ButtonIcon, ContainerSave, TextSave } from '../../../tableEditor/styles';
import {
  DOCXColorIcon, HTMLColorIcon, PDFColorIcon, XLSColorIcon,
} from '../../../../assets/icons';
import { newFile } from '../../../../actions/notifier';

function EffectivityPrintModal({ data, actions }) {
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();

  const saveToFile = useCallback(
    (typeFile) => {
      const d = actions.onSR('reportToFile', { format: typeFile, isPortrait: true });
      d.then((ff) => {
        const f = ff.fileFromReport;
        dispatch(newFile(f.filename, f.content, f.type, f.description));
      });
    },
    [actions, dispatch],
  );

  return (
    <>
      <CPButton
        onClick={() => {
          setOpened(true);
          if (!data[md.columns.effectivityPrint.name]) { actions.onSR('CALCULATION_EFFICIENCY_TASKS'); }
        }}
        content="Детальний розрахунок"
        icon={faEye}
      />
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Детальний розрахунок
          </Modal.Title>
          <ContainerSave>
            <TextSave>Зберегти як:</TextSave>
            <ButtonIcon
              text="XLS"
              onClick={() => saveToFile('XLS')}
            >
              <img src={XLSColorIcon} alt="XLS" width="33px" />
            </ButtonIcon>
            <ButtonIcon
              text="PDF"
              onClick={() => saveToFile('PDF')}
            >
              <img src={PDFColorIcon} alt="PDF" width="33px" />
            </ButtonIcon>
            <ButtonIcon
              text="DOCX"
              onClick={() => saveToFile('DOCX')}
            >
              <img src={DOCXColorIcon} alt="DOCX" width="33px" />
            </ButtonIcon>
            <ButtonIcon
              style={{ marginRight: '0' }}
              text="HTML"
              onClick={() => saveToFile('HTML')}
            >
              <img src={HTMLColorIcon} alt="HTML" width="33px" />
            </ButtonIcon>
          </ContainerSave>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: data[md.columns.effectivityPrint.name] }} />
        </Modal.Body>
      </Modal>
    </>
  );
}

EffectivityPrintModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
    processServerReq: PropTypes.func.isRequired,
  }).isRequired,
};

export default EffectivityPrintModal;
