import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function IndicatorsHeader({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow className="px-4">
      <Col sm={4}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'indicator'}
          highlighted={highlights.includes('indicator')}
          onToggleHighlight={() => onToggleHighlight('indicator')}
        >
          {fields.indicator.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf'}
          highlighted={highlights.includes('amount_zf')}
          onToggleHighlight={() => onToggleHighlight('amount_zf')}
        >
          {fields.amount_zf.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf'}
          highlighted={highlights.includes('amount_sf')}
          onToggleHighlight={() => onToggleHighlight('amount_sf')}
        >
          {fields.amount_sf.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_total'}
          highlighted={highlights.includes('amount_total')}
          onToggleHighlight={() => onToggleHighlight('amount_total')}
        >
          {fields.amount_total.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'source_text'}
          highlighted={highlights.includes('source_text')}
          onToggleHighlight={() => onToggleHighlight('source_text')}
        >
          {fields.source_text.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

IndicatorsHeader.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onToggleHighlight: PropTypes.func.isRequired,
};

IndicatorsHeader.defaultProps = {
  activeCol: null,
  highlights: [],
};

export default IndicatorsHeader;
