/* eslint-disable react/no-array-index-key */
import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function DisposersMapRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  //
  authorityId,
}) {
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kindOfChangeProps = tableHooks.useSelectorInputProps('kind_of_change', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const notesProps = tableHooks.useStringInputProps('notes', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(
    () => ({
      parent: authorityId,
    }),
    [authorityId],
  );
  return (
    <>
      <Col md={4} className="border-right">
        <TabelRowCell column="budget" highlighted={highlights.includes('budget')}>
          <TableControls.ItemPicker {...disposerProps} filter={disposerFilter} noHierarchy />
        </TabelRowCell>
      </Col>
      <Col md={4} className="border-right">
        <TabelRowCell column="kind_of_change" highlighted={highlights.includes('kind_of_change')}>
          <TableControls.SelectorInput {...kindOfChangeProps} />
        </TabelRowCell>
      </Col>
      <Col md={4} className="border-right">
        <TabelRowCell column="notes" highlighted={highlights.includes('notes')}>
          <TableControls.StringInput {...notesProps} />
        </TabelRowCell>
      </Col>
    </>
  );
}

DisposersMapRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  //
  authorityId: PropTypes.number,
};

DisposersMapRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
  //
  authorityId: null,
};

export default memo(DisposersMapRow);
