import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { FormControl, InputGroup, Dropdown } from 'react-bootstrap';

const StyledCalendar = styled(Calendar)`
    border: none !important;
  `;
const DropMenu = styled(Dropdown.Menu)`
  z-index: 1031;
`;

export const DropButton = styled(Dropdown.Toggle)`
    background-color: var(--backgroundButton) !important;
    color: var(--colorTextButton)!important;
      &:hover {
        background-color: var(--hoverButton) !important;
      };
  `;

function YearInput({
  value, onChange, disabled, readOnly,
  errored, size, onFocus, onBlur,
}) {
  const [open, setOpen] = useState(false);

  const [err, setErr] = useState(null);

  const dropRef = useRef();

  const onCalendarChange = useCallback(
    (v) => {
      setErr(null);
      onChange(null, v.getFullYear());
      setOpen(false);
    },
    [onChange],
  );

  const onInputChange = useCallback(
    (e) => {
      const v = Number.parseInt(e.target.value, 10);
      if (Number.isNaN(v)) {
        setErr('Помилка вводу року');
      } else {
        setErr(null);
        onChange(e, Number.parseInt(`${v}0000`.substr(0, 4), 10));
      }
    },
    [onChange],
  );

  const keyDownHandler = useCallback(
    (e) => {
      if (readOnly) return false;
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          onChange(e, value + 1);
          break;
        case 'ArrowDown':
          e.preventDefault();
          onChange(e, value - 1);
          break;
        default:
          return false;
      }
      return true;
    },
    [onChange, readOnly, value],
  );

  const groupClassName = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => errored ? 'is-invalid' : '',
    [errored],
  );

  const calendarValue = useMemo(
    () => new Date(value, 0, 1),
    [value],
  );

  const displayValue = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => value ? value.toString() : '',
    [value],
  );

  return (
    <InputGroup className={groupClassName} size={size || 'sm'}>
      <FormControl
        value={displayValue}
        onChange={onInputChange}
        isInvalid={errored || err}
        onKeyDown={keyDownHandler}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Dropdown
        ref={dropRef}
        as={InputGroup.Append}
        show={open}
        onToggle={(isOpen) => setOpen(isOpen)}
        alignRight
      >
        <DropButton
          variant="outline-secondary"
          disabled={disabled || readOnly}
          onClick={() => setOpen(true)}
          split
          tabIndex="-1"
          style={{ borderRadius: '0 .25rem .25rem 0' }}
        />
        <DropMenu>
          <StyledCalendar
            locale="uk-UA"
            value={calendarValue}
            maxDetail="decade"
            minDetail="decade"
            view="decade"
            onChange={onCalendarChange}
          />
        </DropMenu>
      </Dropdown>
      {err && (
        <FormControl.Feedback type="invalid">{err}</FormControl.Feedback>
      )}
    </InputGroup>
  );
}

YearInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errored: PropTypes.bool,
  size: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

YearInput.defaultProps = {
  value: null,
  disabled: false,
  readOnly: false,
  errored: false,
  size: 'sm',
  onFocus: null,
  onBlur: null,
};

export default YearInput;
