import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CommandPanelButton } from '../button';
import { ExpandIcon, CollapseTableIcon } from '../../assets/icons';

const StyledDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
`;

const ContainerButton = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const GetResizableTP = (WrappedComponent) => {
  function ResizableTP({ fullscreen, onResize, ...restProps }) {
    return (
      <StyledDiv>
        {/*
      позволяет сохранить одинаковый размер иконок
      */}
        <ContainerButton>
          {React.cloneElement(WrappedComponent, { ...restProps })}
          <CommandPanelButton
            text="Розгорнути/Згорнути"
            style={{ marginLeft: 'auto' }}
            onClick={onResize}
          >
            {fullscreen ? <CollapseTableIcon /> : <ExpandIcon />}
          </CommandPanelButton>
        </ContainerButton>
      </StyledDiv>
    );
  }
  ResizableTP.propTypes = {
    onResize: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool,
  };

  ResizableTP.defaultProps = {
    fullscreen: false,
  };
  return ResizableTP;
};

export default GetResizableTP;
