import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';
import api from '../../../../api/req';
import { REPORTS_API_URL } from '../../../basicReport/hooks/consts';
import { CiatAppContext } from '../../../../providers';

const BACKENDURL = meta.cat.reportlabel.backendURL;

const StyledDiv = styled.div`
  width: 50px;
  &>svg{
      height: auto;
      width: auto;
    }
`;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const [reports, setReports] = useState([]);
  const { auth } = useContext(CiatAppContext);
  useEffect(() => {
    const loader = async () => {
      const r = await api.get(REPORTS_API_URL, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    loader().then((d) => setReports(
      d.map((r) => ({
        value: r.id,
        display_name: r.repr,
      })),
    ));
  }, [auth]);

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const reportsProps = editorHooks.useSelectorInputProps('reports', data, fields, fieldErrors, actions.onChange);
  const sibOrderProps = editorHooks.useStringInputProps('sib_order', data, fields, fieldErrors, actions.onChange);
  const iconProps = editorHooks.useStringInputProps('icon', data, fields, fieldErrors, actions.onChange);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.name}
    >
      <Container fluid className="pb-3">
        <Row>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...parentProps} />
          </Col>
          <Col>
            <EditorControls.NumberInput {...sibOrderProps} />
          </Col>
          <Col sm={12}>
            <EditorControls.MultiSelector {...reportsProps} values={reports} />
          </Col>
          <Col sm={12}>
            <div className="d-flex gap-2">
              <div className="flex-fill">
                <EditorControls.TextInput {...iconProps} rows={5} />
              </div>
              <div className="d-flex align-items-start py-3">
                <StyledDiv dangerouslySetInnerHTML={{ __html: data.icon }} className="border rounded p-2 " />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
