import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.sourcesInvestmentProjects;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row>
          <Col sm={1}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fond'}
              highlighted={highlights.includes('fond')}
              onToggleHighlght={() => onToggleHighlght('fond')}
            >
              {meta.columns.fond.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'targetedPrograms'}
                  highlighted={highlights.includes('targetedPrograms')}
                  onToggleHighlght={() => onToggleHighlght('targetedPrograms')}
                >
                  {meta.columns.targetedPrograms.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'objectsBuilding'}
                  highlighted={highlights.includes('objectsBuilding')}
                  onToggleHighlght={() => onToggleHighlght('objectsBuilding')}
                >
                  {meta.columns.objectsBuilding.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'kdbClassifier'}
                  highlighted={highlights.includes('kdbClassifier')}
                  onToggleHighlght={() => onToggleHighlght('kdbClassifier')}
                >
                  {meta.columns.kdbClassifier.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'kfbClassifier'}
                  highlighted={highlights.includes('kfbClassifier')}
                  onToggleHighlght={() => onToggleHighlght('kfbClassifier')}
                >
                  {meta.columns.kfbClassifier.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumCashTotal'}
                  highlighted={highlights.includes('sumCashTotal')}
                  onToggleHighlght={() => onToggleHighlght('sumCashTotal')}
                >
                  {meta.columns.sumCashTotal.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumCashGenF'}
                  highlighted={highlights.includes('sumCashGenF')}
                  onToggleHighlght={() => onToggleHighlght('sumCashGenF')}
                >
                  {meta.columns.sumCashGenF.label}
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumCashSpecF'}
                  highlighted={highlights.includes('sumCashSpecF')}
                  onToggleHighlght={() => onToggleHighlght('sumCashSpecF')}
                >
                  {meta.columns.sumCashSpecF.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPlanTotal'}
                  highlighted={highlights.includes('sumPlanTotal')}
                  onToggleHighlght={() => onToggleHighlght('sumPlanTotal')}
                >
                  {meta.columns.sumPlanTotal.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPlanGenF'}
                  highlighted={highlights.includes('sumPlanGenF')}
                  onToggleHighlght={() => onToggleHighlght('sumPlanGenF')}
                >
                  {meta.columns.sumPlanGenF.label}
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPlanSpecF'}
                  highlighted={highlights.includes('sumPlanSpecF')}
                  onToggleHighlght={() => onToggleHighlght('sumPlanSpecF')}
                >
                  {meta.columns.sumPlanSpecF.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPrognosisTotal'}
                  highlighted={highlights.includes('sumPrognosisTotal')}
                  onToggleHighlght={() => onToggleHighlght('sumPrognosisTotal')}
                >
                  {meta.columns.sumPrognosisTotal.label}
                </TableHeaderCell>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPrognosisGenF'}
                  highlighted={highlights.includes('sumPrognosisGenF')}
                  onToggleHighlght={() => onToggleHighlght('sumPrognosisGenF')}
                >
                  {meta.columns.sumPrognosisGenF.label}
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'sumPrognosisSpecF'}
                  highlighted={highlights.includes('sumPrognosisSpecF')}
                  onToggleHighlght={() => onToggleHighlght('sumPrognosisSpecF')}
                >
                  {meta.columns.sumPrognosisSpecF.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'noteTemplate'}
              highlighted={highlights.includes('noteTemplate')}
              onToggleHighlght={() => onToggleHighlght('noteTemplate')}
            >
              {meta.columns.noteTemplate.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
