import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField } from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';

const TABLENAME = 'programs';

const coldef = md.dp.indicatorsFactPrint.tables.programs.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "strNumber KTKVK subProgram KPKVK elKFK doc";
  grid-template-columns: 50px repeat(1fr, 5);
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="strNumber">{coldef.strNumber.label}</DivHeader>
      <DivHeader areaName="KTKVK">{coldef.KTKVK.label}</DivHeader>
      <DivHeader areaName="subProgram">{coldef.subProgram.label}</DivHeader>
      <DivHeader areaName="KPKVK">{coldef.KPKVK.label}</DivHeader>
      <DivHeader areaName="elKFK">{coldef.elKFK.label}</DivHeader>
      <DivHeader areaName="doc">{coldef.doc.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="strNumber">
          <InputCodeStyled
            value={row.get('strNumber', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="KTKVK">
          <InputCodeStyled
            fluid
            value={row.get('KTKVK', '')}
          />
        </DivCell>
        <DivCell areaName="subProgram">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="kbpClassifier"
            modelType="cat"
            value={row.get('subProgram', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'subProgram'], v));
            }}
          />
        </DivCell>
        <DivCell areaName="KPKVK">
          <InputCodeStyled
            fluid
            value={row.get('KPKVK', '')}
          />
        </DivCell>
        <DivCell areaName="elKFK">
          {/* {row.get('docByKBP7', new Map()) ? ( */}
          {/*  <Fields.SelectorField */}
          {/*    noBorder */}
          {/*    noBackground */}
          {/*    modelName="kbp7Classifier" */}
          {/*    modelType="cat" */}
          {/*    value={row.get('KPKVK', new Map())} */}
          {/*    onChange={(e, v) => { */}
          {/*      dispatch(changeField([`tables/${TABLENAME}`, rowId, 'KPKVK'], v)); */}
          {/*    }} */}
          {/*    noHierarchy */}
          {/*  /> */}
          {/* ) : ( */}
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="elementKFK"
            modelType="cat"
            value={row.get('elKFK', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elKFK'], v));
            }}
            noHierarchy
          />
          {/* )} */}
        </DivCell>
        <DivCell areaName="doc">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="indicatorFact"
            modelType="doc"
            value={row.get('doc', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'doc'], v));
            }}
            noHierarchy
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TablePrograms = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TablePrograms);
