import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const useParams = () => {
  const [params, setParams] = useState([]);

  const [activeParam, setActiveParam] = useState(null);

  const onParamChange = useCallback(
    (paramName, value) => setParams((oldParams) => oldParams
      .map((op) => (op.name === paramName ? { ...op, ...value } : op))),
    [],
  );

  const paramsHandlers = useMemo(() => ({
    onParamChange,
    setActiveParam,
  }), [onParamChange]);

  return {
    params, setParams, activeParam, paramsHandlers,
  };
};

export const paramPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  ctype: PropTypes.string,
  mandatory: PropTypes.bool,
  plural: PropTypes.bool,
  resource: PropTypes.string,
});
