const frontendURL = '/srv/forecastsWizard/';
const backendURL = '/api/forecasts/liquidity-dksu-info/';
const name = 'Помічник з прогнозування';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
