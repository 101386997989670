import { buildModes } from './common';

// Тут только ОБЛАСТИ!!!!!

export const regionDbOptions = [
  {
    text: 'Вінницька обл.',
    value: '02obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Волинська обл.',
    value: '03obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Днiпропетровська область',
    value: '04obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Донецька область',
    value: '05obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Житомирська область',
    value: '06obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Закарпатська область',
    value: '07obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Запорізька область',
    value: '08obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Івано-Франківська область',
    value: '09obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Киівська область',
    value: '10obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Кіровоградська область',
    value: '11obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Луганська область',
    value: '12obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Львівська область',
    value: '13obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  }, {
    text: 'Миколаївська область',
    value: '14obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Одеська область',
    value: '15obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Полтавська область',
    value: '16obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Рівненська область',
    value: '17obl.ciat.net.ua/BM',
    modes: [buildModes.prod, buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Сумська область',
    value: '18obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тернопільська область',
    value: '19obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Харківська область',
    value: '20obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Херсонська область',
    value: '21obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Хмельницька область',
    value: '22obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Черкаська область',
    value: '23obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чернівецька область',
    value: '24obl.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
];
