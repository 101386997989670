import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.explanationLowEffect;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tasks'}
              highlighted={highlights.includes('tasks')}
              onToggleHighlght={() => onToggleHighlght('tasks')}
            >
              {meta.columns.tasks.label}
            </TableHeaderCell>
          </Col>
          <Col lg={2}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'effectivity'}
              highlighted={highlights.includes('effectivity')}
              onToggleHighlght={() => onToggleHighlght('effectivity')}
            >
              {meta.columns.effectivity.label}
            </TableHeaderCell>
          </Col>
          <Col lg={1}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'level'}
              highlighted={highlights.includes('level')}
              onToggleHighlght={() => onToggleHighlght('level')}
            >
              {meta.columns.level.label}
            </TableHeaderCell>
          </Col>
          <Col lg={1}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'koef'}
              highlighted={highlights.includes('koef')}
              onToggleHighlght={() => onToggleHighlght('koef')}
            >
              {meta.columns.koef.label}
            </TableHeaderCell>
          </Col>
          <Col lg={5}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'explanationLowEffect'}
              highlighted={highlights.includes('explanationLowEffect')}
              onToggleHighlght={() => onToggleHighlght('explanationLowEffect')}
            >
              {meta.columns.explanationLowEffect.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
