/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import md from '../../../../constants/meta/documents/payment';

const StdSelector = ListerFactory({
  modelType: 'doc',
  modelName: 'payment',
  viewType: 'selector',
})(DocTableListerFactory({}));

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
});

function Selector({ filter, ...restProps }) {
  const {
    FO,
  } = useSelector(mapState);

  const flt = useMemo(
    () => [{
      fieldName: md.columns.FI.name,
      value: FO.toJS(),
      ...filter,
    },
    ],
    [FO, filter],
  );

  return (
    <StdSelector
      filter={flt}
      {...restProps}
      noHierarchy
    />
  );
}

Selector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
  })),
};

Selector.defaultProps = {
  filter: [],
};

export default Selector;
