import React from 'react';
import propTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorControls } from '../../basicEditor/editorControls';

function DKSUDates({
  year, setYear, getDKSUDates, logicaPingSuccess,
}) {
  return (
    <Row>
      <Col xxl={4} className="d-flex gap-2 align-items-end">
        <EditorControls.YearInput
          label="Рік"
          controlId="year"
          required
          value={year}
          onChange={(e, v) => setYear(v)}
        />
        <Button type="submit" onClick={getDKSUDates} className="mt-2 " disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faSearch} className="me-2" />
          Пошук
        </Button>
      </Col>

    </Row>
  );
}

DKSUDates.propTypes = {
  year: propTypes.number.isRequired,
  setYear: propTypes.func.isRequired,
  getDKSUDates: propTypes.func.isRequired,
  logicaPingSuccess: propTypes.bool.isRequired,
};

export default DKSUDates;
