import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import { availableGroupsPropType, groupsPropType, getPreviousGroup } from '../../hooks';
import SKDGroupsList from './groupsList';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
  IntoButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';

function GroupEditor({ availableGroups, groups, groupsHandlers }) {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  const [openedGroups, setOpenedGroups] = useState([]);
  const visibleGroups = useMemo(() => availableGroups.reduce((R, col) => [
    ...R,
    col.parent === null && {
      ...col,
      lvl: 0,
      hasChild: !!availableGroups.filter((col2) => col2.parent === col.name).length,
    },
    ...availableGroups.filter((col2) => col2.parent === col.name && openedGroups
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1, hasChild: false })),
  ], []).filter((col) => col), [availableGroups, openedGroups]);

  const onActiveGroupHandler = useCallback(
    (gname) => setActiveGroup(gname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );

  const onSetOpenedGroups = useCallback((val) => {
    if (openedGroups.includes(val)) {
      return setOpenedGroups((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedGroups((prev) => ([...prev, val]));
  }, [openedGroups]);

  const groupDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        groupsHandlers.addGroupHandler(name, gname);
      } else if (type === itemTypes.group) {
        groupsHandlers.swapGroupRowHandler(name, gname);
      }
    },
    [groupsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.group) {
        groupsHandlers.removeGroupHandler(name);
        if (activeGroup === name) setActiveGroup(null);
      }
    },
    [activeGroup, groupsHandlers],
  );
  return (
    <div className="d-flex h-100 overflow-hidden">
      <div className="overflow-hidden flex-fill w-50">
        <AvailableFieldList
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={groupsHandlers.addGroupHandler}
          cardTitle="Доступні группування"
          cardText={(
            <p className="text-justify mb-0">
              використовуються для вибору полів, за якими ви хочете групувати дані.
              Перетягніть нижче наведені поля на область
              <b className="mx-1">&quot;Обрані групування&quot;</b>
              .
              Це дозволяє організувати та підсумовувати ваші дані більш гнучко та детально.
              Групування дозволяє вам розглядати ваші дані з різних сторін, досліджуючи зв`язки
              та тенденції, які могли б залишитися непомітними.
            </p>
          )}
          onDragEnd={avaDragEndHanlder}
          setOpenedItems={onSetOpenedGroups}
          visibleItems={visibleGroups}
          openedItems={openedGroups}
        />
      </div>
      <div className="overflow-hidden d-flex align-items-center px-2 ">
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => {
              groupsHandlers.clearAllGroupsHandler();
              setActiveGroup(null);
            }}
            disabled={!groups.length}
            title="Очистити"
          />
          <LeftButton
            onClick={() => {
              groupsHandlers.removeGroupHandler(activeGroup);
              setActiveGroup(getPreviousGroup(groups, activeGroup));
            }}
            disabled={activeGroup === null}
            title="Видалити"
          />
          <RightButton
            onClick={() => groupsHandlers.addGroupHandler(activeAvaField, activeGroup)}
            disabled={!activeAvaField}
            title="Додати"
          />
          <IntoButton
            onClick={() => groupsHandlers.insertSubgroupHandler(activeAvaField, activeGroup)}
            disabled={!activeAvaField}
            title="Згрупувати"
          />
        </ButtonGroup>
      </div>
      <div className="overflow-hidden flex-fill w-50">
        <SKDGroupsList
          groups={groups}
          activeGroup={activeGroup}
          onClick={onActiveGroupHandler}
          onDblClick={(name) => {
            groupsHandlers.removeGroupHandler(name);
            setActiveGroup(getPreviousGroup(groups, name));
          }}
          cardTitle="Обрані группування"
          cardText={(
            <p className="text-justify mb-0">
              Ви можете групувати за кількома полями одночасно, перетягуючи їх у потрібне місце.
              Звіт автоматично обчислює підсумки для груп.
              Ви можете легко змінювати структуру групування та налаштування звіту, щоб отримати
              необхідні результати. Згруповані дані легше сприймати та розуміти, ніж великі
              таблиці з неструктурованою інформацією, що економить ваш час і зусилля.
            </p>
          )}
          onDragEnd={groupDragEndHanlder}
          onSubGroupClick={groupsHandlers.removeSubgroupHandler}
          onChangeHierarchy={groupsHandlers.onChangeHierarchyHandler}
          onSwapSubgroups={groupsHandlers.swapSubgroupHandler}
        />
      </div>
    </div>
  );
}

GroupEditor.propTypes = {
  availableGroups: availableGroupsPropType.isRequired,
  groups: groupsPropType.isRequired,
  groupsHandlers: PropTypes.shape({
    addGroupHandler: PropTypes.func,
    removeGroupHandler: PropTypes.func,
    swapGroupRowHandler: PropTypes.func,
    insertSubgroupHandler: PropTypes.func,
    removeSubgroupHandler: PropTypes.func,
    clearAllGroupsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
    swapSubgroupHandler: PropTypes.func,
  }).isRequired,
};

export default GroupEditor;
