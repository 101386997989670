import { Map } from 'immutable';

// TODO: Remove this because this is deprecated
const storePathParam = (store) => {
  const winManagerState = store.get('windowsManager', new Map());
  const name = winManagerState.get('name', '');
  const type = winManagerState.get('type', '');
  const currentMatch = winManagerState.get('params', { id: '' });
  const id = currentMatch && currentMatch.id;

  const node = `${type}/${name}/editor`;
  const nodeRep = `${type}/${name}/reportEditor`;
  const nameDp = store.getIn([`${type}/${name}/editor`, id, 'dpEditor'], name);
  return {
    name,
    type,
    id,
    node,
    nameDp,
    nodeRep,
  };
};

export default storePathParam;
