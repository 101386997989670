import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  Title,
  CommandPanelButtonText,
} from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/catalogs/objectsBuilding';
import AttachedFiles from '../../../infoRegs/attachedFiles';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  AddressTP,
  DesignersTP, ListDocTP,
  ListPrwTP,
  ListTemTP,
  OrdinanceDocTP,
  WorksTP,
} from './tablepart';
import CsuOwnerSelectionModal from './csuOwnerSelectionModal';

const LandArea = {
  landOk: { label: 'Земельна ділянка відведена', value: 0 },
  wait: { label: 'Очікується рішення про відведення', value: 1 },
  notLandArea: { label: 'Відведення ділянки не потрібне', value: 2 },
};

const ApprovedProjectStage = {
  landOk: { label: 'Проект', value: 0 },
  wait: { label: 'Робочий проект', value: 1 },
};

const DocFilter = {
  ownLevel: { label: 'Тільки свої', value: 1 },
  upperLevel: { label: 'Верхнього рівня', value: 2 },
  all: { label: 'Всі', value: 3 },
};

const mapState = (store) => {
  const isChiefBuilding = store.getIn(['is_chief_building'], false);

  return {
    isChiefBuilding,
  };
};

function ObjectsBuilding({ data, actions, permissions }) {
  const { isChiefBuilding } = useSelector(mapState);
  const readOnly = !permissions.canChange;

  function ExtraButProject({ ConractNumber }) {
    return (
      <CommandPanelButtonText
        label="Перейти на сторiнку тендеру"
        onClick={() => window.open(ConractNumber.trim(), '_blank')}
      />
    );
  }

  ExtraButProject.propTypes = {
    ConractNumber: PropTypes.string.isRequired,
  };

  const handlerSelector = useCallback(
    async (loadData) => {
      await (actions.onChange({
        [md.columns.CsuOwner.name]: loadData[md.columns.CsuOwner.name],
        [md.columns.CsuOwnerName.name]: loadData[md.columns.CsuOwnerName.name],
      }));
    },
    [actions],
  );

  const MainTab = (
    <Container fluid>
      <Row>
        {!isChiefBuilding && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.parent.label}
              value={data[md.columns.parent.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.parent.name]: value,
              })}
              modelType="cat"
              modelName="objectsBuilding"
              readOnly={readOnly}
            />
          </Col>
        )}
      </Row>
      <Row>
        {!isChiefBuilding && (
          <Col>
            <EditorControls.StringInput
              label={md.columns.code.label}
              value={data[md.columns.code.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.code.name]: value,
              })}
              maxLength={4}
              readOnly={readOnly}
            />
          </Col>
        )}
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Correspondence.label}
            value={data[md.columns.Correspondence.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Correspondence.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const GeneralTab = (
    <Container fluid>
      {isChiefBuilding && (
        <>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.FullName.label}
                value={data[md.columns.FullName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FullName.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={isChiefBuilding
                  ? 'Відповідність стратегічним напрямам розвитку та цільовим програмам'
                  : md.columns.Correspondence.label}
                value={data[md.columns.Correspondence.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Correspondence.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.ElementKFK.label}
            value={data[md.columns.ElementKFK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ElementKFK.name]: value,
            })}
            modelType="cat"
            modelName="elementKFK"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Branch.label}
            value={data[md.columns.Branch.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Branch.name]: value,
            })}
            modelType="cat"
            modelName="industry"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.TypeRepair.label}
            value={data[md.columns.TypeRepair.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TypeRepair.name]: value,
            })}
            modelType="cat"
            modelName="typesRepair"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Deputy.label}
            value={data[md.columns.Deputy.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Deputy.name]: value,
            })}
            modelType="cat"
            modelName="deputies"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>Адреса об&#8242;єкта</Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Districts.label}
            value={data[md.columns.Districts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Districts.name]: value,
            })}
            modelType="cat"
            modelName="districts"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Street.label}
            value={data[md.columns.Street.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Street.name]: value,
            })}
            modelType="cat"
            modelName="streets"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Houses.label}
            value={data[md.columns.Houses.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Houses.name]: value,
            })}
            modelType="cat"
            modelName="houses"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>Перелік адрес</Title>
      </Row>
      <Row>
        <Col>
          <AddressTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={isChiefBuilding
              ? 'Розпорядчий документ про необхідність будівництва (реконструкції)'
              : md.columns.DocForUser.label}
            value={data[md.columns.DocForUser.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DocForUser.name]: value,
            })}
            modelName="laws"
            modelType="cat"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Doc.label}
            value={data[md.columns.Doc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Doc.name]: value,
            })}
            modelName="laws"
            modelType="cat"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>
          Відведення земельної ділянки
          {!isChiefBuilding
                    && (
                      <span>
                        (зазначити номер та дату рішення міської ради,
                        якщо відводити земельну ділянку не потрібно,
                        зазначити про це, вказавши відповідну підставу)
                      </span>
                    )}
        </Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.RadioInput
            value={data[md.columns.LandAreaCondition.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LandAreaCondition.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(LandArea).map(({ value, label }) => (
              { value, display_name: label }
            ))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.LandArea.label}
            value={data[md.columns.LandArea.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LandArea.name]: value,
            })}
            modelName="laws"
            modelType="cat"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.RequestNumber.label}
            value={data[md.columns.RequestNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RequestNumber.name]: value,
            })}
            maxLength={20}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.RequestDate.label}
            value={data[md.columns.RequestDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RequestDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <EditorControls.StringInput
            label={md.columns.CadastrNumber1.label}
            value={data[md.columns.CadastrNumber1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CadastrNumber1.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={1}>
          <EditorControls.StringInput
            label=" "
            value={data[md.columns.CadastrNumber2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CadastrNumber2.name]: value,
            })}
            maxLength={2}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={1}>
          <EditorControls.StringInput
            label=" "
            value={data[md.columns.CadastrNumber3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CadastrNumber3.name]: value,
            })}
            maxLength={3}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={2}>
          <EditorControls.StringInput
            label=" "
            value={data[md.columns.CadastrNumber4.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CadastrNumber4.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={6}>
          <EditorControls.StringInput
            label={md.columns.CadastrNote.label}
            value={data[md.columns.CadastrNote.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CadastrNote.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>
          Розпорядчий документ про затвердження проектно-кошторисної документації,
          позитивний експертний звіт
          {!isChiefBuilding
                    && (
                      <span>
                        (Зазначити назву, N та дату документів.
                        Якщо проект будівництва не затверджений,
                        надати інформацію щодо термінів його затвердження, експертний звіт.)
                      </span>
                    )}
        </Title>
      </Row>
      <Row>
        <Col>
          <OrdinanceDocTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={isChiefBuilding
              ? 'Документ, який дає право на виконання будівельних робіт'
              : md.columns.ApprovedBuildingDoc.label}
            value={data[md.columns.ApprovedBuildingDoc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ApprovedBuildingDoc.name]: value,
            })}
            modelType="cat"
            modelName="laws"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.OwnershipProof.label}
            value={data[md.columns.OwnershipProof.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.OwnershipProof.name]: value,
            })}
            modelType="cat"
            modelName="laws"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.LandRightDoc.label}
            value={data[md.columns.LandRightDoc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LandRightDoc.name]: value,
            })}
            modelType="cat"
            modelName="laws"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const TTH = (
    <Container fluid>
      <Row>
        <Title>
          Період будівництва (реконструкції)
        </Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.StartDate.label}
            value={data[md.columns.StartDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.StartDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.EndDate.label}
            value={data[md.columns.EndDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.EndDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>
          ДОВІДКОВО. Техніко-економічні показники
        </Title>
      </Row>
      <Row>
        <Col>
          <ListTemTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );

  const PVR = (
    <Container fluid>
      <Row>
        <Title>
          ДОВІДКОВО. Проектно-вишукувальні роботи
        </Title>
      </Row>
      <Row>
        <Col>
          <ListPrwTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );

  const ContainerTab = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TenderProcContactName.label}
            value={data[md.columns.TenderProcContactName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TenderProcContactName.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <WorksTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );

  const Additional = (
    <Container>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.EDRPOU.label}
            value={data[md.columns.EDRPOU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.EDRPOU.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        { !isChiefBuilding && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.CsuOwner.label}
              value={data[md.columns.CsuOwner.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.CsuOwner.name]: value,
              })}
              modelType="cat"
              modelName="csu"
              readOnly={readOnly}
            />
          </Col>
        )}
        <Col>
          <EditorControls.StringInput
            label={md.columns.CsuOwnerName.label}
            value={data[md.columns.CsuOwnerName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CsuOwnerName.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ marginBottom: '10px' }}>
            <CsuOwnerSelectionModal
              data={data}
              actions={actions}
              onOk={handlerSelector}
              readOnly={readOnly}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Client.label}
            value={data[md.columns.Client.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Client.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TypeBuilding.label}
            value={data[md.columns.TypeBuilding.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TypeBuilding.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Queue.label}
            value={data[md.columns.Queue.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Queue.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Investor.label}
            value={data[md.columns.Investor.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Investor.name]: value,
            })}
            modelType="cat"
            modelName="investor"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={isChiefBuilding
              ? 'Стан справ на об\'єкті, переліки виконаних робіт та робіт на наступний рік'
              : md.columns.State.label}
            value={data[md.columns.State.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.State.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={isChiefBuilding
              ? 'Соціальна значимість об\'єкта'
              : md.columns.SocialSignificance.label}
            value={data[md.columns.SocialSignificance.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SocialSignificance.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Risks.label}
            value={data[md.columns.Risks.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Risks.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={isChiefBuilding
              ? 'Збільшення кошторисної вартості'
              : md.columns.CostAddBase.label}
            value={data[md.columns.CostAddBase.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CostAddBase.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AttachedFiles
            noStyle
            modelType="cat"
            modelName="objectsBuilding"
            id={data[md.columns.id.name]}
          />
        </Col>
      </Row>
    </Container>
  );

  const Project = (
    <Container fluid>
      <Row>
        <Title>
          Тендер
        </Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.TenderProcType.label}
            value={data[md.columns.TenderProcType.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TenderProcType.name]: value,
            })}
            modelType="cat"
            modelName="tenderProcType"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TenderProcContactName.label}
            value={data[md.columns.TenderProcContactName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TenderProcContactName.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>
          Оголошення про заплановану закупівлю
        </Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.PlannedPurchaseDate.label}
            value={data[md.columns.PlannedPurchaseDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PlannedPurchaseDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TenderNumber.label}
            value={data[md.columns.TenderNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TenderNumber.name]: value,
            })}
            maxLength={30}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <div style={{ marginBottom: '10px' }}>
          <ExtraButProject
            ConractNumber={`https://prozorro.gov.ua/tender/${data[md.columns.TenderNumber.name]}`}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateIssueDoc.label}
            value={data[md.columns.DateIssueDoc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateIssueDoc.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.TenderDate.label}
            value={data[md.columns.TenderDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TenderDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Title>
          Договір
        </Title>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.ContractDate.label}
            value={data[md.columns.ContractDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ContractDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.ConractNumber.label}
            value={data[md.columns.ConractNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ConractNumber.name]: value,
            })}
            maxLength={30}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.SumContract.label}
            value={data[md.columns.SumContract.name]}
            precision={2}
            onChange={(e, value) => actions.onChange({
              [md.columns.SumContract.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.RadioInput
            label="Стадія проектування, яка підлягає затвердженню"
            value={data[md.columns.ApprovedProjectStage.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ApprovedProjectStage.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(ApprovedProjectStage).map(({ value, label }) => (
              { value, display_name: label }
            ))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.ContractDesignWorkDate.label}
            value={data[md.columns.ContractDesignWorkDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ContractDesignWorkDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.ContractDesignWorkNumber.label}
            value={data[md.columns.ContractDesignWorkNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ContractDesignWorkNumber.name]: value,
            })}
            maxLength={30}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.readinessDocInfo.label}
            value={data[md.columns.readinessDocInfo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.readinessDocInfo.name]: value,
            })}
            maxLength={3}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.LetterDate.label}
            value={data[md.columns.LetterDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LetterDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.LetterNumber.label}
            value={data[md.columns.LetterNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.LetterNumber.name]: value,
            })}
            maxLength={40}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DesignersTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );

  const AddNum6 = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.YearInput
            label={md.columns.Year.label}
            value={data[md.columns.Year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.Sum.label}
            value={data[md.columns.Sum.name]}
            precision={2}
            onChange={(e, value) => actions.onChange({
              [md.columns.Sum.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          {/* refactor this dispatch */}
          <EditorControls.NumberInput
            label={md.columns.SumPrognosis.label}
            value={data[md.columns.SumPrognosis.name]}
            precision={2}
            onChange={(e, value) => actions.onChange({
              [md.columns.SumPrognosis.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.YearSum.label}
            value={data[md.columns.YearSum.name]}
            precision={2}
            onChange={(e, value) => actions.onChange({
              [md.columns.YearSum.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`NotShow-${data.id}`}
            label={md.columns.NotShow.label}
            value={data[md.columns.NotShow.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NotShow.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const ObjectDoc = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.RadioInput
            className="px-1  d-flex justify-content-start"
            value={data[md.columns.DocFilter.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DocFilter.name]: value,
              });
              actions.onSR('ПриИзменении_ОтборДок');
            }}
            readOnly={readOnly}
            values={Object.values(DocFilter).map(({ value, label }) => (
              { value, display_name: label }
            ))}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            value={data[md.columns.DocYear.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DocYear.name]: value,
              });
              actions.onSR('ПриИзменении_ОтборДок');
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ListDocTP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Row>
        <Col className="my-5">
          <EditorControls.CheckboxInput
            controlId={`Repair-${data.id}`}
            label={md.columns.Repair.label}
            value={data[md.columns.Repair.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Repair.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.OnDate.label}
            value={data[md.columns.OnDate.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.OnDate.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>

        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Author.label}
            value={data[md.columns.Author.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Author.name]: value,
            })}
            modelType="cat"
            modelName="users"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="general" className="m-2 mt-2 border-bottom">
        <Tab title="Загальнe" eventKey="general">
          {GeneralTab}
        </Tab>
        <Tab title={!isChiefBuilding && 'Основні'} eventKey="mainTab">
          {!isChiefBuilding
                    && MainTab }
          {MainTab}
        </Tab>
        <Tab title="Техніко-економічні показники" eventKey="ttn">
          {TTH}
        </Tab>
        <Tab title={isChiefBuilding ? '' : 'ПВР'} eventKey="pvr">
          {PVR}
        </Tab>
        <Tab title="Підряд" eventKey="containerTab">
          {ContainerTab}
        </Tab>
        <Tab title="Проект" eventKey="project">
          {Project}
        </Tab>
        <Tab title="Додатково" eventKey="additional">
          {Additional}
        </Tab>
        <Tab title={isChiefBuilding ? '' : 'Додаток № 6 до рішення сесії'} eventKey="addNum6">
          {AddNum6}
        </Tab>
        <Tab title={isChiefBuilding ? '' : "Документи по об'єкту"} eventKey="objectDoc">
          {ObjectDoc}
        </Tab>
      </Tabs>
    </Container>
  );
}

ObjectsBuilding.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ObjectsBuilding;
