import React from 'react';

import ReportsPanel from '../../reportsPanel';

function Rep() {
  return (
    <ReportsPanel
      schemaId="finrequsts_analizys_2"
    />
  );
}

export default Rep;
