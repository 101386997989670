import base from './base';
import { periodTypes } from '../common';

const columns = {
  ...base.columns,
  response1: { label: 'response1', name: 'response1', visible: true },
  response2: { label: 'response2', name: 'response2', visible: true },
  note: { label: 'Примітка', name: 'Причемание', visible: true },
  ticket: { label: 'ticket', name: 'ticket', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік', name: 'Год', visible: true },
  isGRK: { label: 'Це ГРК', name: 'ЭтоГлавныйРаспорядитель', visible: true },

  //   data: {
  //     label: 'Додаткові дані',
  //     name: 'ДополнительныеДанные',
  //     visible: false,
  //     logicaErr1: { label: 'Відповідь 1', name: 'response1', visible: true },
  //     logicaErr2: { label: 'Відповідь 2', name: 'response2', visible: true },
  // },

  EDRPOU: { label: '1.1 Ідентифікаційний код юридичної особи (код за ЄДРПОУ)', name: 'ЄДРПОУ', visible: true },
  Name: {
    label: '1.2 Найменування установи (підприємства, організації)',
    name: 'ПолнНаименование',
    visible: true,
  },
  CodeDKSU: { label: '1.3 Код з мережі ДКСУ', name: 'КодМережа', visible: true },
  // КОАТУУ (Державний класифікатор об'єктів адміністративно-територіального устрою України)
  KOATUU: { label: '1.4 КОАТУУ ', name: 'КОАТУУ', visible: true },
  PostCode: { label: '1.5 Поштовий індекс', name: 'ПочтовыйИндекс', visible: true },
  Address: { label: '1.6 Адреса', name: 'Адреса', visible: true },
  PhoneNumber: { label: '1.7 Номер телефону', name: 'Телефон', visible: true },
  Email: { label: '1.8 Адреса електронної пошти (Е-mail) ', name: 'E_mail', visible: true },
  WebSite: { label: '1.9 Вебсайт', name: 'Вебсайт', visible: true },
  Skype: { label: '1.10 Skype (корпоративний)', name: 'Skype', visible: true },
  Director: {
    label: 'Керівник /особа, що виконує обов’язки керівника',
    name: 'Руководитель',
    visible: true,
  },
  Position: { label: '1.12 Посада', name: 'Должность', visible: false },
  Surname: { label: '1.13 Прізвище', name: 'Фамилия', visible: true },
  nameDirector: { label: '1.14 Ім’я', name: 'Имя', visible: true },
  MiddleName: { label: '1.15 По-батькові ', name: 'Отчество', visible: false },
  PhoneNumberDirectorWork: { label: '1.16 Контактний номер телефону (робочий)', name: 'ТелефонРукРобочий', visible: true },
  PhoneNumberDirectorMob: { label: '1.17 Контактний номер телефону (мобільний)', name: 'ТелефонРукМоб', visible: true },
  DirectorEmail: { label: '1.18 Адреса електронної пошти (Е-mail) ', name: 'РуководительЕ_mail', visible: true },
  reasonDirector: {
    label: '1.19 Підстава, відповідно до якої особа виконує обов’язки керівника (у разі відсутності керівника)',
    name: 'ОснованиеРуководилель',
    visible: false,
  },
  DirectorFs: {
    label:
        'Керівник фінансової служби/особа, що виконує обов’язки керівника фінансової служби',
    name:
        'Финансист',
    visible: true,
  },
  PositionFin: { label: '1.20 Посада', name: 'ДолжностьФин', visible: false },
  SurnameFs: { label: '1.21 Прізвище', name: 'ФамилияФин', visible: true },
  nameDirectorFs: { label: '1.22 Ім’я', name: 'ИмяФин', visible: true },
  MiddleNameFs: { label: '1.23 По-батькові ', name: 'ОтчествоФин', visible: false },
  PhoneNumberFsWork: { label: '1.24 Контактний номер телефону (робочий)', name: 'ТелефонРукРобочийФин', visible: true },
  PhoneNumberFsMob: { label: '1.25 Контактний номер телефону (мобільний)', name: 'ТелефонРукМобФин', visible: true },
  DirectorFsEmail: { label: '1.26 Адреса електронної пошти (Е-mail) ', name: 'РуководительЕ_mailФин', visible: true },
  reasonDirectorFs: {
    label: '1.27 Підстава, відповідно до якої особа виконує обов’язки керівника фінансової служби (у разі відсутності керівника фінансової служби)',
    name: 'ОснованиеРуководилельФин',
    visible: false,
  },
  EDRPOU_FO: { label: '4.1 ЄДРПОУ установи, що здійснює функцію виконавчого органу', name: 'ЄДРПОУ_ФО', visible: true },
  LocalCode: {
    label: 'Код місцевого бюджету',
    name: 'КодБюджета',
    visible: true,
  },
  ExecutiveName: { label: '4.2 Найменування установи, що здійснює функцію виконавчого органу', name: 'ПолнНаименованиеФин', visible: true },
  Role: { label: '1.11 Роль установи', name: 'РольФО', visible: true },
  KATOTG: { label: '1.4.1 КАТОТТГ ', name: 'КАТОТГ', visible: true },
  receivedLogica: { label: 'Отримано', name: 'КвитанцияОтправлено', visible: true },
  isApprovedLogica: {
    label: 'Надіслано в Logica',
    name: 'ДокументУтвержденОтправлено',
    visible: true,
    type: 'bool',
  },
};

const tables = {
  participantsMfo: {
    name: 'МФО',
    label: 'Учасники бюджетного процесу місцевого бюджету, визначені МФО',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ' },
      KBP: { label: 'КБП', name: 'КБП' },
      kbpClassifier: { label: 'Класифікатор КБП', name: 'КБПСсылка' },
      KPKV: { label: 'КБП7', name: 'КБП7' },
      KVK: { label: 'КВК', name: 'КВК' },
      kvkClassifier: { label: 'Класифікатор КВК', name: 'КВКСсылка' },
      CodeVV: { label: 'Код ВВ', name: 'КодВВ' },
      Name: { label: 'Найменування установи', name: 'НаименованиеРаспорядителя' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Role: { label: 'Роль розпорядника', name: 'РольРаспорядителя' },
    },
  },
  participantsGrk: {
    name: 'ГРК',
    label: 'Учасники бюджетного процесу, визначені ГРК',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ' },
      KBP: { label: 'КБП', name: 'КБП' },
      kbpClassifier: { label: 'Класифікатор КБП', name: 'КБПСсылка' },
      KPKV: { label: 'КБП7', name: 'КБП7' },
      KVK: { label: 'КВК', name: 'КВК' },
      kvkClassifier: { label: 'Класифікатор КВК', name: 'КВКСсылка' },
      CodeVV: { label: 'Код ВВ', name: 'КодВВ' },
      Name: { label: 'Найменування установи', name: 'НаименованиеРаспорядителя' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Role: { label: 'Роль розпорядника', name: 'РольРаспорядителя' },
    },
  },
};

const definition = {
  label: 'Реєстраційна карта',
  name: 'registrationForm',
  backendName: 'КартаЗаявка',
  columns,
  frontend: 'doc/registrationForm',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'year',
      'budget',
      'FI',
      'isApprovedLogica',
      'note',
      // 'EDRPOU',
      // 'CodeDKSU',
      // 'PhoneNumber',
      // 'Director',
      // 'DirectorFs',
      // 'Address',
      // 'Email',
      // 'WebSite',
      // 'Author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
  defaultPeriodType: periodTypes.year,
};

export default definition;
