import { buildModes } from './common';

// Тут только ГОРОДА-КЛИЕНТЫ БМ!!!!!

export const cityDbOptions = [
  {
    text: 'Вінниця',
    value: 'vinnitsa.ciat.net.ua/BM',
    modes: [buildModes.prod, buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Володимир-Волинський',
    value: 'srv81.ciat.net.ua/VVolynskyBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Дніпро',
    value: 'dnipro.ciat.net.ua/BM/',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Енергодар',
    value: 'srv81.ciat.net.ua/EnergoBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Запоріжжя',
    value: 'zaporizzhya.ciat.net.ua/BM',
    modes: [buildModes.prod, buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Івано-Франківськ',
    value: 'app.ivano-frankivsk.ciat.net.ua/IvFrBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Ірпінь',
    value: 'irpen.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Іршава',
    value: 'srv81.ciat.net.ua/Irshava_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Кагарлык',
    value: 'srv81.ciat.net.ua/KaharlykBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Калуш',
    value: 'ivanofrankivsk.ciat.net.ua/Kalush_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Київ',
    value: 'kyiv.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Ковель',
    value: 'srv81.ciat.net.ua/KovelBM/',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Коломия',
    value: 'srv81.ciat.net.ua/Kolomyia_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Костопіль',
    value: 'srv81.ciat.net.ua/Kostopil_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Кременчуг',
    value: 'kremenchuk.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Львів',
    value: 'lviv.ciat.net.ua/BM',
    modes: [buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Луцьк',
    value: 'lutsk.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Маріуполь',
    value: 'srv81.ciat.net.ua/MariupolBM/uk/',
    modes: [buildModes.prod, buildModes.dev],
    AP: 'office',
  },
  {
    text: 'Мелітополь',
    value: 'srv81.ciat.net.ua/MelitopolBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Миколаїв',
    value: 'srv81.ciat.net.ua/MykolaivBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Нововолинськ',
    value: 'novovolynsk.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Новомосковськ',
    value: 'srv81.ciat.net.ua/NovomoskovskBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Одеса',
    value: 'odessa.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Полтава',
    value: 'poltava.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Покровск',
    value: 'srv81.ciat.net.ua/PokrovskBM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Пулини',
    value: 'srv81.ciat.net.ua/Pulyny_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Рівне',
    value: 'srv81.ciat.net.ua/Rivne_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Теплодар',
    value: 'srv81.ciat.net.ua/TeplodarBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тернопіль',
    value: 'ternopil.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Тетіїв',
    value: 'srv81.ciat.net.ua/Tetiev_OTG_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Трускавець',
    value: 'srv81.ciat.net.ua/TruskavetsBMw',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чернігів',
    value: 'chernigov.ciat.net.ua/BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'Чорноморськ',
    value: 'srv81.ciat.net.ua/Chornomorsk_BM',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  // {
  //   text: 'Хуст',
  //   value: '254407281.ciat.net.ua/Khust_BM',
  //   modes: [buildModes.dev, buildModes.prod],
  //   AP: 'office',
  // },

];
