const frontendURL = '/cat/shortkekv/';
const backendURL = '/api/references/refshortkekv/';
const name = 'Класифікація економічна скорочена (Скорочені КЕКВ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
