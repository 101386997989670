import React, {
  memo, useCallback,
} from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'workersAmount';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col sm={2} className="overflow-hidden">
        <TableRowCell column="categories" highlighted={highlights.includes('categories')}>
          <ItemPicker
            value={row[tableMD.columns.categories.name]}
            modelType="cat"
            modelName="statesContingents"
            onChange={async (e, v) => {
              await onRowChange(
                e,
                { [tableMD.columns.categories.name]: v },
              );
              await onSR('CHANGE_CATEGORIES', { rowId: rowIndex });
            }}
            readOnly={readOnly}
          />
          <Badge variant="primary">{row[tableMD.columns.group.name] && row[tableMD.columns.group.name].repr}</Badge>
        </TableRowCell>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf2P" highlighted={highlights.includes('zf2P')}>
              <NumberInput
                value={row[tableMD.columns.zf2P.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf2P" highlighted={highlights.includes('sf2P')}>
              <NumberInput
                value={row[tableMD.columns.sf2P.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf2P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF2P" highlighted={highlights.includes('zfsF2P')}>
              <NumberInput
                value={row[tableMD.columns.zfsF2P.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF2P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf2F" highlighted={highlights.includes('zf2F')}>
              <NumberInput
                value={row[tableMD.columns.zf2F.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf2F" highlighted={highlights.includes('sf2F')}>
              <NumberInput
                value={row[tableMD.columns.sf2F.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf2F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF2F" highlighted={highlights.includes('zfsF2F')}>
              <NumberInput
                value={row[tableMD.columns.zfsF2F.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF2F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf1P" highlighted={highlights.includes('zf1P')}>
              <NumberInput
                value={row[tableMD.columns.zf1P.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf1P" highlighted={highlights.includes('sf1P')}>
              <NumberInput
                value={row[tableMD.columns.sf1P.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf1P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF1P" highlighted={highlights.includes('zfsF1P')}>
              <NumberInput
                value={row[tableMD.columns.zfsF1P.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF1P.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf1F" highlighted={highlights.includes('zf1F')}>
              <NumberInput
                value={row[tableMD.columns.zf1F.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf1F" highlighted={highlights.includes('sf1F')}>
              <NumberInput
                value={row[tableMD.columns.sf1F.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf1F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF1F" highlighted={highlights.includes('zfsF1F')}>
              <NumberInput
                value={row[tableMD.columns.zfsF1F.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF1F.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF0" highlighted={highlights.includes('zfsF0')}>
              <NumberInput
                value={row[tableMD.columns.zfsF0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF1" highlighted={highlights.includes('zfsF1')}>
              <NumberInput
                value={row[tableMD.columns.zfsF1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                inputClassName="text-primary"
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zfsF2" highlighted={highlights.includes('zfsF2')}>
              <NumberInput
                value={row[tableMD.columns.zfsF2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zfsF2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
