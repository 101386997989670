import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  IdMinFin: { label: 'ИдМинФин', name: 'ИдМинФин', visible: false },
  unitLOGICA: { label: 'Одиниця виміру  Logica', name: 'ЕдИзмЛогика', visible: false },
};

export const units = {
  label: 'Одиницi вимiру',
  name: 'units',
  backendName: 'Едизм',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/units',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default units;
