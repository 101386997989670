import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CheckboxField from '../../../../../field/CheckBox';
import GetTextInput from '../../../../../field/TextInput';
import GetNumberInput from '../../../../../field/NumberInput';
import HorizontalAlignSelector from './fields/backendEnums/horizontalAlignSelector';
import VerticalAlignSelector from './fields/backendEnums/verticalAlignSelector';
import PlacementSelector from './fields/backendEnums/placementSelector';
import BoolSelector from './fields/backendEnums/boolSelector';
import LineField from './fields/lineField';
import ColorField from './fields/colorField';
import FontField from './fields/fontField';

const TextInput = GetTextInput();
const IntInput = GetNumberInput(13);

function ARow({
  value, onChange, label, type,
}) {
  return (
    <tr>
      <td>
        <CheckboxField
          value={value.get('Use', false)}
          onChange={(e, v) => onChange(e, value.set('Use', v))}
        />
      </td>
      <td>
        {label}
      </td>
      <td>
        { type === 'text' && (
        <TextInput
          value={value.get('Value', '')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          noBorder
        />
        ) }
        { type === 'int' && (
        <IntInput
          value={value.get('Value', 0)}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          disableCalculator
        />
        ) }
        { type === 'bool' && (
        <BoolSelector
          value={value.get('Value', false)}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          noBorder
        />
        ) }
        { type === 'horizontalAlign' && (
        <HorizontalAlignSelector
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          noBorder
        />
        ) }
        { type === 'verticalAlign' && (
        <VerticalAlignSelector
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          noBorder
        />
        ) }
        { type === 'placement' && (
        <PlacementSelector
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
          noBorder
        />
        ) }
        { type === 'line' && (
        <LineField
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
        />
        ) }
        { type === 'color' && (
        <ColorField
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
        />
        ) }
        { type === 'font' && (
        <FontField
          value={value.get('Value')}
          onChange={(e, v) => onChange(e, value.set('Value', v))}
        />
        ) }
      </td>
    </tr>
  );
}

ARow.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'int',
    'bool',
    'horizontalAlign',
    'verticalAlign',
    'placement',
    'line',
    'color',
    'font',
  ]),
};

ARow.defaultProps = {
  type: '',
};
export default memo(ARow);
