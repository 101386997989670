import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { ModalButton, ModalDropdownToggle, SModal } from '../../../../basicEditor/stdFilters/style';
import FitlerEditor from './filterEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

function LoadLevelDownButton({
  authority, docDate, year,
  onLoadHandler, disabled, id,
}) {
  const [opened, setOpened] = useState(false);
  const [mode, setMode] = useState(false); // false - с нижнего уровня, true - со своего

  const overrideFilter = useMemo(
    () => ({
      [mode ? 'authority' : 'authority_parent']: authority,
      year,
      executed: true,
      approved: false,
      ...(id ? { exclude_id: id } : {}),
    }),
    [authority, id, mode, year],
  );

  const defaultFilter = useMemo(() => {
    const d = docDate ? new Date(docDate) : new Date();
    return {
      begin_date: d.toISOString().substr(0, 10),
      end_date: docDate,
    };
  }, [docDate]);

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <ModalButton
          variant="primary"
          onClick={() => {
            setMode(false);
            setOpened(true);
          }}
          disabled={disabled}
        >
          Заповнити з даних розпорядників нижчого рівня
        </ModalButton>
        <ModalDropdownToggle split />
        <Dropdown.Menu align="right">
          <Dropdown.Item onClick={() => {
            setMode(true);
            setOpened(true);
          }}
          >
            Заповнити з даних розпорядників власного рівня
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FitlerEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          onOk={(ids) => {
            onLoadHandler(mode, ids);
            setOpened(false);
          }}
        />
      </SModal>
    </>
  );
}

LoadLevelDownButton.propTypes = {
  authority: foreignPropType,
  docDate: PropTypes.string,
  year: PropTypes.number,
  onLoadHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.number,
};

LoadLevelDownButton.defaultProps = {
  authority: null,
  docDate: null,
  year: null,
  disabled: false,
  id: null,
};
export default LoadLevelDownButton;
