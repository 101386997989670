import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows, ProposalRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import useEditorParams from '../../../newEditor/hook/params';
import LoadRequestProposalButton from '../../common/loadRequestsProposalDocs';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, headerProps,
  } = useEditor({
    backendURL: meta.doc.finclosing.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const yearProps = editorHooks.useDateInputProps(
    'year',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const useFinProposal = useMemo(
    () => {
      if (data.authority && data.authority.settings) {
        return 'use_fin_proposal' in data.authority.settings && data.authority.settings.use_fin_proposal;
      } return false;
    },
    [data.authority],
  );
  const overrideFilter = useMemo(
    () => ({
      authority: data.authority,
      year: data.year,
    }),
    [data],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.doc.finclosing.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.YearInput {...yearProps} />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <LoadRequestProposalButton
                  disabled={!permissions.canChange}
                  docDate={data.doc_date}
                  useFinProposal={useFinProposal}
                  overrideFilter={overrideFilter}
                  mode="finorder"
                  onLoadHandler={(rows) => actions.onDraft(data, {
                    load_finclsreq_rows: {
                      rows,
                      clear: true,
                    },
                  })}
                />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="finclosing" id="uncontrolled-tab-example">
          {fields && fields.finclosingrequestrow_set && !useFinProposal && (
          <Tab
            eventKey="finclosing"
            title={fields.finclosingrequestrow_set.label}
          >
            <TabContainer>
              <PlanningRows
                data={data}
                actions={actions}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
          {fields && fields.finclosingproposalrow_set && useFinProposal && (
          <Tab
            eventKey="finclosing"
            title={fields.finclosingproposalrow_set.label}
          >
            <TabContainer>
              <ProposalRows
                data={data}
                actions={actions}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
