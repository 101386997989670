import Styled from 'styled-components';

export const StyledModal = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // overflow: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCalculator = Styled.div`
  border: 1px solid #c6c6c6;
  position: fixed;
  height: 100%;
  width: 100%;
  min-width: 320px;
  min-height: 568px;
  max-width: 414px;
  max-height: 823px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

export const StyledDisplayToolbar = Styled.div`
  position: fixed;
  width: 100%;
  height: 40%;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const StyledDisplayForm = Styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
  background-color: #fff;
  flex-grow: 1;
`;

const StyledDisplayInputs = Styled.textarea`
 width: 100%;
  resize: none;
  font-family: Nunito Sans, Helvetica, Arial, sans-serif;
  border: none;
  outline: none;
  text-align: right;
  background-color: inherit;
`;

export const StyledDisplayFormula = Styled(StyledDisplayInputs)`
  flex-grow: 1;
  font-size: 2rem;
`;

export const StyledDisplayInput = Styled(StyledDisplayInputs)`
  flex-shrink: 0;
  font-size: 1.75rem;
  color: #888;
  white-space: nowrap;
  padding-bottom: 15px;
`;

export const StyledToolbar = Styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  color: #3da4ab;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4rem;
  flex-shrink: 0;
`;

export const StyledToolbarItem = Styled.div`
  transition: all 0.35s ease-out;
`;

export const StyledToolbarItemBS = Styled.span`
  transition: all 0.35s ease-out;
  margin-left: 30px;
  font-size: 1.5rem;
`;

// Buttons

export const StyledButtonsContainer = Styled.div`
  position: fixed;
  width: 100%;
  height: 60%;
  left: 0;
  top: 40%;
  background-color: #fff;
  padding: 0.25rem;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  z-index: 100;
  `;

const defaultButton = Styled.button`
  display: block;
  flex: 1 1 calc(25% - 0.5rem);
  width: 25%;
  margin: 0.25rem;
  font-size: 1.75rem;
  outline: none;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  text-transform: uppercase;
  cursor: pointer;
  color: #444;
  background-color: #f5f5f5;
  transition: background-color 1s ease-out;
  :hover {
    border: 1px solid #aaa;
  }
  :active {
    background-color: rgba(0, 0, 0, 0.8);
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

export const FuncButton = Styled(defaultButton)`
  background-color: #d6d6d6;
  :active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  :hover {
    border: 1px solid #aaa;
  }
`;

export const EqualButton = Styled(defaultButton)`
  color: #f5f5f5;
  background-color: #3da4ab;
  font-weight: 700;
  :active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  `;

export const ClearButton = Styled(defaultButton)`
  color: #f5f5f5;
  font-weight: 700;
  background-color: #fc5d76;
  :active {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const DigitButton = defaultButton;

export const StyledHistoryContainer = Styled.div`
  position: fixed;
  width: calc(75% - 0.25rem);
  height: calc(60% * 4 / 5 - 0.25rem);
  left: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  opacity: 1;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  z-index: 101;
  ${({ hidden }) => (hidden ? ' z-index: 99; opacity: 0;' : '')}
`;

export const StyledHistoryList = Styled.div`
  height: 100%;
  padding-bottom: 50px;
`;

export const StyledHistoryItem = Styled.div`
 text-align: right;
  & hr {
    color: #aaa;
    size: 1px;
  } 
`;

export const StyledHistoryItemFormula = Styled.div`
  color: #888;
  padding: 15px 15px 0 15px;
`;

export const StyledHistoryItemResult = Styled.div`
  color: #3da4ab;
  padding: 0 15px;
  :hover{
    cursor: pointer;
    color: #0e9aa7;
  }
`;

export const StyledBtmButtonContainer = Styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const StyledBtmButtons = Styled.button`
  text-transform: uppercase;
  font-size: 1.15rem;
  font-weight: 700;
  padding: 10px 15px;
  width: 50%;
  text-align: center;
  background-color: #3da4ab;
  outline: none;
  border: none;
  color: #fff;
  transition: background-color 0.35s ease-out;
  :hover{
    cursor: pointer;
    background-color: #0e9aa7;
  }
`;

export const StyledCalculateButton = Styled(StyledBtmButtons)`
  border-left: 1px solid #ddd;
`;
