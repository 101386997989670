const frontendURL = '/rep/reduce_indicatiors_sf/';
const backendURL = '/api/authorityplans/printform/reduce_indicatiors_sf/';
const name = 'Зведення показників СФ';
const sections = [];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
