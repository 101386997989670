import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  street: { label: 'Вулиця', name: 'Улица' },
  house: { label: 'Дім', name: 'Дом' },

};

const definition = {
  label: 'Об\'єкти будівництва за адресою',
  name: 'ObjBuildingAddressList',
  columns,
  frontend: 'rep/ObjBuildingAddressList',
  backendName: 'ОбъектыСтроительстваПоАдресу',
  resultColumns: baseResultColumns,
};

export default definition;
