import React, {
  useMemo, useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { EditorDateInput } from '../../basicEditor/editorControls';
import meta from '../../../meta';
import HTMLPrntFormEditor from '../../HTMLPrntFormEditor';

function Rep() {
  const [date, setDate] = useState(() => new Date().toJSON().substr(0, 10));
  const printData = useMemo(
    () => ({
      settings: { end_date: date },
    }),
    [date],
  );
  return (
    <HTMLPrntFormEditor
      printUrl={meta.rep.commoninStitutionStructureBfn.backendURL}
      printData={printData}
    >
      <Row sm={1}>
        <Col>
          <EditorDateInput
            controlId="date_param"
            label="Дата звіту"
            required
            description="Дата, на яку відображаються дані звіту"
            value={date}
            onChange={(e, v) => setDate(v)}
          />
        </Col>
      </Row>
    </HTMLPrntFormEditor>
  );
}

export default Rep;
