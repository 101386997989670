import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from '../../../components/blanks/common/SoftBadge';

// (1, 'COST'), (2, 'PRODUCT'), (3, 'EFFECTIVE'), (4, 'QUALITY'), (5, 'WORKLOAD'), (6, 'UTILITY');
function TypeInd({ title, bg, name }) {
  return (
    <div style={{ margin: 'auto' }}>
      <SoftBadge
        title={title}
        bg={bg}
        className="me-2"
        style={{ margin: 'auto' }}
      >
        {name}
      </SoftBadge>
    </div>
  );
}

export function TypeIndIcon({ value }) {
  switch (value) {
    // case 0:
    //   return null;
    case 1:
      return (
        <TypeInd
          title="Показник затрат"
          bg="info"
          name="З"
        />
      );
    case 2:
      return (
        <TypeInd
          title="Показник продукту"
          bg="success"
          name="П"
        />
      );
    case 3:
      return (
        <TypeInd
          title="Показники ефективності"
          bg="danger"
          name="Е"
        />
      );
    case 4:
      return (
        <TypeInd
          title="Показник якості"
          bg="warning"
          name="Я"
        />
      );
    case 5:
      return (
        <TypeInd
          title="Показник робочого навантаження"
          bg="primary"
          name="Р"
        />
      );
    case 6:
      return (
        <TypeInd
          title="Показник корисності"
          bg="secondary"
          name="К"
        />
      );
    default:
      return null;
  }
}

export function colorIndicator(id) {
  switch (id) {
    case 0:
      return null;
    case 1:
      // title="Показник затрат"  bg="info"
      // var(--falcon-badge-soft-info-background-color) #d4f2ff
      // return '#e5f7ff';
      return 'bg-soft-info';
    case 2:
      // title="Показник продукту"
      // bg="success" var(--falcon-badge-soft-success-background-color) #ccf6e4
      // return '#e0faef';
      return 'bg-soft-success';
    case 3:
      // title="Показники ефективності" bg="danger"
      // var(--falcon-badge-soft-danger-background-color) '#fad7dd';
      // return '#fce7eb';
      return 'bg-soft-danger';
    case 4:
      // title="Показник якості" bg="warning" var(--falcon-badge-soft-warning-background-color)
      // return '#fef0e8';
      return 'bg-soft-warning';
    case 5:
      // title="Показник робочого навантаження" bg="primary"
      // var(--falcon-badge-soft-primary-background-color) #d5e5fa
      // return '#e6effc';
      return 'bg-soft-primary';
    case 6:
      // title="Показник корисності" bg="secondary"
      // var(----falcon-badge-soft-secondary-background-color) #e3e6ea
      return 'bg-soft-secondary';
      // return '#f9fafd';
    default:
      return null;
  }
}

TypeIndIcon.propTypes = {
  value: PropTypes.number,
};

TypeIndIcon.defaultProps = {
  value: null,
};

TypeInd.propTypes = {
  title: PropTypes.string.isRequired,
  bg: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default { TypeIndIcon, colorIndicator };
