import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/changeDraftAnnualBR';

import { soSelector } from '../../_common/selectors';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function ChangeDraftAnnualBR({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const FI = data[md.columns.FI.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.Year.label}
            value={data[md.columns.Year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.variantBudgetDraft.label}
            value={data[md.columns.variantBudgetDraft.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.variantBudgetDraft.name]: value,
              });
            }}
            modelType="cat"
            modelName="variantBudgetDraft"
            filter={FiOwnerFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

ChangeDraftAnnualBR.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChangeDraftAnnualBR;
