import {
  useRef, useCallback,
} from 'react';

/**
 * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
 * Она сработает только один раз через N миллисекунд после последнего вызова.
 * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
 * первого запуска.
 * @param {function} fn - Функция которая выполнится через указанное время
 * @param {number} delay - указанное время
 * @return {function}

 */

export const debounce = (fn, delay = 250) => {
  let timer = null;
  return (...args) => {
    // const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const useDebounce = (fn, delay = 250) => {
  const timer = useRef(null);
  return useCallback(
    (...args) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(
        () => fn(...args),
        delay,
      );
    },
    [delay, fn],
  );
};
