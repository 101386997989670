const frontendURL = '/cat/kbp/';
const backendURL = '/api/references/refkbp/';
const name = 'Кодифікатор бюджетних програм МБ (КБП)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
