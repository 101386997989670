import allocationplan from './allocationPlan';
import allocationplanchanges from './allocationPlanChanges';
import balancesAccounts from './balancesAccounts';
import bfnAllocation from './bfnAllocation';
import bfnInstitutionStructure from './bfnInstitutionStructure';
import budgetExecution from './budgetExecution';
import budgetPassport from './budgetPassport';
import budgetrequest from './budgetRequest';
import creditProject from './creditProject';
import devBudgetProject from './devBudgetProject';
import devBudgetProjectChanges from './devBudgetProjectChanges';
import disposersMap from './disposersMap';
import disposersMapChanges from './disposersMapChanges';
import documentregistry from './documentregistry';
import draftAnnualTransfert from './draftAnnualTransfert';
import draftAnnualTransfertChanges from './draftAnnualTransfertChanges';
import educationExpenditure from './educationExpenditure';
import expensesproject from './expensesProject';
import expensesprojectchanges from './expensesProjectChanges';
import finclosing from './finСlosing';
import finobligation from './finobligation';
import finorder from './finOrder';
import finrequest from './finRequest';
import finsourceplan from './finSourcePlan';
import finsourceplanchanges from './finSourcePlanChanges';
import forecastAdd1 from './forecastAdd1';
import forecastPrepAdd1 from './forecastPrepAdd1';
import forecastPrepAdd2 from './forecastPrepAdd2';
import freeRem3 from './freeRem3';
import freeRem4 from './freeRem4';
import fundingSourceChanges from './fundingSourceChanges';
import fundingSourceProject from './fundingSourceProject';
import fundsReciept from './fundsReciept';
import healthCareExpenses from './healthCareExpenses';
import incomeproject from './incomeProject';
import incomeprojectchanges from './incomeProjectChanges';
import lawobligation from './lawobligation';
import limitreference from './limitReference';
import liquidity from './liquidity';
import loanProject from './loanProject';
import loanProjectChanges from './loanProjectChanges';
import netCategory from './netCategory';
import netCategoryPlan from './netCategoryPlan';
import netFop from './netFop';
import paymentorder from './paymentorder';
import paymenttrans from './paymenttrans';
import registrationcard from './registrationCard';
import revenueplan from './revenuePlan';
import revenueplanchanges from './revenuePlanChanges';
import transferToLogica from './transferToLogica';
import treasureexpenses from './treasureExepenses';
import finproposal from './finProposal';

const doc = {
  budgetrequest,
  allocationplan,
  allocationplanchanges,
  balancesAccounts,
  bfnAllocation,
  bfnInstitutionStructure,
  budgetExecution,
  budgetPassport,
  creditProject,
  devBudgetProject,
  devBudgetProjectChanges,
  disposersMap,
  disposersMapChanges,
  documentregistry,
  draftAnnualTransfert,
  draftAnnualTransfertChanges,
  educationExpenditure,
  expensesproject,
  expensesprojectchanges,
  finclosing,
  finobligation,
  finorder,
  finrequest,
  finproposal,
  finsourceplan,
  finsourceplanchanges,
  forecastAdd1,
  forecastPrepAdd1,
  forecastPrepAdd2,
  freeRem3,
  freeRem4,
  fundingSourceChanges,
  fundingSourceProject,
  fundsReciept,
  healthCareExpenses,
  incomeproject,
  incomeprojectchanges,
  lawobligation,
  limitreference,
  liquidity,
  liquidityVer2:liquidity,
  loanProject,
  loanProjectChanges,
  netCategory,
  netCategoryPlan,
  netFop,
  paymentorder,
  paymenttrans,
  registrationcard,
  revenueplan,
  revenueplanchanges,
  transferToLogica,
  treasureexpenses,
};

export default doc;
