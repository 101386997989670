import { budgetElChTP } from './budgetElChTP';
import { csuElChTP } from './csuElChTP';
import { typesRepairElChTP } from './typesRepairElChTP';
import { addPropClassifiersChTP } from './addPropClassifiersChTP';

export const ChTP = {
  budgetElChTP,
  csuElChTP,
  typesRepairElChTP,
  addPropClassifiersChTP,
};

export default ChTP;
