import styled from 'styled-components';

export const StyledStamp = styled.svg`
    max-width: 400px;
`;

export const StyledApprovedStamp = styled(StyledStamp)`
    fill: var(--falcon-info);
`;

export const StyledRecievedStamp = styled(StyledStamp)`
    fill: var(--falcon-indigo);
`;

export const StyledFinancedStamp = styled(StyledStamp)`
    fill: var(--falcon-success);
`;
