import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Modal, Card,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Loader } from '../../components/bootStrap';
import CardHeader from '../../components/blanks/common/CardHeader';
import { childrenPropType } from '../../constants/backend/propTypes';
import FalconCloseButton from '../../components/blanks/common/CloseButton';
import PageHeader from '../../components/blanks/common/PageHeader';

function EditorContainer({
  isNew,
  name,
  repr,
  children,
  err,
  isLoading,
  onClearErrors,
  nonFieldErrors,
  onClearNonFieldErrors,
  onClose,
  CommandPanel,
  title,
  subtitle,
  fields,
  changed,

}) {
  const [opened, setOpened] = useState();

  const errView = (msg) => msg.replace('{"detail":"', '').replace('"}', '');

  return !!name && (
    <DndProvider backend={HTML5Backend}>
      <PageHeader
        className="mb-3"
      >
        <CardHeader
          title={isNew ? `Створення: ${name} ${title}` : `${title || repr}`}
          className="cardPadding"
          light={false}
          endEl={CommandPanel}
          role="menubar"
        >
          {subtitle && (
          <h5 className="text-muted text-truncate w-100" title={subtitle}>
            {subtitle}
          </h5>
          )}
        </CardHeader>
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={changed ? () => setOpened(true) : onClose}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <Card className="py-2">
        {err && (
        <Alert dismissible={onClearErrors} onClose={onClearErrors} variant="danger">
          <Alert.Heading>
            {errView(err)}
          </Alert.Heading>
        </Alert>
        )}
        {nonFieldErrors && nonFieldErrors.length !== 0 && (

        <Alert dismissible={onClearNonFieldErrors} onClose={onClearNonFieldErrors} variant="danger">
          <Alert.Heading>
            Помилки заповнення даних:
          </Alert.Heading>
          <ul>
            {nonFieldErrors.filter((el) => !!el).map((nfe, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>
                {
                  (fields[nfe.message] ? fields[nfe.message].label : nfe.message)
                    || nfe
                }
              </li>
            ))}
          </ul>
        </Alert>
        )}
        {isLoading && (
        <Loader text="Завантаження" />
        )}

        <Card.Body
          className="position-relative"
        >
          {children}
        </Card.Body>

        <Modal show={opened} onHide={() => setOpened(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Вийти без запису?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Мабуть, Ви забули зберегти зміни. Вийти без запису змін?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onClose}>Так</Button>
            <Button variant="success" onClick={() => setOpened(false)}>Ні</Button>
          </Modal.Footer>
        </Modal>
      </Card>

    </DndProvider>
  );
}

EditorContainer.propTypes = {
  isNew: PropTypes.bool.isRequired,
  name: PropTypes.string,
  repr: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]).isRequired,
  err: PropTypes.string,
  nonFieldErrors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      header: PropTypes.string,
      message: PropTypes.string,
    }),
    PropTypes.string,
  ])),
  isLoading: PropTypes.bool,
  onClearErrors: PropTypes.func,
  onClearNonFieldErrors: PropTypes.func,
  onClose: PropTypes.func,
  CommandPanel: childrenPropType,
  title: PropTypes.string,
  subtitle: childrenPropType,
  fields: PropTypes.shape({}),
  changed: PropTypes.bool,
};

EditorContainer.defaultProps = {
  repr: '',
  err: null,
  nonFieldErrors: null,
  isLoading: false,
  onClearErrors: null,
  onClearNonFieldErrors: null,
  name: null,
  onClose: null,
  CommandPanel: null,
  title: '',
  subtitle: null,
  fields: {},
  changed: false,
};

export default EditorContainer;
