import React, {
  useState, useCallback, memo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal,
} from 'react-bootstrap';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RequestsLoadingBoostraped from '../../../dataProcessors/requestsLoadingBoostraped';
import { referencePropType } from '../../../newEditor/propTypes';

function LoadRequestsModal({
  onOk,
  disabled,
  date,
  fondObject,
  FI,
  budget,
  importAllNotes,
  importNotes,
  CSU, llabel,
}) {
  const ref = useRef();
  const [modalOpened, setModalOpened] = useState(false);
  const okHandler = useCallback(
    (selector) => {
      setModalOpened(false);
      onOk(selector);
    },
    [onOk],
  );

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setModalOpened(true)}
        disabled={disabled}
        ref={ref}
      >
        <FontAwesomeIcon icon={faFileUpload} className="me-2" />
        {llabel}
      </Button>
      <Modal
        show={modalOpened}
        size="xl"
        onHide={() => setModalOpened(false)}
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть документи для завантаження
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <RequestsLoadingBoostraped
            dataSource="Документ.ФинансированиеЗаявка"
            date={date}
            fondObject={fondObject}
            onLoad={okHandler}
            FI={FI}
            budget={budget}
            importAllNotes={importAllNotes}
            importNotes={importNotes}
            CSU={CSU}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

LoadRequestsModal.propTypes = {
  date: PropTypes.number,
  onOk: PropTypes.func.isRequired,
  fondObject: referencePropType,
  FI: referencePropType,
  budget: referencePropType,
  importAllNotes: PropTypes.bool,
  importNotes: PropTypes.bool,
  CSU: referencePropType,
  disabled: PropTypes.bool,
  llabel: PropTypes.string,
};

LoadRequestsModal.defaultProps = {
  importAllNotes: false,
  disabled: false,
  budget: null,
  CSU: null,
  fondObject: null,
  FI: null,
  date: null,
  importNotes: false,
  llabel: null,
};

export default memo(LoadRequestsModal);
