import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'Период' },
  kind: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
};

const definition = {
  label: 'Аналіз розпису асигнувань обласного бюджету',
  name: 'RegionAssigmentsAnalisys',
  columns,
  frontend: 'rep/regionAssigmentsAnalisys',
  backendName: 'АнализРосписиАссигнованийОбласногоБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
