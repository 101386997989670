import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  DateInput,
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/garanty';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { InputWithDropdown } from '../../../../../../components/styledInputs';

const tablename = 'TABL2';

const tableMD = md.tables[tablename];

const repMonth = {
  m1: { label: 'Погашення боргу', name: 'Погашення боргу' },
  m2: { label: 'Плата за користування кредитом', name: 'Плата за користування кредитом' },
  m3: { label: 'Комісія за обслуговування', name: 'Комісія за обслуговування' },
  m4: { label: 'Комісія за зобов\'язання', name: 'Комісія за зобов\'язання' },
  m5: { label: 'Пеня', name: 'Пеня' },
  m6: { label: 'Штраф', name: 'Штраф' },
  m7: { label: 'Інші платежі', name: 'Інші платежі' },
  m8: { label: 'Плата за надання гарантії', name: 'Плата за надання гарантії' },
};

const repDz = {
  m1: { label: 'Кошти позичальника', name: 'Кошти позичальника' },
  m2: { label: 'Кошти на виконання гарантійних зобов’язань, передбачені у рішенні про місцевий бюджет \n' +
        '(у разі настання гарантійного випадку)',
    name: 'Кошти на виконання гарантійних зобов’язань, передбачені у рішенні про місцевий бюджет \n' +
        '(у разі настання гарантійного випадку)' },
  // m3: { label: 'Позичальник', name: 'Позичальник' },
  // m4: { label: '-пусто-', name: '' },
};

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onT2RXXXXG1SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T2RXXXXG1S.name]: v });
  }, [onRowChange]);

  const onT2RXXXXG4SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T2RXXXXG4S.name]: v });
  }, [onRowChange]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG1S" highlighted={highlights.includes('T2RXXXXG1S')}>
              <DateInput
                onChange={onT2RXXXXG1SChange}
                value={row[tableMD.columns.T2RXXXXG1S.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG2" highlighted={highlights.includes('T2RXXXXG2')}>
              <NumberInput
                value={row[tableMD.columns.T2RXXXXG2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG3S" highlighted={highlights.includes('T2RXXXXG3S')}>
              <InputWithDropdown
                options={Object.values(repMonth)}
                value={row[tableMD.columns.T2RXXXXG3S.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG3S.name]: v })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG4S" highlighted={highlights.includes('T2RXXXXG4S')}>
              <DateInput
                onChange={onT2RXXXXG4SChange}
                value={row[tableMD.columns.T2RXXXXG4S.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG5" highlighted={highlights.includes('T2RXXXXG5')}>
              <NumberInput
                value={row[tableMD.columns.T2RXXXXG5.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG5.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG6S" highlighted={highlights.includes('T2RXXXXG6S')}>
              <ItemPicker
                value={row[tableMD.columns.T2RXXXXG6S.name]}
                modelType="cat"
                modelName="currency"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG6S.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG7" highlighted={highlights.includes('T2RXXXXG7')}>
              <NumberInput
                value={row[tableMD.columns.T2RXXXXG7.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG7.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG8" highlighted={highlights.includes('T2RXXXXG8')}>
              <NumberInput
                value={row[tableMD.columns.T2RXXXXG8.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG8.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T2RXXXXG9S" highlighted={highlights.includes('T2RXXXXG9S')}>
              <InputWithDropdown
                options={Object.values(repDz)}
                value={row[tableMD.columns.T2RXXXXG9S.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T2RXXXXG9S.name]: v })}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T2RXXXXG1S.name]: PropTypes.string,
    [tableMD.columns.T2RXXXXG2.name]: PropTypes.number,
    [tableMD.columns.T2RXXXXG3S.name]: PropTypes.string,
    [tableMD.columns.T2RXXXXG4S.name]: PropTypes.string,
    [tableMD.columns.T2RXXXXG5.name]: PropTypes.number,
    [tableMD.columns.T2RXXXXG6S.name]: referencePropType,
    [tableMD.columns.T2RXXXXG7.name]: PropTypes.number,
    [tableMD.columns.T2RXXXXG8.name]: PropTypes.number,
    [tableMD.columns.T2RXXXXG9S.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
