import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Інформація про стан фінансування з міського бюджету (в розрізі постачальників)',
  name: 'InfoAboutFinancing',
  columns,
  frontend: 'rep/InfoAboutFinancing',
  backendName: 'ИнформацияПроФинансирование',
  resultColumns: baseResultColumns,
};

export default definition;
