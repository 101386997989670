import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import Loader from '../../components/styledWrappedLoader';
import { tryLogin, tryLogout } from '../../api/auth';
import {
  login as loginAction, logout, makeDisconnected, makeConnected, logikaLogin,
} from '../../actions/auth';
import { loadSettings } from '../../actions/favorites';
import Login from '../authorization/login';
import Layout from '../../components/layout';
import { proxyApi, dbOptions } from '../../constants/misc';
import { unregister } from '../../registerServiceWorker';
import { loginMinfin } from './minfin_login';
import { AppContext as MinfinAppContext } from '../../minfin/providers/authProvider';
import minfinApi from '../../minfin/api/req';
import MinfinLogicaDriver from './minfinLogicaDriver';
import WinManagerContextProvider from '../../minfin/providers/WinManagerContext/provider';
import CiatAppContextProvider from '../../minfin/providers/CiatAppContext/provider';

const TICKET_STR = 'ticket';

function LoginManager() {
  const [loading, setLoading] = useState(false);
  const [errMinfinConnect, setErrMinfinConnect] = useState(null);
  const selector = useCallback(
    (store) => ({
      accessToken: store.getIn(['auth', 'accessToken']),
      refreshToken: store.getIn(['auth', 'refreshToken']),
      AP: store.getIn(['auth', 'AP']),
      minfinSrv: store.getIn(['auth', 'sessionOptions', 'ptn_srv'], ''),
      useMinfinAuth: store.getIn(['auth', 'sessionOptions', 'use_minfin_auth'], false),
      logicaTicket: store.getIn(['auth', 'ticket'], false),
    }),
    [],
  );

  const websocket = useRef(null);

  const {
    accessToken,
    AP,
    minfinSrv,
    logicaTicket,
    useMinfinAuth,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const logedIn = !!accessToken;

  const [minfinuser, setMinfinUser] = useState(null);
  const [minfinready, setMinfinReady] = useState(false);
  const minfinTokens = useRef({
    accessToken: null,
    refreshToken: null,
  });

  const tryRefreshMinfinToken = useCallback(
    async (srv) => {
      if (!srv) throw new Error(`Missing minfinsrv: ${srv}`);
      if (minfinTokens.current.refreshToken) {
        setErrMinfinConnect(null);
        const r = await minfinApi.post$('/api/token/refresh', () => ({ minfinSrv: srv }), { refresh: minfinTokens.current.refreshToken });
        if (r.ok) {
          const d = await r.json();
          if (process.env.NODE_ENV === 'development') {
            window.localStorage.setItem('minfinTokens', JSON.stringify({
              refresh: minfinTokens.current.refreshToken,
              access: d.access,
            }));
          }
          minfinTokens.current.accessToken = d.access;
          return () => ({
            access: d.access,
          });
        } else {
          setErrMinfinConnect(`${r.status} ${r.statusText}`);
        }
      }
      // tryLogout();
      return false;
    },
    [],
  );

  const getAuth = useCallback(
    () => ({
      access: minfinTokens.current.accessToken,
      onRefresh: () => tryRefreshMinfinToken(minfinSrv),
      minfinSrv,
    }),
    [minfinSrv, tryRefreshMinfinToken],
  );

  const minfinContextValue = useMemo(
    () => ({
      currentUser: minfinuser,
      accessToken: minfinTokens.current.accessToken,
      refreshToken: minfinTokens.current.refreshToken,
      auth: getAuth,
      backend: minfinSrv.endsWith('/') ? minfinSrv.substring(0, minfinSrv.length - 1) : minfinSrv,
    }),
    [getAuth, minfinSrv, minfinuser],
  );

  const connectWebSocket = useCallback(
    (lAP, accToken) => {
      const url = proxyApi[lAP].replace('http://', 'ws://')
        .replace('https://', 'wss://');
      websocket.current = new WebSocket(`${url}ws/ping/`);
      websocket.current.onopen = () => {
        dispatch(makeConnected());
        const s = () => websocket.current.send(JSON.stringify({
          type: 'init',
          access: accToken,
        }));
        if (websocket.current.readyState === websocket.current.CONNECTING) {
          setTimeout(s, 1000);
        } else {
          s();
        }
      };
      websocket.current.onclose = () => {
        dispatch(makeDisconnected());
        console.log('Disconected. Trying for reconnect...');
        setTimeout(connectWebSocket, 1000, lAP, accToken);
      };
      websocket.current.onerror = (e) => console.error('websocket error', e);
      websocket.current.onmessage = (message) => console.log('message', message);
    },
    [dispatch],
  );

  // Спрашивать "Закрыть сайт" только на prod
  if (process.env.NODE_ENV === 'production') {
    window.onbeforeunload = () => {
      unregister();
      // localStorage.removeItem('ticket');
      dispatch(logout());
    };
  }

  const checkMifin = useCallback((d) => {
    if (d.session_params.ptn_srv && d.session_params.use_minfin_auth) {
      setMinfinReady(false);
      // const mfUserName = d.session_params.is_admin ? d.login : `${d.login}@${d.session_params.IB_id.replace(/(.*)([/\\])(.*)/, '$1.$3')}`;
      const mfUserName = `${d.login}@${d.session_params.IB_id.replace(/(.*)([/\\])(.*)/, '$1.$3')}`;
      loginMinfin((d.session_params.ptn_srv.slice(-1) === '/') ? d.session_params.ptn_srv : `${d.session_params.ptn_srv}/`, mfUserName, d.passwd)
        .then(
          ({ tokens, userData }) => {
            if (process.env.NODE_ENV === 'development') {
              window.localStorage.setItem('minfinTokens', JSON.stringify(tokens));
              window.localStorage.setItem('minfinUser', JSON.stringify(userData));
            }
            setMinfinUser(userData);
            setMinfinReady(true);
            minfinTokens.current.accessToken = tokens.access;
            minfinTokens.current.refreshToken = tokens.refresh;
          },
        )
        .catch(
          (e) => {
            console.log('Error connecting to minfin', e);
            setErrMinfinConnect(e.message);
          },
        );
    } else {
      setMinfinReady(true);
    }
  }, []);

  // session_params.ptn_srv
  const handleCreateNewSession = useCallback(
    /**
     * @desc Creating new session & write them into localStorage
     * @func
     * @param d {object}
     * @param d.session_params {object}
     * @param d.access_token {string}
     * @param d.refresh_token {string}
     * @param d.AP {string}
     * @param d.login {string}
     * @param d.passwd {string}
     */
    (d) => {
      const payload = {
        sessionOptions: d.session_params,
        accessToken: d.access_token,
        refreshToken: d.refresh_token,
        AP: d.AP,
      };

      if (process.env.NODE_ENV === 'production') {
        // Sentry.setExtra('AppVerion', APP_VERSION);

        if (payload.sessionOptions) {
          Sentry.setUser(payload.sessionOptions.user);
          Sentry.setExtra('sessionOptions', payload.sessionOptions);
        }
      }

      dispatch(loginAction(payload, d.access_token));
      connectWebSocket(d.AP, d.access_token);
      dispatch(loadSettings());

      if (process.env.NODE_ENV === 'development') {
        window.localStorage.setItem('refreshToken', d.refresh_token);
        window.localStorage.setItem('accessToken', d.access_token);
        window.localStorage.setItem('AP', d.AP);
        window.localStorage.setItem('sessionOptions', JSON.stringify(d.session_params));
      }
      checkMifin(d);
      setLoading(false);
    },
    [checkMifin, connectWebSocket, dispatch],
  );

  const restoreDevSession = useCallback(
    () => {
      const oldRefreshToken = window.localStorage.getItem('refreshToken');
      const oldAccessToken = window.localStorage.getItem('accessToken');
      const oldAP = window.localStorage.getItem('AP');
      let oldSessionOptions;
      try {
        oldSessionOptions = JSON.parse(window.localStorage.getItem('sessionOptions'));
      } catch (e) {
        oldSessionOptions = null;
      }
      if (oldRefreshToken && oldAccessToken && oldAP && oldSessionOptions) {
        const mfTkns = window.localStorage.getItem('minfinTokens');
        const mfUsr = window.localStorage.getItem('minfinUser');
        if (mfTkns && mfUsr) {
          const t = JSON.parse(mfTkns);
          minfinTokens.current.accessToken = t.access;
          minfinTokens.current.refreshToken = t.refresh;
          setMinfinUser(JSON.parse(mfUsr));
        }
        dispatch(loginAction({
          sessionOptions: oldSessionOptions,
          accessToken: oldAccessToken,
          refreshToken: oldRefreshToken,
          AP: oldAP,
        }));
        connectWebSocket(oldAP, oldAccessToken);
        dispatch(loadSettings());
      }
    },
    [connectWebSocket, dispatch],
  );
  const checkTicket = useCallback(
    async (ticket) => {
      const str = decodeURIComponent(window.escape(atob(ticket)));
      const p = JSON.parse(str.substr(1));
      const cAP = dbOptions.filter((d) => d.value === p.db)
        .reduce((R, r) => r.AP, null);

      const r = await tryLogin({
        srv: p.db,
        login: p.user,
        pwd: p.passwd,
        backEnd: proxyApi[cAP],
      });
      if (r.ok) {
        const d = await r.json();
        handleCreateNewSession({
          ...d,
          AP: cAP,
          login: p.user,
          passwd: p.passwd,
        });
        return true;
      }
      return false;
    },
    [handleCreateNewSession],
  );

  useEffect(
    () => {
      const ticket = window.location.search.substr(1)
        .split('&')
        // eslint-disable-next-line no-confusing-arrow
        .reduce((R, r) => r.substr(0, TICKET_STR.length) === TICKET_STR
          ? decodeURIComponent(r.substr(TICKET_STR.length + 1))
          : R, null);

      if (ticket) {
        checkTicket(ticket);
      } else if (process.env.NODE_ENV === 'development') {
        restoreDevSession();
      }
    },
    [checkTicket, restoreDevSession],
  );

  const handleTerminateSession = useCallback(
    async () => {
      setLoading(false);
      await tryLogout({
        backEnd: proxyApi[AP],
        accessToken,
      });
      dispatch(logout());
      if (process.env.NODE_ENV === 'development') {
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('sessionOptions');
        window.localStorage.removeItem('minfinTokens');
        window.localStorage.removeItem('minfinUser');
      }

      window.location.replace('/');
    },
    [AP, accessToken, dispatch],
  );

  useEffect(
    () => {
      if (!logicaTicket) {
        const t = localStorage.getItem('ticket');
        if (t) dispatch(logikaLogin(t));
      }
    },
    [dispatch, logicaTicket],
  );

  useEffect(() => {
    const pingMinfin = async () => {
      const r = await minfinApi.get(`/api/auth/currentuser/${minfinuser.id}/`, getAuth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      return r;
    };

    if (logedIn && !!minfinSrv && useMinfinAuth) {
      if (minfinuser && minfinuser?.id) {
        pingMinfin()
          .then(() => setErrMinfinConnect(null))
          .catch((e) => setErrMinfinConnect(e.message));
      } else {
        // setErrMinfinConnect(`Відбувається підключення до серверу ${minfinSrv}`);
      }
    }
  }, [getAuth, logedIn, minfinSrv, minfinuser, useMinfinAuth]);

  const clearSessionMinfin = useCallback(
    () => {
      window.localStorage.removeItem('minfinTokens');
      setMinfinUser(null);
      minfinTokens.current.accessToken = null;
      minfinTokens.current.refreshToken = null;
    },
    [],
  );

  const tryLogoutMinfin = useCallback(
    () => {
      setLoading(true);
      minfinApi.get('/api-auth/logout', () => ({ access: minfinTokens.current.accessToken }))
        .then((r) => {
          if (r.ok) {
            clearSessionMinfin();
            setLoading(false);
            setErrMinfinConnect(null);
            localStorage.removeItem('ticket');
          } else {
            throw new Error(`${r.status} ${r.statusText}`);
          }
        })
        .catch((e) => {
          setErrMinfinConnect(e.message);
        })
        .finally(() => setLoading(false));
    },
    [clearSessionMinfin],
  );
  const minfinerr = useMemo(
    () => {
      if (!errMinfinConnect) return null;
      return `Виникла помилка підключення до серверу ${minfinSrv}: ${errMinfinConnect}`
    },
    [errMinfinConnect, minfinSrv]
  )
  return (
    <>
      {errMinfinConnect && logedIn && minfinSrv && minfinready && (
        <Alert
          variant="info"
          className="mb-0 rounded-0"
          dismissible
          onClose={() => setErrMinfinConnect(null)}
        >
          <Alert.Heading>
            Виникла помилка підключення до серверу {minfinSrv}
          </Alert.Heading>
          <p className="mb-0">
           {errMinfinConnect}
          </p>
          <p>
            <Button
              size="sm"
              className="mt-2"
              variant="outline-info"
              onClick={handleTerminateSession}
            >
              Підключитись
            </Button>
          </p>
        </Alert>
      )}
      <Loader isLoading={loading} style={{ height: '100vh' }}>
        {logedIn && minfinready ? (
          <MinfinAppContext.Provider value={minfinContextValue}>
            <CiatAppContextProvider
              currentUser={minfinuser}
              tokens={minfinTokens}
              logoutHandler={tryLogoutMinfin}
              refreshHandler={tryRefreshMinfinToken}
              currentUserRefresh={checkMifin}
              minfinSrv={minfinSrv}
            >
              <WinManagerContextProvider>
                <MinfinLogicaDriver>
                  <Layout
                    handleLogout={handleTerminateSession}
                  />
                </MinfinLogicaDriver>
              </WinManagerContextProvider>
            </CiatAppContextProvider>
          </MinfinAppContext.Provider>
        ) : (
          <Login onLogin={handleCreateNewSession} addErr={minfinerr} />
        )}
      </Loader>
    </>
  );
}

export default LoginManager;
