import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faCalendarAlt as icon } from '@fortawesome/free-solid-svg-icons';
import enums from '../../../../../constants/meta/enums';
import { PeriodForm } from '../../../../../components/fields/boostrap';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function SetPeriodButton({
  disabled, onChange, label, value,
}) {
  const [opened, setOpened] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  useEffect(
    () => setLocalValue(value),
    [value],
  );
  return (
    <>
      <DefaultCPButton
        icon={icon}
        onClick={() => setOpened(true)}
        label={label}
        disabled={disabled}
      />
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть період
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeriodForm value={localValue} onChange={(e, v) => setLocalValue(v)} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={(e) => {
              setOpened(false);
              onChange(e, localValue);
            }}
          >
            Встановити
          </Button>
          <Button variant="secondary" onClick={() => setOpened(false)}>Закрити</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

SetPeriodButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    variant: PropTypes.oneOf(Object.values(enums.PeriodSelection).map((v) => v.name)),
  }),
};

SetPeriodButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Обрати період',
};

export default SetPeriodButton;
