import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/documents/indicatorPassport';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';

const IPSelector = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(DocTableListerFactory({}));

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
});

function PassportSelector({ filter, ...restProps }) {
  const { sessionFO } = useSelector(mapState);

  const FO = filter
    .filter((f) => f.fieldName === md.columns.FI.name)
    .reduce((R, f) => f.value, sessionFO);

  const flt = useMemo(
    () => {
      const preparedFilter = filter
        .map((f) => {
          if (typeof f.value === 'object' && !Array.isArray(f.value) && Map.isMap(f.value)) {
            return { ...f, value: f.value.toJS() };
          }
          return f;
        });
      return [
        ...preparedFilter.filter(
          (f) => ![md.columns.FI.name].includes(f.fieldName),
        ),
        {
          fieldName: md.columns.FI.name,
          value: FO,
        },
      ];
    },
    [filter, FO],
  );
  return (
    <IPSelector
      filter={flt}
      {...restProps}
    />
  );
}

PassportSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
  })),
};

PassportSelector.defaultProps = {
  filter: [],
};

export default PassportSelector;
