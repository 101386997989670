import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchResults from './searchResults';

const AnimatedDiv = styled.div`
  flex: 1 1 auto;
  &>.input-group {
    width: 15rem;
    transition: width .25s ease-in;
  }
  &:focus-within .input-group{
    width: 100%;
  }
`;

function SearchInput({ disabled, value, onChange }) {
  const ref = useRef();
  const onClose = useCallback(
    () => {
      ref.current.focus();
    },
    [],
  );

  return (
    <AnimatedDiv className="flex-grow-1 px-1">
      <InputGroup>
        <FormControl ref={ref} value={value} onChange={onChange} disabled={disabled} placeholder="&#128269;Пошук" />

        <Button variant="outline-secondary" onClick={onClose} disabled={disabled}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>

      </InputGroup>
      <SearchResults inputValue={value} />
    </AnimatedDiv>
  );
}
SearchInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  disabled: false,
  value: '',
};

export default SearchInput;
