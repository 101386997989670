import React from 'react';
import { Route } from 'react-router-dom';
import LinkComponent from '../../../components/windowsManager/link';
import md from '../../meta';
import Editors from '../../containers/instances/stdeditor/index';

function minfinRoutes(sessionParams) {
  if (!sessionParams.get('use_minfin_auth', false)) return null;

  return (
    <Route
      exact
      path={`${md.doc.transferToLogica.frontendURL}:id/`}
      element={(
        <LinkComponent
          currentComponent={Editors.doc.transferToLogica}
          windowsName="Відправка в логіку"
          type="doc"
          name="transferToLogica"
        />
      )}
    />
  );
}

export default minfinRoutes;
