import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMD } from './md';
import { paramConverter } from '../../../api/req';

/**
 * useListerActions
 * @param backendURL {string}
 * @param viewType {string}
 * @param backPermissions {{
 *   canNew: boolean,
 *   canNewFolder: boolean,
 *   canCopy: boolean,
 *   canEdit: boolean,
 *   canDelete: boolean,
 *   canExecute: boolean,
 * }}
 * @param items [{
 *   id: string,
 *   repr: string.
 * }]
 * @param refedSelectedRows {{ current: string[] }}
 * @param openedItems string[]
 * @param setOpenedItems function(string[]),
 * @param onExecuteItems function(string[], string[]),
 * @param onDeleteItems function(string[], string[]),
 * @param onUnexecuteItems function(string[], string[]),
 * @param setShowDeleted function(boolean),
 * @param onUnloadChildren function(string),
 * @param onLoadChildren function(string),
 * @param setSearch function(string),
 * @param debouncedBackendSearch function(string),
 * @param setPeriod function,
 * @param onSetSettings function,
 * @param onChoice function,
 * @param setLocalFilter function,
 * @param onApproveItems function,
 * @param onReload function,
 * @param defaults {{}} - Значения по умолчанию для нового элемента,
 * @param clearMessages function,
 * @param setFilterOpened functon,
 * @param useHierarchyPagination boolean,
 * @returns {{
 *  onToggle: function,
 *  onSetFilter: function,
 *  onRefresh: function,
 *  onExecute: function,
 *  onSearch: function,
 *  onToggleShowDeleted: function,
 *  onNew: function,
 *  onCopy: function,
 *  onEdit: function,
 *  onDelete: function,
 *  onUnexecute: function,
 *  onSetPeriod: function,
 *  onNewFolder: function,
 *  onRowDoubleClick: function,
 *  onApproveItems: function,
 *  onUnApproveItems: function,
 *  onApproveStatus: function,
 *  onApprovenavigate: function,
 *  onCloseApproveStatus: function,
 *  onClearMessages: function,
 *  onDeleteMessage: function,
 *  onToggleFilterOpened: function,
 *  }}
 */
const useListerActions = ({
  backendURL,
  // eslint-disable-next-line no-unused-vars
  viewType,
  backPermissions,
  items,
  refedSelectedRows,
  openedItems,
  setOpenedItems,
  onExecuteItems,
  onDeleteItems,
  onUnexecuteItems,
  setShowDeleted,
  onUnloadChildren,
  onLoadChildren,
  setSearch,
  debouncedBackendSearch,
  setPeriod,
  onSetSettings,
  onChoice,
  setLocalFilter,
  onReload,
  // eslint-disable-next-line no-unused-vars
  defaults,
  clearMessages,
  deleteMessage,
  setFilterOpened,
  useHierarchyPagination,
}) => {
  const md = useMD(backendURL);

  const navigate = useNavigate();

  // текущий parent_id
  const defaultParent = items
    .filter((item) => backPermissions.canHierarchy
      && refedSelectedRows.current.includes(item.id)
      && (!backPermissions.foldersUsed || item.is_group))
    .reduce((R, item) => item.id, null);

  const newParams = useMemo(
    () => paramConverter({
      ...defaultParent ? { default_parent: defaultParent } : {},
    }),
    [defaultParent],
  );

  const onNew = useCallback(
    () => {
      if (!backPermissions.canNew) return null;
      return navigate(`${md.frontendURL}create/?${newParams}`);
    },
    [backPermissions.canNew, md.frontendURL, navigate, newParams],
  );
  const onCopy = useCallback(
    () => backPermissions.canEdit && refedSelectedRows.current.forEach((rowId) => navigate(`${md.frontendURL}create/?copyFrom=${rowId}`)),
    [backPermissions.canEdit, md.frontendURL, navigate, refedSelectedRows],
  );

  const onNewFolder = useCallback(
    () => backPermissions.canNewFolder && navigate(`${md.frontendURL}create/?group=1&${newParams}`),
    [backPermissions.canNewFolder, md.frontendURL, navigate, newParams],
  );
  const onEdit = useCallback(
    () => backPermissions.canEdit && refedSelectedRows.current.forEach((rowId) => navigate(`${md.frontendURL}${rowId}/`)),
    [backPermissions.canEdit, md.frontendURL, navigate, refedSelectedRows],
  );
  const onDelete = useCallback(
    () => {
      if (backPermissions.canDelete) {
        const reprs = items
          .filter((item) => refedSelectedRows.current.includes(item.id))
          .reduce((R, row) => ({ ...R, [row.id]: row.repr }), {});
        onDeleteItems(refedSelectedRows.current, reprs);
      }
    },
    [backPermissions.canDelete, items, onDeleteItems, refedSelectedRows],
  );
  const onExecute = useCallback(
    () => {
      if (backPermissions.canExecute) {
        const reprs = items
          .filter((item) => refedSelectedRows.current.includes(item.id))
          .reduce((R, row) => ({ ...R, [row.id]: row.repr }), {});
        onExecuteItems(refedSelectedRows.current, reprs);
      }
    },
    [backPermissions.canExecute, items, onExecuteItems, refedSelectedRows],
  );

  const onUnexecute = useCallback(
    () => {
      if (backPermissions.canExecute) {
        const reprs = items
          .filter((item) => refedSelectedRows.current.includes(item.id))
          .reduce((R, row) => ({ ...R, [row.id]: row.repr }), {});
        onUnexecuteItems(refedSelectedRows.current, reprs);
      }
    },
    [backPermissions.canExecute, items, onUnexecuteItems, refedSelectedRows],
  );
  const onToggleShowDeleted = useCallback(
    () => setShowDeleted((v) => !v),
    [setShowDeleted],
  );

  const onRefresh = useCallback(
    () => onReload(),
    [onReload],
  );

  const onSearch = useCallback(
    (e, v) => {
      setSearch(v);
      debouncedBackendSearch(v);
    },
    [debouncedBackendSearch, setSearch],
  );
  const onSetPeriod = useCallback(
    (e, v) => {
      setPeriod(v);
      onSetSettings({
        period: v,
      });
    },
    [onSetSettings, setPeriod],
  );

  const onToggle = useCallback(
    (e, rowId) => {
      if (openedItems.includes(rowId)) {
        // Закрыли элемент
        const newOpenedItems = openedItems.filter((id) => id !== rowId);
        setOpenedItems(newOpenedItems);
        if (useHierarchyPagination) {
          onUnloadChildren(rowId);
        }
      } else {
        setOpenedItems([...openedItems, rowId]);
        if (useHierarchyPagination) {
          onLoadChildren(rowId);
        }
      }
    },
    [onLoadChildren, onUnloadChildren, openedItems, setOpenedItems, useHierarchyPagination],
  );

  const onRowDoubleClick = useCallback(
    (e, rowId) => {
      if (onChoice) {
        e.preventDefault();
        e.stopPropagation();
        const selectedItem = items
          .filter((item) => item.id === rowId)
          .reduce((R, row) => row, null);
        onChoice(
          e,
          selectedItem,
        );
      } else {
        onEdit();
      }
    },
    [items, onChoice, onEdit],
  );

  const onSetFilter = useCallback(
    (name, value, use) => setLocalFilter((old) => ({
      ...old,
      [name]: { value, use },
    })),
    [setLocalFilter],
  );

  const onToggleFilterOpened = useCallback(
    () => setFilterOpened((o) => !o),
    [setFilterOpened],
  );

  const actions = useMemo(
    () => ({
      onNew,
      onCopy,
      onNewFolder,
      onEdit,
      onDelete,
      onExecute,
      onUnexecute,
      onToggleShowDeleted,
      onSearch,
      onSetPeriod,
      onToggle,
      onRowDoubleClick,
      onSetFilter,
      onRefresh,
      onClearMessages: clearMessages,
      onDeleteMessage: deleteMessage,
      onToggleFilterOpened,
    }),
    [
      onNew, onCopy, onNewFolder, onEdit, onDelete, onExecute, onUnexecute, onToggleShowDeleted,
      onSearch, onSetPeriod, onToggle, onRowDoubleClick, onSetFilter, onRefresh, clearMessages,
      deleteMessage, onToggleFilterOpened],
  );

  return actions;
};

export default useListerActions;
