import React from 'react';
import { Link } from 'react-router-dom';
import md from '../../../meta/srv/ImportTreasureFiles';

function DKSUToast() {
  return (
    <div>
      <h6 className="text-white">Доступні нові дані з ДКСУ</h6>
      <p>
        Ви можете завантажити їх за
        <Link className="text-white ms-2" to={md.frontendURL}>посиланням</Link>
      </p>
    </div>
  );
}

export default DKSUToast;
