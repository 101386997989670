import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: true },
  EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: true },
  NetworkCode: { label: 'КодМережа', name: 'КодМережа', visible: true },
  owner: { label: 'Владелец', name: 'Владелец', visible: true },
};

export const recipients = {
  label: 'Отримувачi',
  name: 'recipients',
  backendName: 'Получатели',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/recipients',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'owner',
      'ID77',
      'EDRPOU',
      'NetworkCode',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default recipients;
