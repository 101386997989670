import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { moneysStingOptions } from '../../../../../../constants/common';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/changesToAssignmentPlanning';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { comparisonTypes, emptyUid } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'general';

const tableMD = md.tables[tablename];

const QUARTERS = [...Array(4)].map((_, i) => i);

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
  onlyYear, CSUinH, isShowKdbKfb, inBR, FI, isFilterCsuElemKFK, showTargetedProgram,
}) {
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const ElemKFKFilter = useMemo(
    () => [...FIOwnerFilter, ...(isFilterCsuElemKFK ? [{
      fieldName: 'Распорядитель',
      comparisonType: comparisonTypes.inList,
      value: [
        { id: emptyUid, repr: '' },
        row[tableMD.columns.CSU.name] || { id: emptyUid, repr: '' },
      ],
    }] : [])],
    [FIOwnerFilter, isFilterCsuElemKFK, row],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, month, isBR, value) => {
      const colName = tableMD.columns[`Sum${isBR ? 'BR' : ''}${month}`].name;
      const colNameBR = tableMD.columns[`SumBR${month}`].name;
      const totColName = tableMD.columns[`Sum${isBR ? 'BR' : ''}`].name;
      const tot = [...Array(12)].reduce(
        (R, _, i) => R + (row[tableMD.columns[`Sum${isBR ? 'BR' : ''}${i + 1}`].name] || 0),
        value - (row[colName] || 0),
      );
      const totColNameBr = tableMD.columns.SumBR.name;
      const totBr = [...Array(12)].reduce(
        (R, _, i) => R + (row[tableMD.columns[`SumBR${i + 1}`].name] || 0),
        value - (row[colName] || 0),
      );

      onRowChange(e, {
        [colName]: value,
        [colNameBR]: inBR ? value : 0,
        [totColName]: tot,
        [totColNameBr]: inBR ? totBr : 0,
      });
    },
    [inBR, onRowChange, row],
  );

  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKEKVChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKEKV.name]: v });
      await onSR('CHANGE_KEKV_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKDB.name]: v });
      await onSR('CHANGE_KDB_GENERAL_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_GENERAL_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={4}
        sm={12}
        className="d-flex"
      >
        <Row sm={isShowKdbKfb && (!inBR || onlyYear) ? 2 : 1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
              {!CSUinH && (
                <ItemPicker
                  value={row[tableMD.columns.CSU.name]}
                  modelType="cat"
                  modelName="csu"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSU.name]: v })}
                  filter={CSUFIlter}
                  noHierarchy
                  readOnly={readOnly}
                />
              )}
            </TableRowCell>
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={onKFKChange}
                filter={ElemKFKFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KPKVCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKEKV.name]}
                modelType="cat"
                modelName="elementKEKV"
                onChange={onKEKVChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KEKVCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            <TableRowCell column="targetedProgram" highlighted={highlights.includes('targetedProgram')}>
              {showTargetedProgram && (
                <ItemPicker
                  value={row[tableMD.columns.targetedProgram.name]}
                  modelType="cat"
                  modelName="targetedPrograms"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.targetedProgram.name]: v })}
                  readOnly={readOnly}
                />
              )}
            </TableRowCell>
          </Col>
          {isShowKdbKfb && (
            <Col>
              <TableRowCell column="elementKDB" highlighted={highlights.includes('elementKDB')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKDB.name]}
                  modelType="cat"
                  modelName="elementKDB"
                  onChange={onKDBChange}
                  filter={FIOwnerFilter}
                  readOnly={readOnly}
                  canErase
                  prepend={(
                    <InputGroup.Text>
                      {row[tableMD.columns.KDBCode.name]}
                    </InputGroup.Text>
                  )}
                />
              </TableRowCell>
              <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKFB.name]}
                  modelType="cat"
                  modelName="elementKFB"
                  onChange={onKFBChange}
                  filter={FIOwnerFilter}
                  readOnly={readOnly}
                  canErase
                  prepend={(
                    <InputGroup.Text>
                      {row[tableMD.columns.KFBCode.name]}
                    </InputGroup.Text>
                  )}
                />
              </TableRowCell>
            </Col>
          )}
        </Row>
      </Col>
      <Col xl={8} sm={12}>
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q}>
              {[...Array(3)].map((_, m) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col as={TableRowCell} column={`Sum${q * 3 + m + 1}`} highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}>
                    <NumberInput
                      value={row[tableMD.columns[`Sum${q * 3 + m + 1}`].name]}
                      precision={2}
                      onChange={(e, v) => onSumChange(e, q * 3 + m + 1, false, v)}
                      readOnly={readOnly}
                    />
                  </Col>
                  {inBR && (
                    <Col as={TableRowCell} column={`SumBR${q * 3 + m + 1}`} highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}>
                      <NumberInput
                        value={row[tableMD.columns[`SumBR${q * 3 + m + 1}`].name]}
                        precision={2}
                        // className="bg-light"
                        inputClassName="font-italic"
                        onChange={(e, v) => onSumChange(e, q * 3 + m + 1, true, v)}
                        readOnly={readOnly}
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className=" d-flex justify-content-start flex-row flex-sm-column">
            {onlyYear ? (
              <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                <NumberInput
                  value={row[tableMD.columns.Sum.name]}
                  precision={2}
                  onChange={(e, v) => onSumChange(e, 1, false, v)}
                  readOnly={readOnly}
                />
              </TableRowCell>
            ) : (
              <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                <div className="font-weight-bold w-100 text-right text-md-center">
                  {row[tableMD.columns.Sum.name] && row[tableMD.columns.Sum.name].toLocaleString('uk', moneysStingOptions)}
                </div>
              </TableRowCell>
            )}
            {inBR && (
              <>
                {onlyYear ? (
                  <TableRowCell column="SumBR" highlighted={highlights.includes('SumBR')}>
                    <NumberInput
                      value={row[tableMD.columns.SumBR.name]}
                      precision={2}
                      inputClassName="font-italic"
                      onChange={(e, v) => onSumChange(e, 1, true, v)}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                ) : (
                  <TableRowCell column="SumBR" highlighted={highlights.includes('SumBR')}>
                    <div className="font-weight-bold w-100 text-right text-md-center font-italic">
                      {row[tableMD.columns.SumBR.name] && row[tableMD.columns.SumBR.name].toLocaleString('uk', moneysStingOptions)}
                    </div>
                  </TableRowCell>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.CSU.name]: referencePropType,
    [tableMD.columns.elementKFK.name]: referencePropType,
    [tableMD.columns.KPKVCode.name]: PropTypes.string,
    [tableMD.columns.elementKEKV.name]: referencePropType,
    [tableMD.columns.KEKVCode.name]: PropTypes.string,
    [tableMD.columns.elementKDB.name]: referencePropType,
    [tableMD.columns.targetedProgram.name]: referencePropType,
    [tableMD.columns.KDBCode.name]: PropTypes.string,
    [tableMD.columns.elementKFB.name]: referencePropType,
    [tableMD.columns.KFBCode.name]: PropTypes.string,
    [tableMD.columns.Sum.name]: PropTypes.number,
    [tableMD.columns.SumBR.name]: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [tableMD.columns[`Sum${i + 1}`].name]: PropTypes.number,
      [tableMD.columns[`SumBR${i + 1}`].name]: PropTypes.number,
    }), {}),
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  onlyYear: PropTypes.bool.isRequired,
  CSUinH: PropTypes.bool.isRequired,
  isShowKdbKfb: PropTypes.bool.isRequired,
  inBR: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  isFilterCsuElemKFK: PropTypes.bool.isRequired,
  showTargetedProgram: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  showTargetedProgram: false,
};

export default memo(TPRow);
