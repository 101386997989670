import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/financing';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  isComposeSettingsChecked,
  activeObjectsBuilding,
  activeBR,
  ShowAccounts,
  CSUinH,
  Transferting,
  TransfertingZFToSF,
  ShowSourcesFinInDocFin,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>
          {!CSUinH
          && (
            <Col sm={12} xl={4} className="border-right">
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'CSU'}
                highlighted={highlights.includes('CSU')}
                onToggleHighlght={() => onToggleHighlght('CSU')}
              >
                {meta.columns.CSU.label}
              </TableHeaderCell>
            </Col>
          )}
          <Col sm={12} xl={CSUinH ? 8 : 6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSUAccount'}
              highlighted={highlights.includes('CSUAccount')}
              onToggleHighlght={() => onToggleHighlght('CSUAccount')}
            >
              {meta.columns.CSUAccount.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={CSUinH ? 4 : 2}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Sum'}
              highlighted={highlights.includes('Sum')}
              onToggleHighlght={() => onToggleHighlght('Sum')}
            >
              {meta.columns.Sum.label}
            </TableHeaderCell>
          </Col>
        </Row>

        {TransfertingZFToSF && ShowAccounts
      && (
        <Row noGutters>
          <Col sm={12} xl={4} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFB'}
              highlighted={highlights.includes('elementKFB')}
              onToggleHighlght={() => onToggleHighlght('elementKFB')}
            >
              {meta.columns.elementKFB.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFBcred'}
              highlighted={highlights.includes('elementKFBcred')}
              onToggleHighlght={() => onToggleHighlght('elementKFBcred')}
            >
              {meta.columns.elementKFBcred.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={4} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'AnalyticalAccountCosts'}
              highlighted={highlights.includes('AnalyticalAccountCosts')}
              onToggleHighlght={() => onToggleHighlght('AnalyticalAccountCosts')}
            >
              {meta.columns.AnalyticalAccountCosts.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'AnalyticalAccount'}
              highlighted={highlights.includes('AnalyticalAccount')}
              onToggleHighlght={() => onToggleHighlght('AnalyticalAccount')}
            >
              {meta.columns.AnalyticalAccount.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'R3261x'}
              highlighted={highlights.includes('R3261x')}
              onToggleHighlght={() => onToggleHighlght('R3261x')}
            >
              {meta.columns.R3261x.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'R3262x'}
              highlighted={highlights.includes('R3262x')}
              onToggleHighlght={() => onToggleHighlght('R3262x')}
            >
              {meta.columns.R3262x.label}
            </TableHeaderCell>
          </Col>
        </Row>
      )}

        <Row noGutters>
          <Col
            xl={4}
            lg={(activeObjectsBuilding && !Transferting) ? 8 : 12}
            sm={12}
            className="d-flex flex-column border-right"
          >
            <Row noGutters>
              <Col>

                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'elementKFK'}
                  highlighted={highlights.includes('elementKFK')}
                  onToggleHighlght={() => onToggleHighlght('elementKFK')}
                >
                  {meta.columns.elementKFK.label}
                </TableHeaderCell>
                {!Transferting
              && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'elementKEKV'}
                  highlighted={highlights.includes('elementKEKV')}
                  onToggleHighlght={() => onToggleHighlght('elementKEKV')}
                >
                  {meta.columns.elementKEKV.label}
                </TableHeaderCell>
              )}
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'WriteOff'}
                  highlighted={highlights.includes('WriteOff')}
                  onToggleHighlght={() => onToggleHighlght('WriteOff')}
                >
                  {meta.columns.WriteOff.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>

          {activeObjectsBuilding && !Transferting
    && (
      <Col xl={2} lg={4} sm={12} className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'BuildObgect'}
          highlighted={highlights.includes('BuildObgect')}
          onToggleHighlght={() => onToggleHighlght('BuildObgect')}
        >
          {meta.columns.BuildObgect.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'TypeWork'}
          highlighted={highlights.includes('TypeWork')}
          onToggleHighlght={() => onToggleHighlght('TypeWork')}
        >
          {meta.columns.TypeWork.label}
        </TableHeaderCell>
      </Col>
    )}

          <Col xl={(activeObjectsBuilding && !Transferting) ? 6 : 8} sm={12}>
            <Row noGutters>
              {!Transferting
            && (
              <Col className="border-right" sm={12} lg={((activeBR || activeObjectsBuilding) && !ShowAccounts) ? 4 : 6}>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'FinObligation'}
                  highlighted={highlights.includes('FinObligation')}
                  onToggleHighlght={() => onToggleHighlght('FinObligation')}
                >
                  {meta.columns.FinObligation.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'Balance'}
                  highlighted={highlights.includes('Balance')}
                  onToggleHighlght={() => onToggleHighlght('Balance')}
                >
                  {meta.columns.Balance.label}
                </TableHeaderCell>
              </Col>
            )}

              <Col className="border-right" sm={12} lg={((activeBR || activeObjectsBuilding) && !ShowAccounts) ? 4 : 6}>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NoteStr'}
                  highlighted={highlights.includes('NoteStr')}
                  onToggleHighlght={() => onToggleHighlght('NoteStr')}
                >
                  {meta.columns.NoteStr.label}
                </TableHeaderCell>
                {!Transferting && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NoteDoc'}
                  highlighted={highlights.includes('NoteDoc')}
                  onToggleHighlght={() => onToggleHighlght('NoteDoc')}
                >
                  {meta.columns.NoteDoc.label}
                </TableHeaderCell>
                )}

              </Col>

              {Transferting && (
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NoteDoc'}
                  highlighted={highlights.includes('NoteDoc')}
                  onToggleHighlght={() => onToggleHighlght('NoteDoc')}
                >
                  {meta.columns.NoteDoc.label}
                </TableHeaderCell>
              </Col>
              )}

              {/*    {(activeBR || activeObjectsBuilding) && !ShowAccounts */}
              {/* && ( */}
              <Col sm={12} lg={4}>
                {/* <TableHeaderCell */}
                {/*  className="text-center text-xl-left" */}
                {/*  active={activeCol === 'WriteOff'} */}
                {/*  highlighted={highlights.includes('WriteOff')} */}
                {/*  onToggleHighlght={() => onToggleHighlght('WriteOff')} */}
                {/* > */}
                {/*  {meta.columns.WriteOff.label} */}
                {/* </TableHeaderCell> */}
                {!Transferting && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NumberOfPayment'}
                  highlighted={highlights.includes('NumberOfPayment')}
                  onToggleHighlght={() => onToggleHighlght('NumberOfPayment')}
                >
                  {meta.columns.NumberOfPayment.label}
                </TableHeaderCell>
                )}
              </Col>
              {/* )} */}
              {Transferting && (
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NumberOfPayment'}
                  highlighted={highlights.includes('NumberOfPayment')}
                  onToggleHighlght={() => onToggleHighlght('NumberOfPayment')}
                >
                  {meta.columns.NumberOfPayment.label}
                </TableHeaderCell>
              </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          {activeObjectsBuilding && Transferting
    && (
      <>
        <Col lg={6} sm={12} className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'BuildObgect'}
            highlighted={highlights.includes('BuildObgect')}
            onToggleHighlght={() => onToggleHighlght('BuildObgect')}
          >
            {meta.columns.BuildObgect.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'TypeWork'}
            highlighted={highlights.includes('TypeWork')}
            onToggleHighlght={() => onToggleHighlght('TypeWork')}
          >
            {meta.columns.TypeWork.label}
          </TableHeaderCell>
        </Col>
      </>
    )}
        </Row>
        {ShowSourcesFinInDocFin && (
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeFinSources'}
              highlighted={highlights.includes('codeFinSources')}
              onToggleHighlght={() => onToggleHighlght('codeFinSources')}
            >
              {meta.columns.codeFinSources.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finSources'}
              highlighted={highlights.includes('finSources')}
              onToggleHighlght={() => onToggleHighlght('finSources')}
            >
              {meta.columns.finSources.label}
            </TableHeaderCell>
          </Col>
        </Row>
        )}
        <Row noGutters>
          <Col sm={12} xl={(isComposeSettingsChecked && !ShowAccounts) ? 6 : 8} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Note'}
              highlighted={highlights.includes('Note')}
              onToggleHighlght={() => onToggleHighlght('Note')}
            >
              {meta.columns.Note.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} lg={(isComposeSettingsChecked && !ShowAccounts) ? 6 : 12} xl={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'DocFinancingRequest'}
              highlighted={highlights.includes('DocFinancingRequest')}
              onToggleHighlght={() => onToggleHighlght('DocFinancingRequest')}
            >
              {meta.columns.DocFinancingRequest.label}
            </TableHeaderCell>
          </Col>

          {isComposeSettingsChecked && !ShowAccounts && (
          <Col sm={12} lg={6} xl={2}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'IsReturn'}
              highlighted={highlights.includes('IsReturn')}
              onToggleHighlght={() => onToggleHighlght('IsReturn')}
            >
              {meta.columns.IsReturn.label}
            </TableHeaderCell>
          </Col>
          )}

        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  isComposeSettingsChecked: PropTypes.bool,
  activeObjectsBuilding: PropTypes.bool,
  activeBR: PropTypes.bool,
  ShowAccounts: PropTypes.bool,
  CSUinH: PropTypes.bool,
  Transferting: PropTypes.bool,
  TransfertingZFToSF: PropTypes.bool,
  ShowSourcesFinInDocFin: PropTypes.bool.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  isComposeSettingsChecked: false,
  activeObjectsBuilding: false,
  activeBR: false,
  ShowAccounts: false,
  CSUinH: false,
  Transferting: false,
  TransfertingZFToSF: false,
};

export default memo(TPHeader);
