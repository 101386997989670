import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Col, Container, Modal, Row, Tab, Tabs,
} from 'react-bootstrap';
import {
  Dod1TP, Dod5TP, Dod7TP, Dod8TP, DocsTP,
} from './tabs';
import md from '../../../../constants/meta/documents/liquidity';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import enums from '../../../../constants/meta/enums';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function LiquidityTabs({
  data, actions, permissions, setMessage,
}) {
  const readOnly = !permissions.canChange;
  const [open, setOpen] = useState(false);
  const onClickCalc = async () => {
    setOpen(false);
    setMessage('');
    await (actions.onSR('Calculate'));
    setMessage('Розрахунок завершився');
  };

  // const [key, setKey] = useState('form2');
  return (
    <>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Body>
          При перерахунку всі попередньо заповнені дані в додатку 1 очистяться. Продовжити?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={onClickCalc}
          >
            Так
          </Button>
          <Button
            variant="danger"
            onClick={() => setOpen(false)}
          >
            Ні
          </Button>
        </Modal.Footer>
      </Modal>
      <Tabs className="m-0">
        <Tab title={md.tables.dod1.label} eventKey="dod1">
          <Container fluid>
            <Row>
              <Col className="mt-4" sm={2}>
                <DefaultCPButton
                  variant="info"
                  label="Розрахувати згідно методу"
                  onClick={() => setOpen(true)}
                />
              </Col>
              <Col>
                <EditorControls.RadioInput
                  className="px-1  d-flex justify-content-start"
                  label={md.columns.method.label}
                  value={data[md.columns.method.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.method.name]: value,
                  })}
                  readOnly={readOnly}
                  values={Object.values(enums.LiquidityCalcMethod).map(({ name, label }) => (
                    { value: name, display_name: label }
                  ))}
                />
              </Col>
            </Row>
          </Container>
          <Dod1TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
        {/* <Tab title={md.tables.dod2.label} eventKey="dod2"> */}
        {/*  <Dod2TP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.dod3.label} eventKey="dod3"> */}
        {/*  <Dod3TP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.dod4.label} eventKey="dod4"> */}
        {/*  <Dod4TP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        <Tab title={md.tables.dod5.label} eventKey="dod5">
          <Dod5TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
        {/* <Tab title={md.tables.dod6.label} eventKey="dod6"> */}
        {/*  <Dod6TP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        <Tab title={md.tables.dod7.label} eventKey="dod7">
          <Dod7TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
        <Tab title={md.tables.dod8.label} eventKey="dod8">
          <Dod8TP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
        <Tab title={md.tables.docs.label} eventKey="docs">
          <DocsTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      </Tabs>
    </>
  );
}

LiquidityTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default LiquidityTabs;
