import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R01G3: { label: 'R01G3', name: 'R106G3' },
  R01G4: { label: 'R01G3', name: 'R106G4' },
  R01G5: { label: 'R01G3', name: 'R106G5' },
  R01G6: { label: 'R01G3', name: 'R106G6' },
  R01G7: { label: 'R01G3', name: 'R106G7' },
  R02G3: { label: 'R01G3', name: 'R107G3' },
  R02G4: { label: 'R01G3', name: 'R107G4' },
  R02G5: { label: 'R01G3', name: 'R107G5' },
  R02G6: { label: 'R01G3', name: 'R107G6' },
  R02G7: { label: 'R01G3', name: 'R107G7' },
  R03G3: { label: 'R01G3', name: 'R108G3' },
  R03G4: { label: 'R01G3', name: 'R108G4' },
  R03G5: { label: 'R01G3', name: 'R108G5' },
  R03G6: { label: 'R01G3', name: 'R108G6' },
  R03G7: { label: 'R01G3', name: 'R108G7' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол1', name: 'Кол2' },
      col3: { label: 'Кол1', name: 'Кол3' },
      col4: { label: 'Кол1', name: 'Кол4' },
      col5: { label: 'Кол1', name: 'Кол5' },
      col6: { label: 'Кол1', name: 'Кол6' },
      col7: { label: 'Кол1', name: 'Кол7' },
      col8: { label: 'Кол1', name: 'Кол8' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd6Printing',
  backendName: 'ПечатьПрогнозДод6',
  label: 'Граничні показники видатків бюджету та надання кредитів з бюджету головним розпорядникам коштів  (Додаток 6)',
  frontend: 'dp/forecastAdd6Printing',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
