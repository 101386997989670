import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import TableComponents from './GeneralTP';

const TABLENAME = 'general';

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
  FI: state.getIn([storePathParam(state).node, storePathParam(state).id, 'headerForm', 'FI']),
  Budget: state.getIn([storePathParam(state).node, storePathParam(state).id, 'headerForm', 'Budget']),
  CSUInH: state.getIn([storePathParam(state).node, storePathParam(state).id, 'headerForm', 'IncludeCSUIntoHead'], false),
});

const TP = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
  TableComponents.FooterComponent,
);

export default connect(mapState)(TP);
