import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function SocialMenuItem() {
  return (
    <UseMenuHeader label="Соціальні виплати">
      <Boxes items={NavPanel.social.boxes} />
    </UseMenuHeader>
  );
}

export default SocialMenuItem;
