/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import { getColor, rgbaColor } from '../../../../../components/blanks/helpers/utils';
import CardHeader from '../../../../../components/blanks/common/CardHeader';
import useDiagramShower from '../../diagramHook';
import { moneysOneDigitStingOptions } from '../../../../../constants/moneys';
import valueFormatter from '../../const/valueFormatter';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent,
]);

const transformBackendData = (data, cDt) => {
  const cYear = cDt.substring(0, 4);
  const year1_ = cYear - 1;
  const year2_ = cYear - 2;

  const transformedData = [['За роками', `${year2_} рік (факт)`, `${year1_} рік (факт)`, `${cYear} рік (план)`]];

  Object.entries(data).forEach(([kdb, years]) => {
    const row = [kdb];
    row.push(years[year2_]?.amount || 0);
    row.push(years[year1_]?.amount || 0);
    row.push(years[cYear]?.amount || 0);
    transformedData.push(row);
  });

  return transformedData;
};

const getOption = (data, cDt, qData) => {
  const cYear = cDt.substring(0, 4);
  const year1_ = cYear - 1;
  const year2_ = cYear - 2;
  const nameKdb = {};

  Object.entries(qData).forEach(([kdb, years]) => {
    nameKdb[kdb] = years.name;
  });

  return {
    color: [
      rgbaColor(getColor('info'), 0.6),
      getColor('primary'),
      rgbaColor(getColor('warning'), 0.4),
    ],
    dataset: { source: data },
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter(params) {
        return `<div className="fw-semi-bold">${params.seriesName}</div><div className="fs--1 text-600"><strong>${params.name} ${nameKdb[params.name]}:</strong> ${params.value[params.componentIndex + 1].toLocaleString('uk', moneysOneDigitStingOptions)}</div>`;
      },
    },
    legend: {
      data: [`${year2_} рік (факт)`, `${year1_} рік (факт)`, `${cYear} рік (план)`],
      left: 'left',
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      textStyle: { color: getColor('gray-700') },
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        color: getColor('gray-400'),
        formatter: (params) => params,
      },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'line',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200'),
          type: 'line',
          width: 0.5,
        },
      },
      axisTick: { show: false },
      boundaryGap: true,
    },
    yAxis: {
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200'),
          type: 'dashed',
        },
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: (value) => valueFormatter(value),
      },
    },
    series: [
      {
        type: 'bar',
        barWidth: '10%', // Keeping bars a bit wider for balance
        barGap: '30%',
        label: { show: false },
        z: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0],
        },
      },
      {
        type: 'bar',
        barWidth: '10%',
        barGap: '30%',
        label: { show: false },
        z: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0],
        },
      },
      {
        type: 'bar',
        barWidth: '10%',
        barGap: '30%',
        label: { show: false },
        z: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0],
        },
      },
    ],
    grid: {
      right: '0',
      left: '85px', // Increase left padding for Y-axis labels
      bottom: '10%',
      top: '12%', // Reduce top to provide more space for the Y-axis labels
    },
  };
};

function DynamicKdb({ data, cDt }) {
  const transformedData = transformBackendData(data, cDt);

  const { containerChartRef, show } = useDiagramShower({});
  return (
    <Card className="h-100">
      <CardHeader
        title="Порівняння з минулими роками"
        titleTag="h6"
        className="py-2"
        light
        // endEl={(
        //   <Form.Select size="sm">
        //     <option>За 3 роки</option>
        //     <option>За 4 роки</option>
        //     <option>За 5 роки</option>
        //   </Form.Select>
        // )}
      />
      <Card.Body className="h-100 w-100" ref={containerChartRef}>
        {show && (
          <ReactEChartsCore
            echarts={echarts}
            option={getOption(transformedData, cDt, data)}
            style={{ height: '100%', minHeight: '15.625rem' }}
            opts={{ renderer: 'svg' }}
          />
        )}
      </Card.Body>
    </Card>
  );
}

DynamicKdb.propTypes = {
  data: PropTypes.shape([]).isRequired,
  cDt: PropTypes.string,
};

DynamicKdb.defaultProps = {
  cDt: null,
};

export default DynamicKdb;
