import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import api from '../../../api/req';
import { CiatAppContext, WinManagerContext } from '../../../providers';
import { getObjectDiff } from '../../../common/diff';
import { useMD } from '../../newLister/hooks/md';

export const HEADER_KEY = '__HEADER__';

function ItemSaveException(message) {
  this.message = message;
  this.name = 'Item Save Exception';
}

function buildErrors({
  non_field_errors: nonFieldErrors,
  executing_errors: executingErrors,
  ...errorData
}, fields) {
  const getPlainErrors = (ers, flds, prefix = '') => Object.keys(ers).reduce((R, fldName) => {
    if (Array.isArray(ers[fldName])) {
      return [
        ...R,
        ...ers[fldName].map((fldErr) => `${prefix} ${flds[fldName].label}: ${fldErr}`),
      ];
    }
    if (typeof ers[fldName] === 'object' && ers[fldName] !== null) {
      return [
        ...R,
        ...Object.entries(ers[fldName]).map(([key, value]) => (
          value.map((v) => `${flds[fldName]?.children[key] ? flds[fldName].children[key].label : key}: ${v}`)
        )),
      ];
    }
    return [...R, `${prefix} ${flds[fldName].label}: ${ers[fldName]}`];
  }, []);
  const headerFields = Object.keys(fields)
    .filter((f) => !fields[f].child)
    .reduce((R, f) => ({ ...R, [f]: fields[f] }), {});
  const headerErrors = Object.keys(errorData)
    .filter((f) => f in headerFields)
    .reduce((R, f) => ({ ...R, [f]: errorData[f] }), {});

  const tpFields = Object.keys(fields)
    .filter((f) => fields[f].child)
    .reduce((R, f) => ({ ...R, [f]: fields[f].child.children }), {});

  const tpErrors = Object.keys(errorData)
    .filter((f) => f in tpFields)
    .reduce((R, f) => ({ ...R, [f]: errorData[f] }), {});

  const plainErrors = [
    ...getPlainErrors(headerErrors, headerFields),
    ...Object.keys(tpErrors)
      .reduce((R, tpName) => [
        ...R,
        ...tpErrors[tpName]
          .map((rowErr, i) => ({ errors: rowErr, rowN: i + 1 }))
          .filter((row) => !!Object.keys(row.errors))
          .reduce((R2, row) => [
            ...R2,
            ...getPlainErrors(row.errors, tpFields[tpName], `${fields[tpName].label}, рядок №${row.rowN},`),
          ], []),
      ], []),
  ];

  const nfe = [
    ...nonFieldErrors || [],
    ...executingErrors || [],
    ...plainErrors,
  ];

  const fieldErrors = {
    ...errorData,
    ...Object.keys(tpErrors)
      .reduce((R, tpName) => ({
        ...R,
        [tpName]: tpErrors[tpName]
          .map((rowErr) => Object.keys(rowErr).reduce((R2, f) => {
            let r = null;
            if (Array.isArray(rowErr[f])) r = rowErr[f];
            else if (typeof rowErr[f] === 'object' && rowErr[f] !== null) r = [...Object.values(rowErr[f])];
            if (!r) return R2;
            return { ...R2, [f]: r };
          }, {})),
      }), {}),
  };
  return {
    fields: fieldErrors,
    nonFieldErrors: nfe.length ? nfe : null,
  };
}

/**
 * @param editorParams Параметры hook редактора
*  @param editorParams.backendURL {string} тип модели
*  @param editorParams.id {string} - ИД,
*  @param editorParams.reason {string} - Основание (при вводе на основании),
*  @param editorParams.isGroup {boolean} - Это группа (при создании группы),
*  @param editorParams.copyFrom {string} - Ид копируемого элемента,
*  @param editorParams.onSaveCallBack (function) - callback при сохранении документа,
*  @param editorParams.onCloseCallBack (function) - callback при закрытии документа,
*  @param editorParams.defaults {{}} - Значения по умолчанию  для нового объекта,
*  @param editorParams.readOnlyGetter {function(@param data {})} - функция,
*                  которая вычисляет значение аттрибута readOnly,
* }}
 *
 * @returns {{
 *   data: {},
 *   fields: {},
 *   printForms: {
 *     name: {string},
 *     description: {string},
 *     url: {string},
 *     icon: {string},
 * }[],
 * options: {},
 * fieldErrors: {},
 * nonFieldErrors: {},
 * systemErrors: string,
 * loading: boolean,
 * changed: boolean,
 * isNew: boolean,
 * actions: {
 *    onReload: (function(): void),
 *    onChange: (function(): void),
 *    onSaveWithoutExit: (function(): void),
 *    onSaveNExit: (function(): void),
 *    onExecuteNExit: (function(): void),
 *    onExecute: (function(): void),
 *    onUnexecute: (function(): void),
 *    onUndo: (function(): void),
 *    onRedo: (function(): void),
 *    onClose: (function(): void),
 *    onErr: (function(): void),
 *    onLoading: (function(): void),
 *    onClearErrs: (function(): void),
 *    onClearNonFieldErrors: (function(): void),
 *    onDraft: (function(): void),
 * },
 * permissions: {
 *    canSave: boolean,
 *    canUndo: boolean,
 *    canRedo: boolean,
 *    canClose: boolean,
 *    canExecute: boolean,
 *    canUnexecute: boolean,
 *    canChange: boolean,
 },
 * headerProps: {
 *  data: {},
 *  fields: {},
 *  fieldErrors: {},
 *  onChange: (function(): void),
 *  readOnly: boolean,
 *  headerReadOnlyFields: string[],
 * }
 * }}
 */
const useEditor = (editorParams) => {
  const defaultParams = useMemo(
    () => ({
      id: null,
      reason: '',
      copyFrom: '', // id документа, который копируется
      isGroup: false,
      onSaveCallBack: null, // callback при сохранении документа
      onCloseCallBack: null, // callback при выходе
      defaults: {}, // Значения по умолчанию  для нового объекта
      readOnlyGetter: null, // функция, которая вычисляет значение аттрибута readOnly
    }),
    [],
  );

  const {
    backendURL,
    id,
    reason,
    isGroup,
    copyFrom,
    onSaveCallBack, // callback при сохранении документа
    onCloseCallBack, // callback при выходе
    defaults, // Значения по умолчанию  для нового объекта
    // readOnlyGetter, // функция, которая вычисляет значение аттрибута readOnly
  } = useMemo(
    () => ({ ...defaultParams, ...editorParams }),
    [defaultParams, editorParams],
  );

  const { auth } = useContext(CiatAppContext);
  const { sendUpdateSignal, closeWarnings, setCloseWarning } = useContext(WinManagerContext);
  const md = useMD(backendURL);

  const cwName = `${md.frontendURL}${id}/`;

  const wmChanged = !!closeWarnings[cwName];

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
  });
  const [fields, setFields] = useState(null);
  const [options, setOptions] = useState({});
  const [printForms, setPrintForms] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const updateLister = useCallback(
    async () => sendUpdateSignal(backendURL),
    [backendURL, sendUpdateSignal],
  );
  const [saveListeners, setSaveListeners] = useState([]);
  const [extraActions, setExtraActions] = useState([]);
  const [readOnlyFields, setReadOnlyFields] = useState({});
  const leadingFieldsRef = useRef([]);
  const setLeadingFields = useCallback(
    // eslint-disable-next-line no-return-assign
    (v) => leadingFieldsRef.current = v,
    [],
  );
  const [fieldErrors, setFieldErrors] = useState({});
  const [nonFieldErrors, setNonFieldErrors] = useState(null);

  const isNew = id === 'create' || id === 'createGroup';

  currentData.current = {
    ...data.current,
    is_group: (isNew) ? isGroup : data?.current?.is_group,
  };

  const [changed, setChanged] = useState(false);

  useEffect(
    () => {
      if (changed !== wmChanged) setCloseWarning(cwName, changed);
    },
    [changed, cwName, setCloseWarning, wmChanged],
  );

  const loadData = useCallback(
    async (itemId) => {
      setErr(null);
      const r = await api.get$(`${backendURL}${itemId}/ `, auth);
      if (!r.ok) {
        let e;
        try {
          e = await r.text();
        } catch {
          e = `${r.status} ${r.statusText}`;
        }
        throw new Error(e);
      }
      const d = await r.json();
      setData({
        current: d,
        history: {
          data: [d],
          pointer: null,
        },
      });
      setChanged(false);
      return d;
    },
    [auth, backendURL],
  );

  const loadOptions = useCallback(
    async (itemId) => {
      const url = `${backendURL}${itemId}/`; // isNew ? `${backendURL}0/` : `${backendURL}${itemId}/`;
      const r = await api.options(url, auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const o = await r.json();
      setOptions({
        name: o.name,
        description: o.description,
      });
      const saveMethod = isNew ? 'GET' : 'PUT';
      const rOnly = !(saveMethod in o.actions);
      setReadOnly(rOnly);
      setExtraActions(o.extra_actions.map((ea) => ea.name.toLowerCase()));
      setLeadingFields(o.leading_fields);
      setFields(rOnly ? o.actions.GET : o.actions[saveMethod]);
      setPrintForms(o.print_forms || null);
      return rOnly;
    },
    [auth, backendURL, isNew, setLeadingFields],
  );

  const draft = useCallback(
    /**
     * Execute DRAFT action
     * @param rec {{}} - rec,
     * @param requirements {{}} - requirements
     * @param updateData {boolean} - updateData
     * @returns {Promise<null|*>}
     */
    async (rec, requirements = {}, updateData = true) => {
      if (!rec) throw new Error('Rec не може бути порожнім або невизначеним');
      setLoading(true);
      setErr(null);
      setNonFieldErrors(null);
      setFieldErrors({});

      const params = {
        record: rec,
        requirements,
      };

      const resp = await api.post$(`${backendURL}draft/`, auth, params);
      if (resp.ok) {
        // eslint-disable-next-line camelcase
        const { record, leading_fields, read_only_fields: lReadOnlyFields } = await resp.json();

        const headerReadonlyFields = lReadOnlyFields.filter((f) => f.indexOf('.') === -1);

        const tablePartsReadonlyFields = lReadOnlyFields
          .filter((f) => f.indexOf('.') !== -1)
          .reduce((R, f) => {
            const [tpName, fName] = f.split('.');
            const o = R[tpName] || [];
            return {
              ...R,
              [tpName]: [...o, fName],
            };
          }, {});

        // Установим значение аттрибута read
        setErr(null);
        setReadOnlyFields({ [HEADER_KEY]: headerReadonlyFields, ...tablePartsReadonlyFields });
        setLeadingFields(leading_fields);
        if (updateData) {
          setData((oldData) => ({
            current: {
              ...oldData.current,
              ...record,
              // Исключения. От драфт всегда призодят пустые
              repr: oldData.current.repr,
              url: oldData.current.url,
              resource: oldData.current.resource,
              is_group: (isNew) ? isGroup : record?.is_group,
            },
            history: { data: [], pointer: null },
          }));
          setChanged(true);
        }
        setLoading(false);
        return record;
      }
      if (resp.status === 400) {
        const ed = await resp.json();
        const errors = buildErrors(ed, {});
        setNonFieldErrors(errors.nonFieldErrors);
        setFieldErrors(errors.fields);
      } else {
        let e;
        try {
          e = await resp.text();
        } catch {
          e = `${resp.status} ${resp.statusText}`;
        }
        setErr(e);
      }
      setLoading(false);
      return null;
    },
    [backendURL, auth, setLeadingFields, isNew, isGroup],
  );
  const locked = useRef(false);
  const lockProcessStarted = useRef(false);

  const lock = useCallback(
    async () => {
      if (locked.current) return true;
      // Блокируются только справочники и документы
      if (isNew) {
        locked.current = true;
      } else {
        lockProcessStarted.current = true;
        setLoading(true);
        setErr(null);
        const r = await api.get(`${backendURL}${id}/block/`, auth);
        lockProcessStarted.current = false;
        setLoading(false);
        if (r.ok) {
          locked.current = true;
        } else if (r.status === 423) {
          const lockInfo = await r.json();
          const dataStartBlock = new Date(lockInfo.start_at)
            .toLocaleString('uk', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            });
          throw new Error(`Цей об'єкт заблоковано користувачем: ${lockInfo.blocker.first_name || ''} ${lockInfo.blocker.last_name || ''} (${lockInfo.blocker.repr}) c ${dataStartBlock}`);
        } else {
          throw new Error(`Помилка при встановленні блокування ${r.status} ${r.statusText}`);
        }
      }
      return locked.current;
    },
    [auth, backendURL, id, isNew],
  );

  const unlock = useCallback(
    async () => {
      if (!locked.current) return null;
      if (isNew) {
        locked.current = false;
      } else {
        setErr(null);
        lockProcessStarted.current = true;
        const r = await api.get(`${backendURL}${id}/unblock/`, auth);
        lockProcessStarted.current = false;
        if (r.ok) {
          locked.current = false;
        } else {
          throw new Error(`Помилка при знятті блокування ${r.status} ${r.statusText}`);
        }
      }
      return null;
    },
    [auth, backendURL, id, isNew],
  );

  useEffect(
    () => () => {
      unlock();
    },
    [unlock],
  );
  const onChange = useCallback(
    /**
     *
     * @param partOfData {{}, function }
     */
    async (partOfData) => {
      if (!readOnly) {
        let l = false;
        try {
          if (!lockProcessStarted.current) {
            l = await lock();
          }
        } catch (e) {
          setErr(e.message);
        }
        if (l) {
          setChanged(true);
          const p = typeof partOfData === 'function' ? partOfData(currentData.current) : partOfData;
          const newCurrent = { ...currentData.current, ...p };
          const hasChange = Object.keys(newCurrent)
            .reduce((Ch, k) => Ch || partOfData[k] !== currentData.current[k], false);
            // Возможно если в p есть табличные части,
            // то необходимо проверять строки на равенство, а в них ключи
          const diff = getObjectDiff(currentData.current, newCurrent);

          const changedFields = leadingFieldsRef.current && diff
            .reduce((cfs, field) => leadingFieldsRef.current.reduce((cfs2, lf) => {
              if (lf === field) return [...cfs2, field];
              const s1 = field.split('.');
              const s2 = lf.split('.');

              if (s1.length === s2.length) {
                if (s2.reduce((R3, lss, index) => R3 && (lss === '*' || lss === s1[index]), true)) return [...cfs2, field];
              }
              return cfs2;
            }, cfs), []);

          const draftResult = changedFields.length
            ? await draft(newCurrent, { changed: changedFields }, false)
            : {};
          setData(({ history }) => {
            const newHData = history.pointer === null
              ? [...history.data, newCurrent]
              : [...history.data.slice(0, history.pointer + 1), newCurrent];
            return ({
              current: { ...newCurrent, ...draftResult },
              history: hasChange ? {
                data: newHData,
                pointer: null,
              } : history,
            });
          });
        }
      }
    },
    [draft, lock, readOnly],
  );

  const initAddParams = useMemo(
    () => {
      if (!isNew) return {};
      return {
        is_group: isGroup,
        copy_from: copyFrom,
        reason,
        defaults,
      };
    },
    [isNew, isGroup, copyFrom, reason, defaults],
  );
  const onReload = useCallback(
    () => {
      setLoading(true);
      (isNew
        ? draft({}, initAddParams)
        : loadData(id).then((d) => draft(d, {}, false))
      )
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [draft, id, initAddParams, isNew, loadData],
  );

  const save = useCallback(
    async (savableData) => {
      setLoading(true);
      setErr(null);
      setFieldErrors({});
      setNonFieldErrors(null);
      const r = isNew
        ? await api.post(backendURL, auth, savableData)
        : await api.put(`${backendURL}${id}/`, auth, savableData);
      setLoading(false);
      if (!r.ok && r.status !== 400) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const d = await r.json();
      const errData = r.status === 400 ? d : null;
      const exts = await Promise.allSettled(saveListeners.map((l) => l(d)));
      const errExts = exts.filter((er) => er.status === 'rejected');
      if (errData || errExts.length) {
        const errors = errData ? buildErrors(errData, fields) : buildErrors(errExts.length, fields);

        setNonFieldErrors([
          ...errors?.nonFieldErrors || [],
        ]);
        setFieldErrors(errors.fields || null);
        throw new ItemSaveException(`${r.status} ${r.statusText}`);
      }
      updateLister();
      await unlock();
      setChanged(false);
      if (!isNew) onReload();
      // setData({ current: d, history: { data: [], pointer: null } });
      return d;
    },
    [auth, backendURL, fields, id, isNew, onReload, saveListeners, unlock, updateLister],
  );

  const undo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer === null) {
          if (history.data.length > 1) {
            return ({
              current: history.data[history.data.length - 2],
              history: {
                data: history.data,
                pointer: history.data.length - 2,
              },
            });
          }
        } else if (history.pointer > 0) {
          return ({
            current: history.data[history.pointer - 1],
            history: {
              data: history.data,
              pointer: history.pointer - 1,
            },
          });
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const redo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer !== null) {
          if (history.data.length > history.pointer + 1) {
            return ({
              current: history.data[history.pointer + 1],
              history: {
                data: history.data,
                pointer: history.data.length > history.pointer + 2
                  ? history.pointer + 1
                  : null,
              },
            });
          }
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const onSaveWithoutExit = useCallback(
    () => save(data.current)
      .then((d) => {
        if (onSaveCallBack) onSaveCallBack(d);
        toast.info(`${options.name}: успішно збережено елемент ${d.repr}`, {
          theme: 'colored',
        });
      })
      .catch((e) => {
        if (!(e instanceof ItemSaveException)) setErr(e.message);
      }),
    [data, onSaveCallBack, options.name, save],
  );

  const onSaveNExit = useCallback(
    () => save(data.current)
      .then((d) => {
        if (onCloseCallBack) onCloseCallBack(d);
        toast.info(`${options.name}: успішно збережено елемент ${d.repr}`, {
          theme: 'colored',
        });
      })
      .catch((e) => {
        if (!(e instanceof ItemSaveException)) setErr(e.message);
      }),
    [data, onCloseCallBack, options.name, save],
  );

  const onExecuteNExit = useCallback(
    () => save({ ...data.current, executed: true })
      .then((d) => {
        if (onCloseCallBack) onCloseCallBack(d);
        toast.info(`${options.name}: успішно проведено документ ${d.repr}`, {
          theme: 'colored',
        });
      })
      .catch(() => toast.error('Документ не вдалося провести', {
        theme: 'colored',
      })),
    [data, onCloseCallBack, options.name, save],
  );

  const onExecute = useCallback(
    () => save({ ...data.current, executed: true }).then((d) => {
      if (onSaveCallBack) onSaveCallBack(d);
      toast.success(`${d.repr} проведено вдало`, {
        theme: 'colored',
      });
    })
      .catch(() => toast.error('Документ не вдалося провести', {
        theme: 'colored',
      })),
    [data, onSaveCallBack, save],
  );

  const onUnexecute = useCallback(
    () => save({ ...data.current, executed: false })
      .then((d) => {
        if (onSaveCallBack) onSaveCallBack(d);
        toast.success(`${d.repr} вдало скасовано проведення`, {
          theme: 'colored',
        });
      })
      .catch(() => toast.error('Документ не вдалося зробити непроведеним', {
        theme: 'colored',
      })),
    [data, onSaveCallBack, save],
  );

  useEffect(
    () => {
      const loader = async (rOnly) => {
        if (isNew) {
          await draft({}, initAddParams);
        } else {
          const d = await loadData(id);
          if (!rOnly) {
            await draft(d, {}, false);
          }
        }
      };
      if (!err) {
        setLoading(true);
        loadOptions(id)
          .then((rOnly) => loader(rOnly))
          .catch((e) => {
            setErr(e.message);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [draft, err, id, initAddParams, isNew, loadData, loadOptions],
  );

  const permissions = useMemo(
    () => {
      const perms = {
        canSave: !readOnly,
        canUndo: !readOnly && data.history.pointer !== 0 && data.history.data.length > 1,
        canRedo: !readOnly && data.history.pointer !== null
          && data.history.pointer < data.history.data.length,
        canClose: !!onCloseCallBack,
        canExecute: !readOnly && extraActions.includes('execute'),
        canUnexecute: !readOnly && extraActions.includes('unexecute'),
        canChange: !readOnly,
        canHistory: !readOnly && extraActions.includes('history'),
      };
      // Пока идет загрузка ничего нельзя
      // Если не блокировать, тогда во время создания если нажать кнопку "Записать" 10 раз
      // будет создано 10 документов
      if (loading) return Object.keys(perms).reduce((R, k) => ({ ...R, [k]: false }), {});
      return perms;
    },
    [data.history.data.length, data.history.pointer,
      extraActions, loading, onCloseCallBack, readOnly],
  );

  const onClose = useCallback(
    () => {
      unlock().then(
        () => {
          if (onCloseCallBack) {
            onCloseCallBack();
          }
        },
      );
    },
    [onCloseCallBack, unlock],
  );

  const onClearErrs = useCallback(
    () => setErr(null),
    [],
  );
  const onClearNonFieldErrors = useCallback(
    () => setNonFieldErrors(null),
    [],
  );

  const registerSaveListener = useCallback(
    (f) => setSaveListeners((o) => {
      if (o.includes(f)) return o;
      return [...o, f];
    }),
    [],
  );

  const actions = useMemo(
    () => ({
      onReload,
      onChange,
      onSaveWithoutExit,
      onSaveNExit,
      onExecuteNExit,
      onExecute,
      onUnexecute,
      onRedo: redo,
      onUndo: undo,
      onClose,
      onErr: setErr,
      onLoading: setLoading,
      onClearErrs,
      onClearNonFieldErrors,
      registerSaveListener,
      onDraft: draft,
    }),
    [
      draft, onChange, onClearErrs, onClearNonFieldErrors, onClose, onExecute, onExecuteNExit,
      onReload, onSaveNExit, onSaveWithoutExit, onUnexecute, redo, registerSaveListener, undo,
    ],
  );

  const headerReadOnlyFields = useMemo(() => readOnlyFields[HEADER_KEY] || [], [readOnlyFields]);

  const headerProps = {
    data: {
      ...data.current,
      is_group: (isNew) ? isGroup : data?.current?.is_group,
    },
    fields,
    fieldErrors,
    onChange: actions.onChange,
    readOnly,
    headerReadOnlyFields,
  };

  return {
    data: Object.keys(data.current).length !== 0 ? {
      ...data.current,
      fieldErrors,
    } : data.current,
    is_group: (isNew) ? isGroup : data?.current?.is_group,
    printForms,
    fields,
    options,
    fieldErrors,
    nonFieldErrors,
    loading,
    systemErrors: err,
    changed,
    permissions,
    actions,
    isNew,
    readOnlyFields,
    headerReadOnlyFields,
    readOnly,
    headerProps,
  };
};

export default useEditor;
