import cat from './cat';
import doc from './doc';
import rep from './rep';
import srv from './srv';

const meta = {
  cat,
  doc,
  rep,
  srv,
};

export const modelTypes = ['cat', 'doc'];

export const modelNames = modelTypes.reduce((R, r) => [...R, ...Object.keys(meta[r])], []);

export const metaBackends = modelTypes.reduce((RM, modelType) => ({
  ...RM,
  ...Object.keys(meta[modelType]).reduce((R, modelName) => ({
    ...R,
    [meta[modelType][modelName].backendURL]: {
      ...meta[modelType][modelName],
      modelType,
      modelName,
    },
  }), RM),
}), {});

export default meta;
