import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, Badge,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import { CiatAppContext } from '../../../../providers';
import EditorFooter from '../../../../components/Containers/editorFooter';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields,
  } = useEditor({
    backendURL: meta.cat.documentnote.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const fullNameProps = editorHooks.useStringInputProps('fullname', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const remarkNoteProps = editorHooks.useItemInputProps('remark_note', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const name4requestProps = editorHooks.useStringInputProps('name4request', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
      disposer: (data.disposer && data.disposer.id) || null,
    }),
    [data.disposer],
  );

  const { currentUser } = useContext(CiatAppContext);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.cat.documentnote.backendURL}
          id={id}
        />
      )}
    >
      {data.is_group && (
      <div className="w-100 text-end">
        <Badge bg="info">Група</Badge>
      </div>
      )}
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col md={4}>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.ItemPicker {...remarkNoteProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
                </Col>
                <Col md={12}>
                  <EditorControls.TextInput {...name4requestProps} rows={3} />
                </Col>
                <Col md={12}>
                  <EditorControls.TextInput {...fullNameProps} rows={3} />
                </Col>
                {currentUser.is_superuser && (
                  <Col md={12}>
                    <EditorControls.ItemPicker {...disposerProps} />
                  </Col>
                )}
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
      <EditorFooter>
        <div className="text-muted">{data.disposer ? data.disposer.repr : ''}</div>
      </EditorFooter>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
