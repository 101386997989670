import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/socialPayments';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { comparisonTypes, selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const indicatorsFilter = useMemo(() => [
    { fieldName: 'Владелец', value: row[tableMD.columns.socialPayment.name] },
    {
      comparisonType: comparisonTypes.equal,
      value: false,
    },
  ], [row]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={7} className="w-100" noGutters>
          <Col className="border-right">
            <TabelRowCell column="codeKBP" highlighted={highlights.includes('codeKBP')}>
              <TextInput
                value={row[tableMD.columns.codeKBP.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.codeKBP.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TabelRowCell>
            <TabelRowCell column="kbpClassifier" highlighted={highlights.includes('kbpClassifier')}>
              <ItemPicker
                value={row[tableMD.columns.kbpClassifier.name]}
                modelType="cat"
                modelName="kbpClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kbpClassifier.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TabelRowCell>
            <TabelRowCell column="socialPayment" highlighted={highlights.includes('socialPayment')}>
              <ItemPicker
                value={row[tableMD.columns.socialPayment.name]}
                modelType="cat"
                modelName="elementSocialPayment"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.socialPayment.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TabelRowCell>
            <TabelRowCell column="deciphering" highlighted={highlights.includes('deciphering')}>
              <ItemPicker
                value={row[tableMD.columns.deciphering.name]}
                modelType="cat"
                modelName="articlesDebtSocialPayments"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.deciphering.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="deptStartMonthTotal1" highlighted={highlights.includes('deptStartMonthTotal1')}>
              <NumberInput
                value={row[tableMD.columns.deptStartMonthTotal1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.deptStartMonthTotal1.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="deptStartMonthTotal" highlighted={highlights.includes('deptStartMonthTotal')}>
              <NumberInput
                value={row[tableMD.columns.deptStartMonthTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.deptStartMonthTotal.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="deptStartMonth" highlighted={highlights.includes('deptStartMonth')}>
              <NumberInput
                value={row[tableMD.columns.deptStartMonth.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.deptStartMonth.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="planMonth" highlighted={highlights.includes('planMonth')}>
              <NumberInput
                value={row[tableMD.columns.planMonth.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.planMonth.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="accrualsMonth" highlighted={highlights.includes('accrualsMonth')}>
              <NumberInput
                value={row[tableMD.columns.accrualsMonth.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.accrualsMonth.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="factMonthTotalTotal" highlighted={highlights.includes('factMonthTotalTotal')}>
              <NumberInput
                value={row[tableMD.columns.factMonthTotalTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthTotalTotal.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factMonthTotal" highlighted={highlights.includes('factMonthTotal')}>
              <NumberInput
                value={row[tableMD.columns.factMonthTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthTotal.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factMonth" highlighted={highlights.includes('factMonth')}>
              <NumberInput
                value={row[tableMD.columns.factMonth.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonth.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factMonthDept" highlighted={highlights.includes('factMonthDept')}>
              <NumberInput
                value={row[tableMD.columns.factMonthDept.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthDept.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="factMonthSFTotal" highlighted={highlights.includes('factMonthSFTotal')}>
              <NumberInput
                value={row[tableMD.columns.factMonthSFTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthSFTotal.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factMonthSF" highlighted={highlights.includes('factMonthSF')}>
              <NumberInput
                value={row[tableMD.columns.factMonthSF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthSF.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factMonthDeptSF" highlighted={highlights.includes('factMonthDeptSF')}>
              <NumberInput
                value={row[tableMD.columns.factMonthDeptSF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factMonthDeptSF.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="factDeptOnDateTotal" highlighted={highlights.includes('factDeptOnDateTotal')}>
              <NumberInput
                value={row[tableMD.columns.factDeptOnDateTotal.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factDeptOnDateTotal.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="factDeptOnDate" highlighted={highlights.includes('factDeptOnDate')}>
              <NumberInput
                value={row[tableMD.columns.factDeptOnDate.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.factDeptOnDate.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
            <TabelRowCell column="overdue" highlighted={highlights.includes('overdue')}>
              <NumberInput
                value={row[tableMD.columns.overdue.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.overdue.name]: v })}
                readOnly={readOnly}
              />
            </TabelRowCell>
          </Col>
          <Col className="border-right">
            <TabelRowCell column="primSocialPayment" highlighted={highlights.includes('primSocialPayment')}>
              <ItemPicker
                value={row[tableMD.columns.primSocialPayment.name]}
                modelType="cat"
                modelName="noteSocialPayments"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.primSocialPayment.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.num.name]: PropTypes.number,
    [tableMD.columns.codeKBP.name]: PropTypes.string,
    [tableMD.columns.kbpClassifier.name]: referencePropType,
    [tableMD.columns.socialPayment.name]: referencePropType,
    [tableMD.columns.deciphering.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
