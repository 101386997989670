import React, {
  useCallback, useEffect, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Button, Col, InputGroup, Row,
} from 'react-bootstrap';
import { CiatAppContext } from '../../../providers';
import { EditorControls } from '../../basicEditor/editorControls';
import { ItemPicker } from '../../../components/Controls';
import { CommandPanel } from '../../../components/bootStrap';
import { foreignPropType } from '../../../constants/backend/propTypes';
import api from '../../../api/req';

const SAVE_MODES = {
  forMe: 0,
  forAuthority: 1,
  forBudget: 2,
  forAll: 3,
};

function SaveReport({
  onHide, reportData, isNew, onSave,
}) {
  const { currentUser } = useContext(CiatAppContext);

  const [newReportData, setReportData] = useState({
    name: '',
    description: '',
    mode: SAVE_MODES.forMe,
    authority: null,
    budget: null,
    user: null,
    title: null,
    labels: [],
  });
  const isReadOnly = !isNew && !currentUser.is_superuser
    && reportData.author && reportData.author.id !== currentUser.profile.id;

  useEffect(
    () => {
      let newMode = SAVE_MODES.forAll;
      if (reportData.user !== null) {
        newMode = SAVE_MODES.forMe;
      } else if (reportData.authority !== null) {
        newMode = SAVE_MODES.forAuthority;
      } else if (reportData.budget !== null) {
        newMode = SAVE_MODES.forBudget;
      } else if (!currentUser.is_superuser) newMode = SAVE_MODES.forMe;
      setReportData((oldParam) => ({
        ...oldParam,
        name: reportData.name,
        description: reportData.description,
        mode: newMode,
        authority: reportData.authority,
        budget: reportData.budget,
        user: reportData.user,
        avatarImg: reportData.avatarImg,
        labels: reportData.labels,
      }));
    },
    [
      currentUser.is_superuser, reportData.description, reportData.name,
      reportData.authority, reportData.budget, reportData.user, reportData.avatarImg,
      reportData.labels],
  );

  const saveHandler = useCallback(
    () => {
      if (!currentUser.profile) throw new Error('У пользователя нет профиля!');
      const bau = currentUser.is_superuser ? {
        budget: newReportData.mode === SAVE_MODES.forBudget ? newReportData.budget : null,
        authority: newReportData.mode === SAVE_MODES.forAuthority ? newReportData.authority : null,
        settings: { title: { display: newReportData.title } },
        user: newReportData.mode === SAVE_MODES.forMe ? newReportData.user : null,
      } : {
        budget: newReportData.mode === SAVE_MODES.forBudget && currentUser.profile.budget
          ? currentUser.profile.budget.id
          : null,
        authority:
          newReportData.mode === SAVE_MODES.forAuthority && currentUser.profile.authority
            ? currentUser.profile.authority.id
            : null,
        user: newReportData.mode === SAVE_MODES.forMe ? currentUser.profile.id : null,
      };
      const saveData = {
        name: newReportData.name,
        description: newReportData.description,
        schema_id: reportData.schema.id,
        avatarImg: reportData.avatarImg,
        labels: newReportData.labels,
        ...bau,
      };
      onSave(
        saveData,
        isNew,
      );
    },
    [currentUser.is_superuser, currentUser.profile, isNew, newReportData.authority,
      newReportData.budget, newReportData.description, newReportData.labels, newReportData.mode,
      newReportData.name, newReportData.title, newReportData.user, onSave, reportData.avatarImg,
      reportData.schema.id],
  );

  const [labels, setLabels] = useState([]);
  const { auth } = useContext(CiatAppContext);
  useEffect(
    () => {
      const loader = async () => {
        const r = await api.get('/api/reports/label/', auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      loader().then((d) => setLabels(d.map((l) => ({ value: l.id, display_name: l.repr }))));
    },
    [auth],
  );
  const saveEnabled = useMemo(
    () => {
      if (!newReportData.name || !newReportData.labels.length) return false;
      if (newReportData.mode === SAVE_MODES.forBudget && !newReportData.budget) return false;
      if (newReportData.mode === SAVE_MODES.forAuthority && !newReportData.authority) return false;
      return true;
    },
    [newReportData.authority, newReportData.budget,
      newReportData.labels.length, newReportData.mode, newReportData.name],
  );

  return (
    <>
      <Row className="px-3">
        <Col md={4}>
          <EditorControls.StringInput
            controlId={`name_${isNew}`}
            label="Назва"
            value={newReportData.name}
            onChange={(e, v) => setReportData((oldV) => ({
              ...oldV,
              name: v,
            }))}
          />
        </Col>
        <Col md={8}>
          <EditorControls.MultiSelector
            label="Категорії звіту"
            value={newReportData.labels}
            onChange={(e, v) => setReportData((oldV) => ({
              ...oldV,
              labels: v,
            }))}
            values={labels}
          />
        </Col>
        <Col sm={12}>
          <EditorControls.TextInput
            controlId={`description_${isNew}`}
            label="Опис"
            value={newReportData.description}
            onChange={(e, v) => setReportData((oldV) => ({
              ...oldV,
              description: v,
            }))}
            rows={3}
          />

        </Col>
        <Col sm={12} className="mt-2">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Додаткові налаштування</Accordion.Header>
              <Accordion.Body>
                <EditorControls.StringInput
                  label="Заголовок"
                  value={newReportData.title}
                  onChange={(e, v) => setReportData((oldV) => ({
                    ...oldV,
                    title: v,
                  }))}
                  readOnly={isReadOnly}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Налаштування доступу</Accordion.Header>
              <Accordion.Body>
                <InputGroup size="sm" className="my-2">
                  <InputGroup.Radio
                    checked={newReportData.mode === SAVE_MODES.forAll}
                    disabled={!currentUser.is_superuser}
                    onChange={() => setReportData(({ ...r }) => ({
                      ...r, mode: SAVE_MODES.forAll,
                    }))}
                  />
                  <InputGroup.Text>Для всіх</InputGroup.Text>
                </InputGroup>
                <InputGroup size="sm" className="my-2">
                  {currentUser.is_superuser ? (
                    <ItemPicker
                      prepend={(
                        <>
                          <InputGroup.Radio
                            checked={newReportData.mode === SAVE_MODES.forBudget}
                            onChange={() => setReportData(
                              ({ ...r }) => ({ ...r, mode: SAVE_MODES.forBudget }),
                            )}
                          />
                          <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного бюджету' : 'Для мого міста'}</InputGroup.Text>
                        </>
              )}
                      required={newReportData.mode === SAVE_MODES.forBudget}
                      value={newReportData.budget}
                      onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, budget: v }))}
                      disabled={newReportData.mode !== SAVE_MODES.forBudget}
                      modelName="budget"
                      modelType="cat"
                    />
                  ) : (
                    <>
                      <InputGroup.Radio
                        checked={newReportData.mode === SAVE_MODES.forBudget}
                        onChange={() => setReportData(
                          ({ ...r }) => ({ ...r, mode: SAVE_MODES.forBudget }),
                        )}
                      />
                      <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного бюджету' : 'Для мого міста'}</InputGroup.Text>
                    </>
                  )}
                </InputGroup>
                <InputGroup size="sm" className="my-2">
                  {currentUser.is_superuser ? (
                    <ItemPicker
                      prepend={(
                        <>
                          <InputGroup.Radio
                            checked={newReportData.mode === SAVE_MODES.forAuthority}
                            onChange={() => setReportData(
                              ({ ...r }) => ({ ...r, mode: SAVE_MODES.forAuthority }),
                            )}
                          />
                          <InputGroup.Text>{currentUser.is_superuser ? 'Для обраної установи' : 'Для моєі установи'}</InputGroup.Text>
                        </>
            )}
                      required={newReportData.mode === SAVE_MODES.forAuthority}
                      value={newReportData.authority}
                      onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, authority: v }))}
                      disabled={newReportData.mode !== SAVE_MODES.forAuthority}
                      modelName="disposers"
                      modelType="cat"
                    />
                  ) : (
                    <>
                      <InputGroup.Radio
                        checked={newReportData.mode === SAVE_MODES.forAuthority}
                        onChange={() => setReportData(
                          ({ ...r }) => ({ ...r, mode: SAVE_MODES.forAuthority }),
                        )}
                      />
                      <InputGroup.Text>{currentUser.is_superuser ? 'Для обраної установи' : 'Для моєі установи'}</InputGroup.Text>
                    </>

                  )}
                </InputGroup>
                <InputGroup size="sm" className="my-2">
                  {currentUser.is_superuser ? (
                    <ItemPicker
                      prepend={(
                        <>
                          <InputGroup.Radio
                            checked={newReportData.mode === SAVE_MODES.forMe}
                            onChange={() => setReportData(
                              ({ ...r }) => ({ ...r, mode: SAVE_MODES.forMe }),
                            )}
                          />
                          <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного користувача' : 'Для мене'}</InputGroup.Text>
                        </>
            )}
                      required={newReportData.mode === SAVE_MODES.forMe}
                      value={newReportData.user}
                      onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, user: v }))}
                      disabled={newReportData.mode !== SAVE_MODES.forMe}
                      modelName="user"
                      modelType="cat"
                    />
                  ) : (
                    <>
                      <InputGroup.Radio
                        checked={newReportData.mode === SAVE_MODES.forMe}
                        onChange={() => setReportData(({ ...r }) => ({
                          ...r,
                          mode: SAVE_MODES.forMe,
                        }))}
                      />
                      <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного користувача' : 'Для мене'}</InputGroup.Text>
                    </>
                  )}
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <CommandPanel
        className="mt-3"
        rtl
        leftPart={(
          <>
            <Button
              disabled={isReadOnly || !saveEnabled}
              onClick={() => {
                saveHandler();
                onHide();
              }}
              variant="falcon-primary"
            >
              Зберегти
            </Button>
            <Button
              onClick={onHide}
              variant="falcon-secondary"
            >
              Закрити
            </Button>
          </>
        )}
      />
    </>
  );
}

SaveReport.propTypes = {
  onHide: PropTypes.func.isRequired,
  reportData: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    avatarImg: PropTypes.string,
    schema: PropTypes.shape({
      id: PropTypes.string,
    }),
    authority: foreignPropType,
    budget: foreignPropType,
    user: foreignPropType,
    author: foreignPropType,
    labels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

SaveReport.defaultProps = {
  isNew: false,
};

export default SaveReport;
