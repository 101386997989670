import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';
import { CiatAppContext } from '../../../../providers';
import EditorFooter from '../../../../components/Containers/editorFooter';

const BACKENDURL = meta.cat.executive.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const { currentUser } = useContext(CiatAppContext);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const firstNameProps = editorHooks.useStringInputProps('first_name', data, fields, fieldErrors, actions.onChange);
  const lastNameProps = editorHooks.useStringInputProps('last_name', data, fields, fieldErrors, actions.onChange);
  const surnameProps = editorHooks.useStringInputProps('surname', data, fields, fieldErrors, actions.onChange);
  const rankProps = editorHooks.useStringInputProps('rank', data, fields, fieldErrors, actions.onChange);
  const phoneNumberProps = editorHooks.useStringInputProps('phone_number', data, fields, fieldErrors, actions.onChange);
  const aPhoneNumber = editorHooks.useStringInputProps('additional_phone_number', data, fields, fieldErrors, actions.onChange);
  const emailProps = editorHooks.useStringInputProps('email', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={`${data.first_name || ''} ${data.last_name || ''} ${data.surname || ''}`}
      subtitle={data.rank}
    >
      <Container fluid className="pb-3">
        <Row>
          <Col>
            <EditorControls.StringInput {...lastNameProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...firstNameProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...surnameProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...rankProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...phoneNumberProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...aPhoneNumber} />
          </Col>
          <Col>
            <EditorControls.StringInput {...emailProps} />
          </Col>
        </Row>
        {currentUser.is_superuser && (
          <EditorControls.ItemPicker {...authorityProps} />
        )}
      </Container>
      <EditorFooter>
        <div className="text-muted">{data.authority ? data.authority.repr : ''}</div>
      </EditorFooter>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
