import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  Tabl111TP, Tabl112TP,
} from './tabs';

const Taxes2Tabs = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabl111TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
        <Col>
          <Tabl112TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );
};

Taxes2Tabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default Taxes2Tabs;
