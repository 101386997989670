import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  fields, activeCol, highlights, onToggleHighlight, activeObjectsBuilding,
  disposerInHeader, ABVisible, isComposeSettingsChecked, totals, isRefundByMonth,
}) {
  return (
    <TableHeaderRow className="px-4 fs--2">
      <Col className="px-1">
        {!disposerInHeader
          && (
            <Row>
              <Col
                xl={4}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer'}
                  highlighted={highlights.includes('disposer')}
                  onToggleHighlight={() => onToggleHighlight('disposer')}
                >
                  {fields.disposer.label}
                </TableHeaderCell>
              </Col>
              <Col
                xl={8}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer_acc'}
                  highlighted={highlights.includes('disposer_acc')}
                  onToggleHighlight={() => onToggleHighlight('disposer_acc')}
                >
                  {fields.disposer_acc.label}
                </TableHeaderCell>
              </Col>
            </Row>
          )}
        <Row>
          <Col
            xl={4}
            sm={12}
            className="d-flex flex-column border-right"
          >
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'kbp7_item'}
                  highlighted={highlights.includes('kbp7_item')}
                  onToggleHighlight={() => onToggleHighlight('kbp7_item')}
                >
                  {fields.kbp7_item.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'kekv_item'}
                  highlighted={highlights.includes('kekv_item')}
                  onToggleHighlight={() => onToggleHighlight('kekv_item')}
                >
                  {fields.kekv_item.label}
                </TableHeaderCell>
                {disposerInHeader
          && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer_acc'}
              highlighted={highlights.includes('disposer_acc')}
              onToggleHighlight={() => onToggleHighlight('disposer_acc')}
            >
              {fields.disposer_acc.label}
            </TableHeaderCell>
          )}
              </Col>
            </Row>
          </Col>

          {activeObjectsBuilding
    && (
      <Col xl={2} sm={12} className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'buildObgect'}
          highlighted={highlights.includes('buildObgect')}
          onToggleHighlight={() => onToggleHighlight('buildObgect')}
        >
          {fields.buildObgect.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'typeRepair'}
          highlighted={highlights.includes('typeRepair')}
          onToggleHighlight={() => onToggleHighlight('typeRepair')}
        >
          {fields.typeRepair.label}
        </TableHeaderCell>
      </Col>
    )}

          <Col xl={activeObjectsBuilding ? 6 : 8} sm={12}>
            <Row>

              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'plan_year_amount'}
                  highlighted={highlights.includes('plan_year_amount')}
                  onToggleHighlight={() => onToggleHighlight('plan_year_amount')}
                >
                  {fields.plan_year_amount.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'plan_period_amount'}
                  highlighted={highlights.includes('plan_period_amount')}
                  onToggleHighlight={() => onToggleHighlight('plan_period_amount')}
                >
                  {fields.plan_period_amount.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'plan_month_amount'}
                  highlighted={highlights.includes('plan_month_amount')}
                  onToggleHighlight={() => onToggleHighlight('plan_month_amount')}
                >
                  {fields.plan_month_amount.label}
                </TableHeaderCell>
              </Col>

              <Col
                className="border-right"
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'fact_period_amount'}
                  highlighted={highlights.includes('fact_period_amount')}
                  onToggleHighlight={() => onToggleHighlight('fact_period_amount')}
                >
                  {fields.fact_period_amount.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'balance'}
                  highlighted={highlights.includes('balance')}
                  onToggleHighlight={() => onToggleHighlight('balance')}
                >
                  {fields.balance.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'fin_commitment_amount'}
                  highlighted={highlights.includes('fin_commitment_amount')}
                  onToggleHighlight={() => onToggleHighlight('fin_commitment_amount')}
                >
                  {fields.fin_commitment_amount.label}
                </TableHeaderCell>
              </Col>

              {(ABVisible || isComposeSettingsChecked)
              && (
                <Col className="border-right" sm={12} lg={3}>
                  {ABVisible && (
                    <>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'balanceRegAccounts'}
                        highlighted={highlights.includes('balanceRegAccounts')}
                        onToggleHighlight={() => onToggleHighlight('balanceRegAccounts')}
                      >
                        {fields.balanceRegAccounts.label}
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'balancePersAccounts'}
                        highlighted={highlights.includes('balancePersAccounts')}
                        onToggleHighlight={() => onToggleHighlight('balancePersAccounts')}
                      >
                        {fields.balancePersAccounts.label}
                      </TableHeaderCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TableHeaderCell
                      className="text-center text-xl-left"
                      active={activeCol === 'amount'}
                      title={totals.toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes('amount')}
                      onToggleHighlight={() => onToggleHighlight('amount')}
                    >
                      {fields.amount.label}
                    </TableHeaderCell>
                  )}
                </Col>
              )}

              <Col
                className="border-right"
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                {!isComposeSettingsChecked && !ABVisible && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'amount'}
                  highlighted={highlights.includes('amount')}
                  title={totals.toLocaleString('uk', moneysStingOptions)}
                  onToggleHighlight={() => onToggleHighlight('amount')}
                >
                  {fields.amount.label}
                </TableHeaderCell>
                )}
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'purpose_of_payment'}
                  highlighted={highlights.includes('purpose_of_payment')}
                  onToggleHighlight={() => onToggleHighlight('purpose_of_payment')}
                >
                  {fields.purpose_of_payment.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'remark_template'}
                  highlighted={highlights.includes('remark_template')}
                  onToggleHighlight={() => onToggleHighlight('remark_template')}
                >
                  {fields.remark_template.label}
                </TableHeaderCell>
                {isComposeSettingsChecked && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'isReturn'}
                  highlighted={highlights.includes('isReturn')}
                  onToggleHighlight={() => onToggleHighlight('isReturn')}
                >
                  {fields.isReturn.label}
                </TableHeaderCell>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {isRefundByMonth && (
          <Col xl={2}>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'return_funding'}
                  highlighted={highlights.includes('return_funding')}
                  onToggleHighlight={() => onToggleHighlight('return_funding')}
                >
                  Вкл.
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'return_month'}
                  highlighted={highlights.includes('return_month')}
                  onToggleHighlight={() => onToggleHighlight('return_month')}
                >
                  {fields.return_month.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          )}
          <Col sm={12} xl={isRefundByMonth ? 5 : 6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'remark_note'}
              highlighted={highlights.includes('remark_note')}
              onToggleHighlight={() => onToggleHighlight('remark_note')}
            >
              {fields.remark_note.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={isRefundByMonth ? 5 : 6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fullremark'}
              highlighted={highlights.includes('fullremark')}
              onToggleHighlight={() => onToggleHighlight('fullremark')}
            >
              {fields.fullremark.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.number,
  disposerInHeader: PropTypes.bool,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  isComposeSettingsChecked: PropTypes.bool,
  isRefundByMonth: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  isComposeSettingsChecked: false,
  totals: 0,
};

export default Header;
