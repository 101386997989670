import {
  reqListerColumns,
  defaultOrder,
  defaultColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  CurrentBudget: {
    label: 'Поточний бюджет',
    name: 'ТекущийБюджет',
    visible: true,
  },
  owner: { label: 'Бюджет', name: 'Владелец', visible: true },
};
  // frontend: `${frontendPrefix}/InfoSources`,
  //   backend: `${backendPrefix}/ИсточникиИнформации`,
  //   listColumns: [...reqListColumns],
  //   hierarchy: false,
// label: 'Джерела інформації',
//   name: 'ИсточникиИнформации',

export const infoSources = {
  label: 'Джерела інформації',
  name: 'infoSources',
  backendName: 'ИсточникиИнформации',
  defaultOrder,
  columns,
  frontend: 'cat/infoSources',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default infoSources;
