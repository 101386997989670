import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { TextareaStyled } from './styles';

function GetItemField({
  value,
  onChange,
  searchText,
  disabled,
  required,
  tabIndex,
  onDoubleClick,
  canFocused,
  // rows -  выстока в строках
  rows,
  noBorder,
  noBackground,
  isFocusCell,
}) {
  const refInput = useRef(null);

  useEffect(() => {
    if (canFocused && isFocusCell) refInput.current.focus();
  }, [canFocused, isFocusCell]);

  const onFocusHandler = (e) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  return (
    <TextareaStyled
      title={value.get('repr', '')}
      required={(!value || (value.get('repr', '')) === '') && required}
      value={searchText === null ? (value.get && value.get('repr', '')) || '' : searchText}
      onChange={(e) => onChange(e, e.target.value)}
      disabled={disabled}
      onFocus={onFocusHandler}
      ref={refInput}
      tabIndex={tabIndex}
      onDoubleClick={onDoubleClick}
      rows={rows}
      noBorder={noBorder}
      noBackground={noBackground}
      isFocusCell={isFocusCell}
    />
  );
}

GetItemField.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func,
  searchText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFocused: PropTypes.bool,
  tabIndex: PropTypes.number,
  onDoubleClick: PropTypes.func,
  rows: PropTypes.number,
  noBorder: PropTypes.bool,
  noBackground: PropTypes.bool,
  isFocusCell: PropTypes.bool,
};

GetItemField.defaultProps = {
  onChange: () => null,
  searchText: null,
  disabled: false,
  required: false,
  canFocused: true,
  tabIndex: null,
  onDoubleClick: null,
  rows: 1,
  noBorder: false,
  noBackground: false,
  isFocusCell: false,
};

export default GetItemField;
