import { useState, useEffect, useRef } from 'react';

const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const MINUS = ['-'];
const DECIMALS = ['.', ','];
const DELETES = ['Delete'];
const BACKSPACES = ['Backspace'];
const ARROW_RIGHT_LEFT = ['ArrowRight', 'ArrowLeft'];

const ALLOWED_KEYS = [
  ...DIGITS,
  ...MINUS,
  ...DECIMALS,
  ...DELETES,
  ...BACKSPACES,
];

const useNumberInput = ({
  valueInit, minValue = 3,
  maxValue, isFocusCell, onChange,
  FractionSize = 2,
  onlyPozitive = false,
  onChangeSumField,
  // AllSize = 17,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue((valueInit || 0).toFixed(FractionSize));
  }, [FractionSize, valueInit]);

  const [errText, setErrText] = useState('');
  const [calcOpened, setCalcOpened] = useState(false);
  const [cursor, setCursor] = useState({ start: 0, end: 0 });
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocusCell) {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursor.start, cursor.end);
        inputRef.current.focus();
      }
    }
  }, [valueInit, value, cursor.start, cursor.end, isFocusCell]);

  const propChanger = (e) => {
    const newValue = parseFloat(value);
    if ((minValue || minValue === 0) && newValue !== 0 && newValue < minValue) {
      setErrText(`Значення не може бути меньне ніж ${minValue}`);
      setValue(minValue.toString());

      e.preventDefault();
    } else if ((maxValue || maxValue === 0) && newValue !== 0 && newValue > maxValue) {
      setErrText(`Значення не може бути більше ніж ${maxValue}`);
      setValue(maxValue.toString());
      e.preventDefault();
    } else if (value !== newValue || newValue === 0) {
      onChange(e, newValue);

      setErrText('');
    }
  };

  const keyDown = (e) => {
    const { start } = cursor;
    const eKey = e.key;
    if (ALLOWED_KEYS.includes(eKey)) {
      e.preventDefault();
      if (DECIMALS.includes(eKey) && FractionSize) {
        const str = e.target.value.replace(',', '.');
        const dotPos = str.indexOf('.');
        setCursor({ start: dotPos + 1, end: dotPos + 1 });
      } else if (MINUS.includes(eKey) && !onlyPozitive) {
        if (e.target.value.substr(0, 1) === '-') {
          setValue(value.substr(1));

          setCursor({
            start: start === 0 ? start : start - 1,
            end: start === 0 ? start : start - 1,
          });
        } else {
          setValue(`-${value}`);
          setCursor({
            start: start + 1,
            end: start + 1,
          });
        }
      } else if (DIGITS.includes(eKey)) {
        const dotPos = value.indexOf('.');
        const override = (value.substr(0, dotPos) === '0') && cursor.start <= dotPos;
        const newValueText = `${value.substr(override ? cursor.start : 0, cursor.start)}${eKey}${value.substr(override ? dotPos : cursor.end)}`;
        const newValueNumber = Number(newValueText);
        if (!Number.isNaN(newValueNumber)) {
          setValue(newValueNumber.toFixed(FractionSize));
          setCursor({
            start: start + 1,
            end: start + 1,
          });
        }
      } else if (DELETES.includes(eKey)) {
        const override = cursor.start !== cursor.end;
        const newValueText = `${value.substr(0, cursor.start)}${value.substr(override ? cursor.end : cursor.end + 1)}`;
        const newValueNumber = Number(newValueText);
        if (!Number.isNaN(newValueNumber)) {
          setValue(newValueNumber.toFixed(FractionSize));
        }
      } else if (BACKSPACES.includes(eKey)) {
        const override = cursor.start !== cursor.end;
        const newValueText = `${value.substr(0, override ? cursor.start : cursor.start - 1)}${value.substr(cursor.end)}`;
        const newValueNumber = Number(newValueText);
        if (!Number.isNaN(newValueNumber)) {
          setValue(newValueNumber.toFixed(FractionSize));
          setCursor({
            start: override ? start : start - 1,
            end: override ? start : start - 1,
          });
        }
      }
    } else if (!ARROW_RIGHT_LEFT.includes(eKey) && (eKey !== 'Enter') && (eKey !== 'Tab')) {
      e.preventDefault();
    }
    if ((e.key === 'Enter') || (e.key === 'Tab')) {
      onChangeSumField(e, Number(value));
    }
  };

  const keyUp = (e) => {
    if (ARROW_RIGHT_LEFT.includes(e.key)) {
      setCursor({
        start: e.target.selectionStart,
        end: e.target.selectionStart,
      });
    }
  };

  return {
    value,
    setValue,
    errText,
    calcOpened,
    setCalcOpened,
    inputRef,
    keyDown,
    keyUp,
    propChanger,
  };
};
export default useNumberInput;
