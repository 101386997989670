import React, {
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/payment';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';

const FOLister = ListerFactory({
  modelType: 'doc',
  modelName: 'payment',
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
});

function Lister() {
  const {
    FO, isAdmin,
  } = useSelector(mapState);

  const flt = useMemo(
    () => (isAdmin ? [] : [{
      fieldName: md.columns.FI.name,
      value: FO.toJS(),
    }]),
    [FO, isAdmin],
  );
  return (
    <FOLister filter={flt} />
  );
}

export default Lister;
