import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  year: { label: 'Рік', name: 'ГодОтчета' },
};

const definition = {
  label: 'Аналіз орієнтовних граничних показників',
  name: 'AnalysisGOV',
  columns,
  frontend: 'rep/AnalysisGOV',
  backendName: 'АнализГОВ',
  resultColumns: baseResultColumns,
};

export default definition;
