import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  notes: { label: 'Коментар', name: 'Комментарий', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  account: { label: 'Банківський рахунок розпорядника', name: 'БанковскийСчет', visible: true },
  client: { label: 'Постачальник', name: 'Контрагент', visible: true },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: true },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  paymentPurpose: { label: 'Призначення платежу', name: 'НазначениеПлатежа', visible: false },
  isPaided: {
    label: 'Проведено банком',
    name: 'Оплачено',
    visible: true,
    type: 'bool',
  },
  paymentDate: { label: 'Дата оплати', name: 'ДатаОплаты', visible: false },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  rNo: { label: 'Реєстраційний номер', name: 'РегНомер', visible: true },
  paymentPurposeTemplate: { label: 'Шаблон (для призначення платежу)', name: 'Шаблон', visible: false },
  KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ', visible: false },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  clientAccount: { label: 'Банківський рахунок постачальника', name: 'БанковскийСчетКонтрагента', visible: true },
  financeObligation: { label: 'Фінансове зобов\'язання', name: 'ФинансовоеОбязательство', visible: false },
  dontFinance: { label: 'Не проводити фінансування', name: 'НеПроводитьФинансирование', visible: false },
  codesKEKV: { label: 'КЕКВ', name: 'КодыКЕКВ', visible: true },
  // // // Дополнитльеные реквизиты
  elememtKDBVisible: { label: 'ДанныеОбязательстваЭлементКДБВидимость', name: 'ДанныеОбязательстваЭлементКДБВидимость', visible: false },
  purposeMaxLength: { label: 'МаксДлиннаНазначенияПлатежа', name: 'МаксДлиннаНазначенияПлатежа' },
  result: { label: 'Результат', name: 'Результат' },
  activeObjectsBuilding: { label: 'Об\'єкт будівництва/ремонту', name: 'флОбъектыСтроительства' },
  typesRepair: { label: 'соотвВидРем', name: 'соотвВидРем' },
  budgetPayment: { label: 'Сплата до бюджету', name: 'ОплатаВБюджет', visible: false },
  comm: { label: 'Коментар', name: 'Комментарий', visible: false },
  org: { label: 'Установа', name: 'Организация', visible: false },
  budgetPaymentCode: { label: 'Код виду оплати до бюджету', name: 'КодВидаОплатыВБюджет', visible: false },
  KOATUU: { label: 'Код КОАТУУ', name: 'КодКОАТУУ', visible: false },
  info: { label: 'Податкова довідкова інформація', name: 'НалоговаяСправочнаяИнформация', visible: false },
  KDB: { label: 'Код доходів бюджету (тип)', name: 'КодДоходовБюджетаНалог', visible: false },
  messageInfo: { label: 'Інформація про податкове повідомлення (рішення)', name: 'ИнформацияПроНалоговоеСообщение', visible: false },
  clientTAX: { label: 'Контрагент (податок)', name: 'КонтрагентНалог', visible: false },
  acc: { label: 'Розрахунковий рахунок (податок)', name: 'РасчетныйСчетКонтрагентаНалог', visible: false },
  addInfo: { label: 'Додаткова інформація запису  про податок', name: 'ДополнительнаяИнформацияЗаписиПроНалог', visible: false },
  dismCred: { label: 'Без урахування фінансових зобов\'язань', name: 'ПогашениеКредита', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ДанныеОбязательства',
    label: 'Дані зобов\'язання',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      KEKVCode: { label: 'Код КЕКВ', name: 'КодКЕКВ' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      sum: { label: 'Сума', name: 'Сумма' },
      prepaid: { label: 'в т.ч. аванс', name: 'Аванс' },
      notes: { label: 'Коментар', name: 'Комментарий' },
      buildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Платіжне доручення',
  name: 'payment',
  backendName: 'ПлатежноеПоручениеИсходящее',
  columns,
  frontend: 'doc/payment',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'rNo',
      'account',
      'FI',
      'client',
      'clientAccount',
      'codesKEKV',
      'elementKFK',
      'fondObject',
      'sum',
      'isPaided',
      'author',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
