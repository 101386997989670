/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { moneysOneDigitStingOptions } from '../../constants/moneys';
// import Styled from "styled-components";

// ${({ noBackground }) => (noBackground ? 'none' : 'transparent')};

// const StyledDiv = Styled.div`
//
// margin-left: ${({level})=>("${2 * level}"px )}
// `;

function TableRow({ revenues, level }) {
  return (
    <tr className={`text-${(level === 1) ? 'black' : 800}`}>
      <td>
        <div
          style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}
        >
          {revenues.kdb.code}
        </div>
      </td>
      <td>
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.kdb.name}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_total_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_total_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_zf_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_zf_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {revenues.amount_sf_plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end">
        <div style={{ marginLeft: `${2 * level}px` }}>
          {revenues.amount_sf_fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  revenues: PropTypes.shape({
    kdb: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    amount_total_fact: PropTypes.number,
    amount_total_plan: PropTypes.number,
    amount_zf_fact: PropTypes.number,
    amount_zf_plan: PropTypes.number,
    amount_sf_fact: PropTypes.number,
    amount_sf_plan: PropTypes.number,
  }).isRequired,
  level: PropTypes.number.isRequired,
};

export default TableRow;
