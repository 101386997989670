import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  selDate: { label: 'Дата', name: 'выбДата' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  printFrom: { label: 'Друкувати мету та назву за рішенням сесії', name: 'флПечатьРеквизитовПрогИзГлРасп' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  year: { label: 'Рік', name: 'Год' },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  // contragent: { label: 'Виконавець', name: 'выбИсполнитель' },
  kbp: { label: 'Бюджетна програма', name: 'КлассификаторКБП' },
  signVariant: { label: 'Варіант підпису', name: 'Подпись' },
  // periodIndicator: { label: 'Звітний період', name: 'ПериодПоказателей' },
  // typeKFK: { label: 'Фл вид КФК', name: 'флВидКФК' },
  detProg: { label: 'Деталізація підпрограм', name: 'флДетализацияПодпрограмм' },
  dateOn: { label: 'Дата в заголовку', name: 'ДатаВШапке' },
  // moveCol: { label: 'Дозволити змінити розмір колонок та рядків', name: 'флДвигатьКолонки' },
  printAutor: { label: 'Друкувати автора і дату', name: 'флПечатьАвтора' },
  printN: { label: 'Друкувати номера документів', name: 'флПечатьНомеровДок' },
  // formName: { label: 'ФормаИмя', name: 'ФормаИмя' },
  targ: { label: 'Мета', name: 'Цель' },
  effPr: { label: 'Варіант друку звіту', name: 'ЭффПрил1' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  KPKV: { label: 'КПКВ', name: 'КлассификаторКБП7', visible: true },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  targetFromPass: {
    label: 'Друкувати мету з паспорта', name: 'флЦельИзПаспорта', visible: true, type: 'bool',
  },
  noCopecs: { label: 'Без десяткових знаків (копійок)', name: 'БезКопеек' },

};

const tables = {
  programs: {
    name: 'Программы',
    label: 'Програми/підпрограми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KTKVK: { label: 'КТКВК', name: 'КТКВК' },
      subProgram: { label: 'Програми/підпрограми', name: 'Программа' },
      elKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KPKVK: { label: 'КПКВК', name: 'КПКВК' },
      doc: { label: 'Документ', name: 'Документ' },
      fullName: { label: 'Найменування', name: 'ПолнНаименование' },
    },
  },
  tpDoc: {
    name: 'ТчДок',
    label: 'Паспорта бюджетних програм',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      doc: { label: 'Звіт з виконання', name: 'Документ' },
      csu: { label: 'Родитель', name: 'Родитель' },
      effect: { label: 'Эффективность', name: 'Эффективность' },
      effective: { label: 'СтепеньЭффективности', name: 'СтепеньЭффективности' },
      KPKVK: { label: 'КПКВК', name: 'КПКВК' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'indicatorsFactPrint',
  backendName: 'ПечатьПоказателиФакт',
  frontend: 'dp/indicatorsFactPrint',
  label: 'Звіт про виконання паспорта бюджетної програми',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
