import {
  reqListerColumns,
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  Comment: { label: 'Коментар', name: 'Комментарий', visible: false },
  ID: { label: 'ID', name: 'ИД', visible: true },
  FullName: { label: 'Повна Назва', name: 'ПолнНаименование', visible: true },
  ProjectAuthor: { label: 'Автор проекта', name: 'АвторПроекта', visible: true },
  ProjectYear: { label: 'Рік проекту', name: 'ГодПроекта', visible: true },
};

export const publicBudgetProject = {
  label: 'Довідник "Проекти громадського бюджету"',
  name: 'publicBudgetProject',
  backendName: 'ПроектГромадськогоБюджету',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/publicBudgetProject',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default publicBudgetProject;
