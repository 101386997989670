import PropTypes from 'prop-types';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  tariff_cat: PropTypes.string,
  kbp7: foreignPropType,
  amount_rank: PropTypes.number,
  amount_fact_rank: PropTypes.number,
  sum_year: PropTypes.number,
  salary: PropTypes.number,
  allowances: PropTypes.number,
  optional_allowances: PropTypes.number,
  for_min_salary: PropTypes.number,
  bonus: PropTypes.number,
  other: PropTypes.number,
  tax: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));

export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
