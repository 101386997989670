import base from './base';

/**
 * @const
 */
const columns = {
  id: { label: 'id', name: 'id', visible: false },
  repr: { label: 'Відображення', name: 'repr', visible: false },
  number: { label: 'Номер', name: 'Номер', visible: true },
  date: {
    label: 'Дата документу', name: 'Дата', visible: true, type: 'date',
  },
  isDeleted: { label: 'Видалений', name: 'ПометкаУдаления', visible: false },
  isProcessed: { label: 'Проведений', name: 'Проведен', visible: false },
};

/**
 * @const
 */
const tables = {};

/**
 * @const
 */
const definition = {
  label: 'Інформація про залишки коштів на рахунках',
  name: 'balancesAccounts',
  backendName: 'balancesAccounts',
  columns,
  frontend: 'doc/balancesAccounts',
  listColumns: base.listColumns,
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
  minfinOnly: true,
};

export default definition;
