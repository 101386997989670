import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';

import { bankAccounts as md } from '../../../../constants/meta/catalogs/bankAccounts';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { Title } from '../../../../components/Form/styledForm';

import enums from '../../../../constants/meta/enums';

function BankAccounts({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.owner.name]: value,
              });
              await actions.onSR('ПриИзменении_Владелец');
            }}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
        {!data[md.columns.NumeratorVisible.name] && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.Numerator.label}
              value={data[md.columns.Numerator.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.Numerator.name]: value,
              })}
              modelType="cat"
              modelName="numbering"
              readOnly={readOnly}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={29}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.SortCode.label}
            value={data[md.columns.SortCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SortCode.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`UsedForCommitments-${data.id}`}
            label={md.columns.UsedForCommitments.label}
            value={data[md.columns.UsedForCommitments.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UsedForCommitments.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Title>Перiоди</Title>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.OpenDate.label}
            value={data[md.columns.OpenDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.OpenDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.CloseDate.label}
            value={data[md.columns.CloseDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CloseDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`Inactive-${data.id}`}
            label={md.columns.Inactive.label}
            value={data[md.columns.Inactive.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Inactive.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.AccountType.label}
            value={data[md.columns.AccountType.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccountType.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(enums.BankAccaountTypes).map(({ name, label }) => (
              { value: name, display_name: label }
            ))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Bank.label}
            value={data[md.columns.Bank.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Bank.name]: value,
            })}
            modelType="cat"
            modelName="banks"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BankName.label}
            value={data[md.columns.BankName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BankName.name]: value,
            })}
            maxLength={38}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Fond.label}
            value={data[md.columns.Fond.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Fond.name]: value,
            })}
            modelType="cat"
            modelName="fondClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.ClassificatorKBP7.label}
            value={data[md.columns.ClassificatorKBP7.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ClassificatorKBP7.name]: value,
            })}
            modelType="cat"
            modelName="kbp7Classifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.AdditionalAccount.label}
            value={data[md.columns.AdditionalAccount.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AdditionalAccount.name]: value,
            })}
            maxLength={29}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.Note.label}
            value={data[md.columns.Note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Note.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

BankAccounts.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default BankAccounts;
