import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import { DivFooter } from '../../../../../components/styledTable/styledTable';

function FooterComponent({ rows }) {
  const totals = rows.reduce((v, row) => (v + row.get('sum', 0)), 0);

  return (
    <GridTemplate>
      <DivFooter areaName="1/1/1/3">Разом</DivFooter>
      <DivFooter areaName="sum">{totals.toFixed(2)}</DivFooter>
      <DivFooter areaName="1/4/1/6" />
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,

};

export default FooterComponent;
