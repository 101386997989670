import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { LevelDownRows2, PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import LoadLevelDownButton from './loadLeveldown';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { PrependFond, PrependKVK } from '../../styles/klassifiers';
import ApprovedInfo from '../../common/approvedInfo';
import useEditorParams from '../../../newEditor/hook/params';
import { WinManagerContext } from '../../../../providers';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.allocationplan.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { setWindowsHint } = useContext(WinManagerContext);
  useEffect(
    () => {
      const header = (
        <p>
          <strong> Сума: </strong>
          {data?.amount?.toLocaleString('uk')}
          {data?.amount_br ? ` в т.ч. БР: ${data.amount_br}` : ''}
        </p>
      );

      const body = (
        <p>
          {data?.description}
          <br />
          <strong>Фонд: </strong>
          {data?.fond?.repr}
          <br />
          <strong>Бюджетний рік: </strong>
          {data?.year}
          <br />
          <strong>Автор: </strong>
          {data?.author?.repr}
          <br />
          {data?.comment ? `Коментар: ${data.comment}` : ''}
        </p>
      );

      setWindowsHint(`${meta.doc.allocationplan.frontendURL}${id}/`, { header, body });
    },
    [
      data?.amount, data.amount_br, data?.author?.repr,
      data.comment, data?.description, data?.fond?.repr, data?.year,
      id, setWindowsHint,
    ],
  );

  // if (!data || !fields) return null;
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerInHeaderProps = editorHooks.useCheckboxInputProps('disposer_in_header', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isCreditProps = editorHooks.useCheckboxInputProps('is_credit', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const commentProps = editorHooks.useTextInputProps('comment', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          backendURL={meta.doc.allocationplan.backendURL}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                  )}
                />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} required />
              </Col>
              {data.disposer_in_header && (
                <Col>
                  <EditorControls.ItemPicker
                    {...disposerProps}
                    filter={{ parent: data.authority.id }}
                    noHierarchy
                    prepend={(
                      <PrependKVK>
                        {data.kvk && data.kvk.repr}
                      </PrependKVK>
                    )}
                  />
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              {!headerReadOnlyFields.includes('disposer_in_header') && (
                <Col md={2}>
                  <EditorControls.CheckboxInput {...disposerInHeaderProps} />
                </Col>
              )}
              {!headerReadOnlyFields.includes('only_year') && (
                <Col md={2}>
                  <EditorControls.CheckboxInput {...onlyYearProps} />
                </Col>
              )}
              {!headerReadOnlyFields.includes('vt4br') && (
                <Col md={2}>
                  <EditorControls.CheckboxInput {...vt4brProps} />
                </Col>
              )}
              {!headerReadOnlyFields.includes('is_credit') && (
                <Col md={2}>
                  <EditorControls.CheckboxInput {...isCreditProps} />
                </Col>
              )}
              <Col>
                <LoadLevelDownButton
                  data={data}
                  onDraft={actions.onDraft}
                  disabled={!permissions.canChange}
                />
              </Col>

            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.allocationplan.backendURL}${data.id}/`}
            />
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="allocationplan">
          {fields && fields.allocationplanrow_set && (
            <Tab eventKey="allocationplan" title={fields.allocationplanrow_set.label}>
              <TabContainer className="p-0">
                <PlanningRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.allocationplanleveldownrow_set && (
            <Tab eventKey="allocationplanleveldownrow_set" title={fields.allocationplanleveldownrow_set.label}>
              <TabContainer className="p-0">
                <LevelDownRows2
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          <Tab eventKey="comment" title={fields && fields.comment ? fields.comment.label : ''}>
            <Container fluid>
              <Row>
                <Col>
                  <EditorControls.TextInput {...commentProps} />
                </Col>
              </Row>
            </Container>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
