import React from 'react';
import PropTypes from 'prop-types';
import {
  faPlusSquare, faCaretSquareUp, faCaretSquareDown,
  faClone, faMinusSquare, faSearchDollar, faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import { AddRowButton, RemoveRowButton } from '../../../components/bootStrap/buttons';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import { CommandPanel } from '../../../components/bootStrap';
import { childrenPropType } from '../../../constants/backend/propTypes';

function TPCommandPanel({
  children, tableActions, readOnly, canAdd, canDelete, canMove,
  activeRow, pinHeader, pinAllowed, showFooter,
}) {
  return (
    <CommandPanel
      className="bg-white border-bottom rounded-top"
      leftPart={(
        <>
          <AddRowButton
            onClick={() => tableActions.onAddRow()}
            disabled={readOnly || !canAdd}
            label="Додати (Insert)"
            icon={faPlusSquare}
          />
          <CPButton
            onClick={() => tableActions.onMoveUpRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити вище"
            icon={faCaretSquareUp}
          />
          <CPButton
            onClick={() => tableActions.onMoveDownRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити нижче"
            icon={faCaretSquareDown}
          />
          <CPButton
            onClick={() => tableActions.onCopyRow(activeRow)}
            disabled={readOnly || !canAdd || activeRow === null}
            label="Копіювати (F9)"
            icon={faClone}
          />
          <RemoveRowButton
            onClick={() => tableActions.onDeleteRow(activeRow)}
            disabled={readOnly || !canDelete || activeRow === null}
            label="Видалити (F8)"
            icon={faMinusSquare}
          />
          {children}
        </>
      )}
      rightPart={(
        <>
          <CPButton
            onClick={() => tableActions.onToggleFooter()}
            title="Показати підвал"
            variant={showFooter ? 'falcon-info' : 'falcon-default'}
            icon={faSearchDollar}
            disabled={showFooter === null}
          />
          <CPButton
            onClick={() => tableActions.onTogglePin()}
            title="Закріпити заговолок та підвал"
            variant={pinHeader ? 'falcon-info' : 'falcon-default'}
            icon={faThumbtack}
            disabled={!pinAllowed}
          />
        </>
      )}
    />
  );
}

TPCommandPanel.propTypes = {
  tableActions: PropTypes.shape({
    onAddRow: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    onMoveUpRow: PropTypes.func.isRequired,
    onMoveDownRow: PropTypes.func.isRequired,
    onCopyRow: PropTypes.func.isRequired,
    onToggleFooter: PropTypes.func.isRequired,
    onTogglePin: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: childrenPropType,
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  canMove: PropTypes.bool,
  activeRow: PropTypes.number,
  showFooter: PropTypes.bool,
  pinHeader: PropTypes.bool.isRequired,
  pinAllowed: PropTypes.bool,
};

TPCommandPanel.defaultProps = {
  readOnly: false,
  children: null,
  canAdd: true,
  canDelete: true,
  canMove: true,
  activeRow: null,
  pinAllowed: true,
  showFooter: null,
};

export default TPCommandPanel;
