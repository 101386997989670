import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { DCControls, mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/revTreasureReport';
import {
  ResultSegment,
  StyledLabel,
  TwoColGridContainer,
  ContainerDiv, DivWithPropsLabel, StyledPane,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import DateInput from '../../../components/fields/dates';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';
import CheckBoxField from '../../field/CheckBox';
import Tabs from '../../../components/tabs/Tabs';

const TwoColContainer = styled(TwoColGridContainer)`
  grid-template-columns: 40% 60% ;
`;

const typeQuartList = {
  p1: { name: 1, label: '1 квартал' },
  p2: { name: 2, label: '2 квартал' },
  p3: { name: 3, label: '3 квартал' },
  p4: { name: 4, label: '4 квартал' },
  p5: { name: 5, label: 'Рік' },
  p6: { name: 6, label: 'Січень' },
  p7: { name: 7, label: 'Лютий' },
  p8: { name: 8, label: 'Березень' },
  p9: { name: 9, label: 'Квітень' },
  p10: { name: 10, label: 'Травень' },
  p11: { name: 11, label: 'Червень' },
  p12: { name: 12, label: 'Липень' },
  p13: { name: 13, label: 'Серпень' },
  p14: { name: 14, label: 'Вересень' },
  p15: { name: 15, label: 'Жовтень' },
  p16: { name: 16, label: 'Листопад' },
  p17: { name: 17, label: 'Грудень' },
};

const typeYearList = {
  p1: { name: 2016, label: '2016' },
  p2: { name: 2017, label: '2017' },
  p3: { name: 2018, label: '2018' },
  p4: { name: 2019, label: '2019' },
  p5: { name: 2020, label: '2020' },
  p6: { name: 2021, label: '2021' },
  p7: { name: 2022, label: '2022' },
  p8: { name: 2023, label: '2023' },
};

const typeAnalysisList = {
  Z2M_ZVED: { name: 'Z2M_ZVED', label: 'Звіт про виконання місцевих бюджетів  (зведення, місячний)' },
  Z2M_ZVEDz: { name: 'Z2M_ZVEDz', label: 'Звіт про виконання місцевих бюджетів  (зведення, місячний) (звед)' },
  Z2M_ZVEDzPor: { name: 'Z2M_ZVEDzPor', label: 'Звіт про виконання місцевих бюджетів  (зведення, місячний) (звед) (порівняння)' },
  Z2M_1: { name: 'Z2M_1', label: 'Доходи  (місячний) }' },
  Z2M_1Por: { name: 'Z2M_1Por', label: 'Доходи  (місячний) (порівняння)' },
  Z2M_2: { name: 'Z2M_2', label: 'Видатки  (місячний)' },
  Z2M_2zPor: { name: 'Z2M_2zPor', label: 'Видатки  (місячний)  (порівняння)' },
  Z2M_3: { name: 'Z2M_3', label: 'Кредитування  (місячний)' },
  Z2M_3Por: { name: 'Z2M_3Por', label: 'Кредитування  (місячний) (порівняння)' },
  Z2M_4: { name: 'Z2M_4', label: 'Фінансування бюджету  (місячний)' },
  Z2M_4Por: { name: 'Z2M_4Por', label: 'Фінансування бюджету  (місячний) (порівняння)' },
  DOD4M: { name: 'DOD4M', label: 'Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету(місячний, квартальний,  річний)' },
  DOD4Mz: { name: 'DOD4Mz', label: 'Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету(місячний, квартальний,  річний) (звед)' },
  DOD4MzPor: { name: 'DOD4MzPor', label: 'Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету(місячний, квартальний,  річний) (звед) (порівняння)' },
  DOD7K: { name: 'DOD7K', label: 'Інформація про виконання захищених статей видатків місцевих бюджетів (місячна, річна)' },
  DOD7Kz: { name: 'DOD7Kz', label: 'Інформація про виконання захищених статей видатків місцевих бюджетів (місячна, річна) (звед)' },
  DOD7KzPor: { name: 'DOD7KzPor', label: 'Інформація про виконання захищених статей видатків місцевих бюджетів (місячна, річна) (звед) (порівняння)' },
  DOD8K: { name: 'DOD8K', label: 'Звіт про використання  коштів з резервного фонду ( місячний, річний)' },
  DOD8Kz: { name: 'DOD8Kz', label: 'Звіт про використання  коштів з резервного фонду ( місячний, річний) (звед)' },
  DOD8KzPor: { name: 'DOD8KzPor', label: 'Звіт про використання  коштів з резервного фонду ( місячний, річний) (звед) (порівняння)' },
  Z7K_1Z: { name: 'Z7K_1Z', label: 'Звіт про бюджетну заборгованість по загальному фонду (місячний, квартальний, річний)' },
  Z7K_1Zz: { name: 'Z7K_1Zz', label: 'Звіт про бюджетну заборгованість по загальному фонду (місячний, квартальний, річний) (звед)' },
  Z7K_1ZzPor: { name: 'Z7K_1ZzPor', label: 'Звіт про бюджетну заборгованість по загальному фонду (місячний, квартальний, річний) (звед) (порівняння)' },
  Z7K_1S: { name: 'Z7K_1S', label: 'Звіт про бюджетну заборгованість  по спеціальному  фонду (місячний, квартальний, річний)' },
  Z7K_1Sz: { name: 'Z7K_1Sz', label: 'Звіт про бюджетну заборгованість  по спеціальному  фонду (місячний, квартальний, річний) (звед)' },
  Z7K_1SzPor: { name: 'Z7K_1SzPor', label: 'Звіт про бюджетну заборгованість  по спеціальному  фонду (місячний, квартальний, річний) (звед) (порівняння)' },
  Z7K_3Z: { name: 'Z7K_3Z', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна)  заг фонд' },
  Z7K_3Zz: { name: 'Z7K_3Zz', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна)  заг фонд (звед)' },
  Z7K_3ZzPor: { name: 'Z7K_3ZzPor', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна)  заг фонд (звед) (порівняння)' },
  Z7K_3S: { name: 'Z7K_3S', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна) спец фонд' },
  Z7K_3Sz: { name: 'Z7K_3Sz', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна) спец фонд (звед)' },
  Z7K_3SzPor: { name: 'Z7K_3SzPor', label: 'Інформація про заборгованість розпорядників та одержувачів коштів місцевих бюджетів за окремими програмами по загальному фонду (місячна, квартальна, річна) спец фонд (звед) (порівняння)' },
  Z1K_BAL: { name: 'Z1K_BAL', label: 'Баланс ( квартальний, річний)' },
  DOD3K: { name: 'DOD3K', label: 'Звіт про фінансові результати виконання місцевих бюджетів (квартальний, річний)' },
  Z2K_ZVED: { name: 'Z2K_ZVED', label: 'Звіт про виконання місцевих бюджетів (зведений, річний)' },
  Z2K_ZVEDz: { name: 'Z2K_ZVEDz', label: 'Звіт про виконання місцевих бюджетів (зведений, річний) (звед)' },
  Z2K_ZVEDzPor: { name: 'Z2K_ZVEDzPor', label: 'Звіт про виконання місцевих бюджетів (зведений, річний) (звед) (порівняння)' },
  Z2K_11Z: { name: 'Z2K_11Z', label: 'Доходи загального фонду (квартальний, річний)' },
  Z2K_11Zz: { name: 'Z2K_11Zz', label: 'Доходи загального фонду (квартальний, річний) (звед)' },
  Z2K_11ZzPor: { name: 'Z2K_ZVEDzPor', label: 'Доходи загального фонду (квартальний, річний) (звед) (порівняння)' },
  Z2K_12S: { name: 'Z2K_12S', label: 'Доходи спеціального фонду (квартальний, річний)' },
  Z2K_12Sz: { name: 'Z2K_12Sz', label: 'Доходи спеціального  фонду (квартальний, річний) (звед) ' },
  Z2K_12SzPor: { name: 'Z2K_12SzPor', label: 'Доходи спеціального фонду (квартальний, річний) (звед) (порівняння)' },
  Z2K_21Z: { name: 'Z2K_21Z', label: 'Видатки загального фонду (квартальний, річний)' },
  Z2K_21Zz: { name: 'Z2K_21Zz', label: 'Видатки загального фонду (квартальний, річний) (звед)' },
  Z2K_21ZzPor: { name: 'Z2K_21ZzPor', label: 'Видатки загального фонду (квартальний, річний) (звед) (порівняння)' },
  Z2K_22S: { name: 'Z2K_22S', label: 'Видатки спеціального фонду (квартальний, річний)' },
  Z2K_22Sz: { name: 'Z2K_22Sz', label: 'Видатки спеціального фонду (квартальний, річний) (звед)' },
  Z2K_22SzPor: { name: 'Z2K_22SzPor', label: 'Видатки спеціального  фонду (квартальний, річний) (звед) (порівняння)' },
  Z2K_22SB: { name: 'Z2K_22SB', label: 'Видатки спеціального фонду за коштами на рахунках в банках (квартальний, річний)' },
  Z2K_22SBz: { name: 'Z2K_22SBz', label: 'Видатки спеціального фонду за коштами на рахунках в банках (квартальний, річний) (звед)' },
  Z2K_22SBzPor: { name: 'Z2K_22SBzPor', label: 'Видатки спеціального фонду за коштами на рахунках в банках (квартальний, річний) (звед) (порівняння)' },
  Z2K_22SE: { name: 'Z2K_22SE', label: 'Видатки спеціального фонду місцевих бюджетів за коштами на рахунках в органах Державної казначейської служби України (квартальний, річний)' },
  Z2K_22SEz: { name: 'Z2K_22SEz', label: 'Видатки спеціального фонду місцевих бюджетів за коштами на рахунках в органах Державної казначейської служби України (квартальний, річний) (звед)' },
  Z2K_22SEzPor: { name: 'Z2K_22SEzPor', label: 'Видатки спеціального фонду місцевих бюджетів за коштами на рахунках в органах Державної казначейської служби України (квартальний, річний) (звед) (порівняння)' },
  Z2K_221S: { name: 'Z2K_221S', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги, що надаються бюджетними установами  (квартальний, річний)' },
  Z2K_221Sz: { name: 'Z2K_221Sz', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги, що надаються бюджетними установами  (квартальний, річний) (звед)' },
  Z2K_221SzPor: { name: 'Z2K_221SzPor', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги, що надаються бюджетними установами  (квартальний, річний) (звед) (порівняння)' },
  Z2K_221SB: { name: 'Z2K_221SB', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках в банках (квартальний, річний)' },
  Z2K_221SBz: { name: 'Z2K_221SBz', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках в банках (квартальний, річний) (звед)' },
  Z2K_221SBzPor: { name: 'Z2K_221SBzPor', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках в банках (квартальний, річний) (звед) (порівняння)' },
  Z2K_221SE: { name: 'Z2K_221SE', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках Державної казначейської служби України   (квартальний, річний)' },
  Z2K_221SEz: { name: 'Z2K_221SEz', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках Державної казначейської служби України   (квартальний, річний) (звед)' },
  Z2K_221SEzPor: { name: 'Z2K_221SEzPor', label: '2.2.1.Видатки спеціального фонду, проведені за рахунок коштів, отриманих як плата за послуги,  що надаються бюджетними установами, за коштами на рахунках Державної казначейської служби України   (квартальний, річний) (звед) (порівняння)' },
  Z2K_222S: { name: 'Z2K_222S', label: '2.2.2.Видатки спеціального фонду, проведені за рахунок коштів, отриманих з інших джерел власних надходжень бюджетних установ (квартальний, річний)' },
  Z2K_222Sz: { name: 'Z2K_222Sz', label: '2.2.2.Видатки спеціального фонду, проведені за рахунок коштів, отриманих з інших джерел власних надходжень бюджетних установ (квартальний, річний) (звед)' },
  Z2K_222SzPor: { name: 'Z2K_222SzPor', label: '2.2.2.Видатки спеціального фонду, проведені за рахунок коштів, отриманих з інших джерел власних надходжень бюджетних установ (квартальний, річний) (звед) (порівняння)' },
  Z2K_222SB: { name: 'Z2K_222SB', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках в банках (квартальний, річний)' },
  Z2K_222SBz: { name: 'Z2K_222SBz', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках в банках (квартальний, річний) (звед)' },
  Z2K_222SBzPor: { name: 'Z2K_222SBzPor', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках в банках (квартальний, річний) (звед) (порівняння)' },
  Z2K_222SE: { name: 'Z2K_222SE', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках органах Державної казначейської служби України   (квартальний, річний) ' },
  Z2K_222SEz: { name: 'Z2K_222SEz', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках органах Державної казначейської служби України   (квартальний, річний) (звед)' },
  Z2K_222SEzPor: { name: 'Z2K_222SEzPor', label: '2.2.2.Видатки спеціального фонду бюджету, проведені за рахунок коштів,отриманих з інших джерел власних надходжень бюджетних установ, за коштами на рахунках органах Державної казначейської служби України   (квартальний, річний) (звед) (порівн)' },
  Z2K_223S: { name: 'Z2K_223S', label: '2.2.3. Видатки спеціального фонду, проведені за рахунок доходів спеціального фонду бюджету,  що направляються на спеціальні видатки (квартальний, річний)' },
  Z2K_223Sz: { name: 'Z2K_222SEz', label: '2.2.3. Видатки спеціального фонду, проведені за рахунок доходів спеціального фонду бюджету,  що направляються на спеціальні видатки (квартальний, річний) (звед)' },
  Z2K_223SzPor: { name: 'Z2K_222SEzPor', label: '2.2.3. Видатки спеціального фонду, проведені за рахунок доходів спеціального фонду бюджету,  що направляються на спеціальні видатки (квартальний, річний) (звед) (порівняння)' },
  Z2K_223SB: { name: 'Z2K_223SB', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в банках  (квартальний, річний)' },
  Z2K_223SBz: { name: 'Z2K_223SBz', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в банках  (квартальний, річний) (звед)' },
  Z2K_223SBzPor: { name: 'Z2K_223SBzPor', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в банках  (квартальний, річний) (звед) (порівняння)' },
  Z2K_223SE: { name: 'Z2K_223SE', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в органах Державної казначейської служби України   (квартальний, річний) ' },
  Z2K_223SEz: { name: 'Z2K_223SEz', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в органах Державної казначейської служби України   (квартальний, річний) (звед)' },
  Z2K_223SEzPor: { name: 'Z2K_223SEzPor', label: '2.2.3.Видатки спеціального фонду бюджету, проведені за рахунок доходів спеціального фонду бюджету, що направляються на спеціальні видатки, за коштами на рахунках в органах Державної казначейської служби України   (квартальний, річний) (звед) (порівняння)' },
  Z2K_31Z: { name: 'Z2K_31Z', label: 'Кредитування загального фонду (квартальний, річний)' },
  Z2K_31Zz: { name: 'Z2K_31Zz', label: 'Кредитування загального фонду (квартальний, річний) (звед)' },
  Z2K_31ZzPor: { name: 'Z2K_31ZzPor', label: 'Кредитування загального фонду (квартальний, річний) (звед) (порівняння)' },
  Z2K_32S: { name: 'Z2K_32S', label: 'Кредитування спеціального фонду (квартальний, річний)' },
  Z2K_32Sz: { name: 'Z2K_32Sz', label: 'Кредитування спеціального фонду (квартальний, річний)  (звед)' },
  Z2K_32SzPor: { name: 'Z2K_31ZzPor', label: 'Кредитування спеціального фонду (квартальний, річний)  (звед) (порівняння)' },
  Z2K_41Z: { name: 'Z2K_41Z', label: 'Фінансування загального фонду місцевих бюджетів (квартальний, річний)' },
  Z2K_41Zz: { name: 'Z2K_41Zz', label: 'Фінансування загального фонду місцевих бюджетів (квартальний, річний) (звед)' },
  Z2K_41ZzPor: { name: 'Z2K_41ZzPor', label: 'Фінансування загального фонду місцевих бюджетів (квартальний, річний) (звед) (порівняння)' },
  Z2K_42S: { name: 'Z2K_42S', label: 'Фінансування спеціального фонду місцевих бюджетів (квартальний, річний)' },
  Z2K_42Sz: { name: 'Z2K_42Sz', label: 'Фінансування спеціального фонду місцевих бюджетів (квартальний, річний) (звед)' },
  Z2K_42SzPor: { name: 'Z2K_42SzPor', label: 'Фінансування спеціального фонду місцевих бюджетів (квартальний, річний) (звед) (порівняння)' },
  Z2K_42SB: { name: 'Z2K_42SB', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках в банках (квартальний, річний)' },
  Z2K_42SBz: { name: 'Z2K_42SBz', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках в банках (квартальний, річний) (звед)' },
  Z2K_42SBzPor: { name: 'Z2K_42SBzPor', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках в банках (квартальний, річний) (звед) (порівняння)' },
  Z2K_42SE: { name: 'Z2K_42SE', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках  в органах Державної казначейської служби України   (квартальний, річний)' },
  Z2K_42SEz: { name: 'Z2K_42SEz', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках  в органах Державної казначейської служби України   (квартальний, річний) (звед)' },
  Z2K_42SEzPor: { name: 'Z2K_42SEzPor', label: 'Фінансування спеціального фонду місцевих бюджетів за коштами на рахунках  в органах Державної казначейської служби України   (квартальний, річний) (звед) (порівняння)' },
  DOD10K: { name: 'DOD10K', label: 'Інформація про стан місцевих боргів  (квартальний , річний)' },
  DOD11K: { name: 'DOD11K', label: 'Інформація про стан гарантованих відповідно АРК, територіальними громадами боргів (квартальний , річний)' },
  DOD123K: { name: 'DOD123K', label: 'Розшифровка  інформації відображеної на рахунках групи 123  "Інші рахунки за коштами місцевих бюджетів в банках" (річний)' },
  DOD152K: { name: 'DOD152K', label: 'Розшифровка  операцій віодбражених на рахунках групи 152 "Кредити, надані з бюджету мі. Києва» (квартальний, річний)' },
  DOD155K: { name: 'DOD155K', label: 'Розшифровка  операцій відображених на рахунках групи 155 “Кредити, що надавались під місцеві гарантії та/або за рахунок коштів, залучених місцевими бюджетами» (квартальний, річний).' },
  DOD4K: { name: 'DOD4K', label: 'Звіт про рух грошових коштів' },
  DOD14K: { name: 'DOD14K', label: 'Звіт про надання та використання  місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету' },
  DOD7M: { name: 'DOD7M', label: 'Інформація про виконання захищених статей видатків місцевих бюджетів  (місячний) ' },
  DOD8M: { name: 'DOD8M', label: 'Звіт про використання коштів з резервного фонду (місячний)' },
  DM_2020: { name: 'DM_2020', label: 'DM_2020 - Виконання місцевих бюджетів за доходами у розрізі усіх місцевих бюджетів (до рівня села, селища)' },
  V2MF_2020: { name: 'V2MF_2020', label: 'V2MF_2020 - Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища)' },
  V2MB_2020: { name: 'V2MB_2020', label: 'V2MB_2020 - Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища)' },
  KRM_2020: { name: 'KRM_2020', label: 'KRM_2020 - Виконання місцевих бюджетів за кредитуванням у розрізі усіх місцевих бюджетів (до рівня села, селища)' },
  FM_2020: { name: 'FM_2020', label: 'FM_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов\'язання у розрізі усіх місцевих бюджетів (до рівня села, селища)' },
  BRMB_M: { name: 'BRMB_M', label: 'BRMB_M - Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів' },
  V7MZ_2020: { name: 'V7MZ_2020', label: 'V7MZ_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ)- загальний фонд' },
  V7MS_2020: { name: 'V7MS_2020', label: 'V7MS_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ)- спеціальний фонд' },
  V73Z_2020: { name: 'V73Z_2020', label: 'V73Z_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - загальний фонд' },
  V73S_2020: { name: 'V73S_2020', label: 'V73S_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - спеціальний фонд' },
  ZAH_MB: { name: 'ZAH_MB', label: 'ZAH_MB - Інформація про виконання захищених видатків місцевих бюджетів' },
  MZS_2020: { name: 'MZS_2020', label: 'MZS_2020 - Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету' },
  RZM_M: { name: 'RZM_M', label: 'RZM_M - Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щомісячна інформація' },
  RZM_Y: { name: 'RZM_Y', label: 'RZM_Y - Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щорічна інформація' },
  V2KB_2020: { name: 'V2KB_2020', label: 'V2KB_2020 - Виконання місцевих бюджетів за видатками у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - за коштами на рахунках в банках' },
  BRMB_Q: { name: 'BRMB_Q', label: 'BRMB_Q - Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів' },
  MZS_2020Q: { name: 'MZS_2020Q', label: 'MZS_2020Q - Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об\'єднаних територіальних громад' },
  CTANBORG: { name: 'CTANBORG', label: 'CTANBORG - Інформація про стан місцевих боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад' },
  CTGARNBORG: { name: 'CTGARNBORG', label: 'CTGARNBORG - Інформація про стан гарантованих відповідно Автономною Республікою Крим, обласними радами та територіальними громадами боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад' },
  GARANTMB: { name: 'GARANTMB', label: 'GARANTMB - Інформація про надані місцеві гарантії у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад (щоквартальна, щорічна)' },
  DMZ_2020: { name: 'DMZ_2020', label: 'DMZ_2020 - Виконання місцевих бюджетів за доходами, загальний фонд' },
  DMS_2020: { name: 'DMS_2020', label: 'DMS_2020 - Виконання місцевих бюджетів за доходами, cпеціальний фонд' },
  V2MZ_2020: { name: 'V2MZ_2020', label: 'V2MZ_2020 - Виконання місцевих бюджетів за видатками (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього' },
  V2MS_2020: { name: 'V2MS_2020', label: 'V2MS_2020 - Виконання місцевих бюджетів за видатками (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього' },
  KRMZ_2020: { name: 'KRMZ_2020', label: 'KRMZ_2020 - Виконання місцевих бюджетів за видатками – кредитування (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього' },
  KRMS_2020: { name: 'KRMS_2020', label: 'KRMS_2020 - Виконання місцевих бюджетів за видатками – кредитування (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього' },
  FMZ_2020: { name: 'FMZ_2020', label: 'FMZ_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього' },
  FMS_2020: { name: 'FMS_2020', label: 'FMS_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього' },
  V7MZ_2020Q: { name: 'V7MZ_2020Q', label: 'V7MZ_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - загальний фонд' },
  V7MS_2020Q: { name: 'V7MS_2020Q', label: 'V7MS_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - спеціальний фонд' },
  V73Z_2020Q: { name: 'V73Z_2020Q', label: 'V73Z_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - загальний фонд' },
  V73S_2020Q: { name: 'V73S_2020Q', label: 'V73S_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - спеціальний фонд' },
};

const importedAnalysis = ['DM_2020', 'BRMB_M',
  'V2MF_2020', 'V2MB_2020', 'KRM_2020', 'FM_2020',
  'V7MZ_2020', 'V7MS_2020', 'V73Z_2020', 'V73S_2020',
  'ZAH_MB', 'MZS_2020', 'RZM_M', 'RZM_Y', 'V2KB_2020',
  'BRMB_Q', 'MZS_2020Q', 'CTANBORG', 'CTGARNBORG', 'GARANTMB',
  'DMZ_2020', 'DMS_2020', 'V2MZ_2020', 'V2MS_2020', 'KRMZ_2020',
  'KRMS_2020', 'FMZ_2020', 'FMS_2020', 'V7MZ_2020Q', 'V7MS_2020Q',
  'V73Z_2020Q', 'V73S_2020Q'];

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();
  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Групування">
        <StyledPane>
          {/* <StyledLabel>Для даного звіту не використовується</StyledLabel> */}
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const SettingsButton = (
    <div>
      <TwoColContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <CheckBoxField
          value={headerForm.get('consolidated', false)}
          label={md.columns.consolidated.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
        />
      </TwoColContainer>
      <div>
        <StyledLabel>{md.columns.cForm.label}</StyledLabel>
        <InputWithDropdown
          value={headerForm.get('cForm', false)}
          options={Object.values(typeAnalysisList)}
          onChange={(e, v) => {
            dispatch(changeField(['headerForm', 'cForm'], v));
            dispatch(processServerReq('CHANGE_SKD'));
          }}
        />
      </div>
      { importedAnalysis.includes(headerForm.get('cForm', false)) ? (
        <div>
          <StyledLabel>{md.columns.onDate.label}</StyledLabel>
          <DateInput
            value={headerForm.get('onDate', null)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'onDate'], v))}
          />
        </div>
      ) : (
        <div>
          <StyledLabel>{md.columns.quart.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('quart', false)}
            options={Object.values(typeQuartList)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'quart'], v))}
          />
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('year', false)}
            options={Object.values(typeYearList)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
          />
        </div>
      )}
      {Bookmarks}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div style={{ display: 'flex', 'align-items': 'center' }} />
      </ContainerDiv>
      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
