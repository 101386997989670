import { baseReportColumns } from '../_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  Document: { label: 'ДокументСсылка', name: 'ДокументСсылка' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  year: { label: 'Рiк', name: 'Год' },
  datePrinting: { label: 'Дата на яку друкується документ', name: 'ДатаПечати' },
  classifierKBP: { label: 'КБП', name: 'КлассификаторКБП' },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  detailsPrograms: { label: 'Деталізувати цільові програми', name: 'флЦелевыеПрогПоЭлФКФ' },
  SignVariant: { label: 'Варіант підпису', name: 'Подпись' },
  signVariantLabel: { label: 'ПодписьТекст', name: 'ПодписьТекст' },
  agreed: { label: 'Погоджено', name: 'Погоджено' },
  order: { label: 'Паспорт затверджуєтся розпорядженням', name: 'Приказ_Распоряжение' },
  printingTable7: { label: 'Друкувати підпрограми', name: 'флПечатьТабл7' },
  printingNumDoc: { label: 'Друкувати реквізити документів', name: 'флПечатьНомеровДок' },

  printingPageNumbers: { label: 'Друкувати номера сторінок', name: 'флПечатьНомераСтранины' },
  printingUAH: { label: 'Друкувати в грн.', name: 'флгрн' },
  printingTargetedPrograms: {
    label: 'Друкувати цільові програми (для випадку коли включена підсистема "Об\'єкти будівництва")',
    name: 'флПечатьРеквизитовПрогИзГлРасп',
  },
  laws1: { label: 'Наказ', name: 'Приказ1' },
  lawsStr1: { label: 'Наказ', name: 'Приказ1Стр' },
  laws2: { label: 'і наказ', name: 'Приказ2' },
  lawsStr2: { label: 'Наказ', name: 'Приказ2Стр' },
  groundLaw: {
    label: 'Законодавчі підстави для виконання бюджетної програми',
    name: 'ЗаконОснование',
  },
  target: { label: 'Мета', name: 'Цель' },
  boss: { label: 'Керівник установи', name: 'Руководитель' },
  buh: { label: 'Фінансист', name: 'Финансист' },
  naneSurname: { label: 'Ім\'я та ПРІЗВИЩЕ', name: 'флИмяФамилия' },
  targetFromDoc: { label: 'Друкувати підстави та мету з налаштувань друку', name: 'флПечатьОснованияЦельИзДокумента' },
  copecks: { label: 'копійки', name: 'флКоп' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  laws1number: { label: 'Номер наказу', name: 'Приказ1Номер' },
  laws1Date: { label: 'Дата наказу', name: 'Приказ1Дата' },
  KPKV: { label: 'КПКВ', name: 'КлассификаторКБП7', visible: true },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  targetFromSession: { label: 'Друкувати мету та назву за рішенням сесії', name: 'флПечатьРеквизитовПрогИзГлРасп' },
  signApprooved: { label: 'ПодписьСогласовано', name: 'ПодписьСогласовано' },
  apprByFO: { label: 'УтвФО', name: 'УтвФО' },
  signMFO: { label: 'ЕстьПодписиМФО', name: 'ЕстьПодписиМФО' },
  existDocSendToLogica: { label: 'ЕстьДокументОтправки', name: 'ЕстьДокументОтправки' },

  // // Дополнительные свойства
  // KievFormReadOnly: { label: 'ФормаКиевТолькоПросмотр', name: 'ФормаКиевТолькоПросмотр' },
  // KievFormVisible: { label: 'ФормаКиевВидимость', name: 'ФормаКиевВидимость' },
  // QuartalVisible: { label: 'сзКварталВидимость', name: 'сзКварталВидимость' },
  // CoordinationVisible: { label: 'СогласованиеВидимость', name: 'СогласованиеВидимость' },
};

const tables = {
  data: {
    name: 'ТчПаспорта',
    label: 'Документи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      Document: { label: 'Паспорт', name: 'Документ' },
      // Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
  programs: {
    name: 'Программы',
    label: 'Програми / Підпрограми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KTKVK: { label: 'КТКВК', name: 'КТКВК' },
      program: { label: 'Програми/підпрограми', name: 'Программа' },
      KPKVK: { label: 'КПКВК', name: 'КПКВК' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'indicatorPassportPrinting',
  backendName: 'ПечатьПаспорта',
  frontend: 'dp/indicatorPassportPrinting',
  label: 'Друк',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
