import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/limitSPR';
import {
  StyledLabel,
  TwoColumnsGridContainer,
  SevenColumnsGridContainer,
  ContainerDiv,
  StyledPane,
  ResultSegment, FiveColumnsGridContainer, CommandPanelColor,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

import YearField from '../../field/yearInput';

import DateRangeField from '../../../components/fields/DateRangeField';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import meta from '../../../constants/meta';

const YearInput = YearField;

const ReportTypes = {
  plan: { label: 'Розпис', name: 1 },
  update: { label: 'Уточнення', name: 2 },
  all: { label: 'Розпис+уточнення', name: 3 },
};

const FondTypes = {
  zf: { label: 'Загальний фонд', name: 1 },
  sf: { label: 'Спеціальний фонд', name: 2 },
  all: { label: 'Всі фонди', name: 3 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'LimitSPR';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const Bookmarks = (

    <StyledPane>
      <DCControls.Filter />
    </StyledPane>

  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <TwoColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
      </TwoColumnsGridContainer>
      <SevenColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('planType', false)}
            options={Object.values(enums.PlaningKind)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.reportType.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('reportType', false)}
            options={Object.values(ReportTypes)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.forAllFonds.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('forAllFonds', false)}
            options={Object.values(FondTypes)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'forAllFonds'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.chiefFin.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('chiefFin', new Map())}
            modelType="cat"
            modelName="officials"
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'chiefFin'], v));
            }}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.yearSpend.label}</StyledLabel>
          <YearInput
            value={headerForm.get('yearSpend', 0)}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'yearSpend'], v));
            }}
            disableCalculator
          />
        </div>
        <div>
          <StyledLabel>{md.columns.progectVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('progectVariant', new Map())}
            modelType="cat"
            modelName="variantBudgetDraft"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'progectVariant'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.in1000.label}
            value={headerForm.get('in1000', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
          <Fields.CheckboxField
            label={md.columns.oldForm.label}
            value={headerForm.get('oldForm', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'oldForm'], v))}
          />
        </div>
      </SevenColumnsGridContainer>
      <FiveColumnsGridContainer>
        <div>
          <Fields.CheckboxField
            label={md.columns.noGRK.label}
            value={headerForm.get('noGRK', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'noGRK'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.noKFK.label}
            value={headerForm.get('noKFK', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'noKFK'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.sighnKFK.label}
            value={headerForm.get('sighnKFK', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'sighnKFK'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.byElementKFK.label}
            value={headerForm.get('byElementKFK', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'byElementKFK'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.dataPlan.label}
            value={headerForm.get('dataPlan', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'dataPlan'], v))}
          />
        </div>
      </FiveColumnsGridContainer>

      {Bookmarks}

    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>
      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
