/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  fields, activeCol, highlights, onToggleHighlight, year, totals,
}) {
  const yearDef = year || new Date().getFullYear();
  const year_1 = `${yearDef - 1}p.`;
  const year_2 = `${yearDef - 2}p.`;
  const year1 = `${yearDef + 1}p.`;
  const year2 = `${yearDef + 2}p.`;

  return (
    <TableHeaderRow>
      <Col>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'customer'}
              highlighted={highlights.includes('customer')}
              onToggleHighlight={() => onToggleHighlight('customer')}
            >
              Замовник
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'building_period'}
              highlighted={highlights.includes('building_period')}
              onToggleHighlight={() => onToggleHighlight('building_period')}
            >
              {fields.building_period.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_2'}
              highlighted={highlights.includes('amount_sf_2')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_2')}
              title={totals.amount_sf_2.toLocaleString('uk', moneysStingOptions)}
            >
              {year_2}
              {' '}
              (звіт)
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'readiness_level_1'}
              highlighted={highlights.includes('readiness_level_1')}
              onToggleHighlight={() => onToggleHighlight('readiness_level_1')}
            >
              Рівень буд. гот.
              {' '}
              {year_2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_1'}
              highlighted={highlights.includes('amount_sf_1')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_1')}
              title={totals.amount_sf_1.toLocaleString('uk', moneysStingOptions)}
            >
              {year_1}
              {' '}
              (план)
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'readiness_level_2'}
              highlighted={highlights.includes('readiness_level_2')}
              onToggleHighlight={() => onToggleHighlight('readiness_level_2')}
            >
              Рівень буд. гот.
              {' '}
              {year_2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf0'}
              highlighted={highlights.includes('amount_sf0')}
              onToggleHighlight={() => onToggleHighlight('amount_sf0')}
              title={totals.amount_sf0.toLocaleString('uk', moneysStingOptions)}
            >
              {yearDef}
              {' '}
              (проект)
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'readiness_level_3'}
              highlighted={highlights.includes('readiness_level_3')}
              onToggleHighlight={() => onToggleHighlight('readiness_level_3')}
            >
              Рівень буд. гот.
              {' '}
              {yearDef}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf1'}
              highlighted={highlights.includes('amount_sf1')}
              onToggleHighlight={() => onToggleHighlight('amount_sf1')}
              title={totals.amount_sf1.toLocaleString('uk', moneysStingOptions)}
            >
              {year1}
              {' '}
              (прогноз)
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'readiness_level_4'}
              highlighted={highlights.includes('readiness_level_4')}
              onToggleHighlight={() => onToggleHighlight('readiness_level_4')}
            >
              Рівень буд. гот.
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf2'}
              highlighted={highlights.includes('amount_sf2')}
              onToggleHighlight={() => onToggleHighlight('amount_sf2')}
              title={totals.amount_sf2.toLocaleString('uk', moneysStingOptions)}
            >
              {year2}
              {' '}
              (прогноз)
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'readiness_level_5'}
              highlighted={highlights.includes('readiness_level_5')}
              onToggleHighlight={() => onToggleHighlight('readiness_level_5')}
            >
              Рівень буд. гот.
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_sf_2: PropTypes.number,
    amount_sf_1: PropTypes.number,
    amount_sf0: PropTypes.number,
    amount_sf1: PropTypes.number,
    amount_sf2: PropTypes.number,
  }).isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
