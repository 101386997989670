import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styled from 'styled-components';
import { List, Map } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearFiles, removeFile } from '../../actions/notifier';
import { Title } from '../../components/Form/styledForm';
import { CloseIcon } from '../../assets/icons';
import File from './file';

const ContainerButton = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    margin-bottom: 10px;
`;

const FilesContainer = Styled.div`
  z-index: 1056;
  position: fixed;
  bottom: 0;
  right: 0;
  border: 1px solid #DDE2E9;
  border-radius: 4px;
  padding: 10px 13px;
  background: #e9f0f8ff;
  box-shadow: 0px 1px 30px -4px rgb(55,55,55);
  margin: 3px;
`;

const HeaderContainer = Styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &>:first-child{
    flex: 1 1 auto;
  }
`;

class Notifier extends PureComponent {
  static propTypes = {
    files: PropTypes.instanceOf(List).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const linkRefs = props.files.reduce((R, f, i) => [...R, state.linkRefs[i] || createRef()], []);
    return {
      modalOpened: state.modalOpened && !!props.files.size,
      linkRefs,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      linkRefs: [],
    };
  }

  getFilesWord = (n) => {
    switch (n) {
      case 1:
        return 'файл';
      case 2:
        return 'файла';
      case 3:
        return 'файла';
      case 4:
        return 'файла';
      default:
        return 'файлів';
    }
  };

  render() {
    const { files, dispatch } = this.props;
    const { modalOpened, linkRefs } = this.state;
    if (!files.size) return null;
    return (
      <FilesContainer>
        <HeaderContainer>
          <CloseIcon
            style={{ alignSelf: 'flex-end', width: '9px' }}
            alt="Очистити"
            onClick={() => dispatch(clearFiles())}
          />
          <Title
            style={{ marginTop: '8px' }}
            onClick={() => this.setState({ modalOpened: true })}
          >
            {`Підготовлено ${files.size} ${this.getFilesWord(files.size)}`}
          </Title>
        </HeaderContainer>
        <ContainerButton>
          <Modal
            show={modalOpened}
            onHide={() => this.setState({ modalOpened: false })}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                Підготовлені для завантаження файли
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {files.map((file, index) => (
                <File
                  key={index}
                  file={file}
                  iRef={linkRefs[index]}
                  onDeleteFile={() => dispatch(removeFile(index))}
                />
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={() => linkRefs.forEach((r) => r.current && r.current.click())}
              >
                <FontAwesomeIcon icon={faFileDownload} className="me-2" />
                Скачати всі
              </Button>

            </Modal.Footer>
          </Modal>

        </ContainerButton>
      </FilesContainer>
    );
  }
}

const mapState = (store) => {
  const notifierNode = store.getIn(['notifier'], new Map());
  return {
    files: notifierNode.get('files', new List()),
  };
};

export default connect(mapState)(Notifier);
