import React, { useCallback, useContext } from 'react';
import LogcaLogin from './auth';
import { WinManagerContext } from '../../../providers';

function LogikaAuth() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const onSuccess = useCallback(
    () => dellComponentFromWindowsManager(),
    [dellComponentFromWindowsManager],
  );
  return (
    <div className="h-100">
      <LogcaLogin onSuccess={onSuccess} />
    </div>
  );
}

export default LogikaAuth;
