import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Орган місцевого самоврядування', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
};

/**
 * @const
 */
const definition = {
  name: 'taxes3PPrint',
  backendName: 'ПечатьНалогиИЛьготыДод3П',
  label: 'Друк',
  frontend: 'dp/taxes3PPrint',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
