const contents = {
  deleteButton: 'Вилучити',
  modalFilesTitle: 'Додані файли',
  modalFilesSubTitle: 'Оберіть файли для завантаження або перетягніть їх до блакитної зони',
  choiseFileButton: 'Оберіть файли',
  modalWarningTitle: 'Увага!',
  modalWarningAlert: 'Відсутні налаштування ftp-серверу. Зверніться до адміністратора.',

};
export default contents;
