import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import md from '../../../../../constants/meta/documents/expensesOnHealth';
import { referencePropType } from '../../../../newEditor/propTypes';
import { EditorControls } from '../../../../../components/bootstrap_components/editorControls';
import { FI, DOC, M } from './consts';

function DocItem({ doc, onToggle }) {
  return (
    <Card
      key={doc[DOC].id}
      border={doc[M] ? 'primary' : 'light'}
    >
      <Card.Header className="d-flex">
        <Card.Title className="flex-grow-1">
          <Link to={`/${md.frontend}/${doc[DOC].id}`}>
            {doc[DOC].repr}
          </Link>
        </Card.Title>
        <EditorControls.CheckboxInput
        // label="Завантажувати"
          controlId={`check-for-document-${doc[DOC].id}`}
          onChange={(e, v) => onToggle(e, doc[DOC].id, v)}
          value={doc[M]}
        />
      </Card.Header>
      <Card.Body>
        <Row noGutters>
          <Col>
            <Card.Text className="d-flex_ align-items-end mb-2 text-center text-xl-left">
              <Form.Label className="me-1">Розпорядник:</Form.Label>
              <span className="text-primary font-weight-bold">
                {doc[FI].repr}
              </span>
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

DocItem.propTypes = {
  doc: PropTypes.shape({
    [DOC]: referencePropType,
    [FI]: referencePropType,
    [M]: PropTypes.bool,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default memo(DocItem);
