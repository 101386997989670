import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InfoPaymentFinRequestEditor } from '../../../dataProcessors';

function RequestInfoModal({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const docFinRequest = useMemo(
    () => new Map({
      id: selectedRows.length ? selectedRows[0] : null,
      repr: '-',
    }),
    [selectedRows],
  );
  return (
    <>
      <Dropdown.Item onClick={() => setOpened(true)} disabled={selectedRows.length === 0}>
        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
        Інформація, щодо фінансування заявки
      </Dropdown.Item>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
            Інформація, щодо фінансування заявки
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfoPaymentFinRequestEditor
            printAfterOpen
            docFinRequest={docFinRequest}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

RequestInfoModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default RequestInfoModal;
