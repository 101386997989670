import React, {
  forwardRef, memo, useContext,
} from 'react';
import {
  NavDropdown,
} from 'react-bootstrap';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  NewButton, EditButton, DeleteButton, NewFolderButton, ShowDeletedToggler,
  FiltersButton, CopyButton, RefreshButton,
} from '../../../../components/bootStrap/buttons';
import {
  SearchInput,
  CommandPanel,
} from '../../../../components/bootStrap';
import ListerContext from '../../context';
import CompareComponent from './other_components/compare_modal';
import { childrenPropType } from '../../../../constants/backend/propTypes';

const CatListerCommandPanel = forwardRef(({
  children, prepend, append,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString, localFilter, onResetColumnSizes, filterOpened,
  } = useContext(ListerContext);

  const filterApplied = !!Object.keys(localFilter).filter((k) => localFilter[k].use).length;

  return (
    <CommandPanel
      sticky="top"
      ref={ref}
      leftPart={(
        <>
          {prepend}
          <NewButton
            onClick={actions.onNew}
            disabled={!(permissions.canNew && actions.onNew)}
          />
          {permissions.foldersUsed && (
            <NewFolderButton
              onClick={actions.onNewFolder}
              disabled={!(permissions.canNewFolder && actions.onNewFolder)}
            />
          )}
          <CopyButton
            onClick={actions.onCopy}
            disabled={!(permissions.canCopy && actions.onCopy)}
          />
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={actions.onDelete}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
          <RefreshButton
            onClick={() => actions.onRefresh()}
          />
          <FiltersButton
            onClick={() => actions.onToggleFilterOpened()}
            disabled={!permissions.canFilter}
            animation={filterApplied}
            variant={filterOpened ? 'falcon-warning' : 'falcon-primary'}
          />
          {permissions.canCompare && (
            <CompareComponent />
          )}
          {children}
        </>
      )}
      rightPart={(
        <div className="d-flex flex-nowrap">
          <ShowDeletedToggler
            value={showDeleted}
            disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
            onChange={actions.onToggleShowDeleted}
          />
          <SearchInput
            onChange={actions.onSearch}
            value={searchString}
            disabled={!permissions.canSearch && !searchString}
          />
        </div>
      )}
      ddItems={(
        <>
          <NavDropdown.Item onClick={() => {
            onResetColumnSizes();
            actions.onRefresh();
          }}
          >
            <FontAwesomeIcon icon={faTableColumns} className="me-2" />
            Скинути налаштування ширини колонок
          </NavDropdown.Item>
          {append}
        </>
      )}
    />
  );
});

CatListerCommandPanel.propTypes = {
  children: childrenPropType,
  prepend: childrenPropType,
  append: childrenPropType,
};

CatListerCommandPanel.defaultProps = {
  children: null,
  prepend: null,
  append: null,
};

export default memo(CatListerCommandPanel);
