const frontendURL = '/cat/csuPartners/';
const backendURL = '/api/references/refdisposerscontragent/';
const name = 'Постачальники розпорядників';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
