import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, headerProps,
  } = useEditor({
    backendURL: meta.doc.incomeproject.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const projectVariantProps = editorHooks.useItemInputProps(
    'project_variant',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const numberProps = editorHooks.useStringInputProps(
    'number',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const yearProps = editorHooks.useNumberInputProps(
    'year',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const for3YearsProps = editorHooks.useCheckboxInputProps(
    'for_3_years',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const inThousands = editorHooks.useCheckboxInputProps(
    'in_thousands',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const noteProps = editorHooks.useTextInputProps(
    'note',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.doc.incomeproject.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...projectVariantProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...for3YearsProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...inThousands} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        {fields && (

          <LabelTab
            defaultActiveKey="incomeproject"
            id="incomeproject"
          >
            <Tab
              eventKey="incomeproject"
              title={fields.incomeprojectrow_set.label}
            >
              <TabContainer>
                <PlanningRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>

            <Tab eventKey="note" title="Примітка">
              <TabContainer>
                <EditorControls.TextInput {...noteProps} />
              </TabContainer>
            </Tab>
          </LabelTab>
        )}
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
