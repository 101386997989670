import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  sort: { label: 'Сортування', name: 'Сортировка', visible: true },
};

export const typeTaxSFForRaitingZF = {
  label: 'Види доходів СФ для оцінки эфективності',
  name: 'typeTaxSFForRaitingZF',
  backendName: 'ВидыДоходовСФДляОценкиЭф',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/typeTaxSFForRaitingZF',
  listColumns: [...(new Set([...reqListerColumns, 'sort']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default typeTaxSFForRaitingZF;
