import React, {
  forwardRef, useContext, useMemo, useState,
} from 'react';
import {
  Accordion, Button,
} from 'react-bootstrap';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import md from '../../../../constants/meta/catalogs/bankAccounts';
import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';
import ListerContext from '../../../newLister/context';
import { comparisonTypes } from '../../../../constants/meta/common';

const BankAccountsLister = ListerFactory({
  modelType: 'cat',
  modelName: 'bankAccounts',
  viewType: 'lister',
})(CatTableListerFactory({}));

const ElementCommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);
  const [isOpen, setIsOpen] = useState(false);
  const flt = useMemo(
    () => ([
      {
        fieldName: md.columns.owner.name,
        comparisonType: comparisonTypes.inList,
        value: isOpen ? selectedRows : [],
      },
    ]),
    [selectedRows, isOpen],
  );
  const accValue = isOpen ? '1' : '0';
  return (
    <>
      <CatListerCommandPanel
        ref={ref}
      >
        <Button variant="link" onClick={() => setIsOpen(!isOpen)}>
          { isOpen ? 'Сховати рахунки' : 'Показати рахунки'}
        </Button>
      </CatListerCommandPanel>
      <Accordion activeKey={accValue}>
        <Accordion.Collapse eventKey="1">
          <BankAccountsLister filter={flt} />
        </Accordion.Collapse>
      </Accordion>
    </>

  );
});

const CSULister = ListerFactory({
  modelType: 'cat',
  modelName: 'csu',
  viewType: 'lister',
})(CatTableListerFactory({
  CommandPanel: ElementCommandPanel,
}));

export default CSULister;
