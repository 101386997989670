import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualSpend';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  CSUinH,
  total,
  genTotal,
  specTotal,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>
          {!CSUinH
          && (
            <Col
              xl={3}
              sm={12}
              className="border-right"
            >
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'CSU'}
                highlighted={highlights.includes('CSU')}
                onToggleHighlght={() => onToggleHighlght('CSU')}
              >
                {meta.columns.CSU.label}
              </TableHeaderCell>
            </Col>
          )}
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={CSUinH ? 2 : 1}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKVCode'}
              highlighted={highlights.includes('KPKVCode')}
              onToggleHighlght={() => onToggleHighlght('KPKVCode')}
            >
              {meta.columns.KPKVCode.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={CSUinH ? 2 : 1}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KFKCode'}
              highlighted={highlights.includes('KFKCode')}
              onToggleHighlght={() => onToggleHighlght('KFKCode')}
            >
              {meta.columns.KFKCode.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'targetedPrograms'}
              highlighted={highlights.includes('targetedPrograms')}
              onToggleHighlght={() => onToggleHighlght('targetedPrograms')}
            >
              {meta.columns.targetedPrograms.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF1000'}
              highlighted={highlights.includes('sumGenF1000')}
              onToggleHighlght={() => onToggleHighlght('sumGenF1000')}
            >
              {meta.columns.sumGenF1000.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF1110'}
              highlighted={highlights.includes('sumGenF1110')}
              onToggleHighlght={() => onToggleHighlght('sumGenF1110')}
            >
              {meta.columns.sumGenF1110.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF1160'}
              highlighted={highlights.includes('sumGenF1160')}
              onToggleHighlght={() => onToggleHighlght('sumGenF1160')}
            >
              {meta.columns.sumGenF1160.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF2000'}
              highlighted={highlights.includes('sumGenF2000')}
              onToggleHighlght={() => onToggleHighlght('sumGenF2000')}
            >
              {meta.columns.sumGenF2000.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF'}
              title={genTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumGenF')}
              onToggleHighlght={() => onToggleHighlght('sumGenF')}
            >
              {meta.columns.sumGenF.label}
            </TableHeaderCell>
          </Col>
        </Row>

        <Row noGutters>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF1000'}
              highlighted={highlights.includes('sumSpecF1000')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF1000')}
            >
              {meta.columns.sumSpecF1000.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF1110'}
              highlighted={highlights.includes('sumSpecF1110')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF1110')}
            >
              {meta.columns.sumSpecF1110.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF1160'}
              highlighted={highlights.includes('sumSpecF1160')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF1160')}
            >
              {meta.columns.sumSpecF1160.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF2000'}
              highlighted={highlights.includes('sumSpecF2000')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF2000')}
            >
              {meta.columns.sumSpecF2000.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={1}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumBR'}
              highlighted={highlights.includes('sumBR')}
              onToggleHighlght={() => onToggleHighlght('sumBR')}
            >
              {meta.columns.sumBR.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={1}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumBRKR'}
              highlighted={highlights.includes('sumBRKR')}
              onToggleHighlght={() => onToggleHighlght('sumBRKR')}
            >
              {meta.columns.sumBRKR.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF'}
              title={specTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF')}
            >
              {meta.columns.sumSpecF.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col
            xl={8}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              title={total.toLocaleString('uk', moneysStingOptions)}
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  CSUinH: PropTypes.bool,
  total: PropTypes.number.isRequired,
  specTotal: PropTypes.number.isRequired,
  genTotal: PropTypes.number.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  CSUinH: false,
};

export default memo(TPHeader);
