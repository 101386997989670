import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/draftAnnualRev';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'general';

const tableMD = md.tables[tablename];

const ThreeYears = [...Array(3)].map((_, i) => i);

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
  for3Years, FI, inThousands,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, year, col, value) => {
      const colName = tableMD.columns[`${col}${year}`].name;

      onRowChange(e, {
        [colName]: value,
      });
    },
    [onRowChange],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.kdbClassifier.name]: v });
      await onSR('CHANGE_KDB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={6}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col style={{ width: '300px' }}>
            <TableRowCell column="kdbClassifier" highlighted={highlights.includes('kdbClassifier')}>
              <ItemPicker
                value={row[tableMD.columns.kdbClassifier.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={onKDBChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="fullName" highlighted={highlights.includes('fullName')}>
              <StringInput
                value={row[tableMD.columns.fullName.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.fullName.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col xl={6} sm={12}>
        {for3Years ? ThreeYears.map((i) => (
          <Row key={i} className="w-100" noGutters>
            <Col>
              <TableRowCell sm={3} column={`SumGenF${i}`} highlighted={highlights.includes(`SumGenF${i}`)}>
                <NumberInput
                  value={row[tableMD.columns[`SumGenF${i}`].name]}
                  precision={inThousands ? 3 : 2}
                  onChange={(e, v) => onSumChange(e, i, 'SumGenF', v)}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell sm={3} column={`SumSpecF${i}`} highlighted={highlights.includes(`SumSpecF${i}`)}>
                <NumberInput
                  value={row[tableMD.columns[`SumSpecF${i}`].name]}
                  precision={inThousands ? 3 : 2}
                  onChange={(e, v) => onSumChange(e, i, 'SumSpecF', v)}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell sm={3} column={`SumBR${i}`} highlighted={highlights.includes(`SumBR${i}`)}>
                <NumberInput
                  value={row[tableMD.columns[`SumBR${i}`].name]}
                  precision={inThousands ? 3 : 2}
                  inputClassName="font-italic"
                  onChange={(e, v) => onSumChange(e, i, 'SumBR', v)}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell sm={3} column={`Sum${i}`} highlighted={highlights.includes(`Sum${i}`)}>
                <NumberInput
                  value={(row[tableMD.columns[`SumSpecF${i}`].name] || 0) + (row[tableMD.columns[`SumGenF${i}`].name] || 0)}
                  precision={inThousands ? 3 : 2}
                  onChange={() => {}}
                  readOnly
                />
              </TableRowCell>
            </Col>
          </Row>
        ))
          : (
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="SumGenF0" highlighted={highlights.includes('SumGenF0')}>
                  <NumberInput
                    value={row[tableMD.columns.SumGenF0.name]}
                    precision={inThousands ? 3 : 2}
                    onChange={(e, v) => onSumChange(e, 0, 'SumGenF', v)}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="SumSpecF0" highlighted={highlights.includes('SumSpecF0')}>
                  <NumberInput
                    value={row[tableMD.columns.SumSpecF0.name]}
                    precision={inThousands ? 3 : 2}
                    onChange={(e, v) => onSumChange(e, 0, 'SumSpecF', v)}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="SumBR0" highlighted={highlights.includes('SumBR0')}>
                  <NumberInput
                    value={row[tableMD.columns.SumBR0.name]}
                    precision={inThousands ? 3 : 2}
                    inputClassName="font-italic"
                    onChange={(e, v) => onSumChange(e, 0, 'SumBR', v)}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="Sum0" highlighted={highlights.includes('Sum0')}>
                  <NumberInput
                    value={(row[tableMD.columns.SumSpecF0.name] || 0)
                    + (row[tableMD.columns.SumGenF0.name] || 0)}
                    precision={inThousands ? 3 : 2}
                    onChange={() => {}}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            </Row>
          )}

      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType.isRequired,
  for3Years: PropTypes.bool,
  inThousands: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  for3Years: false,
  inThousands: false,
};

export default memo(TPRow);
