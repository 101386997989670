import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  IDMinFin: {
    label: 'ПК завдання (для обміну з Мінфіном)',
    name: 'ИдМинФин',
    visible: false,
  },
  NotActual: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: true,
  },
  Tasks: {
    label: 'Завдання',
    name: 'Задание',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  DateKl: {
    label: 'Дата',
    name: 'ДатаКл',
    visible: false,
  },
  owner: { label: 'Бюджет', name: 'Владелец', visible: false },
  flKFKLimited: { label: 'Дозволити використовувати для фіксованого переліку КБП', name: 'флОграничитьПоКФК', visible: false },
};

export const tasks2019 = {
  label: 'Завдання бюджетних програм',
  name: 'Tasks2019',
  backendName: 'Задания2019',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/tasks2019',
  listColumns: [...(new Set([...reqListerColumns,
    'NotActual',
  ]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tasks2019;
