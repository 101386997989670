import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Inactive: { label: 'Не діючий', name: 'НеДействующий', visible: false },
  typeValue: { label: 'Тип значення', name: 'ТипЗначения', visible: false },
  fo: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
};

// Булево, Строка, Дата, Число,
// СправочникСсылка.ДопХЭлементыКФК,
// СправочникСсылка.ЭлементыКФК,
// СправочникСсылка.ОбъектыСтроительстваИсточникиФинансирования

export const budgetElChTP = {
  label: 'Додатковий розріз аналітики (Деталізація ел.бюджету. ПВХ)',
  name: 'budgetElChTP',
  columns,
  backendName: 'ПВХЭлементыКФК',
  defaultOrder,
  frontend: 'ChTP/budgetElChTP',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'Inactive',
      'fo',
    ]),
  ],
  hierarchy: false,
};

export default budgetElChTP;
