import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  typeValue: { label: 'Тип значення', name: 'ТипЗначения', visible: false },
};

const typeValue = [
  { type: 'simple', value: 'bool' },
  { type: 'simple', value: 'numeric' },
  { type: 'simple', value: 'string' },
  { type: 'simple', value: 'date' },

  { type: 'cat', value: 'additionalPropertyKFK' },
];

// Булево, Строка, Дата, Число,
// СправочникСсылка.ДопХВидовРемонта,

export const typesRepairElChTP = {
  label: 'Додатковий розріз аналітики.Об\'єкти будівництва/ремонту',
  name: 'typesRepairElChTP',
  typeValue,
  columns,
  backendName: 'ПВХВидыРемонта',
  defaultOrder,
  frontend: 'ChTP/typesRepairElChTP',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default typesRepairElChTP;
