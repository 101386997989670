import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  activeCol, highlights, onToggleHighlight, year,
}) {
  const yearDef = year || new Date().getFullYear();
  const year1 = `${yearDef + 1}p.`;
  const year2 = `${yearDef + 2}p.`;

  return (
    <TableHeaderRow>
      <Col>
        <Row noGutters>
          <Col lg={8}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicator'}
              highlighted={highlights.includes('indicator')}
              onToggleHighlight={() => onToggleHighlight('indicator')}
            >
              Показник
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicator_type'}
              highlighted={highlights.includes('indicator_type')}
              onToggleHighlight={() => onToggleHighlight('indicator_type')}
            >
              Тип показника
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'activity'}
              highlighted={highlights.includes('activity')}
              onToggleHighlight={() => onToggleHighlight('activity')}
            >
              Завдання
            </TableHeaderCell>
          </Col>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total0'}
              highlighted={highlights.includes('amount_total0')}
              onToggleHighlight={() => onToggleHighlight('amount_total0')}
            >
              {yearDef}
              {' '}
              рік (прогноз), зміни у разі виділення додаткових коштів
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total1'}
              highlighted={highlights.includes('amount_total1')}
              onToggleHighlight={() => onToggleHighlight('amount_total1')}
            >
              {year1}
              {' '}
              рік (прогноз), зміни у разі виділення додаткових коштів
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total2'}
              highlighted={highlights.includes('amount_total2')}
              onToggleHighlight={() => onToggleHighlight('amount_total2')}
            >
              {year2}
              {' '}
              рік (прогноз), зміни у разі виділення додаткових коштів
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
