/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
// eslint-disable-next-line import/no-cycle
import PrintModal from './print_submenu';

function APMenu({ id, data, ...rest }) {
  const selectedRows = useMemo(() => [id], [id]);

  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <PrintModal selectedRows={selectedRows} />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default APMenu;
