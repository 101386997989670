import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { widgetModes } from '../authentication';
import OldEditor from './old';
import NewEditor from './new';

function SignData({
  data, onAddSign, onClose, index, multiple,
}) {
  const [widgetMode, setWidgetMode] = useState(null);
  const widgetModeRef = useRef();
  widgetModeRef.current = widgetMode;

  useEffect(
    () => {
      const savedValue = localStorage.getItem('logica_widget_type');
      if (!(savedValue in widgetModes)) {
        // default value
        setWidgetMode(widgetModes.iit);
      } else {
        setWidgetMode(savedValue);
      }
      return () => localStorage.setItem('logica_widget_type', widgetModeRef.current);
    },
    [],
  );

  const widgetUri = useMemo(
    () => {
      if (widgetMode === widgetModes.iit) return 'https://certs.ciat.net.ua/';
      if (widgetMode === widgetModes.diya) return 'https://certs2.ciat.net.ua/';
      return '';
    },
    [widgetMode],
  );
  if (widgetMode === null) return null;
  if (widgetMode !== widgetModes.ciat) {
    return (
      <div className="d-flex flex-row-reverse align-items-start">
        <Alert variant="info" className="flex-fill mt-1 mx-2">
          <Alert.Heading>До відома користувачів</Alert.Heading>
          <p>З&apos;явилася нова версія механізму авторизації на сервері &ldquo;Логіка&rdquo; </p>
          <p>
            Наразі підтримуються тількі файлові ключі, але незабаром
            буде додано підтримку апаратних ключів
          </p>
          <hr />
          <div className="d-flex gap-4 align-items-baseline">
            <Button variant="outline-info" onClick={() => setWidgetMode(widgetModes.ciat)}>
              Спробувати нову версію
            </Button>
            <div>
              <p className="text-muted small">
                P.S. Повернутися на стару версію можна в будь-який
                час
              </p>
            </div>
          </div>
        </Alert>
        <div>
          <OldEditor
            onAddSign={onAddSign}
            onClose={onClose}
            data={data}
            index={index}
            widgetUri={widgetUri}
            multiple={multiple}
          />
          {/* <EditorControls.CheckboxInput */}
          {/*  controlId="Dija" */}
          {/*  label="Використовувати віджет Дії" */}
          {/*  value={widgetMode === widgetModes.diya} */}
          {/*  onChange={ */}
          {/*    () => setWidgetMode( */}
          {/*      widgetMode === widgetModes.iit ? widgetModes.diya : widgetModes.iit, */}
          {/*    ) */}
          {/*  } */}
          {/* /> */}
        </div>
      </div>
    );
  }
  return (
    <>
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => setWidgetMode(widgetModes.iit)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
        До старої версії
      </Button>
      <NewEditor
        onAddSign={onAddSign}
        onClose={onClose}
        data={data}
        index={index}
        multiple={multiple}
      />
    </>
  );
}

SignData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onAddSign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number,
  multiple: PropTypes.bool,
};

SignData.defaultProps = {
  index: null,
  data: '',
  multiple: false,
};

export default SignData;
