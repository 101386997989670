/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import {
  Badge, Tab, Col, NavDropdown, Row, Nav,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import forms from './forms';

function Tables({
  data, displayList, onClearFilter, setSelectedBs,
  onSetFilter, onSetOrder, selectedIds, setSelectedIds,
}) {
  const [activeTitle, setActiveTitle] = useState(null);
  const displayTabs = useMemo(
    () => {
      const codes = new Set(data.map((frm) => frm.code).filter((code) => code in forms));

      return [...codes].map((formcode) => ({
        code: formcode,
        title: forms[formcode].title,
        Component: forms[formcode].component,
        data: data.filter((r) => r.code === formcode),
        defaultProps: forms[formcode].defaultProps,
      }));
    },
    [data],
  );

  return (
    data.length === 0
      ? <h2 style={{ color: 'red' }}>Нажаль, на вказаний період, документів немає</h2>
      : (
        <Tab.Container id="tabs-with-dropdown">
          <Row className="clearfix">
            <Col sm={12}>
              <Nav variant="tabs" activeKey="1">
                <NavDropdown title={activeTitle || 'Оберіть таблицю'} style={{ fontWeight: 'bold', fontSize: '16px', color: 'blue' }}>
                  {displayTabs.map(({
                    // eslint-disable-next-line no-shadow
                    title, data,
                  }) => (
                    <NavDropdown.Item
                      key={title}
                      eventKey={title}
                      onClick={() => setActiveTitle(title)}
                    >
                      {title}
                      <Badge variant="primary" className="ms-2">{data.length}</Badge>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content animation="true">
                {displayTabs.map(({
                  // eslint-disable-next-line no-shadow
                  code, title, Component, data, defaultProps,
                }) => (
                  <Tab.Pane key={title} eventKey={title}>
                    <Component
                      data={data}
                      displayList={displayList[code].display_list}
                      code={code}
                      onClearFilter={onClearFilter}
                      onSetFilter={onSetFilter}
                      onSetOrder={onSetOrder}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      setSelectedBs={setSelectedBs}
                      {...defaultProps}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>

        </Tab.Container>
      )
  );
}

Tables.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.oneOf(Object.keys(forms)).isRequired,
  })).isRequired,
  displayList: PropTypes.PropTypes.shape({
    code: PropTypes.oneOf(Object.keys(forms)),
  }),
  onSetFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setSelectedBs: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tables.defaultProps = {
  displayList: null,
};

export default Tables;
