import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  progVariant: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  startDate: { label: 'Початок періоду', name: 'НачалоПериода' },
  endDate: { label: 'Кінець періоду', name: 'ДатаОтчета' },
  yearPlan: { label: 'Рік планування', name: 'ГодЗатрат' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  reportsKind: { label: 'Вид плану', name: 'ПланСУточнением' },
  sessiondesigion: { label: 'Рішення сесії для відправки в  ЛОГІКА', name: 'РешениеСессииЛОГИКА' },
  kopecks: { label: 'показувати копійки', name: 'Копейки' },
  flName: { label: 'Ім\'я та ПРІЗВИЩЕ', name: 'флИмяФамилия' },
  flKDB: { label: 'За елементами КДБ', name: 'флПоЭлементамКДБ' },
  flNull: { label: 'Не показувати нульові рядки', name: 'флНеПоказыватьНулевыеСтроки' },
  flNameKBP: { label: 'Використовувати назву КБП як назву трансферту', name: 'флБратьНазваниеТрансфертаСКБП' },
  flTrans: { label: 'за даними документу "Розпис міжбюджетних трасфертів"', name: 'заТрансфертами' },
};

const tables = {
  tpJSON: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      Col1: { label: 'Кол1', name: 'Кол1' },
      Col2: { label: 'Кол2', name: 'Кол2' },
      Col3: { label: 'Кол3', name: 'Кол3' },
      Col4: { label: 'Кол4', name: 'Кол4' },
      Col5: { label: 'Кол5', name: 'Кол5' },
      Col6: { label: 'Кол6', name: 'Кол6' },
      Col7: { label: 'Кол7', name: 'Кол7' },
      Col8: { label: 'Кол8', name: 'Кол8' },
      Col9: { label: 'Кол9', name: 'Кол9' },
      Col10: { label: 'Кол10', name: 'Кол10' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 5 (Трансферти)',
  name: 'SessionAdd5Transfert',
  columns,
  tables,
  frontend: 'rep/sessionAdd5Transfert',
  backendName: 'РешениеСессии05Трансферты',
  resultColumns: baseResultColumns,
};

export default definition;
