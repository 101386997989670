import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fillTable } from '../../../../actions/dpEditor';
import CommandPanelButton from '../../../../components/button/commandPanelButton';
import { CheckAllIcon, UnChekAllIcon } from '../../../../assets/icons';

function CPUsers({ tableName, disabled, dispatch }) {
  return (
    <>
      <CommandPanelButton
        text="Відмітити всі"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['send'], true))}
      >
        <CheckAllIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Зняти відмітки"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['send'], false))}
      >
        <UnChekAllIcon />
      </CommandPanelButton>
    </>
  );
}

CPUsers.propTypes = {
  tableName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

CPUsers.defaultProps = {
  disabled: false,
};

export default connect()(CPUsers);
