import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { CloseIcon } from '../../../../icons';

const PotralDiv = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,.7);
  overflow: hidden;
  
  @media (max-width: 900px) {
  position: fixed;
  }
`;

const IconDiv = styled.div`
  display: flex;
`;

const IconImg = styled.img`
  margin: 0 0 0 auto;
  width: 20px;
  cursor: pointer;
    :hover{
      filter: opacity(0.6);
    };
`;

const getSize = (size) => {
  switch (size) {
    case 'mini':
      return `
        margin:40vh auto;
        height: 20vh;
      `;
    case 'tiny':
      return `
        margin:30vh auto;
        width: 40vw;
        height: 40vh;
      `;
    case 'small':
      return `
        margin:20vh auto;
        width: 60vw;
        height: 60vh;
      `;
    case 'large':
      return `
        margin:10vh auto;
        width: 80vw;
        height: 80vh;
      `;
    case 'fullscreen':
      return `
        margin:0 auto;
        width: 100vw;
        height: 100vh;
      `;
    default:
      return `
        margin:10vh auto;
        width: 80vw;
        height: 80vh;
      `;
  }
};

const PortalContainer = styled.div`
  width: fit-content;
  padding: 1em 1.5em;
  border-radius: 0.2em;
  opacity: 1;
  background: white;
  ${({ size }) => getSize(size)}
  overflow: auto;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 900px) {
  overflow: auto;
  margin: 5vh auto;
  }
`;

export function Modal({
  open, children, node, size, closable, onClose,
}) {
  if (!open) { return null; }
  return createPortal(
    <PotralDiv>
      <PortalContainer size={size}>
        {closable && (
          <IconDiv>
            <IconImg src={CloseIcon} alt="close" onClick={() => onClose()} />
          </IconDiv>
        )}
        {React.Children.only(children)}
      </PortalContainer>
    </PotralDiv>,
    node,
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element.isRequired,
  node: PropTypes.shape({}),
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen']),
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  open: false,
  node: document.body,
  size: 'large',
  closable: false,
  onClose: null,
};

export default Modal;
