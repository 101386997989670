import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Overlay } from 'react-bootstrap';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DimableContainer = styled.div`
  ${(props) => (props.isLoading ? 'filter: blur(4px);' : '')}
`;

function DimableLoader({ loading, text, children }) {
  return (
    <>
      <Overlay show={loading}>
        <SpinnerContainer>
          <Spinner animation="border" variant="primary">
            <span className="sr-only">{text}</span>
          </Spinner>
        </SpinnerContainer>
      </Overlay>
      <DimableContainer isLoading={loading}>
        {children}
      </DimableContainer>
    </>
  );
}

DimableLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

DimableLoader.defaultProps = {
  text: 'Завантаження',
};

export default DimableLoader;
