import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function EnergyMenuItem() {
  return (
    <UseMenuHeader label="Енергоносії">
      <Boxes items={NavPanel.energy.boxes} />
    </UseMenuHeader>
  );
}

export default EnergyMenuItem;
