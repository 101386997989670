import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_61: {
    name: 'Табл61',
    label: 'Табл 61',
    columns: {
      number: { label: 'Номер з/п 1.n', name: 'Номер' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      category: { label: 'Категорія ставок 1.n', name: 'Категория' },
      amountInternal: { label: 'Розмір ставок (внутрішній туризм) 1.n', name: 'РазмерВн' },
      amount: { label: 'Розмір ставок (в\'їздний туризм) 1.n', name: 'Размер' },
    },
  },
  TABL_65: {
    name: 'Табл65',
    label: 'Табл 65',
    columns: {
      number: { label: 'Номер з/п 1.n(об\'єкти для тимч.проживання)', name: 'Номер' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      category: { label: 'Категорія ставок 1.n(об\'єкти для тимч.проживання)', name: 'Категория' },
      amountInternal: { label: 'Розмір ставок (внутрішній туризм) 1.n(об\'єкти для тимч.проживання)', name: 'РазмерВн' },
      amount: { label: 'Розмір ставок (в\'їздний туризм) 1.n(об\'єкти для тимч.проживання)', name: 'Размер' },
    },
  },
  TABL_62: {
    name: 'Табл62',
    label: 'Табл 62',
    columns: {
      number: { label: 'Номер з/п 2.1.n', name: 'Номер' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      category: { label: 'Категорія ставок 2.1.n', name: 'Категория' },
      amountInternal: { label: 'Розмір ставок (внутрішній туризм) 2.1.n', name: 'РазмерВн' },
      amount: { label: 'Розмір ставок (в\'їздний туризм) 2.1.n', name: 'Размер' },
    },
  },
  TABL_63: {
    name: 'Табл63',
    label: 'Табл 63',
    columns: {
      number: { label: 'Номер з/п 2.2.n', name: 'Номер' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      category: { label: 'Категорія ставок 2.2.n', name: 'Категория' },
      amountInternal: { label: 'Розмір ставок (внутрішній туризм) 2.2.n', name: 'РазмерВн' },
      amount: { label: 'Розмір ставок (в\'їздний туризм) 2.2.n', name: 'Размер' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 6 (стар. 4)',
  name: 'taxes4',
  backendName: 'НалогиИЛьготыДод4',
  columns,
  frontend: 'doc/taxes4',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
