import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { PrependFond } from '../../styles/klassifiers';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.finsourceplan.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.finsourceplan.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} required />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                      )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...onlyYearProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...vt4brProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="finsourceplan" id="uncontrolled-tab-example">
          {fields && fields.finsourceplanrow_set && (
          <Tab eventKey="finsourceplan" title={fields.finsourceplanrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
