import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  IndicatorType: {
    label: 'Тип показника',
    name: 'ТипПоказателя',
    visible: true,
  },
  parent: { label: 'Папка', name: '_Родитель', visible: true },
  Units: { label: 'Одиниця вимiру', name: 'Ед', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  IdMinFin: { label: 'ИдМинФин', name: 'ИдМинФин', visible: true },
  Attribute: { label: 'Ознака', name: 'Признак', visible: false },
  Inactive: {
    label: 'Не дiючий',
    name: 'НеДействующий',
    visible: false,
  },
  TaskClassifier: {
    label: 'Типове завдання програми',
    name: 'КлассификаторЗадания',
    visible: true,
  },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  DateCl: { label: 'Дата', name: 'ДатаКл', visible: false },
  ClassifierKBP: {
    label: 'Класифiкатор бюджетних програм МБ(КБП)',
    name: 'КлассификаторКБП',
    visible: true,
  },
};

export const indicatorsClassifier = {
  label: 'Типові показники',
  name: 'indicatorsClassifier',
  backendName: 'КлассификаторПоказатели',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/indicatorsClassifier',
  listColumns: [...new Set([
    ...reqListerColumns,
    'Units',
    'IndicatorType',
    'TaskClassifier',
    'ClassifierKBP',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default indicatorsClassifier;
