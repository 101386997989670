import styled from 'styled-components';
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, CloseIcon, StarIcon } from '../../assets/icons';
import { WinManagerContext } from '../../minfin/providers';

export const ContainerHeader = styled.div`
    margin-bottom: 20px;
`;

export const StyleInnerHeader = styled.span`
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    color: #008F21;
    margin-left: 8px; 
`;
export const ContainerLink = styled.div`
    margin: 10px 0 20px 0;
`;
export const StyledLink = styled(Link)`
    color: #4183c4;
    text-decoration: none;
      :hover {color: #1e70bf};
      :focus {outline:none};
`;
export const LastLink = styled.span`
    color: #808080;
    &:hover{
      color: #808080;
    };
    :focus {outline:none;}
`;

const CloseLink = styled(Link)`
  :hover{
    filter: grayscale(1);
  }
`;

function StyledHeaderDocument({ children, caption, path }) {
  const { dellComponentFromWindowsManager, getNewPath } = useContext(WinManagerContext);
  const prevPath = getNewPath(-1);
  return (
    <>
      <ContainerHeader>
        {caption && (
          <div className="d-flex">
            <StarIcon />
            <StyleInnerHeader>
              {caption}
            </StyleInnerHeader>
            <CloseLink
              className="ms-auto me-1 px-1 py-2"
              to={prevPath}
              onClick={() => {
                dellComponentFromWindowsManager(getNewPath(0));
              }}
            >
              <CloseIcon style={{ width: '14px', height: '14px' }} />
            </CloseLink>
          </div>
        )}
        <ContainerLink>
          {path.map((p, k) => {
            if (k < (path.length - 1)) {
              return (
                <Fragment key={p.label}>
                  <StyledLink to={p.url}>{p.label}</StyledLink>
                  <ArrowRightIcon />
                </Fragment>
              );
            }
            return <LastLink key={p.label}>{p.label}</LastLink>;
          })}
        </ContainerLink>
      </ContainerHeader>
      {children}
    </>
  );
}

StyledHeaderDocument.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, url: PropTypes.string })),
  children: PropTypes.element,
  caption: PropTypes.string,
};

StyledHeaderDocument.defaultProps = {
  children: null,
  caption: null,
  path: [],
};

export default StyledHeaderDocument;
