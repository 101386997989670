import {
  fromJS, List, Map, OrderedMap,
} from 'immutable';
import definition from '../constants/meta';
import reportEditorActions from '../constants/actions/rerportEditor';

import api from '../api/req';
import {
  fromSrvMapper, toSrvMapper, resultMapper,
  SELECTED_KEY, TOGGLED_KEY,
  generateVisibleItems, indexTable, dcSettingsMapper,
} from '../common/editorMappers';
import { newFile } from './notifier';
import storePathParam from '../common/storePathParam';

const getMaxId = (t) => t.reduce((R, r, k) => (Number(k.slice(1)) > R ? Number(k.slice(1)) : R), 0);

/* SERVER REQUEST ACTIONS */
const serverReqDone = (promiseResponse) => async (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const node = store.get(`rep/${storePathParam(store).name}/reportEditor`, new Map());

  const modelName = node.get('modelName', 'Estimate');
  const modelType = node.get('modelType', 'rep');
  const autoExecute = node.get('autoExecute', false);
  const modelDef = definition[modelType][modelName];

  const response = await promiseResponse;
  const DCS = dcSettingsMapper('DataCompositionSettings', response, node.get('dataCompositionSettings'));

  const item = {
    ...fromSrvMapper(modelDef, response),
    ...resultMapper(modelDef, response),
    dataCompositionSettings: DCS,
    model: modelName,
  };

  dispatch({
    type: reportEditorActions.SR_DONE,
    payload: item,
  });

  if (autoExecute) {
    // eslint-disable-next-line
    dispatch(generate());
  }
};

const serverRequestError = (e) => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.SR_ERR,
    payload: {
      errorMsg: e,
      model: storePathParam(getState()).name,
    },
  });
};

let controller;

export const processServerReq = (method, extra = null) => (dispatch, getState) => {
  controller = new AbortController();
  const { signal } = controller;
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = store.get(`rep/${storePathParam(store).name}/reportEditor`);

  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const modelDef = definition[modelType][modelName];

  dispatch({
    type: reportEditorActions.SR_START,
    payload: {
      model: modelName,
    },
  });
  const isDecryption = node.getIn(['decryption', 'opened'], false);

  const DCSNode = isDecryption ? node.getIn(['decryption', 'DCS']) : node.get('dataCompositionSettings');
  const DCS = new Map({
    Group: DCSNode.get('Group'),
    Filter: DCSNode.get('Filter'),
    Order: DCSNode.get('Order'),
    Selection: DCSNode.get('Selection'),
    ConditionalAppearance: DCSNode.get('ConditionalAppearance'),
  });

  const itemData = {
    ...toSrvMapper(modelDef, node),
    dataCompositionSettings: DCS.toJS(),
  };

  api
    .post$(`${modelType}/${modelDef.backendName}/SR`, {
      signal, method, item: itemData, saveSettings: !isDecryption, ...extra,
    })
    .then((d) => {
      if (d.ok) {
        const dJSON = d.json();
        if (!isDecryption) {
          dispatch(serverReqDone(dJSON));
        } else {
          dispatch({
            type: reportEditorActions.CANCEL_REQUEST,
            payload: {
              model: storePathParam(getState()).name,
            },
          });
        }
        if (method === 'reportToFile') {
          dJSON.then((ff) => {
            const f = ff.fileFromReport;
            dispatch(newFile(f.filename, f.content, f.type, f.description));
          });
        }
      } else if (d.status === 422) {
        d.json().then(
          // eslint-disable-next-line no-underscore-dangle
          (data) => dispatch(serverRequestError(data._Error)),
        );
      } else {
        dispatch(serverRequestError(`${d.status} ${d.statusText}`));
      }
    })
    .catch((e) => {
      if (!signal.aborted) {
        dispatch(serverRequestError(e));
      }
    });
};

export const generate = () => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.GENERATE,
    payload: {
      model: storePathParam(getState()).name,
    },
  });
  dispatch(processServerReq('generate'));
};

export const decrypt = (groupValues, decryptParam) => (dispatch, getState) => {
  const store = getState();
  const node = store.get(`rep/${storePathParam(store).name}/reportEditor`);

  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const modelDef = definition[modelType][modelName];

  dispatch({
    type: reportEditorActions.SR_START,
    payload: {
      model: modelName,
    },
  });

  const DCSNode = node.get('dataCompositionSettings');

  const newGroup = {
    _1: {
      Use: true,
      ViewMode: 'QuickAccess',
      Parent: null,
      Name: '',
      Order: 1,
      Index: 1,
      Filter: null,
      Type: 'Group',
      Selection: {
        _1: {
          Type: 'Auto',
          Use: true,
          Parent: null,
          Order: 1,
        },
      },
      GroupFields: {
        _1: {
          Type: 'GroupField',
          Use: true,
          EndOfPeriod: null,
          BeginOfPeriod: null,
          Field: decryptParam,
          GroupType: 'Items',
          AdditionType: 'None',
        },
      },
    },
  };

  const newFilter = groupValues.reduce((R, v, k) => {
    const maxId = getMaxId(R);
    return R.set(`_${maxId + 1}`, new Map({
      ComparisonType: 'EQUAL',
      Use: true,
      ViewMode: 'QuickAccess',
      Parent: null,
      LeftValue: k,
      Order: maxId + 1,
      Index: maxId + 1,
      RightValue: v,
      Presentation: '',
      Type: 'FilterItem',
    }));
  }, DCSNode.get('Filter'));

  const DCS = new Map({
    Group: fromJS(newGroup),
    Filter: newFilter,
    Order: DCSNode.get('Order'),
    Selection: DCSNode.get('Selection'),
    ConditionalAppearance: DCSNode.get('ConditionalAppearance'),
  });

  const itemData = {
    ...toSrvMapper(modelDef, node),
    dataCompositionSettings: DCS.toJS(),
  };

  api
    .post$(`${modelType}/${modelDef.backendName}/SR`, {
      method: 'generate', item: itemData, saveSettings: false,
    })
    .then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          dispatch({
            type: reportEditorActions.DECRYPTION_LOADED,
            payload: {
              model: modelName,
              decryption: resultMapper(modelDef, d),
              DCS,
            },
          });
        });
      } else if (r.status === 422) {
        r.json().then(
          // eslint-disable-next-line no-underscore-dangle
          (d) => dispatch(serverRequestError(d._Error)),
        );
      } else {
        dispatch(serverRequestError(`${r.status} ${r.statusText}`));
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};

export const decryptionClose = () => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.DECRYPTION_CLOSED,
    payload: {
      model: storePathParam(getState()).name,
    },
  });
};

export const cancelRequest = () => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.CANCEL_REQUEST,
    payload: {
      model: storePathParam(getState()).name,
    },
  });
  controller.abort();
};

export const reportToFile = (typeFile) => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.GENERATE,
    payload: {
      model: storePathParam(getState()).name,
    },
  });
  dispatch(processServerReq('reportToFile', { format: typeFile }));
};

/* INIT ACTION */
/**
 * @param _type {string}
 * @param model {string}
 * @param mapInitialOptions {object}
 * @param mapStore {function}
 * @param autoExecute {bool}
 * @return {function(*)}
 */
const init = (_type, model, mapInitialOptions = null, mapStore = null, autoExecute = false) => (dispatch, getState) => {
  dispatch({
    type: reportEditorActions.INIT,
    payload: {
      type: `${_type}`,
      model,
      autoExecute,
    },
  });

  if (mapInitialOptions) {
    const preparedState = mapStore ? mapStore(getState()) : getState();
    const initOptions = Object.keys(mapInitialOptions).reduce((r, key) => ({
      ...r,
      [mapInitialOptions[key]]: preparedState.get(key),
    }), {});
    dispatch(processServerReq('INIT', initOptions));
  } else {
    dispatch(processServerReq('INIT'));
  }
};

// const changeFieldHandler = (dispatch, getState) => {}
export const changeField = (path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  dispatch({
    type: reportEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value,
      model: storePathParam(getState()).name,
    },
  });
};

export const activateTableRow = (tableName, rowId, isMutiple = false) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */
  const table = store.getIn([`rep/${storePathParam(store).name}/reportEditor`, `tables/${tableName}`], new List());
  if (isMutiple) {
    dispatch({
      type: reportEditorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [`tables/${tableName}`, rowId, 'IS_ACTIVE'],
        value: !table.getIn([rowId, 'IS_ACTIVE'], false),
        model: storePathParam(getState()).name,
      },
    });
  } else {
    dispatch({
      type: reportEditorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [`tables/${tableName}`],
        value: table.reduce((V, row, key) => V.setIn([key, 'IS_ACTIVE'], key === rowId), table),
        model: storePathParam(getState()).name,
      },
    });
  }
};

export const fillTable = (tableName, path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const table = store.getIn([`rep/${storePathParam(store).name}/reportEditor`, `tables/${tableName}`], new List());

  const newTable = table.map((row) => row.setIn(path, value));

  dispatch({
    type: reportEditorActions.TABLE_FILL,
    payload: {
      name: tableName,
      items: newTable,
      model: storePathParam(getState()).name,
    },
  });
};

export const DCSelectField = (path, field, multiple = false) => (dispatch, getState) => {
  const oldTable = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]);
  const newTable = multiple ? oldTable : oldTable.map((_r) => _r.delete(SELECTED_KEY));
  const newTable2 = field
    ? newTable.setIn([field, SELECTED_KEY], !newTable.getIn([field, SELECTED_KEY], false))
    : newTable;
  if (!oldTable.equals(newTable2)) {
    dispatch({
      type: reportEditorActions.CHANGE_PROPERTY,
      payload: {
        path,
        value: newTable2,
        model: storePathParam(getState()).name,
      },
    });
  }
};

const DCToggleFieldDone = (path, field) => (dispatch, getState) => {
  const vItems = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path, 'visibleItems']);
  const newVI = vItems.setIn([field, TOGGLED_KEY], (!vItems.getIn([field, TOGGLED_KEY], false)) && vItems.getIn([field, 'itemsCount']));
  const newVi2 = generateVisibleItems(getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path, 'items']), newVI);
  dispatch({
    type: reportEditorActions.CHANGE_PROPERTY,
    payload: {
      path: [...path, 'visibleItems'],
      value: newVi2,
      model: storePathParam(getState()).name,
    },
  });
};

export const DCToggleField = (path, field) => (dispatch, getState) => {
  const node = getState().get(`rep/${storePathParam(getState()).name}/reportEditor`);

  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const modelDef = definition[modelType][modelName];

  const cVItem = node.getIn([...path, 'visibleItems', field]);
  if (cVItem.get('itemsCount') && !cVItem.get(TOGGLED_KEY, false) && !cVItem.get('Items')) {
    api.post$(`${modelType}/${modelDef.backendName}/getAvailableParent`, {
      field,
      section: path[1],
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            const fPath = field.split('.')
              .reduce((R, p) => [...R, R.length ? `${R[R.length - 1]}.${p}` : p], [])
              .reduce((R, p) => [...R, p, 'Items'], []);
            dispatch({
              type: reportEditorActions.CHANGE_PROPERTY,
              payload: {
                path: [...path, 'items', ...fPath],
                value: new OrderedMap(data.Items).map((i) => fromJS(i)),
                model: modelName,
              },
            });
            dispatch(DCToggleFieldDone(path, field));
          });
        } else {
          throw new Error(`${resp.status} ${resp.statusText}`);
        }
      })
      .catch((e) => dispatch(serverRequestError(e.message)));
  } else {
    dispatch(DCToggleFieldDone(path, field));
  }
};

export const DCDeleteField = (path, id = null) => (dispatch, getState) => {
  const KEY = '_SELECTED';

  const deleteBroken = (table) => table.filter((row) => !row.get('Parent') || table.has(row.get('Parent')));
  let newTable1;
  if (!id) {
    newTable1 = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]).filter((row) => !row.get(KEY, false));
  } else {
    newTable1 = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]).filter((row, rId) => rId !== id);
  }
  let newTable2;
  while (true) {
    newTable2 = deleteBroken(newTable1);
    if (newTable2.equals(newTable1)) {
      break;
    }
    newTable1 = newTable2;
  }

  dispatch({
    type: reportEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value: newTable2,
      model: storePathParam(getState()).name,
    },
  });
};

/**
 * Лобавление полей в раздел
 * @param path {[string, string]} - Узел стора куда добвляется поле(я).
 * @param fields - Сами поля, которые добавляются
 * @param parentId - Кто родитель
 * @param index - Куда
 * @return {function(*, *)}
 * @constructor
 */
export const DCAddField = (path, fields, parentId, index) => (dispatch, getState) => {
  const table = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]).map((r) => r.delete(SELECTED_KEY));
  const maxId = getMaxId(table);
  // const remapParents = table.mapEntries(([k, v]) => [k, k < index ? k : k + fields.size]);
  const before = table.slice(0, index);
  const after = table.slice(index);
  const newFields = fields.mapEntries(([k, v], i) => [
    `_${maxId + i + 1}`,
    v
      .set('Use', true).set('Parent', parentId),
  ]).setIn([`_${maxId + fields.size}`, SELECTED_KEY], true);

  const newTable = indexTable(before
    .merge(newFields)
    .merge(after));
  dispatch({
    type: reportEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value: newTable,
      model: storePathParam(getState()).name,
    },
  });
};

export const getFile = (paramGetter) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const node = store.get(`rep/${storePathParam(store).name}/reportEditor`);

  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const modelDef = definition[modelType][modelName];

  const itemData = toSrvMapper(modelDef, node);
  api
    .post$(`${modelType}/${modelDef.backendName}/GetFile`, { item: itemData, ...paramGetter(store) })
    .then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          dispatch(newFile(d.filename, d.content, d.type, d.description));
        });
      } else {
        dispatch(serverRequestError(r.status));
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};
/**
 * Перемещение полей внутри одного раздела
 * @param path {string} - Узел стора куда добвляется поле(я).
 * @param fields - Сами поля, которые добавляются
 * @param from - отклуда перемещаем
 * @param to - куда перемещаем
 * @param newParentId - Новый Ролитель
 * @return {function(*, *)}
 * @constructor
 */
export const DCMoveField = (path, fields, from, to, newParentId) => (dispatch, getState) => {
  const table = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]);
  const min = Math.min(from, to);
  const max = Math.max(from, to);
  const before = table.slice(0, min - 1);

  const t1 = from < to
    ? table.slice((from + fields.size) - 1, max)
    : table.slice(from - 1, (from + fields.size) - 1);
  const t2 = from < to
    ? table.slice(from - 1, (from + fields.size) - 1)
    : table.slice(min - 1, (from + fields.size) - 2);
  const after = table.slice(max);
  const newTable = indexTable(before
    .merge(t1.map((r, k) => (fields.has(k) ? r.set('Parent', newParentId) : r)))
    .merge(t2.map((r, k) => (fields.has(k) ? r.set('Parent', newParentId) : r)))
    .merge(after));

  const oldParentId = fields.reduce((R, r) => r.get('Parent'), null);
  // подчиненные элементы нужно переподчинить родителю переносимого поля
  const newTable2 = from < to
    ? newTable.map((r) => (fields.has(r.get('Parent')) ? r.set('Parent', oldParentId) : r))
    : newTable;

  dispatch({
    type: reportEditorActions.CHANGE_PROPERTY,
    payload: {
      path, //
      value: newTable2,
      model: storePathParam(getState()).name,
    },
  });
};

export const goToOldVersion = () => (dispatch, getState) => {
  const store = getState();
  const node = store.get(`rep/${storePathParam(store).name}/reportEditor`);
  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const modelDef = definition[modelType][modelName];

  const getOldVersionLink = async () => {
    const url = `${modelType}/${modelDef.backendName}/getURL`;
    const r = await api.get$(url);
    if (!r.ok) {
      let e;
      try {
        e = await r.text();
      } catch {
        dispatch(serverRequestError(`${r.status} ${r.statusText}`));
      }
    }
    return r.json();
  };
  getOldVersionLink()
    .then((d) => {
      try {
        window.open(d.oldVersionRef, '_blank').focus();
      } catch {
        dispatch(serverRequestError('В адресній строці необхідно дозволити відкривання нових вкладок'));
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};

const getNode = (store) => {
  const { name } = storePathParam(store);
  return store.get(`rep/${name}/reportEditor`, new Map());
};

const filterActiveItem = (v) => v.get('IS_ACTIVE', false);

export const addTableRow = (tableName) => (dispatch, getState) => {
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = storePathParam(store).type;
  dispatch({
    type: reportEditorActions.TABLE_ROW_ADD,
    payload: {
      name: tableName,
      type: modelType,
      model,
    },
  });
};

export const copyTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: reportEditorActions.TABLE_ROW_COPY,
    payload: {
      name: tableName,
      items: activeSeq,
      model,
    },
  });
};

export const swapUpTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table
    .toOrderedMap()
    .filter(filterActiveItem)
    .reverse();

  dispatch({
    type: reportEditorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: true,
      items: activeSeq.reverse(),
      model,
    },
  });
};

export const swapDownTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toOrderedMap().filter(filterActiveItem);

  dispatch({
    type: reportEditorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: false,
      items: activeSeq,
      model,
    },
  });
};

export const removeTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */

  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: reportEditorActions.TABLE_ROW_REMOVE,
    payload: {
      name: tableName,
      items: activeSeq,
      model,
    },
  });
};

export default {
  init,
  processServerReq,
  generate,
  changeField,
  activateTableRow,
  fillTable,
  getFile,
  DCAddField,
  DCSelectField,
  DCDeleteField,
  DCToggleField,
  cancelRequest,
  goToOldVersion,
  addTableRow,
  copyTableRow,
  swapUpTableRow,
  swapDownTableRow,
  removeTableRow,
};
