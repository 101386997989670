import React, {
  memo, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { DivResizerX } from './styles';

function ResizerX({ onResize, onResetColumnSize }) {
  const resizerRef = useRef();
  const coord = useRef({ start: null, startWidth: 0 });
  const predStyle = useRef({});

  const processResize = useCallback(
    (e) => {
      const { start, startWidth } = coord.current;
      const { top, left, height } = resizerRef.current.parentNode.getBoundingClientRect();
      if (resizerRef.current && start !== null) {
        resizerRef.current.parentNode.style.width = `${(startWidth + e.clientX) - start}px`;
        resizerRef.current.parentNode.style.zIndex = '10000';
        resizerRef.current.parentNode.style.position = 'fixed';
        resizerRef.current.parentNode.style.height = `${height}px`;
        resizerRef.current.parentNode.style.top = `${top}px`;
        resizerRef.current.parentNode.style.left = `${left}px`;
      }
    },
    [],
  );

  const processResizeEnd = useCallback(() => { window.removeEventListener('mousemove', processResize); }, [processResize]);

  const resizeEnd = useCallback(
    (e) => {
      processResizeEnd();
      const { start, startWidth } = coord.current;
      if (start === null) {
        return false;
      }
      coord.current.start = null;
      const newWidth = (startWidth + e.clientX) - start;
      onResize(e, newWidth);

      resizerRef.current.parentNode.style = {};
      resizerRef.current.parentNode.style = predStyle.current;
      // resizerRef.current.parentNode.style.display = 'flex';

      resizerRef.current.style = {};
      return true;
    },
    [onResize, predStyle, processResizeEnd],
  );

  useEffect(
    () => () => {
      if (resizerRef.current) {
        window.removeEventListener('mouseup', resizeEnd);
        window.removeEventListener('mousemove', processResize);
      }
    },
    [processResize, resizeEnd],
  );

  const resizeStart = useCallback(
    (e) => {
      predStyle.current = resizerRef.current.parentNode.style;
      coord.current.start = e.clientX;
      coord.current.startWidth = resizerRef.current.parentNode.getBoundingClientRect().width;
      e.preventDefault();
      e.stopPropagation();
      window.addEventListener('mouseup', resizeEnd);
      window.addEventListener('mousemove', processResize);
    },
    [processResize, resizeEnd],
  );

  // eslint-disable-next-line
  const contextMenu = useCallback(
    (e) => {
      if (onResetColumnSize) {
        onResetColumnSize(e);
        e.preventDefault();
      }
    },
    [onResetColumnSize],
  );

  const onClick = useCallback(
    (e) => e.stopPropagation(),
    [],
  );

  return (
    <DivResizerX
      ref={resizerRef}
      onMouseDown={resizeStart}
      onClick={onClick}
      onContextMenu={contextMenu}
    />

  );
}

ResizerX.propTypes = {
  onResize: PropTypes.func.isRequired,
  onResetColumnSize: PropTypes.func,
};

ResizerX.defaultProps = {
  onResetColumnSize: null,
};
export default memo(ResizerX);
