import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button, ButtonGroup, ButtonToolbar, Modal, Navbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckDouble, faSave, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { dellComponentFromWindowsManager } from '../../actions/windowsManager';
import { useEditorActions, useStorePath } from '../../actions/editor2';

export function BasicMenu2({
  disabled,
  isChanged,
  extraContent = null,
  header = null,
  onClose,
  onSave,
  modelName,
  modelType,
  id,
}) {
  const path = useStorePath(modelType, modelName, id);
  const { save } = useEditorActions(modelType, modelName, id);
  const dispatch = useDispatch();
  const [q, setQ] = useState(false);
  const onCloseClick = useCallback(
    () => {
      if (isChanged) {
        setQ(true);
      } else {
        dispatch(dellComponentFromWindowsManager(path));
        onClose();
      }
    },
    [dispatch, isChanged, onClose, path],
  );
  return (
    <>
      <div className="d-flex">
        <h3>{header}</h3>
        <Button variant="link" onClick={onCloseClick} className="ms-auto">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <Navbar>
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              disabled={disabled}
              onClick={() => dispatch(save({
                exitOnSave: true,
                onClose,
                onSave,
              }))}
              variant="primary"
            >
              <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
              Зберегти та закрити
            </Button>
            <Button
              disabled={disabled}
              onClick={() => dispatch(save({
                onClose,
                onSave,
              }))}
              variant="secondary"
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              Зберегти
            </Button>
          </ButtonGroup>
          {extraContent}
        </ButtonToolbar>
      </Navbar>
      <Modal show={q} onHide={() => setQ(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Вихід без запису
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              dispatch(save({
                exitOnSave: true,
                onClose,
                onSave,
              }));
            }}
          >
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Зберегти зміни та вийти
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch(dellComponentFromWindowsManager(path));
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            Вийти без збереження
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

BasicMenu2.propTypes = {
  disabled: PropTypes.bool,
  extraContent: PropTypes.node,
  header: PropTypes.string,
  isChanged: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  modelType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
BasicMenu2.defaultProps = {
  disabled: true,
  extraContent: null,
  header: null,
  isChanged: false,
};

export default BasicMenu2;

// TODO: Enable bootstrap for this
