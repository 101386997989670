import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL7;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG1S'}
              highlighted={highlights.includes('T7RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG1S')}
            >
              {meta.columns.T7RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG3'}
              highlighted={highlights.includes('T7RXXXXG3')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG3')}
            >
              {meta.columns.T7RXXXXG3.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG4'}
              highlighted={highlights.includes('T7RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG4')}
            >
              {meta.columns.T7RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG5'}
              highlighted={highlights.includes('T7RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG5')}
            >
              {meta.columns.T7RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG6'}
              highlighted={highlights.includes('T7RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG6')}
            >
              {meta.columns.T7RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG7'}
              highlighted={highlights.includes('T7RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG7')}
            >
              {meta.columns.T7RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG8'}
              highlighted={highlights.includes('T7RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG8')}
            >
              {meta.columns.T7RXXXXG8.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG9'}
              highlighted={highlights.includes('T7RXXXXG9')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG9')}
            >
              {meta.columns.T7RXXXXG9.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG10'}
              highlighted={highlights.includes('T7RXXXXG10')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG10')}
            >
              {meta.columns.T7RXXXXG10.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T7RXXXXG11'}
              highlighted={highlights.includes('T7RXXXXG11')}
              onToggleHighlght={() => onToggleHighlght('T7RXXXXG11')}
            >
              {meta.columns.T7RXXXXG11.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
