import React from 'react';
import useComponentsInit from './hooks/useGeneralTP';
import BaseTP from '../../../../components/tableParts/baseTablePartForHooks';

function GeneralTP() {
  const {
    tableName,
    arrBypassGrid,
    componentsType,
    gridTemplateParam,
    headerCol,
    footerCol,
    data,
    ComposeSettings,
  } = useComponentsInit();
  const tableComponents = {
    isHeaderComponent: true,
    isRowComponent: true,
    isFooterComponent: true,
  };

  return (
    <BaseTP
      arrBypassGrid={arrBypassGrid}
      componentsType={componentsType}
      data={data}
      gridTemplateParam={gridTemplateParam}
      headerCol={headerCol}
      footerCol={footerCol}
      tableName={tableName}
      tableComponents={tableComponents}
      reFreshForm={ComposeSettings}
    />
  );
}

export default GeneralTP;
