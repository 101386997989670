import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Navbar, Row,
} from 'react-bootstrap';
import meta from '../../../constants/meta';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { dpEditor as actions } from '../../../actions';
import LogicaReportType from '../../../constants/meta/enums/logicaReportTypes';
import LogicaPing from '../../Logica/Ping';
import Documents from './documents';

const md = meta.dp.importFromLogica;

const DocTypeValues = Object.values(LogicaReportType)
  .map((v) => ({ value: v.name, display_name: v.label }));

function ImportFromLigicaEditor() {
  const dispatch = useDispatch();
  const { headerForm, mountPoint } = useSelector(mapState);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  const ticket = localStorage.getItem('ticket');
  useEffect(
    () => {
      if (headerForm.get('Ticket') !== ticket) {
        dispatch(actions.changeField(['headerForm', 'Ticket'], ticket || null));
      }
    },
    [dispatch, headerForm, ticket],
  );

  return (
    <div>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <Navbar>
        {!ticket && (
          <Navbar.Brand href="#/dp/authentication/">Авторизація у LOGICA</Navbar.Brand>
        )}
        <Button
          variant="primary"
          disabled={!ticket || !headerForm.get('DocumentType')}
          onClick={() => dispatch(actions.processServerReq('GET_LIST'))}
        >
          Отримати перелік документів
        </Button>
        <Button
          variant="success"
          disabled={!ticket || !headerForm.get('DocumentType')}
          onClick={() => dispatch(actions.processServerReq('LOAD_DOCS'))}
        >
          Завантажити відмічені документи
        </Button>
      </Navbar>
      <Row>
        <Col>
          <EditorControls.YearInput
            label="Рік"
            value={headerForm.get('Year', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Year'], v))}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label="Тип звіту"
            value={headerForm.get('DocumentType')}
            values={DocTypeValues}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DocumentType'], v))}
          />
        </Col>
      </Row>
      <Documents mountPoint={mountPoint} />
    </div>
  );
}

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name, // importTreasFiles',
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // Табличные части
  },
);

export default enchance(ImportFromLigicaEditor);
