import React from 'react';
import PropTypes from 'prop-types';
import { useAccordionButton } from 'react-bootstrap';
import classNames from 'classnames';
import { childrenPropType } from '../../../../../common/proptypes';

function AccHeader({ children, eventKey, className }) {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames('btn', className)}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

AccHeader.propTypes = {
  children: childrenPropType.isRequired,
  eventKey: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AccHeader.defaultProps = {
  className: '',
};

export default AccHeader;
