// eslint-disable-next-line import/prefer-default-export
export const months = [...Array(12)].map((_, m) => {
  const d = new Date(2020, m, 1);
  return d.toLocaleString('uk', { month: 'long' });
});

export const moneysStingOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const moneysThousandStingOptions = {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
};
