import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/freeRem';

const meta = md.tables.form1;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  form2022,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={4} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'budget'}
              highlighted={highlights.includes('budget')}
              onToggleHighlght={() => onToggleHighlght('budget')}
            >
              {meta.columns.budget.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumT'}
              highlighted={highlights.includes('sumT')}
              onToggleHighlght={() => onToggleHighlght('sumT')}
            >
              {meta.columns.sumT.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRasp'}
              highlighted={highlights.includes('sumRasp')}
              onToggleHighlght={() => onToggleHighlght('sumRasp')}
            >
              {meta.columns.sumRasp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumWork'}
              highlighted={highlights.includes('sumWork')}
              onToggleHighlght={() => onToggleHighlght('sumWork')}
            >
              {meta.columns.sumWork.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prWork'}
              highlighted={highlights.includes('prWork')}
              onToggleHighlght={() => onToggleHighlght('prWork')}
            >
              {meta.columns.prWork.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCom'}
              highlighted={highlights.includes('sumCom')}
              onToggleHighlght={() => onToggleHighlght('sumCom')}
            >
              {meta.columns.sumCom.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prCom'}
              highlighted={highlights.includes('prCom')}
              onToggleHighlght={() => onToggleHighlght('prCom')}
            >
              {meta.columns.prCom.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumMed'}
              highlighted={highlights.includes('sumMed')}
              onToggleHighlght={() => onToggleHighlght('sumMed')}
            >
              {meta.columns.sumMed.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prMed'}
              highlighted={highlights.includes('prMed')}
              onToggleHighlght={() => onToggleHighlght('prMed')}
            >
              {meta.columns.prMed.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumProd'}
              highlighted={highlights.includes('sumProd')}
              onToggleHighlght={() => onToggleHighlght('sumProd')}
            >
              {meta.columns.sumProd.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prProd'}
              highlighted={highlights.includes('prProd')}
              onToggleHighlght={() => onToggleHighlght('prProd')}
            >
              {meta.columns.prProd.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumP'}
              highlighted={highlights.includes('sumP')}
              onToggleHighlght={() => onToggleHighlght('sumP')}
            >
              {meta.columns.sumP.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prP'}
              highlighted={highlights.includes('prP')}
              onToggleHighlght={() => onToggleHighlght('prP')}
            >
              {meta.columns.prP.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumOther'}
              highlighted={highlights.includes('sumOther')}
              onToggleHighlght={() => onToggleHighlght('sumOther')}
            >
              {meta.columns.sumOther.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prOther'}
              highlighted={highlights.includes('prOther')}
              onToggleHighlght={() => onToggleHighlght('prOther')}
            >
              {meta.columns.prOther.label}
            </TableHeaderCell>
          </Col>
          {form2022
          && (
            <Col>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'prRasp'}
                highlighted={highlights.includes('prRasp')}
                onToggleHighlght={() => onToggleHighlght('prRasp')}
              >
                {meta.columns.prRasp.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'sumUnused'}
                highlighted={highlights.includes('sumUnused')}
                onToggleHighlght={() => onToggleHighlght('sumUnused')}
              >
                {meta.columns.sumUnused.label}
              </TableHeaderCell>
            </Col>
          )}
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  form2022: PropTypes.bool,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  form2022: false,
  /// /
};

export default memo(TPHeader);
