import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  authorityId,
  budgetId,
}) {
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7itemProps = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const targetProgramProps = tableHooks.useItemInputProps('target_program', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const submZagFundProps = tableHooks.useNumberInputProps('subm_zag_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const submSpecFundProps = tableHooks.useNumberInputProps('subm_spec_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const submDevFundProps = tableHooks.useNumberInputProps('subm_dev_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const refundZagFundProps = tableHooks.useNumberInputProps('refund_zag_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const refundSpecFundProps = tableHooks.useNumberInputProps('refund_spec_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const refundDevFundProps = tableHooks.useNumberInputProps('refund_dev_fund', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const disposerFitler = useMemo(
    () => ({ parent: authorityId }),
    [authorityId],
  );
  const kbp7itemFitler = useMemo(
    () => ({ owner: authorityId }),
    [authorityId],
  );
  const targetProgramFitler = useMemo(
    () => ({ owner: budgetId }),
    [budgetId],
  );

  const subm = (submZagFundProps.value || 0) + (submSpecFundProps.value || 0);
  const refund = (refundZagFundProps.value || 0) + (refundSpecFundProps.value || 0);
  const total = subm - refund;
  const specFund = (submSpecFundProps.value || 0) - (refundSpecFundProps.value || 0);
  const zagFund = (submZagFundProps.value || 0) - (refundZagFundProps.value || 0);
  const devFund = (submDevFundProps.value || 0) - (refundDevFundProps.value || 0);

  return (
    <>
      <Col md={4}>
        <Row noGutters>
          {!disposerInHeader && (
          <Col sm={12}>
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker {...disposerProps} filter={disposerFitler} noHierarchy />
            </TabelRowCell>
          </Col>
          )}
          <Col md={4}>
            <Badge variant="info">{row.kbp7 && row.kbp7.repr}</Badge>
          </Col>
          <Col md={8}>
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker {...kbp7itemProps} filter={kbp7itemFitler} />
            </TabelRowCell>
          </Col>
          <Col sm={12}>
            <TabelRowCell column="target_program" highlighted={highlights.includes('target_program')}>
              <TableControls.ItemPicker {...targetProgramProps} filter={targetProgramFitler} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col md={8}>
        <Row noGutters>
          <Col md={4}>
            <Row noGutters>
              <Col sm={12}>
                <TabelRowCell column="subm" className="text-right py-2">
                  <Badge variant="info">{subm.toLocaleString('uk', moneysStingOptions)}</Badge>
                </TabelRowCell>
              </Col>
              <Col md={6}>
                <TabelRowCell column="subm_zag_fund" highlighted={highlights.includes('subm_zag_fund')}>
                  <TableControls.NumberInput {...submZagFundProps} />
                </TabelRowCell>

              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>
                    <TabelRowCell column="subm_spec_fund" highlighted={highlights.includes('subm_spec_fund')}>
                      <TableControls.NumberInput {...submSpecFundProps} />
                    </TabelRowCell>
                  </Col>
                  <Col sm={12}>
                    <TabelRowCell column="subm_dev_fund" highlighted={highlights.includes('subm_dev_fund')}>
                      <TableControls.NumberInput {...submDevFundProps} />
                    </TabelRowCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row noGutters>
              <Col sm={12}>
                <TabelRowCell column="refund" className="text-right py-2">
                  <Badge variant="info">{refund.toLocaleString('uk', moneysStingOptions)}</Badge>
                </TabelRowCell>
              </Col>
              <Col md={6}>
                <TabelRowCell column="refund_zag_fund" highlighted={highlights.includes('refund_zag_fund')}>
                  <TableControls.NumberInput {...refundZagFundProps} />
                </TabelRowCell>

              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>
                    <TabelRowCell column="refund_spec_fund" highlighted={highlights.includes('refund_spec_fund')}>
                      <TableControls.NumberInput {...refundSpecFundProps} />
                    </TabelRowCell>
                  </Col>
                  <Col sm={12}>
                    <TabelRowCell column="refund_dev_fund" highlighted={highlights.includes('refund_dev_fund')}>
                      <TableControls.NumberInput {...refundDevFundProps} />
                    </TabelRowCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row noGutters>
              <Col sm={12}>
                <TabelRowCell column="subm" className="text-right py-2">
                  <Badge variant="info">{total.toLocaleString('uk', moneysStingOptions)}</Badge>
                </TabelRowCell>
              </Col>
              <Col md={6}>
                <TabelRowCell column="subm_zag_fund" highlighted={highlights.includes('zag_fund')} className="text-right py-2">
                  <Badge variant="info">{zagFund.toLocaleString('uk', moneysStingOptions)}</Badge>
                </TabelRowCell>

              </Col>
              <Col>
                <Row noGutters>
                  <Col sm={12}>
                    <TabelRowCell column="subm_spec_fund" highlighted={highlights.includes('spec_fund')} className="text-right py-2">
                      <Badge variant="info">{specFund.toLocaleString('uk', moneysStingOptions)}</Badge>
                    </TabelRowCell>
                  </Col>
                  <Col sm={12}>
                    <TabelRowCell column="subm_dev_fund" highlighted={highlights.includes('dev_fund')} className="text-right py-2">
                      <Badge variant="info">{devFund.toLocaleString('uk', moneysStingOptions)}</Badge>
                    </TabelRowCell>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}

TRow.propTypes = {
  disposerInHeader: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  authorityId: PropTypes.number,
  budgetId: PropTypes.number,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  authorityId: null,
  budgetId: null,
};

export default memo(TRow);
