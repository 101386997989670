import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes } from '../dataPropTypes';
import { decimalToStr } from '../../../../../common/funcs';

function DocDetails({ rows, fields, mode }) {
  return (
    <div className="h-100 d-flex flex-column overflow-hidden">
      <Row className="h5 text-center bg-primary-subtle border rounded">
        {mode === 'finorder' && (
          <>
            <Col>{fields.disposer && fields.disposer.label}</Col>
            <Col>{fields.kbp7 && fields.kbp7.label}</Col>
            <Col>{fields.kekv && fields.kekv.label}</Col>
            <Col>{fields.balance && fields.balance.label}</Col>
          </>
        )}
        {mode === 'finrequest' && (
          <>
            <Col>{fields.disposer && fields.disposer.label}</Col>
            <Col sm={1} />
            <Col>{fields.kbp7_item && fields.kbp7_item.label}</Col>
            <Col sm={1} />
            <Col>{fields.kekv_item && fields.kekv_item.label}</Col>
            <Col>{fields.amount && fields.amount.label}</Col>
          </>
        )}
      </Row>
      <div className="flex-fill overflow-auto">
        {rows.map((r) => (
          <Row key={r.id} className="mx-0">
            {mode === 'finorder' && (
              <>
                <Col className="text-truncate" title={r.disposer.repr}>
                  {r.disposer.repr}
                </Col>
                <Col className="text-truncate text-center" title={r.kbp7.repr}>
                  {r.kbp7.repr}
                </Col>
                <Col className="text-truncate text-center" title={r.kekv.repr}>
                  {r.kekv.repr}
                </Col>
                <Col className="text-end" title={decimalToStr(r.balance, '0.00')}>
                  {decimalToStr(r.balance, '0.00')}
                </Col>
              </>
            )}
            {mode === 'finrequest' && (
              <>
                <Col className="text-truncate" title={r.disposer.repr}>
                  {r.disposer.repr}
                </Col>
                <Col className="text-truncate text-center" sm={1}>
                    {r.kbp7.repr}
                </Col>
                <Col className="text-truncate" title={r.kbp7_item.repr}>
                  {r.kbp7_item.repr}
                </Col>
                <Col className="text-truncate text-center" sm={1}>
                    {r.kekv.repr}
                </Col>
                <Col className="text-truncate" title={r.kekv_item.repr}>
                  {r.kekv_item.repr}
                </Col>
                <Col className="text-end" title={decimalToStr(r.amount, '0.00')}>
                  {decimalToStr(r.amount, '0.00')}
                </Col>
              </>
            )}
          </Row>
        ))}
      </div>
    </div>
  );
}

DocDetails.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  mode: PropTypes.oneOf(['finrequest', 'finorder']).isRequired,
};

export default DocDetails;
