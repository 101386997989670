import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/expensesOnEducation';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={3}
        sm={12}
        className="px-1 d-flex flex-column justify-content-around"
      >
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'CSU'}
          highlighted={highlights.includes('CSU')}
          onToggleHighlght={() => onToggleHighlght('CSU')}
        >
          {meta.columns.CSU.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'CSUtext'}
          highlighted={highlights.includes('CSUtext')}
          onToggleHighlght={() => onToggleHighlght('CSUtext')}
        >
          {meta.columns.CSUtext.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'EDRPOU'}
          highlighted={highlights.includes('EDRPOU')}
          onToggleHighlght={() => onToggleHighlght('EDRPOU')}
        >
          {meta.columns.EDRPOU.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'KBP7'}
          highlighted={highlights.includes('KBP7')}
          onToggleHighlght={() => onToggleHighlght('KBP7')}
        >
          {meta.columns.KBP7.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'NonPedagogicalStaff'}
          highlighted={highlights.includes('NonPedagogicalStaff')}
          onToggleHighlght={() => onToggleHighlght('NonPedagogicalStaff')}
        >
          {meta.columns.NonPedagogicalStaff.label}
        </TableHeaderCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="px-1 d-flex flex-column justify-content-around"
      >
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'ExpensesPrevYear'}
          highlighted={highlights.includes('ExpensesPrevYear')}
          onToggleHighlght={() => onToggleHighlght('ExpensesPrevYear')}
        >
          {meta.columns.ExpensesPrevYear.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'ExpensesPedWorkersSub'}
          highlighted={highlights.includes('ExpensesPedWorkersSub')}
          onToggleHighlght={() => onToggleHighlght('ExpensesPedWorkersSub')}
        >
          {meta.columns.ExpensesPedWorkersSub.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'ExpensesPedWorkersBud'}
          highlighted={highlights.includes('ExpensesPedWorkersBud')}
          onToggleHighlght={() => onToggleHighlght('ExpensesPedWorkersBud')}
        >
          {meta.columns.ExpensesPedWorkersBud.label}
        </TableHeaderCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="px-1 d-flex flex-column justify-content-around"
      >
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'ExpensesThisYear'}
          highlighted={highlights.includes('ExpensesThisYear')}
          onToggleHighlght={() => onToggleHighlght('ExpensesThisYear')}
        >
          {meta.columns.ExpensesThisYear.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'ExpensesThisYearSub'}
          highlighted={highlights.includes('ExpensesThisYearSub')}
          onToggleHighlght={() => onToggleHighlght('ExpensesThisYearSub')}
        >
          {meta.columns.ExpensesThisYearSub.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'ExpensesThisYearBud'}
          highlighted={highlights.includes('ExpensesThisYearBud')}
          onToggleHighlght={() => onToggleHighlght('ExpensesThisYearBud')}
        >
          {meta.columns.ExpensesThisYearBud.label}
        </TableHeaderCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="px-1 d-flex flex-column justify-content-around"
      >
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'AvergageSalaryBoss'}
          highlighted={highlights.includes('AvergageSalaryBoss')}
          onToggleHighlght={() => onToggleHighlght('AvergageSalaryBoss')}
        >
          {meta.columns.AvergageSalaryBoss.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left border-bottom flex-fill"
          active={activeCol === 'AvergageSalaryTeacher'}
          highlighted={highlights.includes('AvergageSalaryTeacher')}
          onToggleHighlght={() => onToggleHighlght('AvergageSalaryTeacher')}
        >
          {meta.columns.AvergageSalaryTeacher.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'AvergageSalaryYoung'}
          highlighted={highlights.includes('AvergageSalaryYoung')}
          onToggleHighlght={() => onToggleHighlght('AvergageSalaryYoung')}
        >
          {meta.columns.AvergageSalaryYoung.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
