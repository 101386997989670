import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faM } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { AppContext } from '../../minfin/providers/authProvider';

const selector = (store) => store.getIn(['auth', 'sessionOptions', 'use_minfin_auth'], false);
function MinfinStatus() {
  const useMFAuth = useSelector(selector);
  const { currentUser } = useContext(AppContext);
  const authenticatedOnMF = !!currentUser;
  return (
    <div className="d-flex justify-content-center align-items-center border px-2">
      <FontAwesomeIcon
        icon={faM}
        size="lg"
        beat={useMFAuth && !authenticatedOnMF}
        className={classNames({
          'text-muted': !useMFAuth,
          'text-success': useMFAuth && authenticatedOnMF,
          'text-danger': useMFAuth && !authenticatedOnMF,
        })}
      />
    </div>
  );
}

export default MinfinStatus;
