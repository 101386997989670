import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { DOC_REGISTRY_KINDS } from './const';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authority,
  kind,
}) {
  const lawObligationProps = tableHooks.useItemInputProps('law_obligation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const finObligationProps = tableHooks.useItemInputProps('fin_obligation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const paymentOrderProps = tableHooks.useItemInputProps('payment_order', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const docFilter = useMemo(() => ({
    owner: authority ? authority.id : 0,
  }), [authority]);
  const amountProps = tableHooks.useNumberInputProps('amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  return (
    <>
      <Col md={9}>
        {kind === DOC_REGISTRY_KINDS.LAW_OBLIGATION && (
        <TabelRowCell column="law_obligation" highlighted={highlights.includes('law_obligation')}>
          <TableControls.ItemPicker
            {...lawObligationProps}
            filter={docFilter}
          />
        </TabelRowCell>
        )}
        {kind === DOC_REGISTRY_KINDS.FIN_OBLIGATION && (
        <TabelRowCell column="fin_obligation" highlighted={highlights.includes('fin_obligation')}>
          <TableControls.ItemPicker
            {...finObligationProps}
            filter={docFilter}
          />
        </TabelRowCell>
        )}
        {kind === DOC_REGISTRY_KINDS.PAYMENT_ORDER && (
        <TabelRowCell column="payment_order" highlighted={highlights.includes('payment_order')}>
          <TableControls.ItemPicker
            {...paymentOrderProps}
            filter={docFilter}
          />
        </TabelRowCell>
        )}
      </Col>
      <Col>
        <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
          <TableControls.NumberInput
            {...amountProps}
          />
        </TabelRowCell>
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  kind: PropTypes.number,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  authority: null,
  kind: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
