const frontendURL = '/cat/symbolKdb/';
const backendURL = '/api/bls/symbolKdb/';
const name = 'Відповідності символу звітності коду класифікації доходів бюджету';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
