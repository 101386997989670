import React, { useState, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const fadeIn = (top, left) => keyframes`
  from {
    height: 0;
    width: 0;
  }

  to {
    height: calc(100vh - ${top}px);
    width: calc(100vw - ${left}px);
  }
`;

const StyledDiv = styled.div`
  
`;

const PortalDiv = styled.div.attrs(({ top, left }) => ({
  style: {
    left,
    top,
    height: `calc(100vh - ${top}px)`,
    width: `calc(100vw - ${left}px)`,
  },
}))`
  position: absolute;
  background: white;
  border-radius: .3rem;
  padding: 5px;
  overflow: hidden;
  animation: ${({ top, left }) => fadeIn(top, left)} 1s linear ;
`;

function ButtonWithPortal({ button, children }) {
  const [opened, setOpened] = useState(false);
  const triggerButton = useMemo(
    () => React.cloneElement(button, { onClick: () => setOpened(!opened) }),
    [button, opened],
  );
  const ref = useRef(null);
  const rect = ref.current ? ref.current.getBoundingClientRect() : {};

  return (
    <StyledDiv ref={ref}>
      {triggerButton}
      {opened && createPortal(
        (
          <PortalDiv top={rect.bottom} left={(rect.left + rect.right) / 2}>
            {children}
          </PortalDiv>
        ), document.body,
      )}
    </StyledDiv>
  );
}

ButtonWithPortal.propTypes = {
  button: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default ButtonWithPortal;
