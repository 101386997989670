import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TPHeader from './header';
import TPRow from './row';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/draftAnnualSpend';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { soSelector } from '../../../../_common/selectors';

const tableMeta = md.tables.targetProg;

function GeneralTP({ data, actions, readOnly }) {
  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const CSUinH = data[md.columns.CSUOnHead.name];
  const FI = data[md.columns.FI.name];
  const budget = data[md.columns.budget.name];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const tablename = 'general';

  const tableMD = md.tables[tablename];

  const total = tableData
    .reduce((sum, row) => sum + (row[tableMD.columns.sum.name] || 0), 0);

  const specTotal = tableData
    .reduce((sum, row) => sum + (row[tableMD.columns.sumSpecF.name] || 0), 0);

  const genTotal = tableData
    .reduce((sum, row) => sum + (row[tableMD.columns.sumGenF.name] || 0), 0);

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          CSUinH={CSUinH}
          total={total}
          specTotal={specTotal}
          genTotal={genTotal}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            CSUinH={CSUinH}
            FI={FI}
            isFilterCsuElemKFK={isFilterCsuElemKFK}
            budget={budget}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
