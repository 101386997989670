import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const StyledLoaderContainer = styled.div`
  position: fixed;
  z-index: 1051;
  left: calc(50% - .5em);
  top: calc(50% - .5em);
`;

function Loader({ text }) {
  return (
    <StyledLoaderContainer className="p-3 shadow-lg rounded bg-white d-flex align-items-center">
      <Spinner animation="border" className="me-2" />
      {text}
    </StyledLoaderContainer>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Зачекайте, будь ласка',
};

export default Loader;
