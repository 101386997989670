import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';

const StringInput = forwardRef(({
  value, disabled, readOnly, size,
  onClick, onFocus, maxLength, onChange,
  errored, tabIndex, onBlur, prepend, append, type,
}, ref) => {
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };
  const displayValue = useMemo(
    () => value || '',
    [value],
  );
  return (
    <InputGroup>
      {prepend}
      <FormControl
        ref={ref}
        value={displayValue}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        isInvalid={errored}
        tabIndex={tabIndex}
        title={displayValue}
        size={size || 'sm'}
        type={type}
      />
      {append}
    </InputGroup>
  );
});

StringInput.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  tabIndex: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errored: PropTypes.bool,
  prepend: PropTypes.symbol,
  append: PropTypes.symbol,
  type: PropTypes.string,
};

StringInput.defaultProps = {
  value: '',
  size: 'sm',
  tabIndex: null,
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  onBlur: null,
  errored: false,
  prepend: null,
  append: null,
  type: '',
};

export default memo(StringInput);
