import React from 'react';
import PropTypes from 'prop-types';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function GoToOldVersionButton({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      icon={faArrowRight}
      variant="light"
      showLabel={false}
    />
  );
}

GoToOldVersionButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

GoToOldVersionButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Перейти на стару версію',
};

export default GoToOldVersionButton;
