/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'budgetrequestnormativeactrow_set', readOnlyFields, errors, fields,
  });

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    budgetrequestnormativeactrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    budgetrequestnormativeactrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    budgetrequestnormativeactrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    budgetrequestnormativeactrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
