import base from './base';

/**
 * @const
 * @type {object}
 * BudgetAmplification
 */

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  year: { label: 'Рік планування', name: 'ГодЗатрат', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  kvkClassifier: { label: 'КВК', name: 'КВК', visible: false },
  templateBaseDoc: { label: 'Рішення сесії', name: 'РешениеСессии', visible: false },

};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      projectPublicBudget: { label: 'Проект', name: 'Проект' },
      id: { label: 'ИД', name: 'ИД' },
      sum: { label: 'Сума', name: 'Сумма' },
      noteDanger: { label: 'Примітки / ризики / застерження', name: 'Примечания_Угрозы' },
      publicBudgetProjectAuthor: { label: 'Автор', name: 'АвторПроекта' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Документ "Проект Громадського бюджет"',
  name: 'publicBudget',
  backendName: 'ГромадськийБюджет',
  columns,
  frontend: 'doc/publicBudget',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'budget',
      'FI',
      'note',
      'year',
      'CSU',
      'kvkClassifier',
      'templateBaseDoc',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
