import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const StyledImg = styled.img`
  width: 100%;
`;

function ImgViewer({ href, filename }) {
  return (
    <div className="p-5 mb-4 bg-light rounded-3">
      <Row>
        <Col>
          <Button as="a" download={filename} href={href}>
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Завантажити
          </Button>
          <StyledImg src={href} alt="Перегляд файлу" />
        </Col>
      </Row>
    </div>
  );
}

ImgViewer.propTypes = {
  href: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default ImgViewer;
