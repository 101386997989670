import React from 'react';
import md from '../../../../../meta/doc/finSourcePlanChanges';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { DocTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_byAuthority/lister_selector';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(DocTableListerFactory());

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
