import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/localDebt';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'TABL10';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG1S" highlighted={highlights.includes('T10RXXXXG1S')}>
              <ItemPicker
                value={row[tableMD.columns.T10RXXXXG1S.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG1S.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG15" highlighted={highlights.includes('T10RXXXXG15')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG15.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG15.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG16" highlighted={highlights.includes('T10RXXXXG16')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG16.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG16.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG17" highlighted={highlights.includes('T10RXXXXG17')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG17.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG17.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG18" highlighted={highlights.includes('T10RXXXXG18')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG18.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG18.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG19" highlighted={highlights.includes('T10RXXXXG19')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG19.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG19.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T10RXXXXG20" highlighted={highlights.includes('T10RXXXXG20')}>
              <NumberInput
                value={row[tableMD.columns.T10RXXXXG20.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T10RXXXXG20.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T10RXXXXG1S.name]: referencePropType,
    [tableMD.columns.T10RXXXXG15.name]: PropTypes.number,
    [tableMD.columns.T10RXXXXG16.name]: PropTypes.number,
    [tableMD.columns.T10RXXXXG17.name]: PropTypes.number,
    [tableMD.columns.T10RXXXXG18.name]: PropTypes.number,
    [tableMD.columns.T10RXXXXG19.name]: PropTypes.number,
    [tableMD.columns.T10RXXXXG20.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
