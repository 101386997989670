const frontendURL = '/srv/ImportDataFromLogica/';
const backendURL = '/api/logica/load_documents/';
const name = 'Завантаження документів з сервісу Logica';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
