import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import checkAnimation from '../../../../assets/lottie/animation_lmylbmm0.json';

function SignatoryRow({
  signatory, step, onSign, onUnsign,
}) {
  return (
    <div className="d-flex align-items-center mb-4">
      <div className="bg-200 rounded-circle text-primary border-300 fs-1 icon-item fw-bold">
        {signatory.signed ? (
          <Lottie animationData={checkAnimation} loop={false} />
        ) : step }
      </div>
      <div className="mx-3">
        {signatory.signed ? (
          <Button variant="falcon-danger" onClick={() => onUnsign(signatory.id)}>
            Зняти підпис
          </Button>
        ) : (
          <Button variant="falcon-success" onClick={() => onSign(signatory.id)}>
            Підписати
          </Button>
        )}
      </div>
      <div>
        <h6 className="mb-0 text-700">
          {signatory.rank}
        </h6>
        <span className="fs--2 text-500 font-sans-serif">{signatory.repr}</span>
      </div>
    </div>
  );
}

SignatoryRow.propTypes = {
  signatory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
    rank: PropTypes.string,
    signed: PropTypes.bool,
  }).isRequired,
  step: PropTypes.number.isRequired,
  onSign: PropTypes.func.isRequired,
  onUnsign: PropTypes.func.isRequired,
};

export default SignatoryRow;
