import React from 'react';
import PropTypes from 'prop-types';
import Stamp from './Stamp';

function ReceivedStamp({ invoice }) {
  return (
    <Stamp mainText="Отримано" subText={invoice} variant="warning" />
  );
}

ReceivedStamp.propTypes = {
  invoice: PropTypes.string,
};

ReceivedStamp.defaultProps = {
  invoice: '',
};

export default ReceivedStamp;
