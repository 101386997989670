import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: '№ рахунку', name: 'Код', visible: true },
  owner: {
    label: 'Розпорядник',
    name: 'Владелец',
    visible: true,
  },
  ClassificatorKFK: {
    label: 'КФК',
    name: 'КлассификаторКФК',
    visible: false,
  },
  Fond: {
    label: 'Фонд',
    name: 'Фонд',
    visible: true,
  },
  Bank: {
    label: 'Код банку',
    name: 'Банк',
    visible: true,
  },
  AccountType: { label: 'Вид рахунку', name: 'ВидСчета', visible: true },
  AccountType2: { label: 'Вид рахунку', name: 'ВидСчетаЖ', visible: true },
  SortCode: { label: 'Код для сортування', name: 'Сорт', visible: true },
  OpenDate: {
    label: 'Дата відкриття',
    name: 'ДатаОткрытия',
    visible: false,
  },
  CloseDate: {
    label: 'Дата закриття',
    name: 'ДатаЗакрытия',
    visible: false,
  },
  Inactive: {
    label: 'Недіючий',
    name: 'флНеДействующий',
    visible: false,
  },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  Numerator: {
    label: 'Нумератор',
    name: 'Нумератор',
    visible: false,
  },
  ClassificatorKBP7: {
    label: 'КБП МБ (7 зн.)',
    name: 'КлассификаторКБП7',
    visible: false,
  },
  AdditionalAccount: {
    label: 'Додатковий рахунок',
    name: 'ДополнительныйСчет',
    visible: false,
  },
  BankName: { label: 'Назва Банку', name: 'НазваБанку', visible: false },
  kvk: { label: 'КВК', name: 'КВК', visible: true },
  codeKBP7: { label: 'Код КБП7', name: 'КодКБП7', visible: true },
  codeKFK: { label: 'Код КФК', name: 'КодКФК', visible: true },
  UsedForCommitments: {
    label: 'Використовується тільки для зобов\'язань',
    name: 'ИспользуетсяДляОбязательств',
    visible: false,
  },
  NumeratorVisible: { label: 'ВидимостьНумератора', name: 'ВидимостьНумератора', visible: false },
};

/**
 * @const
 */
export const bankAccounts = {
  label: 'Казначейськi рахунки',
  name: 'bankAccounts',
  backendName: 'БанковскиеСчета',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  frontend: 'cat/bankAccounts',

  // Remove duplicates
  listColumns: [...new Set([
    ...reqListerColumns,
    'kvk',
    'owner',
    'codeKBP7',
    'Fond',
    'AccountType2',
    'CloseDate',
    'SortCode',
    'Note',
    'Bank',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  // foldersOnTop: true,
};

export default bankAccounts;
