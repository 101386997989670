import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { targetedPrograms as md } from '../../../../constants/meta/catalogs/targetedPrograms';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { selectionMode } from '../../../../constants/meta/common';

function TargetedPrograms({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col sm={3}>
          <EditorControls.DateInput
            label={md.columns.fDate.label}
            value={data[md.columns.fDate.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fDate.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col sm={3}>
          <EditorControls.StringInput
            label={md.columns.tCode.label}
            value={data[md.columns.tCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.tCode.name]: value,
            })}
            readOnly={readOnly}
            maxLength={9}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.ApprovalDocument.label}
            value={data[md.columns.ApprovalDocument.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ApprovalDocument.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.ActivitiesList.label}
            value={data[md.columns.ActivitiesList.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ActivitiesList.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.BuildObject.label}
            value={data[md.columns.BuildObject.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BuildObject.name]: value,
            })}
            modelType="cat"
            modelName="objBuildingTypeDoc"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ThisIsGovProgram-${data.id}`}
            label={md.columns.ThisIsGovProgram.label}
            value={data[md.columns.ThisIsGovProgram.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ThisIsGovProgram.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`CapitalExpendituresBI-${data.id}`}
            label={md.columns.CapitalExpendituresBI.label}
            value={data[md.columns.CapitalExpendituresBI.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CapitalExpendituresBI.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <h6 className="font-weight-bold text-primary">Загальна вартісь програми</h6>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TotalCostZF.label}
            value={data[md.columns.TotalCostZF.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TotalCostZF.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="targetedPrograms"
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
    </Container>
  );
}

TargetedPrograms.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TargetedPrograms;
