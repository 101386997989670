const frontendURL = '/doc/loanproject/';
const backendURL = '/api/planproject/loanprojectheader/';
const name = 'Перелік кредитів (позик) до спец.фонду для інвестиційних проектів (Дод. 8 до РС)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
