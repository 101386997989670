import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dataPropTypes } from './propTypes';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import { fieldsPropTypes } from '../directionOfUse/propTypes';

function OwnEntrySFRows({
  onChange, data, fields, ...rest
}) {
  const totals = Object.keys(fields.executionbpownentrysfrow_set.child.children).filter((k) => k.includes('amount'));

  const onChangeTP = useCallback((f) => {
    const sumChanger = (oldData) => oldData.executionbpownentrysfrow_set.map((row) => ({
      ...row,
      amount_plan: (row.amount_plan || 0),
      amount_fact: (row.amount_fact || 0),
      amount_deviation: (row.amount_plan || 0) - (row.amount_fact || 0),
    }));
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
        executionbpownentrysfrow_set: sumChanger(d),
      };
    });
  }, [onChange]);

  const template = useMemo(() => {
    const tabColProps = {
      xxl: 3,
      xl: 3,
      lg: 6,
      sm: 12,
    };
    return {
      columns: [
        {
          colProps: tabColProps,
          field: 'types_of_entry_sf',
          controlProps: {
            typeOfChoice: typesOfChoices.onlyElements,
            filter: {
              budget: data.budget ? data.budget.id : null,
            },
          },
        },
        {
          colProps: tabColProps,
          field: 'amount_plan',
        },
        {
          colProps: tabColProps,
          field: 'amount_fact',
        },
        {
          colProps: tabColProps,
          field: 'amount_deviation',
        },
      ],
    };
  }, [data.budget]);

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbpownentrysfrow_set"
      onChange={onChangeTP}
      fields={fields}
      totals={totals}
    />
  );
}

OwnEntrySFRows.propTypes = {
  data: PropTypes.shape({
    executionbpownentrysfrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    budget: foreignPropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    executionbpownentrysfrow_set: fieldsPropTypes,
  }),
};

OwnEntrySFRows.defaultProps = {
  fields: null,
};

export default OwnEntrySFRows;
