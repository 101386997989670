import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Перiод', name: 'Период' },
};

const definition = {
  label: 'Казначейський звіт по фінансовим та юридичним зобов\'язанням',
  name: 'TreasureFilesObligationsAnalysis',
  columns,
  frontend: 'rep/TreasureFilesObligationsAnalysis',
  backendName: 'КазначейскиеФайлыПоОбязательствам',
  resultColumns: baseResultColumns,
};

export default definition;
