import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { moneysStingOptions } from '../../../../../../constants/common';

const tablename = 'KFBForecast';

const tableMD = md.tables[tablename];

function TPRow({
  row,
  active,
  rowIndex,
  onChange,
  activeCol,
  readOnly,
  highlights,
  FI,
  onSR,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const FIOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: FI,
    }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
          <ItemPicker
            value={row[tableMD.columns.elementKFB.name]}
            modelType="cat"
            modelName="elementKFB"
            filter={FIOwnerFilter}
            onChange={async (e, v) => {
              await onRowChange(
                e,
                { [tableMD.columns.elementKFB.name]: v },
              );
              await onSR('CHANGE_KFB_IN_KFB', { rowId: rowIndex });
            }}
            readOnly={readOnly}
            prepend={(
              <InputGroup.Text>
                {row[tableMD.columns.codeKFB.name]}
              </InputGroup.Text>
            )}
          />
        </TableRowCell>
      </Col>

      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum_2" highlighted={highlights.includes('sum_2')}>
              {(row[tableMD.columns.sum_2.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf_2" highlighted={highlights.includes('zf_2')}>
              <NumberInput
                value={row[tableMD.columns.zf_2.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf_2.name]: value,
                  [tableMD.columns.sum_2.name]: value
                  + (row[tableMD.columns.sf_2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
              <NumberInput
                value={row[tableMD.columns.sf_2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf_2.name]: value,
                  [tableMD.columns.sum_2.name]: value
                  + (row[tableMD.columns.zf_2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="br_2" highlighted={highlights.includes('br_2')}>
              <NumberInput
                value={row[tableMD.columns.br_2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.br_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum_1" highlighted={highlights.includes('sum_1')}>
              {(row[tableMD.columns.sum_1.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf_1" highlighted={highlights.includes('zf_1')}>
              <NumberInput
                value={row[tableMD.columns.zf_1.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf_1.name]: value,
                  [tableMD.columns.sum_1.name]: value
                  + (row[tableMD.columns.sf_1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
              <NumberInput
                value={row[tableMD.columns.sf_1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf_1.name]: value,
                  [tableMD.columns.sum_1.name]: value
                  + (row[tableMD.columns.zf_1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="br_1" highlighted={highlights.includes('br_1')}>
              <NumberInput
                value={row[tableMD.columns.br_1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.br_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>

      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
              {(row[tableMD.columns.sum0.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf0.name]: value,
                  [tableMD.columns.sum0.name]: value
                  + (row[tableMD.columns.sf0.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf0.name]: value,
                  [tableMD.columns.sum0.name]: value
                  + (row[tableMD.columns.zf0.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="br0" highlighted={highlights.includes('br0')}>
              <NumberInput
                value={row[tableMD.columns.br0.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.br0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              {(row[tableMD.columns.sum1.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf1.name]: value,
                  [tableMD.columns.sum1.name]: value
                  + (row[tableMD.columns.sf1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf1.name]: value,
                  [tableMD.columns.sum1.name]: value
                  + (row[tableMD.columns.zf1.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="br1" highlighted={highlights.includes('br1')}>
              <NumberInput
                value={row[tableMD.columns.br1.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.br1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12} className="text-center px-2 text-muted">
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              {(row[tableMD.columns.sum2.name] || 0).toLocaleString('uk', moneysStingOptions)}
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                inputClassName="text-primary"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.zf2.name]: value,
                  [tableMD.columns.sum2.name]: value
                  + (row[tableMD.columns.sf2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sf2.name]: value,
                  [tableMD.columns.sum2.name]: value
                  + (row[tableMD.columns.zf2.name] || 0),
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="br2" highlighted={highlights.includes('br2')}>
              <NumberInput
                value={row[tableMD.columns.br2.name]}
                inputClassName="text-success"
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.br2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>

      {/* <Col> */}
      {/*  <Row noGutters> */}
      {/*    <Col sm={12} className="text-center px-2 text-muted"> */}
      {/*      <TableRowCell column="sum3" highlighted={highlights.includes('sum3')}> */}
      {/*        {(row[tableMD.columns.sum3.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
      {/*      </TableRowCell> */}
      {/*    </Col> */}
      {/*    <Col sm={12}> */}
      {/*      <TableRowCell column="zf3" highlighted={highlights.includes('zf3')}> */}
      {/*        <NumberInput */}
      {/*          value={row[tableMD.columns.zf3.name]} */}
      {/*          inputClassName="text-primary" */}
      {/*          precision={2} */}
      {/*          onChange={(e, value) => onRowChange(e, { */}
      {/*            [tableMD.columns.zf3.name]: value, */}
      {/*            [tableMD.columns.sum3.name]: value */}
      {/*            + (row[tableMD.columns.sf3.name] || 0), */}
      {/*          })} */}
      {/*          readOnly={readOnly} */}
      {/*        /> */}
      {/*      </TableRowCell> */}
      {/*    </Col> */}
      {/*    <Col sm={12}> */}
      {/*      <TableRowCell column="sf3" highlighted={highlights.includes('sf3')}> */}
      {/*        <NumberInput */}
      {/*          value={row[tableMD.columns.sf3.name]} */}
      {/*          inputClassName="text-success" */}
      {/*          precision={2} */}
      {/*          onChange={(e, value) => onRowChange(e, { */}
      {/*            [tableMD.columns.sf3.name]: value, */}
      {/*            [tableMD.columns.sum3.name]: value */}
      {/*            + (row[tableMD.columns.zf3.name] || 0), */}
      {/*          })} */}
      {/*          readOnly={readOnly} */}
      {/*        /> */}
      {/*      </TableRowCell> */}
      {/*    </Col> */}
      {/*    <Col sm={12}> */}
      {/*      <TableRowCell column="br3" highlighted={highlights.includes('br3')}> */}
      {/*        <NumberInput */}
      {/*          value={row[tableMD.columns.br3.name]} */}
      {/*          precision={2} */}
      {/*          inputClassName="text-success" */}
      {/*          onChange={(e, value) => onRowChange(e, { */}
      {/*            [tableMD.columns.br3.name]: value, */}
      {/*          })} */}
      {/*          readOnly={readOnly} */}
      {/*        /> */}
      {/*      </TableRowCell> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </Col> */}
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};
TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
