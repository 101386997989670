/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Cattablerow } from '../../../newLister/tableComponent/table/row';
import meta from '../../../../constants/meta/catalogs/street';
import toponimTypes from '../../../../constants/meta/enums/toponims';

function StreetsRow({ row, ...rest }) {
  const toponimKey = row[meta.columns.toponymStreet.name];
  const toponim = Object.values(toponimTypes).filter(
    (v) => (v.name === toponimKey),
  );
  const rowWithToponim = row;
  rowWithToponim[meta.columns.toponymStreet.name] = toponim.length && toponim[0].label;
  return (
    <Cattablerow row={rowWithToponim} {...rest} />
  );
}

StreetsRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
};

export default memo(StreetsRow);
