import React from 'react';
import useComponentsInit from './hooks/useGeneralTP';
import BaseTP from '../../../../components/tableParts/baseTablePartForHooks';

function GeneralTP() {
  const {
    tableName,
    arrBypassGrid,
    componentsType,
    gridTemplateParam,
    headerCol,
    footerCol,
    data,
    ComposeSettings,
  } = useComponentsInit();
  const tableComponents = {
    isHeaderComponent: true,
    isRowComponent: true,
    isFooterComponent: true,
  };

  return (
    <BaseTP
      arrBypassGrid={arrBypassGrid}
      componentsType={componentsType}
      data={data}
      gridTemplateParam={gridTemplateParam}
      headerCol={headerCol}
      footerCol={footerCol}
      tableName={tableName}
      tableComponents={tableComponents}
      reFreshForm={ComposeSettings}
    />
  );
}

export default GeneralTP;

// import React, { PureComponent } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { List, Map } from 'immutable';
// import styled from 'styled-components';
// import getBaseTP from '../../../../components/tableParts/index';
// import Fields from '../../../field';
// import md from '../../../../constants/meta/index';
// import { changeField } from '../../../../actions/dpEditor';
// import {
//   DivHeader,
//   DivCell,
//   InputCodeStyled,
//   BasedGrid,
// } from '../../../../components/styledTable/styledTable';
// import storePathParam from '../../../../tablePart/storePathParam';
//
// const TABLENAME = 'data';
//
// const coldef = md.dp.assigmentPrinting.tables.data.columns;
//
// const GridDiv = styled(BasedGrid)`
//   grid-template-areas:  "Document Notes";
//   grid-template-columns: 50% 50%;
//   grid-auto-columns: 1fr;
// `;
//
// const HeaderComponent = () => (
//   <GridDiv>
//     <DivHeader areaName="Document">{coldef.Document.label}</DivHeader>
//     <DivHeader areaName="Notes">
//       {coldef.Notes.label}
//     </DivHeader>
//   </GridDiv>
// );
//
// class Row extends PureComponent {
//   static propTypes = {
//     row: PropTypes.instanceOf(Map).isRequired,
//     rowId: PropTypes.number.isRequired,
//     dispatch: PropTypes.func.isRequired,
//   };
//
//   render() {
//     const {
//       row, rowId, dispatch,
//     } = this.props;
//     return (
//       <GridDiv>
//         <DivCell areaName="Document">
//           <Fields.SelectorField
//             noBorder
//             noBackground
//             modelName="assignmentPlanning"
//             modelType="doc"
//             value={row.get('Document', new Map())}
//             onChange={(e, v) => {
//               dispatch(changeField([`tables/${TABLENAME}`, rowId, 'Document'], v));
//             }}
//             noHierarchy
//           />
//         </DivCell>
//         <DivCell areaName="Notes">
//           <InputCodeStyled
//             value={row.get('Notes', '')}
//             readOnly
//           />
//         </DivCell>
//       </GridDiv>
//     );
//   }
// }
//
// const mapState = (state) => ({
//   data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
//   actionsHistory: state.getIn(
//     [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
//     new List(),
//   ),
// });
//
// const TableData = getBaseTP(
//   TABLENAME,
//   Row,
//   HeaderComponent,
// );
//
// export default connect(mapState)(TableData);
