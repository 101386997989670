import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DocSelectionModal from './docSelectionModal';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import enums from '../../../../constants/meta/enums';
import md from '../../../../constants/meta/documents/assignmentPlanning';
import { ContainerSt } from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { StampApprovedJs } from '../../../../assets/icons';
import FillFromUpLevelDoc from './fillFromUpLevelDoc';
import WinManagerContext from "../../../../minfin/providers/WinManagerContext/context";
import {emptyUid} from "../../../../constants/meta/common";

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function AssignmentPlanning({ data, actions, permissions }) {
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того, чтобы все хозяйство не перерендеривалось
  const FI = data[md.columns.FI.name];
  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;

  const onOk = useCallback((loadData) => {
    actions.onSR('LOAD_FROM_DOC_SELECTION_UPLEV', loadData);
  }, [actions]);

  const csuFilter = useMemo(() => [{ fieldName: '_Родитель', value: FI }], [FI]);




        const docTotalCost = data[md.columns.docTotalCost.name]?.toLocaleString('uk');
        const invoice =data[md.columns.invoice.name];
        const fond=  data[md.columns.fondObject.name]?.repr;
        const year = data[md.columns.year.name];
        const author = data[md.columns.author.name]?.repr
        const note = data[md.columns.note.name]





    const { setWindowsHint } = useContext(WinManagerContext);
  useEffect(
    () => {
      const header = (
        <p>
          <strong> Сума: </strong>
          {docTotalCost}
          <br />
          {invoice && (
            <>
              <strong> Статус: </strong>
              {invoice}
            </>
          )}
        </p>
      );

      const body = (
        <p>
          <strong>Фонд: </strong>
          {fond}
          <br />
          <strong>Бюджетний рік: </strong>
          {year}
          <br />
          <strong>Автор: </strong>
          {author}
          <br />
          {note ? `Коментар: ${note}` : ''}
        </p>
      );

      setWindowsHint(`/${md.frontend}/${data.id}/`, { header, body });
    },
    [author, data.id, docTotalCost, fond, invoice, note, setWindowsHint, year],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planingMode.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.label}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
                [md.columns.includeBudgetAmplification.name]: false,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        {data[md.columns.includeCSUIntoHead.name] && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.CSU.label}
              value={data[md.columns.CSU.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.CSU.name]: value,
                });
              }}
              modelType="cat"
              modelName="csu"
              filter={csuFilter}
              noHierarchy
              readOnly={readOnly}
              canErase
            />
          </Col>
        )}
        <Col>
          {data[md.columns.thisIsSecialFond.name] && (
            <>
              <EditorControls.CheckboxInput
                controlId={`onlyYear-${data.id}`}
                label={md.columns.onlyYear.label}
                value={data[md.columns.onlyYear.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.onlyYear.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                controlId={`includeBudgetAmplification-${data.id}`}
                label={md.columns.includeBudgetAmplification.label}
                value={data[md.columns.includeBudgetAmplification.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.includeBudgetAmplification.name]: value,
                })}
                readOnly={readOnly}
              />
            </>
          )}
          <EditorControls.CheckboxInput
            controlId={`Loans-${data.id}`}
            label={md.columns.Loans.label}
            value={data[md.columns.Loans.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Loans.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            CSU={data[md.columns.CSU.name]}
            FI={data[md.columns.FI.name]}
            fondObject={data[md.columns.fondObject.name]}
            planingMode={data[md.columns.planingMode.name]}
            readOnly={readOnly || data[md.columns.fondObject.name]?.id === emptyUid}
            llabel={data[md.columns.fondObject.name]?.id === emptyUid ? "Заповнити з даних розпорядників нижчого рівня. Увага! Необхідно обрати фонд."
                :
                "Заповнити з даних розпорядників нижчого рівня"}
          />
        </Col>
        <Col>
          <FillFromUpLevelDoc
            planingMode={data[md.columns.planingMode.name]}
            CSU={data[md.columns.CSU.name]}
            FI={data[md.columns.FI.name]}
            fondObject={data[md.columns.fondObject.name]}
            year={data[md.columns.year.name]}
            readOnly={!permissions.canChange}
            onOk={onOk}
          />
        </Col>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
            {data[md.columns.isApproved.name] && sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.received.label}
                value={data[md.columns.received.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.received.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.received.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
    </EditorCollapse>

  );
}

AssignmentPlanning.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.planingMode.name]: PropTypes.oneOf([
      enums.PlaningKind.temporary.name, enums.PlaningKind.permanent.name,
    ]),
    [md.columns.fondObject.name]: referencePropType,
    [md.columns.includeCSUIntoHead.name]: PropTypes.bool,
    [md.columns.CSU.name]: referencePropType,
    [md.columns.thisIsSecialFond.name]: PropTypes.bool,
    [md.columns.onlyYear.name]: PropTypes.bool,
    [md.columns.includeBudgetAmplification.name]: PropTypes.bool,
    [md.columns.Loans.name]: PropTypes.bool,
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
    [md.columns.received.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AssignmentPlanning;
