import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import { DivFooter } from '../../../../../components/styledTable/styledTable';

function FooterComponent({
  rows, CSUinH,
}) {
  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  const totals = rows.reduce((v, row) => v + row.get('Sum', 0), 0);
  return (
    <GridTemplate CSUinH={CSUinH} isHeader>
      <DivFooter areaName="1/1/1/11">Разом</DivFooter>
      <DivFooter areaName="1/11">{formatter(totals.toFixed(2))}</DivFooter>
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,
  CSUinH: PropTypes.bool,
};

FooterComponent.defaultProps = {
  CSUinH: false,
};

export default FooterComponent;
