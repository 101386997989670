import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Badge,
  Button, ButtonGroup,
  Card, Col, Modal, Nav, Navbar, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faComment, faFilter, faUpload, faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import md from '../../../../constants/meta/dataprocessors/requestsLoading';
import { moneysStingOptions } from '../../../../constants/common';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import Content from './content';
import TableEditor from '../../../tableEditor/tableEditor';

const tableDocs = md.tables.docsTable;
const tableDocumentNotes = md.tables.documentNotes;
const { result } = md.resultColumns;

function Documents({
  data,
  content,
  onChange,
  onSR,
  onOk,
}) {
  const [modalOpened, setModalOpened] = useState(false);

  const printShortKEKV = useCallback((e, value) => {
    onChange({ [md.resultColumns.result]: value });
    onSR('PRINT');
    setModalOpened(true);
  }, [onChange, onSR]);

  const onToggleCheck = useCallback(
    (e, rowId, checked) => onChange((o) => ({
      ...o,
      [tableDocs.name]: [
        ...o[tableDocs.name].slice(0, rowId),
        {
          ...o[tableDocs.name][rowId],
          [tableDocs.columns.Check.name]: checked,
        },
        ...o[tableDocs.name].slice(rowId + 1),
      ],

    })),
    [onChange],
  );

  const onCheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [tableDocs.name]: o[tableDocs.name]
        .map((row) => ({
          ...row,
          [tableDocs.columns.Check.name]: true
        })),
    })),
    [onChange],
  );
  const onUncheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [tableDocs.name]: o[tableDocs.name]
        .map((row) => ({
          ...row,
          [tableDocs.columns.Check.name]: false
        })),
    })),
    [onChange],
  );
  const onToggleAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [tableDocs.name]: o[tableDocs.name]
        .map((row) => ({
          ...row,
          [tableDocs.columns.Check.name]: !row[tableDocs.columns.Check.name],
        })),
    })),
    [onChange],
  );

  const checkedDocs = data.filter((d) => d[tableDocs.columns.Check.name]);
  const checkedDocCount = checkedDocs.length;
  const totSum = checkedDocs.reduce((R, d) => R + d[tableDocs.columns.Sum.name], 0);

  return (
    <>
      <Navbar variant="light" expand="sm" className="border rounded bg-light" sticky="top">
        <Nav className="w-100">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => onSR('FILL_TABLE')}>
              <FontAwesomeIcon icon={faFilter} className="me-2"/>
              Відібрати
            </Button>
            <Button variant="outline-success" onClick={() => onOk()} disabled={!checkedDocCount}>
              <FontAwesomeIcon icon={faUpload} className="me-2"/>
              Завантажити
            </Button>
            <Button
              variant="outline-info"
              onClick={printShortKEKV}
            >
              <FontAwesomeIcon icon={faUpload} className="me-2"/>
              Розрахувати суму за КЕКВ у обраних док.
            </Button>
          </ButtonGroup>

          <ButtonGroup className="ms-2">
            <DefaultCPButton onClick={onCheckAll} label="Відмітити все" variant="outline-primary"
                             disabled={!data.length}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10"/>
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 "/>
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10"/>
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onUncheckAll} label="Зняти всі відмитки"
                             variant="outline-secondary" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 up-10"/>
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 "/>
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 down-10"/>
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onToggleAll} label="Змінити все" variant="outline-success"
                             disabled={!data.length}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10"/>
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 "/>
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10"/>
              </span>
            </DefaultCPButton>
          </ButtonGroup>
          <div className="ms-auto d-flex align-items-center">
            Відімічено
            <Badge className="mx-2" variant="dark">{checkedDocCount}</Badge>
            документів, на суму
            <Badge className="mx-2"
                   variant="dark">{totSum.toLocaleString('uk', moneysStingOptions)}</Badge>
            грн
          </div>

          {/* Не працює */}
          <Modal
            show={modalOpened}
            scrollable
            size="xl"
            onHide={() => setModalOpened(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Сума за КЕКВ у обраних документах
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TableEditor mountNode={[result]}/>
            </Modal.Body>
          </Modal>
        </Nav>
      </Navbar>
      {/* <SCardColumns showсontext={showContext.toString()}> */}
      <>
        {data.map((doc, rowId) => (
          <Card
            key={doc[tableDocs.columns.Document.name].id}
            border={doc[tableDocs.columns.Check.name] ? 'primary' : 'light'}
          >
            <Accordion>
              <Accordion.Item eventKey={doc[tableDocs.columns.Document.name].id}>
                <Accordion.Header className="d-flex justify-content-between">
                  <Card.Header className="d-flex w-100 p-0 m-0 align-items-center">
                    <Card.Title className="flex-grow-1 d-flex justify-content-between me-2 align-items-center">
                      <OverlayTrigger
                        key={doc[tableDocs.columns.Document.name].repr}
                        placement="bottom"
                        overlay={(
                          <Tooltip id={`tooltip-${doc[tableDocs.columns.Document.name].repr}`}>
                            <strong>
                              {new Set(content
                                .filter((cRow) => doc[tableDocs.columns.Document.name].id
                                  === cRow[tableDocumentNotes.columns.Document.name].id)
                                .map((d) => `${d[tableDocumentNotes.columns.KPKVCode.name]}   `))}
                            </strong>
                          </Tooltip>
                        )}
                      >
                        <h4
                          className="text-warning  mb-0">{doc[tableDocs.columns.Document.name].repr}</h4>
                      </OverlayTrigger>
                      <h4 className="text-success me-2 mb-0">
                        {doc[tableDocs.columns.Sum.name].toLocaleString('uk', moneysStingOptions)}
                      </h4>
                      <strong>
                        {new Set(content
                          .filter((cRow) => doc[tableDocs.columns.Document.name].id
                            === cRow[tableDocumentNotes.columns.Document.name].id)
                          .map((d) => `${d[tableDocumentNotes.columns.KPKVCode.name]} `))}
                      </strong>

                    </Card.Title>
                    <EditorControls.CheckboxInput
                      // label="Завантажувати"
                      controlId={`check-for-document-${doc[tableDocs.columns.Document.name].id}`}
                      onChange={(e, v) => onToggleCheck(e, rowId, v)}
                      value={doc[tableDocs.columns.Check.name]}
                    />
                  </Card.Header>
                </Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Content
                      data={content
                        .filter((cRow) => doc[tableDocs.columns.Document.name].id
                          === cRow[tableDocumentNotes.columns.Document.name].id)}
                    />
                    {doc[tableDocs.columns.Notes.name] && (
                      <Card.Text>
                        <hr/>
                        <FontAwesomeIcon icon={faComment} className="me-2"/>
                        {doc[tableDocs.columns.Notes.name]}
                        <hr/>
                      </Card.Text>
                    )}
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Card.Footer className="text-muted">
              <Row noGutters sm={2}>
                <Col className="text-end">
                  <Card.Text>
                    <FontAwesomeIcon icon={faUser} className="me-2"/>
                    {doc[tableDocs.columns.Autor.name].repr}
                  </Card.Text>

                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ))}
        {/* </SCardColumns> */}
      </>
    </>
  );
}

Documents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  data: [],
  content: [],
};
export default Documents;
