import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fillTable, processServerReq, getFile } from '../../../../actions/dpEditor';
// import TPCommandPanel from '../../../../components/tableParts/tpDPCommandPanel';
import CommandPanelButton from '../../../../components/button/commandPanelButton';
import {
  CheckAllIcon, UnChekAllIcon, StarIcon, SaveIcon,
} from '../../../../assets/icons';

// const TABLE = 'export';

class CPExport extends PureComponent {
  static propTypes = {
    tableName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { tableName, disabled, dispatch } = this.props;
    return (
      <>
        <CommandPanelButton
          text="Відмітити всі"
          disabled={disabled}
          onClick={() => dispatch(fillTable(tableName, ['check'], true))}
        >
          <CheckAllIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text="Зняти відмітки"
          disabled={disabled}
          onClick={() => dispatch(fillTable(tableName, ['check'], false))}
        >
          <UnChekAllIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text="Заповинити"
          disabled={disabled}
          onClick={() => dispatch(processServerReq('FILL_EXPORT'))}
        >
          <StarIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text="Вивантажити"
          disabled={disabled}
          onClick={() => dispatch(getFile(() => ({ method: 'GET_EXPORT_FILE' })))}
        >
          <SaveIcon />
        </CommandPanelButton>
      </>
    );
    // return (
    //   <TPCommandPanel tableName="export" extraContent={addableButtons} />
    // );
  }
}

export default connect()(CPExport);
