import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  byHighLevel: { label: 'за розпорядниками нижчого рівня', name: 'флНижниеРасп' },
  FO: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період', name: 'Период' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  docKind: { label: 'Використовувати дані з паспортів БП', name: 'ВидДокумента' },
  year: { label: 'Рік', name: 'Год' },
  kfk: { label: 'КБПМБ', name: 'КФК' },
  taskInd: { label: 'Завдання', name: 'Задание' },
  periodOfIndicator: { label: 'Період показників бюджетних програм', name: 'ПериодПоказателей' },
  typeInd: { label: 'Тип показників', name: 'ТипПоказателя' },
  levelCSU: { label: 'Уровень', name: 'Уровень' },
  onlyLast: { label: 'Тільки останій документ', name: 'флТолькоПоследнийДок' },
  onlyAppr: { label: 'Тільки затверджені', name: 'флУтверждено' },
};

const definition = {
  label: 'Аналіз Паспортів бюджетних програм та Звітів з виконання бюджетних програм',
  name: 'PassportAnalisys',
  columns,
  frontend: 'rep/PassportAnalisys',
  backendName: 'АнализПоказателейПаспортОтчет',
  resultColumns: baseResultColumns,
};

export default definition;
