import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,

};

export const objBuildingTypePower = {
  label: 'Довідник ознак потужност для об\'єктів капітального будівництва',
  name: 'objBuildingTypePower',
  backendName: 'ОбъектыСтроительстваМощности',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/objBuildingTypePower',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default objBuildingTypePower;
