import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  target: foreignPropType,
  direction: foreignPropType,
  indicator: foreignPropType,
  goal_indicator: foreignPropType,
  amount_total: PropTypes.number,
  amount_zf0_appr: PropTypes.number,
  amount_zf0_prop: PropTypes.number,
  amount_zf0_dif: PropTypes.number,
  amount_sf0_appr: PropTypes.number,
  amount_sf0_prop: PropTypes.number,
  amount_sf0_dif: PropTypes.number,
  amount_zf1_appr: PropTypes.number,
  amount_zf1_prop: PropTypes.number,
  amount_zf1_dif: PropTypes.number,
  amount_sf1_appr: PropTypes.number,
  amount_sf1_prop: PropTypes.number,
  amount_sf1_dif: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
