import React, {
  Children, cloneElement, useMemo, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs,
} from 'react-bootstrap';
import { childrenPropType } from '../../../../../common/proptypes';
import { ACTIVE_FIELD, DATE_PARAMETER } from '../../../../../constants/backend';
import AppContext from '../../../../../providers/CiatAppContext';

const filterValues = {
  self: { label: 'Власні', value: 'self' },
  child: { label: 'Розпорядників нижчого рівня', value: 'child' },
  all: { label: 'Усі', value: 'all' },
};

function ClassifierListerSelector({
  filter, children, ...restProps
}) {
  const [viewMode, setViewMode] = useState(filterValues.self.value);
  const { currentUser } = useContext(AppContext);
  const authorityId = currentUser?.authority?.id;
  const authFilter = useMemo(
    () => {
      if (viewMode === filterValues.self.value) {
        return { authority: authorityId };
      }
      if (viewMode === filterValues.child.value) {
        return { authority_parent: authorityId };
      }
      return {};
    },
    [authorityId, viewMode],
  );

  const flt = useMemo(
    () => ({
      ...authFilter,
      ...filter,
    }),
    [authFilter, filter],
  );

  const newChild = cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
  });

  return (
    <>
      <Tabs activeKey={viewMode} onSelect={(v) => setViewMode(v)}>
        {Object.values(filterValues).map((vv) => (
          <Tab eventKey={vv.value} title={vv.label} key={vv.value} />
        ))}
      </Tabs>
      {newChild}
    </>
  );
}

ClassifierListerSelector.propTypes = {
  filter: PropTypes.shape({
    [ACTIVE_FIELD]: PropTypes.bool,
    [DATE_PARAMETER]: PropTypes.string,
  }),
  children: childrenPropType.isRequired,
};

ClassifierListerSelector.defaultProps = {
  filter: {},
};

export default ClassifierListerSelector;
