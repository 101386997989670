import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    size="sm"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant="link"
  >
    {children}
    &#x25bc;
  </Button>
));

CustomToggle.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CustomToggle.defaultProps = {
  onClick: PropTypes.func,
};

export default CustomToggle;
