import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  Tabl91TP,
} from './tabs';

function Taxes7Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabl91TP data={data} actions={actions} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );
}

Taxes7Tabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default Taxes7Tabs;
