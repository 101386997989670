import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  reportMonth: { label: 'Місяць за який подається звітність', name: 'МесяцОтчетности', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  form2022: { label: 'Форма 2022', name: 'форма2022', visible: false },
  add3: { label: 'Додаток 3', name: 'Дод3', visible: false },
  add4: { label: 'Додаток 4', name: 'Дод4', visible: false },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденоОбл', visible: true },
  regionInvoice: { label: 'Квитанція(область)', name: 'КвитанцияОбл', visible: false },
  forbidCreatingDate: { label: 'Заборонити створення документу "Вільні залишки" починаючи з', name: 'ОтключитьСозданиеСвободныхОстатковНачинаяС' },
};

/**
 * @const
 */
const tables = {
  form1: {
    name: 'Форма1',
    label: 'Додаток 3',
    columns: {
      budget: { label: 'Назва бюджету', name: 'Бюджет' },
      sum: { label: 'Кошти, отримані від перевиконання дохідної частини місцевих бюджетів на звітну дату', name: 'Сумма' },
      sumT: { label: 'у тому числі обсяг коштів, отриманих від перевиконання  дохідної частини загального фонду (стаття 78 Бюджетного кодексу України) *', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено кошти від перевиконання', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на ЗП (КЕКВ 2110+2120, 2282(2110+2120), 2610(2110+2120))', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270), 2610 (2270))', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220), 2610 (2220))', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230), 2610 (2230))', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumP: { label: 'на кошти, що передаються із загального фонду бюджету до бюджету розвитку (код 602400)', name: 'СуммаПередача' },
      prP: { label: 'питома вага, %', name: 'ПроцентПередача' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      prRasp: { label: 'Відсоток розподілених коштів на звітну дату', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілений обсяг коштів на звітну дату', name: 'СуммаНеРаспределено' },
    },
  },
  form2: {
    name: 'Форма2',
    label: 'Додаток 4',
    columns: {
      budget: { label: 'Назва  бюджету', name: 'Бюджет' },
      sum: { label: 'Вільні залишки коштів станом на початок року (з урахуванням залишків субвенцій з державного та місцевих бюджетів)', name: 'Сумма' },
      sumT: { label: 'у тому числі: залишки коштів за субвенціями з державного та місцевих бюджетів', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено вільні залишки коштів станом на поточну звітну дату (без урахування залишків субвенцій):', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на ЗП (КЕКВ 2110+2120, 2282(2110+2120), 2610(2110+2120))', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270), 2610 (2270))', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220), 2610 (2220))', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230), 2610 (2230))', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumP: { label: 'на кошти, що передаються із загального фонду бюджету до бюджету розвитку (код 602400)', name: 'СуммаПередача' },
      prP: { label: 'питома вага, %', name: 'ПроцентПередача' },
      sumOsvSubv: { label: 'на освітню субвенцію (КЕКВ 2620, 3220)', name: 'СуммаОсвСуб' },
      prOsvSubv: { label: 'питома вага, %', name: 'ПроцентОсвСуб' },
      sumMedSubv: { label: 'на медичну субвенцію (КЕКВ 2620, 3220)', name: 'СуммаМедСуб' },
      prMedSubv: { label: 'питома вага, %', name: 'ПроцентМедСуб' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      prRasp: { label: 'Відсоток розподілених коштів, без урахування розподілених залишків коштів субвенцій', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілені вільні залишки станом на поточну звітну дату', name: 'СуммаНеРаспределено' },
      prRaspAll: { label: 'Відсоток розподілених коштів всього', name: 'ПроцентРаспределеноВсего' },
      sumRaspSubv: {
        label: 'Розподілено вільні залишки коштів за субвенціями з державного та місцевих бюджетів',
        name: 'СуммаРаспределеноСубв',
      },
    },
  },
  form3: {
    name: 'Форма3',
    label: 'Додаток 5',
    columns: {
      budget: { label: 'Назва адмін-тер од', name: 'Бюджет' },
      sum: { label: 'Вільні залишки коштів станом на початок року', name: 'Сумма' },
      sumT: { label: 'з них: залишки коштів за субвенцією з держ. бюджету місцевим бюджетам на здійснення заходів щодо соц-економ розвитку...', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено вільні залишки коштів станом на поточну дату', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на ЗП (КЕКВ 2110+2120, 2282(2110+2120), 2610(2110+2120))', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270), 2610 (2270))', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220), 2610 (2220))', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230), 2610 (2230))', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumP: { label: 'на кошти, що передаються із загального фонду бюджету до бюджету розвитку (код 602400)', name: 'СуммаПередача' },
      prP: { label: 'питома вага, %', name: 'ПроцентПередача' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      sumOsvSubv: { label: 'на освітню субвенцію (КЕКВ 2620, 3220)', name: 'СуммаОсвСуб' },
      prOsvSubv: { label: 'питома вага, %', name: 'ПроцентОсвСуб' },
      sumMedSubv: { label: 'на медичну субвенцію (КЕКВ 2620, 3220)', name: 'СуммаМедСуб' },
      prMedSubv: { label: 'питома вага, %', name: 'ПроцентМедСуб' },
      prRasp: { label: 'Відсоток розподілених коштів на звітну дату', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілений обсяг коштів на звітну дату', name: 'СуммаНеРаспределено' },
      prRaspAll: { label: 'Відсоток розподілених коштів всього', name: 'ПроцентРаспределеноВсего' },
      sumRaspSubv: { label: 'Розподілено вільні залишки коштів за субв. з держ. бюджету місцевим бюджетам на здійснення заходів щодо...', name: 'СуммаРаспределеноСубв' },
    },
  },
  form4: {
    name: 'Форма4',
    label: 'Додаток 8',
    columns: {
      budget: { label: 'Назва адмін-тер од', name: 'Бюджет' },
      sum: { label: 'Вільні залишки коштів станом на початок року', name: 'Сумма' },
      sumT: { label: 'з них: залишки коштів за субвенцією з держ. бюджету місцевим бюджетам на здійснення заходів щодо соц-економ розвитку...', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено вільні залишки коштів станом на поточну дату', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на ЗП (КЕКВ 2110+2120, 2282(2110+2120), 2610(2110+2120))', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270), 2610 (2270))', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220), 2610 (2220))', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230), 2610 (2230))', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumP: { label: 'на кошти, що передаються із загального фонду бюджету до бюджету розвитку (код 602400)', name: 'СуммаПередача' },
      prP: { label: 'питома вага, %', name: 'ПроцентПередача' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      sumOsvSubv: { label: 'на освітню субвенцію (КЕКВ 2620, 3220)', name: 'СуммаОсвСуб' },
      prOsvSubv: { label: 'питома вага, %', name: 'ПроцентОсвСуб' },
      sumMedSubv: { label: 'на медичну субвенцію (КЕКВ 2620, 3220)', name: 'СуммаМедСуб' },
      prMedSubv: { label: 'питома вага, %', name: 'ПроцентМедСуб' },
      prRasp: { label: 'Відсоток розподілених коштів на звітну дату', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілений обсяг коштів на звітну дату', name: 'СуммаНеРаспределено' },
      prRaspAll: { label: 'Відсоток розподілених коштів всього', name: 'ПроцентРаспределеноВсего' },
      sumRaspSubv: { label: 'Розподілено вільні залишки коштів за субв. з держ. бюджету місцевим бюджетам на здійснення заходів щодо...', name: 'СуммаРаспределеноСубв' },
    },
  },
  form5: {
    name: 'Форма5',
    label: 'Додаток 9',
    columns: {
      budget: { label: 'Назва адмін-тер од', name: 'Бюджет' },
      sum: { label: 'Вільні залишки коштів станом на початок року', name: 'Сумма' },
      sumT: { label: 'з них: залишки коштів за субвенцією з держ. бюджету місцевим бюджетам на здійснення заходів щодо соц-економ розвитку...', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено вільні залишки коштів станом на поточну дату', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на ЗП (КЕКВ 2110+2120, 2282(2110+2120), 2610(2110+2120))', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270), 2610 (2270))', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220), 2610 (2220))', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230), 2610 (2230))', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumP: { label: 'на кошти, що передаються із загального фонду бюджету до бюджету розвитку (код 602400)', name: 'СуммаПередача' },
      prP: { label: 'питома вага, %', name: 'ПроцентПередача' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      sumOsvSubv: { label: 'на освітню субвенцію (КЕКВ 2620, 3220)', name: 'СуммаОсвСуб' },
      prOsvSubv: { label: 'питома вага, %', name: 'ПроцентОсвСуб' },
      sumMedSubv: { label: 'на медичну субвенцію (КЕКВ 2620, 3220)', name: 'СуммаМедСуб' },
      prMedSubv: { label: 'питома вага, %', name: 'ПроцентМедСуб' },
      prRasp: { label: 'Відсоток розподілених коштів на звітну дату', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілений обсяг коштів на звітну дату', name: 'СуммаНеРаспределено' },
      prRaspAll: { label: 'Відсоток розподілених коштів всього', name: 'ПроцентРаспределеноВсего' },
      sumRaspSubv: { label: 'Розподілено вільні залишки коштів за субв. з держ. бюджету місцевим бюджетам на здійснення заходів щодо...', name: 'СуммаРаспределеноСубв' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація щодо стану розподілу вільних залишків коштів місцевих бюджетів (стара версія)',
  name: 'freeRem',
  backendName: 'СвободныеОстатки',
  columns,
  frontend: 'doc/freeRem',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'author',
      'apprByRegion',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
