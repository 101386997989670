import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

const BACKENDURL = meta.cat.banks.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const mfoProps = editorHooks.useStringInputProps('mfo', data, fields, fieldErrors, actions.onChange);
  const codeUdkProps = editorHooks.useStringInputProps('code_udk', data, fields, fieldErrors, actions.onChange);
  const nameUdkProps = editorHooks.useStringInputProps('name_udk', data, fields, fieldErrors, actions.onChange);
  const codeVdkProps = editorHooks.useStringInputProps('code_vdk', data, fields, fieldErrors, actions.onChange);
  const nameVdkProps = editorHooks.useStringInputProps('name_vdk', data, fields, fieldErrors, actions.onChange);
  const treasureNameProps = editorHooks.useStringInputProps('treasure_name', data, fields, fieldErrors, actions.onChange);
  const codeTerrProps = editorHooks.useStringInputProps('code_terr', data, fields, fieldErrors, actions.onChange);
  const codeTerrFinProps = editorHooks.useStringInputProps('code_terr_fin', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.name}
    >
      <Container fluid className="pb-3">
        <Row>
          <Col>
            <EditorControls.StringInput {...mfoProps} inputMask="999999" />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...codeUdkProps} inputMask="99" />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameUdkProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...codeVdkProps} inputMask="9999" />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameVdkProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...treasureNameProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...codeTerrProps} inputMask="99" />
          </Col>
        </Row>
        <Row>
          <EditorControls.StringInput {...codeTerrFinProps} inputMask="9999" />
        </Row>
      </Container>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
