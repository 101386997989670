import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup, Form, ToggleButton,
} from 'react-bootstrap';
import { comparisonTypes } from '../../../../constants/meta/enums/DataComposition/comparisonTypes';

function AccTypeSelector({
  typeValues, typeValue, onSetType, onlyValid, onSetOnlyValid,
}) {
  return (
    <div className="border p-2 d-flex">
      <ButtonGroup toggle>
        {typeValues.map((t) => (
          <ToggleButton
            key={t.name}
            value={t.name}
            type="radio"
            checked={t.name === typeValue}
            onClick={() => onSetType(t.name)}
            variant="outline-info"
          >
            {t.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Form.Check
        className="ms-auto text-right"
        type="switch"
        id="onlyValidswitch"
        label="Тільки діючі"
        checked={onlyValid}
        onChange={() => onSetOnlyValid(!onlyValid)}
        isValid={onlyValid}
        isInvalid={!onlyValid}
        feedback={onlyValid ? 'Показувати актуальні рахунки' : 'Показувати всі рахунки'}
      />
    </div>
  );
}

AccTypeSelector.propTypes = {
  typeValues: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })).isRequired,
  typeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSetType: PropTypes.func.isRequired,
  onlyValid: PropTypes.bool.isRequired,
  onSetOnlyValid: PropTypes.func.isRequired,
};

export default AccTypeSelector;
