import React, { useEffect, useMemo, useRef } from 'react';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/summaryOffer';
import ReportHeader from '../_common/reportHeader';

const URL = instance.backendURL;

function Rep() {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL, repr: 'Зведена пропозиція на фінансування' });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const emptyValuesProps = editorHooks.useSelectorInputProps('empty_value', data, fields, fieldErrors, actions.onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const { onChange } = actions;
  useEffect(
    () => {
      onChange(() => ({ filters }));
    },
    [filters, onChange],
  );
  const initialDataLoaded = useRef(false);
  useEffect(
    () => {
      if (!initialDataLoaded.current && data.filters) {
        loadFilters(data.filters);
      }
    },
    [data.filters, filters.length, loadFilters],
  );
  initialDataLoaded.current = Object.keys(data).length > 0;
  return (
    <ReportHeader title={instance.name}>
      <PrintFormContainer
        loading={loading}
        err={err}
        actions={actions}
        ref={resultRef}
        pageLayout={pageLayout}
        quickSettings={(
          <Row>
            <Col md={3}>
              <EditorControls.DateInput {...bdateProps} />
            </Col>
            <Col md={3}>
              <EditorControls.DateInput {...edateProps} />
            </Col>
          </Row>
      )}
        settings={(
          <>
            <Row>
              <Col md={6}>
                <EditorControls.SelectorInput {...planKindProps} required />
                <EditorControls.SelectorInput {...emptyValuesProps} />
              </Col>
              <Col md={6}>
                <EditorControls.ItemPicker {...authorityProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DndProvider backend={HTML5Backend}>
                  <Tabs defaultActiveKey="filters">
                    <Tab eventKey="filters" title="Фільтрування">
                      <FiltersEditor
                        filters={displayFilters}
                        filtersHandlers={filtersHandlers}
                        availableFilters={availableFilters}
                      />
                    </Tab>
                  </Tabs>
                </DndProvider>
              </Col>
            </Row>
          </>
      )}
      />
    </ReportHeader>
  );
}

export default Rep;
