import {
  directions, defaultColumns,
} from '../common';

const measurements = {
  period: {
    label: 'Період', name: 'Период', visible: true, type: 'date',
  },
  element: { label: 'Деталізація КПКВ', name: 'Элемент', visible: true },
};

const resources = {
  classifier: { label: 'Класифікатор КПКВ', name: 'Классификатор', visible: true },
};

const props = {};

const columns = {
  ...defaultColumns,
  id: { label: 'id', name: '_id', visible: false },
  ...measurements,
  ...resources,
  ...props,
};

const defaultOrder = [
  {
    column: columns.period.name,
    direction: directions.ascending,
  },
];

export const infoRegElementTOKBP7Relation = {
  label: 'Відповідність елемента КБП7 класифікатору ',
  name: 'kbpElemRelation',
  columns,
  backendName: 'СоответствиеЭлементаКБП7Классификатору',
  defaultOrder,
  frontend: 'infoRegs/kbp7ElemRelation',
  listColumns: [
    ...new Set([
      'element',
      'classifier',
      'period',
    ]),
  ],
  useListLazyLoad: true,
};

export default infoRegElementTOKBP7Relation;
