import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AssigmPrintModal from '../lister/listerPrintModal';

const PrintSubMenu = ({ id }) => {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <AssigmPrintModal selectedRows={selectedRows} />
  );
};

PrintSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PrintSubMenu;
