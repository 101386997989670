import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar, Navbar } from 'react-bootstrap';
import {
  faLock, faLockOpen, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultCPButton from '../../../components/bootstrap_components/controls/defaultButton';

function DocsCommandPanel({
  children, tableActions, readOnly,
}) {
  return (
    <Navbar className="bg-white border rounded-top border-top-0">
      <ButtonToolbar>
        <ButtonGroup className="me-1">
          <DefaultCPButton
            onClick={() => tableActions.onApproveAllDocs()}
            disabled={readOnly}
            variant="outline-success"
            label='Встановити ознаку "Отримано" на всіх документах'
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faLock} transform="right-8" />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 up-6" />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 " />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 down-6" />
            </span>
          </DefaultCPButton>
          <DefaultCPButton
            onClick={() => tableActions.onUnapproveAllDocs()}
            disabled={readOnly}
            label='Зняти ознаку "Отримано" на всіх документах'
            variant="outline-danger"
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faLockOpen} transform="right-8" />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 up-6" />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 " />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 left-8 down-6" />
            </span>
          </DefaultCPButton>
        </ButtonGroup>
      </ButtonToolbar>
      {children}
    </Navbar>
  );
}

DocsCommandPanel.propTypes = {
  tableActions: PropTypes.shape({
    onApproveAllDocs: PropTypes.func.isRequired,
    onUnapproveAllDocs: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.symbol, PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

DocsCommandPanel.defaultProps = {
  readOnly: false,
  children: null,
};

export default DocsCommandPanel;
