import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioCheckIcon } from '../../../icons';
import {
  Input, Select, Option, Radio, TextArea,
} from '../elements';
import BGErrored from '../../../icons/required_bg.svg';

export const RadioStyled = styled(Radio)`
  display:none;
  +label:before{
    ${({ checked }) => (checked ? `background:url("${RadioCheckIcon}") 0.25em 0.25em no-repeat` : '')};
    content: "";
    display: inline-block;
    margin: -5px 4px 0 0;
    height: 16px;
    width: 16px;
    vertical-align: middle;
    border: 1px solid #BFBFBF;
    border-radius: 50%;
  }    
  +label:after{
   margin: 0 3px;
  }
`;

export const OptionStyled = styled(Option)`
  padding: 8px;
  font-family: Roboto,sans-serif;
  font-size: 12px;
  :checked{
    font-weight: 700;
  };
`;

export const SelectionStyled = styled(Select)`
  width: 100%;
  border: none;
  padding: 8px;
  outline: none;
  background-color: transparent;
  position: relative;
  font-family: Roboto,sans-serif;
  font-size: 12px;
`;

export const TextAreaStyled = styled(TextArea)`
  width: 100%;
  border: none;
  padding: 8px;
  outline: none;
  background-color: transparent;
  position: relative;
  font-family: Roboto,sans-serif;
  font-size: 12px;
  border-radius: .3rem;
  min-width: 1px;
`;

export const InputStyled = styled(Input)`
  width: 100%;
  border: none;
  padding: 8px;
  outline: none;
  background-color: transparent;
  position: relative;
  font-family: Roboto,sans-serif;
  font-size: 12px;
  border-radius: .3rem;
  min-width: 1px;
`;

export const DivStyled = styled.div`
  display: flex;
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid rgba(34,36,38,.15)')};
  border-radius: .3rem;
  background: ${({ noBackground }) => (noBackground ? 'none' : 'transparent')};
  position: relative;
  align-items: center;
  ${({ errored }) => (errored ? `background-image: url(${BGErrored}); background-position: 0% 90%; background-repeat: repeat-x` : '')};
  
`;
export const DivStyledUpload = styled(DivStyled)`
  position: relative; 
  overflow: hidden; 
  width: 110px; 
  height: 20px; 
  background: white;
  border: 1px solid #a2b4e8;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  color: #5e93d0;
  border-radius: 0.3rem;
  // padding: 8px 4px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
    input[type="file"]{
      opacity: 0;
      z-index: 1;
      padding: 0px 0px;
      font-size: 24px;
     }
    span{
    position: absolute;
    top: 8px;
    left: 7px;
    }
`;

export const DefaultInput = React.forwardRef(({
  noBackground, noBorder, errored, ...props
}, ref) => (
  <DivStyled
    noBorder={noBorder}
    noBackground={noBackground}
    errored={errored}
  >
    <InputStyled {...props} ref={ref} />
  </DivStyled>
));

DefaultInput.propTypes = {
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
  errored: PropTypes.bool,
};

DefaultInput.defaultProps = {
  noBackground: false,
  noBorder: false,
  errored: false,
};

export const DefaultTextArea = React.forwardRef(({
  noBackground, noBorder, errored, ...props
}, ref) => (
  <DivStyled
    noBorder={noBorder}
    noBackground={noBackground}
    errored={errored}
  >
    <TextAreaStyled {...props} ref={ref} />
  </DivStyled>
));

DefaultTextArea.propTypes = {
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
  errored: PropTypes.bool,
};

DefaultTextArea.defaultProps = {
  noBackground: false,
  noBorder: false,
  errored: false,
};
