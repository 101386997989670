import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  rozd: { label: 'Код', name: 'Розд', visible: true },
  prozd: { label: 'Код повний', name: 'Прозд', visible: true },
  json: { label: 'JSON', name: 'JSON', visible: true },
};

export const landClassifier = {
  label: 'Класифікатор видів цільового призначення земельних ділянок',
  name: 'landClassifier',
  backendName: 'КлассификаторЗемли',
  defaultOrder: [{
    column: columns.rozd.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/landClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'rozd',
      'prozd',
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default landClassifier;
