/* eslint-disable no-case-declarations */
import { List, Map, OrderedMap } from 'immutable';
import action from '../constants/actions/windowsManager';
import { urlfix } from '../api/funcs';

const defaultStore = new Map({
  currentComponent: null,
  currentPathName: null,
  currentMatch: null,
  hidenWindowsManager: true,
  windowsName: null,
  linkComponents: new OrderedMap(),
  // Здесь храняться даты последней актуальности {url: <int> }. При каком либо изменении
  // модели, необзодимо выстрелить action UPDATE_SIGNAL
  // и в этом месте значение для указаной модели обновится.
  // Это приведет к reload листера (или селектора)
  listerTimeStamps: new Map(),
  // Стек окон для правильного переключения следующего и предыдущего окна
  windowsStack: new List(),
});

const windowsManagerReducer = (state = defaultStore, { type, payload }) => {
  const stack = state.get('windowsStack');
  switch (type) {
    case action.ADD_COMPONENT_TO_WINDOWS_MANAGER:
      const insertedUrl = urlfix(payload.currentPathName);
      return state
        .set('currentComponent', payload.currentComponent)
        .set('currentPathName', insertedUrl)
        .set('params', payload.params)
        .set('hidenWindowsManager', false)
        .set('windowsName', payload.windowsName)
        .setIn(['linkComponents', insertedUrl], new Map({
          windowName: payload.windowsName,
          component: payload.currentComponent,
          params: payload.params,
          type: payload.type,
          name: payload.name,
        }))
        .set('windowsStack', stack.insert(0, insertedUrl))
        .set('type', payload.type)
        .set('name', payload.name);
    case action.HIDEN_WINDOWS_MANAGER:
      return state.set('hidenWindowsManager', true)
        .set('currentComponent', null);
    case action.DEL_COMPONENT_FROM_WINDOWS_MANAGER:
      const urlToRemove = urlfix(payload.componentDelPath);
      return state
        .set('windowsStack', stack.filter((u) => u !== urlToRemove))
        .deleteIn(['linkComponents', urlToRemove]);
    case action.SWITCH_WINDOW:
      const newUrl = urlfix(payload.currentPathName || '');
      const oldUrl = state.get('currentPathName');
      const current = state.getIn(['linkComponents', newUrl], new Map());
      return state
        .set('currentComponent', current.get('component'))
        .set('currentPathName', payload.currentPathName)
        .set('currentMatch', current.get('match', { id: '' }))
        .set('hidenWindowsManager', false)
        .set('windowsName', current.get('windowName', ''))
        .set('windowsStack', stack
          .filter((u) => ![newUrl, oldUrl].includes(u))
          .insert(0, oldUrl)
          .insert(0, newUrl))
        .set('type', current.get('type'))
        .set('name', current.get('name'));
    case action.UPDATE_SIGNAL:
      return state
        .setIn(['listerTimeStamps', urlfix(payload.url)], new Date().valueOf());
    case action.SET_WINDOW_TITLE:
      const titleUrl = urlfix(payload.url || '');
      if (state.getIn(['linkComponents', titleUrl])) {
        return state
          .setIn(['linkComponents', titleUrl, 'windowName'], payload.title);
      }
      return state;
    default:
      return state;
  }
};

export default windowsManagerReducer;
