import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.indicatorsForTarget;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'target'}
          highlighted={highlights.includes('target')}
          onToggleHighlght={() => onToggleHighlght('target')}
        >
          {meta.columns.target.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'indicator'}
          highlighted={highlights.includes('indicator')}
          onToggleHighlght={() => onToggleHighlght('indicator')}
        >
          {meta.columns.indicator.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum_2'}
          highlighted={highlights.includes('sum_2')}
          onToggleHighlght={() => onToggleHighlght('sum_2')}
        >
          Факт
          <Badge className="mx-1" variant="primary">{year - 2}</Badge>
          p.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum_1'}
          highlighted={highlights.includes('sum_1')}
          onToggleHighlght={() => onToggleHighlght('sum_1')}
        >
          Факт
          <Badge className="mx-1" variant="primary">{year - 1}</Badge>
          p.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum0'}
          highlighted={highlights.includes('sum0')}
          onToggleHighlght={() => onToggleHighlght('sum0')}
        >
          Проект
          <Badge className="mx-1" variant="warning">{year}</Badge>
          p.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum1'}
          highlighted={highlights.includes('sum1')}
          onToggleHighlght={() => onToggleHighlght('sum1')}
        >
          Прогноз
          <Badge className="mx-1" variant="success">{year + 1}</Badge>
          p.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum2'}
          highlighted={highlights.includes('sum2')}
          onToggleHighlght={() => onToggleHighlght('sum2')}
        >
          Прогноз
          <Badge className="mx-1" variant="success">{year + 2}</Badge>
          p.
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
