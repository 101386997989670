import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  activeCol, highlights, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_approvedplan_2'}
              highlighted={highlights.includes('amount_approvedplan_2')}
            >
              {totals.amount_approvedplan_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_kassa_expenses_2'}
              highlighted={highlights.includes('amount_kassa_expenses_2')}
            >
              {totals.amount_kassa_expenses_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_approvedplan_1'}
              highlighted={highlights.includes('amount_approvedplan_1')}
            >
              {totals.amount_approvedplan_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total_2'}
              highlighted={highlights.includes('amount_total_2')}
            >
              {totals.amount_total_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total_1'}
              highlighted={highlights.includes('amount_total_1')}
            >
              {totals.amount_total_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_limit'}
              highlighted={highlights.includes('amount_limit')}
            >
              {totals.amount_limit.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment_2'}
              highlighted={highlights.includes('amount_zf_repayment_2')}
            >
              {totals.amount_zf_repayment_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment_1'}
              highlighted={highlights.includes('amount_zf_repayment_1')}
            >
              {totals.amount_zf_repayment_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment0'}
              highlighted={highlights.includes('amount_zf_repayment0')}
            >
              {totals.amount_zf_repayment0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment_2'}
              highlighted={highlights.includes('amount_sf_repayment_2')}
            >
              {totals.amount_sf_repayment_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {' '}
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment_1'}
              highlighted={highlights.includes('amount_sf_repayment_1')}
            >
              {totals.amount_sf_repayment_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {' '}
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment0'}
              highlighted={highlights.includes('amount_sf_repayment0')}
            >
              {totals.amount_sf_repayment0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt_2'}
              highlighted={highlights.includes('amount_debt_2')}
            >
              {totals.amount_debt_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt_1'}
              highlighted={highlights.includes('amount_debt_1')}
            >
              {totals.amount_debt_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt0'}
              highlighted={highlights.includes('amount_debt0')}
            >
              {totals.amount_debt0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount_approvedplan_2: PropTypes.number,
    amount_approvedplan_1: PropTypes.number,
    amount_kassa_expenses_2: PropTypes.number,
    amount_total_2: PropTypes.number,
    amount_total_1: PropTypes.number,
    amount_limit: PropTypes.number,
    amount_zf_repayment_2: PropTypes.number,
    amount_zf_repayment_1: PropTypes.number,
    amount_zf_repayment0: PropTypes.number,
    amount_sf_repayment_2: PropTypes.number,
    amount_sf_repayment_1: PropTypes.number,
    amount_sf_repayment0: PropTypes.number,
    amount_debt_2: PropTypes.number,
    amount_debt_1: PropTypes.number,
    amount_debt0: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
