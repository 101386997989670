const frontendURL = '/cat/bfnGroupName/';
const backendURL = '/api/bfn/refgroupnamebfn/';
const name = 'Групи у складі дитячого закладу';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
