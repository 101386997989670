import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
}) {
  return (
    <Col className="col-lg-8 d-flex justify-content-end  flex-row ">
      <TableHeaderCell
          // className="text-right w-100"
        active
        highlighted={false}
      >
        {`Всього: ${totals}`}
      </TableHeaderCell>
    </Col>
  );
}

TPFooter.propTypes = {
  totals: PropTypes.number,
};

TPFooter.defaultProps = {
  totals: 0,

};

export default memo(TPFooter);
