/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// import { docEditorMenu2 as BasicMenu } from '../../../../components/editorMenu';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from './print_submenu';

const APMenu = ({ id, ...rest }) => (
  <DocEditorCommandPanel
    id={id}
    {...rest}
  >
    <PrintModal id={id} />
  </DocEditorCommandPanel>
);

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
