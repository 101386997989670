import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposer,
}) {
  const amountProps = tableHooks.useNumberInputProps('children_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const groupName = tableHooks.useItemInputProps('group_name', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const groupNameFilter = useMemo(() => ({
    disposer: disposer ? disposer.id : 0,
  }), [disposer]);

  return (
    <Col
      xl={12}
      sm={12}
      className="px-1  d-flex flex-column justify-content-end"
    >
      <Row sm={3} noGutters>

        <Col className="col-sm-4">
          <TabelRowCell column="group_name" highlighted={highlights.includes('group_name')}>
            <TableControls.ItemPicker
              {...groupName}
              filter={groupNameFilter}
              noHierarchy
            />
          </TabelRowCell>
        </Col>
        <Col className="col-sm-4">
          <TabelRowCell column="children_amount" highlighted={highlights.includes('children_amount')}>
            <TableControls.NumberInput
              {...amountProps}
            />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
  disposerInHeader: PropTypes.bool,
  budget: foreignPropType,
  disposer: foreignPropType,
  showKDBKFB: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  onlyYear: false,
  vt4br: false,
  disposerInHeader: false,
  budget: null,
  disposer: null,
  showKDBKFB: false,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
