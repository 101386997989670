import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalButton, SModal } from '../../../../basicEditor/stdFilters/style';
import FitlerEditor from './filterEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

function LoadFinRequestButton({
  authority, fond, docDate, year, planKind,
  onLoadHandler, disabled,
}) {
  const [opened, setOpened] = useState(false);
  const overrideFilter = useMemo(
    () => ({
      authority,
      fond,
      year,
      planKind,
    }),
    [authority, fond, planKind, year],
  );

  const defaultFilter = useMemo(() => {
    const d = docDate ? new Date(docDate) : new Date();
    return {
      begin_date: d.toISOString().substr(0, 10),
      end_date: docDate,
    };
  }, [docDate]);
  return (
    <>
      <ModalButton disabled={disabled || !fond} variant="primary" onClick={() => setOpened(true)}>
        Заповнити з даних заявок на фінансування
      </ModalButton>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FitlerEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          onOk={(ids) => {
            onLoadHandler(ids);
            setOpened(false);
          }}
        />
      </SModal>
    </>
  );
}

LoadFinRequestButton.propTypes = {
  authority: foreignPropType,
  fond: foreignPropType,
  docDate: PropTypes.string,
  year: PropTypes.number,
  planKind: PropTypes.number,
  onLoadHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LoadFinRequestButton.defaultProps = {
  authority: null,
  fond: null,
  docDate: null,
  year: null,
  planKind: null,
  disabled: false,
};

export default LoadFinRequestButton;
