import React from 'react';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import ComponentsFooter from '../../unionCompontnts/componentsFooter';

function FooterComponent({
  footerCol, gridTemplateParam, activeNameCell,
}) {
  return (
    <GridTemplate
      gridTemplateParam={gridTemplateParam}
    >
      <ComponentsFooter
        activeNameCell={activeNameCell}
        footerCol={footerCol}
      />
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  footerCol: PropTypes.shape({}),
  activeNameCell: PropTypes.string,
  gridTemplateParam: PropTypes.shape({
    gridTemplateAreas: PropTypes.string,
    gridTemplateColumns: PropTypes.string,
  }),
};

FooterComponent.defaultProps = {
  footerCol: {},
  activeNameCell: '',
  gridTemplateParam: {},

};

export default FooterComponent;
