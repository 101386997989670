import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Адміністративний район', name: 'Наименование', visible: true },
  owner: { label: 'Бюджет', name: 'Владелец', visible: true },
};

export const districts = {
  label: 'Адміністративні райони',
  name: 'districts',
  backendName: 'Районы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/districts',
  listColumns: [...(new Set(['owner', ...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default districts;
