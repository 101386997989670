import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R209G3: { label: 'R01G3', name: 'R209G3' },
  R209G4: { label: 'R01G3', name: 'R209G4' },
  R209G5: { label: 'R01G3', name: 'R209G5' },
  R209G6: { label: 'R01G3', name: 'R209G6' },
  R209G7: { label: 'R01G3', name: 'R209G7' },
  R209G8: { label: 'R01G3', name: 'R209G8' },
  R209G9: { label: 'R01G3', name: 'R209G9' },
  R209G10: { label: 'R01G3', name: 'R209G10' },
  R209G11: { label: 'R01G3', name: 'R209G11' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол2', name: 'Кол2' },
      col3: { label: 'Кол3', name: 'Кол3' },
      col4: { label: 'Кол4', name: 'Кол4' },
      col5: { label: 'Кол5', name: 'Кол5' },
      col6: { label: 'Кол6', name: 'Кол6' },
      col7: { label: 'Кол7', name: 'Кол7' },
      col8: { label: 'Кол8', name: 'Кол8' },
      col9: { label: 'Кол9', name: 'Кол9' },
      col10: { label: 'Кол10', name: 'Кол10' },
      col11: { label: 'Кол11', name: 'Кол11' },
      col12: { label: 'Кол12', name: 'Кол12' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd10Printing',
  backendName: 'ПечатьПрогнозДод10',
  label: 'Обсяги капітальних вкладень місцевого бюджету у розрізі інвестиційних проектів  (Додаток 10)',
  frontend: 'dp/forecastAdd10Printing',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
