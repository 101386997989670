import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { DocTableListerHeader } from '../../../newLister/tableComponent/table/header';

function LogikaTableListerHeader({ columns, ...rest }) {
  const newColumns = useMemo(
    () => [
      ...columns,
      { key: 'signers', name: 'Подписи', label: 'Підписи' },
    ],
    [columns],
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DocTableListerHeader columns={newColumns} {...rest} />
  );
}
LogikaTableListerHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default memo(LogikaTableListerHeader);
