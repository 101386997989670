import React, {
  forwardRef, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  Badge, Button,
  ButtonGroup, Dropdown, DropdownButton, FormControl, Modal, Navbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';
import baseMD from '../../../../constants/meta/documents/docTransferToLogicaSinging';
import ListerContext from '../../../newLister/context';
import { StyledButtonToolbar } from '../../../newLister/tableComponent/commandpanel/styles';
import {
  RefreshButton,
  SetPeriodButton
} from '../../../newLister/tableComponent/commandpanel/buttons';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import { logikaLogout } from '../../../../actions/auth';
import { modalTitle, msgAlertSuccess, msgAlertVerify } from '../../docTransferToLogica/def';
import SignData from '../../../signData';
import Logica from '../../../authentication';
import { soSelector } from '../../_common/selectors';
import { emptyUid } from '../../../../constants/meta/common';
import api from '../../../../api/req';
import { SearchInput } from '../../../newLister/tableComponent/commandpanel/elements';

const StyledDivMenu = styled.div`
    max-height: 50vh;
`;

const LogikaCommandPanel = forwardRef((_, ref) => {
  const {
    items,
    actions,
    localFilter,
    period,
    onRowFocusHandler,
    selectedRows,
    searchString,
    permissions,
  } = useContext(ListerContext);
  const dispatch = useDispatch();
  const [responseText, setResponseText] = useState(null);
  const [responseStatus, setResponseStatus] = useState(false);
  const [response, setResponse] = useState();
  const [signDataPage, setSignDataPage] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const token = localStorage.getItem('ticket');
  const [formFilter, setFormFilter] = useState('');
  const [unexecute, setUnexecute] = useState(true);
  const sessionOptions = useSelector(soSelector);
  const official = sessionOptions.get('official', new Map());
  const [signingData, setSigningData] = useState({});
  const displayTabs = useMemo(
    () => Object.keys(baseMD.columns.typeReport.variants)
      .map((report) => ({
        name: report,
        label: baseMD.columns.typeReport.variants[report],
      }))
      .filter((f) => f.label.toUpperCase()
        .includes(formFilter.toUpperCase())),
    [formFilter],
  );
  const { onSetFilter } = actions;
  const unsignedPeoples = useMemo(
    () => Object.values(items.reduce((R, row) => ({
      ...R, ...row[baseMD.columns.unsigned.name].reduce((R2, p) => ({
        ...R2,
        [p.id]: p
      }), R),
    }), {})),
    [items],
  );
  const activeUnsignedPeople = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => localFilter.unsigned && localFilter.unsigned.length
      ? localFilter.unsigned[0].value
      : null,
    [localFilter.unsigned],
  );
  const activeTypeReport = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => localFilter.typeReport && localFilter.typeReport.length
      ? localFilter.typeReport[0].value
      : null,
    [localFilter.typeReport],
  );
  const onCheckAll = useCallback(
    (e) => items.filter((i) => !selectedRows.includes(i.id))
      .map((i) => (!selectedRows.includes(i.id)
        && onRowFocusHandler(e, i.id, true))),
    [items, onRowFocusHandler, selectedRows],
  );
  const onUnCheckAll = useCallback(
    (e) => items.filter((i) => selectedRows.includes(i.id))
      .map((i) => (onRowFocusHandler(e, i.id, true))),
    [items, onRowFocusHandler, selectedRows],
  );

  const onSendToLogica = useCallback(
    () => {
      actions.onLogicaSendItems(selectedRows, token);
    },
    [actions, selectedRows, token],
  );

  const onSign = useCallback(
    () => {
      const res = async (idList) => {
        const resp = await api
          .post$('logica/groupSign/logicaSign', {
            idList,
          });
        if (resp.ok) {
          const d = await resp.json();
          return d;
        }
        return [];
      };
      const idList = items
        .filter((item) => selectedRows.includes(item.id))
        .reduce((R, row) => [...R, row.id], []);
      res(idList)
        .then((jsonList) => {
          if (jsonList.length > 0) {
            const jsonObj = Object.assign({}, ...jsonList
              .filter((item) => selectedRows.includes(item.id))
              .map((row) => ({ [row.id]: row.file })));
            setSigningData(jsonObj);
            setSignDataPage(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching signing data:', error);
        });
      setResponse(null);
    },
    [items, selectedRows],
  );

  const addSign = useCallback((signingByIds) => {
    actions.onLogicaSignItems(signingByIds, activeUnsignedPeople);
  }, [actions, activeUnsignedPeople]);

  const allSigned = useMemo(() => {
    if (items.length === 0 || selectedRows.length === 0) {
      return true;
    }
    const unsigned = items
      .filter((item) => selectedRows.includes(item.id)
        && item[baseMD.columns.unsigned.name]
        && item[baseMD.columns.unsigned.name].length);
    return unsigned?.length > 0;
  }, [items, selectedRows]);

  useEffect(() => {
    if (response) {
      setResponseStatus(response.success);
      setResponseText(response.messages);
    }
  }, [response]);

  useEffect(() => {
    // if (token) {
    //   setShowAuth(false);
    // } else {
    //   setShowAuth(true);
    // }
    // if (responseText === 'Необхідно авторизуватись') {
    //   dispatch(logikaLogout());
    //   setShowAuth(true);
    // }
  }, [dispatch, responseText, token]);

  useEffect((e) => {
    if (unexecute) {
      onSetFilter(e, {
        unexecute: [
          {
            fieldName: 'Проведен',
            value: false,
          },
        ],
      });
    } else {
      onSetFilter(e, {
        unexecute: [],
      });
    }
  }, [onSetFilter, unexecute]);

  useEffect((e) => {
    if (official.get('id') !== emptyUid) {
      onSetFilter(e, {
        unsigned: [
          {
            fieldName: 'ПодписантОтбор',
            value: official,
          },
          {
            fieldName: 'ПодписаноОтбор',
            value: false,

          },
        ],
      });
    } else {
      onSetFilter(e, {
        unsigned: [],
      });
    }
  }, [onSetFilter, official]);

  function AlertSuccess() {
    return (
      <Alert
        dismissible
        variant={responseStatus ? 'success' : 'danger'}
        onClose={() => {
          setResponseText(null);
          setResponseStatus(null);
        }}
      >
        {responseText === { msgAlertSuccess } ? (
          <p style={{ fontSize: '16px' }}>
            {msgAlertSuccess}
            <br/>
            <span style={{
              color: 'red',
              fontWeight: 'bold'
            }}>{msgAlertVerify}</span>
          </p>
        ) : <p style={{ fontSize: '16px' }}>{responseText}</p>}
      </Alert>
    );
  }

  return (
    <>
      <Modal
        show={signDataPage}
        onHide={(e) => {
          setSignDataPage(false);
          onRowFocusHandler(e);
        }}
        size="lg"
        fullscreen
        dialogClassName="mw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignData
            data={signingData}
            onAddSign={addSign}
            onClose={(e) => {
              setSignDataPage(false);
              onRowFocusHandler(e);
            }}
            multiple
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showAuth}
        onHide={() => setShowAuth(false)}
        size="lg"
        fullscreen
        dialogClassName="mw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Авторизація в сервісі Logica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Logica/>
        </Modal.Body>
      </Modal>
      {responseText && (<AlertSuccess/>)}
      <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
        <StyledButtonToolbar className="me-auto">
          <ButtonGroup>
            <DefaultCPButton
              onClick={() => {
                setUnexecute(!unexecute);
              }}
              label="Відбір по невідправленим"
              variant="outline-primary"
            >
              <FontAwesomeIcon icon={unexecute ? faCheck : faSquare} className="me-1"/>
              Невідправлені
            </DefaultCPButton>
            <Dropdown>
              <Dropdown.Toggle variant="secondary">
                {activeTypeReport ? `Вид звіту: ${baseMD.columns.typeReport.variants[activeTypeReport]}` : 'Вид звіту: Всі'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => onSetFilter(e, {
                    typeReport: [],
                  })}
                >
                  Всі
                </Dropdown.Item>
                <FormControl
                  autoFocus
                  className="mx-3 my-2 w-100"
                  placeholder="Введіть назву звіту"
                  value={formFilter}
                  onChange={(e) => setFormFilter(e.target.value)}
                />
                <Dropdown.Divider/>
                <StyledDivMenu className="overflow-auto">
                  {displayTabs.map((p) => (
                    <Dropdown.Item
                      key={p.name}
                      active={activeTypeReport && p.name === activeTypeReport}
                      onClick={(e) => {
                        onSetFilter(e, {
                          typeReport: [
                            {
                              fieldName: 'ВидОтчета',
                              value: p.name,
                            },
                          ],
                        });
                      }}
                    >
                      {p.label}
                    </Dropdown.Item>
                  ))}
                </StyledDivMenu>
              </Dropdown.Menu>
            </Dropdown>
            <RefreshButton
              onClick={actions.onRefresh}
            />
            <SetPeriodButton value={period} onChange={actions.onSetPeriod}/>
            <ButtonGroup>
              <DefaultCPButton onClick={onCheckAll} label="Відмітити всі непідписані"
                               variant="outline-primary">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10"/>
                  <FontAwesomeIcon icon={faCheck} transform="shrink-6 "/>
                  <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10"/>
                </span>
              </DefaultCPButton>
              <DefaultCPButton onClick={onUnCheckAll} label="Зняти всі відмитки"
                               variant="outline-secondary">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faSquare} transform="shrink-6 up-10"/>
                  <FontAwesomeIcon icon={faSquare} transform="shrink-6 "/>
                  <FontAwesomeIcon icon={faSquare} transform="shrink-6 down-10"/>
                </span>
              </DefaultCPButton>
            </ButtonGroup>
          </ButtonGroup>
          <ButtonGroup>
            <DropdownButton
              title={activeUnsignedPeople ? `1. Підписант: ${activeUnsignedPeople.repr || activeUnsignedPeople.get('repr')}` : '1. Підписант не обраний'}
              disabled={unsignedPeoples.length === 0 && !activeUnsignedPeople}
              variant="info"
            >
              <Dropdown.Item
                onClick={(e) => {
                  onRowFocusHandler(e);
                  onSetFilter(e, {
                    unsigned: [],
                  });
                }}
              >
                Всі
              </Dropdown.Item>
              <Dropdown.Divider/>
              {unsignedPeoples.map((p) => (
                <Dropdown.Item
                  key={p.id}
                  active={activeUnsignedPeople && p.id === activeUnsignedPeople.id}
                  onClick={(e) => {
                    onRowFocusHandler(e);
                    onSetFilter(e, {
                      unsigned: [
                        {
                          fieldName: 'ПодписантОтбор',
                          value: p,
                        },
                        {
                          fieldName: 'ПодписаноОтбор',
                          value: false,

                        },
                      ],
                    });
                  }}
                >
                  {p.repr}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </ButtonGroup>
          <Button
            variant="success"
            onClick={() => onSign()}
            disabled={!(selectedRows.length > 0 && activeUnsignedPeople !== null)}
            title="Для активації необхідно обрати підписанта"
          >
            2. Підписати
          </Button>
          <Button
            variant="success"
            onClick={() => onSendToLogica()}
            disabled={allSigned}
            title="Для активації необхідно щоб всі документи були підписані"
          >
            3. Відправити до Logica
          </Button>
        </StyledButtonToolbar>
        <div className="ms-auto">
          <div>
            <SearchInput
              onChange={actions.onSearch}
              value={searchString}
              disabled={!permissions.canSearch && !searchString}
            />
            <div>

              Відмічено
              <Badge className="mx-2" variant="dark">{selectedRows.length}</Badge>
              {`документ${(() => {
                switch (selectedRows.length) {
                case 1:
                  return '';
                case 2:
                case 3:
                case 4:
                  return 'и';
                default:
                  return 'ів';
                }
              })()}`}
            </div>

          </div>

        </div>
      </Navbar>
    </>
  );
});

export default LogikaCommandPanel;
