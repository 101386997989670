import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  R01G3: { label: 'R01G2', name: 'R01G3' },
  R01G4: { label: 'R01G3', name: 'R01G4' },
  R01G5: { label: 'R01G4', name: 'R01G5' },
  R02G3: { label: 'R02G2', name: 'R02G3' },
  R02G4: { label: 'R02G3', name: 'R02G4' },
  R02G5: { label: 'R02G4', name: 'R02G5' },
  R03G3: { label: 'R03G2', name: 'R03G3' },
  R03G4: { label: 'R03G3', name: 'R03G4' },
  R03G5: { label: 'R03G4', name: 'R03G5' },
  R04G3: { label: 'R04G2', name: 'R04G3' },
  R04G4: { label: 'R04G3', name: 'R04G4' },
  R04G5: { label: 'R04G4', name: 'R04G5' },
  R05G3: { label: 'R05G2', name: 'R05G3' },
  R05G4: { label: 'R05G3', name: 'R05G4' },
  R05G5: { label: 'R05G4', name: 'R05G5' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол2', name: 'Кол2' },
      col3: { label: 'Кол3', name: 'Кол3' },
      col4: { label: 'Кол4', name: 'Кол4' },
      col5: { label: 'Кол5', name: 'Кол5' },
      col6: { label: 'Кол6', name: 'Кол6' },
      col7: { label: 'Кол7', name: 'Кол7' },
      col8: { label: 'Кол8', name: 'Кол8' },
      col9: { label: 'Кол9', name: 'Кол9' },
      col10: { label: 'Кол10', name: 'Кол10' },
      col11: { label: 'Кол11', name: 'Кол11' },
      col12: { label: 'Кол12', name: 'Кол12' },
      col13: { label: 'Кол13', name: 'Кол13' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'prepareForecastAdd5',
  backendName: 'ПечатьПрогнозДодП5',
  label: 'Показники міжбюджетних трансфертів (іншим місцевим бюджетам) (Додаток 5)',
  frontend: 'dp/prepareForecastAdd5',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
