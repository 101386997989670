import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
  template: {
    label: 'Шаблон',
    name: 'Шаблон',
    visible: false,
  },
  budgetPayment: { label: 'Сплата до бюджету', name: 'ОплатаВБюджет', visible: false },
  comm: { label: 'Коментар', name: 'Комментарий', visible: false },
  org: { label: 'Установа', name: 'Организация', visible: false },
  budgetPaymentCode: { label: 'Код виду оплати до бюджету', name: 'КодВидаОплатыВБюджет', visible: false },
  KOATUU: { label: 'Код КОАТУУ', name: 'КодКОАТУУ', visible: false },
  info: { label: 'Податкова довідкова інформація', name: 'НалоговаяСправочнаяИнформация', visible: false },
  KDB: { label: 'Код доходів бюджету (тип)', name: 'КодДоходовБюджетаНалог', visible: false },
  messageInfo: { label: 'Інформація про податкове повідомлення (рішення)', name: 'ИнформацияПроНалоговоеСообщение', visible: false },
  clientTAX: { label: 'Контрагент (податок)', name: 'КонтрагентНалог', visible: false },
  acc: { label: 'Розрахунковий рахунок (податок)', name: 'РасчетныйСчетКонтрагентаНалог', visible: false },
  addInfo: { label: 'Додаткова інформація запису  про податок', name: 'ДополнительнаяИнформацияЗаписиПроНалог', visible: false },
};

export const paymentPurposeTemplate = {
  label: 'Шаблони призначень платежів',
  name: 'paymentPurposeTemplate',
  backendName: 'ШаблоныНазначенийПлатежа',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/paymentPurposeTemplate',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default paymentPurposeTemplate;
