import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import StructeureItem from './structureItem';
import { StyledSegment, StyledList } from '../StyledComponents';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';

const ctxOptionsHierarchy = [
  {
    label: 'Ієрархія',
    action: DC.groupTypes.Hierarchy,
  }, {
    label: 'Без ієрархії',
    action: DC.groupTypes.Items,
  }, {
    label: 'Тільки ієрархія',
    action: DC.groupTypes.HierarchyOnly,
  },
];

function StructureView({
  availableFields, data, selectHandler, deleteHandler, toggleUseHandler, onActionClick,
}) {
  const items = useMemo(
    () => {
      const getItems = (parent = null, level = 0) => {
        const showItems = data.filter((v) => v.get('Parent') === parent);

        return showItems.reduce((R, item, k) => {
          const childrenItems = getItems(k, level + 1);
          return [
            ...R,
            {
              index: k,
              item,
              level,
            },
            ...childrenItems,
          ];
        }, []);
      };
      return getItems();
    },
    [data],
  );

  return (
    <StyledSegment onClick={selectHandler}>
      <Droppable droppableId="DC.Structure">
        {(providedDrop) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StyledList ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {items.map(({ item, index, level }) => (
              <Draggable key={index} draggableId={index} index={item.get('Index')}>
                {(providedDrag) => (
                  <StructeureItem
                    onD={console.log(item.toJS(), index)}
                    ctxOptions={ctxOptionsHierarchy}
                    onActionClick={(value) => onActionClick(value, index)}
                    iRef={providedDrag.innerRef}
                    item={item}
                    id={index}
                    level={level}
                    availableFields={availableFields}
                    toggleUseHandler={toggleUseHandler}
                    onClickHandler={selectHandler}
                    onDoubleClickHandler={deleteHandler}
                    dragHandleProps={providedDrag.dragHandleProps}
                    draggableProps={providedDrag.draggableProps}
                  />
                )}
              </Draggable>

            ))}
            {providedDrop.placeholder}
          </StyledList>
        )}
      </Droppable>
    </StyledSegment>
  );
}

StructureView.propTypes = {
  availableFields: PropTypes.instanceOf(OrderedMap).isRequired,
  data: PropTypes.instanceOf(OrderedMap).isRequired,
  selectHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  toggleUseHandler: PropTypes.func,
  onActionClick: PropTypes.func,
};

StructureView.defaultProps = {
  selectHandler: () => null,
  deleteHandler: () => null,
  toggleUseHandler: () => null,
  onActionClick: () => null,
};

export default memo(StructureView);
