import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { faArrowRight, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

function GoToOldVersionButton({
  disabled,
  onGoToOldVersion,
  onSaveNGoToOldVersion,
  label,
  isChanged,
  loading,
}) {
  const [q, setQ] = useState(false);

  const onCloseClick = useCallback(
    () => {
      if (isChanged || loading) {
        setQ(true);
      } else {
        onGoToOldVersion();
      }
    },
    [isChanged, loading, onGoToOldVersion],
  );

  return (
    <>
      <DefaultCPButton
        onClick={onCloseClick}
        label={label}
        disabled={disabled}
        variant="light"
        icon={faArrowRight}
        showLabel={false}
      />
      <Modal show={q} onHide={() => setQ(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Перехід на стару версію
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={onSaveNGoToOldVersion}
          >
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Зберегти зміни та перейти
          </Button>
          <Button
            variant="danger"
            onClick={onGoToOldVersion}
          >
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            Перейти без збереження
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

GoToOldVersionButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onGoToOldVersion: PropTypes.func,
  onSaveNGoToOldVersion: PropTypes.func,
  isChanged: PropTypes.bool,
  loading: PropTypes.bool,

};

GoToOldVersionButton.defaultProps = {
  disabled: false,
  label: 'Перейти на стару версію',
  onGoToOldVersion: null,
  onSaveNGoToOldVersion: null,
  isChanged: false,
  loading: false,
};

export default GoToOldVersionButton;
