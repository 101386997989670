import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const GridTemplate = styled(BasedGrid)`
  display: grid;
  grid-template-columns: [check] 40px [docNo] 1fr [date] 1fr [sum] 150px [author] 1fr;
  grid-auto-columns: 100px;
  grid-auto-rows: auto;
`;

export default GridTemplate;
