/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useCallback,
  useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Row, Col, Tabs, TabContainer, Alert, Table, Button, OverlayTrigger, Popover,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { Footer } from '../../../../components/Containers/docs';

import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { Control } from '../../../newEditor/hook/control';
import useEditorParams from '../../../newEditor/hook/params';
import ReceivedStamp from '../../../../components/stamp/receivedStamp';
import LiquidityApp2 from './tp/liquidityApp2';
import LiquidityApp3 from './tp/liquidityApp3';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import LiquidityLevelDownApp from './tp/liquidityLevelDownApp';
import LoadLevelDownButton from './loadLeveldown';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';
import HintForms from './tp/hint';
// import { CiatAppContext } from '../../../../providers';
// import backendEnums from '../../../../constants/backend/enums';

const BACKENDURL = meta.doc.liquidity.backendURL;
// const URL = 'https://eisub.ciat.net.ua/api/forecasts/minfin_data/';

function getMonthName(m) {
  if (m === undefined) return '';
  const d = new Date();
  d.setMonth(m - 1, 1);
  return d.toLocaleString('uk', { month: 'long' });
}

function getNumberUk(numStr) {
  const num = Number.parseInt(numStr, 10).toLocaleString('uk');
  return (numStr === undefined)
    ? (
      <div style={{ textAlign: 'center' }}>-</div>
    )
    : (<div style={{ textAlign: 'right' }}>{num}</div>);
}

function Suggestion({
  show, month1, month2, month3,
  suggestion, setShowSuggestion,
  children,
}) {
  const popover = (
    <Popover
      id="popover-contained"
      style={{ 'max-width': '40%' }}
    >
      <div>
        <Popover.Header as="h3">
          {`Попередні прогнозні значення загального фонду на ${getMonthName(month1)}-${getMonthName(month3)}`}
        </Popover.Header>
        <Popover.Body className="p-1">
          <Table bordered hover>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '30%' }}>
                  КЕКВ
                </th>

                <th>
                  {`${getMonthName(month1)}`}
                </th>
                <th>
                  {`${getMonthName(month2)}`}
                </th>
                <th>
                  {`${getMonthName(month3)}`}
                </th>

              </tr>
            </thead>
            <tbody>

              <tr>
                <td>2110</td>
                <td>{getNumberUk(suggestion.r01g3)}</td>
                <td>{getNumberUk(suggestion.r01g4)}</td>
                <td>{getNumberUk(suggestion.r01g5)}</td>
              </tr>

              <tr>
                <td>2220, 2230, 2270, 2281, 2282, 2700</td>
                <td>{getNumberUk(suggestion.r02g3)}</td>
                <td>{getNumberUk(suggestion.r02g4)}</td>
                <td>{getNumberUk(suggestion.r02g5)}</td>
              </tr>

              <tr>
                <td>5000 (крім 2620, 3220)</td>
                <td>{getNumberUk(suggestion.r03g3)}</td>
                <td>{getNumberUk(suggestion.r03g4)}</td>
                <td>{getNumberUk(suggestion.r03g5)}</td>
              </tr>

              <tr>
                <td>
                  Видатки спеціального фонду, крім видатків
                  за рахунок доходів в натуральній формі, а
                  також видатків за КЕКВ 2120, 2400, 2620,
                  3220
                </td>
                <td>{getNumberUk(suggestion.r04g3)}</td>
                <td>{getNumberUk(suggestion.r04g4)}</td>
                <td>{getNumberUk(suggestion.r04g5)}</td>
              </tr>

            </tbody>
          </Table>
          <Button variant="primary" onClick={() => setShowSuggestion(false)} size="sm" className="my-1">
            Закрити
          </Button>
        </Popover.Body>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={show}
      placement="bottom-start"
      style={{ MaxWidth: '400px' }}
      overlay={popover}
    >
      {children}
    </OverlayTrigger>
  );
}

Suggestion.propTypes = {
  show: PropTypes.bool.isRequired,
  month1: PropTypes.number.isRequired,
  month2: PropTypes.number.isRequired,
  month3: PropTypes.number.isRequired,
  setShowSuggestion: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  suggestion: PropTypes.shape({
    r01g3: PropTypes.string,
    r01g4: PropTypes.string,
    r01g5: PropTypes.string,
    r02g3: PropTypes.string,
    r02g4: PropTypes.string,
    r02g5: PropTypes.string,
    r03g3: PropTypes.string,
    r03g4: PropTypes.string,
    r03g5: PropTypes.string,
    r04g3: PropTypes.string,
    r04g4: PropTypes.string,
    r04g5: PropTypes.string,
  }),
};

Suggestion.defaultProps = {
  suggestion: {
    r01g3: '0',
    r01g4: '0',
    r01g5: '0',
    r02g3: '0',
    r02g4: '0',
    r02g5: '0',
    r03g3: '0',
    r03g4: '0',
    r03g5: '0',
    r04g3: '0',
    r04g4: '0',
    r04g5: '0',
  },
};

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions,
    fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors,
    readOnlyFields, readOnly, headerReadOnlyFields,
    printForms, headerProps,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const signVariantProps = editorHooks.useItemInputProps('authority_sign_variant', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantFitler = useMemo(
    () => ({ authority: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  const {
    month1, month2, month3, suggestion,
  } = data;

  const [err, setErr] = useState(null);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const getSuggestion = useCallback(
    (name) => {
      if (!suggestion) return null;
      if (!suggestion[name]) return null;
      return `Попереднє прогнозне значення: ${Number.parseInt(suggestion[name], 10).toLocaleString('uk')} грн`;
    },
    [suggestion],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          backendURL={BACKENDURL}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={BACKENDURL}
            documnetId={data.id}
            disabled={!data.executed || changed}
            setError={setErr}
          />

        </DocCommandPanel>
      )}
    >
      {data.approved && (
        <div className="stamp-container">
          <ReceivedStamp invoice={data.invoice} />
        </div>
      )}
      <Row>
        <Col>
          <Control name="doc_date" headerProps={headerProps} />
        </Col>
        <Col>
          <Control name="year" headerProps={headerProps} typeControl="yearInput" />
        </Col>
        <Col>
          <EditorControls.ItemPicker {...signVariantProps} filter={signVariantFitler} />
        </Col>
      </Row>
      <Row>
        <Col className="p-3">
          <LoadLevelDownButton
            authority={data.authority}
            docDate={data.doc_date}
            year={data.year}
            disabled={!permissions.canChange}
            onLoadHandler={(documents) => actions.onDraft(
              data,
              {
                load_leveldown_liquidity_rows: {
                  documents,
                },
              },
            )}
          />
        </Col>
        <Col>
          <Control
            name="comment"
            headerProps={headerProps}
            typeHook="text"
            typeControl="text"
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="app1">

        <Tab eventKey="app1" title="Додаток 1">
          <>
            {err && (
            <Alert variant="danger" onClose={() => setErr(null)}>
              {err}
            </Alert>
            )}
            <Suggestion
              show={showSuggestion}
              month1={month1}
              month2={month2}
              month3={month3}
              suggestion={suggestion}
              setShowSuggestion={setShowSuggestion}
            >
              <Alert variant="info">
                <Alert.Heading>Увага!</Alert.Heading>
                Для полегшення процесу прийняття рішень щодо очікуваних обсягів видатків,
                під відповідним показником таблиці може бути вказано попереднє прогнозне значення.
                Це оціночне прогнозне значення слід відкоригувати, беручи до уваги актуальну
                інформацію, що є у відповідальних осіб розпорядників бюджетних коштів та МФО.
                <Button variant="primary" onClick={() => setShowSuggestion(!showSuggestion)} size="sm" className="mx-1">
                  {`${showSuggestion ? 'Приховати' : 'Показати'} попередні прогнозні значення`}
                </Button>
                <br />
                Якщо в результаті платежу кошти будуть спрямовані на рахунки
                відкриті в комерційних банках, то такі очікувані видатки мають відображатися
                у стовпцях 3, 4 та 5 Додатку.
                {' '}
                <br />
                Якщо в результаті платежу кошти будуть спрямовані на рахунки
                відкриті в Казначействі (в межах єдиного казначейського рахунку), то очікувані
                видатки мають відображатися у стовпцях 6, 7 та 8 Додатку.
              </Alert>
            </Suggestion>
            <Table bordered hover>
              <thead>
                <tr>
                  <th rowSpan={2}>
                    КЕКВ
                  </th>
                  <th rowSpan={2}>
                    Фонд
                  </th>
                  <th colSpan={3}>
                    платежі, що спрямовуються на рахунки в комерційних банках
                    (за межі казначейської системи)
                  </th>
                  <th colSpan={3}>
                    платежі, що спрямовуються на рахунки в Казначействі
                  </th>
                </tr>
                <tr>
                  <th>{`${getMonthName(month1)}`}</th>
                  <th>{`${getMonthName(month2)}`}</th>
                  <th>{`${getMonthName(month3)}`}</th>
                  <th>{`${getMonthName(month1)}`}</th>
                  <th>{`${getMonthName(month2)}`}</th>
                  <th>{`${getMonthName(month3)}`}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">2110</th>
                  <th scope="row">Загальний</th>
                  <td>
                    <Control
                      name="r01g3"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r01g3')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r01g4"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r01g4')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r01g5"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r01g5')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r01g6"
                      headerProps={headerProps}
                      readOnly={readOnly}
                      description={getSuggestion('r01g6')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r01g7"
                      headerProps={headerProps}
                      readOnly={readOnly}
                    />
                  </td>
                  <td>
                    <Control
                      name="r01g8"
                      headerProps={headerProps}
                      readOnly={readOnly}
                      description={getSuggestion('r01g8')}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">2220, 2230, 2270, 2281, 2282, 2700</th>
                  <th scope="row">Загальний</th>
                  <td>
                    <Control
                      name="r02g3"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g3')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r02g4"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g4')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r02g5"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g5')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r02g6"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g6')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r02g7"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g7')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r02g8"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r02g8')}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">5000 (крім 2620, 3220)</th>
                  <th scope="row">Загальний</th>
                  <td>
                    <Control
                      name="r03g3"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g3')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r03g4"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g4')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r03g5"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g5')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r03g6"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g6')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r03g7"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g7')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r03g8"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r03g8')}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row" colSpan={2}>
                    Видатки спеціального фонду, крім видатків
                    за рахунок доходів в натуральній формі, а
                    також видатків за КЕКВ 2120, 2400, 2620,
                    3220
                  </th>
                  <td>
                    <Control
                      name="r04g3"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r04g3')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r04g4"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r04g4')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r04g5"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r04g5')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r04g6"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r04g6')}
                    />
                  </td>
                  <td>
                    <Control
                      name="r04g7"
                      readOnly={readOnly}
                      headerProps={headerProps}
                      description={getSuggestion('r04g7')}
                    />
                  </td>
                  <td>
                    <Control
                      headerProps={headerProps}
                      readOnly={readOnly}
                      name="r04g8"
                      description={getSuggestion('r04g8')}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        </Tab>
        <Tab eventKey="app2" title="Додаток 2">
          <TabContainer>
            <LiquidityApp2
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              readOnly={readOnly}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="app3" title="Додаток 3">
          <TabContainer>
            <LiquidityApp3
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              readOnly={readOnly}
            />
          </TabContainer>
        </Tab>
        <Tab title="Рекомендації щодо заповнення форм" eventKey="hint">
          <HintForms />
        </Tab>
        <Tab eventKey="app5" title="Документи нижнього рівня">
          <TabContainer>
            <LoadLevelDownButton
              authority={data.authority}
              docDate={data.doc_date}
              year={data.year}
              disabled={!permissions.canChange}
              onLoadHandler={(documents) => actions.onDraft(
                data,
                {
                  load_leveldown_liquidity_rows: {
                    documents,
                  },
                },
              )}
            />
            <LiquidityLevelDownApp
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              actions={actions}
              readOnly={readOnly}
            />
          </TabContainer>
        </Tab>
      </Tabs>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  options: { name: '' },
  // fieldErrors: {},
};

export default Editor;
