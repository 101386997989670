import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Аналіз Звітів з виконання бюджетних програм',
  name: 'ReportAnalysis',
  columns,
  frontend: 'rep/ReportAnalysis',
  backendName: 'АнализОтчетовВыполнения',
  resultColumns: baseResultColumns,
};

export default definition;
