import React from 'react';
import PropTypes from 'prop-types';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { CPButton } from './styles';

export function UnExecuteButton({ content, onClick, disabled }) {
  return (
    <CPButton
      title="Відмінити проведення"
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faCalendarCheck}
      variant="falcon-default"
    />
  );
}

UnExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

UnExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Відмінити проведення',
  disabled: false,
};

export default UnExecuteButton;
