import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authorityId,
}) {
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(
    () => ({ parent: authorityId }),
    [authorityId],
  );

  const kbp7Props = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7ItemFilter = useMemo(
    () => ({ owner: authorityId }),
    [authorityId],
  );
  const buildingTermProps = tableHooks.useStringInputProps('building_term', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const objectNameProps = tableHooks.useStringInputProps('object_name', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const startPercentProps = tableHooks.useNumberInputProps('start_period_completed_percent', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const endPercentProps = tableHooks.useNumberInputProps('end_period_completed_percent', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const yearAmountProps = tableHooks.useNumberInputProps('year_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const totalAmountProps = tableHooks.useNumberInputProps('total_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col>
      <Row noGutters>
        <Col lg={3}>
          <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
            <TableControls.ItemPicker {...disposerProps} filter={disposerFilter} noHierarchy />
          </TabelRowCell>
          <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
            <TableControls.ItemPicker {...kbp7Props} filter={kbp7ItemFilter} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="building_term" highlighted={highlights.includes('building_term')}>
            <TableControls.StringInput {...buildingTermProps} />
          </TabelRowCell>
          <TabelRowCell column="object_name" highlighted={highlights.includes('object_name')}>
            <TableControls.StringInput {...objectNameProps} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="start_period_completed_percent" highlighted={highlights.includes('start_period_completed_percent')}>
            <TableControls.NumberInput {...startPercentProps} />
          </TabelRowCell>
          <TabelRowCell column="end_period_completed_percent" highlighted={highlights.includes('end_period_completed_percent')}>
            <TableControls.NumberInput {...endPercentProps} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="year_amount" highlighted={highlights.includes('year_amount')}>
            <TableControls.NumberInput {...yearAmountProps} />
          </TabelRowCell>
          <TabelRowCell column="total_amount" highlighted={highlights.includes('total_amount')}>
            <TableControls.NumberInput {...totalAmountProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  //
  authorityId: PropTypes.number,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  //
  authorityId: null,
};

export default memo(TRow);
