import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import {
  StringInput, DateInput, NumberInput, Selector, ColorInput,
  TextInput, ItemField, ItemPicker, MultiValueInput, MultiSelector,
  FormatInput, PhoneInput, YearInput, RadioInput, RangeDatePicker,
} from '../../../components/Controls';
import EditorCheckboxInput from './checkbox';
import editorHooks from './hooks';
import dataHooks from './dataHooks';
import withEditorControl from './editorControl';

export const EditorStingInput = withEditorControl(StringInput);
export const EditorColorInput = withEditorControl(ColorInput);
export const EditorDateInput = withEditorControl(DateInput);
export const EditorNumberInput = withEditorControl(NumberInput);
export const EditorSelectorInput = withEditorControl(Selector, true);
export { EditorCheckboxInput };
export const EditorTextInput = withEditorControl(TextInput);
export const EditorItemField = withEditorControl(ItemField);
export const EditorItemPicker = withEditorControl(ItemPicker);
export const EditorMultiValueInput = withEditorControl(MultiValueInput);
export const EditorMultiSelectorInput = withEditorControl(MultiSelector);
export const EditorFormatInput = withEditorControl(FormatInput);
export const EditorPhoneInput = withEditorControl(PhoneInput);
export const EditorYearInput = withEditorControl(YearInput);
export const EditorRangeDatePicker = withEditorControl(RangeDatePicker);
export const EditorRadioInput = withEditorControl(RadioInput, true);

export { editorHooks, dataHooks };

export const FieldPropType = PropTypes.shape({
  label: PropTypes.string,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['field', 'integer', 'string', 'date', 'boolean', 'decimal', 'nested object', 'choice', 'datetime', 'email', 'url']),
  max_length: PropTypes.number,
  choice: PropTypes.shape({ }),
  resource: PropTypes.string, // backend url for FK fields
});
export const getFieldsPropTypes = (dpt) => Object.keys(dpt)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

export const EditorControls = {
  StringInput: EditorStingInput,
  DateInput: EditorDateInput,
  RangeDatePicker: EditorRangeDatePicker,
  NumberInput: EditorNumberInput,
  SelectorInput: EditorSelectorInput,
  CheckboxInput: EditorCheckboxInput,
  TextInput: EditorTextInput,
  ItemField: EditorItemField,
  ItemPicker: EditorItemPicker,
  MultiSelector: EditorMultiSelectorInput,
  FormatInput: EditorFormatInput,
  ColorInput: EditorColorInput,
  PhoneInput: EditorPhoneInput,
  YearInput: EditorYearInput,
  RadioInput: EditorRadioInput,
};
