import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'НаДату' },
  year: { label: 'Рік', name: 'текГод' },
  budget: { label: 'Бюджет', name: 'текБюджет' },
  FO: { label: 'Фiнансовий орган', name: 'текФинансовыйОрган' },

  fixingTitle: { label: 'Фiксування зоголовку', name: 'ФиксацияЗаголовка' },
};

const definition = {
  label: 'Звіт щодо кількості об\'єктів капітального будівництва та ремонту',
  name: 'CountContractsTenders',
  columns,
  frontend: 'rep/CountContractsTenders',
  backendName: 'ОбъектыСтроительствКолДоговоровТендеров',
  resultColumns: baseResultColumns,
};

export default definition;
