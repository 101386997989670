const frontendURL = '/doc/netCategory/';
const backendURL = '/api/merezha/merezhaheader/';
const name = 'Зведення фактичних показників за мережею, штатами і контингентами (Додаток 4)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
