import React from 'react';
import Lister from '../../lister/lister';
import { FOFilterComponent } from '../_common';

function FinancingCashboxLister() {
  return (
    <Lister
      modelType="doc"
      modelName="financingCashbox"
      headerComponent={<FOFilterComponent />}
    />
  );
}

export default FinancingCashboxLister;
