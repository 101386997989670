import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const GridTemplate = styled(BasedGrid)`
  grid-template-areas: 
    "docno  mfo       incout  createdate  client  edrpou purpose doc"
    "lineno bankname  sum     execdate    account state  purpose doc";
  grid-template-columns: 100px 1fr 100px 100px 1fr 100px 1fr 1fr;
`;

export default GridTemplate;
