import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import IndicatorsFactPrintModal from '../lister/printModalLister';

function PrintSubMenu({ id }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <Dropdown>
      <Dropdown.Toggle variant="info" size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Друк
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <IndicatorsFactPrintModal selectedRows={selectedRows} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

PrintSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({ }).isRequired,
};

export default PrintSubMenu;
