import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { StyledField } from '../../../styled';
import LineTypeSelector, { lineTypes } from './backendEnums/lineTypeSelector';
import Modal from '../../../../../../../components/modal';
import CheckboxField from '../../../../../../field/CheckBox';
import GetNumberInput from '../../../../../../field/NumberInput';
import { StyledLabel } from '../../../../../../../components/Form/styledForm';

const WidthInput = GetNumberInput(1, 0, true);

function LineField({ value, onChange }) {
  const displayValue = useMemo(
    () => Object.values(lineTypes).reduce(
      // eslint-disable-next-line no-confusing-arrow
      (R, vl) => value.get('LineType') === vl.name ? vl.label : R,
      lineTypes.NONE.label,
    ),
    [value],
  );
  const [opened, setOpened] = useState(false);
  return (
    <>
      <StyledField>
        <div>{displayValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Лінія" closeIcon opened={opened} onClose={() => setOpened(false)} size="small">
        <>
          <CheckboxField
            value={value.get('Gap', false)}
            onChange={(e, v) => onChange(e, value.set('Gap', v))}
            label="Відступ"
          />
          <StyledLabel>Товщина</StyledLabel>
          <WidthInput
            value={value.get('Width', 0)}
            onChange={(e, v) => onChange(e, value.set('Width', v))}
            border
            disableCalculator
            disabled={value.get('LineType') === lineTypes.NONE.name}
          />
          <StyledLabel>Тип лінії</StyledLabel>
          <LineTypeSelector
            value={value.get('LineType')}
            onChange={(e, v) => onChange(e, value.set('LineType', v).set('Width', v === lineTypes.NONE.name ? 0 : 1))}
          />
        </>
      </Modal>
    </>
  );
}

LineField.propTypes = {
  value: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
};

LineField.defaultProps = {
  value: new Map(),
};

export default LineField;
