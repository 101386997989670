const frontendURL = '/doc/creditProject/';
const backendURL = '/api/planproject/creditprojectheader/';
const name = 'Проект кредитування місцевого бюджету (Дод. 4 до РС)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
