import React from 'react';
import Editor from './defaultEditor';

function PlanExchange() {
  return (
    <Editor
      title="Завантажити плани та зміни"
      backendUrl="/api/cityplans/importdbf/"
    />
  );
}

export default PlanExchange;
