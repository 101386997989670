import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/docFinAnalysis';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import {
  ContainerTwoColumn,
  StyledLabel, ContainerDiv,
} from '../../../components/Form/styledForm';
import ItemField from '../../field/selectorField/item/itemField';
import DateRangeField from '../../../components/fields/DateRangeField';
import ReportContainer from '../reportContainer';

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

class ReportDocFinAnalysisEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    sessionOptions: new Map(),
    portalOpened: false,
    onReportDetails: null,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );

    const TypeDoc = {
      current: { label: 'Фінансування', value: 'Фінансування' },
      lower: { label: 'Пропозиція на фінансування', value: 'Пропозиція на фінансування' },
      high: { label: 'Заявка на фінансування', value: 'Заявка на фінансування' },
    };

    const SettingsButton = (
      <div>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
        </ContainerTwoColumn>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.typeDoc.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('typeDoc', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'typeDoc'], v))}
              values={Object.values(TypeDoc)}
              name="typeDoc"
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('byCSU', false)}
              label={md.columns.byCSU.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byCSU'], v))}
            />
          </div>
        </ContainerTwoColumn>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.CSU.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('CSU', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'CSU'], v))}
            />
            <StyledLabel>{md.columns.fond.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('fond', new Map())}
              modelType="cat"
              modelName="fondClassifier"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'fond'], v))}
            />
            <StyledLabel>{md.columns.elementKFK.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('elementKFK', new Map())}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'elementKFK'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.elementKEKV.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('elementKEKV', new Map())}
              modelType="cat"
              modelName="elementKEKV"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'elementKEKV'], v))}
            />
            <StyledLabel>{md.columns.docNotes.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('docNotes', new Map())}
              modelType="cat"
              modelName="docNotes"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'docNotes'], v))}
            />
          </div>
        </ContainerTwoColumn>
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportDocFinAnalysisEditor);
