import { useCallback, useContext, useState } from 'react';
import { CiatAppContext, LogicaContext } from '../../../providers';
import api from '../../../api/req';
import md from '../../../meta/srv/ImportPlans';
import checktask from '../../../api/checktask';
// import checkTask from '../../../api/checktask';

const useLogicaPlans = () => {
  // const [dataType, setDataType] = useState(null);
  const [status, setStatus] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [done, setDone] = useState(false);

  const { auth } = useContext(CiatAppContext);
  const { ticket } = useContext(LogicaContext);

  const getStatus = useCallback((y) => {
    const loader = async () => {
      const response = await api.get(md.backendURL, auth, { ticket, year: y });
      if (!response.ok) {
        if (response.status === 400) {
          const errData = await response.json();
          throw new Error(errData.join(', '));
        }
        throw new Error(`${response.status} ${response.statusText}`);
      }
      return response.json();
    };
    setLoading(true);
    setErr(null);
    setDone(false);
    loader()
      .then((data) => {
        setStatus(data);
      })
      .catch((e) => {
        setErr(e.message);
      })
      .finally(() => setLoading(false));
  }, [auth, ticket]);
  const onClearErr = useCallback(
    () => setErr(null),
    [],
  );
  const onClearDone = useCallback(
    () => setDone(false),
    [],
  );
  const onLoad = useCallback(
    (datatype = null) => {
      const loader = async () => {
        const response = await api.post(md.backendURL, auth, { ticket, year, datatype });
        if (!response.ok) {
          if (response.status === 400) {
            const errData = await response.json();
            const errs = errData.reduce((R, e) => [
              ...R,
              ...e.messages || [],
              ...(e.message ? [e.message] : []),
            ], []);
            throw new Error(errs.join(', '));
          }
          throw new Error(`${response.status} ${response.statusText}`);
        }
        return response.json();
      };
      setLoading(true);
      setErr(null);
      setDone(false);
      loader()
        .then((data) => {
          const { task_id: taskId } = data;
          return checktask(taskId, auth);
        })
        .then((d) => {
          if (d.result && d.result.errors) throw new Error(d.result.errors.join(', '));
          setDone(true);
        })
        .catch((e) => {
          setErr(e.message);
        })
        .finally(() => setLoading(false));
    },
    [auth, ticket, year],
  );
  return {
    status,
    loading,
    err,
    onClearErr,
    onClearDone,
    onGetStatus: getStatus,
    done,
    year,
    setYear,
    logicaPingSuccess: true,
    onLoad,
  };
};

export default useLogicaPlans;
