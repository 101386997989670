import { baseReportColumns, baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  KEKVVariant: { label: 'За класифікацією КЕКВ', name: 'выбВариантКЕКВ' },
  USEGeneralCSUForm: { label: 'Використовувати друковані форми фінансового органа', name: 'ПечФормыГлРасп' },
  Symbol0: { label: 'Пусті числові значення показувати як', name: 'СимволПустогоЧисла' },
  ReportVariant: { label: 'Варіант звіту', name: 'флВариантОтчета' },
  AllKEKV: { label: 'За всіма КЕКВ', name: 'флВсеКЕКВ' },
  YearMode: { label: 'Річна', name: 'флГодовая' },
  KDBGroup: { label: 'Групи КДБ', name: 'флГруппыКДБ' },
  KEKVGroup: { label: 'За групами КЕКВ', name: 'флГруппыКЕКВ' },
  KFBGroup: { label: 'Групи КФБ', name: 'флГруппыКФБ' },
  ShowKPKVSvod: { label: 'Показувати зведено за КПКВ', name: 'флПоказыватьСводКФК' },
  KEKVDetails: { label: 'Розшифровка КЕКВ', name: 'флРасшифровкаКЕКВ' },
  ConsolidatedForm: { label: 'Зведена форма', name: 'флСводнаяФорма' },
  InThousandUAH: { label: 'Зведена форма', name: 'флСводнаяФорма' },
  KievForm: { label: 'Форма КМДА', name: 'ФормаКиев' },
  PrintNo: { label: 'Друкувати вн.номер', name: 'флПечатьНомерДок' },
  ElementsKFK: { label: 'За бюджетними програмами', name: 'флЭлементыКФК' },
  KPKVDetails: { label: 'Розшифрувати КПКВ', name: 'флРасшифроватьКФК' },
  KFBKDBGeneralTable: { label: 'КДБ і КФБ з основної таблиці', name: 'флКДБКФБОсновнаяТаблица' },
  DoNotPrintKPKV: { label: 'Не друкувати назву КПКВ', name: 'флНеПечататьКФК' },
  KPKVNameAs: { label: 'Назва КПКВ як', name: 'ПоказыватьКФККак' },
  KVKNameAs: { label: 'Назва КВК як', name: 'ПоказыватьИмяКВККак' },
  Quartal: { label: 'З початку року по', name: 'сзКвартал' },
  DontShowKBPINKEKVHeader: { label: 'Не відображати код КПКВ в шапці КЕКВ', name: 'НеПоказыватьКодКФКВШапкеКЕКВ' },
  ColumnsWidth: { label: 'Ширина колонок (з сумами)', name: 'ШиринаКолонок' },
  DontShowApp: { label: 'Не показувати шапку  "Додаток"', name: 'безДод' },
  PrintKDB: { label: 'Друкувати КДБ', name: 'ПечататьКДБ' },
  PrintKFB: { label: 'Друкувати КФБ', name: 'ПечататьКФБ' },
  Coordination: { label: 'Погодження', name: 'Согласование' },
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  // Дополнительные свойства
  KievFormReadOnly: { label: 'ФормаКиевТолькоПросмотр', name: 'ФормаКиевТолькоПросмотр' },
  KievFormVisible: { label: 'ФормаКиевВидимость', name: 'ФормаКиевВидимость' },
  QuartalVisible: { label: 'сзКварталВидимость', name: 'сзКварталВидимость' },
  CoordinationVisible: { label: 'СогласованиеВидимость', name: 'СогласованиеВидимость' },
  ItChanges: { label: 'ЭтоИзменения', name: 'ЭтоИзменения' },
  ForChangesBlank: { label: 'Номер довідки для друкованої форма як для довідки', name: 'НомерДляСправки' },
  PlanKind: { label: 'Вид плану', name: 'ВидПлана' },
  elementFond: { label: 'Фонд', name: 'ЭлементФонд' },
  Year: { label: 'Рік', name: 'Год' },
  KBP: { label: 'КБП', name: 'КБП' },
  firstNameLastName: { label: 'Прізвище та ім\'я', name: 'флФИ' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  noCop: { label: 'Без десяткових знаків (копійок)', name: 'БезКопеек' },
  wrMounth: { label: 'Місяць прописом', name: 'флМесяцПрописью' },
  tdCSU: { label: 'РК для відправки в ЛОГІКА (використовується для вибору установи у випадку присутності у документі кількох РК. Дані в ЛОГІКА відправляютьмся у розрізі кожного РК окремо)', name: 'ТДРасп' },
  tdKPKMB: { label: 'КПКВМБ для відправки в ЛОГІКА (використовується для вибору КПКВМБ у випадку присутності у документі кількох КПКВМБ. Дані в ЛОГІКА відправляютьмся у розрізі кожного КПКВМБ окремо)', name: 'ТДКПКВМБ' },
  consolid: { label: 'Вивантажувати зведений план', name: 'ВивантажуватиЗведений' },
  noTAX: { label: 'Не друкувати доходи', name: 'НеПечататьДоходы' },
  repCSU: { label: 'Розпорядник для відбору', name: 'РаспорядительОтбор' },
  onPage: { label: 'Помістити на сторінці', name: 'НаСтранице' },
  notShowZeroRows: { label: 'Не показувати нульові рядки КЕКВ в річній формі', name: 'флНЕПоказыватьНулиВГодовом' },
  order938: { label: 'Наказ 938', name: 'флПриказ938' },
  noStars: { label: 'Не показувати примітки внизу форми', name: 'БезЗвездочекПримечаний' },
  onlyOnePage: { label: 'Вписати в сторінку', name: 'УместитьНаСтраницу' },
  printKopekInHat: { label: 'Друк копійок в шапці', name: 'флПечатьКопеекШапка' },
  notPrintKEKVWith0: { label: 'Не друкувати КЕКВ з нульовими річними сумами', name: 'НеПечататьНулевыеКЕКВ' },
};

const tables = {
  data: {
    name: 'ТчДок',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
      Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
  forSending: {
    name: 'ДляОтправки',
    label: 'Для отправки',
    columns: {
      csu: { label: 'Роспорядник', name: 'Расп' },
      KPKVMB: { label: 'КПКВМБ', name: 'КПКВМБ' },
      isSen: { label: 'Відправлено', name: 'Отправлен' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'assigmentPrinting',
  backendName: 'ПечатьАссигнований',
  label: 'Друк',
  frontend: 'dp/assigmentPrinting',
  columns,
  tables,
  resultColumns: baseResultColumns,
};

export default definition;
