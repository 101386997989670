import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

function ExecuteButton({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      variant="success"
      icon={faCheck}
      showLabel
    />
  );
}

ExecuteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ExecuteButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Провести',
};

export default ExecuteButton;
