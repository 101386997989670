export const appearanceTypes = {
  BackColor: {
    label: 'Колір фону',
    type: 'color',
  },
  TextColor: {
    label: 'Колір тексту',
    type: 'color',
  },
  BorderColor: {
    label: 'Колір межі',
    type: 'color',
  },
  BorderStyle: {
    label: 'Стиль межі',
    type: 'line',
  },
  'BorderStyle.Left': {
    label: 'Стиль межі ліворуч',
    type: 'line',
  },
  'BorderStyle.Top': {
    label: 'Стиль межі зверху',
    type: 'line',
  },
  'BorderStyle.Right': {
    label: 'Стиль межі праворуч',
    type: 'line',
  },
  'BorderStyle.Bottom': {
    label: 'Стиль межі знизу',
    type: 'line',
  },
  Font: {
    label: 'Шрифт',
    type: 'font',
  },
  Indent: {
    label: 'Відступ',
    type: 'int',
  },
  AutoIndent: {
    label: 'Автовідступ',
    type: 'int',
  },
  HorizontalAlign: {
    label: 'Горизонтальне положення',
    type: 'horizontalAlign',
  },
  VerticalAlign: {
    label: 'Вертикальне положення',
    type: 'verticalAlign',
  },
  Placement: {
    label: 'Розміщення',
    type: 'placement',
  },
  TextOrientation: {
    label: 'Орієнтація тексту',
    type: 'int',
  },
  Format: {
    label: 'Формат',
    type: 'text',
  },
  MarkNegatives: {
    label: 'Виділяти негативні',
    type: 'bool',
  },
  MinimumWidth: {
    label: 'Мінімальна ширина',
    type: 'int',
  },
  MaximumWidth: {
    label: 'Масимальна ширина',
    type: 'int',
  },
  MinimumHeight: {
    label: 'Мінімальна висота',
    type: 'int',
  },
  MaximumHeight: {
    label: 'Масимальна висота',
    type: 'int',
  },
  Text: {
    label: 'Текст',
    type: 'text',
  },
  MarkIncomplete: {
    label: 'Відмітка незаповнених',
    type: 'bool',
  },
  Visible: {
    label: 'Видимість',
    type: 'bool',
  },
  Enabled: {
    label: 'Доступність',
    type: 'bool',
  },
  ReadOnly: {
    label: 'Тількі перегляд',
    type: 'bool',
  },
  Show: {
    label: 'Відображати',
    type: 'bool',
  },
};
