/* eslint-disable react/no-array-index-key */
import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function App2Row({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  //
  budgetId,
}) {
  const tariffCatProps = tableHooks.useSelectorInputProps('tariff_cat', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Props = tableHooks.useItemInputProps('kbp7', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountRankProps = tableHooks.useNumberInputProps('amount_rank', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactRankProps = tableHooks.useNumberInputProps('amount_fact_rank', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumYearProps = tableHooks.useNumberInputProps('sum_year', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const salaryProps = tableHooks.useNumberInputProps('salary', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const allowancesProps = tableHooks.useNumberInputProps('allowances', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const optionalAllowancesProps = tableHooks.useNumberInputProps('optional_allowances', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const forMinSalaryProps = tableHooks.useNumberInputProps('for_min_salary', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const bonusProps = tableHooks.useNumberInputProps('bonus', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const otherProps = tableHooks.useNumberInputProps('other', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const taxProps = tableHooks.useNumberInputProps('tax', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Filter = useMemo(
    () => ({
      budget: budgetId,
    }),
    [budgetId],
  );
  return (
    <>
      <Col md={2} className="border-right">
        <TabelRowCell column="tariff_cat" highlighted={highlights.includes('tariff_cat')}>
          <TableControls.SelectorInput {...tariffCatProps} />
        </TabelRowCell>
        <TabelRowCell column="budget" highlighted={highlights.includes('budget')}>
          <TableControls.ItemPicker {...kbp7Props} filter={kbp7Filter} />
        </TabelRowCell>
      </Col>
      <Col md={2} className="border-right">
        <TabelRowCell column="amount_rank" highlighted={highlights.includes('amount_rank')}>
          <TableControls.NumberInput {...amountRankProps} />
        </TabelRowCell>
        <TabelRowCell column="amount_fact_rank" highlighted={highlights.includes('amount_fact_rank')}>
          <TableControls.NumberInput {...amountFactRankProps} />
        </TabelRowCell>
      </Col>
      <Col md={2} className="border-right">
        <TabelRowCell column="sum_year" highlighted={highlights.includes('sum_year')}>
          <TableControls.NumberInput {...sumYearProps} />
        </TabelRowCell>
        <TabelRowCell column="salary" highlighted={highlights.includes('salary')}>
          <TableControls.NumberInput {...salaryProps} />
        </TabelRowCell>
      </Col>
      <Col md={2} className="border-right">
        <TabelRowCell column="allowances" highlighted={highlights.includes('allowances')}>
          <TableControls.NumberInput {...allowancesProps} />
        </TabelRowCell>
        <TabelRowCell column="optional_allowances" highlighted={highlights.includes('optional_allowances')}>
          <TableControls.NumberInput {...optionalAllowancesProps} />
        </TabelRowCell>
      </Col>
      <Col md={2} className="border-right">
        <TabelRowCell column="for_min_salary" highlighted={highlights.includes('for_min_salary')}>
          <TableControls.NumberInput {...forMinSalaryProps} />
        </TabelRowCell>
        <TabelRowCell column="bonus" highlighted={highlights.includes('bonus')}>
          <TableControls.NumberInput {...bonusProps} />
        </TabelRowCell>
      </Col>
      <Col md={2} className="border-right">
        <TabelRowCell column="other" highlighted={highlights.includes('other')}>
          <TableControls.NumberInput {...otherProps} />
        </TabelRowCell>
        <TabelRowCell column="tax" highlighted={highlights.includes('tax')}>
          <TableControls.NumberInput {...taxProps} />
        </TabelRowCell>
      </Col>
    </>
  );
}

App2Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  //
  budgetId: PropTypes.number,
};

App2Row.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
  //
  budgetId: null,
};

export default memo(App2Row);
