import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  DivWithPropsLabel,
  CommandPanelColor,
  FrameDiv,
} from '../../../components/Form/styledForm';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import UsersTP from './tablepart/tpUsers';
import UsersCP from './tablepart/cpUsers';
import Tabs from '../../../components/tabs/Tabs';
import { InputWithDropdown } from '../../../components/styledInputs';
import StyledTPPane from '../../../components/tableParts/TPPane';
import DateInput from '../../../components/fields/dates';
import enums from '../../../constants/meta/enums';

const md = meta.dp.messageForUsers;

function MessageForUsers({ headerForm, dispatch }) {
  const recipientsTypes = useMemo(
    () => Object.values(enums.typeMessageRecipients),
    [],
  );
  const messageTypes = useMemo(
    () => Object.values(enums.userMessageTypes),
    [],
  );
  const neverActuality = useCallback(
    () => dispatch(actions.changeField(['headerForm', 'date_until'], null)),
    [dispatch],
  );

  const onChangeRecipients = useCallback(
    (e, v) => {
      dispatch(actions.changeField(['headerForm', 'recipients'], v));
      dispatch(actions.processServerReq('Change_Recipients', { newValue: v }));
    },
    [dispatch],
  );

  const sendSNSHander = useCallback(
    () => dispatch(actions.processServerReq('Send_SNS')),
    [dispatch],
  );
  return (
    <FrameDiv>
      <MyContainerFormWithNColumns
        style={{ alignItems: 'start' }}
        columns={3}
      >
        <div>
          <StyledLabel>{md.columns.recipients.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('recipients')}
            onChange={onChangeRecipients}
            options={recipientsTypes}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.type.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('type', enums.userMessageTypes.info.value)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'type'], v))}
            options={messageTypes}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.date_until.label}</StyledLabel>
          <DateInput
            value={headerForm.get('date_until', null)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'date_until'], v))}
          />
          <CommandPanelColor
            label="Актуально завжди"
            onClick={neverActuality}
          />
        </div>
        <div style={{ gridArea: '2/1/2/4' }}>
          <StyledLabel>{md.columns.message.label}</StyledLabel>
          <Fields.TextAreaField
            value={headerForm.get('message', '')}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'message'], v))}
          />
        </div>
      </MyContainerFormWithNColumns>
      <CommandPanelColor label="Надіслати" onClick={sendSNSHander} />
    </FrameDiv>
  );
}
MessageForUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map).isRequired,
};

const UsersTPRenderer = getTPContainer(UsersTP, getResizableCP(<UsersCP tableName="users" />));

const tablesRender = () => (
  <Tabs>
    <DivWithPropsLabel label={md.tables.users.label}>
      <StyledTPPane>
        <UsersTPRenderer />
      </StyledTPPane>
    </DivWithPropsLabel>
    <DivWithPropsLabel label="0" empty />
  </Tabs>
);

//
// export const mapStateTR = (store) => {
//   const editorNode = store.get('dpEditor', new Map());
//   return {
//     exportMarkedAsPaid: editorNode.getIn(['headerForm', 'exportMarkedAsPaid'], false),
//     exportFormat: editorNode.getIn(['headerForm', 'exportFormat'], 1),
//   };
// };

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: 'messageForUsers',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: tablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(MessageForUsers);
