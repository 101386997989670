import { baseReportColumns } from './_base';

/**
 * @const
 */

const columns = {
  ...baseReportColumns,
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  ReportPeriod: { label: 'Період звіту', name: 'ПериодОтчета' },
  OnlyAssigment: { label: 'Видатки', name: 'ТолькоРасходы' },
  OnlyTax: { label: 'Доходи', name: 'ТолькоДоходы' },
  User: { label: 'Користувач', name: 'Пользователь' },
  FormatFileAssigment: { label: 'Формат файлу вид.', name: 'ФорматФайлаРасходов' },
  FileFI: { label: 'Файл ГФУ', name: 'ФайлГФУ' },
  LoadEstimate: { label: 'Завантажувати кошторисні призначення', name: 'ЗагружатьКошторис' },
  MaskFileTax: { label: 'Маска файлов дох', name: 'МаскаФайловДох' },
  Import2: { label: 'Завантажувати файли FT*.2xx', name: 'Импорт_2' },
  AdressZipFile: { label: 'АдресZipФайла', name: 'АдресZipФайла' },
  AdresspropFile: { label: 'АдресСвойствФайлов', name: 'АдресСвойствФайлов' },
  SelectedName: { label: 'ВыбранноеИмяФайлаНеАрхив', name: 'ВыбранноеИмяФайлаНеАрхив' },
  CatalogFiles: { label: 'Каталог файлов', name: 'КаталогФайлов' },
  CodeBudget: { label: 'ТекКодБюджета', name: 'ТекКодБюджета' },
  CurrentYear: { label: 'Рік', name: 'ТекущийГод' },
  StrGetFileName: { label: 'ПолученоСтрокаИмяФайла', name: 'ПолученоСтрокаИмяФайла' },
  StrGetFile: { label: 'ПолученоСтрокаФайл', name: 'ПолученоСтрокаФайл' },
  PositiveFiles: { label: 'ОбработанныеФайлы', name: 'ОбработанныеФайлы' },
};

const tables = {
  Document: {
    name: 'Документ',
    label: 'Документи',
    columns: {
      Add: { label: 'Імп.', name: 'Добавить' },
      CodeTreas: { label: 'Ном. бюдж.', name: 'КодКазны' },
      Date: { label: 'Дата', name: 'Дата' },
      BudgetD: { label: 'Бюджет', name: 'Бюджет' },
      StateTax: { label: 'Вип. доходи', name: 'ВыпискаДоходы' },
      DocTax: { label: 'Док. доходи (1С)', name: 'ДокументДоходы' },
      StateAssigment: { label: 'Вип. видатки', name: 'ВыпискаРасходы' },
      DocAssigment: { label: 'Док. видатки (1С)', name: 'ДокументРасходы' },
      Date36: { label: 'Дата36', name: 'Дата36' },
    },
  },
  TPTax: {
    name: 'ТЧДоходы',
    label: 'Доходи',
    columns: {
      NomString: { label: 'НомерСтрокиДок', name: 'НомерСтрокиДок' },
      DateTax: { label: 'Дата,DT,D(8)', name: 'Дата' },
      CodeTeritory: { label: 'Код території,KO,C(3)', name: 'КодТеритории' },
      GroupSymbol: { label: 'Група символів,GR,C(1)', name: 'ГруппаСимволов' },
      SymReport: { label: 'Символ звітності,S230,C(3)', name: 'СимволОтчетности' },
      KDBCode: { label: 'Код класифікації за доходами (ККД),KOD,C(8)', name: 'КДБКод' },
      Arrived: { label: 'Надійшло за день,T060,N(16)', name: 'Поступило' },
      ArrivedStartYear: { label: 'Надійшло з початку року,T020,N(16)', name: 'ПоступилоСНачалаГода' },
      StateTaxes: { label: 'Вип. доходи', name: 'ВыпискаДоходы' },
      ArrivedDaySep: { label: 'Надійшло за день розмежування,T060,N(16)', name: 'ПоступилоРозщ' },
      ArrivedStartYearSep: { label: 'Надійшло з початку року(розмежування),T020,N(16)', name: 'ПоступилоСНачалаГодаРозщ' },
    },
  },
  TPAssigment: {
    name: 'ТЧРасходы',
    label: 'Видатки',
    columns: {
      NomOfString: { label: 'НомерСтрокиДок', name: 'НомерСтрокиДок' },
      DateAssig: { label: 'Дата', name: 'Дата' },
      CodeBudget: { label: 'Код бюджету', name: 'КодБюджету' },
      KVKCode: { label: 'Код відомчої класифікації,KVK,С(3)', name: 'КВККод' },
      CodeNet: { label: 'Код розпорядника коштів,KRK,С(5)', name: 'КодМережа' },
      KPKVCode: { label: 'Код тимчасової класифікації видатків місцевих бюджетів,KTFK,C(7)', name: 'КТКВКод' },
      KEKVCode: { label: 'Код за економічною класифікацією видатків та класифікацією кодів кредитування (КЕКВ/ККК),KEKV,C(4)', name: 'КЕКВКод' },
      Razd: { label: 'Розділ,TR,C(2)', name: 'Раздел' },
      FondCode: { label: 'Код фонду,KF,N(2)', name: 'ФондКод' },
      SumExec: { label: 'Сума виконання (виділені асигнування) за звітну дату,VK,N(16)', name: 'СуммаИсполнения' },
      SumExecYear: { label: 'Сума виконання (виділені асигнування) наростаючим підсумком на звітну дату,VKNP,N(16)', name: 'СуммаИсполненияСНачалаГода' },
      SumOst: { label: 'Сума залишку виділених асигнувань на рахунках, які ще не розподілені на звітну дату,ZKNR,N(16)', name: 'СуммаНераспределенногоОстатка' },
      SumCashbox: { label: 'Сума виконання (касові видатки) за звітну дату,KV,N(16)', name: 'СуммаКассовыхРасходов' },
      SumCashboxYear: { label: 'Сума виконання (касові видатки) наростаючим підсумком на звітну дату,KVNP,N(16)', name: 'СуммаКассовыхРасходовСНачалаГода' },
      SumOstFree: { label: 'Сума залишку коштів на реєстраційних рахунках розпорядників коштів на звітну дату,ZKRR,N(16)', name: 'СуммаОстатка' },
      SumComm: { label: 'Зареєстровані фінансові зобов’язання  на звітну дату,ZFZ,N(16)', name: 'СуммаФинОбязательств' },
      StateAssig: { label: 'Вип. видатки', name: 'ВыпискаРасходы' },
      Est: { label: 'Кошторисні призначення', name: 'КП' },
    },
  },
  CurrKVK: {
    name: 'СоотвКВК',
    label: 'СоотвКВК',
    columns: {
      KVKc: { label: 'КВККод', name: 'КВККод' },
      KVK: { label: 'КВК', name: 'КВК' },
    },
  },
  CurrKDB: {
    name: 'СоотвКДБ',
    label: 'СоотвКДБ',
    columns: {
      KDBc: { label: 'КДБКод', name: 'КДБКод' },
      KDB: { label: 'КДБ', name: 'КДБ' },
    },
  },
  CurrKEKV: {
    name: 'СоотвКЕКВ',
    label: 'СоотвКЕКВ',
    columns: {
      KEKVc: { label: 'КЕКВКод', name: 'КЕКВКод' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
    },
  },
  CurrKFK: {
    name: 'СоотвКФК',
    label: 'СоотвКФК',
    columns: {
      KFKc: { label: 'КФККод', name: 'КФККод' },
      KFK: { label: 'КФК', name: 'КФК' },
    },
  },
  CurrCSU: {
    name: 'СоотвРаспорядители',
    label: 'СоотвРаспорядители',
    columns: {
      CSU: { label: 'Распорядитель', name: 'Распорядитель' },
      BudgetCSU: { label: 'Бюджет', name: 'Бюджет' },
      CodeNetCSU: { label: 'КодМережа', name: 'КодМережа' },
    },
  },
  CurrFI: {
    name: 'СоотвФО',
    label: 'СоотвФО',
    columns: {
      CSUFI: { label: 'Распорядитель', name: 'Распорядитель' },
      KVKcFI: { label: 'КВККод', name: 'КВККод' },
      BudgetFI: { label: 'Бюджет', name: 'Бюджет' },
      KVK: { label: 'КВК', name: 'КВК' },
    },
  },
  CurrFond: {
    name: 'СоотвФонды',
    label: 'СоотвФонды',
    columns: {
      Fondc: { label: 'ФондКод', name: 'ФондКод' },
      Fond: { label: 'Фонд', name: 'Фонд' },
    },
  },
  CurrFondTax: {
    name: 'СоотвФондыДоходы',
    label: 'СоотвФондыДоходы',
    columns: {
      FondcTax: { label: 'ФондКод', name: 'ФондКод' },
      FondTax: { label: 'Фонд', name: 'Фонд' },
    },
  },
  CurrElementKDB: {
    name: 'СоотвЭлементыКДБ',
    label: 'СоотвЭлементыКДБ',
    columns: {
      FIElKDB: { label: 'ФинансовыйОрган', name: 'ФинансовыйОрган' },
      BudgetFIKDB: { label: 'Бюджет', name: 'Бюджет' },
      ElKDBCode: { label: 'КДБКод', name: 'КДБКод' },
      KDBElKDB: { label: 'КДБ', name: 'КДБ' },
      ElKDBElKDB: { label: 'ЭлементКДБ', name: 'ЭлементКДБ' },
    },
  },
  TPState: {
    name: 'ТЗВыписки',
    label: 'ТЗВыписки',
    columns: {
      BudgetState: { label: 'Бюджет', name: 'Бюджет' },
      DateState: { label: 'Дата', name: 'Дата' },
      StateTaxState: { label: 'Виписка доходи', name: 'ВыпискаДоходы' },
      StateAssigState: { label: 'Виписка видатки', name: 'ВыпискаРасходы' },
      AddState: { label: 'Добавить', name: 'Добавить' },
      CodeBudgTreasState: { label: 'Код бюджету казна', name: 'КодБюджетаКазна' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'importTreasFiles',
  backendName: 'ИмпортКазначейскихФайлов',
  frontend: 'dp/importTreasFiles',
  label: 'Завантаження казначейських файлів',
  columns,
  tables,
};

export default definition;
