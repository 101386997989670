import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  total,
  activeCol,
  highlights,
  genTotal,
  specTotal,
  specTotal1000,
  specTotal1110,
  specTotal1160,
  specTotal2000,
  genTotal1000,
  genTotal1110,
  genTotal1160,
  genTotal2000,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col className="px-1">
        <Row>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF1000'}
              highlighted={highlights.includes('sumGenF1000')}
            >
              {genTotal1000.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF1110'}
              highlighted={highlights.includes('sumGenF1110')}
            >
              {genTotal1110.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF1160'}
              highlighted={highlights.includes('sumGenF1160')}
            >
              {genTotal1160.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF2000'}
              highlighted={highlights.includes('sumGenF2000')}
            >
              {genTotal2000.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF'}
              highlighted={highlights.includes('sumGenF')}
            >
              {genTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF1000'}
              highlighted={highlights.includes('sumSpecF1000')}
            >
              {specTotal1000.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF1110'}
              highlighted={highlights.includes('sumSpecF1110')}
            >
              {specTotal1110.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF1160'}
              highlighted={highlights.includes('sumSpecF1160')}
            >
              {specTotal1160.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF2000'}
              highlighted={highlights.includes('sumSpecF2000')}
            >
              {specTotal2000.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF'}
              highlighted={highlights.includes('sumSpecF')}
            >
              {specTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
            >
              {total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
  specTotal: PropTypes.number.isRequired,
  genTotal: PropTypes.number.isRequired,
  specTotal1000: PropTypes.number.isRequired,
  specTotal1110: PropTypes.number.isRequired,
  specTotal1160: PropTypes.number.isRequired,
  specTotal2000: PropTypes.number.isRequired,
  genTotal1000: PropTypes.number.isRequired,
  genTotal1110: PropTypes.number.isRequired,
  genTotal1160: PropTypes.number.isRequired,
  genTotal2000: PropTypes.number.isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
