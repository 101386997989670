import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: meta.cat.conrtragent.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const fullNameProps = editorHooks.useStringInputProps('fullname', data, fields, fieldErrors, actions.onChange);
  const edrpouNameProps = editorHooks.useStringInputProps('edrpou', data, fields, fieldErrors, actions.onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, fieldErrors, actions.onChange);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.conrtragent.backendURL}
        />
      )}
    >
      <LabelTab defaultActiveKey="conrtragent" id="uncontrolled-tab-example">
        <Tab eventKey="conrtragent" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col md={4}>
                  <EditorControls.StringInput {...edrpouNameProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.TextInput {...fullNameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...parentProps} />
                </Col>
                <Col>
                  <EditorControls.CheckboxInput {...isGroupProps} />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
