import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { List } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import TableComponents from './Docs';
import TableComponents2 from './Contents';
import CPDocs from './cpDocs';
import StyledTPPane from '../../../../components/tableParts/TPPane';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'docsTable';
const TABLENAME2 = 'documentNotes';

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  content: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME2}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const T1 = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
  TableComponents.FooterComponent,
);
const T2 = getBaseTP(TABLENAME2, TableComponents2.RowComponent, TableComponents2.HeaderComponent);

const Divider = styled.div`
  display: flex;
  width: 2px;
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  margin: 0 5px;
`;

const StyledContainer = styled(StyledTPPane)`
  flex-direction: column;
  border: 1px solid #DDE2E9;
  height: calc(100vh - 2em);
  overflow: hidden;
`;

const StyledGrid = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow:hidden;
  //height: 100%;
`;

export const ContT1 = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const ContT2 = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

function TC() {
  const { data, content } = useSelector(mapState);
  const fData = useMemo(
    () => data.filter((row) => row.get('IS_ACTIVE', false)).reduce((v, row) => v.set(row.getIn(['Document', 'id']), 'true'), new Map()),
    [data],
  );
  const filteredContent = useMemo(
    () => content.filter((cRow) => fData.has(cRow.getIn(['Document', 'id']))),
    [content, fData],
  );
  return (
    <StyledContainer>
      <CPDocs tableName={TABLENAME} />
      <StyledGrid>
        <ContT1>
          <T1 data={data} actionSource="dpEditor" />
        </ContT1>
        <Divider />
        <ContT2>
          <T2
            data={filteredContent}
            actionSource="dpEditor"
          />
        </ContT2>
      </StyledGrid>
    </StyledContainer>
  );
}

export default connect(mapState)(TC);
