import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const BasedGridNew = styled(BasedGrid)`
  grid-template-columns: 10% 1fr;
`;

const generateNewGrid = () => styled(BasedGridNew)`
      grid-template-areas:  "NumDoc DocumentPresent";
    `;

class GridTemplate extends Component {
  static propTypes = {
    CSUOnHead: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    isHeader: PropTypes.bool,
  };

  static defaultProps = {
    CSUOnHead: false,
    children: null,
    isHeader: false,
  };

  constructor(props) {
    super(props);
    this.state = { GridTemplate: generateNewGrid(props) };
  }

  shouldComponentUpdate(nextProps) {
    const { CSUOnHead } = this.props;
    if (nextProps.CSUOnHead !== CSUOnHead) {
      this.setState({ GridTemplate: generateNewGrid(nextProps) });
    }
    return true;
  }

  render() {
    const {
      children, isHeader,
    } = this.props;

    const Grid = this.state.GridTemplate;
    return (
      <Grid isHeader={isHeader}>
        {children}
      </Grid>
    );
  }
}

export default GridTemplate;
