import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  kbpClassifier: { label: 'КФК', name: 'КлассификаторКФК', visible: true },
  fillFromKazn: { label: 'Заповнити за даними казначейства', name: 'флЗаполнитьПоДаннымКазны', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  typePlan: { label: 'Вид плану', name: 'ВидПлана' },
  shemeCalc: { label: 'Схема розрахунку', name: 'ТипРасчета', visible: false },
  pass: { label: 'ВыбПуть', name: 'ВыбПуть', visible: false },
  typeRep: { label: 'Тип звітності', name: 'Отчетность', visible: false },
  codeFile: { label: 'Код файл срцвыплаты', name: 'КодФайлСрцвыплаты', visible: false },
  accMonth: { label: 'Фактично нараховано за місяць і загальний обсяг фінансування це профінасовано + залишки на особових рахунках) - для 2111 та  2120, що передує даті звітності', visible: false },
  planMonth: { label: 'План мес николаев', name: 'ПланМес_Николаев', visible: false },
  dontRound: { label: 'Не заокруглювати суми до гривні', name: 'НеОкруглять_Николаев', visible: false },
  recountFactMonth: { label: 'Перерахунок поля -звітного періоду-  при зміні погашення заборгованості', name: 'ПересчетФактМес', visible: false },
  factMonth: { label: 'Фактично нараховано за місяць це зареєстровано на кінець + загальний обсяг фінансування - зареєстровані на початок   - для 2111 та  2120, що передує даті звітності (м.Вінниця)', name: 'ФактМесИзНачислено', visible: false },
  planMinusKassa: { label: 'Поточні видатки на місяць = Плани на період - касові видатки на початок місяця', name: 'ПланыМинусКасса', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основная',
    label: 'Основне',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      kfkClassifier: { label: 'КФК', name: 'КлассификаторКФК' },
      kbpClassifier: { label: 'КБП', name: 'КлассификаторКБП' },
      socialPayment: { label: 'Показник', name: 'СоцВыплаты' },
      deciphering: { label: 'Розшифровка', name: 'СтатьиДолгСоцВыпл' },
      deptStartMonthTotal1: { label: 'Заборгованість на початок місяця', name: 'ДолгНачМесИт1' },
      deptStartMonthTotal: { label: 'Всього', name: 'ДолгНачМесИт' },
      deptStartMonth: { label: 'в т.ч. по виплатах термін яких пройшов', name: 'ДолгНачМес' },
      planMonth: { label: 'Поточні видатки на місяць, передбачені у бюджеті', name: 'ПланМес' },
      accrualsMonth: { label: 'Фактично нараховано за місяць', name: 'НачисленоМес' },
      factMonthTotal: { label: 'Загальний обсяг фінансування з початку місяця', name: 'ФактМесВсего' },
      factMonth: { label: 'Звітного періоду', name: 'ФактМес' },
      factMonthDept: { label: 'Погашення заборгованостей минулих періодів', name: 'ФактМесДолг' },
      factMonthTotalTotal: { label: 'Всього', name: 'ФактМесВсегоИг' },
      factMonthSFTotal: { label: 'Спрямування коштів із спец.фонду', name: 'ФактМесСФВсего' },
      factMonthSF: { label: 'Звітного періоду', name: 'ФактМесСФ' },
      factMonthDeptSF: { label: 'Погашення боргу минулих періодів', name: 'ФактМесДолгСФ' },
      factDeptOnDateTotal: { label: 'Заборгованість на звітну дату  за фактичними нарахуваннями', name: 'ФактДолгНаДатуИт' },
      factDeptOnDate: { label: 'Всього', name: 'ФактДолгНаДату' },
      overdue: { label: 'у т.ч. за виплатами термін яких пройшов', name: 'ПросроченыйДолг' },
      primSocialPayment: { label: 'Примітка', name: 'ПримСоцВыплат' },
      codeKBP: { label: 'Код КБП', name: 'КодКБП' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Стан фінансування соціальних виплат із місцевих бюджетів',
  name: 'socialPayments',
  backendName: 'СоцВыплаты',
  columns,
  frontend: 'doc/socialPayments',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'kbpClassifier',
      'fillFromKazn',
      'FI',
      'budget',
      'author',
      'typePlan',
      'shemeCalc',
      'pass',
      'typeRep',
      'codeFile',
      'accMonth',
      'planMonth',
      'dontRound',
      'recountFactMonth',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
