import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'Период' },
  settingsId: { label: 'ИД налаштувань', name: 'ИДНастройки' },
};

const tables = {
  journal: {
    name: 'ЖурналРегистрации',
    label: 'ЖурналРегистрации',
    columns: {
      Date: { label: 'Дата', name: 'Дата' },
      UserName: { label: 'Им\'я користувача', name: 'ИмяПользователя' },
      EventPresent: { label: 'Подання події', name: 'ПредставлениеСобытия' },
      Data: { label: 'Дані', name: 'Данные' },
      DataPresent: { label: 'Подання даних', name: 'ПредставлениеДанных' },
      Notes: { label: 'Коментар', name: 'Комментарий' },
    },
  },
};

const definition = {
  label: 'Журнал реєстрації',
  name: 'UsersMonitor',
  columns,
  tables,
  frontend: 'rep/UsersMonitor',
  backendName: 'ЖурналРегистрации',
  resultColumns: baseResultColumns,
};

export default definition;
