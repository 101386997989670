import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноза', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  csu: {
    label: 'Розпорядник', name: 'Распорядитель', visible: true,
  },
  showKDB: {
    label: 'Вносити спецфонд в розрізі КДБ', name: 'флВидимостьКДБ', visible: false,
  },
  ZFsum0: { label: 'ЗФСумма0', name: 'ЗФСумма0', visible: false },
  ZFsum1: { label: 'ЗФСумма1', name: 'ЗФСумма1', visible: false },
  ZFsum2: { label: 'ЗФСумма2', name: 'ЗФСумма2', visible: false },
  SFsum0: { label: 'СФСумма0', name: 'СФСумма0', visible: false },
  SFsum1: { label: 'СФСумма1', name: 'СФСумма1', visible: false },
  SFsum2: { label: 'СФСумма2', name: 'СФСумма2', visible: false },
  Ksum0: { label: 'КСумма0', name: 'КСумма0', visible: false },
  Ksum1: { label: 'КСумма1', name: 'КСумма1', visible: false },
  Ksum2: { label: 'КСумма2', name: 'КСумма2', visible: false },
};

/**
 * @const
 */
const tables = {
  ZF: {
    name: 'ЗФ',
    label: 'Загальний фонд',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
    },
  },
  SF: {
    name: 'СФ',
    label: 'Спеціальний фонд',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
    },
  },
  credit: {
    name: 'Кредити',
    label: 'Надання кредитів',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sumSF0: { label: 'СуммаСФ0', name: 'СуммаСФ0' },
      sumSF1: { label: 'СуммаСФ1', name: 'СуммаСФ1' },
      sumSF2: { label: 'СуммаСФ2', name: 'СуммаСФ2' },
    },
  },
  trans: {
    name: 'Тренсферты',
    label: 'Міжбюджетні трансферти',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
      sum0SF: { label: 'Сумма0', name: 'Сумма0СФ' },
      sum1SF: { label: 'Сумма1', name: 'Сумма1СФ' },
      sum2SF: { label: 'Сумма2', name: 'Сумма2СФ' },
    },
  },
  SFKDB: {
    name: 'СФКДБ',
    label: 'Спеціальний фонд в т.ч. доходи',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
    },
  },
  SFKFB: {
    name: 'СФКФБ',
    label: 'Спеціальний фонд в т.ч. фінансування',
    columns: {
      code: { label: 'Найменування показника', name: 'Код' },
      name: { label: 'Код', name: 'НаименованиеПоказателя' },
      sum0: { label: 'Сумма0', name: 'Сумма0' },
      sum1: { label: 'Сумма1', name: 'Сумма1' },
      sum2: { label: 'Сумма2', name: 'Сумма2' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Орієнтовні граничні показники (Додаток 1)',
  name: 'prepareForecastAdd1',
  backendName: 'ПрогнозДодП1',
  columns,
  frontend: 'doc/prepareForecastAdd1',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'csu',
      'appr',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
