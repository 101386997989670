/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Radio from '../controls/radio';

function EditorRadio({
  controlId, label, required, errors, description, ...restProps
}) {
  const controlRef = useRef(null);
  return (
    <Form.Group className="alwaysFloated" controlId={controlId}>
      {label && (
        <Form.Label>
          {label}
          {required && (<span className="text-danger">*</span>)}
        </Form.Label>
      )}
      <Radio
        {...restProps}
        ref={controlRef}
        errored={errors && !!errors.length}
      />
      {!!errors && (
        <Form.Control.Feedback type="invalid">
          {String(errors)}
        </Form.Control.Feedback>
      )}
      {description && (
        <Form.Text className="text-muted">
          {description}
        </Form.Text>
      )}
    </Form.Group>
  );
}

EditorRadio.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
};

EditorRadio.defaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
};

export default EditorRadio;
