import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  activeCol, highlights, onToggleHighlight, year, totals,
}) {
  const yearDef = year || new Date().getFullYear();
  const year1 = `${yearDef - 1}p.`;
  const year2 = `${yearDef - 2}p.`;

  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
        <Row sm={12} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'group'}
              highlighted={highlights.includes('group')}
              onToggleHighlight={() => onToggleHighlight('group')}
            >
              Група
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'structure_of_labor_cost'}
              highlighted={highlights.includes('structure_of_labor_cost')}
              onToggleHighlight={() => onToggleHighlight('structure_of_labor_cost')}
            >
              Структура оплати
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_2'}
              highlighted={highlights.includes('amount_zf_2')}
              onToggleHighlight={() => onToggleHighlight('amount_zf_2')}
              title={totals.amount_zf_2.toLocaleString('uk', moneysStingOptions)}
            >
              Загальний фонд
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_2'}
              highlighted={highlights.includes('amount_sf_2')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_2')}
              title={totals.amount_sf_2.toLocaleString('uk', moneysStingOptions)}
            >
              Спеціальний фонд
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_br_total_2'}
              highlighted={highlights.includes('amount_br_total_2')}
              onToggleHighlight={() => onToggleHighlight('amount_br_total_2')}
              title={totals.amount_br_total_2.toLocaleString('uk', moneysStingOptions)}
            >
              в т.ч. БР
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_1'}
              highlighted={highlights.includes('amount_zf_1')}
              onToggleHighlight={() => onToggleHighlight('amount_zf_1')}
              title={totals.amount_zf_1.toLocaleString('uk', moneysStingOptions)}
            >
              Загальний фонд
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_1'}
              highlighted={highlights.includes('amount_sf_1')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_1')}
              title={totals.amount_sf_1.toLocaleString('uk', moneysStingOptions)}
            >
              Спеціальний фонд
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_br_total_1'}
              highlighted={highlights.includes('amount_br_total_1')}
              onToggleHighlight={() => onToggleHighlight('amount_br_total_1')}
              title={totals.amount_br_total_1.toLocaleString('uk', moneysStingOptions)}
            >
              в т.ч. БР
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf0'}
              highlighted={highlights.includes('amount_zf0')}
              onToggleHighlight={() => onToggleHighlight('amount_zf0')}
              title={totals.amount_zf0.toLocaleString('uk', moneysStingOptions)}
            >
              Загальний фонд
              {' '}
              {yearDef}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf0'}
              highlighted={highlights.includes('amount_sf0')}
              onToggleHighlight={() => onToggleHighlight('amount_sf0')}
              title={totals.amount_sf0.toLocaleString('uk', moneysStingOptions)}
            >
              Спеціальний фонд
              {' '}
              {yearDef}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_br_total0'}
              highlighted={highlights.includes('amount_br_total0')}
              onToggleHighlight={() => onToggleHighlight('amount_br_total0')}
              title={totals.amount_br_total0.toLocaleString('uk', moneysStingOptions)}
            >
              в т.ч. БР
              {' '}
              {yearDef}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total_2'}
              highlighted={highlights.includes('amount_total_2')}
              onToggleHighlight={() => onToggleHighlight('amount_total_2')}
              title={totals.amount_total_2.toLocaleString('uk', moneysStingOptions)}
            >
              Сума
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total1'}
              highlighted={highlights.includes('amount_total1')}
              onToggleHighlight={() => onToggleHighlight('amount_total1')}
              title={totals.amount_total_1.toLocaleString('uk', moneysStingOptions)}
            >
              Сума
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total0'}
              highlighted={highlights.includes('amount_total0')}
              onToggleHighlight={() => onToggleHighlight('amount_total0')}
              title={totals.amount_total0.toLocaleString('uk', moneysStingOptions)}
            >
              Сума
              {' '}
              {yearDef}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_zf0: PropTypes.number,
    amount_zf_1: PropTypes.number,
    amount_zf_2: PropTypes.number,
    amount_br_total0: PropTypes.number,
    amount_br_total_1: PropTypes.number,
    amount_br_total_2: PropTypes.number,
    amount_sf0: PropTypes.number,
    amount_sf_1: PropTypes.number,
    amount_sf_2: PropTypes.number,
    amount_total0: PropTypes.number,
    amount_total_1: PropTypes.number,
    amount_total_2: PropTypes.number,
  }).isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
