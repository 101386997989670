import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Fields from '../../../../field';
import GridTemplate from './GridTemplate';
import { DivCell } from '../../../../../components/styledTable/styledTable';

const SumInput = Fields.GetNumberInput(13, 2);
const TextInput0 = Fields.GetTextInput();

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    // rowId: PropTypes.number.isRequired,
    // dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row,
      // rowId, dispatch,
    } = this.props;
    return (
      <GridTemplate>
        <DivCell areaName="csu">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('CSU', new Map())}
          />
        </DivCell>
        <DivCell areaName="eKFK">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('ElementKFK', '')}
          />
        </DivCell>
        <DivCell areaName="eKEKV">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('ElementKEKV', '')}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInput
            disabled
            value={row.get('Sum', 0)}
          />
        </DivCell>
        <DivCell areaName="notes">
          <TextInput0
            disabled
            noBorder
            textLeft
            value={row.get('Notes', '')}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
