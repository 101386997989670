import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  reportType: { label: 'Вариант отчета', name: 'ВариантОтчета' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  endDate: { label: 'станом на:', name: 'КонДата' },
  signVariant: { label: 'Підписи', name: 'Подписть' },
  tYear: { label: 'Рік', name: 'тГод' },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  incDB: { label: 'В т.ч. бюджет розвитку', name: 'флБР' },
  yearSum: { label: 'річні суми', name: 'флГодовыеСуммы' },
  details: { label: 'Деталізація', name: 'флДетализация' },
  consolidated: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  fund: { label: 'Фонд', name: 'Фонд' },
  elementFnd: { label: 'Складова фонду', name: 'ЭлементФонд' },
  budgetInTable: { label: 'Показувати в таблиці назву бюджету ', name: 'флБюджетВТаблице' },
  separatedBudget: { label: 'У розрізі бюджетів', name: 'флРазрезБюджет' },
  byDocuments: { label: 'Деталізувати звіт до документу', name: 'флДоДокумента' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  isShowCredit: { label: 'Кредитування окремим рядком', name: 'флВыводитьКредитование' },
  isNewTypeSign: { label: '', name: 'флИмяФамилия' },
};

const definition = {
  label: 'Баланс',
  name: 'Balans',
  columns,
  frontend: 'rep/Balans',
  backendName: 'Баланс',
  resultColumns: baseResultColumns,
};

export default definition;
