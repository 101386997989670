import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import {
  Tabl10TP,
  Tabl20TP,
  Tabl30TP,
  Tabl40TP,
  Tabl50TP,
  Tabl60TP,
  Tabl70TP,
  Tabl80TP,
  Tabl90TP,
  Tabl100TP,
  Tabl110TP,
} from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/taxes';
// import LowerDocs from './tabs/docs';

function TaxesTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="tabl2" className="m-0 mt-2 border-bottom">
      <Tab title="Додаток 2" eventKey="tabl2">
        <Row>
          <Col>
            <Tabl20TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd2.label}
              value={data[md.columns.noteAdd2.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd2.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 3 (стар. 2П)" eventKey="tabl3">
        <Row>
          <Col>
            <Tabl30TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd2P.label}
              value={data[md.columns.noteAdd2P.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd2P.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 4 (стар. 3)" eventKey="tabl4">
        <Row>
          <Col>
            <Tabl40TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd3.label}
              value={data[md.columns.noteAdd3.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd3.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 5 (стар. 3П)" eventKey="tabl5">
        <Row>
          <Col>
            <Tabl50TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd3P.label}
              value={data[md.columns.noteAdd3P.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd3P.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 6 (стар. 4)" eventKey="tabl6">
        <Row>
          <Col>
            <Tabl60TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd4.label}
              value={data[md.columns.noteAdd4.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd4.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 7 (стар. 5)" eventKey="tabl7">
        <Row>
          <Col>
            <Tabl70TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd5.label}
              value={data[md.columns.noteAdd5.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd5.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 8 (стар. 6)" eventKey="tabl8">
        <Row>
          <Col>
            <Tabl80TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd6.label}
              value={data[md.columns.noteAdd6.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd6.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 9 (стар. 7)" eventKey="tabl9">
        <Row>
          <Col>
            <Tabl90TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd7.label}
              value={data[md.columns.noteAdd7.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd7.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 10 (стар. 8)" eventKey="tabl10">
        <Row>
          <Col>
            <Tabl100TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd8.label}
              value={data[md.columns.noteAdd8.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd8.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 11" eventKey="tabl11">
        <Row>
          <Col>
            <Tabl110TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd11.label}
              value={data[md.columns.noteAdd11.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd11.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      <Tab title="Додаток 1 (Зведений)" eventKey="tabl1" tabClassName="font-weight-bold">
        <Row>
          <Col>
            <Tabl10TP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.noteAdd1.label}
              value={data[md.columns.noteAdd1.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.noteAdd1.name]: value,
                });
              }}
              readOnly={readOnly}
              canErase
            />
          </Col>
        </Row>
      </Tab>
      {/* <Tab title={md.tables.descendantsDocuments.label} eventKey="docs"> */}
      {/*  <LowerDocs data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
    </Tabs>
  );
}

TaxesTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default TaxesTabs;
