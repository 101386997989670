import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const BasedGridNew = styled(BasedGrid)`
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 15%;
`;

const generateNewGrid = () => styled(BasedGridNew)`
  grid-template-areas:  "selected  fond";
`;

class GridTemplate extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    isHeader: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    isHeader: false,
  };

  constructor(props) {
    super(props);
    this.state = { GridTemplate: generateNewGrid(props) };
  }

  render() {
    const {
      children, isHeader,
    } = this.props;

    const Grid = this.state.GridTemplate;
    return (
      <Grid isHeader={isHeader}>
        {children}
      </Grid>
    );
  }
}

export default GridTemplate;
