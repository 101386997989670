import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fillTable } from '../../../../actions/dpEditor';
import CommandPanelButton from '../../../../components/button/commandPanelButton';
import CheckAllIcon from '../../../../assets/icons/checkall';
import UnChekAllIcon from '../../../../assets/icons/uncheckall';

export const ContainerButton = styled.div`
  margin: 10px 0;
`;

function DocsCP({ disabled, tableName, dispatch }) {
  return (
    <ContainerButton>
      <CommandPanelButton
        text="Відмітити всі"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['Check'], true))}
      >
        <CheckAllIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Зняти відмітки"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['Check'], false))}
      >
        <UnChekAllIcon />
      </CommandPanelButton>
    </ContainerButton>
  );
}

DocsCP.propTypes = {
  tableName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

DocsCP.defaultProps = {
  disabled: false,
};

export default connect()(DocsCP);
