const frontendURL = '/cat/structureoflaborcosts/';
const backendURL = '/api/references/refstructureoflaborcosts/';
const name = 'Структура видатків на оплату праці';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
