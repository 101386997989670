import base from './base';

/**
 * @const
 */

const columns = {
  ...base.columns,
  code: { label: 'Код об\'єкта будівництва', name: 'Код', visible: true },
  constructionObject: { label: 'Об\'єкт будівництва', name: 'ОбъектСтроительства', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  // objBuildingSourceFin: { label: 'Джерело фінансування', name: 'ИсточникФинансирования', visible: true },

  sumEstimates: { label: 'Кошторисна вартість', name: 'СуммаСмета', visible: true },
  sumByear: { label: 'Виконано на 01.01.2018', name: 'ВыполненоНаНачалоГода', visible: true },
  sumPlan: { label: 'Запланований обсяг видатків на 2018 рік', name: 'СуммаПлан', visible: true },
  sumDebt: { label: 'з них заборгованість за виконані роботи', name: 'СуммаДолг', visible: true },

  sumFact: { label: 'Виконано робіт станом на 01.01.2018', name: 'СуммаФакт', visible: true },
  sumFinanced: { label: 'Профінансовано робіт станом на 01.01.2018', name: 'СуммаПрофинансировано', visible: true },
  sumFinancedDebt: { label: 'Профінансована заборгованість', name: 'СуммаПрофинансированоДолг', visible: true },

  sumCashByear: { label: 'всього з початку року', name: 'СуммаКассаНачГода', visible: true },
  sumCashFyear: { label: 'в т.ч. за звітний період', name: 'СуммаКасаЗаПериод', visible: true },
  sumCashBalance: { label: 'Залишок коштів (+), борг (-) на кінець звітного періоду (гр.9+гр.10-гр.7-гр.8)', name: 'СуммаОстатокКонПериода', visible: true },

  note: { label: 'Стан виконання робіт', name: 'Примечание', visible: true },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ПВР',
    label: 'у т.ч.ПВР',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      PVR: { label: 'ПВР', name: 'ПВР' },
      // objBuildingSourceFin: { label: 'Джерело фінансування', name: 'ИсточникФинансирования' },
      sumF: { label: 'Сума фінансування', name: 'СуммаФин' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Фінансування об\'єктів ремонту',
  name: 'financingRepairObjects',
  backendName: 'ЗаполнениеФинансированияОбъектовРеконструкции',
  columns,
  frontend: 'doc/financingRepairObjects',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'code',
      'constructionObject',
      'budget',
      'FI',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
