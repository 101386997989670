import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { AddRowButton, RemoveRowButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { fieldPropType, metaFieldPropType } from '../hooks';
import MetaFieldItem from './item';
import MetaFieldProps from './props';

function MetaFieldsPage({
  metaFields, activeMetaField, onRenameField, onChangeField,
  onAddField, onRemoveField, onSetActiveField, allowedCtypes, fields, allowedFonts,
  onMoveUp, onMoveDown, systemFields,
}) {
  const displayMetaFields = useMemo(() => metaFields.map((t, itemKey) => ({
    ...t,
    itemKey,
  })), [metaFields]);
  const fValue = useMemo(
    () => metaFields.filter((t) => t.name === activeMetaField).reduce((R, f) => f, {}),
    [activeMetaField, metaFields],
  );
  // const alowedTables = useMemo(
  //   () => Object.keys(tableFields).map((value) => ({ value, display_name: value })),
  //   [tableFields],
  // );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({ value, display_name: value })),
    [allowedCtypes],
  );
  const allowedFields = useMemo(
    () => [
      ...fields.map((f) => ({
        value: f.name,
        display_name: f.name,
      })),
      ...Object.keys(systemFields).map((s) => ({
        value: s,
        display_name: `${systemFields[s].label} (${s})`,
      })),
    ],
    [fields, systemFields],
  );

  const allowedMetaFields = useMemo(
    () => metaFields.map((f) => ({ value: f.name, display_name: f.name })),
    [metaFields],
  );
  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3">
          <CommandPanel leftPart={(
            <>
              <AddRowButton
                title="Додати поле"
                content=""
                onClick={() => onAddField(Math.random().toString(36))}
              />
              <RemoveRowButton
                title="Видалити поле"
                content=""
                onClick={() => onRemoveField(activeMetaField)}
                disabled={!activeMetaField}
              />
            </>
          )}
          />
          <ListGroup className="containerGroup">
            {displayMetaFields.map((metaField, idx) => (
              <MetaFieldItem
                key={metaField.itemKey}
                active={metaField.name === activeMetaField}
                onClick={() => onSetActiveField(metaField.name)}
                metaField={metaField}
                onRenameField={onRenameField}
                onMoveUp={() => onMoveUp(metaField.name)}
                onMoveDown={() => onMoveDown(metaField.name)}
                canUp={idx > 0}
                canDown={idx < (displayMetaFields.length - 1)}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <MetaFieldProps
            onChange={(v) => onChangeField(activeMetaField, v)}
            value={fValue}
            readOnly={!('name' in fValue)}
            allowedCtypes={displayAlowedCtypes}
            allowedFields={allowedFields}
            allowedFonts={allowedFonts}
            allowedMetaFields={allowedMetaFields}
          />
        </Col>
      </Row>
    </Container>
  );
}

MetaFieldsPage.propTypes = {
  metaFields: PropTypes.arrayOf(metaFieldPropType).isRequired,
  systemFields: PropTypes.shape({}),
  activeMetaField: PropTypes.string,
  onRenameField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onSetActiveField: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
};

MetaFieldsPage.defaultProps = {
  activeMetaField: null,
  allowedCtypes: [],
  allowedFonts: [],
  systemFields: {},
};

export default memo(MetaFieldsPage);
