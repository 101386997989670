import React from 'react';
import { Card } from 'react-bootstrap';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import AccountsTable from './AccountsTable';

const dataAccounts = [
  {
    id: 1,
    name: 'Котловий',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 90,
  },
  {
    id: 2,
    name: 'Котловий',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 30,
  },
  {
    id: 3,
    name: 'СУБВ з ДБ "Освітня субвенція" (41033900)',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 70,
  },
  {
    id: 4,
    name: 'Субв з ДБ на буд, придб.житла для ПЕРЕМІЩЕНІ.осіб',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 50,
  }, {
    id: 5,
    name: 'ДОТАЦІЯ Комп-цiя втрат дох. внаслiдок пiльг зi спл зем',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 40,
  }, {
    id: 6,
    name: 'СУБВ з ДБ на зд. доплат з фонду COVID-19 (41031900)',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 60,
  }, {
    id: 7,
    name: 'СУБВ з ДБ на проведення виборів (41037000)',
    value: 13422000,
    plan: 2600,
    fact: 3523,
    progress: 80,
  },
];

function Index() {
  return (
    <Card className="overflow-hidden">
      <CardHeader title="Залишки коштів на рахунках" titleTag="h6" />
      <Card.Body className="p-0">
        <AccountsTable data={dataAccounts} />
      </Card.Body>
    </Card>
  );
}

export default Index;
