import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Container fluid>
        <Row>
          <Col sm={3}>
            <Row noGutters>
              <Col sm={12} className="d-flex align-items-center justify-content-center">
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'disposer'}
                  highlighted={highlights.includes('disposer')}
                  onToggleHighlight={() => onToggleHighlight('disposer')}
                >
                  {fields.disposer.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12} className="d-flex align-items-center justify-content-center">
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'kbp7'}
                  highlighted={highlights.includes('kbp7')}
                  onToggleHighlight={() => onToggleHighlight('kbp7')}
                >
                  {fields.kbp7.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'actual_rates'}
                  highlighted={highlights.includes('actual_rates')}
                  onToggleHighlight={() => onToggleHighlight('actual_rates')}
                >
                  {fields.actual_rates.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_of_operation'}
                  highlighted={highlights.includes('cash_expenses_of_operation')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_of_operation')}
                >
                  {fields.cash_expenses_of_operation.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_for_wages'}
                  highlighted={highlights.includes('cash_expenses_for_wages')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_for_wages')}
                >
                  {fields.cash_expenses_for_wages.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'cash_expenses_of_local_budgets'}
                  highlighted={highlights.includes('cash_expenses_of_local_budgets')}
                  onToggleHighlight={() => onToggleHighlight('cash_expenses_of_local_budgets')}
                >
                  {fields.cash_expenses_of_local_budgets.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'planned_expenses_of_operation'}
                  highlighted={highlights.includes('planned_expenses_of_operation')}
                  onToggleHighlight={() => onToggleHighlight('planned_expenses_of_operation')}
                >
                  {fields.planned_expenses_of_operation.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'planned_expenses_for_wages'}
                  highlighted={highlights.includes('planned_expenses_for_wages')}
                  onToggleHighlight={() => onToggleHighlight('planned_expenses_for_wages')}
                >
                  {fields.planned_expenses_for_wages.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'planned_expenses_of_local_budgets'}
                  highlighted={highlights.includes('planned_expenses_of_local_budgets')}
                  onToggleHighlight={() => onToggleHighlight('planned_expenses_of_local_budgets')}
                >
                  {fields.planned_expenses_of_local_budgets.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row noGutters>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'average_salary_of_director'}
                  highlighted={highlights.includes('average_salary_of_director')}
                  onToggleHighlight={() => onToggleHighlight('average_salary_of_director')}
                >
                  {fields.average_salary_of_director.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'average_salary_of_teacher'}
                  highlighted={highlights.includes('average_salary_of_teacher')}
                  onToggleHighlight={() => onToggleHighlight('average_salary_of_teacher')}
                >
                  {fields.average_salary_of_teacher.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'average_salary_of_young_teacher'}
                  highlighted={highlights.includes('average_salary_of_young_teacher')}
                  onToggleHighlight={() => onToggleHighlight('average_salary_of_young_teacher')}
                >
                  {fields.average_salary_of_young_teacher.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
