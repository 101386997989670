import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function ErrorMessage({ onClose, err, errHeader }) {
  return (
    <Alert
      variant="danger"
      className="position-absolute fixed-bottom mb-0 rounded-0"
      dismissible={!!onClose}
      onClose={onClose}
    >
      <FontAwesomeIcon icon={faExclamationTriangle} className="me-3" pull="left" size="4x" />
      <Alert.Heading>
        {errHeader}
      </Alert.Heading>
      {err}
    </Alert>
  );
}

ErrorMessage.propTypes = {
  err: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func,
  errHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

ErrorMessage.defaultProps = {
  onClose: null,
  errHeader: 'Вибачте ...',
};

export default ErrorMessage;
