import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  byPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГрн' },
  groupLines: { label: 'Групувати рядки при друці', name: 'ГруппироватьСтроки' },
  byDocs: { label: 'До документа', name: 'ДоДокумента' },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  fond: { label: 'Фонд', name: 'Фонд' },
  groupKEKV: { label: 'Згортати за КЕКВ', name: 'СвернутьКЕКВ' },
  consolidated: { label: 'Зведений', name: 'Сводный' },
  consolidatedBudgets: { label: 'Згорнути бюджети', name: 'СвернутьБюджеты' },
};

const definition = {
  label: 'Аналіз показників СФ (Додаток 12)',
  name: 'AnalysisIndicatorsSF',
  columns,
  frontend: 'rep/AnalysisIndicatorsSF',
  backendName: 'АнализПоказателейСФ',
  resultColumns: baseResultColumns,
};

export default definition;
