import React from 'react';

function DocumentApproved() {
  return (
    <svg width="20" fill="white" height="20" xmlns="http://www.w3.org/2000/svg">
      <path id="svg_1" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" stroke="#a0a3ab" d="m15.5,6l-3,0l0,-3" />
      <path id="svg_2" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" stroke="#a0a3ab" d="m15.5,5.5l-3,-3l-8,0l0,15l11,0l0,-12z" />
      <path stroke="null" id="svg_3" fill="#8CC63F" d="m9.944444,14.888888c2.117149,0 3.833332,-1.716183 3.833332,-3.833332c0,-2.117086 -1.716183,-3.833332 -3.833332,-3.833332c-2.117086,0 -3.833332,1.716247 -3.833332,3.833332c0,2.117149 1.716247,3.833332 3.833332,3.833332z" />
      <path stroke="white" id="svg_4" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" d="m8.344465,11.333333l1.247829,1.244444l1.941039,-2.177777" />
    </svg>
  );
}

export default DocumentApproved;
