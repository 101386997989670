/* eslint-disable import/no-cycle */
import accessGroup from './accessGroup/lister/selector';
import additionalPropertyCsu from './additionalPropertyCsu/lister/selector';
import additionalPropertyKFK from './additionalPropertyKFK/lister/selector';
import additionalPropertyTypesRepair from './additionalPropertyTypesRepair/lister/selector';
import administrativeTerritorialUnits from './administrativeTerritorialUnits/lister/selector';
import articlesDebtSocialPayments from './articlesDebtSocialPayments/lister/selector';
import artistsRoles from './artistsRoles/lister/selector';
import bankAccounts from './bankAccounts/lister/selector';
import banks from './banks/lister/selector';
import baseDoc from './baseDoc/lister/selector';
import budget from './budget/lister/selector';
import budgetTransfer from './budgetTransfer/lister/selector';
import categoriesFOP from './categoriesFOP/lister/selector';
import categoriesFOPVersions from './categoriesFOPVersions/lister/selector';
import coverageSource from './coverageSource/lister/selector';
import coverageSourceClassifier from './coverageSourceClassifier/lister/selector';
import csu from './CSU/lister/selector';
import csuPartners from './CSUPartners/lister/selector';
import csuTransfer from './csuTransfer/lister/selector';
import deputies from './deputies/lister/selector';
import districts from './districts/lister/selector';
import docNotes from './docNotes/lister/selector';
import docType from './docType/lister/selector';
import elementKFK from './elementKFK/lister/selector';
import elementFond from './elementFond/lister/selector';
import elementKDB from './elementKDB/lister/selector';
import elementKEKV from './elementKEKV/lister/selector';
import elementKFB from './elementKFB/lister/selector';
import elementSocialPayment from './elementSocialPayment/lister/selector';
import energyCarriersIndicator from './energyCarriersIndicator/lister/selector';
import explanations from './explanations/lister/selector';
import fondClassifier from './fondClassifier/lister/selector';
import forecastCredit from './forecastCredit/lister/selector';
import forecastIndicatorBR from './forecastIndicatorBR/lister/selector';
import groundLaw from './groundLaw/lister/selector';
import houses from './houses/lister/selector';
import implementer from './implementer/lister/selector';
import indicators from './indicators/lister/selector';
import indicatorsClassifier from './indicatorsClassifier/lister/selector';
import indicatorsForTarget from './indicatorsForTarget/lister/selector';
import industry from './industry/lister/selector';
import infoSources from './infoSources/lister/selector';
import investor from './investor/lister/selector';
import kbpClassifier from './KBPClassifier/lister/selector';
import kbp7Classifier from './kbp7Classifier/lister/selector';
import kdbClassifier from './KDBClassifier/lister/selector';
import kekvClassifier from './KEKVClassifier/lister/selector';
import kekvClassifierForMinFinShort from './kekvClassifierForMinFinShort/lister/selector';
import kekvClassifierForPAShort from './kekvClassifierForPAShort/lister/selector';
import kekvClassifierFPShort from './kekvClassifierFPShort/lister/selector';
import kekvClassifierShort from './kekvClassifierShort/lister/selector';
import kfbClassifier from './KFBClassifier/lister/selector';
import kfkvkClassifier from './KFKVKClassifier/lister/selector';
import laws from './laws/lister/selector';
import noteClassifier from './noteClassifier/lister/selector';
import kvkClassifier from './KVKClassifier/lister/selector';
// import modelProgramsClassifier from './modelProgramsClassifier/lister/selector';
import notes from './notes/lister/selector';
import noteSocialPayments from './noteSocialPayments/lister/selector';
import numbering from './numbering/lister/selector';
import objBuildingSourceFin from './objBuildingSourceFin/lister/selector';
import objBuildingTypeDoc from './objBuildingTypeDoc/lister/selector';
import objBuildingTypePower from './objBuildingTypePower/lister/selector';
import objBuildingTypeSource from './objBuildingTypeSource/lister/selector';
import objectivesStatePolicy from './objectivesStatePolicy/lister/selector';
import objectsBuilding from './objectsBuilding/lister/selector';
import officials from './officials/lister/selector';
import outcomeGroup from './outcomeGroup/lister/selector';
import partnerBankAccounts from './partnerBankAccounts/lister/selector';
import partnerBanks from './partnerBanks/lister/selector';
import partners from './partners/lister/selector';
import paymentPurposeTemplate from './paymentPurposeTemplate/lister/selector';
import planing from './planing/lister/selector';
import provisionOfNormativeLegalActs from './provisionOfNormativeLegalActs/lister/selector';
import publicBudgetProject from './publicBudgetProject/lister/selector';
import publicBudgetProjectAuthor from './publicBudgetProjectAuthor/lister/selector';
import recipients from './recipients/lister/selector';
import regions from './regions/lister/selector';
import reportSettings from './reportSettings/lister/selector';
import resolutionSession from './resolutionSession/lister/selector';
import salaryStructure from './salaryStructure/lister/selector';
import signatureVariants from './signatureVariants/lister/selector';
import signGroup from './signGroup/lister/selector';
import signGroupByDocuments from './signGroupByDocuments/lister/selector';
import statesContingents from './statesContingents/lister/selector';
import stewardGroup from './stewardGroup/lister/selector';
import stewardLevel from './stewardLevel/lister/selector';
import streets from './streets/lister/selector';
import submittedDocuments from './submittedDocuments/lister/selector';
import target from './target/lister/selector';
import targetedPrograms from './targetedPrograms/lister/selector';
import tarif from './tarif/lister/selector';
import tasks from './tasks/lister/selector';
import tasks2019 from './tasks2019/lister/selector';
import tasksClassifier from './tasksClassifier/lister/selector';
import templateBaseDoc from './templateBaseDoc/lister/selector';
import tenderProcType from './tenderProcType/lister/selector';
import treasuryStatement from './treasuryStatement/lister/selector';
import typeAttachedFile from './typeAttachedFile/lister/selector';
import typesOfLaborCosts from './typesOfLaborCosts/lister/selector';
import typesRepair from './typesRepair/lister/selector';
import typesRepairIndustry from './typesRepairIndustry/lister/selector';
import typesWork from './typesWork/lister/selector';
import typeTaxSFForRaitingZF from './typeTaxSFForRaitingZF/lister/selector';
import units from './units/lister/selector';
import users from './users/lister/selector';
import variantBudgetDraft from './variantBudgetDraft/lister/selector';

const catSelectors = {
  accessGroup,
  additionalPropertyCsu,
  additionalPropertyKFK,
  additionalPropertyTypesRepair,
  administrativeTerritorialUnits,
  articlesDebtSocialPayments,
  artistsRoles,
  bankAccounts,
  banks,
  baseDoc,
  budget,
  budgetTransfer,
  categoriesFOP,
  categoriesFOPVersions,
  coverageSource,
  coverageSourceClassifier,
  csu,
  csuPartners,
  csuTransfer,
  deputies,
  districts,
  docNotes,
  docType,
  elementKFK,
  elementFond,
  elementKDB,
  elementKEKV,
  elementKFB,
  elementSocialPayment,
  energyCarriersIndicator,
  explanations,
  fondClassifier,
  forecastCredit,
  forecastIndicatorBR,
  groundLaw,
  houses,
  implementer,
  indicators,
  indicatorsClassifier,
  indicatorsForTarget,
  industry,
  infoSources,
  investor,
  kbpClassifier,
  kbp7Classifier,
  kdbClassifier,
  kekvClassifier,
  kekvClassifierForMinFinShort,
  kekvClassifierForPAShort,
  kekvClassifierFPShort,
  kekvClassifierShort,
  kfbClassifier,
  kfkvkClassifier,
  laws,
  noteClassifier,
  kvkClassifier,
  // modelProgramsClassifier,
  notes,
  noteSocialPayments,
  numbering,
  objBuildingSourceFin,
  objBuildingTypeDoc,
  objBuildingTypePower,
  objBuildingTypeSource,
  objectivesStatePolicy,
  objectsBuilding,
  officials,
  outcomeGroup,
  partnerBankAccounts,
  partnerBanks,
  partners,
  paymentPurposeTemplate,
  planing,
  provisionOfNormativeLegalActs,
  publicBudgetProject,
  publicBudgetProjectAuthor,
  recipients,
  regions,
  reportSettings,
  resolutionSession,
  salaryStructure,
  signatureVariants,
  signGroup,
  signGroupByDocuments,
  statesContingents,
  stewardGroup,
  stewardLevel,
  streets,
  submittedDocuments,
  target,
  targetedPrograms,
  tarif,
  tasks,
  tasks2019,
  tasksClassifier,
  templateBaseDoc,
  tenderProcType,
  treasuryStatement,
  typeAttachedFile,
  typesOfLaborCosts,
  typesRepair,
  typesRepairIndustry,
  typesWork,
  typeTaxSFForRaitingZF,
  units,
  users,
  variantBudgetDraft,
};

export default catSelectors;
