import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/ussagePlan';

const URL = instance.backendURL;

function Rep() {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const signvariantProps = editorHooks.useItemInputProps('signvariant', data, fields, fieldErrors, actions.onChange);
  const reportTypeProps = editorHooks.useSelectorInputProps('report_type', data, fields, fieldErrors, actions.onChange);
  const moneyKindProps = editorHooks.useSelectorInputProps('money_kind', data, fields, fieldErrors, actions.onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const inThousandsProps = editorHooks.useCheckboxInputProps('in_thousands', data, fields, fieldErrors, actions.onChange);
  const allKekvProps = editorHooks.useCheckboxInputProps('all_kekv', data, fields, fieldErrors, actions.onChange);
  const kekvProps = editorHooks.useCheckboxInputProps('kekv', data, fields, fieldErrors, actions.onChange);
  const groupKekvProps = editorHooks.useCheckboxInputProps('group_kekv', data, fields, fieldErrors, actions.onChange);
  const shortKekvProps = editorHooks.useCheckboxInputProps('short_kekv', data, fields, fieldErrors, actions.onChange);
  const summaryPlanProps = editorHooks.useCheckboxInputProps('summary_plan', data, fields, fieldErrors, actions.onChange);
  const annualFormProps = editorHooks.useCheckboxInputProps('annual_form', data, fields, fieldErrors, actions.onChange);
  const emptyValuesAs = editorHooks.useSelectorInputProps('empty_values_as', data, fields, fieldErrors, actions.onChange);

  const useKekv = useMemo(
    () => data.kekv,
    [data.kekv],
  );

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const { onChange } = actions;
  useEffect(
    () => {
      onChange(() => ({ filters }));
    },
    [filters, onChange],
  );
  useEffect(
    () => {
      if (data.filters && data.filters.length && !filters.length) {
        loadFilters(data.filters);
      }
    },
    [data.filters, filters.length, loadFilters],
  );
  return (
    <PrintFormContainer
      loading={loading}
      err={err}
      actions={actions}
      ref={resultRef}
      pageLayout={pageLayout}
      quickSettings={(
        <Row>
          <Col md={3}>
            <EditorControls.DateInput {...bdateProps} />
          </Col>
          <Col md={3}>
            <EditorControls.DateInput {...edateProps} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...signvariantProps} />
          </Col>
        </Row>
      )}
      settings={(
        <>
          <Row>
            <Col md={4}>
              <EditorControls.SelectorInput {...reportTypeProps} />
              <EditorControls.SelectorInput {...planKindProps} />
              <EditorControls.SelectorInput {...emptyValuesAs} />
            </Col>
            <Col md={4}>
              <EditorControls.ItemPicker {...authorityProps} />
              <EditorControls.SelectorInput {...moneyKindProps} />
            </Col>
            <Col md={4} className="border-left">
              <EditorControls.CheckboxInput {...kekvProps} />
              <EditorControls.CheckboxInput {...groupKekvProps} disabled={!useKekv} />
              <EditorControls.CheckboxInput {...shortKekvProps} disabled={!useKekv} />
              <EditorControls.CheckboxInput {...allKekvProps} disabled={!useKekv} />
              <br />
              <EditorControls.CheckboxInput {...inThousandsProps} />
              <EditorControls.CheckboxInput {...summaryPlanProps} />
              <EditorControls.CheckboxInput {...annualFormProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DndProvider backend={HTML5Backend}>
                <FiltersEditor
                  filters={displayFilters}
                  filtersHandlers={filtersHandlers}
                  availableFilters={availableFilters}
                />
              </DndProvider>
            </Col>
          </Row>
        </>
      )}
    />
  );
}

export default Rep;
