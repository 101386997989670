import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  cancelRequest, generate, processServerReq, goToOldVersion,
} from '../../../actions/reportEditor';
import { CancelRequestButton } from '../../../components/button';
import StyledTPPane from '../../../components/tableParts/TPPane';
import Loading from '../../../components/styledLoading';
import { CommandPanelButtonText } from '../../../components/Form/styledForm';
import PortalContainer from '../../../components/ReportSettings';
import LoadReportVariant from '../../catalogs/reportSettings/loadVariantButton';
import SaveReportVariant from '../../catalogs/reportSettings/saveVariantButton';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

const ContainerButton = styled.div`
  display: inline-flex;
`;

function ReportContainer({
  children, isProcessing, portalOpened, SettingButton, ExtraButtons, backendName,
}) {
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  return (
    <div>
      <div className="d-flex">
        <CommandPanelButtonText
          disabled={isProcessing}
          text="Сформувати"
          onClick={() => {
            setUpdated(true);
            dispatch(generate());
          }}
        >
          Сформувати
        </CommandPanelButtonText>
        {ExtraButtons && <ExtraButtons />}
        {SettingButton && (
          <PortalContainer isOpened={portalOpened} disabled={isProcessing}>
            {SettingButton}
          </PortalContainer>
        )}
        <ContainerButton>
          <LoadReportVariant
            disabled={isProcessing}
            reportKey={`Отчеты.${backendName}.reactReport`}
            onLoad={(e, variant) => dispatch(processServerReq('LoadVariant', { variant }))}
          />
          <SaveReportVariant
            disabled={isProcessing}
            reportKey={`Отчеты.${backendName}.reactReport`}
            onSave={(e, nameVariant, commentVariant) => dispatch(processServerReq('SaveVariant', { nameVariant, commentVariant }))}
          />
        </ContainerButton>
        <CommandPanelButtonText
          disabled={isProcessing}
          text="До попередньої версії"
          onClick={() => {
            dispatch(goToOldVersion());
          }}
        >
          До попередньої версії
        </CommandPanelButtonText>
      </div>

      {isProcessing && !updated && <Loading />}
      {isProcessing && updated && (
        <Loading>
          <>
            {/* <CancelRequestTitle>Воно того варте! </CancelRequestTitle> */}
            <CancelRequestButton
              onClick={() => {
                setUpdated(false);

                dispatch(cancelRequest());
              }}
            >
              Скасувати запит
            </CancelRequestButton>
          </>
        </Loading>
      )}
      {children}
    </div>

  );
}

ReportContainer.propTypes = {
  children: PropTypes.node,
  ExtraButtons: PropTypes.func,
  SettingButton: PropTypes.element,
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
  backendName: PropTypes.string.isRequired,
};

ReportContainer.defaultProps = {
  isProcessing: false,
  portalOpened: false,
  children: null,
  SettingButton: null,
  ExtraButtons: null,
};

export default ReportContainer;
