import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import api from '../../../../api/req';
import meta from '../../../../constants/meta';

/**
 *
 * @param modelType
 * @param modelName
 * @param viewType
 * @returns {string|null}
 */
function getSettingsName(modelType, modelName, viewType) {
  const md = meta[modelType][modelName];
  const formName = viewType === 'lister' ? 'ФормаСписка' : 'ФормаВыбора';
  switch (modelType) {
    case 'doc':
      return `Документ.${md.backendName}.${formName}`;
    case 'cat':
      return `Справочник.${md.backendName}.${formName}`;
    case 'infoRegs':
      return `РегистрСведений.${md.backendName}.${formName}`;
    case 'ChTP':
      return `ПланыВидовХарактеристик.${md.backendName}.${formName}`;
    default:
      return null;
  }
}

/**
 *
 * @param modelType {string}
 * @param modelName {string}
 * @param viewType {string}
 * @param setLoading {function}
 * @param setErr {function}
 * @returns {{
 * onSaveSettings: (function(): void),
 * settings: {},
 * onLoadSettings: (function(): void),
 * onSetSettings: (function(*): void)
 * }}
 */
const useSettings = (modelType, modelName, viewType, setLoading, setErr) => {
  const loadedSettings = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(
    () => {
      loadedSettings.current = settings;
    },
    [settings],
  );

  const onSaveSettings = useCallback(
    () => {
      const asyncLoader = async (newSettings) => {
        const settingsName = getSettingsName(modelType, modelName, viewType);

        const response = await api.put$(`settings/${settingsName}`, newSettings);
        if (response.ok) {
          return true;
        }
        throw new Error(`Помилка при збереженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      asyncLoader(loadedSettings.current)
        .catch((e) => console.error(e.message))
        .finally(() => setLoading(false));
    },
    [modelName, modelType, setLoading, viewType],
  );

  const onSetSettings = useCallback(
    (partSettgins) => {
      setSettings((oldSettings) => {
        const newSettings = {
          ...oldSettings,
          ...partSettgins,
        };
        loadedSettings.current = newSettings;
        onSaveSettings();
        return newSettings;
      });
    },
    [onSaveSettings],
  );
  const onLoadSettings = useCallback(
    () => {
      const asyncLoader = async () => {
        const settingsName = getSettingsName(modelType, modelName, viewType);

        const response = await api.post$(`settings/${settingsName}`);
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Помилка при завантаженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      asyncLoader()
        .then((data) => {
          setSettings(data);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [modelName, modelType, setErr, setLoading, viewType],
  );

  return {
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,
  };
};

export default useSettings;
