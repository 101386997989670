/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';
import md from '../../../../../../constants/meta/documents/netCategoryPlan';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { CommandPanelColor } from '../../../../../../components/Form/styledForm';

const tableMeta = md.tables.general;

function GeneralTP({ data, actions, readOnly }) {
  const FI = data[md.columns.FI.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });
  const canDelete = activeRow !== null
    && tableData[activeRow]
    && tableData[activeRow][tableMeta.columns.status.name] === 3;
  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          canAdd={false}
          canDelete={canDelete}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          <CommandPanelColor
            label="Розрахувати всі суми документу"
            onClick={() => actions.onSR('CALC_SUM_IN_TABLE')}
          />
          <Button
            variant="warning"
            onClick={() => actions.onSR('DELETE_WRONG_ROWS')}
            disabled={readOnly}
          >
            <FontAwesomeIcon icon={faMagic} className="me-2" />
            Видалити всі неправильні рядки
          </Button>
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            FI={FI}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          activeCol={activeCol}
          highlights={highlights}
          //
        />
      </TableFooter>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
