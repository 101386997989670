import base from './base';

const columns = {
  ...base.columns,
  Author: { label: 'Автор', name: 'Автор', visible: true },
  Tags: { label: 'Опис', name: 'АвтПримечание', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  PlaningMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  Financed: { label: 'Документ профінансовано', name: 'ДокументПрофинансирован', visible: false },
  IsApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  ImportAllNotes: { label: 'Не зводити примітку (в розпорядженні / розподілі)', name: 'ИмпортВсехПримечаний', visible: false },
  Received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  FinReceived: { label: 'Профінансовано', name: 'КвитанцияФ', visible: true },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  RNo: { label: 'Номер', name: 'РегНомер', visible: true },
  RNo2: { label: 'Ном.внутр.обліку', name: 'РегНомер2', visible: false },
  CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
  FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
  FIcode: { label: 'р/р', name: 'РСФинансовыйОрганКод', visible: true },
  Sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: false },
  IncludeCSUIntoHead: {
    label: 'Розпорядник в шапці',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  Implementer: {
    label: 'Відділ виконавець',
    name: 'ОтветственныйИсполнитель',
    visible: false,
  },
  DocumentType: { label: 'Вид документу', name: 'ПередачаСредств', visible: true },
  FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  // Дополнитеьлные свойства
  DocumentTypeVisible: { label: 'ПередачаСредствВидимость', name: 'ПередачаСредствВидимость' },
  KPKVVisible: { label: 'ВидимостьКФК', name: 'ВидимостьКФК' },
  KEKVVisible: { label: 'ВидимостьКЕКВ', name: 'ВидимостьКЕКВ' },
  useLengthControl: { label: 'флСтрогийКонтрольДлиныПримРаспор', name: 'флСтрогийКонтрольДлиныПримРаспор' },
  NotesLength: { label: 'ДлинаПримРаспор', name: 'ДлинаПримРаспор' },
  Notes0Length: { label: 'ДлинаПримРаспорНоль', name: 'ДлинаПримРаспорНоль' },
  FNotesLength: { label: 'ДлинаПриРаспорФин', name: 'ДлинаПриРаспорФин' },
  ReadOnly: { label: 'ФлТолькоПросмотр', name: 'ФлТолькоПросмотр' },
  FIAccNo: {
    label: 'НомерСчетаФО',
    name: 'НомерСчетаФО',
  },
  Fond: {
    label: 'Фонд',
    name: 'Фонд',
  },
  UnderFundedVisible: {
    label: 'НедофинансированоВидимость',
    name: 'НедофинансированоВидимость',
  },
  AccountBalanceVisible: {
    label: 'ОстаткиНаСчетахВидимость',
    name: 'ОстаткиНаСчетахВидимость',
  },
  AccountingByClients: {
    label: 'УчетПоКонтрагентам',
    name: 'УчетПоКонтрагентам',
  },
  FinancingReturn: { label: 'Повернення фінансування', name: 'флВозвратФинансирования', visible: false },
  NumberOfMounthReturn: { label: 'Місяць повернення', name: 'НомМесяцаВозврата', visible: false },
  ComposeSettings: { label: 'Складне налаштування для повернення фінансування', name: 'флСложнаяНастройка' },
  useFOAccounts: { label: 'КотловыеДляРаспорядителей', name: 'КотловыеДляРаспорядителей' },
  doNotUseAccounts: { label: 'НеЗаполнятьРСвЗаявке', name: 'НеЗаполнятьРСвЗаявке' },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Основна',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      Sum: { label: 'Сума', name: 'Сумма' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      KVKCode: { label: 'КВК', name: 'КодКВК' },
      Balance: { label: 'Залишок', name: 'Ост' },
      DocNotes: { label: 'Шаблон примітки', name: 'ПримечанияДок' },
      NoteCat: { label: 'Вид платежу', name: 'ПримечаниеСпр' },
      NoteGeneral: { label: 'Примітка (тут вказується: код КФБ, вид операції, *поточний/розр. рахунок*)', name: 'ПримечаниеОсновной' },
      Note: { label: 'Примітка (50 симв.)', name: 'Примечание' },
      YearPlan: { label: 'План на рік', name: 'ПланГод' },
      PeriodPlan: { label: 'План на період', name: 'ПланПериод' },
      MonthPlan: { label: 'План на місяць', name: 'ПланМесяц' },
      PeriodFact: { label: 'Факт на період', name: 'ФактПериод' },
      UnderFunded: { label: 'Недофінансовано', name: 'Недофинансировано' },
      FinLiabilities: { label: 'Фін.зобов\'язання', name: 'ФинОбязательства' },
      FullNotes: { label: 'Примітка для заявці', name: 'ПолноеПримечание' },
      BalanceRegAccounts: { label: 'Залишки на р-них рахунках', name: 'ОстатокНаРегСчетах' },
      BalancePersAccounts: { label: 'Залишки на особ. рахунках', name: 'ОстатокНаОсобСчетах' },
      FinLiabilitiy: { label: 'Фінансове зобов\'язання', name: 'ФинансовоеОбязательство' },
      IsReturn: { label: 'Повернення фін.', name: 'флВозврат' },
      BankDate: { label: 'Дата', name: 'ДатаКазна' },
      ReturnNotes: { label: 'Примітка для повернення фінансування', name: 'ПримечаниеВозврат' },
      // Дополнительные свойства
      CSUAccountNo: { label: '№ рахунка', name: 'НомерРСРаспорядителя', visible: false },
      BuildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      TypeRepair: { label: 'Вид ремонту', name: 'ВидРемонтаРеквизит' },
      MounthReturn: { label: 'Місяць повернення', name: 'МесяцВозврата' },
    },
  },
  financingRequests: {
    name: 'ФинансированиеЗаявки',
    label: 'Фінансування заявок',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      document: { label: 'Заявка на фінансування', name: 'ФинанированиеЗаявка' },
      IsApproved: { label: 'Затверджено', name: 'ДокументУтвержден' },
      sum: { label: 'Сума', name: 'СуммаДокумента' },
      AutoNotes: { label: 'Опис', name: 'АвтПримечание' },
      //       CSU: { label: 'Розподярник', name: 'ДокРаспНижнегоУрДокФинансовыйОрган' },
      //       author: { label: 'Автор', name: 'Автор' },
      //       notes: { label: 'Примітка', name: 'ДокРаспНижнегоУрДокументПримечание' },
    },
  },
};

const definition = {
  label: 'Пропозиція на фінансування',
  name: 'financingPropose',
  backendName: 'ФинансированиеПредложение',
  columns,
  frontend: 'doc/financingPropose',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'Financed',
      'IsApproved',
      'FinReceived',
      'Received',
      'RNo',
      'Sum',
      'FondObject',
      'Author',
      'Tags',
      'Note',
      'FIcode',
      'FIAccount',
      'Budget',
      'DocumentType',
      'CSU',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
