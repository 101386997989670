import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DivHeader } from '../styledTable/styledTable';

const Header = styled(DivHeader)`
  display: grid;
  grid-auto-rows: auto;
  overflow: hidden;
`;

function ComponentsHeader({
  headerCol, activeNameCell, dynamicHeaderName,
}) {
  return (
    <>
      {headerCol && Object.keys(headerCol).map((elName) => (
        <Header
          areaName={elName}
          key={`Inp${elName}`}
          isActive={activeNameCell === elName}
        >
          {dynamicHeaderName(elName, headerCol)}
        </Header>
      ))}
    </>
  );
}

export default ComponentsHeader;

ComponentsHeader.propTypes = {
  headerCol: PropTypes.shape({}).isRequired,
  dynamicHeaderName: PropTypes.func,
  activeNameCell: PropTypes.string,

};

ComponentsHeader.defaultProps = {
  activeNameCell: '',
  dynamicHeaderName: () => '',
};
