import React from 'react';
import Lister from '../../lister/lister';

const modelName = 'typesRepairElChTP';
const modelType = 'ChTP';

function TypesRepairElChTPLister() {
  return (
    <Lister
      modelType={modelType}
      modelName={modelName}
    />
  );
}

export default TypesRepairElChTPLister;
