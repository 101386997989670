import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівник', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  R01G3: { label: 'R01G3', name: 'R151G3' },
  R01G4: { label: 'R01G3', name: 'R151G4' },
  R01G5: { label: 'R01G3', name: 'R151G5' },
  R01G6: { label: 'R01G3', name: 'R151G6' },
  R01G7: { label: 'R01G3', name: 'R151G7' },
  R02G3: { label: 'R01G3', name: 'R152G3' },
  R02G4: { label: 'R01G3', name: 'R152G4' },
  R02G5: { label: 'R01G3', name: 'R152G5' },
  R02G6: { label: 'R01G3', name: 'R152G6' },
  R02G7: { label: 'R01G3', name: 'R152G7' },
  R03G3: { label: 'R01G3', name: 'R153G3' },
  R03G4: { label: 'R01G3', name: 'R153G4' },
  R03G5: { label: 'R01G3', name: 'R153G5' },
  R03G6: { label: 'R01G3', name: 'R153G6' },
  R03G7: { label: 'R01G3', name: 'R153G7' },
  R154G3: { label: 'R01G3', name: 'R154G3' },
  R154G4: { label: 'R01G3', name: 'R154G4' },
  R154G5: { label: 'R01G3', name: 'R154G5' },
  R154G6: { label: 'R01G3', name: 'R154G6' },
  R154G7: { label: 'R01G3', name: 'R154G7' },
  R155G3: { label: 'R01G3', name: 'R155G3' },
  R155G4: { label: 'R01G3', name: 'R155G4' },
  R155G5: { label: 'R01G3', name: 'R155G5' },
  R155G6: { label: 'R01G3', name: 'R155G6' },
  R155G7: { label: 'R01G3', name: 'R155G7' },
  R156G3: { label: 'R01G3', name: 'R156G3' },
  R156G4: { label: 'R01G3', name: 'R156G4' },
  R156G5: { label: 'R01G3', name: 'R156G5' },
  R156G6: { label: 'R01G3', name: 'R156G6' },
  R156G7: { label: 'R01G3', name: 'R156G7' },
  R157G3: { label: 'R01G3', name: 'R157G3' },
  R157G4: { label: 'R01G3', name: 'R157G4' },
  R157G5: { label: 'R01G3', name: 'R157G5' },
  R157G6: { label: 'R01G3', name: 'R157G6' },
  R157G7: { label: 'R01G3', name: 'R157G7' },
  R158G3: { label: 'R01G3', name: 'R158G3' },
  R158G4: { label: 'R01G3', name: 'R158G4' },
  R158G5: { label: 'R01G3', name: 'R158G5' },
  R158G6: { label: 'R01G3', name: 'R158G6' },
  R158G7: { label: 'R01G3', name: 'R158G7' },
  R159G3: { label: 'R01G3', name: 'R159G3' },
  R159G4: { label: 'R01G3', name: 'R159G4' },
  R159G5: { label: 'R01G3', name: 'R159G5' },
  R159G6: { label: 'R01G3', name: 'R159G6' },
  R159G7: { label: 'R01G3', name: 'R159G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd8Printing',
  backendName: 'ПечатьПрогнозДодаток8',
  label: 'Граничні показники кредитування бюджету  (Додаток 8)',
  frontend: 'dp/forecastAdd8Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
