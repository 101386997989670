import base from './base';

/**
 * @const
 */

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  CSUOnHead: { label: 'Розп./отр. в шапці', name: 'флРаспорядительВШапке', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  reason: { label: 'Підстава', name: 'Причина', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  templateBaseDoc: { label: 'Підстава для змін', name: 'ШаблонОснованияДок', visible: false },
  year: { label: 'Рік планування', name: 'ГодЗатрат', visible: false },
  RNo: { label: 'Рег номер', name: 'РегНомер', visible: true },
  sessionDecision: { label: 'Рішення сесії', name: 'РешениеСессии', visible: true },
  DateStart: { label: 'за період з', name: 'ДатаНач', visible: false },
  DateFin: { label: 'по', name: 'ДатаКон', visible: false },
  isApproved: {
    label: 'Документ затверджено',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',

  },
  docTotalCost: {
    label: 'Сума',
    name: 'СуммаДокумента',
    visible: true,
    type: 'moneys',
  },
  splitTragetProg: { label: 'Окремо за місцевими/регіональними програмами', name: 'флЦелевыеПрогОтдельно', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      kdbClassifier: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
      KFKCode: { label: 'Код КФК', name: 'КодКФК' },
      KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ' },
      targetedPrograms: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
      note: { label: 'Примітка', name: 'Примитка', visible: false },
      sumGenF: { name: 'ЗФ', label: 'Заг.фонд(всього) ' },
      sumGenF1000: { name: 'ЗФ1000', label: 'Видатки споживання ' },
      sumGenF1110: { name: 'ЗФ1110', label: 'Оплата праці ' },
      sumGenF1160: { name: 'ЗФ1160', label: 'Ком.послуги,енергоносії  ' },
      sumGenF2000: { name: 'ЗФ2000', label: 'Видатки розвитку   ' },
      sumSpecF: { name: 'СФ', label: 'Спец.фонд(всього) ' },
      sumSpecF1000: { name: 'СФ1000', label: 'Видатки споживання ' },
      sumSpecF1110: { name: 'СФ1110', label: 'Оплата праці ' },
      sumSpecF1160: { name: 'СФ1160', label: 'Ком.послуги,енергоносії  ' },
      sumSpecF2000: { name: 'СФ2000', label: 'Видатки розвитку   ' },
      sumBR: { name: 'БР', label: 'в т.ч.бюджет розвитку ' },
      sum: { name: 'Сумма', label: 'Разом' },
      sumBRKR: { name: 'БРКР', label: 'Програми соц.ек.розвитку ' },
    },
  },
  targetProg: {
    name: 'ЦелевыеПрограммы',
    label: 'Цільові програми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },

      KFKCode: { label: 'Код КФК', name: 'КодКФК' },
      KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ' },

      targetedPrograms: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },

      sumGenF: { name: 'ЗФ', label: 'Заг.фонд(всього)' },
      sumSpecF: { name: 'СФ', label: 'Спец.фонд(всього)' },
      sumBR: { name: 'БР', label: '`в т.ч.бюджет розвитку' },
      sum: { name: 'Сумма', label: 'Разом' },

      note: { label: 'Примітка', name: 'Примитка', visible: false },
    },
  },
  tvDoc: {
    name: 'ТЗДок',
    label: 'Завантажені документи',
    columns: {
      doc: { label: 'Документ', name: 'Документ' },
      prim: { label: 'Пометка', name: 'Пометка' },
      kbp: { label: 'КБПМБ', name: 'КБПМБ' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      docP: { label: 'Документ', name: 'ПредставлениеДокумента' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до проекту рішення сесії. Додатки 3 та 7 (Видатків та місцеві/регіональні програми)',
  name: 'changeDraftAnnualSpend',
  backendName: 'ИзменениеПроектаРасходов',
  columns,
  frontend: 'doc/changeDraftAnnualSpend',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'isApproved',
      // 'docTotalCost',
      'FI',
      'CSU',
      'author',
      'reason',
      'sessionDecision',
      'variantBudgetDraft',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
