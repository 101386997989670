import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  LawNumber: { label: 'Номер', name: 'НомерЗакона', visible: false },
  LawDate: { label: 'Дата прийняття закону', name: 'ДтЗакона', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  CurrentBudget: {
    label: 'Текущий Бюджет',
    name: 'ТекущийБюджет',
    visible: true,
  },
  owner: { label: 'Бюджет', name: 'Владелец', visible: true },
};

export const laws = {
  label: 'Нормативно-законодавчі акти України',
  name: 'laws',
  backendName: 'Законы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/laws',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default laws;
