import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button, ButtonGroup, Card, Form, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FilesButton from '../commandPanels/buttons/files';
import { SubTitle } from '../../../components/Form/styledForm';
import Loader from '../../../components/styledLoading';
import contents from './contents';

import {
  FilesContainer,
} from '../../../components/uploadFiles/styles';
import md from '../../../constants/meta/documents/legalObligation';
import {
  Dbf, DOCXColorIcon,
  FileColorIcon,
  JPGIcon,
  PDFColorIcon,
  TIFFIcon,
  ExcelIcon,
} from '../../../assets/icons';
import { soSelector } from '../../documents/_common/selectors';

export const ContainerFile = styled.div`
  background: #e1f1f8;
  align-items: start;
  position: relative;
  min-height: 100px;
`;

const extendFile = (filename) => {
  const lenFileName = filename.length;
  const extend = filename.substring(lenFileName - 3);
  return extend.toUpperCase();
};

const getIconFromMimeType = (MIMEType) => {
  switch (MIMEType) {
    case 'application/pdf':
      return (<img src={PDFColorIcon} alt="PDF" width="33px" />);
    case 'image/jpeg':
      return (<JPGIcon width="33px" />);
    case 'image/tiff':
      return (<TIFFIcon width="33px" />);
    case 'application/vnd.ms-excel':
      return (<img src={ExcelIcon} alt="XLS" width="33px" />);
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return (<img src={ExcelIcon} alt="XLS" width="33px" />);
    case 'application/x-dbf':
      return (<img src={Dbf} alt="FileIcon" width="33px" />);
    case 'DBF':
      return (<img src={Dbf} alt="FileIcon" width="33px" />);
    case 'application/octet-stream':
      return (<img src={FileColorIcon} alt="FileIcon" width="33px" />);
    case 'application/msword':
      return (<img src={DOCXColorIcon} alt="word" width="33px" />);
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return (<img src={DOCXColorIcon} alt="word" width="33px" />);
    default:
      return (<img src={FileColorIcon} alt="FileIcon" width="33px" />);
  }
};

function File({
  file, onDeleteFile, onLoadAttachedFiles,
}) {
  const {
    filename, filenameftp, type,
  } = file;

  return (
    <Card border="info" style={{ width: '10rem', margin: '10px' }}>
      <Card.Body onClick={() => onLoadAttachedFiles([filenameftp])} style={{ cursor: 'pointer' }}>
        {getIconFromMimeType(type || extendFile(filename))}
        {filename}
      </Card.Body>
      <Card.Footer className="btn-lg auto">
        <Button
          onClick={onDeleteFile}
          variant="light"
        >
          {contents.deleteButton}
        </Button>
      </Card.Footer>
    </Card>
  );
}

function AttachedFilesList({
  actions,
  permissions,
  data,
  loading,
}) {
  const [isOpen, setOpen] = useState(false);
  const { isLocked } = permissions;
  const attachedFiles = data && data[md.columns.attachedFiles.name]
    ? data[md.columns.attachedFiles.name] : [];
  const countFiles = (attachedFiles.length !== 0) ? attachedFiles.length : null;

  const sessionOptions = useSelector(soSelector);
  const isEnableFtp = sessionOptions.get('is_enable_ftp', false);

  const handleLoadFile = (e) => {
    const files = [...e.target.files];

    Promise.all(files.map((f) => f)).then((results) => {
      const choiseFiles = results.reduce(
        (R, ffs, i) => [
          ...R,
          ...[{
            file: ffs,
            done: false,
            task: i,
          }],
        ],
        [],
      );

      actions.onUpLoadAttachedFiles(choiseFiles);
    });
  };

  const fileGetter = async (file) => [file];

  const scanFiles = (item, callback) => {
    if (item.isDirectory) {
      const DR = item.createReader();
      DR.readEntries((items) => {
        items.map((i) => this.scanFiles(i, callback));
      });
    } else {
      callback(item);
    }
  };

  const fileLoadHandler = (e) => {
    const { items } = e.dataTransfer;

    e.preventDefault();
    let f = [];
    Object.values(items).forEach((i) => scanFiles(i.webkitGetAsEntry(), (item) => {
      item.file((file) => {
        fileGetter(file).then((files) => {
          if (files) {
            f = [...f, ...files.filter((ff) => !!ff).map((fl, ii) => ({
              file: fl,
              done: false,
              task: ii,
            }))];
            actions.onUpLoadAttachedFiles(f);
          }
        });
      });
    }));
  };

  return (
    <>
      <ButtonGroup>
        <FilesButton
          onClick={() => setOpen(!isOpen)}
          disabled={!permissions.canExecute}
          countFiles={countFiles}
        />
      </ButtonGroup>
      <Modal
        size="lg"
        show={isOpen && isEnableFtp}
        onHide={() => setOpen(false)}
        aria-labelledby="modal-File-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-File-title-lg">
            {contents.modalFilesTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerFile
            onDragOver={(e) => e.preventDefault()}
            onDrop={fileLoadHandler}
          >
            {loading ? (
              <Loader />
            ) : (
              <FilesContainer>
                {attachedFiles.map((file) => (
                  <File
                    key={file.name}
                    file={file}
                    isLocked={isLocked}
                    onDeleteFile={() => {
                      actions.onDeleteAttachedFile(file.filename, file.filenameftp);
                    }}
                    onLoadAttachedFiles={actions.onLoadAttachedFiles}
                  />
                ))}
              </FilesContainer>
            )}
            <div>
              <SubTitle>
                {contents.modalFilesSubTitle}
              </SubTitle>
            </div>
          </ContainerFile>

          <Form.Group
            controlId="formFilePdf"
            className="mb-3"
            align="right"
            id="InputADD_TEXT_FORM"
          >
            <Button>
              <Form.Control
                type="file"
                style={{ display: 'none' }}
                onChange={handleLoadFile}
              />
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
              <Form.Label>
                {contents.choiseFileButton}
              </Form.Label>
            </Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={isOpen && !isEnableFtp}
        onHide={() => setOpen(false)}
        aria-labelledby="modal-File-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-File-title-lg">
            {contents.modalWarningTitle}
          </Modal.Title>
        </Modal.Header>
        <Alert key="Alertdanger" variant="danger">
          {contents.modalWarningAlert}
        </Alert>
        <Modal.Body />
      </Modal>
    </>
  );
}

AttachedFilesList.propTypes = {
  actions: PropTypes.shape({
    onLoadAttachedFiles: PropTypes.func.isRequired,
    onDeleteAttachedFile: PropTypes.func.isRequired,
    onUpLoadAttachedFiles: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canExecute: PropTypes.bool,
    isLocked: PropTypes.bool,
  }).isRequired,
  data: PropTypes.shape({
    [md.columns.attachedFiles.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isLocked: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

AttachedFilesList.defaultProps = {
  loading: false,
};

File.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string,
    filenameftp: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onLoadAttachedFiles: PropTypes.func.isRequired,
};

export default AttachedFilesList;
