import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTableComponent } from './tableComponent/hook';
import ListerContext from './context';

const ListerFactory = ({ backendURL, viewType = 'lister' }) => (TableLister) => {
  ListerContext.displayName = `${viewType}  lister context of ${backendURL}`;

  function Lister({
    // choiceSettings,
    filter, noHierarchy, value, onSelect, onChoice, overridePermissions,
    additionalContext, selectMode,
  }) {
    const options = useMemo(
      () => ({
        initialFilter: filter,
        initialValue: value,
        noHierarchy,
        onSelect,
        onChoice,
        selectMode,
      }),
      [filter, noHierarchy, onChoice, onSelect, selectMode, value],
    );
    const {
      visibleColumns, items, openedItems, selectedRows, onSetOrder, order, loading, err,
      showDeleted, searchString, period, maxItemLevel, localFilter, filterOpened, filteringFields,
      onReload,
      permissions,
      actions,
      columnSizes, onSetColumnSizes, onResetColumnSizes,
      ctxMenu, onShowCtxMenu, onHideCtxMenu,
      tableRef, commandPanelRef, onRowFocusHandler, onTableScroll, areaSize,
      messages, printForms, metaOptions,
    } = useTableComponent(backendURL, viewType, options);
    const perms = useMemo(
      () => ({
        ...permissions,
        ...overridePermissions,
      }),
      [overridePermissions, permissions],
    );

    // При необходимости переместить одно ищ указанныъ нижу свойств в
    // context - удалять из свойств. Т.е. свойство должно быть или в
    // props или в context, но никак и там и там.
    const ctxValue = useMemo(
      () => ({
        permissions: perms,
        actions,
        showDeleted,
        searchString,
        period,
        selectedRows,
        items,
        localFilter,
        ctxMenu,
        onShowCtxMenu,
        onHideCtxMenu,
        backendURL,
        viewType,
        messages,
        filteringFields,
        options,
        printForms,
        onResetColumnSizes,
        metaOptions,
        filterOpened,
        ...additionalContext,
      }),
      [perms, actions, showDeleted, searchString, period,
        selectedRows, items, localFilter, ctxMenu,
        onShowCtxMenu, onHideCtxMenu, messages, filteringFields, options, printForms,
        onResetColumnSizes, metaOptions, filterOpened, additionalContext],
    );
    return (
      <ListerContext.Provider value={ctxValue}>
        <TableLister
          ref={tableRef}
          commandPanelRef={commandPanelRef}
          visibleColumns={visibleColumns}
          openedItems={openedItems}
          onRowFocus={onRowFocusHandler}
          onSetOrder={onSetOrder}
          order={order}
          loading={loading}
          err={err}
          onReoload={onReload}
          onScroll={onTableScroll}
          areaSize={areaSize}
          columnSizes={columnSizes}
          onSetColumnSizes={onSetColumnSizes}
          maxItemLevel={maxItemLevel}
        />
      </ListerContext.Provider>
    );
  }
  Lister.propTypes = {
    filter: PropTypes.shape({
    }),
    overridePermissions: PropTypes.shape({}),
    noHierarchy: PropTypes.bool,
    value: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
      url: PropTypes.string,
      resource: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    onChoice: PropTypes.func,
    selectMode: PropTypes.bool,
    additionalContext: PropTypes.shape({}),
  };
  Lister.defaultProps = {
    filter: {},
    overridePermissions: {},
    noHierarchy: false,
    onSelect: null,
    onChoice: null,
    selectMode: false,
    value: null,
    additionalContext: {},
  };
  return Lister;
};

export default ListerFactory;
