import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  TextInput,
  CheckboxInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'descendantsDocuments';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col lg={1} className="text-center">
            <TableRowCell column="isApproved" highlighted={highlights.includes('isApproved')}>
              <CheckboxInput
                value={row[tableMD.columns.isApproved.name]}
                modelName="isApproved"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.isApproved.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={4}>
            <TableRowCell column="document" highlighted={highlights.includes('document')}>
              <ItemPicker
                value={row[tableMD.columns.document.name]}
                modelType="doc"
                modelName="indicatorFact"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.document.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col lg={4}>
            <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
              <ItemPicker
                modelType="cat"
                modelName="csu"
                value={row[tableMD.columns.CSU.name]}
                onChange={(e, value) => onChange({
                  [md.columns.CSU.name]: value,
                })}
              />
            </TableRowCell>
          </Col>
          <Col lg={3}>
            <TableRowCell column="author" highlighted={highlights.includes('author')}>
              <ItemPicker
                modelType="cat"
                modelName="users"
                value={row[tableMD.columns.author.name]}
                onChange={(e, value) => onChange({
                  [md.columns.author.name]: value,
                })}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="notes" highlighted={highlights.includes('notes')}>
              <TextInput
                value={row[tableMD.columns.notes.name]}
                rows={1}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.notes.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
