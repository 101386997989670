import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  NumberInput, StringInput, ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes11';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_112';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="code" highlighted={highlights.includes('code')}>
              <StringInput
                value={row[tableMD.columns.code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.code.name]: v })}
                readOnly={readOnly}
                maxLength={19}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="codeDod" highlighted={highlights.includes('codeDod')}>
              <StringInput
                value={row[tableMD.columns.codeDod.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.codeDod.name]: v })}
                readOnly={readOnly}
                maxLength={19}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="name" highlighted={highlights.includes('name')}>
              <ItemPicker
                value={row[tableMD.columns.name.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.name.name]: v });
                  await onSR('CHANGE_LAND_IN_TABLE112', { rowId: rowIndex });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="landClassifier"
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="rateLegalWithImpact" highlighted={highlights.includes('rateLegalWithImpact')}>
              <NumberInput
                value={row[tableMD.columns.rateLegalWithImpact.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.rateLegalWithImpact.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="rateNaturalWithImpact" highlighted={highlights.includes('rateNaturalWithImpact')}>
              <NumberInput
                value={row[tableMD.columns.rateNaturalWithImpact.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.rateNaturalWithImpact.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="rateLegalWithoutImpact" highlighted={highlights.includes('rateLegalWithoutImpact')}>
              <NumberInput
                value={row[tableMD.columns.rateLegalWithoutImpact.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.rateLegalWithoutImpact.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="rateNaturalWithoutImpact" highlighted={highlights.includes('rateNaturalWithoutImpact')}>
              <NumberInput
                value={row[tableMD.columns.rateNaturalWithoutImpact.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.rateNaturalWithoutImpact.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
