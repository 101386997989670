import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import md from '../../../../../meta/cat/budget';
import { CiatAppContext } from '../../../../../providers';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { CatTableListerFactory } from '../../../../newLister/tableComponent';

const Lister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'lister',
})(CatTableListerFactory());

function BudgetLister({ noHierarchy, ...restProps }) {
  const { currentUser } = useContext(CiatAppContext);
  const isSuperUser = currentUser.is_superuser;

  return (
    <Lister noHierarchy={noHierarchy || !isSuperUser} {...restProps} />
  );
}

BudgetLister.propTypes = {
  noHierarchy: PropTypes.bool,
};

BudgetLister.defaultProps = {
  noHierarchy: false,
};

export default BudgetLister;
