import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Collection, Map } from 'immutable';
import {
  FrameDiv, CollapseButton, ExpandArrow, ErrorMessage,
} from '../../components/Form/styledForm';
import {
  init,
  load,
} from '../../actions/editor';
import def from '../../constants/meta';
import AttachedFiles from './attachedFiles';
import storePathParam from '../../common/storePathParam';

export { default as getRouterEditor } from '../catalogs/routerEditor';

export const mapState = (store, ownProps) => {
  const editorNode = store.getIn([storePathParam(store).node, storePathParam(store).id], new Map());
  return {
    sessionOptions: store.getIn(['auth', 'sessionOptions'], new Map()),
    canBeSaved: editorNode.get('canBeSaved', false),
    isLoading: editorNode.get('isLoading', true),
    isProcessing: editorNode.get('isProcessing', true),
    isChanged: editorNode.get('isChanged', false),
    isValidated: editorNode.get('isValidated', false),
    hasError: editorNode.get('hasError', false),
    errorMsg: editorNode.get('errorMsg', ''),
    headerForm: editorNode.get('headerForm', new Map()), // Шапка
    disabledFields: editorNode.get('disabledFields', new Collection.Set()),
    // notes: editorNode.get('notes', ''),
    isOpenAttachedFiles: editorNode.get('isOpenAttachedFiles', true),
    AttachedFiles: editorNode.get('AttachedFiles', []),
    isLoadingAttachedFiles: editorNode.get('isLoadingAttachedFiles', true),
    ...ownProps,
  };
};

/**
 * @param modelNameNType {Object}
 * @param modelNameNType.name {string}
 * @param modelNameNType.type {string}
 * @param {extra} {Object}
 * @param {extra.menu} {React.Component}
 * @param {extra.tables} {React.Component}
 * @param {extra.footer} {React.Component}
 * @param {extra.canCollapsed} {bool}
 * @returns {function(<object>): *}
 * @constructor
 */
const EditorHOC = ({ name, type }, extra) => (WrappedComponent) => class extends PureComponent {
  static displayName = `Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  static propTypes = {
    id: PropTypes.string.isRequired, // id элемента
    search: PropTypes.string, // Параметры адресной строки (то что идет после ?)
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    isSaved: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isLocked: PropTypes.bool,
    canBeSaved: PropTypes.bool,
    isChanged: PropTypes.bool,
    repr: PropTypes.string,
    noStyles: PropTypes.bool,
    isOpenAttachedFiles: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
    headerForm: PropTypes.instanceOf(Map).isRequired,
  };

  static defaultProps = {
    repr: '',
    isSaved: true,
    isProcessing: false,
    isChanged: false,
    isLocked: false,
    canBeSaved: false,
    noStyles: false,
    search: '',
    errorMsg: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      id,
      search,
    } = this.props;
    const c = new URLSearchParams(search);
    const initParams = {

      ...(c.get('reason') ? { reason: c.get('reason') } : {}),
      id,
      copyFrom: c.get('copyFrom'),
    };
    dispatch(init(type, name, id, initParams));
    dispatch(load(type, name, 'fl_infoRegs'));
  }

  collapseMenu = () => this.setState({ isOpened: !this.state.isOpened });

  render() {
    const { props } = this;
    const { isOpened } = this.state;
    const MenuWrapper = extra && extra.menu;
    const TablesWrapper = extra && extra.tables;
    const FooterWrapper = extra && extra.footer;
    return (
      <>
        {MenuWrapper && (
        <MenuWrapper
          dispatch={props.dispatch}
          onClose={props.onClose}
          onSave={props.onSave}
          disabled={props.isLoading || props.hasError || props.isProcessing}
          isLocked={props.isLocked}
          isChanged={props.isChanged}
          header={type === 'doc' ? props.headerForm.get('repr') : `${def[type][name].label}`}
          id={props.headerForm.get('id')}
          modelName={name}
          modelType={type}
        />
        )}
        {props.hasError && (
        <ErrorMessage>
          {props.errorMsg}
        </ErrorMessage>
        )}
        <FrameDiv>

          {props.isOpenAttachedFiles && <AttachedFiles /> }

          {isOpened ? (
            <>
              <WrappedComponent {...props} />
              {extra.canCollapsed && (
              <CollapseButton isOpened onClick={this.collapseMenu} title="Згорнути" />
              )}
            </>
          ) : (
            <ExpandArrow isOpened onClick={this.collapseMenu} title="Розгорнути" />
          )}
        </FrameDiv>
        <div style={{ clear: 'both' }} />
        {!props.isLoading && TablesWrapper && <TablesWrapper dispatch={props.dispatch} />}
        {FooterWrapper && !props.isLoading && React.cloneElement(FooterWrapper, props)}
      </>
    );
  }
};

export default EditorHOC;
