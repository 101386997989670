import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
  Reason: { label: 'Підстава (загальна)', name: 'Основание', visible: false },
  SessionDate: { label: 'Дата рішення сесії', name: 'ДатаРешенияСессии', visible: false },
};

export const templateBaseDoc = {
  label: 'Підстава для довідок змін',
  name: 'TemplateBaseDoc',
  backendName: 'шаблонныеоснованиядок',
  columns,
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  frontend: 'cat/templateBaseDoc',
  listColumns: [
    ...(new Set([
      ...reqListerColumns,
    ]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default templateBaseDoc;
