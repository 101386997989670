import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { InputWithDropdown } from '../../../components/styledInputs';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  TwoColGridContainer,
  TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

import DateRangeField from '../../../components/fields/DateRangeField';

const MainContainer = styled.div`
  position: relative;
`;

const OneColumnsGridContainer = styled(TwoColumnsGridContainer)`
  grid-template-columns: 40%;
  grid-column-gap: 5%;
`;

const FourColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 20% 20% 20% ;
  grid-column-gap: 2%;
`;

const md = meta.dp.referRegistry;

const StringInput200 = Fields.GetTextInput(200);

const MyplanKind = {
  // ext1: { label: 'Постійний + тимчасовий', name: 'ПостоянныйВременный' },
  ext2: { label: 'Постійний', name: 'Постоянный' },
  ext3: { label: 'Тимчасовий', name: 'Временный' },
};

const MyrefKind = {
  ext1: { label: 'Всі довідки', name: 0 },
  ext2: { label: 'Тількі річні (сума змін за рік не нульова)', name: 1 },
  ext3: { label: 'Пересування (нульова сума змін за рік) ', name: 2 },
};

const MyrefType = {
  ext1: { label: 'Доходи', name: 0 },
  ext2: { label: 'Видатки', name: 1 },
  ext3: { label: 'Джерела', name: 2 },
  ext4: { label: 'Доходи і видатки і джерела', name: 3 },
  ext5: { label: 'Видатки і джерела', name: 4 },
};

const MyformKind = {
  ext1: { label: 'Реєстр', name: 0 },
  ext2: { label: 'Додаток 8', name: 1 },
};

class ReferRegistryEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    headerForm: null,
    isProcessing: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ShowSettings: false,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, headerForm, isProcessing },
      state: { ShowSettings },
    } = this;

    return (
      <MainContainer>
        <TwoColGridContainer />
        <ContainerButtonTop>
          <CommandPanelButton
            text="Сформувати"
            onClick={() => {
              this.setState({ ShowSettings: false });
              dispatch(actions.changeField(['headerForm', 'result'], new Map()));
              dispatch(actions.processServerReq('PRINT'));
            }}
            loading={isProcessing}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
          </CommandPanelButton>
          <CommandPanelButton
            text="Налаштування"
            onClick={() => { this.setState({ ShowSettings: !ShowSettings }); }}
          >
            <SettingsIcon />
          </CommandPanelButton>
        </ContainerButtonTop>
        {
          ShowSettings && (
            <FramePrintModal
              styled={{ ShowSettings } ? { transition: '3s' } : { transition: '3s' }}
              visible={ShowSettings}
              animation="overlay"
              width="very wide"
              direction="top"
              tertiary
              color="blue"
              basic
            >
              <TwoColGridContainer>
                <div>
                  <StyledLabel>{md.columns.budget.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('budget', new Map())}
                    modelType="cat"
                    modelName="budget"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'budget'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.FO.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('FO', new Map())}
                    modelType="cat"
                    modelName="csu"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FO'], v))}
                  />
                </div>
              </TwoColGridContainer>
              <div style={{ marginTop: '10px' }}>
                <StyledLabel>{md.columns.period.label}</StyledLabel>
                <DateRangeField
                  value={headerForm.get('period', new Map())}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'period'], v));
                    dispatch(actions.processServerReq('PERIOD_CHANGE'));
                  }}
                  noBorder
                />
              </div>
              <FourColumnsGrid>
                <div>
                  <StyledLabel>{md.columns.planKind.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('planKind', null)}
                    options={Object.values(MyplanKind)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'planKind'], v));
                    }}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.refKind.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('refKind', 0)}
                    options={Object.values(MyrefKind)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'refKind'], v));
                    }}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.refType.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('refType', 0)}
                    options={Object.values(MyrefType)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'refType'], v));
                    }}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.formKind.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('formKind', 0)}
                    options={Object.values(MyformKind)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'formKind'], v));
                    }}
                  />
                </div>
              </FourColumnsGrid>
              <FourColumnsGrid>
                <div>
                  <Fields.CheckboxField
                    label={md.columns.fdev.label}
                    value={headerForm.get('fdev', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fdev'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    label={md.columns.fYearMounth.label}
                    value={headerForm.get('fYearMounth', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fYearMounth'], v))}
                  />
                  <Fields.CheckboxField
                    label={md.columns.yearAll.label}
                    value={headerForm.get('yearAll', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'yearAll'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    label={md.columns.noSumms.label}
                    value={headerForm.get('noSumms', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noSumms'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    label={md.columns.onlyApprooved.label}
                    value={headerForm.get('onlyApprooved', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyApprooved'], v))}
                  />
                </div>
              </FourColumnsGrid>
              <OneColumnsGridContainer>
                <div>
                  <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('SignVariant', new Map())}
                    modelType="cat"
                    modelName="signatureVariants"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                    filter={[
                      { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
                    ]}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('flSN', true)}
                    label={md.columns.flSN.label}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'flSN'], v))}
                  />
                </div>
              </OneColumnsGridContainer>
              <TwoColGridContainer>
                <div>
                  <StyledLabel>{md.columns.fond.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('fond', new Map())}
                    modelType="cat"
                    modelName="fondClassifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fond'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.reason.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('reason', new Map())}
                    modelType="cat"
                    modelName="templateBaseDoc"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'reason'], v))}
                  />
                </div>
              </TwoColGridContainer>
              <TwoColGridContainer>
                <div>
                  <StyledLabel>{md.columns.ffond.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('ffond', new Map())}
                    modelType="cat"
                    modelName="elementFond"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ffond'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.exec.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('exec', new Map())}
                    modelType="cat"
                    modelName="implementer"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'exec'], v))}
                  />
                </div>
              </TwoColGridContainer>
              <TwoColGridContainer>
                <div>
                  <StyledLabel>{md.columns.author.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('author', new Map())}
                    modelType="cat"
                    modelName="users"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'author'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.fFO.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('fFO', new Map())}
                    modelType="cat"
                    modelName="csu"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fFO'], v))}
                  />
                </div>
              </TwoColGridContainer>
              <div>
                <StyledLabel>{md.columns.nameForm.label}</StyledLabel>
                <StringInput200
                  value={headerForm.get('nameForm', '')}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'nameForm'], v));
                  }}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.nameForm.label}</StyledLabel>
                <StringInput200
                  value={headerForm.get('nameForm', '')}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'nameForm'], v));
                  }}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.nameInTableBudget.label}</StyledLabel>
                <StringInput200
                  value={headerForm.get('nameInTableBudget', '')}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'nameInTableBudget'], v));
                  }}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.nameInTableYear.label}</StyledLabel>
                <StringInput200
                  value={headerForm.get('nameInTableYear', '')}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'nameInTableYear'], v));
                  }}
                />
              </div>
            </FramePrintModal>
          )
        }
        <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
      </MainContainer>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // indicatorsFactPrint',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ReferRegistryEditor);
