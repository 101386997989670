import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  csu: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  CSUOnHead: { label: 'Розп./отр. в шапці', name: 'флРаспорядительВШапке', visible: true },
  Year: { label: 'Рік планування', name: 'ГодЗатрат' },
  isNegativeReturnSum: { label: 'Внесення сум повернення зі знаком "-"', name: 'флВозвратКакПлюсИлиМинус', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KPKVCode: { label: 'Код КПКВ', name: 'КПКВ' },
      tProg: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
      csu: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      sum1: { label: 'Надання кредитів', name: 'Сумма1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      sum2: { label: 'Повернення коштів', name: 'Сумма2' },
      zf: { label: 'Заг.ф.', name: 'ЗФ' },
      sf: { label: 'Спец.ф.', name: 'СФ' },
      br: { label: 'в т.ч.БР', name: 'БР' },
      sum: { label: 'Кредитування, усього', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Проект рішення сесії. Додаток 4 (Кредитування)',
  name: 'draftAnnualCredit',
  backendName: 'ПроектКредитов',
  columns,
  frontend: 'doc/draftAnnualCredit',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'variantBudgetDraft',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
