import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonGroup,
} from 'react-bootstrap';
import {
  SaveButton, CloseButton, UndoButton, RedoButton,
} from './buttons';
import { StyledButtonToolbar } from './styles';
import SaveNExitButton from './buttons/saveNexit';

const RegEditorCommandPanel = forwardRef(({
  children, actions, permissions, changed,
}, ref) => (
  <Navbar sticky="top" className="px-0" ref={ref}>
    <StyledButtonToolbar className="me-auto">
      <ButtonGroup>
        <SaveNExitButton
          onClick={actions.onSaveNExit}
          disabled={!permissions.canClose || !permissions.canSave || !changed}
        />
        <SaveButton
          onClick={actions.onSaveWithoutExit}
          disabled={!changed || !permissions.canSave}
        />
      </ButtonGroup>
      <ButtonGroup>
        <UndoButton
          onClick={actions.onUndo}
          disabled={!permissions.canUndo}
        />
        <RedoButton
          onClick={actions.onRedo}
          disabled={!permissions.canRedo}
        />
      </ButtonGroup>
      <ButtonGroup>
        <CloseButton
          onSave={actions.onSaveNExit}
          onClose={actions.onClose}
          changed={changed}
          disabled={!permissions.canClose}
        />
      </ButtonGroup>
      {children}
    </StyledButtonToolbar>
  </Navbar>
));

RegEditorCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  actions: PropTypes.shape({
    onSaveWithoutExit: PropTypes.func.isRequired,
    onSaveNExit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
  }).isRequired,
  changed: PropTypes.bool,
  permissions: PropTypes.shape({
    canClose: PropTypes.bool,
    canSave: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
  }).isRequired,
};

RegEditorCommandPanel.defaultProps = {
  children: null,
  changed: false,
};

export default memo(RegEditorCommandPanel);
