import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
  disposerInHeader, separatelyLocalRegPrograms,
}) {
  return (
    <TableHeaderRow noGutters className="fs--2">
      <Col md={4}>
        <Row>
          {!disposerInHeader && (
          <Col md={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
          </Col>
          ) }
          <Col md={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'kbp7_item'}
              highlighted={highlights.includes('kbp7_item')}
              onToggleHighlight={() => onToggleHighlight('kbp7_item')}
            >
              {fields.kbp7_item.label}
            </TableHeaderCell>
          </Col>
          {!separatelyLocalRegPrograms && (
          <Col sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'target_programm'}
              highlighted={highlights.includes('target_programm')}
              onToggleHighlight={() => onToggleHighlight('target_programm')}
            >
              {fields.target_programm.label}
            </TableHeaderCell>
          </Col>
          )}
        </Row>
      </Col>
      <Col sm={3}>
        <Row>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'total_zag_fond'}
              highlighted={highlights.includes('total_zag_fond')}
              onToggleHighlight={() => onToggleHighlight('total_zag_fond')}
            >
              Загальний фонд
              {/* {fields.total_zag_fond.label} */}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center p-0"
              active={activeCol === 'consumption_expenditures_zf'}
              highlighted={highlights.includes('consumption_expenditures_zf')}
              onToggleHighlight={() => onToggleHighlight('consumption_expenditures_zf')}
            >
              Видатки споживання
              {/* {fields.consumption_expenditures_zf.label} */}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'job_payment_zf'}
              highlighted={highlights.includes('job_payment_zf')}
              onToggleHighlight={() => onToggleHighlight('job_payment_zf')}
            >
              Оплата праці
              {/* {fields.job_payment_zf.label} */}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'utility_bills_zf'}
              highlighted={highlights.includes('utility_bills_zf')}
              onToggleHighlight={() => onToggleHighlight('utility_bills_zf')}
            >
              Комунальні послуги
              {/* {fields.utility_bills_zf.label} */}
            </TableHeaderCell>
          </Col>
          <Col sm={6}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'development_cost_zf'}
              highlighted={highlights.includes('development_cost_zf')}
              onToggleHighlight={() => onToggleHighlight('development_cost_zf')}
            >
              Видатки розвитку
              {/* {fields.development_cost_zf.label} */}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={4}>
        <Row>
          <Col className="gx-0" sm={12}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'total_spec_fond'}
              highlighted={highlights.includes('total_spec_fond')}
              onToggleHighlight={() => onToggleHighlight('total_spec_fond')}
            >
              Спеціальний фонд
              {/* {fields.total_spec_fond.label} */}
            </TableHeaderCell>
          </Col>
          <Col className="p-0 m-0" sm={4}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'consumption_expenditures_sf'}
              highlighted={highlights.includes('consumption_expenditures_sf')}
              onToggleHighlight={() => onToggleHighlight('consumption_expenditures_sf')}
            >
              Видатки споживання
              {/* {fields.consumption_expenditures_sf.label} */}
            </TableHeaderCell>
          </Col>
          <Col className="g-0" sm={4}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'job_payment_sf'}
              highlighted={highlights.includes('job_payment_sf')}
              onToggleHighlight={() => onToggleHighlight('job_payment_sf')}
            >
              Оплата праці
              {/* {fields.job_payment_sf.label} */}
            </TableHeaderCell>
          </Col>
          <Col className="g-0" sm={4}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'total_development_budget'}
              highlighted={highlights.includes('total_development_budget')}
              onToggleHighlight={() => onToggleHighlight('total_development_budget')}
            >
              Бюджет розвитку
              {/* {fields.total_development_budget.label} */}
            </TableHeaderCell>
          </Col>
          <Col className="gx-0" sm={4}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'utility_bills_sf'}
              highlighted={highlights.includes('utility_bills_sf')}
              onToggleHighlight={() => onToggleHighlight('utility_bills_sf')}
            >
              Комун.послуги, енергоносії
              {/* {fields.utility_bills_sf.label} */}
            </TableHeaderCell>
          </Col>
          <Col sm={4}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'development_cost_sf'}
              highlighted={highlights.includes('development_cost_sf')}
              onToggleHighlight={() => onToggleHighlight('development_cost_sf')}
            >
              Видатки розвитку
              {/* {fields.development_cost_sf.label} */}
            </TableHeaderCell>
          </Col>
          <Col className="gx-0" sm={4}>
            <TableHeaderCell
              className="text-center p-0"
              active={activeCol === 'soc_econimical_programs'}
              highlighted={highlights.includes('soc_econimical_programs')}
              onToggleHighlight={() => onToggleHighlight('soc_econimical_programs')}
            >
              Програми соц. економ розвитку
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="gx-0" sm={1}>
        <Col>
          <TableHeaderCell
            className="text-center"
            active={activeCol === 'total'}
            highlighted={highlights.includes('total')}
            onToggleHighlight={() => onToggleHighlight('total')}
          >
            &#8721;
          </TableHeaderCell>
        </Col>
        <Col className="gx-0">
          <TableHeaderCell
            className="text-center"
            active={activeCol === 'note'}
            highlighted={highlights.includes('note')}
            onToggleHighlight={() => onToggleHighlight('note')}
          >
            {fields.note.label}
          </TableHeaderCell>
        </Col>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  //
  disposerInHeader: PropTypes.bool,
  separatelyLocalRegPrograms: PropTypes.bool,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
  separatelyLocalRegPrograms: false,
};
export default Header;
