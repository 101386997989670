import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/publicBudgetProject';

function PublicBudgetProject({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.ID.label}
            value={data[md.columns.ID.name]}
            maxLength={150}
            onChange={(e, value) => actions.onChange({
              [md.columns.ID.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.ProjectYear.label}
            value={data[md.columns.ProjectYear.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ProjectYear.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            maxLength={150}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.ProjectAuthor.label}
            value={data[md.columns.ProjectAuthor.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ProjectAuthor.name]: value,
            })}
            modelType="cat"
            modelName="publicBudgetProject"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Comment.label}
            value={data[md.columns.Comment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Comment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

PublicBudgetProject.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PublicBudgetProject;
