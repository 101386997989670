import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  CodeUDK: {
    label: 'Код Головного управління Державного казначейства',
    name: 'КодУДК',
    visible: false,
  },
  CodeVDK: {
    label: 'Код відділу державного казначейства',
    name: 'КодВДК',
    visible: false,
  },
  TerritoryCode: {
    label: 'Код території',
    name: 'КодТеритории',
    visible: false,
  },
  DirectiveName: {
    label: 'Назва районного управління казначейства',
    name: 'НаименованиеРаспоряжение',
    visible: true,
  },
  UDKName: { label: 'Назва УДК', name: 'НаимУДК', visible: false },
  VDKName: { label: 'Назва ВДК', name: 'НаимВДК', visible: false },
  MFO: { label: 'МФО', name: 'МФО', visible: false },
  DoesTreasuryIncluded: {
    label: 'Це комерцiйний банк',
    name: 'ЕстьКазначество',
    visible: false,
  },
  TerritoryCodeFin: {
    label: 'Код території для документів фінансування',
    name: 'КодТериторииФин',
    visible: false,
  },
  Level: {
    label: 'Рiвень',
    name: 'Уровень',
    visible: false,
  },
};

export const banks = {
  label: 'Органи ДКУ',
  name: 'banks',
  columns,
  backendName: 'Банки',
  defaultOrder,
  frontend: 'cat/banks',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'DirectiveName',
    ]),
  ],
  hierarchy: false,
};

export default banks;
