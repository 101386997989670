import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable/dist/immutable';
import styled from 'styled-components';
import DateField from '../../../../components/fields/dates';
import { editor as actions } from '../../../../actions';
import storePathParam from '../../../../common/storePathParam';

export const ContainerButton = styled.div`
  display: flex;
`;

function GeneralCP({
  dispatch, headerForm,
}) {
  return (
    <ContainerButton>
      <DateField
        value={headerForm.get('DateFill')}
        onChange={(e, v) => {
          dispatch(actions.changeField(['headerForm', 'DateFill'], v));
        }}
      />
    </ContainerButton>
  );
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

GeneralCP.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map),
};

GeneralCP.defaultProps = {
  headerForm: new Map(),
};

export default connect(mapStateTP)(GeneralCP);
