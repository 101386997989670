import {
  directions, periodTypes,
} from '../common';

const measurements = {
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  deputies: { label: 'Депутат', name: 'Депутат', visible: true },
  name: { label: 'Пропозиція (коротко)', name: 'Наименование', visible: true },
  kfk: { label: 'КФК', name: 'КФК', visible: false },
  proposalShort: { label: 'Пропозиція (коротко)', name: 'Наименование', visible: true },
  inputDate: {
    label: 'Дата внесених даних', name: 'ДатаВвода', visible: false, type: 'date',
  },
  year: { label: 'Рiк', name: 'Год', visible: true },

};

const resources = {

  proposalFull: { label: 'Пропозиція депутата', name: 'ПолнНаименование', visible: true },
  diagnosis: { label: 'Висновок виконавчого органу ради', name: 'Оценка', visible: true },
  resolution: { label: 'Висновок профільного комітету з питань бюджету', name: 'РешениеПК', visible: true },
  SumDep: { label: 'Сума депутата', name: 'СуммаДеп', visible: true },
  SumFin: { label: 'Сума Ради', name: 'СуммаФин', visible: true },
  SumPK: { label: 'Сума профільного комітету', name: 'СуммаПК', visible: true },
};

const props = {
  author: { label: 'Автор', name: 'Автор', visible: true },
};

const columns = {
  parentDeputies: {
    label: 'Фракція', name: 'Фракция', visible: true,
  },
  id: { label: 'id', name: '_id', visible: false },
  ...measurements,
  ...resources,
  ...props,
};

const defaultOrder = [
  {
    column: columns.date,
    direction: directions.ascending,
  },
];

export const proposalDeputies = {
  label: 'Пропозиції депутатів',
  name: 'proposalDeputies',
  columns,
  backendName: 'ПредложенияДепутатов',
  defaultOrder,
  frontend: 'infoRegs/proposalDeputies',
  listColumns: [
    ...new Set([
      'inputDate',
      'year',
      'parentDeputies',
      'deputies',
      'kfk',
      'proposalShort',
      'SumDep',
      'SumFin',
      'SumPK',
    ]),
  ],
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default proposalDeputies;
