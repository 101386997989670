import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Зведення мережі ФОП',
  name: 'NetFOPreduce',
  columns,
  tables: [],
  frontend: 'rep/netFOPReduce',
  backendName: 'ЗведенняМережіФОП',
  resultColumns: baseResultColumns,
};

export default definition;
