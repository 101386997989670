import React from 'react';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function FiltersButton({
  content, onClick, disabled, animation, title, variant,
}) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faFilter}
      bounce={animation}
      title={title}
      variant={variant}
    />
  );
}

FiltersButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  animation: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
};

FiltersButton.defaultProps = {
  onClick: () => null,
  content: 'Фільтрування',
  disabled: false,
  animation: false,
  title: 'Фільтрування',
  variant: null,
};

export default FiltersButton;
