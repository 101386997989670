import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/netFOP';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, value, sumName) => {
      const colName = tableMD.columns[sumName].name;
      const okl = tableMD.columns.okl.name;
      const adds = tableMD.columns.adds.name;
      const addsNo = tableMD.columns.adds_no.name;
      const addsMin = tableMD.columns.adds_min.name;
      const prem = tableMD.columns.prem.name;
      const other = tableMD.columns.other.name;

      const tot = value
                + (row[okl] || 0)
                + (row[adds] || 0)
                + (row[addsNo] || 0)
                + (row[addsMin] || 0)
                + (row[prem] || 0)
                + (row[other] || 0)
                - (row[colName] || 0);

      onRowChange(e, {
        [colName]: value,
        [tableMD.columns.fop.name]: tot,
      });
    },
    [onRowChange, row],
  );

  const onTarifChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.tarifCat.name]: v });
      await onSR('CHANGE_TARIF_IN_GENERAL', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const CSUAccountFIlter = useMemo(
    () => [
      { fieldName: 'НомерДодатка', value: 1 },
    ],
    [],
  );

  const onKPKVKMBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.KPKVKMB.name]: v });
      await onSR('CHANGE_KPKVKMB_IN_TABLE_GENERAL', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onKPKVKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.KPKVK.name]: v });
      await onSR('CHANGE_KPKVK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="tarifCat" highlighted={highlights.includes('tarifCat')}>
              <ItemPicker
                value={row[tableMD.columns.tarifCat.name]}
                modelType="cat"
                modelName="tarif"
                onChange={onTarifChange}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                filter={CSUAccountFIlter}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <Col className="border-right">
              <TableRowCell column="KPKVKMB" highlighted={highlights.includes('KPKVKMB')}>
                <ItemPicker
                  value={row[tableMD.columns.KPKVKMB.name]}
                  modelType="cat"
                  modelName="kbp7Classifier"
                  onChange={onKPKVKMBChange}
                  readOnly={readOnly}
                  choiceSettings={selectionMode.foldersNItems}
                  required
                />
              </TableRowCell>
            </Col>
            <Col className="border-right">
              <TableRowCell column="KPKVK" highlighted={highlights.includes('KPKVK')}>
                <ItemPicker
                  value={row[tableMD.columns.KPKVK.name]}
                  modelType="cat"
                  modelName="kbpClassifier"
                  onChange={onKPKVKChange}
                  readOnly={readOnly}
                  choiceSettings={selectionMode.foldersNItems}
                  required
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="num" highlighted={highlights.includes('num')}>
              <NumberInput
                value={row[tableMD.columns.num.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.num.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="fact" highlighted={highlights.includes('fact')}>
              <NumberInput
                value={row[tableMD.columns.fact.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.fact.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="fop" highlighted={highlights.includes('fop')}>
              <NumberInput
                value={row[tableMD.columns.fop.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.fop.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="okl" highlighted={highlights.includes('okl')}>
              <NumberInput
                value={row[tableMD.columns.okl.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'okl')}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="adds" highlighted={highlights.includes('adds')}>
              <NumberInput
                value={row[tableMD.columns.adds.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'adds')}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="adds_no" highlighted={highlights.includes('adds_no')}>
              <NumberInput
                value={row[tableMD.columns.adds_no.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'adds_no')}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="adds_min" highlighted={highlights.includes('adds_min')}>
              <NumberInput
                value={row[tableMD.columns.adds_min.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'adds_min')}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prem" highlighted={highlights.includes('prem')}>
              <NumberInput
                value={row[tableMD.columns.prem.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'prem')}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="other" highlighted={highlights.includes('other')}>
              <NumberInput
                value={row[tableMD.columns.other.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v, 'other')}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="coun" highlighted={highlights.includes('coun')}>
              <NumberInput
                value={row[tableMD.columns.coun.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.coun.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.num.name]: PropTypes.number,
    [tableMD.columns.fact.name]: PropTypes.number,
    [tableMD.columns.fop.name]: PropTypes.number,
    [tableMD.columns.okl.name]: PropTypes.number,
    [tableMD.columns.adds.name]: PropTypes.number,
    [tableMD.columns.adds_no.name]: PropTypes.number,
    [tableMD.columns.adds_min.name]: PropTypes.number,
    [tableMD.columns.prem.name]: PropTypes.number,
    [tableMD.columns.other.name]: PropTypes.number,
    [tableMD.columns.coun.name]: PropTypes.number,
    [tableMD.columns.tarifCat.name]: referencePropType,
    [tableMD.columns.KPKVKMB.name]: referencePropType,
    [tableMD.columns.KPKVK.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  onSR: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
