import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditorHOC, { mapState, getRouterEditor } from '../basic';
import { addPropClassifiersChTP as def } from '../../../constants/meta/ChTP/addPropClassifiersChTP';
import { editor as editorActions } from '../../../actions';
import { catEditorMenu as BasicMenu } from '../../../components/editorMenu';
import GetTextField from '../../field/TextInput';
import CheckBoxField from '../../field/CheckBox';
import { StyledLabel, ThreeRow } from '../../../components/Form/styledForm';

const TextField50 = GetTextField(50);
const getTypeValue = (headerForm, param) => headerForm.getIn(['typeValue', param], false) !== false;

const setTypeValue = (headerForm, value, param) => {
  const typeValue = headerForm.get('typeValue', new Map());
  if (value) { return typeValue.set(param, param); }
  return typeValue.delete(param);
};
function AddPropClassifiersChTP({ headerForm, dispatch }) {
  return (
    <ThreeRow>
      <div>
        <StyledLabel>{def.columns.code.label}</StyledLabel>
        <TextField50
          value={headerForm.get('code', '')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'code'], value))}
        />
      </div>
      <div>
        <StyledLabel>{def.columns.name.label}</StyledLabel>
        <TextField50
          value={headerForm.get('name', '')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'name'], value))}
        />
      </div>

      <CheckBoxField
        label="Значення додаткових властивостей"
        value={getTypeValue(headerForm, 'Справочник.ДопСвойстваКлассификаторов')}
        onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ДопСвойстваКлассификаторов')))}
      />
    </ThreeRow>
  );
}

AddPropClassifiersChTP.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapState),
  EditorHOC(
    {
      type: 'ChTP',
      name: 'addPropClassifiersChTP',
    },
    {
      menu: BasicMenu,
    },
  ),
);

export const StdEditor = enhance(AddPropClassifiersChTP);

export default getRouterEditor(def)(StdEditor);
