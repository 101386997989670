import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/variantBudgetDraft';

function VariantBudgetDraft({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            maxLength={150}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.dateProject.label}
            value={data[md.columns.dateProject.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dateProject.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.numberProject.label}
            value={data[md.columns.numberProject.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.numberProject.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Штампи
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <EditorControls.TextInput
                    label={md.columns.stamp.label}
                    value={data[md.columns.stamp.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.stamp.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.TextInput
                    label={md.columns.stamp2014.label}
                    value={data[md.columns.stamp2014.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.stamp2014.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetName.label}
            value={data[md.columns.BudgetName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Для лімітної довідки
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <EditorControls.DateInput
                    label={md.columns.dateStatement.label}
                    value={data[md.columns.dateStatement.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.dateStatement.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.DateInput
                    label={md.columns.dateApproved.label}
                    value={data[md.columns.dateApproved.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.dateApproved.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
                <Col>
                  <EditorControls.StringInput
                    label={md.columns.year.label}
                    value={data[md.columns.year.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.year.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.TextInput
                    label={md.columns.basis.label}
                    value={data[md.columns.basis.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.basis.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="variantBudgetDraft"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

VariantBudgetDraft.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default VariantBudgetDraft;
