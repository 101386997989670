import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const QUARTERS = [...Array(4)].map((_, i) => i);

function TPFooter({
  totals,
  activeCol,
  highlights,
  onlyYear,
  vt4br,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        xl={4}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
      <Col xl={8} sm={12} className="px-1">
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q} className="px-1 border-right">
              {[...Array(3)].map((_, m) => (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={vt4br ? 1 : 1} noGutters>
                  <Col className="text-right">
                    <TableHeaderCell active={activeCol === `amount_${q * 3 + m + 1}`} highlighted={highlights.includes(`amount_${q * 3 + m + 1}`)}>
                      {totals[`amount_${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                    </TableHeaderCell>
                  </Col>
                  {vt4br && (
                  <Col className="font-italic text-right">
                    <TableHeaderCell active={activeCol === `amount_${q * 3 + m + 1}`} highlighted={highlights.includes(`amount_${q * 3 + m + 1}`)}>
                      {totals[`amount_${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                    </TableHeaderCell>
                  </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'amount_1' && onlyYear}
              highlighted={highlights.includes('amount_1') && onlyYear}
            >
              {totals.amount_total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {vt4br && (
            <TableHeaderCell
              className="text-right font-italic text-sm-center w-100"
              active={activeCol === 'amount_br_1' && onlyYear}
              highlighted={highlights.includes('amount_br_1') && onlyYear}
            >
              {totals.amount_br_total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount_total: PropTypes.number,
    amount_br_total: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`amount_${i + 1}`]: PropTypes.number,
      [`amount_br_${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
};

TPFooter.defaultProps = {
  activeCol: '',
  onlyYear: false,
  vt4br: false,
};

export default memo(TPFooter);
