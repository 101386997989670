import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const QUARTERS = [...Array(4)].map((_, i) => i);

function TPFooter({
  totals,
  activeCol,
  highlights,
  onlyYear,
  inBR,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={3}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
      <Col xl={9} sm={12} className="px-1">
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q} className="px-1 border-right">
              {[...Array(3)].map((_, m) => (
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col className="text-right">
                    <TableHeaderCell active={activeCol === `Sum${q * 3 + m + 1}`} highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}>
                      {totals[`Sum${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                    </TableHeaderCell>
                  </Col>
                  {inBR && (
                  <Col className="font-italic text-right">
                    <TableHeaderCell active={activeCol === `SumBR${q * 3 + m + 1}`} highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}>
                      {totals[`SumBR${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                    </TableHeaderCell>
                  </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className="px-1 d-flex justify-content-center flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-md-center flex-grow-1"
              active={activeCol === 'Sum1' && onlyYear}
              highlighted={highlights.includes('Sum1') && onlyYear}
            >
              {totals.Sum.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            {inBR && (
            <TableHeaderCell
              className="text-right font-italic text-md-center flex-grow-1"
              active={activeCol === 'SumBR1' && onlyYear}
              highlighted={highlights.includes('SumBR1') && onlyYear}
            >
              {totals.SumBR.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    Sum: PropTypes.number,
    SumBR: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`Sum${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  inBR: PropTypes.bool,
};

TPFooter.defaultProps = {
  activeCol: '',
  onlyYear: false,
  inBR: false,
};

export default memo(TPFooter);
