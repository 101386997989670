import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  signMFO: { label: 'Предупреждение', name: 'Предупреждение' },
  R01G5: { label: 'R01G5', name: 'R01G5' },
  R01G6: { label: 'R01G6', name: 'R01G6' },
  R01G7: { label: 'R01G7', name: 'R01G7' },
  R01G8: { label: 'R01G8', name: 'R01G8' },
  R01G9: { label: 'R01G9', name: 'R01G9' },
  R01G10: { label: 'R01G10', name: 'R01G10' },
  R02G5: { label: 'R01G5', name: 'R02G5' },
  R02G6: { label: 'R01G6', name: 'R02G6' },
  R02G7: { label: 'R01G7', name: 'R02G7' },
  R02G8: { label: 'R01G8', name: 'R02G8' },
  R02G9: { label: 'R01G9', name: 'R02G9' },
  R02G10: { label: 'R01G10', name: 'R02G10' },
  R03G5: { label: 'R01G5', name: 'R03G5' },
  R03G6: { label: 'R01G6', name: 'R03G6' },
  R03G7: { label: 'R01G7', name: 'R03G7' },
  R03G8: { label: 'R01G8', name: 'R03G8' },
  R03G9: { label: 'R01G9', name: 'R03G9' },
  R03G10: { label: 'R01G10', name: 'R03G10' },
  R04G5: { label: 'R01G5', name: 'R04G5' },
  R04G6: { label: 'R01G6', name: 'R04G6' },
  R04G7: { label: 'R01G7', name: 'R04G7' },
  R04G8: { label: 'R01G8', name: 'R04G8' },
  R04G9: { label: 'R01G9', name: 'R04G9' },
  R04G10: { label: 'R01G10', name: 'R04G10' },
  R05G5: { label: 'R01G5', name: 'R05G5' },
  R05G6: { label: 'R01G6', name: 'R05G6' },
  R05G7: { label: 'R01G7', name: 'R05G7' },
  R05G8: { label: 'R01G8', name: 'R05G8' },
  R05G9: { label: 'R01G9', name: 'R05G9' },
  R05G10: { label: 'R01G10', name: 'R05G10' },
  R06G5: { label: 'R01G5', name: 'R06G5' },
  R06G6: { label: 'R01G6', name: 'R06G6' },
  R06G7: { label: 'R01G7', name: 'R06G7' },
  R06G8: { label: 'R01G8', name: 'R06G8' },
  R06G9: { label: 'R01G9', name: 'R06G9' },
  R06G10: { label: 'R01G10', name: 'R06G10' },
  R07G5: { label: 'R01G5', name: 'R07G5' },
  R07G6: { label: 'R01G6', name: 'R07G6' },
  R07G7: { label: 'R01G7', name: 'R07G7' },
  R07G8: { label: 'R01G8', name: 'R07G8' },
  R07G9: { label: 'R01G9', name: 'R07G9' },
  R07G10: { label: 'R01G10', name: 'R07G10' },
  R08G5: { label: 'R01G5', name: 'R08G5' },
  R08G6: { label: 'R01G6', name: 'R08G6' },
  R08G7: { label: 'R01G7', name: 'R08G7' },
  R08G8: { label: 'R01G8', name: 'R08G8' },
  R08G9: { label: 'R01G9', name: 'R08G9' },
  R08G10: { label: 'R01G10', name: 'R08G10' },
  PIDSTAVA_1: { label: 'PIDSTAVA_1', name: 'PIDSTAVA_1' },
  PIDSTAVA_2: { label: 'PIDSTAVA_2', name: 'PIDSTAVA_2' },
  inKEKV: { label: 'Розписати по КЕКВ (п 3.1)', name: 'РасписатьПоКЕКВ' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол2', name: 'Кол2' },
      col3: { label: 'Кол3', name: 'Кол3' },
      col4: { label: 'Кол4', name: 'Кол4' },
      col5: { label: 'Кол5', name: 'Кол5' },
      col6: { label: 'Кол6', name: 'Кол6' },
      col7: { label: 'Кол7', name: 'Кол7' },
      col8: { label: 'Кол8', name: 'Кол8' },
      col9: { label: 'Кол9', name: 'Кол9' },
      col10: { label: 'Кол10', name: 'Кол10' },
      col11: { label: 'Кол11', name: 'Кол11' },
      col12: { label: 'Кол12', name: 'Кол12' },
      col13: { label: 'Кол13', name: 'Кол13' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'prepareForecastAdd4',
  backendName: 'ПечатьПрогнозДодП4',
  label: 'Пропозиція до прогнозу місцевого бюджету (Форма ПП-3) (Додаток 4)',
  frontend: 'dp/prepareForecastAdd4',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
