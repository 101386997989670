import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Accordion,
  Button, Card, Col, Container, Row,
} from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionBody from 'react-bootstrap/AccordionBody';
import api from '../../../api/req';
import { CiatAppContext } from '../../../providers';
import UpdateGroup from './updateGroup';
import IconAlert from '../../../components/blanks/common/IconAlert';
import { Loader } from '../../../components/bootStrap';
import SoftBadge from '../../../components/blanks/common/SoftBadge';
import PageHeader from '../../../components/blanks/common/PageHeader';

function Editor() {
  const { auth } = useContext(CiatAppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [list, setList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const load = useCallback(
    async () => {
      const r = await api.get('/api/auth/permissiongroup/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );
  const loadRoles = useCallback(
    async () => {
      const r = await api.get('/api/auth/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );
  const reload = useCallback(() => {
    onLoadStart();
    load()
      .then((rData) => {
        setList(rData);
        return loadRoles();
      })
      .then((rData) => {
        setRoles(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [load, loadRoles, onError, onLoadEnd, onLoadStart]);

  useEffect(() => {
    reload();
  }, [reload]);

  const deleteGroup = useCallback((id) => {
    const loader = async () => {
      const r = await api.delete(`/api/auth/permissiongroup/${id}`, auth);
      if (!r.ok) throw new Error(`${r.status}: ${r.statusText}`);
      return true;
    };
    onLoadStart();
    loader()
      .then(() => load())
      .then((rData) => setList(rData))
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, load, onError, onLoadEnd, onLoadStart]);

  const handleOpenedUpdatePage = useCallback((id) => {
    setGroupId(id);
    setEditmode(true);
  }, []);
  const handleOpenedCreatePage = useCallback(() => {
    setGroupId(null);
    setEditmode(true);
  }, []);
  return (
    <>
      {loading && (<Loader />)}
      {err && (
        <IconAlert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </IconAlert>
      )}
      <Accordion activeKey={editmode ? '1' : '0'}>
        <AccordionItem eventKey="1">
          <AccordionBody>
            <PageHeader
              title={!groupId ? 'Створення групи:' : 'Редагування групи:'}
              description={!groupId ? 'Створення групи:' : 'Редагування групи:'}
              className="mb-3"
            />
            {editmode && (
              <UpdateGroup
                onClose={() => {
                  setEditmode(false);
                  setErr(null);
                }}
                onRefresh={reload}
                onSetErr={setErr}
                onSetLoading={setLoading}
                groupId={groupId}
                allRoles={roles}
              />
            )}
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="0">
          <AccordionBody>
            <PageHeader
              title="Перелік груп:"
              description="Перелік груп:"
              className="mb-3"
            />
            <Container fluid>
              <Row cols={3}>
                {list.map((el) => (
                  <Col key={el.id} className="mt-2">
                    <Card className="h-100">
                      <Card.Header>
                        <Card.Title>
                          {el.name}
                        </Card.Title>
                        <p className="text-muted small">
                          {el.description}
                        </p>
                      </Card.Header>
                      <Card.Body>
                        {el.rolepriorityforgroup_set.map((rp) => (
                          <SoftBadge bg="success" className="mx-1" key={rp.role.id}>{rp.role.repr}</SoftBadge>
                        ))}
                      </Card.Body>
                      <Card.Footer className="d-flex gap-2 justify-content-end">
                        <Button variant="falcon-success" onClick={() => handleOpenedUpdatePage(el.id)}>Редагувати</Button>
                        <Button variant="falcon-danger" onClick={() => deleteGroup(el.id)}>Видалити</Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
            <Button variant="falcon-primary" onClick={handleOpenedCreatePage} className="mt-2">
              Створити нову групу
            </Button>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default Editor;
