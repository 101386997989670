import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { getColor } from '../blanks/helpers/utils';
import { chartJsDefaultTooltip } from '../blanks/helpers/chartjs-utils';

function HalfDoughnutChart({
  color = 'primary',
  // colorTarget = 'warning',
  target = 50,
  reached = 50,
  // totalPlan = 50,
}) {
  const options = {
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: chartJsDefaultTooltip(),
    },
  };

  const part1Name = (reached > target) ? 'План на поточну дату' : 'Надійшло з початку року';
  const part2Name = (reached < target) ? 'Недовиконання на поточну дату' : 'Перевиконання з початку року';
  const part1 = (reached > target) ? target : reached;
  const part2 = (reached > target) ? reached : target;

  const data = {
    labels: [part1Name, part2Name],
    // labels: ['Надійшло з початку року', 'План на поточну дату', 'План на рік'],
    datasets: [
      {
        data: [part1, part2 - part1],
        // data: [reached, target - reached, totalPlan - target],
        // backgroundColor: [getColor(color), getColor(colorTarget), getColor('gray-300')],
        // borderColor: [getColor(color), getColor(colorTarget), getColor('gray-300')],
        backgroundColor: [getColor(color), getColor('gray-300')],
        borderColor: [getColor(color), getColor('gray-300')],
      },
    ],
  };

  return <Doughnut data={data} options={options} width="112" id="rev-Doughnut" />;
}

HalfDoughnutChart.propTypes = {
  target: PropTypes.number,
  reached: PropTypes.number,
  // totalPlan: PropTypes.number,
  color: PropTypes.string,
  // colorTarget: PropTypes.string,

};

HalfDoughnutChart.defaultProps = {
  target: null,
  reached: null,
  // totalPlan: null,
  color: '',
  // colorTarget: '',
};

export default HalfDoughnutChart;
