const frontendURL = '/srv/importExecution/';
const backendURL = '/api/logica/get-execution/';
const name = 'Виконання паспорту бюджетної програми';
const tableLabels = {
  doc: 'Тип документу',
  kfk: 'КФК',
  edrpou: 'ЄДРПОУ',
  org_name: 'Назва організації',
  kpkvkmb: 'КПКВ',
  actual: 'Актуальний',
  add_date: 'Дата додавання',
  id: 'ID',
};

const instance = {
  frontendURL,
  backendURL,
  name,
  tableLabels,
};

export default instance;
