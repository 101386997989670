import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { changeField } from '../../actions/editor';
import { DivCell } from '../styledTable/styledTable';
import GetNumInput from '../../containers/field/NumberInput/NumInput';

function SumInput({
  id, areaName, callback, isFocusCell, tableName, row, rowId, processingKeyDown, onlyPozitive, FractionSize, maxValue,
}) {
  const dispatch = useDispatch();

  // callback - необов'язкова додаткова перевірка, наприклад,
  // перевірка суми БР, яка, повинна бути меньша від загальної суми

  const onChangeSumField = useCallback(
    (e, v) => {
      dispatch(changeField([`tables/${tableName}`, rowId, areaName], v));

      if (callback) {
        callback(row, v, rowId);
      }
    },
    [areaName, callback, dispatch, row, rowId, tableName],
  );

  return (
    <DivCell
      areaName={areaName}
      key={`${rowId}Div${areaName}`}
      isFocusCell={isFocusCell}
      onKeyDown={(e) => {
        processingKeyDown(e, rowId, id);
      }}
      onClick={(e) => {
        if (typeof processingKeyDown !== 'undefined') processingKeyDown(e, rowId, id);
      }}
    >
      <GetNumInput
        fluid
        key={`${rowId}Sum${areaName}`}
        value={row.get(areaName, 0)}
        disableCalculator={false}
        onChangeSumField={onChangeSumField}
        isFocusCell={isFocusCell}
        onlyPozitive={onlyPozitive}
        onChange={onChangeSumField}
        FractionSize={FractionSize}
        maxValue={maxValue}
      />
    </DivCell>
  );
}

SumInput.propTypes = {
  id: PropTypes.number,
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  FractionSize: PropTypes.number,
  maxValue: PropTypes.number,
  isFocusCell: PropTypes.bool,
  onlyPozitive: PropTypes.bool,
  processingKeyDown: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  areaName: PropTypes.string,
  callback: PropTypes.func,
};

SumInput.defaultProps = {
  id: 0,
  areaName: '',
  isFocusCell: false,
  callback: null,
  onlyPozitive: false,
  FractionSize: 2,
  maxValue: 9999999999999999,
};

export default SumInput;
