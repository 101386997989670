import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { CiatAppContext } from '../../../providers';
import { BEGIN_OF_TIME, END_OF_TIME, longFormat } from '../../../constants/date';
import api from '../../../api/req';

const HISTORY_KEY = 'history';

const useHistory = ({
  data, registerSaveListener, backendURL, id, fields, onChange, historyKey = HISTORY_KEY,
  errors = null,
}) => {
  const isNew = id === 'create';

  // список историй
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [historiesLoaded, setHistoriesLoaded] = useState(true);

  const historyFields = useMemo(
    () => (fields ? fields[historyKey].children : null),
    [fields, historyKey],
  );

  const tFieldErrors = useMemo(
    () => ({
      ...fieldErrors,
      ...(errors[HISTORY_KEY] ? errors[HISTORY_KEY] : {}),
    }),
    [errors, fieldErrors],
  );

  useEffect(
    () => {
    },
    [errors, historyKey],
  );
  const historyData = useMemo(
    () => (data && data[historyKey] ? data[historyKey] : {}),
    [data, historyKey],
  );
  const hCurrent = useRef();
  hCurrent.current = historyData;

  useEffect(
    () => {
      if (backendURL && historyData) {
        setHistoriesLoaded(false);
        if (!isNew) {
          setHistories((o) => {
            if (o.filter((item) => item.value === historyData.datefrom).length) return o;
            return [historyData];
          });
          // setHistoryData(initialHistoryData);
        }
      }
    },
    [backendURL, historyData, id, isNew],
  );
  const historiesList = useMemo(
    () => histories.filter((d) => 'datefrom' in d).map((d) => {
      const d1 = new Date(d.datefrom);
      const d2 = new Date(d.dateto);
      d2.setHours(d2.getHours() - 24);
      let dd1 = d1.toLocaleString('uk', longFormat);
      let dd2 = d2.toLocaleString('uk', longFormat);
      if (d.datefrom === BEGIN_OF_TIME) dd1 = 'З самого початку';
      if (d.dateto === END_OF_TIME) dd2 = 'До самого кінця';
      return {
        value: d.datefrom,
        display_name: `${dd1} - ${dd2}`,
      };
    }),
    [histories],
  );

  const { auth } = useContext(CiatAppContext);

  const loadHistories = useCallback(
    async () => {
      setLoading(true);
      const r = await api.get(`${backendURL}${id}/history/`, auth);
      if (r.ok) {
        const hstrs = await r.json();
        setHistories(hstrs);
        setHistoriesLoaded(true);
        setLoading(false);
        return hstrs;
      }
      setErr(`${r.status} ${r.statusText}`);
      setLoading(false);
      return null;
    },
    [auth, backendURL, id],
  );
  const saveHistory = useCallback(
    /**
     *
     * @param itemId {string} - ID оъекта
     * @param savableData {{}} - данные
     * @param showLoading {boolean} - показывать визуальную часть
     * @returns {Promise<void>}
     */
    async (itemId, savableData = null, showLoading = true) => {
      if (showLoading) setLoading(true);
      const d = new Date(savableData.datefrom).toJSON().substr(0, 10)
        .replace('-', '')
        .replace('-', '');
      const r = await api.put(`${backendURL}${itemId}/${d}/history/ondate/`, auth, savableData);
      if (r.ok) {
        const saved = await r.json();
        // onChange(() => ({ [historyKey]: saved }));
        // setHistoryData(saved);
        setHistories((o) => {
          if (o.map((oo) => oo.datefrom).includes(saved.datefrom)) {
            return o.map((hi) => (hi.datefrom === saved.datefrom ? saved : hi));
          }
          return [...o, saved].sort((a, b) => {
            if (a.datefrom < b.datefrom) return -1;
            if (a.datefrom > b.datefrom) return 1;
            return 0;
          });
        });
        if (showLoading) setLoading(false);
        onChange(() => ({
          [historyKey]: saved,
        }));
        setErr(null);
        setFieldErrors({});
        return true;
      }
      if (showLoading) setLoading(false);
      if (r.status === 400) {
        const errs = await r.json();
        setFieldErrors(errs);
        throw new Error('Виникли помилки при заповненні даних');
      }
      setErr(`${r.status} ${r.statusText}`);
      throw new Error(`${r.status} ${r.statusText}`);
    },
    [auth, backendURL, historyKey, onChange],
  );

  useEffect(
    () => registerSaveListener(async (savedItem) => {
      if (!isNew) {
        await saveHistory(savedItem.id, hCurrent.current, false);
      }
      return true;
    }),
    [historyKey, isNew, onChange, registerSaveListener, saveHistory],
  );

  // ACTIONS //
  const onLoadHistories = useCallback(
    () => {
      if (!historiesLoaded) loadHistories();
    },
    [historiesLoaded, loadHistories],
  );

  const onSelectHistory = useCallback(
    (newDateFrom) => onChange(() => ({
      [historyKey]: histories.filter((h) => h.datefrom === newDateFrom).reduce((R, r) => r, {}),
    })),
    [histories, historyKey, onChange],
  );

  const onHistoryChange = useCallback(
    async (fData) => {
      onChange((oldData) => ({
        ...oldData,
        [historyKey]: {
          ...oldData[historyKey],
          ...fData(oldData[historyKey]),
        },
      }));
    },
    [historyKey, onChange],
  );

  const onSaveHistory = useCallback(
    async () => {
      if (!isNew) {
        onChange((d) => {
          saveHistory(data.id, d[historyKey]);
          return d;
        });
      }
    },
    [data.id, historyKey, isNew, onChange, saveHistory],
  );

  const createHistory = useCallback(
    async (datefrom) => {
      if (!isNew) {
        saveHistory(id, { ...historyData, datefrom });
      }
    },
    [historyData, id, isNew, saveHistory],
  );

  const deleteHistory = useCallback(
    async () => {
      setLoading(true);
      const d = new Date(historyData.datefrom).toJSON().substr(0, 10)
        .replace('-', '')
        .replace('-', '');
      const r = await api.delete(`${backendURL}${id}/${d}/history/ondate/`, auth);
      if (r.ok) {
        const newH = await loadHistories();
        if (newH.length) onChange(() => ({ [historyKey]: newH[0] }));
      } else if (r.status === 400) {
        const errD = await r.json();
        setErr(String(errD));
      } else {
        setErr(`${r.status} ${r.statusText}`);
      }

      setLoading(false);
    },
    [auth, backendURL, historyData.datefrom, historyKey, id, loadHistories, onChange],
  );

  const historyActions = useMemo(
    () => ({
      onCreateHistory: createHistory,
      deleteHistory,
      onHistoryChange,
      onLoadHistories,
      onSelectHistory,
      onSaveHistory,
      onDeleteHistory: deleteHistory,
    }),
    [createHistory, deleteHistory, onHistoryChange, onLoadHistories,
      onSaveHistory, onSelectHistory],
  );
  return ({
    historyData,
    historyActions,
    // histories,
    historiesList,
    historyFields,
    historyFieldErrors: {
      ...tFieldErrors,
      ...data.fieldErrors,
    },
    historyLoading: loading,
    err,
    isNew,

  });
};

export default useHistory;
