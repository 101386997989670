import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import styled from 'styled-components';
import DateInput from './dates';
import PeriodSelection from '../../constants/meta/enums/periodSelection';
import InputWithDropdown from '../styledInputs/inputWithDropdown';
import { getDatesByVariant } from './getDatesByVariant';

const PeriodSelectionValues = Object.values(PeriodSelection);

export const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-gap: 2%;
`;

function DateRangeField({ value, onChange }) {
  return (
    <StyledDiv>
      <InputWithDropdown
        value={value.get('Variant', PeriodSelection.Custom.name)}
        options={PeriodSelectionValues}
        onChange={(e, v) => onChange(e, value.set('Variant', v).merge(getDatesByVariant(v)))}
      />
      <DateInput
        value={value.get('StartDate', 0)}
        onChange={(e, v) => onChange(e, value.set('StartDate', v).set('Variant', PeriodSelection.Custom.name))}
        placeholder="Початок періоду"
      />
      <DateInput
        value={value.get('EndDate', 0)}
        onChange={(e, v) => onChange(e, value.set('EndDate', v).set('Variant', PeriodSelection.Custom.name))}
        placeholder="Кінець періоду"
        endOfDay
      />
    </StyledDiv>
  );
}

DateRangeField.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func,
};

DateRangeField.defaultProps = {
  onChange: () => null,
};
export default DateRangeField;
