/* eslint-disable max-len */
import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import SoftBadge from '../../../../components/blanks/common/SoftBadge';
import corner1 from '../../../../assets/img/illustrations/corner-1.png';
import Background from '../../../../components/blanks/common/Background';
import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../../assets/img/illustrations/corner-3.png';
import RevenueTable from './revenueTable';
import CoreDetailKdb from './CoreKdb/CoreDetailKdb';
import DynamicKdb from './DynamicKdb/DynamicKdb';
import PlanFactRevenue from './PlanFactRevenue';
import PlanFactBarCart from './PlanFactBarCart';
import PlanMonthFactRevenue from './PlanMonthFactRevenue';

import { Loader } from '../../../../components/bootStrap';
import useFetchRevenueData from '../Hooks/useFetchRevenueData';

function Revenue({ cDt, settings }) {
  const {
    isLoading, err, revenueData, dataByFond, dataCompare, compareFact, compareRevenuePlanFact,
  } = useFetchRevenueData({ cDt });

  const displayRevenueSettings = settings.display_revenue_settings;
  return (
    <>
      {isLoading && (
        <Loader text="Завантаження" />
      )}

      <Row className="g-3">
        {displayRevenueSettings.cardTotal && (
          <Col xxl={4} xl={4} md={6} lg={6} className="mt-0 d-flex flex-column gap-3 mb-2">
            <Card className="bg-transparent-50 overflow-hidden">
              <Background
                image={corner1}
                className="bg-card"
              />
              <Card.Header>
                <div>
                  <h4 className="text-warning mb-0">Доходи загального фонду</h4>
                  <h6 className="text-700">
                    без урахування міжбюджетних трансфертів
                  </h6>
                  <div className="d-flex flex-wrap gap-2 align-items-baseline mt-3">
                    <SoftBadge bg="warning" pill>
                      <FontAwesomeIcon icon={
                      dataByFond.zf.percent > 0 ? faCaretUp : faCaretDown
                    }
                      />
                      {dataByFond.zf.percent > 0 ? dataByFond.zf.percent : -1 * dataByFond.zf.percent}
                      %
                    </SoftBadge>
                    <h6 className="text-700">
                      від
                      {' '}
                      {parseInt(cDt.substring(0, 4), 10) - 1}
                      р.
                    </h6>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="position-relative">
                <div
                  className={classNames(
                    'text-warning',
                    'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                  )}
                >
                  <CountUp
                    start={0}
                    end={dataByFond.zf.total}
                    duration={2.75}
                    suffix=" тис. грн."
                    err={err}
                    prefix=""
                    separator=" "
                    decimals={1}
                    decimal=","
                  />
                </div>
              </Card.Body>

            </Card>

            <Card className="bg-transparent-50 overflow-hidden">
              <Background
                image={corner2}
                className="bg-card"
              />
              <Card.Header>
                <div>
                  <h4 className="text-info mb-0">Доходи спеціального фонду</h4>

                  <div className="d-flex flex-wrap gap-2 align-items-baseline mt-3">
                    <SoftBadge bg="success" pill>
                      <FontAwesomeIcon icon={
                      dataByFond.sf.percent > 0 ? faCaretUp : faCaretDown
                    }
                      />
                      {dataByFond.sf.percent > 0 ? dataByFond.sf.percent : -1 * dataByFond.sf.percent}
                      %
                    </SoftBadge>
                    <h6 className="text-700">
                      від
                      {' '}
                      {parseInt(cDt.substring(0, 4), 10) - 1}
                      р.
                    </h6>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="position-relative">
                <div
                  className={classNames(
                    'text-info',
                    'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                  )}
                >
                  <CountUp
                    start={0}
                    end={dataByFond.sf.total}
                    duration={2.75}
                    suffix=" тис. грн."
                    err={err}
                    prefix=""
                    separator=" "
                    decimals={1}
                    decimal=","
                  />
                </div>
              </Card.Body>

            </Card>

            <Card className="bg-transparent-50 overflow-hidden">
              <Background
                image={corner3}
                className="bg-card"
              />
              <Card.Header>
                <div>
                  <h5 className="text-black text-700 mb-0">Бюджет розвитку, що є частиною спец. фонду</h5>
                  <div className="d-flex flex-wrap gap-2 align-items-baseline mt-3">
                    <SoftBadge bg="warning" pill>
                      <FontAwesomeIcon icon={
                        dataByFond.br.percent > 0 ? faCaretUp : faCaretDown
                      }
                      />
                      {dataByFond.br.percent > 0 ? dataByFond.br.percent : -1 * dataByFond.br.percent}
                      %
                    </SoftBadge>
                    <h6 className="text-700">
                      від
                      {' '}
                      {parseInt(cDt.substring(0, 4), 10) - 1}
                      р.
                    </h6>

                  </div>
                </div>
              </Card.Header>
              <Card.Body className="position-relative">
                <div
                  className={classNames(
                    'text-black text-700',
                    'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                  )}
                >
                  <CountUp
                    start={0}
                    end={dataByFond.br.total}
                    duration={2.75}
                    suffix=" тис. грн."
                    err={err}
                    prefix=""
                    separator=" "
                    decimals={1}
                    decimal=","
                  />
                </div>
              </Card.Body>

            </Card>

          </Col>
        )}
        <Col xxl={displayRevenueSettings.cardTotal ? 4 : 6} xl={displayRevenueSettings.cardTotal ? 4 : 6} md={12} lg={6} className="mt-0  mb-2">
          <CoreDetailKdb
            title={`Доходи загального фонду бюджету на ${cDt.substring(0, 4)} рік, тис.грн.`}
            data={revenueData.zf.values}
            total={revenueData.zf.total}
          />
        </Col>
        <Col lg={6} className="d-none d-lg-block d-xl-none mb-2">
          &nbsp;
        </Col>
        <Col xxl={displayRevenueSettings.cardTotal ? 4 : 6} xl={displayRevenueSettings.cardTotal ? 4 : 6} md={12} lg={6} className="mt-0 mb-2">
          <CoreDetailKdb
            title={`Доходи спеціального фонду бюджету на ${cDt.substring(0, 4)} рік, тис.грн.`}
            data={revenueData.sf.values}
            total={revenueData.sf.total}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-0 d-none d-md-flex">
        <Col md={12} lg={12} xxl={12}>
          <DynamicKdb
            data={dataCompare}
            cDt={cDt}
          />
        </Col>
      </Row>
      {displayRevenueSettings.chartPlanFactEveryMonth ? (
        <>
          <Row className="g-3 mt-1 ">
            <Col md={12} lg={8} className="d-none d-sm-block">
              <PlanFactBarCart
                data={compareFact}
              />
            </Col>
            <Col>
              <PlanFactRevenue
                data={compareFact}
              />
            </Col>
          </Row>
          <Row className="g-3 mt-1 ">
            <Col className="d-none d-sm-block">
              <PlanMonthFactRevenue
                data={compareFact}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="g-3 mt-3">
          <Col
            xxl={9}
            xl={9}
            md={12}
            lg={12}
            className="mt-0 mb-2 d-none d-lg-block"
          >
            <PlanMonthFactRevenue
              data={compareFact}
            />
          </Col>
          <Col
            xxl={3}
            xl={3}
            md={12}
            lg={12}
            className="mt-0 mb-2 d-none d-lg-block"
          >
            <PlanFactRevenue
              data={compareFact}
              totalPlan={revenueData.zf.total + revenueData.sf.total}
            />
          </Col>
        </Row>
      ) }

      <Row className="g-3 mt-0 d-none d-lg-flex">
        <Col md={12} lg={12} xxl={12} className="d-flex justify-content-between flex-column">
          <RevenueTable
            data={compareRevenuePlanFact}
          />
        </Col>
      </Row>
    </>
  );
}

Revenue.propTypes = {
  cDt: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    display_revenue_settings: PropTypes.shape({
      cardTotal: PropTypes.bool,
      chartPlanFactEveryMonth: PropTypes.bool,
    }),
  }).isRequired,
};

export default Revenue;
