import { Map, List } from 'immutable';
import notifierActions from '../constants/actions/notifier';

// Create generator for initialState from `const/meta/*` with types
const initialState = Map({
  files: new List(),
  messages: new Map({
    messages: new List(),
    isLoading: false,
    isErrored: false,
    errMessage: null,
  }),
});

/**
 * @desc Reducer provides info about store files, user messages etc
 * @func
 * @param state {Immutable.Map}
 * @param action {object}
 * @param action.type {string}
 * @param action.payload {object}
 * @return {Immutable.Map}
 */
const notifierReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case notifierActions.newFile:
      return state
        .set('files', state.get('files').insert(0, new Map({
          fileName: payload.fileName,
          content: payload.content,
          type: payload.type || 'application/octet-stream',
          description: payload.description,
        })));
    case notifierActions.removeFile:
      return state
        .set('files', state.get('files').delete(payload));
    case notifierActions.clearFiles:
      return state
        .set('files', new List());
    case notifierActions.loadMessagesStart:
      return state
        .setIn(['messages', 'isLoading'], true)
        .setIn(['messages', 'isErrored'], false)
        .setIn(['messages', 'errMessage'], null);
    case notifierActions.loadMessagesEnd:
      return state
        .setIn(['messages', 'messages'], payload)
        .setIn(['messages', 'isLoading'], false)
        .setIn(['messages', 'isErrored'], false)
        .setIn(['messages', 'errMessage'], null);
    case notifierActions.loadMessagesErr:
      return state
        .setIn(['messages', 'isLoading'], false)
        .setIn(['messages', 'isErrored'], true)
        .setIn(['messages', 'errMessage'], payload);
    case notifierActions.updateMessages:
      return state
        .setIn(['messages', 'messages'], payload);
    default:
      return state;
  }
};

export default notifierReducer;
