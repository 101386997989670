import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  // FormType: { label: 'Тип Формы', name: 'ТипФормы' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  PrintKVKTotals: { label: 'Друк підсумків за КВК', name: 'ПечатьИтоговКВК' },
  PrintKBP7Totals: { label: 'Друк підсумків за КПКВ', name: 'ПечатьИтоговКФК' },
  PageOrientation: { label: 'Орієнтація сторінки', name: 'ОриентацияСтраницыПриПечати' },
  DIVCSUs: { label: 'Відокремлювати розпорядників', name: 'ОтделятьРаспорядителей' },
  CSUTotals: { label: 'Підсумки за установами', name: 'ИтогиПоРаспорядителям' },
  ByGeneralCSU: { label: 'За головним розпорядником', name: 'ПоГлавномуРаспорядителю' },
  KEKVTotals: { label: 'Підсумково за КЕКВ', name: 'ИтогиПоКЕКВ' },
  ThisIsRasp: { label: 'Розпорядження', name: 'ЭтоРаспоряжение' },
  ThisIsRequest: { label: 'Заявка', name: 'ЭтоЗаявка' },
  ShowPrintTime: { label: 'Показувати час друку', name: 'флПоказатьВремяПечати' },
  ShowGrid: { label: 'Показувати сітку', name: 'флПоказыватьСетку' },
  ByFI: { label: 'За розп.вищ. рівня', name: 'ПоФинансовомуОргану' },
  KBP7Totals: { label: 'Підсумки за КПКВ', name: 'ИтогиПоКФК' },
  KievForm5: { label: 'Форма 5', name: 'КиевФорма5' },
  KievForm5Rasp: { label: 'Форма розпорядження', name: 'КиевФорма5Распоряжение' },
  CSUCode: { label: 'Код розп.', name: 'КодРаспорядителя' },
  FillAllRows: { label: 'Заповнювати всі рядки', name: 'ЗаполнитьВсеСтроки' },
  QuantOfCopies: { label: 'К-сть екземплярів', name: 'КвоЭкземпляров' },
  ThisIsTransfer: { label: 'Передача коштів з заг. до спец. фонду', name: 'ЭтоПередачаСтредств' },
  TwoCopiesByPage: { label: 'Два екз. на одну стор.', name: 'ДваЭкземпляраНаСтраницу' },
  GroupTable: { label: 'Згорнути табличну частину', name: 'СвернутьТЧ' },
  ExtendedRequest: { label: 'Форма заявки', name: 'флРасширеннаяЗаявка' },
  AdditionalKEKV: { label: 'Додатково звести за КЕКВ', name: 'флДопРасшифровкаПоКЕКВ' },
  FormDnipro: { label: 'Форма заявки без деталізації', name: 'ПечатьФормаДнепр' },
  MoneysCode: { label: 'Код коштів', name: 'КодДенег' },
  ShowSumWords: { label: 'Показати суму прописом', name: 'ПоказатьСуммуПрописью' },
  CSUTotalDown: { label: 'Всього за розпорядником внизу', name: 'флИтогРаспорядителяВнизу' },
  Accepted: { label: 'Правий верхній кут', name: 'СогласованоУтверждено' },
  SignDate: { label: 'Дата підпису', name: 'ДатаПодписи' },
  RequestMode: { label: 'Режим заявки', name: 'РежимЗаявки' },
  PrintKPKV: { label: 'Друкувати КПКВ', name: 'ПечататьКПКВ' },
  CommercialBank: { label: 'Розпорядження/Розподіл коштів БР,які обслуговуються в установах банків', name: 'БР_КомерческийБанк' },
  TempStoprageAddr: { label: 'Адрес временного хранилища', name: 'АдресВременногоХранилища' },
  ForWeb: { label: 'Для експорту на ВЕБ сторінку', name: 'ДляВеб' },
  ByPage: { label: 'На странице', name: 'НаСтранице' },
  ThisIsOffer: { label: 'Проопозиція', name: 'ЭтоПредложение' },
  App31: { label: 'Додаток 31', name: 'флПриложение31' },
  CSUNameAbbr: { label: 'Скорочена назва розпорядника', name: 'НаимРаспСокращенный' },
  PrintRequestNew: { label: 'Новая  форма заявки', name: 'ПечатьЗаявкиНов' },
  TerrCode: { label: 'Код територии для фин', name: 'КодТериторииДляФин' },
  FitToPage: { label: 'Помістити на сторінці', name: 'УместитьНаСтраницу' },
  ColWidths: { label: 'Ширина колонок', name: 'ШиринаКолонок' },
  ByKEKVElements: { label: 'За деталізацією КЕКВ', name: 'ПоЕлКЕКВ' },
  PrintTotalNewMoskow: { label: 'Друк підсумків (Новомосковськ)', name: 'ПечатьИтоговНовомосковск' },
  ShowClients: { label: 'Постачальники детально', name: 'флПоказатьПоставщиков' },
  RepeatHeaderInPrint: { label: 'Повторювати шапку на кожній сторінці', name: 'ПовторятьШапкуНаКаждойСтранице' },
  // Дополнительные Свойства
  FormDniproVisible: { label: 'ПечатьФормаДнепрВидимость', name: 'ПечатьФормаДнепрВидимость' },
  ExtendedRequestVisible: { label: 'флРасширеннаяЗаявкаВидимость', name: 'флРасширеннаяЗаявкаВидимость' },
  CommercialBankVisible: { label: 'БР_КомерческийБанкВидимость', name: 'БР_КомерческийБанкВидимость' },
  ShowClientsVisible: { label: 'флПоказатьПоставщиковВидимость', name: 'флПоказатьПоставщиковВидимость' },
  FinGroupVisibleVisible: { label: 'ГруппаФинансированиеВидимость', name: 'ГруппаФинансированиеВидимость' },
  CSUCodeVisible: { label: 'КодРаспорядителяВидимость', name: 'КодРаспорядителяВидимость' },
  FillAllRowsVisible: { label: 'ЗаполнитьВсеСтрокиВидимость', name: 'ЗаполнитьВсеСтрокиВидимость' },
  KievForm5RaspVisible: { label: 'КиевФорма5РаспоряжениеВидимость', name: 'КиевФорма5РаспоряжениеВидимость' },
  TwoCopiesByPageVisible: { label: 'ДваЭкземпляраНаСтраницуВидимость', name: 'ДваЭкземпляраНаСтраницуВидимость' },
  QuantOfCopiesVisible: { label: 'КвоЭкземпляровВидимость', name: 'КвоЭкземпляровВидимость' },
  ThisIsTransferVisible: { label: 'ЭтоПередачаСтредствВидимость', name: 'ЭтоПередачаСтредствВидимость' },
  DocumentsVisible: { label: 'ТЧДокументыВидимость', name: 'ТЧДокументыВидимость' },
  RaspSettingsGroupVisible: { label: 'ГруппаНастройкаРаспоряженияВидимость', name: 'ГруппаНастройкаРаспоряженияВидимость' },
  DistSettingsGroupVisible: { label: 'ГруппаНастройкаРаспределенияВидимость', name: 'ГруппаНастройкаРаспределенияВидимость' },
  ShowSumWordsVisible: { label: 'ПоказатьСуммуПрописьюВидимость', name: 'ПоказатьСуммуПрописьюВидимость' },
  AcceptedVisible: { label: 'СогласованоУтвержденоВидимость', name: 'СогласованоУтвержденоВидимость' },
  SignDateVisible: { label: 'ДатаПодписиВидимость', name: 'ДатаПодписиВидимость' },
  SeparateCSU: { label: 'ОтделятьРаспорядителейВидимость', name: 'ОтделятьРаспорядителейВидимость' },
  FormCaption: { label: 'ЗаголовокФормы', name: 'ЗаголовокФормы' },
  firstNameLastName: { label: 'Прізвище та ім\'я', name: 'флИмяФамилия' },
  printKOATUU: { label: 'Друкувати код  КОАТУУ як код бюджету', name: 'печататьКОАТУУ' },
  PrintNumberName: { label: 'Друкувати номер і назву рахунку', name: 'ДписатьСчетИНомер' },
};

const tables = {
  data: {
    name: 'ТчДокументы',
    label: 'Документи',
    columns: {
      Notes: { label: 'Примітка', name: 'Примечание' },
      Document: { label: 'Документ', name: 'Документ' },
    },
  },
  dataTable: {
    name: 'ТаблицаДанных',
    label: 'Основне',
    columns: {
      RNo: { label: 'Номер', name: 'РегНомер', visible: true },
      date: { label: 'Дата', name: 'Дата', visible: true },
      Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
      FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
      FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
      FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
      CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
      CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      Note: { label: 'Примітка', name: 'Примечание' },
      Sum: { label: 'Сума', name: 'Сумма' },
      SumPayment: { label: 'Сумма оплата', name: 'СуммаОплата' },
      Balance: { label: 'Залишок  асигнувань', name: 'Ост' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'finPrinting',
  backendName: 'ПечатьФинансирования',
  label: 'Друк',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
