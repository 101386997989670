import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  DivCell, InputCodeStyled, SumInputStyled,
} from '../styledTable/styledTable';
import editor from '../../actions/editor';
import dpEditor from '../../actions/dpEditor';
import { InputStyled } from './textEdit';
import ItemField from '../../containers/field/selectorField/item/itemField';
import CheckboxStyled from './checkbox';
import SumInput from './sumInput';
import InputWithDropdown from '../styledInputs/inputWithDropdown';
import Fields from '../../containers/field';
import DateInput from '../fields/dates';

const TextView = Fields.GetTextInput();

function ComponentsRowTablePart({
  row, rowId, processingKeyDown, arrBypassGrid, tableName, componentsType, activeNameCell, containerRef,
}) {
  const isActive = row.get('IS_ACTIVE', false);
  const dispatch = useDispatch();

  const getIsFocusCell = useCallback((nameCell) => (isActive && (activeNameCell === nameCell)), [activeNameCell, isActive]);

  const {
    selectField: selectFields,
    sumView,
    sumField,
    yearField,
    codeStyle,
    textEdit,
    catView,
    check,
    dropDown,
    textView,
    dateEdit,
    CustomComp,
    typeEditor,
  } = componentsType;

  const selectField = selectFields(row);

  const changeField = (typeEditor === 'dpEditor') ? dpEditor.changeField : editor.changeField;
  const processServerReq = (typeEditor === 'dpEditor') ? dpEditor.processServerReq : editor.processServerReq;

  const SelectFiedComp = useMemo(() => (
    <>
      {
        selectField && Object.keys(selectField).map((elSelectField) => {
          const {
            areaName,
            noBackground,
            noBorder,
            noHierarchy,
            modelName,
            modelType,
            nameServerReq,
            filter,
            canCleared,
            canOpen,
            rows,
            disabled = () => false,
            params,
          } = selectField[elSelectField];

          const getFilter = () => ((typeof filter === 'object') ? filter.map((e) => {
            if (typeof e.value === 'function') {
              const newValue = e.value(row);
              e.value = newValue;
            }
            return e;
          }) : filter);

          const id = arrBypassGrid.indexOf(areaName);

          const isDisabled = (typeof disabled === 'boolean') ? disabled : disabled(rowId);

          return isDisabled ? (
            <DivCell
              areaName={areaName}
              key={`${rowId}Div${areaName}`}
            >
              <ItemField
                noBackground
                noBorder
                key={`${rowId}Item${areaName}`}
                value={row.get(areaName, new Map())}
              />
            </DivCell>
          )
            : (
              <DivCell
                areaName={areaName}
                key={`${rowId}Div${areaName}`}
                isFocusCell={getIsFocusCell(areaName)}
                onKeyDown={(e) => {
                  processingKeyDown(e, rowId, id);
                }}
                onClick={(e) => {
                  if (processingKeyDown) processingKeyDown(e, rowId, id);
                }}
              >
                <Fields.SelectorField
                  noBackground={noBackground || false}
                  noBorder={noBorder || false}
                  key={`${rowId}Select${areaName}`}
                  modelName={modelName}
                  modelType={modelType || 'cat'}
                  value={row.get(areaName, new Map())}
                  onChange={(e, v) => {
                    dispatch(changeField([`tables/${tableName}`, rowId, areaName], v));
                    dispatch(processServerReq(nameServerReq, { rowId }));
                  }}
                  filter={getFilter()}
                  params={params}
                  noHierarchy={noHierarchy || false}
                  isFocusCell={getIsFocusCell(areaName)}
                  rows={rows}
                  canCleared={canCleared}
                  canOpen={canOpen}
                  containerRef={containerRef}
                />
              </DivCell>
            );
        })
      }
    </>
  ), [selectField, arrBypassGrid, rowId, row, getIsFocusCell, containerRef, processingKeyDown, dispatch, changeField, tableName, processServerReq]);

  const SumViewComp = useMemo(() => (
    <>
      {
        sumView && Object.keys(sumView).map((elName) => (
          <DivCell
            areaName={sumView[elName]}
            key={`${rowId}Div${sumView[elName]}`}
          >
            <SumInputStyled
              value={row.get(sumView[elName], 0)}
              key={`${rowId}Sum${sumView[elName]}`}
              disabled
            />
          </DivCell>
        ))
      }
    </>
  ), [sumView, rowId, row]);

  const CodeStyleComp = useMemo(() => (
    <>
      {
        codeStyle && Object.keys(codeStyle).map((elName) => (
          <DivCell
            areaName={codeStyle[elName]}
            key={`${rowId}Div${codeStyle[elName]}`}
          >
            <InputCodeStyled
              key={`${rowId}Inp${codeStyle[elName]}`}
              value={row.get(codeStyle[elName], '')}
              readOnly
            />
          </DivCell>
        ))
      }
    </>
  ), [codeStyle, rowId, row]);

  const SumFieldComp = useMemo(() => (
    <>
      {
        sumField && Object.keys(sumField).map((elSum) => {
          const {
            areaName,
            nameServerReq,
            callback,
            onlyPozitive,
            FractionSize,
            maxValue,
            disabled = () => false,
          } = sumField[elSum];
          const id = arrBypassGrid.indexOf(areaName);
          const isFocusCell = getIsFocusCell(areaName);

          return disabled(rowId) ? (

            <DivCell
              areaName={areaName}
              key={`${rowId}Div${areaName}`}
            >
              <SumInputStyled
                value={row.get(areaName, 0)}
                key={`${rowId}Sum${areaName}`}
                disabled
              />
            </DivCell>
          )
            : (
              <SumInput
                key={`${rowId}Sm${elSum}`}
                id={id}
                areaName={areaName}
                nameServerReq={nameServerReq}
                callback={callback}
                isFocusCell={isFocusCell}
                tableName={tableName}
                rowId={rowId}
                row={row}
                onlyPozitive={onlyPozitive}
                processingKeyDown={processingKeyDown}
                FractionSize={FractionSize}
                maxValue={maxValue}
              />
            );
        })
      }
    </>
  ), [sumField, arrBypassGrid, getIsFocusCell, rowId, tableName, row, processingKeyDown]);

  const YearFieldComp = useMemo(() => (
    <>
      {
        yearField && Object.keys(yearField).map((elYear) => {
          const {
            areaName, nameServerReq,
          } = yearField[elYear];

          return (
            <DivCell
              areaName={areaName}
              isFocusCell={getIsFocusCell(areaName)}
              key={`${rowId}YearDiv${areaName}`}
              onKeyDown={(e) => {
                processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
              }}
              onClick={(e) => {
                if (processingKeyDown) processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
              }}
            >
              <Fields.YearField
                key={`${rowId}YearField${areaName}`}
                value={row.get(areaName, 0)}
                onChange={(e, v) => {
                  dispatch(changeField([`tables/${tableName}`, rowId, areaName], v));
                  dispatch(processServerReq(nameServerReq, { rowId }));
                }}
                disableCalculator
              />
            </DivCell>

          );
        })
      }
    </>
  ), [yearField, getIsFocusCell, rowId, row, processingKeyDown, arrBypassGrid, dispatch, changeField, tableName, processServerReq]);

  const DropDownComp = useMemo(() => (
    <>
      {
        dropDown && Object.keys(dropDown).map((el) => {
          const { areaName, options } = dropDown[el];
          return (
            <DivCell
              areaName={areaName}
              isFocusCell={getIsFocusCell(areaName)}
              key={`${rowId}DropDwnDiv${areaName}`}
              onKeyDown={(e) => {
                processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
              }}
              onClick={(e) => {
                if (processingKeyDown) processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
              }}
            >
              <InputWithDropdown
                noBorder
                noBackground
                key={`${rowId}DropDwnInp${areaName}`}
                value={row.get(areaName, '<>')}
                options={options}
                onChange={(e, v) => {
                  dispatch(changeField([`tables/${tableName}`, rowId, areaName], v));
                }}
                isFocusCell={getIsFocusCell(areaName)}
                processingKeyDown={processingKeyDown}
              />
            </DivCell>
          );
        })
      }
    </>
  ), [dropDown, getIsFocusCell, rowId, row, processingKeyDown, arrBypassGrid, dispatch, changeField, tableName]);

  const TextEditComp = useMemo(() => (
    <>
      {textEdit && Object.keys(textEdit).map((el) => {
        const { areaName, rows, wrap } = textEdit[el];
        return (
          <DivCell
            areaName={areaName}
            key={`${rowId}DivTxt${areaName}`}
            isFocusCell={getIsFocusCell(areaName)}
            onKeyDown={(e) => {
              processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
            }}
            onClick={(e) => {
              if (processingKeyDown) processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
            }}
          >
            <InputStyled
              fluid
              key={`${rowId}InpTxt${areaName}`}
              value={row.get(areaName, '')}
              onChange={(e, v) => {
                dispatch(changeField([`tables/${tableName}`, rowId, areaName], v));
              }}
              isFocusCell={getIsFocusCell(areaName)}
              rows={rows}
              wrap={wrap}
            />
          </DivCell>
        );
      })}
    </>
  ), [textEdit, rowId, getIsFocusCell, row, processingKeyDown, arrBypassGrid, dispatch, changeField, tableName]);

  const TextViewComp = useMemo(() => (
    <>
      {
        textView && Object.keys(textView).map((elName) => {
          const { value, areaName, ...props } = textView[elName];
          return (
            <DivCell
              areaName={areaName}
              key={`${rowId}DivTxtV${areaName}`}
            >
              <TextView
                value={row.get(value, '')}
                key={`${rowId}txtView${areaName}`}
                disabled
                {...props}
              />
            </DivCell>
          );
        })
      }
    </>
  ), [textView, rowId, row]);

  const CatViewComp = useMemo(() => (
    <>
      {catView && Object.keys(catView).map((elCatView) => (
        <DivCell
          areaName={catView[elCatView]}
          key={`${rowId}Div${catView[elCatView]}`}
        >
          <ItemField
            noBackground
            noBorder
            key={`${rowId}Item${catView[elCatView]}`}
            value={row.get(catView[elCatView], new Map())}
          />
        </DivCell>
      ))}
    </>
  ), [catView, rowId, row]);

  const CheckComp = useMemo(() => (
    <>
      {check && Object.keys(check).map((elCheck) => {
        const { areaName, callback = () => null } = check[elCheck];
        return (
          <DivCell
            areaName={areaName}
            key={`${rowId}Div${areaName}`}
            isFocusCell={getIsFocusCell(areaName)}
            onKeyDown={(e) => {
              processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
            }}
            onClick={(e) => {
              processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
            }}
          >
            <CheckboxStyled
              value={row.get(areaName, false)}
              onChange={(e, value) => {
                dispatch(changeField([`tables/${tableName}`, rowId, areaName], value));
                callback({ value, row, rowId });
              }}
              isFocusCell={getIsFocusCell(areaName)}
              onKeyDown={(e) => {
                processingKeyDown(e, rowId, arrBypassGrid.indexOf(areaName));
              }}
            />
          </DivCell>

        );
      })}

    </>
  ), [check, rowId, getIsFocusCell, row, processingKeyDown, arrBypassGrid, dispatch, changeField, tableName]);

  const DateComp = useMemo(() => (
    <>
      {dateEdit && Object.keys(dateEdit).map((el) => (
        <DivCell
          areaName={dateEdit[el]}
          key={`${rowId}Div${dateEdit[el]}`}
          isFocusCell={getIsFocusCell(dateEdit[el])}
          onKeyDown={(e) => {
            processingKeyDown(e, rowId, arrBypassGrid.indexOf(dateEdit[el]));
          }}
          onClick={(e) => {
            processingKeyDown(e, rowId, arrBypassGrid.indexOf(dateEdit[el]));
          }}
        >
          <DateInput
            noBorder
            background
            key={`${rowId}DateInput${dateEdit[el]}`}
            value={row.get(dateEdit[el], null)}
            onChange={(e, value) => {
              dispatch(changeField([`tables/${tableName}`, rowId, dateEdit[el]], value));
            }}
            isFocusCell={getIsFocusCell(dateEdit[el])}
          />
        </DivCell>

      ))}

    </>
  ), [dateEdit, rowId, getIsFocusCell, row, processingKeyDown, arrBypassGrid, dispatch, changeField, tableName]);
  return (
    <>
      {SelectFiedComp}

      {CodeStyleComp}

      {SumFieldComp}
      {SumViewComp}

      {YearFieldComp}

      {TextEditComp}
      {TextViewComp}

      {CatViewComp}

      {CheckComp}

      {DateComp}

      {DropDownComp}

      {CustomComp && CustomComp({
        row, rowId, processingKeyDown, activeNameCell,
      })}

    </>
  );
}

export default ComponentsRowTablePart;

ComponentsRowTablePart.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  arrBypassGrid: PropTypes.arrayOf(PropTypes.string),
  processingKeyDown: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  componentsType: PropTypes.shape({
    selectField: PropTypes.func,
    sumView: PropTypes.arrayOf(PropTypes.string),
    sumField: PropTypes.arrayOf(PropTypes.object),
    yearField: PropTypes.arrayOf(PropTypes.object),
    codeStyle: PropTypes.arrayOf(PropTypes.string),
    textEdit: PropTypes.arrayOf(PropTypes.object),
    catView: PropTypes.arrayOf(PropTypes.object),
    check: PropTypes.arrayOf(PropTypes.string),
    dropDown: PropTypes.arrayOf(PropTypes.object),
    textView: PropTypes.arrayOf(PropTypes.object),
    dateEdit: PropTypes.arrayOf(PropTypes.string),
    CustomComp: PropTypes.arrayOf(PropTypes.object),
    typeEditor: PropTypes.string,
  }),
  activeNameCell: PropTypes.string,
};

ComponentsRowTablePart.defaultProps = {
  activeNameCell: '',
  componentsType: {},
  arrBypassGrid: [],
};
