import React, { useState } from 'react';
import {
  Alert,
  Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/liquidityVer2';
import Liquidity from './editor';
import APMenu from './menu';
import LiquidityTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function LiquidityEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);
  const [message, setMessage] = useState('');

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
        {!!message.length && (
          <Alert
            variant="info"
            dismissible
            onClose={() => setMessage('')}
          >
            <FontAwesomeIcon icon={faCheck} className="me-3" pull="left" size="4x" />
            <Alert.Heading>
              {message}
            </Alert.Heading>
          </Alert>
        )}
        <Card.Header>
          <Liquidity
            data={data}
            actions={actions}
            permissions={permissions}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <LiquidityTabs
            data={data}
            actions={actions}
            permissions={permissions}
            setMessage={setMessage}
          />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

LiquidityEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

LiquidityEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { LiquidityEditor as StdEditor };

export default getRouterEditor(md)(LiquidityEditor);
