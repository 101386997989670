const frontendURL = '/cat/kekv/';
const backendURL = '/api/references/refkekv/';
const name = 'Класифікація економічна (КЕКВ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
