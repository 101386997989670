import React, {
  memo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { moneysStingOptions } from '../../../../../../constants/common';
import {
  StringInput,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/assignmentPlanningSource';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'balancing';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, readOnly,
}) {
  return (
    <TableRow rowIndex={rowIndex} active={active}>
      <Col
        sm={2}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="row">
              <StringInput
                value={row[tableMD.columns.row.name]}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      {[...Array(12)].map((_, m) => (
      // eslint-disable-next-line react/no-array-index-key
        <Col as={TableRowCell} column={`Sum${m + 1}`}>
          <NumberInput
            value={row[tableMD.columns[`Sum${m + 1}`].name]}
            precision={2}
            readOnly={readOnly}
          />
        </Col>
      ))}
      <Col md={1} className=" d-flex justify-content-center justify-content-xl-start flex-sm-column flex-md-row flex-xl-column">
        <div className="font-weight-bold w-100 text-sm-center text-right text-md-center">
          {row[tableMD.columns.Sum.name] && row[tableMD.columns.Sum.name].toLocaleString('uk', moneysStingOptions)}
        </div>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.row.name]: PropTypes.string,
    [tableMD.columns.Sum.name]: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [tableMD.columns[`Sum${i + 1}`].name]: PropTypes.number,
    }), {}),
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  readOnly: false,
};

export default memo(TPRow);
