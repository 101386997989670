import React, {
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import {
  Alert, Button, ButtonGroup, Carousel, Modal, Spinner, ToggleButton,
} from 'react-bootstrap';
import styled from 'styled-components';
import { faPaperclip, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile, faFileExcel, faFileImage, faFilePdf, faFileWord,
} from '@fortawesome/free-regular-svg-icons';

import { FilesButton } from '../../commandpanel/buttons';
import ListerContext from '../../../context';
import api from '../../../../../api/req';
import md from '../../../../../constants/meta';
import FileViewer from '../../../../../components/FilePreview/file-viewer';

function getIconByMime(type) {
  switch (type) {
    case 'application/pdf':
      return faFilePdf;
    case 'image/jpeg':
      return faFileImage;
    case 'application/vnd.ms-excel':
      return faFileExcel;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return faFileExcel;
    case 'application/vnd.msword':
      return faFileWord;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return faFileWord;
    default:
      return faFile;
  }
}

const CarouselInnerDiv = styled.div`
  width: 100%;
  height: 70vh;
  background: var(--dark);
  padding: 0 15% 80px 15%;
  overflow: auto;
  
`;

function FilesShower() {
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const {
    selectedRows, modelType, modelName,
  } = useContext(ListerContext);
  useEffect(
    () => {
      const { backendName } = md[modelType][modelName];
      const loadDocument = async (itemId) => {
        const r = await api.post$(`${modelType}/${backendName}/${itemId}/attachedFiles/`);
        if (!r.ok) {
          let errMessage;
          try {
            errMessage = await r.text();
          } catch {
            errMessage = `${r.status} ${r.statusText}`;
          }
          throw new Error(errMessage);
        }
        return r.json();
      };
      const loader = async (items) => Promise
        .allSettled(items.map((itemId) => loadDocument(itemId)));
      if (show) {
        setLoading(true);
        setErr(null);
        loader(selectedRows)
          .then((results) => {
            const errors = results.filter((r) => r.status === 'rejected');
            setLoading(false);
            if (errors.length) {
              setErr(results.map((r) => r.reason).join(','));
            } else {
              const ffs = results
                .reduce((R, r) => [...R, ...r.value], [])
                .map((f, index) => ({ ...f, icon: getIconByMime(f.type), key: `file-${index}` }));
              setFiles(ffs);
            }
          });
      }
    },
    [modelName, modelType, selectedRows, show],
  );

  const [fileIndex, setFileIndex] = useState(0);

  useEffect(
    () => {
      if (show) setFileIndex(0);
    },
    [show],
  );

  const downloadAll = useCallback(
    () => {
      files.forEach((file) => {
        const byteCharacters = atob(file.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.type });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.download = file.filename;
        a.href = url;

        document.body.append(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
    },
    [files],
  );
  return (
    <>
      <FilesButton onClick={() => setShow(true)} />
      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        className="w-100"
        dialogClassName="min-vw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="me-2">
            <FontAwesomeIcon icon={faPaperclip} />
            Додані файли
          </Modal.Title>
          <ButtonGroup toggle className="ms-auto">
            {files.map((file, index) => (
              <ToggleButton
                key={file.key}
                type="radio"
                variant="link"
                name="files"
                checked={index === fileIndex}
                value={index}
                onChange={() => setFileIndex(index)}
              >
                <FontAwesomeIcon icon={file.icon} className="me-2" />
                {file.filename}
              </ToggleButton>
            ))}
          </ButtonGroup>

        </Modal.Header>
        <Modal.Body>
          {loading && (
            <Spinner animation="border" role="status" />
          )}
          {!files.length && !loading && !err && (
            <Alert variant="info">
              <Alert.Heading>
                :-( Файлів не знайдено
              </Alert.Heading>
              По вибраних документах обраних фалів не знайшлося, або щось пішло не так
            </Alert>
          )}
          {err && (
            <Alert variant="danger">
              <Alert.Heading>
                :-( Отакої
              </Alert.Heading>
              {err}
            </Alert>
          )}
          <Carousel
            activeIndex={fileIndex}
            onSelect={(index) => setFileIndex(index)}
            interval={null}
          >
            {files.map((file) => (
              <Carousel.Item key={file.key}>
                <CarouselInnerDiv>
                  <FileViewer
                    content={file.content}
                    mimeType={file.type}
                    filename={file.filename}
                  />
                </CarouselInnerDiv>
                <Carousel.Caption>
                  <FontAwesomeIcon icon={file.icon} className="me-2" />
                  {file.filename}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={downloadAll}>
            <FontAwesomeIcon icon={faCloudDownloadAlt} className="me-2" />
            Скачати всі
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}

export default FilesShower;
