import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  name: 'Назва',
  ...defaultColumns,
};

export const budgetPaymentCode = {
  label: 'Коди видів сплати до бюджету',
  name: 'budgetPaymentCode',
  backendName: 'КодыВидовОплатыВБюджет',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/partners',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useListLazyLoad: true,
};

export default budgetPaymentCode;
