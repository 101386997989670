import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd2';

const meta = md.tables.exp;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={6} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'exp'}
              highlighted={highlights.includes('exp')}
              onToggleHighlght={() => onToggleHighlght('exp')}
            >
              {meta.columns.exp.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKV'}
              highlighted={highlights.includes('KPKV')}
              onToggleHighlght={() => onToggleHighlght('KPKV')}
            >
              {meta.columns.KPKV.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSU'}
              highlighted={highlights.includes('CSU')}
              onToggleHighlght={() => onToggleHighlght('CSU')}
            >
              {meta.columns.CSU.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf_2'}
              highlighted={highlights.includes('zf_2')}
              onToggleHighlght={() => onToggleHighlght('zf_2')}
            >
              {`${activeYear - 2} (звіт) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf_2'}
              highlighted={highlights.includes('sf_2')}
              onToggleHighlght={() => onToggleHighlght('sf_2')}
            >
              {`${activeYear - 2} (звіт) Спеціальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br_2'}
              highlighted={highlights.includes('br_2')}
              onToggleHighlght={() => onToggleHighlght('br_2')}
            >
              {`${activeYear - 2} (звіт) у т.ч. бюджет розвитку`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf_1'}
              highlighted={highlights.includes('zf_1')}
              onToggleHighlght={() => onToggleHighlght('zf_1')}
            >
              {`${activeYear - 1} (затверджено) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf_1'}
              highlighted={highlights.includes('sf_1')}
              onToggleHighlght={() => onToggleHighlght('sf_1')}
            >
              {`${activeYear - 1} (затверджено) Спеціальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br_1'}
              highlighted={highlights.includes('br_1')}
              onToggleHighlght={() => onToggleHighlght('br_1')}
            >
              {`${activeYear - 1} (звіт) у т.ч. бюджет розвитку`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {`${activeYear} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {`${activeYear} (план) Спеціальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br0'}
              highlighted={highlights.includes('br0')}
              onToggleHighlght={() => onToggleHighlght('br0')}
            >
              {`${activeYear} (звіт) у т.ч. бюджет розвитку`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {`${activeYear + 1} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {`${activeYear + 1} (план) Спеціальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br1'}
              highlighted={highlights.includes('br1')}
              onToggleHighlght={() => onToggleHighlght('br1')}
            >
              {`${activeYear + 1} (звіт) у т.ч. бюджет розвитку`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {`${activeYear + 2} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {`${activeYear + 2} (план) Спеціальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br2'}
              highlighted={highlights.includes('br2')}
              onToggleHighlght={() => onToggleHighlght('br2')}
            >
              {`${activeYear + 2} (звіт) у т.ч. бюджет розвитку`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
