const itemTypes = {
  availableField: 'AVAILABLE',
  group: 'GROUP',
  column: 'COLUMN',
  selection: 'SELECTION',
  order: 'ORDER',
  filter: 'FILTER',
};

export default itemTypes;
