import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/forecastAdd3';

const meta = md.tables.financing;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={6} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KFB'}
              highlighted={highlights.includes('KFB')}
              onToggleHighlght={() => onToggleHighlght('KFB')}
            >
              {meta.columns.KFB.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KFBName'}
              highlighted={highlights.includes('KFBName')}
              onToggleHighlght={() => onToggleHighlght('KFBName')}
            >
              {meta.columns.KFBName.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingGF_Report'}
              highlighted={highlights.includes('financingGF_Report')}
              onToggleHighlght={() => onToggleHighlght('financingGF_Report')}
            >
              {`${activeYear - 2} (звіт) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingSF_Report'}
              highlighted={highlights.includes('financingSF_Report')}
              onToggleHighlght={() => onToggleHighlght('financingSF_Report')}
            >
              {`${activeYear - 2} (звіт) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingGF_Approved'}
              highlighted={highlights.includes('financingGF_Approved')}
              onToggleHighlght={() => onToggleHighlght('financingGF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingSF_Approved'}
              highlighted={highlights.includes('financingSF_Approved')}
              onToggleHighlght={() => onToggleHighlght('financingSF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingGF_Plan'}
              highlighted={highlights.includes('financingGF_Plan')}
              onToggleHighlght={() => onToggleHighlght('financingGF_Plan')}
            >
              {`${activeYear} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingSF_Plan'}
              highlighted={highlights.includes('financingSF_Plan')}
              onToggleHighlght={() => onToggleHighlght('financingSF_Plan')}
            >
              {`${activeYear} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingGF_Plan1'}
              highlighted={highlights.includes('financingGF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('financingGF_Plan1')}
            >
              {`${activeYear + 1} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingSF_Plan1'}
              highlighted={highlights.includes('financingSF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('financingSF_Plan1')}
            >
              {`${activeYear + 1} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingGF_Plan2'}
              highlighted={highlights.includes('financingGF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('financingGF_Plan2')}
            >
              {`${activeYear + 2} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'financingSF_Plan2'}
              highlighted={highlights.includes('financingSF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('financingSF_Plan2')}
            >
              {`${activeYear + 2} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
