// eslint-disable-next-line no-unused-vars
import React, {
  useCallback, useContext, useState,
} from 'react';
import { CiatAppContext, LogicaContext } from '../../../providers';
import api from '../../../api/req';
import md from '../../../meta/srv/ImportDataFromLogica';
import checkTask from '../../../api/checktask';

const useLogicaData = () => {
  const logicaPingSuccess = true;

  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [res, setRes] = useState(null);
  const [datesRes, setDatesRes] = useState({});

  const { auth } = useContext(CiatAppContext);
  const { ticket } = useContext(LogicaContext);

  const getData = useCallback((selectedDocs) => {
    const loader = async () => {
      const params = {
        selected_docs: selectedDocs.join(','),
        ticket,
        year,
      };
      const response = await api.get(md.backendURL, auth, params);
      if (!response.ok) {
        if (response.status === 400) {
          const errData = await response.json();
          throw new Error(errData.join(', '));
        }
        throw new Error(`${response.status} ${response.statusText}`);
      }
      return response.json();
    };
    setLoading(true);
    setErr(null);
    setRes(null);
    loader()
      .then((data) => {
        setDatesRes(data);
      })
      .catch((e) => {
        setErr(e.message);
      })
      .finally(() => setLoading(false));
  }, [auth, ticket, year]);

  const createData = useCallback(
    (selectedIDs, selectedDocs) => {
      const loader = async () => {
        const params = {
          list_id: selectedIDs,
          ticket,
        };

        const r = await api.post(md.backendURL, auth, params);

        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        // eslint-disable-next-line camelcase
        const { task_id } = await r.json();
        // eslint-disable-next-line camelcase
        return task_id;
      };

      setLoading(true);
      setErr(null);
      setRes(null);
      loader(selectedIDs)
        .then((taskId) => checkTask(taskId, auth))
        .then((r) => {
          if (r.result.errors) throw new Error(r.result.errors[0]);
          getData(selectedDocs);
          setRes('Дані завантажено успішно!');
        })
        .catch((e) => {
          getData(selectedDocs);
          setErr(e.message);
        })
        .finally(
          () => {
            setLoading(false);
          },
        );
    },
    [auth, ticket, getData],
  );

  return {
    logicaPingSuccess,
    loading,
    err,
    setErr,
    year,
    setYear,
    res,
    setRes,
    getData,
    datesRes,
    createData,
  };
};

export default useLogicaData;
