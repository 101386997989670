/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor } from '../../../../../../components/Form/styledForm';
import { emptyUid } from '../../../../../../constants/meta/common';

const tableMeta = md.tables.generalTPInd;

function IndicatorsTables({
  data, actions, readOnly, children,
}) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });
  const tasksTP = data[md.tables.tasksSpent.name] || [];
  const indicatorsTasks = [{
    display_name: 'Вимкнено відбір по напрямам',
    value: emptyUid,
  }];
  tasksTP.reduce(
    // eslint-disable-next-line no-confusing-arrow
    (el, o) => o[md.tables.tasksSpent.columns.tasks.name]
      ? (el.set(
        o[md.tables.tasksSpent.columns.tasks.name].id,
        o[md.tables.tasksSpent.columns.tasks.name],
      ))
      : [],
    new Map(),
  )
    .forEach((c) => indicatorsTasks.push(
      {
        display_name: (c.repr === '') ? 'Не заповнено' : c.repr,
        value: (c.id === '') ? emptyUid : c,
      },
    ));
  const [selectedTask, setSelectedTask] = useState(emptyUid);
  const taskId = selectedTask === emptyUid ? selectedTask : selectedTask.id;

  return (
    <>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label="Відібрати показники за:"
            value={selectedTask}
            onChange={(e, value) => setSelectedTask(value)}
            values={indicatorsTasks}
            readOnly={readOnly}
          />
        </Col>
        <Col className="d-flex align-items-end pb-3">
          <CommandPanelColor
            style={{ width: 'fit-content' }}
            label={selectedTask === emptyUid ? ('Заповнити показники') : ('Заповнити показники по напряму')}
            onClick={async () => {
              await actions.onSR('FILL_INDICATORS_IN_TABLE', { taskId });
              setSelectedTask(emptyUid);
            }}
          />
        </Col>
      </Row>
      <Card className="border-0">
        <TableHeader pinHeader={pinHeader}>
          <TPCommandPanel
            tableActions={tableActions}
            readOnly={readOnly}
            activeRow={activeRow}
            pinHeader={pinHeader}
            showFooter={showFooter}
          >
            {children}
          </TPCommandPanel>
          <TPHeader
            activeCol={activeCol}
            highlights={highlights}
            onToggleHighlght={tableActions.onToggleHighlightColumn}
          />
        </TableHeader>
        <Card.Body className="p-0" ref={tableContainerRef}>
          {tableData.map((row, index) => (
            <TPRow
              key={index}
              row={row}
              rowIndex={index}
              active={activeRow === index}
              activeCol={activeRow === index ? activeCol : null}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              onSR={actions.onSR}
              selectedTask={selectedTask}
            />
          ))}
          <Placeholder onFocus={() => tableActions.onAddRow()} />
        </Card.Body>
      </Card>
    </>
  );
}

IndicatorsTables.propTypes = {
  data: PropTypes.shape({
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.symbol, PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

IndicatorsTables.defaultProps = {
  readOnly: false,
  children: null,
};

export default memo(IndicatorsTables);
