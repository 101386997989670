import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  kbs: { label: 'Код', name: 'КБС', visible: true },
  kbsRel: { label: 'Код повний', name: 'КБСКор', visible: true },
  json: { label: 'JSON', name: 'JSON', visible: true },
};

export const buildingClassifier = {
  label: 'Класифікація будівель та споруд',
  name: 'buildingClassifier',
  backendName: 'КлассификаторДомов',
  defaultOrder: [{
    column: columns.kbs.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/buildingClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'kbs',
      'kbsRel',
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default buildingClassifier;
