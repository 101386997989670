import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { kvkClassifier as md } from '../../../../constants/meta/catalogs/kvkClassifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function KVKClassifier({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.Date_kl.label}
            value={data[md.columns.Date_kl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.Date_kl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeOnDate.label}
            value={data[md.columns.CodeOnDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeOnDate.name]: value,
            })}
            readOnly={readOnly}
            maxLength={4}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="kvkClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

KVKClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KVKClassifier;
