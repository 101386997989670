import md from '../../../../../meta/doc/finSourcePlanChanges';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { DocTableListerFactory } from '../../../../newLister/tableComponent';
import CommandPanel from '../common/comandpanel';

const Lister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

export default Lister;
