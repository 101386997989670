const frontendURL = '/cat/minfinactivity/';
const backendURL = '/api/references/refminfinactivity/';
const name = 'Напрямки використання (Нова методика)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
