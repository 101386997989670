import Styled from 'styled-components';
import { Form } from '../elements';

export const HeaderText = Styled.h2`
  color: var(--headerTextH);
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  margin: 10px 0 20px 0;
  font-size: 1.6em;
  
  @media (max-width: 600px) {
  margin: 10px 0;
  }
`;
export const DescriptionText = Styled.div`
    font-style: italic;
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
    color: #4f4646;
    border: 1px solid #e0e0e0;
`;

export const StyledForm = Styled(Form)`
  overflow: auto;
`;

export const EditorContainer = Styled.div`
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 30px 10px;
  margin: 10px 0;
  background: white;
  // background: #e9f0f85e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const GeneralContainer = Styled.div`
  flex: 1 1 auto;
  border-radius: .3rem 0 0 .3rem;
  border: 1px solid #a2b4e8;
  background: white;
  padding: 10px;
`;
