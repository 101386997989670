const frontendURL = '/cat/explanation/';
const backendURL = '/api/references/refexplanation/';
const name = 'Пояснення';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
