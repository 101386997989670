import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes8';

const meta = md.tables.TABL_101;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'code'}
              highlighted={highlights.includes('code')}
              onToggleHighlght={() => onToggleHighlght('code')}
            >
              {meta.columns.code.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'name'}
              highlighted={highlights.includes('name')}
              onToggleHighlght={() => onToggleHighlght('name')}
            >
              {meta.columns.name.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rate'}
              highlighted={highlights.includes('rate')}
              onToggleHighlght={() => onToggleHighlght('rate')}
            >
              {meta.columns.rate.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'period'}
              highlighted={highlights.includes('period')}
              onToggleHighlght={() => onToggleHighlght('period')}
            >
              {meta.columns.period.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
