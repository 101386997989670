/* eslint-disable react/jsx-props-no-spreading */
import ListerFactory from '../../../newLister';
import { modelType, modelName } from '../def';
import { CatTableListerFactory } from '../../../newLister/tableComponent';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory({}));

export default StdLister;
