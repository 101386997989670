import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from './propTypes';
import { TableControls, tableHooks } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { TypeIndIcon } from '../../../../../catalogs/indicator/getTypeIcon';

function IndicatorsRow({
  rowIndex, row, onChange, readOnly, highlights, fields, errors, readOnlyFields,
  fromLogica, budgetFilter,
}) {
  const indicatorProps = tableHooks.useItemInputProps('indicator', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const indicatorTextProps = tableHooks.useStringInputProps('indicator_text', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountZFProps = tableHooks.useNumberInputProps('amount_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountSFProps = tableHooks.useNumberInputProps('amount_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  // Додала можливість редагувати загальної суми по проханню консультантів: (
  // Наприклад стоїть відсоток виконання по загальному фонду 100 та по СФ 100,
  // разом рахує 200%, а потрібно щоб 100
  // Або є кількість дітей, які фінансуються по загальному фонду 52,
  // з них по спецфонду 8, разом рахує 60, а потрібно 52
  // )
  const amountTotalProps = tableHooks.useNumberInputProps('amount_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sourceTextProps = tableHooks.useStringInputProps('source_text', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  return (
    <>
      <Col xs={4}>
        <TabelRowCell column="indicator" highlighted={highlights.includes('indicator')}>
          {fromLogica ? (
            <TableControls.StringInput {...indicatorTextProps} />
          ) : (
            <TableControls.ItemPicker
              {...indicatorProps}
              filter={budgetFilter}
              prepend={(
                <TypeIndIcon value={row.indicator_type} />
                )}
              append={(
                <div className="d-flex border-top border-bottom ">
                  <span className="text-muted fs--2 align-self-center fw-bolder px-2">
                    {row.unit_of_measurement || ''}
                  </span>
                </div>

                )}
            />
          )}
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="amount_zf" highlighted={highlights.includes('amount_zf')}>
          <TableControls.NumberInput
            {...amountZFProps}
            precision={3}
            onChange={(e, v) => onChange(
              e,
              rowIndex,
              { amount_zf: v, amount_total: (row.amount_sf || 0) + v },
            )}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="amount_sf" highlighted={highlights.includes('amount_sf')}>
          <TableControls.NumberInput
            {...amountSFProps}
            precision={3}
            onChange={(e, v) => onChange(
              e,
              rowIndex,
              { amount_sf: v, amount_total: (row.amount_zf || 0) + v },
            )}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="amount_total" highlighted={highlights.includes('amount_total')}>
          <TableControls.NumberInput
            {...amountTotalProps}
            precision={3}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="source_text" highlighted={highlights.includes('source_text')}>
          <TableControls.StringInput
            {...sourceTextProps}
          />
        </TabelRowCell>
      </Col>

    </>
  );
}

IndicatorsRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onToggleHighlight: PropTypes.func.isRequired,
  fromLogica: PropTypes.bool,
  budgetFilter: PropTypes.shape({ budget: PropTypes.number }),
};

IndicatorsRow.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
  activeCol: null,
  highlights: [],
  fromLogica: false,
  budgetFilter: { budget: null },
};

export default memo(IndicatorsRow);
