import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';

function NoNameControl({ name }) {
  return (
    <h5>
      Поле
      {' '}
      {name}
      {' '}
      відсутнє у списку дозволених (fields)
    </h5>
  );
}
export const getComponentByType = (cType, readOnly = false) => {
  switch (cType) {
    case 'string':
      return EditorControls.StringInput;
    case 'email':
      return EditorControls.StringInput;
    case 'nested object':
      // eslint-disable-next-line
    return readOnly ? EditorControls.ItemField : EditorControls.ItemPicker;
    case 'field':
      // eslint-disable-next-line
    return readOnly ? EditorControls.ItemField : EditorControls.ItemPicker;
    case 'integer':
      return EditorControls.NumberInput;
    case 'float':
      return EditorControls.NumberInput;
    case 'decimal':
      return EditorControls.NumberInput;
    case 'choice':
      return EditorControls.SelectorInput;
    case 'date':
      return EditorControls.DateInput;
    case 'datetime':
      return EditorControls.DateInput;
    case 'rangeDatePicker':
      return EditorControls.RangeDatePicker;
    case 'yearInput':
      return EditorControls.YearInput;
    case 'boolean':
      return EditorControls.CheckboxInput;
    case 'text':
      return EditorControls.TextInput;
    default:
      // eslint-disable-next-line no-console
      console.error(`Type ${cType} not supported by editor controls`);
      return NoNameControl;
  }
};

export const getEditorHookByType = (cType) => {
  switch (cType) {
    case 'string':
      return editorHooks.useStringInputProps;
    case 'text':
      return editorHooks.useTextInputProps;
    case 'selector':
      return editorHooks.useSelectorInputProps;
    case 'email':
      return editorHooks.useStringInputProps;
    case 'nested object':
      return editorHooks.useItemInputProps;
    case 'field':
      return editorHooks.useItemInputProps;
    case 'integer':
      return editorHooks.useNumberInputProps;
    case 'float':
      return editorHooks.useNumberInputProps;
    case 'decimal':
      return editorHooks.useNumberInputProps;
    case 'choice':
      return editorHooks.useSelectorInputProps;
    case 'date':
      return editorHooks.useDateInputProps;
    case 'boolean':
      return editorHooks.useCheckboxInputProps;
    default:
      // console.error(`Type ${cType} not supported by editorHooks controls`);
      return (() => {});
  }
};

export function Control({
  headerProps, name, typeControl, typeHook, description,
}) {
  const {
    data,
    fields,
    fieldErrors,
    onChange,
    isReadOnly,
    headerReadOnlyFields,
    readOnly,
    advancedProps,
  } = headerProps;

  const cType = fields && fields[name] ? fields[name].type : '';
  const hookControl = getEditorHookByType(typeHook || cType);

  const controlProps = hookControl(
    name,
    data,
    fields,
    fieldErrors,
    onChange,
    isReadOnly,
    description,
    headerReadOnlyFields,
  );

  const ControlByType = getComponentByType(typeControl || cType, readOnly);

  return (
    <Suspense fallback={(<h4>Зачекайте...</h4>)}>
      <ControlByType
        {...controlProps}
        {...advancedProps}
        name={name}
        description={description}
      />
    </Suspense>
  );
}

Control.propTypes = {
  headerProps: PropTypes.shape({
    data: PropTypes.shape({}),
    fields: PropTypes.shape({}),
    fieldErrors: PropTypes.shape({}),
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    headerReadOnlyFields: PropTypes.arrayOf(PropTypes.string),
    typeControl: PropTypes.string,
    typeHook: PropTypes.string,
    readOnly: PropTypes.bool,
    advancedProps: PropTypes.shape({}),
  }).isRequired,
  name: PropTypes.string.isRequired,
  typeHook: PropTypes.string,
  typeControl: PropTypes.string,
  description: PropTypes.string,

};

Control.defaultProps = {
  typeHook: null,
  typeControl: null,
  description: null,
};

NoNameControl.propTypes = {
  name: PropTypes.string,
};
NoNameControl.defaultProps = {
  name: '',
};

export default {
  Control,
  getComponentByType,
  getEditorHookByType,
};
