import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion, Card, Dropdown, DropdownButton,
} from 'react-bootstrap';

const StyledDate = styled.h5`
color: darkblue`;

const StyledHeader = styled.h2`
  color: ${(props) => props.color}
`;

function ResultVerifyPage({
  getResult, resJson, getDate, resultVerifyList, getResultVerifyList, logicaPingSuccess,
}) {
  return (
    <>
      <DropdownButton id="dropdown-item-button" title="Оберіть необхідний список" disabled={!logicaPingSuccess}>
        <Dropdown.Item as="button" onClick={getResultVerifyList} value="0">Останній валідований</Dropdown.Item>
        <Dropdown.Item as="button" onClick={getResultVerifyList} value="1">Всі валідовані</Dropdown.Item>
        <Dropdown.Item as="button" onClick={getResultVerifyList} value="2">Всі</Dropdown.Item>
      </DropdownButton>
      {resultVerifyList && (
      <Accordion
        defaultActiveKey="0"
        onSelect={(value) => {
          if (value) {
            const line = resultVerifyList.find((i) => i[0] === value);
            getResult(line[1].receipt);
          }
        }}
      >
        {resultVerifyList && resultVerifyList.map((el) => (
          <Accordion.Item eventKey={el[0]} key={el}>
            <Accordion.Header>
                {getDate(el[0])}
            </Accordion.Header>
            <Accordion.Body>
              <div>
              {resJson && resJson.success && (
                <StyledHeader color="green">Верифікація пройшла успішно</StyledHeader>
              )}
              {resJson && !resJson.success && (
                    <>
                      <StyledHeader color="red">Під час верифікації виникли помилки</StyledHeader>
                      <ul>
                        {Object.entries(resJson.messages).map((message, i) => ((typeof message[1] === 'string')
                          ? <li key={i}>{message}</li>
                          : (
                            <li key={i}>
                              {Object.values(message[1])}
                            </li>
                          )))}
                      </ul>
                    </>
              )}
                {resJson && resJson.validationdate && (
                  <StyledDate>
                    Дата валідації:
                      {' '}
                    {getDate(resJson.validationdate)}
                  </StyledDate>
                  )}
                  {resJson && resJson.verificationdate && (
                  <StyledDate>
                    Дата верифікації:
                      {' '}
                    {getDate(resJson.verificationdate)}
                  </StyledDate>
                  )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      )}
    </>
  );
}

ResultVerifyPage.propTypes = {
  getResult: PropTypes.func.isRequired,
  resJson: PropTypes.shape({
    success: PropTypes.bool,
    messages: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    validationdate: PropTypes.string,
    verificationdate: PropTypes.string,
  }),
  getDate: PropTypes.func.isRequired,
  resultVerifyList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ])),
  getResultVerifyList: PropTypes.func.isRequired,
  logicaPingSuccess: PropTypes.bool.isRequired,
};

ResultVerifyPage.defaultProps = {
  resJson: null,
  resultVerifyList: null,
};

export default ResultVerifyPage;
