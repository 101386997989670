import Styled from 'styled-components';

export const DocumentContainer = Styled.div`
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 30px 10px 10px 10px;
  margin: 10px 0;
  background: white;
  // background: #e9f0f85e;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DocumentBody = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;
