import WhyUpdate from './whyUpdate';
import { debounce } from './debounce';

export { WhyUpdate, debounce };

const utils = {
  WhyUpdate,
  debounce,
};

export default utils;
