import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import { CommandPanelButton } from '../../../components/button';
import Loader from '../../../components/styledWrappedLoader';
import { StyledLabel, MyContainerFormWithNColumns } from '../../../components/Form/styledForm';

const md = meta.dp.docRegisterPrinting;

class AssigmentPrintingEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
    isProcessing: false,
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const { dispatch, headerForm, isProcessing } = this.props;

    return (
      <Loader isLoading={isProcessing}>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.Budget.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('Budget', new Map())}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.FI.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('FI', new Map())}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={1}>
          <div>
            <StyledLabel>{md.columns.Document.label}</StyledLabel>
            <Fields.ItemField
              value={headerForm.get('Document', new Map())}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={4}>
          <div>
            <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('SignVariant', new Map())}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('FI', new Map()) },
              ]}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('printDateDoc', false)}
              label={md.columns.printDateDoc.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printDateDoc'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('printFinDate', false)}
              label={md.columns.printFinDate.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printFinDate'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('printFinKOATUU', false)}
              label={md.columns.printFinKOATUU.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printFinKOATUU'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('nameS', false)}
              label={md.columns.nameS.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'nameS'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('noAdd', false)}
              label={md.columns.noAdd.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noAdd'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={1}>
          <div>
            <CommandPanelButton onClick={() => dispatch(actions.processServerReq('PRINT'))}>
              Сформувати
            </CommandPanelButton>
          </div>
        </MyContainerFormWithNColumns>
        <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
      </Loader>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(AssigmentPrintingEditor);
