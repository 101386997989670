import React, { forwardRef, useContext, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import PrintModal from './listerPrintModal';
import ListerContext from '../../../newLister/context';
import SendToLogicaButton from '../../../Logica/sendToLogicaButton';
import { modelName, modelType } from '../def';
import meta from '../../../../constants/meta/documents/expensesOnHealth';
import YouTubeButtom from '../../../newLister/tableComponent/commandpanel/youtube/youtubeButton';
import YoutubeVideo from '../../../newLister/tableComponent/commandpanel/youtube/youtubeVideo';

const videoId = meta.columns.refYoutube && meta.columns.refYoutube.value;

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <DocListerCommandPanel
        ref={ref}
      >
        <ButtonGroup>
          <PrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
        </ButtonGroup>
        {/* <ButtonGroup> */}
        {/*  <SendToLogicaButton modelType={modelType} modelName={modelName} /> */}
        {/* </ButtonGroup> */}
        <YouTubeButtom
          onClick={() => setShowVideo(!showVideo)}
          videoId={videoId}
        />
      </DocListerCommandPanel>
      <YoutubeVideo
        showVideo={showVideo}
        videoId={videoId}
        setShowVideo={setShowVideo}
      />
    </>
  );
});
export default CommandPanel;
