import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  year: { label: 'Рік', name: 'Год', visible: false },
  R01G2: { label: 'Доходи загального фонду місцевого бюджету без урахування міжбюджетних трансфертів (код 90010100)', name: 'R01G2', visible: false },
  R01G3: { label: 'Податок на доходи фізичних осіб (код 11010000)', name: 'R01G3', visible: false },
  R01G4: { label: 'Середньорічний обсяг доходів загального фонду місцевого бюджету', name: 'R01G4', visible: false },
  R01G5: { label: 'Межа загального обсягу місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу', name: 'R01G5', visible: false },
  R01G6: { label: 'Загальний обсяг місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу (звітні дані та прогноз станом на кінець бюджетного періоду)', name: 'R01G6', visible: false },
  R01G7: { label: 'Видатки загального фонду місцевого бюджету (всього) (код 900203)', name: 'R01G7', visible: false },
  R01G8: { label: 'в тому числі: реверсна дотація (код 9110)', name: 'R01G8', visible: false },
  R01G9: { label: 'в тому числі: трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R01G9', visible: false },
  R01G10: { label: 'в тому числі: видатки загального фонду бюджету за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів', name: 'R01G10', visible: false },
  R01G11: { label: 'Межа видатків на обслуговування місцевого боргу загального фонду місцевого бюджету', name: 'R01G11', visible: false },
  R01G12: { label: 'Видатки на обслуговування місцевого боргу загального фонду місцевого бюджету (код 8600)', name: 'R01G12', visible: false },
  R02G2: { label: 'Доходи загального фонду місцевого бюджету без урахування міжбюджетних трансфертів (код 90010100)', name: 'R02G2', visible: false },
  R02G3: { label: 'Податок на доходи фізичних осіб (код 11010000)', name: 'R02G3', visible: false },
  R02G4: { label: 'Середньорічний обсяг доходів загального фонду місцевого бюджету', name: 'R02G4', visible: false },
  R02G5: { label: 'Межа загального обсягу місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу', name: 'R02G5', visible: false },
  R02G6: { label: 'Загальний обсяг місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу (звітні дані та прогноз станом на кінець бюджетного періоду)', name: 'R02G6', visible: false },
  R02G7: { label: 'Видатки загального фонду місцевого бюджету (всього) (код 900203)', name: 'R02G7', visible: false },
  R02G8: { label: 'в тому числі: реверсна дотація (код 9110)', name: 'R02G8', visible: false },
  R02G9: { label: 'в тому числі: трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R02G9', visible: false },
  R02G10: { label: 'в тому числі: видатки загального фонду бюджету за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів', name: 'R02G10', visible: false },
  R02G11: { label: 'Межа видатків на обслуговування місцевого боргу загального фонду місцевого бюджету', name: 'R02G11', visible: false },
  R02G12: { label: 'Видатки на обслуговування місцевого боргу загального фонду місцевого бюджету (код 8600)', name: 'R02G12', visible: false },
  R03G2: { label: 'Доходи загального фонду місцевого бюджету без урахування міжбюджетних трансфертів (код 90010100)', name: 'R02G2', visible: false },
  R03G3: { label: 'Податок на доходи фізичних осіб (код 11010000)', name: 'R03G3', visible: false },
  R03G4: { label: 'Середньорічний обсяг доходів загального фонду місцевого бюджету', name: 'R03G4', visible: false },
  R03G5: { label: 'Межа загального обсягу місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу', name: 'R03G5', visible: false },
  R03G6: { label: 'Загальний обсяг місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу (звітні дані та прогноз станом на кінець бюджетного періоду)', name: 'R03G6', visible: false },
  R03G7: { label: 'Видатки загального фонду місцевого бюджету (всього) (код 900203)', name: 'R03G7', visible: false },
  R03G8: { label: 'в тому числі: реверсна дотація (код 9110)', name: 'R03G8', visible: false },
  R03G9: { label: 'в тому числі: трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R03G9', visible: false },
  R03G10: { label: 'в тому числі: видатки загального фонду бюджету за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів', name: 'R03G10', visible: false },
  R03G11: { label: 'Межа видатків на обслуговування місцевого боргу загального фонду місцевого бюджету', name: 'R03G11', visible: false },
  R03G12: { label: 'Видатки на обслуговування місцевого боргу загального фонду місцевого бюджету (код 8600)', name: 'R03G12', visible: false },
  R04G2: { label: 'Доходи загального фонду місцевого бюджету без урахування міжбюджетних трансфертів (код 90010100)', name: 'R04G2', visible: false },
  R04G3: { label: 'Податок на доходи фізичних осіб (код 11010000)', name: 'R04G3', visible: false },
  R04G4: { label: 'Середньорічний обсяг доходів загального фонду місцевого бюджету', name: 'R04G4', visible: false },
  R04G5: { label: 'Межа загального обсягу місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу', name: 'R04G5', visible: false },
  R04G6: { label: 'Загальний обсяг місцевого боргу та гарантованого Автономною Республікою Крим, обласною радою чи міською територіальною громадою боргу (звітні дані та прогноз станом на кінець бюджетного періоду)', name: 'R04G6', visible: false },
  R04G7: { label: 'Видатки загального фонду місцевого бюджету (всього) (код 900203)', name: 'R04G7', visible: false },
  R04G8: { label: 'в тому числі: реверсна дотація (код 9110)', name: 'R04G8', visible: false },
  R04G9: { label: 'в тому числі: трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R04G9', visible: false },
  R04G10: { label: 'в тому числі: видатки загального фонду бюджету за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів', name: 'R04G10', visible: false },
  R04G11: { label: 'Межа видатків на обслуговування місцевого боргу загального фонду місцевого бюджету', name: 'R04G11', visible: false },
  R04G12: { label: 'Видатки на обслуговування місцевого боргу загального фонду місцевого бюджету (код 8600)', name: 'R04G12', visible: false },
  YEAR_FACT1: { label: 'Рік', name: 'YEAR_FACT1', visible: false },
  YEAR_FACT2: { label: 'Рік', name: 'YEAR_FACT2', visible: false },
  YEAR_FACT3: { label: 'Рік', name: 'YEAR_FACT3', visible: false },
  YEAR_PLAN1: { label: 'Рік', name: 'YEAR_PLAN1', visible: false },
  R05G2: { label: 'Усього видатків загального фонду місцевих бюджетів (код 900203)', name: 'R05G2', visible: false },
  R05G3: { label: 'Усього видатків загального фонду місцевих бюджетів без урахування міжбюджетних трансфертів (код 900201)', name: 'R05G3', visible: false },
  R05G4: { label: 'Реверсна дотація (код 9110', name: 'R05G4', visible: false },
  R05G5: { label: 'Інші дотації з місцевого бюджету (код 9150)', name: 'R05G5', visible: false },
  R05G6: { label: 'Субвенції з місцевого бюджету іншим місцевим бюджетам на здійснення програм та заходів за рахунок коштів місцевих бюджетів (код 9700)', name: 'R05G6', visible: false },
  R05G7: { label: 'Субвенція з місцевого бюджету державному бюджету на виконання програм соціально-економічного розвитку регіонів (код 9800)', name: 'R05G7', visible: false },
  R05G8: { label: 'Субвенція з місцевого бюджету державному бюджету на фінансування діяльності військових адміністрацій із виконання повноважень органів місцевого самоврядування (код 9810)', name: 'R05G8', visible: false },
  R05G9: { label: 'Субвенція з місцевого бюджету державному бюджету на перерахування коштів в умовах воєнного стану або для здійснення згідно із законом заходів загальної мобілізації та з метою відсічі збройної агресії Російської Федерації проти України та забезпечення національної безпеки, усунення загрози небезпеки державній незалежності України, її територіальній цілісності (код 9820)', name: 'R05G9', visible: false },
  R05G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R05G10', visible: false },
  R05G11: { label: 'Трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R05G11', visible: false },
  R06G2: { label: 'Усього видатків загального фонду місцевих бюджетів (код 900203)', name: 'R06G2', visible: false },
  R06G3: { label: 'Усього видатків загального фонду місцевих бюджетів без урахування міжбюджетних трансфертів (код 900201)', name: 'R06G3', visible: false },
  R06G4: { label: 'Реверсна дотація (код 9110', name: 'R06G4', visible: false },
  R06G5: { label: 'Інші дотації з місцевого бюджету (код 9150)', name: 'R06G5', visible: false },
  R06G6: { label: 'Субвенції з місцевого бюджету іншим місцевим бюджетам на здійснення програм та заходів за рахунок коштів місцевих бюджетів (код 9700)', name: 'R06G6', visible: false },
  R06G7: { label: 'Субвенція з місцевого бюджету державному бюджету на виконання програм соціально-економічного розвитку регіонів (код 9800)', name: 'R06G7', visible: false },
  R06G8: { label: 'Субвенція з місцевого бюджету державному бюджету на фінансування діяльності військових адміністрацій із виконання повноважень органів місцевого самоврядування (код 9810)', name: 'R06G8', visible: false },
  R06G9: { label: 'Субвенція з місцевого бюджету державному бюджету на перерахування коштів в умовах воєнного стану або для здійснення згідно із законом заходів загальної мобілізації та з метою відсічі збройної агресії Російської Федерації проти України та забезпечення національної безпеки, усунення загрози небезпеки державній незалежності України, її територіальній цілісності (код 9820)', name: 'R06G9', visible: false },
  R06G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R06G10', visible: false },
  R06G11: { label: 'Трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R06G11', visible: false },
  R07G2: { label: 'Усього видатків загального фонду місцевих бюджетів (код 900203)', name: 'R07G2', visible: false },
  R07G3: { label: 'Усього видатків загального фонду місцевих бюджетів без урахування міжбюджетних трансфертів (код 900201)', name: 'R07G3', visible: false },
  R07G4: { label: 'Реверсна дотація (код 9110', name: 'R07G4', visible: false },
  R07G5: { label: 'Інші дотації з місцевого бюджету (код 9150)', name: 'R07G5', visible: false },
  R07G6: { label: 'Субвенції з місцевого бюджету іншим місцевим бюджетам на здійснення програм та заходів за рахунок коштів місцевих бюджетів (код 9700)', name: 'R07G6', visible: false },
  R07G7: { label: 'Субвенція з місцевого бюджету державному бюджету на виконання програм соціально-економічного розвитку регіонів (код 9800)', name: 'R07G7', visible: false },
  R07G8: { label: 'Субвенція з місцевого бюджету державному бюджету на фінансування діяльності військових адміністрацій із виконання повноважень органів місцевого самоврядування (код 9810)', name: 'R07G8', visible: false },
  R07G9: { label: 'Субвенція з місцевого бюджету державному бюджету на перерахування коштів в умовах воєнного стану або для здійснення згідно із законом заходів загальної мобілізації та з метою відсічі збройної агресії Російської Федерації проти України та забезпечення національної безпеки, усунення загрози небезпеки державній незалежності України, її територіальній цілісності (код 9820)', name: 'R07G9', visible: false },
  R07G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R07G10', visible: false },
  R07G11: { label: 'Трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R07G11', visible: false },
  R08G2: { label: 'Усього видатків загального фонду місцевих бюджетів (код 900203)', name: 'R08G2', visible: false },
  R08G3: { label: 'Усього видатків загального фонду місцевих бюджетів без урахування міжбюджетних трансфертів (код 900201)', name: 'R08G3', visible: false },
  R08G4: { label: 'Реверсна дотація (код 9110', name: 'R08G4', visible: false },
  R08G5: { label: 'Інші дотації з місцевого бюджету (код 9150)', name: 'R08G5', visible: false },
  R08G6: { label: 'Субвенції з місцевого бюджету іншим місцевим бюджетам на здійснення програм та заходів за рахунок коштів місцевих бюджетів (код 9700)', name: 'R08G6', visible: false },
  R08G7: { label: 'Субвенція з місцевого бюджету державному бюджету на виконання програм соціально-економічного розвитку регіонів (код 9800)', name: 'R08G7', visible: false },
  R08G8: { label: 'Субвенція з місцевого бюджету державному бюджету на фінансування діяльності військових адміністрацій із виконання повноважень органів місцевого самоврядування (код 9810)', name: 'R08G8', visible: false },
  R08G9: { label: 'Субвенція з місцевого бюджету державному бюджету на перерахування коштів в умовах воєнного стану або для здійснення згідно із законом заходів загальної мобілізації та з метою відсічі збройної агресії Російської Федерації проти України та забезпечення національної безпеки, усунення загрози небезпеки державній незалежності України, її територіальній цілісності (код 9820)', name: 'R08G9', visible: false },
  R08G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R08G10', visible: false },
  R08G11: { label: 'Трансферти з місцевого бюджету до інших місцевих бюджетів за рахунок трансфертів з державного бюджету', name: 'R08G11', visible: false },
  YEAR_FACT12: { label: 'Рік', name: 'YEAR_FACT12', visible: false },
  YEAR_FACT22: { label: 'Рік', name: 'YEAR_FACT22', visible: false },
  YEAR_FACT32: { label: 'Рік', name: 'YEAR_FACT32', visible: false },
  YEAR_PLAN12: { label: 'Рік', name: 'YEAR_PLAN12', visible: false },
  R09G3: { label: 'Доходи усього', name: 'R09G3', visible: false },
  R09G4: { label: 'У тому числі Загальний фонд', name: 'R09G4', visible: false },
  R09G5: { label: 'У тому числі Спеціальний фонд', name: 'R09G5', visible: false },
  R09G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R09G6', visible: false },
  R09G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R09G7', visible: false },
  R09G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R09G8', visible: false },
  R09G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R09G9', visible: false },
  R09G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R09G10', visible: false },
  R09G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R09G11', visible: false },
  R10G3: { label: 'Доходи усього', name: 'R10G3', visible: false },
  R10G4: { label: 'У тому числі Загальний фонд', name: 'R10G4', visible: false },
  R10G5: { label: 'У тому числі Спеціальний фонд', name: 'R10G5', visible: false },
  R10G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R10G6', visible: false },
  R10G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R10G7', visible: false },
  R10G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R10G8', visible: false },
  R10G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R10G9', visible: false },
  R10G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R10G10', visible: false },
  R10G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R10G11', visible: false },
  R11G3: { label: 'Доходи усього', name: 'R11G3', visible: false },
  R11G4: { label: 'У тому числі Загальний фонд', name: 'R11G4', visible: false },
  R11G5: { label: 'У тому числі Спеціальний фонд', name: 'R11G5', visible: false },
  R11G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R11G6', visible: false },
  R11G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R11G7', visible: false },
  R11G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R11G8', visible: false },
  R11G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R11G9', visible: false },
  R11G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R11G10', visible: false },
  R11G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R11G11', visible: false },
  R12G3: { label: 'Доходи усього', name: 'R12G3', visible: false },
  R12G4: { label: 'У тому числі Загальний фонд', name: 'R12G4', visible: false },
  R12G5: { label: 'У тому числі Спеціальний фонд', name: 'R12G5', visible: false },
  R12G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R12G6', visible: false },
  R12G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R12G7', visible: false },
  R12G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R12G8', visible: false },
  R12G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R12G9', visible: false },
  R12G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R12G10', visible: false },
  R12G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R12G11', visible: false },
  R13G3: { label: 'Доходи усього', name: 'R13G3', visible: false },
  R13G4: { label: 'У тому числі Загальний фонд', name: 'R13G4', visible: false },
  R13G5: { label: 'У тому числі Спеціальний фонд', name: 'R13G5', visible: false },
  R13G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R13G6', visible: false },
  R13G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R13G7', visible: false },
  R13G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R13G8', visible: false },
  R13G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R13G9', visible: false },
  R13G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R13G10', visible: false },
  R13G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R13G11', visible: false },
  R14G3: { label: 'Доходи усього', name: 'R14G3', visible: false },
  R14G4: { label: 'У тому числі Загальний фонд', name: 'R14G4', visible: false },
  R14G5: { label: 'У тому числі Спеціальний фонд', name: 'R14G5', visible: false },
  R14G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R14G6', visible: false },
  R14G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R14G7', visible: false },
  R14G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R14G8', visible: false },
  R14G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R14G9', visible: false },
  R14G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R14G10', visible: false },
  R14G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R14G11', visible: false },
  R15G3: { label: 'Доходи усього', name: 'R15G3', visible: false },
  R15G4: { label: 'У тому числі Загальний фонд', name: 'R15G4', visible: false },
  R15G5: { label: 'У тому числі Спеціальний фонд', name: 'R15G5', visible: false },
  R15G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R15G6', visible: false },
  R15G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R15G7', visible: false },
  R15G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R15G8', visible: false },
  R15G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R15G9', visible: false },
  R15G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R15G10', visible: false },
  R15G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R15G11', visible: false },
  R16G3: { label: 'Доходи усього', name: 'R16G3', visible: false },
  R16G4: { label: 'У тому числі Загальний фонд', name: 'R16G4', visible: false },
  R16G5: { label: 'У тому числі Спеціальний фонд', name: 'R16G5', visible: false },
  R16G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R16G6', visible: false },
  R16G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R16G7', visible: false },
  R16G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R16G8', visible: false },
  R16G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R16G9', visible: false },
  R16G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R16G10', visible: false },
  R16G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R16G11', visible: false },
  R17G3: { label: 'Доходи усього', name: 'R17G3', visible: false },
  R17G4: { label: 'У тому числі Загальний фонд', name: 'R17G4', visible: false },
  R17G5: { label: 'У тому числі Спеціальний фонд', name: 'R17G5', visible: false },
  R17G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R17G6', visible: false },
  R17G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R17G7', visible: false },
  R17G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R17G8', visible: false },
  R17G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R17G9', visible: false },
  R17G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R17G10', visible: false },
  R17G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R17G11', visible: false },
  R18G3: { label: 'Доходи усього', name: 'R18G3', visible: false },
  R18G4: { label: 'У тому числі Загальний фонд', name: 'R18G4', visible: false },
  R18G5: { label: 'У тому числі Спеціальний фонд', name: 'R18G5', visible: false },
  R18G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R18G6', visible: false },
  R18G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R18G7', visible: false },
  R18G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R18G8', visible: false },
  R18G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R18G9', visible: false },
  R18G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R18G10', visible: false },
  R18G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R18G11', visible: false },
  R19G3: { label: 'Доходи усього', name: 'R19G3', visible: false },
  R19G4: { label: 'У тому числі Загальний фонд', name: 'R19G4', visible: false },
  R19G5: { label: 'У тому числі Спеціальний фонд', name: 'R19G5', visible: false },
  R19G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R19G6', visible: false },
  R19G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R19G7', visible: false },
  R19G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R19G8', visible: false },
  R19G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R19G9', visible: false },
  R19G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R19G10', visible: false },
  R19G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R19G11', visible: false },
  R20G3: { label: 'Доходи усього', name: 'R20G3', visible: false },
  R20G4: { label: 'У тому числі Загальний фонд', name: 'R20G4', visible: false },
  R20G5: { label: 'У тому числі Спеціальний фонд', name: 'R20G5', visible: false },
  R20G6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'R20G6', visible: false },
  R20G7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'R20G7', visible: false },
  R20G8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'R20G8', visible: false },
  R20G9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'R20G9', visible: false },
  R20G10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'R20G10', visible: false },
  R20G11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'R20G11', visible: false },
  R21G3: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R21G3', visible: false },
  R21G4: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R21G4', visible: false },
  R21G5: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R21G5', visible: false },
  R21G6: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R21G6', visible: false },
  R21G7: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G7', visible: false },
  R21G8: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G8', visible: false },
  R21G9: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R21G9', visible: false },
  R21G10: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R21G10', visible: false },
  R21G11: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R21G11', visible: false },
  R21G12: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R21G12', visible: false },
  R21G13: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G13', visible: false },
  R21G14: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G14', visible: false },
  R21G15: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R21G15', visible: false },
  R21G16: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R21G16', visible: false },
  R21G17: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R21G17', visible: false },
  R21G18: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R21G18', visible: false },
  R21G19: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G19', visible: false },
  R21G20: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G20', visible: false },
  R21G21: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R21G21', visible: false },
  R21G22: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R21G22', visible: false },
  R21G23: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R21G23', visible: false },
  R21G24: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R21G24', visible: false },
  R21G25: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G25', visible: false },
  R21G26: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G26', visible: false },
  R22G3: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R22G3', visible: false },
  R22G4: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R22G4', visible: false },
  R22G5: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R22G5', visible: false },
  R22G6: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R22G6', visible: false },
  R22G7: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G7', visible: false },
  R22G8: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G8', visible: false },
  R22G9: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R22G9', visible: false },
  R22G10: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R22G10', visible: false },
  R22G11: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R22G11', visible: false },
  R22G12: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R22G12', visible: false },
  R22G13: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R22G13', visible: false },
  R22G14: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R22G14', visible: false },
  R22G15: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R22G15', visible: false },
  R22G16: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R22G16', visible: false },
  R22G17: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R22G17', visible: false },
  R22G18: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R22G18', visible: false },
  R22G19: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R22G19', visible: false },
  R22G20: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R22G20', visible: false },
  R22G21: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R22G21', visible: false },
  R22G22: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R22G22', visible: false },
  R22G23: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R22G23', visible: false },
  R22G24: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R22G24', visible: false },
  R22G25: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R22G25', visible: false },
  R22G26: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R22G26', visible: false },
  R23G3: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R23G3', visible: false },
  R23G4: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R23G4', visible: false },
  R23G5: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R23G5', visible: false },
  R23G6: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R23G6', visible: false },
  R23G7: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R21G7', visible: false },
  R23G8: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R21G8', visible: false },
  R23G9: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R23G9', visible: false },
  R23G10: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R23G10', visible: false },
  R23G11: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R23G11', visible: false },
  R23G12: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R23G12', visible: false },
  R23G13: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R23G13', visible: false },
  R23G14: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R23G14', visible: false },
  R23G15: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R23G15', visible: false },
  R23G16: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R23G16', visible: false },
  R23G17: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R23G17', visible: false },
  R23G18: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R23G18', visible: false },
  R23G19: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R23G19', visible: false },
  R23G20: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R23G20', visible: false },
  R23G21: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'R23G21', visible: false },
  R23G22: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'R23G22', visible: false },
  R23G23: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'R23G23', visible: false },
  R23G24: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'R23G24', visible: false },
  R23G25: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'R23G25', visible: false },
  R23G26: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'R23G26', visible: false },
};

/**
 * @const
 */
const tables = {
  // DOD1: {
  //   name: 'Дод1',
  //   label: 'Додаток 1',
  //   columns: {
  //     R01G2: { label: 'R01G2', name: 'R01G2' },
  //     R01G3: { label: 'R01G3', name: 'R01G3' },
  //     R01G4: { label: 'R01G4', name: 'R01G4' },
  //     R01G5: { label: 'R01G5', name: 'R01G5' },
  //     R01G6: { label: 'R01G6', name: 'R01G6' },
  //     R01G7: { label: 'R01G7', name: 'R01G7' },
  //     R01G8: { label: 'R01G8', name: 'R01G8' },
  //     R01G9: { label: 'R01G9', name: 'R01G9' },
  //     R01G10: { label: 'R01G10', name: 'R01G10' },
  //     R01G11: { label: 'R01G11', name: 'R01G11' },
  //     R01G12: { label: 'R01G12', name: 'R01G12' },
  //     R02G2: { label: 'R02G2', name: 'R02G2' },
  //     R02G3: { label: 'R02G3', name: 'R02G3' },
  //     R02G4: { label: 'R02G4', name: 'R02G4' },
  //     R02G5: { label: 'R02G5', name: 'R02G5' },
  //     R02G6: { label: 'R02G6', name: 'R02G6' },
  //     R02G7: { label: 'R02G7', name: 'R02G7' },
  //     R02G8: { label: 'R02G8', name: 'R02G8' },
  //     R02G9: { label: 'R02G9', name: 'R02G9' },
  //     R02G10: { label: 'R02G10', name: 'R02G10' },
  //     R02G11: { label: 'R02G11', name: 'R02G11' },
  //     R02G12: { label: 'R02G12', name: 'R02G12' },
  //     R03G2: { label: 'R03G2', name: 'R03G2' },
  //     R03G3: { label: 'R03G3', name: 'R03G3' },
  //     R03G4: { label: 'R03G4', name: 'R03G4' },
  //     R03G5: { label: 'R03G5', name: 'R03G5' },
  //     R03G6: { label: 'R03G6', name: 'R03G6' },
  //     R03G7: { label: 'R03G7', name: 'R03G7' },
  //     R03G8: { label: 'R03G8', name: 'R03G8' },
  //     R03G9: { label: 'R03G9', name: 'R03G9' },
  //     R03G10: { label: 'R033G10', name: 'R03G10' },
  //     R03G11: { label: 'R03G11', name: 'R03G11' },
  //     R03G12: { label: 'R03G12', name: 'R03G12' },
  //     R04G2: { label: 'R04G2', name: 'R04G2' },
  //     R04G3: { label: 'R04G2', name: 'R04G2' },
  //     R04G4: { label: 'R04G2', name: 'R04G2' },
  //     R04G5: { label: 'R04G2', name: 'R04G2' },
  //     R04G6: { label: 'R04G2', name: 'R04G2' },
  //     R04G7: { label: 'R04G2', name: 'R04G2' },
  //     R04G8: { label: 'R04G2', name: 'R04G2' },
  //     R04G9: { label: 'R04G2', name: 'R04G2' },
  //     R04G10: { label: 'R04G2', name: 'R04G2' },
  //     R04G11: { label: 'R04G2', name: 'R04G2' },
  //     R04G12: { label: 'R04G2', name: 'R04G2' },
  //     YEAR_FACT1: { label: 'Рік', name: 'YEAR_FACT1' },
  //     YEAR_FACT2: { label: 'Рік', name: 'YEAR_FACT2' },
  //     YEAR_FACT3: { label: 'Рік', name: 'YEAR_FACT3' },
  //     YEAR_PLAN1: { label: 'Рік', name: 'YEAR_PLAN1' },
  //   },
  // },
  // DOD2: {
  //   name: 'Дод2',
  //   label: 'Додаток 2',
  //   columns: {
  //     R05G2: { label: 'R05G2', name: 'R05G2' },
  //     R05G3: { label: 'R05G3', name: 'R05G3' },
  //     R05G4: { label: 'R05G4', name: 'R05G4' },
  //     R05G5: { label: 'R05G5', name: 'R05G5' },
  //     R05G6: { label: 'R05G6', name: 'R05G6' },
  //     R05G7: { label: 'R05G7', name: 'R05G7' },
  //     R05G8: { label: 'R05G8', name: 'R05G8' },
  //     R05G9: { label: 'R05G9', name: 'R05G9' },
  //     R05G10: { label: 'R05G10', name: 'R05G10' },
  //     R05G11: { label: 'R05G11', name: 'R05G11' },
  //     R06G2: { label: 'R06G2', name: 'R06G2' },
  //     R06G3: { label: 'R06G3', name: 'R06G3' },
  //     R06G4: { label: 'R06G4', name: 'R06G4' },
  //     R06G5: { label: 'R06G5', name: 'R06G5' },
  //     R06G6: { label: 'R06G6', name: 'R06G6' },
  //     R06G7: { label: 'R06G7', name: 'R06G7' },
  //     R06G8: { label: 'R06G8', name: 'R06G8' },
  //     R06G9: { label: 'R06G9', name: 'R06G9' },
  //     R06G10: { label: 'R06G10', name: 'R06G10' },
  //     R06G11: { label: 'R06G11', name: 'R06G11' },
  //     R07G2: { label: 'R07G2', name: 'R07G2' },
  //     R07G3: { label: 'R07G3', name: 'R07G3' },
  //     R07G4: { label: 'R07G4', name: 'R07G4' },
  //     R07G5: { label: 'R07G5', name: 'R07G5' },
  //     R07G6: { label: 'R07G6', name: 'R07G6' },
  //     R07G7: { label: 'R07G7', name: 'R07G7' },
  //     R07G8: { label: 'R07G8', name: 'R07G8' },
  //     R07G9: { label: 'R07G9', name: 'R07G9' },
  //     R07G10: { label: 'R07G10', name: 'R07G10' },
  //     R07G11: { label: 'R07G11', name: 'R07G11' },
  //     R08G2: { label: 'R08G2', name: 'R08G2' },
  //     R08G3: { label: 'R08G2', name: 'R08G2' },
  //     R08G4: { label: 'R08G2', name: 'R08G2' },
  //     R08G5: { label: 'R08G2', name: 'R08G2' },
  //     R08G6: { label: 'R08G2', name: 'R08G2' },
  //     R08G7: { label: 'R08G2', name: 'R08G2' },
  //     R08G8: { label: 'R08G2', name: 'R08G2' },
  //     R08G9: { label: 'R08G2', name: 'R08G2' },
  //     R08G10: { label: 'R08G2', name: 'R08G2' },
  //     R08G11: { label: 'R08G2', name: 'R08G2' },
  //     YEAR_FACT1: { label: 'Рік', name: 'YEAR_FACT1' },
  //     YEAR_FACT2: { label: 'Рік', name: 'YEAR_FACT2' },
  //     YEAR_FACT3: { label: 'Рік', name: 'YEAR_FACT3' },
  //     YEAR_PLAN1: { label: 'Рік', name: 'YEAR_PLAN1' },
  //   },
  // },
  TABL1: {
    name: 'TABL1',
    label: 'TABL1',
    columns: {
      T1RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T1RXXXXG1S' },
      T1RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T1RXXXXG2S' },
      T1RXXXXG3: { label: 'Доходи усього', name: 'T1RXXXXG3' },
      T1RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T1RXXXXG4' },
      T1RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T1RXXXXG5' },
      T1RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T1RXXXXG6' },
      T1RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T1RXXXXG7' },
      T1RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T1RXXXXG8' },
      T1RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T1RXXXXG9' },
      T1RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T1RXXXXG10' },
      T1RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T1RXXXXG11' },
    },
  },
  TABL2: {
    name: 'TABL2',
    label: 'TABL2',
    columns: {
      T2RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T2RXXXXG1S' },
      T2RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T2RXXXXG2S' },
      T2RXXXXG3: { label: 'Доходи усього', name: 'T2RXXXXG3' },
      T2RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T2RXXXXG4' },
      T2RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T2RXXXXG5' },
      T2RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T2RXXXXG6' },
      T2RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T2RXXXXG7' },
      T2RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T2RXXXXG8' },
      T2RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T2RXXXXG9' },
      T2RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T2RXXXXG10' },
      T2RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T2RXXXXG11' },
    },
  },
  TABL3: {
    name: 'TABL3',
    label: 'TABL3',
    columns: {
      T3RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T3RXXXXG1S' },
      T3RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T3RXXXXG2S' },
      T3RXXXXG3: { label: 'Доходи усього', name: 'T3RXXXXG3' },
      T3RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T3RXXXXG4' },
      T3RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T3RXXXXG5' },
      T3RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T3RXXXXG6' },
      T3RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T3RXXXXG7' },
      T3RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T3RXXXXG8' },
      T3RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T3RXXXXG9' },
      T3RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T3RXXXXG10' },
      T3RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T3RXXXXG11' },
    },
  },
  TABL4: {
    name: 'TABL4',
    label: 'TABL4',
    columns: {
      T4RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T4RXXXXG1S' },
      T4RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T4RXXXXG2S' },
      T4RXXXXG3: { label: 'Доходи усього', name: 'T4RXXXXG3' },
      T4RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T4RXXXXG4' },
      T4RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T4RXXXXG5' },
      T4RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T4RXXXXG6' },
      T4RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T4RXXXXG7' },
      T4RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T4RXXXXG8' },
      T4RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T4RXXXXG9' },
      T4RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T4RXXXXG10' },
      T4RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T4RXXXXG11' },
    },
  },
  TABL5: {
    name: 'TABL5',
    label: 'TABL5',
    columns: {
      T5RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T5RXXXXG1S' },
      T5RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T5RXXXXG2S' },
      T5RXXXXG3: { label: 'Доходи усього', name: 'T5RXXXXG3' },
      T5RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T5RXXXXG4' },
      T5RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T5RXXXXG5' },
      T5RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T5RXXXXG6' },
      T5RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T5RXXXXG7' },
      T5RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T5RXXXXG8' },
      T5RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T5RXXXXG9' },
      T5RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T5RXXXXG10' },
      T5RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T5RXXXXG11' },
    },
  },
  TABL6: {
    name: 'TABL6',
    label: 'TABL6',
    columns: {
      T6RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T6RXXXXG1S' },
      T6RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T6RXXXXG2S' },
      T6RXXXXG3: { label: 'Доходи усього', name: 'T6RXXXXG3' },
      T6RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T6RXXXXG4' },
      T6RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T6RXXXXG5' },
      T6RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T6RXXXXG6' },
      T6RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T6RXXXXG7' },
      T6RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T6RXXXXG8' },
      T6RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T6RXXXXG9' },
      T6RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T6RXXXXG10' },
      T6RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T6RXXXXG11' },
    },
  },
  TABL7: {
    name: 'TABL7',
    label: 'TABL7',
    columns: {
      T7RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T7RXXXXG1S' },
      T7RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T7RXXXXG2S' },
      T7RXXXXG3: { label: 'Доходи усього', name: 'T7RXXXXG3' },
      T7RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T7RXXXXG4' },
      T7RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T7RXXXXG5' },
      T7RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T7RXXXXG6' },
      T7RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T7RXXXXG7' },
      T7RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T7RXXXXG8' },
      T7RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T7RXXXXG9' },
      T7RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T7RXXXXG10' },
      T7RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T7RXXXXG11' },
    },
  },
  TABL8: {
    name: 'TABL8',
    label: 'TABL8',
    columns: {
      T8RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T8RXXXXG1S' },
      T8RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T8RXXXXG2S' },
      T8RXXXXG3: { label: 'Доходи усього', name: 'T8RXXXXG3' },
      T8RXXXXG4: { label: 'У тому числі Загальний фонд', name: 'T8RXXXXG4' },
      T8RXXXXG5: { label: 'У тому числі Спеціальний фонд', name: 'T8RXXXXG5' },
      T8RXXXXG6: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на початок бюджетного періоду', name: 'T8RXXXXG6' },
      T8RXXXXG7: { label: 'Надходження субвенцій з державного бюджету та субвенцій з інших місцевих бюджетів загального фонду місцевих бюджетів', name: 'T8RXXXXG7' },
      T8RXXXXG8: { label: 'Залишок коштів субвенції загального фонду місцевих бюджетів на кінець бюджетного періоду', name: 'T8RXXXXG8' },
      T8RXXXXG9: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього (розшифрувати у додатку 4)', name: 'T8RXXXXG9' },
      T8RXXXXG10: { label: 'Інше у разі необхідності (розшифрувати внизу таблиці)', name: 'T8RXXXXG10' },
      T8RXXXXG11: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів (розшифрувати у додатку 4)', name: 'T8RXXXXG11' },
    },
  },
  TABL9: {
    name: 'TABL9',
    label: 'TABL9',
    columns: {
      T9RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T9RXXXXG1S' },
      T9RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T9RXXXXG2S' },
      T9RXXXXG3: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T9RXXXXG3' },
      T9RXXXXG4: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T9RXXXXG4' },
      T9RXXXXG5: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T9RXXXXG5' },
      T9RXXXXG6: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T9RXXXXG6' },
      T9RXXXXG7: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T9RXXXXG7' },
      T9RXXXXG8: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T9RXXXXG8' },
      T9RXXXXG9: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T9RXXXXG9' },
      T9RXXXXG10: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T9RXXXXG10' },
      T9RXXXXG11: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T9RXXXXG11' },
      T9RXXXXG12: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T9RXXXXG12' },
      T9RXXXXG13: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T9RXXXXG13' },
      T9RXXXXG14: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T9RXXXXG14' },
      T9RXXXXG15: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T9RXXXXG15' },
      T9RXXXXG16: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T9RXXXXG16' },
      T9RXXXXG17: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T9RXXXXG17' },
      T9RXXXXG18: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T9RXXXXG18' },
      T9RXXXXG19: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T9RXXXXG19' },
      T9RXXXXG20: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T9RXXXXG20' },
      T9RXXXXG21: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T9RXXXXG21' },
      T9RXXXXG22: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T9RXXXXG22' },
      T9RXXXXG23: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T9RXXXXG23' },
      T9RXXXXG24: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T9RXXXXG24' },
      T9RXXXXG25: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T9RXXXXG25' },
      T9RXXXXG26: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T9RXXXXG26' },
    },
  },
  TABL10: {
    name: 'TABL10',
    label: 'TABL10',
    columns: {
      T10RXXXXG1S: { label: 'Код класифікації доходів бюджету у розрізі субвенцій', name: 'T10RXXXXG1S' },
      T10RXXXXG2S: { label: 'Найменування коду класифікації доходів бюджету у розрізі субвенцій', name: 'T10RXXXXG2S' },
      T10RXXXXG3: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T10RXXXXG3' },
      T10RXXXXG4: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T10RXXXXG4' },
      T10RXXXXG5: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T10RXXXXG5' },
      T10RXXXXG6: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T10RXXXXG6' },
      T10RXXXXG7: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T10RXXXXG7' },
      T10RXXXXG8: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T10RXXXXG8' },
      T10RXXXXG9: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T10RXXXXG9' },
      T10RXXXXG10: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T10RXXXXG10' },
      T10RXXXXG11: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T10RXXXXG11' },
      T10RXXXXG12: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T10RXXXXG12' },
      T10RXXXXG13: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T10RXXXXG13' },
      T10RXXXXG14: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T10RXXXXG14' },
      T10RXXXXG15: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T10RXXXXG15' },
      T10RXXXXG16: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T10RXXXXG16' },
      T10RXXXXG17: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T10RXXXXG17' },
      T10RXXXXG18: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T10RXXXXG18' },
      T10RXXXXG19: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T10RXXXXG19' },
      T10RXXXXG20: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T10RXXXXG20' },
      T10RXXXXG21: { label: 'Видатки загального фонду за рахунок субвенцій з державного бюджету та за рахунок субвенцій з інших місцевих бюджетів, всього гр. 11 Додатку 3', name: 'T10RXXXXG21' },
      T10RXXXXG22: { label: 'у тому числі код видатків у розрізі програмної класифікації', name: 'T10RXXXXG22' },
      T10RXXXXG23: { label: 'у тому числі сума видатків у розрізі програмної класифікації (загальні показники гр. 5 мають співпадати з показниками гр. 3)', name: 'T10RXXXXG23' },
      T10RXXXXG24: { label: 'Кошти відповідних субвенцій, що передаються із загального фонду бюджету до бюджету розвитку (спеціального фонду), всього гр. 9 з Додатку 3', name: 'T10RXXXXG24' },
      T10RXXXXG25: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) код видатків у розрізі програмної класифікації', name: 'T10RXXXXG25' },
      T10RXXXXG26: { label: 'Видатки, які здійснені (будуть здійснені) за рахунок переданих коштів, зазначених у гр. 9 Додатку 3, у тому числі в розрізі програмної класифікації видатків (видатки бюджету розвитку місцевих бюджетів) сума видатків у розрізі програмної класифікації', name: 'T10RXXXXG26' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Місцеві запозичення',
  name: 'localDebt',
  backendName: 'МестныеДолги',
  columns,
  frontend: 'doc/localDebt',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
