import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Звіт за соціальними виплатами  (тільки показники)',
  name: 'ReportSocial2',
  columns,
  frontend: 'rep/ReportSocial2',
  backendName: 'ОтчетСоцВыплаты2',
  resultColumns: baseResultColumns,
};

export default definition;
