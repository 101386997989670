import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={5} noGutters>
          {/* <Col> */}
          {/*  <TableHeaderCell */}
          {/*    className="text-center text-xl-left" */}
          {/*    active={activeCol === 'code'} */}
          {/*    highlighted={highlights.includes('code')} */}
          {/*    onToggleHighlght={() => onToggleHighlght('code')} */}
          {/*  > */}
          {/*    {meta.columns.code.label} */}
          {/*  </TableHeaderCell> */}
          {/*  <TableHeaderCell */}
          {/*    className="text-center text-xl-left" */}
          {/*    active={activeCol === 'name'} */}
          {/*    highlighted={highlights.includes('name')} */}
          {/*    onToggleHighlght={() => onToggleHighlght('name')} */}
          {/*  > */}
          {/*    {meta.columns.name.label} */}
          {/*  </TableHeaderCell> */}
          {/* </Col> */}
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum_2'}
              highlighted={highlights.includes('sum_2')}
              onToggleHighlght={() => onToggleHighlght('sum_2')}
            >
              {`${activeYear - 2} (звіт)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum_1'}
              highlighted={highlights.includes('sum_1')}
              onToggleHighlght={() => onToggleHighlght('sum_1')}
            >
              {`${activeYear - 1} (затверджено)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum0'}
              highlighted={highlights.includes('sum0')}
              onToggleHighlght={() => onToggleHighlght('sum0')}
            >
              {`${activeYear} (план)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum1'}
              highlighted={highlights.includes('sum1')}
              onToggleHighlght={() => onToggleHighlght('sum1')}
            >
              {`${activeYear + 1} (план)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              {`${activeYear + 2} (план)`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
