/* eslint-disable react/no-array-index-key */
import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import Header from './header';
import { dataPropTypes, fieldsPropTypes } from './propTypes';
import { errorsPropTypes } from '../../../../allocationplan/editor/tp/planningRows/propTypes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import FreeRem3Row from './freeRem3Row';
import { TPCommandPanel } from '../../../../../newEditor/commandPanels';

function FreeRem3RowSet({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'freerem3row_set', readOnlyFields, errors, fields,
  });
  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <FreeRem3Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

FreeRem3RowSet.propTypes = {
  data: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    merezharow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
};
FreeRem3RowSet.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};
export default FreeRem3RowSet;
