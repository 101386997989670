import styled from 'styled-components';
import { Tabs, Table } from 'react-bootstrap';

export const ContainerTabs = styled(Tabs)`
    > a{
        font-weight: 700;
        color: var(--colorTextTab) !important;
    }
`;

export const StyleTable = styled(Table)`
  th {
    position: sticky;
    top: 0;
    z-index: 3; 
    background: var(--falcon-gray-500);
    border-color: var(--falcon-gray-100);;
    color: var(--falcon-dark)
  };
`;
