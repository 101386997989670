/* eslint-disable no-confusing-arrow */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Button, ListGroup, ListGroupItem,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import MultiValueInput from './multiValueInput';
import { filterValuePropType } from '../../hooks/filter';

function ValueArrayInput({
  type, value, filter, onChange, collapsed,
}) {
  if (collapsed) {
    return (
      <div className="d-flex flex-wrap">
        {value.map((v, i) => (
        // eslint-disable-next-line react/no-array-index-key
          <Badge key={i} variant="light" pill>
            {/* eslint-disable-next-line no-nested-ternary */}
            {v ? ('repr' in v ? v.repr : v) : '<...>'}
          </Badge>
        ))}
      </div>
    );
  }

  return (
    <ListGroup variant="flush">
      {value.map((v, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListGroupItem key={i} className="d-flex align-items-center">
          <MultiValueInput
            onChange={(e, nv) => onChange(e, value.map((vv, ii) => ii === i ? nv : vv))}
            value={v}
            filter={filter}
            type={type}
          />
          <Button
            variant="outline-danger"
            onClick={(e) => onChange(e, value.filter((vv, ii) => ii !== i))}
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </Button>
        </ListGroupItem>
      ))}
      <ListGroupItem>
        <Button
          variant="outline-success"
          onClick={(e) => onChange(e, [...value, null])}
          className="w-100"
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </Button>
      </ListGroupItem>
    </ListGroup>
  );
}

ValueArrayInput.propTypes = {
  type: PropTypes.shape({
    ModelType: PropTypes.oneOf(['cat', 'N', 'S', 'B']).isRequired,
    ModelName: PropTypes.oneOfType([PropTypes.string]),
  }).isRequired,
  value: PropTypes.arrayOf(filterValuePropType),
  filter: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
};

ValueArrayInput.defaultProps = {
  filter: [],
  value: [],
  collapsed: false,
};

export default memo(ValueArrayInput);
