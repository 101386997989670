import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, Dropdown, ButtonGroup, Button,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import ApprovedInfo from '../../common/approvedInfo';
import TargetRowSet from './tp/target';
import ExpensesRowSet from './tp/expenses';
import ComparationRows from './tp/comparation';
import TargetComparationRows from './tp/targetcomparation';
import LowerDocs from './tp/levelDownRows';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.forecastPrepAdd2.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const usePCMProps = editorHooks.useCheckboxInputProps('use_pcm_dictionary', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const explanations32Props = editorHooks.useStringInputProps('explanations_32', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const explanations42Props = editorHooks.useStringInputProps('explanations_42', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          backendURL={meta.doc.allocationplan.backendURL}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col md={4}>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col md={4}>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col md={4}>
                <EditorControls.CheckboxInput {...usePCMProps} />
              </Col>
              <Col md={6}>
                <EditorControls.TextInput {...explanations32Props} rows={3} />
              </Col>
              <Col sm={6}>
                <EditorControls.TextInput {...explanations42Props} rows={3} />
              </Col>
              <Col md={6}>
                <Dropdown as={ButtonGroup}>
                  <Button variant="primary">Завантажити від розпорядників нижчого рівня</Button>
                  <Dropdown.Toggle split variant="primary" />
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Довантажити від розпорядників нижчого рівня</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.forecastPrepAdd2.backendURL}${data.id}/`}
            />
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="target">
          {fields && fields.forecastprepareadd2targets_set && (
            <Tab eventKey="target" title={fields.forecastprepareadd2targets_set.label}>
              <TabContainer className="p-0">
                <TargetRowSet
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd2expenses_set && (
            <Tab eventKey="expenses" title={fields.forecastprepareadd2expenses_set.label}>
              <TabContainer className="p-0">
                <ExpensesRowSet
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd2comparation_set && (
            <Tab eventKey="comparation" title={fields.forecastprepareadd2comparation_set.label}>
              <TabContainer className="p-0">
                <ComparationRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd2targetcomparation_set && (
            <Tab eventKey="targetcomparation" title={fields.forecastprepareadd2targetcomparation_set.label}>
              <TabContainer className="p-0">
                <TargetComparationRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd2leveldownrow_set && (
            <Tab eventKey="docs" title={fields.forecastprepareadd2leveldownrow_set.label}>
              <TabContainer className="p-0">
                <LowerDocs
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
