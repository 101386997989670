import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSUSelection from './csuSelectionBoostraped';

function CsuOwnerSelectionModal({
  data, actions, onOk, readOnly,
}) {
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const okHandler = useCallback(
    (dataCsu) => {
      setOpened(false);
      onOk(dataCsu);
    },
    [onOk],
  );

  return (
    <>
      <Button
        onClick={() => {
          setOpened(true);
          actions.onSR('НайтиПоЕдрпоу');
        }}
        disabled={readOnly}
        ref={ref}
      >
        <FontAwesomeIcon icon={faFileImport} className="me-2" />
        Знайти власника за ЄДРПОУ
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Пошук власника за ЄДРПОУ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <CSUSelection
            onLoad={okHandler}
            data={data}
            actions={actions}
            readOnly={readOnly}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

CsuOwnerSelectionModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

CsuOwnerSelectionModal.defaultProps = {
  readOnly: false,
};

export default CsuOwnerSelectionModal;
