import React, { useCallback, useState } from 'react';
import { fromJS } from 'immutable';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import FinPrintingEditor from '../../../dataProcessors/assigmentPrintingRev/editor';

function PrintButton({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );
  return (
    <>
      <Button variant="primary" onClick={() => setOpened(true)} disabled={selectedRows.length === 0} size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Друкована форма
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друк довідки
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FinPrintingEditor
            mapInitialOptions={{
              Document: 'ДокументСсылка',
            }}
            mapStore={mapStore}
          />

        </Modal.Body>
      </Modal>
    </>
  );
}

PrintButton.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrintButton;
