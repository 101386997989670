import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { processServerReq, goToOldVersion } from '../../../actions/dpEditor';
import StyledTPPane from '../../../components/tableParts/TPPane';
import Loading from '../../../components/styledLoading';
import LoadReportVariant from '../../catalogs/reportSettings/loadVariantButton';
import SaveReportVariant from '../../catalogs/reportSettings/saveVariantButton';
import VariantPanel from '../../variantSettings';
import { CommandPanelButtonText } from '../../../components/Form/styledForm';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

const ContainerButton = styled.div`
  display: inline-flex;
`;

const CommandPanelBut = styled(CommandPanelButtonText)`
  color: #4281C9;
  padding: 10.5px 10px;
  height: 25px;
  font-size: 0.65em;
`;

function DpContainer({
  children, Settings, backendName, reportVariants, isProcessing,
}) {
  const dispatch = useDispatch();

  const Header = (

    <div>
      <div style={{ marginRight: '10px' }}>Налаштування</div>

      <ContainerButton>
        <LoadReportVariant
          disabled={isProcessing}
          reportKey={`Обработки.${backendName}.reactReport`}
          onLoad={(e, variant) => dispatch(processServerReq('LoadVariant', { variant }))}
          LoadButton={CommandPanelBut}
        />
        <SaveReportVariant
          disabled={isProcessing}
          reportKey={`Обработки.${backendName}.reactReport`}
          onSave={(e, nameVariant, commentVariant) => dispatch(processServerReq('SaveVariant', { nameVariant, commentVariant }))}
          SaveButton={CommandPanelBut}
        />

      </ContainerButton>
    </div>
  );

  return (
    <div>
      <VariantPanel
        generateReport={() => {
          dispatch(processServerReq('PRINT'));
        }}
        generateVariant={(variant) => {
          dispatch(processServerReq('LoadVariant_GENERATE', { variant }));
        }}
        deleteVariant={(variant) => {
          dispatch(processServerReq('DeleteVariant', { variant }));
        }}
        Settings={Settings}
        // backendName={backendName}
        isProcessing={isProcessing}
        reportVariants={reportVariants}
        Header={Header}
      />

      {isProcessing && <Loading />}

      {children}
    </div>

  );
}

DpContainer.propTypes = {
  children: PropTypes.node,
  isProcessing: PropTypes.bool,
  Settings: PropTypes.element,
  backendName: PropTypes.string.isRequired,
  reportVariants: PropTypes.instanceOf(Map),
};

DpContainer.defaultProps = {
  children: null,
  Settings: null,
  reportVariants: null,
  isProcessing: false,
};

export default DpContainer;
