import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import TableDocListerRow from '../../../../newLister/tableComponent/table/row/doctablerow';

function FintrquestRow({ row, ...rest }) {
  const icon = useMemo(
    () => {
      if (row.approved && !row.is_financed) {
        return (
          <span className="fa-layers fa-fw fa-lg">
            <FontAwesomeIcon icon={faLock} className="text-info" transform="shrink-1 left-13" title="Затверджено" />
          </span>
        );
      }
      if (!row.approved && row.is_financed) {
        return (
          <span className="fa-layers fa-fw fa-lg">
            <FontAwesomeIcon icon={faSackDollar} className="text-warning" transform="shrink-1 right-3" title="Профінансовано" />
          </span>
        );
      }
      if (row.approved && row.is_financed) {
        return (
          <span className="fa-layers fa-fw fa-lg">
            <FontAwesomeIcon icon={faLock} className="text-info" transform="shrink-1 left-13" title="Затверджено" />
            <FontAwesomeIcon icon={faSackDollar} className="text-warning" transform="shrink-1 right-3" title="Профінансовано" />
          </span>
        );
      }
      return null;
    },
    [row],
  );
  return (
    <TableDocListerRow {...rest} row={row} icon={icon} />
  );
}

FintrquestRow.propTypes = {
  row: PropTypes.shape({
    approved: PropTypes.bool.isRequired,
    is_financed: PropTypes.bool.isRequired,
  }).isRequired,
};

export default memo(FintrquestRow);
