import docSelection from './docSelection';
import docCreation from './docCreation';
import docSelectionUpLevel from './docSelectionUpLevel';
import indicatorPassport from './indicatorPassport/docSelection';
import indicatorFact from './indicatorFact/docSelection';
import assigmentPrinting from './assigmentPrinting';
import requestsLoading from './requestsLoading';
import finPrinting from './finPrinting';
import requestsLoadingPlaningDoc from './requestsLoadingPlaningDoc';
import docRegisterPrinting from './docRegistrPrinting';
import clientBankUGB from './clientBankUGB';
import assigmentPrintingRev from './assigmentPrintingRev';
import changeToAssignPlanRev from './changeToAssignPlanRev';
import changeToAssignPlanSource from './changeToAssignPlanSource';
import assigmentPrintingSource from './assigmentPrintingSource';
import attachedFiles from './attachedFiles';
import exportVDBFKiev from './exportVDBFKiev';
import importTreasFiles from './importTreasFiles';
import exportPlansAndChangesToDBF from './exportPlansAndChangesToDBF';
import exportDBF373 from './exportDBF373';
import exportPlanToDBF from './exportPlanToDBF';
import exportVDBFKievDod21 from './exportVDBFKievDod21';
import exportVDBFKievDod21csu from './exportVDBFKievDod21csu';
import indicatorPassportPrinting from './indicatorPassport/indicatorPassportPrinting';
import budgetRequestPrint from './budgetRequestPrint';
import indicatorsFactPrint from './indicatorsFactPrint';
import planOfUsePrint from './planOfUsePrint';
import omitElementKFK from './omitElementKFK';
import estimatePrinting from './estimatePrinting';
import createElement from './createElement';
import omitElementKDB from './omitElementKDB';
import digitalSing from './digitalSing';
import treasureReportsDBF from './treasureReportsDBF';
import messageForUsers from './messageForUsers';
import authentication from './authentication';
import registrationFormPrinting from './registrationFormPrinting';
import sendFinRequestToHOC from './sendFinRequestToHOC';
import infoPaymentFinRequest from './infoPaymentFinRequest';
import signData from './signData';
import documentsInLogica from './documentsInLogica';
import publicDocumentsInLogica from './publicDocumentsInLogica';
import selfTesting from './selfTesting';
import logikaImportTreasureFiles from './logikaImportTreasureFiles';
import exportSocialPayment from './exportSocialPayment';
import certificateOfAccountPrint from './certificateOfAccountPrint';
import referRegistry from './referRegistry';
import periodPlanPrinting from './periodPlanPrinting';
import relatedRegisterInLogica from './relatedRegisterInLogica';
import forecastAdd1Printing from './forecastAdd1Printing';
import forecastAdd2Printing from './forecastAdd2Printing';
import forecastAdd3Printing from './forecastAdd3Printing';
import forecastAdd4Printing from './forecastAdd4Printing';
import forecastAdd5Printing from './forecastAdd5Printing';
import forecastAdd6Printing from './forecastAdd6Printing';
import forecastAdd7Printing from './forecastAdd7Printing';
import forecastAdd8Printing from './forecastAdd8Printing';
import forecastAdd12Printing from './forecastAdd12Printing';
import forecastAdd11Printing from './forecastAdd11Printing';
import forecastAdd10Printing from './forecastAdd10Printing';
import forecastAdd9Printing from './forecastAdd9Printing';
import docSelectionForecast from './docSelectionForecast';
import prepareForecastAdd1 from './prepareForecastAdd1';
import prepareForecastAdd2 from './prepareForecastAdd2';
import prepareForecastAdd5 from './prepareForecastAdd5';
import prepareForecastAdd4 from './prepareForecastAdd4';
import prepareForecastAdd3 from './prepareForecastAdd3';
import freeRemPrint from './freeRemPrint';
import printingFromLOGICA from './printingFromLOGICA';
import netFOPPrint from './netFOPPrint';
import netCategoryPrint from './netCategoryPrint';
import printPayment from './printPayment';
import netPrint from './netPrint';
import netDBF from './netDBF';
import finPrintingBR from './finPrintingBR';
import mixIndicatorsSFPrint from './mixIndicatorsSFPrint';
import paymentTransferPrint from './paymentTransferPrint';
import taxes8Print from './taxes8Print';
import taxesPrint from './taxesPrint';
import taxes2Print from './taxes2Print';
import taxes2PPrint from './taxes2PPrint';
import taxes3Print from './taxes3Print';
import taxes3PPrint from './taxes3PPrint';
import taxes4Print from './taxes4Print';
import taxes5Print from './taxes5Print';
import taxes6Print from './taxes6Print';
import taxes7Print from './taxes7Print';
import taxes81Print from './taxes81Print';
import taxes11Print from './taxes11Print';
import regInDBF from './regInDBF';
import expensesOnEducationPrinting from './expensesOnEducationPrinting';
import importFromLogica from './importFromLogica';
import repTaxpayers from './repTaxpayers';
import liquidityPrint from './liquidityPrint';
import garantyPrint from './garantyPrint';
import printing from './printing';
import expensesOnHealthPrinting from './expensesOnHealthPrinting';
import countExpensesOnEducationPrinting from './countExpensesOnEducationPrinting';
import localDebtPrinting from './localDebtPrinting';
import dataEducationPaymentPrinting from './dataEducationPaymentPrinting';
import reportsCalendar from './reportsCalendar';
import docSelectionTransfer from './docTransfertSelection';
import liquidityPrintVer2 from './liquidityPrintVer2';

const dataPropcessors = {
  assigmentPrinting,
  assigmentPrintingRev,
  assigmentPrintingSource,
  attachedFiles,
  budgetRequestPrint,
  clientBankUGB,
  changeToAssignPlanRev,
  changeToAssignPlanSource,
  docRegisterPrinting,
  docSelection,
  docCreation,
  indicatorPassport,
  indicatorFact,
  docSelectionUpLevel,
  exportDBF373,
  exportPlansAndChangesToDBF,
  exportPlanToDBF,
  exportVDBFKiev,
  exportVDBFKievDod21,
  exportVDBFKievDod21csu,
  finPrinting,
  importTreasFiles,
  indicatorPassportPrinting,
  indicatorsFactPrint,
  planOfUsePrint,
  omitElementKFK,
  requestsLoading,
  requestsLoadingPlaningDoc,
  estimatePrinting,
  createElement,
  omitElementKDB,
  digitalSing,
  messageForUsers,
  treasureReportsDBF,
  authentication,
  registrationFormPrinting,
  sendFinRequestToHOC,
  infoPaymentFinRequest,
  signData,
  documentsInLogica,
  publicDocumentsInLogica,
  selfTesting,
  logikaImportTreasureFiles,
  exportSocialPayment,
  certificateOfAccountPrint,
  referRegistry,
  periodPlanPrinting,
  relatedRegisterInLogica,
  forecastAdd1Printing,
  forecastAdd2Printing,
  forecastAdd3Printing,
  forecastAdd4Printing,
  forecastAdd5Printing,
  forecastAdd6Printing,
  forecastAdd7Printing,
  forecastAdd8Printing,
  forecastAdd12Printing,
  forecastAdd11Printing,
  forecastAdd10Printing,
  forecastAdd9Printing,
  docSelectionForecast,
  prepareForecastAdd1,
  prepareForecastAdd2,
  prepareForecastAdd5,
  prepareForecastAdd4,
  prepareForecastAdd3,
  freeRemPrint,
  printingFromLOGICA,
  netFOPPrint,
  netCategoryPrint,
  printPayment,
  netPrint,
  netDBF,
  finPrintingBR,
  mixIndicatorsSFPrint,
  paymentTransferPrint,
  taxes8Print,
  taxesPrint,
  taxes2Print,
  taxes2PPrint,
  taxes3Print,
  taxes3PPrint,
  taxes4Print,
  taxes5Print,
  taxes6Print,
  taxes7Print,
  taxes81Print,
  taxes11Print,
  regInDBF,
  expensesOnEducationPrinting,
  importFromLogica,
  repTaxpayers,
  liquidityPrint,
  garantyPrint,
  printing,
  expensesOnHealthPrinting,
  countExpensesOnEducationPrinting,
  localDebtPrinting,
  dataEducationPaymentPrinting,
  reportsCalendar,
  docSelectionTransfer,
  liquidityPrintVer2,
};

export default dataPropcessors;
