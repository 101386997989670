import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  year: { label: 'Рік', name: 'тГод' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  reportType: { label: 'Вид звіту', name: 'ВариантОтчета' },
  kfbClassifier: { label: 'КФБ', name: 'КФБ' },
  fondClassifier: { label: 'Фонд', name: 'Фонд', visible: true },
  signVariant: { label: 'Варіант підпису', name: 'Подпись' },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  consolidated: { label: 'Зведена форма', name: 'фСводний' },
  byDetails: { label: 'Деталізація', name: 'флДетализация' },
  byDocuments: { label: 'Показати документи', name: 'флДоДокумента' },
  incDB: { label: 'В т.ч. бюджет розвитку', name: 'флБР' },
  elementFond: { label: 'Елемент фонду', name: 'ЭлементФонд' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  byYear: { label: 'Річний', name: 'флГодовой' },
  changeSumSign: { label: 'Змінювати знак відображення суми', name: 'флМенятьЗнакОтображения' },
  ignoreSign: { label: 'Не враховувати знаки', name: 'флНеУчитыватьЗнак' },
};

const definition = {
  label: 'Розпис джерел фінансування',
  name: 'SourceAssigment',
  columns,
  frontend: 'rep/SourceAssigment',
  backendName: 'планисточников',
  resultColumns: baseResultColumns,
};

export default definition;
