const frontendURL = '/doc/incomeprojectchanges/';
const backendURL = '/api/planproject/incomeprojectchangesheader/';
const printURL = '';
const name = 'Зміни до проекту доходів';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  name,
};

export default instance;
