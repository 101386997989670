import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DocSelectionModal from './docLoading/docSelectionModal';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/dataEducationPayment';
import { ContainerSt, StyledLabel } from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { StampApprovedJs } from '../../../../assets/icons';
import { InputWithDropdown } from '../../../../components/styledInputs';
import meta from '../../../../constants/meta';

function DataEducationPayment({ data, actions, permissions }) {
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того, чтобы все хозяйство не перерендеривалось
  const readOnly = !permissions.canChange;
  // const FI = data[md.columns.FI.name];

  const repMonth = {
    m1: { label: 'Січень', name: 1 },
    m2: { label: 'Лютий', name: 2 },
    m3: { label: 'Березень', name: 3 },
    m4: { label: 'Квітень', name: 4 },
    m5: { label: 'Травень', name: 5 },
    m6: { label: 'Червень', name: 6 },
    m7: { label: 'Липень', name: 7 },
    m8: { label: 'Серпень', name: 8 },
    m9: { label: 'Вересень', name: 9 },
    m10: { label: 'Жовтень', name: 10 },
    m11: { label: 'Листопад', name: 11 },
    m12: { label: 'Грудень', name: 12 },
  };

  const getDocLogicaId = (o) => {
    for (const i in o) {
      if (i === 'id') {
        return o[i];
      }
    }
    return null;
  };

  const getDocLogicaRepr = (o) => {
    for (const i in o) {
      if (i === 'repr') {
        return o[i];
      }
    }
    return null;
  };

  // const FIFilter = useMemo(
  //   () => [{
  //     fieldName: 'Родитель',
  //     value: FI,
  //   }],
  //   [FI],
  // );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>{md.columns.reportMonth.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repMonth)}
            value={data[md.columns.reportMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reportMonth.name]: value,
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {' Сума виплаченої заробітної плати з '
              + 'утриманнями та нарахуваннями на неї за період, '
              + 'що передує звітному, протягом звітного місяця* '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'1. Сума виплаченої заробітної плати з '
              + 'утриманнями та нарахуваннями на неї за період, '
              + 'що передує звітному, протягом звітного місяця* '
            + 'за рахунок освітньої субвенці КЕКВ 2110'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'2. Сума виплаченої заробітної плати з '
              + 'утриманнями та нарахуваннями на неї за період, '
              + 'що передує звітному, протягом звітного місяця* '
              + 'за рахунок освітньої субвенці КЕКВ 2120'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G2.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'3. Сума виплаченої заробітної плати з '
              + 'утриманнями та нарахуваннями на неї за період, '
              + 'що передує звітному, протягом звітного місяця* '
              + 'за рахунок місцевих бюджетів КЕКВ 2110'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G3.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'4. Сума виплаченої заробітної плати з '
              + 'утриманнями та нарахуваннями на неї за період, '
              + 'що передує звітному, протягом звітного місяця* '
              + 'за рахунок місцевих бюджетів КЕКВ 2120'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G4.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G4.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {' Фактично зайняті ставки у звітному'
              + 'місяці, од. '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'5. Фактично зайняті ставки у звітному'
              + 'місяці, од. Всього'}
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G6.name] + data[md.columns.R01G7.name]
              + data[md.columns.R01G8.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G5.name]: value,
            })}
            readOnly
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            6. Фактично зайняті ставки у звітному місяці, од. у т.ч. вчителі
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G6.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G6.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            7. Фактично зайняті ставки у звітному місяці, од. у т.ч. інші педагогічні працівники
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G7.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G7.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            8. Фактично зайняті ставки у звітному місяці, од. у т.ч. непедагогічні працівники
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G8.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G8.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {'Затверджено кошторисних призначень на '
              + 'звітний місяць'}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'9. Затверджено кошторисних призначень на '
              + 'звітний місяць, з них: за рахунок освітньої субвенці КЕКВ 2110'}
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G9.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G9.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'10. Затверджено кошторисних призначень на '
              + 'звітний місяць, з них: за рахунок освітньої субвенці КЕКВ 2120'}
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G10.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G10.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'11. Затверджено кошторисних призначень на '
              + 'звітний місяць, з них: за рахунок місцевих бюджетів КЕКВ 2110'}
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G11.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G11.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'12. Затверджено кошторисних призначень на '
              + 'звітний місяць, з них: за рахунок місцевих бюджетів КЕКВ 2120'}
          </span>
        </Col>
        <Col lg={5}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G12.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G12.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'13. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
               + 'Фактично зайняті ставки педагогічних працівників, од. Всього'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G14.name] + data[md.columns.R01G15.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G13.name]: value,
            })}
            readOnly
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'14. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки педагогічних працівників, од. у т.ч. вчителі'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G14.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G14.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'15. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки педагогічних працівників, од. у т.ч. інші педагогічні працівники'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G15.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G15.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'16. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) Всього'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G17.name] + data[md.columns.R01G18.name]
              + data[md.columns.R01G20.name] + data[md.columns.R01G21.name]
              + data[md.columns.R01G22.name] + data[md.columns.R01G23.name]
              + data[md.columns.R01G24.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G16.name]: value,
            })}
            readOnly
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'17. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. фонд заробiтної плати за схемним окладом (включаючи підвищення)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G17.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G17.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'18. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки та доплати обов"язкового характеру'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G18.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G18.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'19. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. в т.ч. надбавка за престижнiсть'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G19.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G19.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'20. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки, що носять стимулюючий характер'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G20.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G20.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'21. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. премiї'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G21.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G21.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'22. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. допомога на оздоровлення'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G22.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G22.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'23. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. грошова винагорода'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G23.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G23.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'24. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. iншi види заробiтної плати'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G24.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G24.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'25. Нараховано заробітну плату за рахунок освітньої субвенції для виплати за звітний місяць, з них: '
              + 'Нарахування (КЕКВ 2120)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G25.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G25.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'26. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки, од. Всього'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G27.name] + data[md.columns.R01G28.name]
              + data[md.columns.R01G29.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G26.name]: value,
            })}
            readOnly
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'27. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки, од. у т.ч. вчителі'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G27.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G27.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'28. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки, од. у т.ч. інші педагогічні працівники'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G28.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G28.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'29. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Фактично зайняті ставки, од. у т.ч. непедагогічні працівники'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G29.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G29.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'30. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) Всього'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G31.name] + data[md.columns.R01G32.name]
              + data[md.columns.R01G34.name]
              + data[md.columns.R01G35.name] + data[md.columns.R01G36.name]
              + data[md.columns.R01G37.name] + data[md.columns.R01G38.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G30.name]: value,
            })}
            readOnly
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'31. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. фонд заробiтної плати за схемним окладом (включаю чи підвищен)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G31.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G31.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'32. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. Оплата праці педагогічних працівників (КЕКВ 2110) надбавки '
            + 'та доплати обов"язкового характеру'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G32.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G32.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'33. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. в т.ч. надбавка за престижнiсть'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G33.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G33.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'34. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. надбавки, що носять стимулюючий характер'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G34.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G34.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'35. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. премії'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G35.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G35.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'36. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. допомога на оздоровлення'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G36.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G36.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'37. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. грошова винагорода'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G37.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G37.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'38. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці педагогічних працівників (КЕКВ 2110) у т.ч. інші види заробітної плати'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G38.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G38.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'39. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Нарахування на оплату праці педагогічних працівників (КЕКВ2120)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G39.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G39.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'40. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Оплата праці непедагогічних праціників (КЕКВ2110)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G40.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G40.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'41. Нараховано заробітну плату за рахунок місцевих бюджетів для виплати за звітний місяць, з них: '
              + 'Нарахування на оплату праці непедагогічних працівників (КЕКВ2120)'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G41.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G41.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {'ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'42. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'інших педагогічних працівників (крімвчителів), в т.ч. за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G42.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G42.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'43. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'інших педагогічних працівників (крімвчителів), в т.ч. за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G43.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G43.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'44. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'викладання годин варіативної складової, в т.ч. за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G44.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G44.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'45. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'викладання годин варіативної складової, в т.ч. за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G45.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G45.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'46. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'роботи в умовах простою, в т.ч. за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G46.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G46.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'47. ДОВІДКОВО: із загальної суми нарахованої заробітної плати, обсяг коштів, спрямований для оплати (КЕКВ 2110): '
              + 'роботи в умовах простою, в т.ч. за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G47.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G47.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {'Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'48. Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: '
              + 'Сума виплаченої заробітної плати у звітному місяці за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G48.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G48.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'49. Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: '
              + 'Сума виплаченої заробітної плати у звітному місяці за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G49.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G49.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'50. Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: '
              + 'Сума заробітної плати, яка буде виплачена у місяці, наступному за звітним за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G50.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G50.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'51. Виплата нарахованої заробітної плати за звітний місяць з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.: '
              + 'Сума заробітної плати, яка буде виплачена у місяці, наступному за звітним за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G51.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G51.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {'Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'52. Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: '
              + 'Сплата нарахувань на заробітну плату у звітному місяці за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G52.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G52.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'53. Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: '
              + 'Сплата нарахувань на заробітну плату у звітному місяці за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G53.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G53.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'54. Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: '
              + 'Сума нарахувань, яка буде виплачена у місяці, наступному за звітним за рахунок освітньої субвенції'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G54.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G54.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {'55. Сплата нарахувань на заробітну плату за звітний місяць (КЕКВ 2120) у т.ч.: '
              + 'Сума нарахувань, яка буде виплачена у місяці, наступному за звітним за рахунок місцевих бюджетів'}
          </span>
        </Col>
        <Col lg={4}>
          <EditorControls.NumberInput
            label={null}
            value={data[md.columns.R01G55.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G55.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
            {data[md.columns.isApproved.name] && sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.invoice.label}
                value={data[md.columns.invoice.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.invoice.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.invoice.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
            onSR={actions.onSR}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>
            {md.columns.docLogica.label}
            <Link to={`${meta.doc.docTransferToLogica.frontend}/${getDocLogicaId(data[md.columns.docLogica.name])}`}>
              {getDocLogicaRepr(data[md.columns.docLogica.name])}
            </Link>
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.statusLogica.label]}
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.signLogica.label]}
          </StyledLabel>
        </Col>
      </Row>
    </EditorCollapse>
  );
}

DataEducationPayment.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
    [md.columns.invoice.name]: PropTypes.string,
    [md.columns.note.name]: PropTypes.string,
    [md.columns.author.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DataEducationPayment;
