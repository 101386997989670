import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import GridTemplate from './GridTemplate';
import { DivFooter } from '../../../../../components/styledTable/styledTable';

const Months = [...Array(12).keys()];

function FooterComponent({
  rows, activeAreaName,
}) {
  const sumByPeriod = [...Array(13).keys()].reduce(
    (r, v) => ({
      ...r,
      [`Sum${!v ? '' : v}`]: 0,
    }),
    {},
  );

  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

  const totals = rows.reduce((v, row) => (Object.keys(v).reduce((v1, key) => (
    { ...v1, [key]: v1[key] + row.get(key, 0) }), v)
  ), sumByPeriod);

  const sumColumns = Months.reduce((r, i, m) => ([...r,
    <DivFooter
      key={`s${m + 1}`}
      areaName={`s${m + 1}`}
      isActive={`s${m + 1}` === activeAreaName}
    >
      {formatter(totals[`Sum${m + 1}`].toFixed(2))}
    </DivFooter>,
  ]), []);

  return (
    <GridTemplate isHeader>
      <DivFooter areaName="1/1/4/8">Разом</DivFooter>
      <DivFooter areaName="sum">{formatter(totals.Sum.toFixed(2))}</DivFooter>
      {sumColumns}
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,
  activeAreaName: PropTypes.string,
};

FooterComponent.defaultProps = {
  activeAreaName: '',
};

export default FooterComponent;
