import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  fullName: {
    label: 'Повна назва',
    name: 'ПолнНаименование',
    visible: false,
  },
  salary: { label: 'Оклад', name: 'Оклад', visible: false },
  extraCharge1: { label: 'Надбавка1', name: 'Надбавка1', visible: false },
  extraCharge2: { label: 'Надбавка2', name: 'Надбавка2', visible: false },
  extraCharge3: { label: 'Надбавка3', name: 'Надбавка3', visible: false },
  surcharge1: { label: 'Доплата1', name: 'Доплата1', visible: false },
  surcharge2: { label: 'Доплата2', name: 'Доплата2', visible: false },
  surcharge3: { label: 'Доплата3', name: 'Доплата3', visible: false },
  surcharge4: { label: 'Доплата4', name: 'Доплата4', visible: false },
  key: { label: 'Номер для сортування', name: 'Ключ', visible: false },
  notIncludeInTotals: {
    label: 'Не включати до підсумку',
    name: 'НеВключатьВИтог',
    visible: false,
  },
  doNotPrint: {
    label: 'Не друкувати рядок',
    name: 'НеВыводитьНаПечать',
    visible: false,
  },
  owner: { label: 'Владелец', name: 'Владелец', visible: true },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  folder: { label: 'Папка', name: 'Родитель', visible: false },
};

export const statesContingents = {
  label: 'Категорії працівників',
  name: 'statesContingents',
  backendName: 'ШтатиКонтингенты',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/statesContingents',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default statesContingents;
