import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const useRelations = () => {
  const [relations, setRelations] = useState([]);

  const [activeRelation, setActiveRelation] = useState(null);

  const onAddRelation = useCallback(
    () => setRelations((oldRels) => [
      ...oldRels,
      {
        key: Math.random().toString(36),
        fromTable: '',
        fromField: '',
        toTable: '',
        toField: '',
        mandatory: false,
      },
    ]),
    [],
  );

  const onDeleteRelation = useCallback(
    (relKey) => setRelations((oldRels) => oldRels.filter((r) => r.key !== relKey)),
    [],
  );

  const onChangeRelation = useCallback(
    (relKey, relValue) => setRelations(
      (oldRels) => oldRels.map((r) => (r.key === relKey ? { ...r, ...relValue } : r)),
    ),
    [],
  );
  const relationHandlers = useMemo(() => ({
    onAddRelation,
    onChangeRelation,
    setActiveRelation,
    onDeleteRelation,
  }), [onAddRelation, onChangeRelation, onDeleteRelation]);

  return {
    relations, setRelations, activeRelation, relationHandlers,
  };
};

export const relationPropType = PropTypes.shape({
  key: PropTypes.string,
  fromTable: PropTypes.string,
  fromField: PropTypes.string,
  toTable: PropTypes.string,
  toField: PropTypes.string,
  mandatory: PropTypes.bool,
});
