import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';
import { NewIcon } from '../../../icons';

export function NewButton({ content, onClick, disabled }) {
  return (
    <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
      <StyledImg style={{ width: '20px' }} src={NewIcon} alt={content} />
      <StyledText>{content}</StyledText>
    </StyledCommandPanelButton>
  );
}

NewButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

NewButton.defaultProps = {
  onClick: () => null,
  content: 'Створити',
  disabled: false,
};

export default NewButton;
