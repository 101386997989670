import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/incomingMoney';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function IncomingMoney({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const FI = data[md.columns.FI.name];

  const readOnly = !permissions.canChange;

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.RegNumber.label}
            value={data[md.columns.RegNumber.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RegNumber.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCreate.label}
            value={data[md.columns.DateCreate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateCreate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.Year.label}
            value={data[md.columns.Year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Fond.label}
            value={data[md.columns.Fond.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.Fond.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="fondClassifier"
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.KEKVNatural.label}
            value={data[md.columns.KEKVNatural.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.KEKVNatural.name]: value,
              });
              actions.onSR('CHANGE_KEKV');
            }}
            modelType="cat"
            modelName="elementKEKV"
            readOnly={readOnly}
            filter={fondFilter}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ClosingAcc-${data.id}`}
            label={md.columns.ClosingAcc.label}
            value={data[md.columns.ClosingAcc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ClosingAcc.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`NoControlOfCredits-${data.id}`}
            label={md.columns.NoControlOfCredits.label}
            value={data[md.columns.NoControlOfCredits.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NoControlOfCredits.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

IncomingMoney.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default IncomingMoney;
