import React, { useCallback, useState } from 'react';
import {
  Accordion, Badge, Button, Col, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faTools } from '@fortawesome/free-solid-svg-icons';
import useLogicaPlans from './hook';
import LogcaLoginModal from '../LogikaLogin/LogcaLoginModal';
import { Loader } from '../../../components/bootStrap';
import IconAlert from '../../../components/blanks/common/IconAlert';
import { EditorControls } from '../../basicEditor/editorControls';
import { FORMS, getDateStr } from './consts';
import DateLabel from '../../../components/Controls/dates/dateLabel';
import PageHeader from '../../../components/blanks/common/PageHeader';
import metaSrv from '../../../meta/srv/ImportPlans';

function Editor() {
  const {
    err, loading, done, onClearErr, onGetStatus, year, setYear, onClearDone, logicaPingSuccess,
    status, onLoad,
  } = useLogicaPlans();
  const [show, setShow] = useState('0');
  const onAdditionalSettings = useCallback(
    () => {
      onGetStatus(year);
      setShow('1');
    },
    [onGetStatus, year],
  );
  const onChangeYear = useCallback(
    (e, v) => {
      setYear(v);
      onGetStatus(v);
    },
    [onGetStatus, setYear],
  );
  return (
    <>
      <PageHeader
        title={metaSrv.name}
        description={metaSrv.name}
        className="mb-3"
      />
      <LogcaLoginModal />
      {loading && (
        <Loader text="Завантаження" />
      )}
      <Nav className="gap-2">
        <Button onClick={() => onLoad()} variant="outline-success" className="me-2" disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faRocket} className="me-2" />
          Оновити все
        </Button>
        <Button onClick={onAdditionalSettings} variant="outline-warning" disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faTools} className="me-2" />
          Оновити з додатковими налаштуваннями
        </Button>
      </Nav>
      {done && <IconAlert variant="success" dismissible onClose={onClearDone}>Виконано вдало!</IconAlert>}
      {err && <IconAlert variant="danger" dismissible onClose={onClearErr}>{err}</IconAlert>}
      <Accordion activeKey={show}>
        <Accordion.Item eventKey="1">
          <Accordion.Body>
            <Row>
              <Col>
                <EditorControls.YearInput value={year} onChange={onChangeYear} label="Рік" required />
              </Col>
              <Col>
                {status && (
                <ul className="list-unstyled">
                  {Object.entries(FORMS).map(([k, v]) => (
                    <li key={k} className="d-flex gap-2 justify-content-between mb-2">
                      <div className="fw-bold">
                        {v}
                        {status.current_date[k] && (
                          <Badge className="ms-1"><DateLabel value={status.current_date[k]} /></Badge>
                        )}
                      </div>
                      <Button variant="outline-success" onClick={() => onLoad(k)}>
                        Завантажити за
                        {' '}
                        {getDateStr(status[k])}
                      </Button>
                    </li>
                  ))}
                </ul>
                )}
              </Col>
            </Row>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default Editor;
