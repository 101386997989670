import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  Link: { label: 'Ссылка', name: 'Ссылка' },
  InOrder373: { label: 'ПоПриказу373', name: 'ПоПриказу373' },
  AddressStorage: { label: 'АдресХранилища', name: 'АдресХранилища' },
  Date: { label: 'Дата', name: 'Дата' },
  NumberDoc: { label: 'НомерДок', name: 'НомерДок' },
  KindDoc: { label: 'Вид документу', name: 'ВидДокумента' },
  NameFile: { label: 'ИмяФайла', name: 'ИмяФайла' },
  FormatData: { label: 'ФорматДанных', name: 'ФорматДанных' },
  CodingWin: { label: 'Кодування Windows', name: 'КодировкаВиндовс' },
  Folder: { label: 'Папка', name: 'Папка' },
  StartDt: { label: 'з', name: 'НачДт' },
  FinoshDt: { label: 'по', name: 'КонДт' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  FlTurnStrings: { label: 'Згортати за КФК та КЕКВ', name: 'флСворачиватьСтроки' },
  VDD: { label: 'VDD (великі букви)  для розподілу', name: 'VDD' },
  AccountInNameFolder: { label: 'Рахунок в імені папки', name: 'СчетВИмениПапки' },
  AccountNo: { label: 'НомерСчет', name: 'НомерСчет' },
  Variant2: { label: 'Дані  по варіанту 2', name: 'Вариант2' },
  Transfer: { label: 'Вид розпоряджень', name: 'Передача' },
  SighnBuh: { label: 'Фінансист', name: 'ПодписьБухгалтер' },
  SighnDirector: { label: 'Керівник', name: 'ПодписьНачальник' },
  FO2: { label: 'Установа що надає фінансування', name: 'Финорган' },
  Recipient: { label: 'Установа отримувач  коштів', name: 'Получатель' },
  FieldFond: { label: 'Використовувати наступні значення для визначення типу фонду ,поле  (SK  в  файлі розподілу)', name: 'ПолеФонд' },
  NoNote: { label: 'не враховувати примітку при згортанні', name: 'БезПримечания' },
  PCM: { label: 'За класифікаторами ПЦМ', name: 'ПЦМ' },
  DevBudget: { label: 'Розпорядження (розподіл)  про виділення коштів спеціального фонду місцевих бюджетів, які обслуговуються в установах банків (Дод.№21)', name: 'БюджетРазвития' },
  FileNameDB: { label: 'ИмяФайлаБР', name: 'ИмяФайлаБР' },
  NameCSUShort: { label: 'Скорочена назва установ', name: 'НаимРаспСокращенный' },
  DBF373UD: { label: 'формат файла з подовженими полями для системи Е-КАЗНА', name: 'ДБФ373УД' },
  Note160: { label: 'Збільшене число символів у примітці (160 знаків)', name: 'Примечание160Смволов' },
  UnderlieSymbol: { label: 'Символ "_" замість "." в імені файла', name: 'СимволПодчеркивания' },
  DBF3732: { label: 'Передача коштів із ЗФ до СФ за наказом 373', name: 'ДБФ3732' },
  TransFPlat: { label: 'Передача  у форматі файлів платіжного доручення', name: 'ПередачаФорматПлат' },
  fromFin: { label: 'Джерело даних для запису у файл', name: 'ИзФинансирования' },
  separDoc: { label: 'Вивантажквати документи окремо', name: 'Отдельно' },
};

const tables = {
  tpDocuments: {
    name: 'тчДокументы',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'ДокФинансирование' },
      NumDoc: { label: 'НомерДок', name: 'НомерДок' },
      DocumentPresent: { label: 'Документ', name: 'ДокПредставление' },
    },
  },
  TVFonds: {
    name: 'ТЗФонды',
    label: 'ТЗФонды',
    columns: {
      selected: { label: 'Обрано', name: 'Выбран' },
      fond: { label: 'Фонд', name: 'Фонд' },
    },
  },
  TVOrderDB: {
    name: 'ТЗРаспБР',
    label: 'ТЗРаспБР',
    columns: {
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      KVK: { label: 'KVK', name: 'KVK' },
      DAGL: { label: 'DAGL', name: 'DAGL' },
      NRGL: { label: 'NRGL', name: 'NRGL' },
      DK: { label: 'DK', name: 'DK' },
      UDK: { label: 'UDK', name: 'UDK' },
      NOB: { label: 'NOB', name: 'NOB' },
      VDK: { label: 'VDK', name: 'VDK' },
      NRA: { label: 'NRA', name: 'NRA' },
      KOKPO: { label: 'KOKPO', name: 'KOKPO' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      POLUT: { label: 'POLUT', name: 'POLUT' },
      MFO: { label: 'MFO', name: 'MFO' },
      NAZB: { label: 'NAZB', name: 'NAZB' },
      NLSA: { label: 'NLSA', name: 'NLSA' },
      NLSK: { label: 'NLSK', name: 'NLSK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      KFK: { label: 'KFK', name: 'KFK' },
      KPK: { label: 'KPK', name: 'KPK' },
      S0: { label: 'S0', name: 'S0' },
      NAZ: { label: 'NAZ', name: 'NAZ' },
      FN: { label: 'FN', name: 'FN' },
      PON: { label: 'PON', name: 'PON' },
      FB: { label: 'FB', name: 'FB' },
      POB: { label: 'POB', name: 'POB' },
      RIV: { label: 'RIV', name: 'RIV' },
      SK: { label: 'SK', name: 'SK' },
      KOKPOR: { label: 'KOKPOR', name: 'KOKPOR' },
      NAMER: { label: 'NAMER', name: 'NAMER' },
    },
  },
  TVDistribDB: {
    name: 'ТЗРаспредБР',
    label: 'ТЗРаспредБР',
    columns: {
      KVK: { label: 'KVK', name: 'KVK' },
      KVKD: { label: 'KVKD', name: 'KVKD' },
      DAGL: { label: 'DAGL', name: 'DAGL' },
      NRGL: { label: 'NRGL', name: 'NRGL' },
      UDK: { label: 'UDK', name: 'UDK' },
      VDK: { label: 'VDK', name: 'VDK' },
      DK: { label: 'DK', name: 'DK' },
      KOBL: { label: 'KOBL', name: 'KOBL' },
      KRAJ: { label: 'KRAJ', name: 'KRAJ' },
      KSEL: { label: 'KSEL', name: 'KSEL' },
      KTER: { label: 'KTER', name: 'KTER' },
      NOB: { label: 'NOB', name: 'NOB' },
      NRA: { label: 'NRA', name: 'NRA' },
      KOKPO: { label: 'KOKPO', name: 'KOKPO' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      POLUT: { label: 'POLUT', name: 'POLUT' },
      NAZB: { label: 'NAZB', name: 'NAZB' },
      MFO: { label: 'MFO', name: 'MFO' },
      NLSK: { label: 'NLSK', name: 'NLSK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      KEKVD: { label: 'KEKVD', name: 'KEKVD' },
      KFK: { label: 'KFK', name: 'KFK' },
      KPK: { label: 'KPK', name: 'KPK' },
      KFKD: { label: 'KFKD', name: 'KFKD' },
      S0: { label: 'S0', name: 'S0' },
      S1: { label: 'S1', name: 'S1' },
      S2: { label: 'S2', name: 'S2' },
      NAZ: { label: 'NAZ', name: 'NAZ' },
      FN: { label: 'FN', name: 'FN' },
      PON: { label: 'PON', name: 'PON' },
      FB: { label: 'FB', name: 'FB' },
      POB: { label: 'POB', name: 'POB' },
      RIV: { label: 'RIV', name: 'RIV' },
      SK: { label: 'SK', name: 'SK' },
      NLSA: { label: 'NLSA', name: 'NLSA' },
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      KOD: { label: 'KOD', name: 'KOD' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'exportVDBFKievDod21csu',
  backendName: 'ЭкспортВДБФКиев_Дод21Расп',
  frontend: 'dp/exportVDBFKievDod21csu',
  label: 'Вивантаження  даних по бюджету розвитку  в ДБФ (для розпорядників) Додаток№21',
  columns,
  tables,
};

export default definition;
