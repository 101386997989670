import React from 'react';

const LogicaContext = React.createContext({
  data: null,
  onLogin: () => null,
  ticket: null,
  useWidgetDiya: false,
  setUseDigetDiya: () => null,
  onLogout: () => null,
  widgetURI: '',
});

export default LogicaContext;
