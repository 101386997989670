import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  account: { label: 'Банківський рахунок', name: 'БанковскийСчет' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  period: { label: 'Період', name: 'Период' },
  exportMarkedAsPaid: { label: 'Експортувати помічені як "Сплачені"', name: 'ЭкпортироватьОплаченные' },
  exportFormat: { label: 'Формат експорту', name: 'КакойБанк' },
  // Дополнитльеные свойства
  // exportFile: { label: 'ФайлЭкспорта', name: 'ФайлЭкспорта' },
};

/**
 * @const
 */
const tables = {
  import: {
    name: 'Импорт',
    label: 'Імпорт',
    columns: {
      bankName: { label: 'Назва банку', name: 'НазваниеБанка' },
      incOuntc: { label: 'Надходження / видатки', name: 'ПриходРасход' },
      sum: { label: 'Сума', name: 'Сумма' },
      creationDate: { label: 'Дата створення', name: 'ДатаСоздания' },
      executionDate: { label: 'Дата проведення', name: 'ДатаПроведения' },
      mfo: { label: 'МФО', name: 'v' },
      account: { label: 'Рахунок', name: 'Счет' },
      client: { label: 'Контрагент', name: 'Контрагент' },
      edrpou: { label: 'ЄДРПОУ', name: 'ЕДРПОУ' },
      purpose: { label: 'Призначення платежу', name: 'Назначение' },
      state: { label: 'Стан документу', name: 'СостояниеДокумента' },
      doc: { label: 'Документ', name: 'Документ' },
      lineNo: { label: 'Номер стрічки документу', name: 'НомерСтрокиДокумента' },
      docNo: { label: 'Номер документу', name: 'НомерДокумента' },
    },
  },
  export: {
    name: 'Экспорт',
    label: 'Експорт',
    columns: {
      sum: { label: 'Сума', name: 'Сумма' },
      date: { label: 'Дата', name: 'Дата' },
      mfo: { label: 'МФО', name: 'МФО' },
      edrpou: { label: 'ЄДРПОУ', name: 'ЕДРПОУ' },
      purpose: { label: 'Призначення платежу', name: 'Назначение' },
      doc: { label: 'Документ', name: 'Документ' },
      lineNo: { label: 'Номер стрічки документу', name: 'НомерСтрокиДокумента' },
      docNo: { label: 'Номер документу', name: 'НомерДокумента' },
      client: { label: 'Контрагент', name: 'Контрагент' },
      bankName: { label: 'Банк контаргента', name: 'БанкКонтаргента' },
      account: { label: 'Рахунок контрагента', name: 'РСКонтрагента' },
      check: { label: 'М', name: 'М' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'clientBankUGB',
  backendName: 'КлиентБанка_УкрГазБанк',
  frontend: 'dp/clientBankUGB',
  label: 'Обробка обміну даними з системою "Клієнт - банк"(УкрГазБанк,Приватбанк)',
  columns,
  tables,
};

export default definition;
