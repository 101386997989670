import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Container } from 'react-bootstrap';
import { GeneralTP, TvDocTP, TargetProgTP } from './tabs';
import md from '../../../../constants/meta/documents/draftAnnualSpend';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function DraftAnnualSpendTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const isSplitTragetProg = data[md.columns.splitTragetProg.name];
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.tvDoc.label} eventKey="tvDoc">
        <TvDocTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      {isSplitTragetProg && (
        <Tab title={md.tables.targetProg.label} eventKey="targetProg">
          <TargetProgTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

DraftAnnualSpendTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default DraftAnnualSpendTabs;
