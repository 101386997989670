import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import RowTablePart from '../styledInputs/rowTablePart';
import ea from '../../actions/editor';
import dpea from '../../actions/dpEditor';

const asTypes = {
  editor: 'editor',
  dpEditor: 'dpEditor',
};

class RowCard extends Component {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    onlyYear: PropTypes.bool,
    inBR: PropTypes.bool,
    isShowKdbKfb: PropTypes.bool,
    disabled: PropTypes.bool,
    setDataRef: PropTypes.func,
    processingKeyDown: PropTypes.func,
    AddFilterCsuToListElemKFK: PropTypes.bool,
    activeAreaName: PropTypes.string,
    tableName: PropTypes.string,
    actionSource: PropTypes.oneOf(Object.values(asTypes)),
  };

  static defaultProps = {
    onlyYear: false,
    inBR: false,
    isShowKdbKfb: false,
    disabled: false,
    setDataRef: () => null,
    processingKeyDown: () => null,
    AddFilterCsuToListElemKFK: true,
    activeAreaName: '',
    tableName: '',
    actionSource: asTypes.editor,
  };

  // Для чего так делать?
  // У нас во всех таличных частях есть onlyYear, inBR, isShowKdbKfb?
  // А если меняются другие реквизиты их не надо перерисовывать?
  disabled_shouldComponentUpdate(nextProps) {
    const {
      // dispatch, setDataRef, processingKeyDown, activeAreaName,
      ...t
    } = nextProps;
    const propsToRender = Object.keys(t);
    // const propsToRender = ['row', 'rowId', 'disabled'];
    const changedProps = propsToRender.filter((p) => this.props[p] !== nextProps[p]);

    return (!!changedProps.length
        && ((this.props.onlyYear !== nextProps.onlyYear)
        || (this.props.inBR !== nextProps.inBR)
        || (this.props.isShowKdbKfb !== nextProps.isShowKdbKfb)
        || (this.props.row.get('Check', false) !== nextProps.row.get('Check', false))
        || (nextProps.row.get('IS_ACTIVE', false))
        || (this.props.row.get('IS_ACTIVE', false)))
    );
  }

  getActions = (as) => {
    switch (as) {
      case asTypes.editor:
        return ea;
      case asTypes.dpEditor:
        return dpea;
      default:
        return null;
    }
  };

  render() {
    const {
      rowId,
      row,
      BodyComponent,
      setDataRef,
      processingKeyDown,
      dispatch,
      disabled,
      tableName,
      activeAreaName,
      actionSource,
      ...restProps
    } = this.props;
    const a = this.getActions(actionSource);

    return (
      <RowTablePart
        active={row.get('IS_ACTIVE')}
        onClick={(e) => dispatch(a.activateTableRow(tableName, rowId, e.ctrlKey))}
      >
        <BodyComponent
          size="mini"
          row={row}
          rowId={rowId}
          dispatch={dispatch}
          disabled={disabled}
          setDataRef={setDataRef}
          processingKeyDown={processingKeyDown}
          activeAreaName={activeAreaName}
          tableName={tableName}
          {...restProps}
        />

      </RowTablePart>

    );
  }
}
export default RowCard;
