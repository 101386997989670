import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import baseMD from '../../../../constants/meta/documents/docTransferToLogicaSinging';
import baseEditorMD from '../../../../constants/meta/documents/docTransferToLogica';
import TableListerCell from '../../../newLister/tableComponent/table/tablecell';

function LogikaListerRow({
  row, columns, onFocus, selected, tabIndex, onContextMenu,
}) {
  const navigate = useNavigate();

  const className = useMemo(
    () => {
      let result = 'docrow';
      result += `${selected ? ' selected' : ''} `;
      result += `${row[baseMD.columns.isDeleted.name] ? ' deleted' : ''} `;
      result += `${row[baseMD.columns.isProcessed.name] ? ' executed' : ' unexecuted'} `;
      return result;
    },
    [row, selected],
  );
  const signed = useMemo(
    () => row[baseMD.columns.signed.name],
    [row],
  );
  const unsigned = useMemo(
    () => row[baseMD.columns.unsigned.name],
    [row],
  );
  const onClickHandler = useCallback(
    (e) => {
      onFocus(e, row.id, true);
    },
    [onFocus, row.id],
  );

  return (
    <tr
      data-id={row.id}
      onClick={onClickHandler}
      className={className}
      tabIndex={tabIndex}
      onDoubleClick={(e) => navigate(`/${baseEditorMD.frontend}/${row.id}/`)}
    >
      {columns.map((c) => (
        <TableListerCell
          key={c.key}
          value={row[c.name]}
          variants={c.variants}
          type={c.type}
          onContextMenu={(e) => onContextMenu(e, row.id)}
        />
      ))}
      <td onContextMenu={onContextMenu}>
        <ul className="mb-0">
          {signed.map((sgn) => (
            <li className="position-relative" key={sgn.id}>
              <FontAwesomeIcon icon={faUserCheck} color="var(--falcon-success)" listItem />
              {sgn && sgn.repr}
            </li>
          ))}
          {unsigned.map((sgn) => (
            <li className="position-relative" key={sgn.id}>
              <FontAwesomeIcon icon={faUserTimes} color="var(--danger)" listItem />
              {sgn && sgn.repr}
            </li>
          ))}
        </ul>
      </td>
    </tr>

  );
}

LogikaListerRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'date', 'moneys', 'boolVariants', 'bool', 'variants',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    [baseMD.columns.isDeleted.name]: PropTypes.bool.isRequired,
    [baseMD.columns.isProcessed.name]: PropTypes.bool.isRequired,
    [baseMD.columns.signed.name]: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      repr: PropTypes.string,
    })),
    [baseMD.columns.unsigned.name]: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      repr: PropTypes.string,
    })),
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
};

LogikaListerRow.defaultProps = {
  selected: false,

};
export default memo(LogikaListerRow);
