import React, { forwardRef, useContext, useMemo } from 'react';
import {
  ButtonGroup, ButtonToolbar, Dropdown, DropdownButton, Navbar,
} from 'react-bootstrap';
import baseMD from '../../../../constants/meta/documents/docTransferToLogica';
import ListerContext from '../../../newLister/context';
import {
  EditButton, GoToOldVersionButton,
  RefreshButton, SetPeriodButton, ShowDeletedToggler,
} from '../../../newLister/tableComponent/commandpanel/buttons';
import { SearchInput } from '../../../newLister/tableComponent/commandpanel/elements';
import { StyledButtonToolbar } from '../../../newLister/tableComponent/commandpanel/styles';

const LogikaCommandPanel = forwardRef((_, ref) => {
  const {
    items, actions, localFilter, permissions, showDeleted, searchString, period, selectedRows,
  } = useContext(ListerContext);
  const unsignedPeoples = useMemo(
    () => Object.values(items.reduce((R, row) => ({
      ...R, ...row[baseMD.columns.unsigned.name].reduce((R2, p) => ({ ...R2, [p.id]: p }), R),
    }), {})),
    [items],
  );
  const activeUnsignedPeople = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => localFilter.unsigned && localFilter.unsigned.length
      ? localFilter.unsigned[0].value
      : null,
    [localFilter.unsigned],
  );

  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <StyledButtonToolbar className="me-auto">
        <ButtonGroup>
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit && selectedRows.length > 0)}
          />
          <RefreshButton
            onClick={actions.onRefresh}
          />
        </ButtonGroup>
        <ButtonGroup>
          <SetPeriodButton value={period} onChange={actions.onSetPeriod} />
        </ButtonGroup>
        <ButtonGroup>
          <DropdownButton
            title={activeUnsignedPeople ? `Не підписані ${activeUnsignedPeople.repr}` : 'Не підписані'}
            disabled={unsignedPeoples.length === 0}
            variant="info"
          >
            <Dropdown.Item
              onClick={(e) => actions.onSetFilter(e, { unsigned: [] })}
            >
              Всі
            </Dropdown.Item>
            <Dropdown.Divider />

            {unsignedPeoples.map((p) => (
              <Dropdown.Item
                key={p.id}
                active={activeUnsignedPeople && p.id === activeUnsignedPeople.id}
                onClick={(e) => actions.onSetFilter(e, {
                  unsigned: [
                    {
                      fieldName: 'Подписант',
                      value: p,
                    },
                    {
                      fieldName: 'ПодписаноОтбор',
                      value: false,

                    },
                  ],
                })}
              >
                {p.repr}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </ButtonGroup>
      </StyledButtonToolbar>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>

      <ButtonGroup className="float-right">
        <GoToOldVersionButton
          onClick={actions.onGoToOldVersion}
        />
      </ButtonGroup>
    </Navbar>
  );
});

export default LogikaCommandPanel;
