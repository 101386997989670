import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  byCSU: {
    label: 'За розпорядниками нижчого рівня',
    name: 'флПоНижнимРасп',
  },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  elementKFK: { label: 'Бюджетна програма', name: 'ДетализацияКФК' },
  elementKEKV: { label: 'Елемент КЕКВ', name: 'ДетализацияКЕКВ' },
  fond: { label: 'Фонд', name: 'Фонд' },
  docNotes: {
    label: 'Шаблон примітки',
    name: 'ПримечанияДок',
  },
  typeDoc: {
    label: 'Вид документу',
    name: 'Документ',
  },
};

const definition = {
  label: 'Аналіз документів на фінансування',
  name: 'DocFinAnalysis',
  columns,
  frontend: 'rep/DocFinAnalysis',
  backendName: 'АнализДокументовНаФинансирование',
  resultColumns: baseResultColumns,
};

export default definition;
