import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionContext, Button, useAccordionButton,
} from 'react-bootstrap';

function ContextAwareToggle({
  children, eventKey, variant, el, addStyle,
}) {
  const activeEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionButton(eventKey);

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <Accordion
      as={Button}
      style={{
        backgroundColor: isCurrentEventKey ? 'lavender' : 'transparent',
        textAlign: 'left',
        fontSize: 12,
        ...addStyle,
      }}
      variant={variant}
      eventKey={eventKey}
      onClick={async () => {
        await toggleOnClick(eventKey);
        el.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }}
    >
      {children}
    </Accordion>
  );
}

ContextAwareToggle.propTypes = {
  children: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  variant: PropTypes.string,
  el: PropTypes.element.isRequired,
  addStyle: PropTypes.shape(),
};

ContextAwareToggle.defaultProps = {
  children: 'Закрити',
  variant: 'link',
  addStyle: {},
};

export default ContextAwareToggle;
