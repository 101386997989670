const frontendURL = '/doc/expensesprojectchanges/';
const backendURL = '/api/planproject/expensesprojectchangesheader/';
// const printURL = '';
const name = 'Зміни до проекту видатків';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
