const frontendURL = '/logica/documentsinlogica/';
const backendURL = '/api/logica/documentsinlogica/';
const name = 'Перегляд інформації у системі Логіка';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};

export default instance;
