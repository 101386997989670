import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  document: { label: 'Документ', name: 'ДокументСсылка' },
  selDate: { label: 'Дата уточ.плану', name: 'выбДата' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  FI2: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрганПользователь' },
  csu: { label: 'Виконавець', name: 'выбРаспорядитель' },
  year: { label: 'Рік', name: 'тГод' },
  programm: { label: 'Бюджетна програма', name: 'выбПрограмма' },
  contr: { label: 'Виконавець', name: 'выбИсполнитель' },
  textYear: { label: 'ОбоснованиеНаГод', name: 'ОбоснованиеНаГод' },
  part4: { label: 'Розділ 4', name: 'флРаздел4' },
  part5: { label: 'Розділ 5', name: 'флРаздел5' },
  part6: { label: 'Розділ 6', name: 'флРаздел6' },
  part7: { label: 'Розділ 7', name: 'флРаздел7' },
  part8: { label: 'Розділ 8', name: 'флРаздел8' },
  part9: { label: 'Розділ 9', name: 'флРаздел9' },
  part10: { label: 'Розділ 10', name: 'флРаздел10' },
  part11: { label: 'Розділ 11', name: 'флРаздел11' },
  part12: { label: 'Розділ 12', name: 'флРаздел12' },
  svod: { label: 'флСводный', name: 'флСводный' },
  signVar: { label: 'Варіант підпису', name: 'Подпись' },
  printTarget: { label: 'Друкувати мету та назву за рішенням сесії', name: 'флПечатьРеквизитовПрогИзГлРасп' },
  KFK: { label: 'Наказ', name: 'КФК' },
  classKBP: { label: 'КБП', name: 'КлассификаторКБП' },
  elemKEKVForUsePlan: { label: 'за елементами КЕКВ (для "Плану використання")', name: 'флПечатьПоЭлКЕКВ' },
  elemKEKV: { label: 'за елементами КЕКВ', name: 'флПечатьПоЭлКЕКВ1' },
  printBP: { label: 'Друк КПКВ за розп. вищого рівня', name: 'флПечатьКПКВИзВУ' },
  printNameKBP: { label: 'Назва КПКВК за назвою БП', name: 'флКБПИзКФК' },
  printSF: { label: 'враховувати спец.фонд', name: 'флВключатьВ_Форму3СпецФонд' },
  printSFinF3: { label: 'Враховувати СФ у Формі 3', name: 'флУчитыватьСФДляФ3' },
  datePrint: { label: 'Дата на яку друкується документ', name: 'ДатаПечати' },
  notSvodKEKV: { label: 'Не згортати КЕКВ Ф3', name: 'НеСворачиватьФ3КЕКВ' },
  factThisYear: { label: 'З/П поточного року', name: 'флФактЗпТекГода' },
  in1000: { label: 'тис. грн', name: 'флТысГрн' },
  notPrintPart5: { label: 'Не друкувати розділ 5 форми 3', name: 'ФлНеПечататьРазд5_Ф3' },
  svodRasp: { label: 'Зведено за розпорядником', name: 'СводПоРасп' },
  moveColumns: { label: 'Дозволити змінити розмір колонок та рядків', name: 'флДвигатьКолонки' },
  notPrintNumDocs: { label: 'Не друкувати номерів документів', name: 'флНеПечататьДок' },
  notPrintTime: { label: 'Не друкувати дату та час', name: 'флНеПечатьВремя' },
  notPrintNumPage: { label: 'Не друкувати номера сторінок', name: 'флНеПечатьНомСтр' },
  svodZP: { label: 'Друкувати зведення по З/П', name: 'флСводПоЗП' },
  csuLowlevel: { label: 'За розпорядниками нижчого рівня', name: 'флПоНижнимРасп' },
  nForm: { label: 'Яку форму друкувати ', name: 'НомерФормы' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  uncheck: { label: 'Не прошли проверку', name: 'НеПрошлиПроверку' },
  flName: { label: 'Прізвище та ім\'я', name: 'флФИ' },
  groupKEKV: { label: 'за групами КЕКВ', name: 'флПоГруппеКЕКВ' },
  indicatorsForm1: { label: 'Не друкувати показники в першій формі', name: 'НеПечататьПоказателиФорма1' },
  typeIndicatorsForm2: { label: 'Не друкувати "Тип"  показника в першій формі', name: 'НеПечататьТипПоказателиФорма2' },
  noTask: { label: 'Не друкувати завдання (табл. 8)', name: 'флНЕПечататьЗадания' },
  kbp7: { label: 'КБП7', name: 'КлассификаторКБП7' },
  apprByFO: { label: 'УтвФО', name: 'УтвФО' },
  forbidUnexecutingBRPrinting: { label: 'Заборонити друк непроведеного бюджетного запиту', name: 'флЗапретитьПечатьНепроведенногоБЗ' },
};

const tables = {
  programs: {
    name: 'Программы',
    label: 'Програми/підпрограми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KTKVK: { label: 'КТКВК', name: 'КТКВК' },
      subProgram: { label: 'Програми/підпрограми', name: 'Программа' },
      elKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KPKVK: { label: 'КПКВК', name: 'КПКВК' },
      printSubProg: { label: 'Найменування', name: 'ПечПодПрог' },
      fullName: { label: 'Найменування', name: 'ПолнНаименование' },
    },
  },
  tpDoc: {
    name: 'ТчДок',
    label: 'Документи',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      doc: { label: 'Док', name: 'Док' },
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      elKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KFK: { label: 'КПКВ', name: 'КодКБП7' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'budgetRequestPrint',
  backendName: 'ПечатьБюджетногоЗапроса',
  frontend: 'dp/budgetRequestPrint',
  label: 'Звіт Бюджетний запит',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
