import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  date: { label: 'На дату', name: 'НаДату' },
};

const definition = {
  label: 'Довідка про виконання доходів (у розрізі бюджетів)',
  name: 'RegionRevenueStatement',
  columns,
  frontend: 'rep/RegionRevenueStatement',
  backendName: 'СправкаОВыполненииДоходовОбласногоБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
