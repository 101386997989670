import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import TransfertsRowSet from './tp/transferts';
import SpecialFondByKDBRowSet from './tp/sfByKDB';
import SpecialFondByKFBRowSet from './tp/sfByKFB';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.forecastPrepAdd1.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  // if (!data || !fields) return null;
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const useKDBProps = editorHooks.useCheckboxInputProps('useKDB', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount1ZF = editorHooks.useNumberInputProps('amount_1_zf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount2ZF = editorHooks.useNumberInputProps('amount_2_zf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount3ZF = editorHooks.useNumberInputProps('amount_3_zf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount1SF = editorHooks.useNumberInputProps('amount_1_sf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount2SF = editorHooks.useNumberInputProps('amount_2_sf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount3SF = editorHooks.useNumberInputProps('amount_3_sf', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount1Credit = editorHooks.useNumberInputProps('amount_1_credit', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount2Credit = editorHooks.useNumberInputProps('amount_2_credit', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const amount3Credit = editorHooks.useNumberInputProps('amount_3_credit', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  const disposerFilter = useMemo(
    () => ({ parent: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          backendURL={meta.doc.allocationplan.backendURL}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...disposerProps}
                  filter={disposerFilter}
                  noHierarchy
                />
              </Col>
              <Col sm={12}>
                <EditorControls.CheckboxInput {...useKDBProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="general">
          <Tab eventKey="general" title="Загальні суми">
            <TabContainer className="p-0">
              <Row>
                <Col>
                  <EditorControls.NumberInput {...amount1ZF} label={`План на ${data.year + 1}р. З.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount2ZF} label={`План на ${data.year + 2}р. З.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount3ZF} label={`План на ${data.year + 3}р. З.Ф.`} />
                </Col>
              </Row>
            </TabContainer>
            <TabContainer className="p-0">
              <Row>
                <Col>
                  <EditorControls.NumberInput {...amount1SF} label={`План на ${data.year + 1}р. C.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount2SF} label={`План на ${data.year + 2}р. C.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount3SF} label={`План на ${data.year + 3}р. C.Ф.`} />
                </Col>
              </Row>
            </TabContainer>
            <TabContainer className="p-0">
              <Row>
                <Col>
                  <EditorControls.NumberInput {...amount1Credit} label={`Надання кредитів на ${data.year + 1}р. З.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount2Credit} label={`Надання кредитів на ${data.year + 2}р. З.Ф.`} />
                </Col>
                <Col>
                  <EditorControls.NumberInput {...amount3Credit} label={`Надання кредитів на ${data.year + 3}р. З.Ф.`} />
                </Col>
              </Row>
            </TabContainer>
          </Tab>
          {fields && fields.forecastprepareadd1transferts_set && (
            <Tab eventKey="transferts" title={fields.forecastprepareadd1transferts_set.label}>
              <TabContainer className="p-0">
                <TransfertsRowSet
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd1specialfondbykdb_set && !headerReadOnlyFields.includes('forecastprepareadd1specialfondbykdb_set') && (
            <Tab eventKey="sf_by_kdb" title={fields.forecastprepareadd1specialfondbykdb_set.label}>
              <TabContainer className="p-0">
                <SpecialFondByKDBRowSet
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.forecastprepareadd1specialfondbykfb_set && !headerReadOnlyFields.includes('forecastprepareadd1specialfondbykfb_set') && (
            <Tab eventKey="sf_by_kfb" title={fields.forecastprepareadd1specialfondbykfb_set.label}>
              <TabContainer className="p-0">
                <SpecialFondByKFBRowSet
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
