import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import BasicEditor, { DCControls, mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/usersMonitor';
import DateRangeField from '../../../components/fields/DateRangeField';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ContainerDiv,
  ResultSegment, CommandPanelButtonText, DivWithPropsLabel, StyledPane,
} from '../../../components/Form/styledForm';
import ReportContainer from '../reportContainer';
import Tabs from '../../../components/tabs/Tabs';

const SettingsButton = (
  <Tabs>
    <DivWithPropsLabel label="Группування">
      <StyledPane>
        <DCControls.Structure />
      </StyledPane>
    </DivWithPropsLabel>
    <DivWithPropsLabel label="Відбір">
      <StyledPane>
        <DCControls.Filter />
      </StyledPane>
    </DivWithPropsLabel>
    <DivWithPropsLabel label="Сортування">
      <StyledPane>
        <DCControls.Order />
      </StyledPane>
    </DivWithPropsLabel>
    <DivWithPropsLabel label="Обрані поля">
      <StyledPane>
        <DCControls.Selection />
      </StyledPane>
    </DivWithPropsLabel>
  </Tabs>

);

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      {' '}
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <CommandPanelButtonText
          onClick={() => dispatch(processServerReq('REFRESH_JOURNAL'))}
        >
          Оновити дані журналу
        </CommandPanelButtonText>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}
ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

ReportEditor.displayName = `Report${md.name}Editor`;

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
