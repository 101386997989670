import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '../styledInputField/inputField';
import { font } from '../../constants/globalStyle';

export const StyleCheckbox = styled.input`
  vertical-align: top;  
  margin: 0 3px 0 0;  
  width: 17px;  
  height: 17px; 
  +label{
    cursor: pointer;
  }
  :not(checked){  
    position: absolute;  
    opacity: 0;  
  }
  :not(checked) + label{ 
    position: relative;
    padding: 2px 0 0 50px;
    margin: 10px 0 10px 0;
  }
  :not(checked)+label:before{ 
    content: '';  
    position: absolute;  
    top: -2px;  
    left: 0;  
    width: 43px;  
    height: 24px;  
    border-radius: 13px;  
    background: #CDD1DA;  
    box-shadow: inset 0 2px 3px rgba(0,0,0,.2);  
  }
  :not(checked) + label:after{ 
    content: '';  
    position: absolute;  
    top: 0px;  
    left: 2px;  
    width: 20px;  
    height: 20px;  
    border-radius: 10px;  
    background: #FFF;  
    box-shadow: 0 2px 5px rgba(0,0,0,.3);  
    transition: all .1s;
  }
  :checked + label:before{ 
    background: #548ccd;  
  } 
  :checked + label:after { 
    left: 21px;  
  }  
  :focus + label:before { 
    box-shadow: 0 0 0 3px rgba(255,255,0,.5);  
  } 
`;

export const LabelCheck = styled.label`
    line-height: 20px;
    ${font};
`;

export default class extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.bool.isRequired,
    children: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.node,
  };

  static defaultProps = {
    id: null,
    disabled: null,
    children: null,
    label: null,
    onChange: () => {
      // eslint-disable-next-line
      throw 'Not implemented action';
    },
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { props: { onChange, value, disabled } } = this;
    e.stopPropagation();
    // console.log('field status: ', value, disabled);
    return !disabled && onChange(e, !value);
  }

  render() {
    const {
      props: {
        id, value, children, label, disabled,
      },
    } = this;
    return (
      <InputField style={{ marginBottom: '10px' }} disabled={disabled} onClick={this.handleClick}>
        <StyleCheckbox
          type="checkbox"
          id={id}
          checked={value}
          onChange={(e) => { e.preventDefault(); }}
        />
        <LabelCheck>{label || children}</LabelCheck>
      </InputField>
    );
  }
}
