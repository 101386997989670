/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useMemo, useCallback, memo,
} from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';

const StyledSegment = styled.div`
  overflow: auto;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  flex-flow: column;
  display: flex;
  background: white;
  padding: 0 10px;
`;

const getTPContainer = (WrappedTable, WrappedCP = null) => {
  const TPContainer = (props) => {
    const [fullScreen, setFullScreen] = useState(false);
    const handlerResize = useCallback(
      () => setFullScreen((fs) => !fs),
      [],
    );
    const content = useMemo(
      () => (
        <>
          {WrappedCP && (
            <WrappedCP
              fullscreen={fullScreen}
              onResize={handlerResize}
              {...props}
            />
          )}
          <WrappedTable {...props} />
        </>
      ),
      [fullScreen, handlerResize, props],
    );
    return fullScreen ? createPortal(

      <StyledSegment>
        <div>
          {content}
        </div>
      </StyledSegment>,
      document.body,
    )

      : content;
  };

  return memo(TPContainer);
};

export default getTPContainer;
