import React, { memo, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import useEU from '../../ciatEU/hook';
import Signer from '../authentication/signer';

function NewSignEditor({
  data, onAddSign, onClose, index, multiple,
}) {
  const {
    readkeyFile, initialized, cert, signData, err, loading, setErr, setLoading, onClearKey,
    libraryType, setLibraryType, keyMedias, readKeyMedia, selectedKM, setSelectedKM,
    certificates, setCertificates, needCertificate, setIssuer, issuer, issuers, reloadKeyMedia,
  } = useEU();
  const onSign = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      if (multiple) {
        const multipleSigning = async () => Promise.all(
          Object.keys(data).map(async (docId) => {
            const sign = await signData(data[docId], false);
            return { docId, sign };
          }),
        );
        multipleSigning()
          .then((resultArray) => {
            onAddSign(resultArray);
          })
          .then(() => onClose())
          .catch((e) => setErr(e))
          .finally(() => setLoading(false));
      } else {
        signData(data, false).then(
          (sign) => {
            if (process.env.NODE_ENV === 'development') {
              const signedFile = atob(sign);
              const byteNumbers = new Array(signedFile.length);
              for (let i = 0; i < signedFile.length; i += 1) {
                byteNumbers[i] = signedFile.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: 'application/x-pkcs7-signature' });
              const URL = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = URL;
              a.download = 'file.json.p7s';
              document.body.append(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(URL);
            }
            return onAddSign(index, sign);
          },
        )
          .then(() => onClose())
          .catch((e) => setErr(e))
          .finally(() => setLoading(false));
      }
    },
    [data, index, multiple, onAddSign, onClose, setErr, setLoading, signData],
  );

  return (
    <Container>
      <h3 className="text-primary">
        <FontAwesomeIcon icon={faLock} className="me-2" />
        Підпис даних
      </h3>
      <Signer
        submitTitle="Підписати"
        loading={loading}
        err={err}
        initialized={initialized}
        onReadKeyFile={readkeyFile}
        onReadKeyMedia={readKeyMedia}
        cert={cert}
        onSubmit={onSign}
        onReset={onClearKey}
        libraryType={libraryType}
        onSetLibraryType={setLibraryType}
        keyMedias={keyMedias}
        selectedKM={selectedKM}
        setSelectedKM={setSelectedKM}
        certificates={certificates}
        needCertificate={needCertificate}
        setCertificates={setCertificates}
        setIssuer={setIssuer}
        issuers={issuers}
        issuer={issuer}
        multiple={multiple}
        reloadKeyMedia={reloadKeyMedia}
      />
    </Container>
  );
}

NewSignEditor.propTypes = {
  data: PropTypes.string.isRequired,
  onAddSign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  multiple: PropTypes.bool.isRequired,
};

export default memo(NewSignEditor);
