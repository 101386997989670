import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'investExtraMoney';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, budget, kbp7, program, docByKBP7, FI,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const OBFilter = useMemo(
    () => [
      {
        fieldName: 'Владелец',
        value: budget,
      },
      {
        fieldName: docByKBP7 ? 'КБП7' : 'Программа',
        value: docByKBP7 ? kbp7 : program,
      },
    ],
    [budget, docByKBP7, kbp7, program],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="constructionObject" highlighted={highlights.includes('constructionObject')}>
              <ItemPicker
                value={row[tableMD.columns.constructionObject.name]}
                modelType="cat"
                modelName="objectsBuilding"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.constructionObject.name]: v },
                )}
                readOnly={readOnly}
                filter={OBFilter}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKEKV.name]}
                modelType="cat"
                modelName="elementKEKV"
                filter={FIOwnerFilter}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.elementKEKV.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="KEKV" highlighted={highlights.includes('KEKV')}>
              <ItemPicker
                value={row[tableMD.columns.KEKV.name]}
                modelType="cat"
                modelName="kekvClassifier"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.KEKV.name]: v },
                )}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableRowCell column="sfo" highlighted={highlights.includes('sfo')}>
          <NumberInput
            value={row[tableMD.columns.sfo.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.sfo.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
          <NumberInput
            value={row[tableMD.columns.sf1.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.sf1.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
          <NumberInput
            value={row[tableMD.columns.sf2.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.sf2.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="act" highlighted={highlights.includes('act')}>
          <TextInput
            value={row[tableMD.columns.act.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.act.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="explanation" highlighted={highlights.includes('explanation')}>
          <TextInput
            value={row[tableMD.columns.explanation.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.explanation.name]: value,
            })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  budget: PropTypes.shape({
  }).isRequired,
  kbp7: PropTypes.shape({
  }).isRequired,
  program: PropTypes.shape({
  }).isRequired,
  docByKBP7: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
