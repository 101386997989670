import base from './base';

/**
 * @const
 * @type {object}
 */
const mon = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v, i) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `${mon[i]}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
// export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
//   (r, v) => ({
//     ...r,
//     [`SumBR${v + 1}`]: {
//       name: `СуммаБР${v + 1}`,
//       label: 'в т.ч. БР',
//     },
//   }),
//   {},
// );

/**
 * @const
 */
const columns = {
  ...base.columns,
  year: { label: 'Рiк', name: 'Год', visible: false },
  dataStart: { label: 'с', name: 'ДатаНач', visible: false },
  dataEnd: { label: 'по', name: 'ДатаКон', visible: false },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: false },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: false },
  elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'СписокДокументовОст',
    label: 'Остатки',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      selection: { label: 'Вiдбiр', name: 'Отобрать' },
      document: { label: 'Документ', name: 'Документ' },
      period: { label: 'Перiод', name: 'Период' },
      budget: { label: 'Бюджет', name: 'Бюджет' },
      FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      fondObject: { label: 'Фонд', name: 'ЭлементФонд' },
      KPKV: { label: 'Код КПКВ', name: 'КодКПКВ' },
      KEKV: { label: 'Код КЕКВ', name: 'КЕКВ' },
      KFK: { label: 'Код КФК', name: 'КФК' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      year: { label: 'Рiк', name: 'НомерГода' },
      note: { label: 'Примiтка', name: 'Примечание' },
      currentAccountFI: { label: 'р/р фіноргану', name: 'РСФинансовыйОрган' },
      currentAccountCSU: { label: 'р/р розпорядника', name: 'РСРаспорядителя' },
      signMainMovement: { label: 'Ознака основного руху', name: 'Основной' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Сторнуюча заявка',
  name: 'financingApplicationReversal',
  backendName: 'ФинансированиеЗаявкаСторно',
  columns,
  frontend: 'doc/financingApplicationReversal',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
