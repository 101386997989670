import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.extraMoney;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'elementKEKV'}
          highlighted={highlights.includes('elementKEKV')}
          onToggleHighlght={() => onToggleHighlght('elementKEKV')}
        >
          {meta.columns.elementKEKV.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <Row>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum0'}
              highlighted={highlights.includes('sum0')}
              onToggleHighlght={() => onToggleHighlght('sum0')}
            >
              {meta.columns.sum0.label}
              <Badge className="mx-1" variant="success">{year}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum1'}
              highlighted={highlights.includes('sum1')}
              onToggleHighlght={() => onToggleHighlght('sum1')}
            >
              {meta.columns.sum1.label}
              <Badge className="mx-1" variant="success">{year + 1}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              {meta.columns.sum2.label}
              <Badge className="mx-1" variant="success">{year + 2}</Badge>
              p.
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason1'}
              highlighted={highlights.includes('reason1')}
              onToggleHighlght={() => onToggleHighlght('reason1')}
            >
              {meta.columns.reason1.label}
              <span>на</span>
              <Badge className="mx-1" variant="success">{year}</Badge>
              pік
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason2'}
              highlighted={highlights.includes('reason2')}
              onToggleHighlght={() => onToggleHighlght('reason2')}
            >
              {meta.columns.reason2.label}
              <span>на</span>
              <Badge className="mx-1" variant="success">{year + 1}</Badge>
              -
              <Badge className="mx-1" variant="success">{year + 2}</Badge>
              pоки
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
