import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  fromJS, List, Map, OrderedMap,
} from 'immutable';
import { Message, ErrorMessage } from '../../components/Form/styledForm';

import meta from '../../constants/meta';
import actions from '../../actions/lister';

import CheckboxShowDeleted from '../../components/button/checkboxShowDeleted';
import { SearchInput } from '../../components/styledInputs';
import Loader from '../../components/styledWrappedLoader';
import { debounce } from '../../utils';
import { saveModes } from '../../constants/meta/common';
import { RELOAD_TIMEOUT } from '../../constants/misc';
import { withRouter } from '../../utils/withRouter';

const ContainerButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    margin-bottom: 10px;
`;

const MainButton = styled.div`
    display: flex;
    align-items: center;
`;

const DELTA_FOR_LASY_LOADING = 200;

export class ListerSelector extends Component {
  static propTypes = {
    modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
    modelName: PropTypes.oneOf([
      ...new Set(Object.keys(meta).reduce(
        (r, typeName) => [...r, ...Object.keys(meta[typeName])],
        [],
      )),
    ]).isRequired,
    router: PropTypes.shape({
      location: PropTypes.shape({}),
      navigate: PropTypes.func,
      params: PropTypes.shape({}),
    }),
    /* From redux-store */
    /* eslint-disable react/require-default-props */
    dispatch: PropTypes.func,
    items: PropTypes.instanceOf(OrderedMap),
    itemsCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isProcessing: PropTypes.bool,
    hasError: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    errorMsg: PropTypes.object,
    /* eslint-enable react/forbid-prop-types */
    orderBy: PropTypes.instanceOf(List),
    filterBy: PropTypes.instanceOf(Map),
    paramsBy: PropTypes.instanceOf(Map),
    /* eslint-enable react/require-default-props */
    filter: PropTypes.arrayOf(PropTypes.shape()), // Параметры выбора - начальное значение
    params: PropTypes.arrayOf(PropTypes.shape()),
    showDeleted: PropTypes.bool,
    value: PropTypes.instanceOf(Map),
    CommandPanel: PropTypes.element.isRequired,
    TableComponent: PropTypes.element.isRequired,
    onItemClick: PropTypes.func,
    onItemDoubleClick: PropTypes.func,
    headerComponent: PropTypes.node,
    noHierarchy: PropTypes.bool,
    showCommandPanel: PropTypes.bool,
    visibleColumns: PropTypes.instanceOf(List).isRequired,
    columnSizes: PropTypes.instanceOf(Map).isRequired,
    storeNode: PropTypes.oneOf(['lister', 'selector']),
    readyToLoad: PropTypes.bool,
    timestamp: PropTypes.number,
    // ЗАгруать данные при монтировании. Если В конечном компоненте есть фильтр,
    // который устанавливается при старте - должен быть false
    loadOnMount: PropTypes.bool,
  };

  static defaultProps = {
    filter: [],
    params: [],
    showDeleted: false,
    onItemClick: null,
    onItemDoubleClick: null,
    value: null,
    headerComponent: null,
    noHierarchy: false,
    itemsCount: null,
    showCommandPanel: true,
    storeNode: 'lister',
    readyToLoad: false,
    timestamp: null,
    loadOnMount: true,
    isLoading: false,
    isProcessing: false,
  };

  constructor(props) {
    super(props);

    this.handleSearch = debounce(this.handleSearch.bind(this), 500);
    this.actions = actions[props.storeNode][props.modelType][props.modelName];
    this.initialized = false;
    this.tableRef = React.createRef();
    this.currentRowRef = React.createRef();
    this.state = {
      inputValue: '',
    };
    this.meta = meta[props.modelType][props.modelName];
  }

  componentDidMount() {
    const {
      dispatch, value, noHierarchy, filter, params, loadOnMount,
    } = this.props;

    dispatch(this.actions.init(value, this.meta.useListLazyLoad, noHierarchy));
    if (filter.length) {
      dispatch(this.actions.setFilter(fromJS(filter)));
    } else if (loadOnMount) dispatch(this.actions.load());

    if (params.length) {
      dispatch(this.actions.setParams(fromJS(params)));
    } else if (loadOnMount) dispatch(this.actions.load());
    this.reload = () => dispatch(this.actions.load());

    this.rerender = setInterval(this.reload, RELOAD_TIMEOUT);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      value, items, dispatch, modelType, noHierarchy, timestamp, filter, params,
    } = this.props;

    if (timestamp !== prevProps.timestamp) {
      dispatch(this.actions.load(true));
    }

    if (prevProps.noHierarchy !== noHierarchy) {
      // console.log('reinit')
      dispatch(this.actions.init(value, this.meta.useListLazyLoad, noHierarchy));
    }

    const scroller = this.tableRef.current;
    if (!this.initialized) {
      const currentId = !!(value && value.get('id', ''));
      this.initialized = true;
      if (currentId) {
        if (this.currentRowRef.current) {
          this.currentRowRef.current.scrollIntoView(false);
        }
      } else if (this.meta.useListLazyLoad && items.size && modelType === 'doc') {
        if (scroller) {
          scroller.scrollTo(0, scroller.scrollHeight);
        }
      }
    } else if (snapshot && scroller) {
      scroller.scrollTo(0, scroller.scrollHeight - snapshot);
    } else if (!fromJS(filter).equals(fromJS(prevProps.filter))) {
      dispatch(this.actions.setFilter(fromJS(filter)));
    } else if (!fromJS(params).equals(fromJS(prevProps.params))) {
      dispatch(this.actions.setParam(fromJS(params)));
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    const scroller = this.tableRef.current;
    if (scroller && prevProps.items.size !== this.props.items.size && this.props.modelType === 'doc') {
      return (scroller.scrollHeight - scroller.scrollTop);
    }
    if (scroller && prevProps.items.size !== this.props.items.size && this.props.modelType === 'cat') {
      return (scroller.scrollTop);
    }
    return null;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(this.actions.saveSettings());
    clearInterval(this.rerender);
  }

  resetInterval = () => {
    clearInterval(this.rerender);
    this.rerender = setInterval(this.reload, RELOAD_TIMEOUT);
  };

  updateInputValue = (e) => {
    const searchValue = e.target.value;
    this.setState({
      inputValue: searchValue,
    });
    this.handleSearch(e, searchValue);
  };

  handleSearch = (e, value) => {
    const { dispatch } = this.props;
    dispatch(this.actions.search(value));
  };

  contextMenuLister = (e, key) => {
    const {
      dispatch, history,
    } = this.props;
    dispatch(this.actions.select(key, !e.ctrlKey));
    switch (e) {
      case 'posting': dispatch(this.actions.postItem(saveModes.Posting, `${key}/`));
        break;
      case 'undoPosting': dispatch(this.actions.postItem(saveModes.UndoPosting, `${key}/`));
        break;
      case 'remove': dispatch(this.actions.remove());
        break;
      case 'copyItem': dispatch(this.actions.copyItem(history));
        break;
      case 'editItem': dispatch(this.actions.editItem(history));
        break;
      default: break;
    }
  };

  handleItemClick = (e, key) => {
    const { onItemClick, items, dispatch } = this.props;
    if (!onItemClick || onItemClick(e, key, items.get(key))) {
      e.stopPropagation();
      dispatch(this.actions.select(key, !e.ctrlKey));
      // this.handleToggleItem(e, key);
    }
  };

  handleItemDolubleClick = (e, key) => {
    const { onItemDoubleClick, items, router } = this.props;
    if (!onItemDoubleClick || onItemDoubleClick(e, key, items.get(key))) {
      e.stopPropagation();
      console.log(this.meta);
      router.navigate(`${this.meta.frontend}/${key}/`);
    }
  };

  handleToggleItem = (e, key) => {
    const { dispatch } = this.props;
    e.stopPropagation();
    dispatch(this.actions.toggle(key));
  };

  handlerOrderChange = (e, column) => {
    this.props.dispatch(this.actions.setOrder(column));
  };

  handleScrollTable = (e) => {
    const { dispatch, isLoading, modelType } = this.props;
    const el = e.target;
    if (el.scrollTop <= DELTA_FOR_LASY_LOADING && !isLoading && modelType === 'doc') {
      dispatch(this.actions.decPage());
    }
    if (el.scrollHeight - el.clientHeight - el.scrollTop <= DELTA_FOR_LASY_LOADING && !isLoading && modelType !== 'doc') {
      dispatch(this.actions.decPage());
    }
  };

  NavigatgionArrow = (e) => {
    if ((e.keyCode === 38) || (e.keyCode === 40) || (e.keyCode === 13)) {
      const {
        onItemClick, items, dispatch,
      } = this.props;
      const itemsKey = { prewKey: '', currentKey: '', nextKey: '' };

      e.stopPropagation();

      items.mapKeys((key, value) => {
        itemsKey.currentKey = value.get('ACTIVE', false) ? key : itemsKey.currentKey;
        itemsKey.prewKey = (itemsKey.currentKey === '') ? key : itemsKey.prewKey;
        if ((itemsKey.nextKey === '') && (itemsKey.currentKey !== '') && (!value.get('ACTIVE', false))) {
          itemsKey.nextKey = key;
        }
      });

      switch (e.keyCode) {
        case 38: {
          // top
          if (itemsKey.prewKey !== '') {
            if (!onItemClick || onItemClick(e, itemsKey.prewKey, items.get(itemsKey.prewKey))) {
              dispatch(this.actions.select(itemsKey.prewKey, true));
            }
          }
          break;
        }
        case 40: {
          // down
          if (itemsKey.nextKey !== '') {
            if (!onItemClick || onItemClick(e, itemsKey.nextKey, items.get(itemsKey.nextKey))) {
              dispatch(this.actions.select(itemsKey.nextKey, true));
            }
          }
          break;
        }
        case 13: {
        // Enter
          if (itemsKey.currentKey !== '') {
            this.handleItemDolubleClick(e, itemsKey.currentKey);
          }
          break;
        }
        default: break;
      }
    }
  };

  handlerResizeColumn = (e, columnName, width) => {
    const { dispatch } = this.props;
    dispatch(this.actions.setColumnSize(columnName, `${width}px`));
  };

  handlerResetSizeColumn = (e, columnName) => {
    const { dispatch } = this.props;
    dispatch(this.actions.resetColumnSize(columnName));
  };

  render() {
    const {
      dispatch,
      isLoading,
      isProcessing,
      items,
      showDeleted,
      CommandPanel,
      TableComponent,
      orderBy,
      headerComponent,
      modelType,
      modelName,
      value,
      showCommandPanel,
      visibleColumns,
      columnSizes,
      hasError,
      errorMsg,
    } = this.props;

    const CP = CommandPanel && React.cloneElement(CommandPanel, { modelType, modelName });

    const currentId = value && value.get ? value.get('id', '') : null;

    const Table = React.cloneElement(TableComponent, {
      items,
      onClick: this.handleItemClick,
      onDoubleClick: this.handleItemDolubleClick,
      onToggle: this.handleToggleItem,
      // Пользователсякая сортировка всегда последняя
      orderBy: orderBy.size !== 0 ? orderBy.get(orderBy.size - 1) : {},
      onOrderChange: this.handlerOrderChange,
      onScroll: this.handleScrollTable,
      iRef: this.tableRef,
      currentRowRef: this.currentRowRef,
      currentId,
      visibleColumns,
      columnSizes,
      onResizeColumn: this.handlerResizeColumn,
      onResetColumnSize: this.handlerResetSizeColumn,
      onActionClick: this.contextMenuLister,
      onKeyDown: this.resetInterval(),
    });

    const HC = headerComponent && React.cloneElement(headerComponent, {
      dispatch, actions: this.actions,
    });
    return (
      <div>
        <ContainerButton>
          <MainButton>
            {CP}
          </MainButton>
          {showCommandPanel && (
            <MainButton position="right">
              <CheckboxShowDeleted
                uncheckIcon="unhide"
                checkIcon="hide"
                text={showDeleted ? 'Сховати видалені' : 'Показати видалені'}
                checked={showDeleted}
                onClick={() => dispatch(this.actions.toggleShowDeleted())}
              />
              <SearchInput
                value={this.state.inputValue}
                onChange={this.updateInputValue}
              />
            </MainButton>
          )}
        </ContainerButton>
        <Loader isLoading={isLoading || isProcessing}>
          <div
            style={{ outline: 'none' }}
            id="ThisIsFocusDiv"
            tabIndex={0}
            onKeyDown={(e) => this.NavigatgionArrow(e)}
          >
            {HC}
            {hasError && (
              <ErrorMessage>
                {errorMsg}
              </ErrorMessage>
            )}
            {(items.size === 0) ? (
              <Message>
                {isLoading ? 'Зачекайте, будь ласка...' : 'Записи відсутні***'}
              </Message>
            ) : Table}
          </div>
        </Loader>
      </div>
    );
  }
}

const mapState = (store, ownProps) => {
  const { modelType, modelName } = ownProps;
  const STORE_PATH = `${ownProps.modelType}/${ownProps.modelName}/${ownProps.storeNode || 'lister'}`;
  // console.log(store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap()).filter(s => s.get('TOGGLED', false) || s.get('ACTIVE',false)).toJS())
  return {
    isLoading: store.getIn([STORE_PATH, 'isLoading'], false),
    isProcessing: store.getIn([STORE_PATH, 'isProcessing'], false),
    hasError: store.getIn([STORE_PATH, 'hasError'], false),
    errorMsg: store.getIn([STORE_PATH, 'errorMsg'], null),
    orderBy: store.getIn([STORE_PATH, 'order'], new List()),
    filterBy: store.setIn([STORE_PATH, 'filter'], new Map()),
    paramsBy: store.setIn([STORE_PATH, 'setParams'], new Map()),
    items: store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap()),
    itemsCount: (store.getIn([STORE_PATH, 'items']) || new Map()).size || 0,
    showDeleted: store.getIn([STORE_PATH, 'showDeleted'], false),
    visibleColumns: store.getIn([STORE_PATH, 'visibleColumns'], new List()),
    columnSizes: store.getIn([STORE_PATH, 'columnSizes'], new Map()),
    readyToLoad: store.getIn([STORE_PATH, 'readyToLoad'], false),
    timestamp: store.getIn(['windowsManager', 'listerTimeStamps', `/${meta[modelType][modelName].frontend}/`], 1),
    ...ownProps,
  };
};

const enhance = compose(withRouter, connect(mapState));

export default enhance(ListerSelector);
