import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase as icon } from '@fortawesome/free-solid-svg-icons';
import { CommandPanelButtonText } from '../../../../components/Form/styledForm';
import ExportPlansAndChangesToDBFEditor from '../../../dataProcessors/exportPlansAndChangesToDBF/editor';

function DBFButton({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );

  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={selectedRows.length === 0} size="sm">
        <FontAwesomeIcon icon={icon} className="me-2" />
        ДБФ
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        triggerButton={
          <CommandPanelButtonText label="ДБФ" disabled={!selectedRows} />
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Вивантаження в ДБФ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExportPlansAndChangesToDBFEditor
            mapInitialOptions={{
              Document: 'ДокументСсылка',
            }}
            mapStore={mapStore}
          />
        </Modal.Body>

      </Modal>
    </>

  );
}

DBFButton.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

DBFButton.defaultProps = {
  selectedRows: [],
};

export default DBFButton;
