import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: false },
  isApproved: {
    label: 'Отримано',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  onState: { label: 'Станом на', name: 'СтаномНа', visible: false },
  month2: { label: 'Місяць', name: 'Месяц2' },
  month3: { label: 'Місяць', name: 'Месяц3' },
  month1: { label: 'Місяць', name: 'Месяц1' },
  year2: { label: 'Рік', name: 'Год2' },
  year3: { label: 'Рік', name: 'Год3' },
  year1: { label: 'Рік', name: 'Год1' },
  method: { label: 'Метод розрахунку', name: 'Метод1Дод' },
  R01G3: { label: 'КЕКВ 2110, видатки заг. фонд, місяць 1', name: 'R01G3' },
  R01G4: { label: 'КЕКВ 2110, видатки заг. фонд, місяць 2', name: 'R01G4' },
  R01G5: { label: 'КЕКВ 2110, видатки заг. фонд, місяць 3', name: 'R01G5' },
  R01G6: { label: 'КЕКВ 2110, попередня оплата заг. фонд, місяць 1', name: 'R01G6' },
  R01G7: { label: 'КЕКВ 2110, попередня оплата заг. фонд, місяць 2', name: 'R01G7' },
  R01G8: { label: 'КЕКВ 2110, попередня оплата заг. фонд, місяць 3', name: 'R01G8' },
  R02G3: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, видатки заг. фонд, місяць 1', name: 'R02G3' },
  R02G4: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, видатки заг. фонд, місяць 2', name: 'R02G4' },
  R02G5: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, видатки заг. фонд, місяць 3', name: 'R02G5' },
  R02G6: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, попередня оплата заг. фонд, місяць 1', name: 'R02G6' },
  R02G7: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, попередня оплата заг. фонд, місяць 2', name: 'R02G7' },
  R02G8: { label: 'КЕКВ 2220, 2230, 2270, 2281, 2282, 2700, попередня оплата заг. фонд, місяць 3', name: 'R02G8' },
  R03G3: { label: 'КЕКВ 5000 (крім 2620, 3220), видатки, місяць 1', name: 'R03G3' },
  R03G4: { label: 'КЕКВ 5000 (крім 2620, 3220), видатки, місяць 2', name: 'R03G4' },
  R03G5: { label: 'КЕКВ 5000 (крім 2620, 3220), видатки, місяць 3', name: 'R03G5' },
  R03G6: { label: 'КЕКВ 5000 (крім 2620, 3220), попередня оплата, місяць 1', name: 'R03G6' },
  R03G7: { label: 'КЕКВ 5000 (крім 2620, 3220), попередня оплата, місяць 2', name: 'R03G7' },
  R03G8: { label: 'КЕКВ 5000 (крім 2620, 3220), попередня оплата, місяць 3', name: 'R03G8' },
  R04G3: { label: 'Видатки спец. фонду, місяць 1', name: 'R04G3' },
  R04G4: { label: 'Видатки спец. фонду, місяць 2', name: 'R04G4' },
  R04G5: { label: 'Видатки спец. фонду, місяць 3', name: 'R04G5' },
  R04G6: { label: 'Видатки спец. фонду, попередня оплата, місяць 1', name: 'R04G6' },
  R04G7: { label: 'Видатки спец. фонду, попередня оплата, місяць 2', name: 'R04G7' },
  R04G8: { label: 'Видатки спец. фонду, попередня оплата, місяць 3', name: 'R04G8' },
  //   Дополнительніе свойста
  budgetCode: { label: 'Код бюджета', name: 'КодБюджета' },
  kvkCode: { label: 'Код КВК', name: 'КодКВК' },
  ownLvl: { label: 'Завантажувати документи власного рівня', name: 'ЗагружатьСвойУровень' },
};

/**
 * @const
 */
const tables = {
  dod1: {
    name: 'Додаток1',
    label: 'Додаток 1',
    columns: {
      date: { label: 'Дата', name: 'Дата' },
      dateStr: { label: 'Дата', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Месяц' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'КЕКВ', name: 'Напрям' },
      sum: { label: 'місяць.рік', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд (загальний/ спеціальний)', name: 'Фонд' },
      fondStr: { label: 'Фонд (загальний/ спеціальний)', name: 'ФондСтр' },
      sum2: { label: 'місяць.рік', name: 'Сумма2' },
      sum3: { label: 'місяць.рік', name: 'Сумма3' },
      sum4: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма4' },
      sum5: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма5' },
      sum6: { label: ' у тому числі попередня оплата товарів, робіт і послуг', name: 'Сумма6' },
      year2: { label: 'Рік', name: 'Год2' },
      year3: { label: 'Рік', name: 'Год3' },
      month2: { label: 'Місяць', name: 'Месяц2' },
      month3: { label: 'Місяць', name: 'Месяц3' },
      routeCode: { label: 'Код', name: 'НапрямКод' },
    },
  },
  dod2: {
    name: 'Додаток2',
    label: 'Додаток 2',
    columns: {
      date: { label: 'Дата платежу', name: 'Дата' },
      dateStr: { label: 'Дата платежу', name: 'ДатаСтр' },
      month: { label: 'Місяць', name: 'Напрям' },
      year: { label: 'Рік', name: 'Год' },
      route: { label: 'Напрям', name: 'Напрям' },
      sum: { label: 'Очікуваний обсяг операцій Розміщення', name: 'Сумма' },
      currency: { label: 'Валюта', name: 'Валюта' },
      fond: { label: 'Фонд', name: 'Фонд' },
      fondStr: { label: 'Фонд', name: 'ФондСтр' },
      sum2: { label: 'Очікуваний обсяг операцій Повернення', name: 'Сумма2' },
    },
  },
  dod3: {
    name: 'Додаток3',
    label: 'Додаток 3',
    columns: {
      date: { label: 'Дата платежу', name: 'Дата' },
      debt: { label: 'Запозичення', name: 'Запозичення' },
      send: { label: 'Платежі з погашення', name: 'Погашення' },
      serv: { label: 'Платежі з обслуговування', name: 'Обслуговування' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи розпорядників',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація щодо ліквідності. Версія 2.',
  name: 'liquidityVer2',
  backendName: 'ИнформацияЛиквидностиВерсия2',
  columns,
  frontend: 'doc/liquidityVer2',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
