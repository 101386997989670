import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import {
  SaveButton, CloseButton, ExecuteButton, UnExecuteButton,
} from '../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../components/bootStrap';

function DocCommandPanel({
  canSaved, modified, canExecuted, canUnexecuted, executed,
  onExecute, onCancelExecute, onSave, onClose, children,
}) {
  return (
    <CommandPanel>
      <SaveButton onClick={onSave} disabled={!canSaved || !modified} />
      <ButtonGroup>
        <ExecuteButton onClick={onExecute} disabled={!canExecuted} />
        <UnExecuteButton onClick={onCancelExecute} disabled={!canUnexecuted || !executed} />
      </ButtonGroup>
      <CloseButton onClick={onClose} disabled={!onClose} />
      {children}
    </CommandPanel>
  );
}

DocCommandPanel.propTypes = {
  canSaved: PropTypes.bool,
  modified: PropTypes.bool,
  canExecuted: PropTypes.bool,
  canUnexecuted: PropTypes.bool,
  executed: PropTypes.bool.isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

DocCommandPanel.defaultProps = {
  canSaved: true,
  modified: false,
  canExecuted: true,
  canUnexecuted: true,
  children: null,
};

export default DocCommandPanel;
