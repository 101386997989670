import { useSelector } from 'react-redux';
import { List } from 'immutable';
import storePathParam from '../../../../../common/storePathParam';
import md from '../../../../../constants/meta/dataprocessors/finPrinting';

const tableName = 'data';

const getSelectField = () => () => [
  {
    modelName: 'financingRequest',
    areaName: 'Document',
    modelType: 'doc',
    filter: [],
    // noHierarchy: false,
    noBackground: true,
    noBorder: true,
  },
];

const getTextView = () => ['Notes'];

const getArrBypassGrid = () => ['Document', 'Notes'];

const getHeaderCol = () => {
  const coldef = md.tables.data.columns;

  const headerCol = {
    Document: coldef.Document,
    Notes: coldef.Notes,
  };

  return headerCol;
};

const getFooterCol = () => {};

const useComponentsInit = () => {
  const selector = (state) => state;

  const state = useSelector(selector);

  const data = state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${tableName}`], new List());

  const gridTemplateColumns = '30% 69%';

  const gridTemplateAreas = '"Document Notes";';
  // grid-auto-columns: 1fr;

  const componentsType = {
    selectField: getSelectField(),
    textView: getTextView(),
    typeEditor: 'dpEditor',

  };

  return {
    componentsType,
    gridTemplateParam: {
      gridTemplateAreas,
      gridTemplateColumns,
    },
    headerCol: getHeaderCol(),
    footerCol: getFooterCol(),
    arrBypassGrid: getArrBypassGrid(),
    data,
    tableName,
  };
};

export default useComponentsInit;
