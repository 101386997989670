import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/assignmentPlanningSource';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { months } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.balancing;

function TPHeader() {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={2}
        className="px-1 d-flex flex-column justify-content-start"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center"
            >
              {meta.columns.row.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      {[...Array(12)].map((_, m) => (
      // eslint-disable-next-line react/no-array-index-key
        <Col
          className="px-1 d-flex flex-column justify-content-start"
        >
          <Row noGutters>
            <Col className="text-center">
              <TableHeaderCell>
                {months[m]}
              </TableHeaderCell>
            </Col>
          </Row>
        </Col>
      ))}
      <Col md={1} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-md-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center text-md-center w-100"
        >
          {meta.columns.Sum.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
};

TPHeader.defaultProps = {
};

export default memo(TPHeader);
