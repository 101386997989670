import allocationDetail from './allocationDetail';
import allocationExtract from './allocationExtract';
import allocationYear from './allocationYear';
import analyzeIndicatorsSF from './analyzeIndicatorsSF';
import assigmentplaning from './assigmentPlaning';
import commoninStitutionStructureBfn from './commoninStitutionStructureBfn';
import commoninRepPanel from './commoninRepPanel';
import compareOutcomes from './compareOutcomes';
import estimate from './estimate';
import extendedFinrequestsAnalisys from './extendedFinrequestsAnalisys';
import finanalisys from './finanalisys';
import financingCard from './financing_card';
import findocanalisys from './findocanalisys';
import finrequstsAnalizys from './finrequsts_analizys';
import planBallancing from './planBallancing';
import planFactOutcomes from './planFactOutcomes';
import reduceIndicatorsSF from './reduceIndicatorsSF';
import summaryOffer from './summaryOffer';
import treasuryFileAnalyze from './treasuryFileAnalyze';
import ussagePlan from './ussagePlan';

const rep = {
  allocationDetail,
  allocationExtract,
  allocationYear,
  analyzeIndicatorsSF,
  assigmentplaning,
  commoninStitutionStructureBfn,
  commoninRepPanel,
  compareOutcomes,
  estimate,
  extendedFinrequestsAnalisys,
  finanalisys,
  financingCard,
  findocanalisys,
  finrequstsAnalizys,
  planBallancing,
  planFactOutcomes,
  reduceIndicatorsSF,
  summaryOffer,
  treasuryFileAnalyze,
  ussagePlan,
};

export default rep;
