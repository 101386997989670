// eslint-disable-next-line import/no-cycle
import BudgetLister from './lister';
import BudgetSelector from './selector';

const budget = {
  lister: BudgetLister,
  selector: BudgetSelector,
};

export default budget;
