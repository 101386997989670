import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FalconCloseButton from '../../blanks/common/CloseButton';

const CPCloseButton = styled(FalconCloseButton)`
  position: absolute !important;
  right: 1rem;
  top: .5rem;
`;
export function CloseButton({ content, onClick, disabled }) {
  return (
    <CPCloseButton
      title={content}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

CloseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CloseButton.defaultProps = {
  onClick: () => null,
  content: '',
  disabled: false,
};

export default CloseButton;
