import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  varProg: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  startDate: { label: 'Враховувати дані з', name: 'НаДату' },
  finDate: { label: 'по', name: 'НаДатуКон' },
  yearPlan: { label: 'Рік планування', name: 'ГодЗатрат' },
  fromVar: { label: 'Рішення сесії з варіанту проекту', name: 'ИзВарианта' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  varReport: { label: 'Вариант отчета', name: 'ВариантОтчета' },
  session: { label: 'Рішення сесії', name: 'РешениеСессии' },
  platAproved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  reportsKind: { label: 'Вид плану', name: 'ПланСУточнением' },
  kopecks: { label: 'показувати копійки', name: 'Копейки' },
  sessiondesigionLOGICA: { label: 'Рішення сесії для відправки в  ЛОГІКА', name: 'РешениеСессииЛОГИКА' },
};

const tables = {
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Використання', name: 'Использование' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      ElGroup: { label: 'Элемент группа', name: 'ЭлементГруппа' },
    },
  },
  tpJSON: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      Col1: { label: 'Кол1', name: 'Кол1' },
      Col2: { label: 'Кол2', name: 'Кол2' },
      Col3: { label: 'Кол3', name: 'Кол3' },
      Col4: { label: 'Кол4', name: 'Кол4' },
      Col5: { label: 'Кол5', name: 'Кол5' },
      Col6: { label: 'Кол6', name: 'Кол6' },
      Col7: { label: 'Кол7', name: 'Кол7' },
      Col8: { label: 'Кол8', name: 'Кол8' },
      Col9: { label: 'Кол9', name: 'Кол9' },
      Col10: { label: 'Кол10', name: 'Кол10' },
      Col11: { label: 'Кол11', name: 'Кол11' },
      Col12: { label: 'Кол12', name: 'Кол12' },
      Col13: { label: 'Кол13', name: 'Кол13' },
      Col14: { label: 'Кол14', name: 'Кол14' },
      Col15: { label: 'Кол15', name: 'Кол15' },
      Col16: { label: 'Кол16', name: 'Кол16' },
      Col17: { label: 'Кол17', name: 'Кол17' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 4 (Кредитування)',
  name: 'SessionAdd4',
  columns,
  tables,
  frontend: 'rep/SessionAdd4',
  backendName: 'РешениеСессии04Кредитування',
  resultColumns: baseResultColumns,
};

export default definition;
