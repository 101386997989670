import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import File from '../../notifier/file';
import { deleteAttachedFile, upLoadDbfFiles } from '../../../actions/dpEditor';
import { Title, SubTitle } from '../../../components/Form/styledForm';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import Loader from '../../../components/styledLoading';

import { DivStyledUpload, FileInput } from '../../../components/uploadFiles/styles';
import storePathParam from '../../../common/storePathParam';

const FilesContainer = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin: 5px 0 20px;
`;

const ContainerFile = styled.div`
  border: 1px solid #49a2a8;
  ${({ noStyle }) => (noStyle
    ? 'box-shadow: none'
    : 'box-shadow: 0px 1px 30px -4px rgb(55, 55, 55);')
};
  // box-shadow: 0px 1px 30px -4px rgb(55, 55, 55);
  background: #e1f1f8;
  align-items: start;
  padding: 15px;
  border-radius: 4px;
  ${({ noStyle }) => (noStyle
    ? 'margin-bottom: 0px;'
    : 'margin-bottom : 15px;')
};
  border: 1px solid #DDE2E9;
  position: relative;
  ::before {
    ${({ noStyle }) => noStyle
    && 'display: none;'};
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 500px;
    top: -20px;
    border: 10px solid;
    border-color: transparent #e1f0f8 #e1f0f8 transparent;
`;
const CloseButton = styled(CommandPanelButton)`
  ${({ noStyle }) => noStyle
  && 'display: none;'};
  margin: 0;
  padding: 3px 7px;
  color: #7d7979;
  background: none;
  font-size: 17px;
  align-self: flex-start;
    :hover{
      color: black;
      background: none;
    };
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function AttachedFilesList({
  dispatch,
  AttachedFiles,
  isLoadingAttachedFiles,
  isLocked,
  noStyle,
  positiveFiles, // Успешно обработанные файлы
  restrictDelete,
}) {
  // const { isLocked } =this.props;
  const handleLoadFile = (e) => {
    const files = [...e.target.files];

    Promise.all(files.map((f) => f)).then((results) => {
      const choiseFiles = results.reduce(
        (R, ffs, i) => [
          ...R,
          ...[{
            file: ffs,
            done: false,
            task: i,
          }],
        ],
        AttachedFiles,
      );
      dispatch(upLoadDbfFiles(choiseFiles));
    });
  };

  const fileGetter = async (file) => [file];

  const scanFiles = (item, callback) => {
    if (item.isDirectory) {
      const DR = item.createReader();
      DR.readEntries((items) => {
        items.map((i) => this.scanFiles(i, callback));
      });
    } else {
      callback(item);
    }
  };

  const fileLoadHandler = (e) => {
    const { items } = e.dataTransfer;

    e.preventDefault();
    let f = AttachedFiles;
    Object.values(items).forEach((i) => scanFiles(i.webkitGetAsEntry(), (item) => {
      item.file((file) => {
        fileGetter(file).then((files) => {
          if (files) {
            f = [...f, ...files.filter((ff) => !!ff).map((fl, ii) => ({
              file: fl,
              done: false,
              task: ii,
            }))];
            dispatch(upLoadDbfFiles(f));
          }
        });
      });
    }));
  };

  const removeForList = (indx) => {
    dispatch(upLoadDbfFiles(AttachedFiles.delete(indx)));
  };

  return (
    <ContainerFile
      onDragOver={(e) => e.preventDefault()}
      onDrop={fileLoadHandler}
      noStyle={noStyle}
    >
      <ContainerHeader>
        <Title>Додані файли</Title>
        <CloseButton
          // noStyle={noStyle}
          text="Закрити"
          label="x"
          onClick={() => dispatch(upLoadDbfFiles([]))}
        />

      </ContainerHeader>
      <DivStyledUpload>
        <FileInput
          onChange={handleLoadFile}
        />
        <span>Оберіть файл</span>
      </DivStyledUpload>
      {isLoadingAttachedFiles ? (
        <Loader />
      ) : (
        <FilesContainer>
          {AttachedFiles.map((file, indx) => (
            <File
              key={file.get('fileName')}
              positive={positiveFiles.includes(file.get('fileName'))}
              file={file}
              removeForList={removeForList}
              indx={indx}
              isLocked={isLocked || restrictDelete}
              onDeleteFile={() => dispatch(deleteAttachedFile(file.get('fileName', '')))}
            />
          ))}
        </FilesContainer>
      )}
      <div>
        <SubTitle>
          Оберіть файли для завантаження або перетяніть їх до блакитної зони
        </SubTitle>

      </div>
    </ContainerFile>
  );
}

AttachedFilesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  AttachedFiles: PropTypes.arrayOf.isRequired,
  isLoadingAttachedFiles: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  noStyle: PropTypes.bool,
  positiveFiles: PropTypes.instanceOf(List),
  restrictDelete: PropTypes.bool,
};

AttachedFilesList.defaultProps = {
  isLoadingAttachedFiles: false,
  noStyle: false,
  positiveFiles: new List(),
  restrictDelete: false,
};

const mapState = (store) => {
  const editorNode = store.getIn([`dp/${storePathParam(store).name}/dpEditor`], new Map());
  return {
    AttachedFiles: editorNode.get('AttachedFiles', []),
    isLoadingAttachedFiles: editorNode.get('isLoadingAttachedFiles', false),
    isLocked: editorNode.get('isLocked', false), // низя удалять
  };
};

export default connect(mapState)(AttachedFilesList);
