import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  KLB: { label: 'Код бюджету', name: 'KLB', visible: false },
  NAME_ORG: { label: 'Заявник – найменування відповідної ради', name: 'NAME_ORG', visible: false },
  DATE1: { label: 'Дата звернення (вихідна Заявника) (для шапки)', name: 'DATE1', visible: false },
  DATE2: { label: 'Дата рішення про погодження (вихідна Мінфіну)', name: 'DATE2', visible: false },
  NUMBER1: { label: '№ звернення (вихідний Заявника)', name: 'NUMBER1', visible: false },
  NUMBER2: { label: '№ рішення про погодження (вихідний Мінфіну)', name: 'NUMBER2', visible: false },
  R01D1: { label: 'ЄДРПОУ позичальника', name: 'R01D1', visible: false },
  R02D1: { label: 'Найменування позичальника', name: 'R02D1', visible: false },
  R03D1: { label: 'ЄДРПОУ кредитора', name: 'R03D1', visible: false },
  R04D1: { label: 'Найменування кредитора', name: 'R04D1', visible: false },
  R05D1: { label: 'Дата підписання угоди', name: 'R05D1', visible: false },
  R06D1: { label: 'Код/номер позики', name: 'R06D1', visible: false },
  R07D1: { label: 'Назва позики', name: 'R07D1', visible: false },
  R08D1: { label: 'Характер позики', name: 'R08D1', visible: false },
  R09D1: { label: 'Тип позики', name: 'R09D1', visible: false },
  R10D1: { label: 'Сума позики', name: 'R10D1', visible: false },
  R11D1: { label: 'Валюта позики', name: 'R11D1', visible: false },
  R12D1: { label: 'Термін позики', name: 'R12D1', visible: false },
  R13D1: { label: 'Одиниці терміну', name: 'R13D1', visible: false },
  R14D1: { label: 'Дата погашення', name: 'R14D1', visible: false },
  R15D1: { label: 'Мета запозичення', name: 'R15D1', visible: false },
  R16D1: { label: 'Примітки', name: 'R16D1', visible: false },
  R17D1: { label: 'Кількість облігацій', name: 'R17D1', visible: false },
  R18D1: { label: 'Номінальна вартість', name: 'R18D1', visible: false },
  R19D1: { label: 'Гарантована сума', name: 'R19D1', visible: false },
  R20D1: { label: 'Валюта гарантованої суми', name: 'R20D1', visible: false },
  R21D1: { label: 'Дата гарантії', name: 'R21D1', visible: false },
  R22D1: { label: 'Угода гарантії', name: 'R22D1', visible: false },
  R23D1: { label: 'Початок виплат з погашення', name: 'R23D1', visible: false },
  R24D1: { label: 'Періодичність виплат з погашення', name: 'R24D1', visible: false },
  R25D1: { label: 'Одиниці періоду погашення', name: 'R25D1', visible: false },
  R26D1: { label: 'Закінчення виплат з погашення', name: 'R26D1', visible: false },
  R27D1: { label: 'Початок виплат з обслуговування', name: 'R27D1', visible: false },
  R28D1: { label: 'Періодичність виплат з обслуговування', name: 'R28D1', visible: false },
  R29D1: { label: 'Одиниці періоду обслуговування', name: 'R29D1', visible: false },
  R30D1: { label: 'Закінчення виплат з обслуговування', name: 'R30D1', visible: false },
  R31D1: { label: 'Відсоткова ставка', name: 'R31D1', visible: false },
  R32D1: { label: 'Маржа та/чи спред, %', name: 'R32D1', visible: false },
  HBOS: { label: 'Керівник МФО', name: 'HBOS', visible: false },
  PROJ1: { label: 'Назва проекту', name: 'PROJ1', visible: false },
  EDRPOU1: { label: 'ЄДРПОУ кредитора', name: 'EDRPOU1', visible: false },
  NAME1: { label: 'Найменування кредитора', name: 'NAME1', visible: false },
  DATE3: { label: 'Дата укладення договору', name: 'DATE3', visible: false },
  NUMBER3: { label: 'Номер позики/угоди', name: 'NUMBER3', visible: false },
  PROJ2: { label: 'Назва проекту', name: 'PROJ2', visible: false },
  EDRPOU2: { label: 'ЄДРПОУ кредитора', name: 'EDRPOU2', visible: false },
  NAME2: { label: 'Найменування кредитора', name: 'NAME2', visible: false },
  DATE4: { label: 'Дата укладення договору', name: 'DATE4', visible: false },
  NUMBER4: { label: 'Номер позики/угоди', name: 'NUMBER4', visible: false },
  CRED_NAME: { label: 'Найменування кредитора', name: 'CRED_NAME', visible: false },
  POZ_NAME: { label: 'Назва проєкту', name: 'POZ_NAME', visible: false },
  DATE_SIGN: { label: 'Дата підписання угоди', name: 'DATE_SIGN', visible: false },
  NUM_POZ: { label: 'Код/номер позики', name: 'NUM_POZ', visible: false },
  POZ_NUM: { label: 'Номер позики/угоди', name: 'POZ_NUM', visible: false },
  PROJ_NAME: { label: 'Назва проекту', name: 'PROJ_NAME', visible: false },
  EDRPOU_KRED: { label: 'ЄДРПОУ кредитора', name: 'EDRPOU_KRED', visible: false },
  KRED_NAME: { label: 'Найменування кредитора', name: 'KRED_NAME', visible: false },
  DATE_CONCL: { label: 'Дата укладення договору', name: 'DATE_CONCL', visible: false },
  DATE1_1: { label: 'Дата звернення (вихідна Заявника)', name: 'DATE1_1', visible: false },
  NAME_ORGtext: { label: 'Заявник – найменування відповідної ради', name: 'NAME_ORGтекст', visible: false },
  R34D1: { label: 'Додаткові фінансові умови', name: 'R34D1', visible: false },
};

/**
 * @const
 */
const tables = {
  TABL1: {
    name: 'TABL1',
    label: 'ГРАФІКИ планових та фактичних надходжень коштів за місцевим запозиченням та місцевою гарантією (всі надходження)',
    columns: {
      T1RXXXXG1S: { label: 'Планова дата надходження', name: 'T1RXXXXG1S' },
      T1RXXXXG2: { label: 'Планова сума надходження у валюті позики', name: 'T1RXXXXG2' },
      T1RXXXXG5S: { label: 'Валюта позики', name: 'T1RXXXXG5S' },
      T1RXXXXG4S: { label: 'Дата боргового обліку', name: 'T1RXXXXG4S' },
      T1RXXXXG3S: { label: 'Фактична дата надходження', name: 'T1RXXXXG3S' },
      T1RXXXXG4: { label: 'Фактична сума надходження (у валюті позики)', name: 'T1RXXXXG4' },
      T1RXXXXG6: { label: 'Еквівалент в грн.', name: 'T1RXXXXG6' },
      T1RXXXXG7: { label: 'Еквівалент в дол. США', name: 'T1RXXXXG7' },
      // T1RXXXXG8: { label: 'Курс валюти на дату надходження', name: 'T1RXXXXG8' },
      T1RXXXXG9S: { label: 'Примітка', name: 'T1RXXXXG9S' },

    },
  },
  TABL2: {
    name: 'TABL2',
    label: 'ГРАФІКИ планових та фактичних платежів за місцевим запозиченням та місцевою гарантією (всі платежі)',
    columns: {
      T2RXXXXG1S: { label: 'Планова дата (за графіком)', name: 'T2RXXXXG1S' },
      T2RXXXXG2: { label: 'Планова сума у валюті позики (за графіком відповідно до договору)', name: 'T2RXXXXG2' },
      T2RXXXXG3S: { label: 'Тип платежу', name: 'T2RXXXXG3S' },
      T2RXXXXG4S: { label: 'Фактична дата платежу', name: 'T2RXXXXG4S' },
      T2RXXXXG5: { label: 'Фактична сума платежу у валюті позики', name: 'T2RXXXXG5' },
      T2RXXXXG6S: { label: 'Валюта позики', name: 'T2RXXXXG6S' },
      T2RXXXXG7: { label: 'Фактична сума платежу, еквівалент у грн', name: 'T2RXXXXG7' },
      T2RXXXXG8: { label: 'Фактична сума платежу, еквівалент у дол. США', name: 'T2RXXXXG8' },
      T2RXXXXG9S: { label: 'Джерело погашення', name: 'T2RXXXXG9S' },

    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
  TABL3: {
    name: 'TABL3',
    label: 'Перелік додаткових угод до угоди (за наявності)',
    columns: {
      T3RXXXXG1S: { label: '№', name: 'T3RXXXXG1S' },
      T3RXXXXG2S: { label: 'Дата додаткової угоди', name: 'T3RXXXXG2S' },
      T3RXXXXG3S: { label: 'Номер додаткової угоди ', name: 'T3RXXXXG3S' },
      T3RXXXXG4S: { label: 'Інформація щодо внесених змін до угоди', name: 'T3RXXXXG4S' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Місцеві запозичення та гарантії',
  name: 'garanty',
  backendName: 'ГарантииДолги',
  columns,
  frontend: 'doc/garanty',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'note',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
