import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  Sort: {
    label: 'Код сортування',
    name: 'Сортировка',
    visible: true,
  },
  CostElement: {
    label: 'Вид затрат',
    name: 'ВидЗатрат',
    visible: true,
  },
  ExcludeFromTotal: {
    label: 'Не включати до підсумку',
    name: 'НеВключатьВИтог',
    visible: false,
  },
  DoNotPrint: {
    label: 'Не друкувати рядок',
    name: 'НеВыводитьНаПечать',
    visible: true,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
};

export const salaryStructure = {
  label: 'Структура видатків на оплату праці',
  name: 'salaryStructure',
  backendName: 'СтруктураОплатыТруда',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/salaryStructure',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default salaryStructure;
