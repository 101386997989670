import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from './propTypes';
import { TableControls, tableHooks } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { TypeIndIcon } from '../../../../../catalogs/indicator/getTypeIcon';

function IndicatorsRow({
  rowIndex, row, onChange, readOnly, highlights, fields, errors, readOnlyFields,
  fromLogica, budgetFilter,
}) {
  const indicatorProps = tableHooks.useItemInputProps('indicator', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const indicatorTextProps = tableHooks.useStringInputProps('indicator_text', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanZFProps = tableHooks.useNumberInputProps('amount_plan_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactZFProps = tableHooks.useNumberInputProps('amount_fact_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffZFProps = tableHooks.useNumberInputProps('amount_diff_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanSFProps = tableHooks.useNumberInputProps('amount_plan_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactSFProps = tableHooks.useNumberInputProps('amount_fact_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffSFProps = tableHooks.useNumberInputProps('amount_diff_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  // Додала можливість редагувати загальної суми по проханню консультантів: (
  // Наприклад стоїть відсоток виконання по загальному фонду 100 та по СФ 100,
  // разом рахує 200%, а потрібно щоб 100
  // Або є кількість дітей, які фінансуються по загальному фонду 52,
  // з них по спецфонду 8, разом рахує 60, а потрібно 52
  // )
  const amountPlanTotalProps = tableHooks.useNumberInputProps('amount_plan_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactTotalProps = tableHooks.useNumberInputProps('amount_fact_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffTotalProps = tableHooks.useNumberInputProps('amount_diff_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const descriptionProps = tableHooks.useStringInputProps('description', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <>
      <Col xs={4} className="px-1 mx-1">
        <TabelRowCell column="indicator" highlighted={highlights.includes('indicator')}>
          {fromLogica ? (
            <TableControls.StringInput
              {...indicatorTextProps}
              prepend={(
                <TypeIndIcon value={row.indicator_type} />
                )}
              append={(
                <div className="d-flex border-top border-bottom ">
                  <span className="text-muted fs--2 align-self-center fw-bolder px-2">
                    {row.unit_of_measurement || ''}
                  </span>
                </div>
                )}
            />
          ) : (
            <TableControls.ItemPicker
              {...indicatorProps}
              filter={budgetFilter}
              prepend={(
                <TypeIndIcon value={row.indicator_type} />
                )}
              append={(
                <div className="d-flex border-top border-bottom ">
                  <span className="text-muted fs--2 align-self-center fw-bolder px-2">
                    {row.unit_of_measurement || ''}
                  </span>
                </div>
                )}
            />
          )}
        </TabelRowCell>
      </Col>
      <Col className="px-1 mx-1">
        <Row>
          <Col className="px-0 mx-0">
            <TabelRowCell column="amount_plan_total" highlighted={highlights.includes('amount_plan_total')}>
              <TableControls.NumberInput
                {...amountPlanTotalProps}
                precision={3}
                disabled
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_plan_total: v,
                    amount_diff_total: +((row.amount_fact_total || 0) - v).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabelRowCell column="amount_plan_zf" highlighted={highlights.includes('amount_plan_zf')}>
              <TableControls.NumberInput
                {...amountPlanZFProps}
                precision={3}
                disabled
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_plan_zf: v,
                    amount_plan_total: +((row.amount_plan_sf || 0) + v).toFixed(3),
                    amount_diff_zf: +((row.amount_fact_zf || 0) - v).toFixed(3),
                    amount_diff_total:
                        +((row.amount_fact_zf || 0) - v + (row.amount_diff_sf || 0)).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
          <Col className="p-0 m-0">
            <TabelRowCell column="amount_plan_sf" highlighted={highlights.includes('amount_plan_sf')}>
              <TableControls.NumberInput
                {...amountPlanSFProps}
                precision={3}
                disabled
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_plan_sf: v,
                    amount_plan_total: +((row.amount_plan_zf || 0) + v).toFixed(3),
                    amount_diff_sf: +((row.amount_fact_sf || 0) - v).toFixed(3),
                    amount_diff_total: +((row.amount_diff_zf || 0)
                        + (row.amount_fact_sf || 0) - v).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 mx-0">
        <Row className="px-1 mx-1">
          <Col>
            <TabelRowCell column="amount_fact_total" highlighted={highlights.includes('amount_fact_total')}>
              <TableControls.NumberInput
                {...amountFactTotalProps}
                precision={3}
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_fact_total: v,
                    amount_diff_total: +(v - (row.amount_plan_total || 0)).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
        <Row className="px-1 mx-1">
          <Col className="px-1 mx-0">
            <TabelRowCell column="amount_fact_zf" highlighted={highlights.includes('amount_fact_zf')}>
              <TableControls.NumberInput
                {...amountFactZFProps}
                precision={3}
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_fact_zf: v,
                    amount_fact_total: +((row.amount_fact_sf || 0) + v).toFixed(3),
                    amount_diff_zf: +(v - (row.amount_plan_zf || 0)).toFixed(3),
                    amount_diff_total: +(v - (row.amount_plan_zf || 0)
                        + (row.amount_diff_sf || 0)).toFixed(3),

                  },
                )}
              />
            </TabelRowCell>
          </Col>
          <Col className="px-0 mx-1">
            <TabelRowCell column="amount_fact_sf" highlighted={highlights.includes('amount_fact_sf')}>
              <TableControls.NumberInput
                {...amountFactSFProps}
                precision={3}
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_fact_sf: v,
                    amount_fact_total: +((row.amount_fact_zf || 0) + v).toFixed(3),
                    amount_diff_sf: +(v - (row.amount_plan_sf || 0)).toFixed(3),
                    amount_diff_total: +((row.amount_diff_zf || 0)
                        - (row.amount_plan_sf || 0) + v).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 mx-1">
        <Row>
          <Col className="px-0 mx-0">
            <TabelRowCell column="amount_diff_total" highlighted={highlights.includes('amount_diff_total')}>
              <TableControls.NumberInput
                {...amountDiffTotalProps}
                precision={3}
              />
            </TabelRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="px-0 mx-0">
            <TabelRowCell column="amount_diff_zf" highlighted={highlights.includes('amount_diff_zf')}>
              <TableControls.NumberInput
                {...amountDiffZFProps}
                precision={3}
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_diff_zf: v,
                    amount_diff_total: +((row.amount_diff_sf || 0) + v).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
          <Col className="px-1 mx-0">
            <TabelRowCell column="amount_diff_sf" highlighted={highlights.includes('amount_diff_sf')}>
              <TableControls.NumberInput
                {...amountDiffSFProps}
                precision={3}
                onChange={(e, v) => onChange(
                  e,
                  rowIndex,
                  {
                    amount_diff_sf: v,
                    amount_diff_total: +((row.amount_diff_zf || 0) + v).toFixed(3),
                  },
                )}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 mx-2">
        <TabelRowCell column="description" highlighted={highlights.includes('description')}>
          <TableControls.TextInput
            {...descriptionProps}
            rows={2}
          />
        </TabelRowCell>
      </Col>

    </>
  );
}

IndicatorsRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.shape(dataPropTypes),
  readOnly: PropTypes.bool,
  fields: fieldsPropTypes,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onToggleHighlight: PropTypes.func.isRequired,
  fromLogica: PropTypes.bool,
  budgetFilter: PropTypes.shape({ budget: PropTypes.number }),
};

IndicatorsRow.defaultProps = {
  errors: null,
  readOnlyFields: {},
  readOnly: false,
  activeCol: null,
  highlights: [],
  fromLogica: false,
  budgetFilter: { budget: null },
  fields: null,
};

export default memo(IndicatorsRow);
