import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        className="text-center text-xl-left"
      >
        РАЗОМ
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'zf_2'} highlighted={highlights.includes('zf_2')}>
              {totals.zf_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'sf_2'} highlighted={highlights.includes('sf_2')}>
              {totals.sf_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'br_2'} highlighted={highlights.includes('br_2')}>
              {totals.br_2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'zf_1'} highlighted={highlights.includes('zf_1')}>
              {totals.zf_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'sf_1'} highlighted={highlights.includes('sf_1')}>
              {totals.sf_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'br_1'} highlighted={highlights.includes('br_1')}>
              {totals.br_1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'zf0'} highlighted={highlights.includes('zf0')}>
              {totals.zf0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'sf0'} highlighted={highlights.includes('sf0')}>
              {totals.sf0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'br0'} highlighted={highlights.includes('br0')}>
              {totals.br0.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'zf1'} highlighted={highlights.includes('zf1')}>
              {totals.zf1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'sf1'} highlighted={highlights.includes('sf1')}>
              {totals.sf1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'br1'} highlighted={highlights.includes('br1')}>
              {totals.br1.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'zf2'} highlighted={highlights.includes('zf2')}>
              {totals.zf2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'sf2'} highlighted={highlights.includes('sf2')}>
              {totals.sf2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell className="text-center text-xl-left" active={activeCol === 'br2'} highlighted={highlights.includes('br2')}>
              {totals.br2.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col
            className="text-center text-xl-left"
          />
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    zf2: PropTypes.number,
    zf1: PropTypes.number,
    zf0: PropTypes.number,
    zf_1: PropTypes.number,
    zf_2: PropTypes.number,
    sf2: PropTypes.number,
    sf1: PropTypes.number,
    sf0: PropTypes.number,
    sf_1: PropTypes.number,
    sf_2: PropTypes.number,
    br2: PropTypes.number,
    br1: PropTypes.number,
    br0: PropTypes.number,
    br_1: PropTypes.number,
    br_2: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
