import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/kekvClassifierForMinFinShort';

function KekvClassifierForMinFinShort({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={25}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.owner.name]: value,
              });
            }}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.classifier.label}
            value={data[md.columns.classifier.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.classifier.name]: value,
              });
            }}
            modelType="cat"
            modelName="kekvClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`editingManually-${data.id}`}
            label={md.columns.editingManually.label}
            value={data[md.columns.editingManually.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.editingManually.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`allOthers-${data.id}`}
            label={md.columns.allOthers.label}
            value={data[md.columns.allOthers.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.allOthers.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

KekvClassifierForMinFinShort.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KekvClassifierForMinFinShort;
