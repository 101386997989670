import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import SoftBadge from '../../../../components/blanks/common/SoftBadge';
import corner1 from '../../../../assets/img/illustrations/corner-1.png';
import Background from '../../../../components/blanks/common/Background';
import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../../assets/img/illustrations/corner-3.png';
import RegiontTab from './RegiontTab';
import ChartTop10 from './TableProject';
import { Loader } from '../../../../components/bootStrap';
import useFetchRegionalProgramData from '../Hooks/useFetchRegionalProgramData';

function RegionalProgramPage({ cDt }) {
  const {
    expensesProject, projectTarget, projectPlan, err, isLoading,
  } = useFetchRegionalProgramData({ cDt });
  return (
    <>
      {isLoading && (
        <Loader text="Завантаження" />
      )}
      <Row className="g-3">
        <Col xxl={4} xl={4} md={6} lg={6} className="mt-0 mb-2">
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner1}
            />
            <Card.Header>
              <div>
                <h4 className="text-warning mb-0">Видатки загального фонду на міські цільові програми</h4>
                <h6 className="text-700">
                  згідно дод.7 до рішення сесії про бюджет
                </h6>
                <div className="d-flex flex-wrap gap-2 align-items-baseline">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={faCaretDown} />
                    {' '}
                    {expensesProject.zf.percent}
                    {' '}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від
                    {' '}
                    {parseInt(cDt.substring(0, 4), 10) - 1}
                    р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-warning',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={expensesProject.zf.total}
                  duration={2.75}
                  suffix="грн."
                  err={err}
                  prefix=""
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
            </Card.Body>

          </Card>
        </Col>
        <Col xxl={4} xl={4} md={6} lg={6} className="mt-0 mb-2">
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner2}
            />
            <Card.Header>
              <div>
                <h4 className="text-info mb-0">Видатки спеціального фонду на міські цільові програми</h4>
                <h6 className="text-700">
                  згідно дод.7 до рішення сесії про бюджет
                </h6>
                <div className="d-flex flex-wrap gap-2 align-items-baseline">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={faCaretDown} />
                    {' '}
                    {expensesProject.sf.percent}
                    {' '}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від
                    {' '}
                    {parseInt(cDt.substring(0, 4), 10) - 1}
                    р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-info',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={expensesProject.sf.total}
                  duration={2.75}
                  suffix="грн."
                  prefix=""
                  err={err}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
            </Card.Body>

          </Card>
        </Col>
        <Col xxl={4} xl={4} md={6} lg={6} className="mt-0 mb-2">
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner3}
            />
            <Card.Header>
              <div>
                <h4 className="text-black text-700 mb-0">Видатки бюджет розвитку, що є частиною спец. фонду</h4>
                <h6 className="text-700">
                  згідно дод.7 до рішення сесії про бюджет
                </h6>
                <div className="d-flex flex-wrap gap-2 align-items-baseline">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={faCaretDown} />
                    {' '}
                    {expensesProject.br.percent}
                    {' '}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від 2023р.
                  </h6>

                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-black text-700',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={expensesProject.br.total}
                  duration={2.75}
                  suffix="грн."
                  prefix=""
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
            </Card.Body>

          </Card>
        </Col>
      </Row>
      <Row className="g-3 mt-1 d-none d-sm-flex">
        <ChartTop10
          data={projectTarget}
        />
      </Row>
      <Row className="g-3 mt-1 d-none d-md-flex">
        <RegiontTab
          data={projectPlan}
        />
      </Row>
    </>
  );
}

RegionalProgramPage.propTypes = {
  cDt: PropTypes.string.isRequired,
};
export default RegionalProgramPage;
