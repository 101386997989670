import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/expensesOnHealth';

const meta = md.tables.TP2282;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG51S'}
              highlighted={highlights.includes('T1RXXXXG51S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG51S')}
            >
              {meta.columns.T1RXXXXG51S.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG1S'}
              highlighted={highlights.includes('T1RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG1S')}
            >
              {meta.columns.T1RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG27'}
              highlighted={highlights.includes('T1RXXXXG27')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG27')}
            >
              {meta.columns.T1RXXXXG27.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG28'}
              highlighted={highlights.includes('T1RXXXXG28')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG28')}
            >
              {meta.columns.T1RXXXXG28.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG29'}
              highlighted={highlights.includes('T1RXXXXG29')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG29')}
            >
              {meta.columns.T1RXXXXG29.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG30'}
              highlighted={highlights.includes('T1RXXXXG30')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG30')}
            >
              {meta.columns.T1RXXXXG30.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG31'}
              highlighted={highlights.includes('T1RXXXXG31')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG31')}
            >
              {meta.columns.T1RXXXXG31.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG32'}
              highlighted={highlights.includes('T1RXXXXG32')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG32')}
            >
              {meta.columns.T1RXXXXG32.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG33'}
              highlighted={highlights.includes('T1RXXXXG33')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG33')}
            >
              {meta.columns.T1RXXXXG33.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG34'}
              highlighted={highlights.includes('T1RXXXXG34')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG34')}
            >
              {meta.columns.T1RXXXXG34.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG35'}
              highlighted={highlights.includes('T1RXXXXG35')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG35')}
            >
              {meta.columns.T1RXXXXG35.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG36'}
              highlighted={highlights.includes('T1RXXXXG36')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG36')}
            >
              {meta.columns.T1RXXXXG36.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG37'}
              highlighted={highlights.includes('T1RXXXXG37')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG37')}
            >
              {meta.columns.T1RXXXXG37.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG38'}
              highlighted={highlights.includes('T1RXXXXG38')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG38')}
            >
              {meta.columns.T1RXXXXG38.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG39'}
              highlighted={highlights.includes('T1RXXXXG39')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG39')}
            >
              {meta.columns.T1RXXXXG39.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG40'}
              highlighted={highlights.includes('T1RXXXXG40')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG40')}
            >
              {meta.columns.T1RXXXXG40.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG41'}
              highlighted={highlights.includes('T1RXXXXG41')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG41')}
            >
              {meta.columns.T1RXXXXG41.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG42'}
              highlighted={highlights.includes('T1RXXXXG42')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG42')}
            >
              {meta.columns.T1RXXXXG42.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG43'}
              highlighted={highlights.includes('T1RXXXXG43')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG43')}
            >
              {meta.columns.T1RXXXXG43.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG44'}
              highlighted={highlights.includes('T1RXXXXG44')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG44')}
            >
              {meta.columns.T1RXXXXG44.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG45'}
              highlighted={highlights.includes('T1RXXXXG45')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG45')}
            >
              {meta.columns.T1RXXXXG45.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG46'}
              highlighted={highlights.includes('T1RXXXXG46')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG46')}
            >
              {meta.columns.T1RXXXXG46.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG47'}
              highlighted={highlights.includes('T1RXXXXG47')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG47')}
            >
              {meta.columns.T1RXXXXG47.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG48'}
              highlighted={highlights.includes('T1RXXXXG48')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG48')}
            >
              {meta.columns.T1RXXXXG48.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG49'}
              highlighted={highlights.includes('T1RXXXXG49')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG49')}
            >
              {meta.columns.T1RXXXXG49.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG50'}
              highlighted={highlights.includes('T1RXXXXG50')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG50')}
            >
              {meta.columns.T1RXXXXG50.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
