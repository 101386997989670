import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Flex({
  justifyContent,
  alignItems,
  alignContent,
  inline,
  wrap,
  className,
  tag: Tag = 'div',
  children,
  breakpoint,
  direction,
  ...rest
}) {
  return (
    <Tag
      className={classNames(
        {
          [`d-${breakpoint ? `${breakpoint}-` : ''}flex`]: !inline,
          [`d-${breakpoint ? `${breakpoint}-` : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
}

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  justifyContent: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  inline: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  alignItems: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  alignContent: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  wrap: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tag: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  breakpoint: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  direction: PropTypes.string,
};

// Flex.defaultProps = {
//   justifyContent: '',
//   inline: false,
//   alignItems: '',
//   alignContent: '',
//   wrap: '',
//   className: '',
//   tag: '',
//   breakpoint: '',
//   direction: '',
// };

export default Flex;
