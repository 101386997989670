import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/revenueAnalysis';
import Fields from '../../field';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ContainerFourColumn,
  SevenColumnsGridContainer,
  FiveColumnsGridContainer,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import StyledTPPane from '../../../components/tableParts/TPPane';
import DateInput from '../../../components/fields/dates';
import ReportContainer from '../reportContainer';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

const Grid = styled(SevenColumnsGridContainer)`
  grid-template-columns: 10% 1fr 20% 20%;
`;
const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const DataColumnsGridContainer = styled(FiveColumnsGridContainer)`
  grid-template-columns: 15% 3% 15% 55% 0%;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ReportTypes = {
  plan: { label: 'Розпис', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'Розпис+уточнення', value: 2 },
};

const groupKDB = {
  plan: { label: 'Елементи КДБ', value: 0 },
  update: { label: 'Елементи та групи КДБ', value: 1 },
  all: { label: 'Групи КДБ', value: 2 },
};

const TypeDocReport = {
  infoExecuteBudget: { label: 'Довідка про виконання бюджету', value: 0 },
  tYear: { label: 'Річний', value: 1 },
  tMonth: { label: 'Помісячний', value: 2 },
  infoRev: { label: 'Інформація про надходження', value: 3 },
  analysisRev: { label: 'Аналіз доходів', value: 4 },
  onlyTreasure: { label: 'Тільки казн. виписки по дням', value: 5 },
  analysisRevNew: { label: 'Аналіз доходів (новий порядок стовпців)', value: 6 },
  typeYearRevers: { label: 'Річний (враховувати повернення)', value: 7 },
  typeMonthRevers: { label: 'Помісячний (враховувати повернення)', value: 8 },
  infoExecuteBudgetExt: { label: 'Довідка про виконання бюджету (розширена) ', value: 9 },
};

const StringInput4 = Fields.GetTextInput(4);
const NumberInput2 = Fields.GetNumberInput(2, 0, true);

class ReportRevenueAnalysisEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    onReportDetails: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedReportTypes: 0,
    };
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, onReportDetails,
      },
      state: { selectedReportTypes },
    } = this;

    const { portalOpened } = this.props;

    const HideDisplay = !selectedReportTypes // if 0
      && (
        <div>
          <StyledLabel>{md.columns.hideDisplay.label}</StyledLabel>
          <NumberInput2
            border
            radius
            background
            value={headerForm.get('hideDisplay', '')}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'hideDisplay'], v))}
          />
        </div>
      );

    const ShowCurrentKDB = !selectedReportTypes // if 0
      && (
        <Fields.CheckboxField
          value={headerForm.get('showCurrentKDB', false)}
          label={md.columns.showCurrentKDB.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'showCurrentKDB'], v))}
        />
      );

    const ByMonthsAccum = ([2, 3]).includes(selectedReportTypes)
      && (
        <Fields.CheckboxField
          value={headerForm.get('byMonthsAccum', false)}
          label={md.columns.byMonthsAccum.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'byMonthsAccum'], v))}
        />
      );

    const Relative = ([2, 1]).includes(selectedReportTypes)
      && (
        <div>
          <StyledLabel>Відхилення</StyledLabel>
          <Fields.CheckboxField
            value={headerForm.get('deviationRelative', false)}
            label={md.columns.deviationRelative.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'deviationRelative'], v))}
          />

          <Fields.CheckboxField
            value={headerForm.get('absoluteRelative', false)}
            label={md.columns.absoluteRelative.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'absoluteRelative'], v))}
          />
        </div>
      );

    const IncDB = ([2, 1]).includes(selectedReportTypes)
      && (
        <Fields.CheckboxField
          value={headerForm.get('incDB', false)}
          label={md.columns.incDB.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'incDB'], v))}
        />
      );

    const TypeReport = (selectedReportTypes !== 3)
      && (
        <div>
          <StyledLabel>{md.columns.reportType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('reportType', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
            values={Object.values(ReportTypes)}
            name="reportType"
          />
        </div>
      );

    const DocumentDetails = ([0, 1, 2, 4, 5]).includes(selectedReportTypes)
    && (
      <Fields.CheckboxField
        value={headerForm.get('documentDetails', false)}
        label={md.columns.documentDetails.label}
        onChange={(e, v) => dispatch(changeField(['headerForm', 'documentDetails'], v))}
      />
    );

    const Symbol0 = (selectedReportTypes !== 5)
      && (
        <div>
          <StyledLabel>{md.columns.symbol0.label}</StyledLabel>
          <StringInput4
            radius
            background
            value={headerForm.get('symbol0', '')}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'symbol0'], v))}
          />
        </div>
      );

    const ShowMonth = ([2, 8]).includes(selectedReportTypes)
      && (
        <div>
          <StyledLabel>Показати місяця</StyledLabel>
          <Fields.CheckboxField
            value={headerForm.get('monthByStartYear', false)}
            label={md.columns.monthByStartYear.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'monthByStartYear'], v))}
          />
          <StyledLabel>Кількість</StyledLabel>
          <NumberInput2
            disableCalculator
            border
            radius
            background
            value={headerForm.get('monthCount', '')}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'monthCount'], v))}
          />
        </div>
      );

    const DataStart = (
      <div>
        <StyledLabel>{md.columns.dateStart.label}</StyledLabel>
        <DateInput
          value={headerForm.get('dateStart', null)}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'dateStart'], v))}
          disabled={!([3, 5, 9]).includes(selectedReportTypes)}
        />
      </div>
    );

    const SettingsButton = (
      <div>
        <ContainerFourColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
            />

          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('FO', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'FO'], v));
              }}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.region.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('region', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'region'], v));
              }}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.bySeparateBudgets.label}
              value={headerForm.get('bySeparateBudgets', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'bySeparateBudgets'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.isShowAllBudgets.label}
              value={headerForm.get('isShowAllBudgets', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'isShowAllBudgets'], v));
                if (v) {
                  dispatch(changeField(['headerForm', 'isShowAllBudgets'], false));
                }
              }}
            />
            <Fields.CheckboxField
              label={md.columns.bySplit.label}
              value={headerForm.get('bySplit', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'bySplit'], v));
              }}
            />
            <Fields.CheckboxField
              label={md.columns.withoutSubLevelCSU.label}
              value={headerForm.get('withoutSubLevelCSU', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'withoutSubLevelCSU'], v));
              }}
            />
          </div>
        </ContainerFourColumn>
        <DataColumnsGridContainer>
          {DataStart}

          <StyledLabel>{md.columns.dateEnd.label}</StyledLabel>
          <DateInput
            value={headerForm.get('dateEnd', null)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'dateEnd'], v))}
          />

          <Fields.CheckboxField
            label={md.columns.isIncludeEndDate.label}
            value={headerForm.get('isIncludeEndDate', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isIncludeEndDate'], v))}
          />
        </DataColumnsGridContainer>
        <ContainerFourColumn>
          <div>
            <StyledLabel>{md.columns.kdbClassifier.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('kdbClassifier', new Map())}
              modelType="cat"
              modelName="kdbClassifier"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'kdbClassifier'], v));
              }}
              canCleared
            />
          </div>
          <div>
            <StyledLabel>{md.columns.fondClassifier.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('fondClassifier', new Map())}
              modelType="cat"
              modelName="fondClassifier"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'fondClassifier'], v));
              }}
              canCleared
            />
          </div>
          <div>
            {IncDB}
            <Fields.CheckboxField
              value={headerForm.get('inThousands', false)}
              label={md.columns.inThousands.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'inThousands'], v))}
            />
          </div>
        </ContainerFourColumn>
        <Grid>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => (
                { label: _e.label, value: _e.name }))}
              name="planType"
            />
            {TypeReport}
          </div>
          <div>
            <StyledLabel>{md.columns.groupKDB.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('groupKDB', 0)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'groupKDB'], v))}
              values={Object.values(groupKDB)}
              name="groupKDB"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.typeDocReport.label}</StyledLabel>
            <GridBox>
              <RadioButton
                value={headerForm.get('typeDocReport', 0)}
                values={Object.values(TypeDocReport)}
                name="typeDocReport"
                onChange={(e, v) => {
                  console.log('v', v);
                  dispatch(changeField(['headerForm', 'typeDocReport'], v));
                  dispatch(changeField(['headerForm', 'documentDetails'], false));
                  dispatch(changeField(['headerForm', 'byMonthsAccum'], false));
                  dispatch(changeField(['headerForm', 'deviationRelative'], false));
                  dispatch(changeField(['headerForm', 'absoluteRelative'], false));
                  if (v === 3) {
                    dispatch(processServerReq('VARIANT3'));
                  }
                  this.setState({ selectedReportTypes: v });
                }}
              />
            </GridBox>
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('byGroupKdb', false)}
              label={md.columns.byGroupKdb.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byGroupKdb'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('notDisplayYourRevenues', false)}
              label={md.columns.notDisplayYourRevenues.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'notDisplayYourRevenues'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('ownIncome', false)}
              label={md.columns.ownIncome.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'ownIncome'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('isSB', false)}
              label={md.columns.isSB.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'isSB'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byTreasureCode', false)}
              label={md.columns.byTreasureCode.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byTreasureCode'], v))}
            />
            {DocumentDetails}
            {ShowCurrentKDB}
          </div>
          <div>
            {ShowMonth}
          </div>
          <div>
            {ByMonthsAccum}
            {Relative}
          </div>
          <GridBox style={{ gridColumnGap: '2%', alignItems: 'end' }}>
            {Symbol0}
            {HideDisplay}
          </GridBox>
        </Grid>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportRevenueAnalysisEditor);
