import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Tab, Collapse, Card, Container, Row, Col, Modal, Spinner, Tabs,
} from 'react-bootstrap';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  faFileCode,
  faFileExcel,
  faFilePdf,
  faPlus,
  faPrint,
  faSave,
  faTimes,
  faImage,
  faPortrait,
  faPhotoVideo,
} from '@fortawesome/free-solid-svg-icons';
import { GenerateButton, SettingsButton } from '../../components/bootStrap/buttons';
import useReportEditor from './hooks';
import ReportSettingsEditor from './ReportSettingsEditor';
import Results from './results';
import { CPButton } from '../../components/bootStrap/buttons/styles';
import ParamsField from './reportParams';
import SaveReport from './saveReport';
import ContextMenu from './results/contextMenu';
import DetailsShower from './detailsShower';
import { RENDER_API_URL, REPORTS_API_URL } from './hooks/consts';
import { WinManagerContext } from '../../providers';
import IconAlert from '../../components/blanks/common/IconAlert';
import GetScreen from './getReportAvatar';
import FalconCloseButton from '../../components/blanks/common/CloseButton';
import PageHeader from '../../components/blanks/common/PageHeader';
import CardHeader from '../../components/blanks/common/CardHeader';
import { CommandPanel } from '../../components/bootStrap';

const SHOW_MODES = {
  results: 'RESULTS',
  settings: 'SETTINGS',
  saveNew: 'SAVENEW',
  save: 'SAVE',
};

const StyledDiv = styled.div`
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //box-shadow: #e0e0e0 5px 5px 5px;
  //padding: 1rem;
`;

const StyledSettingsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  //height: calc(100vh - var(--user-line-height) - var(--menu-line-height) - var(--windows-menu-line-height));
  overflow: hidden;
  flex: 1 1 auto;
`;

const StyledTabs = styled(Tabs)`
  &~.tab-content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
  &~.tab-content .tab-pane{
    overflow: hidden;
    height: 100%;
  }

`;
function StdReportEditor({
  reportId, reportUrl, renderReportUrl, url,
  hideGroups, hideColumnGroups, hideFilters, hideSelections, hideOrders, defaultSettingsPage,
  onClose,
}) {
  const {
    reportData, schema,
    availableGroups, groups, groupsHandlers,
    availableColumns, columns, columnsHandlers,
    availableSelections, selections, selectionsHandlers,
    availableOrders, orders, ordersHandlers,
    availableFilters, filters, filtersHandlers,
    params, availableParams, paramsHandlers,
    onGenerateReport, results, loading,
    onGenerateReportXLSX, onGenerateReportHTML, onGenerateReportPDF,
    onSaveReport, onAbortReport,
    error, onGetSettings, permissions,
  } = useReportEditor({ reportId, reportUrl, renderReportUrl });
  const { setWindowTitle } = useContext(WinManagerContext);
  useEffect(
    () => {
      if (reportData.name && url) setWindowTitle(url, reportData.name);
    },
    [reportData.name, url, setWindowTitle],
  );
  const [showMode, setShowMode] = useState(SHOW_MODES.results);
  const [updated, setUpdated] = useState(false);
  const [orientation, setOrientation] = useState(true);
  const [typeOfReport, setTypeOfReport] = useState('report');

  const containerHeaderRef = useRef(null);
  const resultRef = useRef(null);

  const canvasRef = useRef(null);
  const [showAvatarArea, setShowAvatarArea] = useState(false);

  const print = useCallback(
    () => {
      if (results && resultRef.current) {
        const el = resultRef.current.cloneNode(true);
        const newEl = document.body.appendChild(el);
        newEl.className = 'print-area';
        const PageStyle = document.createElement('style');
        if (!orientation) {
          PageStyle.innerHTML = '@page{size: landscape}';
        } else {
          PageStyle.innerHTML = '@page{size: portrait}';
        }

        document.body.appendChild(PageStyle);

        window.print();
        document.body.removeChild(PageStyle);
        document.body.removeChild(newEl);
      }
    },
    [orientation, results],
  );

  const getCapture = () => {
    html2canvas(document.querySelector('#capture')).then((canvas) => {
      canvasRef.current = canvas;
      setShowAvatarArea(true);
    });
  };

  function copyCanvasToClipboard(canvas, format = 'image/png', quality = 1) {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        const clipboardData = new ClipboardItem({ [blob.type]: blob });
        navigator.clipboard.write([clipboardData])
          .then(() => resolve())
          .catch((err) => reject(err));
      }, format, quality);
    });
  }

  // Example usage:

  const doAvatar = ({
    x1, y1, ratio, avatarHeight, canvasHeight,
  }) => {
    setShowAvatarArea(false);

    const canvasWidth = Math.round(canvasHeight * ratio);

    const newCanvas = document.createElement('canvas');
    newCanvas.width = canvasWidth;
    newCanvas.height = canvasHeight;
    newCanvas.style.devicePixelRatio = 5;

    const newCtx = newCanvas.getContext('2d');

    newCtx.drawImage(
      canvasRef.current,
      x1,
      y1,
      Math.round(avatarHeight * ratio),
      avatarHeight,
      0,
      0,
      canvasWidth,
      canvasHeight,
    );

    copyCanvasToClipboard(newCanvas)
      .then(() => console.log('Copied to clipboard!'))
      .catch((err) => {
        console.error('Failed to copy:', err);
      });

    // image/jpeg - якість така ж як і у image/png, а розмір трохи більше
    // reportData.avatarImg = newCanvas.toDataURL('image/jpeg', 1);
    reportData.avatarImg = newCanvas.toDataURL('image/png');
  };

  const [contextMenu, setContextMenu] = useState({
    opened: false,
    x: 0,
    y: 0,
    detail: null,
    target: null,
    allowedDetails: [],
    groupsValues: {},
  });

  const onContextMenu = useCallback(
    (e, contextGroups, contextData) => {
      const knowFields = new Set([
        ...Object.keys(contextGroups.row),
        ...Object.keys(contextGroups.column),
      ]);
      const currentField = [...knowFields]
        .filter((kf) => kf in schema.src.meta_fields
          && schema.src.meta_fields[kf].key in contextData)
        .reduce((R, r) => ({
          ...schema.src.meta_fields[r],
          name: r,
        }), null);
      if (!currentField) return false;
      const usedGroups = new Set([
        ...groups.reduce((R, sg) => [...R, ...(sg.items.map((g) => g.name))], []),
        ...columns.reduce((R, sc) => [...R, ...(sc.items.map((c) => c.name))], []),
      ]);
      const allowedGroups = availableGroups.filter((a) => !usedGroups.has(a.name));

      setContextMenu({
        opened: true,
        x: e.clientX,
        y: e.clientY,
        target: e.target,
        detail: {
          currentItem: {
            backend: currentField.resource,
            key: currentField.key,
            name: currentField.name,
            id: contextData[currentField.key],
            repr: contextData.repr,
          },
        },
        allowedDetails: allowedGroups,
        groupsValues: { ...contextGroups.row, ...contextGroups.column },
      });
      e.preventDefault();
      return false;
    },
    [schema, groups, columns, availableGroups],
  );

  const [detail, setDetail] = useState({
    settings: {},
    opened: false,
  });
  const onDetail = useCallback(
    (e, group) => {
      const oldSettings = onGetSettings();
      const newFIlters = [
        ...oldSettings.filters,
        ...Object.keys(contextMenu.groupsValues)
          .map((k) => [k, '=', { id: contextMenu.groupsValues[k].value, repr: contextMenu.groupsValues[k].repr }, true]),
      ];
      const newGroups = [...oldSettings.groups, [{ key: group.name, hierarchy: false }]];
      setDetail({
        settings: {
          ...oldSettings,
          groups: newGroups,
          filters: newFIlters,
        },
        opened: true,
      });
      setContextMenu(({ ...r }) => ({
        ...r,
        opened: false,
      }));
    },
    [contextMenu.groupsValues, onGetSettings],
  );
  const handleGenerateReport = useCallback(() => {
    if (typeOfReport === 'Excel') {
      return onGenerateReportXLSX.onAbortReport();
    }
    if (typeOfReport === 'HTML') {
      return onGenerateReportHTML.onAbortReport();
    }
    if (typeOfReport === 'PDF') {
      return onGenerateReportPDF.onAbortReport();
    }
    return onAbortReport();
  }, [
    onAbortReport,
    onGenerateReportHTML,
    onGenerateReportPDF,
    onGenerateReportXLSX,
    typeOfReport,
  ]);
  return (
    <div className="h-100 d-flex flex-column">
      <PageHeader
        className="mb-3"
      >
        <CardHeader
          title={reportData.repr || 'Завантаження...'}
          className="cardPadding"
          light={false}
          endEl={(
            <CommandPanel
              className="me-1 ms-auto "
              rtl
              leftPart={(
                <>
                  <GenerateButton
                    onClick={() => {
                      setTypeOfReport('report');
                      onGenerateReport();
                      setShowMode(SHOW_MODES.results);
                      setUpdated(true);
                    }}
                    content="Сформувати звіт"
                  />
                  <CPButton
                    title={`Орієнтація сторінки: ${orientation ? 'Портерна' : 'Ландшафтна'}`}
                    icon={orientation ? faPortrait : faImage}
                    onClick={() => setOrientation((o) => !o)}
                  />
                  <SettingsButton
                    disabled={!permissions.filters && !permissions.fields
                && !permissions.orders && !permissions.groups && !permissions.columns}
                    onClick={() => {
                      setShowMode(
                        (sm) => (
                          sm === SHOW_MODES.settings ? SHOW_MODES.results : SHOW_MODES.settings
                        ),
                      );
                    }}
                  />
                  <CPButton
                    onClick={() => print()}
                    title="Друк"
                    icon={faPrint}
                  />
                  <CPButton
                    onClick={() => {
                      setTypeOfReport('Excel');
                      onGenerateReportXLSX.onGenerateReport();
                    }}
              // disabled={!results || showMode !== SHOW_MODES.results}
                    title="Зберегти в Excel"
                    icon={faFileExcel}
                  />
                  <CPButton
                    onClick={() => {
                      setTypeOfReport('HTML');
                      onGenerateReportHTML.onGenerateReport();
                    }}
              // disabled={!results || showMode !== SHOW_MODES.results}
                    title="Зберегти в HTML"
                    icon={faFileCode}
                  />
                  <CPButton
                    onClick={() => {
                      setTypeOfReport('PDF');
                      onGenerateReportPDF.onGenerateReport();
                    }}
              // disabled={!results || showMode !== SHOW_MODES.results}
                    title="Зберегти в PDF"
                    icon={faFilePdf}
                  />
                  <CPButton
                    onClick={() => {
                      setShowMode(SHOW_MODES.save);
                    }}
                    title="Зберегти звіт"
                    icon={faSave}
                  />
                  <CPButton
                    onClick={() => {
                      setShowMode(SHOW_MODES.saveNew);
                    }}
                    title="Створити новий звіт"
                    icon={faPlus}
                  />
                  <CPButton
                    onClick={() => {
                      getCapture();
                    }}
                    title="Зробити аватарку для варіанту звіту"
                    icon={faPhotoVideo}
                  />
                </>
        )}
            />
          )}
          role="menubar"
        />
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => !!onClose && onClose()}
            title="Закрити"
          />
        </div>
      </PageHeader>
      {error && (
        <IconAlert variant="danger">
          {error}
        </IconAlert>
      )}
      <DndProvider backend={HTML5Backend}>
        <div ref={containerHeaderRef}>
          <Container fluid className="mb-2">
            <Row xl={6} md={3} xs={1}>
              {availableParams && availableParams.map((param) => (
                <Col key={param.name}>
                  <ParamsField
                    param={param}
                    currentParam={params[param.name]}
                    changeParam={paramsHandlers.changeParams}
                    readOnly={!permissions.params}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <Collapse in={showMode === SHOW_MODES.save} appear mountOnEnter>
          <StyledSettingsCard>
            <Card.Body className="p-0 m-0">
              <Card.Header className="cardHeader">Зберегти звіт</Card.Header>
              <SaveReport
                onHide={() => setShowMode(SHOW_MODES.results)}
                reportData={reportData}
                onSave={onSaveReport}
              />
            </Card.Body>
          </StyledSettingsCard>
        </Collapse>
        <Collapse in={showMode === SHOW_MODES.saveNew} appear mountOnEnter>
          <StyledSettingsCard>
            <Card.Body className="p-0 m-0">
              <Card.Header className="cardHeader">Створити новий звіт</Card.Header>
              <SaveReport
                onHide={() => setShowMode(SHOW_MODES.results)}
                isNew
                onSave={onSaveReport}
                reportData={reportData}
                ё
              />
            </Card.Body>
          </StyledSettingsCard>
        </Collapse>
        <Collapse in={showMode === SHOW_MODES.settings} appear>
          <StyledSettingsCard className="border-0 h-100 overflow-hidden">
            <Card.Body className="p-0 m-0 d-flex flex-column overflow-hidden">
              <StyledTabs defaultActiveKey={defaultSettingsPage} className="m-0">
                {!hideGroups && permissions.groups && (
                  <Tab title="Групування" eventKey="groups">
                    <ReportSettingsEditor.GroupEditor
                      availableGroups={availableGroups}
                      groups={groups}
                      groupsHandlers={groupsHandlers}
                    />
                  </Tab>
                )}
                {!hideColumnGroups && permissions.columns && (
                  <Tab title="Групування колонок" eventKey="columns">
                    <ReportSettingsEditor.ColumnsEditor
                      availableColumns={availableColumns}
                      columns={columns}
                      columnsHandlers={columnsHandlers}
                    />
                  </Tab>
                )}
                {!hideSelections && permissions.fields && (
                  <Tab title="Обрані поля" eventKey="selection">
                    <ReportSettingsEditor.SelectionEditor
                      availableSelections={availableSelections}
                      selections={selections}
                      selectionHandlers={selectionsHandlers}
                    />
                  </Tab>
                )}
                {!hideOrders && permissions.orders && (
                  <Tab title="Сортування" eventKey="orders">
                    <ReportSettingsEditor.OrdersEditor
                      availableOrders={availableOrders}
                      orders={orders}
                      ordersHandlers={ordersHandlers}
                    />
                  </Tab>
                )}
                {!hideFilters && permissions.filters && (
                  <Tab title="Фільтрування" eventKey="filters">
                    <ReportSettingsEditor.FiltersEditor
                      availableFilters={availableFilters}
                      filters={filters}
                      filtersHandlers={filtersHandlers}
                    />
                  </Tab>
                )}
              </StyledTabs>
            </Card.Body>
          </StyledSettingsCard>
        </Collapse>
      </DndProvider>
      {showAvatarArea ? (
      // Визначаємо розмір аватарки (іконка варіанту звіту):
      // Висота 220, Ширина у "ratio" разів більша від висоти

        <GetScreen
          doAvatar={doAvatar}
          ratio={1.25}
          canvasHeight={220}
        />
      ) : null}
      {loading && updated && (
      <StyledDiv className="border position-absolute d-flex align-items-center justify-content-end bg-white shadow-lg p-3">
        <Spinner animation="border" className="me-3 text-dark" />
        <div>
          <h6 className="text-muted">Треба трохи зачекати, але воно того варте...</h6>
          <Button
            variant="falcon-danger"
            size="sm"
            onClick={handleGenerateReport}
          >
            <FontAwesomeIcon icon={faTimes} className=" me-1" />
            Скасувати запит
          </Button>
        </div>
      </StyledDiv>
      )}

      {results && showMode === SHOW_MODES.results && (
      <div className="border-minfin border d-flex rounded p-3 bg-white">
        <Results
          result={results}
          ref={resultRef}
          caption={reportData.name}
          onContextMenu={onContextMenu}
        />
        <ContextMenu
          top={contextMenu.y}
          left={contextMenu.x}
          allowedColumns={[]}
          onCloseMenu={() => setContextMenu(({ ...r }) => ({
            ...r,
            opened: false,
          }))}
          detail={contextMenu.detail}
          allowedDetails={contextMenu.allowedDetails}
          onDetailProcessing={onDetail}
          opened={contextMenu.opened}
          target={contextMenu.target}
        />
      </div>

      )}
      {!results && showMode === SHOW_MODES.results && (
        <div className="border-minfin border d-flex rounded p-3 bg-white">
          <div className="text-muted py-2 px-4">
            <h3>Звіт не сформовано</h3>
            <p>
              <span className="me-2">
                Задайте параметри звіту, та натисніть кнопку
              </span>
              <GenerateButton
                onClick={() => {
                  setTypeOfReport('report');
                  onGenerateReport();
                  setShowMode(SHOW_MODES.results);
                  setUpdated(true);
                }}
                content="Сформувати звіт"
              />

            </p>
          </div>
        </div>
      )}
      <Modal
        show={detail.opened}
        onHide={() => setDetail((r) => ({ ...r, opened: false }))}
        size="xl"
        className="w-100"
        dialogClassName="min-vw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <h3 className="text-primary">Розшифровка</h3>
        </Modal.Header>
        <Modal.Body>
          <DetailsShower
            reportId={reportId}
            renderReportUrl={renderReportUrl}
            settings={detail.settings}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

StdReportEditor.propTypes = {
  reportId: PropTypes.string,
  reportUrl: PropTypes.oneOf([REPORTS_API_URL]),
  renderReportUrl: PropTypes.oneOf([RENDER_API_URL]),
  hideGroups: PropTypes.bool,
  hideColumnGroups: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hideSelections: PropTypes.bool,
  hideOrders: PropTypes.bool,
  defaultSettingsPage: PropTypes.oneOf(['groups', 'columns', 'selection', 'orders', 'filters']),
  url: PropTypes.string,
  onClose: PropTypes.func,
};

StdReportEditor.defaultProps = {
  reportId: null,
  reportUrl: REPORTS_API_URL,
  renderReportUrl: RENDER_API_URL,
  hideGroups: false,
  hideColumnGroups: false,
  hideFilters: false,
  hideSelections: false,
  hideOrders: false,
  defaultSettingsPage: 'groups',
  url: null,
  onClose: null,
};

export default StdReportEditor;
