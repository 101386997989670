/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Tab, Table, Tabs,
} from 'react-bootstrap';
import classNames from 'classnames';
import TPCommandPanel from './commandPanel';
import { StyledTableHeaderCell } from '../../../newEditor/tablePart/styles';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { TableControls } from '../../../basicEditor/tableControls';
import { importDocs } from '../../../../meta/srv';

function DocsList({
  data, selectedIDs, setSelectedIDs,
}) {
  const includingId = (id) => (
    Object.values(selectedIDs).reduce(
      (acc, currentValue) => acc.concat(currentValue),
      [],
    ).includes(id)
  );

  const handleClick = (row, doc) => {
    if (!(row.from_db || row.loaded)) {
      if (includingId(row.id)) {
        const changedIdList = selectedIDs[doc].filter((id) => id !== row.id);
        setSelectedIDs((prevState) => ({
          ...prevState,
          [doc]: changedIdList,
        }));
      } else {
        setSelectedIDs((prevState) => ({
          ...prevState,
          [doc]: doc in prevState ? [...prevState[doc], row.id] : [row.id],
        }));
      }
    }
  };
  const getClassName = (row) => {
    if (row.from_db) {
      return 'bg-soft-warning';
    }
    if (row.loaded) {
      return 'bg-soft-dark';
    }
    if (includingId(row.id)) {
      return 'bg-soft-success';
    }
    return '';
  };

  return (
    <Card className="border-0">
      <TPCommandPanel
        tableData={data}
        selectedIDs={selectedIDs}
        setSelectedIDs={setSelectedIDs}
      />
      {!data || Object.keys(data).length === 0 ? (
        <IconAlert variant="info" className="my-2 mx-1">
          Не знайдено жодного документу
        </IconAlert>
      ) : (
        <Card.Body className="p-0">
          <Tabs defaultActiveKey={Object.keys(data)[0]}>
            {Object.entries(data).map(([key, value]) => (
              <Tab eventKey={key} title={`${importDocs[key].name} (${value.length})`}>
                <Table bordered hover responsive size="sm">
                  <thead>
                    <tr key="numericTrHeader">
                      <th
                        key="numericThHeader-number"
                        rowSpan={importDocs[key].tableLabels.length}
                        style={{ padding: '5px', width: '0' }}
                      >
                        №
                      </th>
                      {Object.keys(value[0]).map((row, idxTr) => (
                        (row in importDocs[key].tableLabels) && (
                        <th
                          className={classNames(
                            'p-1 gap-1',
                          )}
                          key={`numericThHeader-${idxTr}`}
                        >
                          <StyledTableHeaderCell>
                            {importDocs[key].tableLabels[row]}
                          </StyledTableHeaderCell>
                        </th>
                        )
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {value.map((row, idx) => (
                      <tr
                        data-row={idx}
                        key={`trRow-${idx}`}
                        onClick={() => handleClick(row, key)}
                        className={getClassName(row)}
                      >
                        <td key={`numericThRow-${idx}`}>
                          {idx + 1}
                        </td>
                        { Object.entries(row).map(([k, v]) => (
                          (k in importDocs[key].tableLabels) && (
                            ((k === 'actual') && (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <td key={`${k}-${idx}`}>
                              <TableControls.CheckboxInput value={v} disabled />
                            </td>
                            )) || (
                            <td
                              key={`${k}-${idx}`}
                            >
                              {k === 'add_date' ? (new Date(v)).toLocaleString() : v}
                            </td>
                            )
                          )))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
            ))}
          </Tabs>
        </Card.Body>
      )}
    </Card>
  );
}

DocsList.propTypes = {
  data: PropTypes.shape({}).isRequired,
  selectedIDs: PropTypes.shape(PropTypes.arrayOf(PropTypes.string)),
  setSelectedIDs: PropTypes.func.isRequired,
};

DocsList.defaultProps = {
  selectedIDs: {},
};

export default DocsList;
