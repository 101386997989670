import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  notes: { label: 'Коментар', name: 'Комментарий', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  account: { label: 'Банківський рахунок розпорядника', name: 'БанковскийСчет', visible: false },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: false },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: false },
  beginDate: { label: 'Дата початку', name: 'ДатаС', visible: false },
  endDate: { label: 'Дата закінчення', name: 'ДатаПо', visible: false },
  sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  registrType: { label: 'Тип реєстру', name: 'ТипРеестра', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  rNo: { label: 'Реєстраційний номер', name: 'РегНомер', visible: true },
  // Дополнитльеные реквизиты
  files: { label: 'Додані файли', name: 'Файлы' },
};

/**
 * @const
 */
const tables = {
  consist: {
    name: 'СоставРеестра',
    label: 'Склад реєстру',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      doc: { label: 'Документ', name: 'Документ' },
      sum: { label: 'Сума', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Реєстр документів',
  name: 'docRegister',
  backendName: 'РеестрДокументов',
  columns,
  frontend: 'doc/docRegister',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'rNo',
      'sum',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
