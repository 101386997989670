import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/assigmentPlaning';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ThreeColumnsGridContainer,
  SevenColumnsGridContainer,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
  ResultSegment,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import Tabs from '../../../components/tabs/Tabs';
import ItemField from '../../field/selectorField/item/itemField';
import ReportContainer from '../reportContainer';
import DocCreationModal from './docCreationModal';

const fundKEKVTypes = {
  none: { label: 'Не враховувати', name: '' },
  ...enums.FundKEKVKInds,
};

// eslint-disable-next-line max-len
const fundKEKVOptions = Object.values(fundKEKVTypes).map((_enum) => ({ label: _enum.label, value: _enum.name }));

const StringInput4 = Fields.GetTextInput(4);

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();
  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());

  const isProcessing = state.get('isProcessing', true);
  const FO = headerForm.get('FO', new Map());

  const filterChoice = useMemo(() => new Map()
    .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }]), [FO]);

  const isConsolidated = headerForm.get('consolidated', false);

  const BudgetLabel = sessionOptions.get('is_admin', false) ? (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    </div>
  ) : (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    </div>
  );

  const SettingsButton = (
    <div>
      <div>
        <ThreeColumnsGridContainer>
          <div>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            <Fields.SelectorField
              value={FO}
              disabled={isConsolidated}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'FO'], v));
                dispatch(changeField(['headerForm', 'signVariant'], new Map()));
              }}
            />
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'consolidated'], v));
                // dispatch(processServerReq('CHANGE_CONSOLIDATED'));
              }}
            />
            <Fields.CheckboxField
              label={md.columns.byHighLevelCSU.label}
              value={headerForm.get('byHighLevelCSU', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'byHighLevelCSU'], v));
                if (v) {
                  dispatch(changeField(['headerForm', 'bySubLevelCSU'], false));
                }
              }}
            />
            <Fields.CheckboxField
              label={md.columns.bySubLevelCSU.label}
              value={headerForm.get('bySubLevelCSU', false)}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'bySubLevelCSU'], v));
                if (v) {
                  dispatch(changeField(['headerForm', 'byHighLevelCSU'], false));
                }
              }}
            />
            {headerForm.get('bySubLevelCSU', false) ? (
              <div>
                <Fields.CheckboxField
                  label={md.columns.includeAllChldren.label}
                  value={headerForm.get('includeAllChldren', false)}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'includeAllChldren'], v))}
                />
                <Fields.CheckboxField
                  label={md.columns.bySublevel.label}
                  value={headerForm.get('bySublevel', false)}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'bySublevel'], v))}
                />
              </div>

            ) : null}
          </div>
        </ThreeColumnsGridContainer>
        <SevenColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.fundsType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('fundsType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'fundsType'], v))}
              values={Object.values(fundKEKVOptions)}
              name="fundsType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={
                Object.values(enums.PlaningKind)
                  .map((_e) => ({ label: _e.label, value: _e.name }))
              }
              name="planType"
            />
            <Fields.CheckboxField
              value={headerForm.get('incDB', false)}
              label={md.columns.incDB.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'incDB'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('byMonths', false)}
              label={md.columns.byMonths.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byMonths'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byReducedKEKV', false)}
              label={md.columns.byReducedKEKV.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byReducedKEKV'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byMonthsAccum', false)}
              label={md.columns.byMonthsAccum.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byMonthsAccum'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('extract', false)}
              label={md.columns.extract.label}
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'extract'], v));
                if (v) {
                  dispatch(changeField(['headerForm', 'planOfUse'], false));
                }
              }}
            />
            {/* <Fields.CheckboxField */}
            {/*  value={headerForm.get('planOfUse', false)} */}
            {/*  label={md.columns.planOfUse.label} */}
            {/*  onChange={(e, v) => { */}
            {/*    dispatch(changeField(['headerForm', 'planOfUse'], v)); */}
            {/*    if (v) { */}
            {/*      dispatch(changeField(['headerForm', 'extract'], false)); */}
            {/*      dispatch(changeField(['headerForm', 'byMonths'], true)); */}
            {/*    } */}
            {/*  }} */}
            {/* /> */}
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('in1000', false)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('approved', false)}
              label={md.columns.approved.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('printReportParams', false)}
              label={md.columns.printReportParams.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'printReportParams'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('printApproved', false)}
              label={md.columns.printApproved.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'printApproved'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('showColonets', false)}
              label={md.columns.showColonets.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showColonets'], v))}
            />
            <br />
            <StyledLabel>{md.columns.symbol0.label}</StyledLabel>
            <StringInput4
              radius
              background
              textLeft
              value={headerForm.get('symbol0', '')}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'symbol0'], v))}
            />
          </div>
          <div>
            {headerForm.get('showColonets', false) ? (
              <Fields.CheckboxField
                value={headerForm.get('printPageNo', false)}
                label={md.columns.printPageNo.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'printPageNo'], v))}
              />
            ) : null}
            {headerForm.get('showColonets', false) ? (
              <Fields.CheckboxField
                value={headerForm.get('printDate', false)}
                label={md.columns.printDate.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'printDate'], v))}
              />
            ) : null}
            <Fields.CheckboxField
              value={headerForm.get('showClassifiersName', false)}
              label={md.columns.showClassifiersName.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showClassifiersName'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('showCSUNames', false)}
              label={md.columns.showCSUNames.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUNames'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('separateColumn', false)}
              label={md.columns.separateColumn.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'separateColumn'], v))}
            />
          </div>
        </SevenColumnsGridContainer>
      </div>
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter
              filterChoice={filterChoice}
            />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
        {!!headerForm.get('byHighLevelCSU', new Map()) && (
          <DocCreationModal
            headerForm={headerForm}
          />
        )}
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
