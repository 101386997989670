import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import TableComponents from './ExportTP';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'export';

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
  FI: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, 'headerForm', 'FI']),
});

const TP = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
);

export default connect(mapState)(TP);
