import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_FIELD_STATE = {
  mandatory: false,
  ctype: null,
  label: '',
  key: false,
  calc: null,
  filtered: [],
  ordered: false,
  having: null,
  hidden: false,
  table: '',
  expression: '',
  alias: '',
  hierarchical_key: null,
};

export const useFields = (tableFields) => {
  const [fields, setFields] = useState([]);

  const [activeField, setActiveField] = useState(null);

  const onAddField = useCallback(
    (name) => setFields((oldFields) => {
      if (oldFields.filter((of) => of.name === name).length) return oldFields;
      return [...oldFields, { name, ...DEFAULT_FIELD_STATE }];
    }),
    [],
  );

  const onRemoveField = useCallback(
    (fName) => {
      setFields((oldFields) => oldFields.filter((of) => of.name !== fName));
      if (activeField === fName) setActiveField(null);
    },
    [activeField],
  );

  const onRenameField = useCallback(
    (oldName, newName) => {
      if (!newName) return false; // Имя таблицы must have
      setFields((oldFields) => {
        if (oldFields
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldFields;
        return oldFields.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeField === oldName) setActiveField(newName);
      return true;
    },
    [activeField],
  );

  const onChangeField = useCallback(
    (tableName, value) => {
      setFields((oldFields) => oldFields
        .map((of) => (of.name !== tableName ? of : { ...of, ...value })));
    },
    [],
  );

  const fillFields = useCallback(
    () => {
      setFields(Object.keys(tableFields).reduce((R, tName) => [
        ...R,
        ...tableFields[tName].map((fName) => ({
          ...DEFAULT_FIELD_STATE,
          name: tName === 'main' ? fName : `${tName}_${fName}`,
          table: tName,
          expression: fName,
          alias: fName,
        })),
      ], []));
    },
    [tableFields],
  );

  const fieldHandlers = useMemo(
    () => ({
      onAddField,
      onRenameField,
      onRemoveField,
      onChangeField,
      setActiveField,
      fillFields,
    }),
    [fillFields, onAddField, onChangeField, onRemoveField, onRenameField],
  );

  return {
    fields, setFields, activeField, fieldHandlers,
  };
};

export const fieldPropType = PropTypes.shape({
  name: PropTypes.string,
  mandatory: PropTypes.bool,
  ctype: PropTypes.string,
  label: PropTypes.string,
  key: PropTypes.bool,
  calc: PropTypes.string,
  filtered: PropTypes.arrayOf(PropTypes.string),
  ordered: PropTypes.bool,
  having: PropTypes.bool,
  hidden: PropTypes.bool,
  table: PropTypes.string,
  expression: PropTypes.string,
  alias: PropTypes.string,
  hierarchical_key: PropTypes.string,
});
