import { baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  SignVariant: { label: 'Варіант підпису', name: 'Подпись' },
  signVariantLabel: { label: 'ПодписьТекст', name: 'ПодписьТекст' },
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  LogicaDoc: { label: 'Документ Logica', name: 'ДокОтправкаВЛогику' },
  // Дполнительные свойств
  DocApproved: { label: 'Документ Затверджено', name: 'ДокументУтвержден' },
  signApprooved: { label: 'ПодписьСогласовано', name: 'ПодписьСогласовано' },
  signMFO: { label: 'ЕстьПодписиМФО', name: 'ЕстьПодписиМФО' },
  existDocSendToLogica: { label: 'ЕстьДокументОтправки', name: 'ЕстьДокументОтправки' },
};

const tables = {
};

/**
 * @const
 */
const definition = {
  name: 'expensesOnEducationPrinting',
  backendName: 'ПечатьИнформацияОСреднемОбразовании',
  label: 'Друк',
  frontend: 'dp/expensesOnEducationPrinting',
  columns,
  tables,
  resultColumns: baseResultColumns,
};

export default definition;
