import meta from '../../../meta';
import catListersSelectors from '../individual/cat';
import docListersSelectors from '../individual/doc';
import ListerFactory from '../../newLister';
import { CatTableListerFactory, DocTableListerFactory } from '../../newLister/tableComponent';

/*

В случае добавления особенного листера
необходимо его вклбючить сюда

 */

const catListers = Object.keys(catListersSelectors)
  .filter((c) => !!catListersSelectors[c].lister)
  .reduce((R, c) => ({ ...R, [c]: catListersSelectors[c].lister }), {});

const docListers = Object.keys(docListersSelectors)
  .filter((d) => !!docListersSelectors[d].lister)
  .reduce((R, d) => ({ ...R, [d]: docListersSelectors[d].lister }), {});

const Listers = {
  cat: Object.keys(meta.cat)
    .filter((mName) => !Object.keys(catListers).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.cat[mName].backendURL,
        viewType: 'lister',
      })(CatTableListerFactory()),
    }), catListers),
  doc: Object.keys(meta.doc)
    .filter((mName) => !Object.keys(docListers).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.doc[mName].backendURL,
        viewType: 'lister',
      })(DocTableListerFactory()),
    }), docListers),
};

export default Listers;
