import { Card, CardGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../../../../components/blanks/common/IconButton';
import { moneysStingOptions } from '../../../../../../constants/moneys';
import formatDate from './dateFormat';
import md from '../../../../../../meta/doc/budgetPassport';

function DocCard({ items, onToggleApproveDoc }) {
  return (
    <CardGroup>
      {Array.isArray(items) && items.map((row, idx) => (
        <Card key={row.id}>
          <Card.Body>
            <Card.Title className="d-flex">
              <Link to={`${md.frontendURL}${row.document.id}`}>
                {row.document.repr}
                <h6>{row.document.amount_total.toLocaleString('uk', moneysStingOptions)}</h6>
              </Link>
              <div className="ms-auto">
                <IconButton
                  variant={(row.blocked)
                    ? 'success' : 'outline-secondary'}
                  onClick={(e) => onToggleApproveDoc(e, idx)}
                  icon={row.blocked ? faSquareCheck : faSquare}
                />
              </div>
            </Card.Title>
            <Card.Text>
              <hr />
              <FontAwesomeIcon icon={faBuildingColumns} className="mx-2" />
              {row.document.authority.repr}
            </Card.Text>
          </Card.Body>
          <Card.Footer className="d-flex _justify-content-between text-muted">
            <h6 className="mb-0 text-600 lh-base align-items-center">
              <FontAwesomeIcon
                icon={faClock}
                className="me-1"
              />
              {formatDate(row.document.doc_date)}
            </h6>
            <div className="ms-auto">
              <Card.Text className="text-end">
                <FontAwesomeIcon icon={faUser} className="mx-2" />
                {row.document.author.repr}
              </Card.Text>
            </div>
          </Card.Footer>
        </Card>
      ))}
    </CardGroup>
  );
}

DocCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onToggleApproveDoc: PropTypes.func.isRequired,
};

DocCard.defaultProps = {
  items: [],
};

export default DocCard;
