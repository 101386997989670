import React from 'react';
// import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/localDebt';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from '../../expensesOnHealth/editor/docLoading/docSelectionModal';
import { ContainerSt } from '../../../../components/Form/styledForm';
import { StampApprovedJs } from '../../../../assets/icons';

function LocalDebt({
  data, actions, permissions,
}) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = !permissions.canChange;

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <EditorControls.ItemPicker */}
      {/*      label={md.columns.csu.label} */}
      {/*      value={data[md.columns.csu.name]} */}
      {/*      onChange={(e, value) => { */}
      {/*        actions.onChange({ */}
      {/*          [md.columns.csu.name]: value, */}
      {/*        }); */}
      {/*      }} */}
      {/*      modelType="cat" */}
      {/*      modelName="csu" */}
      {/*      filter={FIFilter} */}
      {/*      readOnly={readOnly} */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
            {data[md.columns.isApproved.name] && sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.invoice.label}
                value={data[md.columns.invoice.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.invoice.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.invoice.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
    </EditorCollapse>
  );
}

LocalDebt.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LocalDebt;
