import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 60deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, -60deg);
  }
`;
const Img = styled(FontAwesomeIcon)`
  color: var(--falcon-warningIcon);
  transition: all 4s ease;
  animation: ${rotate} 1.5s linear infinite;
  margin-right: 10px;
`;

function WarningMessage({
  header, text, actions,
}) {
  return (
    <Alert variant="warning">
      <div style={{
        display: 'flex',
        alignItems: 'center',
        color: 'var(--falcon-warningText)',
      }}
      >
        <Img
          icon={faExclamationTriangle}
          size="4x"
        />
        <div>
          <Alert.Heading style={{ fontWeight: '700' }}>{header}</Alert.Heading>
          <p>
            {text}
          </p>
        </div>
      </div>
      {actions
          && (
            <div>
              <hr />
              <div className="d-flex justify-content-end">
                {actions}
              </div>
            </div>
          )}
    </Alert>
  );
}

WarningMessage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

WarningMessage.defaultProps = {
  header: 'Вам все вдалося, але виник несподіваний результат',
  text: 'Та ще й розробники забули щось тут написати',
  actions: null,
};

export default WarningMessage;
