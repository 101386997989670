/**
 * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
 * Она сработает только один раз через N миллисекунд после последнего вызова.
 * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
 * первого запуска.
 * @param {function} fn - Функция которая выполнится через указанное время
 * @param {number} delay - указанное время
 * @return {function}

 */

export const debounce = (fn, delay = 500) => {
  let timer = null;
  return (...args) => {
    // const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

/**
 * Преобразщовывает дату в строку без учета timezone
 * @param {date} date - Дата
 * @return {string}

 */
export const dateToString = (date) => {
  const Year = `${'0000'.slice(0, 4 - date.getFullYear().toString().length)}${date.getFullYear()}`;
  return `${Year}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * Устанавливаент свойство displayName для компонента, обернутого в HOC
 * @param WrappedComponent
 * @return {*|string}
 */
export const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || WrappedComponent.type || 'Component'
);

/**
 * Дополняеть строку указанным символом слева
 * @param str {string}
 * @param symbol {string}
 * @param length {int}
 * @return {string}
 */
export const padl = (str = '', symbol = '0', length = 10) => symbol.repeat(length).concat(str).slice(-length);

/**
 * Скачать файл
 * @param jsonData - base64 данные файла. Без всяки фляциков
 * @param filename
 */
export const downloadFileFromJSON = (jsonData, filename, mimeType = '') => {
  const content = atob(jsonData);
  const byteNumbers = new Array(content.length);
  for (let i = 0; i < content.length; i += 1) {
    byteNumbers[i] = content.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  const child = document.body.appendChild(a);
  a.click();
  document.body.removeChild(child);
};

/**
 * Reads file as text
 * @param file {Blob}
 * @returns {Promise<unknown>}
 */
export const readFileAsTextAsync = (file) => new Promise((resolve, reject) => {
  const fr = new FileReader();
  fr.onload = () => resolve(fr.result);
  fr.onerror = (e) => reject(e);
  fr.readAsText(file);
});

export const UID_RE = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

