import base from './base';

import DocumentStornoReportTypes from '../enums/documentStornoReportTypes';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  notes: { label: 'Примітка', name: 'Комментарий', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  account: { label: 'Банківський рахунок розпорядника', name: 'БанковскийСчет', visible: true },
  client: { label: 'Постачальник', name: 'Контрагент', visible: true },
  contractNo: { label: 'Номер договору', name: 'НомерДоговора', visible: true },
  contractStartDate: { label: 'Початок дії договору', name: 'ДатаДоговора', visible: false },
  contractEndDate: { label: 'Кінець дії договору', name: 'ДатаДоговора1', visible: false },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: true },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  isApproved: {
    label: 'Отримано',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  // documentKind: { label: 'Вид документу', name: 'ВидДокумента', visible: true },
  documentKind: {
    label: 'Вид документу',
    name: 'ВидДокумента',
    visible: true,
    type: 'variants',
    variants: Object.values(DocumentStornoReportTypes).reduce((R, v) => (
      { ...R, [v.name]: v.label }), {}),
  },
  reason: { label: 'Підстава', name: 'Основание', visible: false },
  contractTime: { label: 'Строк дії договору', name: 'СрокДействияДоговора', visible: false },
  procurementProc: { label: 'Процедура закупівель', name: 'ПроцедураЗакупок', visible: false },
  procurementRes: { label: 'Результати проведення закупівлі', name: 'РезультатЗакупки', visible: false },
  procPROZORRO: { label: 'Номер процедури закупок у системі ПРОЗОРО', name: 'ПроцедураЗакупокПРОЗОРО', visible: false },
  rNo: { label: 'Реєстраційний номер', name: 'РегНомер', visible: true },
  KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ', visible: false },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  // Дополнитльеные реквизиты
  sumFin: {
    label: 'Сума фін. зобов.', name: 'ФинансовыеОбязательства', visible: true, type: 'moneys',
  },
  elememtKDBVisible: { label: 'Джерело фінансування', name: 'ДанныеОбязательстваЭлементКДБВидимость', visible: false },
  files: { label: 'Додані файли', name: 'Файлы' },
  activeObjectsBuilding: { label: 'Об\'єкт будівництва/ремонту', name: 'флОбъектыСтроительства' },
  typesRepair: { label: 'соотвВидРем', name: 'соотвВидРем' },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  codesKEKV: { label: 'КЕКВ', name: 'КодыКЕКВ', visible: true },
  isAccountHighLevelCSU: { label: 'Використовувати казначейські рахунки розпорядника вищого рівня', name: 'флКазнСчетаРаспВерхнегоУр' },
  accountingByClients: {
    label: 'УчетПоКонтрагентам',
    name: 'УчетПоКонтрагентам',
  },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ДанныеОбязательства',
    label: 'Зобов\'язання',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      KEKVCode: { label: 'Код КЕКВ', name: 'КодКЕКВ' },
      elementKDB: { label: 'Джерело фін.', name: 'ЭлементКДБ' },
      buildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      sum: { label: 'Сума', name: 'Сумма' },
      prepaid: { label: 'в т.ч. аванс', name: 'Аванс' },
      notes: { label: 'Примітка', name: 'Комментарий' },
    },
  },
  presentedDocs: {
    name: 'СписокДокументов',
    label: 'Документи, що додаються',
    columns: {
      use: { label: '', name: 'Метка' },
      doc: { label: 'Документ, що додається', name: 'ПредьявляемыйДокумент' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Юридичне зобов\'язання',
  name: 'legalObligation',
  backendName: 'ЮридическоеОбязательство',
  columns,
  frontend: 'doc/legalObligation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'rNo',
      'contractNo',
      'documentKind',
      'account',
      'client',
      'codesKEKV',
      'isApproved',
      'elementKFK',
      'fondObject',
      'sum',
      'sumFin',
      'appr',
      'author',
      'FI',
      'notes',
      'files',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
