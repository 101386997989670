const authActions = {
  login: '@AUTH/login',
  logout: '@AUTH/logout',
  refresh: '@AUTH/refresh',
  logikaLogin: '@AUTH/logikaLogin',
  logikaLogout: '@AUTH/logikaLogout',
  connected: '@AUTH/connected',
  disconnected: '@AUTH/disconnected',
};

export default authActions;
