const frontendURL = '/rep/commoninRepPanel/';
const backendURL = '/api/api/reports/report/?search=&ordering=name&isActive=true&deleted=false';
const name = 'БФН. Структура дошкільних закладів освіти';
const sections = 'bfn';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
