import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, ListGroupItem } from 'react-bootstrap';
import { useDrag } from 'react-dnd';
import { faFolderClosed, faFolderOpen, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemTypes from '../itemTypes';

function Field({
  name, label, onClick, active, onDblClick,
  level, onToggleOpen, opened, hasChild,
}) {
  const [{ isDragging }, ref] = useDrag({
    item: { name, type: ItemTypes.availableField },
    type: ItemTypes.availableField,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
    paddingLeft: `${level + 1}rem`,
    backgroundColor: active ? '#00FFF71C' : 'white',
    color: 'black',
    border: active ? 'none' : '',
  }), [active, isDragging, level]);

  const icon = useMemo(
    () => {
      if (!hasChild) return faMinus;
      if (opened) return faFolderOpen;
      return faFolderClosed;
    },
    [hasChild, opened],
  );

  return (
    <ListGroupItem
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      style={style}
      ref={ref}
      className={`ms-${level}`}
    >
      <Button
        size="sm"
        className="me-1"
        variant="light"
        disabled={!hasChild}
        onClick={() => onToggleOpen(name)}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
      {label}
    </ListGroupItem>
  );
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  level: PropTypes.number.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  hasChild: PropTypes.bool,
};

Field.defaultProps = {
  active: false,
  onDblClick: () => null,
  opened: false,
  hasChild: false,
};

export default Field;
