import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

const BACKENDURL = meta.cat.treasureaccount.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors, headerReadOnlyFields,

  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondProps = editorHooks.useItemInputProps('fond', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbp7Props = editorHooks.useItemInputProps('kbp7', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const bankProps = editorHooks.useItemInputProps('bank', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const openDateProps = editorHooks.useDateInputProps('open_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const closeDateProps = editorHooks.useDateInputProps('close_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const noteProps = editorHooks.useTextInputProps('note', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const accountKindProps = editorHooks.useSelectorInputProps('account_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const onlyCommitentProps = editorHooks.useCheckboxInputProps('only_commitment', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
      disposer: data.disposer ? data.disposer.id : null,
    }),
    [data.disposer],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.name}
      subtitle={data.code}
    >
      <Container fluid className="pb-3">
        {data.is_group && (
          <div className="w-100 text-end">
            <Badge bg="info">Група</Badge>
          </div>
        )}
        <Row>
          <Col md={4}>
            <EditorControls.StringInput {...codeProps} inputMask="UA999999999999999999999999999" />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker {...disposerProps} />
          </Col>
          <Col>
            <EditorControls.SelectorInput {...accountKindProps} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...fondProps} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker {...kbp7Props} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...bankProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.DateInput {...openDateProps} />
          </Col>
          <Col>
            <EditorControls.DateInput {...closeDateProps} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <EditorControls.TextInput {...noteProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.CheckboxInput {...onlyCommitentProps} />
            <EditorControls.CheckboxInput {...isActiveProps} />
          </Col>
        </Row>
      </Container>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
