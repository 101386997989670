import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  Button, ButtonGroup, Modal, Nav, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectionMode } from '../../../../constants/meta/common';
import meta from '../../../../constants/meta';
import { filterPropType, paramsPropType } from './itemPicker';
// eslint-disable-next-line import/no-cycle
import catSelectors from '../../../catalogs/selectors';
import Selector from '../../../lister/selector';

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName
  || WrappedComponent.name
  || 'Component'
);
const getItemModal = (ChildClass) => (
  class ItemModal extends Component {
    static displayName = `${getDisplayName(ChildClass)} wrapped by ItemModal`;

    static propTypes = {
      value: PropTypes.instanceOf(Map),
      canCleared: PropTypes.bool,
      canOpen: PropTypes.bool,
      canAutoComplete: PropTypes.bool,
      onChange: PropTypes.func,
      choiceSettings: PropTypes.oneOf(Object.values(selectionMode)),
      filter: filterPropType,
      params: paramsPropType,
      modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
      modelName: PropTypes.oneOf([
        ...new Set(Object.keys(meta).reduce(
          (r, typeName) => [...r, ...Object.keys(meta[typeName])],
          [],
        )),
      ]).isRequired,
      noHierarchy: PropTypes.bool,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
      rows: PropTypes.number,
      noBorder: PropTypes.bool,
      noBackground: PropTypes.bool,
      // TAB - Ordering. Переход по Enter и т.д.
      isFocusCell: PropTypes.bool,
      rowId: PropTypes.number,
      id: PropTypes.number,
      processingKeyDown: PropTypes.func,
      setDataRef: PropTypes.func,
      headerComponent: PropTypes.node,
      // endTab
      containerRef: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        current: PropTypes.any,
      }),
    };

    static defaultProps = {
      value: new Map({ id: '', repr: '' }),
      canCleared: false,
      canOpen: false,
      canAutoComplete: true,
      onChange: () => console.log('No handle changer!'),
      filter: [],
      params: [],
      choiceSettings: selectionMode.items,
      noHierarchy: false,
      disabled: false,
      required: false,
      rows: 1,
      noBorder: false,
      noBackground: false,
      // TAB - Ordering. Переход по Enter и т.д.
      isFocusCell: false,
      rowId: 0,
      id: 0,
      processingKeyDown: () => null,
      setDataRef: () => null,
      headerComponent: null,
      containerRef: null,
      // endTab
    };

    constructor(props) {
      super(props);
      this.state = {
        modalOpened: false,
        okEnabled: true,
        item: new Map(),
      };
      this.SelectorComponent = null;
    }

    componentDidMount() {
      const { modelType, modelName } = this.props;
      if (modelType === 'cat' && catSelectors[modelName]) {
        this.SelectorComponent = catSelectors[modelName];
      // } else if (modelType === 'doc' && docSelectors[modelName]) {
      //   console.log(modelName);
      //   this.SelectorComponent = docSelectors[modelName];
      } else {
        this.SelectorComponent = Selector;
      }
    }

    onCancel = () => this.setState({ modalOpened: false });

    onOk = (e) => {
      const { item } = this.state;
      this.setState({ modalOpened: false });
      this.props.onChange(e, new Map({ id: item.get('id'), repr: item.get('repr') }), item);
    };

    onSelect = (e, item) => {
      this.setState({ item, okEnabled: this.selectionTrue(item) });
    };

    onChoice = (e, item) => {
      if (this.selectionTrue(item)) {
        this.setState({ item, modalOpened: false });
        this.props.onChange(e, new Map({ id: item.get('id'), repr: item.get('repr') }), item);
        e.stopPropagation();
      }
    };

    selectionTrue = (item) => {
      const { choiceSettings } = this.props;
      if (choiceSettings === selectionMode.items) {
        return !item.get('isGroup', false);
      } if (choiceSettings === selectionMode.folders) {
        return item.get('isGroup', false);
      }
      return true;
    };

    checkEnter = (e) => {
      switch (e.keyCode) {
        case 13:
          this.onOk(e);
          break;
        case 27:
          this.onCancel();
          break;
        default: break;
      }
    };

    render() {
      const {
        value,
        onChange,
        canCleared,
        canOpen,
        filter,
        params,
        choiceSettings,
        modelType,
        modelName,
        canAutoComplete,
        noHierarchy,
        disabled,
        required,
        rows,
        noBorder,
        noBackground,
        // TAB - Ordering. Переход по Enter и т.д.
        isFocusCell,
        rowId,
        id,
        processingKeyDown,
        setDataRef,
        headerComponent,
        // endTab
        containerRef,
      } = this.props;
      const { modalOpened, okEnabled } = this.state;

      const { SelectorComponent } = this;
      const container = containerRef ? containerRef.current : null;
      if (!container && modalOpened) {
        console.warn('Вызов selectorField без свойства containerRef запрещен. Без этого не будет работать создание нового элемента в окне выбора', modelType, modelName);
      }

      return (
        <>
          <ChildClass
            value={value}
            canCleared={canCleared}
            canOpen={canOpen}
            canAutoComplete={canAutoComplete}
            canSelect
            filter={filter}
            params={params}
            choiceSettings={choiceSettings}
            modelType={modelType}
            modelName={modelName}
            onChoiceButtonClick={() => {
              this.setState({ modalOpened: true });
            }}
            onChange={onChange}
            disabled={disabled}
            modalOpened={modalOpened}
            required={required}
            rows={rows}
            noBorder={noBorder}
            noBackground={noBackground}
            // TAB - Ordering
            isFocusCell={isFocusCell}
            rowId={rowId}
            id={id}
            processingKeyDown={processingKeyDown}
            setDataRef={setDataRef}
            // endTab
          />
          <Modal show={modalOpened} onHide={this.onCancel} size="xl" dialogClassName="mw-100" container={container}>
            <Modal.Header>
              <Modal.Title>
                {meta[modelType][modelName].label}
              </Modal.Title>
              <Nav className="flex-fill">
                <ButtonGroup className="ms-auto">
                  <Button variant="success" disabled={!okEnabled} onClick={this.onOk}>
                    <FontAwesomeIcon icon={faCheck} className="me-2" />
                    Обрати
                  </Button>
                  <Button variant="danger" onClick={this.onCancel}>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />
                    Скасувати
                  </Button>
                </ButtonGroup>
              </Nav>
            </Modal.Header>
            <Modal.Body
              tabIndex={0}
              onKeyDown={(e) => this.checkEnter(e)}
            >
              {SelectorComponent && (
                <Suspense fallback={() => <Spinner animation="border" />}>
                  <SelectorComponent
                    modelType={modelType}
                    modelName={modelName}
                    filter={filter}
                    params={params}
                    choiceSettings={choiceSettings}
                    value={value}
                    onSelect={this.onSelect}
                    onChoice={this.onChoice}
                    noHierarchy={noHierarchy}
                    headerComponent={headerComponent}
                  />
                </Suspense>
              )}
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
);

export default getItemModal;
