import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Alert,
  Button, Form, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import api from '../../../../../api/req';
import { sendUpdateSignal } from '../../../../../actions/windowsManager';
import { useMD } from '../../../hooks/md';

function ImportJSONButton({ className, modelType, modelName }) {
  const dispatch = useDispatch();
  const md = useMD(modelType, modelName);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(
    () => {
      if (!open && !!file) setFile(null);
    },
    [file, open],
  );

  const onChooseFile = useCallback(
    (e) => {
      const fr = new FileReader();
      fr.onload = () => {
        setFile(fr.result);
      };
      fr.readAsDataURL(e.target.files[0]);
    },
    [],
  );

  const onPutFile = useCallback(
    () => {
      const loader = async () => {
        const r = await api.post(`doc/${md.backendName}/`, { method: 'ImportJSON', id: 'create', file });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return true;
      };
      setLoading(true);
      setErr(null);
      loader()
        .then(() => {
          dispatch(sendUpdateSignal(md.frontend));
          setOpen(false);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [dispatch, file, md.backendName, md.frontend],
  );

  return (
    <>
      <Button
        title="Завантажити з JSON"
        variant="warning"
        className={className}
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faUpload} spin={loading} />
      </Button>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Завантаження з файлу</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            custom
            label={!file ? 'Оберіть файл' : 'Файл обрано'}
            data-browse="Показати"
            onChange={onChooseFile}
          />
          {err && (
            <Alert variant="danger">
              {err}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={!file || loading}
            onClick={onPutFile}
          >
            <FontAwesomeIcon icon={faCheck} spin={loading} className="me-1" />
            Завантажити
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ImportJSONButton.propTypes = {
  className: PropTypes.string,
  modelType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
};

ImportJSONButton.defaultProps = {
  className: '',
};

export default ImportJSONButton;
