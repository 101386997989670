import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  label: 'Контрагенты',
  name: 'Контрагенты',
  ...defaultColumns,
  EDRPOU: { label: 'ЕДРПОУ', name: 'ЕДРПОУ', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  bank: { label: 'Банк', name: 'Банк', visible: true },
  codeMer: { label: 'Код за мережею (у кого він є)', name: 'КодМережа', visible: true },
};

export const partners = {
  label: 'Постачальники',
  name: 'partners',
  backendName: 'Контрагенты',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/partners',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'EDRPOU',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useListLazyLoad: true,
};

export default partners;
