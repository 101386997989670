const frontendURL = '/doc/freeRem/';
const backendURL = '/api/merezha/freerem3/';
const name = 'Інформація щодо стану розподілу коштів, отриманих від перевиконання дохідної частини загального фонду місцевих бюджетів (Додаток 3)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
