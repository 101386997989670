import { fromJS } from 'immutable';
import authActions from '../constants/actions/auth';

export const login = (payload) => (dispatch) => {
  dispatch({
    type: authActions.login,
    payload: fromJS(payload),
  });
};

export const refresh = (payload) => (dispatch) => {
  dispatch({
    type: authActions.refresh,
    payload: fromJS(payload),
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: authActions.logout,
    payload: {},
  });
};

export const logikaLogin = (ticket) => (dispatch) => {
  dispatch({
    type: authActions.logikaLogin,
    payload: { ticket },
  });
};

export const logikaLogout = () => (dispatch) => {
  dispatch({
    type: authActions.logikaLogout,
    payload: {},
  });
  // window.localStorage.clear();
  // window.localStorage.removeItem('token');
  // window.localStorage.removeItem('token', 'AP', 'sessionOptions');
  // window.navigateState({}, '', '/');
};

export const makeConnected = () => (dispatch) => {
  dispatch({
    type: authActions.connected,
    payload: {},
  });
};

export const makeDisconnected = () => (dispatch) => {
  dispatch({
    type: authActions.disconnected,
    payload: {},
  });
};

export default {
  login,
  logout,
};
