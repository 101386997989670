import api from './req';

const TIMEOUT = 3000;

function taskChecker(taskId, authF, onDone, onError) {
  if (taskId) {
    api.get$(`/api/tasks/${taskId}/`, authF).then((r) => {
      if (r.ok) {
        r.json().then((json) => {
          switch (json.status) {
            case 'PENDING':
              setTimeout(() => taskChecker(taskId, authF, onDone, onError), TIMEOUT);
              break;
            case 'FAILURE':
              onError(json);
              break;
            case 'REVOKED':
              onError();
              break;
            case 'SUCCESS':
              onDone(json);
              break;
            default:
              onError({ error: `Unknow task status ${json.status}` });
          }
        });
      } else {
        onError({ error: `${r.status} ${r.statusText}` });
      }
    });
  }
}

function checkTask(taskId, auth) {
  return new Promise((resolve, reject) => {
    if (taskId) {
      taskChecker(taskId, auth, resolve, (reason) => reject(new Error(reason)));
    } else {
      reject(new Error('No task'));
    }
  });
}

export default checkTask;
