import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function ExpensesRowSet({ data, ...rest }) {
  const template = useMemo(
    () => {
      const columns = [
        {
          columns:
            [
              {
                field: 'target',
                colProps: { sm: 12 },
                controlProps: { filter: { budget: data.budget ? data.budget.id : null } },
              },
              {
                field: 'kbp7_item',
                colProps: { sm: 12 },
                controlProps: { filter: { owner: data.authority ? data.authority.id : null } },
              },
              {
                field: 'disposer',
                colProps: { sm: 12 },
                controlProps: {
                  filter: { parent: data.authority ? data.authority.id : null },
                  noHierarchy: true,
                },
              },
            ],
        },
        {
          columns:
            [
              { field: 'zf_2', headerLabel: `${data.year - 2}р. (звіт) Загальний фонд`, colProps: { sm: 12 } },
              { field: 'sf_2', headerLabel: `${data.year - 2}р. (звіт) Спеціальний фонд`, colProps: { sm: 12 } },
              { field: 'br_2', headerLabel: `${data.year - 2}р. (звіт) у т.ч. бюджет розвитку`, colProps: { sm: 12 } },
            ],
        },
        {
          columns:
        [
          { field: 'zf_1', headerLabel: `${data.year - 1}р. (затверджено) Загальний фонд`, colProps: { sm: 12 } },
          { field: 'sf_1', headerLabel: `${data.year - 1}р. (затверджено) Спеціальний фонд`, colProps: { sm: 12 } },
          { field: 'br_1', headerLabel: `${data.year - 1}р. (затверджено) у т.ч. бюджет розвитку`, colProps: { sm: 12 } },
        ],
        },
        {
          columns:
        [
          { field: 'zf0', headerLabel: `${data.year}р. (план) Загальний фонд`, colProps: { sm: 12 } },
          { field: 'sf0', headerLabel: `${data.year}р. (план) Спеціальний фонд`, colProps: { sm: 12 } },
          { field: 'br0', headerLabel: `${data.year}р. (план) у т.ч. бюджет розвитку`, colProps: { sm: 12 } },
        ],
        },
        {
          columns:
        [
          { field: 'zf1', headerLabel: `${data.year + 1}р. (план) Загальний фонд`, colProps: { sm: 12 } },
          { field: 'sf1', headerLabel: `${data.year + 1}р. (план) Спеціальний фонд`, colProps: { sm: 12 } },
          { field: 'br1', headerLabel: `${data.year + 1}р. (план) у т.ч. бюджет розвитку`, colProps: { sm: 12 } }],
        },
        {
          columns:
            [
              { field: 'zf2', headerLabel: `${data.year + 2}р. (план) Загальний фонд`, colProps: { sm: 12 } },
              { field: 'sf2', headerLabel: `${data.year + 2}р. (план) Спеціальний фонд`, colProps: { sm: 12 } },
              { field: 'br2', headerLabel: `${data.year + 2}р. (план) у т.ч. бюджет розвитку`, colProps: { sm: 12 } }],
        },
      ];

      return { columns };
    },
    [data.authority, data.budget, data.year],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="forecastprepareadd2expenses_set"
    />
  );
}

ExpensesRowSet.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    budget: foreignPropType,
    authority: foreignPropType,
  }).isRequired,
};

export default ExpensesRowSet;
