import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import styled from 'styled-components';
import { PeriodIcon, OkIc, CancelIcon } from '../../assets/icons/index';
import { CommandPanelButton } from '../button';
import { StyledLabel, ContainerButton } from '../Form/styledForm';
import InputWithDropdown from '../styledInputs/inputWithDropdown';
import enums from '../../constants/meta/enums';
import { getDatesByVariant } from './getDatesByVariant';
import DateInput from './dates';

const periodSelectionOptions = Object.values(enums.PeriodSelection);

const allMonth = [
  {
    name: 'Січень',
    id: 1,
    active: false,
  }, {
    name: 'Лютий',
    id: 2,
    active: false,
  }, {
    name: 'Березень',
    id: 3,
    active: false,
  }, {
    name: 'Квітень',
    id: 4,
    active: false,
  }, {
    name: 'Травень',
    id: 5,
    active: false,
  }, {
    name: 'Червень',
    id: 6,
    active: false,
  }, {
    name: 'Липень',
    id: 7,
    active: false,
  }, {
    name: 'Серпень',
    id: 8,
    active: false,
  }, {
    name: 'Вересень',
    id: 9,
    active: false,
  }, {
    name: 'Жовтень',
    id: 10,
    active: false,
  }, {
    name: 'Листопад',
    id: 11,
    active: false,
  }, {
    name: 'Грудень',
    id: 12,
    active: false,
  },
];

const CurrentYear = new Date().getFullYear();
const allYear = [
  {
    name: CurrentYear - 1,
    id: 1,
    active: false,
  }, {
    name: CurrentYear,
    id: 2,
    active: false,
  }, {
    name: CurrentYear + 1,
    id: 3,
    active: false,
  },
];

const ContButton = styled(ContainerButton)`
  margin-top: 7px;
`;
const StyledContainer = styled.div`
  position: relative;
`;
const OkIcon = styled(OkIc)`
  padding: 2px;
`;
const ModalContainer = styled.div`
  width: 500px;
  position: absolute;
  margin-top: 10px;
  top: 100%;
  left: 0;
  z-index: 2;
  overflow: visible;
  border: 1px solid #DDE2E9;
  border-radius: 4px;
  padding: 15px 20px;
  background: linear-gradient(0deg,rgba(255,255,255,0.8),rgba(255,255,255,0.8)),#DDE2E9;
  box-shadow: 2px 3px 22px -1px rgba(191,206,219,1);
`;

const CalendarHr = styled.hr`
  border: 0.5px solid #008f21;
  width: 100%;
  margin-top: 20px;
`;

const ContainerCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0 15px;
    >div{
      border: 1px solid #c7d9ef;
      padding: 4px 5px;
      cursor: pointer;
    };
`;
const OneCell = styled.div`
  background-color:  ${(props) => (props.active ? '#487dba' : 'white')};
  color:  ${(props) => props.active && 'white'};
  background:  ${(props) => props.hoverEffect && '#487dba'};
  text-align: center;
  ::selection {
    background: none;
  }
`;

const StyledDatesContainer = styled.div`
  display: flex;
  &>*{
    flex: 1 1 auto;
  }
  &>:first-child{
    margin-right: 10px;
  }
`;

const OneYear = styled(OneCell)`
  background-color:  ${(props) => (props.activeY ? '#487dba' : 'white')};
  color:  ${(props) => props.activeY && 'white'};
`;

class DateRangeWithModal extends PureComponent {
  static propTypes = {
    value: PropTypes.instanceOf(Map).isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.mValue) {
      const D1 = new Date(props.value.get('StartDate', 0));
      const D2 = new Date(props.value.get('EndDate', 0));
      D1.setMinutes(D1.getTimezoneOffset());
      D2.setMinutes(D2.getTimezoneOffset());
      const settings = { year: '2-digit', month: '2-digit', day: '2-digit' };
      const label = `${D1.toLocaleString('uk', settings)} - ${D2.toLocaleString('uk', settings)}`;
      return {
        mValue: props.value,
        value: props.value,
        period: enums.PeriodSelection[props.value.get('Variant', 'Custom')].name,
        label,
      };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      mValue: null,
      label: '',
      modalOpened: false,
      hoverEffect: false,
      activeY: null,
      month: allMonth,
      year: allYear,
      period: enums.PeriodSelection.Custom.name,
      firstClick: false,
      fDateM: null,
      lDateM: null,
      fDateS: null,
      lDateS: null,
      dataY: null,
    };
  }

  setYear(year) {
    this.setState({
      activeY: year,
    });
  }

  getSelectedPeriod = (period) => {
    const { value, month } = this.state;
    if (period === enums.PeriodSelection.Custom.name) {
      this.setState({
        activeY: null,
        month: month.map((i) => ({
          ...i,
          active: false,
        })),
      });
    } else {
      this.setState({
        value: value.merge(getDatesByVariant(period)),
      });
    }
  };

  getMonthYear = (periodMon) => {
    const { value, dataY } = this.state;

    // Получаем дату с-по
    const firstDayOfMonth = new Date(
      dataY || (new Date(value.get('StartDate', 0))).getFullYear(),
      periodMon[0] ? (periodMon[0] - 1) : (new Date(value.get('StartDate', 0))).getMonth(),
      1,
    );

    firstDayOfMonth.setHours(0, -firstDayOfMonth.getTimezoneOffset(), 0);

    const lastDayOfMonth = new Date(
      dataY || (new Date(value.get('EndDate', 0))).getFullYear(),
      periodMon[1] ? periodMon[1] : (new Date(value.get('EndDate', 0))).getMonth(),
      0,
    );

    lastDayOfMonth.setHours(23, 59 - lastDayOfMonth.getTimezoneOffset(), 59);

    this.setState({
      value: value
        .set('StartDate', firstDayOfMonth.valueOf())
        .set('EndDate', lastDayOfMonth.valueOf()),
    });
    return value;
  };

  choiceYear = (name) => {
    const { value } = this.state;
    this.setState({ dataY: name });

    const firstDayOfMonth = new Date(
      name || (new Date(value.get('StartDate', 0))).getFullYear(),
      (new Date(value.get('StartDate', 0))).getMonth(),
      1,
    );

    firstDayOfMonth.setHours(0, -firstDayOfMonth.getTimezoneOffset(), 0);

    const lastDayOfMonth = new Date(
      name || (new Date(value.get('EndDate', 0))).getFullYear(),
      (new Date(value.get('EndDate', 0))).getMonth(),
      0,
    );

    lastDayOfMonth.setHours(23, 59 - lastDayOfMonth.getTimezoneOffset(), 59);

    this.setState({
      value: value
        .set('StartDate', firstDayOfMonth.valueOf())
        .set('EndDate', lastDayOfMonth.valueOf()),
    });
    return value;
  };

  choiceMonth = (id) => {
    const {
      firstClick, fDateM,
    } = this.state;

    // В зависимости от количиства кликов мышкой изменяем массив с id (дата с -по)
    let periodMon = [];

    if (firstClick === false) {
      this.setState({ fDateM: id, lDateM: id });
      periodMon = [id, id];
    } else if (firstClick === true) {
      this.setState({ lDateM: id });

      periodMon = [fDateM, id];
    }

    this.sortArr(periodMon);
    this.getMonthYear(periodMon);
  };

  sortArr = (periodMon) => {
    const { month } = this.state;

    // Сортируем массив с периодом по возростанию
    periodMon.sort((a, b) => a - b);

    // Получаем новый массив с id, которые нужно закрасить
    const selectMonth = [];
    for (let i = periodMon[0]; i <= periodMon[1]; i++) {
      selectMonth.push(i);
    }

    this.setState({
      month: month.map((i) => ((selectMonth.indexOf(i.id) !== -1)
        ? { ...i, active: true }
        : { ...i, active: false })),
    });
  };

  startSelect = (id) => {
    this.setState({ fDateS: id, lDateS: id });
  };

  endSelect = (id) => {
    const {
      fDateS,
    } = this.state;

    this.setState({ lDateS: id });

    if (fDateS !== id) {
      this.setState({ firstClick: true, fDateM: fDateS, lDateM: id });
    }
    const periodMonS = [fDateS, id];

    this.sortArr(periodMonS);
    this.getMonthYear(periodMonS);
  };

  render() {
    const {
      modalOpened, value, mValue, label, period, month, year, activeY, firstClick,
    } = this.state;
    const { onChange } = this.props;
    return (
      <StyledContainer>
        <CommandPanelButton
          text={label}
          onClick={() => {
            this.setState({ modalOpened: !modalOpened });
          }}
        >
          <img src={PeriodIcon} alt="Period" />
        </CommandPanelButton>
        {modalOpened && (
          <ModalContainer open={modalOpened}>
            <StyledLabel>Відібрати документи за період</StyledLabel>
            <div style={{ marginBottom: '15px' }}>
              <InputWithDropdown
                value={period}
                options={periodSelectionOptions}
                onChange={(e, v) => {
                  this.setState({ period: v });
                  this.getSelectedPeriod(v);
                }}
              />
            </div>
            <StyledDatesContainer>
              <DateInput
                value={value.get('StartDate', 0)}
                onChange={(e, v) => {
                  this.setState({ value: value.set('StartDate', v) });
                }}
              />
              <DateInput
                value={value.get('EndDate', 0)}
                onChange={(e, v) => {
                  this.setState({ value: value.set('EndDate', v) });
                }}
                endOfDay
              />
            </StyledDatesContainer>
            <CalendarHr />
            {period === enums.PeriodSelection.Custom.name
              ? (
                <ContainerCell>
                  {year.map((y) => (
                    <OneYear
                      key={y.id}
                      activeY={y.id === activeY}
                      onClick={() => {
                        this.setYear(y.id);
                        this.choiceYear(y.name);
                      }}
                    >
                      {y.name}
                    </OneYear>
                  ))}
                </ContainerCell>
              )
              : (
                <ContainerCell style={{ filter: 'opacity(0.5)', cursor: 'default' }}>
                  {year.map((y) => (
                    <OneYear
                      style={{ cursor: 'default' }}
                      key={y.id}
                    >
                      {y.name}
                    </OneYear>
                  ))}
                </ContainerCell>
              )}
            {period === enums.PeriodSelection.Custom.name && activeY !== null
              ? (
                <ContainerCell
                  onClick={() => {
                    this.setState({ firstClick: !firstClick });
                  }}
                >
                  {month.map((m) => (
                    <OneCell
                      key={m.id}
                      active={m.active}
                      onClick={() => {
                        this.choiceMonth(m.id);
                      }}
                      onMouseDown={() => {
                        this.setState({ hoverEffect: true });
                        this.startSelect(m.id);
                      }}
                      onMouseUp={() => {
                        this.setState({ hoverEffect: false });
                        this.endSelect(m.id);
                      }}
                      onMouseMove={() => {
                        this.state.hoverEffect
                        && this.setState({
                          month: month.map((i) => (i.id === m.id
                            ? {
                              ...i,
                              active: true,
                            }
                            : i)),
                        });
                      }}
                    >
                      {m.name}
                    </OneCell>
                  ))}
                </ContainerCell>
              )
              : (
                <ContainerCell
                  style={{ filter: 'opacity(0.5)' }}
                  onClick={() => {
                    this.setState({ firstClick: !firstClick });
                  }}
                >
                  {month.map((m) => (
                    <OneCell
                      style={{ cursor: 'default' }}
                      key={m.id}
                    >
                      {m.name}
                    </OneCell>
                  ))}
                </ContainerCell>
              )}
            <ContButton>
              <CommandPanelButton
                text="Застосувати"
                onClick={(e) => {
                  this.setState({ modalOpened: false });
                  onChange(e, value.set('Variant', period));
                }}
              >
                <OkIcon />
              </CommandPanelButton>
              <CommandPanelButton
                text="Скасувати"
                onClick={() => this.setState({ value: mValue, modalOpened: false })}
              >
                <CancelIcon />
              </CommandPanelButton>
            </ContButton>
          </ModalContainer>
        )}
      </StyledContainer>
    );
  }
}

export default DateRangeWithModal;
