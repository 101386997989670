import React, {
  useState, useCallback, useContext, useMemo, useEffect,
} from 'react';
import {
  Button, Modal, Row, Col, Tabs, Tab, Container, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheck,
  faClock, faFile, faFileLines, faMapMarkerAlt, faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react-json-view';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import SignData from '../../../services/LogikaLogin/signData';
import LogicaPing from '../../../services/LogikaLogin/ping';
import useEditorParams from '../../../newEditor/hook/params';
import useEditor from '../../../newEditor/hook/editor';
import { CiatAppContext, LogicaContext } from '../../../../providers';
import meta, { metaBackends } from '../../../../meta';
import EditorContainer from '../../../newEditor/editorContainer';
import LogcaLoginModal from '../../../services/LogikaLogin/LogcaLoginModal';
import DateLabel from '../../../../components/Controls/dates/dateLabel';
import UserShow from '../../../../components/Controls/user_show';
import editorHooks from '../../../basicEditor/editorControls/hooks';
import { EditorControls } from '../../../basicEditor/editorControls';
import SignatoryRow from './signatoryRow';
import checkTask from '../../../../api/checktask';
import api from '../../../../api/req';
import AppContext from '../../../../components/blanks/context/Context';
import { downloadFile } from './utils';

const BACKENDURL = meta.doc.transferToLogica.backendURL;
function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions,
    fields, nonFieldErrors, options, isNew, changed,
    loading, systemErrors, headerReadonlyFields,
    readOnly,
    fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const {
    config: { isDark },
  } = useContext(AppContext);

  const isDev = process.env.NODE_ENV !== 'production';

  const { ticket } = useContext(LogicaContext);
  const navigate = useNavigate();

  const [currentSignId, setCurrentSignId] = useState(null);
  const [currentUnsignId, setCurrentUnsignId] = useState(null);

  const currentSign = useMemo(
    () => (data.signatory_set || [])
      .filter((s) => s.id === currentSignId && !s.signed)
      .reduce((R, r) => r, {}),
    [currentSignId, data.signatory_set],
  );

  const currentUnSign = useMemo(
    () => (data.signatory_set || [])
      .filter((s) => s.id === currentUnsignId && s.signed)
      .reduce((R, r) => r, {}),
    [currentUnsignId, data.signatory_set],
  );

  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);

  const noteProps = editorHooks.useTextInputProps('note', data, fields, fieldErrors, actions.onChange, readOnly, headerReadonlyFields);

  const onAddSign = useCallback(
    (text) => {
      if (currentSignId) {
        setCurrentSignId(null);
        actions.onDraft(data, { sign: { id: currentSignId, text } });
        if (isDev) downloadFile(atob(text), 'application/x-pkcs7-signature', 'sign.p7s');
      }
    },
    [actions, currentSignId, data, isDev],
  );
  const onRemoveSign = useCallback(
    () => {
      if (currentUnsignId) {
        setCurrentUnsignId(null);
        actions.onDraft(data, { unsign: { id: currentUnsignId } });
      }
    },
    [actions, currentUnsignId, data],
  );

  const [sending, setSending] = useState(false);
  const [sendingErr, setSendingErr] = useState(null);

  const { auth } = useContext(CiatAppContext);

  const onSend = useCallback(
    () => {
      const sender = async () => {
        const r = await api.post(`${BACKENDURL}${data.id}/send_to_logica/`, auth, { ticket });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setSending(true);
      setSendingErr(null);
      sender()
        .then(({ task_id: taskId }) => checkTask(taskId, auth))
      // eslint-disable-next-line no-console
        .then((d) => {
          if (d.result.errors) {
            throw new Error(d.result.errors.join(', '));
          }
          actions.onReload();
        })
        .catch((e) => setSendingErr(e.message))
        .finally(() => setSending(false));
    },
    [actions, auth, data.id, ticket],
  );

  const [printForm, setPrintForm] = useState(null);
  const [printing, setPrinting] = useState(false);
  const [printErr, setPrintErr] = useState(null);

  useEffect(
    () => {
      const loader = async () => {
        const r = await api.get(`${BACKENDURL}${data.id}/get_printform/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        if (r.status === 204) {
          setPrintErr({ variant: 'info', text: 'Для даного типу документів наразі немає друкованої форми' });
          return '';
        }
        return r.json();
      };
      if (data.id) {
        setPrintErr(null);
        setPrinting(true);
        loader()
          .then((d) => {
            if (d) setPrintForm(decodeURIComponent(escape(atob(d.result))));
            else setPrintForm('');
          })
          .catch((e) => setPrintErr({ variant: 'danger', text: e.message }))
          .finally(() => setPrinting(false));
      }
    },
    [auth, data.id],
  );

  const displayJSON = useMemo(
    () => {
      if (data.json_text) {
        try {
          return (JSON.parse(data.json_text));
        } catch (e) {
          return { error: 'При читанні файлу виникла помилка ' };
        }
      }
      return {};
    },
    [data.json_text],
  );

  const displayReportType = useMemo(
    () => {
      if (!data || !fields) return '';
      return fields.report_type.choices
        .filter((c) => c.value === data.report_type)
        .reduce((R, c) => c.display_name, '?');
    },
    [data, fields],
  );

  if (!data || !fields) return null;
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      title="Відправка звітів до logika"
      // CommandPanel={(
      // )}
    >
      <Row>
        <Col className="rounded border py-3">
          <h5>Інформація про документ</h5>
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon icon={faMapMarkerAlt} title="fields.authority.label" className="me-1" fixedWidth />
              {data.authority && data.authority.repr}
            </li>
            <li>
              <FontAwesomeIcon icon={faClock} title={fields.created_at.label} className="me-1" fixedWidth />
              <DateLabel value={data.created_at} displayFormat="long" className="me-2" />
              <UserShow user={data.author} />
            </li>
            <li>
              <FontAwesomeIcon icon={faFileLines} title={fields.report_type.label} className="me-1" fixedWidth />
              <span>{displayReportType}</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faFile} title={fields.documents.label} className="me-1" fixedWidth />
              <div className="d-inline-flex gap-2">
                {data.document && data.document.map((doc) => (
                  <Button
                    key={doc.id}
                    variant="link"
                    as={Link}
                    to={`${metaBackends[doc.resource].frontendURL}${doc.id}/`}
                    className="p-0"
                  >
                    {doc.repr}
                  </Button>
                ))}
              </div>
            </li>
          </ul>
          <h5>Інформація щодо відправки</h5>
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon icon={faClock} title={fields.sent_at.label} className="me-1" fixedWidth />
              {data.sent_at ? (
                <>
                  <DateLabel value={data.sent_at} displayFormat="long" className="me-2" />
                  <UserShow user={data.who_sent} />
                </>
              ) : (
                'Не відправлено'
              )}
            </li>
          </ul>
        </Col>
        <Col>
          <h5>Підписи документу</h5>
          <div className="timeline-vertical">
            {data.signatory_set && data.signatory_set.map((sign, idx) => (
              <SignatoryRow
                signatory={sign}
                step={idx + 1}
                key={sign.id}
                onSign={() => setCurrentSignId(sign.id)}
                onUnsign={() => setCurrentUnsignId(sign.id)}
              />
            ))}
          </div>
          <div className="mt-2 py-2 border-top ">
            {sendingErr && (
              <div>
                <IconAlert variant="danger">
                  {sendingErr}
                </IconAlert>
              </div>
            )}
            <div className="d-flex gap-2">
              <Button
                variant="falcon-success"
                onClick={onSend}
                disabled={!data.can_send || sending}
                title="Надіслати до LOGICA"
              >
                <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                Надіслати до LOGICA
              </Button>
              <Button
                variant="falcon-info"
                onClick={() => navigate(meta.srv.DocumentsInLogica.frontendURL)}
              >
                Результат верифікації
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey="presentedDocs">
        <Tab
          title="Дані, що передаються"
          eventKey="presentedDocs"
        >
          {printErr && (
            <IconAlert variant={printErr.variant}>
              {printErr.text}
            </IconAlert>
          )}
          {printing && (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner className="me-2" />
              Зачекайте будь-ласка
            </div>
          )}
          <div className="max-vh-75 overflow-auto">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: printForm }} />
          </div>
        </Tab>
        <Tab title="Json" eventKey="Json">
          <div className="max-vh-75 overflow-auto">
            {isDev && (
              <Button
                onClick={() => {
                  downloadFile(data.json_text, 'application/json', 'sign.json');
                }}
              >
                Download JSON
              </Button>
            )}
            {displayJSON && (
              <ReactJson
                src={displayJSON}
                theme={isDark ? 'bright' : 'bright:inverted'}
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            )}
          </div>
        </Tab>
        <Tab title="Примітка" eventKey="notes">
          <Container fluid className="mx-1 my-2">
            <EditorControls.TextInput {...noteProps} />
          </Container>
        </Tab>
      </Tabs>
      <Modal
        show={!!currentSignId}
        onHide={() => setCurrentSignId(null)}
        size="lg"
        scrollable
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Накладання підпису ${currentSign.rank} ${currentSign.repr}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignData
            data={data.json_text}
            onAddSign={onAddSign}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={!!currentUnsignId}
        onHide={() => setCurrentUnsignId(null)}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Знимання підпису ${currentUnSign.rank} ${currentUnSign.repr}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ви дійсно бажаєте зняти підпис?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-danger" onClick={onRemoveSign}>
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Так
          </Button>
          <Button variant="falcon-success" onClick={() => setCurrentUnsignId(null)}>
            <FontAwesomeIcon icon={faBan} className="me-2" />
            Ні
          </Button>
        </Modal.Footer>
      </Modal>

      <LogicaPing
        available={logicaPingSuccess}
        handleAvailable={setLogicaPingSuccess}
      />
      <LogcaLoginModal />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  options: { name: '' },
  // fieldErrors: {},
};

export default Editor;
