import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MultiValueInput } from '../../../../components/Controls';

const SelectedDiv = styled.div`
  grid-template-columns:  10% 1fr;
`;

const MultiContainer = styled.div`
  grid-template-columns: 1fr 10%;
`;
const TextBlock = styled.div`
  font-size: 14px;
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 2px 5px;
`;

function PluralValues({
  short, value, onChange, ctype, resource, onlyGroups,
}) {
  const displayValues = useMemo(
    () => value.map((iv) => (iv && iv.repr ? iv.repr : iv)).join(', '),
    [value],
  );
  return (
    <SelectedDiv>
      {!short ? (
        <>
          <FontAwesomeIcon
            icon={faPlus}
            title="Додати"
            onClick={(e) => onChange(e, [...value, null])}
          />
          <MultiContainer>
            {value.map((itemValue, k) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${itemValue}-${k}`}>
                <MultiValueInput
                  ctype={ctype}
                  resource={resource}
                  value={itemValue}
                  onChange={(e, v) => onChange(e, [
                    ...value.slice(0, k),
                    v,
                    ...value.slice(k + 1),
                  ])}
                  id={`${k}_${itemValue === null ? 'null' : itemValue.repr || itemValue}`}
                  onlyGroups={onlyGroups}
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  title="Видалити"
                  onClick={(e) => onChange(e, [
                    ...value.slice(0, k),
                    ...value.slice(k + 1),
                  ])}
                />
              </Fragment>
            ))}
          </MultiContainer>
        </>
      ) : (
        <TextBlock>{displayValues}</TextBlock>
      )}
    </SelectedDiv>

  );
}

PluralValues.propTypes = {
  short: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    ]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  ctype: PropTypes.string.isRequired,
  resource: PropTypes.string,
  onlyGroups: PropTypes.bool,
};

PluralValues.defaultProps = {
  short: false,
  resource: null,
  onlyGroups: false,
};
export default PluralValues;
