const frontendURL = '/doc/paymentTransfer/';
const backendURL = '/api/obligations/paymenttransheader/';
const name = 'Платіжні доручення на переміщення коштів';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
