import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, InputGroup, Badge, Button,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import {
  PrependAccount,
} from '../../styles/klassifiers';
import RowSet from './tp/rowset';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.documentregistry.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kindProps = editorHooks.useSelectorInputProps('kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateFromProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateToProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const accountProps = editorHooks.useItemInputProps('treasure_account', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentProps = editorHooks.useStringInputProps('comment', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  //
  const accountFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.documentregistry.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <div className="d-flex justify-content-end">
            <Badge variant="info" className="me-2">
              {data.year}
                  &nbsp;р.
            </Badge>
          </div>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col lg={3}>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col lg={4}>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col lg={5}>
                <EditorControls.SelectorInput
                  {...kindProps}
                />
              </Col>
              <Col lg={4}>
                <EditorControls.DateInput {...dateFromProps} />
              </Col>
              <Col lg={4}>
                <EditorControls.DateInput {...dateToProps} />
              </Col>
              <Col lg={4}>
                <EditorControls.ItemPicker
                  {...accountProps}
                  filter={accountFilter}
                  prepend={(
                    <PrependAccount>
                      <InputGroup.Text className="w-100">
                        {data.treasure_account && data.treasure_account.code}
                      </InputGroup.Text>
                    </PrependAccount>
                )}
                />
              </Col>
            </Row>
            <Button>Заповнити</Button>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="documentregistryrow_set">
          {fields && fields.documentregistryrow_set && (
          <Tab eventKey="documentregistryrow_set" title={fields.documentregistryrow_set.label}>
            <TabContainer>
              <RowSet
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="notes" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onSave={onSave}
        onClose={onClose}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
