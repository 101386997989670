import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import BasicCell from './basic_cell';
import { columnsPropType } from './propTypes';

function BasicRow({
  columns,
  rowIndex, onChange, row, fields, errors,
  highlights, readOnly, readOnlyFields,
}) {
  return columns.map((col, i) => {
    const colProps = {
      ...(col.colProps || {}),
      ...(col.headerColProps || {}),
    };
    if (col.columns) {
      return (
      // eslint-disable-next-line react/no-array-index-key
        <Col {...colProps} key={i}>
          <Row className="mx-0">
            <BasicRow
              columns={col.columns}
              rowIndex={rowIndex}
              onChange={onChange}
              row={row}
              fields={fields}
              errors={errors}
              highlights={highlights}
              readOnly={readOnly}
              readOnlyFields={readOnlyFields}
            />
          </Row>
        </Col>
      );
    }
    return (
      <Col {...colProps} key={col.field}>
        <BasicCell
          rowIndex={rowIndex}
          onChange={onChange}
          row={row}
          fields={fields}
          field={col.field}
          errors={errors}
          highlighted={highlights.includes(col.field)}
          readOnly={readOnly || readOnlyFields.includes(col.field)}
          controlProps={col.controlProps}
        />
      </Col>
    );
  });
}

BasicRow.propTypes = {
  columns: columnsPropType.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

BasicRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(BasicRow);
