import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/changeToDraftAnnualSources';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, FI,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKFBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onSumChange = useCallback((e, col, value) => {
    const total = () => {
      if (col === 'zf') { return value + (row[tableMD.columns.sf.name] || 0); }
      if (col === 'sf') { return value + (row[tableMD.columns.zf.name] || 0); }
      return row[tableMD.columns.sum.name];
    };
    onRowChange(e, {
      [tableMD.columns[col].name]: value,
      [tableMD.columns.sum.name]: total(),
    });
  }, [onRowChange, row]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col sm={12} xl={4}>
            <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFB.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={onKFBChange}
                filter={FIOwnerFilter}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KFBCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} xl={8}>
            <Row className="w-100" noGutters>
              <Col sm={12} lg={3}>
                <TableRowCell column="zf" highlighted={highlights.includes('zf')}>
                  <NumberInput
                    value={row[tableMD.columns.zf.name]}
                    precision={2}
                    inputClassName="text-center"
                    onChange={(e, v) => onSumChange(e, 'zf', v)}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12} lg={3} className="justify-content-sm-center">
                <TableRowCell column="sf" highlighted={highlights.includes('sf')}>
                  <NumberInput
                    value={row[tableMD.columns.sf.name]}
                    precision={2}
                    inputClassName="text-center"
                    onChange={(e, v) => onSumChange(e, 'sf', v)}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12} lg={3}>
                <TableRowCell column="br" highlighted={highlights.includes('br')}>
                  <NumberInput
                    value={row[tableMD.columns.br.name]}
                    precision={2}
                    inputClassName="font-italic text-center"
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.br.name]: v })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col sm={12} lg={3}>
                <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
                  <NumberInput
                    value={row[tableMD.columns.sum.name]}
                    precision={2}
                    inputClassName="text-center"
                    onChange={() => {}}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
