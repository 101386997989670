/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useRef, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { changeField, registerWarnings } from '../../../../actions/editor';
import { BALANCE_KEYS, NumberOfMounth } from '../consts';
import enums from '../../../../constants/meta/enums';
import md from '../../../../constants/meta/documents/financing';
import {
  StyledLabel, ContainerSt,
} from '../../../../components/Form/styledForm';
import LoadRequestsModal from './loadRequestsModal';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {comparisonTypes, emptyUid} from '../../../../constants/meta/common';
import { modelName, modelType } from '../../financingSources/def';
import { useEditor } from '../../../newEditor';
import { StampFinancedJs } from '../../../../assets/icons';
import BankAccountTypes from '../../../../constants/meta/enums/bankAccaountTypes';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function Financing({ data, actions, permissions }) {
  const dispatch = useDispatch();
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось
  const FI = data[md.columns.FI.name];
  const fondCl = data[md.columns.Fond.name];
  const date = data[md.columns.date.name];
  const useFOAccounts = data[md.columns.useFOAccounts.name];
  const formDisabled = data[md.columns.IsApproved.name] || data[md.columns.Financed.name];
  const ComposeSettingsChecked = data[md.columns.ComposeSettings.name];
  const FinancingReturnChecked = data[md.columns.FinancingReturn.name];
  const isFinManagment = sessionOptions.get('isFinManagment', false);
  const FOParent = sessionOptions.get('foParent', new Map());

  const {
    data: gData,
  } = useEditor({
    modelType,
    modelName,
  });

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;

  const csuFilter = useMemo(() => [{ fieldName: '_Родитель', value: FI }], [FI]);
  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);

  const FiAccFilter = useMemo(
    () => {
      const getAccKind = () => {
        if (isFinManagment || useFOAccounts) {
          return {
            comparisonType: comparisonTypes.equal,
            value: BankAccountTypes.boiler.name,
          };
        }
        return {
          comparisonType: comparisonTypes.notEqual,
          value: BankAccountTypes.boiler.name,
        };
      };
      return [
        { fieldName: 'ИспользуетсяДляОбязательств', value: false },
        {
          fieldName: 'ВидСчета',
          ...getAccKind(),
        },
        {
          fieldName: 'Владелец',
          value: useFOAccounts ? FOParent : FI,
        },
        { fieldName: 'Фонд', value: fondCl },
      ];
    },
    [FI, FOParent, fondCl, isFinManagment, useFOAccounts],
  );

  const requestsLoaded = useRef(false);

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const generalData = gData[md.tables.general.name] || [];
      if (requestsLoaded.current) {
        requestsLoaded.current = false;
        // Если это ворврат финансирования - остатки не проверяем.
        if (FinancingReturnChecked) return true;
        // Проверка превышения финансирования!!!!
        const keys = generalData.groupBy((row) => BALANCE_KEYS
          .reduce((R, k) => R.set(k, row.get(k)), new Map()));
        const balances = keys.map((gv) => new Map()
          .set('balance', gv.first().get('Balance', 0))
          .set('sum', gv.reduce((R, row) => R + row.get('Sum', 0), 0)));
        const redBalances = balances.filter((row) => row.get('balance') < row.get('sum'));
        const warnings = redBalances.reduce((wrngs, rowB, key) => {
          let bal = rowB.get('balance');
          keys.get(key).forEach((row) => {
            bal -= row.get('Sum', 0);

            if (bal < 0) {
              const idx = generalData.indexOf(row);
              // rewrite on actions.onChange?
              dispatch(changeField(['tables/debtManagementAnalysis', idx, 'Sum'], 0));
            }
          });
          const nstr = keys.get(key).reduce((R, r) => [...R, r.get('strNumber', -1)], []);

          return wrngs.push(new Map({
            msg: `В строках № ${nstr.join(',')} є перевищення щодо плану асигнувань!`,
          }));
        }, new List());
        // rewrite ?
        dispatch(registerWarnings('balances', warnings));
      }
    },
    [FinancingReturnChecked, dispatch, gData],

  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row className="align-items-end">
        <Col>
          <EditorControls.StringInput
            label={md.columns.RNo.label}
            value={data[md.columns.RNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.date.name]: value,
              });
              actions.onSR('DATE_ON_CHANGE');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.PlaningMode.label}
            value={data[md.columns.PlaningMode.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.PlaningMode.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.FondObject.label}
            value={data[md.columns.FondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FondObject.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {data[md.columns.TransfertingZFToSFVisible.name] && (
            <span style={{ color: 'red' }}>
              <EditorControls.CheckboxInput
                controlId={`TransfertingZFToSF-${data.id}`}
                label={md.columns.TransfertingZFToSF.label}
                value={data[md.columns.TransfertingZFToSF.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.TransfertingZFToSF.name]: value,
                })}
                readOnly={readOnly}
              />
            </span>
          )}
        </Col>
        {/* {!data[md.columns.TransfertingZFToSF.name] && ( */}
        {/* <Col> */}
        {/*  <EditorControls.CheckboxInput */}
        {/*    controlId={`Transferting-${data.id}`} */}
        {/*    label={md.columns.Transferting.label} */}
        {/*    value={data[md.columns.Transferting.name]} */}
        {/*    onChange={(e, value) => actions.onChange({ */}
        {/*      [md.columns.Transferting.name]: value, */}
        {/*    })} */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        {/* </Col> */}
        {/* )} */}
      </Row>
      <Row className="d-flex align-items-end ">
        <Col lg={8}>
          <EditorControls.ItemPicker
            label={md.columns.FIAccount.label}
            value={data[md.columns.FIAccount.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FIAccount.name]: value,
              });
              actions.onSR('CSU_ACCOUNT_ON_CHANGE');
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={FiAccFilter}
            disabled={formDisabled}
            params={onDateParams}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {data[md.columns.Code.name]}
              </InputGroup.Text>

            )}
          />
        </Col>

        <Col lg={4} style={{ marginBottom: '1rem' }}>
          <LoadRequestsModal
            onOk={handlerSelector}
            disabled={formDisabled  || data[md.columns.FondObject.name]?.id === emptyUid}
            add={data[md.columns.AddApplication.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AddApplication.name]: value,
            })}
            date={data[md.columns.date.name]}
            fondObject={data[md.columns.FondObject.name]}
            FI={data[md.columns.FI.name]}
            budget={data[md.columns.Budget.name]}
            importAllNotes={data[md.columns.ImportAllNotes.name]}
            CSU={data[md.columns.IncludeCSUIntoHead.name] ? data[md.columns.CSU.name] : null}
            FIAccount={data[md.columns.FIAccount.name]}
            llabel={data[md.columns.FondObject.name]?.id === emptyUid ? " Увага! Необхідно обрати фонд."
                :
                ""}
          />
        </Col>
      </Row>
      <Row>
        {data[md.columns.IncludeCSUIntoHead.name] && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.CSU.label}
              modelType="cat"
              modelName="csu"
              value={data[md.columns.CSU.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.CSU.name]: value,
              })}
              filter={csuFilter}
              noHierarchy
              canErase
            />
          </Col>
        )}
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ToHold-${data.id}`}
            label={md.columns.ToHold.label}
            value={data[md.columns.ToHold.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ToHold.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`Loan-${data.id}`}
            label={md.columns.Loan.label}
            value={data[md.columns.Loan.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Loan.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`ImportAllNotes-${data.id}`}
            label={md.columns.ImportAllNotes.label}
            value={data[md.columns.ImportAllNotes.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ImportAllNotes.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          { !data[md.columns.letMinusInRow.name] && (
            <>
              <EditorControls.CheckboxInput
                controlId={`FinancingReturn-${data.id}`}
                label={md.columns.FinancingReturn.label}
                value={data[md.columns.FinancingReturn.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FinancingReturn.name]: value,
                })}
                readOnly={readOnly}
                disabled={ComposeSettingsChecked}
              />
              {data[md.columns.FinancingReturn.name]
                ? (
                  <>
                    <StyledLabel>{md.columns.NumberOfMounthReturn.label}</StyledLabel>
                    <EditorControls.SelectorInput
                      value={data[md.columns.NumberOfMounthReturn.name]}
                      values={Object.values(NumberOfMounth)}
                      onChange={async (e, value) => {
                        await actions.onChange({
                          [md.columns.NumberOfMounthReturn.name]: value,
                        });
                        actions.onSR('RENEW_TOTALS_ALL');
                      }}
                      disabled={formDisabled}
                    />
                  </>
                )
                : (
                  <EditorControls.CheckboxInput
                    controlId={`ComposeSettings-${data.id}`}
                    label={md.columns.ComposeSettings.label}
                    value={data[md.columns.ComposeSettings.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.ComposeSettings.name]: value,
                    })}
                    readOnly={readOnly}
                    disabled={FinancingReturnChecked}
                  />
                )}
            </>
          )}
          {sessionOptions.get('is_admin', false) && (
            <EditorControls.CheckboxInput
              controlId={`Financed-${data.id}`}
              label={md.columns.Financed.label}
              value={data[md.columns.Financed.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.Financed.name]: value,
              })}
            />
          )}
        </Col>

      </Row>
      {data[md.columns.CalcBalanceOfAccounts.name] && (

        <Row>

          <Col>
            <p>
              {md.columns.InputRemainder.label}
              :
              <span className="font-weight-bold">
                {data[md.columns.InputRemainder.name]}
              </span>
            </p>
          </Col>
          <Col>
            <p>
              {md.columns.Listed.label}
              :
              {' '}
              <span className="font-weight-bold">
                {data[md.columns.Listed.name]}
              </span>
            </p>
          </Col>
          <Col>
            <p>
              {md.columns.OutputRemainder.label}
              :
              {' '}
              <span className="font-weight-bold">
                {data[md.columns.OutputRemainder.name]}
              </span>
            </p>
          </Col>
        </Row>
      )}
      {/* Только для Винницы */}
      {false && (
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.TaxObject.repr}
              value={data[md.columns.TaxObject.name] && data[md.columns.TaxObject.name].repr}
              readOnly
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {data[md.columns.Financed.name] && (
            <Col style={{ zIndex: '1' }}>
              <ContainerSt>
                <StampFinancedJs text={data[md.columns.FinReceived.name]} />
              </ContainerSt>
              {sessionOptions.get('is_admin', false) && (
                <EditorControls.StringInput
                  label={md.columns.FinReceived.label}
                  value={data[md.columns.FinReceived.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.FinReceived.name]: value,
                  })}
                />
              )}
            </Col>
          )}
        </Col>
      </Row>
    </EditorCollapse>
  );
}

Financing.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.PlaningMode.name]: PropTypes.oneOf([
      enums.PlaningKind.temporary.name, enums.PlaningKind.permanent.name,
    ]),
    [md.columns.CSU.name]: referencePropType,
    [md.columns.FI.name]: referencePropType,
    [md.columns.IsApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Financing;
