import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  planKind: { label: 'Ознака плану', name: 'ВидПлана' },
  refKind: { label: 'Вид довідки', name: 'ВидСправки' },
  refType: { label: 'Тип довідки', name: 'ТипСправки' },
  formKind: { label: 'Вид форми', name: 'ВидФормы' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період', name: 'ПериодОтчета' },
  fond: { label: 'Фонд', name: 'фФонд' },
  ffond: { label: 'Деталізація фонд', name: 'фЭлементФонд' },
  author: { label: 'Автор', name: 'фАвтор' },
  reason: { label: 'Підстава', name: 'фОснование' },
  exec: { label: 'Виконавець', name: 'фИсполнитель' },
  fFO: { label: 'Розп. нищ. рівня/отримувач', name: 'фРаспорядитель' },
  fdev: { label: 'Розділити довідки за видами', name: 'флРазделитьДов' },
  prinDate: { label: 'Друкувати дату підпису', name: 'ПечатьДатыПодписи' },
  onlyApprooved: { label: 'Тільки підписані документи', name: 'ТолькоПодписанныеДокументы' },
  noSumms: { label: 'Не показувати суми', name: 'НеОтображатьСуммы' },
  nameForm: { label: 'Заголовок звіту', name: 'ИмяФормы' },
  nameInTableYear: { label: 'Заголовок, довідки річні', name: 'ИмяВТаблицеГодовые' },
  nameInTableMounth: { label: 'Заголовок, довідки помісячні', name: 'ИмяВТаблицеМесяцы' },
  nameInTableBudget: { label: 'Заголовок, довідки всі разом', name: 'ИмяВТаблицеБюджет' },
  fYearMounth: { label: 'В помісячних всі довідки', name: 'флГодовыеИПомесячные' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  yearAll: { label: 'В річних всі довідки', name: 'флГодовыеВсе' },
  flSN: { label: 'Прізвище та ім\'я', name: 'флФИ' },

};

const tables = {
  tpDoc: {
    name: 'ТчДок',
    label: 'Тч док',
    columns: {
      fLoad: { label: 'Завантажити', name: 'флЗагружать' },
      doc: { label: 'Док', name: 'Док' },
      regNum: { label: 'Рег номер', name: 'РегНомер' },
      basis: { label: 'Підстава', name: 'Основание' },
      effective: { label: 'Виконавець', name: 'Исполнитель' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'referRegistry',
  backendName: 'РеестрСправок',
  frontend: 'dp/referRegistry',
  label: 'Реєстр довідок (додаток 8)',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
