// import React from 'react';
// import StyledMenuHeader from '../../../components/styledMenuHeader/menuHeader';
// import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
// import NavPanel, { NavPanelBuilding } from '../../../constants/meta/navBar';
// import session from '../../../api/session';
//
// const DirectoriesMenuItem = () => {
//   const isBuildingPanel = session.get('is_chief_building');
//   return (
//     <div>
//       <StyledMenuHeader>
//       Головна
//       </StyledMenuHeader>
//       <Boxes items={!isBuildingPanel ? NavPanel.main.boxes : NavPanelBuilding.main.boxes} />
//     </div>
//   );
// };
//
// export default DirectoriesMenuItem;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import actions from '../../../actions/favorites';
import StyledMenuHeader from '../../../components/styledMenuHeader/menuHeader';
import {
  BoxLink,
  BoxMenuHeader,
  BoxMenuLineHr,
  ElementsListContainer,
} from '../../../components/styledBoxMenu/instancesForBoxMenu/index';
import session from '../../../api/session';
import meta from '../../../constants/meta';
import { Img } from '../../../components/styledBoxMenu/boxBuilder/box';
import { StarFavorites } from '../../../assets/icons';
import UseMenuHeader from '../hooks/useMenuHeader';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import DownloadDictionaryButton
  from '../../newLister/tableComponent/commandpanel/buttons/downloadDictionary';

const Container = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 2%;
`;

class DirectoriesMenuItem extends Component {
  static propTypes = {
    favList: PropTypes.instanceOf(List).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.isChiefBuilding = session.get('is_chief_building');
  }

  render() {
    const { favList, dispatch } = this.props;
    const changedFavlist = favList.map((f) => {
      const [modelType, modelName] = f.split('/');
      return { modelType, modelName };
    });
    const getModelType = [...new Set(changedFavlist.map((f) => f.modelType))];
    const availableType = ['doc', 'rep', 'cat', 'dp'].filter((i) => getModelType.includes(i));
    const getTitle = (type) => {
      switch (type) {
        case 'doc':
          return 'Документи';
        case 'cat':
          return 'Довідники';
        case 'rep':
          return 'Звіти';
        case 'dp':
          return 'Сервіси';
        default:
          return null;
      }
    };
    const modificationFavlist = availableType.map((t) => ({
      title: getTitle(t),
      item: [...changedFavlist.filter((f) => f.modelType === t)],
    }));
    const a = actions;
    return (
      <UseMenuHeader label="Головна">
        <div style={{ marginTop: '40px' }}>
          <BoxMenuHeader>Мої вподобання</BoxMenuHeader>
          <BoxMenuLineHr />
          <ElementsListContainer style={{ height: '73vh', overflow: 'auto' }}>
            <Container>
              {modificationFavlist
                && modificationFavlist.map((f) => {
                  const { title, item } = f;
                  return (
                    <div key={title}>
                      <BoxMenuHeader>{title}</BoxMenuHeader>
                      {Object.values(item).map((i) => {
                        const { modelType, modelName } = i;
                        const itemLink = `${modelType}/${modelName}`;
                        return (
                          <div
                            key={meta[modelType][modelName]?.name}
                            style={{ display: 'flex' }}
                          >
                            <div
                              onClick={() => dispatch(a.addToFavorites(itemLink))}
                            >
                              <Img src={StarFavorites} alt="StarFavorites" />
                            </div>
                            <BoxLink
                              isChiefBuilding={this.isChiefBuilding}
                              key={meta[modelType][modelName]?.name}
                              to={itemLink}
                            >
                              {meta[modelType][modelName]?.label}
                            </BoxLink>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

              {/* {favList && favList */}
              {/*  .map((f) => { */}
              {/*    const [modelType, modelName] = f.split('/'); */}
              {/*    console.log('modelType', modelType); */}
              {/*    return ( */}
              {/*      <div */}
              {/*        key={meta[modelType][modelName].name} */}
              {/*        style={{ display: 'flex' }} */}
              {/*      > */}
              {/*        <div */}
              {/*          onClick={() => dispatch(a.addToFavorites(f))} */}
              {/*        > */}
              {/*          <Img */}
              {/*            src={StarFavorites} */}
              {/*            alt="StarFavorites" */}
              {/*          /> */}
              {/*        </div> */}
              {/*        <BoxLink */}
              {/*          isChiefBuilding={this.isChiefBuilding} */}
              {/*          key={meta[modelType][modelName].name} */}
              {/*          to={f} */}
              {/*        > */}
              {/*          {meta[modelType][modelName].label} */}
              {/*        </BoxLink> */}
              {/*      </div> */}
              {/*    ); */}
              {/*  })} */}
            </Container>
          </ElementsListContainer>
        </div>
      </UseMenuHeader>
    );
  }
}

const mapState = (store) => ({
  favList: store.getIn(['favorites', 'favList'], new List()),
});

export default connect(mapState)(DirectoriesMenuItem);
