const frontendURL = '/doc/transferToLogica/';
const backendURL = '/api/logica/uploadcontainer/';
const name = 'Відправка до Logica розписів та змін до розписів';

const instance = {
  frontendURL,
  backendURL,
  name,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};

export default instance;
