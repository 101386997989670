import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField, processServerReq } from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'tpDoc';

const coldef = md.dp.budgetRequestPrint.tables.tpDoc.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "strNumber doc csu elKFK KFK";
  grid-template-columns: 5% 25% 25% 25% 10%;
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="strNumber">{coldef.strNumber.label}</DivHeader>
      <DivHeader areaName="doc">{coldef.doc.label}</DivHeader>
      <DivHeader areaName="csu">{coldef.csu.label}</DivHeader>
      <DivHeader areaName="elKFK">{coldef.elKFK.label}</DivHeader>
      <DivHeader areaName="KFK">{coldef.KFK.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="strNumber">
          <InputCodeStyled
            value={row.get('strNumber', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="doc">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="budgetRequest"
            modelType="doc"
            value={row.get('doc', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'doc'], v));
              dispatch(processServerReq('CHANGE_DOC_IN_TABLE_DOCS', { rowId }));
            }}
            noHierarchy
          />
        </DivCell>
        <DivCell areaName="csu">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="csu"
            modelType="cat"
            value={row.get('csu', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'csu'], v));
            }}
            noHierarchy
          />
        </DivCell>
        <DivCell areaName="elKFK">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="elementKFK"
            modelType="cat"
            value={row.get('elKFK', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elKFK'], v));
            }}
            // noHierarchy
          />
        </DivCell>
        <DivCell areaName="KFK">
          <InputCodeStyled
            value={row.get('KFK', '')}
            readOnly
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TableData = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TableData);
