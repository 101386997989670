import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function ServicesMenuItem() {
  return (
    <UseMenuHeader label="Сервiси">
      <Boxes items={NavPanel.services.boxes} />
    </UseMenuHeader>
  );
}

export default ServicesMenuItem;
