import { List, Map } from 'immutable';
import favActions from '../constants/actions/favorites';
import api from '../api/req';

const FAVOTITES_SETTINGS_NAME = 'favorites';

export const saveSettings = (favList) => (dispatch) => {
  dispatch({
    type: favActions.saveFavoritesStart,
    payload: null,
  });

  api.put$(`settings/${FAVOTITES_SETTINGS_NAME}`, { favList: favList.toJS() })
    .then(() => dispatch({
      type: favActions.saveFavoritesEnd,
      payload: null,
    }))
    .catch((error) => {
      dispatch({
        type: favActions.saveFavoritesError,
        payload: error.message,
      });
    });
};

export const addToFavorites = (item) => (dispatch, getState) => {
  const favList = getState().getIn(['favorites', 'favList'], new List());
  const idx = favList.indexOf(item);
  const newFavList = idx === -1 ? favList.push(item) : favList.delete(idx);
  dispatch({
    type: favActions.toggleFavorites,
    payload: newFavList,
  });
  dispatch(saveSettings(newFavList));
};

const loadSettingsError = (errorMsg) => (dispatch) => {
  dispatch({
    type: favActions.loadFavoritesError,
    payload: errorMsg,
  });
};

const loadSettingsDone = (data) => (dispatch) => {
  if (!data) {
    return dispatch({
      type: favActions.loadFavoritesEnd,
      payload: new Map(),
    });
  }

  const { favList } = data;

  const applyingSettings = new List(favList);

  dispatch({
    type: favActions.loadFavoritesEnd,
    payload: applyingSettings,
  });
};

export const loadSettings = () => (dispatch) => {
  dispatch({
    type: favActions.loadFavoritesStart,
    payload: null,
  });
  api.post$(`settings/${FAVOTITES_SETTINGS_NAME}`)
    .then((parsedResponse) => {
      if (parsedResponse.ok) {
        parsedResponse.json().then((data) => {
          dispatch(loadSettingsDone(data));
        });
      }
    })
    .catch((error) => {
      dispatch(loadSettingsError(error.message));
    });
};

export default {
  addToFavorites,
  loadSettings,
  saveSettings,
};
