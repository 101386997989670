const frontendURL = '/srv/sendChangesToLogica/';
const backendURL = '/api/authorityplans/send_changes_to_logica/';
const name = 'Створити документ відправки в LOGICA';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
