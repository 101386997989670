import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { CiatAppContext } from '../../../../../providers';
import api from '../../../../../api/req';
import { debounce } from '../../../../../api/utils';

const URL = '/api/financials/loadfinrequests/';
const KEYS = ['disposer', 'kbp7', 'kekv'];
const RES = ['balance'];

const useFinrequestLoading = ({ overrideFilter, docDate }) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [options, setOptions] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const { auth } = useContext(CiatAppContext);
  const [params, setParams] = useState({
    bdate: new Date().toJSON().substring(0, 10),
    edate: new Date().toJSON().substring(0, 10),
  });

  useEffect(() => {
    if (docDate) {
      const d = new Date(docDate).toISOString().substring(0, 10);
      setParams((o) => ({
        ...o,
        bdate: d,
        edate: d,
      }));
    }
  }, [docDate]);

  const displayItems = useMemo(
    () => items.reduce((Ri, docItem) => [
      ...Ri,
      ...docItem.nested.map((row) => ({
        document: {
          id: docItem.key[docItem.key.length - 1].document.document_id,
          repr: docItem.key[docItem.key.length - 1].document.repr,
        },
        ...KEYS.reduce((Rk, k) => ({
          ...Rk,
          [k]: {
            id: row.key[row.key.length - 1][k][`${k}_id`],
            repr: row.key[row.key.length - 1][k].repr,
          },
        }), {}),
        ...RES.reduce((Rs, k) => ({
          ...Rs,
          [k]: row.calcdata[k][k],
        }), {}),

      })),
    ], []).map((item, id) => ({ ...item, id })),
    [items],
  );

  useEffect(() => {
    const loader = async () => {
      const r = await api.options(URL, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => setOptions(d))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth]);

  const loadRequestController = useRef(null);

  const load = useCallback(
    (filters, prms) => {
      const loader = async () => {
        loadRequestController.current = new AbortController();
        const r = await api.post(URL, auth, {
          ...prms,
          filters: [...overrideFilter, ...filters],
        }, false, loadRequestController.current);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      if (loadRequestController.current) loadRequestController.current.abort();
      loader()
        .then((d) => setItems(d))
        .catch((e) => {
          if (!(e instanceof DOMException)) setErr(e.message);
        })
        .finally(() => setLoading(false));
    },
    [auth, overrideFilter],
  );

  const lazyLoader = useMemo(() => debounce(load), [load]);

  return {
    loading,
    err,
    options,
    selectedItems,
    setSelectedItems,
    items: displayItems,
    params,
    setParams,
    load: lazyLoader,
  };
};

export default useFinrequestLoading;
