import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/energyIndicators';

const meta = md.tables.indicators;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'department'}
          highlighted={highlights.includes('department')}
          onToggleHighlght={() => onToggleHighlght('department')}
        >
          {meta.columns.department.label}
        </TableHeaderCell>
      </Col>
      <Col className="border-right">
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Заборгованість на початок року
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'receivable'}
              highlighted={highlights.includes('receivable')}
              onToggleHighlght={() => onToggleHighlght('receivable')}
            >
              Дебіторська
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'payable'}
              highlighted={highlights.includes('payable')}
              onToggleHighlght={() => onToggleHighlght('payable')}
            >
              Кредиторська
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'limits'}
              highlighted={highlights.includes('limits')}
              onToggleHighlght={() => onToggleHighlght('limits')}
            >
              {meta.columns.limits.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'approved'}
              highlighted={highlights.includes('approved')}
              onToggleHighlght={() => onToggleHighlght('approved')}
            >
              {meta.columns.approved.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Фактично спожито
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              у фізичних обсягах
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              грн
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yearNaturalConsumed'}
              highlighted={highlights.includes('yearNaturalConsumed')}
              onToggleHighlght={() => onToggleHighlght('yearNaturalConsumed')}
            >
              з початку року
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yearConsumed'}
              highlighted={highlights.includes('yearConsumed')}
              onToggleHighlght={() => onToggleHighlght('yearConsumed')}
            >
              з початку року
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'monthNaturalConsumed'}
              highlighted={highlights.includes('monthNaturalConsumed')}
              onToggleHighlght={() => onToggleHighlght('monthNaturalConsumed')}
            >
              за звітній місяць
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'monthConsumed'}
              highlighted={highlights.includes('monthConsumed')}
              onToggleHighlght={() => onToggleHighlght('monthConsumed')}
            >
              за звітній місяць
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Проведено розрахунків
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              за поточний рік
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              за минулий рік
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'yearPaid'}
              highlighted={highlights.includes('yearPaid')}
              onToggleHighlght={() => onToggleHighlght('yearPaid')}
            >
              з початку року
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastYearPaid'}
              highlighted={highlights.includes('pastYearPaid')}
              onToggleHighlght={() => onToggleHighlght('pastYearPaid')}
            >
              з початку року
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'monthPaid'}
              highlighted={highlights.includes('monthPaid')}
              onToggleHighlght={() => onToggleHighlght('monthPaid')}
            >
              за звітній місяць
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastYearMonthPaid'}
              highlighted={highlights.includes('pastYearMonthPaid')}
              onToggleHighlght={() => onToggleHighlght('pastYearMonthPaid')}
            >
              за звітній місяць
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Сума списаної заборгованості
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'writtenOffReceivable'}
              highlighted={highlights.includes('writtenOffReceivable')}
              onToggleHighlght={() => onToggleHighlght('writtenOffReceivable')}
            >
              Дебіторської
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'writtenOffPayable'}
              highlighted={highlights.includes('writtenOffPayable')}
              onToggleHighlght={() => onToggleHighlght('writtenOffPayable')}
            >
              Кредиторської
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Заборгованість минулих років, виявлена в наслідок перерахунку
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastReceivable'}
              highlighted={highlights.includes('pastReceivable')}
              onToggleHighlght={() => onToggleHighlght('pastReceivable')}
            >
              Дебіторської
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastPayable'}
              highlighted={highlights.includes('pastPayable')}
              onToggleHighlght={() => onToggleHighlght('pastPayable')}
            >
              Кредиторської
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Заборгованість на звітну дату
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Row>
            <Col>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'receivableOnDate'}
                highlighted={highlights.includes('receivableOnDate')}
                onToggleHighlght={() => onToggleHighlght('receivableOnDate')}
              >
                Дебіторська
              </TableHeaderCell>
            </Col>
            <Col>
              <Row>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                  >
                    Кредиторська заборгованість
                  </TableHeaderCell>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'pastYearPayable'}
                    highlighted={highlights.includes('pastYearPayable')}
                    onToggleHighlght={() => onToggleHighlght('pastYearPayable')}
                  >
                    минулого року
                  </TableHeaderCell>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableHeaderCell
                    className="text-center text-xl-left"
                    active={activeCol === 'payableOnDate'}
                    highlighted={highlights.includes('payableOnDate')}
                    onToggleHighlght={() => onToggleHighlght('payableOnDate')}
                  >
                    поточного року
                  </TableHeaderCell>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              Фактичне споживання у фізичних обсягах за минулий рік
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastYearConsumed'}
              highlighted={highlights.includes('pastYearConsumed')}
              onToggleHighlght={() => onToggleHighlght('pastYearConsumed')}
            >
              за рік
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'pastYearPeriodConsumed'}
              highlighted={highlights.includes('pastYearPeriodConsumed')}
              onToggleHighlght={() => onToggleHighlght('pastYearPeriodConsumed')}
            >
              за відповідний період
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
