import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/netFOP';

const meta = md.tables.generalb;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tarifName'}
              highlighted={highlights.includes('tarifName')}
              onToggleHighlght={() => onToggleHighlght('tarifName')}
            >
              {meta.columns.tarifName.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKVKMB'}
              highlighted={highlights.includes('KPKVKMB')}
              onToggleHighlght={() => onToggleHighlght('KPKVKMB')}
            >
              {meta.columns.KPKVKMB.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKVK'}
              highlighted={highlights.includes('KPKVK')}
              onToggleHighlght={() => onToggleHighlght('KPKVK')}
            >
              {meta.columns.KPKVK.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'num'}
              highlighted={highlights.includes('num')}
              onToggleHighlght={() => onToggleHighlght('num')}
            >
              {meta.columns.num.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fact'}
              highlighted={highlights.includes('fact')}
              onToggleHighlght={() => onToggleHighlght('fact')}
            >
              {meta.columns.fact.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fop'}
              highlighted={highlights.includes('fop')}
              onToggleHighlght={() => onToggleHighlght('fop')}
            >
              {meta.columns.fop.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'okl'}
              highlighted={highlights.includes('okl')}
              onToggleHighlght={() => onToggleHighlght('okl')}
            >
              {meta.columns.okl.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'adds'}
              highlighted={highlights.includes('adds')}
              onToggleHighlght={() => onToggleHighlght('adds')}
            >
              {meta.columns.adds.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'adds_no'}
              highlighted={highlights.includes('adds_no')}
              onToggleHighlght={() => onToggleHighlght('adds_no')}
            >
              {meta.columns.adds_no.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'adds_min'}
              highlighted={highlights.includes('adds_min')}
              onToggleHighlght={() => onToggleHighlght('adds_min')}
            >
              {meta.columns.adds_min.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prem'}
              highlighted={highlights.includes('prem')}
              onToggleHighlght={() => onToggleHighlght('prem')}
            >
              {meta.columns.prem.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'other'}
              highlighted={highlights.includes('other')}
              onToggleHighlght={() => onToggleHighlght('other')}
            >
              {meta.columns.other.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'coun'}
              highlighted={highlights.includes('coun')}
              onToggleHighlght={() => onToggleHighlght('coun')}
            >
              {meta.columns.coun.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
