import React from 'react';
import { ButtonGroup, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/freeRem4Add2024';
import FreeRem4Add from './editor';
import APMenu from './menu';
import FreeRem4AddTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import { soSelector } from '../../_common/selectors';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function FreeRem4AddEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  const sessionOptions = useSelector(soSelector);
  // console.log(permissions.canChange);
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      >
        <ButtonGroup>
          {sessionOptions.get('isRegionUser', false) && sessionOptions.get('approveByRegion', false) && (
            <DefaultCPButton
              variant="success"
              label={data[md.columns.apprByRegion.name] ? 'Зняти затвердження (область)' : 'Затвердити (область)'}
              onClick={() => {
                actions.onSR('APPROVE_BY_REGION');
              }}
            />
          )}
        </ButtonGroup>
      </APMenu>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <FreeRem4Add
            data={data}
            actions={actions}
            permissions={permissions}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <FreeRem4AddTabs
            data={data}
            actions={actions}
            permissions={permissions}
          />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

FreeRem4AddEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

FreeRem4AddEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { FreeRem4AddEditor as StdEditor };

export default getRouterEditor(md)(FreeRem4AddEditor);
