import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Col, Alert, Row, Container, ButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import api from '../../../api/req';
import md from '../../../meta/doc/allocationPlanChanges';
import AppContext from '../../../providers/CiatAppContext';
import { DimableLoader } from '../../../components/bootStrap';
import { downloadFileFromJSON } from '../../../api/utils';
// eslint-disable-next-line import/no-cycle
import {
  EditorCheckboxInput, EditorControls,
  EditorDateInput,
  EditorStingInput,
} from '../../basicEditor/editorControls';
import DocList from '../../instances/individual/doc/allocationplanchanges/docs';
import { CPButton } from '../../../components/bootStrap/buttons/styles';

const types = [
  { value: 1, display_name: 'Рік' },
  // { value: 2, display_name: 'Квартал' },
  { value: 3, display_name: 'Місяць' },
];

const assignTypes = [
  { value: 2, display_name: 'Асигнування' },
  { value: 3, display_name: 'Надання кредитів' },
  { value: 4, display_name: 'Повернення кредитів' },
];

const codeInNameTypes = [
  { value: 0, display_name: 'Немає' },
  { value: 1, display_name: 'Гол.розпорядник' },
  { value: 2, display_name: 'Установа' },
];

const loadVariantTypes = [
  { value: 1, display_name: 'Видатки' },
  { value: 2, display_name: 'Джерела фінансування' },
];

function ExportChangesToDBFEditor({ currentItem, backendURL }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [budget, setBudget] = useState(null); // budgets
  const [loadVariant, setLoadVariant] = useState(1); // load_variant
  const [type, setType] = useState(1); // variant
  const [authority, setAuthority] = useState(null); // authority
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [docs, setDocs] = useState([]); // docs
  const [oneFile, setOneFile] = useState(false); // one_file
  const [noKDB, setNoKDB] = useState(true); // no_kdb
  const [natForm, setNatForm] = useState(false); // nat_form
  // const [asReference, setAsReference] = useState(false); // as_reference
  const [filenameZMINV, setFilenameZMINV] = useState(false); // filename_ZMINV
  const [assign, setAssign] = useState(2); // assign
  const [noYearSummIfZero, setNoYearSummIfZero] = useState(false); // no_year_summ_if_zero
  const [nom, setNom] = useState(''); // nom
  const [nr, setNr] = useState(0); // nr
  const [onlyYear, setOnlyYear] = useState(false); // only_year
  const [codeInName, setCodeInName] = useState(1); // code_in_name
  const [byMainDisposer, setByMainDisposer] = useState(false); // by_main_disposer
  const [noYearSumm, setNoYearSumm] = useState(false); // no_year_summ
  const [addF6, setAddF6] = useState(true); // add_f6
  const [addF7, setAddF7] = useState(false); // add_f7
  const [noArchive, setNoArchive] = useState(false); // no_archive
  const [isFO, setIsFO] = useState(true);
  const [activeDoc, setActiveDoc] = useState(null);
  const [fond2Or3, setFond2Or3] = useState(false);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback((v) => {
    const loader = async () => {
      const r = await api.get(`/api/authorityplans/export/get_initials/?documents=${v[0]}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setIsFO(rData.findep);
        setAuthority(rData.authority);
        setBudget(rData.budget);
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setFond2Or3(rData.fond_3_or_2);
        setDocs(rData.docs.map((d) => ({
          id: d.id,
          repr: d.repr,
          use: true,
        })));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const getDocList = useCallback(
    () => {
      const loader = async () => {
        const params = `authority=${authority.id}&begin_date=${beginDate}
      &end_date=${endDate}&executed=${true}`;
        const r = await api.get(`${backendURL}?${params}`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      onLoadStart();
      loader()
        .then((rData) => {
          const newDocs = [...rData.results].map((doc) => ({
            id: doc.id,
            repr: doc.repr,
            use: true,
          }));
          setDocs(newDocs);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [auth, authority, backendURL, beginDate, endDate, onError, onLoadEnd, onLoadStart],
  );

  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );

  useEffect(
    () => {
      getInitial(currentItem);
    },
    [currentItem, getInitial],
  );

  const selectedDocs = useMemo(
    () => docs.filter((d) => d.use)
      .map((d) => d.id),
    [docs],
  );

  const handleSubmit = useCallback(
    () => {
      const loader = async () => {
        const params = {
          budgets: budget.id,
          authority: authority.id,
          load_variant: loadVariant,
          variant: type,
          one_file: oneFile,
          no_kdb: noKDB,
          nat_form: natForm,
          // as_reference: asReference,
          filename_ZMINV: filenameZMINV,
          assign,
          no_year_summ_if_zero: noYearSummIfZero,
          nom,
          nr,
          only_year: onlyYear,
          code_in_name: codeInName,
          by_main_disposer: byMainDisposer,
          no_year_summ: noYearSumm,
          add_f6: addF6,
          add_f7: addF7,
          no_archive: noArchive,
          docs: selectedDocs.length ? selectedDocs : currentItem,
        };
        const r = await api.get('/api/authorityplans/export/', auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => `${R}${k}: ${d[k].join(', ')}, `, '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      loader()
        .then((r) => {
          if (noArchive) {
            const files = r.file.map((f) => downloadFileFromJSON(f.file, f.filename));
            return files;
          }
          const filename = 'Arhive.zip';
          return downloadFileFromJSON(r.file, filename);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [addF6, addF7, assign, auth, authority, budget, byMainDisposer,
      codeInName, currentItem, filenameZMINV, loadVariant, natForm, noArchive, noKDB, noYearSumm,
      noYearSummIfZero, nom, nr, onError, onLoadEnd, onLoadStart, oneFile, onlyYear, selectedDocs,
      type],
  );

  return (
    <DimableLoader loading={loading}>
      {err && (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </Alert>
      )}
      <Container fluid>
        { isFO ? (
          <Col>
            <Row>
              <Col>
                <EditorControls.RadioInput
                  value={type}
                  values={types}
                  onChange={(e, v) => setType(v)}
                  className="d-flex gap-2"
                />
              </Col>
              <Col>
                { backendURL === md.backendURL && (
                <EditorControls.RadioInput
                  label="Варіант вивантаження"
                  value={loadVariant}
                  values={loadVariantTypes}
                  onChange={(e, v) => setLoadVariant(v)}
                />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorCheckboxInput
                  controlId="noArchive"
                  label="Не архівувати файли"
                  value={noArchive}
                  onChange={(e, v) => setNoArchive(v)}
                />
              </Col>
              <Col>
                <EditorCheckboxInput
                  controlId="oneFile"
                  label="Вивантажити в один файл"
                  value={oneFile}
                  onChange={(e, v) => setOneFile(v)}
                />
              </Col>
              <Col>
                <EditorCheckboxInput
                  controlId="addF6"
                  label="Записувати спецфонд інші кошти без бюджету розвитку окремо"
                  value={addF6}
                  onChange={(e, v) => setAddF6(v)}
                />
              </Col>
              <Col>
                <EditorCheckboxInput
                  controlId="addF7"
                  label="Завжди дописувати підсумки по інших коштах спецфонду"
                  value={addF7}
                  onChange={(e, v) => setAddF7(v)}
                />
              </Col>
              <Col>
                { backendURL === md.backendURL && (
                  <EditorCheckboxInput
                    controlId="noKDB"
                    label="Не заповнювати КДБ"
                    value={noKDB}
                    onChange={(e, v) => setNoKDB(v)}
                  />
                )}
              </Col>
            </Row>
          </Col>
        ) : (
          <Row>
            <Col>
              <Row>
                <Col>
                  <EditorControls.RadioInput
                    label="Вид даних що вивантажуються"
                    value={assign}
                    values={assignTypes}
                    onChange={(e, v) => setAssign(v)}
                  />
                </Col>
                <Col>
                  <EditorControls.RadioInput
                    label="Код розпорядника в імені файла "
                    value={codeInName}
                    values={codeInNameTypes}
                    onChange={(e, v) => setCodeInName(v)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <EditorCheckboxInput
                        controlId="natForm"
                        label="Назва файлу довідки про надходження у натуральній формі  DNF_"
                        description="Формується для 2 та 3 фондів"
                        value={natForm}
                        disabled={!fond2Or3}
                        onChange={(e, v) => setNatForm(v)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <EditorCheckboxInput
                        controlId="noYearSummIfZero"
                        label="Не записувати дані по яким річні суми дорівнюють '0'"
                        value={noYearSummIfZero}
                        onChange={(e, v) => setNoYearSummIfZero(v)}
                      />
                    </Col>
                    <Col>
                      <EditorCheckboxInput
                        controlId="onlyYear"
                        label="Тільки річні суми"
                        value={onlyYear}
                        onChange={(e, v) => setOnlyYear(v)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <EditorCheckboxInput
                    controlId="noYearSumm"
                    label="Не вивантажувати річні суми"
                    value={noYearSumm}
                    onChange={(e, v) => setNoYearSumm(v)}
                  />
                </Col>
                <Col>
                  <EditorCheckboxInput
                    controlId="byMainDisposer"
                    label="Записувати у файл дані по головному розпоряднику, а не по установі"
                    value={byMainDisposer}
                    onChange={(e, v) => setByMainDisposer(v)}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                {/* TODO якщо ніхто не звернеться по цю галку прибрати її всюди,
                  таке як вона не треба */}
                {/* <Col> */}
                {/*  <EditorCheckboxInput */}
                {/*    controlId="asReference" */}
                {/*    label="Вивантажувати планові документи у форматі документів для змін" */}
                {/*    disabled={natForm} */}
                {/*    value={asReference} */}
                {/*    onChange={(e, v) => setAsReference(v)} */}
                {/*  /> */}
                {/* </Col> */}
                <Col>
                  <EditorCheckboxInput
                    controlId="filenameZMINV"
                    label="Назва файлу змін  ZMINV_"
                    value={filenameZMINV}
                    description="Формується для формату документів для змін"
                    onChange={(e, v) => setFilenameZMINV(v)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorStingInput
                    controlId="nom"
                    maxLength={10}
                    label="Значення для поля  NOM тобто номеру реєстру"
                    value={nom}
                    description="Формується для формату документів для змін, якщо
                  не вказати  значення  тоді  буде присвоєно номер довідки"
                    onChange={(e, v) => setNom(v)}
                  />
                </Col>
                <Col>
                  <EditorStingInput
                    controlId="nr"
                    maxLength={4}
                    label="Номер реєстру (при вивантаженні змін)"
                    value={nr}
                    description="Формується для формату документів для змін"
                    onChange={(e, v) => setNr(v)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <EditorDateInput
              controlId="begin_date"
              label="Відібрати документи за період з"
              required
              value={beginDate}
              onChange={(e, v) => setBeginDate(v)}
            />
          </Col>
          <Col>
            <EditorDateInput
              controlId="end_date"
              label="по"
              required
              value={endDate}
              onChange={(e, v) => setEndDate(v)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup>
              <CPButton
                onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                title="Обрати всі"
                icon={faCheckSquare}
              />
              <CPButton
                onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                title="Зняти відмітки"
                icon={faSquare}
              />
            </ButtonGroup>
            <Button
              variant="success"
              onClick={() => getDocList()}
            >
              Відібрати документи
            </Button>
            <Button onClick={handleSubmit}>
              <FontAwesomeIcon
                icon={faFileExport}
                className="mr-2"
              />
              Вивантажити
            </Button>
          </Col>
        </Row>
        <Col>
          <DocList
            docs={docs}
            onSelectDoc={setActiveDoc}
            onToggleDoc={onToggleDoc}
            activeDoc={activeDoc}
          />
        </Col>
      </Container>
    </DimableLoader>
  );
}

ExportChangesToDBFEditor.propTypes = {
  currentItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  backendURL: PropTypes.string.isRequired,
};

export default ExportChangesToDBFEditor;
