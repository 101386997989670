/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { dopFilters } from './consts';

function DopFilters({ checkedDopFilters, handleSetDopFilters }) {
  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dopFilters && dopFilters.map((filter) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div key={filter.id} onClick={() => handleSetDopFilters(filter)}>
          <Form.Check type="switch">
            <Form.Check.Input readOnly type="checkbox" checked={checkedDopFilters.includes(filter)} />
            <Form.Check.Label>{filter.name}</Form.Check.Label>
          </Form.Check>
        </div>
      ))}
    </>
  );
}

DopFilters.propTypes = {
  handleSetDopFilters: PropTypes.func.isRequired,
  checkedDopFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

export default DopFilters;
