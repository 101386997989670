import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Button, Modal, Alert, Row, Col, Card, ButtonGroup, Badge,
} from 'react-bootstrap';
import {
  faClock, faFileLines, faFile, faPaperPlane, faUser, faAddressCard, faBuilding, faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import md from '../../../../constants/meta/documents/docTransferToLogica';
import mdSignatureType from '../../../../constants/meta/catalogs/signatureVariants';

import SignData from '../../../signData';
import LogicaReportType from '../../../../constants/meta/enums/logicaReportTypes';
import { logikaLogout } from '../../../../actions/auth';
import LogicaPing from '../../../Logica/Ping';
import { soSelector } from '../../_common/selectors';
import {
  modalTitle, msgAlertNeed, msgAlertSuccess, msgAlertNeedSession, msgAlertVerify,
} from '../def';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { referencePropType } from '../../../newEditor/propTypes';
import DateLabel from '../../../../minfin/components/Controls/dates/dateLabel';
import UserShow from '../../../../minfin/components/Controls/user_show';
import { emptyUid } from '../../../../constants/meta/common';
import SignatoryRow from './signatoryRow';
import { CPButton } from '../../../../minfin/components/bootStrap/buttons';
import SharedFile from './SharedFiles';
import { doc1c } from '../../../../constants/meta/documents';
import useEU from '../../../../ciatEU/hook';

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    height: 100%;
    text-align: center;
    border: 1px solid;
    box-sizing: border-box;
    background: #15b615;
    color: white;
    border-radius: 4px;
    outline: none;
    cursor: ${({ disabled }) => { if (disabled) { return 'unset'; } return 'pointer'; }};
    padding: 5px 8px;
    margin-right: 5px;
    font-weight: 700;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    filter: ${({ disabled }) => { if (disabled) { return 'grayscale(1) opacity(.6)'; } return 'unset'; }};
    &:hover {
        background: #0e8d0e;
    }
    :focus {
        outline: none;
    }
`;

function DocEditor({
  data, actions, setChanged,
}) {
  const sessionOptions = useSelector(soSelector);

  const dispatch = useDispatch();

  const [signDataPage, setSignDataPage] = useState(false);
  const [enoughSignatures, setEnoghSignatures] = useState(false);
  const [signIdx, setSignIdx] = useState(null);
  const [responseText, setResponseText] = useState(null);
  const [responseStatus, setResponseStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [secondFile, setSecondFile] = useState(null);
  const [noApproveSign, setNoApproveSign] = useState(false);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  const [err, setErr] = useState(null);
  const [isMessageSentError, setIsMessageSentError] = useState(null);
  const [sessioDecigionSentError, setSessioDecigionSentError] = useState(null);
  const [checkSignInfo, setCheckSignInfo] = useState({
    opened: false,
    err: null,
    ownerInfo: null,
    timeInfo: null,
  });

  const [signList, setSignList] = useState([]);

  const typeReport = data[md.columns.typeReport.name];
  const isMessageSent = data[md.columns.isMessageSent.name];
  const sessioDecigionSent = data[md.columns.sessioDecigionSent.name];
  const filePdf = data[md.columns.filePdf.name];
  const filePdfPrognoz = data[md.columns.filePdfPrognoz.name];
  const filePdfRish = data[md.columns.filePdfRish.name];
  const sendJson = data[md.columns.sendJson.name];
  const signatureType = mdSignatureType.tables.variantOfSignature.columns.SignatureType.name;
  const signed = useCallback((ind) => signList[ind]['Подписано'], [signList]);
  const response = data[md.columns.response.name];

  const [ticket, setTicket] = useState(null);
  const token = localStorage.getItem('ticket');

  useEffect(() => {
    if (isMessageSent === false) {
      setIsMessageSentError(true);
    }
  }, [isMessageSent]);

  useEffect(() => {
    if (sessioDecigionSent === false) {
      setSessioDecigionSentError(true);
    }
  }, [sessioDecigionSent]);

  // useEffect(() => {
  //   if (['ПланАссигнований', 'ПаспортБП'].includes(typeReport)) {
  //     setNoApproveSign(true);
  //   }
  // }, [data, typeReport]);

  useEffect(() => {
    if (['ПланАссигнований'].includes(typeReport)) {
      setNoApproveSign(true);
    }
  }, [data, typeReport]);

  useEffect(() => {
    if (data[md.columns.signList.name]) {
      if (noApproveSign) {
        setSignList(data[md.columns.signList.name].filter((s) => (s[signatureType] === 'Подпись')));
      } else { setSignList(data[md.columns.signList.name]); }
    }
  }, [data, noApproveSign, signatureType]);

  useEffect(() => {
    if (response) {
      setResponseStatus(response.success);
      setResponseText(response.messages);
    }
  }, [response]);

  useEffect(() => {
    if (token) {
      setTicket(token);
    } else {
      setTicket(null);
    }
    if (responseText === 'Необхідно авторизуватись') {
      dispatch(logikaLogout());
    }
  }, [dispatch, responseText, token]);

  useEffect(() => {
    if (noApproveSign ? signList.filter((s) => (s[signatureType] === 'Подпись')).every((el, idx) => signed(idx)) : signList.every((el, idx) => signed(idx))) {
      setEnoghSignatures(true);
      return;
    }
    setEnoghSignatures(false);
  }, [noApproveSign, signList, signatureType, signed]);

  const addSign = useCallback(async (index, value) => {
    const signatureList = signList;
    signatureList[index]['Подписано'] = true;
    signatureList[index].signJson = value;
    await actions.onChange({ [md.columns.signList.name]: signatureList });
    await actions.onSR('SET_SIGN');
    setChanged(false);
  }, [actions, setChanged, signList]);

  const reportType = Object.values(LogicaReportType);
  const reportTypeToUk = (value) => {
    if (!value) { return ''; }
    return reportType.filter((report) => report.name === value).reduce((R, r) => r.label, value);
  };

  function AlertSuccess() {
    return (
      <Alert
        dismissible
        variant={responseStatus ? 'success' : 'danger'}
        onClose={() => { setResponseText(null); setResponseStatus(null); }}
      >
        {responseText === { msgAlertSuccess } ? (
          <p style={{ fontSize: '16px' }}>
            {msgAlertSuccess}
            <br />
            <span style={{ color: 'red', fontWeight: 'bold' }}>{msgAlertVerify}</span>
          </p>
        ) : <p style={{ fontSize: '16px' }}>{responseText}</p>}
      </Alert>
    );
  }

  function AlertMessage() {
    return (
      <Alert dismissible variant="danger" onClose={() => setIsMessageSentError(null)}>
        <p style={{ fontSize: '16px' }}>{msgAlertNeed}</p>
      </Alert>
    );
  }

  function AlertMessageSession() {
    return (
      <Alert dismissible variant="danger" onClose={() => setSessioDecigionSentError(null)}>
        <p style={{ fontSize: '16px' }}>{msgAlertNeedSession}</p>
      </Alert>
    );
  }

  function AllertErr() {
    return (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        <p style={{ fontSize: '16px' }}>{err}</p>
      </Alert>
    );
  }

  const handleUploadFile = (e, actionMethod, isSecondFile) => {
    e.preventDefault();

    const reader = new FileReader();
    // eslint-disable-next-line no-shadow
    const doc = e.target.files[0];

    if (doc) {
      const docName = doc.name;
      if ((secondFile && secondFile.name && docName === secondFile.name)
        || (file && file.name && docName === file.name)) {
        setErr('Файл з такою назвою вже додано!');
      } else {
        setErr(null);
        reader.onloadend = () => {
          const readerResult = reader.result;
          const component = readerResult.split(',')[1];
          const type = readerResult.split(';')[0];
          if (isSecondFile) {
            setSecondFile({ file: reader.result, name: docName });
          } else {
            setFile({ file: reader.result, name: docName });
          }

          actions.onSR(actionMethod, {
            file: {
              name: docName, type, component, doc: readerResult,
            },
          });
        };

        reader.readAsDataURL(doc);
      }
    }
  };

  const { checkSign } = useEU();
  const onCheckSign = useCallback(
    (idx) => {
      const loader = async () => {
        const r = await actions.onSR('get_sign', { index: idx }, false);
        return checkSign(r[md.columns.signResult.name], r[md.columns.sendJson.name]);
      };
      setCheckSignInfo({
        opened: true, ownerInfo: null, timeInfo: null, err: null,
      });
      loader()
        .then((d) => setCheckSignInfo(
          (o) => ({ ...o, ownerInfo: d[0].ownerInfo, timeInfo: d[0].timeInfo }),
        ))
        .catch((e) => setCheckSignInfo(
          (o) => ({ ...o, err: e.message }),
        ));
    },
    [actions, checkSign],
  );

  // const onDelSign = useCallback(async (idx) => {
  //   await actions.onSR('del_sign', { index: idx }, false);
  // }, [actions]);

  const onDelSign = useCallback(async (idx) => {
    await actions.onSR('del_sign', { index: idx }, false);
  }, [actions]);

  const containerRef = useRef();

  return (
    <EditorCollapse ref={containerRef}>
      {typeReport === 'Прогноз' && isMessageSentError && (<AlertMessage />)}

      {typeReport === 'РешениеСессии1' && sessioDecigionSentError && (<AlertMessageSession />)}

      {responseText && (<AlertSuccess />)}

      {err && (<AllertErr />)}
      <Modal
        container={containerRef?.current}
        show={checkSignInfo.opened}
        onHide={() => setCheckSignInfo((o) => ({ ...o, opened: false }))}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Результат перевірки підпису
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkSignInfo.err && (
            <Alert variant="danger">{checkSignInfo.err}</Alert>
          )}
          {checkSignInfo.ownerInfo && (
            <>
              <h5 className="text-success">Документ підписано!!!</h5>
              <ul className="list-unstyled">
                <li>
                  <FontAwesomeIcon
                    icon={faUser}
                    title="Особа, що підписала документ"
                    className="me-2"
                    fixedWidth
                  />
                  {checkSignInfo.ownerInfo.subjFullName}
                  <Badge bg="info" className="ms-2">{checkSignInfo.ownerInfo.subjEDRPOUCode}</Badge>
                </li>
                {checkSignInfo.ownerInfo.subjTitle && (
                  <li>
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      title="Посада"
                      className="me-2"
                      fixedWidth
                    />
                    {checkSignInfo.ownerInfo.subjTitle}
                  </li>
                )}
                <li>
                  <FontAwesomeIcon
                    icon={faBuilding}
                    title="Установа"
                    className="me-2"
                    fixedWidth
                  />
                  {checkSignInfo.ownerInfo.subjOrg}
                  <Badge bg="info" className="ms-2">{checkSignInfo.ownerInfo.subjDRFOCode}</Badge>
                </li>
                {checkSignInfo.ownerInfo.subjEMail && (
                  <li>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      title="Електрона адреса"
                      className="me-2"
                      fixedWidth
                    />
                    {checkSignInfo.ownerInfo.subjEMail}
                  </li>
                )}
                {checkSignInfo.timeInfo.signTimeStamp && (
                  <li>
                    <FontAwesomeIcon
                      icon={faClock}
                      title="Час підписання"
                      className="me-2"
                      fixedWidth
                    />
                    Підписано у
                    <span className="ms-2 text-primary">
                      {checkSignInfo.timeInfo.signTimeStamp.toLocaleDateString('uk', {
                        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit',
                      })}
                    </span>
                  </li>
                )}
              </ul>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={signDataPage}
        onHide={() => setSignDataPage(false)}
        size="lg"
        fullscreen
        dialogClassName="mw-100"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignData
            data={sendJson}
            onAddSign={addSign}
            onClose={() => setSignDataPage(false)}
            index={signIdx}
          />
        </Modal.Body>
      </Modal>

      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <Row>
        <Col>
          <Card>
            <Card.Header><h6>Загальна інформація</h6></Card.Header>
            <Card.Body>
              <Row>
                <FontAwesomeIcon icon={faClock} title={md.columns.date.label} className="me-1" fixedWidth />
                <DateLabel value={data[md.columns.date.name]} displayFormat="long" className="me-2" />
              </Row>
              <Row>
                <FontAwesomeIcon icon={faClock} title={md.columns.createDate.label} className="me-1" fixedWidth />
                <DateLabel value={data[md.columns.createDate.name]} displayFormat="long" className="me-2" />
              </Row>
              <Row>
                <UserShow user={data[md.columns.author.name]} title={md.columns.author.label} iconSize="xs" className="ps-1" />
              </Row>
              <Row>
                <FontAwesomeIcon icon={faFileLines} title={md.columns.typeReport.label} className="me-1" fixedWidth />
                <span>{reportTypeToUk(data[md.columns.typeReport.name])}</span>
              </Row>
              <Row className="mt-2">
                <UserShow
                  user={data[md.columns.sendAuthor.name]}
                  title={md.columns.sendAuthor.label}
                  iconSize="xs"
                  className="ps-1"
                />
                {data[md.columns.sendAuthor.name]?.id === emptyUid && (
                  <i className="align-self-center">Не відправлено</i>
                )}
              </Row>
              <Row>
                <FontAwesomeIcon icon={faClock} title={md.columns.sendDate.label} className="me-1" fixedWidth />
                {data[md.columns.sendDate.name] && (<DateLabel value={data[md.columns.sendDate.name]} displayFormat="long" className="me-2" />)}
                {!data[md.columns.sendDate.name] && (<i>Не відправлено</i>)}
              </Row>
              {sessionOptions.get('is_admin', false)
            && (
              <Row>
                <FontAwesomeIcon icon={faFileLines} title={md.columns.idTans.label} className="me-1" fixedWidth />
                <span>{reportTypeToUk(data[md.columns.idTans.name])}</span>
              </Row>
            )}
              <Row>
                <FontAwesomeIcon icon={faFile} title={md.tables.general.label} className="me-1" fixedWidth />
                <div className="d-inline-flex gap-2">
                  {data[md.tables.general.name] && data[md.tables.general.name].map((doc) => (
                    <Button
                      key={doc.Док.id}
                      variant="link"
                      as={Link}
                      to={`/${doc1c[doc.Док.modelName]?.frontend}/${doc.Док.id}/`}
                      className="p-0"
                    >
                      {doc.Док.repr}
                    </Button>
                  ))}
                </div>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col>
              {typeReport === 'Прогноз'
                ? (
                  <>
                    <SharedFile
                      docTitle="Текстова форма прогнозу"
                      file={file}
                      fileHref={filePdfPrognoz}
                      handleUploadFile={handleUploadFile}
                      method="ADD_TEXT_FORM"
                    />
                    <SharedFile
                      docTitle="Документ про схвалення прогнозу"
                      file={secondFile}
                      fileHref={filePdfRish}
                      handleUploadFile={handleUploadFile}
                      isSecondFile
                      method="ADD_ACCESS_DOC"
                    />
                  </>
                )
                : (typeReport === 'ПаспортБП'
                  ? (
                    <SharedFile
                      docTitle="Увага, для даного типу документу прикріплений файл скан-копії є обов'язковим"
                      file={file}
                      fileHref={filePdf}
                      handleUploadFile={handleUploadFile}
                      method="ADD_ATTACHED_FILE"
                    />
                  )
                  : (
                    <SharedFile
                      file={file}
                      fileHref={filePdf}
                      handleUploadFile={handleUploadFile}
                      method="ADD_ATTACHED_FILE"
                    />
                  )
                )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Card>
            <Card.Header><h6>Підписи документу</h6></Card.Header>
            <Card.Body>
              {signList.map((el, idx) => (
                <SignatoryRow
                  onSign={() => {
                    setSignIdx(idx);
                    setSignDataPage(true);
                  }}
                  step={idx + 1}
                  signatory={el}
                  signed={signed(idx)}
                  onCheckSign={onCheckSign}
                  onDelSign={onDelSign}
                />
              ))}
            </Card.Body>
          </Card>
          <Row className="mt-2">
            <ButtonGroup>
              {(!ticket || (responseText === 'Необхідно авторизуватись')) && (
                <Col>
                  <StyledLink to="/dp/authentication/">
                    Авторизація у LOGICA
                  </StyledLink>
                </Col>
              )}
              <Col>
                <CPButton
                  className={((!enoughSignatures || !ticket || signList.size === 0 || !logicaPingSuccess) && 'btn btn-primary btn-block') || 'btn btn-success btn-block'}
                  onClick={() => {
                    actions.onSR('SEND_TO_LOGICA', { ticket });
                    setChanged(false);
                  }}
                  disabled={!enoughSignatures || !ticket
                      || signList.size === 0 || !logicaPingSuccess}
                  title="Надіслати до LOGICA"
                  icon={faPaperPlane}
                  content=" Надіслати до LOGICA"
                />
              </Col>
              {(responseText === msgAlertSuccess) && (
                <Col>
                  <StyledLink to="/dp/documentsInLogica/">
                    Результат верифікації
                  </StyledLink>
                </Col>
              )}
            </ButtonGroup>
          </Row>
        </Col>
      </Row>
    </EditorCollapse>
  );
}

DocEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    // [md.columns.isApproved.name]: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
  setChanged: PropTypes.func.isRequired,
};

export default DocEditor;
