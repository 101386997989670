import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'ID77', name: 'Ид77', visible: false },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
};
  // frontend: `${frontendPrefix}/StewardGroup`,
  //   backend: `${backendPrefix}/ГруппыРаспорядителя`,
  //   listColumns: [...reqListColumns],
  //   hierarchy: true,
  //   hierarchyType: hierarchyTypes.HierarchyFoldersAndItems,
  // label: 'Групування розпорядників',
  // name: 'ГруппыРаспорядителя',

export const stewardGroup = {
  label: 'Групування розпорядників',
  name: 'stewardGroup',
  backendName: 'ГруппыРаспорядителя',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/stewardGroup',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default stewardGroup;
