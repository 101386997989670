import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import {
  changeField, processServerReq, generate,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/addressListCR';
import Fields from '../../field';
import GetNumberField from '../../field/NumberInput';
import TableEditor from '../../tableEditor';
import {
  ContainerSettihgs,
  ThreeColumnsGridContainer,
  StyledLabel,
  Title,
  TwoColumnsGridContainer,
  CommandPanelColor,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import ItemField from '../../field/selectorField/item/itemField';
import DateInput from '../../../components/fields/dates/dateInput';
import ReportContainer from '../reportContainer';

const YearInput = GetNumberField(4, 0, false);

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ThreeColumnsStyle = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 80% 15%;
   // grid-template-columns: 30% 30% 30%;
  grid-column-gap: 5%;
  align-items: start;
`;
const TwoColGridContainer = styled(TwoColumnsGridContainer)`
  margin-top: 10px;
`;
const ColorButton = styled(CommandPanelColor)`
  width: fit-content;
  padding: 9.5px 10px;
`;

class ReportAddressListCREditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    sessionOptions: new Map(),
    portalOpened: false,
    onReportDetails: null,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;
    const isChanged = !sessionOptions.get('is_chief_building');
    const PrintingTypes = {
      repairType: { label: 'Друк по видам ремонту', value: '1' },
      workType: { label: 'Друк по видам робiт', value: '2' },
    };

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );

    const SettingsButton = (
      <TwoColGridContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.typesRepair.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('typesRepair', new Map())}
            modelType="cat"
            modelName="typesRepair"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'typesRepair'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.typeWork.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('typeWork', new Map())}
            modelType="cat"
            modelName="typesWork"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'typeWork'], v))}
          />
        </div>
      </TwoColGridContainer>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        {' '}
        <div>

          <ContainerSettihgs>
            <ThreeColumnsGridContainer>
              <div>
                <StyledLabel>{md.columns.industry.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('industry', new Map())}
                  modelType="cat"
                  modelName="kbpClassifier"
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'industry'], v))}
                  canCleared
                />
              </div>
              <div>
                <StyledLabel>{md.columns.period.label}</StyledLabel>
                <DateInput
                  value={headerForm.get('period', null)}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
                />
              </div>
              {isChanged
            && (
              <div>
                <StyledLabel>{md.columns.year.label}</StyledLabel>
                <YearInput
                  border
                  radius
                  background
                  value={headerForm.get('year', 5)}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
                />
              </div>
            )}
            </ThreeColumnsGridContainer>
            <ThreeColumnsStyle>
              <div>
                <Title>Адреса</Title>
                <ThreeColumnsGridContainer>
                  <div>
                    <StyledLabel>{md.columns.adminDistrict.label}</StyledLabel>
                    <Fields.SelectorField
                      value={headerForm.get('adminDistrict', new Map())}
                      modelType="cat"
                      modelName="districts"
                      onChange={(e, v) => dispatch(changeField(['headerForm', 'adminDistrict'], v))}
                      canCleared
                    />
                  </div>
                  <div>
                    <StyledLabel>{md.columns.street.label}</StyledLabel>
                    <Fields.SelectorField
                      value={headerForm.get('street', new Map())}
                      modelType="cat"
                      filter={[{ fieldName: 'Владелец', value: headerForm.get('adminDistrict', new Map()) }]}
                      modelName="streets"
                      onChange={(e, v) => dispatch(changeField(['headerForm', 'street'], v))}
                      canCleared
                    />
                  </div>
                  <div>
                    <StyledLabel>{md.columns.houses.label}</StyledLabel>
                    <Fields.SelectorField
                      value={headerForm.get('houses', new Map())}
                      filter={[{ fieldName: 'Владелец', value: headerForm.get('street', new Map()) }]}
                      modelType="cat"
                      modelName="houses"
                      onChange={(e, v) => dispatch(changeField(['headerForm', 'houses'], v))}
                      canCleared
                    />
                  </div>
                </ThreeColumnsGridContainer>
              </div>
              <div>
                <Title>Налаштування друку</Title>
                <Fields.CheckboxField
                  value={headerForm.get('fixingTitle', false)}
                  label={md.columns.fixingTitle.label}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'fixingTitle'], v))}
                />
                <RadioButton
                  value={headerForm.get('printingType', false)}
                  onChange={(e, v) => {
                    dispatch(changeField(['headerForm', 'printingType'], v));
                    dispatch(processServerReq('CHANGE_PRINTING_TYPE'));
                  }}
                  values={Object.values(PrintingTypes)}
                  name="printingType"
                />
              </div>
            </ThreeColumnsStyle>
            {!isChanged
            && (
              <ColorButton
                text="Сформувати"
                onClick={() => dispatch(generate())}
              >
                Сформувати
              </ColorButton>
            )}
          </ContainerSettihgs>

          <ResultSegment>
            <TableEditor
              mountNode={[`rep/${md.name}/reportEditor`, 'result']}
              detailsReport={this.detailsReport}
            />
          </ResultSegment>
        </div>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportAddressListCREditor);
