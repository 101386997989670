import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  year: { label: 'Рік', name: 'Год' },
  FT110: { label: 'Щоденна інформація про стан виконання доходів місц. бюджетів', name: 'ДатаДоходов' },
  VD0: { label: 'Щоденна інформація про стан виконання МБ за видатками', name: 'ДатаРасходов' },
  VPF: { label: 'Видатки та кредитування', name: 'ДатаКредитов' },
  BLS: { label: 'Щомісячна інформація про залишки коштів на рахунках 3142 та 3152', name: 'ДатаОстаткиНаСчетах' },
  FU: { label: 'Щоденна інформація про зареєстровані бюджетні зобов\'язання розпорядників (одержувачів) бюджетних коштів', name: 'ДатаЮридическиеОбязательства' },
  FF: { label: 'Щоденна інформація про зареєстровані в органах Казначейства бюджетні фінансові зобов\'язання розпорядників (одержувачів) бюджетних коштів', name: 'ДатаФинансовыеОязательства' },
  B: { label: 'Відомості про зарахування та повернення коштів з аналітичних рахунків. Технологічні файли', name: 'ДатаКредитов' },
  DM_2020: { label: 'Виконання місцевих бюджетів за доходами у розрізі усіх місцевих бюджетів (до рівня села, селища)', name: 'ДатаDM' },
  V2MF_2020: { label: 'Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища)', name: 'ДатаV2MF' },
  V2MB_2020: { label: 'Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища)', name: 'ДатаV2MB' },
  KRM_2020: { label: 'Виконання місцевих бюджетів за кредитуванням у розрізі усіх місцевих бюджетів (до рівня села, селища)', name: 'ДатаKRM' },
  FM_2020: { label: 'Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов\'язання у розрізі усіх місцевих бюджетів (до рівня села, селища)', name: 'ДатаFM' },
  BRMB_M: { label: 'Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів', name: 'ДатаBRMB_M' },
  V7MZ_2020: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ)- "загальний фонд"', name: 'ДатаV7MZ' },
  V7MS_2020: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ)- "спеціальний фонд"', name: 'ДатаV7MS' },
  V73Z_2020: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - "загальний фонд"', name: 'ДатаV73Z' },
  V73S_2020: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - "спеціальний фонд"', name: 'ДатаV73S' },
  ZAH_MB: { label: 'Інформація про виконання захищених видатків місцевих бюджетів', name: 'ДатаZAH_MB' },
  MZS_2020: { label: 'Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій', name: 'ДатаMZS' },
  RZM_M: { label: 'Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щомісячна інформація', name: 'ДатаRZM_M' },
  RZM_Y: { label: 'Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щорічна інформація', name: 'ДатаRZM_Y' },
  V2KB_2020: { label: 'Виконання місцевих бюджетів за видатками у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - за коштами на рахунках в банках', name: 'ДатаV2KB_2020' },
  BRMB_Q: { label: 'Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів', name: 'ДатаBRMB_Q' },
  MZS_2020Q: { label: 'Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об\'єднаних територіальних громад', name: 'ДатаMZS_2020Q' },
  CTANBORG: { label: 'Інформація про стан місцевих боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад', name: 'ДатаCTANBORG' },
  CTGARNBORG: { label: 'Інформація про стан гарантованих відповідно Автономною Республікою Крим, обласними радами та територіальними громадами боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад', name: 'ДатаCTGARNBORG' },
  GARANTMB: { label: 'Інформація про надані місцеві гарантії у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад (щоквартальна, щорічна)', name: 'ДатаGARANTMB' },
  DMZ_2020: { label: 'Виконання місцевих бюджетів за доходами, загальний фонд', name: 'ДатаDMZ_2020' },
  DMS_2020: { label: 'Виконання місцевих бюджетів за доходами, cпеціальний фонд', name: 'ДатаDMS_2020' },
  V2MZ_2020: { label: 'Виконання місцевих бюджетів за видатками (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього', name: 'ДатаV2MZ_2020' },
  V2MS_2020: { label: 'Виконання місцевих бюджетів за видатками (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього', name: 'ДатаV2MS_2020' },
  KRMZ_2020: { label: 'Виконання місцевих бюджетів за видатками – кредитування (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього', name: 'ДатаKRMZ_2020' },
  KRMS_2020: { label: 'Виконання місцевих бюджетів за видатками – кредитування (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього', name: 'ДатаKRMS_2020' },
  FMZ_2020: { label: 'Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього', name: 'ДатаFMZ_2020' },
  FMS_2020: { label: 'Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього', name: 'ДатаFMS_2020' },
  V7MZ_2020Q: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - загальний фонд', name: 'ДатаV7MZ_2020Q' },
  V7MS_2020Q: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - спеціальний фонд', name: 'ДатаV7MS_2020Q' },
  V73Z_2020Q: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - загальний фонд', name: 'ДатаV73Z_2020Q' },
  V73S_2020Q: { label: 'Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - спеціальний фонд', name: 'ДатаV73S_2020Q' },
  FT01: { label: 'Інформація про стан виконання доходів державного бюджету за територіями', name: 'ДатаДоходовГосбюджет' },
};

const definition = {
  label: 'Імпорт казачейських файлів з Logika',
  name: 'logikaImportTreasureFiles',
  columns,
  frontend: 'dp/logikaImportTreasureFiles',
  backendName: 'ИмпортКазначейскихФайловИзЛогики',
};

export default definition;
