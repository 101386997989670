import React, { useState } from 'react';
import { Map } from 'immutable';
import { Modal } from 'react-bootstrap';
import CreateElementEditor from '../../../dataProcessors/createElement/editor';

const mapState = (store) => {
  const Budget = store.getIn(['auth', 'sessionOptions', 'budget'], new Map());
  const FI = store.getIn(['auth', 'sessionOptions', 'fo'], new Map());
  return new Map({
    Budget,
    FI,
  });
};

function CreateElementsModal() {
  const [opened, setOpened] = useState(false);

  return (
    <Modal show={opened} onHide={() => setOpened(false)} size="lg">
      <Modal.Header closeButton />
      <Modal.Body>
        <CreateElementEditor
          mapInitialOptions={{
            FI: 'FI',
            Budget: 'Budget',
          }}
          mapStore={mapState}
        />

      </Modal.Body>

    </Modal>
  );
}

export default CreateElementsModal;
