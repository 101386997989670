import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/freeRem4Add';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

import { ContainerSt, StyledLabel } from '../../../../components/Form/styledForm';

import { InputWithDropdown } from '../../../../components/styledInputs';
import { StampApprovedJs } from '../../../../assets/icons';

const repMonth = {
  m1: { label: 'Січень', name: 1 },
  m2: { label: 'Лютий', name: 2 },
  m3: { label: 'Березень', name: 3 },
  m4: { label: 'Квітень', name: 4 },
  m5: { label: 'Травень', name: 5 },
  m6: { label: 'Червень', name: 6 },
  m7: { label: 'Липень', name: 7 },
  m8: { label: 'Серпень', name: 8 },
  m9: { label: 'Вересень', name: 9 },
  m10: { label: 'Жовтень', name: 10 },
  m11: { label: 'Листопад', name: 11 },
  m12: { label: 'Грудень', name: 12 },
};

function FreeRem4Add({
  data, actions, permissions,
}) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = !permissions.canChange || data[md.columns.apprByRegion.name];
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col lg={3}>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col lg={3}>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.reportMonth.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repMonth)}
            value={data[md.columns.reportMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reportMonth.name]: value,
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {data[md.columns.apprByRegion.name] && (
            <>
              <ContainerSt>
                <StampApprovedJs text={data[md.columns.regionInvoice.name]} />
              </ContainerSt>
              {sessionOptions.get('is_admin', false) && (
                <EditorControls.StringInput
                  label={md.columns.regionInvoice.label}
                  value={data[md.columns.regionInvoice.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.regionInvoice.name]: value,
                  })}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </EditorCollapse>

  );
}

FreeRem4Add.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FreeRem4Add;
