import React, { useCallback, useRef, useState } from 'react';
import { ButtonGroup, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  dataSourceFillUpLevelDoc,
  nameFillUpLevelDocButton,
  titleFillUpLevelDocModal,
} from '../def';
import { soSelector } from '../../_common/selectors';
import DocSelectionUpLevelBoostraped from '../../../dataProcessors/docSelectionUpLevelBoostraped';
import { referencePropType } from '../../../newEditor/propTypes';
import enums from '../../../../constants/meta/enums';

function FillFromUpLevelDoc({
  CSU, year, fondObject, FI, planingMode, readOnly, onOk,
}) {
  const [openModal, setOpenModal] = useState(false);
  const ref = useRef();

  const sessionOptions = useSelector(soSelector);
  const isFinManagment = sessionOptions.get('isFinManagment', false);
  const isAdmin = sessionOptions.get('is_admin', false);

  const okHandler = useCallback(
    (data) => {
      setOpenModal(false);
      onOk(data);
    },
    [onOk],
  );

  return (!isFinManagment || isAdmin) && (
    <>
      <ButtonGroup>
        <Button
          onClick={() => setOpenModal(true)}
          disabled={readOnly}
          ref={ref}
          className="mt-1"
        >
          <FontAwesomeIcon icon={faFileImport} className="me-2" />
          {nameFillUpLevelDocButton}
        </Button>
      </ButtonGroup>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton style={{ zIndex: 2000 }}>
          <Modal.Title>
            {titleFillUpLevelDocModal}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocSelectionUpLevelBoostraped
            CSU={CSU}
            year={year}
            fondObject={fondObject}
            FI={FI}
            planingMode={planingMode}
            dataSource={dataSourceFillUpLevelDoc[planingMode]}
            onLoad={okHandler}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

FillFromUpLevelDoc.propTypes = {
  onOk: PropTypes.func.isRequired,
  CSU: referencePropType,
  year: PropTypes.number,
  fondObject: referencePropType,
  FI: referencePropType,
  planingMode: PropTypes.oneOf([
    enums.PlaningKind.permanent.name,
    enums.PlaningKind.temporary.name,
  ]),
  readOnly: PropTypes.bool,
};

FillFromUpLevelDoc.defaultProps = {
  CSU: null,
  year: null,
  fondObject: null,
  FI: null,
  planingMode: 0,
  readOnly: false,
};

export default FillFromUpLevelDoc;
