import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return <TableHeaderRow noGutters className="px-4" />;
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
