const frontendURL = '/cat/paymentpurposetemplate/';
const backendURL = '/api/references/refpaymentpurposetemplate/';
const name = 'Шаблони призначень платежу';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
