import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  year: { label: 'Рік', name: 'ТекГод' },
  year2: { label: 'Рік2', name: 'ТекГод2' },
  year3: { label: 'Рік3', name: 'ТекГод3' },
  quart: { label: 'Квартал', name: 'ТекКвартал' },
  onDate: { label: 'На дату', name: 'НаДату' },
  cForm: { label: 'Форма звіту', name: 'ТекФормаОтчета' },
  cModel: { label: 'ТекМакетОтчета', name: 'ТекМакетОтчета' },
  consolidated: { label: 'Зведений бюджет', name: 'Сводный' },
};

const definition = {
  label: 'Перегляд казначейскіх звітів',
  name: 'RevTreasureReport',
  columns,
  frontend: 'rep/RevTreasureReport',
  backendName: 'ПросмотрКазнначейскихОтчетов',
  resultColumns: baseResultColumns,
};

export default definition;
