/* eslint-disable react/jsx-props-no-spreading,no-case-declarations */
import React, {
  memo, useMemo, forwardRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import styled from 'styled-components';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import Fields from '../../../field';
import def from '../../../../constants/meta';
import getValueArray from './valueArray';
import { selectionMode } from '../../../../constants/meta/common';
import Checkbox from '../../../../components/styledCheckbox/styledCheckbox';
import { InputWithDropdown } from '../../../../components/styledInputs';
import { ItemContainer, ItemText } from '../StyledComponents';

const active = {
  background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21',
};

const StyledItemContainer = styled(ItemContainer).attrs((props) => ({
  style: {
    ...(props.active ? active : {}),
    paddingLeft: `${props.level}em`,
  },
}))`
  align-items: flex-start;
  border: 1px solid #DDE2E9;
  border-radius: 4px;
  background: #e9f0f85e;
  & > :last-child{
    flex-grow: 1;
    width: 60%;
  }
`;

const StyledNoEdit = styled.div`
  display: grid;
  grid-template-columns: 7% 46% 47%;
  width: 40%;
  align-items: center;
  & > * {
    padding-right: 5px;
  }
`;

const PositionDiv = styled.div`
  align-self: flex-start;
`;

// const NubmberInput = Fields.GetNumberInput(20, 6);

const FilterItem = forwardRef(
  ({
    id, item, availableField, toggleUseHandler, onClickHandler, onDoubleClickHandler,
    onChangeItem, dragHandleProps, draggableProps, filterChoice,
  }, ref) => {
    const isActive = item.get('_SELECTED', false);

    const changeItem = useCallback(
      (e, partValues) => {
        const newItem = Object.keys(partValues).reduce(
          (oldItem, field) => oldItem.set(field, partValues[field]),
          item,
        );
        onChangeItem(e, id, newItem);
      },
      [id, item, onChangeItem],
    );

    const VC = useMemo(
      () => {
        const getComponentByType = (type) => {
          const modelType = type.get('ModelType');
          const otherProps = type.get('ModelName');
          switch (modelType) {
            case 'cat': // Справочник
              const modelName = Object.values(def.cat).reduce((R, d) => (d.backendName === otherProps ? d.name : R), '');

              const leftValue = item.get('LeftValue');

              return {
                component: Fields.SelectorField,
                props: {
                  canCleared: false,
                  modelType: 'cat',
                  modelName,
                  noHierarchy: false,
                  value: new Map(),
                  filter: filterChoice.get(leftValue, []),
                  choiceSettings: selectionMode.foldersNItems,
                },
                defaultValue: new Map(),
              };
            case 'doc': // Документ
              const docModelName = Object.values(def.doc).reduce((R, d) => (d.backendName === otherProps ? d.name : R), '');

              const docLeftValue = item.get('LeftValue');

              return {
                component: Fields.SelectorField,
                props: {
                  canCleared: false,
                  modelType: 'doc',
                  modelName: docModelName,
                  noHierarchy: false,
                  value: new Map(),
                  filter: filterChoice.get(docLeftValue, []),
                },
                defaultValue: new Map(),
              };
            case 'N':
              const [allSize, fractionSize] = otherProps.split(',');
              const NubmberInput = Fields.GetNumberInput(allSize, fractionSize);
              return {
                component: NubmberInput,
                props: {
                  value: 0,
                  border: true,
                  radius: true,
                  background: true,
                },
                defaultValue: 0,
              };
            case 'S':
              const StringInput = Fields.GetTextInput(otherProps);
              return {
                component: StringInput,
                props: {
                  value: '',
                  border: true,
                  radius: true,
                  background: true,
                },
                defaultValue: '',
              };
            case 'B':
              const BoolInput = InputWithDropdown;
              return {
                component: BoolInput,
                props: {
                  value: false,
                  options: [{ label: 'Так', name: true }, { label: 'Ні', name: false }],
                },
                defaultValue: false,
              };
            default:
              throw new Error(`Type ${type} is not supported by Item Filter Editor`);
            // return null;
          }
        };

        const comparisonType = item.get('ComparisonType');

        const firstType = availableField.get('ValueType', new List()).get(0);

        const isArray = comparisonType === DC.comparisonTypes.inList.value
            || comparisonType === DC.comparisonTypes.inListByHierarchy.value
            || comparisonType === DC.comparisonTypes.notInList.value
            || comparisonType === DC.comparisonTypes.notInListByHierarchy.value;
        const { component, props, defaultValue } = getComponentByType(firstType);
        if (isArray) {
          return React.createElement(
            getValueArray(component),
            {
              defaultValue,
              ...props,
              onChange: (e, v) => changeItem(e, { RightValue: v }),
              value: item.get('RightValue', defaultValue) || new List(),
              active: isActive,
            },
          );
        }
        return React.createElement(
          component,
          {
            ...props,
            onChange: (e, v) => changeItem(e, { RightValue: v }),
            value: item.get('RightValue', defaultValue) || defaultValue,
            active: isActive,
          },
        );
      },
      [availableField, changeItem, filterChoice, isActive, item],
    );

    const comparations = useMemo(
      () => availableField.get('AvailableCompareTypes').reduce((R, ct) => {
        const type = Object.values(DC.comparisonTypes).filter((stdType) => stdType.value === ct);
        if (type.length) {
          return [
            ...R,
            {
              label: type[0].label,
              name: type[0].value,
            },
          ];
        }
        return R;
      }, []),
      [availableField],
    );
    const itemTitle = useMemo(
      () => {
        const result = item.get('Tittle', '');

        if (item.get('Type') === DC.fieldTypes.Auto) {
          return 'Авто';
        }

        return result ? `${result}(${availableField.get('Tittle')})` : availableField.get('Tittle');
      },
      [availableField, item],
    );

    const onChangeComparisonType = useCallback(
      (e, comparisonType) => {
        if (comparisonType === item.get('ComparisonType', '')) return false;
        const isArray = comparisonType === DC.comparisonTypes.inList.value
            || comparisonType === DC.comparisonTypes.inListByHierarchy.value
            || comparisonType === DC.comparisonTypes.notInList.value
            || comparisonType === DC.comparisonTypes.notInListByHierarchy.value;
        if (isArray) {
          changeItem(e, {
            ComparisonType: comparisonType,
            RightValue: List.isList(item.get('RightValue')) ? item.get('RightValue') : new List([item.get('RightValue')]),
          });
        } else {
          changeItem(e, {
            ComparisonType: comparisonType,
            RightValue: !List.isList(item.get('RightValue')) ? item.get('RightValue') : item.getIn(['RightValue', 0]),
          });
        }
        return false;
      },
      [changeItem, item],
    );
    return (
      <StyledItemContainer
        ref={ref}
        active={isActive}
        onClick={(e) => onClickHandler(e, id)}
        onDoubleClick={(e) => onDoubleClickHandler(e, id)}
        {...dragHandleProps}
        {...draggableProps}
      >
        <StyledNoEdit>
          <Checkbox
            value={item.get('Use', false)}
            onChange={(e) => toggleUseHandler(e, id)}
          />
          <ItemText>
            {itemTitle}
          </ItemText>
          <PositionDiv>
            <InputWithDropdown
              options={comparations}
              value={item.get('ComparisonType', '')}
              onChange={onChangeComparisonType}
            />
          </PositionDiv>
        </StyledNoEdit>
        {VC}
      </StyledItemContainer>
    );
  },
);

FilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  availableField: PropTypes.instanceOf(Map),
  toggleUseHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
  onDoubleClickHandler: PropTypes.func,
  onChangeItem: PropTypes.func,
  iRef: PropTypes.func,
  dragHandleProps: PropTypes.shape({}),
  draggableProps: PropTypes.shape({}),
  filterChoice: PropTypes.instanceOf(Map),
};

FilterItem.defaultProps = {
  availableField: new Map(),
  toggleUseHandler: () => null,
  onClickHandler: () => null,
  onDoubleClickHandler: () => null,
  onChangeItem: () => null,
  iRef: () => null,
  dragHandleProps: {},
  draggableProps: {},
  filterChoice: new Map(),
};

export default memo(FilterItem);
