import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes4';

const meta = md.tables.TABL_63;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      className="px-1  d-flex flex-column justify-content-end"
    >
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'number'}
            highlighted={highlights.includes('number')}
            onToggleHighlght={() => onToggleHighlght('number')}
          >
            {meta.columns.number.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'codeDod'}
            highlighted={highlights.includes('codeDod')}
            onToggleHighlght={() => onToggleHighlght('codeDod')}
          >
            {meta.columns.codeDod.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'category'}
            highlighted={highlights.includes('category')}
            onToggleHighlght={() => onToggleHighlght('category')}
          >
            {meta.columns.category.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amountInternal'}
            highlighted={highlights.includes('amountInternal')}
            onToggleHighlght={() => onToggleHighlght('amountInternal')}
          >
            {meta.columns.amountInternal.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount'}
            highlighted={highlights.includes('amount')}
            onToggleHighlght={() => onToggleHighlght('amount')}
          >
            {meta.columns.amount.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
