import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { getDisplayName } from '../../../api/utils';
import { emptyUid } from '../../../constants/meta/common';

export const EditorControlPropTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
    ]),
  ]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,

};

export const EditorControlDefaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
  value: null,
  onFocus: null,
  onBlur: null,
  readOnly: false,
  disabled: false,
};

const withEditorControl = (WrappedControl, alwaysFloated = false) => {
  function EditorInput({
    label, required, errors, description, value, readOnly, disabled,
    onFocus, onBlur, ...restProps
  }) {
    const [floated, setFloated] = useState(false);
    const isEmpty = !alwaysFloated && (value === null
      || (typeof value === 'string' && value.trim() === '')
      || (typeof value === 'object' && (!value.id || value.id === emptyUid)));
    useEffect(() => {
      if (!floated && !isEmpty) setFloated(true);
    }, [floated, isEmpty]);

    const focusHandler = useCallback(
      (e) => {
        setFloated(true);
        if (onFocus) onFocus(e);
      },
      [onFocus],
    );
    const blurHandler = useCallback(
      (e) => {
        setFloated(!isEmpty);
        if (onBlur) onBlur(e);
      },
      [isEmpty, onBlur],
    );
    const inputRef = useRef();
    const onLabelClick = useCallback(
      () => {
        if (inputRef.current) inputRef.current.focus();
      },
      [],
    );
    return (
      <Form.Group className={classNames('form-group', { floated })}>
        {label && (
          <Form.Label
            className={classNames('text-truncate', { 'control-disabled': readOnly || disabled })}
            title={label}
            onClick={onLabelClick}
          >
            {label}
            {required && (<span className="text-danger">*</span>)}
          </Form.Label>
        )}
        <WrappedControl
          {...restProps}
          errored={errors && !!errors.length}
          required={required}
          errText={String(errors)}
          value={value}
          onFocus={focusHandler}
          onBlur={blurHandler}
          readOnly={readOnly}
          disabled={disabled}
          ref={inputRef}
        />
        {!!errors && (
          <Form.Control.Feedback type="invalid">
            {String(errors)}
          </Form.Control.Feedback>
        )}
        {description && (
          <Form.Text className="text-muted">
            {description}
          </Form.Text>
        )}
      </Form.Group>
    );
  }

  EditorInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  EditorInput.propTypes = EditorControlPropTypes;

  EditorInput.defaultProps = EditorControlDefaultProps;

  return EditorInput;
};

export default withEditorControl;
