import React, { memo } from 'react';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const TABLENAME = 'docsTable';
const coldef = md.dp.requestsLoading.tables[TABLENAME].columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="check">{coldef.Check.label}</DivHeader>
      <DivHeader areaName="docNo">{coldef.DocNo.label}</DivHeader>
      <DivHeader areaName="date">{coldef.Date.label}</DivHeader>
      <DivHeader areaName="sum">{coldef.Sum.label}</DivHeader>
      <DivHeader areaName="author">{coldef.Notes.label}</DivHeader>
    </GridTemplate>
  );
}

export default memo(HeaderComponent);
