const frontendURL = '/srv/logicaImportTreasureFiles/';
const backendURL = '/api/logica/import-treasure-data/';
const name = 'Завантаження казначейських файлів';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
