import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  DateInput,
  ItemPicker,
  NumberInput, SelectorInput, StringInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { comparisonTypes, emptyUid } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { NumberOfMounth } from '../../../consts';
import md from '../../../../../../constants/meta/documents/financing';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, CSUinH, FI,
  isFilterCsuElemKFK, Fond, date, activeObjectsBuilding, ShowAccounts, Budget, activeBR,
  isComposeSettingsChecked, Transferting, TransfertingZFToSF, letMinusInRow, ShowSourcesFinInDocFin,
  FinancingReturn,
}) {
  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);
  const DocFinancingRequestFilter = useMemo(
    () => [{ fieldName: 'ФинансовыйОрган', value: FI }],
    [FI],
  );
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const CSUAccountFIlter = useMemo(
    () => [
      { fieldName: 'КодКБП7', value: row[tableMD.columns.KPKVCode.name] },
      { fieldName: 'Фонд', value: Fond },
      { fieldName: 'Владелец', value: row[tableMD.columns.CSU.name] },
      { fieldName: 'флНеДействующий', value: false },
      {
        fieldName: 'ДатаЗакрытия',
        comparisonType: comparisonTypes.greaterOrEqual,
        value: date,
      },
    ],
    [Fond, date, row],
  );
  const baFilter = useMemo(() => [
    { fieldName: 'Владелец', value: FI },
  ], [FI]);
  const notesFilter = useMemo(() => [{ fieldName: 'Владелец', value: Budget }], [Budget]);

  const docNotesFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const ElemKFKFilter = useMemo(
    () => [...FIOwnerFilter, ...(isFilterCsuElemKFK ? [{
      fieldName: 'Распорядитель',
      comparisonType: comparisonTypes.inList,
      value: [
        { id: emptyUid, repr: '' },
        row[tableMD.columns.CSU.name] || { id: emptyUid, repr: '' },
      ],
    }] : [])],
    [FIOwnerFilter, isFilterCsuElemKFK, row],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKEKVChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKEKV.name]: v });
      await onSR('CHANGE_KEKV_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onCSUAccountChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSUAccount.name]: v });
      await onSR('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onBuildObgectChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.BuildObgect.name]: v });
  }, [onRowChange]);
  const onTypeWorkChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.TypeWork.name]: v });
  }, [onRowChange]);
  const onSumChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Sum.name]: v });
  }, [onRowChange]);
  const onNoteStrChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.NoteStr.name]: v });
    await onSR('CHANGE_NOTES_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);
  const onNoteDocChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.NoteDoc.name]: v });
    await onSR('CHANGE_DOCNOTES_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);
  const onNoteChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Note.name]: v });
  }, [onRowChange]);
  const onDateChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.DateTreas.name]: v });
  }, [onRowChange]);
  const onNumberOfPaymentChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.NumberOfPayment.name]: v });
  }, [onRowChange]);
  const onWriteOffChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.WriteOff.name]: v });
  }, [onRowChange]);
  const onIsReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.IsReturn.name]: v });
  }, [onRowChange]);
  const onMounthReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.MounthReturn.name]: v });
  }, [onRowChange]);
  const onDocFinancingRequestChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.DocFinancingRequest.name]: v });
  }, [onRowChange]);
  const onKFBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onFinSourcesChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.finSources.name]: v });
      await onSR('CHANGE_FIN_SOURCES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFBcredChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFBcred.name]: v });
      await onSR('CHANGE_KFB_CRED_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onAnalyticalAccountChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.AnalyticalAccount.name]: v });
      await onSR('CHANGE_ANALITIC_ACC_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onAnalyticalAccountCostsChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.AnalyticalAccountCosts.name]: v });
      await onSR('CHANGE_ANALITIC_ACC_COSTS_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          {!CSUinH
          && (
            <Col sm={12} xl={4}>
              <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                <ItemPicker
                  value={row[tableMD.columns.CSU.name]}
                  modelType="cat"
                  modelName="csu"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSU.name]: v })}
                  filter={CSUFIlter}
                  noHierarchy
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
          <Col sm={12} xl={CSUinH ? 8 : 6}>
            <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
              <ItemPicker
                value={row[tableMD.columns.CSUAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onCSUAccountChange}
                filter={CSUAccountFIlter}
                params={onDateParams}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.CSUAccountNo.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} xl={CSUinH ? 4 : 2}>
            <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
              <NumberInput
                value={row[tableMD.columns.Sum.name]}
                precision={2}
                minValue={letMinusInRow ? Number.MIN_SAFE_INTEGER : 0}
                maxValue={
                  FinancingReturn ? Number.MAX_SAFE_INTEGER : row[tableMD.columns.Balance.name]
                }
                onChange={onSumChange}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        {TransfertingZFToSF && ShowAccounts
      && (
        <Row noGutters>
          <Col sm={12} xl={4}>
            <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFB.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={onKFBChange}
                filter={FIOwnerFilter}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.codeKFB.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            <TableRowCell column="elementKFBcred" highlighted={highlights.includes('elementKFBcred')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFBcred.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={onKFBcredChange}
                filter={FIOwnerFilter}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.codeKFBcred.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} xl={4}>
            <TableRowCell column="AnalyticalAccountCosts" highlighted={highlights.includes('AnalyticalAccountCosts')}>
              <ItemPicker
                value={row[tableMD.columns.AnalyticalAccountCosts.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onAnalyticalAccountCostsChange}
                filter={baFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.AnalyticalAccLabelCosts.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            <TableRowCell column="AnalyticalAccount" highlighted={highlights.includes('AnalyticalAccount')}>
              <ItemPicker
                value={row[tableMD.columns.AnalyticalAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onAnalyticalAccountChange}
                filter={baFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.AnalyticalAccLabel.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>

          <Col sm={12} xl={4}>
            <TableRowCell column="R3261x" highlighted={highlights.includes('R3261x')}>
              <ItemPicker
                value={row[tableMD.columns.R3261x.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onCSUAccountChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="R3262x" highlighted={highlights.includes('R3262x')}>
              <ItemPicker
                value={row[tableMD.columns.R3262x.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onCSUAccountChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      )}
        <Row noGutters>
          <Col
            xl={4}
            lg={(activeObjectsBuilding && !Transferting) ? 8 : 12}
            sm={12}
            className="d-flex"
          >
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKFK.name]}
                    modelType="cat"
                    modelName="elementKFK"
                    onChange={onKFKChange}
                    filter={ElemKFKFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KPKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
                {!Transferting
              && (
                <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKEKV.name]}
                    modelType="cat"
                    modelName="elementKEKV"
                    onChange={onKEKVChange}
                    filter={FIOwnerFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KEKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              )}
                <TableRowCell column="DateTreas" highlighted={highlights.includes('WriteOff')}>
                  <DateInput
                    onChange={onDateChange}
                    value={row[tableMD.columns.DateTreas.name]}
                    readOnly={readOnly || !row[tableMD.columns.WriteOff.name]}
                    prepend={(

                      <InputGroup.Text>
                        <CheckboxInput
                          id={`WriteOff-${row[tableMD.columns.strNumber.name]}`}
                          readOnly={readOnly}
                          value={row[tableMD.columns.WriteOff.name]}
                          onChange={onWriteOffChange}
                        />
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          {activeObjectsBuilding && !Transferting
    && (
      <Col xl={2} lg={4} sm={12} className="d-flex">
        <Row className="w-100" noGutters>
          <Col>
            <TableRowCell column="BuildObgect" highlighted={highlights.includes('BuildObgect')}>
              <ItemPicker
                value={row[tableMD.columns.BuildObgect.name]}
                modelType="cat"
                modelName="objectsBuilding"
                onChange={onBuildObgectChange}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="TypeWork" highlighted={highlights.includes('TypeWork')}>
              <ItemPicker
                value={row[tableMD.columns.TypeWork.name]}
                modelType="cat"
                modelName="typesWork"
                onChange={onTypeWorkChange}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    )}
          <Col xl={(activeObjectsBuilding && !Transferting) ? 6 : 8} sm={12}>
            <Row className="w-100" noGutters>
              {!Transferting
            && (
              <Col
                sm={12}
                lg={((activeBR || activeObjectsBuilding)
                    && !ShowAccounts) ? 4 : 6}
              >
                <TableRowCell column="FinObligation" highlighted={highlights.includes('FinObligation')}>
                  <NumberInput
                    value={row[tableMD.columns.FinObligation.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
                <TableRowCell column="Balance" highlighted={highlights.includes('Balance')}>
                  <NumberInput
                    value={row[tableMD.columns.Balance.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
            )}

              <Col
                sm={12}
                lg={((activeBR || activeObjectsBuilding) && !ShowAccounts) ? 4 : 6}
              >
                <TableRowCell column="NoteStr" highlighted={highlights.includes('NoteStr')}>
                  <ItemPicker
                    value={row[tableMD.columns.NoteStr.name]}
                    modelType="cat"
                    modelName="notes"
                    onChange={onNoteStrChange}
                    readOnly={readOnly}
                    filter={notesFilter}
                  />
                </TableRowCell>
                {!Transferting
            && (
              <TableRowCell column="NoteDoc" highlighted={highlights.includes('NoteDoc')}>
                <ItemPicker
                  value={row[tableMD.columns.NoteDoc.name]}
                  modelType="cat"
                  modelName="docNotes"
                  onChange={onNoteDocChange}
                  readOnly={readOnly}
                  filter={docNotesFilter}
                />
              </TableRowCell>
            )}
              </Col>
              {Transferting
                   && (
                     <Col>
                       <TableRowCell column="NoteDoc" highlighted={highlights.includes('NoteDoc')}>
                         <ItemPicker
                           value={row[tableMD.columns.NoteDoc.name]}
                           modelType="cat"
                           modelName="docNotes"
                           onChange={onNoteDocChange}
                           readOnly={readOnly}
                         />
                       </TableRowCell>
                     </Col>
                   )}
              {
                // (activeBR || activeObjectsBuilding) && !ShowAccounts && (
                <Col sm={12} lg={4}>
                  {/* <TableRowCell column="DateTreas" highlighted={highlights.includes('WriteOff')}> */}
                  {/*  <DateInput */}
                  {/*    onChange={onDateChange} */}
                  {/*    value={row[tableMD.columns.DateTreas.name]} */}
                  {/*    readOnly={readOnly || !row[tableMD.columns.WriteOff.name]} */}
                  {/*    prepend={( */}
                  {/*       */}
                  {/*        <InputGroup.Text> */}
                  {/*          <CheckboxInput */}
                  {/*            id={`WriteOff-${row[tableMD.columns.strNumber.name]}`} */}
                  {/*            readOnly={readOnly} */}
                  {/*            value={row[tableMD.columns.WriteOff.name]} */}
                  {/*            onChange={onWriteOffChange} */}
                  {/*          /> */}
                  {/*        </InputGroup.Text> */}
                  {/*       */}
                  {/*    )} */}
                  {/*  /> */}
                  {/* </TableRowCell> */}
                  {!Transferting && (
                    <TableRowCell column="NumberOfPayment" highlighted={highlights.includes('NumberOfPayment')}>
                      <StringInput
                        value={row[tableMD.columns.NumberOfPayment.name]}
                        onChange={onNumberOfPaymentChange}
                        readOnly={readOnly}
                      />
                    </TableRowCell>
                  )}
                </Col>
              // )
              }
              {Transferting && (
                <Col>
                  <TableRowCell column="NumberOfPayment" highlighted={highlights.includes('NumberOfPayment')}>
                    <StringInput
                      value={row[tableMD.columns.NumberOfPayment.name]}
                      onChange={onNumberOfPaymentChange}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {activeObjectsBuilding && Transferting
    && (
      <Row noGutters>
        <Col lg={6} sm={12}>
          <TableRowCell column="BuildObgect" highlighted={highlights.includes('BuildObgect')}>
            <ItemPicker
              value={row[tableMD.columns.BuildObgect.name]}
              modelType="cat"
              modelName="objectsBuilding"
              onChange={onBuildObgectChange}
              readOnly={readOnly}
            />
          </TableRowCell>
        </Col>
        <Col lg={6} sm={12}>
          <TableRowCell column="TypeWork" highlighted={highlights.includes('TypeWork')}>
            <ItemPicker
              value={row[tableMD.columns.TypeWork.name]}
              modelType="cat"
              modelName="typesWork"
              onChange={onTypeWorkChange}
              readOnly={readOnly}
            />
          </TableRowCell>
        </Col>
      </Row>
    )}
        {ShowSourcesFinInDocFin && (
          <Row noGutters>
            <Col sm={12} lg={4}>
              <TableRowCell column="codeFinSources" highlighted={highlights.includes('codeFinSources')}>
                <StringInput
                  value={row[tableMD.columns.codeFinSources.name]}
                  readOnly
                />
              </TableRowCell>
            </Col>
            {(row[tableMD.columns.finSources.name] === null || row[tableMD.columns.finSources.name] === undefined
              || row[tableMD.columns.finSources.name].modelName === 'ЭлементыКФБ'
              || row[tableMD.columns.finSources.name].id === emptyUid) && (
              <Col
                sm={12}
                lg={
                  row[tableMD.columns.finSources.name] === null || row[tableMD.columns.finSources.name] === undefined
                  || row[tableMD.columns.finSources.name].id === emptyUid
                    ? 4 : 8
                }
              >
                <TableRowCell column="finSources" highlighted={highlights.includes('finSources')}>
                  <ItemPicker
                    value={row[tableMD.columns.finSources.name]}
                    modelType="cat"
                    modelName="elementKFB"
                    onChange={onFinSourcesChange}
                    filter={FIOwnerFilter}
                    noHierarchy
                    readOnly={readOnly}
                    canErase
                    prepend={(

                      <InputGroup.Text>
                        КФБ
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            )}
            {(row[tableMD.columns.finSources.name] === null || row[tableMD.columns.finSources.name] === undefined
              || row[tableMD.columns.finSources.name].modelName === 'ЭлементыКДБ'
              || row[tableMD.columns.finSources.name].id === emptyUid) && (
              <Col
                sm={12}
                lg={
                  row[tableMD.columns.finSources.name] === null || row[tableMD.columns.finSources.name] === undefined
                  || row[tableMD.columns.finSources.name].id === emptyUid
                    ? 4 : 8
                }
              >
                <TableRowCell column="finSources" highlighted={highlights.includes('finSources')}>
                  <ItemPicker
                    value={row[tableMD.columns.finSources.name]}
                    modelType="cat"
                    modelName="elementKDB"
                    onChange={onFinSourcesChange}
                    filter={FIOwnerFilter}
                    noHierarchy
                    readOnly={readOnly}
                    canErase
                    prepend={(

                      <InputGroup.Text>
                        КДБ
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            )}
          </Row>
        )}
        <Row noGutters>
          <Col sm={12} xl={(isComposeSettingsChecked && !ShowAccounts) ? 6 : 8}>
            <TableRowCell column="Note" highlighted={highlights.includes('Note')}>
              <TextInput
                value={row[tableMD.columns.Note.name]}
                onChange={onNoteChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} lg={(isComposeSettingsChecked && !ShowAccounts) ? 6 : 12} xl={4}>
            <TableRowCell column="DocFinancingRequest" highlighted={highlights.includes('DocFinancingRequest')}>
              <ItemPicker
                value={row[tableMD.columns.DocFinancingRequest.name]}
                modelType="doc"
                modelName="financingRequest"
                onChange={onDocFinancingRequestChange}
                readOnly={readOnly}
                filter={DocFinancingRequestFilter}
              />
            </TableRowCell>
          </Col>
          {isComposeSettingsChecked && !ShowAccounts && (
            <Col sm={12} lg={6} xl={2}>
              <TableRowCell column="MounthReturn" highlighted={highlights.includes('IsReturn')}>
                <SelectorInput
                  background="inherit"
                  readOnly={readOnly || !row[tableMD.columns.IsReturn.name]}
                  value={row[tableMD.columns.MounthReturn.name]}
                  values={Object.values(NumberOfMounth)}
                  onChange={onMounthReturnChange}
                  prepend={(

                    <InputGroup.Text>
                      <CheckboxInput
                        id={`IsReturn-${row[tableMD.columns.strNumber.name]}`}
                        readOnly={readOnly}
                        value={row[tableMD.columns.IsReturn.name]}
                        onChange={onIsReturnChange}
                      />
                    </InputGroup.Text>

                  )}
                />
              </TableRowCell>
            </Col>
          )}
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  CSUinH: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  isFilterCsuElemKFK: PropTypes.bool.isRequired,
  Fond: referencePropType.isRequired,
  Budget: PropTypes.shape({
  }).isRequired,
  date: PropTypes.number.isRequired,
  activeObjectsBuilding: PropTypes.bool.isRequired,
  ShowAccounts: PropTypes.bool.isRequired,
  activeBR: PropTypes.bool.isRequired,
  isComposeSettingsChecked: PropTypes.bool.isRequired,
  Transferting: PropTypes.bool,
  TransfertingZFToSF: PropTypes.bool,
  letMinusInRow: PropTypes.bool.isRequired,
  ShowSourcesFinInDocFin: PropTypes.bool.isRequired,
  FinancingReturn: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  Transferting: false,
  TransfertingZFToSF: false,
};

export default memo(TPRow);
