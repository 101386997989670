const frontendURL = '/doc/changesToAssignmentPlanning/';
const backendURL = '/api/authorityplans/allocationplanchangesheader/';
// const printURL = '/api/authorityplans/printform/allocationplanheader/app_form1/';
const name = 'Зміни до плану асигнувань';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
