import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

const BACKENDURL = meta.cat.indicatorclassifier.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const fullnameProps = editorHooks.useStringInputProps('fullname', data, fields, fieldErrors, actions.onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, fieldErrors, actions.onChange);
  const kbpProps = editorHooks.useItemInputProps('kbp', data, fields, fieldErrors, actions.onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const unitProps = editorHooks.useItemInputProps('unit', data, fields, fieldErrors, actions.onChange);
  const taskClassifierProps = editorHooks.useItemInputProps('task_classifier', data, fields, fieldErrors, actions.onChange);
  const traitTypeProps = editorHooks.useSelectorInputProps('trait_type', data, fields, fieldErrors, actions.onChange);
  const indicatorTypeProps = editorHooks.useSelectorInputProps('indicator_type', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.name}
    >
      <Container fluid className="pb-3">
        {data.is_group && (
          <div className="w-100 text-end">
            <Badge bg="info">Група</Badge>
          </div>
        )}
        <Row>
          <Col md={5}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col md={5}>
            <EditorControls.ItemPicker {...parentProps} />
          </Col>
          <Col sm={2}>
            <EditorControls.ItemPicker {...unitProps} />
          </Col>
          <Col sm={3}>
            <EditorControls.ItemPicker {...kbpProps} />
          </Col>
          <Col sm={3}>
            <EditorControls.ItemPicker {...taskClassifierProps} />
          </Col>
          <Col sm={3}>
            <EditorControls.SelectorInput {...traitTypeProps} />
          </Col>
          <Col sm={3}>
            <EditorControls.SelectorInput {...indicatorTypeProps} />
          </Col>
          <Col sm={12}>
            <EditorControls.TextInput {...fullnameProps} />
          </Col>
          <Col sm={12}>
            <EditorControls.CheckboxInput {...isActiveProps} />
          </Col>
        </Row>
      </Container>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
