import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  classifier: {
    label: 'Классифікатор',
    name: 'Классификатор',
    visible: true,
  },
  allOthers: {
    label: 'Інші надходження', name: 'ВсеДругие', visible: true, type: 'bool',
  },
  owner: {
    label: 'Бюджет',
    name: 'Владелец',
    visible: true,
  },
};

const tables = {
  fulKEKV: {
    name: 'ПолныеКЕКВ',
    label: 'Повні КЕКВ',
    columns: {
      kekvClassifier: { label: 'Класифікатор', name: 'Классификатор' },
    },
  },
};

export const kekvClassifierShort = {
  label: 'Класифікатор КЕКВ. Скорочений',
  name: 'kekvClassifierShort',
  backendName: 'КлассификаторКЕКВКраткий',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/kekvClassifierShort',
  listColumns: [...(new Set([...reqListerColumns, 'owner', 'classifier', 'allOthers']))],
  tables,
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kekvClassifierShort;
