import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButtonsContainer, FuncButton, EqualButton, ClearButton, DigitButton,
} from './styles';

function Buttons({
  onParenthesis, onOperator, onClear, onDigit, onDecimal, onEqual, showOK,
}) {
  return (
    <StyledButtonsContainer>
      <FuncButton onClick={(e) => onParenthesis(e, '(')}>(</FuncButton>
      <FuncButton onClick={(e) => onParenthesis(e, ')')}>)</FuncButton>
      <FuncButton onClick={(e) => onOperator(e, '%')}>%</FuncButton>
      <ClearButton onClick={onClear}>AC</ClearButton>

      <DigitButton onClick={(e) => onDigit(e, '7')}>7</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '8')}>8</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '9')}>9</DigitButton>
      <FuncButton onClick={(e) => onOperator(e, '/')}>/</FuncButton>

      <DigitButton onClick={(e) => onDigit(e, '4')}>4</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '5')}>5</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '6')}>6</DigitButton>
      <FuncButton onClick={(e) => onOperator(e, '*')}>*</FuncButton>

      <DigitButton onClick={(e) => onDigit(e, '1')}>1</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '2')}>2</DigitButton>
      <DigitButton onClick={(e) => onDigit(e, '3')}>3</DigitButton>
      <FuncButton onClick={(e) => onOperator(e, '-')}>-</FuncButton>

      <DigitButton onClick={(e) => onDigit(e, '0')}>0</DigitButton>
      <DigitButton onClick={(e) => onDecimal(e, '.')}>.</DigitButton>
      <EqualButton onClick={onEqual}>
        {showOK ? 'OK' : '='}
      </EqualButton>
      <FuncButton onClick={(e) => onOperator(e, '+')}>+</FuncButton>
    </StyledButtonsContainer>
  );
}

Buttons.propTypes = {
  onParenthesis: PropTypes.func.isRequired,
  onOperator: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onDigit: PropTypes.func.isRequired,
  onDecimal: PropTypes.func.isRequired,
  onEqual: PropTypes.func.isRequired,
  showOK: PropTypes.bool.isRequired,
};
export default Buttons;
