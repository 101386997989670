import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../../common/proptypes';

const NON_FIELD = 'non_field_errors';
function HistoryContainer({ fields, errors, children }) {
  const ers = useMemo(
    () => {
      const getFLabel = (fname) => {
        if (fname in fields) return fields[fname].label;
        if (fname === NON_FIELD) return "Весь об'єкт";
        return fname;
      };
      return Object.keys(errors).map(
        (k) => ({
          key: k,
          label: getFLabel(k),
          messages: Array.isArray(errors[k]) ? errors[k] : [errors[k]],
        }),
      );
    },
    [errors, fields],
  );
  return (
    <div>
      {!!ers.length && (
        <ul className="border border-danger text-danger small my-2 py-2 rounded">
          {ers.map((e) => (
            <li key={e.key}>
              <span className="me-1 fw-bold">
                {e.label}
                :
              </span>
              {e.messages.join(', ')}
            </li>
          ))}
        </ul>
      )}
      {children}
    </div>
  );
}

HistoryContainer.propTypes = {
  errors: PropTypes.shape({}),
  children: childrenPropType.isRequired,
  fields: PropTypes.shape({}),
};

HistoryContainer.defaultProps = {
  errors: {},
  fields: {},
};

export default HistoryContainer;
