/**
 * @const
 */
const columns = {
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  // FormType: { label: 'Тип Формы', name: 'ТипФормы' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  TypeForm: { label: 'ТипФормы', name: 'ТипФормы' },
  PrintKVKTotals: { label: 'Друк підсумків за КВК', name: 'ПечатьИтоговКВК' },
  PrintKBP7Totals: { label: 'Друк підсумків за КПКВ', name: 'ПечатьИтоговКФК' },
  PageOrientation: { label: 'Орієнтація сторінки', name: 'ОриентацияСтраницыПриПечати' },
  DIVCSUs: { label: 'Відокремлювати розпорядників', name: 'ОтделятьРаспорядителей' },
  CSUTotals: { label: 'Підсумки за установами', name: 'ИтогиПоРаспорядителям' },
  ByGeneralCSU: { label: 'За головним розпорядником', name: 'ПоГлавномуРаспорядителю' },
  KEKVTotals: { label: 'Підсумково за КЕКВ', name: 'ИтогиПоКЕКВ' },
  ThisIsRasp: { label: 'Розпорядження', name: 'ЭтоРаспоряжение' },
  ShowPrintTime: { label: 'Показувати час друку', name: 'флПоказатьВремяПечати' },
  ShowGrid: { label: 'Показувати сітку', name: 'флПоказыватьСетку' },
  ByFI: { label: 'За розп.вищ. рівня', name: 'ПоФинансовомуОргану' },
  KBP7Totals: { label: 'Підсумки за КПКВ', name: 'ИтогиПоКФК' },
  KievForm5: { label: 'Форма 5', name: 'КиевФорма5' },
  KievForm5Rasp: { label: 'Форма розпорядження', name: 'КиевФорма5Распоряжение' },
  CSUCode: { label: 'Код розп.', name: 'КодРаспорядителя' },
  FillAllRows: { label: 'Заповнювати всі рядки', name: 'ЗаполнитьВсеСтроки' },
  QuantOfCopies: { label: 'К-сть екземплярів', name: 'КвоЭкземпляров' },
  ThisIsTransfer: { label: 'Передача коштів з заг. до спец. фонду', name: 'ЭтоПередачаСтредств' },
  TwoCopiesByPage: { label: 'Два екз. на одну стор.', name: 'ДваЭкземпляраНаСтраницу' },
  GroupTable: { label: 'Згорнути табличну частину', name: 'СвернутьТЧ' },
  ExtendedRequest: { label: 'Форма заявки', name: 'флРасширеннаяЗаявка' },
  AdditionalKEKV: { label: 'Додатково звести за КЕКВ', name: 'флДопРасшифровкаПоКЕКВ' },
  FormDnipro: { label: 'Форма заявки без деталізації', name: 'ПечатьФормаДнепр' },
  MoneysCode: { label: 'Код коштів', name: 'КодДенег' },
  ShowSumWords: { label: 'Показати суму прописом', name: 'ПоказатьСуммуПрописью' },
  CSUTotalDown: { label: 'Всього за розпорядником внизу', name: 'флИтогРаспорядителяВнизу' },
  Accepted: { label: 'Правий верхній кут', name: 'СогласованоУтверждено' },
  SignDate: { label: 'Дата підпису', name: 'ДатаПодписи' },
  PrintKPKV: { label: 'Друкувати КПКВ', name: 'ПечататьКПКВ' },
  BRBank: { label: 'Розпорядження/Розподіл коштів БР,які обслуговуються в установах банків', name: 'БР_КомерческийБанк' },
  Period: { label: 'Період', name: 'Период' },
  ElFond: { label: 'Фонд', name: 'ЭлементФонд' },
  InPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  NoNote: { label: 'Не друкувати примітки', name: 'НеПечататьПримечания' },
  NoKFB: { label: 'Не друкувати код КФБ', name: 'НеПечататьКФБ' },
  InFin: { label: 'Джерело даних для звіту', name: 'ИзФинансирования' },
};

const tables = {
  documents: {
    name: 'ТчДокументы',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'finPrintingBR',
  backendName: 'ПечатьФинансирования_БР',
  label: 'Друк фінансування (бюджет розвитку)',
  frontend: 'dp/finPrintingBR',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
