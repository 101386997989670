import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font } from '../../../constants/globalStyle';
import Calculator from './calculator';
import { ButtonModalStyled } from '../selectorField/item/styles';
import { CalulatorIcon } from '../../../assets/icons';

const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const MINUS = ['-'];
const DECIMALS = ['.', ','];
const DELETES = ['Delete'];
const BACKSPACES = ['Backspace'];

const ALLOWED_KEYS = [
  ...DIGITS,
  ...MINUS,
  ...DECIMALS,
  ...DELETES,
  ...BACKSPACES,
];
const StyledCalcButton = styled(ButtonModalStyled)`
  border: none;
  background: none;
  display: none;
`;

const StyledIcon = styled.img.attrs({
  src: CalulatorIcon,
  alt: 'Калькулятор',
})`
  height: 14px;
`;

const Div = styled.div`
  display: flex;
  // flex-direction: column;
  width: 100%;
  font-size: 1em;
  position: relative;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  &:hover{
    button{
    display: block;
    }
  }
`;

const ErrDiv = styled.div`
  color: red;
  text-align: center;
  border: 1px solid red;
  border-radius: 4px;
  padding: 4px 2px;
  background: #fbf1f1;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  color: #5f5f5f;
  align-self: flex-end;
  outline: none;
  padding-bottom: 0;
  line-height: 0.5;
  cursor: pointer;
  &:hover{
    color: black;
  };
`;

const ErrText = styled.span`
  overflow-wrap: break-word
`;

const Input = styled(React.forwardRef((
  {
    radius, background, border, left, ...rest
  },
  ref,
) => <input {...rest} ref={ref} />))`
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    flex: 1 1 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: ${(props) => (props.left ? 'left' : 'right')};
    line-height: 1.21428571em;
    padding: 2.5px 2px;
    border-radius: ${(props) => props.radius && '.28571429rem'};
    background-color: ${(props) => (props.background ? 'white' : 'transparent')};
    border: ${(props) => (props.border ? '1px solid #afbbcc' : 'none')};
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    box-shadow: none;
    ${(props) => (props.disabled ? 'color: black' : '')};
    ${font};
`;
Input.propTypes = {
  radius: PropTypes.bool,
  background: PropTypes.bool,
  border: PropTypes.bool,
  left: PropTypes.bool,
};

Input.defaultProps = {
  radius: false,
  background: false,
  left: false,
  border: false,
};

const GetNumberInput = (AllSize = 0, FractionSize = 0, onlyPozitive = false) => (
  class NumberInput extends Component {
    static propTypes = {
      value: PropTypes.number,
      onChange: PropTypes.func,
      autoselect: PropTypes.bool,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      disabled: PropTypes.bool,
      id: PropTypes.number,
      border: PropTypes.bool,
      radius: PropTypes.bool,
      left: PropTypes.bool,
      background: PropTypes.bool,
      rowId: PropTypes.number,
      processingKeyDown: PropTypes.func,
      setDataRef: PropTypes.func,
      isFocusCell: PropTypes.bool,
      disableCalculator: PropTypes.bool,
    };

    static defaultProps = {
      value: 0,
      onChange: () => null,
      autoselect: true,
      minValue: null,
      maxValue: null,
      disabled: false,
      id: null,
      border: false,
      radius: false,
      left: false,
      background: false,
      rowId: null,
      setDataRef: () => null,
      processingKeyDown: () => null,
      isFocusCell: false,
      disableCalculator: false,
    };

    constructor(props) {
      super(props);
      const {
        value, id, rowId, setDataRef,
      } = this.props;
      this.state = {
        propsValue: value,
        value: (value || 0).toFixed(FractionSize),
        errText: '',
        focused: false,
        calcOpened: false,
        cursor: { start: 0, end: 0 },
      };
      this.input = React.createRef();
      if (setDataRef) setDataRef(rowId, id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.propsValue !== nextProps.value) {
        return {
          propsValue: (nextProps.value) ? nextProps.value : 0,
          value: (nextProps.value)
            ? nextProps.value.toFixed(FractionSize)
            : (0).toFixed(FractionSize),
        };
      }
      return prevState;
    }

    componentDidUpdate(prevProps, prevState) {
      const { cursor } = this.state;
      if (this.input.current) {
        this.input.current.setSelectionRange(cursor.start, cursor.end);
      }
      if (this.props.isFocusCell) this.input.current.focus();
    }

    onFocusHandler = (e) => {
      // TODO: replace this
      if (this.props.autoselect) {
        e.target.setSelectionRange(0, e.target.value.length);
      }
      this.setState({ focused: true });
    };

    propChanger = (e) => {
      const newValue = parseFloat(this.state.value);
      const { minValue, maxValue } = this.props;
      if ((minValue || minValue === 0) && newValue !== 0 && newValue < minValue) {
        this.setState({ errText: `Значення не може бути меньне ніж ${minValue} Щоб відобразити повернення суми необхідно в шапці документа встановити необхідну ознаку: ""Повернення фінансування""(для всього документа) або ""Складне налаштування""(якщо повернення тільки у цьому рядку) та заповнити місяць повернення(в шапці або в рядку)`, value: minValue.toString() });
        e.preventDefault();
      } else if ((maxValue || maxValue === 0) && newValue !== 0 && newValue > maxValue) {
        this.setState({ errText: `Значення не може бути більше ніж ${maxValue}`, value: maxValue.toString() });
        e.preventDefault();
      } else if (this.props.value !== newValue || newValue === 0) {
        this.props.onChange(e, newValue);
        this.setState({ focused: false, errText: '', propsValue: null });
      }
    };

    keyPresser = (e) => {
      const { value } = this.state;
      const { processingKeyDown, rowId, id } = this.props;
      if (processingKeyDown) processingKeyDown(e, rowId, id);
      const cursor = { start: e.target.selectionStart, end: e.target.selectionEnd };
      if (ALLOWED_KEYS.includes(e.key)) {
        e.preventDefault();
        if (DECIMALS.includes(e.key) && FractionSize) {
          const str = e.target.value.replace(',', '.');
          const dotPos = str.indexOf('.');
          this.setState({
            cursor: { start: dotPos + 1, end: dotPos + 1 },
          });
        } else if (MINUS.includes(e.key) && !onlyPozitive) {
          if (e.target.value.substr(0, 1) === '-') {
            this.setState({ value: value.substr(1) });
          } else {
            this.setState({ value: `-${value}` });
          }
        } else if (DIGITS.includes(e.key)) {
          const dotPos = value.indexOf('.');
          const override = (value.substr(0, dotPos) === '0') && cursor.start <= dotPos;
          const newValueText = `${value.substr(override ? cursor.start : 0, cursor.start)}${e.key}${value.substr(override ? dotPos : cursor.end)}`;
          const newValueNumber = Number(newValueText);
          // console.log(value);
          // console.log(value.substr(0, cursor.start), e.key, value.substr(cursor.end));
          if (!Number.isNaN(newValueNumber)) {
            if (cursor.start <= dotPos) { // Ввод целочисленной части
              this.setState({
                value: newValueNumber.toFixed(FractionSize),
                cursor: {
                  start: e.target.selectionStart + 1,
                  end: e.target.selectionStart + 1,
                },
              });
            } else {
              this.setState({
                value: newValueNumber.toFixed(FractionSize),
                cursor: {
                  start: cursor.start + 1,
                  end: cursor.start + 1,
                },
              });
            }
          }
        } else if (DELETES.includes(e.key)) {
          const override = cursor.start !== cursor.end;
          const newValueText = `${value.substr(0, cursor.start)}${value.substr(override ? cursor.end : cursor.end + 1)}`;
          const newValueNumber = Number(newValueText);
          if (!Number.isNaN(newValueNumber)) {
            this.setState({
              value: newValueNumber.toFixed(FractionSize),
              cursor: {
                start: cursor.start,
                end: cursor.start,
              },
            });
          }
        } else if (BACKSPACES.includes(e.key)) {
          const override = cursor.start !== cursor.end;
          const newValueText = `${value.substr(0, override ? cursor.start : cursor.start - 1)}${value.substr(cursor.end)}`;
          const newValueNumber = Number(newValueText);
          if (!Number.isNaN(newValueNumber)) {
            this.setState({
              value: newValueNumber.toFixed(FractionSize),
              cursor: {
                start: override ? cursor.start : cursor.start - 1,
                end: override ? cursor.start : cursor.start - 1,
              },
            });
          }
        }
      }
    };

    render() {
      const {
        id, rowId, processingKeyDown, value, disableCalculator, disabled,
      } = this.props;
      const { calcOpened } = this.state;
      return (
        <>
          <Div>
            {this.state.errText && (
              <ErrDiv>
                <CloseButton
                  onClick={() => this.setState({ errText: '' })}
                >
                  x
                </CloseButton>
                <ErrText>{this.state.errText }</ErrText>
              </ErrDiv>
            )}
            <Input
              ref={this.input}
              id={id}
              type="text"
              value={this.state.focused ? this.state.value : this.state.value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}
              onBlur={this.propChanger}
              onFocus={this.onFocusHandler}
              disabled={this.props.disabled}
              border={this.props.border}
              radius={this.props.radius}
              left={this.props.left}
              background={this.props.background}
              onKeyDown={this.keyPresser}
              onClick={(e) => {
                if (processingKeyDown) processingKeyDown(e, rowId, id);
              }}
              onChange={() => null}
            />
            {!disableCalculator && !disabled && (
              <StyledCalcButton
                onClick={() => this.setState({ calcOpened: true })}
              >
                <StyledIcon />
              </StyledCalcButton>
            )}
          </Div>
          <Calculator
            opened={calcOpened}
            value={Number(value)}
            onClose={() => this.setState({ calcOpened: false })}
            onOk={(e, v) => this.setState({ calcOpened: false, value: v.toFixed(FractionSize) })}
          />
        </>
      );
    }
  }
);

export default GetNumberInput;
