import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen, faMapMarkerAlt, faUser, faCalendarAlt, faEdit, faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import { foreignPropType } from '../../../constants/backend/propTypes';
import { CiatAppContext } from '../../../providers';
import { Control } from '../../../containers/newEditor/hook/control';

function Footer({
  created, updated, budget, authority, author, headerProps,
}) {
  const { currentUser } = useContext(CiatAppContext);

  const crt = new Date(created);
  const upd = new Date(updated);
  const isSuperuser = currentUser.is_superuser;

  return (
    <div className="border rounded p-2 my-2 bg-light d-flex">
      {/* Якщо не суперюзер то бачимо лейбли */}
      {!isSuperuser && (
      <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
        <FontAwesomeIcon
          className="me-1"
          icon={faBookOpen}
        />
        {budget && budget.repr}
      </div>
      )}
      {isSuperuser && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <Control name="budget" headerProps={headerProps} />
        </div>
      )}
      {/* Якщо не суперюзер то бачимо лейбли */}
      {!isSuperuser && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            className="me-1"
            icon={faMapMarkerAlt}
          />
          {authority && authority.repr}
        </div>
      )}
      {isSuperuser && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <Control name="authority" headerProps={headerProps} />
        </div>
      )}
      <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
        <FontAwesomeIcon
          className="me-1"
          icon={faUser}
        />
        {author && author.repr}
      </div>
      {!!created && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            className="me-1"
            icon={faCalendarAlt}
          />
          {/* Створено: */}
          {crt.toLocaleString('uk')}
        </div>
      )}
      {!!updated && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            className="me-1"
            icon={faEdit}
          />
          {/* Останні зміни: */}
          {upd.toLocaleString('uk')}
        </div>
      )}
      {headerProps.data.id && (
        <div className="mx-1 flex-fill small fst-italic d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            className="me-1"
            icon={faBarcode}
          />
          {/* ID: */}
          {headerProps.data.id}
        </div>
      )}
    </div>
  );
}

Footer.propTypes = {
  created: PropTypes.string,
  updated: PropTypes.string,
  budget: foreignPropType,
  authority: foreignPropType,
  author: foreignPropType,
  headerProps: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

Footer.defaultProps = {
  created: null,
  updated: null,
  budget: null,
  authority: null,
  author: null,
  headerProps: { data: {} },
};

// TODO: @marina Надо заменить подписи на картинки и стилизировать футер
export default memo(Footer);
