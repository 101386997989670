import def from '../../../constants/meta';
import { ItemPicker, NumberInput, DateInput } from '../../../components/bootstrap_components/controls';

const getComponentByType = (type) => {
  const modelType = type.ModelType;
  switch (modelType) {
  // eslint-disable-next-line
    case 'cat': // Справочник
    // eslint-disable-next-line
      const modelName = Object.values(def.cat).reduce((R, d) => d.backendName === type.ModelName ? d.name : R, 'unknow');
      return {
        component: ItemPicker,
        props: {
          modelName,
          modelType,
        },
      };
    case 'N':
      return {
        component: NumberInput,
        props: {
          precision: 2,

        },
      };
    case 'D':
      return {
        component: DateInput,
        props: {},
      };
    default:
      return null;
  }
};

export default getComponentByType;
