const frontendURL = '/rep/extendedFinrequestsAnalisys/';
const backendURL = '/api/financials/printform/extended_finrequest_analisys/';
const name = 'Розширений аналіз заявок на фінансування';
const sections = ['executing'];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
