import AssigmentPlaning from './assigmentPlaning';
import FinancingAnalysis from './financingAnalysis';
import CostAnalysisPlanFact from './costAnalysisPlanFact';
import CompareLevels from './compareLevels';
import PaymentList from './paymentList';
import TreasureFilesAnalysis from './treasureFilesAnalysis';
import RevenueAnalysis from './revenueAnalysis';
import RevenueAnalysisVariety from './revenueAnalysisVariety';
import RequestAnalysis from './requestAnalysis';
import RequestAnalysisAdd from './requestAnalysisAdd';
import DocFinAnalysis from './docFinAnalysis';
import PivotRequest from './pivotRequest';
import PivotRequestKMDA from './pivotRequestKMDA';
import ConsolidateFinProposal from './consolidateFinProposal';
import TreasureReport from './treasureReport';
import TurnoverSheetTreasureAccount from './turnoverSheetTreasureAccount';
import PlanOfUse from './planOfUse';
import YearAssigment from './yearAssigment';
import AnalysisFinProtectItems from './analysisFinProtectItems';
import SourceAssigment from './sourceAssigment';
import ConsolidatePartnersFinProposal from './consolidatePartnersFinProposal';
import AnalysisSpentCashe from './analysisSpentCashe';
import ReportByBR from './reportByBR';
import FinSourceAnalysis from './finSourceAnalysis';
import AddressListCR from './addressListCR';
import CountContractsTenders from './countContractsTenders';
import LimitSPR from './limitSPR';
import Balans from './balans';
import SessionAdd1 from './sessionAdd1';
import TreasureReports from './treasureReports';
import TreasureReportStatement from './treasureReportStatement';
import TreasureReportStatementCut from './treasureReportStatementCut';
import TreasureReportStatementCutKiev from './treasureReportStatementCutKiev';
import TreasureFilesObligationsAnalysis from './treasureFilesObligationsAnalysis';
import SessionAdd2 from './sessionAdd2';
import SessionAdd4 from './sessionAdd4';
import SessionAdd5Transfert from './sessionAdd5Transfert';
import SessionAdd6BR from './sessionAdd6BR';
import SessionAdd7 from './sessionAdd7';
import SessionAdd8Loan from './sessionAdd8Loan';
import SessionAdd3 from './sessionAdd3';
import MixIndicatorsSF from './mixIndicatorsSF';
import Estimate from './estimate';
import ReportBalanceOfAccounts from './reportBalanceOfAccounts';
import FinancingOfBRAnalysis from './financingOfBRAnalysis';
import NationalCurrencyNote from './nationalCurrencyNote';
import FactExpence from './factExpence';
import InfoAboutFinancing from './infoAboutFinancing';
import ObjBuildingAddressList from './objBuildingAddressList';
import ReportSocial from './reportSocial';
import ReportSocial1 from './reportSocial1';
import ReportSocial2 from './reportSocial2';
import PassportAnalisys from './passportAnalisys';
import PassportReportAnalysis from './passportReportAnalysis';
import ReportAnalysis from './reportAnalysis';
import RegionAssigmentsAnalisys from './regionAssigmentsAnalisys';
import RegionRevenuePlanAnalysis from './regionRevenuePlanAnalysis';
import RegionAssigmentsFactAnalisys from './regionAssigmentsFactAnalisys';
import RegionRevenueFactDaily from './regionRevenueFactDaily';
import RegionRevenueStatement from './regionRevenueStatement';
import RegionRevenueFactAnalysis from './regionRevenueFactAnalysis';
import AnalysisIndicatorsSF from './analysisIndicatorsSF';
import UsersMonitor from './usersMonitor';
import AnalysisGOV from './analysisGOV';
import AnalysisForecastsPP3 from './analysisForecastsPP3';
import RevTreasureReport from './revTreasureReport';
import BfnCommoninStitutionstructure from './bfnCommoninStitutionstructure';
// import CommonRepPanel from './commonRepPanel';
import GovPlanSpend from './govPlanSpend';
import RevenuePlan from './revenuePlan';
import CertNationalCurr from './certNationalCurr';
import ExpensesBudget from './expensesBudget';
import TreasureOffer from './treasureOffer';
import NetFOPreduce from './netFOPreduce';
import NetFOPPlanreduce from './netFOPPlanreduce';
import EnergyReport from './energyReport';
import TransfertPlanning from './transfertPlanning';
import BrAnalysisSKD from './brAnalysisSKD';
import IndicatorFactAnalysisSKD from './indicatorFactAnalysisSKD';
import PassportAnalysisSKD from './passportAnalysisSKD';
import LiquidityMethod from './liquidityMethod';
import ComparePassport from './comparePassport';

const reports = {
  AssigmentPlaning,
  FinancingAnalysis,
  CostAnalysisPlanFact,
  CompareLevels,
  PaymentList,
  TreasureFilesAnalysis,
  RevenueAnalysis,
  RevenueAnalysisVariety,
  RequestAnalysis,
  RequestAnalysisAdd,
  DocFinAnalysis,
  Balans,
  PivotRequest,
  PivotRequestKMDA,
  ConsolidateFinProposal,
  TreasureReport,
  TurnoverSheetTreasureAccount,
  ReportByBR,
  FinSourceAnalysis,
  PlanOfUse,
  YearAssigment,
  AnalysisFinProtectItems,
  SourceAssigment,
  ConsolidatePartnersFinProposal,
  AnalysisSpentCashe,
  AddressListCR,
  CountContractsTenders,
  LimitSPR,
  TreasureReports,
  SessionAdd1,
  MixIndicatorsSF,
  SessionAdd2,
  SessionAdd4,
  SessionAdd5Transfert,
  SessionAdd6BR,
  SessionAdd7,
  SessionAdd3,
  SessionAdd8Loan,
  Estimate,
  ObjBuildingAddressList,
  ReportBalanceOfAccounts,
  FinancingOfBRAnalysis,
  NationalCurrencyNote,
  FactExpence,
  InfoAboutFinancing,
  ReportSocial,
  ReportSocial1,
  ReportSocial2,
  PassportAnalisys,
  PassportReportAnalysis,
  ReportAnalysis,
  RegionAssigmentsAnalisys,
  RegionRevenuePlanAnalysis,
  RegionAssigmentsFactAnalisys,
  RegionRevenueFactDaily,
  RegionRevenueStatement,
  RegionRevenueFactAnalysis,
  AnalysisIndicatorsSF,
  UsersMonitor,
  TreasureReportStatement,
  TreasureReportStatementCut,
  TreasureReportStatementCutKiev,
  TreasureFilesObligationsAnalysis,
  AnalysisGOV,
  AnalysisForecastsPP3,
  RevTreasureReport,
  BfnCommoninStitutionstructure,
  // CommonRepPanel,
  GovPlanSpend,
  RevenuePlan,
  CertNationalCurr,
  ExpensesBudget,
  TreasureOffer,
  NetFOPreduce,
  NetFOPPlanreduce,
  EnergyReport,
  TransfertPlanning,
  BrAnalysisSKD,
  IndicatorFactAnalysisSKD,
  PassportAnalysisSKD,
  LiquidityMethod,
  ComparePassport,
};

export default reports;
