import React, { memo } from 'react';

import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function TPFooter() {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={4}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {

};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
