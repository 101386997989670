// eslint-disable-next-line import/no-cycle
import budgetRequest from './budgetRequest/lister/selector';
import financeObligation from './financeObligation/lister/selector';
import indicatorFact from './indicatorFact/lister/selector';
import indicatorPassport from './indicatorPassport/lister/selector';
import legalObligation from './legalObligation/lister/selector';
import payment from './payment/lister/selector';

const docSelectors = {
  budgetRequest,
  financeObligation,
  indicatorFact,
  indicatorPassport,
  legalObligation,
  payment,

};

export default docSelectors;
