/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';
import CollapsedTableRow from './CollapsedTableRow';
import useOpenController from '../../../../../components/pureExpendableTable/Hooks/useOpenController';

function TableSection({ row, level }) {
  const { isOpen, toggle } = useOpenController(false);
  return (
    <>
      {row.nested ? (
        <CollapsedTableRow
          row={row}
          toggle={toggle}
          level={level}
          isOpen={isOpen}
        />
      )
        : (
          <TableRow
            row={row}
            level={level}
          />
        )}

      {isOpen && row.nested && (
        row.nested.map((rev, indx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableSection row={rev} key={`pureRow-${indx}`} level={level + 1} />
        ))
      )}
    </>
  );
}

TableSection.propTypes = {
  row: PropTypes.shape({
    nested: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  level: PropTypes.number.isRequired,
};

export default TableSection;
