/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import AppearanceRow from './appearanceRow';
import { StyledTable } from './styled';

function AppearanceTable({
  data, onSelectRow, onChangeRow, availableFields, mountPoint,
}) {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th width="40">&nbsp;</th>
          <th>Оформлення</th>
          <th>Умова</th>
          <th>Поля, що оформлюються</th>
          <th>Область використання</th>
        </tr>
      </thead>
      <tbody>
        {data.reduce((R, row, key) => [...R, (
          <AppearanceRow
            key={key}
            rowId={key}
            row={row}
            onClick={onSelectRow}
            active={row.get('_SELECTED', false)}
            onChange={onChangeRow}
            availableFields={availableFields}
            mountPoint={mountPoint}
          />
        )], [])}
      </tbody>
    </StyledTable>
  );
}

AppearanceTable.propTypes = {
  data: PropTypes.instanceOf(OrderedMap).isRequired,
  availableFields: PropTypes.instanceOf(Map).isRequired,
  mountPoint: PropTypes.string.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
};

export default AppearanceTable;
