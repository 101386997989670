import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={5} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesGF_Report'}
              highlighted={highlights.includes('expensesGF_Report')}
              onToggleHighlght={() => onToggleHighlght('expensesGF_Report')}
            >
              {`${activeYear - 2} (звіт) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Report'}
              highlighted={highlights.includes('expensesSF_Report')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Report')}
            >
              {`${activeYear - 2} (звіт) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesGF_Approved'}
              highlighted={highlights.includes('expensesGF_Approved')}
              onToggleHighlght={() => onToggleHighlght('expensesGF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Approved'}
              highlighted={highlights.includes('expensesSF_Approved')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesGF_Plan'}
              highlighted={highlights.includes('expensesGF_Plan')}
              onToggleHighlght={() => onToggleHighlght('expensesGF_Plan')}
            >
              {`${activeYear} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan'}
              highlighted={highlights.includes('expensesSF_Plan')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan')}
            >
              {`${activeYear} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesGF_Plan1'}
              highlighted={highlights.includes('expensesGF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('expensesGF_Plan1')}
            >
              {`${activeYear + 1} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan1'}
              highlighted={highlights.includes('expensesSF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan1')}
            >
              {`${activeYear + 1} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesGF_Plan2'}
              highlighted={highlights.includes('expensesGF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('expensesGF_Plan2')}
            >
              {`${activeYear + 2} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan2'}
              highlighted={highlights.includes('expensesSF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan2')}
            >
              {`${activeYear + 2} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    Sum: PropTypes.number,
    SumBR: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`Sum${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
