import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  csu: { label: 'Головний роспорядник', name: 'Распорядители', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  reportMonth: { label: 'Місяць звітності', name: 'МесяцОтчетности', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  R01G1: { label: 'Касові видатки за рахунок місцевих бюджетів на оплату праці з нарахуваннями педагогічних працівників', name: 'R01G1', visible: false },
  R01G2: { label: 'Штатна чисельність працівників, всього', name: 'R01G2', visible: false },
  R01G3: { label: 'Штатна чисельність працівників, у т.ч. вчителі', name: 'R01G3', visible: false },
  R01G4: { label: 'Штатна чисельність працівників, у т.ч. інші педагогічні працівники', name: 'R01G4', visible: false },
  R01G5: { label: 'Штатна чисельність працівників, в т.ч. непедагогічні працівники', name: 'R01G5', visible: false },
  R01G6: { label: 'Затверджено коштор.призначень за рах. МБ на випл. з/п педагог. працівникам КЕКВ 2110', name: 'R01G6', visible: false },
  R01G7: { label: 'Затверджено коштор.призначень за рах. МБ на випл. з/п педагог. працівникам КЕКВ 2120', name: 'R01G7', visible: false },
  R01G8: { label: 'Розрах.потреба за рах.освіт.субвенції/Штатна чисельність пед.працівників/Всього', name: 'R01G8', visible: false },
  R01G9: { label: 'Розрах.потреба за рах.освіт.субвенції/Штатна чисельність пед.працівників/Вчителі', name: 'R01G9', visible: false },
  R01G10: { label: 'Розрах.потреба за рах.освіт.субвенції/Штатна чисельність пед.працівників/Інші пед.працівники', name: 'R01G10', visible: false },
  R01G11: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед. прцівників(КЕКВ2110)/Всього', name: 'R01G11', visible: false },
  R01G12: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прцівників(КЕКВ2110)/фонд з/п за схемним окладом', name: 'R01G12', visible: false },
  R01G13: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прцівників(КЕКВ2110)/надбавки та допл. обов.характеру', name: 'R01G13', visible: false },
  R01G14: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/в т.ч.надбавка за престижнiсть', name: 'R01G14', visible: false },
  R01G15: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/надбавки,що носять стим.хар-р', name: 'R01G15', visible: false },
  R01G16: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/премії', name: 'R01G16', visible: false },
  R01G17: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/допомога на оздоровлення', name: 'R01G17', visible: false },
  R01G18: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/грошова винагорода', name: 'R01G18', visible: false },
  R01G19: { label: 'Розрах.потреба за рах.освіт.субвенції/ О/п пед.прац.(КЕКВ2110)/iншi види з/п', name: 'R01G19', visible: false },
  R01G20: { label: 'Розрах.потреба за рах.освіт.субвенції/ Нарахування (КЕКВ 2120)', name: 'R01G20', visible: false },
  R01G21: { label: 'Розрахункова потреба за рахунок МБ/ Штатна чис-сть працівників/ Всього', name: 'R01G21', visible: false },
  R01G22: { label: 'Розрахункова потреба за рахунок МБ/ Штатна чис-сть працівників/ вчителі', name: 'R01G22', visible: false },
  R01G23: { label: 'Розрахункова потреба за рахунок МБ/ Штатна чис-сть працівників/інші пед.працівники', name: 'R01G23', visible: false },
  R01G24: { label: 'Розрахункова потреба за рахунок МБ/ Штатна чис-сть працівників/непед. працівники', name: 'R01G24', visible: false },
  R01G25: { label: 'Розрахункова потреба за рахунок МБ/ О/п пед.працівників(КЕКВ2110)/Всього', name: 'R01G25', visible: false },
  R01G26: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/фонд зп за схемним окладом(вкл.підвищення', name: 'R01G26', visible: false },
  R01G27: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/надбавки та доплати обов.хар-ру', name: 'R01G27', visible: false },
  R01G28: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/в т.ч. надбавка за престижнiсть', name: 'R01G28', visible: false },
  R01G29: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/надбавки, що носять стимул.хар-р', name: 'R01G29', visible: false },
  R01G30: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/премії', name: 'R01G30', visible: false },
  R01G31: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/допомога на оздоровл.', name: 'R01G31', visible: false },
  R01G32: { label: 'Розрахункова потреба за рахунок МБ/ О/п пед.прац.(КЕКВ2110)/грошова винагорода', name: 'R01G32', visible: false },
  R01G33: { label: 'Розрахункова потреба за рахунокМБ/ О/п пед.прац.(КЕКВ2110)/iншi види з/п', name: 'R01G33', visible: false },
  R01G34: { label: 'Розрахункова потреба за рахунокМБ/ Нарах. на о/п пед. працівників (КЕКВ 2120)', name: 'R01G34', visible: false },
  R01G35: { label: 'Розрахункова потреба за рахунок МБ/ Оплата праці непедагогічних праціників (КЕКВ 2110)', name: 'R01G35', visible: false },
  R01G36: { label: 'Розрахункова потреба за рахунок МБ/ Нарахування на о/п непедагогічних працівників (КЕКВ 2120)', name: 'R01G36', visible: false },
  R01G37: { label: 'Із заг.розрах.потреби,обсяг коштів освіт.субвенції таМБ(КЕКВ2110та2120)/інших пед.прац крім вчителів', name: 'R01G37', visible: false },
  R01G38: { label: 'Із заг.розрах.потреби,обсяг коштів освіт.субвенції таМБ(КЕКВ2110та2120)/викл.годин варіативної склад', name: 'R01G38', visible: false },
  R01G39: { label: 'Із заг.розрах.потреби,обсяг коштів освіт.субвенції таМБ(КЕКВ2110та2120)/роботи в умовах простою', name: 'R01G39', visible: false },
  docLogica: { label: 'Документ відправки в Логіка', name: 'ДокОтправкиВЛогика', visible: false },
  isSentLogica: {
    label: 'Підготовлено відправку в Логіку', name: 'ОтправленВЛогику', visible: true, type: 'bool',
  },
  statusLogica: { label: 'СтатусЛогика', name: 'СтатусЛогика', visible: false },
  signLogica: { label: 'ПодписиЛогика', name: 'ПодписиЛогика', visible: false },
};

/**
 * @const
 */
const tables = {
  descendantsDocuments: {
    name: 'Доки',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Документ' },
      isApproved: { label: 'Затверджений', name: 'Утвержден' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Розрахункова потреба у видатках на оплату праці з нарахуваннями працівників закладів загальної середньої освіти, крім приватних, на поточний рік (загальний фонд)',
  name: 'countExpensesOnEducation',
  backendName: 'РасчетнаяПотребностьОбразование',
  columns,
  frontend: 'doc/countExpensesOnEducation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'FI',
      'year',
      'note',
      'author',
      'isSentLogica',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
