import React from 'react';
import PropTypes from 'prop-types';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import Tp from '../../../../../newEditor/newTp/tablepart';

function Rows(props) {
  // const headerTemplate = [
  //   [
  //     { name: 'Заг.ф.', props: { rowSpan: 1 } },
  //     { name: 'Цільові програми', props: { colSpan: 5 } },
  //     { name: 'КДБ', props: { colSpan: 5 } },
  //   ],
  //   [
  //     { name: 'Об\'єкти будівництва', props: { colSpan: 5 } },
  //     { name: 'КФБ', props: { colSpan: 5 } },
  //   ],
  //   [
  //     { name: 'Касові видатки станом на', props: { colSpan: 4 } },
  //     { name: 'План звітного періоду', props: { colSpan: 4 } },
  //     { name: 'Прогноз до кінця реалізації програми', props: { colSpan: 3 } },
  //   ],
  //   [
  //     { name: 'Заг. фонд', props: { colSpan: 2 } },
  //     { name: 'Спец. фонд', props: { colSpan: 2 } },
  //     { name: 'Заг. фонд', props: { colSpan: 2 } },
  //     { name: 'Спец. фонд', props: { colSpan: 2 } },
  //     { name: 'Заг. фонд', props: { colSpan: 2 } },
  //     { name: 'Спец. фонд', props: { colSpan: 1 } },
  //   ],
  //   [
  //     { name: 'Шаблон пояснень', props: { colSpan: 5 } },
  //     { name: 'Пояснення, що характеризують джерела фінансування', props: { colSpan: 6 } },
  //   ],
  // ];

  const rowTemplate = [
    [
      { name: 'is_fond', props: { rowSpan: 1 } },
      { name: 'targetprogram', props: { colSpan: 5 } },
      { name: 'kdb', props: { colSpan: 5 } },
    ],
    [
      { name: 'year', props: { colSpan: 6 } },
      { name: 'kfb', props: { colSpan: 5 } },
    ],
    [
      { name: 'cashbox_expenses', props: { colSpan: 4 } },
      { name: 'report_plan', props: { colSpan: 4 } },
      { name: 'program_end_prediction', props: { colSpan: 3 } },
    ],
    [
      { name: 'amount_zf_ce', props: { colSpan: 2 } },
      { name: 'amount_sf_ce', props: { colSpan: 2 } },
      { name: 'amount_zf_rp', props: { colSpan: 2 } },
      { name: 'amount_sf_rp', props: { colSpan: 2 } },
      { name: 'amount_zf_pep', props: { colSpan: 2 } },
      { name: 'amount_sf_pep', props: { colSpan: 1 } },
    ],
    [
      { name: 'kfb', props: { colSpan: 5 } },
      { name: 'characterizing_explanation', props: { colSpan: 6 } },
    ],
  ];

  return (
    <Tp
      headerTemplate={rowTemplate}
      rowTemplate={rowTemplate}
      {...props}

    />
  );
}

Rows.propTypes = {
  data: PropTypes.shape({
    passportbpprogramfinancingsourcerow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    passportbpprogramfinancingsourcerow_set: fieldsPropTypes,
  }),
  errors: PropTypes.shape({
    passportbpprogramfinancingsourcerow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    passportbpprogramfinancingsourcerow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
};

Rows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: true,
  fields: null,
};

export default Rows;
