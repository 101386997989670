import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TreeviewListItem from './item';

function Treeview({
  data,
  selection,
  expanded,
  selectedItems,
  setSelectedItems,
  currentItem,
  setCurrentItem,
}) {
  const [openedItems, setOpenedItems] = useState(expanded);

  return (
    <ul className="treeview treeview-select">
      {data.map((treeviewItem) => (
        <TreeviewListItem
          key={treeviewItem.id}
          item={treeviewItem}
          openedItems={openedItems}
          setOpenedItems={setOpenedItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selection={selection}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
        />
      ))}
    </ul>
  );
}

Treeview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selection: PropTypes.bool, // If true selection is enabled
  // Default expanded children ids
  expanded: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  // Selected item ids
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setSelectedItems: PropTypes.func, // Setter to select items
  currentItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCurrentItem: PropTypes.func,
};

Treeview.defaultProps = {
  selection: false,
  expanded: [],
  selectedItems: [],
  setSelectedItems: null,
  currentItem: null,
  setCurrentItem: null,
};

export default Treeview;
