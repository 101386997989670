import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Row,
} from 'react-bootstrap';
import { useEditor, useInitParams } from '../../newEditor';
import meta from '../../../constants/meta';
import { getRouterEditor } from '../basic';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import { EditorItemPicker, EditorDateInput } from '../../../components/bootstrap_components/editorControls';
import RegEditorCommandPanel from '../../newEditor/commandPanels/inforeg';

const modelType = 'infoRegs';
const modelName = 'kbpElemRelation';

const def = meta[modelType][modelName];

function KbpElemRelationEditor({
  id, search, onClose, onSave,
}) {
  const {
    reason, copyFrom, isGroup, defaults,
  } = useInitParams(search);
  const {
    data, loading, err, changed,
    permissions, actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    isGroup,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  return (
    <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>
      <Card.Header>
        <RegEditorCommandPanel
          changed={changed}
          onClose={onClose}
          permissions={permissions}
          actions={actions}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            {/* <StyledLabel>{def.columns.period.label}</StyledLabel> */}
            <EditorDateInput
              value={data[def.columns.period.name]}
              onChange={(e, v) => actions.onChange({ [def.columns.period.name]: v })}
              label={def.columns.period.label}
              required
              description="Дата, з якої діє цей запис. Як що цей запис діє з 'початку часів', використовуйте 01 січня 2000 року"
            />
          </Col>
          <Col>
            <EditorItemPicker
              id={`${data.id}-element`}
              value={data[def.columns.element.name]}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, v) => actions.onChange({ [def.columns.element.name]: v })}
              label={def.columns.element.label}
            />
          </Col>
          <Col>
            <EditorItemPicker
              id={`${data.id}-classifier`}
              value={data[def.columns.classifier.name]}
              modelType="cat"
              modelName="kbpClassifier"
              onChange={(e, v) => actions.onChange({ [def.columns.classifier.name]: v })}
              label={def.columns.classifier.label}
            />
          </Col>
        </Row>

      </Card.Body>
    </EditorWrapper>
  );
}

KbpElemRelationEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

KbpElemRelationEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};
export { KbpElemRelationEditor as StdEditor };

export default getRouterEditor(def)(KbpElemRelationEditor);
