/**
 * @const
 */
const columns = {
  budget: { label: 'Бюджет', name: 'Бюджет' },
  period: { label: 'Перiод заповнення', name: 'Период' },
  year: { label: 'На рік', name: 'НаГод' },
  deputies: { label: 'Фракція', name: 'Фракция' },
  KFK: { label: 'КФК', name: 'КФК' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
};

/**
 * @const
 */
const definition = {
  name: 'proposalDeputies',
  backendName: 'ПечатьПредложенияДепутатов',
  label: 'Друк',
  frontend: '',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
