/* eslint-disable import/no-named-as-default */
import proposalDeputies from './proposalDeputies';
import messages from './messages';
import kbpElemRelation from './kbpElemRelation';
import kbp7ElemRelation from './kbp7ElemRelation';
import kfkvkElemRelation from './kfkvkElemRelation';
import kbp7ElemBetween from './kbp7ElemBetween';

export const infoRegs = {
  proposalDeputies,
  messages,
  kbpElemRelation,
  kbp7ElemRelation,
  kfkvkElemRelation,
  kbp7ElemBetween,
};

export default infoRegs;
