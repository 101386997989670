import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  byMonthsAccum: { label: 'Накопичувально', name: 'ПоМесяцамНакопительно' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  approved: { label: 'За підписаними плановими документами', name: 'УтвержденныйПлан' },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },
  periodVariant: { label: 'Виводити', name: 'ВариантыПериодов' },
  printReportParams: {
    label: 'Друкувати параметри звіту',
    name: 'флПечатьСодержанияФильтра',
  },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },
  isDataTreasury: {
    label: 'За даними казначейства',
    name: 'флПоДаннымКазначейства',
  },
  consolidated: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  byApprovedDoc: {
    label: 'За підписаними документами на фінансування',
    name: 'флУтвержденноеФинансирование',
  },
  byProposition: {
    label: 'За даними пропозиції на фінансування',
    name: 'флПоДаннымПредложенияНаФинансирования',
  },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  signVariant: { label: 'За списаними документами', name: 'флПрофинансированКазной' },
  flFin: { label: 'За даними пропозиції на фінансування', name: 'флПоДаннымПредложенияНаФинансирования' },
  flCsu: { label: 'За даними виконавців', name: 'флПоДаннымИсполнителей' },
  flPlan: { label: 'План', name: 'флПоказыватьПлан' },
  flFact: { label: 'Факт', name: 'флПоказыватьФакт' },
};

const definition = {
  label: 'Аналіз виконання видатків (план/факт)',
  name: 'CostAnalysisPlanFact',
  columns,
  frontend: 'rep/CostAnalysisPlanFact',
  backendName: 'ПланФактСКДГруппы',
  resultColumns: baseResultColumns,
};

export default definition;
