import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  account: { label: 'Рахунок', name: 'РСчетВУ' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FI: { label: 'Розп. вищого рівня', name: 'ФинансовыйОрган' },
  allowedAccounts: { label: 'Доступні рахунки', name: 'ДоступныеСчета' },
  OK: { label: 'OK', name: 'OK' },
};

const tables = {
  finRequests: {
    name: 'Заявки',
    label: 'Заявки',
    columns: {
      finRequest: { label: 'Заявка', name: 'Заявка' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'sendFinRequestToHOC',
  backendName: 'ОтправитьЗавккуРаспорядителюВУ',
  label: 'Відправка заявок до розпорядника вищого рівня',
  columns,
  tables,
};

export default definition;
