import React, {
  Children, cloneElement, useMemo, useState, lazy, Suspense, useRef, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'react-bootstrap';
import { childrenPropType } from '../../../../../common/proptypes';
import { ACTIVE_FIELD, DATE_PARAMETER } from '../../../../../constants/backend';
import AppContext from '../../../../../providers/CiatAppContext';

function ElementListerSelector({
  filter, children, ...restProps
}) {
  // eslint-disable-next-line import/no-cycle
  const EditorItemPicker = lazy(() => import('../../../../basicEditor/editorControls/lazyControls/itempicker'));
  const EditorDateInput = lazy(() => import('../../../../basicEditor/editorControls/lazyControls/dateInput'));
  const EditorCheckboxInput = lazy(() => import('../../../../basicEditor/editorControls/lazyControls/checkboxInput'));
  const checkBoxCtrlId = useRef(Math.random().toString(36));
  const [cDate, setCDate] = useState(
    () => (filter && filter[DATE_PARAMETER]
      ? filter[DATE_PARAMETER] : new Date().toJSON().slice(0, 10)),
  );
  const [showInactive, setShowInactive] = useState(false);
  const { currentUser } = useContext(AppContext);
  const [authority, setAuthority] = useState(filter?.owner ? { id: filter?.owner, repr: 'Фільтрування задано зовнішнім компонентом' } : currentUser.profile.authority);
  const useFilterByOwner = !!filter?.owner;
  const [budget, setBudget] = useState(currentUser.profile.budget);
  const reqDate = useMemo(
    () => cDate.replace('-', '').replace('-', ''),
    [cDate],
  );

  const flt = useMemo(
    () => ({
      ...(showInactive ? {} : { [ACTIVE_FIELD]: true }),
      [DATE_PARAMETER]: reqDate,
      ...(!authority ? {} : { owner: authority.id }),
      ...filter,
    }),
    [authority, filter, reqDate, showInactive],
  );

  const newChild = cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
  });

  const onChangeBudget = useCallback(
    (e, v) => {
      if (v.id !== budget.id) setAuthority({ id: null, repr: '' });
      setBudget(v);
    },
    [budget.id],
  );

  const authorityFilter = useMemo(
    () => ({ budget: budget && budget.id }),
    [budget],
  );

  return (
    <>
      <Suspense fallback={<Spinner animation="grow" />}>
        <Row>
          <Col>
            <EditorItemPicker
              label="Бюджет"
              value={budget}
              onChange={onChangeBudget}
              backend="/api/references/refbudget/"
              disabled={useFilterByOwner}
            />
          </Col>
          <Col>
            <EditorItemPicker
              label="Фінансовий орган"
              value={authority}
              onChange={(e, v) => setAuthority(v)}
              filter={authorityFilter}
              backend="/api/references/refdisposer/"
              disabled={useFilterByOwner}
            />
          </Col>
          <Col>
            <EditorDateInput
              label="Показувати на дату"
              value={cDate}
              onChange={(e, v) => setCDate(v)}
              controlId="date_filter"
            />
          </Col>
          <Col sm={2} className="d-flex align-items-end text-right">
            <EditorCheckboxInput
              controlId={checkBoxCtrlId.current}
              label="Показати недіючи"
              value={showInactive}
              onChange={(e, v) => setShowInactive(v)}
              description="Якщо обрано, будуть показані діючи та недіючи коди"
            />
          </Col>
        </Row>
      </Suspense>
      {newChild}
    </>
  );
}

ElementListerSelector.propTypes = {
  filter: PropTypes.shape({
    owner: PropTypes.string,
    [ACTIVE_FIELD]: PropTypes.bool,
    [DATE_PARAMETER]: PropTypes.string,
  }),
  children: childrenPropType.isRequired,
};

ElementListerSelector.defaultProps = {
  filter: { owner: '' },
};

export default ElementListerSelector;
