import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/treasuryStatementIncomeSB';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'date'}
              highlighted={highlights.includes('date')}
              onToggleHighlght={() => onToggleHighlght('date')}
            >
              {meta.columns.date.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'territoryCode'}
              highlighted={highlights.includes('territoryCode')}
              onToggleHighlght={() => onToggleHighlght('territoryCode')}
            >
              {meta.columns.territoryCode.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FI'}
              highlighted={highlights.includes('FI')}
              onToggleHighlght={() => onToggleHighlght('FI')}
            >
              {meta.columns.FI.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'GroupSymbols'}
              highlighted={highlights.includes('GroupSymbols')}
              onToggleHighlght={() => onToggleHighlght('GroupSymbols')}
            >
              {meta.columns.GroupSymbols.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Fond'}
              highlighted={highlights.includes('Fond')}
              onToggleHighlght={() => onToggleHighlght('Fond')}
            >
              {meta.columns.Fond.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeKDB'}
              highlighted={highlights.includes('codeKDB')}
              onToggleHighlght={() => onToggleHighlght('codeKDB')}
            >
              {meta.columns.codeKDB.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={5}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KDB'}
              highlighted={highlights.includes('KDB')}
              onToggleHighlght={() => onToggleHighlght('KDB')}
            >
              {meta.columns.KDB.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={5}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKDB'}
              highlighted={highlights.includes('elementKDB')}
              onToggleHighlght={() => onToggleHighlght('elementKDB')}
            >
              {meta.columns.elementKDB.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumReceived'}
              highlighted={highlights.includes('sumReceived')}
              onToggleHighlght={() => onToggleHighlght('sumReceived')}
            >
              {meta.columns.sumReceived.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRecDelimitation'}
              highlighted={highlights.includes('sumRecDelimitation')}
              onToggleHighlght={() => onToggleHighlght('sumRecDelimitation')}
            >
              {meta.columns.sumRecDelimitation.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRecBeginningYear'}
              highlighted={highlights.includes('sumRecBeginningYear')}
              onToggleHighlght={() => onToggleHighlght('sumRecBeginningYear')}
            >
              {meta.columns.sumRecBeginningYear.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={2}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRecBegYearDelimitation'}
              highlighted={highlights.includes('sumRecBegYearDelimitation')}
              onToggleHighlght={() => onToggleHighlght('sumRecBegYearDelimitation')}
            >
              {meta.columns.sumRecBegYearDelimitation.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reportingSymbol'}
              highlighted={highlights.includes('reportingSymbol')}
              onToggleHighlght={() => onToggleHighlght('reportingSymbol')}
            >
              {meta.columns.reportingSymbol.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
