import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.indicators;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col sm={5}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'indicator'}
          highlighted={highlights.includes('indicator')}
          onToggleHighlght={() => onToggleHighlght('indicator')}
        >
          {meta.columns.indicator.label}
          <br />
          <Badge variant="primary">{meta.columns.tasks.label}</Badge>
          <Badge variant="info">{meta.columns.type.label}</Badge>
          <Badge variant="success">{meta.columns.units.label}</Badge>
        </TableHeaderCell>
      </Col>

      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum_2'}
              highlighted={highlights.includes('sum_2')}
              onToggleHighlght={() => onToggleHighlght('sum_2')}
            >
              Факт
              <Badge className="mx-1" variant="primary">{year - 2}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf_2'}
              highlighted={highlights.includes('zf_2')}
              onToggleHighlght={() => onToggleHighlght('zf_2')}
            >
              {meta.columns.zf_2.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf_2'}
              highlighted={highlights.includes('sf_2')}
              onToggleHighlght={() => onToggleHighlght('sf_2')}
            >
              {meta.columns.sf_2.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum_1'}
              highlighted={highlights.includes('sum_1')}
              onToggleHighlght={() => onToggleHighlght('sum_1')}
            >
              Факт
              <Badge className="mx-1" variant="primary">{year - 1}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf_1'}
              highlighted={highlights.includes('zf_1')}
              onToggleHighlght={() => onToggleHighlght('zf_1')}
            >
              {meta.columns.zf_1.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf_1'}
              highlighted={highlights.includes('sf_1')}
              onToggleHighlght={() => onToggleHighlght('sf_1')}
            >
              {meta.columns.sf_1.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum0'}
              highlighted={highlights.includes('sum0')}
              onToggleHighlght={() => onToggleHighlght('sum0')}
            >
              Проект
              <Badge className="mx-1" variant="warning">{year}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {meta.columns.zf0.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {meta.columns.sf0.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col className="px-1 border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum1'}
              highlighted={highlights.includes('sum1')}
              onToggleHighlght={() => onToggleHighlght('sum1')}
            >
              Прогноз
              <Badge className="mx-1" variant="success">{year + 1}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {meta.columns.zf1.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {meta.columns.sf1.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-muted"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              Прогноз
              <Badge className="mx-1" variant="success">{year + 2}</Badge>
              p.
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {meta.columns.zf2.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left text-success"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {meta.columns.sf2.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  year: 0,
};

export default memo(TPHeader);
