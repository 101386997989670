import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const DeleteButton = forwardRef(({
  content, onClick, disabled, title,
}, ref) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faTrashAlt}
    variant="falcon-danger"
    ref={ref}
    title={title}
  />
));

DeleteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

DeleteButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити',
  disabled: false,
  title: 'Видалити',
};

export default DeleteButton;
