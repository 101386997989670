import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import closeIcon from '../../assets/icons/Close_Small.svg';

function Picture() {
  return <img src={closeIcon} alt="" />;
}

const Div = styled.div`
  display: flex;
`;

const ClearAbleInput = styled.input`
  box-sizing: border-box;
  box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
  border-right: none;
  border-radius: 2px 0px 0px 2px;
  padding: 7px 8px;
  font-size: 12px;
  width: 150px; /*150px - если есть кнопка закрыть, 170рх - если кнопка закрыть еще не появилась*/
  outline:none;
  border-left: 1px solid rgba(142, 179, 223, .5);
  border-top: 1px solid rgba(142, 179, 223, .5);
  border-bottom: 1px solid rgba(142, 179, 223, .5);
  &:focus{
    border-left: 1px solid rgb(142, 179, 223);
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:focus ~ button {
    border-right: 1px solid rgb(142, 179, 223);
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:disabled {
    border-left: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    background: #ffffff;
  }
`;

const CloseButton = styled.button`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border: 1px solid red;
  padding: 7px 4px;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.15);
  border-left: none;
  outline:none;
  background: #ffffff;
  box-sizing: border-box;
  border-left: none;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
  border-right: 1px solid rgba(142, 179, 223, .5);
  border-top: 1px solid rgba(142, 179, 223, .5);
  border-bottom: 1px solid rgba(142, 179, 223, .5);
  &:disabled {
    border-right: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    background: #ffffff;
    cursor: default;
  }
`;

class InputWithClearBtn extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    value: '',
  };

  constructor(props) {
    super(props);
    this.text = React.createRef();
    this.focusInput = this.focusInput.bind(this);
  }

  focusInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onChange({
      target: { value: '' },
    });
    this.text.current.focus();
  };

  render() {
    const {
      text,
      focusInput,
      props: {
        value,
        onChange,
        disabled,
      },
    } = this;

    return (
      <Div
        disabled={disabled}
      >
        <ClearAbleInput
          ref={text}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <CloseButton
          onClick={focusInput}
        >
          <Picture />
        </CloseButton>
      </Div>
    );
  }
}

export default InputWithClearBtn;
