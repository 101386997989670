import base from './base';

const columns = {
  ...base.columns,
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  CodeBudget: { label: 'Код 3', name: 'КодБюджетаКазн', visible: true },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      dateDoc: { label: 'Дата документа, що підтверджує виникнення бюджетного зобов\'язання', name: 'ДатаДок' },
      dateDocF: { label: 'Дата документа, що підтверджує виникнення бюджетного фінансового зобов\'язання', name: 'ДатаДокФ' },
      dateR: { label: 'Дата реєстрації', name: 'ДатаР' },
      numberDoc: { label: 'Номер документа, що підтверджує виникнення бюджетного зобов\'язання', name: 'НомерДок' },
      numberDocF: { label: 'Номер документа, що підтверджує виникнення бюджетного фінансового зобов\'язання', name: 'НомерДокФ' },
      KVKCode: { label: 'КВК код ', name: 'КВККод' },
      Code: { label: 'Код мережа', name: 'КодМережа' },
      KTKVCode: { label: 'КТКВ код', name: 'КТКВКод' },
      KEKVCode: { label: 'КЕКВ код', name: 'КЕКВКод' },
      FondCode: { label: 'Фонд код', name: 'ФондКод' },
      sum: { label: 'Сума бюджетного фінансового зобов\'язання', name: 'Сумма' },
      sumAdvance: { label: 'Попередня оплата', name: 'СуммаАванс' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Fond: { label: 'Фонд', name: 'Фонд' },
      KVK: { label: 'КВК', name: 'КВК' },
      FO: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      note: { label: 'Примітка', name: 'Примечание' },
      edrpou: { label: 'ЄДРПОУ', name: 'ЕДРПОУ' },
      edrpouK: { label: 'ЄДРПОУ кредитора', name: 'ЕДРПОУК' },
      nameCSU: { label: 'Найменування розпорядника', name: 'НаимРасп' },
      mfoK: { label: 'МФО кредитора', name: 'МФОК' },
      account: { label: 'Номер рахунка кредитора', name: 'РСчетК' },
      flag: { label: 'Інформація про обмерення оприлюднення', name: 'Флаг' },
    },
  },
};

const definition = {
  label: 'Казн. виписка фінансові зобов\'язання',
  name: 'treasuryStatementFinObligation',
  backendName: 'ВыпискаФинОбяз',
  columns,
  frontend: 'doc/treasuryStatementFinObligation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'Budget',
      'Author',
      'CodeBudget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
