import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font } from '../../constants/globalStyle';

const Div = styled.div`
  display: flex;
  font-size: 1em;
  position: relative;
  font-weight: 400;
  font-style: normal;
  color: rgba(0,0,0,.87); 
`;
const ErrorDiv = styled.div`
  position: absolute;
  z-index: 1
  bottom: 50px;
  background: #ffe4d9;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
    ::before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 45%;
      bottom: -18px;
      border: 9px solid;
      border-color: #ffe4d9 transparent transparent;
`;
const HeaderError = styled.p`
  // color: red;
  font-weight: 700;
  margin-bottom: 3px;
`;

const Input = styled.textarea.attrs((props) => ({
  rows: props.rows,
}))`
    resize: none;
    margin: 0;
    width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: ${(props) => (props.textLeft ? 'left' : 'right')};
    line-height: 1.21428571em;
    padding: 2.5px 2px;
    border-radius: ${(props) => props.radiusD && '.28571429rem'};
    background-color: ${(props) => (props.background ? 'white' : 'transparent')};
    border: ${(props) => (props.noBorder ? 'none' : '1px solid #afbbcc')};
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    box-shadow: ${(props) => props.required && '0px 3px 0px -2px red'};
    ${(props) => props.disabled && 'color: black'};
    ${font};
`;

const PasswordInput = styled.input.attrs((props) => ({
  type: 'password',
}))`
    margin: 0;
    width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: ${(props) => (props.textLeft ? 'left' : 'right')};
    line-height: 1.21428571em;
    padding: 2.5px 2px;
    border-radius: ${(props) => props.radiusD && '.28571429rem'};
    background-color: ${(props) => (props.background ? 'white' : 'transparent')};
    border: ${(props) => (props.noBorder ? 'none' : '1px solid #afbbcc')};
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    box-shadow: ${(props) => props.required && '0px 3px 0px -2px red'};
    ${(props) => props.disabled && 'color: black'};
    ${font};
`;

const GetTextInput = (AllSize = 0) => (
  class TextInput extends PureComponent {
    static propTypes = {
      value: PropTypes.string,
      onChange: PropTypes.func,
      autoselect: PropTypes.bool,
      disabled: PropTypes.bool,
      textLeft: PropTypes.bool,
      radius: PropTypes.bool,
      noBorder: PropTypes.bool,
      placeholder: PropTypes.string,
      id: PropTypes.number,
      rowId: PropTypes.number,
      processingKeyDown: PropTypes.func,
      setDataRef: PropTypes.func,
      required: PropTypes.bool,
      background: PropTypes.bool,
      isFocusCell: PropTypes.bool,
      rows: PropTypes.number,
      password: PropTypes.bool,
    };

    static defaultProps = {
      onChange: () => null,
      value: '',
      autoselect: true,
      disabled: false,
      textLeft: true,
      radius: false,
      noBorder: false,
      placeholder: '',
      id: null,
      rowId: null,
      setDataRef: () => null,
      processingKeyDown: () => null,
      required: false,
      background: false,
      isFocusCell: false,
      rows: 1,
      password: false,
    };

    constructor(props) {
      super(props);
      const { id, rowId, setDataRef } = this.props;
      this.state = {
        propsValue: props.value,
        errText: '',
        focused: false,
        open: false,
        value: props.value,
      };
      this.input = React.createRef();
      this.cursor = { start: 0, end: 0 };
      if (setDataRef) setDataRef(rowId, id, this.input);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.propsValue !== nextProps.value) {
        return {
          propsValue: nextProps.value,
          value: nextProps.value,
        };
      }
      return prevState;
    }

    componentDidUpdate() {
      if (this.props.isFocusCell) this.input.current.focus();
    }

    onFocusHandler = (e) => {
      if (this.props.autoselect) {
        e.target.setSelectionRange(0, e.target.value.length);
      }
      this.setState({ focused: true });
    };

    textChanger = (e) => {
      const str = e.target.value;
      this.setState({ errText: '' });
      this.setState({ value: str });
      if ((e.target.value.length > AllSize) && (AllSize > 0)) {
        this.setState({ errText: `Кількість символів не може бути більше ніж ${AllSize}` });
        this.setState({ open: true });
        e.preventDefault();
      } else {
        this.setState({ errText: '' });
        this.setState({ open: false });
      }
    };

    propChanger = (e) => {
      const str = (AllSize === 0) ? e.target.value : e.target.value.substr(0, AllSize);
      this.setState({ errText: '' });
      this.setState({ open: false });

      if (this.props.onChange) {
        this.props.onChange(e, str);
      }
    };

    render() {
      const {
        disabled, placeholder, id, rowId, processingKeyDown,
        textLeft, radius, noBorder, required, background, rows,
        password,
      } = this.props;
      const TxtInput = password ? (
        <PasswordInput
          title={this.state.value}
          required={(this.state.value === '') && required}
          placeholder={placeholder}
          textLeft={textLeft}
          noBorder={noBorder}
          radiusD={radius}
          background={background}
          value={this.state.value}
          onChange={this.textChanger}
          onFocus={this.onFocusHandler}
          onBlur={this.propChanger}
          disabled={disabled}
          ref={this.input}
          onKeyDown={(e) => { if (processingKeyDown) processingKeyDown(e, rowId, id); }}
          onClick={(e) => { if (processingKeyDown) processingKeyDown(e, rowId, id); }}
        />

      ) : (
        <Input
          title={this.state.value}
          required={(this.state.value === '') && required}
          placeholder={placeholder}
          rows={rows}
          textLeft={textLeft}
          noBorder={noBorder}
          radiusD={radius}
          background={background}
          type="text"
          value={this.state.value}
          onChange={this.textChanger}
          onFocus={this.onFocusHandler}
          onBlur={this.propChanger}
          disabled={disabled}
          ref={this.input}
          onKeyDown={(e) => { if (processingKeyDown) processingKeyDown(e, rowId, id); }}
          onClick={(e) => { if (processingKeyDown) processingKeyDown(e, rowId, id); }}
        />
      );
      return (
        <Div>
          {this.state.errText ? (
            <>
              <ErrorDiv>
                <HeaderError>Помилка!</HeaderError>
                <span>{this.state.errText}</span>
              </ErrorDiv>
              {TxtInput}
            </>
          ) : TxtInput}
        </Div>
      );
    }
  }
);

export default GetTextInput;
