import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'Период' },
};

const definition = {
  label: 'Щоденна інформація про надходження (у розрізі бюджетів)',
  name: 'RegionRevenueFactDaily',
  columns,
  frontend: 'rep/RegionRevenueFactDaily',
  backendName: 'ЕжедневнаяИнформацияОПоступленииВОбласнойБюджет',
  resultColumns: baseResultColumns,
};

export default definition;
