import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import meta from '../../../../meta';
import { CatCommandPanel } from '../../../basicEditor';

import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';

const BACKENDURL = meta.cat.kindwork.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, fieldErrors, actions.onChange);
  const considerInTreeProps = editorHooks.useCheckboxInputProps('consider_in_tree', data, fields, fieldErrors, actions.onChange);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const title = useMemo(
    () => (isNew ? `New ${options.name}` : `${options.name} ${data.repr}`),
    [data.repr, isNew, options.name],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={title}
    >
      <Row>
        <Col>
          <EditorControls.StringInput {...codeProps} />
        </Col>
        <Col>
          <EditorControls.StringInput {...nameProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput {...considerInTreeProps} />
        </Col>
      </Row>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
