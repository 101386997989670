import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import {
  Alert, DropdownButton, Dropdown,
} from 'react-bootstrap';

import Loader from '../../components/styledWrappedLoader';
import api from '../../api/req';
import init from '../../iitLibrary/eusign';
import { dellComponentFromWindowsManager, prevWindow } from '../../actions/windowsManager';
import LogicaPing from '../Logica/Ping';
import { uint8array } from './utils';

const Button = styled.button`
  background: linear-gradient(0deg, #bfd4ef, #d1e6fb, #dfeefd, #c7daf2);
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => { if (disabled) { return 'unset'; } return 'pointer'; }};
  padding: 5px 8px;
  font-weight: 700;
  width: 300px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  filter: ${({ disabled }) => { if (disabled) { return 'grayscale(1) opacity(.6)'; } return 'unset'; }};
  &:hover {
    background: #aac6e6;
  }
  :focus {
    outline: none;
  }
`;

function Authentication({ onLogin, ticket, widgetUri }) {
  const EndUserRef = useRef(null);
  const EuSignRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);

  const dispatch = useDispatch();

  const inputRef = useRef();
  const setInputFocus = useCallback(
    () => inputRef.current && inputRef.current.focus(),
    [],
  );

  const signWidgetParent = useRef();
  const certificateMessage = useRef();
  const signDataBlock = useRef();
  const iframeId = useRef(Math.random().toString(36).substring(2));

  useEffect(() => {
    EndUserRef.current = init();
    EuSignRef.current = new EndUserRef.current(
      signWidgetParent.current,
      iframeId.current,
      widgetUri,
      EndUserRef.current.FormType.ReadPKey,
    );

    //= ============================================================================

    // Створення об'єкту типу endUser для взаємодії з iframe,
    // який завантажує сторінку SignWidget

    EuSignRef.current
      .ReadPrivateKey()
      .then(() => {
        signWidgetParent.current.classList.add('d-none');
        certificateMessage.current.classList.add('d-none');
        signDataBlock.current.classList.remove('d-none');
        setInputFocus();
      })
      .catch((e) => {
        setError(
          `${'Виникла помилка при зчитуванні ос. ключа. Опис помилки: '}${
            e.message || e
          }`,
        );
      });

    document.body.addEventListener('click', setInputFocus);

    return () => {
      document.body.removeEventListener('click', setInputFocus);
      EuSignRef.current.destroy();
    };
  }, [setInputFocus, widgetUri]);

  //= ============================================================================
  // Накладання підпису на дані

  const onSign = (strSign) => {
    const strUTF16 = uint8array(strSign);
    const external = false;
    const asBase64String = true;
    const signAlgo = EndUserRef.current.SignAlgo.DSTU4145WithGOST34311;
    const signType = EndUserRef.current.SignType.CAdES_X_Long;

    return (
      EuSignRef.current
        .SignData(strUTF16, external, asBase64String, signAlgo, null, signType)

        .catch((e) => {
          setError(
            `${'Виникла помилка при підписі даних. Опис помилки: '}${
              e.message || e
            }`,
          );
        })
    );
  };

  const historyUrl = useLocation();

  const processHandler = useCallback(() => {
    setIsLoading(true);

    const getTicketClick = async (signBase64, requestId) => {
      const body = {
        requestId,
        signBase64,
        userAgent: 'Test',
      };
      const responseLogin = await api.post$('logica/login/', {
        body: JSON.stringify(body),
      });

      if (responseLogin.ok) {
        setIsLoading(false);
        const answearLogin = await responseLogin.json();
        if (!answearLogin.success) {
          throw new Error(`${answearLogin.errorMessage}`);
        }
        onLogin(answearLogin.ticket);

        if (historyUrl.pathname === '/dp/authentication/') {
          dispatch(prevWindow());
        }
        dispatch(dellComponentFromWindowsManager('/dp/authentication/'));
      } else {
        throw new Error(`${responseLogin.status} ${responseLogin.statusText}`);
      }
    };

    const getIdClick = async () => {
      const response = await api.get$('logica/login/');
      if (response.ok) {
        const payload = await response.json();
        const signBase64 = await onSign(payload.stringToSign);
        const { requestId } = payload;
        return { signBase64, requestId };
      }
      throw new Error(`${response.status} ${response.statusText}`);
    };
    getIdClick()
      .then(({ signBase64, requestId }) => getTicketClick(signBase64, requestId))
      .catch((err) => {
        setError(err.toString());
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, historyUrl.pathname, onLogin]);
  return (
    <div className="ms-3">
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <div
        ref={signWidgetParent}
        style={{ width: '700px', height: '500px' }}
      />
      <div ref={certificateMessage}>
        <DropdownButton id="dropdown-basic-button" title="Потрібні сертифікати? Тисніть тут" variant="outline-secondary">
          <Dropdown.Item href="http://acsk.treasury.gov.ua/poshuk_sertificativ/" target="_blank">
            Сертифікати сформовані до 31.01.2020
            року
          </Dropdown.Item>
          <Dropdown.Item href="https://ca.treasury.gov.ua/Content/xPages/Certificates/FindCertificate.aspx" target="_blank">
            Сертифікати сформовані після 31.01.2020
            року
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div ref={signDataBlock} className="d-none">
        {error && (
        <Alert variant="danger" dismissible onClose={() => window.location.reload()}>
          <Alert.Heading>
            При авторизації виникла помилка:
          </Alert.Heading>
          {error}
        </Alert>
        )}
        {error === 500 && (
        <h3>Спробуйте пізніше або зверніться в службу підтримки.</h3>
        )}
        {!error && (!ticket || ticket === 'null') && (
        <Loader isLoading={isLoading}>
          <Button onClick={processHandler} ref={inputRef} disabled={!logicaPingSuccess}>
            Перевірка ЕЦП у &quot;LOGICA&quot;
          </Button>
        </Loader>
        )}
      </div>
    </div>
  );
}

Authentication.propTypes = {
  onLogin: PropTypes.func.isRequired,
  ticket: PropTypes.string,
  widgetUri: PropTypes.string,
};

Authentication.defaultProps = {
  ticket: null,
  widgetUri: 'https://ciat.net.ua',
};

export default Authentication;
