import styled from 'styled-components';

export const StyledSegment = styled.div`
  overflow: auto;
`;

export const StyledList = styled.div`
  overflow: auto;
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  & > *{
    flex: 0 0 auto;
  }
`;

const active = {
  background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21',
};

export const ItemContainer = styled.div.attrs((props) => ({
  style: {
    ...(props.active ? active : {}),
    ...(props.level ? { paddingLeft: `${props.level * 20}px` } : {}),
  },
}))`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  &:hover{
    background: linear-gradient(0deg,#66BC7A,#66BC7A),#008F21;
  }
`;

export const ItemText = styled.div`
  font-weight: 700;
`;

export const StyledPanelsContainer = styled.div`
  display: flex;
  height: 60vh;
  overflow: hidden;
  & > * {
    flex-grow: 1;
    width: 100%;
  }
`;
