const frontendURL = '/doc/balancesAccounts/';
const backendURL = '/api/cityplans/cityplanbalancesonaccountsheader/';
const name = 'Інформація про залишки коштів на рахунках';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
