import React from 'react';

const WinManagerContext = React.createContext({
  linkComponents: [],
  currentComponent: null,
  currentProps: {},
  currentURL: '',
  getNewPath: () => null,
  dellComponentFromWindowsManager: () => null,
  switchWindow: () => null,
  addComponentToWindowsManager: () => null,
  listersTimeStamps: {},
  closeWarnings: {},
  nextWindow: () => null,
  prevWindow: () => null,
  sendUpdateSignal: () => null,
  setCloseWarning: () => null,
  setWindowTitle: () => null,
  containerRefs: () => () => null,
  currentContainer: document.body,
});

export default WinManagerContext;
