import React from 'react';
import PropTypes from 'prop-types';
import ReportItem, { reportPropType } from './item';

function ReportsList({ reports }) {
  return (
    <div style={{ marginTop: '15px' }}>
      {reports.map((report) => (
        <ReportItem key={report.id} report={report} />
      ))}
    </div>
  );
}

ReportsList.propTypes = {
  reports: PropTypes.arrayOf(reportPropType).isRequired,
};

export default ReportsList;
