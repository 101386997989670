import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import md from '../../../../constants/meta/catalogs/bankAccounts';
import enums from '../../../../constants/meta/enums';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import AccTypeSelector from './typeselector';

const StdLister = ListerFactory({
  modelType: 'cat',
  modelName: 'bankAccounts',
  viewType: 'lister',
})(CatTableListerFactory({}));

const CSUfilterValues = [
  { value: 1, label: 'Власні' },
  { value: 2, label: 'Розпорядників нижчого рівня' },
  { value: 3, label: 'Всі' },
];

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
  isFinManagment: store.getIn(['auth', 'sessionOptions', 'isFinManagment'], false),
});

const typeValues = Object.values(enums.BankAccaountTypes);

function BankAccounstLister(props) {
  const {
    FO, isAdmin, isFinManagment,
  } = useSelector(mapState);

  const [csuFValue, setcsuFValue] = useState(isAdmin ? 3 : 1);
  const [kindValue, setKindValue] = useState(isFinManagment ? enums.BankAccaountTypes.boiler.name : enums.BankAccaountTypes.reg.name);
  const [onlyValid, setOnlyValid] = useState(true);

  const accKindFlt = useMemo(
    () => {
      switch (csuFValue) {
        case 1:
          return [{
            fieldName: md.columns.owner.name,
            value: FO.toJS(),
          }];
        case 2:
          return [{
            fieldName: `${md.columns.owner.name}.Родитель`,
            value: FO.toJS(),
          }];
        default:
          return [];
      }
    },
    [FO, csuFValue],
  );

  const flt = useMemo(
    () => [
      ...accKindFlt,
      {
        fieldName: md.columns.AccountType.name,
        value: kindValue,
      },
      ...(onlyValid ? [{
        fieldName: md.columns.Inactive.name,
        value: false,
      }] : []),
    ],
    [accKindFlt, kindValue, onlyValid],
  );

  return (
    <>
      <FilteredTabs
        values={CSUfilterValues}
        value={csuFValue}
        onChange={(e, v) => setcsuFValue(v)}
      />
      <AccTypeSelector
        typeValue={kindValue}
        typeValues={typeValues}
        onSetType={setKindValue}
        onlyValid={onlyValid}
        onSetOnlyValid={setOnlyValid}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StdLister filter={flt} noHierarchy {...props} />
    </>
  );
}

export default BankAccounstLister;
