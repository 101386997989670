import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  indicatorNom: { label: 'Номер показника', name: 'ПоказательНомер', visible: true },
  indicatorGroup: { label: 'Це групуючий показник', name: 'ПоказательГруппирующий', visible: true },
  section: { label: 'Розділ', name: 'Раздел', visible: true },
};

export const forecastIndicatorBR = {
  label: 'Довідник показників бюджету розвитку',
  name: 'forecastIndicatorBR',
  backendName: 'ПоказателиБюджетаРазвития',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/forecastIndicatorBR',
  listColumns: [...(new Set([...reqListerColumns, 'indicatorNom', 'indicatorGroup', 'section']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default forecastIndicatorBR;
