import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import FieldItem from './availableFieldItem';
import { StyledSegment, StyledList } from '../StyledComponents';

function AvailableFields({
  items, selectHandler, choiceHandler, toggleHandler,
}) {
  const showItems = useMemo(
    () => items.reduce((R, item, key) => ([
      ...R,
      (
      // eslint-disable-next-line react/no-array-index-key
        <Draggable ignoreContainerClipping key={key} draggableId={key} index={R.length}>
          {(provided) => (
            <FieldItem
              ref={provided.innerRef}
              id={key}
              item={item}
              level={item.get('_LEVEL', 0)}
              onClickHandler={selectHandler}
              onDoubleClickHandler={choiceHandler}
              onIconClickHandler={toggleHandler}
              dragHandleProps={provided.dragHandleProps}
              draggableProps={provided.draggableProps}
            />
          )}
        </Draggable>
      ),
    ]), []),
    [choiceHandler, items, selectHandler, toggleHandler],
  );
  return (
    <StyledSegment>
      <Droppable droppableId="DC.AvailableFields">
        {(provided) => (
          <StyledList ref={provided.innerRef} {...provided.droppableProps}>
            {showItems}
            {provided.placeholder}
          </StyledList>
        )}
      </Droppable>
    </StyledSegment>
  );
}

AvailableFields.propTypes = {
  items: PropTypes.instanceOf(OrderedMap).isRequired,
  selectHandler: PropTypes.func,
  choiceHandler: PropTypes.func,
  toggleHandler: PropTypes.func,
};

AvailableFields.defaultProps = {
  selectHandler: () => null,
  choiceHandler: () => null,
  toggleHandler: () => null,
};

export default memo(AvailableFields);
