import React, { useEffect, useMemo } from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/findocanalisys';

const URL = instance.backendURL;

function Rep() {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const reportTypeProps = editorHooks.useSelectorInputProps('report_type', data, fields, fieldErrors, actions.onChange);

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const { onChange } = actions;
  useEffect(
    () => {
      onChange(() => ({ filters }));
    },
    [filters, onChange],
  );
  useEffect(
    () => {
      if (data.filters && data.filters.length && !filters.length) {
        loadFilters(data.filters);
      }
    },
    [data.filters, filters.length, loadFilters],
  );
  return (
    <PrintFormContainer
      loading={loading}
      err={err}
      actions={actions}
      ref={resultRef}
      pageLayout={pageLayout}
      quickSettings={(
        <Row>
          <Col md={3}>
            <EditorControls.DateInput {...bdateProps} />
          </Col>
          <Col md={3}>
            <EditorControls.DateInput {...edateProps} />
          </Col>
        </Row>
      )}
      settings={(
        <>
          <Row>
            <Col md={6}>
              <EditorControls.SelectorInput {...reportTypeProps} />
            </Col>
            <Col md={6}>
              <EditorControls.ItemPicker {...authorityProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DndProvider backend={HTML5Backend}>
                <FiltersEditor
                  filters={displayFilters}
                  filtersHandlers={filtersHandlers}
                  availableFilters={availableFilters}
                />
              </DndProvider>
            </Col>
          </Row>
        </>
      )}
    />
  );
}

export default Rep;
