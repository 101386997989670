import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from 'react-bootstrap';
import {
  TransTP, SFKDBTP, SFKFBTP,
} from './tabs';
import md from '../../../../constants/meta/documents/prepareForecastAdd1';

function PrepareForecastAdd1Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="trans" className="m-0 mt-2 border-bottom">
      {/* <Tab title={md.tables.ZF.label} eventKey="ZF"> */}
      {/*  <ZFTP data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
      {/* <Tab title={md.tables.SF.label} eventKey="SF"> */}
      {/*  <SFTP data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
      {/* <Tab title={md.tables.credit.label} eventKey="credit"> */}
      {/*  <CreditTP data={data} actions={actions} readOnly={readOnly} /> */}
      {/* </Tab> */}
      <Tab title={md.tables.trans.label} eventKey="trans">
        <TransTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      {data[md.columns.showKDB.name] && (
        <Tab title={md.tables.SFKDB.label} eventKey="SFKDB">
          <SFKDBTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
      {data[md.columns.showKDB.name] && (
        <Tab title={md.tables.SFKFB.label} eventKey="SFKFB">
          <SFKFBTP data={data} actions={actions} readOnly={readOnly} />
        </Tab>
      )}
    </Tabs>
  );
}

PrepareForecastAdd1Tabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default PrepareForecastAdd1Tabs;
