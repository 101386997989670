/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DocsCommandPanel from '../../../newEditor/commandPanels/docstp';
import LevelDownRow from './row';

function LevelDownDocs({
  tableActions, readOnly, tableData, documentKey, approvedKey, amountKey,
  commentKey, descriptionKey, authorityKey, authorKey,
}) {
  const approval = approvedKey !== '';
  return (
    <>
      {approval && (
      <DocsCommandPanel
        tableActions={tableActions}
        readOnly={readOnly || !tableData.length}
      />
      )}
      <Row xxl={4} className="g-2">
        {tableData.map((row, rowId) => (
          <Col key={rowId}>
            <LevelDownRow
              rowId={rowId}
              readOnly={readOnly}
              document={row[documentKey]}
              approved={row[approvedKey]}
              amount={amountKey ? row[documentKey][amountKey] : null}
              leftText={commentKey ? row[documentKey][commentKey] : null}
              rightText={descriptionKey ? row[documentKey][descriptionKey] : null}
              leftFooterText={authorityKey ? row[documentKey][authorityKey].repr : null}
              user={authorKey ? row[documentKey][authorKey] : null}
              onToggleApproveDoc={tableActions.onToggleApproveDoc}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

LevelDownDocs.propTypes = {
  tableActions: PropTypes.shape({
    onToggleApproveDoc: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  documentKey: PropTypes.string.isRequired,
  approvedKey: PropTypes.string.isRequired,
  amountKey: PropTypes.string,
  commentKey: PropTypes.string,
  descriptionKey: PropTypes.string,
  authorityKey: PropTypes.string,
  authorKey: PropTypes.string,
};

LevelDownDocs.defaultProps = {
  tableData: [],
  readOnly: false,
  commentKey: null,
  descriptionKey: null,
  authorityKey: null,
  authorKey: null,
  amountKey: null,
};

export default LevelDownDocs;
