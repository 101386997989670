import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
  dateStart: { label: 'за період з', name: 'ДатаНач', visible: false },
  dateFin: { label: 'по', name: 'ДатаКон', visible: false },
  dataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных', visible: false },
  period: { label: 'Період', name: 'тПериод' },
  KBP: { label: 'КБП', name: 'КБП' },
  KBP7: { label: 'КПКВМБ', name: 'КБП7' },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  docCurrentType: { label: 'Завантажувати з документів поточного рівня', name: 'ЗагружатьПоТекущемуТипуДокумента' },
  inFill: {
    label: 'Доповнити існуючі дані даними з завантажених документів', name: 'Дописать', visible: true, type: 'bool',
  },
  version: { label: 'Версія', name: 'ВерсияСправочника' },
  nDoc: { label: 'НомерДок', name: 'НомерДок' },
  dDoc: { label: 'ДатаДок', name: 'ДатаДок' },
  noKbpVisual: { label: 'безКБП', name: 'безКБП' },
  fondNetFOP: { label: 'фондМережаФОП', name: 'фондМережаФОП' },
  dod6FromDod7: {
    label: 'Завантажувати дані для документа Граничні показники видатків бюджету та '
      + 'надання кредитів з бюджету головним розпорядникам коштів (Додаток 6) з документів Граничні '
      + 'показники видатків бюджету за ТПКВК (Додаток 7)',
    name: 'ЗагружатьДод6ИзДод7',
  },
  dod67romDodP2: {
    label: 'Завантажувати дані для документа Граничні показники видатків бюджету за ТПКВК (Додаток 7) '
        + 'з документа Пропозиція до прогнозу місцевого бюджету (Форма БП-1) (Додаток 2)',
    name: 'ЗагружатьДод7ИзДодП2',
  },
};

/**
 * @const
 */
const tables = {
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
      FI: { label: 'ФО', name: 'ФО' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'docSelectionForecast',
  backendName: 'ПодборДокПрогноз',
  label: 'Відбір документів',
  columns,
  tables,
};

export default definition;
