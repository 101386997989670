import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { AddRowButton, RemoveRowButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { relationPropType } from '../hooks';
import RelationItem from './item';
import { StyleTable } from '../styles';

function RelationsPage({
  relations, activeRelation, tableFields,
  onAddRelation, onSetActiveRelation, onChangeRelation, onDeleteRelation,
}) {
  const alowedTables = useMemo(
    () => Object.keys(tableFields).map((value) => ({ value, display_name: value })),
    [tableFields],
  );
  return (
    <Container fluid lg={3} md={6} sm={12} className="p-3">
      <CommandPanel leftPart={(
        <>
          <AddRowButton
            title="Додати зв\'язок"
            content=""
            onClick={onAddRelation}
          />
          <RemoveRowButton
            title="Видалити зв\'язок"
            content=""
            onClick={() => onDeleteRelation(activeRelation)}
            disabled={!activeRelation}
          />
        </>
      )}
      />
      <div
        style={{
          maxHeight: '72vh',
          overflow: 'auto',
        }}
      >
        <StyleTable striped bordered hover>
          <thead>
            <tr>
              <th>Таблиця</th>
              <th>Поле</th>
              <th>Таблиця</th>
              <th>Поле</th>
              <th>Обязятельное</th>
            </tr>
          </thead>
          <tbody>
            {relations.map((rel) => (
              <RelationItem
                key={rel.key}
                onClick={() => onSetActiveRelation(rel.key)}
                onChange={onChangeRelation}
                tableFields={tableFields}
                alowedTables={alowedTables}
                active={rel.key === activeRelation}
                relation={rel}
              />
            ))}
          </tbody>
        </StyleTable>
      </div>
    </Container>
  );
}

RelationsPage.propTypes = {
  relations: PropTypes.arrayOf(relationPropType).isRequired,
  activeRelation: PropTypes.string,
  onAddRelation: PropTypes.func.isRequired,
  onChangeRelation: PropTypes.func.isRequired,
  onDeleteRelation: PropTypes.func.isRequired,
  onSetActiveRelation: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
};

RelationsPage.defaultProps = {
  activeRelation: null,
};

export default memo(RelationsPage);
