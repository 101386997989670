import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import TPHeader from './header';
import TPRow from './row';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/draftAnnualSpend';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import DefaultCPButton from '../../../../../../components/bootstrap_components/controls/defaultButton';

const tableMeta = md.tables.tvDoc;

function TvDocTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const onCheckAll = useCallback(
    () => actions.onChange((o) => ({
      ...o,
      [md.tables.tvDoc.name]: o[md.tables.tvDoc.name]
        .map((row) => ({ ...row, [md.tables.tvDoc.columns.prim.name]: true })),
    })),
    [actions],
  );
  const onUncheckAll = useCallback(
    () => actions.onChange((o) => ({
      ...o,
      [md.tables.tvDoc.name]: o[md.tables.tvDoc.name]
        .map((row) => ({ ...row, [md.tables.tvDoc.columns.prim.name]: false })),
    })),
    [actions],
  );
  const onToggleAll = useCallback(
    () => actions.onChange((o) => ({
      ...o,
      [md.tables.tvDoc.name]: o[md.tables.tvDoc.name]
        .map((row) => ({
          ...row,
          [md.tables.tvDoc.columns.prim.name]: !row[md.tables.tvDoc.columns.prim.name],
        })),
    })),
    [actions],
  );

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <ButtonGroup className="ms-2">
          <DefaultCPButton onClick={onCheckAll} label="Відмітити все" variant="outline-primary">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 " />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
            </span>
          </DefaultCPButton>
          <DefaultCPButton onClick={onUncheckAll} label="Зняти всі відмитки" variant="outline-secondary">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faSquare} transform="shrink-6 up-10" />
              <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
              <FontAwesomeIcon icon={faSquare} transform="shrink-6 down-10" />
            </span>
          </DefaultCPButton>
          <DefaultCPButton onClick={onToggleAll} label="Змінити все" variant="outline-success">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
              <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
              <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
            </span>
          </DefaultCPButton>
        </ButtonGroup>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

TvDocTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

TvDocTP.defaultProps = {
  readOnly: false,
};

export default memo(TvDocTP);
