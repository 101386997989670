import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, FormCheck } from 'react-bootstrap';

function DocList({
  docs, onToggleDoc, onSelectDoc, activeDoc,
}) {
  return (
    <div className="h-100 d-flex flex-column">
      <div className="h5 text-center bg-primary-subtle border rounded">
        Знайдені документи
      </div>
      <ListGroup className="overflow-auto flex-fill">
        {docs.map((doc) => (
          <ListGroup.Item
            key={doc.id}
            active={doc.id === activeDoc}
            onClick={() => onSelectDoc(doc.id)}
            variant="primary"
            action
          >
            <FormCheck
              id={doc.id}
              type="switch"
              label={doc.repr}
              checked={doc.use}
              onChange={() => onToggleDoc(doc.id)}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

DocList.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
    use: PropTypes.bool.isRequired,
  })).isRequired,
  onToggleDoc: PropTypes.func.isRequired,
  onSelectDoc: PropTypes.func.isRequired,
  activeDoc: PropTypes.number,
};

DocList.defaultProps = {
  activeDoc: null,
};

export default DocList;
