import React from 'react';
import Lister from '../../lister/lister';

function PublicBudgetLister() {
  return (
    <Lister
      modelType="doc"
      modelName="publicBudget"
    />
  );
}

export default PublicBudgetLister;
