import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/changeToAssignPlanSource';
import ChangeToAssignPlanSource from './editor';
import APMenu from './menu';
import ChangeToAssignPlanSourceTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */
// function isReadOnly(data) {
//   return !!data[md.columns.isApproved.name];
// }
const tablename = 'balancing';

const tableMD = md.tables[tablename];

function ChangeToAssignPlanSourceEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    // readOnlyGetter: isReadOnly,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [tableData, setTableData] = useState([]);
  const onRowChange = useCallback(
    async (e, rowIndex, partData) => actions.onChange((oldData) => ({
      ...oldData,
      [tableMD.name]: [
        ...oldData[tableMD.name].slice(0, rowIndex),
        { ...oldData[tableMD.name][rowIndex], ...partData },
        ...oldData[tableMD.name].slice(rowIndex + 1),
      ],
    })),
    [actions],
  );

  const onSumChange = useCallback(
    (e, month, kfbCode, sumToChange) => {
      const rowName = kfbCode[0] in ['1', '2', '3']
        ? 'Фінансування за типом кредитора(дані із документа)'
        : 'Фінансування за типом боргового зобов`язання(дані із документа)';
      tableData.map((row, index) => {
        if (row[tableMD.columns.row.name] === rowName
        || (row[tableMD.columns.row.name] === 'ВСЬОГО НЕБАЛАНС:'
            && kfbCode[0] in ['1', '2', '3'])) {
          onRowChange(e, index, {
            [tableMD.columns[`Sum${month}`].name]: row[tableMD.columns[`Sum${month}`].name] + sumToChange,
            [tableMD.columns.Sum.name]: row[tableMD.columns.Sum.name] + sumToChange,
          });
        }
        return null;
      });
    },
    [onRowChange, tableData],
  );
  useEffect(() => {
    setTableData(data[tableMD.name]);
  }, [data]);

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <ChangeToAssignPlanSource
            data={data}
            actions={actions}
            permissions={permissions}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            tableData={tableData}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <ChangeToAssignPlanSourceTabs
            data={data}
            actions={actions}
            permissions={permissions}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            onSumChangeForBalance={onSumChange}
          />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

ChangeToAssignPlanSourceEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ChangeToAssignPlanSourceEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { ChangeToAssignPlanSourceEditor as StdEditor };

export default getRouterEditor(md)(ChangeToAssignPlanSourceEditor);
