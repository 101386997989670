const frontendURL = '/doc/budgetExecution/';
const backendURL = '/api/budgetrequest/executionbpheader/';
const name = 'Виконання бюджетної програми за показниками';

const instance = {
  frontendURL,
  backendURL,
  name,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};

export default instance;
