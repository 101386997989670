import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R210G3: { label: 'R01G3', name: 'R213G3' },
  R210G4: { label: 'R01G3', name: 'R213G4' },
  R210G5: { label: 'R01G3', name: 'R213G5' },
  R210G6: { label: 'R01G3', name: 'R213G6' },
  R210G7: { label: 'R01G3', name: 'R213G7' },
  R211G3: { label: 'R01G3', name: 'R214G3' },
  R211G4: { label: 'R01G3', name: 'R214G4' },
  R211G5: { label: 'R01G3', name: 'R214G5' },
  R211G6: { label: 'R01G3', name: 'R214G6' },
  R211G7: { label: 'R01G3', name: 'R214G7' },
  R212G3: { label: 'R01G3', name: 'R215G3' },
  R212G4: { label: 'R01G3', name: 'R215G4' },
  R212G5: { label: 'R01G3', name: 'R215G5' },
  R212G6: { label: 'R01G3', name: 'R215G6' },
  R212G7: { label: 'R01G3', name: 'R215G7' },
};

const tables = {
  JSON: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол1', name: 'Кол2' },
      col3: { label: 'Кол1', name: 'Кол3' },
      col4: { label: 'Кол1', name: 'Кол4' },
      col5: { label: 'Кол1', name: 'Кол5' },
      col6: { label: 'Кол1', name: 'Кол6' },
      col7: { label: 'Кол1', name: 'Кол7' },
      col8: { label: 'Кол1', name: 'Кол8' },
      col9: { label: 'Кол1', name: 'Кол9' },
      col10: { label: 'Кол1', name: 'Кол10' },
      col11: { label: 'Кол1', name: 'Кол11' },
    },
  },
  data: {
    name: 'ТчДок',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
      Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd12Printing',
  backendName: 'ПечатьПрогнозДод12',
  label: 'Показники міжбюджетних трансфертів іншим бюджетам   (Додаток 12)',
  frontend: 'dp/forecastAdd12Printing',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
