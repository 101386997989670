import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Col, Container, Row,
} from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import { FieldPropType } from '../../../newEditor/propTypes';
import { CiatAppContext } from '../../../../providers';
import EditorFooter from '../../../../components/Containers/editorFooter';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryTargetProgram({
  data, fields, actions, id, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.targetprogram.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });

  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isStateProgramProps = editorHooks.useCheckboxInputProps('is_state_program', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const capitalSpendingProps = editorHooks.useCheckboxInputProps('capital_spending', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const parentProps = editorHooks.useItemInputProps('parent', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const budgetProps = editorHooks.useItemInputProps('budget', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const approvedProps = editorHooks.useTextInputProps('approved', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const contentEventsProps = editorHooks.useTextInputProps('content_events', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);

  const { currentUser } = useContext(CiatAppContext);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
      budget: historyData.budget ? historyData.budget.id : null,
    }),
    [historyData.budget],
  );

  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
        <Container fluid className="pb-3">
          {historyData.is_group && (
          <div className="w-100 text-end">
            <Badge bg="info">Група</Badge>
          </div>
          )}
          <Row>
            <Col sm={12} md={6}>
              <EditorControls.StringInput {...nameProps} />
            </Col>
            <Col sm={12} md={6}>
              <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
            </Col>
            <Col sm={12} md={6}>
              <EditorControls.TextInput {...approvedProps} />
            </Col>
            <Col sm={12} md={6}>
              <EditorControls.TextInput {...contentEventsProps} />
            </Col>
            <Col>
              <EditorControls.CheckboxInput {...isStateProgramProps} />
              <EditorControls.CheckboxInput {...capitalSpendingProps} />
              <EditorControls.CheckboxInput {...isActiveProps} />
            </Col>
            {currentUser.is_superuser && (
            <Col sm={12}>
              <EditorControls.ItemPicker {...budgetProps} />
            </Col>
            )}
          </Row>
        </Container>
        <EditorFooter>
          <div className="text-muted">{historyData.budget ? historyData.budget.repr : ''}</div>
        </EditorFooter>
      </HistoryContainer>
    </>
  );
}

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  is_active: PropTypes.bool,
  capital_spending: PropTypes.bool,
  is_state_program: PropTypes.bool,
  parent: PropTypes.shape({ }),
  is_group: PropTypes.bool,
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryTargetProgram.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ),
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape(
      fieldsPropTypes,
    ),
  }),
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}),
};

HistoryTargetProgram.defaultProps = {
  data: {},
  fields: {},
  errors: null,
};

export default HistoryTargetProgram;
