import React, { useCallback, useContext, useState } from 'react';
import { faFileZipper } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import meta from '../../../meta';
import { CPButton } from '../../../components/bootStrap/buttons';
import AppContext from '../../../providers/CiatAppContext';
import api from '../../../api/req';
import { downloadFileFromJSON } from '../../../api/utils';

function DBFButton({ instanceId }) {
  const [loading, setLoading] = useState(false);
  const { auth } = useContext(AppContext);
  const onDBF = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get(`${meta.doc.disposersMap.backendURL}${instanceId}/dbf/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      loader()
        .then((d) => downloadFileFromJSON(d.file, 'merezha.zip'))
        .catch((e) => console.error(e.message))
        .finally(() => setLoading(false));
    },
    [auth, instanceId],
  );
  return (
    <CPButton
      content="Експорт в ДБФ"
      onClick={onDBF}
      disabled={!instanceId || loading}
      icon={faFileZipper}
      variant="info"
      spin={loading}
    />

  );
}

DBFButton.propTypes = {
  instanceId: PropTypes.number,
};

DBFButton.defaultProps = {
  instanceId: null,
};

export default DBFButton;
