/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from './print_submenu';

function APMenu({ id, data, ...rest }) {
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <PrintModal id={id} data={data} />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default APMenu;
