import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/yearAssigment';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ThreeColumnsGridContainer,
  SevenColumnsGridContainer,
  ContainerDiv,
  ResultSegment, DivWithPropsLabel, StyledPane,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import ItemField from '../../field/selectorField/item/itemField';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer';

const FourColumnsGrid = styled(SevenColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15%;
  grid-column-gap: 1%;
`;

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

class ReportYearAssigmentEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    sessionOptions: new Map(),
  };

  constructor(props) {
    super(props);
    this.resultMountNode = [`rep/${md.name}/reportEditor`, 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;
    const FO = headerForm.get('FO', new Map());

    const Bookmarks = (
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    );

    const BudgetLabel = (
      <div>
        <StyledLabel>{md.columns.budget.label}</StyledLabel>
        {
          isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
            />
          ) : (
            <ItemField
              value={headerForm.get('budget', new Map())}
              disabled
            />
          )
        }
      </div>
    );

    const FoLabel = (
      <div>
        <StyledLabel>{md.columns.FO.label}</StyledLabel>
        {
          isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('FO', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'FO'], v));
                dispatch(changeField(['headerForm', 'signVariant'], new Map()));
              }}
            />
          ) : (
            <ItemField
              value={headerForm.get('FO', new Map())}
              disabled
            />
          )
        }
      </div>
    );

    const fundKEKVTypes = {
      none: { label: 'Не враховувати', name: '' },
      ...enums.FundKEKVKInds,
    };

    const fundKEKVOptions = Object.values(fundKEKVTypes).map((_enum) => (
      { label: _enum.label, value: _enum.name }));

    const SettingsButton = (
      <div>
        <div>
          <ThreeColumnsGridContainer>
            <div>
              {BudgetLabel}
            </div>
            <div>
              {FoLabel}
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.consolidated.label}
                value={headerForm.get('consolidated', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.bySubLevelCSU.label}
                value={headerForm.get('bySubLevelCSU', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'bySubLevelCSU'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.byHighLevelCSU.label}
                value={headerForm.get('byHighLevelCSU', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'byHighLevelCSU'], v))}
              />
            </div>
          </ThreeColumnsGridContainer>
          <FourColumnsGrid>
            <div>
              <StyledLabel>{md.columns.reportType.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('reportType', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
                values={Object.values(ReportTypes)}
                name="reportType"
              />
            </div>
            <div>
              <StyledLabel>{md.columns.planType.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('planType', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
                values={Object.values(enums.PlaningKind).map((_e) => (
                  { label: _e.label, value: _e.name }))}
                name="planType"
              />
            </div>

            <div>
              <StyledLabel>{md.columns.fundsType.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('fundsType', false)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'fundsType'], v))}
                values={Object.values(fundKEKVOptions)}
                name="fundsType"
              />
            </div>

            <div>

              <Fields.CheckboxField
                value={headerForm.get('incDB', false)}
                label={md.columns.incDB.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'incDB'], v))}
              />

              <Fields.CheckboxField
                value={headerForm.get('in1000', false)}
                label={md.columns.in1000.label}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
              />
            </div>

          </FourColumnsGrid>
        </div>
        {Bookmarks}
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
              noBorder
            />
          </div>
          <div>
            <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('signVariant', new Map())}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
              // filter={[
              //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportYearAssigmentEditor);
