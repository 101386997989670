import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'extraMoney';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI, onSR,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
          <ItemPicker
            value={row[tableMD.columns.elementKEKV.name]}
            modelType="cat"
            modelName="elementKEKV"
            filter={FIOwnerFilter}
            onChange={async (e, v) => {
              await onRowChange(
                e,
                { [tableMD.columns.elementKEKV.name]: v },
              );
              await onSR('CHANGE_KEKV_IN_EXTRA_MONEY', { rowId: rowIndex });
            }}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {row[tableMD.columns.KEKV.name] && row[tableMD.columns.KEKV.name].repr}
              </InputGroup.Text>

            )}
          />
        </TableRowCell>
      </Col>
      <Col>
        <Row>
          <Col sm={12}>
            <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
              <NumberInput
                value={row[tableMD.columns.sum0.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum0.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
              <NumberInput
                value={row[tableMD.columns.sum1.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.sum2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col sm={12}>
            <TableRowCell column="reason1" highlighted={highlights.includes('reason1')}>
              <TextInput
                value={row[tableMD.columns.reason1.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.reason1.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={12}>
            <TableRowCell column="reason2" highlighted={highlights.includes('reason2')}>
              <TextInput
                value={row[tableMD.columns.reason2.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.reason2.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
