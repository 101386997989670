import PlanExchangeEditor from './plans';
import FactExchangeEditor from './facts';
import RevenueExchangeEditor from './revenue';
import SocialPaymentExchangeEditor from './socialPayment';

const Exchange = {
  ExchangePlan: {
    Editor: PlanExchangeEditor,
  },
  ExchangeIncome: {
    Editor: RevenueExchangeEditor,
  },
  ExchangeExpenses: {
    Editor: FactExchangeEditor,
  },
  ExchangeSocialPayment: {
    Editor: SocialPaymentExchangeEditor,
  },
};

export default Exchange;
