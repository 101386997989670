import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        xl={12}
        sm={12}
        className="col-lg-12 col-md-12 d-flex flex-column justify-content-end"
      >
        <Row sm={12} noGutters className="text-center">
          <TableHeaderCell
            className="col-lg-3 col-md-3  text-center text-xl-left"
            active={activeCol === 'nls'}
            highlighted={highlights.includes('nls')}
            onToggleHighlight={() => onToggleHighlight('nls')}
          >
            {fields.nls.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="col-lg-2 col-md-2 text-center text-xl-left"
            active={activeCol === 'ostf'}
            highlighted={highlights.includes('ostf')}
            onToggleHighlight={() => onToggleHighlight('ostf')}
          >
            {fields.ostf.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="col-lg-2 col-md-2 text-center text-xl-left"
            active={activeCol === 'socred'}
            highlighted={highlights.includes('socred')}
            onToggleHighlight={() => onToggleHighlight('socred')}
          >
            {fields.socred.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="col-lg-2 col-md-2 text-center text-xl-left"
            active={activeCol === 'sodeb'}
            highlighted={highlights.includes('sodeb')}
            onToggleHighlight={() => onToggleHighlight('sodeb')}
          >
            {fields.sodeb.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="col-lg-2 col-md-2 text-center text-xl-left"
            active={activeCol === 'oste'}
            highlighted={highlights.includes('oste')}
            onToggleHighlight={() => onToggleHighlight('oste')}
          >
            {fields.oste.label}
          </TableHeaderCell>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
