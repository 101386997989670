import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденОбластью' },
};

/**
 * @const
 */
const definition = {
  name: 'netFOPPrint',
  backendName: 'ПечатьМережаФОП',
  label: 'Мережа ФОП',
  frontend: 'dp/netFOPPrint',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
