import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { DOC_REGISTRY_KINDS } from './const';

function Header({
  fields, activeCol, highlights, onToggleHighlight, kind,

}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col md={9}>
        {kind === DOC_REGISTRY_KINDS.LAW_OBLIGATION && (
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'law_obligation'}
          highlighted={highlights.includes('law_obligation')}
          onToggleHighlight={() => onToggleHighlight('law_obligation')}
        >
          {fields.law_obligation.label}
        </TableHeaderCell>
        )}
        {kind === DOC_REGISTRY_KINDS.FIN_OBLIGATION && (
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'fin_obligation'}
          highlighted={highlights.includes('fin_obligation')}
          onToggleHighlight={() => onToggleHighlight('fin_obligation')}
        >
          {fields.fin_obligation.label}
        </TableHeaderCell>
        )}
        {kind === DOC_REGISTRY_KINDS.PAYMENT_ORDER && (
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'payment_order'}
          highlighted={highlights.includes('payment_order')}
          onToggleHighlight={() => onToggleHighlight('payment_order')}
        >
          {fields.payment_order.label}
        </TableHeaderCell>
        )}
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'amount'}
          highlighted={highlights.includes('amount')}
          onToggleHighlight={() => onToggleHighlight('amount')}
        >
          {fields.amount.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  kind: PropTypes.oneOf(Object.values(DOC_REGISTRY_KINDS)),
};

Header.defaultProps = {
  activeCol: null,
  kind: null,
};

export default Header;
