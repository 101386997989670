import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faComment, faLock, faLockOpen, faTag,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { moneysStingOptions } from '../../../../constants/moneys';
import { metaBackends } from '../../../../meta';
import { foreignPropType } from '../../../../constants/backend/propTypes';
import UserShow from '../../../../components/Controls/user_show';

function LevelDownRow({
  rowId, document, approved, readOnly, onToggleApproveDoc, amount,
  leftText, rightText, leftFooterText, user,
}) {
  const amountClass = amount < 0 ? 'text-danger' : 'text-success';
  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title>
          <Link to={`${metaBackends[document.resource].frontendURL}${document.id}/`}>
            {document.repr}
          </Link>
        </Card.Title>
        <div className="d-flex">
          {approved !== null && (
          <Button
            variant={approved ? 'outline-success' : 'outline-danger'}
            onClick={(e) => onToggleApproveDoc(e, rowId)}
            disabled={readOnly}
          >
            <FontAwesomeIcon icon={approved ? faLock : faLockOpen} className="me-2" />
            {approved ? 'Отримано' : 'Не отримано'}
          </Button>
          )}
          {amount !== null && (
            <Card.Title as="h4" className={classNames('text-end flex-grow-1', amountClass)}>
              {amount.toLocaleString('uk', moneysStingOptions)}
            </Card.Title>
          )}
        </div>
        <hr />
        <Card.Text>
          {!!leftText && (
            <>
              <FontAwesomeIcon icon={faComment} className="me-2" />
              {leftText}
            </>
          )}
          {!!rightText && (
            <>
              <FontAwesomeIcon icon={faTag} className="mx-2" />
              {rightText}
            </>
          )}
        </Card.Text>
      </Card.Body>
      {(!!leftFooterText || !!user) && (

      <Card.Footer className="d-flex _justify-content-between text-muted">
        {!!leftFooterText && (
        <Card.Text className="flex-grow-1 pe-1 border-right">
          <FontAwesomeIcon icon={faBuilding} className="me-2" />
          {leftFooterText}
        </Card.Text>
        )}
        {!!user && (
          <UserShow user={user} />
        )}
      </Card.Footer>
      ) }
    </Card>
  );
}

LevelDownRow.propTypes = {
  rowId: PropTypes.number.isRequired,
  document: foreignPropType.isRequired,
  approved: PropTypes.bool,
  readOnly: PropTypes.bool,
  onToggleApproveDoc: PropTypes.func.isRequired,
  amount: PropTypes.number,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  leftFooterText: PropTypes.string,
  user: PropTypes.shape({}),
};

LevelDownRow.defaultProps = {
  readOnly: false,
  leftText: null,
  rightText: null,
  leftFooterText: null,
  user: null,
  amount: null,
  approved: null,
};

export default memo(LevelDownRow);
