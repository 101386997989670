/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CatEditorCommandPanel from '../../../newEditor/commandPanels/catalog';
import md from '../../../../constants/meta/catalogs/planing';
import logikaMD from '../../../../constants/meta/documents/docTransferToLogica';
import { emptyUid } from '../../../../constants/meta/common';

function PlanningCommandPanel({ id, actions, ...rest }) {
  const navigate = useNavigate();
  const onSendToLigika = useCallback(
    () => actions.onSR('CreateDispatchDoc').then((d) => {
      if (d && d[md.columns.docSendToLogica.name]
        && d[md.columns.docSendToLogica.name].id !== emptyUid) {
        navigate(`/${logikaMD.frontend}/${d[md.columns.docSendToLogica.name].id}`);
      }
    }),
    [actions, navigate],
  );
  return (
    <CatEditorCommandPanel
      id={id}
      actions={actions}
      {...rest}
    >
      <ButtonGroup className="mx-1">
        <Button variant="success" onClick={onSendToLigika} disabled={id === 'create'}>
          <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
          Надіслати до LOGICA
        </Button>
      </ButtonGroup>
    </CatEditorCommandPanel>

  );
}

PlanningCommandPanel.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onSR: PropTypes.func.isRequired,
  }).isRequired,
};

export default PlanningCommandPanel;
