import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  FullName: {
    label: 'Повне найменування на дату',
    name: 'ПолнНаименование',
    visible: true,
  },
  DateCl: { label: 'Дата', name: 'ДатаКл', visible: true },
  tCode: { label: 'Код', name: 'тКод', visible: true },
  Sign: { label: 'Знак', name: 'Знак', visible: true },
  OnDate: { label: 'НаДату', name: 'НаДату', visible: true },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  KFBKind: { label: 'Вид КФБ', name: 'ВидКФБ', visible: false },
};

export const kfbClassifier = {
  label: 'Класифікація фінансування (КФБ)',
  name: 'kfbClassifier',
  backendName: 'КлассификаторКФБ',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/kfbClassifier',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default kfbClassifier;
