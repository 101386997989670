import styled from 'styled-components';
import {
  ListGroupItem, Badge,
} from 'react-bootstrap';

export const StyledFieldItem = styled(ListGroupItem)`
  font-size: medium;
  background-color: white;
  z-index: auto !important;

  & > * + * {
    margin-left: 0.5rem;
  }
   &.active {
    background-color: rgba(0, 255, 247, 0.11) !important; /* Your desired background color */
    color: black; /* Your desired text color */
    border: none; 
  }
`;

export const StyledSubItem = styled.div.attrs(({ padded }) => ({
  style: {
    ...(padded ? { height: '2rem' } : { height: '0' }),
  },
}))`
  transition: height 0.3s ease-out;
`;

export const StyledBadge = styled(Badge)`
  font-weight: unset !important;
  font-size: unset !important;
`;
