import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Fields from '../../../../field';
import { changeField, processServerReq } from '../../../../../actions/editor';
import GridTemplate from './GridTemplate';
import {
  DivCell,
  InputCodeStyled,
  SumInputStyled,
} from '../../../../../components/styledTable/styledTable';

const TABLENAME = 'general';

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    FI: PropTypes.instanceOf(Map).isRequired,
    Budget: PropTypes.instanceOf(Map).isRequired,
    CSUinH: PropTypes.bool,
    UFVisible: PropTypes.bool,
    ABVisible: PropTypes.bool,
    AccByClients: PropTypes.bool,
    ClientInDoc: PropTypes.bool,
    setDataRef: PropTypes.func,
    processingKeyDown: PropTypes.func,
    activeAreaName: PropTypes.string,
    docDate: PropTypes.number.isRequired,
  };

  static defaultProps = {
    disabled: false,
    CSUinH: false,
    UFVisible: false,
    ABVisible: false,
    AccByClients: false,
    ClientInDoc: false,
    setDataRef: () => null,
    processingKeyDown: () => null,
    activeAreaName: '',
  };

  static getDerivedStateFromProps(props, state) {
    const {
      CSUinH,
    } = props;

    if ((CSUinH !== state.CSUinH)) {
      const arrBypassGrid = ['elementKFK', 'elementKEKV'];
      if (!CSUinH) arrBypassGrid.unshift('csu');
      return {
        CSUinH, arrBypassGrid,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { CSUinH } = this.props;

    const arrBypassGrid = ['elementKFK', 'elementKEKV', 'BuildObgect', 'TypeRepair', 'CSUAccount'];
    if (!CSUinH) arrBypassGrid.unshift('csu');
    arrBypassGrid.push('Sum');
    arrBypassGrid.push('SumPayment');
    this.state = { CSUinH, arrBypassGrid };
  }

  setData = (rowId, indexCell) => {
    const { setDataRef } = this.props;
    setDataRef(rowId, indexCell, this.state.arrBypassGrid[indexCell]);
  };

  render() {
    const {
      row, rowId, disabled, dispatch,
      FI, CSUinH, processingKeyDown,
      activeAreaName, docDate,
    } = this.props;

    return (
      <GridTemplate {...this.props}>
        <DivCell areaName="num">
          <InputCodeStyled
            value={row.get('num', '')}
            readOnly
          />
        </DivCell>
        {!CSUinH && (
          <DivCell areaName="KVKCode">
            <InputCodeStyled
              value={row.get('KVKCode', '')}
              readOnly
            />
          </DivCell>
        )}
        {!CSUinH && (
          <DivCell
            areaName="csu"
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'csu'))}
          >
            <Fields.SelectorField
              required
              modelName="csu"
              modelType="cat"
              value={row.get('CSU', new Map())}
              onChange={(e, v) => {
                dispatch(changeField([`tables/${TABLENAME}`, rowId, 'CSU'], v));
                dispatch(processServerReq('CHANGE_CSU_IN_TABLE', { rowId }));
                processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('csu'));
              }}
              filter={[{ fieldName: 'parent', value: FI }]}
              noHierarchy
              disabled={disabled}
              noBackground
              noBorder
              id={this.state.arrBypassGrid.indexOf('csu')}
              rowId={rowId}
              setDataRef={this.setData}
              processingKeyDown={processingKeyDown}
              isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'csu'))}
            />
          </DivCell>
        )}
        <DivCell areaName="KPKVCode">
          <InputCodeStyled
            value={row.get('KPKVCode', '')}
            readOnly
          />
        </DivCell>
        <DivCell
          areaName="elementKFK"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKFK'))}
        >
          <Fields.SelectorField
            required
            modelName="elementKFK"
            modelType="cat"
            value={row.get('elementKFK', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elementKFK'], v));
              dispatch(processServerReq('CHANGE_KFK_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('elementKFK'));
            }}
            filter={[{ fieldName: 'Владелец', value: FI }]}
            disabled={disabled}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('elementKFK')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKFK'))}
          />
        </DivCell>
        <DivCell areaName="KEKVCode">
          <InputCodeStyled
            value={row.get('KEKVCode', '')}
            readOnly
          />
        </DivCell>
        <DivCell
          areaName="elementKEKV"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKEKV'))}
        >
          <Fields.SelectorField
            required
            modelName="elementKEKV"
            modelType="cat"
            value={row.get('elementKEKV', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'elementKEKV'], v));
              dispatch(processServerReq('CHANGE_KEKV_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('elementKEKV'));
            }}
            filter={[{ fieldName: 'Владелец', value: FI }]}
            disabled={disabled}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('elementKEKV')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'elementKEKV'))}
          />
        </DivCell>
        <DivCell
          areaName="BuildObgect"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'BuildObgect'))}
        >
          <Fields.SelectorField
            rows={3}
            modelName="objectsBuilding"
            modelType="cat"
            value={row.get('BuildObgect', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'BuildObgect'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('BuildObgect'));
            }}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('BuildObgect')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'BuildObgect'))}
          />
        </DivCell>
        <DivCell
          areaName="TypeRepair"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'TypeRepair'))}
        >
          <Fields.SelectorField
            modelName="typesRepair"
            modelType="cat"
            value={row.get('TypeRepair', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'TypeRepair'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('TypeRepair'));
            }}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('TypeRepair')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'TypeRepair'))}
          />
        </DivCell>
        <DivCell
          areaName="CSUAccount"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'CSUAccount'))}
        >
          <Fields.SelectorField
            required
            modelName="bankAccounts"
            modelType="cat"
            value={row.get('CSUAccount', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'CSUAccount'], v));
              dispatch(processServerReq('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId }));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('CSUAccount'));
            }}
            filter={[{ fieldName: 'Владелец', value: row.get('CSU', new Map()) }]}
            disabled={disabled}
            noBackground
            noBorder
            id={this.state.arrBypassGrid.indexOf('CSUAccount')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'CSUAccount'))}
            params={[{
              name: 'НаДату',
              value: docDate,
            }]}
          />
        </DivCell>
        <DivCell areaName="CSUAccountNo">
          <InputCodeStyled
            value={row.get('CSUAccountNo', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="Balance">
          <SumInputStyled
            value={row.get('Balance', 0)}
            disabled
            areaName="Balance"
          />
        </DivCell>
        <DivCell
          areaName="Sum"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'Sum'))}
        >
          <SumInputStyled
            fluid
            value={row.get('Sum', 0)}
            maxValue={row.get('Balance', 0)}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'Sum'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('Sum'));
            }}
            disabled={disabled}
            id={this.state.arrBypassGrid.indexOf('Sum')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'Sum'))}
          />
        </DivCell>
        <DivCell
          areaName="SumPayment"
          isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'SumPayment'))}
        >
          <SumInputStyled
            fluid
            value={row.get('SumPayment', 0)}
            maxValue={row.get('Balance', 0)}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'SumPayment'], v));
              processingKeyDown(e, rowId, this.state.arrBypassGrid.indexOf('SumPayment'));
            }}
            disabled={disabled}
            id={this.state.arrBypassGrid.indexOf('SumPayment')}
            rowId={rowId}
            setDataRef={this.setData}
            processingKeyDown={processingKeyDown}
            isFocusCell={(this.props.row.get('IS_ACTIVE') && (activeAreaName === 'SumPayment'))}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
