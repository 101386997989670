/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import ElementKDBCommandPanel from './commandpanel';
import ElementKDBListerSelector from './lister_selector';
import { modelType, modelName } from '../def';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory({
  CommandPanel: ElementKDBCommandPanel,
}));

function ElementKDBLister(props) {
  return (
    <ElementKDBListerSelector {...props}>
      <StdLister />
    </ElementKDBListerSelector>
  );
}

export default ElementKDBLister;
