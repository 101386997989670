import React from 'react';
import PropTypes from 'prop-types';
import { faFolder as icon } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function NewFolderButton({
  disabled, onClick, content, title,
}) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      content={content}
      title={title}
      variant="falcon-default"
    />
  );
}

NewFolderButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
};

NewFolderButton.defaultProps = {
  disabled: false,
  onClick: null,
  content: 'Створити групу',
  title: 'Створити',
};

export default NewFolderButton;
