import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { longFormat } from '../../constants/date';

const StyledCardText = styled(Card.Text)`
  font-style: italic;
  font-size: 14px;
`;
const ContainerImg = styled.div`
  text-align: center;
  padding: 10px;
  height: 220px;
`;

const gifs = [
  'https://mir-s3-cdn-cf.behance.net/project_modules/disp/40448d71252497.5bbeed275baa0.gif',
  'https://i.pinimg.com/originals/c0/2c/2b/c02c2b3de937036e01f314332b406e62.gif',
  'https://mir-s3-cdn-cf.behance.net/project_modules/disp/2832fb71252497.5bbeed275ae64.gif',
  'https://i.pinimg.com/originals/59/68/a7/5968a7b2d3a8799c5eaa7298ec409e1d.gif',
  'https://i.pinimg.com/originals/bd/68/f4/bd68f479ea47ffabf32af955d43d200d.gif',
];

function ReportItem({ report }) {
  const updated = useMemo(() => {
    const d = new Date(report.updated);
    return d.toLocaleString('uk', longFormat);
  }, [report.updated]);

  const gifIndex = Math.floor(Math.random() * gifs.length);

  return (
    <Card>
      <ContainerImg>
        {report.avatarImg
          ? (<Card.Img src={report.avatarImg} style={{ width: '570px' }} />)
          : (<Card.Img src={gifs[gifIndex]} style={{ width: '250px' }} />)}

      </ContainerImg>
      <Card.Body>
        <Card.Title>
          <Link to={`/rep/${report.identifier}/${report.id}`}>
            {report.name}
          </Link>
        </Card.Title>
        <StyledCardText>
          {report.description}
        </StyledCardText>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">
          {`Останнє оновлення: ${updated}`}
        </small>
      </Card.Footer>
    </Card>
  );
}

export const reportPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  repr: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  avatarImg: PropTypes.string.isRequired,
});

ReportItem.propTypes = {
  report: reportPropType.isRequired,
};

export default ReportItem;
