import {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

const DEFAULT_META_FIELD_STATE = {
  label: '',
  include: [],
  key: '',
  ordered: '',
  display: '',
  resource: null,
  ctype: '',
  format: null,
};

const getSortedMF = (mf, fields = null, calcs = null) => mf.sort((a, b) => {
  if (a.sort < b.sort) return -1;
  if (a.sort > b.sort) return 1;
  if (fields && calcs && a.key && b.key) {
    const isResouceA = a.key in calcs || (a.key in fields && !!fields[a.key].having);
    const isResouceB = b.key in calcs || (b.key in fields && !!fields[b.key].having);
    if (!isResouceA && isResouceB) return -1;
    if (isResouceB && !isResouceA) return 1;
  }
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
});

export const useMetaFields = (fields, calcs) => {
  const [metaFields, setMetaFields] = useState([]);
  useEffect(
    () => {
      const needResort = !!metaFields.filter((mf) => !mf.sort).length;
      if (needResort) {
        const flds = fields.reduce((R, r) => ({ ...R, [r.name]: r }), {});
        const clcs = calcs.reduce((R, r) => ({ ...R, [r.name]: r }), {});
        setMetaFields(
          (of) => getSortedMF(of, flds, clcs).map((f, i) => ({ ...f, sort: (i + 1) })),
        );
      }
    },
    [calcs, fields, metaFields],
  );

  const onSetMetaFields = useCallback(
    (mf) => {
      if (Array.isArray(mf)) setMetaFields(getSortedMF(mf));
      else setMetaFields((omf) => getSortedMF(mf(omf)));
    },
    [],
  );

  const [activeMetaField, setActiveMetaField] = useState(null);

  const onAddField = useCallback(
    (name) => onSetMetaFields((oldFields) => {
      if (oldFields.filter((of) => of.name === name).length) return oldFields;
      const maxSort = Math.max(...(oldFields.map((f) => f.sort)));
      return [...oldFields, { name, ...DEFAULT_META_FIELD_STATE, sort: maxSort + 1 }];
    }),
    [onSetMetaFields],
  );

  const onMoveUp = useCallback(
    (name) => onSetMetaFields((oldFields) => {
      const idx = oldFields.reduce((R, mf, i) => (mf.name === name ? i : R), null);
      if (!idx) return oldFields;
      return [
        ...oldFields.slice(0, idx - 1),
        { ...oldFields[idx], sort: oldFields[idx - 1].sort },
        { ...oldFields[idx - 1], sort: oldFields[idx].sort },
        ...oldFields.slice(idx + 1, oldFields.length),
      ];
    }),
    [onSetMetaFields],
  );

  const onMoveDown = useCallback(
    (name) => onSetMetaFields((oldFields) => {
      const idx = oldFields.reduce((R, mf, i) => (mf.name === name ? i : R), null);
      if (idx === null || idx === oldFields.length - 1) return oldFields;
      return [
        ...oldFields.slice(0, idx),
        { ...oldFields[idx + 1], sort: oldFields[idx].sort },
        { ...oldFields[idx], sort: oldFields[idx + 1].sort },
        ...oldFields.slice(idx + 2, oldFields.length),
      ];
    }),
    [onSetMetaFields],
  );

  const onRemoveField = useCallback(
    (fName) => {
      onSetMetaFields((oldFields) => oldFields.filter((of) => of.name !== fName));
      if (activeMetaField === fName) setActiveMetaField(null);
    },
    [activeMetaField, onSetMetaFields],
  );

  const onRenameField = useCallback(
    (oldName, newName) => {
      if (!newName) return false; // Имя таблицы must have
      onSetMetaFields((oldFields) => {
        if (oldFields
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldFields;
        return oldFields.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeMetaField === oldName) setActiveMetaField(newName);
      return true;
    },
    [activeMetaField, onSetMetaFields],
  );

  const onChangeField = useCallback(
    (tableName, value) => {
      onSetMetaFields((oldFields) => oldFields
        .map((of) => (of.name !== tableName ? of : { ...of, ...value })));
    },
    [onSetMetaFields],
  );

  const metaFieldHandlers = useMemo(
    () => ({
      onAddField,
      onRenameField,
      onRemoveField,
      onChangeField,
      setActiveMetaField,
      onMoveUp,
      onMoveDown,
    }),
    [onAddField, onChangeField, onMoveDown, onMoveUp, onRemoveField, onRenameField],
  );

  return {
    metaFields, setMetaFields: onSetMetaFields, activeMetaField, metaFieldHandlers,
  };
};

export const metaFieldPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  include: PropTypes.arrayOf(PropTypes.string),
  key: PropTypes.string,
  ordered: PropTypes.string,
  display: PropTypes.string,
  resource: PropTypes.string,
  ctype: PropTypes.string,
  format: PropTypes.shape({}),
  sort: PropTypes.number,
  negative_red: PropTypes.bool,
});
