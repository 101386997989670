/* eslint-disable camelcase,react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'incomeprojectchangesrow_set', readOnlyFields, errors, fields,
  });

  const totals = useMemo(
    () => tableData.reduce(
      (Res, r) => ({
        first_year_zf: Res.first_year_zf + (r.first_year_zf || 0),
        second_year_zf: Res.second_year_zf + (r.second_year_zf || 0),
        third_year_zf: Res.third_year_zf + (r.third_year_zf || 0),
        first_year_sf: Res.first_year_sf + (r.first_year_sf || 0),
        second_year_sf: Res.second_year_sf + (r.second_year_sf || 0),
        third_year_sf: Res.third_year_sf + (r.third_year_sf || 0),
        first_year_br: Res.first_year_br + (r.first_year_br || 0),
        second_year_br: Res.second_year_br + (r.second_year_br || 0),
        third_year_br: Res.third_year_br + (r.third_year_br || 0),
        first_year_total: Res.first_year_zf + (r.first_year_zf || 0)
      + Res.first_year_sf + (r.first_year_sf || 0),
        second_year_total: Res.second_year_zf + (r.second_year_zf || 0)
      + Res.second_year_sf + (r.second_year_sf || 0),
        third_year_total: Res.third_year_zf + (r.third_year_zf || 0)
      + Res.third_year_sf + (r.third_year_sf || 0),
      }),
      {
        first_year_zf: 0,
        second_year_zf: 0,
        third_year_zf: 0,
        first_year_sf: 0,
        second_year_sf: 0,
        third_year_sf: 0,
        first_year_br: 0,
        second_year_br: 0,
        third_year_br: 0,
        first_year_total: 0,
        second_year_total: 0,
        third_year_total: 0,
      },
    ),
    [tableData],
  );

  const {
    for_3_years,
  } = data;

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          on3Years={for_3_years}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, idx) => (
          <TableRow key={`prj-${idx}`} rowIndex={idx} active={activeRow === idx} activeCol={activeCol}>
            <Row
              rowIndex={idx}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[idx]}
              readOnlyFields={tableReadOnlyFields}
              on3Years={for_3_years}
            />
          </TableRow>
        ))}
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
          on3Years={for_3_years}
        />
      </TableFooter>
    </Card>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    for_3_years: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: PropTypes.string,
    incomeprojectchangesrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    incomeprojectchangesrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    incomeprojectchangesrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    incomeprojectchangesrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
