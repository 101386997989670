import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput, DateInput,
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/financingSources';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import Factor from '../../../../../../constants/meta/enums/factor';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, Budget,
  FI, TypeTransfer,
}) {
  const baFilter = useMemo(() => [
    { fieldName: 'Владелец', value: FI },
    // { fieldName: 'ДатаЗакрытия',
    // comparisonType: comparisonTypes.greaterOrEqual, value: dateDoc },
  ], [FI]);
  // const onDateParams = useMemo(() => [{
  //   name: 'НаДату',
  //   value: dateDoc,
  // }], []);
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const budgetOwnerFilter = useMemo(() => [{ fieldName: 'Владелец', value: Budget }], [Budget]);

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKFBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onKFBcredChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFBcred.name]: v });
      await onSR('CHANGE_KFB_CRED_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onAnalyticalAccountChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.AnalyticalAccount.name]: v });
      await onSR('CHANGE_ANALITIC_ACC_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onAnalyticalAccountCostsChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.AnalyticalAccountCosts.name]: v });
      await onSR('CHANGE_ANALITIC_ACC_COSTS_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onAccountForTransferChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.AccountForTransfer.name]: v });
      await onSR('CHANGE_TRANSFER_ACC_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onAccountNoteDocChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.NoteDoc.name]: v });
      await onSR('CHANGE_NOTES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      {TypeTransfer === 1 && (
        <Col sm={12} className="d-flex">
          <Row className="w-100" noGutters>
            <Col sm={12} />
          </Row>
        </Col>
      )}
      <Col sm={12} className="d-flex">
        <Row className="w-100" noGutters>
          {TypeTransfer === 1 && (
            <Col sm={3}>
              <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKFB.name]}
                  modelType="cat"
                  modelName="elementKFB"
                  onChange={onKFBChange}
                  filter={FIOwnerFilter}
                  noHierarchy
                  readOnly={readOnly}
                  prepend={(
                    <>
                      {row[tableMD.columns.sign.name] && (
                        <InputGroup.Text>
                          {Object.values(Factor)
                            .find((s) => s.name === row[tableMD.columns.sign.name]).label}
                        </InputGroup.Text>
                      )}
                      <InputGroup.Text>
                        {row[tableMD.columns.codeKFB.name]}
                      </InputGroup.Text>
                    </>
                  )}
                />
              </TableRowCell>
              <TableRowCell column="elementKFBcred" highlighted={highlights.includes('elementKFBcred')}>
                <ItemPicker
                  value={row[tableMD.columns.elementKFBcred.name]}
                  modelType="cat"
                  modelName="elementKFB"
                  onChange={onKFBcredChange}
                  filter={FIOwnerFilter}
                  noHierarchy
                  readOnly={readOnly}
                  prepend={(
                    <>
                      {row[tableMD.columns.signCred.name] && (
                        <InputGroup.Text>
                          {Object.values(Factor)
                            .find((s) => s.name === row[tableMD.columns.signCred.name]).label}
                        </InputGroup.Text>
                      )}
                      <InputGroup.Text>
                        {row[tableMD.columns.codeKFBcred.name]}
                      </InputGroup.Text>
                    </>
                  )}
                />
              </TableRowCell>
            </Col>
          )}
          <Col sm={TypeTransfer === 1 ? 3 : 6}>
            <TableRowCell column="AnalyticalAccountCosts" highlighted={highlights.includes('AnalyticalAccountCosts')}>
              <ItemPicker
                value={row[tableMD.columns.AnalyticalAccountCosts.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onAnalyticalAccountCostsChange}
                filter={baFilter}
                // params={onDateParams}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.AnalyticalAccLabelCosts.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
            <TableRowCell column="AnalyticalAccount" highlighted={highlights.includes('AnalyticalAccount')}>
              <ItemPicker
                value={row[tableMD.columns.AnalyticalAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onAnalyticalAccountChange}
                filter={baFilter}
                // params={onDateParams}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.AnalyticalAccLabel.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>

          </Col>
          <Col sm={3}>
            <TableRowCell column="Remainder" highlighted={highlights.includes('Remainder')}>
              <NumberInput
                value={row[tableMD.columns.Remainder.name]}
                precision={2}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
              <NumberInput
                value={row[tableMD.columns.Sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col sm={3}>
            <TableRowCell column="NoteDoc" highlighted={highlights.includes('NoteDoc')}>
              <ItemPicker
                value={row[tableMD.columns.NoteDoc.name]}
                modelType="cat"
                modelName="docNotes"
                onChange={onAccountNoteDocChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="NoteCat" highlighted={highlights.includes('NoteCat')}>
              <ItemPicker
                value={row[tableMD.columns.NoteCat.name]}
                modelType="cat"
                modelName="notes"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.NoteCat.name]: v })}
                filter={budgetOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} className="d-flex">
        <Row className="w-100" noGutters>
          <Col sm={9}>
            <TableRowCell column="AccountForTransfer" highlighted={highlights.includes('AccountForTransfer')}>
              <ItemPicker
                value={row[tableMD.columns.AccountForTransfer.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onAccountForTransferChange}
                filter={baFilter}
                // params={onDateParams}
                readOnly={readOnly}
                prepend={(
                  <InputGroup.Text>
                    {row[tableMD.columns.AccForTransferLabel.name]}
                  </InputGroup.Text>
                )}
              />
            </TableRowCell>
          </Col>
          <Col sm={3}>
            <TableRowCell column="WriteOffAccount" highlighted={highlights.includes('WriteOffAccount')}>
              {row[tableMD.columns.WriteOffAccount.name]
                ? (
                  <DateInput
                    onChange={(e, v) => onRowChange(e, { [tableMD.columns.WriteOffDate.name]: v })}
                    value={row[tableMD.columns.WriteOffDate.name] || new Date()}
                    readOnly={readOnly || !row[tableMD.columns.WriteOffAccount.name]}
                    prepend={(
                      <div className="align-self-center">
                        <InputGroup.Text>
                          <CheckboxInput
                            id={`WriteOffAccount-${row[tableMD.columns.strNumber.name]}`}
                            readOnly={readOnly}
                            value={row[tableMD.columns.WriteOffAccount.name]}
                            onChange={(e, v) => onRowChange(
                              e,
                              { [tableMD.columns.WriteOffAccount.name]: v },
                            )}
                          />
                        </InputGroup.Text>
                      </div>
                    )}
                  />
                ) : (
                  <div style={{ marginLeft: '12px' }}>
                    <CheckboxInput
                      id={`WriteOffAccount-${row[tableMD.columns.strNumber.name]}`}
                      readOnly={readOnly}
                      value={row[tableMD.columns.WriteOffAccount.name]}
                      onChange={(e, v) => onRowChange(
                        e,
                        { [tableMD.columns.WriteOffAccount.name]: v },
                      )}
                    />
                  </div>
                )}
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} className="d-flex">
        <Row className="w-100 align-items-center" noGutters>
          <Col sm={12}>
            <TableRowCell column="Note" highlighted={highlights.includes('Note')}>
              <TextInput
                value={row[tableMD.columns.Note.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Note.name]: v })}
                readOnly={readOnly}
                rows="1"
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType.isRequired,
  Budget: referencePropType.isRequired,
  TypeTransfer: PropTypes.number.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
