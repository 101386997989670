import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  unit: { label: 'Одиниці виміру', name: 'Ед', visible: false },
  canEdit: { label: 'Доступно', name: 'Доступно', visible: false },
  fullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: false },
  unitLOGICA: { label: 'Одиниця виміру  Logica', name: 'ЕдИзмЛогика', visible: false },
};

export const indicatorsForTarget = {
  label: 'Показники досягнення цілей',
  name: 'indicatorsForTarget',
  backendName: 'ПоказателиДостиженияЦелей',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/indicatorsForTarget',
  listColumns: [...(new Set([...reqListerColumns, 'fullName']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default indicatorsForTarget;
