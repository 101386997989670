import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { changeField } from '../../../../../actions/dpEditor';
import GridTemplate from './GridTemplate';
import { DivCell, SumInputStyled, DivCheckCell } from '../../../../../components/styledTable/styledTable';
import Fields from '../../../../field';
import DateInput from '../../../../../components/fields/dates';

const StringInut = Fields.GetTextInput();
const StringInput6 = Fields.GetTextInput(6);
const StringInput10 = Fields.GetTextInput(10);
const NumberInput0 = Fields.GetNumberInput(3, 0, true);

const TABLENAME = 'export';

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;

    return (
      <GridTemplate>
        <DivCheckCell areaName="check">
          <Fields.CheckboxField
            value={row.get('check', false)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'check'], v))}
          />
        </DivCheckCell>
        <DivCell areaName="docno">
          <StringInut
            noBackground
            noBorder
            value={row.get('docNo', '')}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'docNo'], v))}
          />
        </DivCell>
        <DivCell areaName="docdate">
          <DateInput
            noBackground
            noBorder
            value={row.get('date', null)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'date'], v))}
          />
        </DivCell>
        <DivCell areaName="mfo">
          <StringInput6
            noBackground
            noBorder
            value={row.get('mfo', null)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'mfo'], v))}
          />
        </DivCell>
        <DivCell areaName="bankname">
          <Fields.SelectorField
            noBackground
            noBorder
            modelType="cat"
            modelName="partnerBanks"
            value={row.get('bankName', new Map())}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'bankName'], v))}
          />
        </DivCell>
        <DivCell areaName="account">
          <Fields.SelectorField
            noBackground
            noBorder
            modelType="cat"
            modelName="partnerBankAccounts"
            value={row.get('bankName', new Map())}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'account'], v))}
          />
        </DivCell>
        <DivCell areaName="client">
          <Fields.SelectorField
            noBackground
            noBorder
            modelType="cat"
            modelName="partners"
            value={row.get('client', new Map())}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'client'], v))}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInputStyled
            noBackground
            noBorder
            value={row.get('sum', 0)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'sum'], v))}
          />
        </DivCell>
        <DivCell areaName="edrpou">
          <StringInput10
            noBackground
            noBorder
            value={row.get('edrpou', null)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'edrpou'], v))}
          />
        </DivCell>
        <DivCell areaName="purpose">
          <Fields.TextAreaField
            value={row.get('purpose', '')}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'purpose'], v))}
          />
        </DivCell>
        <DivCell areaName="doc">
          <Fields.ItemField
            value={row.get('doc', new Map())}
            readOnly
          />
        </DivCell>
        <DivCell areaName="lineno">
          <NumberInput0
            value={row.get('lineno', 0)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'lineno'], v))}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
