const frontendURL = '/cat/partnerBanks/';
const backendURL = '/api/references/refcontragentbank/';
const name = 'Банки контрагентів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
