import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/indicatorPassport';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { emptyUid } from '../../../../../../constants/meta/common';
import { typeIndicatorsTransation } from '../../../../../../constants/meta/enums/typeIndicators';

const tablename = 'generalTPInd';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, selectedTask, onSR,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );
  const IndicatorOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: selectedTask,
    }],
    [selectedTask],
  );
  const showRow = (
    row[tableMD.columns.tasks.name] === undefined
      || (row[tableMD.columns.tasks.name]
      && (row[tableMD.columns.tasks.name].id === selectedTask.id
      || selectedTask === emptyUid)));

  return (
    <>
      {showRow && (
        <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="indicators" highlighted={highlights.includes('indicators')}>
                  <ItemPicker
                    value={row[tableMD.columns.indicators.name]}
                    modelType="cat"
                    modelName="indicators"
                    onChange={async (e, v) => {
                      await onRowChange(e, { [tableMD.columns.indicators.name]: v });
                      await onSR('CHANGE_INDICATORS_IN_TABLE', { rowId: rowIndex });
                    }}
                    filter={selectedTask === emptyUid ? [] : IndicatorOwnerFilter}
                    readOnly={readOnly}
                  />
                  <div className="w-100 overflow-hidden">
                    <Badge variant="primary">{row[tableMD.columns.tasks.name] && row[tableMD.columns.tasks.name].repr}</Badge>
                    <Badge variant="info">{typeIndicatorsTransation[row[tableMD.columns.typeIndicators.name]]}</Badge>
                    <Badge variant="success">{row[tableMD.columns.un.name] && row[tableMD.columns.un.name].repr}</Badge>
                  </div>
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <TableRowCell column="sumTotal" highlighted={highlights.includes('sumTotal')}>
                  <NumberInput
                    value={row[tableMD.columns.sumTotal.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumTotal.name]: value,
                    })}
                  />
                </TableRowCell>
              </Col>
            </Row>
            <Row className="w-100" noGutters>
              <Col>
                <TableRowCell column="sumGenF" highlighted={highlights.includes('sumGenF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumGenF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumGenF.name]: value,
                      [tableMD.columns.sumTotal.name]: value
                  + (row[tableMD.columns.sumSpecF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col>
                <TableRowCell column="sumSpecF" highlighted={highlights.includes('sumSpecF')}>
                  <NumberInput
                    value={row[tableMD.columns.sumSpecF.name]}
                    precision={2}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.sumSpecF.name]: value,
                      [tableMD.columns.sumTotal.name]: value
                  + (row[tableMD.columns.sumGenF.name] || 0),
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col sm={2}>
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <TextInput
                value={row[tableMD.columns.note.name]}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.note.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </TableRow>
      )}
    </>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTask: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
