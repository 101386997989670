const frontendURL = '/cat/kindwork/';
const backendURL = '/api/references/refkindwork/';
const name = 'Довідник видів робіт';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
