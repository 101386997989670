import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_41: {
    name: 'Табл41',
    label: 'Табл 41',
    columns: {
      code: { label: 'Код (всі коди окрім 99.n)', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування (всі коди окрім 99.n)', name: 'КласДомов' },
      taxesLegal1: { label: 'Зона 1, юр.ос. (всі коди окрім 99.n)', name: 'НалогЮр1' },
      taxesLegal2: { label: 'Зона 2, юр.ос. (всі коди окрім 99.n)', name: 'НалогЮр2' },
      taxesLegal3: { label: 'Зона 3, юр.ос. (всі коди окрім 99.n)', name: 'НалогЮр3' },
      taxesLegal4: { label: 'Зона 4, юр.ос. (лише коди 99.n)', name: 'НалогЮр4' },
      taxesNatural1: { label: 'Зона 1, фіз.ос. (всі коди окрім 99.n)', name: 'НалогФиз1' },
      taxesNatural2: { label: 'Зона 2, фіз.ос. (всі коди окрім 99.n)', name: 'НалогФиз2' },
      taxesNatural3: { label: 'Зона 3, фіз.ос. (всі коди окрім 99.n)', name: 'НалогФиз3' },
      taxesNatural4: { label: 'Зона 4, фіз.ос. (лише коди 99.n)', name: 'НалогФиз4' },
    },
  },
  TABL_42: {
    name: 'Табл42',
    label: 'Табл 42',
    columns: {
      code: { label: 'Код (лише коди 99.n)', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування (лише коди 99.n)', name: 'КласДомов' },
      taxesLegal1: { label: 'Зона 1, юр.ос. (лише коди 99.n)', name: 'НалогЮр1' },
      taxesLegal2: { label: 'Зона 2, юр.ос. (лише коди 99.n)', name: 'НалогЮр2' },
      taxesLegal3: { label: 'Зона 3, юр.ос. (лише коди 99.n)', name: 'НалогЮр3' },
      taxesLegal4: { label: 'Зона 4, юр.ос. (лише коди 99.n)', name: 'НалогЮр4' },
      taxesNatural1: { label: 'Зона 1, фіз.ос. (лише коди 99.n)', name: 'НалогФиз1' },
      taxesNatural2: { label: 'Зона 2, фіз.ос. (лише коди 99.n)', name: 'НалогФиз2' },
      taxesNatural3: { label: 'Зона 3, фіз.ос. (лише коди 99.n)', name: 'НалогФиз3' },
      taxesNatural4: { label: 'Зона 4, фіз.ос. (лише коди 99.n)', name: 'НалогФиз4' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 4 (стар. 3)',
  name: 'taxes3',
  backendName: 'НалогиИЛьготыДод3',
  columns,
  frontend: 'doc/taxes3',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
