import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

function FilteredTabs({ value, values, onChange }) {
  return (
    <Nav variant="tabs">
      {values.map((vv) => (
        <Nav.Item key={vv.value}>
          <Nav.Link active={vv.value === value} onClick={(e) => onChange(e, vv.value)}>
            {vv.label}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
}

FilteredTabs.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,

};
export default FilteredTabs;
