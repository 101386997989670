import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './styles.css';

const getTotal = (data, key) => data.reduce((acc, val) => acc + Number(val[key]), 0);

function TableRow({ item, isLast }) {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium table-column-width-25',
          {
            'border-bottom-0': isLast,
          },
        )}
      >
        <Link to="#platFactAcc">{item.name}</Link>
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.value}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.plan}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.fact}
      </td>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast,
        })}
      >
        <ProgressBar now={item.progress} style={{ height: 5 }} />
      </td>
    </tr>
  );
}
function AccountsTable({ data }) {
  return (
    <Table className="fs--1 mb-0">
      <thead className="bg-200 text-800">
        <tr>
          <th className="table-column-width-25" style={{ width: '25%' }}>Назва рахунку</th>
          <th className="text-center">Залишок коштів</th>
          <th className="text-center">Уточнений план</th>
          <th className="text-center">Надійшло</th>
          <th className="text-end table-column-width-30">% надходжень</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableRow
            key={item.id}
            item={item}
            isLast={data.length - 1 === index}
          />
        ))}
      </tbody>
      <tfoot className="bg-light">
        <tr className="text-700 fw-bold">
          <th>Total</th>
          <th className="text-center">
            {getTotal(data, 'value')}
          </th>
          <th className="text-center">
            {getTotal(data, 'plan')}
          </th>
          <th className="text-center">
            {getTotal(data, 'fact')}
          </th>
          <th className="pe-x1 text-end">-</th>
        </tr>
      </tfoot>
    </Table>
  );
}

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    plan: PropTypes.number.isRequired,
    fact: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
  }),
  isLast: PropTypes.bool.isRequired,
};

TableRow.defaultProps = {
  item: {},
};

AccountsTable.propTypes = {
  data: PropTypes.shape(TableRow.propTypes.item),
};

AccountsTable.defaultProps = {
  data: [],
};

export default AccountsTable;
