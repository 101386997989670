import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export const getPing = async () => {
  const res = await fetch('https://logica.minfin.gov.ua/ws/api/PING');
  if (res) {
    return res.status;
  }
  return res;
};

export const TimeOut = 12000;

function LogicaPing({ available, handleAvailable }) {
  useEffect(() => {
    const ping = () => getPing().then((res) => {
      if (res !== 200) { return handleAvailable(false); }
      if (res === 200) { return handleAvailable(true); }
      return null;
    }).catch(() => handleAvailable(false));
    ping();
    const isPing = setInterval(ping, TimeOut);
    return () => clearInterval(isPing);
  }, [handleAvailable]);
  return (
    <>
      {!available && (
        <Alert variant="danger">
          Нажаль, сервіс &quot;Logica&quot; не відповідає. Спробуйте пізніше.
        </Alert>
      )}
    </>
  );
}

LogicaPing.propTypes = {
  available: PropTypes.bool.isRequired,
  handleAvailable: PropTypes.func.isRequired,
};

export default LogicaPing;
