import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'react-bootstrap';
import { useEditor } from '../../newEditor';
import { modelName, modelType } from './def';
import md from '../../../constants/meta/dataprocessors/docCreation';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import Documents from './tablepart';

function DocCreationBoostraped({
  onLoad, dataDocs,
}) {
  const {
    data,
    loading,
    err,
    actions: { onSR, onChange, onErr },
  } = useEditor({
    modelType,
    modelName,
  });
  useEffect(
    () => {
      onSR('INIT', {
        [md.tables.data.name]: dataDocs,
      });
    },
    [dataDocs, onSR],
  );
  const tableData = useMemo(() => data[md.tables.data.name] || [], [data]);
  const dataFi = data[md.columns.FI.name];
  const csuFilter = useMemo(
    () => [{ fieldName: 'parent', value: dataFi }],
    [dataFi],
  );
  const onOk = useCallback(
    () => onLoad({
      documents: tableData
        .filter((d) => d[md.tables.data.columns.Check.name])
        .map((d) => d[md.tables.data.columns.Document.name]),
      csu: data[md.columns.CSU.name],
    }),
    [data, onLoad, tableData],
  );

  return (
    <EditorWrapper loading={loading} onErrorDismis={() => onErr(null)} err={err}>
      <Card.Body>
        <EditorControls.ItemPicker
          label={md.columns.CSU.label}
          modelType="cat"
          modelName="csu"
          value={data[md.columns.CSU.name]}
          onChange={(e, value) => onChange({
            [md.columns.CSU.name]: value,
          })}
          filter={csuFilter}
          noHierarchy
        />
      </Card.Body>
      <Card.Body className="pt-0">
        <Documents
          onChange={onChange}
          tableData={tableData}
          onSR={onSR}
          onOk={onOk}
          data={data}
        />
      </Card.Body>
    </EditorWrapper>
  );
}

DocCreationBoostraped.propTypes = {
  onLoad: PropTypes.func.isRequired,
  dataDocs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DocCreationBoostraped;
