import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  docFinRequest: { label: 'Документ', name: 'докЗаявка' },
  // FormType: { label: 'Тип Формы', name: 'ТипФормы' },
};

const tables = {};

/**
 * @const
 */
const definition = {
  name: 'infoPaymentFinRequest',
  backendName: 'ИнфоФинансированиеЗаявки',
  label: 'Інформація, щодо фінансування заявки на фінансування',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
