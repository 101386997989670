import PropTypes from 'prop-types';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  code: PropTypes.string,
  start_y: PropTypes.number,
  finish_y: PropTypes.number,
  middl_y_pl: PropTypes.number,
  middl_y_ex: PropTypes.number,
  id_tpkvk: PropTypes.string,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));

export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
