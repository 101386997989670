import React from 'react';

import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.dp.certificateOfAccountPrint.tables.tvAcc.columns;

const HeaderComponent = () => (
  <GridTemplate isHeader>
    <DivHeader areaName="selected">{coldef.selected.label}</DivHeader>
    <DivHeader areaName="codeS">{coldef.codeS.label}</DivHeader>
    <DivHeader areaName="nameS">{coldef.nameS.label}</DivHeader>
  </GridTemplate>
);

export default HeaderComponent;
