import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function PlanningMenuItem() {
  return (
    <UseMenuHeader label="Планування">
      <Boxes items={NavPanel.planing.boxes} />
    </UseMenuHeader>
  );
}

export default PlanningMenuItem;
