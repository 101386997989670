import React, { forwardRef, useEffect, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';
import FillingKBP7Catalog from './fillingKBP7Catalog';
import api from '../../../../api/req';

const CommandPanel = forwardRef((_, ref) => {
  const [showFillingButton, setShowFillingButton] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await api.get$('logica/filling_kbp7_catalog/');
      if (response.ok) {
        const res = await response.json();
        setShowFillingButton(res.res);
      }
    })();
  }, []);

  return (
    <CatListerCommandPanel ref={ref}>
      <ButtonGroup />
      { showFillingButton && (
        <FillingKBP7Catalog />
      )}
    </CatListerCommandPanel>
  );
});

export default CommandPanel;
