import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.sourcesSpecF;

function TPHeader({
  totals,
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sourcesType'}
              highlighted={highlights.includes('sourcesType')}
              onToggleHighlght={() => onToggleHighlght('sourcesType')}
            >
              {meta.columns.sourcesType.label}
            </TableHeaderCell>
          </Col>
          {/* <Col> */}
          {/* <TableHeaderCell */}
          {/*  className="text-center text-xl-left" */}
          {/*  active={activeCol === 'parentSourcesType'} */}
          {/*  highlighted={highlights.includes('parentSourcesType')} */}
          {/*  onToggleHighlght={() => onToggleHighlght('parentSourcesType')} */}
          {/* > */}
          {/*  {meta.columns.parentSourcesType.label} */}
          {/* </TableHeaderCell> */}
          {/* </Col> */}
          <Col>
            <TableHeaderCell
              title={totals.sumPlan.toLocaleString('uk', moneysStingOptions)}
              className="text-center text-xl-left"
              active={activeCol === 'sumPlan'}
              highlighted={highlights.includes('sumPlan')}
              onToggleHighlght={() => onToggleHighlght('sumPlan')}
            >
              {meta.columns.sumPlan.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.sumFact.toLocaleString('uk', moneysStingOptions)}
              className="text-center text-xl-left"
              active={activeCol === 'sumFact'}
              highlighted={highlights.includes('sumFact')}
              onToggleHighlght={() => onToggleHighlght('sumFact')}
            >
              {meta.columns.sumFact.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              title={totals.sumDeviation.toLocaleString('uk', moneysStingOptions)}
              className="text-center text-xl-left"
              active={activeCol === 'sumDeviation'}
              highlighted={highlights.includes('sumDeviation')}
              onToggleHighlght={() => onToggleHighlght('sumDeviation')}
            >
              {meta.columns.sumDeviation.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'explanation'}
              highlighted={highlights.includes('explanation')}
              onToggleHighlght={() => onToggleHighlght('explanation')}
            >
              {meta.columns.explanation.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumPlan: PropTypes.number,
    sumFact: PropTypes.number,
    sumDeviation: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
