import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import { CiatAppContext } from '../../../../providers';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields,
  } = useEditor({
    backendURL: meta.cat.executor.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  const parentFilter = useMemo(
    () => ({
      is_group: true,
      disposer: (data.disposer && data.disposer.id) || null,
    }),
    [data.disposer],
  );
  const { currentUser } = useContext(CiatAppContext);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          backendURL={meta.cat.executor.backendURL}
          id={id}
        />
      )}
    >
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid className="px-0">
              <Row>
                <Col md={6}>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
                <Col md={6}>
                  <EditorControls.ItemPicker
                    {...disposerProps}
                    disabled={!currentUser.is_superuser}
                  />
                </Col>
                <Col md={6}>
                  <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
                </Col>
                <Col md={6}>
                  <EditorControls.CheckboxInput {...isGroupProps} />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
