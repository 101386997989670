import React, { useCallback, useState } from 'react';
import {
  Button,
  Col, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import IndicatorsTP from './koefTable';

const tableMeta = md.tables.indicators;

function FillElementsModal({ data, actions }) {
  const [opened, setOpened] = useState(false);
  const year = data[md.columns.year.name];
  const onFill = useCallback(
    () => actions.onChange((olddata) => ({
      ...olddata,
      [tableMeta.name]: data[tableMeta.name].map((row) => ({
        ...row,
        [tableMeta.columns.k0.name]: data[md.columns.kp1.name],
        [tableMeta.columns.k1.name]: data[md.columns.kp2.name],
        [tableMeta.columns.k2.name]: data[md.columns.kp3.name],
      })),
    })),
    [actions, data],
  );
  const onCalc = useCallback(
    () => actions.onChange((olddata) => {
      const f1 = data[md.columns.fillKP1.name];
      const f2 = data[md.columns.fillKP2.name];
      const f3 = data[md.columns.fillKP3.name];
      return ({
        ...olddata,
        [tableMeta.name]: data[tableMeta.name].map((row) => ({
          ...row,
          [tableMeta.columns.zf0.name]: f1
            ? row[tableMeta.columns.zf_1.name] * row[tableMeta.columns.k0.name]
            : row[tableMeta.columns.zf0.name],
          [tableMeta.columns.sf0.name]: f1
            ? row[tableMeta.columns.sf_1.name] * row[tableMeta.columns.k0.name]
            : row[tableMeta.columns.sf0.name],
          [tableMeta.columns.sum0.name]: f1
            ? row[tableMeta.columns.sum_1.name] * row[tableMeta.columns.k0.name]
            : row[tableMeta.columns.sum0.name],
          [tableMeta.columns.zf1.name]: f2
            ? row[tableMeta.columns.zf0.name] * row[tableMeta.columns.k1.name]
            : row[tableMeta.columns.zf1.name],
          [tableMeta.columns.sf1.name]: f2
            ? row[tableMeta.columns.sf0.name] * row[tableMeta.columns.k1.name]
            : row[tableMeta.columns.sf1.name],
          [tableMeta.columns.sum1.name]: f2
            ? row[tableMeta.columns.sum0.name] * row[tableMeta.columns.k1.name]
            : row[tableMeta.columns.sum1.name],
          [tableMeta.columns.zf2.name]: f3
            ? row[tableMeta.columns.zf1.name] * row[tableMeta.columns.k2.name]
            : row[tableMeta.columns.zf2.name],
          [tableMeta.columns.sf2.name]: f3
            ? row[tableMeta.columns.sf1.name] * row[tableMeta.columns.k2.name]
            : row[tableMeta.columns.sf2.name],
          [tableMeta.columns.sum2.name]: f3
            ? row[tableMeta.columns.sum1.name] * row[tableMeta.columns.k2.name]
            : row[tableMeta.columns.sum2.name],

        })),
      });
    }),
    [actions, data],
  );

  return (
    <>
      <Button
        size="sm"
        variant="success"
        label=""
        onClick={() => setOpened(true)}
      >
        Заповнити коеф.
      </Button>

      <Modal show={opened} onHide={() => setOpened(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Заповнення згідно коефіціентів</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <EditorControls.NumberInput
                  value={data[md.columns.kp1.name]}
                  precision={4}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.kp1.name]: value,
                  })}
                  label={`Коеф на ${year}`}
                />
              </Col>
              <Col>
                <EditorControls.NumberInput
                  value={data[md.columns.kp2.name]}
                  precision={4}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.kp2.name]: value,
                  })}
                  label={`Коеф на ${year + 1}`}
                />
              </Col>
              <Col>
                <EditorControls.NumberInput
                  value={data[md.columns.kp3.name]}
                  precision={4}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.kp3.name]: value,
                  })}
                  label={`Коеф на ${year + 2} `}
                />
              </Col>
              {/* <Col> */}
              {/*  <EditorControls.NumberInput */}
              {/*    value={data[md.columns.kp1sf.name]} */}
              {/*    precision={4} */}
              {/*    onChange={(e, value) => actions.onChange({ */}
              {/*      [md.columns.kp1sf.name]: value, */}
              {/*    })} */}
              {/*    label={`Коеф на ${year} по спец. фонду`} */}
              {/*  /> */}
              {/* </Col> */}
              {/* <Col> */}
              {/*  <EditorControls.NumberInput */}
              {/*    value={data[md.columns.kp2sf.name]} */}
              {/*    precision={4} */}
              {/*    onChange={(e, value) => actions.onChange({ */}
              {/*      [md.columns.kp2sf.name]: value, */}
              {/*    })} */}
              {/*    label={`Коеф на ${year + 1} по спец. фонду`} */}
              {/*  /> */}
              {/* </Col> */}
              {/* <Col> */}
              {/*  <EditorControls.NumberInput */}
              {/*    value={data[md.columns.kp3sf.name]} */}
              {/*    precision={4} */}
              {/*    onChange={(e, value) => actions.onChange({ */}
              {/*      [md.columns.kp3sf.name]: value, */}
              {/*    })} */}
              {/*    label={`Коеф на ${year + 2} по спец. фонду`} */}
              {/*  /> */}
              {/* </Col> */}
            </Row>
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                onFill();
                // actions.onSR('FILL_INDICATORS_THREE_YEARS');
              }}
            >
              Заповнити всі рядки вказаними коефіціентами
            </Button>
            <IndicatorsTP data={data} actions={actions} />

          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-grow-1 gap-2">
            <EditorControls.CheckboxInput
              controlId={`fillKP1-${data.id}`}
              value={data[md.columns.fillKP1.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.fillKP1.name]: value,
              })}
              label={`Заповнити ${year}`}
            />
            <EditorControls.CheckboxInput
              controlId={`fillKP2-${data.id}`}
              value={data[md.columns.fillKP2.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.fillKP2.name]: value,
              })}
              label={`Заповнити ${year + 1}`}
            />
            <EditorControls.CheckboxInput
              controlId={`fillKP3-${data.id}`}
              value={data[md.columns.fillKP3.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.fillKP3.name]: value,
              })}
              label={`Заповнити ${year + 2}`}
            />
          </div>
          <Button
            variant="primary"
            onClick={() => {
              // actions.onSR('FILL_INDICATORS_THREE_YEARS_BY_ROW');
              onCalc();
              setOpened(false);
            }}
          >
            Обрахувати показники
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

FillElementsModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
};

export default FillElementsModal;
