import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        sm={12}
        md={6}
        className="px-1 text-center"
      >
        РАЗОМ
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'receipts'}
              highlighted={highlights.includes('receipts')}
            >
              {totals.receipts.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'excluded'}
              highlighted={highlights.includes('excluded')}
            >
              {totals.excluded.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'received_loans'}
              highlighted={highlights.includes('received_loans')}
            >
              {totals.received_loans.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'repaided_loans'}
              highlighted={highlights.includes('repaided_loans')}
            >
              {totals.repaided_loans.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={2}>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'total'}
              highlighted={highlights.includes('total')}
            >
              {totals.total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'nature_index_amount'}
              highlighted={highlights.includes('nature_index_amount')}
            >
              {totals.nature_index_amount.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    receipts: PropTypes.number,
    excluded: PropTypes.number,
    received_loans: PropTypes.number,
    repaided_loans: PropTypes.number,
    total: PropTypes.number,
    nature_index_amount: PropTypes.number,
  }),
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
  totals: {},
};

export default memo(TPFooter);
