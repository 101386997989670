import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditorHOC, { mapState, getRouterEditor } from '../basic';
import { csuElChTP as def } from '../../../constants/meta/ChTP/csuElChTP';
import { editor as editorActions } from '../../../actions';
import { catEditorMenu as BasicMenu } from '../../../components/editorMenu';
import GetTextField from '../../field/TextInput';
import { StyledLabel, FiveRow, TwoRow } from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';

const TextField50 = GetTextField(50);
const getTypeValue = (headerForm, param) => headerForm.getIn(['typeValue', param], false) !== false;

const setTypeValue = (headerForm, value, param) => {
  const typeValue = headerForm.get('typeValue', new Map());
  if (value) { return typeValue.set(param, param); }
  return typeValue.delete(param);
};

function CsuElChTP({ headerForm, dispatch }) {
  return (
    <>
      <TwoRow>
        <div>
          <StyledLabel>{def.columns.code.label}</StyledLabel>
          <TextField50
            value={headerForm.get('code', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'code'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.name.label}</StyledLabel>
          <TextField50
            value={headerForm.get('name', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'name'], value))}
          />
        </div>

      </TwoRow>
      <FiveRow>

        <CheckBoxField
          label="Розпорядники"
          value={getTypeValue(headerForm, 'Справочник.Распорядители')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.Распорядители')))}
        />
        <CheckBoxField
          label="Значення додаткової аналітики. Розпорядники"
          value={getTypeValue(headerForm, 'Справочник.ДопХЭлементыРасп')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ДопХЭлементыРасп')))}
        />
        <CheckBoxField
          label="Текст"
          value={getTypeValue(headerForm, 'string')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'string')))}
        />

        <CheckBoxField
          label="Число"
          value={getTypeValue(headerForm, 'number')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'number')))}
        />

        <CheckBoxField
          label="Дата"
          value={getTypeValue(headerForm, 'date')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'date')))}
        />
      </FiveRow>
    </>
  );
}

CsuElChTP.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapState),
  EditorHOC(
    {
      type: 'ChTP',
      name: 'csuElChTP',
    },
    {
      menu: BasicMenu,
    },
  ),
);

export const StdEditor = enhance(CsuElChTP);

export default getRouterEditor(def)(StdEditor);
