import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField } from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'programs';

const coldef = md.dp.budgetRequestPrint.tables.programs.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "strNumber KTKVK subProgram KPKVK";
  grid-template-columns: 5% 30% 40% 25%;
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="strNumber">{coldef.KTKVK.label}</DivHeader>
      <DivHeader areaName="KTKVK">{coldef.KTKVK.label}</DivHeader>
      <DivHeader areaName="subProgram">{coldef.subProgram.label}</DivHeader>
      <DivHeader areaName="KPKVK">{coldef.KPKVK.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="strNumber">
          <InputCodeStyled
            value={row.get('strNumber', '')}
          />
        </DivCell>
        <DivCell areaName="KTKVK">
          <InputCodeStyled
            fluid
            value={row.get('KTKVK', '')}
          />
        </DivCell>
        <DivCell areaName="subProgram">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="kbpClassifier"
            modelType="cat"
            value={row.get('subProgram', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'subProgram'], v));
            }}
          />
        </DivCell>
        <DivCell areaName="KPKVK">
          <InputCodeStyled
            fluid
            value={row.get('KPKVK', '')}
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TablePrograms = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TablePrograms);
