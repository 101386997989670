import { baseReportColumns, baseResultColumns } from './_base';

const columns = baseReportColumns;

const definition = {
  label: 'Чисельність дітей у закладах дошкільної освіти',
  name: 'bfnCommoninStitutionstructure',
  columns,
  frontend: 'rep/bfnCommoninStitutionstructure',
  backendName: 'bfnCommoninStitutionstructure',
  resultColumns: baseResultColumns,
  minfinOnly: true,
};

export default definition;
