import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/catalogs/typesRepairIndustry';
import TypesTP from './tabs/typesOfRepair';

function TablesRender({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="typesOfRepair">
      <Tab title={md.tables.typesOfRepair.label} eventKey="typesOfRepair">
        <Container fluid className="border border-top-0">
          <TypesTP data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
    </Tabs>
  );
}

TablesRender.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default TablesRender;
