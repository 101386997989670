import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  CSU: { label: 'Розпорядник (для заповнення табличної частини документу)', name: 'РаспорядительДляЗаполненияПоУмолчанию' },
  FI: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  newDoc: { label: 'НовДок', name: 'НовДок' },
};

/**
 * @const
 */
const tables = {
  data: {
    name: 'ТабДок',
    label: 'Документи',
    columns: {
      Document: { label: 'Документ', name: 'Регистратор' },
      docRepr: { label: 'Документ рядок', name: 'Представление' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'docCreation',
  backendName: 'СоздатьНовДок',
  label: 'Створити документ',
  columns,
  tables,
};

export default definition;
