import React from 'react';
import PropTypes from 'prop-types';
import { CPButton } from '../../../../../../components/bootStrap/buttons';

function CalculationButton({ onClick }) {
  return (
    <CPButton
      content="Розрахувати показники"
      onClick={onClick}
      variant="falcon-primary"
      size="sm"
    >
      Розрахувати показники
    </CPButton>
  );
}
CalculationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CalculationButton;
