import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  FormCheck, OverlayTrigger, Popover, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import HeaderCell, { StyledTh } from './headerCell';
import FilteredTableRow from './row';

const StyledTable = styled(Table)`
  & tr.selected{
    background-color: var(--falcon-primary);
  }
`;

const bsCodes = ['BLE0020103', 'BLE0020104'];

function FilteredTable({
  columns, onSetFilter, onClearFilter, setSelectedBs,
  onSetOrder, displayList, selectedIds, setSelectedIds,
}) {
  const [columnsWithAdd, setColumnsWithAdd] = useState(columns);
  useEffect(() => {
    if (displayList.length > 0
          && ('docInOurBase' in displayList[0])) {
      setColumnsWithAdd(columns.concat(
        [{
          code: 'docInOurBase',
          label: 'Документ з ЄІСУБ',
          canFilter: true,
        }],
      ));
    }
  }, [columns, displayList]);
  const onRowClick = useCallback(
    (e, rowId, rowCode) => {
      e.persist();
      if (bsCodes.includes(rowCode)) {
        setSelectedBs((prev) => {
          if (prev.includes(rowId)) {
            const index = prev.indexOf(rowId);
            prev.splice(index, 1);
            return prev;
          }
          return [...prev, rowId];
        });
      } else setSelectedBs([]);
      setSelectedIds(
        (prev) => {
          if (e.ctrlKey && !prev.includes(rowId)) return [...prev, rowId];
          if (e.ctrlKey && prev.includes(rowId)) return prev.filter((i) => i !== rowId);
          return [rowId];
        },
      );
    },
    [setSelectedBs, setSelectedIds],
  );
  const allSelected = useMemo(
    () => !displayList.filter((r) => !selectedIds.includes(r.id)).length,
    [displayList, selectedIds],
  );
  const onAllClick = useCallback(
    () => {
      if (allSelected) setSelectedIds([]);
      else setSelectedIds(displayList.map((r) => r.id));
    },
    [allSelected, displayList, setSelectedIds],
  );

  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTh>
            <OverlayTrigger
              placement="auto"
              overlay={(
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    <FontAwesomeIcon icon={faInfo} className="me-2" />
                    Корисно знати!
                  </Popover.Header>
                  <Popover.Body>
                    Щоб обрати декілька рядків - затисніть ctrl!
                  </Popover.Body>
                </Popover>
                )}
            >
              <FormCheck checked={allSelected} onChange={onAllClick} />
            </OverlayTrigger>
          </StyledTh>
          {columnsWithAdd.map((clmn) => (
            <HeaderCell
              key={clmn.code}
              label={clmn.label}
              name={clmn.code}
              canFilter={clmn.canFilter}
              canOrder={clmn.canOrder}
              canSearch={clmn.canSearch}
              data={displayList}
              onSetFilter={onSetFilter}
              onClearFilter={onClearFilter}
              onSetOrder={onSetOrder}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {displayList.map((row) => (
          <FilteredTableRow
            key={row.id}
            onClick={onRowClick}
            columns={columnsWithAdd}
            selected={selectedIds.includes(row.id)}
            row={row}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}

FilteredTable.propTypes = {
  displayList: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    canFilter: PropTypes.bool,
  })).isRequired,
  onSetFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setSelectedBs: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default memo(FilteredTable);
