import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';

const TypeAttachedFileSelector = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory());

export default TypeAttachedFileSelector;
