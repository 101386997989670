import {
  ButtonGroup, Card, Dropdown, OverlayTrigger, Tooltip, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/payment';
import mdDoc from '../../../../constants/meta/documents';
import DocEditor from './editor';
import { modelType, modelName } from '../def';
import DocTabs from './tables';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import APMenu from './menu';

import Question from '../../../../components/question';
import Modal from '../../../../components/modal';
import Report from '../../../tableEditor';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function Doc({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  function isReadOnly(data) {
    return !!data[md.columns.isApproved.name] || !!data[md.columns.isPaided.name];
  }

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    readOnlyGetter: isReadOnly,
  });

  function PrintCP() {
    return changed ? (
      <Question
        questionText="З початку треба зберегти документ"
        answers={[
          { label: 'OK', value: 'OK' },
        ]}
        triggerButton={(

          <Button variant="primary">
            <FontAwesomeIcon icon={faPrint} className="me-1" />
          </Button>
        )}
        onActionClick={() => null}
      />
    ) : (
      <Modal
        triggerButton={(
          <Button
            variant="primary"
            onClick={() => actions.onSR('PRINT')}
          >
            <FontAwesomeIcon icon={faPrint} className="me-1" />
          </Button>
        )}
        header="Друк"
        closeIcon
      >
        {/* <Suspense fallback={<div>Завантаження...</div>}> */}
        {/*  <TableEditor mountNode={resultMountNode} /> */}
        {/* </Suspense> */}

        <Report mountNode={['editor', 'headerForm', 'result']} />
      </Modal>
    );
  }

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
        data={data}
      >
        <ButtonGroup>
          <PrintCP />
        </ButtonGroup>
        <ButtonGroup>
          <Dropdown>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="create_related">
                  Створити на підставі
                </Tooltip>
              )}
            >
              <Dropdown.Toggle>
                Створити на підставі
              </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/${mdDoc.docRegister.frontend}/create?reason=${id}`}>
                {mdDoc.docRegister.label}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
      </APMenu>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <DocEditor data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <DocTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

Doc.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

Doc.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { Doc as StdEditor };

export default getRouterEditor(md)(Doc);
