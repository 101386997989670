export const dateToUpload = (dateToFormat) => {
  const dateArr = dateToFormat.split('.');
  const dateDay = dateArr[0];
  const dateMonth = dateArr[1];
  const dateYear = dateArr[2];
  const formatedDate = `${dateYear}-${dateMonth}-${dateDay}`;
  return formatedDate;
};

export function getDateStr(v) {
  if (v) {
    const cd = new Date(v);
    return cd.toLocaleString('uk', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
  return null;
}

export const FORMS = {
  BLE0020103: 'Помісячний розпис місцевого бюджету',
  BLE0020104: 'Зміни до розпису місцевого бюджету',
};
