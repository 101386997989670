import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font } from '../../../constants/globalStyle';
import Calculator from './calculator';
import { ButtonModalStyled } from '../selectorField/item/styles';
import { CalulatorIcon } from '../../../assets/icons';
import useNumberInput from './hooks/useNumInput';

const StyledCalcButton = styled(ButtonModalStyled)`
  border: none;
  background: none;
  display: none;
`;

const StyledIcon = styled.img.attrs({
  src: CalulatorIcon,
  alt: 'Калькулятор',
})`
  height: 14px;
`;

const Div = styled.div`
  display: flex;
  // flex-direction: column;
  width: 100%;
  font-size: 1em;
  position: relative;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  padding: 0.08em;
  margin-right: 0.2em;
  grid-area: ${(props) => props.areaName};
  margin-right: 0.2em;
  &:hover{
    button{
    display: block;
    }
  }
`;

const ErrDiv = styled.div`
  color: red;
  text-align: center;
  border: 1px solid red;
  border-radius: 4px;
  padding: 4px 2px;
  background: #fbf1f1;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  color: #5f5f5f;
  align-self: flex-end;
  outline: none;
  padding-bottom: 0;
  line-height: 0.5;
  cursor: pointer;
  &:hover{
    color: black;
  };
`;

const ErrText = styled.span`
  overflow-wrap: break-word
`;

const Input = styled(React.forwardRef((
  {
    radius, background, border, left, ...rest
  },
  ref,
) => <input {...rest} ref={ref} />))`
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    flex: 1 1 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: ${(props) => (props.left ? 'left' : 'right')};
    line-height: 1.21428571em;
    padding: 2.5px 2px;
    border-radius: ${(props) => props.radius && '.28571429rem'};
    background-color: ${(props) => (props.background ? 'white' : 'transparent')};
    border: ${(props) => (props.border ? '1px solid #afbbcc' : 'none')};
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    box-shadow: none;
    ${(props) => (props.disabled ? 'color: black' : '')};
    ${font};
`;
Input.propTypes = {
  radius: PropTypes.bool,
  background: PropTypes.bool,
  border: PropTypes.bool,
  left: PropTypes.bool,
};

Input.defaultProps = {
  radius: false,
  background: false,
  left: false,
  border: false,
};

function GetNumberInput({ ...args }) {
  const {
    border,
    radius,
    left,
    background,
    disableCalculator,
    disabled,
    isFocusCell,
    FractionSize = 2,
    value: valueInit,
    onChangeSumField,
    minValue,
    maxValue,
    onChange,
    onlyPozitive,
  } = args;

  const {
    value,
    setValue,
    errText,
    calcOpened,
    setCalcOpened,
    inputRef,
    keyUp,
    keyDown,
    propChanger,
  } = useNumberInput({
    valueInit,
    isFocusCell,
    FractionSize,
    onChangeSumField,
    minValue,
    maxValue,
    onChange,
    onlyPozitive,
  });

  return (
    <>
      <Div>
        {errText && (
          <ErrDiv>
            <CloseButton>
              x
            </CloseButton>
            <ErrText>{errText}</ErrText>
          </ErrDiv>
        )}
        <Input
          ref={inputRef}
          type="text"
          value={isFocusCell ? value : value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}

          onBlur={propChanger}
          disabled={disabled}
          border={border}
          radius={radius}
          left={left}
          background={background}
          onKeyUp={keyUp}
          onKeyDown={keyDown}
          onChange={() => null}
        />
        {!disableCalculator && !disabled && (
          <StyledCalcButton onClick={() => setCalcOpened(true)}>
            <StyledIcon />
          </StyledCalcButton>
        )}
      </Div>
      <Calculator
        opened={calcOpened}
        value={Number(value)}
        onClose={() => setCalcOpened(false)}
        onOk={(e, v) => {
          setCalcOpened(false);
          setValue(v.toFixed(FractionSize));
        }}
      />
    </>
  );
}

GetNumberInput.prototype = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  autoselect: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool,
  border: PropTypes.bool,
  radius: PropTypes.bool,
  left: PropTypes.bool,
  background: PropTypes.bool,
  disableCalculator: PropTypes.bool,
  AllSize: PropTypes.number,
};

GetNumberInput.defaultProps = {
  value: 0,
  onChange: () => null,
  autoselect: true,
  minValue: null,
  maxValue: null,
  disabled: false,
  id: null,
  border: false,
  radius: false,
  left: false,
  background: false,
  disableCalculator: false,
  AllSize: 17,
};

export default memo(GetNumberInput);
