import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Map, OrderedMap, List } from 'immutable';
import { useNavigate } from 'react-router-dom';
import {
  NewIcon,
  AddFolderIcon,
  CopyIcon,
  EditIcon,
  RemoveIcon,
  PostDocIcon,
  UndoPostDoc,
} from '../../assets/icons/index';
import { CommandPanelButton } from '../../components/button';
import { DateFieldWithModal } from '../../components/fields';
import meta from '../../constants/meta';
import actions from '../../actions/lister';
import { hierarchyTypes, saveModes } from '../../constants/meta/common';
import Question from '../../components/question';
import MoveToFolderButton from './moveToFolder';

function ListerCP({
  period, modelType, modelName, dispatch, children = null, filter, activeId,
}) {
  const a = actions.lister[modelType][modelName];
  const navigate = useNavigate();
  return (
    <>
      <CommandPanelButton
        text="Створити"
        // onClick={() => dispatch(a.newItem('selector', modelType, modelName))}
        onClick={() => navigate(`/${modelType}/${modelName}/create/`)}
      >
        <NewIcon />
      </CommandPanelButton>
      {meta[modelType][modelName].hierarchyType === hierarchyTypes.foldersNItems && (

        <CommandPanelButton
          text="Створити групу"
          onClick={() => navigate(`/${modelType}/${modelName}/createGroup/`)}
          // onClick={() => dispatch(a.newGroup('selector', modelType, modelName))}
        >
          <AddFolderIcon />
        </CommandPanelButton>

      // =================== Надо переделать вот так (вместо CommandPanelButton и history использовать Link) ===================
      // <Link
      //   to={`/${modelType}/${modelName}/createGroup/`}
      // >
      //   <AddFolderIcon />
      // </Link>
      //= ===============================================================

      )}
      <CommandPanelButton
        text="Копіювати"
        onClick={() => dispatch(a.copyItem(navigate))}
        disabled={!activeId}
      >
        <CopyIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Змінити"
        onClick={() => dispatch(a.editItem(navigate))}
        disabled={!activeId}
      >
        <EditIcon />
      </CommandPanelButton>

      <Question
        triggerButton={(
          <CommandPanelButton
            text="Видалити"
            disabled={!activeId}
          >
            <RemoveIcon />
          </CommandPanelButton>
        )}
        header="Видалення"
        questionText="Ви дійсно бажаєте видалити цей елемент або елементи?"
        answers={[
          { value: 'YES', label: 'Так' },
          { value: 'NO', label: 'Ні' },
        ]}
        onActionClick={(e, v) => {
          if (v === 'YES') dispatch(a.remove());
        }}
      />
      {modelType === 'doc' && (
        <div style={{ display: 'flex' }}>
          <CommandPanelButton
            text="Провести"
            onClick={() => dispatch(a.postItem(saveModes.Posting))}
            disabled={!activeId}
          >
            <img src={PostDocIcon} alt="PostDocIcon" />
          </CommandPanelButton>
          <CommandPanelButton
            text="Відмінити проведення"
            onClick={() => dispatch(a.postItem(saveModes.UndoPosting))}
            disabled={!activeId}
          >
            <img src={UndoPostDoc} alt="UndoPostDoc" />
          </CommandPanelButton>
          <DateFieldWithModal
            value={period}
            onChange={(e, v) => {
              dispatch(a.setPeriod(v));
            }}
          />
        </div>
      )}
      {modelType === 'cat' && (
        <div style={{ display: 'flex' }}>
          <MoveToFolderButton
            modelType={modelType}
            modelName={modelName}
            onMove={(e, v) => dispatch(a.moveItems(v))}
            filter={filter}
          />
        </div>
      )}
      {children}
    </>
  );
}

ListerCP.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
  modelName: PropTypes.oneOf([
    ...new Set(Object.keys(meta).reduce(
      (r, typeName) => [...r, ...Object.keys(meta[typeName])],
      [],
    )),
  ]).isRequired,
  period: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.node,
  filter: PropTypes.instanceOf(List).isRequired,
  activeId: PropTypes.bool,
};

ListerCP.defaultProps = {
  children: null,
  activeId: false,
};

export const mapState = (store, ownProps) => {
  const STORE_PATH = `${ownProps.modelType}/${ownProps.modelName}/lister`;
  return {
    period: store.getIn([STORE_PATH, 'period'], new Map()),
    items: store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap()),
    filter: store.getIn([STORE_PATH, 'filter'], new List()),
    activeId: (store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap({ i: new OrderedMap({ ACTIVE: false }) }))).filter((i) => i.get('ACTIVE', false)).keySeq().first(),
  };
};

const enhance = compose(connect(mapState));

export default enhance(ListerCP);
