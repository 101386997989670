import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { csuPartners as md } from '../../../../constants/meta/catalogs/csuPartners';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { debounce } from '../../../../api/utils';

function CSUPartners({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const { onSR, onChange } = actions;
  const edrpouSearch = useCallback(
    async () => {
      const r = await onSR('ПриИзменении_ЕДРПОУ', {}, false);
      onChange({ [md.columns.Partner.name]: r[md.columns.Partner.name] });
      console.log(r);
    },
    [onChange, onSR],
  );
  const debouncedEdrpouSearch = useMemo(
    () => debounce(edrpouSearch),
    [edrpouSearch],
  );
  useEffect(
    () => console.log(debouncedEdrpouSearch),
    [debouncedEdrpouSearch],
  );

  const onEDRPOUChange = useCallback(
    async (e, v) => {
      await onChange({ [md.columns.EDRPOU.name]: v });
      debouncedEdrpouSearch();
    },
    [debouncedEdrpouSearch, onChange],
  );
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Partner.label}
            value={data[md.columns.Partner.name]}
            onChange={async (e, value) => {
              await onChange({
                [md.columns.Partner.name]: value,
              });
              actions.onSR('ПриИзменении_Контрагент');
            }}
            modelType="cat"
            modelName="partners"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.EDRPOU.label}
            value={data[md.columns.EDRPOU.name]}
            onChange={onEDRPOUChange}
            readOnly={readOnly}
            maxLength={10}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.INN.label}
            value={data[md.columns.INN.name]}
            onChange={async (e, value) => {
              await onChange({
                [md.columns.INN.name]: value,
              });
              actions.onSR('ПриИзменении_ИНН');
            }}
            readOnly={readOnly}
            maxLength={20}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.codeMer.label}
            value={data[md.columns.codeMer.name]}
            onChange={(e, value) => onChange({
              [md.columns.codeMer.name]: value,
            })}
            readOnly={readOnly}
            maxLength={20}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Head.label}
            value={data[md.columns.Head.name]}
            onChange={(e, value) => onChange({
              [md.columns.Head.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Telephone.label}
            value={data[md.columns.Telephone.name]}
            onChange={(e, value) => onChange({
              [md.columns.Telephone.name]: value,
            })}
            readOnly={readOnly}
            maxLength={40}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Address.label}
            value={data[md.columns.Address.name]}
            onChange={(e, value) => onChange({
              [md.columns.Address.name]: value,
            })}
            readOnly={readOnly}
            maxLength={40}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.bank.label}
            value={data[md.columns.bank.name]}
            onChange={(e, value) => onChange({
              [md.columns.bank.name]: value,
            })}
            modelType="cat"
            modelName="banks"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

CSUPartners.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CSUPartners;
