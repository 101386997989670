import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const disposer = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountProps = tableHooks.useNumberInputProps('bfn_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const salary = tableHooks.useNumberInputProps('salary', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const medicament = tableHooks.useNumberInputProps('medicament', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const currentExpenses = tableHooks.useNumberInputProps('current_expenses', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilities = tableHooks.useNumberInputProps('utilities', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col
      className="col-lg-12 col-md-12 d-flex flex-column justify-content-end"
    >

      <Row noGutters>

        <Col
          className={` col-lg-3 col-md-4 ${highlights.includes('disposer') ? 'bg-warning' : ''}`}
          column="disposer"
          highlighted={highlights.includes('disposer')}
        >
          <TableControls.ItemPicker
            {...disposer}
            readOnly
          />
        </Col>
        <Col
          className="col-lg-3 col-md-4"
          column="bfn_amount"
          highlighted={highlights.includes('bfn_amount')}
        >
          <TableControls.NumberInput
            {...amountProps}
            readOnly
          />
        </Col>
        <Col
          className="col-lg-2 col-md-3"
          column="salary"
          highlighted={highlights.includes('salary')}
        >
          <TableControls.NumberInput
            {...salary}
            readOnly
          />
        </Col>
        <Col
          className="col-lg-1 col-md-3"
          column="medicament"
          highlighted={highlights.includes('medicament')}
        >
          <TableControls.NumberInput
            {...medicament}
            readOnly
          />
        </Col>
        <Col
          className="col-lg-1 col-md-3"
          column="current_expenses"
          highlighted={highlights.includes('current_expenses')}
        >
          <TableControls.NumberInput
            {...currentExpenses}
            readOnly
          />
        </Col>
        <Col
          className="col-lg-2 col-md-3"
          column="utilities"
          highlighted={highlights.includes('utilities')}
        >
          <TableControls.NumberInput
            {...utilities}
            readOnly
          />
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
  disposerInHeader: PropTypes.bool,
  budget: foreignPropType,
  showKDBKFB: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  authorityId: PropTypes.number.isRequired,
};

TRow.defaultProps = {
  errors: {},
  onlyYear: false,
  vt4br: false,
  disposerInHeader: false,
  budget: null,
  showKDBKFB: false,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
