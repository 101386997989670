import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelectorInputProps } from '../hooks/selectorHook';
import api from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @param {{id: number, repr: string}} owner - owner
 * @param {{id: number, repr: string}} classifier - classifier
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: string,
 *  required: boolean,
 *  values: Array,
 *  errors: Array}}
 */
export const useLevelUpProps = ({
  name, data, fields, errors, onChange, isReadOnly = false, readOnlyFelds = [],
  owner = null, classifier = null,
}) => {
  // eslint-disable-next-line max-len
  const baseProps = useSelectorInputProps(name, data, fields, errors, onChange, isReadOnly, readOnlyFelds);
  const [values, setValues] = useState(() => {
    if (data && data[name]) {
      return [{
        value: data[name].id,
        display_name: data[name].repr,
      }];
    }
    return [];
  });

  useEffect(() => {
    if (data && data[name] && data[name].id && !values
      .filter((v) => v.value === data[name].id).length) {
      setValues([...values, {
        value: data[name].id,
        display_name: data[name].repr,
        disabled: true,
      }]);
    }
  }, [data, name, values]);

  const value = (data && data[name] && data && data[name].id) || null;
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const { auth } = useContext(CiatAppContext);

  const backend = useMemo(
    () => (fields && fields[name] ? fields[name].resource : null),
    [fields, name],
  );
  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (owner || classifier || true) setValues([]);
  }, [owner, classifier]);
  const onDropDown = useCallback(
    () => {
      const loader = async () => {
        const params = {
          classifier: classifier ? classifier.id : null,
          owner: owner ? owner.id : null,
        };
        const r = await api.get(`${backend}levelups/`, auth, params);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(false);
      setErr(null);
      loader()
        .then((d) => setValues(d.map((dv) => ({
          value: dv.id,
          display_name: dv.repr,
          disabled: !dv.is_active,
        }))))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, backend, classifier, owner],
  );
  const onSelect = useCallback(
    (e, v) => {
      const localValue = values
        .filter((vv) => vv.value === v)
        .reduce((R, vv) => ({ id: vv.value, repr: vv.display_name }), { id: null, repr: '?' });
      onChange(() => ({ [name]: localValue }));
    },
    [name, onChange, values],
  );
  const itemErrors = useMemo(
    () => {
      const cerr = [
        ...(baseProps.errors || []),
        ...(err ? [err] : []),
        ...(!classifier ? ['Для обрання зв\'язку з елементом розпорядника вищого рівня потрібно вказати зв\'язок з класифікатором'] : []),
      ];
      if (cerr.length) return cerr;
      return null;
    },
    [baseProps.errors, classifier, err],
  );
  return useMemo(
    () => ({
      ...baseProps,
      value,
      values,
      onDropDown,
      onChange: onSelect,
      isLoading: loading,
      errors: itemErrors,
      disabled: !classifier,
    }),
    [baseProps, classifier, itemErrors, loading, onDropDown, onSelect, value, values],
  );
};
export default useLevelUpProps;
