import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonStyled } from './selectorField/item/styles';

const Input = styled.input`
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  line-height: 1.21428571em;
  padding: 2.5px 2px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  resize: vertical;
  text-align: right;
  ::-webkit-inner-spin-button { 
  opacity: 1;
}
`;
const ContainerDiv = styled.div`
  position: relative;
  display: inline-block;
  
  input {
  font-size: 1em;
  -moz-appearance: textfield;
}
  
  input::-webkit-inner-spin-button { 
  display: none;
}
  
  button {
  position: absolute;
}
@supports (clip-path: polygon(50% 30%, 10% 90%, 90% 90%)) {
  
  input {
    padding-right: 1.4em;
  }
  
  button {
    right: 2px;
    width: 1em;
    height: 50%;
    background: rgb(104, 154, 212);
    cursor: pointer;
  }
  
  button:hover {
    background: #AAC6E6;
  }
  
  button:nth-of-type(1) {
    top: 0;
    clip-path: polygon(50% 30%, 10% 90%, 90% 90%);
  }
  
  button:nth-of-type(2) {
    bottom: 0;
    clip-path: polygon(50% 70%, 10% 10%, 90% 10%);
  }
}
`;

function YearField({
  value, onChange, type,
}) {
  return (
    <ContainerDiv>
      <Input
        type={type}
        value={value.toString().length <= 4 ? value : +value.toString().substr(0, 4)}
        title={value}
        onChange={(e) => onChange(e, e.target.value)}
      />
      <ButtonStyled onClick={(e) => onChange(e, +value + 1)} />
      <ButtonStyled onClick={(e) => onChange(e, +value - 1)} />
    </ContainerDiv>
  );
}

YearField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

YearField.defaultProps = {
  type: 'number',
  onChange: () => null,
};

export default YearField;
