import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import PrintGOV from '../../prepareForecastAdd1/lister/listerPrintModal';

function PrintSubMenu({ id }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <Dropdown>
      <Dropdown.Toggle variant="info" size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Граничний обсяг видатків
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <PrintGOV
          selectedRows={selectedRows}
          docType="prepareForecastAdd3"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

PrintSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PrintSubMenu;
