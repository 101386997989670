import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL3;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG1S'}
              highlighted={highlights.includes('T3RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG1S')}
            >
              {meta.columns.T3RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG3'}
              highlighted={highlights.includes('T3RXXXXG3')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG3')}
            >
              {meta.columns.T3RXXXXG3.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG4'}
              highlighted={highlights.includes('T3RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG4')}
            >
              {meta.columns.T3RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG5'}
              highlighted={highlights.includes('T3RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG5')}
            >
              {meta.columns.T3RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG6'}
              highlighted={highlights.includes('T3RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG6')}
            >
              {meta.columns.T3RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG7'}
              highlighted={highlights.includes('T3RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG7')}
            >
              {meta.columns.T3RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG8'}
              highlighted={highlights.includes('T3RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG8')}
            >
              {meta.columns.T3RXXXXG8.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG9'}
              highlighted={highlights.includes('T3RXXXXG9')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG9')}
            >
              {meta.columns.T3RXXXXG9.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG10'}
              highlighted={highlights.includes('T3RXXXXG10')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG10')}
            >
              {meta.columns.T3RXXXXG10.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T3RXXXXG11'}
              highlighted={highlights.includes('T3RXXXXG11')}
              onToggleHighlght={() => onToggleHighlght('T3RXXXXG11')}
            >
              {meta.columns.T3RXXXXG11.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
