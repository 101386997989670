// eslint-disable-next-line import/no-cycle
import DisposersLister from './lister';
import DisposersSelector from './selector';

const budget = {
  lister: DisposersLister,
  selector: DisposersSelector,
};

export default budget;
