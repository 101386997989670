const frontendURL = '/rep/allocation_extract/';
const backendURL = '/api/authorityplans/printform/allocation_extract/';
const name = 'Витяг (регламентований)';
const sections = '';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
