import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import ExpandIcon from '../../assets/icons/expand';

function ExpandButton({ text, ...args }) {
  return (
    <Button {...args} title={text}>
      <ExpandIcon />
    </Button>
  );
}

ExpandButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ExpandButton.defaultProps = {
  text: 'Розгорнути',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default ExpandButton;
