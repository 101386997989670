import React from 'react';
import Editor from './defaultEditor';

function ExchangeSocialPayment() {
  return (
    <Editor
      title="Завантажити виписку соціальних виплат"
      backendUrl="/api/references/socialpayment/importdbf/"
    />
  );
}

export default ExchangeSocialPayment;
