/* eslint-disable import/no-named-as-default */
import { List, Map, fromJS } from 'immutable';
import definition from '../constants/meta';
import dpEditorActions from '../constants/actions/dpEditor';
import editorActions from '../constants/actions/editor';
import api from '../api/req';
import {
  fromSrvMapper,
  resultMapper,
  toSrvMapper,
  dcSettingsMapper,
  SELECTED_KEY,
  TOGGLED_KEY, generateVisibleItems, indexTable,
} from '../common/editorMappers';
import { newFile } from './notifier';
import storePathParam from '../common/storePathParam';

// eslint-disable-next-line no-confusing-arrow
const getMaxId = (t) => t.reduce((R, r, k) => Number(k.slice(1)) > R ? Number(k.slice(1)) : R, 0);

const filterActiveItem = (v) => v.get('IS_ACTIVE', false);

/* SERVER REQUEST ACTIONS */

const getNode = (store) => {
  const { name } = storePathParam(store);

  const { type } = storePathParam(store);
  const { id } = storePathParam(store);

  const nameDp = store.getIn([`${type}/${name}/editor`, id, 'dpEditor'], name);

  return store.get(`dp/${nameDp}/dpEditor`, new Map());
};

const serverReqDone = (promiseResponse) => async (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */

  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const type = node.get('modelType', '');

  const modelDef = definition[type][model];

  const response = await promiseResponse;

  const item2 = fromSrvMapper(modelDef, response);

  const DCSettings = Object.keys(modelDef.columns)
    .filter((key) => modelDef.columns[key].type === 'DCSettings')
    .reduce(
      (R, key) => R.set(key, dcSettingsMapper(modelDef.columns[key].name, response)),
      new Map(),
    );

  const item = {
    headerForm: item2.headerForm
      .merge(new Map(resultMapper(modelDef, response)))
      .merge(DCSettings),
    tables: item2.tables,
  };

  dispatch({
    type: dpEditorActions.SR_DONE,
    payload: {
      item,
      model,
    },
  });
};

const serverRequestError = (e) => (dispatch, getState) => {
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  dispatch({
    type: dpEditorActions.SR_ERR,
    payload: {
      hasError: true,
      errorMsg: e.message || e,
      model,
    },
  });
};

export const processServerReq = (method, extra = null) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */

  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const modelDef = definition[modelType][model];

  dispatch({
    type: dpEditorActions.SR_START,
    payload: {
      model,
    },
  });

  const DCSettingsNames = Object.keys(modelDef.columns).filter((key) => modelDef.columns[key].type === 'DCSettings');
  const DCS = DCSettingsNames.reduce((data, dcKey) => ({
    ...data,
    [modelDef.columns[dcKey].name]: ['Group', 'Filter', 'Order', 'Selection'].reduce((parts, k) => ({
      ...parts,
      [k]: node.getIn(['headerForm', dcKey, k], new Map()).toJS(),
    }), {}),
  }), {});

  const itemData = toSrvMapper(
    modelDef,
    DCSettingsNames.reduce((R, dcKey) => R.deleteIn(['headerForm', dcKey]), node),
  );

  const item = {
    ...itemData,
    ...DCS,
  };

  api
    .post$(`${modelType}/${modelDef.backendName}/SR`, { method, item, ...extra })
    .then((d) => {
      if (d.ok) {
        const dJSON = d.json();
        dispatch(serverReqDone(dJSON));
        if (method === 'reportToFile') {
          dJSON.then((ff) => {
            const f = ff.fileFromReport;
            dispatch(newFile(f.filename, f.content, f.type, f.description));
          });
        }
      } else if (d.status === 422) {
        d.json().then((data) => {
          // eslint-disable-next-line no-underscore-dangle
          if (data._Error) {
            // eslint-disable-next-line no-underscore-dangle
            dispatch(serverRequestError(data._Error));
          } else {
            dispatch(serverRequestError('Виникла невідома помилка'));
          }
        });
      } else {
        throw new Error(`${d.status} ${d.statusText}`);
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};

/* INIT ACTION */
/**
 * @param _type {string}
 * @param model {string}
 * @param mapInitialOptions {object}
 * @param mapStore {function}
 * @return {function(*)}
 */
const init = (_type, model, mapInitialOptions = null, mapStore = null) => (dispatch, getState) => {
  const state = getState();
  const modelType = storePathParam(state).type;
  const modelName = storePathParam(state).name;
  const { id } = storePathParam(state);

  dispatch({
    type: editorActions.SET_DP_EDITOR,
    payload: {
      type: modelType,
      model: modelName,
      id,
      name: model,
    },
  });

  dispatch({
    type: dpEditorActions.INIT,
    payload: {
      type: `${_type}`,
      model,
    },
  });

  if (mapInitialOptions) {
    const preparedState = mapStore ? mapStore(state) : state;
    const initOptions = Object.keys(mapInitialOptions).reduce((r, key) => ({
      ...r,
      [mapInitialOptions[key]]: preparedState.get(key),
    }), {});
    dispatch(processServerReq('INIT', initOptions));
  } else {
    dispatch(processServerReq('INIT'));
  }
};

// const changeFieldHandler = (dispatch, getState) => {}
export const changeField = (path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');

  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value,
      model,
    },
  });
};

export const activateTableRow = (tableName, rowId, isMutiple = false) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */

  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());
  if (isMutiple) {
    dispatch({
      type: dpEditorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [`tables/${tableName}`, rowId, 'IS_ACTIVE'],
        value: !table.getIn([rowId, 'IS_ACTIVE'], false),
        model,
      },
    });
  } else {
    dispatch({
      type: dpEditorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [`tables/${tableName}`],
        value: table.reduce((V, row, key) => V.setIn([key, 'IS_ACTIVE'], key === rowId), table),
        model,
      },
    });
  }
};

export const fillTable = (tableName, path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const node = getNode(store);
  const model = node.get('modelName', '');

  const table = node.get(`tables/${tableName}`, new List());

  const newTable = table.map((row) => row.setIn(path, value));

  dispatch({
    type: dpEditorActions.TABLE_FILL,
    payload: {
      name: tableName,
      items: newTable,
      model,
    },
  });
};

export const addTableRow = (tableName) => (dispatch, getState) => {
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = storePathParam(store).type;
  dispatch({
    type: dpEditorActions.TABLE_ROW_ADD,
    payload: {
      tableName,
      type: modelType,
      model,
    },
  });
};

/**
 * @function
 * @desc Removing all active rows from table
 * @param {string} tableName
 * @return {Function}
 */
export const removeTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */

  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: dpEditorActions.TABLE_ROW_REMOVE,
    payload: {
      name: tableName,
      items: activeSeq,
      model,
    },
  });
};

export const copyTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');

  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: dpEditorActions.TABLE_ROW_COPY,
    payload: {
      name: tableName,
      items: activeSeq,
      model,
    },
  });
};

export const swapUpTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table
    .toOrderedMap()
    .filter(filterActiveItem)
    .reverse();

  dispatch({
    type: dpEditorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: true,
      items: activeSeq.reverse(),
      model,
    },
  });
};

export const swapDownTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const table = store.getIn([`${modelType}/${model}/dpEditor`, `tables/${tableName}`], new List());

  const activeSeq = table.toOrderedMap().filter(filterActiveItem);

  dispatch({
    type: dpEditorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: false,
      items: activeSeq,
      model,
    },
  });
};

/**
 *
 * @param paramGetter {function}
 * @return {function(*=)}
 */
export const getFile = (paramGetter) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const node = getNode(store);
  const model = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const modelDef = definition[modelType][model];

  const itemData = toSrvMapper(modelDef, node);
  api
    .post$(`${modelType}/${modelDef.backendName}/GetFile`, { item: itemData, ...paramGetter(store) })
    .then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          dispatch(newFile(d.filename, d.content, d.type, d.description));
        });
      } else {
        dispatch(serverRequestError(r.status));
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};
export const loadAttachedFiles = () => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */
  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();

    const node = getNode(store);
    const model = node.get('modelName', '');
    const modelType = node.get('modelType', '');

    dispatch({
      type: dpEditorActions.PROCESS_ATTACHED_FILES,
      payload:
        {
          isLoadingAttachedFiles: true,
          AttachedFiles: [],
          model,
        },
    });

    api.post$(`${modelType}/${definition[modelType][model].backendName}/attachedFiles`).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          const AttachedFiles = d.reduce((R, f) => R.push(new Map({
            content: f.content,
            type: f.type,
            fileName: f.filename,
            description: f.description,
          })), new List());

          dispatch({
            type: dpEditorActions.PROCESS_ATTACHED_FILES,
            payload:
              {
                isLoadingAttachedFiles: false,
                AttachedFiles,
                model,
              },
          });
        });
      } else {
        console.error(r.statusText);
      }
    });
  };
  return handler;
};

export const deleteAttachedFile = (fileName) => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */

  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();

    const node = getNode(store);
    const model = node.get('modelName', '');
    const modelType = node.get('modelType', '');

    const id = node.get('itemId');

    dispatch({
      type: dpEditorActions.DELETE_ATTACHED_FILES,
      payload:
        {
          isLoadingAttachedFiles: true,
          model,
        },
    });

    const modelDef = definition[modelType][model];

    const dataFile = {
      id,
      modelType,
      modelName: modelDef.backendName,
      fileName,
    };

    api.post$(`${modelType}/${modelDef.backendName}/${id}/deleteFiles/`, dataFile).then(async (r) => {
      if (r.ok) {
        dispatch(loadAttachedFiles());

        dispatch({
          type: dpEditorActions.DELETE_ATTACHED_FILES,
          payload:
            {
              isLoadingAttachedFiles: false,
              model,
            },
        });
      }
    });
  };
  return handler;
};

export const upLoadDbfFiles = (files) => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */

  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */

    const node = getNode(getState());
    const model = node.get('modelName', '');

    dispatch({
      type: dpEditorActions.PROCESS_ADD_FILES,
      payload:
        {
          isLoadingAttachedFiles: true,
          model,
        },
    });

    const ParseFile = (cFiles) => {
      // Получаем последнпй необработанный Файл в массиве
      const currentFile = cFiles.filter((f) => !f.done && f.file).reduce((R, f) => f, null);
      if (currentFile) {
        // Есть необработанные файлы
        const reader = new FileReader();
        reader.onloadend = () => {
          // debugger;
          currentFile.dbfFiles = reader.result;
          currentFile.fileName = currentFile.file.name;
          currentFile.done = true;

          // eslint-disable-next-line no-confusing-arrow
          const newFiles = cFiles.map((f) => f.task === currentFile.task ? currentFile : f);
          ParseFile(newFiles);
        };
        reader.readAsDataURL(currentFile.file);
      } else {
        dispatch({
          type: dpEditorActions.PROCESS_ATTACHED_FILES,
          payload:
            {
              isLoadingAttachedFiles: false,
              model,
              AttachedFiles: fromJS(cFiles),
            },
        });

        // dispatch(processServerReq('uploadFiles', { files: cFiles }));
      }
    };

    ParseFile(files);
  };
  return handler;
};

export const upLoadAttachedFiles = (files) => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */

  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();

    const node = getNode(store);
    const model = node.get('modelName', '');
    const modelType = node.get('modelType', '');
    const id = node.get('itemId');

    dispatch({
      type: dpEditorActions.PROCESS_ADD_FILES,
      payload:
        {
          isLoadingAttachedFiles: true,
          model,
        },
    });

    const modelDef = definition[modelType][model];

    files.filter((f) => !f.done).forEach(async (file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataFile = {
          id,
          modelType,
          modelName: modelDef.backendName,
          fileName: file.file.name,
          task: file.task,
          method: 'uploadFiles',
          file: reader.result,
        };

        api.post$(`${modelType}/${modelDef.backendName}/SR/`, dataFile).then(async (r) => {
          if (r.ok) {
            const res = await r.json();
            // eslint-disable-next-line no-param-reassign
            files[res.task].done = res.done;

            dispatch(loadAttachedFiles());

            dispatch({
              type: dpEditorActions.PROCESS_ADD_FILES,
              payload:
                {
                  isLoadingAttachedFiles: false,
                  model,
                },
            });
          }
        });
      };

      if (file.file) {
        reader.readAsDataURL(file.file);
      }
    });
  };
  return handler;
};

// DATA CAOMPOSITION SETTINGS
export const DCSelectField = (path, field, multiple = false) => (dispatch, getState) => {
  const node = getNode(getState());
  const model = node.get('modelName', '');

  const oldTable = node.getIn(path);
  const newTable = multiple ? oldTable : oldTable.map((_r) => _r.delete(SELECTED_KEY));
  const newTable2 = field
    ? newTable.setIn([field, SELECTED_KEY], !newTable.getIn([field, SELECTED_KEY], false))
    : newTable;

  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value: newTable2,
      model,
    },
  });
};

export const DCToggleField = (path, field) => (dispatch, getState) => {
  const node = getNode(getState());
  const model = node.get('modelName', '');
  const newVI = node.getIn([...path, 'visibleItems'])
    // eslint-disable-next-line0
    .map((i, k) => (field === k ? i.set(TOGGLED_KEY, !i.get(TOGGLED_KEY, false)) : i));
  const newVi2 = generateVisibleItems(node.getIn([...path, 'items']), newVI);
  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path: [...path, 'visibleItems'],
      value: newVi2,
      model,
    },
  });
};

export const DCDeleteField = (path, id = null) => (dispatch, getState) => {
  const node = getNode(getState());
  const model = node.get('modelName', '');
  const KEY = '_SELECTED';

  const deleteBroken = (table) => table.filter((row) => !row.get('Parent') || table.has(row.get('Parent')));
  let newTable1;
  if (!id) {
    newTable1 = node.getIn(path).filter((row) => !row.get(KEY, false));
  } else {
    newTable1 = node.getIn(path).filter((row, rId) => rId !== id);
  }
  let newTable2;
  while (true) {
    newTable2 = deleteBroken(newTable1);
    if (newTable2.equals(newTable1)) {
      break;
    }
    newTable1 = newTable2;
  }

  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value: newTable2,
      model,
    },
  });
};

/**
 * Лобавление полей в раздел
 * @param path {string} - Узел стора куда добвляется поле(я).
 * @param fields - Сами поля, которые добавляются
 * @param parentId - Кто родитель
 * @param index - Куда
 * @return {function(*, *)}
 * @constructor
 */
export const DCAddField = (path, fields, parentId, index) => (dispatch, getState) => {
  const node = getNode(getState());
  const model = node.get('modelName', '');
  const table = node.getIn(path).map((r) => r.delete(SELECTED_KEY));
  const maxId = getMaxId(table);
  // const remapParents = table.mapEntries(([k, v]) => [k, k < index ? k : k + fields.size]);
  const before = table.slice(0, index);
  const after = table.slice(index);
  const newFields = fields.mapEntries(([k, v], i) => [
    `_${maxId + i + 1}`,
    v
      .set('Use', true).set('Parent', parentId),
  ]).setIn([`_${maxId + fields.size}`, SELECTED_KEY], true);

  const newTable = indexTable(before
    .merge(newFields)
    .merge(after));
  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value: newTable,
      model,
    },
  });
};

export const DCMoveField = (path, fields, from, to, newParentId) => (dispatch, getState) => {
  const node = getNode(getState());
  const model = node.get('modelName', '');
  const table = node.getIn(path);
  const min = Math.min(from, to);
  const max = Math.max(from, to);
  const before = table.slice(0, min - 1);

  const t1 = from < to
    ? table.slice((from + fields.size) - 1, max)
    : table.slice(from - 1, (from + fields.size) - 1);
  const t2 = from < to
    ? table.slice(from - 1, (from + fields.size) - 1)
    : table.slice(min - 1, (from + fields.size) - 2);
  const after = table.slice(max);
  const newTable = indexTable(before
    .merge(t1.map((r, k) => (fields.has(k) ? r.set('Parent', newParentId) : r)))
    .merge(t2.map((r, k) => (fields.has(k) ? r.set('Parent', newParentId) : r)))
    .merge(after));

  const oldParentId = fields.reduce((R, r) => r.get('Parent'), null);
  // подчиненные элементы нужно переподчинить родителю переносимого поля
  const newTable2 = from < to
    ? newTable.map((r) => (fields.has(r.get('Parent')) ? r.set('Parent', oldParentId) : r))
    : newTable;

  dispatch({
    type: dpEditorActions.CHANGE_PROPERTY,
    payload: {
      path, //
      value: newTable2,
      model,
    },
  });
};

export const goToOldVersion = () => (dispatch, getState) => {
  const node = getNode(getState());
  const modelName = node.get('modelName', '');
  const modelType = node.get('modelType', '');
  const modelDef = definition[modelType][modelName];

  const getOldVersionLink = async () => {
    const url = `${modelType}/${modelDef.backendName}/getURL`;
    const r = await api.get$(url);
    if (!r.ok) {
      let e;
      try {
        e = await r.text();
      } catch {
        dispatch(serverRequestError(`${r.status} ${r.statusText}`));
      }
    }
    return r.json();
  };
  getOldVersionLink()
    .then((d) => {
      try {
        window.open(d.oldVersionRef, '_blank').focus();
      } catch {
        dispatch(serverRequestError('В адресній строці необхідно дозволити відкривання нових вкладок'));
      }
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};

export default {
  init,
  processServerReq,
  changeField,
  activateTableRow,
  fillTable,
  swapUpTableRow,
  swapDownTableRow,
  addTableRow,
  removeTableRow,
  copyTableRow,
  getFile,
  upLoadAttachedFiles,
  // DCSettings
  DCSelectField,
  DCToggleField,
  DCDeleteField,
  DCAddField,
  DCMoveField,
  goToOldVersion,
};
