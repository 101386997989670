/**
 * @const
 */
const baseActions = [
  'INIT',
  'GENERATE',
  'SR_START',
  'SR_DONE',
  'SR_ERR',
  'CHANGE_PROPERTY',
  'SR_CHANGE_PROPERTY',
  'TABLE_ROW_SET_ACTIVE',
  'TABLE_ROW_ADD',
  'TABLE_ROW_COPY',
  'TABLE_ROW_SWAP',
  'TABLE_ROW_REMOVE',
  'TABLE_FILL',
  'CANCEL_REQUEST',
  'DECRYPTION_LOADED',
  'DECRYPTION_CLOSED',
];

/**
 * @func
 * @param {object} r
 * @param {string} a
 * @return {object}
 */
const reportEditorActionCreators = (r, a) => ({ ...r, [a]: `${a}/@reportEditor` });

/**
 * @const
 * @property {string} INIT
 * @property {string} GENERATE
 * @property {string} LOAD_DONE
 * @property {string} LOAD_ERR
 * @property {string} SR_START
 * @property {string} SR_DONE
 * @property {string} SR_ERR
 * @property {string} CHANGE_PROPERTY
 * @property {string} SR_CHANGE_PROPERTY
 * @property {string} TABLE_ROW_SET_ACTIVE
 * @property {string} TABLE_ROW_ADD
 * @property {string} TABLE_ROW_COPY
 * @property {string} TABLE_ROW_SWAP
 * @property {string} TABLE_ROW_REMOVE
 * @property {string} TABLE_FILL
 * @property {string} CANCEL_REQUEST
 * @property {string} DECRYPTION_LOADED
 * @property {string} DECRYPTION_CLOSED
 */
const reportEditorActions = baseActions.reduce(reportEditorActionCreators, {});

export default reportEditorActions;
