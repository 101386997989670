const frontendURL = '/srv/ImportFRFZFromLogica/';
const backendURL = '/api/logica/get_report/';
const name = 'Завантаження документів (FR, FZ) з сервісу Logica';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
