import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  byRequest: {
    label: 'за даними пропозиції на фінансування',
    name: 'флПоДаннымПредложенияНаФинансирования',
  },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  byCSU: {
    label: 'За даними розпорядника рівня',
    name: 'флПоДаннымФО',
  },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },
  byTreasure: {
    label: 'за даними казначейства',
    name: 'флПоДаннымКазначейства',
  },
//  consolidated: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
//  bySubLevelCSU: {
//    label: 'За данними підлеглих розпорядників',
//    name: 'флПоДаннымНижнихРасп',
//  },
//  byHighLevelCSU: {
//    label: 'За даними розпорядника вищого рівня',
//    name: 'флПоДаннымФО',
//  },
};

const definition = {
  label: 'Розширений аналіз заявок на фінансування',
  name: 'RequestAnalysisAdd',
  columns,
  frontend: 'rep/RequestAnalysisAdd',
  backendName: 'РасширенийАнализЗаявок',
  resultColumns: baseResultColumns,
};

export default definition;
