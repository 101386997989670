import {
  defaultColumns, defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,

};

export const typeAttachedFile = {
  label: 'Тип документа, що завантажується (об\'єкти буд.)',
  name: 'typeAttachedFile',
  backendName: 'ТипЗагружаемогоДок',
  defaultOrder: [
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/typeAttachedFile',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: false,

};

export default typeAttachedFile;
