import React from 'react';
import Lister from '../../lister/lister';

function TreasuryStatementCostsLister() {
  return (
    <Lister
      modelType="doc"
      modelName="treasuryStatementCosts"
    />
  );
}

export default TreasuryStatementCostsLister;
