const frontendURL = '/rep/ussage_plan/';
const backendURL = '/api/authorityplans/printform/usage_plan/';
const name = 'План використання (регламентований)';
const sections = '';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
