import React from 'react';
import Lister from '../../lister/lister';
import ListerCP from '../../lister/listerCommandPanel';
import ListerPrintModal from './listerPrintModal';

const modelName = 'proposalDeputies';
const modelType = 'infoRegs';

function ProposalDeputiesLister() {
  return (
    <Lister
      modelType={modelType}
      modelName={modelName}
      CommandPanel={(
        <ListerCP>
          <ListerPrintModal />
        </ListerCP>
    )}
    />
  );
}

export default ProposalDeputiesLister;
