import React, {
  forwardRef, useCallback, useMemo, useState,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import DatePickerInput from './datePickerInput';
import getCalendarContainer from './calendarContainer';

const DtPicker = forwardRef(({
  id, value, onChange, disabled, readOnly, onFocus, onBlur,
  errors, variants, errorAsTooltip, size, prepend,
}, ref) => {
  const [open, setOpen] = useState(false);
  const vValue = useMemo(
    () => {
      if (!variants) return null;
      return variants.filter((v) => v.value === value).reduce((R, v) => v.display_name, null);
    },
    [value, variants],
  );
  const cDate = useMemo(
    () => {
      if (!value) return null;
      if (!Date.parse(value)) return null;
      return new Date(value);
    },
    [value],
  );

  const errored = errors && errors.length;

  const onFocusHanlder = useCallback(
    (e) => {
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const onBlurHanlder = useCallback(
    (e) => {
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  const onDateChange = (e, v) => {
    const year = v.getFullYear();
    const month = String(v.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
    const day = String(v.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    onChange(e, formattedDate);
  };

  const CContainer = useMemo(
    () => getCalendarContainer({
      variants,
      value,
      onChange: (e, v) => {
        onChange(e, v);
        setOpen(false);
      },
    }),
    [onChange, value, variants],
  );

  return (
    <InputGroup className={classNames('flex-nowrap', { 'is-invalid': errored })} size={size}>
      <DatePicker
        id={id}
        ref={ref}
        selected={cDate}
        onChange={(v, e) => onDateChange(e, v)}
        onFocus={onFocusHanlder}
        onBlur={onBlurHanlder}
        className="w-100"
        placeholderText="Оберіть дату"
        dateFormat="dd.MM.yyyy"
        locale="uk"
        disabled={disabled || readOnly}
        showYearDropdown
        showMonthDropdown
        shouldCloseOnSelect
        open={open}
        onCalendarOpen={() => {
          setOpen(true);
        }}
        onCalendarClose={() => {
          setOpen(false);
        }}
        customInput={(
          <DatePickerInput
            prepend={prepend}
            errors={errors}
            errorAsTooltip={errorAsTooltip}
            vValue={vValue}
            onFocus={onFocus}
            onBlur={onBlur}
            useMask={!vValue}
          />
      )}
        calendarContainer={CContainer}
      />
    </InputGroup>
  );
});

DtPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  variants: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })),
  errorAsTooltip: PropTypes.bool,
  prepend: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

DtPicker.defaultProps = {
  id: '',
  value: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  readOnly: false,
  errors: null,
  variants: null,
  errorAsTooltip: false,
  size: 'sm',
  prepend: '',
};

export default DtPicker;
