import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Alert,
  Button, ButtonGroup, Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import classNames from 'classnames';
import api from '../../../../../api/req';
import ListerContext from '../../../context';
import { CiatAppContext } from '../../../../../providers';
import { CompareButton } from '../../../../../components/bootStrap/buttons';
import { Loader } from '../../../../../components/bootStrap';

const StyledTable = styled(Table)`
  margin-bottom: 0 !important;
  position: relative;
  & tr{
    transition: all 0.1s linear 0s;
  }
`;

// const StyledTH = styled.th`
//   vertical-align: middle !important;
//   position: sticky;
//   top: 0;
//   z-index: 11;
//   background: var(--backgroundTableHeader);
//   color: var(--colorTextTableHeader);
//   line-height: 1.2;
//   border: 1px solid var(--tableBorder)!important;
// `;

function CompareComponent() {
  const [modalOpened, setModalOpened] = useState();
  const [loading, setLoading] = useState();
  const [err, setErr] = useState();
  const { backendURL, actions } = useContext(ListerContext);
  const { auth } = useContext(CiatAppContext);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(
    () => {
      const aloader = async () => {
        const r = await api.get(`${backendURL}compare/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      if (modalOpened) {
        setLoading(true);
        setErr(null);
        aloader()
          .then((d) => setData(d.result))
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [backendURL, auth, modalOpened],
  );

  const onSelect = useCallback(
    (code) => setSelected((o) => (o.includes(code) ? o.filter((oo) => oo !== code) : [...o, code])),
    [],
  );

  const onUpdate = useCallback(
    () => {
      const aLoader = async (params) => {
        const r = await api.post(`${backendURL}compare_with_update/`, auth, params);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      const cData = data.filter((d) => selected.includes(d.code));
      const codeNothingToDo = cData.filter((d) => d.description.compare_result === 'no_code_logika').map((el) => el.code);
      const codeToCreate = cData.filter((d) => d.description.compare_result === 'no_code_minfin').map((el) => el.code);
      const codeToUpdate = cData.map((d) => d.code)
        .filter((d) => !codeToCreate.includes(d.code) && !codeNothingToDo.includes(d.code));
      const params = {
        to_update: codeToUpdate,
        to_create: codeToCreate,
      };
      if (params.to_create.length || params.to_update.length) {
        setLoading(true);
        setErr(null);
        aLoader(params)
          .then(() => {
            actions.onRefresh();
            setModalOpened(false);
          })
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      } else {
        // setModalOpened(false);
      }
    },
    [actions, auth, backendURL, data, selected],

  );

  return (
    <>
      <CompareButton
        onClick={() => setModalOpened(true)}
        content=""
      />
      <Modal
        show={modalOpened}
        scrollable
        size="xl"
        onHide={() => setModalOpened(false)}
      >
        <Modal.Title>
          <Modal.Header closeButton>
            Перегляд оновлень в Logika
          </Modal.Header>
        </Modal.Title>
        <Modal.Footer>
          <ButtonGroup size="sm">
            <Button
              variant="primary"
              onClick={() => setSelected(data.map((d) => d.code))}
            >
              Відмітити всі
            </Button>
            <Button
              variant="secondary"
              onClick={() => setSelected([])}
            >
              Зняти відмітки
            </Button>
            <Button variant="success" onClick={onUpdate}>
              Оновити відмічені
            </Button>
          </ButtonGroup>
        </Modal.Footer>
        <Modal.Body className="pt-0" style={{ minHeight: '50vh' }}>
          {err && (
            <Alert variant="danger">
              {err}
            </Alert>
          )}
          {loading && (
          <Loader text="Завантаження" />
          )}
          <StyledTable bordered hover>
            <thead>
              <tr>
                <th className="align-middle bg-white text-secondary border sticky-top">Код</th>
                <th className="align-middle bg-white text-secondary border sticky-top">Зміни</th>
                <th className="align-middle bg-white text-secondary border sticky-top">Найменування Логіка</th>
                <th className="align-middle bg-white text-secondary border sticky-top">Найменування Мінфін</th>
              </tr>
            </thead>
            <tbody>
              {data.map((el) => (
                <tr
                  className={classNames({ 'bg-primary': selected.includes(el.code), 'text-white': selected.includes(el.code) })}
                  key={el.code}
                  onClick={() => onSelect(el.code)}
                >
                  <td>{el.code}</td>
                  <td>{el.description.reason}</td>
                  <td>{el.logika_name}</td>
                  <td>{el.minfin_name}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CompareComponent;
