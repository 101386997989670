import React from 'react';
import PropTypes from 'prop-types';
import '../../tpStyle.css';
import { Card } from 'react-bootstrap';
import DocCard from './docCard';
import TPCommandPanel from './commandPanel';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';

function DocCards({
  data, tableName, onDraft, onChange,
}) {
  const tableData = data[tableName] || [];
  const {
    tableActions,
  } = useLowerDocs({
    tableName,
    onChange,
    approvedName: 'blocked',
  });

  return (
    <Card className="border-0">
      <TPCommandPanel
        data={data}
        onDraft={onDraft}
        tableActions={tableActions}
        items={tableData}
      />
      <Card.Body className="p-0">
        <DocCard
          items={tableData}
          tableName={tableName}
          onToggleApproveDoc={tableActions.onToggleApproveDoc}
        />
      </Card.Body>
    </Card>
  );
}

DocCards.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onDraft: PropTypes.func.isRequired,
  fields: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  readOnlyFields: PropTypes.shape({}),
  tableName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DocCards.defaultProps = {
  errors: null,
  readOnlyFields: [],
  fields: null,
};

export default DocCards;
