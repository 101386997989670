import styled from 'styled-components';

export const RequiredLabel = styled.span`
  color: var(--falcon-danger);
  font-weight: 700;
  &::after{
    content: '*';
  }
`;

export default RequiredLabel;
