import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  Classifier: {
    label: 'Класифікатор',
    name: 'Классификатор',
    visible: true,
  },
  SearchCode: {
    label: 'Код для пошуку',
    name: 'КодДляПоиска',
    visible: true,
  },
  CurrentBudget: {
    label: 'ТекущийБюджет',
    name: 'ТекущийБюджет',
    visible: true,
  },
  FondCode: { label: 'КодФонд', name: 'КодФонд', visible: true },
  owner: { label: 'Бюджет', name: 'Владелец', visible: true },
};

export const notes = {
  label: 'Примітки',
  name: 'notes',
  backendName: 'Примечания',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/notes',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'Classifier',
      'SearchCode',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default notes;
