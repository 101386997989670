import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import ElementListerSelector from './lister_selector';
import { modelType, modelName } from '../def';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory());

function ElementLister(props) {
  return (
    <ElementListerSelector {...props}>
      <StdLister />
    </ElementListerSelector>
  );
}

export default ElementLister;
