import {
  defaultOrder,
  defaultColumns,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
};

export const administrativeTerritorialUnits = {
  label: 'Адміністративно-територіальнi одиницi',
  name: 'administrativeTerritorialUnits',
  backendName: 'АдминистративноТерриториальныеЕдиницы',
  defaultOrder,
  columns,
  frontend: 'cat/administrativeTerritorialUnits',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default administrativeTerritorialUnits;
