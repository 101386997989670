const columns = [
  {
    name: 'YEAR', label: 'Рік',
  },
  {
    name: 'BUDGETCODE', label: 'Код бюджету', canSearch: true,
  },
  {
    name: 'EDRPOU', label: 'ЄДРПОУ', canSearch: true,
  },
  {
    name: 'LASTEDITSTR', label: 'Востаннє змінено',
  },
  {
    name: 'JSONLINK', label: 'JSON', link: true, onlyForAdmins: true,
  },
  {
    name: 'PDFLINK', label: 'PDF', link: true,
  },
  {
    name: 'ATTACHMENT', label: 'Додатки до рішень та прогнозів', link: true,
  },
  {
    name: 'SIGNLINK', label: 'Підпис', link: true, onlyForAdmins: true,
  }];

export default columns;
