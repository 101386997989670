import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  fileAddress: { label: 'АдресФайла', name: 'АдресФайла' },
  fileType: { label: 'Тип файлу для вивантаження', name: 'ТипФайла' },
  codeWin: { label: 'Кодування  Windows', name: 'КодВинд' },
  printKOATUU: { label: 'Друкувати код  КОАТУУ як код бюджету', name: 'печататьКОАТУУ' },
  order: { label: 'Довідка про операції в національній валюті', name: 'Справка' },
};

/**
 * @const
 */
const definition = {
  name: 'regInDBF',
  backendName: 'РеестрВДБФ',
  label: 'Реєстри в ДБФ',
  frontend: 'dp/regInDBF',
  columns,
};

export default definition;
