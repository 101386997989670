/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DocSelectionModal from './docSelectionModal';
// import DocSelectionModalUpLevel from './docSelectionModalUpLevel';
import { ContainerSt } from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/documents/changesToAssignmentPlanning';
import enums from '../../../../constants/meta/enums';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { StampApprovedJs } from '../../../../assets/icons';
import FillFromUpLevelDoc from './fillFromUpLevelDoc';
import {emptyUid} from "../../../../constants/meta/common";

const planingKindsOptions = Object.values(enums.PlaningKind)
  .map(({ name, label }) => ({ value: name, display_name: label }));

function ChangesToAssignmentPlanning({ data, actions, permissions }) {
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  // const handlerSelectorUpLevel = useCallback(
  //   (loadData) => {
  //     actions.onSR('LOAD_FROM_DOC_SELECTION_UPLEV', loadData);
  //   },
  //   [actions],
  // );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось
  const FI = data[md.columns.FI.name];
  const sourcesCoverList = useMemo(() => data[md.columns.sourcesCoverList.name] || [], [data]);
  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;

  const sourcesCoverDropDown = useMemo(() => Object.values(sourcesCoverList)
    .map(({ id, repr }) => ({ value: id, display_name: repr })), [sourcesCoverList]);

  const onRefReasonChange = useCallback((e, value) => actions.onChange({
    [md.columns.refReason.name]: value,
  }), [actions]);

  const onOk = useCallback((loadData) => {
    actions.onSR('LOAD_FROM_DOC_SELECTION_UPLEV', loadData);
  }, [actions]);

  const csuFilter = useMemo(() => [{ fieldName: '_Родитель', value: FI }], [FI]);

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
            timeFormat="dd.MM.yyyy HH:mm:ss"
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.numberRegistryFI.label}
            value={data[md.columns.numberRegistryFI.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.numberRegistryFI.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.label}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.templateBaseDoc.label}
            value={data[md.columns.templateBaseDoc.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.templateBaseDoc.name]: value,
              });
              actions.onSR('TEMPLATE_BASED_ON_CHANGE');
            }}
            modelType="cat"
            modelName="templateBaseDoc"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.implementer.label}
            value={data[md.columns.implementer.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.implementer.name]: value,
                [md.columns.includeBudgetAmplification.name]: false,
              });
            }}
            modelType="cat"
            modelName="implementer"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planingMode.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.refReason.label}
            value={data[md.columns.refReason.name]}
            onChange={onRefReasonChange}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.sourcesCover.label}
            value={data[md.columns.sourcesCover.name]
            && data[md.columns.sourcesCover.name].id}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.sourcesCover.name]: { id: value },
              });
            }}
            values={sourcesCoverDropDown}
            readOnly={readOnly}
          />
          {data[md.columns.includeCSUIntoHead.name] && (
            <EditorControls.ItemPicker
              label={md.columns.CSU.label}
              value={data[md.columns.CSU.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.CSU.name]: value,
                });
              }}
              modelType="cat"
              modelName="csu"
              filter={csuFilter} // or fondFilter?
              readOnly={readOnly}
              noHierarchy
              canErase
            />
          )}
        </Col>

        <Col>
          {data[md.columns.thisIsSecialFond.name] && (
            <>
              <EditorControls.CheckboxInput
                controlId={`onlyYear-${data.id}`}
                label={md.columns.onlyYear.label}
                value={data[md.columns.onlyYear.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.onlyYear.name]: value,
                })}
                readOnly={readOnly}
              />

              <EditorControls.CheckboxInput
                controlId={`includeBudgetAmplification-${data.id}`}
                label={md.columns.includeBudgetAmplification.label}
                value={data[md.columns.includeBudgetAmplification.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.includeBudgetAmplification.name]: value,
                })}
                readOnly={readOnly}
              />
            </>
          )}
          <EditorControls.CheckboxInput
            controlId={`loans-${data.id}`}
            label={md.columns.loans.label}
            value={data[md.columns.loans.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.loans.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`internal-${data.id}`}
            label={md.columns.internal.label}
            value={data[md.columns.internal.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.internal.name]: value,
            })}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`disableControl-${data.id}`}
            label={md.columns.disableControl.label}
            value={data[md.columns.disableControl.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.disableControl.name]: value,
            })}
            readOnly={readOnly}
          />
          {sessionOptions.get('is_admin', false) && (
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
          )}
        </Col>
        <Col>
          {data[md.columns.isApproved.name] && (
            <>
              <ContainerSt>
                <StampApprovedJs text={data[md.columns.received.name]} />
              </ContainerSt>
              {sessionOptions.get('is_admin', false) && (
                <EditorControls.StringInput
                  label={md.columns.received.label}
                  value={data[md.columns.received.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.received.name]: value,
                  })}
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            CSU={data[md.columns.CSU.name]}
            FI={data[md.columns.FI.name]}
            fondObject={data[md.columns.fondObject.name]}
            planingMode={data[md.columns.planingMode.name]}
            readOnly={readOnly || data[md.columns.fondObject.name]?.id === emptyUid}
            llabel={data[md.columns.fondObject.name]?.id === emptyUid ? "Заповнити з даних розпорядників нижчого рівня. Увага! Необхідно обрати фонд."
                :
                "Заповнити з даних розпорядників нижчого рівня"}
          />
        </Col>
        <Col>
          <FillFromUpLevelDoc
            planingMode={data[md.columns.planingMode.name]}
            CSU={data[md.columns.CSU.name]}
            FI={data[md.columns.FI.name]}
            fondObject={data[md.columns.fondObject.name]}
            year={data[md.columns.year.name]}
            readOnly={!permissions.canChange}
            onOk={onOk}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

ChangesToAssignmentPlanning.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChangesToAssignmentPlanning;
