import React, { forwardRef, useContext } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import FinPrintModal from './listerPrintModal';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import ListerContext from '../../../newLister/context';

const FinancingCommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);
  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <ButtonGroup>
        <Dropdown>
          <Dropdown.Toggle>
            <FontAwesomeIcon icon={faPrint} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <FinPrintModal selectedRows={selectedRows} />
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>

    </DocListerCommandPanel>
  );
});

export default FinancingCommandPanel;
