import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs,
} from 'react-bootstrap';
import {
  Tabl21TP, Tabl22TP,
} from './tabs';
import md from "../../../../constants/meta/documents/taxes2";

function Taxes2Tabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="TABL_21" className="m-0 mt-2 border-bottom">
      <Tab title="Всі коди окрім 00.n" eventKey="TABL_21">
        <Tabl21TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Лише коди 00.n" eventKey="TABL_22">
        <Tabl22TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
    // <Container fluid>
    //   <Row>
    //     <Col>
    //       <Tabl21TP data={data} actions={actions} readOnly={readOnly} />
    //     </Col>
    //     <Col>
    //       <Tabl22TP data={data} actions={actions} readOnly={readOnly} />
    //     </Col>
    //   </Row>
    // </Container>
  );
}

Taxes2Tabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default Taxes2Tabs;
