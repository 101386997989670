import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/energyIndicators';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { ContainerSt } from '../../../../components/Form/styledForm';
import { StampApprovedJs } from '../../../../assets/icons';
import { elementKFK as elementKFKMeta } from '../../../../constants/meta/catalogs/elementKFK';

function EnergyIndicators({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const isAdmin = sessionOptions.get('is_admin', false);

  const readOnly = !permissions.canChange;

  const ApprovedLabel = (
    <>
      {isAdmin && (
        <Col>
          <EditorControls.CheckboxInput
            controlId={`isApproved-${data.id}`}
            value={data[md.columns.isApproved.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isApproved.name]: value,
            })}
            label={md.columns.isApproved.label}
            readOnly={readOnly}
          />
        </Col>
      )}
      {data[md.columns.isApproved.name] && isAdmin && (
        <Col>
          <EditorControls.StringInput
            label={md.columns.received.label}
            value={data[md.columns.received.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.received.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      )}
      {data[md.columns.isApproved.name] && (
        <Col>
          <ContainerSt>
            <StampApprovedJs text={data[md.columns.received.name]} />
          </ContainerSt>
        </Col>
      )}
    </>
  );

  const hlElementFilter = useMemo(
    () => ([
      {
        fieldName: elementKFKMeta.columns.owner.name,
        value: data[md.columns.csu.name],
      },
    ]),
    [data],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <Row>
          <Col>
            <EditorControls.ItemPicker
              value={data[md.columns.budget.name]}
              label="Бюджет"
              modelType="cat"
              modelName="budget"
              onChange={(e, value) => actions.onChange({
                [md.columns.budget.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.author.label}
              value={data[md.columns.author.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.author.name]: value,
              })}
              modelType="cat"
              modelName="users"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.csu.label}
            value={data[md.columns.csu.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.csu.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.onState.label}
            value={data[md.columns.onState.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.onState.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.elementKFK.label}
            value={data[md.columns.elementKFK.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.elementKFK.name]: value,
              });
            }}
            modelType="cat"
            modelName="elementKFK"
            filter={hlElementFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.elementKEKV.label}
            value={data[md.columns.elementKEKV.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.elementKEKV.name]: value,
              });
            }}
            modelType="cat"
            modelName="elementKEKV"
            filter={hlElementFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.client.label}
            value={data[md.columns.client.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.client.name]: value,
              });
            }}
            modelType="cat"
            modelName="partners"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.elementFond.label}
            value={data[md.columns.elementFond.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.elementFond.name]: value,
              });
            }}
            modelType="cat"
            modelName="elementFond"
            filter={hlElementFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`autoCalculate-${data.id}`}
            label={md.columns.autoCalculate.label}
            value={data[md.columns.autoCalculate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.autoCalculate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        {ApprovedLabel}
      </Row>

    </EditorCollapse>
  );
}

EnergyIndicators.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default EnergyIndicators;
