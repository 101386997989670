import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: {
    label: 'Бюджет',
    name: 'Владелец',
    visible: false,
  },
  LawOfFoundation: {
    label: 'Законодавчі підстави для виконання бюджетної програми',
    name: 'ЗаконОснование',
    visible: false,
  },
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  FoundationDate: { label: 'ДатаОсн', name: 'ДатаОсн', visible: false },
  CurrentBudget: {
    label: 'Текущий Бюджет',
    name: 'ТекущийБюджет',
    visible: true,
  },
  DateMain: { label: 'Дата', name: 'ДатаОсн', visible: false },
};

const tables = {
  tpParagr: {
    label: 'Перелік положень нормативно-правових актів',
    name: 'ПоложенияНормативныхАктов',
    columns: {
      paragr: { label: 'Положення нормативно-правових актів', name: 'Статья' },
    },
  },
};

export const groundLaw = {
  label: 'Законодавчі підстави для виконання бюджетної програми',
  name: 'groundLaw',
  backendName: 'ЗаконОснование',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  tables,
  columns,
  frontend: 'cat/groundLaw',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default groundLaw;
