import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Button,
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  CheckboxInput,
  ItemPicker,
  NumberInput, SelectorInput, StringInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { comparisonTypes, emptyUid } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { NumberOfMounth } from '../../../consts';
import md from '../../../../../../constants/meta/documents/financingRequest';
import finobligation from '../../../../../../constants/meta/documents/financeObligation';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, CSUinH, FI,
  isFilterCsuElemKFK, Fond, date, activeObjectsBuilding, ABVisible,
  isComposeSettingsChecked, AccTypeTable, doNotUseAccounts, ClientInDoc, AccountingByClients,
  useFOAccounts, letMinusInRow, ShowSourcesFinInDocFin, FinancingReturn,
}) {
  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);

  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const CSUAccountFIlter = useMemo(
    () => [
      { fieldName: 'КодКБП7', value: row[tableMD.columns.KPKVCode.name] },
      { fieldName: 'Фонд', value: Fond },
      { fieldName: 'Владелец', value: useFOAccounts ? FI : row[tableMD.columns.CSU.name] },
      { fieldName: 'ВидСчета', value: AccTypeTable },
      {
        fieldName: 'ДатаЗакрытия',
        comparisonType: comparisonTypes.greaterOrEqual,
        value: date,
      },
    ],
    [AccTypeTable, FI, Fond, date, row, useFOAccounts],
  );
  const ElemKFKFilter = useMemo(
    () => [...FIOwnerFilter, ...(isFilterCsuElemKFK ? [{
      fieldName: 'Распорядитель',
      comparisonType: comparisonTypes.inList,
      value: [
        { id: emptyUid, repr: '' },
        row[tableMD.columns.CSU.name] || { id: emptyUid, repr: '' },
      ],
    }] : [])],
    [FIOwnerFilter, isFilterCsuElemKFK, row],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSU.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKEKVChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKEKV.name]: v });
      await onSR('CHANGE_KEKV_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onCSUAccountChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSUAccount.name]: v });
      await onSR('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onBuildObgectChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.BuildObgect.name]: v });
    await onSR('CHANGE_BuildObgect_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);
  const onTypeRepairChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.TypeRepair.name]: v });
  }, [onRowChange]);
  const onSumChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Sum.name]: v });
  }, [onRowChange]);
  const onNoteChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Note.name]: v });
  }, [onRowChange]);
  const onFullNotesChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.FullNotes.name]: v });
  }, [onRowChange]);
  const onIsReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.IsReturn.name]: v });
  }, [onRowChange]);
  const onMounthReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.MounthReturn.name]: v });
  }, [onRowChange]);
  const onNoteCatChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.NoteCat.name]: v });
      await onSR('CHANGE_NOTES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onDocNotesChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.DocNotes.name]: v });
    await onSR('CHANGE_DOCNOTES_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);

  const onElIncomeChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.ElIncome.name]: v });
      await onSR('CHANGE_ElINCOME_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onCodeElIncomeChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.CodeElIncome.name]: v });
  }, [onRowChange]);
  const onFinSourcesChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.finSources.name]: v });
      await onSR('CHANGE_FIN_SOURCES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        {!CSUinH && !doNotUseAccounts
          && (
            <Row noGutters>
              <Col
                xl={4}
                sm={12}
              >
                <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                  <ItemPicker
                    value={row[tableMD.columns.CSU.name]}
                    modelType="cat"
                    modelName="csu"
                    onChange={onCSUChange}
                    filter={CSUFIlter}
                    noHierarchy
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col
                xl={8}
                sm={12}
              >
                <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
                  <ItemPicker
                    value={row[tableMD.columns.CSUAccount.name]}
                    modelType="cat"
                    modelName="bankAccounts"
                    onChange={onCSUAccountChange}
                    filter={CSUAccountFIlter}
                    params={onDateParams}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.CSUAccountNo.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            </Row>
          )}
        <Row noGutters>
          <Col
            xl={4}
            sm={12}
            className="d-flex"
          >
            <Row className="w-100" noGutters>
              <Col>
                {!CSUinH && doNotUseAccounts && (
                  <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                    <ItemPicker
                      value={row[tableMD.columns.CSU.name]}
                      modelType="cat"
                      modelName="csu"
                      onChange={onCSUChange}
                      filter={CSUFIlter}
                      noHierarchy
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                )}
                <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKFK.name]}
                    modelType="cat"
                    modelName="elementKFK"
                    onChange={onKFKChange}
                    filter={ElemKFKFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KPKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
                <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKEKV.name]}
                    modelType="cat"
                    modelName="elementKEKV"
                    onChange={onKEKVChange}
                    filter={FIOwnerFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KEKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
                {CSUinH && !doNotUseAccounts
          && (
            <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
              <ItemPicker
                value={row[tableMD.columns.CSUAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onCSUAccountChange}
                filter={CSUAccountFIlter}
                params={onDateParams}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.CSUAccountNo.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          )}
              </Col>
            </Row>
          </Col>
          {activeObjectsBuilding
             && (
               <Col xl={2} sm={12} className="d-flex">
                 <Row className="w-100" noGutters>
                   <Col>
                     <TableRowCell column="BuildObgect" highlighted={highlights.includes('BuildObgect')}>
                       <ItemPicker
                         value={row[tableMD.columns.BuildObgect.name]}
                         modelType="cat"
                         modelName="objectsBuilding"
                         filter={FIOwnerFilter}
                         onChange={onBuildObgectChange}
                         readOnly={readOnly}
                       />
                     </TableRowCell>
                     <TableRowCell column="TypeRepair" highlighted={highlights.includes('TypeRepair')}>
                       <StringInput
                         value={row[tableMD.columns.TypeRepair.name]}
                         onChange={onTypeRepairChange}
                         readOnly={readOnly}
                       />
                     </TableRowCell>
                   </Col>
                 </Row>
               </Col>
             )}
          {ShowSourcesFinInDocFin
            && (
              <Col xl={2} sm={12} className="d-flex">
                <Row className="w-100" noGutters>
                  <Col>
                    <TableRowCell column="ElIncome" highlighted={highlights.includes('ElIncome')}>
                      <ItemPicker
                        value={row[tableMD.columns.ElIncome.name]}
                        modelType="cat"
                        modelName="elementKFB"
                        filter={FIOwnerFilter}
                        onChange={onElIncomeChange}
                        readOnly={readOnly}
                      />
                    </TableRowCell>
                    <TableRowCell column="CodeElIncome" highlighted={highlights.includes('CodeElIncome')}>
                      <StringInput
                        value={row[tableMD.columns.CodeElIncome.name]}
                        onChange={onCodeElIncomeChange}
                        readOnly={readOnly}
                      />
                    </TableRowCell>
                  </Col>
                </Row>
              </Col>
            )}
          <Col xl={(activeObjectsBuilding || ShowSourcesFinInDocFin) ? 6 : 8} sm={12}>
            <Row className="w-100" noGutters>
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableRowCell column="YearPlan" highlighted={highlights.includes('YearPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.YearPlan.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="PeriodPlan" highlighted={highlights.includes('PeriodPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.PeriodPlan.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="MonthPlan" highlighted={highlights.includes('MonthPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.MonthPlan.name]}
                    precision={2}
                    onChange={() => {}}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>

              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableRowCell column="PeriodFact" highlighted={highlights.includes('PeriodFact')}>
                  <NumberInput
                    value={row[tableMD.columns.PeriodFact.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="Balance" highlighted={highlights.includes('Balance')}>
                  <NumberInput
                    value={row[tableMD.columns.Balance.name]}
                    precision={2}
                    onChange={() => {}}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="FinLiabilities" highlighted={highlights.includes('FinLiabilities')}>
                  <NumberInput
                    value={row[tableMD.columns.FinLiabilities.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>

              {(ABVisible || isComposeSettingsChecked)
              && (
                <Col
                  sm={12}
                  lg={3}
                >
                  {ABVisible && (
                    <>
                      <TableRowCell column="BalanceRegAccounts" highlighted={highlights.includes('BalanceRegAccounts')}>
                        <NumberInput
                          value={row[tableMD.columns.BalanceRegAccounts.name]}
                          onChange={() => {}}
                          precision={2}
                          readOnly={readOnly}
                        />
                      </TableRowCell>
                      <TableRowCell column="BalancePersAccounts" highlighted={highlights.includes('BalancePersAccounts')}>
                        <NumberInput
                          value={row[tableMD.columns.BalancePersAccounts.name]}
                          precision={2}
                          onChange={() => {}}
                          readOnly={readOnly}
                        />
                      </TableRowCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                      <NumberInput
                        value={row[tableMD.columns.Sum.name]}
                        precision={2}
                        minValue={letMinusInRow ? Number.MIN_SAFE_INTEGER : 0}
                        maxValue={
                          FinancingReturn ? Number.MAX_SAFE_INTEGER : row[tableMD.columns.Balance.name]
                        }
                        onChange={onSumChange}
                        readOnly={readOnly}
                      />
                    </TableRowCell>
                  )}
                </Col>
              )}
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                {!isComposeSettingsChecked && !ABVisible && (
                  <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                    <NumberInput
                      value={row[tableMD.columns.Sum.name]}
                      precision={2}
                      minValue={letMinusInRow ? Number.MIN_SAFE_INTEGER : 0}
                      maxValue={
                        FinancingReturn ? Number.MAX_SAFE_INTEGER : row[tableMD.columns.Balance.name]
                      }
                      onChange={onSumChange}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                )}
                <TableRowCell column="NoteCat" highlighted={highlights.includes('NoteCat')}>
                  <ItemPicker
                    value={row[tableMD.columns.NoteCat.name]}
                    modelType="cat"
                    modelName="notes"
                    onChange={onNoteCatChange}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="DocNotes" highlighted={highlights.includes('DocNotes')}>
                  <ItemPicker
                    value={row[tableMD.columns.DocNotes.name]}
                    modelType="cat"
                    modelName="docNotes"
                    onChange={onDocNotesChange}
                    filter={FIOwnerFilter}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                {isComposeSettingsChecked && (
                  <TableRowCell column="MounthReturn" highlighted={highlights.includes('IsReturn')}>
                    <SelectorInput
                      background="inherit"
                      readOnly={readOnly}
                      value={row[tableMD.columns.MounthReturn.name]}
                      values={Object.values(NumberOfMounth)}
                      onChange={onMounthReturnChange}
                      prepend={(

                        <InputGroup.Text>
                          <CheckboxInput
                            id={`IsReturn-${row[tableMD.columns.strNumber.name]}`}
                            readOnly={readOnly}
                            value={row[tableMD.columns.IsReturn.name]}
                            onChange={onIsReturnChange}
                          />
                        </InputGroup.Text>

                      )}
                    />
                  </TableRowCell>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {ShowSourcesFinInDocFin && (
          <Row noGutters>
            <Col sm={12} lg={4}>
              <TableRowCell column="codeFinSources" highlighted={highlights.includes('codeFinSources')}>
                <StringInput
                  value={row[tableMD.columns.codeFinSources.name]}
                  readOnly
                />
              </TableRowCell>
            </Col>
            {(row[tableMD.columns.finSources.name] === null
                || row[tableMD.columns.finSources.name] === undefined
              || row[tableMD.columns.finSources.name].modelName === 'ЭлементыКФБ'
              || row[tableMD.columns.finSources.name].id === emptyUid) && (
              <Col
                sm={12}
                lg={
                  row[tableMD.columns.finSources.name] === null
                  || row[tableMD.columns.finSources.name] === undefined
                      || row[tableMD.columns.finSources.name].id === emptyUid
                    ? 4 : 8
                }
              >
                <TableRowCell column="finSources" highlighted={highlights.includes('finSources')}>
                  <ItemPicker
                    value={row[tableMD.columns.finSources.name]}
                    modelType="cat"
                    modelName="elementKFB"
                    onChange={onFinSourcesChange}
                    filter={FIOwnerFilter}
                    noHierarchy
                    readOnly={readOnly}
                    canErase
                    prepend={(

                      <InputGroup.Text>
                        КФБ
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            )}
            {(row[tableMD.columns.finSources.name] === null
                || row[tableMD.columns.finSources.name] === undefined
              || row[tableMD.columns.finSources.name].modelName === 'ЭлементыКДБ'
              || row[tableMD.columns.finSources.name].id === emptyUid) && (
              <Col
                sm={12}
                lg={
                  row[tableMD.columns.finSources.name] === null
                  || row[tableMD.columns.finSources.name] === undefined
                      || row[tableMD.columns.finSources.name].id === emptyUid
                    ? 4 : 8
                }
              >
                <TableRowCell column="finSources" highlighted={highlights.includes('finSources')}>
                  <ItemPicker
                    value={row[tableMD.columns.finSources.name]}
                    modelType="cat"
                    modelName="elementKDB"
                    onChange={onFinSourcesChange}
                    filter={FIOwnerFilter}
                    noHierarchy
                    readOnly={readOnly}
                    canErase
                    prepend={(

                      <InputGroup.Text>
                        КДБ
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            )}
          </Row>
        )}
        <Row noGutters>
          <Col sm={12} xl={6}>
            <TableRowCell column="Note" highlighted={highlights.includes('Note')}>
              <TextInput
                value={row[tableMD.columns.Note.name]}
                onChange={onNoteChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} xl={6}>
            <TableRowCell column="FullNotes" highlighted={highlights.includes('FullNotes')}>
              <TextInput
                value={row[tableMD.columns.FullNotes.name]}
                onChange={onFullNotesChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          { ClientInDoc
          && (
            <Col>
              <TableRowCell column="Client" highlighted={highlights.includes('Client')}>
                <ItemPicker
                  value={row[tableMD.columns.Client.name]}
                  modelType="cat"
                  modelName="csuPartners"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.Client.name]: v })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
          { AccountingByClients
          && (
            <Col>
              <TableRowCell column="FinLiabilitiy" highlighted={highlights.includes('FinLiabilitiy')}>
                <ItemPicker
                  value={row[tableMD.columns.FinLiabilitiy.name]}
                  modelType="doc"
                  modelName="financeObligation"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.FinLiabilitiy.name]: v })}
                  readOnly={readOnly}
                  prepend={(
                    <Button
                      variant="outline-secondary"
                      tabIndex="-1"
                      size="sm"
                      to={`/${finobligation.frontend}/${row[tableMD.columns.FinLiabilitiy.name]?.id}/`}
                      as={Link}
                    >
                      <FontAwesomeIcon icon={faGlasses} />
                    </Button>
                  )}
                />
              </TableRowCell>
            </Col>
          )}
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  CSUinH: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  isFilterCsuElemKFK: PropTypes.bool.isRequired,
  Fond: referencePropType.isRequired,
  Budget: PropTypes.shape({
  }).isRequired,
  date: PropTypes.number.isRequired,
  activeObjectsBuilding: PropTypes.bool.isRequired,
  ABVisible: PropTypes.bool.isRequired,
  isComposeSettingsChecked: PropTypes.bool.isRequired,
  AccountingByClients: PropTypes.bool.isRequired,
  ClientInDoc: PropTypes.bool.isRequired,
  AccTypeTable: PropTypes.number,
  doNotUseAccounts: PropTypes.bool,
  useFOAccounts: PropTypes.bool.isRequired,
  letMinusInRow: PropTypes.bool.isRequired,
  ShowSourcesFinInDocFin: PropTypes.bool.isRequired,
  FinancingReturn: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  AccTypeTable: -1,
  doNotUseAccounts: false,
};

export default memo(TPRow);
