import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { groundLaw as md } from '../../../../constants/meta/catalogs/groundLaw';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { referencePropType } from '../../../newEditor/propTypes';
import { selectionMode } from '../../../../constants/meta/common';

function GroundLawEditor({
  data,
  actions,
  permissions,
}) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      {data[md.columns.isGroup.name]
        ? (
          <>
            <Row>
              <Col sm={3}>
                <EditorControls.StringInput
                  label={md.columns.code.label}
                  value={data[md.columns.code.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.code.name]: value,
                  })}
                  maxLength={9}
                  readOnly={readOnly}
                />

              </Col>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.name.label}
                  value={data[md.columns.name.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.name.name]: value,
                  })}
                  maxLength={150}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.owner.label}
                  value={data[md.columns.owner.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.owner.name]: value,
                  })}
                  modelType="cat"
                  modelName="budget"
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.parent.label}
                  value={data[md.columns.parent.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.parent.name]: value,
                  })}
                  modelType="cat"
                  modelName="groundLaw"
                  readOnly={readOnly}
                  choiceSettings={selectionMode.folders}
                />
              </Col>
            </Row>
          </>
        )
        : (
          <>
            <Row>
              <Col>
                <EditorControls.DateInput
                  label={md.columns.DateMain.label}
                  value={data[md.columns.DateMain.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.DateMain.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.StringInput
                  label={md.columns.name.label}
                  value={data[md.columns.name.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.name.name]: value,
                  })}
                  maxLength={150}
                  readOnly={readOnly}
                />

              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.owner.label}
                  value={data[md.columns.owner.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.owner.name]: value,
                  })}
                  modelType="cat"
                  modelName="budget"
                  readOnly={readOnly}
                />

              </Col>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.LawOfFoundation.label}
                  value={data[md.columns.LawOfFoundation.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.LawOfFoundation.name]: value,
                  })}
                  modelType="cat"
                  modelName="laws"
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.parent.label}
                  value={data[md.columns.parent.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.parent.name]: value,
                  })}
                  modelType="cat"
                  modelName="groundLaw"
                  readOnly={readOnly}
                  choiceSettings={selectionMode.folders}
                />
              </Col>
              <Col>
                <EditorControls.TextInput
                  label={md.columns.FullName.label}
                  value={data[md.columns.FullName.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.FullName.name]: value,
                  })}
                  readOnly={readOnly}
                  rows={5}
                />
              </Col>
            </Row>
          </>
        )}
    </Container>
  );
}

GroundLawEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
    [md.columns.owner.name]: referencePropType,
    [md.columns.parent.name]: referencePropType,
    [md.columns.DateMain.name]: PropTypes.number,
    [md.columns.LawOfFoundation.name]: referencePropType,
    [md.columns.FullName.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default GroundLawEditor;
