import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DocSelectionModal from './docLoading/docSelectionModal';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/countExpensesOnEducation';
import { ContainerSt, StyledLabel } from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { StampApprovedJs } from '../../../../assets/icons';
import { InputWithDropdown } from '../../../../components/styledInputs';
import meta from '../../../../constants/meta';

function CountExpensesOnEducation({ data, actions, permissions }) {
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того, чтобы все хозяйство не перерендеривалось
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];

  const repMonth = {
    m1: { label: 'Січень', name: 1 },
    m8: { label: 'Серпень', name: 8 },
  };

  const FIFilter = useMemo(
    () => [{
      fieldName: 'Родитель',
      value: FI,
    }],
    [FI],
  );

  const getDocLogicaId = (o) => {
    for (const i in o) {
      if (i === 'id') {
        return o[i];
      }
    }
    return null;
  };

  const getDocLogicaRepr = (o) => {
    for (const i in o) {
      if (i === 'repr') {
        return o[i];
      }
    }
    return null;
  };

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>{md.columns.reportMonth.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repMonth)}
            value={data[md.columns.reportMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reportMonth.name]: value,
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.csu.label}
            value={data[md.columns.csu.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.csu.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            filter={FIFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G1.label}
            value={data[md.columns.R01G1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G2.label}
            value={data[md.columns.R01G3.name] + data[md.columns.R01G4.name]
              + data[md.columns.R01G5.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G2.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G3.label}
            value={data[md.columns.R01G3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G3.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G4.label}
            value={data[md.columns.R01G4.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G4.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G5.label}
            value={data[md.columns.R01G5.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G5.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G6.label}
            value={data[md.columns.R01G6.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G6.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G7.label}
            value={data[md.columns.R01G7.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G7.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G8.label}
            value={data[md.columns.R01G9.name] + data[md.columns.R01G10.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G8.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G9.label}
            value={data[md.columns.R01G9.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G9.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G10.label}
            value={data[md.columns.R01G10.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G10.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G11.label}
            value={data[md.columns.R01G12.name] + data[md.columns.R01G13.name]
              + data[md.columns.R01G15.name] + data[md.columns.R01G16.name]
              + data[md.columns.R01G17.name] + data[md.columns.R01G18.name]
              + data[md.columns.R01G19.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G11.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G12.label}
            value={data[md.columns.R01G12.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G12.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G13.label}
            value={data[md.columns.R01G13.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G13.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G14.label}
            value={data[md.columns.R01G14.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G14.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G15.label}
            value={data[md.columns.R01G15.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G15.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G16.label}
            value={data[md.columns.R01G16.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G16.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G17.label}
            value={data[md.columns.R01G17.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G17.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G18.label}
            value={data[md.columns.R01G18.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G18.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G19.label}
            value={data[md.columns.R01G19.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G19.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G20.label}
            value={data[md.columns.R01G20.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G20.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G21.label}
            value={data[md.columns.R01G22.name] + data[md.columns.R01G23.name]
          + data[md.columns.R01G24.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G21.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G22.label}
            value={data[md.columns.R01G22.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G22.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G23.label}
            value={data[md.columns.R01G23.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G23.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G24.label}
            value={data[md.columns.R01G24.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G24.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G25.label}
            value={data[md.columns.R01G26.name] + data[md.columns.R01G27.name]
              + data[md.columns.R01G29.name] + data[md.columns.R01G30.name]
              + data[md.columns.R01G31.name] + data[md.columns.R01G32.name]
              + data[md.columns.R01G33.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G25.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G26.label}
            value={data[md.columns.R01G26.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G26.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G27.label}
            value={data[md.columns.R01G27.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G27.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G28.label}
            value={data[md.columns.R01G28.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G28.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G29.label}
            value={data[md.columns.R01G29.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G29.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G30.label}
            value={data[md.columns.R01G30.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G30.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G31.label}
            value={data[md.columns.R01G31.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G31.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G32.label}
            value={data[md.columns.R01G32.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G32.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G33.label}
            value={data[md.columns.R01G33.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G33.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G34.label}
            value={data[md.columns.R01G34.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G34.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G35.label}
            value={data[md.columns.R01G35.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G35.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G36.label}
            value={data[md.columns.R01G36.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G36.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G37.label}
            value={data[md.columns.R01G37.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G37.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G38.label}
            value={data[md.columns.R01G38.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G38.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R01G39.label}
            value={data[md.columns.R01G39.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01G39.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
            onSR={actions.onSR}
          />
        </Col>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
            />
            {data[md.columns.isApproved.name] && sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.invoice.label}
                value={data[md.columns.invoice.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.invoice.name]: value,
                })}
              />
            )}
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <Col>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.invoice.name]} />
            </ContainerSt>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <StyledLabel>
            {md.columns.docLogica.label}
            <Link to={`${meta.doc.docTransferToLogica.frontend}/${getDocLogicaId(data[md.columns.docLogica.name])}`}>
              {getDocLogicaRepr(data[md.columns.docLogica.name])}
            </Link>
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.statusLogica.label]}
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.signLogica.label]}
          </StyledLabel>
        </Col>
      </Row>
    </EditorCollapse>
  );
}

CountExpensesOnEducation.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    [md.columns.isApproved.name]: PropTypes.bool,
    [md.columns.invoice.name]: PropTypes.string,
    [md.columns.note.name]: PropTypes.string,
    [md.columns.author.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CountExpensesOnEducation;
