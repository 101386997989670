import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: {
    label: 'Назва',
    name: 'Наименование',
    visible: true,
  },
  owner: { name: 'Владелец', label: 'Бюджет', visible: false },
  budget: { name: 'Бюджет', label: 'Бюджет', visible: true },
  FI: { name: 'ФинансовыйОрган', label: 'Фінансовий орган', visible: true },
  DATEDOC: { name: 'DATEDOC', label: '(Дата рішення сесії)' },
  NUMDOC: { name: 'NUMDOC', label: '(№ рішення сесії)' },
  KOATUUNAIM: { name: 'KOATUUNAIM', label: '(назва адміністративно-територіальної одиниці)' },
  PY: { name: 'PY', label: 'на' },
  KLB: { name: 'KLB', label: '(код бюджету)' },
  NAME_ORGV: { name: 'NAME_ORGV', label: '(назва органу місцевого самоврядування)' },
  A1001: { name: 'A1001', label: 'A1001' },
  A1002: { name: 'A1002', label: 'A1002' },
  A1003: { name: 'A1003', label: 'A1003' },
  A1004: { name: 'A1004', label: 'A1004' },
  A1005: { name: 'A1005', label: 'A1005' },
  A1006: { name: 'A1006', label: 'A1006' },
  A1007: { name: 'A1007', label: 'A1007' },
  A1008: { name: 'A1008', label: 'A1008' },
  A1009: { name: 'A1009', label: 'A1009' },
  A1010: { name: 'A1010', label: 'A1010' },
  A1011: { name: 'A1011', label: 'A1011' },
  A1012: { name: 'A1012', label: 'A1012' },
  A1013: { name: 'A1013', label: 'A1013' },
  A1014: { name: 'A1014', label: 'A1014' },
  A1015: { name: 'A1015', label: 'A1015' },
  A1016: { name: 'A1016', label: 'A1016' },
  A1017: { name: 'A1017', label: 'A1017' },
  A1018: { name: 'A1018', label: 'A1018' },
  A1019: { name: 'A1019', label: 'A1019' },
  A1020: { name: 'A1020', label: 'A1020' },
  DESCRIPTION1_1: { name: 'DESCRIPTION1_1', label: 'опис' },
  DESCRIPTION1_2: { name: 'DESCRIPTION1_2', label: 'опис' },
  DESCRIPTION1_3: { name: 'DESCRIPTION1_3', label: 'опис' },
  DESCRIPTION1_4: { name: 'DESCRIPTION1_4', label: 'опис' },
  DESCRIPTION1_5: { name: 'DESCRIPTION1_5', label: 'опис' },
  DESCRIPTION1_6: { name: 'DESCRIPTION1_6', label: 'опис' },
  DESCRIPTION1_7: { name: 'DESCRIPTION1_7', label: 'опис' },
  DESCRIPTION1_8: { name: 'DESCRIPTION1_8', label: 'опис' },
  DESCRIPTION2_1: { name: 'DESCRIPTION2_1', label: 'опис' },
  DESCRIPTION3_1: { name: 'DESCRIPTION3_1', label: 'опис' },
  DESCRIPTION3_2: { name: 'DESCRIPTION3_2', label: 'опис' },
  DESCRIPTION3_3: { name: 'DESCRIPTION3_3', label: 'опис' },
  DESCRIPTION4_1: { name: 'DESCRIPTION4_1', label: 'опис' },
  DESCRIPTION5_1: { name: 'DESCRIPTION5_1', label: 'опис' },
  A5001: { name: 'A5001', label: 'A5001' },
  DESCRIPTION6_1: { name: 'DESCRIPTION6_1', label: 'опис' },
  DESCRIPTION7_1: { name: 'DESCRIPTION7_1', label: 'опис' },
  RECEIPTS1: { name: 'RECEIPTS1', label: 'RECEIPTS1' },
  ARTICLE7_1_1: { name: 'ARTICLE7_1_1', label: 'ARTICLE7_1_1' },
  ARTICLE7_1_2: { name: 'ARTICLE7_1_2', label: 'ARTICLE7_1_2' },
  POINT7_2_1: { name: 'POINT7_2_1', label: 'POINT7_2_1' },
  PART7_2_1: { name: 'PART7_2_1', label: 'PART7_2_1' },
  ARTICLE7_2: { name: 'ARTICLE7_2', label: 'ARTICLE7_2' },
  RECEIPTS2: { name: 'RECEIPTS2', label: 'RECEIPTS2' },
  RECEIPTS3: { name: 'RECEIPTS3', label: 'RECEIPTS3' },
  RECEIPTS4: { name: 'RECEIPTS4', label: 'RECEIPTS4' },
  RECEIPTS5: { name: 'RECEIPTS5', label: 'RECEIPTS5' },
  RECEIPTS6: { name: 'RECEIPTS6', label: 'RECEIPTS6' },
  DESCRIPTION7_2: { name: 'DESCRIPTION7_2', label: 'опис' },
  DESCRIPTION7_3: { name: 'DESCRIPTION7_3', label: 'опис' },
  ARTICLE8_1: { name: 'ARTICLE8_1', label: 'ARTICLE8_1' },
  DESCRIPTION8_1: { name: 'DESCRIPTION8_1', label: 'DESCRIPTION8_1' },
  PART8_2_1: { name: 'PART8_2_1', label: 'PART8_2_1' },
  POINT8_2_1: { name: 'POINT8_2_1', label: 'POINT8_2_1' },
  ARTICLE8_2: { name: 'ARTICLE8_2', label: 'ARTICLE8_2' },
  POINT8_3_1: { name: 'POINT8_3_1', label: 'POINT8_3_1' },
  PART8_3_1: { name: 'PART8_3_1', label: 'PART8_3_1' },
  ARTICLE8_3: { name: 'ARTICLE8_3', label: 'ARTICLE8_3' },
  DESCRIPTION8_2: { name: 'DESCRIPTION8_2', label: 'DESCRIPTION8_2' },
  DESCRIPTION8_3: { name: 'DESCRIPTION8_3', label: 'DESCRIPTION8_3' },
  PART9_1_1: { name: 'PART9_1_1', label: 'PART9_1_1' },
  ARTICLE9_1_1: { name: 'ARTICLE9_1_1', label: 'ARTICLE9_1_1' },
  PART9_1_2: { name: 'PART9_1_2', label: 'PART9_1_2' },
  ARTICLE9_1_2: { name: 'ARTICLE9_1_2', label: 'ARTICLE9_1_2' },
  PARAGRAPH9_1: { name: 'PARAGRAPH9_1', label: 'PARAGRAPH9_1' },
  DECISION9_1_1: { name: 'DECISION9_1_1', label: 'DECISION9_1_1' },
  DECISION9_1_2: { name: 'DECISION9_1_2', label: 'DECISION9_1_2' },
  DESCRIPTION9_1: { name: 'DESCRIPTION9_1', label: 'DESCRIPTION9_1' },
  SUBPARAGRAPH9_1: { name: 'SUBPARAGRAPH9_1', label: 'SUBPARAGRAPH9_1' },
  SUBPARAGRAPH9_2: { name: 'SUBPARAGRAPH9_2', label: 'SUBPARAGRAPH9_2' },
  PARAGRAPH9_1_1: { name: 'PARAGRAPH9_1_1', label: 'PARAGRAPH9_1_1' },
  PARAGRAPH9_2_1: { name: 'PARAGRAPH9_2_1', label: 'PARAGRAPH9_2_1' },
  A10001: { name: 'A10001', label: 'A10001' },
  A10002: { name: 'A10002', label: 'A10002' },
  A10003: { name: 'A10003', label: 'A10003' },
  DESCRIPTION11_1: { name: 'DESCRIPTION11_1', label: 'DESCRIPTION11_1' },
  DESCRIPTION11_2: { name: 'DESCRIPTION11_2', label: 'DESCRIPTION11_2' },
  DESCRIPTION11_3: { name: 'DESCRIPTION11_3', label: 'DESCRIPTION11_3' },
  DESCRIPTION11_4: { name: 'DESCRIPTION11_4', label: 'DESCRIPTION11_4' },
  DESCRIPTION11_5: { name: 'DESCRIPTION11_5', label: 'DESCRIPTION11_5' },
  DESCRIPTION12_1: { name: 'DESCRIPTION12_1', label: 'DESCRIPTION12_1' },
  DESCRIPTION13_1: { name: 'DESCRIPTION13_1', label: 'DESCRIPTION13_1' },
  DESCRIPTION14_1: { name: 'DESCRIPTION14_1', label: 'DESCRIPTION14_1' },
  DESCRIPTION15_1: { name: 'DESCRIPTION15_1', label: 'DESCRIPTION15_1' },
  DESCRIPTION16_1: { name: 'DESCRIPTION16_1', label: 'DESCRIPTION16_1' },
  DESCRIPTION16_2: { name: 'DESCRIPTION16_2', label: 'DESCRIPTION16_2' },
  DESCRIPTION16_3: { name: 'DESCRIPTION16_3', label: 'DESCRIPTION16_3' },
  DESCRIPTION16_4: { name: 'DESCRIPTION16_4', label: 'DESCRIPTION16_4' },
  DESCRIPTION16_5: { name: 'DESCRIPTION16_5', label: 'DESCRIPTION16_5' },
  DESCRIPTION16_6: { name: 'DESCRIPTION16_6', label: 'DESCRIPTION16_6' },
  DESCRIPTION16_7: { name: 'DESCRIPTION16_7', label: 'DESCRIPTION16_7' },
  DESCRIPTION16_8: { name: 'DESCRIPTION16_8', label: 'DESCRIPTION16_8' },
  DESCRIPTION17: { name: 'DESCRIPTION17', label: 'DESCRIPTION17' },
  ADDITION18: { name: 'ADDITION18', label: 'ADDITION18' },
  DESCRIPTION18: { name: 'DESCRIPTION18', label: 'DESCRIPTION18' },
  ADDITION19: { name: 'ADDITION19', label: 'ADDITION19' },
  DESCRIPTION19: { name: 'DESCRIPTION19', label: 'DESCRIPTION19' },
  DESCRIPTION20: { name: 'DESCRIPTION20', label: 'DESCRIPTION20' },
  DESCRIPTION21: { name: 'DESCRIPTION21', label: 'DESCRIPTION21' },
  DESCRIPTION22: { name: 'DESCRIPTION22', label: 'DESCRIPTION22' },
  DESCRIPTION23: { name: 'DESCRIPTION23', label: 'DESCRIPTION23' },
  DESCRIPTION24: { name: 'DESCRIPTION24', label: 'DESCRIPTION24' },
  NAMEOFFICE: { name: 'NAMEOFFICE', label: 'NAMEOFFICE' },
  HBOSS: { name: 'HBOSS', label: 'HBOSS' },
  ADD1: { name: 'ADD1', label: 'Додаток 1' },
  ADD2: { name: 'ADD2', label: 'Додаток 2' },
  ADD3: { name: 'ADD3', label: 'Додаток 3' },
  ADD4: { name: 'ADD4', label: 'Додаток 4' },
  ADD5: { name: 'ADD5', label: 'Додаток 5' },
  ADD6: { name: 'ADD6', label: 'Додаток 6' },
  ADD7: { name: 'ADD7', label: 'Додаток 7' },
  ADD8: { name: 'ADD8', label: 'Додаток 8' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Це проект рішення', name: 'Проект' },
  docId: { label: 'id документа', name: 'id' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  isFirst: { label: 'Це першопочаткове рішення (на початок року)', name: 'ЭтоПервоеРешение' },
  noADD1: { label: 'Не відправляти в Логіка дані по додатку 1', name: 'НетДод1' },
  noADD2: { label: 'Не відправляти в Логіка дані по додатку 2', name: 'НетДод2' },
  noADD3: { label: 'Не відправляти в Логіка дані по додатку 3', name: 'НетДод3' },
  noADD4: { label: 'Не відправляти в Логіка дані по додатку 4', name: 'НетДод4' },
  noADD5: { label: 'Не відправляти в Логіка дані по додатку 5', name: 'НетДод5' },
  noADD6: { label: 'Не відправляти в Логіка дані по додатку 6', name: 'НетДод6' },
  noADD7: { label: 'Не відправляти в Логіка дані по додатку 7', name: 'НетДод7' },
  noADD8: { label: 'Не відправляти в Логіка дані по додатку 8', name: 'НетДод8' },
};

export const resolutionSession = {
  label: 'Рішення сесії про бюджет (Текстова частина з додатками)',
  name: 'resolutionSession',
  backendName: 'РешениеСессииТекст',
  defaultOrder,
  columns,
  frontend: 'cat/resolutionSession',
  listColumns: [...(new Set([...reqListerColumns, 'owner']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default resolutionSession;
