import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  objBuildingTypeSource: { label: 'Тип джерела фінансування', name: 'Тип', visible: false },

};

export const objBuildingSourceFin = {
  label: 'Довідник джерел фінансування об\'єктів будівництва',
  name: 'objBuildingSourceFin',
  backendName: 'ОбъектыСтроительстваИсточникиФинансирования',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/objBuildingSourceFin',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default objBuildingSourceFin;
