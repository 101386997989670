import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import EstimatePrintModal from '../../assignmentPlanning/lister/estimatePrintModalLister';

function PrintSubMenu() {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="info" size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Показати ще ...
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <EstimatePrintModal />
      </Dropdown.Menu>
    </Dropdown>
  );
}

PrintSubMenu.propTypes = {
};

export default PrintSubMenu;
