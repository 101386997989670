import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  treasCode: { label: 'Код казначейского файлу (Якщо небхідно)', name: 'КодКазны', visible: false },
  disableLoad: { label: 'Не завантажувати дані з Логіка', name: 'НаЗагружатьИзЛогика', visible: false },
};

export const budgetTransfer = {
  label: 'Бюджети для міжбюджетних трансфертів',
  name: 'budgetTransfer',
  columns,
  backendName: 'БюджетыТрансфертов',
  defaultOrder,
  frontend: 'cat/budgetTransfer',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default budgetTransfer;
