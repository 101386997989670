import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const GridTemplate = styled(BasedGrid)`
  grid-template-areas: 
    "check docno   mfo       account  sum    purpose doc    "
    "check docdate bankname  client   edrpou purpose lineno ";
  grid-template-columns: 30px 100px 1fr 1fr 100px 1fr 1fr ;
`;

export default GridTemplate;
