import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  disposer: foreignPropType,
  kbp7_item: foreignPropType,
  target_programm: foreignPropType,
  note: PropTypes.string,
  zf: PropTypes.number,
  sf: PropTypes.number,
  dev_budget: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
