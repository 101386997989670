import React, {
  Fragment, memo, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { monthNames } from '../../../../../../constants/date';
import { decimalToStr } from '../../../../../../common/funcs';

const QUARTERS = [...Array(4)].map((_, i) => i);

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  onlyYear,
  vt4br,
  authority,
}) {
  const amountChangeHandler = useCallback(
    (e, newValue, m) => {
      const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_${k + 1}`] || 0)), 0);
      onChange(e, rowIndex, {
        [`amount_${m}`]: newValue,
        amount_total: total,
      });
    },
    [onChange, row, rowIndex],
  );

  const amountBRChangeHandler = useCallback(
    (e, newValue, m) => {
      const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_br_${k + 1}`] || 0)), 0);
      onChange(e, rowIndex, {
        [`amount_br_${m}`]: newValue,
        amount_br_total: total,
      });
    },
    [onChange, row, rowIndex],
  );

  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(() => ({
    parent: authority ? authority.id : 0,
  }), [authority]);
  const kbp7ItemProps = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountProps = monthNames.map(
    (_, m) => tableHooks.useNumberInputProps(`amount_${m + 1}`, row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields),
  );

  const amountBRProps = monthNames.map(
    (_, m) => tableHooks.useNumberInputProps(`amount_br_${m + 1}`, row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields),
  );

  return (
    <>
      <Col
        xl={4}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposerProps}
                filter={disposerFilter}
                noHierarchy
              />
            </TabelRowCell>
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker {...kbp7ItemProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col xl={8} sm={12}>
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q}>
              {[...Array(3)].map((_, m) => (
                <Fragment key={`amount_${q * 3 + m + 1}`}>
                  <TabelRowCell
                    column={`amount_${q * 3 + m + 1}`}
                    highlighted={highlights.includes(`amount_${q * 3 + m + 1}`)}
                  >
                    <TableControls.NumberInput
                      {...amountProps[q * 3 + m]}
                      onChange={(e, v) => amountChangeHandler(e, v, q * 3 + m + 1)}
                    />
                  </TabelRowCell>
                  {vt4br && (
                    <TabelRowCell
                      column={`amount_br_${q * 3 + m + 1}`}
                      highlighted={highlights.includes(`amount_br_${q * 3 + m + 1}`)}
                    >
                      <TableControls.NumberInput
                        {...amountBRProps[q * 3 + m]}
                        onChange={(e, v) => amountBRChangeHandler(e, v, q * 3 + m + 1)}
                      />
                    </TabelRowCell>
                  )}
                </Fragment>
              ))}
            </Col>
          ))}
          <Col
            md={12}
            className="d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column"
          >
            {onlyYear ? (
              <>
                <TabelRowCell column="amount_1" highlighted={highlights.includes('amount_1')}>
                  <TableControls.NumberInput
                    {...amountProps[0]}
                    onChange={(e, v) => amountChangeHandler(e, v, 1)}
                  />
                </TabelRowCell>
                {vt4br && (
                <TabelRowCell column="amount_br_1" highlighted={highlights.includes('amount_br_1')}>
                  <TableControls.NumberInput
                    {...amountBRProps[0]}
                    onChange={(e, v) => amountBRChangeHandler(e, v, 1)}
                  />
                </TabelRowCell>
                )}
              </>
            ) : (
              <div className="font-weight-bold w-100 text-right text-md-center">
                <TabelRowCell column="amount_total" highlighted={highlights.includes('amount_total')}>
                  {decimalToStr(row.amount_total)}
                </TabelRowCell>
                {vt4br && (
                <TabelRowCell column="amount_br_total" highlighted={highlights.includes('amount_br_total')}>
                  {decimalToStr(row.amount_br_total)}
                </TabelRowCell>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>

    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
  disposerInHeader: PropTypes.bool,
  authority: foreignPropType,
  showKDBKFB: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  onlyYear: false,
  vt4br: false,
  disposerInHeader: false,
  authority: null,
  showKDBKFB: false,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
