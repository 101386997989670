import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function IncomeMenuItem() {
  return (
    <UseMenuHeader label="Доходи">
      <Boxes items={NavPanel.income.boxes} />
    </UseMenuHeader>
  );
}

export default IncomeMenuItem;
