import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import TableComponents from './Docs';
import TableComponents2 from './Contents';
import CPDocs from './cpDocs';
import StyledTPPane from '../../../../components/tableParts/TPPane';

const TABLENAME = 'docsTable';
const TABLENAME2 = 'documentNotes';

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  content: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME2}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const T1 = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
  TableComponents.FooterComponent,
);
const T2 = getBaseTP(TABLENAME2, TableComponents2.RowComponent, TableComponents2.HeaderComponent);

const Divider = styled.div`
  display: flex:
  width: 2px;
  border: 1px solid rgba(34,36,38,.15);
  margin: 0 5px;
`;

const StyledContainer = styled(StyledTPPane)`
  flex-direction: column;
  border: 1px solid #DDE2E9;
`;

const StyledGrid = styled.div`
  display: flex;
  height: 100%;
`;

export const ContT = styled.div`
  flex: 1 1 auto;
`;

export const DivCell = styled.div`
`;

function TC({
  dispatch, data, content,
}) {
  const fData = data.filter((row) => row.get('IS_ACTIVE', false)).reduce((v, row) => v.set(row.getIn(['Document', 'id']), 'true'), new Map());
  const filteredContent = content.filter((cRow) => fData.has(cRow.getIn(['Document', 'id'])));
  return (
    <StyledContainer>
      <CPDocs tableName={TABLENAME} dispatch={dispatch} />
      <StyledGrid>
        <ContT>
          <T1 data={data} dispatch={dispatch} actionSource="dpEditor" />
        </ContT>
        <Divider />
        <ContT>
          <T2
            data={filteredContent}
            dispatch={dispatch}
            actionSource="dpEditor"
          />
        </ContT>
      </StyledGrid>
    </StyledContainer>
  );
}

TC.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(List).isRequired,
  content: PropTypes.instanceOf(List).isRequired,
};

export default connect(mapState)(TC);
