import React, { useEffect, useMemo, useRef } from 'react';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/finanalisys';
import { useGroups } from '../../basicReport/hooks/groups';
import GroupsEditor from '../../basicReport/ReportSettingsEditor/groups/groupsEditor';
import ReportHeader from '../_common/reportHeader';

const URL = instance.backendURL;

function Rep() {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL, repr: 'Аналіз фінансування' });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange);
  const inThousandsProps = editorHooks.useCheckboxInputProps('in_thousands', data, fields, fieldErrors, actions.onChange);
  const approvedPlanProps = editorHooks.useCheckboxInputProps('approved_plan', data, fields, fieldErrors, actions.onChange);
  const displayClassifiersNameProps = editorHooks.useCheckboxInputProps('display_classifiers_name', data, fields, fieldErrors, actions.onChange);
  const authorityFilterProps = editorHooks.useSelectorInputProps('authority_filter', data, fields, fieldErrors, actions.onChange);

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const {
    groups, availableGroups, displayGroups, groupsHandlers, loadGroups,
  } = useGroups(reportData, data, 'report_meta');
  const { onChange } = actions;
  const initialDataLoaded = useRef(false);
  useEffect(
    () => {
      if (initialDataLoaded.current) onChange(() => ({ filters, groups }));
    },
    [filters, groups, onChange],
  );
  useEffect(
    () => {
      if (!initialDataLoaded.current && (data.filters || data.groups)) {
        if (data.filters) loadFilters(data.filters);
        if (data.groups) loadGroups(data.groups);
        initialDataLoaded.current = true;
      }
    },
    [data.filters, data.groups, filters, filters.length, loadFilters, loadGroups],
  );

  return (
    <ReportHeader title={instance.name}>
      <PrintFormContainer
        loading={loading}
        err={err}
        actions={actions}
        ref={resultRef}
        pageLayout={pageLayout}
        quickSettings={(
          <Row>
            <Col md={3}>
              <EditorControls.DateInput {...bdateProps} />
            </Col>
            <Col md={3}>
              <EditorControls.DateInput {...edateProps} />
            </Col>
          </Row>
      )}
        settings={(
          <>
            <Row>
              <Col md={4}>
                <EditorControls.SelectorInput {...planKindProps} />
                <EditorControls.SelectorInput {...authorityFilterProps} />
              </Col>
              <Col md={4}>
                <EditorControls.ItemPicker {...authorityProps} />
              </Col>
              <Col md={4} className="border-left">
                <EditorControls.CheckboxInput {...inThousandsProps} />
                <EditorControls.CheckboxInput {...approvedPlanProps} />
                <EditorControls.CheckboxInput {...displayClassifiersNameProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DndProvider backend={HTML5Backend}>
                  <Tabs defaultActiveKey="groups">
                    <Tab eventKey="groups" title="Групування">
                      <GroupsEditor
                        groups={displayGroups}
                        groupsHandlers={groupsHandlers}
                        availableGroups={availableGroups}
                      />
                    </Tab>
                    <Tab eventKey="filters" title="Фільтрування">
                      <FiltersEditor
                        filters={displayFilters}
                        filtersHandlers={filtersHandlers}
                        availableFilters={availableFilters}
                      />
                    </Tab>
                  </Tabs>
                </DndProvider>
              </Col>
            </Row>
          </>
      )}
      />
    </ReportHeader>
  );
}

export default Rep;
