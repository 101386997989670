import React, {
  memo, useCallback, useMemo,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput, StringInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'investPrograms';

const tableMD = md.tables[tablename];

function TPRow({
  row,
  active,
  rowIndex,
  onChange,
  activeCol,
  readOnly,
  highlights,
  transitionToOB,
  turnBuildObjOn,
  budget,
  kbp7,
  program,
  docByKBP7,
  isKyiv,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  const OBFilter = useMemo(
    () => [
      {
        fieldName: 'Владелец',
        value: budget,
      },
      {
        fieldName: docByKBP7 ? 'КБП7' : 'Программа',
        value: docByKBP7 ? kbp7 : program,
      },
    ],
    [budget, docByKBP7, kbp7, program],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col sm={2}>
        <Row noGutters>
          { turnBuildObjOn && (
            <Col sm={12}>
              <TableRowCell column="constructionObject" highlighted={highlights.includes('constructionObject')}>
                <ItemPicker
                  value={row[tableMD.columns.constructionObject.name]}
                  modelType="cat"
                  modelName="objectsBuilding"
                  onChange={(e, v) => onRowChange(
                    e,
                    { [tableMD.columns.constructionObject.name]: v },
                  )}
                  readOnly={readOnly}
                  filter={OBFilter}
                />
              </TableRowCell>
            </Col>
          )}
          { (transitionToOB || !turnBuildObjOn) && (
            <Col sm={12}>
              <TableRowCell column="targetS" highlighted={highlights.includes('targetS')}>
                <ItemPicker
                  value={row[tableMD.columns.targetS.name]}
                  modelType="cat"
                  modelName="targetedPrograms"
                  onChange={(e, v) => onRowChange(
                    e,
                    { [tableMD.columns.targetS.name]: v },
                  )}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
          <Col sm={12}>
            <TableRowCell column="constructionPeriod" highlighted={highlights.includes('constructionPeriod')}>
              <StringInput
                value={row[tableMD.columns.constructionPeriod.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.constructionPeriod.name]: v },
                )}
                maxLength={50}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          { turnBuildObjOn && (
            <Col sm={12}>
              <TableRowCell column="costumer" highlighted={highlights.includes('costumer')}>
                <StringInput
                  value={row[tableMD.columns.costumer.name]}
                  onChange={(e, v) => onRowChange(
                    e,
                    { [tableMD.columns.costumer.name]: v },
                  )}
                  maxLength={500}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
          <Col sm={12} className="text-center">
            <TableRowCell column="basicMoney" highlighted={highlights.includes('basicMoney')}>
              <NumberInput
                value={row[tableMD.columns.basicMoney.name]}
                precision={2}
                onChange={(e, value) => onRowChange(e, {
                  [tableMD.columns.basicMoney.name]: value,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={(turnBuildObjOn && isKyiv) ? 6 : 10}>
        <Row noGutters>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum_2" highlighted={highlights.includes('sum_2')}>
                  {/* {(row[tableMD.columns.sum_2.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
                  <NumberInput
                    value={row[tableMD.columns.sum_2.name]}
                    inputClassName="text-success"
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
              { !turnBuildObjOn && (
                <Col sm={12}>
                  <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
                    <NumberInput
                      value={row[tableMD.columns.sf_2.name]}
                      inputClassName="text-success"
                      precision={2}
                      onChange={(e, value) => onRowChange(e, {
                        [tableMD.columns.sf_2.name]: value,
                        [tableMD.columns.sum_2.name]: value,
                      })}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              )}
              <Col sm={12}>
                <TableRowCell column="ss_1" highlighted={highlights.includes('ss_1')}>
                  <NumberInput
                    value={row[tableMD.columns.ss_1.name]}
                    inputClassName="text-success"
                    precision={1}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.ss_1.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum_1" highlighted={highlights.includes('sum_1')}>
                  {/* {(row[tableMD.columns.sum_1.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
                  <NumberInput
                    value={row[tableMD.columns.sum_1.name]}
                    inputClassName="text-success"
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
              { !turnBuildObjOn && (
                <Col sm={12}>
                  <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
                    <NumberInput
                      value={row[tableMD.columns.sf_1.name]}
                      inputClassName="text-success"
                      precision={2}
                      onChange={(e, value) => onRowChange(e, {
                        [tableMD.columns.sf_1.name]: value,
                        [tableMD.columns.sum_1.name]: value,
                      })}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              )}
              <Col sm={12}>
                <TableRowCell column="ss_2" highlighted={highlights.includes('ss_2')}>
                  <NumberInput
                    value={row[tableMD.columns.ss_2.name]}
                    precision={1}
                    inputClassName="text-success"
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.ss_2.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum0" highlighted={highlights.includes('sum0')}>
                  {/* {(row[tableMD.columns.sum0.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
                  <NumberInput
                    value={row[tableMD.columns.sum0.name]}
                    inputClassName="text-success"
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
              { !turnBuildObjOn && (
                <Col sm={12}>
                  <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
                    <NumberInput
                      value={row[tableMD.columns.sf0.name]}
                      inputClassName="text-success"
                      precision={2}
                      onChange={(e, value) => onRowChange(e, {
                        [tableMD.columns.sf0.name]: value,
                        [tableMD.columns.sum0.name]: value,
                      })}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              ) }
              <Col sm={12}>
                <TableRowCell column="ss_3" highlighted={highlights.includes('ss_3')}>
                  <NumberInput
                    value={row[tableMD.columns.ss_3.name]}
                    inputClassName="text-success"
                    precision={1}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.ss_3.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum1" highlighted={highlights.includes('sum1')}>
                  {/* {(row[tableMD.columns.sum1.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
                  <NumberInput
                    value={row[tableMD.columns.sum1.name]}
                    inputClassName="text-success"
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
              { !turnBuildObjOn && (
                <Col sm={12}>
                  <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
                    <NumberInput
                      value={row[tableMD.columns.sf1.name]}
                      inputClassName="text-success"
                      precision={2}
                      onChange={(e, value) => onRowChange(e, {
                        [tableMD.columns.sf1.name]: value,
                        [tableMD.columns.sum1.name]: value,
                      })}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              )}
              <Col sm={12}>
                <TableRowCell column="ss_4" highlighted={highlights.includes('ss_4')}>
                  <NumberInput
                    value={row[tableMD.columns.ss_4.name]}
                    inputClassName="text-success"
                    precision={1}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.ss_4.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row noGutters>
              <Col sm={12} className="text-center px-2 text-muted">
                <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
                  {/* {(row[tableMD.columns.sum2.name] || 0).toLocaleString('uk', moneysStingOptions)} */}
                  <NumberInput
                    value={row[tableMD.columns.sum2.name]}
                    inputClassName="text-success"
                    precision={2}
                    readOnly
                  />
                </TableRowCell>
              </Col>
              { !turnBuildObjOn && (
                <Col sm={12}>
                  <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
                    <NumberInput
                      value={row[tableMD.columns.sf2.name]}
                      inputClassName="text-success"
                      precision={2}
                      onChange={(e, value) => onRowChange(e, {
                        [tableMD.columns.sf2.name]: value,
                        [tableMD.columns.sum2.name]: value,
                      })}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                </Col>
              ) }
              <Col sm={12}>
                <TableRowCell column="ss_5" highlighted={highlights.includes('ss_5')}>
                  <NumberInput
                    value={row[tableMD.columns.ss_5.name]}
                    inputClassName="text-success"
                    precision={1}
                    onChange={(e, value) => onRowChange(e, {
                      [tableMD.columns.ss_5.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
            </Row>
          </Col>
          { isKyiv && (
            <Col sm={12}>
              <TableRowCell column="docKMR" highlighted={highlights.includes('docKMR')}>
                <TextInput
                  value={row[tableMD.columns.docKMR.name]}
                  onChange={(e, value) => onRowChange(e, {
                    [tableMD.columns.docKMR.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
        </Row>
      </Col>
      { isKyiv && (
        <Col sm={4}>
          <Row noGutters>
            <Col sm={6}>
              <TableRowCell column="linkMCP" highlighted={highlights.includes('linkMCP')}>
                <TextInput
                  value={row[tableMD.columns.linkMCP.name]}
                  onChange={(e, value) => onRowChange(e, {
                    [tableMD.columns.linkMCP.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col sm={6}>
              <TableRowCell column="docPKD" highlighted={highlights.includes('docPKD')}>
                <TextInput
                  value={row[tableMD.columns.docPKD.name]}
                  onChange={(e, value) => onRowChange(e, {
                    [tableMD.columns.docPKD.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col sm={6}>
              <TableRowCell column="doc" highlighted={highlights.includes('doc')}>
                <TextInput
                  value={row[tableMD.columns.doc.name]}
                  onChange={(e, value) => onRowChange(e, {
                    [tableMD.columns.doc.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col sm={6}>
              <TableRowCell column="basis" highlighted={highlights.includes('basis')}>
                <TextInput
                  value={row[tableMD.columns.basis.name]}
                  onChange={(e, value) => onRowChange(e, {
                    [tableMD.columns.basis.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Row>
        </Col>
      )}
    </TableRow>

  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  transitionToOB: PropTypes.bool,
  turnBuildObjOn: PropTypes.bool,
  budget: PropTypes.shape({
  }).isRequired,
  kbp7: PropTypes.shape({
  }).isRequired,
  program: PropTypes.shape({
  }).isRequired,
  docByKBP7: PropTypes.bool.isRequired,
  isKyiv: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  transitionToOB: false,
  turnBuildObjOn: false,
};

export default memo(TPRow);
