import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/treasuryStatementCosts';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSU'}
              highlighted={highlights.includes('CSU')}
              onToggleHighlght={() => onToggleHighlght('CSU')}
            >
              {meta.columns.CSU.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FO'}
              highlighted={highlights.includes('FO')}
              onToggleHighlght={() => onToggleHighlght('FO')}
            >
              {meta.columns.FO.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'date'}
              highlighted={highlights.includes('date')}
              onToggleHighlght={() => onToggleHighlght('date')}
            >
              {meta.columns.date.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KTKVCode'}
              highlighted={highlights.includes('KTKVCode')}
              onToggleHighlght={() => onToggleHighlght('KTKVCode')}
            >
              {meta.columns.KTKVCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Code'}
              highlighted={highlights.includes('Code')}
              onToggleHighlght={() => onToggleHighlght('Code')}
            >
              {meta.columns.Code.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Fond'}
              highlighted={highlights.includes('Fond')}
              onToggleHighlght={() => onToggleHighlght('Fond')}
            >
              {meta.columns.Fond.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KVK'}
              highlighted={highlights.includes('KVK')}
              onToggleHighlght={() => onToggleHighlght('KVK')}
            >
              {meta.columns.KVK.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KEKV'}
              highlighted={highlights.includes('KEKV')}
              onToggleHighlght={() => onToggleHighlght('KEKV')}
            >
              {meta.columns.KEKV.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FondCode'}
              highlighted={highlights.includes('FondCode')}
              onToggleHighlght={() => onToggleHighlght('FondCode')}
            >
              {meta.columns.FondCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KVKCode'}
              highlighted={highlights.includes('KVKCode')}
              onToggleHighlght={() => onToggleHighlght('KVKCode')}
            >
              {meta.columns.KVKCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KEKVCode'}
              highlighted={highlights.includes('KEKVCode')}
              onToggleHighlght={() => onToggleHighlght('KEKVCode')}
            >
              {meta.columns.KEKVCode.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumExecution'}
              highlighted={highlights.includes('sumExecution')}
              onToggleHighlght={() => onToggleHighlght('sumExecution')}
            >
              {meta.columns.sumExecution.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumExecutionBegYear'}
              highlighted={highlights.includes('sumExecutionBegYear')}
              onToggleHighlght={() => onToggleHighlght('sumExecutionBegYear')}
            >
              {meta.columns.sumExecutionBegYear.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRetained'}
              highlighted={highlights.includes('sumRetained')}
              onToggleHighlght={() => onToggleHighlght('sumRetained')}
            >
              {meta.columns.sumRetained.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCashExpenses'}
              highlighted={highlights.includes('sumCashExpenses')}
              onToggleHighlght={() => onToggleHighlght('sumCashExpenses')}
            >
              {meta.columns.sumCashExpenses.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCashExpensesBegYear'}
              highlighted={highlights.includes('sumCashExpensesBegYear')}
              onToggleHighlght={() => onToggleHighlght('sumCashExpensesBegYear')}
            >
              {meta.columns.sumCashExpensesBegYear.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRemaining'}
              highlighted={highlights.includes('sumRemaining')}
              onToggleHighlght={() => onToggleHighlght('sumRemaining')}
            >
              {meta.columns.sumRemaining.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFinObligations'}
              highlighted={highlights.includes('sumFinObligations')}
              onToggleHighlght={() => onToggleHighlght('sumFinObligations')}
            >
              {meta.columns.sumFinObligations.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Section'}
              highlighted={highlights.includes('Section')}
              onToggleHighlght={() => onToggleHighlght('Section')}
            >
              {meta.columns.Section.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KP'}
              highlighted={highlights.includes('KP')}
              onToggleHighlght={() => onToggleHighlght('KP')}
            >
              {meta.columns.KP.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,

};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
