import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { officials as md } from '../../../../constants/meta/catalogs/officials';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Roles = {
  general: { label: 'Звичайний', name: 'Обычный' },
  fo: { label: 'Керівництво ФО', name: 'РукФО' },
  tg: { label: 'Керівництво ТГ', name: 'РукТГ' },
};

const rolesOptions = Object.values(Roles).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function Officials({ data, actions, permissions }) {
  const selector = (state) => state;
  const stateGlobal = useSelector(selector);
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const isAdmin = sessionOptions.get('is_admin');
  const readOnly = !permissions.canChange
      || (!isAdmin && (data[md.columns.role.name] === Roles.fo.name
          || data[md.columns.role.name] === Roles.tg.name));
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Budget.label}
            value={data[md.columns.Budget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Budget.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Surname.label}
            value={data[md.columns.Surname.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Surname.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Name.label}
            value={data[md.columns.Name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.MiddleName.label}
            value={data[md.columns.MiddleName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.MiddleName.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={9}>
          <EditorControls.StringInput
            label={md.columns.Position.label}
            value={data[md.columns.Position.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Position.name]: value,
            })}
            readOnly={readOnly}
            maxLength={400}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.role.label}
            value={data[md.columns.role.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.role.name]: value,
            })}
            readOnly={!isAdmin}
            values={rolesOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.PhoneNumberWork.label}
            value={data[md.columns.PhoneNumberWork.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PhoneNumberWork.name]: value,
            })}
            readOnly={readOnly}
            maxLength={15}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.PhoneNumberMob.label}
            value={data[md.columns.PhoneNumberMob.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PhoneNumberMob.name]: value,
            })}
            readOnly={readOnly}
            maxLength={15}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Email.label}
            value={data[md.columns.Email.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Email.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.reason.label}
            value={data[md.columns.reason.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reason.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.Date_kl.label}
            value={data[md.columns.Date_kl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.Date_kl.name]: value,
              });
              // actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="officials"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.oowner.label}
            value={data[md.columns.oowner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.oowner.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

Officials.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Officials;
