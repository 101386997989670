import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, Button, Dropdown, DropdownButton, Form, Navbar,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LogicaPing from '../../Logica/Ping';
import Loader from '../../../components/styledWrappedLoader';
import { logikaLogout } from '../../../actions/auth';
import FilteredTable from './formComponent/baseTable';
import columns from './columns';
import { soSelector } from '../../documents/_common/selectors';

function PublicDocumentsInLogica() {
  const [isLoading, setIsLoading] = useState(false);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState(null);

  const [typesOfFormList, setTypesofFormList] = useState(null);
  const [publicDocsList, setPublicDocsList] = useState(null);
  const [displayDocList, setDisplayDocList] = useState(null);

  const [formCode, setFormCode] = useState([]);
  const [year, setYear] = useState('2022');
  const [regionCode, setRegionCode] = useState(null);

  const dispatch = useDispatch();

  const token = localStorage.getItem('ticket');

  const sessionOptions = useSelector(soSelector);
  const isAdmin = sessionOptions.get('is_admin', false);

  const getTypesOfForm = useCallback(async () => {
    const link = 'https://logica.minfin.gov.ua/ws/api/_REPORTS.DESCRIPTIONS';
    setIsLoading(true);
    const res = await fetch(link);
    if (res.status === 401) {
      dispatch(logikaLogout());
      setIsLoading(false);
      setError('Необхідно авторизуватись!');
      return;
    }
    const r = await res.json();
    setIsLoading(false);
    setTypesofFormList(r.result
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((i) => ({ value: i.code, name: i.title })));
  }, [dispatch]);

  useEffect(() => { getTypesOfForm(); }, [getTypesOfForm]);

  useEffect(() => {
    if (token) {
      setTicket(token);
    } else {
      setTicket(null);
    }
  }, [token]);

  const getPublicDocsList = useCallback(async () => {
    try {
      setError(null);
      setPublicDocsList(null);
      setDisplayDocList(null);
      let link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORTS.PUBLISH?formCode=${formCode}&year=${year}`;
      if (regionCode) {
        link += `&regionCode=${regionCode}`;
      }
      setIsLoading(true);
      const res = await fetch(link, {
        headers: {
          Ticket: ticket, accept: 'application/json',
        },
      });
      if (res.status === 401) {
        dispatch(logikaLogout());
        setIsLoading(false);
        setError('Необхідно авторизуватись!');
        return;
      }
      const r = await res.json();
      setIsLoading(false);
      if (r.success) {
        if (r.result.length > 0) {
          setPublicDocsList(r.result.sort((a, b) => a.EDRPOU - b.EDRPOU));
          setDisplayDocList(r.result.sort((a, b) => a.EDRPOU - b.EDRPOU));
        } else (setError('За даним запитом опублікованих документів не знайдено'));
      } else setError(r.message);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  }, [dispatch, formCode, regionCode, ticket, year]);

  const handleTypesChange = (e) => {
    setFormCode(Array.from(e.currentTarget.selectedOptions, (v) => v.value));
  };

  const onSetFilter = (col, val) => {
    setDisplayDocList(publicDocsList.filter((doc) => doc[col].includes(val)));
  };

  return (
    <Loader isLoading={isLoading}>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(false)}>
          <Alert.Heading>
            {error}
          </Alert.Heading>
        </Alert>
      )}

      {(!ticket || (error === 'Необхідно авторизуватись!')) && (
        <Navbar.Brand href="#/dp/authentication/" className="mb-2" onClick={() => setError(null)}>Авторизація у LOGICA</Navbar.Brand>
      )}

      {!!typesOfFormList
         && (
           <Form.Group className="mb-3" controlId="ControlInput">
             <Form.Label>Форми</Form.Label>
             <Form.Control className="me-3" as="select" multiple onChange={handleTypesChange} value={formCode} size="lg" htmlSize="5">
               {typesOfFormList.map((type) => (
                 <option key={type.name} value={type.value} selected>
                   {type.name}
                 </option>
               ))}
             </Form.Control>
           </Form.Group>
         )}
      <Form style={{ display: 'flex', alignItems: 'end' }}>
        <Form.Group className="me-3" controlId="exampleForm.ControlInput2">
          <Form.Label>Код регіону</Form.Label>
          <Form.Control type="text" placeholder="Код регіону" onChange={(e) => setRegionCode(e.target.value)} />
        </Form.Group>
        <DropdownButton variant="outline-primary" id="dropdown-item-button" title={year} disabled={!logicaPingSuccess} style={{ marginBottom: ' 1rem' }}>
          <Dropdown.Item as="button" onClick={() => setYear('2020')}>2020</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setYear('2021')}>2021</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setYear('2022')}>2022</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setYear('2023')}>2023</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setYear('2024')}>2024</Dropdown.Item>
        </DropdownButton>
      </Form>

      <Button className="mb-3" type="button" onClick={getPublicDocsList} disabled={formCode.length === 0 || !ticket}>Отримати</Button>

      {publicDocsList && displayDocList && publicDocsList.length > 0
      && (
        <FilteredTable
          columns={columns}
          data={displayDocList}
          typesOfFormList={typesOfFormList}
          isAdmin={isAdmin}
          onSetFilter={onSetFilter}
        />
      )}

    </Loader>
  );
}

export default PublicDocumentsInLogica;
