/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Button, ButtonGroup, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadDialog from './dialog';

function LoadLevelDownButton({
  overrideFilter, onLoadHandler, useFinProposal, disabled, docDate,
}) {
  const [opened, setOpened] = useState(false);
  const [clear, setClear] = useState(true);
  return (
    <>
      <Dropdown as={ButtonGroup} className="mt-2">
        <Button
          disabled={disabled}
          variant="falcon-primary"
          onClick={() => {
            setClear(true);
            setOpened(true);
          }}
        >
          {`Заповнити ${useFinProposal ? 'пропозиціями' : 'заявками'} на фінансування`}
        </Button>
        <Dropdown.Toggle split variant="falcon-primary" />
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {
            setClear(false);
            setOpened(true);
          }}
          >
            {`Доповнити ${useFinProposal ? 'пропозиціями' : 'заявками'} на фінансування`}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      { opened && (
        <LoadDialog
          overrideFilter={overrideFilter}
          onClose={() => setOpened(false)}
          onOk={(items) => onLoadHandler(items, clear)}
          docDate={docDate}
        />
      )}
    </>
  );
}

LoadLevelDownButton.propTypes = {
  overrideFilter: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.shape({}), PropTypes.bool, PropTypes.number,
  ]))),
  onLoadHandler: PropTypes.func.isRequired,
  useFinProposal: PropTypes.bool,
  disabled: PropTypes.bool,
  docDate: PropTypes.string,
};

LoadLevelDownButton.defaultProps = {
  overrideFilter: {},
  useFinProposal: false,
  disabled: false,
  docDate: null,
};

export default LoadLevelDownButton;
