import React, {
  useCallback, memo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderedMap, Map } from 'immutable';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  changeField, DCAddField, DCDeleteField, DCSelectField, DCMoveField, DCToggleField,
} from '../../../../actions/reportEditor';
import AvailableFields from '../AvailableFields';
import StructureView from './structureView';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import { StyledPanelsContainer } from '../StyledComponents';
import storePathParam from '../../../../common/storePathParam';
import NavButtons from '../navButtons';
import meta from '../../../../constants/meta';
import CheckBoxField from '../../../field/CheckBox';

const AVPATH = [
  'dataCompositionSettings',
  'GroupAvailableFields',
];

const PATH = [
  'dataCompositionSettings',
  'Group',
];

function Structure() {
  const [showAdvansedGroup, setShowAdvansedGroup] = useState(false);
  const { availableFields, data, mainGroupFields } = useSelector(
    (state) => {
      const { name } = storePathParam(state);
      const modelType = state.getIn([`rep/${name}/reportEditor`, 'modelType'], '');
      const modelName = state.getIn([`rep/${name}/reportEditor`, 'modelName'], '');
      // eslint-disable-next-line no-shadow
      const { mainGroupFields } = meta[modelType][modelName];
      const allAvailableFields = state.getIn([`rep/${name}/reportEditor`, ...AVPATH], new Map());
      // eslint-disable-next-line no-shadow
      const availableFields = showAdvansedGroup
        ? allAvailableFields
        : allAvailableFields.map((item) => item.filter((el) => {
          if (!mainGroupFields) return true;
          return mainGroupFields.includes(el.get('Field', ''));
        }));
      return {
        data: state.getIn([`rep/${name}/reportEditor`, ...PATH], new OrderedMap()),
        availableFields: mainGroupFields ? availableFields : allAvailableFields,
        mainGroupFields,
      };
    },
  );

  const dispatch = useDispatch();

  const addFieldsToStructure = useCallback(
    (fields, addParams) => {
      const addableFields = fields.map((row) => new Map({
        Type: DC.fieldTypes.Group,
        Name: '',
        Selection: new Map({
          1: new Map({
            Type: DC.fieldTypes.Auto,
            Use: true,
            Order: 1,
            Parent: null,
          }),
        }),
        Filter: new Map(),
        Order: new Map(),
        GroupFields: new Map({
          1: new Map({
            Type: DC.fieldTypes.GroupField,
            Use: true,
            Field: row.get('Field'),
            GroupType: DC.groupTypes.Items,
            AdditionType: 'None',
          }),
        }),
        ViewMode: DC.viewModes.QuickAccess,
      }));
      dispatch(DCAddField(PATH, addableFields, addParams.parentId, addParams.index));
    },
    [dispatch],
  );
  const onDragEnd = useCallback(
    ({
      destination, draggableId, source,
    }) => {
      if (destination) {
        if (destination.droppableId === 'DC.Structure' && source.droppableId === 'DC.AvailableFields') {
          const draggableField = availableFields.getIn(['visibleItems', draggableId]);
          const addableFields = new Map().set(draggableId, draggableField);
          const parentId = data.filter((r) => r.get('Index') === destination.index).reduce((R, r, k) => k, null);
          addFieldsToStructure(addableFields, { parentId, index: destination.index + 1 });
        } else if (destination.droppableId === 'DC.AvailableFields' && source.droppableId === 'DC.Structure') {
          dispatch(DCDeleteField(PATH, draggableId));
        } else if (destination.droppableId === 'DC.Structure' && source.droppableId === 'DC.Structure') {
          const fields = new Map().set(draggableId, data.get(draggableId, new Map()));
          const newId = source.index > destination.index
            ? destination.index
            : destination.index + 1;
          const oldId = source.index;
          // new parentId has null if Index === 1
          const newParentId = data
            .filter((r) => r.get('Index') === (destination.index + (newId > oldId ? 1 : 0)))
            .reduce((R, r, k) => k, null);
          dispatch(DCMoveField(PATH, fields, oldId, newId, newParentId));
        }
      }
    },
    [addFieldsToStructure, availableFields, data, dispatch],
  );

  const toggleSelection = useCallback(
    (e, rowId) => dispatch(changeField([...PATH, rowId, 'Use'], !data.getIn([rowId, 'Use'], false))),
    [data, dispatch],
  );

  const typesGroop = useCallback(
    (value, rowId) => { dispatch(changeField([...PATH, rowId, 'GroupFields', '1', 'GroupType'], value)); },
    [dispatch],
  );

  const fieldSelectionHandler = useCallback(
    (e, rowId) => {
      e.stopPropagation();
      dispatch(DCSelectField(
        PATH,
        rowId,
        false,
      ));
    },
    [dispatch],
  );

  const fieldDeletionHandler = useCallback(
    () => dispatch(DCDeleteField(PATH)),
    [dispatch],
  );

  const fieldAddHandler = useCallback(
    () => {
      const addableFields = availableFields.get('visibleItems', new Map())
        .filter((row) => row.get('_SELECTED', false));
      const addParams = data.reduce((R, row, key) => {
        if (row.get('_SELECTED', false)) {
          return {
            parentId: key,
            afterKey: null,
          };
        }
        return R;
      }, { parentId: null, afterKey: null });

      addFieldsToStructure(addableFields, addParams);
    },
    [addFieldsToStructure, availableFields, data],
  );

  const selectHandler = useCallback(
    (e, field) => dispatch(DCSelectField([...AVPATH, 'visibleItems'], field, e.ctrlKey)),
    [dispatch],
  );

  const toggleAvailabeFields = useCallback(
    (e, field) => dispatch(DCToggleField(AVPATH, field)),
    [dispatch],
  );

  const leftSelected = !!availableFields.get('visibleItems', new Map()).filter((row) => row.get('_SELECTED', false)).size;
  const rightSelected = !!data.filter((row) => row.get('_SELECTED', false)).size;

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      {mainGroupFields && (
        <CheckBoxField
          value={showAdvansedGroup}
          label="Додаткові групування"
          onChange={() => {
            setShowAdvansedGroup(!showAdvansedGroup);
          }}
        />
      )}
      <StyledPanelsContainer>
        <AvailableFields
          items={availableFields.get('visibleItems', new Map())}
          selectHandler={selectHandler}
          choiceHandler={fieldAddHandler}
          toggleHandler={toggleAvailabeFields}
        />
        <NavButtons
          canRight={leftSelected}
          canLeft={rightSelected}
          onRight={fieldAddHandler}
          onLeft={fieldDeletionHandler}
        />
        <StructureView
          availableFields={availableFields.get('items')}
          data={data}
          selectHandler={fieldSelectionHandler}
          toggleUseHandler={toggleSelection}
          deleteHandler={fieldDeletionHandler}
          onActionClick={typesGroop}
        />
      </StyledPanelsContainer>
    </DragDropContext>
  );
}

export default memo(Structure);
