import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const taskProps = tableHooks.useItemInputProps('problem', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col>
      <Row className="w-100" noGutters>
        <Col>
          <TabelRowCell column="task" highlighted={highlights.includes('task')}>
            <TableControls.ItemPicker {...taskProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
