import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/tasks';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { selectionMode } from '../../../../constants/meta/common';

function TasksEditor({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.TypeTaskProgramm.label}
            value={data[md.columns.TypeTaskProgramm.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TypeTaskProgramm.name]: value,
            })}
            modelType="cat"
            modelName="tasksClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            readOnly={readOnly}
            maxLength={9}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={90}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
            maxLength={290}
          />

        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateKl.label}
            value={data[md.columns.DateKl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateKl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="tasks"
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
      <EditorControls.CheckboxInput
        controlId={`NotActual-${data.id}`}
        label={md.columns.NotActual.label}
        value={data[md.columns.NotActual.name]}
        onChange={(e, value) => actions.onChange({
          [md.columns.NotActual.name]: value,
        })}
        readOnly={readOnly}
      />
    </Container>
  );
}

TasksEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TasksEditor;
