import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import md from '../../../../constants/meta/reports/sessionAdd1';

function PrintButton() {
  return (
    <Button as={Link} variant="primary" to={`/${md.frontend}/`} size="sm">
      <FontAwesomeIcon icon={faPrint} color="white" />
    </Button>
  );
}

export default PrintButton;
