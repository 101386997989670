import React, { useId, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import Checkbox from '../../../components/Controls/checkbox';

export function EditorCheckbox({
  label, required, errors, description, className, ...restProps
}) {
  const controlRef = useRef(null);
  const id = useId();
  const errored = !!errors && !!errors.length;
  return (
    <div className="mb-2">
      <Form.Group className={classNames(className, { 'is-invalid': errored })}>
        <Checkbox
          className="mb-0"
          {...restProps}
          id={id}
          label={label}
          ref={controlRef}
          errored={errors && !!errors.length}
        />
      </Form.Group>
      {errors && errors.length && (
      <Form.Control.Feedback type="invalid">
        {errors.join(', ')}
      </Form.Control.Feedback>
      )}
      {description && (
      <Form.Text className="text-muted mt-n1 d-block">
        {description}
      </Form.Text>
      )}
    </div>
  );
}

EditorCheckbox.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  className: PropTypes.string,
};

EditorCheckbox.defaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
  className: '',
};

export default EditorCheckbox;
