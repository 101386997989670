import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from '../../../../incomeProjectChanges/editor/tp/planningRows/propTypes';

function Header({
  activeCol, highlights, onToggleHighlight, totals, fields,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col lg={3} className="border-right">
        <Row>
          <Col xs={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
          </Col>
          <Col xs={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7_item'}
              highlighted={highlights.includes('kbp7_item')}
              onToggleHighlight={() => onToggleHighlight('kbp7_item')}
            >
              {fields.kbp7_item.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col lg={3} className="border-right">
        <Row>
          <Col xs={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'contragent'}
              highlighted={highlights.includes('contragent')}
              onToggleHighlight={() => onToggleHighlight('contragent')}
            >
              {fields.contragent.label}
            </TableHeaderCell>
          </Col>
          <Col xs={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'target_program'}
              highlighted={highlights.includes('target_program')}
              onToggleHighlight={() => onToggleHighlight('target_program')}
            >
              {fields.target_program.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col lg={2} className="border-right">
        <Row>
          <Col xs={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'date_number'}
              highlighted={highlights.includes('date_number')}
              onToggleHighlight={() => onToggleHighlight('date_number')}
            >
              {fields.date_number.label}
            </TableHeaderCell>
          </Col>
          <Col xs={8}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'credit_term'}
              highlighted={highlights.includes('credit_term')}
              onToggleHighlight={() => onToggleHighlight('credit_term')}
            >
              {fields.credit_term.label}
            </TableHeaderCell>
          </Col>
          <Col xs={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'currency'}
              highlighted={highlights.includes('currency')}
              onToggleHighlight={() => onToggleHighlight('currency')}
            >
              {fields.currency.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col lg={2} className="border-right">
        <Row noGutters>
          <Col xs={12}>
            <TableHeaderCell
              title={totals.credit_amount}
              className="text-center text-xl-left"
              active={activeCol === 'credit_amount'}
              highlighted={highlights.includes('credit_amount')}
              onToggleHighlight={() => onToggleHighlight('credit_amount')}
            >
              {fields.credit_amount.label}
            </TableHeaderCell>
          </Col>
          <Col xs={12}>
            <TableHeaderCell
              title={totals.credit_involvement_amount}
              className="text-center text-xl-left"
              active={activeCol === 'credit_involvement_amount'}
              highlighted={highlights.includes('credit_involvement_amount')}
              onToggleHighlight={() => onToggleHighlight('credit_involvement_amount')}
            >
              {fields.credit_involvement_amount.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col lg={2}>
        <TableHeaderCell
          title={totals.plan_year}
          className="text-center text-xl-left"
          active={activeCol === 'plan_year'}
          highlighted={highlights.includes('plan_year')}
          onToggleHighlight={() => onToggleHighlight('plan_year')}
        >
          {fields.plan_year.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    credit_involvement_amount: PropTypes.number,
    credit_amount: PropTypes.number,
    plan_year: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
