import { compose } from 'redux';
import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import TableComponents from './GeneralTP';

const TABLENAME = 'general';

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const enhance = compose(
  connect(mapState),
  getBaseTP,
);

export default enhance(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
  TableComponents.FooterComponent,
);
