const frontendURL = '/doc/budgetProjectChanges/';
const backendURL = '/api/planproject/devbudgetprojectchangesheader/';
const name = 'Зміни до розподілу коштів бюджету розвитку';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
