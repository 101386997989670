import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters>
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumResultGenF'}
              highlighted={highlights.includes('sumResultGenF')}
            >
              {totals.sumResultGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumResultSpecF'}
              highlighted={highlights.includes('sumResultSpecF')}
            >
              {totals.sumResultSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumCashGenF'}
              highlighted={highlights.includes('sumCashGenF')}
            >
              {totals.sumCashGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumCashSpecF'}
              highlighted={highlights.includes('sumCashSpecF')}
            >
              {totals.sumCashSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPlanGenF'}
              highlighted={highlights.includes('sumPlanGenF')}
            >
              {totals.sumPlanGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPlanSpecF'}
              highlighted={highlights.includes('sumPlanSpecF')}
            >
              {totals.sumPlanSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactGenF'}
              highlighted={highlights.includes('sumFactGenF')}
            >
              {totals.sumFactGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactSpecF'}
              highlighted={highlights.includes('sumFactSpecF')}
            >
              {totals.sumFactSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPrognosisGenF'}
              highlighted={highlights.includes('sumPrognosisGenF')}
            >
              {totals.sumPrognosisGenF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPrognosisSpecF'}
              highlighted={highlights.includes('sumPrognosisSpecF')}
            >
              {totals.sumPrognosisSpecF.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumResultTotal'}
              highlighted={highlights.includes('sumResultTotal')}
            >
              {totals.sumResultTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumCashTotal'}
              highlighted={highlights.includes('sumCashTotal')}
            >
              {totals.sumCashTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPlanTotal'}
              highlighted={highlights.includes('sumPlanTotal')}
            >
              {totals.sumPlanTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFactTotal'}
              highlighted={highlights.includes('sumFactTotal')}
            >
              {totals.sumFactTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPrognosisTotal'}
              highlighted={highlights.includes('sumPrognosisTotal')}
            >
              {totals.sumPrognosisTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumResultGenF: PropTypes.number,
    sumResultSpecF: PropTypes.number,
    sumResultTotal: PropTypes.number,
    sumCashGenF: PropTypes.number,
    sumCashSpecF: PropTypes.number,
    sumCashTotal: PropTypes.number,
    sumPlanGenF: PropTypes.number,
    sumPlanSpecF: PropTypes.number,
    sumFactGenF: PropTypes.number,
    sumFactSpecF: PropTypes.number,
    sumPlanTotal: PropTypes.number,
    sumFactTotal: PropTypes.number,
    sumPrognosisTotal: PropTypes.number,
    sumPrognosisGenF: PropTypes.number,
    sumPrognosisSpecF: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
