import React from 'react';

function ArrowIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 10H5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.59997 14L4.59998 10L8.59997 6" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowIcon;
