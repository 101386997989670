/**
 * @const
 */
const baseActions = [
  'INIT',
  'LOAD_START',
  'LOAD_DONE',
  'LOAD_ERR',
  'SAVE_START',
  'SAVE_DONE',
  'SAVE_ERROR',
  'PROCESS_START',
  'PROCESS_DONE',
  'PROCESS_ERR',
  'LOCK_START',
  'LOCK_DONE',
  'LOCK_ERR',
  'UNLOCK_START',
  'UNLOCK_DONE',
  'UNLOCK_ERR',
  'SR_START',
  'SR_DONE',
  'SR_ERROR',
  'CHANGE_PROPERTY',
  'SR_CHANGE_PROPERTY',
  'TABLE_ROW_SET_ACTIVE',
  'TABLE_ROW_ADD',
  'TABLE_ROW_COPY',
  'TABLE_ROW_SWAP',
  'TABLE_ROW_REMOVE',
  'TABLE_FILL',
  'TURNING_ATTACHED_FILES',
  'PROCESS_ATTACHED_FILES',
  'PROCESS_ADD_FILES',
  'DELETE_ATTACHED_FILES',
  'SET_DP_EDITOR',
  'REGISTER_WARNINGS',
  'CLEAR_WARNINGS',
];

/**
 * @func
 * @param {object} r
 * @param {string} a
 * @return {object}
 */
// const editorActionCreators = (r, a) => ({ ...r, [a]: `@editor/${a}` });
const editorActionCreators = (r, a) => ({ ...r, [a]: `${a}/@editor` });
/**
 * @const
 * @property {string} INIT
 * @property {string} LOAD_START
 * @property {string} LOAD_DONE
 * @property {string} LOAD_ERR
 * @property {string} SAVE_START
 * @property {string} SAVE_DONE
 * @property {string} SAVE_ERROR
 * @property {string} LOCK_START
 * @property {string} LOCK_DONE
 * @property {string} LOCK_ERR
 * @property {string} UNLOCK_STARTF
 * @property {string} UNLOCK_DONE
 * @property {string} UNLOCK_ERR
 * @property {string} PROCESS_START
 * @property {string} PROCESS_DONE
 * @property {string} PROCESS_ERR
 * @property {string} SR_START
 * @property {string} SR_DONE
 * @property {string} SR_ERR
 * @property {string} CHANGE_PROPERTY
 * @property {string} SR_CHANGE_PROPERTY
 * @property {string} TABLE_ROW_SET_ACTIVE
 * @property {string} TABLE_ROW_ADD
 * @property {string} TABLE_ROW_COPY
 * @property {string} TABLE_ROW_SWAP
 * @property {string} TABLE_ROW_REMOVE
 * @property {string} TABLE_FILL
 * @property {string} TURNING_ATTACHED_FILES
 * @property {string} PROCESS_ATTACHED_FILES
 * @property {string} PROCESS_ADD_FILES
 * @property {string} DELETE_ATTACHED_FILES
 * @property {string} SET_DP_EDITOR
 * @property {string} REGISTER_WARNINGS
 * @property {string} CLEAR_WARNINGS
 */
export const editorActions = baseActions.reduce(editorActionCreators, {});

export default editorActions;
