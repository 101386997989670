import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Map } from 'immutable';
import DocCreationBoostraped from '../../dataProcessors/docCreation';
import { processServerReq } from '../../../actions/reportEditor';

function DocCreationModal({
  headerForm,
}) {
  const ref = useRef();
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const okHandler = useCallback(
    () => {
      setOpened(false);
    },
    [],
  );

  const onButtonClick = async () => {
    await dispatch(processServerReq('CreateDoc'));
    setOpened(true);
  };

  return (
    <>
      <Button onClick={onButtonClick} ref={ref}>
        <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
        Створити документ
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Створити документ розпорядника на підставі документу фін.органу
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <DocCreationBoostraped
            onLoad={okHandler}
            dataDocs={headerForm.get('docs', new Map())}
          />
        </Modal.Body>

      </Modal>
    </>
  );
}

DocCreationModal.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
};

export default DocCreationModal;
