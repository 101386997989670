import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { compose } from 'redux';

import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import {
  StyledLabel,
  FrameDiv,
  CommandPanelColor, ThreeColumnsGridContainer,
} from '../../../components/Form/styledForm';

import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import Fields from '../../field';

const md = meta.dp.createElement;

const autoFillName = {
  detKDB: { value: 1, label: 'Деталізація КДБ' },
  detKFB: { value: 2, label: 'Деталізація КФБ' },
  detKEKV: { value: 3, label: 'Деталізація КЕКВ' },
  detFond: { value: 4, label: 'Деталізація фонди' },
  detKFK: { value: 5, label: 'Деталізація КПКВ' },
};

class CreateElementKEKVEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <div>
          <ThreeColumnsGridContainer>
            <div>
              <StyledLabel>{md.columns.Budget.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'Budget'], value));
                }}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.FI.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], value));
                }}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.TypeCat.label}</StyledLabel>
              <RadioButton
                values={Object.values(autoFillName)}
                name="autoFillName"
                value={headerForm.get('TypeCat', 1)}
                onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'TypeCat'], value))}
              />
            </div>
          </ThreeColumnsGridContainer>
          <CommandPanelColor
            style={{ marginTop: '10px' }}
            text="Заповнити деталізацію"
            label="Заповнити деталізацію"
            onClick={() => dispatch(actions.processServerReq('CREATE_EL'))}
          />
        </div>
      </FrameDiv>
    );
  }
}

const enchance = compose(

  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // omitElementKFK',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(CreateElementKEKVEditor);
