/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';

function StampFinanced({ text }) {
  return (
    <svg
      version="1.1"
      id="Слой_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-939 962 121 78"
      xmlSpace="preserve"
      style={{ maxWidth: '400px', fill: 'var(--falcon-success)' }}
    >
      <style type="text/css" />
      <g>
        <path
          className="st0"
          d="M-928.8,1012.5c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1l2.3,6.3l1.8-0.7
		l-2.4-6.5c-0.1,0.1-0.2,0.2-0.3,0.2C-928.4,1012.4-928.6,1012.5-928.8,1012.5z"
        />
        <path
          className="st0"
          d="M-923.2,1012.4l-1.7-4.8l-6,2.2l0.6,1.5h0.1c0.4,0.1,0.4,0.3,0.3,0.5c0,0.2,0,0.3,0.1,0.5
		c0-0.1,0.1-0.2,0.1-0.4c0.1-0.4,0.4-0.5,1.1-0.3c0.2,0,0.3,0.1,0.5,0.1l-0.4-1.2l2.4-0.9l0.6,1.6c0.2,0,0.4,0,0.6,0
		c0.1,0.1,0.2,0.2,0.4,0.4c-0.3-0.1-0.6-0.2-0.9-0.2l2.3,6.2l1.8-0.7L-923.2,1012.4L-923.2,1012.4L-923.2,1012.4z"
        />
        <path
          className="st0"
          d="M-920.3,1015.6c0.3-0.4,0.5-0.7,0.7-1.1c0.4,0.1,0.6,0.1,0.8,0.2l-1-2.7l1.6-0.6c0.7-0.3,1.3-0.8,1.5-1.5
		c0.3-0.7,0.2-1.5,0-2.3c-0.3-0.8-0.7-1.4-1.4-1.7c-0.7-0.4-1.4-0.4-2.1-0.2l-3.4,1.2l1.9,5.1c0.1,0,0.1,0,0.2,0l0,0l0,0l0,0
		c0,0.1,0,0.3,0.1,0.4c0,0,0,0-0.1,0l0.6,1.8c0.3,0.1,0.5,0.2,0.6,0.3C-920.2,1015-920.4,1015.3-920.3,1015.6
		c-0.2-0.1-0.2-0.1-0.3-0.1l0.4,1.1l1.8-0.7l-0.2-0.6C-918.7,1015.5-918.9,1015.5-920.3,1015.6z M-921.3,1007.9l0.9-0.3
		c0.9-0.3,1.5,0,1.9,0.9c0.3,0.9,0.1,1.6-0.8,1.9l-0.9,0.3L-921.3,1007.9z"
        />
        <path
          className="st0"
          d="M-909.3,1005.4c-0.8-2.2-2.2-2.9-4.2-2.1c-2.1,0.7-2.7,2.2-1.9,4.4l1.3,3.4c0.8,2.1,2.2,2.8,4.3,2.1
		c2-0.7,2.7-2.2,1.9-4.3L-909.3,1005.4z M-910.5,1011.7c-0.8,0.3-1.4-0.1-1.8-1.1l-1.4-3.9c-0.4-1.1-0.2-1.7,0.7-2
		c0.8-0.3,1.4,0.1,1.8,1.2l1.4,3.9C-909.4,1010.7-909.6,1011.4-910.5,1011.7z"
        />
        <path
          className="st0"
          d="M-898.6,1003.4c-0.4-1.2-1.1-2-2-2.4c-0.8-0.4-1.6-0.4-2.5-0.1l-0.5,0.2l-0.4-1.1l-1.8,0.7l0.4,1.1l-0.5,0.2
		c-0.7,0.3-1.3,0.7-1.6,1.4c0.2,0.5,0,0.8-0.4,1.1c-0.1,0.7-0.1,1.5,0.3,2.4c0.4,1.2,1.1,2,2,2.4c0.8,0.4,1.6,0.4,2.4,0.1l0.5-0.2
		l0.4,1.2l1.8-0.7l-0.4-1.2l0.5-0.2c0.9-0.3,1.5-0.9,1.8-1.6C-898.2,1005.6-898.2,1004.6-898.6,1003.4z M-903.9,1003.1
		c0-0.2,0-0.3,0-0.4c0.2,0,0.3,0.1,0.5,0.1c-0.4,1.1-0.4,1.1-0.8,1.1c-0.1-0.3-0.2-0.6-0.4-0.9C-904.3,1003-904.1,1003-903.9,1003.1
		z M-903.7,1007.7c-0.9,0.3-1.6-0.2-2.2-1.7c-0.2-0.7-0.3-1.3-0.2-1.8c0.1-0.5,0.4-0.8,0.8-1l0.4-0.2l1.6,4.4L-903.7,1007.7z
		 M-901,1006.7l-0.4,0.2l-1.2-3.4c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0.1c0-0.3-0.1-0.6-0.1-1h0.1l-0.1-0.2l0.4-0.2
		c0.4-0.2,0.8-0.1,1.2,0.3c0.4,0.3,0.7,0.8,1,1.5C-899.9,1005.5-900.1,1006.4-901,1006.7z"
        />

        <rect
          x="-901.6"
          y="1001.8"
          transform="matrix(0.342 0.9397 -0.9397 0.342 352.3235 1502.3015)"
          className="st0"
          width="10.1"
          height="1.9"
        />
        <path
          className="st0"
          d="M-889.7,996.1c-0.1-0.1-0.3-0.2-0.5-0.3c0.1,0,0.2,0,0.4,0l-0.3-0.8l-1.8,0.7l1.3,3.7l-2.4,0.9l-1.3-3.7
		l-1.8,0.7l3.5,9.5l1.8-0.7l-1.6-4.4l2.4-0.9l1.6,4.4l1.8-0.7L-889.7,996.1z"
        />
        <path
          className="st0"
          d="M-887,993.8l0.2,1.6l0,0c0,0.1,0.1,0.3,0,0.4l0,0l0.8,8.4l1.9-0.7l-0.3-2.7l2-0.7l1.3,2.3l2-0.7l-5.6-8.7
		L-887,993.8z M-884.2,1003.1c0.1,0,0.1,0.1,0.1,0.2s-0.2,0.1-0.3,0.1c-0.2,0-0.4,0-0.5,0c-0.3-0.2-0.4-0.3-0.7-0.5
		C-885,1003-884.6,1003-884.2,1003.1z M-884.6,999.3l-0.7-4.7l2.3,4.2L-884.6,999.3z"
        />
        <polygon
          className="st0"
          points="-877.5,990.3 -876.2,994 -878.6,994.9 -879.9,991.2 -881.7,991.9 -878.3,1001.4 -876.5,1000.7
		-878.1,996.3 -875.7,995.4 -874.1,999.8 -872.3,999.2 -875.7,989.7 	"
        />
        <path
          className="st0"
          d="M-873.4,992.3l1.3,3.5c0.8,2.1,2.1,2.8,4.1,2.1c1.9-0.7,2.5-2.1,1.7-4.2l-0.1-0.4l-1.8,0.7l0.2,0.5
		c0.2,0.6,0.3,1.1,0.2,1.4s-0.3,0.5-0.7,0.7c-0.4,0.1-0.7,0.1-1-0.1s-0.5-0.6-0.7-1.2l-1.4-3.8c-0.2-0.6-0.3-1-0.2-1.3
		s0.3-0.5,0.7-0.7c0.4-0.1,0.7-0.1,0.9,0.1c0.3,0.2,0.5,0.6,0.7,1.2l0.1,0.2l1.8-0.7l-0.2-0.5c-0.3-0.9-0.8-1.5-1.5-1.8
		s-1.5-0.3-2.4,0C-873.6,988.7-874.2,990.2-873.4,992.3z"
        />
        <path
          className="st0"
          d="M-865.1,993.2c0.8,2.1,2.2,2.8,4.3,2.1c2-0.7,2.7-2.2,1.9-4.3l-1.3-3.4c-0.8-2.2-2.2-2.9-4.2-2.1
		c-2.1,0.7-2.7,2.2-1.9,4.4L-865.1,993.2z M-863.9,986.8c0.8-0.3,1.4,0.1,1.8,1.2l1.4,3.9c0.4,1,0.2,1.7-0.7,2
		c-0.8,0.3-1.4-0.1-1.8-1.1l-1.4-3.9C-865,987.8-864.8,987.1-863.9,986.8z M-864.7,989.1c-0.4-0.3-0.7-0.5-1-0.7
		C-864.9,988.4-864.6,988.6-864.7,989.1z"
        />
        <path
          className="st0"
          d="M-856.7,993.5l3.6-1.3c0.7-0.3,1.2-0.7,1.3-1.4c0.2-0.6,0.1-1.4-0.2-2.2c-0.3-0.9-0.9-1.5-1.6-1.7
		c-0.5,0.1-1.4-0.1-1.7-0.3c0.5,0,0.9,0,1.3,0.1l0.1-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0.1,0c0.1-0.2,0.2-0.4,0.2-0.7
		c0.1-0.5,0-1-0.1-1.4c-0.2-0.7-0.6-1.1-1.1-1.4c-0.4-0.2-0.9-0.3-1.5-0.2c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0-0.1,0-0.2,0
		c0-0.1-0.1-0.1-0.1-0.2l-3.1,1.1L-856.7,993.5z M-853.7,989.2c0.4,1,0.2,1.6-0.5,1.8l-1.2,0.4l-1.1-2.9l1.2-0.4
		C-854.6,987.9-854.1,988.3-853.7,989.2z M-856.8,984.3c0.7-0.3,1.2,0,1.5,0.8c0.3,0.9,0.1,1.4-0.7,1.7l-0.9,0.3l-0.9-2.5
		L-856.8,984.3z"
        />
        <path
          className="st0"
          d="M-850.4,987.5c0.2,0.1,0.6,0,1.1,0c0.2,0.2-0.1,0.3-0.5,0.3c-0.3,0-0.6-0.1-1-0.1l0.4,3.6l1.9-0.7l-0.3-2.4
		c-0.1,0.2-0.3,0.3-0.4,0.5c0.1-0.5,0.1-1,0.2-1.5c0.4,0.2,0.5,0.4,0.4,0.7l1.8-0.7l1,1.7c0.2,0,0.4,0,0.6,0c0,0,0,0.1,0.1,0.1
		c-0.2,0-0.4,0.1-0.5,0.1l0.2,0.4l2-0.7l-5.6-8.7l-2.4,0.9l0.6,6.2C-850.6,987.2-850.6,987.4-850.4,987.5z M-847.4,985.7l-1.6,0.6
		l-0.7-4.7L-847.4,985.7z"
        />
        <polygon
          className="st0"
          points="-840.8,987.7 -842.4,983.3 -840,982.5 -838.4,986.9 -836.6,986.2 -840.1,976.7 -841.9,977.4
		-840.6,981 -842.9,981.9 -844.3,978.2 -846.1,978.9 -842.6,988.4 	"
        />
        <path
          className="st0"
          d="M-831.2,984c0.1-0.1,0.3-0.1,0.5-0.1c0.8-0.8,0.9-1.9,0.4-3.4l-1.3-3.4c-0.8-2.2-2.2-2.9-4.2-2.1
		c-2.1,0.7-2.7,2.2-1.9,4.4l1.3,3.4c0.8,2.1,2.2,2.8,4.3,2.1c0.4-0.2,0.8-0.3,1.1-0.5C-831.2,984.2-831.2,984-831.2,984z
		 M-832.7,983.4c-0.8,0.3-1.4-0.1-1.8-1.1l-1.4-3.9c-0.4-1.1-0.2-1.7,0.7-2c0.8-0.3,1.4,0.1,1.8,1.2l1.4,3.9
		C-831.7,982.5-831.9,983.1-832.7,983.4z"
        />
        <path className="st0" d="M-875.2,1019.5l-0.3,0.1c0,0.1,0,0.3,0.1,0.4C-875.1,1019.9-875.1,1019.7-875.2,1019.5z" />
        <path
          className="st0"
          d="M-862.2,1015c0-0.1,0-0.1,0.1-0.2L-862.2,1015C-862.3,1014.9-862.3,1015-862.2,1015
		C-862.3,1015-862.3,1015-862.2,1015z"
        />
        <path
          className="st0"
          d="M-861.4,1014.5l-0.7,0.2c0.1,0.1,0.2,0.1,0.7,0.1C-861.4,1014.8-861.4,1014.6-861.4,1014.5z"
        />
        <path
          className="st0"
          d="M-909.6,989.5L-909.6,989.5C-909.7,989.6-909.7,989.6-909.6,989.5C-909.7,989.5-909.7,989.5-909.6,989.5z"
        />
        <path
          className="st0"
          d="M-818.4,994.9l-10.1-27.7c-1.3-3.5-5.2-5.3-8.7-4l-37.4,13.6c0.8,0,1.6,0,2.4,0c0.5,0.2,0.9,0.3,1.3,0.5
		c-0.3,0.2-0.7,0.3-1.2,0.6c0.8,0.2,0.9,0.6,0.8,1l35.1-12.8c1.9-0.7,4.1,0.3,4.8,2.2l5.9,16.2c0.1,0,0.3,0.1,0.4,0.1
		c0.6,0,1.2-0.2,1.5,0.3c-0.3,0.2-0.5,0.4-0.6,0.5c-0.3,0-0.6,0.1-1,0.1l3.8,10.5c0.7,1.9-0.3,4.1-2.2,4.8l-37.5,13.7
		c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6-0.1c0.2,0.1,0.3,0.3,0.6,0.1c0,0,0.2,0,0.2,0.1c0.1,0.2,0.1,0.2,0.4,0.1c0.1,0,0.3,0,0.4,0.1
		c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.4,0.1-0.6,0c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0,0.4,0c-0.1-0.1-0.2-0.1-0.2-0.1l0,0
		c0.2,0,0.4,0.1,0.7,0.1c-0.4,0.2-0.8,0.3-1.3,0.1c-0.1,0-0.1,0-0.2-0.1c-0.2-0.2-0.2-0.2-0.5,0h-0.1c0,0-0.1,0-0.2-0.1
		c0.1,0,0.1-0.1,0.2-0.1c-0.2,0-0.4,0-0.7-0.1c0.2,0.2,0.3,0.4,0.4,0.5c-0.4-0.1-0.5-0.2-0.4-0.3c-0.2,0-0.4,0.1-0.7,0.1
		c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0,0.4-0.1,0.4,0c0.2,0.1,0.3,0.2,0.4,0.3h-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.4,0.1-0.4,0.3-0.4,0.4c-0.1,0-0.3-0.1-0.5-0.2c0,0.1,0.1,0.1,0.1,0.2c-0.1-0.1-0.2-0.1-0.4-0.2c0,0.1,0,0.1,0,0.2
		c-0.1,0-0.2-0.1-0.3-0.1v-0.1c0.2-0.1,0.2-0.2,0.1-0.3c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0-0.1,0c0-0.1,0-0.3,0-0.4h0.1
		c0,0,0,0.1,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0,0v0.1h0.1c0-0.1,0-0.2,0.1-0.3c0,0,0,0-0.1,0l-0.1,0.1c-0.1,0-0.2-0.1-0.4-0.1
		l-0.2,0.1l0,0c-0.1,0-0.2,0.1-0.3,0.1l0,0l-11.3,4.1c-0.2,0.3-0.3,0.7-0.5,1c-0.1,0-0.2,0-0.3,0c0-0.2-0.1-0.4-0.1-0.6l-38.7,14.1
		c0.1,0.1,0.1,0.2,0.2,0.3c-0.4-0.1-0.7,0-1,0l-5.5,2c-1.9,0.7-4.1-0.3-4.8-2.2l-4.1-11.4c-0.1,0-0.1-0.1-0.2-0.1l0.1-0.1l-5.9-16.1
		c-0.7-1.9,0.3-4.1,2.2-4.8l61.8-22.5c0-0.1-0.1-0.2-0.1-0.3l-0.1,0.1c-0.3-0.2-0.6-0.3-1-0.5c0,0.1-0.1,0.2-0.2,0.4
		c-0.4-0.5-0.5-1.1-0.4-1.6c-0.3,0.4-0.8,0.3-1.2,0.2c-0.2-0.1-0.4-0.1-0.6-0.2l-33.6,12.2c0.3,0.1,0.5,0.3,0.9,0.4
		c-0.5,0-0.7,0-1,0.1c0,0.1,0,0.3,0.1,0.5c-0.2-0.2-0.3-0.4-0.4-0.5c-0.3,0.1-0.5,0.2-0.9,0.3c0-0.2,0-0.3,0-0.4l-24.4,8.9
		c-3.5,1.3-5.3,5.2-4,8.7l10.1,27.7c1.3,3.5,5.2,5.3,8.7,4l97.2-35.4C-819,1002.3-817.2,998.4-818.4,994.9z M-869.8,977.5
		c-0.9-0.2-1-0.4-0.5-0.7c0.4,0,0.7,0,1.1,0C-869.4,977-869.6,977.2-869.8,977.5z M-931.7,1023.7c-0.1,0-0.1,0-0.2,0
		c-0.1-0.1-0.2-0.3-0.4-0.4c0.5,0,0.9,0,1.4,0l0.1,0.1C-931,1023.5-931.3,1023.6-931.7,1023.7z M-890.9,1027.7
		c-0.9-0.2-1-0.4-0.5-0.7c0.4,0,0.7,0,1.1,0C-890.5,1027.2-890.7,1027.5-890.9,1027.7z"
        />
      </g>
      <text x="-1220" y="656" textLength="87%" lengthAdjust="spacingAndGlyphs" style={{ transform: 'rotate(340deg)', fill: 'var(--falcon-primary)', fontSize: 'xx-small' }}>{text}</text>
    </svg>
  );
}

StampFinanced.propTypes = {
  text: PropTypes.string,
};

StampFinanced.defaultProps = {
  text: '',
};

export default StampFinanced;
