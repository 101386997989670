import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { StyledField } from '../../../styled';
import Modal from '../../../../../../../components/modal';
import CheckboxField from '../../../../../../field/CheckBox';
import GetNumberInput from '../../../../../../field/NumberInput';
import { StyledLabel } from '../../../../../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../../../../../components/styledInputs';

const SizeInput = GetNumberInput(2, 0, true);
const ScaleInput = GetNumberInput(3, 0, true);

const AllowedFonts = [
  'Arial',
  'Arial Black',
  'Courier New',
  'Georgia',
  'Impact',
  'Times New Roman',
  'Verdana',
];

const optAF = AllowedFonts.map((f) => ({ name: f, label: f }));

function FontField({ value, onChange }) {
  const displayValue = useMemo(
    () => {
      const vv = [];
      if (value.get('Name')) vv.push(value.get('Name'));
      if (value.get('Size')) vv.push(value.get('Size'));
      if (value.get('Bold')) vv.push('напівжирний');
      if (value.get('Italic')) vv.push('похилий');
      if (value.get('Underline')) vv.push('підкреслений');
      if (value.get('Strikeout')) vv.push('закреслений');
      if (value.get('Scale')) vv.push(`${value.get('Scale')}%`);
      return vv.join(',');
    },
    [value],
  );
  const [opened, setOpened] = useState(false);
  return (
    <>
      <StyledField>
        <div>{displayValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Вибір Шрифту" closeIcon opened={opened} onClose={() => setOpened(false)} size="small">
        <>
          <StyledLabel>Шрифт</StyledLabel>
          <InputWithDropdown
            value={value.get('Name', '')}
            options={optAF}
            onChange={(e, v) => onChange(e, value.set('Name', v))}
          />
          <StyledLabel>Розмір</StyledLabel>
          <SizeInput
            value={value.get('Size', 0)}
            onChange={(e, v) => onChange(e, value.set('Size', v))}
            border
            disableCalculator
          />
          <StyledLabel>Масштаб</StyledLabel>
          <ScaleInput
            value={value.get('Scale', 0)}
            onChange={(e, v) => onChange(e, value.set('Scale', v))}
            border
            disableCalculator
          />
          <CheckboxField
            value={value.get('Bold', false)}
            onChange={(e, v) => onChange(e, value.set('Bold', v))}
            label="Жирний"
          />
          <CheckboxField
            value={value.get('Italic', false)}
            onChange={(e, v) => onChange(e, value.set('Italic', v))}
            label="Нахилений"
          />
          <CheckboxField
            value={value.get('Underline', false)}
            onChange={(e, v) => onChange(e, value.set('Underline', v))}
            label="Підкреслений"
          />
          <CheckboxField
            value={value.get('Strikeout', false)}
            onChange={(e, v) => onChange(e, value.set('Strikeout', v))}
            label="Закреслений"
          />
        </>
      </Modal>
    </>
  );
}

FontField.propTypes = {
  value: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
};

FontField.defaultProps = {
  value: new Map(),
};

export default FontField;
