import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {DateInput, ItemPicker, StringInput} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'TABL_60';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights, budget,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const budgetOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="solutionNumber" highlighted={highlights.includes('solutionNumber')}>
              <StringInput
                value={row[tableMD.columns.solutionNumber.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.solutionNumber.name]: v })}
                readOnly={readOnly}
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="solutionDate" highlighted={highlights.includes('solutionDate')}>
              <DateInput
                value={row[tableMD.columns.solutionDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.solutionDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="forceDate" highlighted={highlights.includes('forceDate')}>
              <DateInput
                value={row[tableMD.columns.forceDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.forceDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="dateFrom" highlighted={highlights.includes('dateFrom')}>
              <DateInput
                value={row[tableMD.columns.dateFrom.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.dateFrom.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="code" highlighted={highlights.includes('code')}>
              <StringInput
                value={row[tableMD.columns.code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.code.name]: v })}
                readOnly={readOnly}
                maxLength={19}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="koatuu" highlighted={highlights.includes('koatuu')}>
              <ItemPicker
                value={row[tableMD.columns.koatuu.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.koatuu.name]: v });
                  await onSR('CHANGE_KOATUU_MS_IN_TABLES', { rowId: rowIndex, tableNumber: '60' });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="koatuu"
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="koatuuAto" highlighted={highlights.includes('koatuuAto')}>
              <StringInput
                value={row[tableMD.columns.koatuuAto.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.koatuuAto.name]: v })}
                readOnly={readOnly}
                maxLength={19}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nameAto" highlighted={highlights.includes('nameAto')}>
              <ItemPicker
                value={row[tableMD.columns.nameAto.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.nameAto.name]: v });
                  await onSR('CHANGE_KOATUU_ATO_IN_TABLES', { rowId: rowIndex, tableNumber: '60' });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="koatuu"
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="border-right">
        <TableRowCell column="add" highlighted={highlights.includes('add')}>
          <ItemPicker
            value={row[tableMD.columns.add.name]}
            modelType="doc"
            modelName="taxes4"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.add.name]: v })}
            filter={budgetOwnerFilter}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>

    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.code.name]: PropTypes.string,
    [tableMD.columns.koatuu.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  budget: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
