const frontendURL = '/doc/finclosing/';
const backendURL = '/api/financials/finclosingrequestheader/';
// const printURL = '';
const name = 'Закриття заявок на фінансування';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
