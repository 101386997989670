const typeIndicators = {
  spent: { name: 'Затрат', label: '1 Показники затрат' },
  product: { name: 'Продукта', label: '2 Показники продукту' },
  efficacy: { name: 'Эфективности', label: '3 Показники ефективності' },
  quality: { name: 'Качества', label: '4 Показники якості' },
  capacity: { name: 'Нагрузки', label: 'Показники робочого навантаження' },
  good: { name: 'Полезности', label: 'Показники корисності' },
};

export const typeIndicatorsTransation = Object.values(typeIndicators)
  .reduce((R, { name, label }) => ({
    ...R, [name]: label,
  }), {});

export default typeIndicators;
