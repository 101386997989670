import {
  defaultColumns,
  defaultOrder,
  // hierarchyTypes,
  // reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Назва', name: 'Наименование', visible: true },
  classifierKBP: { label: 'Класифiкатор КБП', name: 'КлассификаторКБП', visible: true },
};

const tables = {
  typesOfRepair: {
    name: 'ВидыРемонта',
    label: 'Вид ремонта',
    columns: {
      number: { label: '№', name: 'НомерСтроки' },
      propertyType: { label: 'Вид ремонта', name: 'ВидРемонта' },
    },
  },
};

export const typesRepairIndustry = {
  label: 'Види ремонту галузі',
  name: 'TypesRepairIndustry',
  backendName: 'ОбъектыСтроительстваОтрасли',
  columns,
  defaultOrder,
  frontend: 'cat/typesRepairIndustry',
  listColumns: [
    ...(new Set([
      // ...reqListerColumns,
      'name',
    ]))],
  tables,
  // hierarchy: true,
  // hierarchyType: hierarchyTypes.foldersNItems,
};

export default typesRepairIndustry;
