import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import { DateRangeField } from '../../../components/fields';
import { StyledLabel, MyContainerFormWithNColumns } from '../../../components/Form/styledForm';
import session from '../../../api/session';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import ImportTP from './tablepart/tpImport';
import ExportTP from './tablepart/tpExport';
import ExportCP from './tablepart/cpExport';
import Tabs from '../../../components/tabs/Tabs';
import CheckboxField from '../../field/CheckBox';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import storePathParam from '../../../common/storePathParam';
import { goToOldVersion } from '../../../actions/dpEditor';

const md = meta.dp.clientBankUGB;

const ExportFormatsOptions = {
  first: {
    label: 'УкрГазБанк',
    value: 1,
  },
  second: {
    label: 'ПриватБанк',
    value: 2,
  },
};

class ClientBankUGB extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.isAdmin = session.get('is_admin');
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      dispatch, headerForm,
    } = this.props;

    return (
      <>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.FI.label}</StyledLabel>
            {this.isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], value));
                  dispatch(actions.changeField(['headerForm', 'account'], new Map()));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('FI', new Map())}
              />
            )}
          </div>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {this.isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('budget', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'budget'], value));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('budget', new Map())}
              />
            )}
          </div>
        </MyContainerFormWithNColumns>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.account.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('account', new Map())}
              modelType="cat"
              modelName="bankAccounts"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'account'], value));
              }}
              filter={[{
                fieldName: 'Владелец',
                value: headerForm.get('FI', new Map()),
              }]}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'period'], value));
              }}
            />
          </div>
        </MyContainerFormWithNColumns>
      </>
    );
  }
}

const ImportTPRenderer = getTPContainer(ImportTP, getResizableCP(<TPCommandPanel tableName="import" />));
const ExportTPRenderer = getTPContainer(ExportTP, getResizableCP(<ExportCP tableName="export" />));

function TablesRender({ exportMarkedAsPaid, exportFormat, dispatch }) {
  return (
    <Tabs>
      <div label={md.tables.export.label}>
        <ExportTPRenderer />
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <CheckboxField
              label={md.columns.exportMarkedAsPaid.label}
              value={exportMarkedAsPaid}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'exportMarkedAsPaid'], value));
              }}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.exportFormat.label}</StyledLabel>
            <RadioButton
              value={exportFormat}
              values={Object.values(ExportFormatsOptions)}
              name="exportFormat"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'exportFormat'], value));
              }}
            />
          </div>
        </MyContainerFormWithNColumns>
      </div>
      <div label={md.tables.import.label}>
        <ImportTPRenderer />
      </div>
    </Tabs>
  );
}

TablesRender.propTypes = {
  exportMarkedAsPaid: PropTypes.bool.isRequired,
  // exportFormat: PropTypes.oneOf(ExportFormatsOptions.map(e => e.value)).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export const mapStateTR = (store) => {
  const editorNode = store.get(`dp/${storePathParam(store).nameDp}/dpEditor`, new Map());
  return {
    exportMarkedAsPaid: editorNode.getIn(['headerForm', 'exportMarkedAsPaid'], false),
    exportFormat: editorNode.getIn(['headerForm', 'exportFormat'], 1),
  };
};

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: connect(mapStateTR)(TablesRender), // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ClientBankUGB);
