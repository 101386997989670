/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import md from '../../../../../../constants/meta/documents/liquidityVer2';

function getMonthName(m) {
  if (m === undefined) return '';
  const d = new Date();
  d.setMonth(m - 1, 1);
  return d.toLocaleString('uk', { month: 'long' });
}

const URL = 'https://eisub.ciat.net.ua/api/forecasts/minfin_data/';
// const URL = 'http://localhost:8000/api/forecasts/minfin_data/';

function Dod1({ data, actions, readOnly }) {
  const month1 = data[md.columns.month1.name];
  const month2 = data[md.columns.month2.name];
  const month3 = data[md.columns.month3.name];
  const [suggestions, setSuggestions] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const dt = useMemo(
    () => {
      if (month1 === undefined) return null;
      const d = new Date(data[md.columns.date.name]);
      d.setHours(0, -d.getTimezoneOffset(), 0);
      d.setMonth(month1 - 2, 1);
      d.setYear(data[md.columns.year1.name]);
      return d.toJSON().substring(0, 10);
    },
    [data, month1],
  );
  const getSuggestion = useCallback(
    (name) => {
      if (!suggestions) return null;
      if (!suggestions[name]) return null;
      return `Попереднє прогнозне значення: ${Number.parseInt(suggestions[name], 10).toLocaleString('uk')} грн`;
    },
    [suggestions],
  );
  useEffect(() => {
    const loader = async () => {
      const message = {
        budget_code: (data[md.columns.budgetCode.name] || '').substring(0, 10),
        kvk_code: (data[md.columns.kvkCode.name] || '').substring(0, 2),
        date: dt,
      };
      const r = await fetch(URL, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(message),
      });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      if (r.status === 204) return null;
      return r.json();
    };
    if (dt !== null) {
      setErr(null);
      loader()
        .then((d) => setSuggestions(d))
        .catch((e) => {
          if (e.message === 'Failed to fetch') setErr("За цей період не вдалось завантажити очікувані обсяги видатків")
            else
          setErr(e.message);
        });
    }
  }, [data, dt]);
  return (
    <>
      {err && (
        <Alert variant="danger" onClose={() => setErr(null)}>
          {err}
        </Alert>
      )}
      <Alert variant="info">
        <Alert.Heading>Увага!</Alert.Heading>
        Для полегшення процесу прийняття рішень щодо очікуваних обсягів видатків,
        під відповідним показником таблиці може бути вказано попереднє прогнозне значення.
        Це оціночне прогнозне значення слід відкоригувати, беручи до уваги актуальну інформацію,
        що є у відповідальних осіб розпорядників бюджетних коштів та МФО.<br/>
        Якщо в результаті платежу кошти будуть спрямовані на рахунки відкриті в комерційних банках,
        то такі очікувані видатки мають відображатися у стовпцях 3, 4 та 5 Додатку. <br/>
        Якщо в результаті платежу кошти будуть спрямовані на рахунки відкриті в Казначействі (в межах єдиного
        казначейського рахунку), то очікувані видатки мають відображатися у стовпцях 6, 7 та 8 Додатку.
      </Alert>
      <Table bordered hover>
        <thead>
          <tr>
            <th rowSpan={2}>
              КЕКВ
            </th>
            <th rowSpan={2}>
              Фонд
            </th>
            <th colSpan={3}>
              платежі, що спрямовуються на рахунки в комерційних банках
              (за межі казначейської системи)
            </th>
            <th colSpan={3}>
              платежі, що спрямовуються на рахунки в Казначействі
            </th>
          </tr>
          <tr>
            <th>{`${getMonthName(month1)}`}</th>
            <th>{`${getMonthName(month2)}`}</th>
            <th>{`${getMonthName(month3)}`}</th>
            <th>{`${getMonthName(month1)}`}</th>
            <th>{`${getMonthName(month2)}`}</th>
            <th>{`${getMonthName(month3)}`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">2110</th>
            <th scope="row">Загальний</th>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G3.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G3.name]: value,
                })}
                description={getSuggestion([md.columns.R01G3.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G4.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G4.name]: value,
                })}
                description={getSuggestion([md.columns.R01G4.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G5.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G5.name]: value,
                })}
                description={getSuggestion([md.columns.R01G5.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G6.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G6.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G7.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G7.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R01G8.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R01G8.name]: value,
                })}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">2220, 2230, 2270, 2281, 2282, 2700</th>
            <th scope="row">Загальний</th>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G3.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G3.name]: value,
                })}
                description={getSuggestion([md.columns.R02G3.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G4.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G4.name]: value,
                })}
                description={getSuggestion([md.columns.R02G4.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G5.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G5.name]: value,
                })}
                description={getSuggestion([md.columns.R02G5.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G6.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G6.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G7.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G7.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R02G8.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R02G8.name]: value,
                })}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">5000 (крім 2620, 3220)</th>
            <th scope="row">Загальний</th>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G3.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G3.name]: value,
                })}
                description={getSuggestion([md.columns.R03G3.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G4.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G4.name]: value,
                })}
                description={getSuggestion([md.columns.R03G4.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G5.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G5.name]: value,
                })}
                description={getSuggestion([md.columns.R03G5.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G6.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G6.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G7.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G7.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R03G8.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R03G8.name]: value,
                })}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              Видатки спеціального фонду, крім видатків
              за рахунок доходів в натуральній формі, а
              також видатків за КЕКВ 2120, 2400, 2620,
              3220
            </th>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G3.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G3.name]: value,
                })}
                description={getSuggestion([md.columns.R04G3.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G4.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G4.name]: value,
                })}
                description={getSuggestion([md.columns.R04G4.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G5.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G5.name]: value,
                })}
                description={getSuggestion([md.columns.R04G5.name.toLowerCase()])}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G6.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G6.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G7.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G7.name]: value,
                })}
              />
            </td>
            <td>
              <EditorControls.NumberInput
                value={data[md.columns.R04G8.name]}
                readOnly={readOnly}
                onChange={(e, value) => actions.onChange({
                  [md.columns.R04G8.name]: value,
                })}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

Dod1.propTypes = {
  data: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

Dod1.defaultProps = {
  readOnly: false,
};
export default Dod1;
