import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  AutoFillFinanceDocs: {
    label: 'Автозаповнення док. фiнансування',
    name: 'АвтоЗаполнениеДокФинансирования',
    visible: true,
  },
  NameForPrinting: {
    label: 'Назва для платiжн. док-тiв',
    name: 'НаименованиеДляПечати',
    visible: true,
  },
  Bank: {
    label: 'Банк',
    name: 'Банк',
    visible: true,
    // type: columnsDataTypes.object,
  },
  name: { label: 'Назва', name: 'Наименование', visible: false },
  nameForLister: { label: 'Назва', name: 'Наименование', visible: true },
  DateCl: { label: 'станом на', name: 'ДатаКл', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  BudgetAdmin: { label: 'Бюджет', name: 'БюджетАдмин', visible: true },
  Parent: { label: 'Розп. вищ. рiвня', name: 'Родитель', visible: true },
  parentVisible: { label: 'Розп. вищ. рiвня (батьківський елемент)', name: 'РодительНаименование', visible: true },
  ParentAdmin: {
    label: 'Розп. вищ. рiвня',
    name: 'РодительАдмин',
    visible: true,
  },
  FinDocType: {
    label: 'ВидДокументаФинансирования',
    name: 'ВидДокументаФинансирования',
    visible: true,
  },
  AlwaysFormByLevelOfFinInstitution: {
    label: 'Завжди формувати за рівнем фінансового органа',
    name: 'ВсегдаФормироватьПоУровнюФинОргана',
    visible: true,
  },
  StewardGroup: {
    label: 'Групування розпорядників',
    name: 'ГруппаРаспорядителя',
    visible: true,
    // type: columnsDataTypes.object,
  },
  ForbidDate: { label: 'Дата заборони', name: 'ДатаЗапрета', visible: true },
  ForbidDateOfOutcomeProject: {
    label: 'Дата заборони змiни документiв "Проект видаткiв"',
    name: 'ДатаЗапретаПроектРасходов',
    visible: true,
  },
  SeparateUsersByDepartments: {
    label: 'ДелитьПользователейПоОтделам',
    name: 'ДелитьПользователейПоОтделам',
    visible: true,
  },
  NoteLength: {
    label: 'Кiлькiсть символiв примiтки у фiн. док.',
    name: 'ДлинаПримечания',
    visible: true,
  },
  NoteLengthFin: {
    label: 'Кiлькiсть символiв примiтки у Пропоз. на фiнансування',
    name: 'ДлинаПримечанияФин',
    visible: true,
  },
  NotShowScratchAndFinDuties: {
    label: 'Не показувати залишок та фін.зобов"язання',
    name: 'ДокФинВидимостьОстФинОбяз',
    visible: true,
  },
  EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: true },
  ID77: { label: 'Ид77', name: 'Ид77', visible: true },
  CityName: { label: 'Назва міста', name: 'ИмяГорода', visible: true },
  RegionName: { label: 'Назва області', name: 'ИмяОбласти', visible: true },
  AreaName: { label: 'Назва району', name: 'ИмяРайона', visible: true },
  UseSignGroup: {
    label: 'Використовувати групи підписів для затвердження документів',
    name: 'ИспользоватьГруппыПодписей',
    visible: true,
  },
  UseIncomePart: {
    label: 'Використовувати дохідну частину',
    name: 'ИспользоватьДоходнуюЧасть',
    visible: true,
  },
  UseFinancialPropose: {
    label: 'Використовувати пропозицiю на фiнансування',
    name: 'ИспользоватьФинансированиеПредложение',
    visible: true,
  },
  UseKievForm: {
    label: 'Використовувати форму Київ',
    name: 'ИспользоватьФормуКиев',
    visible: true,
  },
  ObjBuilding: {
    label: 'Розпис об\'єктів капітального будівництва та ремонту',
    name: 'флОбъектыСтроительства',
    visible: true,
  },
  TreasuryAccountsOfHighestLevelStewards: {
    label: 'Використовувати казначейські рахунки розпорядника вищого рівня',
    name: 'КазначейскиеСчетаРаспорядителяВерхнегоУровня',
    visible: true,
  },
  TreasuryBudgetCode: {
    label: 'Код казначейского файлу (Якщо небхідно)',
    name: 'КодБюджетаКазн',
    visible: true,
  },
  NetworkCode: { label: 'Код Мережа', name: 'КодМережа', visible: true },
  code: { label: 'Код', name: 'Код', visible: false },
  ControlOfReTransferTheApplications: {
    label: 'Перепроведення заявок',
    name: 'КонтрольПереПроведенияЗаявок',
    visible: true,
  },
  CheckApplicationLimitMethod: {
    label: 'МетодПроверкиЛимитовЗаявки',
    name: 'МетодПроверкиЛимитовЗаявки',
    visible: true,
  },
  PlanLimitCheckMethod: {
    label: 'Метод перевірки лімітів. План',
    name: 'МетодПроверкиЛимитовПлан',
    visible: true,
  },
  FinLimitCheckMethod: {
    label: 'Метод перевірки лімітів документів фінансування',
    name: 'ОСР_МетодПроверкиЛимитовФинансирования',
    visible: true,
  },
  FactLimitCheckMethod: {
    label: 'Метод перевірки лімітів. Фінансування',
    name: 'МетодПроверкиЛимитовФакт',
    visible: true,
  },
  LimitKFKAccess: {
    label: 'ОграничиватьДоступКФК',
    name: 'ОграничиватьДоступКФК',
    visible: true,
  },
  LimitStewardsAccess: {
    label: 'ОграничиватьДоступРаспорядители',
    name: 'ОграничиватьДоступРаспорядители',
    visible: true,
  },
  DisableSelectionOfKDBElementSelection: {
    label: 'Відключити відбір елементів КДБ за реквізитом "Розпорядник"',
    name: 'ОтключитьОтборВыбораЭлементовКДБ',
    visible: true,
  },
  DisableSelectionOfKEKVElementSelection: {
    label: 'ОтключитьОтборВыбораЭлементовКЕКВ',
    name: 'ОтключитьОтборВыбораЭлементовКЕКВ',
    visible: true,
  },
  DisableSelectionOfKFBElementSelection: {
    label: 'Відключити відбір елементів КФБ за реквізитом "Розпорядник"',
    name: 'ОтключитьОтборВыбораЭлементовКФБ',
    visible: true,
  },
  DisableSelectionOfKFKElementSelection: {
    label: 'Відключити відбір елементів КФК за реквізитом "Розпорядник"',
    name: 'ОтключитьОтборВыбораЭлементовКФК',
    visible: true,
  },
  DisableSelectionOfFondElementSelection: {
    label: 'Відключити відбір елементів Фонд за реквізитом "Розпорядник"',
    name: 'ОтключитьОтборВыбораЭлементовФонд',
    visible: true,
  },
  DisableRefinancingCheck: {
    label:
      'Вiдключити перевiрку на перефiнансування документiв на фiнансування',
    name: 'ОтключитьПроверкуНаПерефинансирование',
    visible: true,
  },
  DisableRefinancingApplicationsCheck: {
    label: 'Вiдключити перевiрку на перефiнансування заявок на фiнансування',
    name: 'ОтключитьПроверкуНаПерефинансированиеЗаявок',
    visible: true,
  },
  PrintFormsMainSteward: {
    label: 'Використовувати друковані форми головного розпорядника',
    name: 'ПечФормыГлРасп',
    visible: true,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  RecipientOfBudgetCosts: {
    label: 'Отримувач бюджетних коштiв',
    name: 'ПолучательБюджетныхСредств',
    visible: true,
  },
  Prefix: { label: 'Префiкс', name: 'Префикс', visible: true },
  SortByAccountByApplicationsAreLoaded: {
    label:
      'Використовувати відбір по рахунку при завантаженні заявок до документів фінансування',
    name: 'ПриЗагрузкеЗаявокОтбиратьПоСчету',
    visible: true,
  },
  CalculateRemainsOfApplicationByTheOffer: {
    label: 'Розраховувати залишки в заявцi за пропозицiєю фiнансування',
    name: 'РасчетОстатковЗаявкиПоПредложению',
    visible: true,
  },
  DisableOrderAccountingNotes: {
    label: 'Відключити облік заявок по приміткам',
    name: 'ОтключитьУчетЗаявокПоПримечаниям',
    visible: true,
  },
  Sort: { label: 'Код для сортування', name: 'Сорт', visible: true },
  AccountToTheTable: {
    label:
      'Заповнювати рахунки з табличної частини заявки в табличну частину розпорядження (для ФУ)',
    name: 'СчетаВТаблицу',
    visible: true,
  },
  AccountFU: {
    label: 'Використовувати котлові рахунки (для розпорядників коштів)',
    name: 'СчетеФУ',
    visible: true,
  },
  OnlyApprovedPlan: {
    label: 'Використовувати тількі затверджені плани в заявках на фінансування',
    name: 'толькоУтвержденныйПлан',
    visible: true,
  },
  DisableSortOfClassifierElementsChose: {
    label: 'Відключити відбір елементів КЕКВ за реквізитом "Розпорядник"',
    name: 'ОтборВыбораЭлементовКлассификаторов',
    visible: true,
  },
  StewardLevel: {
    label: 'Рiвень розпорядника',
    name: 'УровеньРаспорядителя',
    visible: true,
    // type: columnsDataTypes.object,
  },
  AccountingByBins: {
    label: 'Учет По Корзинам',
    name: 'УчетПоКорзинам',
    visible: true,
  },
  FinancingIncludingKEKV: {
    label: 'По КЕКВ',
    nem: 'ФинансированиеВключаяКЕКВ',
    visible: true,
  },
  FinancingIncludingKFK: {
    label: 'По КФК',
    name: 'ФинансированиеВключаяКФК',
    visible: true,
  },
  FinancingFundsTransfer: {
    label: 'Є передача коштів',
    name: 'ФинансированиеПередачаСредств',
    visible: true,
  },
  FinancingStewardInAHat: {
    label: 'Розпорядник в шапцi',
    name: 'ФинансированиеРаспорядительВШапке',
    visible: true,
  },
  FinancingOrder: {
    label: 'Розпорядження',
    name: 'ФинансированиеРаспоряжение',
    visible: true,
  },
  FinancingDistribution: {
    label: 'Розподіл',
    name: 'ФинансированиеРаспределение',
    visible: true,
  },
  Finances: {
    label: 'Фінансовий орган (для рішення сесії)',
    name: 'Финансирует',
    visible: true,
  },
  AutoCreateDocForBalance: {
    label: 'Автоматично створювати документи для балансу',
    name: 'флАвтСозданиеДокДляБалансаСФпоРаспКДБ_КФБ',
    visible: true,
  },
  BalanceSpecialFondForSteward: {
    label: 'Балансування спец. фонду за розпорядниками КДБ та КФБ',
    name: 'флБалансСФпоРасп_КДБ_КФБ',
    visible: true,
  },
  PullInDocFinAppLowerLevel: {
    label:
      'Завантажувати у документ "Розпорядження/Розподiл" заявки пiдлеглих розпорядникiв',
    name: 'флЗатягиватьВ_ДокФинансированиеЗаявкиНижнУр',
    visible: true,
  },
  PullInDocFinLowerLevel: {
    label:
      'Завантажувати у документ "Фінансування (внесення даних по касових видатках)" документи пiдлеглих розпорядникiв',
    name: 'флЗатягиватьВ_ДокФинансированиеКасаНижнУр',
    visible: true,
  },
  NumerationDokFinancingSeparateAccounts: {
    label: 'флНумерацияДокФинансированиеРаздельноПоСчетам',
    name: 'флНумерацияДокФинансированиеРаздельноПоСчетам',
    visible: true,
  },
  ShowRemainsFinForF5: {
    label: 'Вiдображати залишок фiнансування як (План - Факт за пропозицiєю)',
    name: 'флПоказыватьОстаткиФинПоФ5',
    visible: true,
  },
  DifferentNumerationInstructionsAndCostsTransfer: {
    label: 'Рiзна нумерацiя док "Розпорядження" та "Передача коштiв"',
    name: 'флРазнаяНумерацияРаспИПередачаСр',
    visible: true,
  },
  AllowAnaliseOfFreeBudget: {
    label: 'Дозволити аналiзувати зведений бюджет',
    name: 'флРазрешитьАнализСводногоБюджета',
    visible: true,
  },
  StewardInAHat: {
    label: 'Один плановий документ за розпорядником',
    name: 'флРаспорядительВШапке',
    visible: true,
  },
  CalculationRemainsByFinInstitution: {
    label: 'Залишки за розпорядником вищого рівня',
    name: 'флРасчетОстатовПоФинОргану',
    visible: true,
  },
  ControlStewardNoteLength: {
    label: 'Примусово обмежити довжину примiтки у фiн. док.',
    name: 'флСтрогийКонтрольДлиныПримРаспор',
    visible: true,
  },
  OnlyRegisteredTreasuryAccounts: {
    label: 'Використовувати тільки реєстраційні казнач.рахунки',
    name: 'флТолькоРегисрационныеКазнСчета',
    visible: true,
  },
  AccountOfMovementOfTreasuryAccounts: {
    label: 'Обілик залишків на казначейських рахунках',
    name: 'флУчетДвиженийПоКазнСчетах',
    visible: true,
  },
  AccountOfCommonFondByKDB: {
    label: 'Облiк заг фонду у розрiзi КДБ та КФБ',
    name: 'флУчетЗагФондаПоКДБ',
    visible: true,
  },
  AccountRemainsFinancialApplications: {
    label: 'флУчетОстатковЗаявокНаФинансирование',
    name: 'флУчетОстатковЗаявокНаФинансирование',
    visible: true,
  },
  ActivityObjective: {
    label: 'Мета діяльності',
    name: 'ЦельДеятельности',
    visible: true,
  },
  ElementKVK: { label: 'ЭлементКВК', name: 'ЭлементКВК', visible: true },
  Classifier: { label: 'КВК', name: 'Классификатор', visible: true },
  ThisIsMainSteward: {
    label: 'Головний розпорядник бюджетних коштів',
    name: 'ЭтоГлавныйРаспорядитель',
    visible: true,
  },
  LegalEntity: {
    name: 'ЮридичнаОсоба',
    visible: true,
  },
  AdditionalControlOfTreasuryAccounts: {
    label:
      'Додатковий контроль за казн. рахунками у документах на фiнансування',
    name: 'флДопКонтрольКазнСчетовВ_ДокФинансирование',
    visible: true,
  },
  NameOfAPayer: {
    label: 'НаименованиеПлательщика',
    name: 'НаименованиеПлательщика',
    visible: true,
  },
  KVKCode: {
    label: 'КВК',
    name: 'КВККод',
    visible: true,
  },
  AdressReg: {
    label: 'Адміністративний район',
    name: 'АдресРайон',
    visible: true,
  },
  AdressSt: {
    label: 'Вулиця',
    name: 'АдресУлица',
    visible: true,
  },
  AdressHouse: {
    label: 'Будинок',
    name: 'АдресДом',
    visible: true,
  },
  doNotUseAccounts: { label: 'Не заповнювати рахунок в заявці на фін.', name: 'флНеЗаполнятьРСвЗаявке', visible: true },
  KOATUU: {
    label: 'Код КОАТУУ',
    name: 'КОАТУУ',
    visible: true,
  },
  territory: {
    label: 'Територія',
    name: 'Территория',
    visible: true,
  },
  territotyKoatuu: {
    label: 'КОАТУУ',
    name: 'ТерриторияКОАТУУ',
    visible: true,
  },
  territotyKatottg: {
    label: 'КАТОТТГ',
    name: 'ТерриторияКАТОТТГ',
    visible: true,
  },
  regionBudget: {
    label: 'Бюджет області',
    name: 'флОбласнойБюджет',
    visible: true,
  },
  allowCreatePayment: {
    label: 'Дозволити створювати платіжне доручення без фінансових зобов\'язань',
    name: 'РазрешитьПлатежкуБезФинОбязательств',
    visible: true,
  },
};

const tables = {
  externalReports: {
    label: 'Зовнішні звіти',
    name: 'тчВнешниеОтчеты',
    columns: {
      namber: { label: '№', name: 'НомерСтроки' },
      nameReport: { label: 'Найменування звіту', name: 'ИмяОтчета' },
      report: { label: 'Звіт', name: 'внОтчет' },
      off: { label: 'Увімкнений', name: 'Включен' },
    },
  },
};

const footer = { ...columns };

export const csu = {
  label: 'Розпорядники',
  name: 'csu',
  columns,
  footer,
  backendName: 'Распорядители',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'KVKCode',
      'nameForLister',
      'FullName',
      'parentVisible',
      'Bank',
      'EDRPOU',
      'StewardGroup',
      'NetworkCode',
      'StewardLevel',
    ]),
  ],
  defaultOrder,
  tables,
  frontend: 'cat/CSU',
  hierarchy: true,
  hierarchyType: hierarchyTypes.onlyItems,
  useFoldersLazyLoad: true,
};

export default csu;
