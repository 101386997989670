import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const definition = {
  label: 'Звіт за казначейською випискою',
  name: 'TreasureReportStatement',
  columns,
  frontend: 'rep/TreasureReportStatement',
  backendName: 'КазнВыписка',
  resultColumns: baseResultColumns,
};

export default definition;
