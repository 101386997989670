import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { columnsPropType } from './propTypes';
import { StyledTableRow } from '../../newEditor/tablePart/styles';
import BasicFooterRow from './basicFooterRow';

function BasicTotalFooter({
  columns, tableData, totals, fields, highlights,
}) {
  const row = useMemo(() => {
    const sum = {};
    totals.forEach((key) => {
      sum[key] = tableData.reduce((acc, obj) => acc + (obj[key] || 0), 0);
    });
    return sum;
  }, [totals, tableData]);

  return (
    <StyledTableRow>
      <BasicFooterRow
        row={row}
        highlights={highlights}
        fields={fields}
        columns={columns}
        totals={totals}
      />
    </StyledTableRow>
  );
}

BasicTotalFooter.propTypes = {
  columns: columnsPropType.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({}).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.arrayOf(PropTypes.string),
};

BasicTotalFooter.defaultProps = {
  tableData: [],
  totals: [],
};

export default memo(BasicTotalFooter);
