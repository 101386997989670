import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { docEditorMenu as BasicMenu } from '../../../components/editorMenu/index';
import BasicEditor, { mapState, getRouterEditor } from '../../editor/basic';
import Fields from '../../field';
import GeneralTP from './tablepart/apGeneralTP';
import DocsTP from './tablepart/apDocs';
import CPDocsTP from './tablepart/cpDocs';
import CPGeneralTP from './tablepart/cpGeneral';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import StyledTPPane from '../../../components/tableParts/TPPane';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import { editor as actions } from '../../../actions/index';
import enums from '../../../constants/meta/enums';
import md from '../../../constants/meta/documents/financingCashbox';
import {
  StyledGreyLabel,
  StyledLabel,
  SubText,
  ContainerThreeColumn,
  ContainerTwoColumn,
  DivWithPropsLabel,
  HeaderColumnsGridContainer,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import { InputWithDropdown } from '../../../components/styledInputs';
import DateInput from '../../../components/fields/dates';
import GetTextField from '../../field/TextInput';
import LoadRequestsModal from './loadRequestsModal';
import storePathParam from '../../../common/storePathParam';

const planingKindsOptions = Object.values(enums.PlaningKind);

const NumberInput = GetTextField(10);

const ContainerForTwoColumn = styled(ContainerTwoColumn)`
margin-bottom: 0`;

export class FinancingCashbox extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    headerForm: new Map(),
    sessionOptions: new Map(),
  };

  render() {
    const {
      props: { dispatch, headerForm, sessionOptions },
    } = this;

    const handlerSelector = (selector) => dispatch(actions.processServerReq('LOAD_FROM_DOC_SELECTION', {}, selector));

    const headerUpdater = (...path) => (e, value) => {
      dispatch(actions.changeField(['headerForm', ...path], value));
    };
    const formDisabled = headerForm.get('IsApproved', false) || (headerForm.get('Financed'));
    return (
      <>
        <HeaderColumnsGridContainer>
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.Budget.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], new Map()));
                  dispatch(actions.changeField(['headerForm', 'Budget'], value));
                }}
                disabled={formDisabled}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.Budget.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['Budget', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.FI.label}</StyledLabel>
              <Fields.SelectorField
                required
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                filter={[{ fieldName: 'Бюджет', value: headerForm.get('Budget') }]}
                onChange={headerUpdater('FI')}
                disabled={formDisabled}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.FI.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['FI', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.Author.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Author', new Map())}
                modelType="cat"
                modelName="users"
                onChange={headerUpdater('Author')}
                disabled={formDisabled}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.Author.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['Author', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
        </HeaderColumnsGridContainer>
        <ContainerThreeColumn>
          <ContainerForTwoColumn>
            <div>
              <StyledLabel>{md.columns.RNo.label}</StyledLabel>
              <NumberInput
                background
                radius
                value={headerForm.get('RNo', '')}
                onChange={headerUpdater('RNo')}
                disabled={formDisabled}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.date.label}</StyledLabel>
              <DateInput
                value={headerForm.get('date', null)}
                onChange={headerUpdater('date')}
                disabled={formDisabled}
              />
            </div>
          </ContainerForTwoColumn>
          <div>
            <StyledLabel>{md.columns.PlaningMode.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('PlaningMode')}
              options={planingKindsOptions}
              onChange={(e, v) => {
                headerUpdater('PlaningMode')(e, v);
                dispatch(actions.processServerReq('RENEW_TOTALS_ALL'));
              }}
              disabled={formDisabled}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.FondObject.label}</StyledLabel>
            <Fields.SelectorField
              required
              value={headerForm.get('FondObject', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'FondObject'], value));
                dispatch(actions.processServerReq('FOND_ON_CHANGE'));
              }}
              modelType="cat"
              modelName="elementFond"
              filter={[{ fieldName: 'Владелец', value: headerForm.get('FI', new Map()) }]}
              disabled={formDisabled}
            />
          </div>
        </ContainerThreeColumn>
        <ContainerThreeColumn>
          <div>
            <StyledLabel>{md.columns.FIcode.label}</StyledLabel>
            <NumberInput
              background
              radius
              value={headerForm.get('FIcode', '')}
              onChange={headerUpdater('FIcode')}
              disabled={formDisabled}
            />
          </div>
          <div>
            <StyledLabel>{`${md.columns.FIAccount.label} №${headerForm.get('FIAccNo', '')}`}</StyledLabel>
            <Fields.SelectorField
              required
              value={headerForm.get('FIAccount', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'FIAccount'], value));
                dispatch(actions.processServerReq('CSU_ACCOUNT_ON_CHANGE'));
              }}
              modelType="cat"
              modelName="bankAccounts"
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('FI', new Map()) },
                { fieldName: 'ИспользуетсяДляОбязательств', value: false },
                { fieldName: 'Фонд', value: headerForm.get('Fond', new Map()) },
              ]}
              params={[{
                name: 'НаДату',
                value: headerForm.get('date'),
              }]}
              disabled={formDisabled}
            />
          </div>
          <LoadRequestsModal onOk={handlerSelector} />
        </ContainerThreeColumn>
        <ContainerThreeColumn>
          {headerForm.get('IncludeCSUIntoHead', false) && (
            <div>
              <StyledLabel>{md.columns.CSU.label}</StyledLabel>
              <Fields.SelectorField
                required
                value={headerForm.get('CSU')}
                onChange={headerUpdater('CSU')}
                modelType="cat"
                modelName="csu"
                noHierarchy
                filter={[{ fieldName: '_Родитель', value: headerForm.get('FI', new Map()) }]}
                disabled={formDisabled}
              />
            </div>
          )}
          <div>
            <StyledLabel>{`${md.columns.CSUAccount.label} №${headerForm.get('FIAccNo', '')}`}</StyledLabel>
            <Fields.SelectorField
              required
              value={headerForm.get('CSUAccount', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'CSUAccount'], value));
                dispatch(actions.processServerReq('CSU_ACCOUNT_ON_CHANGE'));
              }}
              modelType="cat"
              modelName="bankAccounts"
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('FI', new Map()) },
                { fieldName: 'ИспользуетсяДляОбязательств', value: false },
                { fieldName: 'Фонд', value: headerForm.get('Fond', new Map()) },
              ]}
              disabled={formDisabled}
              params={[{
                name: 'НаДату',
                value: headerForm.get('date'),
              }]}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('ToHold', false)}
              onChange={headerUpdater('ToHold')}
              label={md.columns.ToHold.label}
            />
            <Fields.CheckboxField
              value={headerForm.get('ImportAllNotes', false)}
              onChange={headerUpdater('ImportAllNotes')}
              label={md.columns.ImportAllNotes.label}
            />
            <Fields.CheckboxField
              value={headerForm.get('WithoutResidueControl', false)}
              onChange={headerUpdater('WithoutResidueControl')}
              label={md.columns.WithoutResidueControl.label}
            />
          </div>
        </ContainerThreeColumn>
      </>
    );
  }
}

const GeneralTPRenderer = getTPContainer(GeneralTP, getResizableCP(<TPCommandPanel extraContent={<CPGeneralTP tableName="general" />} tableName="general" />));
const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(<CPDocsTP tableName="filledFromDocument" />));

export function FinancingCashboxTabs({
  dispatch, Note, Tags, isDisabled,
}) {
  return (
    <Tabs>
      <DivWithPropsLabel label={md.tables.general.label}>
        <StyledTPPane>
          <GeneralTPRenderer disabled={isDisabled} />
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label={md.tables.filledFromDocument.label}>
        <StyledTPPane>
          <DocsTPRenderer disabled={isDisabled} />
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Примітка">
        <StyledTPPane>
          <StyledLabel>{md.columns.Note.label}</StyledLabel>
          <Fields.TextAreaField
            value={Note}
            autoHeight
            onChange={(e, v) => {
              dispatch(actions.changeField(['headerForm', 'Note'], v));
            }}
            disabled={isDisabled}
          />
          <StyledLabel>{md.columns.Tags.label}</StyledLabel>
          <Fields.TextAreaField
            value={Tags}
            autoHeight
            onChange={(e, v) => {
              dispatch(actions.changeField(['headerForm', 'Tags'], v));
            }}
            disabled={isDisabled}
          />
        </StyledTPPane>
      </DivWithPropsLabel>
    </Tabs>
  );
}

FinancingCashboxTabs.propTypes = {
  Note: PropTypes.string.isRequired,
  Tags: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

FinancingCashboxTabs.defaultProps = {
  isDisabled: false,
};

const mapStateTP = (store) => ({
  Note: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm', 'Note'], ''),
  Tags: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm', 'Tags'], ''),
  isDisabled: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm', 'IsApproved'], false) || store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm', 'Financed'], false),
});

const connectedTablesRender = connect(mapStateTP)(FinancingCashboxTabs);

function MainMenu(props) {
  return (
    <BasicMenu
      {...props}
    />
  );
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'doc',
      name: 'financingCashbox',
    },
    {
      menu: MainMenu, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
      canCollapsed: true, // сворачиать Шапку документа
    },
  ),
  // wasModified,
  // hasLoader,
);

export const StdEditor = enhance(FinancingCashbox);

export default getRouterEditor(md)(StdEditor);
