import { baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  DocApproved: { label: 'Документ Затверджено', name: 'ДокументУтвержден' },
};

const tables = {
};

/**
 * @const
 */
const definition = {
  name: 'localDebtPrinting',
  backendName: 'ПечатьМестныеДолги',
  label: 'Друк',
  frontend: 'dp/localDebtPrinting',
  columns,
  tables,
  resultColumns: baseResultColumns,
};

export default definition;
