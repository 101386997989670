import React from 'react';
import PropTypes from 'prop-types';
import { InputWithDropdown } from '../../../../../../../../components/styledInputs';

const horizontalAlign = {
  AUTO: {
    label: 'Автоматично',
    name: 'Auto',
  },
  LEFT: {
    label: 'Притиснути ліворуч',
    name: 'Left',
  },
  JUSTIFY: {
    label: 'За шириною',
    name: 'Justify',
  },
  RIGHT: {
    label: 'Притиснути праворуч',
    name: 'Right',
  },
  CENTER: {
    label: 'Центрувати',
    name: 'Center',
  },
};

const enumValues = Object.values(horizontalAlign);

function HorizontalAlignSelector({ value, onChange, noBorder }) {
  return (
    <InputWithDropdown
      value={value}
      onChange={onChange}
      options={enumValues}
      noBorder={noBorder}
    />
  );
}

HorizontalAlignSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(horizontalAlign).map((v) => v.name)),
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
};

HorizontalAlignSelector.defaultProps = {
  value: horizontalAlign.AUTO.name,
  noBorder: false,
};

export default HorizontalAlignSelector;
