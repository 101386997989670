import React from 'react';

function TIFFIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 303.188 303.188"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <polygon fill="#E8E8E8" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	" />
        <g>
          <circle fill="#A4A9AD" cx="183.686" cy="145.654" r="26.521" />
          <rect x="93.088" y="91.479" fill="#A4A9AD" width="43.947" height="43.947" />
          <polygon fill="#D1D3D3" points="125.476,100.052 188.858,83.067 171.874,146.452 		" />
        </g>
        <g>
          <path
            fill="#A4A9AD"
            d="M109.415,273.871H96.452v-37.379H84.737v-10.6h36.361v10.6h-11.684L109.415,273.871
			L109.415,273.871z"
          />
          <path fill="#A4A9AD" d="M127.958,273.871v-47.979h13.028v47.979H127.958z" />
          <path
            fill="#A4A9AD"
            d="M164.122,273.871h-12.766v-47.979h28.354v10.403h-15.589v9.156h14.374v10.403h-14.374v18.017
			H164.122z"
          />
          <path
            fill="#A4A9AD"
            d="M200.353,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
			L200.353,273.871L200.353,273.871z"
          />
        </g>
        <polygon fill="#38A3FF" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	" />
        <polygon fill="#D1D3D3" points="219.821,50.525 270.346,50.525 219.821,0 	" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export default TIFFIcon;
