import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { switchWindow } from '../../../../actions/windowsManager';

const StyledBoxLink = styled(({ ...r }) => <Link {...r} />)`
    color: #252525;
`;
function PrintModal() {
  const dispatch = useDispatch();
  return (
    <Dropdown.Item>
      <FontAwesomeIcon icon={faPrint} className="me-2" />
      <StyledBoxLink
        to="/rep/Estimate/"
        onClick={() => {
          dispatch(switchWindow('/rep/Estimate/'));
        }}
      >
        Друк Кошторису
      </StyledBoxLink>
    </Dropdown.Item>
  );
}

export default PrintModal;
