const frontendURL = '/cat/reportlabel/';
const backendURL = '/api/reports/label/';
const name = 'Категорії звітів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
