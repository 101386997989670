import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: false },
  owner: { label: 'Показник заборгованості по соц. виплатам', name: 'Владелец', visible: false },
  fullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  codeIntoFile: { label: 'Код в файлі', name: 'КодВФайл', visible: false },
  codeIntoFileLOGICA: { label: 'Код в файлі для ЛОГІКА', name: 'КодВФайлЛОГИКА', visible: false },
};

const tables = {
  kfk: {
    name: 'КФК',
    label: 'Обрахунок з КЕКВ',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      kbpClassifier: { label: 'КБП', name: 'КлассификаторКФК' },
    },
  },
};

export const articlesDebtSocialPayments = {
  label: 'Статті витрат за соц. виплатами',
  name: 'articlesDebtSocialPayments',
  backendName: 'СтатьиДолгСоцВыпл',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/articlesDebtSocialPayments',
  listColumns: [...(new Set([...reqListerColumns, 'fullName']))],
  tables,
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default articlesDebtSocialPayments;
