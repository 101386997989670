import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
// import classNames from 'classnames';
import CountUp from 'react-countup';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import corner1 from '../../../../assets/img/illustrations/corner-3.png';
// import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import Background from '../../../../components/blanks/common/Background';
// import SoftBadge from '../../../../components/blanks/common/SoftBadge';
import ProjectLocation from '../../mapLcation/ProjectLocation';

import { Loader } from '../../../../components/bootStrap';
import useFetchAddition6Data from '../Hooks/useFetchAddition6Data';

function Addition6({ cDt }) {
  const { isLoading, err, budgetProject } = useFetchAddition6Data({ cDt });
  return (
    <>
      {isLoading && (
        <Loader text="Завантаження" />
      )}
      <Row className="g-3 mt-0 mb-2">
        <Col className="d-flex justify-content-between flex-column">

          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner1}
              className="bg-card"
            />
            <Card.Header>
              <div id="spend-zf">

                <div className="d-flex justify-content-around">
                  <div className="flex-column">
                    <div>
                      <h2 className="text-warning mb-0">
                        Обсяг капітального будівництва у
                        {' '}
                        {parseInt(cDt.substring(0, 4), 10)}
                        {' '}
                        році
                      </h2>
                    </div>
                    <div>

                      {/* <h5 className="text-700"> */}
                      {/*  згідно дод.6 до рішення сесії про бюджет */}
                      {/* </h5> */}
                      {/* <div className="d-flex flex-wrap gap-2 align-items-baseline"> */}
                      {/*  <SoftBadge bg="success" pill> */}
                      {/*    <FontAwesomeIcon icon={faCaretUp} /> */}
                      {/*    /!* 2023 - 3 948 351,17 *!/ */}
                      {/*    {budgetProject.construction.percent} */}
                      {/*    % */}
                      {/*  </SoftBadge> */}
                      {/*  <h6 className="text-700"> */}
                      {/*    від */}
                      {/*    {' '} */}
                      {/*    {parseInt(cDt.substring(0, 4), 10) - 1} */}
                      {/*    р. */}
                      {/*  </h6> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div>
                    <h2 className="text-warning mb-0">
                      <CountUp
                        start={0}
                        end={budgetProject.construction.total}
                        duration={2.75}
                        suffix=" тис.грн."
                        prefix=""
                        err={err}
                        separator=" "
                        decimals={1}
                        decimal="."
                      />
                    </h2>
                  </div>
                </div>
              </div>
            </Card.Header>
          </Card>
        </Col>
        {/* <Col md={6} lg={6} xxl={6} className="d-flex justify-content-between flex-column"> */}
        {/*  <Card className="bg-transparent-50 overflow-hidden"> */}
        {/*    <Background */}
        {/*      image={corner2} */}
        {/*      className="bg-card" */}
        {/*    /> */}
        {/*    <Card.Header> */}
        {/*      <div id="spend-sf"> */}
        {/*        <h4 className="text-info mb-0"> */}
        {/*          Обсяг капітального ремонту у */}
        {/*          {' '} */}
        {/*          {parseInt(cDt.substring(0, 4), 10)} */}
        {/*          {' '} */}
        {/*          році */}
        {/*        </h4> */}
        {/*        <h6 className="text-700"> */}
        {/*          згідно дод.6 до рішення сесії про бюджет */}
        {/*        </h6> */}
        {/*        <div className="d-flex flex-wrap gap-2 align-items-baseline"> */}
        {/*          <SoftBadge bg="success" pill> */}
        {/*            <FontAwesomeIcon icon={faCaretUp} /> */}
        {/*            /!* 2023 - 4168606,51 *!/ */}
        {/*            {budgetProject.repair.percent} */}
        {/*            % */}
        {/*          </SoftBadge> */}
        {/*          <h6 className="text-700"> */}
        {/*            від */}
        {/*            {' '} */}
        {/*            {parseInt(cDt.substring(0, 4), 10) - 1} */}
        {/*            р. */}
        {/*          </h6> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </Card.Header> */}
        {/*    <Card.Body className="position-relative"> */}
        {/*      <div */}
        {/*        className={classNames( */}
        {/*          'text-info', */}
        {/*          'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif', */}
        {/*        )} */}
        {/*      > */}
        {/*        <CountUp */}
        {/*          start={0} */}
        {/*          end={budgetProject.repair.total} */}
        {/*          duration={2.75} */}
        {/*          suffix=" тис.грн." */}
        {/*          prefix="" */}
        {/*          separator=" " */}
        {/*          decimals={1} */}
        {/*          decimal="." */}
        {/*        /> */}
        {/*      </div> */}
        {/*    </Card.Body> */}
        {/*  </Card> */}

        {/* </Col> */}
      </Row>
      {/* <LayerComponent /> */}
      <ProjectLocation
        dataAdditional6={budgetProject.building_objects}
        cDt={cDt.substring(0, 4)}
      />
    </>

  );
}
Addition6.propTypes = {
  cDt: PropTypes.string.isRequired,
};
export default Addition6;
