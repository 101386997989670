import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Buttons from './buttons';
import History from './history';
import DisplayToolbar from './displayToolbar';
import * as core from './funcs';
import { StyledCalculator, StyledModal } from './styles';

const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const OPERATORS = ['+', '-', '*', '/', '%'];
const DECIMALS = ['.', ',', 110];
const PARENTHESIS = ['(', ')'];
const ENTERS = ['Enter'];
const DELETES = ['Delete'];
const BACKSPACES = ['Backspace'];

class Calculator extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    value: PropTypes.number.isRequired,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
    opened: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onOk: null,
    onClose: null,
  };

  static getDerivedStateFromProps(props, state) {
    // eslint-disable-next-line
    if (state._value !== props.value) {
      return {
        _value: props.value,
        formula: [],
        history: [],
        input: String(props.value),
        isShowHistory: false,
        afterCalculation: false,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      _value: null,
      formula: [],
      history: [],
      input: '0',
      isShowHistory: false,
      afterCalculation: false,
    };
    this.StyledCalculatorRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.StyledCalculatorRef.current && this.props.opened) {
      this.StyledCalculatorRef.current.focus();
    }
  }

  onDigit = (e, digit) => {
    const { input } = this.state;

    if (this.state.afterCalculation) {
      this.setState({
        input: digit,
        afterCalculation: false,
      });
    } else if (input === '0') {
      this.setState({
        input: digit,
      });
    } else if (core.isNotNumber(input)) {
      this.setState({
        input: digit,
        formula: this.state.formula.concat(input),
      });
    } else {
      this.setState({
        input: input.concat(digit),
      });
    }
  };

  onDecimal = (e, decimal) => {
    const { input } = this.state;

    if (this.state.afterCalculation) {
      this.setState({
        input: `0${decimal}`,
        afterCalculation: false,
      });
    } else if (core.isNotNumber(input)) {
      this.setState({
        input: `0${decimal}`,
        formula: this.state.formula.concat(input),
      });
    } else if (!input.includes(decimal)) {
      this.setState({
        input: input.concat(decimal),
      });
    }
  };

  onOperator = (e, operator) => {
    const { input } = this.state;

    if (core.isOperator(input)) {
      this.setState({
        input: operator,
        afterCalculation: false,
      });
    } else if (input !== '(') {
      this.setState({
        formula: this.state.formula.concat(this.state.input),
        input: operator,
        afterCalculation: false,
      });
    }
  };

  onParenthesis = (e, parenthesis) => {
    const { input } = this.state;

    if (parenthesis === '(') {
      if ((core.isNumber(input) && input !== '0')
        || (core.isNumber(input) && input === '0' && this.state.formula.length > 0)
        || input === ')') {
        this.setState({
          input: parenthesis,
          formula: this.state.formula.concat([input, '*']),
          afterCalculation: false,
        });
      } else if (core.isOperator(input) || input === '(') {
        this.setState({
          input: parenthesis,
          formula: this.state.formula.concat(input),
          afterCalculation: false,
        });
      } else if (core.isNumber(input) && input === '0' && this.state.formula.length === 0) {
        this.setState({
          input: parenthesis,
          afterCalculation: false,
        });
      }
    } else {
      const arrayOpenParenthesis = this.state.formula.join('').match(/\(/g);
      const numOpenParenthesis = arrayOpenParenthesis ? arrayOpenParenthesis.length : 0;

      const arrayCloseParenthesis = this.state.formula.join('').match(/\)/g);
      const numCloseParenthesis = arrayCloseParenthesis ? arrayCloseParenthesis.length : 0;

      if ((core.isNumber(input) || input === ')') && numOpenParenthesis > 0 && numOpenParenthesis > numCloseParenthesis) {
        this.setState({
          input: parenthesis,
          formula: this.state.formula.concat(input),
          afterCalculation: false,
        });
      }
    }
  };

  onClear = () => {
    this.setState({
      formula: [],
      input: '0',
      afterCalculation: false,
    });
  };

  onBackspace = () => {
    const { input } = this.state;
    const { formula } = this.state;
    const currentInputLength = input.length;

    if (input === 'Infinity' || input === '-Infinity' || input === 'NaN') {
      this.setState({
        input: '0',
        afterCalculation: false,
      });
    } else if (currentInputLength > 1) {
      this.setState({
        input: input.slice(0, currentInputLength - 1),
        afterCalculation: false,
      });
    } else if (input !== '0') {
      this.setState({
        input: '0',
        afterCalculation: false,
      });
    } else if (formula.length > 0) {
      this.setState({
        input: formula[formula.length - 1],
        formula: formula.slice(0, formula.length - 1),
        afterCalculation: false,
      });
    }
  };

  onEqual = (e) => {
    const { input, afterCalculation, formula } = this.state;
    const { onOk } = this.props;
    const finalFormula = formula.concat(input);
    const result = core.evaluate(finalFormula);

    if (!Number.isNaN(result)) {
      const newHistoryItem = {
        formula: finalFormula,
        result,
      };

      this.setState({
        input: `${result}`,
        formula: [],
        history: [].concat(newHistoryItem, this.state.history),
        afterCalculation: true,
      });

      if (onOk && afterCalculation) {
        onOk(e, Number(input));
      }
    }
  };

  onHistory = () => {
    this.setState({
      isShowHistory: !this.state.isShowHistory,
    });
  };

  onClearHistory = () => {
    this.setState({
      history: [],
    });
  };

  onHistoryItemClicked = ({ target }) => {
    const number = target.getAttribute('value');
    const { input } = this.state;

    if (core.isNumber(input)) {
      this.setState({
        input: number,
      });
    } else {
      this.setState({
        input: number,
        formula: this.state.formula.concat(input),
      });
    }
  };

  keyPressHandler = (e) => {
    if (DIGITS.includes(e.key)) {
      this.onDigit(e, e.key);
    } else if (DECIMALS.includes(e.key)) {
      this.onDecimal(e, '.');
    } else if (OPERATORS.includes(e.key)) {
      this.onOperator(e, e.key);
    } else if (PARENTHESIS.includes(e.key)) {
      this.onParenthesis(e, e.key);
    } else if (ENTERS.includes(e.key)) {
      this.onEqual(e);
    } else if (DELETES.includes(e.key)) {
      this.onClear(e);
    } else if (BACKSPACES.includes(e.key)) {
      this.onBackspace(e);
    } else {
      // console.log(e.key);
      // console.log(e.charCode);
      // console.log(e.keyCode);
    }
  };

  render() {
    const {
      formula, input, isShowHistory, afterCalculation, history,
    } = this.state;
    const { opened, onClose } = this.props;
    return opened && createPortal(
      (
        <StyledModal onClick={() => onClose && onClose()}>
          <StyledCalculator
            onKeyDown={this.keyPressHandler}
            onClick={(e) => e.stopPropagation()}
          >
            <DisplayToolbar
              formula={formula}
              input={input}
              onBackspace={this.onBackspace}
              onHistory={this.onHistory}
              isShowHistory={isShowHistory}
              ref={this.StyledCalculatorRef}
            />
            <Buttons
              onClear={this.onClear}
              onEqual={this.onEqual}
              onDecimal={this.onDecimal}
              onDigit={this.onDigit}
              onOperator={this.onOperator}
              onParenthesis={this.onParenthesis}
              showOK={afterCalculation}
            />
            <History
              isShowHistory={isShowHistory}
              history={history}
              onHistoryItemClicked={this.onHistoryItemClicked}
              onEqual={this.onEqual}
              onClearHistory={this.onClearHistory}
            />
          </StyledCalculator>
        </StyledModal>
      ), document.body,
    );
  }
}

export default Calculator;
