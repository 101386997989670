import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem, InputGroup, ButtonGroup, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { metaFieldPropType } from '../hooks';

function MetaFieldItem({
  metaField, active, onRenameField, onClick,
  onMoveUp, onMoveDown, canUp, canDown,
}) {
  return (
    <ListGroupItem
      active={active}
      onClick={onClick}
    >
      <TableStingInput
        controlId={`input-metafield-${metaField.itemKey}`}
        value={metaField.name}
        onChange={(e, v) => onRenameField(metaField.name, v)}
        prepend={(
          <InputGroup.Text>{metaField.sort || '!'}</InputGroup.Text>
      )}
        append={(
          <ButtonGroup>
            <Button onClick={onMoveUp} variant="outline-secondary" disabled={!canUp}>
              <FontAwesomeIcon icon={faArrowUp} />
            </Button>
            <Button onClick={onMoveDown} variant="outline-secondary" disabled={!canDown}>
              <FontAwesomeIcon icon={faArrowDown} />
            </Button>
          </ButtonGroup>
      )}
      />
    </ListGroupItem>
  );
}

MetaFieldItem.propTypes = {
  metaField: metaFieldPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  canUp: PropTypes.bool,
  canDown: PropTypes.bool,
};

MetaFieldItem.defaultProps = {
  active: false,
  canUp: false,
  canDown: false,
};

export default MetaFieldItem;
