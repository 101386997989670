import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { CiatAppContext } from '../../../providers';
import api from '../../../api/req';
import { PORTRAIT } from '../../../constants/pageLayouts';
import { downloadFileFromJSON } from '../../../api/utils';

// eslint-disable-next-line import/prefer-default-export
export function usePrintFormEditor({
  backendURL, instanceId = '', autogenerate = false, repr = 'noname', title = null,
}) {
  const [fields, setFields] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [data, setData] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const { auth } = useContext(CiatAppContext);

  const resultRef = useRef();
  useEffect(
    () => {
      if (resultRef.current) {
        if (result) {
          resultRef.current.innerHTML = decodeURIComponent(escape(atob(result)));
        } else {
          resultRef.current.innerHTML = '';
        }
      }
    },
    [result],
  );

  const generate = useCallback(
    async (format = 'html', allHtml = false) => {
      const params = {
        instance_id: instanceId,
        ...data,
        to_format: format,
        all_html: allHtml,
      };
      const r = await api.post(backendURL, auth, params);
      if (!r.ok && r.status !== 400) throw new Error(`${r.status} ${r.statusText}`);
      if (!r.ok) {
        const fe = await r.json();
        setFieldErrors(fe);
        if ('error_data' in fe) {
          const messages = fe.error_data.reduce((R, i) => [...R, ...i.messages], []).join('\n');
          throw new Error(messages);
        }
        if (Array.isArray(fe)) {
          throw new Error(fe.join(', '));
        }
        const e = Object.keys(fe).reduce((R, rr) => `${R} \n ${rr}: ${fe[rr]}`);
        throw new Error(e);
      }
      return r.json();
    },
    [auth, backendURL, data, instanceId],
  );

  const loadOptions = useCallback(
    async () => {
      const r = await api.options(backendURL, auth, { instance_id: instanceId });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, backendURL, instanceId],
  );

  const load = useCallback(
    async () => {
      const r = await api.get(backendURL, auth, { instance_id: instanceId });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, backendURL, instanceId],
  );
  const print = useCallback(
    () => {
      if (resultRef.current) {
        const isPortrait = data.page_layout === PORTRAIT;
        const PageStyle = document.createElement('style');
        PageStyle.innerHTML = `
          @page{
            size: a4 ${isPortrait ? 'portrait' : 'landscape'}
          }
        `;
        document.body.appendChild(PageStyle);
        const el = resultRef.current.cloneNode(true);
        const newEl = document.body.appendChild(el);
        newEl.className = 'print-area';
        window.print();
        document.body.removeChild(PageStyle);
        document.body.removeChild(newEl);
      }
    },
    [data.page_layout],
  );

  // actions
  const onChange = useCallback(
    async (partOfData) => {
      setData((current) => {
        const p = typeof partOfData === 'function' ? partOfData(current) : partOfData;
        return { ...current, ...p };
      });
    },
    [],
  );
  const onGenerate = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      generate('html')
        .then(({ result: newResult, to_format: _, ...rest }) => {
          setData(rest);
          setResult(newResult);
        })
        .catch((e) => setErr(e.message.split('\n').filter((es) => !!es.trim())))
        .finally(() => setLoading(false));
    },
    [generate],
  );

  const filename = useMemo(
    () => {
      let s = repr;
      if (title) s = `${s} - ${title};`;
      return s;
    },
    [repr, title],
  );

  const onGenerateXLSX = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      generate('excel')
        .then((d) => {
          downloadFileFromJSON(d.result, `${filename}.xlsx`);
        })
        .catch((e) => setErr(e.message.split('\n').filter((es) => !!es.trim())))
        .finally(() => setLoading(false));
    },
    [filename, generate],
  );

  const onGenerateHTML = useCallback(
    () => {
      setLoading(true);
      setErr(null);
      generate('html', true)
        .then((d) => {
          downloadFileFromJSON(`data:text/html;base64,${d.result}`, `${filename}.html`);
        })
        .catch((e) => setErr(e.message.split('\n').filter((es) => !!es.trim())))
        .finally(() => setLoading(false));
    },
    [filename, generate],
  );

  const onPrint = useCallback(
    () => print(),
    [print],
  );
  const actions = useMemo(
    () => ({
      onChange,
      onGenerate,
      onGenerateXLSX,
      onGenerateHTML,
      onPrint,
    }),
    [onChange, onGenerate, onGenerateHTML, onGenerateXLSX, onPrint],
  );
  useEffect(
    () => {
      setLoading(true);
      setErr(null);
      loadOptions()
        .then((o) => {
          setFields(o.actions.POST);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [loadOptions],
  );

  useEffect(
    () => {
      if (autogenerate && fields && !loading && !err && !result
        && Object.keys(data).length) onGenerate();
    },
    [autogenerate, data, err, fields, loading, onGenerate, result],
  );

  useEffect(
    () => {
      if (fields) {
        setLoading(true);
        setErr(null);
        load()
          .then((d) => {
            setData(d);
          })
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [fields, load, loadOptions],
  );

  const pageLayoutField = useMemo(
    () => {
      if (fields) {
        return fields.page_layout;
      }
      return null;
    },
    [fields],
  );

  const pageLayoutDisabled = !(pageLayoutField?.choices?.length > 1);
  const onTogglePageLayout = useCallback(
    () => {
      if (pageLayoutField && !pageLayoutDisabled) {
      // eslint-disable-next-line camelcase
        setData(({ page_layout, ...rest }) => ({
          ...rest,
          // eslint-disable-next-line camelcase
          page_layout: page_layout === pageLayoutField.choices[0].value
            ? pageLayoutField.choices[1].value
            : pageLayoutField.choices[0].value,
        }));
      }
    },
    [pageLayoutDisabled, pageLayoutField],
  );

  const pageLayout = useMemo(
    () => ({
      value: data.page_layout || '',
      field: pageLayoutField,
      onToggle: onTogglePageLayout,
      disabled: pageLayoutDisabled,
    }),
    [data.page_layout, onTogglePageLayout, pageLayoutDisabled, pageLayoutField],
  );

  return {
    loading,
    err,
    data,
    result,
    fields,
    fieldErrors,
    actions,
    resultRef,
    pageLayout,
  };
}
