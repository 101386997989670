import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableListerCell from '../tablecell';
// import { defaultColumns } from '../../../../../constants/meta/common';
import HierarchyTogglerCell from './hierarchyToggler';

function TableCatListerRow({
  row, columns, onFocus, selected, tabIndex, onContextMenu,
  permissions, actions, isOpened, className,
}) {
  const onClickHandler = useCallback(
    (e) => {
      onFocus(e, row.id);
    },
    [onFocus, row.id],
  );
  const clsName = useMemo(
    () => {
      let result = `${className} catrow`;
      result += `${selected ? ' selected' : ''} `;
      result += `${row.deleted ? ' deleted' : ''} `;
      return result;
    },
    [className, row.deleted, selected],
  );
  const isFolder = permissions.foldersUsed && row.is_group;
  const canBeOpened = permissions.canHierarchy && (isFolder || !permissions.foldersUsed);
  return (
    <tr
      data-id={row.id}
      onClick={onClickHandler}
      className={clsName}
      tabIndex={tabIndex}
      onDoubleClick={(e) => actions.onRowDoubleClick(e, row.id)}
    >
      {permissions.canHierarchy && (
        <HierarchyTogglerCell
          isFolder={isFolder}
          canBeOpened={canBeOpened}
          onClick={(e) => actions.onToggle(e, row.id)}
          opened={isOpened}
          level={row.level}
        />
      )}
      {columns.map((c) => (
        <TableListerCell
          key={c.key}
          value={row[c.key]}
          type={c.type}
          choices={c.choices}
          onContextMenu={(e) => onContextMenu(e, row.id)}
        />
      ))}
    </tr>

  );
}

TableCatListerRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'string', 'email', 'url', 'integer', 'float', 'decimal', 'choice', 'date', 'datetime', 'nested object', 'boolean', 'field',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    level: PropTypes.number,
    deleted: PropTypes.bool,
    is_group: PropTypes.bool,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
    foldersUsed: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    enEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  isOpened: PropTypes.bool,
  className: PropTypes.string,
};

TableCatListerRow.defaultProps = {
  selected: false,
  isOpened: false,
  className: '',
};
export default memo(TableCatListerRow);
