import React from 'react';
import PropTypes from 'prop-types';
import {
  SaveButton, UndoButton, RedoButton, ExecuteButton, UnExecuteButton, YouTubeButton, HelperButton,
} from '../../../components/bootStrap/buttons';
import PrintFormButton from '../printFormButton';
import { CommandPanel } from '../../../components/bootStrap';
import { useMD } from '../../newLister/hooks/md';

function DocCommandPanel({
  permissions, changed, executed,
  actions, children, printForms, id, backendURL, repr,
}) {
  const md = useMD(backendURL);
  return (
    <CommandPanel
      className="me-1 ms-auto "
      rtl
      leftPart={(
        <>
          <SaveButton
            onClick={actions.onSaveWithoutExit}
            disabled={!permissions.canSave || !changed}
          />
          <ExecuteButton
            onClick={actions.onExecute}
            disabled={!permissions.canExecute}
          />
          <UnExecuteButton
            onClick={actions.onUnexecute}
            disabled={!permissions.canUnexecute || !executed}
          />
          <UndoButton
            onClick={actions.onUndo}
            disabled={!permissions.canUndo}
          />
          <RedoButton
            onClick={actions.onRedo}
            disabled={!permissions.canRedo}
          />
          {printForms && !!printForms.length && (
          <PrintFormButton
            printForms={printForms}
            instanceId={id}
            instanceRepr={repr}
          />
          )}
          {children}
          <YouTubeButton
            urls={md.youtubeVideo}
          />
          <HelperButton
            backendURL={backendURL}
          />
        </>
        )}
    />
  );
}

DocCommandPanel.propTypes = {
  backendURL: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    canSave: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    canClose: PropTypes.bool,
    canExecute: PropTypes.bool,
    canUnexecute: PropTypes.bool,
    canChange: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onSaveWithoutExit: PropTypes.func,
    onSaveNExit: PropTypes.func,
    onExecuteNExit: PropTypes.func,
    onExecute: PropTypes.func,
    onUnexecute: PropTypes.func,
    onUndo: PropTypes.func,
    onRedo: PropTypes.func,
    onClose: PropTypes.func,
    onLoading: PropTypes.func,

  }).isRequired,
  changed: PropTypes.bool,
  executed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
  printForms: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
  })),
  id: PropTypes.string,
  repr: PropTypes.string,
};

DocCommandPanel.defaultProps = {
  changed: false,
  children: null,
  executed: false,
  printForms: null,
  id: null,
  repr: null,
};

export default DocCommandPanel;
