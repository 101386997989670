/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import ElementKFKCommandPanel from './commandpanel';
import ElementKFKListerSelector from './lister_selector';
import { modelType, modelName } from '../def';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({
  CommandPanel: ElementKFKCommandPanel,
}));

function ElementKFKLister(props) {
  return (
    <ElementKFKListerSelector {...props}>
      <StdLister />
    </ElementKFKListerSelector>
  );
}

export default ElementKFKLister;
