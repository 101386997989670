import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
  on3Years, year,
}) {
  const year0 = `${year}p.`;
  const year1 = `${year + 1}p.`;
  const year2 = `${year + 2}p.`;
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'kdb'}
          highlighted={highlights.includes('kdb')}
          onToggleHighlight={() => onToggleHighlight('kdb')}
        >
          {fields.kdb.label}
        </TableHeaderCell>
      </Col>

      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'first_year_zf'}
          highlighted={highlights.includes('first_year_zf')}
          onToggleHighlight={() => onToggleHighlight('first_year_zf')}
        >
          {fields.first_year_zf.label}
          <Badge variant="secondary" className="ms-2">
            {year0}
          </Badge>
        </TableHeaderCell>
        {on3Years && (
        <>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'second_year_zf'}
            highlighted={highlights.includes('second_year_zf')}
            onToggleHighlight={() => onToggleHighlight('second_year_zf')}
          >
            {fields.second_year_zf.label}
            <Badge variant="secondary" className="ms-2">
              {year1}
            </Badge>
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'third_year_zf'}
            highlighted={highlights.includes('third_year_zf')}
            onToggleHighlight={() => onToggleHighlight('third_year_zf')}
          >
            {fields.third_year_zf.label}
            <Badge variant="secondary" className="ms-2">
              {year2}
            </Badge>
          </TableHeaderCell>
        </>
        )}
      </Col>

      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'first_year_sf'}
          highlighted={highlights.includes('first_year_sf')}
          onToggleHighlight={() => onToggleHighlight('first_year_sf')}
        >
          {fields.first_year_sf.label}
          <Badge variant="secondary" className="ms-2">
            {year0}
          </Badge>
        </TableHeaderCell>
        {on3Years && (
        <>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'second_year_sf'}
            highlighted={highlights.includes('second_year_sf')}
            onToggleHighlight={() => onToggleHighlight('second_year_sf')}
          >
            {fields.second_year_sf.label}
            <Badge variant="secondary" className="ms-2">
              {year1}
            </Badge>
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'third_year_sf'}
            highlighted={highlights.includes('third_year_sf')}
            onToggleHighlight={() => onToggleHighlight('third_year_sf')}
          >
            {fields.third_year_sf.label}
            <Badge variant="secondary" className="ms-2">
              {year2}
            </Badge>
          </TableHeaderCell>
        </>
        )}
      </Col>

      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'first_year_br'}
          highlighted={highlights.includes('first_year_br')}
          onToggleHighlight={() => onToggleHighlight('first_year_br')}
        >
          {fields.first_year_br.label}
          <Badge variant="secondary" className="ms-2">
            {year0}
          </Badge>
        </TableHeaderCell>
        {on3Years && (
        <>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'second_year_br'}
            highlighted={highlights.includes('second_year_br')}
            onToggleHighlight={() => onToggleHighlight('second_year_br')}
          >
            {fields.second_year_br.label}
            <Badge variant="secondary" className="ms-2">
              {year1}
            </Badge>
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'third_year_br'}
            highlighted={highlights.includes('third_year_br')}
            onToggleHighlight={() => onToggleHighlight('third_year_br')}
          >
            {fields.third_year_br.label}
            <Badge variant="secondary" className="ms-2">
              {year2}
            </Badge>
          </TableHeaderCell>
        </>
        )}
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
        >
          &#8721;
          <Badge variant="secondary" className="ms-2">
            {year0}
          </Badge>
        </TableHeaderCell>
        {on3Years && (
        <>
          <TableHeaderCell
            className="text-center text-xl-left"
          >
            &#8721;
            <Badge variant="secondary" className="ms-2">
              {year1}
            </Badge>
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
          >
            &#8721;
            <Badge variant="secondary" className="ms-2">
              {year2}
            </Badge>
          </TableHeaderCell>
        </>
        )}
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  on3Years: PropTypes.bool,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  on3Years: false,
  year: 1970,
};

export default Header;
