import React, { useContext } from 'react';
import ListerContext from '../../../newLister/context';
import DBFButton from './DBFButton';

function ListerDBFButton() {
  const {
    selectedRows,
  } = useContext(ListerContext);
  return (
    <DBFButton selectedRows={selectedRows} />
  );
}

export default ListerDBFButton;
