import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  Year: { label: 'Рік планування', name: 'ГодЗатрат' },
  SumDocOut: {
    label: 'Разом трансфертів до інших бюджетів',
    name: 'СуммаДокументаК',
    visible: true,
    type: 'moneys',
  },
  SumDocIn: {
    label: 'Разом трансфертів від інших бюджетів',
    name: 'СуммаДокументаОт',
    visible: true,
    type: 'moneys',
  },
};

/**
 * @const
 */
const tables = {
  transfertFrom: {
    name: 'ТрансфертиОтДругих',
    label: 'Трансферти з інших місцевих бюджетів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      kdbClassifier: { label: 'КДБ', name: 'КлассификаторКДБ' },
      nameKDB: { label: 'Найменування трансферту', name: 'НаимТрансферта', visible: true },
      budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
      zf: { label: 'Заг.ф.', name: 'ЗФ' },
      sf: { label: 'Спец.ф.', name: 'СФ' },
      sum: { label: 'Усього', name: 'Сумма' },
    },
  },
  transfertTo: {
    name: 'ТрансфертиДругим',
    label: 'Трансферти іншим місцевим бюджетам',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KBP7Code: { label: 'Код КПКВ', name: 'КодКПКВ' },
      budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
      zf: { label: 'Заг.ф.', name: 'ЗФ' },
      sf: { label: 'Спец.ф.', name: 'СФ' },
      sum: { label: 'Усього', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Проект рішення сесії. Додаток 5 (Міжбюджетні трансферти)',
  name: 'draftAnnualTransfert',
  backendName: 'ПроектТрансфертов',
  columns,
  frontend: 'doc/draftAnnualTransfert',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'SumDocOut',
      'SumDocIn',
      'variantBudgetDraft',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
