import React, {
  useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import PrintingEditor from '../../../dataProcessors/taxes2Print/editor';

function PrintModal({ selectedRows, docType }) {
  const [opened, setOpened] = useState(false);

  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
        docType,
      },
    }),
    [docType, selectedRows],
  );
  return (
    <>
      <Dropdown.Item onClick={() => setOpened(true)} disabled={selectedRows.length === 0}>
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Друкована форма
      </Dropdown.Item>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друкована форма
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintingEditor
            mapInitialOptions={{
              Document: 'ДокументСсылка',
            }}
            mapStore={mapStore}
          />

        </Modal.Body>
      </Modal>
    </>
  );
}

PrintModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  docType: PropTypes.arrayOf(PropTypes.string),
};

PrintModal.defaultProps = {
  selectedRows: [],
  docType: 'taxes2',
};

export default PrintModal;
