import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/docRegistr';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.consist;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>
          <Col className="border-right" md={10}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'doc'}
              highlighted={highlights.includes('doc')}
              onToggleHighlght={() => onToggleHighlght('doc')}
            >
              {meta.columns.doc.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
