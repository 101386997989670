import React, { memo } from 'react';

import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">

      <Col className="border-right">
                &nbsp;
      </Col>
      <Col className="text-right">
        <TableHeaderCell active={activeCol === 'sum_2'} highlighted={highlights.includes('sum_2')}>
          {totals.sum_2.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="text-right">
        <TableHeaderCell active={activeCol === 'sum_1'} highlighted={highlights.includes('sum_1')}>
          {totals.sum_1.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="text-right">
        <TableHeaderCell active={activeCol === 'sum0'} highlighted={highlights.includes('sum0')}>
          {totals.sum0.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="text-right">
        <TableHeaderCell active={activeCol === 'sum1'} highlighted={highlights.includes('sum1')}>
          {totals.sum1.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col className="text-right">
        <TableHeaderCell active={activeCol === 'sum2'} highlighted={highlights.includes('sum2')}>
          {totals.sum2.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  totals: PropTypes.shape({
    sum_2: PropTypes.number,
    sum_1: PropTypes.number,
    sum0: PropTypes.number,
    sum1: PropTypes.number,
    sum2: PropTypes.number,
  }).isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
