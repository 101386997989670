import React, {
  memo, useCallback, useEffect, useLayoutEffect, useMemo,
  useRef, useState,
} from 'react';
import {
  Alert, Button, Card, Collapse, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faCertificate,
  faCheckDouble, faEye, faKey, faTimes, faUser,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Loader } from '../../components/bootstrap_components';
import { EditorControls } from '../../components/bootstrap_components/editorControls';
import { LIBRARY_TYPES } from '../../ciatEU/hook';
import MediaType from './mediaType';

function Signer({
  submitTitle, loading, err, initialized,
  onReadKeyFile, onReadKeyMedia,
  cert, onSubmit, onReset, libraryType, onSetLibraryType,
  keyMedias, selectedKM, setSelectedKM,
  issuers, issuer, setIssuer,
  needCertificate, certificates, setCertificates, reloadKeyMedia,
}) {
  const inputFileRef = useRef();
  const inputCertRef = useRef();
  const [keyFile, setKeyFile] = useState(null);
  const [password, setPassword] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const loadKeyFile = useCallback(
    (e) => {
      if (e.target.files.length === 1) {
        const file = e.target.files[0];
        setKeyFile(file);
        onReset();
      }
    },
    [onReset],
  );
  const loadCertificates = useCallback(
    (e) => {
      setCertificates([...e.target.files]);
    },
    [setCertificates],
  );
  const fileName = useMemo(
    () => {
      if (keyFile) return keyFile.name;
      return '';
    },
    [keyFile],
  );
  const readKey = useCallback(
    () => {
      switch (libraryType) {
        case LIBRARY_TYPES.file:
          onReadKeyFile(keyFile, password);
          break;
        case LIBRARY_TYPES.km:
          onReadKeyMedia(password);
          break;
        default:
          throw new Error('Not implemented');
      }
    },
    [keyFile, libraryType, onReadKeyFile, onReadKeyMedia, password],
  );
  const errRef = useRef();
  const oldErr = useRef();
  useLayoutEffect(() => {
    if (errRef.current && err && !oldErr.current) errRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [err]);
  useEffect(() => {
    oldErr.current = err;
  }, [err]);
  const issuerValues = useMemo(
    () => [
      {
        value: null,
        display_name: 'Визначити автоматично',
      },
      ...issuers.map((c) => ({ value: c, display_name: c })),
    ],
    [issuers],
  );
  const displayCertificates = useMemo(
    () => certificates.map((c) => c.name).join(', '),
    [certificates],
  );
  return (
    <>
      <input type="file" onChange={loadKeyFile} className="d-none" ref={inputFileRef} />
      {needCertificate && (
        <input type="file" onChange={loadCertificates} className="d-none" ref={inputCertRef} multiple />
      )}
      {loading && <Loader />}
      {err && (
        <Alert variant={err.type} ref={errRef}>
          {err.message}
          {err.url && (
            <>
              <hr />
              <Button href={err.url} variant={err.type} target="_blank">
                Завантажити
              </Button>
            </>
          )}
        </Alert>
      )}
      <Collapse in={initialized}>
        <div>
          <div className="d-flex gap-4 justify-content-center">
            {Object.values(LIBRARY_TYPES).map((type) => (
              <MediaType
                type={type}
                selected={libraryType === type}
                onSelect={() => onSetLibraryType(type)}
              />
            ))}
          </div>
          <EditorControls.SelectorInput
            label="Кваліфікований надавач ел. довірчих послуг:"
            value={issuer}
            values={issuerValues}
            onChange={(e, v) => setIssuer(v)}
          />
        </div>
      </Collapse>
      <Collapse in={initialized && libraryType === LIBRARY_TYPES.file}>
        <div>
          <EditorControls.StringInput
            className="bg-white"
            value={fileName}
            readOnly
            label="Файл з ключем"
            description="За звичай це файл Key6.dat або *.jks"
            onChange={() => null}
            append={(
              <>
                {!fileName ? (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    disabled={!initialized}
                    onClick={() => inputFileRef.current.click()}
                  >
                    <FontAwesomeIcon icon={faKey} className="me-2" bounce />
                    Завантажити файл з ключем
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => inputFileRef.current.click()}
                      disabled={!initialized}
                    >
                      <FontAwesomeIcon icon={faKey} className="me-2" />
                      Завантажити інший файл з ключем
                    </Button>
                    <Button
                      variant="outline-warning"
                      size="sm"
                      onClick={() => {
                        setKeyFile(null);
                        setPassword('');
                        onReset();
                        inputFileRef.current.value = '';
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="me-2" />
                      Очиcтити
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </Collapse>
      <Collapse in={libraryType === LIBRARY_TYPES.km && keyMedias}>
        <div>
          <div className="d-flex gap-2 align-items-end">
            <div className="flex-fill">
              <EditorControls.SelectorInput
                label="Оберіть ключ"
                value={selectedKM}
                onChange={(e, v) => setSelectedKM(v)}
                values={keyMedias}
                required
              />
            </div>
            <div>
              <OverlayTrigger
                placement="auto-end"
                overlay={(
                  <Tooltip>
                    Натисніть кнопку для оновлення переліку ключів у списку, якщо була проведена
                    його
                    заміна або ключ був вставлений після завантаження цієї сторінки
                  </Tooltip>
                )}
              >
                <Button
                  size="sm"
                  className="mt-2"
                  variant="outline-primary"
                  title="Оновити перелік ключів"
                  onClick={() => reloadKeyMedia()}
                >
                  Оновити перелік ключів
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse in={needCertificate}>
        <div>
          <EditorControls.StringInput
            className="bg-white"
            value={displayCertificates}
            readOnly
            label="Сертіфікат(и)"
            onChange={() => null}
            append={(

              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => inputCertRef.current.click()}
              >
                <FontAwesomeIcon icon={faCertificate} className="me-2" bounce={!certificates.length} />
                Завантажити сертифікат(и)
              </Button>

            )}
          />

        </div>
      </Collapse>
      <Collapse in={!!keyFile || (libraryType === LIBRARY_TYPES.km && !!selectedKM)}>
        <div>
          <EditorControls.StringInput
            label="Пароль"
            value={password}
            onChange={(e, v) => setPassword(v)}
            type={showPwd ? '' : 'password'}
            append={(

              <Button variant="secondary" onClick={() => setShowPwd((o) => !o)} size="sm">
                <FontAwesomeIcon icon={faEye} alt="Показати / приховати пароль" />
              </Button>

            )}
          />
          {cert ? (
            <div className="btn-success d-inline-block px-3 py-2 font-weight-bold fs-2 rounded my-2">
              <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
              Зчитано
            </div>
          ) : (
            <Button
              variant="outline-success"
              disabled={!password}
              className="mt-3"
              onClick={readKey}
            >
              Зчитати ключ
            </Button>
          )}
        </div>
      </Collapse>
      <Collapse in={!!cert}>
        <Card>
          <Card.Body>
            {cert && (
              <div>
                <h4>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  <b>{cert.subjFullName}</b>
                </h4>
                <p>
                  <FontAwesomeIcon icon={faBuilding} className="me-2" />
                  {cert.subjTitle ? (
                    <>
                      <b className="me-2">{cert.subjTitle}</b>
                      в
                      <b className="ms-2">{cert.subjOrg}</b>
                    </>
                  ) : (
                    <b>{cert.subjOrg}</b>
                  )}
                </p>
              </div>
            )}
          </Card.Body>
          <Card.Footer>
            <Button variant="success" size="lg" onClick={onSubmit}>
              {submitTitle}
            </Button>
          </Card.Footer>
        </Card>
      </Collapse>
    </>
  );
}

Signer.propTypes = {
  loading: PropTypes.bool,
  err: PropTypes.shape({
    type: PropTypes.oneOf(['danger', 'warning']),
    message: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
  initialized: PropTypes.bool,
  submitTitle: PropTypes.string,
  libraryType: PropTypes.oneOf([
    LIBRARY_TYPES.file,
    LIBRARY_TYPES.km,
    LIBRARY_TYPES.ksp,
  ]),
  onReadKeyFile: PropTypes.func.isRequired,
  onReadKeyMedia: PropTypes.func.isRequired,
  cert: PropTypes.shape({
    isFilled: PropTypes.bool,
    issuer: PropTypes.string,
    issuerCN: PropTypes.string,
    serial: PropTypes.string,
    subjAddress: PropTypes.string,
    subjCN: PropTypes.string,
    subjDNS: PropTypes.string,
    subjDRFOCode: PropTypes.string,
    subjEDRPOUCode: PropTypes.string,
    subjEMail: PropTypes.string,
    subjFullName: PropTypes.string,
    subjLocality: PropTypes.string,
    subjOrg: PropTypes.string,
    subjOrgUnit: PropTypes.string,
    subjPhone: PropTypes.string,
    subjState: PropTypes.string,
    subjTitle: PropTypes.string,
    subject: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSetLibraryType: PropTypes.func.isRequired,
  keyMedias: PropTypes.shape({}),
  selectedKM: PropTypes.string,
  setSelectedKM: PropTypes.func.isRequired,
  issuers: PropTypes.arrayOf(PropTypes.string).isRequired,
  issuer: PropTypes.string,
  setIssuer: PropTypes.func.isRequired,
  needCertificate: PropTypes.bool,
  certificates: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  setCertificates: PropTypes.func.isRequired,
  reloadKeyMedia: PropTypes.func.isRequired,
};

Signer.defaultProps = {
  loading: false,
  submitTitle: 'Зчитати',
  err: null,
  initialized: false,
  cert: null,
  libraryType: LIBRARY_TYPES.file,
  keyMedias: null,
  selectedKM: null,
  issuer: null,
  needCertificate: false,
  certificates: [],
};

export default memo(Signer);
