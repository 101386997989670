import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/prepareForecastAdd4';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from './docSelectionModal';

function PrepareForecastAdd4({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LoadDocs', loadData);
    },
    [actions],
  );

  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {sessionOptions.get('is_admin', false) && (
        <Row>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isGet-${data.id}`}
              label={md.columns.isGet.label}
              value={data[md.columns.isGet.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isGet.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.csuA.label}
            value={data[md.columns.csuA.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.csuA.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            noHierarchy
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.noteZF.label}
            value={data[md.columns.noteZF.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.noteZF.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.noteSF.label}
            value={data[md.columns.noteSF.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.noteSF.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`usePCMCatalog-${data.id}`}
            label={md.columns.usePCMCatalog.label}
            value={data[md.columns.usePCMCatalog.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.usePCMCatalog.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`inFill-${data.id}`}
            label={md.columns.inFill.label}
            value={data[md.columns.inFill.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.inFill.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

PrepareForecastAdd4.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
    [md.columns.csuA.name]: referencePropType,
    [md.columns.noteSF.name]: PropTypes.string,
    [md.columns.noteZF.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PrepareForecastAdd4;
