import PropTypes from 'prop-types';
import {
  faCheck,
  faFile, faMeh, faSmile,
  faThumbsDown,
  faThumbsUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useMemo } from 'react';

function FilteredTableCell({ selected, clmn, value }) {
  const canSigned = (clmn.code === '_ACTUAL') || (clmn.code === '_SIGNEDBYFINDEP');
  const canVerified = (clmn.code === 'success');
  const canApproved = (clmn.code === '_PROJAPPROVED');
  const fromOurBase = (clmn.code === 'docInOurBase');

  const textClassName = useMemo(
    () => {
      if (selected) return 'text-white';
      if (canSigned) return value === 'Так' ? 'text-success' : 'text-danger';
      if (canVerified) return value === 'Верифіковано' ? 'text-success' : 'text-danger';
      if (canApproved) return value === 'Затверджено' ? 'text-primary' : 'text-warning';
      if (fromOurBase) return value === 'Так' ? 'text-success' : 'text-primary';
      return '';
    },
    [canApproved, canSigned, canVerified, selected, value, fromOurBase],
  );

  const fwClassName = useMemo(
    () => {
      if (canSigned && value === 'Так') return 'fw-bold';
      return '';
    },
    [canSigned, value],
  );

  const Icon = useMemo(
    () => {
      if (canVerified && value === 'Верифіковано') {
        return (
          <FontAwesomeIcon icon={faCheck} mask={faFile} className="me-2" size="lg" />
        );
      }
      if (canVerified && value === 'З помилками') {
        return (
          <FontAwesomeIcon icon={faTimes} mask={faFile} className="me-2" size="lg" />
        );
      }
      if (canSigned && value === 'Так') {
        return (
          <FontAwesomeIcon icon={faThumbsUp} className="me-2" size="lg" />
        );
      }
      if (canSigned && value === 'Ні') {
        return (
          <FontAwesomeIcon icon={faThumbsDown} className="me-2" size="lg" />
        );
      }
      if (canApproved && value === 'Затверджено') {
        return (
          <FontAwesomeIcon icon={faSmile} className="me-2" size="lg" />
        );
      }
      if (canApproved && value === 'Проект') {
        return (
          <FontAwesomeIcon icon={faMeh} className="me-2" size="lg" />
        );
      }
      if (canApproved && value === 'Проект') {
        return (
          <FontAwesomeIcon icon={faMeh} className="me-2" size="lg" />
        );
      }
      if (fromOurBase && value === 'Так') {
        return (
          <FontAwesomeIcon icon={faCheck} mask={faFile} className="me-2" size="lg" />
        );
      }
      if (fromOurBase && value === 'Ні') {
        return (
          <FontAwesomeIcon icon={faTimes} mask={faFile} className="me-2" size="lg" />
        );
      }
      return false;
    },
    [canApproved, canSigned, canVerified, fromOurBase, value],
  );
  const disaplyValue = useMemo(() => {
    if (['lastEditDate', 'addDate'].includes(clmn.code)) {
      return new Date(value).toLocaleString('uk', {
        day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit',
      });
    }
    return value;
  }, [clmn.code, value]);

  const className = useMemo(
    () => `${textClassName} ${fwClassName}`,
    [fwClassName, textClassName],
  );

  return (
    <td className={className}>
      {Icon}
      {disaplyValue}
    </td>
  );
}

FilteredTableCell.propTypes = {
  selected: PropTypes.bool,
  clmn: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FilteredTableCell.defaultProps = {
  selected: false,
  value: null,
};

export default memo(FilteredTableCell);
