import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import { availableColumnsPropType, columnsPropType, getPreviousColumn } from '../../hooks';
import SKDColumnsList from './columnsList';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
  IntoButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';

function ColumnsEditor({ availableColumns, columns, columnsHandlers }) {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableColumns.reduce((R, col) => [
    ...R,
    col.parent === null && {
      ...col,
      lvl: 0,
      hasChild: !!availableColumns.filter((col2) => col2.parent === col.name).length,
    },
    ...availableColumns.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1, hasChild: false })),
  ], []).filter((col) => col), [availableColumns, openedColumns]);

  const onActiveColumnHandler = useCallback(
    (cname) => setActiveColumn(cname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const columnDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        columnsHandlers.addColumnHandler(name, gname);
      } else if (type === itemTypes.column) {
        columnsHandlers.swapColumnRowHandler(name, gname);
      }
    },
    [columnsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.column) {
        columnsHandlers.removeColumnHandler(name);
        if (activeColumn === name) setActiveColumn(null);
      }
    },
    [activeColumn, columnsHandlers],
  );
  return (
    <div className="d-flex h-100 overflow-hidden">
      <div className="overflow-hidden flex-fill w-50">
        <AvailableFieldList
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={columnsHandlers.addColumnHandler}
          cardTitle="Доступні для группування колонки"
          cardText={(
            <p className="text-justify mb-0">
              дозволяє організувати та підсумовувати ваші дані за кількома рівнями, що
              робить аналіз складних даних більш зручним та гнучким.
              Перетягніть нижче наведені поля на область
              <b className="mx-1">&quot;Обрані для группування колонки&quot;</b>
              .
              Згруповані дані з підсумками легше представити у вигляді звітів та презентацій.
            </p>
          )}
          onDragEnd={avaDragEndHanlder}
          setOpenedItems={onSetOpenedColumns}
          visibleItems={visibleColumns}
          openedItems={openedColumns}
        />
      </div>
      <div className="overflow-hidden d-flex align-items-center px-2 ">
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => {
              columnsHandlers.clearAllColumnsHandler();
              setActiveColumn(null);
            }}
            disabled={!columns.length}
            title="Очистити"
          />
          <LeftButton
            onClick={() => {
              columnsHandlers.removeColumnHandler(activeColumn);
              setActiveColumn(getPreviousColumn(columns, activeColumn));
            }}
            disabled={activeColumn === null}
            title="Видалити"
          />
          <RightButton
            onClick={() => columnsHandlers.addColumnHandler(activeAvaField, activeColumn)}
            disabled={!activeAvaField}
            title="Додати"
          />
          <IntoButton
            onClick={() => columnsHandlers.insertSubcolumnHandler(activeAvaField, activeColumn)}
            disabled={!activeAvaField}
            title="Згрупувати"
          />
        </ButtonGroup>
      </div>
      <div className="overflow-hidden flex-fill w-50">
        <SKDColumnsList
          columns={columns}
          activeColumn={activeColumn}
          onClick={onActiveColumnHandler}
          onDblClick={(name) => {
            columnsHandlers.removeColumnHandler(name);
            setActiveColumn(getPreviousColumn(columns, name));
          }}
          cardTitle="Обрані для группування колонки"
          cardText={(
            <p className="text-justify mb-0">
              Групування дозволяє розбити ваші дані на логічні категорії, що робить їх
              більш зрозумілими та легкими для аналізу. Ви можете групувати по одному або
              декількох полях одночасно, перетягуючи їх послідовно у цю область.
              Ви можете налаштувати структуру групування та підсумки,
              щоб отримати необхідні результати.
            </p>
          )}
          onDragEnd={columnDragEndHanlder}
          onSubColumnClick={columnsHandlers.removeSubcolumnHandler}
          onChangeHierarchy={columnsHandlers.onChangeHierarchyHandler}
        />
      </div>
    </div>
  );
}

ColumnsEditor.propTypes = {
  availableColumns: availableColumnsPropType.isRequired,
  columns: columnsPropType.isRequired,
  columnsHandlers: PropTypes.shape({
    addColumnHandler: PropTypes.func,
    removeColumnHandler: PropTypes.func,
    swapColumnRowHandler: PropTypes.func,
    insertSubcolumnHandler: PropTypes.func,
    removeSubcolumnHandler: PropTypes.func,
    clearAllColumnsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
  }).isRequired,
};

export default ColumnsEditor;
