import { compose } from 'redux';
import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import TableComponents from './docsTP';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'tpDoc';

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const enhance = compose(
  connect(mapState),
  getBaseTP,
);

export default enhance(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
);
