import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useDublicates from './hook';
import IconAlert from '../../../../components/blanks/common/IconAlert';

function ShowDublicatesWarning({ rows, dublicateKeys }) {
  const { dublicates } = useDublicates({ rows, dublicateKeys });
  if (dublicates.length === 0) return null;
  return (
    <IconAlert variant="warning">
      <Alert.Heading>Знайдені стрічки з однаковими показниками</Alert.Heading>
      Це не є помилкою, але варто звернути увагу,
      щоб Ви не ввели одні і теж самі данні бульше одно разу
    </IconAlert>
  );
}

ShowDublicatesWarning.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dublicateKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ShowDublicatesWarning;
