const toponimTypes = {
  val1: { name: 0, label: 'Алея' },
  val2: { name: 1, label: 'Бульвар' },
  val3: { name: 2, label: 'Вулиця' },
  val4: { name: 3, label: 'Дорога' },
  val5: { name: 4, label: 'Лінія' },
  val6: { name: 5, label: 'Майдан' },
  val7: { name: 6, label: 'Набережна' },
  val8: { name: 7, label: 'Площа' },
  val9: { name: 8, label: 'Провулок' },
  val10: { name: 9, label: 'Проїзд' },
  val11: { name: 10, label: 'Проспект' },
  val12: { name: 11, label: 'Тупик' },
  val13: { name: 12, label: 'Узвіз' },
  val14: { name: 13, label: 'Шосе' },
  val15: { name: 14, label: 'Інше' },
  val16: { name: 15, label: 'Село' },
  val17: { name: 16, label: 'Острів' },
  val18: { name: 17, label: 'Сквер' },
  val19: { name: 18, label: 'Місто' },
};

export default toponimTypes;
