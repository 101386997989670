const frontendURL = '/doc/forecastadd1/';
const backendURL = '/api/forecasts/forecastadd1header/';
// const printURL = '/api/authorityplans/printform/allocationplanheader/app_form1/';
const name = 'Додаток 1 до Прогнозу бюджету ';
// "Загальні показники бюджету"

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
