import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

function RepTaxpayers() {
  // const navigate = useNavigate();
  // const url = '/incomeDirectory/';
  // navigate({ pathname: url });
  useEffect(
    () => {
      window.open('https://taxpayers.ciat.net.ua/#/grep/', '_blank');
    },
    [],
  );

  return (
    <Button
      onClick={() => window.open('https://taxpayers.ciat.net.ua/#/grep/', '_blank')}
    >
      Перейти до звітності
    </Button>
  );
}
export default RepTaxpayers;
