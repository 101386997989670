/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/financingRequest';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { soSelector } from '../../../../_common/selectors';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';

const tableMeta = md.tables.general;

function GeneralTP({ data, actions, readOnly }) {
  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);
  const Budget = data[md.columns.Budget.name];
  const date = data[md.columns.date.name] || -1;
  const Fond = data[md.columns.Fond.name];
  const FI = data[md.columns.FI.name];
  const CSUinH = data[md.columns.IncludeCSUIntoHead.name];
  const isComposeSettingsChecked = data[md.columns.ComposeSettings.name];
  const activeObjectsBuilding = data[md.columns.activeObjectsBuilding.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const ABVisible = data[md.columns.AccountBalanceVisible.name];
  const useFOAccounts = data[md.columns.useFOAccounts.name];
  const AccTypeTable = data[md.columns.AccTypeTable.name];
  const doNotUseAccounts = data[md.columns.doNotUseAccounts.name];
  const AccountingByClients = data[md.columns.AccountingByClients.name];
  const ClientInDoc = data[md.columns.ClientInDoc.name];
  const letMinusInRow = data[md.columns.letMinusInRow.name];
  const ShowSourcesFinInDocFin = data[md.columns.ShowSourcesFinInDocFin.name];
  const FinancingReturn = data[md.columns.FinancingReturn.name];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });
  const totals = useMemo(
    () => tableData.reduce((R, r) => R + (r[tableMeta.columns.Sum.name] || 0), 0),
    [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          //
          isComposeSettingsChecked={isComposeSettingsChecked}
          activeObjectsBuilding={activeObjectsBuilding}
          CSUinH={CSUinH}
          ABVisible={ABVisible}
          doNotUseAccounts={doNotUseAccounts}
          ClientInDoc={ClientInDoc}
          AccountingByClients={AccountingByClients}
          ShowSourcesFinInDocFin={ShowSourcesFinInDocFin}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            /// /
            CSUinH={CSUinH}
            isComposeSettingsChecked={isComposeSettingsChecked}
            activeObjectsBuilding={activeObjectsBuilding}
            FI={FI}
            Fond={Fond}
            date={date}
            Budget={Budget}
            isFilterCsuElemKFK={isFilterCsuElemKFK}
            ABVisible={ABVisible}
            useFOAccounts={useFOAccounts}
            AccTypeTable={AccTypeTable}
            doNotUseAccounts={doNotUseAccounts}
            ClientInDoc={ClientInDoc}
            AccountingByClients={AccountingByClients}
            letMinusInRow={letMinusInRow}
            ShowSourcesFinInDocFin={ShowSourcesFinInDocFin}
            FinancingReturn={FinancingReturn}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
