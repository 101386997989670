import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import ListerSelector from './listerselector';
import SelectorCP from './selectorCommandPanel';
import TableComponent from './tableComponent';
import meta from '../../constants/meta';

function Selector({
  onSelect, onChoice, modelType, modelName, ...rest
}) {
  return (
    <ListerSelector
      modelType={modelType}
      modelName={modelName}
      onItemClick={(e, key, item) => {
        onSelect(e, item);
        return true;
      }}
      onItemDoubleClick={(e, key, item) => onChoice(e, item)}
      CommandPanel={<SelectorCP />}
      TableComponent={<TableComponent modelType={modelType} modelName={modelName} />}
      storeNode="selector"
      {...rest}
    />
  );
}

Selector.propTypes = {
  modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
  modelName: PropTypes.oneOf([
    ...new Set(Object.keys(meta).reduce(
      (r, typeName) => [...r, ...Object.keys(meta[typeName])],
      [],
    )),
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onChoice: PropTypes.func.isRequired,
};

export default Selector;
