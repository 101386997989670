import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Fields from '../../../../field';
import GridTemplate from './GridTemplate';
import { DivCell, InputCodeStyled, SumInputStyled } from '../../../../../components/styledTable/styledTable';

function RowComponent({ row }) {
  return (
    <GridTemplate>
      <DivCell areaName="csu">
        <Fields.ItemField
          noBackground
          noBorder
          rows={2}
          value={row.get('CSU', new Map())}
        />
      </DivCell>
      <DivCell areaName="eKFK">
        <Fields.ItemField
          noBackground
          noBorder
          rows={3}
          value={row.get('ElementKFK', '')}
        />
      </DivCell>
      <DivCell areaName="KPKVCode">
        <InputCodeStyled
          noBackground
          noBorder
          value={row.get('KPKVCode', '')}
        />
      </DivCell>

      <DivCell areaName="eKEKV">
        <Fields.ItemField
          noBackground
          noBorder
          rows={3}
          value={row.get('ElementKEKV', '')}
        />
      </DivCell>

      <DivCell areaName="KEKVCode">
        <InputCodeStyled
          disabled
          value={row.get('KEKVCode', '')}
        />
      </DivCell>

      <DivCell areaName="sum">
        <SumInputStyled
          disabled
          value={row.get('Sum', 0)}
        />
      </DivCell>
      <DivCell areaName="notes">
        <InputCodeStyled
          readOnly
          rows={3}
          value={row.get('Notes', '')}
        />
      </DivCell>
    </GridTemplate>
  );
}
RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
};

export default memo(RowComponent);
