import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faFolder, faFolderOpen, faPlus, faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { StyledToggler } from '../styles';

function HierarchyTogglerCell({
  isFolder, opened, canBeOpened, deleted, level, onClick,
}) {
  return (
    <StyledToggler level={level}>
      <Button variant="link" className="p-0" onClick={onClick} disabled={!canBeOpened}>
        {!isFolder && canBeOpened && !opened && (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faPlus} />
          {deleted && (
          <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-6 right-6" />
          )}
        </span>
        )}
        {((!isFolder && opened) || (!opened && !canBeOpened)) && (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faMinus} />
          {deleted && (
          <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-6 right-6" />
          )}
        </span>
        )}
        {isFolder && !opened && (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faFolder} color="var(--falcon-warning)" />
          {deleted && (
          <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-6 right-6" />
          )}
        </span>
        )}
        {isFolder && opened && (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faFolderOpen} color="var(--falcon-warning)" />
          {deleted && (
          <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-6 right-6" />
          )}
        </span>
        )}
      </Button>
    </StyledToggler>
  );
}

HierarchyTogglerCell.propTypes = {
  isFolder: PropTypes.bool,
  opened: PropTypes.bool,
  canBeOpened: PropTypes.bool,
  deleted: PropTypes.bool,
  level: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

HierarchyTogglerCell.defaultProps = {
  isFolder: false,
  opened: false,
  canBeOpened: false,
  deleted: false,
  level: 0,
};

export default HierarchyTogglerCell;
