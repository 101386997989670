import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import ChoicesRow from './row';
import { AddRowButton } from '../../../../../components/bootStrap/buttons';

function ChoicesTable({
  value, onChange, ctype, mode,
}) {
  const onRowChange = useCallback(
    (e, rowIndex, v) => {
      if (mode === 'meta') return onChange(e, value.map((vv, i) => (i === rowIndex ? v : vv)));
      return onChange(
        e,
        value.map((vv, i) => (i === rowIndex ? { value: v[0], display_name: v[1] } : vv)),
      );
    },
    [mode, onChange, value],
  );
  const onRowDelete = useCallback(
    (e, rowIndex) => onChange(e, value.filter((vv, i) => i !== rowIndex)),
    [onChange, value],
  );
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
            Значення
          </th>
          <th>
            Представлення
          </th>
        </tr>
      </thead>
      <tbody>
        {value.map((v, rowIndex) => (
          <ChoicesRow
            key={mode === 'meta' ? v[0] : v.value}
            value={mode === 'meta' ? v[0] : v.value}
            label={mode === 'meta' ? v[1] : v.display_name}
            rowIndex={rowIndex}
            onChange={onRowChange}
            onDelete={onRowDelete}
            canDelete={value.length > 1}
            ctype={ctype}
          />
        ))}
      </tbody>
      <tfoot>
        <AddRowButton
          onClick={(e) => onChange(e, [...value, mode === '' ? [null, 'Нове значення'] : { value: null, display_name: 'Нове значення' }])}
          content="Додати"
        />
      </tfoot>
    </Table>

  );
}

ChoicesTable.propTypes = {
  value: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.bool,
  ]))).isRequired,
  ctype: PropTypes.oneOf(['String', 'Date', 'Boolean', 'Integer', 'Decimal']).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['meta', 'param']),
};

ChoicesTable.defaultProps = {
  mode: 'meta',
};

export default ChoicesTable;
