import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/liquidityMethod';
import {
  ResultSegment,
  StyledLabel,
  FiveColumnsGridContainer,
  ContainerThreeColumn,
} from '../../../components/Form/styledForm';

import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import ReportContainer from '../reportContainer/variantReportContainer';
import DateRangeField from '../../../components/fields/DateRangeField';

import { InputWithDropdown } from '../../../components/styledInputs';

const typeAnalysisList = {
  p1: { name: 'МетодПриростаДанныеЕИСУБ', label: 'Метод приросту' },
  p2: { name: 'МетодВыполненияПланаДанныеЕИСУБ', label: 'Метод виконання плану' },
  p3: { name: 'МетодНаОснованииПлана', label: 'Наївна модель' },
};

const FiveColGridContainer = styled(FiveColumnsGridContainer)`
  grid-template-columns: 25% 15% 20% 20% 20%;
`;

const CardDiv = styled.div`
    margin-right: 25px;
    box-sizing: border-box;
    // color: #377dff;
    padding: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: .0625rem solid rgba(231,234,243,.7);
    border-radius: .75rem;
    box-shadow: 0 6px 12px rgba(140,152,164,.075);
    transition: .3s;
    &:hover {
    transition: .3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3)};
`;

CardDiv.propTypes = { children: PropTypes.node };
function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>

      </ContainerThreeColumn>
      <FiveColGridContainer>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'period'], v));
            }}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.method.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('method', false)}
            options={Object.values(typeAnalysisList)}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'method'], v));
            }}
          />
        </div>
      </FiveColGridContainer>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      {/* <ContainerDiv> */}
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'period'], v));
            }}
            noBorder
          />
        </div>
        <div style={{ marginLeft: '25px' }}>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </div>

      {/* </ContainerDiv> */}

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
