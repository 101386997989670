import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Textarea = styled.textarea`
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  padding: 9.4px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  resize: vertical;
`;
function TextAreaField({
  value, onChange, rows, ...restProps
}) {
  const [editValue, setValue] = useState(value);
  return (
    <Textarea
      value={editValue}
      title={editValue}
      rows={rows}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={(e) => {
        onChange(e, editValue);
      }}
      {...restProps}
    />
  );
}

TextAreaField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
};

TextAreaField.defaultProps = {
  onChange: () => null,
  rows: 1,
  value: '',
};

export default TextAreaField;
