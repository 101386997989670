import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualSpend';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.targetProg;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  CSUinH,
  total,
  genTotal,
  specTotal,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>
          {!CSUinH
          && (
            <Col
              sm={12}
              className="border-right"
            >
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'CSU'}
                highlighted={highlights.includes('CSU')}
                onToggleHighlght={() => onToggleHighlght('CSU')}
              >
                {meta.columns.CSU.label}
              </TableHeaderCell>
            </Col>
          )}
        </Row>

        <Row noGutters>
          <Col
            xl={4}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={4}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KFKCode'}
              highlighted={highlights.includes('KFKCode')}
              onToggleHighlght={() => onToggleHighlght('KFKCode')}
            >
              {meta.columns.KFKCode.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={4}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'targetedPrograms'}
              highlighted={highlights.includes('targetedPrograms')}
              onToggleHighlght={() => onToggleHighlght('targetedPrograms')}
            >
              {meta.columns.targetedPrograms.label}
            </TableHeaderCell>
          </Col>
        </Row>

        <Row noGutters>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGenF'}
              title={genTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumGenF')}
              onToggleHighlght={() => onToggleHighlght('sumGenF')}
            >
              {meta.columns.sumGenF.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSpecF'}
              title={specTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumSpecF')}
            >
              {meta.columns.sumSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumBR'}
              highlighted={highlights.includes('sumBR')}
              onToggleHighlght={() => onToggleHighlght('sumBR')}
            >
              {meta.columns.sumBR.label}
            </TableHeaderCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              title={total.toLocaleString('uk', moneysStingOptions)}
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
          </Col>

        </Row>
        <Row noGutters>
          <Col
            sm={12}
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlght={() => onToggleHighlght('note')}
            >
              {meta.columns.note.label}
            </TableHeaderCell>
          </Col>

        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  CSUinH: PropTypes.bool,
  total: PropTypes.number.isRequired,
  specTotal: PropTypes.number.isRequired,
  genTotal: PropTypes.number.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  CSUinH: false,
};

export default memo(TPHeader);
