export const userMessageTypes = {
  info: {
    label: 'Інформаційне',
    name: 'Информационное',
  },
  warning: {
    label: 'Попереджаюче',
    name: 'Предупреждающее',
  },
};
