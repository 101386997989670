import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes';

const meta = md.tables.TABL_100;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'solutionNumber'}
              highlighted={highlights.includes('solutionNumber')}
              onToggleHighlght={() => onToggleHighlght('solutionNumber')}
            >
              {meta.columns.solutionNumber.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'solutionDate'}
              highlighted={highlights.includes('solutionDate')}
              onToggleHighlght={() => onToggleHighlght('solutionDate')}
            >
              {meta.columns.solutionDate.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'forceDate'}
              highlighted={highlights.includes('forceDate')}
              onToggleHighlght={() => onToggleHighlght('forceDate')}
            >
              {meta.columns.forceDate.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'dateFrom'}
              highlighted={highlights.includes('dateFrom')}
              onToggleHighlght={() => onToggleHighlght('dateFrom')}
            >
              {meta.columns.dateFrom.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'code'}
              highlighted={highlights.includes('code')}
              onToggleHighlght={() => onToggleHighlght('code')}
            >
              {meta.columns.code.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'koatuu'}
              highlighted={highlights.includes('koatuu')}
              onToggleHighlght={() => onToggleHighlght('koatuu')}
            >
              {meta.columns.koatuu.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'koatuuAto'}
              highlighted={highlights.includes('koatuuAto')}
              onToggleHighlght={() => onToggleHighlght('koatuuAto')}
            >
              {meta.columns.koatuuAto.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'nameAto'}
              highlighted={highlights.includes('nameAto')}
              onToggleHighlght={() => onToggleHighlght('nameAto')}
            >
              {meta.columns.nameAto.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'add'}
              highlighted={highlights.includes('add')}
              onToggleHighlght={() => onToggleHighlght('add')}
            >
              {meta.columns.add.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
