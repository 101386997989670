import React from 'react';
import propTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorControls } from '../../basicEditor/editorControls';

function DKSUData({
  dataType, setDataType, date, setDate, getDKSUData, datesByType, lastDownload, logicaPingSuccess,
}) {
  return (
    <Row>
      <Col md={3} className="d-flex justify-content-end flex-column">
        <EditorControls.SelectorInput
          label="Оберіть тип даних для завантаження"
          controlId="dataType"
          required
          value={dataType}
          values={Object.keys(datesByType).map((data) => ({ value: data, display_name: data }))}
          onChange={(e, data) => setDataType(data)}
        />
      </Col>
      <Col md={3} className="d-flex justify-content-end flex-column">
        <EditorControls.StringInput
          label="В останнє завантажено на:"
          value={lastDownload}
          controlId="lastDownload"
          readOnly
          onChange={() => null}
        />
      </Col>
      <Col md={3} className="d-flex justify-content-end flex-column">
        <EditorControls.SelectorInput
          label="Оберіть дату"
          required
          controlId="date"
          value={date}
          values={datesByType[dataType]}
          onChange={(e, data) => setDate(data)}
          readOnly={!dataType}
        />
      </Col>
      <Col md={3} className="d-flex align-items-end">
        <Button onClick={getDKSUData} disabled={!logicaPingSuccess}>
          <FontAwesomeIcon icon={faDownload} className="me-2" />
          Завантажити
        </Button>
      </Col>
    </Row>
  );
}

DKSUData.propTypes = {
  dataType: propTypes.string,
  setDataType: propTypes.func.isRequired,
  date: propTypes.string,
  setDate: propTypes.func.isRequired,
  getDKSUData: propTypes.func.isRequired,
  datesByType: propTypes.shape({
    Всі: propTypes.arrayOf(propTypes.shape({
      value: propTypes.string,
      display_label: propTypes.string,
    })),
  }).isRequired,
  lastDownload: propTypes.string,
  logicaPingSuccess: propTypes.bool.isRequired,
};

DKSUData.defaultProps = {
  dataType: null,
  date: null,
  lastDownload: null,
};

export default DKSUData;
