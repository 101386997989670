import React from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/draftAnnualSpend';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const FRLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

export default FRLister;
