const frontendURL = '/srv/dburl/';
const backendURL = '/api/forecasts/refdburl/';
const name = 'Посилання ЄІСУБ-web';
const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  backendURL,
  name,
  getVisibility,
};

export default instance;
