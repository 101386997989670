import styled from 'styled-components';
import { hoverTable, tableHeaderLister as tabHeader, tableInside } from '../../../constants/globalStyle';

const DivHeader = styled.div`
  position: sticky;
  overflow-wrap: break-word;
  overflow: hidden;
  // display: inline-flex;
  // justify-content: center;
  align-items:center;
  text-align: center;
  z-index: 1;
  top: 0;
  ${tabHeader};
  ${tableInside};
`;

export const DivTogglerHeader = styled(DivHeader)`
  grid-column: togglerColumn;
`;

export const DivColumnHeader = styled(DivHeader)`
      `;

export const getBackground = (active, negative, even) => {
  if (active) return 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #008F21'; // Активная строчка
  if (negative) return 'rgba(255, 246, 246, 0.1)'; // Помеченая на удаление - возмодно потом заменим на икноку
  if (even) return 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),#aab4bf'; // четная
  return 'unset'; // Никакой
};

export const BasedDiv = styled.div`
  // border: 1px solid rgba(34,36,38,0.1);
  border: 1px solid #afbbcc;
  background: ${({ active, negative, even }) => `${getBackground(active, negative, even)};`}
`;

const DivBaseItem = styled(BasedDiv)`
  padding: 6px 5px;
  // text-align: center;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 3em;
   ${hoverTable};
`;
export const DivTogglerItem = styled(BasedDiv)`
  grid-column: togglerColumn;
  padding: 10px;
  // border: 1px solid rgba(34,36,38,0.1);
  border: 1px solid #afbbcc;
  display: flex;
`;

export const DivItem = styled(DivBaseItem)`
  position: relative;
  overflow: hidden;
`;

export const NumberSpan = styled.span`
  display: flex;
  justify-content: flex-end;
`;

export const BoolContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
