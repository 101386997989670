import React from 'react';
import PropTypes from 'prop-types';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function AllRightButton({ content, onClick, disabled }) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faAngleDoubleRight}
    />
  );
}

AllRightButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AllRightButton.defaultProps = {
  onClick: () => null,
  content: '',
  disabled: false,
};

export default AllRightButton;
