import React, {
  useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Dropdown, Row, Tab, Tabs,
} from 'react-bootstrap';
import meta from '../../../../meta';
import HistoricalValues from './history';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { CiatAppContext } from '../../../../providers';
import Settings from './settings';
import useEditorParams from '../../../newEditor/hook/params';
import EditorFooter from '../../../../components/Containers/editorFooter';
import useHistory from '../../../newEditor/hook/useHistory';

const BACKENDURL = meta.cat.disposers.backendURL;

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors, readOnly, headerReadOnlyFields,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  // add a review history field error on the header
  // because users don't understand why the disposer isn't saving
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew: isNewHistory,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.disposers.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors: fieldErrors,
  });

  const headerError = useMemo(() => (
    (historyFieldErrors && Object.keys(historyFieldErrors).length)
      ? Object.keys(historyFieldErrors).map(
        (key) => ((key in historyFields)
          ? `${historyFields[key].label}: ${historyFieldErrors[key][0]}`
          : ''),
      ) : []
  ), [historyFieldErrors, historyFields]);

  const { currentUser } = useContext(CiatAppContext);
  const isSuperuser = currentUser.is_superuser;

  // eslint-disable-next-line max-len
  const editorStringHooks = (name) => editorHooks.useStringInputProps(name, data, fields, fieldErrors, actions.onChange, readOnly, headerReadOnlyFields);
  // eslint-disable-next-line max-len
  const editorItemHooks = (name) => editorHooks.useItemInputProps(name, data, fields, fieldErrors, actions.onChange, readOnly, headerReadOnlyFields);
  // eslint-disable-next-line max-len
  const editorSelectHooks = (name) => editorHooks.useSelectorInputProps(name, data, fields, fieldErrors, actions.onChange, readOnly, headerReadOnlyFields);

  const recipientProps = editorItemHooks('recipient');
  const disposerKindProps = editorSelectHooks('disposer_kind');
  const levelProps = editorSelectHooks('level');
  const bankProps = editorItemHooks('bank');
  const parentProps = editorItemHooks('parent');
  const budgetProps = editorItemHooks('budget');

  const name = editorStringHooks('name');
  const edrpou = editorStringHooks('edrpou');
  const addressProps = editorStringHooks('address');
  const koatuuProps = editorStringHooks('koatuu');
  const katottgProps = editorStringHooks('katottg');
  const phonenumberProps = editorStringHooks('phone_number');
  const emailProps = editorStringHooks('email');
  const webSiteProps = editorStringHooks('web_site');
  const skypeProps = editorStringHooks('skype');
  const postCodeProps = editorStringHooks('post_code');
  const cityProps = editorStringHooks('city');
  const districtProps = editorStringHooks('district');
  const regionProps = editorStringHooks('region');
  const disposerInHeader = editorHooks.useCheckboxInputProps('disposer_in_header', data, fields, fieldErrors, actions.onChange, readOnly, headerReadOnlyFields);
  const balanceByParentProps = editorHooks.useCheckboxInputProps('balances_by_parent', data, fields, fieldErrors, actions.onChange, readOnly, headerReadOnlyFields);
  const parentFilter = useMemo(
    () => ({ budget: data.budget ? data.budget.id : null }),
    [data.budget],
  );
  const recipientFilter = useMemo(
    () => (data.id ? { parent: data.id } : {}),
    [data.id],
  );

  const koatuuValues = useMemo(
    () => (data.allowed_katottg || []).map((k) => ({
      value: k.koatuu,
      display_name: `${k.koatuu} ${k.name}`,
      key: k.katottg,
    })),
    [data.allowed_katottg],
  );
  const katottgValues = useMemo(
    () => (data.allowed_katottg || []).map((k) => ({
      value: k.katottg,
      display_name: `${k.katottg} ${k.name}`,
      key: k.katottg,
    })),
    [data.allowed_katottg],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors ? [...headerError, ...nonFieldErrors] : headerError}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      title={data.name}
    >
      <Container fluid>
        {isSuperuser && (
          <Row className="mb-2">
            <Col>
              <EditorControls.ItemPicker {...budgetProps} />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                {...parentProps}
                filter={parentFilter}
                disabled={!data.budget}
              />
            </Col>
          </Row>
        )}
        <Tabs defaultActiveKey="disposers">
          <Tab eventKey="disposers" title="Основні властивості">
            <Container fluid className="border border-top-0 pb-3 rounded-bottom">
              <Row>
                <Col sm={6}>
                  <EditorControls.StringInput {...name} />
                </Col>
                <Col sm={6}>
                  <EditorControls.StringInput {...edrpou} inputMask="99999999" />
                </Col>
                <Col sm={10}>
                  <EditorControls.StringInput {...addressProps} />
                </Col>
                <Col sm={2}>
                  <EditorControls.StringInput {...postCodeProps} inputMask="99999" />
                </Col>
              </Row>
              <Row className="border rounded bg-warning-subtle py-2 my-3">
                <h6 className="text-muted mb-0">Дані поля заповнюються якщо значення відрізняються від значень бюджету</h6>
                <Col md={4}>
                  <EditorControls.StringInput {...cityProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.StringInput {...districtProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.StringInput {...regionProps} />
                </Col>
              </Row>
              <Row className="align-items-baseline">
                <Col md={6}>
                  <EditorControls.StringInput
                    {...koatuuProps}
                    inputMask="9999999999"
                    append={(
                      <Dropdown onSelect={(v, e) => koatuuProps.onChange(e, v)}>
                        <Dropdown.Toggle variant="secondary" className="z-5" />
                        <Dropdown.Menu>
                          {koatuuValues.map((k) => (
                            <Dropdown.Item
                              eventKey={k.value}
                              key={k.key}
                            >
                              {k.display_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <EditorControls.StringInput
                    {...katottgProps}
                    inputMask="UA99999999999999999"
                    append={(
                      <Dropdown onSelect={(v, e) => katottgProps.onChange(e, v)}>
                        <Dropdown.Toggle variant="secondary" className="z-5" />
                        <Dropdown.Menu>
                          {katottgValues.map((k) => (
                            <Dropdown.Item
                              eventKey={k.value}
                              key={k.key}
                            >
                              {k.display_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  />
                </Col>
                <Col>
                  <EditorControls.StringInput {...phonenumberProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...emailProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...webSiteProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...skypeProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    {...recipientProps}
                    filter={recipientFilter}
                    noHierarchy
                    disabled={!data.id}
                    description={!data.id ? (
                      <span className="text-warning">
                        Для обрання отримувача потрібно спочатку зберегти поточний елемент
                      </span>
                    ) : recipientProps.description}
                  />
                </Col>
                <Col>
                  <EditorControls.ItemPicker {...bankProps} />
                </Col>
                <Col>
                  <EditorControls.SelectorInput {...disposerKindProps} />
                </Col>
                <Col>
                  <EditorControls.SelectorInput {...levelProps} />
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="table" title="Історичні значення">
            <div className="p-3 border border-top-0 pb-3 rounded-bottom">
              <HistoricalValues
                data={data}
                readOnly={readOnly}
                historyActions={historyActions}
                historyData={historyData}
                historiesList={historiesList}
                historyFields={historyFields}
                historyFieldErrors={historyFieldErrors}
                historyLoading={historyLoading}
                isNew={isNewHistory}
                errors={fieldErrors}
              />
            </div>
          </Tab>
          <Tab eventKey="settings" title="Налаштування">
            <Settings data={data} onChange={actions.onChange}>
              <Row>
                <Col>
                  <EditorControls.CheckboxInput
                    {...disposerInHeader}
                  />
                  <EditorControls.CheckboxInput
                    {...balanceByParentProps}
                  />
                </Col>
              </Row>
            </Settings>
          </Tab>
        </Tabs>
      </Container>
      <EditorFooter>
        <div className="text-muted">{data.budget ? data.budget.repr : ''}</div>
        <div className="text-muted">
          {data.parent ? data.parent.repr : 'Є фінансовим органом'}
        </div>
      </EditorFooter>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
