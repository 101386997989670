import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  codeKDB: { label: 'Виводити коди надходжень (замість кодів КЕКВ у групі "Надходження")', name: 'КодПоступлений' },
  signVariant: { label: 'Підпис', name: 'ВариантПодписи' },
};

const definition = {
  label: 'Довідка про операції в національній валюті',
  name: 'NationalCurrencyNote',
  columns,
  frontend: 'rep/NationalCurrencyNote',
  backendName: 'СправкаОбОперацияхВНациональнойВалюте',
  resultColumns: baseResultColumns,
};

export default definition;
