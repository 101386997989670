import React, { forwardRef, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonToolbar, ButtonGroup,
} from 'react-bootstrap';
import {
  CopyButton, DeleteButton, EditButton, NewButton, NewFolderButton,
  ShowDeletedToggler,
} from './buttons';
import { SearchInput } from './elements';
import { StyledButtonToolbar } from './styles';
import ListerContext from '../../context';
import GoToOldVersionButton from './buttons/goOnOldVersion';

const CatListerCommandPanel = forwardRef(({
  children,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString,
  } = useContext(ListerContext);

  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <StyledButtonToolbar className="me-auto">
        <ButtonGroup>
          <NewButton onClick={actions.onNew} disabled={!(permissions.canNew && actions.onNew)} />
          {permissions.foldersUsed && (
            <NewFolderButton
              onClick={actions.onNewFolder}
              disabled={!(permissions.canNewFolder && actions.onNewFolder)}
            />
          )}
          <CopyButton
            onClick={actions.onCopy}
            disabled={!(permissions.canCopy && actions.onCopy)}
          />
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={actions.onDelete}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
        </ButtonGroup>
        {children}
      </StyledButtonToolbar>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>
      <ButtonGroup className="float-right">
        <GoToOldVersionButton
          onClick={actions.onGoToOldVersion}
        />
      </ButtonGroup>
    </Navbar>
  );
});

CatListerCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

CatListerCommandPanel.defaultProps = {
  children: null,
};

export default memo(CatListerCommandPanel);
