import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { TPCommandPanel } from '../../newEditor/commandPanels';
import { childrenPropType } from '../../../constants/backend/propTypes';
import useTablePart from '../../newEditor/hook/tablepart';
import BasicHeader from './basicheader';
import TableHeader from '../../newEditor/tablePart/tableHeader';
import TableRow from '../../newEditor/tablePart/tablerow';
import BasicRow from './basicrow';
import Placeholder from '../../newEditor/tablePart/placeholder';
import { columnsPropType } from './propTypes';
import TableFooter from '../../newEditor/tablePart/tableFooter';
import BasicTotalFooter from './basicTotalFooter';
import TableContainer from '../../newEditor/tablePart/tableContainer';

function BasicTP({
  template, tableName, addCommands,
  onChange, data, fields, errors, readOnlyFields, readOnly, totals, noUseCommandPanel,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter,
    tableContainerRef, tableReadOnlyFields, tableErrors,
    tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName, readOnlyFields, errors, fields,
  });
  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        {!noUseCommandPanel && (
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        >
          {addCommands}
        </TPCommandPanel>
        )}
        <BasicHeader
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          columns={template.columns}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
              // eslint-disable-next-line react/no-array-index-key
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            tableName={tableName}
            onMoveRow={tableActions.onMoveRow}
          >
            <BasicRow
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              columns={template.columns}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      {totals && (
      <TableFooter showFooter={showFooter} pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <BasicTotalFooter
          tableData={tableData}
          totals={totals}
          highlights={highlights}
          activeCol={activeCol}
          fields={tableFields}
          columns={template.columns}
          tableName={tableName}
        />
      </TableFooter>
      )}
    </TableContainer>
  );
}

BasicTP.propTypes = {
  template: PropTypes.shape({
    columns: columnsPropType,
  }).isRequired,
  tableName: PropTypes.string.isRequired,
  addCommands: childrenPropType, // Дополнительные кнопки командной панели
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape().isRequired,
  errors: PropTypes.shape(),
  readOnlyFields: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  noUseCommandPanel: PropTypes.bool,
  totals: childrenPropType,
};

BasicTP.defaultProps = {
  addCommands: null,
  errors: {},
  readOnlyFields: {},
  readOnly: false,
  totals: null,
  noUseCommandPanel: false,
};

export default BasicTP;
