import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { Collapse } from 'react-bootstrap';
import './youtubePlayer.css';

import { CloseIcon } from '../../../../../assets/icons';

const ContainerDiv = styled.div`
height: ${({ accessHeight }) => `calc(100vh - ${accessHeight}px - 40px)`};
border: solid 1px blue;
display: flex;
justify-content: space-between;
position: relative
`;

const SDiv = styled.div`
position: absolute;
right: 10px;
top: 4px;
cursor: pointer;
`;

const SCloseIcon = styled(CloseIcon)`
width: 15px;
height: 15px;
transition: all .3s;
&:hover{
height: 18px;
width: 18px;
}
`;

function YoutubePlayer({ videoId = '', onReady = () => null }) {
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onReady={onReady}
      containerClassName="containerYouTubeClassName"
    />
  );
}

function YoutubeVideo({ videoId = '', showVideo = false, setShowVideo = () => null }) {
  const [player, setPlayer] = useState(null);
  const [accessHeight, setAccessHeight] = useState(0);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setAccessHeight(ref.current.getBoundingClientRect().top);
    }
  }, []);

  const onReady = (event) => {
    setPlayer(event.target);
  };

  if (!showVideo && (player !== null)) player.pauseVideo();

  return (
    <div ref={ref}>
      <Collapse in={showVideo}>
        <div>
          <ContainerDiv accessHeight={accessHeight}>
            <YoutubePlayer
              videoId={videoId}
              showVideo={showVideo}
              onReady={onReady}
            />
            <SDiv
              onClick={() => {
                player.pauseVideo();
                setShowVideo(false);
              }}
            >
              <SCloseIcon />
            </SDiv>
          </ContainerDiv>
        </div>
      </Collapse>
    </div>
  );
}

export default YoutubeVideo;
