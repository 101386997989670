import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' }, // >Отбор.Владелец = FO
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  isDataTreasury: { label: 'за даними казначейства', name: 'флПоДаннымКазначейства' },
  printDate: { label: 'Друк дати', name: 'флКолонтитулДаты' },
  printPageNo: { label: 'Друк ном.сторінки', name: 'флКолонтитулНомСтр' },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },
  printReportParams: {
    label: 'Друкувати параметри звіту',
    name: 'флПечатьСодержанияФильтра',
  },
  bySubLevelCSU: {
    label: 'За данними підлеглих розпорядників',
    name: 'флПоДаннымНижнихРасп',
  },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },

  consolidated: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  // флСтандартнаяОбработка
  includeAllChldren: { label: 'З урахуванням розпорядників нижчого рівня', name: 'флВключаяВсехПодчиненных' },
  byProposition: { label: 'За даними пропозиції на фінансування', name: 'флПоДаннымПредложенияНаФинансирования' },

};

const definition = {
  label: 'Аналіз фінансування',
  name: 'FinancingAnalysis',
  columns,
  frontend: 'rep/FinancingAnalysis',
  backendName: 'НовАнализФинансированияСКД',
  resultColumns: baseResultColumns,
};

export default definition;
