import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,

  dateStart: { label: 'Дата з', name: 'ТекДата0' },
  dateEnd: { label: 'по', name: 'ТекДата1' },
  isIncludeEndDate: { label: '(включаючи кінцеву дату)', name: 'НаДатуВключительно' },

  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  region: { label: 'Район', name: 'Район' },
  bySeparateBudgets: { label: 'Бюджети окремо', name: 'флБюджетыРаздельно' },
  isShowAllBudgets: {
    label: 'Друкувати назви усіх бюджетів по яким формується звіт',
    name: 'флПоказатьВсеБюджеты',
  },
  bySplit: { label: 'З урахуванням розмежування', name: 'УчетРасщепления' },
  withoutSubLevelCSU: { label: 'Без розпорядників нижчого рівня', name: 'БезРаспорядителейНижнегоУровня' },

  fondClassifier: { label: 'Фонд', name: 'Фонд' },
  kdbClassifier: { label: 'КДБ', name: 'КДБ' },
  inThousands: { label: 'в тис.грн.', name: 'ВТысГрн' },
  incDB: { label: 'В т.ч. БР', name: 'втчБР' },

  planType: { label: 'Вид плану', name: 'ВидПлана' },
  byGroupKdb: { label: 'КДБ за групами', name: 'флГруппыКДБ' },
  byBaskets: { label: 'Показувати кошики', name: 'ПоКорзинам' },
  notDisplayYourRevenues: { label: 'Не показувати  власні надходження', name: 'НеПоказ250' },
  byTreasureCode: { label: 'За казначейськими кодами', name: 'ПоКазначейскимКодам' },
  documentDetails: { label: 'До документа', name: 'ДоДокумента' },

  reportType: { label: 'Вид звіту', name: 'ВариантОтчета' },
  typeDocReport: { label: 'Варіант звіту', name: 'ВидОтчета' },
  byMonthsAccum: { label: 'Накопичувально', name: 'НакопительноПоМесяцам' },
  monthByStartYear: { label: 'З початку року', name: 'МесяцыСНачалаГода' },
  monthCount: { label: 'Кількість', name: 'МесяцыКоличество' },
  deviationRelative: { label: 'Відносне', name: 'ОтклонениеОтносительное' },
  absoluteRelative: { label: 'Абсолютне', name: 'ОтклонениеАбсолютное' },

  hideDisplay: {
    label: 'Приховувати елементи по варіанту (в підсумку дані будуть)',
    name: 'СкрытьОтображение',
  },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  ownIncome: { label: 'Показувати власні надходження', name: 'ПоСобственным' },
  isAnalysis: { label: 'Это аналитика', name: 'ЭтоАналитика' },
  groupKDB: { label: 'Виводити КДБ як ', name: 'ГруппыКДБ' },
  showCurrentKDB: {
    label: 'Показувати останню актуальну назву КДБ (не дивлячись на  наявність залишків минулого року) ',
    name: 'флТолькоТекущееНазваниеКДБ',
  },
  isSB: { label: 'Факт за даними з державного бюджету', name: 'ПоГосбюджету' },
};

const definition = {
  label: 'Аналіз доходів',
  name: 'RevenueAnalysis',
  columns,
  frontend: 'rep/RevenueAnalysis',
  backendName: 'АнализДоходов',
  resultColumns: baseResultColumns,
};

export default definition;
