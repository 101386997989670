const frontendURL = '/cat/minfinindicator/';
const backendURL = '/api/references/refminfinindicator/';
const name = 'Показники бюджетних програм (Нова методика)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
