import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { StyledTableHeaderCell } from './styles';

function TableHeaderCell({
  active, className, children, title, canHighlight, highlighted, onToggleHighlight,
}) {
  const c = (
    <StyledTableHeaderCell
      role="button"
      active={active}
      className={classNames(className, 'p-0', {
        'bg-warning': highlighted,
        'text-white': highlighted,
        'border-bottom': '1px sold black',
      })}
      onClick={(e) => canHighlight && onToggleHighlight && onToggleHighlight(e)}
    >
      {children}
    </StyledTableHeaderCell>
  );
  return title ? (
    <OverlayTrigger
      placement="auto"
      overlay={(
        <Tooltip>
          {title}
        </Tooltip>
      )}
    >
      {c}
    </OverlayTrigger>
  ) : c;
}
TableHeaderCell.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.node,
  canHighlight: PropTypes.bool,
  highlighted: PropTypes.bool,
  onToggleHighlight: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  active: false,
  className: '',
  title: null,
  canHighlight: true,
  highlighted: false,
  onToggleHighlight: null,
};

export default TableHeaderCell;
