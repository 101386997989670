import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../../constants/meta/catalogs/typesRepairIndustry';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.typesOfRepair;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'propertyType'}
          highlighted={highlights.includes('propertyType')}
          onToggleHighlght={() => onToggleHighlght('propertyType')}
        >
          {meta.columns.propertyType.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
