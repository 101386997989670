import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes11';

const meta = md.tables.TABL_112;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Row>
      <Col>
        <TableHeaderCell>
          Лише коди 00.n
        </TableHeaderCell>
      </Col>
    </Row>
    <Row>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'code'}
              highlighted={highlights.includes('code')}
              onToggleHighlght={() => onToggleHighlght('code')}
            >
              {meta.columns.code.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeDod'}
              highlighted={highlights.includes('codeDod')}
              onToggleHighlght={() => onToggleHighlght('codeDod')}
            >
              {meta.columns.codeDod.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'name'}
              highlighted={highlights.includes('name')}
              onToggleHighlght={() => onToggleHighlght('name')}
            >
              {meta.columns.name.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithImpact'}
              highlighted={highlights.includes('rateLegalWithImpact')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithImpact')}
            >
              {meta.columns.rateLegalWithImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithImpact'}
              highlighted={highlights.includes('rateNaturalWithImpact')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithImpact')}
            >
              {meta.columns.rateNaturalWithImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithoutImpact'}
              highlighted={highlights.includes('rateLegalWithoutImpact')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithoutImpact')}
            >
              {meta.columns.rateLegalWithoutImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithoutImpact'}
              highlighted={highlights.includes('rateNaturalWithoutImpact')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithoutImpact')}
            >
              {meta.columns.rateNaturalWithoutImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </Row>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
