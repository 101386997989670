import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { docEditorMenu as BasicMenu } from '../../../components/editorMenu/index';
import BasicEditor, { mapState, getRouterEditor } from '../../editor/basic';
// import PrintModal from './printModal';
import SelectorField from '../../field/stdSelectorField';
import YearField from '../../field/yearInput';
import GeneralTP from './tablepart/apGeneralTP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import StyledTPPane from '../../../components/tableParts/TPPane';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import { editor as actions } from '../../../actions/index';
import md from '../../../constants/meta/documents/publicBudget';
import DateField from '../../../components/fields/dates';
import {
  StyledGreyLabel,
  StyledLabel,
  SubText,
  ContainerThreeColumn,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import TextAreaField from '../../field/TextArea';
import storePathParam from '../../../common/storePathParam';

const YearInput = YearField;
const Note = TextAreaField;

export class PublicBudget extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    headerForm: new Map(),
    sessionOptions: new Map(),
  };

  render() {
    const {
      props: { dispatch, headerForm, sessionOptions },
    } = this;

    const headerUpdater = (...path) => (e, value) => {
      dispatch(actions.changeField(['headerForm', ...path], value));
    };
    return (
      <ContainerThreeColumn>
        {sessionOptions.get('is_admin', false) ? (
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'FI'], new Map()));
                dispatch(actions.changeField(['headerForm', 'budget'], value));
              }}
            />
          </div>
        ) : (
          <StyledGreyLabel>
            {md.columns.budget.label}
            :
            {' '}
            <SubText>
              {' '}
              {headerForm.getIn(['budget', 'repr'], '')}
            </SubText>
          </StyledGreyLabel>
        )}
        {sessionOptions.get('is_admin', false) ? (
          <div>
            <StyledLabel>{md.columns.FI.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('FI', new Map())}
              modelType="cat"
              modelName="csu"
              filter={[{ fieldName: 'Бюджет', value: headerForm.get('budget') }]}
              onChange={headerUpdater('FI')}
            />
          </div>
        ) : (
          <StyledGreyLabel>
            {md.columns.FI.label}
            :
            {' '}
            <SubText>
              {' '}
              {headerForm.getIn(['FI', 'repr'], '')}
            </SubText>
          </StyledGreyLabel>
        )}
        {sessionOptions.get('is_admin', false) ? (
          <div>
            <StyledLabel>{md.columns.author.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('author', new Map())}
              modelType="cat"
              modelName="users"
              onChange={headerUpdater('author')}
            />
          </div>
        ) : (
          <StyledGreyLabel>
            {md.columns.author.label}
            :
            {' '}
            <SubText>
              {' '}
              {headerForm.getIn(['author', 'repr'], '')}
            </SubText>
          </StyledGreyLabel>
        )}
        <div>
          <StyledLabel>{md.columns.date.label}</StyledLabel>
          <DateField
            value={headerForm.get('date', null)}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'date'], value));
              dispatch(actions.changeField(['headerForm', 'year'], new Date(value).getFullYear()));
            }}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <YearInput
            value={headerForm.get('year', 0)}
            onChange={headerUpdater('year')}
            disableCalculator
          />
        </div>
      </ContainerThreeColumn>
    );
  }
}

const GeneralTPRenderer = getTPContainer(GeneralTP, getResizableCP(<TPCommandPanel tableName="general" />));

export function PublicBudgetTabs({ headerForm, dispatch }) {
  const headerUpdater = (...path) => (e, value) => {
    dispatch(actions.changeField(['headerForm', ...path], value));
  };
  return (
    <Tabs>
      <DivWithPropsLabel label={md.tables.general.label}>
        <StyledTPPane>
          <GeneralTPRenderer />
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Примітка">
        <StyledTPPane>
          <StyledLabel>Примітка</StyledLabel>
          <Note
            value={headerForm.getIn(['note'], '')}
            onChange={headerUpdater('note')}
          />
        </StyledTPPane>
      </DivWithPropsLabel>
    </Tabs>
  );
}

PublicBudgetTabs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map),
};

PublicBudgetTabs.defaultProps = {
  headerForm: new Map(),
};

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

const connectedTablesRender = connect(mapStateTP)(PublicBudgetTabs);

function APMenu(props) {
  return (
    <BasicMenu
    // extraContent={(<PrintModal />)}
      {...props}
    />
  );
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'doc',
      name: 'publicBudget',
    },
    {
      menu: APMenu, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
      canCollapsed: true,
    },
  ),
  // wasModified,
  // hasLoader,
);

export const StdEditor = enhance(PublicBudget);

export default getRouterEditor(md)(StdEditor);
