import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_21: {
    name: 'Табл21',
    label: 'Табл 21',
    columns: {
      code: { label: 'Код ', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування ', name: 'КласЗемли' },
      rateLegalWithImpact: { label: 'Оцінку проведено, юр.ос. ', name: 'СтавкаЮрОСОценкой' },
      rateNaturalWithImpact: { label: 'Оцінку проведено, фіз.ос. ', name: 'СтавкаФОСОценкой' },
      rateLegalWithoutImpact: { label: 'Оцінку не проведено, юр.ос. ', name: 'СтавкаЮрОБезОценки' },
      rateNaturalWithoutImpact: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки' },
      rateLegalWithImpact1: { label: 'Оцінку проведено, юр.ос.(крім держ.та комун.форми власност)', name: 'СтавкаЮрОСОценкой1' },
      rateLegalWithImpact11: { label: 'Оцінку проведено, юр.ос. держ. форми власності', name: 'СтавкаЮрОСОценкой11' },
      rateNaturalWithImpact1: { label: 'Оцінку проведено, юр.ос. комун. форми власності', name: 'СтавкаФОСОценкой1' },
      rateNaturalWithoutImpact1: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки1' },
      rateLegalWithoutImpact1: { label: 'Оцінку не проведено, юр.ос.  (крім держ. та комун. форми власності)', name: 'СтавкаЮрОБезОценки1' },
      rateLegalWithoutImpact12: { label: 'Оцінку не проведено, юр.ос. держ. форми власності', name: 'СтавкаЮрОБезОценки12' },
      rateLegalWithoutImpact13: { label: 'Оцінку не проведено, юр.ос. комун. форми власності', name: 'СтавкаЮрОБезОценки13' },
      rateNaturalWithoutImpact12: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки12' },
    },
  },
  TABL_22: {
    name: 'Табл22',
    label: 'Табл 22',
    columns: {
      code: { label: 'Код ', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування ', name: 'КласЗемли' },
      rateLegalWithImpact: { label: 'Оцінку проведено, юр.ос. ', name: 'СтавкаЮрОСОценкой' },
      rateNaturalWithImpact: { label: 'Оцінку проведено, фіз.ос. ', name: 'СтавкаФОСОценкой' },
      rateLegalWithoutImpact: { label: 'Оцінку не проведено, юр.ос. ', name: 'СтавкаЮрОБезОценки' },
      rateNaturalWithoutImpact: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки' },
      rateLegalWithImpact1: { label: 'Оцінку проведено, юр.ос.(крім державної та комунальної форми власност)', name: 'СтавкаЮрОСОценкой1' },
      rateLegalWithImpact11: { label: 'Оцінку проведено, юр.ос.державної форми власності', name: 'СтавкаЮрОСОценкой11' },
      rateNaturalWithImpact1: { label: 'Оцінку проведено, юр.ос.комунальної форми власності', name: 'СтавкаФОСОценкой1' },
      rateNaturalWithoutImpact1: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки1' },
      rateLegalWithoutImpact1: { label: 'Оцінку не проведено, юр.ос.  (крім державної та комунальної форми власності)', name: 'СтавкаЮрОБезОценки1' },
      rateLegalWithoutImpact12: { label: 'Оцінку не проведено, юр.ос. державної форми власності', name: 'СтавкаЮрОБезОценки12' },
      rateLegalWithoutImpact13: { label: 'Оцінку не проведено, юр.ос. комунальної форми власності', name: 'СтавкаЮрОБезОценки13' },
      rateNaturalWithoutImpact12: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФООБезОценки12' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 2',
  name: 'taxes2',
  backendName: 'НалогиИЛьготыДод2',
  columns,
  frontend: 'doc/taxes2',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
