import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { signatureVariants as md } from '../../../../constants/meta/catalogs/signatureVariants';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { soSelector } from '../../../documents/_common/selectors';
import { selectionMode } from '../../../../constants/meta/common';

function SignVariants({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const d = useSelector(soSelector);
  const isAdmin = d.get('is_admin', false);
  const parentFilter = useMemo(
    () => [
      { fieldName: md.columns.isGroup.name, value: true },
      { fieldName: md.columns.owner.name, value: data[md.columns.owner.name] },
    ],
    [data],
  );
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            modelName="csu"
            modelType="cat"
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            readOnly={readOnly || !isAdmin}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            maxLength={150}
            required
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            modelName="signatureVariants"
            modelType="cat"
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            filter={parentFilter}
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
    </Container>
  );
}

SignVariants.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SignVariants;
