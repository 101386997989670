import React from 'react';
import PropTypes from 'prop-types';

function Placeholder({ onFocus }) {
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return <span tabIndex={0} onFocus={onFocus} data-row="" data-col="-" />;
}

Placeholder.propTypes = {
  onFocus: PropTypes.func.isRequired,
};

export default Placeholder;
