import React, {
  lazy, Suspense, useMemo, useState, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import Fields from '../../field';
import { MyContainerFormWithNColumns, StyledLabel, FramePrintModal } from '../../../components/Form/styledForm';
import { SettingsIcon } from '../../../assets/icons';

import { ContainerButtonTop } from '../../tableEditor/styles';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

const TableEditor = lazy(() => import('../../tableEditor'));

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.infoPaymentFinRequest;

function InfoPaymentFinRequestEditor({ printAfterOpen, docFinRequest }) {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  useEffect(
    () => {
      dispatch(actions.changeField(['headerForm', 'result'], new Map()));
      if (printAfterOpen) {
        if (docFinRequest.get('repr', '') !== '') {
          dispatch(actions.changeField(['headerForm', 'docFinRequest'], docFinRequest));
          dispatch(actions.processServerReq('PRINT'));
        }
      }
    },
    [dispatch, docFinRequest, printAfterOpen],
  );

  return (
    <MainContainer>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal>

          <MyContainerFormWithNColumns columns={2}>
            <StyledLabel>{md.columns.docFinRequest.label}</StyledLabel>
            <Fields.SelectorField
              modelType="doc"
              modelName="financingRequest"
              value={headerForm.get('docFinRequest', new Map())}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'docFinRequest'], v))}
            />
          </MyContainerFormWithNColumns>
        </FramePrintModal>
      ) : (
        <Suspense fallback={<div>Завантаження...</div>}>
          <TableEditor mountNode={resultMountNode} />
        </Suspense>
      )}
    </MainContainer>
  );
}

InfoPaymentFinRequestEditor.propTypes = {
  printAfterOpen: PropTypes.bool,
  docFinRequest: PropTypes.node,
};

InfoPaymentFinRequestEditor.defaultProps = {
  printAfterOpen: false,
  docFinRequest: new Map(),
};

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null,
    },
    memo,
  ),

);

export default enchance(InfoPaymentFinRequestEditor);
