import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ClosedFolder,
  ClosedFolderDeleted,
  OpenedFolder,
  OpenedFolderDeleted,
  ClosedItem,
  ClosedItemDeleted,
  OpenedItem,
  OpenedItemDeleted,
  DocumentApproved,
  DocumentIcon,
  DocumentDeletedIcon,
} from '../../assets/icons';
import meta from '../../constants/meta';
import { hierarchyTypes } from '../../constants/meta/common';

function iconSwitcherClosed(isGroup, isDeleted, TOGGLED, isProcessed, modelType, modelName) {
  if (modelType === 'cat') {
    if (meta[modelType][modelName].hierarchyType === hierarchyTypes.foldersNItems
      && meta[modelType][modelName].hierarchy) {
      if (isGroup && TOGGLED && !isDeleted) return <OpenedFolder />;
      if (isGroup && !TOGGLED && !isDeleted) return <ClosedFolder />;
      if (isGroup && TOGGLED && isDeleted) return <OpenedFolderDeleted />;
      if (isGroup && !TOGGLED && isDeleted) return <ClosedFolderDeleted />;
      if (!isDeleted) return <DocumentIcon />;
      if (isDeleted) return <DocumentDeletedIcon />;
    }
    if (meta[modelType][modelName].hierarchyType === hierarchyTypes.onlyItems
      && meta[modelType][modelName].hierarchy) {
      if (TOGGLED && !isDeleted) return <img src={OpenedItem} alt="OpenedItem" />;
      if (!TOGGLED && !isDeleted) return <img src={ClosedItem} alt="ClosedItem" />;
      if (TOGGLED && isDeleted) return <img src={OpenedItemDeleted} alt="OpenedItemDeleted" />;
      if (!TOGGLED && isDeleted) return <img src={ClosedItemDeleted} alt="ClosedItemDeleted" />;
    }
  }
  // doc
  if (isDeleted) return <DocumentDeletedIcon />;
  if (isProcessed) return <DocumentApproved />;
  return <DocumentIcon />;
}

const Button = styled.button`
  border:none;
  outline: none;
  background: none;
  cursor:pointer;
  transform: scale(1.5);
  :focus {outline:none;}
`;
const CheckboxButton = forwardRef(({
  text,
  checked,
  onClick,
  isGroup,
  isDeleted,
  TOGGLED,
  isProcessed,
  modelType,
  modelName,
  ...a
}, ref) => (
  <Button
    title={text}
    onClick={onClick}
    toggle
    ref={ref}
    {...a}
  >
    {iconSwitcherClosed(isGroup, isDeleted, TOGGLED, isProcessed, modelType, modelName)}
  </Button>
));

CheckboxButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  checked: PropTypes.bool,
  isActive: PropTypes.bool,
  isGroup: PropTypes.bool,
  isDeleted: PropTypes.bool,
  TOGGLED: PropTypes.bool,
  isProcessed: PropTypes.bool,
  modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
  modelName: PropTypes.oneOf([
    ...new Set(Object.keys(meta).reduce(
      (r, typeName) => [...r, ...Object.keys(meta[typeName])],
      [],
    )),
  ]).isRequired,
};

CheckboxButton.defaultProps = {
  checked: true,
  isActive: false,
  isGroup: false,
  isDeleted: false,
  TOGGLED: false,
  isProcessed: false,
  text: '',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default CheckboxButton;
