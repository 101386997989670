import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

export const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled(Dropdown.Toggle)`
  background-color: inherit !important;
  border: none !important;
  border-radius: none !important;
  text-align: left !important;
  width: 100%;
   ::after {display: none!important};
    padding: 0 10px!important;
    margin-top: 0px!important;
    &:hover{
        background: var(--hoverNavBar)!important;
        color: white;
    }
`;

export const LinkItem = styled(Link)`
  display: block;
  color: var(--textForNavBar);
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  font-size: 14px;
  white-space: pre-wrap;
  margin: 4px 0;
  line-height: 1.3;
    :hover {
      color: var(--textForNavBar);
      text-decoration: none;
    }
    :active {
      color: var(--textForNavBar)
    }
`;
