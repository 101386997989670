import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  activateTableRow, addTableRow, copyTableRow, removeTableRow,
} from '../../../actions/editor';

const useBaseTablePart = ({
  tableName,
  data,
  arrBypassGrid,
}) => {
  const [activeId, setActiveId] = useState(0);
  const dispatch = useDispatch();
  const sumCells = arrBypassGrid.length;

  const elFocus = useCallback(
    (rowId, id, deltaHor = 0, deltaVer = 0) => {
      const sumRows = data.size;
      if (!sumRows > 0) return { rowId, id };

      if ((rowId === 0) && (deltaVer < 0)) {
        dispatch(activateTableRow(tableName, (sumRows - 1), false));
        return id;
      }

      if (((rowId + 1) === sumRows) && (deltaVer > 0)) {
        dispatch(activateTableRow(tableName, 0, false));
        return id;
      }

      if (deltaVer !== 0) {
        dispatch(activateTableRow(tableName, (rowId + deltaVer), false));
        return id;
      }

      if ((rowId === 0)
        && (id === 0)
        && (deltaHor < 0)) {
        dispatch(activateTableRow(tableName, (sumRows - 1), false));
        return (sumCells - 1);
      }

      if (((rowId + 1) === sumRows)
        && ((id + 1) === sumCells)
        && (deltaHor > 0)) {
        dispatch(activateTableRow(tableName, 0, false));
        return 0;
      }

      if (((id + 1) >= sumCells) && (deltaHor > 0)) {
        dispatch(activateTableRow(tableName, (rowId + 1), false));
        return 0;
      }

      if ((id === 0) && (deltaHor < 0)) {
        dispatch(activateTableRow(tableName, (rowId - 1), false));
        return sumCells - 1;
      }

      if (deltaHor !== 0) {
        dispatch(activateTableRow(tableName, rowId, false));
        return (id + deltaHor);
      }

      return id;
    },
    [data.size, dispatch, sumCells, tableName],
  );

  const nextFocus = useCallback(
    (rowId, id, deltaHor, deltaVer) => {
      setActiveId(elFocus(rowId, id, deltaHor, deltaVer));
    },
    [elFocus],
  );

  const processingKeyDown = useCallback(
    (e, rowId, id) => {
      const countRow = data.size;

      switch (e.keyCode) {
        case 13: {
          // Enter

          if ((rowId === (countRow - 1)) && (id === (sumCells - 1))) {
            setActiveId(0);
            dispatch(addTableRow(tableName));
            dispatch(activateTableRow(tableName, countRow, false));
          } else { nextFocus(rowId, id, 1, 0); }

          break;
        }
        case undefined: {
          // onClick
          nextFocus(rowId, id, 0, 0);
          break;
        }
        case 39: {
          // rigth

          if (e.target.value.length === e.target.selectionEnd) nextFocus(rowId, id, 1, 0);
          break;
        }
        case 37: {
          // left
          if (e.target.selectionStart === 0) nextFocus(rowId, id, -1, 0);
          break;
        }
        case 40: {
          // down
          nextFocus(rowId, id, 0, 1);
          break;
        }
        case 38: {
          // up
          nextFocus(rowId, id, 0, -1);
          break;
        }
        case 27: {
          // Esc
          nextFocus(rowId, id, 0, 0);
          break;
        }
        case 45: {
          // insert
          dispatch(addTableRow(tableName));
          dispatch(activateTableRow(tableName, countRow, false));

          break;
        }
        case 115: {
          // F4 (choice selector)
          nextFocus(rowId, id, 1, 0);
          break;
        }
        case 119: {
          // F8 (delete row)

          // const isActiveRowId = data.reduce(
          //   // eslint-disable-next-line no-confusing-arrow,no-nested-ternary
          //   (ii, row, key) => (ii === -1)
          //     ? (row.get('IS_ACTIVE', false) ? key : ii)
          //     : ii,
          //   -1,
          // );

          dispatch(removeTableRow(tableName));

          dispatch(activateTableRow(
            tableName,
            ((data.size - 2) > rowId) ? rowId : (data.size - 2),
            false,
          ));

          break;
        }
        case 120: {
          // F9 (copy row)
          dispatch(copyTableRow(tableName));
          break;
        }
        default: break;
      }
    },
    [data.size, dispatch, nextFocus, sumCells, tableName],
  );
  // useEffect(() => console.log('processingKeyDown', processingKeyDown), [processingKeyDown]);

  return {
    activeNameCell: arrBypassGrid[activeId],
    processingKeyDown,
  };
};

export default useBaseTablePart;
