import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form,
} from 'react-bootstrap';

function Comment({ func, onClose }) {
  const [comment, setComment] = useState(null);
  return (
    <>
      <h2>Введіть причину скасування ознаки</h2>
      <Form.Control as="textarea" rows={3} cols="30" onChange={(e) => setComment(e.target.value)} style={{ marginBottom: '10px' }} />
      <Button
        disabled={!comment}
        onClick={() => {
          func(false, comment);
          onClose();
        }}
      >
        Відправити
      </Button>
    </>
  );
}

Comment.propTypes = {
  func: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Comment;
