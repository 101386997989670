import {
  hierarchyTypes,
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  label: 'Класифікатор типових завдань',
  name: 'КлассификаторЗаданий',
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: false },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  IdMinFin: { label: 'ПК завдання (для обміну з Мінфіном)', name: 'ИдМинФин', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  Inactive: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: false,
  },
  KBPClassifier: {
    label: 'Кодифікатор бюджетних програм МБ (КБП)',
    name: 'КлассификаторКБП',
    visible: true,
  },
  Author: {
    label: 'Автор', name: 'Автор', visible: true,
  },
  NotActual: {
    label: 'Не діючий',
    name: 'НеДействующий',
    visible: true,
  },
};

export const tasksClassifier = {
  label: 'Типові завдання',
  name: 'tasksClassifier',
  backendName: 'КлассификаторЗаданий',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/tasksClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'KBPClassifier',
      'Author',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tasksClassifier;
