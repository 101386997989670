import {
  useCallback, useEffect, useState,
} from 'react';
import { useGenerateReport } from './render';

const useDetailsShower = ({ reportId, settings, renderReportUrl }) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [results, setResults] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);
  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);
  const {
    groups, selections, orders, filters, columns, params,
  } = settings;
  const { onGenerateReport } = useGenerateReport({
    renderReportUrl,
    reportId,
    groups,
    selections,
    orders,
    filters,
    columns,
    param: params,
    onStart: onLoadStart,
    onEnd: onLoadEnd,
    onDone: setResults,
    onError,
  });

  useEffect(() => onGenerateReport(), [onGenerateReport]);

  return {
    loading,
    err,
    results,
  };
};

export default useDetailsShower;
