import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import DocsTP from './tablepart/apdocsTP';
import DateField from '../../../components/fields/dates';
import storePathParam from '../../../common/storePathParam';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import { changeField } from '../../../actions/reportEditor';

const md = meta.dp.exportPlansAndChangesToDBF;

const StringInput4 = Fields.GetTextInput(4);
const StringInput10 = Fields.GetTextInput(10);

const assignTypes = {
  TranscriptKEKV1: { label: 'Асигнування', value: 2 },
  TranscriptKEKV2: { label: 'Надання кредитів', value: 3 },
  TranscriptKEKV3: { label: 'Повернення кредитів', value: 4 },
};

const codeInNameTypes = {
  none: { label: 'Немає', value: 0 },
  csu: { label: 'Гол.розпорядник', value: 1 },
  sub: { label: 'Установа', value: 2 },
};

const TypeChanges = {
  Year: { label: 'Рік', name: 1 },
  Quartal: { label: 'Квартал', name: 2 },
  Mounth: { label: 'Місяць', name: 3 },
};

class ExportPlansAndChangesToDBFEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      // firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        {/* <div style={{ marginBottom: '10px' }}> */}
        {/*  <StyledLabel>{md.columns.FileName.label}</StyledLabel> */}
        {/*  <StringField20 */}
        {/*    radius */}
        {/*    background */}
        {/*    textLeft */}
        {/*    value={headerForm.get('FileName', '')} */}
        {/*    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FileName'], v))} */}
        {/*  /> */}
        {/* </div> */}
        {headerForm.get('isFO', false) && (
          <MyContainerFormWithNColumns columns={6}>
            <div>
              <StyledLabel>{md.columns.Variant.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('Variant', false)}
                options={Object.values(TypeChanges)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Variant'], v))}
              />
            </div>
            <Fields.CheckboxField
              label={md.columns.Reg.label}
              value={headerForm.get('Reg', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Reg'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.PCM.label}
              value={headerForm.get('PCM', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PCM'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.SF111.label}
              value={headerForm.get('SF111', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SF111'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.NoArchive.label}
              value={headerForm.get('NoArchive', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoArchive'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.OneFile.label}
              value={headerForm.get('OneFile', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OneFile'], v))}
            />
            {/* <Fields.CheckboxField */}
            {/*  label={md.columns.InOrder.label} */}
            {/*  value={headerForm.get('InOrder', false)} */}
            {/*  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'InOrder'], v))} */}
            {/* /> */}
            <Fields.CheckboxField
              value={headerForm.get('f6Separate', false)}
              label={md.columns.f6Separate.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'f6Separate'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('all7', false)}
              label={md.columns.all7.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'all7'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.noKDB.label}
              value={headerForm.get('noKDB', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noKDB'], v))}
            />
          </MyContainerFormWithNColumns>
        )}
        {(headerForm.get('isFO', false) === false) && (
          <MyContainerFormWithNColumns columns={3}>
            <div>
              <StyledLabel>{md.columns.assign.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('assign', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'assign'], v))}
                values={Object.values(assignTypes)}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.NR.label}</StyledLabel>
              <StringInput4
                border
                radius
                background
                value={headerForm.get('NR', 0)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NR'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.NOM.label}</StyledLabel>
              <StringInput10
                radius
                background
                value={headerForm.get('NOM', '')}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NOM'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
        )}
        {(headerForm.get('isFO', false) === false) && (
          <MyContainerFormWithNColumns columns={4}>
            <div>
              <StyledLabel>Код розпорядника в імені файла </StyledLabel>
              <RadioButton
                value={headerForm.get('codeInName', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'codeInName'], v))}
                values={Object.values(codeInNameTypes)}
                name="codeInName"
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('nameFileChange', false)}
                label={md.columns.nameFileChange.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'nameFileChange'], v))}
              />
              <Fields.CheckboxField
                value={headerForm.get('flDNF', false)}
                label={md.columns.flDNF.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'flDNF'], v))}
              />
              <Fields.CheckboxField
                value={headerForm.get('UploadAsChanges', false)}
                label={md.columns.UploadAsChanges.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'UploadAsChanges'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('NoYearSummIfZero', false)}
                label={md.columns.NoYearSummIfZero.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoYearSummIfZero'], v))}
              />
              <Fields.CheckboxField
                value={headerForm.get('onlyYear', false)}
                label={md.columns.onlyYear.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyYear'], v))}
              />
              <Fields.CheckboxField
                value={headerForm.get('PCM', false)}
                label={md.columns.PCM.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PCM'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('inHeadCSU', false)}
                label={md.columns.inHeadCSU.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inHeadCSU'], v))}
              />
              <Fields.CheckboxField
                value={headerForm.get('NoYearSumm', false)}
                label={md.columns.NoYearSumm.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoYearSumm'], v))}
              />
              {/* <Fields.CheckboxField */}
              {/*  value={headerForm.get('f6Separate', false)} */}
              {/*  label={md.columns.f6Separate.label} */}
              {/*  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'f6Separate'], v))} */}
              {/* /> */}
              {/* <Fields.CheckboxField */}
              {/*  value={headerForm.get('all7', false)} */}
              {/*  label={md.columns.all7.label} */}
              {/*  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'all7'], v))} */}
              {/* /> */}
            </div>
          </MyContainerFormWithNColumns>
        )}
        <MyContainerFormWithNColumns columns={3}>
          <div>
            <StyledLabel>{md.columns.Date1.label}</StyledLabel>
            <DateField
              value={headerForm.get('Date1', null)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Date1'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.Date2.label}</StyledLabel>
            <DateField
              value={headerForm.get('Date2', null)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Date2'], v))}
            />
          </div>
          <div>
            <CommandPanelColor
              style={{ marginTop: '10px' }}
              text="Отримати документи"
              label="Отримати документи"
              onClick={() => dispatch(actions.processServerReq('UPDATE'))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(<TPCommandPanel tableName="docs" />));

function TablesRender() {
  return <DocsTPRenderer />;
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

const connectedTablesRender = connect(mapStateTP)(TablesRender);

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportPlansAndChangesToDBF',
    },
    {
      menu: () => null,
      tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ExportPlansAndChangesToDBFEditor);
