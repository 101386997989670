import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import { CommandPanelButtonText } from '../../../components/Form/styledForm';

class changeToAssignPlanSourceEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    // headerForm: PropTypes.instanceOf(Map),
    // onOK: PropTypes.func,
    // isLoading: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {},
    // headerForm: null,
    // onOK: null,
    // isLoading: false,
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    return (
      <div>
        <CommandPanelButtonText>Друк</CommandPanelButtonText>
        <CommandPanelButtonText>Закрити</CommandPanelButtonText>
      </div>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: 'ChangeToAssignPlanSource',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enhance(changeToAssignPlanSourceEditor);
