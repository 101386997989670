import React, {
  useState, useEffect, useCallback, memo, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Collection, Map } from 'immutable';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Container } from 'react-bootstrap';
import {
  FrameDiv, CollapseButton, ExpandArrow, ErrorMessage,
} from '../../components/Form/styledForm';
import {
  useEditorActions,
} from '../../actions/editor2';
import def from '../../constants/meta';
import AttachedFiles from '../infoRegs/attachedFiles';
import { Loader } from '../../components/bootstrap_components';

export { default as getRouterEditor } from '../catalogs/routerEditor';

const getSelector = (modelType, modelName, id) => (store) => {
  // const winManagerState = store.get('windowsManager', new Map());
  // const modelName = winManagerState.get('name', '');
  // const modelType = winManagerState.get('type', '');
  // const currentMatch = winManagerState.get('currentMatch', { id: '' });
  // const { id } = (typeof currentMatch === 'undefined' ? { id: '' } : currentMatch);

  const editorNode = store.getIn([`${modelType}/${modelName}/editor`, id], new Map());
  return {
    hierarchyType: editorNode.get('hierarchyType', 'item'),
    sessionOptions: store.getIn(['auth', 'sessionOptions'], new Map()),
    canBeSaved: editorNode.get('canBeSaved', false),
    isLoading: editorNode.get('isLoading', true),
    isProcessing: editorNode.get('isProcessing', true),
    isChanged: editorNode.get('isChanged', false),
    isValidated: editorNode.get('isValidated', false),
    hasError: editorNode.get('hasError', false),
    errorMsg: editorNode.get('errorMsg', ''),
    headerForm: editorNode.get('headerForm', new Map()), // Шапка
    disabledFields: editorNode.get('disabledFields', new Collection.Set()),
    notes: editorNode.get('notes', ''),
    isOpenAttachedFiles: editorNode.get('isOpenAttachedFiles', true),
    AttachedFiles: editorNode.get('AttachedFiles', []),
    isLoadingAttachedFiles: editorNode.get('isLoadingAttachedFiles', true),
    warnings: editorNode.get('warnings', new Map()),
  };
};

export const useEditorSelector = (modelType, modelName, id) => useMemo(
  () => getSelector(modelType, modelName, id),
  [id, modelName, modelType],
);

const WarningsContainer = styled.div`
  border: 1px solid #afbbcc;
  border-radius: .3em;  
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
  overflow: auto;
  position: sticky;
  bottom: 0;
  z-index: 12;
  background: #fddbdb;
  &>button {
    position: absolute;
    top: 4px;
    right: 10px;
    background: transparent;
    width: 20px;
    height: 20px;
    padding: 2px 1px;
    border-radius: 50%;
    cursor: pointer;
    border: none;      
  }
  &>button:hover, &>button:focus {
    border: 1px solid  #4281C9;
    outline: none;
  }
  &>span::before {
    content: '!!!';
    color: darkred;
    margin-right: 10px;
    font-weight: 700;
  }
`;

/*
 * @param modelNameNType {Object}
 * @param modelNameNType.name {string}
 * @param modelNameNType.type {string}
 * @param {extra} {Object}
 * @param {extra.menu} {React.Component}
 * @param {extra.tables} {React.Component}
 * @param {extra.footer} {React.Component}
 * @param {extra.canCollapsed} {bool}
 * @returns {function(<object>): *}
 * @constructor
 */

const EditorHOC = ({ name, type }, extra) => (WrappedComponent) => {
  function BasicEditor({
    id, search, onClose, onSave,
  }) {
    const selector = useMemo(
      () => getSelector(type, name, id),
      [id],
    );

    const dispatch = useDispatch();

    const {
      warnings, isOpenAttachedFiles, isLoading, isProcessing,
      hasError, isLocked, isChanged, errorMsg, headerForm,
    } = useSelector(selector);

    const [isOpened, setIsOpened] = useState(true);

    const actions = useEditorActions(type, name, id);

    useEffect(
      () => {
        const c = new URLSearchParams(search);
        const initParams = {
          ...(c.get('reason') ? { reason: c.get('reason') } : {}),
          isGroup: c.get('g'),
          copyFrom: c.get('copyFrom'),
        };
        dispatch(actions.init(initParams));
        dispatch(actions.load());
        dispatch(actions.loadAttachedFiles());
      },
      [actions, dispatch, id, search],
    );

    const collapseMenu = useCallback(
      () => setIsOpened((v) => !v),
      [],
    );

    const MenuWrapper = extra && extra.menu;
    const TablesWrapper = extra && extra.tables;
    const displayWarnings = warnings.reduce((R, w) => [
      ...R,
      ...w.reduce((R2, w2) => [...R2, w2.get('msg')], []),
    ], []);

    const containerRef = useRef(null);

    const Footer = useMemo(
      // eslint-disable-next-line no-confusing-arrow
      () => extra ? extra.footer : null,
      [],
    );

    return (
      <Container ref={containerRef} fluid>
        {(isLoading || isProcessing) && (
          <Loader />
        )}
        {MenuWrapper && (
          <MenuWrapper
            dispatch={dispatch}
            onClose={onClose}
            onSave={onSave}
            disabled={isLoading || hasError || isProcessing}
            isLocked={isLocked}
            isChanged={isChanged}
            header={type === 'doc' ? headerForm.get('repr') : `${def[type][name].label}: ${headerForm.get('repr')}`}
            id={id}
            modelName={name}
            modelType={type}
            containerRef={containerRef}
          />
        )}
        {hasError && (
          <ErrorMessage>
            {errorMsg}
          </ErrorMessage>
        )}
        <FrameDiv>

          {isOpenAttachedFiles && <AttachedFiles /> }

          {isOpened ? (
            <>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <WrappedComponent
                id={id}
                headerForm={headerForm}
                containerRef={containerRef}
              />
              {extra.canCollapsed && (
                <CollapseButton isOpened={isOpened} onClick={collapseMenu} title="Згорнути" />
              )}
            </>
          ) : (
            <ExpandArrow isOpened={isOpened} onClick={collapseMenu} title="Розгорнути" />
          )}
        </FrameDiv>
        <div style={{ clear: 'both' }} />
        {!isLoading && TablesWrapper && <TablesWrapper id={id} containerRef={containerRef} />}
        {Footer && (
          <Footer
            headerForm={headerForm}
            id={id}
            containerRef={containerRef}
          />
        )}

        {displayWarnings.length > 0 && (
          <Alert show onClose={() => dispatch(actions.clearWarnings())} variant="danger" dismissible className="position-sticky fixed-bottom">
            <Alert.Heading>На жаль щось пішло не так :-(</Alert.Heading>
            {displayWarnings.map((w) => (
              <p key={w}>{w}</p>
            ))}
          </Alert>
        )}
      </Container>
    );
  }

  BasicEditor.displayName = `Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  BasicEditor.propTypes = {
    id: PropTypes.string.isRequired, // id элемента
    search: PropTypes.string, // Параметры адресной строки (то что идет после ?)
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  BasicEditor.defaultProps = {
    search: '',
  };

  return memo(BasicEditor);
};

export default EditorHOC;
