import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/kbp7Classifier';

const dateParam = 'НаДату';

function ElementListerSelector({
  filter, params, children, ...restProps
}) {
  const [currentDate, setCurrentDate] = useState(() => params.filter(
    (p) => p.name === dateParam,
  ).reduce((R, p) => p.value, new Date().valueOf()));

  const [showInactive, setShowInactive] = useState(false);

  const prm = useMemo(
    () => [
      ...params.filter((p) => p.name !== dateParam),
      {
        name: dateParam,
        value: currentDate,
      },
    ],
    [currentDate, params],
  );

  const flt = useMemo(
    () => [
      ...filter,
      ...(showInactive ? [] : [
        {
          fieldName: md.columns.Inactive.name,
          value: false,
        }]),
    ],
    [filter, showInactive],
  );

  const CH = cloneElement(Children.only(children), {
    ...restProps,
    params: prm,
    filter: flt,
  });

  return (
    <>
      <Row>
        <Col>
          <EditorControls.DateInput
            label="Показувати на дату"
            value={currentDate}
            onChange={(e, v) => setCurrentDate(v)}
          />
        </Col>
        <Col sm={2} className="d-flex align-items-end text-right">
          <EditorControls.CheckboxInput
            controlId="KBP_Show_Inactives"
            label="Показати недіючи"
            value={showInactive}
            onChange={(e, v) => setShowInactive(v)}
            description="Якщо обрано, будуть показані діючи та недіючи коди"
          />
        </Col>
      </Row>
      {CH}
    </>
  );
}

ElementListerSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.instanceOf(Map),
    ]),
  })),
  children: PropTypes.element.isRequired,
};

ElementListerSelector.defaultProps = {
  filter: [],
  params: [],
};

export default ElementListerSelector;
