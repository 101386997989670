import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/expensesOnHealth';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'TP2282';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI, onSR, expensesOnHealthKyiv,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const onSumT1RXXXXGChange = useCallback(
    (e, value, colName, totColName) => {
      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
      });
    },
    [onRowChange, row],
  );

  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.T1RXXXXG1S.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onKBP7Change = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.T1RXXXXG51S.name]: v });
      await onSR('CHANGE_KBP7_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG51S" highlighted={highlights.includes('T1RXXXXG51S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG51S.name]}
                modelType="cat"
                modelName="kbp7Classifier"
                choiceSettings={selectionMode.items}
                onChange={onKBP7Change}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG1S" highlighted={highlights.includes('T1RXXXXG1S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG1S.name]}
                modelType="cat"
                modelName="csu"
                onChange={onCSUChange}
                readOnly={readOnly || expensesOnHealthKyiv}
                filter={CSUFIlter}
                noHierarchy
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG27" highlighted={highlights.includes('T1RXXXXG27')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG27.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG27.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG28" highlighted={highlights.includes('T1RXXXXG28')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG28.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG28.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG29" highlighted={highlights.includes('T1RXXXXG29')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG29.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG29.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG30" highlighted={highlights.includes('T1RXXXXG30')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG30.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG30.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG31" highlighted={highlights.includes('T1RXXXXG31')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG31.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG31.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG32" highlighted={highlights.includes('T1RXXXXG32')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG32.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG32.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG33" highlighted={highlights.includes('T1RXXXXG33')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG33.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG33.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG34" highlighted={highlights.includes('T1RXXXXG34')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG34.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG34.name,
                  tableMD.columns.T1RXXXXG27.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG35" highlighted={highlights.includes('T1RXXXXG35')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG35.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG35.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG36" highlighted={highlights.includes('T1RXXXXG36')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG36.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG36.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG37" highlighted={highlights.includes('T1RXXXXG37')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG37.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG37.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG38" highlighted={highlights.includes('T1RXXXXG38')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG38.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG38.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG39" highlighted={highlights.includes('T1RXXXXG39')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG39.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG39.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG40" highlighted={highlights.includes('T1RXXXXG40')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG40.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG40.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG41" highlighted={highlights.includes('T1RXXXXG41')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG41.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG41.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG42" highlighted={highlights.includes('T1RXXXXG42')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG42.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG42.name,
                  tableMD.columns.T1RXXXXG35.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG43" highlighted={highlights.includes('T1RXXXXG43')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG43.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG43.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG44" highlighted={highlights.includes('T1RXXXXG44')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG44.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG44.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG45" highlighted={highlights.includes('T1RXXXXG45')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG45.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG45.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG46" highlighted={highlights.includes('T1RXXXXG46')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG46.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG46.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG47" highlighted={highlights.includes('T1RXXXXG47')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG47.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG47.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG48" highlighted={highlights.includes('T1RXXXXG48')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG48.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG48.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG49" highlighted={highlights.includes('T1RXXXXG49')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG49.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG49.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG50" highlighted={highlights.includes('T1RXXXXG50')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG50.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG50.name,
                  tableMD.columns.T1RXXXXG43.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T1RXXXXG1S.name]: referencePropType,
    [tableMD.columns.T1RXXXXG50.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG49.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG48.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG47.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG46.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG45.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG44.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG43.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG42.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG41.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG40.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG39.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG38.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG37.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG36.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG35.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG34.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG33.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG32.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG31.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG30.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG29.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG28.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG27.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType,
  onSR: PropTypes.func.isRequired,
  expensesOnHealthKyiv: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  FI: null,
  expensesOnHealthKyiv: false,
};

export default memo(TPRow);
