import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/catalogs/typesRepair';

const tablename = 'additionalProperties';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="period" highlighted={highlights.includes('period')}>
          <EditorControls.DateInput
            value={row[tableMD.columns.period.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.period.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="propertyType" highlighted={highlights.includes('propertyType')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.propertyType.name].repr}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.propertyType.name]: value })}
            readOnly={readOnly}
          />
          {/* <ItemPicker */}
          {/*    value={row[tableMD.columns.propertyType.name]} */}
          {/*    modelType="cat" */}
          {/*    modelName="propertyType" */}
          {/*    onChange={(e, v) => onRowChange(e, { [tableMD.columns.propertyType.name]: v })} */}
          {/*    readOnly={readOnly} */}
          {/* /> */}
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="val" highlighted={highlights.includes('val')}>
          <EditorControls.StringInput
            value={row[tableMD.columns.val.name]}
            onChange={(e, value) => onRowChange(e, { [tableMD.columns.val.name]: value })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
