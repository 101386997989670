// eslint-disable-next-line import/no-cycle
import budget from './budgets';
import disposers from './disposers';
// import authoritysignvariant from './authoritysignvariant';
import fond from './fond';
import fondItem from './fondItem';
import kbp from './kbp';
import kbp7 from './kbp7';
import kbp7item from './kbp7item';
import kdb from './kdb';
import kekv from './kekv';
import kekvItem from './kekvItem';
import kfb from './kfb';
import kfk from './kfk';
import kvk from './kvk';
import shortkekv from './shortkekv';
// import treasureaccount from './treasureaccount';

const cats = {
  budget,
  disposers,
  // authoritysignvariant,
  fond,
  fondItem,
  kbp,
  kbp7,
  kbp7item,
  kdb,
  kekv,
  kekvItem,
  kfb,
  kfk,
  kvk,
  shortkekv,
  // treasureaccount,
};

export default cats;
