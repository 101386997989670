import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  ticket: { label: 'Токен для обмыну ынформацыэю', name: 'ТокенОбмена' },
  requestId: { label: 'Ідентифікатор запиту на авторизацію', name: 'ИдЗапросаНаАвторизацию' },
  signBase64: { label: 'Внутрішній електронний підпис рядка,', name: 'ИдЗапросBase64' },

};

const tables = {

};

/**
 * @const
 */
const definition = {
  name: 'digitalSing',
  backendName: 'работаЭЦП',
  frontend: 'dp/digitalSing',
  label: 'Створення кваліфікованого електронного підпису',
  columns,
  tables,
};
export default definition;
