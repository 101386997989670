/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { moneysOneDigitStingOptions } from '../../../../../constants/moneys';
// import './styles.css';

function TableRow({ item, isLast }) {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium',
          {
            'border-bottom-0': isLast,
          },
        )}
      >
        {item.repr}
        {/* <Link to="#platFactAcc">{item.name}</Link> */}
      </td>
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast,
        })}
      >
        {item.plan.toLocaleString('uk', moneysOneDigitStingOptions)}
      </td>
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast,
        })}
      >
        {item.fact.toLocaleString('uk', moneysOneDigitStingOptions)}
      </td>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast,
        })}
      >
        <ProgressBar now={item.percent} style={{ height: 5 }} />
      </td>
    </tr>
  );
}
function GrkTable({ data }) {
  return (
    <Table className="fs--1 mb-0">
      <thead className="bg-200 text-800">
        <tr>
          <th style={{ width: '55%' }}>Назва розпорядника коштів</th>
          <th style={{ width: '15%' }} className="text-center">План</th>
          <th style={{ width: '15%' }} className="text-center">Факт</th>
          <th style={{ width: '15%' }} className="text-end table-column-width-30">% виконання</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableRow
            key={index}
            item={item}
            isLast={data.length - 1 === index}
          />
        ))}
      </tbody>
    </Table>
  );
}

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    repr: PropTypes.string.isRequired,
    plan: PropTypes.number.isRequired,
    fact: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }),
  isLast: PropTypes.bool.isRequired,
};

TableRow.defaultProps = {
  item: {},
};

GrkTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      repr: PropTypes.string.isRequired,
      plan: PropTypes.number.isRequired,
      fact: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }),
  ),
};

GrkTable.defaultProps = {
  data: [],
};

export default GrkTable;
