import React, {
  useMemo, useState, forwardRef, useContext, useEffect, useCallback, Suspense,
  lazy, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  Modal, Nav, Button, Spinner, Overlay, Form, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import {
  faEllipsisH, faTimes, faCheck, faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { CiatAppContext } from '../../../providers';
import { ItemField, valuePropType } from './itemField';
import { DataList } from './datalist';
import api from '../../../api/req';
import { debounce } from '../../../api/utils';
import meta, { modelNames, modelTypes, metaBackends } from '../../../meta';
import typesOfChoices from '../../../constants/typesOfChoices';
import { childrenPropType } from '../../../constants/backend/propTypes';

const StyledDataList = styled.div`
  z-index: 2000;
  right: 0;
`;

const renderTooltipIconEdit = (props) => (
  <Tooltip id="button-tooltip-Edit" {...props}>
    Відкрити у новому вікні
  </Tooltip>
);

const renderTooltipIconChoice = (props) => (
  <Tooltip id="button-tooltip-Choice" {...props}>
    Відкрити весь список
  </Tooltip>
);

export const ItemPicker = forwardRef(({
  id, value, canErase, onChange, filter, disabled, noHierarchy,
  onClick, onFocus, onBlur, onDropDown,
  readOnly, modelType, modelName, errors, backend,
  className, prepend, append, errorAsTooltip, typeOfChoice, noIconEdit, ...restProps
}, ref) => {
  const inputRef = useRef();
  const containerRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);
  const MetaInfo = useMemo(() => {
    if (modelType && modelName) {
      if (!(modelType in meta) || !(modelName in meta[modelType])) {
        throw new Error(`${modelType} / ${modelName} невідомі. Перевірте Ваш код!`);
      }
      return {
        backend: meta[modelType][modelName].backendURL,
      };
    }
    if (backend) {
      if (!(backend in metaBackends)) return { backend: null };
      return {
        backend,
      };
    }

    return {
      backend,
    };

    // throw new Error('no modelType, modelName or backend props in ItemPicker');
  }, [modelName, modelType, backend]);

  const ItemUrl = useMemo(() => {
    if (backend && value?.id) {
      return `#${metaBackends[backend].frontendURL}${value.id}`;
    }
    return '#';
  }, [backend, value]);

  const [editText, setEditText] = useState(null);
  const [okEnabled, setOkEnabled] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [activeListItem, setActiveListItem] = useState(null);
  const [items, setItems] = useState([]);
  const [listOpened, setListopened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [curValue, setCurValue] = useState(value);
  const { auth } = useContext(CiatAppContext);
  const loadRequestController = useRef(null);
  const oldTimer = useRef(null);

  const searchFilter = useMemo(
    () => {
      switch (typeOfChoice) {
        case typesOfChoices.onlyElements:
          return { ...filter, is_group: false };
        case typesOfChoices.onlyGroups:
          return { ...filter, is_group: true };
        default:
          return filter;
      }
    },
    [filter, typeOfChoice],
  );
  const displayFilter = useMemo(
    () => {
      switch (typeOfChoice) {
        case typesOfChoices.onlyGroups:
          return { ...filter, is_group: true };
        default:
          return filter;
      }
    },
    [filter, typeOfChoice],
  );
  const makeRequest = useMemo(() => debounce((search) => {
    const ldr = async () => {
      const r = await api.get(
        MetaInfo.backend,
        auth,
        { ...searchFilter, search },
        false,
        loadRequestController.current,
      );
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      return r.json();
    };
    loadRequestController.current = new AbortController();
    setLoading(true);
    setErr(null);
    ldr()
      .then((data) => {
        let itms;
        if (('results') in data && data.results.length) {
          itms = data.results;
        } else if (data.length) {
          itms = data;
        } else {
          itms = [{ id: null, repr: 'Нічого не знайдено' }];
        }
        setItems(itms);
        setActiveListItem(itms.length ? 0 : null);
        setListopened(!!itms.length);
        if (onDropDown) onDropDown();
      })
      .catch((e) => setErr(e.message))
      .finally(() => {
        setLoading(false);
        loadRequestController.current = null;
      });
  }), [MetaInfo.backend, auth, onDropDown, searchFilter]);
  oldTimer.current = makeRequest.timer;

  const onDataListSelect = useCallback(
    (e, v) => {
      setListopened(false);
      if (v && v.id) {
        onChange(e, v);
      }
      setEditText(null);
    },
    [onChange],
  );

  const openModal = useCallback(
    () => {
      setModalOpened(true);
      setListopened(false);
    },
    [],
  );

  const handleUserKeyPress = useCallback((e) => {
    const { key } = e;
    // if (containerRef.current.contains(e.target)) {
    if (key === 'F4') {
      openModal();
    } else if (listOpened) {
      if (key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        setActiveListItem((i) => (i ? i - 1 : items.length - 1));
      } else if (key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        setActiveListItem((i) => (i < items.length - 1 ? i + 1 : 0));
      } else if (key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onDataListSelect(e, items[activeListItem]);
      } else if (key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        setListopened(false);
        setEditText(null);
      }
      // }
    }
  }, [activeListItem, items, listOpened, onDataListSelect, openModal]);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('keydown', handleUserKeyPress);

    return () => {
      container.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const onBlurIn = useCallback(
    (e) => {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        if (loadRequestController.current) loadRequestController.current.abort();
        clearTimeout(oldTimer.current);
        setListopened(false);
        setEditText(null);
      }
    },
    [],
  );
  useEffect(
    () => {
      // Тушить открытое меню поиска при покидании фокуса
      // if (!listOpened) return undefined;
      document.addEventListener('focusout', onBlurIn);
      return () => document.removeEventListener('focusout', onBlurIn);
    },
    [onBlurIn],
  );

  const onOk = useCallback(
    (e) => {
      if (okEnabled) {
        setModalOpened(false);
        onChange(e, curValue);
      }
    },
    [curValue, okEnabled, onChange],
  );

  const Selector = useMemo(
    // eslint-disable-next-line import/no-cycle
    () => lazy(() => import('./selector')),
    [],
  );
  const onSelectorChoice = useCallback(
    (e, v) => {
      if (okEnabled) {
        onChange(e, v);
        setModalOpened(false);
      }
    },
    [okEnabled, onChange],
  );
  const onSelectorSelect = useCallback(
    (e, v) => {
      if (typeOfChoice !== null) {
        switch (typeOfChoice) {
          case typesOfChoices.onlyElements:
            setOkEnabled(!v.is_group);
            break;
          case typesOfChoices.onlyGroups:
            // Должен справляться фильтр.
            // такая конструкция нужна для отчетов (нпр распорядитель в группе)
            setOkEnabled(true);
            break;
          case typesOfChoices.groupsEndElements:
            setOkEnabled(true);
            break;
          default:
            setOkEnabled(false);
            break;
        }
      } else {
        setOkEnabled(true);
      }
      setCurValue(v);
    },
    [typeOfChoice],
  );
  const errored = !!errors && !!errors.length;

  const stopEvent = useCallback(
    (e) => e.stopPropagation(),
    [],
  );

  return (
    <div ref={containerRef} className="w-100" onDoubleClick={stopEvent}>
      <ItemField
        id={id}
        value={value}
        isLoading={loading}
        editText={editText}
        onChange={(e, v) => {
          setEditText(v);
          if (loadRequestController.current) loadRequestController.current.abort();
          makeRequest(v);
        }}
        readOnly={disabled || readOnly}
        onClick={onClick}
        onFocus={onFocus}
        ref={inputRef}
        onBlur={onBlur}
        errored={errored}
        className={className}
        prepend={prepend}
        append={append}
      >
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltipIconChoice}
        >
          <Button
            onClick={openModal}
            variant="falcon-secondary"
            disabled={readOnly || disabled}
            tabIndex={-1}
            className="px-2"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </OverlayTrigger>
        {!noIconEdit
          && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipIconEdit}
          >
            <Button
              href={ItemUrl}
              variant="falcon-secondary"
              tabIndex={-1}
              className="px-2"
              disabled={!value}
            >
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </Button>
          </OverlayTrigger>
          )}
        {canErase
          && (
            <Button
              onClick={(e) => {
                onChange(e, null);
                setEditText(null);
              }}
              variant="falcon-secondary"
              disabled={readOnly || disabled || !value}
              tabIndex={-1}
              className="px-2"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        {err && <small className="text-danger">{err}</small> }
        {errors && (
        <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </Form.Control.Feedback>
        )}
      </ItemField>
      <Overlay
        target={inputRef.current}
        show={listOpened}
        placement="bottom-start"
        flip
        container={containerRef.current}
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          // eslint-disable-next-line react/prop-types
          <StyledDataList className="position-absolute bg-white rounded shadow" {...props}>
            <DataList
              data={items}
              activeItem={activeListItem}
              onSelect={onDataListSelect}
            />
          </StyledDataList>
        )}
      </Overlay>
      <Modal
        show={modalOpened}
        fullscreen
        scrollable
        centered
        onHide={() => setModalOpened(false)}
        container={containerRef.current}
      >
        <Modal.Header>
          <Modal.Title>
            {MetaInfo.label}
          </Modal.Title>
          <Nav className="flex-fill">
            <ButtonGroup className="ms-auto">
              <Button variant="success" disabled={!okEnabled} onClick={onOk}>
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Обрати
              </Button>
              <Button variant="danger" onClick={() => setModalOpened(false)}>
                <FontAwesomeIcon icon={faTimes} className="me-2" />
                Скасувати
              </Button>
            </ButtonGroup>
          </Nav>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<Spinner size="sm" />}>
            <Selector
              filter={displayFilter}
              onChoice={onSelectorChoice}
              onSelect={onSelectorSelect}
              value={value}
              currentId={value && value.id}
              noHierarchy={noHierarchy}
              backend={backend}
              {...restProps}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
    </div>
  );
});

ItemPicker.propTypes = {
  id: PropTypes.string,
  value: valuePropType,
  canErase: PropTypes.bool,
  noIconEdit: PropTypes.bool,
  onChange: PropTypes.func,
  filter: PropTypes.shape({}), // key equals value
  disabled: PropTypes.bool,
  noHierarchy: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onDropDown: PropTypes.func,
  readOnly: PropTypes.bool,
  modelType: PropTypes.oneOf(modelTypes),
  modelName: PropTypes.oneOf(modelNames),
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  backend: PropTypes.oneOf(Object.keys(metaBackends)),
  prepend: childrenPropType,
  append: childrenPropType,
  errorAsTooltip: PropTypes.bool,
  typeOfChoice: PropTypes.oneOf(Object.values(typesOfChoices)),
};

ItemPicker.defaultProps = {
  id: '',
  value: { id: null, repr: '' },
  canErase: false,
  noIconEdit: false,
  onChange: () => null,
  filter: null,
  disabled: false,
  noHierarchy: false,
  onClick: null,
  onFocus: null,
  onBlur: null,
  onDropDown: null,
  readOnly: false,
  errors: null,
  className: null,
  backend: null,
  modelType: null,
  modelName: null,
  prepend: null,
  append: null,
  errorAsTooltip: false,
  typeOfChoice: typesOfChoices.groupsEndElements,
};

export default ItemPicker;
