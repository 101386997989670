import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import './minfin.css';
import './assets/css/react-datepicker.css';
import moment from 'moment';
import uk from 'date-fns/locale/uk';
import { registerLocale } from 'react-datepicker';
import 'moment/locale/uk';
import Root from './containers/root';
import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './components/wrappers/errorBoundary';
import Main from './minfin/components/blanks/Main';

moment.locale('uk');
registerLocale('uk', uk);

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://b75da21c8ef5428db2416e32d2dcf596@o576767.ingest.sentry.io/5730761',
//     integrations: [...Sentry.defaultIntegrations, new Integrations.BrowserTracing()],
//     environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//
//     // Установите tracesSampleRate на 1.0,
//     // чтобы захватывать 100% транзакций для мониторинга производительности.
//     // Рекомендуется скорректировать это значение в процессе производства.
//     // Чтобы уменьшить объем собираемых данных о производительности,
//     // измените значение tracesSampleRate на значение от 0 до 1.
//     tracesSampleRate: 1.0,
//   });
// }

const root = createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
    <Main>
      <Root />
    </Main>
  </ErrorBoundary>,
);

registerServiceWorker();
// unregister();
