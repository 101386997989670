import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faUser, faComment, faLock, faLockOpen, faFileArchive,
} from '@fortawesome/free-solid-svg-icons';
import md from '../../../../../../constants/meta/documents/financingRequest';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';
import DocsCommandPanel from '../../../../../newEditor/commandPanels/docstp';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { moneysStingOptions } from '../../../../../../constants/common';
import DefaultCPButton from '../../../../../../components/bootstrap_components/controls/defaultButton';

const tableMeta = md.tables.descendantsDocuments;

function LowerDocs({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const {
    tableActions,
  } = useLowerDocs({
    tableName: tableMeta.name,
    onChange: actions.onChange,
    approvedName: tableMeta.columns.IsApproved.name,
  });
  return (
    <>
      <DocsCommandPanel tableActions={tableActions} readOnly={readOnly}>
        <DefaultCPButton
          onClick={() => actions.onSR('LoadLowLevelDocs')}
          disabled={readOnly}
          label="Завантажити файли, що прикріплені"
          variant="outline-secondary"
        >
          <FontAwesomeIcon icon={faFileArchive} />
        </DefaultCPButton>
      </DocsCommandPanel>
      <>
        {tableData.map((doc, rowId) => (
          <Card key={doc[tableMeta.columns.document.name].id}>
            <Card.Body>
              <Card.Title>
                <Link to={`/${md.frontend}/${doc[tableMeta.columns.document.name].id}`}>
                  {doc[tableMeta.columns.document.name].repr}
                </Link>
              </Card.Title>
              <div className="d-flex">
                <Button
                  variant={doc[tableMeta.columns.IsApproved.name] ? 'outline-success' : 'outline-danger'}
                  onClick={(e) => tableActions.onToggleApproveDoc(e, rowId)}
                >
                  <FontAwesomeIcon icon={doc[tableMeta.columns.IsApproved.name] ? faLock : faLockOpen} className="me-2" />
                  {doc[tableMeta.columns.IsApproved.name] ? 'Отримано' : 'Не отримано'}
                </Button>
                <Card.Title as="h4" className="text-right text-success flex-grow-1">
                  {doc[tableMeta.columns.sum.name].toLocaleString('uk', moneysStingOptions)}
                </Card.Title>

              </div>
              {doc[tableMeta.columns.notes.name] && (
                <Card.Text>
                  <hr />
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  {doc[tableMeta.columns.notes.name]}
                </Card.Text>
              )}
            </Card.Body>
            <Card.Footer className="d-flex _justify-content-between text-muted">
              <Card.Text className="flex-grow-1">
                {doc[tableMeta.columns.CSU.name].repr && (
                  <>
                    <FontAwesomeIcon icon={faBuilding} className="me-2" />
                    {doc[tableMeta.columns.CSU.name].repr}
                  </>
                )}
              </Card.Text>
              <Card.Text>
                <FontAwesomeIcon icon={faUser} className="me-2" />
                {doc[tableMeta.columns.author.name].repr}
              </Card.Text>
            </Card.Footer>
          </Card>
        ))}
      </>
    </>
  );
}

LowerDocs.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({
      [tableMeta.columns.document.name]: referencePropType,
      [tableMeta.columns.CSU.name]: referencePropType,
      [tableMeta.columns.notes.name]: PropTypes.string,
      [tableMeta.columns.author.name]: referencePropType,
      [tableMeta.columns.sum.name]: PropTypes.number,
    })),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

LowerDocs.defaultProps = {
  readOnly: false,
};

export default LowerDocs;
