import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputField } from '../styledInputField/inputField';
import checkIcon from '../../assets/icons/im.svg';

export const StyleCheckbox = styled.input`
    display:none;
    +label:before{
      ${({ checked }) => (checked ? `background:url("${checkIcon}") 1px 2px no-repeat white` : 'background: white')};
      content: "";
      display: inline-block;
      margin: -5px 4px 0 0;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      border: 1px solid #a9a5a5;
      border-radius: 2px;
      box-sizing: inherit;
      }    
`;

export const LabelCheck = styled.label`
    line-height: 20px;
    font-family: Roboto,sans-serif;
    font-size: 14px;
`;

export default class extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    value: PropTypes.bool.isRequired,
    children: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    isFocusCell: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    disabled: null,
    children: null,
    label: null,
    isFocusCell: false,
    placeholder: '',
    onChange: () => {
      // eslint-disable-next-line
      throw 'Not implemented action';
    },
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.refInput = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.isFocusCell) this.refInput.current.focus();
  }

  handleClick(e) {
    const { props: { onChange, value, disabled } } = this;
    e.stopPropagation();
    return !disabled && onChange(e, !value);
  }

  render() {
    const {
      props: {
        id, value, children, label, disabled, placeholder,
      },
    } = this;
    return (
      <InputField disabled={disabled} onClick={this.handleClick} {...this.props}>
        <StyleCheckbox
          type="checkbox"
          placeholder={placeholder}
          ref={this.refInput}
          id={id}
          checked={value}
          onChange={(e) => { e.preventDefault(); }}
        />
        <LabelCheck>{label || children}</LabelCheck>
      </InputField>
    );
  }
}
