import React, {
  useCallback, memo,
} from 'react';
import { Map, List, OrderedMap } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { StyledPanelsContainer } from '../StyledComponents';
import storePathParam from '../../../../common/storePathParam';
import AppearanceTable from './appearanceTable';
import {
  changeField, DCSelectField, DCDeleteField, DCAddField,
} from '../../../../actions/reportEditor';
import AppearanceCommandPanel from './appearanceCP';
import { TableContainer } from './styled';

const PATH = [
  'dataCompositionSettings',
  'ConditionalAppearance',
];

const AVPATH = [
  'dataCompositionSettings',
  'SelectionAvailableFields',
];

const mapStateToProps = (state) => {
  const { name } = storePathParam(state);
  return {
    mountPoint: `rep/${name}/reportEditor`,
    data: state.getIn([`rep/${name}/reportEditor`, ...PATH], Map({})),
    availableFields: state.getIn([`rep/${name}/reportEditor`, ...AVPATH], Map({})),
  };
};

function AppearanceController() {
  const { data, availableFields, mountPoint } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const onSelectRow = useCallback(
    (e, rowId) => dispatch(DCSelectField(PATH, rowId, false)),
    [dispatch],
  );

  const onAddRow = useCallback(
    () => {
      const newField = new Map({
        UseInOverall: true,
        UseInFilter: true,
        ViewMode: 'QuickAccess',
        Parent: null,
        UseInHierarchicalGroup: true,
        UseInFieldsHeader: true,
        UseInGroup: true,
        UseInParameters: true,
        UseInHeader: true,
        Appearance: new Map(),
        Filter: new OrderedMap(),
        Fields: new List(),

      });
      dispatch(DCAddField(PATH, new Map({ 0: newField }), null));
    },
    [dispatch],
  );

  const onChangeRow = useCallback(
    (e, rowId, attrs) => attrs.forEach(
      (value, attr) => dispatch(changeField([...PATH, rowId, attr], value)),
    ),
    [dispatch],
  );

  const onRemoveRow = useCallback(
    () => data.filter((row) => row.get('_SELECTED')).forEach(
      (row, key) => dispatch(DCDeleteField(PATH, key)),
    ),
    [data, dispatch],
  );
  const anyRowSelected = data.filter((row) => row.get('_SELECTED')).size > 0;
  return (
    <StyledPanelsContainer>
      <TableContainer>
        <AppearanceCommandPanel
          onAdd={onAddRow}
          canRemove={anyRowSelected}
          onRemove={onRemoveRow}
        />
        <AppearanceTable
          data={data}
          availableFields={availableFields}
          onSelectRow={onSelectRow}
          onChangeRow={onChangeRow}
          mountPoint={mountPoint}
        />
      </TableContainer>
    </StyledPanelsContainer>
  );
}

export default memo(AppearanceController);
