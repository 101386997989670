import React, {
  useEffect, useState, useContext, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/req';
import AppContext from '../../../providers/CiatAppContext';
import { ErrorMessage } from '../../../components/bootStrap';

const withFilter = (API_URL) => (WrappedComponent) => {
  function FilterEditor({ defaultFilter, overrideFilter, ...restProps }) {
    const { auth } = useContext(AppContext);

    const [error, setError] = useState(null);
    const [options, setOptions] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const loader = async () => {
        const r = await api.options(API_URL, auth);
        if (!r.ok) {
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      loader().then((o) => setOptions(o));
    }, [auth]);

    // genarate filter names
    const filteringFields = useMemo(() => {
      if (!options) return [];
      const fields = options.actions.GET;
      return options.filtering_fields.map((ff) => {
        const field = fields[ff.name] || {};
        return {
          name: ff.name,
          type: field.type,
          resource: field.resource,
          label: ff.label || field.label,
        };
      });
    }, [options]);

    // genarate fields
    const fields = useMemo(() => {
      if (!options) return {};
      return options.actions.GET;
    }, [options]);

    // genarate filter values
    const [filters, setFilters] = useState(
      () => {
        const dd = { ...defaultFilter, ...overrideFilter };
        const ffs = Object.keys(dd)
          .reduce((R, ff) => ({ ...R, [ff]: { use: true, value: dd[ff] } }), {});

        return filteringFields
          .filter((ff) => !(ff.name in ffs))
          .reduce((R, ff) => ({
            ...R,
            [ff.name]: {
              value: null,
              use: false,
            },
          }), ffs);
      },
    );

    const [items, setItems] = useState([]);

    // genarate query params
    const qf = useMemo(
      () => Object.keys(filters)
        .filter((f) => (f in filters) && filters[f].use)
        .reduce((R, f) => {
          const v = filters[f].value;
          let vv;
          if (v && v.id) {
            vv = v.id;
          } else {
            vv = v;
          }
          return {
            ...R,
            [f]: vv,
          };
        }, {}),
      [filters],
    );

    // loading items
    useEffect(
      () => {
        if (options) {
          const loader = async () => {
            setLoading(true);
            setError(null);
            const r = await api.get(API_URL, auth, qf);
            if (!r.ok) {
              setLoading(false);
              throw new Error(`${r.status} ${r.statusText}`);
            }
            const d = await r.json();
            setLoading(false);
            return d;
          };
          loader()
            .then((d) => setItems(d.results))
            .catch((e) => setError(e.message));
        }
      },
      [auth, options, qf],
    );

    const setFilterHandler = useCallback((name, value, use) => (!(name in overrideFilter))
        && setFilters(
          {
            ...filters,
            [name]: {
              value,
              use,
            },
          },
        ), [filters, overrideFilter]);
    const displayFilters = useMemo(
      () => filteringFields
        .filter((ff) => !(ff.name in overrideFilter)),
      [filteringFields, overrideFilter],
    );

    return options && (
      <>
        {error && (
        <ErrorMessage text={error} />
        )}
        <WrappedComponent
          {...restProps}
          filters={filters}
          filteringFields={displayFilters}
          setFilterHandler={setFilterHandler}
          items={items}
          fields={fields}
          loading={loading}
        />
      </>
    );
  }

  FilterEditor.propTypes = {
    defaultFilter: PropTypes.shape({}),
    overrideFilter: PropTypes.shape({}),
  };

  FilterEditor.defaultProps = {
    defaultFilter: {},
    overrideFilter: {},
  };

  return FilterEditor;
};

export default withFilter;
