import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Dropdown, ButtonGroup, Button, Badge,
} from 'react-bootstrap';
import { Map } from 'immutable';
import { faMinus, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DATATYPES, getDateStr } from './consts';
import md from '../../../constants/meta/dataprocessors/logikaImportTreasureFiles';

function ResultTable({
  data, newData, errs, headerForm, onLoadFiles,
}) {
  return (
    <Table>
      <thead>
        <tr>
          <th width="50%">
            Форма
          </th>
          <th width="25%" className="text-center">
            Останні завантажені дані
          </th>
          <th width="25%" className="text-center">
            Доступні для завантаження дані
          </th>
          <th>
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        {DATATYPES.map((k) => (
          <tr key={k}>
            <th>
              {md.columns[k.replace('@', '')].label}
              <Badge variant="warning" className="ms-1">{k}</Badge>
            </th>
            <td className="text-center">
              {headerForm.get(k.replace('@', '')) ? (
                <>
                  <FontAwesomeIcon icon={faCalendar} className="text-success me-2" />
                  {getDateStr(headerForm.get(k.replace('@', '')))}
                </>
              ) : (
                <FontAwesomeIcon icon={faMinus} className="text-danger" />
              )}
            </td>
            <td className={`text-center ${errs[k] ? 'text-danger' : ''}`}>
              {errs[k] || newData[k] ? newData[k].length : 0}
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Button
                  variant="secondary"
                  onClick={() => onLoadFiles({ [k]: newData[k] })}
                >
                  Оновити
                </Button>
                <Dropdown.Toggle split variant="secondary" disabled={!data[k]} />
                <Dropdown.Menu>
                  {data[k] && data[k].map((frm) => (
                    <Dropdown.Item
                      key={frm.datefile}
                      onClick={() => onLoadFiles({ [k]: [frm] })}
                    >
                      {getDateStr(frm.datefile)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>

              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
const dataPropType = PropTypes.arrayOf(PropTypes.shape({
  datefile: PropTypes.string,
  version: PropTypes.number,
}));

ResultTable.propTypes = {
  newData: PropTypes.shape({
    FT110: dataPropType,
    VD0: dataPropType,
  }).isRequired,
  data: PropTypes.shape({
    FT110: dataPropType,
    VD0: dataPropType,
  }).isRequired,
  errs: PropTypes.shape({}).isRequired,
  headerForm: PropTypes.instanceOf(Map).isRequired,
  onLoadFiles: PropTypes.func.isRequired,
};

export default ResultTable;
