import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useFetchGeneralData from '../Hooks/useFetchGeneralData';
import { Loader } from '../../../../components/bootStrap';
import corner1 from '../../../../assets/img/illustrations/corner-3.png';
import Background from '../../../../components/blanks/common/Background';
import IconAlert from '../../../../components/blanks/common/IconAlert';

function General({ cDt }) {
  const { totalValues, isLoading, err } = useFetchGeneralData({ cDt });

  if (isLoading) {
    return <Loader text="Завантаження" />;
  }

  if (err) {
    return (
      <IconAlert variant="danger">
        {err}
      </IconAlert>
    );
  }

  if (!totalValues || Object.keys(totalValues).length === 0) {
    return (
      <IconAlert variant="info">
        Немає даних для відображення
      </IconAlert>
    );
  }

  return (
    <Row xs={1} md={2} lg={3} className="justify-content-center">
      {['zf', 'sf', 'total'].map((key) => (
        totalValues[key] && (
          <Col className="g-3 mt-0" key={key}>
            <Card className="bg-transparent-50 overflow-hidden">
              <Card.Header>
                <div>
                  <h3 className="text-info mb-0 text-center">{totalValues[key]?.repr}</h3>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div
                  className="position-relative py-2 px-4 border-bottom mb-4"
                >
                  <Background
                    image={corner1}
                  />
                  {totalValues[key]?.fields
                    ?.filter((f) => f.important)
                    .map((field) => (
                      <div
                        className="d-flex flex-column flex-sm-row justify-content-between mb-4 align-items-sm-end align-items-center"
                        key={field.repr}
                      >
                        <span className={classNames('h5 text-center text-sm-start', field.color)}>
                          {field.repr}
                          :
                        </span>
                        <div
                          className={classNames(
                            'text-nowrap fs-2',
                            {
                              [field.color]: field.amount >= 0,
                              'text-danger': field.amount < 0,
                            },
                          )}
                        >
                          <CountUp
                            start={0}
                            end={field.amount}
                            duration={2.75}
                            prefix=""
                            separator=" "
                            decimals={2}
                            decimal="."
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="position-relative py-2 px-4">
                  {totalValues[key].rows.map((row) => (
                    <div className="d-flex justify-content-between mb-4 align-items-end" key={row.repr}>
                      <h5 className="text-secondary fs--1">
                        {row.repr}
                        :
                      </h5>
                      <div className="text-secondary fs--1 text-nowrap">
                        <CountUp
                          start={0}
                          end={row.amount}
                          duration={2.75}
                          separator=" "
                          decimals={2}
                          decimal="."
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        )
      ))}
    </Row>
  );
}

General.propTypes = {
  cDt: PropTypes.string.isRequired,
};

export default General;
