import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data,
    actions,
    fields, nonFieldErrors, options,
    isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: meta.cat.dbUrl.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, fieldErrors, actions.onChange);
  const dbUrlProps = editorHooks.useStringInputProps('db_url', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.user.backendURL}
        />
      )}
      // title={userData.username || ''}
      // subtitle={`${userData.first_name || ''} ${userData.last_name || ''}`}
    >
      <Container fluid>
        <Row>
          <Col>
            <EditorControls.StringInput
              {...nameProps}
            />
          </Col>
          <Col>
            <EditorControls.StringInput
              {...dbUrlProps}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.CheckboxInput {...isActiveProps} />
          </Col>
        </Row>
      </Container>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
