import React, {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  faFileCode,
  faPrint, faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { CommandPanel } from '../../../../components/bootStrap';
import { GenerateButton, SettingsButton } from '../../../../components/bootStrap/buttons';
import { CPButton } from '../../../../components/bootStrap/buttons/styles';
import { pageLayouts } from '../../../../constants/pageLayouts';

function PrintCommandPanel({
  onGenerateReport, onPrint, pageLayout, onToggleSettings, settingsActive, showSettings,
  onGenerateXLSX, onGeneratePDF, onGenerateHTML,
}) {
  const pageLayoutTitle = useMemo(
    () => pageLayout.field && pageLayout.field.choices
      .filter((c) => c.value === pageLayout.value)
      .reduce((R, r) => r.display_name, ''),
    [pageLayout.field, pageLayout.value],
  );
  const pageLayoutIcon = useMemo(
    () => (pageLayout.value in pageLayouts && pageLayouts[pageLayout.value].icon) || null,
    [pageLayout.value],
  );
  const onGenerate = useCallback(
    (e) => {
      if (settingsActive) onToggleSettings();
      return onGenerateReport(e);
    },
    [onGenerateReport, onToggleSettings, settingsActive],
  );
  return (
    <CommandPanel
      leftPart={(
        <>
          <GenerateButton
            onClick={onGenerate}
          />
          <CPButton
            onClick={onPrint}
            title="Друк"
            icon={faPrint}
          />
          <CPButton
            onClick={pageLayout.onToggle}
            title={pageLayoutTitle}
            icon={pageLayoutIcon}
            disabled={pageLayout.disabled}
          />
          { showSettings && (
          <SettingsButton onClick={onToggleSettings} active={settingsActive} />
          )}
        </>
    )}
      rightPart={(
        <>
          {onGenerateXLSX && (
            <CPButton
              onClick={onGenerateXLSX}
              title="Експорт в excel"
              icon={faFileExcel}
              variant="falcon-success"
            />
          )}
          {onGeneratePDF && (
            <CPButton
              onClick={onGeneratePDF}
              title="Експорт в PDF"
              icon={faFilePdf}
            />
          )}
          {onGenerateHTML && (
            <CPButton
              onClick={onGenerateHTML}
              title="Експорт в HTML"
              icon={faFileCode}
            />
          )}
        </>
      )}
    />
  );
}

PrintCommandPanel.propTypes = {
  onGenerateReport: PropTypes.func.isRequired,
  onGenerateXLSX: PropTypes.func,
  onGeneratePDF: PropTypes.func,
  onGenerateHTML: PropTypes.func,
  onPrint: PropTypes.func.isRequired,
  onToggleSettings: PropTypes.func.isRequired,
  settingsActive: PropTypes.bool.isRequired,
  pageLayout: PropTypes.shape({
    value: PropTypes.string,
    field: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        display_label: PropTypes.string,
      })),
    }),
    onToggle: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
  showSettings: PropTypes.bool,
};

PrintCommandPanel.defaultProps = {
  showSettings: false,
  onGenerateXLSX: null,
  onGeneratePDF: null,
  onGenerateHTML: null,
};

export default PrintCommandPanel;
