import React from 'react';
import PropTypes from 'prop-types';
import { paramPropType } from '../hooks';
import { EditorMultiValueInput } from '../../../basicEditor/editorControls';

function ParamsEditor({ params, paramValues, onChange }) {
  return (
    <div>
      <h5 className="text-primary">Значения параметров</h5>
      {params.map((p) => (
        <EditorMultiValueInput
          key={p.name}
          controlId={`param_${p.name}_input`}
          label={p.label}
          ctype={p.ctype}
          resource={p.resource}
          required
          value={paramValues[p.name]}
          onChange={(e, v) => onChange(p.name, v)}
        />
      ))}
    </div>
  );
}
ParamsEditor.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  paramValues: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ParamsEditor;
