import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  typeValue: { label: 'Тип значення', name: 'ТипЗначения', visible: false },
};

const typeValue = [
  { type: 'simple', value: 'numeric' },
  { type: 'simple', value: 'string' },
  { type: 'simple', value: 'date' },
  { type: 'cat', value: 'CSU' },
  { type: 'cat', value: 'additionalPropertyCsu' },
];

// Булево, Строка, Дата, Число,
// СправочникСсылка.ДопХЭлементыКФК,
// СправочникСсылка.ЭлементыКФК,
// СправочникСсылка.ОбъектыСтроительстваИсточникиФинансирования

export const csuElChTP = {
  label: 'Додатковий розріз аналітики (Деталізація за Розпорядниками бюджетних коштів)',
  name: 'csuElChTP',
  typeValue,
  columns,
  backendName: 'ПВХЭлементыРасп',
  defaultOrder,
  frontend: 'ChTP/csuElChTP',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default csuElChTP;
