import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  year: { label: 'Рік', name: 'ГодПрогноза' },
  typeAnalysis: { label: 'Тип аналізу', name: 'Таблица' },
  cDate: { label: 'Дата', name: 'Дата' },
};

const definition = {
  label: 'Аналіз Пропозицій до прогнозу місцевого бюджету (Форма ПП-2) (Додаток 3)',
  name: 'AnalysisForecastsPP3',
  columns,
  frontend: 'rep/AnalysisForecastsPP3',
  backendName: 'АнализПрогнозДодП3',
  resultColumns: baseResultColumns,
};

export default definition;
