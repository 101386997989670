import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CardHeader from '../../../../../components/blanks/common/CardHeader';
import GrkTable from './GrkTable';

function Index({ data, cDt }) {
  const title = `Виконання видаткової частини бюджету за ГРК, станом на ${cDt}, тис.грн.`;
  return (
    <Card className="overflow-hidden">
      <CardHeader
        title={title}
        titleTag="h6"
      />
      <Card.Body className="p-0">
        <GrkTable data={data} />
      </Card.Body>
    </Card>
  );
}

Index.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  cDt: PropTypes.string,
};

Index.defaultProps = {
  data: [],
  cDt: '',
};

export default Index;
