import React from 'react';

function OkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 224 224"
      {...props}
    >
      <g fill="none" fillRule="none" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
        <path d="M0,224v-224h224v224z" fill="none" fillRule="nonzero" />
        <g fill="#4281c9" fillRule="evenodd">
          <g id="surface1">
            <path d="M210.875,32.66667l-135.625,137.08333l-62.125,-61.83333l-13.125,13.41667l75.25,74.66667l148.75,-149.91667z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OkIcon;
