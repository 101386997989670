import React from 'react';
// import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/garanty';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
// import DocSelectionModal from './docSelectionModal';
// import { selectionMode } from '../../../../constants/meta/common';
import { StyledLabel } from '../../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../../components/styledInputs';

const repMonth = {
  m1: { label: 'боргова', name: 'боргова' },
  m2: { label: 'гарантійна', name: 'гарантійна' },
};

const repCh = {
  m1: { label: 'кредитна', name: 'кредитна' },
  m2: { label: 'облігаційна', name: 'облігаційна' },
};

const repYear = {
  m1: { label: 'рік', name: 'рік' },
  m2: { label: 'квартал', name: 'квартал' },
  m3: { label: 'місяць', name: 'місяць' },
  m4: { label: 'день', name: 'день' },
};

const repY = {
  m1: { label: 'рік', name: 'рік' },
  m3: { label: 'місяць', name: 'місяць' },
  m4: { label: 'день', name: 'день' },
};

function Garanty({
  data, actions, permissions,
}) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = !permissions.canChange;

  // const handlerSelector = useCallback(
  //   (loadData) => {
  //     actions.onSR('LoadDocs', loadData);
  //   },
  //   [actions],
  // );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.NAME_ORG.label}
            value={data[md.columns.NAME_ORG.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.NAME_ORG.name]: value,
              });
              actions.onSR('CHANGE_CSU');
            }}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.NAME_ORGtext.label}
            value={data[md.columns.NAME_ORGtext.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NAME_ORGtext.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.CRED_NAME.label}
            value={data[md.columns.CRED_NAME.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CRED_NAME.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.POZ_NAME.label}
            value={data[md.columns.POZ_NAME.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.POZ_NAME.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DATE_SIGN.label}
            value={data[md.columns.DATE_SIGN.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DATE_SIGN.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.NUM_POZ.label}
            value={data[md.columns.NUM_POZ.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NUM_POZ.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DATE1.label}
            value={data[md.columns.DATE1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DATE1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DATE1_1.label}
            value={data[md.columns.DATE1_1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DATE1_1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.NUMBER1.label}
            value={data[md.columns.NUMBER1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NUMBER1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DATE2.label}
            value={data[md.columns.DATE2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DATE2.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.NUMBER2.label}
            value={data[md.columns.NUMBER2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NUMBER2.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <span>
          Основна інформація позики
        </span>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R01D1.label}
            value={data[md.columns.R01D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R01D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R02D1.label}
            value={data[md.columns.R02D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R02D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R03D1.label}
            value={data[md.columns.R03D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R03D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R04D1.label}
            value={data[md.columns.R04D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R04D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R05D1.label}
            value={data[md.columns.R05D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R05D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R06D1.label}
            value={data[md.columns.R06D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R06D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R07D1.label}
            value={data[md.columns.R07D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R07D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.R08D1.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repMonth)}
            value={data[md.columns.R08D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R08D1.name]: value,
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>{md.columns.R09D1.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repCh)}
            value={data[md.columns.R09D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R09D1.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R10D1.label}
            value={data[md.columns.R10D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R10D1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.R11D1.label}
            value={data[md.columns.R11D1.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.R11D1.name]: value,
              });
            }}
            modelType="cat"
            modelName="currency"
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R12D1.label}
            value={data[md.columns.R12D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R12D1.name]: value,
            })}
            readOnly={readOnly}
            precision={0}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.R13D1.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repY)}
            value={data[md.columns.R13D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R13D1.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R14D1.label}
            value={data[md.columns.R14D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R14D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R15D1.label}
            value={data[md.columns.R15D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R15D1.name]: value,
            })}
            readOnly={readOnly}
            rows={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R16D1.label}
            value={data[md.columns.R16D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R16D1.name]: value,
            })}
            readOnly={readOnly}
            rows={2}
          />
        </Col>
      </Row>
      <Row>
        <span>
          Дані про облігаційну позику
        </span>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R17D1.label}
            value={data[md.columns.R17D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R17D1.name]: value,
            })}
            readOnly={readOnly}
            precision={0}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R18D1.label}
            value={data[md.columns.R18D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R18D1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <span>
          Дані про фактично надану гарантію
        </span>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R19D1.label}
            value={data[md.columns.R19D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R19D1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.R20D1.label}
            value={data[md.columns.R20D1.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.R20D1.name]: value,
              });
            }}
            modelType="cat"
            modelName="currency"
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R21D1.label}
            value={data[md.columns.R21D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R21D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R22D1.label}
            value={data[md.columns.R22D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R22D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <span>
          Дані про графік погашення
        </span>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R23D1.label}
            value={data[md.columns.R23D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R23D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R24D1.label}
            value={data[md.columns.R24D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R24D1.name]: value,
            })}
            readOnly={readOnly}
            precision={0}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.R25D1.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repYear)}
            value={data[md.columns.R25D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R25D1.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R26D1.label}
            value={data[md.columns.R26D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R26D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <span>
          Дані про графік та фінансові умови обслуговування
        </span>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R27D1.label}
            value={data[md.columns.R27D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R27D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.R28D1.label}
            value={data[md.columns.R28D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R28D1.name]: value,
            })}
            readOnly={readOnly}
            precision={0}
          />
        </Col>
        <Col>
          <StyledLabel>{md.columns.R29D1.label}</StyledLabel>
          <InputWithDropdown
            options={Object.values(repYear)}
            value={data[md.columns.R29D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R29D1.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.R30D1.label}
            value={data[md.columns.R30D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R30D1.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R31D1.label}
            value={data[md.columns.R31D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R31D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R32D1.label}
            value={data[md.columns.R32D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R32D1.name]: value,
            })}
            readOnly={readOnly}
            rows={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.R34D1.label}
            value={data[md.columns.R34D1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.R34D1.name]: value,
            })}
            readOnly={readOnly}
            rows={3}
          />
        </Col>
      </Row>
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <DocSelectionModal */}
      {/*      onOk={handlerSelector} */}
      {/*      FI={data[md.columns.FI.name]} */}
      {/*      readOnly={readOnly} */}
      {/*      nDoc={data[md.columns.number.name]} */}
      {/*      dDoc={data[md.columns.date.name]} */}
      {/*      noKbpVisual */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
    </EditorCollapse>
  );
}

Garanty.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Garanty;
