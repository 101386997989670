/* eslint-disable no-nested-ternary,no-undef */
import React, {
  useState, useCallback, memo,
} from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ea, { addTableRow, copyTableRow, removeTableRow } from '../../actions/editor';
import RowCard from './rowCard';
import './baseTablePart.css';
import dpea from '../../actions/dpEditor';

const asTypes = {
  editor: 'editor',
  dpEditor: 'dpEditor',
};

const getActions = (as) => {
  switch (as) {
    case asTypes.editor:
      return ea;
    case asTypes.dpEditor:
      return dpea;
    default:
      return null;
  }
};

export default (tableName, BodyComponent, HeaderComponent = null, FooterComponent = null) => {
  function BaseTablePart({
    data, disabled, actionSource, arrBypassGrid, ...restProps
  }) {
    const [activeId, setActiveId] = useState(0);
    const dispatch = useDispatch();

    const elFocus = useCallback(
      (rowId, id, deltaHor = 0, deltaVer = 0) => {
        const sumRows = data.size;

        if (!sumRows > 0) return { rowId, id };

        const sumCells = arrBypassGrid.length;

        const a = getActions(actionSource);

        if ((rowId === 0) && (deltaVer < 0)) {
          dispatch(a.activateTableRow(tableName, (sumRows - 1), false));
          return { rowId: (sumRows - 1), id };
        }

        if (((rowId + 1) === sumRows) && (deltaVer > 0)) {
          dispatch(a.activateTableRow(tableName, 0, false));
          return { rowId: 0, id };
        }

        if (deltaVer !== 0) {
          dispatch(a.activateTableRow(tableName, (rowId + deltaVer), false));
          return { rowId: (rowId + deltaVer), id };
        }

        if ((rowId === 0)
          && (id === 0)
          && (deltaHor < 0)) {
          dispatch(a.activateTableRow(tableName, (sumRows - 1), false));
          return { rowId: (sumRows - 1), id: (sumCells - 1) };
        }

        if (((rowId + 1) === sumRows)
          && ((id + 1) === sumCells)
          && (deltaHor > 0)) {
          dispatch(a.activateTableRow(tableName, 0, false));
          return { rowId: 0, id: 0 };
        }

        if (((id + 1) >= sumCells) && (deltaHor > 0)) {
          dispatch(a.activateTableRow(tableName, (rowId + 1), false));
          return { rowId: (rowId + 1), id: 0 };
        }

        if ((id === 0) && (deltaHor < 0)) {
          dispatch(a.activateTableRow(tableName, (rowId - 1), false));
          return { rowId: (rowId - 1), id: (sumCells - 1) };
        }

        if (deltaHor !== 0) {
          dispatch(a.activateTableRow(tableName, rowId, false));
          return { rowId, id: (id + deltaHor) };
        }

        return { rowId, id };
      },
      [actionSource, arrBypassGrid.length, data.size, dispatch],
    );

    const nextFocus = useCallback(
      (rowId, id, deltaHor, deltaVer) => {
        const elf = elFocus(rowId, id, deltaHor, deltaVer);
        setActiveId(elf.id);
      },
      [elFocus],
    );

    const processingKeyDown = useCallback(
      (e, rowId, id) => {
        const countRow = data.size;

        const countCells = arrBypassGrid.length;
        if (e) {
          switch (e.keyCode) {
          // Enter

            case 13: {
              e.preventDefault();

              if ((rowId === (countRow - 1)) && (id === (countCells - 1))) {
                const a = getActions(actionSource);
                setActiveId(0);
                dispatch(addTableRow(tableName));
                dispatch(a.activateTableRow(tableName, countRow, false));
              } else nextFocus(rowId, id, 1, 0);

              break;
            }
            case undefined: {
              // onClick
              nextFocus(rowId, id, 0, 0);
              break;
            }
            case 39: {
              // rigth

              if (e.target.value.length === e.target.selectionEnd) nextFocus(rowId, id, 1, 0);
              break;
            }
            case 37: {
              // left
              if (e.target.selectionStart === 0) nextFocus(rowId, id, -1, 0);
              break;
            }
            case 40: {
              // down
              nextFocus(rowId, id, 0, 1);
              break;
            }
            case 38: {
              // up
              nextFocus(rowId, id, 0, -1);
              break;
            }
            case 27: {
              // Esc
              nextFocus(rowId, id, 0, 0);
              break;
            }
            case 45: {
              // insert
              const a = getActions(actionSource);

              dispatch(addTableRow(tableName));
              dispatch(a.activateTableRow(tableName, countRow, false));

              break;
            }
            case 115: {
              // F4 (choice selector)
              nextFocus(rowId, id, 1, 0);
              break;
            }
            case 119: {
              // F8 (delete row)

              const a = getActions(actionSource);

              const isActiveRowId = data.reduce(
                (ii, row, key) => ((ii === -1)
                  ? (row.get('IS_ACTIVE', false) ? key : ii)
                  : ii),
                -1,
              );

              dispatch(removeTableRow(tableName));

              dispatch(a.activateTableRow(
                tableName,
                ((data.size - 2) > isActiveRowId) ? isActiveRowId : (data.size - 2),
                false,
              ));

              break;
            }
            case 120: {
              // F9 (copy row)
              dispatch(copyTableRow(tableName));
              break;
            }
            default: break;
          }
        }
      },
      [actionSource, arrBypassGrid.length, data, dispatch, nextFocus],
    );

    return (
      <div className="tablePartGrid">
        {HeaderComponent && (
          <HeaderComponent
            activeId={activeId}
            {...restProps}
          />
        )}
        <div className="body">
          {data.map((row, key) => (
            <RowCard
              key={row.get('orderNo', key)}
              rowId={row.get('orderNo', key)}
              BodyComponent={BodyComponent}
              row={row}
              dispatch={dispatch}
              disabled={disabled}
              tableName={tableName}
              actionSource={actionSource}
              processingKeyDown={processingKeyDown}
              activeId={activeId}
              {...restProps}
            />
          ))}
        </div>
        {FooterComponent && (
          <FooterComponent
            rows={data}
            activeId={activeId}
            {...restProps}
          />
        )}
      </div>
    );
  }

  BaseTablePart.displayName = 'BaseTP';
  BaseTablePart.propTypes = {
    data: PropTypes.instanceOf(List),
    disabled: PropTypes.bool,
    actionSource: PropTypes.oneOf(Object.values(asTypes)),
    arrBypassGrid: PropTypes.instanceOf(Array),
  };

  BaseTablePart.defaultProps = {
    data: new List(),
    disabled: false,
    actionSource: asTypes.editor,
    arrBypassGrid: [],
  };

  return memo(BaseTablePart);
};
