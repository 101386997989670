import { Map, List } from 'immutable';
import action from '../constants/actions/dpEditor';

/**
 * @func
 * @param {Immutable.Map} state
 * @param {object} action
 * @param {string} action.type
 * @param {any} action.payload
 * @param {array} action.payload.[path]
 * @param {any} action.payload.[value]
 * @param {any} action.payload.[items]
 * @return {Immutable.Map}
 */
const makeReducer = (model) => (state = new Map({}), { type, payload = {} }) => {
  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */
  if (
    (!type.endsWith('@dpEditor'))
        || (payload.model !== model)
  ) {
    // Не наш узел
    return state;
  }

  const updaterCreateTableRow = (table) => ((table.size > 0) ? table
    .map((v) => v.set('IS_ACTIVE', false))
    .set(table.size, table.get(0)
      .map((v) => {
        switch (typeof v) {
          case 'number':
            return 0;
          case 'string':
            return '';
          case 'object':
            return (v || new Map()).set('repr', '')
              .set('id', '');

          default:
            return v;
        }
      }).set('IS_ACTIVE', true)) : table.push(new Map().set('IS_ACTIVE', true)));

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */
  const updaterDeleteTableRow = (table) => table.filter((row, key) => !payload.items.has(key));

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */

  const updaterCopyTableRow = (table) => payload.items.reduce(
    /**
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @return {Immutable.List}
       */
    (r, v) => r.push(v),
    table,
  );

  const updaterSwapDownTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next row
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k + 1, v),
    updaterDeleteTableRow(table),
  );

  const updaterSwapUpTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next row
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k - 1, v),
    updaterDeleteTableRow(table),
  );

  switch (type) {
    case action.INIT:
      return new Map({})
        .set('modelType', payload.type)
        .set('modelName', payload.model)
        .set('headerForm', new Map())
        .set('tables', new Map())
        .set('isProcessing', true)
        .set('isChanged', false)
        .set('isValidated', false)
        .set('hasError', false)
        .set('errorMsg', null)
        .set('isLoadingAttachedFiles', false)
        .set('AttachedFiles', [])
        .set('disabledFields', new Map());
    case action.CHANGE_PROPERTY:
      return state.set('isChanged', true).setIn(payload.path, payload.value);
    case action.PROCESS_ATTACHED_FILES:
      return state
        .set('isLoadingAttachedFiles', payload.isLoadingAttachedFiles)
        .set('AttachedFiles', payload.AttachedFiles);
    case action.PROCESS_ADD_FILES:
      return state
        .set('isLoadingAttachedFiles', payload.isLoadingAttachedFiles);
    case action.DELETE_ATTACHED_FILES:
      return state
        .set('isLoadingAttachedFiles', payload.isLoadingAttachedFiles);
    case action.SR_CHANGE_PROPERTY:
      return state.setIn(payload.path, payload.value);
    case action.TABLE_ROW_SET_ACTIVE:
      return state
        .setIn(payload.path, payload.value);
    case action.TABLE_ROW_ADD:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.tableName}`, new List(), updaterCreateTableRow);
    case action.TABLE_ROW_COPY:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.name}`, new List(), updaterCopyTableRow);
    case action.TABLE_ROW_REMOVE:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.name}`, new List(), updaterDeleteTableRow);
    case action.TABLE_ROW_SWAP:
      return state
        .set('isChanged', true)
        .update(
          `tables/${payload.name}`,
          new List(),
          payload.reverseDirection
            ? updaterSwapUpTableRow
            : updaterSwapDownTableRow,
        );
    case action.TABLE_FILL:
      return state
        .set('isChanged', true)
        .set(
          `tables/${payload.name}`,
          payload.items,
        );
    case action.SR_START:
      return state
        .set('hasError', false)
        .set('errorMsg', '')
        .set('isProcessing', true);
    case action.SR_DONE:
      return payload.item.tables
        .keySeq()
        .reduce(
          (r, table) => r.set(`tables/${table}`, payload.item.tables.get(table)),
          state
            .set('isProcessing', false)
            .set('headerForm', payload.item.headerForm || null),
        );
    case action.SR_ERR:
      return state
        .set('isProcessing', false)
        .set('hasError', true)
        .set('errorMsg', payload.errorMsg);
    default:
      return state;
  }
};

const createDpEditorReducer = (modelType, o, _form = 'dpEditor') => Object.keys(o)
  .reduce((r, key) => ({
    ...r,
    [`${modelType}/${key}/${_form}`]: makeReducer(key),
  }), {});

export default createDpEditorReducer;
