import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Map } from 'immutable';
import enums from '../../../../constants/meta/enums';
import md from '../../../../constants/meta/documents/financingSources';
import {
  CommandPanelColor,
} from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { comparisonTypes } from '../../../../constants/meta/common';
import BankAccountTypes from '../../../../constants/meta/enums/bankAccaountTypes';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const TypeTransfer = {
  first: { display_name: 'На котловий', value: 0 },
  second: { display_name: 'інші', value: 1 },
};

function FinancingSources({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const FI = data[md.columns.FI.name];
  const date = data[md.columns.date.name];
  const isFinManagment = sessionOptions.get('isFinManagment', false);
  const FOParent = sessionOptions.get('foParent', new Map());
  const fondCl = data[md.columns.Fond.name];

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);
  const readOnly = !permissions.canChange;
  const FiAccFilter = useMemo(
    () => {
      const getAccKind = () => {
        if (isFinManagment) {
          return {
            comparisonType: comparisonTypes.equal,
            value: BankAccountTypes.boiler.name,
          };
        }
        return {
          comparisonType: comparisonTypes.notEqual,
          value: BankAccountTypes.boiler.name,
        };
      };
      return [
        { fieldName: 'ИспользуетсяДляОбязательств', value: false },
        {
          fieldName: 'ВидСчета',
          ...getAccKind(),
        },
        {
          fieldName: 'Владелец',
          value: FI,
        },
        { fieldName: 'Фонд', value: fondCl },
      ];
    },
    [FI, fondCl, isFinManagment],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}

      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.RNo.label}
            value={data[md.columns.RNo.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.RNo.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.date.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.PlaningMode.label}
            value={data[md.columns.PlaningMode.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.PlaningMode.name]: value,
              });
              actions.onSR('RENEW_TOTALS_ALL');
            }}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.FondObject.label}
            value={data[md.columns.FondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FondObject.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={fondFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.FIAccount.label}
            value={data[md.columns.FIAccount.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.FIAccount.name]: value,
              });
              actions.onSR('CSU_ACCOUNT_ON_CHANGE');
            }}
            modelType="cat"
            modelName="bankAccounts"
            filter={FiAccFilter}
            // headerComponent={ChoiseTypeBankAccount}
            params={onDateParams}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <EditorControls.SelectorInput
                label={md.columns.TypeTransfer.label}
                value={data[md.columns.TypeTransfer.name]}
                values={Object.values(TypeTransfer)}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.TypeTransfer.name]: value,
                  });
                  // actions.onSR('DOC_TYPE_ON_CHANGE');
                }}
              />
            </Col>
            <Col className="align-self-end">
              <EditorControls.CheckboxInput
                controlId={`CarryOut-${data.id}`}
                label={md.columns.CarryOut.label}
                value={data[md.columns.CarryOut.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.CarryOut.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.TextInput
            label="Вид операції"
            value={data[md.columns.Note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Note.name]: value,
            })}
            rows={1}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.AccountBalance.label}
            value={data[md.columns.AccountBalance.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.SumDoc.label}
            value={data[md.columns.SumDoc.name]}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Delta.label}
            value={data[md.columns.Delta.name]}
            readOnly
          />
        </Col>
        <Col className="align-self-end">
          <CommandPanelColor
            style={{ marginBottom: '16px', width: '100%', display: 'block' }}
            label="Оновити залишок"
            onClick={() => actions.onSR('UPDATE_BALANCE')}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

FinancingSources.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FinancingSources;
