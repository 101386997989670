/* eslint-disable react/no-array-index-key */
import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import Header from './header';
import { dataPropTypes, fieldsPropTypes } from './propTypes';
import { errorsPropTypes } from '../../../../allocationplan/editor/tp/planningRows/propTypes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import DisposersMapRow from './disposersMapRow';
import { TPCommandPanel } from '../../../../../newEditor/commandPanels';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { FillInTheTableButton } from '../../../../../../components/bootStrap/buttons';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function DisposersMapRows({
  onChange, data, fields, errors, readOnlyFields, readOnly, onDraft,
}) {
  const {
    tableData, tableActions, pinHeader, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'rows', readOnlyFields, errors, fields,
  });
  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          activeRow={activeRow}
        >
          <FillInTheTableButton
            onClick={() => onDraft(data, { fill_disposers_map_rows: true })}
            content="Заповнити"
          />
        </TPCommandPanel>
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            onMoveRow={tableActions.onMoveRow}
            tableName="rows"
          >
            <DisposersMapRow
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              authorityId={data.authority.id}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </TableContainer>
  );
}

DisposersMapRows.propTypes = {
  data: PropTypes.shape({
    authority: foreignPropType.isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    rows: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    rows: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
};
DisposersMapRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};
export default DisposersMapRows;
