import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  inKDB: { label: 'Розписати за КДБ', name: 'РаписатьКДБ' },
  R01G3: { label: 'R01G3', name: 'R22G3' },
  R01G4: { label: 'R01G3', name: 'R22G4' },
  R01G5: { label: 'R01G3', name: 'R22G5' },
  R01G6: { label: 'R01G3', name: 'R22G6' },
  R01G7: { label: 'R01G3', name: 'R22G7' },
  R02G3: { label: 'R01G3', name: 'R23G3' },
  R02G4: { label: 'R01G3', name: 'R23G4' },
  R02G5: { label: 'R01G3', name: 'R23G5' },
  R02G6: { label: 'R01G3', name: 'R23G6' },
  R02G7: { label: 'R01G3', name: 'R23G7' },
  R03G3: { label: 'R01G3', name: 'R24G3' },
  R03G4: { label: 'R01G3', name: 'R24G4' },
  R03G5: { label: 'R01G3', name: 'R24G5' },
  R03G6: { label: 'R01G3', name: 'R24G6' },
  R03G7: { label: 'R01G3', name: 'R24G7' },
  R04G3: { label: 'R01G3', name: 'R25G3' },
  R04G4: { label: 'R01G3', name: 'R25G4' },
  R04G5: { label: 'R01G3', name: 'R25G5' },
  R04G6: { label: 'R01G3', name: 'R25G6' },
  R04G7: { label: 'R01G3', name: 'R25G7' },
  R05G3: { label: 'R01G3', name: 'R26G3' },
  R05G4: { label: 'R01G3', name: 'R26G4' },
  R05G5: { label: 'R01G3', name: 'R26G5' },
  R05G6: { label: 'R01G3', name: 'R26G6' },
  R05G7: { label: 'R01G3', name: 'R26G7' },
  R06G3: { label: 'R01G3', name: 'R27G3' },
  R06G4: { label: 'R01G3', name: 'R27G4' },
  R06G5: { label: 'R01G3', name: 'R27G5' },
  R06G6: { label: 'R01G3', name: 'R27G6' },
  R06G7: { label: 'R01G3', name: 'R27G7' },
  R07G3: { label: 'R01G3', name: 'R28G3' },
  R07G4: { label: 'R01G3', name: 'R28G4' },
  R07G5: { label: 'R01G3', name: 'R28G5' },
  R07G6: { label: 'R01G3', name: 'R28G6' },
  R07G7: { label: 'R01G3', name: 'R28G7' },
  R08G3: { label: 'R01G3', name: 'R29G3' },
  R08G4: { label: 'R01G3', name: 'R29G4' },
  R08G5: { label: 'R01G3', name: 'R29G5' },
  R08G6: { label: 'R01G3', name: 'R29G6' },
  R08G7: { label: 'R01G3', name: 'R29G7' },
  R09G3: { label: 'R01G3', name: 'R30G3' },
  R09G4: { label: 'R01G3', name: 'R30G4' },
  R09G5: { label: 'R01G3', name: 'R30G5' },
  R09G6: { label: 'R01G3', name: 'R30G6' },
  R09G7: { label: 'R01G3', name: 'R30G7' },
  R10G3: { label: 'R01G3', name: 'R31G3' },
  R10G4: { label: 'R01G3', name: 'R31G4' },
  R10G5: { label: 'R01G3', name: 'R31G5' },
  R10G6: { label: 'R01G3', name: 'R31G6' },
  R10G7: { label: 'R01G3', name: 'R31G7' },
  R11G3: { label: 'R01G3', name: 'R31G3' },
  R11G4: { label: 'R01G3', name: 'R31G4' },
  R11G5: { label: 'R01G3', name: 'R31G5' },
  R11G6: { label: 'R01G3', name: 'R31G6' },
  R11G7: { label: 'R01G3', name: 'R31G7' },
  R12G3: { label: 'R01G3', name: 'R32G3' },
  R12G4: { label: 'R01G3', name: 'R32G4' },
  R12G5: { label: 'R01G3', name: 'R32G5' },
  R12G6: { label: 'R01G3', name: 'R32G6' },
  R12G7: { label: 'R01G3', name: 'R32G7' },
  R13G3: { label: 'R01G3', name: 'R33G3' },
  R13G4: { label: 'R01G3', name: 'R33G4' },
  R13G5: { label: 'R01G3', name: 'R33G5' },
  R13G6: { label: 'R01G3', name: 'R33G6' },
  R13G7: { label: 'R01G3', name: 'R33G7' },
  R14G3: { label: 'R01G3', name: 'R34G3' },
  R14G4: { label: 'R01G3', name: 'R34G4' },
  R14G5: { label: 'R01G3', name: 'R34G5' },
  R14G6: { label: 'R01G3', name: 'R34G6' },
  R14G7: { label: 'R01G3', name: 'R34G7' },
  R15G3: { label: 'R01G3', name: 'R35G3' },
  R15G4: { label: 'R01G3', name: 'R35G4' },
  R15G5: { label: 'R01G3', name: 'R35G5' },
  R15G6: { label: 'R01G3', name: 'R35G6' },
  R15G7: { label: 'R01G3', name: 'R35G7' },
  R16G3: { label: 'R01G3', name: 'R36G3' },
  R16G4: { label: 'R01G3', name: 'R36G4' },
  R16G5: { label: 'R01G3', name: 'R36G5' },
  R16G6: { label: 'R01G3', name: 'R36G6' },
  R16G7: { label: 'R01G3', name: 'R36G7' },
  R17G3: { label: 'R01G3', name: 'R37G3' },
  R17G4: { label: 'R01G3', name: 'R37G4' },
  R17G5: { label: 'R01G3', name: 'R37G5' },
  R17G6: { label: 'R01G3', name: 'R37G6' },
  R17G7: { label: 'R01G3', name: 'R37G7' },
  R18G3: { label: 'R01G3', name: 'R38G3' },
  R18G4: { label: 'R01G3', name: 'R38G4' },
  R18G5: { label: 'R01G3', name: 'R38G5' },
  R18G6: { label: 'R01G3', name: 'R38G6' },
  R18G7: { label: 'R01G3', name: 'R38G7' },
  R19G3: { label: 'R01G3', name: 'R39G3' },
  R19G4: { label: 'R01G3', name: 'R39G4' },
  R19G5: { label: 'R01G3', name: 'R39G5' },
  R19G6: { label: 'R01G3', name: 'R39G6' },
  R19G7: { label: 'R01G3', name: 'R39G7' },
  R20G3: { label: 'R01G3', name: 'R40G3' },
  R20G4: { label: 'R01G3', name: 'R40G4' },
  R20G5: { label: 'R01G3', name: 'R40G5' },
  R20G6: { label: 'R01G3', name: 'R40G6' },
  R20G7: { label: 'R01G3', name: 'R40G7' },
  R41G3: { label: 'R01G3', name: 'R41G3' },
  R41G4: { label: 'R01G3', name: 'R41G4' },
  R41G5: { label: 'R01G3', name: 'R41G5' },
  R41G6: { label: 'R01G3', name: 'R41G6' },
  R41G7: { label: 'R01G3', name: 'R41G7' },
  R21G3: { label: 'R01G3', name: 'R42G3' },
  R21G4: { label: 'R01G3', name: 'R42G4' },
  R21G5: { label: 'R01G3', name: 'R42G5' },
  R21G6: { label: 'R01G3', name: 'R42G6' },
  R21G7: { label: 'R01G3', name: 'R42G7' },
  R43G3: { label: 'R01G3', name: 'R43G3' },
  R43G4: { label: 'R01G3', name: 'R43G4' },
  R43G5: { label: 'R01G3', name: 'R43G5' },
  R43G6: { label: 'R01G3', name: 'R43G6' },
  R43G7: { label: 'R01G3', name: 'R43G7' },
  R44G3: { label: 'R01G3', name: 'R44G3' },
  R44G4: { label: 'R01G3', name: 'R44G4' },
  R44G5: { label: 'R01G3', name: 'R44G5' },
  R44G6: { label: 'R01G3', name: 'R44G6' },
  R44G7: { label: 'R01G3', name: 'R44G7' },
  R45G3: { label: 'R01G3', name: 'R45G3' },
  R45G4: { label: 'R01G3', name: 'R45G4' },
  R45G5: { label: 'R01G3', name: 'R45G5' },
  R45G6: { label: 'R01G3', name: 'R45G6' },
  R45G7: { label: 'R01G3', name: 'R45G7' },
  R46G3: { label: 'R01G3', name: 'R46G3' },
  R46G4: { label: 'R01G3', name: 'R46G4' },
  R46G5: { label: 'R01G3', name: 'R46G5' },
  R46G6: { label: 'R01G3', name: 'R46G6' },
  R46G7: { label: 'R01G3', name: 'R46G7' },
  R47G3: { label: 'R01G3', name: 'R47G3' },
  R47G4: { label: 'R01G3', name: 'R47G4' },
  R47G5: { label: 'R01G3', name: 'R47G5' },
  R47G6: { label: 'R01G3', name: 'R47G6' },
  R47G7: { label: 'R01G3', name: 'R47G7' },
  R48G3: { label: 'R01G3', name: 'R48G3' },
  R48G4: { label: 'R01G3', name: 'R48G4' },
  R48G5: { label: 'R01G3', name: 'R48G5' },
  R48G6: { label: 'R01G3', name: 'R48G6' },
  R48G7: { label: 'R01G3', name: 'R48G7' },
  R49G3: { label: 'R01G3', name: 'R49G3' },
  R49G4: { label: 'R01G3', name: 'R49G4' },
  R49G5: { label: 'R01G3', name: 'R49G5' },
  R49G6: { label: 'R01G3', name: 'R49G6' },
  R49G7: { label: 'R01G3', name: 'R49G7' },
  R50G3: { label: 'R01G3', name: 'R50G3' },
  R50G4: { label: 'R01G3', name: 'R50G4' },
  R50G5: { label: 'R01G3', name: 'R50G5' },
  R50G6: { label: 'R01G3', name: 'R50G6' },
  R50G7: { label: 'R01G3', name: 'R50G7' },
  R51G3: { label: 'R01G3', name: 'R51G3' },
  R51G4: { label: 'R01G3', name: 'R51G4' },
  R51G5: { label: 'R01G3', name: 'R51G5' },
  R51G6: { label: 'R01G3', name: 'R51G6' },
  R51G7: { label: 'R01G3', name: 'R51G7' },
  R52G3: { label: 'R01G3', name: 'R52G3' },
  R52G4: { label: 'R01G3', name: 'R52G4' },
  R52G5: { label: 'R01G3', name: 'R52G5' },
  R52G6: { label: 'R01G3', name: 'R52G6' },
  R52G7: { label: 'R01G3', name: 'R52G7' },
  R53G3: { label: 'R01G3', name: 'R53G3' },
  R53G4: { label: 'R01G3', name: 'R53G4' },
  R53G5: { label: 'R01G3', name: 'R53G5' },
  R53G6: { label: 'R01G3', name: 'R53G6' },
  R53G7: { label: 'R01G3', name: 'R53G7' },
  R54G3: { label: 'R01G3', name: 'R54G3' },
  R54G4: { label: 'R01G3', name: 'R54G4' },
  R54G5: { label: 'R01G3', name: 'R54G5' },
  R54G6: { label: 'R01G3', name: 'R54G6' },
  R54G7: { label: 'R01G3', name: 'R54G7' },
  R55G3: { label: 'R01G3', name: 'R55G3' },
  R55G4: { label: 'R01G3', name: 'R55G4' },
  R55G5: { label: 'R01G3', name: 'R55G5' },
  R55G6: { label: 'R01G3', name: 'R55G6' },
  R55G7: { label: 'R01G3', name: 'R55G7' },
  R56G3: { label: 'R01G3', name: 'R56G3' },
  R56G4: { label: 'R01G3', name: 'R56G4' },
  R56G5: { label: 'R01G3', name: 'R56G5' },
  R56G6: { label: 'R01G3', name: 'R56G6' },
  R56G7: { label: 'R01G3', name: 'R56G7' },
  R57G3: { label: 'R01G3', name: 'R57G3' },
  R57G4: { label: 'R01G3', name: 'R57G4' },
  R57G5: { label: 'R01G3', name: 'R57G5' },
  R57G6: { label: 'R01G3', name: 'R57G6' },
  R57G7: { label: 'R01G3', name: 'R57G7' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол1', name: 'Кол2' },
      col3: { label: 'Кол1', name: 'Кол3' },
      col4: { label: 'Кол1', name: 'Кол4' },
      col5: { label: 'Кол1', name: 'Кол5' },
      col6: { label: 'Кол1', name: 'Кол6' },
      col7: { label: 'Кол1', name: 'Кол7' },
      col8: { label: 'Кол1', name: 'Кол8' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd2Printing',
  backendName: 'ПечатьПрогнозДод2',
  label: 'Показники доходів бюджету (Додаток 2)',
  frontend: 'dp/forecastAdd2Printing',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
