import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OrderedMap, Map } from 'immutable';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  changeField, DCAddField, DCDeleteField, DCSelectField, DCToggleField,
} from '../../../../actions/reportEditor';
import AvailableFields from '../AvailableFields';
import OrderView from './orderView';
import { DataComposition as DC } from '../../../../constants/meta/enums/DataComposition';
import { StyledPanelsContainer } from '../StyledComponents';
import storePathParam from '../../../../common/storePathParam';
import NavButtons from '../navButtons';

const AVPATH = [
  'dataCompositionSettings',
  'OrderAvailableFields',
];

const PATH = [
  'dataCompositionSettings',
  'Order',
];

class Order extends Component {
  static propTypes = {
    availableFields: PropTypes.instanceOf(Map).isRequired,
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.instanceOf(OrderedMap).isRequired,
  };

  onDragEnd = ({
    destination, draggableId, source,
  }) => {
    const { availableFields, dispatch } = this.props;
    if (destination) {
      if (destination.droppableId === 'DC.Order' && source.droppableId === 'DC.AvailableFields') {
        const draggableField = availableFields.getIn(['visibleItems', draggableId]);
        const addableFields = new Map().set(draggableId, draggableField);
        this.addFieldsToStructure(addableFields, { index: destination.index + 1 });
      } else if (destination.droppableId === 'DC.AvailableFields' && source.droppableId === 'DC.Order') {
        dispatch(DCDeleteField(PATH, draggableId));
      }
    }
  };

  toggleSelection = (e, rowId) => {
    const { data, dispatch } = this.props;
    dispatch(changeField([...PATH, rowId, 'Use'], !data.getIn([rowId, 'Use'], false)));
  };

  fieldSelectionHandler = (e, rowId) => {
    const { dispatch } = this.props;
    e.stopPropagation();
    dispatch(DCSelectField(PATH, rowId, false));
  };

  fieldDeletionHandler = () => {
    const { dispatch } = this.props;
    dispatch(DCDeleteField(PATH));
  };

  addFieldsToStructure = (fields, addParams) => {
    const { dispatch } = this.props;
    const addableFields = fields.map((row) => new Map({
      Type: DC.fieldTypes.OrderItem,
      OrderType: DC.orderTypes.Ascending,
      Field: row.get('Field'),
      ViewMode: DC.viewModes.QuickAccess,
    }));
    dispatch(DCAddField(PATH, addableFields, addParams.parentId, addParams.afterKey));
  };

  fieldAddHandler = () => {
    const { dispatch, availableFields, data } = this.props;

    const addParams = data.reduce((R, row, key) => {
      if (row.get('_SELECTED', false)) {
        return {
          afterKey: key,
        };
      }
      return R;
    }, { afterKey: null });

    dispatch(DCAddField(
      PATH,
      availableFields.get('visibleItems', new Map())
        .filter((row) => row.get('_SELECTED', false)),
      null,
      addParams.afterKey,
    ));
  };

  selectHandler = (e, field) => {
    const { dispatch } = this.props;
    dispatch(DCSelectField([...AVPATH, 'visibleItems'], field, e.ctrlKey));
  };

  toggleAvailabeFields = (e, field) => {
    const { dispatch } = this.props;
    dispatch(DCToggleField(AVPATH, field));
  };

  changeSortHandler = (e, rowId, newSort) => this.props.dispatch(changeField([...PATH, rowId, 'OrderType'], newSort));

  render() {
    const { availableFields, data } = this.props;
    const leftSelected = !!availableFields.get('visibleItems', new Map()).filter((row) => row.get('_SELECTED', false)).size;
    const rightSelected = !!data.filter((row) => row.get('_SELECTED', false)).size;
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
      >
        <StyledPanelsContainer>
          <AvailableFields
            items={availableFields.get('visibleItems', new Map())}
            selectHandler={this.selectHandler}
            choiceHandler={this.fieldAddHandler}
            toggleHandler={this.toggleAvailabeFields}
          />
          <NavButtons
            canRight={leftSelected}
            canLeft={rightSelected}
            onRight={this.fieldAddHandler}
            onLeft={this.fieldDeletionHandler}
          />
          <OrderView
            availableFields={availableFields.get('items')}
            data={data}
            selectHandler={this.fieldSelectionHandler}
            toggleUseHandler={this.toggleSelection}
            deleteHandler={this.fieldDeletionHandler}
            changeSortHandler={this.changeSortHandler}
          />
        </StyledPanelsContainer>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => {
  const { name } = storePathParam(state);
  return {
    data: state.getIn([`rep/${name}/reportEditor`, ...PATH], OrderedMap({})),
    availableFields: state.getIn([`rep/${name}/reportEditor`, ...AVPATH], Map({})),
  };
};

// const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps)(Order);
