import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import { CommandPanel } from '../../../components/bootStrap';
import {
  AddRowButton, RemoveRowButton, CopyRowButton, FillInTheTableButton,
} from '../../../components/bootStrap/buttons';
import { childrenPropType } from '../../../common/proptypes';

function TPCommandPanel({
  canModified, onAdd, onDelete, onCopy, children, selectedRows, onFillIn,
}) {
  return (
    <CommandPanel>
      <ButtonGroup>
        {onAdd && (
        <AddRowButton
          onClick={onAdd}
          disabled={!canModified}
        />
        )}
        {onCopy && (
        <CopyRowButton
          onClick={onCopy}
          disabled={!canModified || !selectedRows.length}
        />
        )}
        {onDelete && (
        <RemoveRowButton
          onClick={onDelete}
          disabled={!canModified || !selectedRows.length}
        />
        )}

        {onFillIn && (
        <FillInTheTableButton
          onClick={onFillIn}
          disabled={!canModified}
        />
        )}
      </ButtonGroup>
      {children}
    </CommandPanel>
  );
}

TPCommandPanel.propTypes = {
  canModified: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  children: childrenPropType,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  onFillIn: PropTypes.func,
};

TPCommandPanel.defaultProps = {
  onCopy: null,
  canModified: true,
  children: null,
  selectedRows: [],
  onFillIn: null,
};

export default TPCommandPanel;
