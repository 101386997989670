// eslint-disable-next-line import/no-cycle
import allocationplan from './allocationplan';
import allocationplanchanges from './allocationplanchanges';
import finrequest from './finRequest';
import finorder from './financialOrder';
import finsourceplanchanges from './finSourcePlanChanges';
import revenueplanchanges from './revenuePlanChanges';
import netCategory from './netCategory';

const docs = {
  allocationplan,
  allocationplanchanges,
  finrequest,
  finorder,
  finsourceplanchanges,
  revenueplanchanges,
  netCategory,
};

export default docs;
