import React, {
  forwardRef, lazy, useContext, useState, Suspense,
} from 'react';
import { Modal } from 'react-bootstrap';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/no-cycle
import { DocListerCommandPanel } from '../../../../newLister/tableComponent/commandpanel';
import { CPButton } from '../../../../../components/bootStrap/buttons/styles';
import ListerContext from '../../../../newLister/context';

// eslint-disable-next-line import/no-cycle
const ExportChangesToDBFEditor = lazy(() => import('../../../../services/ExportChangesToDBF/ExportChangesToDBF'));

const CommandPanel = forwardRef((_, ref) => {
  const {
    backendURL, selectedRows,
  } = useContext(ListerContext);
  const [openedDBF, setOpenedDBF] = useState(false);

  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <CPButton
        onClick={() => setOpenedDBF(true)}
        disabled={!selectedRows.length}
        content="DBF"
        icon={faFileExport}
      />
      <Modal
        show={openedDBF}
        onHide={() => setOpenedDBF(false)}
        className="w-100"
        dialogClassName="min-vw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Вивантаження в ДБФ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={(<span>...</span>)}>
            <ExportChangesToDBFEditor
              currentItem={selectedRows}
              backendURL={backendURL}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
    </DocListerCommandPanel>
  );
});

export default CommandPanel;
