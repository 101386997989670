import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';

const Prepend = styled(InputGroup.Text)`
  overflow: hidden;
`;
export const PrependKVK = styled(Prepend)`
  width: min(2.5rem, 50%);
`;
export const PrependFond = styled(Prepend)`
  width: min(2rem, 50%);
`;

export const PrependKBP7 = styled(Prepend)`
  width: min(5rem, 50%);
`;

export const PrependKFB = styled(Prepend)`
  width: 5rem;
`;

export const PrependKEKV = styled(Prepend)`
  width: min(3.5rem, 50%);
`;

export const PrependAccount = styled(Prepend)`
  //width: 14rem; не влазить весь номер рахунку
    max-width: 50%;
`;
