import React, { memo } from 'react';

import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Plan'} highlighted={highlights.includes('incomesGF_Plan')}>
            {totals.incomesGF_Plan.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Plan'} highlighted={highlights.includes('incomesSF_Plan')}>
            {totals.incomesSF_Plan.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Plan1'} highlighted={highlights.includes('incomesGF_Plan1')}>
            {totals.incomesGF_Plan1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Plan1'} highlighted={highlights.includes('incomesSF_Plan1')}>
            {totals.incomesSF_Plan1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Plan2'} highlighted={highlights.includes('incomesGF_Plan2')}>
            {totals.incomesGF_Plan2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Plan2'} highlighted={highlights.includes('incomesSF_Plan2')}>
            {totals.incomesSF_Plan2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row xl={7} className="w-100" noGutters>
        <Col className="text-right" />
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Add'} highlighted={highlights.includes('incomesGF_Add')}>
            {totals.incomesGF_Add.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Add'} highlighted={highlights.includes('incomesSF_Add')}>
            {totals.incomesSF_Add.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Add1'} highlighted={highlights.includes('incomesGF_Add1')}>
            {totals.incomesGF_Add1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Add1'} highlighted={highlights.includes('incomesSF_Add1')}>
            {totals.incomesSF_Add1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesGF_Add2'} highlighted={highlights.includes('incomesGF_Add2')}>
            {totals.incomesGF_Add2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="text-right">
          <TableHeaderCell active={activeCol === 'incomesSF_Add2'} highlighted={highlights.includes('incomesSF_Add2')}>
            {totals.incomesSF_Add2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  totals: PropTypes.shape({
    incomesGF_Plan: PropTypes.number,
    incomesGF_Add: PropTypes.number,

    incomesSF_Plan: PropTypes.number,
    incomesSF_Add: PropTypes.number,

    incomesGF_Plan1: PropTypes.number,
    incomesGF_Add1: PropTypes.number,

    incomesSF_Plan1: PropTypes.number,
    incomesSF_Add1: PropTypes.number,

    incomesGF_Plan2: PropTypes.number,
    incomesGF_Add2: PropTypes.number,

    incomesSF_Plan2: PropTypes.number,
    incomesSF_Add2: PropTypes.number,

  }).isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
