import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dataPropTypes } from './propTypes';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { fieldsPropTypes } from '../directionOfUse/propTypes';
import FillButton from '../additionalCP/fillingData';

function ActivityAnalogySF({
  onDraft, onChange, data, fields, ...rest
}) {
  const totals = Object.keys(fields.executionbpactivityanalogysfrow_set.child.children).filter((k) => k.includes('amount'));

  const onChangeTP = useCallback((f) => {
    const sumChanger = (oldData) => oldData.executionbpactivityanalogysfrow_set.map((row) => ({
      ...row,
      amount_pr_fact_zf: (row.amount_pr_fact_zf || 0),
      amount_pr_fact_sf: (row.amount_pr_fact_sf || 0),
      amount_pr_fact_total: (row.amount_pr_fact_zf || 0) + (row.amount_pr_fact_sf || 0),
      amount_fact_zf: (row.amount_fact_zf || 0),
      amount_fact_sf: (row.amount_fact_sf || 0),
      amount_fact_total: (row.amount_fact_zf || 0) + (row.amount_fact_sf || 0),
      amount_diff_zf: (row.amount_pr_fact_zf - row.amount_fact_zf || 0),
      amount_diff_sf: (row.amount_pr_fact_sf - row.amount_fact_sf || 0),
      amount_diff_total: (row.amount_pr_fact_zf || 0) + (row.amount_pr_fact_sf || 0)
          - ((row.amount_fact_zf || 0) + (row.amount_fact_sf || 0)),
    }));
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
        executionbpactivityanalogysfrow_set: sumChanger(d),
      };
    });
  }, [onChange]);

  const template = useMemo(
    () => {
      const sumColProps = {
        xxl: 2,
        xl: 3,
        lg: 2,
        sm: 12,
      };
      const activityColProps = {
        xxl: 6,
        xl: 3,
        lg: 6,
        sm: 12,
      };

      const sumcolumns = [
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_pr_fact_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_pr_fact_zf',
                  headerLabel: 'ЗФ',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_pr_fact_sf',
                  headerLabel: 'СФ',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_fact_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_fact_zf',
                  headerLabel: 'ЗФ',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_fact_sf',
                  headerLabel: 'СФ',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_diff_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6 },
                  field: 'amount_diff_zf',
                  headerLabel: 'ЗФ',
                },
                {
                  colProps: { xs: 6 },
                  field: 'amount_diff_sf',
                  headerLabel: 'СФ',
                },
              ],
            },
          ],
        },

      ];

      return {
        columns: [{
          colProps: { xs: 12 },
          columns: [
            {
              colProps: activityColProps,
              columns: [
                {
                  field: 'activity',
                  colProps: { xs: 12 },
                  controlProps: {
                    typeOfChoice: typesOfChoices.onlyElements,
                    filter: {
                      budget: data.budget ? data.budget.id : null,
                    },
                  },
                },
              ],
            },
            ...sumcolumns,
          ],
        },
        {
          colProps: { xs: 12 },
          columns: [
            {
              field: 'cost_change_explanation',
              colProps: { xs: 6 },
            },
            {
              field: 'inindicator_dynamics_explanation',
              colProps: { xs: 6 },
            },
          ],
        },
        ],
      };
    },
    [data.budget],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="executionbpactivityanalogysfrow_set"
      onChange={onChangeTP}
      fields={fields}
      totals={totals}
      addCommands={(
        <FillButton onClick={() => onDraft(data, { fill_activityanalogysf: true })} />
      )}
    />
  );
}

ActivityAnalogySF.propTypes = {
  data: PropTypes.shape({
    executionbpactivityanalogysfrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    budget: foreignPropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    executionbpactivityanalogysfrow_set: fieldsPropTypes,
  }),

};

ActivityAnalogySF.defaultProps = {
  fields: null,
};

export default ActivityAnalogySF;
