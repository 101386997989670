import ListerFactory from '../../../newLister';
import { InfoRegTableListerFactory } from '../../../newLister/tableComponent';

const KBPElementBetweenLister = ListerFactory({
  modelType: 'infoRegs',
  modelName: 'kbp7ElemBetween',
  viewType: 'lister',
})(InfoRegTableListerFactory({
  // CommandPanel,
}));

export default KBPElementBetweenLister;
