const frontendURL = '/rep/planFact/';
const name = 'Порівняння видатків (план / факт)';
const sections = 'planning';

const instance = {
  frontendURL,
  name,
  sections,
};

export default instance;
