import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorSelectorInput,
  EditorStingInput,
  EditorMultiSelectorInput,
} from '../../../basicEditor/editorControls';
import { calcPropType } from '../hooks';

function CalcProps({
  value, onChange, readOnly,
  allowedCtypes, fields,
}) {
  return (
    <div className="containerField p-3 border rounded">
      <EditorMultiSelectorInput
        controlId="calc_include_input"
        label="Include"
        required
        description="Перечень полей (из раздела fields) которые включает данная сущность. При использовании данного поля в запрос будут включены все поля из include."
        value={value.include}
        values={fields}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ include: v })}
      />
      <EditorSelectorInput
        controlId="calc_ctype_input"
        label="Ctype"
        required
        description="Тип поля"
        value={value.ctype}
        readOnly={readOnly}
        values={allowedCtypes}
        onChange={(e, v) => onChange({
          ctype: v,
        })}
      />
      <EditorStingInput
        controlId="calc_formula_input"
        label="Formula"
        required
        description="ФОрмула для вычисления поля. Представляет собой python-выражение"
        value={value.formula}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ formula: v })}
      />
    </div>
  );
}

CalcProps.propTypes = {
  value: calcPropType,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  allowedCtypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CalcProps.defaultProps = {
  value: '',
  readOnly: false,
};

export default CalcProps;
