function downloadXls(xls, name) {
  if (!xls) return null;
  const byteCharacters = atob(xls);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'xlsx' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.download = `${name}.xlsx`;
  a.href = url;

  document.body.append(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
  return null;
}

export default downloadXls;
