const frontendURL = '/doc/disposersMapChanges/';
const backendURL = '/api/merezha/disposers_map_changes/';
const name = 'Зміни до мережі розпорядників';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
