const frontendURL = '/cat/staff/';
const backendURL = '/api/logica/director/';
const name = 'Персонал';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
