import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Badge,
  Button, ButtonGroup, Card,
  Col, Collapse, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import FilterGrid from '../../../basicEditor/stdFilters/filterGrid';
import { EditorCheckboxInput, EditorDateInput } from '../../../basicEditor/editorControls';
import { CommandPanel, Loader } from '../../../../components/bootStrap';
import {
  HeaderText, FooterText,
} from '../../../basicEditor/stdFilters/style';
import { CPButton } from '../../../../components/bootStrap/buttons/styles';
import useFilter from '../../../basicEditor/stdFilters/useFilter';
import { decimalToStr } from '../../../../common/funcs';
import TableList from './tableList';
import CardList from './cardList';

function FilterEditor({
  overrideFilter, defaultFilter,
  onClose, onOk, docName, mode,
}) {
  const { API_URL, LOCAL_FILTERS } = useMemo(
    () => {
      if (mode === 'finorder') {
        return {
          API_URL: '/api/financials/loadfinrequests/',
          LOCAL_FILTERS: ['begin_date', 'end_date', 'executed', 'kbp7'],
        };
      }
      return {
        API_URL: '/api/financials/finrequestrow/',
        LOCAL_FILTERS: ['begin_date', 'end_date', 'authority', 'executed', 'kbp7'],
      };
    },
    [mode],
  );

  const {
    filteringFields, items, loading, filters, setFilterHandler, fields, error, ready,
  } = useFilter({ apiUrl: API_URL, defaultFilter, overrideFilter });

  const displayFilteringFields = useMemo(
    () => filteringFields.filter((ff) => !LOCAL_FILTERS.includes(ff.name)),
    [LOCAL_FILTERS, filteringFields],
  );

  const [selectedItems, setSelectedItems] = useState([]);
  const [showApproved, setShowApproved] = useState(false);

  const totals = useMemo(
    () => {
      const lines = items.filter((item) => item[docName] && selectedItems.includes(item.id));
      return {
        docsCount: new Set(lines.map((l) => l[docName].id)).size,
        sum: lines.reduce((R, i) => (R + (mode === 'finorder' ? i.balance : i.amount)), 0),
      };
    },
    [docName, items, mode, selectedItems],
  );

  useEffect(() => {
    setSelectedItems(items.map((i) => i.id));
  }, [items]);

  const [showFilters, setShowFilters] = useState(false);
  const [tableView, setTableView] = useState(false);

  return ready && (
    <>
      <Modal.Header closeButton>
        <HeaderText>Завантаження документів</HeaderText>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <Loader text="Завантаження" />
        )}
        {error && (
          <Alert variant="danger">
            <Alert.Heading>
              {error}
            </Alert.Heading>
          </Alert>
        )}
        <div className="h-100 overflow-hidden d-flex flex-column">
          <Container fluid>
            <Row>
              <Col>
                <EditorDateInput
                  controlId="begin_date"
                  label="Відібрати документи за період з"
                  required
                  value={filters.begin_date.value}
                  onChange={(e, v) => setFilterHandler('begin_date', v, true)}
                />
              </Col>
              <Col>
                <EditorDateInput
                  controlId="end_date"
                  label="по"
                  required
                  value={filters.end_date.value}
                  onChange={(e, v) => setFilterHandler('end_date', v, true)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CommandPanel
                  leftPart={(
                    <>
                      <CPButton
                        onClick={() => setShowFilters(!showFilters)}
                        title="Фільтрування"
                        icon={faFilter}
                      />
                      <ButtonGroup>
                        <CPButton
                          onClick={() => setSelectedItems(items.map((i) => i.id))}
                          title="Обрати всі"
                          icon={faCheckSquare}
                        />
                        <CPButton
                          onClick={() => setSelectedItems([])}
                          title="Зняти відмітки"
                          icon={faSquare}
                        />
                      </ButtonGroup>
                    </>
                  )}
                  rightPart={(
                    <EditorCheckboxInput
                      value={tableView}
                      onChange={() => setTableView((o) => !o)}
                      label="Табличний перегляд"
                      description="Ви можете переключитися між табличним та картковим переглядом і обрати найбільш зручний для Вас"
                    />
                  )}
                />
                <Card className="border-0">
                  <Collapse in={showFilters} appear>
                    <Card.Body className="p-2">
                      <FilterGrid
                        filters={filters}
                        filteringFields={displayFilteringFields}
                        onSetFilter={setFilterHandler}
                      />
                      { mode === 'finrequest' && (
                      <EditorCheckboxInput
                        value={showApproved}
                        onChange={() => {
                          setShowApproved((o) => !o);
                          setFilterHandler('approved', false, showApproved);
                        }}
                        label="Показати отримані"
                        description="Показати вже затягнуті на верхній рівень заявки"
                      />
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Container>
          {tableView ? (
            <TableList
              fields={fields}
              selectedItems={selectedItems}
              items={items}
              setSelectedItems={setSelectedItems}
              docName={docName}
              mode={mode}
            />
          ) : (
            <CardList
              fields={fields}
              selectedItems={selectedItems}
              items={items}
              setSelectedItems={setSelectedItems}
              docName={docName}
              mode={mode}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FooterText>
          Буде завантажено
          {' '}
          <Badge bg="info">{`${totals.docsCount}`}</Badge>
          {' '}
          документів на суму
          {' '}
          <Badge bg="info">{decimalToStr(totals.sum, '0,00')}</Badge>
          {' '}
          грн.
        </FooterText>
        <Button
          variant="success"
          onClick={() => {
            if (mode === 'finrequest') {
              onOk(selectedItems);
            } else {
              onOk(items.filter((item) => selectedItems.includes(item.id)));
            }
          }}
        >
          Завантажити
        </Button>
        <Button variant="warning" onClick={onClose}>Закрити</Button>
      </Modal.Footer>
    </>
  );
}

FilterEditor.propTypes = {
  overrideFilter: PropTypes.shape({}),
  defaultFilter: PropTypes.shape({
    begin_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  docName: PropTypes.oneOf(['header', 'finrequest', 'finproposal']).isRequired,
  mode: PropTypes.oneOf(['finrequest', 'finorder']).isRequired,
};

FilterEditor.defaultProps = {
  overrideFilter: {},
};

export default FilterEditor;
