import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem } from '../styles';
import { subGroupPropType } from '../../hooks';
import IconButton from '../../../../components/blanks/common/IconButton';

function SKDGroupItem({
  subgroups, onClick, active, name, onDblClick, onHover, padded, onSubGroupClick, onChangeHierarchy,
  onCtxMenu,
}) {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.group],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: itemTypes.group },
    type: itemTypes.group,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  drag(drop(ref));

  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={isOver ? 'success' : ''}
      style={style}
      onContextMenu={(e) => onCtxMenu(e, ref.current)}
      data-name={name}
    >
      <StyledSubItem
        padded={padded}
      />
      {subgroups.map((f) => (
        <h5>
          <Badge
            variant={f.errored ? 'danger' : 'light'}
            key={f.name}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}

            <span
              className="me-2"
            >
              {f.label}
            </span>
          </Badge>
          <IconButton
            size="sm"
            variant="light"
            onClick={() => onChangeHierarchy(name, f.name, !f.useHierarchy)}
            disabled={!f.canHierarchy}
            title={f.useHierarchy ? 'З ієрархія' : 'Без ієрархії'}
            color="var(--activeColorTextTab)"
            icon={f.useHierarchy ? faStream : faBars}
          />
          {subgroups.length > 1 && (
            <FontAwesomeIcon
              icon={faTimes}
              title="Прибрати"
              transform="shrink-6 up-6 left-8"
              color="#b90c0c"
              onClick={() => onSubGroupClick(name, f.name)}
            />
          )}

        </h5>
      ))}
    </StyledFieldItem>
  );
}

SKDGroupItem.propTypes = {
  name: PropTypes.string.isRequired,
  subgroups: PropTypes.arrayOf(subGroupPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  onSubGroupClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  onChangeHierarchy: PropTypes.func.isRequired,
  onCtxMenu: PropTypes.func.isRequired,
};

SKDGroupItem.defaultProps = {
  active: false,
  padded: false,
};

export default SKDGroupItem;
