/* eslint-disable import/no-named-as-default */
import activityKinds from './activityKinds';
import bankAccounts from './bankAccounts';
import banks from './banks';
import bfnAmount from './bfnAmount';
import bfnGoalFondAmount from './bfnGoalFondAmount';
import bfnGroupAge from './bfnGroupAge';
import bfnGroupFullness from './bfnGroupFullness';
import bfnGroupTypes from './bfnGroupTypes';
import bfnPeriod from './bfnPeriod';
import bfnGroupName from './bfnGroupName';
import bfnBanDoc from './bfnBanDoc';
import buildingClassifier from './buildingClassifier';
import coverageSourceClassifier from './coverageSourceClassifier';
import additionalPropertyCsu from './additionalPropertyCsu';
import additionalPropertyTypesRepair from './additionalPropertyTypesRepair';
import budget from './budget';
import budgetTransfer from './budgetTransfer';
import csu from './CSU';
import deputies from './deputies';
import elementFond from './elementFond';
import elementKFK from './elementKFK';
import elementKEKV from './elementKEKV';
import fondClassifier from './fondClassifier';
import kbp7Classifier from './kbp7Classifier';
import kbpClassifier from './kbpClassifier';
import kekvClassifier from './kekvClassifier';
import koatuu from './koatuu';
import kvkClassifier from './kvkClassifier';
import landClassifier from './landClassifier';
import signGroup from './signGroup';
import target from './target';
import users from './users';
import elementKFB from './elementKFB';
import templateBaseDoc from './templateBaseDoc';
import tenderProcType from './tenderProcType';
import elementKDB from './elementKDB';
import kdbClassifier from './kdbClassifer';
import kfbClassifier from './kfbClassifier';
import stewardGroup from './stewardGroup';
import kfkvkClassifier from './kfkvkClassifier';
import groundLaw from './groundLaw';
import laws from './laws';
import stewardLevel from './stewardLevel';
import baseDoc from './baseDoc';
import notes from './notes';
import docNotes from './docNotes';
import csuPartners from './csuPartners';
import coverageSource from './coverageSource';
import implementer from './implementer';
import tasks from './tasks';
import tasks2019 from './tasks2019';
import variantBudgetDraft from './variantBudgetDraft';
import noteClassifier from './noteClassifier';
import parkingKinds from './parkingKinds';
import partners from './partners';
import targetedPrograms from './targetedPrograms';
import partnerBanks from './partnerBanks';
import partnerBankAccounts from './partnerBankAccounts';
import signatureVariants from './signatureVariants';
import indicators from './indicators';
import energyCarriersIndicator from './energyCarriersIndicator';
import typesOfLaborCosts from './typesOfLaborCosts';
import salaryStructure from './salaryStructure';
import industry from './industry';
import docType from './docType';
import outcomeGroup from './outcomeGroup';
import publicBudgetProjectAuthor from './publicBudgetProjectAuthor';
import accessGroup from './accessGroup';
import officials from './officials';
import elementSocialPayment from './elementSocialPayment';
import articlesDebtSocialPayments from './articlesDebtSocialPayments';
import noteSocialPayments from './noteSocialPayments';
import treasuryStatement from './treasuryStatement';
import houses from './houses';
import districts from './districts';
import streets from './streets';
import typeTaxSFForRaitingZF from './typeTaxSFForRaitingZF';
import statesContingents from './statesContingents';
import paymentPurposeTemplate from './paymentPurposeTemplate';
import signGroupByDocuments from './signGroupByDocuments';
import units from './units';
import explanations from './explanations';
import administrativeTerritorialUnits from './administrativeTerritorialUnits';
import indicatorsClassifier from './indicatorsClassifier';
import infoSources from './infoSources';
import tasksClassifier from './tasksClassifier';
import planing from './planing';
import regions from './regions';
import provisionOfNormativeLegalActs from './provisionOfNormativeLegalActs';
import recipients from './recipients';
import submittedDocuments from './submittedDocuments';
import publicBudgetProject from './publicBudgetProject';
import artistsRoles from './artistsRoles';
import kekvClassifierShort from './kekvClassifierShort';
import kekvClassifierForPAShort from './kekvClassifierForPAShort';
import kekvClassifierForMinFinShort from './kekvClassifierForMinFinShort';
import kekvClassifierFPShort from './kekvClassifierFPShort';
import numbering from './numbering';
import reportSettings from './reportSettings';
import residenceKinds from './residenceKinds';
import typesRepair from './typesRepair';
import typesWork from './typesWork';
import typesRepairIndustry from './typesRepairIndustry';
import objectsBuilding from './objectsBuilding';
import investor from './investor';
import objBuildingPVR from './objBuildingPVR';
import objBuildingTypeDoc from './objBuildingTypeDoc';
import objectivesStatePolicy from './objectivesStatePolicy';
import typeAttachedFile from './typeAttachedFile';
import additionalPropertyKFK from './additionalPropertyKFK';
import resolutionSession from './resolutionSession';
import objBuildingSourceFin from './objBuildingSourceFin';
import objBuildingTypeSource from './objBuildingTypeSource';
import objBuildingTypePower from './objBuildingTypePower';
import forecastCredit from './forecastCredit';
import forecastIndicatorBR from './forecastIndicatorBR';
import indicatorsForTarget from './indicatorsForTarget';
import csuTransfer from './csuTransfer';
import unitsClassifier from './unitsClassifier';
import tarif from './tarif';
import categoriesFOP from './categoriesFOP';
import categoriesFOPVersions from './categoriesFOPVersions';
import industryDet from './industryDet';
import currency from './currency';
import department from './department';
import reportsForms from './reportsForms';
import budgetPaymentCode from './budgetPaymentCode';
import symbolKdb from './symbolKdb';

const catalogs = {
  accessGroup,
  activityKinds,
  additionalPropertyCsu,
  additionalPropertyKFK,
  additionalPropertyTypesRepair,
  administrativeTerritorialUnits,
  articlesDebtSocialPayments,
  artistsRoles,
  banks,
  bankAccounts,
  baseDoc,
  bfnAmount,
  bfnGoalFondAmount,
  bfnGroupAge,
  bfnGroupFullness,
  bfnGroupTypes,
  bfnPeriod,
  bfnGroupName,
  bfnBanDoc,
  budget,
  budgetTransfer,
  buildingClassifier,
  coverageSourceClassifier,
  investor,
  csu,
  deputies,
  elementFond,
  elementKFK,
  elementKEKV,
  fondClassifier,
  kbp7Classifier,
  kbpClassifier,
  kekvClassifier,
  koatuu,
  kvkClassifier,
  landClassifier,
  signGroup,
  target,
  users,
  elementKFB,
  elementKDB,
  kdbClassifier,
  templateBaseDoc,
  tasks,
  tasks2019,
  coverageSource,
  implementer,
  variantBudgetDraft,
  tasksClassifier,
  kfbClassifier,
  stewardGroup,
  kfkvkClassifier,
  groundLaw,
  laws,
  stewardLevel,
  notes,
  docNotes,
  csuPartners,
  noteClassifier,
  parkingKinds,
  partners,
  targetedPrograms,
  energyCarriersIndicator,
  typesOfLaborCosts,
  tenderProcType,
  salaryStructure,
  outcomeGroup,
  docType,
  industry,
  partnerBanks,
  partnerBankAccounts,
  signatureVariants,
  indicators,
  publicBudgetProjectAuthor,
  officials,
  elementSocialPayment,
  noteSocialPayments,
  treasuryStatement,
  houses,
  districts,
  streets,
  typeTaxSFForRaitingZF,
  statesContingents,
  paymentPurposeTemplate,
  signGroupByDocuments,
  units,
  explanations,
  indicatorsClassifier,
  infoSources,
  // modelProgramsClassifier,
  regions,
  provisionOfNormativeLegalActs,
  recipients,
  publicBudgetProject,
  submittedDocuments,
  kekvClassifierShort,
  kekvClassifierForPAShort,
  kekvClassifierForMinFinShort,
  kekvClassifierFPShort,
  numbering,
  reportSettings,
  residenceKinds,
  typesRepair,
  typesWork,
  typesRepairIndustry,
  objectsBuilding,
  objBuildingPVR,
  objBuildingTypeDoc,
  objectivesStatePolicy,
  typeAttachedFile,
  resolutionSession,
  objBuildingTypeSource,
  objBuildingTypePower,
  objBuildingSourceFin,
  forecastCredit,
  planing,
  forecastIndicatorBR,
  indicatorsForTarget,
  csuTransfer,
  unitsClassifier,
  tarif,
  categoriesFOP,
  categoriesFOPVersions,
  industryDet,
  currency,
  department,
  reportsForms,
  budgetPaymentCode,
  symbolKdb,
};

export const cat1c = new Proxy({}, {
  get(_, prop) {
    const cat = Object.keys(catalogs)
      .filter((d) => catalogs[d].backendName.toLowerCase() === prop.toLowerCase());
    if (cat.length === 1) return catalogs[cat[0]];
    throw new Error('Property {prop} not found in documents!');
  },
});

export default catalogs;
