import styled from 'styled-components';
import { CommandPanelButtonText } from '../../components/Form/styledForm';

export const GeneralBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ContaiterForm = styled.div`
  display: grid;
  grid-row-gap: 10px;
  box-shadow: rgb(58, 140, 194) 0px 1px 30px -4px;
  background: white;
  border-radius: 0.3rem;
  padding: 20px;
`;

export const HeaderLogin = styled.h1`
  color: #00b5ad;
  font-size: 2em;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  background-color: #fff6f6;
  border-radius: 4px;
  font-weight: 700;
  color: #9f3a38;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.4285em;
`;

export const ButtonLogin = styled(CommandPanelButtonText)`
  display: grid;
  margin-right: 0;
  margin-top: 15px;
  background: #00b5ad;
  color: white;
    :hover {
      background: #009c95;
    };
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
`;

export const Input = styled.input`
  border: none;
  border-left: 1px solid rgba(34,36,38,.15);
  padding: 9.4px;
  width: 100%;
`;

export const ImgInput = styled.img`
  height: 20px;
  padding: 0 5px;
`;

export const Base = styled.div`
   cursor: pointer;
    :hover {
      background-color: #e8edfa;
    }
`;

export const DropContainer = styled.div`
  position: absolute;
  background-color: white;
  right: 20px;
  left: 20px;
  border: 1px solid rgba(142, 179, 223, .5);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  max-height: 200px;
  overflow: auto;
`;
