import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import OmitElementKFKEditor from '../../dataProcessors/omitElementKFK/editor';

function OmitElementKFKModal({ id }) {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Button variant="success" onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faTasks} className="me-2" />
        Створити для підлеглих розпорядників
      </Button>
      <Modal show={opened} onHide={() => setOpened(false)} size="xl">
        <Modal.Header closeButton>
          Створити детальні КПКВ для підлеглих розпорядників
        </Modal.Header>
        <Modal.Body>
          <OmitElementKFKEditor
            mapInitialOptions={{
              Link: 'ЭлКФК',
            }}
            mapStore={() => new Map()
              .setIn(['Link', 'id'], id)
              .setIn(['Link', 'repr'], '?')}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

OmitElementKFKModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OmitElementKFKModal;
