import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows, RequestRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import LoadFinRequestButton from './loadFinRequest';
import { PrependAccount, PrependFond } from '../../styles/klassifiers';
import ApprovedInfo from '../../common/approvedInfo';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.finproposal.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const numberProps = editorHooks.useStringInputProps(
    'number',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const planKindProps = editorHooks.useSelectorInputProps(
    'plan_kind',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  const fondItemProps = editorHooks.useItemInputProps(
    'fond_item',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  const authorityAccProps = editorHooks.useItemInputProps(
    'authority_acc',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );

  const authorityAccFilter = useMemo(
    () => ({
      disposer: data.authority ? data.authority.id : null,
      fond: data.fond ? data.fond.id : null,
    }),
    [data.authority, data.fond],
  );

  const commentProps = editorHooks.useTextInputProps(
    'comment',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const transferTypeProps = editorHooks.useSelectorInputProps(
    'transfer_type',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    !permissions.canChange,
    headerReadOnlyFields,
  );
  const isRefundProps = editorHooks.useCheckboxInputProps('is_refund', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const isRefundByMonthProps = editorHooks.useCheckboxInputProps('is_refund_by_month', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const monthRefundProps = editorHooks.useSelectorInputProps('month_refund', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.finproposal.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  canErase
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                  )}
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...authorityAccProps}
                  filter={authorityAccFilter}
                  canErase
                  prepend={(
                    <PrependAccount>
                      {data.authority_acc && data.authority_acc.code}
                    </PrependAccount>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.SelectorInput
                  {...transferTypeProps}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  {...isRefundProps}
                  disabled={data.is_refund_by_month}
                />
              </Col>
              {data.is_refund && (
              <Col>
                <EditorControls.SelectorInput {...monthRefundProps} required />
              </Col>
              )}
              {!data.is_refund && (
              <Col>
                <EditorControls.CheckboxInput {...isRefundByMonthProps} />
              </Col>
              )}
            </Row>

            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice_levelup}
              type="approved"
              url={`${meta.doc.finproposal.backendURL}${data.id}/`}
            />
            <ApprovedInfo
              approved={data.is_financed}
              approvedText={data.invoice}
              type="financed"
              url={`${meta.doc.finproposal.backendURL}${data.id}/`}
              position={2}
            />

            <LoadFinRequestButton
              disabled={!permissions.canChange}
              authority={data.authority}
              fond={data.fond}
              docDate={data.doc_date}
              year={data.year}
              planKind={data.plan_kind}
              onLoadHandler={(rows) => actions.onDraft(data, {
                load_finproposalrequestrow_set: {
                  rows,
                  clear: true,
                },
              })}
            />
          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="finproposal" id="uncontrolled-tab-example">
          {fields && fields.finproposalrow_set && (
            <Tab eventKey="finproposal" title={fields.finproposalrow_set.label}>
              <TabContainer>
                <PlanningRows
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={!permissions.canChange}
                  isRefundByMonth={data.is_refund_by_month}
                  isRefund={data.is_refund}
                />
              </TabContainer>
            </Tab>
          )}

          {fields && fields.finproposalrequestrow_set && (
            <Tab eventKey="#" title={fields.finproposalrequestrow_set.label}>
              <TabContainer>
                <RequestRows
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}

          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        readOnly={!permissions.canChange}
        onChange={actions.onChange}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
