import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters>
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row>
          <Col />
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumPlan'}
              highlighted={highlights.includes('sumPlan')}
            >
              {totals.sumPlan.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumFact'}
              highlighted={highlights.includes('sumFact')}
            >
              {totals.sumFact.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-right"
              active={activeCol === 'sumDeviation'}
              highlighted={highlights.includes('sumDeviation')}
            >
              {totals.sumDeviation.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumPlan: PropTypes.number,
    sumFact: PropTypes.number,
    sumDeviation: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
