import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import md from '../../../../constants/meta/reports/sessionAdd5Transfert';

function PirntButton() {
  return (
    <Button variant="primary">
      <Link
        to={`/${md.frontend}/`}
      >
        <FontAwesomeIcon icon={faPrint} color="white" />
      </Link>
    </Button>
  );
}

export default PirntButton;
