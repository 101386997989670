import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Badge,
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/netCategory';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { COLOR_1, COLOR_2, COLOR_3 } from '../../colors';
// eslint-disable-next-line import/no-named-as-default
import ItemField
  from '../../../../../../components/bootstrap_components/controls/itemField/itemField';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row,
  active,
  rowIndex,
  onChange,
  activeCol,
  readOnly,
  highlights,
  onSR,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, {
      ...partData,
      [tableMD.columns.status.name]: 0,
    }),
    [onChange, rowIndex],
  );

  // const onSum1Change = () => null;
  // const onSum2Change = () => null;
  // const onSum3Change = () => null;
  // const onSum4Change = () => null;
  const onSum1Change = useCallback(
    (e, v) => {
      onRowChange(e, { [tableMD.columns.sum1.name]: v });
      // await onSR('CHANGE_SUM1_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange],
  );

  const onSum2Change = useCallback(
    (e, v) => {
      onRowChange(e, { [tableMD.columns.sum2.name]: v });
      // await onSR('CHANGE_SUM2_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange],
  );

  const onSum3Change = useCallback(
    (e, v) => {
      onRowChange(e, { [tableMD.columns.sum3.name]: v });
      // await onSR('CHANGE_SUM3_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange],
  );

  const onSum4Change = useCallback(
    (e, v) => {
      onRowChange(e, { [tableMD.columns.sum4.name]: v });
      // await onSR('CHANGE_SUM4_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange],
  );

  const onCategoryChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.category.name]: v });
      await onSR('CHANGE_CATEGORY_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const statusSum = row[tableMD.columns.statusSum.name] ? row[tableMD.columns.statusSum.name] : '0000';

  const rowClass = useMemo(
    () => {
      const statusRow = row[tableMD.columns.status.name];
      if (statusRow === 1) return `bg-${COLOR_1}`;
      if (statusRow === 2) return `bg-${COLOR_2}`;
      if (statusRow === 3) return `bg-${COLOR_3}`;
      return '';
    },
    [row],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} className={rowClass}>
      <Col sm={8} className="border-right">
        <Row noGutters>
          <Col sm={12}>
            <TableRowCell column="category" highlighted={highlights.includes('category')}>
              <ItemField
                value={row[tableMD.columns.category.name]}
                modelType="cat"
                modelName="categoriesFOP"
                onChange={onCategoryChange}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                tabIndex="-1"
                required
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.category3.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell
              column="countRow"
              highlighted={highlights.includes('countRow')}
              className="border-right text-left"
            >
              <Badge variant="light" className="py-1 my-1 text-center" style={{ fontSize: '.85rem' }}>
                {row[tableMD.columns.countRow.name]}
              </Badge>
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="TPKVK" highlighted={highlights.includes('TPKVK')}>
              <Badge variant="info" className="py-1 my-1 text-center" style={{ fontSize: '.85rem' }}>
                {row[tableMD.columns.TPKVK.name] && row[tableMD.columns.TPKVK.name].repr}
              </Badge>
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell
              column="unit"
              highlighted={highlights.includes('unit')}
              className="border-right text-center"
            >
              <Badge variant="warning" className="py-1 my-1 text-center" style={{ fontSize: '.85rem' }}>
                {row[tableMD.columns.unit.name]}
              </Badge>
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableRowCell
          sm={1}
          className={`border-right ${readOnly || (statusSum[0] !== '0') ? 'bg-blue-light' : ''}`}
          style={{ backgroundColor: `${readOnly || (statusSum[0] !== '0') ? '#dadada' : ''}` }}
          column="sum1"
          highlighted={highlights.includes('sum1')}
        >
          {(statusSum[0] === '1') ? (
            <div
              className="w-100 py-2 border"
              style={{
                textAlign: 'center',
                backgroundColor: '#dadada',
              }}
              tabIndex="-1"
            >
              X
            </div>
          ) : (
            <NumberInput
              value={row[tableMD.columns.sum1.name]}
              precision={2}
              placeholder="X"
              onChange={onSum1Change}
              tabIndex={statusSum[0] === '0' ? '' : '-1'}
              readOnly={readOnly || (statusSum[0] !== '0')}
            />
          )}
        </TableRowCell>
        <TableRowCell
          column="sum2"
          highlighted={highlights.includes('sum2')}
          className={`border-right ${(statusSum[1] !== '0') ? 'bg-blue-light' : ''}`}
          style={{ backgroundColor: `${(statusSum[1] !== '0') ? '#dadada' : ''}` }}
        >
          {(statusSum[1] === '1') ? (
            <div
              className="w-100 py-2 border"
              style={{
                textAlign: 'center',
                backgroundColor: '#dadada',
              }}
              tabIndex="-1"
            >
              X
            </div>
          ) : (
            <NumberInput
              value={row[tableMD.columns.sum2.name]}
              precision={2}
              placeholder="X"
              onChange={onSum2Change}
              tabIndex={statusSum[1] === '0' ? '' : '-1'}
              readOnly={readOnly || (statusSum[1] !== '0')}
            />
          )}
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell
          column="sum3"
          highlighted={highlights.includes('sum3')}
          className={`border-right ${(statusSum[2] !== '0') ? 'bg-blue-light' : ''}`}
          style={{ backgroundColor: `${(statusSum[2] !== '0') ? '#dadada' : ''}` }}
        >
          {(statusSum[2] === '1') ? (
            <div
              className="w-100 py-2 border"
              style={{
                textAlign: 'center',
                backgroundColor: '#dadada',
              }}
              tabIndex="-1"
            >
              X
            </div>
          ) : (
            <NumberInput
              value={row[tableMD.columns.sum3.name]}
              precision={2}
              placeholder="X"
              onChange={onSum3Change}
              tabIndex={statusSum[2] === '0' ? '' : '-1'}
              readOnly={readOnly || (statusSum[2] !== '0')}
            />

          )}
        </TableRowCell>
        <TableRowCell
          column="sum4"
          highlighted={highlights.includes('sum4')}
          className={`border-right ${(statusSum[3] !== '0') ? 'bg-blue-light' : ''}`}
          style={{ backgroundColor: `${(statusSum[3] !== '0') ? '#dadada' : ''}` }}
        >
          {(statusSum[3] === '1') ? (
            <div
              className="w-100 py-2 border"
              style={{
                textAlign: 'center',
                backgroundColor: '#dadada',
              }}
              tabIndex="-1"
            >
              X
            </div>
          ) : (
            <NumberInput
              value={row[tableMD.columns.sum4.name]}
              precision={2}
              placeholder="X"
              onChange={onSum4Change}
              tabIndex={statusSum[3] === '0' ? '' : '-1'}
              readOnly={readOnly || (statusSum[3] !== '0')}
            />
          )}
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.categoryName.name]: PropTypes.string,
    [tableMD.columns.category3.name]: PropTypes.string,
    [tableMD.columns.sum1.name]: PropTypes.number,
    [tableMD.columns.sum2.name]: PropTypes.number,
    [tableMD.columns.sum3.name]: PropTypes.number,
    [tableMD.columns.sum4.name]: PropTypes.number,
    [tableMD.columns.TPKVK.name]: referencePropType,
    [tableMD.columns.category.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  onSR: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
