const frontendURL = '/cat/lawrealisebasis/';
const backendURL = '/api/references/reflawrealisebasis/';
const name = 'Законодавчі підстави для виконання бюджетної програми';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
