import React from 'react';
import PropTypes from 'prop-types';
import ListerSelector from './listerselector';
import ListerCP from './listerCommandPanel';
import TableComponent from './tableComponent';
import meta from '../../constants/meta';

function Lister({
  modelType, modelName, extraContent, showCommandPanel = true, CommandPanel, ...respProps
}) {
  return (
    <ListerSelector
      {...respProps}
      modelType={modelType}
      modelName={modelName}
      CommandPanel={CommandPanel || (showCommandPanel && (<ListerCP />))}
      extraContent={extraContent}
      TableComponent={<TableComponent modelType={modelType} modelName={modelName} />}
      showCommandPanel={showCommandPanel}
    />
  );
}

Lister.propTypes = {
  extraContent: PropTypes.node,
  modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
  showCommandPanel: PropTypes.bool,
  modelName: PropTypes.oneOf([
    ...new Set(Object.keys(meta).reduce(
      (r, typeName) => [...r, ...Object.keys(meta[typeName])],
      [],
    )),
  ]).isRequired,
};

Lister.defaultProps = {
  showCommandPanel: true,
  extraContent: null,
};

export default Lister;
