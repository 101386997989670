import React, { useEffect, useState } from 'react';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLeftRight,
  faListCheck,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faScaleBalanced, faTreeCity,
} from '@fortawesome/free-solid-svg-icons';
import GreetingOBCard from './greetingsOpenBudget/GreetingObCard';
import Revenue from './revenue';
import Spend from './spend';
import RegionalProgram from './regionalProgram';
import Transfert from './transfert';
import Addition6 from './addition6';
import useOpenBudgetSettings from './Hooks/useOpenBudgetSettings';
import { Loader } from '../../../components/bootStrap';
import General from './general/index2';
import IconAlert from '../../../components/blanks/common/IconAlert';

const TABS = [
  {
    key: 'General', icon: faScaleBalanced, title: 'Баланс', component: General, vK: 'display_main',
  },
  {
    key: 'Revenue', icon: faMagnifyingGlassPlus, title: 'Доходи бюджету', component: Revenue, vK: 'display_revenue',
  },
  {
    key: 'Spend', icon: faMagnifyingGlassMinus, title: 'Видатки бюджету', component: Spend, vK: 'display_expenses',
  },
  {
    key: 'RegProgram', icon: faListCheck, title: 'Міські цільові програми', component: RegionalProgram, vK: 'display_programs',
  },
  {
    key: 'Transfert', icon: faLeftRight, title: 'Міжбюджетні трансферти', component: Transfert, vK: 'display_transfers',
  },
  {
    key: 'Addition6', icon: faTreeCity, title: 'Капітальні вкладення', component: Addition6, vK: 'display_investments',
  },
];
function OpenBudget() {
  const [key, setKey] = useState('');
  const [cDt, setDt] = useState(
    new Date().toISOString().slice(0, 10),
  );
  const {
    isLoading, err, settings,
  } = useOpenBudgetSettings();
  useEffect(() => {
    setKey(settings.default_page);
  }, [settings.default_page]);

  return (
    <>
      {isLoading && (
      <Loader text="Завантаження" />
      )}
      {err && (
        <IconAlert variant="danger">{err}</IconAlert>
      )}
      <Row className="g-3 mb-0">
        <Col xxl={12} xl={12}>
          <Row className="g-3" />
          <GreetingOBCard
            title={settings.title}
            desc={settings.description}
            cDt={cDt}
            setDt={setDt}
          />
        </Col>
        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="flex-nowrap"
        >
          {TABS.filter((t) => settings[t.vK]).map((t) => {
            const Board = t.component;
            return (
              <Tab
                eventKey={t.key}
                key={t.key}
                className="p-3"
                title={(
                  <>
                    <FontAwesomeIcon icon={t.icon} className="d-inline-block d-sm-none" alt={t.title} size="1x" />
                    <FontAwesomeIcon icon={t.icon} className="d-none d-sm-inline-block d-md-none" alt={t.title} size="2x" />
                    <span className="d-none d-md-inline-block">{t.title}</span>
                  </>
                  )}
              >
                <Board cDt={cDt} settings={settings} />
              </Tab>
            );
          })}
        </Tabs>
      </Row>
    </>
  );
}

export default OpenBudget;
