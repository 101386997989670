import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/socialPayments';

const meta = md.tables.general;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeKBP'}
              highlighted={highlights.includes('codeKBP')}
              onToggleHighlght={() => onToggleHighlght('codeKBP')}
            >
              {meta.columns.codeKBP.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbpClassifier'}
              highlighted={highlights.includes('kbpClassifier')}
              onToggleHighlght={() => onToggleHighlght('kbpClassifier')}
            >
              {meta.columns.kbpClassifier.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'socialPayment'}
              highlighted={highlights.includes('socialPayment')}
              onToggleHighlght={() => onToggleHighlght('socialPayment')}
            >
              {meta.columns.socialPayment.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deciphering'}
              highlighted={highlights.includes('deciphering')}
              onToggleHighlght={() => onToggleHighlght('deciphering')}
            >
              {meta.columns.deciphering.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deptStartMonthTotal1'}
              highlighted={highlights.includes('deptStartMonthTotal1')}
              onToggleHighlght={() => onToggleHighlght('deptStartMonthTotal1')}
            >
              {meta.columns.deptStartMonthTotal1.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deptStartMonthTotal'}
              highlighted={highlights.includes('deptStartMonthTotal')}
              onToggleHighlght={() => onToggleHighlght('deptStartMonthTotal')}
            >
              {meta.columns.deptStartMonthTotal.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'deptStartMonth'}
              highlighted={highlights.includes('deptStartMonth')}
              onToggleHighlght={() => onToggleHighlght('deptStartMonth')}
            >
              {meta.columns.deptStartMonth.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'planMonth'}
              highlighted={highlights.includes('planMonth')}
              onToggleHighlght={() => onToggleHighlght('planMonth')}
            >
              {meta.columns.planMonth.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'accrualsMonth'}
              highlighted={highlights.includes('accrualsMonth')}
              onToggleHighlght={() => onToggleHighlght('accrualsMonth')}
            >
              {meta.columns.accrualsMonth.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthTotalTotal'}
              highlighted={highlights.includes('factMonthTotalTotal')}
              onToggleHighlght={() => onToggleHighlght('factMonthTotalTotal')}
            >
              {meta.columns.factMonthTotalTotal.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthTotal'}
              highlighted={highlights.includes('factMonthTotal')}
              onToggleHighlght={() => onToggleHighlght('factMonthTotal')}
            >
              {meta.columns.factMonthTotal.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonth'}
              highlighted={highlights.includes('factMonth')}
              onToggleHighlght={() => onToggleHighlght('factMonth')}
            >
              {meta.columns.factMonth.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthDept'}
              highlighted={highlights.includes('factMonthDept')}
              onToggleHighlght={() => onToggleHighlght('factMonthDept')}
            >
              {meta.columns.factMonthDept.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthSFTotal'}
              highlighted={highlights.includes('factMonthSFTotal')}
              onToggleHighlght={() => onToggleHighlght('factMonthSFTotal')}
            >
              {meta.columns.factMonthSFTotal.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthSF'}
              highlighted={highlights.includes('factMonthSF')}
              onToggleHighlght={() => onToggleHighlght('factMonthSF')}
            >
              {meta.columns.primSocialPayment.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factMonthDeptSF'}
              highlighted={highlights.includes('factMonthDeptSF')}
              onToggleHighlght={() => onToggleHighlght('factMonthDeptSF')}
            >
              {meta.columns.factMonthDeptSF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factDeptOnDateTotal'}
              highlighted={highlights.includes('factDeptOnDateTotal')}
              onToggleHighlght={() => onToggleHighlght('factDeptOnDateTotal')}
            >
              {meta.columns.factDeptOnDateTotal.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'factDeptOnDate'}
              highlighted={highlights.includes('factDeptOnDate')}
              onToggleHighlght={() => onToggleHighlght('factDeptOnDate')}
            >
              {meta.columns.factDeptOnDate.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'overdue'}
              highlighted={highlights.includes('overdue')}
              onToggleHighlght={() => onToggleHighlght('overdue')}
            >
              {meta.columns.overdue.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'primSocialPayment'}
              highlighted={highlights.includes('primSocialPayment')}
              onToggleHighlght={() => onToggleHighlght('primSocialPayment')}
            >
              {meta.columns.primSocialPayment.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
