import React, { memo, useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import {
  ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faEdit, faFile,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheck, faTimes, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { StyledCtxContainer } from './styles';
import { useCtxMenu } from './hook';
import ListerContext from '../../context';

const DocCtxMenu = ({ children }) => {
  const {
    ctxMenu, onHideCtxMenu, actions, permissions,
  } = useContext(ListerContext);

  const { ref } = useCtxMenu(ctxMenu, onHideCtxMenu);

  const onActionClickHanlder = useCallback(
    (act, e) => {
      switch (act) {
        case 'edit':
          actions.onEdit(e);
          break;
        case 'copy':
          actions.onCopy(e);
          break;
        case 'delete':
          actions.onDelete(e);
          break;
        case 'execute':
          actions.onExecute(e);
          break;
        case 'unexecute':
          actions.onUnexecute(e);
          break;
        default:
          throw new Error(`Unknow action ${act}`);
      }
      onHideCtxMenu(e);
    },
    [actions, onHideCtxMenu],
  );

  return createPortal(
    (
      <StyledCtxContainer ref={ref} visible={ctxMenu.visible}>
        <ListGroup onSelect={onActionClickHanlder} activeKey="">
          <ListGroup.Item action eventKey="edit" disabled={!permissions.canEdit}>
            <FontAwesomeIcon icon={faEdit} className="me-2" size="lg" />
            Змінити
          </ListGroup.Item>
          { permissions.canCopy && (
          <ListGroup.Item action eventKey="copy">
            <FontAwesomeIcon icon={faCopy} className="me-2" size="lg" />
            Копіювати
          </ListGroup.Item>
          )}
          <ListGroup.Item action eventKey="delete" disabled={!permissions.canDelete}>
            <FontAwesomeIcon icon={faTrash} className="me-2" size="lg" color="var(--danger)" />
            Видалити
          </ListGroup.Item>
          <ListGroup.Item action eventKey="execute" disabled={!permissions.canExecute}>
            <span className="fa-layers fa-fw me-2">
              <FontAwesomeIcon icon={faFile} size="lg" />
              <FontAwesomeIcon icon={faCheck} color="var(--falcon-success)" transform="shrink-6 down-2" />
            </span>
            Провести
          </ListGroup.Item>
          <ListGroup.Item action eventKey="unexecute" disabled={!permissions.canUnexecute}>
            <span className="fa-layers fa-fw me-2">
              <FontAwesomeIcon icon={faFile} size="lg" />
              <FontAwesomeIcon icon={faTimes} color="var(--danger)" transform="shrink-6 down-2 left-1" />
            </span>
            Відмінити проведення
          </ListGroup.Item>
          {children}

        </ListGroup>
      </StyledCtxContainer>
    ), document.body,
  );
};

export default memo(DocCtxMenu);
