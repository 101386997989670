import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  EDRPOU: { label: 'ЄДРПОУ (для юридичних осіб)', name: 'ЕДРПОУ', visible: true },
  Partner: {
    label: 'Довiдник постачальникiв',
    name: 'Контрагент',
    visible: true,
  },
  INN: { label: 'ІПН (для фізичних осіб)', name: 'ИНН', visible: true },
  Head: { label: 'Керівник', name: 'Руководитель', visible: false },
  Telephone: {
    label: 'Номер телефону керiвника',
    name: 'Телефон',
    visible: false,
  },
  Address: {
    label: 'Юридична адреса організації',
    name: 'Адрес',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  owner: { label: 'Розпорядник', name: 'Владелец', visible: true },
  bank: { label: 'Банк', name: 'Банк', visible: true },
  codeMer: { label: 'Код за мережею (у кого він є)', name: 'КодМережа', visible: true },
};

export const csuPartners = {
  label: 'Постачальники розпорядників',
  name: 'csuPartners',
  backendName: 'КонтрагентыРаспорядителей',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/csuPartners',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'EDRPOU',
      'INN',
      'Partner',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useListLazyLoad: true,
};

export default csuPartners;
