import {
  reqListerColumns,
  defaultColumns,
  defaultOrder,
} from '../common';

const columns = {
  ...defaultColumns,
};

export const artistsRoles = {
  label: 'Посади',
  name: 'artistsRoles',
  backendName: 'РолиИсполнителей',
  defaultOrder,
  columns,
  frontend: 'cat/artistsRoles',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default artistsRoles;
