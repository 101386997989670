/* eslint-disable react/no-array-index-key */
import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

const SUMS = [
  'cash_expenses_of_operation',
  'cash_expenses_for_wages',
  'cash_expenses_of_local_budgets',
  'planned_expenses_of_operation',
  'planned_expenses_for_wages',
  'planned_expenses_of_local_budgets',
  'average_salary_of_director',
  'average_salary_of_teacher',
  'average_salary_of_young_teacher',
];
function EducationExpenditureRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authority,
}) {
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Props = tableHooks.useItemInputProps('kbp7', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(() => ({
    parent: authority ? authority.id : 0,
  }), [authority]);
  const actualRates = tableHooks.useNumberInputProps('actual_rates', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesOfOperation = tableHooks.useNumberInputProps('cash_expenses_of_operation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesForWages = tableHooks.useNumberInputProps('cash_expenses_for_wages', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesOfLocalBudgets = tableHooks.useNumberInputProps('cash_expenses_of_local_budgets', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const plannedExpensesOfOperation = tableHooks.useNumberInputProps('planned_expenses_of_operation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const plannedExpensesForWages = tableHooks.useNumberInputProps('planned_expenses_for_wages', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const plannedExpensesOfLocalBudgets = tableHooks.useNumberInputProps('planned_expenses_of_local_budgets', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const averageSalaryOfDirector = tableHooks.useNumberInputProps('average_salary_of_director', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const averageSalaryOfTeacher = tableHooks.useNumberInputProps('average_salary_of_teacher', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const averageSalaryOfYoungTeacher = tableHooks.useNumberInputProps('average_salary_of_young_teacher', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProps = useMemo(
    () => [
      cashExpensesOfOperation,
      cashExpensesForWages, cashExpensesOfLocalBudgets,
      plannedExpensesOfOperation, plannedExpensesForWages, plannedExpensesOfLocalBudgets,
      averageSalaryOfDirector, averageSalaryOfTeacher, averageSalaryOfYoungTeacher,
    ],
    [cashExpensesForWages, cashExpensesOfOperation, cashExpensesOfLocalBudgets,
      averageSalaryOfDirector, plannedExpensesOfLocalBudgets, plannedExpensesForWages,
      averageSalaryOfTeacher, averageSalaryOfYoungTeacher, plannedExpensesOfOperation],
  );

  return (
    <Container fluid>
      <Row>
        <Col sm={3}>
          <Row noGutters>
            <Col sm={12}>
              <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
                <TableControls.ItemPicker
                  {...disposerProps}
                  filter={disposerFilter}
                  noHierarchy
                />
              </TabelRowCell>
            </Col>
            <Col sm={12}>
              <TabelRowCell column="kbp7" highlighted={highlights.includes('kbp7')}>
                <TableControls.ItemPicker
                  {...kbp7Props}
                />
              </TabelRowCell>
            </Col>
            <Col sm={12}>
              <TabelRowCell column="actual_rates" highlighted={highlights.includes('actual_rates')}>
                <TableControls.NumberInput {...actualRates} />
              </TabelRowCell>
            </Col>
          </Row>
        </Col>
        {[...Array(3)].map((c, colN) => (
          <Col sm={3} key={colN}>
            <Row noGutters>
              {[...Array(3)].map((r, rowN) => (
                <Col sm={12} key={rowN}>
                  <TabelRowCell
                    column={SUMS[colN * 3 + rowN]}
                    highlighted={highlights.includes(SUMS[colN * 3 + rowN])}
                  >
                    <TableControls.NumberInput
                      {...sumProps[colN * 3 + rowN]}
                    />
                  </TabelRowCell>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

EducationExpenditureRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

EducationExpenditureRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(EducationExpenditureRow);
