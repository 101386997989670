import React from 'react';
import PropTypes from 'prop-types';
import { StyledApprovedStamp } from './styled';

function StampApproved({ text, onClick }) {
  return (
    <StyledApprovedStamp
      version="1.1"
      id="Слой_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-939 962 121 78"
      xmlSpace="preserve"
      onClick={onClick}
      role="button"
    >
      <style type="text/css" />
      <g>
        <path
          className="st0"
          d="M-918.3,1012.6L-918.3,1012.6c0.1,0.1,0.1,0.2,0.2,0.2h0.1l0,0c-0.2-0.6-0.5-1-0.8-1.4v0.1l0,0l0,0l0,0
c-0.3-0.2-0.7-0.4-1.1-0.6c0.1,0,0.2,0,0.2-0.1c-0.4-0.1-0.8-0.2-1.2-0.2c0.4-0.3,0.7-0.7,0.9-1.3c0-0.2,0.1-0.4,0.1-0.5l0,0l0,0
c0-0.4,0-0.7-0.2-1.1c-0.4-1-1-1.6-1.9-1.9c-0.7-0.2-1.5-0.1-2.4,0.2c-1,0.4-1.7,1-2,1.8c-0.3,0.8-0.3,1.7,0,2.7l2-0.7
c-0.2-0.5-0.2-0.9-0.1-1.3c0.1-0.4,0.4-0.7,0.7-0.8c0.4-0.1,0.8-0.1,1.1,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.2-0.1,0.3-0.1,0.5-0.2
c0,0.3-0.1,0.4-0.1,0.6c-0.1,0-0.2,0-0.3,0c0,0,0,0.1,0.1,0.1c0.3,0.9,0.1,1.5-0.8,1.9l-0.4,0.2l0.6,1.5l0.4-0.2
c1-0.4,1.7,0,2.1,1.2c0.2,0.6,0.3,1.1,0.1,1.5c0,0.1,0,0.2,0,0.3c0,0,0-0.1-0.1-0.1c-0.1,0.2-0.4,0.4-0.7,0.6c-0.9,0.3-1.5,0-1.9-1
l-2,0.7c0.4,1.1,1,1.8,2,2.1c0.8,0.2,1.7,0.2,2.6-0.2c2.1-0.8,2.9-2.2,2.2-4.2c-0.3,0-0.5,0-0.8,0.1
C-918.6,1012.9-919.2,1012.6-918.3,1012.6z M-921.1,1009.9c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.5-0.5,0.3-0.7
C-921.1,1009.4-921.1,1009.7-921.1,1009.9z"
        />
        <path className="st0" d="M-920.1,1014.9c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0.1,0.1C-920,1015.1-920,1015-920.1,1014.9z" />
        <path
          className="st0"
          d="M-919.9,1008.9C-919.9,1008.9-919.9,1008.8-919.9,1008.9C-919.9,1008.8-919.9,1008.8-919.9,1008.9
L-919.9,1008.9z"
        />
        <path
          className="st0"
          d="M-918.1,1012.8c-0.1,0-0.1-0.1-0.2-0.2l0,0c-0.9,0-0.3,0.3-0.5,0.5c0.3,0,0.6,0,0.8-0.1
C-918,1013-918,1012.9-918.1,1012.8C-918,1012.8-918.1,1012.8-918.1,1012.8z"
        />
        <path className="st0" d="M-918.8,1011.5L-918.8,1011.5L-918.8,1011.5z" />
        <path
          className="st0"
          d="M-918.8,1011.5L-918.8,1011.5c-0.3-0.4-0.6-0.6-0.9-0.7c-0.1,0-0.2,0-0.2,0.1
C-919.4,1011.2-919.1,1011.3-918.8,1011.5z"
        />
        <path className="st0" d="M-921.4,1009.9c0.1,0,0.2,0,0.3,0c0-0.2,0-0.5,0-0.7C-921.9,1009.3-921.5,1009.6-921.4,1009.9z" />
        <path
          className="st0"
          d="M-921.7,1008.1c-0.2,0.1-0.4,0.1-0.5,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0,0.3,0
C-921.8,1008.5-921.8,1008.4-921.7,1008.1z"
        />
        <path className="st0" d="M-916.3,1013.8v0.3c0-0.1,0.1-0.2,0.1-0.3C-916.3,1013.8-916.3,1013.8-916.3,1013.8z" />
        <path
          className="st0"
          d="M-917.4,1003.7l0.9,8.7c0.2-0.1,0.4-0.1,0.6-0.2c0,0.3,0.1,0.6,0.1,0.9c-0.1,0-0.1,0-0.2,0
c-0.2-0.1-0.4-0.3-0.5-0.4l0.1,0.8c0.4,0,0.9-0.1,1.3-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.5,0.2-0.8,0.3-1.3,0.5l0.1,1.4l2.2-0.8
l-0.4-3.1l2.3-0.8l1.5,2.6l2.2-0.8l-6.4-10L-917.4,1003.7z M-909.5,1012.2c0.1,0,0.1,0.1,0.2,0.1c-0.2,0.2-0.5,0.4-0.8,0.6
C-910.7,1012.3-909.8,1012.4-909.5,1012.2z M-915.4,1004c-0.1-0.3-0.1-0.5-0.2-0.7C-914.6,1003.6-914.6,1003.7-915.4,1004z
M-914.7,1010l-0.8-5.4l2.7,4.7L-914.7,1010z"
        />
        <path
          className="st0"
          d="M-915.1,1013.4c-0.5,0.1-0.9,0.1-1.3,0.1v0.3h0.1c0,0.1,0,0.1-0.1,0.3l0,0c0.5-0.2,0.8-0.3,1.3-0.5
C-915,1013.7-915,1013.5-915.1,1013.4z"
        />
        <path
          className="st0"
          d="M-915.7,1013.1c0-0.3-0.1-0.6-0.1-0.9c-0.2,0.1-0.4,0.1-0.6,0.2v0.3c0.2,0.1,0.4,0.3,0.5,0.4
C-915.9,1013.1-915.8,1013.1-915.7,1013.1z"
        />
        <path className="st0" d="M-915.6,1003.3c0.1,0.2,0.1,0.4,0.2,0.7C-914.6,1003.7-914.6,1003.6-915.6,1003.3z" />
        <path className="st0" d="M-909.3,1012.3c-0.1,0-0.1-0.1-0.2-0.1c-0.3,0.2-1.2,0.1-0.6,0.7C-909.8,1012.7-909.6,1012.5-909.3,1012.3z" />
        <path
          className="st0"
          d="M-904,1000.7l-0.6-1.7l-7.2,2.6l0.6,1.7l2.6-0.9l3.3,9.2l2.1-0.8l-3.3-9.2L-904,1000.7z M-906.4,1004.7
c0.4,0,0.4,0.3,0.7,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.2,0-0.4,0-0.8-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0-0.1,0s-0.3,0.1-0.4,0.1
l-0.2-0.4C-906.9,1004.7-906.6,1004.7-906.4,1004.7z"
        />
        <path
          className="st0"
          d="M-896.8,1001.9c0.1-0.1,0.2-0.1,0.3-0.2c0-0.1,0-0.3,0.2-0.4c0.1-0.1,0-0.3-0.1-0.4l0,0c0.1,0,0.2-0.1,0.3-0.1
v-0.1c0.1-0.5,0-1.1-0.2-1.6c-0.3-0.8-0.7-1.3-1.3-1.6c-0.7-0.4-1.4-0.4-2.3-0.1l-3.8,1.4l4,10.9l4.2-1.5c0.8-0.3,1.3-0.8,1.5-1.6
c0.2-0.7,0.1-1.6-0.2-2.5C-894.7,1002.6-895.5,1001.9-896.8,1001.9z M-900,1002.3l-1-2.9l1.1-0.4c0.8-0.3,1.4,0,1.8,0.9
c0.4,1,0.1,1.6-0.8,1.9L-900,1002.3z M-896.9,1006.8l-1.3,0.5l-1.2-3.4l1.3-0.5c0.8-0.3,1.3,0.1,1.8,1.3
C-895.9,1005.8-896.1,1006.5-896.9,1006.8z"
        />
        <path
          className="st0"
          d="M-896.4,1000.8c0,0.1,0.2,0.3,0.1,0.4c-0.2,0.1-0.3,0.3-0.2,0.4c0.2-0.2,0.4-0.5,0.5-0.9
C-896.1,1000.8-896.3,1000.8-896.4,1000.8L-896.4,1000.8z"
        />
        <polygon
          className="st0"
          points="-890.3,1004.3 -891.5,1000.9 -888.1,999.6 -888.7,998 -892.1,999.2 -893,996.7 -889.3,995.4
-889.9,993.7 -895.7,995.8 -891.8,1006.7 -885.9,1004.5 -886.5,1002.9"
        />
        <path
          className="st0"
          d="M-882.3,992c-0.8-0.4-1.6-0.5-2.4-0.2l-3.9,1.4l4,10.9l2.1-0.8l-1.6-4.4l1.8-0.7c0.9-0.3,1.4-0.9,1.7-1.7
c0.3-0.8,0.3-1.7-0.1-2.6C-881,993-881.6,992.4-882.3,992z M-883.2,993.2c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.2,0.1-0.3,0.1
c-0.2,0-0.4,0-0.5,0c-0.3-0.2-0.4-0.3-0.7-0.5C-884,993.1-883.6,993.1-883.2,993.2z M-881.3,995.3c0,0.2,0,0.4-0.1,0.5l0,0l0,0l0,0
c-0.4,0.3-0.8,0.4-1.4,0.2c-0.2,0.4-0.5,0.6-1,0.8l-1,0.4l-0.5-1.4c-0.1,0.1-0.1,0.1-0.2,0.2l0,0l0,0l0,0c-0.2-0.2-0.1-0.4,0-0.5
l-0.5-1.4l1-0.4c1-0.4,1.7,0,2.1,1c0.1,0.2,0.1,0.3,0.1,0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.4,0.3,0.5,0.4
c0.4-0.1,0.8-0.2,1.2-0.3l0.1,0.1C-880.9,995.3-881.1,995.3-881.3,995.3z"
        />
        <path className="st0" d="M-885.5,996c0.1-0.1,0.1-0.1,0.2-0.2l-0.1-0.4C-885.6,995.6-885.7,995.8-885.5,996z" />
        <path className="st0" d="M-885.5,996L-885.5,996L-885.5,996L-885.5,996z" />
        <path
          className="st0"
          d="M-881.9,995.3c-0.1-0.1-0.3-0.2-0.5-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.3,0,0.6-0.1,0.9c0.6,0.2,1,0,1.4-0.2
c0-0.2,0-0.4,0.1-0.5c0.2,0,0.4-0.1,0.6-0.1l-0.1-0.1C-881.2,995.2-881.5,995.2-881.9,995.3z"
        />
        <path className="st0" d="M-881.3,995.9L-881.3,995.9L-881.3,995.9L-881.3,995.9z" />
        <path
          className="st0"
          d="M-883.3,993.5c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0-0.2-0.1-0.2c-0.4-0.1-0.8-0.1-1.3-0.2c0.3,0.2,0.4,0.3,0.7,0.5
C-883.8,993.6-883.5,993.6-883.3,993.5z"
        />
        <path
          className="st0"
          d="M-869.6,996.7l-3.4-9.2l-6.2,2.2l1.2,3.4c0.9,2.4,1.3,4.5,1.1,6.2l-0.7,0.2l1.4,3.8l2-0.7l-0.8-2.2l4.5-1.6
l0.8,2.2l2-0.7l-1.4-3.8L-869.6,996.7z M-874.7,998.6c0-0.5,0-1.1,0-1.7c-0.4,0.1-0.8,0.1-1.3,0.2c0,0-0.1-0.2,0-0.2
c0.4-0.1,0.8-0.3,1.3-0.2c0-0.3-0.1-0.6-0.1-0.8c-0.7-0.1,0,0.6-0.8,0.6c0-0.1,0.1-0.2,0.1-0.3c-0.4,0.1-0.7,0.2-1.2,0.3
c0.1-0.3,0.1-0.6,0.1-0.8c0.4-0.1,0.7-0.2,1-0.2c0,0,0,0.1,0,0.2c0.2,0,0.4,0,0.6,0c-0.2-0.8-0.4-1.5-0.7-2.4l-0.9-2.5l2.1-0.8
l2.7,7.5L-874.7,998.6z"
        />
        <path
          className="st0"
          d="M-875.5,995.5c0-0.1,0-0.2,0-0.2c-0.3,0.1-0.6,0.1-1,0.2c0,0.2-0.1,0.5-0.1,0.8c0.5-0.1,0.8-0.2,1.2-0.3
c-0.1,0.2-0.1,0.2-0.1,0.3c0.7,0,0.1-0.7,0.8-0.6c0-0.1,0-0.2-0.1-0.2C-875.1,995.5-875.3,995.6-875.5,995.5z"
        />
        <path
          className="st0"
          d="M-876,996.8c-0.1,0,0,0.2,0,0.2c0.5-0.1,0.9-0.1,1.3-0.2c0-0.1,0-0.2,0-0.3
C-875.2,996.6-875.6,996.7-876,996.8z"
        />
        <polygon
          className="st0"
          points="-859.9,982.8 -862.2,983.6 -863,989.2 -864.7,984.5 -866.7,985.3 -865,990 -869.2,986.2 -871.6,987
-867.1,990.7 -867.7,997.9 -865.4,997.1 -865.2,992.1 -864,992.9 -862.8,996.1 -860.7,995.4 -861.9,992.1 -861.5,990.7
-858.1,994.4 -855.8,993.6 -860.9,988.5"
        />
        <polygon
          className="st0"
          points="-849.6,989.5 -853.4,990.8 -854.6,987.5 -851.2,986.2 -851.8,984.6 -855.2,985.8 -856.1,983.3
-852.4,981.9 -853,980.3 -858.8,982.4 -854.8,993.2 -849,991.1"
        />
        <path
          className="st0"
          d="M-844.9,979.2c0-0.1,0-0.2,0-0.4c0.2,0,0.4,0,0.6,0l-0.6-1.6l-2.1,0.7l0.3,0.7c0.3,0,0.6,0,0.9-0.1
c0.4,0,0.4,0.3,0.7,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.2,0-0.4,0-0.8-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0-0.1,0s-0.3,0.1-0.4,0.1
l1.1,3.1l-2.7,1l-1.5-4.2l-2.1,0.8l4,10.9l2.1-0.8l-1.8-5l2.7-1l1.8,5l2.1-0.7l-3.3-9.1C-844.4,979.1-844.6,979.2-844.9,979.2z"
        />
        <path
          className="st0"
          d="M-835.1,977.7c-0.9-2.5-2.5-3.3-4.8-2.4c-2.4,0.9-3.1,2.5-2.2,5l1.4,3.9c0.9,2.4,2.5,3.2,4.9,2.4
c2.3-0.8,3.1-2.5,2.2-4.9L-835.1,977.7z M-836.4,985c-0.9,0.3-1.6-0.1-2.1-1.2l-1.6-4.4c-0.4-1.2-0.2-2,0.8-2.3
c0.9-0.3,1.6,0.1,2.1,1.3l1.6,4.4C-835.3,983.9-835.5,984.6-836.4,985z"
        />
        <path className="st0" d="M-896.5,1027.3l-0.4,0.1c0.2,0,0.4,0.1,0.6,0.1C-896.4,1027.4-896.4,1027.3-896.5,1027.3z" />
        <path
          className="st0"
          d="M-818.3,994.8l-10.1-27.7c-1.3-3.5-5.2-5.3-8.7-4l-7.2,2.6c0.4,0.2,0.9,0.3,1.3,0.5c-0.3,0.2-0.7,0.3-1.2,0.6
c1.1,0.3,0.9,0.9,0.6,1.4c-0.1-0.2-0.2-0.4-0.2-0.6l-0.1,0.1c-0.3-0.2-0.6-0.3-1-0.5c0,0.1-0.1,0.2-0.2,0.4
c-0.4-0.5-0.5-0.9-0.4-1.4l-26.2,9.5c0.1,0.1,0.3,0.1,0.3,0.2c0.3,0.2,0.4,0.5,0.6,0.8l0,0c-0.1,0.1-0.3,0.3-0.5,0.5
c0-0.3,0-0.5,0-0.9c-0.3,0.4-0.7,0.4-1.4,0c0.2-0.1,0.4-0.1,0.7-0.3c-0.3,0-0.4,0-0.7,0l-1.5,0.5l0,0c0,0,0.1,0,0.2,0.1
c0.6,0.2,1.3,0.4,1.9,0.5l-0.1,0.1c-0.6,0.1-1.2,0.2-1.8,0.3c0,0.2,0,0.4,0,0.6c-0.5,0-0.8-0.1-1.3-0.1c-0.1-0.1-0.8-0.3-0.2-0.6
c0.3,0.1,0.5,0.1,0.8,0.2c-0.1-0.3-0.1-0.3-1.3-0.3c0,0,0,0,0-0.1l-58.6,21.3c-3.5,1.3-5.3,5.2-4,8.7l10.1,27.7
c1.3,3.5,5.2,5.3,8.7,4l74.2-27c-0.5-0.3-0.9-0.7-1.4-1c0.1,0,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.2,0.3-0.3,0.5-0.5
c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0,0.5,0.1,0.5,0.1c0.5-0.4,1-0.1,1.4,0c0.1,0,0.2,0,0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3
c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.4,0.4l19.9-7.3C-818.9,1002.2-817.1,998.3-818.3,994.8z M-842.4,965.7c0.4,0,0.7,0,1.1,0
c-0.2,0.2-0.4,0.5-0.6,0.7C-842.7,966.2-842.9,966-842.4,965.7z M-877.1,1022.4c-0.4,0-0.8-0.1-1.2-0.2c-0.3,0.1-0.5,0.2-0.8,0.3
c-0.1-0.1-0.4-0.1-0.4-0.2c-0.1-0.1,0-0.2,0.1-0.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.7,0.3l0,0l0,0c-0.1-0.1-0.2-0.2-0.4-0.2
c0.2-0.1,0.3-0.3,0.5-0.4c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.1,0.6,0,1.1,0C-876.5,1022.2-876.7,1022.4-877.1,1022.4z M-876.5,1023.2
c0.1-0.5,0.1-1,0.2-1.5C-875.5,1022.2-876.1,1022.7-876.5,1023.2z M-823.4,1000.6l-71.8,26.1c0.5,0.1,0.9,0.3,1.4,0.4l-0.1,0.1
c-0.6,0.1-1.2,0.2-1.8,0.3c0,0.2,0,0.4,0,0.6c-0.5,0-0.8-0.1-1.3-0.1c-0.1-0.1-0.5-0.2-0.4-0.4l-23.2,8.5c-1.9,0.7-4.1-0.3-4.8-2.2
l-10.1-27.7c-0.7-1.9,0.3-4.1,2.2-4.8l32.5-11.8v-0.1c0.1,0,0.1,0,0.1,0.1l32-11.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.5,0,0.9-0.1,1.3-0.1
l11.5-4.2c-0.1-0.2-0.2-0.3-0.2-0.5c0.4,0,0.7,0,1.1,0c-0.1,0.1-0.2,0.2-0.3,0.3l19.3-7c1.9-0.7,4.1,0.3,4.8,2.2l6.4,17.5
c0.3,0,0.6,0.1,0.9,0.2c0.6,0,1.2-0.2,1.5,0.3c-0.3,0.2-0.5,0.4-0.6,0.5c-0.5,0.1-0.9,0.1-1.4,0.2l3.3,9.2
C-820.5,997.8-821.5,999.9-823.4,1000.6z"
        />
        <path className="st0" d="M-876.3,1021.7c-0.1,0.5-0.1,1-0.2,1.5C-876.1,1022.7-875.5,1022.2-876.3,1021.7z" />
        <path
          className="st0"
          d="M-877.8,1022.1c-0.2-0.1-0.3-0.4-0.4-0.6c-0.2,0.1-0.3,0.3-0.5,0.4c0.1,0.1,0.3,0.2,0.4,0.2l0,0
c0.4,0.1,0.8,0.2,1.2,0.2c0.4,0,0.7-0.1,0.5-0.3C-877.1,1022.1-877.6,1022.2-877.8,1022.1z"
        />
        <path
          className="st0"
          d="M-879,1021.9c-0.2-0.1-0.4-0.1-0.6-0.1c0,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.3,0.2,0.4,0.2
c0.3-0.1,0.6-0.2,0.8-0.3l0,0C-878.5,1022.1-878.7,1022-879,1021.9z"
        />
        <path
          className="st0"
          d="M-842.9,1010.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.3,0-0.4,0c-0.5-0.1-0.9-0.5-1.4,0c0,0-0.3,0-0.5-0.1
s-0.3-0.1-0.5-0.2c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.1,0.1-0.2,0.1c0.5,0.3,0.9,0.7,1.4,1l3-1.1
c-0.1-0.1-0.3-0.3-0.4-0.4C-842.7,1010.4-842.8,1010.4-842.9,1010.4z"
        />
        <path
          className="st0"
          d="M-823.1,986.5c0.1-0.1,0.3-0.3,0.6-0.5c-0.3-0.5-1-0.3-1.5-0.3c-0.3-0.1-0.6-0.2-0.9-0.2l0.4,1.1
C-824,986.6-823.6,986.6-823.1,986.5z"
        />
        <path className="st0" d="M-900.7,989.3L-900.7,989.3l0.1,0.1L-900.7,989.3z" />
      </g>
      <text
        x="-1220"
        y="656"
        textLength="87%"
        lengthAdjust="spacingAndGlyphs"
        style={{ transform: 'rotate(340deg)', fontSize: 'xx-small' }}
      >
        {text}
      </text>
    </StyledApprovedStamp>
  );
}

StampApproved.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

StampApproved.defaultProps = {
  text: '',
};

export default StampApproved;
