import Styled from 'styled-components';

const getCursor = ({ resizing, vertical }) => {
  if (!resizing) return '';
  return vertical ? 'cursor: row-resize;' : 'cursor: col-resize;';
};

export const StyledContainer = Styled.div`
  // position: absolute;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  ${({ resizing, vertical }) => getCursor({ resizing, vertical })}
  width: 100%;
  height: inherit;
  overflow: hidden;
`;

export const StyledSeparator = Styled.div.attrs((props) => ({
  role: 'separator',
  style: props.resizing ? { backgroundColor: '#aaa' } : {},
}))`
  flex: 0 0 auto;
  width: ${({ vertical }) => (vertical ? '100%' : '4px')};
  height: ${({ vertical }) => (vertical ? '4px' : 'height')};
  cursor: ${({ vertical }) => (vertical ? 'row-resize' : 'col-resize')};
  background-color: #ccc;
  :hover{
    background-color: #bbb;
  }
`;

const defaultPane = Styled.div`
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
`;

export const StyledPrimaryPane = Styled(defaultPane)`
  flex: 1 1 auto;
`;

export const StyledSecondaryPane = Styled(defaultPane)
  .attrs(({ vertical, panelSize, percentage }) => ({
    style: { [vertical ? 'height' : 'width']: `${panelSize}${percentage ? '%' : 'px'}` },
  }))`
`;
