import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import AppContext from '../../../../providers/CiatAppContext';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: meta.cat.disposerscontragent.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const { currentUser } = useContext(AppContext);
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const edrpouProps = editorHooks.useStringInputProps('edrpou', data, fields, fieldErrors, actions.onChange);
  const innProps = editorHooks.useStringInputProps('inn', data, fields, fieldErrors, actions.onChange);
  const contragentProps = editorHooks.useItemInputProps('contragent', data, fields, fieldErrors, actions.onChange);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange);
  const chiefProps = editorHooks.useStringInputProps('chief', data, fields, fieldErrors, actions.onChange);
  const telephoneProps = editorHooks.useStringInputProps('telephone', data, fields, fieldErrors, actions.onChange);
  const addressProps = editorHooks.useStringInputProps('address', data, fields, fieldErrors, actions.onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, fieldErrors, actions.onChange);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.disposerscontragent.backendURL}
        />
      )}
    >
      <LabelTab defaultActiveKey="general">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...edrpouProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...innProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...chiefProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...telephoneProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...contragentProps} />
                </Col>
                <Col>
                  <EditorControls.ItemPicker {...parentProps} />
                </Col>
                <Col>
                  <EditorControls.CheckboxInput {...isGroupProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.TextInput {...addressProps} />
                </Col>
                {currentUser.is_superuser && (
                  <Col>
                    <EditorControls.ItemPicker {...disposerProps} />
                  </Col>
                )}
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
