import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  elementKFK as md,
} from '../../../../constants/meta/catalogs/elementKFK';
import {
  Title,
} from '../../../../components/Form/styledForm';
import { selectionMode } from '../../../../constants/meta/common';

const autoFillName = {
  first: {
    value: 1,
    display_name: 'З назви класифікатора',
  },
  second: {
    value: 2,
    display_name: 'З назви елементу вищого рівня',
  },
};

function ElementKFK({
  data,
  actions,
  permissions,
}) {
  const readOnly = !permissions.canChange;
  const FIElements = useMemo(
    () => {
      if (!data[md.columns.FIElementsList.name]) return [];
      return data[md.columns.FIElementsList.name].map((fl) => ({
        value: fl.id,
        display_name: fl.repr,
      }));
    },
    [data],
  );
  return (
    <Container fluid>
      {data[md.columns.isGroup.name] ? (
        <>
          <Row>
            {/*<Col sm={3}>*/}
            {/*  <EditorControls.StringInput*/}
            {/*    label={md.columns.code.label}*/}
            {/*    value={data[md.columns.code.name]}*/}
            {/*    onChange={(e, value) => actions.onChange({*/}
            {/*      [md.columns.code.name]: value,*/}
            {/*    })}*/}
            {/*    maxLength={12}*/}
            {/*    readOnly={readOnly}*/}
            {/*  />*/}

            {/*</Col>*/}
            <Col>
              <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            </Row>
            <Row>
            <Col sm={2}>
              <EditorControls.CheckboxInput
                label={md.columns.DoNotPrint.label}
                value={data[md.columns.DoNotPrint.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                label={md.columns.DoNotPrint2.label}
                value={data[md.columns.DoNotPrint2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint2.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                label={md.columns.DoNotPrint3.label}
                value={data[md.columns.DoNotPrint3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint3.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                label={md.columns.DoNotPrint4.label}
                value={data[md.columns.DoNotPrint4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint4.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                label={md.columns.DoNotPrint5.label}
                value={data[md.columns.DoNotPrint5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint5.name]: value,
                })}
                readOnly={readOnly}
              />
              <EditorControls.CheckboxInput
                label={md.columns.rooted.label}
                value={data[md.columns.rooted.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.rooted.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={6}>
              <EditorControls.TextInput
                label={md.columns.FullName.label}
                value={data[md.columns.FullName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FullName.name]: value,
                })}
                readOnly={readOnly}
                rows={12}
              />
            </Col>
            <Col sm={4}>
              <EditorControls.ItemPicker
                label={md.columns.Target.label}
                value={data[md.columns.Target.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Target.name]: value,
                })}
                modelType="cat"
                modelName="target"
                readOnly={readOnly}
              />
              <EditorControls.ItemPicker
                label={md.columns.Steward.label}
                value={data[md.columns.Steward.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Steward.name]: value,
                })}
                modelType="cat"
                modelName="csu"
                readOnly={readOnly}
              />
              <EditorControls.ItemPicker
                label={md.columns.GroundLaw.label}
                value={data[md.columns.GroundLaw.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.GroundLaw.name]: value,
                })}
                modelType="cat"
                modelName="groundLaw"
                readOnly={readOnly}
              />
              <EditorControls.ItemPicker
                label={md.columns.parent.label}
                value={data[md.columns.parent.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.parent.name]: value,
                })}
                modelType="cat"
                modelName="elementKFK"
                readOnly={readOnly}
                filter={[{
                  fieldName: md.columns.owner.name,
                  value: data[md.columns.owner.name],
                }]}
                choiceSettings={selectionMode.folders}
              />
            </Col>
          </Row>
          <Title>Зв&#8242;язки з бюджетними класифікаторами, станом на вказану дату</Title>
          <Row>
            <Col sm={3}>
              <EditorControls.DateInput
                label={md.columns.DateClassifier.label}
                value={data[md.columns.DateClassifier.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.code.name]: value,
                  });
                  actions.onSR('ПриИзменении_ДатаКл');
                }}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={3}>
              <EditorControls.ItemPicker
                label={md.columns.KBP.label}
                value={data[md.columns.KBP.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.KBP.name]: value,
                  });
                  actions.onSR('КлассификаторКБППриИзменении');
                }}
                modelName="kbpClassifier"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col sm={3}>
              <EditorControls.ItemPicker
                label={md.columns.KBP7.label}
                value={data[md.columns.KBP7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KBP7.name]: value,
                })}
                modelName="kbp7Classifier"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col sm={3}>
              <EditorControls.ItemPicker
                label={md.columns.KFK4.label}
                value={data[md.columns.KFK4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KFK4.name]: value,
                })}
                modelName="kfkvkClassifier"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.owner.label}
                value={data[md.columns.owner.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.owner.name]: value,
                })}
                modelName="csu"
                modelType="cat"
                readOnly={readOnly}
              />

            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            {/*<Col sm={2}>*/}
            {/*  <EditorControls.StringInput*/}
            {/*    label={md.columns.KPKV.label}*/}
            {/*    value={data[md.columns.KPKV.name]}*/}
            {/*    onChange={(e, value) => actions.onChange({*/}
            {/*      [md.columns.KPKV.name]: value,*/}
            {/*    })}*/}
            {/*    maxLength={7}*/}
            {/*    readOnly={readOnly}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col sm={4}>
              <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={4}>
              <EditorControls.ItemPicker
                label={md.columns.ElementKFKPlan.label}
                value={data[md.columns.ElementKFKPlan.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ElementKFKPlan.name]: value,
                })}
                modelType="cat"
                modelName="elementKFK"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.Bin.label}
                value={data[md.columns.Bin.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Bin.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          {data[md.columns.zeroLevel.name] && (
            <>
              <Title>Зв&apos;язок елементів, станом на вказану дату</Title>
              <Row>
                <Col sm={9}>
                  <EditorControls.SelectorInput
                    label={md.columns.FIElement.label}
                    value={data[md.columns.FIElement.name].id}
                    onChange={(e, value) => {
                      const repr = FIElements.filter((vv) => vv.value === value).reduce((R, r) => r.repr, '');
                      actions.onChange({
                        [md.columns.FIElement.name]: { id: value, repr },
                      });
                    }}
                    readOnly={readOnly}
                    values={FIElements}
                  />
                </Col>
                <Col sm={3}>
                  <EditorControls.DateInput
                    label={md.columns.DateElement.label}
                    value={data[md.columns.DateElement.name]}
                    onChange={async (e, value) => {
                      await actions.onChange({
                        [md.columns.DateElement.name]: value,
                      });
                      actions.onSR('ПриИзменении_ДатаЭл');
                    }}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col sm={2}>
              <EditorControls.StringInput
                label={md.columns.code.label}
                value={data[md.columns.code.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.code.name]: value,
                })}
                maxLength={12}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={3}>
              <EditorControls.RadioInput
                controlId="AutoFillName"
                label={md.columns.AutoFillName.label}
                value={data[md.columns.AutoFillName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AutoFillName.name]: value,
                })}
                values={Object.values(autoFillName)}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={4}>
              <EditorControls.TextInput
                label={md.columns.FullName.label}
                value={data[md.columns.FullName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FullName.name]: value,
                })}
                readOnly={readOnly}
                rows={1}
              />
            </Col>
            <Col sm={3}>
              <EditorControls.RadioInput
                controlId="AutoFillFullName"
                label={md.columns.AutoFillFullName.label}
                value={data[md.columns.AutoFillFullName.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.AutoFillFullName.name]: value,
                })}
                values={Object.values(autoFillName)}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Title>Зв&apos;язки з бюджетними класифікаторами, станом на вказану дату</Title>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.DateClassifier.label}
                value={data[md.columns.DateClassifier.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.code.name]: value,
                  });
                  actions.onSR('ПриИзменении_ДатаКл');
                }}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.KBP.label}
                value={data[md.columns.KBP.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.KBP.name]: value,
                  });
                  actions.onSR('КлассификаторКБППриИзменении');
                }}
                modelName="kbpClassifier"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.KBP7.label}
                value={data[md.columns.KBP7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KBP7.name]: value,
                })}
                modelName="kbp7Classifier"
                modelType="cat"
                readOnly={readOnly}
                required
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.KFK4.label}
                value={data[md.columns.KFK4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KFK4.name]: value,
                })}
                modelName="kfkvkClassifier"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col className="d-flex justify-content-center align-items-end">
              <EditorControls.CheckboxInput
                controlId="DoNotPrint"
                label={md.columns.DoNotPrint.label}
                value={data[md.columns.DoNotPrint.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DoNotPrint.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.Budget.label}
                value={data[md.columns.Budget.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Budget.name]: value,
                })}
                modelName="budget"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.owner.label}
                value={data[md.columns.owner.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.owner.name]: value,
                })}
                modelName="csu"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

ElementKFK.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    zeroLevel: PropTypes.string,
    FIElementsList: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ElementKFK;
