import React from 'react';
import { Card } from 'react-bootstrap';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import Revenue412Table from './Revenue412Table';

const dataRevenue = [
  {
    id: 1,
    name: '11010100: Податок на доходи фізичних осіб, що сплачується податковими агентами, із доходів платника податку у вигляді заробітної плати',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 90,
  },
  {
    id: 2,
    name: '11010500: Податок на доходи фізичних осіб, що сплачується фізичними особами за результатами річного декларуван',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 30,
  },
  {
    id: 3,
    name: '11010400: Податок на доходи фізичних осіб, що сплачується податковими агентами, із доходів платника податку інших ніж заробітна плата',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 70,
  },
  {
    id: 4,
    name: '14040000: Акцизний податок з реалізації суб\'єктами господарювання роздрібної торгівлі підакцизних товарів',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 50,
  }, {
    id: 5,
    name: '22090100: Державне мито, що сплачується за місцем розгляду та оформлення документів, у тому числі за оформлення документів на спадщину і дарування',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 40,
  }, {
    id: 6,
    name: '41051200: Субвенція з місцевого бюджету на надання державної підтримки особам з особливими освітніми потребами за рахунок відповідної субвенції з державного бюджету',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 60,
  }, {
    id: 7,
    name: '41033900: Освітня субвенція з державного бюджету місцевим бюджетам',
    fond: 'заг.фонд',
    sum_day: 2600,
    sum_year: 3523,
    sum_year_last: 3523,
    progress: 80,
  },
];

function Revenue412() {
  return (
    <Card className="overflow-hidden">
      <CardHeader title="Виконання дохідної частини за доходами (ф.412)" titleTag="h6" />
      <Card.Body className="p-0">
        <Revenue412Table data={dataRevenue} />
      </Card.Body>
    </Card>
  );
}

export default Revenue412;
