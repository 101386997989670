import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/financingRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  isComposeSettingsChecked,
  activeObjectsBuilding,
  CSUinH,
  ABVisible,
  doNotUseAccounts,
  ClientInDoc,
  AccountingByClients,
  ShowSourcesFinInDocFin,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        {!CSUinH && !doNotUseAccounts
          && (
            <Row noGutters>
              <Col
                xl={4}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'CSU'}
                  highlighted={highlights.includes('CSU')}
                  onToggleHighlght={() => onToggleHighlght('CSU')}
                >
                  {meta.columns.CSU.label}
                </TableHeaderCell>
              </Col>
              <Col
                xl={8}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'CSUAccount'}
                  highlighted={highlights.includes('CSUAccount')}
                  onToggleHighlght={() => onToggleHighlght('CSUAccount')}
                >
                  {meta.columns.CSUAccount.label}
                </TableHeaderCell>
              </Col>
            </Row>
          )}
        <Row noGutters>
          <Col
            xl={4}
            sm={12}
            className="d-flex flex-column border-right"
          >
            <Row noGutters>
              <Col>
                {!CSUinH && doNotUseAccounts && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'CSU'}
                  highlighted={highlights.includes('CSU')}
                  onToggleHighlght={() => onToggleHighlght('CSU')}
                >
                  {meta.columns.CSU.label}
                </TableHeaderCell>
                )}
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'elementKFK'}
                  highlighted={highlights.includes('elementKFK')}
                  onToggleHighlght={() => onToggleHighlght('elementKFK')}
                >
                  {meta.columns.elementKFK.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'elementKEKV'}
                  highlighted={highlights.includes('elementKEKV')}
                  onToggleHighlght={() => onToggleHighlght('elementKEKV')}
                >
                  {meta.columns.elementKEKV.label}
                </TableHeaderCell>
                {CSUinH && !doNotUseAccounts
          && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSUAccount'}
              highlighted={highlights.includes('CSUAccount')}
              onToggleHighlght={() => onToggleHighlght('CSUAccount')}
            >
              {meta.columns.CSUAccount.label}
            </TableHeaderCell>
          )}
              </Col>
            </Row>
          </Col>

          {activeObjectsBuilding
    && (
      <Col xl={2} sm={12} className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'BuildObgect'}
          highlighted={highlights.includes('BuildObgect')}
          onToggleHighlght={() => onToggleHighlght('BuildObgect')}
        >
          {meta.columns.BuildObgect.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'TypeRepair'}
          highlighted={highlights.includes('TypeRepair')}
          onToggleHighlght={() => onToggleHighlght('TypeRepair')}
        >
          {meta.columns.TypeRepair.label}
        </TableHeaderCell>
      </Col>
    )}
          {ShowSourcesFinInDocFin
          && (
            <Col xl={2} sm={12} className="border-right">
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'ElIncome'}
                highlighted={highlights.includes('ElIncome')}
                onToggleHighlght={() => onToggleHighlght('ElIncome')}
              >
                {meta.columns.ElIncome.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'CodeElIncome'}
                highlighted={highlights.includes('CodeElIncome')}
                onToggleHighlght={() => onToggleHighlght('CodeElIncome')}
              >
                {meta.columns.CodeElIncome.label}
              </TableHeaderCell>
            </Col>
          )}

          <Col xl={(activeObjectsBuilding || ShowSourcesFinInDocFin) ? 6 : 8} sm={12}>
            <Row noGutters>

              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'YearPlan'}
                  highlighted={highlights.includes('YearPlan')}
                  onToggleHighlght={() => onToggleHighlght('YearPlan')}
                >
                  {meta.columns.YearPlan.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'PeriodPlan'}
                  highlighted={highlights.includes('PeriodPlan')}
                  onToggleHighlght={() => onToggleHighlght('PeriodPlan')}
                >
                  {meta.columns.PeriodPlan.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'MonthPlan'}
                  highlighted={highlights.includes('MonthPlan')}
                  onToggleHighlght={() => onToggleHighlght('MonthPlan')}
                >
                  {meta.columns.MonthPlan.label}
                </TableHeaderCell>
              </Col>

              <Col
                className="border-right"
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'PeriodFact'}
                  highlighted={highlights.includes('PeriodFact')}
                  onToggleHighlght={() => onToggleHighlght('PeriodFact')}
                >
                  {meta.columns.PeriodFact.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'Balance'}
                  highlighted={highlights.includes('Balance')}
                  onToggleHighlght={() => onToggleHighlght('Balance')}
                >
                  {meta.columns.Balance.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'FinLiabilities'}
                  highlighted={highlights.includes('FinLiabilities')}
                  onToggleHighlght={() => onToggleHighlght('FinLiabilities')}
                >
                  {meta.columns.FinLiabilities.label}
                </TableHeaderCell>
              </Col>

              {(ABVisible || isComposeSettingsChecked)
              && (
                <Col className="border-right" sm={12} lg={3}>
                  {ABVisible && (
                    <>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'BalanceRegAccounts'}
                        highlighted={highlights.includes('BalanceRegAccounts')}
                        onToggleHighlght={() => onToggleHighlght('BalanceRegAccounts')}
                      >
                        {meta.columns.BalanceRegAccounts.label}
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'BalancePersAccounts'}
                        highlighted={highlights.includes('BalancePersAccounts')}
                        onToggleHighlght={() => onToggleHighlght('BalancePersAccounts')}
                      >
                        {meta.columns.BalancePersAccounts.label}
                      </TableHeaderCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TableHeaderCell
                      className="text-center text-xl-left"
                      active={activeCol === 'Sum'}
                      highlighted={highlights.includes('Sum')}
                      onToggleHighlght={() => onToggleHighlght('Sum')}
                    >
                      {meta.columns.Sum.label}
                    </TableHeaderCell>
                  )}
                </Col>
              )}

              <Col
                className="border-right"
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                {!isComposeSettingsChecked && !ABVisible && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'Sum'}
                  highlighted={highlights.includes('Sum')}
                  onToggleHighlght={() => onToggleHighlght('Sum')}
                >
                  {meta.columns.Sum.label}
                </TableHeaderCell>
                )}
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'NoteCat'}
                  highlighted={highlights.includes('NoteCat')}
                  onToggleHighlght={() => onToggleHighlght('NoteCat')}
                >
                  {meta.columns.NoteCat.label}
                </TableHeaderCell>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'DocNotes'}
                  highlighted={highlights.includes('DocNotes')}
                  onToggleHighlght={() => onToggleHighlght('DocNotes')}
                >
                  {meta.columns.DocNotes.label}
                </TableHeaderCell>
                {isComposeSettingsChecked && (
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'IsReturn'}
                  highlighted={highlights.includes('IsReturn')}
                  onToggleHighlght={() => onToggleHighlght('IsReturn')}
                >
                  {meta.columns.IsReturn.label}
                </TableHeaderCell>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {ShowSourcesFinInDocFin && (
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeFinSources'}
              highlighted={highlights.includes('codeFinSources')}
              onToggleHighlght={() => onToggleHighlght('codeFinSources')}
            >
              {meta.columns.codeFinSources.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finSources'}
              highlighted={highlights.includes('finSources')}
              onToggleHighlght={() => onToggleHighlght('finSources')}
            >
              {meta.columns.finSources.label}
            </TableHeaderCell>
          </Col>
        </Row>
        )}
        <Row noGutters>
          <Col sm={12} xl={6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Note'}
              highlighted={highlights.includes('Note')}
              onToggleHighlght={() => onToggleHighlght('Note')}
            >
              {meta.columns.Note.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FullNotes'}
              highlighted={highlights.includes('FullNotes')}
              onToggleHighlght={() => onToggleHighlght('FullNotes')}
            >
              {meta.columns.FullNotes.label}
            </TableHeaderCell>
          </Col>
          { ClientInDoc
        && (
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Client'}
              highlighted={highlights.includes('Client')}
              onToggleHighlght={() => onToggleHighlght('Client')}
            >
              {meta.columns.Client.label}
            </TableHeaderCell>
          </Col>
        )}
          { AccountingByClients
        && (
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'FinLiabilitiy'}
              highlighted={highlights.includes('FinLiabilitiy')}
              onToggleHighlght={() => onToggleHighlght('FinLiabilitiy')}
            >
              {meta.columns.FinLiabilitiy.label}
            </TableHeaderCell>
          </Col>
        )}
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  isComposeSettingsChecked: PropTypes.bool,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  CSUinH: PropTypes.bool,
  doNotUseAccounts: PropTypes.bool,
  AccountingByClients: PropTypes.bool.isRequired,
  ClientInDoc: PropTypes.bool.isRequired,
  ShowSourcesFinInDocFin: PropTypes.bool,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  isComposeSettingsChecked: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  CSUinH: false,
  doNotUseAccounts: false,
  ShowSourcesFinInDocFin: false,
};

export default memo(TPHeader);
