import KPKVNameAsVariants from './KPKVNameAsVariants';
import ReportVariants from './ReportVariants';
import ReportVariantsNew from './ReportVariantsNew';
import ReportVariantsNewNew from './ReportVariantsNewNew';
import QuartalVariants from './QuartalVariants';
import KVKNameAsVariants from './KVKNameAsVariants';
import KVKNameAsVariantsNew from './KVKNameAsVariantsNew';
import KEKVVariants from './KEKVVariants';
import KEKVDetailsVariants from './KEKVDetailsVariants';
import CoordinationVariants from './CoordinationVariants';
import PlaningKind from './planingKind';

const enums = {
  KPKVNameAsVariants,
  PlaningKind,
  ReportVariants,
  ReportVariantsNew,
  ReportVariantsNewNew,
  QuartalVariants,
  KVKNameAsVariants,
  KVKNameAsVariantsNew,
  KEKVVariants,
  KEKVDetailsVariants,
  CoordinationVariants,
};

export default enums;
