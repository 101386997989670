import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-center"
      >
        <Row sm={2} noGutters>

          <TableHeaderCell
            className="text-center text-xl-left col-sm-4"
            active={activeCol === 'group_name'}
            highlighted={highlights.includes('group_name')}
            onToggleHighlight={() => onToggleHighlight('group_name')}
          >
            {(fields.group_name === undefined) ? '' : fields.group_name.label}
          </TableHeaderCell>

          <TableHeaderCell
            className="text-center text-xl-left  col-sm-4"
            active={activeCol === 'children_amount'}
            highlighted={highlights.includes('children_amount')}
            onToggleHighlight={() => onToggleHighlight('children_amount')}
            title={`Всього:${totals} діток`}
          >
            {(fields.children_amount === undefined) ? '' : fields.children_amount.label}
          </TableHeaderCell>

        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    children_total: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
