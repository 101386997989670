import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer } from '../../../../components/Containers/docs';
import AppContext from '../../../../providers/CiatAppContext';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors,
    fieldErrors,

  } = useEditor({
    backendURL: meta.cat.bfnGroupAge.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { currentUser } = useContext(AppContext);
  const isSuperuser = currentUser.is_superuser;

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange, !permissions.canChange);
  const budgetProps = editorHooks.useItemInputProps('budget', data, fields, fieldErrors, actions.onChange, !permissions.canChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.bfnGroupAge.backendURL}
        />
      )}
    >
      <Container fluid style={{ padding: '0' }}>
        { isSuperuser && (
        <Row>
          <Col>
            <EditorControls.ItemPicker {...budgetProps} modelType="cat" modelName="budget" />
          </Col>
        </Row>
        )}
        <Row>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
      </Container>

      <Footer
        budget={data.budget}
      />

    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
