const frontendURL = '/doc/financingRequest/';
const backendURL = '/api/financials/finrequestheader/';
// const printURL = '';
const name = 'Заявка на фінансування';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
