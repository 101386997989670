import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { TableControls, tableHooks } from '../tableControls';
import TabelRowCell from '../../newEditor/tablePart/tabkeRowCell';

function BasicCell({
  rowIndex, onChange, row, field, fields, errors, highlighted, readOnly, controlProps,
}) {
  const hook = useMemo(
    () => {
      if (!(field in fields)) {
        return () => ({
          bg: 'danger',
        });
      }
      switch (fields[field].type) {
        case 'string':
          return tableHooks.useStringInputProps;
        case 'date':
          return tableHooks.useDateInputProps;
        case 'decimal':
        case 'number':
          return tableHooks.useNumberInputProps;
        case 'boolean':
          return tableHooks.useCheckboxInputProps;
        case 'choice':
          return tableHooks.useSelectorInputProps;
        case 'nested object':
          return tableHooks.useItemInputProps;
        default:
          throw new Error(`${fields[field].type} еще не реализован в BasicCell`);
      }
    },
    [field, fields],
  );
  const cProps = hook(
    field,
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
  );
  const Control = useMemo(
    () => {
      if (!(field in fields)) {
        return Badge;
      }
      switch (fields[field].type) {
        case 'string':
          return TableControls.StringInput;
        case 'date':
          return TableControls.DateInput;
        case 'decimal':
        case 'number':
          return TableControls.NumberInput;
        case 'boolean':
          return TableControls.CheckboxInput;
        case 'choice':
          return TableControls.SelectorInput;
        case 'nested object':
          return TableControls.ItemPicker;
        default:
          throw new Error(`${fields[field].type} еще не реализован в BasicCell`);
      }
    },
    [field, fields],
  );
  const cp = useMemo(
    () => {
      let ctrlProps = {};
      if (controlProps && typeof controlProps === 'function') {
        ctrlProps = controlProps(row);
      } else if (controlProps && typeof controlProps === 'object') {
        ctrlProps = controlProps;
      }
      return {
        ...cProps,
        ...ctrlProps,
      };
    },
    [cProps, controlProps, row],
  );

  return (
    <TabelRowCell column={field} highlighted={highlighted} active>
      <Control {...cp} />
    </TabelRowCell>
  );
}

BasicCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
  highlighted: PropTypes.bool,
  readOnly: PropTypes.bool,
  field: PropTypes.string.isRequired,
  controlProps: PropTypes.shape({}),
};

BasicCell.defaultProps = {
  errors: {},
  highlighted: false,
  readOnly: false,
  controlProps: {},
};
export default BasicCell;
