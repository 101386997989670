import React, {
  useEffect, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, Col, Row, Button, Container,
} from 'react-bootstrap';
import { paramPropType, tablePropType } from '../hooks';
import ParamsEditor from './paramValues';
import TableRecords from './records';
import { CommandPanel } from '../../../../components/bootStrap';

function TableBrowser({
  params, tables, tableFields, onGetTableRecords, tableRows,
}) {
  const [paramValues, setParamValues] = useState({});
  const [howManys, setHowManys] = useState({});
  useEffect(() => setParamValues(
    (oldVals) => params.reduce((R, p) => ({ ...R, [p.name]: oldVals[p.name] || null }), {}),
  ), [params, setParamValues]);
  useEffect(() => setHowManys(
    (oldVals) => tables.reduce((R, t) => ({ ...R, [t.name]: oldVals[t.name] || -1 }), {}),
  ), [tables, setHowManys]);

  const [page, setPage] = useState('');
  return (
    <Container fluid>
      <Row>
        <Col xl={3} className="containerCol border rounded">
          <ParamsEditor
            params={params}
            onChange={(name, value) => setParamValues((oldVals) => ({ ...oldVals, [name]: value }))}
            paramValues={paramValues}
          />
        </Col>
        <Col xl={9} className="containerCol">
          <Accordion activeKey={page}>
            <CommandPanel leftPart={tables.map((t) => (
              <Accordion.Button
                size="sm"
                key={t.name}
                as={Button}
                variant="outline-primary"
                eventkey={t.name}
                onClick={() => setPage(t.name)}
              >
                <span>{t.name}</span>
              </Accordion.Button>
            ))}
            />
            {tables.map((t) => (
              <Accordion.Collapse eventKey={t.name} key={t.name}>
                <TableRecords
                  fields={tableFields[t.name] || []}
                  tableName={t.name}
                  howMany={howManys[t.name] || 0}
                  onChangeHowMany={(v) => setHowManys((oldVal) => ({ ...oldVal, [t.name]: v }))}
                  onRequestRecords={() => onGetTableRecords(t.name, paramValues, howManys[t.name])}
                  records={tableRows[t.name]}
                />
              </Accordion.Collapse>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>

  );
}

TableBrowser.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  tables: PropTypes.arrayOf(tablePropType).isRequired,
  tableFields: PropTypes.shape({}).isRequired,
  onGetTableRecords: PropTypes.func.isRequired,
  tableRows: PropTypes.shape({}).isRequired,
};

export default memo(TableBrowser);
