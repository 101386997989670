import React, {
  useEffect, useMemo,
} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';

import {
  StyledLabel,
  MyContainerFormWithNColumns, CommandPanelColor,
} from '../../../components/Form/styledForm';

import { emptyUid } from '../../../constants/meta/common';

import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import DateField from '../../../components/fields/dates';
import { InputWithDropdown } from '../../../components/styledInputs';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.exportSocialPayment;

const StringField8 = Fields.GetTextInput(8);
const StringField3 = Fields.GetTextInput(3);

const RepType = {
  Tweek: { label: 'Тижнева звітність', name: 1 },
  Tmonth: { label: 'Місячна звітність', name: 2 },
};

function ExportSocialPaymentEditor() {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/dp/exportSocialPayment/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId],
  );

  return (
    <MainContainer>
      <MyContainerFormWithNColumns columns={1}>
        <div>
          <StyledLabel>{md.columns.Budget.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('Budget', new Map())}
            modelType="cat"
            modelName="budget"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'Budget'], value));
            }}
          />
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={4}>
        <div>
          <StyledLabel>{md.columns.DateFin.label}</StyledLabel>
          <DateField
            value={headerForm.get('DateFin', null)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DateFin'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.TypeRep.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('TypeRep', 1)}
            options={Object.values(RepType)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'TypeRep'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.f866.label}
            value={headerForm.get('f866', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'f866'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.LongKBP.label}
            value={headerForm.get('LongKBP', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'LongKBP'], v))}
          />
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={2}>
        <div>
          <StyledLabel>{md.columns.FileName.label}</StyledLabel>
          <StringField8
            value={headerForm.get('FileName', null)}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'FileName'], value));
            }}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.FileCode.label}</StyledLabel>
          <StringField3
            value={headerForm.get('FileCode', null)}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'FileCode'], value));
            }}
          />
        </div>
      </MyContainerFormWithNColumns>
      <div>
        <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('SignVariant', new Map())}
          modelType="cat"
          modelName="signatureVariants"
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
        />
      </div>
      <MyContainerFormWithNColumns columns={2}>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Створення документу для відправки в Logica"
          label="Створити документ відправки в Logica"
          onClick={() => {
            dispatch(actions.processServerReq('CreateDispatchDoc'));
          }}
        />
      </MyContainerFormWithNColumns>
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ExportSocialPaymentEditor);
