import PropTypes from 'prop-types';
import { getFieldsPropTypes } from '../../../basicEditor';
import { foreignPropType } from '../../../../constants/backend/propTypes';

export const dataPropTypes = {
  id: PropTypes.number,
  finrequest: PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }),
  disposer: foreignPropType,
  kbp7: foreignPropType,
  kekv: foreignPropType,
  balance: PropTypes.number,
};

export const fieldsPropTypes = PropTypes.shape(
  getFieldsPropTypes(dataPropTypes),
);
