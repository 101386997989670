import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  StyledHistoryContainer, StyledHistoryList, StyledHistoryItem, StyledHistoryItemFormula, StyledHistoryItemResult, StyledBtmButtons, StyledCalculateButton, StyledBtmButtonContainer,
} from './styles';
// import './History.css';

function isOverflowedX(element) {
  return element.scrollWidth > element.clientWidth;
}

function isOverflowedY(element) {
  return element.scrollHeight > element.clientHeight;
}

function addScrollbarIfNeeded(element) {
  // eslint-disable-next-line
  if (isOverflowedY(element)) element.style.overflowY = 'scroll';
  // eslint-disable-next-line
  else element.style.overflowY = 'initial';

  // eslint-disable-next-line
  if (isOverflowedX(element)) element.style.overflowX = 'scroll';
  // eslint-disable-next-line
  else element.style.overflowX = 'initial';
}

export default class History extends PureComponent {
  static propTypes = {
    isShowHistory: PropTypes.bool.isRequired,
    history: PropTypes.arrayOf(PropTypes.shape({
      formula: PropTypes.arrayOf(PropTypes.string),
      result: PropTypes.number,
    })).isRequired,
    onHistoryItemClicked: PropTypes.func.isRequired,
    onClearHistory: PropTypes.func.isRequired,
    onEqual: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.historyList = React.createRef();
  }

  componentDidUpdate() {
    addScrollbarIfNeeded(this.historyList.current);
  }

  render() {
    const {
      isShowHistory, history, onHistoryItemClicked, onClearHistory, onEqual,
    } = this.props;
    return (
      <StyledHistoryContainer hidden={!isShowHistory}>
        <StyledHistoryList ref={this.historyList}>
          {
            history.map((item, index) => (
              // eslint-disable-next-line
              <StyledHistoryItem key={index}>
                <StyledHistoryItemFormula>
                  {item.formula.join('')}
                </StyledHistoryItemFormula>
                <StyledHistoryItemResult
                  value={item.result}
                  onClick={onHistoryItemClicked}
                >
                  =
                  {item.result}
                </StyledHistoryItemResult>
                <hr />
              </StyledHistoryItem>
            ))
          }
        </StyledHistoryList>
        <StyledBtmButtonContainer>
          <StyledBtmButtons
            onClick={onClearHistory}
          >
            Clear
          </StyledBtmButtons>
          <StyledCalculateButton
            onClick={onEqual}
          >
            Equal
          </StyledCalculateButton>
        </StyledBtmButtonContainer>
      </StyledHistoryContainer>
    );
  }
}
