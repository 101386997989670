/* eslint-disable react/no-array-index-key */
import React, { memo, useContext, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd2';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { soSelector } from '../../../../_common/selectors';
import { fillCountChild } from '../../fillCountChild';
import { AppContext } from '../../../../../../minfin/providers/authProvider';

const tableMeta = md.tables.tar;

export const ModalButton = styled(Button)`
    background-color: var(--backgroundBrightButton) !important;
    color: var(--colorTextBrightButton) !important;
    :hover {
        background-color: var(--hoverBrightButton) !important;
    };
`;

function TarTP({ data, actions, readOnly }) {
  const FI = data[md.columns.FI.name];
  const usePCMCatalog = data[md.columns.usePCMCatalog.name];
  const activeYear = data[md.columns.year.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const totals = useMemo(() => (tableData.reduce((res, r) => {
    res.sum_2 += r[tableMeta.columns.sum_2.name] ? r[tableMeta.columns.sum_2.name] : 0;
    res.sum_1 += r[tableMeta.columns.sum_1.name] ? r[tableMeta.columns.sum_1.name] : 0;
    res.sum0 += r[tableMeta.columns.sum0.name] ? r[tableMeta.columns.sum0.name] : 0;
    res.sum1 += r[tableMeta.columns.sum1.name] ? r[tableMeta.columns.sum1.name] : 0;
    res.sum2 += r[tableMeta.columns.sum2.name] ? r[tableMeta.columns.sum2.name] : 0;
    return res;
  }, {
    sum_2: 0,
    sum_1: 0,
    sum0: 0,
    sum1: 0,
    sum2: 0,
  })
  ), [tableData]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);
  const { auth } = useContext(AppContext);
  const tableMD = md.tables.tar;

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          {sessionOptions.getIn(['func_params', 'ВключитьПодсистемуБФН'], false) && (
            <ModalButton
              variant="primary"
              onClick={(e) => {
                const count = fillCountChild(
                  {
                    data,
                    auth,
                  },
                );
                tableActions.onCellChange(e, activeRow, { [`${tableMD.columns[activeCol].name}`]: count });
              }}
            >
              Заповнити кількісттю дітей за даними БФН
            </ModalButton>
          )}
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          activeYear={activeYear}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          usePCMCatalog={usePCMCatalog}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            /// /
            FI={FI}
            isFilterCsuElemKFK={isFilterCsuElemKFK}
            usePCMCatalog={usePCMCatalog}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          activeCol={activeCol}
          highlights={highlights}
          totals={totals}
          //
        />
      </TableFooter>
    </Card>
  );
}

TarTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

TarTP.defaultProps = {
  readOnly: false,
};

export default memo(TarTP);
