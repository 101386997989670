import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile as icon, faCheck } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function Execute({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      variant="success"
    >
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={icon} />
        <FontAwesomeIcon icon={faCheck} color="var(--falcon-success)" transform="shrink-6 down-2" />
      </span>

    </DefaultCPButton>
  );
}

Execute.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

Execute.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Провести',
};

export default Execute;
