import styled from 'styled-components';
import { BasedGrid } from '../../../../../components/styledTable/styledTable';

const gridTemplateAreas = `"csu      csu      eKFK eKEKV sum notes"
                           "KPKVCode KEKVCode eKFK eKEKV sum notes";`;

const gridTemplateColumns = ' 0.5fr 0.5fr 1fr 1fr 150px  1fr';

const GridTemplate = styled(BasedGrid)`
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  grid-template-areas: ${gridTemplateAreas};
  grid-auto-columns: 100px;
  grid-auto-rows: auto;
`;

export const DivCell = styled.div`
  grid-area: ${(props) => props.areaName};
  overflow: hidden;
`;

export const DivCheckCell = styled.div`
  grid-area: ${(props) => props.areaName};
  margin: auto;
`;

export const DivHeader = styled.div`
  grid-area: ${(props) => props.areaName};
  border: 1px gray dotted ;
`;

export default GridTemplate;
