import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  CodeKEKV: { label: 'Код КЕКВ', name: 'КодКЕКВ', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  Steward: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  Classifier: { label: 'КЕКВ', name: 'Классификатор', visible: true },
  DateCl: { label: 'станом на', name: 'ДатаКл', visible: true },
  ClassifierPI: {
    label: 'КЕКВ (для плану використання)',
    name: 'КлассификаторПИ',
    visible: true,
  },
  DateCLPI: { label: 'станом на', name: 'ДатаКлПИ', visible: true },
  FinInstitutionElementList: {
    label: 'Список элементiв вищого рiвня',
    name: 'СписокЭлементовВУ',
    visible: false,
  },
  FinInstitutionElement: {
    label: 'Елемент фін. органу',
    name: 'ЭлементФинОргана',
    visible: true,
  },
  flAutoFillName: {
    label: 'Автоматичне заповнення найменування ',
    name: 'флАтоматЗаполненияНаим',
    visible: false,
  },
  flAutoFillFullName: {
    label: 'Автоматичне заповнення повного найменування ',
    name: 'флАтоматЗаполненияПолнНаим',
    visible: false,
  },
  DateEl: { label: 'станом на', name: 'ДатаЭл', visible: true },
  owner: { label: 'Фінансовий орган', name: 'Владелец', visible: true },
};

export const elementKEKV = {
  label: 'Деталізація КЕКВ',
  name: 'elementKEKV',
  backendName: 'ЭлементыКЕКВ',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    {
      column: columns.CodeKEKV.name,
      direction: 'ascending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/elementKEKV',
  listColumns: [
    ...new Set([
      'CodeKEKV',
      ...reqListerColumns,
      'ClassifierPI',
      'Steward',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default elementKEKV;
