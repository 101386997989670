import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';
import { changeField } from '../../../../../actions/dpEditor';
import Fields from '../../../../field';
import { DateField } from '../../../../../components/fields';
import GridTemplate from './GridTemplate';
import {
  DivCell, DivCheckCell, InputCodeStyled, SumInputStyled,
} from '../../../../../components/styledTable/styledTable';

const TABLENAME = 'docsTable';

function RowComponent({ row, rowId }) {
  const dispatch = useDispatch();
  return (
    <GridTemplate>
      <DivCheckCell areaName="check">
        <Fields.CheckboxField
          value={row.get('Check', false)}
          onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'Check'], v))}
        />
      </DivCheckCell>
      <DivCell areaName="docNo">
        <InputCodeStyled
          readOnly
          value={row.get('DocNo', '')}
        />
      </DivCell>
      <DivCell areaName="date">
        <DateField
          value={row.get('Date', 0)}
          noBackground
          noBorder
        />
      </DivCell>
      <DivCell areaName="sum">
        <SumInputStyled
          disabled
          value={row.get('Sum', 0)}
        />
      </DivCell>
      <DivCell areaName="author">
        <Fields.ItemField
          value={row.get('Autor', new Map())}
          noBackground
          noBorder
        />
      </DivCell>
    </GridTemplate>
  );
}

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
};

export default memo(RowComponent);
