import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/prepareForecastAdd1';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function PrepareForecastAdd1({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.csu.label}
            value={data[md.columns.csu.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.csu.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            noHierarchy={false}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд ${data[md.columns.year.name]} (план)`}
            value={data[md.columns.ZFsum0.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ZFsum0.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд ${data[md.columns.year.name] + 1} (план)`}
            value={data[md.columns.ZFsum1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ZFsum1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд ${data[md.columns.year.name] + 2} (план)`}
            value={data[md.columns.ZFsum2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ZFsum2.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={`Спец.фонд ${data[md.columns.year.name]} (план)`}
            value={data[md.columns.SFsum0.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SFsum0.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Спец.фонд ${data[md.columns.year.name] + 1} (план)`}
            value={data[md.columns.SFsum1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SFsum1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Спец.фонд ${data[md.columns.year.name] + 2} (план)`}
            value={data[md.columns.SFsum2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SFsum2.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд кредит. ${data[md.columns.year.name]} (план)`}
            value={data[md.columns.Ksum0.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Ksum0.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд кредит. ${data[md.columns.year.name] + 1} (план)`}
            value={data[md.columns.Ksum1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Ksum1.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={`Заг.фонд кредит. ${data[md.columns.year.name] + 2} (план)`}
            value={data[md.columns.Ksum2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Ksum2.name]: value,
            })}
            readOnly={readOnly}
            precision={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`showKDB-${data.id}`}
            label={md.columns.showKDB.label}
            value={data[md.columns.showKDB.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.showKDB.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

PrepareForecastAdd1.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PrepareForecastAdd1;
