import React, {
  Suspense, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import { soSelector } from '../../documents/_common/selectors';
import { emptyUid } from '../../../constants/meta/common';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.forecastAdd7Printing;

function ForecastAdd7PrintingEditor({ onClose }) {
  const dispatch = useDispatch();
  const {
    headerForm,
    isProcessing,
  } = useSelector(mapState);
  const so = useSelector(soSelector);
  const showProj = so.get('isFinManagment', false) || so.get('is_admin', false);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );
  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );
  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [navigate, logikaId, onClose],
  );

  return (
    <MainContainer>
      <div style={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
      >
        {showProj && (
          <div style={{
            flex: '1 1 auto',
            marginRight: '20px',
          }}
          >
            <StyledLabel>{md.columns.proj.label}</StyledLabel>
            <Fields.SelectorField
              modelType="cat"
              modelName="planing"
              value={headerForm.get('proj', new Map())}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'proj'], v))}
            />
          </div>

        )}
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && (
          <CommandPanelButton
            label="Передати в Logica"
            onClick={() => {
              dispatch(actions.processServerReq('CreateDispatchDoc'));
            }}
          />
        )}
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal
          styled={settingsOpened ? { transition: '3s' } : { transition: '3s' }}
          visible={settingsOpened}
          animation="overlay"
          width="very wide"
          direction="top"
          tertiary
          color="blue"
          basic
        >
          <div>
            <ContainerThreeColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
            </ContainerThreeColumn>
          </div>
        </FramePrintModal>
      )
        : (
          <Suspense fallback={<div>Завантаження...</div>}>
            <TableEditor mountNode={resultMountNode} />
          </Suspense>
        )}
    </MainContainer>
  );
}

ForecastAdd7PrintingEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

export default enchance(ForecastAdd7PrintingEditor);
