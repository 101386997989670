import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/passportReportAnalysis';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  TwoColumnsGridContainer,
  StyledPane,
  DivWithPropsLabel,
  ResultSegment,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import ItemField from '../../field/selectorField/item/itemField';
import ReportContainer from '../reportContainer';

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();
  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());

  const isProcessing = state.get('isProcessing', true);
  const FO = headerForm.get('FO', new Map());

  const filterChoice = useMemo(() => new Map()
    .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
    .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }]), [FO]);

  const BudgetLabel = sessionOptions.get('is_admin', false) ? (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    </div>
  ) : (
    <div>
      <StyledLabel>{md.columns.budget.label}</StyledLabel>
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    </div>
  );

  const FoLabel = sessionOptions.get('is_admin', false) || headerForm.get('byHighLevelCSU', false) ? (
    <div>
      <StyledLabel>{md.columns.FO.label}</StyledLabel>
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    </div>
  ) : (
    <div>
      <StyledLabel>{FO.label}</StyledLabel>
      <ItemField
        value={FO}
        disabled
      />
    </div>
  );

  const SettingsButton = (
    <div>
      <div>
        <TwoColumnsGridContainer>
          <div>
            {BudgetLabel}
          </div>
          <div>
            {FoLabel}
          </div>
        </TwoColumnsGridContainer>
      </div>
      {/*<Tabs>*/}
      {/*  <DivWithPropsLabel label="Группування">*/}
      {/*    <StyledPane>*/}
      {/*      <DCControls.Structure />*/}
      {/*    </StyledPane>*/}
      {/*  </DivWithPropsLabel>*/}
      {/*  <DivWithPropsLabel label="Відбір">*/}
      {/*    <StyledPane>*/}
      {/*      <DCControls.Filter*/}
      {/*        filterChoice={filterChoice}*/}
      {/*      />*/}
      {/*    </StyledPane>*/}
      {/*  </DivWithPropsLabel>*/}
        {/*<DivWithPropsLabel label="Обрані поля">*/}
        {/*  <StyledPane>*/}
        {/*    <DCControls.Selection />*/}
        {/*  </StyledPane>*/}
        {/*</DivWithPropsLabel>*/}
        {/*<DivWithPropsLabel label="Сортування">*/}
        {/*  <StyledPane>*/}
        {/*    <DCControls.Order />*/}
        {/*  </StyledPane>*/}
        {/*</DivWithPropsLabel>*/}
      {/*</Tabs>*/}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <div style={{ marginTop: '10px' }}>
        <StyledLabel>{md.columns.period.label}</StyledLabel>
        <DateRangeField
          value={headerForm.get('period', new Map())}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
          noBorder
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
