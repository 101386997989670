import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  fullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: false },
  logicaName: { label: 'Назва для вивантаження Logica', name: 'НаимЛогика', visible: true },
  nomDod: { label: 'Номер додатку', name: 'НомерДодатка', visible: true },
};

export const tarif = {
  label: 'Тарифні розряди',
  name: 'tarif',
  backendName: 'ТарифРозряд',
  defaultOrder,
  columns,
  frontend: 'cat/tarif',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'nomDod',
      'logicaName',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tarif;
