import React, { useContext } from 'react';
import ListerContext from '../../../newLister/context';
import PrintButton from './printModal';

function ListerPrintButton() {
  const {
    selectedRows,
  } = useContext(ListerContext);
  return (
    <PrintButton selectedRows={selectedRows} />
  );
}

export default ListerPrintButton;
