import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes2P';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_31';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="privilegeNumber" highlighted={highlights.includes('privilegeNumber')}>
          <StringInput
            value={row[tableMD.columns.privilegeNumber.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.privilegeNumber.name]: v })}
            readOnly={readOnly}
            maxLength={19}
            required
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="codeDod" highlighted={highlights.includes('codeDod')}>
          <StringInput
            value={row[tableMD.columns.codeDod.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.codeDod.name]: v })}
            readOnly={readOnly}
            maxLength={19}
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="privilegeName" highlighted={highlights.includes('privilegeName')}>
          <StringInput
            value={row[tableMD.columns.privilegeName.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.privilegeName.name]: v })}
            readOnly={readOnly}
            maxLength={254}
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="privilegeAmount" highlighted={highlights.includes('privilegeAmount')}>
          <NumberInput
            value={row[tableMD.columns.privilegeAmount.name]}
            precision={4}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.privilegeAmount.name]: v })}
            readOnly={readOnly}
            required
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.privilegeNumber.name]: PropTypes.string,
    [tableMD.columns.privilegeName.name]: PropTypes.string,
    [tableMD.columns.privilegeAmount.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
