const QuartalVariants = {
  first: { label: 'Весь рік', name: 0 },
  second: { label: 'І Квартал', name: 1 },
  third: { label: 'ІІ Квартал', name: 2 },
  fourth: { label: 'І-е півріччя', name: 3 },
  fifth: { label: 'ІІІ Квартал', name: 4 },
  sixth: { label: '9 місяців', name: 5 },
  seventh: { label: 'ІV Квартал', name: 6 },
};

export default QuartalVariants;
