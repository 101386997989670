const frontendURL = '/cat/user/';
const backendURL = '/api/auth/profile/';
// const backendURL = '/api/auth/user/';
const name = 'Користувачі';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
