import {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { CiatAppContext } from '../../../providers';

/**
 * Генерирует параметры, пригодные для отображения в редакторе
 * @param params {{}}
 * @returns {{}}
 */
const useDisplaysParams = (params) => useMemo(
  () => params || {},
  [params],
);

/**
 * Генерирует доступные поля параметров
 * @param nonEditableParams {Object}
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableParams = (fields, nonEditableParams) => useMemo(
  () => fields && Object.keys(fields)
    .filter((name) => !name.startsWith('request__') && !(name in nonEditableParams))
    .map((name) => ({ ...fields[name], name }))
    .sort((a, b) => (a.sort || 0 - b.sort || 0)),
  [fields, nonEditableParams],
);

/**
 * HOOK для работы с параметрами отчетов
 * @param reportData {{options: {params: string[]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  params: Object,
 *  displayParams: Object,
 *  availableParams: Array,
 *  groupsHandlers: {
 *      changeParams: function,
 *  },
 *  }}
 */

export const useParams = (reportData, schema) => {
  const [params, setParams] = useState({});

  const { currentUser } = useContext(CiatAppContext);

  // Параметры, которые не редактируются,
  // напримем ФинансовыйОрган
  const adminParams = useMemo(
    () => {
      if (currentUser.is_superuser) return {};
      return {
        authority: currentUser.profile.authority,
      };
    },
    [currentUser],
  );

  useEffect(() => {
    setParams({
      ...reportData.options.params,
      ...adminParams,
    });
  }, [adminParams, reportData]);

  const displayParams = useDisplaysParams(params);
  const availableParams = useAvailableParams(schema.src.params, adminParams);
  const handlers = useMemo(
    () => {
      const changeParams = (name, value) => {
        setParams({ ...params, [name]: value });
      };

      return ({
        changeParams,
      });
    },
    [params],
  );

  return {
    params,
    displayParams,
    availableParams,
    paramsHandlers: handlers,
  };
};

export const availableParamPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableParamsPropType = PropTypes.arrayOf(availableParamPropType);

export const subParamPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
});

export const paramPropType = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(subParamPropType),
});

export const paramsPropType = PropTypes.arrayOf(paramPropType);
