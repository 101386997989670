import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';

const LawsLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory());

export default LawsLister;
