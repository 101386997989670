import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import OrderItem from './orderItem';
import { StyledSegment, StyledList } from '../StyledComponents';

class OrderView extends Component {
  static propTypes = {
    availableFields: PropTypes.instanceOf(OrderedMap).isRequired,
    data: PropTypes.instanceOf(OrderedMap).isRequired,
    selectHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    toggleUseHandler: PropTypes.func,
    changeSortHandler: PropTypes.func,
  };

  static defaultProps = {
    selectHandler: () => null,
    deleteHandler: () => null,
    toggleUseHandler: () => null,
    changeSortHandler: () => null,
  };

  getItems = () => {
    const {
      availableFields, data, selectHandler, deleteHandler, toggleUseHandler, changeSortHandler,
    } = this.props;

    return data.reduce((R, item, k) => {
      const path = item.get('Field', '').split('.');
      // eslint-disable-next-line
      const newPath = path.reduce((R2, p, i) => R2.length ? [...R2, 'Items', `${path.slice(0, i).join('.')}.${p}`] : [p], []);
      return [
        ...R,
        // eslint-disable-next-line
        <Draggable key={k} draggableId={k} index={item.get('Index', 0)} >
          {(provided) => (
            <OrderItem
              iRef={provided.innerRef}
              item={item}
              id={k}
              defaultFieldTittle={availableFields.getIn([...newPath, 'Tittle'], '')}
              toggleUseHandler={toggleUseHandler}
              onClickHandler={selectHandler}
              onDoubleClickHandler={deleteHandler}
              onChangeSortHanlder={changeSortHandler}
              dragHandleProps={provided.dragHandleProps}
              draggableProps={provided.draggableProps}
            />
          )}
        </Draggable>,
      ];
    }, []);
  };

  render() {
    const { selectHandler } = this.props;
    return (
      <StyledSegment onClick={selectHandler}>
        <Droppable droppableId="DC.Order">
          {(provided) => (
            <StyledList ref={provided.innerRef} {...provided.droppableProps}>
              {this.getItems()}
            </StyledList>
          )}
        </Droppable>
      </StyledSegment>
    );
  }
}

export default OrderView;
