import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FileIcon } from './icons';
import CommandPanelButton from '../button/commandPanelButton';

export const DocGrid = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;                
`;

export const InfoLabel = styled.h3`
  color: green;
  text-align: center;
`;
export const GridItem = styled.div`
  grid-area: ${({ area }) => area};
`;

export const GItem = styled(GridItem)`
  overflow: auto;
  display: flex;
`;

export const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "org"
    "info"
    "buttons"
    "master"
    ;
  grid-template-rows: 50px 100px auto 1fr;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;  
`;

export const Input = styled.input`

`;

export const InputStyled = styled.input`
  display: flex;
  padding: 8.5px 10px;
  font-size: 1em;
  background: white;
  border: 1px solid #a2b4e8;
  color: #5e93d0;
  border-radius: 0.3rem;
`;

export const DivStyled = styled.div`
  display: flex;
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid rgba(34,36,38,.15)')};
  border-radius: .3rem;
  background: ${({ noBackground }) => (noBackground ? 'none' : 'white')};
  min-height: 33px;
  position: relative;
`;

// export const DivStyledUpload = styled(DivStyled)`
//   position: relative;
//   overflow: hidden;
//   width: 110px;
//   height: 20px;
//   background: white;
//   border: 1px solid #a2b4e8;
//   box-shadow: 0 0 20px rgba(0,0,0,0.16);
//   color: #5e93d0;
//   border-radius: 0.3rem;
//   // padding: 8px 4px;
//   text-align: center;
//   font-family: Roboto, sans-serif;
//   font-size: 14px;
//   font-weight: 700;
//   cursor: pointer;
//     input[type="file"]{
//       opacity: 0;
//       z-index: 1;
//       padding: 0px 0px;
//       font-size: 24px;
//      }
//     span{
//     position: absolute;
//     top: 8px;
//     left: 7px;
//     }
// `;

export const DropZone = styled.div`
  text-align: center;
  margin: 10px;
  padding: 10px;
  background: #ffc6c6;
  // border: 4px dashed red;
  border-radius: 10px;
`;
export const FileInput = styled(InputStyled).attrs({
  type: 'file',
  multiple: true,
})`
`;

export const FilesContainer = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin: 5px 0 20px;
`;

export const ContainerFile = styled.div`
  border: 1px solid #49a2a8;
  ${({ noStyle }) => (noStyle
    ? 'box-shadow: none'
    : 'box-shadow: 0px 1px 30px -4px rgb(55, 55, 55);')
};
  // box-shadow: 0px 1px 30px -4px rgb(55, 55, 55);
  background: #e1f1f8;
  align-items: start;
  padding: 15px;
  border-radius: 4px;
  ${({ noStyle }) => (noStyle
    ? 'margin-top: 10px;'
    : 'margin-bottom: 15px;')
};
  border: 1px solid #DDE2E9;
  position: relative;
  ::before {
     ${({ noStyle }) => noStyle
    && 'display: none;'};
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 500px;
    top: -20px;
    border: 10px solid;
    border-color: transparent #e1f0f8 #e1f0f8 transparent;
`;

export const CloseButton = styled(CommandPanelButton)`
  ${({ noStyle }) => noStyle
  && 'display: none;'};
  margin: 0;
  padding: 3px 7px;
  color: #7d7979;
  background: none;
  font-size: 17px;
  align-self: flex-start;
    :hover{
      color: black;
      background: none;
    };
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivStyledUpload = styled.div`
  margin-top: 20px;
  margin-right: 5px;
  display: flex;
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  border-radius: .3rem;
  background: white;
  min-height: 33px;
  position: relative;
  position: relative; 
  overflow: hidden; 
  width: 110px; 
  height: 20px; 
  background: white;
  border: 1px solid #a2b4e8;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  color: #5e93d0;
  border-radius: 0.3rem;
  // padding: 8px 4px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
    input[type="file"]{
      opacity: 0;
      z-index: 1;
      padding: 0px 0px;
      font-size: 24px;
     }
    span{
    position: absolute;
    top: 7px;
    left: 7px;
    }
`;

export const BoxTittle = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Form = styled.form`
  height: 100%; 
`;

export const ReadPrivateKey = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  // padding-bottom: 1em; 
`;

const StyledBoxFile = styled.div`
   display: flex;
   align-items: center;
   margin-top: 5px;
   margin-right: 5px;
   font-size: 13px;
   background: white;
   border: 1px solid #a2b4e8;
   border-radius: 0.3rem;
   padding: 4px;
   cursor: pointer;
`;

const StyledBoxFileImg = styled.img`
`;

const StyledStepItem = styled(GridItem)`
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 10px;
  margin: 10px 0;
  background: white;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
  width: 100%;
  // display: flex;
  // flex-direction: column;
`;

export const ResultContainer = styled.div`
  margin-left: 10px;
  border: 1px solid #DDE2E9;
  border-radius: 0.3em;
  padding: 10px;
  width: 100%;
`;

export const Label = styled.label`
  position: relative;
  color: #605b5b;
`;

export const StepLabel = styled(Label)`
  // color: green;
  color: #488de4;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 1.2em;
`;

export const ResultHeader = styled.div`
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  color: #605b5b;
  margin: 0.3em 0;
`;

export const ResultSubHeader = styled.div`
  font-weight: 700;
  margin: 0.7em 0;
  color: green;
`;

export const ResultItem = styled.div`
  display: flex;
  align-items: center;
`;

export function StepItem({ label, children }) {
  return (
    <StyledStepItem>
      <StepLabel>{label}</StepLabel>
      {React.Children.only(children)}
    </StyledStepItem>
  );
}

export function BoxFile({ fileName }) {
  return (
    <StyledBoxFile>
      <StyledBoxFileImg src={FileIcon} alt={fileName} />
      {fileName}
    </StyledBoxFile>
  );
}

BoxFile.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export const FileDiv = styled.div`
`;
