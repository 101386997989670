/* eslint-disable react/no-danger */
import React, { useCallback, useContext, useState } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-toastify';
import { CPButton } from './styles';
import api, { backendUri } from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';

export function HelperButton({ backendURL }) {
  const { auth } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [result, setResult] = useState(null);

  const handleClose = useCallback(() => {
    setShow(false);
    setResult(null);
  }, []);

  const handleFetch = useCallback(async (url, params = {}) => {
    const response = await api.get(url, auth, params);

    if (response.status === 200) {
      const d = await response.json();
      const decodedContent = decodeURIComponent(escape(atob(d.content)));
      setResult(decodedContent);
      setShow(true);
    } else if (response.status === 404) {
      toast.error('Нажаль, сторінки з довідником не існує.');
      setResult(null);
    }
  }, [auth]);
  const onClickHandler = useCallback(
    (e) => {
      if (e.target.tagName === 'A' && e.target.hasAttribute('href')) {
        const pageUrl = `${e.target.getAttribute('href').replace(backendUri, '')}get_html`;
        handleFetch(pageUrl);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [handleFetch],
  );
  return (
    <>
      <CPButton
        title="Довідник"
        onClick={() => handleFetch('/api/informations/pages/get_page_by_url/', { url: backendURL })}
        content="Довідник"
        icon={faQuestion}
        variant="falcon-info"
      />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Довідник</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {result && (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              onClick={onClickHandler}
              dangerouslySetInnerHTML={{ __html: result }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

HelperButton.propTypes = {
  backendURL: propTypes.string.isRequired,
};

export default HelperButton;
