import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import meta from '../../../../constants/meta';
import { useEditor } from '../../../newEditor';
import { modelName, modelType } from '../../docSelectionBoostraped/def';
import { soSelector } from '../../../documents/_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import Documents from '../../docSelectionBoostraped/tablepart';

const md = meta.dp.indicatorFact;

function DocSelectionEditorPPB({
  onLoad, CSU, year, elementKFK, FI, dataSource, budget, KPKV,
}) {
  const {
    data,
    loading,
    err,
    actions: { onSR, onChange, onErr },
  } = useEditor({
    modelType,
    modelName,
  });
  const tableData = useMemo(() => data[md.tables.data.name] || [], [data]);
  useEffect(
    () => {
      onSR('INIT', {
        [md.columns.DataSource.name]: dataSource,
        [md.columns.CSU.name]: CSU,
        [md.columns.FI.name]: FI,
        [md.columns.year.name]: year,
        [md.columns.elementKFK.name]: elementKFK,
        [md.columns.budget.name]: budget,
        [md.columns.KBP7.name]: KPKV,
      });
    },
    [CSU, FI, KPKV, budget, dataSource, elementKFK, onSR, year],
  );
  const so = useSelector(soSelector);
  const isAdmin = so.get('is_admin', false);
  const dataFi = data[md.columns.FI.name];
  const dataBudget = data[md.columns.budget.name];

  const csuFilter = useMemo(
    () => [{ fieldName: 'parent', value: dataFi }],
    [dataFi],
  );
  const elementKFKFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: dataFi }],
    [dataFi],
  );
  const BudgetOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: dataBudget,
    }],
    [dataBudget],
  );

  const onOk = useCallback(
    () => onLoad({
      documents: tableData
        .filter((d) => d[md.tables.data.columns.Check.name])
        .map((d) => d[md.tables.data.columns.Document.name]),
      csu: data[md.columns.CSU.name],
      period: data[md.columns.period.name],
    }),
    [data, onLoad, tableData],
  );
  return (
    <EditorWrapper loading={loading} onErrorDismis={() => onErr(null)} err={err}>
      <Card.Body>
        <Row>
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.FI.label}
              required
              modelType="cat"
              modelName="csu"
              value={data[md.columns.FI.name]}
              onChange={(e, value) => onChange({
                [md.columns.FI.name]: value,
                [md.columns.CSU.name]: null,
                [md.columns.elementKFK.name]: null,
              })}
            />
          </Col>
          <Col>
            <EditorControls.YearInput
              label={md.columns.year.label}
              value={data[md.columns.year.name]}
              onChange={(e, value) => onChange({
                [md.columns.year.name]: value,
              })}
            />
          </Col>
          <Col>
            <EditorControls.PeriodInput
              label={md.columns.period.label}
              required
              value={data[md.columns.period.name]}
              onChange={(e, v) => onChange({ [md.columns.period.name]: v })}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: 'end' }}>
          <Col>
            {data[md.columns.docByKBP7.name] ? (
              <EditorControls.ItemPicker
                label={md.columns.KBP7.label}
                value={data[md.columns.KBP7.name]}
                onChange={async (e, value) => {
                  await onChange({
                    [md.columns.KBP7.name]: value,
                  });
                }}
                modelType="cat"
                modelName="kbp7Classifier"
                filter={BudgetOwnerFilter}
              />
            ) : (
              <EditorControls.ItemPicker
                label={md.columns.elementKFK.label}
                modelType="cat"
                modelName="elementKFK"
                value={data[md.columns.elementKFK.name]}
                onChange={(e, value) => onChange({
                  [md.columns.elementKFK.name]: value,
                })}
                filter={elementKFKFilter}
              />
            )}
          </Col>
          {/* <Col> */}
          {/*  <EditorControls.ItemPicker */}
          {/*    label={md.columns.CSU.label} */}
          {/*    modelType="cat" */}
          {/*    modelName="csu" */}
          {/*    value={data[md.columns.CSU.name]} */}
          {/*    onChange={(e, value) => onChange({ */}
          {/*      [md.columns.CSU.name]: value, */}
          {/*    })} */}
          {/*    filter={csuFilter} */}
          {/*    filter={csuFilter} */}
          {/*    noHierarchy */}
          {/*  /> */}
          {/* </Col> */}
          {!data[md.columns.docByKBP7.name] && (
            <Col>
              <EditorControls.CheckboxInput
                controlId={`isAllKPKV-${data.id}`}
                label={md.columns.isAllKPKV.label}
                value={data[md.columns.isAllKPKV.name]}
                onChange={(e, v) => onChange({ [md.columns.isAllKPKV.name]: v })}
              />
            </Col>
          )}
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isTasks-${data.id}`}
              label={md.columns.isTasks.label}
              value={data[md.columns.isTasks.name]}
              onChange={(e, v) => onChange({ [md.columns.isTasks.name]: v })}
            />
          </Col>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isTarget-${data.id}`}
              label={md.columns.isTarget.label}
              value={data[md.columns.isTarget.name]}
              onChange={(e, v) => onChange({ [md.columns.isTarget.name]: v })}
            />
          </Col>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isDirect-${data.id}`}
              label={md.columns.isDirect.label}
              value={data[md.columns.isDirect.name]}
              onChange={(e, v) => onChange({ [md.columns.isDirect.name]: v })}
            />
          </Col>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isRegionPrograms-${data.id}`}
              label={md.columns.isRegionPrograms.label}
              value={data[md.columns.isRegionPrograms.name]}
              onChange={(e, v) => onChange({ [md.columns.isRegionPrograms.name]: v })}
            />
          </Col>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isIndicators-${data.id}`}
              label={md.columns.isIndicators.label}
              value={data[md.columns.isIndicators.name]}
              onChange={(e, v) => onChange({ [md.columns.isIndicators.name]: v })}
            />
          </Col>
          <Col>
            <EditorControls.CheckboxInput
              controlId={`isSourcesInvestmentProjects-${data.id}`}
              label={md.columns.isSourcesInvestmentProjects.label}
              value={data[md.columns.isSourcesInvestmentProjects.name]}
              onChange={(e, v) => onChange({ [md.columns.isSourcesInvestmentProjects.name]: v })}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Body className="pt-0">
        <Documents onChange={onChange} data={tableData} onSR={onSR} onOk={onOk} />
      </Card.Body>
      {!isAdmin && (
        <Card.Footer>
          <FontAwesomeIcon icon={faCity} className="me-2" />
          {data[md.columns.FI.name] && data[md.columns.FI.name].repr}
        </Card.Footer>
      )}
    </EditorWrapper>
  );
}

DocSelectionEditorPPB.propTypes = {
  onLoad: PropTypes.func.isRequired,
  CSU: referencePropType,
  year: PropTypes.number,
  FI: referencePropType,
  elementKFK: referencePropType,
  dataSource: 'Документ.ПоказателиФакт',
  budget: referencePropType,
  KPKV: referencePropType,
};

DocSelectionEditorPPB.defaultProps = {
  CSU: null,
  year: null,
  FI: null,
  elementKFK: null,
  dataSource: 'Документ.ПоказателиФакт',
  budget: null,
  KPKV: null,
};

export default DocSelectionEditorPPB;
