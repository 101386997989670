import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import CopyIcon from '../../assets/icons/copy';

function AddButton({ text, onClick, ...args }) {
  return (
    <Button onClick={onClick} {...args} title={text}>
      <CopyIcon />
    </Button>
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

AddButton.defaultProps = {
  text: 'Копіювати',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default AddButton;
