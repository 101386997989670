import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.costsKEKV;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  year,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'elementKEKV'}
          highlighted={highlights.includes('elementKEKV')}
          onToggleHighlght={() => onToggleHighlght('elementKEKV')}
        >
          {meta.columns.elementKEKV.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'k0'}
          highlighted={highlights.includes('k0')}
          onToggleHighlght={() => onToggleHighlght('k0')}
        >
          {year}
          р.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'k1'}
          highlighted={highlights.includes('k1')}
          onToggleHighlght={() => onToggleHighlght('k1')}
        >
          {year + 1}
          р.
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'k2'}
          highlighted={highlights.includes('k2')}
          onToggleHighlght={() => onToggleHighlght('k2')}
        >
          {year + 2}
          р.
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
