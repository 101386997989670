const frontendURL = '/rep/findocanalisys/';
const backendURL = '/api/financials/printform/findocanalisys/';
const name = 'Аналіз документів на фінансування';
const sections = ['executing'];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
