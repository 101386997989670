import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  byCSU: {
    label: 'За даними розпорядника рівня',
    name: 'флПоДаннымФО',
  },
};

const definition = {
  label: 'Аналіз заявок на фінансування',
  name: 'RequestAnalysis',
  columns,
  frontend: 'rep/RequestAnalysis',
  backendName: 'АнализЗаявок',
  resultColumns: baseResultColumns,
};

export default definition;
