const baseActions = [
  // Додавання вікна з такими параметрами: currentMatch, currentComponent, currentPathName, windowsName, type, name
  'ADD_COMPONENT_TO_WINDOWS_MANAGER',
  // Закриття та видалення вікна
  'DEL_COMPONENT_FROM_WINDOWS_MANAGER',
  'HIDEN_WINDOWS_MANAGER',
  'SWITCH_WINDOW',
  'UPDATE_SIGNAL',
  'SET_WINDOW_TITLE',
];
/**
 * @const
 * @property {string} ADD_COMPONENT_TO_WINDOWS_MANAGER
 * @property {string} DEL_COMPONENT_FROM_WINDOWS_MANAGER
 * @property {string} HIDEN_WINDOWS_MANAGER
 * @property {string} SWITCH_WINDOW
 * @property {string} UPDATE_SIGNAL
 * @property {string} SET_WINDOW_TITLE
 */
const listCompActionCreators = (r, a) => ({ ...r, [a]: `@listComponents_${a}` });

const windowsManagerActions = baseActions.reduce(listCompActionCreators, {});
export default windowsManagerActions;
