import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import {
  changeField, processServerReq,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/regionAssigmentsFactAnalisys';
import DateRangeField from '../../../components/fields/DateRangeField';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  CommandPanelButtonText,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
  ResultSegment, MyContainerFormWithNColumns,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer';

function ReportEditor({
  dispatch, headerForm, isProcessing, portalOpened, onReportDetails,
}) {
  const resultMountNode = useMemo(() => [`rep/${md.name}/reportEditor`, 'result'], []);
  const planTypes = useMemo(() => Object.values(enums.PlaningKind).map(
    ({ name, label }) => ({ value: name, label }),
  ), []);

  const FO = headerForm.get('FO', new Map());

  const SettingsButton = (
    <div>
      <div>
        <MyContainerFormWithNColumns columns={1}>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={planTypes}
              name="planType"
            />
          </div>
        </MyContainerFormWithNColumns>
      </div>
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Обрані поля">
          <StyledPane>
            <DCControls.Selection />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
      <div style={{ float: 'right' }}>
        <CommandPanelButtonText
          onClick={() => dispatch(processServerReq('STD_SETTINGS'))}
        >
          Встановити стандартні налаштування
        </CommandPanelButtonText>

      </div>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={resultMountNode} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onReportDetails: PropTypes.func,
  headerForm: PropTypes.instanceOf(Map).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  portalOpened: PropTypes.bool,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

ReportEditor.displayName = `Report${md.name}Editor`;

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
