import React, {
  forwardRef, memo, useEffect, useMemo, useRef, useState, useCallback, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const TextInput = forwardRef(({
  value, disabled, readOnly, size,
  onClick, onFocus, onBlur, maxLength, onChange,
  errored, rows, className,
}, ref) => {
  const [cursor, setCursor] = useState({ start: 0, end: 0 });
  const inputRef = useRef();
  // eslint-disable-next-line no-undef
  useImperativeHandle(ref, () => inputRef.current);
  const changeHandler = useCallback(
    (e) => {
      const newValue = e.target.value;
      const currentCursor = { start: e.target.selectionStart, end: e.target.selectionEnd };
      if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
        onChange(e, newValue);
        setCursor({
          start: currentCursor.start,
          end: currentCursor.start,
        });
      }
    },
    [maxLength, onChange, readOnly],
  );
  const displayValue = useMemo(
    () => value || '',
    [value],
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.setSelectionRange(cursor.start, cursor.end);
  });
  return (
    <FormControl
      className={className}
      as="textarea"
      rows={rows}
      ref={inputRef}
      value={displayValue}
      onChange={changeHandler}
      disabled={disabled}
      readOnly={readOnly}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      isInvalid={errored}
      size={size || 'sm'}

    />
  );
});

TextInput.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errored: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  size: 'sm',
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  onBlur: null,
  errored: false,
  rows: 3,
  className: '',
};

export default memo(TextInput);
