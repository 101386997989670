import base from './base';

/**
 * @const
 */

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: false },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: true },
  indicatorPassport: { label: 'Паспорт бюджетної програми', name: 'Паспорт', visible: false },
  indicatorFactLastYear: {
    label: 'Звіт за минулий період',
    name: 'ДокПоказателиФактПрошлогоПериода',
    visible: false,
  },
  isDeleteRows: { label: 'Видаляти рядки', name: 'флУдалитьСтроки', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  analize: { label: 'Узагальнений висновок про виконання бюджетної програми', name: 'ОбщийАнализ', visible: true },
  year: { label: 'Рік планування', name: 'Год', visible: false },
  isApproved: { label: 'Затверджений', name: 'ДокументУтвержден', visible: false },
  received: { label: 'Квитанція', name: 'Квитанция', visible: true },
  signatureVariants: { label: 'Варіанти підписів', name: 'Подпись', visible: false },

  deviationPlanCash5_5: {
    label: 'Пояснення щодо причин відхилення касових видатків від планового показника:',
    name: 'ОтклооненеКассы_5_5',
    visible: false,
  },
  deviationFactCash5_5: {
    label: 'Пояснення щодо причин відхилення фактичних надходжень від касових видатків:',
    name: 'ОтклФактДоход_5_5',
    visible: false,
  },
  defectionFin: { label: 'Наявність фінансових порушень:', name: 'ФинНарущения', visible: false },
  disciplineFin: { label: 'Стан фінансової дисципліни:', name: 'ФинДисциплина', visible: false },
  relevanceBp: { label: 'актуальності бюджетної програми:', name: 'Актуальность', visible: false },
  efficiencyBp: { label: 'Ефективність бюджетної програми', name: 'Эффективность', visible: true },
  degreeEfficiency: { label: 'Ступень ефективності бюджетної програми', name: 'СтепеньЭфективности', visible: false },
  usefulnessBp: { label: 'корисності бюджетної програми:', name: 'Полезность', visible: false },
  effectsBp: { label: 'довгострокових наслідків бюджетної програми:', name: 'Последствия', visible: false },

  explanationLowEffect: {
    label: 'Пояснення низької ефективності бюджетної програми',
    name: 'ОбъясненияНизкойЭфективности',
    visible: false,
  },
  KPKVCode: { label: 'Код КПКВМБ', name: 'КПКВ', visible: true },
  KFKCode: { label: 'КФК', name: 'КодКФК', visible: false },
  code: { label: 'Код', name: 'Код', visible: true },
  KBPName: { label: 'КБП', name: 'НаимКлассификаторКБП', visible: false },
  fullNameKFK: { label: 'Повна назва бюджетної програми', name: 'ПолнНаименованиеЭлементКФК', visible: true },

  isTargetedProgramsForYear: {
    label: 'Планування цільових програм за роками',
    name: 'флЦелевыеПрограммыПоГодам',
    visible: false,
  },
  changeInCosts: {
    label: 'Пояснення щодо збільшення (зменшення) обсягів проведених видатків (наданих кредитів) порівняно із аналогічними показниками попереднього року ',
    name: 'ИзменениеОбъемовЗатрат',
    visible: false,
  },
  analyzePerformanceIndicator: {
    label: 'Аналіз стану виконання результативних показників',
    name: 'АнализПоказателей',
    visible: false,
  },
  KPKV: { label: 'КПКВ', name: 'КлассификаторКБП7', visible: true },
  codClassifierKBP7: { label: 'КПКВ', name: 'КодКлассификаторКБП7', visible: true },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  c_corrTot: { label: 'Коэффіціент коригування шкали', name: 'К_КорректОбщ', visible: true },
  eficiencyBp: { label: 'Ефективність бюджетної програми', name: 'Эфективность', visible: true },
  effectivityPrint: { label: 'ПолныйРасчет', name: 'ПолныйРасчет' },
  result: { label: 'Результат', name: 'Результат' },
//  laws1: { label: 'Наказ', name: 'Приказ1', visible: false },
//  laws2: { label: 'і наказ', name: 'Приказ2', visible: false },
//  lawsStr1: { label: 'Наказ', name: 'Приказ1Стр', visible: false },
//  lawsStr2: { label: 'Наказ', name: 'Приказ2Стр', visible: false },
//  target: { label: 'Мета', name: 'Цель', visible: false },
//  sumGenF: { name: 'ЗФ3', label: 'Заг.фонд(всього) ' },
//  sumSpecF: { name: 'СФ3', label: 'Спец.фонд(всього) ' },
};

/**
 * @const
 */
const tables = {
  tasksSpent: {
    name: 'РасходыЗаданий',
    label: 'Напрями використання бюджетних коштів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      elementKFK: { label: 'Заповнювати дані з ', name: 'ЭлементКФК' },
      explanationDeviation: { label: 'Пояснення щодо причин відхилення', name: 'Пояснения' },
      explanationLowEffect: { label: 'Пояснення низької ефективності завдання', name: 'ОбъясненияНизкойЭфективности' },
      sumPlanGenF: { name: 'ПланЗФ', label: 'Заг.ф.' },
      sumPlanSpecF: { name: 'ПланСФ', label: 'Спец.ф.' },
      sumPlanTotal: { name: 'ПланСумма', label: 'Затверджено паспортом' },
      sumFactGenF: { name: 'ФактЗФ', label: 'Заг.ф.' },
      sumFactSpecF: { name: 'ФактСФ', label: 'Спец.ф.' },
      sumFactTotal: { name: 'ФактСумма', label: 'Касові видатки' },
      sumDeviationGenF: { name: 'ОтклЗФ', label: 'Заг.ф.' },
      sumDeviationSpecF: { name: 'ОтклСФ', label: 'Спец.ф.' },
      sumDeviationTotal: { name: 'ОтклСумма', label: 'Відхилення' },
      effBP: { name: 'Ефективність бюджетної програми', label: 'Эфективность' },
      stEff: { name: 'Ступінь ефективності бюджетної програми', label: 'СтепеньЭфективности' },
      c_corr: { name: 'Коэффіціент коригування шкали', label: 'К_Коррект' },
    },
  },
  tasksComparing: {
    name: 'СравнениеЗаданий',
    label: 'Виконання показників порівняно із показниками попереднього року (608)',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      explanationSpentChanges: { label: 'Пояснення щодо зміни обсягів проведених видатків за напрямом', name: 'ПоясненияОИзмЗатрат' },
      explanationIndicatorChanges: { label: 'Пояснення щодо динаміки результативних показників за напрямом', name: 'ПоясненияОИзмПоказателе' },

      sumFactLastYearGenF: { name: 'ПрФактЗФ', label: 'Попередній рік, ЗФ' },
      sumFactLastYearSpecF: { name: 'ПрФактСФ', label: 'Попередній рік, СФ' },
      sumFactLastYearTotal: { name: 'ПрФактСумма', label: 'Попередній рік, сума' },

      sumFactGenF: { name: 'ФактЗФ', label: 'Звітний рік, ЗФ' },
      sumFactSpecF: { name: 'ФактСФ', label: 'Звітний рік, СФ' },
      sumFactTotal: { name: 'ФактСумма', label: 'Звітний рік, сума' },

      sumDeviationGenF: { name: 'ОтклЗФ', label: 'Відхилення, ЗФ' },
      sumDeviationSpecF: { name: 'ОтклСФ', label: 'Відхилення, СФ' },
      sumDeviationTotal: { name: 'ОтклСумма', label: 'Відхилення, сума' },
    },
  },
  generalTPInd: {
    name: 'Основной',
    label: 'Показники',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      indicators: { label: 'Показник', name: 'Показатель' },
      typeIndicators: { label: 'Тип показника', name: 'ТипПоказателя' },
      markIndicators: { label: 'Стимул./Дестимул.', name: 'Признак' },
      units: { label: 'Одиниця виміру', name: 'ЕдИзм' },
      note: { label: 'Пояснення причин розбіжностей', name: 'Описание' },

      sumPlanGenF: { label: 'Заг.ф.', name: 'ПланЗФ' },
      sumPlanSpecF: { label: 'Спец.ф.', name: 'ПланСФ' },
      sumPlanTotal: { label: 'Затверджено за звітний період', name: 'ПланСумма' },

      sumFactGenF: { label: 'Заг.ф.', name: 'ФактЗФ' },
      sumFactSpecF: { label: 'Спец.ф.', name: 'ФактСФ' },
      sumFactTotal: { label: 'Виконано за звітний період', name: 'ФактСумма' },

      sumDeviationGenF: { label: 'Заг.ф.', name: 'ОтклЗФ' },
      sumDeviationSpecF: { label: 'Спец.ф.', name: 'ОтклСФ' },
      sumDeviationTotal: { label: 'Відхилення', name: 'ОтклСумма' },
    },
  },
  regionPrograms: {
    name: 'РегПрограммы',
    label: 'Рег.цільові програми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      targetedPrograms: { label: 'Цільові програми', name: 'ЦелевыеПрограммы' },
      elementKFK: { label: 'Заповнювати дані з', name: 'ЭлементКФК' },
      explanationDeviation: { label: 'Пояснення щодо причин відхилення', name: 'Пояснения' },

      sumPlanGenF: { label: 'Заг.ф.', name: 'ПланЗФ' },
      sumPlanSpecF: { label: 'Спец.ф.', name: 'ПланСФ' },
      sumPlanTotal: { label: 'Затверджено паспортом', name: 'ПланСумма' },

      sumFactGenF: { label: 'Заг.ф.', name: 'ФактЗФ' },
      sumFactSpecF: { label: 'Спец.ф.', name: 'ФактСФ' },
      sumFactTotal: { label: 'Виконано за звітний період', name: 'ФактСумма' },

      sumDeviationGenF: { label: 'Заг.ф.', name: 'ОтклЗФ' },
      sumDeviationSpecF: { label: 'Спец.ф.', name: 'ОтклСФ' },
      sumDeviationTotal: { label: 'Відхилення', name: 'ОтклСумма' },
    },
  },
  sourcesInvestmentProjects: {
    name: 'ИсточникиФинансированияПрограмм',
    label: 'Джерела фін.інвест.проектів/програм',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      targetedPrograms: { label: 'Цільові програми', name: 'ЦелеваяПрограмма' },
      kdbClassifier: { label: 'КДБ', name: 'КДБ' },
      kfbClassifier: { label: 'КФБ', name: 'КФБ' },
      fond: { label: 'Загальний фонд', name: 'Фонд' },
      background: { label: 'Пояснення, що характеризують джерела фінансування', name: 'ПояснениеСтр' },
      backgroundTemplate: { label: 'Шаблон пояснень', name: 'Пояснение' },
      explanationDeviation: {
        label: 'Пояснення щодо причин відхилення касових видатків на виконання інвестиційного проекту (програми)  від планового показника',
        name: 'ОтличиеКассовыхРасх',
      },

      sumResultGenF: { label: 'Заг.ф.', name: 'ЗагОбсягФин_ЗФ' },
      sumResultSpecF: { label: 'Спец.ф.', name: 'ЗагОбсягФин_СФ' },
      sumResultTotal: {
        label: 'Загальний обсяг фінансування проекту (програми), всього',
        name: 'ЗагОбсягФин_Сумма',
      },

      sumCashGenF: { label: 'Заг.фонд', name: 'ФактЗФ' },
      sumCashSpecF: { label: 'Спец.фонд', name: 'ФактСФ' },
      sumCashTotal: { label: 'Касові видатки станом на', name: 'ФактСумма' },

      sumPlanGenF: { label: 'Заг.фонд', name: 'ПланЗФ' },
      sumPlanSpecF: { label: 'Спец.фонд', name: 'ПланСФ' },
      sumPlanTotal: { label: 'Затверджено паспортом', name: 'ПланСумма' },

      sumFactGenF: { label: 'Заг.ф.', name: 'ИсполненоЗФ' },
      sumFactSpecF: { label: 'Спец.ф.', name: 'ИсполненоСФ' },
      sumFactTotal: { label: 'Виконано за звітний період', name: 'ИсполненоСумма' },

      sumPrognosisTotal: { label: 'Прогноз до кінця звітного періоду', name: 'ПрогнозСумма' },
      sumPrognosisGenF: { label: 'Заг.фонд', name: 'ПрогнозЗФ' },
      sumPrognosisSpecF: { label: 'Спец.фонд', name: 'ПрогнозСФ' },

      tasks: { label: 'Завдання', name: 'Задание' },
      constructionObject: { label: 'Об\'єкти будівництва', name: 'ОбъектыСтроительства' },
      year: { label: 'Рік', name: 'ГодПрограммы' },
    },
  },
  sourcesSpecF: {
    name: 'ВлНадхСФ',
    label: 'Джерела надходжень СФ',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      parentSourcesType: { label: 'Група', name: 'ВидыДоходовСФ' },
      sourcesType: { label: 'Показник', name: 'ВидыДоходовСФ' },
      explanation: { label: 'Пояснення', name: 'Пояснения' },
      sumPlan: { label: 'План з урахуванням змін ', name: 'План' },
      sumFact: { label: 'Виконано', name: 'Выполнено' },
      sumDeviation: { label: 'Відхилення', name: 'Отклонение' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Док. розпорядників',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      document: { label: 'Документ', name: 'Док' },
      isApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      CSU: { label: 'Виконавець', name: 'Распорядитель' },
      notes: { label: 'Примітка', name: 'Примечание' },
      author: { label: 'Автор', name: 'Автор' },
    },
  },

  explanationLowEffect: {
    name: 'РасходыЗаданий',
    label: 'Розрахунок ефективності',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      level: { label: 'Ступінь ефективності', name: 'СтепеньЭфективности' },
      effectivity: { label: 'Ефективність бюджетної програми', name: 'Эфективность' },
      koef: { label: 'Коефіціент коригування шкали', name: 'К_Коррект' },
      explanationLowEffect: { label: 'Пояснення низької ефективності напряму', name: 'ОбъясненияНизкойЭфективности' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'indicatorFact',
  backendName: 'ПоказателиФакт',
  frontend: 'doc/indicatorFact',
  label: 'Виконання бюджетної програми за показниками',
  columns,
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'codClassifierKBP7',
      'elementKFK',
      'CSU',
      'author',
      'note',
      'isApproved',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
