import React, {
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const YEAR_INPUT_MASK = '9999';

const YearPickerInput = forwardRef(
  ({
    prepend, value, onClick, disabled, id, placeholder,
    readOnly, required, tabIndex, title, errors, errorAsTooltip,
    onBlur, onChange, onFocus, onKeyDown,
    useMask, append,
  }, ref) => {
    // console.log(value);
    const errored = errors && errors.length;
    return (
      <>
        {prepend}
        {useMask ? (
          <InputMask
            mask={YEAR_INPUT_MASK}
            maskChar=" "
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          >
            {(inputProps) => (
              <FormControl
                id={id}
                ref={ref}
                isInvalid={errored}
                isValid={false}
                onClick={onClick}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                tabIndex={tabIndex}
                title={title}
                {...inputProps}
              />
            )}
          </InputMask>
        ) : (
          <FormControl
            id={id}
            ref={ref}
            isInvalid={errored}
            isValid={false}
            onClick={onClick}
            placeholder={placeholder}
            required={required}
            tabIndex={tabIndex}
            title={title}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        )}
        {append}
        {errors && (
        <FormControl.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </FormControl.Feedback>
        )}
      </>

    );
  },
);

YearPickerInput.propTypes = {
  prepend: PropTypes.node,
  append: PropTypes.node,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  useMask: PropTypes.bool,
};

YearPickerInput.defaultProps = {
  prepend: null,
  append: null,
  value: null,
  onClick: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  id: null,
  placeholder: null,
  disabled: null,
  readOnly: null,
  required: null,
  tabIndex: null,
  title: null,
  errors: null,
  errorAsTooltip: false,
  useMask: false,
};

export default YearPickerInput;
