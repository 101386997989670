import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function TPFooter({
  totals,
  activeCol,
  highlights,
  onlyYear,
  inBR,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={4}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    Sum: PropTypes.number,
    SumBR: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`Sum${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  inBR: PropTypes.bool,
};

TPFooter.defaultProps = {
  activeCol: '',
  onlyYear: false,
  inBR: false,
};

export default memo(TPFooter);
