import { CalendarContainer } from 'react-datepicker';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { childrenPropType } from '../../../constants/backend/propTypes';

const StyledButtonContainer = styled.div`
  max-width: 23rem;
  row-gap: .75rem;
`;

function getCalendarContainer({ variants, value, onChange }) {
  function CContainer({ className, children }) {
    return (
      <CalendarContainer className={className}>
        <StyledButtonContainer className="bg-white d-flex justify-content-between flex-wrap p-2 border-bottom">
          {(variants || []).map((v) => (
            <Button
              key={v.value}
              size="sm"
              variant="outline-secondary"
              active={v.value === value}
              onClick={(e) => onChange(e, v.value)}
              className="text-nowrap rounded-pill"
            >
              {v.display_name}
            </Button>
          ))}

        </StyledButtonContainer>
        <div className="position-relative">
          {children}
        </div>
      </CalendarContainer>
    );
  }
  CContainer.propTypes = {
    className: PropTypes.string.isRequired,
    children: childrenPropType.isRequired,
  };
  return CContainer;
}

export default getCalendarContainer;
