import React, {
  useMemo, useState, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { StyledField } from '../styled';
import Modal from '../../../../../components/modal';
import CheckboxField from '../../../../field/CheckBox';

function FieldsField({ value, onChange, availableFields }) {
  const displauValue = useMemo(
    () => value.filter((v) => v.get('Use'))
      .reduce((R, v) => [
        ...R,
        availableFields.getIn(['items', v.get('Field'), 'Tittle'], v.get('Field')),
      ], []).join(', '),
    [availableFields, value],
  );
  const [opened, setOpened] = useState(false);
  const displayValues = useMemo(
    () => availableFields.get('items').map((item, key) => ({
      use: value.findKey((v) => v.get('Use') && v.get('Field') === key) >= 0,
      key,
      label: item.get('Tittle'),
    })).toList().toArray(),
    [availableFields, value],
  );

  const toggleValue = useCallback(
    (e, key, v) => {
      if (v) {
        onChange(e, value.push(new Map({ Field: key, Use: true })));
      } else {
        onChange(e, value.filter((vv) => vv.get('Field') !== key));
      }
    },
    [onChange, value],
  );
  return (
    <>
      <StyledField>
        <div>{displauValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Поля, що оформлюються" closeIcon opened={opened} onClose={() => setOpened(false)} size="small">
        {displayValues.map((vv) => (
          <CheckboxField
            key={vv.key}
            onChange={(e, v) => toggleValue(e, vv.key, v)}
            value={vv.use}
            label={vv.label}
          />
        ))}
      </Modal>
    </>
  );
}

FieldsField.propTypes = {
  value: PropTypes.instanceOf(List),
  onChange: PropTypes.func.isRequired,
  availableFields: PropTypes.instanceOf(Map).isRequired,
};

FieldsField.defaultProps = {
  value: new List(),
};

export default memo(FieldsField);
