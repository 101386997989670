import React from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear, faGrinStars } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';

const StyledTh = styled.th`
    background-color: white;
    position: sticky;
    z-index: 1;
    top: 0
`;

function RelatedRegisterTable({ data }) {
  return (
    <Table striped bordered hover style={{ marginTop: '10px' }}>
      <thead>
        <tr>
          <StyledTh>Зареєстровано</StyledTh>
          <StyledTh>Дата реєстрації</StyledTh>
          <StyledTh>ЄДРПОУ</StyledTh>
          <StyledTh>КВК</StyledTh>
          <StyledTh>Назва</StyledTh>
        </tr>
      </thead>
      <tbody>
        {data.map((l) => (
          <tr key={l.EdrpouRole}>
            <td style={{
              verticalAlign: 'middle',
              textAlign: 'center',
            }}
            >
              {l.Register === '+'
                ? <FontAwesomeIcon icon={faGrinStars} size="2x" className="me-2" style={{ color: 'green' }} />
                : <FontAwesomeIcon icon={faSadTear} className="me-2" size="2x" style={{ color: 'red' }} />}
            </td>
            <td>{l.DateRegText}</td>
            <td>{l.EdrpouRole}</td>
            <td>{l.Kvk}</td>
            <td>{l.Name_l}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

RelatedRegisterTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RelatedRegisterTable;
