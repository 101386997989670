import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';

const selector = (store) => store.getIn(['auth', 'connected'], false);
function ConnectionStatus() {
  const connected = useSelector(selector);
  return (
    <div className="d-flex justify-content-center align-items-center border px-2">
      <FontAwesomeIcon icon={connected ? faLink : faUnlink} size="lg" className={connected ? 'text-success' : 'text-danger'} />
    </div>
  );
}

export default ConnectionStatus;
