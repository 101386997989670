import {
  fromJS, List, Map, OrderedMap,
} from 'immutable';

/**
 * @desc Carrying function for create data-mapper
 * @param modelDef {string}
 * @param node {Immutable.Map}
 * @return {object}
 */
export const toSrvMapper = (modelDef, node) => {
  const headerMapper = (r, v) => {
    const cyrillicPath = modelDef.columns[v].name;
    const mappedProperty = node.getIn(['headerForm', v]);

    if (mappedProperty === undefined) {
      return r;
    }

    return {
      ...r,
      [cyrillicPath]: Map.isMap(mappedProperty) ? mappedProperty.toJS() : mappedProperty,
    };
  };

  /**
   * @func
   * @param {object} mappedTables
   * @param {string} tableName
   * @return {object}
   */
  const tablesMapper = (mappedTables, tableName) => {
    /**
     * @const
     * @type {object}
     */
    const tableDef = modelDef.tables[tableName];
    /**
     * @function
     * @param {Immutable.List} r
     * @param {object} row
     * @return {Immutable.List}
     */

    const remapTable = (r, row) => [
      ...r,
      Object.keys(tableDef.columns).reduce((rr, column) => {
        const parsedValue = row.getIn([column]);
        return { ...rr, [tableDef.columns[column].name]: parsedValue };
      }, {}),
    ];
    return {
      ...mappedTables,
      [tableDef.name]: (node.get(`tables/${tableName}`) || new List()).reduce(remapTable, []),
    };
  };

  /**
   * @const
   * @type {object}
   */

  const headerForm = Object.keys(modelDef.columns).reduce(headerMapper, {});

  /**
   * @const
   * @type {object}
   */
  const tables = Object.keys(modelDef.tables || {}).reduce(tablesMapper, {});

  return { ...headerForm, ...tables };
};

export const fromSrvMapper = (modelDef, response) => {
  const headerMapper = (r, v) => {
    const cyrillicPath = modelDef.columns[v].name;
    const mappedProperty = response[cyrillicPath];

    if (mappedProperty === undefined) {
      // перевірка, що це не є реквізитом форми. isObjectForm - ознака реквізиту форми.
      if (!modelDef.columns[v].isObjectForm) {
        console.log(`[Not Found Cyrillic property '${cyrillicPath}' in ${modelDef.name} response, by '${v}']`);
      }
      return r;
    }
    return r.set(v, fromJS(mappedProperty));
  };

  /**
   * @func
   * @param {object} mappedTables
   * @param {string} tableName
   * @return {object}
   */
  const tablesMapper = (mappedTables, tableName) => {
    /**
     * @const
     * @type {object}
     */
    const tableDef = modelDef.tables[tableName];
    /**
     * @function
     * @param {Immutable.List} r
     * @param {object} row
     * @return {Immutable.List}
     */

    const remapTable = (r, row) => r.push(Object.keys(tableDef.columns).reduce((rr, column) => {
      const parsedValue = row[tableDef.columns[column].name];
      return rr.set(column, fromJS(parsedValue));
    }, new Map()));

    if (!(tableDef.name in response)) return mappedTables;
    return mappedTables.set(tableName, response[tableDef.name].reduce(remapTable, new List()));
  };

  /**
   * @const
   * @type {Immutable.Map}
   */
  const headerForm = Object.keys(modelDef.columns).reduce(headerMapper, new Map());

  /**
   * @const
   * @type {Immutable.Map}
   */
  const tables = Object.keys(modelDef.tables || {}).reduce(tablesMapper, new Map());

  return { headerForm, tables };
};

export const resultMapper = (modelDef, response) => {
  const rc = modelDef.resultColumns;

  if (!rc) return {};

  return Object.keys(rc).reduce((RR, key) => {
    const r = response[rc[key]];
    return {
      ...RR,
      [key]: !r ? new Map() : new Map({
        params: fromJS(r.params),
        styles: fromJS(r.styles),
        tables: Object.values(r.tables).reduce((R, table) => R.push(new Map({
          id: table.id,
          columns: fromJS(table.columns),
          rows: table.rows.reduce((R2, row) => R2.push(new Map({
            cells: Object.values(row.cells).reduce((R3, cell) => R3.push(fromJS(cell)), new List()),
            id: row.id,
          })), new List()),
        })), new List()),
      }),
    };
  }, {});
};

export const SELECTED_KEY = '_SELECTED';
export const TOGGLED_KEY = '_TOGGLED';
export const LEVEL_KEY = '_LEVEL';

export const generateVisibleItems = (items, oldVisibleItems = new Map()) => {
  const oldSelected = oldVisibleItems.filter((i) => i.get(SELECTED_KEY, false)).keySeq();
  const oldToggled = oldVisibleItems.filter((i) => i.get(TOGGLED_KEY, false)).keySeq();

  // const getChildItems = (item, level) => item.get('Items', new Map())
  //   .reduce((R, i, k) => R.set(k, i).merge(getChildItems(i, level + 1)))
  const isToggled = (key) => oldToggled.includes(key);
  const isSelected = (key) => oldSelected.includes(key);

  const getModifiedItem = (item, key, level = 0) => {
    const modificatior = new Map({
      ...(isSelected(key) ? { [SELECTED_KEY]: true } : {}),
      ...(isToggled(key) ? { [TOGGLED_KEY]: true } : {}),
    });
    return item.set(LEVEL_KEY, level).merge(modificatior);
  };

  // eslint-disable-next-line
  const getChildItems = (item, key, level) => isToggled(key)
  // eslint-disable-next-line
      ? getItems(item.get('Items', new Map()), level + 1)
    : new Map();

  const getItems = (i, level = 0) => i.reduce((R, item, key) => R.set(key, getModifiedItem(item, key, level))
    .merge(getChildItems(item, key, level)), new OrderedMap());

  return getItems(items);
};

export const indexTable = (t, offset = 0, parent = null) => {
  const tt = t.filter((r) => r.get('Parent') === parent);
  return tt.reduce((tbl, item, k) => {
    const childItems = indexTable(t, tbl.size + offset + 1, k);
    return tbl
      .set(k, item.set('Index', tbl.size + offset + 1))
      .merge(childItems);
  }, new OrderedMap());
};

export const dcSettingsMapper = (fieldName, response, oldValue = null) => {
  const getIndexedCollection = (collection) => new OrderedMap(collection).map((i) => fromJS(i));
  const getAvailableCollection = (DCS, section) => {
    // Если доступные поля не пришли - используем старые
    if (!DCS[section] && !!oldValue) return oldValue.getIn([section, 'items'], new OrderedMap());
    return getIndexedCollection(DCS[section]);
  };

  let GAV = new Map();
  let FAV = new Map();
  let OAV = new Map();
  let SAV = new Map();
  let CAFAV = new Map();
  let Group = new Map();
  let Filter = new Map();
  let Order = new Map();
  let Selection = new Map();
  let ConditionalAppearance = new Map();

  const proSet = (obj, parent = null) => {
    const tempO = getIndexedCollection(obj);
    const mapKeys = tempO.mapEntries(([k], i) => [k, `_${i + 1}`]);
    const compileItem = (item) => {
      // Устнаовть парент по таблице перекодировки
      let newItem = new Map();
      if (item.get('Parent') === null) {
        newItem = item;
      } else if (item.get('Parent') === '') {
        newItem = item.set('Parent', null);
      } else {
        newItem = item.set('Parent', mapKeys.get(item.get('Parent'), parent));
      }

      // Проработать таблицу
      if (newItem.get('Type', '') === 'Table') {
        // console.log('columns', newItem.get('Columns').toJS());
        // console.log(proSet(newItem.get('Columns')).toJS())
        newItem = newItem.set('Columns', proSet(newItem.get('Columns')));
        // console.log('rows');
        newItem = newItem.set('Rows', proSet(newItem.get('Rows')));
      }
      return newItem;
    };
    // console.log(tempO.mapEntries(([k, v]) => [mapKeys.get(k), compileItem(v)]).toJS());
    return indexTable(tempO.mapEntries(([k, v]) => [mapKeys.get(k), compileItem(v)]));
  };

  if (response[fieldName] !== undefined) {
    GAV = getAvailableCollection(response[fieldName], 'GroupAvailableFields');
    FAV = getAvailableCollection(response[fieldName], 'FilterAvailableFields');
    OAV = getAvailableCollection(response[fieldName], 'OrderAvailableFields');
    SAV = getAvailableCollection(response[fieldName], 'SelectionAvailableFields');
    CAFAV = getAvailableCollection(response[fieldName], 'ConditionalAppearanceFilterAvailableFields');
    Group = proSet(response[fieldName].Group);
    Filter = proSet(response[fieldName].Filter);
    Order = proSet(response[fieldName].Order);
    Selection = proSet(response[fieldName].Selection);
    ConditionalAppearance = proSet(response[fieldName].ConditionalAppearance)
      .map((cRow) => cRow.set('Filter', proSet(cRow.get('Filter', new Map()))));
  }

  return new Map({
    Group,
    Filter,
    Order,
    Selection,
    ConditionalAppearance,

    GroupAvailableFields: new Map({
      items: GAV,
      visibleItems: generateVisibleItems(GAV),
    }),

    FilterAvailableFields: new Map({
      items: FAV,
      visibleItems: generateVisibleItems(FAV),
    }),
    OrderAvailableFields: new Map({
      items: OAV,
      visibleItems: generateVisibleItems(OAV),
    }),
    SelectionAvailableFields: new Map({
      items: SAV,
      visibleItems: generateVisibleItems(SAV),
    }),
    ConditionalAppearanceFilterAvailableFields: new Map({
      items: CAFAV,
      visibleItems: generateVisibleItems(CAFAV),
    }),
  });
};
