import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FO: { label: 'ТекущийФинансовыйОрган', name: 'ТекущийФинансовыйОрган' },
  Budget: { label: 'ТекущийБюджет', name: 'ТекущийБюджет' },
  FormVariant: { label: 'Форма', name: 'Годовая' },
  KDBdetailing: { label: 'Деталізація КДБ', name: 'ПоДетализации' },
  Groups: { label: 'За групами', name: 'ПоГруппам' },
  AllClassifier: { label: 'Весь класифікатор', name: 'ПоВсем' },
  DontShowBr: { label: 'Не відображати БР', name: 'НеПоказБР' },
  Currency: { label: 'в тис. грн', name: 'ВТысГрн' },
  DontShowAdditionHeader: { label: 'Не показувати  зверху "Додаток"', name: 'БезДодаток' },
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  Symbol0: { label: 'Пусті числові значення показувати як', name: 'СимволПустогоЧисла' },
  ColumnsWidth: { label: 'Ширина колонок (з сумами)', name: 'ШиринаКолонок' },
  FormCosts: { label: 'Форма за видатками', name: 'ФормаРасходы' },
  NameSurname: { label: 'Ім\'я та ПРІЗВИЩЕ', name: 'флИмяФамилия' },
  order938: { label: 'Наказ 938', name: 'флПриказ938' },
};

// const tables = {
//   data: {
//     name: 'ТчДок',
//     label: 'Документи',
//     columns: {
//       Document: { label: 'Документ', name: 'Документ' },
//       Notes: { label: 'Примітка', name: 'Примечание' },
//     },
//   },
// };

/**
 * @const
 */
const definition = {
  name: 'assigmentPrintingRev',
  backendName: 'ПечатьДоходов',
  frontend: '',
  label: 'Друк',
  columns,
  // tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
