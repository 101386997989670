import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'Fb0vNl885gg',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  deptIn: {
    name: 'ДолгВнутренний',
    label: 'Внутрішній борг',
    columns: {
      deptInGF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнутреннийЗФ_Отчет' },
      deptInGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнутреннийЗФ_Утверждено' },
      deptInGF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План' },
      deptInGF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План1' },
      deptInGF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнутреннийЗФ_План2' },
      deptInSF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнутреннийСФ_Отчет' },
      deptInSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнутреннийСФ_Утверждено' },
      deptInSF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План' },
      deptInSF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План1' },
      deptInSF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнутреннийСФ_План2' },
    },
  },
  deptOut: {
    name: 'ДолгВнешний',
    label: 'Зовнішній борг',
    columns: {
      deptOutGF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнешнийЗФ_Отчет' },
      deptOutGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнешнийЗФ_Утверждено' },
      deptOutGF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План' },
      deptOutGF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План1' },
      deptOutGF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнешнийЗФ_План2' },
      deptOutSF_Report: { label: '20__ рік  (звіт)', name: 'ДолгВнешнийСФ_Отчет' },
      deptOutSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДолгВнешнийСФ_Утверждено' },
      deptOutSF_Plan: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План' },
      deptOutSF_Plan1: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План1' },
      deptOutSF_Plan2: { label: '20__ рік  (план)', name: 'ДолгВнешнийСФ_План2' },
    },
  },
  garantIn: {
    name: 'ГарантииВнутренний',
    label: 'Гарантії внутрішні',
    columns: {
      garantInGF_Report: { label: '20__ рік  (звіт)', name: 'ГарантииВнутреннийЗФ_Отчет' },
      garantInGF_Approved: { label: '20__ рік  (затверджено)', name: 'ГарантииВнутреннийЗФ_Утверждено' },
      garantInGF_Plan: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийЗФ_План' },
      garantInGF_Plan1: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийЗФ_План1' },
      garantInGF_Plan2: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийЗФ_План2' },
      garantInSF_Report: { label: '20__ рік  (звіт)', name: 'ГарантииВнутреннийСФ_Отчет' },
      garantInSF_Approved: { label: '20__ рік  (затверджено)', name: 'ГарантииВнутреннийСФ_Утверждено' },
      garantInSF_Plan: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийСФ_План' },
      garantInSF_Plan1: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийСФ_План1' },
      garantInSF_Plan2: { label: '20__ рік  (план)', name: 'ГарантииВнутреннийСФ_План2' },
    },
  },
  garantOut: {
    name: 'ГарантииВнешний',
    label: 'Гарантії зовнішні',
    columns: {
      garantOutGF_Report: { label: '20__ рік  (звіт)', name: 'ГарантииВнешнийЗФ_Отчет' },
      garantOutGF_Approved: { label: '20__ рік  (затверджено)', name: 'ГарантииВнешнийЗФ_Утверждено' },
      garantOutGF_Plan: { label: '20__ рік  (план)', name: 'ГарантииВнешнийЗФ_План' },
      garantOutGF_Plan1: { label: '20__ рік  (план)', name: 'ГарантииВнешнийЗФ_План1' },
      garantOutGF_Plan2: { label: '20__ рік  (план)', name: 'ГарантииВнешнийЗФ_План2' },
      garantOutSF_Report: { label: '20__ рік  (звіт)', name: 'ГарантииВнешнийСФ_Отчет' },
      garantOutSF_Approved: { label: '20__ рік  (затверджено)', name: 'ГарантииВнешнийСФ_Утверждено' },
      garantOutSF_Plan: { label: '20__ рік  (план)', name: 'ГарантииВнешнийСФ_План' },
      garantOutSF_Plan1: { label: '20__ рік  (план)', name: 'ГарантииВнешнийСФ_План1' },
      garantOutSF_Plan2: { label: '20__ рік  (план)', name: 'ГарантииВнешнийСФ_План2' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Показники гарантованого Автономною Республікою Крим, обласною радою чи територіальною громадою міста боргу і надання місцевих гарантій (Додаток 5)',
  name: 'forecastAdd5',
  backendName: 'ПрогнозДод5',
  columns,
  frontend: 'doc/forecastAdd5',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
