import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd4';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { comparisonTypes, selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'incomes';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKPKV.name]: v });
      await onSR('CHANGE_KDB_GENERAL_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const elementKFKFilter = useMemo(() => [
    { fieldName: 'Владелец', value: FI },
    {
      fieldName: 'НеДействующий',
      comparisonType: comparisonTypes.equal,
      value: false,
    },
  ], [FI]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="elementKPKV" highlighted={highlights.includes('elementKPKV')}>
              <ItemPicker
                value={row[tableMD.columns.elementKPKV.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.elementKPKV.name]: v })}
                filter={elementKFKFilter}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
                placeholder={tableMD.columns.elementKPKV.label}
              />
            </TableRowCell>
            <TableRowCell column="kekv" highlighted={highlights.includes('kekv')}>
              <ItemPicker
                value={row[tableMD.columns.kekv.name]}
                modelType="cat"
                modelName="kekvClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kekv.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                required
                placeholder={`Оберіть ${tableMD.columns.kekv.label}`}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan" highlighted={highlights.includes('incomesGF_Plan')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesGF_Add" highlighted={highlights.includes('incomesGF_Add')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Add.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Add.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesSF_Plan" highlighted={highlights.includes('incomesSF_Plan')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Add" highlighted={highlights.includes('incomesSF_Add')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Add.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Add.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan1" highlighted={highlights.includes('incomesGF_Plan1')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesGF_Add1" highlighted={highlights.includes('incomesGF_Add1')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Add1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Add1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesSF_Plan1" highlighted={highlights.includes('incomesSF_Plan1')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Add1" highlighted={highlights.includes('incomesSF_Add1')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Add1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Add1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan2" highlighted={highlights.includes('incomesGF_Plan2')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesGF_Add2" highlighted={highlights.includes('incomesGF_Add2')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Add2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Add2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesSF_Plan2" highlighted={highlights.includes('incomesSF_Plan2')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Add2" highlighted={highlights.includes('incomesSF_Add2')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Add2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Add2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.incomesSF_Add2.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan2.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Add2.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan2.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Add1.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan1.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Add1.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan1.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Add.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Add.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan.name]: PropTypes.number,
    [tableMD.columns.elementKPKV.name]: referencePropType,
    [tableMD.columns.kekv.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
