import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        md={4}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'disposer'}
          highlighted={highlights.includes('disposer')}
          onToggleHighlight={() => onToggleHighlight('disposer')}
        >
          {fields.disposer.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={4}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'kind_of_change'}
          highlighted={highlights.includes('kind_of_change')}
          onToggleHighlight={() => onToggleHighlight('kind_of_change')}
        >
          {fields.kind_of_change.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={4}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'notes'}
          highlighted={highlights.includes('notes')}
          onToggleHighlight={() => onToggleHighlight('notes')}
        >
          {fields.notes.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
