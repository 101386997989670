import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Popover, Badge, Modal, Overlay,
} from 'react-bootstrap';
import {
  NewButton, SaveButton, DeleteButton,
} from '../../components/bootStrap/buttons';
import { Selector } from '../../components/Controls';
import { EditorControls } from '../basicEditor/editorControls';
import IconAlert from '../../components/blanks/common/IconAlert';
import { CommandPanel } from '../../components/bootStrap';

function HistoryCommandPanel({
  currentStartDate, histories, historyLoading, onLoadHistories, onSelectHistory, onSaveHistory,
  onCreateHistory, onDeleteHistory,
  isNew, readOnly,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [date, setDate] = useState(new Date().toISOString());
  const deleteBtnRef = useRef();
  return isNew ? (
    <IconAlert variant="info" className="my-2 mx-1">
      Створення додаткових записів історії буде доступно після запису класифікатора
    </IconAlert>
  ) : (
    <>
      <CommandPanel
        className="border rounded"
        leftPart={(
          <>
            <Badge className="d-flex align-items-center small bg-secondary">Параметри діють в періоді</Badge>
            <Selector
              id="history_selector"
              value={currentStartDate}
              values={histories}
              isLoading={historyLoading}
              onDropDown={onLoadHistories}
              onChange={(e, v) => onSelectHistory(v)}
              disabled={isNew}
              size="sm"
            />
            <NewButton
              content="Створити нову історію"
              onClick={() => setModalOpened(true)}
              disabled={isNew || readOnly}
            />
            <SaveButton
              content="Зберегти зміни"
              onClick={() => onSaveHistory()}
              disabled={isNew || readOnly}
            />
            <DeleteButton
              content="Видалити "
              disabled={isNew || readOnly}
              ref={deleteBtnRef}
              onClick={() => setShowDeleteWarning(true)}
            />
            <Overlay
              show={showDeleteWarning}
              target={deleteBtnRef.current}
            >
              <Popover id="delete-history" placement="bottom">
                <Popover.Header className="text-danger">Ця операція незворотна</Popover.Header>
                <Popover.Body>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setShowDeleteWarning(false);
                      onDeleteHistory();
                    }}
                  >
                    Видалити
                  </Button>
                </Popover.Body>
              </Popover>
            </Overlay>
          </>
      )}
      />
      <Modal
        show={modalOpened}
        onHide={() => setModalOpened(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Створення нової історії</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorControls.DateInput
            label="Дата початку нової історії"
            required
            id="historyDate"
            value={date}
            onChange={(e, v) => setDate(v)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onCreateHistory(date);
              setModalOpened(false);
            }}
          >
            Створити
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

HistoryCommandPanel.propTypes = {
  currentStartDate: PropTypes.string,
  histories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })),
  historyLoading: PropTypes.bool,
  onLoadHistories: PropTypes.func.isRequired,
  onSaveHistory: PropTypes.func.isRequired,
  onCreateHistory: PropTypes.func.isRequired,
  onDeleteHistory: PropTypes.func.isRequired,
  onSelectHistory: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

HistoryCommandPanel.defaultProps = {
  historyLoading: false,
  currentStartDate: null,
  histories: [],
  readOnly: false,
};

export default HistoryCommandPanel;
