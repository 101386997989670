import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';
import csuMD from '../../../../../../constants/meta/catalogs/CSU';

const tableMeta = md.tables.csuByEdrpou;

function CsusByEdrpou({
  data, onOk,
}) {
  const tableData = data[tableMeta.name] || [];

  return (
    <>
      {tableData.map((catCsu, rowId) => (
        <Card
          key={catCsu[tableMeta.columns.Csu.name].id}
        >
          <Card.Header className="d-flex">
            <Card.Title className="flex-grow-1">
              <Link to={`/${csuMD.frontend}/${catCsu[tableMeta.columns.Csu.name].id}`}>
                {catCsu[tableMeta.columns.CsuName.name]}
              </Link>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Button variant="outline-success" onClick={() => onOk(rowId)}>
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              Обрати
            </Button>
          </Card.Body>
          <Card.Footer className="text-muted">
            <Card.Text>
              {catCsu[tableMeta.columns.UpperLevelCsu.name] && (
                <>
                  <FontAwesomeIcon icon={faBuilding} className="me-2" />
                  {catCsu[tableMeta.columns.UpperLevelCsu.name].repr}
                </>
              )}
            </Card.Text>
          </Card.Footer>
        </Card>
      ))}
    </>
  );
}

CsusByEdrpou.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onOk: PropTypes.func.isRequired,
};

export default CsusByEdrpou;
