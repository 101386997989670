import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CPButton as Button } from '../../Buttons';

const PotralDiv = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  background: rgba(0,0,0,.3);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PortalContainer = styled.div`
  width: fit-content;
  color: green;
  margin: 10% auto;
  background: white;
  border-radius: .3rem;
  padding: 10px 2%;
  box-shadow: 0px 1px 30px -4px rgba(58,140,194,1);
`;

const StyledButton = styled(Button)`
  margin: 1em 0;
  background: linear-gradient(180deg,#338bc0 0%,#062a53 100%);
  color: white;
  font-weight: 700;
  font-size: 14px;
  min-width: 4rem;
  justify-content: center;
  &:hover {
    background: #062a53;
  }
}  
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextQuestion = styled.h3`
`;

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portalOpened: false,
    };
  }

  render() {
    const { portalOpened } = this.state;
    const {
      questionText, actionHandler, actions, children,
    } = this.props;
    const NewButton = React.cloneElement(
      React.Children.only(children),
      { onClick: () => this.setState({ portalOpened: true }) },
    );
    return (
      <>
        {NewButton}
        {!portalOpened ? null : createPortal(
          (
            <PotralDiv>
              <PortalContainer>
                <TextQuestion>{questionText}</TextQuestion>
                <ButtonContainer>
                  {actions.map((action) => (
                    <StyledButton
                      content={action.label}
                      addText
                      key={`Action${action.value}`}
                      onClick={(e) => {
                        this.setState({ portalOpened: false });
                        actionHandler(e, action.value);
                      }}
                    />
                  ))}
                </ButtonContainer>
              </PortalContainer>
            </PotralDiv>
          ), document.body,
        )}
      </>
    );
  }
}
Question.propTypes = {
  children: PropTypes.element.isRequired,
  questionText: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  actionHandler: PropTypes.func.isRequired,
};

Question.defaultProps = {
  actions: [],
};

export default Question;
