const frontendURL = '/doc/legalObligation/';
const backendURL = '/api/obligations/lawobligationheader/';
const name = 'Юридичні зобов\'язання';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
