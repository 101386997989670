import React from 'react';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function ShowDeletedToggler({
  disabled, value, onChange, content,
}) {
  return (
    <CPButton
      onClick={(e) => onChange(e, !value)}
      title={content}
      disabled={disabled}
      variant={value ? 'falcon-warning' : 'falcon-success'}
      icon={value ? faEye : faEyeSlash}
    />
  );
}

ShowDeletedToggler.propTypes = {
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  content: PropTypes.string,
};

ShowDeletedToggler.defaultProps = {
  disabled: false,
  onChange: null,
  content: 'Показувати видаленні',
};

export default ShowDeletedToggler;
