const frontendURL = '/rep/commoninstitutionstructurebfn/';
const backendURL = '/api/bfn/commoninstitutionstructurebfn/';
const name = 'Чисельність дітей у закладах дошкільної освіти';
const sections = 'bfn';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
