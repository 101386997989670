import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Author: { label: 'Автор', name: 'Автор', visible: false },
  owner: { label: 'Бюджет', name: 'Владелец', visible: false },
  CurrentBudget: {
    label: 'ТекущийБюджет',
    name: 'ТекущийБюджет',
    visible: false,
  },
  parent: { label: 'Папка', name: '_Родитель', visible: false },
  tCode: { label: 'КПКВ на дату', name: 'тКод', visible: false },
  OnDate: { label: 'На дату', name: 'НаДату', visible: false },
  DateCl: { label: 'Дата', name: 'ДатаКл', visible: false },
  Inactive: { label: 'Не діючий', name: 'НеДействующий', visible: false },
  Classifier: { label: 'КБП', name: 'КлассификаторКБП', visible: false },
  KVK: { label: 'КВК', name: 'КлассификаторКВК', visible: false },
  StewardLevel: { label: 'Рiвень розпорядника', name: 'УровеньРаспорядителя', visible: false },
  SubProgram: { label: 'Пiдпрограма', name: 'ЭтоПодпрограмма', visible: false },
  DoNotIssuePassportBP: { label: 'Не оформляти паспорт БП', name: 'НеОформлятьПаспортБП', visible: false },
  flDoNotUseSessionDecision: { label: 'Вик. рiш. сессiї', name: 'флИспРешенииСесии', visible: false },
  FullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: false },
};

export const kbp7Classifier = {
  label: 'Кодифікатор бюджетних програм МБ (КБП) 7зн.',
  name: 'kbp7Classifier',
  backendName: 'КлассификаторКБП7',
  defaultOrder: [
    {
      column: defaultColumns.code.name,
      direction: directions.ascending,
    }],
  columns,
  frontend: 'cat/kbp7Classifier',
  listColumns: [...new Set([
    // 'tCode',
    // 'Classifier',
    ...reqListerColumns,
    'tCode',
    'Classifier',
    'Inactive',
  ])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useFoldersLazyLoad: true,
};

export default kbp7Classifier;
