import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledObject = styled.object`
  width: 100%;
  height: 100%;
`;

function PDFDriver({ href }) {
  return (
    <StyledObject
      aria-label="document"
      type="application/pdf"
      data={href}
      onDrop={(e) => e.preventDefault()}
    />
  );
}

PDFDriver.propTypes = {
  href: PropTypes.string.isRequired,
};

export default PDFDriver;
