import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const nls = tableHooks.useNumberInputProps('nls', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const ostf = tableHooks.useNumberInputProps('ostf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const socred = tableHooks.useNumberInputProps('socred', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sodeb = tableHooks.useNumberInputProps('sodeb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const oste = tableHooks.useNumberInputProps('oste', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col
      className="col-lg-12 col-md-12 d-flex flex-column justify-content-end"
    >
      <Row noGutters>

        <Col
          className={` col-lg-3 col-md-3 ${highlights.includes('nls') ? 'bg-warning' : ''}`}
          column="nls"
          highlighted={highlights.includes('nls')}
        >
          <TableControls.StringInput
            {...nls}
          />
        </Col>
        <Col
          className={` col-lg-2 col-md-2 ${highlights.includes('ostf') ? 'bg-warning' : ''}`}
          column="ostf"
          highlighted={highlights.includes('ostf')}
        >
          <TableControls.NumberInput
            {...ostf}
          />
        </Col>
        <Col
          className={` col-lg-2 col-md-2 ${highlights.includes('socred') ? 'bg-warning' : ''}`}
          column="socred"
          highlighted={highlights.includes('socred')}
        >
          <TableControls.NumberInput
            {...socred}
          />
        </Col>
        <Col
          className={` col-lg-2 col-md-2 ${highlights.includes('sodeb') ? 'bg-warning' : ''}`}
          column="sodeb"
          highlighted={highlights.includes('sodeb')}
        >
          <TableControls.NumberInput
            {...sodeb}
          />
        </Col>
        <Col
          className={` col-lg-2 col-md-2 ${highlights.includes('oste') ? 'bg-warning' : ''}`}
          column="oste"
          highlighted={highlights.includes('oste')}
        >
          <TableControls.NumberInput
            {...oste}
          />
        </Col>

      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
