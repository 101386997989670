import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
// import FillButton from '../additionalCP/fillingData';
import useTablePart from '../../../../../newEditor/hook/tablepart';
// import TPCommandPanel from './commandPanel';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import IndicatorsHeader from './header';
import IndicatorsRow from './row';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
// import CalculationButton from '../additionalCP/calculationIndicators';

function Indicators({
  onChange, data, fields, errors, readOnlyFields, readOnly,
  fromLogica,
}) {
  const {
    tableData, tableActions, pinHeader, tableContainerRef, tableReadOnlyFields, tableErrors,
    tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'executionbpindicatorrow_set', readOnlyFields, errors, fields,
  });

  // const listactivity = useMemo(
  //   () => {
  //     const actvs = (data.executionbpactivityrow_set || [])
  //       .filter((r) => r.activity)
  //       .reduce((R, r) => ({ ...R, [r.activity.id]: r.activity.repr }), {});
  //     return Object.keys(actvs).map((id) => ({ id: parseInt(id, 10), repr: actvs[id] }));
  //   },
  //   [data.executionbpactivityrow_set],
  // );
  const listIndicatorsactivity = useMemo(
    () => {
      const hasNull = !!tableData.filter((r) => !r.activity).length;
      const tdMap = tableData.map((r, rowIndex) => ({
        activity_id: r.activity ? r.activity.id : null,
        rowIndex,
      }));
      const actvs = tableData
        .filter((r) => r.activity)
        .reduce((R, r) => ({ ...R, [r.activity.id]: r.activity.repr }), {});
      return [
        ...(hasNull ? [{ id: null, repr: 'Не вказано напрям' }] : []),
        ...(Object.keys(actvs).map((id) => ({ id: parseInt(id, 10), repr: actvs[id] }))),
      ].map((activity) => ({
        ...activity,
        rows: tdMap.filter((r) => r.activity_id === activity.id).map((r) => r.rowIndex),
      }));
    },
    [tableData],
  );
  const budgetFilter = useMemo(
    () => ({
      budget: data.budget ? data.budget.id : null,
    }),
    [data.budget],
  );
  return (
    <Card className="border-0 tablePartContainer">
      <TableHeader pinHeader={pinHeader}>
        {/* <TPCommandPanel */}
        {/*  pinHeader={pinHeader} */}
        {/*  tableActions={tableActions} */}
        {/*  showFooter={showFooter} */}
        {/*  readOnly={readOnly} */}
        {/*  activeRow={activeRow} */}
        {/*  listactivity={listactivity} */}
        {/* > */}
        {/*  <FillButton onClick={() => onDraft(data, { add_indicators: true })} /> */}
        {/*  <CalculationButton onClick={() => onDraft(data, { calculate_indicators: true })} /> */}
        {/* </TPCommandPanel> */}
        <IndicatorsHeader
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {listIndicatorsactivity.map((activity, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Accordion key={i} defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>{activity.repr}</Accordion.Header>
              <Accordion.Body>
                {activity.rows.map((rowIndex) => (
                  <TableRow
                  // eslint-disable-next-line react/no-array-index-key
                    key={rowIndex}
                    rowIndex={rowIndex}
                    active={activeRow === rowIndex}
                    activeCol={activeCol}
                    onMoveRow={tableActions.onMoveRow}
                    tableName="executionbpindicatorrow_set"
                  >
                    <IndicatorsRow
                      rowIndex={rowIndex}
                      row={tableData[rowIndex]}
                      onChange={tableActions.onCellChange}
                      readOnly={readOnly}
                      highlights={highlights}
                      fields={tableFields}
                      errors={tableErrors[rowIndex]}
                      readOnlyFields={tableReadOnlyFields}
                      onToggleHighlight={tableActions.onToggleHighlightColumn}
                      fromLogica={fromLogica}
                      budgetFilter={budgetFilter}
                    />
                  </TableRow>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </Card.Body>

    </Card>
  );
}

Indicators.propTypes = {
  data: PropTypes.shape({
    executionbpactivityrow_set: PropTypes.arrayOf(PropTypes.shape()),
    executionbpindicatorrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    tableName: PropTypes.string,
    budget: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  // onDraft: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    executionbpindicatorrow_set: fieldsPropTypes,
  }),
  errors: PropTypes.shape({
    executionbpindicatorrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape(dataPropTypes),
  readOnly: PropTypes.bool,
  budgetFilter: PropTypes.shape({ budget: PropTypes.number }),
  fromLogica: PropTypes.bool.isRequired,
};

Indicators.defaultProps = {
  errors: null,
  readOnlyFields: {},
  readOnly: true,
  fields: null,
  budgetFilter: { budget: null },
};

export default Indicators;
