import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { referencePropType } from '../../newEditor/propTypes';

import { useEditor } from '../../newEditor';
import { modelName, modelType } from './def';
import md from '../../../constants/meta/dataprocessors/docSelectionForecast';

import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import Documents from './tablepart';

function DocSelectionBoostrapedForecast({
  onLoad, period, dataSource, FI, year, KBP, docCurrentType, KBP7, version, nDoc, dDoc,
  noKbpVisual, fondNetFOP, dod6FromDod7, dod67romDodP2,
}) {
  const {
    data,
    loading,
    err,
    actions: { onSR, onChange, onErr },
  } = useEditor({
    modelType,
    modelName,
  });
  const tableData = useMemo(() => data[md.tables.docs.name] || [], [data]);
  useEffect(
    () => {
      onSR('INIT', {
        [md.columns.period.name]: period,
        [md.columns.FI.name]: FI,
        [md.columns.KBP.name]: KBP,
        [md.columns.KBP7.name]: KBP7,
        [md.columns.dataSource.name]: dataSource,
        [md.columns.year.name]: year,
        [md.columns.docCurrentType.name]: docCurrentType,
        [md.columns.version.name]: version,
        [md.columns.nDoc.name]: nDoc,
        [md.columns.dDoc.name]: dDoc,
        [md.columns.noKbpVisual.name]: noKbpVisual,
        [md.columns.fondNetFOP.name]: fondNetFOP,
        [md.columns.dod6FromDod7.name]: dod6FromDod7,
        [md.columns.dod67romDodP2.name]: dod67romDodP2,
      });
    },
    [onSR, period, dataSource, FI, year, KBP, docCurrentType, KBP7, version, nDoc, dDoc,
      noKbpVisual, fondNetFOP, dod6FromDod7, dod67romDodP2],
  );

  const onOk = useCallback(
    () => onLoad({
      documents: tableData
        .filter((d) => d[md.tables.docs.columns.appr.name])
        .map((d) => d[md.tables.docs.columns.doc.name]),
    }),
    [onLoad, tableData],
  );

  const CSUFilter = useMemo(
    () => [{ fieldName: 'parent', value: FI.id }],
    [FI.id],
  );

  const kbpParams = useMemo(
    () => [
      {
        name: 'НаДату',
        value: new Date().valueOf(),
      },
    ],
    [],
  );

  return (
    <EditorWrapper loading={loading} onErrorDismis={() => onErr(null)} err={err}>
      <Card.Body>
        <Container fluid>
          <Badge variant="secondary">{version ? version.repr : ''}</Badge>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.CSU.label}
                modelType="cat"
                modelName="csu"
                value={data[md.columns.CSU.name]}
                onChange={(e, value) => onChange({
                  [md.columns.CSU.name]: value,
                })}
                filter={CSUFilter}
                noHierarchy
                canErase
              />
            </Col>
            <Col>
              <EditorControls.PeriodInput
                label={md.columns.period.label}
                required
                value={data[md.columns.period.name]}
                onChange={(e, v) => onChange({ [md.columns.period.name]: v })}
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`dod6FromDod7-${data.id}`}
                label={md.columns.dod6FromDod7.label}
                value={data[md.columns.dod6FromDod7.name]}
                onChange={(e, v) => onChange({ [md.columns.dod6FromDod7.name]: v })}
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`dod67romDodP2-${data.id}`}
                label={md.columns.dod67romDodP2.label}
                value={data[md.columns.dod67romDodP2.name]}
                onChange={(e, v) => onChange({ [md.columns.dod67romDodP2.name]: v })}
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`docCurrentType-${data.id}`}
                label={md.columns.docCurrentType.label}
                value={data[md.columns.docCurrentType.name]}
                onChange={(e, v) => onChange({ [md.columns.docCurrentType.name]: v })}
              />
            </Col>
            {!noKbpVisual && (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.KBP.label}
                  modelType="cat"
                  modelName="kbpClassifier"
                  value={data[md.columns.KBP.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.KBP.name]: value,
                  })}
                  params={kbpParams}
                  canErase
                />
              </Col>
            )}
            {!noKbpVisual && (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.KBP7.label}
                  modelType="cat"
                  modelName="kbp7Classifier"
                  value={data[md.columns.KBP7.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.KBP7.name]: value,
                  })}
                  params={kbpParams}
                  canErase
                />
              </Col>
            )}
          </Row>
        </Container>
      </Card.Body>
      <Card.Body className="pt-0">
        <Documents onChange={onChange} data={tableData} onSR={onSR} onOk={onOk} />
      </Card.Body>
    </EditorWrapper>
  );
}

DocSelectionBoostrapedForecast.propTypes = {
  onLoad: PropTypes.func.isRequired,
  period: PropTypes.number,
  FI: referencePropType,
  KBP: referencePropType,
  KBP7: referencePropType,
  version: referencePropType,
  nDoc: PropTypes.string,
  dDoc: PropTypes.number,
  year: PropTypes.number,
  docCurrentType: PropTypes.bool,
  noKbpVisual: PropTypes.bool,
  dataSource: PropTypes.oneOf([
    'Документ.ПрогнозДод6', 'Документ.ПрогнозГраничныеПоказателиРасхДод7', 'Документ.ПрогнозДод10',
    'Документ.ПрогнозДодП2', 'Документ.ПрогнозДодП3', 'Документ.ПрогнозДодП4', 'Документ.ПрогнозДодП1',
    'Документ.ПрогнозДод11', 'Документ.ПрогнозДод8', 'Документ.ПрогнозДод3', 'Документ.ПрогнозДод2',
    'Документ.ПрогнозДод12', 'Документ.ПрогнозДод1', 'Документ.МережаФОП', 'Документ.МережаПорказатели', 'Документ.МережаПорказателиПлан',
    'Документ.ИнформацияЛиквидности', 'Документ.ИнформацияЛиквидностиВерсич2',
  ]).isRequired,
  fondNetFOP: PropTypes.number,
  dod6FromDod7: PropTypes.bool,
  dod67romDodP2: PropTypes.bool,
};

DocSelectionBoostrapedForecast.defaultProps = {
  period: null,
  year: null,
  FI: null,
  KBP: null,
  KBP7: null,
  version: null,
  docCurrentType: false,
  nDoc: null,
  dDoc: null,
  noKbpVisual: false,
  fondNetFOP: 0,
  dod6FromDod7: false,
  dod67romDodP2: false,
};

export default DocSelectionBoostrapedForecast;
