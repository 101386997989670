import base from './base';

/**
 * @const
 */
const { columns } = base;

/**
 * @const
 */
const tables = {};

/**
 * @const
 */
const definition = {
  label: 'Дані, щодо чисельності дітей та структури закладу дошкільної освіти',
  name: 'bfnInstitutionStructure',
  backendName: 'bfnInstitutionStructure',
  columns,
  frontend: 'doc/bfnInstitutionStructure',
  listColumns: base.listColumns,
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
  minfinOnly: true,
};

export default definition;
