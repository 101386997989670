import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnGroupName = {
  label: 'Групи у складі дитячого закладу',
  name: 'bfnGroupName',
  backendName: 'refgroupnamebfn',
  defaultOrder,
  columns,
  frontend: 'cat/bfnGroupName',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnGroupName;
