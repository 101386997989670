import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { monthNames } from '../../../../../../constants/date';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const vt4brLabel = 'в тч БР';
const QUARTERS = [...Array(4)].map((_, i) => i);
function Header({
  fields, activeCol, highlights, onToggleHighlight,
  onlyYear, vt4br, totals,
}) {
  return (
    <TableHeaderRow className="px-4 fs--2">
      <Col
        xl={4}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kdb'}
              highlighted={highlights.includes('kdb')}
              onToggleHighlight={() => onToggleHighlight('kdb')}
            >
              {fields.kdb.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col xl={8} sm={12} className="px-1">
        <Row xl={onlyYear ? 1 : 5}>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q} className="px-1 border-right">
              {[...Array(3)].map((_, m) => (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={vt4br ? 1 : 1}>
                  <Col className="text-center">
                    <TableHeaderCell
                      active={activeCol === `amount_${q * 3 + m + 1}`}
                      title={totals[`amount_${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`amount_${q * 3 + m + 1}`)}
                      onToggleHighlight={() => onToggleHighlight(`amount_${q * 3 + m + 1}`)}
                    >
                      {monthNames[q * 3 + m]}
                    </TableHeaderCell>
                  </Col>
                  {vt4br && (
                  <Col className="text-center font-italic">
                    <TableHeaderCell
                      active={activeCol === `amount_br_${q * 3 + m + 1}`}
                      title={totals[`amount_br_${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`amount_br_${q * 3 + m + 1}`)}
                      onToggleHighlight={() => onToggleHighlight(`amount_br_${q * 3 + m + 1}`)}
                    >
                      {vt4brLabel}
                    </TableHeaderCell>
                  </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'amount_total' && onlyYear}
              title={totals.amount_total.toLocaleString('uk', moneysStingOptions)}
              canHighlight={onlyYear}
              highlighted={onlyYear && highlights.includes('amount_total')}
              onToggleHighlight={() => onToggleHighlight('amount_total')}
            >
              {fields.amount_total.label}
            </TableHeaderCell>
            {vt4br && (
            <TableHeaderCell
              className="text-right font-italic text-sm-center w-100"
              active={activeCol === 'amount_br_total' && onlyYear}
              title={totals.amount_br_total.toLocaleString('uk', moneysStingOptions)}
              canHighlight={onlyYear}
              highlighted={onlyYear && highlights.includes('amount_br_total')}
              onToggleHighlight={() => onToggleHighlight('amount_br_total')}
            >
              {vt4brLabel}
            </TableHeaderCell>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_total: PropTypes.number,
    amount_br_total: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`amount_${i + 1}`]: PropTypes.number,
      [`amount_br_${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
};

Header.defaultProps = {
  activeCol: null,
  onlyYear: false,
  vt4br: false,
};

export default Header;
