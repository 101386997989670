import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { SModal } from '../../../../basicEditor/stdFilters/style';
import FitlerEditor from './filterEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';
import { CPButton } from '../../../../../components/bootStrap/buttons';

const levelTypes = {
  low: 'Заповнити з даних розпорядника нижнього рівня',
  high: 'Заповнити з даних розпорядника верхнього рівня',
};

function LoadDocsButton({
  data, onDraft,
  disabled,
}) {
  const {
    authority, fond, doc_date: docDate, plan_kind: planKind, year,
  } = data;
  const [opened, setOpened] = useState(false);
  const [lowLevel, setLowLevel] = useState('low');
  const overrideFilter = useMemo(
    () => ({
      ...(lowLevel === 'low' ? { authority_parent: authority } : { disposer: authority }),
      fond,
      year,
      plan_kind: planKind,
    }),
    [authority, fond, lowLevel, planKind, year],
  );

  const defaultFilter = useMemo(() => ({
    begin_date: docDate,
    end_date: docDate,
    approved: false,
    executed: true,
  }), [docDate]);

  const onLoadHandler = useCallback((rows, disposer) => onDraft(
    data,
    {
      load_leveldown_allcplnchng_rows: {
        rows,
        disposer,
        clear: true,
        low_level: lowLevel === 'low',
      },
    },
  ), [data, lowLevel, onDraft]);

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <CPButton
          onClick={() => setOpened(true)}
          content={levelTypes[lowLevel]}
          icon={faFileImport}
          disabled={disabled}
        />
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" disabled={disabled} />
        <Dropdown.Menu>
          {Object.keys(levelTypes).map((item) => (
            <Dropdown.Item
              key={item}
              onClick={() => {
                setLowLevel(item);
                setOpened(true);
              }}
            >
              {levelTypes[item]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FitlerEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          lowLevel={lowLevel === 'low'}
          onOk={(ids, disposer) => {
            onLoadHandler(ids, disposer);
            setOpened(false);
          }}
        />
      </SModal>
    </>
  );
}

LoadDocsButton.propTypes = {
  data: PropTypes.shape({
    authority: foreignPropType,
    fond: foreignPropType,
    doc_date: PropTypes.string,
    year: PropTypes.number,
    plan_kind: PropTypes.number,
  }),
  onDraft: PropTypes.func,
  disabled: PropTypes.bool,
};

LoadDocsButton.defaultProps = {
  data: {},
  onDraft: null,
  disabled: false,
};

export default LoadDocsButton;
