import React, { memo } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { faQuestion as icon } from '@fortawesome/free-solid-svg-icons';

import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function YoutubeButton({ videoId, onClick = '' }) {
  return (videoId !== '') ? (
    <ButtonGroup>
      <DefaultCPButton
        icon={icon}
        onClick={onClick}
        label="Навчальні матеріали"
      />
    </ButtonGroup>
  )
    : <></>;
}

export default memo(YoutubeButton);
