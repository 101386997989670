import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  label: 'Класифікатор приміток',
  name: 'КлассификаторПримечаний',
  ...defaultColumns,
  FullName: {
    label: 'Повне найменування',
    name: 'ПолноеНаименование',
    visible: true,
  },
};

export const noteClassifier = {
  label: 'Клиссифiкатор примiток',
  name: 'NoteClassifier',
  backendName: 'КлассификаторПримечаний',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/noteClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'FullName',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default noteClassifier;
