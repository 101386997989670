/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card } from 'react-bootstrap';

import TPHeader from './header';
import TPRow from './row';

import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import md from '../../../../../../constants/meta/documents/limitReference';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';

const tableMeta = md.tables.KPKVMB;

function KPKVMBTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const FI = data[md.columns.FI.name];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <Alert variant="info">
          Оберіть КПКВМБ, по яких заповнити дані
        </Alert>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          <Button
            variant="primary"
            onClick={() => actions.onSR('FILL_KPKVMB')}
            className="me-1"
            size="sm"
          >
            Отримати КПКВМБ
          </Button>
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            onSR={actions.onSR}
            readOnly={readOnly}
            highlights={highlights}
            FI={FI}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader} />
    </Card>
  );
}

KPKVMBTP.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

KPKVMBTP.defaultProps = {
  readOnly: false,
};

export default memo(KPKVMBTP);
