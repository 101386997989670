import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnPeriod = {
  label: 'Період обрахунку БФН',
  name: 'bfnPeriod',
  backendName: 'refperiodbfn',
  defaultOrder,
  columns,
  frontend: 'cat/bfnPeriod',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnPeriod;
