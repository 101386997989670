import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import styled from 'styled-components';
import SelectorField from '../../../../field/stdSelectorField';
import GridTemplate from './GridTemplate';
import GetNumberInput from '../../../../field/NumberInput';
import { editor as actions } from '../../../../../actions';
import Fields from '../../../../field';
import { processServerReq } from '../../../../../actions/editor';
import { DivCell } from '../../../../../components/styledTable/styledTable';

const TABLENAME = 'general';

const SumInputStyled = styled(GetNumberInput(13, 2))`
  padding: 0.08em;
  margin-right: 0.2em;
  grid-area: ${(props) => props.areaName};
`;

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    const TextInput10 = Fields.GetTextInput(10);
    const TextInput0 = Fields.GetTextInput(0);
    return (
      <GridTemplate>
        <DivCell areaName="projectPublicBudget">
          <SelectorField
            noBorder
            noBackground
            modelName="projectPublicBudget"
            modelType="cat"
            value={row.get('projectPublicBudget', new Map())}
            onChange={(e, v) => {
              dispatch(actions.changeField([`tables/${TABLENAME}`, rowId, 'projectPublicBudget'], v));
              dispatch(processServerReq('CHANGE_PUBLIC_BUDGET', { rowId }));
            }}
          />
        </DivCell>
        <DivCell areaName="id">
          <TextInput10
            noBorder
            value={row.get('id', '')}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'id'], value));
            }}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInputStyled
            fluid
            value={row.get('sum', 0)}
            onChange={(e, v) => {
              dispatch(actions.changeField([`tables/${TABLENAME}`, rowId, 'sum'], v));
            }}
          />
        </DivCell>
        <DivCell areaName="noteDanger">
          <TextInput0
            noBorder
            value={row.get('noteDanger', '')}
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'noteDanger'], value));
            }}
          />
        </DivCell>
        <DivCell areaName="publicBudgetProjectAuthor">
          <SelectorField
            noBorder
            noBackground
            modelName="publicBudgetProjectAuthor"
            modelType="cat"
            value={row.get('publicBudgetProjectAuthor', new Map())}
            onChange={(e, v) => {
              dispatch(actions.changeField([`tables/${TABLENAME}`, rowId, 'publicBudgetProjectAuthor'], v));
            }}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
