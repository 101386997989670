import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FillButton from '../additionalCP/fillingData';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function UsingBudgetFunds({
  onDraft, data, fromLogica, onChange, ...rest
}) {
  const onChangeTP = useCallback((f) => {
    const sumChanger = (oldData) => oldData.passportbpusingbudgetfundsrow_set.map((row) => ({
      ...row,
      amount_zf: (row.amount_zf || 0),
      amount_sf: (row.amount_sf || 0),
      amount_br: (row.amount_sf < (row.amount_br || 0)) ? 0 : row.amount_br,
      amount_total: (row.amount_zf || 0) + (row.amount_sf || 0),
    }));
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
        passportbpusingbudgetfundsrow_set: sumChanger(d),
      };
    });
  }, [onChange]);

  const template = useMemo(
    () => {
      const sumColProps = {
        xxl: 2,
        xl: 2,
        lg: 6,
        sm: 12,
      };
      const activityColProps = {
        xxl: 6,
        xl: 5,
        lg: 6,
        sm: 12,
      };

      const sumcolumns = [
        {
          field: 'amount_zf',
          colProps: sumColProps,
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_sf',
            },
            {
              colProps: { xs: 12 },
              field: 'amount_br',
            },
          ],
        },
        {
          field: 'amount_total',
          controlProps: {
            readOnly: true,
          },
        },
      ];
      if (!fromLogica) {
        return {
          columns: [
            {
              field: 'activity',
              controlProps: {
                typeOfChoice: typesOfChoices.onlyElements,
                filter: {
                  budget: data.budget ? data.budget.id : null,
                },
              },
              colProps: activityColProps,
            },
            ...sumcolumns,
          ],
        };
      }
      return {
        columns: [
          {
            field: 'activity_text',
            colProps: activityColProps,
          },
          ...sumcolumns,
        ],
      };
    },
    [data.budget, fromLogica],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="passportbpusingbudgetfundsrow_set"
      onChange={onChangeTP}
      addCommands={(
        <FillButton
          onClick={() => onDraft(data, { add_activity: true })}
        />
      )}
    />
  );
}

UsingBudgetFunds.propTypes = {
  data: PropTypes.shape({
    passportbpusingbudgetfundsrow_set: PropTypes.arrayOf(PropTypes.shape({})),
    budget: foreignPropType,
  }).isRequired,
  onDraft: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fromLogica: PropTypes.bool.isRequired,
};

export default UsingBudgetFunds;
