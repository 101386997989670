import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/treasureOffer';
import {
  ResultSegment,
  StyledLabel,
  FiveColumnsGridContainer,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel, ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';
import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';
import Tabs from '../../../components/tabs/Tabs';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';
import DateRangeField from '../../../components/fields/DateRangeField';
import DateInput from '../../../components/fields/dates';

const FiveColGridContainer = styled(FiveColumnsGridContainer)`
  grid-template-columns: 15% 23% 23% 15% 19%;
`;

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const reportVeriant = [
    { name: 1, label: 'Додаток 1' },
    { name: 2, label: 'Додаток 2' },
    { name: 5, label: 'Додаток 5' },
    { name: 6, label: 'Додаток 6' },
    { name: 6, label: 'Додаток 6.1' },
    { name: 20, label: 'Помісячний розпис (з КДБ)' },
    { name: 21, label: 'Річний розпис витрат (з КДБ)' },
  ];

  const typeReport = [
    { name: 0, label: 'Розпис' },
    { name: 1, label: 'Уточнення' },
    { name: 2, label: 'Розпис + Уточнення' },
  ];

  const dataSel = [
    { name: 0, label: 'Доходами' },
    { name: 1, label: 'Видатками' },
    { name: 2, label: 'Джерелами фінансування' },
  ];

  const formType2 = [
    { name: 0, label: 'Річна' },
    { name: 1, label: 'Помісячна' },
  ];

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
      </ContainerThreeColumn>
      <FiveColGridContainer>
        <div>
          <StyledLabel>{md.columns.planinKind.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('planinKind', false)}
            options={Object.values(enums.PlaningKind)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planinKind'], v))}
          />
        </div>

        <div>
          <StyledLabel>{md.columns.typeReport.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('typeReport')}
            options={typeReport}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'typeReport'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.reportVeriant.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('reportVeriant')}
            options={reportVeriant}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportVeriant'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.dataSel.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('dataSel')}
            options={dataSel}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'dataSel'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.formType2.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('formType2')}
            options={formType2}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'formType2'], v))}
          />
        </div>

      </FiveColGridContainer>
      <FiveColGridContainer>
        <div>
          <StyledLabel>{md.columns.date1.label}</StyledLabel>
          <DateInput
            value={headerForm.get('date1', null)}
            onChange={(e, value) => dispatch(changeField(['headerForm', 'date1'], value))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.date2.label}</StyledLabel>
          <DateInput
            value={headerForm.get('date2', null)}
            onChange={(e, value) => dispatch(changeField(['headerForm', 'date2'], value))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('in1000', false)}
            label={md.columns.in1000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('KEKVsh', false)}
            label={md.columns.KEKVsh.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'KEKVsh'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('inBR', false)}
            label={md.columns.inBR.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'inBR'], v))}
          />
        </div>
      </FiveColGridContainer>

      {/* {Bookmarks} */}

    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel style={{ marginBottom: '6px' }}>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            filter={[
              { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
