import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import {
  changeField, processServerReq,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/countContractsTenders';
import Fields from '../../field';
import GetNumberField from '../../field/NumberInput';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import ItemField from '../../field/selectorField/item/itemField';
import DateInput from '../../../components/fields/dates/dateInput';
import ReportContainer from '../reportContainer';

const YearInput = GetNumberField(4, 0, false);

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const TwoColGridContainer = styled(TwoColumnsGridContainer)`
  margin-top: 10px;
  align-items: end;
`;

class ReportCountContractsTendersEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    sessionOptions: new Map(),
    portalOpened: false,
    onReportDetails: null,
  };

  constructor(props) {
    super(props);
    const { sessionOptions } = this.props;
    this.currentFO = sessionOptions.get('fo', new Map());
  }

  detailsReport = (e, contentCell) => {
    if ((typeof contentCell) === 'object') {
      const idFO = contentCell.get('id', undefined);
      if (idFO !== undefined) {
        const { dispatch } = this.props;

        dispatch(changeField(['headerForm', 'FO'], contentCell));

        dispatch(processServerReq('GENERATE'));
      }
    }
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );

    const SettingsButton = (
      <div>
        <TwoColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>

        </TwoColumnsGridContainer>
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        <TwoColGridContainer>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateInput
              value={headerForm.get('period', null)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.year.label}</StyledLabel>
            <YearInput
              border
              radius
              value={headerForm.get('year', 5)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
            />
          </div>

        </TwoColGridContainer>

        <ResultSegment>
          <TableEditor
            mountNode={[`rep/${md.name}/reportEditor`, 'result']}
            detailsReport={this.detailsReport}
          />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
      autoExecute: true,
    },
  ),
);

export default enhance(ReportCountContractsTendersEditor);
