import GroupManagement from './GroupManagement';
import LogikaLogin from './LogikaLogin';
import RoleManagement from './RoleManagement';
import ImportTreasureFiles from './ImportTreasureFiles';
import ImportPlans from './ImportPlans';
import ImportPassports from './ImportPassports';
import ImportExecution from './ImportExecution';
import SKDMaster from './SKDMaster';
import DocumentsInLogica from './DocumentsInlogica';
import ImportDataFromLogica from './ImportDataFromLogica';
import ForecastsDashBoard from './ForecastsDashBoard';
import ForecastsWizard from './ForecastsWizard';
import MDEditor from './MDEditor';
import StepByStep from './StepByStep';
import ImportLiquidity from './ImportLiquidity';
import ChiefDashboard from './ChiefDashboard';
import OpenBudget from './openBudget';
import Exchange from './Exchange';
import TreasureExport from './treasureExport';
import ImportFRFZfromLogica from './ImportFRFZfromLogica';
import ExportChangesToLogica from './ExportChangesToLogica';
import LogicaCalendar from './LogicaCalendar';
// import User from '../cat/user';

const srv = {
  ...Exchange,
  LogikaLogin,
  groups: GroupManagement,
  role: RoleManagement,
  SKDMaster,
  ImportTreasureFiles,
  // ImportPlans,
  // ImportPassports,
  DocumentsInLogica,
  ImportDataFromLogica,
  ForecastsDashBoard,
  MDEditor,
  ForecastsWizard,
  StepByStep,
  ChiefDashboard,
  OpenBudget,
  TreasureExport,
  ImportFRFZfromLogica,
  ExportChangesToLogica,
  LogicaCalendar,
  // ImportLiquidity,
  // user: User,
};
//   logica: {
//     name: 'Робота з Логіка',
//     items: {
//       Login,
//       ImportTreasureFiles,
//     },
//   },
//   dbf: {
//     name: 'Завантаження файлів *.dbf',
//     items: {
//       ExchangePlan,
//       ExchangeIncome,
//       ExchangeExpenses,
//       ExchangeSocialPayment,
//       TreasureExport,
//     },
//   },
//   auth: {
//     name: 'Доступ до системи',
//     items: {
//       User,
//       RoleManagement,
//       GroupManagement,
//     },
//   },
//   rep: {
//     name: 'Звітність',
//     items: {
//       SKDMaster,
//     },
//   },
// };
export const importDocs = {
  plans: ImportPlans,
  passport: ImportPassports,
  liquidity: ImportLiquidity,
  execution: ImportExecution,

};

export default srv;
