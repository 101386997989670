import { Map, OrderedSet, OrderedMap } from 'immutable';

const makeReducer = (key, _form, modelType) =>
/**
   * @param {Immutable.Map} state
   * @param {object} action
   * @param {string} action.type
   * @param {any} action.payload
   * @return {Immutable.Map}
   */

  (state = new Map({}), { type, payload = null }) => {
    // Каждое действие начинается с @ и названия узла стора
    const thisReducer = `${modelType}/${key}/${_form}`;

    // Проверяем соответствие узла

    const areThisReducer = type.slice(1).startsWith(thisReducer);
    if (!areThisReducer) {
      // Если узел чужой - вернем состояние узла без изменений
      return state;
    }

    // Uncomment for debug lister actions :)
    // Functional programming & curring functions are fucking beautiful !
    // console.log(
    //   `ACTION ${type.slice(thisReducer.length + 2)} for ${thisReducer} RAISED`
    // );

    // Перед проверкой обрезаем всё, кроме описания действия
    switch (type.slice(thisReducer.length + 2)) {
      case 'INIT':
        return state.clear().merge(payload);
      case 'CLEAR_ITEMS':
        return state
          .set('items', new OrderedMap())
          .set('visibleItems', new OrderedMap());
      case 'ALL_ITEMS_LOADED':
        return state
          .set('allItemsLoaded', payload);
      case 'LOAD_START':
        return state
          .set('isLoading', true)
          .set('elementsRange', payload.elementsRange);
      case 'LOAD_DONE':
        return state.set('isLoading', false)
          .set('items', payload.items)
          .set('visibleItems', payload.visibleItems)
          .set('initialValue', payload.initialValue)
          .set('hasError', false)
          .set('errorMsg', null);
      case 'LOAD_ERROR':
        return state.set('isLoading', false)
          .set('hasError', true)
          .set('errorMsg', payload);
      case 'SET_FILTERS':
      // payload type {Immutable.Map}
        return state.set('filter', payload);
      case 'SEARCH':
        return state.set('search', payload.text);
      case 'SEARCH_RESULT':
        return state.set('visibleItems', payload);
      case 'CHANGE_PAGE':
        return state.set('page', payload.pageNumber)
          .set('visibleItems', payload.items);
      case 'LOCK_START':
        return state.set('isProcessing', true);
      case 'LOCK_DONE':
        return state.set('isProcessing', false)
          .set('lockedList', payload);
      case 'CLEAR_RESULT_NODE':
        return state
          .delete('result');
      case 'LOCK_ERROR':
        return state.set('isProcessing', false)
          .set('errorMsg', payload);
      case 'UNLOCK_START':
      // Поставить флаг обработки
        return state.set('isProcessing', true);
      case 'UNLOCK_DONE':
      // Снять флаг обработки
      // Удалить все заблокированные объекты
        return state.set('isProcessing', false)
          .set('lockedList', new OrderedSet());
      case 'UNLOCK_ERROR':
        return state.set('isProcessing', false)
          .set('errorMsg', payload);
      case 'REMOVE_START':
        return state.set('isProcessing', true);
      case 'REMOVE_DONE':
        return state
          .set('isProcessing', false)
          .set('hasError', true)
          .set('errorMsg', payload);
      case 'REMOVE_ERROR':
        return state.set('isProcessing', false)
          .set('hasError', true)
          .set('errorMsg', payload);

      case 'SAVE_START':
        return state.set('isProcessing', true);
      case 'SAVE_DONE':
        return state
          .set('isProcessing', false)
          .set('hasError', true)
          .set('errorMsg', payload);
      case 'SAVE_ERROR':
        return state.set('isProcessing', false)
          .set('hasError', true)
          .set('errorMsg', payload);

      case 'SELECT':
        return state
          .set('visibleItems', payload.visibleItems);
      case 'DESELECT':
        return state.removeIn([...payload, 'ACTIVE']);
      case 'TOGGLE':
        return state
          .set('visibleItems', payload.visibleItems);
      case 'TOGGLE_SHOW_DELETED':
        return state
          .set('showDeleted', payload.showDeleted);
      case 'SET_ORDER':
        return state
          .set('order', payload.order);
      case 'SET_PERIOD':
        return state
          .set('period', payload.period);
      case 'RESIZE_COLUMN':
        return state
          .set('columnSizes', payload.columnSizes);
      case 'SET_PARAMS':
        return state
          .set('params', payload);
      case 'SETTINGS_LOAD_START':
        return state
          .set('isProcessing', true);
      case 'SETTINGS_LOAD_DONE':
        return state
          .merge(payload)
          .set('isProcessing', false)
          .set('readyToLoad', true);
      case 'SETTINGS_LOAD_ERROR':
        return state.set('isLoading', false)
          .set('hasError', true)
          .set('errorMsg', payload);
      default:
        return state;
    }
  };
const createListerReducer = (modelType, o, _form = 'lister') => Object.keys(o)
  .reduce((r, key) => ({
    ...r,
    [`${modelType}/${key}/${_form}`]: makeReducer(key, _form, modelType),
  }), {});

export default createListerReducer;
