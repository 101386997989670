import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from './propTypes';

function Header({
  activeCol, highlights, onToggleHighlight, totals, fields,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={3}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'kdb'}
          highlighted={highlights.includes('kdb')}
          onToggleHighlight={() => onToggleHighlight('kdb')}
        >
          {fields.kdb.label}
        </TableHeaderCell>
      </Col>
      <Col sm={3}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'person_budget'}
          highlighted={highlights.includes('person_budget')}
          onToggleHighlight={() => onToggleHighlight('person_budget')}
        >
          {fields.person_budget.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          title={totals.zag_fond}
          className="text-center text-xl-left"
          active={activeCol === 'zag_fond'}
          highlighted={highlights.includes('zag_fond')}
          onToggleHighlight={() => onToggleHighlight('zag_fond')}
        >
          {fields.zag_fond.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          title={totals.spec_fond}
          className="text-center text-xl-left"
          active={activeCol === 'spec_fond'}
          highlighted={highlights.includes('spec_fond')}
          onToggleHighlight={() => onToggleHighlight('spec_fond')}
        >
          {fields.spec_fond.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          title={totals.total}
          className="text-center text-xl-left"
          active={activeCol === 'total'}
          highlighted={highlights.includes('total')}
          onToggleHighlight={() => onToggleHighlight('total')}
        >
          Загальна сума
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    spec_fond: PropTypes.number,
    zag_fond: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  fields: fieldsPropTypes.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
