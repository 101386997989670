/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import CommandPanel from './commandpanel';
import ListerSelector from './lister_selector';
import { modelType, modelName } from '../def';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'selector',
})(CatTableListerFactory({
  CommandPanel,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
