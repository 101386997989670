import React, { useCallback } from 'react';
// import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Col, Row } from 'react-bootstrap';

// import { useHistory } from 'react-router';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/taxes';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { ContainerSt } from '../../../../components/Form/styledForm';
import { StampApprovedJs } from '../../../../assets/icons';
// import { emptyUid } from '../../../../constants/meta/common';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import DocSelectionModal from './docLoading/docSelectionModal';

function Taxes({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);

  const readOnly = sessionOptions.get('is_admin', false) ? !permissions.canChange : !permissions.canChange || data[md.columns.isApproved.name];

  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );
  // const navigate = useNavigate();
  //
  // const logikaId = useMemo(
  //   () => data[md.columns.docSendToLogica.name] && data[md.columns.docSendToLogica.name].id,
  //   [data],
  // );
  //
  // const docId = useMemo(
  //   () => data[md.columns.id.name],
  //   [data],
  // );
  // const dispatch = useDispatch();

  // useEffect(
  //   () => {
  //     // const modelType = 'doc';
  //     // const modelName = 'taxes';
  //     if (logikaId && logikaId !== emptyUid) {
  //       // dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));
  //
  //       navigate({
  //         pathname: `/doc/docTransferToLogica/${logikaId}/`,
  //       });
  //     }
  //   },
  //   [dispatch, docId, navigate, logikaId],
  // );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        {/* <Col> */}
        {/*  <EditorControls.ItemPicker */}
        {/*    label={md.columns.signVariant.label} */}
        {/*    value={data[md.columns.signVariant.name]} */}
        {/*    onChange={(e, value) => actions.onChange({ */}
        {/*      [md.columns.signVariant.name]: value, */}
        {/*    })} */}
        {/*    modelType="cat" */}
        {/*    modelName="signatureVariants" */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        {/* </Col> */}
        {/* <Col> */}
        {/*  <EditorControls.CheckboxInput */}
        {/*    controlId={`isApproved-${data.id}`} */}
        {/*    label={md.columns.isApproved.label} */}
        {/*    value={data[md.columns.isApproved.name]} */}
        {/*    onChange={(e, value) => actions.onChange({ */}
        {/*      [md.columns.isApproved.name]: value, */}
        {/*    })} */}
        {/*    readOnly={readOnly} */}
        {/*  /> */}
        {/* </Col> */}
        {data[md.columns.isApproved.name] && (
          <Col style={{ zIndex: '1' }}>
            <ContainerSt>
              <StampApprovedJs text={data[md.columns.invoice.name]} />
            </ContainerSt>
            {sessionOptions.get('is_admin', false) && (
              <EditorControls.StringInput
                label={md.columns.invoice.label}
                value={data[md.columns.invoice.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.invoice.name]: value,
                })}
              />
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.area.label}
            value={data[md.columns.area.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.area.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.disrtict.label}
            value={data[md.columns.disrtict.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.disrtict.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.KATOTTG.label}
            value={data[md.columns.KATOTTG.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KATOTTG.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={async (e, v) => {
              await actions.onChange({ [md.columns.name.name]: v });
              await actions.onSR('CHANGE_KOATUU_MS');
            }}
            modelType="cat"
            modelName="koatuu"
          />
        </Col>
        <Col>
          <ButtonGroup>
            <DefaultCPButton
              variant="info"
              label="Заповнити КАТОТТГ та назву для додатків"
              onClick={() => {
                actions.onSR('FillFromFI');
              }}
            />
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
            onSR={actions.onSR}
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

Taxes.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Taxes;
