import React, {
  useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
// import { useDropzone } from 'react-dropzone';
// import Flex from '../../../../components/blanks/common/Flex';
// import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import { Control } from '../../../newEditor/hook/control';
import api from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';
import downloadXls from '../../../../utils/downloadXls';
import statImg from '../../../../assets/img/stat';
import AdvanceReactTable from '../../../../utils/advanceTable';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { Loader } from '../../../../components/bootStrap';
import ReadFile from './readFile';

const BACKENDURL = meta.cat.ExternalSource.backendURL;

// function Files({ fileInfo }) {
//   return (
//     <div>
//       {fileInfo.path}
//       {' '}
//       -
//       {fileInfo.size}
//       {' '}
//       bytes
//     </div>
//   );
// }

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions,
    nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerProps,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const [err, setError] = useState(null);
  // const [fileInfo, setFileInfo] = useState(null);
  const { auth } = useContext(CiatAppContext);

  // const onDrop = useCallback((acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   const reader = new FileReader();
  //   setFileInfo({
  //     path: file.path,
  //     size: file.size,
  //   });
  //
  //   reader.onload = (event) => {
  //     const fileContents = event.target.result;
  //     actions.onChange(
  //       () => (
  //         {
  //           skd_schema: fileContents,
  //         }),
  //     );
  //     // document.getElementById('output').textContent = reader.result;
  //   };
  //
  //   reader.onerror = () => {
  //     setError(reader.error);
  //   };
  //   reader.readAsText(file);
  // }, [actions]);

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  //   accept: '.xml',
  //   maxFiles: 1,
  // });

  const getResult = useCallback(async () => {
    if (actions) {
      actions.onLoading(true);
    }
    const params = {
      id: (data?.id) ? data.id : '',
    };
    const response = await api.post('/api/external/execute/', auth, params);

    if (response.ok) {
      await response.json();
      if (actions) {
        actions.onLoading(false);
      }
    } else {
      setError(`Помилка HTTP: ${response.status}`);
    }
  }, [actions, auth, data.id]);

  const getExport = useCallback(async () => {
    if (actions) {
      actions.onLoading(true);
    }
    const params = {
      id: (data?.id) ? data.id : '',
    };
    const response = await api.post('/api/external/export/', auth, params);

    if (response.ok) {
      const d = await response.json();
      const { fileXls } = d;
      downloadXls(fileXls, data.name);
      if (actions) {
        actions.onLoading(false);
      }
    } else {
      setError(`Помилка HTTP: ${response.status}`);
    }
  }, [actions, auth, data.id, data.name]);

  const getColumns = useCallback((dataTable) => dataTable && Object.keys(dataTable[0])
    .reduce((colTable, nameCol) => {
      const rowNameCol = {
        accessor: nameCol,
        Header: nameCol,
      };
      return [
        ...colTable,
        rowNameCol];
    }, []), []);

  const colTable = data.result && getColumns(data.result);

  const setShemaSkd = useCallback((fileContents) => actions.onChange(
    () => (
      {
        skd_schema: fileContents,
      }),
  ), [actions]);

  const setVariantSkd = useCallback((fileContents) => actions.onChange(
    () => (
      {
        skd_variant: fileContents,
      }),
  ), [actions]);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          actions={actions}
          permissions={permissions}
          changed={changed}
          backendURL={BACKENDURL}
        />
      )}
      subtitle="Інформація, яка отриманна із зовнішніх джерел"
    >
      {err && (
      <IconAlert variant="danger">
        {err}
      </IconAlert>
      )}
      {loading && (
      <Loader text="Завантаження" />
      )}
      <>
        <Row>
          <Col lg={2}>
            <Button
              onClick={() => getResult(data)}
            >
              Отримати дані
            </Button>
          </Col>
          <Col lg={2} />
        </Row>
        <Row>
          <Col>
            <Control name="name" headerProps={headerProps} />
          </Col>
          <Col>
            <Control
              name="base"
              headerProps={{
                ...headerProps,
                advancedProps: {
                  backend: '/api/forecasts/refdburl/',
                  modelName: 'dbUrl',
                  label: 'База даних',
                  modelType: 'cat',
                  readOnly: false,
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-2">
          <Col>
            <ReadFile
              skd={{
                label: 'Схема СКД',
                onChange: setShemaSkd,
              }}
            >
              <Control name="skd_schema" headerProps={headerProps} typeControl="text" />
            </ReadFile>
          </Col>
          <Col>
            <ReadFile
              skd={{
                label: 'Віріант СКД',
                onChange: setVariantSkd,
              }}
            >
              <Control name="skd_variant" headerProps={headerProps} typeControl="text" />
            </ReadFile>
          </Col>
        </Row>
        <Row className="mt-3 mb-2">
          <Col>
            {data.result && (
            <Card>
              <Card.Header>
                <Button
                  onClick={() => {
                    getExport(data);
                  }}
                  title="Зберегти в Excel"
                  variant="light"
                  size="sm"
                >
                  <img src={statImg.excel} width="100%" alt="" />
                </Button>
              </Card.Header>

              <Card.Body className="bg-light">
                <AdvanceReactTable
                  data={data?.result}
                  columns={colTable}
                  perPage={50}
                />
              </Card.Body>
            </Card>
            )}
          </Col>
        </Row>
      </>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

// Files.propTypes = {
//   fileInfo: PropTypes.shape({
//     path: PropTypes.string.isRequired,
//     size: PropTypes.string.isRequired,
//   }),
// };
//
// Files.defaultProps = {
//   fileInfo: null,
// };

export default Editor;
