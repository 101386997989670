import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  ItemPicker,
  NumberInput, SelectorInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { comparisonTypes, emptyUid } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { NumberOfMounth } from '../../../consts';
import md from '../../../../../../constants/meta/documents/financingPropose';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, CSUinH, FI,
  isFilterCsuElemKFK, Fond, date, ABVisible,
  isComposeSettingsChecked, doNotUseAccounts,
}) {
  const onDateParams = useMemo(() => [{
    name: 'НаДату',
    value: date,
  }], [date]);
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const CSUAccountFIlter = useMemo(
    () => [
      { fieldName: 'КодКБП7', value: row[tableMD.columns.KPKVCode.name] },
      { fieldName: 'Фонд', value: Fond },
      { fieldName: 'Владелец', value: row[tableMD.columns.CSU.name] },
    ],
    [Fond, row],
  );
  const ElemKFKFilter = useMemo(
    () => [...FIOwnerFilter, ...(isFilterCsuElemKFK ? [{
      fieldName: 'Распорядитель',
      comparisonType: comparisonTypes.inList,
      value: [
        { id: emptyUid, repr: '' },
        row[tableMD.columns.CSU.name] || { id: emptyUid, repr: '' },
      ],
    }] : [])],
    [FIOwnerFilter, isFilterCsuElemKFK, row],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSU.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKEKVChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKEKV.name]: v });
      await onSR('CHANGE_KEKV_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onCSUAccountChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSUAccount.name]: v });
      await onSR('CHANGE_CSU_ACCOUNT_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onBuildObgectChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.BuildObgect.name]: v });
    await onSR('CHANGE_BuildObgect_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);
  const onTypeRepairChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.TypeRepair.name]: v });
  }, [onRowChange]);
  const onSumChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Sum.name]: v });
  }, [onRowChange]);
  const onNoteChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.Note.name]: v });
  }, [onRowChange]);
  const onFullNotesChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.FullNotes.name]: v });
  }, [onRowChange]);
  const onIsReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.IsReturn.name]: v });
  }, [onRowChange]);
  const onMounthReturnChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.MounthReturn.name]: v });
  }, [onRowChange]);
  const onNoteCatChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.NoteCat.name]: v });
      await onSR('CHANGE_NOTES_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onDocNotesChange = useCallback(async (e, v) => {
    await onRowChange(e, { [tableMD.columns.DocNotes.name]: v });
    await onSR('CHANGE_DOCNOTES_IN_TABLE', { rowId: rowIndex });
  }, [onRowChange, onSR, rowIndex]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        {!CSUinH && !doNotUseAccounts
          && (
            <Row noGutters>
              <Col
                xl={4}
                sm={12}
              >
                <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                  <ItemPicker
                    value={row[tableMD.columns.CSU.name]}
                    modelType="cat"
                    modelName="csu"
                    onChange={onCSUChange}
                    filter={CSUFIlter}
                    noHierarchy
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>
              <Col
                xl={8}
                sm={12}
              >
                <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
                  <ItemPicker
                    value={row[tableMD.columns.CSUAccount.name]}
                    modelType="cat"
                    modelName="bankAccounts"
                    onChange={onCSUAccountChange}
                    filter={CSUAccountFIlter}
                    readOnly={readOnly}
                    params={onDateParams}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.CSUAccountNo.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
              </Col>
            </Row>
          )}
        <Row noGutters>
          <Col
            xl={4}
            sm={12}
            className="d-flex"
          >
            <Row className="w-100" noGutters>
              <Col>
                {!CSUinH && doNotUseAccounts && (
                  <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                    <ItemPicker
                      value={row[tableMD.columns.CSU.name]}
                      modelType="cat"
                      modelName="csu"
                      onChange={onCSUChange}
                      filter={CSUFIlter}
                      noHierarchy
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                )}
                <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKFK.name]}
                    modelType="cat"
                    modelName="elementKFK"
                    onChange={onKFKChange}
                    filter={ElemKFKFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KPKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
                <TableRowCell column="elementKEKV" highlighted={highlights.includes('elementKEKV')}>
                  <ItemPicker
                    value={row[tableMD.columns.elementKEKV.name]}
                    modelType="cat"
                    modelName="elementKEKV"
                    onChange={onKEKVChange}
                    filter={FIOwnerFilter}
                    readOnly={readOnly}
                    prepend={(

                      <InputGroup.Text>
                        {row[tableMD.columns.KEKVCode.name]}
                      </InputGroup.Text>

                    )}
                  />
                </TableRowCell>
                {CSUinH && !doNotUseAccounts
          && (
            <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
              <ItemPicker
                value={row[tableMD.columns.CSUAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={onCSUAccountChange}
                filter={CSUAccountFIlter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.CSUAccountNo.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          )}
              </Col>
            </Row>
          </Col>
          <Col xl={8} sm={12}>
            <Row className="w-100" noGutters>
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableRowCell column="YearPlan" highlighted={highlights.includes('YearPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.YearPlan.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="PeriodPlan" highlighted={highlights.includes('PeriodPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.PeriodPlan.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="MonthPlan" highlighted={highlights.includes('MonthPlan')}>
                  <NumberInput
                    value={row[tableMD.columns.MonthPlan.name]}
                    precision={2}
                    onChange={() => {}}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>

              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TableRowCell column="PeriodFact" highlighted={highlights.includes('PeriodFact')}>
                  <NumberInput
                    value={row[tableMD.columns.PeriodFact.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="Balance" highlighted={highlights.includes('Balance')}>
                  <NumberInput
                    value={row[tableMD.columns.Balance.name]}
                    precision={2}
                    onChange={() => {}}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="FinLiabilities" highlighted={highlights.includes('FinLiabilities')}>
                  <NumberInput
                    value={row[tableMD.columns.FinLiabilities.name]}
                    onChange={() => {}}
                    precision={2}
                    readOnly={readOnly}
                  />
                </TableRowCell>
              </Col>

              {(ABVisible || isComposeSettingsChecked)
              && (
                <Col
                  sm={12}
                  lg={3}
                >
                  {ABVisible && (
                    <>
                      <TableRowCell column="BalanceRegAccounts" highlighted={highlights.includes('BalanceRegAccounts')}>
                        <NumberInput
                          value={row[tableMD.columns.BalanceRegAccounts.name]}
                          onChange={() => {}}
                          precision={2}
                          readOnly={readOnly}
                        />
                      </TableRowCell>
                      <TableRowCell column="BalancePersAccounts" highlighted={highlights.includes('BalancePersAccounts')}>
                        <NumberInput
                          value={row[tableMD.columns.BalancePersAccounts.name]}
                          precision={2}
                          onChange={() => {}}
                          readOnly={readOnly}
                        />
                      </TableRowCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                      <NumberInput
                        value={row[tableMD.columns.Sum.name]}
                        precision={2}
                        onChange={onSumChange}
                        readOnly={readOnly}
                      />
                    </TableRowCell>
                  )}
                </Col>
              )}
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                {!isComposeSettingsChecked && !ABVisible && (
                  <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
                    <NumberInput
                      value={row[tableMD.columns.Sum.name]}
                      precision={2}
                      onChange={onSumChange}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                )}
                <TableRowCell column="NoteCat" highlighted={highlights.includes('NoteCat')}>
                  <ItemPicker
                    value={row[tableMD.columns.NoteCat.name]}
                    modelType="cat"
                    modelName="notes"
                    onChange={onNoteCatChange}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                <TableRowCell column="DocNotes" highlighted={highlights.includes('DocNotes')}>
                  <ItemPicker
                    value={row[tableMD.columns.DocNotes.name]}
                    modelType="cat"
                    modelName="docNotes"
                    onChange={onDocNotesChange}
                    readOnly={readOnly}
                  />
                </TableRowCell>
                {isComposeSettingsChecked && (
                  <TableRowCell column="MounthReturn" highlighted={highlights.includes('IsReturn')}>
                    <SelectorInput
                      background="inherit"
                      readOnly={readOnly}
                      value={row[tableMD.columns.MounthReturn.name]}
                      values={Object.values(NumberOfMounth)}
                      onChange={onMounthReturnChange}
                      prepend={(

                        <InputGroup.Text>
                          <CheckboxInput
                            id={`IsReturn-${row[tableMD.columns.strNumber.name]}`}
                            readOnly={readOnly}
                            value={row[tableMD.columns.IsReturn.name]}
                            onChange={onIsReturnChange}
                          />
                        </InputGroup.Text>

                      )}
                    />
                  </TableRowCell>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={12} xl={6}>
            <TableRowCell column="Note" highlighted={highlights.includes('Note')}>
              <TextInput
                value={row[tableMD.columns.Note.name]}
                onChange={onNoteChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          <Col sm={12} xl={6}>
            <TableRowCell column="FullNotes" highlighted={highlights.includes('FullNotes')}>
              <TextInput
                value={row[tableMD.columns.FullNotes.name]}
                onChange={onFullNotesChange}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  CSUinH: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  isFilterCsuElemKFK: PropTypes.bool.isRequired,
  Fond: referencePropType.isRequired,
  Budget: PropTypes.shape({
  }).isRequired,
  date: PropTypes.number.isRequired,
  ABVisible: PropTypes.bool.isRequired,
  isComposeSettingsChecked: PropTypes.bool.isRequired,
  doNotUseAccounts: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  doNotUseAccounts: false,
};

export default memo(TPRow);
