import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import classNames from 'classnames';
import HalfDoughnutChart from '../../../components/Charts/HalfDoughnutChart';
import InfoCard from './InfoCard';
import corner1 from '../../../assets/img/illustrations/corner-1.png';

function PlanFactAccounts() {
  const [stat] = useState({
    target: 1200000000,
    reached: 823000000,
  });
  return (
    <Row className="g-3">
      <InfoCard
        corner={corner1}
        headerTxt="Залишок на рахунку"
        subHeaderTxt="СУБВ з ДБ Освітня субвенція (41033900)"
      >
        <div
          id="platFactAcc"
          className={classNames(
            'text-warning',
            'display-4 fs-lg-2 fs-md-1 fw-normal font-sans-serif',
          )}
        >
          <CountUp
            start={0}
            end={43455322234}
            duration={2.75}
            suffix="грн."
            prefix=""
            separator=" "
            decimals={2}
            decimal="."
          />
        </div>
      </InfoCard>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0">СУБВ з ДБ Освітня субвенція (41033900)</h6>

        </Card.Header>

        <Card.Body className="pt-0">
          <Row className="align-items-center mb-n1 mt-n3">
            <Col>
              <div
                className="ms-auto me-auto text-center"
                style={{ maxWidth: '10.625rem' }}
              >
                <div className="mb-n6">
                  <HalfDoughnutChart
                    color="primary"
                    target={stat.target}
                    reached={stat.reached}
                  />
                </div>
                <CountUp
                  start={0}
                  end={((stat.reached / stat.target) * 100).toFixed(0)}
                  duration={2.75}
                  className="d-inline-block mb-0 mt-n6 fs-1 fw-medium font-sans-serif"
                  suffix="%"
                  separator=","
                />
              </div>
              {/* </Col> */}
              {/* <Col xs={5} className="pe-md-0 pe-lg-3"> */}
              <Row className="g-0 mt-4">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Уточнений план з початку року: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">1 200 000 000</h6>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Надійшло з початку року: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">823 000 000 </h6>
                </Col>
              </Row>
              <Row className="g-0  mt-1">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Відхилення від уточ. плану: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">377 000 000 </h6>
                </Col>
              </Row>
              <Row className="g-0 mt-3">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Надійшло за день: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">210 200 </h6>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Профінансовано за день: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">332 000 </h6>
                </Col>
              </Row>
              <Row className="g-0 mt-3">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Надійшло за поточний місяць, згідно ф.412: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">210 200 </h6>
                </Col>
              </Row>
              <Row className="g-0 mt-3">
                <Col xs={7}>
                  <h6 className="mb-0 text-600">Залишок на початок року: </h6>
                </Col>
                <Col xs={5}>
                  <h6 className="mb-0 text-800">210 200 </h6>
                </Col>
              </Row>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default PlanFactAccounts;
