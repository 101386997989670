import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Fields from '../../field';
import Selector from '../../lister/selector';
import {
  ModalDiv,
  ContentDiv,
  Header,
  NegativeButton,
  PositiveButton,
  ContainerHeader,
} from '../../../components/Form/styledForm';
import TextAreaField from '../../field/TextArea';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import SaveIcon from '../../../assets/icons/save';

const TextInput = Fields.GetTextInput(150);

const Title = styled(Header)`
  text-align: center;
  margin: 30px 0;
`;
const TextArea = styled(TextAreaField)`
  margin-top: 10px;
`;

const selector = (store) => ({
  currentFO: store.getIn(['auth', 'sessionOptions', 'fo']),
  currentBudget: store.getIn(['auth', 'sessionOptions', 'budget']),
});

function SaveReportVariant({
  onSave, reportKey, disabled, SaveButton,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [nameVariant, setNameVariant] = useState('');
  const [commentVariant, setCommentVariant] = useState('');
  const { currentFO, currentBudget } = useSelector(selector);

  const handleSelect = useCallback(
    (e, item) => {
      if (item.get('isGroup', true)) {
        setNameVariant(item.get('name', ''));
      }
    },
    [],
  );

  const handleChoice = useCallback(
    (e) => {
      if (nameVariant) {
        onSave(e, nameVariant, commentVariant);
        setModalOpened(false);
      }
    },
    [commentVariant, nameVariant, onSave],
  );

  const filter = useMemo(
    () => ([
      { fieldName: 'ФинансовыйОрган', value: currentFO },
      { fieldName: 'Бюджет', value: currentBudget },
      { fieldName: 'Ключ', value: reportKey },
    ]),
    [currentBudget, currentFO, reportKey],
  );

  return (
    <>

      {SaveButton ? (
        <SaveButton
          disabled={disabled}
          text="Зберегти налаштування"
          onClick={() => {
            setModalOpened(true);
          }}
        >
          Зберегти налаштування
        </SaveButton>
      )
        : (
          <CommandPanelButton
            disabled={disabled}
            text="Зберегти налаштування"
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <SaveIcon />
          </CommandPanelButton>
        )}

      {modalOpened
        && (
          <ModalDiv zIndexModal={1000}>
            <ContentDiv>
              <ContainerHeader>
                <Header>Оберіть варіант звіту для збереження</Header>
                <div>
                  <PositiveButton
                    onClick={handleChoice}
                    disabled={nameVariant.length === 0}
                  >
                    Зберегти
                  </PositiveButton>
                  <NegativeButton onClick={() => setModalOpened(false)}>
                    Скасувати
                  </NegativeButton>
                </div>
              </ContainerHeader>
              <Title>Або вкажіть назву та коментар для додавання нового варіанту</Title>
              <div style={{ marginBottom: '10px' }}>
                <TextInput
                  radius
                  textLeft
                  placeholder="Назва"
                  onChange={(e, v) => setNameVariant(v)}
                />
                <TextArea
                  placeholder="Коментар"
                  value={commentVariant}
                  onChange={(e) => setCommentVariant(e.target.value)}
                />
              </div>
              <div>
                <Selector
                  modelType="cat"
                  modelName="reportSettings"
                  onSelect={handleSelect}
                  onChoice={handleChoice}
                  filter={filter}
                  CommandPanel={<div />}
                />
              </div>
            </ContentDiv>
          </ModalDiv>
        )}
    </>
  );
}

SaveReportVariant.propTypes = {
  onSave: PropTypes.func.isRequired,
  reportKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  SaveButton: PropTypes.elementType,
};

SaveReportVariant.defaultProps = {
  disabled: false,
  SaveButton: null,
};

export default SaveReportVariant;
