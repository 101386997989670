import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { DCControls, mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/reportSocial1';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  ContainerTwoColumn, DivWithPropsLabel,
  StyledLabel, StyledPane, ContainerDiv,
} from '../../../components/Form/styledForm';
import ItemField from '../../field/selectorField/item/itemField';
import Tabs from '../../../components/tabs/Tabs';
import DateRangeField from '../../../components/fields/DateRangeField';
import ReportContainer from '../reportContainer';

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

class ReportSocial1Editor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    sessionOptions: new Map(),
    portalOpened: false,
    onReportDetails: null,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const FoLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('FO', new Map())}
        modelType="cat"
        modelName="csu"
        onChange={(e, v) => {
          dispatch(changeField(['headerForm', 'FO'], v));
          dispatch(changeField(['headerForm', 'signVariant'], new Map()));
        }}
      />
    ) : (
      <ItemField
        value={headerForm.get('FO', new Map())}
        disabled
      />
    );
    const FO = headerForm.get('FO', new Map());
    const Bookmarks = (
      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter
              filterChoice={new Map()
                .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКДБ', [{ fieldName: 'Владелец', value: FO }])
                .set('ЭлементКФБ', [{ fieldName: 'Владелец', value: FO }])}
            />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    );

    const SettingsButton = (
      <div>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
        </ContainerTwoColumn>
        {Bookmarks}
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportSocial1Editor);
