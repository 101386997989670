import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function IndicatorsHeader({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow className="px-4 fs--2">
      <Col sm={4}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'indicator'}
          highlighted={highlights.includes('indicator')}
          onToggleHighlight={() => onToggleHighlight('indicator')}
        >
          {fields.indicator.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_plan_total'}
              highlighted={highlights.includes('amount_plan_total')}
              onToggleHighlight={() => onToggleHighlight('amount_plan_total')}
            >
              {fields.amount_plan_total.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_plan_zf'}
              highlighted={highlights.includes('amount_plan_zf')}
              onToggleHighlight={() => onToggleHighlight('amount_plan_zf')}
            >
              {fields.amount_plan_zf.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_plan_sf'}
              highlighted={highlights.includes('amount_plan_sf')}
              onToggleHighlight={() => onToggleHighlight('amount_plan_sf')}
            >
              {fields.amount_plan_sf.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_fact_total'}
              highlighted={highlights.includes('amount_fact_total')}
              onToggleHighlight={() => onToggleHighlight('amount_fact_total')}
            >
              {fields.amount_fact_total.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_fact_zf'}
              highlighted={highlights.includes('amount_fact_zf')}
              onToggleHighlight={() => onToggleHighlight('amount_fact_zf')}
            >
              {fields.amount_fact_zf.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_fact_sf'}
              highlighted={highlights.includes('amount_fact_sf')}
              onToggleHighlight={() => onToggleHighlight('amount_fact_sf')}
            >
              {fields.amount_fact_sf.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_diff_total'}
              highlighted={highlights.includes('amount_diff_total')}
              onToggleHighlight={() => onToggleHighlight('amount_diff_total')}
            >
              {fields.amount_diff_total.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_diff_zf'}
              highlighted={highlights.includes('amount_diff_zf')}
              onToggleHighlight={() => onToggleHighlight('amount_diff_zf')}
            >
              {fields.amount_diff_zf.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_diff_sf'}
              highlighted={highlights.includes('amount_diff_sf')}
              onToggleHighlight={() => onToggleHighlight('amount_diff_sf')}
            >
              {fields.amount_diff_sf.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'description'}
          highlighted={highlights.includes('description')}
          onToggleHighlight={() => onToggleHighlight('description')}
        >
          {fields.description.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

IndicatorsHeader.propTypes = {
  fields: fieldsPropTypes,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onToggleHighlight: PropTypes.func.isRequired,
};

IndicatorsHeader.defaultProps = {
  activeCol: null,
  highlights: [],
  fields: null,
};

export default IndicatorsHeader;
