// eslint-disable-next-line import/no-cycle
import DocLister from './lister';
import DocSelector from './selector';

const finSourcePlanChanges = {
  lister: DocLister,
  selector: DocSelector,
};

export default finSourcePlanChanges;
