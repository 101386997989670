import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: {
    label: 'Код', name: 'Код', visible: false, width: 'fit-content(150px)',
  },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  fullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: false },
  categ: { label: 'Категорія', name: 'Категория', visible: true },
  units: { label: 'Одиниці виміру', name: 'ЕдИзм', visible: false },
  categ3: { label: 'Категория 3 зн', name: 'Категория3зн', visible: true },
  logId: { label: 'Номер в логіці', name: 'ИДЛогика', visible: false },
  form_start: { label: 'Фактична наявність початок року', name: 'Формула_начало', visible: false },
  form_finish: { label: 'Фактична наявність кінець року', name: 'Формула_конец', visible: false },
  form_start_middle: { label: 'Середньорічна кількість план', name: 'Формула_сред_начало', visible: false },
  form_finish_middle: { label: 'Середньорічна кількість виконано', name: 'Формула_сред_конец', visible: false },
  KPKV: { label: 'КПКВ', name: 'КПКВ', visible: true },
  KPKVLink: { label: 'КПКВ з довідника', name: 'КПКВСсылка', visible: false },
  countRow: { label: 'Номер рядка для підрахунку в формулах', name: 'РядокРасчета', visible: true },
  isBanCountS: { label: 'Заборона внесення "Середньорічна кількість" (на початок)', name: 'флЗапретитьВноситьСреднеГодКолНач', visible: true },
  isBanCountE: { label: 'Заборона внесення "Середньорічна кількість" (на кінець)', name: 'флЗапретитьВноситьСреднеГодКолКон', visible: true },
  isBanFactS: { label: 'Заборона внесення "Фактична наявність" (на початок)', name: 'флЗапретитьВноситьФактНач', visible: true },
  isBanFactE: { label: 'Заборона внесення "Фактична наявність" (на кінець)', name: 'флЗапретитьВноситьФактКон', visible: true },
  owner: { label: 'Версія довідника', name: 'Владелец', visible: true },

};

export const tarif = {
  label: 'Категорії працівників для мережі, штатів та контингентів',
  name: 'categoriesFOP',
  backendName: 'КатегорииФОП',
  defaultOrder,
  columns,
  frontend: 'cat/categoriesFOP',
  listColumns: [...(new Set(['owner', 'countRow', 'categ', 'categ3', 'KPKV', ...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tarif;
