import base from './base';
import { periodTypes } from '../common';
/**
 * @const
 * @type {object}
 */

const mon = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v, i) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `${mon[i]}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  // authorCh: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  includeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },
  received: { label: 'Отримано', name: 'Квитанция', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  onlyYear: {
    label: 'Тільки річні суми',
    name: 'ТолькоГодовая',
    visible: false,
  },
  includeBudgetAmplification: {
    label: 'в т.ч. Бюджет Розвитку',
    name: 'ВТЧБР',
    visible: false,
  },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  tags: { label: 'Теги', name: 'АвтПримечание', visible: true },
  docRevenuePlanning: {
    label: 'Планування доходів',
    name: 'ДокПланДох',
    visible: false,
  },
  docTotalCost: {
    label: 'Загальна вартість',
    name: 'СуммаДокумента',
    visible: true,
    type: 'moneys',
  },
  docTotal: {
    label: 'Сума ттрансфертів іншим бюджетам',
    name: 'СуммаОсновной',
    visible: true,
    type: 'moneys',
  },
  docTotalKDB: {
    label: 'Сума трансфертів з інших бюджетів',
    name: 'СуммаОсеовнойКДБ',
    visible: true,
    type: 'moneys',
  },
  numberRegistryFI: {
    label: 'Реестраційний номер',
    name: 'РегНомер',
    visible: false,
  },
  year: {
    label: 'Рік',
    name: 'Год',
    visible: true,
  },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Трансферти іншим бюджетам',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      budget: { label: 'Бюджет', name: 'Бюджет' },
      kbp7Class: { label: 'КПКВКМБ', name: 'КлассификаторКБП7' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      budgetCode: { label: 'Код бюджету', name: 'КодБюджета' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
  generalKDB: {
    name: 'ОсновнойКДБ',
    label: 'Трансферти з інших бюджетів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      budget: { label: 'Бюджет', name: 'Бюджет' },
      kdbClass: { label: 'КДБ', name: 'КлассификаторКДБ' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      KDBCode: { label: 'КДБ', name: 'КодКДБ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      budgetCode: { label: 'Код бюджету', name: 'КодБюджета' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
  lowLevelDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Док' },
      isApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      CSU: { label: 'Розподярник', name: 'Распорядитель' },
      author: { label: 'Автор', name: 'Автор' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Розпис міжбюджетних трасфертів',
  name: 'transfertPlanning',
  backendName: 'ПланМежбюджТрансфертов',
  columns,
  frontend: 'doc/transfertPlanning',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'docTotal',
      'docTotalKDB',
      'fondObject',
      'year',
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
