import React, {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Modal } from 'react-bootstrap';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  ContainerTwoColumn,
  CommandPanelColor,
  DivWithPropsLabel,
  StyledPane,
  TwoRow,
  TwoColGridContainer,
  ThreeColumnsGridContainer, FramePrintModal,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import DateInput from '../../../components/fields/dates';
import Tabs from '../../../components/tabs/Tabs';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import DataTP from './tablepart/Data';
import ProgramsTP from './tablepart/Programs';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpCommandPanel';
import { emptyUid } from '../../../constants/meta/common';

const YearInput = Fields.GetNumberInput(4, 0, false);
const Note = Fields.TextAreaField;
const TextInput150 = Fields.GetTextInput(150);

const MainContainer = styled.div`
  position: relative;
`;

const StyledWhitePane = styled(StyledPane)`
  background: white;
`;

const StyledResponse = styled.h2`
 color: red;
 `;

const md = meta.dp.indicatorPassportPrinting;

const DataTPRenderer = getTPContainer(DataTP, getResizableCP(<TPCommandPanel tableName="data" />));
const ProgramsTPRenderer = getTPContainer(ProgramsTP, getResizableCP(<TPCommandPanel tableName="programs" />));

function IndicatorPassportEditor({ onClose }) {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);

  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  const docByKBP7 = useMemo(
    () => headerForm.get('docByKBP7', false),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  const signVariantValue = useMemo(
    () => headerForm.get('SignVariant', new Map()).toJS(),
    [headerForm],
  );

  const signMFO = useMemo(
    () => headerForm.get('signMFO', null),
    [headerForm],
  );

  const existDocSendToLogica = useMemo(
    () => headerForm.get('existDocSendToLogica', null),
    [headerForm],
  );

  const signApprooved = useMemo(
    () => headerForm.get('signApprooved', false),
    [headerForm],
  );
  const signVariantValueFilled = signVariantValue.id && signVariantValue.id !== emptyUid
    && signApprooved;

  const [opened, setOpened] = useState(true);
  const [openedEx, setOpenedEx] = useState(true);

  return (
    <MainContainer>
      {!headerForm.getIn(['SignVariant', 'repr'], '')
      && <StyledResponse color="red">Необхiдно заповнити поле &#39;Варіант підпису&#39;</StyledResponse> }
      <div>
        <StyledLabel>{headerForm.get('signVariantLabel', false) || md.columns.SignVariant.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('SignVariant', new Map())}
          modelType="cat"
          modelName="signatureVariants"
          onChange={(e, v) => {
            dispatch(actions.changeField(['headerForm', 'SignVariant'], v));
            dispatch(actions.processServerReq('RESET_SignVariant'));
          }}
          // filter={[
          //   { fieldName: 'Владелец', value: headerForm.get('FI', new Map()) },
          // ]}
          // noHierarchy
        />
      </div>
      {/* {signMFO !== null && ( */}
      {/*  <Alert variant="warning" className="mb-0 mx-auto"> */}
      {/*    {signMFO} */}
      {/*  </Alert> */}
      {/* )} */}
      <ContainerButtonTop>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setShowSettings(false);
            dispatch(actions.changeField(['headerForm', 'result'], new Map()));
            dispatch(actions.processServerReq('PRINT'));
          }}
          loading={isProcessing}
          disabled={!headerForm.getIn(['SignVariant', 'repr'], '')}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          text="Налаштування"
          onClick={() => setShowSettings((v) => !v)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        {signMFO !== null && (
          <Modal show={opened} onHide={() => setOpened(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Увага !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {signMFO}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={() => setOpened(false)}>ОК</Button>
            </Modal.Footer>
          </Modal>
        )}
        {existDocSendToLogica !== null && (
          <Modal show={openedEx} onHide={() => setOpenedEx(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Увага !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {existDocSendToLogica}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={() => dispatch(actions.processServerReq('CreateDispatchDocNext'))}>Так</Button>
              <Button variant="danger" onClick={() => setOpenedEx(false)}>Ні</Button>
            </Modal.Footer>
          </Modal>
        )}
        {headerForm.get('result', false) && headerForm.get('result', false).size !== 0 && (
          <CommandPanelButton
            label="Передати в Logica"
            onClick={() => {
              dispatch(actions.processServerReq('CreateDispatchDoc'));
            }}
            disabled={!signVariantValueFilled}
          />
        )}
      </ContainerButtonTop>
      {
        showSettings && (
          <FramePrintModal>
            <Tabs>
              <DivWithPropsLabel label="Налаштування">
                <StyledWhitePane>
                  <ThreeColumnsGridContainer>
                    <div>
                      <StyledLabel>{md.columns.budget.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('budget', new Map())}
                        modelType="cat"
                        modelName="budget"
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'budget'], v))}
                      />
                    </div>
                    <div>
                      <StyledLabel>{md.columns.FO.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('FO', new Map())}
                        modelType="cat"
                        modelName="csu"
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FO'], v))}
                      />
                    </div>
                    <div>
                      <StyledLabel>{md.columns.CSU.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('CSU', new Map())}
                        modelType="cat"
                        modelName="csu"
                        noHierarchy
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSU'], v))}
                      />
                    </div>
                    <TwoColGridContainer>
                      <div>
                        <StyledLabel>{md.columns.datePrinting.label}</StyledLabel>
                        <DateInput
                          value={headerForm.get('datePrinting', null)}
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'datePrinting'], v))}
                        />
                      </div>
                      <div>
                        <StyledLabel>{md.columns.year.label}</StyledLabel>
                        <YearInput
                          border
                          radius
                          background
                          value={headerForm.get('year', 5)}
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'year'], v))}
                        />
                      </div>
                    </TwoColGridContainer>
                    <div>
                      <StyledLabel>{md.columns.classifierKBP.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('classifierKBP', new Map())}
                        modelType="cat"
                        modelName="kbpClassifier"
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'classifierKBP'], v))}
                      />
                    </div>
                    <Fields.CheckboxField
                      style={{ alignSelf: 'end' }}
                      value={headerForm.get('detailsPrograms', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'detailsPrograms'], v))}
                      label={md.columns.detailsPrograms.label}
                    />
                    {docByKBP7 ? (
                      <div>
                        <StyledLabel>{md.columns.KPKV.label}</StyledLabel>
                        <Fields.SelectorField
                          modelName="kbp7Classifier"
                          modelType="cat"
                          value={headerForm.get('KPKV', new Map())}
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KPKV'], v))}
                        />
                      </div>
                    ) : (
                      <div>
                        <StyledLabel>{md.columns.elementKFK.label}</StyledLabel>
                        <Fields.SelectorField
                          value={headerForm.get('elementKFK', new Map())}
                          modelType="cat"
                          modelName="elementKFK"
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'elementKFK'], v))}
                        />
                      </div>
                    )}
                    <TwoColGridContainer style={{ alignSelf: 'end' }}>
                      <CommandPanelColor
                        style={{ justifyContent: 'center' }}
                        label="Заповнити підпрограми"
                        onClick={() => dispatch(actions.processServerReq('FILL_SUBROUTINES'))}
                      />
                      <CommandPanelColor
                        style={{ justifyContent: 'center' }}
                        label="Заповнити документами"
                        onClick={() => dispatch(actions.processServerReq('FILL_DOCUMENTS'))}
                      />
                    </TwoColGridContainer>
                  </ThreeColumnsGridContainer>
                  <TwoColGridContainer>
                    <div>
                      <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('SignVariant', new Map())}
                        modelType="cat"
                        modelName="signatureVariants"
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                        filter={[
                          { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
                        ]}
                      />
                    </div>
                    <div>
                      <StyledLabel>{md.columns.agreed.label}</StyledLabel>
                      <TextInput150
                        radius
                        background
                        textLeft
                        value={headerForm.get('agreed', '')}
                        maxLength={150}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'agreed'], v))}
                      />
                    </div>
                  </TwoColGridContainer>
                  <ThreeColumnsGridContainer>
                    <div>
                      <Fields.CheckboxField
                        value={headerForm.get('order', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'order'], v))}
                        label={md.columns.order.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('targetFromSession', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'targetFromSession'], v))}
                        label={md.columns.targetFromSession.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('printingTable7', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printingTable7'], v))}
                        label={md.columns.printingTable7.label}
                      />
                    </div>
                    <div>
                      <Fields.CheckboxField
                        value={headerForm.get('naneSurname', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'naneSurname'], v))}
                        label={md.columns.naneSurname.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('printingNumDoc', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printingNumDoc'], v))}
                        label={md.columns.printingNumDoc.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('targetFromDoc', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'targetFromDoc'], v))}
                        label={md.columns.targetFromDoc.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('printingPageNumbers', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printingPageNumbers'], v))}
                        label={md.columns.printingPageNumbers.label}
                      />
                    </div>
                    <div>
                      <Fields.CheckboxField
                        value={headerForm.get('printingUAH', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printingUAH'], v))}
                        label={md.columns.printingUAH.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('copecks', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'copecks'], v))}
                        label={md.columns.copecks.label}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('printingTargetedPrograms', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printingTargetedPrograms'], v))}
                        label={md.columns.printingTargetedPrograms.label}
                      />
                    </div>
                  </ThreeColumnsGridContainer>
                  <Tabs>
                    <DivWithPropsLabel label="Програми / Підпрограми">
                      <StyledWhitePane>
                        <ProgramsTPRenderer />
                      </StyledWhitePane>
                    </DivWithPropsLabel>
                    <DivWithPropsLabel label="Документи">
                      <StyledWhitePane>
                        <DataTPRenderer />
                      </StyledWhitePane>
                    </DivWithPropsLabel>
                  </Tabs>
                </StyledWhitePane>
              </DivWithPropsLabel>
              <DivWithPropsLabel label="Підстави">
                <StyledWhitePane>
                  <ContainerTwoColumn>
                    <TwoRow>
                      <div>
                        <StyledLabel>{md.columns.laws1.label}</StyledLabel>
                        <Fields.SelectorField
                          value={headerForm.get('laws1', new Map())}
                          modelType="cat"
                          modelName="laws"
                          canCleared
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'laws1'], v))}
                        />
                      </div>
                      <Note
                        value={headerForm.getIn(['lawsStr1'], '')}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'lawsStr1'], v))}
                      />
                      <div>
                        <StyledLabel>{md.columns.laws1number.label}</StyledLabel>
                        <TextInput150
                          radius
                          background
                          textLeft
                          value={headerForm.get('laws1number', '')}
                          maxLength={150}
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'laws1number'], v))}
                        />
                      </div>
                      <div>
                        <StyledLabel>{md.columns.laws1Date.label}</StyledLabel>
                        <DateInput
                          value={headerForm.get('laws1Date', null)}
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'laws1Date'], v))}
                        />
                      </div>
                    </TwoRow>
                    <TwoRow>
                      <div>
                        <StyledLabel>{md.columns.laws2.label}</StyledLabel>
                        <Fields.SelectorField
                          value={headerForm.get('laws2', new Map())}
                          modelType="cat"
                          modelName="laws"
                          canCleared
                          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'laws2'], v))}
                        />
                      </div>
                      <Note
                        value={headerForm.getIn(['lawsStr2'], '')}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'lawsStr2'], v))}
                      />
                    </TwoRow>
                    <div>
                      <StyledLabel>{md.columns.groundLaw.label}</StyledLabel>
                      <Note
                        value={headerForm.getIn(['groundLaw'], '')}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'groundLaw'], v))}
                      />
                    </div>
                    <div>
                      <StyledLabel>{md.columns.target.label}</StyledLabel>
                      <Note
                        value={headerForm.getIn(['target'], '')}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'target'], v))}
                      />
                    </div>
                  </ContainerTwoColumn>
                </StyledWhitePane>
              </DivWithPropsLabel>
            </Tabs>
          </FramePrintModal>
        )
      }
      <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
    </MainContainer>
  );
}
IndicatorPassportEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

export default enchance(IndicatorPassportEditor);
