import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/payment';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  activeObjectsBuilding,
  elememtKDBVisible,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row noGutters>

          <Col lg={6} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKEKV'}
              highlighted={highlights.includes('elementKEKV')}
              onToggleHighlght={() => onToggleHighlght('elementKEKV')}
            >
              {meta.columns.elementKEKV.label}
            </TableHeaderCell>
            {activeObjectsBuilding && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'activeObjectsBuilding'}
              highlighted={highlights.includes('activeObjectsBuilding')}
              onToggleHighlght={() => onToggleHighlght('activeObjectsBuilding')}
            >
              {md.columns.activeObjectsBuilding.label}
            </TableHeaderCell>
            )}
            {elememtKDBVisible && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elememtKDBVisible'}
              highlighted={highlights.includes('elememtKDBVisible')}
              onToggleHighlght={() => onToggleHighlght('elememtKDBVisible')}
            >
              {md.columns.elememtKDBVisible.label}
            </TableHeaderCell>
            )}
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>

            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prepaid'}
              highlighted={highlights.includes('prepaid')}
              onToggleHighlght={() => onToggleHighlght('prepaid')}
            >
              {meta.columns.prepaid.label}
            </TableHeaderCell>

          </Col>
          <Col lg={4} className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'notes'}
              highlighted={highlights.includes('notes')}
              onToggleHighlght={() => onToggleHighlght('notes')}
            >
              {meta.columns.notes.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  activeObjectsBuilding: PropTypes.bool,
  elememtKDBVisible: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
  activeObjectsBuilding: false,
  elememtKDBVisible: false,
  /// /
};

export default memo(TPHeader);
