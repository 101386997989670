import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Styled from 'styled-components';
import { ItemContainer, ItemText } from '../StyledComponents';
import { MinusIcon, ClosedFolder } from '../../../../assets/icons';
import Checkbox from '../../../../components/styledCheckbox/styledCheckbox';
import { fieldTypes } from '../../../../constants/meta/enums/DataComposition/fieldTypes';

const StyledItemContainer = Styled(ItemContainer).attrs((props) => ({
  style: { paddingLeft: `${props.level}em` },
}))`
  & > :last-child{
    flex-grow: 1;
    margin-left: 5px;
  }
`;

class SelectionItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    item: PropTypes.instanceOf(Map).isRequired,
    defaultFieldTittle: PropTypes.string,
    toggleUseHandler: PropTypes.func,
    onClickHandler: PropTypes.func,
    onDoubleClickHandler: PropTypes.func,
    level: PropTypes.number,
    iRef: PropTypes.func,
  };

  static defaultProps = {
    defaultFieldTittle: '',
    toggleUseHandler: () => null,
    onClickHandler: () => null,
    onDoubleClickHandler: () => null,
    level: 0,
    iRef: () => null,
  };

  getItemIcon = (itemType) => {
    switch (itemType) {
      case fieldTypes.SelectedFieldGroup:
        return (<ClosedFolder />);
      default:
        return (<MinusIcon />);
    }
  };

  getItemTitle = () => {
    const { item, defaultFieldTittle } = this.props;

    const itemTittle = item.get('Tittle', '');

    if (item.get('Type') === fieldTypes.Auto) {
      return 'Авто';
    }
    return itemTittle ? `${itemTittle}(${defaultFieldTittle})` : defaultFieldTittle;
  };

  render() {
    const {
      id, item, toggleUseHandler, onClickHandler, onDoubleClickHandler,
      level,
      iRef,
      dragHandleProps,
      draggableProps,
    } = this.props;
    return (
      <StyledItemContainer
        active={item.get('_SELECTED', false)}
        level={level}
        onClick={(e) => onClickHandler(e, id)}
        onDoubleClick={(e) => onDoubleClickHandler(e, id)}
        ref={iRef}
        {...dragHandleProps}
        {...draggableProps}
      >
        {this.getItemIcon(this.getItemIcon(item.get('Type', '')))}
        <Checkbox
          value={item.get('Use', false)}
          onChange={(e) => toggleUseHandler(e, id)}
        />
        <ItemText>
          {this.getItemTitle()}
        </ItemText>
      </StyledItemContainer>
    );
  }
}

export default SelectionItem;
