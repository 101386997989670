import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';

import EditIcon from '../../assets/icons/edit';

function EditButton({ text, onClick, ...args }) {
  return (
    <Button onClick={onClick} {...args} title={text}>
      <EditIcon />
    </Button>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

EditButton.defaultProps = {
  text: 'Змінити',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default EditButton;
