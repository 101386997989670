import React from 'react';
import { childrenPropType } from '../../constants/backend/propTypes';

function EditorFooter({ children }) {
  return (
    <div className="bg-light rounded-bottom d-flex justify-content-between mt-2 p-3 pb-0 border-top ">
      {children}
    </div>
  );
}

EditorFooter.propTypes = {
  children: childrenPropType.isRequired,
};

export default EditorFooter;
