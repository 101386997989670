import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Container, Row,
} from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import { FieldPropType } from '../../../newEditor/propTypes';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryIndicator({
  data, fields, actions, id, readOnlyFields, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.pcmindicator.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });

  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const fullnameProps = editorHooks.useStringInputProps('fullname', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const isActivePropsProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);

  return (
    <Card>
      <Card.Header>
        <HistoryCommandPanel
          currentStartDate={historyData.datefrom}
          histories={historiesList}
          historyLoading={historyLoading}
          onLoadHistories={historyActions.onLoadHistories}
          onSelectHistory={historyActions.onSelectHistory}
          onSaveHistory={historyActions.onSaveHistory}
          onCreateHistory={historyActions.onCreateHistory}
          onDeleteHistory={historyActions.onDeleteHistory}
          isNew={isNew}
        />
      </Card.Header>
      <Card.Body>
        <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
          <Container fluid>
            <Row>
              <Col md={6}>
                <EditorControls.StringInput {...nameProps} />
              </Col>
              <Col md={6}>
                <EditorControls.CheckboxInput {...isActivePropsProps} />
              </Col>
              <Col sm={12}>
                <EditorControls.TextInput {...fullnameProps} rows={3} />
              </Col>
            </Row>
          </Container>
        </HistoryContainer>
      </Card.Body>
    </Card>
  );
}

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  fullnamer: PropTypes.string,
  is_active: PropTypes.bool,
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryIndicator.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ),
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape(
      fieldsPropTypes,
    ),
  }),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}),
};

HistoryIndicator.defaultProps = {
  fields: {},
  data: {},
  readOnlyFields: [],
  errors: {},
};

export default HistoryIndicator;
