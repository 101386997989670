import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import DateField from '../../../components/fields/dates';
import enums from '../../../constants/meta/enums';
import Loading from '../../../components/styledLoading';

const md = meta.dp.exportDBF373;

const NumInput4 = Fields.GetNumberInput(4, 0, false);

const TypeDocType = {
  Tax: { label: 'Доходи', name: 0 },
  Ass: { label: 'Видатки', name: 1 },
};

const TypeChanges = {
  Plan: { label: 'Розпис', name: 0 },
  Changes: { label: 'Зміни до розпису', name: 1 },
  Catalogs: { label: 'Довідники', name: 2 },
};

const TypeSumFormat = {
  Cop: { label: 'У копійках', name: 0 },
  Grn: { label: 'У гривнях', name: 1 },
};

const planingKindsOptions = Object.values(enums.PlaningKind);

const TypePeriodType = {
  Year: { label: 'Річний розпис', name: 1 },
  Quart: { label: 'Квартальний розпис', name: 2 },
  Month: { label: 'Помісячний розпис', name: 3 },
};

class ExportDBF373Editor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
    isProcessing: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.firstStart && !props.isProcessing) {
      props.dispatch(actions.processServerReq('DBF'));
      return { firstStart: false };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm, isProcessing },
    } = this;
    return (
      <FrameDiv>
        {isProcessing
          && <Loading />}
        <div>
          <MyContainerFormWithNColumns columns={5}>
            <div>
              <StyledLabel>{md.columns.DateStart.label}</StyledLabel>
              <DateField
                label={md.columns.DateStart.label}
                value={headerForm.get('DateStart', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DateStart'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.DateFin.label}</StyledLabel>
              <DateField
                label={md.columns.DateFin.label}
                value={headerForm.get('DateFin', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DateFin'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.Year.label}</StyledLabel>
              <NumInput4
                border
                radius
                background
                label={md.columns.Year.label}
                value={headerForm.get('Year', 0)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Year'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.OldVar.label}
                value={headerForm.get('OldVar', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OldVar'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.Coding.label}
                value={headerForm.get('Coding', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Coding'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={3}>
            <div>
              <StyledLabel>{md.columns.Budget.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('Budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'Budget'], value));
                }}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.FO.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('FO', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FO'], value));
                }}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.Svod.label}
                value={headerForm.get('Svod', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Svod'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={5}>
            <div>
              <StyledLabel>{md.columns.DataType.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('DataType', false)}
                options={Object.values(TypeChanges)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DataType'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.PlanType.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('PlanType', false)}
                options={Object.values(planingKindsOptions)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PlanType'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.DocType.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('DocType', false)}
                options={Object.values(TypeDocType)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DocType'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.PeriodType.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('PeriodType', false)}
                options={Object.values(TypePeriodType)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PeriodType'], v))}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.SumFormat.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('SumFormat', false)}
                options={Object.values(TypeSumFormat)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SumFormat'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={6}>
            <div>
              <Fields.CheckboxField
                label={md.columns.NoInside.label}
                value={headerForm.get('NoInside', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'NoInside'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.AddEmptyFiles.label}
                value={headerForm.get('AddEmptyFiles', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AddEmptyFiles'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.OnlyMain.label}
                value={headerForm.get('OnlyMain', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'OnlyMain'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.Registry.label}
                value={headerForm.get('Registry', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Registry'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.InputMonthNom.label}
                value={headerForm.get('InputMonthNom', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'InputMonthNom'], v))}
              />
            </div>
            <div>
              {/* <Fields.CheckboxField */}
              {/*  label={md.columns.br.label} */}
              {/*  value={headerForm.get('br', false)} */}
              {/*  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'br'], v))} */}
              {/* /> */}
              <Fields.CheckboxField
                label={md.columns.f6Separate.label}
                value={headerForm.get('f6Separate', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'f6Separate'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.all7.label}
                value={headerForm.get('all7', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'all7'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.noKDB.label}
                value={headerForm.get('noKDB', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noKDB'], v))}
              />
            </div>
          </MyContainerFormWithNColumns>
        </div>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(ExportDBF373Editor);
