import base from './base';

const columns = {
  ...base.columns,
  Author: { label: 'Автор', name: 'Автор', visible: true },
  Tags: { label: 'Опис', name: 'АвтПримечание', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  PlaningMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  Year: { label: 'Рiк', name: 'Год', visible: false },
  Financed: {
    label: 'Документ профінансовано',
    name: 'ДокументПрофинансирован',
    visible: true,
    type: 'bool',
  },
  IsApproved: {
    label: 'Документ затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  ImportAllNotes: {
    label: 'Не зводити примітку (в розпорядженні / розподілі)',
    name: 'ИмпортВсехПримечаний',
    visible: false,
  },
  ImportReqNotes: {
    label: 'Не зводити примітку в заявці',
    name: 'ИмпортПримечанийВЗаявку',
    visible: false,
  },
  Received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  FinReceived: { label: 'Профінансовано', name: 'КвитанцияФ', visible: false },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  RNo: { label: 'Номер', name: 'РегНомер', visible: true },
  RNo2: { label: 'Ном.внутр.обліку', name: 'РегНомер2', visible: false },
  CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
  FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
  FIcode: { label: 'р/р розп.', name: 'РСФинансовыйОрганКод', visible: true },
  Sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  IncludeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  CalcBalanceByFI: {
    label: 'Залишки за розпорядником вищого рівня',
    name: 'флРасчетОстатовПоФинОргану',
    visible: false,
  },
  FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  DontOmitNotesRequerst: {
    label: 'Не зводити рядки у заявці за примітками',
    name: 'НеСводитьПоПримечаниювЗаявке',
    visible: false,
  },
  // Дополнитеьлные свойства
  CalcBalanceByFIEnabled: {
    label: 'флРасчетОстатовПоФинОргануДоступность',
    name: 'флРасчетОстатовПоФинОргануДоступность',
  },
  FIAccNo: {
    label: 'НомерСчетаФО',
    name: 'НомерСчетаФО',
  },
  Fond: {
    label: 'Фонд',
    name: 'Фонд',
  },
  UnderFundedVisible: {
    label: 'НедофинансированоВидимость',
    name: 'НедофинансированоВидимость',
  },
  AccountBalanceVisible: {
    label: 'ОстаткиНаСчетахВидимость',
    name: 'ОстаткиНаСчетахВидимость',
  },
  AccountingByClients: {
    label: 'УчетПоКонтрагентам',
    name: 'УчетПоКонтрагентам',
  },
  ClientInDoc: {
    label: 'КонтрагентВЗаявке',
    name: 'КонтрагентВЗаявке',
  },
  FIParent: { label: 'Фінансовий орган родитель', name: 'ФОРодитель' },
  useFOAccounts: { label: 'КотловыеДляРаспорядителей', name: 'КотловыеДляРаспорядителей' },
  AccTypeHeader: { label: 'ТипСчетаШапка', name: 'ТипСчетаШапка' },
  AccTypeTable: { label: 'ТипСчетаТЧ', name: 'ТипСчетаТЧ' },
  FinancingReturn: { label: 'Повернення фінансування', name: 'флВозвратФинансирования', visible: false },
  NumberOfMounthReturn: { label: 'Місяць повернення', name: 'НомМесяцаВозврата', visible: false },
  ComposeSettings: { label: 'Складне налаштування для повернення фінансування', name: 'флСложнаяНастройка' },
  activeObjectsBuilding: { label: 'флОбъектыСтроительстваВключены', name: 'флОбъектыСтроительстваВключены' },
  result: { label: 'Результат', name: 'Результат' },
  resultColumns: { result: 'Результат' },
  files: { label: 'Додані файли', name: 'Файлы' },
  isSeveralAccount: { label: 'Ознака наявності декількох рахунків', name: 'ЕстьНесколькоСчетов' },
  doNotUseAccounts: { label: 'НеЗаполнятьРСвЗаявке', name: 'НеЗаполнятьРСвЗаявке' },
  createDate: { label: 'Дата створення', name: 'ДатаСоздания' },
  executeDate: { label: 'Дата проведення', name: 'ДатаПроведения' },
  changesAuthor: { label: 'Автор змін', name: 'АвторИзм' },
  letMinusInRow: { label: 'флРазрешитьМинусаВДокФин', name: 'флРазрешитьМинусаВДокФин' },
  ShowSourcesFinInDocFin: {
    label: 'Виводити джерела фінансування в фін. док-ах ',
    name: 'ВыводитьИсточникиФинВДокФин',
    visible: false,
  },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      Sum: { label: 'Сума', name: 'Сумма' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      KVKCode: { label: 'КВК', name: 'КодКВК' },
      Balance: { label: 'Залишок', name: 'Ост' },
      NoteCat: { label: 'Вид платежу', name: 'ПримечаниеСпр' },
      Note: { label: 'Примітка в розпорядженні/ розподілі', name: 'Примечание' },
      YearPlan: { label: 'План на рік', name: 'ПланГод' },
      PeriodPlan: { label: 'План на період', name: 'ПланПериод' },
      MonthPlan: { label: 'План на місяць', name: 'ПланМесяц' },
      PeriodFact: { label: 'Факт на період', name: 'ФактПериод' },
      UnderFunded: { label: 'Залишок з урах. непроф. заявок', name: 'Недофинансировано' },
      DocNotes: { label: 'Шаблон (не обов\'язковий)', name: 'ПримечанияДок' },
      FinLiabilities: { label: 'Фін.зобов\'язання', name: 'ФинОбязательства' },
      FullNotes: { label: 'Примітка в заявці', name: 'ПолноеПримечание' },
      BalanceRegAccounts: { label: 'Залишки на р-них рахунках', name: 'ОстатокНаРегСчетах' },
      BalancePersAccounts: { label: 'Залишки на особ. рахунках', name: 'ОстатокНаОсобСчетах' },
      FinLiabilitiy: { label: 'Фінансове зобов\'язання', name: 'ФинансовоеОбязательство' },
      Client: { label: 'Постачальник', name: 'КонтрагентЗаявка' },
      // Дополнительные свойства
      CSUAccountNo: { label: '№ рахунка', name: 'НомерРСРаспорядителя', visible: false },
      BuildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      TypeRepair: { label: 'Вид ремонту', name: 'ВидРемонтаРеквизит' },
      IsReturn: { label: 'Повернення', name: 'ЭтоВозврат' },
      MounthReturn: { label: 'Місяць повернення', name: 'МесяцВозврата' },
      ElIncome: { label: 'Джерело фінансування', name: 'ЭлементДохода' },
      CodeElIncome: { label: 'Код элемента доходу', name: 'КодЭлементаДохода' },
      finSources: { label: 'Джерело фінансування', name: 'ЭлементДохода' },
      codeFinSources: { label: 'Код джерела фінансування', name: 'КодЭлементаДохода' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Заявка', name: 'Док' },
      IsApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      sum: { label: 'Сума (підсумок)', name: 'СуммаДокумента' },
      AutoNotes: { label: 'Опис', name: 'ДокРаспНижнегоУрДокАвтПримечание' },
      CSU: { label: 'Розподярник', name: 'ДокРаспНижнегоУрДокФинансовыйОрган' },
      author: { label: 'Автор', name: 'Автор' },
      notes: { label: 'Примітка', name: 'ДокРаспНижнегоУрДокументПримечание' },
    },
  },
};

const definition = {
  label: 'Заявка на фінансування',
  name: 'financingRequest',
  backendName: 'ФинансированиеЗаявка',
  columns,
  frontend: 'doc/financingRequest',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'IsApproved',
      'Financed',
      'RNo',
      'Sum',
      'FondObject',
      'Author',
      'FIcode',
      'Tags',
      'Note',
      'FIcode',
      'FIAccount',
      'FI',
      'files',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
  result: { label: 'Результат', name: 'Результат' },
  resultColumns: { result: 'Результат' },
};

export default definition;
