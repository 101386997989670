import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { getRouterEditor } from '../../../editor/basic2';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import CatEditorCommandPanel from '../../../newEditor/commandPanels/catalog';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import md from '../../../../constants/meta/catalogs/noteSocialPayments';
import { modelType, modelName } from '../def';
import NoteSocialPayments from './editor';

function NoteSocialPaymentsEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <CatEditorCommandPanel
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onErr}>
        <Card.Header>
          <NoteSocialPayments data={data} actions={actions} permissions={permissions} />
        </Card.Header>
      </EditorWrapper>
    </>
  );
}

NoteSocialPaymentsEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

NoteSocialPaymentsEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { NoteSocialPaymentsEditor as StdEditor };

export default getRouterEditor(md)(NoteSocialPaymentsEditor);
