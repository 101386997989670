import base from './base';

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  Year: { label: 'Рік планування', name: 'ГодЗатрат' },
  avtoFill: { label: 'Заповнення назви робіт/объекту з бюдж.програми', name: 'флАвтоЗаполениеНаимОбъекта' },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Розподіл коштів бюджету розвитку',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KBP7Code: { label: 'Код КПКВ', name: 'КодКПКВ' },
      nameObj: { label: 'Назва робіт/об`єкту', name: 'НаимОбъекта' },
      deadLine: { label: 'Тривалість будівництва', name: 'СрокСтроительства' },
      startPart: { label: '% виконання на початок періоду', name: 'ПроцентГотовностиНаНачПериода' },
      finishPart: { label: '% виконання на кінець періоду', name: 'ПроцентГотовностиНаКонПериода' },
      sum: { label: 'Загальна вартість, грн', name: 'СуммаВсего' },
      sumYear: { label: 'Сума на рік, грн.', name: 'СуммаПланГод' },
      sumCapital: { label: 'Обсяг капітальних вкладень місцевого бюджету всього', name: 'СуммаВсегоКапитал' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до проекту рішення сесії. Додаток 6 (Розподіл коштів бюджету розвитку)',
  name: 'changeDraftAnnualBR',
  backendName: 'ИзменениеПроектуБР',
  columns,
  frontend: 'doc/changeDraftAnnualBR',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'variantBudgetDraft',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
