import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '4lkW0hsKzQA',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  financing: {
    name: 'Финансирование',
    label: 'Фінансування',
    columns: {
      financingGF_Report: { label: '20__ рік  (звіт)', name: 'ФинансированиеЗФ_Отчет' },
      financingGF_Approved: { label: '20__ рік  (затверджено)', name: 'ФинансированиеЗФ_Утверждено' },
      financingGF_Plan: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План' },
      financingGF_Plan1: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План1' },
      financingGF_Plan2: { label: '20__ рік  (план)', name: 'ФинансированиеЗФ_План2' },
      financingSF_Report: { label: '20__ рік  (звіт)', name: 'ФинансированиеСФ_Отчет' },
      financingSF_Approved: { label: '20__ рік  (затверджено)', name: 'ФинансированиеСФ_Утверждено' },
      financingSF_Plan: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План' },
      financingSF_Plan1: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План1' },
      financingSF_Plan2: { label: '20__ рік  (план)', name: 'ФинансированиеСФ_План2' },
      KFB: { label: 'Код', name: 'КФБ' },
      KFBName: { label: 'Найменування', name: 'КФБНаим' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Показники фінансування бюджету (Додаток 3)',
  name: 'forecastAdd3',
  backendName: 'ПрогнозДод3',
  columns,
  frontend: 'doc/forecastAdd3',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
