import React, { useMemo } from 'react';
import { read, utils } from 'xlsx';
import {
  Nav, Tab, Button, Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function XlxsViewer({ content, filename, href }) {
  const sheets = useMemo(
    () => {
      const workbook = read(content, { type: 'base64' });
      const ss = workbook.Sheets;
      return Object.keys(ss).map((name) => {
        const html = utils.sheet_to_html(ss[name]);
        return { name, html: { __html: html } };
      });
    },
    [content],
  );

  const defaultKey = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => sheets.length ? sheets[0].name : '',
    [sheets],
  );

  return (
    <div className="p-5 mb-4 bg-light rounded-3">
      <Row>
        <Col>
          <Button as="a" download={filename} href={href}>
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Завантажити
          </Button>

          <Tab.Container defaultActiveKey={defaultKey}>
            <Tab.Content>
              {sheets.map((sheet) => (
                <Tab.Pane key={sheet.name} eventKey={sheet.name}>
                  <div dangerouslySetInnerHTML={sheet.html} />
                </Tab.Pane>
              ))}
            </Tab.Content>
            <Nav className="flex-row border-top" variant="pills">
              {sheets.map((sheet) => (
                <Nav.Item key={sheet.name}>
                  <Nav.Link eventKey={sheet.name} className="px-2">
                    {sheet.name}
                  </Nav.Link>

                </Nav.Item>
              ))}

            </Nav>

          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
}

XlxsViewer.propTypes = {
  filename: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default XlxsViewer;
