import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { paramPropType } from '../hooks';

function ParamsItem({
  value, active, onClick,
}) {
  return (
    <ListGroupItem
      active={active}
      onClick={onClick}
    >
      <span>{value.name}</span>
    </ListGroupItem>
  );
}

ParamsItem.propTypes = {
  value: paramPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ParamsItem.defaultProps = {
  active: false,
};

export default ParamsItem;
