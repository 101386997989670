import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const definition = {
  label: 'Звіт за казначейською випискою (зріз за останніми рухами по рахунках) (новий)',
  name: 'TreasureReport',
  columns,
  frontend: 'rep/TreasureReport',
  backendName: 'КазнВыпискаСрезНовый',
  resultColumns: baseResultColumns,
};

export default definition;
