const frontendURL = '/cat/direction/';
const backendURL = '/api/references/refpcmdirection/';
const name = 'Напрями';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
