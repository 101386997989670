import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  reportMonth: { label: 'Місяць звітності', name: 'МесяцОтчетности', visible: true },
  currentFO: { label: 'Завантажувати за установами', name: 'ЗагружатьЗаОрганизациями', visible: false },
  docLogica: { label: 'Документ відправки в Логіка', name: 'ДокОтправкиВЛогика', visible: false },
  isSentLogica: {
    label: 'Підготовлено відправку в Логіку', name: 'ОтправленВЛогику', visible: true, type: 'bool',
  },
  statusLogica: { label: 'СтатусЛогика', name: 'СтатусЛогика', visible: false },
  signLogica: { label: 'ПодписиЛогика', name: 'ПодписиЛогика', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основная',
    label: 'Видатки',
    columns: {
      lineno: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      KBP7: { label: 'КПКВКМБ', name: 'КБП7' },
      NonPedagogicalStaff: { label: 'Фактично зайняті ставки непедагогічного персоналу на початок навчального року, од.', name: 'ЗанятыеСтавкиНГ' },
      ExpensesPrevYear: { label: 'Касові видатки на функціонування закладу у попередньому році (загальний фонд), грн.', name: 'КассовыеПредГодЗФФунк' },
      ExpensesPedWorkersSub: { label: 'Касові видатки на оплату праці з нарахуваннями педагогічних працівників за рахунок коштів освітньої субвенції та залишків освітньої субвенції у попередньому році (загальний фонд), грн.', name: 'КассовыеПредГодЗФОплатаТрудаСубвенция' },
      ExpensesPedWorkersBud: { label: 'Касові  видатки на оплату праці з нарахуваннями педагогічних працівників за рахунок коштів місцевих бюджетів у попередньому році (загальний фонд), грн.', name: 'КассовыеПредГодЗФОплатаТрудаМБ' },
      ExpensesThisYear: { label: 'Планові видатки на функціонування закладу у поточному році (загальний фонд), грн.', name: 'РасходыФункТГ' },
      ExpensesThisYearSub: { label: 'Планові видатки на оплату праці з нарахуваннями педагогічних працівників за рахунок коштів освітньої субвенції та залишків освітньої субвенції у поточному році (загальний фонд), грн.', name: 'РасходыТГОплатаТрудаСубвенция' },
      ExpensesThisYearBud: { label: 'Планові  видатки на оплату праці з нарахуваннями педагогічних працівників за рахунок коштів місцевих бюджетів у поточному році (загальний фонд), грн.', name: 'РасходыТГОплатаТрудаМБ' },
      AvergageSalaryBoss: { label: 'Середньомісячна заробітна плата у попередньому році (без допомоги на оздоровлення та грошової допомоги) на одну ставку директора школи, грн. ', name: 'СредняяЗПНаСтавкуРук' },
      AvergageSalaryTeacher: { label: 'Середньомісячна заробітна плата у попередньому році (без допомоги на оздоровлення та грошової допомоги) на одну ставку вчителя вищої категорії зі стажем роботи не менше 15 років, грн.', name: 'СредняяЗПНаСтавкуУчителя15р' },
      AvergageSalaryYoung: { label: 'Середньомісячна заробітна плата у попередньому році (без допомоги на оздоровлення та грошової допомоги) на одну ставку молодого вчителя зі стажем роботи менше 3 років, грн. ', name: 'СредняяЗПНаСтавкуМолодогоУчителя' },
      CSUtext: { label: 'Найменування', name: 'РаспорядительТекст' },
      EDRPOU: { label: 'ЕДРПОУ', name: 'ЕДРПОУ' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНУ',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Документ' },
      isApproved: { label: 'Затверджений', name: 'Утвержден' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація про бюджетні видатки на освіту',
  name: 'expensesOnEducation',
  backendName: 'ИнформацияОРасходахНаОбразование',
  columns,
  frontend: 'doc/expensesOnEducation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'FI',
      'year',
      'note',
      'author',
      'isSentLogica',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
