import TableListerFactory from './table/table';
import { Doctablerow, Cattablerow, Regtablerow } from './table/row';
import { DocListerCommandPanel, CatListerCommandPanel, RegListerCommandPanel } from './commandpanel';
import { DocTableListerHeader, CatTableListerHeader, RegTableListerHeader } from './table/header';
import { DocCtxMenu, CatCtxMenu } from './contextMenu';

const DocTableLister = TableListerFactory({
  RowComponent: Doctablerow,
  HeaderComponent: DocTableListerHeader,
  CommandPanel: DocListerCommandPanel,
  ContextMenuComponent: DocCtxMenu,
});

/**
 *
 * @param components {{
 *   Row: React.Component,
 *   HeaderComponent: React.Component,
 *   CommandPanel: React.Component,
 *   ContextMenuComponent: React.Component,
 * }}
 * @returns {React.NamedExoticComponent<React.PropsWithoutRef<{readonly err?: *, readonly areaSize?: *, readonly onHideCtxMenu?: *, readonly onSetOrder?: *, readonly onResizeColumn?: *, readonly loading?: *, readonly items?: *, readonly ctxMenu?: *, readonly onResetColumnSize?: *, readonly order?: *, readonly columnSizes?: *, readonly onReoload?: *, readonly permissions?: *, readonly showDeleted?: *, readonly onRowFocus?: *, readonly period?: *, readonly onScroll?: *, readonly onShowCtxMenu?: *, readonly selectedRows?: *, readonly searchString?: *, readonly actions?: *, readonly visibleColumns?: *}> & React.RefAttributes<unknown>> | React.MemoExoticComponent<React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly err?: *, readonly areaSize?: *, readonly onHideCtxMenu?: *, readonly onSetOrder?: *, readonly onResizeColumn?: *, readonly loading?: *, readonly items?: *, readonly ctxMenu?: *, readonly onResetColumnSize?: *, readonly order?: *, readonly columnSizes?: *, readonly onReoload?: *, readonly permissions?: *, readonly showDeleted?: *, readonly onRowFocus?: *, readonly period?: *, readonly onScroll?: *, readonly onShowCtxMenu?: *, readonly selectedRows?: *, readonly searchString?: *, readonly actions?: *, readonly visibleColumns?: *}> & React.RefAttributes<unknown>>>}
 * @constructor
 */
const DocTableListerFactory = (components) => {
  const defaults = {
    RowComponent: Doctablerow,
    HeaderComponent: DocTableListerHeader,
    CommandPanel: DocListerCommandPanel,
    ContextMenuComponent: DocCtxMenu,
  };
  return TableListerFactory({ ...defaults, ...components });
};

const CatTableListerFactory = (components) => {
  const defaults = {
    RowComponent: Cattablerow,
    HeaderComponent: CatTableListerHeader,
    CommandPanel: CatListerCommandPanel,
    ContextMenuComponent: CatCtxMenu,
  };
  return TableListerFactory({ ...defaults, ...components });
};

const InfoRegTableListerFactory = (components) => {
  const defaults = {
    RowComponent: Regtablerow,
    HeaderComponent: RegTableListerHeader,
    CommandPanel: RegListerCommandPanel,
    ContextMenuComponent: CatCtxMenu,
  };
  return TableListerFactory({ ...defaults, ...components });
};

// eslint-disable-next-line import/prefer-default-export
export {
  DocTableLister, DocTableListerFactory, CatTableListerFactory, InfoRegTableListerFactory,
};
