import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
  DocumentID: {
    label: 'Ид документа',
    name: 'ИдДокумента',
    visible: false,
  },
  DocumentType: {
    label: 'Вид Документа',
    name: 'ВидДокумента',
    visible: true,
  },
  MaxSignNumber: {
    label: 'Макс Номер Подписи',
    name: 'МаксНомерПодписи',
    visible: false,
  },
  docTypeList: { label: 'Список видів документів', name: 'СписокВидовДокументов', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'ГруппыПодписей',
    label: 'Групи підписів',
    columns: {
      signGroup: { label: 'Група підпису', name: 'ГруппаПодписи' },
    },
  },
};

/**
 * @const
 */
const signGroupByDocuments = {
  name: 'signGroupByDocuments',
  label: 'Групи пiдписiв за документами',
  backendName: 'ГруппыПодписейПоДокументам',
  columns,
  frontend: 'cat/signGroupByDocuments',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'DocumentType',
    ]),
  ],
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  tables,
  hierarchy: false,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default signGroupByDocuments;
