import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  NumberInput, ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/energyIndicators';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'indicators';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights, autoCalculate,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow noGutters rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="department" highlighted={highlights.includes('department')}>
          <ItemPicker
            value={row[tableMD.columns.department.name]}
            onChange={(e, v) => {
              onRowChange(e, { [tableMD.columns.department.name]: v });
              if (autoCalculate) {
                onSR('Calculate');
              }
            }}
            modelType="cat"
            modelName="department"
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <Row noGutters>
          <TableRowCell column="receivable" highlighted={highlights.includes('receivable')}>
            <NumberInput
              value={row[tableMD.columns.receivable.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.receivable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
        <Row noGutters>
          <TableRowCell column="payable" highlighted={highlights.includes('payable')}>
            <NumberInput
              value={row[tableMD.columns.payable.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.payable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <TableRowCell column="limits" highlighted={highlights.includes('limits')}>
            <NumberInput
              value={row[tableMD.columns.limits.name]}
              precision={3}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.limits.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
        <Row noGutters>
          <TableRowCell column="approved" highlighted={highlights.includes('approved')}>
            <NumberInput
              value={row[tableMD.columns.approved.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.approved.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
      </Col>
      <Col sm={2}>
        <Row noGutters>
          <Col className="p-0">
            <TableRowCell column="yearNaturalConsumed" highlighted={highlights.includes('yearNaturalConsumed')}>
              <NumberInput
                value={row[tableMD.columns.yearNaturalConsumed.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.yearNaturalConsumed.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
          <Col className="p-0">
            <TableRowCell column="yearConsumed" highlighted={highlights.includes('yearConsumed')}>
              <NumberInput
                value={row[tableMD.columns.yearConsumed.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.yearConsumed.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableRowCell column="monthNaturalConsumed" highlighted={highlights.includes('monthNaturalConsumed')}>
              <NumberInput
                value={row[tableMD.columns.monthNaturalConsumed.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.monthNaturalConsumed.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
          <Col className="p-0">
            <TableRowCell column="monthConsumed" highlighted={highlights.includes('monthConsumed')}>
              <NumberInput
                value={row[tableMD.columns.monthConsumed.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.monthConsumed.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col sm={2}>
        <Row noGutters>
          <Col className="p-0">
            <TableRowCell column="yearPaid" highlighted={highlights.includes('yearPaid')}>
              <NumberInput
                value={row[tableMD.columns.yearPaid.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.yearPaid.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
          <Col className="p-0">
            <TableRowCell column="pastYearPaid" highlighted={highlights.includes('pastYearPaid')}>
              <NumberInput
                value={row[tableMD.columns.pastYearPaid.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.pastYearPaid.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="p-0">
            <TableRowCell column="monthPaid" highlighted={highlights.includes('monthPaid')}>
              <NumberInput
                value={row[tableMD.columns.monthPaid.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.monthPaid.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
          <Col className="p-0">
            <TableRowCell column="pastYearMonthPaid" highlighted={highlights.includes('pastYearMonthPaid')}>
              <NumberInput
                value={row[tableMD.columns.pastYearMonthPaid.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.pastYearMonthPaid.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <TableRowCell column="writtenOffReceivable" highlighted={highlights.includes('writtenOffReceivable')}>
            <NumberInput
              value={row[tableMD.columns.writtenOffReceivable.name]}
              precision={3}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.writtenOffReceivable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
        <Row noGutters>
          <TableRowCell column="writtenOffPayable" highlighted={highlights.includes('writtenOffPayable')}>
            <NumberInput
              value={row[tableMD.columns.writtenOffPayable.name]}
              precision={3}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.writtenOffPayable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <TableRowCell column="pastReceivable" highlighted={highlights.includes('pastReceivable')}>
            <NumberInput
              value={row[tableMD.columns.pastReceivable.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.pastReceivable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
        <Row noGutters>
          <TableRowCell column="pastPayable" highlighted={highlights.includes('pastPayable')}>
            <NumberInput
              value={row[tableMD.columns.pastPayable.name]}
              precision={2}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.pastPayable.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
      </Col>
      <Col sm={2}>
        <Row noGutters>
          <Col className="p-0">
            <TableRowCell column="receivableOnDate" highlighted={highlights.includes('receivableOnDate')}>
              <NumberInput
                value={row[tableMD.columns.receivableOnDate.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.receivableOnDate.name]: v,
                })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
          <Col>
            <Row noGutters>
              <TableRowCell column="pastYearPayable" highlighted={highlights.includes('pastYearPayable')}>
                <NumberInput
                  value={row[tableMD.columns.pastYearPayable.name]}
                  precision={2}
                  onChange={(e, v) => onRowChange(e, {
                    [tableMD.columns.pastYearPayable.name]: v,
                  })}
                  readOnly={readOnly}
                  required
                />
              </TableRowCell>
            </Row>
            <Row noGutters>
              <TableRowCell column="payableOnDate" highlighted={highlights.includes('payableOnDate')}>
                <NumberInput
                  value={row[tableMD.columns.payableOnDate.name]}
                  precision={2}
                  onChange={(e, v) => onRowChange(e, {
                    [tableMD.columns.payableOnDate.name]: v,
                  })}
                  readOnly={readOnly}
                  required
                />
              </TableRowCell>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row noGutters>
          <TableRowCell column="pastYearConsumed" highlighted={highlights.includes('pastYearConsumed')}>
            <NumberInput
              value={row[tableMD.columns.pastYearConsumed.name]}
              precision={3}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.pastYearConsumed.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
        <Row noGutters>
          <TableRowCell column="pastYearPeriodConsumed" highlighted={highlights.includes('pastYearPeriodConsumed')}>
            <NumberInput
              value={row[tableMD.columns.pastYearPeriodConsumed.name]}
              precision={3}
              onChange={(e, v) => onRowChange(e, {
                [tableMD.columns.pastYearPeriodConsumed.name]: v,
              })}
              readOnly={readOnly}
              required
            />
          </TableRowCell>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoCalculate: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
