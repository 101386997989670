import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  method: { label: 'Метод розрахунку', name: 'МетодРасчета' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
};

const definition = {
  label: 'Методика розрахунків для документів "Інформація щодо ліквідності"',
  name: 'LiquidityMethod',
  columns,
  frontend: 'rep/LiquidityMethod',
  backendName: 'МетодыИнформацияЛиквидности',
  resultColumns: baseResultColumns,
};

export default definition;
