import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMessage = styled.div`
    z-index: 1000;
    position: relative !important;
    background: #ffe4d9;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
`;
const HeaderError = styled.p`
    font-weight: 700;
    margin-bottom: 3px;
`;

const getFieldWithErrMsg = (WrappedField) => class ErrWC extends PureComponent {
  static propTypes = {
    errText: PropTypes.string,
  };

  static defaultProps = {
    errText: '',
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  render() {
    const { errText, ...rest } = this.props;

    return (
      <div ref={this.node}>
        {this.node.current && errText ? (
          <div>
            {/* mountNode={this.node.current} */}
            <StyledMessage>
              <HeaderError>Виникла помилка</HeaderError>
              <span>{errText}</span>
            </StyledMessage>
          </div>
        ) : null}
        <WrappedField {...rest} />
      </div>
    );
  }
};

export default getFieldWithErrMsg;
