import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PrintGOV from '../../prepareForecastAdd1/lister/listerPrintModal';

function PrintListGOV({ selectedRows, disabled }) {
  return (
    <Dropdown disabled={disabled}>
      <Dropdown.Toggle variant="info" disabled={disabled}>
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Граничний обсяг видатків
      </Dropdown.Toggle>
      <Dropdown.Menu disabled={disabled}>
        <PrintGOV
          selectedRows={selectedRows}
          docType="prepareForecastAdd4"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

PrintListGOV.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

PrintListGOV.defaultProps = {
  selectedRows: [],
  disabled: true,
};
export default PrintListGOV;
