import React, { useEffect, useRef, useState } from 'react';
import init from './eusign';
import CommandPanelButton from '../../../../components/button/commandPanelButton';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

//= ================================================================
// Ідентифікатор батківського елементу для відображення iframe,
// який завантажує сторінку SignWidget

const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
// Ідентифікатор iframe, який завантажує сторінку SignWidget

const SIGN_WIDGET_ID = 'sign-widget';

// "https://eu.iit.com.ua/sign-widget/v20200311/"`
// URI з адресою за якою розташована сторінка SignWidget
// https://eu.iit.com.ua/sign-widget/

const SIGN_WIDGET_URI = 'https://certs.ciat.net.ua/';
const SIGN_WIDGET_URI2 = 'https://certs2.ciat.net.ua/';

// const { endUser } = init();

function Widget() {
  const [widgetDija, setWidgetDija] = useState(false);
  const widgetURI = widgetDija ? SIGN_WIDGET_URI : SIGN_WIDGET_URI2;
  const EndUserRef = useRef(null);
  const EuSignRef = useRef(null);
  useEffect(() => {
    EndUserRef.current = init();

    // console.log('EndUserRef', EndUserRef.current);

    EuSignRef.current = new EndUserRef.current(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      widgetURI,
      EndUserRef.current.FormType.ReadPKey,
    );

    //= ============================================================================

    // Створення об'єкту типу endUser для взаємодії з iframe,
    // який завантажує сторінку SignWidget

    EuSignRef.current.ReadPrivateKey()
      .then((res) => {
        document.getElementById('sign-widget-parent').style.display = 'none';
        document.getElementById('sign-data-block').style.display = 'block';
        console.log('ReadPrivateKey', res);
      })
      .catch((e) => {
        console.log('!!!!!!!!!!!!! Виникла помилка при зчитуванні ос. ключа. !!!!!!!!!!!!!!!!!!!!');
        alert(`${'Виникла помилка при зчитуванні ос. ключа. '
        + 'Опис помилки: '}${e.message || e}`);
      });
    return () => EndUserRef.current = null;
  }, [widgetURI]);

  //= ============================================================================
  // Накладання підпису на дані

  function onSign() {
    // const data = document.getElementById('textAreaData').value;
    const data = 'wwwwwwwwwww';
    const external = false;
    const asBase64String = true;
    const signAlgo = EndUserRef.current.SignAlgo.DSTU4145WithGOST34311;
    const signType = EndUserRef.current.SignType.CAdES_X_Long;

    console.log('CommandPanelButton');

    // console.log('endUser', EndUserRef.current);
    //
    // console.log('data', data);

    EuSignRef.current.SignData(
      data,
      external,
      asBase64String,
      signAlgo,
      null,
      signType,
    )
      .then((sign) => {
        console.log('============= sign ==================', sign);
        // document.getElementById('textAreaSign').value = sign;
      })
      .catch((e) => {
        alert(`${'Виникла помилка при підписі даних. '
			+ 'Опис помилки: '}${e.message || e}`);
      });
  }

  return (
    <>
      <div id="sign-widget-parent" style={{ width: '700px', height: '500px' }} />

      <EditorControls.CheckboxInput
        controlId="Dija"
        label="Використовувати віджет Дії"
        value={widgetDija}
        onChange={(e, value) => {
          const iFrame = document.getElementById('sign-widget');
          if (iFrame) iFrame.remove();
          setWidgetDija(value);
        }}
        readOnly={false}
      />

      <CommandPanelButton
        onClick={() => onSign()}
      >
        Підписати
      </CommandPanelButton>

    </>
  );

  // Очікування зчитування ос. ключа користувачем
}

//= ============================================================================

export default Widget;
//
