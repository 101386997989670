import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable/dist/immutable';
import { fillTable } from '../../../../actions/editor';
import { CommandPanelButton } from '../../../../components/button';
import { CheckAllIcon, UnChekAllIcon } from '../../../../assets/icons';
import { editor as actions } from '../../../../actions';
import { CommandPanelColor } from '../../../../components/Form/styledForm';
import md from '../../../../constants/meta/documents/financing';
import Fields from '../../../field';
import storePathParam from '../../../../common/storePathParam';

export const ContainerButton = styled.div`
  display: flex;
`;

function DocsCP({
  disabled, tableName, dispatch, headerForm,
}) {
  return (
    <ContainerButton>
      <CommandPanelButton
        text="Відмітити всі"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['IsApproved'], true))}
      >
        <CheckAllIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Зняти відмітки"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['IsApproved'], false))}
      >
        <UnChekAllIcon />
      </CommandPanelButton>
      <CommandPanelColor
        label="Друк підлеглого документу"
      />
      <Fields.CheckboxField
        style={{ display: 'flex', alignSelf: 'center' }}
        value={headerForm.get('AddApplication', false)}
        onChange={(e, v) => {
          dispatch(actions.changeField(['headerForm', 'AddApplication'], v));
          // dispatch(actions.processServerReq('ADD_APLICATION'));
        }}
        label={md.columns.AddApplication.label}
      />
    </ContainerButton>
  );
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

DocsCP.propTypes = {
  tableName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map),
};

DocsCP.defaultProps = {
  disabled: false,
  headerForm: new Map(),
};

export default connect(mapStateTP)(DocsCP);
