function valueFormatter(value) {
  const formats = [
    {
      limit: 0, label: 'грн', div: 1, places: 2,
    },
    {
      limit: 1000, label: 'тис. грн', div: 1000, places: 3,
    },
    {
      limit: 1000000, label: 'млн. грн', div: 1000000, places: 3,
    },
    {
      limit: 1000000000, label: 'млрд. грн', div: 1000000000, places: 3,
    },
  ];
  return formats
    .filter((r) => r.limit <= Math.abs(value))
    .reduce((R, r) => `${(value / r.div)
      .toLocaleString('uk', { maximumFractionDigits: r.places })} ${r.label}`, '');
}

export default valueFormatter;
