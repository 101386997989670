import React from 'react';
import md from '../../../../../meta/doc/finRequest';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { DocTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_byAuthority/lister_selector';
import FintrquestRow from './row';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'lister',
})(DocTableListerFactory({
  RowComponent: FintrquestRow,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
