/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/expensesOnEducation';
import useTablePart from '../../../../../newEditor/hook/tablepart';

const tableMeta = md.tables.general;

function GeneralTP({ data, actions, readOnly }) {
  const FI = data[md.columns.FI.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            //
            FI={FI}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
