import styled, { keyframes } from 'styled-components';

const blinking = keyframes`
  from {
    opacity: 100%;
  }
  40% {
    opacity: 100%;
  }
  50% {
    opacity: 10%;
  }
  90% {
    opacity: 10%;
  }
  to {
    opacity: 100%;
  }
`;

const Cursor = styled.span`
    animation: ${blinking} 1s linear infinite;
    &:after{
      content: '|';
    }  
`;

export default Cursor;
