import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
  disposerInHeader,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col className="gx-2 gy-0" md={6}>
        {!disposerInHeader && (
          <Col className="gx-1" md={12}>
            <TableHeaderCell
              className="p-0 m-0 text-center"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
          </Col>
        ) }
        <Col className="gx-1" md={12}>
          <TableHeaderCell
            className="text-center p-0 m-0"
            active={activeCol === 'target_programm'}
            highlighted={highlights.includes('target_programm')}
            onToggleHighlight={() => onToggleHighlight('target_programm')}
          >
            {fields.target_programm.label}
          </TableHeaderCell>
        </Col>
        <Col className="gx-1" md={12}>
          <TableHeaderCell
            className="text-center p-0 m-0"
            active={activeCol === 'kbp7_item'}
            highlighted={highlights.includes('kbp7_item')}
            onToggleHighlight={() => onToggleHighlight('kbp7_item')}
          >
            {fields.kbp7_item.label}
          </TableHeaderCell>
        </Col>
      </Col>
      <Col className="gx-1 gy-0" md={6}>
        <Row className="gy-0">
          <Col className="gx-1" md={8}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'zf'}
              highlighted={highlights.includes('zf')}
              onToggleHighlight={() => onToggleHighlight('zf')}
            >
              {fields.zf.label}
            </TableHeaderCell>
          </Col>
          <Col className="gx-1" md={2}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'total'}
              highlighted={highlights.includes('total')}
              onToggleHighlight={() => onToggleHighlight('total')}
            >
              &#8721;
            </TableHeaderCell>
          </Col>
        </Row>
        <Row className="gy-0">

          <Col className="gx-1" md={4}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'sf'}
              highlighted={highlights.includes('sf')}
              onToggleHighlight={() => onToggleHighlight('sf')}
            >
              {fields.sf.label}
            </TableHeaderCell>
          </Col>
          <Col className="gx-1" md={4}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'dev_budget'}
              highlighted={highlights.includes('dev_budget')}
              onToggleHighlight={() => onToggleHighlight('dev_budget')}
            >
              {fields.dev_budget.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row className="gy-0">
          <Col className="gx-0" md={12}>
            <TableHeaderCell
              className="text-center p-0 m-0"
              active={activeCol === 'note'}
              highlighted={highlights.includes('note')}
              onToggleHighlight={() => onToggleHighlight('note')}
            >
              {fields.note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>

    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  //
  disposerInHeader: PropTypes.bool,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
};
export default Header;
