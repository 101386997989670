import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  fields, activeCol, highlights, onToggleHighlight, on3Years, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kdb'}
              highlighted={highlights.includes('kdb')}
              onToggleHighlight={() => onToggleHighlight('kdb')}
            >
              {fields.kdb.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              title={totals.first_year_zf}
              active={activeCol === 'first_year_zf'}
              highlighted={highlights.includes('first_year_zf')}
              onToggleHighlight={() => onToggleHighlight('first_year_zf')}
            >
              {fields.first_year_zf.label}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.second_year_zf}
                active={activeCol === 'second_year_zf'}
                highlighted={highlights.includes('second_year_zf')}
                onToggleHighlight={() => onToggleHighlight('second_year_zf')}
              >
                {fields.second_year_zf.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.third_year_zf}
                active={activeCol === 'third_year_zf'}
                highlighted={highlights.includes('third_year_zf')}
                onToggleHighlight={() => onToggleHighlight('third_year_zf')}
              >
                {fields.third_year_zf.label}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              title={totals.first_year_sf}
              active={activeCol === 'first_year_sf'}
              highlighted={highlights.includes('first_year_sf')}
              onToggleHighlight={() => onToggleHighlight('first_year_sf')}
            >
              {fields.first_year_sf.label}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.second_year_sf}
                active={activeCol === 'second_year_sf'}
                highlighted={highlights.includes('second_year_sf')}
                onToggleHighlight={() => onToggleHighlight('second_year_sf')}
              >
                {fields.second_year_sf.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.third_year_sf}
                active={activeCol === 'third_year_sf'}
                highlighted={highlights.includes('third_year_sf')}
                onToggleHighlight={() => onToggleHighlight('third_year_sf')}
              >
                {fields.third_year_sf.label}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              title={totals.first_year_br}
              active={activeCol === 'first_year_br'}
              highlighted={highlights.includes('first_year_br')}
              onToggleHighlight={() => onToggleHighlight('first_year_br')}
            >
              {fields.first_year_br.label}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.second_year_br}
                active={activeCol === 'second_year_br'}
                highlighted={highlights.includes('second_year_br')}
                onToggleHighlight={() => onToggleHighlight('second_year_br')}
              >
                {fields.second_year_br.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.third_year_br}
                active={activeCol === 'third_year_br'}
                highlighted={highlights.includes('third_year_br')}
                onToggleHighlight={() => onToggleHighlight('third_year_br')}
              >
                {fields.third_year_br.label}
              </TableHeaderCell>
            </>
            )}
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              title={totals.first_year_total}
              active={activeCol === 'first_year_total'}
              highlighted={highlights.includes('first_year_total')}
              onToggleHighlight={() => onToggleHighlight('first_year_total')}
            >
              {fields.first_year_total.label}
            </TableHeaderCell>
            {on3Years && (
            <>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.second_year_total}
                active={activeCol === 'second_year_total'}
                highlighted={highlights.includes('second_year_total')}
                onToggleHighlight={() => onToggleHighlight('second_year_total')}
              >
                {fields.second_year_total.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                title={totals.third_year_total}
                active={activeCol === 'third_year_total'}
                highlighted={highlights.includes('third_year_total')}
                onToggleHighlight={() => onToggleHighlight('third_year_total')}
              >
                {fields.third_year_total.label}
              </TableHeaderCell>
            </>
            )}
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  fields: fieldsPropTypes.isRequired,
  on3Years: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    first_year_zf: PropTypes.number,
    second_year_zf: PropTypes.number,
    third_year_zf: PropTypes.number,
    first_year_sf: PropTypes.number,
    second_year_sf: PropTypes.number,
    third_year_sf: PropTypes.number,
    first_year_br: PropTypes.number,
    second_year_br: PropTypes.number,
    third_year_br: PropTypes.number,
    first_year_total: PropTypes.number,
    second_year_total: PropTypes.number,
    third_year_total: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
  on3Years: false,
};

export default Header;
