import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/common';

const meta = md.tables.sourcesInvestmentProjects;

function TPHeader({
  totals,
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters className="border-bottom">
          <Col lg={1}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'fond'}
              highlighted={highlights.includes('fond')}
              onToggleHighlght={() => onToggleHighlght('fond')}
            >
              {meta.columns.fond.label}
            </TableHeaderCell>
          </Col>
          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'targetedPrograms'}
              highlighted={highlights.includes('targetedPrograms')}
              onToggleHighlght={() => onToggleHighlght('targetedPrograms')}
            >
              {meta.columns.targetedPrograms.label}
            </TableHeaderCell>
          </Col>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kdbClassifier'}
              highlighted={highlights.includes('kdbClassifier')}
              onToggleHighlght={() => onToggleHighlght('kdbClassifier')}
            >
              {meta.columns.kdbClassifier.label}
            </TableHeaderCell>
          </Col>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kfbClassifier'}
              highlighted={highlights.includes('kfbClassifier')}
              onToggleHighlght={() => onToggleHighlght('kfbClassifier')}
            >
              {meta.columns.kfbClassifier.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumResultGenF'}
              title={totals.sumResultGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumResultGenF')}
              onToggleHighlght={() => onToggleHighlght('sumResultGenF')}
            >
              {meta.columns.sumResultGenF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumResultSpecF'}
              title={totals.sumResultSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumResultSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumResultSpecF')}
            >
              {meta.columns.sumResultSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCashGenF'}
              title={totals.sumCashGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumCashGenF')}
              onToggleHighlght={() => onToggleHighlght('sumCashGenF')}
            >
              {meta.columns.sumCashGenF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCashSpecF'}
              title={totals.sumCashSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumCashSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumCashSpecF')}
            >
              {meta.columns.sumCashSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanGenF'}
              title={totals.sumPlanGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPlanGenF')}
              onToggleHighlght={() => onToggleHighlght('sumPlanGenF')}
            >
              {meta.columns.sumPlanGenF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanSpecF'}
              title={totals.sumPlanSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPlanSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumPlanSpecF')}
            >
              {meta.columns.sumPlanSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactGenF'}
              title={totals.sumFactGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactGenF')}
              onToggleHighlght={() => onToggleHighlght('sumFactGenF')}
            >
              {meta.columns.sumFactGenF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactSpecF'}
              title={totals.sumFactSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumFactSpecF')}
            >
              {meta.columns.sumFactSpecF.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPrognosisGenF'}
              title={totals.sumPrognosisGenF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPrognosisGenF')}
              onToggleHighlght={() => onToggleHighlght('sumPrognosisGenF')}
            >
              {meta.columns.sumPrognosisGenF.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPrognosisSpecF'}
              title={totals.sumPrognosisSpecF.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPrognosisSpecF')}
              onToggleHighlght={() => onToggleHighlght('sumPrognosisSpecF')}
            >
              {meta.columns.sumPrognosisSpecF.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumResultTotal'}
              title={totals.sumResultTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumResultTotal')}
              onToggleHighlght={() => onToggleHighlght('sumResultTotal')}
            >
              {meta.columns.sumResultTotal.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCashTotal'}
              title={totals.sumCashTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumCashTotal')}
              onToggleHighlght={() => onToggleHighlght('sumCashTotal')}
            >
              {meta.columns.sumCashTotal.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPlanTotal'}
              title={totals.sumPlanTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPlanTotal')}
              onToggleHighlght={() => onToggleHighlght('sumPlanTotal')}
            >
              {meta.columns.sumPlanTotal.label}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFactTotal'}
              title={totals.sumFactTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumFactTotal')}
              onToggleHighlght={() => onToggleHighlght('sumFactTotal')}
            >
              {meta.columns.sumFactTotal.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumPrognosisTotal'}
              title={totals.sumPrognosisTotal.toLocaleString('uk', moneysStingOptions)}
              highlighted={highlights.includes('sumPrognosisTotal')}
              onToggleHighlght={() => onToggleHighlght('sumPrognosisTotal')}
            >
              {meta.columns.sumPrognosisTotal.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'backgroundTemplate'}
              highlighted={highlights.includes('backgroundTemplate')}
              onToggleHighlght={() => onToggleHighlght('backgroundTemplate')}
            >
              {meta.columns.backgroundTemplate.label}
            </TableHeaderCell>
          </Col>
          <Col lg={8}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'background'}
              highlighted={highlights.includes('background')}
              onToggleHighlght={() => onToggleHighlght('background')}
            >
              {meta.columns.background.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'explanationDeviation'}
              highlighted={highlights.includes('explanationDeviation')}
              onToggleHighlght={() => onToggleHighlght('explanationDeviation')}
            >
              {meta.columns.explanationDeviation.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    sumResultGenF: PropTypes.number,
    sumResultSpecF: PropTypes.number,
    sumResultTotal: PropTypes.number,
    sumCashGenF: PropTypes.number,
    sumCashSpecF: PropTypes.number,
    sumCashTotal: PropTypes.number,
    sumPlanGenF: PropTypes.number,
    sumPlanSpecF: PropTypes.number,
    sumFactGenF: PropTypes.number,
    sumFactSpecF: PropTypes.number,
    sumPlanTotal: PropTypes.number,
    sumFactTotal: PropTypes.number,
    sumPrognosisTotal: PropTypes.number,
    sumPrognosisGenF: PropTypes.number,
    sumPrognosisSpecF: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
