import { List, Map, OrderedMap } from 'immutable';
import { useNavigate } from 'react-router-dom';
import { editorActions } from '../constants/actions/editor';
import definition from '../constants/meta';
import api from '../api/req';
import { toSrvMapper, fromSrvMapper, resultMapper } from '../common/editorMappers';
import { saveModes } from '../constants/meta/common';
import storePathParam from '../common/storePathParam';
import { sendUpdateSignal } from './windowsManager';
import useWinManager from '../components/windowsManager/Hooks/useWinManager';

const filterActiveItem = (v) => v.get('IS_ACTIVE', false);

export const registerWarnings = (key, warnings) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  dispatch({
    type: editorActions.REGISTER_WARNINGS,
    payload: {
      warningsKey: key,
      items: warnings,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const clearWarnings = () => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  dispatch({
    type: editorActions.CLEAR_WARNINGS,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

/* LOADING ACTIONS */
// const loadDoneHandler = (dispatch, getState) => {};
export const loadDone = (promiseResponse) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const modelName = storePathParam(store).name;

  const modelType = storePathParam(store).type;
  const { id } = storePathParam(store);

  const modelDef = definition[modelType][modelName];
  const response = promiseResponse;

  /**
   * @param {Immutable.Map} r
   * @param {object} v
   */
  const item2 = fromSrvMapper(modelDef, response);
  const item = {
    headerForm: item2.headerForm
      .merge(new Map(resultMapper(modelDef, response))),
    tables: item2.tables,
  };

  dispatch(clearWarnings());

  dispatch({
    type: editorActions.LOAD_DONE,
    payload: {
      item,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

// const loadErrorHandler = (dispatch, getState) => {};
const loadError = (e, type, name, id) => (dispatch) => {
  dispatch({
    type: editorActions.LOAD_ERR,
    payload: {
      e,
      type,
      model: name,
      id,
    },
  });
};

/**
 * @return {function(func)}
 * ЧТО ТАКОЕ t и n?????????????????????????????????????????????
 * И ДЛЯ ЧЕГО ОНИ НУЖНЫ?
 */
export const load = (t = '', n = '', loadId = '') => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */
  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    const { id } = storePathParam(store);

    const node = store.getIn([`${modelType}/${modelName}/editor`, id], new Map());
    const itemId = (loadId === 'fl_infoRegs') ? loadId : node.get('itemId', 'create');

    const vi = store.getIn([`${modelType}/${modelName}/lister`, 'visibleItems'], new OrderedMap());
    const activeId = vi.filter((i) => i.get('ACTIVE', false)).keySeq().first();

    const initParams = activeId ? ({ activeId, ...node.get('initParams') }) : node.get('initParams');

    const modelDef = definition[modelType][modelName];

    dispatch({
      type: editorActions.LOAD_START,
      payload: {
        type: modelType,
        model: modelName,
        id,
      },
    });

    api
      .post$(`${modelType}/${modelDef.backendName}/${itemId}`, initParams)
      .then((d) => {
        if (d.ok) {
          try {
            d.json()
              .then((data) => {
                dispatch(loadDone(data));
              })
              .catch((e) => console.log('error pasrsing json ', e));
          } catch (e) {
            console.log('loadError');
            dispatch(loadError(e.message, modelType, modelName, id));
          }
        } else {
          console.log('else error');
          d.text()
            .then((t) => dispatch(loadError(t, modelType, modelName, id)))
            .catch((e) => dispatch(loadError(e.message, modelType, modelName, id)));
        }
      })
      .catch((e) => {
        console.error('LOAD_START error ', e);
        dispatch(loadError(e, modelType, modelName, id));
      });
  };
  return handler;
};

export const loadAttachedFiles = () => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */
  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    const { id } = storePathParam(store);

    dispatch({
      type: editorActions.PROCESS_ATTACHED_FILES,
      payload:
          {
            isLoadingAttachedFiles: false,
            AttachedFiles: [],
            type: modelType,
            model: modelName,
            id,
          },
    });

    api.post$(`${modelType}/${definition[modelType][modelName].backendName}/${id}/attachedFiles`).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          const AttachedFiles = d.reduce((R, f) => R.push(new Map({
            content: f.content,
            type: f.type,
            fileName: f.filename,
            description: f.description,
          })), new List());

          dispatch({
            type: editorActions.PROCESS_ATTACHED_FILES,
            payload:
                {
                  isLoadingAttachedFiles: true,
                  AttachedFiles,
                  type: modelType,
                  model: modelName,
                  id,
                },
          });
        });
      } else {
        console.error(r.statusText);
      }
    });
  };
  return handler;
};

export const upLoadAttachedFiles = (files) => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */

  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    const { id } = storePathParam(store);

    // const node = store.get('editor');
    //
    // const modelName = node.get('modelName', '');
    // const modelType = node.get('modelType', '');
    // const id = node.get('itemId');

    dispatch({
      type: editorActions.PROCESS_ADD_FILES,
      payload:
                {
                  isLoadingAttachedFiles: false,
                  type: modelType,
                  model: modelName,
                  id,
                },
    });

    const modelDef = definition[modelType][modelName];

    files.filter((f) => !f.done).forEach(async (file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataFile = {
          id,
          modelType,
          modelName: modelDef.backendName,
          fileName: file.file.name,
          task: file.task,
          file: reader.result,
        };

        api.post$(`${modelType}/${modelDef.backendName}/${id}/addedFiles/`, dataFile).then(async (r) => {
          if (r.ok) {
            const res = await r.json();
            files[res.task].done = res.done;

            dispatch(loadAttachedFiles());

            dispatch({
              type: editorActions.PROCESS_ADD_FILES,
              payload:
              {
                isLoadingAttachedFiles: true,
                type: modelType,
                model: modelName,
                id,
              },
            });
          }
        });
      };

      if (file.file) {
        reader.readAsDataURL(file.file);
      }
    });
  };
  return handler;
};

export const deleteAttachedFile = (fileName) => {
  /**
   * @param {func} dispatch
   * @param {func} getState
   */

  const handler = (dispatch, getState) => {
    /**
     * @const
     * @type {Immutable.Map}
     */
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    const { id } = storePathParam(store);

    dispatch({
      type: editorActions.DELETE_ATTACHED_FILES,
      payload:
                {
                  isLoadingAttachedFiles: false,
                  type: modelType,
                  model: modelName,
                  id,
                },
    });

    const modelDef = definition[modelType][modelName];

    const dataFile = {
      id,
      modelType,
      modelName: modelDef.backendName,
      fileName,
    };

    api.post$(`${modelType}/${modelDef.backendName}/${id}/deleteFiles/`, dataFile).then(async (r) => {
      if (r.ok) {
        dispatch(loadAttachedFiles());

        dispatch({
          type: editorActions.DELETE_ATTACHED_FILES,
          payload:
              {
                isLoadingAttachedFiles: true,
                type: modelType,
                model: modelName,
                id,
              },
        });
      }
    });
  };
  return handler;
};

/* INIT ACTION */

/**
 * @param _type {string}
 * @param model {string}
 * @param id {string}
 * @param initParams {object}
 * @return {function(*)}
 */
export const init = (_type, model, id, initParams = null) => (dispatch) => {
  dispatch({
    type: editorActions.INIT,
    payload: {
      type: `${_type}`,
      model,
      id,
      initParams,
    },
  });
};

/* LOCK ACTION */

/**
 * @function
 * @param dispatch {func}
 */
const serverLockDoneHandler = (type, model, id) => (dispatch) => {
  dispatch({
    type: editorActions.LOCK_ERR,
    payload: {
      type,
      model,
      id,
    },
  });
};
const serverLockDone = (type, model, id) => serverLockDoneHandler;

// const serverLockErrorHandler = (dispatch, getState) => {};
const serverLockError = (type, model, id, e) => (dispatch) => {
  console.error(e);
  dispatch({
    type: '',
    payload: {
      type,
      model,
      id,
    },
  });
};

/**
 * @function
 * @param dispatch {func}
 */
const serverLockStartHandler = (type, model, id) => (dispatch) => {
  dispatch({
    type: '',
    payload: {
      type,
      model,
      id,
    },
  });
  fetch()
    .then((resp) => {
      if (resp.ok) {
        dispatch(serverLockDone(type, model, id, resp.json()));
      } else {
        dispatch(serverLockError(type, model, id, 'Server Error'));
      }
    })
    .catch(() => {
      dispatch(serverLockError(type, model, id, 'Network Error'));
    });
};
export const serverLock = (type, model, id) => serverLockStartHandler;

/* UNLOCK ACTIONS */

/**
 * @function
 * @param dispatch {func}
 */
const serverUnlockDoneHandler = (dispatch) => {};

// const serverUnlockErrorHandler = (dispatch, getState) => {};
const serverUnlockError = (e) => (dispatch) => {};

/**
 * @function
 * @param dispatch {func}
 * @param getState {func}
 */
const serverUnlockStartHandler = (dispatch) => {
  dispatch({
    type: '',
    payload: {},
  });

  fetch()
    .then((resp) => {
      if (resp.ok) {
        dispatch(serverUnlockDoneHandler);
      } else {
        dispatch(serverUnlockError('Server error'));
      }
    })
    .catch(() => {
      dispatch(serverUnlockError('Network Error'));
    });
};
export const serverUnlock = () => serverUnlockStartHandler;

/* @@@@@@@@@@@@@@@@@ SAVE HANDLER @@@@@@@@@@@@@@@@@@ */
export const saveDone = (data) => {
  /**
   * @function
   * @param dispatch {func}
   * @param getState {func}
   */
  const saveDoneHandler = (dispatch, getState) => {
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    const { id } = storePathParam(store);

    dispatch({
      type: editorActions.SAVE_DONE,
      payload: {
        type: modelType,
        model: modelName,
        id,
      },
    });
    dispatch(sendUpdateSignal(definition[modelType][modelName].frontend));
  };

  return saveDoneHandler;
};

/**
 *
 * @param exitOnSave {bool}
 * @param history {object}
 * @param savemode {string}
 * @return {function(func, func)}
 */

export const saveError = (e, type, model, id) => (dispatch) => {
  dispatch({
    type: editorActions.SAVE_ERROR,
    payload: {
      e,
      type,
      model,
      id,
    },
  });
};

/**
 * @function
 * @param exitOnSave {boolean} - выходить из редактора после записи
 * @param onClose { function } - callback, выполняется перед закрытием компонента
 * @param onSave { exitOnSave } - callback, выполняется при записи элемента
 * @param savemode { string } - режим записи (для зсправочника всегда "write", для докуента может быть "Posting" или "UndoPosting"
 * @return {function(...[*]=)}
 */
const newSaveStartHandler = (exitOnSave, onClose, onSave, savemode) =>
/**
     * @function
     * @param dispatch {func}
     * @param getState {func}
     */
  (dispatch, getState) => {
    /**
       * @const
       * @desc Redux node state
       * @type {Immutable.Map}
       */
    const store = getState();
    // const modelName = storePathParam(store).name;
    // const modelType = storePathParam(store).type;

    const { dellComponentFromWindowsManager } = useWinManager();

    const {
      id, node, name: modelName, type: modelType,
    } = storePathParam(store);

    const item = getState().getIn([node, id], new Map());

    /**
     * @const
     * @desc Current metadata
     * @type {object}
     */
    const cMeta = definition[modelType][modelName];

    dispatch({
      type: editorActions.SAVE_START,
      payload: {
        type: modelType,
        model: modelName,
        id,
      },
    });

    const itemData = toSrvMapper(cMeta, item);

    api
      .put$(`${modelType}/${cMeta.backendName}/${id}`, { item: { ...itemData }, savemode })
      .then((r) => {
        if (r.ok) {
          r.json().then((d) => {
            dispatch(saveDone(d));
            if (exitOnSave) {
              if (onClose) onClose();
              dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${id}/`));
            } else {
              if (onSave) {
                onSave(d.id);
              }
              if (id.toUpperCase() === 'CREATE') dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${id}/`));
            }
            // actionsLister.lister[modelType][modelName].load()(dispatch, getState);
          });
        } else {
          r.json().then((d) => {
            // eslint-disable-next-line no-underscore-dangle
            if (d._Error) {
            // eslint-disable-next-line no-underscore-dangle
              dispatch(saveError(d._Error, modelType, modelName, id));
            } else {
              dispatch(saveError(`Server Error ${r.status} ${r.statusText}`, modelType, modelName, id));
            }
          });
        }
      })
      .catch((e) => {
        dispatch(saveError(e, modelType, modelName, id));
      });
  };
// export const save = (params = {}) => {
//   const { exitOnSave, navigate, saveMode } = params;
//   console.error('Method save is DEPRECATD. Use newSave!!!!')
//   return saveStartHandler(exitOnSave || false, history || null, saveMode || saveModes.Write);
// };
//
/**
 *
 * @param params {object}
 * @return {function(func, func)}
 */
export const newSave = (params = {}) => {
  const {
    exitOnSave, onClose, onSave, saveMode,
  } = params;
  return newSaveStartHandler(
    exitOnSave || false,
    onClose || null,
    onSave || null,
    saveMode || saveModes.Write,
  );
};

export const addFiles = () => (dispatch, getState) => {
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);
  dispatch({
    type: editorActions.TURNING_ATTACHED_FILES,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

// const changeFieldHandler = (dispatch, getState) => {}
export const changeField = (path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const isLocked = getState().getIn([`${modelType}/${modelName}/editor`, id, 'isLocked'], false);

  path.unshift(id);

  if (isLocked) {
    dispatch(serverLock(modelType, modelName, id));
  }
  dispatch({
    type: editorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const serverRequestError = (e, type, model, id) => (dispatch) => {
  dispatch({
    type: editorActions.SR_ERR,
    payload: {
      hasError: true,
      errorMsg: e,
      type,
      model,
      id,
    },
  });
};

export const serverReqDone = (promiseResponse) => async (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */

  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const modelDef = definition[modelType][modelName];

  const response = await promiseResponse;

  const item2 = fromSrvMapper(modelDef, response);
  const item = {
    headerForm: item2.headerForm
      .merge(new Map(resultMapper(modelDef, response))),
    tables: item2.tables,
  };

  dispatch({
    type: editorActions.SR_DONE,
    payload: {
      item,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const processServerReq = (
  method,
  extra = {},
  selector = () => ({}),
) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const node = store.getIn([`${modelType}/${modelName}/editor`, id], new Map());

  // const modelName = node.get('modelName', '');
  // const modelType = node.get('modelType', '');
  const modelDef = definition[modelType][modelName];

  dispatch({
    type: editorActions.SR_START,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });

  const itemData = toSrvMapper(modelDef, node);

  const selectorResult = selector(getState());

  api
    .post$(`${modelType}/${modelDef.backendName}/SR`, {
      method,
      item: itemData,
      ...extra,
      ...selectorResult,
    })
    .then((d) => {
      dispatch(serverReqDone(d.json()));
    })
    .catch((e) => {
      dispatch(serverRequestError(e));
    });
};

/**
 *
 * @param exitOnSave {bool}
 * @param history {object}
 * @param savemode {string}
 * @return {function(func, func)}
 */

export const activateTableRow = (tableName, rowId, isMutiple = false) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */

  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  // const item = getState().getIn([`${modelType}/${modelName}/editor`, id], new Map());

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());

  if (isMutiple) {
    dispatch({
      type: editorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [id, `tables/${tableName}`, rowId, 'IS_ACTIVE'],
        value: !table.getIn([rowId, 'IS_ACTIVE'], false),
        type: modelType,
        model: modelName,
        id,
      },
    });
  } else {
    dispatch({
      type: editorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [id, `tables/${tableName}`],
        // value: table.reduce((V, row, key) => V.setIn([key, 'IS_ACTIVE'], key === rowId), table),
        value: table.map((row, key) => row.set('IS_ACTIVE', key === rowId)),
        type: modelType,
        model: modelName,
        id,
      },
    });
  }
};

export const addTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.List}
   */

  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);
  dispatch({
    type: editorActions.TABLE_ROW_ADD,
    payload: {
      tableName,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

/**
 * @function
 * @desc Removing all active rows from table
 * @param {string} tableName
 * @return {Function}
 */
export const removeTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  /**
   * @const
   * @type {Immutable.List}
   */

  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());
  // const table = node.getIn([storePathParam(store).node, storePathParam(store).id, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_REMOVE,
    payload: {
      name: tableName,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const copyTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());

  // const table = node.getIn([storePathParam(store).node, storePathParam(store).id, `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_COPY,
    payload: {
      name: tableName,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const swapUpTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());

  // const table = node.getIn([storePathParam(store).node, storePathParam(store).id, `tables/${tableName}`], new List());

  const activeSeq = table
    .toOrderedMap()
    .filter(filterActiveItem)
    .reverse();

  dispatch({
    type: editorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: true,
      items: activeSeq.reverse(),
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const swapDownTableRow = (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());

  // const table = node.getIn([storePathParam(store).node, storePathParam(store).id, `tables/${tableName}`], new List());

  const activeSeq = table.toOrderedMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: false,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const fillTable = (tableName, path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const modelName = storePathParam(store).name;
  const modelType = storePathParam(store).type;

  const { id } = storePathParam(store);

  const table = store.getIn([`${modelType}/${modelName}/editor`, id, `tables/${tableName}`], new List());

  // const table = node.getIn([storePathParam(store).node, storePathParam(store).id, `tables/${tableName}`], new List());

  const newTable = table.map((row) => row.setIn(path, value));

  dispatch({
    type: editorActions.TABLE_FILL,
    payload: {
      name: tableName,
      items: newTable,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

/**
 *
 * @return {function(func, func)}
 */
export const close = () =>
  /**
   * @function
   * @param dispatch {func}
   * @param getState {func}
   */
  (dispatch, getState) => {
    /**
     * @const
     * @desc Redux node state
     * @type {Immutable.Map}
     */
    const store = getState();
    const modelName = storePathParam(store).name;
    const modelType = storePathParam(store).type;

    /**
     * @const
     * @type {string}
     * @private
     */
    // const modelName = item.get('modelName');

    /**
     * @const
     * @type {string}
     * @private
     */
    // const modelType = item.get('modelType');

    /**
     * @const
     * @desc Current metadata
     * @type {object}
     */
    const cMeta = definition[modelType][modelName];
    const navigate = useNavigate();

    navigate(`/${cMeta.frontend}/`);
  };

export default {
  addTableRow,
  changeField,
  load,
  loadDone,
  init,
  processServerReq,
  activateTableRow,
  removeTableRow,
  copyTableRow,
  swapUpTableRow,
  swapDownTableRow,
  fillTable,
  registerWarnings,
  clearWarnings,
};
