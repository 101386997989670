import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Орган місцевого самоврядування', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
};

/**
 * @const
 */
const definition = {
  name: 'taxesPrint',
  backendName: 'ПечатьНалогиИЛьготы',
  label: 'Друк',
  frontend: 'dp/taxesPrint',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
