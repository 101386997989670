const frontendURL = '/cat/elementKFK/';
const backendURL = '/api/references/refkbp7item/';
const name = 'Елементи кодифікатора бюджетних програм МБ (КБП) 7зн.';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
