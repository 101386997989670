import mae from './mae.png';
import mape from './mape.png';
import smape from './smape.png';
import rmse from './rmse.png';
import r2 from './r2.png';
import excel from './excel.png';

const statImg = {
  mae,
  mape,
  smape,
  rmse,
  r2,
  excel,
};
export default statImg;
