import HeaderComponent from './Header';
import RowComponent from './RowComponent';
import FooterComponent from './Footer';

const TableComponents = {
  HeaderComponent,
  RowComponent,
  FooterComponent,
};

export default TableComponents;
