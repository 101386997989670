import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd2';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { comparisonTypes, selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'exp';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const elementKFKFilter = useMemo(() => [
    { fieldName: 'Владелец', value: FI },
    {
      fieldName: 'НеДействующий',
      comparisonType: comparisonTypes.equal,
      value: false,
    },
  ], [FI]);

  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="exp" highlighted={highlights.includes('exp')}>
              <ItemPicker
                value={row[tableMD.columns.exp.name]}
                modelType="cat"
                modelName="objectivesStatePolicy"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.exp.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
            <TableRowCell column="KPKV" highlighted={highlights.includes('KPKV')}>
              <ItemPicker
                value={row[tableMD.columns.KPKV.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KPKV.name]: v })}
                filter={elementKFKFilter}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
            <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
              <ItemPicker
                value={row[tableMD.columns.CSU.name]}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSU.name]: v })}
                filter={CSUFIlter}
                readOnly={readOnly}
                noHierarchy
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf_2" highlighted={highlights.includes('zf_2')}>
              <NumberInput
                value={row[tableMD.columns.zf_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sf_2" highlighted={highlights.includes('sf_2')}>
              <NumberInput
                value={row[tableMD.columns.sf_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="br_2" highlighted={highlights.includes('br_2')}>
              <NumberInput
                value={row[tableMD.columns.br_2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br_2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf_1" highlighted={highlights.includes('zf_1')}>
              <NumberInput
                value={row[tableMD.columns.zf_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sf_1" highlighted={highlights.includes('sf_1')}>
              <NumberInput
                value={row[tableMD.columns.sf_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="br_1" highlighted={highlights.includes('br_1')}>
              <NumberInput
                value={row[tableMD.columns.br_1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br_1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="br0" highlighted={highlights.includes('br0')}>
              <NumberInput
                value={row[tableMD.columns.br0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="br1" highlighted={highlights.includes('br1')}>
              <NumberInput
                value={row[tableMD.columns.br1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="br2" highlighted={highlights.includes('br2')}>
              <NumberInput
                value={row[tableMD.columns.br2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.zf2.name]: PropTypes.number,
    [tableMD.columns.sf2.name]: PropTypes.number,
    [tableMD.columns.zf1.name]: PropTypes.number,
    [tableMD.columns.sf1.name]: PropTypes.number,
    [tableMD.columns.zf0.name]: PropTypes.number,
    [tableMD.columns.sf0.name]: PropTypes.number,
    [tableMD.columns.zf_2.name]: PropTypes.number,
    [tableMD.columns.sf_2.name]: PropTypes.number,
    [tableMD.columns.zf_1.name]: PropTypes.number,
    [tableMD.columns.sf_1.name]: PropTypes.number,
    [tableMD.columns.br_1.name]: PropTypes.number,
    [tableMD.columns.br_2.name]: PropTypes.number,
    [tableMD.columns.br2.name]: PropTypes.number,
    [tableMD.columns.br1.name]: PropTypes.number,
    [tableMD.columns.br0.name]: PropTypes.number,
    [tableMD.columns.KPKV.name]: referencePropType,
    [tableMD.columns.exp.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  FI: referencePropType.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
