/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/objBuildingAddressList';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  ResultSegment,
  TwoColGridContainer,
  StyledLabel,
  SevenColumnsGrid,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer';

function ObjBuildingAddressList({
  dispatch, headerForm, isProcessing, sessionOptions, portalOpened, onReportDetails,
}) {
  const SettingsButton = (
    <div>

      <Tabs>
        <DivWithPropsLabel label="Группування">
          <StyledPane>
            <DCControls.Structure />
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Відбір">
          <StyledPane>
            <DCControls.Filter />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    </div>
  );

  return (
    <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
      <ContainerDiv>
        <TwoColGridContainer>
          <div>
            <StyledLabel>{md.columns.street.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('street', new Map())}
              modelType="cat"
              modelName="streets"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'street'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.house.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('house', new Map())}
              modelType="cat"
              modelName="houses"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'house'], v))}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('street', new Map()) }]}
            />
          </div>
        </TwoColGridContainer>
      </ContainerDiv>
      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ObjBuildingAddressList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map).isRequired,
  sessionOptions: PropTypes.instanceOf(Map).isRequired,
  isProcessing: PropTypes.bool,
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ObjBuildingAddressList.defaultProps = {
  isProcessing: false,
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ObjBuildingAddressList);
