import base from './base';

/**
 * @const
 */

const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  authorCh: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: false },
  CSU: { label: 'Виконавець', name: 'Распорядитель', visible: true },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: false },
  groundLaw: {
    label: 'Законодавчі підстави для виконання бюджетної програми',
    name: 'ЗаконОснование',
    visible: false,
  },
  KFKCode: { label: 'КФК', name: 'КодКФК', visible: true },
  KBP7Code: { label: 'КПКВ', name: 'КПКВ', visible: true },
  KPKVCode: { label: 'Код КПКВ', name: 'КПКВ', visible: false },
  KPBName: { label: 'КБП', name: 'НаимКлассификаторКБП', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рік планування', name: 'Год', visible: true },
  signatureVariants: { label: 'Варіанти підписів', name: 'Подпись', visible: false },
  laws1: { label: 'Наказ', name: 'Приказ1', visible: false },
  laws2: { label: 'і наказ', name: 'Приказ2', visible: false },
  lawsStr1: { label: 'Наказ', name: 'Приказ1Стр', visible: false },
  lawsStr2: { label: 'Наказ', name: 'Приказ2Стр', visible: false },
  target: { label: 'Мета', name: 'Цель', visible: false },
  isApproved: {
    label: 'Затверджений', name: 'МаксПодпись', type: 'bool', visible: true,
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: true },
  budgetRequest: { label: 'Бюджетний запит', name: 'БюджЗапрос', visible: false },
  isTargetedProgramsForYear: {
    label: 'Планування цільових програм за роками',
    name: 'флЦелевыеПрограммыПоГодам',
    visible: false,
  },
  sumGenF: { label: 'Заг.фонд(всього)', name: 'ЗФ3', visible: false },
  sumSpecF: { label: 'Спец.фонд(всього)', name: 'СФ3', visible: false },
  deltaGenF: { label: 'Різниця Заг.фонд', name: 'ОтклЗФ3', visible: false },
  deltaSpecF: { label: 'Різниця Спец.фонд', name: 'ОтклСФ3', visible: false },
  byQuarters: { label: 'Квартальна форма', name: 'флПоКварталам', visible: false },
  fullNameBP: { label: 'Повне найменування бюджетної програми', name: 'ПолнНаимПрог', visible: false },
  orderN: { label: 'Номер наказу', name: 'НомерПриказа1', visible: false },
  orderD: { label: 'Дата наказу', name: 'ДатаПриказа1', visible: false },
  KPKV: { label: 'КПКВ', name: 'КлассификаторКБП7', visible: true },
  codClassifierKBP7: { label: 'КПКВ', name: 'КодКлассификаторКБП7', visible: true },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  docLogica: { label: 'Документ відправки в Логіка', name: 'ДокОтправкиВЛогика', visible: false },
  isSentLogica: {
    label: 'Підготовлено відправку в Логіку', name: 'ОтправленВЛогику', visible: true, type: 'bool',
  },
  statusLogica: { label: 'СтатусЛогика', name: 'СтатусЛогика', visible: false },
  signLogica: { label: 'ПодписиЛогика', name: 'ПодписиЛогика', visible: false },
};

/**
 * @const
 */
const tables = {
  tasksSpent: {
    name: 'РасходыЗаданий',
    label: 'Напрями використання бюджетних коштів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      elementKFK: { label: 'Заповнювати дані з ', name: 'ЭлементКФК' },
      sumGenF: { label: 'Заг.фонд(всього)', name: 'ЗФ3' },
      sumSpecF: { label: 'Спец.фонд(всього)', name: 'СФ3' },
      sumTotal: { label: 'Сума', name: 'Сумма3' },
      sumBR: { label: 'в т.ч. БР', name: 'БР3' },
      note: { label: 'Опис', name: 'Описание' },
    },
  },
  elemSpent: { // Державний бюджет, скорее всего не будет нужен
    name: 'НаправленияГБ',
    label: 'Напрями використання бюджетних коштів',
    columns: {
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      sumGenF: { label: 'Заг.фонд(всього)', name: 'ЗФ' },
      sumSpecF: { label: 'Спец.фонд(всього)', name: 'СФ' },
      sumTotal: { label: 'Сума', name: 'Сумма' },
    },
  },
  generalTPInd: {
    name: 'Основной',
    label: 'Показники',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      indicators: { label: 'Показник', name: 'Показатель' },
      typeIndicators: { label: 'Тип показника', name: 'ТипПоказателя' },
      units: { label: 'Одиниця виміру (старий)', name: 'ЕдИзмСтар' },
      un: { label: 'Одиниця виміру', name: 'ЕдИзм' },
      note: { label: 'Примітка', name: 'Описание', visible: false },
      sumTotal: { label: 'Сума', name: 'Сумма3' },
      sumSpecF: { label: 'Спец.фонд(всього)', name: 'СФ3' },
      sumGenF: { label: 'Заг.фонд(всього)', name: 'ЗФ3' },
      unOld: { label: 'Одиниця виміру (старі)', name: 'ЕдИзмСтар' },
    },
  },
  regionPrograms: {
    name: 'РегПрограммы',
    label: 'Регіональні цільові програми',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      targetedPrograms: { label: 'Цільові програми', name: 'ЦелевыеПрограммы' },
      elementKFK: { label: 'Заповнювати дані з', name: 'ЭлементКФК' },
      sumTotal: { label: 'Сума', name: 'Сумма3' },
      sumSpecF: { label: 'Спец.фонд(всього)', name: 'СФ3' },
      sumGenF: { label: 'Заг.фонд(всього)', name: 'ЗФ3' },
    },
  },
  sourcesInvestmentProjects: {
    name: 'ИсточникиФинансированияПрограмм',
    label: 'Джерела фінансування інвестиційних проектів (програм)',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      targetedPrograms: { label: 'Цільові програми', name: 'ЦелеваяПрограмма' },
      kdbClassifier: { label: 'КДБ', name: 'КДБ' },
      kfbClassifier: { label: 'КФБ', name: 'КФБ' },
      fond: { label: 'Заг. фонд', name: 'Фонд' },
      note: { label: 'Пояснення, що характеризують джерела фінансування', name: 'ПояснениеСтр' },
      noteTemplate: { label: 'Шаблон пояснень', name: 'Пояснение' },
      sumCashTotal: { label: 'Касові видатки станом на ', name: 'Сумма0' },
      sumCashSpecF: { label: 'Спец. фонд', name: 'СФ0' },
      sumCashGenF: { label: 'Заг. фонд', name: 'ЗФ0' },
      sumPlanTotal: { label: 'План звітного періоду', name: 'Сумма1' },
      sumPlanSpecF: { label: 'Спец. фонд', name: 'СФ1' },
      sumPlanGenF: { label: 'Заг. фонд', name: 'ЗФ1' },
      sumPrognosisTotal: { label: 'Прогноз до кінця реалізації програми', name: 'Сумма2' },
      sumPrognosisSpecF: { label: 'Спец. фонд', name: 'СФ2' },
      sumPrognosisGenF: { label: 'Заг. фонд', name: 'ЗФ2' },
      objectsBuilding: { label: 'Об\'єкти будівництва', name: 'ОбъектыСтроительства' },
      year: { label: 'Рік', name: 'ГодПрограммы' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      document: { label: 'Паспорт', name: 'Док' },
      isApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      CSU: { label: 'Виконавець', name: 'Распорядитель' },
      note: { label: 'Примітка', name: 'Примечание' },
      author: { label: 'Автор', name: 'Автор' },
    },
  },
  targetsOfStatePolicy: {
    name: 'ЦелиГосПол',
    label: 'Цілі державної політики',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      targetsOfStatePolicy: { label: 'Цілі державної політики', name: 'РекЦельГосПол' },
    },
  },
  tasks: {
    name: 'Задания2019',
    label: 'Завдання',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks2019: { label: 'Завдання', name: 'Задания2019' },
    },
  },
  distributionOfCosts: {
    name: 'РаспределениеРасходов',
    label: 'Розподіл видатків у розрізі адміністративно-територіальних одиниць',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      territorialUnit: { label: 'Назва адміністративно-територіальної одиниці', name: 'ТерЕдиница' },
      generalFund: { label: 'Загальний фонд', name: 'ЗФ' },
      specialFund: { label: 'Спеціальний фонд', name: 'СФ' },
      sum: { label: 'Разом', name: 'Всего' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Паспорт бюджетної програми',
  name: 'indicatorPassport',
  backendName: 'ПоказателиУтверждение',
  columns,
  frontend: 'doc/indicatorPassport',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'isApproved',
      'KBP7Code',
      'note',
      'author',
      'authorCh',
      'isSentLogica',
      'CSU',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
