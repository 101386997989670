import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  year: { label: 'Рік', name: 'ГодПрогноза' },
  tabl: { label: 'Таблица', name: 'Таблица' },
  ldoc: { label: 'Останній документ', name: 'ПоследнийДокумент' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  isWeb: { label: 'ЭтоВеб', name: 'ЭтоВеб' },
};

const definition = {
  label: 'Аналіз паспортів',
  name: 'PassportAnalysisSKD',
  columns,
  frontend: 'rep/PassportAnalysisSKD',
  backendName: 'АнализПоказателей_СКД',
  resultColumns: baseResultColumns,
};

export default definition;
