import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  inThousands: { label: 'в тис.грн.', name: 'ВТысГрн' },
};

const definition = {
  label: 'Аналіз доходів (довільні поля)',
  name: 'RevenueAnalysisVariety',
  columns,
  frontend: 'rep/RevenueAnalysisVariety',
  resultColumns: baseResultColumns,
  backendName: 'АнализДоходовПроизвольный',
};

export default definition;
