import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function Header({
  activeCol, highlights, onToggleHighlight, year, totals,
}) {
  const yearDef = year || new Date().getFullYear();
  const pre1year = `${yearDef - 1}p.`;
  const pre2year = `${yearDef - 2}p.`;
  return (
    <TableHeaderRow>
      <Col>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kekv'}
              highlighted={highlights.includes('kekv')}
              onToggleHighlight={() => onToggleHighlight('kekv')}
            >
              КЕКВ
            </TableHeaderCell>
          </Col>
        </Row>

        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_approvedplan_2'}
              highlighted={highlights.includes('amount_approvedplan_2')}
              onToggleHighlight={() => onToggleHighlight('amount_approvedplan_2')}
              title={totals.amount_approvedplan_2.toLocaleString('uk', moneysStingOptions)}
            >
              Уточ.план
              {' '}
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_kassa_expenses_2'}
              highlighted={highlights.includes('amount_kassa_expenses_2')}
              onToggleHighlight={() => onToggleHighlight('amount_kassa_expenses_2')}
              title={totals.amount_kassa_expenses_2.toLocaleString('uk', moneysStingOptions)}
            >
              Касові видатки
              {' '}
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_approvedplan_1'}
              highlighted={highlights.includes('amount_approvedplan_1')}
              onToggleHighlight={() => onToggleHighlight('amount_approvedplan_1')}
              title={totals.amount_approvedplan_1.toLocaleString('uk', moneysStingOptions)}
            >
              Уточ.план
              {' '}
              {pre1year}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total_2'}
              highlighted={highlights.includes('amount_total_2')}
              onToggleHighlight={() => onToggleHighlight('amount_total_2')}
              title={totals.amount_total_2.toLocaleString('uk', moneysStingOptions)}
            >
              Кред. заборгов. на 01.01.
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total_1'}
              highlighted={highlights.includes('amount_total_1')}
              onToggleHighlight={() => onToggleHighlight('amount_total_1')}
              title={totals.amount_total_1.toLocaleString('uk', moneysStingOptions)}
            >
              Кред. заборгов. на 01.01.
              {pre1year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_limit'}
              highlighted={highlights.includes('amount_limit')}
              onToggleHighlight={() => onToggleHighlight('amount_limit')}
              title={totals.amount_limit.toLocaleString('uk', moneysStingOptions)}
            >
              Граничний обсяг
              {' '}
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment_2'}
              highlighted={highlights.includes('amount_zf_repayment_2')}
              onToggleHighlight={() => onToggleHighlight('amount_zf_repayment_2')}
              title={totals.amount_zf_repayment_2.toLocaleString('uk', moneysStingOptions)}
            >
              Пошаг. кред. заб. зі заг. ф.
              {' '}
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment_1'}
              highlighted={highlights.includes('amount_zf_repayment_1')}
              onToggleHighlight={() => onToggleHighlight('amount_zf_repayment_1')}
              title={totals.amount_zf_repayment_1.toLocaleString('uk', moneysStingOptions)}
            >
              Пошаг. кред. заб. зі заг. ф.
              {' '}
              {pre1year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf_repayment0'}
              highlighted={highlights.includes('amount_zf_repayment0')}
              onToggleHighlight={() => onToggleHighlight('amount_zf_repayment0')}
              title={totals.amount_zf_repayment0.toLocaleString('uk', moneysStingOptions)}
            >
              План. пошаг. кред. заб. зі заг. ф.
              {' '}
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment_2'}
              highlighted={highlights.includes('amount_sf_repayment_2')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_repayment_2')}
              title={totals.amount_sf_repayment_2.toLocaleString('uk', moneysStingOptions)}
            >
              Пошаг. кред. заб. зі спец. ф.
              {' '}
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment_1'}
              highlighted={highlights.includes('amount_sf_repayment_1')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_repayment_1')}
              title={totals.amount_sf_repayment_1.toLocaleString('uk', moneysStingOptions)}
            >
              Пошаг. кред. заб. зі спец. ф.
              {' '}
              {pre1year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf_repayment0'}
              highlighted={highlights.includes('amount_sf_repayment0')}
              onToggleHighlight={() => onToggleHighlight('amount_sf_repayment0')}
              title={totals.amount_sf_repayment0.toLocaleString('uk', moneysStingOptions)}
            >
              План. пошаг. кред. заб. зі спец. ф.
              {' '}
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt_2'}
              highlighted={highlights.includes('amount_debt_2')}
              onToggleHighlight={() => onToggleHighlight('amount_debt_2')}
              title={totals.amount_debt_2.toLocaleString('uk', moneysStingOptions)}
            >
              Дебіторська заборгованість на 01.01.
              {pre2year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt_1'}
              highlighted={highlights.includes('amount_debt_1')}
              onToggleHighlight={() => onToggleHighlight('amount_debt_1')}
              title={totals.amount_debt_1.toLocaleString('uk', moneysStingOptions)}
            >
              Дебіторська заборгованість на 01.01.
              {pre1year}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_debt0'}
              highlighted={highlights.includes('amount_debt0')}
              onToggleHighlight={() => onToggleHighlight('amount_debt0')}
              title={totals.amount_debt0.toLocaleString('uk', moneysStingOptions)}
            >
              Дебіторська заборгованість на 01.01.
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason'}
              highlighted={highlights.includes('reason')}
              onToggleHighlight={() => onToggleHighlight('reason')}
            >
              Причини виникнення заборгованості
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'taken_measures'}
              highlighted={highlights.includes('taken_measures')}
              onToggleHighlight={() => onToggleHighlight('taken_measures')}
            >
              Вжиті заходи щодо ліквідації заборгованості
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_approvedplan_2: PropTypes.number,
    amount_approvedplan_1: PropTypes.number,
    amount_kassa_expenses_2: PropTypes.number,
    amount_total_2: PropTypes.number,
    amount_total_1: PropTypes.number,
    amount_limit: PropTypes.number,
    amount_zf_repayment_2: PropTypes.number,
    amount_zf_repayment_1: PropTypes.number,
    amount_zf_repayment0: PropTypes.number,
    amount_sf_repayment_2: PropTypes.number,
    amount_sf_repayment_1: PropTypes.number,
    amount_sf_repayment0: PropTypes.number,
    amount_debt_2: PropTypes.number,
    amount_debt_1: PropTypes.number,
    amount_debt0: PropTypes.number,
  }).isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
