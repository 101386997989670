/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { moneysOneDigitStingOptions } from '../../../../../constants/moneys';
// import Styled from "styled-components";

// ${({ noBackground }) => (noBackground ? 'none' : 'transparent')};

// const StyledDiv = Styled.div`
//
// margin-left: ${({level})=>("${2 * level}"px )}
// `;

function TableRow({ row, level }) {
  return (
    <tr className={`text-${(level === 1) ? 'black' : 800}`}>
      <td className="align-middle">
        <div
          style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}
        >
          {row.code}
        </div>
      </td>
      <td className="align-middle">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {row.name}
        </div>
      </td>
      <td className="text-end align-middle">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {row.plan.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end align-middle">
        <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}>
          {row.fact.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
      <td className="text-end align-middle">
        <ProgressBar now={row.percent} style={{ height: 5 }} />
        {/* <div style={{ marginLeft: `${2 * level}px`, padding: '0.75rem 0.15rem' }}> */}
        {/*  {row.percent} */}
        {/*  % */}
        {/* </div> */}
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  row: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    plan: PropTypes.number,
    fact: PropTypes.number,
    percent: PropTypes.number,
  }).isRequired,
  level: PropTypes.number.isRequired,
};

export default TableRow;
