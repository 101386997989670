import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'Период' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
};

const definition = {
  label: 'План факт видатків обласного бюджету',
  name: 'RegionAssigmentsFactAnalisys',
  columns,
  frontend: 'rep/regionAssigmentsFactAnalisys',
  backendName: 'ПланФактРасходовОбласногоБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
