import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WinManagerContext } from '../../../providers';
import PageHeader from '../../../components/blanks/common/PageHeader';
import CardHeader from '../../../components/blanks/common/CardHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { childrenPropType } from '../../../common/proptypes';

function ReportHeader({ children, title }) {
  const {
    dellComponentFromWindowsManager,
  } = useContext(WinManagerContext);
  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <PageHeader
        className="mb-3"
      >
        <CardHeader
          title={title}
          className="cardPadding"
          light={false}
          role="menubar"
        />
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => dellComponentFromWindowsManager()}
            title="Закрити"
          />
        </div>
      </PageHeader>
      {children}
    </div>
  );
}

ReportHeader.propTypes = {
  children: childrenPropType.isRequired,
  title: PropTypes.node.isRequired,
};

export default ReportHeader;
