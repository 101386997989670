import AccessGroupEditor from './accessGroup/editor';
import AccessGroupLister from './accessGroup/lister/lister';

import ActivityKindsEditor from './activityKinds/editor';
import ActivityKindsLister from './activityKinds/lister/lister';

import AdditionalPropertyCsuEditor from './additionalPropertyCsu/editor';
import AdditionalPropertyCsuLister from './additionalPropertyCsu/lister';

import AdditionalPropertyKFKEditor from './additionalPropertyKFK/editor';
import AdditionalPropertyKFKLister from './additionalPropertyKFK/lister';

import AdditionalPropertyTypesRepairEditor from './additionalPropertyTypesRepair/editor';
import AdditionalPropertyTypesRepairLister from './additionalPropertyTypesRepair/lister';

import AdministrativeTerritorialUnitsEditor from './administrativeTerritorialUnits/editor';
import AdministrativeTerritorialUnitsLister from './administrativeTerritorialUnits/lister';

import ArticlesDebtSocialPaymentsEditor from './articlesDebtSocialPayments/editor';
import ArticlesDebtSocialPaymentsLister from './articlesDebtSocialPayments/lister';

import ArtistsRolesEditor from './artistsRoles/editor';
import ArtistsRolesLister from './artistsRoles/lister';

import { BankAccountsLister } from './bankAccounts/lister';
import BankAccountsEditor from './bankAccounts/editor';

import BanksEditor from './banks/editor';
import BanksLister from './banks/lister';

import BaseDocEditor from './baseDoc/editor';
import BaseDocLister from './baseDoc/lister';

import BudgetEditor from './budget/editor';
import BudgetLister from './budget/lister';

import BuildingClassifierEditor from './buildingClassifier/editor';
import BuildingClassifierLister from './buildingClassifier/lister';

import CoverageSourceEditor from './coverageSource/editor';
import CoverageSourceLister from './coverageSource/lister';

import CoverageSourceClassifierEditor from './coverageSourceClassifier/editor';
import CoverageSourceClassifierLister from './coverageSourceClassifier/lister';

import { CSULister } from './CSU/lister';
import CSUEditor from './CSU/editor';

import CSUPartnersEditor from './CSUPartners/editor';
import CSUPartnersLister from './CSUPartners/lister';

import DeputiesEditor from './deputies/editor';
import DeputiesLister from './deputies/lister';

import DistrictsEditor from './districts/editor';
import DistrictsLister from './districts/lister';

import DocNotesEditor from './docNotes/editor';
import DocNotesLister from './docNotes/lister';

import DocTypeEditor from './docType/editor';
import DocTypeLister from './docType/lister';

import ElementFondLister from './elementFond/lister';
import ElementFondEditor from './elementFond/editor';

import ElementKDBLister from './elementKDB/lister';
import ElementKDBEditor from './elementKDB/editor';

import ElementKEKVLister from './elementKEKV/lister';
import ElementKEKVEditor from './elementKEKV/editor';

import ElementKFBLister from './elementKFB/lister';
import ElementKFBEditor from './elementKFB/editor';

import ElementKFKLister from './elementKFK/lister';
import ElementKFKEditor from './elementKFK/editor';

import ElementSocialPaymentEditor from './elementSocialPayment/editor';
import ElementSocialPaymentLister from './elementSocialPayment/lister';

import EnergyCarriersIndicatorEditor from './energyCarriersIndicator/editor';
import EnergyCarriersIndicatorLister from './energyCarriersIndicator/lister';

import ExplanationsEditor from './explanations/editor';
import ExplanationsLister from './explanations/lister';

import FondClassifierEditor from './fondClassifier/editor';
import FondClassifierLister from './fondClassifier/lister';

import GroundLawEditor from './groundLaw/editor';
import GroundLawLister from './groundLaw/lister';

import HousesEditor from './houses/editor';
import HousesLister from './houses/lister';

import ImplementerEditor from './implementer/editor';
import ImplementerLister from './implementer/lister';

import IndicatorLister from './indicators/lister';
import IndicatorEditor from './indicators/editor';

import IndicatorsClassifierEditor from './indicatorsClassifier/editor';
import IndicatorsClassifierLister from './indicatorsClassifier/lister';

import IndustryEditor from './industry/editor';
import IndustryLister from './industry/lister';

import InfoSourcesEditor from './infoSources/editor';
import InfoSourcesLister from './infoSources/lister';

import InvestorLister from './investor/lister';
import InvestorEditor from './investor/editor';

import Kbp7ClassifierLister from './kbp7Classifier/lister/lister';
import KBP7ClassifierEditor from './kbp7Classifier/editor';

import KBPClassifierEditor from './KBPClassifier/editor';
import KBPClassifierLister from './KBPClassifier/lister';

import KDBClassifierEditor from './KDBClassifier/editor';
import KDBClassifierLister from './KDBClassifier/lister';

import KEKVClassifierEditor from './KEKVClassifier/editor';
import KEKVClassifierLister from './KEKVClassifier/lister';

import KekvClassifierForMinFinShortEditor from './kekvClassifierForMinFinShort/editor';
import KekvClassifierForMinFinShortLister from './kekvClassifierForMinFinShort/lister';

import KekvClassifierForPAShortEditor from './kekvClassifierForPAShort/editor';
import KekvClassifierForPAShortLister from './kekvClassifierForPAShort/lister';

import KekvClassifierFPShortEditor from './kekvClassifierFPShort/editor';
import KekvClassifierFPShortLister from './kekvClassifierFPShort/lister';

import KekvClassifierShortEditor from './kekvClassifierShort/editor';
import KekvClassifierShortLister from './kekvClassifierShort/lister';

import KFBClassifierEditor from './KFBClassifier/editor';
import KFBClassifierLister from './KFBClassifier/lister';

import KFKVKClassifierEditor from './KFKVKClassifier/editor';
import KFKVKClassifierLister from './KFKVKClassifier/lister';

import KVKClassifierEditor from './KVKClassifier/editor';
import KVKClassifierLister from './KVKClassifier/lister';

import KoatuuEditor from './koatuu/editor';
import KoatuuLister from './koatuu/lister';

import LandClassifierEditor from './landClassifier/editor';
import LandClassifierLister from './landClassifier/lister';

import LawsEditor from './laws/editor';
import LawsLister from './laws/lister';

import ForecastCreditEditor from './forecastCredit/editor';
import ForecastCreditLister from './forecastCredit/lister';

import PlaningEditor from './planing/editor';
import PlaningLister from './planing/lister';

import ForecastIndicatorBREditor from './forecastIndicatorBR/editor';
import ForecastIndicatorBRLister from './forecastIndicatorBR/lister';

import IndicatorsForTargetEditor from './indicatorsForTarget/editor';
import IndicatorsForTargetLister from './indicatorsForTarget/lister';

import ReportsFormsEditor from './reportsForms/editor';
import ReportsFormsSelector from './reportsForms/lister/selector';

import DepartmentEditor from './department/editor';
import DepartmentLister from './department/lister';

// import ModelProgramsClassifierEditor from './modelProgramsClassifier/editor';
// import ModelProgramsClassifierLister from './modelProgramsClassifier/lister';

import NoteClassifierEditor from './noteClassifier/editor';
import NoteClassifierLister from './noteClassifier/lister';

import NotesEditor from './notes/editor';
import NotesLister from './notes/lister';

import NoteSocialPaymentsEditor from './noteSocialPayments/editor';
import NoteSocialPaymentsLister from './noteSocialPayments/lister';

import NumberingEditor from './numbering/editor';
import NumberingLister from './numbering/lister';

import ObjBuildingPVREditor from './objBuildingPVR/editor';
import ObjBuildingPVRLister from './objBuildingPVR/lister';

import ObjBuildingTypeDocEditor from './objBuildingTypeDoc/editor';
import ObjBuildingTypeDocLister from './objBuildingTypeDoc/lister';

import ObjectsBuildingLister from './objectsBuilding/lister';
import ObjectsBuildingEditor from './objectsBuilding/editor';

import OfficialsEditor from './officials/editor';
import OfficialsLister from './officials/lister';

import OutcomeGroupEditor from './outcomeGroup/editor';
import OutcomeGroupLister from './outcomeGroup/lister';

import ParkingKindsEditor from './parkingKinds/editor';
import ParkingKindsLister from './parkingKinds/lister';

import PartnerBankAccountsEditor from './partnerBankAccounts/editor';
import PartnerBankAccountsLister from './partnerBankAccounts/lister';

import PartnerBanksEditor from './partnerBanks/editor';
import PartnerBanksLister from './partnerBanks/lister';

import PartnersEditor from './partners/editor';
import PartnersLister from './partners/lister';

import PaymentPurposeTemplateEditor from './paymentPurposeTemplate/editor';
import PaymentPurposeTemplateLister from './paymentPurposeTemplate/lister';

import ProvisionOfNormativeLegalActsEditor from './provisionOfNormativeLegalActs/editor';
import ProvisionOfNormativeLegalActsLister from './provisionOfNormativeLegalActs/lister';

import PublicBudgetProjectEditor from './publicBudgetProject/editor';
import PublicBudgetProjectLister from './publicBudgetProject/lister';

import PublicBudgetProjectAuthorEditor from './publicBudgetProjectAuthor/editor';
import PublicBudgetProjectAuthorLister from './publicBudgetProjectAuthor/lister';

import RecipientsEditor from './recipients/editor';
import RecipientsLister from './recipients/lister';

import RegionsEditor from './regions/editor';
import RegionsLister from './regions/lister';

import SalaryStructureEditor from './salaryStructure/editor';
import SalaryStructureLister from './salaryStructure/lister';

import SignatureVariantsEditor from './signatureVariants/editor';
import SignatureVariantsLister from './signatureVariants/lister';

import SignGroupEditor from './signGroup/editor';
import SignGroupLister from './signGroup/lister';

import SignGroupByDocumentsEditor from './signGroupByDocuments/editor';
import SignGroupByDocumentsLister from './signGroupByDocuments/lister';

import StatesContingentsEditor from './statesContingents/editor';
import StatesContingentsLister from './statesContingents/lister';

import StewardGroupEditor from './stewardGroup/editor';
import StewardGroupLister from './stewardGroup/lister';

import StewardLevelEditor from './stewardLevel/editor';
import StewardLevelLister from './stewardLevel/lister';

import StreetsEditor from './streets/editor';
import StreetsLister from './streets/lister';

import SubmittedDocumentsEditor from './submittedDocuments/editor';
import SubmittedDocumentsLister from './submittedDocuments/lister';

import TargetEditor from './target/editor';
import TargetLister from './target/lister';

import TargetedProgramsEditor from './targetedPrograms/editor';
import TargetedProgramsLister from './targetedPrograms/lister';

import TasksEditor from './tasks/editor';
import TasksLister from './tasks/lister';

import Tasks2019Editor from './tasks2019/editor';
import Tasks2019Lister from './tasks2019/lister';

import TasksClassifierEditor from './tasksClassifier/editor';
import TasksClassifierLister from './tasksClassifier/lister';

import TemplateBaseDocLister from './templateBaseDoc/lister';
import TemplateBaseDocEditor from './templateBaseDoc/editor';

import TenderProcTypeLister from './tenderProcType/lister';
import TenderProcTypeEditor from './tenderProcType/editor';

import TreasuryStatementEditor from './treasuryStatement/editor';
import TreasuryStatementLister from './treasuryStatement/lister';

import TypeAttachedFileEditor from './typeAttachedFile/editor';
import TypeAttachedFileLister from './typeAttachedFile/lister';

import TypesOfLaborCostsEditor from './typesOfLaborCosts/editor';
import TypesOfLaborCostsLister from './typesOfLaborCosts/lister';

import TypesRepairEditor from './typesRepair/editor';
import TypesRepairLister from './typesRepair/lister';

import TypesRepairIndustryEditor from './typesRepairIndustry/editor';
import TypesRepairIndustryLister from './typesRepairIndustry/lister';

import TypesWorkEditor from './typesWork/editor';
import TypesWorkLister from './typesWork/lister';

import TypeTaxSFForRaitingZFEditor from './typeTaxSFForRaitingZF/editor';
import TypeTaxSFForRaitingZFLister from './typeTaxSFForRaitingZF/lister';

import UnitsEditor from './units/editor';
import UnitsLister from './units/lister';

import VariantBudgetDraftEditor from './variantBudgetDraft/editor';
import VariantBudgetDraftLister from './variantBudgetDraft/lister';

import ObjectivesStatePolicyEditor from './objectivesStatePolicy/editor';
import ObjectivesStatePolicyLister from './objectivesStatePolicy/lister/lister';

import ResolutionSessionEditor from './resolutionSession/editor';
import ResolutionSessionLister from './resolutionSession/lister';

import usersEditor from './users/editor';
import UsersLister from './users/lister';

import ObjBuildingTypeSourceEditor from './objBuildingTypeSource/editor';
import ObjBuildingTypeSourceLister from './objBuildingTypeSource/lister';

import ObjBuildingTypePowerEditor from './objBuildingTypePower/editor';
import ObjBuildingTypePowerLister from './objBuildingTypePower/lister';

import ObjBuildingSourceFinEditor from './objBuildingSourceFin/editor';
import ObjBuildingSourceFinLister from './objBuildingSourceFin/lister';

import CsuTransferEditor from './csuTransfer/editor';
import CsuTransferLister from './csuTransfer/lister';

import BudgetTransferEditor from './budgetTransfer/editor';
import BudgetTransferLister from './budgetTransfer/lister';

import ReportSettingsEditor from './reportSettings/editor';
import ReportSettingsLister from './reportSettings/lister';

import ResidenceKindsEditor from './residenceKinds/editor';
import ResidenceKindsLister from './residenceKinds/lister';

import TarifEditor from './tarif/editor';
import TarifLister from './tarif/lister';

import CategoriesFOPEditor from './categoriesFOP/editor';
import CategoriesFOPLister from './categoriesFOP/lister';

import CategoriesFOPVersionsEditor from './categoriesFOPVersions/editor';
import CategoriesFOPVersionsLister from './categoriesFOPVersions/lister';

import IndustryDetEditor from './industryDet/editor';
import IndustryDetLister from './industryDet/lister';

import CurrencyEditor from './currency/editor';
import CurrencyLister from './currency/lister';
import ReportsFormsLister from './reportsForms/lister';

import BudgetPaymentCodeEditor from './budgetPaymentCode/editor';
import BudgetPaymentCodeLister from './budgetPaymentCode/lister';

import SymbolKdbEditor from './symbolKdb/editor';
import SymbolKdbLister from './symbolKdb/lister';

const catComponents = {
  additionalPropertyCsu: {
    editor: AdditionalPropertyCsuEditor,
    lister: AdditionalPropertyCsuLister,
  },
  additionalPropertyKFK: {
    editor: AdditionalPropertyKFKEditor,
    lister: AdditionalPropertyKFKLister,
  },
  additionalPropertyTypesRepair: {
    editor: AdditionalPropertyTypesRepairEditor,
    lister: AdditionalPropertyTypesRepairLister,
  },
  bankAccounts: {
    editor: BankAccountsEditor,
    lister: BankAccountsLister,
  },
  csu: {
    editor: CSUEditor,
    lister: CSULister,
  },
  elementFond: {
    editor: ElementFondEditor,
    lister: ElementFondLister,
  },
  elementKDB: {
    editor: ElementKDBEditor,
    lister: ElementKDBLister,
  },
  elementKEKV: {
    editor: ElementKEKVEditor,
    lister: ElementKEKVLister,
  },
  elementKFB: {
    editor: ElementKFBEditor,
    lister: ElementKFBLister,
  },
  elementKFK: {
    editor:
    ElementKFKEditor,
    lister: ElementKFKLister,
  },
  groundLaw: {
    editor: GroundLawEditor,
    lister: GroundLawLister,
  },
  indicators: {
    editor: IndicatorEditor,
    lister: IndicatorLister,
  },
  investor: {
    editor: InvestorEditor,
    lister: InvestorLister,
  },
  kbp7Classifier: {
    editor: KBP7ClassifierEditor,
    lister: Kbp7ClassifierLister,
  },
  objectsBuilding: {
    editor: ObjectsBuildingEditor,
    lister: ObjectsBuildingLister,
  },
  templateBaseDoc: {
    editor: TemplateBaseDocEditor,
    lister: TemplateBaseDocLister,
  },
  tenderProcType: {
    editor: TenderProcTypeEditor,
    lister: TenderProcTypeLister,
  },
  target: {
    editor: TargetEditor,
    lister: TargetLister,
  },
  deputies: {
    editor: DeputiesEditor,
    lister: DeputiesLister,
  },
  signGroup: {
    editor: SignGroupEditor,
    lister: SignGroupLister,
  },
  articlesDebtSocialPayments: {
    editor: ArticlesDebtSocialPaymentsEditor,
    lister: ArticlesDebtSocialPaymentsLister,
  },
  submittedDocuments: {
    editor: SubmittedDocumentsEditor,
    lister: SubmittedDocumentsLister,
  },
  users: {
    editor: usersEditor,
    lister: UsersLister,
  },
  kvkClassifier: {
    editor: KVKClassifierEditor,
    lister: KVKClassifierLister,
  },
  banks: {
    editor: BanksEditor,
    lister: BanksLister,
  },
  fondClassifier: {
    editor: FondClassifierEditor,
    lister: FondClassifierLister,
  },
  kekvClassifier: {
    editor: KEKVClassifierEditor,
    lister: KEKVClassifierLister,
  },
  kbpClassifier: {
    editor: KBPClassifierEditor,
    lister: KBPClassifierLister,
  },
  kdbClassifier: {
    editor: KDBClassifierEditor,
    lister: KDBClassifierLister,
  },
  tasks: {
    editor: TasksEditor,
    lister: TasksLister,
  },
  tasks2019: {
    editor: Tasks2019Editor,
    lister: Tasks2019Lister,
  },
  objectivesStatePolicy: {
    editor: ObjectivesStatePolicyEditor,
    lister: ObjectivesStatePolicyLister,
  },
  typesOfLaborCosts: {
    editor: TypesOfLaborCostsEditor,
    lister: TypesOfLaborCostsLister,
  },
  salaryStructure: {
    editor: SalaryStructureEditor,
    lister: SalaryStructureLister,
  },
  energyCarriersIndicator: {
    editor: EnergyCarriersIndicatorEditor,
    lister: EnergyCarriersIndicatorLister,
  },
  publicBudgetProject: {
    editor: PublicBudgetProjectEditor,
    lister: PublicBudgetProjectLister,
  },
  publicBudgetProjectAuthor: {
    editor: PublicBudgetProjectAuthorEditor,
    lister: PublicBudgetProjectAuthorLister,
  },
  industry: {
    editor: IndustryEditor,
    lister: IndustryLister,
  },
  outcomeGroup: {
    editor: OutcomeGroupEditor,
    lister: OutcomeGroupLister,
  },
  docType: {
    editor: DocTypeEditor,
    lister: DocTypeLister,
  },
  tasksClassifier: {
    editor: TasksClassifierEditor,
    lister: TasksClassifierLister,
  },
  coverageSourceClassifier: {
    editor: CoverageSourceClassifierEditor,
    lister: CoverageSourceClassifierLister,
  },
  coverageSource: {
    editor: CoverageSourceEditor,
    lister: CoverageSourceLister,
  },
  implementer: {
    editor: ImplementerEditor,
    lister: ImplementerLister,
  },
  variantBudgetDraft: {
    editor: VariantBudgetDraftEditor,
    lister: VariantBudgetDraftLister,
  },
  budget: {
    editor: BudgetEditor,
    lister: BudgetLister,
  },
  kfbClassifier: {
    editor: KFBClassifierEditor,
    lister: KFBClassifierLister,
  },
  stewardGroup: {
    editor: StewardGroupEditor,
    lister: StewardGroupLister,
  },
  kfkvkClassifier: {
    editor: KFKVKClassifierEditor,
    lister: KFKVKClassifierLister,
  },
  laws: {
    editor: LawsEditor,
    lister: LawsLister,
  },
  forecastCredit: {
    editor: ForecastCreditEditor,
    lister: ForecastCreditLister,
  },
  forecastIndicatorBR: {
    editor: ForecastIndicatorBREditor,
    lister: ForecastIndicatorBRLister,
  },
  indicatorsForTarget: {
    editor: IndicatorsForTargetEditor,
    lister: IndicatorsForTargetLister,
  },
  stewardLevel: {
    editor: StewardLevelEditor,
    lister: StewardLevelLister,
  },
  baseDoc: {
    editor: BaseDocEditor,
    lister: BaseDocLister,
  },
  notes: {
    editor: NotesEditor,
    lister: NotesLister,
  },
  docNotes: {
    editor: DocNotesEditor,
    lister: DocNotesLister,
  },
  csuPartners: {
    editor: CSUPartnersEditor,
    lister: CSUPartnersLister,
  },
  noteClassifier: {
    editor: NoteClassifierEditor,
    lister: NoteClassifierLister,
  },
  partners: {
    editor: PartnersEditor,
    lister: PartnersLister,
  },
  accessGroup: {
    editor: AccessGroupEditor,
    lister: AccessGroupLister,
  },
  activityKinds: {
    editor: ActivityKindsEditor,
    lister: ActivityKindsLister,
  },
  officials: {
    editor: OfficialsEditor,
    lister: OfficialsLister,
  },
  targetedPrograms: {
    editor: TargetedProgramsEditor,
    lister: TargetedProgramsLister,
  },
  buildingClassifier: {
    editor: BuildingClassifierEditor,
    lister: BuildingClassifierLister,
  },
  parkingKinds: {
    editor: ParkingKindsEditor,
    lister: ParkingKindsLister,
  },
  partnerBanks: {
    editor: PartnerBanksEditor,
    lister: PartnerBanksLister,
  },
  partnerBankAccounts: {
    editor: PartnerBankAccountsEditor,
    lister: PartnerBankAccountsLister,
  },
  signatureVariants: {
    editor: SignatureVariantsEditor,
    lister: SignatureVariantsLister,
  },
  elementSocialPayment: {
    editor: ElementSocialPaymentEditor,
    lister: ElementSocialPaymentLister,
  },
  noteSocialPayments: {
    editor: NoteSocialPaymentsEditor,
    lister: NoteSocialPaymentsLister,
  },
  treasuryStatement: {
    editor: TreasuryStatementEditor,
    lister: TreasuryStatementLister,
  },
  districts: {
    editor: DistrictsEditor,
    lister: DistrictsLister,
  },
  streets: {
    editor: StreetsEditor,
    lister: StreetsLister,
  },
  houses: {
    editor: HousesEditor,
    lister: HousesLister,
  },
  typeTaxSFForRaitingZF: {
    editor: TypeTaxSFForRaitingZFEditor,
    lister: TypeTaxSFForRaitingZFLister,
  },
  statesContingents: {
    editor: StatesContingentsEditor,
    lister: StatesContingentsLister,
  },
  paymentPurposeTemplate: {
    editor: PaymentPurposeTemplateEditor,
    lister: PaymentPurposeTemplateLister,
  },
  signGroupByDocuments: {
    editor: SignGroupByDocumentsEditor,
    lister: SignGroupByDocumentsLister,
  },
  units: {
    editor: UnitsEditor,
    lister: UnitsLister,
  },
  explanations: {
    editor: ExplanationsEditor,
    lister: ExplanationsLister,
  },
  administrativeTerritorialUnits: {
    editor: AdministrativeTerritorialUnitsEditor,
    lister: AdministrativeTerritorialUnitsLister,
  },
  indicatorsClassifier: {
    editor: IndicatorsClassifierEditor,
    lister: IndicatorsClassifierLister,
  },
  infoSources: {
    editor: InfoSourcesEditor,
    lister: InfoSourcesLister,
  },
  // modelProgramsClassifier: {
  //   editor: ModelProgramsClassifierEditor,
  //   lister: ModelProgramsClassifierLister,
  // },
  regions: {
    editor: RegionsEditor,
    lister: RegionsLister,
  },
  provisionOfNormativeLegalActs: {
    editor: ProvisionOfNormativeLegalActsEditor,
    lister: ProvisionOfNormativeLegalActsLister,
  },
  recipients: {
    editor: RecipientsEditor,
    lister: RecipientsLister,
  },
  artistsRoles: {
    editor: ArtistsRolesEditor,
    lister: ArtistsRolesLister,
  },
  kekvClassifierShort: {
    editor: KekvClassifierShortEditor,
    lister: KekvClassifierShortLister,
  },
  kekvClassifierForPAShort: {
    editor: KekvClassifierForPAShortEditor,
    lister: KekvClassifierForPAShortLister,
  },
  kekvClassifierForMinFinShort: {
    editor: KekvClassifierForMinFinShortEditor,
    lister: KekvClassifierForMinFinShortLister,
  },
  kekvClassifierFPShort: {
    editor: KekvClassifierFPShortEditor,
    lister: KekvClassifierFPShortLister,
  },
  koatuu: {
    editor: KoatuuEditor,
    lister: KoatuuLister,
  },
  landClassifier: {
    editor: LandClassifierEditor,
    lister: LandClassifierLister,
  },
  numbering: {
    editor: NumberingEditor,
    lister: NumberingLister,
  },
  typesRepair: {
    editor: TypesRepairEditor,
    lister: TypesRepairLister,
  },
  typesWork: {
    editor: TypesWorkEditor,
    lister: TypesWorkLister,
  },
  typesRepairIndustry: {
    editor: TypesRepairIndustryEditor,
    lister: TypesRepairIndustryLister,
  },
  objBuildingPVR: {
    editor: ObjBuildingPVREditor,
    lister: ObjBuildingPVRLister,
  },
  objBuildingTypeDoc: {
    editor: ObjBuildingTypeDocEditor,
    lister: ObjBuildingTypeDocLister,
  },
  typeAttachedFile: {
    editor: TypeAttachedFileEditor,
    lister: TypeAttachedFileLister,
  },
  resolutionSession: {
    editor: ResolutionSessionEditor,
    lister: ResolutionSessionLister,
  },

  objBuildingTypeSource: {
    editor: ObjBuildingTypeSourceEditor,
    lister: ObjBuildingTypeSourceLister,
  },

  objBuildingTypePower: {
    editor: ObjBuildingTypePowerEditor,
    lister: ObjBuildingTypePowerLister,
  },
  objBuildingSourceFin: {
    editor: ObjBuildingSourceFinEditor,
    lister: ObjBuildingSourceFinLister,
  },

  planing: {
    editor: PlaningEditor,
    lister: PlaningLister,
  },
  csuTransfer: {
    editor: CsuTransferEditor,
    lister: CsuTransferLister,
  },
  budgetTransfer: {
    editor: BudgetTransferEditor,
    lister: BudgetTransferLister,
  },
  reportSettings: {
    editor: ReportSettingsEditor,
    lister: ReportSettingsLister,
  },
  residenceKinds: {
    editor: ResidenceKindsEditor,
    lister: ResidenceKindsLister,
  },
  tarif: {
    editor: TarifEditor,
    lister: TarifLister,
  },
  categoriesFOP: {
    editor: CategoriesFOPEditor,
    lister: CategoriesFOPLister,
  },

  categoriesFOPVersions: {
    editor: CategoriesFOPVersionsEditor,
    lister: CategoriesFOPVersionsLister,
  },

  industryDet: {
    editor: IndustryDetEditor,
    lister: IndustryDetLister,
  },

  currency: {
    editor: CurrencyEditor,
    lister: CurrencyLister,
  },
  department: {
    editor: DepartmentEditor,
    lister: DepartmentLister,
  },
  reportsForms: {
    editor: ReportsFormsEditor,
    lister: ReportsFormsLister,
  },
  budgetPaymentCode: {
    editor: BudgetPaymentCodeEditor,
    lister: BudgetPaymentCodeLister,
  },
  symbolKdb: {
    editor: SymbolKdbEditor,
    lister: SymbolKdbLister,
  },

  // Компоненты исключительно на minfin-e
  bfnAmount: {},
  bfnGoalFondAmount: {},
  bfnGroupAge: {},
  bfnGroupFullness: {},
  bfnGroupTypes: {},
  bfnGroupName: {},
  bfnBanDoc: {},
  bfnPeriod: {},
  // symbolKdb: {},
};

export default {
  catComponents,

  BankAccountsLister,
  BankAccountsEditor,
  AdditionalPropertyCsuEditor,
  AdditionalPropertyCsuLister,
  AdditionalPropertyKFKEditor,
  AdditionalPropertyKFKLister,
  AdditionalPropertyTypesRepairEditor,
  AdditionalPropertyTypesRepairLister,
  CSULister,
  CSUEditor,
  ElementFondLister,
  ElementFondEditor,
  ElementKDBEditor,
  ElementKDBLister,
  ElementKEKVLister,
  ElementKEKVEditor,
  ElementKFBLister,
  ElementKFBEditor,
  ElementKFKLister,
  ElementKFKEditor,
  GroundLawEditor,
  GroundLawLister,
  IndicatorLister,
  IndicatorEditor,
  InvestorLister,
  InvestorEditor,
  Kbp7ClassifierLister,
  KBP7ClassifierEditor,
  ObjectsBuildingLister,
  ObjectsBuildingEditor,
  TemplateBaseDocLister,
  TemplateBaseDocEditor,
  TenderProcTypeLister,
  TenderProcTypeEditor,
  TargetEditor,
  TargetLister,
  DeputiesEditor,
  DeputiesLister,
  SignGroupEditor,
  SignGroupLister,
  ArticlesDebtSocialPaymentsEditor,
  ArticlesDebtSocialPaymentsLister,
  SubmittedDocumentsEditor,
  SubmittedDocumentsLister,
  usersEditor,
  UsersLister,
  KVKClassifierEditor,
  KVKClassifierLister,
  BanksEditor,
  BanksLister,
  FondClassifierEditor,
  FondClassifierLister,
  KEKVClassifierEditor,
  KEKVClassifierLister,
  KBPClassifierEditor,
  KBPClassifierLister,
  KDBClassifierEditor,
  KDBClassifierLister,
  TasksEditor,
  TasksLister,
  Tasks2019Editor,
  Tasks2019Lister,
  TypesOfLaborCostsEditor,
  TypesOfLaborCostsLister,
  SalaryStructureEditor,
  SalaryStructureLister,
  EnergyCarriersIndicatorEditor,
  EnergyCarriersIndicatorLister,
  PublicBudgetProjectEditor,
  PublicBudgetProjectLister,
  PublicBudgetProjectAuthorEditor,
  PublicBudgetProjectAuthorLister,
  IndustryEditor,
  IndustryLister,
  OutcomeGroupEditor,
  OutcomeGroupLister,
  ObjectivesStatePolicyEditor,
  ObjectivesStatePolicyLister,
  DocTypeEditor,
  DocTypeLister,
  TasksClassifierEditor,
  TasksClassifierLister,
  CoverageSourceClassifierEditor,
  CoverageSourceClassifierLister,
  ImplementerEditor,
  ImplementerLister,
  CoverageSourceLister,
  CoverageSourceEditor,
  VariantBudgetDraftEditor,
  VariantBudgetDraftLister,
  BudgetEditor,
  BudgetLister,
  KFBClassifierEditor,
  KFBClassifierLister,
  StewardGroupEditor,
  StewardGroupLister,
  KFKVKClassifierEditor,
  KFKVKClassifierLister,
  LawsEditor,
  LawsLister,
  ForecastCreditEditor,
  ForecastCreditLister,
  ForecastIndicatorBREditor,
  ForecastIndicatorBRLister,
  IndicatorsForTargetEditor,
  IndicatorsForTargetLister,
  StewardLevelEditor,
  StewardLevelLister,
  BaseDocEditor,
  BaseDocLister,
  NotesEditor,
  NotesLister,
  DocNotesEditor,
  DocNotesLister,
  CSUPartnersEditor,
  CSUPartnersLister,
  NoteClassifierEditor,
  NoteClassifierLister,
  PartnersEditor,
  PartnersLister,
  AccessGroupEditor,
  AccessGroupLister,
  ActivityKindsEditor,
  ActivityKindsLister,
  OfficialsEditor,
  OfficialsLister,
  TargetedProgramsEditor,
  TargetedProgramsLister,
  BuildingClassifierEditor,
  BuildingClassifierLister,
  ParkingKindsEditor,
  ParkingKindsLister,
  PartnerBanksEditor,
  PartnerBanksLister,
  PartnerBankAccountsEditor,
  PartnerBankAccountsLister,
  SignatureVariantsEditor,
  SignatureVariantsLister,
  ElementSocialPaymentEditor,
  ElementSocialPaymentLister,
  NoteSocialPaymentsEditor,
  NoteSocialPaymentsLister,
  TreasuryStatementEditor,
  TreasuryStatementLister,
  DistrictsEditor,
  DistrictsLister,
  StreetsEditor,
  StreetsLister,
  HousesEditor,
  HousesLister,
  TypeTaxSFForRaitingZFEditor,
  TypeTaxSFForRaitingZFLister,
  StatesContingentsEditor,
  StatesContingentsLister,
  PaymentPurposeTemplateEditor,
  PaymentPurposeTemplateLister,
  SignGroupByDocumentsEditor,
  SignGroupByDocumentsLister,
  UnitsEditor,
  UnitsLister,
  ExplanationsEditor,
  ExplanationsLister,
  AdministrativeTerritorialUnitsEditor,
  AdministrativeTerritorialUnitsLister,
  IndicatorsClassifierEditor,
  IndicatorsClassifierLister,
  InfoSourcesEditor,
  InfoSourcesLister,
  // ModelProgramsClassifierEditor,
  // ModelProgramsClassifierLister,
  RegionsEditor,
  RegionsLister,
  ProvisionOfNormativeLegalActsEditor,
  ProvisionOfNormativeLegalActsLister,
  RecipientsEditor,
  RecipientsLister,
  ArtistsRolesEditor,
  ArtistsRolesLister,
  KekvClassifierShortEditor,
  KekvClassifierShortLister,
  KekvClassifierForPAShortEditor,
  KekvClassifierForPAShortLister,
  KekvClassifierForMinFinShortEditor,
  KekvClassifierForMinFinShortLister,
  KekvClassifierFPShortEditor,
  KekvClassifierFPShortLister,
  KoatuuEditor,
  KoatuuLister,
  LandClassifierEditor,
  LandClassifierLister,
  NumberingEditor,
  NumberingLister,
  TypesRepairEditor,
  TypesRepairLister,
  TypesWorkEditor,
  TypesWorkLister,
  TypesRepairIndustryEditor,
  TypesRepairIndustryLister,
  ObjBuildingPVREditor,
  ObjBuildingPVRLister,
  ObjBuildingTypeDocEditor,
  ObjBuildingTypeDocLister,
  TypeAttachedFileEditor,
  TypeAttachedFileLister,
  ResolutionSessionEditor,
  ResolutionSessionLister,
  ObjBuildingTypeSourceEditor,
  ObjBuildingTypeSourceLister,
  ObjBuildingTypePowerEditor,
  ObjBuildingTypePowerLister,
  ObjBuildingSourceFinEditor,
  ObjBuildingSourceFinLister,
  PlaningEditor,
  PlaningLister,
  CsuTransferEditor,
  CsuTransferLister,
  BudgetTransferEditor,
  BudgetTransferLister,
  ReportSettingsEditor,
  ReportSettingsLister,
  ResidenceKindsEditor,
  ResidenceKindsLister,
  TarifEditor,
  TarifLister,
  CategoriesFOPEditor,
  CategoriesFOPLister,
  CategoriesFOPVersionsEditor,
  CategoriesFOPVersionsLister,
  IndustryDetEditor,
  IndustryDetLister,
  CurrencyEditor,
  CurrencyLister,
};

export {
  catComponents,

  BankAccountsLister,
  BankAccountsEditor,
  AdditionalPropertyCsuEditor,
  AdditionalPropertyCsuLister,
  AdditionalPropertyTypesRepairEditor,
  AdditionalPropertyTypesRepairLister,
  CSULister,
  CSUEditor,
  ElementFondLister,
  ElementFondEditor,
  ElementKDBEditor,
  ElementKDBLister,
  ElementKEKVLister,
  ElementKEKVEditor,
  ElementKFBLister,
  ElementKFBEditor,
  ElementKFKLister,
  ElementKFKEditor,
  GroundLawEditor,
  GroundLawLister,
  IndicatorLister,
  IndicatorEditor,
  InvestorLister,
  InvestorEditor,
  Kbp7ClassifierLister,
  KBP7ClassifierEditor,
  ObjectsBuildingLister,
  ObjectsBuildingEditor,
  TemplateBaseDocLister,
  TemplateBaseDocEditor,
  TenderProcTypeLister,
  TenderProcTypeEditor,
  TargetEditor,
  TargetLister,
  DeputiesEditor,
  DeputiesLister,
  SignGroupEditor,
  SignGroupLister,
  ArticlesDebtSocialPaymentsEditor,
  ArticlesDebtSocialPaymentsLister,
  SubmittedDocumentsEditor,
  SubmittedDocumentsLister,
  usersEditor,
  UsersLister,
  KVKClassifierEditor,
  KVKClassifierLister,
  BanksEditor,
  BanksLister,
  FondClassifierEditor,
  FondClassifierLister,
  KEKVClassifierEditor,
  KEKVClassifierLister,
  KBPClassifierEditor,
  KBPClassifierLister,
  KDBClassifierEditor,
  KDBClassifierLister,
  TasksEditor,
  TasksLister,
  Tasks2019Editor,
  Tasks2019Lister,
  TypesOfLaborCostsEditor,
  TypesOfLaborCostsLister,
  SalaryStructureEditor,
  SalaryStructureLister,
  EnergyCarriersIndicatorEditor,
  EnergyCarriersIndicatorLister,
  PublicBudgetProjectEditor,
  PublicBudgetProjectLister,
  PublicBudgetProjectAuthorEditor,
  PublicBudgetProjectAuthorLister,
  IndustryEditor,
  IndustryLister,
  OutcomeGroupEditor,
  OutcomeGroupLister,
  ObjectivesStatePolicyEditor,
  ObjectivesStatePolicyLister,
  DocTypeEditor,
  DocTypeLister,
  TasksClassifierEditor,
  TasksClassifierLister,
  CoverageSourceClassifierEditor,
  CoverageSourceClassifierLister,
  CoverageSourceEditor,
  CoverageSourceLister,
  ImplementerEditor,
  ImplementerLister,
  VariantBudgetDraftEditor,
  VariantBudgetDraftLister,
  BudgetEditor,
  BudgetLister,
  KFBClassifierEditor,
  KFBClassifierLister,
  StewardGroupEditor,
  StewardGroupLister,
  KFKVKClassifierEditor,
  KFKVKClassifierLister,
  LawsEditor,
  LawsLister,
  ForecastCreditEditor,
  ForecastCreditLister,
  ForecastIndicatorBREditor,
  ForecastIndicatorBRLister,
  IndicatorsForTargetEditor,
  IndicatorsForTargetLister,
  StewardLevelEditor,
  StewardLevelLister,
  BaseDocEditor,
  BaseDocLister,
  NotesEditor,
  NotesLister,
  DocNotesEditor,
  DocNotesLister,
  CSUPartnersEditor,
  CSUPartnersLister,
  NoteClassifierEditor,
  NoteClassifierLister,
  PartnersEditor,
  PartnersLister,
  AccessGroupEditor,
  AccessGroupLister,
  ActivityKindsEditor,
  ActivityKindsLister,
  OfficialsEditor,
  OfficialsLister,
  TargetedProgramsEditor,
  TargetedProgramsLister,
  BuildingClassifierEditor,
  BuildingClassifierLister,
  ParkingKindsEditor,
  ParkingKindsLister,
  PartnerBanksEditor,
  PartnerBanksLister,
  PartnerBankAccountsEditor,
  PartnerBankAccountsLister,
  SignatureVariantsEditor,
  SignatureVariantsLister,
  ElementSocialPaymentEditor,
  ElementSocialPaymentLister,
  NoteSocialPaymentsEditor,
  NoteSocialPaymentsLister,
  TreasuryStatementEditor,
  TreasuryStatementLister,
  DistrictsEditor,
  DistrictsLister,
  StreetsEditor,
  StreetsLister,
  HousesEditor,
  HousesLister,
  TypeTaxSFForRaitingZFEditor,
  TypeTaxSFForRaitingZFLister,
  StatesContingentsEditor,
  StatesContingentsLister,
  PaymentPurposeTemplateEditor,
  PaymentPurposeTemplateLister,
  SignGroupByDocumentsEditor,
  SignGroupByDocumentsLister,
  UnitsEditor,
  UnitsLister,
  ExplanationsEditor,
  ExplanationsLister,
  AdministrativeTerritorialUnitsEditor,
  AdministrativeTerritorialUnitsLister,
  IndicatorsClassifierEditor,
  IndicatorsClassifierLister,
  InfoSourcesEditor,
  InfoSourcesLister,
  // ModelProgramsClassifierEditor,
  // ModelProgramsClassifierLister,
  RegionsEditor,
  RegionsLister,
  ProvisionOfNormativeLegalActsEditor,
  ProvisionOfNormativeLegalActsLister,
  RecipientsEditor,
  RecipientsLister,
  ArtistsRolesEditor,
  ArtistsRolesLister,
  KekvClassifierShortEditor,
  KekvClassifierShortLister,
  KekvClassifierForPAShortEditor,
  KekvClassifierForPAShortLister,
  KekvClassifierForMinFinShortEditor,
  KekvClassifierForMinFinShortLister,
  KekvClassifierFPShortEditor,
  KekvClassifierFPShortLister,
  NumberingEditor,
  NumberingLister,
  TypesRepairEditor,
  TypesRepairLister,
  TypesWorkEditor,
  TypesWorkLister,
  TypesRepairIndustryEditor,
  TypesRepairIndustryLister,
  ObjBuildingPVREditor,
  ObjBuildingPVRLister,
  ObjBuildingTypeDocEditor,
  ObjBuildingTypeDocLister,
  TypeAttachedFileEditor,
  TypeAttachedFileLister,
  ResolutionSessionEditor,
  ResolutionSessionLister,
  ObjBuildingTypeSourceEditor,
  ObjBuildingTypeSourceLister,
  ObjBuildingTypePowerEditor,
  ObjBuildingTypePowerLister,
  ObjBuildingSourceFinEditor,
  ObjBuildingSourceFinLister,
  PlaningEditor,
  PlaningLister,
  CsuTransferEditor,
  CsuTransferLister,
  BudgetTransferEditor,
  BudgetTransferLister,
  ReportSettingsEditor,
  ReportSettingsLister,
  ResidenceKindsEditor,
  ResidenceKindsLister,
  TarifEditor,
  TarifLister,
  CategoriesFOPEditor,
  CategoriesFOPLister,
  CategoriesFOPVersionsEditor,
  CategoriesFOPVersionsLister,
  IndustryDetEditor,
  IndustryDetLister,
  CurrencyEditor,
  CurrencyLister,
  SymbolKdbEditor,
  SymbolKdbLister,
};
