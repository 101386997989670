import React, { useCallback, forwardRef, useState } from 'react';
import {
  Accordion,
  Alert, Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Loader } from '../../../../components/bootStrap';
import PrintCommandPanel from './printCommandPanel';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { metaBackends } from '../../../../meta';

const PrintFormContainer = forwardRef(({
  loading, err, settings, quickSettings, actions, pageLayout,
}, ref) => {
  const [settingsOpened, setSettinsOpened] = useState(false);
  const onToggleSettings = useCallback(
    () => setSettinsOpened((o) => !o),
    [],
  );
  const accActiveKey = settingsOpened ? 'settings' : 'result';
  const navigate = useNavigate();
  const clickHandler = useCallback(
    (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault();
        e.stopPropagation();
        const oldUrl = e.target.getAttribute('href') || '';
        const res = /^(\/api\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/)(\d+\/)$/.exec(oldUrl);
        if (res.length === 3) {
          const md = metaBackends[res[1]];
          if (md) {
            const url = `${md.frontendURL}${res[2]}`;
            navigate(url);
          } else {
            console.error(`Cannot resolve url ${oldUrl}!`);
          }
        }
      }
    },
    [navigate],
  );
  return (
    <Container fluid className="flex-fill overflow-hidden d-flex flex-column h-100">
      {loading && (
        <Loader text="Завантаження" />
      )}
      {err && (
        <IconAlert variant="danger">
          <Alert.Heading>Не вдалося сформувати друковану форму</Alert.Heading>
          {Array.isArray(err) ? (
            <ul className="list-unstyled">
              {err.map((er, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>{er}</li>
              ))}
            </ul>
          ) : err}
        </IconAlert>
      )}
      {quickSettings && (
        <div className="border rounded p-3">
          {quickSettings}
        </div>
      )}
      <div className="mt-2">
        <PrintCommandPanel
          onPrint={actions.onPrint}
          onGenerateReport={actions.onGenerate}
          onGenerateXLSX={actions.onGenerateXLSX}
          onGeneratePDF={actions.onGeneratePDF}
          onGenerateHTML={actions.onGenerateHTML}
          onToggleSettings={onToggleSettings}
          pageLayout={pageLayout}
          pageLayoutValues={pageLayout.field}
          settingsActive={settingsOpened}
          showSettings={!!settings}
        />
      </div>
      <Accordion activeKey={accActiveKey} className="flex-fill overflow-hidden">
        {/* Зробила так бо обрізався дропдаун в налаштуваннях */}
        {/* <Accordion.Collapse eventKey="result"> */}
        {/*  <div className="border rounded p-2"> */}
        {/*    <Result ref={ref} className="scrollbar rounded p-6" /> */}
        {/*  </div> */}
        {/* </Accordion.Collapse> */}
        <Accordion.Collapse eventKey="settings" className="h-100">
          <div className="border rounded p-2 bg-white scrollbar h-100 d-flex flex-column overflow-visible">
            {settings}
          </div>
        </Accordion.Collapse>
        <div className="border rounded p-2 scrollbar h-100 text-black bg-white">
          <div className={classNames('preview a4', pageLayout.value.toLowerCase())}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */}
            <div ref={ref} className="p-6" onClick={clickHandler} />
          </div>
        </div>
      </Accordion>
    </Container>
  );
});

PrintFormContainer.propTypes = {
  loading: PropTypes.bool,
  err: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  settings: PropTypes.element,
  quickSettings: PropTypes.element,
  actions: PropTypes.shape({
    onGenerate: PropTypes.func.isRequired,
    onGeneratePDF: PropTypes.func,
    onGenerateHTML: PropTypes.func,
    onGenerateXLSX: PropTypes.func,
    onPrint: PropTypes.func.isRequired,
  }).isRequired,
  pageLayout: PropTypes.shape({
    value: PropTypes.string,
    field: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        display_label: PropTypes.string,
      })),
    }),
    onToggle: PropTypes.func,
  }).isRequired,
};

PrintFormContainer.defaultProps = {
  loading: false,
  err: null,
  settings: null,
  quickSettings: null,
};

export default PrintFormContainer;
