import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Зведення мережі ФОП (Планові дані)',
  name: 'NetFOPPlanreduce',
  columns,
  tables: [],
  frontend: 'rep/netFOPPlanReduce',
  backendName: 'ЗведенняМережіФОПплан',
  resultColumns: baseResultColumns,
};

export default definition;
