import { Card } from 'react-bootstrap';
import Lottie from 'lottie-react';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import euKeyFile from '../../assets/animation/eu_key_file.json';
import euKeyMedia from '../../assets/animation/eu_key_media.json';
import { LIBRARY_TYPES } from '../../ciatEU/hook';

const MEDIA_TYPES = {
  [LIBRARY_TYPES.file]: { animation: euKeyFile, caption: 'Файловий ключ' },
  [LIBRARY_TYPES.km]: { animation: euKeyMedia, caption: 'Апаратний ключ' },
};

const StyledCard = styled(Card)`
    & .card-header {
        transition: all 0.4s ease;
    }
    &:hover .card-header{
        background-color: var(--falcon-success);
    }
`;
function MediaType({ type, selected, onSelect }) {
  return (
    <StyledCard
      className={classNames('w-25', { 'shadow-lg': selected })}
      role="button"
      onClick={onSelect}
    >
      <Card.Body>
        <Lottie animationData={MEDIA_TYPES[type].animation} />
      </Card.Body>
      <Card.Header className={classNames({ 'bg-primary': selected })}>
        <Card.Title className="text-center">{MEDIA_TYPES[type].caption}</Card.Title>
      </Card.Header>
    </StyledCard>

  );
}

MediaType.propTypes = {
  type: PropTypes.oneOf(Object.keys(MEDIA_TYPES)),
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

MediaType.defaultProps = {
  type: null,
  selected: false,
};

export default MediaType;
