import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd2';

const meta = md.tables.comT;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear, usePCMCatalog,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'exp'}
              highlighted={highlights.includes('exp')}
              onToggleHighlght={() => onToggleHighlght('exp')}
            >
              {meta.columns.exp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0appr'}
              highlighted={highlights.includes('zf0appr')}
              onToggleHighlght={() => onToggleHighlght('zf0appr')}
            >
              {`затверджено прогнозом місцевого бюджету на ${activeYear} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf0appr'}
              highlighted={highlights.includes('sf0appr')}
              onToggleHighlght={() => onToggleHighlght('sf0appr')}
            >
              {`затверджено прогнозом місцевого бюджету на ${activeYear} рік по СФ`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {`включено до пропозиції до прогнозу місцевого бюджету на ${activeYear} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {`включено до пропозиції до прогнозу місцевого бюджету на ${activeYear} рік по СФ`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0div'}
              highlighted={highlights.includes('zf0div')}
              onToggleHighlght={() => onToggleHighlght('zf0div')}
            >
              {`Відхилення +/- ${activeYear} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf0div'}
              highlighted={highlights.includes('sf0div')}
              onToggleHighlght={() => onToggleHighlght('sf0div')}
            >
              {`Відхилення +/- ${activeYear} рік по СФ`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1appr'}
              highlighted={highlights.includes('zf1appr')}
              onToggleHighlght={() => onToggleHighlght('zf1appr')}
            >
              {`затверджено прогнозом місцевого бюджету на ${activeYear + 1} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1appr'}
              highlighted={highlights.includes('sf1appr')}
              onToggleHighlght={() => onToggleHighlght('sf1appr')}
            >
              {`затверджено прогнозом місцевого бюджету на ${activeYear + 1} рік по СФ`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {`включено до пропозиції до прогнозу місцевого бюджету на ${activeYear + 1} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {`включено до пропозиції до прогнозу місцевого бюджету на ${activeYear + 1} рік по СФ`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1div'}
              highlighted={highlights.includes('zf1div')}
              onToggleHighlght={() => onToggleHighlght('zf1div')}
            >
              {`Відхилення +/- ${activeYear + 1} рік по ЗФ`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1div'}
              highlighted={highlights.includes('sf1div')}
              onToggleHighlght={() => onToggleHighlght('sf1div')}
            >
              {`Відхилення +/- ${activeYear + 1} рік по СФ`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,
  usePCMCatalog: PropTypes.bool,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
