import DocumentsInLogica from './DocumentsInLogica';
import Exchange from './Exchange';
// import ForecastsDashBoard from './ForecastsDashBoard';
// import ForecastsWizard from './ForecastsWizard';
import GroupManagement from './GroupManagement';
import ImportDataFromLogica from './ImportDataFromLogica';
import ImportPlans from './importPlans';
import ImportTreasureFiles from './ImportTreasureFiles';
import ImportVp from './ImportVp';
import LogikaLogin from './LogikaLogin';
import MDEditor from './MDEditor';
import RoleManagement from './RoleManagement';
import SKDMaster from './SKDMaster';
// import StepByStep from './StepByStep';
import TreasureExport from './TreasureExport';
import ChiefDashboard from './ChiefDashboard';
import OpenBudget from './OpenBudget';
import ImportFRFZfromLogica from './importFRFZfromLogica';
import ExportChangesToLogica from './ExportChangesToLogica';

const services = {
  ...Exchange,
  DocumentsInLogica,
  // ForecastsDashBoard,
  // ForecastsWizard,
  GroupManagement,
  groups: GroupManagement,
  ImportDataFromLogica,
  ImportPlans,
  ImportTreasureFiles,
  ImportVp,
  LogikaLogin,
  MDEditor,
  role: RoleManagement,
  RoleManagement,
  SKDMaster,
  // StepByStep,
  TreasureExport,
  ChiefDashboard,
  OpenBudget,
  ImportFRFZfromLogica,
  ExportChangesToLogica,
};

export default services;
