import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import {
  changeField,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/passportAnalisys';
import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import StyledTPPane from '../../../components/tableParts/TPPane';
import InputWithDropdown from '../../../components/styledInputs/inputWithDropdown';
import ReportContainer from '../reportContainer';

const indicatorTypes = {
  costs: { name: 0, label: 'Показники затрат' },
  product: { name: 1, label: 'Показники продукту' },
  efficiency: { name: 2, label: 'Показники ефективності' },
  quality: { name: 3, label: 'Показники якості' },
  load: { name: 4, label: 'Показники робочого навантаження' },
  usefulness: { name: 5, label: 'Показники корисності' },
};

const indicatorPeriod = {
  costs: { name: 0, label: 'Квартал' },
  product: { name: 1, label: 'Півріччя' },
  efficiency: { name: 2, label: '9 місяців' },
  quality: { name: 3, label: 'Рік' },

};

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const FourColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 23% 23% 23% 23% ;
  grid-column-gap: 2%;
`;

class ReportPassportAnalisysEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    isProcessing: false,
    portalOpened: false,
    sessionOptions: new Map(),
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm', 'reportEditor', 'result'];
  }

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const SettingsButton = (
      <div>
        <MyContainerFormWithNColumns columns={2}>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {isAdmin ? (
              <Fields.SelectorField
                value={headerForm.get('FO', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => {
                  dispatch(changeField(['headerForm', 'FO'], v));
                  dispatch(changeField(['headerForm', 'signVariant'], new Map()));
                }}
              />
            ) : (
              <Fields.ItemField
                value={headerForm.get('FO', new Map())}
              />
            )}
          </div>
        </MyContainerFormWithNColumns>
        <FourColumnsGrid>
          <Fields.CheckboxField
            value={headerForm.get('docKind', true)}
            label={md.columns.docKind.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'docKind'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('byHighLevel', true)}
            label={md.columns.byHighLevel.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'byHighLevel'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('onlyLast', true)}
            label={md.columns.onlyLast.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'onlyLast'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('onlyAppr', true)}
            label={md.columns.onlyAppr.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'onlyAppr'], v))}
          />
        </FourColumnsGrid>
        <FourColumnsGrid>
          <div>
            <StyledLabel>{md.columns.typeInd.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('typeInd')}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'typeInd'], value))}
              options={Object.values(indicatorTypes)}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.periodOfIndicator.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('periodOfIndicator')}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'periodOfIndicator'], value))}
              options={Object.values(indicatorPeriod)}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.taskInd.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('taskInd', new Map())}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'taskInd'], value))}
              modelName="tasks2019"
              modelType="cat"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.kfk.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('kfk', new Map())}
              onChange={(e, value) => dispatch(changeField(['headerForm', 'kfk'], value))}
              modelName="kbp7Classifier"
              modelType="cat"
            />
          </div>
        </FourColumnsGrid>
      </div>
    );

    return (
      <ReportContainer
        isProcessing={isProcessing}
        portalOpened={portalOpened}
        SettingButton={SettingsButton}
        backendName={md.backendName}
      >
        <div style={{ display: 'flex' }} />
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
              noBorder
            />
          </div>
          {/*<div>*/}
          {/*  <StyledLabel style={{ marginBottom: '6px' }}>{md.columns.signVariant.label}</StyledLabel>*/}
          {/*  <Fields.SelectorField*/}
          {/*    value={headerForm.get('signVariant', new Map())}*/}
          {/*    modelType="cat"*/}
          {/*    modelName="signatureVariants"*/}
          {/*    onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}*/}
          {/*    filter={[*/}
          {/*      { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}*/}
          {/*  />*/}
          {/*</div>*/}
        </ContainerDiv>
        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportPassportAnalisysEditor);
