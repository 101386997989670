/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from './printCP';

function APMenu({ id, ...rest }) {
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <PrintModal id={id} />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
