import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Collapse, ListGroup,
} from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import itemTypes from '../itemTypes';
import OrdersItem from './ordersItem';
import { ordersPropType } from '../../hooks';
import Background from '../../../../components/blanks/common/Background';
import corner4 from '../../../../assets/img/illustrations/corner-4.png';

function OrdersList({
  cardTitle, cardText,
  orders, activeOrder, onClick, onDblClick, onDragEnd, onDirectionChange,
}) {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback(
    (item) => {
      onDragEnd(item, padded);
    },
    [onDragEnd, padded],
  );
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.order],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  const [helpOpened, setHelpOpened] = useState(false);
  return (
    <div className="w-100 h-100 d-flex flex-column" ref={ref}>
      <Card className="rounded-0 border-bottom">
        <Background
          image={corner4}
          className="bg-card d-none d-sm-block rounded-0"
        />
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title>
              {cardTitle}
            </Card.Title>
            <Button onClick={() => setHelpOpened((o) => !o)} size="sm" variant="link" className="z-1">
              <FontAwesomeIcon icon={faQuestionCircle} bounce={helpOpened} />
            </Button>
          </div>
          <Collapse in={helpOpened}>
            <Card.Text>
              {cardText}
            </Card.Text>
          </Collapse>
        </Card.Body>
      </Card>
      <ListGroup className="flex-fill scrollbar fs--1">
        {orders.map((f) => (
          <OrdersItem
            key={f.name}
            name={f.name}
            label={f.label}
            onClick={onClick}
            onDblClick={onDblClick}
            active={activeOrder === f.name}
            onHover={setPadded}
            padded={padded === f.name}
            direction={f.direction}
            onDirectionChange={onDirectionChange}
            errored={f.errored}
          />
        ))}
      </ListGroup>
    </div>
  );
}

OrdersList.propTypes = {
  orders: ordersPropType.isRequired,
  cardTitle: PropTypes.node.isRequired,
  cardText: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeOrder: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  onDirectionChange: PropTypes.func.isRequired,
};

OrdersList.defaultProps = {
  activeOrder: null,
  onDblClick: () => null,
};

export default OrdersList;
