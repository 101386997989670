import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, FormControl, InputGroup } from 'react-bootstrap';

const Input = styled.input`
  background: white;
  border: solid 1px #cdd3d9;
  border-radius: 0.25rem;
  height: 38px;
`;

const ColorInput = forwardRef(({
  value, disabled, readOnly, size,
  onClick, onFocus, maxLength, onChange, id,
  errors, label, errorAsTooltip,
}, ref) => {
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };
  const errored = !!errors && !!errors.length;
  return (
    <p>
      <Form.Label>
        {label}
      </Form.Label>
      <InputGroup size={size || 'sm'}>
        <FormControl
          as={Input}
          type="color"
          ref={ref}
          id={id}
          value={value || ''}
          onChange={changeHandler}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          onFocus={onFocus}
          isInvalid={errored}
        />
        {errors && (
        <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </Form.Control.Feedback>
        )}
      </InputGroup>

    </p>
  );
});

ColorInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
};

ColorInput.defaultProps = {
  id: '',
  value: '',
  size: 'sm',
  label: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  errors: null,
  errorAsTooltip: false,
};

export default ColorInput;
