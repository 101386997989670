import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  period: { label: 'Період', name: 'Период' },
  KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
  KPKV: { label: 'КПКВ', name: 'КПКВ' },
  KBP: { label: 'КБП', name: 'КБП' },
  csu: { label: 'Розпорядник', name: 'Распорядитель' },
  byPcm: { label: ' за ПЦМ', name: 'флПЦМ' },
  fond: { label: 'Фонд', name: 'Фонд' },
  coverElement: { label: 'Джерело покриття', name: 'ЭлементИсточникПокрытия' },
};

/**
 * @const
 */
const definition = {
  name: 'periodPlanPrinting',
  backendName: 'ПечатьИзмененияЗаПериод',
  frontend: 'dp/periodPlanPrinting',
  label: 'Зміни за період',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
