import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getColor } from '../../../../components/blanks/helpers/utils';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';
import valueFormatter from '../const/valueFormatter';
import useDiagramShower from '../diagramHook';

function ChartTop10({ data }) {
  const programNames = data.map((item) => item.repr);
  const programAmounts = data.map((item) => item.amount);

  const tooltipFormatter = (params) => {
    if (params[0]) {
      const { name, value } = params[0];
      const formattedValue = value.toLocaleString('uk', moneysOneDigitStingOptions);

      return `${name}: ${formattedValue} тис.грн.`;
    }
    return '';
  };

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
    },
    xAxis: {
      type: 'category',
      data: programNames,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid',
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: () => '*', // value.substring(0, 3),
        margin: 15,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15,
        formatter: (value) => valueFormatter(value),
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200'),
        },
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 600,
    },
    series: [
      {
        type: 'bar',
        barWidth: '15rem',
        name: 'Cума видатків',
        data: programAmounts,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: [10, 10, 0, 0],
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true,
        },
      },
    ],
    grid: {
      right: '3%',
      left: '90px',
      bottom: '10%',
      top: '5%',
    },
  });

  const { containerChartRef, show } = useDiagramShower({});

  return (
    <Card className="overflow-hidden">
      <CardHeader title="Найбільші міські цільові програми" titleTag="h6" />
      <Card.Body className="p-0" ref={containerChartRef}>
        {show && (
          <ReactEChartsCore
            echarts={echarts}
            option={getOption()}
            style={{ height: '20rem' }}
          />
        )}
      </Card.Body>
    </Card>
  );
}

ChartTop10.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      repr: PropTypes.string,
      amount: PropTypes.number,
    }),
  ).isRequired,
};

export default ChartTop10;
