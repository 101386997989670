import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeToAssignPlanSource';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions, months } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;
const QUARTERS = [...Array(4)].map((_, i) => i);

function TPHeader({
  totals,
  activeCol,
  highlights,
  onToggleHighlght,
  onlyYear,
  inBR,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1 d-flex flex-column justify-content-start"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'elementKFB'}
              highlighted={highlights.includes('elementKFB')}
              onToggleHighlght={() => onToggleHighlght('elementKFB')}
            >
              {meta.columns.elementKFB.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col sm={12} className="px-1">
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q} className="px-1 border-right">
              {[...Array(3)].map((_, m) => (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col className="text-center">
                    <TableHeaderCell
                      active={activeCol === `Sum${q * 3 + m + 1}`}
                      title={totals[`Sum${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}
                      onToggleHighlght={() => onToggleHighlght(`Sum${q * 3 + m + 1}`)}
                    >
                      {months[q * 3 + m]}
                    </TableHeaderCell>
                  </Col>
                  {inBR && (
                  <Col className="text-center font-italic">
                    <TableHeaderCell
                      active={activeCol === `SumBR${q * 3 + m + 1}`}
                      title={totals[`SumBR${q * 3 + m + 1}`].toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}
                      onToggleHighlght={() => onToggleHighlght(`SumBR${q * 3 + m + 1}`)}
                    >
                      В т.ч. БР
                    </TableHeaderCell>
                  </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          {onlyYear ? (
            <Col md={12} className="px-1 d-flex flex-column">
              <TableHeaderCell
                className="text-center"
                active={activeCol === 'Sum1' && onlyYear}
                title={totals.Sum.toLocaleString('uk', moneysStingOptions)}
                canHighlight={onlyYear}
                highlighted={onlyYear && highlights.includes('Sum1')}
                onToggleHighlght={() => onToggleHighlght('Sum1')}
              >
                {!onlyYear ? meta.columns.Sum.label : 'Сума на рік'}
              </TableHeaderCell>
              {inBR && (
              <TableHeaderCell
                className="text-center font-italic"
                active={activeCol === 'SumBR1' && onlyYear}
                title={totals.SumBR.toLocaleString('uk', moneysStingOptions)}
                canHighlight={onlyYear}
                highlighted={onlyYear && highlights.includes('SumBR1')}
                onToggleHighlght={() => onToggleHighlght('SumBR1')}
              >
                В т.ч. БР
              </TableHeaderCell>
              )}
            </Col>
          ) : (
            <Col md={12} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-md-row flex-xl-column">
              <TableHeaderCell
                className="text-right text-sm-center text-md-center w-100"
                active={activeCol === 'Sum1' && onlyYear}
                title={totals.Sum.toLocaleString('uk', moneysStingOptions)}
                canHighlight={onlyYear}
                highlighted={onlyYear && highlights.includes('Sum1')}
                onToggleHighlght={() => onToggleHighlght('Sum1')}
              >
                {!onlyYear ? meta.columns.Sum.label : 'Сума на рік'}
              </TableHeaderCell>
              {inBR && (
              <TableHeaderCell
                className="text-right text-sm-center text-md-center w-100 font-italic"
                active={activeCol === 'SumBR1' && onlyYear}
                title={totals.SumBR.toLocaleString('uk', moneysStingOptions)}
                canHighlight={onlyYear}
                highlighted={onlyYear && highlights.includes('SumBR1')}
                onToggleHighlght={() => onToggleHighlght('SumBR1')}
              >
                В т.ч. БР
              </TableHeaderCell>
              )}
            </Col>
          )}

        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    Sum: PropTypes.number,
    SumBR: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`Sum${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  onlyYear: PropTypes.bool,
  inBR: PropTypes.bool,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  onlyYear: false,
  inBR: false,
};

export default memo(TPHeader);
