const frontendURL = '/cat/law/';
const backendURL = '/api/references/reflaw/';
const name = 'Нормативно-законодавчі акти України';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
