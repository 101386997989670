const frontendURL = '/doc/loanprojectchanges/';
const backendURL = '/api/planproject/loanprojectchangesheader/';
// const printURL = '';
const name = 'Зміни до проекту позик';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
