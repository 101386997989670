import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Styled from 'styled-components';
import TableSection from './TableSection';

const StyledTh = Styled.th`
  border: 1px solid var(--falcon-gray-400) !important;
`;
const StyledTh5 = Styled(StyledTh)`
  width: 5%;
`;

const StyledTh50 = Styled(StyledTh)`
  width: 50%;
`;

const StyledTh15 = Styled(StyledTh)`
width: 15%;
`;
function RegionProgTable({ data }) {
  return (
    <Table className="fs--1 mb-0">
      <thead className="bg-200 text-800">
        <tr>
          <StyledTh5 className="text-center" scope="col">Код</StyledTh5>
          <StyledTh50 className="text-center" scope="col">Найменування</StyledTh50>
          <StyledTh15 className="text-center" scope="col">План</StyledTh15>
          <StyledTh15 className="text-center" scope="col">Факт</StyledTh15>
          <StyledTh15 className="text-center" scope="col">% виконання</StyledTh15>
        </tr>
      </thead>
      <tbody>
        { data.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableSection row={row} key={`rowProgall-${index}`} level={0} />
        ))}
      </tbody>
    </Table>
  );
}

RegionProgTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

RegionProgTable.defaultProps = {
  data: [],
};

export default RegionProgTable;
