import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import Tabs from '../../../../components/tabs/Tabs';
import StyledTPPane from '../../../../components/tableParts/TPPane';
import md from '../../../../constants/meta/catalogs/indicators';

const indicatorTypes = {
  costs: { name: 'Затрат', label: 'Показники затрат' },
  product: { name: 'Продукта', label: 'Показники продукту' },
  efficiency: { name: 'Эфективности', label: 'Показники ефективності' },
  quality: { name: 'Качества', label: 'Показники якості' },
  load: { name: 'Нагрузки', label: 'Показники робочого навантаження' },
  usefulness: { name: 'Полезности', label: 'Показники корисності' },
};

const indicatorTypesValues = Object.values(indicatorTypes).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

const indicatorSymptoms = {
  stimulator: { name: 'Стимулятор', label: 'Стимулятор' },
  destimulator: { name: 'Дестимулятор', label: 'Дестимулятор' },
};

const indicatorSymptomsValues = Object.values(indicatorSymptoms).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function TabMenu({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  console.log(data);
  const firstPane = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.InfoSource.label}
            value={data[md.columns.InfoSource.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.InfoSource.name]: value,
            })}
            modelType="cat"
            modelName="infoSources"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.IndicatorType.label}
            value={data[md.columns.IndicatorType.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.IndicatorType.name]: value,
            })}
            values={indicatorTypesValues}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Units.label}
            value={data[md.columns.Units.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Units.name]: value,
            })}
            modelType="cat"
            modelName="units"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Un.label}
            value={data[md.columns.Un.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Un.name]: value,
            })}
            modelType="cat"
            modelName="unitsClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.Symptom.label}
            value={data[md.columns.Symptom.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Symptom.name]: value,
            })}
            values={indicatorSymptomsValues}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const secondPane = (
    <Container fluid>
      <Row>
        <Col>(</Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Numerator.label}
            value={data[md.columns.Numerator.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Numerator.name]: value,
            })}
            modelType="cat"
            modelName="indicators"
            filter={[
              {
                fieldName: 'Владелец',
                value: data[md.columns.owner.name],
              },
            ]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Sign.label}
            value={data[md.columns.Sign.name]}
            maxLength={1}
            onChange={(e, value) => actions.onChange({
              [md.columns.Sign.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Subtrahend.label}
            value={data[md.columns.Subtrahend.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Subtrahend.name]: value,
            })}
            modelType="cat"
            modelName="indicators"
            filter={[
              {
                fieldName: 'Владелец',
                value: data[md.columns.owner.name],
              },
            ]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>)</Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Factor.label}
            value={data[md.columns.Factor.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Factor.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Denominator.label}
            value={data[md.columns.Denominator.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Denominator.name]: value,
            })}
            modelType="cat"
            modelName="indicators"
            filter={[
              {
                fieldName: 'Владелец',
                value: data[md.columns.owner.name],
              },
            ]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
  return (
    <Tabs>
      <Col label="Основне">
        <StyledTPPane>
          {firstPane}
        </StyledTPPane>
      </Col>
      <Col label="Обрахунок середніх витрат">
        <StyledTPPane disabled={!data[md.columns.CalculateAverage.name]}>
          {secondPane}
        </StyledTPPane>
      </Col>
    </Tabs>
  );
}

TabMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TabMenu;
