import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  recipients: { label: 'Отримувачи', name: 'Получатели' },
  date_until: { label: 'Актуально до', name: 'Дата актуальности' },
  showOnDashboard: { label: 'Демонструвати при старті системи', name: 'ДемонстрироватьПриСтарте' },
  type: { label: 'Тип повідомлення', name: 'ТипСообщения' },
  message: { label: 'Повідомлення', name: 'Сообщение' },
};

/**
 * @const
 */
const tables = {
  users: {
    name: 'СписокПользователей',
    label: 'Список користувачів',
    columns: {
      user: { label: 'Користувач', name: 'Пользователь' },
      send: { label: 'M', name: 'Отправлять' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'messageForUsers',
  backendName: 'СообщенияПользователям',
  frontend: 'dp/messageForUsers',
  label: 'Надіслати повідомлення користувачам',
  columns,
  tables,
};

export default definition;
