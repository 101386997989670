import React, {
  cloneElement, forwardRef, isValidElement, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styled from 'styled-components';
import { childrenPropType } from '../../../constants/backend/propTypes';

const StyledButton = styled(Button)`
  &>.btn-content-letter{
      display: none;
  }
  .size-medium &>.btn-content-letter{
      display: inline;
  }
  .size-full &>.btn-content-text{
    display: inline;
  }
  .size-medium &>.btn-content-text{
    display: none;
  }
  .size-small &{
    border: none;
    box-shadow: none;
    border-bottom: var(--falcon-border-color) 1px solid;
    padding: .5rem 1rem;
  }
`;
export const CPButton = forwardRef(({
  content, title, onClick, disabled, icon, spin, pulse, bounce,
  tabIndex, active, variant, className,
}, ref) => {
  const isFontAwesomIcon = useMemo(
    () => typeof icon === 'object' && icon !== null && !!icon.prefix,
    [icon],
  );
  const icon2 = useMemo(
    () => {
      if (!icon || !isValidElement(icon)) return null;
      return cloneElement(icon);
    },
    [icon],
  );
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      size="sm"
      title={title || content}
      tabIndex={tabIndex}
      ref={ref}
      active={active}
      className={classNames(className, 'text-nowrap')}
    >
      {isFontAwesomIcon && (
        <FontAwesomeIcon icon={icon} spin={spin} pulse={pulse} bounce={bounce} />
      )}
      {icon2}
      {content
        && <span className="ms-1 btn-content-text">{content}</span>}
      {content && !isFontAwesomIcon && !icon2 && typeof content === 'string'
        && <span className="btn-content-letter">{content.substring(0, 1)}</span>}
    </StyledButton>
  );
});

CPButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.shape({ prefix: PropTypes.string, iconName: PropTypes.string }),
    childrenPropType,
  ]),
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  bounce: PropTypes.bool,
  active: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
    'link',
    'outline-primary',
    'outline-secondary',
    'outline-success',
    'outline-danger',
    'outline-warning',
    'outline-info',
    'outline-dark',
    'outline-light',
    'falcon-primary',
    'falcon-success',
    'falcon-info',
    'falcon-warning',
    'falcon-danger',
    'falcon-default',
  ]),
};

CPButton.defaultProps = {
  onClick: null,
  title: '',
  content: '',
  disabled: false,
  icon: null,
  spin: false,
  pulse: false,
  bounce: false,
  active: false,
  tabIndex: null,
  variant: 'falcon-primary',
  className: '',
};

export default CPButton;
