import React, { forwardRef, useContext, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import AssigmPrintModal from './listerPrintModal';

import ListerContext from '../../../newLister/context';

import YouTubeButtom from '../../../newLister/tableComponent/commandpanel/youtube/youtubeButton';
import YoutubeVideo from '../../../newLister/tableComponent/commandpanel/youtube/youtubeVideo';
import meta from '../../../../constants/meta/documents/liquidityVer2';

const videoId = meta.columns.refYoutube && meta.columns.refYoutube.value;

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <DocListerCommandPanel
        ref={ref}
      >
        <ButtonGroup>

          <AssigmPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />

        </ButtonGroup>
        {/* <ButtonGroup> */}
        {/*  <SendToLogicaButton */}
        {/*    modelType={modelType} */}
        {/*    modelName={modelName} */}
        {/*  /> */}
        {/* </ButtonGroup> */}
        <YouTubeButtom
          onClick={() => setShowVideo(!showVideo)}
          videoId={videoId}
        />

        <Button href={`${process.env.PUBLIC_URL}/help/doc/Ліквідність.docx`} download="Ліквідність.docx">
          Посібник для користувача
        </Button>

      </DocListerCommandPanel>

      <YoutubeVideo
        showVideo={showVideo}
        videoId={videoId}
      />
    </>

  );
});
export default CommandPanel;
