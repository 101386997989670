import components from './components/formComponents';

const forms = {
  BLP0050101: {
    title: 'Бюджетний запит. Форма-1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуально', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0050201: {
    title: 'Бюджетний запит. Форма-2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуально', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0050301: {
    title: 'Бюджетний запит. Форма-3',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуально', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0082001: {
    title: 'Прийняття рішення на 2021 рік',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB_NAME', label: 'Найменування місцевого бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0082002: {
    title: 'Прийняття рішення',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB_NAME', label: 'Найменування місцевого бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080002: {
    title: 'Рішення про місцевий бюджет(з дод.) на 2021 рік',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_REPORT_PROJAPPR', label: 'Проект/Кінцеве рішення', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080003: {
    title: 'Рішення про місцевий бюджет(з дод.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_REPORT_PROJAPPR', label: 'Проект/Кінцеве рішення', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0020101: {
    title: 'Річний розпис місцевого бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0010101: {
    title: 'Паспорт БП',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080001: {
    title: 'Рішення про місцевий бюджет',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080301: {
    title: 'Д-3 до рішення про місц. бюдж.(Розподіл видатків)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080101: {
    title: 'Д-1 до рішення про місц. бюдж.(Доходи)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080201: {
    title: 'Д-2 до рішення про місц. бюдж.(Фінансування)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080401: {
    title: 'Д-4 до рішення про місц. бюдж.(Кредитування)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0020103: {
    title: 'Помісячний розпис місцевого бюджету(табл.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_QUART', label: 'Тип розпису', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0020102: {
    title: 'Помісячний розпис місцевого бюджету(віз.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0020104: {
    title: 'Зміни розпису місцевого бюджету(табл.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_QUART', label: 'Тип розпису', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0050101: {
    title: 'Стан фінансування соц. виплат(табл.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true,
        },
        {
          code: '_EDRPOU', label: 'Код ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0050102: {
    title: 'Стан фінансування соц. виплат(віз.)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLR0010101: {
    title: 'Звіт про виконання паспорту БП (вер-1)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLR0010102: {
    title: 'Звіт про виконання паспорту БП (вер-2)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010402: {
    title: 'Додаток 1. Граничні показники видатків ГРК (зі змінами)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва установи', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010601: {
    title: 'Додаток 8. Показники міжбюджетних трансфертів',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_LCBUDCODER', label: 'Код бюджету отримувача', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування надавача', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORGVV', label: 'Найменування отримувача', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010501: {
    title: 'Додаток 2. Граничні показники видатків розпоряднику/одержувачу',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020001: {
    title: 'Типова форма прогнозу місцевого бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080601: {
    title: 'Додаток 6 до Типової форми рішення про місцевий бюджет. Розподіл коштів бюджету розвитку за об\'єктами у 20__ році. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080701: {
    title: 'Додаток 7 до Типової форми рішення про місцевий бюджет. Розподіл витрат місцевого бюджету на реалізацію міських цільових програм у 20__ році. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080801: {
    title: 'Додаток 8 до Типової форми рішення про місцевий бюджет. Перелік кредитів (позик), що залучаються місцевою радою до спеціального фонду у 20__ році. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0080501: {
    title: 'Додаток 5 до Типової форми рішення про місцевий бюджет. Міжбюджетні трансферти на 20_ рік. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010101: {
    title: 'Додаток 3. Бюджетні пропозиції. Форма БП-1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },

  // here can set more fields
  BLP0010201: {
    title: 'Додаток 4. Бюджетні пропозиції. Форма БП-2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК для ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORGVV', label: 'Найменування відповідального виконавця', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVKVV', label: 'КВК для ВВ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },

  // here can set more fields
  BLP0010301: {
    title: 'Додаток 5. Бюджетні пропозиції. Форма БП-3',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК для ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORGVV', label: 'Найменування відповідального виконавця', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVKVV', label: 'КВК для ВВ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020101: {
    title: 'Додаток 1. Основні показники місцевого бюджету. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020201: {
    title: 'Додаток 2. Доходи місцевого бюджету. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020301: {
    title: 'Додаток 3. Видатки та надання кредитів місцевого бюджету за функціональною ознакою. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020401: {
    title: 'Додаток 4. Рекомендована форма оформлення показників видатків та надання кредитів місцевого бюджету головних розпорядників коштів. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020501: {
    title: 'Додаток 5. Бюджетні програми місцевого бюджету, які забезпечують виконання інвестиційних проектів. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KOATUU_NAME', label: 'KOATUU', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010401: {
    title: 'Додаток 1. Граничні показники видатків ГРК',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0030101: {
    title: 'Лімітні довідки про бюджетні асигнування та кредитування',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_TPKVKMB', label: 'Найменування програми згідно з ТПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORGVV', label: 'Найменування ВВ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0020210: {
    title: 'Зведений план асигнувань',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0100101: {
    title: 'Кошторис',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_KTVKVK', label: 'Код типової відомчої класифікації видатків для ГРК', canFilter: true },
        {
          code: '_KTPKVKMB', label: 'КБП', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0090103: {
    title: 'План асигнувань',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KPKVKMB', label: 'КПКВ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0040101: {
    title: 'Інформація щодо стану розподілу вільних залишків',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },

      ],
    },
  },
  BLE0040203: {
    title: 'Інформація щодо стану розподілу вільних залишків Додаток 3 (оновлена форма)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },

      ],
    },
  },
  BLE0040204: {
    title: 'Інформація щодо стану розподілу вільних залишків Додаток 4 (оновлена форма)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },

      ],
    },
  },
  BLE0040102: {
    title: 'Інформація щодо стану розподілу вільних залишків форма-2023',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0040103: {
    title: 'Інформація щодо стану розподілу вільних залишків Додаток 3',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0040104: {
    title: 'Інформація щодо стану розподілу вільних залишків Додаток 4',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLCUSTDOC: {
    title: 'Довільний документ учасника бюджетного процесу',
    component: components.default,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_LCBUDCODES', label: 'Код бюджету (відправник)', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_BCROLE_S', label: 'Бізнес-код ролі (відправник)', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_TBNG', label: 'Група користувачів (відправник)', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_LCBUDCODER', label: 'Код бюджету (адресат)', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_BCROLE_R', label: 'Бізнес-код ролі (адресат)', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_TBNG_R', label: 'Група користувачів (адресат)', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010102: {
    title: 'Форма ПП-1. Версія 2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_NAME_ORG', label: 'Найменування ГРК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010202: {
    title: 'Форма ПП-2. Версія 2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_EDRPOU', label: 'Код ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTPKVKMB', label: 'КБП', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVKVV', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010302: {
    title: 'Форма ПП-3. Версія 2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_EDRPOU', label: 'Код ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU_VV', label: 'Код ЄДРПОУ виконавця', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVK', label: 'КВК', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KTVKVKVV', label: 'КВК виконавця', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORGVV', label: 'Назва виконавця', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0010602: {
    title: 'Показники міжбюджетних трансфертів. Версія 2',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_EDRPOU', label: 'Код ЄДРПОУ надавача', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_NAME_ORG', label: 'Назва надавача', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU_R', label: 'Код ЄДРПОУ отримувача', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0020002: {
    title: 'Типова форма прогнозу місцевого бюджету. Версія 4',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0022001: {
    title: 'Повідомлення про розгляд прогнозів радою. Версія 1',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_KLB_NAME', label: 'Найменування місцевого бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000201: {
    title: 'Показники за чисельністю, фондом оплати праці(ГРК)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000101: {
    title: 'Показники за чисельністю, фондом оплати праці(МФО)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000103: {
    title: 'Мережа -зведення фактичних показників за мережею (МФО)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000203: {
    title: 'Мережа -зведення фактичних показників за мережею (ГРК)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000104: {
    title: 'Мережа -зведення планових показників за мережею (МФО)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BMRZ000204: {
    title: 'Мережа -зведення планових показників за мережею (ГРК)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: 'Month', label: 'Місяць', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0120101: {
    title: 'Ставки та пільги',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0060101: {
    title: 'Витяги з розпису місцевих бюджетів',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_NAME_ORG', label: 'Найменування головного розпорядника коштів місцевого бюджету', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0070101: {
    title: 'Інформація щодо ліквідності',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: 'Month', label: 'Місяць', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0070201: {
    title: 'Інформація щодо ліквідності (ГРК)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: 'Month', label: 'Місяць', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLE0070301: {
    title: 'Інформація щодо ліквідності (РК/ОК)',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: 'Month', label: 'Місяць', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0130101: {
    title: 'План надання кредитів із загального фонду бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_KTVKVK', label: 'Код типової відомчої класифікації видатків для ГРК', canFilter: true },
        { code: '_KTPKVKMB', label: 'Код Типової Програмної класифікації видатків та кредитування місцевих бюджетів', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0140101: {
    title: 'Зведений план надання кредитів із загального фонду бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_KTVKVK', label: 'Код типової відомчої класифікації видатків для ГРК', canFilter: true },
        { code: '_KTPKVKMB', label: 'Код Типової Програмної класифікації видатків та кредитування місцевих бюджетів', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0160101: {
    title: 'Зведений план спеціального фонду бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_KTVKVK', label: 'Код типової відомчої класифікації видатків для ГРК', canFilter: true },
        { code: '_KTPKVKMB', label: 'Код Типової Програмної класифікації видатків та кредитування місцевих бюджетів', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0150101: {
    title: 'План спеціального фонду бюджету',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        {
          code: '_KLB', label: 'Код бюджету', canFilter: true, canOrder: true, canSearch: true,
        },
        {
          code: '_EDRPOU', label: 'ЄДРПОУ', canFilter: true, canOrder: true, canSearch: true,
        },
        { code: '_KTVKVK', label: 'Код типової відомчої класифікації видатків для ГРК', canFilter: true },
        { code: '_KTPKVKMB', label: 'Код Типової Програмної класифікації видатків та кредитування місцевих бюджетів', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0180101: {
    title: 'Місцеві запозичення та гарантії',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
        { code: '_SIGNEDBYFINDEP', label: 'Підписано обласним фіндепом', canFilter: true },
      ],
    },
  },
  BLP0180102: {
    title: 'Місцеві запозичення та гарантії. Версія 2.',
    component: components.filteredTable,
    defaultProps: {
      columns: [
        { code: 'success', label: 'Статус верифікації', canFilter: true },
        {
          code: 'addDate', label: 'Дата додання', canOrder: true,
        },
        {
          code: 'lastEditDate', label: 'Востаннє змінено', canOrder: true,
        },
        { code: '_ACTUAL', label: 'Актуальні', canFilter: true },
        { code: '_PROJAPPROVED', label: 'Затверджено', canFilter: true },
        { code: '_ISPUBLISH', label: 'Опубліковано', canFilter: true },
        { code: '_VERSION', label: 'Версія', canFilter: true },
      ],
    },
  },
};

export default forms;
