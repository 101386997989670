import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Найменування коротко', name: 'Наименование', visible: true },
  BudgetName: { label: 'До бюджету', name: 'ИмяБюджета', visible: false },
  owner: { label: 'Власник', name: 'Владелец', visible: false },
  dateProject: { label: 'Дата рішення', name: 'ДатаПроекта', visible: false },
  numberProject: { label: 'Номер рішення', name: 'НомерПроекта', visible: false },
  stamp: { label: 'До рішення(додається в друковану форму додатку до рішення сессії)', name: 'ШтампКРешению', visible: false },
  stamp2014: { label: 'До рішення (дод.3, 7 з 2015 р.)', name: 'ШтампКРешению_3_2014', visible: false },
  basis: { label: 'Підстава', name: 'ОснованиеЛимитка', visible: false },
  dateStatement: { label: 'Власник', name: 'ЛимиткаДатаВыписки', visible: false },
  dateApproved: { label: 'Граничний термін', name: 'ЛимиткаДатаУтверждения', visible: false },
  year: { label: 'Рік довідки', name: 'ЛимиткаГод', visible: false },
};

export const variantBudgetDraft = {
  label: 'Варіанти проекту',
  name: 'ВариантыПроектаБюджета',
  backendName: 'ВариантыПроектаБюджета',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/variantBudgetDraft',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default variantBudgetDraft;
