import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { DivTogglerHeader } from './styles';
import ColumnHeader from './ColumnHeader';
import setResizable from './ResizableDiv';

const ResizableColumnHeader = setResizable(ColumnHeader);

function TableHeader({
  columns, onColumnClick, orderBy, onResizeColumn, onResetColumnSize, columnsDef,
}) {
  return (
    <>
      <DivTogglerHeader key="hrh">
        {'\u2800'}
      </DivTogglerHeader>
      {columns.map((col, key) => (
        <ResizableColumnHeader
        // eslint-disable-next-line
        key={key}
          label={col.get('label')}
          onResizeColumn={(e, dx) => onResizeColumn(e, key, dx)}
          onResetColumnSize={(e) => onResetColumnSize(e, key)}
          sorted={orderBy.column === columnsDef[key].name ? orderBy.direction : 'NONE'}
          onClick={(e) => {
            onColumnClick(e, key);
          }}
        />
      )).toList()}
    </>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.instanceOf(Map).isRequired,
  orderBy: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  columnsDef: PropTypes.shape().isRequired,
  onColumnClick: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  onResetColumnSize: PropTypes.func.isRequired,
};

export default TableHeader;
