import { cityDbOptions } from './cities';
import { regionDbOptions } from './regions';
import { otgDbOptions } from './otgs';
import { testDbOptions } from './tests';

export const allDbOptions = [
  ...[...cityDbOptions,
    ...regionDbOptions,
    ...otgDbOptions].sort((a, b) => a.text.localeCompare(b.text)),
  ...testDbOptions,
];
