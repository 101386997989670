import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: meta.cat.bringdocument.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const documentTypeProps = editorHooks.useSelectorInputProps('document_type', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.bringdocument.backendURL}
        />
      )}
    >
      <LabelTab defaultActiveKey="bringdocument" id="uncontrolled-tab-example">
        <Tab eventKey="bringdocument" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.SelectorInput {...documentTypeProps} />
                </Col>
                <Col />
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>

    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
