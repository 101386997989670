import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';

const meta = md.tables.designers;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'Designer'}
          highlighted={highlights.includes('Designer')}
          onToggleHighlght={() => onToggleHighlght('Designer')}
        >
          {meta.columns.Designer.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'Note'}
          highlighted={highlights.includes('Note')}
          onToggleHighlght={() => onToggleHighlght('Note')}
        >
          {meta.columns.Note.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
