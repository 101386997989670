import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/draftAnnualSources';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const Prepend = styled.div`
  max-width: 75%
  @media ( max-width: 830px){
    max-width: 50%
  }
  @media (min-width: 1200px) and (max-width: 1400px){
    max-width: 50%
  }
`;

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
  FI, onSR,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col style={{ width: '300px' }}>
            <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFB.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={onKDBChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
                prepend={(
                  <Prepend>
                    <InputGroup.Text className="w-100" style={{ overflowX: 'overlay', boxSizing: 'context-box' }}>
                      {row[tableMD.columns.KFBCode.name]}
                    </InputGroup.Text>
                  </Prepend>
                )}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf" highlighted={highlights.includes('zf')}>
              <NumberInput
                value={row[tableMD.columns.zf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sf" highlighted={highlights.includes('sf')}>
              <NumberInput
                value={row[tableMD.columns.sf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="br" highlighted={highlights.includes('br')}>
              <NumberInput
                value={row[tableMD.columns.br.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.br.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.zf.name]: PropTypes.number,
    [tableMD.columns.sf.name]: PropTypes.number,
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.br.name]: PropTypes.number,
    [tableMD.columns.elementKFB.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType.isRequired,

};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
