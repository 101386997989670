import { Map, List } from 'immutable';
import { editorActions } from '../constants/actions/editor';

const makeReducer = (key, _form, modelType) => (state = new Map({}), { type, payload = {} }) => {
  // const thisReducer = `${modelType}/${key}/${_form}`;

  if (
    (!type.endsWith('@editor'))
        || (payload.model !== key)
        || (payload.type !== modelType)
  ) {
    // Не наш узел
    return state;
  }

  const thisId = payload.id;

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */
  const updaterCreateTableRow = (table) => ((table.size > 0) ? table
    .map((v) => v.set('IS_ACTIVE', false))
    .set(table.size, table.get(0)
      .map((v) => {
        switch (typeof v) {
          case 'number':
            return 0;
          case 'string':
            return '';
          case 'object':
            return (v || new Map()).set('repr', '')
              .set('id', '');

          default:
            return v;
        }
      }).set('IS_ACTIVE', true)) : table.push(new Map().set('IS_ACTIVE', true)));

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */
  const updaterDeleteTableRow = (table) => table.filter((row, RowKey) => !payload.items.has(RowKey));

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */

  const updaterCopyTableRow = (table) => payload.items.reduce(
    /**
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @return {Immutable.List}
       */
    (r, v) => r.push(v),
    table,
  );

  const updaterSwapDownTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next row
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k + 1, v),
    updaterDeleteTableRow(table),
  );

  const updaterSwapUpTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next rocw
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k - 1, v),
    updaterDeleteTableRow(table),
  );

  switch (type) {
  // case `${thisReducer}/${thisId}/${editorActions.INIT}`:
    case editorActions.INIT:
      return state
        .setIn([thisId, 'itemId'], payload.id)
        .setIn([thisId, 'modelType'], payload.type)
        .setIn([thisId, 'modelName'], payload.model)
        .setIn([thisId, 'initParams'], payload.initParams)
        .setIn([thisId, 'hierarchyType'], 'item')
        .setIn([thisId, 'headerForm'], new Map())
        .setIn([thisId, 'tables'], new Map())
        .setIn([thisId, 'canBeSaved'], false)
        .setIn([thisId, 'isProcessing'], true)
        .setIn([thisId, 'isLoading'], true)
        .setIn([thisId, 'isLocked'], false)
        .setIn([thisId, 'isChanged'], false)
        .setIn([thisId, 'isValidated'], false)
        .setIn([thisId, 'hasError'], false)
        .setIn([thisId, 'errorMsg'], null)
        .setIn([thisId, 'isOpenAttachedFiles'], false)
        .setIn([thisId, 'countAttachedFiles'], 0)
        .setIn([thisId, 'disabledFields'], new Map())
        .setIn([thisId, 'isLoadingAttachedFiles'], true)
        .setIn([thisId, 'warnings'], new Map())
        .setIn([thisId, 'AttachedFiles'], []);
    case editorActions.LOAD_START:
      return state.setIn([thisId, 'isLoading'], true)
        .setIn([thisId, 'isProcessing'], false);
    case editorActions.PROCESS_ATTACHED_FILES:
      return state
        .setIn([thisId, 'isLoadingAttachedFiles'], payload.isLoadingAttachedFiles)
        .setIn([thisId, 'AttachedFiles'], payload.AttachedFiles);
    case editorActions.PROCESS_ADD_FILES:
      return state
        .setIn([thisId, 'isLoadingAttachedFiles'], payload.isLoadingAttachedFiles);
    case editorActions.DELETE_ATTACHED_FILES:
      return state
        .setIn([thisId, 'isLoadingAttachedFiles'], payload.isLoadingAttachedFiles);
    case editorActions.TURNING_ATTACHED_FILES:
      return state
        .setIn([thisId, 'isOpenAttachedFiles'], !state.getIn([thisId, 'isOpenAttachedFiles'], false));
    case editorActions.LOAD_DONE:
      return payload.item.tables
        .keySeq()
        .reduce(
          (r, table) => r.setIn([thisId, `tables/${table}`], payload.item.tables.get(table)),
          state
            .setIn([thisId, 'isLoading'], false)
            .setIn([thisId, 'headerForm'], payload.item.headerForm || null),
        );
    case editorActions.LOAD_ERR:
      return state
        .setIn([thisId, 'hasError'], true)
        .setIn([thisId, 'isLoading'], false)
        .setIn([thisId, 'errorMsg'], payload.e);
    case editorActions.CHANGE_PROPERTY:
      return state
        .setIn([thisId, 'isChanged'], true)
        .setIn([thisId, 'hasError'], false)
        .setIn([thisId, 'errorMsg'], null)
        .setIn(payload.path, payload.value);
    case editorActions.SR_CHANGE_PROPERTY:
      return state.setIn([thisId, payload.path], payload.value);
    case editorActions.TABLE_ROW_SET_ACTIVE:
      return state.setIn(payload.path, payload.value);
    case editorActions.TABLE_ROW_ADD:
      return state
        .setIn([thisId, 'isChanged'], true)
        .updateIn([thisId, `tables/${payload.tableName}`], new List(), updaterCreateTableRow);
    case editorActions.TABLE_ROW_COPY:
      return state
        .setIn([thisId, 'isChanged'], true)
        .setIn([thisId, 'hasError'], false)
        .updateIn([thisId, `tables/${payload.name}`], new List(), updaterCopyTableRow);
    case editorActions.TABLE_ROW_REMOVE:
      return state
        .setIn([thisId, 'isChanged'], true)
        .updateIn([thisId, `tables/${payload.name}`], new List(), updaterDeleteTableRow);
    case editorActions.TABLE_ROW_SWAP:
      return state
        .setIn([thisId, 'isChanged'], true)
        .updateIn(
          [thisId, `tables/${payload.name}`],
          new List(),
          payload.reverseDirection ? updaterSwapUpTableRow : updaterSwapDownTableRow,
        );
    case editorActions.TABLE_FILL:
      return state
        .setIn([thisId, 'isChanged'], true)
        .setIn(
          [thisId,
            `tables/${payload.name}`],
          payload.items,
        );
    case editorActions.LOCK_START:
      return state.setIn([thisId, 'isProcessing'], true);
    case editorActions.LOCK_DONE:
      return state.setIn([thisId, 'isLocked'], true)
        .setIn([thisId, 'isProcessing'], false);
    case editorActions.LOCK_ERR:
      return state.setIn([thisId, 'hasError'], payload)
        .setIn([thisId, 'isProcessing'], false);
    case editorActions.UNLOCK_START:
      return state.setIn([thisId, 'pendingLock'], true)
        .setIn([thisId, 'pendingLock'], false);
    case editorActions.UNLOCK_DONE:
      return state.setIn([thisId, 'isLocked'], false)
        .setIn([thisId, 'pendingLock'], false);
    case editorActions.UNLOCK_ERR:
      return editorActions.setIn([thisId, 'hasError'], payload)
        .setIn([thisId, 'isProcessing'], false);
    case editorActions.SR_START:
      return state.setIn([thisId, 'isProcessing'], true);
    case editorActions.SR_DONE:
      return payload.item.tables
        .keySeq()
        .reduce(
          (r, table) => r.setIn([thisId, `tables/${table}`], payload.item.tables.get(table)),
          state
            .setIn([thisId, 'isProcessing'], false)
            .setIn([thisId, 'headerForm'], payload.item.headerForm || null),
        );
    case editorActions.SR_ERR:
      return state.setIn([thisId, 'hasError'], true)
        .setIn([thisId, 'errorMsg'], payload);
    case editorActions.SAVE_START:
      return state.setIn([thisId, 'isProcessing'], true);
    case editorActions.SAVE_DONE:
      return state
        .setIn([thisId, 'hasError'], false)
        .setIn([thisId, 'isProcessing'], false)
        .setIn([thisId, 'errorMsg'], null)
        .setIn([thisId, 'isChanged'], false);
    case editorActions.SAVE_ERROR:
      return state
        .setIn([thisId, 'hasError'], true)
        .setIn([thisId, 'errorMsg'], payload.e)
        .setIn([thisId, 'isProcessing'], false);
    case editorActions.SET_DP_EDITOR:
      return state.setIn([thisId, 'dpEditor'], payload.name);
    case editorActions.REGISTER_WARNINGS:
      if (payload.items.size > 0) return state.setIn([thisId, 'warnings', payload.warningsKey], payload.items);
      return state.deleteIn([thisId, 'warnings', payload.warningsKey]);
    case editorActions.CLEAR_WARNINGS:
      return state.setIn([thisId, 'wags'], new Map());
    default:
      return state;
  }
};

const createEditorReducer = (modelType, o, _form = 'editor') => Object.keys(o)
  .reduce((r, key) => ({
    ...r,
    [`${modelType}/${key}/${_form}`]: makeReducer(key, _form, modelType),
  }), {});

export default createEditorReducer;
