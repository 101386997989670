import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HeaderRow from './headerRow';

function Header({
  lines, rowLevels, onRowLevelToggle,
}) {
  const [coord, setCoord] = useState({ height: 0, top: 0 });
  const mRef = useCallback((node) => {
    if (node !== null) {
      setCoord({
        height: node.getBoundingClientRect().height,
        top: node.getBoundingClientRect().top,
      });
    }
  }, []);
  return (
    <thead ref={mRef}>

      {lines.map((cells) => (
      // eslint-disable-next-line react/no-array-index-key
        <HeaderRow
          rows={lines.length}
          firstRow={cells[0].row_num === 1}
          cells={cells}
          key={cells[0].row_num}
          sticked={coord.height / (window.innerHeight - coord.top) <= 0.2}
          rowLevels={cells[0].row_num === 1 ? rowLevels : null}
          onRowLevelToggle={onRowLevelToggle}
        />
      ))}
    </thead>
  );
}

Header.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    // key: PropTypes.string.isRequired,
    col_num: PropTypes.number,
    row_num: PropTypes.number,
    colspan: PropTypes.number,
    rowSpan: PropTypes.number,
    repr: PropTypes.string.isRequired,
  }))).isRequired,
  rowLevels: PropTypes.arrayOf(PropTypes.shape({
    level: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  onRowLevelToggle: PropTypes.func.isRequired,
};

export default memo(Header);
