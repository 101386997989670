const frontendURL = '/srv/ImportLiquidity/';
const backendURL = '/api/logica/get-liquidity/';
const name = 'Інформація щодо ліквідності';
const tableLabels = {
  version: 'Версія документу',
  klb: 'Код місцевого бюджету',
  klb_name: 'Найменування місцевого бюджету',
  edrpou: 'Код ЄДРПОУ',
  year: 'Затверджений рік',
  ktvkvk: 'Код типової відомчої класифікації видатків',
  month: 'Місяць',
  prog_approved: 'Ознака проект затверджено',
  name_org: 'Найменування розпорядника',
  id: 'ID',
};

const instance = {
  frontendURL,
  backendURL,
  name,
  tableLabels,
};

export default instance;
