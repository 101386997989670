import { baseReportColumns } from '../_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  CSU: { label: 'Розпорядник', name: 'фРаспорядитель' },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  isDirect: { label: 'Напрями використання коштів', name: 'флНаправления' },
  isIndicators: { label: 'Показники', name: 'флПоказатели' },
  isRegionPrograms: { label: 'Державні/регіональні цільові програми', name: 'флРегГосПрораммы' },
  isSourcesInvestmentProjects: { label: 'Джерела фінансування інвестиційних програм', name: 'флИсточникиПрограмм' },
  isAllKPKV: { label: 'Заповнити в цілому за КПКВ', name: 'флЗаполнятьПоКПКВ' },
  year: { label: 'Рік', name: 'тГод' },
  KBP: { label: 'КБП', name: 'КБП' },
  KBP7: { label: 'КПКВ', name: 'КПКВ' },
  period: { label: 'Період', name: 'тПериод' },
  DataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных' },
  isTasks: { label: 'Завдання', name: 'флЗадания' },
  isTarget: { label: 'Цілі державної політики', name: 'флЦелиГосПолит' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  loadAll: { label: 'Завантажувати отримані документи', name: 'ОтборУтвержденных', visible: false },
};

/**
 * @const
 */
const tables = {
  data: {
    name: 'Данные',
    label: 'Дані',
    columns: {
      Check: { label: 'М', name: 'М' },
      Document: { label: 'Документ', name: 'Документ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      KPKVCode: { label: 'КодКПКВ', name: 'КодКПКВ' },
      Autor: { label: 'Автор', name: 'Автор' },
      Notes: { label: 'Примітка', name: 'Примечание' },
      Sum: { label: 'Сума', name: 'Сумма' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'docSelection',
  backendName: 'ПодборДок',
  label: 'Відбір документів',
  columns,
  tables,
};

export default definition;
