import React, { forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';
import ListerOmitElementKDBModal from './listerOmitElementModal';
import CreateElementsModal from './createElemtsModal';

const ElementKDBCommandPanel = forwardRef((_, ref) => (
  <CatListerCommandPanel ref={ref}>

    <ButtonGroup>
      <ListerOmitElementKDBModal />
      <CreateElementsModal />
    </ButtonGroup>

  </CatListerCommandPanel>
));

export default ElementKDBCommandPanel;
