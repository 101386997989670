import React from 'react';
import styled from 'styled-components';
import { BigDivLoadStyled } from '../../containers/field/selectorField/item/styles';

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  background: #e9f0f85e;
  border-radius: 4px;
  border: 1px solid #cbcbe4;
  align-items: baseline
`;
const Text = styled.span`
  margin-left: 10px;
  margin-top: 25px;
  color: #4281C9;
  font-weight: 700;
  font-size: 15px;
`;
function Loading({ children }) {
  return (
    <Container>
      <BigDivLoadStyled />
      <Text>Зачекайте, будь ласка...</Text>
      {children}
    </Container>
  );
}

export default Loading;
