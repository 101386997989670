import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge } from 'react-bootstrap';
import {
  faFilePdf, faFileExcel, faFileWord, faFileImage, faFile,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledCell = styled.td`
  white-space: normal !important;
`;

function FileBadge({ filename }) {
  const icon = useMemo(
    () => {
      if (!filename) return faFile;
      if (filename.endsWith('.pdf')) return faFilePdf;
      if (filename.endsWith('.xls') || filename.endsWith('.xlsx')) return faFileExcel;
      if (filename.endsWith('.doc') || filename.endsWith('.docx')) return faFileWord;
      if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')) return faFileImage;
      return faFile;
    },
    [filename],
  );

  return (
    <Badge variant="info" title={filename}>
      <FontAwesomeIcon icon={icon} className="me-1" />
      {filename}
    </Badge>
  );
}

FileBadge.propTypes = {
  filename: PropTypes.string,
};

FileBadge.defaultProps = {
  filename: '',
};

function FilesCell({ files }) {
  return (
    <StyledCell>
      {files.filter((f) => f).map((f) => <FileBadge key={f} filename={f} />)}
    </StyledCell>
  );
}

FilesCell.propTypes = {
  files: PropTypes.arrayOf(PropTypes.string),
};

FilesCell.defaultProps = {
  files: [],
};

export default FilesCell;
