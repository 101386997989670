const frontendURL = '/doc/fundingsourceproject/';
const backendURL = '/api/planproject/fundingsourceprojectheader/';
// const printURL = '';
const name = 'Проект джерел фінансування (Дод. 2 до РС)';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
