import React, {
  useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import api from '../../../../api/req';
import { useMD } from '../md';
import useMessages from './messages';
import { CiatAppContext } from '../../../../providers';
import useSettings from './settings';
import { ExecutionError } from './errors';
// import {
//   comparisonTypes, emptyUid, hierarchyTypes, saveModes,
// } from '../../../../constants/meta/common';

/**
 * Возвращает строку для вівода ошибок проведения / распроведения документа
 * @param err {{
 *     executing_errors: {}[],
 * }[]}
 * @returns string[]
 */
function buildErrors(err) {
  return (err.executing_errors || [
    'Помилка при збереженні даних. Заповнено не всі дані або завповнено не вірно. Перейдіть в документ та спробуйте перезберегти його',
  ]).reduce((R, e) => {
    switch (typeof e) {
      case 'string':
        return [...R, e];
      case 'object':
        return [...R, ...Object.values(e).reduce((R2, e2) => [...R2, ...e2], [])];
      default:
        return [...R, String(e)];
    }
  }, []);
}

function showError(error) {
  const toastId = toast.error('', {
    theme: 'colored',
    autoClose: false,
  });
  toast.update(
    toastId,
    {
      render: (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: error }} />
      ),
    },
  );
}

/**
 *
 * @param backendURL {string}
 * @param viewType {string}
 * @param filter {{}}
 * @param order {{
 *     column: string,
 *     isAscending: boolean,
 * }}
 * @param searchString {string}
 * @param noHierarchy {boolean}
 * @returns {{
 * meta: *,
 * visibleColumns: {
 *  name: string,
 *  label: string,
 *  key: string,
 *  type: string,
 * },
 * items: [{ id: string, deleted: boolean, executed: boolean }],
 * options: {
 *    name: string,
 *    description: string,
 *    actions: {
 *        POST: {},
 *        GET: {},
 *    },
 *    hidden_fields: string[],
 *    filtering_fields: {name: string}[],
 *    ordering_fields: {}
 * },
 * loading: boolean,
 * err: string,
 * pageInfo: {
 *  current: {
 *   currentPage: number,
 *   allLoaded: boolean,
 *   parents: string[],
 * }},
 * settings: {},
 * onReload: function,
 * onLoadOptions: function,
 * optionsLoaded: boolean,
 * onNextPage: function,
 * onExecute: function,
 * onUnExecute: function,
 * permissions: {
 *   canNew: boolean,
 *   canEdit: boolean,
 *   canCopy: boolean,
 *   canDelete: boolean,
 *   canHideDeleted: boolean,
 *   canExecute: boolean,
 *   canUnexecute: boolean,
 *   canNextPage: boolean,
 *   canHierarchy: boolean,
 *   foldersUsed: boolean,
 *   canNewFolder: boolean,
 *   canNextPage: boolean,
 *   canFilterByPeriod: boolean,
 * },
 * onDeleteItems: function,
 * onLoadSettings: function,
 * loadPath: function,
 * messages: { title: string, text: string, variant: string }[],
 * clearMessages: function,
 * deleteMessage: function,
 * filteringFields: {
 *   type: string,
 *   label: string,
 *   name: string,
 * }[]
 * }}
 */
// type: "string", required: true, read_only: false, label: "Назва", name: "name"

const useListerBackend = ({
  backendURL, viewType,
  filter, order, searchString, noHierarchy,
}) => {
  const meta = useMD(backendURL);

  const { auth } = useContext(CiatAppContext);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState(null);
  const optionsLoaded = !!options;
  const printForms = useMemo(
    () => (options ? options.print_forms : []),
    [options],
  );
  const columns = useMemo(
    () => {
      if (!options) return [];
      const fields = options.actions.GET || {};
      const oFields = Object.keys(options.ordering_fields || {});
      return Object.keys(fields).reduce(
        (R, f) => (
          {
            ...R,
            [f]: {
              ...fields[f],
              visible: !(options.hidden_fields || []).includes(f),
              orderable: oFields.includes(f),
            },
          }),
        {},
      );
    },
    [options],
  );

  const filteringFields = useMemo(
    () => (options ? options.filtering_fields
      .filter((f) => (f.name in options.actions.GET) || !!f.label)
      .map((f) => ({
        ...(options.actions.GET[f.name] || {}),
        ...f,
      })) : []),
    [options],
  );

  const useHierarchyPagination = useMemo(
    () => !!filteringFields.filter((f) => f.name === 'parent').length,
    [filteringFields],
  );

  const visibleColumns = useMemo(
    () => Object.keys(columns)
      .filter((c) => columns[c].visible)
      .reduce((R, k) => [...R, {
        key: k,
        ...columns[k],
      }], []),
    [columns],
  );

  const isHierarhical = !!columns.parent; // Это иерархичекий справочник

  const {
    messages, clearMessages, addMessage, deleteMessage,
  } = useMessages();
    // Текущая страница загрузки данных
  const pageInfo = useRef({
    currentPage: 1,
    allLoaded: false,
    parents: [null],
  });

  const {
    onLoadSettings,
    settings,
    onSetSettings,

  } = useSettings(backendURL, viewType, meta, setLoading, setErr);

  const loadRequestController = useRef(null);

  const loadPath = useCallback(
    async (id) => {
      const response = await api.get(`${backendURL}${id}/get_ancestors/`, auth);
      if (response.ok) return response.json();
      throw new Error(`${response.status} ${response.statusText}`);
    },
    [auth, backendURL],
  );

  const loadOptions = useCallback(
    async (url) => {
      const r = await api.options$(url, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );

  const orderingFields = useMemo(
    () => (options ? options.ordering_fields : {}),
    [options],
  );

  const asyncLoader = useCallback(
    /**
       *
       * @param p {{
       *     page: number,
       *     filter: {},
       *     clear: boolean,
       *     order: {
       *         column: string,
       *         isAsceding: boolean,
       *     },
       *     search: string,
       * }}
       */
    async (p) => {
      loadRequestController.current = new AbortController();
      const response = await api.get(backendURL, auth, {
        ...p.filter,
        ...(p.page === 1 ? {} : { page: p.page }),
        ...(p.order && p.order.column ? { ordering: `${p.order.isAscending ? '' : '-'}${orderingFields[p.order.column]}` } : {}),
        ...(p.search ? { search: p.search } : {}),
      }, false, loadRequestController.current);
      if (response.ok) {
        return response.json();
      }
      throw new Error(`Помилка при завантаженні списку ${meta.label} (${response.status} ${response.statusText})`);
    },
    [auth, backendURL, meta.label, orderingFields],
  );

  const resultStore = useCallback(
    (data, clear = false, page = 1) => {
      setItems((oldData) => {
        const usePagination = 'results' in data;
        if (usePagination && useHierarchyPagination) {
          throw new Error('Невозможно использовать иерархическую загрузку, поскольку предусмотрена страничная загрузка. Смотри бекенд.');
        }
        pageInfo.current.currentPage = page;
        pageInfo.current.allLoaded = !usePagination || !data.next;

        if (clear) return usePagination ? data.results : data;

        const existedKeys = new Set(oldData.map((r) => r.id));
        return [
          ...oldData,
          ...(usePagination ? data.results : data).filter((r) => !existedKeys.has(r.id)),
        ];
      });
    },
    [useHierarchyPagination],
  );

  const load = useCallback(
    /**
       *
       * @param p {{
       *     page: number,
       *     filter: {},
       *     clear: boolean,
       *     order: {
       *         column: string,
       *         isAsceding: boolean,
       *     },
       *     search: string,
       * }}
       */
    (p) => {
      if (loadRequestController.current) loadRequestController.current.abort();
      setLoading(true);
      setErr(null);
      asyncLoader(p)
        .then((data) => {
          resultStore(data, p.clear, p.page);
          setLoading(false);
        })
        .catch((e) => {
          if (!(e instanceof DOMException)) {
            setErr(e.message);
            setLoading(false);
          }
        });
    },
    [asyncLoader, resultStore],
  );

  const onReload = useCallback(
    () => {
      if (useHierarchyPagination && !searchString && !noHierarchy) {
        const parents = pageInfo.current.parents.filter((p) => !!p);
        const params = {
          filter,
          page: 1,
          clear: true,
          order,
          search: searchString,
        };
        const reqs = [
          () => asyncLoader({ ...params, filter: { ...params.filter, parent_null: true } }),
        ];
        if (parents.length > 0) {
          reqs.push(
            () => asyncLoader({ ...params, filter: { ...params.filter, parent__in: parents } }),
          );
        }
        setLoading(true);
        setErr(null);
        Promise.allSettled(reqs.map((r) => r()))
          .then((results) => {
            const errs = results.filter((r) => r.status === 'rejected');
            if (errs.length) throw new Error(errs.map((e) => e.reason).join(', '));
            const data = results.reduce((R, r) => [...R, ...r.value], []);
            resultStore(data, true, 1);
            setLoading(false);
          })
          .catch((e) => {
            if (!(e instanceof DOMException)) {
              setErr(e.message);
              setLoading(false);
            }
          });
      } else {
        load({
          filter,
          page: 1,
          clear: true,
          order,
          search: searchString,
        });
      }
    },
    [
      useHierarchyPagination, searchString, noHierarchy, filter,
      order, asyncLoader, resultStore, load],
  );
  const onLoadOptions = useCallback(
    () => loadOptions(backendURL)
      .then((d) => setOptions(d))
      .catch((e) => setErr(e.message)),
    [backendURL, loadOptions],
  );

  const onNextPage = useCallback(
    () => {
      if (!pageInfo.current.allLoaded) {
        load({
          filter,
          order,
          page: pageInfo.current.currentPage + 1,
          clear: false,
          search: searchString,
        });
      }
    },
    [filter, load, order, searchString],
  );

  const onLoadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents.push(parentId);
      load({
        filter: {
          ...filter,
          parent: parentId,
        },
        order,
        page: pageInfo.current.currentPage,
        clear: false,
        search: searchString,
      });
    },
    [filter, load, order, searchString],
  );

  const onUnloadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents = pageInfo.current.parents.filter((p) => p !== parentId);
      setItems((oi) => oi.filter(
        (item) => item.parent === null || pageInfo.current.parents.includes(typeof item.parent === 'object' ? item.parent.id : item.parent),
      ));
    },
    [],
  );

  const onDeleteItems = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const del = async (id, repr) => {
        const response = await api.patch$(`${backendURL}${id}/delete/`, auth);
        if (response.ok) {
          return null;
        }
        if (response.status === 400) {
          const d = await response.json();
          if ('executing_errors' in d) {
            const errData = buildErrors(d);
            throw new ExecutionError(errData, `Помилка при видаленні ${repr}`);
          }
          throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr}: ${d.join(', ')}`);
        }
        if (response.status === 403) {
          const d = await response.json();
          throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr}: ${d.detail}`);
        }
        throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => del(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            if (options && options.name) {
              toast.info(`${options.name}: успішно помічено (знято помітку) на видалення у ${results.length} елементів.`, {
                theme: 'colored',
              });
            }
            onReload();
          }
        });
    },
    [auth, backendURL, onReload, options],
  );

  const onExecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const exec = async (id, repr) => {
        const response = await api.patch(`${backendURL}${id}/execute/`, auth);
        if (response.ok) return `${repr} проведено вдало`;
        if (response.status === 400) {
          const errData = buildErrors(await response.json());
          throw new ExecutionError(errData, `Помилка при проведенні ${repr}`);
        }
        throw new ExecutionError([`Помилка при проведенні ${repr} (${response.status} ${response.statusText})`]);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => exec(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            const errs = failedResults.map((fr) => fr.reason.message);
            errs.forEach((e) => showError(e));
            setLoading(false);
          } else {
            results.forEach((res) => toast.success(res.value, {
              theme: 'colored',
            }));
            onReload();
          }
        });
    },
    [auth, backendURL, onReload],
  );

  const onUnexecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const unexec = async (id, repr) => {
        const response = await api.patch(`${backendURL}${id}/unexecute/`, auth);
        if (response.ok) return `${repr} вдало скасовано проведення`;
        if (response.status === 400) {
          const errData = buildErrors(await response.json());
          throw new ExecutionError(errData, `Помилка при скасованні проведення ${repr}`);
        }
        throw new ExecutionError([`Помилка при скасованні проведення ${repr} (${response.status} ${response.statusText})`]);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => unexec(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            const errs = failedResults.map((fr) => fr.reason.message);
            errs.forEach((e) => showError(e));
            setLoading(false);
          } else {
            results.forEach((res) => toast.success(res.value, {
              theme: 'colored',
            }));
            onReload();
          }
        });
    },
    [auth, backendURL, onReload],
  );

  const actionKeys = useMemo(
    () => (options ? new Set(options.extra_actions.map((ea) => ea.name.toLowerCase())) : new Set()),
    [options],
  );

  // Разрешения:
  /*
Заполняются исключительно возможносятми.
Здесь нет анализа на выбрано что-то или нет
 */
  const permissions = useMemo(
    () => {
      const p = {
        canNew: !!options && !!options.actions.POST,
        canEdit: true,
        canCopy: !!options && !!options.actions.POST,
        canDelete: actionKeys.has('delete'),
        canHideDeleted: filteringFields.map((f) => f.name).includes('deleted'),
        canExecute: options && actionKeys.has('execute'),
        canUnexecute: options && actionKeys.has('unexecute'),
        canHierarchy: !noHierarchy && isHierarhical,
        canCompare: options && actionKeys.has('compare'),
        foldersUsed: !noHierarchy && isHierarhical && 'is_group' in columns,
        canNextPage: !pageInfo.current.allLoaded,
        canFilterByPeriod: filteringFields.map((f) => f.name).includes('doc_date'),
      };
      p.canNewFolder = p.canNew && p.foldersUsed;
      return p;
    },
    [actionKeys, columns, filteringFields, isHierarhical, noHierarchy, options],
  );

  return {
    meta,
    visibleColumns,
    items,
    options,
    loading,
    err,
    pageInfo,
    onReload,
    onLoadOptions,
    optionsLoaded,
    onNextPage,
    onDeleteItems,
    onExecute,
    onUnexecute,
    permissions,
    onLoadSettings,
    settings,
    onSetSettings,
    onLoadChildren,
    onUnloadChildren,
    loadPath,
    messages,
    clearMessages,
    deleteMessage,
    addMessage,
    filteringFields,
    useHierarchyPagination,
    printForms,
    actionKeys,
  };
};
export default useListerBackend;
