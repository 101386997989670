/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import FieldItem from './availableFieldItem';

function AvailableFields({
  items, selectHandler, choiceHandler, toggleHandler, activeItem, openedItems,
}) {
  return (
    <div className="flex-grow-1 h-100 ">
      <Droppable droppableId="DC.AvailableFields">
        {(provided) => (
          <ListGroup
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="h-100 overflow-auto border rounded"
            variant="flush"
          >
            {items.map((item) => (
              <Draggable
                ignoreContainerClipping
                key={item.key}
                draggableId={item.key}
                index={item.index}
              >
                {(dragProvided) => (
                  <FieldItem
                    ref={dragProvided.innerRef}
                    id={item.key}
                    item={item.item}
                    level={item.level}
                    isActive={item.key === activeItem}
                    opened={openedItems.includes(item.key)}
                    onClickHandler={selectHandler}
                    onDoubleClickHandler={choiceHandler}
                    onIconClickHandler={toggleHandler}
                    dragHandleProps={dragProvided.dragHandleProps}
                    draggableProps={dragProvided.draggableProps}
                  />
                )}
              </Draggable>
            ))}
            {/* {showItems} */}
            {provided.placeholder}
          </ListGroup>
        )}
      </Droppable>
    </div>
  );
}

AvailableFields.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    level: PropTypes.number,
    key: PropTypes.string,
    index: PropTypes.number.isRequired,
    item: PropTypes.shape({
      Tittle: PropTypes.string.isRequired,
      itemsCount: PropTypes.number,
    }).isRequired,
  })).isRequired,
  activeItem: PropTypes.string,
  selectHandler: PropTypes.func.isRequired,
  choiceHandler: PropTypes.func.isRequired,
  toggleHandler: PropTypes.func.isRequired,
  openedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AvailableFields.defaultProps = {
  activeItem: null,
};

export default memo(AvailableFields);
