import React, {
  useRef, useState, useEffect, useCallback, useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { debounce } from 'lodash';
import api from '../../../../api/req';
import { comparisonTypes, selectionMode } from '../../../../constants/meta/common';
import meta from '../../../../constants/meta';
import { ExpandIcon } from '../../../../assets/icons';
import {
  ButtonModalStyled,
  ButtonCleanStyled,
  DivDropStyled,
  DivInnerDropStyled, ButtonOpenStyled, ButtonsContainer,
} from './styles';

const SERVER_REQUEST_TIMEOUT = 500;

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

const filterValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.instanceOf(Map),
]);

export const filterPropType = PropTypes.arrayOf(PropTypes.shape({
  fieldName: PropTypes.string.isRequired,
  comparisonType: PropTypes.oneOf(Object.values(comparisonTypes)),
  value: PropTypes.oneOfType([filterValuePropType, PropTypes.arrayOf(filterValuePropType)]),
}));

export const paramsPropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(Map),
  ]),
}));

const getItemPicker = (ChildClass) => {
  function ItemPicker({
    modelType, modelName,
    value, disabled, required, canSelect, canOpen, canCleared,
    filter, choiceSettings,
    modalOpened,
    onChoiceButtonClick, onChange,
    rows, isFocusCell, rowId, id, setDataRef,
    noBorder, noBackground,
  }) {
    const mountNode = useRef();
    const optContainer = useRef();
    const [searchText, setSearchText] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState({ active: null, items: [] });
    const [optionsOpened, setOptionsOpened] = useState(false);

    const navigate = useNavigate();

    useEffect(
      () => {
        setSearchText(null);
        // value обязательно, потому что должні віполнятся при каждом измененеии value
        setOptionsOpened(value && false);
      },
      [value],
    );

    const onSearch = useCallback(
      (text) => {
        const loader = async () => {
          const response = await api.post$(
            `${modelType}/${meta[modelType][modelName].backendName}/getchoicedata`,
            {
              searchText: text,
              filter,
              choiceSettings,
            },
          );
          if (response.ok) {
            return response.json();
          }
          throw new Error(`${response.status} ${response.statusText}`);
        };
        setLoading(true);
        loader()
          .then(
            (data) => {
              setOptions({ active: null, items: data.ChoiceData });
              setOptionsOpened(true);
            },
          )
          .finally(setLoading(false));
      },
      [choiceSettings, filter, modelName, modelType],
    );

    const getListChoice = useMemo(
      () => debounce(onSearch, SERVER_REQUEST_TIMEOUT),
      [onSearch],
    );

    const onKeyDown = useCallback(
      (e) => {
        if (!mountNode.current.contains(e.target)) return true;
        if (e.key === 'F4') {
          e.stopPropagation();
          e.preventDefault();
          onChoiceButtonClick(e);
        } else if (optionsOpened && ['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(e.key)) {
          e.stopPropagation();
          e.preventDefault();
          switch (e.key) {
            case 'ArrowUp':
              setOptions(({ active, items }) => {
                if (active === null) return { active: items.length - 1, items };
                if (active <= 0) return { active: items.length - 1, items };
                return { active: active - 1, items };
              });
              break;
            case 'ArrowDown':
              setOptions(({ active, items }) => {
                if (active === null) return { active: 0, items };
                if (active >= items.length - 1) return { active: 0, items };
                return { active: active + 1, items };
              });
              break;
            case 'Enter':
              setOptions(({ active, items }) => {
                if (options.items[active]) {
                  onChange(e, new Map(options.items[active].id));
                } else {
                  setOptionsOpened(false);
                  setSearchText(null);
                }
                return { active, items };
              });
              break;
            case 'Escape':
              setOptionsOpened(false);
              setSearchText(null);
              break;
            default:
              throw new Error(`Unknow key ${e.key}`);
          }
        }
        return true;
      },
      [onChange, onChoiceButtonClick, options.items, optionsOpened],
    );
    useEffect(
      () => {
        document.body.addEventListener('keydown', onKeyDown);
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
        };
      },
      [onKeyDown],
    );

    const textChanger = useCallback(
      (e, v) => {
        setSearchText(v);
        getListChoice(v);
      },
      [getListChoice],
    );

    const clearValue = useCallback(
      (e) => onChange(e, new Map({ id: '', repr: '' })),
      [onChange],
    );
    const isEmpty = !value || !value.get('id') || value.get('id') === '00000000-0000-0000-0000-000000000000';
    useEffect(
      () => {
        if (optionsOpened && optContainer.current) {
          const el = optContainer.current.children[options.active];
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      },
      [options.active, optionsOpened],
    );
    return (
      <ChildClass
        tabIndex={0}
        // onKeyDown={analysisChoice}
        value={value || new Map()}
        onChange={textChanger}
        searchText={searchText}
        ref={mountNode}
        disabled={disabled}
        required={required}
        loading={loading}
        canFocused={!optionsOpened && !modalOpened}
        onDoubleClick={onChoiceButtonClick}
        open={onChoiceButtonClick}
        rows={rows}
        // TAB
        isFocusCell={isFocusCell}
        rowId={rowId}
        id={id}
        setDataRef={setDataRef}
        noBorder={noBorder}
        noBackground={noBackground}
      >
        <ButtonsContainer>
          {(canSelect || !disabled) && (
            <ButtonModalStyled
              onClick={onChoiceButtonClick}
              disabled={disabled}
              title="F4 - Вибрати зі списку"
              tabindex={0}
            >
              ...
            </ButtonModalStyled>
          )}
          {(canOpen && !disabled) && (
            <ButtonOpenStyled
              onClick={() => navigate(`/${meta[modelType][modelName].frontend}/${value.get('id')}`)}
              disabled={isEmpty}
              title="Відкрити"
              tabindex={-1}
            >
              <ExpandIcon />
            </ButtonOpenStyled>
          )}
          {canCleared && (
            <ButtonCleanStyled
              onClick={clearValue}
              disabled={disabled}
              tabindex={-1}
            >
              X
            </ButtonCleanStyled>
          )}
        </ButtonsContainer>
        <>
          {(mountNode.current && optionsOpened && searchText) && createPortal(
            (
              <DivDropStyled
                ref={optContainer}
                top={mountNode.current.getBoundingClientRect().height}
                // onMouseLeave={() => this.setState({ optionsOpened: false, searchText: null })}
              >
                {options.items.map((el, idx) => (
                  <DivInnerDropStyled
                    active={idx === options.active}
                    key={`_${el.id.id || el.id || el || `${idx}_undef`}`}
                    onClick={(e) => onChange(e, new Map({ id: el.id.id, repr: el.id.repr }))}
                  >
                    {el.repr}
                  </DivInnerDropStyled>
                ))}
              </DivDropStyled>),
            mountNode.current,
          )}
        </>
      </ChildClass>
    );
  }

  ItemPicker.displayName = `${getDisplayName(ChildClass)} wrapped by ItemPicker`;

  ItemPicker.propTypes = {
    value: PropTypes.instanceOf(Map),
    canCleared: PropTypes.bool,
    canAutoComplete: PropTypes.bool,
    canSelect: PropTypes.bool,
    canOpen: PropTypes.bool,
    modalOpened: PropTypes.bool,
    onChoiceButtonClick: PropTypes.func,
    onChange: PropTypes.func,
    filter: filterPropType,
    choiceSettings: PropTypes.oneOf(Object.values(selectionMode)),
    modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
    modelName: PropTypes.oneOf([
      ...new Set(Object.keys(meta)
        .reduce((r, typeName) => [...r, ...Object.keys(meta[typeName])], [])),
    ]).isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.number,
    noBorder: PropTypes.bool,
    noBackground: PropTypes.bool,
    // TAB - Ordering. Переход по Enter и т.д.
    isFocusCell: PropTypes.bool,
    rowId: PropTypes.number,
    id: PropTypes.number,
    setDataRef: PropTypes.func,
    // endTab
  };

  ItemPicker.defaultProps = {
    value: new Map(),
    canCleared: false,
    canAutoComplete: true,
    canSelect: true,
    canOpen: true,
    onChange: () => console.log('No handle changer!'),
    filter: [],
    modalOpened: false,
    choiceSettings: selectionMode.items,
    onChoiceButtonClick: () => console.log('No method onChoiceButtonClick on props'),
    disabled: false,
    required: false,
    rows: 1,
    noBorder: false,
    noBackground: false,
    // TAB - Ordering. Переход по Enter и т.д.
    isFocusCell: false,
    rowId: 0,
    id: 0,
    setDataRef: () => null,
    // endTab
  };

  return ItemPicker;
};
export default getItemPicker;
