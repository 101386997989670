import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { EditorDateInput } from '../../basicEditor/editorControls';

function PeriodEdit({
  beginDate, setBeginDate, endDate, setEndDate,
}) {
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorDateInput
            controlId="begin_date"
            label="Оберіть період з"
            required
            value={beginDate}
            onChange={(e, v) => setBeginDate(v)}
          />
        </Col>
        <Col>
          <EditorDateInput
            controlId="end_date"
            label="по"
            required
            value={endDate}
            onChange={(e, v) => setEndDate(v)}
          />
        </Col>
      </Row>
    </Container>
  );
}

PeriodEdit.propTypes = {
  beginDate: PropTypes.string,
  setBeginDate: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func.isRequired,
};

PeriodEdit.defaultProps = {
  beginDate: null,
  endDate: null,
};

export default PeriodEdit;
