import base from './base';

const columns = {
  ...base.columns,
  FIcode: { label: '№ р/р', name: 'РСФинансовыйОрганКод', visible: true },
  FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
  FIAccNo: { label: 'НомерСчетаФО', name: 'НомерСчетаФО' },
  Sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  RNo: { label: '№ ', name: 'РегНомер', visible: true },
  PlaningMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: false },
  Fond: { label: 'Фонд', name: 'Фонд' },
  TypeTransfer: { label: 'Вид перерахування', name: 'ВидПередачи' },
  AccountBalance: { label: 'Залишок на рахунку', name: 'Ост' },
  SumDoc: { label: 'За документом (разом)', name: 'ИтогСумма' },
  Delta: { label: 'Різниця', name: 'Дельта' },
  CarryOut: { label: 'Провести без залишків', name: 'ПровестиЕслиНетДенегНаСчету' },
  NoteCat: { label: 'Примітка', name: 'ПримечаниеСпр' },
  DateFill: { label: 'Дата фін.', name: 'ДатаФинансирования' },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      WriteOffAccount: { label: 'Списати', name: 'СписатьСоСчета' },
      WriteOffDate: { label: 'Факт.дата', name: 'СписатьДатой' },
      elementKFB: { label: 'КФБ(борг)', name: 'ЭлКФБ' },
      elementKFBcred: { label: 'КФБ(кред)', name: 'ЭлКФБ_кред' },
      elementKFB2: { label: 'Елемент КФБ(борг)', name: 'ЭлементКФБ' },
      elementKFB2cred: { label: 'Елемент КФБ(кред)', name: 'ЭлементКФБ_кред' },
      codeKFB: { label: 'КФБ(борг)', name: 'КодКФБ' },
      codeKFBcred: { label: 'КФБ(кред)', name: 'КодКФБ_кред' },
      sign: { label: '+/-', name: 'Знак' },
      signCred: { label: '+/-', name: 'Знак_кред' },
      AnalyticalAccount: { label: 'Аналітичний рахунок(надходжень)', name: 'РСАналитический' },
      AnalyticalAccLabel: { label: '№ рах.(надходжень)', name: 'БухСчет' },
      AnalyticalAccountCosts: { label: 'Аналітичний рахунок(витрат)', name: 'РСАналитический_витрат' },
      AnalyticalAccLabelCosts: { label: '№ рах.(витрат)', name: 'БухСчет_витрат' },
      AccountForTransfer: { label: 'Рахунок для перерахування', name: 'РСДляПеречисления' },
      AccForTransferLabel: { label: '№ рах.', name: 'РСРаспорядителя' },
      Remainder: { label: 'Залишок  асигнувань', name: 'Остаток' },
      Sum: { label: 'Сума', name: 'Сумма' },
      NoteDoc: { label: 'Шаблон примітки', name: 'ПримечанияДок' },
      Note: { label: 'Примітка', name: 'Примечание' },
      NoteCat: { label: 'Вид платежу', name: 'ПримечаниеСпр' },
    },
  },
  filledWithDoc: {
    name: 'ЗаполненоИзДок',
    label: 'Заповнено з док.',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      IsApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      document: { label: 'Документ', name: 'Документ' },
      Author: { label: 'Автор', name: 'ДокументАвтор' },
      sum: { label: 'Сума', name: 'СуммаДокумента' },
      numDoc: { label: 'Номер', name: 'ДокументНомер' },
      data: { label: 'Дата', name: 'ДатаДокумента' },
    },
  },
};

const definition = {
  label: 'Розпорядження на передачу коштів із заг.фонду до спец.',
  name: 'financingSources',
  backendName: 'ФинансированиеИсточники',
  columns,
  frontend: 'doc/financingSources',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'RNo',
      'FIcode',
      'FIAccount',
      'Sum',
      'Note',
      'Budget',
      'FI',
      'Author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
