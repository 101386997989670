// eslint-disable-next-line no-unused-vars
import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_FIELD, DATE_PARAMETER } from '../../../../../constants/backend';
import { childrenPropType } from '../../../../../constants/backend/propTypes';

function ClassifierListerSelector({
  filter, children, ...restProps
}) {
  const [cDate, setCDate] = useState(
    () => (filter && filter[DATE_PARAMETER]
      ? filter[DATE_PARAMETER]
      : new Date().toJSON().slice(0, 10)),
  );
  const [showInactive, setShowInactive] = useState(false);

  const reqDate = useMemo(
    () => cDate.replace('-', '').replace('-', ''),
    [cDate],
  );

  const flt = useMemo(
    () => ({
      ...(showInactive ? {} : { [ACTIVE_FIELD]: true }),
      [DATE_PARAMETER]: reqDate,
      ...filter,
    }),
    [filter, reqDate, showInactive],
  );

  const listerAddContext = useMemo(
    () => ({
      activeDate: cDate,
      onChangeActiveDate: (e, v) => setCDate(v),
      showInactive,
      onChangeShowInactive: (e, v) => setShowInactive(v),
    }),
    [cDate, showInactive],
  );

  return cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
    additionalContext: listerAddContext,
  });
}

ClassifierListerSelector.propTypes = {
  filter: PropTypes.shape({
    [ACTIVE_FIELD]: PropTypes.bool,
    [DATE_PARAMETER]: PropTypes.string,
  }),
  children: childrenPropType.isRequired,
};

ClassifierListerSelector.defaultProps = {
  filter: {},
};

export default ClassifierListerSelector;
