import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TPGridCell, TPGridRow } from '../../../../../basicEditor/index';
import { EItemPicker, ENumerInput, EStringInput } from '../../../../../../components/Controls/withErrors';
import {
  dataPropTypes,
  errorsPropTypes,
  fieldsPropTypes,
} from './propTypes';

const ROWS_ON_ROW = 2;

function Row({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors,
}) {
  const allProps = {
    onClick: (e) => onActivateRow(e, rowIndex),
    noBorder: true,
    noBackground: true,
  };
  return (
    <>
      <TPGridRow
        gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/indicator/${(rowIndex + 1) * ROWS_ON_ROW + ROWS_ON_ROW + 1}/amount_zf2`}
        active={active}
        selected={selected}
        onClick={(e) => onActivateRow(e, rowIndex)}
      />

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/indicator`}>
        <EItemPicker
          value={row.indicator}
          onChange={(e, v) => onChangeRow(e, rowIndex, { indicator: v })}
          readOnly={fields.indicator.read_only}
          errors={errors.indicator}
          modelType="cat"
          modelName="indicator"
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/gender_status`}>
        <EItemPicker
          value={row.gender_status}
          onChange={(e, v) => onChangeRow(e, rowIndex, { gender_status: v })}
          readOnly={fields.gender_status.read_only}
          errors={errors.gender_status}
          modelType="cat"
          modelName="gender_status"
          {...allProps}
        />
      </TPGridCell>

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/kvk3/${(rowIndex + 1) * ROWS_ON_ROW + 3}/kvk3`}>
        <EItemPicker
          value={row.kvk3}
          onChange={(e, v) => onChangeRow(e, rowIndex, { kvk3: v })}
          readOnly={fields.kvk3.read_only}
          errors={errors.kvk3}
          modelType="cat"
          modelName="kvk3"
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/gender_age`}>
        <EItemPicker
          value={row.gender_age}
          onChange={(e, v) => onChangeRow(e, rowIndex, { gender_age: v })}
          readOnly={fields.gender_age.read_only}
          errors={errors.gender_age}
          modelType="cat"
          modelName="gender_age"
          {...allProps}
        />
      </TPGridCell>

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/general_indicator_code`}>
        <EStringInput
          value={row.general_indicator_code}
          onChange={(e, v) => onChangeRow(e, rowIndex, { general_indicator_code: v })}
          readOnly={fields.general_indicator_code.read_only}
          errors={errors.general_indicator_code}
          maxLength={fields.general_indicator_code.max_length}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/gender_segment`}>
        <EItemPicker
          value={row.gender_segment}
          onChange={(e, v) => onChangeRow(e, rowIndex, { gender_segment: v })}
          readOnly={fields.gender_segment.read_only}
          errors={errors.gender_segment}
          modelType="cat"
          modelName="gender_segment"
          {...allProps}
        />
      </TPGridCell>

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/amount_total_2`}>
        <ENumerInput
          value={row.amount_zf_2 + row.amount_sf_2}
          readOnly
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/amount_total_1`}>
        <ENumerInput
          value={row.amount_zf_1 + row.amount_sf_1}
          readOnly
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/amount_total0`}>
        <ENumerInput
          value={row.amount_zf0 + row.amount_sf0}
          readOnly
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/amount_total1`}>
        <ENumerInput
          value={row.amount_zf1 + row.amount_sf1}
          readOnly
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 1}/amount_total2`}>
        <ENumerInput
          value={row.amount_zf2 + row.amount_sf2}
          readOnly
          precision={2}
          {...allProps}
        />
      </TPGridCell>

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_zf_2`}>
        <ENumerInput
          value={row.amount_zf_2}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_zf_2: v })}
          readOnly={fields.amount_zf_2.read_only}
          errors={errors.amount_zf_2}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_zf_1`}>
        <ENumerInput
          value={row.amount_zf_1}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_zf_1: v })}
          readOnly={fields.amount_zf_1.read_only}
          errors={errors.amount_zf_1}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_zf0`}>
        <ENumerInput
          value={row.amount_zf0}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_zf0: v })}
          readOnly={fields.amount_zf0.read_only}
          errors={errors.amount_zf0}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_zf1`}>
        <ENumerInput
          value={row.amount_zf1}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_zf1: v })}
          readOnly={fields.amount_zf1.read_only}
          errors={errors.amount_zf1}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_zf2`}>
        <ENumerInput
          value={row.amount_zf2}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_zf2: v })}
          readOnly={fields.amount_zf2.read_only}
          errors={errors.amount_zf2}
          precision={2}
          {...allProps}
        />
      </TPGridCell>

      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_sf_2`}>
        <ENumerInput
          value={row.amount_sf_2}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_sf_2: v })}
          readOnly={fields.amount_sf_2.read_only}
          errors={errors.amount_sf_2}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_sf_1`}>
        <ENumerInput
          value={row.amount_sf_1}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_sf_1: v })}
          readOnly={fields.amount_sf_1.read_only}
          errors={errors.amount_sf_1}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_sf0`}>
        <ENumerInput
          value={row.amount_sf0}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_sf0: v })}
          readOnly={fields.amount_sf0.read_only}
          errors={errors.amount_sf0}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_sf1`}>
        <ENumerInput
          value={row.amount_sf1}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_sf1: v })}
          readOnly={fields.amount_sf1.read_only}
          errors={errors.amount_sf1}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
      <TPGridCell gridArea={`${(rowIndex + 1) * ROWS_ON_ROW + 2}/amount_sf2`}>
        <ENumerInput
          value={row.amount_sf2}
          onChange={(e, v) => onChangeRow(e, rowIndex, { amount_sf2: v })}
          readOnly={fields.amount_sf2.read_only}
          errors={errors.amount_sf2}
          precision={2}
          {...allProps}
        />
      </TPGridCell>
    </>
  );
}

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes.isRequired,
};

Row.defaultProps = {
  active: false,
  selected: false,
};

export default memo(Row);

// TODO: @jaksam backendURL Indicator
// TODO: @jaksam backendURL gender_status
// TODO: @jaksam backendURL gender_age
// TODO: @jaksam backendURL gender_segment
