import {
  defaultColumns,
  directions,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  code: { label: 'КОАТУУ', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  katottg: { label: 'КАТОТТГ', name: 'КАТОТТГ', visible: true },
  edrpou: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: true },
};

export const koatuu = {
  label: 'Довідник територій',
  name: 'koatuu',
  backendName: 'КОАТУУ',
  defaultOrder: [{
    column: defaultColumns.code.name,
    direction: directions.ascending,
  }],
  columns,
  frontend: 'cat/koatuu',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'katottg',
      'edrpou',
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.onlyItems,
};

export default koatuu;
