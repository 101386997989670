import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  KPKV: { label: 'КПКВМБ', name: 'КБП7', visible: false },
  year: { label: 'Рік', name: 'Год', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Распорядители',
    label: 'Розпорядники',
    columns: {
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Мережа',
  name: 'net',
  backendName: 'Мережа',
  columns,
  frontend: 'doc/net',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
