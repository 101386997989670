import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { useDispatch } from 'react-redux';
import GridTemplate from './GridTemplate';
import { DivCell, DivCheckCell } from '../../../../../components/styledTable/styledTable';

import CheckboxStyled from '../../../../../components/styledCheckbox/styledCheckbox';
import { changeField } from '../../../../../actions/dpEditor';

import ItemField from '../../../../field/selectorField/item/itemField';

const TABLENAME = 'csues';

function RowComponent({ row, rowId }) {
  const dispatch = useDispatch();

  return (
    <GridTemplate>
      <DivCheckCell areaName="check">
        <CheckboxStyled
          value={row.get('check', false)}
          onChange={(e, value) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'check'], value))}
        />
      </DivCheckCell>
      <DivCell areaName="csu">
        <ItemField
          noBorder
          noBackground
          value={row.get('csu', new Map())}
        />
      </DivCell>
    </GridTemplate>
  );
}

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
};

export default memo(RowComponent);
