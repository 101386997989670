import React from 'react';
import Lister from '../../lister/lister';

function FinancingRepairObjectsLister() {
  return (
    <Lister
      modelType="doc"
      modelName="financingRepairObjects"
    />
  );
}

export default FinancingRepairObjectsLister;
