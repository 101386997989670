import React, { useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { WinManagerContext } from '../../minfin/providers';

const getRouterEditor = (meta) => (WrappedComponent) => {
  function RouterEditor({ id, search }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { dellComponentFromWindowsManager, prevWindow } = useContext(WinManagerContext);
    const oldLocation = useRef(location.pathname);
    const onClose = useCallback(
      () => {
        prevWindow();
        dellComponentFromWindowsManager(location.pathname);
      },
      [dellComponentFromWindowsManager, location.pathname, prevWindow],
    );
    const onSave = useCallback(
      (newId) => {
        dellComponentFromWindowsManager(oldLocation.current);
        navigate(`/${meta.frontend}/${newId}/`);
      },
      [dellComponentFromWindowsManager, navigate],
    );
    return (
      <WrappedComponent
        id={id}
        search={search}
        onClose={onClose}
        onSave={onSave}
      />
    );
  }

  RouterEditor.displayName = `Router Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  RouterEditor.propTypes = {
    id: PropTypes.string.isRequired,
    search: PropTypes.string,
  };

  RouterEditor.defaultProps = {
    search: null,
  };

  return RouterEditor;
};

export default getRouterEditor;
