import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/liquidity';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'dod1';

const tableMD = md.tables[tablename];

const repMonth = {
  m1: { label: 'Січень', name: 'Січень' },
  m2: { label: 'Лютий', name: 'Лютий' },
  m3: { label: 'Березень', name: 'Березень' },
  m4: { label: 'Квітень', name: 'Квітень' },
  m5: { label: 'Травень', name: 'Травень' },
  m6: { label: 'Червень', name: 'Червень' },
  m7: { label: 'Липень', name: 'Липень' },
  m8: { label: 'Серпень', name: 'Серпень' },
  m9: { label: 'Вересень', name: 'Вересень' },
  m10: { label: 'Жовтень', name: 'Жовтень' },
  m11: { label: 'Листопад', name: 'Листопад' },
  m12: { label: 'Грудень', name: 'Грудень' },
};

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="routeCode" highlighted={highlights.includes('routeCode')}>
              <StringInput
                value={row[tableMD.columns.routeCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.routeCode.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="route" highlighted={highlights.includes('route')}>
              <ItemPicker
                value={row[tableMD.columns.route.name]}
                modelType="cat"
                modelName="kekvClassifierShort"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.route.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="fond" highlighted={highlights.includes('fond')}>
              <ItemPicker
                value={row[tableMD.columns.fond.name]}
                modelType="cat"
                modelName="fondClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.fond.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum4" highlighted={highlights.includes('sum4')}>
              <NumberInput
                value={row[tableMD.columns.sum4.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum4.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum2" highlighted={highlights.includes('sum2')}>
              <NumberInput
                value={row[tableMD.columns.sum2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum5" highlighted={highlights.includes('sum5')}>
              <NumberInput
                value={row[tableMD.columns.sum5.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum5.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum3" highlighted={highlights.includes('sum3')}>
              <NumberInput
                value={row[tableMD.columns.sum3.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum3.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum6" highlighted={highlights.includes('sum6')}>
              <NumberInput
                value={row[tableMD.columns.sum6.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum6.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.sum2.name]: PropTypes.number,
    [tableMD.columns.sum3.name]: PropTypes.number,
    [tableMD.columns.sum4.name]: PropTypes.number,
    [tableMD.columns.sum5.name]: PropTypes.number,
    [tableMD.columns.sum6.name]: PropTypes.number,
    [tableMD.columns.fond.name]: referencePropType,
    [tableMD.columns.route.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
