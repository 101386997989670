import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/forecastAdd9';

const meta = md.tables.expenses;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicatorNum'}
              highlighted={highlights.includes('indicatorNum')}
              onToggleHighlght={() => onToggleHighlght('indicatorNum')}
            >
              {meta.columns.indicatorNum.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicator'}
              highlighted={highlights.includes('indicator')}
              onToggleHighlght={() => onToggleHighlght('indicator')}
            >
              {meta.columns.indicator.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Report'}
              highlighted={highlights.includes('expensesSF_Report')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Report')}
            >
              {`${activeYear - 2} (звіт)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Approved'}
              highlighted={highlights.includes('expensesSF_Approved')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Approved')}
            >
              {`${activeYear - 1} (затверджено)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan'}
              highlighted={highlights.includes('expensesSF_Plan')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan')}
            >
              {`${activeYear} (план)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan1'}
              highlighted={highlights.includes('expensesSF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan1')}
            >
              {`${activeYear + 1} (план)`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'expensesSF_Plan2'}
              highlighted={highlights.includes('expensesSF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('expensesSF_Plan2')}
            >
              {`${activeYear + 2} (план)`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
