import React from 'react';
import md from '../../../../../meta/cat/fonditem';
import ListerFactory from '../../../../newLister';
import { CatTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_elementLister/lister_selector';
import ClassifierRow from '../_classifierLister/classifierRow';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(CatTableListerFactory({
  RowComponent: ClassifierRow,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
