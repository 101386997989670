import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import CommandPanelButton from '../../../components/button/commandPanelButton';

import { FramePrintModal } from '../../../components/Form/styledForm';
import { SettingsIcon } from '../../../assets/icons';

import TableEditor from '../../tableEditor';
import { ContainerButtonTop } from '../../tableEditor/styles';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.registrationFormPrinting;

class PrintingEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
    isProcessing: false,
  };

  // static getDerivedStateFromProps(props, state) {
  //  if (state.firstStart && !props.isProcessing) {
  //    props.dispatch(actions.processServerReq('PRINT'));
  //   return { firstStart: false };
  // }
  // return null;
  // }

  constructor(props) {
    super(props);
    this.resultMountNode = [`dp/${md.name}/dpEditor`, 'headerForm', 'result'];
    this.state = {
      settingsOpened: false,
      firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, isProcessing },
      state: { settingsOpened },
    } = this;
    return (
      <MainContainer>
        <ContainerButtonTop style={{ marginTop: '10px' }}>
          <CommandPanelButton
            text="Сформувати"
            onClick={() => {
              this.setState({ settingsOpened: false });
              dispatch(actions.processServerReq('PRINT'));
            }}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
          </CommandPanelButton>
          <CommandPanelButton
            // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
            text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
            onClick={() => this.setState({ settingsOpened: !settingsOpened })}
          >
            <SettingsIcon />
          </CommandPanelButton>
        </ContainerButtonTop>
        {settingsOpened ? (
          <FramePrintModal />
        ) : (
          <TableEditor mountNode={this.resultMountNode} />
        )}
      </MainContainer>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: TPDocs, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(PrintingEditor);
