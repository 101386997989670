import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import Cell from './cell';
import { PrintableRow } from './styles';

function Row({
  cells, tableName,
  activeCell, editMode, onActivateCell, onResizeColumn,
  fixedLeft, fixedTop, onReportDetails,
  tableTop, tableLeft,
}) {
  const groupValues = useMemo(
    () => cells
      .filter((c) => !!c.get('decryption', false))
      .reduce((R, c) => R.merge(c.get('decryption')), new Map()),
    [cells],
  );
  const onDetails = useCallback(
    (e, description) => onReportDetails && onReportDetails(e, description, groupValues),
    [groupValues, onReportDetails],
  );
  return (
    <PrintableRow>
      {cells.map((cell) => (
        <Cell
          key={cell.get('name')}
          name={cell.get('name')}
          column={cell.get('col', 0)}
          colSpan={cell.get('colspan', 0)}
          rowSpan={cell.get('rowspan', 0)}
          content={cell.get('content', '')}
          styleId={cell.get('styleId', 0)}
          decryption={cell.get('decryption', '')}
          tableName={tableName}
          active={cell.get('col', 0) === activeCell}
          editMode={cell.get('col', 0) === activeCell && editMode}
          onActivateCell={onActivateCell}
          onResizeColumn={onResizeColumn}
          fixedTop={fixedTop}
          fixedLeft={cell.get('col', 0) <= fixedLeft}
          onReportDetails={onDetails}
          tableTop={tableTop}
          tableLeft={tableLeft}
        />
      ))}
    </PrintableRow>
  );
}

Row.propTypes = {
  cells: PropTypes.instanceOf(List).isRequired,
  tableName: PropTypes.number.isRequired,
  activeCell: PropTypes.number,
  editMode: PropTypes.bool.isRequired,
  onActivateCell: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  fixedLeft: PropTypes.number.isRequired,
  fixedTop: PropTypes.bool.isRequired,
  onReportDetails: PropTypes.func.isRequired,
  tableTop: PropTypes.number,
  tableLeft: PropTypes.number,
};

Row.defaultProps = {
  activeCell: null,
  tableTop: 0,
  tableLeft: 0,
};

export default memo(Row);
