/**
 * @const
 */
const baseActions = [
  'INIT',
  'SR_START',
  'SR_DONE',
  'SR_ERR',
  'CHANGE_PROPERTY',
  'SR_CHANGE_PROPERTY',
  'TABLE_ROW_SET_ACTIVE',
  'TABLE_ROW_ADD',
  'TABLE_ROW_COPY',
  'TABLE_ROW_SWAP',
  'TABLE_ROW_REMOVE',
  'PROCESS_ATTACHED_FILES',
  'PROCESS_ADD_FILES',
  'DELETE_ATTACHED_FILES',
  'TABLE_FILL',
  'SET_DP_EDITOR',
];

/**
 * @func
 * @param {object} r
 * @param {string} a
 * @return {object}
 */
const dpEditorActionCreators = (r, a) => ({ ...r, [a]: `${a}/@dpEditor` });

/**
 * @const
 * @property {string} INIT
 * @property {string} LOAD_DONE
 * @property {string} LOAD_ERR
 * @property {string} SR_START
 * @property {string} SR_DONE
 * @property {string} SR_ERR
 * @property {string} CHANGE_PROPERTY
 * @property {string} SR_CHANGE_PROPERTY
 * @property {string} TABLE_ROW_SET_ACTIVE
 * @property {string} TABLE_ROW_ADD
 * @property {string} TABLE_ROW_COPY
 * @property {string} TABLE_ROW_SWAP
 * @property {string} TABLE_ROW_REMOVE
 * @property {string} TABLE_FILL
 * @property {string} PROCESS_ATTACHED_FILES
 * @property {string} PROCESS_ADD_FILES
 * @property {string} DELETE_ATTACHED_FILES
 */
const dpEditorActions = baseActions.reduce(dpEditorActionCreators, {});

export default dpEditorActions;
