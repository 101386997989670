import React, { PureComponent, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  TwoColGridContainer, ThreeColumnsGridContainer,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

import DateRangeField from '../../../components/fields/DateRangeField';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.periodPlanPrinting;

class PeriodPlanPrintingEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    headerForm: null,
    isProcessing: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ShowSettings: false,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, headerForm, isProcessing },
      state: { ShowSettings },
    } = this;

    return (
      <MainContainer>
        <TwoColGridContainer />
        <ContainerButtonTop>
          <CommandPanelButton
            text="Сформувати"
            onClick={() => {
              this.setState({ ShowSettings: false });
              dispatch(actions.changeField(['headerForm', 'result'], new Map()));
              dispatch(actions.processServerReq('PRINT'));
            }}
            loading={isProcessing}
          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
          </CommandPanelButton>
          <CommandPanelButton
            text="Налаштування"
            onClick={() => { this.setState({ ShowSettings: !ShowSettings }); }}
          >
            <SettingsIcon />
          </CommandPanelButton>
        </ContainerButtonTop>
        {
          ShowSettings && (
            <FramePrintModal
              styled={{ ShowSettings } ? { transition: '3s' } : { transition: '3s' }}
              visible={ShowSettings}
              animation="overlay"
              width="very wide"
              direction="top"
              tertiary
              color="blue"
              basic
            >
              <TwoColGridContainer>
                <div>
                  <StyledLabel>{md.columns.budget.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('budget', new Map())}
                    modelType="cat"
                    modelName="budget"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'budget'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.FO.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('FO', new Map())}
                    modelType="cat"
                    modelName="csu"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FO'], v))}
                  />
                </div>
              </TwoColGridContainer>
              <div style={{ marginTop: '10px' }}>
                <StyledLabel>{md.columns.period.label}</StyledLabel>
                <DateRangeField
                  value={headerForm.get('period', new Map())}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'period'], v))}
                  noBorder
                />
              </div>
              <ThreeColumnsGridContainer>
                <div>
                  <StyledLabel>{md.columns.coverElement.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('coverElement', new Map())}
                    modelType="cat"
                    modelName="coverageSource"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'coverElement'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.fond.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('fond', new Map())}
                    modelType="cat"
                    modelName="fondClassifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fond'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.KPKV.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('KPKV', new Map())}
                    modelType="cat"
                    modelName="kbp7Classifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KPKV'], v))}
                    filter={[
                      { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
                  />
                </div>
              </ThreeColumnsGridContainer>
              <ThreeColumnsGridContainer>
                <div>
                  <StyledLabel>{md.columns.KBP.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('KBP', new Map())}
                    modelType="cat"
                    modelName="kbpClassifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KBP'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.KEKV.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('KEKV', new Map())}
                    modelType="cat"
                    modelName="kekvClassifier"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKV'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.csu.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('csu', new Map())}
                    modelType="cat"
                    modelName="csu"
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'csu'], v))}
                  />
                </div>
              </ThreeColumnsGridContainer>

              <div>
                <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('signVariant', new Map())}
                  modelType="cat"
                  modelName="signatureVariants"
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'signVariant'], v))}
                />
              </div>

            </FramePrintModal>
          )
        }
        <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
      </MainContainer>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // indicatorsFactPrint',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(PeriodPlanPrintingEditor);
