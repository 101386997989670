import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  inPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  onlyMy: { label: 'Показувати тількі власні плани, зобов\'язання всі', name: 'ПланыТолькоПоСебе' },
  byCurrentLevel: { label: 'Тільки за своїм рівнем', name: 'флПоСвоемуУровню' },
  byLowLevel: { label: 'За данними підлеглих розпорядників', name: 'флПоДаннымНижнихРасп' },
  byExecutiveLevel: { label: 'За даними виконавців', name: 'флПоДаннымИсполнителей' },
};

const definition = {
  label: 'Звіт по бюджету розвитку (Баланс планів асигнувань, зобов"язань та платіжних доручень)',
  name: 'ReportByBR',
  columns,
  frontend: 'rep/ReportByBR',
  backendName: 'ОтчетПоБР',
  resultColumns: baseResultColumns,
};

export default definition;
