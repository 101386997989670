const getParam = (paramName) => {
//  return null;
  const so = JSON.parse(localStorage.getItem('sessionOptions'));
  return so && so[paramName];
};

const session = {
  get: getParam,
};

export default session;
