import {
  Map, OrderedMap, List, fromJS,
} from 'immutable';
import { createPath } from 'react-router-dom';
import meta from '../constants/meta';
import api from '../api/req';
import notitfierActions from './notifier';
import {
  directions, comparisonTypes, emptyUid, hierarchyTypes, periodTypes,
} from '../constants/meta/common';

const ELEMENTS_BY_PAGE = 100;

/**
 * @function Возвращает симвользное имя настоек в зависсимости от переменных
 * @param {string} modelType
 * @param {string} modelName
 * @param {string} viewType
 * @return {string}
 */

const getSettingsName = (modelType, modelName, viewType) => {
  const md = meta[modelType][modelName];
  const formName = viewType === 'lister' ? 'ФормаСписка' : 'ФормаВыбора';
  switch (modelType) {
    case 'doc':
      return `Документ.${md.backendName}.${formName}`;
    case 'cat':
      return `Справочник.${md.backendName}.${formName}`;
    case 'ChTP':
      return `ПланыВидовХарактеристик.${md.backendName}.${formName}`;
    default:
      return null;
  }
};

const getDefaultPeriod = (modelType, modelName) => {
  const md = meta[modelType][modelName];
  const periodType = md.defaultPeriodType || periodTypes.month;
  const beginDate = new Date();
  const endDate = new Date();
  switch (periodType) {
    case periodTypes.year:
      beginDate.setMonth(0, 1);
      endDate.setMonth(12, 0);
      break;
    case periodTypes.halfYear:
      beginDate.setMonth(Math.floor(beginDate.getMonth() / 6) * 6, 1);
      endDate.setMonth((Math.floor(endDate.getMonth() / 6) + 1) * 6, 0);
      break;
    case periodTypes.quarter:
      beginDate.setMonth(Math.floor(beginDate.getMonth() / 3) * 3, 1);
      endDate.setMonth((Math.floor(endDate.getMonth() / 3) + 1) * 3, 0);
      break;
    case periodTypes.month:
      beginDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1, 0);
      break;
    case periodTypes.day:
      break;
    default:
      throw new Error('Неивестный тип периода');
  }
  // Из-за этого смещается дата
  beginDate.setHours(0, -beginDate.getTimezoneOffset(), 0);
  endDate.setHours(23, 59 - endDate.getTimezoneOffset(), 59);
  return {
    variant: 'Custom',
    bDate: beginDate.getTime(),
    eDate: endDate.getTime(),
  };
};

/**
 * @func
 * @param {OrderedMap} items
 * @param {OrderedMap} openedItems
 * @param {String} currentId
 * @return {OrderedMap}
 */
const generateVisibleItems = (items, openedItems = new OrderedMap(), currentId = '') => {
  const oi = currentId ? items.reduce((r) => (r.currentId ? {
    currentId: items.getIn([r.currentId, 'parentId']),
    res: r.res.set(r.currentId, items.get(r.currentId).set('TOGGLED', true)),
  } : r), { currentId: items.getIn([currentId, 'parentId']), res: new OrderedMap() }).res : openedItems;
  return items.reduce((r, v) => (
    !v.get('parentId') || oi.has(v.get('parentId'))
      ? r.set(v.get('id'), v
        .merge(new Map(oi.getIn([v.get('id'), 'TOGGLED'], false) ? { TOGGLED: true } : {}))
        .merge(new Map(v.get('id') === currentId || oi.getIn([v.get('id'), 'ACTIVE'], false) ? { ACTIVE: true } : {})))
      : r
  ), new OrderedMap());
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const loadErr = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (errorMsg) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      dispatch({
        type: `@${NODE_NAME}/LOAD_ERROR`,
        payload: errorMsg,
      });
    };
    return middleware;
  };
  return handler;
};

const clearResultNode = (viewType, modelType, modelName) => () => (dispatch) => {
  /**
   * @const
   * @type {Immutable.Map}
   */

  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;

  dispatch({
    type: `@${NODE_NAME}/CLEAR_RESULT_NODE`,
    payload: null,
  });
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const loadDone = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;

  const COLUMNS = meta[modelType][name].columns;

  const handler = (response, parentId = emptyUid, addOpenedItems = new OrderedMap(), selectedItems) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      if (response.ok) {
        response.json()
          .then((data) => {
            /**
             * @func
             * @param {Immutable.OrderedMap} r
             * @param {string} v
             * @return {Immutable.OrderedMap}
             */
            // console.log('data', data);
            const mapItems = (r, v) => r.set(
              v.slice(1),
              Object.keys(COLUMNS).reduce(
                (row, rowName) => (data[v][COLUMNS[rowName].name]
                  ? row.set(rowName, fromJS(data[v][COLUMNS[rowName].name]))
                  : fromJS(row)),
                new Map({ parentId: data[v].parentId }),
              ),
            );
            const oldItems = getState().getIn([NODE_NAME, 'items'], new OrderedMap());

            const HLModifier = parentId !== emptyUid ? oldItems.getIn([parentId, 'hierarchyLevel'], 0) + 1 : 0;

            const newItems = Object.keys(data).reduce(mapItems, new OrderedMap()).map((r) => r.set('hierarchyLevel', r.get('hierarchyLevel', 0) + HLModifier));

            const insertIndex = oldItems.keySeq().findKey((k) => k === parentId) + 1;
            //  const items = newItems.merge(oldItems);
            const items = (insertIndex
              ? oldItems.slice(0, insertIndex)
                .concat(newItems)
                .concat(oldItems.slice(insertIndex))
              : newItems.merge(oldItems));
            // .map(el => addOpenedItems.has(el.get('id', '')) ? el.set('TOGGLED', true) : el);

            dispatch({
              type: `@${NODE_NAME}/ALL_ITEMS_LOADED`,
              payload: (items.size - oldItems.size) < getState().getIn([NODE_NAME, 'elementsByPage'], 0),
            });
            const openedItems = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap())
              .filter((el) => el.get('TOGGLED', false) || addOpenedItems.has(el.get('id', '')))
              .map((el) => (addOpenedItems.has(el.get('id', '')) ? el.set('TOGGLED', true) : el))
              .concat(addOpenedItems);
            const currentId = getState().getIn([NODE_NAME, 'initialValue', 'id'], '');
            const visibleItems1 = generateVisibleItems(items, openedItems, currentId);
            // Востанавливаем выделенные строки
            const visibleItems = currentId ? visibleItems1 : visibleItems1.map((i, k) => (selectedItems.includes(k) ? i.set('ACTIVE', true) : i));

            dispatch({
              type: `@${NODE_NAME}/LOAD_DONE`,
              payload: {
                items,
                visibleItems,
                initialValue: new Map(),
              },
            });
          })
          .catch((error) => {
            dispatch(loadErr(viewType, modelType, name)(error.message));
          });
      } else if (response.status === 500) {
        // необработанная ошибка 1С. Текст ошибки берем из тела
        response.text()
          .then((error) => (
            dispatch(loadErr(viewType, modelType, name)(error))
          ))
          .catch((error) => {
            dispatch(loadErr(viewType, modelType, name)(error.message));
          });
      } else {
        // обработать !!!!
        console.log(response);
      }
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const load = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (reload = true, parentId = emptyUid, newOpenedItems = null) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      /**
       * @const
       * @type {Immutable.Map}
       */
      const store = getState();

      /**
       * @type {Immutable.List}
       */
      const selectedFields = store.getIn([NODE_NAME, 'columns'], new List());

      /**
       * @type {Immutable.List}
       */
      const orderSettings = store.getIn([NODE_NAME, 'order'], new List());

      /**
       * @type {Immutable.Map}
       */
      const filter = store.getIn([NODE_NAME, 'filter'], new Map());

      const showDeleted = store.getIn([NODE_NAME, 'showDeleted'], false);

      const searchString = store.getIn([NODE_NAME, 'search'], '');

      const useLazyLoad = store.getIn([NODE_NAME, 'useLazyLoad'], false);

      const useFoldersLazyLoad = store.getIn([NODE_NAME, 'useFoldersLazyLoad'], false);

      const elementsByPage = store.getIn([NODE_NAME, 'elementsByPage'], 0);

      const elementsRange = store.getIn([NODE_NAME, 'elementsRange']);

      const noHierarchy = store.getIn([NODE_NAME, 'noHierarchy']);

      const period = store.getIn([NODE_NAME, 'period']);

      const openedItems = newOpenedItems || getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap()).filter((el) => el.get('TOGGLED', false));

      const selectedItems = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap()).filter((el) => el.get('ACTIVE', false)).keySeq();

      const initialValue = store.getIn([NODE_NAME, 'initialValue'], null);

      const reqParents = reload
        ? openedItems.keySeq().reduce((R, id) => [...R, { id }], [{ id: parentId }])
        : [{ id: parentId }];

      let llSettings = {};
      if (useLazyLoad && modelType === 'doc') {
        llSettings = {
          start: reload ? -elementsByPage + 1 : elementsRange.get('start', 0) - elementsByPage,
          end: reload ? 0 : elementsRange.get('end', elementsByPage) - elementsByPage,
        };
      } else if (useLazyLoad && modelType === 'cat') {
        llSettings = {
          start: reload ? 0 : elementsRange.get('start', 0) + elementsByPage,
          end: reload ? elementsByPage - 1 : elementsRange.get('end', elementsByPage) + elementsByPage,
        };
      }

      if (reload) {
        dispatch({
          type: `@${NODE_NAME}/CLEAR_ITEMS`,
        });
      }

      dispatch({
        type: `@${NODE_NAME}/LOAD_START`,
        payload: {
          elementsRange: new Map(llSettings),
        },
      });
      const loadFunc = (folderIds, useHierarchy) => api
        .post$(`${modelType}/${meta[modelType][name].backendName}`, {
          selected_fields: selectedFields.reduce(
            (r, v) => [...r, { name: v }],
            [],
          ),
          order: orderSettings.reduce((r, v) => [...r, v], []),
          filter: [
            ...filter.reduce((r, v) => ([...r, v.toJS ? v.toJS() : v]), []),
            ...showDeleted ? [] : [{
              fieldName: 'ПометкаУдаления',
              value: false,
            }],
            ...modelType === 'doc' ? [
              {
                fieldName: 'Дата',
                comparisonType: comparisonTypes.greaterOrEqual,
                value: period && period.get('StartDate', 0),
              },
              {
                fieldName: 'Дата',
                comparisonType: comparisonTypes.lessOrEqual,
                value: period && period.get('EndDate', 0),
              },
            ] : [],
            ...(useFoldersLazyLoad && !searchString) ? [
              {
                fieldName: '_Родитель',
                comparisonType: comparisonTypes.inList,
                value: folderIds,
              },
            ] : [],
          ],
          searchString,
          llSettings,
          NoHierarchy: noHierarchy,
          useFoldersLazyLoad: useFoldersLazyLoad && !useHierarchy,
        })
        .then(
          /**
             * @function
             * @param {object} parsedResponse
             */
          (parsedResponse) => {
            dispatch(loadDone(viewType, modelType, name)(parsedResponse, parentId, openedItems, selectedItems));
          },
        )
        .catch((error) => {
          dispatch(loadErr(viewType, modelType, name)(error.message));
        });
      const currentId = initialValue && initialValue.get('id', emptyUid);
      if (reload && initialValue && currentId && currentId !== emptyUid && modelType !== 'doc') {
        api
          .post$(`${modelType}/${meta[modelType][name].backendName}/${initialValue.get('id', emptyUid)}/getPath`)
          .then((resp) => resp.json().then((path) => {
            loadFunc([...path, { id: emptyUid }], true);
          }))
          .catch((error) => {
            dispatch(loadErr(viewType, modelType, name)(error.message));
          });
      } else {
        loadFunc(reqParents, reload);
      }
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const loadSettingsDone = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const md = meta[modelType][modelName];
  const handler = (data) => {
    /**
     *jr
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      // размеры колонок
      if (!data) {
        return dispatch({
          type: `@${NODE_NAME}/SETTINGS_LOAD_DONE`,
          payload: new Map(),
        });
      }

      const { columnSizes } = data;

      let applyingSettings = new Map();

      // Сохраненнные размеры колонок
      if (data && columnSizes) {
        const visibleColumns = md.listColumns.filter((c) => md.columns[c].visible);

        // console.log(modelName, ': {');
        // console.log('visibleColumns:', visibleColumns);
        // console.log('data:', data, ', },');

        const newColumnSizes = Object.keys(columnSizes).reduce((R, colName) => {
          if (!visibleColumns.includes(colName)) return R;
          return R.setIn([colName, 'size'], columnSizes[colName]);
        }, getState().getIn([NODE_NAME, 'columnSizes'], new Map()));
        applyingSettings = applyingSettings.set('columnSizes', newColumnSizes);
      }

      // Период журнала для документа
      if (modelType === 'doc') {
        if (data.period) {
          applyingSettings = applyingSettings.set('period', new Map(data.period));
        }
      }

      dispatch({
        type: `@${NODE_NAME}/SETTINGS_LOAD_DONE`,
        payload: applyingSettings,
      });
    };
    return middleware;
  };
  return handler;
};
/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const loadSettingsError = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (errorMsg) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SETTINGS_LOAD_ERROR`,
        payload: errorMsg,
      });
    };
    return middleware;
  };
  return handler;
};
/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const loadSettings = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      const settingsName = getSettingsName(modelType, modelName, viewType);
      if (settingsName) {
        dispatch({
          type: `@${NODE_NAME}/SETTINGS_LOAD_START`,
          payload: null,
        });
        api.post$(`settings/${settingsName}`)
          .then((parsedResponse) => {
            parsedResponse.json().then((data) => (
              dispatch(loadSettingsDone(viewType, modelType, modelName)(data))
            ));
          })
          .catch((error) => {
            dispatch(loadSettingsError(viewType, modelType, modelName)(error.message));
          });
      } else {
        dispatch(loadSettingsDone(viewType, modelType, modelName)(null));
      }
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const init = (viewType, modelType, modelName) => (initialValue, useLazyLoad = false, noHierarchy = false) => {
  /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
  const middleware = async (dispatch, getState) => {
    const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
    // const cD = new Date();

    const store = getState();

    /**
       * @type {Immutable.List}
       */

    // Заблокировал условие Роман. Есл разблокировано,
    // то остаюся старые параметры на экране (например noHierarchy)
    if (store.getIn([NODE_NAME, 'items']) && false) {
      // return dispatch(load(viewType, modelType, modelName)());
      return false;
    }

    const md = meta[modelType][modelName];

    const defaultPeriod = getDefaultPeriod(modelType, modelName);

    const StartDate = store.getIn([NODE_NAME, 'period', 'StartDate'], defaultPeriod.bDate);
    const EndDate = store.getIn([NODE_NAME, 'period', 'EndDate'], defaultPeriod.eDate);
    const Variant = store.getIn([NODE_NAME, 'period', 'Variant'], defaultPeriod.variant);

    const visibleColumns = new List(md.listColumns.filter((column) => md.columns[column].visible));
    const columnSizes = visibleColumns.reduce((R, column) => R.set(column, new Map({
      size: md.columns[column].width || '1fr',
      label: md.columns[column].label,
    })), new OrderedMap());

    dispatch({
      type: `@${modelType}/${modelName}/${viewType}/INIT`,
      payload: Map({
        // ссылка на тип в backend
        modelType,

        // название модели в front
        modelName,

        // фл загрузки документа
        isLoading: true,

        // фл процесса обработки проведения/серверного вызова/сохранения/etc
        isProcessing: true,

        // наличие ошибки
        hasError: false,

        // содержимое ошибки
        errorMsg: null,

        // Место для списка заблокированых объектов
        // блокировка по id
        // Для проведения/удаления
        lockedList: new List(),

        // Список столбцов для загрузки
        columns: md.listColumns.reduce(
          /**
             * @param {List} r
             * @param {string} v
             * @return {List}
             */
          (r, v) => r.push(md.columns[v].name),
          new List(),
        ),

        // "сырой" (теневой) упорядоченый словарь в сервера
        items: getState().getIn([NODE_NAME, 'items'], new OrderedMap()),
        // items: new OrderedMap(),

        // "видимый" упорядоченый словарь
        // можно менять содержимое, к примеру
        // выставить смещение в иерархии до
        // того как данные будут отрисовыватся
        visibleItems: getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap()),
        // visibleItems: new OrderedMap(),

        // фильтр в виде набора параметров
        // Возможно стоит сменить на Set (коллекцию)
        // после изменения стоит загрузить данные заново
        filter: new List(),

        // "Быстрый поиск" - может изменять видимый массив
        // Иначе - использовать фильтр
        search: '',

        // номер текущей страницы, если у списка есть пагинация
        page: null,

        // испольщовать ленивую загрузку
        useLazyLoad,

        useFoldersLazyLoad: !!md.useFoldersLazyLoad && !noHierarchy,

        // Количество элементов на стнраницу
        elementsByPage: ELEMENTS_BY_PAGE,

        // диапазон загруженных элементов
        elementsRange: new Map(),

        // все элементы загружены
        allItemsLoaded: false,

        // Период по уморлчанию
        period: new Map({ StartDate, EndDate, Variant }),

        // Приоритет сортировки
        // Упорядоченная коллекция названий колонок
        // eslint-disable-next-line
          order: md.defaultOrder.reduce(
          /**
             * @param {Immutable.List} r
             * @param {object} v
             * @return {Immutable.List}
             */
          (r, v) => r.push(v),
          new List(),
        ),
        // показывать удаленные
        showDeleted: false,
        // Начальное значение селектора
        initialValue,
        // Не показывать иерархию
        noHierarchy,
        // Видимые колонки
        visibleColumns,
        // Размеры колонок по умолчанию
        columnSizes,
        // Параметры сесии
        setParams: store.getIn([NODE_NAME, 'setParams'], new Map()),
        /* Признак того, что стор готов к загрузке. Устанавливается после того, как
          были загружены настройки, произошел инит зранилища и т.д.
           */
        readyToLoad: false,
      }),
    });
    dispatch(loadSettings(viewType, modelType, modelName)());
  };
  return middleware;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const setFilter = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;

  /**
   * @func
   * @param {Immutable.Map} newFilters
   * @returns {function(func, func)}
   */
  const handler = (newFilters) => {
    /**
     *
     * @param {func} dispatch
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SET_FILTERS`,
        payload: newFilters,
      });
      dispatch(load(viewType, modelType, name)());
    };
    return middleware;
  };
  return handler;
};

const setParams = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;

  /**
   * @func
   * @param {Immutable.Map} newFilters
   * @returns {function(func, func)}
   */
  const handler = (newParams) => {
    /**
     *
     * @param {func} dispatch
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SET_PARAMS`,
        payload: newParams,
      });
      dispatch(load(viewType, modelType, name)());
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
// eslint-disable-next-line
const clearFilter = (viewType, modelType, modelName) => {
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = () => {
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const search = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = (searchString) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SEARCH`,
        payload: {
          text: searchString,
        },
      });
      dispatch(load(viewType, modelType, modelName)());
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const setOrder = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = (columnName) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const column = meta[modelType][modelName].columns[columnName].name;
    const middleware = (dispatch, getState) => {
      // debugger;
      const order = getState().getIn([NODE_NAME, 'order'], new List());
      const userOrderIndex = order.size ? order.size - 1 : 0;
      const userOrder = order.get(userOrderIndex, { column: '', direction: directions.ascending });
      if (userOrder.column !== column) {
        dispatch({
          type: `@${NODE_NAME}/SET_ORDER`,
          payload: {
            order: order.set(userOrderIndex, { column, direction: directions.ascending }),
          },
        });
      } else {
        dispatch({
          type: `@${NODE_NAME}/SET_ORDER`,
          payload: {
            order: order.set(
              userOrderIndex,
              {
                column,
                direction: userOrder.direction === directions.ascending
                  ? directions.descending
                  : directions.ascending,
              },
            ),
          },
        });
      }
      dispatch(load(viewType, modelType, modelName)());
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
// const changePage = (viewType, modelType, _name) => {
//   const handler = (number) => {
//     /**
//      *
//      * @param {func} dispatch
//      * @param {func} getState
//      */
//     const middleware = (dispatch, getState) => {
//     };
//     return middleware;
//   };
//   return handler;
// };

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */

const decPage = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      const useLazyLoad = getState().getIn([NODE_NAME, 'useLazyLoad'], false);
      const loadedAll = getState().getIn([NODE_NAME, 'allItemsLoaded'], true);
      if (useLazyLoad && !loadedAll) {
        dispatch(load(viewType, modelType, modelName)(false));
      }
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const removeErr = (viewType, modelType, _name) => {
  const NODE_NAME = `${modelType}/${_name}/${viewType}`;
  const handler = (errMessage) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/REMOVE_ERROR`,
        payload: errMessage,
      });
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const removeDone = (viewType, modelType, _name) => {
  const NODE_NAME = `${modelType}/${_name}/${viewType}`;
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/REMOVE_DONE`,
      });
      dispatch(load(viewType, modelType, _name)());
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const remove = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      dispatch({
        type: `@${NODE_NAME}/REMOVE_START`,
      });
      const vi = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap());
      const activeId = vi.filter((i) => i.get('ACTIVE', false)).keySeq().first();
      const md = meta[modelType][modelName];
      api.delete$(`${modelType}/${md.backendName}/${activeId}/`)
        .then((r) => {
          if (r.ok) {
            dispatch(removeDone(viewType, modelType, modelName)());
          } else {
            r.text()
              .then((t) => dispatch(removeErr(viewType, modelType, modelName)(t)))
              .catch((error) => dispatch(removeErr(viewType, modelType, modelName)(error.message)));
          }
        })
        .catch((error) => {
          dispatch(removeErr(viewType, modelType, modelName)(error.message));
        });
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const select = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  /**
   * @func
   * @param {string} itemId
   * @param {bool} deselectOthers
   * @return {function}
   */
  const handler = (itemId, deselectOthers = true) => {
    /**
     * @func
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      // const middleware = (dispatch, getState) => {
      // /**
      //  * @const
      //  * @type {Immutable.Map}
      //  */
      // const store = getState();
      //
      // /**
      //  * @const
      //  * @type {Immutable.Map}
      //  */
      // const node = store.get(NODE_NAME);
      const visibleItems = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap());
      dispatch({
        type: `@${NODE_NAME}/SELECT`,
        payload: {
          visibleItems: (deselectOthers ? visibleItems.map((v) => v.delete('ACTIVE')) : visibleItems)
            .setIn([itemId, 'ACTIVE'], true),
        },
      });
    };

    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const toggle = (viewType, modelType, name) => {
  const handler = (_id) => {
    /**
     *
     * @param dispatch
     * @param {func} getState
     */
    const middleware = (dispatch, getState) => {
      const NODE_NAME = `${modelType}/${name}/${viewType}`;
      const store = getState().get(NODE_NAME);
      const vi = store.get('visibleItems', new OrderedMap());
      const md = meta[modelType][name];
      if (md.hierarchy && (md.hierarchyType === hierarchyTypes.onlyItems || vi.getIn([_id, 'isGroup'], false))) {
        const isOpen = !vi.getIn([_id, 'TOGGLED'], false);

        const newVI = vi.getIn([_id, 'TOGGLED'], false) ? vi.deleteIn([_id, 'TOGGLED']) : vi.setIn([_id, 'TOGGLED'], true);

        const useFoldersLazyLoad = store.get('useFoldersLazyLoad', false);

        const searchString = store.get('search', '');

        const openedItems = newVI.filter((el) => el.get('TOGGLED')).reduce((r, v) => (
          r.has(v.get('parent')) || !v.get('parent', false) ? r.set(v.get('id'), v) : r
        ), new OrderedMap());
        if (isOpen && useFoldersLazyLoad && !vi.filter((v) => v.get('parent') === _id).size && !searchString) {
          dispatch(load(viewType, modelType, name)(false, _id, openedItems));
        } else {
          dispatch({
            type: `@${NODE_NAME}/TOGGLE`,
            payload: {
              visibleItems: generateVisibleItems(
                getState().getIn([NODE_NAME, 'items'], new OrderedMap()),
                openedItems,
              ),
            },
          });
        }
      }
    };

    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const toggleShowDeleted = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = () => {
    const middleware = (dispatch, getState) => {
      dispatch({
        type: `@${NODE_NAME}/TOGGLE_SHOW_DELETED`,
        payload: {
          showDeleted: !getState().getIn([NODE_NAME, 'showDeleted'], false),
        },
      });
      dispatch(load(viewType, modelType, name)());
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const setPeriod = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (period) => {
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SET_PERIOD`,
        payload: { period },
      });
      dispatch(load(viewType, modelType, name)());
    };
    return middleware;
  };
  return handler;
};

const newItem = (viewType, modelType, name) => {
  const handler = () => {
    const middleware = (navigate) => {
      const md = meta[modelType][name];
      navigate(`/${md.frontend}/create/`);
    };
    return middleware;
  };
  return handler;
};

const newGroup = (viewType, modelType, modelName) => {
  const handler = (navigate) => {
    const middleware = () => {
      const md = meta[modelType][modelName];
      navigate(`/${md.frontend}/createGroup/`);
    };
    return middleware;
  };
  return handler;
};

const editItem = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (navigate) => {
    const middleware = (dispatch, getState) => {
      const vi = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap());
      const activeId = vi.filter((i) => i.get('ACTIVE', false)).keySeq().first();
      const md = meta[modelType][name];
      const url = `/${md.frontend}/${activeId}/`;
      if (viewType === 'selector') {
        const path = createPath({ pathname: url });
        window.open(path, '_blank');
      } else {
        navigate(url);
      }
    };
    return middleware;
  };
  return handler;
};

const postErr = (viewType, modelType, _name) => {
  const NODE_NAME = `${modelType}/${_name}/${viewType}`;
  const handler = (errMessage) => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SAVE_ERROR`,
        payload: errMessage,
      });
    };
    return middleware;
  };
  return handler;
};

const postDone = (viewType, modelType, _name) => {
  const NODE_NAME = `${modelType}/${_name}/${viewType}`;
  const handler = () => {
    /**
     *
     * @param {func} dispatch
     * @param {func} getState
     */
    const middleware = (dispatch) => {
      dispatch({
        type: `@${NODE_NAME}/SAVE_DONE`,
      });
      dispatch(load(viewType, modelType, _name)());
    };
    return middleware;
  };
  return handler;
};

const postItem = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  const handler = (savemode) => {
    const middleware = (dispatch, getState) => {
      dispatch({
        type: `@${NODE_NAME}/SAVE_START`,
      });
      const vi = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap());
      const activeId = vi.filter((i) => i.get('ACTIVE', false)).keySeq().first();
      const md = meta[modelType][modelName];
      api.put$(`${modelType}/${md.backendName}/${activeId}/`, { savemode })
        .then((r) => {
          if (r.ok) {
            dispatch(postDone(viewType, modelType, modelName)());
          } else {
            r.text()
              .then((t) => dispatch(postErr(viewType, modelType, modelName)(t)))
              .catch((error) => dispatch(postErr(viewType, modelType, modelName)(error.message)));
          }
        })
        .catch((error) => {
          dispatch(postErr(viewType, modelType, modelName)(error.message));
        });
    };
    return middleware;
  };
  return handler;
};

const copyItem = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  const handler = (navigate) => {
    const middleware = (dispatch, getState) => {
      const vi = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap());
      const activeId = vi.filter((i) => i.get('ACTIVE', false)).keySeq().first();
      const md = meta[modelType][name];
      navigate(`/${md.frontend}/create/?copyFrom=${activeId}`);
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const getFile = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  /**
   *
   * @param paramGetter {function}
   * @return {function(*=)}
   */

  const handler = (paramGetter) => {
    const middleware = (dispatch, getState) => {
      api.post$(`${modelType}/${meta[modelType][name].backendName}/GetFile`, paramGetter(getState().get(NODE_NAME)))
        .then((r) => {
          if (r.ok) {
            r.json()
              .then((d) => dispatch(notitfierActions.newFile(d.filename, d.content, d.type, d.description)))
              .catch((error) => dispatch(loadErr(viewType, modelType, name)(error.message)));
          } else {
            r.text()
              .then((t) => dispatch(loadErr(viewType, modelType, name)(t)))
              .catch((error) => dispatch(loadErr(viewType, modelType, name)(error.message)));
          }
        })
        .catch((error) => {
          dispatch(removeErr(viewType, modelType, name)(error.message));
        });
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} name
 * @return {function}
 */
const setColumnSize = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  /**
   *
   * @param columnName {string}
   * @param newSize {string}
   * @return {function(*=)}
   */

  const handler = (columnName, newSize) => {
    const middleware = (dispatch, getState) => {
      const columnSizes = getState().getIn([NODE_NAME, 'columnSizes'], new Map());
      dispatch({
        type: `@${NODE_NAME}/RESIZE_COLUMN`,
        payload: {
          columnSizes: columnSizes.setIn([columnName, 'size'], newSize),
        },
      });
    };
    return middleware;
  };
  return handler;
};

const setParam = (viewType, modelType, name) => {
  const NODE_NAME = `${modelType}/${name}/${viewType}`;
  /**
   *
   * @param paramName {string}
   * @param value {map}
   * @return {function(*=)}
   */

  // debugger;

  const handler = (paramName, value) => {
    const middleware = (dispatch, getState) => {
      const setParams = getState().getIn([NODE_NAME, 'setParams'], new Map());
      dispatch({
        type: `@${NODE_NAME}/SET_PARAMS`,
        payload: { setParams: setParams.setIn([paramName], value) },
      });
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const resetColumnSize = (viewType, modelType, modelName) => {
  /**
   *
   * @param columnName {string}
   * @return {function(*=)}
   */

  const handler = (columnName) => {
    const middleware = (dispatch) => {
      const md = meta[modelType][modelName];
      const newSize = md.columns[columnName].width || '1fr';
      dispatch(setColumnSize(viewType, modelType, modelName)(columnName, newSize));
    };
    return middleware;
  };
  return handler;
};

/**
 * @func
 * @param {string} viewType
 * @param {string} modelType
 * @param {string} modelName
 * @return {function}
 */
const saveSettings = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  /**
   *
   * @return {function(*=)}
   */

  const handler = () => {
    const middleware = (dispatch, getState) => {
      const node = getState().get(NODE_NAME, new Map());
      const settingsName = getSettingsName(modelType, modelName, viewType);
      const settings = {
        columnSizes: node.get('columnSizes', new Map()).map((c) => c.get('size')).toJS(),
      };
      if ((modelType === 'cat') || (modelType === 'ChTP')) {
      } else {
        settings.period = node.get('period', new Map()).toJS();
      }

      api.put$(`settings/${settingsName}`, settings)
        .catch((error) => {
          dispatch(loadErr(viewType, modelType, modelName)(error.message));
        });
    };
    return middleware;
  };
  return handler;
};

const moveItems = (viewType, modelType, modelName) => {
  const NODE_NAME = `${modelType}/${modelName}/${viewType}`;
  /**
   *
   * @return {function(*=)}
   */

  const handler = (newFolder) => {
    const middleware = (dispatch, getState) => {
      const selectiedItems = getState().getIn([NODE_NAME, 'visibleItems'], new OrderedMap()).filter((item) => item.get('ACTIVE', false)).keySeq();
      api.patch$(`${modelType}/${meta[modelType][modelName].backendName}`, {
        method: 'MOVE',
        items: selectiedItems,
        to: newFolder,
      })
        .then((r) => {
          if (r.ok) {
            dispatch(load(viewType, modelType, modelName)());
          } else {
            dispatch(loadErr(viewType, modelType, modelName)(`${r.status} ${r.statusText}`));
          }
        })
        .catch((error) => {
          dispatch(loadErr(viewType, modelType, modelName)(error.message));
        });
    };
    return middleware;
  };
  return handler;
};

/**
 * @const
 * @param {object<object>} cat
 * @param {object<object>} ChTP
 * @param {object<object>} doc
 */

// Повбивав би за такий код !!!
const listerActions = new Proxy(
  {},
  {
    /**
     * @param {object} __
     * @param {string} viewType
     * @return {object}
     */
    get(__, viewType) {
      return new Proxy(
        {},
        {
          /**
           * @param {object} target
           * @param {string} modelType
           * @return {object}
           */
          get(target, modelType) {
            /**
             * @param {string} modelName
             * @return {object<func>}
             */
            return new Proxy(
              {},
              {
                get(_, modelName) {
                  return {
                    init: init(viewType, modelType, modelName),
                    load: load(viewType, modelType, modelName),
                    loadDone: loadDone(viewType, modelType, modelName),
                    loadErr: loadErr(viewType, modelType, modelName),
                    remove: remove(viewType, modelType, modelName),
                    removeDone: removeDone(viewType, modelType, modelName),
                    removeErr: removeErr(viewType, modelType, modelName),
                    select: select(viewType, modelType, modelName),
                    toggle: toggle(viewType, modelType, modelName),
                    setFilter: setFilter(viewType, modelType, modelName),
                    setParams: setParams(viewType, modelType, modelName),
                    toggleShowDeleted: toggleShowDeleted(viewType, modelType, modelName),
                    search: search(viewType, modelType, modelName),
                    setOrder: setOrder(viewType, modelType, modelName),
                    decPage: decPage(viewType, modelType, modelName),
                    setPeriod: setPeriod(viewType, modelType, modelName),
                    newItem: newItem(viewType, modelType, modelName),
                    newGroup: newGroup(viewType, modelType, modelName),
                    editItem: editItem(viewType, modelType, modelName),

                    postItem: postItem(viewType, modelType, modelName),

                    copyItem: copyItem(viewType, modelType, modelName),
                    getFile: getFile(viewType, modelType, modelName),
                    setParam: setParam(viewType, modelType, modelName),
                    setColumnSize: setColumnSize(viewType, modelType, modelName),
                    resetColumnSize: resetColumnSize(viewType, modelType, modelName),
                    saveSettings: saveSettings(viewType, modelType, modelName),
                    loadSettings: loadSettings(viewType, modelType, modelName),
                    moveItems: moveItems(viewType, modelType, modelName),
                    clearResultNode: clearResultNode(viewType, modelType, modelName),
                  };
                },
              },
            );
          },
        },
      );
    },
  },
);

export default listerActions;
