import React, { useCallback, useEffect, useRef } from 'react';

const useDiagramShower = ({ onResize = null }) => {
  const containerChartRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = React.useState(false);

  const onResizeHandler = useCallback(
    () => {
      const { width, height } = containerChartRef.current.getBoundingClientRect();
      if ((width || height) && !show) {
        setShow(true);
      }
      if (onResize) onResize(containerChartRef.current);
    },
    [onResize, show],
  );
  const observer = useRef(new ResizeObserver(onResizeHandler));

  useEffect(() => {
    if (containerChartRef.current) {
      observer.current.observe(containerChartRef.current);
    }
    return () => {
      if (containerChartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(containerChartRef.current);
      }
    };
  }, []);
  return { containerChartRef, show };
};

export default useDiagramShower;
