import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import PrintingEditor from '../../../dataProcessors/prepareForecastAdd2/editor';
import { mapState } from '../../../dpEditor/basic';
import { emptyUid } from '../../../../constants/meta/common';

const mapInitialOptions = {
  Document: 'ДокументСсылка',
};
function PrintModal({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const { headerForm } = useSelector(mapState);

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        setOpened(false);
      }
    },
    [logikaId],
  );

  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );
  const onClose = useCallback(
    () => setOpened(false),
    [],
  );
  return (
    <>
      <Dropdown.Item onClick={() => setOpened(true)} disabled={selectedRows.length === 0}>
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Друкована форма
      </Dropdown.Item>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друкована форма
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintingEditor
            mapInitialOptions={mapInitialOptions}
            mapStore={mapStore}
            onClose={onClose}
          />

        </Modal.Body>
      </Modal>
    </>
  );
}

PrintModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

PrintModal.defaultProps = {
  selectedRows: [],
};

export default PrintModal;
