import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import md from '../../../../constants/meta/catalogs/kekvClassifierShort';
import KEKVTP from './tabs/fulKEKV';

function TablesRender({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="fulKEKV">
      <Tab title={md.tables.fulKEKV.label} eventKey="fulKEKV">
        <Container fluid className="border border-top-0">
          <KEKVTP data={data} actions={actions} readOnly={readOnly} />
        </Container>
      </Tab>
    </Tabs>
  );
}

TablesRender.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default TablesRender;
