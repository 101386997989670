const frontendURL = '/doc/assignmentPlanningRev/';
const backendURL = '/api/authorityplans/revenueplanheader/';
// const printURL = '';
const name = 'Розпис доходів';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
