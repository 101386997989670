import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { referencePropType } from '../../newEditor/propTypes';
import enums from '../../../constants/meta/enums';
import { useEditor } from '../../newEditor';
import { modelName, modelType } from './def';
import md from '../../../constants/meta/dataprocessors/docSelectionUpLevel';
import { soSelector } from '../../documents/_common/selectors';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import EditorWrapper from '../../../components/bootstrap_components/editor';
import Documents from './tablepart';

const planingKindsOptions = Object.values(enums.PlaningKind)
  .map(({ name, label }) => ({ value: name, display_name: label }));

function DocSelectionUpLevelBoostraped({
  onLoad, CSU, year, fondObject, FI, planingMode, dataSource,
}) {
  const {
    data,
    loading,
    err,
    actions: { onSR, onChange, onErr },
  } = useEditor({
    modelType,
    modelName,
  });
  const tableData = data[md.tables.data.name];
  useEffect(
    () => {
      onSR('INIT', {
        [md.columns.planingMode.name]: planingMode,
        [md.columns.DataSource.name]: dataSource,
        [md.columns.CSU.name]: CSU,
        [md.columns.FI.name]: FI,
        [md.columns.elementFond.name]: fondObject,
        [md.columns.year.name]: year,
      });
    },
    [CSU, FI, dataSource, fondObject, onSR, planingMode, year],
  );

  const so = useSelector(soSelector);
  const isAdmin = so.get('is_admin', false);
  const dataFi = data[md.columns.FI.name];
  const csuFilter = useMemo(
    () => [{ fieldName: 'parent', value: dataFi }],
    [dataFi],
  );
  const elementFondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: dataFi }],
    [dataFi],
  );

  const onOk = useCallback(
    () => onLoad({
      documents: tableData
        .filter((d) => d[md.tables.data.columns.Check.name])
        .map((d) => d[md.tables.data.columns.Document.name]), // DocumentRep
      csu: data[md.columns.CSU.name],
      period: data[md.columns.period.name],
    }),
    [data, onLoad, tableData],
  );

  return (
    <EditorWrapper loading={loading} onErrorDismis={() => onErr(null)} err={err}>
      <Card.Body>
        <Container fluid>
          {isAdmin && (
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.FI.label}
                  required
                  modelType="cat"
                  modelName="csu"
                  value={data[md.columns.FI.name]}
                  onChange={(e, value) => onChange({
                    [md.columns.FI.name]: value,
                    [md.columns.CSU.name]: null,
                    [md.columns.elementFond.name]: null,
                    [md.columns.elementKFK.name]: null,
                  })}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <EditorControls.PeriodInput
                label={md.columns.period.label}
                required
                value={data[md.columns.period.name]}
                onChange={(e, v) => onChange({ [md.columns.period.name]: v })}
              />
            </Col>
            <Col>
              <EditorControls.SelectorInput
                label={md.columns.planingMode.label}
                required
                value={data[md.columns.planingMode.name]}
                values={planingKindsOptions}
                onChange={async (e, v) => {
                  await onChange(
                    { [md.columns.planingMode.name]: v },
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <EditorControls.ItemPicker
                label={md.columns.CSU.label}
                modelType="cat"
                modelName="csu"
                value={data[md.columns.CSU.name]}
                onChange={(e, value) => onChange({
                  [md.columns.CSU.name]: value,
                })}
                filter={csuFilter}
                noHierarchy
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <EditorControls.ItemPicker
                label={md.columns.elementFond.label}
                modelType="cat"
                modelName="elementFond"
                value={data[md.columns.elementFond.name]}
                onChange={(e, value) => onChange({
                  [md.columns.elementFond.name]: value,
                })}
                filter={elementFondFilter}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <EditorControls.ItemPicker
                label={md.columns.KBP.label}
                modelType="cat"
                modelName="kbpClassifier"
                value={data[md.columns.KBP.name]}
                onChange={(e, value) => onChange({
                  [md.columns.KBP.name]: value,
                })}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <EditorControls.ItemPicker
                label={md.columns.KBP7.label}
                modelType="cat"
                modelName="kbp7Classifier"
                value={data[md.columns.KBP7.name]}
                onChange={(e, value) => onChange({
                  [md.columns.KBP7.name]: value,
                })}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Body className="pt-0">
        <Documents onChange={onChange} data={tableData} onSR={onSR} onOk={onOk} />
      </Card.Body>
      {!isAdmin && (
        <Card.Footer>
          <FontAwesomeIcon icon={faCity} className="me-2" />
          {data[md.columns.FI.name] && data[md.columns.FI.name].repr}
        </Card.Footer>
      )}
    </EditorWrapper>
  );
}

DocSelectionUpLevelBoostraped.propTypes = {
  onLoad: PropTypes.func.isRequired,
  CSU: referencePropType,
  year: PropTypes.number,
  fondObject: referencePropType,
  FI: referencePropType,
  planingMode: PropTypes.number,
  dataSource: PropTypes.oneOf([
    'АссигнованияПлан', 'АссигнованияУточнения',
    'АссигнованияПланВременный', 'АссигнованияУточненияВременный',
  ]).isRequired,
};

DocSelectionUpLevelBoostraped.defaultProps = {
  CSU: null,
  year: null,
  fondObject: null,
  FI: null,
  planingMode: 0,
};

export default DocSelectionUpLevelBoostraped;
