import React, { forwardRef, useContext, useState } from 'react';

import CatListerCommandPanel from '../../../newLister/tableComponent/commandpanel/cat_panel';
import meta from '../../../../constants/meta/catalogs/planing';
import ListerContext from '../../../newLister/context';

import YouTubeButtom from '../../../newLister/tableComponent/commandpanel/youtube/youtubeButton';
import YoutubeVideo from '../../../newLister/tableComponent/commandpanel/youtube/youtubeVideo';

const videoId = meta.columns.refYoutube && meta.columns.refYoutube.value;

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      <CatListerCommandPanel
        ref={ref}
      >
        <YouTubeButtom
          onClick={() => setShowVideo(!showVideo)}
          videoId={videoId}
        />
      </CatListerCommandPanel>

      <YoutubeVideo
        showVideo={showVideo}
        videoId={videoId}
      />
    </>

  );
});

export default CommandPanel;
