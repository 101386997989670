import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  selDate: { label: 'Дата', name: 'выбДата' },
  selCSU: { label: 'Виконавець', name: 'выбИсполнитель' },
  year: { label: 'Рік', name: 'Год' },
  baseLaw: { label: 'Законодавчі підстави для виконання бюджетної програми', name: 'ЗаконОснование' },
  KBP: { label: 'Бюджетна програма', name: 'КлассификаторКБП' },
  pass: { label: 'Пар пароль', name: 'ПарПароль' },
  port: { label: 'Пар порт', name: 'ПарПорт' },
  signVariant: { label: 'Варіант підпису', name: 'Подпись' },
  law: { label: 'Наказ', name: 'Приказ1' },
  lawStr: { label: 'Наказ', name: 'Приказ1Стр' },
  law2: { label: 'Наказ', name: 'Приказ2' },
  law2Str: { label: 'Наказ', name: 'Приказ2Стр' },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  typeKFK: { label: 'Фл вид КФК', name: 'флВидКФК' },
  printKBP: { label: 'Друкувати назву бюджетної програми за довідником КБП', name: 'флПечатьНаимКБП' },
  printSession: { label: 'Друкувати мету та назву за рішенням сесії', name: 'флПечатьРеквизитовПрогИзГлРасп' },
  printSubProg: { label: 'Друкувати підпрограми', name: 'флПечатьТабл7' },
  ftpAdress: { label: 'ФТПАдресс', name: 'ФТПАдресс' },
  ftpLogin: { label: 'ФТПЛогин', name: 'ФТПЛогин' },
  targ: { label: 'Мета', name: 'Цель' },
  elKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  fltarg: { label: 'Деталізувати цільові програми', name: 'флЦелевыеПрогПоЭлФКФ' },
  flPages: { label: 'Друкувати номера сторінок', name: 'флПечатьНомераСтранины' },
  flOrder: { label: 'Фл печать приказ из паспорта', name: 'флПечатьПриказИзПаспорта' },
  flDate: { label: 'Дата на яку друкується документ', name: 'ДатаПечати' },
  fltargPrint: { label: 'Друкувати цільові програми (для випадку коли включена підсистема "Об\'єкти будівництва")', name: 'флПечатьЦелевыеПрограммы' },
  flHrn: { label: 'Друкувати в грн.', name: 'флгрн' },
  document: { label: 'Паспорт (проект)', name: 'ДокументСсылка' },
  document2: { label: 'Затверджено паспорт', name: 'ДокументСсылка2' },
  apprr: { label: 'Погоджено', name: 'Погоджено' },
  ord: { label: 'Паспорт затверджуєтся розпорядженням', name: 'Приказ_Распоряжение' },
  flNumb: { label: 'Друкувати реквизити документів', name: 'флПечатьНомеровДок' },
};

const definition = {
  label: 'Звіт "Порівняння паспортів бюджетної програми"',
  name: 'ComparePassport',
  columns,
  frontend: 'rep/ComparePassport',
  backendName: 'СравнениеПаспортов',
  resultColumns: baseResultColumns,
};

export default definition;
