import React, {
  Suspense, useEffect, useMemo,
} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import styled from 'styled-components';

import { compose } from 'redux';
// import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { ContainerButtonTop } from '../../tableEditor/styles';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';

const MainContainer = styled.div`
  position: relative;
`;

const md = meta.dp.printPayment;

function PrintPayment() {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      dispatch(actions.processServerReq('PRINT'));
    },
    [dispatch, docId],
  );

  return (
    <MainContainer>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
      </ContainerButtonTop>
      <Suspense fallback={<div>Завантаження...</div>}>
        <TableEditor mountNode={resultMountNode} />
      </Suspense>
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enchance(PrintPayment);
