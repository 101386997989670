import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ErrorDiv = styled.div`
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  background-color: #fff6f6;
  color: #9f3a38;
  padding: 1em 1.5em;
  margin: 1em 0;
  border-radius: 0.5em;
`;

const ErrorHeader = styled.header`
  font-weight: 700;
`;

const ErrorText = styled.p`
  white-space: pre-wrap
`;

export function ErrorMessage({ header, text }) {
  return (
    <ErrorDiv>
      <ErrorHeader>{header}</ErrorHeader>
      <ErrorText>{text}</ErrorText>
    </ErrorDiv>
  );
}

ErrorMessage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
};

ErrorMessage.defaultProps = {
  header: 'Вам все вдалося, але виник несподіваний результат',
  text: 'Та ще й розробники забули щось тут написати',
};

export default ErrorMessage;
