/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TableControls } from '../../../../basicEditor/tableControls';
import { RemoveRowButton } from '../../../../../components/bootStrap/buttons';

function ChoicesRow({
  value, label, onChange, onDelete, canDelete, rowIndex, ctype,
}) {
  const onRowChange = useCallback(
    (e, p) => onChange(e, rowIndex, p),
    [onChange, rowIndex],
  );
  return (
    <tr>
      <td>
        <TableControls.MultiValueInput
          ctype={ctype}
          value={value}
          onChange={(e, v) => onRowChange(e, [v, label])}
        />
      </td>
      <td>
        <TableControls.StringInput
          value={label}
          onChange={(e, v) => onRowChange(e, [value, v])}
        />
      </td>
      <td>
        <RemoveRowButton
          disabled={!canDelete}
          onClick={(e) => onDelete(e, rowIndex)}
          color="error"
        />
      </td>
    </tr>
  );
}

ChoicesRow.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired,
  ctype: PropTypes.oneOf(['String', 'Date', 'Boolean', 'Integer', 'Decimal']).isRequired,
};

export default memo(ChoicesRow);
