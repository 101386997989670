import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { StrNumberRibbon, StyledTableRow } from './styles';

function TableRow({
  active, children, rowIndex, className, activeCol, tableName,
  onMoveRow,
}) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      if (active && ref.current) {
        const selector = activeCol ? `[data-col="${activeCol}"] input` : 'input';
        const i = ref.current.querySelector(selector);
        if (i) {
          i.focus();
        }
      }
    }
  }, [active, activeCol]);

  const [{ isDragging }, dragRef] = useDrag({
    type: tableName || 'tableName',
    item: { rowIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [tpClass, setTpClass] = useState('');

  const [{ isOverCurrent }, dropRef] = useDrop({
    accept: tableName || 'tableName',
    drop: (item) => {
      if (item.rowIndex !== rowIndex && onMoveRow != null) {
        onMoveRow(item.rowIndex, rowIndex);
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item) => {
      if (item.rowIndex < rowIndex) {
        setTpClass('pb-4');
      } else if (item.rowIndex > rowIndex) {
        setTpClass('pt-4');
      } else {
        setTpClass('');
      }
    },
  });

  const dragDropRef = dragRef(dropRef(ref));
  return (
    <StyledTableRow
      className={classNames('d-flex border-bottom ps-4 position-relative my-1', className, {
        active,
        'opacity-25': isDragging,
        [tpClass]: isOverCurrent,
        rowPadding: true,
      })}
      data-row={rowIndex}
      ref={dragDropRef}
    >
      <StrNumberRibbon>
        {rowIndex + 1}
      </StrNumberRibbon>
      {children}
    </StyledTableRow>
  );
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  activeCol: PropTypes.string,
  tableName: PropTypes.string,
  onMoveRow: PropTypes.func,
};

TableRow.defaultProps = {
  active: false,
  className: '',
  activeCol: null,
  tableName: '',
  onMoveRow: null,
};

export default TableRow;
