import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Card, Col, Container, Row,
} from 'react-bootstrap';
import EditorWrapper from '../../../../../components/bootstrap_components/editor';
import { EditorControls } from '../../../../../components/bootstrap_components/editorControls';
import md from '../../../../../constants/meta/catalogs/objectsBuilding';
import CsusByEdrpou from './tablepart';

const tableMeta = md.tables.csuByEdrpou;

function CSUSelection({
  onLoad, data, actions, readOnly,
}) {
  const tableData = useMemo(() => data[md.tables.csuByEdrpou.name] || [], [data]);

  const onOk = useCallback(
    (rowId) => onLoad({
      [md.columns.CsuOwner.name]: tableData[rowId][tableMeta.columns.Csu.name],
      [md.columns.CsuOwnerName.name]: tableData[rowId][tableMeta.columns.CsuName.name],
    }),
    [tableData, onLoad],
  );

  return (
    <EditorWrapper>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col>
              <Button
                onClick={() => actions.onSR('НайтиПоЕдрпоу')}
                disabled={readOnly}
                className="my-4"
              >
                <FontAwesomeIcon icon={faFileImport} className="me-2" />
                Знайти власника за ЄДРПОУ
              </Button>
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.EDRPOU.label}
                value={data[md.columns.EDRPOU.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.EDRPOU.name]: value,
                })}
                maxLength={10}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Body className="pt-0">
        <CsusByEdrpou
          data={data}
          onOk={onOk}
        />
      </Card.Body>
    </EditorWrapper>
  );
}

CSUSelection.propTypes = {
  onLoad: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default CSUSelection;
