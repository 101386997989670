import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  DateInput,
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/treasuryStatementIncome';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="date" highlighted={highlights.includes('date')}>
              <DateInput
                value={row[tableMD.columns.date.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.date.name]: v },
                )}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="territoryCode" highlighted={highlights.includes('territoryCode')}>
              <StringInput
                value={row[tableMD.columns.territoryCode.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.territoryCode.name]: v },
                )}
                maxLength={3}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableRowCell column="FI" highlighted={highlights.includes('FI')}>
              <ItemPicker
                value={row[tableMD.columns.FI.name]}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.FI.name]: v },
                )}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="GroupSymbols" highlighted={highlights.includes('GroupSymbols')}>
              <StringInput
                value={row[tableMD.columns.GroupSymbols.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.GroupSymbols.name]: v },
                )}
                maxLength={3}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableRowCell column="Fond" highlighted={highlights.includes('Fond')}>
              <ItemPicker
                value={row[tableMD.columns.Fond.name]}
                modelType="cat"
                modelName="elementFond"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.Fond.name]: v },
                )}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="codeKDB" highlighted={highlights.includes('codeKDB')}>
              <StringInput
                value={row[tableMD.columns.codeKDB.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.codeKDB.name]: v },
                )}
                noHierarchy
                maxLength={8}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={5}
            sm={12}
          >
            <TableRowCell column="KDB" highlighted={highlights.includes('KDB')}>
              <ItemPicker
                value={row[tableMD.columns.KDB.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.KDB.name]: v },
                )}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={5}
            sm={12}
          >
            <TableRowCell column="elementKDB" highlighted={highlights.includes('elementKDB')}>
              <ItemPicker
                value={row[tableMD.columns.elementKDB.name]}
                modelType="cat"
                modelName="elementKDB"
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.elementKDB.name]: v },
                )}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumReceived" highlighted={highlights.includes('sumReceived')}>
              <NumberInput
                value={row[tableMD.columns.sumReceived.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumReceived.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumRecDelimitation" highlighted={highlights.includes('sumRecDelimitation')}>
              <NumberInput
                value={row[tableMD.columns.sumRecDelimitation.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumRecDelimitation.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumRecBeginningYear" highlighted={highlights.includes('sumRecBeginningYear')}>
              <NumberInput
                value={row[tableMD.columns.sumRecBeginningYear.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumRecBeginningYear.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumRecBegYearDelimitation" highlighted={highlights.includes('sumRecBegYearDelimitation')}>
              <NumberInput
                value={row[tableMD.columns.sumRecBegYearDelimitation.name]}
                precision={2}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.sumRecBegYearDelimitation.name]: v },
                )}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableRowCell column="reportingSymbol" highlighted={highlights.includes('reportingSymbol')}>
              <StringInput
                value={row[tableMD.columns.reportingSymbol.name]}
                onChange={(e, v) => onRowChange(
                  e,
                  { [tableMD.columns.reportingSymbol.name]: v },
                )}
                maxLength={3}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
