/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Cattablerow } from '../../../newLister/tableComponent/table/row';
import meta from '../../../../constants/meta/catalogs/kbp7Classifier';

function KVKRow({ row, className, ...rest }) {
  const rowClassName = useMemo(
    () => `${row[meta.columns.Inactive.name] ? 'text-danger' : ''} ${className}`,
    [className, row],
  );
  return (
    <Cattablerow row={row} className={rowClassName} {...rest} />
  );
}

KVKRow.propTypes = {
  row: PropTypes.shape({
    [meta.columns.Inactive.name]: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
};

KVKRow.defaultProps = {
  className: '',
};

export default memo(KVKRow);
