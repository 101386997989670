import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import { docComponents } from '../../documents';
import { catComponents } from '../../catalogs';
import Button from '../../../components/styledAssigmButton';
import {
  addComponentToWindowsManager,
  dellComponentFromWindowsManager,
  switchWindow,
} from '../../../actions/windowsManager';
import mdDoc from '../../../constants/meta/documents';
import mdCat from '../../../constants/meta/catalogs';

function SelfTesting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const linkComponents = store.getIn(['windowsManager', 'linkComponents'], new Map());
  const cont = useRef(0);

  const rendrDocList = () => Object.keys(docComponents).map((el) => {
    const currentComponent = docComponents[el].lister;
    const url = `/doc/${el}/`;
    const windowsName = `Список "${mdDoc[el].label}" `;
    const type = 'doc';
    const { name } = mdDoc[el];

    if (!linkComponents.has(url)) {
      dispatch(
        addComponentToWindowsManager({ id: '' }, currentComponent, url, windowsName, type, name),
      );
    }
     return null;
  });

  const rendrCatList = () => Object.keys(catComponents).map((el) => {
    const currentComponent = catComponents[el].lister;
    const url = `/cat/${el}/`;
    const windowsName = `Список "${mdCat[el].label}" `;
    const type = 'cat';
    const { name } = mdCat[el];

    if (!linkComponents.has(url)) {
      dispatch(
        addComponentToWindowsManager({ id: '' }, currentComponent, url, windowsName, type, name),
      );
    }
    return null;
  });

  const rendrCreateDoc = () => {
    const res = Object.keys(docComponents).map((el) => {
      const currentComponent = docComponents[el].editor;
      const url = `/doc/${el}/create/`;
      const windowsName = `${mdDoc[el].label}`;
      const type = 'doc';
      const { name } = mdDoc[el];
      cont.current += 1;

      navigate({ pathname: url });

      dispatch(switchWindow(url));

      dispatch(
        addComponentToWindowsManager({ id: 'create' }, currentComponent, url, windowsName, type, name),
      );
    });
    return res;
  };


  const rendrCreateCat = () => Object.keys(catComponents).map((el) => {
    const currentComponent = catComponents[el].editor;
    const url = `/cat/${el}/create/`;
    const windowsName = `${mdCat[el].label}`;
    const type = 'cat';
    const { name } = mdCat[el];
    cont.current += 1;
    if ((cont.current > 10) && (cont.current <= 11)) {
      console.log('================================================');
      console.log('cont, name, el, url', cont.current, name, el, url);
      navigate({ pathname: url });

      dispatch(switchWindow(url));

      dispatch(
        addComponentToWindowsManager({ id: 'create' }, currentComponent, url, windowsName, type, name),
      );
    }
    // wait(3000);
  });

  const closeAllWin = () => linkComponents.map((comp, path) => {
    if (path !== '/dp/selfTesting/') {
      // console.log('path', path);

      dispatch(dellComponentFromWindowsManager(path));
    }
  });

  const rendrDoc = () => {
    closeAllWin();
    let cont = 0;
    return Object.keys(docComponents).map((el) => {
      const type = 'doc';
      const listDoc = store.getIn([`doc/${el}/lister`, 'items'], new Map());

      const elDoc = listDoc.last();
      const id = elDoc ? elDoc.get('id', 'create') : 'create';
      const { name } = mdDoc[el];

      if (el !== '') {
        const currentComponent = docComponents[el].editor;
        const url = `/${type}/${el}/${id || 'create'}/`;
        const windowsName = `Список "${mdDoc[el].label}" `;

        cont += 1;

        console.log('id, windowsName, url', id, windowsName, url);

        console.log('type, name', type, el);
        console.log('currentComponent', currentComponent);

        navigate({ pathname: url });

        dispatch(switchWindow(url));

        dispatch(
          addComponentToWindowsManager({ id }, currentComponent, url, windowsName, type, name),
        );
      }
    });
  };

  return (
    <>
      <Button
        onClick={() => rendrDocList()}
        title="Doc List"
      >
        Doc List
      </Button>

      <Button
        onClick={() => rendrCreateDoc()}
        title="Doc create"
      >
        Doc create
      </Button>

      <Button
        onClick={() => rendrDoc()}
        title="Doc "
      >
        Doc
      </Button>

      <Button
        onClick={() => rendrCatList()}
        title="Cat List"
      >
        Cat List
      </Button>

      <Button
        onClick={() => rendrCreateCat()}
        title="Cat create"
      >
        Cat create
      </Button>

      <Button
        onClick={() => closeAllWin()}
        title="close all winows"
      >
        close all winows
      </Button>
    </>
  );
}

export default SelfTesting;
