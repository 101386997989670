import React from 'react';
import styled from 'styled-components';

export const ArrowRight = styled.div`
    margin: 0 4px;
    display: inline-block;
`;

function ArrowRightIcon(props) {
  return (
    <ArrowRight {...props}>
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0.5V7.5L5.5 4L0 0.5Z" fill="#4281C9" />
      </svg>
    </ArrowRight>
  );
}

export default ArrowRightIcon;
