import PropTypes from 'prop-types';
import { getFieldsPropTypes } from '../../../../basicEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  id: PropTypes.number.isRequired,
  header: PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }).isRequired,
  disposer: foreignPropType.isRequired,
  kbp7_item: foreignPropType.isRequired,
  kekv_item: foreignPropType.isRequired,
  amount: PropTypes.number.isRequired,
};

export const fieldsPropTypes = PropTypes.shape(
  getFieldsPropTypes(dataPropTypes),
);
