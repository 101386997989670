import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, InputGroup, Badge,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import {
  PrependAccount, PrependFond, PrependKBP7,
} from '../../styles/klassifiers';
import RowSet from './tp/rowset';
import BringDocuments from '../../../catalogs/bringdocument/bringDocuments';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    // eslint-disable-next-line no-unused-vars
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.finobligation.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const onChangeBringDocuments = useCallback(
    (e, v) => actions.onChange({ finobligationbringdocument_set: v }),
    [actions],
  );

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbp7ItemProps = editorHooks.useItemInputProps('kbp7_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const contragentProps = editorHooks.useItemInputProps('contragent', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const accountProps = editorHooks.useItemInputProps('treasure_account', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const lawObligationProps = editorHooks.useItemInputProps('lawobligation', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disclosureRestrictionsProps = editorHooks.useSelectorInputProps('disclosure_restrictions', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const contragentDocDateProps = editorHooks.useDateInputProps('contragent_doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const contragentNumberProps = editorHooks.useStringInputProps('contragent_number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const contragentAccountProps = editorHooks.useItemInputProps('contragent_account', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  //
  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const kbp7ItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const contragentFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const accountFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  const lawObligationFilter = useMemo(
    () => ({ authority: data.authority ? data.authority.id : null }),
    [data.authority],
  );

  const contragentAccountFilter = useMemo(
    () => ({ contragent: data.contragent ? data.contragent.id : null }),
    [data.contragent],
  );

  const displayKind = useMemo(
    () => ((data && fields) ? fields.kind.choices.filter((c) => c.value === data.kind).reduce((R, r) => r.display_name, '') : ''),
    [data, fields],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.finobligation.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <div className="d-flex justify-content-end">
            <Badge variant="info" className="me-2">
              {data.year}
                  &nbsp;р.
            </Badge>
            <Badge variant="warning">{displayKind}</Badge>
          </div>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col xl={2} lg={2}>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col xl={2} lg={3}>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col xl={2} lg={3}>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              <Col xl={3} lg={4}>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  prepend={(
                    <PrependFond>
                      <InputGroup.Text className="w-100">{data.fond && data.fond.code}</InputGroup.Text>
                    </PrependFond>
                  )}
                />
              </Col>
              <Col xl={3} lg={6}>
                <EditorControls.ItemPicker
                  {...kbp7ItemProps}
                  filter={kbp7ItemFilter}
                  prepend={(
                    <PrependKBP7>
                      <InputGroup.Text className="w-100">{data.kbp7 && data.kbp7.repr}</InputGroup.Text>
                    </PrependKBP7>
                  )}
                />
              </Col>
              <Col xl={3} lg={6}>
                <EditorControls.ItemPicker
                  {...contragentProps}
                  filter={contragentFilter}
                />
              </Col>
              <Col xl={3} lg={6}>
                <EditorControls.ItemPicker
                  {...accountProps}
                  filter={accountFilter}
                  prepend={(
                    <PrependAccount>
                      <InputGroup.Text className="w-100">{data.treasure_account && data.treasure_account.code}</InputGroup.Text>
                    </PrependAccount>
                  )}
                />
              </Col>
              <Col xl={3} lg={6}>
                <EditorControls.ItemPicker
                  {...lawObligationProps}
                  filter={lawObligationFilter}
                />
              </Col>
              <Col xl={3} lg={6}>
                <EditorControls.SelectorInput
                  {...disclosureRestrictionsProps}
                />
              </Col>
            </Row>
            <h5>Дані документа</h5>
            <Row>
              <Col xl={2} lg={2}>
                <EditorControls.DateInput {...contragentDocDateProps} />
              </Col>
              <Col xl={3} lg={3}>
                <EditorControls.StringInput {...contragentNumberProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...contragentAccountProps}
                  filter={contragentAccountFilter}
                />
              </Col>
            </Row>

          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="finobligationrow_set">
          {fields && fields.finobligationrow_set && (
          <Tab eventKey="finobligationrow_set" title={fields.finobligationrow_set.label}>
            <TabContainer>
              <RowSet
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          {fields && fields.finobligationrow_set && (
          <Tab eventKey="finobligationbringdocument_set" title={fields.finobligationbringdocument_set.label}>
            <TabContainer>
              <BringDocuments
                data={data.finobligationbringdocument_set}
                allowed={data.allowed_bring_documents}
                onChange={onChangeBringDocuments}
              />
            </TabContainer>
          </Tab>
          )}
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onSave={onSave}
        onClose={onClose}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
