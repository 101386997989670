import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/draftAnnualCredit';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  CSUinH,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        {!CSUinH
        && (
          <Row>
            <Col>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'csu'}
                highlighted={highlights.includes('csu')}
                onToggleHighlght={() => onToggleHighlght('csu')}
              >
                {meta.columns.csu.label}
              </TableHeaderCell>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKVCode'}
              highlighted={highlights.includes('KPKVCode')}
              onToggleHighlght={() => onToggleHighlght('KPKVCode')}
            >
              {meta.columns.KPKVCode.label}
            </TableHeaderCell>
          </Col>
          <Col
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKFK'}
              highlighted={highlights.includes('elementKFK')}
              onToggleHighlght={() => onToggleHighlght('elementKFK')}
            >
              {meta.columns.elementKFK.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col
            className="border-right"
          >
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tProg'}
              highlighted={highlights.includes('tProg')}
              onToggleHighlght={() => onToggleHighlght('tProg')}
            >
              {meta.columns.tProg.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum1'}
              highlighted={highlights.includes('sum1')}
              onToggleHighlght={() => onToggleHighlght('sum1')}
            >
              {meta.columns.sum1.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {meta.columns.zf1.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {meta.columns.sf1.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br1'}
              highlighted={highlights.includes('br1')}
              onToggleHighlght={() => onToggleHighlght('br1')}
            >
              {meta.columns.br1.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum2'}
              highlighted={highlights.includes('sum2')}
              onToggleHighlght={() => onToggleHighlght('sum2')}
            >
              {meta.columns.sum2.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {meta.columns.zf2.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {meta.columns.sf2.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br2'}
              highlighted={highlights.includes('br2')}
              onToggleHighlght={() => onToggleHighlght('br2')}
            >
              {meta.columns.br2.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf'}
              highlighted={highlights.includes('zf')}
              onToggleHighlght={() => onToggleHighlght('zf')}
            >
              {meta.columns.zf.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf'}
              highlighted={highlights.includes('sf')}
              onToggleHighlght={() => onToggleHighlght('sf')}
            >
              {meta.columns.sf.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'br'}
              highlighted={highlights.includes('br')}
              onToggleHighlght={() => onToggleHighlght('br')}
            >
              {meta.columns.br.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  CSUinH: PropTypes.bool,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  CSUinH: false,
};

export default memo(TPHeader);
