/* eslint-disable react/no-array-index-key,no-confusing-arrow */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Button,
  Card,
} from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import FillCoefficientEditor from './fillElementEditor';
import SettingsKEKV from './KEKVsettings';
import TPFooter from './footer';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';

const tableMeta = md.tables.costsKEKV;

function KEKVTP({
  data,
  actions,
  readOnly,
}) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions,
    activeCol,
    activeRow,
    tableContainerRef,
    highlights,
    pinHeader,
    showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });
  // const year = data[md.columns.year.name];
  const FI = data[md.columns.FI.name];
  const [accKey, setAccKey] = useState('');

  const totals = useMemo(
    () => ({
      zf_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf_2.name] || 0), 0),
      sf_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf_2.name] || 0), 0),
      br_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.br_2.name] || 0), 0),
      zf_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf_1.name] || 0), 0),
      sf_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf_1.name] || 0), 0),
      br_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.br_1.name] || 0), 0),
      zf0: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf0.name] || 0), 0),
      sf0: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf0.name] || 0), 0),
      br0: tableData.reduce((R, r) => R + (r[tableMeta.columns.br0.name] || 0), 0),
      zf1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf1.name] || 0), 0),
      sf1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf1.name] || 0), 0),
      br1: tableData.reduce((R, r) => R + (r[tableMeta.columns.br1.name] || 0), 0),
      zf2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf2.name] || 0), 0),
      sf2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf2.name] || 0), 0),
      br2: tableData.reduce((R, r) => R + (r[tableMeta.columns.br2.name] || 0), 0),
    }),
    [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <Accordion activeKey={accKey}>
          <TPCommandPanel
            tableActions={tableActions}
            readOnly={readOnly}
            activeRow={activeRow}
            pinHeader={pinHeader}
            showFooter={showFooter}
          >
            <Button
              variant="primary"
              onClick={() => actions.onSR('FILL_CostsKEKV')}
              className="me-1"
              size="sm"
            >
              Заповнити видатки з прогнозів
            </Button>
            <Button
              variant="primary"
              onClick={() => setAccKey((o) => o !== 'settings-kekv' ? 'settings-kekv' : '')}
              className="me-1"
              size="sm"
            >
              Автоматичне заповнення
            </Button>
            <Button
              variant="success"
              className="me-1"
              onClick={() => setAccKey((o) => o !== 'fill-coefs' ? 'fill-coefs' : '')}
              size="sm"
            >
              Заповнити коеф. окремо
            </Button>

            {/* <Button */}
            {/*  variant="success" */}
            {/*  onClick={() => setAccKey((o) => o ? '' : 'filling-forecasts')} */}
            {/*  className="me-1" */}
            {/*  size="sm" */}
            {/* > */}
            {/*  Заповнення згідно з коефіціентів */}
            {/* </Button> */}
          </TPCommandPanel>
          <Accordion.Collapse eventKey="settings-kekv">
            <SettingsKEKV data={data} actions={actions} readOnly={readOnly} setAccKey={setAccKey} />
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="fill-coefs">
            <FillCoefficientEditor data={data} actions={actions} setAccKey={setAccKey} />
          </Accordion.Collapse>
          {/*  <Accordion.Collapse eventKey="filling-forecasts"> */}
          {/*    <Card className="p-2"> */}
          {/*      <Row> */}
          {/*        <Col sm={6}> */}
          {/*          <Row> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k1.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k1.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year} для ЗФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k2.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k2.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year + 1} для ЗФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k3.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k3.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year + 2} для ЗФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*          </Row> */}
          {/*          <Row> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k1sf.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k1sf.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year} для СФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k2sf.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k2sf.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year + 1} для СФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*            <Col> */}
          {/*              <EditorControls.NumberInput */}
          {/*                value={data[md.columns.k3sf.name]} */}
          {/*                precision={4} */}
          {/*                onChange={(e, value) => actions.onChange({ */}
          {/*                  [md.columns.k3sf.name]: value, */}
          {/*                })} */}
          {/*                label={`Коеф на ${year + 2} для СФ`} */}
          {/*                readOnly={readOnly} */}
          {/*              /> */}
          {/*            </Col> */}
          {/*          </Row> */}
          {/*        </Col> */}
          {/*        <div className="d-flex flex-column justify-content-center me-5"> */}
          {/*          <EditorControls.CheckboxInput */}
          {/*            controlId={`fillK1-${data.id}`} */}
          {/*            value={data[md.columns.fillK1.name]} */}
          {/*            onChange={(e, value) => actions.onChange({ */}
          {/*              [md.columns.fillK1.name]: value, */}
          {/*            })} */}
          {/*            label={`Заповнити ${year}`} */}
          {/*            readOnly={readOnly} */}
          {/*          /> */}
          {/*          <EditorControls.CheckboxInput */}
          {/*            controlId={`fillK2-${data.id}`} */}
          {/*            value={data[md.columns.fillK2.name]} */}
          {/*            onChange={(e, value) => actions.onChange({ */}
          {/*              [md.columns.fillK2.name]: value, */}
          {/*            })} */}
          {/*            label={`Заповнити ${year + 1}`} */}
          {/*            readOnly={readOnly} */}
          {/*          /> */}
          {/*          <EditorControls.CheckboxInput */}
          {/*            controlId={`fillK3-${data.id}`} */}
          {/*            value={data[md.columns.fillK3.name]} */}
          {/*            onChange={(e, value) => actions.onChange({ */}
          {/*              [md.columns.fillK3.name]: value, */}
          {/*            })} */}
          {/*            label={`Заповнити ${year + 2}`} */}
          {/*            readOnly={readOnly} */}
          {/*          /> */}
          {/*        </div> */}
          {/*        <div className="d-flex flex-column justify-content-center"> */}
          {/*          <CommandPanelColor */}
          {/*            label="Заповнити по рокам" */}
          {/*            onClick={() => actions.onSR('FILL_SUM_FOR_THREE_YEARS')} */}
          {/*          /> */}
          {/*        </div> */}
          {/*      </Row> */}
          {/*    </Card> */}
          {/*  </Accordion.Collapse> */}
        </Accordion>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          year={data[md.columns.year.name]}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            FI={FI}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

KEKVTP.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};
KEKVTP.defaultProps = {
  readOnly: false,
};
export default memo(KEKVTP);
