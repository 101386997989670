import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/incomingMoney';

const meta = md.tables.accClose;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={6} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'CSUAccount'}
              highlighted={highlights.includes('CSUAccount')}
              onToggleHighlght={() => onToggleHighlght('CSUAccount')}
            >
              {meta.columns.CSUAccount.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KPKV'}
              highlighted={highlights.includes('KPKV')}
              onToggleHighlght={() => onToggleHighlght('KPKV')}
            >
              {meta.columns.KPKV.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KEKV'}
              highlighted={highlights.includes('KEKV')}
              onToggleHighlght={() => onToggleHighlght('KEKV')}
            >
              {meta.columns.KEKV.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KDB'}
              highlighted={highlights.includes('KDB')}
              onToggleHighlght={() => onToggleHighlght('KDB')}
            >
              {meta.columns.KDB.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumIn'}
              highlighted={highlights.includes('SumIn')}
              onToggleHighlght={() => onToggleHighlght('SumIn')}
            >
              {meta.columns.SumIn.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumOut'}
              highlighted={highlights.includes('SumOut')}
              onToggleHighlght={() => onToggleHighlght('SumOut')}
            >
              {meta.columns.SumOut.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumGet'}
              highlighted={highlights.includes('SumGet')}
              onToggleHighlght={() => onToggleHighlght('SumGet')}
            >
              {meta.columns.SumGet.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumDel'}
              highlighted={highlights.includes('SumDel')}
              onToggleHighlght={() => onToggleHighlght('SumDel')}
            >
              {meta.columns.SumDel.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'Sum'}
              highlighted={highlights.includes('Sum')}
              onToggleHighlght={() => onToggleHighlght('Sum')}
            >
              {meta.columns.Sum.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'SumNatural'}
              highlighted={highlights.includes('SumNatural')}
              onToggleHighlght={() => onToggleHighlght('SumNatural')}
            >
              {meta.columns.SumNatural.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /

};

export default memo(TPHeader);
