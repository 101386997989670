import React, {
  useEffect, useMemo,
} from 'react';

import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  TwoColumnsGridContainer, ThreeColumnsGridContainer, DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import enums from '../../../constants/meta/enums';
import InputWithDropdown from '../../../components/styledInputs/inputWithDropdown';
import DpContainer from '../dpContainer';
import { MainContainer, ResultSegment } from '../styledComponentsDp';

const md = meta.dp.planOfUsePrint;

const SixColumnsGrid = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%;
`;

const ReportTypes = {
  plan: { label: 'Розпис', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'Розпис+уточнення', value: 2 },
};

const StringInput4 = Fields.GetTextInput(4);

const FundsTypes = {
  none: { label: 'Не враховувати', value: 0 },
  assign: { label: 'Асигнування', value: 1 },
  credit: { label: 'Кредитування', value: 2 },
};

const TypeVariantKEKV = {
  details: { label: 'Деталізацією КЕКВ', name: 0 },
  full: { label: 'Повним КЕКВ', name: 1 },
  short: { label: 'Скороченим КЕКВ', name: 2 },
};

const ShowCSU = {
  mainCSU: { label: 'Гол.Розпорядник', name: 0 },
  csu: { label: 'Розпорядник', name: 1 },
  kvk: { label: 'КВК', name: 2 },
};

const OneColumnsGridContainer = styled(TwoColumnsGridContainer)`
  grid-template-columns: 40%;
  grid-column-gap: 5%;
`;

function PlanOfUsePrintEditor() {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );

  const Quarter = (headerForm.get('planType') === 1) && (
    <div>
      <StyledLabel>{md.columns.quarter.label}</StyledLabel>
      <InputWithDropdown
        value={headerForm.get('quarter')}
        options={Object.values(enums.QuartalVariants)}
        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'quarter'], v))}
      />
    </div>
  );

  const ByReducedKEKV = (headerForm.get('typeVariantKEKV') === 2) && (
    <Fields.CheckboxField
      value={headerForm.get('byReducedKEKV', false)}
      label={md.columns.byReducedKEKV.label}
      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'byReducedKEKV'], v))}
    />
  );

  const changeDetailsKEKV = () => {
    dispatch(actions.changeField(['headerForm', 'byShortKEKV'], headerForm.get('byDetailsKEKV', false)));
    dispatch(actions.changeField(['headerForm', 'byDetailsElKEKV'], headerForm.get('byDetailsKEKV', false)));
  };

  const ByDetailsKEKV = (headerForm.get('typeVariantKEKV') !== 0) && (
    <Fields.CheckboxField
      value={headerForm.get('byDetailsKEKV', false)}
      label={md.columns.byDetailsKEKV.label}
      onChange={(e, v) => {
        dispatch(actions.changeField(['headerForm', 'byDetailsKEKV'], v));
        changeDetailsKEKV();
      }}
    />
  );

  const AllKEKV = (headerForm.get('typeVariantKEKV') !== 0) && (
    <Fields.CheckboxField
      value={headerForm.get('allKEKV', false)}
      label={md.columns.allKEKV.label}
      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'allKEKV'], v))}
    />
  );

  const ByShortKEKV = (headerForm.get('typeVariantKEKV') === 2) && (
    <Fields.CheckboxField
      value={headerForm.get('byShortKEKV', false)}
      label={md.columns.byShortKEKV.label}
      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'byShortKEKV'], v))}
    />
  );

  const reportVariants = headerForm.get('reportVariants', new Map());

  const SettingsButton = (
    <div>
      <DivWithPropsLabel label="Основні налаштування">
        <OneColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('SignVariant', new Map())}
              modelType="cat"
              modelName="signatureVariants"
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
              filter={[
                { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
              ]}
            />
          </div>
        </OneColumnsGridContainer>
        <SixColumnsGrid>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => (
                { label: _e.label, value: _e.name }
              ))}
              name="planType"
            />
          </div>
          <div>
            <div>
              <StyledLabel>{md.columns.FundsType.label}</StyledLabel>
              <RadioButton
                value={headerForm.get('FundsType', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FundsType'], v))}
                values={Object.values(FundsTypes)}
                name="FundsType"
              />
            </div>
            <div>
              <StyledLabel>{md.columns.typeVariantKEKV.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('typeVariantKEKV')}
                options={Object.values(TypeVariantKEKV)}
                onChange={(e, v) => {
                  dispatch(actions.changeField(['headerForm', 'typeVariantKEKV'], v));
                  if (v === 0) {
                    dispatch(actions.changeField(['headerForm', 'allKEKV'], false));
                    dispatch(actions.changeField(['headerForm', 'byDetailsKEKV'], false));
                    changeDetailsKEKV();
                  }
                }}
              />
            </div>
            {Quarter}
          </div>
          <div>
            {ByReducedKEKV}
            <Fields.CheckboxField
              value={headerForm.get('notRepeatDetails', false)}
              label={md.columns.notRepeatDetails.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notRepeatDetails'], v))}
            />
            {ByDetailsKEKV}
            <Fields.CheckboxField
              value={headerForm.get('byDetailsElKEKV', false)}
              label={md.columns.byDetailsElKEKV.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'byDetailsElKEKV'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('notShowSubGroupCode', false)}
              label={md.columns.notShowSubGroupCode.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notShowSubGroupCode'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('hideKEKVgroups', false)}
              label={md.columns.hideKEKVgroups.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'hideKEKVgroups'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('hideEmptyRows', false)}
              label={md.columns.hideEmptyRows.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'hideEmptyRows'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.symbol0.label}</StyledLabel>
            <StringInput4
              radius
              background
              value={headerForm.get('symbol0', '')}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'symbol0'], v))}
            />
            <StyledLabel>{md.columns.widthColumn.label}</StyledLabel>
            <StringInput4
              radius
              background
              value={headerForm.get('widthColumn', '')}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'widthColumn'], v))}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.showCSU.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('showCSU')}
              options={Object.values(ShowCSU)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'showCSU'], v))}
            />
            {ByShortKEKV}
            {AllKEKV}
            <Fields.CheckboxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'consolidated'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.forYear.label}
              value={headerForm.get('forYear', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'forYear'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('onlyOnePage', false)}
              label={md.columns.onlyOnePage.label}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyOnePage'], v))}
            />
          </div>
        </SixColumnsGrid>
      </DivWithPropsLabel>
    </div>
  );

  return (
    <MainContainer>
      <DpContainer
        Settings={SettingsButton}
        backendName={md.backendName}
        reportVariants={reportVariants}
      >
        <ResultSegment>
          <TableEditor mountNode={resultMountNode} />
        </ResultSegment>
      </DpContainer>
    </MainContainer>
  );
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // planOfUsePrint',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(PlanOfUsePrintEditor);
