import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  DateInput,
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/garanty';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'TABL1';

const tableMD = md.tables[tablename];

const repMonth = {
  m1: { label: 'Січень', name: 'Січень' },
  m2: { label: 'Лютий', name: 'Лютий' },
  m3: { label: 'Березень', name: 'Березень' },
  m4: { label: 'Квітень', name: 'Квітень' },
  m5: { label: 'Травень', name: 'Травень' },
  m6: { label: 'Червень', name: 'Червень' },
  m7: { label: 'Липень', name: 'Липень' },
  m8: { label: 'Серпень', name: 'Серпень' },
  m9: { label: 'Вересень', name: 'Вересень' },
  m10: { label: 'Жовтень', name: 'Жовтень' },
  m11: { label: 'Листопад', name: 'Листопад' },
  m12: { label: 'Грудень', name: 'Грудень' },
};

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onT1RXXXXG1SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T1RXXXXG1S.name]: v });
  }, [onRowChange]);

  const onT1RXXXXG3SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T1RXXXXG3S.name]: v });
  }, [onRowChange]);

  const onT1RXXXXG4SChange = useCallback((e, v) => {
    onRowChange(e, { [tableMD.columns.T1RXXXXG4S.name]: v });
  }, [onRowChange]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG1S" highlighted={highlights.includes('T1RXXXXG1S')}>
              <DateInput
                onChange={onT1RXXXXG1SChange}
                value={row[tableMD.columns.T1RXXXXG1S.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG2" highlighted={highlights.includes('T1RXXXXG2')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG5S" highlighted={highlights.includes('T1RXXXXG5S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG5S.name]}
                modelType="cat"
                modelName="currency"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG5S.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG4S" highlighted={highlights.includes('T1RXXXXG4S')}>
              <DateInput
                onChange={onT1RXXXXG4SChange}
                value={row[tableMD.columns.T1RXXXXG4S.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG3S" highlighted={highlights.includes('T1RXXXXG3S')}>
              <DateInput
                onChange={onT1RXXXXG3SChange}
                value={row[tableMD.columns.T1RXXXXG3S.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG4" highlighted={highlights.includes('T1RXXXXG4')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG4.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG4.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG6" highlighted={highlights.includes('T1RXXXXG6')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG6.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG6.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG7" highlighted={highlights.includes('T1RXXXXG7')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG7.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG7.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG9S" highlighted={highlights.includes('T1RXXXXG9S')}>
              <TextInput
                value={row[tableMD.columns.T1RXXXXG9S.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG9S.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T1RXXXXG1S.name]: PropTypes.string,
    [tableMD.columns.T1RXXXXG2.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG3S.name]: PropTypes.string,
    [tableMD.columns.T1RXXXXG4S.name]: PropTypes.string,
    [tableMD.columns.T1RXXXXG4.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG5S.name]: referencePropType,
    [tableMD.columns.T1RXXXXG6.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG7.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG9S.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
