import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/freeRem4Add2024';

const meta = md.tables.form2;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  form2022,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={7} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'budget'}
              highlighted={highlights.includes('budget')}
              onToggleHighlght={() => onToggleHighlght('budget')}
            >
              {meta.columns.budget.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
              onToggleHighlght={() => onToggleHighlght('sum')}
            >
              {meta.columns.sum.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumT'}
              highlighted={highlights.includes('sumT')}
              onToggleHighlght={() => onToggleHighlght('sumT')}
            >
              {meta.columns.sumT.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRasp'}
              highlighted={highlights.includes('sumRasp')}
              onToggleHighlght={() => onToggleHighlght('sumRasp')}
            >
              {meta.columns.sumRasp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumWork'}
              highlighted={highlights.includes('sumWork')}
              onToggleHighlght={() => onToggleHighlght('sumWork')}
            >
              {meta.columns.sumWork.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prWork'}
              highlighted={highlights.includes('prWork')}
              onToggleHighlght={() => onToggleHighlght('prWork')}
            >
              {meta.columns.prWork.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumCom'}
              highlighted={highlights.includes('sumCom')}
              onToggleHighlght={() => onToggleHighlght('sumCom')}
            >
              {meta.columns.sumCom.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prCom'}
              highlighted={highlights.includes('prCom')}
              onToggleHighlght={() => onToggleHighlght('prCom')}
            >
              {meta.columns.prCom.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumMed'}
              highlighted={highlights.includes('sumMed')}
              onToggleHighlght={() => onToggleHighlght('sumMed')}
            >
              {meta.columns.sumMed.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prMed'}
              highlighted={highlights.includes('prMed')}
              onToggleHighlght={() => onToggleHighlght('prMed')}
            >
              {meta.columns.prMed.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumProd'}
              highlighted={highlights.includes('sumProd')}
              onToggleHighlght={() => onToggleHighlght('sumProd')}
            >
              {meta.columns.sumProd.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prProd'}
              highlighted={highlights.includes('prProd')}
              onToggleHighlght={() => onToggleHighlght('prProd')}
            >
              {meta.columns.prProd.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSoc'}
              highlighted={highlights.includes('sumSoc')}
              onToggleHighlght={() => onToggleHighlght('sumSoc')}
            >
              {meta.columns.sumSoc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prSoc'}
              highlighted={highlights.includes('prSoc')}
              onToggleHighlght={() => onToggleHighlght('prSoc')}
            >
              {meta.columns.prSoc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumReabil'}
              highlighted={highlights.includes('sumReabil')}
              onToggleHighlght={() => onToggleHighlght('sumReabil')}
            >
              {meta.columns.sumReabil.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prReabil'}
              highlighted={highlights.includes('prReabil')}
              onToggleHighlght={() => onToggleHighlght('prReabil')}
            >
              {meta.columns.prReabil.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumTer'}
              highlighted={highlights.includes('sumTer')}
              onToggleHighlght={() => onToggleHighlght('sumTer')}
            >
              {meta.columns.sumTer.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prTer'}
              highlighted={highlights.includes('prTer')}
              onToggleHighlght={() => onToggleHighlght('prTer')}
            >
              {meta.columns.prTer.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumMob'}
              highlighted={highlights.includes('sumMob')}
              onToggleHighlght={() => onToggleHighlght('sumMob')}
            >
              {meta.columns.sumMob.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prMob'}
              highlighted={highlights.includes('prMob')}
              onToggleHighlght={() => onToggleHighlght('prMob')}
            >
              {meta.columns.prMob.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumSec'}
              highlighted={highlights.includes('sumSec')}
              onToggleHighlght={() => onToggleHighlght('sumSec')}
            >
              {meta.columns.sumSec.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prSec'}
              highlighted={highlights.includes('prSec')}
              onToggleHighlght={() => onToggleHighlght('prSec')}
            >
              {meta.columns.prSec.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumBuild'}
              highlighted={highlights.includes('sumBuild')}
              onToggleHighlght={() => onToggleHighlght('sumBuild')}
            >
              {meta.columns.sumBuild.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prBuild'}
              highlighted={highlights.includes('prBuild')}
              onToggleHighlght={() => onToggleHighlght('prBuild')}
            >
              {meta.columns.prBuild.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumFort'}
              highlighted={highlights.includes('sumFort')}
              onToggleHighlght={() => onToggleHighlght('sumFort')}
            >
              {meta.columns.sumFort.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prFort'}
              highlighted={highlights.includes('prFort')}
              onToggleHighlght={() => onToggleHighlght('prFort')}
            >
              {meta.columns.prFort.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumDebt'}
              highlighted={highlights.includes('sumDebt')}
              onToggleHighlght={() => onToggleHighlght('sumDebt')}
            >
              {meta.columns.sumDebt.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prDebt'}
              highlighted={highlights.includes('prDebt')}
              onToggleHighlght={() => onToggleHighlght('prDebt')}
            >
              {meta.columns.prDebt.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumGarant'}
              highlighted={highlights.includes('sumGarant')}
              onToggleHighlght={() => onToggleHighlght('sumGarant')}
            >
              {meta.columns.sumGarant.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prGarant'}
              highlighted={highlights.includes('prGarant')}
              onToggleHighlght={() => onToggleHighlght('prGarant')}
            >
              {meta.columns.prGarant.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumOther'}
              highlighted={highlights.includes('sumOther')}
              onToggleHighlght={() => onToggleHighlght('sumOther')}
            >
              {meta.columns.sumOther.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prOther'}
              highlighted={highlights.includes('prOther')}
              onToggleHighlght={() => onToggleHighlght('prOther')}
            >
              {meta.columns.prOther.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumRaspSubv'}
              highlighted={highlights.includes('sumRaspSubv')}
              onToggleHighlght={() => onToggleHighlght('sumRaspSubv')}
            >
              {meta.columns.sumRaspSubv.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumReturn'}
              highlighted={highlights.includes('sumReturn')}
              onToggleHighlght={() => onToggleHighlght('sumReturn')}
            >
              {meta.columns.sumReturn.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumUnused'}
              highlighted={highlights.includes('sumUnused')}
              onToggleHighlght={() => onToggleHighlght('sumUnused')}
            >
              {meta.columns.sumUnused.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prRaspAll'}
              highlighted={highlights.includes('prRaspAll')}
              onToggleHighlght={() => onToggleHighlght('prRaspAll')}
            >
              {meta.columns.prRaspAll.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prRasp'}
              highlighted={highlights.includes('prRasp')}
              onToggleHighlght={() => onToggleHighlght('prRasp')}
            >
              {meta.columns.prRasp.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumAdd'}
              highlighted={highlights.includes('sumAdd')}
              onToggleHighlght={() => onToggleHighlght('sumAdd')}
            >
              {meta.columns.sumAdd.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prAdd'}
              highlighted={highlights.includes('prAdd')}
              onToggleHighlght={() => onToggleHighlght('prAdd')}
            >
              {meta.columns.prAdd.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sumZF'}
              highlighted={highlights.includes('sumZF')}
              onToggleHighlght={() => onToggleHighlght('sumZF')}
            >
              {meta.columns.sumZF.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'prZF'}
              highlighted={highlights.includes('prZF')}
              onToggleHighlght={() => onToggleHighlght('prZF')}
            >
              {meta.columns.prZF.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  form2022: PropTypes.bool,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  form2022: false,
  /// /
};

export default memo(TPHeader);
