import React, { forwardRef, useContext } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import ListerDBFModal from '../menuButtons/DBFModal';
import ListerPeriodPlanPrintingModal from '../menuButtons/periodPlanPrintingModal';
import AssigmPrintModal from '../menuButtons/printModal';
import PrintModalPlanOfUse from '../../assignmentPlanning/lister/printModalPlanOfUse';
import EstimatePrintModal from '../../assignmentPlanning/lister/estimatePrintModalLister';
import MixIndicatorsSFPrintModal from '../../assignmentPlanning/lister/mixIndicatorsSFPrintModalLister';
import ListerContext from '../../../newLister/context';

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  return (
    <DocListerCommandPanel
      ref={ref}
    >
      <ButtonGroup>
        <Dropdown>
          <Dropdown.Toggle>
            <FontAwesomeIcon icon={faPrint} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <AssigmPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
            <PrintModalPlanOfUse selectedRows={selectedRows} disabled={!selectedRows.length} />
            <MixIndicatorsSFPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
      <ButtonGroup>
        <Dropdown>
          <Dropdown.Toggle>
            Показати ще...
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <EstimatePrintModal />
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
      <ListerDBFModal selectedRows={selectedRows} />
      <ListerPeriodPlanPrintingModal selectedRows={selectedRows} />
    </DocListerCommandPanel>

  );
});

export default CommandPanel;
