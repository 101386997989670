import React from 'react';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.doc.financingCashbox.tables.general.columns;

function HeaderComponent({ activeAreaName, CSUinH }) {
  return (
    <GridTemplate isHeader>
      <DivHeader
        areaName="num"
        isActive={activeAreaName === 'num'}
      >
        {coldef.num.label}
      </DivHeader>
      {!CSUinH && (
      <DivHeader areaName="KVKCode">{coldef.KVKCode.label}</DivHeader>
      )}
      {!CSUinH && (
      <DivHeader
        areaName="csu"
        isActive={activeAreaName === 'csu'}
      >
        {coldef.CSU.label}
      </DivHeader>
      )}
      <DivHeader areaName="KPKVCode">{coldef.KPKVCode.label}</DivHeader>
      <DivHeader
        areaName="elementKFK"
        isActive={activeAreaName === 'elementKFK'}
      >
        {coldef.elementKFK.label}
      </DivHeader>
      <DivHeader areaName="KEKVCode">{coldef.KEKVCode.label}</DivHeader>
      <DivHeader
        areaName="elementKEKV"
        isActive={activeAreaName === 'elementKEKV'}
      >
        {coldef.elementKEKV.label}
      </DivHeader>
      <DivHeader
        areaName="BuildObgect"
        isActive={activeAreaName === 'BuildObgect'}
      >
        {coldef.BuildObgect.label}
      </DivHeader>
      <DivHeader
        areaName="TypeRepair"
        isActive={activeAreaName === 'TypeRepair'}
      >
        {coldef.TypeRepair.label}
      </DivHeader>

      <DivHeader
        areaName="CSUAccount"
        isActive={activeAreaName === 'CSUAccount'}
      >
        {coldef.CSUAccount.label}
      </DivHeader>

      <DivHeader areaName="CSUAccountNo">{coldef.CSUAccountNo.label}</DivHeader>

      <DivHeader areaName="Balance">{coldef.Balance.label}</DivHeader>

      <DivHeader
        areaName="Sum"
        isActive={activeAreaName === 'Sum'}
      >
        {coldef.Sum.label}
      </DivHeader>
      <DivHeader
        areaName="SumPayment"
        isActive={activeAreaName === 'SumPayment'}
      >
        {coldef.SumPayment.label}
      </DivHeader>
    </GridTemplate>
  );
}

HeaderComponent.propTypes = {
  CSUinH: PropTypes.bool,
  activeAreaName: PropTypes.string,
};

HeaderComponent.defaultProps = {
  CSUinH: false,
  activeAreaName: '',
};

export default HeaderComponent;
