import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/incomingMoney';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'accClose';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="CSUAccount" highlighted={highlights.includes('CSUAccount')}>
              <ItemPicker
                value={row[tableMD.columns.CSUAccount.name]}
                modelType="cat"
                modelName="bankAccounts"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSUAccount.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
                filter={FIOwnerFilter}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <Col className="border-right">
              <TableRowCell column="KPKV" highlighted={highlights.includes('KPKV')}>
                <ItemPicker
                  value={row[tableMD.columns.KPKV.name]}
                  modelType="cat"
                  modelName="kbp7Classifier"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.KPKV.name]: v })}
                  readOnly={readOnly}
                  choiceSettings={selectionMode.foldersNItems}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="KEKV" highlighted={highlights.includes('KEKV')}>
              <ItemPicker
                value={row[tableMD.columns.KEKV.name]}
                modelType="cat"
                modelName="kekvClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KEKV.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
              />
            </TableRowCell>
            <TableRowCell column="KDB" highlighted={highlights.includes('KDB')}>
              <ItemPicker
                value={row[tableMD.columns.KDB.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KDB.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.foldersNItems}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="SumIn" highlighted={highlights.includes('SumIn')}>
              <NumberInput
                value={row[tableMD.columns.SumIn.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumIn.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="SumOut" highlighted={highlights.includes('SumOut')}>
              <NumberInput
                value={row[tableMD.columns.SumOut.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumOut.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="SumGet" highlighted={highlights.includes('SumGet')}>
              <NumberInput
                value={row[tableMD.columns.SumGet.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumGet.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="SumDel" highlighted={highlights.includes('SumDel')}>
              <NumberInput
                value={row[tableMD.columns.SumDel.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumDel.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="Sum" highlighted={highlights.includes('Sum')}>
              <NumberInput
                value={row[tableMD.columns.Sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="SumNatural" highlighted={highlights.includes('SumNatural')}>
              <NumberInput
                value={row[tableMD.columns.SumNatural.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.SumNatural.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.SumNatural.name]: PropTypes.number,
    [tableMD.columns.Sum.name]: PropTypes.number,
    [tableMD.columns.SumDel.name]: PropTypes.number,
    [tableMD.columns.SumGet.name]: PropTypes.number,
    [tableMD.columns.SumOut.name]: PropTypes.number,
    [tableMD.columns.SumIn.name]: PropTypes.number,
    [tableMD.columns.KEKV.name]: referencePropType,
    [tableMD.columns.KPKV.name]: referencePropType,
    [tableMD.columns.CSUAccount.name]: referencePropType,
    [tableMD.columns.KDB.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  FI: referencePropType,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  FI: null,
};

export default memo(TPRow);
