import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
// import styled from 'styled-components';
// import styled from 'styled-components';
import classNames from 'classnames';
import { columnsPropType } from './propTypes';
import { moneysStingOptions } from '../../../constants/moneys';

// export const FooterText = styled.input`
//     color: var(--headerTextH);
//     font-weight: 700;
//     text-align: right;
// `;

function BasicFooterRow({
  columns, row, fields,
  highlights, totals,
}) {
  return columns.map((col, i) => {
    const colProps = {
      ...(col.colProps || {}),
      ...(col.headerColProps || {}),
    };
    if (col.columns) {
      return (
      // eslint-disable-next-line react/no-array-index-key
        <Col {...colProps} key={i}>
          <Row className="mx-0">
            <BasicFooterRow
              columns={col.columns}
              row={row}
              fields={fields}
              highlights={highlights}
              totals={totals}
            />
          </Row>
        </Col>
      );
    }
    return (
      <Col {...colProps} key={col.field}>
        { (totals.includes(col.field)) && (
        <div
          data-col={fields}
          className={classNames('text-end overflow-hidden', { 'bg-soft-warning': highlights.includes(col.field) })}
        >
          <input
            className="form-control text-end fw-bold"
            value={row[col.field].toLocaleString('uk', moneysStingOptions)}
            title={row[col.field].toLocaleString('uk', moneysStingOptions)}
            readOnly
          />
        </div>
        )}
      </Col>
    );
  });
}

BasicFooterRow.propTypes = {
  columns: columnsPropType.isRequired,
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.arrayOf(PropTypes.string),
};

BasicFooterRow.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
  totals: [],
};

export default memo(BasicFooterRow);
