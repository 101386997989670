import React from 'react';

function JPGIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 303.188 303.188"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <polygon fill="#E8E8E8" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	" />
        <g>
          <rect x="90.902" y="61.704" fill="#38A3FF" width="119.89" height="119.89" />
          <polygon
            fill="#007934"
            points="184.795,122.678 160.983,162.858 122.243,97.484 90.902,150.372 90.902,181.593
			210.792,181.593 210.792,166.549 		"
          />
        </g>
        <polygon fill="#38A3FF" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	" />
        <g>
          <path
            fill="#A4A9AD"
            d="M94.319,288.935c-2.363,0-4.605-0.23-6.728-0.689v-10.075c0.7,0.131,1.444,0.279,2.231,0.443
			c0.788,0.164,1.641,0.246,2.56,0.246c2.144,0,3.675-0.646,4.594-1.936c0.919-1.292,1.378-3.5,1.378-6.629v-44.402h13.028v43.287
			c0,6.498-1.422,11.415-4.266,14.751C104.274,287.266,100.007,288.935,94.319,288.935z"
          />
          <path
            fill="#A4A9AD"
            d="M156.575,241.152c0,5.361-1.582,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917h-12.963v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C155.054,232.51,156.575,236.273,156.575,241.152z
			 M134.718,247.387h2.363c1.948,0,3.495-0.546,4.643-1.641c1.15-1.094,1.725-2.604,1.725-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.348L134.718,247.387L134.718,247.387z"
          />
          <path
            fill="#A4A9AD"
            d="M183.484,245.779h20.708v25.86c-5.623,1.925-11.804,2.888-18.542,2.888
			c-7.395,0-13.11-2.144-17.147-6.432c-4.036-4.288-6.055-10.403-6.055-18.345c0-7.745,2.21-13.772,6.629-18.083
			c4.419-4.309,10.611-6.465,18.575-6.465c3.019,0,5.868,0.285,8.549,0.853c2.68,0.569,5.016,1.292,7.007,2.166l-4.102,10.174
			c-3.457-1.707-7.253-2.56-11.388-2.56c-3.785,0-6.711,1.231-8.778,3.692c-2.068,2.461-3.102,5.979-3.102,10.551
			c0,4.485,0.936,7.904,2.806,10.256c1.871,2.352,4.566,3.528,8.09,3.528c1.925,0,3.697-0.186,5.316-0.558v-7.515h-8.565
			L183.484,245.779L183.484,245.779z"
          />
        </g>
        <polygon fill="#D1D3D3" points="219.821,50.525 270.346,50.525 219.821,0 	" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export default JPGIcon;
