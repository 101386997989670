import Selection from './Selection';
import Structure from './Structure';
import Order from './Order';
import Filter from './Filter';
import CAppearance from './CAppearance';

export const DCControls = {
  Selection,
  Structure,
  Order,
  Filter,
  CAppearance,
};

export default DCControls;
