export const PROPERTY_VALUES = {
  MAIN: { value: 'MAIN', label: 'Власні' },
  CHILD: { value: 'CHILD', label: 'Підлеглих розпордників' },
  ALL: { value: 'ALL', label: 'Всі' },
};
export const TYPE_VALUES = {
  SPECIAL: { value: 0, label: 'Особовий' },
  REGISTRATION: { value: 1, label: 'Реєстраційний' },
  CUMULATIVE: { value: 2, label: 'Котловий' },
};
