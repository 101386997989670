import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  disposer: foreignPropType,
  kbp7: foreignPropType,
  kekv: foreignPropType,
  kdb: foreignPropType,
  kfb: foreignPropType,
  amount_total: PropTypes.number,
  amount_1: PropTypes.number,
  amount_2: PropTypes.number,
  amount_3: PropTypes.number,
  amount_4: PropTypes.number,
  amount_5: PropTypes.number,
  amount_6: PropTypes.number,
  amount_7: PropTypes.number,
  amount_8: PropTypes.number,
  amount_9: PropTypes.number,
  amount_10: PropTypes.number,
  amount_11: PropTypes.number,
  amount_12: PropTypes.number,
  amount_br_total: PropTypes.number,
  amount_br_1: PropTypes.number,
  amount_br_2: PropTypes.number,
  amount_br_3: PropTypes.number,
  amount_br_4: PropTypes.number,
  amount_br_5: PropTypes.number,
  amount_br_6: PropTypes.number,
  amount_br_7: PropTypes.number,
  amount_br_8: PropTypes.number,
  amount_br_9: PropTypes.number,
  amount_br_10: PropTypes.number,
  amount_br_11: PropTypes.number,
  amount_br_12: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
