import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

function LocalRegionRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'expensesprojectchangeslocalregionrow_set', readOnlyFields, errors, fields,
  });
  const disposerInHeader = data.disposer_in_header;
  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          //
          disposerInHeader={disposerInHeader}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              //
              authorityId={data.authority.id}
              budgetId={data.budget.id}
              disposerInHeader={disposerInHeader}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

LocalRegionRows.propTypes = {
  data: PropTypes.shape({
    disposer_in_header: PropTypes.bool,
    separately_local_reg_programs: PropTypes.bool,
    authority: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.number,
    }),
    budget: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.number,
    }),
    expensesprojectchangeslocalregionrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    expensesprojectchangeslocalregionrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    expensesprojectchangeslocalregionrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    expensesprojectchangeslocalregionrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

LocalRegionRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,

};

export default LocalRegionRows;
