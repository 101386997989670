import React, {
  useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import { loadMessages, temporaryHideMessage, setMessageAsRead } from '../../../actions/notifier';
import { HasNewMessages, MessagesError, NoMessages } from '../../../assets/icons';
import MessagesBox from './messageBox';

const IndicatorMsg = styled.img`
  height: 25px;
  margin-left: auto;
  padding: 0 5px;
  //border-left: 1px solid rgb(175,187,204);
`;

const MsgContainer = styled.div`
  //position: absolute;
  //right: 0;
  z-index: 5;
  //top: 0;
  //bottom: 0;
  padding: 0 3px;
  border-left: 1px solid rgb(175,187,204);
  
`;

function Messages({
  messages, isLoading, isErrored, errText,
  reload, onPostpone, onRead,
}) {
  useEffect(

    () => {
      reload();
      const timerId = setInterval(reload, 10 * 60 * 1000); // 10 min
      return () => clearInterval(timerId);
    },
    [reload],
  );
  const [show, setShow] = useState(false);
  const [curMessage, setCurMessage] = useState(null);

  useEffect(
    () => {
      if (show) {
        setCurMessage(messages.get(0, null));
      } else {
        (setCurMessage(null));
      }
    },
    [messages, show],
  );

  useEffect(
    () => {
      // Для того, чтобы все подряд сообщения не выскаукивали
      // на весь экран заменить строку "showOnDashboard__" на "showOnDashboard"
      const dbMessages = messages.filter((m) => m.get('showOnDashboard__', true) && !m.get('readed', false));
      if (dbMessages.size) {
        setCurMessage(messages.filter((m) => m.get('id') === dbMessages.getIn([0, 'id'])).get(0));
      } else {
        setCurMessage(null);
      }
    },
    [messages],
  );
  const onNextMessage = useCallback(
    () => {
      setCurMessage(
        (cm) => {
          const idx = messages.indexOf(cm);
          if (idx < messages.size - 1) {
            return messages.get(idx + 1);
          }
          return cm;
        },
      );
    },
    [messages],
  );

  const onPrevMessage = useCallback(
    () => {
      setCurMessage(
        (cm) => {
          const idx = messages.indexOf(cm);
          if (idx > 0) {
            return messages.get(idx - 1);
          }
          return cm;
        },
      );
    },
    [messages],
  );

  if (isErrored) return (<IndicatorMsg alt={errText} src={MessagesError} />);
  return (
    <MsgContainer>
      {messages.size ? (
        <IndicatorMsg
          title={`У вас ${messages.size} непрочитаних повідомлень`}
          src={HasNewMessages}
          isLoading={isLoading}
          onClick={() => setShow(true)}
        />
      ) : (
        <IndicatorMsg
          title="У вас немає непрочитаних повідомлень"
          src={NoMessages}
          isLoading={isLoading}
        />
      )}

      {curMessage && (
        <MessagesBox
          message={curMessage}
          hasClose={show}
          hasPrevious={messages.indexOf(curMessage) > 0}
          hasNext={messages.indexOf(curMessage) < messages.size - 1}
          hasPostPone={!show}
          hasRead
          onPostpone={onPostpone}
          onRead={onRead}
          onNext={onNextMessage}
          onPrevious={onPrevMessage}
          onClose={() => setShow(false)}
        />
      )}
    </MsgContainer>
  );
}

Messages.propTypes = {
  messages: PropTypes.instanceOf(List).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isErrored: PropTypes.bool.isRequired,
  errText: PropTypes.string,
  reload: PropTypes.func.isRequired,
  onPostpone: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
};

Messages.defaultProps = {
  errText: null,
};
const mapState = (store) => {
  const msgNode = store.getIn(['notifier', 'messages'], new Map());
  return {
    messages: msgNode.get('messages', new List()),
    isLoading: msgNode.get('isLoading', false),
    isErrored: msgNode.get('isErrored', false),
    errText: msgNode.get('errMessage', null),
  };
};

const mapDispatchToProps = (dispatch) => ({
  reload: () => dispatch(loadMessages()),
  onPostpone: (msgId) => dispatch(temporaryHideMessage(msgId)),
  onRead: (msgId) => dispatch(setMessageAsRead(msgId)),
});

export default connect(mapState, mapDispatchToProps)(Messages);
