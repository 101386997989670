import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  additionalAnalitics,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: { label: 'Фінансовий орган', name: 'Владелец', visible: true },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  Executor: {
    label: 'Виконавець',
    name: 'Исполнитель',
    visible: false,
  },
  KPKV: { label: 'КПКВ', name: 'КПКВ', visible: true },
  Target: {
    label: 'Мета',
    name: 'Цель',
    visible: true,
  },
  Steward: {
    label: 'Розпорядник',
    name: 'Распорядитель',
    visible: true,
  },
  Budget: {
    label: 'Бюджет',
    name: 'Бюджет',
    visible: false,
  },
  GroundLaw: {
    label: 'Законодавчі підстави для виконання бюджетної програми',
    name: 'ЗаконОснование',
    visible: false,
  },
  Bin: { label: 'Корзина', name: 'Корзина', visible: false },
  ThisIsBuildObject: {
    label: "Об'єкт будівництва",
    name: 'ЭтоОбъектСтроительства',
    visible: false,
  },
  ElementKFKPlan: {
    label: 'Планується по',
    name: 'ПлановыйЭлементКФК',
    visible: false,
  },
  Inactive: { label: 'Не діючий', name: 'НеДействующий', visible: false },
  DoNotPrint: {
    label: 'Не друкувати',
    name: 'флНеПечатать',
    visible: false,
  },
  // Task: { label: 'Задача', name: 'Задача', visible: false },
  IdMinFin: { label: 'ПК у ПО мінфіну', name: 'ИдМинФин', visible: false },
  DoNotPrint2: {
    label: 'Не друкувати (2)',
    name: 'флНеПечатать2',
    visible: false,
  },
  DoNotPrint3: {
    label: 'Не друкувати (3)',
    name: 'флНеПечатать3',
    visible: false,
  },
  DoNotPrint4: {
    label: 'Не друкувати (4)',
    name: 'флНеПечатать4',
    visible: false,
  },
  DoNotPrint5: {
    label: 'Не друкувати (5)',
    name: 'флНеПечатать5',
    visible: false,
  },
  // Доплнительные поля:
  KFK: {
    label: 'КФК (6зн.)',
    name: 'Классификатор',
    visible: true,
  },
  KFK4: {
    label: 'КФК(4зн.)',
    name: 'КлассификаторКФКВК',
    visible: true,
  },
  KBP: {
    label: 'КБП',
    name: 'КлассификаторКБП',
    visible: true,
  },
  KVK: {
    label: 'КВК',
    name: 'КлассификаторКВК',
    visible: true,
  },
  KBP7: {
    label: 'з КПБ МБ (7зн.)',
    name: 'КлассификаторКБП7',
    visible: true,
  },
  DateElement: {
    label: 'станом на',
    name: 'ДатаЭл',
    visible: true,
  },
  DateClassifier: {
    label: 'станом на',
    name: 'ДатаКл',
    visible: true,
  },
  FIElement: {
    label: 'Элемент вищого рівня ',
    name: 'ЭлементФинОргана',
    visible: true,
  },
  FIElementsList: {
    label: 'Список елементів вищого рівня ',
    name: 'СписокЭлементовВУ',
    visible: false,
  },
  AutoFillName: {
    label: 'Автоматичне заповнення найменування',
    name: 'флАтоматЗаполненияНаим',
    visible: false,
  },
  AutoFillFullName: {
    label: 'Автоматичне заповнення повного найменування ',
    name: 'флАтоматЗаполненияПолнНаим',
    visible: false,
  },
  code: {
    label: 'Код',
    name: 'Код(для сортування)',
    visible: false,
  },
  name: {
    label: 'Найменування',
    name: 'Наименование',
    visible: true,
  },
  rooted: {
    label: 'Корінь (Не спускати розпоряднику)',
    name: 'Корневой',
    visible: false,
  },
  zeroLevel: {
    label: 'НульУровень',
    name: 'НульУровень',
    visible: false,
  },
  addAnalityces: {
    label: 'Додаткова аналітика',
    name: 'ДополнительнаяАналитика',
    visible: false,
  },
  KBPCode: {
    label: 'КБП',
    name: 'КлассификаторКБПКод',
    visible: true,
  },

};

const footer = { ...columns };

const tables = { additionalAnalitics };

export const elementKFK = {
  label: 'Елементи КПКВ',
  name: 'elementKFK',
  backendName: 'ЭлементыКФК',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    {
      column: columns.KBPCode.name,
      direction: 'ascending',
    },
    ...defaultOrder,
  ],
  columns,
  footer,
  tables,
  frontend: 'cat/ElementKFK',
  listColumns: [
    ...new Set([
      'KBP7',
      'KVK',
      // 'KPKV',
      // 'KBP',
      'KBPCode',
      'KFK4',
      'name',
      // 'KFK',
      'Steward',
      // 'FullName',
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  useFoldersLazyLoad: true,
};

export default elementKFK;
