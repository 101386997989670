import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'tariff_cat'}
          highlighted={highlights.includes('tariff_cat')}
          onToggleHighlight={() => onToggleHighlight('tariff_cat')}
        >
          {fields.tariff_cat.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'kbp7'}
          highlighted={highlights.includes('kbp7')}
          onToggleHighlight={() => onToggleHighlight('kbp7')}
        >
          {fields.kbp7.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'amount_rank'}
          highlighted={highlights.includes('amount_rank')}
          onToggleHighlight={() => onToggleHighlight('amount_rank')}
        >
          {fields.amount_rank.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'amount_fact_rank'}
          highlighted={highlights.includes('amount_fact_rank')}
          onToggleHighlight={() => onToggleHighlight('amount_fact_rank')}
        >
          {fields.amount_fact_rank.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'sum_year'}
          highlighted={highlights.includes('sum_year')}
          onToggleHighlight={() => onToggleHighlight('sum_year')}
        >
          {fields.sum_year.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'salary'}
          highlighted={highlights.includes('salary')}
          onToggleHighlight={() => onToggleHighlight('salary')}
        >
          {fields.salary.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'allowances'}
          highlighted={highlights.includes('allowances')}
          onToggleHighlight={() => onToggleHighlight('allowances')}
        >
          {fields.allowances.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'optional_allowances'}
          highlighted={highlights.includes('optional_allowances')}
          onToggleHighlight={() => onToggleHighlight('optional_allowances')}
        >
          {fields.optional_allowances.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'for_min_salary'}
          highlighted={highlights.includes('for_min_salary')}
          onToggleHighlight={() => onToggleHighlight('for_min_salary')}
        >
          {fields.for_min_salary.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'bonus'}
          highlighted={highlights.includes('bonus')}
          onToggleHighlight={() => onToggleHighlight('bonus')}
        >
          {fields.bonus.label}
        </TableHeaderCell>
      </Col>
      <Col
        md={2}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'other'}
          highlighted={highlights.includes('other')}
          onToggleHighlight={() => onToggleHighlight('other')}
        >
          {fields.other.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left flex-fill"
          active={activeCol === 'tax'}
          highlighted={highlights.includes('tax')}
          onToggleHighlight={() => onToggleHighlight('tax')}
        >
          {fields.tax.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
