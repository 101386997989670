import { fromJS } from 'immutable';

function encodeRow(row, columns) {
  const recodedItem = Object.keys(columns).reduce(
    (R, col) => (columns[col].name in row
      ? { ...R, [col]: row[columns[col].name] }
      : R),
    {},
  );

  return fromJS(recodedItem);
}

// eslint-disable-next-line import/prefer-default-export
export { encodeRow };
