import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'disposer'}
          highlighted={highlights.includes('disposer')}
          onToggleHighlight={() => onToggleHighlight('disposer')}
        >
          {fields.disposer.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
