/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';
import md from '../../../../../../constants/meta/documents/indicatorFact';
import useTablePart from '../../../../../newEditor/hook/tablepart';

const tableMeta = md.tables.sourcesInvestmentProjects;

function GeneralTP({ data, actions, readOnly }) {
  const budget = data[md.columns.budget.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const totals = useMemo(() => (tableData.reduce(
    (res, r) => {
      res.sumResultTotal += (r[tableMeta.columns.sumResultGenF.name] || 0)
        + (r[tableMeta.columns.sumResultSpecF.name] || 0);
      res.sumResultGenF += r[tableMeta.columns.sumResultGenF.name] || 0;
      res.sumResultSpecF += r[tableMeta.columns.sumResultSpecF.name] || 0;

      res.sumCashTotal += (r[tableMeta.columns.sumCashSpecF.name] || 0)
        + (r[tableMeta.columns.sumCashGenF.name] || 0);
      res.sumCashSpecF += r[tableMeta.columns.sumCashSpecF.name] || 0;
      res.sumCashGenF += r[tableMeta.columns.sumCashGenF.name] || 0;

      res.sumFactTotal += (r[tableMeta.columns.sumFactGenF.name] || 0)
        + (r[tableMeta.columns.sumFactSpecF.name] || 0);
      res.sumFactGenF += r[tableMeta.columns.sumFactGenF.name] || 0;
      res.sumFactSpecF += r[tableMeta.columns.sumFactSpecF.name] || 0;

      res.sumPlanTotal += (r[tableMeta.columns.sumPlanSpecF.name] || 0)
        + r[tableMeta.columns.sumPlanGenF.name] || 0;
      res.sumPlanSpecF += r[tableMeta.columns.sumPlanSpecF.name] || 0;
      res.sumPlanGenF += r[tableMeta.columns.sumPlanGenF.name] || 0;

      res.sumPrognosisTotal += (r[tableMeta.columns.sumPrognosisSpecF.name] || 0)
        + r[tableMeta.columns.sumPrognosisGenF.name] || 0;
      res.sumPrognosisSpecF += r[tableMeta.columns.sumPrognosisSpecF.name] || 0;
      res.sumPrognosisGenF += r[tableMeta.columns.sumPrognosisGenF.name] || 0;
      return res;
    },
    {
      sumResultTotal: 0,
      sumResultGenF: 0,
      sumResultSpecF: 0,
      sumCashTotal: 0,
      sumCashSpecF: 0,
      sumCashGenF: 0,
      sumFactTotal: 0,
      sumFactGenF: 0,
      sumFactSpecF: 0,
      sumPlanTotal: 0,
      sumPlanSpecF: 0,
      sumPlanGenF: 0,
      sumPrognosisTotal: 0,
      sumPrognosisSpecF: 0,
      sumPrognosisGenF: 0,
    },
  )
  ), [tableData]);

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          totals={totals}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            budget={budget}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
