import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, OverlayTrigger, Popover } from 'react-bootstrap';

function checkNumber(number) {
  return number.match(/^((\+3)8)0\d{9}$/) != null;
}

const PhoneInput = forwardRef(
  (
    {
      value,
      disabled,
      readOnly,
      onClick,
      onFocus,
      onBlur,
      maxLength,
      onChange,
      id,
      errored,
    },
    ref,
  ) => {
    const [error, setError] = useState(null);
    const [number, setNumber] = useState(null);

    const changeHandler = useCallback(
      (e) => {
        const newValue = e.target.value;
        if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
          onChange(e, newValue);
          setNumber(e.target.value);
        }
      },
      [maxLength, onChange, readOnly],
    );

    const onBlurHandler = useCallback(
      (e) => {
        if (number && !checkNumber(number)) {
          setError('Введіть номер у форматі +380***');
        } else setError(null);
        if (onBlur) onBlur(e);
      },
      [number, onBlur],
    );

    const popover = (
      <Popover>
        <Popover.Body>
          {error}
        </Popover.Body>
      </Popover>
    );

    return error ? (
      <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
        <FormControl
          ref={ref}
          id={id}
          value={value || ''}
          onChange={changeHandler}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlurHandler}
          isInvalid={errored || error}
        />
      </OverlayTrigger>
    ) : (
      <FormControl
        ref={ref}
        id={id}
        value={value || ''}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlurHandler}
        isInvalid={errored || error}
      />
    );
  },
);

PhoneInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errored: PropTypes.bool,
};

PhoneInput.defaultProps = {
  id: '',
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  onBlur: null,
  errored: false,
};

export default PhoneInput;
