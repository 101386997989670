import React, {
  forwardRef,
  // , useContext
} from 'react';

import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

// import ListerContext from '../../../newLister/context';

const CommandPanel = forwardRef((_, ref) =>
// const {
//   selectedRows,
// } = useContext(ListerContext);

  (
    <DocListerCommandPanel
      ref={ref}
    />

  ));
export default CommandPanel;
