import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import {
  Badge, Button, Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  ColorTextStamp,
  ContainerStamp,
  ImgContainerStamp,
  StyledLabel,
  ContainerThreeColumn,
  SubText,
  StyledGreyLabel,
} from '../../../../components/Form/styledForm';
import StampApproved from '../../../../assets/icons/stamp_approved.svg';
import { soSelector } from '../../_common/selectors';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import md from '../../../../constants/meta/documents/indicatorPassport';
import DocSelectionModal from '../docSelectionModal';
import meta from '../../../../constants/meta';

function IndicatorPassport({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const sessionOptions = useSelector(soSelector);
  const FI = data[md.columns.FI.name];
  const budget = data[md.columns.budget.name];

  const FIOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: FI,
    }],
    [FI],
  );

  const BudgetFilter = useMemo(
    () => [{
      fieldName: 'Бюджет',
      value: budget,
    }],
    [budget],
  );

  const BudgetOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: budget,
    }],
    [budget],
  );
  const csuFilter = useMemo(() => [
    {
      fieldName: 'parent',
      value: FI,
    }], [FI]);

  const budgetReqFitler = useMemo(() => [{
    fieldName: 'ФинансовыйОрган',
    value: data[md.columns.FI.name],
  }], [data]);
  const isAdmin = sessionOptions.get('is_admin', false);
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );

  const ApprovedLabel = (
    <ContainerThreeColumn>
      {isAdmin && (
        <EditorControls.CheckboxInput
          controlId={`isApproved-${data.id}`}
          value={data[md.columns.isApproved.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.isApproved.name]: value,
          })}
          label={md.columns.isApproved.label}
          readOnly={readOnly}
        />
      )}
      {data[md.columns.isApproved.name] && isAdmin && (
        <div>
          <EditorControls.StringInput
            label={md.columns.received.label}
            value={data[md.columns.received.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.received.name]: value,
            })}
            readOnly={readOnly}
          />
        </div>
      )}
      <ContainerStamp>
        {data[md.columns.isApproved.name] && (
          <ImgContainerStamp background={StampApproved}>
            <ColorTextStamp color="#4281c9">
              {data[md.columns.received.name]}
            </ColorTextStamp>
          </ImgContainerStamp>
        )}
      </ContainerStamp>
    </ContainerThreeColumn>
  );

  const getDocLogicaId = (o) => {
    for (const i in o) {
      if (i === 'id') {
        return o[i];
      }
    }
    return null;
  };

  const getDocLogicaRepr = (o) => {
    for (const i in o) {
      if (i === 'repr') {
        return o[i];
      }
    }
    return null;
  };

  return (
    <EditorCollapse>
      <Row>
        {isAdmin ? (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.budget.label}
              value={data[md.columns.budget.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.budget.name]: value,
                  [md.columns.CSU.name]: new Map(),
                });
              }}
              modelType="cat"
              modelName="budget"
              noHierarchy
              readOnly={readOnly}
            />
          </Col>
        ) : (
          <Col>
            <StyledGreyLabel>
              {md.columns.budget.label}
              :
              {' '}
              <SubText>
                {' '}
                { data[md.columns.budget.name] && data[md.columns.budget.name].repr }
              </SubText>
            </StyledGreyLabel>
          </Col>
        )}
        {isAdmin ? (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.FI.label}
              value={data[md.columns.FI.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.FI.name]: value,
                });
              }}
              modelType="cat"
              modelName="csu"
              filter={BudgetFilter}
              readOnly={readOnly}
            />
          </Col>
        ) : (
          <Col>
            <StyledGreyLabel>
              {md.columns.FI.label}
              :
              {' '}
              <SubText>
                {' '}
                { data[md.columns.FI.name] && data[md.columns.FI.name].repr }
              </SubText>
            </StyledGreyLabel>
          </Col>
        )}
        {isAdmin ? (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.author.label}
              value={data[md.columns.author.name]}
              onChange={(e, value) => {
                actions.onChange({
                  [md.columns.author.name]: value,
                });
              }}
              modelType="cat"
              modelName="users"
              readOnly={readOnly}
            />
          </Col>
        ) : (
          <Col>
            <StyledGreyLabel>
              {md.columns.author.label}
              :
              {' '}
              <SubText>
                {' '}
                { data[md.columns.author.name] && data[md.columns.author.name].repr }
              </SubText>
            </StyledGreyLabel>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            readOnly
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col style={{
          justifySelf: 'center',
          alignSelf: 'center',
        }}
        >
          <StyledLabel style={{ color: 'red' }}>
            {md.columns.KPBName.label}
            :
            {data[md.columns.KPBName.name]}
          </StyledLabel>
        </Col>
        <Col style={{
          justifySelf: 'center',
          alignSelf: 'center',
        }}
        >
          <EditorControls.CheckboxInput
            controlId={`byQuarters-${data.id}`}
            value={data[md.columns.byQuarters.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.byQuarters.name]: value,
            })}
            label={md.columns.byQuarters.label}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {data[md.columns.docByKBP7.name] ? (
            <EditorControls.ItemPicker
              label={md.columns.KPKV.label}
              value={data[md.columns.KPKV.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.KPKV.name]: value,
                });
                actions.onSR('CHANGE_PROG');
              }}
              modelType="cat"
              modelName="kbp7Classifier"
              filter={BudgetOwnerFilter}
              readOnly={readOnly}
            />
          ) : (
            <EditorControls.ItemPicker
              label={md.columns.elementKFK.label}
              value={data[md.columns.elementKFK.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.elementKFK.name]: value,
                });
                actions.onSR('CHANGE_PROG');
              }}
              modelType="cat"
              modelName="elementKFK"
              filter={FIOwnerFilter}
              readOnly={readOnly}
            />
          )}
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.CSU.label}
            value={data[md.columns.CSU.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.CSU.name]: value,
              });
            }}
            modelType="cat"
            modelName="csu"
            noHierarchy
            filter={csuFilter}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.budgetRequest.label}
            value={data[md.columns.budgetRequest.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budgetRequest.name]: value,
            })}
            modelType="doc"
            modelName="budgetRequest"
            filter={budgetReqFitler}
            readOnly={readOnly}
          />
        </Col>
        <Col style={{
          justifySelf: 'center',
          alignSelf: 'center',
        }}
        >
          <Button onClick={() => actions.onSR('CHANGE_BUDGET_REQEST')} disabled={readOnly}>
            Заповнити з бюджетного запиту
          </Button>
        </Col>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            CSU={data[md.columns.CSU.name]}
            year={data[md.columns.year.name]}
            FI={FI}
            elementKFK={data[md.columns.elementKFK.name]}
            budget={budget}
            KPKV={data[md.columns.KPKV.name]}
          />
        </Col>
      </Row>
      {ApprovedLabel}
      <Row>
        <Col>
          <Badge className="mx-1" variant="info">
            {' Робота з Логіка '}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel>
            {md.columns.docLogica.label}
            <Link to={`${meta.doc.docTransferToLogica.frontend}/${getDocLogicaId(data[md.columns.docLogica.name])}`}>
              {getDocLogicaRepr(data[md.columns.docLogica.name])}
            </Link>
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.statusLogica.label]}
          </StyledLabel>
        </Col>
        <Col>
          <StyledLabel>
            {data[md.columns.signLogica.label]}
          </StyledLabel>
        </Col>

      </Row>
    </EditorCollapse>
  );
}

IndicatorPassport.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default IndicatorPassport;
