import React, {
  useEffect, useMemo,
} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ContainerForm, DivWithPropsLabel, MyContainerFormWithNColumns,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpDPCommandPanel';

import DocsTP from './tablepart/tpDoc';
import Tabs from '../../../components/tabs/Tabs';

import DpContainer from '../dpContainer';
import { MainContainer, ResultSegment } from '../styledComponentsDp';
import Enums from '../../../constants/meta/enums';
import DateField from '../../../components/fields/dates';

export const ContainerOneColumn = styled(ContainerForm)`
  grid-template-columns: 90%;
  margin-bottom: 10px;
`;

const md = meta.dp.finPrinting;

const StringField3 = Fields.GetTextInput(3);
const StringField4 = Fields.GetTextInput(4);

const NumberField10 = Fields.GetNumberInput(10, 0, false);

const PageOrientationValues = Object.values(Enums.PageOrientation);

const CSUCodes = {
  EDRPOU: { label: 'ЄДРПОУ', name: 0 },
  ByNetwork: { label: 'За мережею', name: 1 },
};

const ReqVariants = {
  std: { label: 'Звичайна', name: 0 },
  ext1: { label: 'Розширена (недоф., фін.з.)', name: 1 },
  ext2: { label: 'Розширена (проф., фін.з)', name: 2 },
  FIClients: { label: 'Форма ФУ за контрагентами', name: 3 },
  CSUClients: { label: 'Форма розпорядника за контрагентами', name: 4 },
};

const AcceptedValues = [
  { label: 'Погоджено', name: 'Погоджено' },
  { label: 'ПОГОДЖЕНО', name: 'ПОГОДЖЕНО' },
  { label: 'Затверджено', name: 'Затверджено' },
  { label: 'ЗАТВЕРДЖЕНО', name: 'ЗАТВЕРДЖЕНО' },

];

const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(
  <TPCommandPanel
    tableName="data"
  />,
));

function FinPrintingEditor() {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );

  const reportVariants = headerForm.get('reportVariants', new Map());

  const SettingsButton = (
    <div>
      <Tabs>
        <DivWithPropsLabel label="Основні налаштування">
          <MyContainerFormWithNColumns columns={5}>
            <div>
              <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('SignVariant', new Map())}
                modelType="cat"
                modelName="signatureVariants"
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                canCleared
              />
            </div>
            <div>
              {headerForm.get('ShowSumWordsVisible', false) && (
                <Fields.CheckboxField
                  label={md.columns.ShowSumWords.label}
                  value={headerForm.get('ShowSumWords', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowSumWords'], v))}
                />
              )}
              {headerForm.get('CommercialBankVisible', false) && (
                <Fields.CheckboxField
                  label={md.columns.CommercialBank.label}
                  value={headerForm.get('CommercialBank', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CommercialBank'], v))}
                />
              )}
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.ForWeb.label}
                value={headerForm.get('ForWeb', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ForWeb'], v))}
              />
              {headerForm.get('TwoCopiesByPageVisible', false) && (
                <Fields.CheckboxField
                  label={md.columns.TwoCopiesByPage.label}
                  value={headerForm.get('TwoCopiesByPage', false)}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'TwoCopiesByPage'], v));
                  }}
                />
              )}
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.ShowPrintTime.label}
                value={headerForm.get('ShowPrintTime', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowPrintTime'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.ShowGrid.label}
                value={headerForm.get('ShowGrid', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowGrid'], v))}
              />
            </div>
            <div>
              {headerForm.get('KievForm5RaspVisible', false) && (
                <Fields.CheckboxField
                  label={md.columns.KievForm5Rasp.label}
                  value={headerForm.get('KievForm5Rasp', false)}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'KievForm5Rasp'], v));
                    if (v) {
                      dispatch(actions.changeField(['headerForm', 'ThisIsTransfer'], false));
                    }
                  }}
                />
              )}
              {headerForm.get('ThisIsTransferVisible', false) && (
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  <Fields.CheckboxField
                    label={md.columns.ThisIsTransfer.label}
                    value={headerForm.get('ThisIsTransfer', false)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'ThisIsTransfer'], v));
                      if (v) {
                        dispatch(actions.changeField(['headerForm', 'KievForm5Rasp'], false));
                      }
                    }}
                  />
                </span>
              )}
            </div>
            <div>
              {headerForm.get('ExtendedRequestVisible', false) && (
                <div>
                  <StyledLabel>{md.columns.ExtendedRequest.label}</StyledLabel>
                  <InputWithDropdown
                    value={headerForm.get('ExtendedRequest', false)}
                    options={Object.values(ReqVariants)}
                    onChange={(e, v) => {
                      dispatch(actions.changeField(['headerForm', 'ExtendedRequest'], v));
                      if (v > 2) {
                        dispatch(actions.changeField(['headerForm', 'PageOrientation'], Enums.PageOrientation.Portrait));
                      }
                    }}
                  />
                </div>
              )}
              {headerForm.get('FormDniproVisible', false) && (
                <Fields.CheckboxField
                  label={md.columns.FormDnipro.label}
                  value={headerForm.get('FormDnipro', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FormDnipro'], v))}
                />
              )}
            </div>
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={2}>
            {headerForm.get('AcceptedVisible', false) && (
              <div>
                <StyledLabel>{md.columns.Accepted.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('Accepted', false)}
                  options={AcceptedValues}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'Accepted'], v));
                  }}
                />
              </div>
            )}
            {headerForm.get('SignDateVisible', false) && (
              <div>
                <StyledLabel>{md.columns.SignDate.label}</StyledLabel>
                <DateField
                  value={headerForm.get('SignDate', 0)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignDate'], v))}
                />
              </div>
            )}
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={8}>
            <div />
            {headerForm.get('QuantOfCopiesVisible', false) && (
              <div>
                <StyledLabel>{md.columns.QuantOfCopies.label}</StyledLabel>
                <NumberField10
                  value={headerForm.get('QuantOfCopies', 0)}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'QuantOfCopies'], v));
                  }}
                />
              </div>
            )}
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={6}>
            <div>
              <StyledLabel>{md.columns.PageOrientation.label}</StyledLabel>
              <InputWithDropdown
                value={headerForm.get('PageOrientation', false)}
                options={PageOrientationValues}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PageOrientation'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.GroupTable.label}
                value={headerForm.get('GroupTable', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'GroupTable'], v))}
              />
            </div>

            {headerForm.get('CSUCodeVisible', false) && (
              <div>
                <StyledLabel>{md.columns.CSUCode.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('CSUCode', false)}
                  options={Object.values(CSUCodes)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUCode'], v))}
                />
              </div>
            )}
            <div>
              <StyledLabel>{md.columns.MoneysCode.label}</StyledLabel>
              <StringField3
                radius
                background
                value={headerForm.get('MoneysCode', '')}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'MoneysCode'], v))}
              />
            </div>
            {headerForm.get('FillAllRowsVisible', false) && (
              <div>
                <Fields.CheckboxField
                  label={md.columns.FillAllRows.label}
                  value={headerForm.get('FillAllRows', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FillAllRows'], v))}
                />
              </div>
            )}
            <div>
              <Fields.CheckboxField
                label={md.columns.ByKEKVElements.label}
                value={headerForm.get('ByKEKVElements', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ByKEKVElements'], v))}
              />
            </div>

          </MyContainerFormWithNColumns>
          {headerForm.get('FinGroupVisibleVisible', false) && (headerForm.get('DistSettingsGroupVisible', false) || headerForm.get('RaspSettingsGroupVisible', false)) && (
            <MyContainerFormWithNColumns columns={9}>
              <Fields.CheckboxField
                label={md.columns.firstNameLastName.label}
                value={headerForm.get('firstNameLastName', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'firstNameLastName'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.printKOATUU.label}
                value={headerForm.get('printKOATUU', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printKOATUU'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.ByGeneralCSU.label}
                value={headerForm.get('ByGeneralCSU', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ByGeneralCSU'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.DIVCSUs.label}
                value={headerForm.get('DIVCSUs', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DIVCSUs'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.CSUTotals.label}
                value={headerForm.get('CSUTotals', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUTotals'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.KBP7Totals.label}
                value={headerForm.get('KBP7Totals', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KBP7Totals'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.KEKVTotals.label}
                value={headerForm.get('KEKVTotals', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKVTotals'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.CSUTotalDown.label}
                value={headerForm.get('CSUTotalDown', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'CSUTotalDown'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.PrintNumberName.label}
                value={headerForm.get('PrintNumberName', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintNumberName'], v))}
              />
            </MyContainerFormWithNColumns>
          )}
          {headerForm.get('RaspSettingsGroupVisible', false) && (
            <MyContainerFormWithNColumns columns={4}>
              <Fields.CheckboxField
                label={md.columns.ByFI.label}
                value={headerForm.get('ByFI', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ByFI'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.PrintKVKTotals.label}
                value={headerForm.get('PrintKVKTotals', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKVKTotals'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.PrintKBP7Totals.label}
                value={headerForm.get('PrintKBP7Totals', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKBP7Totals'], v))}
              />
              <Fields.CheckboxField
                label={md.columns.RepeatHeaderInPrint.label}
                value={headerForm.get('RepeatHeaderInPrint', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'RepeatHeaderInPrint'], v))}
              />
            </MyContainerFormWithNColumns>
          )}
          <MyContainerFormWithNColumns columns={3}>
            <Fields.CheckboxField
              label={md.columns.AdditionalKEKV.label}
              value={headerForm.get('AdditionalKEKV', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AdditionalKEKV'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.PrintKPKV.label}
              value={headerForm.get('PrintKPKV', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKPKV'], v))}
            />
            {headerForm.get('ShowClientsVisible', false) && (
              <Fields.CheckboxField
                label={md.columns.ShowClients.label}
                value={headerForm.get('ShowClients', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowClients'], v))}
              />
            )}
          </MyContainerFormWithNColumns>
          <MyContainerFormWithNColumns columns={2}>
            <div>
              <StyledLabel>{md.columns.ColWidths.label}</StyledLabel>
              <StringField4
                radius
                background
                textLeft
                value={headerForm.get('ColWidths', '')}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ColWidths'], v))}
              />
            </div>
            <Fields.CheckboxField
              label={md.columns.FitToPage.label}
              value={headerForm.get('FitToPage', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'FitToPage'], v))}
            />
          </MyContainerFormWithNColumns>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Документи">
          <ContainerOneColumn>
            <DocsTPRenderer />
          </ContainerOneColumn>
        </DivWithPropsLabel>
      </Tabs>
    </div>
  );

  return (
    <MainContainer>
      <DpContainer
        Settings={SettingsButton}
        backendName={md.backendName}
        reportVariants={reportVariants}
      >
        <ResultSegment>
          <TableEditor mountNode={resultMountNode} />
        </ResultSegment>
      </DpContainer>
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null,
    },
  ),
);

export default enchance(FinPrintingEditor);
