import { useCallback, useMemo, useState } from 'react';

/**
 *
 * @param {{
 * meta: {
 *  options: {
 *    group: [],
 *    column: [],
 *    fields: [],
 *    filter: [],
 *    order: [],
 *  },
 *  schema: {},
 * },
 * data: {
 *    header: [[]],
 *    body: [[]],
 *    footer: [{}],
 *    columns_cnt: int,
 *    },
 * group_rows: {}
 * }} result
 */
// eslint-disable-next-line import/prefer-default-export
export const useReportResult = (result) => {
  const [closedRowGroups, setClosedRowGroups] = useState([]);
  const header = useMemo(() => result.data.header, [result.data.header]);
  const body = useMemo(() => result.data.body, [result.data.body]);
  const footer = useMemo(() => result.data.footer, [result.data.footer]);
  const groupRows = useMemo(
    () => {
      const gr = result.group_rows;
      // console.log(gr['1']);
      return Object.keys(gr)
        .reduce((RL, level) => gr[level]
          .reduce((R, [start, finish]) => ({
            ...R,
            [start - 1]: { start, finish, level: Number(level) - 1 },
          }), RL), {});
    },
    [result.group_rows],
  );
  const onToggleRowGroup = useCallback(
    (e, group) => setClosedRowGroups(
      (old) => (old.includes(group) ? old.filter((g) => g !== group) : [...old, group]),
    ),
    [],
  );
  const rowLevels = useMemo(
    () => {
      const s = [...new Set(Object.keys(groupRows).map((k) => groupRows[k].level))];
      return [
        ...s.map((level) => ({
          level,
          label: `${level}`,
        })),
        {
          level: Math.max(...s) + 1,
          label: '∞',
        },
      ];
    },
    [groupRows],
  );
  const invisibleRows = useMemo(
    () => closedRowGroups.reduce((R, g) => [
      ...R,
      ...([...Array(groupRows[g].finish - groupRows[g].start + 1)]
        .map((_, i) => groupRows[g].start + i)),
    ], []),
    [closedRowGroups, groupRows],
  );
  const onRowLevelToggle = useCallback(
    (e, level) => {
      const groupsByLevel = Object.keys(groupRows)
        .filter((g) => groupRows[g].level >= level)
        .map((g) => Number(g));
      setClosedRowGroups(groupsByLevel);
    },
    [groupRows],
  );
  return {
    header,
    body,
    footer,
    groupRows,
    rowLevels,
    closedRowGroups,
    onToggleRowGroup,
    onRowLevelToggle,
    invisibleRows,
  };
};
