import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { getRandomID } from '../../../../../api/utils';

const DIGITS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
const SIGNS = ['+', '-', '*', '/'];
const BRAKETS = ['(', ')'];
const OPERATOR_CLS = ' text-primary ';
const DIGIT_CLS = ' text-success ';
const DEFAULT_CLS = ' rounded px-2 py-1 ';

export const SHORT_NAME_REGEX = '^[A-Z,a-z,А-Я,а-я,Є-Ї,є-ї][A-Z,a-z,А-Я,а-я,Є-Ї,є-ї,0-9,_]*$';

const TYPES_OF_ITEMS = {
  digit: 'D',
  operator: 'O',
  variable: 'V',
};

function getClassName(type, variable = null) {
  switch (type) {
    case TYPES_OF_ITEMS.operator:
      return `${DEFAULT_CLS} ${OPERATOR_CLS}`;
    case TYPES_OF_ITEMS.digit:
      return `${DEFAULT_CLS} ${DIGIT_CLS}`;
    case TYPES_OF_ITEMS.variable:
      if (variable) return `${DEFAULT_CLS} ${variable.className || ''}`;
      return `${DEFAULT_CLS} bg-danger text-white`;
    default:
      return `${DEFAULT_CLS} bg-danger text-white`;
  }
}
const useFormulaItems = ({ initialExpression, allowedVariables }) => {
  const [items, setItems] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [editItem, setEditItem] = useState(null);

  const formulaRef = useRef();

  useEffect(
    () => {
      if (formulaRef.current) formulaRef.current.focus();
    },
    [],
  );
  useEffect(
    () => setItems((initialExpression || '')
      .split(' ')
      .filter((v) => !!v)
      .map((v) => {
        let type = null;
        if (SIGNS.includes(v) || BRAKETS.includes(v)) type = TYPES_OF_ITEMS.operator;
        else if (!Number.isNaN(parseFloat(v))) type = TYPES_OF_ITEMS.digit;
        else type = TYPES_OF_ITEMS.variable;

        const variable = type === TYPES_OF_ITEMS.variable && allowedVariables
          .filter((av) => av.name === v).reduce((R, aw) => aw, false);
        return {
          value: v,
          type,
          className: getClassName(type, variable),
          title: variable ? variable.title : null,
          key: getRandomID(),
        };
      })),
    [allowedVariables, initialExpression],
  );
  const expression = items.map((item) => item.value).join(' ');

  const onClickHandler = useCallback(
    (e) => {
      const { target } = e;
      const idx = target.dataset.index;
      if (idx) {
        e.stopPropagation();
        e.preventDefault();
        const idx2 = parseInt(idx, 10);
        const { left, width } = target.getBoundingClientRect();
        if ((e.pageX - left) < (left + width - e.pageX)) setCursor(idx2);
        else setCursor(idx2 + 1);
      } else if (target === formulaRef.current) {
        setCursor(items.length);
      }
    },
    [items.length],
  );

  const validateItem = useCallback(
    (itemIndex) => {
      setItems((oi) => oi.map((item, idx) => {
        if (idx !== itemIndex) return item;
        if (item.isDigit) return { ...item, value: String(parseFloat(item.value)) };
        return item;
      }));
    },
    [],
  );

  const insertOperator = useCallback(
    (operator) => {
      if (editItem) validateItem(editItem);
      setItems((oi) => [
        ...oi.slice(0, cursor),
        {
          value: operator,
          type: TYPES_OF_ITEMS.operator,
          className: getClassName(TYPES_OF_ITEMS.operator),
          title: null,
          key: getRandomID(),
        },
        ...oi.slice(cursor, oi.length),
      ]);
      setEditItem(null);
      setCursor(cursor + 1);
    },
    [cursor, editItem, validateItem],
  );

  const removeItem = useCallback(
    (idx) => {
      setItems((oi) => [
        ...oi.slice(0, idx),
        ...oi.slice(idx + 1, oi.length),
      ]);
      setCursor((c) => (cursor > idx ? c - 1 : c));
    },
    [cursor],
  );

  const insertDigit = useCallback(
    (digit) => {
      if (editItem) validateItem(editItem);
      setItems((oi) => [
        ...oi.slice(0, cursor),
        {
          value: digit,
          type: TYPES_OF_ITEMS.digit,
          className: getClassName(TYPES_OF_ITEMS.digit),
          title: null,
          key: getRandomID(),
        },
        ...oi.slice(cursor, oi.length),
      ]);
      setEditItem(cursor);
      setCursor(cursor + 1);
    },
    [cursor, editItem, validateItem],
  );

  const editDigit = useCallback(
    (digit) => {
      let newValue = items[editItem].value;
      if (digit === 'Backspace' && newValue.length > 1) newValue = newValue.slice(0, newValue.length - 1);
      else if (digit === 'Backspace' && newValue.length === 1) {
        removeItem(editItem);
        setEditItem(null);
        return true;
      } else if (DIGITS.includes(digit) && !Number.isNaN(parseFloat(`${newValue}${digit}`))) newValue += digit;
      setItems((oi) => [
        ...oi.slice(0, editItem),
        {
          ...oi[editItem],
          value: newValue,
        },
        ...oi.slice(editItem + 1, oi.length),
      ]);
      return true;
    },
    [editItem, items, removeItem],
  );
  const editVariable = useCallback(
    (key) => {
      const insrt = editItem === null;
      const currentValue = insrt ? { type: TYPES_OF_ITEMS.variable } : items[editItem];
      if (currentValue.type !== TYPES_OF_ITEMS.variable) return false;
      let varName = null;
      if (key.length === 1) {
        varName = insrt ? key : `${currentValue.value}${key}`;
        const regex = new RegExp(SHORT_NAME_REGEX);
        if (!regex.test(varName)) return false;
      } else if (key === 'Backspace' && !insrt) {
        varName = items[editItem].value.slice(0, currentValue.value.length - 1);
        if (varName === '') {
          removeItem(editItem);
          setEditItem(null);
          return true;
        }
      } else {
        return false;
      }
      const variable = allowedVariables.filter((v) => v.name === varName).reduce((R, v) => v, null);
      const vv = {
        value: varName,
        className: getClassName(TYPES_OF_ITEMS.variable, variable),
        title: variable ? variable.title : null,
      };
      if (insrt) {
        setItems((oi) => [
          ...oi.slice(0, cursor),
          {
            type: TYPES_OF_ITEMS.variable,
            key: getRandomID(),
            ...vv,
          },
          ...oi.slice(cursor, oi.length),
        ]);
        setEditItem(cursor);
        setCursor(cursor + 1);
      } else {
        setItems((oi) => oi.map((item, i) => (i === editItem ? { ...item, ...vv } : item)));
      }
      return true;
    },
    [allowedVariables, cursor, editItem, items, removeItem],
  );

  const onButtonHandler = useCallback(
    (key) => {
      if (SIGNS.includes(key)) insertOperator(key);
      else if (BRAKETS.includes(key)) insertOperator(key);
      else if (DIGITS.includes(key) && editItem === null) insertDigit(key);
      else if (editItem === null && key === 'Backspace' && cursor > 0) removeItem(cursor - 1);
      else if (editItem === null && key === 'Delete' && cursor < items.length) removeItem(cursor);
      else if (editItem === null) editVariable(key);
      else if (items[editItem].type === TYPES_OF_ITEMS.digit) editDigit(key);
      else if (items[editItem].type === TYPES_OF_ITEMS.variable) editVariable(key);
      else return false;
      if (formulaRef.current) formulaRef.current.focus();
      return true;
    },
    [cursor, editDigit, editItem, editVariable, insertDigit, insertOperator, items, removeItem],
  );

  const insertVariable = useCallback(
    (varName) => {
      if (editItem) validateItem(editItem);
      const variable = allowedVariables.filter((v) => v.name === varName).reduce((R, v) => v, null);
      setItems((oi) => [
        ...oi.slice(0, cursor),
        {
          value: varName,
          type: TYPES_OF_ITEMS.variable,
          className: getClassName(TYPES_OF_ITEMS.variable, variable),
          title: variable ? variable.title : null,
          key: getRandomID(),
        },
        ...oi.slice(cursor, oi.length),
      ]);
      setEditItem(cursor);
      setCursor(cursor + 1);
    },
    [allowedVariables, cursor, editItem, validateItem],
  );

  const onVariableClick = useCallback(
    (varName) => {
      insertVariable(varName);
      if (formulaRef.current) formulaRef.current.focus();
    },
    [insertVariable],
  );

  const onKeyDownHandler = useCallback(
    (e) => {
      const { key } = e;
      if (key === 'ArrowRight' && cursor < items.length) {
        setCursor(cursor + 1);
        setEditItem(null);
      } else if (key === 'ArrowLeft' && cursor > 0) {
        setCursor(cursor - 1);
        setEditItem(null);
      } else if (onButtonHandler(key)) {
        /* empty */
      } else {
        return false;
      }
      e.preventDefault();
      e.stopPropagation();
      return true;
    },
    [cursor, items.length, onButtonHandler],
  );

  return {
    items,
    expression,
    cursor,
    setCursor,
    onKeyDownHandler,
    onClickHandler,
    onButtonHandler,
    onVariableClick,
    formulaRef,
  };
};

export default useFormulaItems;
