import React, {
  memo, useCallback,
} from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker, NumberInput,
} from '../../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../../constants/meta/documents/budgetRequest';
import TableRow from '../../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'indicators';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, highlights,
}) {
  const onRowChange = useCallback(
    (e, partrow) => onChange(e, rowIndex, partrow),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="indicator" highlighted={highlights.includes('indicator')}>
          <ItemPicker
            value={row[tableMD.columns.indicator.name]}
            modelType="cat"
            modelName="indicators"
            onChange={(e, v) => onRowChange(
              e,
              { [tableMD.columns.indicator.name]: v },
            )}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="k0" highlighted={highlights.includes('k0')}>
          <NumberInput
            value={row[tableMD.columns.k0.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.k0.name]: value,
            })}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="k1" highlighted={highlights.includes('k1')}>
          <NumberInput
            value={row[tableMD.columns.k1.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.k1.name]: value,
            })}
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="k2" highlighted={highlights.includes('k2')}>
          <NumberInput
            value={row[tableMD.columns.k2.name]}
            precision={2}
            onChange={(e, value) => onRowChange(e, {
              [tableMD.columns.k2.name]: value,
            })}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
};

export default memo(TPRow);
