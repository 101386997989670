import Doctablerow from './doctablerow';
import Cattablerow from './cattablerow';

export {
  Doctablerow,
  Cattablerow,
};

const ListerRows = {
  Doctablerow,
  Cattablerow,
};

export default ListerRows;
