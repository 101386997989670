import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Badge, Button, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function UnsupportedViewer({ mimeType, href, filename }) {
  return (
    <div className="p-5 mb-4 bg-light rounded-3">
      <Row>
        <Col>
          <Alert variant="warning">
            Прегляд файлів типу
            {' '}
            <Badge variant="info">{mimeType}</Badge>
            не підтримується, але Ви можете
            <Button as="a" download={filename} href={href} variant="link">
              <FontAwesomeIcon icon={faDownload} className="me-2" />
              Завантажити
            </Button>
            його, те переглянути на своєму комп&apos;ютері.
          </Alert>
        </Col>
      </Row>
    </div>
  );
}

UnsupportedViewer.propTypes = {
  mimeType: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default UnsupportedViewer;
