import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  name: { label: 'Назва вулиці', name: 'Наименование', visible: true },
  toponymStreet: { label: 'Топонім', name: 'Топоним', visible: false },

  owner: { label: 'Власник', name: 'Владелец', visible: false },
};

export const street = {
  label: 'Вулиці',
  name: 'street',
  backendName: 'Улицы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/street',
  listColumns: [...(new Set([...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default street;
