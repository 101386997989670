import React, { Fragment, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import { monthNames } from '../../../../../../constants/date';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { decimalToStr } from '../../../../../../common/funcs';
import { PrependKFB } from '../../../../styles/klassifiers';

const QUARTERS = [...Array(4)].map((_, i) => i);

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  onlyYear,
  vt4br,
}) {
  const amountChangeHandler = useCallback(
    (e, newValue, m) => {
      const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_${k + 1}`] || 0)), 0);
      onChange(e, rowIndex, {
        [`amount_${m}`]: newValue,
        amount_total: total,
      });
    },
    [onChange, row, rowIndex],
  );

  const amountBRChangeHandler = useCallback(
    (e, newValue, m) => {
      const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_br_${k + 1}`] || 0)), 0);
      onChange(e, rowIndex, {
        [`amount_br_${m}`]: newValue,
        amount_br_total: total,
      });
    },
    [onChange, row, rowIndex],
  );

  const kdbProps = tableHooks.useItemInputProps('kdb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const amountProps = monthNames.map(
    (_, m) => tableHooks.useNumberInputProps(`amount_${m + 1}`, row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields),
  );

  const amountBRProps = monthNames.map(
    (_, m) => tableHooks.useNumberInputProps(`amount_br_${m + 1}`, row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields),
  );

  return (
    <>
      <Col
        xl={4}
        sm={12}
        className="d-flex p-0 ps-3"
      >
        <Row className="w-100">
          <Col>
            <TabelRowCell column="kdb" highlighted={highlights.includes('kdb')}>
              <TableControls.ItemPicker
                {...kdbProps}
                prepend={(
                  <PrependKFB>
                    {row.kdb && row.kdb.code}
                  </PrependKFB>
                      )}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col xl={8} sm={12} className="p-0">
        <Row xl={onlyYear ? 1 : 5} className="me-0 pe-2">
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q} className="p-0 border-right">
              {[...Array(3)].map((_, m) => (
                <Fragment key={`amount_${q * 3 + m + 1}`}>
                  <TabelRowCell
                    column={`amount_${q * 3 + m + 1}`}
                    highlighted={highlights.includes(`amount_${q * 3 + m + 1}`)}
                  >
                    <TableControls.NumberInput
                      {...amountProps[q * 3 + m]}
                      onChange={(e, v) => amountChangeHandler(e, v, q * 3 + m + 1)}
                    />
                  </TabelRowCell>
                  {vt4br && (
                  <TabelRowCell
                    column={`amount_br_${q * 3 + m + 1}`}
                    highlighted={highlights.includes(`amount_br_${q * 3 + m + 1}`)}
                  >
                    <TableControls.NumberInput
                      {...amountBRProps[q * 3 + m]}
                      onChange={(e, v) => amountBRChangeHandler(e, v, q * 3 + m + 1)}
                    />
                  </TabelRowCell>
                  )}
                </Fragment>
              ))}
            </Col>
          ))}
          <Col
            md={12}
            className="p-0 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column"
          >
            {onlyYear ? (
              <>
                <TabelRowCell column="amount_1" highlighted={highlights.includes('amount_1')}>
                  <TableControls.NumberInput
                    {...amountProps[0]}
                    onChange={(e, v) => amountChangeHandler(e, v, 1)}
                  />
                </TabelRowCell>
                {vt4br && (
                <TabelRowCell column="amount_br_1" highlighted={highlights.includes('amount_br_1')}>
                  <TableControls.NumberInput
                    {...amountBRProps[0]}
                    onChange={(e, v) => amountBRChangeHandler(e, v, 1)}
                  />
                </TabelRowCell>
                )}
              </>
            ) : (
              <div className="w-100">
                <TabelRowCell column="amount_total" highlighted={highlights.includes('amount_total')} className="justify-content-end fw-bold">
                  {decimalToStr(row.amount_total, '0,00')}
                </TabelRowCell>
                {vt4br && (
                  <TabelRowCell column="amount_br_total" highlighted={highlights.includes('amount_br_total')} className="justify-content-end fw-bold">
                    {decimalToStr(row.amount_br_total, '0,00')}
                  </TabelRowCell>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>

    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyYear: PropTypes.bool,
  vt4br: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  onlyYear: false,
  vt4br: false,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
