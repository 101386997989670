import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Doctablerow } from '../../../newLister/tableComponent/table/row';
import md from '../../../../constants/meta/documents/financingRequest';
import { FilesCell } from '../../../newLister/tableComponent/table/components';

function FinancingRequestListerRow({ row, ...rest }) {
  return (
    <Doctablerow row={row} {...rest}>
      <FilesCell files={row[md.columns.files.name]} />
    </Doctablerow>
  );
}

FinancingRequestListerRow.propTypes = {
  row: PropTypes.shape({
    [md.columns.files.name]: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default memo(FinancingRequestListerRow);
