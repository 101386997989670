import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  FullName: {
    label: 'Повне найменування (в розпорядженні / розподілі)',
    name: 'ПолнНаименование',
    visible: false,
  },
  CatalogsNotes: {
    label: 'Вид платежу',
    name: 'ПримечанияСпр',
    visible: false,
  },
  owner: { label: 'Владелец', name: 'Владелец', visible: true },
  FullNameForRequest: {
    label: 'Повне найменування(в заявцi)',
    name: 'ПолнНаименованиеДляЗаявки',
    visible: false,
  },
  FondCode: { label: 'КодФонд', name: 'КодФонд', visible: true },
  CurrentFinancialInstitution: {
    label: 'ТекущийФинансовыйОрган',
    name: 'ТекущийФинансовыйОрган',
    visible: true,
  },
};

export const docNotes = {
  label: 'Примітка (док)',
  name: 'docNotes',
  backendName: 'ПримечанияДок',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/docNotes',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default docNotes;
