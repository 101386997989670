// eslint-disable-next-line import/no-cycle
import { ItemField } from './selectorField/item';
import CheckboxField from './CheckBox';
import GetTextInput from './TextInput';
import GetNumberInput from './NumberInput';
// eslint-disable-next-line import/no-cycle
import SelectorField from './stdSelectorField';
import TextAreaField from './TextArea';
import GetFieldWithErrMsg from './getFieldWithErrMsg';
import YearField from './yearInput';

const Fields = {
  ItemField,
  CheckboxField,
  GetTextInput,
  GetNumberInput,
  GetFieldWithErrMsg,
  SelectorField,
  TextAreaField,
  YearField,
};

export default Fields;
