import React from 'react';

function OutputIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 12.5V17.5H2.5V2.5H12.5V7.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 10H17.5" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 8L17.5 10L15.5 12" stroke="#4281C9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default OutputIcon;
