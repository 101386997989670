import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasedGrid } from '../../styledTable/styledTable';

function GridTemplate({ gridTemplateParam, children, isHeader }) {
  const Grid = useMemo(
    () => {
      const { gridTemplateAreas, gridTemplateColumns } = gridTemplateParam;
      return styled(BasedGrid)`
      grid-template-areas:  ${gridTemplateAreas} 
      grid-template-columns: ${gridTemplateColumns};
      //position: ${isHeader ? 'sticky' : 'unset'};
      &:first-child{
        top: 36px;
      z-index: 1;}
      &:last-child{
      position: sticky;
        z-index: 0;}
      `;
    },
    [gridTemplateParam, isHeader],
  );
  // console.log('render grid template')

  return <Grid>{children}</Grid>;
}

GridTemplate.propTypes = {
  gridTemplateParam: PropTypes.shape({
    gridTemplateAreas: PropTypes.string,
    gridTemplateColumns: PropTypes.string,
    // isHeader: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  isHeader: PropTypes.bool,
};

GridTemplate.defaultProps = {
  gridTemplateParam: {},
  isHeader: false,
};

export default memo(GridTemplate);
