import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledListerTable = styled(Table)`
  table-layout: fixed;
  & thead th{
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(0deg,#d0e5fa,#dde9f8,#dfeefd,#d2def8);
    position:sticky;
    z-index: 1;
    top: 0;
    padding-right: 1rem;
    color: var(--falcon-dark);
  }
  & tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & thead th>svg {
    position: absolute;
    right: .5rem;
    top: calc(50% - .5em)
  }
  & tbody td.bool {
    text-align: center;
  }
  & tbody td.moneys {
    text-align: right;
  }
  & tbody tr {
    transition: all 0.1s linear 0s;
    color: var(--falcon-dark)
  }
  & tr.active, tr.selected {
    background: linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21 !important;
  } 
  & tr.active>td, tr.selected>td {
    --falcon-table-bg-type: none; 
  } 
   & tbody tr.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  & tbody tr.unexecuted {
    color: var(--falcon-gray-500) !important;
  }
  & tbody tr.deleted{
    text-decoration: line-through;
  }
`;

export const StyledContainer = styled.div.attrs(({ height }) => ({
  style: { height: `${height}px` },
}))`
        width: 100%;
  overflow: auto;
`;

export const StyledTh = styled.th.attrs(({ top, width }) => ({
  style: {
    top: `${top}px`,
    ...width && { width: `${width}` },
  },
}))`
`;

export const StyledToggler = styled.td.attrs(({ level }) => ({
  style: {
    paddingLeft: `${level + 1}rem`,
  },
}))`
`;
