import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function Row({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerId,
}) {
  const executiveProps = tableHooks.useItemInputProps('executive', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const signKindProps = tableHooks.useSelectorInputProps('sign_kind', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const executiveFilter = useMemo(
    () => ({
      owner: disposerId,
    }),
    [disposerId],
  );
  return (
    <>
      <Col sm={9}>
        <TabelRowCell column="executive" highlighted={highlights.includes('executive')}>
          <TableControls.ItemPicker {...executiveProps} filter={executiveFilter} />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="sign_kind" highlighted={highlights.includes('sign_kind')}>
          <TableControls.SelectorInput {...signKindProps} />
        </TabelRowCell>
      </Col>
    </>
  );
}

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  disposerId: PropTypes.number,
};

Row.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  disposerId: null,
};

export default memo(Row);
