import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  dataSel: { label: 'показати за', name: 'выбНаборДанных' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГрн' },
  reportVeriant: { label: 'Варіант звіту', name: 'ВариантОтчета' },
  planinKind: { label: 'Вид плану', name: 'ВидПлана' },
  typeReport: { label: 'Вид звіту', name: 'ВидОтчета' },
  date1: { label: 'За період з', name: 'НаДату1' },
  date2: { label: 'по', name: 'НаДату2' },
  KEKVsh: { label: 'За скороченим КЕКВ', name: 'флКЕКВКраткий' },
  inBR: { label: 'в т.ч. БР', name: 'флБР' },
  fond: { label: 'Фонд', name: 'ТекущийФонд' },
  formType2: { label: 'Вид форми', name: 'ВидПриложения2' },
  pcm: { label: 'За ПЦМ', name: 'флПЦМ' },
  period: { label: 'Фонд', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Додатки 1,2 до Порядку казначейського обслуговування   (не регламентовані, для управлінського обліку)',
  name: 'TreasureOffer',
  columns,
  frontend: 'rep/TreasureOffer',
  backendName: 'КазначейскиеПриложения',
  resultColumns: baseResultColumns,
};

export default definition;
