/* eslint-disable no-confusing-arrow */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Triangle from '../../assets/icons/Triangle_2.svg';
import { font } from '../../constants/globalStyle';

function Picture() {
  return <img style={{ padding: '5.4px 0' }} src={Triangle} alt="" />;
}

const Div = styled.div`
  display: flex;
  position: relative;
`;

const DivForOuterClick = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const DrDn = styled.button`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;  
  text-align: left;
  position: relative;
  border-right: none;
  background: ${(props) => props.noBorder ? 'transparent' : 'white'};
  // background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  padding: 2px;
  min-height: 22px;
  width: 100%;
  min-width: 1px;
  ${font};
  outline: none;
  border-left: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  border-top: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  border-bottom: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  &:focus{
    border-left: 1px solid rgb(142, 179, 223);
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:focus ~ button {
    border-right: 1px solid rgb(142, 179, 223);
    border-top: 1px solid rgb(142, 179, 223);
    border-bottom: 1px solid rgb(142, 179, 223);
  }
  &:disabled {
    border-left: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
    border-bottom: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
    border-top: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
    background: ${(props) => props.noBorder ? 'transparent' : '#ffffff'};
  }
`;

const Window = styled.div`
  position: absolute;
  z-index: 2221;
  max-height: 150px;
  overflow: auto;
  width: 100%;  
  border-radius: 4.5px 5px;
  background: white;
  border: 1px solid #afbbcc;
`;

const WindowElement = styled.div`
  cursor: pointer;
  padding: 2px 2.5px;
  border-radius: 4px;
  background: #ffffff;
  &:hover{
    background: #e8edfa;
  }
`;

const DropDownButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border: 1px solid red;
  padding: 1.5px 9.4px;
  border-left: none;
  outline:none;
  background: ${(props) => props.noBorder ? 'transparent' : 'white'};
  // background: #ffffff;
  box-sizing: border-box;
  border-left: none;
  border-radius: 0 4px 4px 0;
  border-right: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  border-top: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  border-bottom: 1px solid ${(props) => props.noBorder ? 'transparent' : '#afbbcc'};
  &:disabled {
    border-right: 1px solid #afbbcc;
    border-top: 1px solid #afbbcc;
    border-bottom: 1px solid #afbbcc;
    background: #ffffff;
    cursor: default;
  }
  :focus {outline:none;};
`;

function InputWithDropdown({
  value,
  onChange,
  disabled,
  options,
  noBorder,
  isFocusCell,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const displayValue = useMemo(
    () => options.filter((o) => o.name === value).reduce((R, o) => o.label, ''),
    [options, value],
  );

  const ref = useRef(null);
  const dropDownRef = useRef(null);
  const containerRef = useRef(null);
  useEffect(() => {
    if (isFocusCell) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [isFocusCell]);

  const parent = document.querySelector('.tablePartGrid');

  const elementsHeight = (options.length * 21 + 24);
  const heightToComponent = containerRef.current && containerRef.current.offsetTop + containerRef.current.clientHeight + elementsHeight;
  const heightToParent = parent && parent.offsetTop + parent.offsetHeight;
  const val = containerRef.current && parent ? (heightToComponent) > (heightToParent) : false;

  const style = val ? {
    bottom: '24px',
  } : {
    marginTop: '22px',
  };

  const dropDown = () => {
    setIsOpened(!isOpened);
  };

  const closeDrDn = (e) => {
    e.stopPropagation();
    setIsOpened(false);
  };

  return (
    <Div
      disabled={disabled}
      ref={containerRef}
    >
      <DrDn
        noBorder={noBorder}
        ref={ref}
        value={value}
        disabled={disabled}
        onClick={dropDown}
      >
        {displayValue}
      </DrDn>
      <DropDownButton
        noBorder={noBorder}
        onClick={dropDown}
        disabled={disabled}
      >
        {!disabled
          && <Picture />}
      </DropDownButton>
      {isOpened
      && (
        <Window style={style} ref={dropDownRef}>
          {
            createPortal(
              <DivForOuterClick onClick={closeDrDn} />,
              document.body,
            )
          }
          {options.map((item) => (
            <WindowElement
              key={item.name}
              onClick={(e) => {
                onChange(e, item.name);
                closeDrDn(e);
              }}
            >
              {item.label}
            </WindowElement>
          ))}
        </Window>
      )}
    </Div>
  );
}

InputWithDropdown.propTypes = {
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  isFocusCell: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  })).isRequired,
};

InputWithDropdown.defaultProps = {
  value: null,
  disabled: false,
  noBorder: false,
  isFocusCell: false,
};

export default InputWithDropdown;
