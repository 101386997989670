import React from 'react';

function NoComponent() {
  return (
    <span className="text-danger">
      Уппс.. Компонент не знайдено.
    </span>
  );
}

export default NoComponent;
