import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions, moneysThousandStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const ThreeYears = [...Array(3)].map((_, i) => i);

function TPFooter({
  totals,
  activeCol,
  highlights,
  for3Years,
  inThousands,
}) {
  return (
    <TableHeaderRow noGutters>
      <Col
        xl={6}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        &nbsp;
      </Col>
      <Col xl={6} sm={12} className="px-1">
        {for3Years ? ThreeYears.map((i) => (
          <Row key={i} noGutters>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === `SumGenF${i}`} highlighted={highlights.includes(`SumGenF${i}`)}>
                {totals[`SumGenF${i}`].toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === `SumSpecF${i}`} highlighted={highlights.includes(`SumSpecF${i}`)}>
                {totals[`SumSpecF${i}`].toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === `SumBR${i}`} highlighted={highlights.includes(`SumBR${i}`)}>
                {totals[`SumBR${i}`].toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === `Sum${i}`} highlighted={highlights.includes(`Sum${i}`)}>
                {(totals[`SumGenF${i}`] + totals[`SumSpecF${i}`]).toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
          </Row>
        )) : (
          <Row noGutters>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === 'SumGenF0'} highlighted={highlights.includes('SumGenF0')}>
                {totals.SumGenF0.toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === 'SumSpecF0'} highlighted={highlights.includes('SumSpecF0')}>
                {totals.SumSpecF0.toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === 'SumBR0'} highlighted={highlights.includes('SumBR0')}>
                {totals.SumBR0.toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
            <Col className="text-right">
              <TableHeaderCell active={activeCol === 'Sum0'} highlighted={highlights.includes('Sum0')}>
                {(totals.SumGenF0 + totals.SumSpecF0).toLocaleString('uk', inThousands ? moneysThousandStingOptions : moneysStingOptions)}
              </TableHeaderCell>
            </Col>
          </Row>
        )}
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    SumGenF0: PropTypes.number,
    SumSpecF0: PropTypes.number,
    SumBR0: PropTypes.number,
    Sum0: PropTypes.number,
    ...[...Array(3)].reduce((R, r, i) => ({
      ...R,
      [`SumGenF${i + 1}`]: PropTypes.number,
      [`SumSpecF${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
      [`Sum${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  inThousands: PropTypes.bool,
  for3Years: PropTypes.bool,
};

TPFooter.defaultProps = {
  activeCol: '',
  for3Years: false,
  inThousands: false,
};

export default memo(TPFooter);
