import base from './base';

/**
 * @const
 * @type {object}
 */
const mon = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v, i) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `${mon[i]}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  authorCh: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  catList: { label: 'Задіяні коди', name: 'ПереченьКл', visible: true },
  fondObject: { label: 'Елемент фонд', name: 'ЭлементФонд', visible: true },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: true },
  refNumberYear: { label: 'Ном.дов.(річна)', name: 'РегНомерГод', visible: false },
  refReason: {
    label: 'Підстава для змін',
    name: 'ОснованиеДляИзм',
    visible: false,
  },
  templateBaseDoc: { label: 'Підстава для змін', name: 'ШаблонОснованияДок', visible: true },
  sourcesCover: { label: 'Джерело покриття', name: 'ЭлементИсточникПокрытия', visible: true },
  sourcesCoverList: { label: 'сп Джерело покриття', name: 'спИсточниковПокрытия', visible: false },
  implementer: { label: 'Виконавець', name: 'Исполнитель', visible: true },
  includeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  received: { label: 'Отримано', name: 'Квитанция', visible: false },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  autoGenNote: { label: 'Опис', name: 'АвтПримечание' },
  onlyYear: {
    label: 'Тільки річні суми',
    name: 'ТолькоГодовая',
    visible: false,
  },
  includeBudgetAmplification: {
    label: 'в т.ч. Бюджет Розвитку',
    name: 'ВТЧБР',
    visible: false,
  },
  isApproved: {
    label: 'Документ затверджено',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  isApproved1: {
    label: 'Затверджений',
    name: 'ДокументУтвержден1',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  tags: { label: 'Теги', name: 'АвтПримечание', visible: true },
  docTotalCost: {
    label: 'Сума',
    name: 'СуммаДокумента',
    visible: true,
    type: 'moneys',
  },
  numberRegistryFI: {
    label: 'Номер довідки',
    name: 'РегНомер',
    visible: true,
  },
  isShowKdbKfb: {
    label: 'Облік заг.фонду у розрізі КДБ та КФБ',
    name: 'флВидимостьКдбКфб',
    visible: false,
  },
  year: { label: 'Рiк', name: 'Год', visible: true },
  loans: { label: 'Кредитування', name: 'флКредитование', visible: true },
  internal: { label: 'Внутрішня', name: 'Внутренняя', visible: true },
  disableControl: { label: 'Відключити контроль залишків для субвенції', name: 'ОтключитьКонтрольОстатков', visible: true },
  // Дополнительные свойства
  thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },
  DisableFilterCsuToListElemKFK: { label: 'ОтключитьОтборВыбораЭлементовКФК', name: 'ОтключитьОтборВыбораЭлементовКФК', visible: false },
  isSignature: {
    label: 'Підписано',
    name: 'МаксПодпись',
    visible: true,
    type: 'bool',
  },
  showTargetedProgram: { label: 'Фл показать целевые программы', name: 'флПоказатьЦелевыеПрограммы', visible: false },
  isFI: { label: 'ЭтоФинДеп', name: 'ЭтоФинДеп', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      elementKFB: { label: 'КФБ', name: 'ЭлементКФБ' },
      KDBCode: { label: 'Код КДБ ', name: 'КодКДБ' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
      targetedProgram: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Док' },
      isApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      CSU: { label: 'Розподярник', name: 'Распорядитель' },
      sum: { label: 'Сума (підсумок)', name: 'СуммаДокумента' },
      notes: { label: 'Примітка', name: 'Примечание' },
      autoGenNote: { label: 'Опис', name: 'АвтПримечание' },
      author: { label: 'Автор', name: 'Автор' },
    },
  },
  yearKDB: {
    name: 'ГодовойКДБ',
    label: 'Дані по КДБ для річної довідки',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KDBCode: { label: 'Код КДБ', name: 'КодКДБ' },
      KDBClassifier: { label: 'КДБ', name: 'КлассификаторКДБ' },
      elementKDB: { label: 'Елемент КДБ', name: 'ЭлементКДБ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
    },
  },
  yearKFB: {
    name: 'ГодовойКФБ',
    label: 'Дані по КФБ для річної довідки',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      KFBClassifier: { label: 'КФБ', name: 'КлассификаторКФБ' },
      elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Зміни до плану асигнувань',
  name: 'changesToAssignmentPlanning',
  backendName: 'ИзменениеАссигнований',
  columns,
  frontend: 'doc/changesToAssignmentPlanning',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'isApproved1',
      'isSignature',
      'numberRegistryFI',
      'tags',
      'docTotalCost',
      'catList',
      'author',
      'authorCh',
      'fondObject',
      'note',
      'FI',
      'templateBaseDoc',
      'refReason',
      'internal',
      'budget',
      'CSU',
      'fondObject',
      'numberRegistryFI',
      'onlyYear',
      'includeBudgetAmplification',
      'autoGenNote',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
