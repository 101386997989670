import React, {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  &.pined .card-body * {
      scroll-margin-top: ${({ headerheight }) => headerheight}px;
      scroll-margin-bottom: ${({ footerheight }) => footerheight}px;
  }
`;
function TableContainer({
  // eslint-disable-next-line no-unused-vars
  className, children, pinHeader, pinAllowed, onAllowPin,
}) {
  const [partHeights, setPartHeight] = useState({
    header: 0,
    footer: 0,
    window: 0,
  });
  const tpRef = useRef();
  const measure = useCallback(
    () => {
      if (tpRef.current) {
        const header = tpRef.current.querySelector('.card-header');
        const footer = tpRef.current.querySelector('.card-footer');
        setPartHeight({
          header: header ? header.getBoundingClientRect().height : 0,
          footer: footer ? footer.getBoundingClientRect().height : 0,
          window: window.innerHeight,
        });
      }
    },
    [],
  );
  useEffect(
    () => {
      if (pinAllowed && (partHeights.header + partHeights.footer) * 3 >= partHeights.window) {
        onAllowPin(false);
      }
      if (!pinAllowed && (partHeights.header + partHeights.footer) * 3 < partHeights.window) {
        onAllowPin(true);
      }
    },
    [onAllowPin, partHeights.footer, partHeights.header, partHeights.window, pinAllowed],
  );

  const resizeObServer = useRef();
  useLayoutEffect(() => {
    resizeObServer.current = new ResizeObserver(
      () => measure(),
    );
    resizeObServer.current.observe(document.body);
  }, [measure]);
  useEffect(() => () => resizeObServer.current.unobserve(document.body), []);
  return (
    <StyledCard
      className={classNames(className, { pined: pinHeader && pinAllowed })}
      ref={tpRef}
      headerheight={partHeights.header}
      footerheight={partHeights.footer}
    >
      {children}
    </StyledCard>
  );
}

TableContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  pinHeader: PropTypes.bool,
  pinAllowed: PropTypes.bool,
  onAllowPin: PropTypes.func.isRequired,
};

TableContainer.defaultProps = {
  className: 'border-0',
  pinHeader: false,
  pinAllowed: true,
};

export default TableContainer;
