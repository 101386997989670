import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import md from '../../../../constants/meta/index';
import ea from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
} from '../../../../components/styledTable/styledTable';
import Fields from '../../../field';
import { InputStyled } from '../../../field/selectorField/item/styles';
import storePathParam from '../../../../common/storePathParam';

const coldef = md.dp.docSelectionForecast.tables.docs.columns;

const TABLENAME = 'docs';

const GridDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: "appr doc note";
  grid-template-columns: 60px  ;
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  top: 0;
  bottom: 0;
  position: ${({ isHeader }) => (isHeader ? 'sticky' : 'unset')};
  z-index: ${({ isHeader }) => (isHeader ? '3' : 'unset')};
`;
const DivChek = styled(DivCell)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

function HeaderComponent() {
  return (
    <GridDiv isHeader>
      <DivHeader areaName="appr">{coldef.appr.label}</DivHeader>
      <DivHeader areaName="doc">{coldef.doc.label}</DivHeader>
      <DivHeader areaName="note">{coldef.note.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivChek areaName="appr">
          <Fields.CheckboxField
            value={row.get('appr', false)}
            onChange={(e, value) => dispatch(ea.changeField([`tables/${TABLENAME}`, rowId, 'appr'], value))}
          />
        </DivChek>
        <DivCell areaName="doc">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('doc', new Map())}
          />
        </DivCell>
        <DivCell areaName="note">
          <InputStyled
            disabled
            value={row.get('note', '')}
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const enchance = compose(
  connect(mapState),
  getBaseTP,
);

export default enchance(TABLENAME, RowComponent, HeaderComponent);
