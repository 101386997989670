/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import useTablePart from '../../../../../newEditor/hook/tablepart';

const tableMeta = md.tables.wageStructure;

function WageStructureTP({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const totals = useMemo(
    () => ({
      sum_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sum_2.name] || 0), 0),
      zf_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf_2.name] || 0), 0),
      sf_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf_2.name] || 0), 0),
      zfsf_2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zfsf_2.name] || 0), 0),
      sum_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sum_1.name] || 0), 0),
      zf_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf_1.name] || 0), 0),
      sf_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf_1.name] || 0), 0),
      zfsf_1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zfsf_1.name] || 0), 0),
      sum0: tableData.reduce((R, r) => R + (r[tableMeta.columns.sum0.name] || 0), 0),
      zf0: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf0.name] || 0), 0),
      sf0: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf0.name] || 0), 0),
      zfsf0: tableData.reduce((R, r) => R + (r[tableMeta.columns.zfsf0.name] || 0), 0),
      sum1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sum1.name] || 0), 0),
      zf1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf1.name] || 0), 0),
      sf1: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf1.name] || 0), 0),
      zfsf1: tableData.reduce((R, r) => R + (r[tableMeta.columns.zfsf1.name] || 0), 0),
      sum2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sum2.name] || 0), 0),
      zf2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zf2.name] || 0), 0),
      sf2: tableData.reduce((R, r) => R + (r[tableMeta.columns.sf2.name] || 0), 0),
      zfsf2: tableData.reduce((R, r) => R + (r[tableMeta.columns.zfsf2.name] || 0), 0),
    }),
    [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          year={data[md.columns.year.name]}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

WageStructureTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

WageStructureTP.defaultProps = {
  readOnly: false,
};

export default memo(WageStructureTP);
