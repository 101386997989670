import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  DateInput,
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/treasuryStatementFinObligation';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col>
            <TableRowCell column="dateDoc" highlighted={highlights.includes('dateDoc')}>
              <DateInput
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.dateDoc.name]: v })}
                value={row[tableMD.columns.dateDoc.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="dateDocF" highlighted={highlights.includes('dateDocF')}>
              <DateInput
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.dateDocF.name]: v })}
                value={row[tableMD.columns.dateDocF.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="dateR" highlighted={highlights.includes('dateR')}>
              <DateInput
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.dateR.name]: v })}
                value={row[tableMD.columns.dateR.name]}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="numberDoc" highlighted={highlights.includes('numberDoc')}>
              <StringInput
                value={row[tableMD.columns.numberDoc.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.numberDoc.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="numberDocF" highlighted={highlights.includes('numberDocF')}>
              <StringInput
                value={row[tableMD.columns.numberDocF.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.numberDocF.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="KTKVCode" highlighted={highlights.includes('KTKVCode')}>
              <StringInput
                value={row[tableMD.columns.KTKVCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KTKVCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="Code" highlighted={highlights.includes('Code')}>
              <StringInput
                value={row[tableMD.columns.Code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.Code.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="flag" highlighted={highlights.includes('flag')}>
              <StringInput
                value={row[tableMD.columns.flag.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.flag.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="FondCode" highlighted={highlights.includes('FondCode')}>
              <StringInput
                value={row[tableMD.columns.FondCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.FondCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KVKCode" highlighted={highlights.includes('KVKCode')}>
              <StringInput
                value={row[tableMD.columns.KVKCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KVKCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="KEKVCode" highlighted={highlights.includes('KEKVCode')}>
              <StringInput
                value={row[tableMD.columns.KEKVCode.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KEKVCode.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumAdvance" highlighted={highlights.includes('sumAdvance')}>
              <NumberInput
                value={row[tableMD.columns.sumAdvance.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumAdvance.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <StringInput
                value={row[tableMD.columns.note.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.note.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="edrpou" highlighted={highlights.includes('edrpou')}>
              <StringInput
                value={row[tableMD.columns.edrpou.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.edrpou.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="edrpouK" highlighted={highlights.includes('edrpouK')}>
              <StringInput
                value={row[tableMD.columns.edrpouK.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.edrpouK.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col>
            <TableRowCell column="nameCSU" highlighted={highlights.includes('nameCSU')}>
              <StringInput
                value={row[tableMD.columns.nameCSU.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.nameCSU.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="mfoK" highlighted={highlights.includes('mfoK')}>
              <StringInput
                value={row[tableMD.columns.mfoK.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.mfoK.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="account" highlighted={highlights.includes('account')}>
              <StringInput
                value={row[tableMD.columns.account.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.account.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
