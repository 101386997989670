const frontendURL = '/doc/institutionstructurebfn/';
const backendURL = '/api/bfn/bfninststructheader/';
const printURL = '';
const name = 'Дані, щодо чисельності дітей та структури закладу дошкільної освіти';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  name,
};

export default instance;
