import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  byPCM: { label: 'За ПЦМ', name: 'ФлПЦМ' },
  listQuarter: { label: 'З початку року по', name: 'сзКвартал' },
  organisation: { label: 'Установа', name: 'Распорядитель' },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  selectedDocuments: { label: 'За відібраними документами', name: 'ПоОтобранымДокументам' },
  showCSUAs: { label: 'Показувати розпорядника як', name: 'ПоказыватьРаспКак' },
  showKFKAs: { label: 'Показувати КПКВ як', name: 'ПоказыватьКФККак' },
  planEst: { label: 'Тип звіту', name: 'ПланСмета' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  fund: { label: 'Фонд', name: 'Фонд' },
  elementFond: { label: 'Деталізація фонд', name: 'ЭлементФонд' },
  showList1: { label: 'Тільки вибрані фонди', name: 'ПоказатьСписок1' },
  kbp7Classifier: { label: 'КБП7', name: 'КФК' },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  showList: { label: 'Тільки вибрані КПКВ', name: 'ПоказатьСписок' },
  byReducedKEKV: { label: 'За скороченими КЕКВ', name: 'КЕКВСкор' },
  byGroupsKEKV: { label: 'За групами КЕКВ', name: 'КЕКВГруппы' },
  byAllKEKV: { label: 'За всіма КЕКВ', name: 'КЕКВВсе' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГрн' },
  budgetTL_TERRA: { label: 'Друкувати (вивантажувати у файл) код  КОАТУУ як код бюджету', name: 'TL_TERRA' },
  planOfUse: {
    label: 'Зведено за КЕКВ для плану використання',
    name: 'СводПоКЕКВПланИспользования',
  },
  NumOfRegister: { label: 'Номер реєстру змін', name: 'НомерРеестраИзм' },
  transcriptKEKV: { label: 'Розшифровка КЕКВ', name: 'флРасшифровкаКЕКВ' },
  assign: { label: 'Вид даних що вивантажуються', name: 'Ассигнования' },
  path: { label: 'Шлях до файлу для виванаження', name: 'Путь' },
  nameFileChange: { label: 'Назва файлу змін  ZMINV_', name: 'флИмяФайлаИзм' },
  codeInName: { label: 'Код розпорядника в імені файла ', name: 'КодВИмени' },
  NR: { label: 'Номер реєстру (при вивантаженні змін) ', name: 'NR' },
  inHeadCSU: { label: 'Записувати у файл дані по головному розпоряднику, а не по установі ', name: 'ПоГолРасп' },
  NoYearSumm: { label: 'Не вивантажувати річні суми ', name: 'БезГодСум' },
  NoYearSummIfZero: { label: 'Не записувати дані по яким річні суми дорівнюють "0" ', name: 'НеПоказСуммаГодНулевая' },
  NOM: { label: 'Значення для поля  NOM тобто номеру реєстру , якщо не вказати  значення  тоді  буде присвоєно номер довідки ', name: 'NOM' },
  onlyYear: { label: 'Тільки річні суми ', name: 'ТолькоГод' },
  folders: { label: 'Створювати окрему папку для кожної довідки ', name: 'ПоПапкам' },
  PCM: { label: 'За класифікаторами ПЦМ', name: 'ПЦМ' },
  consolidated: { label: 'Зведений', name: 'Зведений' },
  UploadAsChanges: { label: 'Вивантажувати плановиі документи у форматі документів для змін', name: 'ВыгрузитьКакСправку' },
  noZero: { label: 'Не виводити нулі на початку коду мережі', name: 'БезНулейВКодеМережи' },
  KOATUU: { label: 'Код КОАТУУ як код території', name: 'флКОАТУУ' },
  printingDate: { label: 'Дата для друку', name: 'ДатаДляПечати' },
  printingName: { label: 'Ім\'я та ПРІЗВИЩЕ', name: 'флИмяФамилия' },
  flDNF: { label: 'Назва файлу довідки про надходження у натуральній формі  DNF_', name: 'флИмяФайлаИзмНат' },
  isShowKBP7: { label: 'Виводити КПКВ', name: 'флВыводитьКБП7' },
  isShowKDB: { label: 'Виводити КДБ', name: 'флВыводитьКДБ' },
  isShowKFB: { label: 'Виводити КФБ', name: 'флВыводитьКФБ' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
};

const tables = {
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Використання', name: 'Использование' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      Element: { label: 'Элемент группа', name: 'ЭлементГруппа' },
    },
  },
  tpTVChange: {
    name: 'ТЗИзм',
    label: 'ТЗИзм',
    columns: {
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      SRK: { label: 'SRK', name: 'SRK' },
      KVK: { label: 'KVK', name: 'KVK' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      UDK: { label: 'UDK', name: 'UDK' },
      VDK: { label: 'VDK', name: 'VDK' },
      KPK: { label: 'KPK', name: 'KPK' },
      KFK: { label: 'KFK', name: 'KFK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      SK: { label: 'SK', name: 'SK' },
      SUTVG: { label: 'SUTVG', name: 'SUTVG' },
      SUTVSG: { label: 'SUTVSG', name: 'SUTVSG' },
      SUTVS: { label: 'SUTVS', name: 'SUTVS' },
      S100: { label: 'S100', name: 'S100' },
      S101: { label: 'S101', name: 'S101' },
      S102: { label: 'S102', name: 'S102' },
      S103: { label: 'S103', name: 'S103' },
      S104: { label: 'S104', name: 'S104' },
      SUTVD: { label: 'SUTVD', name: 'SUTVD' },
      S200: { label: 'S200', name: 'S200' },
      S201: { label: 'S201', name: 'S201' },
      S202: { label: 'S202', name: 'S202' },
      S203: { label: 'S203', name: 'S203' },
      S204: { label: 'S204', name: 'S204' },
      S205: { label: 'S205', name: 'S205' },
      SUTVI: { label: 'SUTVI', name: 'SUTVI' },
      SUTVR: { label: 'SUTVR', name: 'SUTVR' },
      SUTV1: { label: 'SUTV1', name: 'SUTV1' },
      SUTV2: { label: 'SUTV2', name: 'SUTV2' },
      SUTV3: { label: 'SUTV3', name: 'SUTV3' },
      SUTV4: { label: 'SUTV4', name: 'SUTV4' },
      SUTV5: { label: 'SUTV5', name: 'SUTV5' },
      SUTV6: { label: 'SUTV6', name: 'SUTV6' },
      SUTV7: { label: 'SUTV7', name: 'SUTV7' },
      SUTV8: { label: 'SUTV8', name: 'SUTV8' },
      SUTV9: { label: 'SUTV9', name: 'SUTV9' },
      SUTV10: { label: 'SUTV10', name: 'SUTV10' },
      SUTV11: { label: 'SUTV11', name: 'SUTV11' },
      SUTV12: { label: 'SUTV12', name: 'SUTV12' },
      NR: { label: 'NR', name: 'NR' },
      NOM: { label: 'NOM', name: 'NOM' },
      DAT: { label: 'DAT', name: 'DAT' },
    },
  },
  tpTVPlan: {
    name: 'ТЗПлан',
    label: 'ТЗПлан',
    columns: {
      BUDGET: { label: 'BUDGET', name: 'BUDGET' },
      SRK: { label: 'SRK', name: 'SRK' },
      KVK: { label: 'KVK', name: 'KVK' },
      KPOL: { label: 'KPOL', name: 'KPOL' },
      UDK: { label: 'UDK', name: 'UDK' },
      VDK: { label: 'VDK', name: 'VDK' },
      KPK: { label: 'KPK', name: 'KPK' },
      KFK: { label: 'KFK', name: 'KFK' },
      KEKV: { label: 'KEKV', name: 'KEKV' },
      SK: { label: 'SK', name: 'SK' },
      SUTVG: { label: 'SUTVG', name: 'SUTVG' },
      SUTVSG: { label: 'SUTVSG', name: 'SUTVSG' },
      SUTVS: { label: 'SUTVS', name: 'SUTVS' },
      S100: { label: 'S100', name: 'S100' },
      S101: { label: 'S101', name: 'S101' },
      S102: { label: 'S102', name: 'S102' },
      S103: { label: 'S103', name: 'S103' },
      S104: { label: 'S104', name: 'S104' },
      SUTVD: { label: 'SUTVD', name: 'SUTVD' },
      S200: { label: 'S200', name: 'S200' },
      S201: { label: 'S201', name: 'S201' },
      S202: { label: 'S202', name: 'S202' },
      S203: { label: 'S203', name: 'S203' },
      S204: { label: 'S204', name: 'S204' },
      S205: { label: 'S205', name: 'S205' },
      SUTVI: { label: 'SUTVI', name: 'SUTVI' },
      SUTVR: { label: 'SUTVR', name: 'SUTVR' },
      SUTV1: { label: 'SUTV1', name: 'SUTV1' },
      SUTV2: { label: 'SUTV2', name: 'SUTV2' },
      SUTV3: { label: 'SUTV3', name: 'SUTV3' },
      SUTV4: { label: 'SUTV4', name: 'SUTV4' },
      SUTV5: { label: 'SUTV5', name: 'SUTV5' },
      SUTV6: { label: 'SUTV6', name: 'SUTV6' },
      SUTV7: { label: 'SUTV7', name: 'SUTV7' },
      SUTV8: { label: 'SUTV8', name: 'SUTV8' },
      SUTV9: { label: 'SUTV9', name: 'SUTV9' },
      SUTV10: { label: 'SUTV10', name: 'SUTV10' },
      SUTV11: { label: 'SUTV11', name: 'SUTV11' },
      SUTV12: { label: 'SUTV12', name: 'SUTV12' },
      NR: { label: 'NR', name: 'NR' },
    },
  },
  tpDoc: {
    name: 'СписокОтобранныхДокументов',
    label: 'СписокОтобранныхДокументов',
    columns: {
      mark: { label: 'Відбір', name: 'Пометка' },
      val: { label: 'Значение', name: 'Значение' },
      list: { label: 'Документ', name: 'Передставление' },
    },
  },
};

const definition = {
  label: 'Казначейські звіти',
  name: 'TreasureReports',
  columns,
  tables,
  frontend: 'rep/TreasureReports',
  backendName: 'КазначейскиеОтчеты',
  resultColumns: baseResultColumns,
};

export default definition;
