import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'wqg1z9ir3YY',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: false },
  isApproved: { label: 'Затверджено', name: 'ДокументУтвержден', visible: false },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Доходи',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKPKV: { label: 'Елемент КПКВ', name: 'КБПМБ' },
      realPeriod: { label: 'Загальний період реалізації проекту (рік початку і завершення)', name: 'ПериодРеализации' },
      price: { label: 'Загальна вартість проекту', name: 'Стоимость' },
      ready: { label: 'Очікуваний рівень готовності проекту на кінець 20__року (план), %', name: 'Готовность' },
      invest: { label: 'Найменування інвестиційного проекту', name: 'ИнвестПроект' },
      KBP: { label: 'КБП', name: 'КБП' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Обсяги капітальних вкладень місцевого бюджету у розрізі інвестиційних проектів  (Додаток 10)',
  name: 'forecastAdd10',
  backendName: 'ПрогнозДод10',
  columns,
  frontend: 'doc/forecastAdd10',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
