import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/liquidity';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

import { InputWithDropdown } from '../../../../../../components/styledInputs';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';

const tablename = 'dod3';

const tableMD = md.tables[tablename];

const repMonth = {
  m1: { label: 'Січень', name: 'Січень' },
  m2: { label: 'Лютий', name: 'Лютий' },
  m3: { label: 'Березень', name: 'Березень' },
  m4: { label: 'Квітень', name: 'Квітень' },
  m5: { label: 'Травень', name: 'Травень' },
  m6: { label: 'Червень', name: 'Червень' },
  m7: { label: 'Липень', name: 'Липень' },
  m8: { label: 'Серпень', name: 'Серпень' },
  m9: { label: 'Вересень', name: 'Вересень' },
  m10: { label: 'Жовтень', name: 'Жовтень' },
  m11: { label: 'Листопад', name: 'Листопад' },
  m12: { label: 'Грудень', name: 'Грудень' },
};

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="month" highlighted={highlights.includes('month')}>
              <InputWithDropdown
                options={Object.values(repMonth)}
                value={row[tableMD.columns.month.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.month.name]: v })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="year" highlighted={highlights.includes('year')}>
              <EditorControls.YearInput
                value={row[tableMD.columns.year.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.year.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.year.name]: PropTypes.number,
    [tableMD.columns.month.name]: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
