import React from 'react';

function OpenedFolderDeleted() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#fffead" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 5.5V2.5H9L10 4.5H17.5V16.5" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 16.5L16.5 7.5H9L8 5.5H2.5L3.5 16.5H17.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.1212 14.1213C13.2928 12.9497 13.2928 11.0503 12.1212 9.8787C10.9497 8.70714 9.05019 8.70714 7.87862 9.8787C6.70706 11.0503 6.70706 12.9497 7.87862 14.1213C9.05019 15.2929 10.9497 15.2929 12.1212 14.1213Z" fill="#FF0000" />
      <path d="M9.30005 11.3L10.7 12.7" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.30005 12.7L10.7 11.3" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default OpenedFolderDeleted;
