import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  activeCol, highlights, onToggleHighlight, year, totals,
}) {
  const yearDef = year || new Date().getFullYear();
  const year1 = `${yearDef + 1}p.`;
  const year2 = `${yearDef + 2}p.`;
  const years = `${yearDef + 1}-${yearDef + 2}р.`;

  return (
    <TableHeaderRow>
      <Col>
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kekv'}
              highlighted={highlights.includes('kekv')}
              onToggleHighlight={() => onToggleHighlight('kekv')}
            >
              КЕКВ
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total0'}
              highlighted={highlights.includes('amount_total0')}
              onToggleHighlight={() => onToggleHighlight('amount_total0')}
              title={totals.amount_total0.toLocaleString('uk', moneysStingOptions)}
            >
              Необхідно додатково на
              {' '}
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total1'}
              highlighted={highlights.includes('amount_total1')}
              onToggleHighlight={() => onToggleHighlight('amount_total1')}
              title={totals.amount_total1.toLocaleString('uk', moneysStingOptions)}
            >
              Необхідно додатково на
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total2'}
              highlighted={highlights.includes('amount_total2')}
              onToggleHighlight={() => onToggleHighlight('amount_total2')}
              title={totals.amount_total2.toLocaleString('uk', moneysStingOptions)}
            >
              Необхідно додатково на
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason1'}
              highlighted={highlights.includes('reason1')}
              onToggleHighlight={() => onToggleHighlight('reason1')}
            >
              Обгрунтування необхідності додаткових коштів на
              {' '}
              {yearDef}
              р.
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'reason2'}
              highlighted={highlights.includes('reason2')}
              onToggleHighlight={() => onToggleHighlight('reason2')}
            >
              Обгрунтування необхідності додаткових коштів на
              {' '}
              {years}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_total0: PropTypes.number,
    amount_total1: PropTypes.number,
    amount_total2: PropTypes.number,
  }).isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
