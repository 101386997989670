import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button, ButtonGroup,
  Card, Col, Form, Nav, Navbar, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faCheck,
  faComment, faFilter, faUpload,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import md from '../../../../constants/meta/dataprocessors/docSelectionUpLevel';
import { moneysStingOptions } from '../../../../constants/common';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import assChangesPlanMD from '../../../../constants/meta/documents/changesToAssignmentPlanning';

const tableMeta = md.tables.data;

function Documents({
  data, onChange, onSR, onOk,
}) {
  const onToggleCheck = useCallback(({ checked, rowDataId }) => onChange((o) => {
    const checkedEl = o[md.tables.data.name][rowDataId];

    checkedEl[md.tables.data.columns.Check.name] = checked;

    o[md.tables.data.name][rowDataId] = checkedEl;

    return o;
  }), [onChange]);

  const onCheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.data.name]: o[md.tables.data.name]
        .map((row) => ({ ...row, [md.tables.data.columns.Check.name]: true })),
    })),
    [onChange],
  );
  const onUncheckAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.data.name]: o[md.tables.data.name]
        .map((row) => ({ ...row, [md.tables.data.columns.Check.name]: false })),
    })),
    [onChange],
  );
  const onToggleAll = useCallback(
    () => onChange((o) => ({
      ...o,
      [md.tables.data.name]: o[md.tables.data.name]
        .map((row) => ({
          ...row,
          [md.tables.data.columns.Check.name]: !row[md.tables.data.columns.Check.name],
        })),
    })),
    [onChange],
  );
  const checkedDocs = data.filter((d) => d[tableMeta.columns.Check.name]);
  const checkedDocCount = checkedDocs.length;
  const totSum = checkedDocs.reduce((R, d) => R + d[tableMeta.columns.Sum.name], 0);

  return (
    <>
      <Navbar variant="light" expand="sm" className="border rounded bg-light" sticky="top">
        <Nav className="w-100">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => onSR('FILL_TABLE')}>
              <FontAwesomeIcon icon={faFilter} className="me-2" />
              Відібрати
            </Button>
            <Button variant="outline-success" onClick={() => onOk()} disabled={!checkedDocCount}>
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              Завантажити
            </Button>
          </ButtonGroup>

          <ButtonGroup className="ms-2">
            <DefaultCPButton onClick={onCheckAll} label="Відмітити все" variant="outline-primary" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 " />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onUncheckAll} label="Зняти всі відмитки" variant="outline-secondary" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
            <DefaultCPButton onClick={onToggleAll} label="Змінити все" variant="outline-success" disabled={!checkedDocCount}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 up-10" />
                <FontAwesomeIcon icon={faSquare} transform="shrink-6 " />
                <FontAwesomeIcon icon={faCheck} transform="shrink-6 down-10" />
              </span>
            </DefaultCPButton>
          </ButtonGroup>
          <div className="ms-auto d-flex align-items-center">
            Відімічено
            <Badge className="mx-2" variant="dark">{checkedDocCount}</Badge>
            документів, на суму
            <Badge className="mx-2" variant="dark">{totSum.toLocaleString('uk', moneysStingOptions)}</Badge>
            грн
          </div>
        </Nav>
      </Navbar>
      <>
        {data.map((doc, rowDataId) => (
          <Card
            key={doc[tableMeta.columns.DocumentRep.name].id}
            border={doc[tableMeta.columns.Check.name] ? 'primary' : 'light'}
          >
            <Card.Header className="d-flex">
              <Card.Title className="flex-grow-1">
                <Link to={`/${assChangesPlanMD.frontend}/${doc[tableMeta.columns.DocumentRep.name].id}`}>
                  {doc[tableMeta.columns.DocumentRep.name]}
                </Link>
              </Card.Title>
              <EditorControls.CheckboxInput
                controlId={`check-for-document-${rowDataId}`}
                onChange={(e, v) => onToggleCheck({
                  rowDataId,
                  checked: v,
                })}
                description="Завантажувати"
                value={doc[tableMeta.columns.Check.name]}
              />
            </Card.Header>
            <Card.Body>
              <Row noGutters>
                <Col xl={6}>
                  <Card.Text className="d-flex_ align-items-end mb-2 text-center text-xl-left">
                    <Form.Label className="me-1">КПКВ:</Form.Label>
                    <span className="text-primary font-weight-bold">
                      {doc[tableMeta.columns.KPKVCode.name]}
                    </span>
                  </Card.Text>
                </Col>
                <Col xl={6}>
                  <Card.Title as="h4" className="text-center text-xl-right text-success mb-3">
                    {doc[tableMeta.columns.Sum.name].toLocaleString('uk', moneysStingOptions)}
                  </Card.Title>
                </Col>
              </Row>
              {doc[tableMeta.columns.Notes.name] && (
                <Card.Text>
                  <hr />
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  {doc[tableMeta.columns.Notes.name]}
                  <hr />
                </Card.Text>
              )}
            </Card.Body>
            <Card.Footer className="text-muted">
              <Row noGutters sm={2}>
                <Col>
                  <Card.Text>
                    {doc[tableMeta.columns.CSU.name].repr && (
                      <>
                        <FontAwesomeIcon icon={faBuilding} className="me-2" />
                        {doc[tableMeta.columns.CSU.name].repr}
                      </>
                    )}
                  </Card.Text>

                </Col>
                <Col className="text-right">
                  <Card.Text>
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    {doc[tableMeta.columns.FondRep.name].repr}
                  </Card.Text>

                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ))}
      </>
    </>
  );
}

Documents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({

  })),
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  data: [],
};
export default Documents;
