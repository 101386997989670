import React from 'react';
import PropTypes from 'prop-types';
import { faSync as icon } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../../components/bootstrap_components/controls/defaultButton';

function RefreshButton({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      icon={icon}
      onClick={onClick}
      label={label}
      disabled={disabled}
    />
  );
}

RefreshButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

RefreshButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Оновити',
};

export default RefreshButton;
