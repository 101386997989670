import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditorHOC, { mapState, getRouterEditor } from '../basic';
import { typesRepairElChTP as def } from '../../../constants/meta/ChTP/typesRepairElChTP';
import { editor as editorActions } from '../../../actions';
import { catEditorMenu as BasicMenu } from '../../../components/editorMenu';
import GetTextField from '../../field/TextInput';
import { StyledLabel, TwoRow, FiveRow } from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';

const TextField50 = GetTextField(50);
const getTypeValue = (headerForm, param) => headerForm.getIn(['typeValue', param], false) !== false;

const setTypeValue = (headerForm, value, param) => {
  const typeValue = headerForm.get('typeValue', new Map());
  if (value) { return typeValue.set(param, param); }
  return typeValue.delete(param);
};

function TypesRepairElChTP({ headerForm, dispatch }) {
  return (
    <>
      <TwoRow>
        <div>
          <StyledLabel>{def.columns.code.label}</StyledLabel>
          <TextField50
            value={headerForm.get('code', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'code'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.name.label}</StyledLabel>
          <TextField50
            value={headerForm.get('name', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'name'], value))}
          />
        </div>
      </TwoRow>
      <FiveRow>
        <CheckBoxField
          label="Текст"
          value={getTypeValue(headerForm, 'string')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'string')))}
        />

        <CheckBoxField
          label="Число"
          value={getTypeValue(headerForm, 'number')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'number')))}
        />

        <CheckBoxField
          label="Дата"
          value={getTypeValue(headerForm, 'date')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'date')))}
        />

        <CheckBoxField
          label='Відповідь на питання "Так/ні"'
          value={getTypeValue(headerForm, 'boolean')}

          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'boolean')))}
        />

        <CheckBoxField
          label="Додаткова фналітика за видами ремонту"
          value={getTypeValue(headerForm, 'Справочник.ДопХВидовРемонта')}
          onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'typeValue'], setTypeValue(headerForm, value, 'Справочник.ДопХВидовРемонта')))}
        />
      </FiveRow>
    </>
  );
}

TypesRepairElChTP.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapState),
  EditorHOC(
    {
      type: 'ChTP',
      name: 'typesRepairElChTP',
    },
    {
      menu: BasicMenu,
    },
  ),
);

export const StdEditor = enhance(TypesRepairElChTP);

export default getRouterEditor(def)(StdEditor);
