import React from 'react';
import PropTypes from 'prop-types';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';

function UnExecuteButton({ disabled, onClick, label }) {
  return (
    <DefaultCPButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      variant="warning"
      icon={faBan}
      showLabel
    />
  );
}

UnExecuteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

UnExecuteButton.defaultProps = {
  disabled: false,
  onClick: null,
  label: 'Відмінити проведення',
};

export default UnExecuteButton;
