import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={5} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInGF_Report'}
              highlighted={highlights.includes('garantInGF_Report')}
              onToggleHighlght={() => onToggleHighlght('garantInGF_Report')}
            >
              {`${activeYear - 2} (звіт) у національній валюті (грн)`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInSF_Report'}
              highlighted={highlights.includes('garantInSF_Report')}
              onToggleHighlght={() => onToggleHighlght('garantInSF_Report')}
            >
              {`${activeYear - 2} (звіт) в іноземній валюті`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInGF_Approved'}
              highlighted={highlights.includes('garantInGF_Approved')}
              onToggleHighlght={() => onToggleHighlght('garantInGF_Approved')}
            >
              {`${activeYear - 1} (затверджено) у національній валюті (грн)`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInSF_Approved'}
              highlighted={highlights.includes('garantInSF_Approved')}
              onToggleHighlght={() => onToggleHighlght('garantInSF_Approved')}
            >
              {`${activeYear - 1} (затверджено) в іноземній валюті`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInGF_Plan'}
              highlighted={highlights.includes('garantInGF_Plan')}
              onToggleHighlght={() => onToggleHighlght('garantInGF_Plan')}
            >
              {`${activeYear} (план) у національній валюті (грн)`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInSF_Plan'}
              highlighted={highlights.includes('garantInSF_Plan')}
              onToggleHighlght={() => onToggleHighlght('garantInSF_Plan')}
            >
              {`${activeYear} (план) в іноземній валюті`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInGF_Plan1'}
              highlighted={highlights.includes('garantInGF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('garantInGF_Plan1')}
            >
              {`${activeYear + 1} (план) у національній валюті (грн)`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInSF_Plan1'}
              highlighted={highlights.includes('garantInSF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('garantInSF_Plan1')}
            >
              {`${activeYear + 1} (план) в іноземній валюті`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInGF_Plan2'}
              highlighted={highlights.includes('garantInGF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('garantInGF_Plan2')}
            >
              {`${activeYear + 2} (план) у національній валюті (грн)`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'garantInSF_Plan2'}
              highlighted={highlights.includes('garantInSF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('garantInSF_Plan2')}
            >
              {`${activeYear + 2} (план) в іноземній валюті`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  activeYear: PropTypes.number,
};

TPHeader.defaultProps = {
  activeCol: '',
  activeYear: 0,
};

export default memo(TPHeader);
