import React from 'react';

function CheckAllIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="0.5" width="7" height="7" rx="1.5" stroke="#a9a5a5" fill=" #ffffff80" />
      <rect x="4.5" y="8.5" width="7" height="7" rx="1.5" stroke="#a9a5a5" fill=" #ffffff80" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.058 3.493L7.084 4.526L9.944 1.5L11 2.558L7.089 6.644L5 4.551L6.058 3.493Z" fill="#4281C9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.058 11.493L7.084 12.526L9.944 9.5L11 10.558L7.089 14.644L5 12.551L6.058 11.493Z" fill="#4281C9" />
    </svg>
  );
}

export default CheckAllIcon;
