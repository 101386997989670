import assignmentPlanning from './assignmentPlanning';
import changesToAssignmentPlanning from './changesToAssignmentPlanning';
import financingRequest from './financingRequest';
import financing from './financing';
import financingPropose from './financingPropose';
import assignmentPlanningRev from './assignmentPlanningRev';
import assignmentPlanningSource from './assignmentPlanningSource';
import changeToAssignPlanRev from './changeToAssignPlanRev';
import changeToAssignPlanSource from './changeToAssignPlanSource';
import draftAnnualRev from './draftAnnualRev';
import draftAnnualSpend from './draftAnnualSpend';
import changeDraftAnnualRev from './changeDraftAnnualRev';
import changeDraftAnnualSpend from './changeDraftAnnualSpend';
import limitReference from './limitReference';
import publicBudget from './publicBudget';
import socialPayments from './socialPayments';
import indicatorPassport from './indicatorPassport';
import indicatorFact from './indicatorFact';
import investmentProposalObjects from './investmentProposalObjects';
import investmentProposalObjectsChanges from './investmentProposalObjectsChanges';
import budgetRequest from './budgetRequest';
import legalObligation from './legalObligation';
import financeObligation from './financeObligation';
import payment from './payment';
import paymentTransfer from './paymentTransfer';
import docRegister from './docRegistr';
import financingRepairObjects from './financingRepairObjects';
import financingApplicationReversal from './financingApplicationReversal';
import financingSources from './financingSources';
import financingCashbox from './financingCashbox';
import treasuryStatementIncome from './treasuryStatementIncome';
import treasuryStatementCosts from './treasuryStatementCosts';
import registrationForm from './registrationForm';
import incomingMoney from './incomingMoney';
import docTransferToLogica from './docTransferToLogica';
import docTransferToLogicaSinging from './docTransferToLogicaSinging';
import draftAnnualSources from './draftAnnualSources';
import changeToDraftAnnualSources from './changeToDraftAnnualSources';
import draftAnnualCredit from './draftAnnualCredit';
import changeDraftAnnualCredit from './changeDraftAnnualCredit';
import draftAnnualTransfert from './draftAnnualTransfert';
import changeDraftAnnualTransfert from './changeDraftAnnualTransfert';
import draftAnnualLoan from './draftAnnualLoan';
import changeDraftAnnualLoan from './changeDraftAnnualLoan';
import draftAnnualBR from './draftAnnualBR';
import changeDraftAnnualBR from './changeDraftAnnualBR';
import forecastAdd1 from './forecastAdd1';
import forecastAdd2 from './forecastAdd2';
import forecastAdd3 from './forecastAdd3';
import forecastAdd4 from './forecastAdd4';
import forecastAdd5 from './forecastAdd5';
import forecastAdd6 from './forecastAdd6';
import forecastAdd7 from './forecastAdd7';
import forecastAdd8 from './forecastAdd8';
import forecastAdd12 from './forecastAdd12';
import forecastAdd11 from './forecastAdd11';
import forecastAdd10 from './forecastAdd10';
import forecastAdd9 from './forecastAdd9';
import prepareForecastAdd1 from './prepareForecastAdd1';
import prepareForecastAdd2 from './prepareForecastAdd2';
import prepareForecastAdd5 from './prepareForecastAdd5';
import prepareForecastAdd4 from './prepareForecastAdd4';
import prepareForecastAdd3 from './prepareForecastAdd3';
import freeRem from './freeRem';
import freeRem3Add from './freeRem3Add';
import freeRem4Add from './freeRem4Add';
import decisionNotification from './decisionNotification';
import taxes from './taxes';
import taxes2 from './taxes2';
import taxes2P from './taxes2P';
import taxes3 from './taxes3';
import taxes3P from './taxes3P';
import taxes4 from './taxes4';
import taxes5 from './taxes5';
import taxes6 from './taxes6';
import taxes7 from './taxes7';
import taxes8 from './taxes8';
import taxes11 from './taxes11';
import bfnInstitutionStructure from './bfnInstitutionStructure';
import bfnAllocation from './bfnAllocation';
import netFOP from './netFOP';
import netCategory from './netCategory';
import net from './net';
import netChange from './netChange';
import borderLimit from './borderLimit';
import changeBorderLimit from './changeBorderLimit';
import netCategoryPlan from './netCategoryPlan';
import expensesOnEducation from './expensesOnEducation';
import treasuryStatementFinObligation from './treasuryStatementFinObligation';
import treasuryStatementLegalObligation from './treasuryStatementLegalObligation';
import garanty from './garanty';
import energyIndicators from './energyIndicators';
import expensesOnHealth from './expensesOnHealth';
import transfertPlanning from './transfertPlanning';
import treasuryStatementIncomeSB from './treasuryStatementIncomeSB';
import changeTransfertPlanning from './changeTransfertPlanning';
import balancesAccounts from './balancesAccounts';
import countExpensesOnEducation from './countExpensesOnEducation';
import localDebt from './localDebt';
import dataEducationPayment from './dataEducationPayment';
import freeRem4Add2024 from './freeRem4Add2024';
import liquidityVer2 from './liquidityVer2';
import liquidity from './liquidity';

export { assignmentPlanning, changesToAssignmentPlanning };

const documents = {
  assignmentPlanning,
  changesToAssignmentPlanning,
  financingRequest,
  financing,
  financingPropose,
  assignmentPlanningRev,
  assignmentPlanningSource,
  changeToAssignPlanRev,
  changeToAssignPlanSource,
  draftAnnualRev,
  draftAnnualSpend,
  changeDraftAnnualRev,
  changeDraftAnnualSpend,
  limitReference,
  publicBudget,
  socialPayments,
  indicatorPassport,
  indicatorFact,
  investmentProposalObjects,
  investmentProposalObjectsChanges,
  budgetRequest,
  legalObligation,
  financeObligation,
  payment,
  paymentTransfer,
  docRegister,
  financingRepairObjects,
  financingApplicationReversal,
  financingSources,
  financingCashbox,
  treasuryStatementIncome,
  treasuryStatementCosts,
  registrationForm,
  incomingMoney,
  docTransferToLogica,
  docTransferToLogicaSinging,
  draftAnnualSources,
  changeToDraftAnnualSources,
  draftAnnualCredit,
  changeDraftAnnualCredit,
  draftAnnualTransfert,
  changeDraftAnnualTransfert,
  draftAnnualLoan,
  changeDraftAnnualLoan,
  draftAnnualBR,
  changeDraftAnnualBR,
  forecastAdd1,
  forecastAdd2,
  forecastAdd3,
  forecastAdd4,
  forecastAdd5,
  forecastAdd6,
  forecastAdd7,
  forecastAdd8,
  forecastAdd12,
  forecastAdd11,
  forecastAdd10,
  forecastAdd9,
  prepareForecastAdd1,
  prepareForecastAdd2,
  prepareForecastAdd5,
  prepareForecastAdd4,
  prepareForecastAdd3,
  freeRem,
  freeRem3Add,
  freeRem4Add,
  decisionNotification,
  // BFN
  bfnInstitutionStructure,
  bfnAllocation,
  netFOP,
  netCategory,
  net,
  netChange,
  taxes,
  taxes2,
  taxes2P,
  taxes3,
  taxes3P,
  taxes4,
  taxes5,
  taxes6,
  taxes7,
  taxes8,
  taxes11,
  borderLimit,
  changeBorderLimit,
  netCategoryPlan,
  expensesOnEducation,
  treasuryStatementFinObligation,
  treasuryStatementLegalObligation,
  garanty,
  energyIndicators,
  expensesOnHealth,
  transfertPlanning,
  treasuryStatementIncomeSB,
  changeTransfertPlanning,
  balancesAccounts,
  countExpensesOnEducation,
  localDebt,
  dataEducationPayment,
  freeRem4Add2024,
  liquidityVer2,
  liquidity,
};

export const doc1c = new Proxy({}, {
  get(target, prop) {
    const doc = Object.keys(documents)
      .filter((d) => documents[d].backendName.toLowerCase() === prop.toLowerCase());
    if (doc.length === 1) return documents[doc[0]];
    throw new Error('Property {prop} not found in documents!');
  },
});

export default documents;
