const frontendURL = '/cat/executive/';
const backendURL = '/api/references/refexecutive/';
const name = 'Довідник посадових осіб';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
