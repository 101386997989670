import React from 'react';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.dp.clientBankUGB.tables.import.columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="docno">{coldef.docNo.label}</DivHeader>
      <DivHeader areaName="bankname">{coldef.bankName.label}</DivHeader>
      <DivHeader areaName="incout">{coldef.incOuntc.label}</DivHeader>
      <DivHeader areaName="sum">{coldef.sum.label}</DivHeader>
      <DivHeader areaName="createdate">{coldef.creationDate.label}</DivHeader>
      <DivHeader areaName="execdate">{coldef.executionDate.label}</DivHeader>
      <DivHeader areaName="mfo">{coldef.mfo.label}</DivHeader>
      <DivHeader areaName="account">{coldef.account.label}</DivHeader>
      <DivHeader areaName="client">{coldef.client.label}</DivHeader>
      <DivHeader areaName="edrpou">{coldef.edrpou.label}</DivHeader>
      <DivHeader areaName="purpose">{coldef.purpose.label}</DivHeader>
      <DivHeader areaName="state">{coldef.state.label}</DivHeader>
      <DivHeader areaName="doc">{coldef.doc.label}</DivHeader>
      <DivHeader areaName="lineno">{coldef.lineNo.label}</DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
