import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import DateTimeLabel from '../../../components/Controls/dates/dateTimeLabel';

const selectOptions = [
  { value: 0, label: 'Останній валідований' },
  { value: 1, label: 'Всі валідовані' },
  { value: 2, label: 'Всі' },
];

function ResultVerifyPage({
  getResult, resJson, resultVerifyList, getResultVerifyList,
}) {
  const [mode, setMode] = useState(selectOptions[0].value);
  useEffect(
    () => getResultVerifyList(mode),
    [getResultVerifyList, mode],
  );
  return (
    <>
      <DropdownButton id="dropdown-item-button" title={selectOptions[mode].label}>
        {selectOptions.map((so) => (
          <Dropdown.Item as="button" onClick={() => setMode(so.value)}>
            {selectOptions[so.value].label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {resultVerifyList && (
      <Accordion
        defaultActiveKey="0"
        onSelect={(value) => {
          if (value) {
            const line = resultVerifyList.find((i) => i[0] === value);
            getResult(line[1].receipt);
          }
        }}
      >
        {resultVerifyList && resultVerifyList.map((el) => (
          <Accordion.Item eventKey={el[0]} key={el[0]}>
            <Accordion.Header>
              <DateTimeLabel value={el[0]} displayFormat="long" />
            </Accordion.Header>
            <Accordion.Body eventKey={el[0]}>
              {resJson && (
                <>
                  {resJson.success ? (
                    <h5 className="text-success border-bottom pb-2 mb-2">
                      <FontAwesomeIcon icon={faThumbsUp} className="me-2" />
                      Верифікація пройшла успішно
                    </h5>
                  ) : (
                    <>
                      <h5 className="text-danger border-bottom pb-2 mb-2">
                        <FontAwesomeIcon icon={faThumbsDown} className="me-2" />
                        Під час верифікації виникли помилки
                      </h5>
                      <ul className="text-danger">
                        {Object.entries(resJson.messages).map(([key, message]) => (
                          <li>
                            <b className="me-2">
                              {`${key}:`}
                            </b>
                            {Array.isArray(message) ? message.join(', ') : message}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <div className="d-flex justify-content-between">
                    {resJson.validationdate && (
                    <div>
                      <span className="bg-primary rounded px-2 py-1 text-white me-2">Валідовано:</span>
                      <DateTimeLabel value={resJson.validationdate} displayFormat="long" />
                    </div>
                    )}
                    {resJson.verificationdate && (
                    <div>
                      <span className="bg-success rounded px-2 py-1 text-white me-2">Верифіковано:</span>
                      <DateTimeLabel value={resJson.verificationdate} displayFormat="long" />
                    </div>
                    )}
                  </div>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      )}
    </>
  );
}

ResultVerifyPage.propTypes = {
  getResult: PropTypes.func.isRequired,
  resJson: PropTypes.shape({
    success: PropTypes.bool,
    messages: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    validationdate: PropTypes.string,
    verificationdate: PropTypes.string,
  }),
  resultVerifyList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ])),
  getResultVerifyList: PropTypes.func.isRequired,
};

ResultVerifyPage.defaultProps = {
  resJson: null,
  resultVerifyList: null,
};

export default ResultVerifyPage;
