/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/financing';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { soSelector } from '../../../../_common/selectors';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';
import DefaultCPButton from '../../../../../../components/bootstrap_components/controls/defaultButton';

const tableMeta = md.tables.general;

function GeneralTP({ data, actions, readOnly }) {
  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);
  const Budget = data[md.columns.Budget.name];
  const date = data[md.columns.date.name] || -1;
  const Fond = data[md.columns.Fond.name];
  const FI = data[md.columns.FI.name];
  const CSUinH = data[md.columns.IncludeCSUIntoHead.name];
  const isComposeSettingsChecked = data[md.columns.ComposeSettings.name];
  const activeObjectsBuilding = data[md.columns.activeObjectsBuilding.name];
  const activeBR = data[md.columns.activeBR.name];
  const ShowAccounts = data[md.columns.ShowAccounts.name];
  const Transferting = data[md.columns.Transferting.name];
  const TransfertingZFToSF = data[md.columns.TransfertingZFToSF.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const letMinusInRow = data[md.columns.letMinusInRow.name];
  const ShowSourcesFinInDocFin = data[md.columns.ShowSourcesFinInDocFin.name];
  const Sum = data[md.columns.Sum.name];
  const FinancingReturn = data[md.columns.FinancingReturn.name];

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const tablename = 'general';

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        >
          {
            (activeBR || activeObjectsBuilding) && !ShowAccounts && (
              <>
                <DefaultCPButton
                  onClick={() => {
                    const writeOff = md.tables.general.columns.WriteOff.name;
                    const newTableData = tableData.map((el) => {
                      el[writeOff] = true;
                      return el;
                    });
                    actions.onChange({
                      [md.tables[tablename].name]: newTableData,
                    });
                    actions.onSaveWithoutExit();
                  }}
                  label="Встановити списання з рахунку для всіх рядків"
                  variant="success"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 up-6" />
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 " />
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 down-6" />
                  </span>
                </DefaultCPButton>

                <DefaultCPButton
                  onClick={() => {
                    const writeOff = md.tables.general.columns.WriteOff.name;
                    const newTableData = tableData.map((el) => {
                      el[writeOff] = false;
                      return el;
                    });
                    actions.onChange({
                      [md.tables[tablename].name]: newTableData,
                    });
                    actions.onSaveWithoutExit();
                  }}
                  variant="danger"
                  label="Відмінити списання з рахунку для всіх рядків"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 up-6" />
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 " />
                    <FontAwesomeIcon icon={faCheck} transform="shrink-6 center-8 down-6" />
                  </span>
                </DefaultCPButton>
              </>
            )
          }
        </TPCommandPanel>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          //
          isComposeSettingsChecked={isComposeSettingsChecked}
          activeObjectsBuilding={activeObjectsBuilding}
          activeBR={activeBR}
          ShowAccounts={ShowAccounts}
          CSUinH={CSUinH}
          Transferting={Transferting}
          TransfertingZFToSF={TransfertingZFToSF}
          ShowSourcesFinInDocFin={ShowSourcesFinInDocFin}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            /// /
            CSUinH={CSUinH}
            isComposeSettingsChecked={isComposeSettingsChecked}
            activeObjectsBuilding={activeObjectsBuilding}
            activeBR={activeBR}
            FI={FI}
            Fond={Fond}
            ShowAccounts={ShowAccounts}
            date={date}
            Budget={Budget}
            isFilterCsuElemKFK={isFilterCsuElemKFK}
            Transferting={Transferting}
            TransfertingZFToSF={TransfertingZFToSF}
            letMinusInRow={letMinusInRow}
            ShowSourcesFinInDocFin={ShowSourcesFinInDocFin}
            FinancingReturn={FinancingReturn}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={Sum}
          activeCol={activeCol}
          highlights={highlights}
          //
        />
      </TableFooter>
    </Card>
  );
}

GeneralTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
    onSaveWithoutExit: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

GeneralTP.defaultProps = {
  readOnly: false,
};

export default memo(GeneralTP);
