import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

function UnknowPrintForm({ url }) {
  return (
    <Alert variant="danger">
      <Alert.Heading>Невідома друкована форма</Alert.Heading>
      <p>Дана друкована форма ще не реалізована :-(</p>
      <p>{url}</p>
    </Alert>
  );
}

UnknowPrintForm.propTypes = {
  url: PropTypes.string,
};

UnknowPrintForm.defaultProps = {
  url: '',
};

export default UnknowPrintForm;
