import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions';
import meta from '../../../constants/meta';
import Fields from '../../field';
import {
  MyContainerFormWithNColumns,
  FrameDiv,
  StyledLabel,
  CommandPanelColor,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';

const md = meta.dp.netDBF;

const ExportTypes = {
  Order: { label: 'Центральний', name: 1 },
  Distrib: { label: 'Обласний', name: 2 },
};

class NetDBFEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    dispatch: () => {
    },
    headerForm: null,
  };

  constructor(props) {
    super(props);
    this.resultMountNode = ['headerForm'];
    this.state = {
      // firstStart: true,
    };
  }

  // componentWillUnmount() {
  //   const { dispatch } = this.props;
  //   dispatch(actions.processServerReq('CLOSING'));
  // }

  getDBF = () => {
    const {
      dispatch,
    } = this.props;
    const { cp1251 } = this.state;

    const pGetter = (store) => {
      // const activeItems =
      store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

      return {
        method: 'DBF',
        cp1251,
        // item: activeItems.size ? activeItems.keySeq().first() : '',
      };
    };
    dispatch(actions.getFile(pGetter));
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <div>
          <StyledLabel>{md.columns.netLvl.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('netLvl', false)}
            options={Object.values(ExportTypes)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'netLvl'], v))}
          />
        </div>
        <MyContainerFormWithNColumns columns={1} style={{ marginTop: '10px' }}>
          <div>
            <Fields.CheckboxField
              label={md.columns.in866.label}
              value={headerForm.get('in866', false)}
              onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'in866'], v))}
            />
          </div>
        </MyContainerFormWithNColumns>
        <CommandPanelColor
          style={{ marginTop: '10px' }}
          text="Натисніть щоб сформувати файл"
          label="Сформувати файл"
          onClick={this.getDBF}
        />
      </FrameDiv>
    );
  }
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // exportVDBFKiev',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(NetDBFEditor);
