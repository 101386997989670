import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const definition = {
  label: 'Інформація про залишки коштів на котлових рахунках бюджету',
  name: 'TreasureReportStatementCutKiev',
  columns,
  frontend: 'rep/TreasureReportStatementCutKiev',
  backendName: 'КазнВыпискаСрезНовыйКиев',
  resultColumns: baseResultColumns,
};

export default definition;
