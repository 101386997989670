import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' }, // >Отбор.Владелец = FO
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  fundsType: { label: 'Вид коштів', name: 'ВидыСредствКЕКВ' },
  incDB: { label: 'в т.ч. БР', name: 'вТЧБР' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  showColonets: {
    label: 'Показати колонтітули',
    name: 'ПоказыватьКолонтитулы',
  },
  byMonths: { label: 'Помісячно', name: 'ПоМесяцам' },
  byMonthsAccum: { label: 'Накопичувально', name: 'ПоМесяцамНакопительно' },
  symbol0: {
    label: 'Пусті числові значення показувати як',
    name: 'СимволПустогоЧисла',
  },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  showClassifiersName: {
    label: 'Відображати найменування класифікаторів',
    name: 'флБезНаимКлассификаторов',
  },
  extract: { label: 'Витяг', name: 'флВитяг' },
  printDate: { label: 'Друк дати', name: 'флКолонтитулДаты' },
  printPageNo: { label: 'Друк ном.сторінки', name: 'флКолонтитулНомСтр' },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },
  printReportParams: {
    label: 'Друкувати параметри звіту',
    name: 'флПечатьСодержанияФильтра',
  },
  printApproved: {
    label: 'Друкувати "Затверджено"',
    name: 'флПечатьУтверждаю',
  },
  planOfUse: {
    label: 'За планом використання',
    name: 'флПланИспользования',
  },
  bySubLevelCSU: {
    label: 'За данними підлеглих розпорядників',
    name: 'флПоДаннымНижнихРасп',
  },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },
  byReducedKEKV: { label: 'За скороченими КЕКВ', name: 'флПоСокрКЕКВ' },
  consolidated: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  // флСтандартнаяОбработка
  includeAllChldren: { label: 'З урахуванням розпорядників нижчого рівня', name: 'флВключаяВсехПодчиненных' },
  byPCM: { label: 'За ПЦМ', name: 'ПоПЦМ' },
  separateColumn: {
    label: 'Код та назва в окремих колонках',
    name: 'флКодОтдельнаяКолонка',
  },
  bySublevel: {
    label: 'Тільки за нижчими рівнем',
    name: 'флТолькоПоНижнимУровням',
  },
  docs: {
    label: 'Документи',
    name: 'масДок',
  },
};

const mainGroupFields = [
  'КВК',
  'Распорядитель',
  'Фонд',
  'ЭлементФонд',
  'КБП',
  'КБП7',
  'ЭлементКФК',
  'КЕКВ',
  'ЭлементКЕКВ',
  'КДБ',
  'КФБ',
  'КФК',
  'ВидРемонта',
  'ОбъектСтроительства',
  'Регистратор',
  'Бюджет',
];

const definition = {
  label: 'Розпис асигнувань',
  name: 'AssigmentPlaning',
  columns,
  frontend: 'rep/AssigmentPlaning',
  backendName: 'НовПланАссигнованийГруппы',
  resultColumns: baseResultColumns,
  mainGroupFields,
};

export default definition;
