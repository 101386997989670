import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { tarif as md } from '../../../../constants/meta/catalogs/categoriesFOP';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function CategoriesFOP({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.fullName.label}
            value={data[md.columns.fullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fullName.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.categ.label}
            value={data[md.columns.categ.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.categ.name]: value,
            })}
            maxLength={25}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.categ3.label}
            value={data[md.columns.categ3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.categ3.name]: value,
            })}
            maxLength={10}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.units.label}
            value={data[md.columns.units.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.units.name]: value,
            })}
            maxLength={25}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.KPKV.label}
            value={data[md.columns.KPKV.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KPKV.name]: value,
            })}
            maxLength={4}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.form_start.label}
            value={data[md.columns.form_start.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.form_start.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.form_finish.label}
            value={data[md.columns.form_finish.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.form_finish.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.form_start_middle.label}
            value={data[md.columns.form_start_middle.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.form_start_middle.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.form_finish_middle.label}
            value={data[md.columns.form_finish_middle.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.form_finish_middle.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.countRow.label}
            value={data[md.columns.countRow.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.countRow.name]: value,
            })}
            maxLength={10}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId="isBanFactS"
            label={md.columns.isBanFactS.label}
            value={data[md.columns.isBanFactS.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isBanFactS.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId="isBanCountS"
            label={md.columns.isBanCountS.label}
            value={data[md.columns.isBanCountS.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isBanCountS.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId="isBanFactE"
            label={md.columns.isBanFactE.label}
            value={data[md.columns.isBanFactE.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isBanFactE.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId="isBanCountE"
            label={md.columns.isBanCountE.label}
            value={data[md.columns.isBanCountE.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isBanCountE.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>

    </Container>
  );
}

CategoriesFOP.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
    [md.columns.fullName.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CategoriesFOP;
