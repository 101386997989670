import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  planKind: { label: 'Вид плану', name: 'ВидПлана' },
  reportsKind: { label: 'Варіант звіту', name: 'ВариантОтчета' },
  budgetVar: { label: 'Варіант проекту до рішення сесії', name: 'ВариантПроекта' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'Подписть' },
  fond: { label: 'Фонд', name: 'Фонд' },
  yearPlan: { label: 'Рік', name: 'тГод' },
  startDate: { label: 'Враховувати дані з', name: 'НачДата' },
  finDate: { label: 'по', name: 'КонДата' },
  sessiondesigion: { label: 'Рішення сесії для відправки в ЛОГІКА', name: 'РешениеСессии' },
  in1000: { label: 'В тыс грн', name: 'ВТысГрн' },
  KFB: { label: 'КФБ', name: 'КФБ' },
  elKDB: { label: 'Складова КДБ', name: 'ЭлементКДБ' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  elFond: { label: 'ЭлементФонд', name: 'ЭлементФонд' },
  det: { label: 'Деталізація', name: 'флДетализация' },
  inBR: { label: 'В т.ч. бюджет розвитку', name: 'флБР' },
  increasing: { label: 'Наростаючим підсумком', name: 'флНарастающийИтог' },
  platAproved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  consolid: { label: 'Зведена форма', name: 'фСводний' },
  copecks: { label: 'Показувати копійки', name: 'Копейки' },
  showDocs: { label: 'Показати документи', name: 'флДоДокумента' },
  flPlaN: { label: 'За документами "Розпис/зміни до розпису джерел фінансування"', name: 'флЗаПланомИзмИсточники' },
};

const tables = {
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Відбирати', name: 'Пометка' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
    },
  },
  tpFO: {
    name: 'ФинансовыеОрганы',
    label: 'ФинансовыеОрганы',
    columns: {
      FO: { label: 'ФинансовыйОрган', name: 'ФинансовыйОрган' },
    },
  },
  tpJSON: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      Col1: { label: 'Кол1', name: 'Кол1' },
      Col2: { label: 'Кол2', name: 'Кол2' },
      Col3: { label: 'Кол3', name: 'Кол3' },
      Col4: { label: 'Кол4', name: 'Кол4' },
      Col5: { label: 'Кол5', name: 'Кол5' },
      Col6: { label: 'Кол6', name: 'Кол6' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 2 (Джерела фінансування)',
  name: 'SessionAdd2',
  columns,
  tables,
  frontend: 'rep/SessionAdd2',
  backendName: 'РешениеСессии02',
  resultColumns: baseResultColumns,
};

export default definition;
