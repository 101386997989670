import React, { memo } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import GridTemplate from './GridTemplate';
import { DivFooter } from '../../../../../components/styledTable/styledTable';

function FooterComponent({ rows }) {
  const totals = rows.reduce((v, row) => v + (row.get('Check', false) ? row.get('Sum', 0) : 0), 0);
  return (
    <GridTemplate isHeader>
      <DivFooter areaName="auto/check/auto/sum">Разом</DivFooter>
      <DivFooter areaName="auto/sum">{totals.toFixed(2)}</DivFooter>
      <DivFooter areaName="auto/author" />
    </GridTemplate>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,
};

export default memo(FooterComponent);
