import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/changeDraftAnnualSpend';
import {
  CommandPanelColor,
} from '../../../../components/Form/styledForm';
import { soSelector } from '../../_common/selectors';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function ChangeDraftAnnualSpend({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const FI = data[md.columns.FI.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.variantBudgetDraft.label}
            value={data[md.columns.variantBudgetDraft.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.variantBudgetDraft.name]: value,
              });
            }}
            modelType="cat"
            modelName="variantBudgetDraft"
            filter={FiOwnerFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label="Від: "
            value={data[md.columns.date.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.date.name]: value,
                [md.columns.year.name]: new Date(value).getFullYear(),
              });
              actions.onSR('CHANGE_YEAR');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.year.name]: value,
              });
              actions.onSR('CHANGE_YEAR');
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CSUOnHead-${data.id}`}
            label={md.columns.CSUOnHead.label}
            value={data[md.columns.CSUOnHead.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.CSUOnHead.name]: value,
              });
              actions.onSR('CHANGE_CSU');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`splitTragetProg-${data.id}`}
            label={md.columns.splitTragetProg.label}
            value={data[md.columns.splitTragetProg.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.splitTragetProg.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        {data[md.columns.CSUOnHead.name] && (
          <Col>
            <EditorControls.ItemPicker
              label={md.columns.CSU.label}
              value={data[md.columns.CSU.name]}
              onChange={async (e, value) => {
                await actions.onChange({
                  [md.columns.CSU.name]: value,
                });
                actions.onSR('CHANGE_CSU');
              }}
              modelType="cat"
              modelName="csu"
              noHierarchy
              readOnly={readOnly}
            />
          </Col>
        )}
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.sessionDecision.label}
            value={data[md.columns.sessionDecision.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.sessionDecision.name]: value,
            })}
            modelType="cat"
            modelName="templateBaseDoc"
            noHierarchy
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.reason.label}
            value={data[md.columns.reason.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.reason.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateStart.label}
            value={data[md.columns.DateStart.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateStart.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateFin.label}
            value={data[md.columns.DateFin.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateFin.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <div style={{ marginBottom: '1rem' }}>
          <CommandPanelColor
            style={{ width: 'fit-content', alignSelf: 'end' }}
            label="Отримати документи для заповнення"
            onClick={() => actions.onSR('SELECT_FROM_ASSIGMENT_PLANNING_REV')}
          />
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <CommandPanelColor
            style={{ width: 'fit-content', alignSelf: 'end' }}
            label="Заповнити з обраних документів"
            onClick={() => actions.onSR('LOAD_FROM_ASSIGMENT_PLANNING_REV')}
          />
        </div>
      </Row>

    </EditorCollapse>
  );
}

ChangeDraftAnnualSpend.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChangeDraftAnnualSpend;
