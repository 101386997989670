import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/forecastAdd11';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'incomes';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.KDB.name]: v });
      await onSR('CHANGE_KDB_GENERAL_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onBudgetChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.budget.name]: v });
      await onSR('CHANGE_BUDGET_GENERAL_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="KDB" highlighted={highlights.includes('KDB')}>
              <ItemPicker
                value={row[tableMD.columns.KDB.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={onKDBChange}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
            <TableRowCell column="KDBName" highlighted={highlights.includes('KDBName')}>
              <TextInput
                value={row[tableMD.columns.KDBName.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KDBName.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
            <TableRowCell column="budget" highlighted={highlights.includes('budget')}>
              <ItemPicker
                value={row[tableMD.columns.budget.name]}
                modelType="cat"
                modelName="budgetTransfer"
                onChange={onBudgetChange}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Report" highlighted={highlights.includes('incomesGF_Report')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Report.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Report.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Report" highlighted={highlights.includes('incomesSF_Report')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Report.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Report.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Approved" highlighted={highlights.includes('incomesGF_Approved')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Approved.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Approved.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Approved" highlighted={highlights.includes('incomesSF_Approved')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Approved.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Approved.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan" highlighted={highlights.includes('incomesGF_Plan')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Plan" highlighted={highlights.includes('incomesSF_Plan')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan1" highlighted={highlights.includes('incomesGF_Plan1')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Plan1" highlighted={highlights.includes('incomesSF_Plan1')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="incomesGF_Plan2" highlighted={highlights.includes('incomesGF_Plan2')}>
              <NumberInput
                value={row[tableMD.columns.incomesGF_Plan2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesGF_Plan2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="incomesSF_Plan2" highlighted={highlights.includes('incomesSF_Plan2')}>
              <NumberInput
                value={row[tableMD.columns.incomesSF_Plan2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.incomesSF_Plan2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.incomesGF_Report.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Report.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Approved.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Approved.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan1.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan1.name]: PropTypes.number,
    [tableMD.columns.incomesGF_Plan2.name]: PropTypes.number,
    [tableMD.columns.incomesSF_Plan2.name]: PropTypes.number,
    [tableMD.columns.KDB.name]: referencePropType,
    [tableMD.columns.budget.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
