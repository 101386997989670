import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        lg={6}
      >
        &nbsp;
      </Col>
      <Col lg={6} className="px-1">
        <Row noGutters>
          <Col lg={4} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'credit_amount'}
              highlighted={highlights.includes('credit_amount')}
            >
              {totals.credit_amount.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col lg={4} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'credit_involvement_amount'}
              highlighted={highlights.includes('credit_involvement_amount')}
            >
              {totals.credit_involvement_amount.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
          <Col lg={4} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'plan_year'}
              highlighted={highlights.includes('plan_year')}
            >
              {totals.plan_year.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    credit_amount: PropTypes.number,
    credit_involvement_amount: PropTypes.number,
    plan_year: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
