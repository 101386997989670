import React from 'react';
import PropTypes from 'prop-types';
import { CPButton } from '../../../../../../components/bootStrap/buttons';

function FillButton({ onClick }) {
  return (
    <CPButton
      content="Заповнити"
      onClick={onClick}
      variant="falcon-success"
      size="sm"
    >
      Заповнити
    </CPButton>
  );
}
FillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FillButton;
