import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const DEFAULT_PREFIX = 'default_';

const useEditorParams = () => {
  const location = useLocation();
  return useMemo(
    () => {
      const up = new URLSearchParams(location.search);
      const defaults = [...up.entries()].reduce((R, [k, v]) => {
        if (k.startsWith(DEFAULT_PREFIX)) return { ...R, [k.substring(DEFAULT_PREFIX.length)]: v };
        return R;
      }, {});
      return {
        isGroup: !!up.get('group'),
        copyFrom: up.get('copyFrom'),
        reason: up.get('reason'),
        defaults,
      };
    },
    [location.search],
  );
};

export default useEditorParams;
