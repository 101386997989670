import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  inKEKV: { label: 'Розписати по КЕКВ (п 6.1)', name: 'РасписатьПоКЕКВ' },
  lev: { label: 'Рівень виконавця', name: 'УрИ' },
  signMFO: { label: 'Предупреждение', name: 'Предупреждение' },
  R01G3: { label: 'R01G2', name: 'R01G3' },
  R01G4: { label: 'R01G3', name: 'R01G4' },
  R01G5: { label: 'R01G4', name: 'R01G5' },
  R01G6: { label: 'R01G3', name: 'R01G6' },
  R01G7: { label: 'R01G4', name: 'R01G7' },
  R02G3: { label: 'R01G2', name: 'R02G3' },
  R02G4: { label: 'R01G3', name: 'R02G4' },
  R02G5: { label: 'R01G4', name: 'R02G5' },
  R02G6: { label: 'R01G3', name: 'R02G6' },
  R02G7: { label: 'R01G4', name: 'R02G7' },
  R03G3: { label: 'R01G2', name: 'R03G3' },
  R03G4: { label: 'R01G3', name: 'R03G4' },
  R03G5: { label: 'R01G4', name: 'R03G5' },
  R03G6: { label: 'R01G3', name: 'R03G6' },
  R03G7: { label: 'R01G4', name: 'R03G7' },
  R04G3: { label: 'R01G2', name: 'R04G3' },
  R04G4: { label: 'R01G3', name: 'R04G4' },
  R04G5: { label: 'R01G4', name: 'R04G5' },
  R04G6: { label: 'R01G3', name: 'R04G6' },
  R04G7: { label: 'R01G4', name: 'R04G7' },
  R05G3: { label: 'R01G2', name: 'R05G3' },
  R05G4: { label: 'R01G3', name: 'R05G4' },
  R05G5: { label: 'R01G4', name: 'R05G5' },
  R05G6: { label: 'R01G3', name: 'R05G6' },
  R05G7: { label: 'R01G4', name: 'R05G7' },
  R06G3: { label: 'R01G2', name: 'R06G3' },
  R06G4: { label: 'R01G3', name: 'R06G4' },
  R06G5: { label: 'R01G4', name: 'R06G5' },
  R06G6: { label: 'R01G3', name: 'R06G6' },
  R06G7: { label: 'R01G4', name: 'R06G7' },
  R51G3: { label: 'R01G2', name: 'R51G3' },
  R51G4: { label: 'R01G3', name: 'R51G4' },
  R51G5: { label: 'R01G4', name: 'R51G5' },
  R51G6: { label: 'R01G3', name: 'R51G6' },
  R51G7: { label: 'R01G4', name: 'R51G7' },
  R52G3: { label: 'R01G2', name: 'R52G3' },
  R52G4: { label: 'R01G3', name: 'R52G4' },
  R52G5: { label: 'R01G4', name: 'R52G5' },
  R52G6: { label: 'R01G3', name: 'R52G6' },
  R52G7: { label: 'R01G4', name: 'R52G7' },
  R53G3: { label: 'R01G2', name: 'R53G3' },
  R53G4: { label: 'R01G3', name: 'R53G4' },
  R53G5: { label: 'R01G4', name: 'R53G5' },
  R53G6: { label: 'R01G3', name: 'R53G6' },
  R53G7: { label: 'R01G4', name: 'R53G7' },
  R54G3: { label: 'R01G2', name: 'R54G3' },
  R54G4: { label: 'R01G3', name: 'R54G4' },
  R54G5: { label: 'R01G4', name: 'R54G5' },
  R54G6: { label: 'R01G3', name: 'R54G6' },
  R54G7: { label: 'R01G4', name: 'R54G7' },
  R55G3: { label: 'R01G2', name: 'R55G3' },
  R55G4: { label: 'R01G3', name: 'R55G4' },
  R55G5: { label: 'R01G4', name: 'R55G5' },
  R55G6: { label: 'R01G3', name: 'R55G6' },
  R55G7: { label: 'R01G4', name: 'R55G7' },
  R56G3: { label: 'R01G2', name: 'R56G3' },
  R56G4: { label: 'R01G3', name: 'R56G4' },
  R56G5: { label: 'R01G4', name: 'R56G5' },
  R56G6: { label: 'R01G3', name: 'R56G6' },
  R56G7: { label: 'R01G4', name: 'R56G7' },
  R57G3: { label: 'R01G2', name: 'R57G3' },
  R57G4: { label: 'R01G3', name: 'R57G4' },
  R57G5: { label: 'R01G4', name: 'R57G5' },
  R57G6: { label: 'R01G3', name: 'R57G6' },
  R57G7: { label: 'R01G4', name: 'R57G7' },
  R58G3: { label: 'R01G2', name: 'R58G3' },
  R58G4: { label: 'R01G3', name: 'R58G4' },
  R58G5: { label: 'R01G4', name: 'R58G5' },
  R58G6: { label: 'R01G3', name: 'R58G6' },
  R58G7: { label: 'R01G4', name: 'R58G7' },
  R59G3: { label: 'R01G2', name: 'R59G3' },
  R59G4: { label: 'R01G3', name: 'R59G4' },
  R59G5: { label: 'R01G4', name: 'R59G5' },
  R59G6: { label: 'R01G3', name: 'R59G6' },
  R59G7: { label: 'R01G4', name: 'R59G7' },
  R08G3: { label: 'R01G2', name: 'R08G3' },
  R08G4: { label: 'R01G3', name: 'R08G4' },
  R08G5: { label: 'R01G4', name: 'R08G5' },
  R08G6: { label: 'R01G3', name: 'R08G6' },
  R08G7: { label: 'R01G4', name: 'R08G7' },
  R09G3: { label: 'R01G2', name: 'R09G3' },
  R09G4: { label: 'R01G3', name: 'R09G4' },
  R09G5: { label: 'R01G4', name: 'R09G5' },
  R09G6: { label: 'R01G3', name: 'R09G6' },
  R09G7: { label: 'R01G4', name: 'R09G7' },
  R10G3: { label: 'R01G2', name: 'R10G3' },
  R10G4: { label: 'R01G3', name: 'R10G4' },
  R10G5: { label: 'R01G4', name: 'R10G5' },
  R10G6: { label: 'R01G3', name: 'R10G6' },
  R10G7: { label: 'R01G4', name: 'R10G7' },
  R11G3: { label: 'R01G2', name: 'R11G3' },
  R11G4: { label: 'R01G3', name: 'R11G4' },
  R11G5: { label: 'R01G4', name: 'R11G5' },
  R11G6: { label: 'R01G3', name: 'R11G6' },
  R11G7: { label: 'R01G4', name: 'R11G7' },
  R12G3: { label: 'R01G2', name: 'R12G3' },
  R12G4: { label: 'R01G3', name: 'R12G4' },
  R12G5: { label: 'R01G4', name: 'R12G5' },
  R12G6: { label: 'R01G3', name: 'R12G6' },
  R12G7: { label: 'R01G4', name: 'R12G7' },
  R60G3: { label: 'R01G2', name: 'R60G3' },
  R60G4: { label: 'R01G3', name: 'R60G4' },
  R60G5: { label: 'R01G4', name: 'R60G5' },
  R60G6: { label: 'R01G3', name: 'R60G6' },
  R60G7: { label: 'R01G4', name: 'R60G7' },
  R61G3: { label: 'R01G2', name: 'R61G3' },
  R61G4: { label: 'R01G3', name: 'R61G4' },
  R61G5: { label: 'R01G4', name: 'R61G5' },
  R61G6: { label: 'R01G3', name: 'R61G6' },
  R61G7: { label: 'R01G4', name: 'R61G7' },
  R15G3: { label: 'R01G2', name: 'R15G3' },
  R15G4: { label: 'R01G3', name: 'R15G4' },
  R15G5: { label: 'R01G4', name: 'R15G5' },
  R15G6: { label: 'R01G3', name: 'R15G6' },
  R15G7: { label: 'R01G4', name: 'R15G7' },
  R16G3: { label: 'R01G2', name: 'R16G3' },
  R16G4: { label: 'R01G3', name: 'R16G4' },
  R16G5: { label: 'R01G4', name: 'R16G5' },
  R16G6: { label: 'R01G3', name: 'R16G6' },
  R16G7: { label: 'R01G4', name: 'R16G7' },
  R17G3: { label: 'R01G2', name: 'R17G3' },
  R17G4: { label: 'R01G3', name: 'R17G4' },
  R17G5: { label: 'R01G4', name: 'R17G5' },
  R17G6: { label: 'R01G3', name: 'R17G6' },
  R17G7: { label: 'R01G4', name: 'R17G7' },
  R18G3: { label: 'R01G2', name: 'R18G3' },
  R18G4: { label: 'R01G3', name: 'R18G4' },
  R18G5: { label: 'R01G4', name: 'R18G5' },
  R18G6: { label: 'R01G3', name: 'R18G6' },
  R18G7: { label: 'R01G4', name: 'R18G7' },
  R27G3: { label: 'R01G2', name: 'R27G3' },
  R27G4: { label: 'R01G3', name: 'R27G4' },
  R27G5: { label: 'R01G4', name: 'R27G5' },
  R27G6: { label: 'R01G3', name: 'R27G6' },
  R27G7: { label: 'R01G4', name: 'R27G7' },
  R28G3: { label: 'R01G2', name: 'R28G3' },
  R28G4: { label: 'R01G3', name: 'R28G4' },
  R28G5: { label: 'R01G4', name: 'R28G5' },
  R28G6: { label: 'R01G3', name: 'R28G6' },
  R28G7: { label: 'R01G4', name: 'R28G7' },
  R29G3: { label: 'R01G2', name: 'R29G3' },
  R29G4: { label: 'R01G3', name: 'R29G4' },
  R29G5: { label: 'R01G4', name: 'R29G5' },
  R29G6: { label: 'R01G3', name: 'R29G6' },
  R29G7: { label: 'R01G4', name: 'R29G7' },
  R30G3: { label: 'R01G2', name: 'R30G3' },
  R30G4: { label: 'R01G3', name: 'R30G4' },
  R30G5: { label: 'R01G4', name: 'R30G5' },
  R30G6: { label: 'R01G3', name: 'R30G6' },
  R30G7: { label: 'R01G4', name: 'R30G7' },
  R31G3: { label: 'R01G2', name: 'R31G3' },
  R31G4: { label: 'R01G3', name: 'R31G4' },
  R31G5: { label: 'R01G4', name: 'R31G5' },
  R31G6: { label: 'R01G3', name: 'R31G6' },
  R31G7: { label: 'R01G4', name: 'R31G7' },
  R32G3: { label: 'R01G2', name: 'R32G3' },
  R32G4: { label: 'R01G3', name: 'R32G4' },
  R32G5: { label: 'R01G4', name: 'R32G5' },
  R32G6: { label: 'R01G3', name: 'R32G6' },
  R32G7: { label: 'R01G4', name: 'R32G7' },
};

const tables = {
  data: {
    name: 'ДляJSON',
    label: 'ДляJSON',
    columns: {
      col1: { label: 'Кол1', name: 'Кол1' },
      col2: { label: 'Кол1', name: 'Кол2' },
      col3: { label: 'Кол1', name: 'Кол3' },
      col4: { label: 'Кол1', name: 'Кол4' },
      col5: { label: 'Кол1', name: 'Кол5' },
      col6: { label: 'Кол1', name: 'Кол6' },
      col7: { label: 'Кол1', name: 'Кол7' },
      col8: { label: 'Кол1', name: 'Кол8' },
      col9: { label: 'Кол1', name: 'Кол9' },
      col10: { label: 'Кол1', name: 'Кол10' },
      col11: { label: 'Кол1', name: 'Кол11' },
      col12: { label: 'Кол1', name: 'Кол12' },
      col13: { label: 'Кол1', name: 'Кол13' },
      col14: { label: 'Кол1', name: 'Кол14' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'prepareForecastAdd3',
  backendName: 'ПечатьПрогнозДодП3',
  label: 'Пропозиція до прогнозу місцевого бюджету індивідуальна (Форма ПП-2) (Додаток 3)',
  frontend: 'dp/prepareForecastAdd3',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
