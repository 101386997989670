import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField } from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';
import storePathParam from '../../../../common/storePathParam';

const TABLENAME = 'tpDoc';

const coldef = md.dp.indicatorsFactPrint.tables.tpDoc.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "strNumber KPKVK doc";
  grid-template-columns: 10% 20% 70%;
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="strNumber">{coldef.strNumber.label}</DivHeader>
      <DivHeader areaName="KPKVK">{coldef.KPKVK.label}</DivHeader>
      <DivHeader areaName="doc">{coldef.doc.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="strNumber">
          <InputCodeStyled
            value={row.get('strNumber', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="KPKVK">
          <InputCodeStyled
            value={row.get('KPKVK', '')}
            readOnly
          />
        </DivCell>
        <DivCell areaName="doc">
          <Fields.SelectorField
            noBorder
            noBackground
            modelName="indicatorFact"
            modelType="doc"
            value={row.get('doc', new Map())}
            onChange={(e, v) => {
              dispatch(changeField([`tables/${TABLENAME}`, rowId, 'doc'], v));
            }}
            noHierarchy
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TableData = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TableData);
