import React, {
  Suspense, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel,
  FramePrintModal,
  ContainerFourColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import { emptyUid } from '../../../constants/meta/common';

const MainContainer = styled.div`
  position: relative;
`;

const StyledResponse = styled.h2`
 color: red;
 `;

const md = meta.dp.liquidityPrint;

function LiquidityPrintEditor({ onClose }) {
  const dispatch = useDispatch();
  const { headerForm, isProcessing } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  return (
    <MainContainer>
      {!headerForm.getIn(['SignVariant', 'repr'], '')
        && <StyledResponse color="red">Необхiдно заповнити поле &#39;Варіант підпису&#39;</StyledResponse> }
      <div>
        <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
        <Fields.SelectorField
          value={headerForm.get('SignVariant', new Map())}
          modelType="cat"
          modelName="signatureVariants"
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
        />
      </div>
      <ContainerButtonTop style={{ marginTop: '10px' }}>
        <CommandPanelButton
          text="Сформувати"
          onClick={() => {
            setSettingsOpened(false);
            dispatch(actions.processServerReq('PRINT'));
          }}
        >
          {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
        </CommandPanelButton>
        <CommandPanelButton
          // label={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          text={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
          onClick={() => setSettingsOpened(!settingsOpened)}
        >
          <SettingsIcon />
        </CommandPanelButton>
        <CommandPanelButton
          label="Передати в Logica"
          onClick={() => {
            dispatch(actions.processServerReq('CreateDispatchDoc'));
          }}
          disabled={!headerForm.getIn(['SignVariant', 'repr'], '')}
        />
      </ContainerButtonTop>
      {settingsOpened ? (
        <FramePrintModal
          styled={settingsOpened ? { transition: '3s' } : { transition: '3s' }}
          visible={settingsOpened}
          animation="overlay"
          width="very wide"
          direction="top"
          tertiary
          color="blue"
          basic
        >
          <div>
            <ContainerFourColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  value={headerForm.get('shKEKV', false)}
                  label={md.columns.shKEKV.label}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'shKEKV'], v))}
                />
              </div>
            </ContainerFourColumn>
          </div>
        </FramePrintModal>
      )
        : (
          <Suspense fallback={<div>Завантаження...</div>}>
            <TableEditor mountNode={resultMountNode} />
          </Suspense>
        )}
    </MainContainer>
  );
}

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null, // or yourHOC(BasicMenu)
    // tables, // Табличные части
  },
);

LiquidityPrintEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LiquidityPrintEditor.defaultProps = {

};

export default enchance(LiquidityPrintEditor);
