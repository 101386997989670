import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  csu: { label: 'Головний роспорядник', name: 'Распорядители', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  reportMonth: { label: 'Місяць звітності', name: 'МесяцОтчетности', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  isApproved: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },

  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  currentLevel: { label: 'Завантажувати з планів поточного рівня', name: 'ЗагружатьИзПлановТекущегоУровня', visible: false },
  currentFO: { label: 'Завантажувати за установами', name: 'ЗагружатьЗаОрганизациями', visible: false },
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'wKzEl2MwuoM',
  },
  expensesOnHealthKyiv: { label: 'ИнформацияОРасходахНаЗдоровъяКиев', name: 'ИнформацияОРасходахНаЗдоровъяКиев', visible: false },
  docLogica: { label: 'Документ відправки в Логіка', name: 'ДокОтправкиВЛогика', visible: false },
  isSentLogica: {
    label: 'Підготовлено відправку в Логіку', name: 'ОтправленВЛогику', visible: true, type: 'bool',
  },
  statusLogica: { label: 'СтатусЛогика', name: 'СтатусЛогика', visible: false },
  signLogica: { label: 'ПодписиЛогика', name: 'ПодписиЛогика', visible: false },
};

/**
 * @const
 */
const tables = {
  TP2282: {
    name: 'ТЧ2282',
    label: 'КЕКВ 2282',
    columns: {
      lineno: { label: '№', name: 'НомерСтроки' },
      T1RXXXXG1S: { label: 'Назва закладу охорони здоров\'я', name: 'T1RXXXXG1S' },
      T1RXXXXG2S: { label: 'ЄДРПОУ закладу охорони здоров\'я', name: 'T1RXXXXG2S' },
      T1RXXXXG3: { label: 'Зведений план викор. коштів на поточний рік. Всього', name: 'T1RXXXXG3' },
      T1RXXXXG4: { label: 'Зведений план викор. коштів на поточний рік. Оплата праці', name: 'T1RXXXXG4' },
      T1RXXXXG5: { label: 'Зведений план викор. коштів на поточний рік. Нарахування на оплату праці', name: 'T1RXXXXG5' },
      T1RXXXXG6: { label: 'Зведений план викор. коштів на поточний рік. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG6' },
      T1RXXXXG7: { label: 'Зведений план викор. коштів на поточний рік. Продукти харчування', name: 'T1RXXXXG7' },
      T1RXXXXG8: { label: 'Зведений план викор. коштів на поточний рік. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG8' },
      T1RXXXXG9: { label: 'Зведений план викор. коштів на поточний рік. Соціальне забезпечення', name: 'T1RXXXXG9' },
      T1RXXXXG10: { label: 'Зведений план викор. коштів на поточний рік. Інші видатки', name: 'T1RXXXXG10' },
      T1RXXXXG11: { label: 'План викор. коштів на звітний місяць. Всього', name: 'T1RXXXXG11' },
      T1RXXXXG12: { label: 'План викор. коштів на звітний місяць. Оплата праці', name: 'T1RXXXXG12' },
      T1RXXXXG13: { label: 'План викор. коштів на звітний місяць. Нарахування на оплату праці', name: 'T1RXXXXG13' },
      T1RXXXXG14: { label: 'План викор. коштів на звітний місяць. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG14' },
      T1RXXXXG15: { label: 'План викор. коштів на звітний місяць. Продукти харчування', name: 'T1RXXXXG15' },
      T1RXXXXG16: { label: 'План викор. коштів на звітний місяць. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG16' },
      T1RXXXXG17: { label: 'План викор. коштів на звітний місяць. Соціальне забезпечення', name: 'T1RXXXXG17' },
      T1RXXXXG18: { label: 'План викор. коштів на звітний місяць. Інші видатки', name: 'T1RXXXXG18' },
      T1RXXXXG19: { label: 'Касові видатки на кінець звітного місяця. Всього', name: 'T1RXXXXG19' },
      T1RXXXXG20: { label: 'Касові видатки на кінець звітного місяця. Оплата праці', name: 'T1RXXXXG20' },
      T1RXXXXG21: { label: 'Касові видатки на кінець звітного місяця. Нарахування на оплату праці', name: 'T1RXXXXG21' },
      T1RXXXXG22: { label: 'Касові видатки на кінець звітного місяця. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG22' },
      T1RXXXXG23: { label: 'Касові видатки на кінець звітного місяця. Продукти харчування', name: 'T1RXXXXG23' },
      T1RXXXXG24: { label: 'Касові видатки на кінець звітного місяця. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG24' },
      T1RXXXXG25: { label: 'Касові видатки на кінець звітного місяця. Соціальне забезпечення', name: 'T1RXXXXG25' },
      T1RXXXXG26: { label: 'Касові видатки на кінець звітного місяця. Інші видатки', name: 'T1RXXXXG26' },
      T1RXXXXG27: { label: 'Зведений план викор. коштів на поточний рік. Всього', name: 'T1RXXXXG27' },
      T1RXXXXG28: { label: 'Зведений план викор. коштів на поточний рік. Оплата праці', name: 'T1RXXXXG28' },
      T1RXXXXG29: { label: 'Зведений план викор. коштів на поточний рік. Нарахування на оплату праці', name: 'T1RXXXXG29' },
      T1RXXXXG30: { label: 'Зведений план викор. коштів на поточний рік. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG30' },
      T1RXXXXG31: { label: 'Зведений план викор. коштів на поточний рік. Продукти харчування', name: 'T1RXXXXG31' },
      T1RXXXXG32: { label: 'Зведений план викор. коштів на поточний рік. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG32' },
      T1RXXXXG33: { label: 'Зведений план викор. коштів на поточний рік. Соціальне забезпечення', name: 'T1RXXXXG33' },
      T1RXXXXG34: { label: 'Зведений план викор. коштів на поточний рік. Інші видатки', name: 'T1RXXXXG34' },
      T1RXXXXG35: { label: 'План викор. коштів на звітний місяць. Всього', name: 'T1RXXXXG35' },
      T1RXXXXG36: { label: 'План викор. коштів на звітний місяць. Оплата праці', name: 'T1RXXXXG36' },
      T1RXXXXG37: { label: 'План викор. коштів на звітний місяць. Нарахування на оплату праці', name: 'T1RXXXXG37' },
      T1RXXXXG38: { label: 'План викор. коштів на звітний місяць. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG38' },
      T1RXXXXG39: { label: 'План викор. коштів на звітний місяць. Продукти харчування', name: 'T1RXXXXG39' },
      T1RXXXXG40: { label: 'План викор. коштів на звітний місяць. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG40' },
      T1RXXXXG41: { label: 'План викор. коштів на звітний місяць. Соціальне забезпечення', name: 'T1RXXXXG41' },
      T1RXXXXG42: { label: 'План викор. коштів на звітний місяць. Інші видатки', name: 'T1RXXXXG42' },
      T1RXXXXG43: { label: 'Касові видатки на кінець звітного місяця. Всього', name: 'T1RXXXXG43' },
      T1RXXXXG44: { label: 'Касові видатки на кінець звітного місяця. Оплата праці', name: 'T1RXXXXG44' },
      T1RXXXXG45: { label: 'Касові видатки на кінець звітного місяця. Нарахування на оплату праці', name: 'T1RXXXXG45' },
      T1RXXXXG46: { label: 'Касові видатки на кінець звітного місяця. Медикаменти та перев\'язувальні матеріали', name: 'T1RXXXXG46' },
      T1RXXXXG47: { label: 'Касові видатки на кінець звітного місяця. Продукти харчування', name: 'T1RXXXXG47' },
      T1RXXXXG48: { label: 'Касові видатки на кінець звітного місяця. Оплата комунальних послуг та енергоносіїв', name: 'T1RXXXXG48' },
      T1RXXXXG49: { label: 'Касові видатки на кінець звітного місяця. Соціальне забезпечення', name: 'T1RXXXXG49' },
      T1RXXXXG50: { label: 'Касові видатки на кінець звітного місяця. Інші видатки', name: 'T1RXXXXG50' },
      T1RXXXXG51S: { label: 'Код Типової програмної класифікації видатків та кредитування місцевого бюджету', name: 'T1RXXXXG51S' },
    },
  },
  TP2610: {
    name: 'ТЧ2610',
    label: 'КЕКВ 2610',
    columns: {
      lineno: { label: '№', name: 'НомерСтроки' },
      T1RXXXXG1S: { label: 'Назва закладу охорони здоров\'я', name: 'T1RXXXXG1S' },
      T1RXXXXG2S: { label: 'КПКВКМБ', name: 'T1RXXXXG2S' },
      T1RXXXXG27: { label: '2282 Зведений план викор. коштів на поточний рік. Всього', name: 'T1RXXXXG27' },
      T1RXXXXG28: { label: '2282 Зведений план викор. коштів на поточний рік. Всього (за казначейськими даними)', name: 'T1RXXXXG28' },
      T1RXXXXG31: { label: '2282 Касові видатки на кінець звітного місяця. Всього', name: 'T1RXXXXG31' },
      T1RXXXXG32: { label: '2282 Касові видатки на кінець звітного місяця. Всього (за казначейськими даними)', name: 'T1RXXXXG32' },
      T1RXXXXG33: { label: '2610 Зведений план викор. коштів на поточний рік. Всього', name: 'T1RXXXXG33' },
      T1RXXXXG34: { label: '2610 Зведений план викор. коштів на поточний рік. Всього (за казначейськими даними)', name: 'T1RXXXXG34' },
      T1RXXXXG37: { label: '2610 Касові видатки на кінець звітного місяця. Всього', name: 'T1RXXXXG37' },
      T1RXXXXG38: { label: '2610 Касові видатки на кінець звітного місяця. Всього (за казначейськими даними)', name: 'T1RXXXXG38' },
      noDataPresent: { label: 'НетДанных', name: 'НетДанных' },
    },
  },
  descendantsDocuments: {
    name: 'Доки',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Документ' },
      isApproved: { label: 'Затверджений', name: 'Утвержден' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація про бюджетні видатки на охорону здоров\'я',
  name: 'expensesOnHealth',
  backendName: 'ИнформацияОРасходахНаЗдоровъя',
  columns,
  frontend: 'doc/expensesOnHealth',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'FI',
      'year',
      'appr',
      'note',
      'isApproved',
      'isSentLogica',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
