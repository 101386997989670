import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

function ShowPDFModal({ pdflink }) {
  if (!pdflink) return null;
  return (
    <object data={pdflink} type="application/pdf" width="100%" height="500px">
      <Alert variant="danger">
        Не вдалося відобразити PDF.
      </Alert>
    </object>
  );
}

ShowPDFModal.propTypes = {
  pdflink: PropTypes.string,
};

ShowPDFModal.defaultProps = {
  pdflink: null,
};

export default ShowPDFModal;
