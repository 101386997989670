import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '../../../../components/blanks/common/Flex';
import barChart from '../../../../assets/img/illustrations/crm-bar-chart.png';
import lineChart from '../../../../assets/img/illustrations/crm-line-chart.png';
import { EditorControls } from '../../../basicEditor/editorControls';

function GreetingCard() {
  const [cDt, setDt] = useState(new Date());
  return (
    <Card className="bg-100 shadow-none border">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col sm="auto">
            <Flex alignItems="center">
              <img src={barChart} width={90} alt="..." className="ms-n4" />
              <div>
                <h6 className="text-primary fs--1 mb-0">Вітаємо у </h6>
                <h4 className="text-primary fw-bold mb-0">
                  ЄІСУБ,
                  <span className="text-info fw-medium"> Робочий простір керівника</span>
                </h4>
              </div>
              <img
                src={lineChart}
                width={140}
                alt="..."
                className="ms-n4 d-md-none d-lg-block"
              />
            </Flex>
          </Col>
          <Col md="auto" className="mb-3 mb-md-0">
            <Row className="g-3 gy-md-0 h-100 align-items-center">
              {/* <Col xs="auto"> */}
              {/*  <h6 className="text-700 mb-0 text-nowrap">Інформація, станом на:</h6> */}
              {/* </Col> */}
              <Col xs="auto">
                <EditorControls.DateInput
                  value={cDt}
                  onChange={(e, v) => setDt(v)}
                  label="Інформація, станом на:"
                />
              </Col>
              <Col md="auto">
                {/* <GreetingsDate /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

// GreetingCard.propTypes = {
//   notifications: PropTypes.arrayOf({}),
// };
//
// GreetingCard.defaultProps = {
//   notifications: [],
// };

export default GreetingCard;
