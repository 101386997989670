const frontendURL = '/rep/summary_offer/';
const backendURL = '/api/financials/printform/summary_offer/';
const name = 'Зведена пропозиція на фінансування';
const sections = '';

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
