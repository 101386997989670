/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import TableSection from '../../../../components/pureExpendableTable/TableSection';
import CardHeader from '../../../../components/blanks/common/CardHeader';

const StyledTh = Styled.th`
  border: 1px solid var(--falcon-gray-400) !important;
`;
const StyledTh3 = Styled(StyledTh)`
  width: 3%;
`;

const StyledTh10 = Styled(StyledTh)`
  width: 10%;
`;

const StyledTh32 = Styled(StyledTh)`
  width: 32%;
`;

const StyledTh15 = Styled(StyledTh)`
  width: 15%;
`;

function RevenueTable({ data }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader title="Доходи бюджету на 2024 рік, згідно дод.1 до рішення сесії про бюджет" titleTag="h6" />
      <Card.Body className="p-0">
        <Table striped className="fs--1 mb-0" bordered responsive>
          <thead className="bg-200 text-800">
            <tr>
              <StyledTh3 rowSpan="2" className="text-center" scope="col">Код</StyledTh3>
              <StyledTh32 rowSpan="2" className="text-center" scope="col"> Найменування згідно з Класифікацією доходів бюджету</StyledTh32>
              <StyledTh15 colSpan="2" className="text-center" scope="col">Усього</StyledTh15>
              <StyledTh10 colSpan="2" className="text-center" scope="col">Загальний фонд</StyledTh10>
              <StyledTh colSpan="2" className="text-center" scope="col">Спеціальний фонд</StyledTh>
            </tr>
            <tr>
              <StyledTh10>План</StyledTh10>
              <StyledTh10>Факт</StyledTh10>
              <StyledTh10>План</StyledTh10>
              <StyledTh10>Факт</StyledTh10>
              <StyledTh10>План</StyledTh10>
              <StyledTh10>Факт</StyledTh10>
            </tr>
          </thead>
          <tbody>
            { data.map((rev, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <TableSection revenues={rev} key={`revScec-${index}`} level={0} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

RevenueTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    amount_zf_fact: PropTypes.number,
    amount_zf_plan: PropTypes.number,
    amount_sf_fact: PropTypes.number,
    amount_sf_plan: PropTypes.number,
    amount_total_fact: PropTypes.number,
    amount_total_plan: PropTypes.number,
    kdb: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    nested: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
};

export default RevenueTable;
