import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Doctablerow from '../../../newLister/tableComponent/table/row/doctablerow';
import baseMD from '../../../../constants/meta/documents/base';
import md from '../../../../constants/meta/documents/freeRem4Add2024';

function FreeRemListerRow({ row, ...rest }) {
  const newRow = useMemo(
    () => {
      if (row[baseMD.columns.isDeleted.name] || (!row[baseMD.columns.isProcessed.name])) return row;

      let clsName = row.className || '';

      if (row[md.columns.apprByRegion.name]) {
        clsName = `${clsName} text-primary`;
      }
      return {
        ...row,
        className: clsName,
      };
    },
    [row],
  );
  return <Doctablerow row={newRow} {...rest} />;
}

FreeRemListerRow.propTypes = {
  row: PropTypes.shape({
    className: PropTypes.string,
  }).isRequired,
};

export default memo(FreeRemListerRow);
