import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { streets as md } from '../../../../constants/meta/catalogs/streets';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import toponimTypes from '../../../../constants/meta/enums/toponims';

const toponimTypesOptions = Object.values(toponimTypes).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function Streets({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.budget.label}
            value={data[md.columns.budget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budget.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="districts"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.toponim.label}
            value={data[md.columns.toponim.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.toponim.name]: value,
            })}
            values={toponimTypesOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={60}
            readOnly={readOnly}
          />
        </Col>
      </Row>

    </Container>
  );
}

Streets.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Streets;
