import React, {
  forwardRef, memo, useContext, useMemo,
} from 'react';
import {
  NavDropdown,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CommandPanel, SearchInput } from '../../../../components/bootStrap';
import ListerContext from '../../context';
import {
  ShowDeletedToggler, FiltersButton, NewButton, EditButton, DeleteButton, RefreshButton,
  CopyButton, ExecuteButton, UnExecuteButton,
} from '../../../../components/bootStrap/buttons';
import { SetPeriodButton } from './buttons';
import { childrenPropType } from '../../../../constants/backend/propTypes';
// eslint-disable-next-line import/no-cycle
import PrintFormButton from '../../../newEditor/printFormButton';

const DocListerCommandPanel = forwardRef(({
  children, prepend, append,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString, period, localFilter, printForms,
    selectedRows, onResetColumnSizes, items, filterOpened,
  } = useContext(ListerContext);
  const currentItem = useMemo(
    () => (selectedRows.length ? String(selectedRows[0]) : null),
    [selectedRows],
  );
  const currentRepr = useMemo(
    () => items
      .filter((item) => item.id === Number.parseInt(currentItem, 10))
      .reduce((R, item) => item.repr, null),
    [currentItem, items],
  );
  const filterApplied = !!Object.keys(localFilter).filter((k) => localFilter[k].use).length;
  const approvedRows = useMemo(
    () => items
      .filter((item) => selectedRows.includes(item.id) && 'approved' in item && item.approved),
    [items, selectedRows],
  );

  return (
    <CommandPanel
      sticky="top"
      ref={ref}
      leftPart={(
        <>
          {prepend}
          <NewButton onClick={actions.onNew} disabled={!(permissions.canNew && actions.onNew)} />
          <CopyButton
            onClick={actions.onCopy}
            disabled={!(permissions.canCopy && actions.onCopy)}
          />
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={() => {
              if (approvedRows.length) {
                toast.error('Затверджені документи не можна видаляти', {
                  theme: 'colored',
                });
              } else {
                actions.onDelete();
              }
            }}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
          <RefreshButton
            onClick={actions.onRefresh}
          />
          <ExecuteButton
            onClick={actions.onExecute}
            disabled={!(permissions.canExecute && actions.onExecute)}
          />
          <UnExecuteButton
            onClick={actions.onUnexecute}
            disabled={!(permissions.canUnexecute && actions.onUnexecute)}
          />
          <SetPeriodButton value={period} onChange={actions.onSetPeriod} />
          <FiltersButton
            onClick={() => actions.onToggleFilterOpened()}
            disabled={!permissions.canFilter}
            animation={filterApplied}
            variant={filterOpened ? 'falcon-warning' : 'falcon-primary'}
          />
          {printForms && !!printForms.length && (
          <PrintFormButton
            printForms={printForms}
            instanceId={currentItem}
            instanceRepr={currentRepr}
            disabled={!currentItem}
          />
          )}
          {children}
        </>
        )}
      rightPart={(
        <div className="d-flex flex-nowrap">
          <ShowDeletedToggler
            value={showDeleted}
            disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
            onChange={actions.onToggleShowDeleted}
          />
          <SearchInput
            onChange={actions.onSearch}
            value={searchString}
            disabled={!permissions.canSearch && !searchString}
          />
        </div>
      )}
      ddItems={(
        <>
          <NavDropdown.Item onClick={() => {
            onResetColumnSizes();
            actions.onRefresh();
          }}
          >
            <FontAwesomeIcon icon={faTableColumns} className="me-2" />
            Скинути налаштування ширини колонок
          </NavDropdown.Item>
          {append}
        </>
      )}
    />
  );
});

DocListerCommandPanel.propTypes = {
  children: childrenPropType,
  prepend: childrenPropType,
  append: childrenPropType,
};

DocListerCommandPanel.defaultProps = {
  children: null,
  prepend: null,
  append: null,
};

export default memo(DocListerCommandPanel);
