import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  period: { label: 'За період', name: 'ПериодДокументов' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  date: { label: 'Дата', name: 'ДатаОтчета' },
  Transfer: { label: 'Передача коштів', name: 'ПередачаСредств' },
  CSU: { label: 'Розпорядник', name: 'Распорядитель' },
  FIAccount: { label: 'р./р. Розп. вищого рівня', name: 'РСФинансовыйОрган' },
  CSUAccount: { label: 'р./р. Розпорядника', name: 'РСРаспорядитель' },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд' },
  ApprovedPlan: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  FI: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
  LoadLoaded: { label: 'Завантаження документів з ознакою "Отримано"', name: 'флЗагружено' },
  LoadFinanced: { label: 'Завантаження документів з ознакою "Профінансовано"', name: 'флПрофинансировано' },
  Total: { label: 'Разом', name: 'Всего' },
  WithoutNotes: { label: 'Імпортувати без приміток', name: 'БезПримечаний' },
  ImportAllNotes: { label: 'Не зводити примітку', name: 'ИмпортВсехПримечаний' },
  ImportFullNotes: { label: 'Не зводити примітку (в заявці)', name: 'ИмпортПримечанийВЗаявку' },
  DataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных' },
  Autor: { label: 'Автор', name: 'Автор' },
  planingMode: { label: 'Вид планування', name: 'ВидПлана' },
  includeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
  },
  // Дополнительные свойства
  isRequest: { label: 'ЭтоЗявка', name: 'ЭтоЗявка' },
  isFinPropose: { label: 'ЭтоФинПредложение', name: 'ЭтоФинПредложение' },
  isFinancing: { label: 'ЭтоФинансирование', name: 'ЭтоФинансирование' },
  // KBP7: { label: 'КБП7', name: 'КБП7' },
  // KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
  // elKEKV: { label: 'Деталізація КЕКВ', name: 'ЭлементКЕКВ' },
  // elKFK: { label: 'Бюджетна програма', name: 'ЭлементКФК' },
  DCSettings: { label: 'Налашування СКД', name: 'НастройкиСКД', type: 'DCSettings' },
  attachedFiles: { label: 'Додані фали', name: 'ТаблицаПрикрепленныхФайлов', visible: false },
  implementer: {
    label: 'Відділ виконавець',
    name: 'ОтветственныйИсполнитель',
    visible: false,
  },
};

/**
 * @const
 */
const tables = {
  docsTable: {
    name: 'Заявки',
    label: 'Документи',
    columns: {
      Check: { label: 'М', name: 'М' },
      Document: { label: 'Документ', name: 'Документ' },
      Sum: { label: 'Сума за документом', name: 'Сумма' },
      Autor: { label: 'Підготував', name: 'Автор' },
      DocNo: { label: 'Номер', name: 'Номер' },
      Date: { label: 'Дата', name: 'Дата' },
      Balance: { label: 'Недофінансовано', name: 'СуммаОстаток' },
      Notes: { label: 'Примітка', name: 'Примечание' },
    },
  },
  documentNotes: {
    name: 'СодержаниеДокумента',
    label: 'Зміст',
    columns: {
      Document: { label: 'Документ', name: 'Документ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      ElementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      ElementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      Sum: { label: 'Сума', name: 'Сумма' },
      Notes: { label: 'Примітка для розпорядження', name: 'Примечание' },
      CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
      NoteCat: { label: 'Вид платежу', name: 'ПримечаниеСпр' },
      NoteDoc: { label: 'Власний шаблон (не обов\'язковий)', name: 'ПримечанияДок' },
      FullNotes: { label: 'Примітка для заявці', name: 'ПолноеПримечание' },
      FinLiabilitiy: { label: 'Фінансове зобов\'язання', name: 'ФинансовоеОбязательство' },
      Client: { label: 'Постачальник', name: 'КонтрагентЗаявка' },
      MonthReturn: { label: 'Місяць повернення', name: 'МесяцВозврата' },
      isReturn: { label: 'Повернення', name: 'ЭтоВозврат' },
      KEKVCode: { label: 'Код КЕКВ', name: 'КодКЕКВ' },
      KPKVCode: { label: 'Код КПКВ', name: 'КодКПКВ' },
      StrNumber: { label: 'Номер рядку', name: 'НомерСтроки' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'requestsLoading',
  backendName: 'ЗагрузкаЗаявокWEB',
  label: 'Завантаження документів',
  columns,
  tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
