import { Map } from 'immutable';
import authActions from '../constants/actions/auth';

/**
 * @desc Reducer provides info about sessionOptions
 * @func
 * @param state {Immutable.Map}
 * @param action {object}
 * @param action.type {string}
 * @param action.payload {{token:{string},sessionOptions:{object}}}
 * @return {Immutable.Map}
 */
const authReducer = (state = new Map(), { type, payload }) => {
  const newStore = new Map();
  switch (type) {
    case authActions.login:
      return newStore
        .set('accessToken', payload.get('accessToken'))
        .set('refreshToken', payload.get('refreshToken'))
        .set('AP', payload.get('AP'))
        .set('sessionOptions', payload.get('sessionOptions'))
        .set('connected', false);
    case authActions.refresh:
      return state
        .set('accessToken', payload.get('token'));
    case authActions.logout:
      return new Map();
    case authActions.logikaLogin:
      localStorage.setItem('ticket', payload.ticket);
      return state.set('logikaTicket', payload.ticket);
    case authActions.logikaLogout:
      localStorage.removeItem('ticket');
      return state.delete('logikaTicket');
    case authActions.connected:
      return state.set('connected', true);
    case authActions.disconnected:
      return state.set('connected', false);
    default:
      return state;
  }
};

export default authReducer;
