import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';

export const dataPropTypes = {
  disposer: foreignPropType,
  kbp7_item: foreignPropType,
  kekv_item: foreignPropType,
  kdb: foreignPropType,
  kfb: foreignPropType,
  amount_total: PropTypes.number,

};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));
export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
