import React, {
  useState, useMemo, cloneElement, Children,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/budgetRequest';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import { childrenPropType } from '../../../../common/proptypes';

const filterValues = [
  { value: 1, label: 'За розпорядником' },
  { value: 2, label: 'За розпорядником нижчого рівня' },
  { value: 3, label: 'Всі' },
  // { value: 4, label: 'За розпорядником вищого рівня' },
];

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
  finRequestsHOC: store.getIn(['auth', 'sessionOptions', 'finRequestsHOC'], false),
  currentFOParent: store.getIn(['auth', 'sessionOptions', 'foParent'], new Map()),
});

function ListerSelector({ children }) {
  const {
    FO, isAdmin, finRequestsHOC, currentFOParent,
  } = useSelector(mapState);

  const [fValue, setFValue] = useState(isAdmin ? 3 : 1);
  const displayFilterValues = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => finRequestsHOC ? filterValues : filterValues.filter(((f) => f.value !== 4)),
    [finRequestsHOC],
  );
  const flt = useMemo(
    () => {
      switch (fValue) {
        case 1:
          return [{
            fieldName: md.columns.FI.name,
            value: FO.toJS(),
          }];
        case 2:
          return [{
            fieldName: `${md.columns.FI.name}.Родитель`,
            value: FO.toJS(),
          }];
        case 4:
          return [{
            fieldName: `${md.columns.FI.name}`,
            value: currentFOParent.toJS(),
          }];
        default:
          return [];
      }
    },
    [FO, currentFOParent, fValue],
  );
  const c = useMemo(
    () => cloneElement(Children.only(children), { filter: flt }),
    [children, flt],
  );
  return (
    <>
      <FilteredTabs values={displayFilterValues} value={fValue} onChange={(e, v) => setFValue(v)} />
      {c}
    </>
  );
}

ListerSelector.propTypes = {
  children: childrenPropType.isRequired,
};

export default ListerSelector;
