import React from 'react';
import { MarginForSortIcon } from './sortUp';

function SortDownIcon(props) {
  return (
    <MarginForSortIcon {...props}>
      <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 2.5V9.5L2.5 7.5" stroke="#4D4D4D" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.5 9.5H7.5" stroke="#4D4D4D" strokeLinecap="round" />
        <path d="M6.5 6.5H8.5" stroke="#4D4D4D" strokeLinecap="round" />
        <path d="M6.5 3.5H9.5" stroke="#4D4D4D" strokeLinecap="round" />
      </svg>
    </MarginForSortIcon>
  );
}

export default SortDownIcon;
