import React from 'react';

import GridTemplate from './GridTemplate';
import md from '../../../../../constants/meta';
import { DivHeader } from '../../../../../components/styledTable/styledTable';

const coldef = md.dp.omitElementKFK.tables.csues.columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="check">{coldef.check.label}</DivHeader>
      <DivHeader areaName="csu">{coldef.csu.label}</DivHeader>
    </GridTemplate>
  );
}

HeaderComponent.defaultProps = {
  activeAreaName: '',
};

export default HeaderComponent;
