import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період', name: 'Период' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  codeAdd: { label: 'Виводити коди надходжень (замість кодів КЕКВ у групі "Надходження")', name: 'КодПоступлений' },
  flFi: { label: 'Прізвище та ім\'я', name: 'ФлФИ' },
};

const definition = {
  label: 'Довідка про операції в національній валюті (Додаток 22)',
  name: 'CertNationalCurr',
  columns,
  frontend: 'rep/CertNationalCurr',
  backendName: 'СправкаОбОперацияхВНациональнойВалюте',
  resultColumns: baseResultColumns,
};

export default definition;
