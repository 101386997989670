import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
  TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/expensesOnEducation';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
  FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  const onKVKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSU.name]: v });
      await onSR('CHANGE_KVK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={3}
        sm={12}
        className="d-flex flex-column"
      >
        <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
          <ItemPicker
            value={row[tableMD.columns.CSU.name]}
            modelType="cat"
            modelName="csu"
            onChange={onKVKChange}
            filter={CSUFIlter}
            noHierarchy
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="CSUtext" highlighted={highlights.includes('CSUtext')}>
          <TextInput
            value={row[tableMD.columns.CSUtext.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.CSUtext.name]: v })}
            readOnly={readOnly}
            rows={1}
          />
        </TableRowCell>
        <TableRowCell column="EDRPOU" highlighted={highlights.includes('EDRPOU')}>
          <TextInput
            value={row[tableMD.columns.EDRPOU.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.EDRPOU.name]: v })}
            readOnly={readOnly}
            rows={1}
          />
        </TableRowCell>
        <TableRowCell column="KBP7" highlighted={highlights.includes('KBP7')}>
          <ItemPicker
            value={row[tableMD.columns.KBP7.name]}
            modelType="cat"
            modelName="kbp7Classifier"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.KBP7.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
        <TableRowCell column="NonPedagogicalStaff" highlighted={highlights.includes('NonPedagogicalStaff')}>
          <NumberInput
            value={row[tableMD.columns.NonPedagogicalStaff.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.NonPedagogicalStaff.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="d-flex flex-column"
      >
        <TableRowCell column="ExpensesPrevYear" highlighted={highlights.includes('ExpensesPrevYear')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesPrevYear.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesPrevYear.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="ExpensesPedWorkersSub" highlighted={highlights.includes('ExpensesPedWorkersSub')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesPedWorkersSub.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesPedWorkersSub.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="ExpensesPedWorkersBud" highlighted={highlights.includes('ExpensesPedWorkersBud')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesPedWorkersBud.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesPedWorkersBud.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="d-flex flex-column"
      >
        <TableRowCell column="ExpensesThisYear" highlighted={highlights.includes('ExpensesThisYear')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesThisYear.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesThisYear.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="ExpensesThisYearSub" highlighted={highlights.includes('ExpensesThisYearSub')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesThisYearSub.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesThisYearSub.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="ExpensesThisYearBud" highlighted={highlights.includes('ExpensesThisYearBud')}>
          <NumberInput
            value={row[tableMD.columns.ExpensesThisYearBud.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.ExpensesThisYearBud.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
      </Col>
      <Col
        xl={3}
        sm={12}
        className="d-flex flex-column"
        Jg
      >
        <TableRowCell column="AvergageSalaryBoss" highlighted={highlights.includes('AvergageSalaryBoss')}>
          <NumberInput
            value={row[tableMD.columns.AvergageSalaryBoss.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.AvergageSalaryBoss.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="AvergageSalaryTeacher" highlighted={highlights.includes('AvergageSalaryTeacher')}>
          <NumberInput
            value={row[tableMD.columns.AvergageSalaryTeacher.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.AvergageSalaryTeacher.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
        <TableRowCell column="AvergageSalaryYoung" highlighted={highlights.includes('AvergageSalaryYoung')}>
          <NumberInput
            value={row[tableMD.columns.AvergageSalaryYoung.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.AvergageSalaryYoung.name]: v })}
            readOnly={readOnly}
            precision={2}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.CSU.name]: referencePropType,
    [tableMD.columns.KBP7.name]: referencePropType,
    [tableMD.columns.NonPedagogicalStaff.name]: PropTypes.number,
    [tableMD.columns.ExpensesPrevYear.name]: PropTypes.number,
    [tableMD.columns.ExpensesPedWorkersSub.name]: PropTypes.number,
    [tableMD.columns.ExpensesPedWorkersBud.name]: PropTypes.number,
    [tableMD.columns.ExpensesThisYear.name]: PropTypes.number,
    [tableMD.columns.ExpensesThisYearSub.name]: PropTypes.number,
    [tableMD.columns.ExpensesThisYearBud.name]: PropTypes.number,
    [tableMD.columns.AvergageSalaryBoss.name]: PropTypes.number,
    [tableMD.columns.AvergageSalaryTeacher.name]: PropTypes.number,
    [tableMD.columns.AvergageSalaryYoung.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType,
  onSR: PropTypes.func.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  FI: null,
};

export default memo(TPRow);
