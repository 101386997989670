import React, {
  useEffect, useMemo, memo, useCallback, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ea, { decrypt, decryptionClose } from '../../actions/reportEditor';
import storePathParam from '../../common/storePathParam';
import { ErrorMessage } from '../../components/Form/styledForm';
import meta, { metaMaper } from '../../constants/meta';
import ReportContextMenu from './context_menu';
import TableEditor from '../tableEditor';

export const mapState = (store) => {
  const editorNode = store.getIn([`rep/${storePathParam(store).name}/reportEditor`], new Map());
  const sessionOptions = store.getIn(['auth', 'sessionOptions'], new Map());

  const res = {
    isProcessing: editorNode.get('isProcessing', true),
    hasError: editorNode.get('hasError', false),
    errorMsg: editorNode.get('errorMsg', ''),
    headerForm: editorNode.get('headerForm', new Map()), // Шапка
    sessionOptions,
  };

  return res;
};

/**
 * @param model {String}
 * @param modelType {String}
 * @param [extra.menu] {React.Component}
 * @param [extra.tables] {React.Component}
 * @param [extra.footer] {React.Component}
 * @returns {function(<object>): *}
 * @constructor
 */
const EditorHOC = ({ name, type }, extra) => (WrappedComponent) => {
  function BasicEditor({
    dispatch, isProcessing, hasError, errorMsg, mapInitialOptions, mapStore, ...restProps
  }) {
    useEffect(
      () => dispatch(ea.init(type, name, mapInitialOptions, mapStore, extra.autoExecute)),
      [dispatch, mapInitialOptions, mapStore],
    );
    const navigate = useNavigate();
    const md = useMemo(
      () => meta[type][name],
      [],
    );
    const node = useMemo(
      () => `rep/${md.name}/reportEditor`,
      [md.name],
    );
    const decMountNode = useMemo(
      () => [node, 'decryption', 'result'],
      [node],
    );

    const { availableGroups } = useSelector(
      (state) => ({
        groups: state.getIn([node, 'dataCompositionSettings', 'Group'], new OrderedMap()),
        availableGroups: state.getIn([node, 'dataCompositionSettings', 'GroupAvailableFields', 'items'], new Map()),
      }),
    );

    const decryptionOpened = useSelector(
      (state) => state.getIn([node, 'decryption', 'opened'], false),
    );

    const MenuWrapper = extra && extra.menu;
    const TablesWrapper = extra && extra.tables;
    const FooterWrapper = extra && extra.footer;

    const [menu, setMenu] = useState(null);
    // Значения группировок, для обработки расшифровки
    const gvalues = useRef(null);

    const onReportDetails = useCallback(
      (e, decr, groupValues) => {
        const urlitems = decr
          .filter((v) => Map.isMap(v) && !!v.get('modelType') && !!v.get('modelName'))
          .map((v) => v.set('url', meta[v.get('modelType')][metaMaper[v.get('modelType')][v.get('modelName')]].frontend));
        if (urlitems.size === 1) {
          navigate(`/${urlitems.first().get('url')}/${urlitems.first().get('id')}`);
          return false;
        }
        const availableDescriptions = availableGroups.filter((v, k) => !groupValues.has(k));
        gvalues.current = groupValues;
        setMenu({
          coords: { x: e.clientX, y: e.clientY },
          // target: e.target,
          items: availableDescriptions.reduce((R, item) => [
            ...R,
            { key: item.get('Field'), title: item.get('Tittle') },
          ], []),
        });
        return false;
      },
      [availableGroups, navigate],
    );

    const onDectyptionSelect = useCallback(
      (key) => {
        dispatch(decrypt(gvalues.current, key));
        setMenu(null);
      },
      [dispatch],
    );

    const compProps = useMemo(
      () => ({
        dispatch,
        isProcessing,
        hasError,
        errorMsg,
        onReportDetails,
        ...restProps,
      }),
      [dispatch, errorMsg, hasError, isProcessing, onReportDetails, restProps],
    );
    return (
      <>
        {MenuWrapper && (
          <MenuWrapper
            dispatch={dispatch}
            disabled={isProcessing || hasError}
          />
        )}
        {hasError && (
          <ErrorMessage>
            {errorMsg}
          </ErrorMessage>
        )}
        <WrappedComponent {...compProps} />
        {TablesWrapper && (
          <TablesWrapper />
        )}
        {FooterWrapper && React.cloneElement(FooterWrapper, compProps)}
        {menu && (
          <ReportContextMenu
            items={menu.items}
            coords={menu.coords}
            onSelect={onDectyptionSelect}
            onClose={() => setMenu(null)}
          />
        )}
        <Modal show={decryptionOpened} onHide={() => dispatch(decryptionClose())} scrollable size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Розшифровка</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(md.resultColumns).map((n) => (
              <TableEditor mountNode={[...decMountNode, n]} />
            ))}
          </Modal.Body>
        </Modal>
      </>
    );
  }
  BasicEditor.displayName = `Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  BasicEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    hasError: PropTypes.bool,
    errorMsg: PropTypes.string,
    children: PropTypes.node,
    mapInitialOptions: PropTypes.shape(),
    mapStore: PropTypes.func,
  };

  BasicEditor.defaultProps = {
    isProcessing: false,
    hasError: false,
    errorMsg: null,
    mapInitialOptions: null,
    mapStore: null,
    children: null,
  };
  return memo(BasicEditor);
};

export default EditorHOC;
