import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import loadScriptAsync from './load_script';

export const LIBRARY_TYPES = {
  file: '1',
  km: '2',
  // ksp: '3',
};

export class LibraryNotInstalledError extends Error {
  constructor(message, url) {
    super(message);
    this.url = url;
    this.name = 'LibraryNotInstalledError';
  }
}

const useEU = () => {
  const euSignFile = useRef();
  const euSignMedia = useRef();
  const [libraryType, setLibraryType] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const euSettings = useRef(null);
  const [keyMedias, setKeyMedias] = useState(null);
  const [selectedKM, setSelectedKM] = useState(null);
  const [cert, setCert] = useState(null);
  const scripts = useRef([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [issuers, setIssuers] = useState([]);
  const [issuer, setIssuer] = useState(null);
  const [needCertificate, setNeedCertificate] = useState(false);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const loadLibraries = async () => Promise.allSettled([
      loadScriptAsync(`${process.env.PUBLIC_URL}/iit_library/euscp.js`),
      loadScriptAsync(`${process.env.PUBLIC_URL}/iit_library/euscpt.js`),
    ]);
    setErr(null);
    setLoading(true);
    loadLibraries()
      .then((loadedScripts) => {
        scripts.current = loadedScripts
          .filter((s) => s.status === 'fulfilled')
          .map((s) => s.value);
        euSignFile.current = new global.EndUser(
          `${process.env.PUBLIC_URL}/iit_library/euscp.worker.ex.js`,
          global.EndUserConstants.EndUserLibraryType.JS,
        );
        euSignMedia.current = new global.EndUser(
          null,
          global.EndUserConstants.EndUserLibraryType.SW,
        );
        euSettings.current = {
          language: 'uk',
          encoding: 'utf-8',
          httpProxyServiceURL: process.env.REACT_APP_EU_SERVER_URL,
          directAccess: true,
          CAs: `${process.env.PUBLIC_URL}/Data/CAs.json`,
          CACertificates: `${process.env.PUBLIC_URL}/Data/CACertificates.p7b`,
          // Реєстрація хмарних провайдерів
          KSPs: [
            {
              name: 'ІІТ - хмарний підпис (2)',
              ksp: global.EndUserConstants.EndUserKSP.IIT,
              address: 'https://sserver2.iit.com.ua',
              port: '443',
            },
          ],
        };

        const oldLT = localStorage.getItem('ciatWdigetLibraryType');
        setLibraryType(oldLT || LIBRARY_TYPES.file);
      })
      .catch((e) => setErr({ type: 'danger', message: e.message }))
      .finally(() => setLoading(false));

    return () => {
      scripts.current.forEach((s) => s.remove());
      if (euSignFile.current && euSignFile.current.IsInitialized()) {
        euSignFile.current.m_library.m_worker.m_worker.terminate();
      }
    };
  }, []);

  const onConfirmKSPOperation = useCallback(
    (kspEvent) => {
      console.log(kspEvent);
    },
    [],
  );

  // Перечитать токены, доступные в системе
  const reloadKeyMedia = useCallback(
    async () => {
      if (libraryType === LIBRARY_TYPES.km) {
        const kms = await euSignMedia.current.GetKeyMedias();
        setKeyMedias(kms);
      }
    },
    [libraryType],
  );

  useEffect(() => {
    // Инициализация библиотеки для файловых
    const initFileLibrary = async () => {
      euSignFile.current.Initialize(euSettings.current);
      const cas = await euSignFile.current.GetCAs();
      setIssuers(cas.map((c) => c.issuerCNs[0]));
      euSignFile
        .current
        .SetRuntimeParameter(global.EU_SIGN_TYPE_PARAMETER, global.EU_SIGN_TYPE_CADES_X_LONG);
      // SignType = CAdES-X Long,);
      euSignFile.current.AddEventListener(
        global.EndUserConstants.EndUserEventType.ConfirmKSPOperation,
        onConfirmKSPOperation,
      );
      return true;
    };
    const initMediaLibrary = async () => {
      const info = await euSignMedia.current.GetLibraryInfo();
      if (!info.supported) throw new Error('Бібліотека web-підпису не підтримується в вашому браузері або ОС');
      if (!info.loaded) {
        // Бібліотека встановлена, але потребує оновлення
        if (info.isNativeLibraryNeedUpdate) {
          throw new LibraryNotInstalledError(
            `Бібліотека web-підпису потребує оновлення. Будь ласка, встановіть оновлення за посиланням ${info.nativeLibraryInstallURL}`,
            info.nativeLibraryInstallURL,
          );
        }
        if (info.isWebExtensionSupported && !info.isWebExtensionInstalled) {
          throw new LibraryNotInstalledError(
            `Бібліотека web-підпису потребує встановлення web-розширення. Будь ласка, встановіть web-розширення за посиланням ${info.webExtensionInstallURL}`,
            info.webExtensionInstallURL,
          );
        }
        throw new LibraryNotInstalledError(
          `Бібліотека web-підпису потребує встановлення. Будь ласка, встановіть бібліотеку за посиланням ${info.nativeLibraryInstallURL}`,
          info.nativeLibraryInstallURL,
        );
      }
      const inited = await euSignMedia.current.IsInitialized();
      if (!inited) {
        await euSignMedia.current.Initialize(euSettings.current);
        const cas = await euSignMedia.current.GetCAs();
        setIssuers(cas.map((c) => c.issuerCNs[0]));
        euSignMedia
          .current
          .SetRuntimeParameter(global.EU_SIGN_TYPE_PARAMETER, global.EU_SIGN_TYPE_CADES_X_LONG);
        // SignType = CAdES-X Long,);
      }
    };

    if (libraryType === null) return;
    setErr(null);
    setLoading(true);
    const initFunc = libraryType === LIBRARY_TYPES.file ? initFileLibrary : initMediaLibrary;
    initFunc()
      .then(() => {
        reloadKeyMedia();
        setInitialized(true);
      })
      .catch((e) => {
        if (e instanceof LibraryNotInstalledError) {
          setErr({
            type: 'warning',
            message: e.message,
            url: e.url,
          });
        } else {
          setErr({
            type: 'danger',
            message: e.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [libraryType, onConfirmKSPOperation, reloadKeyMedia]);

  const readFile = useCallback(
    (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          file,
          data: new Uint8Array(reader.result),
        });
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(file);
    }),
    [],
  );

  const getCertificates = useCallback(
    async () => {
      const rr = await Promise.all(certificates.map((crt) => readFile(crt)));
      return rr.map((r) => r.data);
    },
    [certificates, readFile],
  );

  const readPrivateFileKey = useCallback(
    /**
     * Прочитать файловый ключ
     * @param file {File} - файл
     * @param passwd {string} - пароль
     */
    (file, passwd) => {
      const readJKS = async (readedFile) => {
        const jksKeys = await euSignFile.current.GetJKSPrivateKeys(readedFile.data);
        // Для спрощення прикладу обирається перший ключ
        const pkIndex = 0;
        const pkCertificates = jksKeys[pkIndex].certificates.map((c) => c.data);
        // const caCN = issuer;
        return euSignFile.current.ReadPrivateKeyBinary(
          jksKeys[pkIndex].privateKey,
          passwd,
          pkCertificates,
          // caCN,
          issuer,
        );
      };

      setErr(null);
      setLoading(true);
      if (libraryType === LIBRARY_TYPES.file) {
        Promise.all([readFile(file), getCertificates()])
          .then(([result, pkCertificates]) => {
            if (file.name.endsWith('.jks')) {
              return readJKS(result);
            }
            // const pkCertificates = [];
            // const caCN = null;
            return euSignFile.current.ReadPrivateKeyBinary(
              result.data,
              passwd,
              pkCertificates,
              // caCN,
              issuer,
            );
          })
          .then((crt) => {
            setCert(crt);
          })
          .catch((e) => setErr({ type: 'danger', message: e.message }))
          .finally(() => setLoading(false));
      } else {
        throw new Error('This function implements file based keys only.');
      }
    },
    [getCertificates, issuer, libraryType, readFile],
  );
  const readPrivateMediaKey = useCallback(
    /**
     * Прочитать аппаратный ключ
     * @param passwd {string} - пароль
     */
    (passwd) => {
      const loader = (pkCertificates) => new Promise((resolve, reject) => {
        const skm = keyMedias.filter((s) => s.visibleName === selectedKM).reduce((R, k) => k, null);
        if (!skm) reject(new Error('Selected key media not in list'));
        const keyMedia = new global.EndUserKeyMedia(skm);
        keyMedia.password = passwd;
        euSignMedia.current.ReadPrivateKey(keyMedia, pkCertificates, issuer)
          .then((result) => resolve(result))
          .catch((e) => reject(e));
      });
      setErr(null);
      setLoading(true);
      if (libraryType === LIBRARY_TYPES.km) {
        getCertificates()
          .then((pkCertificates) => loader(pkCertificates))
          .then((crt) => {
            localStorage.setItem('ciatWdigetMediaKey', selectedKM);
            setCert(crt);
          })
          .catch((e) => {
            if (e.code === 51 && !!issuer) {
              setNeedCertificate(true);
              setErr({
                type: 'info',
                message: `Надавач ${issuer} не підтримує автоматичний пошук сертифіката за ос. ключем. Необхідно при необхідності скачати та обрати сертифікат(и) ос. ключа`,
                url: 'https://ca.treasury.gov.ua/пошук-сертифікатів/пошук-сертифікатів-користувачі',
              });
            } else {
              setErr({
                type: 'danger',
                message: e.message,
              });
            }
          })
          .finally(() => setLoading(false));
      } else {
        throw new Error('This function implements media based keys only.');
      }
    },
    [getCertificates, issuer, keyMedias, libraryType, selectedKM],
  );

  const currentLibrary = useMemo(
    () => {
      switch (libraryType) {
        case LIBRARY_TYPES.file:
          return euSignFile;
        case LIBRARY_TYPES.km:
          return euSignMedia;
        default:
          return null;
      }
    },
    [libraryType],
  );

  const signData = useCallback(
    /**
     * Подписать данные
     * @param data {string} - Строка для подписи
     * @param internal {boolean} - Включить ли в результат исходные данные
     * @returns {Promise<*>}
     */
    async (data, internal = false) => {
      if (!cert) throw new Error('Private key wont read');
      if (!currentLibrary) throw new Error('Not implemented');
      if (internal) return currentLibrary.current.SignDataInternal(true, data, true);
      return currentLibrary.current.SignData(data, true);
    },
    [cert, currentLibrary],
  );

  const checkSign = useCallback(
    async (sign, source = null) => {
      if (!currentLibrary) throw new Error('Not implemented');
      if (source === null) {
        return currentLibrary.current.VerifyDataInternal(sign);
      }
      return currentLibrary.current.VerifyData(source, sign);
    },
    [currentLibrary],
  );

  const onClearKey = useCallback(
    () => {
      if (libraryType === LIBRARY_TYPES.file) {
        euSignFile.current.ResetPrivateKey();
      } else {
        throw new Error('Not implemented');
      }
      setCert(null);
    },
    [libraryType],
  );
  const displayedKeyMedias = useMemo(
    () => {
      if (!keyMedias) return null;
      return keyMedias.map((k) => ({ value: k.visibleName, display_name: k.visibleName }));
    },
    [keyMedias],
  );

  const savedIssuer = useRef();
  savedIssuer.current = issuer;
  useEffect(() => {
    const oldIssuer = localStorage.getItem('ciatWdigetIssuer');
    setIssuer(oldIssuer);
    return () => {
      if (savedIssuer.current) localStorage.setItem('ciatWdigetIssuer', savedIssuer.current);
      else localStorage.removeItem('ciatWdigetIssuer');
    };
  }, []);

  // save settings
  useEffect(() => {
    if (libraryType === LIBRARY_TYPES.km && displayedKeyMedias && !selectedKM) {
      const oldKM = localStorage.getItem('ciatWdigetMediaKey');
      if (oldKM && displayedKeyMedias && displayedKeyMedias
        .map((d) => d.value)
        .includes(oldKM)
      ) setSelectedKM(oldKM);
    }
    return () => {
      if (libraryType) {
        localStorage.setItem('ciatWdigetLibraryType', libraryType);
      }
    };
  }, [displayedKeyMedias, libraryType, selectedKM]);
  return {
    initialized,
    readkeyFile: readPrivateFileKey,
    readKeyMedia: readPrivateMediaKey,
    cert,
    signData,
    loading,
    err,
    setErr,
    setLoading,
    onClearKey,
    libraryType,
    setLibraryType,
    keyMedias: displayedKeyMedias,
    selectedKM,
    setSelectedKM,
    issuers,
    issuer,
    setIssuer,
    needCertificate,
    certificates,
    setCertificates,
    checkSign,
    reloadKeyMedia,
  };
};

export default useEU;
