import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  EDRPOU: { label: 'ЄДРПОУ', name: 'ЄДРПОУ', visible: true },
  fullName: { label: 'Повне найменування', name: 'ПолнНаименование', visible: true },
};

export const csuTransfer = {
  label: 'Роспорядники для міжбюджетних транфертів',
  name: 'csuTransfer',
  columns,
  backendName: 'РаспорядителиДляТрансфертов',
  defaultOrder,
  frontend: 'cat/csuTransfer',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'EDRPOU',
      'fullName',
    ]),
  ],
  hierarchy: false,
};

export default csuTransfer;
