import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { focusNextElement } from '../../../common/funcs';

/**
 *
 * @param data {{}}
 * @param onChange {function}
 * @param tableName {string}
 * @param errors {{}}
 * @param readOnlyFields {string[]}
 * @param fields {{}}
 * @param nameCol {{}}
 *
 * @returns {{
 *    tableData: {}[],
 *    activeRow: number,
 *    activeCol: string,
 *    tableContainerRef: {current},
 *    highlights: string[],
 *    showFooter: bool,
 *    pinHeader: bool,
 *    pinAllowed: bool,
 *    tableReadOnlyFields: string[],
 *    tableErrors: {},
 *    tableFields: {},
 *    columns: [{}],
 *    tableActions: {
 *          onAddRow(function(): void),
 *          onCopyRow(function(): void),
 *          onDeleteRow(function(): void),
 *          onMoveUpRow(function(): void),
 *          onMoveDownRow(function(): void),
 *          onMoveRow(function(): void),
 *          onCellChange(function(): void),
 *          onHighlightColumn(function(): void),
 *          onUnhighlightColumn(function(): void),
 *          onToggleHighlightColumn(function(): void),
 *          onToggleFooter(function(): void),
 *          onTogglePin(function(): void),
 *          onAllowPin(function(): void),
 *    }
 * }}

*/
const useTablePart = ({
  data, onChange, tableName, errors, readOnlyFields, fields,
}) => {
  const [active, setActive] = useState({ row: null, col: null });
  const [highlights, setHighlights] = useState([]); // ПОдсвеченные колонки (массив имен)
  const [showFooter, setShowFooter] = useState(false);
  const [pinHeader, setPinHeader] = useState(true);
  const [pinAllowed, setPinAllowed] = useState(true);
  const tableContainerRef = useRef();

  const tableData = useMemo(
    () => (data && tableName in data ? data[tableName] : []),
    [data, tableName],
  );
  const tableFields = useMemo(
    () => (fields && tableName in fields ? fields[tableName].child.children : {}),
    [fields, tableName],
  );
  const rowsCount = tableData.length;

  const tableReadOnlyFields = useMemo(
    () => (readOnlyFields && tableName in readOnlyFields ? readOnlyFields[tableName] : []),
    [readOnlyFields, tableName],
  );
  const tableErrors = useMemo(
    () => (errors && tableName in errors ? errors[tableName] : []),
    [errors, tableName],
  );

  const onAddRow = useCallback(
    async (defaults = {}) => {
      await onChange((oldData) => ({
        [tableName]: [...oldData[tableName], defaults],
      }));
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onCopyRow = useCallback(
    async (rowIndex) => {
      await onChange(
        (oldData) => ({
          [tableName]: [...oldData[tableName], oldData[tableName][rowIndex]],
        }),
      );
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onDeleteRow = useCallback(
    async (rowIndex) => {
      await onChange((oldData) => ({
        [tableName]: [
          ...oldData[tableName].slice(0, rowIndex),
          ...oldData[tableName].slice(rowIndex + 1),
        ],
      }));
      // eslint-disable-next-line no-confusing-arrow
      setActive((o) => o.row === rowsCount - 1 ? { row: rowsCount - 1, col: o.col } : o);
    },
    [onChange, rowsCount, tableName],
  );
  const activeColName = active.col;
  const onMoveUpRow = useCallback(
    async (rowIndex) => {
      if (rowIndex) {
        await onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex - 1),
            oldData[tableName][rowIndex],
            oldData[tableName][rowIndex - 1],
            ...oldData[tableName].slice(rowIndex + 1),
          ],
        }));
        setActive({ row: rowIndex - 1, col: activeColName });
      } else {
        await onChange((oldData) => ({
          [tableName]: [...oldData[tableName].slice(1), oldData[tableName][0]],
        }));
      }
    },
    [onChange, tableName, activeColName],
  );

  const onMoveDownRow = useCallback(
    async (rowIndex) => {
      if (rowIndex < rowsCount - 1) {
        await onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex),
            oldData[tableName][rowIndex + 1],
            oldData[tableName][rowIndex],
            ...oldData[tableName].slice(rowIndex + 2),
          ],
        }));
        setActive({ row: rowIndex + 1, col: activeColName });
      } else {
        onChange((oldData) => ({
          [tableName]: [
            oldData[tableName][rowIndex],
            ...oldData[tableName].slice(0, rowIndex),
          ],
        }));
      }
    },
    [rowsCount, onChange, tableName, activeColName],
  );

  const onMoveRow = useCallback(
    async (from, to) => {
      if (from < to) {
        return onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, from),
            ...oldData[tableName].slice(from + 1, to),
            { ...oldData[tableName][to], isActive: true },
            { ...oldData[tableName][from], isActive: false },
            ...oldData[tableName].slice(to + 1),
          ],
        })).then(() => setActive({ row: to, col: activeColName }));
      }

      return onChange((oldData) => ({
        [tableName]: [
          ...oldData[tableName].slice(0, to),
          { ...oldData[tableName][from], isActive: false },
          { ...oldData[tableName][to], isActive: true },
          ...oldData[tableName].slice(to + 1, from),
          ...oldData[tableName].slice(from + 1),
        ],
      })).then(() => setActive({ row: to, col: activeColName }));
    },
    [onChange, tableName, activeColName],
  );

  const onCellChange = useCallback(
    async (e, rowIndex, partData) => onChange((oldData) => ({
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        { ...oldData[tableName][rowIndex], ...partData },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [onChange, tableName],
  );

  const onHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [...new Set([...o, ...c])]);
    },
    [],
  );
  const onUnhighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => o.filter((cc) => !c.includes(cc)));
    },
    [],
  );
  const onToggleHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [
        ...o.filter((cc) => !c.includes(cc)),
        ...c.filter((cc) => !o.includes(cc)),
      ]);
    },
    [],
  );

  const onToggleFooter = useCallback(
    () => setShowFooter((o) => !o),
    [],
  );
  const onTogglePin = useCallback(
    () => setPinHeader((o) => !o),
    [],
  );
  const onAllowPin = useCallback(
    (v) => setPinAllowed(v),
    [],
  );

  const tableActions = useMemo(
    () => ({
      onAddRow,
      onCopyRow,
      onDeleteRow,
      onMoveUpRow,
      onMoveDownRow,
      onMoveRow,
      onCellChange,
      onHighlightColumn,
      onUnhighlightColumn,
      onToggleHighlightColumn,
      onToggleFooter,
      onTogglePin,
      onAllowPin,
    }),
    [onAddRow, onCellChange, onCopyRow, onMoveRow,
      onDeleteRow, onHighlightColumn,
      onMoveDownRow, onMoveUpRow, onAllowPin,
      onToggleFooter, onToggleHighlightColumn, onTogglePin, onUnhighlightColumn],
  );

  const onFocusIn = useCallback(
    (e) => {
      if (tableContainerRef.current && tableContainerRef.current.contains(e.target)) {
        const colNode = e.target.closest('[data-col]');
        const rowNode = e.target.closest('[data-row]');
        const focusInfo = {
          col: colNode ? colNode.dataset.col : null,
          row: rowNode ? rowNode.dataset.row : null,

        };
        if (focusInfo.col === null) {
          // eslint-disable-next-line no-console
          console.warn('В событии фокус элемента не удалось найти ячейку табличной части для определения текущей колонки. '
            + 'Вероятно Вы забыли установить свойство "data-col={colName}" в компоненте ячейки', e.target);
        }
        if (focusInfo.row === null) {
          // eslint-disable-next-line no-console
          console.warn('В событии фокус элемента не удалось найти строку табличной части для определения текущей строки. '
            + 'Вероятно Вы забыли установить свойство "data-row={rowIndex}" в компоненте строки', e.target);
        }
        setActive({
          row: focusInfo.row ? Number.parseInt(focusInfo.row, 10) : null,
          col: focusInfo.col,
        });
      }
    },
    [],
  );

  const currActiveRow = useRef(null);

  useEffect(
    () => {
      currActiveRow.current = active.row;
    },
    [active.row],
  );
  const onKeyDown = useCallback(
    (e) => {
      if (tableContainerRef.current.contains(e.target)) {
        if (e.key === 'Enter') {
          e.preventDefault();
          focusNextElement(tableContainerRef.current);
        }
        if (e.key === 'Insert') {
          e.preventDefault();
          onAddRow();
        }
        if (e.key === 'F8') {
          e.preventDefault();
          onDeleteRow(currActiveRow.current);
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setActive((o) => ({ ...o, row: o.row ? o.row - 1 : rowsCount - 1 }));
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setActive((o) => ({ ...o, row: rowsCount - 1 ? o.row + 1 : 0 }));
        }
      }
    },
    [onAddRow, onDeleteRow, rowsCount],
  );

  useEffect(() => {
    const node = tableContainerRef.current;
    if (!node) throw new Error('Вы забыли установить tableContainerRef.');
    node.addEventListener('focusin', onFocusIn);
    node.addEventListener('click', onFocusIn);
    node.addEventListener('keydown', onKeyDown);
    return () => {
      node.removeEventListener('focusin', onFocusIn);
      node.removeEventListener('click', onFocusIn);
      node.removeEventListener('keydown', onKeyDown);
    };
  }, [onFocusIn, onKeyDown]);

  return {
    activeRow: active.row,
    activeCol: active.col,
    showFooter,
    pinHeader,
    pinAllowed,
    tableActions,
    tableContainerRef,
    highlights,
    tableData,
    tableReadOnlyFields,
    tableErrors,
    tableFields,
  };
};

export default useTablePart;
