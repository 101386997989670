import base from './base';
import { periodTypes } from '../common';
/**
 * @const
 * @type {object}
 */

const mon = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v, i) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `${mon[i]}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  // authorCh: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  refNumber: { label: 'Номер довідки', name: 'НомерСправки', visible: false },
  refReason: {
    label: 'Підстава для довідки',
    name: 'ОснованиеДляСправки',
    visible: false,
  },
  includeCSUIntoHead: {
    label: 'Распорядитель в шапке',
    name: 'флРаспорядительВШапке',
    visible: false,
  },
  received: { label: 'Отримано', name: 'Квитанция', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  onlyYear: {
    label: 'Тільки річні суми',
    name: 'ТолькоГодовая',
    visible: false,
  },
  includeBudgetAmplification: {
    label: 'в т.ч. Бюджет Розвитку',
    name: 'ВТЧБР',
    visible: false,
  },
  includeParent: {
    label: 'в т.ч. за розпорядником',
    name: 'флПоРодителю',
    visible: false,
  },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  tags: { label: 'Теги', name: 'АвтПримечание', visible: true },
  isCreditingAllowed: {
    label: 'За розпорядником вищого рівня',
    name: 'флПоРодителю',
    visible: false,
  },
  docRevenuePlanning: {
    label: 'Планування доходів',
    name: 'ДокПланДох',
    visible: false,
  },
  docRevenueSources: {
    label: 'Планування джерел',
    name: 'ДокПланИсточники',
    visible: false,
  },
  docTotalCost: {
    label: 'Загальна вартість',
    name: 'СуммаДокумента',
    visible: true,
    type: 'moneys',
  },
  numberRegistryFI: {
    label: 'Реестраційний номер',
    name: 'РегНомер',
    visible: false,
  },
  year: { label: 'Рiк', name: 'Год', visible: true },
  Loans: { label: 'Кредитування', name: 'флКредитование', visible: true },
  // Дополнительные свойства
  thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },

  isShowKdbKfb: {
    label: 'Облік заг.фонду у розрізі КДБ та КФБ',
    name: 'флВидимостьКдбКфб',
    visible: false,
  },
  isSignature: {
    label: 'Підписано',
    name: 'МаксПодпись',
    visible: true,
    type: 'bool',
  },

  // BalanceSpecFound: { label: 'Балансувати спец. фонд', name: 'БалансСФКДБКФБ' },
  DisableFilterCsuToListElemKFK: { label: 'ОтключитьОтборВыбораЭлементовКФК', name: 'ОтключитьОтборВыбораЭлементовКФК', visible: false },
  showTargetedProgram: { label: 'Фл показать целевые программы', name: 'флПоказатьЦелевыеПрограммы', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      elementKFB: { label: 'КФБ', name: 'ЭлементКФБ' },
      KDBCode: { label: 'Код КДБ ', name: 'КодКДБ' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
      targetedProgram: { label: 'Цільова програма', name: 'ЦелеваяПрограмма' },
    },
  },
  descendantsDocuments: {
    name: 'ДокРаспНижнегоУр',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Док' },
      isApproved: { label: 'Затверджений', name: 'ДокументУтвержден' },
      CSU: { label: 'Розподярник', name: 'Распорядитель' },
      sum: { label: 'Сума (підсумок)', name: 'СуммаДокумента' },
      notes: { label: 'Примітка', name: 'Примечание' },
      author: { label: 'Автор', name: 'Автор' },
    },
  },
  yearKDB: {
    name: 'ГодовойКДБ',
    label: 'Дані по КДБ для річної довідки',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KDBCode: { label: 'Код КДБ', name: 'КодКДБ' },
      KDBClassifier: { label: 'КДБ', name: 'КлассификаторКДБ' },
      elementKDB: { label: 'Елемент КДБ', name: 'ЭлементКДБ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
  yearKFB: {
    name: 'ГодовойКФБ',
    label: 'Дані по КФБ для річної довідки',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      KFBCode: { label: 'Код КФБ', name: 'КодКФБ' },
      KFBClassifier: { label: 'КФБ', name: 'КлассификаторКФБ' },
      elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
      Sum: { label: 'Разом', name: 'Сумма' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'План асигнувань',
  name: 'assignmentPlanning',
  backendName: 'ПланАссигнований',
  columns,
  frontend: 'doc/assignmentPlanning',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'docTotalCost',
      'fondObject',
      'isApproved',
      'author',
      // 'authorCh',
      'note',
      'tags',
      'isSignature',
      'budget',
      'CSU',
      'year',
      'FI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
