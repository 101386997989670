import PropTypes from 'prop-types';

export const FieldPropType = PropTypes.shape({
  label: PropTypes.string,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['field', 'integer', 'string', 'date', 'boolean', 'decimal', 'nested object', 'choice', 'datetime', 'email', 'url']),
  max_length: PropTypes.number,
  choice: PropTypes.shape({ }),
  resource: PropTypes.string, // backend url for FK fields
});

export const FieldErrorPropType = PropTypes.arrayOf(PropTypes.string);
