import React, {
  useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Map } from 'immutable';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  FiveColumnsGridContainer,
  ContainerForm, CommandPanelColor, DivWithPropsLabel, ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import { InputWithDropdown } from '../../../components/styledInputs';
import enums from '../../documents/assignmentPlanning/enumsForPrintModal';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import TPCommandPanel from '../../../components/tableParts/tpDPCommandPanel';

import DocsTP from './tablepart/tpDoc';
import SelectorField from '../../field/stdSelectorField';
import Tabs from '../../../components/tabs/Tabs';

import DpContainer from '../dpContainer';
import { MainContainer, ResultSegment } from '../styledComponentsDp';
import { emptyUid } from '../../../constants/meta/common';
import CommandPanelButton from '../../../components/button/commandPanelButton';

const planingKindsOptions = Object.values(enums.PlaningKind);

export const ContainerOneColumn = styled(ContainerForm)`
  grid-template-columns: 90%;
  margin-bottom: 10px;
`;

export const ContainerFourColumn = styled(ContainerForm)`
  grid-template-columns: 25% 25% 25% 25% ;
  margin-bottom: 10px;
`;

const StringField10 = Fields.GetTextInput(10);

const md = meta.dp.assigmentPrinting;

function FillButton() {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);
  return (
    <FiveColumnsGridContainer>
      <div>
        <StyledLabel>{md.columns.Year.label}</StyledLabel>
        <Fields.YearField
          value={headerForm.get('Year', 0)}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Year'], v))}
          disableCalculator
        />
      </div>
      <div>
        <StyledLabel>{md.columns.PlanKind.label}</StyledLabel>
        <InputWithDropdown
          value={headerForm.get('PlanKind')}
          options={planingKindsOptions}
          onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PlanKind'], v))}
        />
      </div>
      <div>
        <StyledLabel>{md.columns.elementFond.label}</StyledLabel>
        <SelectorField
          value={headerForm.get('elementFond', new Map())}
          modelType="cat"
          modelName="elementFond"
          onChange={(e, value) => {
            dispatch(actions.changeField(['headerForm', 'elementFond'], value));
          }}
        />
      </div>
      <div>
        <StyledLabel>{md.columns.KBP.label}</StyledLabel>
        <SelectorField
          value={headerForm.get('KBP', new Map())}
          modelType="cat"
          modelName="kbpClassifier"
          onChange={(e, value) => {
            dispatch(actions.changeField(['headerForm', 'KBP'], value));
          }}
        />
      </div>
      <CommandPanelColor
        onClick={() => {
          dispatch(actions.processServerReq('RESET'));
        }}
      >
        Заповнити
      </CommandPanelColor>
    </FiveColumnsGridContainer>
  );
}

const DocsTPRenderer = getTPContainer(DocsTP, getResizableCP(
  <TPCommandPanel
    tableName="data"
    extraContent={<FillButton />}
  />,
));

const ReportVariants = Object.values(enums.ReportVariantsNewNew);

const QuartalVariants = Object.values(enums.QuartalVariants);

const KPKVNameAsVariants = Object.values(enums.KPKVNameAsVariants);

const KVKNameAsVariants = Object.values(enums.KVKNameAsVariantsNew);

const KEKVVariants = Object.values(enums.KEKVVariants);

const KEKVDetailsVariants = Object.values(enums.KEKVDetailsVariants);

const CoordinationVariants = Object.values(enums.CoordinationVariants);

function AssigmentPrintingEditor({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { headerForm } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['Document', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      if (logikaId && logikaId !== emptyUid) {
        onClose();
        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, headerForm, navigate, logikaId, onClose],
  );

  const reportVariants = headerForm.get('reportVariants', new Map());

  const SettingsButton = (
    <div>
      <Tabs>
        <DivWithPropsLabel label="Основні налаштування">
          <div>
            <ContainerThreeColumn>
              <div>
                <StyledLabel>{md.columns.Document.label}</StyledLabel>
                <Fields.ItemField
                  value={headerForm.get('Document', new Map())}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
                <Fields.SelectorField
                  value={headerForm.get('SignVariant', new Map())}
                  modelType="cat"
                  modelName="signatureVariants"
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                  filter={[
                    { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
                  ]}
                />
              </div>
            </ContainerThreeColumn>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('USEGeneralCSUForm', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'USEGeneralCSUForm'], v))}
                label={md.columns.USEGeneralCSUForm.label}
              />
            </div>
            <FiveColumnsGridContainer>
              <div>
                <StyledLabel>{md.columns.ReportVariant.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('ReportVariant', '')}
                  onChange={(e, v) => {
                    dispatch(actions.changeField(['headerForm', 'ReportVariant'], v));
                    if (v === enums.ReportVariants.ConsolidatedPlan.value) {
                      dispatch(actions.changeField(['headerForm', 'YearMode'], false));
                    } else if (v === enums.ReportVariants.UsingPlan.value) {
                      dispatch(actions.changeField(['headerForm', 'KEKVVariant'], enums.KEKVVariants.full.value));
                      dispatch(actions.changeField(['headerForm', 'AllKEKV'], false));
                    }
                  }}
                  options={ReportVariants}
                />
              </div>
              {
                !!(
                  headerForm.get('ReportVariant', 0) === enums.ReportVariants.ConsolidatedPlan.value
                      || headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value
                ) && (
                  <div>
                    <Fields.CheckboxField
                      label={md.columns.ConsolidatedForm.label}
                      value={headerForm.get('ConsolidatedForm', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ConsolidatedForm'], v))}
                    />
                  </div>
                )
              }
              {
                headerForm.get('QuartalVisible') && (
                  <div>
                    <StyledLabel>{md.columns.Quartal.label}</StyledLabel>
                    <InputWithDropdown
                      value={headerForm.get('Quartal', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Quartal'], v))}
                      options={QuartalVariants}
                    />
                  </div>
                )
              }
              {headerForm.get('ItChanges') && (
                <div>
                  <Fields.CheckboxField
                    label={md.columns.YearMode.label}
                    value={headerForm.get('YearMode', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'YearMode'], v))}
                  />
                </div>
              )}
              <div>
                <Fields.CheckboxField
                  label={md.columns.InThousandUAH.label}
                  value={headerForm.get('InThousandUAH', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'InThousandUAH'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.PrintNo.label}
                  value={headerForm.get('PrintNo', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintNo'], v))}
                  disabled={headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.DontShowApp.label}
                  value={headerForm.get('DontShowApp', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'DontShowApp'], v))}
                />
              </div>
            </FiveColumnsGridContainer>
            <FiveColumnsGridContainer>
              <div>
                <StyledLabel>{md.columns.KEKVVariant.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('KEKVVariant', 0)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKVVariant'], v))}
                  options={KEKVVariants}
                  disabled={headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.KVKNameAs.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('KVKNameAs', 0)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KVKNameAs'], v))}
                  options={KVKNameAsVariants}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.ElementsKFK.label}
                  value={headerForm.get('ElementsKFK', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ElementsKFK'], v))}
                  disabled={headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.notPrintKEKVWith0.label}
                  value={headerForm.get('notPrintKEKVWith0', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notPrintKEKVWith0'], v))}
                  disabled={headerForm.get('YearMode', false) === false}
                />
              </div>
              <div />
              <div>
                <Fields.CheckboxField
                  label={md.columns.AllKEKV.label}
                  value={headerForm.get('AllKEKV', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'AllKEKV'], v))}
                  disabled={
                    headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value
                        || headerForm.get('KievForm', false)
                  }
                />
              </div>
              <div>
                <StyledLabel>{md.columns.KPKVNameAs.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('KPKVNameAs', '')}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KPKVNameAs'], v))}
                  options={KPKVNameAsVariants}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.ShowKPKVSvod.label}
                  value={headerForm.get('ShowKPKVSvod', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ShowKPKVSvod'], v))}
                  disabled={
                    headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value
                        || headerForm.get('KievForm', false)
                  }
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.KDBGroup.label}
                  value={headerForm.get('KDBGroup', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KDBGroup'], v))}
                  disabled={
                    headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value
                        || headerForm.get('KievForm', false)
                  }
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.PrintKDB.label}
                  value={headerForm.get('PrintKDB', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKDB'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.KEKVGroup.label}
                  value={headerForm.get('KEKVGroup', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKVGroup'], v))}
                  disabled={headerForm.get('KEKVVariant', 0) === enums.KEKVVariants.full}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.Symbol0.label}</StyledLabel>
                <StringField10
                  border
                  value={headerForm.get('Symbol0', '')}
                  onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'Symbol0'], value))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.KFBKDBGeneralTable.label}
                  value={headerForm.get('KFBKDBGeneralTable', false)}
                  onChange={(e, v) => dispatch(actions
                    .changeField(['headerForm', 'KFBKDBGeneralTable'], v))}
                  disabled={headerForm
                    .get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.KFBGroup.label}
                  value={headerForm.get('KFBGroup', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KFBGroup'], v))}
                  disabled={
                    headerForm.get('ReportVariant', 0) === enums.ReportVariants.UsingPlan.value
                        || headerForm.get('KievForm', false)
                  }
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.PrintKFB.label}
                  value={headerForm.get('PrintKFB', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'PrintKFB'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.noCop.label}
                  value={headerForm.get('noCop', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noCop'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.wrMounth.label}
                  value={headerForm.get('wrMounth', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'wrMounth'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.onPage.label}
                  value={headerForm.get('onPage', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onPage'], v))}
                />
              </div>
              <div>
                <Fields.CheckboxField
                  label={md.columns.noTAX.label}
                  value={headerForm.get('noTAX', false)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noTAX'], v))}
                />
              </div>
              <div>
                <StyledLabel>{md.columns.KEKVDetails.label}</StyledLabel>
                <InputWithDropdown
                  value={headerForm.get('KEKVDetails', 0)}
                  onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KEKVDetails'], v))}
                  options={KEKVDetailsVariants}
                  disabled={
                    headerForm.get('KEKVVariant', 0) === enums.KEKVVariants.full.value
                        || headerForm.get('KievForm', false)
                  }
                />
              </div>
              <div>
                {headerForm.get('KievFormVisible', true) ? (
                  <Fields.CheckboxField
                    label={md.columns.KievForm.label}
                    value={headerForm.get('KievForm', false)}
                    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KievForm'], v))}
                    disabled={headerForm.get('KievFormReadOnly', false)}
                  />
                ) : null}
              </div>
              {
                headerForm.get('CoordinationVisible', true) && (
                  <div>
                    <StyledLabel>{md.columns.Coordination.label}</StyledLabel>
                    <InputWithDropdown
                      value={headerForm.get('Coordination', 0)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'Coordination'], v))}
                      options={CoordinationVariants}
                    />
                  </div>
                )
              }
              <div>
                <StyledLabel>{md.columns.ForChangesBlank.label}</StyledLabel>
                <StringField10
                  border
                  value={headerForm.get('ForChangesBlank', '')}
                  onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'ForChangesBlank'], value))}
                />
              </div>
            </FiveColumnsGridContainer>
          </div>
          <FiveColumnsGridContainer>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('firstNameLastName', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'firstNameLastName'], v))}
                label={md.columns.firstNameLastName.label}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('notShowZeroRows', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'notShowZeroRows'], v))}
                label={md.columns.notShowZeroRows.label}
              />
            </div>
            {/* <div> */}
            {/*  <Fields.CheckboxField */}
            {/*    value={headerForm.get('order938', false)} */}
            {/*    onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'order938'], v))} */}
            {/*    label={md.columns.order938.label} */}
            {/*  /> */}
            {/* </div> */}
            <div>
              <Fields.CheckboxField
                value={headerForm.get('noStars', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noStars'], v))}
                label={md.columns.noStars.label}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('onlyOnePage', false)}
                label={md.columns.onlyOnePage.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyOnePage'], v))}
              />
            </div>
            <div>
              <Fields.CheckboxField
                value={headerForm.get('printKopekInHat', false)}
                label={md.columns.printKopekInHat.label}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'printKopekInHat'], v))}
              />
            </div>
          </FiveColumnsGridContainer>

        </DivWithPropsLabel>
        <DivWithPropsLabel label="Документи">
          <ContainerOneColumn>
            <DocsTPRenderer />
          </ContainerOneColumn>
        </DivWithPropsLabel>
      </Tabs>
    </div>
  );

  return (
    <MainContainer>
      <DpContainer
        Settings={SettingsButton}
        backendName={md.backendName}
        reportVariants={reportVariants}
      >
        <ResultSegment>
          {!headerForm.get('ItChanges', false) && (
            <div>
              <CommandPanelButton
                label="Передати в Logica"
                onClick={() => {
                  dispatch(actions.processServerReq('CreateDispatchDoc'));
                }}
              />
            </div>
          )}
          <ContainerFourColumn>
            <div>
              <StyledLabel>{md.columns.tdCSU.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('tdCSU', new Map())}
                modelType="cat"
                modelName="csu"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'tdCSU'], value));
                }}
                filter={[
                  { fieldName: 'Родитель', value: headerForm.get('FO', new Map()) },
                ]}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.tdKPKMB.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('tdKPKMB', new Map())}
                modelType="cat"
                modelName="kbp7Classifier"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'tdKPKMB'], value));
                }}
              />
            </div>
            <div>
              <StyledLabel>{md.columns.SignVariant.label}</StyledLabel>
              <Fields.SelectorField
                value={headerForm.get('SignVariant', new Map())}
                modelType="cat"
                modelName="signatureVariants"
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'SignVariant'], v))}
                filter={[
                  { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
                ]}
              />
            </div>
            <div>
              <Fields.CheckboxField
                label={md.columns.consolid.label}
                value={headerForm.get('consolid', false)}
                onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'consolid'], v))}
              />
            </div>
          </ContainerFourColumn>
          <TableEditor mountNode={resultMountNode} />
        </ResultSegment>
      </DpContainer>
    </MainContainer>
  );
}

AssigmentPrintingEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const enchance = BasicEditor(
  {
    type: 'dp',
    name: md.name,
  },
  {
    menu: () => null,
  },
);

export default enchance(AssigmentPrintingEditor);
