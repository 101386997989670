import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import {
  PrependAccount, PrependKBP7, PrependKEKV, PrependKVK,
} from '../../../../styles/klassifiers';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  authorityId,
  activeObjectsBuilding,
  transferType,
  isRefundByMonth,
  isRefund,
}) {
  const [currentTableErrors, setCurrentTableErrors] = useState(errors);

  const onSumChange = useCallback(
    (e, v) => {
      if (v > row.balance) {
        setCurrentTableErrors({ ...errors, ...{ amount: ['Сума фінансування перевищує залишок'] } });
      } else {
        const { amount, ...errorsWithOutAmount } = errors;
        setCurrentTableErrors(errorsWithOutAmount);
      }
      onChange(e, rowIndex, {
        amount: v,
      });
    },
    [errors, onChange, row.balance, rowIndex, setCurrentTableErrors],
  );

  useEffect(() => {
    if (row.amount > row.balance) {
      setCurrentTableErrors({ ...errors, ...{ amount: ['Сума фінансування перевищує залишок'] } });
    } else {
      const { amount, ...errorsWithOutAmount } = errors;
      setCurrentTableErrors(errorsWithOutAmount);
    }
  }, [row.balance, row.amount, errors]);

  const kbp7Props = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kbp7Filter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const kekvProps = tableHooks.useItemInputProps(
    'kekv_item',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kekvFilter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const disposerFilter = useMemo(() => ({ parent: authorityId }), [authorityId]);

  const disposerAccProps = tableHooks.useItemInputProps(
    'disposer_acc',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const disposerAccFilter = useMemo(
    () => ({ disposer: row.disposer ? row.disposer.id : null }),
    [row.disposer],
  );

  const buildObgectProps = tableHooks.useItemInputProps(
    'buildObgect',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const typeRepairProps = tableHooks.useItemInputProps(
    'typeRepair',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const planPeriodAmountProps = tableHooks.useNumberInputProps(
    'plan_period_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const factPeriodAmountProps = tableHooks.useNumberInputProps(
    'fact_period_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balanceProps = tableHooks.useNumberInputProps(
    'balance',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const registerAccRemain = tableHooks.useNumberInputProps(
    'register_acc_remain',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const treasuryAccRemain = tableHooks.useNumberInputProps(
    'treasury_acc_remain',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const finCommitmentAmountProps = tableHooks.useNumberInputProps(
    'fin_commitment_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const amountProps = tableHooks.useNumberInputProps(
    'amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const remarkTemplateProps = tableHooks.useItemInputProps(
    'remark_template',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const remarkMainProps = tableHooks.useStringInputProps(
    'remark_main',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const remarkNoteProps = tableHooks.useStringInputProps(
    'remark_note',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const purposeOfPaymentProps = tableHooks.useItemInputProps(
    'purpose_of_payment',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const returnMonthProps = tableHooks.useSelectorInputProps('return_month', row, fields, currentTableErrors, onChange, rowIndex, readOnly, readOnlyFields);
  const returnFundingProps = tableHooks.useCheckboxInputProps('return_funding', row, fields, currentTableErrors, onChange, rowIndex, readOnly, readOnlyFields);
  const finrequestProps = tableHooks.useItemInputProps('finrequest', row, fields, currentTableErrors, onChange, rowIndex, true, readOnlyFields);
  const refundClass = useMemo(() => (
    isRefund || (isRefundByMonth && row.return_funding) ? 'text-danger' : ''
  ), [isRefund, isRefundByMonth, row.return_funding]);

  return (
    <>
      {transferType === 2 && (
        <Row noGutters>
          <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
            <TableControls.NumberInput
              {...amountProps}
            />
          </TabelRowCell>
          <TabelRowCell
            column="remark_main"
            highlighted={highlights.includes('remark_main')}
          >
            <TableControls.TextInput
              {...remarkMainProps}
            />
          </TabelRowCell>
          <TabelRowCell
            column="remark_note"
            highlighted={highlights.includes('remark_note')}
          >
            <TableControls.TextInput
              {...remarkNoteProps}
            />
          </TabelRowCell>
        </Row>
      )}
      {transferType !== 2 && (
        <Col>
          {!disposerInHeader
          && (
            <Row noGutters>
              <Col>
                <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
                  <TableControls.ItemPicker
                    {...disposerProps}
                    filter={disposerFilter}
                    noHierarchy
                    prepend={(
                      <PrependKVK>
                        {row.kvk && row.kvk.repr}
                      </PrependKVK>
                      )}
                  />
                </TabelRowCell>
              </Col>
              <Col>
                <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
                  <TableControls.ItemPicker
                    {...disposerAccProps}
                    filter={disposerAccFilter}
                    prepend={(
                      <PrependAccount>
                        {row.disposer_acc && row.disposer_acc.code}
                      </PrependAccount>
                      )}
                  />
                </TabelRowCell>
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col
              className="d-flex"
            >
              <Row className="w-100" noGutters>
                <Col>
                  <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
                    <TableControls.ItemPicker
                      {...kbp7Props}
                      filter={kbp7Filter}
                      prepend={(
                        <PrependKBP7>
                          {row.kbp7 && row.kbp7.repr}
                        </PrependKBP7>
                      )}
                    />
                  </TabelRowCell>
                  <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
                    <TableControls.ItemPicker
                      {...kekvProps}
                      filter={kekvFilter}
                      prepend={(
                        <PrependKEKV>
                          {row.kekv && row.kekv.repr}
                        </PrependKEKV>
                      )}
                    />
                  </TabelRowCell>
                  {disposerInHeader
                  && (
                    <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
                      <TableControls.ItemPicker
                        {...disposerAccProps}
                        filter={disposerAccFilter}
                        prepend={(
                          <PrependAccount>
                            <InputGroup.Text className="w-100">{row.disposer_acc && row.disposer_acc.code}</InputGroup.Text>
                          </PrependAccount>
                      )}
                      />
                    </TabelRowCell>
                  )}
                </Col>
              </Row>
            </Col>
            {activeObjectsBuilding
            && (
              <Col xl={2} className="d-flex">
                <Row className="w-100" noGutters>
                  <Col>
                    <TabelRowCell column="buildObgect" highlighted={highlights.includes('buildObgect')}>
                      <TableControls.ItemPicker
                        {...buildObgectProps}
                      />
                    </TabelRowCell>
                    <TabelRowCell column="typeRepair" highlighted={highlights.includes('typeRepair')}>
                      <TableControls.StringInput
                        {...typeRepairProps}
                      />
                    </TabelRowCell>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xl={activeObjectsBuilding ? 6 : 8}>
              <Row className="w-100" noGutters>
                {transferType !== 1 && (
                <Col>
                  <TabelRowCell column="plan_period_amount" highlighted={highlights.includes('plan_period_amount')}>
                    <TableControls.NumberInput
                      {...planPeriodAmountProps}
                      disabled
                    />
                  </TabelRowCell>
                  <TabelRowCell column="fact_period_amount" highlighted={highlights.includes('fact_period_amount')}>
                    <TableControls.NumberInput
                      {...factPeriodAmountProps}
                      disabled
                    />
                  </TabelRowCell>
                </Col>
                )}
                <Col>
                  <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
                    <TableControls.NumberInput
                      {...balanceProps}
                      disabled
                    />
                  </TabelRowCell>
                  <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
                    <TableControls.NumberInput
                      {...finCommitmentAmountProps}
                      disabled
                    />
                  </TabelRowCell>
                </Col>
                <Col>
                  <TabelRowCell column="register_acc_remain" highlighted={highlights.includes('register_acc_remain')}>
                    <TableControls.NumberInput
                      {...registerAccRemain}
                      disabled
                    />
                  </TabelRowCell>
                  <TabelRowCell column="treasury_acc_remain" highlighted={highlights.includes('treasury_acc_remain')}>
                    <TableControls.NumberInput
                      {...treasuryAccRemain}
                      disabled
                    />
                  </TabelRowCell>
                </Col>
                <Col>
                  <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
                    <TableControls.NumberInput
                      {...amountProps}
                      onChange={(e, v) => onSumChange(e, v)}
                      className={refundClass}
                    />
                  </TabelRowCell>
                  <TabelRowCell column="finrequest" highlighted={highlights.includes('finrequest')}>
                    <TableControls.ItemPicker {...finrequestProps} />
                  </TabelRowCell>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {isRefundByMonth && (
            <Col xl={2}>
              <Row>
                <Col xl={1}>
                  <TabelRowCell
                    column="return_funding"
                    highlighted={highlights.includes('return_funding')}
                  >
                    <TableControls.CheckboxInput
                      {...returnFundingProps}
                    />
                  </TabelRowCell>
                </Col>
                <Col>
                  <TabelRowCell column="return_month" highlighted={highlights.includes('return_month')}>
                    <TableControls.SelectorInput
                      {...returnMonthProps}
                    />
                  </TabelRowCell>
                </Col>
              </Row>
            </Col>
            )}
            <Col>
              <TabelRowCell
                column="remark_note"
                highlighted={highlights.includes('remark_note')}
              >
                <TableControls.TextInput
                  {...remarkNoteProps}
                />
              </TabelRowCell>
            </Col>
            <Col xl={3}>
              <TabelRowCell column="remark_template" highlighted={highlights.includes('remark_template')}>
                <TableControls.ItemPicker
                  {...remarkTemplateProps}
                />
              </TabelRowCell>
            </Col>
            <Col xl={3}>
              <TabelRowCell column="purpose_of_payment" highlighted={highlights.includes('purpose_of_payment')}>
                <TableControls.ItemPicker
                  {...purposeOfPaymentProps}
                />
              </TabelRowCell>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

TRow.propTypes = {
  row: PropTypes.shape(dataPropTypes).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authorityId: PropTypes.number,
  activeObjectsBuilding: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  transferType: PropTypes.number,
  isRefundByMonth: PropTypes.bool.isRequired,
  isRefund: PropTypes.bool.isRequired,
};

TRow.defaultProps = {
  errors: {},
  active: false,
  activeCol: '',
  readOnly: false,
  authorityId: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  readOnlyFields: [],
  transferType: 0,
};

export default memo(TRow);
