import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function LiquidityRowSet3({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'payment_date',
        },
        {
          field: 'sum_income',
        },
        {
          field: 'sum_repayment',
        },
        {
          field: 'sum_service',
        },
      ],
    }),
    [],
  );
  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="liquiditytable2rowv2_set"
    />
  );
}

LiquidityRowSet3.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.shape({
      budget: foreignPropType,
    }),
  }).isRequired,
};

export default LiquidityRowSet3;
