import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import { selectionMode } from '../../constants/meta/common';
import Modal from '../../components/modal';
import { CommandPanelButton } from '../../components/button';
import Selector from './selector';
import meta from '../../constants/meta';
import { NegativeButton, PositiveButton } from '../../components/Form/styledForm';
import { MoveToFolderIcon as MoveIcon } from '../../assets/icons';

function MoveToFolderButton({
  modelType, modelName, onMove, filter, activeId,
}) {
  const [selectorOpened, setSelectorOpened] = useState(false);
  const [current, setCurrent] = useState(null);
  return (
    <>
      <Modal
        opened={selectorOpened}
        size="fullscreen"
        onClose={() => setSelectorOpened(false)}
        header="Перенести в іншу папку"
        closeIcon
      >
        <div>
          <PositiveButton
            onClick={(e) => {
              onMove(e, current.get('id', null));
              setSelectorOpened(false);
            }}
            disabled={!current}
          >
            Обрати
          </PositiveButton>
          <NegativeButton onClick={() => setSelectorOpened(false)}>
            Скасувати
          </NegativeButton>
        </div>
        <Selector
          modelName={modelName}
          modelType={modelType}
          choiceSettings={selectionMode.folders}
          onSelect={(e, v) => setCurrent(v)}
          onChoice={(e, v) => {
            setCurrent(v);
            onMove(e, current.get('id', null));
          }}
          filter={[...filter, { fieldName: 'ЭтоГруппа', value: true }]}
          CommandPanel={null}
        />
      </Modal>
      <CommandPanelButton
        text="Перенести"
        onClick={() => setSelectorOpened(true)}
        disabled={!activeId}
      >
        <MoveIcon />
      </CommandPanelButton>
    </>
  );
}

MoveToFolderButton.propTypes = {
  modelType: PropTypes.oneOf(Object.keys(meta)).isRequired,
  modelName: PropTypes.oneOf([
    ...new Set(Object.keys(meta).reduce(
      (r, typeName) => [...r, ...Object.keys(meta[typeName])],
      [],
    )),
  ]).isRequired,
  onMove: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.shape({})),
  activeId: PropTypes.bool,
};

MoveToFolderButton.defaultProps = {
  filter: [],
  activeId: false,
};

const mapState = (store, ownProps) => {
  const STORE_PATH = `${ownProps.modelType}/${ownProps.modelName}/lister`;
  return {
    activeId: (store.getIn([STORE_PATH, 'visibleItems'], new OrderedMap())).filter((i) => i.get('ACTIVE', false)).keySeq().first(false),
  };
};

export default connect(mapState)(MoveToFolderButton);
