/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Accordion, Button,
  Card, Col, ListGroup, Row, Tab, Tabs,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/investmentProposalObjectsChanges';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  BuildingState, GeneralTP, PVR, Power,
} from './tabs';

function InvestmentProposalChangesObjects({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось
  const FI = data[md.columns.FI.name];
  const objectsBuildingOptions = useMemo(
    () => [{ fieldName: 'Заказчик', value: FI }],
    [FI],
  );
  const readOnly = !permissions.canChange;

  const beginningDate = new Date(data[md.columns.beginningDate.name]);
  const month = (date) => {
    const m = date.getMonth() + 1;
    if (m.toString().length === 1) {
      return `0${m}`;
    }
    return m;
  };
  const day = (date) => {
    const d = date.getDate();
    if (d.toString().length === 1) {
      return `0${d}`;
    }
    return d;
  };

  const onSumChange = useCallback(
    () => {
      actions.onChange({
        [md.columns.nextYearSum.name]:
            data[md.columns.nextYearCriticalSum.name] || 0
            + data[md.columns.nextYearAddSum.name] || 0,
        [md.columns.nextYearCriticalSumWithChanges.name]:
            data[md.columns.nextYearCriticalSumPrev.name] || 0
            + data[md.columns.nextYearCriticalSum.name] || 0,
        [md.columns.nextYearAddSumWithChanges.name]:
            data[md.columns.nextYearAddSumPrev.name] || 0
            + data[md.columns.nextYearAddSum.name] || 0,
      });
      actions.onChange({
        [md.columns.nextYearSumWithChanges.name]:
                data[md.columns.nextYearCriticalSumWithChanges.name] || 0
                + data[md.columns.nextYearAddSumWithChanges.name] || 0,
      });
    },
    [actions, data],
  );

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title="Основне" eventKey="general">
        <EditorCollapse>
          {sessionOptions.get('is_admin', false) && (
            <BudgetFOUserEditorRow
              onChange={actions.onChange}
              data={data}
              readOnly={readOnly}
            />
          )}
          <Row>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.number.label}
                value={data[md.columns.number.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.number.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.DateInput
                label={md.columns.date.label}
                value={data[md.columns.date.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.date.name]: value,
                  [md.columns.year.name]: new Date(value).getFullYear(),
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.YearInput
                label={md.columns.year.label}
                value={data[md.columns.year.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.year.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            {data[md.columns.isOdessaMode.name] && (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.projectVariant.label}
                  value={data[md.columns.projectVariant.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.year.name]: value,
                  })}
                  modelType="cat"
                  modelName="variantBudgetDraft"
                  readOnly={readOnly}
                />
              </Col>
            )}
            <Col>
              <EditorControls.CheckboxInput
                controlId={`isApproved-${data.id}`}
                label={md.columns.isApproved.label}
                value={data[md.columns.isApproved.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.isApproved.name]: value,
                })}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`flClosedDoc-${data.id}`}
                label={md.columns.flClosedDoc.label}
                value={data[md.columns.flClosedDoc.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.flClosedDoc.name]: value,
                })}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.objectBuilding.label}
                value={data[md.columns.objectBuilding.name]}
                onChange={(e, value) => {
                  actions.onChange({
                    [md.columns.objectBuilding.name]: value,
                  });
                  actions.onSR('Object_ON_CHANGE');
                }}
                modelType="cat"
                modelName="objectsBuilding"
                values={objectsBuildingOptions}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.finSource.label}
                value={data[md.columns.finSource.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.finSource.name]: value,
                })}
                modelType="cat"
                modelName="objBuildingSourceFin"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.beginningDate.label}
                value={data[md.columns.beginningDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.beginningDate.name]: value,
                })}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.DateInput
                label={md.columns.endingDate.label}
                value={data[md.columns.endingDate.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.endingDate.name]: value,
                })}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.sumEstimatesPrev.label}
                precision={2}
                value={data[md.columns.sumEstimatesPrev.name]}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.sumEstimates.label}
                precision={2}
                value={data[md.columns.sumEstimates.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.sumEstimates.name]: value,
                  [md.columns.sumEstimatesWithChanges.name]:
                  data[md.columns.sumEstimatesPrev.name] || 0 + value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.sumEstimatesWithChanges.label}
                precision={2}
                value={data[md.columns.sumEstimatesWithChanges.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.sumEstimatesWithChanges.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </EditorCollapse>
        <Tabs defaultActiveKey="sums" className="m-0 mt-2 border-bottom">
          <Tab title="Очікуване виконання на початок наступного року" eventKey="sums">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> За інвестиційною пропозицією </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.totalSumPrev.label}
                          precision={2}
                          value={data[md.columns.totalSumPrev.name]}
                          readOnly
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.yearSumPrev.label}
                          precision={2}
                          value={data[md.columns.yearSumPrev.name]}
                          readOnly
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> Зміна (+/-) </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.totalSum.label}
                          precision={2}
                          value={data[md.columns.totalSum.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.totalSum.name]: value,
                            [md.columns.totalSumWithChanges.name]:
                            data[md.columns.totalSumPrev.name] || 0 + value,
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.yearSum.label}
                          precision={2}
                          value={data[md.columns.yearSum.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.yearSum.name]: value,
                            [md.columns.yearSumWithChanges.name]:
                            value + data[md.columns.yearSumPrev.name] || 0,
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> З урахуванням змін </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.totalSumWithChanges.label}
                          precision={2}
                          value={data[md.columns.totalSumWithChanges.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.totalSumWithChanges.name]: value,
                            [md.columns.totalSum.name]:
                          value - data[md.columns.totalSumPrev.name] || 0,
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.yearSumWithChanges.label}
                          precision={2}
                          value={data[md.columns.yearSumWithChanges.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.yearSumWithChanges.name]: value,
                            [md.columns.yearSum.name]:
                          value - data[md.columns.yearSumPrev.name] || 0,
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab title="Поточний рік" eventKey="nextYear">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> За інвестиційною пропозицією </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearSumPrev.label}
                          precision={2}
                          value={data[md.columns.nextYearSumPrev.name]}
                          readOnly
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearCriticalSumPrev.label}
                          precision={2}
                          value={data[md.columns.nextYearCriticalSumPrev.name]}
                          readOnly
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearAddSumPrev.label}
                          precision={2}
                          value={data[md.columns.nextYearAddSumPrev.name]}
                          readOnly
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> Зміна (+/-) </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearSum.label}
                          precision={2}
                          value={data[md.columns.nextYearSum.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearSum.name]: value,
                          })}
                          readOnly
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearCriticalSum.label}
                          precision={2}
                          value={data[md.columns.nextYearCriticalSum.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearCriticalSum.name]: value,
                            [md.columns.nextYearCriticalSumWithChanges.name]:
                                value + (data[md.columns.nextYearCriticalSumPrev.name] || 0),
                            [md.columns.nextYearSum.name]:
                                value + (data[md.columns.nextYearAddSum.name] || 0),
                            [md.columns.nextYearSumWithChanges.name]:
                                value + (data[md.columns.nextYearSumPrev.name] || 0)
                                + (data[md.columns.nextYearAddSum.name] || 0),
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearAddSum.label}
                          precision={2}
                          value={data[md.columns.nextYearAddSum.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearAddSum.name]: value,
                            [md.columns.nextYearAddSumWithChanges.name]:
                            value + (data[md.columns.nextYearAddSumPrev.name] || 0),
                            [md.columns.nextYearSum.name]:
                            value + (data[md.columns.nextYearCriticalSum.name] || 0),
                            [md.columns.nextYearSumWithChanges.name]:
                            value + (data[md.columns.nextYearSumPrev.name] || 0)
                            + (data[md.columns.nextYearCriticalSum.name] || 0),
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> З урахуванням змін </Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearSumWithChanges.label}
                          precision={2}
                          value={data[md.columns.nextYearSumWithChanges.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearSumWithChanges.name]: value,
                          })}
                          readOnly
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearCriticalSumWithChanges.label}
                          precision={2}
                          value={data[md.columns.nextYearCriticalSumWithChanges.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearCriticalSumWithChanges.name]: value,
                            [md.columns.nextYearCriticalSum.name]:
                          value - (data[md.columns.nextYearCriticalSumPrev.name] || 0),
                            [md.columns.nextYearSum.name]:
                            value + (data[md.columns.nextYearAddSum.name] || 0)
                            - (data[md.columns.nextYearCriticalSumPrev.name] || 0),
                            [md.columns.nextYearSumWithChanges.name]:
                            value + (data[md.columns.nextYearAddSumWithChanges.name] || 0),
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <EditorControls.NumberInput
                          label={md.columns.nextYearAddSumWithChanges.label}
                          precision={2}
                          value={data[md.columns.nextYearAddSumWithChanges.name]}
                          onChange={(e, value) => actions.onChange({
                            [md.columns.nextYearAddSumWithChanges.name]: value,
                            [md.columns.nextYearAddSum.name]:
                          value - (data[md.columns.nextYearAddSumPrev.name] || 0),
                            [md.columns.nextYearSum.name]:
                            value + (data[md.columns.nextYearCriticalSum.name] || 0)
                            - (data[md.columns.nextYearCriticalSumPrev.name] || 0),
                            [md.columns.nextYearSumWithChanges.name]:
                            value + (data[md.columns.nextYearCriticalSumWithChanges.name] || 0),
                          })}
                          readOnly={readOnly}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab title="Прогноз" eventKey="prediction">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title> За інвестиційною пропозицією </Card.Title>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum1Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum1Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum1Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum2Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum2Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum2Prev.name]}
                              readOnly
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Зміна (+/-)</Card.Title>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum1.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionSum1.name]: value,
                              })}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum1.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionCriticalSum1.name]: value,
                                [md.columns.predictionCriticalSum1WithChanges.name]:
                                value + (data[md.columns.predictionCriticalSum1Prev.name] || 0),
                                [md.columns.predictionSum1.name]:
                                  value + (data[md.columns.predictionAddSum1.name] || 0),
                                [md.columns.predictionSum1WithChanges.name]:
                                  value + (data[md.columns.predictionSum1Prev.name] || 0)
                                  + (data[md.columns.predictionAddSum1.name] || 0),

                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum1.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionAddSum1.name]: value,
                                [md.columns.predictionAddSum1WithChanges.name]:
                                value + (data[md.columns.predictionAddSum1Prev.name] || 0),
                                [md.columns.predictionSum1.name]:
                                  value + (data[md.columns.predictionCriticalSum1.name] || 0),
                                [md.columns.predictionSum1WithChanges.name]:
                                  value + (data[md.columns.predictionSum1Prev.name] || 0)
                                  + (data[md.columns.predictionCriticalSum1.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum2.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionSum2.name]: value,
                                [md.columns.predictionSum2WithChanges.name]:
                                (data[md.columns.predictionSum2Prev.name] || 0) + value,
                              })}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum2.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionCriticalSum2.name]: value,
                                [md.columns.predictionCriticalSum2WithChanges.name]:
                                (data[md.columns.predictionCriticalSum2Prev.name] || 0) + value,
                                [md.columns.predictionSum2.name]:
                                  value + (data[md.columns.predictionAddSum2.name] || 0),
                                [md.columns.predictionSum2WithChanges.name]:
                                  value + (data[md.columns.predictionSum2Prev.name] || 0)
                                  + (data[md.columns.predictionAddSum2.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum2.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionAddSum2.name]: value,
                                [md.columns.predictionAddSum2WithChanges.name]:
                                (data[md.columns.predictionAddSum2Prev.name] || 0) + value,
                                [md.columns.predictionSum2.name]:
                                  value + (data[md.columns.predictionCriticalSum2.name] || 0),
                                [md.columns.predictionSum2WithChanges.name]:
                                  value + (data[md.columns.predictionSum2Prev.name] || 0)
                                  + (data[md.columns.predictionCriticalSum2.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>З урахуванням змін</Card.Title>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum1WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionSum1WithChanges.name]: value,
                                [md.columns.predictionSum1.name]:
                                  value - (data[md.columns.predictionSum1Prev.name] || 0),
                              })}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum1WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionCriticalSum1WithChanges.name]: value,
                                [md.columns.predictionCriticalSum1.name]:
                                  value - (data[md.columns.predictionCriticalSum1Prev.name] || 0),
                                [md.columns.predictionSum1.name]:
                                  value - (data[md.columns.predictionCriticalSum1Prev.name] || 0)
                                  + (data[md.columns.predictionCriticalSum1.name] || 0),
                                [md.columns.predictionSum1WithChanges.name]:
                                  value
                                  + (data[md.columns.predictionAddSum1WithChanges.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 1} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum1WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionAddSum1WithChanges.name]: value,
                                [md.columns.predictionAddSum1.name]:
                                  value - (data[md.columns.predictionAddSum1Prev.name] || 0),
                                [md.columns.predictionSum1.name]:
                                  value - (data[md.columns.predictionAddSum1Prev.name] || 0)
                                  + (data[md.columns.predictionCriticalSum1.name] || 0),
                                [md.columns.predictionSum1WithChanges.name]:
                                  value
                                  + (data[md.columns.predictionCriticalSum1WithChanges.name] || 0),

                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р.`}
                              precision={2}
                              value={data[md.columns.predictionSum2WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionSum2WithChanges.name]: value,
                                [md.columns.predictionSum2.name]:
                                value - (data[md.columns.predictionSum2Prev.name] || 0),
                              })}
                              readOnly
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (граничний потреба)`}
                              precision={2}
                              value={data[md.columns.predictionCriticalSum2WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionCriticalSum2WithChanges.name]: value,
                                [md.columns.predictionCriticalSum2.name]:
                                value - (data[md.columns.predictionCriticalSum2Prev.name] || 0),
                                [md.columns.predictionSum2.name]:
                                  value - (data[md.columns.predictionCriticalSum2Prev.name] || 0)
                                  + (data[md.columns.predictionAddSum2.name] || 0),
                                [md.columns.predictionSum2WithChanges.name]:
                                  value
                                  + (data[md.columns.predictionAddSum2WithChanges.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <EditorControls.NumberInput
                              label={`на ${data[md.columns.year.name] + 2} р. (додаткова потреба)`}
                              precision={2}
                              value={data[md.columns.predictionAddSum2WithChanges.name]}
                              onChange={(e, value) => actions.onChange({
                                [md.columns.predictionAddSum2WithChanges.name]: value,
                                [md.columns.predictionAddSum2.name]:
                                value - (data[md.columns.predictionAddSum2Prev.name] || 0),
                                [md.columns.predictionSum2.name]:
                                  value - (data[md.columns.predictionAddSum2Prev.name] || 0)
                                  + (data[md.columns.predictionCriticalSum2.name] || 0),
                                [md.columns.predictionSum2WithChanges.name]:
                                  value
                                  + (data[md.columns.predictionCriticalSum2WithChanges.name] || 0),
                              })}
                              readOnly={readOnly}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.contractor.label}
              value={data[md.columns.contractor.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.contractor.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.deadlines.label}
              value={data[md.columns.deadlines.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.deadlines.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput
              label={md.columns.designer.label}
              value={data[md.columns.designer.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.designer.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.TextInput
              label={md.columns.reason.label}
              value={data[md.columns.reason.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.reason.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Інфо
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <GeneralTP data={data} actions={actions} readOnly={readOnly} />
          </Accordion.Collapse>
        </Accordion>
      </Tab>
      <Tab title="ПВР" eventKey="pvr">
        <PVR data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Потужності" eventKey="power">
        <Power data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Рівень будівельної готовності" eventKey="buildingState">
        <BuildingState data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

InvestmentProposalChangesObjects.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default InvestmentProposalChangesObjects;
