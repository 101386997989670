import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import {
  PrependAccount, PrependKBP7, PrependKEKV, PrependKVK,
} from '../../../../styles/klassifiers';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  disposerAccHeaderFilter,
  authorityId,
  activeObjectsBuilding,
  ABVisible,
  isComposeSettingsChecked,
  isRefundByMonth,
  isRefund,
  disposerAccDisabled,
  fondId,
}) {
  const [currentTableErrors, setCurrentTableErrors] = useState(errors);

  const onSumChange = useCallback(
    (e, v) => {
      if (v > row.balance) {
        setCurrentTableErrors({ ...errors, ...{ amount: ['Сума фінансування перевищує залишок'] } });
      } else {
        const { amount, ...errorsWithOutAmount } = errors;
        setCurrentTableErrors(errorsWithOutAmount);
      }
      onChange(e, rowIndex, {
        amount: v,
      });
    },
    [errors, onChange, row.balance, rowIndex, setCurrentTableErrors],
  );
  const rowRefund = isRefund || (isRefundByMonth && row.return_funding);

  useEffect(() => {
    if (row.amount > row.balance && !rowRefund) {
      setCurrentTableErrors({ ...errors, ...{ amount: ['Сума фінансування перевищує залишок'] } });
    } else {
      const { amount, ...errorsWithOutAmount } = errors;
      setCurrentTableErrors(errorsWithOutAmount);
    }
  }, [row.balance, row.amount, errors, rowRefund]);

  const kbp7Props = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kbp7Filter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const kekvProps = tableHooks.useItemInputProps(
    'kekv_item',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kekvFilter = useMemo(() => ({ owner: authorityId }), [authorityId]);

  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const disposerFilter = useMemo(() => ({ parent: authorityId }), [authorityId]);

  const disposerAccProps = tableHooks.useItemInputProps(
    'disposer_acc',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const disposerAccFilter = useMemo(
    () => ({
      disposer: row.disposer ? row.disposer.id : null,
      fond: fondId,
    }),
    [row.disposer, fondId],
  );

  const buildObgectProps = tableHooks.useItemInputProps(
    'buildObgect',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const typeRepairProps = tableHooks.useItemInputProps(
    'typeRepair',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const planYearAmountProps = tableHooks.useNumberInputProps(
    'plan_year_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planPeriodAmountProps = tableHooks.useNumberInputProps(
    'plan_period_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planMouthAmountProps = tableHooks.useNumberInputProps(
    'plan_month_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const factPeriodAmountProps = tableHooks.useNumberInputProps(
    'fact_period_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balanceProps = tableHooks.useNumberInputProps(
    'balance',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const isReturnProps = tableHooks.useNumberInputProps(
    'isReturn',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balanceRegAccountsProps = tableHooks.useNumberInputProps(
    'balanceRegAccounts',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balancePersAccountsProps = tableHooks.useNumberInputProps(
    'balancePersAccounts',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const finCommitmentAmountProps = tableHooks.useNumberInputProps(
    'fin_commitment_amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const amountProps = tableHooks.useNumberInputProps(
    'amount',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const remarkTemplateProps = tableHooks.useItemInputProps(
    'remark_template',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const remarkNoteProps = tableHooks.useStringInputProps(
    'remark_note',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const purposeOfPaymentProps = tableHooks.useItemInputProps(
    'purpose_of_payment',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const fullremarkProps = tableHooks.useItemInputProps(
    'fullremark',
    row,
    fields,
    currentTableErrors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const returnMonthProps = tableHooks.useSelectorInputProps('return_month', row, fields, currentTableErrors, onChange, rowIndex, readOnly, readOnlyFields);
  const returnFundingProps = tableHooks.useCheckboxInputProps('return_funding', row, fields, currentTableErrors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col>
      {!disposerInHeader
          && (
            <Row>
              <Col
                xl={4}
                sm={12}
                className="px-0"
              >
                <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
                  <TableControls.ItemPicker
                    {...disposerProps}
                    filter={disposerFilter}
                    noHierarchy
                    prepend={(
                      <PrependKVK>
                        {row.kvk && row.kvk.repr}
                      </PrependKVK>
                      )}
                  />
                </TabelRowCell>
              </Col>
              <Col
                xl={8}
                sm={12}
                className="px-0"
              >
                <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
                  <TableControls.ItemPicker
                    {...disposerAccProps}
                    filter={disposerAccFilter}
                    disabled={!row.disposer}
                    prepend={(
                      <PrependAccount>
                        {row.disposer_acc && row.disposer_acc.code}
                      </PrependAccount>
                      )}
                  />
                </TabelRowCell>
              </Col>
            </Row>
          )}
      <Row>
        <Col
          xl={4}
          sm={12}
          className="d-flex px-0"
        >
          <Row className="w-100">
            <Col className="px-0">
              <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
                <TableControls.ItemPicker
                  {...kbp7Props}
                  filter={kbp7Filter}
                  prepend={(
                    <PrependKBP7>
                      {row.kbp7 && row.kbp7.repr}
                    </PrependKBP7>
                      )}
                />
              </TabelRowCell>
              <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
                <TableControls.ItemPicker
                  {...kekvProps}
                  filter={kekvFilter}
                  prepend={(
                    <PrependKEKV>
                      {row.kekv && row.kekv.repr}
                    </PrependKEKV>
                      )}
                />
              </TabelRowCell>
              {disposerInHeader
                  && (
                  <Col className="px-0">
                    <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
                      <TableControls.ItemPicker
                        {...disposerAccProps}
                        filter={disposerAccHeaderFilter}
                        disabled={disposerAccDisabled}
                        prepend={(
                          <PrependAccount>
                            {row.disposer_acc && row.disposer_acc.code}
                          </PrependAccount>
                      )}
                      />
                    </TabelRowCell>
                  </Col>
                  )}
            </Col>
          </Row>
        </Col>
        {activeObjectsBuilding
            && (
              <Col xl={2} sm={12} className="d-flex px-0">
                <Row className="w-100">
                  <Col className="px-0">
                    <TabelRowCell column="buildObgect" highlighted={highlights.includes('buildObgect')}>
                      <TableControls.ItemPicker
                        {...buildObgectProps}
                      />
                    </TabelRowCell>
                    <TabelRowCell column="typeRepair" highlighted={highlights.includes('typeRepair')}>
                      <TableControls.StringInput
                        {...typeRepairProps}
                      />
                    </TabelRowCell>
                  </Col>
                </Row>
              </Col>
            )}
        <Col xl={activeObjectsBuilding ? 6 : 8} sm={12} className="px-0">
          <Row className="w-100">
            <Col
              sm={12}
              lg={3}
              className="px-0"
            >
              <TabelRowCell column="plan_year_amount" highlighted={highlights.includes('plan_year_amount')}>
                <TableControls.NumberInput
                  {...planYearAmountProps}
                  disabled
                />
              </TabelRowCell>
              <TabelRowCell column="plan_period_amount" highlighted={highlights.includes('plan_period_amount')}>
                <TableControls.NumberInput
                  {...planPeriodAmountProps}
                  disabled
                />
              </TabelRowCell>
              <TabelRowCell column="plan_month_amount" highlighted={highlights.includes('plan_month_amount')}>
                <TableControls.NumberInput
                  {...planMouthAmountProps}
                  disabled
                />
              </TabelRowCell>
            </Col>
            <Col
              sm={12}
              lg={3}
              className="px-0"
            >
              <TabelRowCell column="fact_period_amount" highlighted={highlights.includes('fact_period_amount')}>
                <TableControls.NumberInput
                  {...factPeriodAmountProps}
                  disabled
                />
              </TabelRowCell>
              <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
                <TableControls.NumberInput
                  {...balanceProps}
                  disabled
                />
              </TabelRowCell>
              <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
                <TableControls.NumberInput
                  {...finCommitmentAmountProps}
                  disabled
                />
              </TabelRowCell>
            </Col>
            {(ABVisible || isComposeSettingsChecked)
                && (
                  <Col
                    sm={12}
                    lg={3}
                    className="px-0"
                  >
                    {ABVisible && (
                      <>
                        <TabelRowCell
                          column="balanceRegAccounts"
                          highlighted={highlights.includes('balanceRegAccounts')}
                        >
                          <TableControls.NumberInput
                            {...balanceRegAccountsProps}
                          />
                        </TabelRowCell>
                        <TabelRowCell
                          column="balancePersAccounts"
                          highlighted={highlights.includes('balancePersAccounts')}
                        >
                          <TableControls.NumberInput
                            {...balancePersAccountsProps}
                          />
                        </TabelRowCell>
                      </>
                    )}
                    {(ABVisible || isComposeSettingsChecked) && (
                      <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
                        <TableControls.NumberInput
                          {...amountProps}
                          onChange={(e, v) => onSumChange(e, v)}
                          className={classNames({ 'text-danger': rowRefund })}
                        />
                      </TabelRowCell>
                    )}
                  </Col>
                )}
            <Col
              sm={12}
              lg={6}
              className="px-0"
            >
              {!isComposeSettingsChecked && !ABVisible && (
              <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
                <TableControls.NumberInput
                  {...amountProps}
                  onChange={(e, v) => onSumChange(e, v)}
                  className={classNames({ 'text-danger': rowRefund })}
                />
              </TabelRowCell>
              )}
              <TabelRowCell column="purpose_of_payment" highlighted={highlights.includes('purpose_of_payment')}>
                <TableControls.ItemPicker
                  {...purposeOfPaymentProps}
                />
              </TabelRowCell>
              <TabelRowCell column="remark_template" highlighted={highlights.includes('remark_template')}>
                <TableControls.ItemPicker
                  {...remarkTemplateProps}
                />
              </TabelRowCell>
              {isComposeSettingsChecked && (
              <TabelRowCell column="isReturn" highlighted={highlights.includes('isReturn')}>
                <TableControls.SelectorInput
                  {...isReturnProps}
                />
              </TabelRowCell>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {isRefundByMonth && (
        <Col xl={2} className="px-0 d-flex">
          <TabelRowCell column="return_funding" highlighted={highlights.includes('return_funding')}>
            <TableControls.CheckboxInput
              {...returnFundingProps}
            />
          </TabelRowCell>
          <TabelRowCell column="return_month" highlighted={highlights.includes('return_month')} className="flex-fill">
            <TableControls.SelectorInput
              {...returnMonthProps}
            />
          </TabelRowCell>
        </Col>
        )}
        <Col sm={12} xl={isRefundByMonth ? 5 : 6} className="px-0">
          <TabelRowCell
            column="remark_note"
            highlighted={highlights.includes('remark_note')}
          >
            <TableControls.TextInput
              {...remarkNoteProps}
            />
          </TabelRowCell>
        </Col>
        <Col sm={12} xl={isRefundByMonth ? 5 : 6} className="px-0">
          <TabelRowCell column="fullremark" highlighted={highlights.includes('fullremark')}>
            <TableControls.TextInput
              {...fullremarkProps}
            />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  row: PropTypes.shape(dataPropTypes).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authorityId: PropTypes.number,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  isComposeSettingsChecked: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  isRefundByMonth: PropTypes.bool.isRequired,
  isRefund: PropTypes.bool.isRequired,
  disposerAccHeaderFilter: PropTypes.shape({
    disposer: PropTypes.number,
    fond: PropTypes.number,
  }).isRequired,
  disposerAccDisabled: PropTypes.bool,
  fondId: PropTypes.number,
};

TRow.defaultProps = {
  errors: {},
  active: false,
  activeCol: '',
  readOnly: false,
  authorityId: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  isComposeSettingsChecked: false,
  readOnlyFields: [],
  disposerAccDisabled: false,
  fondId: null,
};

export default memo(TRow);
