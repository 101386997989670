import { baseReportColumns, baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  ReportKind: { label: 'Вид звіту', name: 'ВидОтчета' },
  PlanKind: { label: 'Вид плану', name: 'ВидПлана' },
  CostKind: { label: 'Вид коштів', name: 'ВидыСредствКЕКВ' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  yearCost: { label: 'Рік складання', name: 'ГодЗатрат' },
  kfk: { label: 'КФК', name: 'КФК' },
  csuUp: { label: 'Розп. нищ. рівня', name: 'Распорядитель' },
  quart: { label: 'З початку року по', name: 'сзКвартал' },
  emptyNumber: { label: 'Пусті числові значення показувати як:', name: 'СимволПустогоЧисла' },
  approvedPlan: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  allKEKV: { label: 'За всіма КЕКВ', name: 'флПоВсемКЕКВ' },
  redForm: { label: 'Зведена форма', name: 'флСводный' },
  head: { label: 'Шапка', name: 'фРаспИнфо' },
  headApproved: { label: 'Шапка затверджено', name: 'ШапкаУтверждено' },
  elKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
  onlyKEKV: { label: 'Тільки КЕКВ для зведеного кошторису', name: 'ТолькоКЕКВСметы' },
  redCsu: { label: 'Зведено за установою', name: 'СводныйПоФУ' },
  showCSUas: { label: 'Показувати розпорядника як', name: 'ПоказыватьРаспКак' },
  redu: { label: '"ЗВЕДЕНИЙ" у заголовку', name: 'Звед' },
  kbp: { label: 'КБП 7 зн', name: 'КБП' },
  pcm: { label: 'Фл ПЦМ', name: 'флПЦМ' },
  kbp4: { label: 'КБП 4 зн', name: 'КБП4' },
  agreed: { label: 'Погоджено', name: 'Согласовано' },
  otherName: { label: 'Використовувати додаткову назву для КЕКВ', name: 'ИспользоватьДругонНаименование' },
  noCop: { label: 'Без десяткових знаків (копійок)', name: 'БезКопеек' },
  flName: { label: 'Прізвище та ім\'я', name: 'флФИ' },
  Document: { label: 'Документ', name: 'ДокументСсылка' },
};

/**
 * @const
 */
const definition = {
  name: 'estimatePrinting',
  label: 'Друк кошторису',
  backendName: 'ПечатьРегСмета',
  frontend: '',
  columns,
  resultColumns: baseResultColumns,
};

export default definition;
