import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Tabs, Tab, Container,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/docTransferToLogica';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function FinancingTabs({
  data, actions, permissions, setChanged,
}) {
  const readOnly = !permissions.canChange;
  const [htmlReport, setHtmlReport] = useState(null);
  const [htmlReportOriginal, setHtmlReportOriginal] = useState(null);
  useEffect(() => {
    if (!htmlReport && data.id) {
      actions.onSR('PRINT', {}, false).then(
        (d) => {setHtmlReport(d[md.columns.htmlReport.name]);
          setChanged(false);},
      );
    }
  }, [actions, data.id, htmlReport, setChanged]);
  useEffect(() => {
    if (!htmlReportOriginal && data.id) {
      actions.onSR('PRINT', {}, false).then(
        (d) => {setHtmlReportOriginal(d[md.columns.htmlReportOriginal.name]);
             setChanged(false);},
      );
    }
  }, [actions, data.id, htmlReportOriginal, setChanged]);

  return (
    <Tabs
      defaultActiveKey="presentedDocsOriginal"
      className="m-0 mt-2 border-bottom"
      id="controlled-tab"
    >
      <Tab
        title="Друкована форма (ЄІСУБ)"
        eventKey="presentedDocsOriginal"
      >
        <div dangerouslySetInnerHTML={{ __html: htmlReportOriginal }} />
      </Tab>
      <Tab
        title="Друкована форма (Логіка)"
        eventKey="presentedDocs"
      >
        <div dangerouslySetInnerHTML={{ __html: htmlReport }} />
      </Tab>
      <Tab title="Json" eventKey="Json">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.sendJson.label}
            value={data[md.columns.sendJson.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.sendJson.name]: value,
            })}
            readOnly={readOnly}
            rows={15}
          />
        </Container>
      </Tab>
      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

FinancingTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  // docId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
  setChanged: PropTypes.func.isRequired,
};

export default FinancingTabs;
