import React from 'react';
import md from '../../../../../meta/cat/fonditem';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { CatTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_elementLister/lister_selector';
import ClassifierRow from '../_classifierLister/classifierRow';
// import ElementListerCommandPanel from '../_elementLister/element_cp';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'lister',
})(CatTableListerFactory({
  RowComponent: ClassifierRow,
}));

function Lister(props) {
  return (
    <ListerSelector {...props}>
      <StdLister />
    </ListerSelector>
  );
}

export default Lister;
