import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: {
    label: 'Найменування',
    name: 'Наименование',
    visible: true,
  },
  comment: { label: 'Коментар', name: 'Комментарий', visible: true },

  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const publicBudgetProjectAuthor = {
  label: 'Автори проекта громадського бюджету',
  name: 'publicBudgetProjectAuthor',
  backendName: 'АвторПроектаГромадськийБюджет',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/publicBudgetProjectAuthor',
  listColumns: [...(new Set([...reqListerColumns, 'comment']))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default publicBudgetProjectAuthor;
