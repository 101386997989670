import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  dateRep: { label: 'Враховувати дані з', name: 'ДатаОтчета' },
  dateRep1: { label: 'по', name: 'ДатаОтчета1' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  appPlan: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  progVariant: { label: 'Варіант проекту', name: 'ВариантПроекта' },
  yearPlan: { label: 'Рік планування', name: 'ГодЗатрат' },
  in2019: { label: 'форми 2019  року', name: 'на2019' },
  in1000: { label: 'В тыс грн', name: 'ВТысГрн' },
  fromVar: { label: 'Рішення сесії з варіанту проекту', name: 'ИзВарианта' },
  sessiondesigion: { label: 'Рішення сесії', name: 'РешениеСессии' },
  sessiondesigionLOGICA: { label: 'Рішення сесії для відправки в  ЛОГІКА', name: 'РешениеСессииЛОГИКА' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  copecks: { label: 'Показувати копійки', name: 'Копейки' },
  flPlaN: { label: 'За документами "Розпис доходів" та "Зміни до розпису доходів"', name: 'флЗаДокПланИзмДоходов' },
};

const tables = {
  tpBudgets: {
    name: 'Бюджеты',
    label: 'Бюджети',
    columns: {
      Use: { label: 'Використання', name: 'Использование' },
      Budget: { label: 'Бюджет', name: 'Бюджет' },
      Element: { label: 'Элемент группа', name: 'ЭлементГруппа' },
    },
  },
};

const definition = {
  label: 'Рішення сесії. Додаток 1   (Доходи)',
  name: 'SessionAdd1',
  columns,
  tables,
  frontend: 'rep/SessionAdd1',
  backendName: 'РешениеСессии01',
  resultColumns: baseResultColumns,
};

export default definition;
