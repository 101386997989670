import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { comparisonTypes, emptyUid } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualSpend';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly,
  highlights, onSR, CSUinH, FI, CSU, isFilterCsuElemKFK, budget,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onlyExistFilter = useMemo(() => [{
    fieldName: 'НеДействующий',
    value: false,
  }], []);
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const ElemKFKFilter = useMemo(
    () => [...FIOwnerFilter, ...onlyExistFilter, ...(isFilterCsuElemKFK ? [{
      fieldName: 'Распорядитель',
      comparisonType: comparisonTypes.inList,
      value: [
        { id: emptyUid, repr: '' },
        (CSUinH ? CSU : row[tableMD.columns.CSU.name]) || { id: emptyUid, repr: '' },
      ],
    }] : [])],
    [CSU, CSUinH, FIOwnerFilter, isFilterCsuElemKFK, onlyExistFilter, row],
  );

  const TargetedProgramsFIlter = useMemo(
    () => [{ fieldName: 'Владелец', value: budget }],
    [budget],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, type, sumType, value) => {
      const colName = tableMD.columns[type].name;
      const sumName = tableMD.columns[sumType].name;
      const totColName = tableMD.columns.sum.name;
      let sum = 0;
      if (sumType === 'sumGenF') {
        sum = type === 'sumGenF1000' ? (row[tableMD.columns.sumGenF2000.name] || 0) + value : (row[tableMD.columns.sumGenF1000.name] || 0) + value;
      }
      if (sumType === 'sumSpecF') {
        sum = type === 'sumSpecF1000' ? (row[tableMD.columns.sumSpecF2000.name] || 0) + value : (row[tableMD.columns.sumSpecF1000.name] || 0) + value;
      }
      const tot = sumType === 'sumSpecF' ? (row[tableMD.columns.sumGenF.name] || 0) + sum : (row[tableMD.columns.sumSpecF.name] || 0) + sum;
      onRowChange(e, {
        [colName]: value,
        [sumName]: sum,
        [totColName]: tot,
      });
    },
    [onRowChange, row],
  );

  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.CSU.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onKFKChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  const onTargetedProgramsChange = useCallback(
    (e, v) => onRowChange(e, { [tableMD.columns.targetedPrograms.name]: v }),
    [onRowChange],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters className="align-items-center">
          {!CSUinH
          && (
            <Col
              xl={3}
              sm={12}
            >
              <TableRowCell column="CSU" highlighted={highlights.includes('CSU')}>
                <ItemPicker
                  value={row[tableMD.columns.CSU.name]}
                  modelType="cat"
                  modelName="csu"
                  onChange={onCSUChange}
                  filter={CSUFIlter}
                  noHierarchy
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          )}
          <Col
            xl={3}
            sm={12}
          >
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={onKFKChange}
                filter={ElemKFKFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {row[tableMD.columns.KPKVCode.name]}
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={CSUinH ? 2 : 1}
            sm={12}
          >
            <TableRowCell column="KPKVCode" highlighted={highlights.includes('KPKVCode')}>
              <StringInput
                value={row[tableMD.columns.KPKVCode.name]}
                onChange={() => {}}
                noHierarchy
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col
            xl={CSUinH ? 2 : 1}
            sm={12}
          >
            <TableRowCell column="KFKCode" highlighted={highlights.includes('KFKCode')}>
              <StringInput
                value={row[tableMD.columns.KFKCode.name]}
                onChange={() => {}}
                noHierarchy
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableRowCell column="targetedPrograms" highlighted={highlights.includes('targetedPrograms')}>
              <ItemPicker
                value={row[tableMD.columns.targetedPrograms.name]}
                modelType="cat"
                modelName="targetedPrograms"
                onChange={onTargetedProgramsChange}
                filter={TargetedProgramsFIlter}
                noHierarchy
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row noGutters>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumGenF1000" highlighted={highlights.includes('sumGenF1000')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF1000.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, 'sumGenF1000', 'sumGenF', v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumGenF1110" highlighted={highlights.includes('sumGenF1110')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF1110.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumGenF1110.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumGenF1160" highlighted={highlights.includes('sumGenF1160')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF1160.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumGenF1160.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumGenF2000" highlighted={highlights.includes('sumGenF2000')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF2000.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, 'sumGenF2000', 'sumGenF', v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableRowCell column="sumGenF" highlighted={highlights.includes('sumGenF')}>
              <NumberInput
                value={row[tableMD.columns.sumGenF.name]}
                precision={2}
                onChange={() => null}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>

        <Row noGutters>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumSpecF1000" highlighted={highlights.includes('sumSpecF1000')}>
              <NumberInput
                value={row[tableMD.columns.sumSpecF1000.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, 'sumSpecF1000', 'sumSpecF', v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumSpecF1110" highlighted={highlights.includes('sumSpecF1110')}>
              <NumberInput
                value={row[tableMD.columns.sumSpecF1110.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumSpecF1110.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumSpecF1160" highlighted={highlights.includes('sumSpecF1160')}>
              <NumberInput
                value={row[tableMD.columns.sumSpecF1160.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumSpecF1160.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumSpecF2000" highlighted={highlights.includes('sumSpecF2000')}>
              <NumberInput
                value={row[tableMD.columns.sumSpecF2000.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, 'sumSpecF2000', 'sumSpecF', v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={1}
            sm={12}
          >
            <TableRowCell column="sumBR" highlighted={highlights.includes('sumBR')}>
              <NumberInput
                value={row[tableMD.columns.sumBR.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumBR.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={1}
            sm={12}
          >
            <TableRowCell column="sumBRKR" highlighted={highlights.includes('sumBRKR')}>
              <NumberInput
                value={row[tableMD.columns.sumBRKR.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumBRKR.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={2}
            sm={12}
          >
            <TableRowCell column="sumSpecF" highlighted={highlights.includes('sumSpecF')}>
              <NumberInput
                name="specF"
                value={row[tableMD.columns.sumSpecF.name]}
                precision={2}
                onChange={() => null}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col
            xl={8}
            sm={12}
          >
            <TableRowCell column="note" highlighted={highlights.includes('note')}>
              <TextInput
                value={row[tableMD.columns.note.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.note.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={4}
            sm={12}
          >
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={() => null}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  CSUinH: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
  CSU: referencePropType.isRequired,
  budget: PropTypes.shape({
  }).isRequired,
  isFilterCsuElemKFK: PropTypes.bool.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
