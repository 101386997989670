import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import BasicTP from '../../../../../basicEditor/basicTP';
import typesOfChoices from '../../../../../../constants/typesOfChoices';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function ActivityRows({
  onChange, fromLogica, data, errors, readOnlyFields, fields, ...rest
}) {
  const totals = Object.keys(fields.executionbpactivityrow_set.child.children).filter((k) => k.includes('amount'));

  const onChangeTP = useCallback((f) => {
    const sumChanger = (oldData) => oldData.executionbpactivityrow_set.map((row) => ({
      ...row,
      amount_plan_zf: (row.amount_plan_zf || 0),
      amount_plan_sf: (row.amount_plan_sf || 0),
      amount_plan_total: (row.amount_plan_zf || 0) + (row.amount_plan_sf || 0),
      amount_fact_zf: (row.amount_fact_zf || 0),
      amount_fact_sf: (row.amount_fact_sf || 0),
      amount_fact_total: (row.amount_fact_zf || 0) + (row.amount_fact_sf || 0),
      amount_diff_zf: (row.amount_fact_zf - row.amount_plan_zf || 0),
      amount_diff_sf: (row.amount_fact_sf - row.amount_plan_sf || 0),
      amount_diff_total: (row.amount_fact_zf || 0) + (row.amount_fact_sf || 0)
          - ((row.amount_plan_zf || 0) + (row.amount_plan_sf || 0)),
    }));
    onChange((oldData) => {
      const d = f(oldData);
      return {
        ...d,
        executionbpactivityrow_set: sumChanger(d),
      };
    });
  }, [onChange]);

  const template = useMemo(
    () => {
      const sumColProps = {
        xxl: 2,
        xl: 3,
        lg: 2,
        sm: 12,
      };
      const activityColProps = {
        xxl: 6,
        xl: 3,
        lg: 6,
        sm: 12,
      };

      const sumcolumns = [
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_plan_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_plan_zf',
                },
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_plan_sf',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_fact_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_fact_zf',
                },
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_fact_sf',
                },
              ],
            },
          ],
        },
        {
          colProps: sumColProps,
          columns: [
            {
              colProps: { xs: 12 },
              field: 'amount_diff_total',
            },
            {
              colProps: { xs: 12 },
              columns: [
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_diff_zf',
                },
                {
                  colProps: { xs: 6, className: 'm-0 p-0' },
                  field: 'amount_diff_sf',
                },
              ],
            },
          ],
        },

      ];

      if (!fromLogica) {
        return {
          columns: [
            {
              colProps: activityColProps,
              columns: [
                {
                  field: 'activity',
                  colProps: { xs: 12 },
                  controlProps: {
                    typeOfChoice: typesOfChoices.onlyElements,
                    filter: {
                      budget: data.budget ? data.budget.id : null,
                    },
                  },
                },
                {
                  field: 'explanation',
                  colProps: { xs: 12 },
                  controlProps: {
                    rows: 2,
                  },
                },
              ],
            },
            ...sumcolumns,
          ],
        };
      }
      return {
        columns: [
          {
            colProps: activityColProps,
            columns: [
              {
                field: 'activity_text',
                colProps: { xs: 12 },
                controlProps: {
                  rows: 2,
                },
              },

              {
                field: 'explanation',
                colProps: { xs: 12 },
                controlProps: {
                  rows: 2,
                },
              },
            ],
          },
          ...sumcolumns,
        ],
      };
    },
    [data.budget, fromLogica],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      fields={fields}
      tableName="executionbpactivityrow_set"
      onChange={onChangeTP}
      totals={totals}
      noUseCommandPanel
      readOnlyFields={{
        executionbpactivityrow_set: [
          'amount_plan_zf',
          'amount_plan_sf',
          'amount_plan_total',
        ],
      }}
    />
  );
}

ActivityRows.propTypes = {
  data: PropTypes.shape({
    executionbpactivityrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    budget: foreignPropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    executionbpactivityrow_set: fieldsPropTypes,
  }),
  errors: PropTypes.shape({
    executionbpactivityrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape(dataPropTypes),
  readOnly: PropTypes.bool,
  fromLogica: PropTypes.bool.isRequired,
};

ActivityRows.defaultProps = {
  errors: null,
  readOnlyFields: {},
  readOnly: false,
  fields: null,
};

export default ActivityRows;
