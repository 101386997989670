import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  tablePeriod: { label: 'Період табличної частини', name: 'ПериодТЧ', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_91: {
    name: 'Табл91',
    label: 'Табл 91',
    columns: {
      code: { label: 'Код виду діяльності', name: 'Код' },
      name: { label: 'Назва виду діяльності', name: 'Наим' },
      rate: { label: 'Ставка єдиного податку', name: 'Ставка' },
      period: { label: 'Період застосування ставки', name: 'Период' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 9 (стар. 7)',
  name: 'taxes7',
  backendName: 'НалогиИЛьготыДод7',
  columns,
  frontend: 'doc/taxes7',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
