const frontendURL = '/cat/bfnGoalFondAmount/';
const backendURL = '/api/bfn/goalfondamount/';
// const backendURL = '/api/bfn/bfnamount/'; // Временно
const name = 'Довідник "Цільовий фонд вирівнювання"';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
