import React, { useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { StyledField } from '../styled';
import { usesTypes } from './constants';
import Modal from '../../../../../components/modal';
import CheckboxField from '../../../../field/CheckBox';

function UsesField({ value, onChange }) {
  const displayValue = useMemo(
    () => Object.keys(usesTypes).reduce((R, key) => {
      if (value.get(key)) return [...R, usesTypes[key]];
      return R;
    }, []).join(', '),
    [value],
  );
  const [opened, setOpened] = useState(false);
  return (
    <>
      <StyledField>
        <div>{displayValue}</div>
        <button type="button" className="change-button" onClick={() => setOpened(true)}>...</button>
      </StyledField>
      <Modal header="Область використання" closeIcon opened={opened} onClose={() => setOpened(false)} size="small">
        {Object.keys(usesTypes).map((key) => (
          <CheckboxField
            key={key}
            onChange={(e, v) => onChange(e, key, v)}
            value={value.get(key)}
            label={usesTypes[key]}
          />
        ))}
      </Modal>
    </>
  );
}

UsesField.propTypes = {
  value: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
};

UsesField.defaultProps = {
  value: new Map(),
};

export default memo(UsesField);
