import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  ThisIsGovProgram: {
    label: 'Державна цiльова програма',
    name: 'ЭтоГосПрограмма',
    visible: false,
  },
  BuildObject: {
    label: 'Об\'єкт будівництва',
    name: 'ОбъектСтроительства',
    visible: false,
  },
  fDate: {
    label: 'Вiдображення станом на дату',
    name: 'фДата',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  tCode: { label: 'КПКВ', name: 'тКод', visible: true },
  ApprovalDocument: {
    label: 'Коли та яким документом затверджена',
    name: 'ДокументУтверждения',
    visible: false,
  },
  ActivitiesList: {
    label: 'Короткий змiст заходiв за програмою',
    name: 'ПереченьМероприятий',
    visible: false,
  },
  TotalCostZF: {
    label: 'Загальна вартiсть заг.ф.',
    name: 'ОбщаяСтоимостьЗФ',
    visible: false,
  },
  TotalCostSF: {
    label: 'Загальна вартiсть спец.ф.',
    name: 'ОбщаяСтоимостьСФ',
    visible: false,
  },
  CapitalExpendituresBI: {
    label: 'Капітальні видатки з утримання бюджетних установ',
    name: 'КапЗатрНаСодержание',
    visible: false,
  },
  owner: { label: 'Бюджет', name: 'Владелец', visible: false },
};

export const targetedPrograms = {
  label: 'Цільові програми',
  name: 'targetedPrograms',
  backendName: 'ЦелевыеПрограммы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/targetedPrograms',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'tCode',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default targetedPrograms;
