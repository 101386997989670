import {
  useState, useCallback, useEffect, useContext,
} from 'react';
import api from '../../../../api/req';
import checkTask from '../../../../api/checktask';
import { CiatAppContext } from '../../../../providers';

const useFetchSpendData = ({ cDt }) => {
  const { auth } = useContext(CiatAppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [expensesFondData, setExpensesFondData] = useState({
    zf: {
      total: 0, percent: 0, 2110: { total: 0, repr: '' }, 2270: { total: 0, repr: '' },
    },
    sf: {
      total: 0, percent: 0, 2110: { total: 0, repr: '' }, 2270: { total: 0, repr: '' },
    },
  });
  const [expensesData, setExpensesData] = useState({
    kbp: {
      total: 0,
      values: [],
    },
    kekv: {
      total: 0,
      values: [],
    },
  });
  const [compareExpansesData, setCompareExpansesData] = useState({
    'Державне управління': { 2024: { amount: 0 }, 2023: { amount: 0 }, 2022: { amount: 0 } },
    Освіта: { 2024: { amount: 0 }, 2023: { amount: 0 }, 2022: { amount: 0 } },
    "Охорона здоров'я": { 2024: { amount: 0 }, 2023: { amount: 0 }, 2022: { amount: 0 } },
    'Решта видатків': { 2024: { amount: 0 }, 2023: { amount: 0 }, 2022: { amount: 0 } },
  });
  const [expensesByDisposer, setExpensesByDisposer] = useState(
    [
      {
        repr: '02 Виконавчий комітет місцевої ради (міської, селищної, сільської ради), Рада міністрів Автономної Республіки Крим, державна адміністрація (обласні державні адміністрації, Київська та Севастопольська міські державні адміністрації, районні державні адміністрації (управління, відділи))',
        fact: 0,
        plan: 0,
        percent: 0,
      },
      {
        repr: '06 Орган з питань освіти і науки',
        fact: 0,
        plan: 0,
        percent: 0,
      },
      {
        repr: '08 Орган з питань праці та соціального захисту населення',
        fact: 0,
        plan: 0,
        percent: 0,
      },
    ],
  );

  const fetchAllData = useCallback(() => {
    const loadExpensesFond = async () => {
      const r = await api.get(`/api/dashboards/plan_expenses_by_fond/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadExpenses = async () => {
      const r = await api.get(`/api/dashboards/plan_expenses/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadExpensesCompare = async () => {
      const r = await api.get(`/api/dashboards/expenses_compare_years/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    const loadExpensesByDisposer = async () => {
      const r = await api.get(`/api/dashboards/expenses_by_disposer/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    setIsLoading(true);
    setErr(null);

    Promise.all([
      loadExpensesFond(),
      loadExpenses(),
      loadExpensesCompare(),
      loadExpensesByDisposer()])
      .then((
        [expensesFondDataResult,
          expensesDataResult,
          compareExpansesDataResult,
          expensesByDisposerResult,
        ],
      ) => {
        setExpensesFondData(expensesFondDataResult.result);
        setExpensesData(expensesDataResult.result);
        setCompareExpansesData(compareExpansesDataResult.result);
        setExpensesByDisposer(expensesByDisposerResult.result);
      })
      .catch((error) => setErr(error.message))
      .finally(() => setIsLoading(false));
  }, [auth, cDt]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return {
    isLoading, err, expensesFondData, expensesData, compareExpansesData, expensesByDisposer,
  };
};

export default useFetchSpendData;
