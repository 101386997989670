import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card } from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import Flex from '../../../../components/blanks/common/Flex';
import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';
import IconAlert from '../../../../components/blanks/common/IconAlert';

function Files({ fileInfo }) {
  return (
    <div>
      {fileInfo.path}
      {' '}
      -
      {fileInfo.size}
      {' '}
      bytes
    </div>
  );
}

function ReadFile({ skd, children }) {
  const [err, setError] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    setFileInfo({
      path: file.path,
      size: file.size,
    });

    reader.onload = (event) => {
      const fileContents = event.target.result;
      skd.onChange(fileContents);
    };

    reader.onerror = () => {
      setError(reader.error);
    };

    reader.readAsText(file);
  }, [skd]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xml',
    maxFiles: 1,
  });

  return (
    <Card>
      <Card.Header>
        {err && (
        <IconAlert variant="danger">
          {err}
        </IconAlert>
        )}
        {/* <Card.Title>{data?.skd_schema?.label}</Card.Title> */}
        <Card.Title>{skd.label}</Card.Title>
        <div {...getRootProps({ className: 'dropzone-area py-0' })}>
          <input {...getInputProps()} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Перетягніть файл сюди</p>
          </Flex>
        </div>
        <div className="mt-3">
          {fileInfo && (
            <>
              <h6>Файл</h6>
              <Files
                fileInfo={fileInfo}
              />
            </>
          )}
        </div>
      </Card.Header>
      <Card.Body className="bg-light">
        {/* <Control name="skd_schema" headerProps={headerProps} typeControl="text" /> */}
        {children}
      </Card.Body>
    </Card>
  );
}

Files.propTypes = {
  fileInfo: PropTypes.shape({
    path: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
};

Files.defaultProps = {
  fileInfo: null,
};

ReadFile.propTypes = {
  skd: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.shape({}),
};

ReadFile.defaultProps = {
  children: null,
};
export default ReadFile;
