import React, { Suspense, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import ExportFinancialsToDBFEditor from './ExportFinancialsToDBF';

function ExportFinancialsToDBFButton({ doc, backendURL, disabled }) {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <CPButton
        onClick={() => setOpened(true)}
        content="DBF"
        disabled={disabled}
        icon={faFileExport}
      />
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        className="w-100"
        dialogClassName="min-vw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Вивантаження в ДБФ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={(<span>...</span>)}>
            <ExportFinancialsToDBFEditor
              currentItem={doc}
              backendURL={backendURL}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
    </>
  );
}

ExportFinancialsToDBFButton.propTypes = {
  doc: PropTypes.arrayOf(PropTypes.string).isRequired,
  backendURL: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ExportFinancialsToDBFButton.defaultProps = {
  disabled: false,
};
export default ExportFinancialsToDBFButton;
