import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../newEditor/tablePart/styles';
import { columnsPropType } from './propTypes';

const pt = {
  columns: columnsPropType.isRequired,
  fields: PropTypes.shape({}).isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,

};

const dp = {
  activeCol: null,
};

function BasicHeaderCols({
  columns,
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <>
      {columns.map((col, i) => {
        const colProps = {
          ...(col.colProps || {}),
          ...(col.headerColProps || {}),
        };
        if (col.columns) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={i} {...colProps}>
              <Row className="mx-0">
                <BasicHeaderCols
                  columns={col.columns}
                  fields={fields}
                  activeCol={activeCol}
                  highlights={highlights}
                  onToggleHighlight={onToggleHighlight}
                />
              </Row>
            </Col>
          );
        }
        return (
          <Col key={col.field} {...colProps}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === col.field}
              highlighted={highlights.includes(col.field)}
              onToggleHighlight={() => onToggleHighlight(col.field)}
              title={col.title}
            >
              {col.headerLabel || (fields[col.field] || {}).label}
            </TableHeaderCell>
          </Col>
        );
      })}
    </>
  );
}

BasicHeaderCols.propTypes = pt;
BasicHeaderCols.defaultProps = dp;

function BasicHeader({
  columns,
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow className="px-4">
      <BasicHeaderCols
        columns={columns}
        fields={fields}
        activeCol={activeCol}
        highlights={highlights}
        onToggleHighlight={onToggleHighlight}
      />
    </TableHeaderRow>
  );
}

BasicHeader.propTypes = pt;
BasicHeader.defaultProps = dp;

export default BasicHeader;
