/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';
import useOpenController from './Hooks/useOpenController';
import CollapsedTableRow from './CollapsedTableRow';

function TableSection({ revenues, level }) {
  const { isOpen, toggle } = useOpenController(false);
  return (
    <>
      {revenues.nested ? (
        <CollapsedTableRow
          revenues={revenues}
          toggle={toggle}
          level={level}
          isOpen={isOpen}
        />
      )
        : (
          <TableRow
            revenues={revenues}
            level={level + 1}
          />
        )}

      {isOpen && revenues.nested && (
        revenues.nested.map((rev, indx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableSection revenues={rev} key={`pureRow-${indx}`} level={level + 1} />
        ))
      )}
    </>
  );
}

TableSection.propTypes = {
  revenues: PropTypes.shape({
    nested: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  level: PropTypes.number.isRequired,
};

export default TableSection;
