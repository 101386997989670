import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
  year,
}) {
  return (
    <TableHeaderRow className="px-4">
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'target'}
          highlighted={highlights.includes('target')}
          onToggleHighlight={() => onToggleHighlight('target')}
        >
          {fields.target.label}
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf0_appr'}
          highlighted={highlights.includes('amount_zf0_appr')}
          onToggleHighlight={() => onToggleHighlight('amount_zf0_appr')}
        >
          затверджено прогнозом місцевого бюджету на
          <span className="mx-2">{year}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf0_appr'}
          highlighted={highlights.includes('amount_sf0_appr')}
          onToggleHighlight={() => onToggleHighlight('amount_sf0_appr')}
        >
          затверджено прогнозом місцевого бюджету на
          <span className="mx-2">{year}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf0_prop'}
          highlighted={highlights.includes('amount_zf0_prop')}
          onToggleHighlight={() => onToggleHighlight('amount_zf0_prop')}
        >
          включено до пропозиції до прогнозу місцевого бюджету на
          <span className="mx-2">{year}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf0_prop'}
          highlighted={highlights.includes('amount_sf0_prop')}
          onToggleHighlight={() => onToggleHighlight('amount_sf0_prop')}
        >
          включено до пропозиції до прогнозу місцевого бюджету на
          <span className="mx-2">{year}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf0_dif'}
          highlighted={highlights.includes('amount_zf0_dif')}
          onToggleHighlight={() => onToggleHighlight('amount_zf0_dif')}
        >
          Відхилення +/-
          <span className="mx-2">{year}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf0_dif'}
          highlighted={highlights.includes('amount_sf0_dif')}
          onToggleHighlight={() => onToggleHighlight('amount_sf0_dif')}
        >
          Відхилення +/-
          <span className="mx-2">{year}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf1_appr'}
          highlighted={highlights.includes('amount_zf1_appr')}
          onToggleHighlight={() => onToggleHighlight('amount_zf1_appr')}
        >
          затверджено прогнозом місцевого бюджету на
          <span className="mx-2">{year + 1}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf1_appr'}
          highlighted={highlights.includes('amount_sf1_appr')}
          onToggleHighlight={() => onToggleHighlight('amount_sf1_appr')}
        >
          затверджено прогнозом місцевого бюджету на
          <span className="mx-2">{year + 1}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf1_prop'}
          highlighted={highlights.includes('amount_zf1_prop')}
          onToggleHighlight={() => onToggleHighlight('amount_zf1_prop')}
        >
          включено до пропозиції до прогнозу місцевого бюджету на
          <span className="mx-2">{year + 1}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf1_prop'}
          highlighted={highlights.includes('amount_sf1_prop')}
          onToggleHighlight={() => onToggleHighlight('amount_sf1_prop')}
        >
          включено до пропозиції до прогнозу місцевого бюджету на
          <span className="mx-2">{year + 1}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
      <Col
        className="px-1  d-flex flex-column justify-content-end"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_zf1_dif'}
          highlighted={highlights.includes('amount_zf1_dif')}
          onToggleHighlight={() => onToggleHighlight('amount_zf1_dif')}
        >
          Відхилення +/-
          <span className="mx-2">{year + 1}</span>
          рік по ЗФ
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'amount_sf1_dif'}
          highlighted={highlights.includes('amount_sf1_dif')}
          onToggleHighlight={() => onToggleHighlight('amount_sf1_dif')}
        >
          Відхилення +/-
          <span className="mx-2">{year + 1}</span>
          рік по CФ
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: new Date().getFullYear(),
};

export default Header;
