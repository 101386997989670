import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  fin: { label: 'Фінансист', name: 'Финансист' },
};

/**
 * @const
 */
const definition = {
  name: 'printPayment',
  backendName: 'ПечатьПлатИсх',
  label: 'Друк платіжного доручення',
  frontend: 'dp/printPayment',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
