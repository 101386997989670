const frontendURL = '/rep/treasuryFileAnalyze/';
const name = 'Аналіз казначейських файлів';
const sections = ['execution'];

const instance = {
  frontendURL,
  name,
  sections,
};

export default instance;
