import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: {
    label: 'Найменування',
    name: 'Наименование',
    visible: true,
  },
  owner: { name: 'Владелец', label: 'Власник', visible: true },

  // FullName: {
  //   label: 'Повне найменування',
  //   name: 'ПолнНаименование',
  //   visible: true,
  // },
};

export const reportsForms = {
  label: 'Форми звітів',
  name: 'reportsForms',
  backendName: 'ФормыОтчетов',
  defaultOrder,
  columns,
  frontend: 'cat/reportsForms',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default reportsForms;
