import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControl, InputGroup, Dropdown, Spinner, Form,
} from 'react-bootstrap';
import { foreignPropType } from '../../constants/backend/propTypes';

const StyledDropdownMenu = styled(Dropdown.Menu)`
  max-height: 20em;
`;

const StyledFormControl = styled(FormControl)`
  background-color: var(--falcon-input-bg);
`;
export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, foreignPropType]);

const Selector = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  required, isLoading, nullLabel, onDropDown, errors,
  className, size, errorAsTooltip, onFocus, onBlur, prepend, append,
}, ref) => {
  const valueTest = useMemo(
    () => (typeof value === 'object' && value !== null ? value.id : value),
    [value],
  );
  const displayValues = useMemo(
    () => (required ? values || [] : [{ value: null, display_name: nullLabel }, ...values])
      .map((v) => ({
        ...v,
        valueTest: typeof v.value === 'object' && v.value !== null ? v.value.id : v.value,
      })),
    [nullLabel, required, values],
  );
  const displayValue = useMemo(
    () => displayValues.reduce(
      (R, v) => (v.valueTest === valueTest ? v.display_name : R),
      typeof value === 'object' && value !== null ? value.repr : '',
    ),
    [displayValues, value, valueTest],
  );
  const errored = !!errors && !!errors.length;

  return (
    <InputGroup className={className} size={size || 'sm'}>
      {prepend}
      <StyledFormControl
        id={id}
        ref={ref}
        value={displayValue}
        isInvalid={errored}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly
        size={size || 'sm'}
      />
      {isLoading && (
        <div className="border border-left-0 px-2 d-flex align-items-center">
          <Spinner animation="border" size={size || 'sm'} />
        </div>
      )}
      {append}
      <Dropdown
        // style={{ position: 'unset' }}
        // as={InputGroup.Append}
        size={size || 'sm'}
        onToggle={(isOpen, e, md) => {
          if (isOpen && onDropDown) onDropDown(e, md);
        }}
      >
        <Dropdown.Toggle id={`drdn-${id}`} variant="falcon-secondary" disabled={disabled || readOnly} split size="sm" />
        <StyledDropdownMenu align="end" className="scrollbar">
          {displayValues.map((vv) => (
            <Dropdown.Item
              key={vv.valueTest}
              active={vv.valueTest === valueTest}
              disabled={vv.disabled}
              onClick={(e) => { e.preventDefault(); onChange(e, vv.value); }}
              onSelect={(eventKey, event) => event.preventDefault()}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              {vv.display_name}
            </Dropdown.Item>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
      {errors && (
      <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
        {errors.join(', ')}
      </Form.Control.Feedback>
      )}
    </InputGroup>
  );
});

Selector.propTypes = {
  id: PropTypes.string,
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  nullLabel: PropTypes.string,
  onDropDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', '']),
  prepend: PropTypes.node,
  append: PropTypes.node,
};

Selector.defaultProps = {
  id: '',
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  required: true,
  isLoading: false,
  nullLabel: 'Не обрано',
  onDropDown: null,
  className: '',
  size: 'sm',
  errors: null,
  onFocus: null,
  onBlur: null,
  errorAsTooltip: false,
  prepend: null,
  append: null,
};

export default Selector;
