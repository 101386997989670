import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  CSU: { label: 'Розпорядник', name: 'фРаспорядитель' },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  elementFond: { label: 'Елемент фонду', name: 'ЭлементФонд' },
  year: { label: 'Рік', name: 'тГод' },
  planingMode: { label: 'Вид планування', name: 'ВидПлана' },
  period: { label: 'Період', name: 'тПериод' },
  DataSource: { label: 'ИсточникДанных', name: 'ИсточникДанных' },
  kbp7: { label: 'КБП7', name: 'КБП7' },
  kekv: { label: 'КЕКВ', name: 'КЕКВ' },
};

/**
 * @const
 */
const tables = {
  data: {
    name: 'Данные',
    label: 'Дані',
    columns: {
      Check: { label: 'М', name: 'М' },
      Document: { label: 'Документ', name: 'Документ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Autor: { label: 'Автор', name: 'Автор' },
    },
  },
};

/**
 * @const
 */
const definition = {
  name: 'docSelectionTransfer',
  backendName: 'ПодборДокМежбюджТрансф',
  label: 'Відбір документів',
  columns,
  tables,
};

export default definition;
