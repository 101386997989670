import {
  useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import api from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';
import { useMD } from '../../../newLister/hooks/md';

/**
 *
 * @param modelType
 * @param modelName
 * @param viewType
 * @returns {string|null}
 */
// function getSettingsName(backendURL, viewType) {
//   return `${backendURL}/${viewType}`;
// }

/**
 *
 * @param backendURL {string}
 * @param viewType {string}
 * @param meta
 * @param setLoading {function}
 * @param setErr {function}
 * @returns {{
 * onSaveSettings: (function(): void),
 * settings: {},
 * onLoadSettings: (function(): void),
 * onSetSettings: (function(*): void)
 * }}
 */
const useSettings = (backendURL, viewType, setLoading, setErr) => {
  const meta = useMD(backendURL);
  const settings = useRef(null);

  const settingsName = useMemo(
    () => `${meta.modelType}-${meta.modelName}:${viewType}`,
    [meta.modelName, meta.modelType, viewType],
  );
  const settingsURL = useMemo(() => `/api/auth/profilesettings/${settingsName}/`, [settingsName]);
  const { auth } = useContext(CiatAppContext);
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const onSaveSettings = useCallback(
    () => {
      const asyncLoader = async () => {
        const response = await api.post(settingsURL, auth, { settings: settings.current });
        if (response.ok) {
          return true;
        }
        throw new Error(`Помилка при збереженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      asyncLoader(settings.current)
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, meta.label, setErr, setLoading, settingsURL],
  );

  const onSetSettings = useCallback(
    (partSettgins) => {
      settings.current = {
        ...settings.current,
        ...partSettgins,
      };
      onSaveSettings();
    },
    [onSaveSettings],
  );
  const onLoadSettings = useCallback(
    () => {
      const asyncLoader = async () => {
        const response = await api.get(settingsURL, auth);
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Помилка при завантаженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      asyncLoader()
        .then((data) => {
          settings.current = data.settings || {};
          setSettingsLoaded(true);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, meta.label, setErr, setLoading, settingsURL],
  );

  return {
    onLoadSettings,
    settings: settings.current,
    onSetSettings,
    settingsLoaded,
  };
};

export default useSettings;
