import React, { useContext, useMemo } from 'react';
import {
  Col, Container, OverlayTrigger, Popover, Row, Tab, TabContainer, Tabs,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { Footer } from '../../../../components/Containers/docs';
import {
  Indicators,
  InvestmentProgram,
  DirectionOfUse,
  RegProgram,
  OwnEntrySF,
  ActivityAnalogySF,
  // Note,
  Conclusions,
  DocumentDisposerOfLowerLevel,
  EfficiencyСalculation,
  PublicPolicyTarget,
  Assignment,
} from './tp';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';
import { CiatAppContext } from '../../../../providers';
import { Control } from '../../../newEditor/hook/control';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';
import ReceivedStamp from '../../../../components/stamp/receivedStamp';

const BACKENDURL = meta.doc.budgetExecution.backendURL;
function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, readOnlyFields, headerProps, readOnly, printForms,
  } = useEditor({
    backendURL: BACKENDURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const { currentUser } = useContext(CiatAppContext);
  const { authority } = currentUser;

  const disposerFilter = useMemo(
    () => ({
      parent: data.authority ? data.authority.id : authority.id,
    }),
    [authority.id, data.authority],
  );
  const authorityFilter = useMemo(
    () => ({
      authority: data.authority ? data.authority.id : authority.id,
    }),
    [authority.id, data.authority],
  );
  const yearFilter = useMemo(
    () => ({
      year: data.year ? data.year - 1 : new Date().getFullYear() - 1,
    }),
    [data.year],
  );
  const fromLogica = !!data.logica_id;
  const indicatorАnalysis = editorHooks.useStringInputProps('indicator_analysis', data, fields, fieldErrors, actions.onChange, false);
  const explanation = editorHooks.useStringInputProps('explanation', data, fields, fieldErrors, actions.onChange, false);
  const conclusion = editorHooks.useStringInputProps('conclusion', data, fields, fieldErrors, actions.onChange, false);
  const goalText = editorHooks.useStringInputProps('goal_text', data, fields, fieldErrors, actions.onChange, false);
  const lowEffectivenessExplanation = editorHooks.useStringInputProps('low_effectiveness_explanation', data, fields, fieldErrors, actions.onChange, false);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={`${data.repr} ${fromLogica ? '(затягнутий з сервісу Logica)' : ''}`}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      fields={fields}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          backendURL={BACKENDURL}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={BACKENDURL}
            documnetId={data.id}
            disabled={!(data.authority_sign_variant && data.executed && !changed)}
          />
        </DocCommandPanel>
      )}
    >
      <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Загальне">
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <Control name="doc_date" headerProps={headerProps} />
              </Col>
              <Col>
                <Control name="year" headerProps={headerProps} typeControl="yearInput" />
              </Col>
              <Col>
                <Control name="authority_sign_variant" headerProps={headerProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(
                    <Popover id="tooltip-passport">
                      <Popover.Header as="h3">
                        {'Оберіть документ \'Паспорт бюджетної програми\','
                            + ' на основі якого буде створено цей документ'}
                      </Popover.Header>
                      <Popover.Body>
                        {`Після того, як ви виберете 'Паспорт бюджетної програми', данні з
                         нього автоматично перенесуться до цього документу `}
                      </Popover.Body>
                    </Popover>
                    )}
                >
                  <div>
                    <Control
                      name="passport"
                      headerProps={{
                        ...headerProps,
                        readOnly: data.kbp7 && !data.passport,
                        advancedProps: {
                          filter: authorityFilter,
                        },
                      }}
                    />
                  </div>
                </OverlayTrigger>
              </Col>
              <Col>
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(
                    <Popover id="tooltip-execution_prev_year">
                      <Popover.Header as="h3">
                        {'Виберіть документ \'Виконання бюджетної програми\''
                            + ' за минулий період.'}
                      </Popover.Header>
                      <Popover.Body>
                        {`Після вибору цього документа дані з нього автоматично перенесуться до 
                        поточного документа, і ви зможете здійснити розрахунок ефективності бюджетної програми.`}
                      </Popover.Body>
                    </Popover>
                    )}
                >
                  <div>
                    <Control
                      name="execution_prev_year"
                      headerProps={{
                        ...headerProps,
                        advancedProps: {
                          filter: { ...authorityFilter, ...yearFilter },
                        },
                      }}
                    />
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>

            {data.passport && (
            <Row>
              <Col>
                <Control
                  name="kbp7"
                  headerProps={{
                    ...headerProps,
                    readOnly: data.passport,
                    isReadOnly: data.passport,
                  }}
                />
              </Col>
              <Col>
                <Control
                  name="kbp"
                  headerProps={{
                    ...headerProps,
                    readOnly: true,
                    isReadOnly: true,
                  }}
                />
              </Col>
              <Col>
                <Control
                  name="kfk"
                  headerProps={{
                    ...headerProps,
                    readOnly: true,
                    isReadOnly: true,
                  }}
                />
              </Col>
              <Col>
                <Control
                  name="disposer"
                  headerProps={{
                    ...headerProps,
                    advancedProps: {
                      filter: disposerFilter,
                      noHierarchy: true,
                    },
                  }}
                />
              </Col>
              {/* <Col className="mt-auto"> */}
              {/* <Control name="approved" headerProps={headerProps} /> */}
              {/* </Col> */}
            </Row>
            )}

            { currentUser.is_superuser && (
            <Row>
              <Col>
                <Control name="received" headerProps={headerProps} />
              </Col>
              <Col />
            </Row>
            )}
          </Container>
          <Footer
            created={data.created_at}
            updated={data.updated_at}
            budget={data.budget}
            authority={data.authority}
            author={data.author}
            headerProps={headerProps}
          />
          {data.received && (
          <div className="stamp-container">
            <ReceivedStamp invoice={data.invoice} />
          </div>
          )}
        </Tab>
        <Tab eventKey="main" title="Основне">
          <Tabs defaultActiveKey="executionbpindicatorcost" id="main-tabs">
            <Tab eventKey="executionbpindicatorcost" title="Напрями використання бюджетних коштів">
              <TabContainer>
                <DirectionOfUse
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  fromLogica={fromLogica}
                />
              </TabContainer>
            </Tab>
            {fromLogica && (
              <Tab eventKey="passportbppublicpolicytarget" title="Цілі держ.політики, завдання, мета та пояснення (Logica)">
                <TabContainer>
                  <Row>
                    <PublicPolicyTarget
                      data={data}
                      fields={fields}
                      errors={fieldErrors}
                      readOnly={readOnly}
                    />
                  </Row>
                  <Row>
                    <Assignment
                      data={data}
                      fields={fields}
                      errors={fieldErrors}
                      readOnly={readOnly}
                    />
                  </Row>
                  <Row>
                    <EditorControls.TextInput {...goalText} />
                  </Row>
                  <Row>
                    <EditorControls.TextInput {...explanation} />
                  </Row>
                </TabContainer>
              </Tab>
            )}
            <Tab eventKey="executionbpbasic" title="Показники">
              <TabContainer>
                <Indicators
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  readOnly={readOnly}
                  onDraft={actions.onDraft}
                  fromLogica={fromLogica}
                />
              </TabContainer>
            </Tab>
            <Tab eventKey="analysis" title="Висновки по виконанню бюджетної програми">
              <TabContainer>
                <Row>
                  <EditorControls.TextInput {...indicatorАnalysis} rows={5} />
                </Row>
                <Row>
                  <EditorControls.TextInput {...conclusion} rows={5} />
                </Row>
              </TabContainer>
            </Tab>
            <Tab eventKey="executionbpregprogram" title="Рег. цільові програми">
              <TabContainer>
                <RegProgram
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  fromLogica={fromLogica}
                />
              </TabContainer>
            </Tab>
            <Tab eventKey="executionbpinvestmentprogram" title="Джерела фін. інвест. програм">
              <TabContainer>
                <InvestmentProgram
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                  fromLogica={fromLogica}
                />
              </TabContainer>
            </Tab>
            <Tab eventKey="executionbpownentrysf" title="Джерела надходжень СФ">
              <TabContainer>
                <OwnEntrySF
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>
          </Tabs>
        </Tab>
        {/* {fields && fields.note && ( */}
        {/* <Tab eventKey="note" title="Примітки"> */}
        {/*  <TabContainer> */}
        {/*    <Note */}
        {/*      data={data} */}
        {/*      fields={fields} */}
        {/*      errors={fieldErrors} */}
        {/*      onChange={actions.onChange} */}
        {/*    /> */}
        {/*  </TabContainer> */}
        {/* </Tab> */}
        {/* )} */}
        <Tab eventKey="executionbpefficiencycalculation" title="Розрахунок ефективності">
          <Row>
            <Col>
              <Control
                name="effectiveness"
                headerProps={{
                  ...headerProps,
                  isReadOnly: true,
                }}
              />
            </Col>
            <Col>
              <Control name="quality_degree" headerProps={{ ...headerProps, isReadOnly: true }} />
            </Col>
            <Col>
              <Control name="adjustment" headerProps={{ ...headerProps, isReadOnly: true }} />
            </Col>
          </Row>
          <Row>
            <TabContainer>
              <EfficiencyСalculation
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
                onDraft={actions.onDraft}
              />
            </TabContainer>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput {...lowEffectivenessExplanation} rows={3} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="executionbpactivityanalogysf" title="Виконання показників порівняно із показниками попереднього року">
          <TabContainer>
            <ActivityAnalogySF
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              onDraft={actions.onDraft}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="conclusions" title="Висновки по програмі">
          <TabContainer>
            <Conclusions
              data={data}
              fields={fields}
              errors={fieldErrors}
              onChange={actions.onChange}
            />
          </TabContainer>
        </Tab>
        <Tab eventKey="executionbplowleverdocs" title="Док. розпорядників">
          <TabContainer>
            <DocumentDisposerOfLowerLevel
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
              readOnly={readOnly}
              onDraft={actions.onDraft}
              tableName="executionbpleveldowndocsrow_set"
            />
          </TabContainer>
        </Tab>
      </Tabs>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
