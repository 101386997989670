import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Map } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft, faArrowRight, faClock, faCheck, faExclamationCircle, faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import enums from '../../../constants/meta/enums';

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 30deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, -30deg);
  }
`;

const StyledFAI = styled(FontAwesomeIcon)`
  transition: all 4s ease;
  animation: ${rotate} 1s linear infinite;
`;

function MessagesBox({
  message,
  hasNext, hasPrevious, hasClose, hasPostPone, hasRead,
  onNext, onPrevious, onClose, onPostpone, onRead,
}) {
  return (
    <Modal show size="lg" onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton={hasClose}>
        <Modal.Title>
          {message.get('type') === enums.userMessageTypes.warning ? 'Попередження!' : 'Інформація!'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.get('type') === enums.userMessageTypes.warning ? (
          <StyledFAI icon={faExclamationCircle} className="me-4 text-warning" size="5x" pull="left" />
        ) : (
          <StyledFAI icon={faInfoCircle} className="me-4 text-success" size="5x" pull="left" />
        )}
        {message.get('message')}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onPrevious()} disabled={!hasPrevious} variant="info">
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          Попереднє
        </Button>
        <Button onClick={() => onPostpone(message.get('id'))} disabled={!hasPostPone} variant="light">
          <FontAwesomeIcon icon={faClock} className="me-2" />
          Нагадати пізніше
        </Button>
        <Button onClick={() => onRead(message.get('id'))} disabled={!hasRead} variant="success">
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Ознайомлений
        </Button>
        <Button onClick={() => onNext()} disabled={!hasNext} variant="info">
          <FontAwesomeIcon icon={faArrowRight} className="me-2" />
          Наступне
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MessagesBox.propTypes = {
  message: PropTypes.instanceOf(Map).isRequired,
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  hasClose: PropTypes.bool,
  hasPostPone: PropTypes.bool,
  hasRead: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPostpone: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
};

MessagesBox.defaultProps = {
  hasNext: false,
  hasPrevious: false,
  hasClose: false,
  hasPostPone: false,
  hasRead: false,
};

export default MessagesBox;
