import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import meta from '../../../constants/meta';
import api from '../../../api/req';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import { Loader } from '../../../components/bootstrap_components';
import IconAlert from '../../../minfin/components/blanks/common/IconAlert';
import { EditorControls } from '../../../components/bootstrap_components/editorControls';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const TRANS = {
  ref1: 'Приказ1',
  number1: 'НомерПриказа1',
  date1: 'ДатаПриказа1',
  text1: 'Приказ1Стр'
};

function maper(data){
  return Object.keys(TRANS)
            .reduce((R, k) => ({
              ...R,
              [k]: data[TRANS[k]] || null,
            }), {})

}
function demaper(data){
  return Object.keys(TRANS)
            .reduce((R, k) => ({
              ...R,
              [TRANS[k]]: data[k],
            }), {})

}

function NakazForm({ selectedRows }) {
  const id = useMemo(
    () => selectedRows.length ? selectedRows[0] : null,
    [selectedRows],
  );
  const [data, setData] = useState({});
  useEffect(() => {
    if (id) setData(Object.keys(TRANS)
    .reduce((R, k) => ({
      ...R,
      [k]: null
    }), {}))
  }, [id]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
      const loader = async () => {
        const r = await api.post(`/doc/${meta.doc.indicatorPassport.backendName}/`, { id, method: 'get_nakaz' });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      if (show) {
        setLoading(true);
        setErr(false);
        loader()
          .then(d => setData(maper(d)))
          .catch(e => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [id, show]
  );
  const onChangeRef1 = useCallback(
    (e,v) => {
      setData((d) => ({...d,  ref1: v}));
      const loader = async () => {
        const r = await api.post(`/doc/${meta.doc.indicatorPassport.backendName}/`, { [TRANS.ref1]: v, method: 'fill_nakaz' });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(false);
      loader()
        .then(d => setData(maper(d)))
        .catch(e => setErr(e.message))
        .finally(() => setLoading(false));
    },
    []
  )
  const onSave = useCallback(
    (e) => {
      const loader = async () => {
        const r = await api.post(`/doc/${meta.doc.indicatorPassport.backendName}/`, { ...demaper(data), id, method: 'set_nakaz' });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(false);
      loader()
        .then(() => setShow(false))
        .catch(e => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [data, id]
  )
  return (
    <>
      <Button
        variant="primary"
        title="Додати наказ"
        onClick={() => setShow(true)}
        disabled={!id}
      >
        <FontAwesomeIcon icon={faWpforms}/>
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Додати наказ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader/>}
          {err && (
            <IconAlert variant="danger">
              {err}
            </IconAlert>
          )}
          <Row>
            <Col md={12}>
              <EditorControls.ItemPicker
                modelType="cat"
                modelName="laws"
                value={data.ref1}
                label="Наказ"
                onChange={onChangeRef1}
              />
            </Col>
            <Col md={6}>
              <EditorControls.StringInput
                value={data.number1}
                label="№ наказа"
                onChange={(e,v) => setData((d) => ({ ...d, number1: v}))}
              />
            </Col>
            <Col md={6}>
              <EditorControls.DateInput
                value={data.date1}
                label="Дата наказа"
                onChange={(e,v) => setData((d) => ({ ...d, date1: v}))}
              />
            </Col>
            <Col md={12}>
              <EditorControls.TextInput
                value={data.text1}
                onChange={(e,v) => setData((d) => ({ ...d, text1: v}))}
                rows={5}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            <FontAwesomeIcon icon={faSave} className="me-1" />
            Встановити
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FontAwesomeIcon icon={faTimes} className="me-1" />
            Закрити
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}

NakazForm.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default NakazForm;