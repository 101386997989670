const frontendURL = '/doc/netCategoryPlan/';
const backendURL = '/api/merezha/merezhaplanheader/';
const name = 'Зведення планових показників за мережею, штатами і контингентами (Додаток 3)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
