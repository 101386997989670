import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={9}>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'executive'}
          highlighted={highlights.includes('executive')}
          onToggleHighlight={() => onToggleHighlight('executive')}
        >
          {fields.executive.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center"
          active={activeCol === 'sign_kind'}
          highlighted={highlights.includes('sign_kind')}
          onToggleHighlight={() => onToggleHighlight('sign_kind')}
        >
          {fields.sign_kind.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};
export default Header;
