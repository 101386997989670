import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import md from '../../../../../meta/cat/budget';
import { CiatAppContext } from '../../../../../providers';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { CatTableListerFactory } from '../../../../newLister/tableComponent';

const Selector = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(CatTableListerFactory());

function BudgetSelector({ noHierarchy, ...restProps }) {
  const { currentUser } = useContext(CiatAppContext);
  const isSuperUser = currentUser.is_superuser;

  return (
    <Selector noHierarchy={noHierarchy || !isSuperUser} {...restProps} />
  );
}

BudgetSelector.propTypes = {
  noHierarchy: PropTypes.bool,
};

BudgetSelector.defaultProps = {
  noHierarchy: false,
};

export default BudgetSelector;
