import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const ErroredTooltip = styled(Tooltip)`
  &>.tooltip-inner{
    background: var(--backgroundError);
    color: var(--colorError);
    // border-color: #f5c6cb;
  }
  &>.arrow::before{
    border-left-color: #f5c6cb;
  }
`;

export default Tooltip;
