import React from 'react';

function UnCheckAllIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="2" width="8" height="8" rx="1.5" stroke="#a9a5a5" fill=" #ffffff80" />
      <rect x="6" y="10.5" width="8" height="8" rx="1.5" stroke="#a9a5a5" fill=" #ffffff80" />
    </svg>
  );
}

export default UnCheckAllIcon;
