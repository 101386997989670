/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from './printCP';

import DBFModal from './DBFModal';

function APMenu({
  id, data, actions, ...rest
}) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <DocEditorCommandPanel
      id={id}
      data={data}
      actions={actions}
      {...rest}
    >
      <PrintModal id={id} />
      <ButtonGroup className="ms-1">
        <DBFModal selectedRows={selectedRows} />
      </ButtonGroup>
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({
    onSR: PropTypes.func.isRequired,
  }).isRequired,
};

export default APMenu;
