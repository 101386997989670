/* eslint-disable no-unused-vars */
import React, {
  memo, useCallback, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { StyledTh } from '../styles';
import { debounce } from '../../../../../api/utils';

function CatTableListerHeader({
  columns,
  onSetOrder,
  order,
  columnSizes,
  onSetColumnSizes,
  permissions,
  maxItemLevel,
}) {
  const ref = useRef();
  const sizes = useRef({});
  const onResize = useCallback(
    () => {
      const node = ref.current;
      if (node) {
        sizes.current = [...node.querySelectorAll('th[data-col]:not(:last-child)')].reduce(
          (R, th) => ({
            ...R,
            [th.dataset.col]: `${th.getBoundingClientRect().width}px`,
          }),
          {},
        );
        onSetColumnSizes(sizes.current);
      }
    },
    [onSetColumnSizes],
  );
  const observer = useMemo((() => new ResizeObserver(debounce(onResize))), [onResize]);
  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const node = ref.current;
      if (columns && node) {
        sizes.current = columns.reduce((R, col) => ({ ...R, [col.key]: columnSizes[col.key] }), {});
        node.querySelectorAll('[data-col]:not(:last-child)').forEach(
          (subnode) => observer.observe(subnode),
        );
        return () => {
          observer.disconnect();
        };
      }
    },
    [columnSizes, columns, observer, onSetColumnSizes],
  );
  return (
    <thead>
      <tr ref={ref}>
        {permissions.canHierarchy && (
        <StyledTh style={{ width: `${maxItemLevel + 1 + 2.5}rem` }}>&nbsp;</StyledTh>
        )}
        {columns.map((dc, i) => (
          <StyledTh
            key={dc.key}
            data-col={dc.key}
            width={i === (columns.length - 1) ? 'auto' : columnSizes[dc.key]}
            orderable={dc.orderable}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */}
            <span onClick={(e) => dc.orderable && onSetOrder(e, dc.key)}>
              {dc.label}
            </span>
            {order.column === dc.key && order.isAscending && (
              <FontAwesomeIcon
                icon={faAngleUp}
                onClick={(e) => dc.orderable && onSetOrder(e, dc.key)}
              />
            )}
            {order.column === dc.key && !order.isAscending && (
              <FontAwesomeIcon
                icon={faAngleDown}
                onClick={(e) => dc.orderable && onSetOrder(e, dc.key)}
              />
            )}
          </StyledTh>
        ))}
      </tr>
    </thead>
  );
}

CatTableListerHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    orderable: PropTypes.bool,
  })).isRequired,
  onSetOrder: PropTypes.func.isRequired,
  order: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
  columnSizes: PropTypes.shape(),
  onSetColumnSizes: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
  }).isRequired,
  maxItemLevel: PropTypes.number,
};

CatTableListerHeader.defaultProps = {
  columnSizes: {},
  maxItemLevel: 0,
};

export default memo(CatTableListerHeader);
