import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Card, Container, Row, Accordion, Col,
} from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionBody from 'react-bootstrap/AccordionBody';
import api from '../../../api/req';
import { CiatAppContext } from '../../../providers';
import UpdateRole from './updateRole';
import { Loader } from '../../../components/bootStrap';
import IconAlert from '../../../components/blanks/common/IconAlert';
import SoftBadge from '../../../components/blanks/common/SoftBadge';

function Editor() {
  const { auth } = useContext(CiatAppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [list, setList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [roleId, setRoleId] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const load = useCallback(
    async () => {
      const r = await api.get('/api/auth/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );
  const loadPermissions = useCallback(
    async () => {
      const r = await api.get('/api/auth/permissions/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );

  const reload = useCallback(() => {
    onLoadStart();
    load()
      .then((rData) => {
        setList(rData);
        return loadPermissions();
      })
      .then((rData) => {
        setPermissions(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [load, loadPermissions, onError, onLoadEnd, onLoadStart]);

  useEffect(() => {
    reload();
  }, [reload]);

  const deleteRole = useCallback((id) => {
    const loader = async () => {
      const r = await api.delete(`/api/auth/role/${id}`, auth);
      if (!r.ok) throw new Error(`${r.status}: ${r.statusText}`);
      return true;
    };
    onLoadStart();
    loader()
      .then(() => load())
      .then((rData) => setList(rData))
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, load, onError, onLoadEnd, onLoadStart]);

  const handleOpenedUpdatePage = useCallback((id) => {
    setRoleId(id);
    setEditmode(true);
  }, []);
  const handleOpenedCreatePage = useCallback(() => {
    setRoleId(null);
    setEditmode(true);
  }, []);
  const dPerms = useMemo(
    () => permissions.reduce((R, p) => ({ ...R, [p.key]: p.label }), {}),
    [permissions],
  );

  return (
    <>
      {loading && <Loader />}
      {err && (
        <IconAlert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </IconAlert>
      )}
      <Accordion activeKey={editmode ? '1' : '0'}>
        <AccordionItem eventKey="1">
          <AccordionBody>
            <h2 className="text-primary">
              {!roleId ? 'Створення ролі' : 'Редагування ролі'}
            </h2>
            {editmode && (
            <UpdateRole
              onClose={() => setEditmode(false)}
              onRefresh={reload}
              onSetErr={setErr}
              onSetLoading={setLoading}
              roleId={roleId}
              allPermissions={permissions}
            />
            )}
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="0">
          <AccordionBody>
            <h2 className="text-primary">Перелік ролей:</h2>
            <Container fluid>
              <Row cols={3} className="g-3">
                {list.map((el) => (
                  <Col key={el.id}>
                    <Card className="h-100">
                      <Card.Header>
                        <Card.Title>
                          {el.name}
                        </Card.Title>
                        <p className="text-muted small">
                          {el.description}
                        </p>
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex flex-wrap gap-2">
                          {Object.keys(el.permissions).map((p) => (
                            <SoftBadge bg={el.permissions[p] ? 'success' : 'danger'} key={p}>
                              {dPerms[p]}
                            </SoftBadge>
                          ))}
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex gap-2 justify-content-end">
                        <Button variant="falcon-success" onClick={() => handleOpenedUpdatePage(el.id)}>Редагувати</Button>
                        <Button variant="falcon-danger" onClick={() => deleteRole(el.id)}>Видалити</Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
            <Button variant="falcon-primary" onClick={handleOpenedCreatePage} className="mt-2">
              Створити нову роль
            </Button>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default Editor;
