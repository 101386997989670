import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';

/**
                      choiceSettings={choiceSettings}
                      value={value}
                      onSelect={this.onSelect}

* */

const CSUSelector = ListerFactory({
  modelType: 'cat',
  modelName: 'csu',
  viewType: 'selector',
})(CatTableListerFactory({}));

export default CSUSelector;
