import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { kekvClassifier as md } from '../../../../constants/meta/catalogs/kekvClassifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const fundsKEKVKinds = {
  assignment: { value: 0, display_name: 'Асигнування' },
  crediting: { value: 1, display_name: 'Кредитування' },
};

function KEKVClassifier({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={4}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AllOthers-${data.id}`}
            label={md.columns.AllOthers.label}
            value={data[md.columns.AllOthers.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AllOthers.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AllowNegativeBalance-${data.id}`}
            label={md.columns.AllowNegativeBalance.label}
            value={data[md.columns.AllowNegativeBalance.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AllowNegativeBalance.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <h6 className="font-weight-bold text-primary">
        Код та назва, станом на вказану дату
      </h6>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateKl.label}
            value={data[md.columns.DateKl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateKl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.OnDate.label}
            value={data[md.columns.OnDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.OnDate.name]: value,
            })}
            readOnly={readOnly}
            maxLength={4}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.KEKVType.label}
            value={data[md.columns.KEKVType.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KEKVType.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(fundsKEKVKinds)}
          />
        </Col>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="kekvClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

KEKVClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KEKVClassifier;
