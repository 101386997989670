import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';

import DataTP from './tablepart/Data';

import DateRange from '../../../components/fields/DateRangeField';
import {
  StyledLabel,
  ThreeColumnsGridContainer,
  CommandPanelColor,
  ContainerButton,
  FrameDiv,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import Tabs from '../../../components/tabs/Tabs';
import StyledTPPane from '../../../components/tableParts/TPPane';
import CPDocs from './tablepart/cpDocs';
import storePathParam from '../../../common/storePathParam';
import { ItemField } from '../../field/selectorField/item';

const md = meta.dp.docSelectionForecast;

const TABLENAME = 'docs';

const ThreeColumns = styled(ThreeColumnsGridContainer)`
  margin: 10px 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2%;
`;
const ContainerBut = styled(ContainerButton)`
  margin: 20px 0 5px 0;
`;

class DocSelectionForecastEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    onOK: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
    onOK: null,
  };

  constructor(props) {
    super(props);
    console.warn('This component was deprecated!!!!!!!');
  }

  loadHandler = () => {
    const { onOK } = this.props;
    if (onOK) {
      onOK((store) => ({
        documents: store.getIn([`dp/${md.name}/dpEditor`, 'tables/docs'], new List()).reduce((r, row) => (row.get('appr', false) ? [...r, row.get('docs')] : r), []),
        period: store.getIn([`dp/${md.name}/dpEditor`, 'headerForm', 'period']),
        FI: store.getIn([`dp/${md.name}/dpEditor`, 'headerForm', 'FI']),
      }));
    }
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;
    return (
      <FrameDiv>
        <div>
          <StyledLabel>{md.columns.FI.label}</StyledLabel>
          <ItemField
            value={headerForm.get('FI', new Map())}
          />
        </div>
        <ThreeColumns>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRange
              value={headerForm.get('period', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'period'], value));
              }}
            />
          </div>
        </ThreeColumns>
        <ContainerBut>
          <CommandPanelColor
            label="1. Відібрати"
            onClick={() => dispatch(actions.processServerReq('FILL_TABLE'))}
          />
          <CommandPanelColor
            label="2. Завантажити"
            onClick={this.loadHandler}
          />
        </ContainerBut>
      </FrameDiv>
    );
  }
}

function TablesRender({ dispatch }) {
  return (
    <Tabs>
      <DivWithPropsLabel label="Документи">
        <StyledTPPane>
          <CPDocs tableName={TABLENAME} dispatch={dispatch} />
          <DataTP />
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="" empty disabled />
    </Tabs>
  );
}

TablesRender.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
const mapStateTP = (store) => ({
  thisIsSecialFond: store.getIn([storePathParam(store).name, storePathParam(store).id, 'headerForm', 'thisIsSecialFond'], false),
});

const connectedTablesRender = connect(mapStateTP)(TablesRender);

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

// TODO: This component deprecated. You'll use docSelectionBoostraped and will remove this after refactor
//  BudgetRequest && changeToAssigmentPlaning
export default enchance(DocSelectionForecastEditor);
