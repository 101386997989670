import React, {
  Children, cloneElement, useMemo, useState,
} from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { StyledLabel } from '../../../../components/Form/styledForm';
import DateInput from '../../../../components/fields/dates';

const dateParam = 'НаДату';

function ElementListerSelector({
  filter, params, children, ...restProps
}) {
  const [currentDate, setCurrentDate] = useState(() => params.filter(
    (p) => p.name === dateParam,
  ).reduce((R, p) => p.value, new Date().valueOf()));

  const prm = useMemo(
    () => [
      ...params.filter((p) => p.name !== dateParam),
      {
        name: dateParam,
        value: currentDate,
      },
    ],
    [currentDate, params],
  );

  const CH = cloneElement(Children.only(children), {
    ...restProps,
    params: prm,
  });

  return (
    <>
      <Row>
        <Col>
          <StyledLabel>Показувати на дату</StyledLabel>
          <DateInput
            value={currentDate}
            onChange={(e, v) => setCurrentDate(v)}
          />
        </Col>
      </Row>
      {CH}
    </>
  );
}

ElementListerSelector.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  params: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.instanceOf(Map),
    ]),
  })),
  children: PropTypes.element.isRequired,
};

ElementListerSelector.defaultProps = {
  filter: [],
  params: [],
};

export default ElementListerSelector;
