import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  budgetType: { label: 'Вид бюджету', name: 'ВидБюджета', visible: false },
  KFKAccordance: {
    label: 'Включити аналіз відповідність КФК в заявках на фінансування',
    name: 'ВключитьАнализСоответствияКФКВЗаявках',
    visible: false,
  },
  FBRSubsystem: {
    label: 'Включити підсистему "Фінансування бюджету розвитку"',
    name: 'ВключитьПодсистемуФБР',
    visible: false,
  },
  EnergyCarriers: {
    label: 'Включити підсистему "Енергоносії"',
    name: 'ВключитьПодсистемуЭнергоносители',
    visible: false,
  },
  ForbidChangesForScraps: {
    name: 'ЗапретитьОстаткиПоРаспорядителюВерхнегоУровня',
    label: 'Заборонити залишки за розпорядником вищого рівня',
    visible: false,
  },
  FinRequestToOrg: {
    label: 'Заявка на фінансування "до установи"',
    name: 'ЗаявкаНаФинансированиеДоОрганизации',
    visible: false,
  },
  IdMinFin: { label: 'Ид мин фин', name: 'ИдМинФин', visible: false },
  BudgetCode: { label: 'Код бюджету', name: 'КодБюджета', visible: false },
  IASCode: {
    label: 'Код у назві файла для завантаження у ИАС',
    name: 'КодИАС',
    visible: false,
  },
  TreasuryCode: { name: 'КодКазны', label: 'Номер бюджету', visible: true },
  CodeTLTerra: {
    name: 'КодТЛТерра',
    label: 'Код за TL_TERRA',
    visible: true,
  },
  CodeFileSocialPayment: {
    name: 'КодФайлСоцвыплаты',
    label: 'Код Файл Соц. выплаты',
    visible: false,
  },
  BankMaskFile: {
    name: 'МаскаФайловБанк',
    label: 'Маска файлів банківської виписки',
    visible: false,
  },
  BankMaskFileIncome: {
    name: 'МаскаФайловДох',
    label: 'Маска казнач. файлів за доходами',
    visible: false,
  },
  BankMaskFileOutcome: {
    name: 'МаскаФайловРасх',
    label: 'Маска казнач. файлів за видатками',
    visible: false,
  },
  BudgetName: {
    name: 'НаимБюджета',
    label: 'Найменування Бюджету',
    visible: false,
  },
  BudgetNameGenitive: {
    name: 'НаимБюджетаРодитПадеж',
    label: 'Найменування бюджету (родовий відмінок)',
    visible: false,
  },
  DontCheckForCommitment: {
    name: 'НеПроверятьФинОбязательстваНаУтвержденность',
    label: "Не перевіряти фінансові зобов'язання на затвердженість",
    visible: false,
  },
  UnderFunded: {
    name: 'ОтображатьНедофинансировано',
    label: 'Показувати залишок з урах.непроф.заявок',
    visible: false,
  },
  PrintNumbContract: {
    label: 'Друкувати номер договору юридичних та фин. обязательств',
    name: 'ПечатьНомерДоговораЮрИФинОб',
    visible: false,
  },
  CheckPaymentTypeFulfilment: {
    label: 'Перевіряти заповнення поля "Вид платежу"',
    name: 'ПроверятьЗаполнениеВидаПлатежа',
    visible: false,
  },
  CheckAccordancePlanCSU: {
    label: 'Перевіряти відповідність планів планам розпорядника вищого рівня',
    name: 'ПроверятьСоответствиеПлановРаспорядителюВерхнегоУровня',
    visible: false,
  },
  CheckAccordanceTemplateNote: {
    label: 'Перевіряти примітку на відповідність шаблону',
    name: 'ПроверятьСоответствиеПримечанияШаблону',
    visible: false,
  },
  Path: {
    label: 'Шлях до файлів для завантаження',
    name: 'Путь',
    visible: false,
  },
  Region: { label: 'Регіон/Область', name: 'Регион', visible: false },
  FinResponsibilitiesManualInput: {
    label: 'Внесення фін. зобов\'язань "вручну"',
    name: 'РучноеВнесениеФинансовыхОбязательств',
    visible: false,
  },
  OnlyByScrapsOfSteward: {
    label: 'Розраховувати залишки тільки за розпорядниками вищого рівня',
    name: 'ТолькоПоОстаткамРаспорядителяВерхнегоУровня',
    visible: false,
  },
  ShowSourcesInFinDocs: {
    label: 'Вказувати джерела в документах фінансування',
    name: 'УказватьИсточникиВДокументахФинансрирования',
    visible: false,
  },
  AccountingByPartners: {
    label:
      'Облік фінансування за контрагентами (без перевірок на затвердження реєстрів)',
    name: 'УчетПоКонтрагентам',
    visible: false,
  },
  AccountForBalancesOnTreasuryAccounts: {
    label: 'Враховувати залишки на казн. рахунках для документів фінансування',
    name: 'УчитыватьОстаткиНаКазначейскихСчетах',
    visible: false,
  },
  FinancialInstitution: {
    label: 'Фінансовий орган',
    name: 'ФинОрган',
    visible: false,
  },
  TurnTaskPCMFilterOn: {
    label: 'Включити фільтр по завданням бюджетних програм за КБП',
    name: 'флВключитьФильтрПоЗаданиямПЦМ',
    visible: false,
  },
  TurnScrapsControlOnAccountsOff: {
    label:
      "Відключити контроль залишків на рахунках у платежному дорученні, фін.зобов'язаннях",
    name: 'флОтключитьКонтрольОстатковНаСчетахДляПлатежки',
    visible: false,
  },
  VinnitsyaMode: {
    label: '"Складний" режим фінансування (Вінниця)',
    name: 'флРежимВинница',
    visible: false,
  },
  AccountingForNotesByNotes: {
    label: 'Облік фінансування у розрізі приміток',
    name: 'флУчетЗаявокПоПримечаниям',
    visible: false,
  },
  SalaryFactCurYear: {
    label: 'Внесення факту видатків поточного року у Бюжджетному запиті',
    name: 'флФактЗпТекГода',
    visible: false,
  },
  SupplierInRequest: {
    label: 'Показувати "Постачальник" в "Заявці"',
    name: 'флПоставщикВЗаявке',
    visible: false,
  },
  Yakoi: {
    label: 'Якой',
    name: 'Якой',
    visible: false,
  },
  Yakij: {
    label: 'Який',
    name: 'Який',
    visible: false,
  },
  TurnBuildObjOn: {
    label: 'Включити підсистему "Об\'єкти будівництва"',
    name: 'ВключитьОбъектыСтроительства',
    visible: false,
  },
  flAddTargetProg: {
    label: 'Дадавати цільову програму до плану асигнувань та змін',
    name: 'флДобавлятьЦелевуюПрограммуВПланы',
    visible: false,
  },
  flReqReceivedByFD: {
    label: 'Завантажувати тільки заявки, профінансовані ФД',
    name: 'флЗагружатьТолькоПрофЗаявкиФУ',
    visible: false,
  },
  TransitionToOB: {
    label: 'Період переходу "Об\'єкти будівництва"',
    name: 'флПереходНаОС',
    visible: false,
  },
  flLimitDateOfRequestLoad: {
    label: 'Обмежити дату затягування заявок',
    name: 'флОграничитьДатуЗатягиванияЗаявок',
    visible: true,
  },
  dayAmountOfRequestLoad: {
    label: 'Кількість днів для затягування заявок',
    name: 'КолДнейЗатягиванияЗаявок',
    visible: true,
  },
  ShowSourcesFinInDocFin: {
    label: 'Виводити джерела фінансування в фін. док-ах ',
    name: 'ВыводитьИсточникиФинВДокФин',
    visible: false,
  },
  SignByRegion: {
    label: 'Документи затверджуються областю',
    name: 'флДокУтвОбластью',
    visible: false,
  },
  includeDocumentClose: {
    label: 'Включити можливість закриття документів',
    name: 'ВключитьВозможностьЗакрытияДокумента',
    visible: false,
  },
  includeCreditSystem: {
    label: 'Уквімкнути підсистему Кредитування',
    name: 'ВключитьПодсистемуКредитование',
    visible: false,
  },
  includeTaxCommerce: {
    label: 'Уквімкнути підсистему Власні надходження в комерційних банках',
    name: 'ВключитьПодсистемуСобственныеПоступленияВКомерческихБанках',
    visible: false,
  },
  outputTable14: {
    label: 'Виводити таблицю 14 у бюджетному запиті навіть якщо вона пуста',
    name: 'ВыводитьНулевыеЗаписиДебиторскойЗадолженностиВФорме2БЗ',
    visible: false,
  },
  cityName: {
    label: 'Місто у родовому відмінку',
    name: 'ГородВРодительномПадеже',
    visible: false,
  },
  edrpou_fo: {
    label: 'ЄДРПОУ Установи, що здійснює функцію виконавчого органу',
    name: 'ЄДРПОУ_ФО',
    visible: false,
  },
  useDocForLimits: {
    label: 'Выкористовувати документ Граничний обсяг',
    name: 'ИспДокГраничныйОбъем',
    visible: false,
  },
  baseBudget: {
    label: 'Базовий бюджет',
    name: 'БазовыйБюджет',
    visible: false,
  },
  codeFileSocpay: {
    label: 'Код файл срцвыплаты',
    name: 'КодФайлСрцвыплаты',
    visible: false,
  },
  codeFileBankOst: {
    label: 'Маска файлів банк залишки',
    name: 'МаскаФайловБанкОст',
    visible: false,
  },
  nameSession: {
    label: 'Назва бюджету до рішення сесії',
    name: 'НазваБюджету',
    visible: false,
  },
  doNotCheck: {
    label: 'Не перевіряти заповнення адреси в об\'єктах будівництва',
    name: 'НЕПроверятьАдресВОС',
    visible: false,
  },
  includeAutoNumberingFin: {
    label: 'Включити автонумерацію фінансування',
    name: 'НомероватьАвто',
    visible: false,
  },
  turnOffControlAccount: {
    label: 'Відключити контроль рахунків фінорганом  в заявках на фінансування',
    name: 'ОтключитьКонтрольСчетовФинорганом',
    visible: false,
  },
  fullNameFin: {
    label: 'Найменування установи, що здійснює функцію виконавчого органу',
    name: 'ПолнНаименованиеФин',
    visible: false,
  },
  excessSumCassExp: {
    label: 'Перевищення суми касових видатків у документі Фінансування',
    name: 'ПревышениеСуммыКассовыхРасходовВФинансировании',
    visible: false,
  },
  allowThinClient: {
    label: 'Дозволити доступ тонкого клиента',
    name: 'РазрешитьВходТонкогоКлиента',
    visible: false,
  },
  includeAnalyticssession: {
    label: 'Включити підсистему Аналіз видатків рішень сесії за роками',
    name: 'флАнализРасхСесииПоГодам',
    visible: false,
  },
  blockApplication: {
    label: 'Блокування заявок',
    name: 'флБлокировкаЗаявок',
    visible: false,
  },
  includePlansLowLevel: {
    label: 'Включити перевірку планів для нижчого рівня',
    name: 'флВключитьПроверкуПлановНижнУр',
    visible: false,
  },
  turnOffcheckObligation: {
    label: 'Не враховувати юр.зобов\'язання при проведенні планів',
    name: 'флВыклПроверкуЮрОбязВИзмАссигн',
    visible: false,
  },
  useNameForSession: {
    label: 'Використовувати назву для рішень сессії',
    name: 'флИспользованьНазваниеДляРешенийСессии',
    visible: false,
  },
  turnOffCheckDublesAcc: {
    label: 'Не перевіряти рахунки на дублі',
    name: 'флНеПроверятьСчетаНаДубли',
    visible: false,
  },
  allowOutput: {
    label: 'Дозволити вивід в Exsel (Бюджетний запит та звіт з виконання)',
    name: 'флРазрешитьВывод',
    visible: false,
  },
  allowMinus: {
    label: 'Дозволити знак мінус у документах фінансування',
    name: 'флРазрешитьМинусаВДокФин',
    visible: false,
  },
  allowEmptyField: {
    label: 'Дозволити не заповнювати поле "Постачальники" у довіднику "Постачальники розпорядників" ',
    name: 'флРазрешитьПустоеПолеКонтрагенты',
    visible: false,
  },
  regimeOdesa: {
    label: 'Режим Одесса (БЗ без підпрограм)',
    name: 'флРежимОдесса',
    visible: false,
  },
  consolidated: {
    label: 'Створення додаткового документу для аналізу зведеного бюджету області',
    name: 'флСводБюджет',
    visible: false,
  },
  LIGAform: {
    label: 'Форми_документів_по_ЛИГА',
    name: 'флФормыЛИГА',
    visible: false,
  },
  allowChangeContragent: {
    label: 'Дозволити змінювати контрагента у фінансовому зобов\'язанні',
    name: 'РазрешитьИзменениеКонтрагентаВФинансовомОбязательстве',
    visible: false,
  },
  CheckApplicationDate: {
    label: 'Перевіряти дату заявки (дата не менше поточної)',
    name: 'флПроверятьДатуЗаявки',
    visible: false,
  },
  nameOB: {
    label: 'Добавляти в примітку фінансування назву Об\'єкта будівництва( перед словом "КПКВ")',
    name: 'УДАЛфлДобавитьВПримечаниеНазваниеОС',
    visible: false,
  },
  checkCreatorOfFin: {
    label: 'Перевіряти при збереженні розподілу , чи створене розпорядження ',
    name: 'флПроверятьСоздРаспорПередРаспред',
    visible: false,
  },
  countBudget: {
    label: 'Перечисление бюджет',
    name: 'ПеречислениеБюджет',
    visible: false,
  },
  overwriteLogica: {
    label: 'Знімати ознаку затвердження з раніше надісланих документів в  ЛОГИКА',
    name: 'ПерезаписьДокументовВЛОГИКА',
    visible: false,
  },
  includeFin: {
    label: 'Включити платіжні дор., юр./фін. забов\'язання',
    name: 'флВключитьЮрФинПлатежки',
    visible: false,
  },
  usePCM: {
    label: 'Використовувати довідник показників з  ПЦМ при внесені даних підготовки до прогнозу',
    name: 'ИспользоватьСправочникиПЦМ',
    visible: false,
  },
  editPCMDocs: {
    label: 'Редагування підстав в затверджених документах ПЦМ',
    name: 'флРедактированиеОснованийВУтвержденныхДокументах',
    visible: false,
  },
  maskFileReports: {
    label: 'Маска казнач. файлів за щомісячною звітністю',
    name: 'МаскаФайловОтчет',
    visible: false,
  },
  maskSumBFN: {
    label: 'Перевіряти відповідність сум БФН та планів',
    name: 'флСравниватьПланыИБФН',
    visible: false,
  },
  includeBFN: {
    label: 'Уквімкнути підсистему "БФН"',
    name: 'ВключитьПодсистемуБФН',
    visible: false,
  },
  addProc: {
    label: 'Дадавати відсоток готовності, термін, вартість в елемент КПКВ',
    name: 'флДобавлятьПроцентГотовностиСтоимостьСрокВБП',
    visible: false,
  },
  includeDictIndicators: {
    label: 'Використовувати довідник показників досягнення цілей при роботі з формою-1 бюджетного запиту',
    name: 'ИспользоватьСправочникПоказателиДостиженияЦелейДляБюджетныхЗапросов',
    visible: false,
  },
  checkFillFields: {
    label: 'Перевіряти заповнення юридичних зобов\'язань',
    name: 'флПроверятьЗаполПолейЮрОбяз',
    visible: false,
  },
  turnOffCopy: {
    label: 'Вимкнути копіювання заявок',
    name: 'флОтключитьКопированиеЗаявок',
    visible: false,
  },
  PCMbyKBP7: {
    label: 'Вводити ПЦМ за класифікатором КБП7',
    name: 'флПЦМпоКБП7',
    visible: false,
  },
  noteLong: {
    label: 'Кількість символів примітки у фін.док.',
    name: 'ДлинаПримечания',
    visible: false,
  },
  newInterface: {
    label: 'Робота в новому інтерфейсі',
    name: 'флНовыйИнтерфейс',
    visible: false,
  },
  expensesOnHealthPrintingKyiv: {
    label: 'Заборона проведення документу "Інформація про бюджетні видатки на охорону здоров\'я"',
    name: 'ИнформацияОРасходахНаЗдоровъя',
    visible: false,
  },
  forbidUnexecutingBRPrinting: {
    label: 'Заборонити друк непроведеного бюджетного запиту',
    name: 'флЗапретитьПечатьНепроведенногоБЗ',
    visible: false,
  },
  checkPlansByIncome: {
    label: 'Перевіряти залишки планів в розрізі доходів',
    name: 'флПроверкаПлановВРазрезеДоходов',
    visible: false,
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const budget = {
  label: 'Бюджети',
  name: 'budget',
  backendName: 'Города',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables: {},
  footer,
  frontend: 'cat/budget',
  // Remove duplicates
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
  // foldersOnTop: true,
};

export default budget;
