import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/garanty';

const meta = md.tables.TABL1;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG1S'}
              highlighted={highlights.includes('T1RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG1S')}
            >
              {meta.columns.T1RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG2'}
              highlighted={highlights.includes('T1RXXXXG2')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG2')}
            >
              {meta.columns.T1RXXXXG2.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG5S'}
              highlighted={highlights.includes('T1RXXXXG5S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG5S')}
            >
              {meta.columns.T1RXXXXG5S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG4S'}
              highlighted={highlights.includes('T1RXXXXG4S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG4S')}
            >
              {meta.columns.T1RXXXXG4S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG3S'}
              highlighted={highlights.includes('T1RXXXXG3S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG3S')}
            >
              {meta.columns.T1RXXXXG3S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG4'}
              highlighted={highlights.includes('T1RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG4')}
            >
              {meta.columns.T1RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG6'}
              highlighted={highlights.includes('T1RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG6')}
            >
              {meta.columns.T1RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG7'}
              highlighted={highlights.includes('T1RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG7')}
            >
              {meta.columns.T1RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          {/*<Col>*/}
          {/*  <TableHeaderCell*/}
          {/*    className="text-center text-xl-left"*/}
          {/*    active={activeCol === 'T1RXXXXG8'}*/}
          {/*    highlighted={highlights.includes('T1RXXXXG8')}*/}
          {/*    onToggleHighlght={() => onToggleHighlght('T1RXXXXG8')}*/}
          {/*  >*/}
          {/*    {meta.columns.T1RXXXXG8.label}*/}
          {/*  </TableHeaderCell>*/}
          {/*</Col>*/}
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG9S'}
              highlighted={highlights.includes('T1RXXXXG9S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG9S')}
            >
              {meta.columns.T1RXXXXG9S.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
