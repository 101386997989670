import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import Signer from './signer';
import api from '../../api/req';
import useEU from '../../ciatEU/hook';
import { uint8array } from './utils';

function NewLogicaAuth({ onLogin }) {
  const {
    readkeyFile, initialized, cert, signData, err, loading, setErr, setLoading, onClearKey,
    libraryType, setLibraryType, keyMedias, readKeyMedia, selectedKM, setSelectedKM, issuers, issuer, setIssuer,
    needCertificate, certificates, setCertificates, reloadKeyMedia,
  } = useEU();
  const auth = useCallback(
    () => {
      const logicaGet = async () => {
        const r = await api.get('logica/login/');
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      const sign = async ({ stringToSign, requestId }) => {
        const str = uint8array(stringToSign);
        const signed = await signData(str, true);
        return { requestId, signed };
      };
      const logicaPost = async ({ requestId, signed }) => {
        const r = await api.post('logica/login/', { body: JSON.stringify({ requestId, signBase64: signed }) });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setErr(null);
      setLoading(true);
      logicaGet()
        .then(({ stringToSign, requestId }) => sign({ stringToSign, requestId }))
        .then(({ requestId, signed }) => logicaPost({ requestId, signed }))
        .then((result) => {
          if (!result.success) {
            throw new Error(`(${result.errorCode}) ${result.errorMessage} - ${result.errorDescription}`);
          }
          onLogin(result.ticket);
        })
        .catch((e) => setErr({ type: 'danger', message: e.message }))
        .finally(() => setLoading(false));
    },
    [onLogin, setErr, setLoading, signData],
  );
  return (
    <Container>
      <h3 className="text-primary">
        <FontAwesomeIcon icon={faLock} className="me-2" />
        Авторизація у Logica
      </h3>
      <Signer
        submitTitle="Авторизуватись"
        loading={loading}
        err={err}
        initialized={initialized}
        onReadKeyFile={readkeyFile}
        onReadKeyMedia={readKeyMedia}
        cert={cert}
        onSubmit={auth}
        onReset={onClearKey}
        libraryType={libraryType}
        onSetLibraryType={setLibraryType}
        keyMedias={keyMedias}
        selectedKM={selectedKM}
        setSelectedKM={setSelectedKM}
        issuers={issuers}
        issuer={issuer}
        setIssuer={setIssuer}
        needCertificate={needCertificate}
        certificates={certificates}
        setCertificates={setCertificates}
        reloadKeyMedia={reloadKeyMedia}
      />
    </Container>
  );
}

NewLogicaAuth.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default NewLogicaAuth;
