import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'Период' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  printNote: { label: 'Друкувати примітки користувача', name: 'ВыводитьПримечания' },
  showLevel1: { label: 'Відображати суми головного розпорядника 1 рівня', name: 'Показывать1Уровень' },
  showLevel2: { label: 'Відображати суми головного розпорядника 2 рівня', name: 'Показывать2Уровень' },
  showLevel3: { label: 'Відображати суми головного розпорядника 3 рівня', name: 'Показывать3Уровень' },
  showLevel4: { label: 'Відображати суми головного розпорядника 4 рівня', name: 'Показывать4Уровень' },
};

const definition = {
  label: 'Зведена завка на фінансування КМДА',
  name: 'PivotRequestKMDA',
  columns,
  frontend: 'rep/PivotRequestKMDA',
  backendName: 'ЗведенаЗаявкаНаФінансуванняКМДА',
  resultColumns: baseResultColumns,
};

export default definition;
