import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualTransfert';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'transfertTo';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
  FI, onSR,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onKDBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFK.name]: v });
      await onSR('CHANGE_KFK_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col style={{ width: '300px' }}>
            <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}>
              <ItemPicker
                value={row[tableMD.columns.elementKFK.name]}
                modelType="cat"
                modelName="elementKFK"
                onChange={onKDBChange}
                filter={FIOwnerFilter}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="KBP7Code" highlighted={highlights.includes('KBP7Code')}>
              <TextInput
                value={row[tableMD.columns.KBP7Code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.KBP7Code.name]: v })}
                readOnly={readOnly}
                rows={1}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="budget" highlighted={highlights.includes('budget')}>
              <ItemPicker
                value={row[tableMD.columns.budget.name]}
                modelType="cat"
                modelName="budgetTransfer"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.budget.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf" highlighted={highlights.includes('zf')}>
              <NumberInput
                value={row[tableMD.columns.zf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.zf.name]: v,
                  [tableMD.columns.sum.name]: row[tableMD.columns.sf.name] + v,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sf" highlighted={highlights.includes('sf')}>
              <NumberInput
                value={row[tableMD.columns.sf.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, {
                  [tableMD.columns.sf.name]: v,
                  [tableMD.columns.sum.name]: row[tableMD.columns.zf.name] + v,
                })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.zf.name]: PropTypes.number,
    [tableMD.columns.sf.name]: PropTypes.number,
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.KBP7Code.name]: PropTypes.string,
    [tableMD.columns.budget.name]: referencePropType,
    [tableMD.columns.elementKFK.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  FI: referencePropType.isRequired,

};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
