import { OrderedMap, List } from 'immutable';
import windowsManagerActions from '../constants/actions/windowsManager';

export const addComponentToWindowsManager = (componentParams, currentComponent, currentPathName, windowsName, type, name, search) => (dispatch) => {
  dispatch({
    type: windowsManagerActions.ADD_COMPONENT_TO_WINDOWS_MANAGER,
    payload: {
      params: componentParams,
      currentComponent,
      currentPathName,
      windowsName,
      type,
      name,
      search,
    },
  });
};

export const switchWindow = (currentPathName) => (dispatch) => {
  dispatch({
    type: windowsManagerActions.SWITCH_WINDOW,
    payload: {
      currentPathName,
    },
  });
};

export const dellComponentFromWindowsManager = (currentPathName) => (dispatch, getState) => {
  const store = getState();
  const stack = store.getIn(['windowsManager', 'windowsStack'], new List());
  const current = store.getIn(['windowsManager', 'currentPathName'], new List());

  const componentDelPath = currentPathName === null ? store.getIn(['windowsManager', 'currentPathName'], '') : currentPathName;

  // Переключаем окошки только в том случае если закрываем текущее окно
  if (current === currentPathName) {
    dispatch(switchWindow(
      stack.filter((u) => u !== currentPathName).first(),
    ));
  }
  dispatch({
    type: windowsManagerActions.DEL_COMPONENT_FROM_WINDOWS_MANAGER,
    payload: {
      componentDelPath,
    },
  });
};

export const getNewPath = (store, stepWin = 0) => {
  const lcs = store.getIn(['windowsManager', 'linkComponents'], new OrderedMap());
  const currentPathName = store.getIn(['windowsManager', 'currentPathName'], '');

  const keys = lcs.keySeq();
  const idx = keys.findIndex((key) => key === currentPathName);
  let newPath = '';

  if (keys.size > 1) {
    if (stepWin > 0) newPath = keys.size > idx + stepWin ? keys.get(idx + stepWin) : keys.get(0);
    else newPath = idx + stepWin >= 0 ? keys.get(idx + stepWin) : keys.get(keys.size - 1);
  }

  return newPath;
};

export const nextWindow = () => (dispatch, getState) => {
  const store = getState();
  const newPath = getNewPath(store, 1);
  switchWindow(newPath);
};

export const prevWindow = () => (dispatch, getState) => {
  const store = getState();
  const newPath = getNewPath(store, -1);
  switchWindow(newPath);
};

export const hidenWindowsManager = () => (dispatch) => {
  dispatch({
    type: windowsManagerActions.HIDEN_WINDOWS_MANAGER,
    payload: {
      hidenWindowsManager: true,
    },
  });
};

// Посылает сигнал на обновление данных компоненту, зарегесрированному по указанному url
export const sendUpdateSignal = (url) => (dispatch) => dispatch({
  type: windowsManagerActions.UPDATE_SIGNAL,
  payload: {
    url,
  },
});

export const setWindowTitle = (url, title) => (dispatch) => dispatch({
  type: windowsManagerActions.SET_WINDOW_TITLE,
  payload: {
    url,
    title,
  },
});

export default {
  addComponentToWindowsManager,
  hidenWindowsManager,
  dellComponentFromWindowsManager,
  switchWindow,
  sendUpdateSignal,
  nextWindow,
  prevWindow,
};
