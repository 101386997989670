import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ETextInput } from '../../../../../../components/Controls/withErrors';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from '../factWorkerCategory/propTypes';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
  width: 100%
`;

function ResultAnalysisPage(props) {
  const {
    data, fields, errors, onChange,
  } = props;

  return (
    <>
      <StyledLabel>
        {fields.zf_result_analysis.help_text}
        <ETextInput
          value={data.zf_result_analysis}
          onChange={(e, v) => onChange({ zf_result_analysis: v })}
          readOnly={fields.zf_result_analysis.read_only}
          errors={errors.zf_result_analysis}
        />
      </StyledLabel>
      <StyledLabel>
        {fields.sf_result_analysis.help_text}
        <ETextInput
          value={data.sf_result_analysis}
          onChange={(e, v) => onChange({ sf_result_analysis: v })}
          readOnly={fields.sf_result_analysis.read_only}
          errors={errors.sf_result_analysis}
        />
      </StyledLabel>
    </>
  );
}

ResultAnalysisPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  onChange: PropTypes.func.isRequired,
};

ResultAnalysisPage.defaultProps = {
  errors: [],
};

export default ResultAnalysisPage;
