import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
};

export const regions = {
  label: 'Області',
  name: 'regions',
  backendName: 'Области',
  defaultOrder,
  columns,
  frontend: 'cat/regions',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default regions;
