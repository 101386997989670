import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/financingSources';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
  TypeTransfer,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        {TypeTransfer === 1 && (
        <Row className="border-bottom" noGutters />
        )}

        <Row className="border-bottom" noGutters>
          {TypeTransfer === 1 && (
          <Col sm={3}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'elementKFB'}
              highlighted={highlights.includes('elementKFB')}
              onToggleHighlght={() => onToggleHighlght('elementKFB')}
            >
              {meta.columns.elementKFB.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'elementKFBcred'}
              highlighted={highlights.includes('elementKFBcred')}
              onToggleHighlght={() => onToggleHighlght('elementKFBcred')}
            >
              {meta.columns.elementKFBcred.label}
            </TableHeaderCell>
          </Col>
          )}
          <Col sm={TypeTransfer === 1 ? 3 : 6}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'AnalyticalAccountCosts'}
              highlighted={highlights.includes('AnalyticalAccountCosts')}
              onToggleHighlght={() => onToggleHighlght('AnalyticalAccountCosts')}
            >
              {meta.columns.AnalyticalAccountCosts.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'AnalyticalAccount'}
              highlighted={highlights.includes('AnalyticalAccount')}
              onToggleHighlght={() => onToggleHighlght('AnalyticalAccount')}
            >
              {meta.columns.AnalyticalAccount.label}
            </TableHeaderCell>
          </Col>
          <Col sm={3}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'Remainder'}
              highlighted={highlights.includes('Remainder')}
              onToggleHighlght={() => onToggleHighlght('Remainder')}
            >
              {meta.columns.Remainder.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'Sum'}
              highlighted={highlights.includes('Sum')}
              onToggleHighlght={() => onToggleHighlght('Sum')}
            >
              {meta.columns.Sum.label}
            </TableHeaderCell>
          </Col>
          <Col sm={3}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'NoteDoc'}
              highlighted={highlights.includes('NoteDoc')}
              onToggleHighlght={() => onToggleHighlght('NoteDoc')}
            >
              {meta.columns.NoteDoc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'NoteCat'}
              highlighted={highlights.includes('NoteCat')}
              onToggleHighlght={() => onToggleHighlght('NoteCat')}
            >
              {meta.columns.NoteCat.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row className="border-bottom" noGutters>
          <Col sm={9}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'AccountForTransfer'}
              highlighted={highlights.includes('AccountForTransfer')}
              onToggleHighlght={() => onToggleHighlght('AccountForTransfer')}
            >
              {meta.columns.AccountForTransfer.label}
            </TableHeaderCell>
          </Col>
          <Col sm={3}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'WriteOffAccount'}
              highlighted={highlights.includes('WriteOffAccount')}
              onToggleHighlght={() => onToggleHighlght('WriteOffAccount')}
            >
              {meta.columns.WriteOffAccount.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-lg-left"
              active={activeCol === 'Note'}
              highlighted={highlights.includes('Note')}
              onToggleHighlght={() => onToggleHighlght('Note')}
            >
              {meta.columns.Note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  TypeTransfer: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  TypeTransfer: null,
};

export default memo(TPHeader);
