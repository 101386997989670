// eslint-disable-next-line import/no-cycle
import TableListerFactory from './table/table';
import { Doctablerow, Cattablerow } from './table/row';
import { DocListerCommandPanel, CatListerCommandPanel } from './commandpanel';
import { DocTableListerHeader, CatTableListerHeader } from './table/header';
import { DocCtxMenu, CatCtxMenu } from './contextMenu';

const DocTableLister = TableListerFactory({
  RowComponent: Doctablerow,
  HeaderComponent: DocTableListerHeader,
  CommandPanel: DocListerCommandPanel,
  ContextMenuComponent: DocCtxMenu,
});

/**
 *
 * @param components {{
 *   RowComponent: React.Component,
 *   HeaderComponent: React.Component,
 *   CommandPanel: React.Component,
 *   ContextMenuComponent: React.Component,
 * }}
 * @constructor
 */
const DocTableListerFactory = (components) => {
  const defaults = {
    RowComponent: Doctablerow,
    HeaderComponent: DocTableListerHeader,
    CommandPanel: DocListerCommandPanel,
    ContextMenuComponent: DocCtxMenu,
  };
  return TableListerFactory({ ...defaults, ...components });
};

const CatTableListerFactory = (components) => {
  const defaults = {
    RowComponent: Cattablerow,
    HeaderComponent: CatTableListerHeader,
    CommandPanel: CatListerCommandPanel,
    ContextMenuComponent: CatCtxMenu,
  };
  return TableListerFactory({ ...defaults, ...components });
};

// eslint-disable-next-line import/prefer-default-export
export {
  DocTableLister, DocTableListerFactory, CatTableListerFactory,
};
