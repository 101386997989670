import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівник', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  onlyFill: { label: 'Виводити тільки строки із заповненими значеннями', name: 'ТолькоЗаполн' },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденОбластью' },
};

/**
 * @const
 */
const definition = {
  name: 'netCategoryPrint',
  backendName: 'ПечатьМережаПоказатели',
  label: 'Зведення показників за мережею, штатами і контингентами',
  frontend: 'dp/netCategoryPrint',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
