import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from '../../../../components/blanks/common/Flex';
import barChart from '../../../../assets/img/illustrations/crm-bar-chart.png';
import Background from '../../../../components/blanks/common/Background';
import greetingsBg from '../../../../assets/img/illustrations/ticket-greetings-bg.png';
import { EditorControls } from '../../../basicEditor/editorControls';

function GreetingOBCard({
  cDt, setDt, title, desc,
}) {
  return (
    <Card className="bg-100 shadow-none border">
      <Background image={greetingsBg} className="bg-card" />
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col xl={10} md={10} lg={10}>
            <Flex alignItems="center">
              <img src={barChart} width={90} alt="..." className="ms-n4" />
              <div>
                {/* <h6 className="text-primary fs--1 mb-0">Відкритий бюджет </h6> */}
                <h4 className="text-primary fw-bold mb-0">
                  <span className="text-nowrap me-2">
                    {cDt.substring(0, 4)}
                    р.
                  </span>
                  <span className="text-nowrap me-0 me-sm-2">
                    {title}
                  </span>
                  <span className="text-info fw-medium d-none d-sm-inline-block">
                    {desc}
                  </span>
                </h4>

              </div>
            </Flex>
          </Col>
          <Col xl={2} md={2} lg={2} className="d-flex gap-2 flex-wrap align-content-center">
            <EditorControls.DateInput
              value={cDt}
              onChange={(e, v) => setDt(v)}
              label="Інформація, станом на:"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

GreetingOBCard.propTypes = {
  cDt: PropTypes.string.isRequired,
  setDt: PropTypes.func.isRequired,
  title: PropTypes.string,
  desc: PropTypes.string,
};

GreetingOBCard.defaultProps = {
  title: '',
  desc: '',
};

export default GreetingOBCard;
