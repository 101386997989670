import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  IdLogica: { label: 'Номер в логіці', name: 'ИДЛогика', visible: false },
};

export const unitsClassifier = {
  label: 'Класифікатор одиниць виміру',
  name: 'unitsClassifier',
  backendName: 'КлассификаторЕдиницИзмерения',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/unitsClassifier',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default unitsClassifier;
