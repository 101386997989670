import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, ButtonGroup, Card,
  Col, Collapse, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import FilterGrid from '../../../../basicEditor/stdFilters/filterGrid';
import DocList from './docs';
import DocDetails from './docDetails';
import { EditorDateInput, EditorItemPicker } from '../../../../basicEditor/editorControls';
import { CommandPanel, Loader } from '../../../../../components/bootStrap';
import {
  HeaderText, FooterText, FooterLabel,
} from '../../../../basicEditor/stdFilters/style';
import { CPButton } from '../../../../../components/bootStrap/buttons/styles';
import useFilter from '../../../../basicEditor/stdFilters/useFilter';
import IconAlert from '../../../../../components/blanks/common/IconAlert';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

const API_URL = '/api/authorityplans/allocationplanchangesrow/';

const LOCAL_FILTERS = ['begin_date', 'end_date', 'executed', 'kbp7', 'authority_parent'];

function FilterEditor({
  overrideFilter, defaultFilter,
  onClose, onOk, lowLevel,
}) {
  const {
    filteringFields, items, loading, filters, setFilterHandler, fields, error, ready,
  } = useFilter({ apiUrl: API_URL, defaultFilter, overrideFilter });

  const displayFilteringFields = useMemo(
    () => filteringFields.filter((ff) => !LOCAL_FILTERS.includes(ff.name)),
    [filteringFields],
  );

  const [docs, setDocs] = useState([]);
  const [activeDoc, setActiveDoc] = useState();
  const [disposer, setDisposer] = useState();
  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );
  useEffect(() => {
    const docIds = new Set(items.map((d) => d.header.id));
    const newDocs = [...docIds].map((dId) => {
      const f0 = items.filter((item) => item.header.id === dId)[0];
      return {
        id: f0.header.id,
        repr: f0.header.repr,
        use: true,
      };
    });
    setDocs(newDocs);
  }, [items]);

  const selectedItems = useMemo(
    () => docs.filter((d) => d.use)
      .reduce((R, d) => [...R, ...items.filter((i) => i.header.id === d.id)], []),
    [docs, items],
  );

  const totals = useMemo(
    () => ({
      docsCount: docs.filter((d) => d.use).length,
      sum: selectedItems.reduce((R, i) => R + i.amount_total, 0),
    }),
    [docs, selectedItems],
  );

  const docDetails = useMemo(
    () => items.filter((item) => item.header.id === activeDoc),
    [activeDoc, items],
  );

  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

  const [showFilters, setShowFilters] = useState(false);

  return ready && (
    <>
      <Modal.Header closeButton>
        <HeaderText>{`Завантаження довідок про зміни розпорядників ${lowLevel ? 'нижчого' : 'верхнього'} рівня`}</HeaderText>
      </Modal.Header>
      <Modal.Body>
        {loading && (
        <Loader text="Завантаження" />
        )}
        {error && (
        <IconAlert variant="danger">
          {error}
        </IconAlert>
        )}
        <Container fluid>
          <Row>
            <Col>
              <EditorDateInput
                controlId="begin_date"
                label="Відібрати документи за період з"
                required
                value={filters.begin_date.value}
                onChange={(e, v) => setFilterHandler('begin_date', v, true)}
              />
            </Col>
            <Col>
              <EditorDateInput
                controlId="end_date"
                label="по"
                required
                value={filters.end_date.value}
                onChange={(e, v) => setFilterHandler('end_date', v, true)}
              />
            </Col>
            {!lowLevel && (
            <Col>
              <EditorItemPicker
                className="mt-0"
                label="Розпорядник для заповнення табличної частини"
                backend="/api/references/refdisposer/"
                required
                value={disposer}
                onChange={(e, v) => setDisposer(v)}
                noHierarchy
                filter={{ parent: filters.disposer?.value?.id }}
                description="Вказаний розпорядник будже використаний для заповнення строк довідки
                про зміни. Після завантаження його можна буде змінити."
              />
            </Col>
            )}
          </Row>
          <Row>
            <Col>
              <CommandPanel leftPart={(
                <>
                  <CPButton
                    onClick={() => setShowFilters(!showFilters)}
                    title="Фільтрування"
                    icon={faFilter}
                  />
                  <ButtonGroup>
                    <CPButton
                      onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                      title="Обрати всі"
                      icon={faCheckSquare}
                    />
                    <CPButton
                      onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                      title="Зняти відмітки"
                      icon={faSquare}
                    />
                  </ButtonGroup>
                </>
              )}
              />
              <Card className="border-0">
                <Collapse in={showFilters} appear>
                  <Card.Body className="p-0">
                    <FilterGrid
                      filters={filters}
                      filteringFields={displayFilteringFields}
                      onSetFilter={setFilterHandler}
                    />
                  </Card.Body>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <div className="p-1 border bg-light">
            <Row>
              <Col>
                <DocList
                  docs={docs}
                  onSelectDoc={setActiveDoc}
                  onToggleDoc={onToggleDoc}
                  activeDoc={activeDoc}
                />
              </Col>
              <Col sm={8}>
                <DocDetails
                  rows={docDetails}
                  fields={fields}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <FooterText>
          Буде завантажено
          {' '}
          <FooterLabel>{`${totals.docsCount}`}</FooterLabel>
          {' '}
          документів на суму
          {' '}
          <FooterLabel>{`${formatter(totals.sum.toFixed(2))}`}</FooterLabel>
          {' '}
          грн.
        </FooterText>
        <Button
          disabled={!lowLevel && disposer == null}
          variant="success"
          onClick={() => onOk(selectedItems.map((i) => i.id), disposer?.id)}
        >
          Завантажити
        </Button>
        <Button variant="dark" onClick={onClose}>Закрити</Button>
      </Modal.Footer>
    </>
  );
}

FilterEditor.propTypes = {
  overrideFilter: PropTypes.shape({
    disposer: foreignPropType,
  }),
  defaultFilter: PropTypes.shape({
    begin_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  lowLevel: PropTypes.bool.isRequired,
};
FilterEditor.defaultProps = {
  overrideFilter: {},
};
export default FilterEditor;
