import React from 'react';
import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const TABLENAME = 'documentNotes';
const coldef = md.dp.requestsLoading.tables[TABLENAME].columns;

function HeaderComponent() {
  return (
    <GridTemplate>
      <DivHeader areaName="csu">{coldef.CSU.label}</DivHeader>
      <DivHeader areaName="eKFK">{coldef.ElementKFK.label}</DivHeader>
      <DivHeader areaName="KPKVCode">{coldef.KPKVCode.label}</DivHeader>
      <DivHeader areaName="KEKVCode">{coldef.KEKVCode.label}</DivHeader>
      <DivHeader areaName="eKEKV">{coldef.ElementKEKV.label}</DivHeader>
      <DivHeader areaName="sum">{coldef.Sum.label}</DivHeader>
      <DivHeader areaName="notes">{coldef.Notes.label}</DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
