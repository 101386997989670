import React, { useCallback, useState } from 'react';
import {
  Alert, Button, ButtonGroup, Navbar,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../api/req';
import LogicaPing from '../../../Logica/Ping';
import { logikaLogout } from '../../../../actions/auth';

const selector = (state) => ({
  ticket: state.getIn(['auth', 'logikaTicket'], null),
});

function FillingKBP7Catalog() {
  const dispatch = useDispatch();
  const { ticket } = useSelector(selector);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClearErr = useCallback(
    () => {
      setError(null);
    },
    [],
  );

  const FillCat = useCallback(
    () => {
      const loader = async () => {
        const res = await await api.post$('logica/filling_kbp7_catalog', { ticket });
        if (res.status === 401) {
          dispatch(logikaLogout());
          setError('Необхідно авторизуватись!');
        }
      };
      setLoading(true);
      loader()
        .finally(setLoading(false));
    },
    [dispatch, ticket],
  );
  return (
    <ButtonGroup>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      {error && (
        <Alert variant="danger" dismissible onClose={onClearErr}>
          <Alert.Heading>
            {error}
          </Alert.Heading>
        </Alert>
      )}
      {(!ticket || (error === 'Необхідно авторизуватись!')) && (
        <Navbar.Brand href="#/dp/authentication/" onClick={() => setError(null)}>
          Авторизація
          у
          LOGICA
        </Navbar.Brand>
      )}
      <Button
        variant={loading ? 'secondary' : 'primary'}
        onClick={FillCat}
        disabled={loading || error}
      >
        Заповнити
        довідник
        з
        логіки
      </Button>
    </ButtonGroup>
  );
}

export default FillingKBP7Catalog;
