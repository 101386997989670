import React from 'react';
import Boxes from '../../../components/styledBoxMenu/boxBuilder/boxes';
import NavPanel from '../../../constants/meta/navBar';
import UseMenuHeader from '../hooks/useMenuHeader';

function BFNMenuItem() {
  return (
    <UseMenuHeader label="БФН">
      <Boxes items={NavPanel.bfn.boxes} />
    </UseMenuHeader>
  );
}

export default BFNMenuItem;
