import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeDraftAnnualRev';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;
const ThreeYears = [...Array(3)].map((_, i) => i);

function TPHeader({
  totals,
  activeCol,
  highlights,
  onToggleHighlght,
  for3Years,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={6}
        sm={12}
        className="px-1  d-flex flex-column"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kdbClassifier'}
              highlighted={highlights.includes('kdbClassifier')}
              onToggleHighlght={() => onToggleHighlght('kdbClassifier')}
            >
              {meta.columns.kdbClassifier.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col xl={6} sm={12} className="px-1">

        {for3Years ? ThreeYears.map((i) => (
          <Row key={i} noGutters>
            <Col>
              <TableHeaderCell
                active={activeCol === `SumGenF${i}`}
                title={totals[`SumGenF${i}`].toLocaleString('uk', moneysStingOptions)}
                highlighted={highlights.includes(`SumGenF${i}`)}
                onToggleHighlght={() => onToggleHighlght(`SumGenF${i}`)}
              >
                Заг.ф.
              </TableHeaderCell>
            </Col>
            <Col>
              <TableHeaderCell
                active={activeCol === `SumSpecF${i}`}
                title={totals[`SumSpecF${i}`].toLocaleString('uk', moneysStingOptions)}
                highlighted={highlights.includes(`SumSpecF${i}`)}
                onToggleHighlght={() => onToggleHighlght(`SumSpecF${i}`)}
              >
                Спец.ф.
              </TableHeaderCell>
            </Col>
            <Col className="font-italic">
              <TableHeaderCell
                active={activeCol === `SumBR${i}`}
                title={totals[`SumBR${i}`].toLocaleString('uk', moneysStingOptions)}
                highlighted={highlights.includes(`SumBR${i}`)}
                onToggleHighlght={() => onToggleHighlght(`SumBR${i}`)}
              >
                В т.ч. БР
              </TableHeaderCell>
            </Col>
            <Col>
              <TableHeaderCell
                active={activeCol === `Sum${i}`}
                title={(totals[`SumGenF${i}`] + totals[`SumSpecF${i}`]).toLocaleString('uk', moneysStingOptions)}
                highlighted={highlights.includes(`Sum${i}`)}
                onToggleHighlght={() => onToggleHighlght(`Sum${i}`)}
              >
                Разом
              </TableHeaderCell>
            </Col>
          </Row>
        ))
          : (
            <Row noGutters>
              <Col>
                <TableHeaderCell
                  active={activeCol === 'SumGenF0'}
                  title={totals.SumGenF0.toLocaleString('uk', moneysStingOptions)}
                  highlighted={highlights.includes('SumGenF0')}
                  onToggleHighlght={() => onToggleHighlght('SumGenF0')}
                >
                  Заг.ф.
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  active={activeCol === 'SumSpecF0'}
                  title={totals.SumSpecF0.toLocaleString('uk', moneysStingOptions)}
                  highlighted={highlights.includes('SumSpecF0')}
                  onToggleHighlght={() => onToggleHighlght('SumSpecF0')}
                >
                  Спец.ф.
                </TableHeaderCell>
              </Col>
              <Col className="font-italic">
                <TableHeaderCell
                  active={activeCol === 'SumBR0'}
                  title={totals.SumBR0.toLocaleString('uk', moneysStingOptions)}
                  highlighted={highlights.includes('SumBR0')}
                  onToggleHighlght={() => onToggleHighlght('SumBR0')}
                >
                  В т.ч. БР
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  active={activeCol === 'Sum0'}
                  title={(totals.SumGenF0 + totals.SumSpecF0).toLocaleString('uk', moneysStingOptions)}
                  highlighted={highlights.includes('Sum0')}
                  onToggleHighlght={() => onToggleHighlght('Sum0')}
                >
                  Разом
                </TableHeaderCell>
              </Col>
            </Row>
          )}
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    SumGenF0: PropTypes.number,
    SumSpecF0: PropTypes.number,
    SumBR0: PropTypes.number,
    Sum0: PropTypes.number,
    ...[...Array(3)].reduce((R, r, i) => ({
      ...R,
      [`SumGenF${i + 1}`]: PropTypes.number,
      [`SumSpecF${i + 1}`]: PropTypes.number,
      [`SumBR${i + 1}`]: PropTypes.number,
      [`Sum${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  for3Years: PropTypes.bool,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  for3Years: false,
};

export default memo(TPHeader);
