import base from './base';

/**
 * @const
 * @type {object}
 */
const mon = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v, i) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `${mon[i]}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: false },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  planingMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  refNumber: { label: 'Номер довідки', name: 'НомерСправки', visible: false },
  refReason: { label: 'Підстава для довідки', name: 'ОснованиеДляСправки', visible: false },
  variantBudgetDraft: { label: 'Варіант проекту (для порівняння)', name: 'ВариантПроекта', visible: false },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  includeBudgetAmplification: { label: 'в т.ч. бюджет бозвитку', name: 'ВТЧБР', visible: false },
  year: { label: 'Рiк', name: 'ГодЗатрат', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  // Дополнительные свойства
  thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      // KFKCode: { label: 'КФК', name: 'КодКФК' },
      delta: { label: 'Дельта', name: 'Дельта' },
      Sum: { label: 'Разом', name: 'Сумма' },
      // SumProject: { label: 'Сума проекту', name: 'СуммаПроекта' },
      ...sumByPeriod,
      SumBR: { label: 'В т.ч БР', name: 'СуммаБР' },
      ...sumByPeriodBudgetAmplification,
    },
  },
  KPKVMB: {
    name: 'ТЗКПКВМБ',
    label: 'КПКВМБ',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      check: { label: 'Обрати', name: 'Выбран' },
      kpkvmb: { label: 'КПКВМБ', name: 'КПКВМБ' },
    },
  },
  CSU: {
    name: 'ТЗРаспорядители',
    label: 'Розпорядники',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      check: { label: 'Обрати', name: 'Выбран' },
      csu: { label: 'Розпорядник', name: 'Распорядитель' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Лімітна довідка',
  name: 'limitReference',
  backendName: 'ЛимитнаяСправка',
  columns,
  frontend: 'doc/limitReference',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'fondObject',
      'FI',
      'year',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
