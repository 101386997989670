import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  total,
  activeCol,
  highlights,
  genTotal,
  specTotal,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={6}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row noGutters>
          <Col>
            <TableHeaderCell
              className="text-right font-italic text-sm-center w-100"
              active={activeCol === 'sumGenF'}
              highlighted={highlights.includes('sumGenF')}
            >
              Сума по заг. фонду
            </TableHeaderCell>
            <TableHeaderCell
              className="text-right font-italic text-sm-center w-100"
              active={activeCol === 'sumSpecF'}
              highlighted={highlights.includes('sumSpecF')}
            >
              Сума по спец. фонду
            </TableHeaderCell>
            <TableHeaderCell
              className="text-right font-italic text-sm-center w-100"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
            >
              Загальна сума
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col xl={6} sm={12} className="px-1">
        <Row noGutters>
          <Col md={12} className="px-1 d-flex justify-content-center flex-sm-column flex-row flex-xl-column">
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumGenF'}
              highlighted={highlights.includes('sumGenF')}
            >
              {genTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sumSpecF'}
              highlighted={highlights.includes('sumSpecF')}
            >
              {specTotal.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-right text-sm-center w-100"
              active={activeCol === 'sum'}
              highlighted={highlights.includes('sum')}
            >
              {total.toLocaleString('uk', moneysStingOptions)}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
  specTotal: PropTypes.number.isRequired,
  genTotal: PropTypes.number.isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
