import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Props = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const contragentProps = tableHooks.useItemInputProps('contragent', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const targetProgramProps = tableHooks.useItemInputProps('target_program', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const dateNumberProps = tableHooks.useStringInputProps('date_number', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const creditTermProps = tableHooks.useStringInputProps('credit_term', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const currencyProps = tableHooks.useStringInputProps('currency', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const creditAmountProps = tableHooks.useNumberInputProps('credit_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const creditInvolvementAmountProps = tableHooks.useNumberInputProps('credit_involvement_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const planYearProps = tableHooks.useNumberInputProps('plan_year', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <Col>
      <Row noGutters>
        <Col lg={3}>
          <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
            <TableControls.ItemPicker {...disposerProps} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
            <TableControls.ItemPicker {...kbp7Props} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="contragent" highlighted={highlights.includes('contragent')}>
            <TableControls.ItemPicker {...contragentProps} />
          </TabelRowCell>
        </Col>
        <Col lg={3}>
          <TabelRowCell column="contragent" highlighted={highlights.includes('contragent')}>
            <TableControls.ItemPicker {...targetProgramProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col lg={2}>
          <TabelRowCell column="date_number" highlighted={highlights.includes('date_number')}>
            <TableControls.StringInput {...dateNumberProps} />
          </TabelRowCell>
        </Col>

        <Col lg={2}>
          <TabelRowCell column="credit_term" highlighted={highlights.includes('credit_term')}>
            <TableControls.StringInput {...creditTermProps} />
          </TabelRowCell>
        </Col>

        <Col lg={2}>
          <TabelRowCell column="currency" highlighted={highlights.includes('currency')}>
            <TableControls.StringInput {...currencyProps} />
          </TabelRowCell>
        </Col>

        <Col lg={2}>
          <TabelRowCell column="credit_amount" highlighted={highlights.includes('credit_amount')}>
            <TableControls.NumberInput {...creditAmountProps} />
          </TabelRowCell>
        </Col>

        <Col lg={2}>
          <TabelRowCell column="credit_involvement_amount" highlighted={highlights.includes('credit_involvement_amount')}>
            <TableControls.NumberInput {...creditInvolvementAmountProps} />
          </TabelRowCell>
        </Col>

        <Col lg={2}>
          <TabelRowCell column="plan_year" highlighted={highlights.includes('plan_year')}>
            <TableControls.NumberInput {...planYearProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
