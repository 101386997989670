import React, {
  forwardRef, lazy, useMemo, useState, Suspense,
} from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Modal } from 'react-bootstrap';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from '../../bootStrap/buttons/styles';

const FormatInput = forwardRef(({
  id, value, onChange, disabled, readOnly, size,
  errored, className, allowedFonts,
}, ref) => {
  const [show, setShow] = useState(false);
  const dicClassName = useMemo(
    () => `${className} form-control ${errored ? 'is-invalid' : ''}`,
    [className, errored],
  );
  const displayValue = useMemo(
    () => (value ? 'Задано' : 'Не задано'),
    [value],
  );
  // eslint-disable-next-line import/no-cycle
  const FormatEditor = useMemo(() => lazy(() => import('./editor')), []);
  return (
    <InputGroup size={size || 'sm'}>
      <div
        id={id}
        ref={ref}
        className={dicClassName}
      >
        {displayValue}
      </div>
      <CPButton
        onClick={() => setShow(true)}
        icon={faEllipsisH}
        variant="outline-secondary"
        disabled={readOnly || disabled}
      />
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Формат</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Загрузка...</div>}>
            <FormatEditor
              onClose={() => setShow(false)}
              onChange={onChange}
              value={value}
              allowedFonts={allowedFonts}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
    </InputGroup>
  );
});

FormatInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    font: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      strike: PropTypes.bool,
      underline: PropTypes.bool,
      color: PropTypes.string,
    }),
    background: PropTypes.shape({
      fgColor: PropTypes.string,
    }),
    alignment: PropTypes.shape({
      horizontal: PropTypes.oneOf(['left', 'right', 'center']),
      vertical: PropTypes.oneOf(['top', 'bottom', 'middle']),
    }),
  }),
  onChange: PropTypes.func.isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errored: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

FormatInput.defaultProps = {
  id: '',
  value: {},
  allowedFonts: [],
  disabled: false,
  readOnly: false,
  errored: false,
  className: null,
  size: 'sm',
};

export default FormatInput;
