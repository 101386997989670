import {
  useCallback, useEffect, useState, useContext,
} from 'react';
import { SCHEMA_BACKEND_URL } from '../schemas';
import { useTables } from './tables';
import { CiatAppContext } from '../../../../providers';
import api from '../../../../api/req';
import { useParams } from './params';
import { useRelations } from './relations';
import { useFields } from './fields';
import { useMetaFields } from './metafields';
import { useCalcs } from './calcs';
import { REPORTS_API_URL, CHECK_SCHEMA_API_URL } from '../../../basicReport/hooks/consts';
import { useSettings } from './settings';
import { toBinary } from '../../../../api/utils';

// eslint-disable-next-line import/prefer-default-export
export const useMaster = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const { auth } = useContext(CiatAppContext);

  const [options, setOptions] = useState({});

  const {
    tables, setTables, tableHandlers, activeTable,
  } = useTables();

  const {
    params, setParams, paramsHandlers, activeParam,
  } = useParams();

  const {
    relations, setRelations, relationHandlers, activeRelation,
  } = useRelations();

  // fields of table:
  const [tableFields, setTableFields] = useState({ main: [] });
  const [tableRows, setTableRows] = useState({});

  const {
    fields, setFields, fieldHandlers, activeField,
  } = useFields(tableFields);

  const {
    calcs, setCalcs, calcHandlers, activeCalc,
  } = useCalcs();

  const {
    metaFields, setMetaFields, metaFieldHandlers, activeMetaField,
  } = useMetaFields(fields, calcs);

  const {
    settings, setSettings, settingHandlers, activeSetting,
  } = useSettings();

  useEffect(() => {
    const loader = async () => {
      const r = await api.options(CHECK_SCHEMA_API_URL, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    loader()
      .then((d) => {
        setOptions(d);
      })
      .catch((error) => setErr(`Ошибка получения параметров запроса: ${error.message}`))
      .finally(() => setLoading(false));
  }, [auth]);

  const onAnalyzeHandler = useCallback(
    () => {
      const loader = async () => {
        const queryParams = {
          tables: tables.reduce((R, t) => ({
            ...R,
            [t.name]:
            { [Object.keys(t.text)]: btoa(toBinary(t.text[Object.keys(t.text)])) },
          }), {}),
          params: params.reduce((R, { name, ...param }) => ({ ...R, [name]: param }), {}),
        };
        const r = await api.post(`${CHECK_SCHEMA_API_URL}analyze_queries/`, auth, queryParams);
        if (!r.ok && r.status === 400) {
          const d = await r.json();
          if (Array.isArray(d)) throw new Error(d.join(', '));
        }
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      loader()
        .then((d) => {
          setParams(Object.keys(d.params).map((name) => ({ name, ...d.params[name] })));
          setTableFields(d.fields);
        })
        .catch((error) => setErr(`Ошибка получения параметров запроса: ${error.message}`))
        .finally(() => setLoading(false));
    },
    [auth, params, setParams, tables],
  );

  const saveToJSON = useCallback(
    (mustParse = true) => {
      const saveParams = {
        tables: tables.reduce((R, { name, text }) => ({ ...R, [name]: text }), {}),
        relations: relations.map((r) => ({
          from: `${r.fromTable}.${r.fromField}`,
          to: `${r.toTable}.${r.toField}`,
          mandatory: r.mandatory,
        })),
        fields: fields.reduce((R, { name, ...r }) => ({ ...R, [name]: r }), {}),
        settings: settings.reduce((R, { name, text }) => ({ ...R, [name]: text }), {}),
        calculations: calcs.reduce((R, { name, ...r }) => ({ ...R, [name]: r }), {}),
        params: params.reduce((R, { name, ...r }) => ({ ...R, [name]: r }), {}),
        meta_fields: metaFields.reduce((R, { name, ...r }) => ({ ...R, [name]: r }), {}),
      };
      if (mustParse) {
        return JSON.stringify(saveParams, null, '\t');
      }
      return saveParams;
    },
    [calcs, fields, metaFields, params, relations, settings, tables],
  );

  const loadFromJSON = useCallback(
    (json, mustParse = true) => {
      let data;
      try {
        data = mustParse ? JSON.parse(json) : json;
      } catch (e) {
        setErr(e.message);
        return null;
      }
      setErr(null);
      if (data) {
        if ('tables' in data) {
          setTables(Object.keys(data.tables).map((name) => ({ name, text: data.tables[name] })));
        } else {
          setTables([]);
        }
        if ('params' in data) {
          setParams(Object.keys(data.params).map((name) => ({ name, ...data.params[name] })));
        } else {
          setParams([]);
        }
        if ('relations' in data) {
          setRelations(data.relations.map((rel) => {
            const [fromTable, fromField] = rel.from.split('.');
            const [toTable, toField] = rel.to.split('.');
            return {
              fromTable,
              fromField,
              toTable,
              toField,
              mandatory: rel.mandatory,
              key: Math.random().toString(36),
            };
          }));
        } else {
          setRelations([]);
        }
        if ('fields' in data) {
          setFields(Object.keys(data.fields).map((name) => ({ name, ...data.fields[name] })));
        } else {
          setFields([]);
        }
        if ('settings' in data) {
          setSettings(Object.keys(data.settings)
            .map((name) => ({ name, text: data.settings[name] })));
        } else {
          setSettings([]);
        }
        if ('calculations' in data) {
          setCalcs(
            Object.keys(data.calculations)
              .map((name) => ({ name, ...data.calculations[name] })),
          );
        } else {
          setCalcs([]);
        }
        if ('meta_fields' in data) {
          setMetaFields(
            Object.keys(data.meta_fields)
              .map((name) => ({ name, ...data.meta_fields[name] })),
          );
        } else {
          setMetaFields([]);
        }
      }
      return true;
    },
    [setCalcs, setFields, setMetaFields, setParams, setRelations, setSettings, setTables],
  );

  const loadSchemaFromSrv = useCallback((id) => {
    const loader = async () => {
      const r = await api.get(`${SCHEMA_BACKEND_URL}${id}/`, auth);
      if (!r.ok && r.status === 400) {
        const d = await r.json();
        throw new Error(d.join(', '));
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    loader()
      .then((d) => {
        loadFromJSON(d.src, false);
      })
      .catch((error) => setErr(`Ошибка загрузки схемы: ${error.message}`))
      .finally(() => setLoading(false));
  }, [auth, loadFromJSON]);

  const saveSchemaToSrv = useCallback((id) => {
    const loader = async (data) => {
      const r = await api.patch(`${SCHEMA_BACKEND_URL}${id}/`, auth, { id, src: data });
      if (!r.ok && r.status === 400) {
        const d = await r.json();
        throw new Error(d.join(', '));
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader(saveToJSON(false))
      .then((d) => {
        loadFromJSON(d.src, false);
      })
      .catch((error) => setErr(`Ошибка сохранения схемы: ${error.message}`))
      .finally(() => setLoading(false));
  }, [auth, loadFromJSON, saveToJSON]);

  const createNewSchema = useCallback(({ newSchemeParams, setShowSchemePage }) => {
    const loader = async () => {
      const r = await api.post(`${SCHEMA_BACKEND_URL}`, auth, { ...newSchemeParams });
      if (!r.ok && r.status === 400) {
        const d = await r.json();
        throw new Error(d.join(', '));
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader(newSchemeParams)
      .then((d) => d && setShowSchemePage(false))
      .catch((error) => setErr(`Ошибка сохранения схемы: ${error.message}`))
      .finally(() => setLoading(false));
  }, [auth]);

  const createNewReport = useCallback(({ newReportParams, setShowReportPage }) => {
    const loader = async () => {
      const r = await api.post(`${REPORTS_API_URL}`, auth, { ...newReportParams });
      if (!r.ok && r.status === 400) {
        const d = await r.json();
        throw new Error(d.join(', '));
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader(newReportParams)
      .then((d) => d && setShowReportPage(false))
      .catch((error) => setErr(`Ошибка сохранения схемы: ${error.message}`))
      .finally(() => setLoading(false));
  }, [auth]);

  useEffect(() => {
    const autosave = sessionStorage.getItem('skd_master_session');
    loadFromJSON(autosave);
  }, [loadFromJSON, setFields, setMetaFields, setParams, setRelations, setTables]);

  useEffect(() => {
    sessionStorage.setItem('skd_master_session', saveToJSON());
  }, [fields, metaFields, params, relations, saveToJSON, tables]);

  const onGetTableRecords = useCallback(
    (tableName, paramValues, recordsCount) => {
      const loader = async () => {
        const queryParams = {
          tables: tables
            .reduce((R, t) => ({
              ...R,
              [t.name]: { [Object.keys(t.text)]: btoa(toBinary(t.text[Object.keys(t.text)])) },
            }), {}),
          params: params.reduce((R, { name, ...param }) => ({ ...R, [name]: param }), {}),
          param_values: paramValues,
          records_count: recordsCount,
          table_name: tableName,
        };
        const r = await api.post(`${CHECK_SCHEMA_API_URL}get_table_records/`, auth, queryParams);
        if (!r.ok && r.status === 400) {
          const d = await r.json();
          throw new Error(d.join(', '));
        }
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      loader()
        .then((d) => {
          setTableRows((tr) => ({ ...tr, [tableName]: d.rows }));
        })
        .catch((error) => setErr(`Ошибка получения параметров запроса: ${error.message}`))
        .finally(() => setLoading(false));
    },
    [auth, params, tables],
  );

  const masterHandlers = {
    saveToJSON,
    loadFromJSON,
    loadSchemaFromSrv,
    saveSchemaToSrv,
    onGetTableRecords,
    createNewSchema,
    createNewReport,
  };

  return {
    loading,
    setLoading,
    err,
    setErr,
    tables,
    tableHandlers,
    activeTable,
    params,
    paramsHandlers,
    activeParam,
    options,
    onAnalyzeHandler,
    tableFields,
    relations,
    setRelations,
    relationHandlers,
    activeRelation,
    fields,
    setFields,
    fieldHandlers,
    activeField,
    calcs,
    setCalcs,
    calcHandlers,
    activeCalc,
    metaFields,
    metaFieldHandlers,
    activeMetaField,
    masterHandlers,
    tableRows,
    settings,
    settingHandlers,
    activeSetting,
  };
};
