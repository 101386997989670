import PropTypes from 'prop-types';
import React from 'react';

import { Tab, Tabs } from 'react-bootstrap';
import { Form2TP } from './tabs';
import md from '../../../../constants/meta/documents/freeRem4Add';

function FreeRemTabs({
  data, actions, permissions,
}) {
  const readOnly = !permissions.canChange || data[md.columns.apprByRegion.name];

  // const [key, setKey] = useState('form2');
  return (
    <Tabs>
      <Tab title={md.tables.form2.label} eventKey="form2">
        <Form2TP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

FreeRemTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default FreeRemTabs;
