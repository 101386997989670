import base from './base';

/**
 * @const
 */
const columns = {
  ...base.columns,
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  notes: { label: 'Коментар', name: 'Комментарий', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  accountFrom: { label: 'Рахунок-відправник', name: 'СтарыйБанковскийСчет', visible: false },
  accountTo: { label: 'Рахунок-отримувач', name: 'НовыйБанковскийСчет', visible: false },
  // client: { label: 'Постачальник', name: 'Контрагент', visible: true },
  elementKFK: { label: 'Елемент КПКВ/КПКВМБ ', name: 'ЭлементКФК', visible: false },
  fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: false },
  sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  paymentPurpose: { label: 'Призначення платежу', name: 'НазначениеПлатежа', visible: true },
  isPaided: { label: 'Проведено банком', name: 'Оплачено', visible: false },
  paymentDate: { label: 'Дата оплати', name: 'ДатаОплаты', visible: false },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  rNo: { label: '№ платіжного доручення', name: 'РегНомер', visible: true },
  // Дополнитльеные свойства
  byElementsKFK: { label: 'За елементами бюджету', name: 'ПоЭлементуБюджета', visible: false },
  purposeMaxLength: { label: 'МаксДлиннаНазначенияПлатежа', name: 'МаксДлиннаНазначенияПлатежа' },
  result: { label: 'Результат', name: 'Результат' },
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
};

/**
 * @const
 */
const tables = {
  docData: {
    name: 'ДанныеДокумента',
    label: 'Дані документу',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'КЕКВ', name: 'ЭлементКЕКВ' },
      elementKDB: { label: 'КДБ', name: 'ЭлементКДБ' },
      sum: { label: 'Сума', name: 'Сумма' },
      notes: { label: 'Коментар', name: 'Комментарий' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Платіжне доручення на переміщення коштів',
  name: 'paymentTransfer',
  backendName: 'ПлатежноеПоручениеНаПеремещениеДенег',
  columns,
  frontend: 'doc/paymentTransfer',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'rNo',
      'sum',
      'paymentPurpose',
      'notes',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
