import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/analysisSpentCashe';
import DateRangeField from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  ThreeColumnsGridContainer,
  FourColumnsGridContainer,
  ContainerDiv,
  ResultSegment, DivWithPropsLabel, StyledPane,
} from '../../../components/Form/styledForm';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import ItemField from '../../field/selectorField/item/itemField';
import Tabs from '../../../components/tabs/Tabs';
import ReportContainer from '../reportContainer';

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const SettingsButton = (
    <div>
      <div>
        <ThreeColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{md.columns.FO.label}</StyledLabel>
            {FoLabel}
          </div>
          <div>
            <Fields.CheckboxField
              label={md.columns.consolidated.label}
              value={headerForm.get('consolidated', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.byHighLevelCSU.label}
              value={headerForm.get('byHighLevelCSU', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byHighLevelCSU'], v))}
            />
            <Fields.CheckboxField
              label={md.columns.bySubLevelCSU.label}
              value={headerForm.get('bySubLevelCSU', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'bySubLevelCSU'], v))}
            />
          </div>
        </ThreeColumnsGridContainer>
        <FourColumnsGridContainer>
          <div>
            <StyledLabel>{md.columns.reportType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('reportType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
              values={Object.values(ReportTypes)}
              name="reportType"
            />
          </div>
          <div>
            <StyledLabel>{md.columns.planType.label}</StyledLabel>
            <RadioButton
              value={headerForm.get('planType', false)}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
              values={Object.values(enums.PlaningKind).map((_e) => ({ label: _e.label, value: _e.name }))}
              name="planType"
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('in1000', false)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byDataTreasury', false)}
              label={md.columns.byDataTreasury.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byDataTreasury'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('byRequest', false)}
              label={md.columns.byRequest.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'byRequest'], v))}
            />
          </div>
          <div>
            <Fields.CheckboxField
              value={headerForm.get('showClassifiersName', false)}
              label={md.columns.showClassifiersName.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showClassifiersName'], v))}
            />
            <Fields.CheckboxField
              value={headerForm.get('showCSUNames', false)}
              label={md.columns.showCSUNames.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUNames'], v))}
            />

          </div>
        </FourColumnsGridContainer>
      </div>
      {Bookmarks}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
      </ContainerDiv>
      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
