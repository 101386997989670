import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/changeToDraftAnnualSources';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        sm={12}
        className="px-1  d-flex flex-column justify-content-start"
      >
        <Row noGutters>
          <Col sm={12} xl={4}>
            <TableHeaderCell
              className="text-center"
              active={activeCol === 'elementKFB'}
              highlighted={highlights.includes('elementKFB')}
              onToggleHighlght={() => onToggleHighlght('elementKFB')}
            >
              {meta.columns.elementKFB.label}
            </TableHeaderCell>
          </Col>
          <Col sm={12} xl={8}>
            <Row className="w-100" noGutters>
              <Col sm={12} lg={3}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'zf'}
                  highlighted={highlights.includes('zf')}
                  onToggleHighlght={() => onToggleHighlght('zf')}
                >
                  {meta.columns.zf.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12} lg={3}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'sf'}
                  highlighted={highlights.includes('sf')}
                  onToggleHighlght={() => onToggleHighlght('sf')}
                >
                  {meta.columns.sf.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12} lg={3}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'br'}
                  highlighted={highlights.includes('br')}
                  onToggleHighlght={() => onToggleHighlght('br')}
                >
                  {meta.columns.br.label}
                </TableHeaderCell>
              </Col>
              <Col sm={12} lg={3}>
                <TableHeaderCell
                  className="text-center"
                  active={activeCol === 'sum'}
                  highlighted={highlights.includes('sum')}
                  onToggleHighlght={() => onToggleHighlght('sum')}
                >
                  {meta.columns.sum.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
