import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { foreignPropType } from '../../../../constants/backend/propTypes';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryDisposer({
  data, readOnly,
  historyActions, historyData, historiesList, historyFields, historyFieldErrors,
  historyLoading, isNew,
}) {
  //   address, budget, code_dksu, deleted, disposer_kind, edrpou, email,
  // history, id, koatuu, level,  name, parent, phone_number, post_code,
  // recipient, repr, resource,  skype, url, web_site,

  // eslint-disable-next-line max-len
  const editorStringHooks = (name) => editorHooks.useStringInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, readOnly);
  // eslint-disable-next-line max-len
  const editorSelectorHooks = (name) => editorHooks.useItemInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, readOnly);
  // eslint-disable-next-line max-len
  const editorCheckboxHooks = (name) => editorHooks.useCheckboxInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, readOnly);

  // console.log(historyData);
  const kvk = editorSelectorHooks('kvk');
  // const chief = editorSelectorHooks('chief');
  // const buhgalter = editorSelectorHooks('buhgalter');

  const isActive = editorCheckboxHooks('is_active');

  const transfername = editorStringHooks('transfername');
  const fullname = editorStringHooks('fullname');
  const networkCode = editorStringHooks('network_code');
  const treasureNameProps = editorStringHooks('treasure_name');
  const buhgalterProps = editorSelectorHooks('buhgalter');
  const chiefProps = editorSelectorHooks('chief');

  const executiveFilter = useMemo(() => ({ authority: data.id }), [data.id]);
  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
        readOnly={readOnly}
      />
      <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
        <Container fluid className="pb-3">
          <Row>
            <Col>
              <EditorControls.StringInput {...fullname} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput {...transfername} />
            </Col>
            <Col>
              <EditorControls.StringInput {...treasureNameProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker {...kvk} required={!!data.parent} />
            </Col>
            <Col>
              <EditorControls.StringInput {...networkCode} inputMask="999999" />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                {...buhgalterProps}
                filter={executiveFilter}
                disabled={!data.id}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                {...chiefProps}
                filter={executiveFilter}
                disabled={!data.id}
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput {...isActive} />
            </Col>
          </Row>
        </Container>
      </HistoryContainer>
    </>
  );
}
const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  fullname: PropTypes.string,
  genetivename: PropTypes.string,
  is_active: PropTypes.bool,
  parent: PropTypes.shape({ }),
  settings: PropTypes.shape({}),
};

HistoryDisposer.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    history: PropTypes.shape(dataPropTypes),
    parent: foreignPropType,
  }).isRequired,
  readOnly: PropTypes.bool,
  historyActions: PropTypes.shape({
    onCreateHistory: PropTypes.func,
    deleteHistory: PropTypes.func,
    onHistoryChange: PropTypes.func,
    onLoadHistories: PropTypes.func,
    onSelectHistory: PropTypes.func,
    onSaveHistory: PropTypes.func,
    onDeleteHistory: PropTypes.func,
  }),
  historyData: PropTypes.shape({ datefrom: PropTypes.string }),
  historiesList: PropTypes.shape({}),
  historyFields: PropTypes.shape({}),
  historyFieldErrors: PropTypes.shape({}),
  historyLoading: PropTypes.bool,
  isNew: PropTypes.bool,
};

HistoryDisposer.defaultProps = {
  readOnly: false,
  historyActions: null,
  historyData: null,
  historiesList: null,
  historyFields: null,
  historyFieldErrors: null,
  historyLoading: false,
  isNew: false,

};

export default HistoryDisposer;
