import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import FiltersList from './filtersList';
import { availableFiltersPropType, getPreviousFilter, filtersPropType } from '../../hooks';

function FiltersEditor({
  availableFilters, filters, filtersHandlers,
}) {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const [openedFilters, setOpenedFilters] = useState([]);
  const visibleFilters = useMemo(() => availableFilters.reduce((R, col) => [
    ...R,
    col.parent === null && {
      ...col,
      lvl: 0,
      hasChild: !!availableFilters.filter((col2) => col2.parent === col.name).length,
    },
    ...availableFilters.filter((col2) => col2.parent === col.name && openedFilters
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1, hasChild: false })),
  ], []).filter((col) => col), [availableFilters, openedFilters]);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveFilter(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedFilters = useCallback((val) => {
    if (openedFilters.includes(val)) {
      return setOpenedFilters((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedFilters((prev) => ([...prev, val]));
  }, [openedFilters]);

  const filtersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        filtersHandlers.addFilterHandler(name, oname);
      } else if (type === itemTypes.filter) {
        filtersHandlers.swapFilterHandler(name, oname);
      }
    },
    [filtersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.filter) {
        filtersHandlers.removeFilterHandler(name);
        if (activeFilter === name) setActiveFilter(null);
      }
    },
    [activeFilter, filtersHandlers],
  );

  return (
    <div className="d-flex h-100 overflow-hidden">
      <div className="overflow-hidden flex-fill w-25">
        <AvailableFieldList
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={filtersHandlers.addFilterHandler}
          cardTitle="Доступні для фільтрування поля"
          cardText={(
            <p className="text-justify mb-0">
              можна використовувати для фільтрації даних у вашому звіті.
              Ці поля дозволяють вам зосередитися на певних значеннях у вашій таблиці,
              виключаючи ті, які не відповідають вашим критеріям. Ви можете перетягнути
              будь-яке поле з
              <b className="mx-1">&quot;Доступних для фільтрування полів&quot;</b>
              до
              <b className="mx-1">&quot;Обрані для фільтрування поля&quot;</b>
            </p>
          )}
          onDragEnd={avaDragEndHanlder}
          setOpenedItems={onSetOpenedFilters}
          visibleItems={visibleFilters}
          openedItems={openedFilters}
        />
      </div>
      <div className="overflow-hidden d-flex align-items-center px-2 ">
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => filtersHandlers.clearAllFiltersHandler()}
            disabled={!filters.length}
          />
          <LeftButton
            onClick={() => {
              filtersHandlers.removeFilterHandler(activeFilter);
              setActiveFilter(getPreviousFilter(filters, activeFilter));
            }}
            disabled={activeFilter === null}
          />
          <RightButton
            onClick={() => filtersHandlers.addFilterHandler(activeAvaField)}
            disabled={!activeAvaField}
          />
        </ButtonGroup>
      </div>
      <div className="overflow-hidden flex-fill w-75">
        <FiltersList
          cardTitle="Обрані для фільтрування поля"
          cardText={(
            <p className="text-justify mb-0">
              Після того, як поле буде додано до
              <b className="ms-1">&quot;Обрані для фільтрування поля&quot;</b>
              ,
              Ви можете створити для нього фільтр.
              <br />
              Виберіть тип фільтра.
              <ol>
                <li>
                  Для Довідника : Дозволяють обрати певний елемент довіднику,
                  папку або список елементів довідника.
                </li>
                <li>Для Текстового фільтру : Дозволяють шукати певні слова, фрази або значення.</li>
                <li>
                  Числові фільтри: Дозволяють фільтрувати за діапазонами значень,
                  більшими/меншими за певне значення тощо.
                </li>
                <li>
                  Фільтри за датою: Дозволяють фільтрувати за певними
                  датами або проміжками часу.
                </li>
                <li>
                  Логічні фільтри: Дозволяють
                  фільтрувати за значеннями &quot;Так&quot; або &quot;Ні&quot;.
                </li>
              </ol>
            </p>
          )}
          filters={filters}
          activeFilter={activeFilter}
          onClick={onActiveSelectionHandler}
          onDblClick={filtersHandlers.removeFilterHandler}
          onDragEnd={filtersDragEndHanlder}
          changeFilterUse={filtersHandlers.changeFilterUse}
          changeFilterOperation={filtersHandlers.changeFilterOperation}
          changeFilterValue={filtersHandlers.changeFilterValue}
        />
      </div>
    </div>
  );
}

FiltersEditor.propTypes = {
  filters: filtersPropType.isRequired,
  availableFilters: availableFiltersPropType.isRequired,
  filtersHandlers: PropTypes.shape({
    addFilterHandler: PropTypes.func,
    removeFilterHandler: PropTypes.func,
    swapFilterHandler: PropTypes.func,
    clearAllFiltersHandler: PropTypes.func,
    changeFilterUse: PropTypes.func,
    changeFilterOperation: PropTypes.func,
    changeFilterAdd: PropTypes.func,
    changeFilterValue: PropTypes.func,
    changeFilterRemove: PropTypes.func,
  }).isRequired,
};

export default FiltersEditor;
