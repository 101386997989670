import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/sessionAdd5Transfert';
import enums from '../../../constants/meta/enums';
import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import {
  StyledLabel,
  MyContainerFormWithNColumns,
  TwoColumnsGridContainer, CommandPanelColor,
} from '../../../components/Form/styledForm';
import StyledTPPane from '../../../components/tableParts/TPPane';
// import DateInput from '../../../components/fields/dates';
import ReportContainer from '../reportContainer';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;
const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;

const TwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 60% 38%;
  grid-column-gap: 2%;
`;

const MyTwoColumnsGrid = styled(TwoColumnsGridContainer)`
  grid-template-columns: 20% 68%;
  grid-column-gap: 2%;
`;

function ReportEditor({
  dispatch, headerForm, isProcessing, portalOpened, onReportDetails,
}) {
  const store = useSelector((state) => state);

  const sessionOptions = store.getIn(['auth', 'sessionOptions'], new Map());

  const resultMountNode = useMemo(() => [`rep/${md.name}/reportEditor`, 'result'], []);
  const reportKinds = useMemo(() => Object.values(enums.ReportsKind).map(
    ({ label }, idx) => ({ value: idx + 1, label }),
  ), []);

  const isAdmin = sessionOptions.get('is_admin');
  // const { portalOpened } = this.props;

  const SettingsButton = (
    <div>
      <MyContainerFormWithNColumns columns={2}>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
            />
          ) : (
            <Fields.ItemField
              value={headerForm.get('budget', new Map())}
            />
          )}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {isAdmin ? (
            <Fields.SelectorField
              value={headerForm.get('FO', new Map())}
              modelType="cat"
              modelName="csu"
              onChange={(e, v) => {
                dispatch(changeField(['headerForm', 'FO'], v));
                dispatch(changeField(['headerForm', 'signVariant'], new Map()));
              }}
            />
          ) : (
            <Fields.ItemField
              value={headerForm.get('FO', new Map())}
            />
          )}
        </div>
      </MyContainerFormWithNColumns>
      <MyContainerFormWithNColumns columns={6}>
        {/* <div> */}
        {/*  <StyledLabel>{md.columns.startDate.label}</StyledLabel> */}
        {/*  <DateInput */}
        {/*    value={headerForm.get('startDate', null)} */}
        {/*    onChange={(e, value) => dispatch( */}
        {/*      changeField(['headerForm', 'startDate'], value), */}
        {/*    )} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div> */}
        {/*  <StyledLabel>{md.columns.endDate.label}</StyledLabel> */}
        {/*  <DateInput */}
        {/*    value={headerForm.get('endDate', null)} */}
        {/*    onChange={(e, value) => dispatch( */}
        {/*      changeField(['headerForm', 'endDate'], value), */}
        {/*    )} */}
        {/*  /> */}
        {/* </div> */}
        <div>
          <StyledLabel>{md.columns.reportsKind.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('reportsKind', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportsKind'], v))}
            values={reportKinds}
            name="reportsKind"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.yearPlan.label}</StyledLabel>
          <Fields.YearField
            value={headerForm.get('yearPlan', 0)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'yearPlan'], v))}
            disableCalculator
          />
        </div>
        <Fields.CheckboxField
          value={headerForm.get('flKDB', true)}
          label={md.columns.flKDB.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'flKDB'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('flNull', true)}
          label={md.columns.flNull.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'flNull'], v))}
        />
        <Fields.CheckboxField
          value={headerForm.get('flTrans', true)}
          label={md.columns.flTrans.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'flTrans'], v))}
        />
      </MyContainerFormWithNColumns>
      <TwoColumnsGrid>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            canCleared
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'signVariant'], v));
            }}
          />
        </div>
        <div>
          <Fields.CheckboxField
            value={headerForm.get('flName', true)}
            label={md.columns.flName.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'flName'], v))}
          />
          <Fields.CheckboxField
            value={headerForm.get('kopecks', true)}
            label={md.columns.kopecks.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'kopecks'], v))}
          />
        </div>
      </TwoColumnsGrid>
      <TwoColumnsGrid>
        <div>
          <StyledLabel>{md.columns.progVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('progVariant', new Map())}
            modelType="cat"
            modelName="variantBudgetDraft"
            canCleared
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'progVariant'], v));
            }}
          />
        </div>
        <Fields.CheckboxField
          value={headerForm.get('flNameKBP', true)}
          label={md.columns.flNameKBP.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'flNameKBP'], v))}
        />
      </TwoColumnsGrid>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
      </ContainerDiv>
      <MyTwoColumnsGrid>
        <div>
          <CommandPanelColor
            style={{ width: 'fit-content', alignSelf: 'end' }}
            label="Створити документ відправки в Logica"
            onClick={() => dispatch(processServerReq('UPLOAD_TO_LOGICA'))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.sessiondesigion.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('sessiondesigion', new Map())}
            modelType="cat"
            modelName="resolutionSession"
            canCleared
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'sessiondesigion'], v));
            }}
          />
        </div>
      </MyTwoColumnsGrid>

      <ResultSegment>
        <TableEditor mountNode={resultMountNode} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
