import {
  defaultOrder,
  defaultColumns,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  owner: { label: 'Владелец', name: 'Владелец', visible: true },
};

export const provisionOfNormativeLegalActs = {
  label: 'Положення нормативно правових актiв',
  name: 'provisionOfNormativeLegalActs',
  backendName: 'ПоложенняНормативноПравовихАктов',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/provisionOfNormativeLegalActs',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default provisionOfNormativeLegalActs;
