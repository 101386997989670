import { combineReducers } from 'redux-immutable';

import auth from './auth';
import notifier from './notifier';
import createListerReducers from './lister';
// import editor from './editor';
import createEditorReducer from './editorId';
import createDpEditorReducer from './dpEditor';

import createReportReducer from './reportEditor';
import favorites from './favorites';
import windowsManager from './windowsManager';
import reportDescription from '../constants/meta/reports';
import dpEditortDescription from '../constants/meta/dataprocessors';
import catalogDescription from '../constants/meta/catalogs';
import documentDescription from '../constants/meta/documents';
import ChTPDescription from '../constants/meta/ChTP';
import infoRegsDescription from '../constants/meta/infoRegs';

const reducers = combineReducers({
  auth,
  notifier,
  // dpEditor,
  favorites,
  windowsManager,
  ...createReportReducer('rep', reportDescription, 'reportEditor'),
  ...createDpEditorReducer('dp', dpEditortDescription, 'dpEditor'),
  /* Creating catalogs store */
  ...createListerReducers('cat', catalogDescription),
  ...createListerReducers('cat', catalogDescription, 'selector'),
  ...createEditorReducer('cat', catalogDescription, 'editor'),
  /* Creating document store */
  ...createListerReducers('doc', documentDescription),
  ...createListerReducers('doc', documentDescription, 'selector'),
  ...createEditorReducer('doc', documentDescription, 'editor'),
  /* Creating ChTP (Characteristics Type Plane) store */
  ...createListerReducers('ChTP', ChTPDescription),
  ...createListerReducers('ChTP', ChTPDescription, 'selector'),
  ...createEditorReducer('ChTP', ChTPDescription, 'editor'),
  /* Creating infoRegs store */
  ...createListerReducers('infoRegs', infoRegsDescription),
  ...createListerReducers('infoRegs', infoRegsDescription, 'selector'),
  ...createEditorReducer('infoRegs', infoRegsDescription, 'editor'),
});

export default reducers;
