import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';

function TableHeader({
  pinHeader, pinAllowed, className, children,
}) {
  return (
    <Card.Header
      className={classNames('px-0 pt-0 bg-light tablepart-header', className || '', { 'position-sticky fixed-top': pinHeader && pinAllowed })}
    >
      {children}
    </Card.Header>
  );
}

TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  pinHeader: PropTypes.bool.isRequired,
  pinAllowed: PropTypes.bool,
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  className: '',
  pinAllowed: true,
};

export default TableHeader;
