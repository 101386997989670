import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.regulations;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'regulation'}
            highlighted={highlights.includes('regulation')}
            onToggleHighlght={() => onToggleHighlght('regulation')}
          >
            {meta.columns.regulation.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'article'}
            highlighted={highlights.includes('article')}
            onToggleHighlght={() => onToggleHighlght('article')}
          >
            {meta.columns.article.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KEKV'}
              highlighted={highlights.includes('KEKV')}
              onToggleHighlght={() => onToggleHighlght('KEKV')}
            >
              {meta.columns.KEKV.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'elementKEKV'}
              highlighted={highlights.includes('elementKEKV')}
              onToggleHighlght={() => onToggleHighlght('elementKEKV')}
            >
              {meta.columns.elementKEKV.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'sum1'}
            highlighted={highlights.includes('sum1')}
            onToggleHighlght={() => onToggleHighlght('sum1')}
          >
            {meta.columns.sum1.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'sum2'}
            highlighted={highlights.includes('sum2')}
            onToggleHighlght={() => onToggleHighlght('sum2')}
          >
            {meta.columns.sum2.label}
          </TableHeaderCell>
        </Row>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'sum3'}
            highlighted={highlights.includes('sum3')}
            onToggleHighlght={() => onToggleHighlght('sum3')}
          >
            {meta.columns.sum3.label}
          </TableHeaderCell>
        </Row>
      </Col>
      <Col>
        <Row>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'description'}
            highlighted={highlights.includes('description')}
            onToggleHighlght={() => onToggleHighlght('description')}
          >
            {meta.columns.description.label}
          </TableHeaderCell>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
