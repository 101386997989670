import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Col, Collapse, Container, Row,
} from 'react-bootstrap';
import { referencePropType } from '../../../../../newEditor/propTypes';
import md from '../../../../../../constants/meta/documents/budgetRequest';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor } from '../../../../../../components/Form/styledForm';
import { useDCS } from '../../../../../DCS/hooks';
import { modelType } from '../../../../../dataProcessors/requestsLoadingBoostraped/def';
import DCSFilter from '../../../../../DCS/components/filter/filter';

const tableMeta = md.tables.costsKEKV;

const cashbox = [
  { value: 1, display_name: 'заповнити за даними уточ.плану' },
  { value: 2, display_name: 'за профінансованими даними' },
  { value: 3, display_name: 'за касою (відбір за КБП)' },
];

function SettingsKEKV({
  data, actions, readOnly, setAccKey,
}) {
  const year = data[md.columns.year.name] || 0;
  const fillYears = useMemo(
    () => [
      { value: 0, display_name: 'всі роки' },
      { value: 1, display_name: `${year - 1} рік` },
      { value: 2, display_name: `${year - 2} рік` },
    ],
    [year],
  );
  const FI = data[md.columns.FI.name];
  const [showFilters, setShowFilters] = useState(false);

  const onChangeDCS = useCallback(
    (f) => actions.onChange((d) => ({
      ...d,
      [md.columns.DCSettings.name]: f(d[md.columns.DCSettings.name]),
    })),
    [actions],
  );
  const reqParams = useMemo(
    () => ({ FI }),
    [FI],
  );
  const {
    filters, filterAvailables,
  } = useDCS({
    data: data[md.columns.DCSettings.name],
    onChange: onChangeDCS,
    reqParams,
    backendURL: `${modelType}/${md.backendName}/`,
  });
  const filterCnts = filters.items.filter((f) => f.item.Use).length;

  return (
    <Container fluid className="border rounded p-4 ms-0">
      <Row>
        <Col>
          <EditorControls.RadioInput
            value={data[md.columns.cashbox.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.cashbox.name]: value,
            })}
            readOnly={readOnly}
            values={cashbox}
          />
        </Col>
        <Col style={{ display: 'flex' }}>
          <EditorControls.RadioInput
            value={data[md.columns.fillYear.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fillYear.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(fillYears)}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.dateOfCompletion.label}
            value={data[md.columns.dateOfCompletion.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dateOfCompletion.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <CommandPanelColor
            style={{ marginTop: '30px' }}
            label={!showFilters ? 'Показати фільтрування' : 'Сховати фільтрування'}
            onClick={() => setShowFilters(!showFilters)}
          >
            {filterCnts ? (
              <Badge className="ms-2" variant="warning" pill>{filterCnts}</Badge>
            ) : null}
          </CommandPanelColor>
        </Col>
        <Col>
          <CommandPanelColor
            style={{ marginTop: '30px' }}
            label="Заповнити"
            onClick={() => {
              setShowFilters(false);
              actions.onSR('FILL_TABLE_AUTO');
              setAccKey('');
            }}
          />
        </Col>
      </Row>
      <Row>
        <Collapse in={showFilters}>
          <div className="my-2 w-100 ">
            <DCSFilter
              filters={filters}
              availables={filterAvailables}
            />
          </div>
        </Collapse>
      </Row>
    </Container>
  );
}

SettingsKEKV.propTypes = {
  data: PropTypes.shape({
    [md.columns.budget.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  setAccKey: PropTypes.func.isRequired,
};

SettingsKEKV.defaultProps = {
  readOnly: false,
};

export default memo(SettingsKEKV);
