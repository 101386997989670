import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import TableRenderer from './tableRenderer';

function Table({
  name, columns, rows, cellStyles,
  activeCell, editMode, onActivateCell, onResizeColumn, fixedLeft, fixedTop, onReportDetails,
  scroller, pageHeaderTop, pageHeaderDown, maxWidth,
}) {
  const columnsTemplate = useMemo(
    () => columns.reduce(
      (R, c) => `${R} 
      & td.col${c.get('id')}{
        width: ${(c.get('width', 0) / 16)}rem;
      }`,
      '',
    ),
    [columns],
  );

  const styles = useMemo(
    () => cellStyles.reduce(
      (R, style) => `
      ${R}
        &  .CellStyle${style.get('id', '')}{
          ${style.get('style', '')}
        }`,
      '',
    ),
    [cellStyles],
  );

  const { preHeaderRows, headerRows, bodyRows } = useMemo(
    () => ({
      preHeaderRows: rows.filter((r) => r.get('id') < pageHeaderTop),
      headerRows: rows.filter((r) => r.get('id') >= pageHeaderTop && r.get('id') <= pageHeaderDown),
      bodyRows: rows.filter((r) => r.get('id') > pageHeaderDown),
    }),
    [pageHeaderDown, pageHeaderTop, rows],
  );
  return (
    <>
      {preHeaderRows.size > 0 && (
        <TableRenderer
          // headerRows={headerRows}
          bodyRows={preHeaderRows}
          name={name}
          columns={columns}
          addStyles={`${styles} ${columnsTemplate} `}
          activeCell={activeCell}
          editMode={editMode}
          onResizeColumn={onResizeColumn}
          onActivateCell={onActivateCell}
          onReportDetails={onReportDetails}
          scroller={scroller}
          fixedTop={fixedTop}
          fixedLeft={fixedLeft}
          maxWidth={maxWidth / 16}
        />
      )}
      {(headerRows.size > 0 || bodyRows.size > 0) && (
        <TableRenderer
          headerRows={headerRows}
          bodyRows={bodyRows}
          name={name}
          columns={columns}
          addStyles={`${styles} ${columnsTemplate} `}
          activeCell={activeCell}
          editMode={editMode}
          onResizeColumn={onResizeColumn}
          onActivateCell={onActivateCell}
          onReportDetails={onReportDetails}
          scroller={scroller}
          fixedTop={fixedTop}
          fixedLeft={fixedLeft}
          maxWidth={maxWidth / 16}
        />
      )}
    </>
  );
}

Table.propTypes = {
  name: PropTypes.number.isRequired,
  columns: PropTypes.instanceOf(List).isRequired,
  rows: PropTypes.instanceOf(List).isRequired,
  cellStyles: PropTypes.instanceOf(List).isRequired,
  activeCell: PropTypes.shape({
    row: PropTypes.number,
    column: PropTypes.number,
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  onActivateCell: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  fixedLeft: PropTypes.number.isRequired,
  fixedTop: PropTypes.number.isRequired,
  onReportDetails: PropTypes.func.isRequired,
  scroller: PropTypes.instanceOf(Element).isRequired,
  pageHeaderTop: PropTypes.number,
  pageHeaderDown: PropTypes.number,
  maxWidth: PropTypes.number.isRequired,
};

Table.defaultProps = {
  pageHeaderTop: 0,
  pageHeaderDown: 0,
};

export default memo(Table);
