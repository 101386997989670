import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  bySubLevelCSU: {
    label: 'За данними підлеглих розпорядників',
    name: 'флПоДаннымНижнихРасп',
  },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },
  printReportParams: {
    label: 'Друкувати параметри звіту',
    name: 'флПечатьСодержанияФильтра',
  },
  dataType: { label: 'Формувати дані за', name: 'ВидДанных' },
  atLoan: { label: 'За рахунок позики', name: 'флЗаРахПозики' },
  docType: { label: 'Вид докумету', name: 'флВидДокумента' },
  financedTreasure: { label: 'За списаними документами', name: 'флПрофинансированКазной' },

};

const definition = {
  label: 'Картка фінансування',
  name: 'PaymentList',
  columns,
  frontend: 'rep/PaymentList',
  backendName: 'НовКарточкаФинансированияСКД',
  resultColumns: baseResultColumns,
};

export default definition;
