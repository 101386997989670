import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import { modelName, modelType } from '../def';
import SendToLogicaEditorButton from '../../../Logica/sendToLogicaEditorButton';
import PrintModal from './print_submenu';

const APMenu = ({
  id, logikaId, onSR, changed, loading, ...rest
}) => (
  <DocEditorCommandPanel
    id={id}
    onSR={onSR}
    changed={changed}
    loading={loading}
    {...rest}
  >
    <PrintModal id={id} />
    <ButtonGroup>
      <SendToLogicaEditorButton
        modelType={modelType}
        modelName={modelName}
        docId={id}
      />
    </ButtonGroup>
  </DocEditorCommandPanel>
);

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  logikaId: PropTypes.string.isRequired,
  onSR: PropTypes.func.isRequired,
  changed: PropTypes.bool,
  loading: PropTypes.bool,
};

APMenu.defaultProps = {
  changed: false,
  loading: false,
};

export default APMenu;
