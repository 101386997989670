import React, { useCallback, useState } from 'react';
import { fromJS } from 'immutable';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import PrintingEditor from '../../../dataProcessors/printPayment/editor';
import md from '../../../../constants/meta/dataprocessors/printPayment';

function PrintModal({ selectedRows }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => fromJS({
      Document: {
        id: selectedRows.length ? selectedRows[0] : null,
        repr: '-',
      },
    }),
    [selectedRows],
  );
  return (
    <>
      <Button
        onClick={() => setOpened(true)}
        disabled={selectedRows.length === 0}
        variant="primary"
        size="sm"
      >
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Друк
      </Button>

      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            {md.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintingEditor
            mapInitialOptions={{
              Document: 'ДокументСсылка',
            }}
            mapStore={mapStore}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

PrintModal.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrintModal;
