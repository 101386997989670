import React from 'react';
import {
  Card, Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../../../components/styledAssigmButton';
import { PasswordIcon } from '../../../../assets/icons';
import StyledTPPane from '../../../../components/tableParts/TPPane';
import md from '../../../../constants/meta/catalogs/users';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { BudgetTP, HigherLevelTP } from './tabs';

const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

function TablesRender({ data, actions, permissions }) {
  const allowedRoles = md.columns.allowedRoles.name in data && Object.values(
    data[md.columns.allowedRoles.name],
  ).map(({ repr, id }) => ({
    display_name: repr,
    value: id,
  }));
  const readOnly = !permissions.canChange;
  const page1 = (
    <Card>
      <Card.Body>
        <Card.Title>Пароль</Card.Title>
        <Container fluid>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Password.label}
                value={data[md.columns.Password.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Password.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PasswordConfirmation.label}
                value={data[md.columns.PasswordConfirmation.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PasswordConfirmation.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  const newPasswd = Math.random().toString(36).slice(-8);
                  actions.onChange({
                    [md.columns.Password.name]: newPasswd,
                    [md.columns.PasswordConfirmation.name]: newPasswd,
                    [md.columns.PasswordF.name]: `Користувач: ${data[md.columns.Name.name]}, пароль: ${newPasswd}`,
                  });
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={PasswordIcon} style={{ height: '16px', marginRight: '10px' }} />
                Сгенерувати пароль
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.PasswordF.label}
                value={data[md.columns.PasswordF.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PasswordF.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.SelectorInput
                label={md.columns.Roles.label}
                value={data[md.columns.Roles.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Roles.name]: value,
                })}
                values={allowedRoles}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.ContractCode.label}
                value={data[md.columns.ContractCode.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ContractCode.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.TelephoneNumber.label}
                value={data[md.columns.TelephoneNumber.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.TelephoneNumber.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.Email.label}
                value={data[md.columns.Email.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Email.name]: value,
                })}
                maxLength={150}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.official.label}
                value={data[md.columns.official.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.official.name]: value,
                })}
                modelType="cat"
                modelName="officials"
                readOnly={readOnly}
              />
            </Col>
            {/* <div> */}
            {/* <StyledLabel>{def.columns.UserIDIB.label}</StyledLabel> */}
            {/* <Fields.TextAreaField */}
            {/* value={headerForm.get('UserIDIB', '')} */}
            {/* /> */}
            {/* </div> */}
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
  const page2 = (
    <>
      <Card>
        <Container fluid>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.Budget.label}
                value={data[md.columns.Budget.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Budget.name]: value,
                })}
                modelType="cat"
                modelName="budget"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.FinancialInstitution.label}
                value={data[md.columns.FinancialInstitution.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FinancialInstitution.name]: value,
                })}
                modelType="cat"
                modelName="csu"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`EditOnlyOwnDocuments-${data.id}`}
                    label={md.columns.EditOnlyOwnDocuments.label}
                    value={data[md.columns.EditOnlyOwnDocuments.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.EditOnlyOwnDocuments.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`LevelAdmin-${data.id}`}
                    label={md.columns.LevelAdmin.label}
                    value={data[md.columns.LevelAdmin.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.LevelAdmin.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`AllowAccessToStewardsOfLowerLevels-${data.id}`}
                    label={md.columns.AllowAccessToStewardsOfLowerLevels.label}
                    value={data[md.columns.AllowAccessToStewardsOfLowerLevels.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.AllowAccessToStewardsOfLowerLevels.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Керування затвердженими документами</Card.Title>
          <Container>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowRerunOfEnforcedRequests-${data.id}`}
                  label={md.columns.AllowRerunOfEnforcedRequests.label}
                  value={data[md.columns.AllowRerunOfEnforcedRequests.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowRerunOfEnforcedRequests.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditToStewardsOfHL-${data.id}`}
                  label={md.columns.AllowEditToStewardsOfHL.label}
                  value={data[md.columns.AllowEditToStewardsOfHL.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditToStewardsOfHL.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditClassifierElements-${data.id}`}
                  label={md.columns.AllowEditClassifierElements.label}
                  value={data[md.columns.AllowEditClassifierElements.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditClassifierElements.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowMultipleSessions-${data.id}`}
                  label={md.columns.AllowMultipleSessions.label}
                  value={data[md.columns.AllowMultipleSessions.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowMultipleSessions.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditBudgetCatalogs-${data.id}`}
                  label={md.columns.AllowEditBudgetCatalogs.label}
                  value={data[md.columns.AllowEditBudgetCatalogs.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditBudgetCatalogs.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditChargingCatalogs-${data.id}`}
                  label={md.columns.AllowEditChargingCatalogs.label}
                  value={data[md.columns.AllowEditChargingCatalogs.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditChargingCatalogs.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowedToChangeDateOfRestriction-${data.id}`}
                  label={md.columns.AllowedToChangeDateOfRestriction.label}
                  value={data[md.columns.AllowedToChangeDateOfRestriction.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowedToChangeDateOfRestriction.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditCatalogsForPCM-${data.id}`}
                  label={md.columns.AllowEditCatalogsForPCM.label}
                  value={data[md.columns.AllowEditCatalogsForPCM.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditCatalogsForPCM.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowImportOfTreasuryFiles-${data.id}`}
                  label={md.columns.AllowImportOfTreasuryFiles.label}
                  value={data[md.columns.AllowImportOfTreasuryFiles.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowImportOfTreasuryFiles.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowSignGroupManage-${data.id}`}
                  label={md.columns.AllowSignGroupManage.label}
                  value={data[md.columns.AllowSignGroupManage.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowSignGroupManage.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditCatalogsOR-${data.id}`}
                  label={md.columns.AllowEditCatalogsOR.label}
                  value={data[md.columns.AllowEditCatalogsOR.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditCatalogsOR.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowViewingOwnRequestsOnHighestLevel-${data.id}`}
                  label={md.columns.AllowViewingOwnRequestsOnHighestLevel.label}
                  value={data[md.columns.AllowViewingOwnRequestsOnHighestLevel.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowViewingOwnRequestsOnHighestLevel.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowToEditTreasuryAccounts-${data.id}`}
                  label={md.columns.AllowToEditTreasuryAccounts.label}
                  value={data[md.columns.AllowToEditTreasuryAccounts.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowToEditTreasuryAccounts.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditCatalogsOB-${data.id}`}
                  label={md.columns.AllowEditCatalogsOB.label}
                  value={data[md.columns.AllowEditCatalogsOB.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditCatalogsOB.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`OficiersCreationBan-${data.id}`}
                  label={md.columns.OficiersCreationBan.label}
                  value={data[md.columns.OficiersCreationBan.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.OficiersCreationBan.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`AllowEditBoilerTreasuryAccounts-${data.id}`}
                  label={md.columns.AllowEditBoilerTreasuryAccounts.label}
                  value={data[md.columns.AllowEditBoilerTreasuryAccounts.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.AllowEditBoilerTreasuryAccounts.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Обмеження по часу</Card.Title>
          <Container fluid>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`TimeLimit-${data.id}`}
                  label={md.columns.TimeLimit.label}
                  value={data[md.columns.TimeLimit.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.TimeLimit.name]: value,
                  })}
                  readOnly={readOnly}
                />
                <EditorControls.DateInput
                  label={md.columns.WorkAfter.label}
                  value={data[md.columns.WorkAfter.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.WorkAfter.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`ForbidToSpendAdditionalFinancing-${data.id}`}
                  label={md.columns.ForbidToSpendAdditionalFinancing.label}
                  value={data[md.columns.ForbidToSpendAdditionalFinancing.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.ForbidToSpendAdditionalFinancing.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`HeadOB-${data.id}`}
                  label={md.columns.HeadOB.label}
                  value={data[md.columns.HeadOB.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.HeadOB.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`RestrictToRemoveSignFromPlanDocs-${data.id}`}
                  label={md.columns.RestrictToRemoveSignFromPlanDocs.label}
                  value={data[md.columns.RestrictToRemoveSignFromPlanDocs.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.RestrictToRemoveSignFromPlanDocs.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`WithoutIncomeInAnalise-${data.id}`}
                  label={md.columns.WithoutIncomeInAnalise.label}
                  value={data[md.columns.WithoutIncomeInAnalise.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.WithoutIncomeInAnalise.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`CreateActualOutcome-${data.id}`}
                  label={md.columns.CreateActualOutcome.label}
                  value={data[md.columns.CreateActualOutcome.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.CreateActualOutcome.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`Import-${data.id}`}
                  label={md.columns.Import.label}
                  value={data[md.columns.Import.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.Import.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`WithoutTranscript-${data.id}`}
                  label={md.columns.WithoutTranscript.label}
                  value={data[md.columns.WithoutTranscript.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.WithoutTranscript.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`ChangeFO-${data.id}`}
                  label={md.columns.ChangeFO.label}
                  value={data[md.columns.ChangeFO.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.ChangeFO.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`WithoutAccounts-${data.id}`}
                  label={md.columns.WithoutAccounts.label}
                  value={data[md.columns.WithoutAccounts.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.WithoutAccounts.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
              <Col>
                <EditorControls.CheckboxInput
                  controlId={`Head-${data.id}`}
                  label={md.columns.Head.label}
                  value={data[md.columns.Head.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.Head.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      {/* <ThreeColumnsGrid> */}
      {/* <div> */}
      {/* <StyledLabel>{def.columns.PanelSectionLocation.label}</StyledLabel> */}
      {/* <InputStyled */}
      {/* border */}
      {/* value={headerForm.get('PanelSectionLocation', '')} */}
      {/* onChange={(e, value) => */}
      {/* dispatch(actions.changeField(['headerForm', 'PanelSectionLocation'], value)) */}
      {/* } */}
      {/* /> */}
      {/* </div> */}
      {/* <div> */}
      {/* <StyledLabel>{def.columns.UserIDIB.label}</StyledLabel> */}
      {/* <Fields.TextAreaField */}
      {/* value={headerForm.get('UserIDIB', '')} */}
      {/* /> */}
      {/* </div> */}
      {/* </ThreeColumnsGrid> */}
    </>
  );

  const page3 = (
    <StyledPane>
      <Tabs>
        <Tab title={md.tables.budget.label} eventKey="budget">
          <StyledTPPane>
            <BudgetTP data={data} actions={actions} readOnly={readOnly} />
          </StyledTPPane>
        </Tab>
        <Tab title={md.tables.higherLevelManager.label} eventKey="higherLevelManager">
          <StyledTPPane>
            <HigherLevelTP data={data} actions={actions} readOnly={readOnly} />
          </StyledTPPane>
        </Tab>
      </Tabs>
    </StyledPane>
  );

  return (
    <Tabs>
      <Tab title="Основна" eventKey="general">
        <StyledPane>
          {page1}
        </StyledPane>
      </Tab>
      <Tab title="Доступ" eventKey="access">
        <StyledPane>
          {page2}
        </StyledPane>
      </Tab>
      <Tab title="Додатково Доступ" eventKey="dopAccess">
        {page3}
      </Tab>
    </Tabs>
  );
}

TablesRender.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TablesRender;
