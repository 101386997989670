import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Card, ListGroup, Row,
  CardGroup, Alert, Accordion, FormCheck, Badge,
} from 'react-bootstrap';
import {
  faBuildingColumns,
  faUpload, faGear, faUser, faClock,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquareCheck, faSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { SetPeriodButton } from '../../../../../newLister/tableComponent/commandpanel/buttons';
import IconButton from '../../../../../../components/blanks/common/IconButton';
import Flex from '../../../../../../components/blanks/common/Flex';
import useFilter from '../../../../../basicEditor/stdFilters/useFilter';
import { Loader } from '../../../../../../components/bootStrap';
import { CPButton } from '../../../../../../components/bootStrap/buttons';
import { moneysStingOptions } from '../../../../../../constants/moneys';
import IconAlert from '../../../../../../components/blanks/common/IconAlert';
import formatDate from './dateFormat';
import md from '../../../../../../meta/doc/budgetPassport';

const API_URL = '/api/budgetrequest/passportbprow/';

const passportTables = {
  passportbpassignmentrow_set: 'Завдання',
  passportbppublicpolicytarget_set: 'Цілі державної політики',
  passportbpusingbudgetfundsrow_set: 'Напрями використання коштів',
  passportbpregionaltargetprogramrow_set: 'Державні/регіональні цільові програми',
  passportbpindicatorrow_set: 'Показники',
  // programFinancingSource: 'Джерела фінансування інвестиційних програм',
};

function LowerLevelLoading({
  overrideFilter,
  defaultFilter,
  docDate,
  onLoadHandler,
}) {
  const [localValue, setLocalValue] = useState({});
  const [open, setOpen] = useState(true);
  const [docsToLoad, setDocsToLoad] = useState([]);
  const [loadingTables, setLoadingTables] = useState(Object.keys(passportTables));
  const [openTableList, setOpenTableList] = useState(0);
  const [received, setReceived] = useState(false);
  const [totSum, setTotSum] = useState(0);

  const {
    items, loading, filters, setFilterHandler, error, ready,
  } = useFilter({ apiUrl: API_URL, defaultFilter, overrideFilter });

  const addDocToLoad = useCallback((newDoc) => {
    setDocsToLoad([...docsToLoad, newDoc]);
  }, [docsToLoad]);

  const deleteDocToLoad = (docToDelete) => {
    const updatedDocs = docsToLoad.filter((doc) => doc !== docToDelete);
    setDocsToLoad(updatedDocs);
  };

  useEffect(
    () => {
      setTotSum(docsToLoad.reduce(
        (accumulator, d) => {
          const arr = items.filter((v) => v.id === d);
          if (arr.length) {
            return accumulator + arr[0].amount_total;
          }
          return accumulator;
        },
        0,
      ));
    },
    [docsToLoad, items],
  );

  useEffect(
    () => {
      setLocalValue(
        {
          start: `${overrideFilter.year}-01-01`,
          end: docDate,
        },
      );
    },
    [docDate, overrideFilter.year],
  );

  useEffect(() => {
    const docs = items.map((v) => v.id);
    setDocsToLoad(docs);
    if (items.length) { setOpen(false); }
  }, [setDocsToLoad, items]);

  const uploadDocs = useCallback(
    () => {
      onLoadHandler(docsToLoad, loadingTables);
    },
    [docsToLoad, onLoadHandler, loadingTables],
  );

  const updateLoadingTables = (v) => {
    if (loadingTables.includes(v)) {
      const updatedTables = loadingTables.filter((tb) => tb !== v);
      setLoadingTables(updatedTables);
    } else {
      setLoadingTables([...loadingTables, v]);
    }
  };

  return ready && (
    <Container fluid>
      {loading && (
        <Loader text="Завантаження" />
      )}
      {error && (
        <Alert variant="danger">
          <Alert.Heading>
            {error}
          </Alert.Heading>
        </Alert>
      )}
      <Row>
        { open && (
          <Col lg={4}>
            <Card className="mb-3">
              <Card.Body className="bg-light">
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>{overrideFilter?.authority?.repr}</ListGroup.Item>
                  <ListGroup.Item>{`${overrideFilter?.year} рік`}</ListGroup.Item>
                  <ListGroup.Item>{overrideFilter?.kbp7?.repr}</ListGroup.Item>
                  <ListGroup.Item className="d-flex">
                    {`${formatDate(filters.begin_date.value)} - ${formatDate(filters.end_date.value)}`}
                    <div className="ms-auto">
                      <SetPeriodButton
                        content=""
                        value={localValue}
                        onChange={(e, v) => {
                          setFilterHandler('begin_date', v.start, true);
                          setFilterHandler('end_date', v.end, true);
                          setLocalValue(v);
                        }}
                      />
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex">
                      Таблиці для завантаження
                      <div className="ms-auto">
                        <CPButton
                          variant="info"
                          content={loadingTables.length}
                          onClick={() => setOpenTableList(!openTableList)}
                        />
                      </div>
                    </div>
                    <Accordion activeKey={!openTableList ? '0' : '1'}>
                      <Accordion.Collapse eventKey="1">
                        <ListGroup className="list-group-flush">
                          {Object.entries(passportTables).map(([k, v]) => (
                            <FormCheck
                              label={v}
                              onChange={() => updateLoadingTables(k)}
                              checked={loadingTables.includes(k)}
                            />
                          ))}
                        </ListGroup>
                      </Accordion.Collapse>
                    </Accordion>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FormCheck
                      label="Завантажувати отримані"
                      onChange={(e) => {
                        setFilterHandler('received', e.target.checked, true);
                        setReceived(e.target.checked);
                      }}
                      checked={received}
                    />
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col>
          <Row>
            <Flex>
              <IconButton
                variant="falcon-default"
                size="sm"
                className="ms-sm-1"
                icon={faGear}
                onClick={() => { setOpen(!open); }}
              />
              <IconButton
                variant="falcon-default"
                size="sm"
                className="ms-sm-1"
                onClick={() => {
                  if (docsToLoad.length === items.length) {
                    setDocsToLoad([]);
                  } else {
                    const docs = items.map((v) => v.id);
                    setDocsToLoad(docs);
                  }
                }}
                icon={docsToLoad.length === items.length ? faSquare : faSquareCheck}
              />
              <IconButton
                variant="success"
                size="sm"
                className="ms-sm-1"
                icon={faUpload}
                onClick={() => { uploadDocs(); }}
              >
                Завантажити
              </IconButton>
              <div className="ms-auto">
                Відмічено
                <Badge className="mx-2" variant="dark">{docsToLoad.length}</Badge>
                {`документ${(() => {
                  switch (docsToLoad.length) {
                    case 1:
                      return '';
                    case 2:
                    case 3:
                    case 4:
                      return 'и';
                    default:
                      return 'ів';
                  }
                })()} на суму`}
                <Badge className="mx-2" variant="dark">{totSum.toLocaleString('uk', moneysStingOptions)}</Badge>
                грн
              </div>
            </Flex>
          </Row>
          <Row className="mt-2">
            {items.length === 0 ? (
              <IconAlert variant="info" className="my-2 mx-1">
                По заданим параметрам відбору документи не знайдені
              </IconAlert>
            ) : (
              <CardGroup>
                {items.map((doc) => (
                  <Card key={doc.id}>
                    <Card.Body>
                      <Card.Title className="d-flex">
                        <Link to={`${md.frontendURL}${doc.id}`}>
                          {doc.repr}
                          <h6>{doc.amount_total.toLocaleString('uk', moneysStingOptions)}</h6>
                        </Link>
                        <div className="ms-auto">
                          <IconButton
                            variant={docsToLoad.includes(doc.id)
                              ? 'success' : 'outline-secondary'}
                            onClick={() => {
                              if (docsToLoad.includes(doc.id)) {
                                deleteDocToLoad(doc.id);
                              } else { addDocToLoad(doc.id); }
                            }}
                            icon={docsToLoad.includes(doc.id) ? faSquareCheck : faSquare}
                          />
                        </div>
                      </Card.Title>
                      <Card.Text>
                        <hr />
                        <FontAwesomeIcon icon={faBuildingColumns} className="mx-2" />
                        {doc.authority.repr}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex _justify-content-between text-muted">
                      <h6 className="mb-0 text-600 lh-base align-items-center">
                        <FontAwesomeIcon
                        // icon={['faFar', 'faClock']}
                          icon={faClock}
                          className="me-1"
                        />
                        {formatDate(doc.doc_date)}
                      </h6>
                      <div className="ms-auto">
                        <Card.Text className="text-end">
                          <FontAwesomeIcon icon={faUser} className="mx-2" />
                          {doc.author.repr}
                        </Card.Text>
                      </div>
                    </Card.Footer>
                  </Card>
                ))}
              </CardGroup>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

LowerLevelLoading.propTypes = {
  overrideFilter: PropTypes.shape({
    authority: PropTypes.shape({
      repr: PropTypes.string.isRequired,
    }),
    year: PropTypes.string,
    kbp7: PropTypes.shape({
      repr: PropTypes.string.isRequired,
    }),
  }),
  defaultFilter: PropTypes.shape({}),
  docDate: PropTypes.string,
  onLoadHandler: PropTypes.func.isRequired,
};

LowerLevelLoading.defaultProps = {
  overrideFilter: {},
  defaultFilter: {},
  docDate: null,
};

export default LowerLevelLoading;
