import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import enums from '../../../../constants/meta/enums';
import { PeriodForm } from '../../../fields/boostrap';

const shortFormat = { day: '2-digit', month: '2-digit', year: 'numeric' };

function PeriodInput({
  disabled, onChange, value, size,
}) {
  const [opened, setOpened] = useState(false);
  const ref = useRef();
  const [localValue, setLocalValue] = useState({});
  useEffect(
    () => {
      if (value != null) {
        setLocalValue({ startDate: value.StartDate, endDate: value.EndDate, variant: value.Variant });
      }
    },
    [value],
  );
  const displayValue = useMemo(
    () => {
      if (value != null) {
        const bDate = new Date(value.StartDate);
        const eDate = new Date(value.EndDate);
        bDate.setMinutes(bDate.getMinutes() + bDate.getTimezoneOffset());
        eDate.setMinutes(eDate.getMinutes() + eDate.getTimezoneOffset());
        return `${bDate.toLocaleString('uk', shortFormat)} - ${eDate.toLocaleString('uk', shortFormat)}`;
      }
      return '';
    },
    [value],
  );
  return (
    <>
      <div className="input-group" ref={ref}>
        <span className="form-control">{displayValue}</span>
        <div className="input-group-append">
          <Button
            variant="outline-secondary"
            disabled={disabled}
            onClick={() => setOpened(true)}
            size={size || 'sm'}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </div>
      </div>
      <Modal show={opened} onHide={() => setOpened(false)} container={ref}>
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть період
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeriodForm value={localValue} onChange={(e, v) => setLocalValue(v)} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={(e) => {
              setOpened(false);
              onChange(e, {
                StartDate: localValue.startDate,
                EndDate: localValue.endDate,
                Variant: localValue.variant,
              });
            }}
          >
            Встановити
          </Button>
          <Button variant="secondary" onClick={() => setOpened(false)}>Закрити</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
PeriodInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  value: PropTypes.shape({
    StartDate: PropTypes.number,
    EndDate: PropTypes.number,
    Variant: PropTypes.oneOf(Object.values(enums.PeriodSelection).map((v) => v.name)),
  }),
};

PeriodInput.defaultProps = {
  disabled: false,
  size: 'sm',
  value: {
    StartDate: 0,
    EndDate: 0,
    Variant: enums.PeriodSelection.Custom.name,
  },
};

export default PeriodInput;
