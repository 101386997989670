import React, {
  useEffect, useContext, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import WinManagerContext from '../../minfin/providers/WinManagerContext/context';
import { UID_RE } from '../../api/utils';
import minfin, { componentOnMinfin } from '../../constants/minfin';
import mdCat from '../../constants/meta/catalogs';
import { useSelector } from 'react-redux';
import { soSelector } from '../../containers/documents/_common/selectors';

function LinkComp({
  currentComponent, windowsName, leftToolBar, type, name,
}) {
  const {
    addComponentToWindowsManager, linkComponents, currentURL, switchWindow,
  } = useContext(WinManagerContext);
  const url = useLocation();
  const routerProps = useRef({});
  routerProps.current = useParams();
  const sessionParams = useSelector(soSelector);
  const cComponent = useMemo(
    // В случае если открываем справочник, который не на минфин из документа, который на минфин
    // например через форму выбора то открывать надо версию минфин чтобы он сразу отобразился в списке
    () => {
      if (type !== 'cat' || !routerProps.current.id ) {
        return currentComponent;
      }
      if (!componentOnMinfin(mdCat[name], 'Справочник', sessionParams) && !UID_RE.test(routerProps.current.id)
          && !['create', 'createGroup'].includes(routerProps.current.id) && minfin[mdCat[name].frontend]){
        return minfin[mdCat[name].frontend].editor
      }
      return currentComponent;
    },
    [currentComponent, name, sessionParams, type]
  )
  if (!currentComponent) {
    // eslint-disable-next-line no-console
    console.error(`Unknow Component on ${windowsName}`);
  }

  useEffect(
    () => {
      if (linkComponents.filter((l) => l.url === url.pathname).length) {
        if (currentURL !== url.pathname) {
          switchWindow(url.pathname);
        }
      }
    },
    [currentURL, linkComponents, switchWindow, url.pathname],
  );
  useEffect(
    () => {
      if (!linkComponents.filter((l) => l.url === url.pathname).length) {
        addComponentToWindowsManager(
          cComponent,
          url.pathname,
          windowsName,
          type,
          name,

          { ...routerProps.current, url: url.pathname, leftToolBar, search: url.search },
        );
      }
    },
    [
      addComponentToWindowsManager, cComponent, leftToolBar, linkComponents,
      windowsName, url.pathname, type, name, url.search],
  );
  return (
    <div className="d-none">{windowsName}</div>
  );
}

LinkComp.propTypes = {
  currentComponent: PropTypes.elementType,
  windowsName: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  leftToolBar: PropTypes.bool,
  props: PropTypes.shape({}),
};

LinkComp.defaultProps = {
  currentComponent: null,
  leftToolBar: false,
  name: '',
  type: '',
  props: {
  },
};

export default LinkComp;
