import React from 'react';
import PropTypes from 'prop-types';
import { InputWithDropdown } from '../../../../../../../../components/styledInputs';

const placement = {
  OVERFLOW: {
    label: 'Виступати',
    name: 'Overflow',
  },
  BLOCK: {
    label: 'Забивати',
    name: 'Block',
  },
  CUT: {
    label: 'Обрізати',
    name: 'Cut',
  },
  WRAP: {
    label: 'Переносити',
    name: 'Wrap',
  },
};

const enumValues = Object.values(placement);

function PlacementSelector({ value, onChange, noBorder }) {
  return (
    <InputWithDropdown
      value={value}
      onChange={onChange}
      options={enumValues}
      noBorder={noBorder}
    />
  );
}

PlacementSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(placement).map((v) => v.name)),
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
};

PlacementSelector.defaultProps = {
  value: placement.BLOCK.name,
  noBorder: false,
};

export default PlacementSelector;
