/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Container, Row,
} from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import { FieldPropType } from '../../../newEditor/propTypes';
import FE from './formulaEditor';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryIndicator({
  data, fields, actions, id, readOnlyFields, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.indicator.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });

  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const indicatorTypeProps = editorHooks.useSelectorInputProps('indicator_type', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const shownReportProps = editorHooks.useCheckboxInputProps('not_shown_report', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const calcAvgProps = editorHooks.useCheckboxInputProps('calc_avg', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const trendSignProps = editorHooks.useSelectorInputProps('trend_sign', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const calculatedProps = editorHooks.useCheckboxInputProps('calculated', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const formulaProps = editorHooks.useStringInputProps('formula', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const infosourceProps = editorHooks.useItemInputProps('infosource', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const measurementProps = editorHooks.useItemInputProps('measurement', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const decimalPlacesProps = editorHooks.useSelectorInputProps('decimal_places', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);
  const unitOfMeasureProps = editorHooks.useNumberInputProps('unit_of_measure', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange, false, readOnlyFields);

  const infoSourceFilter = useMemo(
    () => ({
      is_group: true,
      budget: data.budget ? data.budget.id : null,
    }),
    [data.budget],
  );

  return (
    <Card>
      <Card.Header>
        <HistoryCommandPanel
          currentStartDate={historyData.datefrom}
          histories={historiesList}
          historyLoading={historyLoading}
          onLoadHistories={historyActions.onLoadHistories}
          onSelectHistory={historyActions.onSelectHistory}
          onSaveHistory={historyActions.onSaveHistory}
          onCreateHistory={historyActions.onCreateHistory}
          onDeleteHistory={historyActions.onDeleteHistory}
          isNew={isNew}
        />
      </Card.Header>
      <Card.Body>
        <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
          <Container fluid>
            <Row>
              <Col md={6}>
                <EditorControls.StringInput {...nameProps} />
              </Col>
              <Col md={6}>
                {!data.is_group && (
                <EditorControls.ItemPicker {...infosourceProps} filter={infoSourceFilter} />
                )}
              </Col>
              <Col md={3}>
                {!data.is_group && (
                <EditorControls.SelectorInput {...indicatorTypeProps} />
                )}
              </Col>
              <Col md={3}>
                {!data.is_group && (
                <EditorControls.SelectorInput {...trendSignProps} />
                )}
              </Col>
              <Col md={4}>
                {!data.is_group && (
                <EditorControls.ItemPicker
                  {...measurementProps}
                  onChange={(e, v) => {
                    const { onChange } = unitOfMeasureProps;
                    const onChangeMeasurement = measurementProps.onChange;
                    onChange(e, v ? v.name : '');
                    onChangeMeasurement(e, v);
                  }}
                  required={unitOfMeasureProps.required || measurementProps.required}
                  errors={measurementProps.errors || unitOfMeasureProps.errors}
                />
                )}
              </Col>
              <Col md={2}>
                {!data.is_group && (
                <EditorControls.SelectorInput {...decimalPlacesProps} />
                )}
              </Col>

              <Col md={6}>
                {!data.is_group && (
                <EditorControls.CheckboxInput {...shownReportProps} />
                )}
                {!data.is_group && (
                <EditorControls.CheckboxInput {...calcAvgProps} />
                )}
                {!data.is_group && (
                <EditorControls.CheckboxInput {...calculatedProps} />
                )}
                <EditorControls.CheckboxInput // этот отвечает за Діючий
                  {...isActiveProps}
                  value={(id === 'create') ? true : isActiveProps.value}
                />
              </Col>
              <Col md={6}>
                {!data.is_group && (
                <FE id={data.id} budgetId={data.budget ? data.budget.id : null} {...formulaProps} />
                )}
              </Col>
            </Row>
          </Container>
        </HistoryContainer>
      </Card.Body>
    </Card>
  );
}

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
  fullname: PropTypes.string,
  goal: PropTypes.string,
  shown_report: PropTypes.bool,
  calc_avg: PropTypes.bool,
  format_string: PropTypes.string,
  calculated: PropTypes.bool,
  formula: PropTypes.string,
  infosource: PropTypes.shape({ }),
  parent: PropTypes.shape({ }),
  is_group: PropTypes.bool,
  indicator_type: PropTypes.number,
  // trait_type: PropTypes.number,
  trend_sign: PropTypes.number,
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryIndicator.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ),
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape(
      fieldsPropTypes,
    ),
  }),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}),
};

HistoryIndicator.defaultProps = {
  fields: {},
  data: {},
  readOnlyFields: [],
  errors: {},
};

export default HistoryIndicator;
