import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Tabs, Tab, Container, Row, Col, InputGroup,
} from 'react-bootstrap';
import { GeneralTP } from './tabs';
import md from '../../../../constants/meta/documents/financing';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import LowerDocs from './tabs/docs';

function FinancingTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  const FI = data[md.columns.FI.name];

  const FiOwnerFilter = useMemo(() => [
    { fieldName: 'Владелец', value: FI },
  ], [FI]);

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title="Передача коштів " eventKey="TransfertingZFToSF" disabled={!data[md.columns.TransfertingZFToSF.name]}>
        <Container fluid className="border border-top-0 py-3">
          <Row md={2}>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.elementKFBcred.label}
                value={data[md.columns.elementKFBcred.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={(e, value) => actions.onChange({
                  [md.columns.elementKFBcred.name]: value,
                })}
                filter={FiOwnerFilter}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {data[md.columns.codeKFBcred.name]}
                  </InputGroup.Text>

                )}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.elementKFB.label}
                value={data[md.columns.elementKFB.name]}
                modelType="cat"
                modelName="elementKFB"
                onChange={(e, value) => actions.onChange({
                  [md.columns.elementKFB.name]: value,
                })}
                filter={FiOwnerFilter}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {data[md.columns.codeKFB.name]}
                  </InputGroup.Text>

                )}
              />
            </Col>

            <Col>
              <EditorControls.ItemPicker
                label={md.columns.A3142.label}
                value={data[md.columns.A3142.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.A3142.name]: value,
                  });
                  actions.onSR('ZF_TO_SF_ON_CHANGE');
                }}
                modelType="cat"
                modelName="bankAccounts"
                filter={FiOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {data[md.columns.A3142Code.name]}
                  </InputGroup.Text>

                )}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.A3262.label}
                value={data[md.columns.A3262.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.A3262.name]: value,
                  });
                  actions.onSR('ZF_TO_SF_ON_CHANGE');
                }}
                modelType="cat"
                modelName="bankAccounts"
                filter={FiOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {data[md.columns.A3262Code.name]}
                  </InputGroup.Text>

                )}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.A3261.label}
                value={data[md.columns.A3261.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.A3261.name]: value,
                  });
                  actions.onSR('ZF_TO_SF_ON_CHANGE');
                }}
                modelType="cat"
                modelName="bankAccounts"
                filter={FiOwnerFilter}
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    {data[md.columns.A3261Code.name]}
                  </InputGroup.Text>

                )}
              />
            </Col>

            <Col className="d-flex align-items-end">
              <EditorControls.CheckboxInput
                controlId={`ShowAccounts-${data.id}`}
                label={md.columns.ShowAccounts.label}
                value={data[md.columns.ShowAccounts.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ShowAccounts.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label="Вид операції з фінансування"
                value={data[md.columns.Note.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Note.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
      </Tab>

      <Tab title={md.tables.descendantsDocuments.label} eventKey="descendantsDocuments">
        <LowerDocs data={data} actions={actions} readOnly={readOnly} />
      </Tab>

      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.Note.label}
            value={data[md.columns.Note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />

          <EditorControls.TextInput
            label={md.columns.Tags.label}
            value={data[md.columns.Tags.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Tags.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Container>
      </Tab>
    </Tabs>
  );
}

FinancingTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default FinancingTabs;
