import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CheckboxField from '../../../field/CheckBox';
import AppearanceField from './apperanceEditor';
import FilterField from './filter';
import FieldsField from './fields';
import UsesField from './uses';

function AppearanceRow({
  row, rowId, active, onClick, onChange, availableFields, mountPoint,
}) {
  const onRowClickHandler = useCallback(
    (e) => onClick(e, rowId),
    [onClick, rowId],
  );
  const onRowCheck = useCallback(
    (e, Use) => onChange(e, rowId, new Map({ Use })),
    [onChange, rowId],
  );

  const onAppearanceChange = useCallback(
    (e, Appearance) => onChange(e, rowId, new Map({ Appearance })),
    [onChange, rowId],
  );
  const onFilterChange = useCallback(
    (e, Filter) => onChange(e, rowId, new Map({ Filter })),
    [onChange, rowId],
  );
  const onFieldsChange = useCallback(
    (e, Fields) => onChange(e, rowId, new Map({ Fields })),
    [onChange, rowId],
  );

  const onUsesChange = useCallback(
    (e, attr, value) => onChange(e, rowId, new Map({ [attr]: value })),
    [onChange, rowId],
  );
  return (
    <tr className={active ? 'active' : ''} onClick={onRowClickHandler}>
      <td>
        <CheckboxField
          value={row.get('Use', false)}
          onChange={onRowCheck}
        />
      </td>
      <td>
        <AppearanceField
          value={row.get('Appearance')}
          onChange={onAppearanceChange}
        />
      </td>
      <td>
        <FilterField
          value={row.get('Filter')}
          onChange={onFilterChange}
          mountPoint={mountPoint}
          rowKey={rowId}
        />
      </td>
      <td>
        <FieldsField
          value={row.get('Fields')}
          onChange={onFieldsChange}
          availableFields={availableFields}
        />
      </td>
      <td>
        <UsesField
          value={row}
          onChange={onUsesChange}
        />
      </td>
    </tr>
  );
}

AppearanceRow.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  availableFields: PropTypes.instanceOf(Map).isRequired,
  mountPoint: PropTypes.string.isRequired,
};

AppearanceRow.defaultProps = {
  active: false,
};

export default memo(
  AppearanceRow,
);
