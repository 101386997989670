/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useMemo, memo, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { faFolder, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SListGroupItem } from '../styled';

const AvailableFieldItem = forwardRef(({
  id, item, onClickHandler, onDoubleClickHandler,
  dragHandleProps, draggableProps, level, onIconClickHandler, opened, isActive,
}, ref) => {
  const { itemIcon, iconClass, disabled } = useMemo(
    () => {
      if (item.Folder) return { itemIcon: faFolder, iconClass: 'text-warning', disabled: true };
      if (!item.itemsCount) return { itemIcon: faMinus, iconClass: 'text-gray', disabled: true };
      if (opened) return { itemIcon: faMinus, iconClass: 'text-success', disabled: false };
      return { itemIcon: faPlus, iconClass: 'text-success', disabled: false };
    },
    [item.Folder, item.itemsCount, opened],
  );
  const style = useMemo(
    () => ({
      marginLeft: `${(level || 0) * 1}rem`,
    }),
    [level],
  );
  return (
    <SListGroupItem
      ref={ref}
      active={isActive}
      level={level}
      onClick={() => onClickHandler(id)}
      className="d-flex align-items-center"
      onDoubleClick={() => onDoubleClickHandler(id)}
      {...dragHandleProps}
      {...draggableProps}
    >
      <FontAwesomeIcon
        style={style}
        icon={itemIcon}
        className={`${iconClass} me-2`}
        onClick={() => !disabled && onIconClickHandler(id)}
        role="button"
      />
      <span>
        {item.Tittle}
      </span>
    </SListGroupItem>
  );
});

AvailableFieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    Tittle: PropTypes.string.isRequired,
    itemsCount: PropTypes.number,
    Folder: PropTypes.bool,
  }).isRequired,
  onClickHandler: PropTypes.func.isRequired,
  onDoubleClickHandler: PropTypes.func.isRequired,
  onIconClickHandler: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.shape({}).isRequired,
  draggableProps: PropTypes.shape({}).isRequired,
  level: PropTypes.number,
  opened: PropTypes.bool,
  isActive: PropTypes.bool,
};

AvailableFieldItem.defaultProps = {
  level: 0,
  opened: false,
  isActive: false,
};

export default memo(AvailableFieldItem);
