import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  ID77: { label: 'Ид77', name: 'Ид77', visible: false },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: false,
  },
  CodeFond: { label: 'КодФонд', name: 'КодФонд', visible: true },
  owner: { label: 'Владелец', name: 'Владелец', visible: true },
};

export const explanations = {
  label: 'Пояснення',
  name: 'explanations',
  backendName: 'Пояснения',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/explanations',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default explanations;
