/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import useSelectAcc from '../../../../finRequest/editor/tp/planningRows/showAcc/selectAccHook';
import SelectAccModal from '../../../../finRequest/editor/tp/planningRows/showAcc/selectAccModal';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
  isRefundByMonth, isTransferCfSf, isTransfer, useFinProposal, isRefund,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'finorderrow_set', readOnlyFields, errors, fields,
  });
  const { disposer_in_header: disposerInHeader } = data;
  const disposerAccHeaderFilter = useMemo(
    () => ({ disposer: data.disposer ? data.disposer.id : null }),
    [data.disposer],
  );

  const totals = useMemo(
    () => tableData.reduce(
      (Res, r) => ({
        fin_commitment_amount: Res.fin_commitment_amount + (r.fin_commitment_amount || 0),
        balance: Res.balance + (r.balance || 0),
        amount: Res.amount + (r.amount || 0),
      }),
      {
        fin_commitment_amount: 0,
        balance: 0,
        amount: 0,
      },
    ),
    [tableData],
  );
  // Необходимые данные из шапки документа
  const authorityId = data.authority && data.authority.id;
  const fondId = data.fond && data.fond.id;

  const {
    showChoice, setShowChoice, rowAccounts, onCellChange,
  } = useSelectAcc({
    activeRow,
    fond: data.fond,
    tableData,
    headerDisposer: disposerInHeader && data.disposer,
    onChange: tableActions.onCellChange,
  });

  return (
    <Card className="border-0">
      <SelectAccModal
        show={showChoice}
        onHide={() => setShowChoice(false)}
        accounts={rowAccounts}
        onSelect={(e, acc) => {
          tableActions.onCellChange(e, activeRow, { disposer_acc: acc });
          setShowChoice(false);
        }}
      />
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          totals={totals}
          disposerInHeader={disposerInHeader}
          isRefundByMonth={isRefundByMonth}
          isTransferCfSf={isTransferCfSf}
          useFinProposal={useFinProposal}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              disposerInHeader={disposerInHeader}
              disposerAccHeaderFilter={disposerAccHeaderFilter}
              authorityId={authorityId}
              fondId={fondId}
              isRefundByMonth={isRefundByMonth}
              isRefund={isRefund}
              isTransferCfSf={isTransferCfSf}
              isTransfer={isTransfer}
              useFinProposal={useFinProposal}
            />
          </TableRow>
        ))}
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    onlyYear: PropTypes.bool,
    disposer_in_header: PropTypes.bool,
    vt4br: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: foreignPropType,
    fond: foreignPropType,
    disposer: foreignPropType,
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    allocationplanrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  isRefundByMonth: PropTypes.bool,
  isRefund: PropTypes.bool,
  isTransferCfSf: PropTypes.bool,
  isTransfer: PropTypes.bool,
  useFinProposal: PropTypes.bool,
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
  isRefundByMonth: false,
  isRefund: false,
  isTransferCfSf: false,
  isTransfer: false,
  useFinProposal: false,
};

export default PlanningRows;
