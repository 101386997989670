import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/draftAnnualSpend';

const tablename = 'tvDoc';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters className="align-items-center">
          <Col
            xl={1}
            sm={12}
            className="text-center"
          >
            <TableRowCell column="prim" highlighted={highlights.includes('prim')}>
              <CheckboxInput
                id={`prim-${row[tableMD.columns.doc.name]}`}
                value={row[tableMD.columns.prim.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prim.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col
            xl={8}
            sm={12}
          >
            <TableRowCell column="docP" highlighted={highlights.includes('docP')}>
              <StringInput
                value={row[tableMD.columns.docP.name]}
                onChange={() => null}
                readOnly
              />
            </TableRowCell>
          </Col>
          <Col
            xl={3}
            sm={12}
          >
            <TableRowCell column="kbp" highlighted={highlights.includes('kbp')}>
              <StringInput
                value={row[tableMD.columns.kbp.name]}
                onChange={() => null}
                readOnly
              />
            </TableRowCell>
          </Col>
          {/* <Col */}
          {/*  xl={3} */}
          {/*  sm={12} */}
          {/* > */}
          {/*  <TableRowCell column="elementKFK" highlighted={highlights.includes('elementKFK')}> */}
          {/*    <StringInput */}
          {/*      value={row[tableMD.columns.elementKFK.name]} */}
          {/*      onChange={() => null} */}
          {/*      readOnly */}
          {/*    /> */}
          {/*  </TableRowCell> */}
          {/* </Col> */}
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
