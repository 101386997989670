import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  compareType: { label: 'Вид порівняння', name: 'ВидСравнения' },

  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const mainGroupFields = [
  'КВК',
  'Расп',
  'Фонд',
  'ЭлементФонд',
  'КБП',
  'КБП7',
  'ЭлементКФК',
  'КЕКВ',
  'ЭлементКЕКВ',
  'Регистратор',
  'Источник',
];

const definition = {
  label: 'Порівняння видатків (між рівнями)',
  name: 'CompareLevels',
  columns,
  frontend: 'rep/CompareLevels',
  backendName: 'СравнениеРасходов',
  resultColumns: baseResultColumns,
  mainGroupFields,
};

export default definition;
