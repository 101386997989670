import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button, Card, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faUser, faLock, faLockOpen,
} from '@fortawesome/free-solid-svg-icons';
import md from '../../../../../../constants/meta/documents/transfertPlanning';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';
import DocsCommandPanel from '../../../../../newEditor/commandPanels/docstp';
import { referencePropType } from '../../../../../newEditor/propTypes';
import DocSelectionModal from '../../docSelectionModal';

const tableMeta = md.tables.lowLevelDocuments;

function LowerDocs({ data, actions, readOnly }) {
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const {
    tableActions,
  } = useLowerDocs({
    tableName: tableMeta.name,
    onChange: actions.onChange,
    approvedName: tableMeta.columns.isApproved.name,
  });
  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LOAD_FROM_DOC_SELECTION', loadData);
    },
    [actions],
  );

  return (
    <>
      <DocsCommandPanel tableActions={tableActions} readOnly={readOnly}>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            year={data[md.columns.year.name]}
            planingMode={data[md.columns.planingMode.name]}
            FI={data[md.columns.FI.name]}
            fondObject={data[md.columns.fondObject.name]}
            readOnly={readOnly}
          />
        </Col>
      </DocsCommandPanel>
      <>
        {tableData.map((doc, rowId) => (
          <Card key={doc[tableMeta.columns.document.name].id}>
            <Card.Body>
              <Card.Title>
                <Link to={`/${md.frontend}/${doc[tableMeta.columns.document.name].id}`}>
                  {doc[tableMeta.columns.document.name].repr}
                </Link>
              </Card.Title>
              <div className="d-flex">
                <Button
                  variant={doc[tableMeta.columns.isApproved.name] ? 'outline-success' : 'outline-danger'}
                  onClick={(e) => tableActions.onToggleApproveDoc(e, rowId)}
                >
                  <FontAwesomeIcon icon={doc[tableMeta.columns.isApproved.name] ? faLock : faLockOpen} className="me-2" />
                  {doc[tableMeta.columns.isApproved.name] ? 'Отримано' : 'Не отримано'}
                </Button>

              </div>
            </Card.Body>
            <Card.Footer className="d-flex _justify-content-between text-muted">
              <Card.Text className="flex-grow-1">
                {doc[tableMeta.columns.CSU.name].repr && (
                  <>
                    <FontAwesomeIcon icon={faBuilding} className="me-2" />
                    {doc[tableMeta.columns.CSU.name].repr}
                  </>
                )}
              </Card.Text>
              <Card.Text>
                <FontAwesomeIcon icon={faUser} className="me-2" />
                {doc[tableMeta.columns.author.name].repr}
              </Card.Text>
            </Card.Footer>
          </Card>
        ))}
      </>
    </>
  );
}

LowerDocs.propTypes = {
  data: PropTypes.shape({
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({
      [tableMeta.columns.document.name]: referencePropType,
      [tableMeta.columns.CSU.name]: referencePropType,
      [tableMeta.columns.author.name]: referencePropType,
    })),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

LowerDocs.defaultProps = {
  readOnly: false,
};

export default LowerDocs;
