import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { industry as md } from '../../../../constants/meta/catalogs/industry';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function Industry({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

Industry.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Industry;
