import React from 'react';
import PropTypes from 'prop-types';
import {
  SaveButton, UndoButton, RedoButton, YouTubeButton, HelperButton,
} from '../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../components/bootStrap';
import { useMD } from '../../newLister/hooks/md';

function CatCommandPanel({
  permissions, changed,
  actions, children, backendURL,
}) {
  const md = useMD(backendURL);
  return (
    <CommandPanel
      className="me-1 ms-auto "
      rtl
      leftPart={(
        <>
          <SaveButton
            onClick={actions.onSaveWithoutExit}
            disabled={!permissions.canSave || !changed}
          />
          <UndoButton onClick={actions.onUndo} disabled={!permissions.canUndo} />
          <RedoButton
            onClick={actions.onRedo}
            disabled={!permissions.canRedo}
          />
          <YouTubeButton
            urls={md.youtubeVideo}
          />
          <HelperButton
            backendURL={backendURL}
          />
          {children}
        </>
        )}
    />
  );
}

CatCommandPanel.propTypes = {
  backendURL: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    canSave: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    canClose: PropTypes.bool,
    canChange: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onSaveWithoutExit: PropTypes.func,
    onSaveNExit: PropTypes.func,
    onUndo: PropTypes.func,
    onRedo: PropTypes.func,
    onClose: PropTypes.func,
    onLoading: PropTypes.func,

  }).isRequired,
  changed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

CatCommandPanel.defaultProps = {
  changed: false,
  children: null,
};

export default CatCommandPanel;
