import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconButton({
  icon,
  iconAlign,
  iconClassName,
  transform,
  children,
  ...rest
}) {
  return (
    <Button {...rest}>
      {iconAlign === 'right' && children}
      <FontAwesomeIcon
        icon={icon}
        className={classNames(iconClassName, {
          'me-1': children && iconAlign === 'left',
          'ms-1': children && iconAlign === 'right',
        })}
        transform={transform}
      />
      {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
    </Button>
  );
}

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.array,
      iconName: PropTypes.string,
      prefix: PropTypes.string,
    }),
  ]).isRequired,
  children: PropTypes.shape(),
  iconAlign: PropTypes.oneOf(['left', 'right', 'middle']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
};

IconButton.defaultProps = {
  iconAlign: 'left',
  children: null,
  iconClassName: '',
  transform: null,
};

export default IconButton;
