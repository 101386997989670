import React, { useState } from 'react';
import { Map } from 'immutable';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import CreateElementKDBEditor from '../../../dataProcessors/createElement/editor';

const mapState = (store) => {
  const Budget = store.getIn(['auth', 'sessionOptions', 'budget'], new Map());
  const FI = store.getIn(['auth', 'sessionOptions', 'fo'], new Map());
  return new Map({
    Budget,
    FI,
  });
};

function CreateElementsModal() {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faDatabase} className="me-2" />
        Заповнити згідно класифікатора
      </Button>
      <Modal show={opened} onHide={() => setOpened(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faDatabase} className="me-2" />
            Заповнити згідно класифікатора
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateElementKDBEditor
            mapInitialOptions={{
              FI: 'FI',
              Budget: 'Budget',
            }}
            mapStore={mapState}
          />

        </Modal.Body>

      </Modal>
    </>
  );
}

export default CreateElementsModal;
