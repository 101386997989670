/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup, ButtonToolbar,
  DropdownButton, Navbar, Dropdown,
} from 'react-bootstrap';
import {
  faCaretSquareUp, faCaretSquareDown,
  faClone, faMinusSquare, faSearchDollar, faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import { RemoveRowButton } from '../../../../../../components/bootStrap/buttons';
import { CPButton } from '../../../../../../components/bootStrap/buttons/styles';
import { childrenPropType } from '../../../../../../constants/backend/propTypes';

function AddButton({
  listactivity, tableActions,
}) {
  return (
    <DropdownButton
      size="sm"
      title="Додати показник"
      className="mb-1"
    >
      <Dropdown.Header>Оберіть напрям(з вкладки `Напрями`)</Dropdown.Header>
      {listactivity.map((actv, idx) => (
        <Dropdown.Item
          key={`AddButton-${idx}`}
          eventKey={idx}
          onClick={async () => {
            tableActions.onAddRow({ activity: actv });
          }}
        >
          {actv.repr}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

AddButton.propTypes = {
  listactivity: PropTypes.arrayOf(PropTypes.shape),
  tableActions: PropTypes.shape({
    onAddRow: PropTypes.func.isRequired,
  }).isRequired,
};

AddButton.defaultProps = {
  listactivity: [],
};

function TPCommandPanel({
  children, tableActions, readOnly, canAdd, canDelete,
  canMove, activeRow, pinHeader, showFooter, listactivity,
}) {
  return (
    <Navbar className="bg-white border-bottom rounded-top">
      <ButtonToolbar className="w-100">
        <ButtonGroup className="me-1">
          <AddButton
            listactivity={listactivity}
            tableActions={tableActions}
          />
        </ButtonGroup>
        <ButtonGroup className="me-1">
          <CPButton
            onClick={() => tableActions.onMoveUpRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити вище"
            icon={faCaretSquareUp}
          />
          <CPButton
            onClick={() => tableActions.onMoveDownRow(activeRow)}
            disabled={readOnly || !canMove || activeRow === null}
            label="Перемістити нижче"
            icon={faCaretSquareDown}
          />
        </ButtonGroup>
        <ButtonGroup className="me-1">
          <CPButton
            onClick={() => tableActions.onCopyRow(activeRow)}
            disabled={readOnly || !canAdd || activeRow === null}
            label="Копіювати (F9)"
            icon={faClone}
          />
          <RemoveRowButton
            onClick={() => tableActions.onDeleteRow(activeRow)}
            disabled={readOnly || !canDelete || activeRow === null}
            label="Видалити (F8)"
            variant="danger"
            icon={faMinusSquare}
          />
        </ButtonGroup>
        {children}
        <ButtonGroup className="ms-auto">
          <CPButton
            onClick={() => tableActions.onToggleFooter()}
            label="Показати підвал"
            variant={showFooter ? 'info' : 'outline-info'}
            icon={faSearchDollar}
          />
          <CPButton
            onClick={() => tableActions.onTogglePin()}
            label="Закріпити заговолок та підвал"
            variant={pinHeader ? 'info' : 'outline-info'}
            icon={faThumbtack}
          />
        </ButtonGroup>
      </ButtonToolbar>
    </Navbar>
  );
}

TPCommandPanel.propTypes = {
  tableActions: PropTypes.shape({
    onAddRow: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    onMoveUpRow: PropTypes.func.isRequired,
    onMoveDownRow: PropTypes.func.isRequired,
    onCopyRow: PropTypes.func.isRequired,
    onToggleFooter: PropTypes.func.isRequired,
    onTogglePin: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: childrenPropType,
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  canMove: PropTypes.bool,
  activeRow: PropTypes.number,
  showFooter: PropTypes.bool.isRequired,
  pinHeader: PropTypes.bool.isRequired,
  listactivity: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

TPCommandPanel.defaultProps = {
  readOnly: false,
  children: null,
  canAdd: true,
  canDelete: true,
  canMove: true,
  activeRow: null,
};

export default TPCommandPanel;
