import React, {
  useEffect, useMemo,
} from 'react';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import TableEditor from '../../tableEditor';
import { MainContainer, ResultSegment } from '../styledComponentsDp';
import { useEditor } from '../../newEditor';
import { modelName, modelType } from './def';

const md = meta.dp.printing;

function PrintingEditor({ ad }) {
  const dispatch = useDispatch();
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );
  const {
    actions: { onSR },
  } = useEditor({
    modelType,
    modelName,
  });

  useEffect(() => {
    dispatch(actions.changeField(['headerForm', 'result'], new Map()));
    dispatch(actions.processServerReq('PRINT', { ad }));
    // onSR('PRINT', { ad: `${ad}${search}` });
  }, [ad, onSR, dispatch]);

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );

  return (
    <MainContainer>
      <ResultSegment>
        <TableEditor mountNode={resultMountNode} />
      </ResultSegment>
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null,
    },
  ),
);

PrintingEditor.propTypes = {
  ad: PropTypes.string,
};

PrintingEditor.defaultProps = {
  ad: '',
};

export default enchance(PrintingEditor);
