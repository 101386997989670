import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  code2: { label: 'Код2', name: 'Код2', visible: true },
  code3: { label: 'Код3', name: 'Код3', visible: true },
  code4: { label: 'Код4', name: 'Код4', visible: true },

  // level: { label: 'Рівень', name: 'Уровень', visible: true },
  printLevel: { label: 'Рівень друку', name: 'УровеньПечати', visible: true },

  FullName: {
    label: 'Повна назва',
    name: 'ПолнНаименование',
    visible: true,
  },
  sorting: { label: 'Сортування', name: 'Сорт', visible: true },
  owner: { label: 'Властивість', name: 'Владелец', visible: true },
};

export const additionalPropertyKFK = {
  label: 'Додаткова властивість елемету КФК (Дов.)',
  name: 'additionalPropertyKFK',
  backendName: 'ДопХЭлементыКФК',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/additionalPropertyKFK',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'code2',
      'code3',
      'code4',
      // 'level',
      'printLevel',
      'FullName',
      'sorting',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default additionalPropertyKFK;
