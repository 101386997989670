import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/proposalDeputiesPrinting';
import DateRangeField from '../../../components/fields/DateRangeField';
import CheckBoxField from '../../field/CheckBox';
import TableEditor from '../../tableEditor';
import {
  ContainerTwoColumn,
  StyledLabel,
  ThreeColumnsGridContainer,
  ContainerDiv,
  ResultSegment,
} from '../../../components/Form/styledForm';
import Fields from '../../field';
import { ItemField } from '../../field/selectorField/item';
import { DivStyled } from '../../field/selectorField/item/styles';
import GetNumberField from '../../field/NumberInput';
import { editor as actions } from '../../../actions';
import { proposalDeputies as def } from '../../../constants/meta/infoRegs/proposalDeputies';
import SelectorField from '../../field/stdSelectorField';
import { selectionMode } from '../../../constants/meta/common';
import ReportContainer from '../reportContainer';

const ThreeColGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 18% 18% 54%;
  align-items: end;
`;

class proposalDeputiesPrintingEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
    portalOpened: PropTypes.bool,
    onReportDetails: PropTypes.func,
  };

  static defaultProps = {
    dispatch: () => null,
    headerForm: new Map(),
    sessionOptions: new Map(),
    isProcessing: false,
    portalOpened: false,
    onReportDetails: false,
  };

  render() {
    const {
      props: {
        dispatch, headerForm, isProcessing, sessionOptions, onReportDetails,
      },
    } = this;

    const isAdmin = sessionOptions.get('is_admin');
    const { portalOpened } = this.props;

    const BudgetLabel = isAdmin ? (
      <Fields.SelectorField
        value={headerForm.get('budget', new Map())}
        modelType="cat"
        modelName="budget"
        onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
      />
    ) : (
      <ItemField
        value={headerForm.get('budget', new Map())}
        disabled
      />
    );

    const YearInput = GetNumberField(4, 0, false);

    const SettingsButton = (
      <div>
        <ContainerTwoColumn>
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            {BudgetLabel}
          </div>
          <div>
            <StyledLabel>{def.columns.deputies.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('parentDeputies', new Map())}
              modelType="cat"
              modelName="deputies"
              choiceSettings={selectionMode.folders}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'parentDeputies'], value));
              }}
            />
          </div>
          <ThreeColGridContainer>
            <div>
              <StyledLabel>{md.columns.year.label}</StyledLabel>
              <DivStyled>
                <YearInput
                  value={headerForm.get('year', 5)}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
                  disableCalculator
                />
              </DivStyled>
            </div>
            <CheckBoxField
              value={headerForm.get('in1000', false)}
              label={md.columns.in1000.label}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
            />
            <div>
              <StyledLabel>{def.columns.kfk.label}</StyledLabel>
              <SelectorField
                value={headerForm.get('kfk', new Map())}
                modelType="cat"
                modelName="kfkvkClassifier"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'kfk'], value));
                }}
              />
            </div>
          </ThreeColGridContainer>
        </ContainerTwoColumn>
        {/* {Bookmarks} */}
      </div>
    );

    return (
      <ReportContainer isProcessing={isProcessing} portalOpened={portalOpened} SettingButton={SettingsButton} backendName={md.backendName}>
        <ContainerDiv>
          <div>
            <StyledLabel>{md.columns.period.label}</StyledLabel>
            <DateRangeField
              value={headerForm.get('period', new Map())}
              onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            />
          </div>
        </ContainerDiv>

        <ResultSegment>
          <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
        </ResultSegment>
      </ReportContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(proposalDeputiesPrintingEditor);
