const frontendURL = '/rep/analyze_indicatiors_sf/';
const backendURL = '/api/authorityplans/printform/analyze_indicatiors_sf/';
const name = 'Аналіз показників СФ';
const sections = [];

const instance = {
  frontendURL,
  backendURL,
  name,
  sections,
};

export default instance;
