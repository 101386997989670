import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanelButton } from '../../../../components/button';
import { AddIcon, RemoveIcon } from '../../../../assets/icons';
import { CommandPanel } from './styled';

function AppearanceCommandPanel({ onAdd, onRemove, canRemove }) {
  return (
    <CommandPanel>
      <CommandPanelButton
        text="Додати"
        onClick={onAdd}
      >
        <img src={AddIcon} alt="Додати" />
      </CommandPanelButton>
      <CommandPanelButton
        text="Видалити"
        disabled={!canRemove}
        onClick={onRemove}
      >
        <RemoveIcon />
      </CommandPanelButton>
    </CommandPanel>
  );
}

AppearanceCommandPanel.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool,
};

AppearanceCommandPanel.defaultProps = {
  canRemove: true,
};

export default AppearanceCommandPanel;
