import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import CommandPanelButton from '../../../components/button/commandPanelButton';
import { SettingsIcon } from '../../../assets/icons';
import { ContainerButtonTop } from '../../tableEditor/styles';
import {
  StyledLabel, ContainerTwoColumn, FramePrintModal, ContainerFiveColumn,
} from '../../../components/Form/styledForm';
import { DivLoadStyled, LoadStyled } from '../../field/selectorField/item/styles';
import DateField from '../../../components/fields/dates';
import FondsTP from './tablepart/tvFondsTP';
import AccTP from './tablepart/tvAccTP';
import getTPContainer from '../../../components/tableParts/tablePartContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

const MainContainer = styled.div`
  position: relative;
`;

const ReqVariants = {
  std: { label: 'За обрану дату', name: 0 },
  ext1: { label: 'За місяць', name: 1 },
  ext2: { label: 'За рік', name: 2 },
};

const MyContainerTwoColumn = styled(ContainerTwoColumn)`
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  height: 300px;
`;

const md = meta.dp.certificateOfAccountPrint;

const FondsTPRenderer = getTPContainer(FondsTP);
const AccTPRenderer = getTPContainer(AccTP);

class CertificateOfAccountPrintEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    headerForm: PropTypes.instanceOf(Map),
    isProcessing: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {},
    headerForm: null,
    isProcessing: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ShowSettings: true,
      // firstStart: true,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    const {
      props: { dispatch, headerForm, isProcessing },
      state: { ShowSettings },
    } = this;

    return (
      <MainContainer>
        <ContainerButtonTop>
          <CommandPanelButton
            text="Сформувати"
            onClick={() => {
              this.setState({ ShowSettings: false });
              dispatch(actions.changeField(['headerForm', 'result'], new Map()));
              dispatch(actions.processServerReq('PRINT'));
            }}

          >
            {isProcessing ? <LoadStyled><DivLoadStyled /></LoadStyled> : 'Сформувати'}
          </CommandPanelButton>
          <CommandPanelButton
            text="Налаштування"
            onClick={() => { this.setState({ ShowSettings: !ShowSettings }); }}
          >
            <SettingsIcon />
          </CommandPanelButton>
        </ContainerButtonTop>
        {
          ShowSettings && (
            <FramePrintModal
              styled={ShowSettings ? { transition: '3s' } : { transition: '3s' }}
              visible={ShowSettings}
              animation="overlay"
              width="very wide"
              direction="top"
              tertiary
              color="blue"
              basic
            >
              <div>
                <ContainerTwoColumn>
                  {!headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.ToDate.label}</StyledLabel>
                      <DateField
                        label={md.columns.ToDate.label}
                        value={headerForm.get('ToDate', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'ToDate'], v))}
                      />
                    </div>
                  )}
                  {headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.dateStart.label}</StyledLabel>
                      <DateField
                        label={md.columns.dateStart.label}
                        value={headerForm.get('dateStart', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dateStart'], v))}
                      />
                    </div>
                  )}
                  {headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.dateFinish.label}</StyledLabel>
                      <DateField
                        label={md.columns.dateFinish.label}
                        value={headerForm.get('dateFinish', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dateFinish'], v))}
                      />
                    </div>
                  )}
                </ContainerTwoColumn>
                <ContainerFiveColumn>
                  <div>
                    <Fields.CheckboxField
                      value={headerForm.get('mainForm', false)}
                      onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'mainForm'], v))}
                      label={md.columns.mainForm.label}
                    />
                  </div>
                  {!headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.stYear.label}</StyledLabel>
                      <InputWithDropdown
                        value={headerForm.get('stYear', false)}
                        options={Object.values(ReqVariants)}
                        onChange={(e, v) => {
                          dispatch(actions.changeField(['headerForm', 'stYear'], v));
                          dispatch(actions.processServerReq('CHANGE_stYear'));
                        }}
                      />
                    </div>
                  )}
                  {!headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.year.label}</StyledLabel>
                      <Fields.YearField
                        value={headerForm.get('year', 0)}
                        onChange={(e, v) => {
                          dispatch(actions.changeField(['headerForm', 'year'], v));
                          dispatch(actions.processServerReq('CHANGE_YEAR'));
                        }}
                      />
                    </div>
                  )}
                  {!headerForm.get('mainForm', false) && (
                    <div>
                      <Fields.CheckboxField
                        value={headerForm.get('onlyPositive', false)}
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'onlyPositive'], v))}
                        label={md.columns.onlyPositive.label}
                      />
                    </div>
                  )}
                  {headerForm.get('mainForm', false) && (
                    <div>
                      <StyledLabel>{md.columns.fond.label}</StyledLabel>
                      <Fields.SelectorField
                        value={headerForm.get('fond', new Map())}
                        modelType="cat"
                        modelName="fondClassifier"
                        onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'fond'], v))}
                        canCleared
                      />
                    </div>
                  )}
                </ContainerFiveColumn>
                <StyledLabel>Доступні дати виписок і рахунки</StyledLabel>
                <MyContainerTwoColumn>
                  {!headerForm.get('mainForm', false) && (
                    <FondsTPRenderer />
                  )}
                  <AccTPRenderer />
                </MyContainerTwoColumn>
              </div>
            </FramePrintModal>
          )
        }
        <TableEditor mountNode={[`dp/${md.name}/dpEditor`, 'headerForm', 'result']} />
      </MainContainer>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(CertificateOfAccountPrintEditor);
