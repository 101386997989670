import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd4';

const meta = md.tables.tar;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear, usePCMCatalog,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={4} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'tar'}
              highlighted={highlights.includes('tar')}
              onToggleHighlght={() => onToggleHighlght('tar')}
            >
              {meta.columns.tar.label}
            </TableHeaderCell>
            {usePCMCatalog && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'task'}
              highlighted={highlights.includes('task')}
              onToggleHighlght={() => onToggleHighlght('task')}
            >
              {meta.columns.task.label}
            </TableHeaderCell>
            )}
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'indicator'}
              highlighted={highlights.includes('indicator')}
              onToggleHighlght={() => onToggleHighlght('indicator')}
            >
              {meta.columns.indicator.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf0'}
              highlighted={highlights.includes('zf0')}
              onToggleHighlght={() => onToggleHighlght('zf0')}
            >
              {`${activeYear} обсяг коштів, в межах орієнтовного граничного показника`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf0'}
              highlighted={highlights.includes('sf0')}
              onToggleHighlght={() => onToggleHighlght('sf0')}
            >
              {`${activeYear} пропозиції щодо додаткового обсягу коштів`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf1'}
              highlighted={highlights.includes('zf1')}
              onToggleHighlght={() => onToggleHighlght('zf1')}
            >
              {`${activeYear + 1} обсяг коштів, в межах орієнтовного граничного показника`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf1'}
              highlighted={highlights.includes('sf1')}
              onToggleHighlght={() => onToggleHighlght('sf1')}
            >
              {`${activeYear + 1} пропозиції щодо додаткового обсягу коштів`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'zf2'}
              highlighted={highlights.includes('zf2')}
              onToggleHighlght={() => onToggleHighlght('zf2')}
            >
              {`${activeYear + 2} обсяг коштів, в межах орієнтовного граничного показника`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'sf2'}
              highlighted={highlights.includes('sf2')}
              onToggleHighlght={() => onToggleHighlght('sf2')}
            >
              {`${activeYear + 2} пропозиції щодо додаткового обсягу коштів`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,
  usePCMCatalog: PropTypes.bool,
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
  usePCMCatalog: false,
};

export default memo(TPHeader);
