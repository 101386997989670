import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import {
  SelectButton, PortalDiv, DivStyled, InputStyled,
} from './styles';

class DateInput extends Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    endOfDay: PropTypes.bool, // Добавлять к дате 23:59:59
    background: PropTypes.bool,
    noBorder: PropTypes.bool,
    allowedValues: PropTypes.arrayOf(PropTypes.number), // перечень разрешенных дат
    id: PropTypes.number,
    rowId: PropTypes.number,
    setDataRef: PropTypes.func,
    processingKeyDown: PropTypes.func,
    isFocusCell: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    value: null,
    onChange: () => console.log('This control id ReadOnly because prop "onChange" is not defined'),
    disabled: false,
    readOnly: false,
    endOfDay: false,
    background: false,
    noBorder: false,
    allowedValues: null,
    id: null,
    rowId: null,
    setDataRef: () => null,
    processingKeyDown: () => null,
    isFocusCell: false,
    placeholder: '',
  };

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (state.pvalue !== props.value) {
      const newDate = new Date(value);
      // Из-за этого смещается дата
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      return ({
        value: props.value ? newDate : null,
        pvalue: props.value,
      });
    }
    return state;
  }

  constructor(props) {
    super(props);
    const { rowId, id, setDataRef } = props;
    this.state = {
      value: null,
      pvalue: Number.NaN,
      portalOpened: false,
      cursorPosition: 0,
    };
    this.divRef = React.createRef();
    this.inputRef = React.createRef();
    if (setDataRef) setDataRef(rowId, id);
  }

  componentDidMount() {
    if ((this.props.isFocusCell)) { this.inputRef.current.focus(); }
  }

  componentDidUpdate() {
    if (this.inputRef.current) {
      const { cursorPosition } = this.state;
      this.inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
    if ((this.props.isFocusCell)) { this.inputRef.current.focus(); }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clicker);
  }

  onFocusHandler = (e) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  getValue = () => {
    const { value } = this.state;
    if (!value) return '';
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return value.toLocaleString('uk', options);
  };

  changeHandler = (e, value) => {
    const { onChange, endOfDay } = this.props;
    const d = new Date(value.valueOf());
    if (endOfDay) {
      d.setHours(23);
      d.setMinutes(59);
      d.setSeconds(59);
    }
    // Из-за этого смещается дата
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    onChange(e, d.valueOf());
    this.closePortal();
  };

  keyPressHandler = (e) => {
    const { value } = this.state;
    const { readOnly, allowedValues } = this.props;
    if (readOnly || !!allowedValues) return false;
    const cursorPosition = this.inputRef.current.selectionStart;
    const valid = (e.charCode >= 48 && e.charCode <= 59)
      || (e.charCode === 46);
    if (valid) {
      const cValue = this.getValue();
      const lDate = value || new Date();
      if (e.charCode === 46) {
        const newPosition = cValue.indexOf('.', cursorPosition);
        if (newPosition > -1) {
          this.setState({ cursorPosition: newPosition + 1 });
        }
      } else {
        const InsertPosition = cValue.substr(cursorPosition, 1) === '.' ? cursorPosition + 1 : cursorPosition;
        const newValue = cValue.substring(0, InsertPosition)
          + String.fromCharCode(e.charCode)
          + cValue.substring(InsertPosition + 1, cValue.length);
        const newDate = new Date(lDate.valueOf());
        if (InsertPosition < 2) {
          newDate.setDate(newValue.substr(0, 2));
          if (newDate.getMonth() !== lDate.getMonth()) {
            return false;
          }
        } else if (InsertPosition < 6) {
          newDate.setMonth(newValue.substr(3, 2) - 1);
          if (newDate.getFullYear() === lDate.getFullYear()) {
          } else if (String.fromCharCode(e.charCode) === '0') {
            newDate.setMonth(0);
            newDate.setFullYear(lDate.getFullYear());
          } else if (String.fromCharCode(e.charCode) === '1') {
            newDate.setMonth(9);
            newDate.setFullYear(lDate.getFullYear());
          } else {
            return false;
          }
        } else {
          newDate.setFullYear(newValue.substr(6, 4));
        }
        this.setState({ cursorPosition: InsertPosition + 1 });
        this.changeHandler(e, newDate);
      }
    }
    e.preventDefault();
    return true;
  };

  showPortal = () => {
    document.addEventListener('click', this.clicker);
    this.setState({ portalOpened: true });
  };

  closePortal = () => {
    document.removeEventListener('click', this.clicker);
    this.setState({ portalOpened: false });
  };

  clicker = (e) => {
    if (!this.divRef.current.contains(e.target) && document.body.contains(e.target)) {
      this.closePortal();
    }
  };

  render() {
    const {
      disabled,
      readOnly,
      onChange,
      background,
      noBorder,
      allowedValues,
      rowId,
      id,
      processingKeyDown,
      placeholder,
    } = this.props;
    const { portalOpened, value } = this.state;
    return (
      <DivStyled
        ref={this.divRef}
        style={{ position: 'relative' }}
        noBorder={noBorder}
      >
        <InputStyled
          onClick={(e) => { if (processingKeyDown) processingKeyDown(e, rowId, id); }}
          onFocus={this.onFocusHandler}
          background={background}
          value={this.getValue()}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={this.keyPressHandler}
          ref={this.inputRef}
          readOnly={readOnly || !!allowedValues}
          onKeyDown={(e) => {
            if (e.keyCode === 27 && portalOpened) {
              this.setState({ portalOpened: false });
            }
            if (processingKeyDown) processingKeyDown(e, rowId, id);
          }}
        />
        {!readOnly && !disabled ? (
          <SelectButton
            onClick={() => (!portalOpened ? this.showPortal() : this.closePortal())}
            disabled={disabled}
            onKeyDown={(e) => (e.keyCode === 27 && portalOpened
              ? this.setState({ portalOpened: false })
              : null)}
          />
        ) : null}
        {!portalOpened ? null : createPortal(
          (
            <PortalDiv top={this.divRef.current.getBoundingClientRect().height}>
              <Calendar
                value={value}
                onChange={(v) => this.changeHandler(null, v)}
                locale="uk-UA"
              />

            </PortalDiv>
          ), this.divRef.current,
        )}
      </DivStyled>
    );
  }
}

export default DateInput;
