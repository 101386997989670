const frontendURL = '/cat/indicator/';
const backendURL = '/api/references/refindicator/';
const name = 'Показники бюджетних програм';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};
export default instance;
