import base from './base';

/**
 * @const
 * @type {object}
 */
export const sumByPeriod = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`Sum${v + 1}`]: {
      name: `Сумма${v + 1}`,
      label: `Сумма ${v + 1}`,
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumByPeriodBudgetAmplification = [...Array(12).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v + 1}`]: {
      name: `СуммаБР${v + 1}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'ZkjkB_HiPL0',
  },
  program: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: true },
  codClassifierKBP: { label: 'КБП', name: 'КодКлассификаторКБП', visible: true },
  codClassifierKBP7: { label: 'КПКВ', name: 'КодКлассификаторКБП7', visible: true },
  codClassifierKFKBK: { label: 'КФКВК', name: 'КодКлассификаторКФКВК', visible: true },
  groundsAndJustificationOfSpecialFundExpenditures: {
    label: 'Підстави та обгрунтування видатків спеціального фонду',
    name: 'АнализРезультатовПоСФ',
  },
  consequences0: { name: 'Последствия0', label: 'Наслідки, у разі невиділення додаткових коштів' },
  consequences1_3: { name: 'Последствия1_3', label: 'Наслідки, у разі невиділення додаткових коштів' },
  zfResultAnalysis: {
    label: 'Аналiз результатiв, досягнутих внаслiдок використання коштiв'
      + 'загального фонду бюджету, та очiкуванi результати:',
    name: 'АнализРезультатовПоЗФ',
  },
  ResultAnalysis: { label: 'Аналiз результатiв', isObjectForm: true },
  BudgetManagementAnalysis: {
    label: 'Аналіз управління бюджетними зобов’язаннями та пропозиції щодо їх упорядкування', isObjectForm: true,
  },
  liabilitiesManagementAnalysis: {
    label: ' Аналіз управління бюджетними зобов’язаннями та пропозиції щодо упорядкування бюджетних зобов’язань',
    name: 'АнализУправленияОбязательств',
  },
  TargetLawBasis: { label: 'Мета, законодачі підстави', isObjectForm: true },
  budgetProgramPurpose: { label: 'Мета бюджетної програми', name: 'Цель' },
  disposerPurpose: { label: 'Мета діяльності головного розпорядника', name: 'ЦельДеятельности' },
  basis: { label: 'Законодавчі підстави реалізації бюджетної програми', name: 'ЗаконОснование' },
  Note: { label: 'Примiтка', name: 'Примечание' },

  author: { label: 'Автор', name: 'Автор', visible: true },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  turnBuildObjOn: {
    label: 'Включити підсистему "Об\'єкти будівництва"',
    name: 'ВключитьОбъектыСтроительства',
    visible: false,
  },
  transitionToOB: {
    label: 'Період переходу "Об\'єкти будівництва"',
    name: 'флПереходНаОС',
    visible: false,
  },
  // fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  // refNumber: { label: 'Номер довідки', name: 'НомерСправки', visible: false },
  // refReason: {
  //   label: 'Підстава для довідки',
  //   name: 'ОснованиеДляСправки',
  //   visible: false,
  // },
  // includeCSUIntoHead: {
  //   label: 'Распорядитель в шапке',
  //   name: 'флРаспорядительВШапке',
  //   visible: false,
  // },
  // elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ', visible: false },
  // fondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  // refNumber: { label: 'Номер довідки', name: 'НомерСправки', visible: false },
  // refReason: {
  //   label: 'Підстава для довідки',
  //   name: 'ОснованиеДляСправки',
  //   visible: false,
  // },
  // includeCSUIntoHead: {
  //   label: 'Распорядитель в шапке',
  //   name: 'флРаспорядительВШапке',
  //   visible: false,
  // },
  received: { label: 'Отримано', name: 'Квитанция', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  // approved: {
  //   label: 'Затверджено',
  //   name: 'МаксНомерПодписи',
  //   visible: true,
  // },
  // budgetRec: { label: 'Бюджетний запит', name: 'Ссылка', visible: true },
  KFK: { label: 'КФК', name: 'КФК', visible: true },
  KPKV: { label: 'КПКВ', name: 'КлассификаторКБП7', visible: true },
  // onlyYear: {
  //   label: 'Тільки річні суми',
  //   name: 'ТолькоГодовая',
  //   visible: false,
  // },
  // includeBudgetAmplification: {
  //   label: 'в т.ч. Бюджет Розвитку',
  //   name: 'ВТЧБР',
  //   visible: false,
  // },
  // includeParent: {
  //   label: 'в т.ч. за розпорядником',
  //   name: 'флПоРодителю',
  //   visible: false,
  // },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: false,
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  // tags: { label: 'Теги', name: 'АвтПримечание', visible: true },
  // isCreditingAllowed: {
  //   label: 'За розпорядником вищого рівня',
  //   name: 'флПоРодителю',
  //   visible: false,
  // },
  // docRevenuePlanning: {
  //   label: 'Планування доходів',
  //   name: 'ДокПланДох',
  //   visible: false,
  // },
  // docRevenueSources: {
  //   label: 'Планування джерел',
  //   name: 'ДокПланИсточники',
  //   visible: false,
  // },
  // docTotalCost: {
  //   label: 'Загальна вартість',
  //   name: 'СуммаДокумента',
  //   visible: true,
  // },
  // numberRegistryFI: {
  //   label: 'Реестраційний номер',
  //   name: 'РегНомер',
  //   visible: false,
  // },
  // Loans: { label: 'Кредитування', name: 'флКредитование', visible: true },
  // Дополнительные свойства
  // thisIsSecialFond: { label: 'Це спецфонд', name: 'ЭтоСпецФонд', visible: true },
  // BalanceSpecFound: { label: 'Балансувати спец. фонд', name: 'БалансСФКДБКФБ', visible: false },
  k1: { label: 'Коефіцієнт 1', name: 'Коэф1Расх', visible: true },
  k2: { label: 'Коефіцієнт 2', name: 'Коэф2Расх', visible: true },
  k3: { label: 'Коефіцієнт 3', name: 'Коэф3Расх', visible: true },
  k1sf: { label: 'Коефіцієнт 1', name: 'Коэф1РасхСФ', visible: true },
  k2sf: { label: 'Коефіцієнт 2', name: 'Коэф2РасхСФ', visible: true },
  k3sf: { label: 'Коефіцієнт 3', name: 'Коэф3РасхСФ', visible: true },
  fillK1: { label: 'Заповнити 1', name: 'флЗаполнитьРасх1', visible: true },
  fillK2: { label: 'Заповнити 2', name: 'флЗаполнитьРасх2', visible: true },
  fillK3: { label: 'Заповнити 3', name: 'флЗаполнитьРасх3', visible: true },
  previousK: { label: 'Попередні коеф.', name: 'ЗавантажитиПоперПараметри', visible: true },

  kt1: { label: 'Коефіцієнт 1', name: 'Коэф1Заданий', visible: true },
  kt2: { label: 'Коефіцієнт 2', name: 'Коэф2Заданий', visible: true },
  kt3: { label: 'Коефіцієнт 3', name: 'Коэф3Заданий', visible: true },
  fillKT1: {
    label: 'Заповнити 1', name: 'флЗаполнитьЗадания1', visible: true, isObjectForm: true,
  },
  fillKT2: {
    label: 'Заповнити 2', name: 'флЗаполнитьЗадания2', visible: true, isObjectForm: true,
  },
  fillKT3: {
    label: 'Заповнити 3', name: 'флЗаполнитьЗадания3', visible: true, isObjectForm: true,
  },

  kgb1: {
    label: 'Коефіцієнт 1', name: 'Коэф1НаправленияГБ', visible: true, isObjectForm: true,
  },
  kgb2: {
    label: 'Коефіцієнт 2', name: 'Коэф2НаправленияГБ', visible: true, isObjectForm: true,
  },
  kgb3: {
    label: 'Коефіцієнт 3', name: 'Коэф3НаправленияГБ', visible: true, isObjectForm: true,
  },
  fillKGB1: { label: 'Заповнити 1', name: 'флЗаполнитьНаправленияГБ1', visible: true },
  fillKGB2: { label: 'Заповнити 2', name: 'флЗаполнитьНаправленияГБ2', visible: true },
  fillKGB3: { label: 'Заповнити 3', name: 'флЗаполнитьНаправленияГБ3', visible: true },

  kp1: { label: 'Коефіцієнт 1', name: 'Коэф1Пок', visible: true },
  kp2: { label: 'Коефіцієнт 2', name: 'Коэф2Пок', visible: true },
  kp3: { label: 'Коефіцієнт 3', name: 'Коэф3Пок', visible: true },
  kp1sf: { label: 'Коефіцієнт 1', name: 'Коэф1ПокСФ', visible: true },
  kp2sf: { label: 'Коефіцієнт 2', name: 'Коэф2ПокСФ', visible: true },
  kp3sf: { label: 'Коефіцієнт 3', name: 'Коэф3ПокСФ', visible: true },
  fillKP1: {
    label: 'Заповнити 1', name: 'флЗаполнитьПок1', visible: true, isObjectForm: true,
  },
  fillKP2: {
    label: 'Заповнити 2', name: 'флЗаполнитьПок2', visible: true, isObjectForm: true,
  },
  fillKP3: {
    label: 'Заповнити 3', name: 'флЗаполнитьПок3', visible: true, isObjectForm: true,
  },

  kws1: { label: 'Коефіцієнт 1', name: 'Коэф1ЗП', visible: true },
  kws2: { label: 'Коефіцієнт 2', name: 'Коэф2ЗП', visible: true },
  kws3: { label: 'Коефіцієнт 3', name: 'Коэф3ЗП', visible: true },
  fillKWS1: {
    label: 'Заповнити 1', name: 'флЗаполнитьЗп1', visible: true, isObjectForm: true,
  },
  fillKWS2: {
    label: 'Заповнити 2', name: 'флЗаполнитьЗп2', visible: true, isObjectForm: true,
  },
  fillKWS3: {
    label: 'Заповнити 3', name: 'флЗаполнитьЗп3', visible: true, isObjectForm: true,
  },

  kw1: { label: 'Коефіцієнт 1', name: 'Коэф1Ч', visible: true },
  kw2: { label: 'Коефіцієнт 2', name: 'Коэф2Ч', visible: true },
  kw3: { label: 'Коефіцієнт 3', name: 'Коэф3Ч', visible: true },
  fillKW1: {
    label: 'Заповнити 1', name: 'флЗаполнитьЧ1', visible: true, isObjectForm: true,
  },
  fillKW2: {
    label: 'Заповнити 2', name: 'флЗаполнитьЧ2', visible: true, isObjectForm: true,
  },
  fillKW3: {
    label: 'Заповнити 3', name: 'флЗаполнитьЧ3', visible: true, isObjectForm: true,
  },

  ktpf1: {
    label: 'Коефіцієнт 1', name: 'Коэф1ЦелевПрог', visible: true, isObjectForm: true,
  },
  fillKTPF1: {
    label: 'Заповнити 1', name: 'флЗаполнитьЦПрог1', visible: true, isObjectForm: true,
  },

  ktp1: {
    label: 'Коефіцієнт 1', name: 'Коэф2ЦелевПрог', visible: true, isObjectForm: true,
  },
  ktp2: {
    label: 'Коефіцієнт 2', name: 'Коэф3ЦелевПрог', visible: true, isObjectForm: true,
  },
  fillKTP1: {
    label: 'Заповнити 1', name: 'флЗаполнитьЦПрог2', visible: true, isObjectForm: true,
  },
  fillKTP2: {
    label: 'Заповнити 2', name: 'флЗаполнитьЦПрог3', visible: true, isObjectForm: true,
  },
  startPeriod: {
    label: 'Заповнити з', name: 'дтНачЗаполненияДок', visible: true, isObjectForm: true,
  },
  endPeriod: {
    label: 'по', name: 'дтКонЗаполненияДок', visible: true, isObjectForm: true,
  },
  factPeriod: { label: 'Дата фактичних даних', name: 'ДатаФактическихДанныхПоСтруктурeОплатыТруда', visible: true },
  notControlZP: { label: 'Не контролювати видатки по ЗП', name: 'флПроверятьСтрЗП', visible: true },
  factOfSpending: { label: 'Факт видаткiв поточного року', name: 'флФактЗпТекГода', visible: true },

  dateOfCompletion: { label: 'Дата уточ. плану', name: 'ДатаЗаполнения', visible: true },
  cashbox: { label: 'ФлКасса', name: 'ФлКасса', visible: true },
  byKBP7: { label: 'за КПКВ', name: 'флПоКБП7', visible: true },
  fillYear: { label: 'пЗаполнитьГода', name: 'пЗаполнитьГода', visible: true },
  indicatorPassport: { label: 'Паспорт', name: 'Паспорт', visible: false },
  indicatorFact: { label: 'Звіт з виконання паспорту', name: 'ФактПоказателей', visible: false },
  indicatorPassportUPD: { label: 'Уточнений паспорт', name: 'ПаспортУточн', visible: false },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  docByKBP7: {
    label: 'флПЦМпоКБП7', name: 'флПЦМпоКБП7', visible: true, type: 'bool',
  },
  DCSettings: { label: 'Налашування СКД', name: 'НастройкиСКД', type: 'DCSettings' },
  showIndicatorsForTarget: { label: 'ПоказыватьПоказателиДЛостиженияЦелей', name: 'ПоказыватьПоказателиДЛостиженияЦелей', type: 'bool' },
  isKyiv: { label: 'ЭтоКиев', name: 'ЭтоКиев', type: 'bool' },
  sumF2: {
    label: 'Сума за КЕКВ',
    name: 'СуммаФ2',
    visible: true,
    type: 'moneys',
  },
  sumF3: {
    label: 'Сума (дод.)',
    name: 'СуммаФ3',
    visible: true,
    type: 'moneys',
  },
};

/**
 * @const
 */
const tables = {
  fillingByLowestLevelCSU: {
    name: 'ДокРаспНижнегоУр',
    label: 'Заповнення за даними розпорядників нижчого рівня',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      received: { label: 'Отримано', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      selection: { label: 'Вiдбiр', name: 'Отбор' },
      FI: { label: 'Розпорядник', name: 'ФО' },
    },
  },
  debtManagementAnalysis: {
    name: 'КредЗадолженность',
    label: 'Таблиця аналізу управління заборгованістю',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      cashBox: { label: 'Касовi видатки', name: 'Касса_2' },
      applied1: { label: 'Затверджено з урахуванням змiн', name: 'УтвПлан_1' },
      applied2: { label: 'Затверджено з урахуванням змiн', name: 'УтвПлан_2' },
      payables2: { label: 'Кредиторська заборгованiсть', name: 'КредитДолг_2' },
      payables1: { label: 'Кредиторська заборгованiсть', name: 'КредитДолг_1' },
      borderAmount: { label: 'Граничний обсяг', name: 'ГраничнийОбсяг' },
      creditRepaymentLiquidatedzf2: { label: 'Погаш. кред. заб. з заг. фонду', name: 'ПогашеноКредЗФ_2' },
      creditRepaymentLiquidatedzf1: { label: 'Погаш. кред. заб. з заг. фонду', name: 'ПогашеноКредЗФ_1' },
      creditRepaymentLiquidatedzf0: { label: 'Планується погасити. кред. заб. з заг. фонду', name: 'ПогашеноКредЗФ0' },
      creditRepaymentLiquidatedsf2: { label: 'Погаш. кред. заб. з спец. фонду', name: 'ПогашеноКредСФ_2' },
      creditRepaymentLiquidatedsf1: { label: 'Погаш. кред. заб. з спец. фонду', name: 'ПогашеноКредСФ_1' },
      creditRepaymentLiquidatedsf0: { label: 'Планується погасити. кред. заб. з спец. фонду', name: 'ПогашеноКредСФ0' },
      receivables2: { label: 'Дебіторська заборгованість', name: 'ДебиторЗаб_2' },
      receivables1: { label: 'Дебіторська заборгованість', name: 'ДебиторЗаб_1' },
      receivables0: { label: 'Очікувана дебіторська заборгованість', name: 'ДебиторЗаб0' },
      reason: { label: 'Причини виникнення заборгованностi', name: 'Причина' },
      takenActions: { label: 'вжиті заходи щодо ліквідації заборгованості', name: 'ПринятыеМеры' },
    },
  },
  KDBForecast: {
    name: 'КДБ',
    label: 'Прогноз надходжень',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKDB: { label: 'Елемент КДБ', name: 'ЭлементКДБ' },
      KDB: { label: 'КДБ', name: 'КДБ' },
      codeKDB: { label: 'Код КДБ', name: 'КодКДБ' },

      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      zf3: { label: 'Заг.ф.', name: 'ЗФ3' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf3: { label: 'Спец.ф.', name: 'СФ3' },
      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      br3: { label: 'в т.ч.БР', name: 'БР3' },
      sum1: { label: 'Сума 1', name: 'Сумма1' },
      sum2: { label: 'Сума 2', name: 'Сумма2' },
      sum3: { label: 'Сума 3', name: 'Сумма3' },
      sum0: { label: 'Сума 0', name: 'Сумма0' },

      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      br_1: { label: 'в т.ч. БР', name: 'БР_1' },
      br_2: { label: 'в т.ч. БР', name: 'БР_2' },
      sum_1: { label: 'Сума 1', name: 'Сумма_1' },
      sum_2: { label: 'Сума 2', name: 'Сумма_2' },
    },
  },
  KFBForecast: {
    name: 'КФБ',
    label: 'Прогноз джерел фінансування',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },

      elementKFB: { label: 'Елемент КФБ', name: 'ЭлементКФБ' },
      KFB: { label: 'КФБ', name: 'КФБ' },
      codeKFB: { label: 'Код КФБ', name: 'КодКФБ' },

      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      zf3: { label: 'Заг.ф.', name: 'ЗФ3' },

      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf3: { label: 'Спец.ф.', name: 'СФ3' },

      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      br3: { label: 'в т.ч.БР', name: 'БР3' },

      sum1: { label: 'Сума 1', name: 'Сумма1' },
      sum2: { label: 'Сума 2', name: 'Сумма2' },
      sum3: { label: 'Сума 3', name: 'Сумма3' },
      sum0: { label: 'Сума 0', name: 'Сумма0' },

      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },

      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },

      br_1: { label: 'в т.ч.БР', name: 'БР_1' },
      br_2: { label: 'в т.ч.БР', name: 'БР_2' },

      sum_1: { label: 'Сума 1', name: 'Сумма_1' },
      sum_2: { label: 'Сума 2', name: 'Сумма_2' },
    },
  },
  costsKEKV: {
    name: 'РасходыКЕКВ',
    label: 'План',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      br_1: { label: 'в т.ч.БР', name: 'БР_1' },
      br_2: { label: 'в т.ч.БР', name: 'БР_2' },
      sum1: { label: 'Сума 1', name: 'Сумма1' },
      sum2: { label: 'Сума 2', name: 'Сумма2' },
      sum_1: { label: 'Сума 1', name: 'Сумма_1' },
      sum_2: { label: 'Сума 2', name: 'Сумма_2' },
      sum0: { label: 'Сума 0', name: 'Сумма0' },
      explanation: { label: 'Пояснення', name: 'Пояснення' },
      k0: { label: 'К0', name: 'К0' },
      k1: { label: 'К1', name: 'К1' },
      k2: { label: 'К2', name: 'К2' },
    },
  },
  return: {
    name: 'ВозвратКредита',
    label: 'План повернення кредиту',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      kbp: { label: 'КБП', name: 'КБП' },
      codeKBP: { label: 'код КБП', name: 'КодКБП' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      zf3: { label: 'Заг.ф.', name: 'ЗФ3' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf3: { label: 'Спец.ф.', name: 'СФ3' },
      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      br3: { label: 'в т.ч.БР', name: 'БР3' },
      sum1: { label: 'Сума 1', name: 'Сумма1' },
      sum2: { label: 'Сума 2', name: 'Сумма2' },
      sum0: { label: 'Сума 0', name: 'Сумма0' },
      sum3: { label: 'Сума 0', name: 'Сумма3' },
      k0: { label: 'К0', name: 'К0' },
      k1: { label: 'К1', name: 'К1' },
      k2: { label: 'К2', name: 'К2' },

      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      br_1: { label: 'в т.ч.БР', name: 'БР_1' },
      br_2: { label: 'в т.ч.БР', name: 'БР_2' },
      sum_1: { label: 'Сума 1', name: 'Сумма_1' },
      sum_2: { label: 'Сума 2', name: 'Сумма_2' },
    },
  },
  directionsGBFact: {
    name: 'НаправленияГБ_Факт',
    label: 'Факт',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      // zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      // sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf2: { label: 'Спец.ф.', name: 'СФ_2' },
      sum1: { label: 'Сума_1', name: 'Сумма_1' },
      sum2: { label: 'Сума_2', name: 'Сумма_2' },
      // sum0: { label: 'Сума 0', name: 'Сумма0' },
      br1: { label: 'в т.ч.БР', name: 'БР_1' },
      br2: { label: 'в т.ч.БР', name: 'БР_2' },
    },
  },
  directionsGB: {
    name: 'НаправленияГБ_План',
    label: 'План',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sum1: { label: 'Сума1', name: 'Сумма1' },
      sum2: { label: 'Сума2', name: 'Сумма2' },
      sum0: { label: 'Сума0', name: 'Сумма0' },
      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      k0: { label: 'К0', name: 'К0' },
      k1: { label: 'К1', name: 'К1' },
      k2: { label: 'К2', name: 'К2' },
    },
  },
  tasks: {
    name: 'Задания',
    label: 'План',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks: { label: 'Напрям', name: 'Задание' },
      objectivesStatePolicy: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      br0: { label: 'в т.ч.БР', name: 'БР0' },
      br1: { label: 'в т.ч.БР', name: 'БР1' },
      br2: { label: 'в т.ч.БР', name: 'БР2' },
      sum1: { label: 'Сума 1', name: 'Сумма1' },
      sum2: { label: 'Сума 2', name: 'Сумма2' },
      sum0: { label: 'Сума 0', name: 'Сумма0' },
      k0: { label: 'К0', name: 'К0' },
      k1: { label: 'К1', name: 'К1' },
      k2: { label: 'К2', name: 'К2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      br_1: { label: 'в т.ч.БР', name: 'БР_1' },
      br_2: { label: 'в т.ч.БР', name: 'БР_2' },
      sum_1: { label: 'Сума 1', name: 'Сумма_1' },
      sum_2: { label: 'Сума 2', name: 'Сумма_2' },
    },
  },
  tasks2019: {
    name: 'Задания2019',
    label: 'Завдання',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      tasks2019: { label: 'Завдання', name: 'Задания2019' },
    },
  },
  indicators: {
    name: 'Показатели',
    label: 'План',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      indicator: { label: 'Показник', name: 'Показатель' },
      type: { label: 'Тип показника', name: 'ТипПоказателя' },
      tasks: { label: 'Напрям', name: 'Задание' },
      units: { label: 'Од.вим.', name: 'ЕдИзм' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sum1: { label: 'Сума1', name: 'Сумма1' },
      sum2: { label: 'Сума2', name: 'Сумма2' },
      sum0: { label: 'Сума0', name: 'Сумма0' },
      k0: { label: 'К0', name: 'К0' },
      k1: { label: 'К1', name: 'К1' },
      k2: { label: 'К2', name: 'К2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      sum_1: { label: 'Сума_1', name: 'Сумма_1' },
      sum_2: { label: 'Сума_2', name: 'Сумма_2' },
    },
  },
  wageStructure: {
    name: 'СтруктураОплатыТруда',
    label: 'Структура видатків на оплату праці',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      parent: { label: 'Група', name: 'СтруктураОплатыТрудаРодитель' },
      wage: { label: 'Структура oплати', name: 'СтруктураОплатыТруда' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      zfsf_1: { label: 'в т.ч. оплата за заг.ф., що врах.у спец.ф.', name: 'ВтчЗфСф_1' },
      zfsf_2: { label: 'в т.ч. оплата за заг.ф., що врах.у спец.ф.', name: 'ВтчЗфСф_2' },
      zfsf0: { label: 'в т.ч. оплата за заг.ф., що врах.у спец.ф.', name: 'ВтчЗфСф0' },
      zfsf1: { label: 'в т.ч. оплата за заг.ф., що врах.у спец.ф.', name: 'ВтчЗфСф1' },
      zfsf2: { label: 'в т.ч. оплата за заг.ф., що врах.у спец.ф.', name: 'ВтчЗфСф2' },
      sum_1: { label: 'Сума_1', name: 'Сумма_1' },
      sum_2: { label: 'Сума_2', name: 'Сумма_2' },
      sum0: { label: 'Сума2', name: 'Сумма0' },
      sum1: { label: 'Сума1', name: 'Сумма1' },
      sum2: { label: 'Сума2', name: 'Сумма2' },

    },
  },

  workersAmount: {
    label: 'Чисельність зайнятих у бюджетних установах',
    name: 'КатегорииРаботниковФакт',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      group: { label: 'Група', name: 'РодительКатегорииРаботников' },
      categories: { label: 'Категорії', name: 'Категории' },
      zf2P: { label: 'Затв. заг.ф.', name: 'ЗФ_2П' },
      sf2P: { label: 'Затв. спец.ф.', name: 'СФ_2П' },
      zfsF2P: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф_2П' },
      zf2F: { label: 'Факт заг.ф.', name: 'ЗФ_2Ф' },
      sf2F: { label: 'Факт спец.ф.', name: 'СФ_2Ф' },
      zfsF2F: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф_2Ф' },
      zf1P: { label: 'Затв. заг.ф.', name: 'ЗФ_1П' },
      sf1P: { label: 'Затв. спец.ф.', name: 'СФ_1П' },
      zfsF1P: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф_1П' },
      zf1F: { label: 'Факт заг.ф.', name: 'ЗФ_1Ф' },
      sf1F: { label: 'Факт спец.ф.', name: 'СФ_1Ф' },
      zfsF1F: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф_1Ф' },
      zf0: { label: 'Затв. заг.ф.', name: 'ЗФ0' },
      sf0: { label: 'Затв. спец.ф.', name: 'СФ0' },
      zfsF0: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      zfsF1: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      zfsF2: { label: 'в т.ч. заг.ф. що врах. у спец.ф.', name: 'ВтчЗфСф2' },
    },
  },
  targetProgramsFact: {
    name: 'ЦелевыеПрограммыФакт',
    label: 'Факт',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      target: { label: 'Цiльова програма', name: 'ЦелеваяПрограмма' },

      // doc: { label: 'Коли, та яким документом затверджена', name: 'ДокументУтверждения' },
      // docContent: { label: 'Короткий змiст заходiв за програмою', name: 'ПереченьМероприятий' },
    },
  },
  targetPrograms: {
    name: 'ЦелевыеПрограммы',
    label: 'План',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      target: { label: 'Цiльова програма', name: 'ЦелеваяПрограмма' },
      // ObjectivesStatePolicy: { label: 'Ціль державної політики', name: 'ЦелеваяПрограмма' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sum1: { label: 'Сума1', name: 'Сумма1' },
      sum2: { label: 'Сума2', name: 'Сумма2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sum_1: { label: 'Сума1', name: 'Сумма_1' },
      sum_2: { label: 'Сума2', name: 'Сумма_2' },
      sum0: { label: 'Сума0', name: 'Сумма0' },
      doc: { label: 'Коли, та яким документом затверджена', name: 'ДокументУтверждения' },
      docContent: { label: 'Короткий змiст заходiв за програмою', name: 'ПереченьМероприятий' },
    },
  },
  investPrograms: {
    name: 'ИнвестПрограммы',
    label: 'Інвестиційні проекти (об\'єкти кап. будівн.)',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      budgetRevenues: { label: 'Доходи заг.ф бюджету', name: 'ДоходыБюдж' }, // on back always visible = true
      targetS: { label: 'Державна цiльова програма', name: 'ЦелеваяПрограмма' },
      constructionObject: { label: 'Найменування об\'єкта відповідно до проектно-кошторисної документації', name: 'ОбъектыСтроительства' },
      constructionPeriod: { label: 'Перiод будiвництва', name: 'ПериодСтроительства' },
      costumer: { label: 'Замовник', name: 'Заказчик' },
      KFB: { label: 'КФБ', name: 'КФБ' }, // on back always visible = true
      KDB: { label: 'КДБ', name: 'КДБ' }, // on back always visible = true
      basicMoney: { label: 'Загальна вартість об\'єкту', name: 'ОсвоенныеДенеги' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' }, // on back always visible = true
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' }, // on back always visible = true
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' }, // on back always visible = true
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' }, // on back always visible = true
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' }, // on back always visible = true
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      ss_1: { label: 'Рівень буд. гот. %', name: 'СС_1' },
      ss_2: { label: 'Рівень буд. гот. %', name: 'СС_2' },
      ss_3: { label: 'Рівень буд. гот. %', name: 'СС_3' },
      ss_4: { label: 'Рівень буд. гот. %', name: 'СС_4' },
      ss_5: { label: 'Рівень буд. гот. %', name: 'СС_5' },
      sum_1: { label: 'Разом 1', name: 'Сумма_1' },
      sum_2: { label: 'Разом 2', name: 'Сумма_2' },
      sum0: { label: 'Разом 0', name: 'Сумма0' },
      sum1: { label: 'Разом1', name: 'Сумма1' },
      sum2: { label: 'Разом2', name: 'Сумма2' },
      linkMCP: { label: 'Посилання на пункт, розділ МЦП(дата, номер та назва документа)', name: 'СсылкаНаМЦП' },
      doc: { label: 'Документ, що пiдтверджує належнисть об\'єкта до комунальноi власностi територiальноi громади', name: 'ПодтверждениеПраваКомСобственности' },
      docKMR: { label: 'Документ про оформлення права на земельну ділянку, дата та номер рішення КМР (тільки в разі нового будувництва)', name: 'ДокПравоНаЗемлю' },
      docPKD: { label: 'Документ про затвердження ПКД', name: 'ДокУтвПКД' },
      basis: { label: 'Пiдстава для виконання робiт (розпорядження КМДА або дефектний акт, дата та номер)', name: 'ОснованиеНаВіполнРабот' },
      // template: { label: 'Шаблон пояснень', name: 'Пояснение' },
      // explanation: { label: 'Пояснення, що харектиризують джерела фiнансування', name: 'ПояснениеСтр' },
    },
  },
  regulations: {
    name: 'НормативнАкты',
    label: 'Перелік положень нормативно-правових  актів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      regulation: { label: 'Нормативний  акт', name: 'НормативныйАкт' },
      article: { label: 'Перелiк вiдповiдних статей (пунктiв) приведеного документа', name: 'СтатьиДок' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      sum1: { label: 'Граничний обсяг', name: 'ГраничнаяСумма' },
      sum2: { label: 'Необхiдна сума', name: 'НеобходимаяСумма' },
      sum3: { label: 'Не забезпеченi видатки', name: 'НеобеспечСумма' },
      description: { label: 'Необхiднi заходи', name: 'ОписаниеДействий' },
    },
  },
  extraMoney: {
    name: 'ДопДеньги',
    label: 'Додаткові кошти',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      sum1: { label: 'Необхiдно додатково', name: 'Сумма1' },
      sum2: { label: 'Необхiдно додатково', name: 'Сумма2' },
      sum0: { label: 'Необхiдно додатково', name: 'Сумма0' },
      reason1: { label: 'Обгрунтування необхiдностi додаткових коштiв', name: 'Обоснования1' },
      reason2: { label: 'Обґрунтування необхідності додаткових коштів', name: 'Обоснования2' },
    },
  },
  extraIndicators: {
    name: 'ДопПоказатели',
    label: 'Зміна показників, у разі виділення додатк.коштів',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      indicator: { label: 'Показник', name: 'Показатель' },
      type: { label: 'Тип показника', name: 'ТипПоказателя' },
      tasks: { label: 'Завдання', name: 'Задание' },
      units: { label: 'Од.вим.', name: 'ЕдИзм' },
      sum0: { label: 'Сума', name: 'Сумма0' },
      sum1: { label: 'Сума', name: 'Сумма1' },
      sum2: { label: 'Сума', name: 'Сумма2' },
    },
  },
  investExtraMoney: {
    name: 'ИнвестПрограммыДопДеньги',
    label: 'Об\'єкти будівництва',
    columns: {
      constructionObject: { label: 'Об\'єкти будівництва', name: 'ОбъектыСтроительства' },
      KDB: { label: 'КДБ', name: 'КДБ' },
      KFB: { label: 'КФБ', name: 'КФБ' },
      sfo: { label: 'Додатково необхiдно по спец. ф.', name: 'СФ0' },
      sf1: { label: 'План звітного періоду спец. ф.', name: 'СФ1' },
      sf2: { label: 'Прогноз звітного періоду спец. ф.', name: 'СФ2' },
      act: { label: 'Дефектний акт', name: 'ДефектныйАкт' },
      explanation: { label: 'Обгрунтування необхiдностi додаткових коштiв спец.ф.', name: 'ПояснениеСтр' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
    },
  },
  indicatorsForTarget: {
    name: 'ПоказателиДостиженияЦелей',
    label: 'Показники досягнення цілей план',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      indicator: { label: 'Показник досягнення цілей', name: 'ПоказательДостиженияЦелей' },
      target: { label: 'Ціль державної політики', name: 'ЦельГосПолитики' },
      zf0: { label: 'Заг.ф.', name: 'ЗФ0' },
      zf1: { label: 'Заг.ф.', name: 'ЗФ1' },
      zf2: { label: 'Заг.ф.', name: 'ЗФ2' },
      sf0: { label: 'Спец.ф.', name: 'СФ0' },
      sf1: { label: 'Спец.ф.', name: 'СФ1' },
      sf2: { label: 'Спец.ф.', name: 'СФ2' },
      sum0: { label: 'Сума', name: 'Сумма0' },
      sum1: { label: 'Сума', name: 'Сумма1' },
      sum2: { label: 'Сума', name: 'Сумма2' },
      br0: { label: 'в т.ч.Б.Р.', name: 'БР0' },
      br1: { label: 'в т.ч.Б.Р.', name: 'БР1' },
      br2: { label: 'в т.ч.Б.Р.', name: 'БР2' },
      zf_1: { label: 'Заг.ф.', name: 'ЗФ_1' },
      zf_2: { label: 'Заг.ф.', name: 'ЗФ_2' },
      sf_1: { label: 'Спец.ф.', name: 'СФ_1' },
      sf_2: { label: 'Спец.ф.', name: 'СФ_2' },
      sum_1: { label: 'Сума', name: 'Сумма_1' },
      sum_2: { label: 'Сума', name: 'Сумма_2' },
      br_1: { label: 'в т.ч.Б.Р.', name: 'БР_1' },
      br_2: { label: 'в т.ч.Б.Р.', name: 'БР_2' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Бюджетний запит',
  name: 'budgetRequest',
  backendName: 'БюджЗапрос',
  columns,
  frontend: 'doc/budgetRequest',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'received',
      // 'approved',
      // 'budgetRec',
      'codClassifierKBP7',
      'author',
      'note',
      'year',
      'appr',
      'sumF2',
      'sumF3',
      'CSU',
      'program',
      // 'author',
      // 'budget',
      // 'tags',
      // 'docTotalCost',
      // 'fondObject',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
