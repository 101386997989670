import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import ea from '../../actions/dpEditor';
import Loader from '../../components/styledWrappedLoader';
import storePathParam from '../../common/storePathParam';

export const mapState = (store) => {
  const { nameDp } = storePathParam(store);
  const mountPoint = `dp/${nameDp}/dpEditor`; // Точка мантирования данных (раздел store)
  const editorNode = store.get(mountPoint, new Map());

  return {
    isProcessing: editorNode.get('isProcessing', true),
    hasError: editorNode.get('hasError', false),
    errorMsg: editorNode.get('errorMsg', ''),
    headerForm: editorNode.get('headerForm', new Map()), // Шапка
    tables: editorNode.get('tables', new Map()), // Шапка
    mountPoint, // Точка мантирования данных (раздел store)
  };
};

/**
 *
 * @param name {string}
 * @param type {string}
 * @param extra.menu, {React.Component}
 * @param extra.tables, {React.Component}
 * @param extra.footer, {React.Component}
 * @return {React.Component}
 * @constructor
 */
const EditorHOC = ({ name, type }, extra) => (WrappedComponent) => {
  class DPEditor extends PureComponent {
    static displayName = `Data Processing Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      isProcessing: PropTypes.bool,
      hasError: PropTypes.bool,
      errorMsg: PropTypes.string,
      children: PropTypes.node,
      mapInitialOptions: PropTypes.shape(),
      mapStore: PropTypes.func,
    };

    static defaultProps = {
      isProcessing: false,
      hasError: false,
      errorMsg: null,
      mapInitialOptions: null,
      mapStore: null,
      children: null,
    };

    componentDidMount() {
      const {
        dispatch,
        mapInitialOptions,
        mapStore,
      } = this.props;
      dispatch(ea.init(type, name, mapInitialOptions, mapStore));
    }

    render() {
      const {
        dispatch, isProcessing, hasError, errorMsg, ...rest
      } = this.props;

      const MenuWrapper = extra && extra.menu;
      const TablesWrapper = extra && extra.tables;
      const FooterWrapper = extra && extra.footer;
      return (
        <Loader isLoading={isProcessing}>
          {MenuWrapper && (
            <MenuWrapper
              dispatch={dispatch}
              disabled={isProcessing || hasError}
            />
          )}
          { hasError && (
            <Alert variant="danger">
              <Alert.Heading>
                Вибачте, але щось пішло не так :-(
              </Alert.Heading>
              {errorMsg}
            </Alert>
          )}
          <div>
            <WrappedComponent {...this.props} />
            {TablesWrapper && (
              <TablesWrapper />
            )}
          </div>
          {FooterWrapper && React.cloneElement(FooterWrapper, this.props)}
        </Loader>
      );
    }
  }
  return connect(mapState)(DPEditor);
};

export default EditorHOC;
