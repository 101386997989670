import { Map } from 'immutable';
import favActions from '../constants/actions/favorites';

/**
 * @desc Reducer provides info about sessionOptions
 * @func
 * @param state {Immutable.Map}
 * @param action {object}
 * @param action.type {string}
 * @param action.togglePayload {{token:{string},sessionOptions:{object}}}
 * @return {Immutable.Map}
 */

const favReducer = (state = new Map(), { type, payload }) => {
  switch (type) {
    case favActions.toggleFavorites:
      return state
        .set('favList', payload);
    case favActions.loadFavoritesStart:
      return state
        .set('loading', true);
    case favActions.loadFavoritesError:
      return state
        .set('loading', false)
        .set('errored', true)
        .set('errText', payload);
    case favActions.loadFavoritesEnd:
      return state
        .set('loading', false)
        .set('errored', false)
        .set('favList', payload);
    case favActions.saveFavoritesStart:
      return state
        .set('loading', true);
    case favActions.saveFavoritesError:
      return state
        .set('loading', false)
        .set('errored', true)
        .set('errText', payload);
    case favActions.saveFavoritesEnd:
      return state
        .set('loading', false)
        .set('errored', false);
    default:
      return state;
  }
};

export default favReducer;
