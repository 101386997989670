/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { moneysOneDigitStingOptions } from '../../../../../constants/moneys';

function TableRow({ row }) {
  return (
    <tr className="text-800">
      <td>
        <div
          style={{ padding: '0.75rem 0.15rem' }}
        >
          {row.code}
        </div>
      </td>
      <td>
        <div style={{ padding: '0.75rem 0.15rem' }}>
          {row.name}
        </div>
      </td>
      <td className="text-end">
        <div style={{ padding: '0.75rem 0.15rem' }}>
          {row.amount.toLocaleString('uk', moneysOneDigitStingOptions)}
        </div>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
};

export default TableRow;
