import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { budget as md } from '../../../../constants/meta/catalogs/budget';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import Tabs from '../../../../components/tabs/Tabs';
import StyledTPPane from '../../../../components/tableParts/TPPane';

import { Title } from '../../../../components/Form/styledForm';

const StyledPane = styled(StyledTPPane)`
  height: auto;
  overflow: unset;
`;

const budgetTypes = {
  city: { name: 0, label: 'Міський (базовий)' },
  region: { name: 1, label: 'Обласний' },
  area: { name: 2, label: 'Районний' },
  village: { name: 3, label: 'Сільський' },
  township: { name: 4, label: 'Селищний' },
  cotyReg: { name: 5, label: 'Міський  (районного значення)' },
  community: { name: 6, label: 'Бюджет тер. громади' },
  area_city: { name: 7, label: 'Районний (у складі міста)' },
  state: { name: 8, label: 'Державний' },
};

const planingKindsOptions = Object.values(budgetTypes).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function Budget({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const mainPane = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetCode.label}
            value={data[md.columns.BudgetCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetCode.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TreasuryCode.label}
            value={data[md.columns.TreasuryCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TreasuryCode.name]: value,
            })}
            maxLength={18}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetName.label}
            value={data[md.columns.BudgetName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.IASCode.label}
            value={data[md.columns.IASCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.IASCode.name]: value,
            })}
            maxLength={3}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BudgetNameGenitive.label}
            value={data[md.columns.BudgetNameGenitive.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BudgetNameGenitive.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const secondPane = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.FinancialInstitution.label}
            value={data[md.columns.FinancialInstitution.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FinancialInstitution.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BankMaskFileOutcome.label}
            value={data[md.columns.BankMaskFileOutcome.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BankMaskFileOutcome.name]: value,
            })}
            maxLength={88}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.budgetType.label}
            value={data[md.columns.budgetType.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.budgetType.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BankMaskFile.label}
            value={data[md.columns.BankMaskFile.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BankMaskFile.name]: value,
            })}
            maxLength={88}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.BankMaskFileIncome.label}
            value={data[md.columns.BankMaskFileIncome.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.BankMaskFileIncome.name]: value,
            })}
            maxLength={88}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AccountingForNotesByNotes-${data.id}`}
            label={md.columns.AccountingForNotesByNotes.label}
            value={data[md.columns.AccountingForNotesByNotes.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccountingForNotesByNotes.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`UnderFunded-${data.id}`}
            label={md.columns.UnderFunded.label}
            value={data[md.columns.UnderFunded.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UnderFunded.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`EnergyCarriers-${data.id}`}
            label={md.columns.EnergyCarriers.label}
            value={data[md.columns.EnergyCarriers.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.EnergyCarriers.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CheckPaymentTypeFulfilment-${data.id}`}
            label={md.columns.CheckPaymentTypeFulfilment.label}
            value={data[md.columns.CheckPaymentTypeFulfilment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CheckPaymentTypeFulfilment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AccountingByPartners-${data.id}`}
            label={md.columns.AccountingByPartners.label}
            value={data[md.columns.AccountingByPartners.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccountingByPartners.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`SupplierInRequest-${data.id}`}
            label={md.columns.SupplierInRequest.label}
            value={data[md.columns.SupplierInRequest.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SupplierInRequest.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`FinResponsibilitiesManualInput-${data.id}`}
            label={md.columns.FinResponsibilitiesManualInput.label}
            value={data[md.columns.FinResponsibilitiesManualInput.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FinResponsibilitiesManualInput.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`TurnBuildObjOn-${data.id}`}
            label={md.columns.TurnBuildObjOn.label}
            value={data[md.columns.TurnBuildObjOn.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TurnBuildObjOn.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`DontCheckForCommitment-${data.id}`}
            label={md.columns.DontCheckForCommitment.label}
            value={data[md.columns.DontCheckForCommitment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DontCheckForCommitment.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flAddTargetProg-${data.id}`}
            label={md.columns.flAddTargetProg.label}
            value={data[md.columns.flAddTargetProg.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flAddTargetProg.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`FBRSubsystem-${data.id}`}
            label={md.columns.FBRSubsystem.label}
            value={data[md.columns.FBRSubsystem.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FBRSubsystem.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`VinnitsyaMode-${data.id}`}
            label={md.columns.VinnitsyaMode.label}
            value={data[md.columns.VinnitsyaMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.VinnitsyaMode.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`FinRequestToOrg-${data.id}`}
            label={md.columns.FinRequestToOrg.label}
            value={data[md.columns.FinRequestToOrg.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FinRequestToOrg.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`KFKAccordance-${data.id}`}
            label={md.columns.KFKAccordance.label}
            value={data[md.columns.KFKAccordance.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KFKAccordance.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CheckAccordancePlanCSU-${data.id}`}
            label={md.columns.CheckAccordancePlanCSU.label}
            value={data[md.columns.CheckAccordancePlanCSU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CheckAccordancePlanCSU.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`TurnTaskPCMFilterOn-${data.id}`}
            label={md.columns.TurnTaskPCMFilterOn.label}
            value={data[md.columns.TurnTaskPCMFilterOn.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TurnTaskPCMFilterOn.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ShowSourcesInFinDocs-${data.id}`}
            label={md.columns.ShowSourcesInFinDocs.label}
            value={data[md.columns.ShowSourcesInFinDocs.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ShowSourcesInFinDocs.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`SalaryFactCurYear-${data.id}`}
            label={md.columns.SalaryFactCurYear.label}
            value={data[md.columns.SalaryFactCurYear.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SalaryFactCurYear.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`CheckAccordanceTemplateNote-${data.id}`}
            label={md.columns.CheckAccordanceTemplateNote.label}
            value={data[md.columns.CheckAccordanceTemplateNote.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CheckAccordanceTemplateNote.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`PrintNumbContract-${data.id}`}
            label={md.columns.PrintNumbContract.label}
            value={data[md.columns.PrintNumbContract.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.PrintNumbContract.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`TurnScrapsControlOnAccountsOff-${data.id}`}
            label={md.columns.TurnScrapsControlOnAccountsOff.label}
            value={data[md.columns.TurnScrapsControlOnAccountsOff.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TurnScrapsControlOnAccountsOff.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AccountForBalancesOnTreasuryAccounts-${data.id}`}
            label={md.columns.AccountForBalancesOnTreasuryAccounts.label}
            value={data[md.columns.AccountForBalancesOnTreasuryAccounts.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccountForBalancesOnTreasuryAccounts.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flReqReceivedByFD-${data.id}`}
            label={md.columns.flReqReceivedByFD.label}
            value={data[md.columns.flReqReceivedByFD.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flReqReceivedByFD.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col sm={6}>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`flLimitDateOfRequestLoad-${data.id}`}
                label={md.columns.flLimitDateOfRequestLoad.label}
                value={data[md.columns.flLimitDateOfRequestLoad.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.flLimitDateOfRequestLoad.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            {data[md.columns.flLimitDateOfRequestLoad.name] && (
              <Col>
                <EditorControls.NumberInput
                  label={md.columns.dayAmountOfRequestLoad.label}
                  value={data[md.columns.dayAmountOfRequestLoad.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.dayAmountOfRequestLoad.name]: value,
                  })}
                  readOnly={readOnly}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ShowSourcesFinInDocFin-${data.id}`}
            label={md.columns.ShowSourcesFinInDocFin.label}
            value={data[md.columns.ShowSourcesFinInDocFin.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.ShowSourcesFinInDocFin.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`baseBudget-${data.id}`}
            label={md.columns.baseBudget.label}
            value={data[md.columns.baseBudget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.baseBudget.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`includeDictIndicators-${data.id}`}
            label={md.columns.includeDictIndicators.label}
            value={data[md.columns.includeDictIndicators.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.includeDictIndicators.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`editPCMDocs-${data.id}`}
            label={md.columns.editPCMDocs.label}
            value={data[md.columns.editPCMDocs.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.editPCMDocs.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`usePCM-${data.id}`}
            label={md.columns.usePCM.label}
            value={data[md.columns.usePCM.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.usePCM.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`SignByRegion-${data.id}`}
            label={md.columns.SignByRegion.label}
            value={data[md.columns.SignByRegion.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SignByRegion.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <EditorControls.CheckboxInput
            controlId={`forbidUnexecutingBRPrinting-${data.id}`}
            label={md.columns.forbidUnexecutingBRPrinting.label}
            value={data[md.columns.forbidUnexecutingBRPrinting.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.forbidUnexecutingBRPrinting.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Title>Обрахунок залишкiв</Title>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`ForbidChangesForScraps-${data.id}`}
            label={md.columns.ForbidChangesForScraps.label}
            value={data[md.columns.ForbidChangesForScraps.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.ForbidChangesForScraps.name]: value,
              });
              if (value) {
                actions.onChange({
                  [md.columns.ForbidChangesForScraps.name]: !value,
                });
              }
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`OnlyByScrapsOfSteward-${data.id}`}
            label={md.columns.OnlyByScrapsOfSteward.label}
            value={data[md.columns.OnlyByScrapsOfSteward.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.OnlyByScrapsOfSteward.name]: value,
              });
              if (value) {
                actions.onChange({
                  [md.columns.OnlyByScrapsOfSteward.name]: !value,
                });
              }
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`checkPlansByIncome-${data.id}`}
            label={md.columns.checkPlansByIncome.label}
            value={data[md.columns.checkPlansByIncome.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.checkPlansByIncome.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={11}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeTLTerra.label}
            value={data[md.columns.CodeTLTerra.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeTLTerra.name]: value,
            })}
            maxLength={11}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Tabs>
        <div label="Основне">
          <StyledPane>
            {mainPane}
          </StyledPane>
        </div>
        <div label="Налаштування">
          <StyledPane>
            {secondPane}
          </StyledPane>
        </div>
      </Tabs>
    </Container>
  );
}

Budget.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Budget;
