const frontendURL = '/cat/genderstatus/';
const backendURL = '/api/references/refgenderstatus/';
const name = 'Гендерний статус';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
