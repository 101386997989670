export const LIGIKA_URL_DATES = 'https://logica.minfin.gov.ua/ws/api/_MINFIN.GETDKSUDATES_JSON';
export const LIGIKA_URL_FILE = 'https://logica.minfin.gov.ua/ws/api/_MINFIN.GETDKSUDATA_JSON';

// export const DATATYPES = ['FT110', 'VD0', 'VPF', 'BLS', 'FU', 'FF', '@B'];
export const DATATYPES = ['FT110', 'VD0', 'VPF',
  'BLS', 'FU', 'FF', 'DM_2020', 'BRMB_M',
  // '@B',
  'V2MF_2020', 'V2MB_2020', 'KRM_2020', 'FM_2020',
  'V7MZ_2020', 'V7MS_2020', 'V73Z_2020', 'V73S_2020',
  'ZAH_MB', 'MZS_2020', 'RZM_M', 'RZM_Y', 'V2KB_2020',
  'BRMB_Q', 'MZS_2020Q', 'CTANBORG', 'CTGARNBORG', 'GARANTMB',
  'DMZ_2020', 'DMS_2020', 'V2MZ_2020', 'V2MS_2020', 'KRMZ_2020',
  'KRMS_2020', 'FMZ_2020', 'FMS_2020', 'V7MZ_2020Q', 'V7MS_2020Q',
  'V73Z_2020Q', 'V73S_2020Q', 'FT01',
]; // 'ZAH_MB', 'MZS_2020' - errors
// export const DATATYPES = ['FT110', 'VD0', 'VPF', 'BLS'];// 'VD0']; // 'VPF'

// Щоденні дані:
// FT110 - Щоденна інформація про стан виконання доходів місц. бюджетів,
// VD0 - Щоденна інформація про стан виконання МБ за видатками,
// VPF - Видатки та кредитування,
// BLS - Щомісячна інформація про залишки коштів на рахунках 3142 та 3152,
// FU - Щоденна інформація про зареєстровані бюджетні зобов'язання розпорядників (одержувачів) бюджетних коштів, ' +
// FF - Щоденна інформація про зареєстровані в органах Казначейства бюджетні фінансові зобов'язання розпорядників (одержувачів) бюджетних коштів,
// @B - Відомості про зарахування та повернення коштів з аналітичних рахунків. Технологічні файли
// Щомісячні дані:
// DM_2020 - Виконання місцевих бюджетів за доходами у розрізі усіх місцевих бюджетів (до рівня села, селища),
// V2MF_2020 - Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища),
// V2MB_2020 - Виконання місцевих бюджетів за видатками у розрізі усіх місцевих бюджетів (до рівня села, селища),
// KRM_2020 - Виконання місцевих бюджетів за кредитуванням у розрізі усіх місцевих бюджетів (до рівня села, селища),
// FM_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов'язання у розрізі усіх місцевих бюджетів (до рівня села, селища),
// BRMB_M - Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів,
// V7MZ_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - загальний фонд,
// V7MS_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - спеціальний фонд,
// V73Z_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - загальний фонд,
// V73S_2020 - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - спеціальний фонд,
// ZAH_MB - Інформація про виконання захищених видатків місцевих бюджетів,
// MZS_2020 - Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об'єднаних територіальних громад '''
// RZM_M - Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щомісячна інформація
// RZM_Y - Звіт про використання коштів з резервного фонду, у розрізі усіх місцевих бюджетів щорічна інформація
// V2KB_2020 - Виконання місцевих бюджетів за видатками у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - за коштами на рахунках в банках
// BRMB_Q - Звіт про виконання бюджету розвитку місцевих бюджетів у розрізі усіх місцевих бюджетів
// MZS_2020Q - Звіт щодо надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об'єднаних територіальних громад
// CTANBORG - Інформація про стан місцевих боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад
// CTGARNBORG - Інформація про стан гарантованих відповідно Автономною Республікою Крим, обласними радами та територіальними громадами боргів у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад
// GARANTMB - Інформація про надані місцеві гарантії у розрізі бюджету Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад (щоквартальна, щорічна)
// DMZ_2020 - Виконання місцевих бюджетів за доходами, загальний фонд
// DMS_2020 - Виконання місцевих бюджетів за доходами, cпеціальний фонд
// V2MZ_2020 - Виконання місцевих бюджетів за видатками (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього
// V2MS_2020 - Виконання місцевих бюджетів за видатками (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад) - всього
// KRMZ_2020 - Виконання місцевих бюджетів за видатками – кредитування (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього
// KRMS_2020 - Виконання місцевих бюджетів за видатками – кредитування (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад – всього
// FMZ_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (загальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього
// FMS_2020 - Фінансування місцевих бюджетів за типом кредитора та за типом боргового зобов’язання (спеціальний фонд) у розрізі бюджетів Автономної Республіки Крим, обласних бюджетів, бюджетів міст Києва та Севастополя, міст республіканського Автономної Республіки Крим та обласного підпорядкування, бюджетів районів, бюджетів об’єднаних територіальних громад - всього
// V7MZ_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - загальний фонд
// V7MS_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) - спеціальний фонд
// V73Z_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - загальний фонд
// V73S_2020Q - Кредиторська та дебіторська заборгованість бюджетних установ, які отримують кошти місцевих бюджетів (до рівня ОТГ) за окремими програмами - спеціальний фонд

export function getDateStr(v) {
  const cd = new Date(v);
  return cd.toLocaleString('uk', { day: 'numeric', month: 'short' });
}
