import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { SearchInput } from '../styledInputs';

export const ContainerHeader = styled.div`
    // margin-bottom: 20px;
`;

export const StyleInnerHeader = styled.h3`
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    color: #008F21;
    margin-bottom: 20px;
`;

// const FILTER_KEYS = ['label'];

function StyledMenuHeader({ inputValue, setInputValue, children }) {
  return (
    <ContainerHeader>
      <StyleInnerHeader>{children}</StyleInnerHeader>
      <div className="d-flex w-100 gap-2">
        <SearchInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          className="ms-auto me-2 align-self-start"
          href="#/srv/reportsCalendar/"
          variant="outline-success"
        >
          <FontAwesomeIcon icon={faQuestion} beat className="me-2" />
          Перелік звітних форм та терміни їх надсилання до Logica
        </Button>
        <Button
          className="ms-auto me-2 align-self-start"
        // href={`${process.env.PUBLIC_URL}/help/doc/User_manual_2022.pdf`}
          href={`${process.env.PUBLIC_URL}/help/doc/User_manual_2022.docx`}
        // download="Довідник Користувача ЄІСУБ.pdf"
          download="Довідник Користувача ЄІСУБ.docx"
          variant="outline-success"
        >
          <FontAwesomeIcon icon={faQuestion} beat className="me-2" />
          Завантажити довідник користувача
        </Button>
      </div>
    </ContainerHeader>
  );
}

StyledMenuHeader.propTypes = {
  children: PropTypes.node,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
};

StyledMenuHeader.defaultProps = {
  children: null,
  inputValue: '',
};

export default StyledMenuHeader;
