import PropTypes from 'prop-types';

export const foreignPropType = PropTypes.shape({
  id: PropTypes.number,
  repr: PropTypes.string,
  url: PropTypes.string,
  resource: PropTypes.string,
});

const dataPropTypes = {
  foreignPropType,
};

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export default dataPropTypes;
