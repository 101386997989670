import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/banks';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function Banks({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.UDKName.label}
            value={data[md.columns.UDKName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UDKName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeUDK.label}
            value={data[md.columns.CodeUDK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeUDK.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.MFO.label}
            value={data[md.columns.MFO.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.MFO.name]: value,
            })}
            maxLength={6}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.VDKName.label}
            value={data[md.columns.VDKName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.VDKName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.CodeVDK.label}
            value={data[md.columns.CodeVDK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.CodeVDK.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TerritoryCode.label}
            value={data[md.columns.TerritoryCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TerritoryCode.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.DirectiveName.label}
            value={data[md.columns.DirectiveName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DirectiveName.name]: value,
            })}
            maxLength={600}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.TerritoryCodeFin.label}
            value={data[md.columns.TerritoryCodeFin.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.TerritoryCodeFin.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Level.label}
            value={data[md.columns.Level.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Level.name]: value,
            })}
            maxLength={9}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`DoesTreasuryIncluded-${data.id}`}
            label={md.columns.DoesTreasuryIncluded.label}
            value={data[md.columns.DoesTreasuryIncluded.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DoesTreasuryIncluded.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

Banks.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Banks;
