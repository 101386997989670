import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/garanty';

const meta = md.tables.TABL2;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG1S'}
              highlighted={highlights.includes('T2RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG1S')}
            >
              {meta.columns.T2RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG2'}
              highlighted={highlights.includes('T2RXXXXG2')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG2')}
            >
              {meta.columns.T2RXXXXG2.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG3S'}
              highlighted={highlights.includes('T2RXXXXG3S')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG3S')}
            >
              {meta.columns.T2RXXXXG3S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG4S'}
              highlighted={highlights.includes('T2RXXXXG4S')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG4S')}
            >
              {meta.columns.T2RXXXXG4S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG5'}
              highlighted={highlights.includes('T2RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG5')}
            >
              {meta.columns.T2RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG6S'}
              highlighted={highlights.includes('T2RXXXXG6S')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG6S')}
            >
              {meta.columns.T2RXXXXG6S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG7'}
              highlighted={highlights.includes('T2RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG7')}
            >
              {meta.columns.T2RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG8'}
              highlighted={highlights.includes('T2RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG8')}
            >
              {meta.columns.T2RXXXXG8.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T2RXXXXG9S'}
              highlighted={highlights.includes('T2RXXXXG9S')}
              onToggleHighlght={() => onToggleHighlght('T2RXXXXG9S')}
            >
              {meta.columns.T2RXXXXG9S.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
