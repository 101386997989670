import React from 'react';

function ClosedFolderDeleted() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1212 13.1213C13.2928 11.9497 13.2928 10.0503 12.1212 8.8787C10.9497 7.70714 9.05019 7.70714 7.87862 8.8787C6.70706 10.0503 6.70706 11.9497 7.87862 13.1213C9.05019 14.2929 10.9497 14.2929 12.1212 13.1213Z" fill="#FF0000" />
      <path d="M9.30005 10.3L10.7 11.7" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.30005 11.7L10.7 10.3" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 15.5V6.5H10L9 4.5H3.5V15.5C8.57682 15.5 11.4232 15.5 16.5 15.5Z" stroke="#a0a3ab" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ClosedFolderDeleted;
