import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { editorHooks, EditorControls } from '../../../basicEditor/editorControls';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,
  } = useEditor({
    backendURL: meta.cat.contragentaccount.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, fieldErrors, actions.onChange);
  const noteProps = editorHooks.useStringInputProps('note', data, fields, fieldErrors, actions.onChange);
  const accountProps = editorHooks.useStringInputProps('account', data, fields, fieldErrors, actions.onChange);
  const disposersContragentProps = editorHooks.useItemInputProps('disposers_contragent', data, fields, fieldErrors, actions.onChange);
  const bankProps = editorHooks.useItemInputProps('bank', data, fields, fieldErrors, actions.onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange);
  const isDefaultProps = editorHooks.useCheckboxInputProps('is_default', data, fields, fieldErrors, actions.onChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, fieldErrors, actions.onChange);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.contragentaccount.backendURL}
        />
      )}
    >
      <LabelTab defaultActiveKey="conrtragent" id="uncontrolled-tab-example">
        <Tab eventKey="conrtragent" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col>
                  <EditorControls.StringInput {...codeProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.StringInput {...noteProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...accountProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...disposersContragentProps} modelType="cat" modelName="disposerscontragent" />
                </Col>
                <Col>
                  <EditorControls.ItemPicker {...bankProps} modelType="cat" modelName="contragentbank" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...parentProps} modelType="cat" modelName="contragentaccount" />
                </Col>
                <Col>
                  <Col>
                    <EditorControls.CheckboxInput {...isGroupProps} />
                  </Col>
                  <Col>
                    <EditorControls.CheckboxInput {...isDefaultProps} />
                  </Col>
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
