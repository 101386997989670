import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../../actions/reportEditor';
import { CustomFilter, getMapDispatchToProps, getMapStateToProps } from '../../Filter';

function AppearanceFilter({ mountPoint, rowKey }) {
  const mapState = useMemo(
    () => {
      const dataPath = ['dataCompositionSettings', 'ConditionalAppearance', rowKey, 'Filter'];
      const avaPath = ['dataCompositionSettings', 'ConditionalAppearanceFilterAvailableFields'];// ''

      return getMapStateToProps(mountPoint, dataPath, avaPath);
    },
    [mountPoint, rowKey],
  );

  const {
    data, availableFields, path, avpath,
  } = useSelector(mapState);
  const dispatch = useDispatch();
  const {
    onDeleteField,
    onChangeField,
    onSelectField,
    onAddField,
    onToggleField,
  } = getMapDispatchToProps(actions)(dispatch);
  return (
    <CustomFilter
      data={data}
      availableFields={availableFields}
      path={path}
      avpath={avpath}
      onDeleteField={onDeleteField}
      onSelectField={onSelectField}
      onToggleField={onToggleField}
      onAddField={onAddField}
      onChangeField={onChangeField}
    />
  );
}

AppearanceFilter.propTypes = {
  mountPoint: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired,
};

export default AppearanceFilter;
