import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: { label: 'Код', name: 'Код', visible: true },
  name: { label: 'Найменування', name: 'Наименование', visible: false },
  fullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  noCount: { label: 'Не враховувати при розрахунках', name: 'НеУчитывать', visible: false },
};

const tables = {
  general: {
    name: 'ТабличнаяЧасть1',
    label: 'Задіяні КЕКВ',
    columns: {
      kekvClassifier: { label: 'КЕКВ', name: 'КЕКВ' },
    },
  },
};

export const elementSocialPayment = {
  label: 'Показники заборгованості по соц. виплатам',
  name: 'elementSocialPayment',
  backendName: 'ПоказателиСоцВыплаты',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/elementSocialPayment',
  listColumns: [...(new Set([...reqListerColumns, 'fullName']))],
  tables,
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default elementSocialPayment;
