import React, {
  memo, useCallback,
} from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import md from '../../../../../../constants/meta/documents/financeObligation';

const tablename = 'presentedDocs';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row noGutters>
          <Col>
            <TableRowCell column="doc" highlighted={highlights.includes('doc')}>
              <ItemPicker
                value={row[tableMD.columns.doc.name]}
                modelType="cat"
                modelName="submittedDocuments"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.doc.name]: v })}
                noHierarchy
                readOnly={readOnly}
                prepend={(

                  <InputGroup.Text>
                    <CheckboxInput
                      id={`IsReturn-${rowIndex}`}
                      readOnly={readOnly}
                      value={row[tableMD.columns.use.name]}
                      onChange={(e, v) => {
                        onRowChange(e, { [tableMD.columns.use.name]: v });
                        console.log('v', v);
                      }}
                    />
                  </InputGroup.Text>

                )}
              />
            </TableRowCell>
          </Col>
        </Row>

      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  Budget: PropTypes.shape({
  }).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
