import {
  ButtonGroup, Card, Dropdown, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/legalObligation';
import mdDoc from '../../../../constants/meta/documents';
import LegalObligation from './editor';
import { modelType, modelName } from '../def';
import LegalObligationTabs from './tables';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import APMenu from './menu';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */
function isReadOnly(data) {
  return !!data[md.columns.isApproved.name];
}

function LegalObligationEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    readOnlyGetter: isReadOnly,
  });

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
        loading={loading}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
        data={data}
        loading={loading}
      >
        <ButtonGroup>
          <Dropdown>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="create_related">
                  Створити на підставі
                </Tooltip>
              )}
            >
              <Dropdown.Toggle>
                Створити на підставі
              </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/${mdDoc.legalObligation.frontend}/create?reason=${id}`}>
                {mdDoc.legalObligation.label}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/${mdDoc.financeObligation.frontend}/create?reason=${id}`}>
                {mdDoc.financeObligation.label}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/${mdDoc.docRegister.frontend}/create?reason=${id}`}>
                {mdDoc.docRegister.label}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>

      </APMenu>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <LegalObligation data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <LegalObligationTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

LegalObligationEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

LegalObligationEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { LegalObligationEditor as StdEditor };

export default getRouterEditor(md)(LegalObligationEditor);
