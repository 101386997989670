import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/kfbClassifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const signs = {
  first: { value: 0, display_name: '-' },
  second: { value: 1, display_name: '+' },
  third: { value: 2, display_name: 'Не враховувати' },
};

const KFBKinds = {
  first: { value: 'Обычный', display_name: 'Звичайний' },
  second: { value: 'НачальныйОстаток', display_name: 'Залишок на початок' },
  third: { value: 'КонечныйОстаток', display_name: 'Залишок на кінець' },
};

function KFBClassifier({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.code.label}
            value={data[md.columns.code.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.code.name]: value,
            })}
            readOnly={readOnly}
            maxLength={6}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={100}
          />
        </Col>
      </Row>
      <h6 className="font-weight-bold text-primary">
        Код та назва, станом на вказану дату
      </h6>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCl.label}
            value={data[md.columns.DateCl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateCl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.tCode.label}
            value={data[md.columns.tCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.tCode.name]: value,
            })}
            readOnly={readOnly}
            maxLength={6}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.Sign.label}
            value={data[md.columns.Sign.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Sign.name]: value,
            })}
            readOnly={readOnly}
            values={Object.values(signs)}
          />
        </Col>
        <Col>
          <EditorControls.RadioInput
            label={md.columns.KFBKind.label}
            value={data[md.columns.KFBKind.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.KFBKind.name]: value,
              });
              actions.onSR('ПриИзменении_ВидКФБ');
            }}
            readOnly={readOnly}
            values={Object.values(KFBKinds)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="kfbClassifier"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

KFBClassifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KFBClassifier;
