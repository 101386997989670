import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NumberInput, ItemPicker } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/borderLimit';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'general';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights, FI,
}) {
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumZFChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumZF.name;
      const colName2 = tableMD.columns.sum.name;

      const tot = value + (row[colName2] || 0) - (row[colName] || 0);

      onRowChange(e, {
        [colName]: value,
        [colName2]: tot,
      });
    },
    [onRowChange, row],
  );

  const onSumSFChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumSF.name;
      const colName2 = tableMD.columns.sum.name;

      const tot = value + (row[colName2] || 0) - (row[colName] || 0);

      onRowChange(e, {
        [colName]: value,
        [colName2]: tot,
      });
    },
    [onRowChange, row],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="csu" highlighted={highlights.includes('csu')}>
              <ItemPicker
                value={row[tableMD.columns.csu.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.csu.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="csu"
                filter={CSUFIlter}
                noHierarchy
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="branches" highlighted={highlights.includes('branches')}>
              <ItemPicker
                value={row[tableMD.columns.branches.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.branches.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="industryDet"
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="kbp" highlighted={highlights.includes('kbp')}>
              <ItemPicker
                value={row[tableMD.columns.kbp.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kbp.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="kbpClassifier"
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="kekv" highlighted={highlights.includes('kekv')}>
              <ItemPicker
                value={row[tableMD.columns.kekv.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.kekv.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="kekvClassifier"
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="elKekv" highlighted={highlights.includes('elKekv')}>
              <ItemPicker
                value={row[tableMD.columns.elKekv.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.elKekv.name]: v })}
                readOnly={readOnly}
                modelType="cat"
                modelName="elementKEKV"
                filter={FIOwnerFilter}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sum.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="sumZF" highlighted={highlights.includes('sumZF')}>
              <NumberInput
                value={row[tableMD.columns.sumZF.name]}
                precision={2}
                onChange={(e, v) => onSumZFChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sumSF" highlighted={highlights.includes('sumSF')}>
              <NumberInput
                value={row[tableMD.columns.sumSF.name]}
                precision={2}
                onChange={(e, v) => onSumSFChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sumBR" highlighted={highlights.includes('sumBR')}>
              <NumberInput
                value={row[tableMD.columns.sumBR.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumBR.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
