import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { kbp7Classifier as md } from '../../../../constants/meta/catalogs/kbp7Classifier';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { selectionMode } from '../../../../constants/meta/common';

function KBP7Classifier({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            readOnly={readOnly}
            maxLength={150}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCl.label}
            value={data[md.columns.DateCl.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.DateCl.name]: value,
              });
              actions.onSR('ПриИзменении_Дата');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.tCode.label}
            value={data[md.columns.tCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.tCode.name]: value,
            })}
            readOnly={readOnly}
            maxLength={7}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Classifier.label}
            value={data[md.columns.Classifier.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Classifier.name]: value,
            })}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.KVK.label}
            value={data[md.columns.KVK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KVK.name]: value,
            })}
            modelType="cat"
            modelName="kvkClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.StewardLevel.label}
            value={data[md.columns.StewardLevel.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.StewardLevel.name]: value,
            })}
            readOnly={readOnly}
            maxLength={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`SubProgram-${data.id}`}
            label={md.columns.SubProgram.label}
            value={data[md.columns.SubProgram.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.SubProgram.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`DoNotIssuePassportBP-${data.id}`}
            label={md.columns.DoNotIssuePassportBP.label}
            value={data[md.columns.DoNotIssuePassportBP.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DoNotIssuePassportBP.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`flDoNotUseSessionDecision-${data.id}`}
            label={md.columns.flDoNotUseSessionDecision.label}
            value={data[md.columns.flDoNotUseSessionDecision.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.flDoNotUseSessionDecision.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`Inactive-${data.id}`}
            label={md.columns.Inactive.label}
            value={data[md.columns.Inactive.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Inactive.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="kbp7Classifier"
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.owner.label}
            value={data[md.columns.owner.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.owner.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
}

KBP7Classifier.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default KBP7Classifier;
