import React, { useCallback, useRef } from 'react';
import { Container } from 'react-bootstrap';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { CPButton } from './buttons/styles';
//
// const FileInput = styled.input.attrs(({ multiple }) => ({
//   type: 'file',
//   multiple,
// }))`
//   width: 0.1px;
//   height: 0.1px;
//   opacity: 0;
//   overflow: hidden;
//   position: absolute;
//   z-index: -1;
// `;

function FileUploadDropZone({
  disabled, title, multiple, readAs, onChange, children,
}) {
  const files = useRef({});

  const onFilesDrop = useCallback(
    // eslint-disable-next-line consistent-return
    (e) => {
      e.preventDefault();
      if (disabled) return false;
      const fls = [...e.dataTransfer.files];
      const fileNames = fls.map((f) => f.name);
      fls.filter((_, idx) => multiple || idx === 0).forEach((file) => {
        const fr = new FileReader();
        if (readAs === 'text') {
          fr.readAsText(file);
        } else if (readAs === 'dataURL') {
          fr.readAsDataURL(file);
        } else {
          throw new Error(`Read method ${readAs} not implemented`);
        }
        fr.onloadend = () => {
          files.current[file.name] = fr.result;
          if (fileNames.reduce((R, f) => R && Object.keys(files.current).includes(f), true)) {
            onChange(e, files.current);
          }
        };
      });
    },
    [disabled, multiple, onChange, readAs],
  );
  return (
    <Container
      onDragOver={(e) => e.preventDefault()}
      onDrop={onFilesDrop}
      className="bg-primary-subtle my-3 p-5 rounded-3"
      fluid
    >
      {title && (<h1>{title}</h1>)}
      {children}
    </Container>
  );
}

FileUploadDropZone.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  readAs: PropTypes.oneOf(['text', 'dataURL']),
  children: PropTypes.node,
};

FileUploadDropZone.defaultProps = {
  onChange: () => null,
  disabled: false,
  title: '',
  multiple: false,
  readAs: 'text',
  children: null,
};

export { FileUploadDropZone };

export default FileUploadDropZone;
