const frontendURL = '/doc/educationexpenditure/';
const backendURL = '/api/merezha/educationexpenditureheader/';
const name = 'Документ про бюджетні видатки на загальну середню освіту';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
