import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card, Tabs, Tab, Row, Col, Container, Badge,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import md from '../../../../constants/meta/catalogs/planing';
import {
  StyledButton,
} from '../../../../components/Form/styledForm';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import { soSelector } from '../../../documents/_common/selectors';
import BudgetFOUserEditorRow from '../../../documents/_common/BudgetFOUserEditorRow';

const Button = styled(StyledButton)`
    justify-content: center;
    height: 100%;
    width: 250px;
    cursor: pointer;
    background: ${(props) => props.background};
    &:hover{
    background: ${(props) => props.hover};
    }
`;

function PlaningEditorData({
  data, actions,
}) {
  const sessionOptions = useSelector(soSelector);

  const FI = data[md.columns.FI.name];

  const signFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  return (
    <Card.Body>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={(pd) => actions.onChange({
            ...pd,
            [md.columns.owner.name]: pd[md.columns.budget.name] || data[md.columns.budget.name],
          })}
          data={data}
        />
      )}
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <EditorControls.CheckboxInput */}
      {/*      label={md.columns.proj.label} */}
      {/*      value={data[md.columns.proj.name]} */}
      {/*      onChange={(e, value) => actions.onChange({ [md.columns.proj.name]: value })} */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        <Col sm={6}>
          <EditorControls.StringInput
            required
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({ [md.columns.name.name]: value })}
            maxLength={125}
          />
        </Col>
        <Col sm={2}>
          <EditorControls.YearInput
            required
            label={md.columns.PY.label}
            value={data[md.columns.PY.name]}
            onChange={(e, value) => actions.onChange({ [md.columns.PY.name]: value })}
          />
        </Col>
        <Col sm={4}>
          <EditorControls.StringInput
            required
            label={md.columns.KLB.label}
            value={data[md.columns.KLB.name]}
            onChange={(e, value) => actions.onChange({ [md.columns.KLB.name]: value })}
            maxLength={11}
          />
        </Col>
        <Col sm={12}>
          <EditorControls.ItemPicker
            label={md.columns.signVariant.label}
            value={data[md.columns.signVariant.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.signVariant.name]: value,
            })}
            modelType="cat"
            modelName="signatureVariants"
            filter={signFilter}
            required
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="general" className="m-0 mt-2 ">
        <Tab title="Склад" eventKey="general">
          <Container fluid className="py-2 border">
            <Row>

              {[...Array(12)].map((_, i) => (
                <Col sm={2} key={i} eventKey={`add${i + 1}`} className="my-2">
                  {data[md.columns[`ADD${i + 1}`].name] ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                  ) : (
                    <FontAwesomeIcon icon={faExclamation} className="text-warning me-2" />
                  )}
                  <span className="me-1">{md.columns[`ADD${i + 1}`].label}</span>
                  {data[md.columns[`ADD${i + 1}`].name] ? (
                    <Badge variant="success">завантажено</Badge>
                  ) : (
                    <Badge variant="warning">відсутній</Badge>
                  )}
                </Col>
              ))}
            </Row>
          </Container>
        </Tab>
        <Tab title="Додатки до прогнозу" eventKey="applications">
          <Container fluid className="py-2 border">
            <Row>
              {[...Array(12)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col sm={4} key={i}>
                  <EditorControls.TextInput
                    label={md.columns[`ADD${i + 1}`].label}
                    value={data[md.columns[`ADD${i + 1}`].name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns[`ADD${i + 1}`].name]: value,
                    })}
                  />

                </Col>
              ))}
            </Row>

          </Container>
        </Tab>
      </Tabs>
    </Card.Body>

  );
}

PlaningEditorData.propTypes = {
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({

  }).isRequired,
};

export default PlaningEditorData;
