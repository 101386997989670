import React, { useContext } from 'react';
import { DocListerCommandPanel } from '../../../newLister/tableComponent/commandpanel';
import ListerContext from '../../../newLister/context';
import PrintModal from '../printModal';

function RegistrationFormMenu() {
  const {
    selectedRows,
  } = useContext(ListerContext);
  return (
    <DocListerCommandPanel>
      <PrintModal selectedRows={selectedRows} />
    </DocListerCommandPanel>
  );
}

export default RegistrationFormMenu;
