/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Placeholder, Table,
} from 'react-bootstrap';
import useTablePart from '../hook/tablepart';
import TPCommandPanel from '../commandPanels/tablepart';
import Header from './header';
import TemplateRow from './templateRow';

function Tp({
  onChange, data, fields, errors, readOnlyFields,
  readOnly, rowTemplate, tableName, headerTemplate, additionalTpActions,
}) {
  const {
    tableData, tableActions, activeCol,
    showFooter, pinHeader, tableContainerRef,
    activeRow, tableFields, highlights, tableErrors, tableReadOnlyFields,
  } = useTablePart({
    data, onChange, tableName, readOnlyFields, errors, fields,
  });

  return (
    <Card className="border-0">
      <TPCommandPanel
        pinHeader={pinHeader}
        tableActions={tableActions}
        showFooter={showFooter}
        readOnly={readOnly}
        activeRow={activeRow}
      >
        {additionalTpActions}
      </TPCommandPanel>
      <Card.Body className="p-0" ref={tableContainerRef}>
        <Table striped bordered hover responsive size="sm">
          <Header
            headerTemplate={headerTemplate}
            highlights={highlights}
            onToggleHighlight={tableActions.onToggleHighlightColumn}
            activeCol={activeCol}
            tableFields={tableFields}
          />
          <tbody>
            {tableData && tableData.map((row, idx) => (
              <TemplateRow
                key={idx}
                active={activeRow === idx}
                activeCol={activeRow === idx ? activeCol : null}
                rowIndex={idx}
                row={row}
                data={data}
                rowTemplate={rowTemplate}
                onChangeRow={tableActions.onCellChange}
                onMoveRow={tableActions.onMoveRow}
                readOnly={readOnly}
                highlights={highlights}
                fields={tableFields}
                errors={{
                  ...tableErrors[idx],
                  ...row.fieldErrors,
                }}
                readOnlyFields={tableReadOnlyFields}
              />
            ))}
          </tbody>
        </Table>
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
    </Card>
  );
}

Tp.propTypes = {
  data: PropTypes.shape([]).isRequired,
  headerTemplate: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rowTemplate: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  readOnlyFields: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  additionalTpActions: PropTypes.node,
};

Tp.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
  fields: null,
  additionalTpActions: null,
};

export default Tp;
