import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import ForLink from '../../../components/blanks/common/ForLink';
import Background from '../../../components/blanks/common/Background';

function InfoCard({
  corner, headerTxt, subHeaderTxt, children,
  footerLinkName, footerLink, callback, id,
}) {
  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Background
        image={corner}
        className="bg-card"
      />
      <Card.Header>
        <div id={id}>
          <h4 className="text-warning mb-0">{headerTxt}</h4>
          <h5 className="text-700">
            {subHeaderTxt}
          </h5>
        </div>
      </Card.Header>
      <Card.Body className="position-relative">
        {children}
      </Card.Body>

      {footerLinkName && (
      <Card.Footer className="bg-light text-end py-0 position-relative">
        <ForLink
          title={footerLinkName}
          to={footerLink}
          icon="external-link-alt"
          className="fw-medium"
          onClick={callback}
        />
      </Card.Footer>
      )}
    </Card>
  );
}

InfoCard.propTypes = {
  children: PropTypes.node,
  corner: PropTypes.string,
  id: PropTypes.string,
  headerTxt: PropTypes.string,
  subHeaderTxt: PropTypes.string,
  footerLinkName: PropTypes.string,
  footerLink: PropTypes.string,
  callback: PropTypes.func,
};

InfoCard.defaultProps = {
  children: null,
  corner: null,
  headerTxt: '',
  subHeaderTxt: '',
  footerLinkName: null,
  footerLink: '"#!"',
  callback: null,
  id: null,
};

export default InfoCard;
