import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledListerTable = styled(Table)`
  table-layout: fixed;
  
  & tbody  tr:nth-child(even) {
    background-color: var(--falcon-soft-secondary);
  }
  & thead th {
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    background: var(--falcon-gray-200 );
    position: sticky;
    z-index: 1;
    top: 0;
    padding-right: 1rem;
    overflow: hidden;
    color: var(--falcon-dark)
  }
  & thead th[data-col]:not(:last-child){
    resize: horizontal;
  }

  & tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & thead th > svg {
    position: absolute;
    right: .5rem;
    top: calc(50% - .5em)
  }

  & tbody td.boolean {
    text-align: center;
  }

  & tbody td.decimal {
    text-align: right;
  }

  & tbody tr {
    transition: all 0.1s linear 0s;
    color: var(--falcon-dark)
  }

  & tr.active, tr.selected {
    background: var(--falcon-soft-primary) !important;
  }
  
  & tbody tr.unexecuted {
    color: var(--falcon-gray-500) !important;
  }

  & tbody tr.deleted {
    text-decoration: line-through;
  }
`;

export const StyledContainer = styled.div.attrs(({ height }) => ({
  style: { height: `${height}px` },
}))`
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
`;

export const StyledTh = styled.th.attrs(({ top, orderable }) => ({
  style: {
    top: `${top}px`,
    // ...width && { width:  },
    cursor: orderable ? 'pointer' : 'unset',
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  // width: `${Width}`,
}))`
`;

export const StyledToggler = styled.td.attrs(({ level }) => ({
  style: {
    paddingLeft: `calc(${level} * 1rem)`,
  },
}))`
`;
