const frontendURL = '/srv/openbudget/';
const backendURL = '/api/dashboards/open_budget_settings/';
const name = 'Відкритий бюджет';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
