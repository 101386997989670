import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CommandPanelButton } from '../button';
import { ExitCommandButton } from '../Form/styledForm';
import Question from '../question';
import { newSave } from '../../actions/editor';
import { SaveIcon, CloseIcon } from '../../assets/icons';
import { dellComponentFromWindowsManager } from '../../actions/windowsManager';
import meta from '../../constants/meta';

const StyledCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
`;

const StyledExitCommandButton = styled(ExitCommandButton)`
:hover {filter: grayscale(1)}
`;

export function BasicMenu({
  dispatch,
  disabled,
  isChanged,
  extraContent = null,
  header = null,
  onClose,
  onSave,
  modelName,
  modelType,
  id,
}) {
  const path = useMemo(
    () => `/${meta[modelType][modelName].frontend}/${id}/`,
    [id, modelName, modelType],
  );
  return (
    <div>
      <div style={{ display: 'flex' }}>
        {header && (<h3>{header}</h3>)}
        <div style={{
          marginLeft: 'auto',
          marginRight: '5px',
          padding: '5px 8px',
        }}
        >
          {isChanged ? (
            <Question
              triggerButton={(
                <StyledExitCommandButton text="Вийти без збереження">
                  <StyledCloseIcon />
                </StyledExitCommandButton>
              )}
              header="Вихід без запису"
              // questionText="Зроблені зміни не збережені. Бажаєти вийти?"
              answers={[
                { label: 'Вийти без збереження', value: 'YES' },
                { label: 'Скасувати', value: 'NO' },
                { label: 'Зберегти зміни та вийти', value: 'YESnSAVE' },
              ]}
              onActionClick={(e, v) => {
                switch (v) {
                  case 'YES':
                    onClose();
                    break;
                  case 'NO':
                    break;
                  case 'YESnSAVE':
                    dispatch(newSave({
                      exitOnSave: true,
                      onSave,
                      onClose,
                    }));
                    break;
                  default:
                    throw new Error(`Unknow answer ${v}`);
                }
                dispatch(dellComponentFromWindowsManager(path));
              }}
            />
          ) : (
            <StyledExitCommandButton
              text="Вийти без збереження"
              onClick={() => {
                dispatch(dellComponentFromWindowsManager(path));
                onClose();
              }}
            >
              <StyledCloseIcon />
            </StyledExitCommandButton>
          )}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <CommandPanelButton
          disabled={disabled}
          onClick={() => {
            dispatch(newSave({
              exitOnSave: true,
              onClose,
              onSave,
            }));
          }}
          text="Зберегти та закрити"
          label="Зберегти та закрити"
        />
        <CommandPanelButton
          text="Зберегти"
          disabled={disabled}
          onClick={() => dispatch(newSave({ onSave, onClose }))}
        >
          <SaveIcon />
        </CommandPanelButton>
        {extraContent}
      </div>
    </div>
  );
}

BasicMenu.propTypes = {
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  canBeProceed: PropTypes.bool,
  canBeSaved: PropTypes.bool,
  extraContent: PropTypes.node,
  header: PropTypes.string,
  isChanged: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
BasicMenu.defaultProps = {
  dispatch: () => {},
  disabled: true,
  canBeProceed: false,
  canBeSaved: false,
  extraContent: null,
  header: null,
  isChanged: false,
};

export default BasicMenu;
