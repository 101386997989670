import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function Assigment({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'assignment',
          controlProps: {
            filter: {
              budget: data.history && data.history.budget ? data.history.budget.id : null,
            },
          },
        },
      ],
    }),
    [data.history],
  );
  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="refkbp7assignmentrelations_set"
    />
  );
}

Assigment.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.shape({
      budget: foreignPropType,
    }),
  }).isRequired,
};

export default Assigment;
