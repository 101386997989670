import base from './base';

const columns = {
  ...base.columns,
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  CodeBudget: { label: 'Код 3', name: 'КодБюджетаКазн', visible: true },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      dateDoc: { label: 'Дата документа, що підтверджує виникнення бюджетного зобов\'язання', name: 'ДатаДок' },
      dateR: { label: 'Дата реєстрації', name: 'ДатаР' },
      dateTerm: { label: 'Дата дії договору', name: 'ДатаДействия' },
      numberDoc: { label: 'Номер документа, що підтверджує виникнення бюджетного зобов\'язання', name: 'НомерДок' },
      KVKCode: { label: 'КВК код ', name: 'КВККод' },
      Code: { label: 'Код мережа', name: 'КодМережа' },
      KTKVCode: { label: 'КТКВ код', name: 'КТКВКод' },
      KEKVCode: { label: 'КЕКВ код', name: 'КЕКВКод' },
      FondCode: { label: 'Фонд код', name: 'ФондКод' },
      sum: { label: 'Сума бюджетного зобов\'язання', name: 'Сумма' },
      sumAdvance: { label: 'Попередня оплата', name: 'СуммаАванс' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Fond: { label: 'Фонд', name: 'Фонд' },
      KVK: { label: 'КВК', name: 'КВК' },
      FO: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
      note: { label: 'Інші суттєві умови', name: 'Другое' },
      info: { label: 'Інформація про процедуру закупівель', name: 'Инфо' },
      edrpou: { label: 'ЄДРПОУ', name: 'ЕДРПОУ' },
      nameCSU: { label: 'Найменування розпорядника', name: 'НаимРасп' },
    },
  },
};

const definition = {
  label: 'Казн. виписка юридичні зобов\'язання',
  name: 'treasuryStatementLegalObligation',
  backendName: 'ВыпискаЮрОбяз',
  columns,
  frontend: 'doc/treasuryStatementLegalObligation',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'Budget',
      'Author',
      'CodeBudget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
