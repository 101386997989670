import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

function TPFooter({
  totals,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col>
        <TableHeaderCell active={activeCol === 'kekv_item'} highlighted={highlights.includes('kekv_item')}>
        &nbsp;
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          active={activeCol === 'amount_total'}
          highlighted={highlights.includes('amount_total')}
          className="text-right"
        >
          {totals.amount_total.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          active={activeCol === 'prepayment'}
          highlighted={highlights.includes('prepayment')}
          className="text-right"
        >
          {totals.prepayment.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell active={activeCol === 'note'} highlighted={highlights.includes('note')}>
        &nbsp;
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount_total: PropTypes.number,
    prepayment: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
