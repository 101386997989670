import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  R01G3: { label: 'R01G3', name: 'R109G3' },
  R01G4: { label: 'R01G3', name: 'R109G4' },
  R01G5: { label: 'R01G3', name: 'R109G5' },
  R01G6: { label: 'R01G3', name: 'R109G6' },
  R01G7: { label: 'R01G3', name: 'R109G7' },
  R02G3: { label: 'R01G3', name: 'R110G3' },
  R02G4: { label: 'R01G3', name: 'R110G4' },
  R02G5: { label: 'R01G3', name: 'R110G5' },
  R02G6: { label: 'R01G3', name: 'R110G6' },
  R02G7: { label: 'R01G3', name: 'R110G7' },
  R03G3: { label: 'R01G3', name: 'R111G3' },
  R03G4: { label: 'R01G3', name: 'R111G4' },
  R03G5: { label: 'R01G3', name: 'R111G5' },
  R03G6: { label: 'R01G3', name: 'R111G6' },
  R03G7: { label: 'R01G3', name: 'R111G7' },
  R04G3: { label: 'R01G3', name: 'R102G3' },
  R04G4: { label: 'R01G3', name: 'R102G4' },
  R04G5: { label: 'R01G3', name: 'R102G5' },
  R04G6: { label: 'R01G3', name: 'R102G6' },
  R04G7: { label: 'R01G3', name: 'R102G7' },
  R05G3: { label: 'R01G3', name: 'R103G3' },
  R05G4: { label: 'R01G3', name: 'R103G4' },
  R05G5: { label: 'R01G3', name: 'R103G5' },
  R05G6: { label: 'R01G3', name: 'R103G6' },
  R05G7: { label: 'R01G3', name: 'R103G7' },
  R06G3: { label: 'R01G3', name: 'R104G3' },
  R06G4: { label: 'R01G3', name: 'R104G4' },
  R06G5: { label: 'R01G3', name: 'R104G5' },
  R06G6: { label: 'R01G3', name: 'R104G6' },
  R06G7: { label: 'R01G3', name: 'R104G7' },
  R07G3: { label: 'R01G3', name: 'R105G3' },
  R07G4: { label: 'R01G3', name: 'R105G4' },
  R07G5: { label: 'R01G3', name: 'R105G5' },
  R07G6: { label: 'R01G3', name: 'R105G6' },
  R07G7: { label: 'R01G3', name: 'R105G7' },
  R08G3: { label: 'R01G3', name: 'R106G3' },
  R08G4: { label: 'R01G3', name: 'R106G4' },
  R08G5: { label: 'R01G3', name: 'R106G5' },
  R08G6: { label: 'R01G3', name: 'R106G6' },
  R08G7: { label: 'R01G3', name: 'R106G7' },
  R09G3: { label: 'R01G3', name: 'R107G3' },
  R09G4: { label: 'R01G3', name: 'R107G4' },
  R09G5: { label: 'R01G3', name: 'R107G5' },
  R09G6: { label: 'R01G3', name: 'R107G6' },
  R09G7: { label: 'R01G3', name: 'R107G7' },
  R10G3: { label: 'R01G3', name: 'R108G3' },
  R10G4: { label: 'R01G3', name: 'R108G4' },
  R10G5: { label: 'R01G3', name: 'R108G5' },
  R10G6: { label: 'R01G3', name: 'R108G6' },
  R10G7: { label: 'R01G3', name: 'R108G7' },
  R11G3: { label: 'R01G3', name: 'R109G3' },
  R11G4: { label: 'R01G3', name: 'R109G4' },
  R11G5: { label: 'R01G3', name: 'R109G5' },
  R11G6: { label: 'R01G3', name: 'R109G6' },
  R11G7: { label: 'R01G3', name: 'R109G7' },
  R12G3: { label: 'R01G3', name: 'R110G3' },
  R12G4: { label: 'R01G3', name: 'R110G4' },
  R12G5: { label: 'R01G3', name: 'R110G5' },
  R12G6: { label: 'R01G3', name: 'R110G6' },
  R12G7: { label: 'R01G3', name: 'R110G7' },
  R13G3: { label: 'R01G3', name: 'R111G3' },
  R13G4: { label: 'R01G3', name: 'R111G4' },
  R13G5: { label: 'R01G3', name: 'R111G5' },
  R13G6: { label: 'R01G3', name: 'R111G6' },
  R13G7: { label: 'R01G3', name: 'R111G7' },
  R14G3: { label: 'R01G3', name: 'R112G3' },
  R14G4: { label: 'R01G3', name: 'R112G4' },
  R14G5: { label: 'R01G3', name: 'R112G5' },
  R14G6: { label: 'R01G3', name: 'R112G6' },
  R14G7: { label: 'R01G3', name: 'R112G7' },
  R15G3: { label: 'R01G3', name: 'R113G3' },
  R15G4: { label: 'R01G3', name: 'R113G4' },
  R15G5: { label: 'R01G3', name: 'R113G5' },
  R15G6: { label: 'R01G3', name: 'R113G6' },
  R15G7: { label: 'R01G3', name: 'R113G7' },
  R16G3: { label: 'R01G3', name: 'R114G3' },
  R16G4: { label: 'R01G3', name: 'R114G4' },
  R16G5: { label: 'R01G3', name: 'R114G5' },
  R16G6: { label: 'R01G3', name: 'R114G6' },
  R16G7: { label: 'R01G3', name: 'R114G7' },
  R17G3: { label: 'R01G3', name: 'R115G3' },
  R17G4: { label: 'R01G3', name: 'R115G4' },
  R17G5: { label: 'R01G3', name: 'R115G5' },
  R17G6: { label: 'R01G3', name: 'R115G6' },
  R17G7: { label: 'R01G3', name: 'R115G7' },
  R18G3: { label: 'R01G3', name: 'R116G3' },
  R18G4: { label: 'R01G3', name: 'R116G4' },
  R18G5: { label: 'R01G3', name: 'R116G5' },
  R18G6: { label: 'R01G3', name: 'R116G6' },
  R18G7: { label: 'R01G3', name: 'R116G7' },
  R19G3: { label: 'R01G3', name: 'R117G3' },
  R19G4: { label: 'R01G3', name: 'R117G4' },
  R19G5: { label: 'R01G3', name: 'R117G5' },
  R19G6: { label: 'R01G3', name: 'R117G6' },
  R19G7: { label: 'R01G3', name: 'R117G7' },
  R20G3: { label: 'R01G3', name: 'R118G3' },
  R20G4: { label: 'R01G3', name: 'R118G4' },
  R20G5: { label: 'R01G3', name: 'R118G5' },
  R20G6: { label: 'R01G3', name: 'R118G6' },
  R20G7: { label: 'R01G3', name: 'R118G7' },
  R41G3: { label: 'R01G3', name: 'R119G3' },
  R41G4: { label: 'R01G3', name: 'R119G4' },
  R41G5: { label: 'R01G3', name: 'R119G5' },
  R41G6: { label: 'R01G3', name: 'R119G6' },
  R41G7: { label: 'R01G3', name: 'R119G7' },
  R21G3: { label: 'R01G3', name: 'R120G3' },
  R21G4: { label: 'R01G3', name: 'R120G4' },
  R21G5: { label: 'R01G3', name: 'R120G5' },
  R21G6: { label: 'R01G3', name: 'R120G6' },
  R21G7: { label: 'R01G3', name: 'R120G7' },
  R43G3: { label: 'R01G3', name: 'R121G3' },
  R43G4: { label: 'R01G3', name: 'R121G4' },
  R43G5: { label: 'R01G3', name: 'R121G5' },
  R43G6: { label: 'R01G3', name: 'R121G6' },
  R43G7: { label: 'R01G3', name: 'R121G7' },
  R44G3: { label: 'R01G3', name: 'R122G3' },
  R44G4: { label: 'R01G3', name: 'R122G4' },
  R44G5: { label: 'R01G3', name: 'R122G5' },
  R44G6: { label: 'R01G3', name: 'R122G6' },
  R44G7: { label: 'R01G3', name: 'R122G7' },
  R45G3: { label: 'R01G3', name: 'R123G3' },
  R45G4: { label: 'R01G3', name: 'R123G4' },
  R45G5: { label: 'R01G3', name: 'R123G5' },
  R45G6: { label: 'R01G3', name: 'R123G6' },
  R45G7: { label: 'R01G3', name: 'R123G7' },
  R46G3: { label: 'R01G3', name: 'R124G3' },
  R46G4: { label: 'R01G3', name: 'R124G4' },
  R46G5: { label: 'R01G3', name: 'R124G5' },
  R46G6: { label: 'R01G3', name: 'R124G6' },
  R46G7: { label: 'R01G3', name: 'R124G7' },
  R47G3: { label: 'R01G3', name: 'R125G3' },
  R47G4: { label: 'R01G3', name: 'R125G4' },
  R47G5: { label: 'R01G3', name: 'R125G5' },
  R47G6: { label: 'R01G3', name: 'R125G6' },
  R47G7: { label: 'R01G3', name: 'R125G7' },
  R48G3: { label: 'R01G3', name: 'R126G3' },
  R48G4: { label: 'R01G3', name: 'R126G4' },
  R48G5: { label: 'R01G3', name: 'R126G5' },
  R48G6: { label: 'R01G3', name: 'R126G6' },
  R48G7: { label: 'R01G3', name: 'R126G7' },
  R49G3: { label: 'R01G3', name: 'R127G3' },
  R49G4: { label: 'R01G3', name: 'R127G4' },
  R49G5: { label: 'R01G3', name: 'R127G5' },
  R49G6: { label: 'R01G3', name: 'R127G6' },
  R49G7: { label: 'R01G3', name: 'R127G7' },
  R50G3: { label: 'R01G3', name: 'R128G3' },
  R50G4: { label: 'R01G3', name: 'R128G4' },
  R50G5: { label: 'R01G3', name: 'R128G5' },
  R50G6: { label: 'R01G3', name: 'R128G6' },
  R50G7: { label: 'R01G3', name: 'R128G7' },
  R51G3: { label: 'R01G3', name: 'R129G3' },
  R51G4: { label: 'R01G3', name: 'R129G4' },
  R51G5: { label: 'R01G3', name: 'R129G5' },
  R51G6: { label: 'R01G3', name: 'R129G6' },
  R51G7: { label: 'R01G3', name: 'R129G7' },
  R52G3: { label: 'R01G3', name: 'R130G3' },
  R52G4: { label: 'R01G3', name: 'R130G4' },
  R52G5: { label: 'R01G3', name: 'R130G5' },
  R52G6: { label: 'R01G3', name: 'R130G6' },
  R52G7: { label: 'R01G3', name: 'R130G7' },
  R53G3: { label: 'R01G3', name: 'R131G3' },
  R53G4: { label: 'R01G3', name: 'R131G4' },
  R53G5: { label: 'R01G3', name: 'R131G5' },
  R53G6: { label: 'R01G3', name: 'R131G6' },
  R53G7: { label: 'R01G3', name: 'R131G7' },
  R54G3: { label: 'R01G3', name: 'R132G3' },
  R54G4: { label: 'R01G3', name: 'R132G4' },
  R54G5: { label: 'R01G3', name: 'R132G5' },
  R54G6: { label: 'R01G3', name: 'R132G6' },
  R54G7: { label: 'R01G3', name: 'R132G7' },
  R55G3: { label: 'R01G3', name: 'R133G3' },
  R55G4: { label: 'R01G3', name: 'R133G4' },
  R55G5: { label: 'R01G3', name: 'R133G5' },
  R55G6: { label: 'R01G3', name: 'R133G6' },
  R55G7: { label: 'R01G3', name: 'R133G7' },
  R56G3: { label: 'R01G3', name: 'R134G3' },
  R56G4: { label: 'R01G3', name: 'R134G4' },
  R56G5: { label: 'R01G3', name: 'R134G5' },
  R56G6: { label: 'R01G3', name: 'R134G6' },
  R56G7: { label: 'R01G3', name: 'R134G7' },
  R57G3: { label: 'R01G3', name: 'R135G3' },
  R57G4: { label: 'R01G3', name: 'R135G4' },
  R57G5: { label: 'R01G3', name: 'R135G5' },
  R57G6: { label: 'R01G3', name: 'R135G6' },
  R57G7: { label: 'R01G3', name: 'R135G7' },
  R136G3: { label: 'R01G3', name: 'R136G3' },
  R136G4: { label: 'R01G3', name: 'R136G4' },
  R136G5: { label: 'R01G3', name: 'R136G5' },
  R136G6: { label: 'R01G3', name: 'R136G6' },
  R136G7: { label: 'R01G3', name: 'R136G7' },
  R137G3: { label: 'R01G3', name: 'R137G3' },
  R137G4: { label: 'R01G3', name: 'R137G4' },
  R137G5: { label: 'R01G3', name: 'R137G5' },
  R137G6: { label: 'R01G3', name: 'R137G6' },
  R137G7: { label: 'R01G3', name: 'R137G7' },
  R138G3: { label: 'R01G3', name: 'R138G3' },
  R138G4: { label: 'R01G3', name: 'R138G4' },
  R138G5: { label: 'R01G3', name: 'R138G5' },
  R138G6: { label: 'R01G3', name: 'R138G6' },
  R138G7: { label: 'R01G3', name: 'R138G7' },
  R139G3: { label: 'R01G3', name: 'R139G3' },
  R139G4: { label: 'R01G3', name: 'R139G4' },
  R139G5: { label: 'R01G3', name: 'R139G5' },
  R139G6: { label: 'R01G3', name: 'R139G6' },
  R139G7: { label: 'R01G3', name: 'R139G7' },
  R140G3: { label: 'R01G3', name: 'R140G3' },
  R140G4: { label: 'R01G3', name: 'R140G4' },
  R140G5: { label: 'R01G3', name: 'R140G5' },
  R140G6: { label: 'R01G3', name: 'R140G6' },
  R140G7: { label: 'R01G3', name: 'R140G7' },
  R141G3: { label: 'R01G3', name: 'R141G3' },
  R114G4: { label: 'R01G3', name: 'R141G4' },
  R141G5: { label: 'R01G3', name: 'R141G5' },
  R141G6: { label: 'R01G3', name: 'R141G6' },
  R141G7: { label: 'R01G3', name: 'R141G7' },
  R142G3: { label: 'R01G3', name: 'R142G3' },
  R142G4: { label: 'R01G3', name: 'R142G4' },
  R142G5: { label: 'R01G3', name: 'R142G5' },
  R142G6: { label: 'R01G3', name: 'R142G6' },
  R142G7: { label: 'R01G3', name: 'R142G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd7Printing',
  backendName: 'ПечатьПрогнозДодаток7',
  label: 'Граничні показники видатків бюджету за ТПКВК (Додаток 7)',
  frontend: 'dp/forecastAdd7Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
