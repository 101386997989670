import styled from 'styled-components';

const RowTablePart = styled.div.attrs(({ active }) => ({
  style: active ? {
    border: '1px solid #AAC6E6',
    background: 'linear-gradient(0deg, #D9EEDE, #D9EEDE), #008F21',
  } : {
    border: '1px solid #DDE2E9',
  },
}))`
      font-size: 0.8em;
     `;

export default RowTablePart;
