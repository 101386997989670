import md from '../../../../../meta/doc/revenuePlanChanges';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { DocTableListerFactory } from '../../../../newLister/tableComponent';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(DocTableListerFactory({
}));

export default StdLister;
