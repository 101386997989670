import React, { forwardRef, useContext, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import AssigmPrintModal from './listerPrintModal';
import EstimatePrintModal from './estimatePrintModalLister';
import MixIndicatorsSFPrintModal from './mixIndicatorsSFPrintModalLister';
import ListerContext from '../../../newLister/context';

import YouTubeButtom from '../../../newLister/tableComponent/commandpanel/youtube/youtubeButton';
import YoutubeVideo from '../../../newLister/tableComponent/commandpanel/youtube/youtubeVideo';
import meta from '../../../../constants/meta/documents/assignmentPlanning';

const videoId = meta.columns.refYoutube && meta.columns.refYoutube.value;

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <DocListerCommandPanel
        ref={ref}
      >
        <ButtonGroup>
          <Dropdown>
            <Dropdown.Toggle>
              <FontAwesomeIcon icon={faPrint} />
            </Dropdown.Toggle>
            <Dropdown.Menu>

              <AssigmPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />

            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
        <ButtonGroup>
          <Dropdown>
            <Dropdown.Toggle>
              Показати ще...
            </Dropdown.Toggle>
            <Dropdown.Menu>

              {/* <PrintModalPlanOfUse selectedRows={selectedRows} /> */}
              <EstimatePrintModal />
              <MixIndicatorsSFPrintModal selectedRows={selectedRows} />

            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
        <YouTubeButtom
          onClick={() => setShowVideo(!showVideo)}
          videoId={videoId}
        />
      </DocListerCommandPanel>

      <YoutubeVideo
        showVideo={showVideo}
        videoId={videoId}
      />

    </>
  );
});
export default CommandPanel;
