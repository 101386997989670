import React, {
  forwardRef, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';

// const DATE_INPUT_MASK = /[0-3]\d\.[0-1]\d\.\d{4}/;
const DATE_INPUT_MASK = '99.99.9999';

/**
 *
 * @param v {string}
 */
function getYear(v) {
  const d = new Date();
  if (v.trim().length === 0) return String(d.getFullYear());
  if (v.trim().length === 2) {
    if (Number.parseInt(v, 10) >= 50) return `19${v.trim()}`;
    return `20${v.trim()}`;
  }
  return v;
}
function getMonth(v) {
  const d = new Date();
  if (v.trim().length === 0) return String(d.getMonth() + 1).padStart(2, '0');
  return v.padStart(2, '0');
}

function getDay(v) {
  const d = new Date();
  if (v.trim().length === 0) return String(d.getDate() + 1).padStart(2, '0');
  return v.padStart(2, '0');
}

const DatePickerInput = forwardRef(
  ({
    prepend, value, onClick, vValue, disabled, id, placeholder,
    readOnly, required, tabIndex, title, errors, errorAsTooltip,
    onBlur, onChange, onFocus, onKeyDown,
    useMask,
  }, ref) => {
    const dValue = useMemo(
      () => vValue || value,
      [vValue, value],
    );
    const errored = errors && errors.length;
    const onB = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      const v = `${getDay(value.substring(0, 2))}.${getMonth(value.substring(3, 5))}.${getYear(value.substring(6, 10))}`;
      if (v !== value) {
        e.target.value = v;
        onChange(e);
      }
      onBlur(e);
    }, [onBlur, onChange, value]);

    return (
      <>
        {prepend && (
        <InputGroup.Text>
          {prepend}
        </InputGroup.Text>
        )}
        {useMask ? (
          <InputMask
            mask={DATE_INPUT_MASK}
            maskChar=" "
            value={dValue}
            readOnly={readOnly || !!vValue}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onB}
            onKeyDown={onKeyDown}
          >
            {(inputProps) => (
              <FormControl
                id={id}
                ref={ref}
                isInvalid={errored}
                isValid={false}
                onClick={onClick}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                tabIndex={tabIndex}
                title={title}
                {...inputProps}
              />
            )}
          </InputMask>
        ) : (
          <FormControl
            id={id}
            ref={ref}
            isInvalid={errored}
            isValid={false}
            onClick={onClick}
            placeholder={placeholder}
            required={required}
            tabIndex={tabIndex}
            title={title}
            value={dValue}
            readOnly={readOnly || !!vValue}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onB}
            onKeyDown={onKeyDown}
          />
        )}
        {errors && (
        <FormControl.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </FormControl.Feedback>
        )}
      </>

    );
  },
);

DatePickerInput.propTypes = {
  prepend: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  vValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  useMask: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  prepend: '',
  value: null,
  vValue: null,
  onClick: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  id: null,
  placeholder: null,
  disabled: null,
  readOnly: null,
  required: null,
  tabIndex: null,
  title: null,
  errors: null,
  errorAsTooltip: false,
  useMask: false,
};

export default DatePickerInput;
