import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import cat from '../../catalogs';
import doc from '../../documents';
import rep from '../../reports';
import meta from '../../../meta';
import { WinManagerContext } from '../../../providers';

const editors = {
  cat,
  doc,
  rep,
};

const Editors = Object.keys(editors).reduce((RT, mType) => ({
  ...RT,
  [mType]: Object.keys(editors[mType]).reduce((RN, mName) => {
    if (!(mName in meta[mType])) throw new Error(`(${mName}) відсутній у метаданих ${mType}`);

    function WindowedEditor({
      id, url,
    }) {
      const navigate = useNavigate();
      const {
        dellComponentFromWindowsManager,
      } = useContext(WinManagerContext);
      const onSave = useCallback(
        (item) => {
          if (String(item.id) !== id) {
            dellComponentFromWindowsManager(url);
            navigate(`${meta[mType][mName].frontendURL}${item.id}/`);
          }
        },
        [dellComponentFromWindowsManager, navigate, id, url],
      );
      const { search } = useLocation();
      const editorParams = useMemo(
        () => {
          if (!search.startsWith('?')) return {};
          const pss = search.substring(1).split('&');
          return pss.reduce((R, r) => {
            const [k, v] = r.split('=');
            return { ...R, [k]: v };
          }, {});
        },
        [search],
      );

      return React.createElement(
        editors[mType][mName].Editor,
        {
          id,
          onClose: () => dellComponentFromWindowsManager(url),
          onSave,
          ...editorParams,
        },
      );
    }
    WindowedEditor.propTypes = {
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    };
    return {
      ...RN,
      [mName]: WindowedEditor,
    };
  }, {}),
}), {});

export default Editors;
