import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, InputGroup,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { PrependKEKV } from '../../../../styles/klassifiers';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authority,
}) {
  const kekvItemProps = tableHooks.useItemInputProps('kekv_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kekvItemFilter = useMemo(() => ({
    owner: authority ? authority.id : 0,
  }), [authority]);
  const amountProps = tableHooks.useNumberInputProps('amount_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const noteProps = tableHooks.useStringInputProps('note', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  return (
    <>
      <Col>
        <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
          <TableControls.ItemPicker
            {...kekvItemProps}
            filter={kekvItemFilter}
            prepend={(
              <PrependKEKV>
                <InputGroup.Text className="w-100">{row.kekv && row.kekv.repr}</InputGroup.Text>
              </PrependKEKV>
                      )}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="amount_total" highlighted={highlights.includes('amount_total')}>
          <TableControls.NumberInput
            {...amountProps}
          />
        </TabelRowCell>
      </Col>
      <Col>
        <TabelRowCell column="note" highlighted={highlights.includes('note')}>
          <TableControls.TextInput
            rows={1}
            {...noteProps}
          />
        </TabelRowCell>
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
