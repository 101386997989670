import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';

import md from '../../../../constants/meta/documents/limitReference';
import { CSUTP, GeneralTP, KPKVMBTP } from './tabs';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

function LimitReferenceTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <Row>
          <Col>
            <GeneralTP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
      </Tab>
      <Tab title="Імпорт з планів" eventKey="KPKVMB">
        <CommandPanelColor
          label="Заповнити даними з планів за обраними розпорядниками та КПКВМБ"
          onClick={() => actions.onSR('FILL')}
        />
        <Row>
          <Col>
            <CSUTP data={data} actions={actions} readOnly={readOnly} />
          </Col>
          <Col>
            <KPKVMBTP data={data} actions={actions} readOnly={readOnly} />
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );
}

LimitReferenceTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default LimitReferenceTabs;
