import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { StyledField } from '../../../styled';

function ColorField({ value, onChange }) {
  const displayColor = useMemo(
    () => ['Red', 'Green', 'Blue']
      .reduce(
        (R, c) => `${R}${value.get(c, 0).toString(16).padStart(2, '0')}`,
        '#',
      ),
    [value],
  );
  const isDark = (1 - (
    0.299 * value.get('Red', 0)
    + 0.587 * value.get('Green', 0)
    + 0.114 * value.get('Blue', 0)) / 255 >= 0.5);

  const onColorChange = useCallback(
    (e) => {
      // eslint-disable-next-line no-unused-vars
      const [_, r, g, b] = e.target.value.match(/#([0-9,a-f]{2})([0-9,a-f]{2})([0-9,a-f]{2})/);
      onChange(e, value
        .set('Red', parseInt(r, 16))
        .set('Green', parseInt(g, 16))
        .set('Blue', parseInt(b, 16)));
    },
    [onChange, value],
  );
  return (
    <StyledField>
      <div style={{ color: displayColor, backgroundColor: isDark ? 'white' : 'black' }}>Колір</div>
      <input type="color" onChange={onColorChange} value={displayColor} />
    </StyledField>
  );
}

ColorField.propTypes = {
  value: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
};

ColorField.defaultProps = {
  value: new Map(),
};

export default ColorField;
