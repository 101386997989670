import React, { useEffect, useMemo } from 'react';
import {
  ButtonGroup, Card, Dropdown, DropdownButton,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/freeRem';
import FreeRem from './editor';
import APMenu from './menu';
import FreeRemTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';
import DefaultCPButton from '../../../../components/bootstrap_components/controls/defaultButton';
import { soSelector } from '../../_common/selectors';
import { emptyUid } from '../../../../constants/meta/common';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */

function FreeRemEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  const sessionOptions = useSelector(soSelector);
  const navigate = useNavigate();
  const add3Id = useMemo(
    () => (data[md.columns.add3.name] ? data[md.columns.add3.name].id : emptyUid),
    [data],
  );

  const add4Id = useMemo(
    () => (data[md.columns.add4.name] ? data[md.columns.add4.name].id : emptyUid),
    [data],
  );

  useEffect(
    () => {
      if (add3Id && add3Id !== emptyUid) {
        navigate({
          pathname: `/doc/freeRem3Add/${add3Id}/`,
        });
      }
    },
    [navigate, add3Id],
  );

  useEffect(
    () => {
      if (add4Id && add4Id !== emptyUid) {
        navigate({
          pathname: `/doc/freeRem4Add/${add4Id}/`,
        });
      }
    },
    [navigate, add4Id],
  );

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      >
        <ButtonGroup>
          {sessionOptions.get('isRegionUser', false) && sessionOptions.get('approveByRegion', false) && (
            <DefaultCPButton
              variant="success"
              label={data[md.columns.apprByRegion.name] ? 'Зняти затвердження (область)' : 'Затвердити (область)'}
              onClick={() => {
                actions.onSR('APPROVE_BY_REGION');
              }}
            />
          )}
        </ButtonGroup>
        <ButtonGroup>
          <DropdownButton variant="outline-primary" id="dropdown-item-button" title="Створити на підставі..." style={{ marginRight: '10px' }}>
            <Dropdown.Item
              as="button"
              onClick={() => {
                actions.onSR('CREATE_ADD_3');
              }}
            >
              Додаток 3
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => {
                actions.onSR('CREATE_ADD_4');
              }}
            >
              Додаток 4
            </Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
      </APMenu>
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <FreeRem
            data={data}
            actions={actions}
            permissions={permissions}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <FreeRemTabs
            data={data}
            actions={actions}
            permissions={permissions}
          />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

FreeRemEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

FreeRemEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { FreeRemEditor as StdEditor };

export default getRouterEditor(md)(FreeRemEditor);
