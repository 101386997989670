import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК', visible: false },
  csu: { label: 'Розпорядник бюджетних коштів', name: 'Распорядитель' },
  elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
  client: { label: 'Постачальник', name: 'Контрагент', visible: true },
  elementFond: { label: 'Фонд', name: 'ЭлементФонд', visible: true },
  isApproved: {
    label: 'Затверджений',
    name: 'ДокументУтвержден',
    visible: true,
    type: 'bool',
  },
  received: { label: 'Квитанція', name: 'Квитанция', visible: false },
  onState: { label: 'Станом на', name: 'СтаномНа', visible: false },
  autoCalculate: { label: 'Автоматичний розрахунок', name: 'АвтоРасчет' },
  checkMessage: { label: 'Повідомлення про перевірку', name: 'СообщТекст' },
};

/**
 * @const
 */
const tables = {
  indicators: {
    name: 'Показатели',
    label: 'Показники',
    columns: {
      receivable: { label: 'Дебіторська заборговність на початок року', name: 'ДебиторскаяЗадолженностьНГ' },
      payable: { label: 'Кредиторська заборговність на початок року ', name: 'КредиторскаяЗадолженностьНГ' },
      limits: { label: 'Ліміти споживання у фізичних обсягах', name: 'УтвержденоРосписьюКоличество' },
      approved: { label: 'Затверджено розписом', name: 'УтвержденоРосписьюСумма' },
      yearPaid: { label: 'Сплачено за поточний рік з початку року', name: 'ОплаченоТекущийГодНИ' },
      monthPaid: { label: 'Сплачено за поточний рік за звітній місяць', name: 'ОплаченоТекущийГодМес' },
      pastYearPaid: { label: 'Сплачено за минулий рік з початку року', name: 'ОплаченоПрогшлыйГодНИ' },
      pastYearMonthPaid: { label: 'Сплачено за минулий рік за звітній місяць', name: 'ОплаченоПрошлыйГодМес' },
      writtenOffReceivable: { label: 'Сума списаної заборгованості (дебіторської)', name: 'СуммаСписаннойЗадолженностиДеб' },
      writtenOffPayable: { label: 'Сума списаної заборгованості (кредиторської)', name: 'СуммаСписаннойЗадолженностиКред' },
      pastReceivable: { label: 'Заборгованість минулих років, виявлена в наслідок перерахунку (дебіторська)', name: 'ЗадолженностьПредПериодовПерерахДеб' },
      pastPayable: { label: 'Заборгованість минулих років, виявлена в наслідок перерахунку (кредиторська)', name: 'ЗадолженностьПредПериодовПерерахКред' },
      receivableOnDate: { label: 'Дебіторська заборговність на звітну дату', name: 'ДебиторскаяЗадолженностьКГ' },
      pastYearPayable: { label: 'Кредиторська заборговність на звітну дату (минулого року)', name: 'КредиторскаяЗадолженностьКГ_ПГ' },
      payableOnDate: { label: 'Кредиторська заборговність на звітну дату (поточного року)', name: 'КредиторскаяЗадолженностьКГ_ТГ' },
      yearConsumed: { label: 'Фактично спожито на звітну дату (з нарост підсумком)', name: 'ФактическиИспользованоНГ' },
      monthConsumed: { label: 'Фактично спожито на звітну дату (за поточний місяць)', name: 'ФактическиИспользованоМес' },
      yearNaturalConsumed: { label: 'Фактично спожито на звітну дату (з нарост підсумком) в натуральних показниках', name: 'ФактическиИспользованоНГ_Количество' },
      monthNaturalConsumed: { label: 'Фактично спожито на звітну дату (за поточний місяць) в натуральих показниках', name: 'ФактическиИспользованоМес_Количество' },
      pastYearPeriodConsumed: { label: 'Фактичне споживання у фізичних обсягах за відповідний період минулого року', name: 'ФактическоеИспользованиеКолПрошлыйПериод' },
      pastYearConsumed: { label: 'Фактичне споживання у фізичних обсягах за минулий рік', name: 'ФактическоеИспользованиеКолПрошлыйГод' },
      department: { label: 'Підрозділ', name: 'Подразделение' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Введення показників за енергоносіями та комунальними послугами',
  name: 'energyIndicators',
  backendName: 'ВводПоказателейПоЭнергоносителям',
  columns,
  frontend: 'doc/energyIndicators',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
