import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/expensesOnHealth';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { selectionMode } from '../../../../../../constants/meta/common';
import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'TP2282';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, FI, onSR, expensesOnHealthKyiv,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  const onSumT1RXXXXGChange = useCallback(
    (e, value, colName, totColName) => {
      const tot = ((row[totColName] || 0) - (row[colName] || 0)) + value;
      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
      });
    },
    [onRowChange, row],
  );

  const onCSUChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.T1RXXXXG1S.name]: v });
      await onSR('CHANGE_CSU_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onKBP7Change = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.T1RXXXXG51S.name]: v });
      await onSR('CHANGE_KBP7_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG51S" highlighted={highlights.includes('T1RXXXXG51S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG51S.name]}
                modelType="cat"
                modelName="kbp7Classifier"
                choiceSettings={selectionMode.items}
                onChange={onKBP7Change}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG1S" highlighted={highlights.includes('T1RXXXXG1S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG1S.name]}
                modelType="cat"
                modelName="csu"
                onChange={onCSUChange}
                readOnly={readOnly || expensesOnHealthKyiv}
                filter={CSUFIlter}
                noHierarchy
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG3" highlighted={highlights.includes('T1RXXXXG3')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG3.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG3.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG4" highlighted={highlights.includes('T1RXXXXG4')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG4.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG4.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG5" highlighted={highlights.includes('T1RXXXXG5')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG5.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG5.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG6" highlighted={highlights.includes('T1RXXXXG6')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG6.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG6.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG7" highlighted={highlights.includes('T1RXXXXG7')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG7.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG7.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG8" highlighted={highlights.includes('T1RXXXXG8')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG8.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG8.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG9" highlighted={highlights.includes('T1RXXXXG9')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG9.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG9.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG10" highlighted={highlights.includes('T1RXXXXG10')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG10.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG10.name,
                  tableMD.columns.T1RXXXXG3.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG11" highlighted={highlights.includes('T1RXXXXG11')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG11.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG11.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG12" highlighted={highlights.includes('T1RXXXXG12')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG12.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG12.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG13" highlighted={highlights.includes('T1RXXXXG13')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG13.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG13.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG14" highlighted={highlights.includes('T1RXXXXG14')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG14.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG14.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG15" highlighted={highlights.includes('T1RXXXXG15')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG15.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG15.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG16" highlighted={highlights.includes('T1RXXXXG16')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG16.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG16.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG17" highlighted={highlights.includes('T1RXXXXG17')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG17.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG17.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG18" highlighted={highlights.includes('T1RXXXXG18')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG18.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG18.name,
                  tableMD.columns.T1RXXXXG11.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG19" highlighted={highlights.includes('T1RXXXXG19')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG19.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG19.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG20" highlighted={highlights.includes('T1RXXXXG20')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG20.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG20.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG21" highlighted={highlights.includes('T1RXXXXG21')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG21.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG21.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG22" highlighted={highlights.includes('T1RXXXXG22')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG22.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG22.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG23" highlighted={highlights.includes('T1RXXXXG23')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG23.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG23.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG24" highlighted={highlights.includes('T1RXXXXG24')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG24.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG24.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG25" highlighted={highlights.includes('T1RXXXXG25')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG25.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG25.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG26" highlighted={highlights.includes('T1RXXXXG26')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG26.name]}
                precision={2}
                onChange={(e, v) => onSumT1RXXXXGChange(
                  e,
                  v,
                  tableMD.columns.T1RXXXXG26.name,
                  tableMD.columns.T1RXXXXG19.name,
                )}
                readOnly={readOnly || expensesOnHealthKyiv}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T1RXXXXG1S.name]: referencePropType,
    [tableMD.columns.T1RXXXXG26.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG25.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG24.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG23.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG22.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG21.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG20.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG19.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG18.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG17.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG16.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG15.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG14.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG13.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG12.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG11.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG10.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG9.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG8.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG7.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG6.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG5.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG4.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG3.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType,
  onSR: PropTypes.func.isRequired,
  expensesOnHealthKyiv: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  FI: null,
  expensesOnHealthKyiv: false,
};

export default memo(TPRow);
