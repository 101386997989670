import React, {
  useCallback, useEffect, useState, useMemo, useRef,
} from 'react';
import {
  Button, Navbar, DropdownButton, Dropdown, Alert, Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faFileDownload, faCheckDouble, faHistory, faRedo, faDownload,
} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../components/styledWrappedLoader';
import Comment from './comment';
import Tables from './Table';
import ResultVerifyPage from './resultVerifyPage';
import forms from './forms';
import { logikaLogout } from '../../../actions/auth';
import LogicaPing from '../../Logica/Ping';
import api from '../../../api/req';
import ShowPDFModal from './showPDFModal';
import { UnauthorizedError } from '../../../api/errors';

const StyledButton = styled(Button)`
  margin-right: 5px;
  display: flex;
  align-items: center`;

const StyledDropdownButton = styled(DropdownButton)`
  display: flex;
  margin-right: 5px
`;

const StyledDiv = styled.div`
  position: sticky;
  top: ${(props) => props.top};
  z-index: ${(props) => props.zIndex};
  margin-bottom: 10px;
  background-color: white;
  display: flex`;

const Text = styled.p`
  text-align: center;
  margin: 0;
  margin-left: 5px`;

const FilterTypes = [
  'Тільки документи установи',
  'Документи установи та підпорядкованих ГРК',
  'Документи бюджетів, що входять до зведенного бюджету (області, району, міста)',
  'Документи ГРК територіальних громад (для ГРК обласного рівня)',
];

function ListOfCatalogs() {
  const dispatch = useDispatch();
  const [activeForm, setActiveForm] = useState(null);
  const [year, setYear] = useState(() => new Date().getFullYear());
  const [list, setList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [selectedBs, setSelectedBs] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [commentPage, setCommentPage] = useState(false);
  const [filter, setFilter] = useState({});
  const [order, setOrder] = useState({});
  const [resultVerifyPage, setResultVerifyPage] = useState(false);
  // Відкриття модалки
  const [resultVerify, setResultVerify] = useState(null);
  // Весь список спроб верифікації по 1-му документу
  const [resultVerifyList, setResultVerifyList] = useState(null);
  // Список файлів наданих користувачу
  const [resJson, setResJson] = useState(null);
  const [err, setErr] = useState(null);
  const [logikaErrors, setLogikaErrors] = useState([]);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  // Останній результат верифікації кожного з файлів
  const [verifyResults, setVerifyResults] = useState(null);
  const [filterType, setFilterType] = useState(0);
  const token = localStorage.getItem('ticket');

  const store = useSelector((state) => state);
  const sessionOptions = store.getIn(['auth', 'sessionOptions'], new Map());
  const role = sessionOptions.get('fo_role', '');
  const admin = sessionOptions.get('is_admin', '');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadFile, setLoadFile] = useState(null);

  const pdflink = useRef(null);
  const setPdfLink = useCallback(
    (url) => {
      if (pdflink.current) window.URL.revokeObjectURL(pdflink.current);
      pdflink.current = url;
    },
    [],
  );

  useEffect(
    () => () => {
      if (pdflink.current) window.URL.revokeObjectURL(pdflink.current);
    },
    [],
  );

  useEffect(() => {
    if (token) {
      setTicket(token);
    } else {
      setTicket(null);
    }
  }, [token]);

  const getValidationResults = useCallback(async (array, address) => {
    const arr = array.map((i) => i.id.toString());
    const link = `https://logica.minfin.gov.ua/ws/api${address}`;
    setIsLoading(true);
    const res = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: arr }),
    });
    const results = await res.json();

    const loadResults = async (id, result) => {
      const lastVersion = Object.keys(result)
        .map((key) => ({
          key,
          v: new Date(key),
        }))
        .sort((a, b) => a.v.valueOf() - b.v.valueOf())
        .reduce((R, r) => r, null);
      if (!lastVersion) {
        throw new Error(`Нет версий документа с id=${id}`);
      }
      const url = result[lastVersion.key].receipt;
      if (url) {
        const ress = await fetch(url);
        if (ress.ok) {
          const json = await ress.json();
          return { [id]: { success: json.success } };
        }
        throw new Error(`${ress.status} ${ress.statusText}`);
      }
      throw new Error('Нема урлов');
    };
    const resultStatus = await Promise
      .allSettled(Object.keys(results)
        .map((id) => loadResults(id, results[id])));
    const successes = resultStatus
      .filter((r) => r.status === 'fulfilled')
      .reduce((R, r) => ({ ...R, ...r.value }), {});

    setVerifyResults((prev) => {
      const rslt = prev ? { ...prev, ...successes } : { ...successes };
      return rslt;
    });
  }, []);

  const updateList = useCallback(
    async (list1) => {
      const docIds = list1?.map((v) => v.id);
      const resBack = await api.post$('logica/find_docs_in_our_base', { docIds });
      if (resBack) {
        const data = await resBack.json();
        if (data?.length !== 0) {
          if (data.length !== 0) {
            const list2 = list1.map((v) => (
              {
                ...v,
                docInOurBase: data.find((o) => o.id === v.id).inBase,
              }));
            setList(list2);
          } else {
            setList(list1);
          }
        }
      } else {
        setList(list1);
      }
    },
    [],
  );

  const getList = useCallback(() => {
    const headers = {
      Ticket: ticket,
      accept: 'application/json',
    };
    const loader = async (method, formCode) => {
      const res = await fetch(
        `https://logica.minfin.gov.ua/ws/api/${method}?filter=${filterType}&period=${year}&code=${formCode}`,
        { headers },
      );
      if (!res.ok) {
        if (res.status === 401) {
          throw new UnauthorizedError(res.statusText);
        }
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res.json();
    };
    setSelectedIds([]);
    setIsLoading(true);
    setError(null);
    setResponse(null);
    loader(forms[activeForm].isBs ? '_REPORTS.GETBS' : '_REPORTS.REPORTS', activeForm)
      .then((r) => {
        const lst = r.result || [];
        updateList(lst);
        if (!forms[activeForm].isBs) {
          getValidationResults(lst, '/_REPORTS.REPORT.STATUS.POST')
            .catch(() => console.error('Помилка при завантаженні результатів валідації'));
        } else {
          getValidationResults(lst, '/_REPORTS.GETBSSTATUS')
            .catch(() => console.error('Помилка при завантаженні результатів валідації'));
        }
      })
      .catch((e) => {
        if (e instanceof UnauthorizedError) {
          setError('Ви не авторізований у Логіка');
          dispatch(logikaLogout());
        } else {
          setError(e.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [activeForm, dispatch, filterType, getValidationResults, setError, ticket, updateList, year]);

  const showFile = useCallback(async () => {
    setIsLoading(true);
    try {
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.GETBSDATA?id=${selectedBs[0]}`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT?id=${selectedIds[0]}`;
      const res = await fetch(
        link,
        {
          headers: {
            Ticket: ticket,
            accept: 'application/json',
          },
        },
      );
      const r = await res.json();
      if (!r.pdflink) {
        setError('Для цього звіту немає PDF на сервері Logica');
      } else {
        const r2 = await fetch(r.pdflink);
        const file = await r2.blob();
        setPdfLink(window.URL.createObjectURL(file));
        setModalIsOpen(true);
      }
      setIsLoading(false);
      setResponse(null);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [selectedBs, selectedIds, setError, setPdfLink, ticket]);

  const onClearErr = useCallback(
    () => {
      setError(null);
      setLogikaErrors([]);
      setResponse(null);
    },
    [setError],
  );

  const handleApproved = useCallback(async (approved, comment) => {
    const appr = async (id, approv, com) => {
      let link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=${approv}&comment=${com}`;
      if (approv) {
        link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=${approv}`;
      }
      const res = await fetch(link, {
        headers: {
          Ticket: ticket,
          accept: 'application/json',
        },
      });
      if (res.ok) {
        const d = await res.json();
        return d;
      }
      throw new Error(`Помилка встановлення / знаття позначки на видалення ${id} (${res.status} ${res.statusText})`);
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => appr(id, approved, comment)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));

        const ld = results
          .filter((r) => r.status === 'fulfilled' && r.value.success);
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
          getList();
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [getList, selectedIds, ticket]);

  const handlePublish = useCallback(async (publish, comment) => {
    const publ = async (id, pub, com) => {
      let link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.PUBLISH?id=${id}&publish=${publish}&comment=${com}`;
      if (pub) {
        link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.PUBLISH?id=${id}&publish=${pub}`;
      }
      setIsLoading(true);
      const res = await fetch(link, {
        headers: {
          Ticket: ticket,
          accept: 'application/json',
        },
      });
      if (res.ok) {
        const d = await res.json();
        return d;
      }
      throw new Error(`Помилка встановлення / знаття позначки на видалення ${id} (${res.status} ${res.statusText})`);
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => publ(id, publish, comment)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));

        const ld = results
          .filter((r) => r.status === 'fulfilled' && r.value.success);
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
          getList();
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [getList, selectedIds, ticket]);

  const handleCancel = useCallback(async () => {
    try {
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.CANCELBS?id=${selectedBs[0]}`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.CANCEL?id=${selectedIds[0]}`;
      setIsLoading(true);
      const res = await fetch(link, {
        headers: {
          Ticket: ticket,
          accept: 'application/json',
        },
      });
      if (res.status === 401) {
        dispatch(logikaLogout());
        setResponse(null);
        setIsLoading(false);
        setError('Необхідно авторизуватись!');
        return;
      }
      setError(null);
      const r = await res.json();
      setResponse(r.success ? 'Звіт скасовано успішно!' : r.message);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [dispatch, selectedBs, selectedIds, setError, ticket]);

  const getStatusInfo = useCallback(async () => {
    try {
      setResultVerifyList(null);
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.GETBSSTATUS?ids=['${selectedIds[0]}']`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.STATUS?ids=['${selectedIds[0]}']`;
      setIsLoading(true);
      const res = await fetch(link);
      const r = await res.json();
      if (Object.entries(r).length === 0) {
        setResponse(null);
        setIsLoading(false);
        return setError('Для перевірки результату веріфікації перейдіть до розділу "Тип звіту.(табл)", де "Тип звіту.(табл)" - назва відповідного розділу.');
      }
      setResultVerify(Object.entries(Object.values(r)[0]));
      setResultVerifyPage(true);
      setIsLoading(false);
    } catch (e) {
      setResponse(null);
      setError(e.message);
    }
    return setIsLoading(false);
  }, [selectedBs.length, selectedIds, setError]);

  const getHistory = useCallback(async () => {
    try {
      setResultVerifyList(null);
      const link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.HISTORY?id=${selectedIds[0]}`;
      setIsLoading(true);
      const res = await fetch(link, {
        headers: {
          Ticket: ticket,
          accept: 'application/json',
        },
      });
      const r = await res.json();
      if (!r.receipt) {
        setError('Для цього звіту немає історії на сервері Logica');
      } else {
        setLoadFile(r.receipt);
        const r2 = await fetch(r.receipt);
        const file = await r2.blob();
        setPdfLink(window.URL.createObjectURL(file));
        setModalIsOpen(true);
      }
      setIsLoading(false);
      setResponse(null);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [selectedIds, setError, setPdfLink, ticket]);

  const signDocByFinDep = useCallback(async (publish) => {
    const signFile = async (id) => {
      if (list.find((o) => o.id === id).docInOurBase === 'Так') {
        let res = { ok: true };
        if (publish) {
          const link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=true`;

          res = await fetch(link, {
            headers: {
              Ticket: ticket,
              accept: 'application/json',
            },
          });
        }
        if (res.ok) {
          const link2 = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.SIGNED?id=${id}&publish=${publish}`;
          setIsLoading(true);
          const res2 = await fetch(link2, {
            headers: {
              Ticket: ticket,
              accept: 'application/json',
            },
          });
          if (res2.ok) {
            const d2 = await res2.json();
            return d2;
          }
          throw new Error(`Помилка підпису документа ${id} (${res.status} ${res.statusText})`);
        }
        throw new Error(`Помилка при затверджені ${id} (${res.status} ${res.statusText})`);
      } else {
        throw new Error(`Документ з id ${id} не знайдено в нашій базі`);
      }
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => signFile(id)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
          getList();
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [getList, list, selectedIds, ticket]);

  const getResult = useCallback(async (url) => {
    try {
      if (url) {
        const res = await fetch(url);

        if (res.ok) {
          const json = await res.json();
          setResJson(json);
        } else {
          setError(`Помилка HTTP: ${res.status}`);
        }
      } else {
        setResJson({
          success: false,
          messages: ['Валідація не була пройдена'],
        });
      }
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [setError]);

  const onSetOrder = useCallback((e, column) => {
    setOrder({
      ...column,
    });
  }, []);

  const onSetFilter = useCallback((e, partFilter) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      ...partFilter,
    }));
  }, []);

  const onClearFilter = useCallback((e, keys = []) => {
    setFilter((oldFilter) => (oldFilter && Object.keys(oldFilter)
      .filter((key) => !keys.includes(key))
      .reduce(
        (RK, key) => ({
          ...RK,
          [key]: oldFilter[key],
        }),
        {},
      )
    ));
  }, []);
  const orderingForm = useCallback((a, b, formOrder) => {
    if (Object.values(formOrder)
      .includes('DESC')) {
      if (Object.keys(formOrder)[0] === 'lastEditDate' || Object.keys(formOrder)[0] === 'addDate') {
        return (new Date(b[Object.keys(formOrder)]).valueOf()
          - new Date(a[Object.keys(formOrder)]).valueOf());
      }
      return b.analytics[Object.keys(formOrder)] - a.analytics[Object.keys(formOrder)];
    }

    if (Object.values(formOrder)
      .includes('ASC')) {
      if (Object.keys(formOrder)[0] === 'lastEditDate' || Object.keys(formOrder)[0] === 'addDate') {
        return (new Date(a[Object.keys(formOrder)]).valueOf()
          - new Date(b[Object.keys(formOrder)]).valueOf());
      }
      return a.analytics[Object.keys(formOrder)] - b.analytics[Object.keys(formOrder)];
    }
    return new Date(b.lastEditDate).valueOf() - new Date(a.lastEditDate).valueOf();
  }, []);

  const getDate = (el) => el.replace('T', ' ');

  const displayList = useMemo(
    () => {
      if (list) {
        let dItems = list.map((row) => {
          const {
            _ACTUAL,
            _PROJAPPROVED,
            _REPORT_PROJAPPR,
            _ISPUBLISH,
            _SIGNEDBYFINDEP,
          } = row.analytics;
          return {
            ...row,
            analytics: {
              ...row.analytics,
              _ACTUAL: (!_ACTUAL || (_ACTUAL === 'Ні')) ? 'Ні' : 'Так',
              _ISPUBLISH: (!_ISPUBLISH || (_ISPUBLISH === 'Ні')) ? 'Ні' : 'Так',
              _PROJAPPROVED: _PROJAPPROVED === 'A' ? 'Затверджено' : 'Проект',
              _REPORT_PROJAPPR: _REPORT_PROJAPPR === 'A' ? 'Кінцеве рішення' : 'Проект',
              success: '?',
              _SIGNEDBYFINDEP: (!_SIGNEDBYFINDEP || (_SIGNEDBYFINDEP === 'Ні')) ? 'Ні' : 'Так',
            },
          };
        });
        if (verifyResults) {
          dItems = dItems.map((l) => {
            if (l.id in verifyResults) {
              return {
                ...l,
                analytics:
                      {
                        ...l.analytics,
                        success: verifyResults[l.id].success ? 'Верифіковано' : 'З помилками',
                      },
              };
            }
            return l;
          });
        }
        const formFilter = filter || {}; // {f: 1, a: 2}
        const filteredFormData = dItems.filter((row) => Object.keys(formFilter)
          .reduce(
            (filterR, field) => {
              if (filterR) {
                if (field === 'addDate' || field === 'lastEditDate' || field === 'docInOurBase') {
                  return row[field] === formFilter[field];
                }
                return row.analytics[field] === formFilter[field];
              }
              return false;
            },
            true,
          ));
        const formOrder = order || {};
        const orderedFormData = [...filteredFormData]
          .sort((a, b) => orderingForm(a, b, formOrder));
        return {
          items: dItems,
          order: formOrder,
          filter: formFilter,
          display_list: orderedFormData,
        };
      }
      return { items: [], display_list: [] };
    },
    [filter, list, order, orderingForm, verifyResults],
  );

  const getResultVerifyList = useCallback((e) => {
    const type = e.target.value;
    if (type === '0') {
      return setResultVerifyList(Array.of(resultVerify.find((el) => el[1].receipt)));
    }
    if (type === '1') {
      return setResultVerifyList(resultVerify.filter((el) => el[1].receipt));
    }
    return setResultVerifyList(resultVerify);
  }, [resultVerify]);

  const years = useMemo(
    () => {
      const d = new Date();
      return [...Array(d.getFullYear() - 2020 + 2)].map((_, i) => i + 2020);
    },
    [],
  );

  console.log('years=');
  console.log(years);

  const handleSetYear = useCallback(async (e) => {
    setYear(e.target.value);
  }, []);

  const handleSetFilterType = useCallback((v) => {
    setFilterType(v);
  }, []);

  useEffect(() => {
    if (activeForm !== null) {
      getList();
    }
  }, [activeForm, filterType, getList]);
  return (
    <Loader isLoading={isLoading}>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      {error && (
      <Alert variant="danger" dismissible onClose={onClearErr}>
        <Alert.Heading>
          {error}
        </Alert.Heading>
      </Alert>
      )}
      <StyledDiv top="0" zIndex="3">
        {(!ticket || (error === 'Необхідно авторизуватись!')) && (
        <Navbar.Brand href="#/dp/authentication/" onClick={() => setError(null)}>
          Авторизація у
          LOGICA
        </Navbar.Brand>
        )}

        <DropdownButton
          variant="outline-primary"
          id="dropdown-item-button"
          title={year}
          disabled={!logicaPingSuccess}
          style={{ marginRight: '10px' }}
        >
          {years.map((y) => (
            <Dropdown.Item as="button" key={y} onClick={handleSetYear} value={y}>{y}</Dropdown.Item>
          ))}
        </DropdownButton>

        <DropdownButton
          variant="outline-primary"
          id="dropdown-item-button"
          title={`Тип відбору форм: ${filterType !== null ? FilterTypes[filterType] : 'Не обрано'}`}
          disabled={!logicaPingSuccess}
        >
          {FilterTypes.map((v, i) => (
            <Dropdown.Item
              key={v}
              as="button"
              onClick={() => handleSetFilterType(i.toString())}
            >
              {v}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        <StyledButton variant="success" onClick={getList} style={{ marginLeft: '10px' }}>
          <FontAwesomeIcon icon={faRedo} />
        </StyledButton>

      </StyledDiv>

      <Modal
        show={resultVerifyPage}
        size="xl"
        onHide={() => setResultVerifyPage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Результат відправлення документу до ЛОГІКА</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResultVerifyPage
            resultVerify={resultVerify}
            onClose={() => setResultVerifyPage(false)}
            getResult={getResult}
            resJson={resJson}
            getDate={getDate}
            getResultVerifyList={getResultVerifyList}
            resultVerifyList={resultVerifyList}
            logicaPingSuccess={logicaPingSuccess}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={commentPage}
        size="xl"
        onHide={() => setCommentPage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Підпис даних</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Comment
            onClose={() => setCommentPage(false)}
            func={commentPage === 'approved' ? handleApproved : handlePublish}
          />
        </Modal.Body>
      </Modal>

      {(err || logikaErrors.length > 0) && (
      <Alert variant="danger" dismissible onClose={onClearErr}>
        <Alert.Heading>
          {err && err}
          {/* eslint-disable-next-line react/no-array-index-key */}
          {logikaErrors.length > 0 && logikaErrors.map((e, idx) => <li key={idx}>{e}</li>)}
        </Alert.Heading>
      </Alert>
      )}

      {!err && logikaErrors.length === 0 && response === 'Зміни пройшли успішно!' && (
      <Alert variant="success" onClose={onClearErr}>
        <Alert.Heading>
          Вітаємо!
        </Alert.Heading>
        {response}
      </Alert>
      )}

      <Modal
        show={modalIsOpen}
        size="xl"
        onHide={() => {
          setModalIsOpen(false);
          setLoadFile(null);
        }}
        dialogClassName="vh-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>Перегляд</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShowPDFModal
            pdflink={pdflink.current}
          />
        </Modal.Body>
        {loadFile && (
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => window.open(`${loadFile}`, '_blank')}
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Скачати
          </Button>
        </Modal.Footer>
        )}
      </Modal>

      <StyledDiv top="38px" zIndex="2">
        <StyledButton
          variant="outline-danger"
          onClick={handleCancel}
          disabled={selectedIds.length !== 1 || !logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faBan} />
          <Text>Скасувати звіт</Text>
        </StyledButton>

        <StyledDropdownButton
          id="dropdown-item-button"
          title="Змінити ознаку &quot;Затверджено&quot;"
          drop="right"
          variant="outline-warning"
          disabled={((role !== '01') && (!admin)) || (selectedIds.length === 0) || !logicaPingSuccess}
        >
          <Dropdown.Item
            as="button"
            onClick={() => handleApproved(true)}
            value="0"
          >
            Затвердити
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setCommentPage('approved')} value="1">
            Зняти
            затвердження
          </Dropdown.Item>
        </StyledDropdownButton>

        <StyledDropdownButton
          id="dropdown-item-button"
          title="Змінити ознаку &quot;Опубліковано&quot;"
          drop="right"
          variant="outline-warning"
          disabled={(selectedIds.length === 0) || !logicaPingSuccess}
        >
          <Dropdown.Item
            as="button"
            onClick={() => handlePublish(true)}
            value="0"
          >
            Опублікувати
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setCommentPage('publish')} value="1">
            Зняти
            опублікування
          </Dropdown.Item>
        </StyledDropdownButton>

        <StyledButton
          variant="outline-info"
          onClick={getStatusInfo}
          disabled={selectedIds.length !== 1 || !logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faCheckDouble} />
          <Text>Результат верифікації</Text>
        </StyledButton>

        <StyledButton
          variant="outline-success"
          onClick={showFile}
          disabled={selectedIds.length !== 1 || !logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          <Text>Перегляд</Text>
        </StyledButton>

        <StyledButton
          variant="outline-secondary"
          onClick={getHistory}
          disabled={selectedIds.length !== 1 || !logicaPingSuccess}
        >
          <FontAwesomeIcon icon={faHistory} />
          <Text>Історія змін</Text>
        </StyledButton>
        {((sessionOptions.get('isRegionUser', false)
                    && sessionOptions.get('approveByRegion', false))
                  || sessionOptions.get('approveByRegionByKyiv', false))
                && (
                  <StyledDropdownButton
                    id="dropdown-item-button"
                    title="Змінити ознаку &quot;Підписано обласним фіндепом&quot;"
                    drop="right"
                    variant="outline-warning"
                    disabled={((role !== '01') && (!admin)) || (selectedIds.length === 0) || !logicaPingSuccess}
                  >
                    <Dropdown.Item as="button" onClick={() => signDocByFinDep(true)} value="0">
                      Підписати
                      та затвердити
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => signDocByFinDep(false)} value="1">
                      Зняти
                      підпис
                    </Dropdown.Item>
                  </StyledDropdownButton>
                )}
      </StyledDiv>

      <Tables
        data={list}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setSelectedBs={setSelectedBs}
        displayList={displayList}
        onSetFilter={onSetFilter}
        onClearFilter={onClearFilter}
        onSetOrder={onSetOrder}
        activeForm={activeForm}
        setActiveForm={setActiveForm}
      />

    </Loader>
  );
}

export default ListOfCatalogs;
