import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes2';

const meta = md.tables.TABL_22;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    {/*<Row>*/}
    {/*  <Col>*/}
    {/*    <TableHeaderCell>*/}
    {/*      Лише коди 00.n*/}
    {/*    </TableHeaderCell>*/}
    {/*  </Col>*/}
    {/*</Row>*/}
    <Row>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'code'}
              highlighted={highlights.includes('code')}
              onToggleHighlght={() => onToggleHighlght('code')}
            >
              {meta.columns.code.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'codeDod'}
              highlighted={highlights.includes('codeDod')}
              onToggleHighlght={() => onToggleHighlght('codeDod')}
            >
              {meta.columns.codeDod.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'name'}
              highlighted={highlights.includes('name')}
              onToggleHighlght={() => onToggleHighlght('name')}
            >
              {meta.columns.name.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithImpact'}
              highlighted={highlights.includes('rateLegalWithImpact')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithImpact')}
            >
              {meta.columns.rateLegalWithImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithImpact'}
              highlighted={highlights.includes('rateNaturalWithImpact')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithImpact')}
            >
              {meta.columns.rateNaturalWithImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithoutImpact'}
              highlighted={highlights.includes('rateLegalWithoutImpact')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithoutImpact')}
            >
              {meta.columns.rateLegalWithoutImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithoutImpact'}
              highlighted={highlights.includes('rateNaturalWithoutImpact')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithoutImpact')}
            >
              {meta.columns.rateNaturalWithoutImpact.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell>
              Постійне використання
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithImpact1'}
              highlighted={highlights.includes('rateLegalWithImpact1')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithImpact1')}
            >
              {meta.columns.rateLegalWithImpact1.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithImpact11'}
              highlighted={highlights.includes('rateLegalWithImpact11')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithImpact11')}
            >
              {meta.columns.rateLegalWithImpact11.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithImpact1'}
              highlighted={highlights.includes('rateNaturalWithImpact1')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithImpact1')}
            >
              {meta.columns.rateNaturalWithImpact1.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableHeaderCell>
              Постійне використання
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithoutImpact1'}
              highlighted={highlights.includes('rateNaturalWithoutImpact1')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithoutImpact1')}
            >
              {meta.columns.rateNaturalWithoutImpact1.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithoutImpact1'}
              highlighted={highlights.includes('rateLegalWithoutImpact1')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithoutImpact1')}
            >
              {meta.columns.rateLegalWithoutImpact1.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithoutImpact12'}
              highlighted={highlights.includes('rateLegalWithoutImpact12')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithoutImpact12')}
            >
              {meta.columns.rateLegalWithoutImpact12.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateLegalWithoutImpact13'}
              highlighted={highlights.includes('rateLegalWithoutImpact13')}
              onToggleHighlght={() => onToggleHighlght('rateLegalWithoutImpact13')}
            >
              {meta.columns.rateLegalWithoutImpact13.label}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'rateNaturalWithoutImpact12'}
              highlighted={highlights.includes('rateNaturalWithoutImpact12')}
              onToggleHighlght={() => onToggleHighlght('rateNaturalWithoutImpact12')}
            >
              {meta.columns.rateNaturalWithoutImpact12.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </Row>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
