const frontendURL = '/srv/md-editor/';
const backendURL = '/api/repos/check-schema____/';
const name = 'МД едітор';

const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  backendURL,
  name,
  getVisibility,
};

export default instance;
