const LiquidityCalcMethod = {
  second: { name: 1, label: 'Метод приросту' },
  third: { name: 2, label: 'Метод виконання плану' },
  // exp_smoothing: { name: 3, label: 'Експоненціальне згладжування' },
  // linear_regression: { name: 4, label: 'Лінійна регресія' },
  // tcn: { name: 5, label: 'Часова згорткова мережа' },
  fromPlan: { name: -1, label: 'Наївна модель' },
};

export default LiquidityCalcMethod;
