import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function NavButtons({
  canLeft, canRight,
  onLeft, onRight,
}) {
  return (
    <ButtonGroup vertical>
      <Button onClick={onRight} disabled={!canRight} variant="outline-secondary">
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
      <Button onClick={onLeft} disabled={!canLeft} variant="outline-secondary">
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
    </ButtonGroup>
  );
}

NavButtons.propTypes = {
  canLeft: PropTypes.bool,
  canRight: PropTypes.bool,
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,

};

NavButtons.defaultProps = {
  canLeft: true,
  canRight: true,
};

export default NavButtons;
