import React from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTh = styled.th`
color: #007bff;
position: sticky;
z-index: 1;
background-color: white;
top: 0;`;

function HeaderCell({
  col, isAdmin, onSetFilter,
}) {
  return (
    <>
      {(!col.onlyForAdmins || isAdmin) && (
      <>
        {col.canSearch
          ? (
            <StyledTh>
              <Dropdown>
                <Dropdown.Toggle as="a">
                  {col.label}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{
                  maxHeight: '30vh',
                  overflow: 'auto',
                  background: 'white',
                }}
                >
                  <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Пошук"
                    onChange={(e) => onSetFilter(col.name, e.target.value)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </StyledTh>
          )
          : (
            <StyledTh>
              {col.label}
            </StyledTh>
          )}
      </>
      )}
    </>
  );
}

HeaderCell.propTypes = {
  col: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onlyForAdmins: PropTypes.bool,
    canSearch: PropTypes.bool,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default HeaderCell;
