import React, { useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCtxMenu } from '../newLister/tableComponent/contextMenu/hook';

const StyledMenuContainer = styled.div`
  position: fixed;
  z-index: 1100;
  max-height: 30vh;
`;

function ReportContextMenu({
  items, coords, onSelect, onClose,
}) {
  const ctxMenu = useMemo(
    () => ({
      left: coords.x,
      top: coords.y,
      visible: true,
    }),
    [coords.x, coords.y],
  );

  const { ref } = useCtxMenu(ctxMenu, onClose);

  return (
    <StyledMenuContainer
      ref={ref}
      className="bg-white shadow-lg rounded pt-2 border overflow-hidden d-flex flex-column"
    >
      <h5 className="text-primary mx-3">Оберіть поле для розшифровки</h5>
      <ListGroup
        variant="flush"
        className="overflow-auto"
        onSelect={onSelect}
      >
        {items.map((item) => (
          <ListGroup.Item
            key={item.key}
            action
            eventKey={item.key}
          >
            {item.title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </StyledMenuContainer>
  );
}
ReportContextMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  coords: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReportContextMenu;
