import {
  useCallback, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';

const DEFAULT_FIELD_STATE = {
  display: '',
};

export const useSettings = () => {
  const [settings, setSettings] = useState([]);

  const [activeSetting, setActiveSetting] = useState(null);

  const onAddSetting = useCallback(
    (name) => setSettings((oldSettings) => {
      if (oldSettings.filter((ot) => ot.name === name).length) return oldSettings;
      setActiveSetting(name);
      return [...oldSettings, { name, text: { ...DEFAULT_FIELD_STATE } }];
    }),
    [],
  );

  const onRenameSetting = useCallback(
    (oldName, newName) => {
      if (!newName) return false;
      setSettings((oldSettings) => {
        if (oldSettings
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldSettings;
        return oldSettings.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeSetting === oldName) setActiveSetting(newName);
      return true;
    },
    [activeSetting],
  );

  const onRemoveSetting = useCallback(
    (settingName) => {
      setSettings((oldSettings) => oldSettings.filter((ot) => ot.name !== settingName));
      if (activeSetting === settingName) setActiveSetting(null);
    },
    [activeSetting],
  );

  const onChangeSetting = useCallback(
    (settingName, v, keyValue) => {
      setSettings((oldSettings) => oldSettings
        .map((ot) => (ot.name !== settingName
          ? ot : { ...ot, text: { ...ot.text, [keyValue]: v } })));
    },
    [],
  );

  const settingHandlers = useMemo(() => ({
    onAddSetting,
    onRenameSetting,
    onRemoveSetting,
    onChangeSetting,
    setActiveSetting,
  }), [onAddSetting, onChangeSetting, onRemoveSetting, onRenameSetting]);

  return {
    settings, setSettings, activeSetting, settingHandlers,
  };
};

export const settingPropType = PropTypes.shape({
  name: PropTypes.string,
  text: PropTypes.shape({}),
});
