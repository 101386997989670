const frontendURL = '/srv/groupManagement/';
const backendURL = '/api/auth/permissiongroup/';
const name = 'Управління Групами';
const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  backendURL,
  name,
  getVisibility,
};

export default instance;
