import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: {
    label: 'Фінансовий орган',
    name: 'Владелец',
    visible: false,
  },
  FullName: {
    label: 'Повне найменування',
    name: 'ПолнНаименование',
    visible: true,
  },
  flAutoFillName: {
    label: 'Автоматичне заповнення найменування ',
    name: 'флАтоматЗаполненияНаим',
    visible: false,
  },
  flAutoFillFullName: {
    label: 'Автоматичне заповнення повного найменування ',
    name: 'флАтоматЗаполненияПолнНаим',
    visible: false,
  },
  Steward: {
    label: 'Розпорядник',
    name: 'Распорядитель',
    visible: true,
  },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  CodeEnding: {
    label: 'Останнi двi цифри класифiкатора',
    name: 'КодОкончание',
    visible: true,
  },
  Classifier: { label: 'КДБ', name: 'Классификатор', visible: true },
  DateCl: { label: 'станом на', name: 'ДатаКл', visible: true },
  ElementFinInstitution: {
    label: 'Елемент фін. органу',
    name: 'ЭлементФинОргана',
    visible: true,
  },
  DateEl: { label: 'станом на', name: 'ДатаЭл', visible: true },
  CodeKDB: { label: 'Код КДБ', name: 'КодКДБ', visible: true },
  code: { ...defaultColumns.code, visible: false },
};

export const elementKDB = {
  label: 'Деталiзацiя КДБ',
  name: 'elementKDB',
  backendName: 'ЭлементыКДБ',
  columns,
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  frontend: 'cat/elementKDB',
  listColumns: [...new Set([
    'CodeKDB',
    ...reqListerColumns,
    'name',
    'CodeEnding',
    'Steward',
  ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default elementKDB;
