import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/documents/limitReference';
import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import enums from '../../../../constants/meta/enums';

const planingKindsOptions = Object.values(enums.PlaningKind)
  .map(({ name, label }) => ({ value: name, display_name: label }));

function LimitReference({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.FI.name];

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planingMode.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fondObject.label}
            value={data[md.columns.fondObject.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.fondObject.name]: value,
              });
              actions.onSR('FOND_ON_CHANGE');
            }}
            modelType="cat"
            modelName="elementFond"
            filter={fondFilter}
            readOnly={readOnly}
          />
          <EditorControls.CheckboxInput
            controlId={`includeBudgetAmplification-${data.id}`}
            label={md.columns.includeBudgetAmplification.label}
            value={data[md.columns.includeBudgetAmplification.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.includeBudgetAmplification.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.variantBudgetDraft.label}
            value={data[md.columns.variantBudgetDraft.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.variantBudgetDraft.name]: value,
              });
              actions.onSR('VARIANT_ON_CHANGE');
            }}
            modelType="cat"
            modelName="variantBudgetDraft"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
            rows={3}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

LimitReference.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LimitReference;
