import { baseReportColumns, baseResultColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  varKEKV: { label: 'За класифікацією КЕКВ', name: 'ВариантКЕКВ' },
  varReport: { label: 'Варіант звіту', name: 'ВариантОтчета' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  typePlan: { label: 'Вид плану', name: 'ВидПлана' },
  typeMoney: { label: 'Вид коштів', name: 'ВидСредствКЕКВ' },
  WithKFB: { label: 'Показувати КФБ', name: 'ВключатьКФБ' },
  in1000: { label: 'В тис.грн.', name: 'ВТысГрн' },
  groupKEKV: { label: 'За групами КЕКВ', name: 'ГруппыКЕКВ' },
  dateSign: { label: 'Дата підписання', name: 'ДатаПодписания' },
  dateUtv: { label: 'Дата затвердження', name: 'ДатаУтверждения' },
  withDoc: { label: 'До документа', name: 'ДоДокумента' },
  document: { label: 'Документ ссылка', name: 'ДокументСсылка' },
  form: { label: 'Форма', name: 'ИздательФорм' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  inKFK: { label: 'За КПКВ', name: 'ПоКФК' },
  inKFKNoGroups: { label: 'КПКВ тільки елементи', name: 'ПоКФКБезГрупп' },
  inCSU: { label: 'За розпорядниками', name: 'ПоРаспорядителям' },
  sumVoid: { label: 'Пусті числові значення показувати як', name: 'СимволПустогоЧисла' },
  inCSU2: { label: 'Виконавець', name: 'СправкаИсполнитель' },
  numberDoc: { label: 'Номер', name: 'СправкаНомер' },
  noteDoc: { label: 'Підстава', name: 'СправкаОснование' },
  reasonDoc: { label: 'Причина', name: 'СправкаПричина' },
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОрган' },
  typeKEKV: { label: 'Тип звіту', name: 'ТипКЕКВ' },
  yearForm: { label: 'Річна', name: 'ФормаГодовая' },
  mixForm: { label: 'Зведена форма', name: 'ФормаСводная' },
  approve: { label: '-Затверджую-', name: 'ФормаУтверждаю' },
  showCSUAs: { label: 'Показувати розпорядника як', name: 'ПоказыватьРаспКак' },
  showKFKAs: { label: 'Показувати КПКВ як', name: 'ПоказыватьКФККак' },
  lvQuart: { label: 'З початку року по', name: 'сзКвартал' },
  noShowHead: { label: 'Не показувати шапку звіту перед розпорядниками', name: 'НеПоказыватьШапкуОтчетаСверхуРаспорядителей' },
  dateReg: { label: 'Дата реєстру змін', name: 'РеестрДата' },
  regNo: { label: 'Номер реєстру змін', name: 'РеестрНомер' },
  inKBP: { label: 'По КБП', name: 'ПоКБП' },
  inPCM: { label: 'За ПЦМ', name: 'флПЦМ' },
  showAs: { label: 'Відображення як', name: 'ОтображениеКодаТерра' },
  dontShow: { label: 'Не відображати залишки ', name: 'НеотражатьОстатки' },
  widthCol: { label: 'Ширина колонок (з сумами)', name: 'ШиринаКолонок' },
  inPage: { label: 'Вписати в сторінку', name: 'УместитьНаСтраницу' },
  addName: { label: 'Використовувати додаткову назву для КЕКВ', name: 'ИспользоватьДругонНаименование' },
  showKVK: { label: 'Показувати КВК як', name: 'ПоказыватьКВККак' },
  KOATUU: { label: 'Код КОАТУУ як код території', name: 'флКОАТУУ' },
  flSN: { label: 'ПРІЗВИЩЕ та ім"я', name: 'ФлФИ' },
  flKEKVNoH: { label: 'КЕКВ без ієрархії', name: 'флКЕКВБезИерархии' },
  noCop: { label: 'Без десяткових знаків (копійок)', name: 'БезКопеек' },
  flKPKVInH: { label: 'КПКВ з ієрархією', name: 'флКПКВИерархия' },
  flNoKEKVCodes: { label: 'Не друкувати код КЕКВ', name: 'флНеПечататьКодКЕКВ' },
};

/**
 * @const
 */
const definition = {
  label: 'Зведення показників СФ',
  name: 'mixIndicatorsSFPrint',
  columns,
  frontend: 'rep/mixIndicatorsSFPrint',
  backendName: 'ОткрытьСводПоказателейСФ',
  resultColumns: baseResultColumns,
};

export default definition;
