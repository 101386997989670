import React from 'react';
import Reports from './Reports';
// import { Account, Team } from './Admin';
import { Directors } from '../../../constants/meta/navBar';

function QuickLinks() {
  return (
    <>
      <Reports items={Directors.directors.boxes} />
      {/* <Team /> */}
      {/* <Account /> */}
    </>
  );
}

export default QuickLinks;
