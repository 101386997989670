import React, {
  memo, useCallback,
} from 'react';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ItemPicker, NumberInput, StringInput } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes4';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_61';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <TableRowCell column="number" highlighted={highlights.includes('number')}>
          <StringInput
            value={row[tableMD.columns.number.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.number.name]: v })}
            readOnly={readOnly}
            maxLength={10}
            required
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="codeDod" highlighted={highlights.includes('codeDod')}>
          <StringInput
            value={row[tableMD.columns.codeDod.name]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.codeDod.name]: v })}
            readOnly={readOnly}
            maxLength={19}
            required
          />
        </TableRowCell>
      </Col>
      <Col>
        <TableRowCell column="category" highlighted={highlights.includes('category')}>
          <ItemPicker
            value={row[tableMD.columns.category.name]}
            onChange={async (e, v) => {
              await onRowChange(e, { [tableMD.columns.category.name]: v });
              await onSR('CHANGE_CATEGORY_IN_TABLE61', { rowId: rowIndex });
            }}
            readOnly={readOnly}
            modelType="cat"
            modelName="residenceKinds"
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="amountInternal" highlighted={highlights.includes('amountInternal')}>
          <NumberInput
            value={row[tableMD.columns.amountInternal.name]}
            precision={4}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.amountInternal.name]: v })}
            readOnly={readOnly}
            required
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="amount" highlighted={highlights.includes('amount')}>
          <NumberInput
            value={row[tableMD.columns.amount.name]}
            precision={4}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.amount.name]: v })}
            readOnly={readOnly}
            required
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
