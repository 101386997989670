import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fillTable } from '../../../../actions/editor';
import { CommandPanelButton } from '../../../../components/button';
import { CheckAllIcon, UnChekAllIcon } from '../../../../assets/icons';
import { editor as actions } from '../../../../actions';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

export const ContainerButton = styled.div`
  display: flex;
`;

function DocsCP({ disabled, tableName, dispatch }) {
  return (
    <ContainerButton>
      <CommandPanelButton
        text="Відмітити всі"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['selection'], true))}
      >
        <CheckAllIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Зняти відмітки"
        disabled={disabled}
        onClick={() => dispatch(fillTable(tableName, ['selection'], false))}
      >
        <UnChekAllIcon />
      </CommandPanelButton>
      <CommandPanelColor
        label="Заповнити"
        onClick={() => dispatch(actions.processServerReq('FILL_STORNO'))}
      />
    </ContainerButton>
  );
}

DocsCP.propTypes = {
  tableName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

DocsCP.defaultProps = {
  disabled: false,
};

export default connect()(DocsCP);
