import React, { useCallback, useState } from 'react';
import { fromJS } from 'immutable';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import FinPrintingEditor from '../../../dataProcessors/finPrinting/editor';
import md from '../../../../constants/meta/documents/financing';

const cols = md.columns;
const tCols = md.tables.general.columns;

function PrintModalWithoutSave({ data }) {
  const [opened, setOpened] = useState(false);
  const mapStore = useCallback(
    () => {
      const d = {
        IsFinancing: data[cols.IsFinancing.name],
        Transferting: data[cols.Transferting.name],
        TransfertingZFToSF: data[cols.TransfertingZFToSF.name],
        DataTable: data[md.tables.general.name].map((row) => {
          const maps = [
            {
              k: 'Номер',
              v: data[cols.RNo.name],
            },
            {
              k: 'Дата',
              v: data[cols.date.name],
            },
            {
              k: 'Бюджет',
              v: data[cols.Budget.name],
            },
            {
              k: 'ЭлементФонд',
              v: data[cols.FondObject.name],
            },
            {
              k: 'ФинансовыйОрган',
              v: data[cols.FI.name],
            },
            {
              k: 'РСФинансовыйОрган',
              v: data[cols.FIAccount.name],
            },
            {
              k: 'Распорядитель',
              v: data[cols.IncludeCSUIntoHead.name] ? data[cols.CSU.name] : row[tCols.CSU.name],
            },
            {
              k: 'РСРаспорядителя',
              v: row[tCols.CSUAccount.name],
            },
            {
              k: 'ЭлементКФК',
              v: row[tCols.elementKFK.name],
            },
            {
              k: 'ЭлементКЕКВ',
              v: row[tCols.elementKEKV.name],
            },
            {
              k: 'Примечание',
              v: row[tCols.Note.name],
            },
            {
              k: 'Сумма',
              v: row[tCols.Sum.name],
            },
            {
              k: 'СуммаОплата',
              v: row[tCols.SumPayment.name],
            },
            {
              k: 'Остаток',
              v: 0,
            },
          ];
          return maps.reduce((R, {
            k,
            v,
          }) => ({
            ...R,
            [k]: v,
          }), {});
        }),
      };
      return fromJS(d);
    },
    [data],
  );
  return (
    <>
      <Dropdown.Item onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faPrint} className="me-2" />
        Тимчасовий друк (без запису)
      </Dropdown.Item>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Друк фінансування
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FinPrintingEditor
            mapInitialOptions={{
              DataTable: 'ТаблицаДанных',
              IsFinancing: 'ЭтоРаспоряжение',
              Transferting: 'ПередачаСредств',
              TransfertingZFToSF: 'ПередачаИзЗф_Сф',
            }}
            mapStore={mapStore}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
PrintModalWithoutSave.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
export default PrintModalWithoutSave;
