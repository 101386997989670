import React, { useState } from 'react';
import {
  Collapse, ListGroup, Button, Card, Badge, Nav, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare, faSquare,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function List({
  list, onCheck, onCheckAll, onUncheckAll,
}) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Card>
      <Card.Header>
        <Button variant="fallcon-link" onClick={() => setCollapsed((o) => !o)}>
          Бюджети
          <Badge variant="success" className="ms-1">
            {list.filter((l) => l.check).length}
            {' '}
            /
            {list.length}
          </Badge>
        </Button>
      </Card.Header>
      <Collapse in={!collapsed}>
        <Card.Body>
          <Nav>
            <Button className="me-2" onClick={onCheckAll} variant="outline-success">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="me-1"
              />
              Вибрати всі
            </Button>
            <Button onClick={onUncheckAll} variant="outline-danger">
              <FontAwesomeIcon
                icon={faSquare}
                className="me-1"
              />
              Скасувати всі
            </Button>
          </Nav>
          <ListGroup variant="flush" className="mt-2">
            {list.map((el) => (
              <ListGroup.Item key={el.id}>
                <Form.Check
                  type="checkbox"
                  className="mb-0 d-flex gap-1"
                >
                  <Form.Check.Input
                    checked={el.check}
                    onChange={() => onCheck(el.id)}
                  />
                  <Form.Check.Label className="mb-0">
                    <Badge variant="info" className="me-1">
                      {el.code}
                    </Badge>
                    {el.name}
                  </Form.Check.Label>
                </Form.Check>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Collapse>
    </Card>
  );
}

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  onUncheckAll: PropTypes.func.isRequired,
};

List.defaultProps = {
  list: null,
};
export default List;
