import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledContainer = Styled.div`
  display: flex;
  border: 1px solid #80ace6;
  border-radius: 0.3rem;
  padding: 5px;
  background: white;
  margin: 5px 0;
`;

const StyledContainer2 = Styled.div`
  margin-left: 10px;
  width: 100%;
`;

const StyledIcon = Styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
  &>* {
    height: 40px;
    width: 40px;
  }
`;

const StyledHeader = Styled.div`
  color: #008F21;
  font-family: 'Roboto', sans-serif;
  // margin: 0 0 25px 0;
  font-size: 1.2em;
`;

const StyledContent = Styled.div`
  font-size: 0.8em;
  font-style: italic;
  color: grey;
`;

function Item({ header, icon, content }) {
  return (
    <StyledContainer>
      {icon && (
      <StyledIcon>
        {icon}
      </StyledIcon>
      )}
      <StyledContainer2>
        {header && (
        <StyledHeader>
          {header}
        </StyledHeader>
        )}
        <StyledContent>
          {content}
        </StyledContent>
      </StyledContainer2>
    </StyledContainer>
  );
}

Item.propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
};

Item.defaultProps = {
  header: null,
  icon: null,
};

export default Item;
