import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/freeRem4Add2024';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'form2';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sum.name;
      const colName2 = tableMD.columns.sumT.name;
      const colName3 = tableMD.columns.sumRasp.name;
      const colName4 = tableMD.columns.sumRaspSubv.name;

      const totColName = tableMD.columns.sumUnused.name;
      const totColName2 = tableMD.columns.prRasp.name;
      const totColName3 = tableMD.columns.prRaspAll.name;

      const tot = value - (row[colName3] || 0) - (row[colName4] || 0);
      const tot2 = ((row[colName3] || 0) / (value - (row[colName2] || 0))) * 100;
      const tot3 = (((row[colName3] || 0) + (row[colName4] || 0)) / value) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
        [totColName3]: tot3,
      });
    },
    [onRowChange, row],
  );

  const onSumTChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumT.name;
      const colName2 = tableMD.columns.sum.name;
      const colName3 = tableMD.columns.sumRasp.name;

      const totColName2 = tableMD.columns.prRasp.name;

      const tot2 = ((row[colName3] || 0) / ((row[colName2] || 0) - value)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName2]: tot2,
      });
    },
    [onRowChange, row],
  );

  const onSumRaspSubvChange = useCallback(
    (e, value) => {
      const colName = tableMD.columns.sumRaspSubv.name;
      const colName2 = tableMD.columns.sumRasp.name;
      const colName3 = tableMD.columns.sum.name;

      const totColName = tableMD.columns.sumUnused.name;
      const totColName2 = tableMD.columns.prRaspAll.name;

      const tot = (row[colName3] || 0) - (row[colName2] || 0) - value;
      const tot2 = (((row[colName2] || 0) + value) / (row[colName3] || 0)) * 100;

      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
        [totColName2]: tot2,
      });
    },
    [onRowChange, row],
  );

  const onSumWorkChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumWork] || 0)) + value;
      const totprWork = (value / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumWork]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumComChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumCom] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = (value / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumCom]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumMedChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumMed] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = (value / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumMed]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumProdChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumProd] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = (value / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumProd]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumSocChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumSoc] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = (value / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumSoc]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumReabilChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumReabil] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = (value / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumReabil] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumReabil]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumTerChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumTer] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = (value / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumTer] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumTer]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumMobChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumMob] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = (value / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumMob] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumMob]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumSecChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumSec] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = (value / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumSec] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumSec]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumBuildChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumBuild] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = (value / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumBuild] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumBuild]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumFortChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumFort] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = (value / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totsumZF = ((row[sumZF] || 0) - (row[sumFort] || 0)) + value;
      const totprZF = (totsumZF / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumFort]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [sumZF]: totsumZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumDebtChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumDebt] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = (value / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumDebt]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumGarantChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumGarant] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = (value / totsumRasp) * 100;
      const totprOther = ((row[sumOther] || 0) / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumGarant]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumOtherChange = useCallback(
    (e, value) => {
      const sumWork = tableMD.columns.sumWork.name;
      const sumRasp = tableMD.columns.sumRasp.name;
      const prWork = tableMD.columns.prWork.name;
      const prCom = tableMD.columns.prCom.name;
      const prMed = tableMD.columns.prMed.name;
      const prProd = tableMD.columns.prProd.name;
      const prSoc = tableMD.columns.prSoc.name;
      const prReabil = tableMD.columns.prReabil.name;
      const prTer = tableMD.columns.prTer.name;
      const prMob = tableMD.columns.prMob.name;
      const prSec = tableMD.columns.prSec.name;
      const prBuild = tableMD.columns.prBuild.name;
      const prFort = tableMD.columns.prFort.name;
      const prDebt = tableMD.columns.prDebt.name;
      const prGarant = tableMD.columns.prGarant.name;
      const prOther = tableMD.columns.prOther.name;
      const prAdd = tableMD.columns.prAdd.name;
      const prZF = tableMD.columns.prZF.name;
      const sumCom = tableMD.columns.sumCom.name;
      const sumMed = tableMD.columns.sumMed.name;
      const sumProd = tableMD.columns.sumProd.name;
      const sumSoc = tableMD.columns.sumSoc.name;
      const sumReabil = tableMD.columns.sumReabil.name;
      const sumTer = tableMD.columns.sumTer.name;
      const sumMob = tableMD.columns.sumMob.name;
      const sumSec = tableMD.columns.sumSec.name;
      const sumBuild = tableMD.columns.sumBuild.name;
      const sumFort = tableMD.columns.sumFort.name;
      const sumDebt = tableMD.columns.sumDebt.name;
      const sumGarant = tableMD.columns.sumGarant.name;
      const sumOther = tableMD.columns.sumOther.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const sumZF = tableMD.columns.sumZF.name;

      const totsumRasp = ((row[sumRasp] || 0) - (row[sumOther] || 0)) + value;
      const totprWork = ((row[sumWork] || 0) / totsumRasp) * 100;
      const totprCom = ((row[sumCom] || 0) / totsumRasp) * 100;
      const totprMed = ((row[sumMed] || 0) / totsumRasp) * 100;
      const totprProd = ((row[sumProd] || 0) / totsumRasp) * 100;
      const totprSoc = ((row[sumSoc] || 0) / totsumRasp) * 100;
      const totprReabil = ((row[sumReabil] || 0) / totsumRasp) * 100;
      const totprTer = ((row[sumTer] || 0) / totsumRasp) * 100;
      const totprMob = ((row[sumMob] || 0) / totsumRasp) * 100;
      const totprSec = ((row[sumSec] || 0) / totsumRasp) * 100;
      const totprBuild = ((row[sumBuild] || 0) / totsumRasp) * 100;
      const totprFort = ((row[sumFort] || 0) / totsumRasp) * 100;
      const totprDebt = ((row[sumDebt] || 0) / totsumRasp) * 100;
      const totprGarant = ((row[sumGarant] || 0) / totsumRasp) * 100;
      const totprOther = (value / totsumRasp) * 100;
      const totprAdd = ((row[sumAdd] || 0) / totsumRasp) * 100;
      const totprZF = ((row[sumZF] || 0) / totsumRasp) * 100;

      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totColNameprRasp = tableMD.columns.prRasp.name;
      const totColNameprRaspAll = tableMD.columns.prRaspAll.name;
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumT = tableMD.columns.sumT.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totsumUnused = (row[ColNamesum] || 0) - totsumRasp - (row[ColNamesumRaspSubv] || 0)
        - (row[ColNamesumReturn] || 0);
      const totprRasp = (totsumRasp * 100) / ((row[ColNamesum] || 0) - (row[ColNamesumT] || 0));
      const totprRaspAll = ((totsumRasp
        + (row[ColNamesumRaspSubv] || 0)) * 100) / (row[ColNamesum] || 0);

      onRowChange(e, {
        [sumOther]: value,
        [sumRasp]: totsumRasp,
        [prWork]: totprWork,
        [prCom]: totprCom,
        [prMed]: totprMed,
        [prProd]: totprProd,
        [prSoc]: totprSoc,
        [prReabil]: totprReabil,
        [prTer]: totprTer,
        [prMob]: totprMob,
        [prSec]: totprSec,
        [prBuild]: totprBuild,
        [prFort]: totprFort,
        [prDebt]: totprDebt,
        [prGarant]: totprGarant,
        [prOther]: totprOther,
        [prAdd]: totprAdd,
        [prZF]: totprZF,
        [totColNamesumUnused]: totsumUnused,
        [totColNameprRasp]: totprRasp,
        [totColNameprRaspAll]: totprRaspAll,
      });
    },
    [onRowChange, row],
  );

  const onSumReturnChange = useCallback(
    (e, value) => {
      const ColNamesum = tableMD.columns.sum.name;
      const ColNamesumRaspSubv = tableMD.columns.sumRaspSubv.name;
      const ColNamesumRasp = tableMD.columns.sumRasp.name;
      const ColNamesumReturn = tableMD.columns.sumReturn.name;
      const totColNamesumUnused = tableMD.columns.sumUnused.name;
      const totsumUnused = (row[ColNamesum] || 0) - (row[ColNamesumRasp] || 0)
        - (row[ColNamesumRaspSubv] || 0)
        - (value || 0);

      onRowChange(e, {
        [ColNamesumReturn]: value,
        [totColNamesumUnused]: totsumUnused,
      });
    },
    [onRowChange, row],
  );

  const onSumAddChange = useCallback(
    (e, value) => {
      const sumRasp = tableMD.columns.sumRasp.name;
      const prAdd = tableMD.columns.prAdd.name;
      const sumAdd = tableMD.columns.sumAdd.name;
      const totsumRasp = row[sumRasp] || 0;
      const totprAdd = (value / totsumRasp) * 100;

      onRowChange(e, {
        [sumAdd]: value,
        [sumRasp]: totsumRasp,
        [prAdd]: totprAdd,
      });
    },
    [onRowChange, row],
  );

  const onSumZFChange = useCallback(
    (e, value) => {
      const sumRasp = tableMD.columns.sumRasp.name;
      const prZF = tableMD.columns.prZF.name;
      const sumZF = tableMD.columns.sumZF.name;
      const totsumRasp = row[sumRasp] || 0;
      const totprZF = (value / totsumRasp) * 100;

      onRowChange(e, {
        [sumZF]: value,
        [sumRasp]: totsumRasp,
        [prZF]: totprZF,
      });
    },
    [onRowChange, row],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="budget" highlighted={highlights.includes('budget')}>
              <ItemPicker
                value={row[tableMD.columns.budget.name]}
                modelType="cat"
                modelName="budgetTransfer"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.budget.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
            <TableRowCell column="sum" highlighted={highlights.includes('sum')}>
              <NumberInput
                value={row[tableMD.columns.sum.name]}
                precision={2}
                onChange={(e, v) => onSumChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumT" highlighted={highlights.includes('sumT')}>
              <NumberInput
                value={row[tableMD.columns.sumT.name]}
                precision={2}
                onChange={(e, v) => onSumTChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumRasp" highlighted={highlights.includes('sumRasp')}>
              <NumberInput
                value={row[tableMD.columns.sumRasp.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumRasp.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumWork" highlighted={highlights.includes('sumWork')}>
              <NumberInput
                value={row[tableMD.columns.sumWork.name]}
                precision={2}
                onChange={(e, v) => onSumWorkChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prWork" highlighted={highlights.includes('prWork')}>
              <NumberInput
                value={row[tableMD.columns.prWork.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prWork.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumCom" highlighted={highlights.includes('sumCom')}>
              <NumberInput
                value={row[tableMD.columns.sumCom.name]}
                precision={2}
                onChange={(e, v) => onSumComChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prCom" highlighted={highlights.includes('prCom')}>
              <NumberInput
                value={row[tableMD.columns.prCom.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prCom.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumMed" highlighted={highlights.includes('sumMed')}>
              <NumberInput
                value={row[tableMD.columns.sumMed.name]}
                precision={2}
                onChange={(e, v) => onSumMedChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prMed" highlighted={highlights.includes('prMed')}>
              <NumberInput
                value={row[tableMD.columns.prMed.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prMed.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumProd" highlighted={highlights.includes('sumProd')}>
              <NumberInput
                value={row[tableMD.columns.sumProd.name]}
                precision={2}
                onChange={(e, v) => onSumProdChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prProd" highlighted={highlights.includes('prProd')}>
              <NumberInput
                value={row[tableMD.columns.prProd.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prProd.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumSoc" highlighted={highlights.includes('sumSoc')}>
              <NumberInput
                value={row[tableMD.columns.sumSoc.name]}
                precision={2}
                onChange={(e, v) => onSumSocChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prSoc" highlighted={highlights.includes('sumSoc')}>
              <NumberInput
                value={row[tableMD.columns.prSoc.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prSoc.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumReabil" highlighted={highlights.includes('sumReabil')}>
              <NumberInput
                value={row[tableMD.columns.sumReabil.name]}
                precision={2}
                onChange={(e, v) => onSumReabilChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prReabil" highlighted={highlights.includes('sumReabil')}>
              <NumberInput
                value={row[tableMD.columns.prReabil.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prReabil.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumTer" highlighted={highlights.includes('sumTer')}>
              <NumberInput
                value={row[tableMD.columns.sumTer.name]}
                precision={2}
                onChange={(e, v) => onSumTerChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prTer" highlighted={highlights.includes('prTer')}>
              <NumberInput
                value={row[tableMD.columns.prTer.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prTer.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumMob" highlighted={highlights.includes('sumMob')}>
              <NumberInput
                value={row[tableMD.columns.sumMob.name]}
                precision={2}
                onChange={(e, v) => onSumMobChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prMob" highlighted={highlights.includes('prMob')}>
              <NumberInput
                value={row[tableMD.columns.prMob.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prMob.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumSec" highlighted={highlights.includes('sumSec')}>
              <NumberInput
                value={row[tableMD.columns.sumSec.name]}
                precision={2}
                onChange={(e, v) => onSumSecChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prSec" highlighted={highlights.includes('prSec')}>
              <NumberInput
                value={row[tableMD.columns.prSec.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prSec.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumBuild" highlighted={highlights.includes('sumBuild')}>
              <NumberInput
                value={row[tableMD.columns.sumBuild.name]}
                precision={2}
                onChange={(e, v) => onSumBuildChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prBuild" highlighted={highlights.includes('prBuild')}>
              <NumberInput
                value={row[tableMD.columns.prBuild.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prBuild.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumFort" highlighted={highlights.includes('sumFort')}>
              <NumberInput
                value={row[tableMD.columns.sumFort.name]}
                precision={2}
                onChange={(e, v) => onSumFortChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prFort" highlighted={highlights.includes('prFort')}>
              <NumberInput
                value={row[tableMD.columns.prFort.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prFort.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumDebt" highlighted={highlights.includes('sumDebt')}>
              <NumberInput
                value={row[tableMD.columns.sumDebt.name]}
                precision={2}
                onChange={(e, v) => onSumDebtChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prDebt" highlighted={highlights.includes('prDebt')}>
              <NumberInput
                value={row[tableMD.columns.prDebt.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prDebt.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumGarant" highlighted={highlights.includes('sumGarant')}>
              <NumberInput
                value={row[tableMD.columns.sumGarant.name]}
                precision={2}
                onChange={(e, v) => onSumGarantChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prGarant" highlighted={highlights.includes('prGarant')}>
              <NumberInput
                value={row[tableMD.columns.prGarant.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prGarant.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumOther" highlighted={highlights.includes('sumOther')}>
              <NumberInput
                value={row[tableMD.columns.sumOther.name]}
                precision={2}
                onChange={(e, v) => onSumOtherChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prOther" highlighted={highlights.includes('prOther')}>
              <NumberInput
                value={row[tableMD.columns.prOther.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prOther.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumRaspSubv" highlighted={highlights.includes('sumRaspSubv')}>
              <NumberInput
                value={row[tableMD.columns.sumRaspSubv.name]}
                precision={2}
                onChange={(e, v) => onSumRaspSubvChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumReturn" highlighted={highlights.includes('sumReturn')}>
              <NumberInput
                value={row[tableMD.columns.sumReturn.name]}
                precision={2}
                onChange={(e, v) => onSumReturnChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="sumUnused" highlighted={highlights.includes('sumUnused')}>
              <NumberInput
                value={row[tableMD.columns.sumUnused.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sumUnused.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="prRaspAll" highlighted={highlights.includes('prRaspAll')}>
              <NumberInput
                value={row[tableMD.columns.prRaspAll.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prRaspAll.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="prRasp" highlighted={highlights.includes('prRasp')}>
              <NumberInput
                value={row[tableMD.columns.prRasp.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prRasp.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="sumAdd" highlighted={highlights.includes('sumAdd')}>
              <NumberInput
                value={row[tableMD.columns.sumAdd.name]}
                precision={2}
                onChange={(e, v) => onSumAddChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prAdd" highlighted={highlights.includes('prAdd')}>
              <NumberInput
                value={row[tableMD.columns.prAdd.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prAdd.name]: v })}
                readOnly
              />
            </TableRowCell>
            <TableRowCell column="sumZF" highlighted={highlights.includes('sumZF')}>
              <NumberInput
                value={row[tableMD.columns.sumZF.name]}
                precision={2}
                onChange={(e, v) => onSumZFChange(e, v)}
                readOnly={readOnly}
              />
            </TableRowCell>
            <TableRowCell column="prZF" highlighted={highlights.includes('prZF')}>
              <NumberInput
                value={row[tableMD.columns.prZF.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.prZF.name]: v })}
                readOnly
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sum.name]: PropTypes.number,
    [tableMD.columns.sumT.name]: PropTypes.number,
    [tableMD.columns.sumRasp.name]: PropTypes.number,
    [tableMD.columns.sumWork.name]: PropTypes.number,
    [tableMD.columns.prWork.name]: PropTypes.number,
    [tableMD.columns.sumCom.name]: PropTypes.number,
    [tableMD.columns.prCom.name]: PropTypes.number,
    [tableMD.columns.sumMed.name]: PropTypes.number,
    [tableMD.columns.prMed.name]: PropTypes.number,
    [tableMD.columns.sumProd.name]: PropTypes.number,
    [tableMD.columns.prProd.name]: PropTypes.number,
    [tableMD.columns.sumOther.name]: PropTypes.number,
    [tableMD.columns.prOther.name]: PropTypes.number,
    [tableMD.columns.sumUnused.name]: PropTypes.number,
    [tableMD.columns.prRasp.name]: PropTypes.number,
    [tableMD.columns.prRaspAll.name]: PropTypes.number,
    [tableMD.columns.sumRaspSubv.name]: PropTypes.number,
    [tableMD.columns.budget.name]: referencePropType,
    [tableMD.columns.sumZF.name]: PropTypes.number,
    [tableMD.columns.prZF.name]: PropTypes.number,
    [tableMD.columns.sumAdd.name]: PropTypes.number,
    [tableMD.columns.prAdd.name]: PropTypes.number,
    [tableMD.columns.sumReturn.name]: PropTypes.number,
    [tableMD.columns.sumGarant.name]: PropTypes.number,
    [tableMD.columns.prGarant.name]: PropTypes.number,
    [tableMD.columns.sumDebt.name]: PropTypes.number,
    [tableMD.columns.prDebt.name]: PropTypes.number,
    [tableMD.columns.sumFort.name]: PropTypes.number,
    [tableMD.columns.prFort.name]: PropTypes.number,
    [tableMD.columns.sumBuild.name]: PropTypes.number,
    [tableMD.columns.prBuild.name]: PropTypes.number,
    [tableMD.columns.sumSec.name]: PropTypes.number,
    [tableMD.columns.prSec.name]: PropTypes.number,
    [tableMD.columns.sumMob.name]: PropTypes.number,
    [tableMD.columns.prMob.name]: PropTypes.number,
    [tableMD.columns.sumTer.name]: PropTypes.number,
    [tableMD.columns.prTer.name]: PropTypes.number,
    [tableMD.columns.sumReabil.name]: PropTypes.number,
    [tableMD.columns.prReabil.name]: PropTypes.number,
    [tableMD.columns.sumSoc.name]: PropTypes.number,
    [tableMD.columns.prSoc.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
