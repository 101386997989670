import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Alert, Overlay, Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import StampRecieved from './stampRecieved';
import StampApproved from './stampApproved';
import StampFinanced from './stampFinanced';
import AppContext from '../../../../providers/CiatAppContext';
import api from '../../../../api/req';
import { Loader } from '../../../../components/bootStrap';
import { metaBackends } from '../../../../meta';

const ApprovedDiv = styled.div.attrs(({ top }) => ({
  style: { top },
}))`
  position: absolute;
  top: -100px;
  right: 20px;
  z-index: 1020;
  width: 300px;
`;

function ApprovedInfo({
  approved, approvedText, type, url, position,
}) {
  const [items, setItems] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const { auth } = useContext(AppContext);
  const top = useMemo(
    () => {
      switch (position) {
        case 1:
          return '-100px';
        case 2:
          return '20px';
        case 3:
          return '140px';
        default:
          return '999px';
      }
    },
    [position],
  );
  useEffect(() => {
    let method = '';
    switch (type) {
      case 'recieved':
        method = 'reciece_info';
        break;
      case 'approved':
        method = 'approve_info';
        break;
      case 'financed':
        method = 'finance_info';
        break;
      default:
        method = 'unknow';
    }
    const loader = async () => {
      const r = await api.get(`${url}${method}/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    const checker = async (item) => {
      const r = await api.get(`${item.resource}${item.id}/`, auth);
      if (!r.ok) return { ...item, url: null };
      return item;
    };
    if (opened) {
      setLoading(true);
      setErr(false);
      setOpened(true);
      loader()
        .then((d) => Promise.all(d.map((dd) => checker(dd))))
        .then((d) => setItems(d.map((dd) => ({
          ...dd,
          url: dd.url ? `${metaBackends[dd.resource].frontendURL}${dd.id}` : null,
        }))))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    }
  }, [opened, url, auth, type]);
  const ref = useRef();

  const Component = useMemo(
    () => {
      if (type === 'recieved') return StampRecieved;
      if (type === 'approved') return StampApproved;
      if (type === 'financed') return StampFinanced;
      return null;
    },
    [type],
  );
  return approved && Component && (
    <>
      <ApprovedDiv ref={ref} top={top}>
        <Component text={approvedText} onClick={() => setOpened((o) => !o)} />
      </ApprovedDiv>
      <Overlay
        container={ref.current}
        target={ref.current}
        show={opened}
        onHide={() => setOpened(false)}
        placement="left"
      >
        {(props) => (
          <Popover id="approved_info_popover" {...props}>
            <Popover.Header as="h3">Документи, в яких використовується даний документ!!</Popover.Header>
            <Popover.Body>
              {loading && (
              <Loader text="Завантаження" />
              )}
              {err && (
              <Alert dismissible onClose={() => setErr(null)} variant="danger">
                <Alert.Heading>
                  {err}
                </Alert.Heading>
              </Alert>
              )}
              <ul className="list-unstyled ">
                {items.map((item) => (
                  <li key={item.id}>
                    <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
                    {item.url ? (
                      <Link to={item.url}>
                        {item.repr}
                      </Link>
                    ) : (
                      <span className="text-muted">{item.repr}</span>
                    )}
                  </li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </>
  );
}

ApprovedInfo.propTypes = {
  approved: PropTypes.bool,
  type: PropTypes.oneOf(['recieved', 'approved', 'financed']),
  approvedText: PropTypes.string,
  url: PropTypes.string.isRequired,
  position: PropTypes.oneOf([1, 2, 3]),
};

ApprovedInfo.defaultProps = {
  approved: false,
  type: 'recieved',
  approvedText: 'Клацніть щоб дізнатися більше',
  position: 1,
};

export default ApprovedInfo;
