import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = { ...defaultColumns };

export const partnerBanks = {
  label: 'Банки контрагентів',
  name: 'partnerBanks',
  backendName: 'БанкиКонтрагентов',
  defaultOrder,
  columns,
  frontend: 'cat/partnerBanks',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
    ]),
  ],
  hierarchy: false,
};

export default partnerBanks;
