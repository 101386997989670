import React from 'react';
import PropTypes from 'prop-types';
import Button from '../styledAssigmButton/assigmButton';
import HideDeletedIcon from '../../assets/icons/hideDeleted';
import ShowDeletedIcon from '../../assets/icons/showDeleted';

function CheckboxShowDeleted({
  text,
  checked,
  checkIcon,
  uncheckIcon,
  onClick,
  ...args
}) {
  return (
    <Button
      title={text}
    // icon={checked ? checkIcon : uncheckIcon}
      onClick={onClick}
      toggle
      {...args}
    >
      {checked ? <HideDeletedIcon /> : <ShowDeletedIcon />}
    </Button>
  );
}

CheckboxShowDeleted.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  checked: PropTypes.bool,
  // checkIcon: PropTypes.string,
  // uncheckIcon: PropTypes.string,
};

CheckboxShowDeleted.defaultProps = {
  checked: true,
  // checkIcon: 'checkmark box',
  // uncheckIcon: 'square outline',
  text: 'Показати видалені',
  onClick: (e) => {
    console.log('Button clicked', e.target);
  },
};

export default CheckboxShowDeleted;
