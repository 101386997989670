import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ListGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import FilterField from './filterField';
import { CloseButton } from '../../../../components/bootStrap/buttons';

const WIDTH = '340px';

const AnimatedFiltersContainer = styled.nav.attrs(({ h }) => ({
  style: {
    height: `${h}px`,
  },
}))`
  flex: 0 0 auto;
  overflow: hidden auto;
  transition: all .3s ease-out, opacity .3s ease-out;
  position: relative;
  width: ${WIDTH};
  margin-right: -${WIDTH};
  &.show {
    margin-right: 0;
  }
`;

function FilterPanel({
  filteringFields, show, filter, onSetFilter, height, onToggleFilterOpened,
}) {
  return (
    <AnimatedFiltersContainer className={classNames('ps-2 pt-1 bg-light', { show })} h={height}>
      <div className="w-100 bg-white">
        <Navbar
          className="flex-column "
        >
          <div className="mb-4">
            <CloseButton
              onClick={() => onToggleFilterOpened()}
            />
          </div>
          <ListGroup>
            {filteringFields.map((ff) => (
              <FilterField
                key={ff.name}
                desc={ff}
                value={filter[ff.name]}
                onSetFilter={onSetFilter}
              />
            ))}
          </ListGroup>

        </Navbar>
      </div>
    </AnimatedFiltersContainer>
  );
}

FilterPanel.propTypes = {
  filteringFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    resource: PropTypes.string,
  })).isRequired,
  show: PropTypes.bool.isRequired,
  filter: PropTypes.shape({}).isRequired,
  onSetFilter: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  onToggleFilterOpened: PropTypes.func.isRequired,
};

export default FilterPanel;
