import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { AddIcon } from '../../../../../assets/icons';
import {
  StyledDiv, StyledRepr, StyledItem, StyledAddButton, StyledRemoveIcon,
} from './styles';

const getValueArray = (WrappedComponent) => {
  function ValuesShower({
    value, active, defaultValue, onChange, ...rest
  }) {
    const repr = value.reduce((R, v) => [...R, <StyledRepr>{Map.isMap(v) ? v.get('repr', 'Пусте значення') : v}</StyledRepr>], []);
    if (!active) {
      return (
        <StyledDiv>
          {repr}
        </StyledDiv>
      );
    }
    return (
      <StyledDiv>
        {value.reduce((R, itemValue, k) => [...R, (
          // eslint-disable-next-line react/no-array-index-key
          <StyledItem key={k}>
            {React.createElement(
              WrappedComponent,
              {
                value: itemValue,
                onChange: (e, v) => onChange(e, value.set(k, v)),
                ...rest,
              },
            )}
            <StyledRemoveIcon
              onClick={(e) => onChange(e, value.delete(k))}
            />
          </StyledItem>
        )], [])}
        <StyledAddButton
          onClick={(e) => onChange(e, value.push(defaultValue))}
        >
          <img src={AddIcon} alt="Додати" />
          <span>Додати ще значення</span>
        </StyledAddButton>
      </StyledDiv>
    );
  }

  ValuesShower.propTypes = {
    value: PropTypes.instanceOf(List),
    active: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
      PropTypes.instanceOf(Map),
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
  };

  ValuesShower.defaultProps = {
    value: new List(),
    onChange: (e, v) => console.error('Array changed to ', v.toJS()),
    defaultValue: '',
    active: false,
  };

  return memo(ValuesShower);
};

export default getValueArray;
