import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  GenerateIcon, SettingsRepIcon, RepIcon, DelIcon,
} from '../../assets/icons';
import './variantSettings.css';
import Modal from '../../components/modal/modalPureJs';
import { goToOldVersion } from '../../actions/reportEditor';
import DefaultCPButton from '../../components/bootstrap_components/controls/defaultButton';

function VariantPanel({
  deleteVariant = () => null,
  generateReport = () => null,
  isProcessing,
  Settings,
  reportVariants,
  generateVariant,
  Header,
}) {
  const [modalSettings, setModalSettings] = useState(false);
  const dispatch = useDispatch();
  const onClose = () => setModalSettings(false);

  return (
    <div style={{ position: 'relative' }}>
      {/* <span className="variant__text-save">Збережені варіанти</span> */}
      <div className="variant_panel">
        <div className="variant">

          <div className="variant__report-gen">
            <div className="variant__rep-gen-img">
              <img src={GenerateIcon} alt="Звіт" width="40" height="40" />
            </div>

            <button
              className="variant__button"
              type="button"
              onClick={generateReport}
            >
              <span className="variant__text text-gen">
                Сформувати за поточними налаштуваннями
              </span>
            </button>
          </div>

          <div className="variant__report-set">
            <div className="variant__rep-set-img">
              <img src={SettingsRepIcon} alt="Звіт" width="40" height="40" />
            </div>
            <button
              className="variant__button"
              type="button"
              disabled={isProcessing}
              onClick={(e) => setModalSettings(true)}
            >
              <span className="variant__text text-set">
                Налаштування
              </span>
            </button>
          </div>

          {modalSettings && (
            <Modal
              header={Header}
              zIndexModal={1000}
              onClose={onClose}
              // size="middle"
            >
              {React.Children.only(Settings)}
            </Modal>
          )}

          <div className="vl" />

          {reportVariants && (reportVariants.map((el, i) => (
            <div
              className="variant"
              key={`variant__report${i}`}
              title={el.get('comment', '')}
            >
              <div className="variant__report">
                <div className="variant__rep-gen-img">
                  <img src={RepIcon} alt="Звіт" width="40" height="40" />
                </div>

                <button
                  className="variant__button"
                  type="button"
                  onClick={() => generateVariant(el.get('variant', ''))}
                >
                  <span className="variant__text">
                    {el.get('name', '')}
                  </span>
                </button>

                <button
                  className="variant__dell-button"
                  type="button"
                  onClick={() => deleteVariant(el.get('variant', ''))}
                >
                  <img src={DelIcon} alt="Видалити" width="20" height="20" />
                </button>
              </div>

            </div>
          ))
          )}
        </div>
        <div style={{ position: 'absolute', right: 0 }}>
          <DefaultCPButton
            onClick={() => {
              dispatch(goToOldVersion());
            }}
            label="До попередньої версії"
            disabled={isProcessing}
            icon={faArrowRight}
            variant="light"
            showLabel={false}
          />
        </div>
      </div>
    </div>

  );
}

VariantPanel.propTypes = {
  deleteVariant: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  Settings: PropTypes.node,
  reportVariants: PropTypes.shape(Map),
  generateVariant: PropTypes.func.isRequired,
  Header: PropTypes.node,
};

VariantPanel.defaultProps = {
  isProcessing: false,
  Settings: null,
  reportVariants: null,
  Header: null,
};

export default VariantPanel;
