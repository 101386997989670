import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { CheckboxInput } from '../../../../components/bootstrap_components/controls';

import md from '../../../../constants/meta/documents/forecastAdd7';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from './docSelectionModal';
import { CommandPanelColor, ContainerSt } from '../../../../components/Form/styledForm';
import { StampApprovedJs } from '../../../../assets/icons';

function ForecastAdd7({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = !permissions.canChange;

  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LoadDocs', loadData);
    },
    [actions],
  );

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            FI={data[md.columns.FI.name]}
            readOnly={readOnly}
          />
        </Col>
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.StringInput
              label={(
                <CheckboxInput
                  value={!!data[md.columns.isApproved.name]}
                  label={md.columns.isApproved.label}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.isApproved.name]: value,
                  })}
                  id={md.columns.isApproved.name}
                />
              )}
              value={data[md.columns.invoice.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.invoice.name]: value,
              })}
              disabled={!data[md.columns.isApproved.name]}
            />
          </Col>
        )}
        {sessionOptions.get('is_admin', false) && (
          <Col>
            <EditorControls.StringInput
              label={(
                <CheckboxInput
                  value={!!data[md.columns.isApproved7.name]}
                  label={md.columns.isApproved7.label}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.isApproved7.name]: value,
                  })}
                  id={md.columns.isApproved7.name}
                />
              )}
              value={data[md.columns.invoice7.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.invoice7.name]: value,
              })}
              disabled={!data[md.columns.isApproved7.name]}
            />
          </Col>
        )}
        {data[md.columns.isApproved.name] && (
          <ContainerSt>
            <StampApprovedJs text={data[md.columns.invoice.name]} />
          </ContainerSt>
        )}

        {data[md.columns.isApproved7.name] && (
          <ContainerSt>
            <StampApprovedJs text={data[md.columns.invoice7.name]} />
          </ContainerSt>
        )}

      </Row>
      <Row>
        <Col>
          <CommandPanelColor
            label="Отримати дані з бюжетних запитів"
            onClick={() => actions.onSR('FILL_FROM_BZ')}
          />
        </Col>
      </Row>
    </EditorCollapse>

  );
}

ForecastAdd7.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.year.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ForecastAdd7;
