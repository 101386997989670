import React from 'react';
import PropTypes from 'prop-types';
import { DivColumnHeader } from './styles';
import { directions } from '../../../constants/meta/common';
import { SortUpIcon, SortDownIcon } from '../../../assets/icons';

function ColumnHeader({
  label, children, onClick, sorted,
}) {
  return (
    <DivColumnHeader onClick={onClick} style={{ display: 'flex' }}>
      {label}
      {sorted === directions.ascending && (
      <SortUpIcon />
      )}
      {sorted === directions.descending && (
      <SortDownIcon />
      )}
      {children}
    </DivColumnHeader>
  );
}

ColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  sorted: PropTypes.oneOf([...Object.values(directions), 'NONE']),
  onClick: PropTypes.func,
};

ColumnHeader.defaultProps = {
  children: null,
  sorted: 'NONE',
  onClick: () => null,
};

export default ColumnHeader;
