import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { mapState } from '../../reportEditor';
import {
  changeField, fillTable, processServerReq,
} from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/treasureReports';
import {
  StyledLabel,
  StyledPane,
  CommandPanelColor,
  DivWithPropsLabel,
  MyContainerFormWithNColumns,
} from '../../../components/Form/styledForm';

import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';

import ReportContainer from '../reportContainer/variantReportContainer';

import DateRangeField, { StyledDiv } from '../../../components/fields/DateRangeField';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import Tabs from '../../../components/tabs/Tabs';
import DateInput from '../../../components/fields/dates';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import { ContainerButton } from '../../documents/financingCashbox/tablepart/cpDocs';
import { CommandPanelButton } from '../../../components/button';
import { CheckAllIcon, UnChekAllIcon } from '../../../assets/icons';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import TpDocTP from './tablepart/tpDoc';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import { reportEditor as actions } from '../../../actions';
import meta from '../../../constants/meta';

const ResultSegment = styled.div`
  border: 1px solid #cbcbe4;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 100vh;
  _overflow: auto;
`;

const ContainerDiv = styled(StyledDiv)`
  margin-top: 10px;
`;
const ColorContainer = styled.div`
  background: #f5f3f3;
  padding: 10px;
  border-radius: 4px;
  margin: 15px 0;
`;

const StringInput4 = Fields.GetTextInput(4);
const StringInput10 = Fields.GetTextInput(10);

const ReportTypes = {
  plan: { label: 'Розпис', value: 0 },
  update: { label: 'Уточнення', value: 1 },
  all: { label: 'Розпис+уточнення', value: 2 },
};
const codeInNameTypes = {
  none: { label: 'Немає', value: 0 },
  csu: { label: 'Гол.розпорядник', value: 1 },
  sub: { label: 'Установа', value: 2 },
};

const TranscriptKEKV = {
  TranscriptKEKV1: { label: 'Без розшифровки', value: 0 },
  TranscriptKEKV2: { label: 'Задіяні коди', value: 1 },
  TranscriptKEKV3: { label: '+Тільки 5000', value: 2 },
};

const assignTypes = {
  TranscriptKEKV1: { label: 'Асигнування', value: 2 },
  TranscriptKEKV2: { label: 'Надання кредитів', value: 3 },
  TranscriptKEKV3: { label: 'Повернення кредитів', value: 4 },
};

const PlanEst = {
  PlanEst1: { label: 'Рестр змін розподілу показників зведених планів асигнувань (Дод.15)', value: 1 },
  PlanEst2: { label: 'Розподіл показників зведених планів асигнувань (Дод.10)', value: 2 },
  PlanEst3: { label: 'Реєстр змін показників зведених кошторисів (Дод.14)', value: 3 },
  PlanEst4: { label: 'Розподіл показників зведених кошторисів (Дод.9)', value: 4 },
  PlanEst5: { label: 'Витяг з помісячного розпису асигнувань (Дод.7)', value: 5 },
  PlanEst7: { label: 'Розподіл показників зведених планів використання (Дод.11)', value: 7 },
  PlanEst6: { label: 'Витяг з річного розпису асигнувань місцевих бюджетів (Дод.6)', value: 6 },
  PlanEst8: { label: 'Розподіл показників зведених помісячних планів використання (Дод.12)', value: 8 },
};

const ShowKFKAs = {
  ShowKFKAs1: { label: 'КПКВ', value: false },
  ShowKFKAs2: { label: 'Деталізація КПКВ', value: true },
};

const ShowCSUAs = {
  ShowCSUAs1: { label: 'Розпорядник', value: false },
  ShowCSUAs2: { label: 'КВК', value: true },
};

function MarckingRow() {
  const dispatch = useDispatch();
  return (
    <ContainerButton>
      <CommandPanelButton
        text="Відмітити всі"
        onClick={() => dispatch(fillTable('tpDoc', ['mark'], true))}
      >
        <CheckAllIcon />
      </CommandPanelButton>
      <CommandPanelButton
        text="Зняти відмітки"
        onClick={() => dispatch(fillTable('tpDoc', ['mark'], false))}
      >
        <UnChekAllIcon />
      </CommandPanelButton>
    </ContainerButton>
  );
}

const TpDocTPRenderer = getTPContainer(TpDocTP, getResizableCP(
  <MarckingRow />,
));

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'TreasureReports';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const SettingsButton = (
    <div>
      <Tabs>
        <DivWithPropsLabel label="Загальні налаштування">
          <StyledPane>
            <MyContainerFormWithNColumns columns={2}>
              <div>
                <StyledLabel>{md.columns.budget.label}</StyledLabel>
                {isAdmin ? (
                  <Fields.SelectorField
                    value={headerForm.get('budget', new Map())}
                    modelType="cat"
                    modelName="budget"
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
                  />
                ) : (
                  <Fields.ItemField
                    value={headerForm.get('budget', new Map())}
                  />
                )}
              </div>
              <div>
                <StyledLabel>{md.columns.FO.label}</StyledLabel>
                {isAdmin ? (
                  <Fields.SelectorField
                    value={headerForm.get('FO', new Map())}
                    modelType="cat"
                    modelName="csu"
                    onChange={(e, v) => {
                      dispatch(changeField(['headerForm', 'FO'], v));
                      dispatch(changeField(['headerForm', 'signVariant'], new Map()));
                    }}
                  />
                ) : (
                  <Fields.ItemField
                    value={headerForm.get('FO', new Map())}
                  />
                )}
              </div>
            </MyContainerFormWithNColumns>
            <ColorContainer>
              <MyContainerFormWithNColumns columns={5}>
                <Fields.CheckboxField
                  value={headerForm.get('consolidated', false)}
                  label={md.columns.consolidated.label}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
                />
                <Fields.CheckboxField
                  value={headerForm.get('selectedDocuments', false)}
                  label={md.columns.selectedDocuments.label}
                  onChange={(e, v) => dispatch(changeField(['headerForm', 'selectedDocuments'], v))}
                />
              </MyContainerFormWithNColumns>
              <MyContainerFormWithNColumns columns={4}>
                <div>
                  <StyledLabel>{md.columns.organisation.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('organisation', new Map())}
                    modelType="cat"
                    modelName="csu"
                    canCleared
                    onChange={(e, v) => {
                      dispatch(changeField(['headerForm', 'organisation'], v));
                    }}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.symbol0.label}</StyledLabel>
                  <StringInput4
                    radius
                    background
                    value={headerForm.get('symbol0', '')}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'symbol0'], v))}
                  />
                </div>
                {/* <Fields.CheckboxField */}
                {/*  value={headerForm.get('byPCM', true)} */}
                {/*  label={md.columns.byPCM.label} */}
                {/*  onChange={(e, v) => dispatch(changeField(['headerForm', 'byPCM'], v))} */}
                {/* /> */}
                <div>
                  <StyledLabel>{md.columns.printingDate.label}</StyledLabel>
                  <DateInput
                    value={headerForm.get('printingDate', null)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'printingDate'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.NumOfRegister.label}</StyledLabel>
                  <StringInput10
                    radius
                    background
                    value={headerForm.get('NumOfRegister', '')}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'NumOfRegister'], v))}
                  />
                </div>
              </MyContainerFormWithNColumns>
              <MyContainerFormWithNColumns style={{ alignItems: 'start' }} columns={6}>
                <div>
                  <StyledLabel>{md.columns.reportType.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('reportType', 0)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
                    values={Object.values(ReportTypes)}
                    name="reportType"
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.planType.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('planType', false)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
                    values={Object.values(enums.PlaningKind).map((_e) => (
                      { label: _e.label, value: _e.name }))}
                    name="planType"
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.showCSUAs.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('showCSUAs', false)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'showCSUAs'], v))}
                    values={Object.values(ShowCSUAs)}
                    name="showCSUAs"
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.showKFKAs.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('showKFKAs', false)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'showKFKAs'], v))}
                    values={Object.values(ShowKFKAs)}
                    name="showKFKAs"
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.transcriptKEKV.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('transcriptKEKV', 0)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'transcriptKEKV'], v))}
                    values={Object.values(TranscriptKEKV)}
                    name="transcriptKEKV"
                  />
                </div>
              </MyContainerFormWithNColumns>
              <div>
                <StyledLabel>{md.columns.planEst.label}</StyledLabel>
                <MyContainerFormWithNColumns columns={2} style={{ gridRowGap: '0px' }}>
                  <RadioButton
                    value={headerForm.get('planEst', 1)}
                    onChange={(e, v) => {
                      dispatch(changeField(['headerForm', 'planEst'], v));
                      if (v === 5 || v === 6) {
                        dispatch(changeField(['headerForm', 'isShowKBP7'], true));
                        dispatch(changeField(['headerForm', 'isShowKDB'], true));
                        dispatch(changeField(['headerForm', 'isShowKFB'], true));
                      } else {
                        dispatch(changeField(['headerForm', 'isShowKBP7'], false));
                        dispatch(changeField(['headerForm', 'isShowKDB'], false));
                        dispatch(changeField(['headerForm', 'isShowKFB'], false));
                      }
                    }}
                    values={Object.values(PlanEst)}
                    name="planEst"
                  />
                </MyContainerFormWithNColumns>
              </div>
              <MyContainerFormWithNColumns columns={4}>
                <div>
                  <StyledLabel>{md.columns.fund.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('fund', new Map())}
                    modelType="cat"
                    modelName="fondClassifier"
                    canCleared
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'fund'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.elementFond.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('elementFond', new Map())}
                    modelType="cat"
                    modelName="elementFond"
                    canCleared
                    filter={[{ fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'elementFond'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.kbp7Classifier.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('kbp7Classifier', new Map())}
                    modelType="cat"
                    modelName="kbp7Classifier"
                    canCleared
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'kbp7Classifier'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.elementKFK.label}</StyledLabel>
                  <Fields.SelectorField
                    value={headerForm.get('elementKFK', new Map())}
                    modelType="cat"
                    modelName="elementKFK"
                    canCleared
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'elementKFK'], v))}
                  />
                </div>
              </MyContainerFormWithNColumns>
              <MyContainerFormWithNColumns columns={4}>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('budgetTL_TERRA', false)}
                    label={md.columns.budgetTL_TERRA.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'budgetTL_TERRA'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('byReducedKEKV', false)}
                    label={md.columns.byReducedKEKV.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'byReducedKEKV'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('byGroupsKEKV', false)}
                    label={md.columns.byGroupsKEKV.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'byGroupsKEKV'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('byAllKEKV', false)}
                    label={md.columns.byAllKEKV.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'byAllKEKV'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('in1000', false)}
                    label={md.columns.in1000.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('KOATUU', false)}
                    label={md.columns.KOATUU.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'KOATUU'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('noZero', false)}
                    label={md.columns.noZero.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'noZero'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('planOfUse', false)}
                    label={md.columns.planOfUse.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'planOfUse'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('printingName', false)}
                    label={md.columns.printingName.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'printingName'], v))}
                  />
                </div>
                <div>
                  {(headerForm.get('planEst', 1) === 5 || headerForm.get('planEst', 1) === 6) && (
                    <div>
                      <Fields.CheckboxField
                        value={headerForm.get('isShowKBP7', false)}
                        label={md.columns.isShowKBP7.label}
                        onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowKBP7'], v))}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('isShowKDB', false)}
                        label={md.columns.isShowKDB.label}
                        onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowKDB'], v))}
                      />
                      <Fields.CheckboxField
                        value={headerForm.get('isShowKFB', false)}
                        label={md.columns.isShowKFB.label}
                        onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowKFB'], v))}
                      />
                    </div>
                  )}
                </div>
              </MyContainerFormWithNColumns>
            </ColorContainer>
          </StyledPane>
        </DivWithPropsLabel>
        <DivWithPropsLabel label="Налаштування для вивантаження у DBF">
          <StyledPane>
            <ColorContainer>
              {/* <Title style={{ margin: '15px 0 20px ' }}> */}
              {/* Налаштування для вивантаження до файлу у DBF форматі */}
              {/* </Title> */}
              <MyContainerFormWithNColumns columns={3}>
                <div>
                  <StyledLabel>{md.columns.assign.label}</StyledLabel>
                  <RadioButton
                    value={headerForm.get('assign', false)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'assign'], v))}
                    values={Object.values(assignTypes)}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.NR.label}</StyledLabel>
                  <StringInput4
                    border
                    radius
                    background
                    value={headerForm.get('NR', 0)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'NR'], v))}
                  />
                </div>
                <div>
                  <StyledLabel>{md.columns.NOM.label}</StyledLabel>
                  <StringInput10
                    radius
                    background
                    value={headerForm.get('NOM', '')}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'NOM'], v))}
                  />
                </div>
              </MyContainerFormWithNColumns>
              <MyContainerFormWithNColumns columns={4}>
                <div>
                  <StyledLabel>Код розпорядника в імені файла </StyledLabel>
                  <RadioButton
                    value={headerForm.get('codeInName', false)}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'codeInName'], v))}
                    values={Object.values(codeInNameTypes)}
                    name="codeInName"
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('nameFileChange', false)}
                    label={md.columns.nameFileChange.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'nameFileChange'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('flDNF', false)}
                    label={md.columns.flDNF.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'flDNF'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('UploadAsChanges', false)}
                    label={md.columns.UploadAsChanges.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'UploadAsChanges'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('NoYearSummIfZero', false)}
                    label={md.columns.NoYearSummIfZero.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'NoYearSummIfZero'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('onlyYear', false)}
                    label={md.columns.onlyYear.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'onlyYear'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('PCM', false)}
                    label={md.columns.PCM.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'PCM'], v))}
                  />
                </div>
                <div>
                  <Fields.CheckboxField
                    value={headerForm.get('inHeadCSU', false)}
                    label={md.columns.inHeadCSU.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'inHeadCSU'], v))}
                  />
                  <Fields.CheckboxField
                    value={headerForm.get('NoYearSumm', false)}
                    label={md.columns.NoYearSumm.label}
                    onChange={(e, v) => dispatch(changeField(['headerForm', 'NoYearSumm'], v))}
                  />
                </div>
              </MyContainerFormWithNColumns>
              <MyContainerFormWithNColumns columns={1}>
                <div>
                  <CommandPanelColor
                    style={{ marginTop: '10px' }}
                    text="Отримати документи"
                    label="Отримати документи"
                    onClick={() => dispatch(processServerReq('UPDATE'))}
                  />
                </div>
                <div>
                  <CommandPanelColor
                    style={{ marginTop: '10px' }}
                    text="Натисніть щоб вивантажити дані у файл"
                    label="Вивантажити у файл"
                    onClick={() => {
                      dispatch(actions.getFile((store) => {
                        store.get('visibleItems', new OrderedMap()).filter((i) => i.get('ACTIVE', false));

                        return {
                          method: 'DBF',
                        };
                      }));
                    }}
                    // onClick={() => {
                    //   dispatch(actions.processServerReq('CreateDBF'));
                    // }}
                  />
                </div>
              </MyContainerFormWithNColumns>
            </ColorContainer>
            <TpDocTPRenderer />
          </StyledPane>
        </DivWithPropsLabel>
      </Tabs>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </ContainerDiv>
      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica (для Додатку 6,7)"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
        />
      </div>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
