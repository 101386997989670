import md from '../../../../../meta/doc/netCategory';
import ListerFactory from '../../../../newLister';
// eslint-disable-next-line import/no-cycle
import { DocTableListerFactory } from '../../../../newLister/tableComponent';
import NetCategoryCommandPanel from './cp';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(DocTableListerFactory({
  CommandPanel: NetCategoryCommandPanel,
}));

export default StdLister;
