import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';
// TODO: @marina Надо сделать картинку
import { ReplaceThisIcon as UnExecuteIcon } from '../../../icons';

export function UnExecuteButton({ content, onClick, disabled }) {
  return (
    <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
      <StyledImg style={{ width: '20px' }} src={UnExecuteIcon} alt={content} />
      <StyledText>{content}</StyledText>
    </StyledCommandPanelButton>
  );
}

UnExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

UnExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Відмінити проведення',
  disabled: false,
};

export default UnExecuteButton;
