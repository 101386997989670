import React, { forwardRef, useContext, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import AssigmPrintModal from './listerPrintModal';

import ListerContext from '../../../newLister/context';

import YouTubeButtom from '../../../newLister/tableComponent/commandpanel/youtube/youtubeButton';
import YoutubeVideo from '../../../newLister/tableComponent/commandpanel/youtube/youtubeVideo';
import meta from '../../../../constants/meta/documents/prepareForecastAdd1';
import SendToLogicaButton from '../../../Logica/sendToLogicaButton';
import { modelName, modelType } from '../def';

const videoId = meta.columns.refYoutube && meta.columns.refYoutube.value;

const CommandPanel = forwardRef((_, ref) => {
  const {
    selectedRows,
  } = useContext(ListerContext);

  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <DocListerCommandPanel
        ref={ref}
      >
        <ButtonGroup>

          <AssigmPrintModal selectedRows={selectedRows} disabled={!selectedRows.length} />

        </ButtonGroup>
        <ButtonGroup>
          <SendToLogicaButton
            modelType={modelType}
            modelName={modelName}
          />
        </ButtonGroup>
        <YouTubeButtom
          onClick={() => setShowVideo(!showVideo)}
          videoId={videoId}
        />
      </DocListerCommandPanel>

      <YoutubeVideo
        showVideo={showVideo}
        videoId={videoId}
      />
    </>

  );
});
export default CommandPanel;
