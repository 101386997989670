import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge, Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TotalCell = styled(TabelRowCell)`
  padding: 6px;
  text-align: right;
  font-weight: bold;
  color: var(--colorTextActiveRow);
`;

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authorityId, budgetId,
  disposerInHeader,
}) {
  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const disposerFilter = useMemo(
    () => ({ parent: authorityId }),
    [authorityId],
  );

  const kbp7ItemProps = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const kbp7ItemFilter = useMemo(
    () => ({ owner: authorityId }),
    [authorityId],
  );

  const targetProgramProps = tableHooks.useItemInputProps(
    'target_programm',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const targetProgramFilter = useMemo(
    () => ({ owner: budgetId }),
    [budgetId],
  );

  const zfProps = tableHooks.useNumberInputProps(
    'zf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const sfProps = tableHooks.useNumberInputProps(
    'sf',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const devBudgetProps = tableHooks.useNumberInputProps(
    'dev_budget',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const noteProps = tableHooks.useStringInputProps(
    'note',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  return (
    <>

      <Col className="gx-2" md={6}>
        <Row className="gx-0">
          {!disposerInHeader && (
          <Col className="gx-1" md={12}>
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposerProps}
                filter={disposerFilter}
                noHierarchy
                canErase={false}
              />
            </TabelRowCell>
          </Col>
          )}
        </Row>
        <Row className="gx-0">
          <Col className="gx-1" md={12}>
            <TabelRowCell column="target_programm" highlighted={highlights.includes('target_programm')}>
              <TableControls.ItemPicker
                {...targetProgramProps}
                filter={targetProgramFilter}
                canErase={false}
              />
            </TabelRowCell>
          </Col>
        </Row>
        <Row className="gx-0">
          <Col className="gx-1" md={2}>
            <TabelRowCell column="kbp" highlighted={highlights.includes('kbp')}>
              <Badge variant="info">{row.kbp7 && row.kbp7.repr}</Badge>
            </TabelRowCell>
          </Col>
          <Col className="gx-1" md={10}>
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker
                {...kbp7ItemProps}
                filter={kbp7ItemFilter}
                canErase={false}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col className="gx-2" md={6}>
        <Row>
          <Col className="gx-1" md={8}>
            <TabelRowCell column="zf" highlighted={highlights.includes('zf')}>
              <TableControls.NumberInput {...zfProps} />
            </TabelRowCell>
          </Col>

          <Col className="gx-1" md={4}>
            <TotalCell column="total" highlighted={highlights.includes('total')}>
              {((row.zf || 0) + (row.sf || 0)).toLocaleString('uk', moneysStingOptions)}
            </TotalCell>
          </Col>
        </Row>
        <Row>

          <Col className="gx-1" md={4}>
            <TabelRowCell column="sf" highlighted={highlights.includes('sf')}>
              <TableControls.NumberInput {...sfProps} />
            </TabelRowCell>
          </Col>
          <Col className="gx-1" md={4}>
            <TabelRowCell column="dev_budget" highlighted={highlights.includes('dev_budget')}>
              <TableControls.NumberInput {...devBudgetProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="gx-2">
          <Col className="gx-1" md={12}>
            <TabelRowCell column="note" highlighted={highlights.includes('note')}>
              <TableControls.TextInput {...noteProps} rows={1} />
            </TabelRowCell>
          </Col>

        </Row>
      </Col>
    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  //
  authorityId: PropTypes.number,
  budgetId: PropTypes.number,
  disposerInHeader: PropTypes.bool,
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
  //
  authorityId: null,
  budgetId: null,
  disposerInHeader: false,
};

export default memo(TRow);
