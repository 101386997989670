import { List } from 'immutable';
import frontonecMeta from './meta';
import stdLister from '../minfin/containers/instances/lister';
import stdSelector from '../minfin/containers/instances/selector';
import stdEditor from '../minfin/containers/instances/stdeditor';
import minfinReports from '../minfin/containers/reports';
import minfinServices from '../minfin/containers/services';
import minfinMeta from '../minfin/meta';

/**
 * Объекты, реализованные на minfin
 * В дальнейшем возможно нужно будет переделать на proxy-Объект
 */
const includedItems = {
  cat: {
    [frontonecMeta.cat.banks.frontend]: 'banks',
    [frontonecMeta.cat.symbolKdb.frontend]: 'symbolKdb',
    [frontonecMeta.cat.bankAccounts.frontend]: 'treasureaccount',
    [frontonecMeta.cat.baseDoc.frontend]: 'documentbasis',
    [frontonecMeta.cat.bfnAmount.frontend]: 'bfnAmount',
    [frontonecMeta.cat.bfnGoalFondAmount.frontend]: 'bfnGoalFondAmount',
    [frontonecMeta.cat.bfnGroupAge.frontend]: 'bfnGroupAge',
    [frontonecMeta.cat.bfnGroupFullness.frontend]: 'bfnGroupFullness',
    [frontonecMeta.cat.bfnGroupTypes.frontend]: 'bfnGroupTypes',
    [frontonecMeta.cat.bfnBanDoc.frontend]: 'bfnBanDoc',
    [frontonecMeta.cat.bfnGroupName.frontend]: 'bfnGroupName',
    [frontonecMeta.cat.bfnPeriod.frontend]: 'bfnPeriod',
    [frontonecMeta.cat.budget.frontend]: 'budget',
    [frontonecMeta.cat.budgetTransfer.frontend]: 'personBudget',
    [frontonecMeta.cat.csu.frontend]: 'disposers',
    [frontonecMeta.cat.docNotes.frontend]: 'documentnote',
    [frontonecMeta.cat.elementFond.frontend]: 'fonditem',
    [frontonecMeta.cat.elementKEKV.frontend]: 'kekvitem',
    [frontonecMeta.cat.elementKFK.frontend]: 'kbp7item',
    [frontonecMeta.cat.implementer.frontend]: 'executor',
    [frontonecMeta.cat.kbp7Classifier.frontend]: 'kbp7',
    [frontonecMeta.cat.noteClassifier.frontend]: 'note',
    [frontonecMeta.cat.notes.frontend]: 'remarknote',
    [frontonecMeta.cat.signatureVariants.frontend]: 'authoritysignvariant',
    [frontonecMeta.cat.templateBaseDoc.frontend]: 'templateddocumentbasis',
  },
  doc: {
    [frontonecMeta.doc.assignmentPlanning.frontend]: 'allocationplan',
    [frontonecMeta.doc.assignmentPlanningRev.frontend]: 'revenueplan',
    [frontonecMeta.doc.assignmentPlanningSource.frontend]: 'finsourceplan',
    [frontonecMeta.doc.changesToAssignmentPlanning.frontend]: 'allocationplanchanges',
    [frontonecMeta.doc.changeToAssignPlanRev.frontend]: 'revenueplanchanges',
    [frontonecMeta.doc.changeToAssignPlanSource.frontend]: 'finsourceplanchanges',
    [frontonecMeta.doc.bfnInstitutionStructure.frontend]: 'bfnInstitutionStructure',
    [frontonecMeta.doc.bfnAllocation.frontend]: 'bfnAllocation',
    [frontonecMeta.doc.expensesOnHealth.frontend]: 'healthCareExpenses',
    [frontonecMeta.doc.financingRequest.frontend]: 'finrequest',
    [frontonecMeta.doc.financing.frontend]: 'finorder',
    [frontonecMeta.doc.freeRem3Add.frontend]: 'freeRem3',
    [frontonecMeta.doc.freeRem4Add.frontend]: 'freeRem4',
    [frontonecMeta.doc.incomingMoney.frontend]: 'fundsReciept',
    [frontonecMeta.doc.net.frontend]: 'disposersMap',
    [frontonecMeta.doc.netCategory.frontend]: 'netCategory',
    [frontonecMeta.doc.netCategoryPlan.frontend]: 'netCategoryPlan',
    [frontonecMeta.doc.netFOP.frontend]: 'netFop',
    [frontonecMeta.doc.netChange.frontend]: 'disposersMapChanges',
    [frontonecMeta.doc.balancesAccounts.frontend]: 'balancesAccounts',
    [frontonecMeta.doc.liquidity.frontend]: 'liquidity',
    [frontonecMeta.doc.liquidityVer2.frontend]: 'liquidityVer2',
  },
  rep: {
    [frontonecMeta.rep.AnalysisIndicatorsSF.frontend]: 'analyzeIndicatorsSF',
    [frontonecMeta.rep.AssigmentPlaning.frontend]: 'assigmentplaning',
    [frontonecMeta.rep.BfnCommoninStitutionstructure.frontend]: 'commoninStitutionStructureBfn',
    [frontonecMeta.rep.ConsolidateFinProposal.frontend]: 'summaryOffer',
    // [frontonecMeta.rep.CommonRepPanel.frontend]: 'commonRepPanel',
    [frontonecMeta.rep.CompareLevels.frontend]: 'compareOutcomes',
    [frontonecMeta.rep.CostAnalysisPlanFact.frontend]: 'planFactOutcomes',
    [frontonecMeta.rep.DocFinAnalysis.frontend]: 'findocanalisys',
    [frontonecMeta.rep.Estimate.frontend]: 'estimate',
    [frontonecMeta.rep.FinancingAnalysis.frontend]: 'finanalisys',
    [frontonecMeta.rep.GovPlanSpend.frontend]: 'allocationDetail',
    [frontonecMeta.rep.MixIndicatorsSF.frontend]: 'reduceIndicatorsSF',
    [frontonecMeta.rep.PaymentList.frontend]: 'financingCard',
    [frontonecMeta.rep.PlanOfUse.frontend]: 'ussagePlan',
    [frontonecMeta.rep.RequestAnalysis.frontend]: 'finrequstsAnalizys',
    [frontonecMeta.rep.RequestAnalysisAdd.frontend]: 'extendedFinrequestsAnalisys',
    [frontonecMeta.rep.TreasureFilesAnalysis.frontend]: 'treasuryFileAnalyze',
    [frontonecMeta.rep.YearAssigment.frontend]: 'allocationYear',
  },
  srv: {
    [frontonecMeta.dp.exportPlanToDBF.frontend]: 'ExportChangesToLogica',
  },
};

const minfinReportsNServices = {
  'rep': minfinReports,
  'srv': minfinServices,
}

const minfin = {
  ...Object.keys(includedItems).filter((chapter) => ['cat', 'doc'].includes(chapter)).reduce((R, chapter) => ({
    ...R,
    ...Object.keys(includedItems[chapter])
      .reduce((R1, key) => ({
        ...R1,
        [key]: {
          lister: stdLister[chapter][includedItems[chapter][key]],
          selector: stdSelector[chapter][includedItems[chapter][key]],
          editor: stdEditor[chapter][includedItems[chapter][key]],
          meta: minfinMeta[chapter][includedItems[chapter][key]],
        },
      }), R),
  }), {}),
  ...Object.keys(includedItems).filter((chapter) => ['rep', 'srv'].includes(chapter)).reduce((R, chapter) => ({
    ...R,
    ...Object.keys(includedItems[chapter])
      .reduce((R1, key) => ({
        ...R1,
        [key]: {
          editor: minfinReportsNServices[chapter][includedItems[chapter][key]].Editor,
          meta: minfinMeta[chapter][includedItems[chapter][key]],
        },
      }), R),
  }), {}),
};

/**
 * Функция, которая переключает компоненты между минфиновскими и фронтонековскими
 * @param md {{
 *   frontend: string,
 *   minfinOnly: boolean,
 * }}
 * @param clsName: string - "Справочники" или "Документы" и т.д.
 * @param session {Immutable.Map}
 * @return boolean
 */
export function componentOnMinfin(md, clsName, session) {
  if (!session.get('use_minfin_auth', false)) return false;
  if (md.frontend in minfin) {
    if (md.minfinOnly) {
      return true;
    }
    return !!session
      .get('minfin_objects', new List())
      .filter((el) => el.toLowerCase() === `${clsName}.${md.backendName}`.toLowerCase()).size;
  }
  return false;
}

export default minfin;
