import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CardHeader from '../../../../../components/blanks/common/CardHeader';
import TransfertToTable from './TransfertToTable';

function Index({ data }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader title="Трансферти до загального фонду бюджету" titleTag="h6" />
      <Card.Body className="p-0">
        <TransfertToTable data={data} />
      </Card.Body>
    </Card>
  );
}

Index.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
  })),
};

Index.defaultProps = {
  data: [],
};

export default Index;
