import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/expensesOnHealth';

const meta = md.tables.TP2610;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={5} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG2S'}
              highlighted={highlights.includes('T1RXXXXG2S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG2S')}
            >
              {meta.columns.T1RXXXXG2S.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG1S'}
              highlighted={highlights.includes('T1RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG1S')}
            >
              {meta.columns.T1RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG27'}
              highlighted={highlights.includes('T1RXXXXG27')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG27')}
            >
              {meta.columns.T1RXXXXG27.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG28'}
              highlighted={highlights.includes('T1RXXXXG28')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG28')}
            >
              {meta.columns.T1RXXXXG28.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG31'}
              highlighted={highlights.includes('T1RXXXXG31')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG31')}
            >
              {meta.columns.T1RXXXXG31.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG32'}
              highlighted={highlights.includes('T1RXXXXG32')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG32')}
            >
              {meta.columns.T1RXXXXG32.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG33'}
              highlighted={highlights.includes('T1RXXXXG33')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG33')}
            >
              {meta.columns.T1RXXXXG33.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG34'}
              highlighted={highlights.includes('T1RXXXXG34')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG34')}
            >
              {meta.columns.T1RXXXXG34.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG37'}
              highlighted={highlights.includes('T1RXXXXG37')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG37')}
            >
              {meta.columns.T1RXXXXG37.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T1RXXXXG38'}
              highlighted={highlights.includes('T1RXXXXG38')}
              onToggleHighlght={() => onToggleHighlght('T1RXXXXG38')}
            >
              {meta.columns.T1RXXXXG38.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
