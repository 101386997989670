import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './styles.css';

const getTotal = (data, key) => data.reduce((acc, val) => acc + Number(val[key]), 0);

function TableRow({ item, isLast }) {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium table-column-width-25',
          {
            'border-bottom-0': isLast,
          },
        )}
      >
        <Link to="#DynamicsRevenue">{item.name}</Link>
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.fond}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.sum_day}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.sum_year}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.sum_year_last}
      </td>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast,
        })}
      >
        {item.progress}
      </td>
    </tr>
  );
}

function Revenue412Table({ data }) {
  return (
    <SimpleBarReact>
      <Table className="fs--1 mb-0">
        <thead className="bg-200 text-800">
          <tr>
            <th className="table-column-width-30">Код та назва доходу бюджету</th>
            <th className="text-center">Фонд</th>
            <th className="text-center table-column-width-15">Надійшло за день</th>
            <th className="text-center table-column-width-20">Надійшло з початаку року</th>
            <th className="text-center table-column-width-20">Надійшло за аналогічний період минулого року</th>
            <th className="text-center">%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableRow
              key={item.id}
              item={item}
              isLast={data.length - 1 === index}
            />
          ))}
        </tbody>
        <tfoot className="bg-light">
          <tr className="text-700 fw-bold">
            <th>Total</th>
            <th className="text-center">
              {getTotal(data, 'value')}
            </th>
            <th className="text-center">
              {getTotal(data, 'plan')}
            </th>
            <th className="text-center">
              {getTotal(data, 'fact')}
            </th>
            <th className="pe-x1 text-end">-</th>
          </tr>
        </tfoot>
      </Table>
    </SimpleBarReact>
  );
}

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    fond: PropTypes.string.isRequired,
    sum_day: PropTypes.number.isRequired,
    sum_year: PropTypes.number.isRequired,
    sum_year_last: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
  }),
  isLast: PropTypes.bool.isRequired,
};

TableRow.defaultProps = {
  item: {},
};

Revenue412Table.propTypes = {
  data: PropTypes.shape(TableRow.propTypes.item),
};

Revenue412Table.defaultProps = {
  data: [],
};

export default Revenue412Table;
