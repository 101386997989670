import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  isShowVariantBudget: { label: 'Показувати код бюджету', name: 'ВариантКодаБюджета' },
  isPlanLevel: { label: 'За установами', name: 'флПоУстановам' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  in1000: { label: 'у тис.грн.', name: 'ВТысГРН' },
  year: { label: 'Рік', name: 'ГодОтчета' },
  startDate: { label: 'Початок періоду', name: 'ДатаНачала' },
  endDate: { label: 'Кінець періоду', name: 'ДатаОкончания' },
  notShowCodeSCU: { label: 'Не відображати: код за мережею у назві установи', name: 'НеПоказыватьКодМережи' },
  isSortByNameCsu: { label: 'Сортувати за назвою установи', name: 'СортироватьПоИмени' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  approved: { label: 'Затверджений план', name: 'УтвержденныйПлан' },
  isShowParamReport: { label: 'Показувати параметри відбору', name: 'флПоказатьОтбор' },
  inFinDep: { label: 'ЭтоФУ', name: 'ЭтоФУ' },
  isDayShow: { label: 'Надходження за день', name: 'флПоказатьЗаДень' },
  consolidateBudget: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  startDt: { label: 'Початок періоду', name: 'НачПериода' },
  endDt: { label: 'Кінець періоду', name: 'КонПериода' },
  listDate: { label: 'Список дат', name: 'СписокДат' },
  customSettings: { label: 'індивідуальні налаштування', name: 'флИндНастройки' },
  autoCr: { label: 'Автомат.створення установ', name: 'флАвтоСоздаватьРасп' },
  KEKVShr: { label: 'Виводити скорочену класифікацію КЕКВ', name: 'флВыводитьКраткийКЕКВ' },
  ShowD: { label: 'Показати фінансування та касу за день', name: 'флПоказатьЗаДень' },
  checkNo: { label: 'Виконувати перевірку заповнення реквізитів', name: 'ВыполнятьПроверкуЗаполненияРеквизитов' },
  byLowLevel: { label: 'Рівень нижче', name: 'флУровеньНиже' },
  isShowKEKV0000: { label: 'Відображати КЕКВ з кодом "0000"', name: 'флПоказатьКЕКВ0000' },
  justCashier: { label: 'Показувати тільки касу', name: 'флПоказыватьТолькоКассу' },
};

const mainGroupFields = [
  'КВК',
  'Расп',
  'Фонд',
  'КБП',
  'КФК',
  'КЕКВ',
  'Регистратор',
  'Бюджет',
];

const definition = {
  label: 'Аналіз казначейських файлів',
  name: 'TreasureFilesAnalysis',
  columns,
  frontend: 'rep/TreasureFilesAnalysis',
  backendName: 'АнализКазначейскихФайловВеб',
  resultColumns: baseResultColumns,
  mainGroupFields,
};

export default definition;
