import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
  hierarchyTypes,
} from '../common';

const columns = {
  ...defaultColumns,
  owner: {
    label: 'Контаргент',
    name: 'Владелец',
    visible: true,
  },
  bank: {
    label: 'Банк',
    name: 'Банк',
    visible: true,
  },
  accountNumber: {
    label: 'Номер рахунку',
    name: 'НомерСчета',
    visible: true,
  },
  main: {
    label: 'Рахунок за замовчуванням',
    name: 'Основной',
    visible: true,
  },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
};

export const partnerBankAccounts = {
  label: 'Банківські рахунки контрагентів',
  name: 'partnerBankAccounts',
  backendName: 'БанковскиеСчетаКонтрагентов',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: 'descending',
    },
    ...defaultOrder,
  ],
  columns,
  frontend: 'cat/partnerBankAccounts',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'bank',
      'accountNumber',
      'main',
      'Note',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default partnerBankAccounts;
