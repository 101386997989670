import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Col, Alert, Row, Container, ButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import api from '../../../api/req';
import AppContext from '../../../providers/CiatAppContext';
import { DimableLoader } from '../../../components/bootStrap';
import { downloadFileFromJSON } from '../../../api/utils';
// eslint-disable-next-line import/no-cycle
import {
  EditorCheckboxInput, EditorControls,
  EditorDateInput, EditorItemPicker, EditorStingInput,
} from '../../basicEditor/editorControls';
import DocList from '../../instances/individual/doc/allocationplanchanges/docs';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import useServices from '../hooks';

// Після тестування і роботи якийсь час все закоментоване можна видаляти
// const kinds = [
//   { value: 0, display_name: 'Типовий + передача' },
//   { value: 1, display_name: 'Типовий' },
//   { value: 2, display_name: 'Передача' },
// ];

const variants = [
  { value: 0, display_name: 'Розпорядження' },
  { value: 1, display_name: 'Розподіл' },
  { value: 2, display_name: 'Передача коштів' },
];

function ExportFinancialsToDBFEditor({ currentItem, backendURL }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [docs, setDocs] = useState([]); // docs
  // const [fonds, setFonds] = useState([]); // docs
  const [activeDoc, setActiveDoc] = useState(null);
  const [noArchive, setNoArchive] = useState(false); // no_archive
  // const [kind, setKind] = useState(0);
  const [variant, setVariant] = useState(0);
  const [dbf373Long, setDbf373Long] = useState(false);
  const [dbf3732, setDbf3732] = useState(false);
  // поки приберу це, є підозра що вона не потрібна
  // const [asPaymentOrder, setAsPaymentOrder] = useState(false); // just for kind = 2
  const [winCode, setWinCode] = useState(false);
  const [bigNote, setBigNote] = useState(false);
  const [collapseRows, setCollapseRows] = useState(false);
  const [collapseWithoutNote, setCollapseWithoutNote] = useState(false);
  const [addDbf, setAddDbf] = useState(false);
  const [dbfDate, setDbfDate] = useState(new Date()); //
  const [upperCaseFilename, setUpperCaseFilename] = useState(false);
  const [underscoreFilename, setUnderscoreFilename] = useState(false);
  const [accountFilename, setAccountFilename] = useState(false);
  const [numberInFilename, setNumberInFilename] = useState(false);
  const [shortDisposerName, setShortDisposerName] = useState(false);
  const [budgetCodeAsKoatuu, setBudgetCodeAsKoatuu] = useState(false);
  const [buhSign, setBuhSign] = useState();
  const [directorSign, setDirectorSign] = useState();
  const [buhRank, setBuhRank] = useState('');
  const [directorRank, setDirectorRank] = useState('');
  const [authority, setAuthority] = useState();
  const [authorityReceiver, setAuthorityReceiver] = useState();
  const [nob, setNob] = useState();
  const [nazb, setNazb] = useState();
  const [nra, setNra] = useState();
  const [br, setBr] = useState(false);
  const [foId, setFoId] = useState(false);
  const [useSk, setUseSk] = useState(false);

  const { settings, onSetSettings } = useServices(backendURL, variant);
  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  // const selectedFonds = useMemo(
  //   () => fonds.filter((d) => d.use)
  //     .map((d) => d.id),
  //   [fonds],
  // );
  const selectedDocs = useMemo(
    () => docs.filter((d) => d.use)
      .map((d) => d.id),
    [docs],
  );

  const params = useMemo(
    () => {
      const p = {
        no_archive: noArchive,
        docs: selectedDocs.length ? selectedDocs : currentItem,
        begin_date: beginDate,
        end_date: endDate,
        active_doc: activeDoc,
        variant,
        dbf_373_long: dbf373Long,
        dbf_373_2: dbf3732,
        // as_payment_order: asPaymentOrder,
        win_code: winCode,
        big_note: bigNote,
        collapse_rows: collapseRows,
        collapse_without_note: collapseWithoutNote,
        add_dbf: addDbf,
        dbf_date: dbfDate,
        buh_rank: buhRank,
        director_rank: directorRank,
        upper_case_filename: upperCaseFilename,
        underscore_filename: underscoreFilename,
        account_filename: accountFilename,
        number_in_filename: numberInFilename,
        short_disposer_name: shortDisposerName,
        budget_code_as_koatuu: budgetCodeAsKoatuu,
        br,
        use_sk: useSk,
      };
      const items = {
        buh_sign: buhSign,
        director_sign: directorSign,
        authority,
        authority_receiver: authorityReceiver,
        nob,
        nazb,
        nra,
      };
      Object.keys(items).forEach((key) => {
        if (items[key] !== null && items[key] !== undefined) {
          p[key] = items[key].id;
        }
      });
      return p;
    },
    [accountFilename, activeDoc, addDbf, authority, authorityReceiver, beginDate, bigNote, br,
      budgetCodeAsKoatuu, buhRank, buhSign, collapseRows, collapseWithoutNote, currentItem, dbf3732,
      dbf373Long, dbfDate, directorRank, directorSign, endDate, nazb, noArchive, nob, nra,
      numberInFilename, selectedDocs, shortDisposerName,
      underscoreFilename, upperCaseFilename, useSk, variant, winCode],
  );
  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback((v) => {
    const loader = async () => {
      const r = await api.get(`/api/financials/export/get_initials/?documents=${v[0]}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setDbfDate(rData.dbf_date);
        setVariant(rData.variant);
        setBuhSign(rData.buh_sign);
        setBuhRank(rData.buh_rank);
        setFoId(rData.fo_id);
        setDirectorSign(rData.director_sign);
        setDirectorRank(rData.director_rank);
        setDocs(rData.docs.map((d) => ({
          id: d.id,
          repr: d.repr,
          use: true,
        })));
        // setFonds(rData.fonds.map((f) => ({
        //   id: f.id,
        //   repr: f.repr,
        //   use: f.use,
        // })));
        setDbf3732(rData.dbf_373_2);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const getDocList = useCallback(
    () => {
      const loader = async () => {
        const listParams = `authority=${foId}&begin_date=${beginDate}
      &end_date=${endDate}&executed=${true}`;
        const r = await api.get(`${backendURL}?${listParams}`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      onLoadStart();
      loader()
        .then((rData) => {
          const newDocs = [...rData.results].map((doc) => ({
            id: doc.id,
            repr: doc.repr,
            use: true,
          }));
          setDocs(newDocs);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [auth, backendURL, beginDate, endDate, foId, onError, onLoadEnd, onLoadStart],
  );

  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );
  const fillSettings = useCallback(
    () => {
      if (settings && Object.keys(settings).length > 0) {
        setUpperCaseFilename(settings.upper_case_filename);
        setBudgetCodeAsKoatuu(settings.budget_code_as_koatuu);
        setDbf373Long(settings.dbf_373_long);
        setDbf3732(settings.dbf_373_2);
        setWinCode(settings.win_code);
        setBigNote(settings.big_note);
        setCollapseRows(settings.collapse_rows);
        setCollapseWithoutNote(settings.collapse_without_note);
        setAddDbf(settings.add_dbf);
        setUpperCaseFilename(settings.upper_case_filename);
        setUnderscoreFilename(settings.underscore_filename);
        setAccountFilename(settings.account_filename);
        setNumberInFilename(settings.number_in_filename);
        setShortDisposerName(settings.short_disposer_name);
        // setBuhRank(settings.buh_rank);
        // setDirectorRank(settings.director_rank);
        setAuthority(settings.authority);
        setAuthorityReceiver(settings.authority_receiver);
        setNob(settings.nob);
        setNazb(settings.nazb);
        setNra(settings.nra);
        setBr(settings.br);
        setUseSk(settings.use_sk);
      }
    },
    [settings],
  );

  // const onToggleFond = useCallback(
  //   (fondId) => setFonds(fonds.map((d) => ({ ...d, use: d.id === fondId ? !d.use : d.use }))),
  //   [fonds],
  // );

  useEffect(
    () => {
      getInitial(currentItem);
      fillSettings();
    },
    [currentItem, fillSettings, getInitial],
  );
  useEffect(
    () => { fillSettings(); },
    [fillSettings, settings],
  );

  const handleSubmit = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get('/api/financials/export/', auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => `${R}${k}: ${d[k].join(', ')}, `, '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      loader()
        .then((r) => {
          if (noArchive) {
            const files = r.file.map((f) => downloadFileFromJSON(f.file, f.filename));
            return files;
          }
          const filename = 'Arhive.zip';
          return downloadFileFromJSON(r.file, filename);
        })
        .catch((e) => onError(e.message))
        .finally(() => {
          onLoadEnd();
          onSetSettings(params);
        });
    },
    [onLoadStart, auth, params, noArchive, onError, onLoadEnd, onSetSettings],
  );

  return (
    <DimableLoader loading={loading}>
      {err && (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </Alert>
      )}
      <Container fluid>
        <Row>
          <Col>
            <Button onClick={handleSubmit}>
              <FontAwesomeIcon
                icon={faFileExport}
                className="mr-2"
              />
              Вивантажити DBF
            </Button>
          </Col>
          <Col>
            <EditorControls.RadioInput
              label="Варіант вивантаження"
              value={variant}
              values={variants}
              onChange={(e, v) => setVariant(v)}
              className="d-flex gap-2"
              disabled
            />
          </Col>
          {/* <Col> */}
          {/*  <EditorControls.RadioInput */}
          {/*    label="Варіант документа" */}
          {/*    value={kind} */}
          {/*    values={kinds} */}
          {/*    onChange={(e, v) => setKind(v)} */}
          {/*    className="d-flex gap-2" */}
          {/*  /> */}
          {/* </Col> */}
        </Row>
        <Row>
          <Col>
            <EditorCheckboxInput
              controlId="noArchive"
              label="Не архівувати файли"
              value={noArchive}
              onChange={(e, v) => setNoArchive(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="dbf373Long"
              label="Формат файла з подовженими полями для системи Е-КАЗНА"
              value={dbf373Long}
              onChange={(e, v) => setDbf373Long(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="dbf3732"
              label="Передача коштів із ЗФ до СФ за наказом 373"
              value={dbf3732}
              onChange={(e, v) => setDbf3732(v)}
            />
          </Col>
          {/* { (variant === 2) && ( */}
          {/* <Col> */}
          {/*  <EditorCheckboxInput */}
          {/*    controlId="asPaymentOrder" */}
          {/*    label="Передача у форматі файлів платіжного доручення" */}
          {/*    value={asPaymentOrder} */}
          {/*    onChange={(e, v) => setAsPaymentOrder(v)} */}
          {/*  /> */}
          {/* </Col> */}
          {/*  )} */}
          <Col sm={4}>
            <EditorCheckboxInput
              controlId="br"
              label="Розпорядження (розподіл)  про виділення коштів спеціального фонду місцевих бюджетів,
                             які обслуговуються в установах банків (Дод.№21)"
              value={br}
              onChange={(e, v) => setBr(v)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorCheckboxInput
              controlId="winCode"
              label="Кодіровка Windows"
              value={winCode}
              onChange={(e, v) => setWinCode(v)}
            />
          </Col>
          { variant === 1 && (
          <>
            <Col>
              <EditorCheckboxInput
                controlId="bigNote"
                label="Збільшене число символів у примітці (160 знаків)"
                value={bigNote}
                onChange={(e, v) => setBigNote(v)}
              />
            </Col>
            <Col>
              <EditorCheckboxInput
                controlId="useSk"
                // Використовувати наступні значення для визначення типу фонду ,
                  // поле  SK  в   розподілі
                label="SK: 1 - загальний фонд, 2 - плата за послуги бюджетних установ, 3 - інші джерела власних надходжень, 7 - інші кошти спеціального фонду"
                value={useSk}
                onChange={(e, v) => setUseSk(v)}
              />
            </Col>
          </>
          )}
          { (variant === 0 || variant === 1) && (
          <Col>
            <EditorCheckboxInput
              controlId="collapseRows"
              label="Згортати за КБП7 та КЕКВ"
              value={variant === 2 ? false : collapseRows}
              onChange={(e, v) => setCollapseRows(v)}
              disabled={variant === 2}
            />
          </Col>
          )}
          <Col>
            <EditorCheckboxInput
              controlId="collapseWithoutNote"
              label="Не враховувати примітку при згортанні"
              value={collapseWithoutNote}
              onChange={(e, v) => setCollapseWithoutNote(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="shortDisposerName"
              label="Скорочена назва установ"
              value={shortDisposerName}
              onChange={(e, v) => setShortDisposerName(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="budgetCodeAsKoatuu"
              label="Код бюджету за КОАТУУ"
              value={budgetCodeAsKoatuu}
              onChange={(e, v) => setBudgetCodeAsKoatuu(v)}
              description="поле BUDGET як Код бюджету в файлі ДБФ"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorDateInput
              controlId="dbf_date"
              label="Дата файлу"
              value={dbfDate}
              onChange={(e, v) => setDbfDate(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="addDbf"
              label="Проставляти у файлі розширення &quot;DBF&quot;"
              value={addDbf}
              onChange={(e, v) => setAddDbf(v)}
            />
          </Col>
          {variant === 1 && (
          <>
            <Col>
              <EditorCheckboxInput
                controlId="upperCaseFilename"
                label="Великі букви для розподілу"
                value={upperCaseFilename}
                onChange={(e, v) => setUpperCaseFilename(v)}
              />
            </Col>
            <Col>
              <EditorCheckboxInput
                controlId="underscoreFilename"
                label="Символ &quot;_&quot; замість &quot;.&quot; в імені файла"
                value={underscoreFilename}
                onChange={(e, v) => setUnderscoreFilename(v)}
              />
            </Col>
          </>
          )}
          <Col>
            <EditorCheckboxInput
              controlId="accountFilename"
              label="Рахунок в імені папки"
              value={accountFilename}
              onChange={(e, v) => setAccountFilename(v)}
            />
          </Col>
          <Col>
            <EditorCheckboxInput
              controlId="numberInFilename"
              label="Вказувати номер документу в розширенні файлу"
              value={numberInFilename}
              onChange={(e, v) => setNumberInFilename(v)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            Додаткова інформація для запису у файл (заповнюється виключно за потребою користувача,
            у випадку необхідності запису у файл нестандартних значень)
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorStingInput
              value={buhRank}
              label="Посада фінансиста"
              onChange={(e, v) => setBuhRank(v)}
              required
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={buhSign}
              onChange={(e, v) => setBuhSign(v)}
              backend="/api/references/refexecutive/"
              modelName="executive"
              label="Фінансист(з фін. органу)"
              modelType="cat"
            />
          </Col>
          <Col>
            <EditorStingInput
              value={directorRank}
              label="Посада керівника"
              required
              onChange={(e, v) => setDirectorRank(v)}
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={directorSign}
              onChange={(e, v) => setDirectorSign(v)}
              backend="/api/references/refexecutive/"
              modelName="executive"
              label="Керівник(з фін. органу)"
              modelType="cat"
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={authority}
              onChange={(e, v) => setAuthority(v)}
              backend="/api/references/refdisposer/"
              modelName="disposers"
              label="Установа що надає фінансування"
              modelType="cat"
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={authorityReceiver}
              onChange={(e, v) => setAuthorityReceiver(v)}
              backend="/api/references/refdisposer/"
              modelName="disposers"
              label="Установа отримувач коштів"
              modelType="cat"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorItemPicker
              value={nob}
              onChange={(e, v) => setNob(v)}
              backend="/api/references/reftreasurebank/"
              modelName="banks"
              label="Назва гол упр ДКСУ (поле NOB)"
              modelType="cat"
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={nazb}
              onChange={(e, v) => setNazb(v)}
              backend="/api/references/reftreasurebank/"
              modelName="banks"
              label="Назва респ  упр ДКСУ (поле  NAZB)"
              modelType="cat"
            />
          </Col>
          <Col>
            <EditorItemPicker
              value={nra}
              onChange={(e, v) => setNra(v)}
              backend="/api/references/reftreasurebank/"
              modelName="banks"
              label="Назва рай упр ДКСУ (поле NRA)"
              modelType="cat"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <EditorDateInput
              controlId="begin_date"
              label="Відібрати документи за період з"
              required
              value={beginDate}
              onChange={(e, v) => setBeginDate(v)}
            />
          </Col>
          <Col>
            <EditorDateInput
              controlId="end_date"
              label="по"
              required
              value={endDate}
              onChange={(e, v) => setEndDate(v)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup>
              <CPButton
                onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                title="Обрати всі"
                icon={faCheckSquare}
              />
              <CPButton
                onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                title="Зняти відмітки"
                icon={faSquare}
              />
            </ButtonGroup>
            <Button
              variant="success"
              onClick={() => getDocList()}
            >
              Відібрати документи
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DocList
              docs={docs}
              onSelectDoc={setActiveDoc}
              onToggleDoc={onToggleDoc}
              activeDoc={activeDoc}
            />
          </Col>
          {/* <Col> */}
          {/*  <FondList */}
          {/*    fonds={fonds} */}
          {/*    onToggleFond={onToggleFond} */}
          {/*  /> */}
          {/* </Col> */}
        </Row>
      </Container>
    </DimableLoader>
  );
}

ExportFinancialsToDBFEditor.propTypes = {
  currentItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  backendURL: PropTypes.string.isRequired,
};

export default ExportFinancialsToDBFEditor;
