const frontendURL = '/cat/budgetTransfer/';
const backendURL = '/api/references/refpersonbudget/';
const name = 'Довідник бюджетів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
