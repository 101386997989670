import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { changeField } from '../../../../../actions/dpEditor';
import Fields from '../../../../field';
import { DateField } from '../../../../../components/fields';
import GridTemplate from './GridTemplate';
import { DivCell, DivCheck } from '../../../../../components/styledTable/styledTable';

const TABLENAME = 'docsTable';

const SumInput = Fields.GetNumberInput(13, 2);
const TextInput9 = Fields.GetTextInput(9);

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridTemplate>
        <DivCheck areaName="check">
          <Fields.CheckboxField
            value={row.get('Check', false)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'Check'], v))}
          />
        </DivCheck>
        <DivCell areaName="docNo">
          <TextInput9
            noBorder
            readOnly
            disabled
            value={row.get('DocNo', '')}
          />
        </DivCell>
        <DivCell areaName="date">
          <DateField
            noBorder
            noBackground
            readOnly
            rows={1}
            disabled
            value={row.get('Date', 0)}
          />
        </DivCell>
        <DivCell areaName="sum">
          <SumInput
            disabled
            value={row.get('Sum', 0)}
          />
        </DivCell>
        <DivCell areaName="author">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('Autor', new Map())}
          />
        </DivCell>
      </GridTemplate>
    );
  }
}

export default RowComponent;
