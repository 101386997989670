import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { ItemPicker } from '../../../../../../components/bootstrap_components/controls/itemField/itemPicker';
import md from '../../../../../../constants/meta/catalogs/objectsBuilding';

const tablename = 'addressList';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col className="border-right">
        <TableRowCell column="Districts" highlighted={highlights.includes('Districts')}>
          <ItemPicker
            value={row[tableMD.columns.Districts.name]}
            modelType="cat"
            modelName="districts"
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.Districts.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="Street" highlighted={highlights.includes('Street')}>
          <ItemPicker
            value={row[tableMD.columns.Street.name]}
            modelType="cat"
            modelName="streets"
            filter={[{ fieldName: 'Владелец', value: row[tableMD.columns.Districts.name] }]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.Street.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
      <Col className="border-right">
        <TableRowCell column="Houses" highlighted={highlights.includes('Houses')}>
          <ItemPicker
            value={row[tableMD.columns.Houses.name]}
            modelType="cat"
            modelName="houses"
            filter={[{ fieldName: 'Владелец', value: row[tableMD.columns.Street.name] }]}
            onChange={(e, v) => onRowChange(e, { [tableMD.columns.Houses.name]: v })}
            readOnly={readOnly}
          />
        </TableRowCell>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    // [tableMD.columns.kekvClassifier.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
