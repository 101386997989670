import React, {
  useRef, useCallback, useState, useMemo, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Map, List } from 'immutable';
import { createGlobalStyle } from 'styled-components';
import {
  StyledContainer, StyledContainer2, PrintableArea,
  ContainerButtonBottom, ContainerSave, ButtonIcon, TextSave,
} from './styles';
import Table from './table';
import { CommandPanelButton } from '../../components/button';
import {
  PrintIcon, EditIcon, FinishEditingIcon, FixAreaIcon, ChangeModeIcon,
  UnFixAreaIcon, XLSColorIcon, PDFColorIcon, DOCXColorIcon, HTMLColorIcon,
} from '../../assets/icons';
import { InputWithDropdown } from '../../components/styledInputs';
import { reportToFile } from '../../actions/reportEditor';
import { processServerReq } from '../../actions/editor';
import { dpEditor as actions } from '../../actions';
import { useDebounce } from '../../utils/debounce';

const HTMLStyle = createGlobalStyle`
  html{
    font-size:   ${({ screenSize }) => screenSize}px;
  }
  @media print {
    html{
      font-size:   ${({ printSize }) => printSize}px;
    }
  }
`;

const scaleOptions = {
  s25: { label: '25%', name: '25' },
  s50: { label: '50%', name: '50' },
  s75: { label: '75%', name: '75' },
  s100: { label: '100%', name: '100' },
  s150: { label: '150%', name: '150' },
  s200: { label: '200%', name: '200' },
  fitToWidth: { label: 'fit to screen', name: 'auto' },
};

/* на какое количество (в среднем) пикселей отличается реальная
  ширина колонки от той, которая пришла с сервере
  Обычно из-за рамки
 */
const FIXED_COLUMN_WIDTH_DELTA = 0;

const calculateWidth = (tables) => tables
  .reduce((R, table) => ([...R,
    table.get('columns', new List()).reduce((R2, col) => R2 + col.get('width', 0) + FIXED_COLUMN_WIDTH_DELTA, 0)]), []);

function TableEditor({
  mountNode, fitWidth, onReportDetails, getPrint, getLogicaData,
}) {
  const divRev = useRef();

  const [isPortrait, setIsPortrait] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [fixed, setFixed] = useState({ left: 0, top: 0 });
  const [activeCell, setActiveCell] = useState({ row: null, column: null });
  const [scale, setScale] = useState(
    fitWidth ? scaleOptions.fitToWidth.name : scaleOptions.s100.name,
  );

  const onSetScale = useCallback(
    (e, v) => {
      setFixed((oldFixed) => {
        setFixed({ left: 0, top: 0 });
        setScale(v);
        // Компонент должен успеть перерисоваться.setTimeout обязятелен
        setTimeout(() => setFixed(oldFixed), 10);
      });
    },
    [],
  );

  const [tablesColumns, setTablesColumns] = useState(new Map());

  const mapState = useMemo(
    () => (store) => ({
      data: store.getIn(mountNode, new Map()),
    }),
    [mountNode],
  );

  const { data } = useSelector(mapState);
  const dispatch = useDispatch();

  useEffect(() => {
    setTablesColumns(
      data.get('tables', new Map())
        .reduce((R, table) => R.setIn(
          [table.get('id'), 'columns'],
          table.get('columns').map(
            (c) => c.set('width', c.get('width', 0)),
          ),
        ), new Map()),
      //  Увеличим ширину колонки на 8 пикселя, потому что там у нас есть сколлер и margin
    );
    // Компонент должен успеть перерисоваться.setTimeout обязятелен
    setTimeout(
      () => setFixed({
        left: data.getIn(['params', 'fixedLeft'], 0),
        top: data.getIn(['params', 'fixedTop'], 0),
      }),
      10,
    );
    setIsPortrait(data.getIn(['params', 'PageOrientation'], '') === 'Portrait');
  }, [data]);

  const tablewidth = useMemo(
    () => calculateWidth(tablesColumns),
    [tablesColumns],
  );

  const pageWidth = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => (isPortrait ? 210 * 5.12 : 297 * 3.8),
    [isPortrait],
  );

  const printScale = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => Math.max(...tablewidth) === 0 ? 1 : pageWidth / Math.max(...tablewidth),
    [pageWidth, tablewidth],
  );

  const print = useCallback(
    () => {
      if (divRev.current) {
        const el = divRev.current.cloneNode(true);
        const newEl = document.body.appendChild(el);
        newEl.className = 'print-area';
        const PageStyle = document.createElement('style');
        PageStyle.innerHTML = `
          @page{
            size: ${isPortrait ? 'portrait' : 'landscape'};
          }
        `;
        document.body.appendChild(PageStyle);
        window.print();
        document.body.removeChild(PageStyle);
        document.body.removeChild(newEl);
      }
    },
    [isPortrait],
  );

  const fixArea = useCallback(
    () => {
      if (!activeCell.column || !activeCell.row) return false;
      setFixed({
        left: activeCell.column - 1,
        top: activeCell.row - 1,
      });
      return true;
    },
    [activeCell],
  );

  const unFixArea = useCallback(
    () => setFixed({
      left: 0,
      top: 0,
    }),
    [],
  );

  const saveToFile = useCallback(
    (typeFile) => {
      const checkTypeDpEditor = (elem) => elem.endsWith('dpEditor');
      const checkTypeReportEditor = (elem) => elem.endsWith('reportEditor');

      if (mountNode.some(checkTypeDpEditor)) {
        dispatch(actions.changeField(['headerForm', 'result'], new Map()));
        dispatch(actions.processServerReq('reportToFile', { format: typeFile, isPortrait }));
      }

      if (mountNode.some(checkTypeReportEditor)) {
        dispatch(reportToFile(typeFile));
      }
    },
    [dispatch, isPortrait, mountNode],
  );

  const screenScale = useMemo(
    () => {
      const getScale = () => {
        if (!divRev.current) return 1;
        const delta = 0;
        const cRect = divRev.current.parentNode.getBoundingClientRect();
        switch (scale) {
          case scaleOptions.s25.name:
            return 0.25;
          case scaleOptions.s50.name:
            return 0.5;
          case scaleOptions.s75.name:
            return 0.75;
          case scaleOptions.s100.name:
            return 1;
          case scaleOptions.s150.name:
            return 1.5;
          case scaleOptions.s200.name:
            return 2;
          case scaleOptions.fitToWidth.name:
          // console.log((cRect.width - delta) / iRect.width);
            if (cRect.width) {
              return (cRect.width - delta) / tablewidth;
            }
            return 1;
          default:
            return 1;
        }
      };
      return Math.round(getScale() * 100) / 100;
    },
    [scale, tablewidth],
  );

  const resizeColumn = useCallback(
    (e, tableName, col, colspan, newWdith) => {
      setTablesColumns((tc) => {
        const collection = tc.getIn([tableName, 'columns']);
        const resizedColumns = collection
          .filter((c) => c.get('id') >= col && c.get('id') <= col + (colspan || 1) - 1)
          .reduce((R, clmn) => R.set(clmn.get('id'), clmn), new Map());
        const oldWidth = resizedColumns.reduce((R, clmn) => R + clmn.get('width', 0), 0);
        const newColumns = collection.map((clmn) => {
          if (!resizedColumns.has(clmn.get('id'))) return clmn;
          return clmn.set('width', Math.max(newWdith * (clmn.get('width') / oldWidth), 0) / screenScale);
        });
        return tc.setIn([tableName, 'columns'], newColumns);
      });
    },
    [screenScale],
  );

  const onActivateCell = useCallback(
    (e, name) => {
      const re = /R(\d+)C(\d+)/;
      const results = name.match(re);
      setActiveCell({
        row: Number(results[1]),
        column: Number(results[2]),
      });
    },
    [],
  );

  const maxRows = useMemo(
    () => data.get('tables', new List()).reduce((R, t) => R + t.get('rows').size, 0),
    [data],
  );

  const cells = useMemo(
    () => data.get('tables', new List()).reduce((tables, t) => tables.merge(
      t.get('rows', new List()).reduce((rows, r) => rows.merge(
        r.get('cells', new List()).reduce((clls, c) => clls.set(c.get('name'), new Map({
          rowId: r.get('id'),
          colspan: c.get('colspan') || 1,
          rowspan: c.get('rowspan') || 1,
          col: c.get('col'),
        })), new Map()),
      ), new Map()),
    ), new Map()),
    [data],
  );

  const rowsWidth = useMemo(
    () => cells.reduce((R, c) => R.set(c.get('rowId'), R.get(c.get('rowId'), 0) + c.get('colspan')), new Map()),
    [cells],
  );

  const scrollerRef = useRef();

  const onKeyPressHandler = useCallback(
    (e) => {
      if (e.target !== document.body) return true;
      if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) return true;
      setActiveCell(({ row, column }) => {
        const cCol = cells.get(`R${row}C${column}`);
        const cRow = rowsWidth.get(row);
        if (!cCol || !cRow) {
          return { row, column };
        }
        const prevColumn = column > 1 ? Math.max(...cells
          .filter((c) => c.get('rowId') === row && c.get('col') < column)
          .map((c) => c.get('col'))
          .toList().toArray()) : null;
        const prevRow = row > 1 ? Math.max(...cells
          .filter((c) => c.get('rowId') < row && c.get('col') === column)
          .map((c) => c.get('rowId'))
          .toList().toArray()) : null;

        if (e.key === 'ArrowLeft') {
          return {
            row,
            column: prevColumn || column,
          };
        }
        if (e.key === 'ArrowRight') {
          return {
            row,
            column: column + cCol.get('colspan') <= cRow ? column + cCol.get('colspan') : column,
          };
        }
        if (e.key === 'ArrowUp' && prevRow !== -Infinity) {
          return {
            row: prevRow || row,
            column,
          };
        }
        if (e.key === 'ArrowDown') {
          return {
            row: row + cCol.get('rowspan') <= maxRows ? row + cCol.get('rowspan') : row,
            column,
          };
        }
        return { row, column };
      });
      e.preventDefault();
      return false;
    },
    [cells, maxRows, rowsWidth],
  );

  useEffect(() => {
    if (getPrint) {
      dispatch(processServerReq('PRINT'));
    }
    if (getLogicaData) {
      const ticket = localStorage.getItem('ticket');
      dispatch(processServerReq('REPORTS_REPORT', { ticket }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      document.body.addEventListener('keydown', onKeyPressHandler);
      return () => {
        document.body.removeEventListener('keydown', onKeyPressHandler);
      };
    },
    [onKeyPressHandler],
  );

  const scrollToActiveCell = useCallback(
    () => {
      if (scrollerRef.current) {
        const el = scrollerRef.current.querySelector('td.ActiveCell');
        const fixedCoords = {
          height: 0,
          left: 0,
        };
        const topFixedCells = scrollerRef.current.querySelectorAll('td.FixedToTop');
        if (topFixedCells.length > 0) {
          fixedCoords.height = topFixedCells[topFixedCells.length - 1]
            .getBoundingClientRect().bottom
            - scrollerRef.current.getBoundingClientRect().top;
        }

        const leftFixedCells = scrollerRef.current.querySelectorAll('td.FixedToLeft');
        if ((leftFixedCells.length > 0) && (topFixedCells.length >= leftFixedCells.length)) {
          fixedCoords.left = topFixedCells[leftFixedCells.length - 1].getBoundingClientRect().right
            - scrollerRef.current.getBoundingClientRect().left;
        }

        if (el) {
          const {
            top, left, bottom, right,
          } = el.getBoundingClientRect();
          let doScroll = false;
          const scrollTo = { top: scrollerRef.current.scrollTop, left: scrollerRef.current.scrollLeft, behavior: 'smooth' };
          const scrollerCoords = scrollerRef.current.getBoundingClientRect();

          if ((right - scrollerCoords.left) > (scrollerRef.current.clientWidth)) {
            doScroll = true;
            scrollTo.left = Math.min(
              right - scrollerCoords.left + scrollerRef.current.scrollLeft + 10,
              scrollerRef.current.scrollWidth,
            ) - scrollerRef.current.clientWidth;
          }
          if ((left - scrollerCoords.left) < fixedCoords.left) {
            doScroll = true;
            scrollTo.left = Math.max(
              left - scrollerCoords.left + scrollerRef.current.scrollLeft - fixedCoords.left - 10,
              0,
            );
          }
          if ((bottom - scrollerCoords.top) > (scrollerRef.current.clientHeight)) {
            doScroll = true;
            scrollTo.top = Math.min(
              bottom - scrollerCoords.top + scrollerRef.current.scrollTop + 10,
              scrollerRef.current.scrollHeight,
            ) - scrollerRef.current.clientHeight;
          }

          if ((top - scrollerCoords.top) < fixedCoords.height) {
            doScroll = true;
            scrollTo.top = Math.max(
              top - scrollerCoords.top + scrollerRef.current.scrollTop - fixedCoords.height - 10,
              0,
            );
          }

          if (doScroll) {
            scrollerRef.current.scrollTo(scrollTo);
          }
        }
      }
    },
    [],
  );
  const debouncedScroll = useDebounce(scrollToActiveCell);

  useEffect(
    () => debouncedScroll(activeCell.column, activeCell.row),
    [activeCell.column, activeCell.row, debouncedScroll],
  );

  useEffect(
    () => {
      const root = document.getElementById('root-container');
      if (root && data.size && scrollerRef.current) {
        root.scrollTo({ left: 0, top: scrollerRef.current.getBoundingClientRect().top, behavior: 'smooth' });
      }
    },
    [data.size],
  );
  // Все едеиниці в документе должны испоьзовать rem - Размеры относительно шрифта в document.body
  // поэтому мы созраняем значение которое там было и будем менять размер шрафта от этого значения
  // для задания масштаба
  return (
    <StyledContainer>
      <HTMLStyle screenSize={16 * screenScale} printSize={printScale * 16} />
      <ContainerButtonBottom>
        <CommandPanelButton
          text="Друк"
          onClick={print}
        >
          &nbsp;
          <PrintIcon />
        </CommandPanelButton>
        <CommandPanelButton
          text={editMode ? 'Завершити редагування' : 'Редагувати'}
          onClick={() => setEditMode(!editMode)}
        >
          &nbsp;
          {editMode ? <img src={FinishEditingIcon} alt="Завершити редагування" /> : <EditIcon />}
        </CommandPanelButton>
        <CommandPanelButton
          style={{ color: '#4281C9' }}
          text={isPortrait ? 'Книжкова' : 'Альбомна'}
          onClick={() => setIsPortrait(!isPortrait)}
        >
          {isPortrait
            ? <img src={ChangeModeIcon} alt="Книжкова" />
            : <img src={ChangeModeIcon} alt="Альбомна" style={{ transform: 'scale(-1, 1) rotate(270deg)' }} />}
        </CommandPanelButton>
        <CommandPanelButton
          text="Закріпити області"
          onClick={fixArea}
        >
          <img src={FixAreaIcon} alt="Закріпити" />
        </CommandPanelButton>
        <CommandPanelButton
          text="Зняти закріплення областей"
          onClick={unFixArea}
        >
          <img src={UnFixAreaIcon} alt="Зняти" />
        </CommandPanelButton>
        <div style={{ height: '37px', display: 'flex' }}>
          <InputWithDropdown
            onChange={onSetScale}
            options={Object.values(scaleOptions)}
            value={scale}
          />
        </div>
        <ContainerSave>
          <TextSave>Зберегти як:</TextSave>
          <ButtonIcon
            text="XLS"
            onClick={() => saveToFile('XLS')}
          >
            <img src={XLSColorIcon} alt="XLS" width="33px" />
          </ButtonIcon>
          <ButtonIcon
            text="PDF"
            onClick={() => saveToFile('PDF')}
          >
            <img src={PDFColorIcon} alt="PDF" width="33px" />
          </ButtonIcon>
          <ButtonIcon
            text="DOCX"
            onClick={() => saveToFile('DOCX')}
          >
            <img src={DOCXColorIcon} alt="DOCX" width="33px" />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: '0' }}
            text="HTML"
            onClick={() => saveToFile('HTML')}
          >
            <img src={HTMLColorIcon} alt="HTML" width="33px" />
          </ButtonIcon>
        </ContainerSave>
      </ContainerButtonBottom>
      <StyledContainer2 ref={scrollerRef}>
        <PrintableArea className="printable-area" id="printable-area" ref={divRev}>
          {data && tablesColumns.size > 0 && data.get('tables', new List()).map((table) => (
            <Table
              pageHeaderTop={data.getIn(['params', 'pageHeaderTop'], 0)}
              pageHeaderDown={data.getIn(['params', 'pageHeaderDown'], 0)}
              key={table.get('id')}
              name={table.get('id')}
              columns={tablesColumns.getIn([table.get('id'), 'columns'], new List())}
              rows={table.get('rows', new Map())}
              cellStyles={data.get('styles', new Map())}
              activeCell={activeCell}
              editMode={editMode}
              onActivateCell={onActivateCell}
              onResizeColumn={resizeColumn}
              fixedLeft={fixed.left}
              fixedTop={fixed.top}
              onReportDetails={onReportDetails}
              scroller={scrollerRef.current}
              maxWidth={Math.max(...tablewidth)}
            />
          ))}
        </PrintableArea>
      </StyledContainer2>
    </StyledContainer>

  );
}
TableEditor.propTypes = {
  mountNode: PropTypes.arrayOf(PropTypes.string).isRequired,
  fitWidth: PropTypes.bool,
  onReportDetails: PropTypes.func,
  getPrint: PropTypes.bool,
  getLogicaData: PropTypes.bool,
};

TableEditor.defaultProps = {
  fitWidth: false,
  onReportDetails: null,
  getPrint: false,
  getLogicaData: false,
};

// export default connect(mapState)(TableEditor);
export default memo(TableEditor);
