import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({ activeCol, highlights, onToggleHighlight }) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
        <Row sm={12} noGutters>
          <Col sm={12}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'problem'}
              highlighted={highlights.includes('problem')}
              onToggleHighlight={() => onToggleHighlight('problem')}
            >
              Завдання
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
