const frontendURL = '/cat/externalSource/';
const backendURL = '/api/external/info/';
const name = 'Інформація, отримана із зовнішніх джерел';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
