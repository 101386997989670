import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnGroupFullness = {
  label: 'Наповненість груп закладу дошкільної освіти',
  name: 'bfnGroupFullness',
  backendName: 'bfnGroupFullness',
  defaultOrder,
  columns,
  frontend: 'cat/bfnGroupFullness',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnGroupFullness;
