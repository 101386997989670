const frontendURL = '/doc/payment/';
const backendURL = '/api/obligations/paymentorderheader/';
const name = 'Платіжні доручення';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
