import React from 'react';
import PropTypes from 'prop-types';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function CompareButton({
  content, onClick, disabled, title,
}) {
  return (
    <CPButton
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faGlasses}
      title={title}
    />
  );
}

CompareButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

CompareButton.defaultProps = {
  onClick: () => null,
  content: 'Переглянути оновлення',
  disabled: false,
  title: 'Переглянути оновлення',
};

export default CompareButton;
