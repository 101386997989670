import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/indicatorFact';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import FinancingCommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const FRLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel: FinancingCommandPanel,
}));

const filterValues = [
  { value: 1, label: 'За розпорядником' },
  { value: 2, label: 'За розпорядником нижчого рівня' },
  { value: 3, label: 'Всі' },
];

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
});

function FinancingLister() {
  const {
    FO, isAdmin,
  } = useSelector(mapState);

  const [fValue, setFValue] = useState(isAdmin ? 3 : 1);
  const flt = useMemo(
    () => {
      switch (fValue) {
        case 1:
          return [{
            fieldName: md.columns.FI.name,
            value: FO.toJS(),
          }];
        case 2:
          return [{
            fieldName: `${md.columns.FI.name}.Родитель`,
            value: FO.toJS(),
          }];
        default:
          return [];
      }
    },
    [FO, fValue],
  );
  return (
    <>
      <FilteredTabs values={filterValues} value={fValue} onChange={(e, v) => setFValue(v)} />
      <FRLister filter={flt} />
    </>
  );
}

export default FinancingLister;
