import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  DateInput, StringInput, ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_10';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="solutionNumber" highlighted={highlights.includes('solutionNumber')}>
              <StringInput
                value={row[tableMD.columns.solutionNumber.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.solutionNumber.name]: v })}
                readOnly={readOnly}
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="solutionDate" highlighted={highlights.includes('solutionDate')}>
              <DateInput
                value={row[tableMD.columns.solutionDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.solutionDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="forceDate" highlighted={highlights.includes('forceDate')}>
              <DateInput
                value={row[tableMD.columns.forceDate.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.forceDate.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="dateFrom" highlighted={highlights.includes('dateFrom')}>
              <DateInput
                value={row[tableMD.columns.dateFrom.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.dateFrom.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="koutuuMs" highlighted={highlights.includes('koutuuMs')}>
              <StringInput
                value={row[tableMD.columns.koutuuMs.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.koutuuMs.name]: v })}
                readOnly={readOnly}
                maxLength={19}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nameMs" highlighted={highlights.includes('nameMs')}>
              <ItemPicker
                value={row[tableMD.columns.nameMs.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.nameMs.name]: v });
                  await onSR('CHANGE_KOATUU_MS_IN_TABLE', { rowId: rowIndex });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="koatuu"
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="koatuuAto" highlighted={highlights.includes('koatuuAto')}>
              <StringInput
                value={row[tableMD.columns.koatuuAto.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.koatuuAto.name]: v })}
                readOnly={readOnly}
                maxLength={19}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="nameAto" highlighted={highlights.includes('nameAto')}>
              <ItemPicker
                value={row[tableMD.columns.nameAto.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.nameAto.name]: v });
                  await onSR('CHANGE_KOATUU_ATO_IN_TABLE', { rowId: rowIndex });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="koatuu"
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add2Tax" highlighted={highlights.includes('add2Tax')}>
              <StringInput
                value={row[tableMD.columns.add2Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add2Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add2TaxAdd" highlighted={highlights.includes('add2TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add2TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add2TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add2Privilege" highlighted={highlights.includes('add2Privilege')}>
              <StringInput
                value={row[tableMD.columns.add2Privilege.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add2Privilege.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add2PrivilegeAdd" highlighted={highlights.includes('add2PrivilegeAdd')}>
              <StringInput
                value={row[tableMD.columns.add2PrivilegeAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add2PrivilegeAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add3Tax" highlighted={highlights.includes('add3Tax')}>
              <StringInput
                value={row[tableMD.columns.add3Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add3Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add3TaxAdd" highlighted={highlights.includes('add3TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add3TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add3TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add3Privilege" highlighted={highlights.includes('add3Privilege')}>
              <StringInput
                value={row[tableMD.columns.add3Privilege.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add3Privilege.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add3PrivilegeAdd" highlighted={highlights.includes('add3PrivilegeAdd')}>
              <StringInput
                value={row[tableMD.columns.add3PrivilegeAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add3PrivilegeAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add4Tax" highlighted={highlights.includes('add4Tax')}>
              <StringInput
                value={row[tableMD.columns.add4Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add4Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add4TaxAdd" highlighted={highlights.includes('add4TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add4TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add4TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add5Tax" highlighted={highlights.includes('add5Tax')}>
              <StringInput
                value={row[tableMD.columns.add5Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add5Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add5TaxAdd" highlighted={highlights.includes('add5TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add5TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add5TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add6Tax" highlighted={highlights.includes('add6Tax')}>
              <StringInput
                value={row[tableMD.columns.add6Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add6Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add6TaxAdd" highlighted={highlights.includes('add6TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add6TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add6TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add7Tax" highlighted={highlights.includes('add7Tax')}>
              <StringInput
                value={row[tableMD.columns.add7Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add7Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add7TaxAdd" highlighted={highlights.includes('add7TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add7TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add7TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add8Tax" highlighted={highlights.includes('add8Tax')}>
              <StringInput
                value={row[tableMD.columns.add8Tax.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add8Tax.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add8TaxAdd" highlighted={highlights.includes('add8TaxAdd')}>
              <StringInput
                value={row[tableMD.columns.add8TaxAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add8TaxAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="add11Rent" highlighted={highlights.includes('add11Rent')}>
              <StringInput
                value={row[tableMD.columns.add11Rent.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add11Rent.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="add11RentAdd" highlighted={highlights.includes('add11RentAdd')}>
              <StringInput
                value={row[tableMD.columns.add11RentAdd.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.add11RentAdd.name]: v })}
                readOnly
                maxLength={10}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
