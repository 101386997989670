import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { getRouterEditor } from '../../editor/basic';
import { ItemField } from '../../field/selectorField/item';
import { editor as actions } from '../../../actions/index';
import md from '../../../constants/meta/dataprocessors/attachedFiles';
import { StyledLabel, ThreeColumnsGridContainer } from '../../../components/Form/styledForm';
import Fields from '../../field';

const TextInput250 = Fields.GetTextInput(250);

class AttachedFilesEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    headerForm: new Map(),
  };

  render() {
    const {
      props: { dispatch, headerForm },
    } = this;

    return (
      <ThreeColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.anyLink.label}</StyledLabel>
          <ItemField
            value={headerForm.get('anyLink', new Map())}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.anyLinkName.label}</StyledLabel>
          <TextInput250
            required
            radius
            background
            textLeft
            value={headerForm.get('anyLinkName', '')}
            onChange={(e, value) => dispatch(actions.changeField(['headerForm', 'anyLinkName'], value))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.typeFile.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('typeFile', new Map())}
            modelType="cat"
            modelName="typeAttachedFile"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'typeFile'], value));
            }}
          />
        </div>

      </ThreeColumnsGridContainer>
    );
  }
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name,
    },
    {
      menu: () => null,
    },
  ),
);

export const StdEditor = enhance(AttachedFilesEditor);

export default getRouterEditor(md)(StdEditor);
