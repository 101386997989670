// const url = 'https://zakon.rada.gov.ua/laws/show/z1372-21';

import { Link } from 'react-router-dom';

const urlTarif = 'https://zakon.rada.gov.ua/laws/show/1298-2002-%D0%BF#n79';

function PlanMereza1() {
  return (
          <>
          <span>
          Інформація щодо планової чисельності працівників та фонду оплати праці працівників установ, закладів та
організацій окремих галузей бюджетної сфери, оплата праці яких здійснюється відповідно до {' '}
          </span>
          <Link rel="noreferrer" to={urlTarif} target="_blank">
        Єдиної тарифної сітки розрядів і коефіцієнтів з оплати праці працівників установ, закладів та
            організацій окремих галузей бюджетної сфери,
          </Link>
          <span>, затвердженої постановою Кабінету Міністрів України від 30 серпня 2002 року № 1298
            (у редакції постанови Кабінету Міністрів України від 22 серпня 2005 року № 790), станом на 01 січня
            </span>
          </>
        )
}

function PlanMereza2() {
  return (
          <>
          <span>
          Інформація щодо планової чисельності працівників та фонду оплати праці працівників установ, закладів
            та організацій окремих галузей бюджетної сфери, оплата праці яких здійснюється відповідно до  {' '}
          </span>
          <Link rel="noreferrer" to={urlTarif} target="_blank">
        Єдиної тарифної сітки розрядів і коефіцієнтів з оплати праці працівників установ, закладів та організацій
            окремих галузей бюджетної сфери,
          </Link>
          <span>, затвердженої постановою Кабінету Міністрів України від 30 серпня 2002 року № 1298
            (у редакції постанови Кабінету Міністрів України від 22 серпня 2005 року № 790), станом на 01 липня
            </span>
          </>
        )
}

function MonthInfo() {
  return (
    <>
      <span>Інформація щодо місячної бюджетної звітності:</span>
      <ul style={{ listStyleType: 'circle' }}>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n23"} target="_blank">
            форма № 2ммб
          </Link>
            - Звіт про виконання місцевих бюджетів (доходи, видатки, кредитування, фінансування);
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n82"} target="_blank">
            форма № 3ммб
          </Link>
            - Звіт про виконання бюджету розвитку місцевих бюджетів;
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n29"} target="_blank">
            форма № 7мб
          </Link>
            - Звіт про бюджетну заборгованість;
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n32"} target="_blank">
          Інформація про виконання захищених видатків місцевих бюджетів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n35"} target="_blank">
            Звіт про використання коштів з резервного фонду;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n38"} target="_blank">
          Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету
          </Link>
        </li>
      </ul>
    </>
        )
  }

function QuarteInfo() {
  return (
    <>
      <span>Інформація щодо квартальної бюджетної звітності:</span>
      <ul style={{ listStyleType: 'circle' }}>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n26"} target="_blank">
            форма № 2кмб(мб)
          </Link>
            - Звіт про виконання місцевих бюджетів (доходи, видатки, кредитування, фінансування);
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n85"} target="_blank">
            форма № 3кмб(мб)
          </Link>
            - Звіт про виконання бюджету розвитку місцевих бюджетів;
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n38"} target="_blank">
            Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n38"} target="_blank">
          Інформація про стан місцевих боргів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n44"} target="_blank">
            Інформація про стан гарантованих відповідно Автономною Республікою Крим, обласними радами та
            територіальними громадами боргів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n47"} target="_blank">
          Інформація про надані місцеві гарантії
          </Link>
        </li>
      </ul>
    </>
  )
}

function YearInfo() {
  return (
    <>
      <span>Інформація щодо річної бюджетної звітності:</span>
      <ul style={{ listStyleType: 'circle' }}>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n26"} target="_blank">
            форма № 2кмб(мб)
          </Link>
            - Звіт про виконання місцевих бюджетів (доходи, видатки, кредитування, фінансування);
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n85"} target="_blank">
            форма № 3кмб(мб)
          </Link>
            - Звіт про виконання бюджету розвитку місцевих бюджетів;
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n29"} target="_blank">
            форма № 7мб
          </Link>
            - Звіт про бюджетну заборгованість;
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n32"} target="_blank">
            Інформація про виконання захищених видатків місцевих бюджетів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n35"} target="_blank">
          Звіт про використання коштів з резервного фонду;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n38"} target="_blank">
            Звіт про надання та використання місцевими бюджетами дотацій та субвенцій, отриманих з державного бюджету;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n41"} target="_blank">
          Інформація про стан місцевих боргів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n44"} target="_blank">
          Інформація про стан гарантованих відповідно Автономною Республікою Крим, обласними радами та
            територіальними громадами боргів;
          </Link>
        </li>
        <li>
          <Link rel="noreferrer" to={"https://zakon.rada.gov.ua/rada/show/v0012201-18#n47"} target="_blank">
         Інформація про надані місцеві гарантії
          </Link>
        </li>
      </ul>
    </>
  )
}

const content = [
  {
    header: ' Інформація, яку передають місцеві фінансові органи до Міністерства фінансів України',
    items: [
      {
        name: 'Орієнтовні граничні показники видатків місцевого бюджету та надання кредитів з місцевого бюджету ' +
          'для головних розпорядників коштів місцевих бюджетів',
        description: 'До 20 липня',
        month: 7,
        day: 20,
        rowNum: 1,
      },
      {
        name: 'Показники міжбюджетних трансфертів (іншим місцевим бюджетам),'
            + ' які передбачаються в прогнозі місцевого бюджету на середньостроковий бюджетний період',
        description: 'До 4 серпня',
        month: 8,
        day: 4,
        rowNum: 2,
      },
      {
        name: 'Прогноз місцевого бюджету на середньостроковий бюджетний період',
        description: 'До 1 вересня',
        month: 9,
        day: 1,
        rowNum: 3,
      }, {
        name: 'Повідомлення про прийняття рішення про місцевий бюджет органом місцевого самоврядування',
        description: 'Один робочий день після схвалення рішення',
        month: 0,
        day: 0,
        rowNum: 4,
      },
      {
        name: 'Рішення про місцевий бюджет з додатками',
        description: 'До 5 січня',
        month: 1,
        day: 5,
        rowNum: 5,
      },
      {
        name: 'Рішення про місцевий бюджет з додатками (зі змінами)',
        description: '10 днів після внесення змін до рішення про місцевий бюджет',
        month: 0,
        day: 0,
        rowNum: 6,
      },
      {
        name: 'Розпис місцевих бюджетів',
        description: 'До 10:00 п’ятого робочого дня що настає за днем внесення та затвердження розпису '
            + 'відповідними місцевими фінансовими органами, але не пізніше третього робочого дня місяця, '
            + 'що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 7,
      },
      {
        name: 'Зміни до розпису місцевих бюджетів',
        description: 'До 10:00 другого робочого дня, що настає за днем затвердження змін до розпису',
        month: 0,
        day: 0,
        rowNum: 8,
      },
      {
        name: 'Лімітні довідки про бюджетні асигнування та кредитування',
        description: '14 днів після затвердження рішення про місцевий бюджет',
        month: 0,
        day: 0,
        rowNum: 9,
      },
      {
        name: 'Інформація щодо стану фінансування соціальних виплат із місцевих бюджетів',
        description: 'Щомісячно до 5 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 10,
      },
      {
        name: 'Інформація щодо стану розподілу коштів, отриманих від перевиконання дохідної частини '
            + 'загального фонду місцевих бюджетів',
        description: 'Щомісяця до 10 числа місяця, наступного за звітним',
        month: 0,
        day: 0,
        rowNum: 11,
      },
      {
        name: 'Інформація щодо розподілу вільних залишків коштів місцевих бюджетів за загальним фондом',
        description: 'Щомісячно до 3 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 12,
      },
      {
        name: 'Інформація про ставки місцевих податків та/або зборів та податкових пільг із сплати місцевих податків '
            + 'та/або зборів, які визначені Порядком обміну інформацією між органами, що контролюють справляння надходжень'
            + 'бюджету, та органами місцевого самоврядування',
        description: 'У десятиденний строк з дня прийняття рішень, але не пізніше 25 липня року, що передує бюджетному періоду, в                                           '
            + 'якому планується застосування встановлених місцевих податків та/або зборів і податкових пільг зі сплати місцевих податків та/або зборів',
        month: 0,
        day: 0,
        rowNum: 13,
      },
      {
        name: 'Повідомлення про розгляд прогнозу місцевого бюджету місцевою радою',
        description: 'Один робочий день після прийняття рішення',
        month: 0,
        day: 0,
        rowNum: 14,
      },
      {
        name: 'Проєкт рішення про місцевий бюджет, схвалений місцевою державною адміністрацією чи виконавчим ' +
          'органом місцевої ради',
        description: 'До 25 грудня',
        month: 12,
        day: 25,
        rowNum: 15,
      },
      {
        name: PlanMereza1(),
        description: 'До 30 січня',
        month: 1,
        day: 30,
        rowNum: 16,
      },
      {
        name: `Інформація щодо планової чисельності працівників та фонду оплати праці
        інших працівників бюджетної сфери, оплата праці яких здійснюється згідно з
        іншими нормативно-правовими актами, станом на 01 січня`,
        description: 'До 30 січня',
        month: 1,
        day: 30,
        rowNum: 17,
      },
      {
        name: PlanMereza2(),
        description: 'До 30 липня',
        month: 7,
        day: 30,
        rowNum: 18,
      },
      {
        name: `Інформація щодо планової чисельності працівників та фонду оплати праці інших працівників бюджетної сфери,
оплата праці яких здійснюється згідно з іншими нормативно-правовими актами, станом на 01 липня`,
        description: 'До 30 липня',
        month: 7,
        day: 30,
        rowNum: 19,
      },
      {
        name: 'Зведення планових показників за мережею, штатами і контингентами установ, що фінансуються з '
            + 'місцевих бюджетів областей та міста Києва, на 20___ рік',
        description: 'До 15 лютого',
        month: 2,
        day: 15,
        rowNum: 20,
      },
      {
        name: 'Зведення планових показників за мережею, штатами і контингентами установ, що фінансуються з ' +
          'місцевих бюджетів областей та міста Києва, на 20___ рік',
        description: 'До 15 березня',
        month: 3,
        day: 15,
        rowNum: 21,
      },
      {
        name: 'Інформація про валютно-фінансові умови місцевого запозичення / місцевої гарантії',
        description: 'Протягом 10 днів з дати підписання договорів (угод), дати отримання коштів або виконання платежу',
        month: 0,
        day: 0,
        rowNum: 22,
      },
      {
        name: 'Графіки планових та фактичних надходжень коштів за місцевим запозиченням та місцевою гарантією ' +
          '(всі надходження)',
        description: 'Протягом 10 днів з дати підписання договорів (угод), дати отримання коштів або виконання платежу',
        month: 0,
        day: 0,
        rowNum: 23,
      },
      {
        name: 'Графіки планових та фактичних платежів за місцевим запозиченням та місцевою гарантією (всі платежі)',
        description: 'Протягом 10 днів з дати підписання договорів (угод), дати отримання коштів або виконання платежу',
        month: 0,
        day: 0,
        rowNum: 24,
      },
      {
        name: 'Очікувані помісячні обсяги видатків',
        description: 'Щомісяця до останнього робочого дня місяця',
        month: 0,
        day: 0,
        rowNum: 25,
      },
      {
        name: 'Очікувані поденні обсяги операцій з тимчасово-вільними коштами на депозитних рахунках',
        description: 'Щомісяця до останнього робочого дня місяця',
        month: 0,
        day: 0,
        rowNum: 26,
      },
      {
        name: 'Очікувані поденні обсяги місцевих запозичень та платежів за місцевим боргом в національній валюті',
        description: 'Щомісяця до останнього робочого дня місяця',
        month: 0,
        day: 0,
        rowNum: 27,
      },
    ],
  },
  {
    header: 'Інформація, яку передають головні розпорядники та відповідальні виконавці до Міністерства фінансів України',
    items: [
      {
        name: `Орієнтовні граничні показники видатків місцевого бюджету та надання кредитів з місцевого бюджету
        розпоряднику/одержувачу коштів`,
        description: 'До 23 липня',
        month: 7,
        day: 23,
        rowNum: 1,
      }, {
        name: 'Бюджетна пропозиція загальна (Форма БП-1)',
        description: 'До 1 серпня',
        month: 8,
        day: 1,
        rowNum: 2,
      }, {
        name: 'Бюджетна пропозиція індивідуальна (Форма БП-2)',
        description: 'До 1 серпня',
        month: 8,
        day: 1,
        rowNum: 3,
      }, {
        name: 'Бюджетна пропозиція додаткова (Форма БП-3)',
        description: 'До 1 серпня',
        month: 8,
        day: 1,
        rowNum: 4,
      },
      {
        name: 'Бюджетний запит загальний (Форма 20__-1)',
        description: 'До 1 листопада',
        month: 11,
        day: 1,
        rowNum: 5,
      },
      {
        name: 'Бюджетний запит індивідуальний (Форма 20__-2)',
        description: 'До 1 листопада',
        month: 11,
        day: 1,
        rowNum: 6,
      },
      {
        name: 'Бюджетний запит додатковий (Форма 20__-3)',
        description: 'До 1 листопада',
        month: 11,
        day: 1,
        rowNum: 7,
      },
      {
        name: 'Паспорт бюджетної програми місцевого бюджету',
        description: '48 днів після затвердження рішення про місцевий бюджет',
        month: 0,
        day: 0,
        rowNum: 8,
      },
      {
        name: 'Паспорт бюджетної програми місцевого бюджету (у новій редакції)',
        description: '3 робочі дні з дня затвердженняа',
        month: 0,
        day: 0,
        rowNum: 9,
      },
      {
        name: 'Звіт про виконання паспорта бюджетної програми місцевого бюджету',
        description: '3 робочі дні після 1 березня',
        month: 0,
        day: 0,
        rowNum: 10,
      },
      {
        name: `Зведений кошторис, зведений план асигнувань (за винятком надання кредитів з бюджету) загального фонду
бюджету, зведений план надання кредитів із загального фонду бюджету, зведений план спеціального фонду бюджету (за
винятком власних надходжень бюджетних установ та відповідних видатків), зведений план використання бюджетних коштів
та зведений помісячний план використання бюджетних коштів`,
        description: '30 днів після затвердження розпису місцевого бюджету',
        month: 0,
        day: 0,
        rowNum: 11,
      },
      {
        name: '`Кошторис, план асигнувань (за винятком надання кредитів з бюджету) загального фонду бюджету, план надання\n'
            + 'кредитів із загального фонду бюджету, план спеціального фонду бюджету (за винятком власних надходжень бюджетних установ\n'
            + 'та відповідних видатків), план використання бюджетних коштів та помісячний план використання бюджетних коштів`',
        description: '30 днів після затвердження розпису місцевого бюджету',
        month: 0,
        day: 0,
        rowNum: 12,
      },
      {
        name: `Уточнений зведений кошторис, уточнений зведений план асигнувань (за винятком надання кредитів з бюджету)
загального фонду бюджету, уточнений зведений план надання кредитів із загального фонду бюджету, уточнений зведений план
 спеціального фонду бюджету (за винятком власних надходжень бюджетних установ та відповідних видатків), уточнений
 зведений план використання бюджетних коштів та уточнений зведений помісячний план використання бюджетних коштів`,
        description: '3 робочі дні з дня затвердження змін',
        month: 0,
        day: 0,
        rowNum: 13,
      },
      {
        name: `Уточнений кошторис, уточнений план асигнувань (за винятком надання кредитів з бюджету) загального фонду
        бюджету, уточнений план надання кредитів із загального фонду бюджету, уточнений план спеціального фонду
        бюджету (за винятком власних надходжень бюджетних установ та відповідних видатків), уточнений план використання
         бюджетних коштів та уточнений помісячний план використання бюджетних коштів`,
        description: '3 робочі дні з дня затвердження змін',
        month: 0,
        day: 0,
        rowNum: 14,
      },
      {
        name: 'Інформація про бюджетні видатки на загальну середню освіту в розрізі закладів загальної середньої '
            + 'освіти (крім приватних, філій опорних шкіл, закладів інтернатного типу (спеціальні, спеціалізовані тощо))',
        description: 'До 10 березня',
        month: 3,
        day: 10,
        rowNum: 15,
      },
      {
        name: 'Інформація про розрахункову потребу у видатках на оплату праці з нарахуваннями працівників ' +
          'закладів загальної середньої освіти, крім приватних, на поточний рік (загальний фонд), станом на 01 лютого',
        description: 'До 20 лютого',
        month: 2,
        day: 20,
        rowNum: 16,
      },
      {
        name: 'Інформація про розрахункову потребу у видатках на оплату праці з нарахуваннями працівників закладів' +
          ' загальної середньої освіти, крім приватних, на поточний рік (загальний фонд), станом на 01 вересня',
        description: 'До 20 вересня',
        month: 9,
        day: 20,
        rowNum: 17,
      },
      {
        name: 'Інформація про видатки на оплату праці з нарахуваннями працівників закладів загальної середньої освіти, ' +
          'крім приватних, у поточному році (загальний фонд)',
        description: 'Щомісяця до 10 числа місяця, наступного за звітним',
        month: 0,
        day: 0,
        rowNum: 18,
      },
      {
        name: 'Інформація про видатки закладів охорони здоров’я у поточному році за КЕКВ 2282 та 2610 (загальний фонд)',
        description: 'Щомісяця до 10 числа місяця, наступного за звітним',
        month: 0,
        day: 0,
        rowNum: 19,
      },
    ],
  },
  {
    header: 'Електронні документи та інформація, які Міністерство фінансів України передає місцевим фінансовим органам:',
    items: [
      {
        name: `Щоденна банківська виписка з рахунків 3241 «Кошти місцевих бюджетів» про здійснені операції`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 1,
      },
      {
        name: `Щоденна інформація про стан виконання місцевих бюджетів за видатками`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 2,
      },
      {
        name: `Щоденна інформація про залишки коштів на рахунках (рахунки, відкриті в органах Казначейства)`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 3,
      },
      {
        name: `Щоденна інформація про зареєстровані бюджетні зобов’язання розпорядників (одержувачів) бюджетних коштів`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 4,
      },
      {
        name: `Щоденна інформація про зареєстровані в органах Казначейства бюджетні фінансові зобов’язання
        розпорядників (одержувачів) коштів місцевих бюджетів`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 5,
      },
      {
        name: `Щоденна інформація про стан виконання доходів місцевих бюджетів по територіях`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 6,
      },
      {
        name: `Відомості про зарахування та повернення коштів з аналітичних рахунків за доходами та з аналітичних
        рахунків, на які зараховуються кошти, що надійдуть в національній валюті на погашення заборгованості за
        кредитами, залученими державою або під державні гарантії, бюджетними позичками та фінансовою допомогою,
        наданою на поворотній основі, які отримуються від Казначейства (центральний рівень) територіальними органами
        Державної казначейської служби України в Автономній Республіці Крим, областях, містах Києві та Севастополі,
         у вигляді технологічних файлів`,
        description: 'Щоденно, до 09:00 першого робочого дня, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 7,
      },
      {
        name: `Суми нарахованих та сплачених податків та/або зборів, суми податкового боргу та надмірно сплачених до
        місцевих бюджетів податків та/або зборів у розрізі джерел доходів бюджету та платників податків - юридичних
        осіб відповідно до адміністративно-територіальних одиниць (територіальних громад)`,
        description: 'До 10 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 8,
      },
      {
        name: `Суми списаного податкового боргу у розрізі джерел доходів бюджету та платників податків - юридичних
        осіб відповідно до адміністративно-територіальних одиниць (територіальних громад)`,
        description: 'До 10 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 9,
      },
      {
        name: `Суми розстрочених і відстрочених грошових зобов’язань та/або податкового боргу платників податків,
        які мають бути сплачені до місцевих бюджетів на відповідних територіях у розрізі джерел доходів бюджету та
        платників податків - юридичних осіб відповідно до адміністративно-територіальних одиниць (територіальних громад)`,
        description: 'До 10 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 10,
      },
      {
        name: `Суми наданих податкових пільг, включаючи втрати доходів бюджету від їх надання у розрізі джерел доходів
         бюджету та платників податків - юридичних осіб відповідно до адміністративно-територіальних одиниць
         (територіальних громад)`,
        description: 'До 10 числа місяця, що настає за звітним',
        month: 0,
        day: 0,
        rowNum: 11,
      },
      {
        name: MonthInfo(),
        description: 'До 25 числа місяця, наступного за звітним',
        month: 0,
        day: 0,
        rowNum: 12,
      },
      {
        name: QuarteInfo(),
        description: 'До 35 дня після закінчення звітного кварталу',
        month: 0,
        day: 0,
        rowNum: 13,
      },
      {
        name: YearInfo(),
        description: 'До 01 квітня',
        month: 4,
        day: 1,
        rowNum: 14,
      },
    ],
  }
];

export default content;
