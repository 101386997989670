import { connect } from 'react-redux';
import { List } from 'immutable';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import TableComponents from './DocsTP';

const TABLENAME = 'filledFromDocument';

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).node, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
  docDate: state.getIn([storePathParam(state).node, storePathParam(state).id, 'headerForm', 'date']),
});

const TP = getBaseTP(
  TABLENAME,
  TableComponents.RowComponent,
  TableComponents.HeaderComponent,
);

export default connect(mapState)(TP);
