import React from 'react';
import PropTypes from 'prop-types';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export function ExecuteButton({ content, onClick, disabled }) {
  return (
    <CPButton
      title="Провести"
      onClick={onClick}
      disabled={disabled}
      content={content}
      icon={faCalendarCheck}
      variant="falcon-success"
    />
  );
}

ExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Провести',
  disabled: false,
};

export default ExecuteButton;
