import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  withoutDocDetails: { label: 'Без деталізації документів', name: 'флНеДетализировать' },
  byNote: { label: 'Відокремити по примітці', name: 'флПоПримечанию' },
  variantNotes: { label: 'Примітка', name: 'ВариантПримечание' },
  notShowCol: {
    label: 'Не показувати залишки асигнувань і зареєстровані зобов\'язання',
    name: 'СтарыйНаборДанных',
  },
};

const definition = {
  label: 'Зведена пропозиція на фінансування',
  name: 'ConsolidateFinProposal',
  columns,
  frontend: 'rep/ConsolidateFinProposal',
  backendName: 'СводПредложенийФинансирование',
  resultColumns: baseResultColumns,
};

export default definition;
