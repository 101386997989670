import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ArrowDown = styled.div`
  transform: rotate(90deg);
  padding: 7px 5px;
  display: flex;
  width: fit-content;
`;

function ArrowDownIcon({ onClick, ...props }) {
  return (
    <ArrowDown onClick={onClick} {...props}>
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0L16 6L8 12V8H0V4H8V0Z" fill="#4281C9" />
      </svg>
    </ArrowDown>
  );
}

ArrowDownIcon.propTypes = {
  onClick: PropTypes.func,
};

ArrowDownIcon.defaultProps = {
  onClick: PropTypes.func,
};

export default ArrowDownIcon;
