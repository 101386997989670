import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Alert,
  Badge, Button, ButtonGroup, Col, ListGroup, ListGroupItem, Row,
} from 'react-bootstrap';

import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import md from '../../../../constants/meta/documents/netCategory';

import { soSelector } from '../../_common/selectors';
import { referencePropType } from '../../../newEditor/propTypes';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import DocSelectionModal from './docSelectionModal';
import { CommandPanelColor, ContainerSt } from '../../../../components/Form/styledForm';
import { emptyUid, selectionMode } from '../../../../constants/meta/common';
import {
  COLOR_1, COLOR_2, COLOR_3,
} from './colors';
import { StampApprovedJs, StampApprovedNew3Js } from '../../../../assets/icons';

function NetCategory({
  data, actions, permissions, findRowByStatus,
}) {
  const sessionOptions = useSelector(soSelector);
  // Вспомогательные занченя для того , чтобы все хозяйство не перерендеривалось

  const readOnly = sessionOptions.get('is_admin', false)
    ? !permissions.canChange
    : !permissions.canChange || data[md.columns.isApproved.name] || data[md.columns.appr.name]
    || data[md.columns.apprByRegion.name];

  const handlerSelector = useCallback(
    (loadData) => {
      actions.onSR('LoadDocs', loadData);
    },
    [actions],
  );

  const warningsByStatus = useMemo(
    () => (data[md.tables.general.name] || []).reduce((R, rec) => {
      const key = rec[md.tables.general.columns.status.name] || 0;
      return {
        ...R,
        [key]: R[key] + 1,
      };
    }, {
      0: 0, 1: 0, 2: 0, 3: 0,
    }),
    [data],
  );
  const showWarning = !!warningsByStatus[1] || !!warningsByStatus[2] || !!warningsByStatus[3];
  const fillAllowed = (!!data[md.columns.allKBPallowed.name]) || (
    data[md.columns.KPKV.name]
    && data[md.columns.KPKV.name]
    && data[md.columns.KPKV.name].id !== emptyUid
  );
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.KPKV.label}
            description="Оберіть КПКВ, по якому заповнити показники з довідника і натисніть кнопку -Заповнити таблицю з довідника-. Якщо КПКВ не обрано - заповнюються усі наявні в довіднику показники"
            value={data[md.columns.KPKV.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.KPKV.name]: value,
            })}
            modelType="cat"
            modelName="kbpClassifier"
            choiceSettings={selectionMode.foldersNItems}
            readOnly={readOnly}
            canErase
          />
        </Col>
        <Col>
          <CommandPanelColor
            label="Заповнити таблицю з довідника"
            disabled={!fillAllowed}
            onClick={() => actions.onSR('FILL')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup>
            <div className="btn btn-outline-info">
              <span>Поточна версія:</span>
              <Badge variant="info">
                {data[md.columns.dictVersion.name] ? data[md.columns.dictVersion.name].repr : 'не заповнено'}
              </Badge>
            </div>
            { data[md.columns.hasNewVersion.name] && (
              <Button variant="warning" onClick={() => actions.onSR('NEW_VERSION')}>
                Перейти на нову версію
              </Button>
            )}
          </ButtonGroup>
        </Col>
      </Row>
      {showWarning && (
        <Alert variant="light">
          <Alert.Heading>
            Увага! Відбувся перехід на нову версію
          </Alert.Heading>
          <ListGroup>
            {!!warningsByStatus[1] && (
              <ListGroupItem variant={COLOR_1} action onClick={() => findRowByStatus(1)}>
                {warningsByStatus[1]}
                {' '}
                строк були змінини.
              </ListGroupItem>
            )}
            {!!warningsByStatus[2] && (
              <ListGroupItem variant={COLOR_2} action onClick={() => findRowByStatus(2)}>
                {warningsByStatus[2]}
                {' '}
                строк були додані як нові.
              </ListGroupItem>
            )}
            {!!warningsByStatus[3] && (
              <ListGroupItem variant={COLOR_3} action onClick={() => findRowByStatus(3)}>
                {warningsByStatus[3]}
                {' '}
                строк були видалені зі старою версіі. Вам потрібно видалити ці стрічки самостійно
              </ListGroupItem>
            )}
          </ListGroup>
        </Alert>
      )}

      {/* <Row> */}
      {/*  <Col> */}
      {/*    <CommandPanelColor */}
      {/*      label="Оновити категорії в документі згідно обраної версії довідника " */}
      {/*      onClick={() => actions.onSR('FILL_NEW')} */}
      {/*    /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row>
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.note.name]: value,
              });
            }}
            readOnly={readOnly}
            canErase
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DocSelectionModal
            onOk={handlerSelector}
            FI={data[md.columns.FI.name]}
            KBP={data[md.columns.KPKV.name]}
            version={data[md.columns.dictVersion.name]}
            readOnly={readOnly}
            nDoc={data[md.columns.number.name]}
            dDoc={data[md.columns.date.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {sessionOptions.get('is_admin', false) && (
            <EditorControls.CheckboxInput
              controlId={`isApproved-${data.id}`}
              label={md.columns.isApproved.label}
              value={data[md.columns.isApproved.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.isApproved.name]: value,
              })}
              readOnly={readOnly}
            />
          )}
        </Col>
        <Col>
          {data[md.columns.isApproved.name] && (
            <>
              <ContainerSt>
                <StampApprovedNew3Js text={data[md.columns.invoice.name]} />
              </ContainerSt>
              {sessionOptions.get('is_admin', false) && (
                <EditorControls.StringInput
                  label={md.columns.invoice.label}
                  value={data[md.columns.invoice.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.invoice.name]: value,
                  })}
                />
              )}
            </>
          )}
        </Col>
        <Col>
          {data[md.columns.apprByRegion.name] && (
            <>
              <ContainerSt>
                <StampApprovedJs text={data[md.columns.regionInvoice.name]} />
              </ContainerSt>
              {sessionOptions.get('is_admin', false) && (
                <EditorControls.StringInput
                  label={md.columns.regionInvoice.label}
                  value={data[md.columns.regionInvoice.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.regionInvoice.name]: value,
                  })}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </EditorCollapse>

  );
}

NetCategory.propTypes = {
  // id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.budget.name]: referencePropType,
    [md.columns.date.name]: PropTypes.number,
    [md.columns.FI.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
  findRowByStatus: PropTypes.func.isRequired,
};

export default NetCategory;
