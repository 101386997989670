import React from 'react';

function PlaceholderIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="20" height="20" rx="2" fill="#D9E6F4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.1161 9.99999L0.80806 1.69195C0.56398 1.44787 0.56398 1.05212 0.80806 0.808037C1.05214 0.563988 1.44789 0.563988 1.69197 0.808037L10 9.11608L18.3081 0.808037C18.5521 0.563988 18.9479 0.563988 19.192 0.808037C19.4361 1.05212 19.4361 1.44787 19.192 1.69195L10.8839 9.99999L19.192 18.308C19.4361 18.5521 19.4361 18.9479 19.192 19.1919C18.9479 19.436 18.5521 19.436 18.3081 19.1919L10 10.8839L1.69197 19.1919C1.44789 19.436 1.05214 19.436 0.80806 19.1919C0.56398 18.9479 0.56398 18.5521 0.80806 18.308L9.1161 9.99999Z" fill="#4281C9" fillOpacity="0.2" />
    </svg>
  );
}

export default PlaceholderIcon;
