/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import {
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {
  PlanningMenuItem,
  MainMenuItem,
  ExecutionMenuItem,
  CatalogsMenuItem,
  ServicesMenuItem,
  FBRMenuItem,
  PCMMenuItem,
  OCBMenuItem,
  AnalyticalInfMenuItem,
  IncomeMenuItem,
  SocialMenuItem,
  ForecastMenuItem,
  EnergyMenuItem,
  BFNMenuItem,
  LogicaMenuItem,
} from '../../containers/dashboard/dashboardMenuPoints';
import mdRep from '../../constants/meta/reports';
import mdDoc from '../../constants/meta/documents';
import mdCat from '../../constants/meta/catalogs';
import mdChTP from '../../constants/meta/ChTP';
import mdDp from '../../constants/meta/dataprocessors';
import mdInfoReg from '../../constants/meta/infoRegs';

import StyledHeaderDocument from '../styledTable/headerDocument';
import { catComponents } from '../../containers/catalogs';
import { docComponents } from '../../containers/documents';
import { repComponents } from '../../containers/reports';
import { dpComponents } from '../../containers/dataProcessors';
import { ChTPComponents } from '../../containers/ChTP';
import { infoRegsComponents } from '../../containers/infoRegs';

import DeveloperService from '../developerService/DeveloperService';
import QuickLinks from '../../containers/reports/quickLinks/QuickLinks';
import ReportsCalendar from '../../minfin/containers/services/reportsCalendar';

import LinkComponent from '../windowsManager/link';
import { hidenWindowsManager } from '../../actions/windowsManager';
import minfin, { componentOnMinfin } from '../../constants/minfin';
import StdReportEditor from '../../minfin/containers/basicReport/routerEditor';
import { soSelector } from '../../containers/documents/_common/selectors';
import PrintingEditor from '../../containers/dataProcessors/printing/editor';
import minfinRoutes from '../../minfin/components/requiredRoutes';

const EmptyBlockText = styled.h1`
  font-weight: 700;
  font-size: 72px;
`;

function hideWinManager({ dispatch }) {
  dispatch(hidenWindowsManager());
  return <></>;
}

hideWinManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStore = (store) => (
  {
    windowsManager: store.get('windowsManager', new Map()),
  }
);
const HideWindowsManager = connect(mapStore)(hideWinManager);

const routNavBar = [
  {
    path: '/',
    component: MainMenuItem,
  },
  {
    path: '/planningDirectory/',
    component: PlanningMenuItem,
  },
  {
    path: '/executionDirectory/',
    component: ExecutionMenuItem,
  },
  {
    path: '/pcmDirectory/',
    component: PCMMenuItem,
  },
  {
    path: '/FBRDirectory/',
    component: FBRMenuItem,
  },
  {
    path: '/OCBDirectory/',
    component: OCBMenuItem,
  },
  {
    path: '/analyticalInfDirectory/',
    component: AnalyticalInfMenuItem,
  },
  {
    path: '/incomeDirectory/',
    component: IncomeMenuItem,
  },
  {
    path: '/socialDirectory/',
    component: SocialMenuItem,
  },
  {
    path: '/forecastDirectory/',
    component: ForecastMenuItem,
  },
  {
    path: '/energyDirectory/',
    component: EnergyMenuItem,
  },
  {
    path: '/catalogsDirectory/',
    component: CatalogsMenuItem,
  },
  // {
  //   path: '/reportsDirectory/',
  //   component: ReportsMenuItem,
  // },
  {
    path: '/servicesDirectory/',
    component: ServicesMenuItem,
  },
  {
    path: '/cat/',
    component: <h1>Справочники, component:</h1>,
  },
  {
    path: '/bfn/',
    component: BFNMenuItem,
  },
  {
    path: '/logica/',
    component: LogicaMenuItem,
  },
  {
    path: '/directors/',
    component: QuickLinks,
  },
];

function AppRouter() {
  const sessionParams = useSelector(soSelector);
  return (
    <Routes>

      <Route
        path="/"
        element={<Outlet />}
      >

        <Route
          exact
          path="/developer/service/"
          element={DeveloperService}
        />

        {minfinRoutes(sessionParams)}

        <Route
          exact
          path="/rep/quickLinks/"
          element={QuickLinks}
        />

        <Route
          exact
          key="cat-editor-reportsCalendar"
          path="/srv/reportsCalendar/"
          element={(
            <LinkComponent
              currentComponent={ReportsCalendar}
              windowsName="Календар звітності"
              type="dp"
              name="reportsCalendar"
            />
          )}
        />

        {routNavBar.map((el) => (
          <Route
            exact
            path={el.path}
            key={el.path}
            element={(
              <>
                <HideWindowsManager />
                <el.component />
              </>
            )}
          />
        ))}

        {/* Catalogs lists */}

        {Object.keys(catComponents).map((el) => {
          const url = `/${mdCat[el].frontend}/`;

          const Comp = componentOnMinfin(mdCat[el], 'Справочник', sessionParams)
            ? minfin[mdCat[el].frontend].lister
            : catComponents[el].lister;
          return (
            <Route
              exact
              key={`cat-list-${el}`}
              path={url}
              element={(
                <StyledHeaderDocument
                  caption={mdCat[el].label}
                >
                  <LinkComponent
                    currentComponent={Comp}
                    windowsName={`Список "${mdCat[el].label}" `}
                    type="cat"
                    name={el}
                  />
                </StyledHeaderDocument>
              )}
            />
          );
        })}

        {Object.keys(catComponents).map((el) => {
          const url = `/${mdCat[el].frontend}/`;
          const Comp = componentOnMinfin(mdCat[el], 'Справочник', sessionParams)
            ? minfin[mdCat[el].frontend].editor
            : catComponents[el].editor;
          return (
            <Route
              exact
              key={`cat-editor-${el}`}
              path={`${url}:id/`}
              element={(
                <LinkComponent
                  currentComponent={Comp}
                  windowsName={`Елем.дов. "${mdCat[el].label} "`}
                  type="cat"
                  name={el}
                />
              )}
            />
          );
        })}

        {/* Documents lists */}

        {Object.keys(docComponents).map((el) => {
          const url = `/${mdDoc[el].frontend}/`;
          const onMF = componentOnMinfin(mdDoc[el], 'Документ', sessionParams)
          const elem = onMF ? (
            <LinkComponent
              currentComponent={minfin[mdDoc[el].frontend].lister}
              windowsName={`Список "${mdDoc[el].label}" `}
              type="doc"
              name={el}
            />
          ) : (
            <StyledHeaderDocument
              caption={mdDoc[el].label}
            >
              <LinkComponent
                currentComponent={docComponents[el].lister}
                windowsName={`Список "${mdDoc[el].label}" `}
                type="doc"
                name={el}
              />
            </StyledHeaderDocument>
          )
          return (
            <Route
              exact
              key={`doc-list-${el}`}
              path={url}
              element={elem}
            />
          );
        })}

        {Object.keys(docComponents).map((el) => {
          const url = `/${mdDoc[el].frontend}/`;
          const Comp = componentOnMinfin(mdDoc[el], 'Документ', sessionParams)
            ? minfin[mdDoc[el].frontend].editor
            : docComponents[el].editor;
          return (
            <Route
              exact
              key={`doc-editor-${el}`}
              path={`${url}:id/`}
              element={(
                <LinkComponent
                  currentComponent={Comp}
                  windowsName={`Документ "${mdDoc[el].label} "`}
                  type="doc"
                  name={el}
                />
              )}
            />
          );
        })}

        {/* Characteristic type plan lists */}

        {Object.keys(ChTPComponents).map((el) => (
          <Route
            exact
            key={`ChTP-editor-${el}`}
            path={`/ChTP/${el}/:id/`}
            element={(
              <StyledHeaderDocument
                caption={mdChTP[el].label}
              >
                <LinkComponent
                  currentComponent={ChTPComponents[el].editor}
                  windowsName={`Елем."${mdChTP[el].label} "`}
                  type="ChTP"
                  name={mdChTP[el].name}
                />
              </StyledHeaderDocument>
            )}
          />
        ))}

        {Object.keys(ChTPComponents).map((el) => (
          <Route
            exact
            key={`ChTP-list-${el}`}
            path={`/ChTP/${el}/`}
            element={(
              <StyledHeaderDocument
                caption={mdChTP[el].label}
              >
                <LinkComponent
                  currentComponent={ChTPComponents[el].lister}
                  windowsName={`Список "${mdChTP[el].label}" `}
                  type="ChTP"
                  name={mdChTP[el].name}
                />
              </StyledHeaderDocument>
            )}
          />
        ))}

        {/* infoRegs */}

        {Object.keys(infoRegsComponents).map((el) => (
          <Route
            exact
            key={`infoRegs-editor-${el}`}
            path={`/infoRegs/${el}/:id/`}
            element={(
              <StyledHeaderDocument
                caption={mdInfoReg[el]?.label}
              >
                <LinkComponent
                  currentComponent={infoRegsComponents[el].editor}
                  windowsName={`Елем."${mdInfoReg[el]?.label} "`}
                  type="infoRegs"
                  name={mdInfoReg[el]?.name}
                />
              </StyledHeaderDocument>
            )}
          />
        ))}

        {Object.keys(infoRegsComponents).map((el) => (
          <Route
            exact
            key={`infoRegs-list-${el}`}
            path={`/infoRegs/${el}/`}
            element={(
              <StyledHeaderDocument
                caption={mdInfoReg[el]?.label}
              >
                <LinkComponent
                  currentComponent={infoRegsComponents[el].lister}
                  windowsName={`Список "${mdInfoReg[el]?.label}" `}
                  type="infoRegs"
                  name={mdInfoReg[el]?.name}
                />
              </StyledHeaderDocument>
            )}
          />
        ))}

        {/* Reports */}

        {Object.keys(repComponents).map((el) => {
          const url = `/${mdRep[el].frontend}/`;
          const onMF = componentOnMinfin(mdRep[el], 'Отчет', sessionParams);
          const elem = onMF ? (
            <LinkComponent
              currentComponent={minfin[mdRep[el].frontend].editor}
              windowsName={`Звіт "${mdRep[el].label}"`}
              type="rep"
              name={mdRep[el].name}
            />
          ) : (
                            <StyledHeaderDocument
                  caption={mdRep[el].label}
                >
                  <LinkComponent
                    currentComponent={repComponents[el].editor}
                    windowsName={`Звіт "${mdRep[el].label}"`}
                    type="rep"
                    name={mdRep[el].name}
                  />
                </StyledHeaderDocument>

          )
          return (
            <Route
              exact
              key={`rep-${el}`}
              path={url}
              element={elem}
            />
          );
        })}

        <Route
          exact
          path="/rep/:reportName/:id"
          element={(
            <LinkComponent
              type="rep"
              currentComponent={StdReportEditor}
              windowsName="Звіт"
            />
          )}
        />

        {/* dataProcessor */}
        <Route
          exact
          key="dp-printing"
          path="/dp/printing/:ad+"
          element={(
            <LinkComponent
              type="dp"
              name="printing"
              currentComponent={PrintingEditor}
              windowsName="Друк"
            />
          )}
        />

        {Object.keys(dpComponents).filter((el) => dpComponents[el].isRoute).map((el) => {
          const url = `/${mdDp[el].frontend}/`;
          const onMF = componentOnMinfin(mdDp[el], 'Обработка', sessionParams);
          const elem = onMF ? (
            <LinkComponent
              currentComponent={minfin[mdDp[el].frontend].editor}
              windowsName={mdDp[el].label}
              type="srv"
              name={mdDp[el].name}
            />
          ) : (
            <StyledHeaderDocument
              caption={mdDp[el].label}
            >
              <LinkComponent
                currentComponent={dpComponents[el].editor}
                windowsName={mdDp[el].label}
                type="dp"
                name={mdDp[el].name}
              />
            </StyledHeaderDocument>
          )
          return (
            <Route
              exact
              key={`dp-${el}`}
              path={url}
              element={elem}
            />
          );
        })}

        <Route element={(<EmptyBlockText>Нема сторiнки</EmptyBlockText>)} />
      </Route>
    </Routes>
  );
}

export default memo(AppRouter);
