import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import classNames from 'classnames';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import corner1 from '../../../../assets/img/illustrations/corner-3.png';
import corner2 from '../../../../assets/img/illustrations/corner-2.png';
import Background from '../../../../components/blanks/common/Background';
import SoftBadge from '../../../../components/blanks/common/SoftBadge';
import TransfertToTab from './TransfertToTab';
import TransfertFromTab from './TransfertFromTab';
import { Loader } from '../../../../components/bootStrap';
import useFetchTransfertData from '../Hooks/useFetchTransfertData';

function Transfert({ cDt }) {
  const { isLoading, err, transferProject } = useFetchTransfertData({ cDt });
  return (
    <>
      {isLoading && (
        <Loader text="Завантаження" />
      )}
      <Row className="g-3 mt-0">
        <Col md={6} lg={6} xxl={6} className="d-flex justify-content-between flex-column">

          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner1}
              className="bg-card"
            />
            <Card.Header>
              <div id="spend-zf">
                <h4 className="text-warning mb-0">Трансферти до загального фонду бюджету</h4>
                <h6 className="text-700">
                  згідно дод.5 до рішення сесії про бюджет
                </h6>
                <div className="d-flex flex-wrap gap-2 align-items-baseline">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={faCaretDown} />
                    {' '}
                    {transferProject.zf_in.percent}
                    {' '}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від
                    {' '}
                    {parseInt(cDt.substring(0, 4), 10) - 1}
                    р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-warning',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={transferProject.zf_in.total}
                  duration={2.75}
                  suffix=" тис.грн."
                  prefix=""
                  err={err}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={6} xxl={6} className="d-flex justify-content-between flex-column">
          <Card className="bg-transparent-50 overflow-hidden">
            <Background
              image={corner2}
              className="bg-card"
            />
            <Card.Header>
              <div id="spend-sf">
                <h4 className="text-info mb-0">Трансферти із загального фонду бюджету</h4>
                <h6 className="text-700">
                  згідно дод.5 до рішення сесії про бюджет
                </h6>
                <div className="d-flex flex-wrap gap-2 align-items-baseline">
                  <SoftBadge bg="warning" pill>
                    <FontAwesomeIcon icon={faCaretDown} />
                    {' '}
                    {transferProject.zf_out.percent}
                    {' '}
                    %
                  </SoftBadge>
                  <h6 className="text-700">
                    від
                    {' '}
                    {parseInt(cDt.substring(0, 4), 10) - 1}
                    р.
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="position-relative">
              <div
                className={classNames(
                  'text-info',
                  'display-4 fs-lg-2 fs-1 fw-normal font-sans-serif',
                )}
              >
                <CountUp
                  start={0}
                  end={transferProject.zf_out.total}
                  duration={2.75}
                  suffix=" тис.грн."
                  prefix=""
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </div>
            </Card.Body>
          </Card>

        </Col>
      </Row>
      {/* <Row className="g-3 mt-0"> */}
      {/*  <Col md={12} lg={12} xxl={12}> */}
      {/*    <TransfertChart /> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row className="g-3 mt-0 d-none d-sm-flex">
        <Col md={6} lg={6} xxl={6}>
          <TransfertToTab
            data={transferProject.transfer_in}
          />
        </Col>
        <Col md={6} lg={6} xxl={6}>
          <TransfertFromTab
            data={transferProject.transfer_out}
          />
        </Col>
      </Row>
    </>
  );
}
Transfert.propTypes = {
  cDt: PropTypes.string.isRequired,
};
export default Transfert;
