import React from 'react';
import PropTypes from 'prop-types';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import useSendLogika from '../hooks/sendToLogika';
import { CPButton } from '../../../../components/bootStrap/buttons';

function SendToLogicaButton({ url, documnetId, disabled }) {
  const { handleSendLogika, loading } = useSendLogika({ url, documnetId });
  return (
    <CPButton
      content="Надіслати до Логіка"
      onClick={handleSendLogika}
      disabled={disabled || !documnetId || loading}
      title="Надіслати до Логіка. Документ повинен бути записаний та проведений."
      icon={faPaperPlane}
      variant="falcon-info"
      spin={loading}
    />

  );
}

SendToLogicaButton.propTypes = {
  url: PropTypes.string.isRequired,
  documnetId: PropTypes.number,
  disabled: PropTypes.bool,
};

SendToLogicaButton.defaultProps = {
  documnetId: null,
  disabled: false,
};

export default SendToLogicaButton;
