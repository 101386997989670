import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { importDocs } from '../../../meta/srv';

function DropdownDocs({ selectedDocs, setSelectedDocs }) {
  const handleCheckboxChange = (item) => {
    if (selectedDocs.includes(item)) {
      setSelectedDocs(selectedDocs.filter((id) => id !== item));
    } else {
      setSelectedDocs([...selectedDocs, item]);
    }
  };
  const endOfWord = () => {
    switch (selectedDocs.length) {
      case 1:
        return '';
      case 2:
      case 3:
      case 4:
        return 'и';
      default:
        return 'ів';
    }
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
        {`Обрано ${selectedDocs.length} вид${endOfWord()} документ${endOfWord()}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(importDocs).map((item) => (
          <Dropdown.Item key={item} onClick={() => handleCheckboxChange(item)}>
            <FontAwesomeIcon icon={selectedDocs.includes(item) ? faCheck : faSquare} className="me-2" />
            <span>{importDocs[item].name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DropdownDocs.propTypes = {
  selectedDocs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedDocs: PropTypes.func.isRequired,
};

export default DropdownDocs;
