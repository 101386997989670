import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { StyledLabel } from '../../../../../../components/Form/styledForm';
import { moneysStingOptions } from '../../../../../../constants/common';

function TPFooter({ docTotalCost, docTotalCostBr, inBR }) {
  return (
    <Container fluid>
      <Row>
        <Col>
          <StyledLabel style={{ fontWeight: 'bold' }}>
            { `Сума всього : ${docTotalCost.toLocaleString('uk', moneysStingOptions)}`}
          </StyledLabel>
        </Col>
        {inBR && (
        <Col>
          <StyledLabel style={{ fontWeight: 'bold' }}>
            { `Сума БР всього : ${docTotalCostBr.toLocaleString('uk', moneysStingOptions)}`}
          </StyledLabel>
        </Col>
        )}
      </Row>
    </Container>
  );
}

TPFooter.propTypes = {
  docTotalCost: PropTypes.number.isRequired,
  docTotalCostBr: PropTypes.number.isRequired,
  inBR: PropTypes.bool,
};

TPFooter.defaultProps = {
  inBR: false,
};

export default memo(TPFooter);
