/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import SendToHOCModal from './sendToHOCModal';
import FinRequestPrintModal from '../lister/listerPrintModal';
import RequestInfoModal from '../lister/reqInfoModal';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import AttachedFilesList from '../../../newEditor/attachedFiles';

function APMenu({ id, data, ...rest }) {
  const finRequestsHOC = useSelector((store) => store.getIn(['auth', 'sessionOptions', 'finRequestsHOC'], false));
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <>
        <ButtonGroup>
          <Dropdown>
            <Dropdown.Toggle size="sm">
              <FontAwesomeIcon icon={faPrint} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <FinRequestPrintModal selectedRows={selectedRows} />
              <RequestInfoModal selectedRows={selectedRows} />
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
        {finRequestsHOC && (
          <ButtonGroup>
            <SendToHOCModal
                id={id}
                data={data}
            />
          </ButtonGroup>
        )}
      </>
      <AttachedFilesList
        data={data}
        {...rest}
      />
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default APMenu;
