import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  code: {
    label: 'Код', name: 'Код', visible: false, width: 'fit-content(150px)',
  },
  name: { label: 'Найменування', name: 'Наименование', visible: true },
  nVersion: { label: 'Номер версії', name: 'НомерВерсии', visible: true },
  year: { label: 'Рік', name: 'Год', visible: true },
  planFact: { label: 'Планові дані чи фактмчні', name: 'ПланФакт', visible: true },
  orderName: { label: 'Наказ', name: 'Наказ', visible: true },
  isAppr: { label: 'Ознака затвердження', name: 'ОзнакаЗатвердження', visible: true },

};

export const tarif = {
  label: 'Версії довідника =Категорії працівників для мережі, штатів та контингентів=',
  name: 'categoriesFOPVersions',
  backendName: 'КатегорииФОПВерсии',
  defaultOrder,
  columns,
  frontend: 'cat/categoriesFOPVersions',
  listColumns: [...(new Set(['nVersion', 'year', ...reqListerColumns]))],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default tarif;
