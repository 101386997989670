import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BasicEditor, { mapState, DCControls } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/treasureFilesAnalysis';
import {
  ResultSegment,
  StyledLabel,
  FiveColumnsGridContainer,
  CommandPanelButtonText,
  ContainerDiv,
  StyledPane,
  DivWithPropsLabel, ContainerThreeColumn,
} from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';
import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';

import enums from '../../../constants/meta/enums';
import Tabs from '../../../components/tabs/Tabs';

import ReportContainer from '../reportContainer/variantReportContainer';

import { InputWithDropdown } from '../../../components/styledInputs';

import YearField from '../../field/yearInput';

const YearInput = YearField;

const FiveColGridContainer = styled(FiveColumnsGridContainer)`
  grid-template-columns: 15% 23% 23% 15% 19%;
`;

const CommandPanelButText = styled(CommandPanelButtonText)`
  color: #4281C9;
  padding: 10.5px 10px;
`;

function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const ListDate = useMemo(() => headerForm.get('listDate', new Map()).reduce(
    (r, i) => [...r, { name: i.get('repr'), label: i.get('repr') }],
    [],
  ), [headerForm]);

  const isShowVariantBudget = [
    { name: 0, label: 'За Тл.ТЕРРА' },
    { name: 2, label: 'За довідником' },
    { name: 1, label: 'За кодом' },
  ];

  const sortListDate = useMemo(() => headerForm
    .get('listDate', new Map())
  // eslint-disable-next-line array-callback-return
    .sort((a, b) => {
      if (a.get('id') < b.get('id')) { return 1; }
      if (a.get('id') > b.get('id')) { return -1; }
      if (a.get('id') === b.get('id')) { return 0; }
    })
    .reduce(
      (r, i) => [...r, { name: i.get('repr'), label: i.get('repr') }],
      [],
    ), [headerForm]);

  const Bookmarks = (
    <Tabs>
      <DivWithPropsLabel label="Группування">
        <StyledPane>
          <DCControls.Structure />
        </StyledPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="Відбір">
        <StyledPane>
          <DCControls.Filter />
        </StyledPane>
      </DivWithPropsLabel>
    </Tabs>
  );

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin || headerForm.get('byHighLevelCSU', false) ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const headerUpdater = (path) => (e, value) => {
    dispatch(changeField(['headerForm', path], value));
  };

  const headerUpdaterDt = (path1, path2) => (e, value) => {
    dispatch(changeField(['headerForm', path1], value));
    const fl = ListDate.filter((el) => el.label === value);

    if (fl.length > 0) {
      dispatch(changeField(['headerForm', path2], fl[0].name));
    }
  };

  const inFinDep = headerForm.get('inFinDep', false);

  const SettingsButton = (
    <div>
      <ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>
        <CheckBoxField
          value={headerForm.get('consolidateBudget', false)}
          label={md.columns.consolidateBudget.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidateBudget'], v))}
        />
      </ContainerThreeColumn>
      <FiveColGridContainer>

        <div>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <YearInput
            value={headerForm.get('year', 0)}
            onChange={(e, v) => {
              headerUpdater('year');
              dispatch(changeField(['headerForm', 'year'], v));
              dispatch(processServerReq('REFRESH_DATE_DOC_TREASURE'));
            }}
            disableCalculator
          />
        </div>

        <div>
          <StyledLabel>{md.columns.startDate.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('startDt')}
            options={ListDate}
            onChange={headerUpdaterDt('startDt', 'startDate')}
          />
        </div>

        <div>
          <StyledLabel>{md.columns.endDate.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('endDt')}
            options={sortListDate}
            onChange={
              headerUpdaterDt('endDt', 'endDate')
            }
          />
        </div>

        <CommandPanelButText
          style={{ padding: '6px' }}
          label="Оновити дати"
          onClick={() => dispatch(processServerReq('REFRESH_DATE_DOC_TREASURE'))}
        />

      </FiveColGridContainer>
      <FiveColGridContainer>
        <div>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('planType', false)}
            options={Object.values(enums.PlaningKind)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
          />
        </div>

        <div>
          <StyledLabel>{md.columns.isShowVariantBudget.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('isShowVariantBudget')}
            options={isShowVariantBudget}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowVariantBudget'], v))}
          />
        </div>
        {inFinDep && (
          <CheckBoxField
            value={headerForm.get('approved', false)}
            label={md.columns.approved.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
          />
        )}
      </FiveColGridContainer>
      <FiveColGridContainer>
        <div>
          <CheckBoxField
            value={headerForm.get('isPlanLevel', false)}
            label={md.columns.isPlanLevel.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isPlanLevel'], v))}
          />
          <CheckBoxField
            value={headerForm.get('isSortByNameCsu', false)}
            label={md.columns.isSortByNameCsu.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isSortByNameCsu'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('byLowLevel', false)}
            label={md.columns.byLowLevel.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'byLowLevel'], v))}
          />
          <CheckBoxField
            value={headerForm.get('isShowKEKV0000', false)}
            label={md.columns.isShowKEKV0000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowKEKV0000'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('in1000', false)}
            label={md.columns.in1000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
          <CheckBoxField
            value={headerForm.get('notShowCodeSCU', false)}
            label={md.columns.notShowCodeSCU.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'notShowCodeSCU'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('isShowParamReport', false)}
            label={md.columns.isShowParamReport.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowParamReport'], v))}
          />
          <CheckBoxField
            value={headerForm.get('customSettings', false)}
            label={md.columns.customSettings.label}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'customSettings'], v));
              dispatch(processServerReq('CUSTOM_SETTINGS'));
            }}
          />
        </div>
        <CheckBoxField
          value={headerForm.get('isDayShow', false)}
          label={md.columns.isDayShow.label}
          onChange={(e, v) => {
            dispatch(changeField(['headerForm', 'isDayShow'], v));
          }}
        />
      </FiveColGridContainer>
      <FiveColGridContainer>
        <div>
          <CheckBoxField
            value={headerForm.get('autoCr', false)}
            label={md.columns.autoCr.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'autoCr'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('checkNo', false)}
            label={md.columns.checkNo.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'checkNo'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('KEKVShr', false)}
            label={md.columns.KEKVShr.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'KEKVShr'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('ShowD', false)}
            label={md.columns.ShowD.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'ShowD'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('justCashier', false)}
            label={md.columns.justCashier.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'justCashier'], v))}
          />
        </div>
      </FiveColGridContainer>

      {Bookmarks}
      <div style={{ float: 'right' }}>
        <CommandPanelButtonText
          onClick={() => dispatch(processServerReq('STD_SETTINGS'))}
        >
          Встановити стандартні налаштування
        </CommandPanelButtonText>

      </div>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      <ContainerDiv>
        <div style={{ display: 'flex' }}>
          <div>
            <StyledLabel>{md.columns.startDate.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('startDt')}
              options={ListDate}
              onChange={headerUpdaterDt('startDt', 'startDate')}
            />
          </div>

          <div style={{ margin: '0 10px' }}>
            <StyledLabel>{md.columns.endDate.label}</StyledLabel>
            <InputWithDropdown
              value={headerForm.get('endDt')}
              options={sortListDate}
              onChange={headerUpdaterDt('endDt', 'endDate')}
            />
          </div>
        </div>

      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
