const frontendURL = '/cat/sourceofcoverage/';
const backendURL = '/api/references/refsourceofcoverage/';
const name = 'Класифікація джерел покриття';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
