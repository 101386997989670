import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  ButtonGroup, ButtonToolbar, Col, Container, Navbar, Row,
} from 'react-bootstrap';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import IconButton from '../../../../components/blanks/common/IconButton';

function TPCommandPanel({
  tableData,
  selectedIDs,
  setSelectedIDs,
}) {
  const legendItems = [
    { color: 'bg-soft-dark', label: 'Завантажено' },
    { color: 'bg-soft-success', label: 'Обрано для завантаження' },
    { color: 'bg-soft-warning', label: 'Створено в цій програмі і завантажено в Logica' },
  ];
  const docsListLength = useMemo(
    () => (
      tableData ? Object.values(tableData).filter(
        (value) => value.some((row) => !(row.from_db || row.loaded)),
      ).reduce(
        (acc, currentValue) => acc.concat(currentValue),
        [],
      ) : []
    ).length,
    [tableData],
  );
  const selectedIdsLength = useMemo(
    () => (
      selectedIDs ? Object.values(selectedIDs).reduce(
        (acc, currentValue) => acc.concat(currentValue),
        [],
      ) : []
    ).length,
    [selectedIDs],
  );

  return (
    <Navbar className="bg-white border-bottom rounded-top">
      <ButtonToolbar className="mx-2">
        <ButtonGroup>
          <IconButton
            variant="falcon-default"
            size="sm"
            className="ms-sm-1"
            onClick={() => {
              if (selectedIdsLength === docsListLength) {
                setSelectedIDs({});
              } else {
                const newObject = {};
                Object.keys(tableData).forEach((key) => {
                  if (Array.isArray(tableData[key])) {
                    newObject[key] = tableData[key]
                      .filter((row) => !(row.from_db || row.loaded))
                      .map((item) => item.id);
                  } else {
                    newObject[key] = tableData[key];
                  }
                });
                setSelectedIDs(newObject);
              }
            }}
            icon={
              selectedIdsLength === docsListLength ? faSquare : faSquareCheck
          }
          />
        </ButtonGroup>
      </ButtonToolbar>
      <Container>
        <Row>
          {legendItems.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={index} className={item.color}>
              {item.label}
            </Col>
          ))}
        </Row>
      </Container>
      <div className="ms-auto">
        Відмічено
        <Badge className="mx-2" variant="dark">{selectedIdsLength}</Badge>
        {`документ${(() => {
          switch (selectedIdsLength) {
            case 1:
              return '';
            case 2:
            case 3:
            case 4:
              return 'и';
            default:
              return 'ів';
          }
        })()} `}
      </div>
    </Navbar>
  );
}

TPCommandPanel.propTypes = {
  tableData: PropTypes.shape(PropTypes.arrayOf({})),
  selectedIDs: PropTypes.shape(PropTypes.arrayOf(PropTypes.string)),
  setSelectedIDs: PropTypes.func.isRequired,
};

TPCommandPanel.defaultProps = {
  tableData: {},
  selectedIDs: {},
};

export default TPCommandPanel;
