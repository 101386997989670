import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';

function SpecialFondByKDBRowSet({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'kdb',
        },
        ...[...Array(3)].map((_, i) => ({
          field: `amount_${i + 1}`,
          headerLabel: `План на ${data.year + i + 1}р.`,
        })),
      ],
    }),
    [data.year],
  );

  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="forecastprepareadd1specialfondbykdb_set"
    />
  );
}

SpecialFondByKDBRowSet.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
  }).isRequired,
};

export default SpecialFondByKDBRowSet;
