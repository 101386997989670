import React, { forwardRef, useContext } from 'react';

import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

import ListerContext from '../../../newLister/context';

const CommandPanel = forwardRef((_, ref) => {
  // eslint-disable-next-line no-empty-pattern
  const {
  } = useContext(ListerContext);

  return (
    <DocListerCommandPanel
      ref={ref}
    />

  );
});
export default CommandPanel;
