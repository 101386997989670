import AddressListCR from './addressListCR';
import AssigmentPlaning from './assigmentPlaning';
import FinancingAnalysis from './financingAnalysis';
import CostAnalysisPlanFact from './costAnalysisPlanFact';
import CountContractsTenders from './countContractsTenders';
import CompareLevels from './compareLevels';
import PaymentList from './paymentList';
import PivotRequest from './pivotRequest';
import PivotRequestKMDA from './pivotRequestKMDA';
import TreasureFilesAnalysis from './treasureFilesAnalysis';
import RevenueAnalysis from './revenueAnalysis';
import RequestAnalysis from './requestAnalysis';
import RequestAnalysisAdd from './requestAnalysisAdd';
import RevenueAnalysisVariety from './revenueAnalysisVariety';
import DocFinAnalysis from './docFinAnalysis';
import Estimate from './estimate';
import Balans from './balans';
import ConsolidateFinProposal from './consolidateFinProposal';
import TreasureReport from './treasureReport';
import TreasureReportStatement from './treasureReportStatement';
import TreasureReportStatementCut from './treasureReportStatementCut';
import TreasureReportStatementCutKiev from './treasureReportStatementCutKiev';
import TreasureReports from './treasureReports';
import TreasureFilesObligationsAnalysis from './treasureFilesObligationsAnalysis';
import SourceAssigment from './sourceAssigment';
import AnalysisSpentCashe from './analysisSpentCashe';
import TurnoverSheetTreasureAccount from './turnoverSheetTreasureAccount';
import ReportByBR from './reportByBR';
import FinSourceAnalysis from './finSourceAnalysis';
import PlanOfUse from './planOfUse';
import YearAssigment from './yearAssigment';
import AnalysisFinProtectItems from './analysisFinProtectItems';
import ConsolidatePartnersFinProposal from './consolidatePartnersFinProposal';
import LimitSPR from './limitSPR';
import PassportAnalisys from './passportAnalisys';
import SessionAdd1 from './sessionAdd1';
import SessionAdd2 from './sessionAdd2';
import SessionAdd3 from './sessionAdd3';
import SessionAdd4 from './sessionAdd4';
import SessionAdd5Transfert from './sessionAdd5Transfert';
import SessionAdd6BR from './sessionAdd6BR';
import SessionAdd7 from './sessionAdd7';
import SessionAdd8Loan from './sessionAdd8Loan';
import MixIndicatorsSF from './mixIndicatorsSF';
import ReportBalanceOfAccounts from './reportBalanceOfAccounts';
import FinancingOfBRAnalysis from './financingOfBRAnalysis';
import NationalCurrencyNote from './nationalCurrencyNote';
import FactExpence from './factExpence';
import InfoAboutFinancing from './infoAboutFinancing';
import PassportReportAnalysis from './passportReportAnalysis';
import ReportAnalysis from './reportAnalysis';
import ReportSocial from './reportSocial';
import ReportSocial1 from './reportSocial1';
import ReportSocial2 from './reportSocial2';
import RegionAssigmentsAnalisys from './regionAssigmentsAnalisys';
import RegionRevenuePlanAnalysis from './regionRevenuePlanAnalysis';
import RegionAssigmentsFactAnalisys from './regionAssigmentsFactAnalisys';
import RegionRevenueFactDaily from './regionRevenueFactDaily';
import RegionRevenueStatement from './regionRevenueStatement';
import RegionRevenueFactAnalysis from './regionRevenueFactAnalysis';
import AnalysisIndicatorsSF from './analysisIndicatorsSF';
import UsersMonitor from './usersMonitor';
import ObjBuildingAddressList from './objBuildingAddressList';
import AnalysisGOV from './analysisGOV';
import AnalysisForecastsPP3 from './analysisForecastsPP3';
import RevTreasureReport from './revTreasureReport';
import GovPlanSpend from './govPlanSpend';
import RevenuePlan from './revenuePlan';
import CertNationalCurr from './certNationalCurr';
import ExpensesBudget from './expensesBudget';
import TreasureOffer from './treasureOffer';
import NetFOPreduce from './netFOPreduce';
import NetFOPPlanreduce from './netFOPPlanreduce';
import EnergyReport from './energyReport';
import TransfertPlanning from './transfertPlanning';
import BrAnalysisSKD from './brAnalysisSKD';
import IndicatorFactAnalysisSKD from './indicatorFactAnalysisSKD';
import PassportAnalysisSKD from './passportAnalysisSKD';
import LiquidityMethod from './liquidityMethod';
import ComparePassport from './comparePassport';

const repComponents = {
  AddressListCR: {
    editor: AddressListCR,
  },
  AssigmentPlaning: {
    editor: AssigmentPlaning,
  },
  GovPlanSpend: {
    editor: GovPlanSpend,
  },
  FinancingAnalysis: {
    editor: FinancingAnalysis,
  },
  CostAnalysisPlanFact: {
    editor: CostAnalysisPlanFact,
  },
  CountContractsTenders: {
    editor: CountContractsTenders,
  },
  CompareLevels: {
    editor: CompareLevels,
  },
  PaymentList: {
    editor: PaymentList,
  },
  PivotRequest: {
    editor: PivotRequest,
  },
  PivotRequestKMDA: {
    editor: PivotRequestKMDA,
  },
  TreasureFilesAnalysis: {
    editor: TreasureFilesAnalysis,
  },
  RevenueAnalysis: {
    editor: RevenueAnalysis,
  },
  RequestAnalysis: {
    editor: RequestAnalysis,
  },
  RequestAnalysisAdd: {
    editor: RequestAnalysisAdd,
  },
  DocFinAnalysis: {
    editor: DocFinAnalysis,
  },
  Estimate: {
    editor: Estimate,
  },
  RevenueAnalysisVariety: {
    editor: RevenueAnalysisVariety,
  },
  ConsolidateFinProposal: {
    editor: ConsolidateFinProposal,
  },
  PlanOfUse: {
    editor: PlanOfUse,
  },
  YearAssigment: {
    editor: YearAssigment,
  },
  TreasureReport: {
    editor: TreasureReport,
  },
  TreasureReportStatement: {
    editor: TreasureReportStatement,
  },
  TreasureReportStatementCut: {
    editor: TreasureReportStatementCut,
  },
  TreasureReportStatementCutKiev: {
    editor: TreasureReportStatementCutKiev,
  },
  TreasureReports: {
    editor: TreasureReports,
  },
  TreasureFilesObligationsAnalysis: {
    editor: TreasureFilesObligationsAnalysis,
  },
  AnalysisFinProtectItems: {
    editor: AnalysisFinProtectItems,
  },
  SourceAssigment: {
    editor: SourceAssigment,
  },
  ConsolidatePartnersFinProposal: {
    editor: ConsolidatePartnersFinProposal,
  },
  AnalysisSpentCashe: {
    editor: AnalysisSpentCashe,
  },
  TurnoverSheetTreasureAccount: {
    editor: TurnoverSheetTreasureAccount,
  },
  ReportByBR: {
    editor: ReportByBR,
  },
  FinSourceAnalysis: {
    editor: FinSourceAnalysis,
  },
  LimitSPR: {
    editor: LimitSPR,
  },
  PassportAnalisys: {
    editor: PassportAnalisys,
  },
  MixIndicatorsSF: {
    editor: MixIndicatorsSF,
  },
  Balans: {
    editor: Balans,
  },
  SessionAdd1: {
    editor: SessionAdd1,
  },
  SessionAdd2: {
    editor: SessionAdd2,
  },
  SessionAdd3: {
    editor: SessionAdd3,
  },
  SessionAdd4: {
    editor: SessionAdd4,
  },
  SessionAdd5Transfert: {
    editor: SessionAdd5Transfert,
  },
  SessionAdd6BR: {
    editor: SessionAdd6BR,
  },
  SessionAdd7: {
    editor: SessionAdd7,
  },
  SessionAdd8Loan: {
    editor: SessionAdd8Loan,
  },
  ReportBalanceOfAccounts: {
    editor: ReportBalanceOfAccounts,
  },
  FinancingOfBRAnalysis: {
    editor: FinancingOfBRAnalysis,
  },
  NationalCurrencyNote: {
    editor: NationalCurrencyNote,
  },
  FactExpence: {
    editor: FactExpence,
  },
  InfoAboutFinancing: {
    editor: InfoAboutFinancing,
  },
  PassportReportAnalysis: {
    editor: PassportReportAnalysis,
  },
  ReportAnalysis: {
    editor: ReportAnalysis,
  },
  ReportSocial: {
    editor: ReportSocial,
  },
  ReportSocial1: {
    editor: ReportSocial1,
  },
  ReportSocial2: {
    editor: ReportSocial2,
  },
  RegionAssigmentsAnalisys: {
    editor: RegionAssigmentsAnalisys,
  },
  RegionRevenuePlanAnalysis: {
    editor: RegionRevenuePlanAnalysis,
  },
  RegionAssigmentsFactAnalisys: {
    editor: RegionAssigmentsFactAnalisys,
  },
  RegionRevenueFactDaily: {
    editor: RegionRevenueFactDaily,
  },
  RegionRevenueStatement: {
    editor: RegionRevenueStatement,
  },
  RegionRevenueFactAnalysis: {
    editor: RegionRevenueFactAnalysis,
  },
  AnalysisIndicatorsSF: {
    editor: AnalysisIndicatorsSF,
  },
  UsersMonitor: {
    editor: UsersMonitor,
  },
  ObjBuildingAddressList: {
    editor: ObjBuildingAddressList,
  },
  AnalysisGOV: {
    editor: AnalysisGOV,
  },
  AnalysisForecastsPP3: {
    editor: AnalysisForecastsPP3,
  },
  RevTreasureReport: {
    editor: RevTreasureReport,
  },
  RevenuePlan: {
    editor: RevenuePlan,
  },
  CertNationalCurr: {
    editor: CertNationalCurr,
  },
  ExpensesBudget: {
    editor: ExpensesBudget,
  },
  TreasureOffer: {
    editor: TreasureOffer,
  },
  NetFOPreduce: {
    editor: NetFOPreduce,
  },
  NetFOPPlanreduce: {
    editor: NetFOPPlanreduce,
  },
  // Отчеты только на стороне minfin
  BfnCommoninStitutionstructure: {
    editor: null,
  },
  // CommonRepPanel: {
  //   editor: null,
  // },
  EnergyReport: {
    editor: EnergyReport,
  },
  TransfertPlanning: {
    editor: TransfertPlanning,
  },
  BrAnalysisSKD: {
    editor: BrAnalysisSKD,
  },
  IndicatorFactAnalysisSKD: {
    editor: IndicatorFactAnalysisSKD,
  },
  PassportAnalysisSKD: {
    editor: PassportAnalysisSKD,
  },
  LiquidityMethod: {
    editor: LiquidityMethod,
  },
  ComparePassport: {
    editor: ComparePassport,
  },
};

export {
  repComponents,

  AddressListCR,
  AssigmentPlaning,
  FinancingAnalysis,
  CostAnalysisPlanFact,
  CountContractsTenders,
  CompareLevels,
  PaymentList,
  PivotRequest,
  PivotRequestKMDA,
  TreasureFilesAnalysis,
  RevenueAnalysis,
  RequestAnalysis,
  RequestAnalysisAdd,
  DocFinAnalysis,
  Estimate,
  RevenueAnalysisVariety,
  ConsolidateFinProposal,
  PlanOfUse,
  YearAssigment,
  TreasureReport,
  TreasureReportStatement,
  TreasureReportStatementCut,
  TreasureReportStatementCutKiev,
  TreasureReports,
  TreasureFilesObligationsAnalysis,
  AnalysisFinProtectItems,
  SourceAssigment,
  ConsolidatePartnersFinProposal,
  AnalysisSpentCashe,
  TurnoverSheetTreasureAccount,
  ReportByBR,
  FinSourceAnalysis,
  LimitSPR,
  PassportAnalisys,
  MixIndicatorsSF,
  Balans,
  SessionAdd1,
  SessionAdd2,
  SessionAdd3,
  SessionAdd4,
  SessionAdd5Transfert,
  SessionAdd6BR,
  SessionAdd7,
  SessionAdd8Loan,
  ReportBalanceOfAccounts,
  FinancingOfBRAnalysis,
  NationalCurrencyNote,
  FactExpence,
  InfoAboutFinancing,
  ReportSocial,
  ReportSocial1,
  ReportSocial2,
  RegionAssigmentsAnalisys,
  RegionRevenuePlanAnalysis,
  RegionRevenueFactDaily,
  RegionRevenueStatement,
  RegionRevenueFactAnalysis,
  AnalysisIndicatorsSF,
  UsersMonitor,
  ObjBuildingAddressList,
  AnalysisGOV,
  AnalysisForecastsPP3,
  RevTreasureReport,
  GovPlanSpend,
  RevenuePlan,
  CertNationalCurr,
  ExpensesBudget,
  TreasureOffer,
  NetFOPreduce,
  NetFOPPlanreduce,
  EnergyReport,
  TransfertPlanning,
  BrAnalysisSKD,
  IndicatorFactAnalysisSKD,
  PassportAnalysisSKD,
  LiquidityMethod,
  ComparePassport,
};

export default {

  repComponents,
  AddressListCR,
  AssigmentPlaning,
  FinancingAnalysis,
  CostAnalysisPlanFact,
  CountContractsTenders,
  CompareLevels,
  PaymentList,
  PivotRequest,
  PivotRequestKMDA,
  TreasureFilesAnalysis,
  RevenueAnalysis,
  RequestAnalysis,
  RequestAnalysisAdd,
  DocFinAnalysis,
  Estimate,
  RevenueAnalysisVariety,
  ConsolidateFinProposal,
  PlanOfUse,
  YearAssigment,
  TreasureReport,
  TreasureReportStatement,
  TreasureReportStatementCut,
  TreasureReportStatementCutKiev,
  TreasureReports,
  TreasureFilesObligationsAnalysis,
  AnalysisFinProtectItems,
  SourceAssigment,
  ConsolidatePartnersFinProposal,
  AnalysisSpentCashe,
  TurnoverSheetTreasureAccount,
  ReportByBR,
  FinSourceAnalysis,
  LimitSPR,
  PassportAnalisys,
  MixIndicatorsSF,
  Balans,
  SessionAdd1,
  SessionAdd2,
  SessionAdd3,
  SessionAdd4,
  SessionAdd5Transfert,
  SessionAdd6BR,
  SessionAdd7,
  SessionAdd8Loan,
  ReportBalanceOfAccounts,
  FinancingOfBRAnalysis,
  NationalCurrencyNote,
  FactExpence,
  InfoAboutFinancing,
  ReportSocial,
  ReportSocial1,
  ReportSocial2,
  RegionAssigmentsAnalisys,
  RegionRevenuePlanAnalysis,
  RegionRevenueFactDaily,
  RegionRevenueStatement,
  RegionRevenueFactAnalysis,
  AnalysisIndicatorsSF,
  UsersMonitor,
  ObjBuildingAddressList,
  AnalysisGOV,
  AnalysisForecastsPP3,
  RevTreasureReport,
  GovPlanSpend,
  RevenuePlan,
  CertNationalCurr,
  ExpensesBudget,
  NetFOPreduce,
  NetFOPPlanreduce,
  EnergyReport,
  TransfertPlanning,
  BrAnalysisSKD,
  IndicatorFactAnalysisSKD,
  PassportAnalysisSKD,
  LiquidityMethod,
  ComparePassport,
};
