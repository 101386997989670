const frontendURL = '/doc/treasuryStatementCosts/';
const backendURL = '/api/treasuries/treasuryexpensesheader/';
const name = 'Казначейська виписка (видатки)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
