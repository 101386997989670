import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicTP from '../../../../../basicEditor/basicTP';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

function LiquidityRowSet2({ data, ...rest }) {
  const template = useMemo(
    () => ({
      columns: [
        {
          field: 'payment_date',
        },
        {
          field: 'sum_place',
        },
        {
          field: 'sum_return',
        },
      ],
    }),
    [],
  );
  return (
    <BasicTP
      data={data}
      {...rest}
      template={template}
      tableName="liquiditytable1rowv2_set"
    />
  );
}

LiquidityRowSet2.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.shape({
      budget: foreignPropType,
    }),
  }).isRequired,
};

export default LiquidityRowSet2;
