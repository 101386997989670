const frontendURL = '/cat/signatureVariants/';
const backendURL = '/api/references/refauthoritysignvariant/';
const name = 'Варіанти підписів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
