import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem } from '../styles';
import { DIRECTIONS } from '../../hooks';

const icons = {
  [DIRECTIONS.ascending]: faSortUp,
  [DIRECTIONS.descending]: faSortDown,
};

const hints = {
  [DIRECTIONS.ascending]: 'За зростанням',
  [DIRECTIONS.descending]: 'За спаданням',
};

function OrdersItem({
  name, label, onClick, active, onDblClick, onHover, padded, direction, onDirectionChange, errored,
}) {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.order],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: itemTypes.order },
    type: itemTypes.order,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  const directionClickHandler = useCallback(
    () => onDirectionChange(
      name,
      direction === DIRECTIONS.ascending ? DIRECTIONS.descending : DIRECTIONS.ascending,
    ),
    [direction, name, onDirectionChange],
  );

  drag(drop(ref));
  let variant = '';
  if (errored) variant = 'danger';
  else if (isOver) variant = 'success';
  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={variant}
      style={style}
    >
      <StyledSubItem padded={padded} />
      {label}
      <FontAwesomeIcon
        icon={icons[direction]}
        onClick={directionClickHandler}
        title={hints[direction]}
      />
    </StyledFieldItem>
  );
}

OrdersItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)).isRequired,
  onDirectionChange: PropTypes.func.isRequired,
  errored: PropTypes.bool,
};

OrdersItem.defaultProps = {
  label: '???',
  active: false,
  onDblClick: () => null,
  padded: false,
  errored: false,
};

export default OrdersItem;
