import moment from 'moment';

export const monthNames = [...Array(12)].map((_, m) => {
  const d = new Date();
  d.setMonth(m, 1);
  return d.toLocaleString('uk', { month: 'long' });
});

export const shortFormat = { day: '2-digit', month: '2-digit', year: 'numeric' };

export const longFormat = { day: 'numeric', month: 'long', year: 'numeric' };

export const periodVarians = {
  today: {
    label: 'Сьогодні',
    eval: () => ({
      start: moment().utc().startOf('day').format('YYYY-MM-DD'),
      end: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  yesterday: {
    label: 'Вчора',
    eval: () => ({
      start: moment().utc().add(-1, 'days').startOf('day')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'days').endOf('day')
        .format('YYYY-MM-DD'),
    }),
  },
  tomorrow: {
    label: 'Завтра',
    eval: () => ({
      start: moment().utc().add(1, 'days').startOf('day')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'days').endOf('day')
        .format('YYYY-MM-DD'),
    }),
  },
  thisMonth: {
    label: 'Цей місяць',
    eval: () => ({
      start: moment().utc().startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  nextMonth: {
    label: 'Наступний місяць',
    eval: () => ({
      start: moment().utc().add(+1, 'months').startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(+1, 'months').endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  prevMonth: {
    label: 'Минулий місяць',
    eval: () => ({
      start: moment().utc().add(-1, 'months').startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'months').endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  thisQuarter: {
    label: 'Цей квартал',
    eval: () => ({
      start: moment().utc().startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  nextQuarter: {
    label: 'Наступний квартал',
    eval: () => ({
      start: moment().utc().add(1, 'quarters').startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'quarters').endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  prevQuarter: {
    label: 'Минулий квартал',
    eval: () => ({
      start: moment().utc().add(-1, 'quarters').startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'quarters').endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  thisYear: {
    label: 'Цей рік',
    eval: () => ({
      start: moment().utc().startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
  nextYear: {
    label: 'Наступний рік',
    eval: () => ({
      start: moment().utc().add(1, 'years').startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'years').endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
  prevYear: {
    label: 'Минулий рік',
    eval: () => ({
      start: moment().utc().add(-1, 'years').startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'years').endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
};

export const BEGIN_OF_TIME = '1970-01-01';

export const END_OF_TIME = '2999-12-31';

export const LogicaReportType = {
  budgetRequestF1: { name: 'БюджетныйЗапросФ1', label: 'Бюджетний запит Форма 1' },
  budgetRequestF2: { name: 'БюджетныйЗапросФ2', label: 'Бюджетний запит Форма 2' },
  budgetRequestF3: { name: 'БюджетныйЗапросФ3', label: 'Бюджетний запит Форма 3' },
  sessionDecision: { name: 'РешениеСессии1', label: 'РешениеСессии1' },
  decisionNotification: { name: 'СообщениеОРешенииСессии', label: 'Повідомлення про прийняття рішеня органом місцевого самоврядування' },
  prepareForecastAdd1: { name: 'ПодготовкаПрогнозД1', label: 'Підготовка до прогнозу Дод1' },
  prepareForecastAdd2: { name: 'ПодготовкаПрогнозД2', label: 'Підготовка до прогнозу Дод2' },
  prepareForecastAdd3: { name: 'ПодготовкаПрогнозД3', label: 'Підготовка до прогнозу Дод3' },
  prepareForecastAdd4: { name: 'ПодготовкаПрогнозД4', label: 'Підготовка до прогнозу Дод4' },
  prepareForecastAdd5: { name: 'ПодготовкаПрогнозД5', label: 'Підготовка до прогнозу Дод5' },
  freeRem: { name: 'СвободныеОстатки', label: 'Інформація про вільні залишки Додаток 3' },
  forecast: { name: 'Прогноз', label: 'Прогноз' },
  indicatorFact: { name: 'ОтчетИсполненияПаспортБП', label: 'Звіт з виконання паспорту БП' },
  socialPayments: { name: 'Соцвыплаты', label: 'Соцвыплаты' },
  assignmentPlanning: { name: 'ПланАссигнований', label: 'План асигнувань' },
  estimate: { name: 'Кошторис', label: 'Кошторис' },
  changeToCost: { name: 'РосписьПомесечнаяИзм', label: 'РосписьПомесечнаяИзм' },
  indicatorPassport: { name: 'ПаспортБП', label: 'ПаспортБП' },
  costYear: { name: 'РосписьГодовая', label: 'РосписьГодовая' },
  costMounth: { name: 'РосписьПомесечная', label: 'РосписьПомесечная' },
  taxesAdd2: { name: 'ПодаткиДод2', label: 'Ставки та пільги (Додаток 2)' },

  netFOP_GRK: { name: 'МережаПоказателиДод4ГРК', label: 'Мережа показатели дод 4 ГРК' },
  netFOP_FI: { name: 'МережаПоказатели', label: 'Мережа показатели дод 4 МФО' },
  netFOP_Plan_FI: { name: 'МережаПоказателиДод3МФО', label: 'Мережа показатели дод 3 МФО' },
  netFOP_Plan_GRK: { name: 'МережаПоказателиДод3ГРК', label: 'Мережа показатели дод 3 ГРК' },
  freeRem4: { name: 'СвободныеОстатки4', label: 'Інформація про вільні залишки Додаток 4' },
};
