import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledTitleContainer = styled.div`
  min-width: 20%;
  max-width: 40%;
`;

function Title({
  titleTag: TitleTag, className, breakPoint, children,
}) {
  return (
    <TitleTag
      className={classNames(
        {
          'mb-0': !breakPoint,
          [`mb-${breakPoint}-0`]: !!breakPoint,
        },
        className,
      )}
    >
      {children}
    </TitleTag>
  );
}

function CardHeader({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  endEl,
  children,
}) {
  return (
    <Card.Header className={classNames('me-3', className, { 'bg-light': light })}>
      {endEl ? (
        <div className="d-flex flex-row align-items-center">
          <StyledTitleContainer className="overflow-hidden flex-fill">
            <Title
              breakPoint={breakPoint}
              titleTag={titleTag}
              className={titleClass}
            >
              {title}
            </Title>
            {children}
          </StyledTitleContainer>
          {/* overflow-x-hidden */}
          <div className="w-100">
            <div className="ms-auto w-auto">
              {endEl}
            </div>
          </div>
        </div>
      ) : (
        <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
          {title}
        </Title>
      )}
    </Card.Header>
  );
}

Title.propTypes = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = { titleTag: 'h5' };

CardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool.isRequired,
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  endEl: PropTypes.node,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

CardHeader.defaultProps = {
  endEl: null,
  breakPoint: 'sm',
  titleClass: '',
  children: '',
  titleTag: 'h5',
  className: '',
};

export default CardHeader;
