import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NumberInput, StringInput, ItemPicker } from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/taxes3';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tablename = 'TABL_42';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, onSR, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col>
        <Row>
          <Col>
            <TableRowCell column="code" highlighted={highlights.includes('code')}>
              <StringInput
                value={row[tableMD.columns.code.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.code.name]: v })}
                readOnly={readOnly}
                maxLength={19}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="codeDod" highlighted={highlights.includes('codeDod')}>
              <StringInput
                value={row[tableMD.columns.codeDod.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.codeDod.name]: v })}
                readOnly={readOnly}
                maxLength={19}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableRowCell column="name" highlighted={highlights.includes('name')}>
              <ItemPicker
                value={row[tableMD.columns.name.name]}
                onChange={async (e, v) => {
                  await onRowChange(e, { [tableMD.columns.name.name]: v });
                  await onSR('CHANGE_BUILD_IN_TABLE42', { rowId: rowIndex });
                }}
                readOnly={readOnly}
                modelType="cat"
                modelName="buildingClassifier"
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesLegal1" highlighted={highlights.includes('taxesLegal1')}>
              <NumberInput
                value={row[tableMD.columns.taxesLegal1.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesLegal1.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesNatural1" highlighted={highlights.includes('taxesNatural1')}>
              <NumberInput
                value={row[tableMD.columns.taxesNatural1.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesNatural1.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesLegal2" highlighted={highlights.includes('taxesLegal2')}>
              <NumberInput
                value={row[tableMD.columns.taxesLegal2.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesLegal2.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesNatural2" highlighted={highlights.includes('taxesNatural2')}>
              <NumberInput
                value={row[tableMD.columns.taxesNatural2.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesNatural2.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesLegal3" highlighted={highlights.includes('taxesLegal3')}>
              <NumberInput
                value={row[tableMD.columns.taxesLegal3.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesLegal3.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesNatural3" highlighted={highlights.includes('taxesNatural3')}>
              <NumberInput
                value={row[tableMD.columns.taxesNatural3.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesNatural3.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesLegal4" highlighted={highlights.includes('taxesLegal4')}>
              <NumberInput
                value={row[tableMD.columns.taxesLegal4.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesLegal4.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <TableRowCell column="taxesNatural4" highlighted={highlights.includes('taxesNatural4')}>
              <NumberInput
                value={row[tableMD.columns.taxesNatural4.name]}
                precision={4}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.taxesNatural4.name]: v })}
                readOnly={readOnly}
                required
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
