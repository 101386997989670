/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DBFModal from './DBFModal';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';
import PrintModal from './print_submenu';

function APMenu({ id, data, ...rest }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <PrintModal id={id} data={data} />
      <ButtonGroup className="ms-1">
        <DBFModal selectedRows={selectedRows} />
      </ButtonGroup>
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default APMenu;
