import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, FormCheck } from 'react-bootstrap';
import { ContainerTable, HeaderItem } from '../../../../basicEditor/stdFilters/style';

function DocList({
  docs, onToggleDoc, onSelectDoc, activeDoc,
}) {
  return (
    <ContainerTable>
      <ListGroup>
        <HeaderItem>
          Знайдені документи
        </HeaderItem>
        {docs.map((doc) => (
          <ListGroup.Item
            key={doc.id}
            active={doc.id === activeDoc}
            onClick={() => onSelectDoc(doc.id)}
          >
            <FormCheck
              id={doc.id}
            >
              <FormCheck.Input
                checked={doc.use}
                onChange={() => onToggleDoc(doc.id)}
              />
              <span>{doc.repr}</span>
            </FormCheck>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </ContainerTable>
  );
}

DocList.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
    use: PropTypes.bool.isRequired,
  })).isRequired,
  onToggleDoc: PropTypes.func.isRequired,
  onSelectDoc: PropTypes.func.isRequired,
  activeDoc: PropTypes.number,
};

DocList.defaultProps = {
  activeDoc: null,
};

export default DocList;
