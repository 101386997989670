import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { tableInside } from '../../../constants/globalStyle';

const StyledTable = styled.div`
      display: grid;
      max-height: 78vh;
      overflow: auto;
      grid-template-columns: ${(props) => props.templateColumns};
      grid-auto-rows: 3em;
      line-height: 1.2;
      ${tableInside};
    `;

class Table extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    // eslint-disable-next-line
    columnSizes: PropTypes.instanceOf(Map).isRequired,
    onScroll: PropTypes.func.isRequired,
    iRef: PropTypes.shape().isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const { columnSizes } = props;
    if (columnSizes !== state.columnSizes) {
      const templateColumns = columnSizes.reduce((R, clmn, key) => (`${R} [${key}] ${clmn.get('size', '1fr')}`), '[togglerColumn] fit-content(50px) ');
      return {
        templateColumns,
        columnSizes,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      columnSizes: null,
      templateColumns: '',
    };
  }

  render() {
    const { children, onScroll, iRef } = this.props;
    const { templateColumns } = this.state;
    return (
      <StyledTable templateColumns={templateColumns} onScroll={onScroll} ref={iRef}>
        {children}
      </StyledTable>
    );
  }
}

export default Table;
