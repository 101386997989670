import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  JSON: { label: 'Джейсон', name: 'Джейсон' },
};

/**
 * @const
 */
const definition = {
  name: 'printingFromLOGICA',
  backendName: 'ПечатьИзЛОГИКА',
  label: 'Перегляд даних з ЛОГФКА',
  frontend: 'dp/printingFromLOGICA',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
