import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  BudgetAdmin: { label: 'Бюджет', name: 'БюджетАдмин', visible: false },
};

export const baseDoc = {
  label: 'Класифікатор підстав',
  name: 'baseDoc',
  backendName: 'ОснованияДок',
  defaultOrder,
  columns,
  frontend: 'cat/baseDoc',
  listColumns: [...new Set([...reqListerColumns])],
  hierarchy: false,
};

export default baseDoc;
