const frontendURL = '/cat/elementFond/';
const backendURL = '/api/references/reffonditem/';
const name = 'Елементи класифікатора фонд';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
