import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function SignatoryRow({
  signatory, step, onSign, signed, onCheckSign, onDelSign,
}) {
  return (
    <div className="d-flex align-items-center mb-4">
      {signed ? (
        <div className="bg-200 rounded-circle text-primary border-300 fs-1 icon-item fw-bold bg-success">
          <FontAwesomeIcon icon={faCheck} color="white" />
        </div>
      )
        : (
          <div className="bg-200 rounded-circle text-primary border-300 fs-1 icon-item fw-bold">
            {step}
          </div>
        )}
      <div className="mx-3">
        {signed ? (
          <Button variant="secondary">
            Підписано
          </Button>
        ) : (
          <Button variant="success" onClick={() => onSign(step - 1)}>
            Підписати
          </Button>
        )}
      </div>
      <div className="flex-fill">
        <h6 className="mb-0 text-700">
          {signatory?.ДолжностноеЛицо?.repr}
        </h6>
        <span className="fs--2 text-500 font-sans-serif">{signatory?.ДолжностноеЛицо?.repr}</span>
      </div>
      <div>
        <Button variant="info" onClick={() => onCheckSign(step - 1)} disabled={!signed}>
          Перевірити підпис
        </Button>
      </div>
      <div>
        <Button variant="dark" onClick={() => onDelSign(step - 1)} disabled={!signed}>
          Зняти підпис
        </Button>
      </div>
    </div>
  );
}

SignatoryRow.propTypes = {
  signatory: PropTypes.shape({
    ДолжностноеЛицо: PropTypes.shape({
      repr: PropTypes.string,
    }),
  }).isRequired,
  step: PropTypes.number.isRequired,
  onSign: PropTypes.func.isRequired,
  signed: PropTypes.bool.isRequired,
  onCheckSign: PropTypes.func.isRequired,
  onDelSign: PropTypes.func.isRequired,
};

export default SignatoryRow;
