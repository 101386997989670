import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import CardDropdown from './CardDropdown';
import Flex from '../../../../components/blanks/common/Flex';
import CoreDetailKekvChart from './CoreDetailKekvChart';
import CardHeader from './CardHeader';

function LeadItem({ item, isLast }) {
  return (
    <Flex
      justifyContent="between"
      alignItems="center"
      className={classNames('border-bottom py-3', {
        'border-bottom-0 pb-0': isLast,
      })}
    >
      <div style={{ width: '50%' }}>
        <Flex>
          <img src={item.img} alt="..." width={16} height={16} className="me-2" />
          <h6 className="text-700 mb-0">{item.title}</h6>
        </Flex>
      </div>
      <div style={{ width: '45%' }}>
        <p className="fs--1 text-500 mb-0 fw-semi-bold">{item.target}</p>
      </div>
      <h6 className="text-700 mb-0">
        {item.amount}
        %
      </h6>
    </Flex>
  );
}

function CoreDetailKekv({ title, data }) {
  return (
    <Card className="h-100">
      <CardHeader
        title={title}
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body as={Row}>
        <Col md={5} xxl={12} className="mb-xxl-1">
          <CoreDetailKekvChart
            data={data}
          />
        </Col>
        <Col xxl={12} md={7}>
          <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
          {data.map((item, index) => (
            <LeadItem
              key={item.id}
              item={item}
              isLast={index === data.length - 1}
            />
          ))}
        </Col>
      </Card.Body>
      {/* <FalconCardFooterLink title="Primary" size="sm" to="#!" /> */}
    </Card>
  );
}

LeadItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  isLast: PropTypes.bool.isRequired,
};

LeadItem.defaultProps = {
  item: [],
};

CoreDetailKekv.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape([]),
};
CoreDetailKekv.defaultProps = {
  title: '',
  data: null,
};

export default CoreDetailKekv;
