/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TPHeader from './header';
import TPRow from './row';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd4';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import { soSelector } from '../../../../_common/selectors';

const tableMeta = md.tables.incomes;

function IncomesTP({ data, actions, readOnly }) {
  const FI = data[md.columns.FI.name];
  const activeYear = data[md.columns.year.name];
  const tableData = useMemo(() => data[tableMeta.name] || [], [data]);
  const totals = useMemo(() => (tableData.reduce((res, r) => {
    res.incomesGF_Plan += r[tableMeta.columns.incomesGF_Plan.name]
      ? r[tableMeta.columns.incomesGF_Plan.name] : 0;

    res.incomesGF_Add += r[tableMeta.columns.incomesGF_Add.name]
      ? r[tableMeta.columns.incomesGF_Add.name] : 0;

    res.incomesSF_Plan += r[tableMeta.columns.incomesSF_Plan.name]
      ? r[tableMeta.columns.incomesSF_Plan.name] : 0;

    res.incomesSF_Add += r[tableMeta.columns.incomesSF_Add.name]
      ? r[tableMeta.columns.incomesSF_Add.name] : 0;

    res.incomesGF_Plan1 += r[tableMeta.columns.incomesGF_Plan1.name]
      ? r[tableMeta.columns.incomesGF_Plan1.name] : 0;

    res.incomesGF_Add1 += r[tableMeta.columns.incomesGF_Add1.name]
      ? r[tableMeta.columns.incomesGF_Add1.name] : 0;

    res.incomesSF_Plan1 += r[tableMeta.columns.incomesSF_Plan1.name]
      ? r[tableMeta.columns.incomesSF_Plan1.name] : 0;

    res.incomesSF_Add1 += r[tableMeta.columns.incomesSF_Add1.name]
      ? r[tableMeta.columns.incomesSF_Add1.name] : 0;

    res.incomesGF_Plan2 += r[tableMeta.columns.incomesGF_Plan2.name]
      ? r[tableMeta.columns.incomesGF_Plan2.name] : 0;

    res.incomesGF_Add2 += r[tableMeta.columns.incomesGF_Add2.name]
      ? r[tableMeta.columns.incomesGF_Add2.name] : 0;

    res.incomesSF_Plan2 += r[tableMeta.columns.incomesSF_Plan2.name]
      ? r[tableMeta.columns.incomesSF_Plan2.name] : 0;

    res.incomesSF_Add2 += r[tableMeta.columns.incomesSF_Add2.name]
      ? r[tableMeta.columns.incomesSF_Add2.name] : 0;

    return res;
  }, {
    incomesGF_Plan: 0,
    incomesGF_Add: 0,

    incomesSF_Plan: 0,
    incomesSF_Add: 0,

    incomesGF_Plan1: 0,
    incomesGF_Add1: 0,

    incomesSF_Plan1: 0,
    incomesSF_Add1: 0,

    incomesGF_Plan2: 0,
    incomesGF_Add2: 0,

    incomesSF_Plan2: 0,
    incomesSF_Add2: 0,
  })
  ), [tableData]);

  const {
    tableActions, activeCol, activeRow, tableContainerRef, highlights, pinHeader, showFooter,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const sessionOptions = useSelector(soSelector);
  const isFilterCsuElemKFK = sessionOptions.get('isFilterCsuElemKFK', false);

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          pinHeader={pinHeader}
          showFooter={showFooter}
        />
        <TPHeader
          activeCol={activeCol}
          activeYear={activeYear}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, index) => (
          <TPRow
            key={index}
            row={row}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeRow === index ? activeCol : null}
            onChange={tableActions.onCellChange}
            readOnly={readOnly}
            highlights={highlights}
            onSR={actions.onSR}
            /// /
            FI={FI}
            isFilterCsuElemKFK={isFilterCsuElemKFK}
          />
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          activeCol={activeCol}
          highlights={highlights}
          totals={totals}
          //
        />
      </TableFooter>
    </Card>
  );
}

IncomesTP.propTypes = {
  data: PropTypes.shape({
    [md.columns.FI.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

IncomesTP.defaultProps = {
  readOnly: false,
};

export default memo(IncomesTP);
