import base from './base';

/**
 * @const
 * @type {object}
 */
export const sumByGeneralFond = [...Array(3).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumGenF${v}`]: {
      name: `ЗФ${v}`,
      label: 'Заг.ф.',
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 */
export const sumBySpecFond = [...Array(3).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumSpecF${v}`]: {
      name: `СФ${v}`,
      label: 'Спец.ф.',
    },
  }),
  {},
);
export const sumByTotal = [...Array(3).keys()].reduce(
  (r, v) => ({
    ...r,
    [`Sum${v}`]: {
      name: `Сумма${v}`,
      label: 'Разом',
    },
  }),
  {},
);

/**
 * @const
 * @type {object}
 * BudgetAmplification
 */
export const sumByBudgetBR = [...Array(3).keys()].reduce(
  (r, v) => ({
    ...r,
    [`SumBR${v}`]: {
      name: `БР${v}`,
      label: 'в т.ч. БР',
    },
  }),
  {},
);

/**
 * @const
 */
const columns = {
  ...base.columns,
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  for3Years: { label: 'Планування на 3 роки', name: 'НаТриГода', visible: false },
  inThousands: { label: 'В тис. грн.', name: 'ВТысГрн', visible: false },
  variantBudgetDraft: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: true },
  year: { label: 'Рік планування', name: 'ГодЗатрат', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      strNumber: { label: '№', name: 'НомерСтроки' },
      kdbClassifier: { label: 'КДБ', name: 'КлассификаторКДБ' },
      fullName: { label: 'Назва', name: 'ПолнНаим' },
      ...sumByGeneralFond,
      ...sumBySpecFond,
      ...sumByBudgetBR,
      ...sumByTotal,
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Проект рішення сесії. Додаток 1 (Доходи)',
  name: 'draftAnnualRev',
  backendName: 'ПроектДоходов',
  columns,
  frontend: 'doc/draftAnnualRev',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'author',
      'variantBudgetDraft',
      'note',
      'FI',
      'budget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
