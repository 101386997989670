import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Col, Container, Row,
} from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { FieldPropType } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import HistoryContainer from '../../../historyValues/historyContainer';

function History({
  data, fields, actions, id, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.kbp.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });
  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const codeProps = editorHooks.useStringInputProps('code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);

  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
        <Container fluid className="pb-3">
          {historyData.is_group && (
          <div className="w-100 text-end">
            <Badge bg="info">Група</Badge>
          </div>
          )}
          <Row>
            <Col>
              <EditorControls.StringInput {...codeProps} />
            </Col>
            <Col>
              <EditorControls.StringInput {...nameProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput {...isActiveProps} />
            </Col>
          </Row>
        </Container>
      </HistoryContainer>
    </>
  );
}

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

History.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape(
      fieldsPropTypes,
    ),
  }),
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}),
};

History.defaultProps = {
  fields: {},
  errors: null,
};

export default History;
