import PropTypes from 'prop-types';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { FieldErrorPropType, FieldPropType } from '../../../../../basicEditor';

export const dataPropTypes = {
  indicator: foreignPropType,
  kvk3: foreignPropType,
  gender_status: foreignPropType,
  gender_age: foreignPropType,
  general_indicator_code: PropTypes.string,
  gender_segment: foreignPropType,
  amount_sf0: PropTypes.number,
  amount_sf1: PropTypes.number,
  amount_sf2: PropTypes.number,
  amount_sf_1: PropTypes.number,
  amount_sf_2: PropTypes.number,
  amount_total0: PropTypes.number,
  amount_total1: PropTypes.number,
  amount_total2: PropTypes.number,
  amount_total_1: PropTypes.number,
  amount_total_2: PropTypes.number,
  amount_zf0: PropTypes.number,
  amount_zf1: PropTypes.number,
  amount_zf2: PropTypes.number,
  amount_zf_1: PropTypes.number,
  amount_zf_2: PropTypes.number,
};

export const errorsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});
export const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});
