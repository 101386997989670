import React, {
  useCallback, useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'react-bootstrap';
import {
  MapContainer, Marker, Popup, TileLayer,
} from 'react-leaflet';
import CardHeader from '../../../components/blanks/common/CardHeader';

function ProjectLocation({ CardDropdown, dataAdditional6, cDt }) {
  const [marker, setMarker] = useState(dataAdditional6[0]);
  const mapRef = useRef();
  // const [map, setMap] = useState(null);

  const onClick = useCallback((id) => {
    setMarker(dataAdditional6[id]);
    if (mapRef.current) {
      mapRef.current.setView(dataAdditional6[id].point, 15);
    }
  }, [dataAdditional6]);

  return (
    <>
      <Card className="h-100">
        <CardHeader
          title="Розташування об'єкту"
          titleTag="h5"
          endEl={(
            <CardDropdown
              dataAdditional6={dataAdditional6}
              onClick={onClick}
            />
)}
          light
        />
        <Card.Body className="h-100 p-0" dir="ltr" id="map" />
      </Card>
      <MapContainer
        center={marker.point}
        zoom={15}
        scrollWheelZoom
          // className="h-100 bg-white"
        style={{ height: '500px' }}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={marker.point}>
          <Popup>
            <p>
              {marker.name}
            </p>
            <p>
              період реалізації:-
              {' '}
              {marker.period}
            </p>
            <p>
              Загальна вартість, тис.грн.:-
              {' '}
              {marker.totalPrice}
            </p>
            <p>
              Капітальні
              вкладення у
              {' '}
              {cDt}
              , тис.грн.:-
              {' '}
              {marker.totalYear}

            </p>
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
}

ProjectLocation.propTypes = {
  CardDropdown: PropTypes.node,
  dataAdditional6: PropTypes.shape([]),
  cDt: PropTypes.string,
};

ProjectLocation.defaultProps = {
  CardDropdown: () => null,
  dataAdditional6: [],
  cDt: '2024',
};

export default ProjectLocation;
