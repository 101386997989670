import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LogicaContext } from '../../../providers';
import LogcaLogin from './auth';

function LogcaLoginModal() {
  const { ticket } = useContext(LogicaContext);
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show && !ticket}
      contentClassName="vh-100"
      dialogClassName="my-0"
      onHide={() => setShow(false)}
      size="xl"
      scrollable
    >
      <Modal.Header closeButton>
        Авторизація в Logica
      </Modal.Header>
      <Modal.Body>
        <LogcaLogin />
      </Modal.Body>
      <Modal.Footer className="d-none">
        <div className="d-none">
          {/* TODO: Запитатися чи таке треба */}
          <p className="text-muted">
            Для успішного проходження реєстрації Вам
            необхідно буде використати ключ, за допомогою
            якого здійснюватиметься ідентифікація установи.
            Якщо Ви використовуватимете ключ ДКУ,
            рекомендуємо завчасно завантажити сертифікати,
            так як їх потрібно буде додати самостійно.
            <Button
              variant="link"
              href="https://ca.treasury.gov.ua/Content/xPages/Certificates/FindCertificate.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Сертифікати сформовані після 31.01.2020
              року
            </Button>
          </p>
        </div>
      </Modal.Footer>
    </Modal>

  );
}

export default LogcaLoginModal;
