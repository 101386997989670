import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL9;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG1S'}
              highlighted={highlights.includes('T9RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG1S')}
            >
              {meta.columns.T9RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG3'}
              highlighted={highlights.includes('T9RXXXXG3')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG3')}
            >
              {meta.columns.T9RXXXXG3.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG4'}
              highlighted={highlights.includes('T9RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG4')}
            >
              {meta.columns.T9RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG5'}
              highlighted={highlights.includes('T9RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG5')}
            >
              {meta.columns.T9RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG6'}
              highlighted={highlights.includes('T9RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG6')}
            >
              {meta.columns.T9RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG7'}
              highlighted={highlights.includes('T9RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG7')}
            >
              {meta.columns.T9RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T9RXXXXG8'}
              highlighted={highlights.includes('T9RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T9RXXXXG8')}
            >
              {meta.columns.T9RXXXXG8.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
