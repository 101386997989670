import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/investmentProposalObjectsChanges';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.buildingState;

function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'year'}
          highlighted={highlights.includes('year')}
          onToggleHighlght={() => onToggleHighlght('year')}
        >
          {meta.columns.year.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'rate'}
          highlighted={highlights.includes('rate')}
          onToggleHighlght={() => onToggleHighlght('rate')}
        >
          {meta.columns.rate.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
