const frontendURL = '/cat/csu/';
const backendURL = '/api/references/refdisposer/';
const name = 'Довідник розпорядників бюджетних коштів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: true,
  youtubeVideo: [
    // id в посиланні після дорівнює приклад масиву = label: 'назва', id: 'gnzYZ_6RmgA'

  ],
};

export default instance;
