import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import md from '../../../../constants/meta/index';
import ea from '../../../../actions/dpEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  SumInputStyled,
} from '../../../../components/styledTable/styledTable';
import Fields from '../../../field';
import { InputStyled } from '../../../field/selectorField/item/styles';
import storePathParam from '../../../../common/storePathParam';

const coldef = md.dp.docSelection.tables.data.columns;

const TABLENAME = 'data';

const GridDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: "Check c2 c3 c4 c5 c6 c7";
  grid-template-columns: 60px  ;
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  top: 0;
  bottom: 0;
  position: ${({ isHeader }) => (isHeader ? 'sticky' : 'unset')};
  z-index: ${({ isHeader }) => (isHeader ? '3' : 'unset')};
`;
const DivChek = styled(DivCell)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const DivFooter = styled(DivHeader)`
  // grid-area: 1/1/4/7;
  text-align: right;
`;

function HeaderComponent() {
  return (
    <GridDiv isHeader>
      <DivHeader areaName="Check">{coldef.Check.label}</DivHeader>
      <DivHeader areaName="c2">{coldef.Document.label}</DivHeader>
      <DivHeader areaName="c3">{coldef.CSU.label}</DivHeader>
      <DivHeader areaName="c4">{coldef.KPKVCode.label}</DivHeader>
      <DivHeader areaName="c5">{coldef.Autor.label}</DivHeader>
      <DivHeader areaName="c6">{coldef.Notes.label}</DivHeader>
      <DivHeader areaName="c7">{coldef.Sum.label}</DivHeader>
    </GridDiv>
  );
}

function FooterComponent({ rows }) {
  const totals = rows.reduce((v, row) => (row.get('Check', false) ? v + row.get('Sum') : v), 0);
  return (
    <GridDiv isHeader>
      <DivFooter areaName="1/1/4/7">Разом</DivFooter>
      <DivHeader areaName="c7"><div align="right">{totals.toFixed(2)}</div></DivHeader>
    </GridDiv>
  );
}

FooterComponent.propTypes = {
  rows: PropTypes.instanceOf(List).isRequired,
};

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivChek areaName="Check">
          <Fields.CheckboxField
            value={row.get('Check', false)}
            onChange={(e, value) => dispatch(ea.changeField([`tables/${TABLENAME}`, rowId, 'Check'], value))}
          />
        </DivChek>
        <DivCell areaName="c2">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('Document', new Map())}
          />
        </DivCell>
        <DivCell areaName="c3">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('CSU', new Map())}
          />
        </DivCell>
        <DivCell areaName="c4">
          <InputCodeStyled
            disabled
            value={row.get('KPKVCode', '')}
          />
        </DivCell>
        <DivCell areaName="c5">
          <Fields.ItemField
            noBorder
            noBackground
            value={row.get('Autor', new Map())}
          />
        </DivCell>
        <DivCell areaName="c6">
          <InputStyled
            disabled
            value={row.get('Notes', '')}
          />
        </DivCell>
        <DivCell areaName="c7">
          <SumInputStyled
            disabled
            value={row.get('Sum', 0)}
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [`dp/${storePathParam(state).nameDp}/dpEditor`, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const enchance = compose(
  connect(mapState),
  getBaseTP,
);

export default enchance(TABLENAME, RowComponent, HeaderComponent, FooterComponent);
