import React, {
  useCallback,
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Tooltip, OverlayTrigger, Collapse,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp as icon } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledUpButton = styled(Button)`
  border: 1px solid #dee2e6!important;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  top: calc(50% - 38px/2);
  z-index: 1031;
`;

function TableFooter({
  pinHeader, className, children, showFooter,
}) {
  const clsName = useMemo(
    () => `px-0 bg-light ${className || ''} ${pinHeader ? ' position-sticky fixed-bottom ' : ''}`,
    [className, pinHeader],
  );
  const [toUpButtonVisible, setToUpButtonVisible] = useState(false);

  const containerRef = useRef();
  const onContainerScroll = useCallback(
    () => {
      if (!!containerRef.current.scrollTop && !toUpButtonVisible) setToUpButtonVisible(true);
      if (!containerRef.current.scrollTop && toUpButtonVisible) setToUpButtonVisible(false);
    },
    [toUpButtonVisible],
  );
  useEffect(
    () => {
      const c = document.getElementById('root-container');
      if (!c) {
        console.warn('Не удалось найти контейнер #root-container. Анализ скролинг а не возможен');
      } else {
        containerRef.current = c;
      }
      containerRef.current.addEventListener('scroll', onContainerScroll);
      onContainerScroll();
      return () => containerRef.current.removeEventListener('scroll', onContainerScroll);
    },
  );

  return (
    <Collapse in={showFooter}>
      <div className={clsName}>
        <Card.Footer>
          {containerRef.current && toUpButtonVisible && (
            <OverlayTrigger
              placement="auto"
              overlay={(
                <Tooltip>
                  До верху
                </Tooltip>
              )}
            >
              <StyledUpButton variant="light" onClick={() => containerRef.current.scrollTo({ top: 0, behavior: 'smooth' })}>
                <FontAwesomeIcon icon={icon} />
              </StyledUpButton>
            </OverlayTrigger>
          )}
          {children}
        </Card.Footer>
      </div>
    </Collapse>
  );
}

TableFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  showFooter: PropTypes.bool.isRequired,
  pinHeader: PropTypes.bool.isRequired,
};

TableFooter.defaultProps = {
  className: '',
};

export default TableFooter;
