import React, {
  useEffect, useMemo,
} from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';
import meta from '../../../constants/meta';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import { MainContainer, ResultSegment } from '../styledComponentsDp';

import {
  StyledLabel, ThreeColumnsGridContainer,
  TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';

import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import enums from '../../../constants/meta/enums';

import StyledTPPane from '../../../components/tableParts/TPPane';
import DateRangeField from '../../../components/fields/DateRangeField';

import DpContainer from '../dpContainer';

import DateInput from '../../../components/fields/dates';
import { changeField } from '../../../actions/reportEditor';
import InputWithDropdown from '../../../components/styledInputs/inputWithDropdown';

const md = meta.dp.mixIndicatorsSFPrint;

const MyThreeColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 20% 30% 30%;
  grid-column-gap: 10%;
  align-items: start;
`;

const MySixColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-column-gap: 2%;
  align-items: start;
`;

const MySevenColumnsGridContainer = styled(ThreeColumnsGridContainer)`
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12%;
  grid-column-gap: 2%;
  align-items: start;
`;

const StringInput20 = Fields.GetTextInput(20);
const StringInput10 = Fields.GetTextInput(10);
const NumberInput3 = Fields.GetNumberInput(3, 0);

const fundKEKVTypes = {
  none: { label: 'Не враховувати', name: '' },
  ...enums.FundKEKVKInds,
};

const fundKEKVOptions = Object.values(fundKEKVTypes).map((_enum) => ({ label: _enum.label, value: _enum.name }));

const planingKindsOptions = Object.values(enums.PlaningKind);

const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

const FormTypes = {
  kazn: { label: 'Казначейство', value: 0 },
  mf: { label: 'Мінфін', value: 1 },
};

const ShowAsTypes = {
  code: { label: 'Код', value: 1 },
  codeTL_TERRA: { label: 'Код за TL_TERRA', value: 2 },
};

const ShowCSUAsTypes = {
  csu: { label: 'Розпорядник', value: true },
  KVK: { label: 'КВК', value: false },
};

const ShowKFKAsTypes = {
  kfk: { label: 'КПКВ', value: false },
  kfkel: { label: 'Бюджетна програма', value: true },
};

const ShowKVKTypes = {
  csu: { label: 'ГолРозпорядник', value: 0 },
  csu2: { label: 'Розпорядник', value: 1 },
  kvk: { label: 'КВК', value: 2 },
};

const TypeKEKVTypes = {
  short: { label: 'За скороченою економікою', value: 1 },
  long: { label: 'За повною економікою (всі коди)', value: 2 },
  full: { label: 'За повною економікою (задіяні коди)', value: 3 },
};

export const StyledPane = styled(StyledTPPane)`
  height: auto;
`;

function MixIndicatorsSFPrintEditor() {
  const dispatch = useDispatch();
  const { headerForm } = useSelector(mapState);
  const resultMountNode = useMemo(
    () => [`dp/${md.name}/dpEditor`, 'headerForm', 'result'],
    [],
  );

  useEffect(
    () => () => dispatch(actions.processServerReq('CLOSING')),
    [dispatch],
  );

  const reportVariants = headerForm.get('reportVariants', new Map());

  const SettingsButton = (
    <div>
      <TwoColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'signVariant'], v))}
            filter={[
              { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) },
            ]}
          />
          <Fields.CheckboxField
            value={headerForm.get('flSN', true)}
            label={md.columns.flSN.label}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'flSN'], v))}
          />
        </div>
      </TwoColumnsGridContainer>
      <MyThreeColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.dateReg.label}</StyledLabel>
          <DateInput
            label={md.columns.dateReg.label}
            value={headerForm.get('dateReg', null)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dateReg'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.regNo.label}</StyledLabel>
          <StringInput20
            value={headerForm.get('regNo', '')}
            onChange={(e, v) => {
              dispatch(actions.changeField(['headerForm', 'regNo'], v));
            }}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.typePlan.label}</StyledLabel>
          <InputWithDropdown
            value={headerForm.get('typePlan')}
            options={planingKindsOptions}
            onChange={(e, v) => {
              dispatch(actions.changeField(['headerForm', 'typePlan'], v));
            }}
          />
        </div>
      </MyThreeColumnsGridContainer>
      <MySixColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.form.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('form', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'form'], v))}
            values={Object.values(FormTypes)}
            name="form"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.showAs.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('showAs', 1)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'showAs'], v))}
            values={Object.values(ShowAsTypes)}
            name="showAs"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.typeKEKV.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('typeKEKV', 1)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'typeKEKV'], v))}
            values={Object.values(TypeKEKVTypes)}
            name="typeKEKV"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.varReport.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('varReport', 1)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'varReport'], v))}
            values={Object.values(ReportTypes)}
            name="varReport"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.typeMoney.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('typeMoney', 1)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'typeMoney'], v))}
            values={Object.values(fundKEKVOptions)}
            name="typeMoney"
          />
        </div>
      </MySixColumnsGridContainer>
      <MySevenColumnsGridContainer>
        <div>
          <Fields.CheckboxField
            label={md.columns.inKFK.label}
            value={headerForm.get('inKFK', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inKFK'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.inKFKNoGroups.label}
            value={headerForm.get('inKFKNoGroups', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inKFKNoGroups'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.WithKFB.label}
            value={headerForm.get('WithKFB', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'WithKFB'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.withDoc.label}
            value={headerForm.get('withDoc', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'withDoc'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.in1000.label}
            value={headerForm.get('in1000', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'in1000'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.inPCM.label}
            value={headerForm.get('inPCM', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inPCM'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.dontShow.label}
            value={headerForm.get('dontShow', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'dontShow'], v))}
          />
        </div>
      </MySevenColumnsGridContainer>
      <MySevenColumnsGridContainer>
        <div>
          <Fields.CheckboxField
            label={md.columns.noShowHead.label}
            value={headerForm.get('noShowHead', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'noShowHead'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.KOATUU.label}
            value={headerForm.get('KOATUU', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'KOATUU'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.sumVoid.label}</StyledLabel>
          <StringInput10
            value={headerForm.get('sumVoid', '')}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'sumVoid'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.widthCol.label}</StyledLabel>
          <NumberInput3
            value={headerForm.get('widthCol', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'widthCol'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.addName.label}
            value={headerForm.get('addName', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'addName'], v))}
          />
        </div>
        <div>
          <Fields.CheckboxField
            label={md.columns.inPage.label}
            value={headerForm.get('inPage', false)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'inPage'], v))}
          />
        </div>
      </MySevenColumnsGridContainer>
      <MyThreeColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.showCSUAs.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('showCSUAs', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'showCSUAs'], v))}
            values={Object.values(ShowCSUAsTypes)}
            name="showCSUAs"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.showKVK.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('showKVK', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'showKVK'], v))}
            values={Object.values(ShowKVKTypes)}
            name="showKVK"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.showKFKAs.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('showKFKAs', 0)}
            onChange={(e, v) => dispatch(actions.changeField(['headerForm', 'showKFKAs'], v))}
            values={Object.values(ShowKFKAsTypes)}
            name="showKFKAs"
          />
        </div>
      </MyThreeColumnsGridContainer>
    </div>
  );

  return (
    <MainContainer>
      <DpContainer
        Settings={SettingsButton}
        backendName={md.backendName}
        reportVariants={reportVariants}
      >
        <ResultSegment>
          <TableEditor mountNode={resultMountNode} />
        </ResultSegment>
      </DpContainer>
    </MainContainer>
  );
}

const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: md.name, // planOfUsePrint',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(MixIndicatorsSFPrintEditor);
