import React, { forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';
import SendToLogicaButton from '../../../Logica/sendToLogicaButton';
import { modelName, modelType } from '../def';

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  >
    <ButtonGroup>
      <SendToLogicaButton modelType={modelType} modelName={modelName} />
    </ButtonGroup>
  </DocListerCommandPanel>
));
export default CommandPanel;
