import base from './base';

const columns = {
  ...base.columns,
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  CodeBudget: { label: 'Код 3', name: 'КодБюджетаКазн', visible: true },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      date: { label: 'Дата', name: 'Дата' },
      KVKCode: { label: 'КВК код ', name: 'КВККод' },
      Code: { label: 'Код мережа', name: 'КодМережа' },
      KTKVCode: { label: 'КТКВ код', name: 'КТКВКод' },
      KEKVCode: { label: 'КЕКВ код', name: 'КЕКВКод' },
      Section: { label: 'Розділ', name: 'Раздел' },
      FondCode: { label: 'Фонд код', name: 'ФондКод' },
      KP: { label: 'Кошторисні призначення', name: 'КП' },
      sumExecution: { label: 'Сума виконання', name: 'СуммаИсполнения' },
      sumExecutionBegYear: { label: 'Сума виконання з початку року', name: 'СуммаИсполненияСНачалаГода' },
      sumRetained: { label: 'Сума нерозподіленого залишку', name: 'СуммаНераспределенногоОстатка' },
      sumCashExpenses: { label: 'Сума касових видатків', name: 'СуммаКассовыхРасходов' },
      sumCashExpensesBegYear: { label: 'Сума касових видатків з початку року', name: 'СуммаКассовыхРасходовСНачалаГода' },
      sumRemaining: { label: 'Сума залишку', name: 'СуммаОстатка' },
      sumFinObligations: { label: 'Сума фін. зобов\'язань', name: 'СуммаФинОбязательств' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      Fond: { label: 'Фонд', name: 'Фонд' },
      KVK: { label: 'КВК', name: 'КВК' },
      FO: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
      KEKV: { label: 'КЕКВ', name: 'КЕКВ' },
    },
  },
};

const definition = {
  label: 'Казн. виписка видатки',
  name: 'treasuryStatementCosts',
  backendName: 'ВыпискаРасходы',
  columns,
  frontend: 'doc/treasuryStatementCosts',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'Budget',
      'Author',
      'CodeBudget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
