import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  CommandPanelColor,
} from '../../../../../../components/Form/styledForm';
import CostsKEKVTP from './koefTable';
import { EditorControls } from '../../../../../../components/bootstrap_components/editorControls';
import md from '../../../../../../constants/meta/documents/budgetRequest';

function FillCoefficientEditor({ data, actions, setAccKey }) {
  const year = data[md.columns.year.name];

  return (
    <Container fluid className="border rounded p-4 ms-0" style={{ width: 'fit-content' }}>
      <Row>
        <Col>
          <EditorControls.NumberInput
            value={data[md.columns.k1.name]}
            precision={4}
            onChange={(e, value) => actions.onChange({
              [md.columns.k1.name]: value,
            })}
            label={`Коеф на ${year}`}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            value={data[md.columns.k2.name]}
            precision={4}
            onChange={(e, value) => actions.onChange({
              [md.columns.k2.name]: value,
            })}
            label={`Коеф на ${year + 1}`}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            value={data[md.columns.k3.name]}
            precision={4}
            onChange={(e, value) => actions.onChange({
              [md.columns.k3.name]: value,
            })}
            label={`Коеф на ${year + 2} `}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`fillK1-${data.id}`}
            value={data[md.columns.fillK1.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fillK1.name]: value,
            })}
            label={`Заповнити ${year}`}
          />
          <EditorControls.CheckboxInput
            controlId={`fillK2-${data.id}`}
            value={data[md.columns.fillK2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fillK2.name]: value,
            })}
            label={`Заповнити ${year + 1}`}
          />
          <EditorControls.CheckboxInput
            controlId={`fillK3-${data.id}`}
            value={data[md.columns.fillK3.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fillK3.name]: value,
            })}
            label={`Заповнити ${year + 2}`}
          />
        </Col>
      </Row>
      <CommandPanelColor
        label="Заповнити коеф. по рядкам з документу"
        onClick={() => {
          actions.onSR('FILL_PREVIOUS_K');
        }}
      />
      {/* <EditorControls.CheckboxInput */}
      {/*    controlId={`appr-${data.id}`} */}
      {/*    value={data[md.columns.previousK.name]} */}
      {/*    onChange={(e, value) => actions.onChange({ */}
      {/*        [md.columns.previousK.name]: value, */}
      {/*    })} */}
      {/*    label={md.columns.previousK.label} */}
      {/* /> */}
      <CommandPanelColor
        label="Заповнити суми за КЕКВ (згідно окремих коефіцієнтів)"
        onClick={() => {
          actions.onSR('FILL_SUM');
          setAccKey('');
        }}
      />
      <CostsKEKVTP data={data} actions={actions} />

    </Container>
  );
}

FillCoefficientEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  setAccKey: PropTypes.func.isRequired,
};

export default FillCoefficientEditor;
