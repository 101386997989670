import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  UnsupportedViewer,
  ImgViewer,
  PDFViewer,
  // DocxViewer,
  XlsxViewer,
} from './drivers';

function FileViewer({ content, mimeType, filename }) {
  const [fileURL, setFileUrl] = useState(null);
  useEffect(
    () => {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      setFileUrl(url);
      return () => window.URL.revokeObjectURL(url);
    },
    [content, mimeType],
  );
  const Driver = useMemo(
    () => {
      switch (mimeType) {
        case 'application/pdf':
          return PDFViewer;
        case 'image/jpeg':
          return ImgViewer;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return XlsxViewer;
          // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          //   return DocxViewer;
        default:
          return UnsupportedViewer;
      }
    },
    [mimeType],
  );
  // console.log(mimeType, fileURL);

  return fileURL ? (
    <Driver mimeType={mimeType} href={fileURL} content={content} filename={filename} />
  ) : null;
}

FileViewer.propTypes = {
  mimeType: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default FileViewer;
