import React, { useCallback, useContext, useState } from 'react';
import {
  Badge,
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faFloppyDisk, faMagnifyingGlass, faSquare,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../../components/blanks/common/PageHeader';
import CardHeader from '../../../components/blanks/common/CardHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { CiatAppContext, LogicaContext, WinManagerContext } from '../../../providers';
import { EditorControls } from '../../basicEditor/editorControls';
import IconAlert from '../../../components/blanks/common/IconAlert';
import LogcaLoginModal from '../LogikaLogin/LogcaLoginModal';
import { Loader } from '../../../components/bootStrap';
import api from '../../../api/req';
import md from '../../../meta/srv/ImportFRFZfromLogica';
import DateLabel from '../../../components/Controls/dates/dateLabel';
import checkTask from '../../../api/checktask';

const FORMS = {
  plan: { code: 'BLE0020103', label: 'Помісячний розпис місцевого бюджету' },
  changes: { code: 'BLE0020104', label: 'Зміни до розпису місцевого бюджету' },
};
function Editor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const { auth } = useContext(CiatAppContext);
  const { ticket } = useContext(LogicaContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [done, setDone] = useState(false);
  const [err, setErr] = useState(null);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    year: new Date().getFullYear(),
    form: FORMS.changes.code,
  });
  const onLoad = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get(md.backendURL, auth, { ticket, year: params.year });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      setDone(false);
      loader()
        .then((d) => {
          setData(d[params.form].map((item) => ({ ...item, checked: !item.is_loaded })));
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, params.year, params.form, ticket],
  );
  const onSave = useCallback(
    () => {
      const loader = async () => {
        const r = await api.post(md.backendURL, auth, {
          ticket,
          auto_execute: true,
          [params.form]: data.filter((item) => item.checked).map((item) => item.id),
        });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        const { task_id: task } = await r.json();
        return checkTask(task, auth);
      };
      setSaving(true);
      setErr(null);
      loader()
        .then(() => {
          setDone(true);
          setData([]);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setSaving(false));
    },
    [auth, data, params.form, ticket],
  );
  return (
    <>
      <PageHeader
        className="mb-3"
      >
        <CardHeader
          title="Завантаження документів (FR, FZ) з сервісу Logica"
          className="cardPadding"
          light={false}
          role="menubar"
        />
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => dellComponentFromWindowsManager()}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <Card className="py-2">
        <Card.Body className="position-relative">
          {done && (
          <IconAlert variant="success" onClose={() => setDone(false)}>
            Дані завантажено вдало!!!
          </IconAlert>
          )}
          {err && (
          <IconAlert variant="danger" onClose={() => setErr(null)}>
            {err}
          </IconAlert>
          )}
          <LogcaLoginModal />
          {loading && <Loader />}
          <Row>
            <Col>
              <EditorControls.YearInput
                value={params.year}
                onChange={(e, v) => {
                  setData([]);
                  setParams((o) => ({ ...o, year: v }));
                }}
                label="Рік"
                description="Рік, за який перевіряются оновлення."
                required
              />
            </Col>
            <Col>
              <EditorControls.SelectorInput
                value={params.form}
                onChange={(e, v) => {
                  setData([]);
                  setParams((o) => ({ ...o, form: v }));
                }}
                label="Форма"
                required
                values={Object.values(FORMS)
                  .map(({ code, label }) => ({
                    value: code,
                    display_name: label,
                  }))}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button
              variant="falcon-primary"
              onClick={onLoad}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} bounce={!loading && !data.length} className="me-2" />
              Перевірити
            </Button>
            <Button
              variant="falcon-primary"
              onClick={() => setData((o) => o.map((i) => ({ ...i, checked: true })))}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Відмітити всі
            </Button>
            <Button
              variant="falcon-primary"
              onClick={() => setData((o) => o.map((i) => ({ ...i, checked: false })))}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faSquare} className="me-2" />
              Зняти відмітки
            </Button>
          </div>
          {!!data.length && (
            <>
              <div className="p-3 d-flex flex-wrap rounded">
                {data.map((item) => (
                  <div key={item.id} className="rounded border border-success px-4 py-2 m-2">
                    <EditorControls.CheckboxInput
                      value={item.checked}
                      label={(
                        <>
                          <Badge bg="primary" className="mx-2">{item.budget_code}</Badge>
                          {item.budget_name}
                        </>
                      )}
                      onChange={(e, v) => setData(
                        (o) => o.map((i) => (i.id === item.id ? { ...i, checked: v } : i)),
                      )}
                      description={(
                        <div>
                          Дані на
                          <DateLabel value={item.date} className="mx-1" />
                        </div>
                    )}
                    />
                  </div>
                ))}
              </div>
              <Button
                variant="falcon-success"
                onClick={onSave}
                disabled={saving}
              >
                <FontAwesomeIcon icon={faFloppyDisk} bounce={!saving} className="me-2" />
                Завантажити
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default Editor;
