import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BasicEditor, { DCControls, mapState } from '../../reportEditor';
import { changeField, processServerReq } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/govPlanSpend';
import {
  ResultSegment,
  StyledLabel,
  FiveColumnsGridContainer,
  ContainerThreeColumn, DivWithPropsLabel, StyledPane, CommandPanelColor, TwoColumnsGridContainer,
} from '../../../components/Form/styledForm';
import CheckBoxField from '../../field/CheckBox';
import { ItemField } from '../../field/selectorField/item';
import Fields from '../../field';
import TableEditor from '../../tableEditor';
import enums from '../../../constants/meta/enums';
import ReportContainer from '../reportContainer/variantReportContainer';
import YearField from '../../field/yearInput';
import DateRangeField from '../../../components/fields/DateRangeField';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import Tabs from '../../../components/tabs/Tabs';
import { emptyUid } from '../../../constants/meta/common';
import { dellComponentFromWindowsManager } from '../../../actions/windowsManager';
import meta from '../../../constants/meta';

const YearInput = YearField;

const FiveColGridContainer = styled(FiveColumnsGridContainer)`
  grid-template-columns: 25% 15% 20% 20% 20%;
`;

const CardDiv = styled.div`
    margin-right: 25px;
    box-sizing: border-box;
    // color: #377dff;
    padding: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: .0625rem solid rgba(231,234,243,.7);
    border-radius: .75rem;
    box-shadow: 0 6px 12px rgba(140,152,164,.075);
    transition: .3s;
    &:hover {
    transition: .3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3)};
`;

CardDiv.propTypes = { children: PropTypes.node };
function ReportEditor({ portalOpened, onReportDetails }) {
  const dispatch = useDispatch();

  const node = `rep/${md.name}/reportEditor`;

  const selector = (state) => state;

  const stateGlobal = useSelector(selector);
  const state = stateGlobal.get(node, new Map());
  const sessionOptions = stateGlobal.getIn(['auth', 'sessionOptions'], new Map());
  const headerForm = state.get('headerForm', new Map());
  const isProcessing = state.get('isProcessing', true);
  const isAdmin = sessionOptions.get('is_admin');
  const reportVariants = headerForm.get('reportVariants', new Map());

  const FO = headerForm.get('FO', new Map());
  const filterChoice = useMemo(
    () => new Map()
      .set('ЭлементКЕКВ', [{ fieldName: 'Владелец', value: FO }])
      .set('ЭлементКФК', [{ fieldName: 'Владелец', value: FO }])
      .set('ЭлементФонд', [{ fieldName: 'Владелец', value: FO }]),
    [FO],
  );

  const ReportKind = {
    plan: { label: 'Розпис', value: 0 },
    update: { label: 'Уточнення', value: 1 },
    all: { label: 'Розпис+уточнення', value: 2 },
  };

  const fundKEKVTypes = {
    none: { label: 'Не враховувати', name: '' },
    ...enums.FundKEKVKInds,
  };
  const fundKEKVOptions = Object.values(fundKEKVTypes).map((_enum) => (
    { label: _enum.label, value: _enum.name }
  ));

  const DetailsElKPKV = headerForm.get('byDetailsElKPKV', false);

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => headerForm.getIn(['docSendToLogica', 'id']),
    [headerForm],
  );

  const docId = useMemo(
    () => headerForm.getIn(['docId', 'id']),
    [headerForm],
  );

  useEffect(
    () => {
      const modelType = 'rep';
      const modelName = 'LimitSPR';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  const KPKVKMBValue = useMemo(
    () => headerForm.get('KPKVKMB', new Map()).toJS(),
    [headerForm],
  );

  const KPKVKMBValueFilled = KPKVKMBValue.id && KPKVKMBValue.id !== emptyUid;

  const BudgetLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('budget', new Map())}
      modelType="cat"
      modelName="budget"
      onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
    />
  ) : (
    <ItemField
      value={headerForm.get('budget', new Map())}
      disabled
    />
  );

  const FoLabel = isAdmin ? (
    <Fields.SelectorField
      value={headerForm.get('FO', new Map())}
      modelType="cat"
      modelName="csu"
      onChange={(e, v) => {
        dispatch(changeField(['headerForm', 'FO'], v));
        dispatch(changeField(['headerForm', 'signVariant'], new Map()));
      }}
    />
  ) : (
    <ItemField
      value={headerForm.get('FO', new Map())}
      disabled
    />
  );

  const headerUpdater = (path) => (e, value) => {
    dispatch(changeField(['headerForm', path], value));
  };

  const SettingsButton = (
    <div>
      <ContainerThreeColumn>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          {BudgetLabel}
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          {FoLabel}
        </div>

      </ContainerThreeColumn>
      <FiveColGridContainer>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'period'], v));
              dispatch(processServerReq('REFRESH_YEAR'));
            }}
            noBorder
          />
        </div>

        <div>
          <StyledLabel>{md.columns.year.label}</StyledLabel>
          <YearInput
            value={headerForm.get('year', 0)}
            onChange={(e, v) => {
              headerUpdater('year');
              dispatch(changeField(['headerForm', 'year'], v));
              dispatch(processServerReq('REFRESH_DATES'));
            }}
            disableCalculator
          />
        </div>

        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
          />
        </div>

      </FiveColGridContainer>
      <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
        <div style={{ marginRight: '15px' }}>
          <StyledLabel>{md.columns.fond.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('fond', new Map())}
            modelType="cat"
            modelName="fondClassifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'fond'], v))}
          />
        </div>

      </div>
      <div style={{
        display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch', marginBottom: '15px',
      }}
      >
        <CardDiv>
          <StyledLabel>{md.columns.ReportKind.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('ReportKind', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'ReportKind'], v))}
            values={Object.values(ReportKind)}
            name="ReportKind"
          />
        </CardDiv>
        <CardDiv>
          <StyledLabel>{md.columns.fundsType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('fundsType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'fundsType'], v))}
            values={Object.values(fundKEKVOptions)}
            name="fundsType"
          />
        </CardDiv>

        <CardDiv>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('planType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
            values={
              Object.values(enums.PlaningKind)
                .map((_e) => ({ label: _e.label, value: _e.name }))
            }
            name="planType"
          />
        </CardDiv>

        <CardDiv>
          <StyledLabel>{md.columns.reportType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('reportType', false)}
            disabled={DetailsElKPKV}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
            values={
              Object.values(enums.TypeRepPlanSpend)
                .map((_e) => ({ label: _e.label, value: _e.name }))
            }
            name="planType"
          />
        </CardDiv>
        <CardDiv>
          <CheckBoxField
            value={headerForm.get('byKEKV', false)}
            label={md.columns.byKEKV.label}
            disabled={DetailsElKPKV}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byKEKV'], v));
            }}
          />

          <CheckBoxField
            value={headerForm.get('byGroupKEKV', false)}
            label={md.columns.byGroupKEKV.label}
            disabled={DetailsElKPKV}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byGroupKEKV'], v));
            }}
          />

          <CheckBoxField
            value={headerForm.get('byReducedKEKV', false)}
            label={md.columns.byReducedKEKV.label}
            disabled={DetailsElKPKV}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byReducedKEKV'], v));
            }}
          />
          <CheckBoxField
            value={headerForm.get('allKEKV', false)}
            label={md.columns.allKEKV.label}
            disabled={DetailsElKPKV}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'allKEKV'], v));
            }}
          />

        </CardDiv>

        <CardDiv>
          <CheckBoxField
            value={headerForm.get('byCSU', false)}
            disabled={DetailsElKPKV}
            label={md.columns.byCSU.label}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byCSU'], v));
            }}
          />
          <CheckBoxField
            value={headerForm.get('byDetailsElKPKV', false)}
            placeholder="За деталізацією бюджетних прогам, (у т.ч. за трансфертами для обл.фін.деп.)"
            label={md.columns.byDetailsElKPKV.label}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byDetailsElKPKV'], v));
              dispatch(processServerReq('CHANGE_DETAILS_KPKV'));
            }}
          />
          <CheckBoxField
            value={headerForm.get('byGroupKBP', false)}
            disabled={DetailsElKPKV}
            label={md.columns.byGroupKBP.label}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'byGroupKBP'], v));
            }}
          />

        </CardDiv>
        <CheckBoxField
          value={headerForm.get('in1000', false)}
          label={md.columns.in1000.label}
          onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
        />
      </div>

      {DetailsElKPKV
          && (
            <Tabs>
              <DivWithPropsLabel label="Группування">
                <StyledPane>
                  <DCControls.Structure />
                </StyledPane>
              </DivWithPropsLabel>
              <DivWithPropsLabel label="Відбір">
                <StyledPane>
                  <DCControls.Filter
                    filterChoice={filterChoice}
                  />
                </StyledPane>
              </DivWithPropsLabel>
            </Tabs>
          )}
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      Settings={SettingsButton}
      backendName={md.backendName}
      reportVariants={reportVariants}
    >
      {/* <ContainerDiv> */}
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'period'], v));
              dispatch(processServerReq('REFRESH_YEAR'));
            }}
            noBorder
          />
        </div>
        <div style={{ marginLeft: '25px' }}>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            // filter={[
            //   { fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
          />
        </div>
      </div>
      <TwoColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.KPKVKMB.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('KPKVKMB', new Map())}
            modelType="cat"
            modelName="kbp7Classifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'KPKVKMB'], v))}
            canCleared
          />
          {/* <CheckBoxField */}
          {/*  value={headerForm.get('consolid', false)} */}
          {/*  label={md.columns.consolid.label} */}
          {/*  onChange={(e, v) => dispatch(changeField(['headerForm', 'consolid'], v))} */}
          {/* /> */}
        </div>
        <div>
          <StyledLabel>{md.columns.csu.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('csu', new Map())}
            modelType="cat"
            modelName="csu"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'csu'], v))}
            canCleared
          />
        </div>
      </TwoColumnsGridContainer>

      <div>
        <CommandPanelColor
          style={{ width: 'fit-content', alignSelf: 'end' }}
          label="Створити документ відправки в Logica"
          onClick={() => dispatch(processServerReq('CreateDispatchDoc'))}
          disabled={!KPKVKMBValueFilled}
        />
      </div>

      {/* </ContainerDiv> */}

      <ResultSegment>
        <TableEditor mountNode={[`rep/${md.name}/reportEditor`, 'result']} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
