import React, {
  useState, useCallback, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, Container,
} from 'react-bootstrap';
import {
  faClipboardList, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUploadDropZone, FileUploadButton } from '../../../components/bootStrap';
import { EditorCheckboxInput, EditorYearInput } from '../../basicEditor/editorControls';
import AppContext from '../../../providers/CiatAppContext';
import api, { NetworkException } from '../../../api/req';
import checkTask from '../../../api/checktask';
import { FILE_STATES } from './consts';
import File from './file';
import PageHeader from '../../../components/blanks/common/PageHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import CardHeader from '../../../components/blanks/common/CardHeader';
import { WinManagerContext } from '../../../providers';

function Editor({ title, backendUrl }) {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const [data, setData] = useState(() => {
    const today = new Date();
    return ({
      autoexecute: true,
      year: today.getFullYear(),
      curryear: today.getFullYear(),
    });
  });

  const [files, setFiles] = useState({});

  const onFilesAdd = useCallback(
    (e, v) => {
      setFiles(Object.keys(v).reduce((R, fName) => ({
        ...R,
        [fName]: {
          content: v[fName],
          state: FILE_STATES.prepared,
          result: null,
        },
      }), {})); // oldF - Закоментировано, чтобы после загрузки файлы очищались
    },
    [],
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const displayFiles = useMemo(
    () => Object.keys(files).map((fName) => ({ name: fName, ...files[fName] })),
    [files],
  );

  const { auth } = useContext(AppContext);

  const processHandler = useCallback(
    () => {
      const processFile = async (fileName) => {
        setFiles((of) => ({
          ...of,
          [fileName]: { ...of[fileName], state: FILE_STATES.pending },
        }));
        try {
          const r = await api.post(backendUrl, auth, {
            auto_execute: data.autoexecute,
            YEAR: data.year,
            file: {
              name: fileName,
              content: files[fileName].content,
            },
          });
          if (!r.ok && r.status !== 400) throw new NetworkException(`${r.status} ${r.statusText}`);
          const d = await r.json();
          if (r.status === 400) {
            throw new NetworkException(`${d.error_data.reduce((R, ed) => [...R, ...ed.messages], []).join(',\n')}`);
          }
          const result = await checkTask(d.task_id, auth);
          if (result && result.result && result.result.errors) {
            const errs = result.result.errors.reduce((R, e) => {
              if (!e) return R;
              if (e.constructor.name === 'Object') {
                return [
                  R,
                  ...Object.keys(e).map((key) => `${key}: ${e[key]}`),
                ]
                  .filter((er) => er)
                  .join(', ');
              }
              if (Array.isArray(e)) {
                return [
                  R,
                  ...e.map((ee) => `${ee}`),
                ]
                  .filter((er) => er)
                  .join(', ');
              }
              return `${R} ${e}`;
            }, '');
            throw new Error(errs);
          }
          setFiles((of) => ({
            ...of,
            [fileName]: {
              ...of[fileName],
              state: FILE_STATES.done,
              result,
            },
          }));
        } catch (e) {
          setFiles((of) => ({
            ...of,
            [fileName]: {
              ...of[fileName],
              state: FILE_STATES.errored,
              result: e.message,
            },
          }));
        }
      };
      setIsProcessing(true);
      Object.keys(files).reduce((p, fname) => p.then(() => processFile(fname)), Promise.resolve())
        .catch((e) => console.error(e.message))
        .finally(() => setIsProcessing(false));
    },
    [auth, backendUrl, data.autoexecute, data.year, files],
  );

  return (
    <>
      <PageHeader
        className="mb-3"
      >
        <CardHeader
          title={title}
          className="cardPadding"
          light={false}
          role="menubar"
        />
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => dellComponentFromWindowsManager()}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <Card className="py-2">
        <Card.Body className="position-relative">
          <EditorYearInput
            controlId="year"
            label="Рік"
            required
            value={data.year}
            onChange={(e, v) => setData((d) => ({ ...d, year: v }))}
            minValue={data.curryear - 3}
            maxValue={data.curryear + 1}
            disabled={isProcessing}
          />
          <FileUploadDropZone
            title={title}
            readAs="dataURL"
            multiple
            onChange={onFilesAdd}
            disabled={isProcessing}
          >
            <div>
              Перетяніть файли до блакитної зони, або оберить іх нажав на кнопку &nbsp;
              <FileUploadButton
                content="Обрати файли"
                icon={faUpload}
                onChange={onFilesAdd}
                readAs="dataURL"
                multiple
                title={title}
                disabled={isProcessing}
              />
            </div>
            <Container>
              {displayFiles.map((f) => (
                <File
                  key={f.name}
                  name={f.name}
                  state={f.state}
                  result={f.result}
                />
              ))}
            </Container>
          </FileUploadDropZone>
          <Button className="mt-2" disabled={isProcessing || !displayFiles.length} onClick={processHandler} variant="falcon-primary" size="lg">
            <FontAwesomeIcon icon={faClipboardList} className="me-2" bounce={!!displayFiles.length} />
            Відправити на обробку
          </Button>
        </Card.Body>
        <Card.Footer className="border-top">
          <EditorCheckboxInput
            controlId="autoexecute"
            label="Автопроведення"
            description="Знимати цю галку не рекомендується!"
            value={data.autoexecute}
            onChange={(e, v) => setData((d) => ({ ...d, autoexecute: v }))}
            disable={isProcessing}
          />
        </Card.Footer>
      </Card>
    </>
  );
}

Editor.propTypes = {
  title: PropTypes.string.isRequired,
  backendUrl: PropTypes.string.isRequired,
};

export default Editor;
