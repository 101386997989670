import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const kfbProps = tableHooks.useItemInputProps('kfb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const gloansZfProps = tableHooks.useNumberInputProps('gloans_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const gloansSfProps = tableHooks.useNumberInputProps('gloans_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const gloansDbProps = tableHooks.useNumberInputProps('gloans_db', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const gloansTotalProps = tableHooks.useNumberInputProps('gloans_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  gloansTotalProps.value = (gloansZfProps.value || 0) + (gloansSfProps.value || 0);

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="kfb" highlighted={highlights.includes('kfb')}>
            <TableControls.ItemPicker {...kfbProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="gloans_zf" highlighted={highlights.includes('gloans_zf')}>
            <TableControls.NumberInput {...gloansZfProps} />
          </TabelRowCell>
        </Col>

        <Col>
          <TabelRowCell column="gloans_sf" highlighted={highlights.includes('gloans_sf')}>
            <TableControls.NumberInput {...gloansSfProps} />
          </TabelRowCell>
        </Col>

        <Col>
          <TabelRowCell column="gloans_db" highlighted={highlights.includes('gloans_db')}>
            <TableControls.NumberInput {...gloansDbProps} />
          </TabelRowCell>
        </Col>

        <Col>
          <TabelRowCell column="gloans_total" highlighted={highlights.includes('gloans_total')}>
            <TableControls.NumberInput {...gloansTotalProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
