import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { ArrowRightIcon } from '../../assets/icons';

const MenuItem = styled.div`
  width: 300px;
  border: 1px solid #BACCE2;
  position: absolute;
  z-index: 1031;
  color: #575757;
  background: white;
`;

// const ctxOptions = [
//   {
//     label: 'first menu',
//     icon: <ArrowRightIcon />,
//     disabled: true,
//     delimiter: true,
//     action: 'you clicked 1 menu',
//     children: [
//       {
//         label: '--sub first menu',
//       },
//     ],
//   }, {
//     label: 'second menu',
//     action: 'you clicked 2 menu',
//   }, {
//     label: 'third menu',
//     action: 'you clicked 3 menu',
//     children: [
//       {
//         label: '--sub second menu',
//         disabled: true,
//         delimiter: true,
//         action: 'you clicked --sub 2 menu',
//       },
//       {
//         label: '--sub third menu',
//         action: 'you clicked --sub 3 menu',
//       },
//     ],
//   },
// ];

const BoxOptionsMenu = styled.div`
 ${({ delimiter }) => delimiter
  && 'border-bottom: 1px dotted lightgray;'};
`;
const StyleOptionsMenu = styled.div`
${({ disabled }) => disabled
  && 'filter: opacity(0.6)'};
  display: flex;
  position: relative;
  padding: 7px 15px;
  cursor: pointer;
    :hover{
      background: #d9e5f3;
    };
`;
const BoxSubMenu = styled.div`
  position: absolute;
  background: white;
  left: 300px;
  width: 300px;
  margin-top: -34px;
`;
const StyleSubMenu = styled(StyleOptionsMenu)`
  background: #ceffce;
   ${({ delimiter }) => delimiter
  && 'border-bottom: 1px dotted lightgray;'};
`;
const ArrowIcon = styled(ArrowRightIcon)`
  margin-left: auto;
    *{
      fill: #b5b5b5;
    }
`;
const Clicker = styled.div`
  position: relative;
`;

const ContextMenuHOC = (WrappedComponent) => {
  class ContextMenu extends PureComponent {
    static propTypes = {
      ctxOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.element,
        disabled: PropTypes.bool,
        delimiter: PropTypes.bool,
        children: PropTypes.arrayOf,
        action: PropTypes.string,
        // onActionClick: PropTypes.func,
      })),
      onActionClick: PropTypes.func,
    };

    static defaultProps = {
      ctxOptions: [],
      onActionClick: (value) => {
        console.log(value);
      },
    };

    constructor(props) {
      super(props);
      this.state = {
        x: 0,
        y: 0,
        open: false,
        showSubMenu: false,
        hoverItem: false,
      };
      this.containerRef = createRef();
    }

    componentDidMount() {
      document.addEventListener('click', this.clicker);
      document.addEventListener('contextmenu', this.clicker);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.clicker);
      document.removeEventListener('contextmenu', this.clicker);
    }

    onContextMenu = (e) => {
      if (this.containerRef.current.contains(e.target)) {
        e.preventDefault();
        this.setState({
          x: e.pageX,
          y: e.pageY,
          open: true,
        });
        return false;
      }
    };

    clicker = (e) => {
      if (this.state.open
        && !this.containerRef.current.contains(e.target)) this.setState({ open: false });
    };

    render() {
      const { onActionClick, ctxOptions, ...restProps } = this.props;

      const {
        x, y, open, showSubMenu, hoverItem,
      } = this.state;
      return (
        <div className="resize-container" ref={this.containerRef}>
          <div>
            {open && createPortal(
              (
                <MenuItem
                  style={{ top: `${y}px`, left: `${x}px` }}
                  onMouseLeave={() => this.setState({ showSubMenu: false })}
                >
                  {ctxOptions.map((item) => (
                    <BoxOptionsMenu
                      delimiter={item.delimiter}
                      key={item.label}
                    >
                      <StyleOptionsMenu
                        disabled={item.disabled}
                        onClick={() => {
                          if (!item.children && !item.disabled) {
                            onActionClick(item.action);
                            this.setState({ open: false });
                          }
                        }}
                        onMouseEnter={() => this.setState({
                          showSubMenu: true,
                          hoverItem: item.label,
                        })}
                      >
                        <div style={{ marginRight: '10px' }}>
                          {item.icon}
                        </div>
                        {item.label}
                        {item.children
                        && <ArrowIcon />}
                      </StyleOptionsMenu>
                      {hoverItem === item.label
                  && item.children && showSubMenu && !item.disabled
                  && (
                    <BoxSubMenu>
                      {item.children
                        .map((child) => (
                          <StyleSubMenu
                            onClick={() => {
                              if (!child.disabled) {
                                onActionClick(child.action);
                                this.setState({ open: false });
                              }
                            }}
                            disabled={child.disabled}
                            delimiter={child.delimiter}
                            key={child.label}
                          >
                            {item.icon}
                            {child.label}
                          </StyleSubMenu>
                        ))}
                    </BoxSubMenu>
                  )}
                    </BoxOptionsMenu>
                  ))}
                </MenuItem>
              ), document.body,
            )}
            <Clicker
              className="ForClick"
              onContextMenu={this.onContextMenu}
              onClick={() => this.setState({ open: false })}
            >
              <WrappedComponent
                {...restProps}
              />
            </Clicker>
          </div>
        </div>
      );
    }
  }
  return ContextMenu;
};

export default ContextMenuHOC;
