import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { FieldPropType, FieldErrorPropType } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';
import HistoryContainer from '../../../historyValues/historyContainer';

function HistoryExplanation({
  data, fields, actions, id, errors,
}) {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.kbp.backendURL,
    id,
    fields,
    onChange: actions.onChange,
    errors,
  });

  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const codeProps = editorHooks.useStringInputProps('code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const fullNameProps = editorHooks.useStringInputProps('fullname', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const parentProps = editorHooks.useItemInputProps('parent', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);

  return (
    <div>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <HistoryContainer errors={historyFieldErrors} fields={historyFields}>
        <Container fluid style={{ padding: '0' }}>
          <Row>
            <Col>
              <EditorControls.StringInput {...codeProps} />
            </Col>
            <Col>
              <EditorControls.StringInput {...nameProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput {...fullNameProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker {...parentProps} modelType="cat" modelName="explanation" />
            </Col>
            <Col>
              <Col>
                <EditorControls.CheckboxInput {...isGroupProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isActiveProps} />
              </Col>
            </Col>
          </Row>
        </Container>
      </HistoryContainer>
    </div>
  );
}

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
  fullname: PropTypes.string,
  parent: PropTypes.shape({ }),
  is_group: PropTypes.bool,
};

const errorsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});
const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryExplanation.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  errors: PropTypes.shape(
    errorsPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  history_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHistoryDropDown: PropTypes.func.isRequired,
  history_loading: PropTypes.bool,
  onChangeHistoryVariant: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired, // Признак того что создается ноывй элемнт
  // ( не элемент истории а элемент классификатора)
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

HistoryExplanation.defaultProps = {
  history_loading: false,
};

export default HistoryExplanation;
