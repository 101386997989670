/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ListerFactory from '../../../newLister';
import ElementListerSelector from './lister_selector';
import { modelType, modelName } from '../def';
import { CatTableListerFactory } from '../../../newLister/tableComponent';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory({}));

function ElementLister(props) {
  return (
    <ElementListerSelector {...props}>
      <StdLister />
    </ElementListerSelector>
  );
}

export default ElementLister;
