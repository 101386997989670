import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FileColorIcon } from '../../../../assets/icons';
import Flex from '../../../reports/quickLinks/Flex';

function SharedFile({
  docTitle, file, fileHref, handleUploadFile, isSecondFile, method,
}) {
  return (
    <Flex alignItems="center" className="m-3 hover-actions-trigger">
      <div className="d-flex align-items-center mb-4">
        <img src={FileColorIcon} alt="FileIcon" width="33px" />
        <div>
          <h6 className="mb-0 text-700">
            {docTitle}
          </h6>
          <span
            className="fs--2 text-500 font-sans-serif font-italic"
          >
            {file?.name || (!!fileHref && 'Файл обрано') || 'Файл не обрано'}
          </span>
        </div>
        <div className="d-flex ms-4">
          <Form>
            <Form.Group
              controlId="formFilePdf"
              className="m-0"
              id={method}
            >
              <Button
                title="Додати файл"
                variant="light"
                size="sm"
                className="border-300 me-1 text-600"
              >
                <Form.Control
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    handleUploadFile(
                      e,
                      method,
                      isSecondFile,
                    );
                  }}
                />
                <Form.Label><FontAwesomeIcon icon={faUpload} /></Form.Label>
              </Button>
            </Form.Group>
          </Form>
          <Button
            variant="light"
            size="sm"
            className="border-300 me-1 text-600"
            title="Завантажити файл"
            disabled={!fileHref}
            as="a"
            href={fileHref}
            download
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </div>
      </div>
    </Flex>
  );
}

SharedFile.propTypes = {
  file: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string,
    user: PropTypes.string,
    time: PropTypes.string,
    border: PropTypes.bool,
  }),
  docTitle: PropTypes.string,
  fileHref: PropTypes.string,
  handleUploadFile: PropTypes.func.isRequired,
  isSecondFile: PropTypes.bool,
  method: PropTypes.string.isRequired,
};

SharedFile.defaultProps = {
  file: null,
  docTitle: 'Документ',
  fileHref: null,
  isSecondFile: false,
};

export default SharedFile;
