import base from './base';

const columns = {
  ...base.columns,
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  CodeBudget: { label: 'Код 3', name: 'КодБюджетаКазн', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  nameV: { label: 'Імя казн.виписки', name: 'ИмяФайлаДБФ', visible: false },
  fileA: { label: 'АдресФайла', name: 'АдресФайла', visible: false },
  pathV: { label: 'ВыбПуть', name: 'ВыбПуть', visible: false },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      date: { label: 'Дата', name: 'Дата' },
      territoryCode: { label: 'Код ', name: 'КодТеритории' },
      FI: { label: 'Місто', name: 'ФинансовыйОрган' },
      GroupSymbols: { label: 'Група символів', name: 'ГруппаСимволов' },
      Fond: { label: 'Фонд', name: 'Фонд' },
      codeKDB: { label: 'Код КДБ (Казн.)', name: 'КДБКод' },
      KDB: { label: 'КДБ', name: 'КДБ' },
      elementKDB: { label: 'Деталізація КДБ (якщо потрібно)', name: 'ЭлементКДБ' },
      sumReceived: { label: 'Надійшло за день', name: 'Поступило' },
      sumRecDelimitation: { label: 'Надійшло за день (розмежування)', name: 'ПоступилоРозщ' },
      sumRecBeginningYear: { label: 'Надійшло з початку року', name: 'ПоступилоСНачалаГода' },
      sumRecBegYearDelimitation: { label: 'Надійшло з початку року (розмежування)', name: 'ПоступилоСНачалаГодаРозщ' },
      reportingSymbol: { label: 'Символ звітності', name: 'СимволОтчетности' },
    },
  },
};

const definition = {
  label: 'Казн. виписка доходи',
  name: 'treasuryStatementIncome',
  backendName: 'ВыпискаДоходы',
  columns,
  frontend: 'doc/treasuryStatementIncome',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'Budget',
      'Author',
      'CodeBudget',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
