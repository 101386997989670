import { useCallback, useState } from 'react';

/**
 * HOOK для доставки уведомлений
 * @returns {{
 *  messages: *[],
 *  deleteMessage: (function(number): void),
 *  clearMessages: (function(): void),
 *  addMessage: (function({text: string, title: string, variant?: string}): void)
 *  }}
 */
const useMessages = () => {
  // сообщения - предупреждения, информационные сообщения и т.д.
  const [messages, setMessages] = useState([]);
  const clearMessages = useCallback(
    () => setMessages([]),
    [],
  );
  const addMessage = useCallback(
    ({ text, title, variant }) => setMessages((om) => {
      const maxId = om.length ? Math.max(...om.map((m) => m.id)) : 0;
      return [...om, {
        id: maxId + 1,
        text,
        title,
        variant: variant || 'info',
      }];
    }),
    [],
  );
  const deleteMessage = useCallback(
    (messageId) => setMessages((om) => om.filter((m) => m.id !== messageId)),
    [],
  );
  return {
    messages,
    addMessage,
    deleteMessage,
    clearMessages,
  };
};

export default useMessages;
