import React, {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Tab, TabContainer, Tabs,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import api from '../../../../api/req';
import { CiatAppContext } from '../../../../providers';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import useEditorParams from '../../../newEditor/hook/params';
import useAuth from '../../../../components/Login/hooks/useAuth';

function Editor({ id, onClose, onSave }) {
  const editorParams = useEditorParams();
  const {
    data,
    actions,
    fields, nonFieldErrors, options,
    isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: meta.cat.user.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const { auth } = useContext(CiatAppContext);
  const [disposersList, setDisposersList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [showMsg, setShowMsg] = useState('');

  const { onLoading, onErr } = actions;
  const { resetPassHandler } = useAuth();

  const onLoadStart = useCallback(() => {
    onLoading(true);
    onErr(null);
  }, [onLoading, onErr]);

  const onLoadEnd = useCallback(() => {
    onLoading(false);
  }, [onLoading]);
  const onError = useCallback((e) => onErr(e), [onErr]);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/references/refdisposer/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.map((el) => ({ ...el, value: el.id, display_name: el.name }));
        setDisposersList(newData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/auth/permissiongroup/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    loader()
      .then((rData) => {
        setGroupList(rData.map((r) => ({
          id: r.id, name: r.name, value: r.id, display_name: r.name,
        })));
      })
      .catch((e) => onError(e.message));
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const disposersProps = editorHooks.useSelectorInputProps('disposers', data, fields, fieldErrors, actions.onChange);
  const groupProps = editorHooks.useSelectorInputProps('group', data, fields, fieldErrors, actions.onChange);
  const defaultPageProps = editorHooks.useSelectorInputProps('default_page', data, fields, fieldErrors, actions.onChange);

  const userData = data.user ? data.user : {};
  const userFields = fields ? fields.user.children : {};

  const onChange = (v) => {
    const user = data.user ? {
      ...data.user,
      ...v(),
    } : {
      ...v(),
    };
    actions.onChange({
      ...data,
      user: {
        ...user,
      },
    });
  };

  const usernameProps = editorHooks.useStringInputProps('username', userData, userFields, fieldErrors, onChange);
  const lastNameProps = editorHooks.useStringInputProps('last_name', userData, userFields, fieldErrors, onChange);
  const firstNameProps = editorHooks.useStringInputProps('first_name', userData, userFields, fieldErrors, onChange);
  const emailProps = editorHooks.useStringInputProps('email', userData, userFields, fieldErrors, onChange);
  const isSuperuserProps = editorHooks.useCheckboxInputProps('is_superuser', userData, userFields, fieldErrors, onChange);
  const isStaffProps = editorHooks.useCheckboxInputProps('is_staff', userData, userFields, fieldErrors, onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', userData, userFields, fieldErrors, onChange);

  const updateGroup = useCallback(
    (group) => groupList
      .filter((r) => r.id === group)
      .reduce((R, r) => r, null),
    [groupList],
    [],
  );

  const group = useMemo(
    () => data && data.group && data.group.id,
    [data],
  );

  const onSetGroup = useCallback(
    (e, v) => actions.onChange({ group: updateGroup(v) }),
    [actions, updateGroup],
  );

  const email = emailProps.value;

  const resetPasswod = () => {
    resetPassHandler(email, setShowMsg);
    if (showMsg) {
      toast.success(
        showMsg,
        {
          theme: 'colored',
        },
      );
    }
  };

  const disabledSendMail = !((id !== 'create') && (email) && (isActiveProps.value));

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <CatCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          backendURL={meta.cat.user.backendURL}
        >
          <Button
            variant="falcon-warning"
            onClick={resetPasswod}
            disabled={disabledSendMail}
          >
            Надіслати лист користувачу, щодо встановлення паролю
          </Button>
        </CatCommandPanel>
      )}
      title={userData.username || ''}
      subtitle={`${userData.first_name || ''} ${userData.last_name || ''}`}
    >
      <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid>
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    {...authorityProps}
                    label="Розпорядник вищого рівня"
                  />
                </Col>
                <Col>
                  <EditorControls.SelectorInput
                    {...groupProps}
                    values={groupList}
                    value={group}
                    label="Група"
                    onChange={onSetGroup}
                  />
                </Col>
              </Row>
              <Row>
                {disposersList.length > 0
                && (
                <Col>
                  <EditorControls.MultiSelector {...disposersProps} values={disposersList} />
                </Col>
                )}
                <Col>
                  <EditorControls.SelectorInput {...defaultPageProps} />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
        <Tab eventKey="user" title="Користувач">
          <TabContainer>
            <Row>
              <Col>
                <EditorControls.StringInput {...usernameProps} />
              </Col>
              <Col>
                <EditorControls.StringInput
                  {...emailProps}
                  placeholder="ел.адреса@для.встановлення.паролю"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.StringInput {...firstNameProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...lastNameProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...isSuperuserProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isStaffProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isActiveProps} />
              </Col>
            </Row>
          </TabContainer>
        </Tab>
      </Tabs>
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
