import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import OrdersList from './ordersList';
import { availableOrdersPropType, getPreviousOrder, ordersPropType } from '../../hooks';

function OrdersEditor({ availableOrders, orders, ordersHandlers }) {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);

  const [openedOrders, setOpenedOrders] = useState([]);
  const visibleOrders = useMemo(() => availableOrders.reduce((R, col) => [
    ...R,
    col.parent === null && {
      ...col,
      lvl: 0,
      hasChild: !!availableOrders.filter((col2) => col2.parent === col.name).length,
    },
    ...availableOrders.filter((col2) => col2.parent === col.name && openedOrders
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1, hasChild: false })),
  ], []).filter((col) => col), [availableOrders, openedOrders]);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveOrder(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedOrders = useCallback((val) => {
    if (openedOrders.includes(val)) {
      return setOpenedOrders((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedOrders((prev) => ([...prev, val]));
  }, [openedOrders]);

  const ordersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        ordersHandlers.addOrderHandler(name, oname);
      } else if (type === itemTypes.order) {
        ordersHandlers.swapOrderHandler(name, oname);
      }
    },
    [ordersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.order) {
        ordersHandlers.removeOrderHandler(name);
        if (activeOrder === name) setActiveOrder(null);
      }
    },
    [activeOrder, ordersHandlers],
  );

  return (
    <div className="d-flex h-100 overflow-hidden">
      <div className="overflow-hidden flex-fill w-50">
        <AvailableFieldList
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={ordersHandlers.addOrderHandler}
          cardTitle="Доступні для сортування поля"
          cardText={(
            <p className="text-justify mb-0">
              дозволяють вам організувати ваші дані за зростаючим або спадаючим порядком.
              Це полегшує виявлення тенденцій, закономірностей та зрозуміння ваших даних. Ви можете
              перетягнути будь-яке поле з
              <b className="mx-1">&quot;Доступних для сортування полів&quot;</b>
              в область
              <b className="mx-1">&quot;Обрані для сортування поля&quot;</b>
              ,
              або зробити це поле активним та натиснути кнопку
              <RightButton
                className="mx-1"
                onClick={() => ordersHandlers.addOrderHandler(activeAvaField)}
                disabled={!activeAvaField}
              />
              ,
              що знаходиться зліва. Очистити все сортування можна кнопкою
              <ClearAllButton
                className="mx-1"
                onClick={() => ordersHandlers.clearAllOrdersHandler()}
                disabled={!orders.length}
              />
              . Ви можете сортувати
              за одним або декількома полями, а також вибирати напрямок сортування
            </p>
          )}
          onDragEnd={avaDragEndHanlder}
          setOpenedItems={onSetOpenedOrders}
          visibleItems={visibleOrders}
          openedItems={openedOrders}
        />
      </div>
      <div className="overflow-hidden d-flex align-items-center px-2 ">
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => ordersHandlers.clearAllOrdersHandler()}
            disabled={!orders.length}
          />
          <LeftButton
            onClick={() => {
              ordersHandlers.removeOrderHandler(activeOrder);
              setActiveOrder(getPreviousOrder(orders, activeOrder));
            }}
            disabled={activeOrder === null}
          />
          <RightButton
            onClick={() => ordersHandlers.addOrderHandler(activeAvaField)}
            disabled={!activeAvaField}
          />
        </ButtonGroup>
      </div>
      <div className="overflow-hidden flex-fill w-50">
        <OrdersList
          cardTitle="Обрані для сортування поля"
          cardText={(
            <p className="text-justify mb-0">
              Після того, як поле буде додано до області сортування, ви можете вибрати
              Сортування за зростанням або спаданням за допомогою кнопки у вигляді трикутника.
              Сортування за зростанням: Цей варіант організовує ваші дані від найменшого до
              найбільшого або від найстарішого до найновішого.
              Сортування за спаданням: Цей варіант організовує ваші дані від найбільшого до
              найменшого або від найновішого до найстарішого. Ви можете створити кілька
              рівнів сортування за допомогою різних полів.
            </p>
          )}
          orders={orders}
          activeOrder={activeOrder}
          onClick={onActiveSelectionHandler}
          onDblClick={ordersHandlers.removeOrderHandler}
          onDragEnd={ordersDragEndHanlder}
          onDirectionChange={ordersHandlers.changeOrderDirectionHandler}
        />
      </div>
    </div>
  );
}

OrdersEditor.propTypes = {
  orders: ordersPropType.isRequired,
  availableOrders: availableOrdersPropType.isRequired,
  ordersHandlers: PropTypes.shape({
    addOrderHandler: PropTypes.func,
    removeOrderHandler: PropTypes.func,
    swapOrderHandler: PropTypes.func,
    clearAllOrdersHandler: PropTypes.func,
    changeOrderDirectionHandler: PropTypes.func,
  }).isRequired,
};

export default OrdersEditor;
