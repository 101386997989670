import React, {
  useState, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import md from '../../../../constants/meta/documents/liquidity';
import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';
import { FilteredTabs } from '../../../../components/fields/boostrap';
import CommandPanel from './commandpanel';
import { modelType, modelName } from '../def';

const FRLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

const filterValues = [
  {
    value: 1,
    label: 'За розпорядником',
  },
  {
    value: 2,
    label: 'За розпорядником нижчого рівня',
  },
  {
    value: 3,
    label: 'Всі',
  },
  // { value: 4, label: 'За розпорядником вищого рівня' },
];

const mapState = (store) => ({
  FO: store.getIn(['auth', 'sessionOptions', 'fo'], new Map()),
  isAdmin: store.getIn(['auth', 'sessionOptions', 'is_admin'], false),
  finRequestsHOC: store.getIn(['auth', 'sessionOptions', 'finRequestsHOC'], false),
  currentFOParent: store.getIn(['auth', 'sessionOptions', 'foParent'], new Map()),
  budgetId: store.getIn(['auth', 'sessionOptions', 'budget', 'id'], ''),
});

function Lister() {
  const {
    FO,
    isAdmin,
    finRequestsHOC,
    currentFOParent,
    budgetId,
  } = useSelector(mapState);

  // TODO: Специально для білої церкви. Після 15.06.2024 прибрати умову.
  const overridePermissions = useMemo(
    () => ([
      '713abea8-5c93-11e9-8bb2-7824af41bb8e', // Біла церка
      '888b8034-d7e5-11e6-98e4-000c29123d1d', // Запоріжжя
    ].includes(budgetId)
      ? {}
      : {
        canCopy: false,
        canDelete: false,
        // canEdit: false,
        canExecute: false,
        canNew: false,
        canResetSign: false,
        canSign: false,
        canUnexecute: false,
      }),
    [budgetId],
  );

  const [fValue, setFValue] = useState(isAdmin ? 3 : 1);
  const displayFilterValues = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => finRequestsHOC ? filterValues : filterValues.filter(((f) => f.value !== 4)),
    [finRequestsHOC],
  );
  const flt = useMemo(
    () => {
      switch (fValue) {
        case 1:
          return [{
            fieldName: md.columns.FI.name,
            value: FO.toJS(),
          }];
        case 2:
          return [{
            fieldName: `${md.columns.FI.name}.Родитель`,
            value: FO.toJS(),
          }];
        case 4:
          return [{
            fieldName: `${md.columns.FI.name}`,
            value: currentFOParent.toJS(),
          }];
        default:
          return [];
      }
    },
    [FO, currentFOParent, fValue],
  );
  return (
    <>
      <FilteredTabs values={displayFilterValues} value={fValue} onChange={(e, v) => setFValue(v)} />
      <FRLister filter={flt} overridePermissions={overridePermissions} />
    </>
  );
}

export default Lister;
