import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col, Alert, Badge, Button,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { PlanningRows } from './tp';
import { PrependFond } from '../../styles/klassifiers';
import useEditorParams from '../../../newEditor/hook/params';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.revenueplan.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondItemProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const fondItemFilter = useMemo(
    () => ({ owner: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  const [warnings, setWarnings] = useState(null);
  const onExecute = useCallback(
    () => {
      const amountColmns = [
        'amount_total',
        [...Array(12)].map((a, i) => `amount_${i}`),
      ];
      const emptyRows = data.revenueplanrow_set.filter(
        (row) => !!row.kdb && amountColmns.reduce((R, aColumn) => R && (!row[aColumn]), true),
      );
      if (!emptyRows.length) {
        actions.onExecute();
      } else {
        setWarnings(
          <Alert variant="warning" dismissible onClose={() => setWarnings(null)}>
            <Alert.Heading>Знайдені пусті рядки</Alert.Heading>
            <p>
              В рядках по КДБ:
              <div className="d-inline-flex gap-2 mx-2">
                {emptyRows.map((r, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Badge bg="warning" key={i}>{r.kdb.repr}</Badge>
                ))}
              </div>
              знайдені путсі строки (всі суми дорівнюють 0)
            </p>
            <hr className="text-warning" />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  setWarnings(null);
                  actions.onExecute();
                }}
                variant="outline-warning"
              >
                Все одно провести
              </Button>
            </div>
          </Alert>,
        );
      }
    },
    [actions, data.revenueplanrow_set],
  );

  const docActions = useMemo(
    () => ({ ...actions, onExecute }),
    [actions, onExecute],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={docActions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.revenueplan.backendURL}
          id={id}
          repr={data.repr}
        />
      )}
    >
      {warnings}
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} required />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...fondItemProps}
                  filter={fondItemFilter}
                  canErase
                  prepend={(
                    <PrependFond>
                      {data.fond && data.fond.code}
                    </PrependFond>
                  )}
                />
              </Col>
            </Row>
            <Row>
              {!headerReadOnlyFields.includes('only_year') && (
                <Col>
                  <EditorControls.CheckboxInput {...onlyYearProps} />
                </Col>
              )}
              <Col>
                <EditorControls.CheckboxInput {...vt4brProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="assignmentplanningrev" id="uncontrolled-tab-example">
          {fields && fields.revenueplanrow_set && (
          <Tab eventKey="assignmentplanningrev" title={fields.revenueplanrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
