import {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import api from '../../../../../../../api/req';
import meta from '../../../../../../../meta';
import { CiatAppContext } from '../../../../../../../providers';

const useSelectAcc = ({
  activeRow, tableData, headerDisposer, fond, onChange,
}) => {
  const { auth } = useContext(CiatAppContext);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [rowAccounts, setRowAccounts] = useState([]);
  const [showChoice, setShowChoice] = useState(false);

  const checkAccounts = useRef(false);
  const modifiedOnChange = useCallback((e, rowIndex, partData) => {
    if ('kbp7_item' in partData || 'disposer' in partData) checkAccounts.current = true;
    onChange(e, rowIndex, partData);
  }, [onChange]);

  useEffect(() => {
    const loader = async (flt) => {
      const r = await api.get(meta.cat.treasureaccount.backendURL, auth, flt);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    if (activeRow != null) {
      if (checkAccounts.current) {
        const row = tableData && tableData[activeRow];
        const filter = {
          disposer: headerDisposer?.id || row.disposer?.id,
          kbp7: row.kbp7?.id,
          fond: fond?.id,
          active_for_opdate: true,
        };

        if (Object.values(filter).reduce((R, r) => R && r, true)) {
          setLoading(true);
          setErr(null);
          loader(filter)
            .then((d) => {
              if (d.length > 1) {
                setRowAccounts(d);
                setShowChoice(true);
              }
            })
            .catch((e) => setErr(e.message))
            .finally(() => setLoading(false));
        }
      }
    }
    checkAccounts.current = false;
  }, [activeRow, auth, fond?.id, headerDisposer?.id, tableData]);

  return {
    rowAccounts,
    showChoice,
    setShowChoice,
    loading,
    err,
    onCellChange: modifiedOnChange,
  };
};

export default useSelectAcc;
