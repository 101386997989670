import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R01G3: { label: 'R01G3', name: 'R187G3' },
  R01G4: { label: 'R01G3', name: 'R187G4' },
  R01G5: { label: 'R01G3', name: 'R187G5' },
  R01G6: { label: 'R01G3', name: 'R187G6' },
  R01G7: { label: 'R01G3', name: 'R187G7' },
  R02G3: { label: 'R01G3', name: 'R188G3' },
  R02G4: { label: 'R01G3', name: 'R188G4' },
  R02G5: { label: 'R01G3', name: 'R188G5' },
  R02G6: { label: 'R01G3', name: 'R188G6' },
  R02G7: { label: 'R01G3', name: 'R188G7' },
  R03G3: { label: 'R01G3', name: 'R189G3' },
  R03G4: { label: 'R01G3', name: 'R189G4' },
  R03G5: { label: 'R01G3', name: 'R189G5' },
  R03G6: { label: 'R01G3', name: 'R189G6' },
  R03G7: { label: 'R01G3', name: 'R189G7' },
  R154G3: { label: 'R01G3', name: 'R190G3' },
  R154G4: { label: 'R01G3', name: 'R190G4' },
  R154G5: { label: 'R01G3', name: 'R190G5' },
  R154G6: { label: 'R01G3', name: 'R190G6' },
  R154G7: { label: 'R01G3', name: 'R190G7' },
  R155G3: { label: 'R01G3', name: 'R191G3' },
  R155G4: { label: 'R01G3', name: 'R191G4' },
  R155G5: { label: 'R01G3', name: 'R191G5' },
  R155G6: { label: 'R01G3', name: 'R191G6' },
  R155G7: { label: 'R01G3', name: 'R191G7' },
  R156G3: { label: 'R01G3', name: 'R192G3' },
  R156G4: { label: 'R01G3', name: 'R192G4' },
  R156G5: { label: 'R01G3', name: 'R192G5' },
  R156G6: { label: 'R01G3', name: 'R192G6' },
  R156G7: { label: 'R01G3', name: 'R192G7' },
  R157G3: { label: 'R01G3', name: 'R193G3' },
  R157G4: { label: 'R01G3', name: 'R193G4' },
  R157G5: { label: 'R01G3', name: 'R193G5' },
  R157G6: { label: 'R01G3', name: 'R193G6' },
  R157G7: { label: 'R01G3', name: 'R193G7' },
  R158G3: { label: 'R01G3', name: 'R194G3' },
  R158G4: { label: 'R01G3', name: 'R194G4' },
  R158G5: { label: 'R01G3', name: 'R194G5' },
  R158G6: { label: 'R01G3', name: 'R194G6' },
  R158G7: { label: 'R01G3', name: 'R194G7' },
  R159G3: { label: 'R01G3', name: 'R195G3' },
  R159G4: { label: 'R01G3', name: 'R195G4' },
  R159G5: { label: 'R01G3', name: 'R195G5' },
  R159G6: { label: 'R01G3', name: 'R195G6' },
  R159G7: { label: 'R01G3', name: 'R195G7' },
  R199G3: { label: 'R01G3', name: 'R199G3' },
  R199G4: { label: 'R01G3', name: 'R199G4' },
  R199G5: { label: 'R01G3', name: 'R199G5' },
  R199G6: { label: 'R01G3', name: 'R199G6' },
  R199G7: { label: 'R01G3', name: 'R199G7' },
  R200G3: { label: 'R01G3', name: 'R200G3' },
  R200G4: { label: 'R01G3', name: 'R200G4' },
  R200G5: { label: 'R01G3', name: 'R200G5' },
  R200G6: { label: 'R01G3', name: 'R200G6' },
  R200G7: { label: 'R01G3', name: 'R200G7' },
  R201G3: { label: 'R01G3', name: 'R201G3' },
  R201G4: { label: 'R01G3', name: 'R201G4' },
  R201G5: { label: 'R01G3', name: 'R201G5' },
  R201G6: { label: 'R01G3', name: 'R201G6' },
  R201G7: { label: 'R01G3', name: 'R201G7' },
  R202G3: { label: 'R01G3', name: 'R202G3' },
  R202G4: { label: 'R01G3', name: 'R202G4' },
  R202G5: { label: 'R01G3', name: 'R202G5' },
  R202G6: { label: 'R01G3', name: 'R202G6' },
  R202G7: { label: 'R01G3', name: 'R202G7' },
  R203G3: { label: 'R01G3', name: 'R203G3' },
  R203G4: { label: 'R01G3', name: 'R203G4' },
  R203G5: { label: 'R01G3', name: 'R203G5' },
  R203G6: { label: 'R01G3', name: 'R203G6' },
  R203G7: { label: 'R01G3', name: 'R203G7' },
  R204G3: { label: 'R01G3', name: 'R204G3' },
  R204G4: { label: 'R01G3', name: 'R204G4' },
  R204G5: { label: 'R01G3', name: 'R204G5' },
  R204G6: { label: 'R01G3', name: 'R204G6' },
  R204G7: { label: 'R01G3', name: 'R204G7' },
  R205G3: { label: 'R01G3', name: 'R205G3' },
  R205G4: { label: 'R01G3', name: 'R205G4' },
  R205G5: { label: 'R01G3', name: 'R205G5' },
  R205G6: { label: 'R01G3', name: 'R205G6' },
  R205G7: { label: 'R01G3', name: 'R205G7' },
  R206G3: { label: 'R01G3', name: 'R206G3' },
  R206G4: { label: 'R01G3', name: 'R206G4' },
  R206G5: { label: 'R01G3', name: 'R206G5' },
  R206G6: { label: 'R01G3', name: 'R206G6' },
  R206G7: { label: 'R01G3', name: 'R206G7' },
  R207G3: { label: 'R01G3', name: 'R207G3' },
  R207G4: { label: 'R01G3', name: 'R207G4' },
  R207G5: { label: 'R01G3', name: 'R207G5' },
  R207G6: { label: 'R01G3', name: 'R207G6' },
  R207G7: { label: 'R01G3', name: 'R207G7' },
  R208G3: { label: 'R01G3', name: 'R208G3' },
  R208G4: { label: 'R01G3', name: 'R208G4' },
  R208G5: { label: 'R01G3', name: 'R208G5' },
  R208G6: { label: 'R01G3', name: 'R208G6' },
  R208G7: { label: 'R01G3', name: 'R208G7' },

};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd9Printing',
  backendName: 'ПечатьПрогнозДод9',
  label: 'Показники бюджету розвитку  (Додаток 9)',
  frontend: 'dp/forecastAdd9Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
