import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { docEditorMenu as BasicMenu } from '../../../components/editorMenu/index';
import BasicEditor, { mapState, getRouterEditor } from '../../editor/basic';

import SelectorField from '../../field/stdSelectorField';
import GetTextField from '../../field/TextInput';
import GeneralTP from './tablepart/apGeneralTP';
import StyledTPPane from '../../../components/tableParts/TPPane';

import { editor as actions } from '../../../actions/index';
import md from '../../../constants/meta/documents/financingApplicationReversal';
import getResizableCP from '../../../components/tableParts/ResizableCP';
import getTPContainer from '../../../components/tableParts/tablePartContainer';
import {
  StyledLabel,
  ContainerFourColumn,
  ContainerTwoColumn,
  SubTitle,
  SubText,
  StyledGreyLabel,
  DivWithPropsLabel,
} from '../../../components/Form/styledForm';
import DateInput from '../../../components/fields/dates';
import Tabs from '../../../components/tabs/Tabs';
import Fields from '../../field';
import CPDocsTP from './tablepart/cpDocs';
import { changeField } from '../../../actions/reportEditor';
import storePathParam from '../../../common/storePathParam';

const RefNumberInput = GetTextField(10);
const NumberInput4 = Fields.GetNumberInput(4, 0, true);

const ContainerThreeColumn = styled(ContainerTwoColumn)`
  grid-template-columns: 19% 19% 58%;
`;
const ContainerThreeColumnColor = styled(ContainerTwoColumn)`
  grid-template-columns: 32% 32% 32%;
  margin-bottom: 0;
  background: #f3f8ff;
  border: 1px solid #c4e5ff;
  padding: 5px;
  border-radius: 4px;
`;

export class FinancingApplicationReversal extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    headerForm: PropTypes.instanceOf(Map),
    sessionOptions: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    headerForm: new Map(),
    sessionOptions: new Map(),
  };

  render() {
    const {
      props: { dispatch, headerForm, sessionOptions },
    } = this;

    const headerUpdater = (...path) => (e, value) => {
      dispatch(actions.changeField(['headerForm', ...path], value));
    };
    return (
      <div>
        <ContainerTwoColumn>
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.budget.label}</StyledLabel>
              <SelectorField
                required
                value={headerForm.get('budget', new Map())}
                modelType="cat"
                modelName="budget"
                onChange={(e, value) => {
                  dispatch(actions.changeField(['headerForm', 'FI'], new Map()));
                  dispatch(actions.changeField(['headerForm', 'budget'], value));
                }}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.budget.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['budget', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
          {sessionOptions.get('is_admin', false) ? (
            <div>
              <StyledLabel>{md.columns.FI.label}</StyledLabel>
              <SelectorField
                required
                value={headerForm.get('FI', new Map())}
                modelType="cat"
                modelName="csu"
                filter={[{ fieldName: 'Бюджет', value: headerForm.get('budget') }]}
                onChange={headerUpdater('FI')}
              />
            </div>
          ) : (
            <StyledGreyLabel>
              {md.columns.FI.label}
              :
              {' '}
              <SubText>
                {' '}
                {headerForm.getIn(['FI', 'repr'], '')}
              </SubText>
            </StyledGreyLabel>
          )}
        </ContainerTwoColumn>
        <ContainerThreeColumn>
          <div>
            <StyledLabel>{md.columns.number.label}</StyledLabel>
            <RefNumberInput
              background
              radius
              value={headerForm.get('number', '')}
              onChange={headerUpdater('number')}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.date.label}</StyledLabel>
            <DateInput value={headerForm.get('date', null)} onChange={headerUpdater('date')} />
          </div>
          <ContainerThreeColumnColor>
            <div>
              <SubTitle>Період відбору документів</SubTitle>
              <StyledLabel>{md.columns.dataStart.label}</StyledLabel>
              <DateInput value={headerForm.get('dataStart', null)} onChange={headerUpdater('dataStart')} />
            </div>
            <div>
              <StyledLabel>{md.columns.dataEnd.label}</StyledLabel>
              <DateInput value={headerForm.get('dataEnd', null)} onChange={headerUpdater('dataEnd')} />
            </div>
            <div>
              <StyledLabel>{md.columns.year.label}</StyledLabel>
              <NumberInput4
                border
                radius
                background
                value={headerForm.get('year', 0)}
                onChange={(e, v) => dispatch(changeField(['headerForm', 'year'], v))}
                disableCalculator
              />
            </div>
          </ContainerThreeColumnColor>
        </ContainerThreeColumn>
        <ContainerFourColumn>
          <div>
            <StyledLabel>{md.columns.CSU.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('CSU', new Map())}
              modelType="cat"
              modelName="csu"
              filter={[{ fieldName: '_Родитель', value: headerForm.get('FI', new Map()) }]}
              onChange={headerUpdater('CSU')}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.fondObject.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('fondObject', new Map())}
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'fondObject'], value));
                dispatch(actions.processServerReq('FOND_ON_CHANGE'));
              }}
              modelType="cat"
              modelName="elementFond"
              filter={[{ fieldName: 'Владелец', value: headerForm.get('FI', new Map()) }]}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.elementKFK.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('elementKFK', new Map())}
              modelType="cat"
              modelName="elementKFK"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'elementKFK'], value));
                dispatch(actions.processServerReq('CHANGE_KFK'));
              }}
              filter={[{
                fieldName: 'Владелец',
                value: headerForm.get('FI', new Map()),
              }]}
            />
          </div>
          <div>
            <StyledLabel>{md.columns.elementKEKV.label}</StyledLabel>
            <Fields.SelectorField
              value={headerForm.get('elementKEKV', new Map())}
              modelType="cat"
              modelName="elementKEKV"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'elementKEKV'], value));
              // dispatch(actions.processServerReq('CHANGE_KEKV_IN_TABLE'));
              }}
              filter={[{
                fieldName: 'Владелец',
                value: headerForm.get('FI', new Map()),
              }]}
            />
          </div>
        </ContainerFourColumn>
      </div>
    );
  }
}

const GeneralTPRenderer = getTPContainer(GeneralTP, getResizableCP(<CPDocsTP tableName="general" />));

export function FinancingApplicationReversalTabs() {
  return (
    <Tabs>
      <DivWithPropsLabel label={md.tables.general.label}>
        <StyledTPPane>
          <GeneralTPRenderer />
        </StyledTPPane>
      </DivWithPropsLabel>
      <DivWithPropsLabel label="" empty disabled />
    </Tabs>
  );
}

const mapStateTP = (store) => ({
  headerForm: store.getIn([storePathParam(store).node, storePathParam(store).id, 'headerForm'], false),
});

const connectedTablesRender = connect(mapStateTP)(FinancingApplicationReversalTabs);

function APMenu(props) {
  return (
    <BasicMenu
      {...props}
    />
  );
}

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'doc',
      name: 'financingApplicationReversal',
    },
    {
      menu: APMenu, // or yourHOC(BasicMenu)
      tables: connectedTablesRender, // Табличные части
      canCollapsed: true,
    },
  ),
  // wasModified,
  // hasLoader,
);

export const StdEditor = enhance(FinancingApplicationReversal);

export default getRouterEditor(md)(StdEditor);
