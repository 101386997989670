import { comparisonTypes, emptyUid } from '../../constants/meta/common';

/**
 * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
 * Она сработает только один раз через N миллисекунд после последнего вызова.
 * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
 * первого запуска.
 * @param {function} fn - Функция которая выполнится через указанное время
 * @param {number} delay - указанное время
 * @return {function}

 */

export const debounce = (fn, delay = 500) => {
  function debounced(...args) {
    clearTimeout(debounced.timer);
    debounced.timer = setTimeout(() => {
      fn(...args);
    }, delay);
  }
  debounced.timer = null;
  return debounced;
};

/**
 * Преобразщовывает дату в строку без учета timezone
 * @param {date} date - Дата
 * @return {string}

 */
export const dateToString = (date) => {
  const Year = `${'0000'.slice(0, 4 - date.getFullYear().toString().length)}${date.getFullYear()}`;
  return `${Year}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * Устанавливаент свойство displayName для компонента, обернутого в HOC
 * @param WrappedComponent
 * @return {*|string}
 */
export const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || WrappedComponent.type || 'Component'
);

/**
 * Дополняеть строку указанным символом слева
 * @param str {string}
 * @param symbol {string}
 * @param length {int}
 * @return {string}
 */
export const padl = (str = '', symbol = '0', length = 10) => symbol.repeat(length).concat(str).slice(-length);

export const downloadFileFromJSON = (jsonData, filename) => {
  const a = document.createElement('a');
  a.href = jsonData;
  a.download = filename;
  const child = document.body.appendChild(a);
  a.click();
  document.body.removeChild(child);
};

export function getRandomID(min = 0, max = Number.MAX_SAFE_INTEGER) {
  const int = Math.floor(Math.random() * (max - min + 1)) + min;
  return int.toString(36);
}

/**
 * Функция ползволяет заворачивать в base64 кирилицу
 * см. https://developer.mozilla.org/en-US/docs/Web/API/btoa
 * @param string
 * @return {string}
 */
export function toBinary(string) {
  const codeUnits = Uint16Array.from(
    { length: string.length },
    (element, index) => string.charCodeAt(index),
  );
  const charCodes = new Uint8Array(codeUnits.buffer);

  let result = '';
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return result;
}

export function translit(str) {
  const ru = ('А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я').split('-');
  const en = ('A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-_-_-Y-y-_-_-E-e-YU-yu-YA-ya').split('-');
 	let res = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0, l = str.length; i < l; i++) {
    const s = str.charAt(i); const
      n = ru.indexOf(s);
    if (n >= 0) { res += en[n]; } else { res += s; }
  }
  return res;
}
/**
 *
 * @param onecFilter [{
 *   fieldName: {string},
 *   comparisonType: {string},
 *   value: {*},
 * }]
 * @return {[]|boolean|*}
 */
export function transformFilter(onecFilter, params) {
  const getSufix = (cType) => {
    if (!cType || cType === comparisonTypes.equal) return '';
    switch (cType) {
      case comparisonTypes.less:
        return '__le';
      case comparisonTypes.lessOrEqual:
        return '__lte';
      case comparisonTypes.greater:
        return '__ge';
      case comparisonTypes.greaterOrEqual:
        return '__gte';
      case comparisonTypes.notEqual:
        return '__neq';
      default:
        throw new Error(`Вид порівняння ${cType} поки що не підтимується`);
    }
  };
  const transformValue = (v) => {
    if (v && typeof v === 'object' && v.id) {
      // eslint-disable-next-line no-underscore-dangle
      if (v._code) return v._code;
      if (v.id !== emptyUid) return v.id;
      return null;
    }
    return v;
  };

  const getValue = (cType, value) => {
    if (!cType || cType === comparisonTypes.equal || cType === comparisonTypes.notEqual) return transformValue(value);
    if ([
      comparisonTypes.less, comparisonTypes.lessOrEqual,
      comparisonTypes.greater, comparisonTypes.greaterOrEqual,
    ].includes(cType)) {
      return transformValue(value);
    }
    throw new Error(`Вид порівняння ${cType} поки що не підтимується`);
  };

  const transformParam = (name, value) => {
    if (name.toLowerCase() === 'надату') {
      const dd = value ? new Date(value) : new Date();
      return { opdate: dd.toISOString().substring(0, 10).replaceAll('-', '') };
    }
    return { [translit(name).toLowerCase()]: transformValue(value) };
  };

  return {
    ...onecFilter.reduce((R, ff) => ({
      ...R,
      [`onec_${translit(ff.fieldName)
        .toLowerCase()}${getSufix(ff.comparisonType)}`]: getValue(ff.comparisonType, ff.value),
    }), {}),
    ...params.reduce(
      (R, pp) => ({ ...R, ...transformParam(pp.name, pp.value) }),
      {},
    ),
  };
}
