import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import FilterItem from './filterItem';
import { comparisonTypes } from '../../../../constants/meta/common';
import { filterValuePropType } from '../../hooks/filter';
// import { StyledSegment, StyledList } from '../StyledComponents';

function FilterView({
  items, selectHandler, deleteHandler, toggleUseHandler,
  onChangeValue, onChangeComparisson, filterChoice, activeFilter,
}) {
  return (
    <div onClick={() => selectHandler(null)} className="flex-grow-1 h-100">
      <Droppable droppableId="DC.Filter">
        {(DropProvided) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
          <ListGroup
            ref={DropProvided.innerRef}
            {...DropProvided.droppableProps}
            className="h-100 overflow-auto border rounded"
            variant="flush"
          >
            {DropProvided.placeholder}
            {items.map(({
              item, id, index, isArray, title, allowedComparations, errored, type,
              // childrenItems,
            }) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(DragProvided) => (
                  <FilterItem
                    ref={DragProvided.innerRef}
                    item={item}
                    isArray={isArray}
                    title={title}
                    allowedComparations={allowedComparations}
                    errored={errored}
                    type={type}
                    id={id}
                    toggleUseHandler={toggleUseHandler}
                    onClickHandler={selectHandler}
                    onDoubleClickHandler={deleteHandler}
                    dragHandleProps={DragProvided.dragHandleProps}
                    draggableProps={DragProvided.draggableProps}
                    filterChoice={filterChoice}
                    isActive={activeFilter === id}
                    onChangeValue={onChangeValue}
                    onChangeComparisson={onChangeComparisson}
                  />
                )}
              </Draggable>

            ))}
          </ListGroup>
        )}
      </Droppable>
    </div>
  );
}

FilterView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      Use: PropTypes.bool,
      ComparisonType: PropTypes.oneOf(Object.values(comparisonTypes)),
      RightValue: PropTypes.oneOfType([
        filterValuePropType,
        PropTypes.arrayOf(filterValuePropType),
      ]),
    }).isRequired,
    af: PropTypes.shape({}),
    index: PropTypes.number.isRequired,
    isArray: PropTypes.bool,
    title: PropTypes.string.isRequired,
    allowedComparations: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOf(Object.values(comparisonTypes)).isRequired,
      display_name: PropTypes.string.isRequired,
    })).isRequired,
    errored: PropTypes.bool,
    type: PropTypes.shape({
      ModelType: PropTypes.oneOf(['doc', 'cat', 'B', 'N', 'S']),
      ModelName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  })).isRequired,
  selectHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  toggleUseHandler: PropTypes.func.isRequired,
  filterChoice: PropTypes.shape({}), // Параметры выбора
  activeFilter: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  onChangeComparisson: PropTypes.func.isRequired,
};

FilterView.defaultProps = {
  filterChoice: {},
  activeFilter: null,
};

export default memo(FilterView);
