import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasicEditor, { mapState } from '../../reportEditor';
import { changeField } from '../../../actions/reportEditor';
import md from '../../../constants/meta/reports/balans';
import {
  ResultSegment,
  ThreeColumnsGridContainer,
  StyledLabel,
  SevenColumnsGridContainer,
  ContainerDiv,
} from '../../../components/Form/styledForm';
import Fields from '../../field';
import DateInput from '../../../components/fields/dates';
import CheckBoxField from '../../field/CheckBox';
import RadioButton from '../../../components/styledRadiobutton/styledRadiobutton';
import enums from '../../../constants/meta/enums';
import TableEditor from '../../tableEditor';
import ReportContainer from '../reportContainer';
import DateRangeField from '../../../components/fields/DateRangeField';

const SixColumns = styled(SevenColumnsGridContainer)`
  grid-template-columns: 10% 10% 10% 20% 20% 20%;
  grid-column-gap: 2%;
`;
const ReportTypes = {
  plan: { label: 'Розпис', value: 1 },
  update: { label: 'Уточнення', value: 2 },
  all: { label: 'Розпис+уточнення', value: 3 },
};

function ReportEditor({
  dispatch, headerForm, isProcessing, portalOpened, onReportDetails,
}) {
  useEffect(() => {
    dispatch(changeField(['headerForm', 'isNewTypeSign'], true));
    dispatch(changeField(['headerForm', 'isShowCredit'], true));
  }, [dispatch]);

  const resultMountNode = useMemo(() => [`rep/${md.name}/reportEditor`, 'result'], []);
  const SettingsButton = (
    <div>
      <ThreeColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.budget.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('budget', new Map())}
            modelType="cat"
            modelName="budget"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'budget'], v))}
            filter={[
              { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.FO.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('FO', new Map())}
            modelType="cat"
            modelName="csu"
            onChange={(e, v) => {
              dispatch(changeField(['headerForm', 'FO'], v));
            }}
            filter={[
              { fieldName: 'Владелец', value: headerForm.get('budget', new Map()) }]}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('consolidated', false)}
            label={md.columns.consolidated.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'consolidated'], v))}
          />
          <CheckBoxField
            value={headerForm.get('separatedBudget', false)}
            label={md.columns.separatedBudget.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'separatedBudget'], v))}
          />
        </div>
      </ThreeColumnsGridContainer>
      <SixColumns>
        <div>
          <StyledLabel>{md.columns.tYear.label}</StyledLabel>
          <Fields.YearField
            value={headerForm.get('tYear', '')}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'tYear'], v))}
          />
        </div>
        <div>
          <StyledLabel>{md.columns.endDate.label}</StyledLabel>
          <DateInput
            value={headerForm.get('endDate', null)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'endDate'], v))}
          />
        </div>

        <div>
          <CheckBoxField
            value={headerForm.get('isShowCredit', true)}
            label={md.columns.isShowCredit.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'isShowCredit'], v))}
          />
          <CheckBoxField
            value={headerForm.get('byDocuments', false)}
            label={md.columns.byDocuments.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'byDocuments'], v))}
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('approved', false)}
            label={md.columns.approved.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'approved'], v))}
          />
          <CheckBoxField
            value={headerForm.get('budgetInTable', false)}
            label={md.columns.budgetInTable.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'budgetInTable'], v))}
          />

        </div>
      </SixColumns>

      <SixColumns>

        <div>
          <StyledLabel>{md.columns.reportType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('reportType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'reportType'], v))}
            values={Object.values(ReportTypes)}
            name="reportType"
          />
        </div>
        <div>
          <StyledLabel>{md.columns.planType.label}</StyledLabel>
          <RadioButton
            value={headerForm.get('planType', false)}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'planType'], v))}
            values={Object.values(enums.PlaningKind).map((_e) => ({ label: _e.label, value: _e.name }))}
            name="planType"
          />
        </div>
        <div>
          <CheckBoxField
            value={headerForm.get('yearSum', false)}
            label={md.columns.yearSum.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'yearSum'], v))}
          />
          <CheckBoxField
            value={headerForm.get('in1000', false)}
            label={md.columns.in1000.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'in1000'], v))}
          />
          <CheckBoxField
            value={headerForm.get('incDB', false)}
            label={md.columns.incDB.label}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'incDB'], v))}
          />
        </div>
      </SixColumns>
      <ThreeColumnsGridContainer>
        <div>
          <StyledLabel>{md.columns.fund.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('fund', new Map())}
            modelType="cat"
            modelName="fondClassifier"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'fund'], v))}
            canCleared
          />
        </div>
        <div>
          <StyledLabel>{md.columns.elementFnd.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('elementFnd', new Map())}
            modelType="cat"
            modelName="elementFond"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'elementFnd'], v))}
            filter={[{ fieldName: 'Владелец', value: headerForm.get('FO', new Map()) }]}
            canCleared
          />
        </div>
        <div>
          <StyledLabel>{md.columns.signVariant.label}</StyledLabel>
          <Fields.SelectorField
            value={headerForm.get('signVariant', new Map())}
            modelType="cat"
            modelName="signatureVariants"
            onChange={(e, v) => dispatch(changeField(['headerForm', 'signVariant'], v))}
            canCleared
          />
        </div>
      </ThreeColumnsGridContainer>
    </div>
  );

  return (
    <ReportContainer
      isProcessing={isProcessing}
      portalOpened={portalOpened}
      SettingButton={SettingsButton}
      backendName={md.backendName}
    >
      <ContainerDiv>
        <div>
          <StyledLabel>{md.columns.period.label}</StyledLabel>
          <DateRangeField
            value={headerForm.get('period', new Map())}
            onChange={(e, v) => dispatch(changeField(['headerForm', 'period'], v))}
            noBorder
          />
        </div>
      </ContainerDiv>

      <ResultSegment>
        <TableEditor mountNode={resultMountNode} onReportDetails={onReportDetails} />
      </ResultSegment>
    </ReportContainer>
  );
}

ReportEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  headerForm: PropTypes.instanceOf(Map).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  portalOpened: PropTypes.bool,
  onReportDetails: PropTypes.func,
};

ReportEditor.defaultProps = {
  portalOpened: false,
  onReportDetails: null,
};

ReportEditor.displayName = `Report${md.name}Editor`;

const enhance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'rep',
      name: md.name,
    },
    {
      menu: null, // or yourHOC(BasicMenu)
    },
  ),
);

export default enhance(ReportEditor);
