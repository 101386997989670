import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab, Container,
} from 'react-bootstrap';
// import { GeneralTP } from './tabs';
import md from '../../../../constants/meta/documents/legalObligation';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import LowerDocs from './tabs/docs';
import GeneralTP from './tabs/general';

function FinancingTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>

      <Tab title={md.tables.presentedDocs.label} eventKey="presentedDocs">
        <LowerDocs data={data} actions={actions} readOnly={readOnly} />
      </Tab>

      <Tab title="Примітка" eventKey="notes">
        <Container fluid className="mx-1 my-2">
          <EditorControls.TextInput
            label={md.columns.notes.label}
            value={data[md.columns.notes.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.notes.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />

        </Container>
      </Tab>
    </Tabs>
  );
}

FinancingTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default FinancingTabs;
