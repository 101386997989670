import React from 'react';
import PropTypes from 'prop-types';
import {  Col, Container, Row,} from 'react-bootstrap';
import md from '../../../../constants/meta/catalogs/symbolKdb';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';


function SymbolKdb({data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
      <Container fluid>
        <Row>
          <Col>
            <EditorControls.StringInput
                label={md.columns.kdbCode.label}
                value={data[md.columns.kdbCode.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.kdbCode.name]: value,
                })}
                maxLength={8}
                readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.StringInput
                label={md.columns.subAccount.label}
                value={data[md.columns.subAccount.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.subAccount.name]: value,
                })}
                maxLength={4}
                readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.StringInput
                label={md.columns.symbol.label}
                value={data[md.columns.symbol.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.symbol.name]: value,
                })}
                maxLength={3}
                readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={400}
                readOnly={readOnly}
            />
          </Col>
          <Col>
            <EditorControls.StringInput
                label={md.columns.fond.label}
                value={data[md.columns.fond.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.fond.name]: value,
                })}
                maxLength={1}
                readOnly={readOnly}
            />
          </Col>
        </Row>
      </Container>
  );
}

SymbolKdb.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

SymbolKdb.defaultProps = {
  onClose: null,
  onSave: null,
};

export default SymbolKdb;
