import React, { useState } from 'react';
import {
  Container, Row, Col, Tabs, Tab,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { selectionMode } from '../../../../constants/meta/common';
import {
  elementKFK as md,
} from '../../../../constants/meta/catalogs/elementKFK';
import AdditionalAnalitics from '../../../field/addAnalitics2';
import ClassifiersRelation from './relations';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

function TabMenu({
  data,
  actions,
  permissions,
}) {
  const readOnly = !permissions.canChange;
  const [page, setPage] = useState('general');
  return !data[md.columns.isGroup.name] && (
    <Tabs activeKey={page} onSelect={setPage} className="mx-0 border-bottom">
      <Tab eventKey="general" title="Основні реквізити програми">
        <Container fluid className="border border-top-0 mt-2 pt-2">
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.parent.label}
                value={data[md.columns.parent.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.parent.name]: value,
                })}
                modelName="elementKFK"
                modelType="cat"
                filter={[{
                  fieldName: md.columns.owner.name,
                  value: data[md.columns.owner.name],
                }]}
                choiceSettings={selectionMode.folders}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.Steward.label}
                value={data[md.columns.Steward.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Steward.name]: value,
                })}
                modelName="csu"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.GroundLaw.label}
                value={data[md.columns.GroundLaw.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.GroundLaw.name]: value,
                })}
                modelName="groundLaw"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.Target.label}
                value={data[md.columns.Target.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Target.name]: value,
                })}
                modelName="target"
                modelType="cat"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId="Inactive"
                label={md.columns.Inactive.label}
                value={data[md.columns.Inactive.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.Inactive.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab eventKey="AdditionalAnalitics" title="Додаткова аналітика" disabled={data.id === 'create'}>
        <Container fluid className="border border-top-0 mt-2 pt-2">
          <AdditionalAnalitics
            data={data[md.columns.addAnalityces.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.addAnalityces.name]: value,
            })}
          />
        </Container>
      </Tab>
      <Tab eventKey="ClassifiersRelation" title="Відповідність до класифікаторів" disabled={data.id === 'create'}>
        <Container fluid className="border border-top-0 mt-2 pt-2">
          <ClassifiersRelation elementId={data.id} />
        </Container>
      </Tab>
    </Tabs>
  );
}

TabMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TabMenu;
