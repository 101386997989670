import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormCheck, FormGroup,
} from 'react-bootstrap';
import classNames from 'classnames';

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const Radio = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  required, errors, errorAsTooltip,
  className, inline,
}, ref) => {
  const groupName = useMemo(
    () => Math.random().toString(36).substring(7),
    [],
  );
  const errored = !!errors && !!errors.length;
  return (
    <>
      <FormGroup className={classNames(className, { 'is-invalid': errored, radio: true })} id={id} ref={ref}>
        {values.map((vv) => (
          <FormCheck
            name={groupName}
            type="radio"
            inline={inline}
            key={vv.value}
            checked={vv.value === value}
            disabled={vv.disabled || disabled}
            readOnly={readOnly}
            onClick={(e) => onChange(e, vv.value)}
            label={vv.display_name}
            isInvalid={errored}
            required={required}
            onChange={() => false}
          />
        ))}
      </FormGroup>
      {errors && (
      <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
        {errors.join(', ')}
      </Form.Control.Feedback>
      )}
    </>
  );
});

Radio.propTypes = {
  id: PropTypes.string,
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  errorAsTooltip: PropTypes.bool,
  inline: PropTypes.bool,
};

Radio.defaultProps = {
  id: '',
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  required: true,
  errors: null,
  className: null,
  errorAsTooltip: false,
  inline: false,
};

export default Radio;
