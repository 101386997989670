import PropTypes from 'prop-types';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  budget: foreignPropType,
  sum: PropTypes.number,
  sumT: PropTypes.number,
  sumRasp: PropTypes.number,
  sumWork: PropTypes.number,
  prWork: PropTypes.number,
  sumCom: PropTypes.number,
  prCom: PropTypes.number,
  sumMed: PropTypes.number,
  prMed: PropTypes.number,
  sumProd: PropTypes.number,
  prProd: PropTypes.number,
  sumP: PropTypes.number,
  prP: PropTypes.number,
  sumW: PropTypes.number,
  prW: PropTypes.number,
  sumOther: PropTypes.number,
  prOther: PropTypes.number,
  sumRaspSubv: PropTypes.number,
  sumUnused: PropTypes.number,
  prRaspAll: PropTypes.number,
  prRasp: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));

export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
