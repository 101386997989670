import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  subAccount: {
    label: 'Субрахунок',
    name: 'sub_account',
    visible: true,
  },
  kdbCode: {
    label: 'КДБ',
    name: 'kdb_code',
    visible: true,
  },
  symbol: {
    label: 'Символ звітності',
    name: 'symbol',
    visible: true,
  },
  name: {
    label: 'Назва',
    name: 'name',
    visible: true,
  },
  fond: {
    label: 'Ознака фонду',
    name: 'fond',
    visible: true,
  },
}

export const symbolKdb = {
  label: 'Відповідності символу звітності коду класифікації доходів бюджетуі',
  name: 'symbolKdb',
  backendName: 'symbolKdb',
  defaultOrder,
  columns,
  frontend: 'cat/symbolKdb',
  listColumns: [
      ...new Set([
          // ...reqListerColumns,
          'symbol',
          'subAccount',
      ]),
  ],
  minfinOnly: true,
};

export default symbolKdb;
