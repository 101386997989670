import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight, totals,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-center text-center"
      >
      &nbsp;
        <Row sm={12} noGutters className="text-center">
          <TableHeaderCell
            className="text-center col-lg-3 col-md-4"
            active={activeCol === 'disposer'}
            highlighted={highlights.includes('disposer')}
            onToggleHighlight={() => onToggleHighlight('disposer')}
          >
            {(fields.disposer === undefined) ? '' : fields.disposer.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left col-lg-3 col-md-4"
            active={activeCol === 'bfn_amount'}
            highlighted={highlights.includes('bfn_amount')}
            onToggleHighlight={() => onToggleHighlight('bfn_amount')}
            title={`Всього:${totals}`}
          >
            {(fields.bfn_amount === undefined) ? '' : fields.bfn_amount.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center col-lg-2 col-md-3"
            active={activeCol === 'salary'}
            highlighted={highlights.includes('salary')}
            onToggleHighlight={() => onToggleHighlight('salary')}
            title={`з/п з нарахуваннями:${totals.salary}`}
          >
            {(fields.salary === undefined) ? 'з/п з нарахуваннями' : fields.salary.label}
          </TableHeaderCell>

          <TableHeaderCell
            className="text-center text-xl-left col-lg-1 col-md-3"
            active={activeCol === 'medicament'}
            highlighted={highlights.includes('medicament')}
            onToggleHighlight={() => onToggleHighlight('medicament')}
            title={`Медикаменти:${totals.medicament}`}
          >
            {(fields.medicament === undefined) ? 'Медикаменти' : fields.medicament.label}
          </TableHeaderCell>

          <TableHeaderCell
            className="text-center text-xl-left  col-lg-1 col-md-3"
            active={activeCol === 'current_expenses'}
            highlighted={highlights.includes('current_expenses')}
            onToggleHighlight={() => onToggleHighlight('current_expenses')}
            title={`Поточні видатки:${totals.current_expenses}`}
          >
            {(fields.current_expenses === undefined) ? '' : fields.current_expenses.label}
          </TableHeaderCell>

          <TableHeaderCell
            className="text-center text-xl-left col-lg-2 col-md-3"
            active={activeCol === 'utilities'}
            highlighted={highlights.includes('utilities')}
            onToggleHighlight={() => onToggleHighlight('utilities')}
            title={`Комунальні послуги:${totals.utilities}`}
          >
            {(fields.utilities === undefined) ? 'Комунальні послуги' : fields.utilities.label}
          </TableHeaderCell>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    bfn_amount: PropTypes.number,
    salary: PropTypes.number,
    medicament: PropTypes.number,
    current_expenses: PropTypes.number,
    utilities: PropTypes.number,
  }),
};

Header.defaultProps = {
  activeCol: null,
  totals: {
    bfn_amount: 0,
    salary: 0,
    medicament: 0,
    current_expenses: 0,
    utilities: 0,
  },
};

export default Header;
