import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getRouterEditor } from '../../../editor/basic2';
import md from '../../../../constants/meta/documents/changeToAssignPlanRev';
import ChangeToAssignPlanRev from './editor';
import APMenu from './menu';
import ChangeToAssignPlanRevTabs from './tables';
import { modelType, modelName } from '../def';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import EditorHeader from '../../../newEditor/header';
import BudgetFOUserEditorShower from '../../_common/BudgetFOUserEditorShower';

/**
 * Функция, которая возвращает readOnly документа
 * @param data - Данные объекта
 * @returns {boolean}
 */
// function isReadOnly(data) {
//   return !!data[md.columns.isApproved.name];
// }

function ChangeToAssignPlanRevEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
    // readOnlyGetter: isReadOnly,
  });

  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <APMenu
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      />
      <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onReload}>

        <Card.Header>
          <ChangeToAssignPlanRev data={data} actions={actions} permissions={permissions} />
        </Card.Header>
        <Card.Body className="p-0">
          <ChangeToAssignPlanRevTabs data={data} actions={actions} permissions={permissions} />
        </Card.Body>
        <Card.Footer>
          <BudgetFOUserEditorShower data={data} />
        </Card.Footer>
      </EditorWrapper>
    </>
  );
}

ChangeToAssignPlanRevEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ChangeToAssignPlanRevEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { ChangeToAssignPlanRevEditor as StdEditor };

export default getRouterEditor(md)(ChangeToAssignPlanRevEditor);
