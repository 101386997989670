import { useCallback, useContext, useRef } from 'react';
import api from '../../../api/req';
import checkTask from '../../../api/checktask';
import { AppContext } from '../../../providers/authProvider';

// eslint-disable-next-line import/prefer-default-export
export const useGenerateReport = ({
  renderReportUrl, reportId,
  groups, selections, orders, filters, columns, param,
  onStart, onEnd, onDone, onError, renderer = 'json',
}) => {
  const { auth } = useContext(AppContext);
  const taskId = useRef(null);
  return {
    onGenerateReport: useCallback(
      () => {
        const loader = async () => {
          const fields = selections;
          const params = {
            report: reportId,
            render: renderer,
            details: false,
            params: param,
            options: {
              group: groups,
              column: columns,
              order: orders,
              filter: filters,
              fields,
            },
          };
          const r = await api.post(renderReportUrl, auth, params, false);

          if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
          const d = await r.json();
          taskId.current = d.task_id;
          return d.task_id;
        };
        onStart();
        loader()
          .then((d) => {
            const onTaskDone = (res) => {
              if (res.result.errors) {
                onError(res.result.errors.join(', '));
              } else {
                onDone(res.result);
              }
              if (res.status !== 'PENDING') onEnd();
            };
            const onTaskError = (e) => {
              onError(e ? e.message : 'Скасовано');
              onEnd();
            };
            checkTask(d, auth, onTaskDone, onTaskError);
          })
          .catch((e) => {
            onError(e.message);
            onEnd();
          });
      },
      [onStart, selections, reportId, renderer, param, groups, columns, orders, filters, renderReportUrl, auth, onEnd, onError, onDone],
    ),
    onAbortReport: useCallback(
      () => {
        if (taskId.current) {
          api.delete(`${renderReportUrl}?task_id=${taskId.current}`, auth);
        }
      },
      [auth, renderReportUrl],
    ),
  };
};
