import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/localDebt';

const meta = md.tables.TABL4;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={9} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG1S'}
              highlighted={highlights.includes('T4RXXXXG1S')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG1S')}
            >
              {meta.columns.T4RXXXXG1S.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG3'}
              highlighted={highlights.includes('T4RXXXXG3')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG3')}
            >
              {meta.columns.T4RXXXXG3.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG4'}
              highlighted={highlights.includes('T4RXXXXG4')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG4')}
            >
              {meta.columns.T4RXXXXG4.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG5'}
              highlighted={highlights.includes('T4RXXXXG5')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG5')}
            >
              {meta.columns.T4RXXXXG5.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG6'}
              highlighted={highlights.includes('T4RXXXXG6')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG6')}
            >
              {meta.columns.T4RXXXXG6.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG7'}
              highlighted={highlights.includes('T4RXXXXG7')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG7')}
            >
              {meta.columns.T4RXXXXG7.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG8'}
              highlighted={highlights.includes('T4RXXXXG8')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG8')}
            >
              {meta.columns.T4RXXXXG8.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG9'}
              highlighted={highlights.includes('T4RXXXXG9')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG9')}
            >
              {meta.columns.T4RXXXXG9.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG10'}
              highlighted={highlights.includes('T4RXXXXG10')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG10')}
            >
              {meta.columns.T4RXXXXG10.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'T4RXXXXG11'}
              highlighted={highlights.includes('T4RXXXXG11')}
              onToggleHighlght={() => onToggleHighlght('T4RXXXXG11')}
            >
              {meta.columns.T4RXXXXG11.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
};

export default memo(TPHeader);
