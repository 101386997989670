import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function TableRowCell({
  column, highlighted, children, className,
}) {
  const clsName = useMemo(
    () => `${className} ${highlighted ? 'bg-warning' : ''}`,
    [className, highlighted],
  );
  return (
    <div data-col={column} className={clsName}>
      {children}
    </div>
  );
}

TableRowCell.propTypes = {
  column: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
};

TableRowCell.defaultProps = {
  highlighted: false,
  className: '',
};

export default TableRowCell;
