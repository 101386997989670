/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';

function RowSet({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'paymentorderrow_set', readOnlyFields, errors, fields,
  });
  // Необходимые данные из шапки документа
  const {
    authority,
  } = data;

  const totals = useMemo(
    () => ({
      amount_total: tableData.reduce((R, r) => R + (r.amount_total || 0), 0),
      prepayment: tableData.reduce((R, r) => R + (r.prepayment || 0), 0),
    }),
    [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
             //
              authority={authority}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
}

RowSet.propTypes = {
  data: PropTypes.shape({
    onlyYear: PropTypes.bool,
    vt4br: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: foreignPropType,
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    showKDBKFB: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    allocationplanrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

RowSet.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default RowSet;
