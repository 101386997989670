import React from 'react';

import GridTemplate from './GridTemplate';
import { DivHeader } from '../../../../../components/styledTable/styledTable';
import md from '../../../../../constants/meta';

const coldef = md.dp.exportVDBFKiev.tables.TVFonds.columns;

function HeaderComponent() {
  return (
    <GridTemplate isHeader>
      <DivHeader areaName="selected">{coldef.selected.label}</DivHeader>
      <DivHeader areaName="fondPresent">
        {coldef.fondPresent.label}
        {' '}
      </DivHeader>
    </GridTemplate>
  );
}

export default HeaderComponent;
