import Styled from 'styled-components';
import { CommandPanelButtonText } from '../../../../../components/Form/styledForm';
import { RemoveIcon } from '../../../../../assets/icons';
import { tableInside } from '../../../../../constants/globalStyle';

export const StyledDiv = Styled.div`
  width: 60%;
`;

export const StyledRepr = Styled.div`
  padding: 4px 6px;
  margin: 2px;
  background: white;
  border-radius: 4px;
  display: inline-block;
  ${tableInside};
`;
export const StyledItem = Styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRemoveIcon = Styled(RemoveIcon)`
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(34,36,38,.15);
  &:hover {
    background: #AAC6E6;  
  }
`;

export const StyledAddButton = Styled(CommandPanelButtonText)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    &>span{
      margin-left: 5px;
      color: #4281C9;
    };
}`;
