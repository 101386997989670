import React, {
  useEffect,
  useState,
  useContext,
  useCallback, useLayoutEffect, useRef,
} from 'react';
import {
  DropdownButton, Dropdown, Alert, Collapse, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import api from '../../../api/req';
import EndUser from './eusign';
import { Loader } from '../../../components/bootStrap';
import { EditorStingInput } from '../../basicEditor/editorControls';
import { LogicaContext } from '../../../providers';
import { AppContext } from '../../../providers/authProvider';

export function uint8array(string) {
  const byteArray = new Uint8Array(string.length * 2);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < string.length; i++) {
    byteArray[i * 2] = string.charCodeAt(i); // & 0xff;
    // eslint-disable-next-line no-bitwise
    byteArray[i * 2 + 1] = string.charCodeAt(i) >> 8; // & 0xff;
  }
  return byteArray;
  // return Buffer.from(string, 'utf16le');
}

function LogcaLogin({ onSuccess, getUserData }) {
  const { widgetURI, onLogin } = useContext(LogicaContext);
  const [euSign, setEUSign] = useState(null);
  const [showWidget, setShowWidget] = useState(true);
  const parentRef = useRef();
  const widgetRef = useRef();

  useLayoutEffect(
    () => {
      setEUSign(new EndUser(
        parentRef,
        widgetRef,
        widgetURI,
        EndUser.FormType.ReadPKey,
      ));
    },
    [widgetURI],
  );

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const { auth } = useContext(AppContext);

  const loginHandler = useCallback(
    async (userData = null) => {
      const getSignString = async () => {
        const response = !getUserData ? await api.get('/api/logica/login/', auth)
          : await api.get$('/api/auth/check-logica/', () => null);
        if (!response.ok) throw new Error(`${response.status} ${response.statusText}`);
        const { stringToSign, requestId } = await response.json();
        return { stringToSign, requestId };
      };

      const login = async ({ signed, requestId }) => {
        const response = !getUserData ? await api.post$('/api/logica/login/', auth, { signBase64: signed, requestId })
          : await api.post$('/api/auth/check-logica/', () => null, { signBase64: signed, requestId });
        if (!response.ok) throw new Error(`${response.status} ${response.statusText}`);
        return response.json();
      };

      const { stringToSign, requestId } = await getSignString();
      const signed = await euSign.SignData(
        uint8array(stringToSign),
        false,
        true,
        EndUser.SignAlgo.DSTU4145WithGOST34311,
        null,
        EndUser.SignType.CAdES_X_Long,
      );
      const loginData = await login({ signed, requestId });
      if (!loginData.success) {
        throw new Error(loginData.errorMessage);
      }

      if (getUserData) {
        getUserData(
          {
            logikaData: loginData,
            keyData: userData,
          },
        );
      }

      return loginData;
    },
    [auth, euSign, getUserData],
  );

  const [lgt, setLgt] = useState('');

  useEffect(
    () => {
      // Створення об'єкту типу endUser для взаємодії з iframe,
      // який завантажує сторінку SignWidget
      if (euSign) {
        euSign
          .ReadPrivateKey()
          .then((userData) => {
            setShowWidget(false);
            setLoading(true);
            setErr(null);
            return loginHandler(userData);
          })
          .then((loginData) => {
            onLogin(loginData);
            if (onSuccess) onSuccess();
          })
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [euSign, getUserData, loginHandler, onLogin, onSuccess],
  );
  return (
    <div className="h-100 w-100 d-flex flex-column">
      {/* <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} /> */}
      {loading && (
        <Loader text="Завантаження" />
      )}
      {err && (
        <Alert variant="danger">
          <Alert.Heading>
            При авторизації виникла помилка:
          </Alert.Heading>
          {err}
        </Alert>
      )}
      <Collapse in={showWidget}>
        <div className="flex-fill d-flex flex-column">
          <div ref={parentRef} className="flex-fill" />
          {process.env.NODE_ENV === 'development' && (
            <div>
              <EditorStingInput
                label="Logika ticket"
                maxLength={36}
                description="Це поле доступно тільки в режимі розробки."
                value={lgt}
                onChange={(e, v) => setLgt(v)}
                append={(
                  <Button
                    variant="outline-secondary"
                    disabled={lgt.length !== 36}
                    onClick={() => {
                      onLogin({
                        ticket: lgt,
                        organizationId: '12345678',
                        organizationName: 'CIAT developer company',
                        subject: 'CIAT developer',
                      });
                      if (onSuccess) onSuccess();
                    }}
                  >
                    Login
                  </Button>
                )}
              />
            </div>
          )}
        </div>
      </Collapse>
      <div id="certificate-message" className="d-none">
        {/* TODO: Спросить надо ли это */}
        <DropdownButton id="dropdown-basic-button" title="Потрібні сертифікати? Тисніть тут" variant="outline-secondary">
          <Dropdown.Item href="http://acsk.treasury.gov.ua/poshuk_sertificativ/" target="_blank">
            Сертифікати сформовані до 31.01.2020
            року
          </Dropdown.Item>
          <Dropdown.Item href="https://ca.treasury.gov.ua/Content/xPages/Certificates/FindCertificate.aspx" target="_blank">
            Сертифікати сформовані після 31.01.2020
            року
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
}

LogcaLogin.propTypes = {
  onSuccess: PropTypes.func,
  getUserData: PropTypes.func,
};

LogcaLogin.defaultProps = {
  onSuccess: null,
  getUserData: null,
};

export default LogcaLogin;
// Примеры ответов отлогики:
// Успешная авторищация
// const succesAuth = {
//   errorCode: '',
//   errorDescription: '',
//   errorMessage: '',
// eslint-disable-next-line max-len
//   issuer: 'O=АКЦІОНЕРНЕ ТОВАРИСТВО КОМЕРЦІЙНИЙ БАНК «ПРИВАТБАНК»;OU=АЦСК;CN=АЦСК АТ КБ «ПРИВАТБАНК»;Serial=UA-14360570-2022;C=UA;L=Київ;OI=NTRUA-14360570',
//   organizationId: '2912011539',
//   organizationName: 'ФОП ЯКОВЕНКО РОМАН ОЛЕКСАНДРОВИЧ',
//   position: 'КЕРІВНИК',
//   registrationNeeded: true,
//   serial: '248197DDFAB977E5040000004795E8005045D603',
//   subject: 'ЯКОВЕНКО РОМАН ОЛЕКСАНДРОВИЧ',
//   success: true,
//   ticket: 'B1E1B3D1-CAD8-4AD2-B1E3-A997D16BF797',
// };
// Ошибка авторизации:
// {
//   errorCode: "EMPTY_REQUEST_CODE",
//   errorDescription: "",
//   errorMessage: "Не задано ID запиту",
//   issuer: null,
//   organizationId: null,
//   organizationName: null,
//   position: null,
//   registrationNeeded: false,
//   serial: null,
//   subject: null,
//   success: false,
//   ticket: null,
// }
