import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import md from '../../../../constants/meta/documents/treasuryStatementFinObligation';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { referencePropType } from '../../../newEditor/propTypes';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';

function TreasuryStatementLegalObligation({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;
  const sessionOptions = useSelector(soSelector);
  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row className="align-items-end">
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => {
              actions.onChange({
                [md.columns.date.name]: value,
              });
            }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
}

TreasuryStatementLegalObligation.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.CodeBudget.name]: PropTypes.number,
    [md.columns.Budget.name]: referencePropType,
    [md.columns.Author.name]: referencePropType,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TreasuryStatementLegalObligation;
