import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { CiatAppContext } from '../../../../providers';
import api from '../../../../api/req';
import checkTask from '../../../../api/checktask';

const settings = {
  revenues: { label: 'Доходи', important: true, color: 'text-success' },
  expenses: { label: 'Видатки', important: true, color: 'text-warning' },
  deficit: { label: 'Профіцит(+) Дефіцит(-)', important: true, color: 'text-success' },
};

const chapters = {
  zf: { key: 'zf', label: 'Загальний фонд (тис. грн.)' },
  total: { key: 'total', label: 'Разом (тис. грн.)' },
  sf: { key: 'sf', label: 'Спеціальний фонд (тис. грн.)' },
};

const transformData = (data) => {
  const transformedData = Object.keys(chapters).reduce((result, chapterKey) => ({
    ...result,
    [chapterKey]: {
      repr: chapters[chapterKey].label,
      fields: Object.keys(settings).map((settingKey) => ({
        repr: settings[settingKey].label,
        color: settings[settingKey].color,
        amount: data[settingKey][chapterKey],
        important: settings[settingKey].important,
      })),
    },
  }), {});

  transformedData.sf.rows = data.rows.map((row) => ({
    repr: row.repr,
    amount: row.sf,
  }));

  transformedData.zf.rows = data.rows.map((row) => ({
    repr: row.repr,
    amount: row.zf,
  }));

  transformedData.total.rows = data.rows.map((row) => ({
    repr: row.repr,
    amount: row.total,
  }));

  return transformedData;
};

const useFetchAddition6Data = ({ cDt }) => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [totalValues, setTotalValues] = useState(null);
  const { auth } = useContext(CiatAppContext);

  const fetchAllData = useCallback(() => {
    const loadBudgetProject = async () => {
      const r = await api.get(`/api/dashboards/total_values/?date=${cDt}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      const data = await r.json();
      return checkTask(data.task_id, auth);
    };

    setErr(null);
    setIsLoading(true);

    Promise.all([loadBudgetProject()])
      .then(([totalValuesResult]) => {
        const transformedData = transformData(totalValuesResult.result);
        setTotalValues(transformedData);
      })
      .catch((error) => setErr(error.message))
      .finally(() => setIsLoading(false));
  }, [auth, cDt]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return {
    isLoading, err, totalValues,
  };
};

export default useFetchAddition6Data;
