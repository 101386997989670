/* eslint-disable react/no-array-index-key */
import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

const SUMS = [
  'start_y',
  'finish_y',
  'middl_y_pl',
];
function NetCategoryRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const startYProps = tableHooks.useNumberInputProps('start_y', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const finishYProps = tableHooks.useNumberInputProps('finish_y', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const middlYPlProps = tableHooks.useNumberInputProps('middl_y_pl', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const middlYExProps = tableHooks.useNumberInputProps('middl_y_ex', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProps = useMemo(
    () => [
      startYProps, finishYProps, middlYPlProps, middlYExProps,
    ],
    [finishYProps, middlYExProps, middlYPlProps, startYProps],
  );

  return (
    <>
      <Col sm={8}>
        <TabelRowCell column="code" highlighted={highlights.includes('code')} className="d-flex h-100">
          <div className="h-100 d-flex flex-column mx-2 gap-1">
            <div className="rounded border-primary text-primary px-2 text-center" title="КБП">{row.id_tpkvk}</div>
            <div className="rounded bg-primary text-white px-2 text-center">{row.id_catp}</div>
            <div className="rounded bg-success text-white font-weight-bold px-2 text-center">{row.code}</div>
          </div>
          <span>
            {row.sign_name}
            {row.id_finunit && (
              <i className="fst-">
                ,
                {row.id_finunit}
              </i>
            )}

          </span>
        </TabelRowCell>
      </Col>
      {[...Array(2)].map((c, colN) => (
        <Col sm={2} key={colN}>
          <Row noGutters>
            {[...Array(colN === 0 ? 2 : 1)].map((r, rowN) => (
              <Col sm={12} key={rowN}>
                <TabelRowCell
                  column={SUMS[colN * 2 + rowN]}
                  highlighted={highlights.includes(SUMS[colN * 2 + rowN])}
                >
                  {row.properties.substring(colN * 2 + rowN, colN * 2 + rowN + 1) === '0' && (
                  <TableControls.NumberInput
                    {...sumProps[colN * 2 + rowN]}
                  />
                  )}
                  {row.properties.substring(colN * 2 + rowN, colN * 2 + rowN + 1) === '1' && (
                  <div className="form-control text-center bg-light">
                    X
                  </div>
                  )}
                  {row.properties.substring(colN * 2 + rowN, colN * 2 + rowN + 1) === '2' && (
                  <div className="form-control text-right bg-light">
                    {(row[SUMS[colN * 2 + rowN]] || 0).toLocaleString('uk', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  </div>
                  )}
                </TabelRowCell>
              </Col>
            ))}
          </Row>
        </Col>

      ))}
    </>
  );
}

NetCategoryRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

NetCategoryRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(NetCategoryRow);
