import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchButton = styled(InputGroup.Text)`
    background: var(--backgroundButton)!important;
    color: var(--colorTextButton)!important;
    cursor: pointer!important;
        &:hover {
            background: var(--hoverButton)!important;
        }
`;
const SearchFormControl = styled(FormControl)`
  min-width: 6rem!important;
`;

function SearchInput({
  value, onChange, size, disabled,
}) {
  return (
    <InputGroup className="flex-nowrap">
      <SearchFormControl
        placeholder="Пошук"
        value={value}
        onChange={(e) => onChange(e, e.target.value)}
        size={size}
        disabled={disabled}
      />
      <SearchButton onClick={(e) => onChange(e, '')}>
        <FontAwesomeIcon icon={faTimes} size="sm" />
      </SearchButton>
    </InputGroup>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  disabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  value: '',
  size: 'sm',
  disabled: false,
};

export default SearchInput;
