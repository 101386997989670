import React, {
  memo, useContext,
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup, Dropdown, Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import UnknowPrintForm from './unknowPrintForm';
import { CPButton } from '../../../components/bootStrap/buttons';
import { WinManagerContext } from '../../../providers';

const StyledImg = styled.img`
  height: 1em;
  opacity: 0.5;
`;
const StyledCuurrentImg = styled(StyledImg)`
  //filter: invert();
`;

const StyledDDItem = styled(Dropdown.Item)`
  &.active>img, &:focus>img, &:focus>img   {
    filter: invert();
  }
`;

function PrintFormButton({
  printForms, instanceId, disabled, showContent, instanceRepr,
}) {
  const { currentContainer } = useContext(WinManagerContext);
  const modalContainer = useRef(currentContainer);
  const [printFormComponents, setPrintFormComponents] = useState({});
  useEffect(
    () => {
      // eslint-disable-next-line import/no-cycle
      import('../../printForms').then((c) => {
        setPrintFormComponents(c.default);
      });
    },
    [],
  );

  const [opened, setOpened] = useState(false);
  const [currentForm, setCurrentForm] = useState(printForms.length ? printForms[0].url : null);

  const { currentName, currentDescription, currentIcon } = useMemo(
    () => printForms.filter((p) => p.url === currentForm).reduce((R, r) => ({
      currentName: r.name,
      currentDescription: r.description,
      currentIcon: r.icon,
    }), {}),
    [currentForm, printForms],
  );

  const CurrentComponent = useMemo(
    () => {
      if (currentForm in printFormComponents) return printFormComponents[currentForm].component;
      return UnknowPrintForm;
    },
    [currentForm, printFormComponents],
  );
  const printUnavailable = !instanceId || instanceId === 'create' || disabled;
  return (
    <>
      { printForms.length === 1 ? (
        <CPButton
          title={currentDescription}
          disabled={printUnavailable}
          onClick={() => setOpened(true)}
          className="d-flex align-items-center text-nowrap"
          variant="falcon-info"
          icon={currentIcon ? (
            <StyledCuurrentImg src={currentIcon} alt={currentName} />
          ) : faPrint}
          content={showContent ? currentName : ''}
        />
      ) : (
        <Dropdown
          as={ButtonGroup}
          onSelect={(eventKey) => {
            setOpened(true);
            setCurrentForm(eventKey);
          }}
          size="sm"
        >
          <Button
            title={currentDescription}
            disabled={printUnavailable}
            onClick={() => setOpened(true)}
            className="d-flex align-items-center text-nowrap"
            variant="falcon-info"
          >
            {currentIcon && (
              <StyledCuurrentImg src={currentIcon} alt={currentName} className="me-2" />
            )}
            {showContent && currentName}
          </Button>
          <Dropdown.Toggle split disabled={printUnavailable || printForms.length < 2} variant="falcon-info" />

          <Dropdown.Menu>
            {printForms.map((dm) => (
              <StyledDDItem
                key={dm.url}
                active={dm.url === currentForm}
                title={dm.description}
                eventKey={dm.url}
              >
                {dm.icon && (
                  <StyledImg src={dm.icon} alt={dm.name} className="me-2" />
                )}
                {dm.name}
              </StyledDDItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        fullscreen
        container={modalContainer.current}
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {instanceId && (
            <CurrentComponent
              instanceId={instanceId}
              url={currentForm}
              instanceRepr={instanceRepr}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

PrintFormButton.propTypes = {
  printForms: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
  })).isRequired,
  instanceId: PropTypes.string,
  instanceRepr: PropTypes.string,
  disabled: PropTypes.bool,
  showContent: PropTypes.bool,
};

PrintFormButton.defaultProps = {
  instanceId: null,
  instanceRepr: null,
  disabled: false,
  showContent: true,
};

export default memo(PrintFormButton);
