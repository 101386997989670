import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import enums from '../../../../constants/meta/enums';
import md from '../../../../constants/meta/documents/changeToAssignPlanRev';
import { soSelector } from '../../_common/selectors';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const planingKindsOptions = Object.values(enums.PlaningKind).map(({ name, label }) => ({
  display_name: label,
  value: name,
}));

function ChangeToAssignPlanRev({ data, actions, permissions }) {
  const sessionOptions = useSelector(soSelector);

  const FI = data[md.columns.FI.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const readOnly = !permissions.canChange;

  return (
    <EditorCollapse>
      {sessionOptions.get('is_admin', false) && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
              [md.columns.year.name]: new Date(value).getFullYear(),
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.YearInput
            label={md.columns.year.label}
            value={data[md.columns.year.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.year.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.numberRegistryFI.label}
            value={data[md.columns.numberRegistryFI.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.numberRegistryFI.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.implementer.label}
            value={data[md.columns.implementer.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.implementer.name]: value,
            })}
            modelType="cat"
            modelName="implementer"
            filter={FiOwnerFilter}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.planingMode.label}
            value={data[md.columns.planingMode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.planingMode.name]: value,
            })}
            values={planingKindsOptions}
            readOnly={readOnly}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.fondObject.label}
                value={data[md.columns.fondObject.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.fondObject.name]: value,
                  });
                  actions.onSR('FOND_ON_CHANGE');
                }}
                modelType="cat"
                modelName="elementFond"
                filter={FiOwnerFilter}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.templateBaseDoc.label}
                value={data[md.columns.templateBaseDoc.name]}
                onChange={async (e, value) => {
                  await actions.onChange({
                    [md.columns.templateBaseDoc.name]: value,
                  });
                  actions.onSR('TEMPLATE_BASED_ON_CHANGE');
                }}
                modelType="cat"
                modelName="templateBaseDoc"
                filter={FiOwnerFilter}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
        <Col className="align-self-end">
          <EditorControls.StringInput
            label={md.columns.refReason.label}
            value={data[md.columns.refReason.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.refReason.name]: value,
            })}
            readOnly={readOnly}
            style={{ alignSelf: 'end' }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`internal-${data.id}`}
            label={md.columns.internal.label}
            value={data[md.columns.internal.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.internal.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        {data[md.columns.thisIsSecialFond.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`onlyYear-${data.id}`}
              label={md.columns.onlyYear.label}
              value={data[md.columns.onlyYear.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.onlyYear.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
        {data[md.columns.thisIsSecialFond.name] && (
          <Col>
            <EditorControls.CheckboxInput
              controlId={`includeBudgetAmplification-${data.id}`}
              label={md.columns.includeBudgetAmplification.label}
              value={data[md.columns.includeBudgetAmplification.name]}
              onChange={(e, value) => actions.onChange({
                [md.columns.includeBudgetAmplification.name]: value,
              })}
              readOnly={readOnly}
            />
          </Col>
        )}
      </Row>
    </EditorCollapse>
  );
}

ChangeToAssignPlanRev.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChangeToAssignPlanRev;
