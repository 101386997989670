import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ContainerTable, HeaderItem } from '../../../../basicEditor/stdFilters/style';
import UserShow from '../../../../../components/Controls/user_show';
import { EditorControls } from '../../../../basicEditor/editorControls';

function DocList({
  docs, onToggleDoc, onSelectDoc, activeDoc,
}) {
  return (
    <ContainerTable>
      <ListGroup>
        <HeaderItem>
          Знайдені документи
        </HeaderItem>
        {docs.map((doc) => (
          <ListGroup.Item
            key={doc.id}
            active={false && doc.id === activeDoc}
            onClick={() => onSelectDoc(doc.id)}
          >
            <div className="d-flex flex-row gap-2">
              <div className="d-flex justify-content-center align-items-center">
                <EditorControls.CheckboxInput
                  value={doc.use}
                  onChange={() => onToggleDoc(doc.id)}
                />
              </div>
              <Row>
                <Col sm={12}>
                  <h5 className="fs-1 fw-bold">{doc.repr}</h5>
                </Col>
                <Col sm={4} className="d-inline-flex gap-2 text-muted">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faMapMarkerAlt} title="Фінансовий орган" size="xl" />
                  </div>
                  <span className="align-self-center">{doc.authority.repr}</span>
                </Col>
                <Col sm={4} className="text-muted">
                  <UserShow user={doc.author} iconSize="xl" className="h-100" />
                </Col>
              </Row>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </ContainerTable>
  );
}

DocList.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
    use: PropTypes.bool.isRequired,
  })).isRequired,
  onToggleDoc: PropTypes.func.isRequired,
  onSelectDoc: PropTypes.func.isRequired,
  activeDoc: PropTypes.number,
};

DocList.defaultProps = {
  activeDoc: null,
};

export default DocList;
