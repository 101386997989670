import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import { getColor, rgbaColor } from '../../../../components/blanks/helpers/utils';
import CardHeader from '../../../../components/blanks/common/CardHeader';
import { moneysOneDigitStingOptions } from '../../../../constants/moneys';
import valueFormatter from '../const/valueFormatter';
import useDiagramShower from '../diagramHook';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent,
]);

// Преобразование данных в формат, подходящий для ECharts
const transformData = (data) => {
  const categories = Object.keys(data);
  const years = Object.keys(data[categories[0]]);

  const transformedData = [
    ['Category', ...years.map((year, idx) => `${year} рік ${(years.length === (idx + 1)) ? '(план)' : '(факт)'}`)],
    ...categories.map((category) => [
      category,
      ...years.map((year) => data[category][year].amount),
    ]),
  ];

  return transformedData;
};

const getOption = (data) => {
  const categories = Object.keys(data);
  const years = Object.keys(data[categories[0]]); // Извлекаем года из данных

  return {
    color: [
      rgbaColor(getColor('info'), 0.6),
      getColor('primary'),
      rgbaColor(getColor('warning'), 0.4),
    ],
    dataset: { source: transformData(data) },
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter(params) {
        return `<div className="fw-semi-bold">${
          params.seriesName
        }</div><div className="fs--1 text-600"><strong>${params.name}:</strong> ${
          params.value[params.componentIndex + 1].toLocaleString('uk', moneysOneDigitStingOptions)
        }</div>`;
      },
    },
    legend: {
      data: years.map((year, idx) => `${year} рік ${(years.length === (idx + 1)) ? '(план)' : '(факт)'}`), // Используем года для легенды
      left: 'left',
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      textStyle: { color: getColor('gray-700') },
    },
    xAxis: {
      type: 'category',
      axisLabel: { color: getColor('gray-400') },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'line',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200'),
          type: 'line',
          width: 0.5,
        },
      },
      axisTick: { show: false },
      boundaryGap: true,
    },
    yAxis: {
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200'),
          type: 'dashed',
        },
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: (value) => valueFormatter(value),
      },
    },
    series: years.map(() => ({
      type: 'bar',
      barWidth: '10%',
      barGap: '30%',
      label: { show: false },
      z: 10,
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        borderRadius: [8, 8, 0, 0],
      },
    })),
    grid: {
      right: '0',
      left: '85px',
      bottom: '10%',
      top: '12%',
    },
  };
};

function DynamicKbp({ data }) {
  const { containerChartRef, show } = useDiagramShower({});
  return (
    <Card className="h-100">
      <CardHeader
        title="Порівняння з минулими роками"
        titleTag="h6"
        className="py-2"
        light
        // endEl={(
        //   <Form.Select size="sm">
        //     <option>За 3 роки</option>
        //     <option>За 4 роки</option>
        //     <option>За 5 роки</option>
        //   </Form.Select>
        // )}
      />
      <Card.Body className="h-100 w-100" ref={containerChartRef} style={{ minHeight: '15.625rem' }}>
        {show && (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(data)}
          style={{ height: '100%', minHeight: '15.625rem' }}
        />
        )}
      </Card.Body>
    </Card>
  );
}

DynamicKbp.propTypes = {
  data: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        amount: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
};

export default DynamicKbp;
