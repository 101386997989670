import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControl, InputGroup, Dropdown, Spinner,
} from 'react-bootstrap';

const SFormControl = styled(FormControl)`
  &[readonly] {
  background: ${(props) => (props.background ? props.background : ' var(--falcon-white) !important')}
  }
`;

export const DropContainerButton = styled(InputGroup)`
   div button {
      background-color: ${(props) => (props.background ? props.background : 'var(--backgroundButton) !important')}
      color: var(--colorTextButton)!important;
        &:hover {
          background-color: var(--hoverButton) !important;
        };
    };
  div div {
    // transform: translate3d(0px, 38px, 0px) !important;
    right: 0px !important;
    left: auto !important;
    width: 100% !important;
    };
`;

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const Selector = forwardRef(({
  value, values, onChange, disabled, readOnly,
  required, isLoading, nullLabel, onDropDown, errored,
  className, background, prepend, size, onFocus, onBlur,
}, ref) => {
  const displayValue = useMemo(
    () => (values || []).reduce((R, v) => (v.value === value ? v.display_name : R), ''),
    [values, value],
  );
  const displayValues = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => (required ? values || [] : [{ value: null, display_name: nullLabel }, ...values]),
    [nullLabel, required, values],
  );
  return (
    <DropContainerButton className={className} background={background} size={size || 'sm'}>
      {prepend}
      <SFormControl
        background={background}
        ref={ref}
        value={displayValue}
        isInvalid={errored}
        readOnly
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {isLoading && (

        <InputGroup.Text>
          <Spinner animation="grow" size="sm" />
        </InputGroup.Text>

      )}
      <Dropdown
        style={{ position: 'unset' }}
        as={InputGroup.Append}
        onToggle={(isOpen, e, md) => {
          if (isOpen && onDropDown) onDropDown(e, md);
        }}
      >
        <Dropdown.Toggle
          // id={`drdn-${id}`}
          variant="outline-secondary"
          disabled={disabled || readOnly}
          split
          style={{ zIndex: 'auto' }}
        />
        <Dropdown.Menu alignRight style={{ maxHeight: '20em', zIndex: '10000' }} className="overflow-auto">
          {displayValues.map((vv) => (
            <Dropdown.Item
              key={vv.value}
              active={vv.value === value}
              disabled={vv.disabled}
              onClick={(e) => onChange(e, vv.value)}
            >
              {vv.display_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </DropContainerButton>
  );
});

Selector.propTypes = {
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  nullLabel: PropTypes.string,
  onDropDown: PropTypes.func,
  errored: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  background: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

Selector.defaultProps = {
  value: null,
  disabled: false,
  readOnly: false,
  required: true,
  isLoading: false,
  nullLabel: 'Не обрано',
  onDropDown: null,
  errored: false,
  className: null,
  size: 'sm',
  background: null,
  prepend: null,
  onFocus: null,
  onBlur: null,
};

export default Selector;
