import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },

  planType: { label: 'Вид плану', name: 'ВидПлана' },
  fundsType: { label: 'Вид коштів', name: 'ВидыСредствКЕКВ' },
  ReportKind: { label: 'Вид звіту', name: 'ВидОтчета' },
  reportType: { label: 'Додатки', name: 'ТипОтчета' },

  in1000: { label: 'у тис.грн.', name: 'ВТысГРН' },
  year: { label: 'Рік', name: 'ГодЗатрат' },

  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },

  allKEKV: { label: 'За всіма КЕКВ', name: 'флПоВсемКЕКВ' },
  byGroupKEKV: { label: 'За групами КЕКВ', name: 'флГруппыКЕКВ' },
  byGroupKBP: { label: 'За групами КБП (галузями)', name: 'флГруппыКФК' },
  byKEKV: { label: 'За КЕКВ', name: 'флКЕКВ' },
  byDetailsElKPKV: { label: 'За детал. бюджет.прог.', name: 'флПоДетализацииКФК' },
  byCSU: { label: 'За розп./отр.', name: 'флПоРаспорядителям' },
  byReducedKEKV: { label: 'За скороченими КЕКВ', name: 'флСокрКЕКВ' },
  fond: { label: 'Фонд', name: 'Фонд' },
  customSettings: { label: 'індивідуальні налаштування', name: 'флИндНастройки' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  docId: { label: 'id документа', name: 'id' },
  consolid: { label: 'Зведений (для відправки в Логіка)', name: 'флСвод' },
  KPKVKMB: { label: 'КПКВКМБ (для відправки в Логіка)', name: 'КПКВМБ' },
  csu: { label: 'Установа (для відправки в Логіка, якщо не обрати, надсилається звіт зведений за КПКВКМБ)', name: 'Расп' },
};

const definition = {
  label: 'Розпис асигнувань (регл.)',
  name: 'GovPlanSpend',
  columns,
  frontend: 'rep/GovPlanSpend',
  backendName: 'регРосписьАссигнований',
  resultColumns: baseResultColumns,
};

export default definition;
