import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  signVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  reportType: { label: 'Вид звіту', name: 'ВидОтчета' },
  planType: { label: 'Вид плану', name: 'ВидПлана' },
  fundsType: { label: 'Вид коштів', name: 'ВидыСредствКЕКВ' },
  incDB: { label: 'в т.ч. БР', name: 'вТЧБР' },
  in1000: { label: 'в тис.грн.', name: 'ВТысГРН' },
  year: { label: 'Рік складання', name: 'ГодЗатрат' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
  byExtract: { label: 'Витяг', name: 'флВитяг' },
  showCSUNames: {
    label: 'Відображати найменування розпорядників',
    name: 'флНаимРаспорядителей',
  },

  bySubLevelCSU: {
    label: 'За данними підлеглих розпорядників',
    name: 'флПоДаннымНижнихРасп',
  },
  byHighLevelCSU: {
    label: 'За даними розпорядника вищого рівня',
    name: 'флПоДаннымФО',
  },
  consolidated: { label: 'Зведений', name: 'флСводныйБюджет' },

};

const definition = {
  label: 'Річний розпис асигнувань (регл)',
  name: 'YearAssigment',
  columns,
  frontend: 'rep/YearAssigment',
  backendName: 'регрічнийрозпис',
  resultColumns: baseResultColumns,
};

export default definition;
