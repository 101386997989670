import React, {
  memo, useCallback, useEffect, useId, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { getDisplayName } from '../../../api/utils';

const withEditorControl = (WrappedControl, alwaysFloated = false) => {
  function EditorInput({
    controlId, label, required, description, onFocus, onBlur,
    value, readOnly, disabled, ...restProps
  }) {
    const controlRef = useRef(null);
    const nId = useId();
    const cId = controlId || nId;
    const [floated, setFloated] = useState(false);
    const isEmpty = value === null || (typeof value === 'string' && value.trim() === '');
    useEffect(() => {
      if (!floated && !isEmpty) setFloated(true);
    }, [floated, isEmpty]);

    const focusHandler = useCallback(
      (e) => {
        setFloated(true);
        if (onFocus) onFocus(e);
      },
      [onFocus],
    );
    const blurHandler = useCallback(
      (e) => {
        setFloated(!isEmpty);
        if (onBlur) onBlur(e);
      },
      [isEmpty, onBlur],
    );
    const handleLabelClick = useCallback(
      () => {
        if (controlRef.current && controlRef.current.focus) controlRef.current.focus();
      },
      [],
    );
    return (
      <Form.Group className={classNames('form-group', { floated, alwaysFloated })}>
        <WrappedControl
          {...restProps}
          id={cId}
          ref={controlRef}
          required={required}
          onFocus={focusHandler}
          onBlur={blurHandler}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
        />
        {label && (
        <Form.Label
          required={required}
          className={classNames('text-truncate', { 'control-disabled': readOnly || disabled })}
          title={label}
          onClick={handleLabelClick}
        >
          {label}
        </Form.Label>
        )}
        {description && (
          <Form.Text>
            {description}
          </Form.Text>
        )}
      </Form.Group>
    );
  }

  EditorInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  EditorInput.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({}),
      ])),
    ]),
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  EditorInput.defaultProps = {
    controlId: null,
    label: null,
    required: false,
    errors: null,
    description: null,
    onFocus: null,
    onBlur: null,
    value: null,
    readOnly: false,
    disabled: false,
  };
  return memo(EditorInput);
};

export default withEditorControl;
