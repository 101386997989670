import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
  disposerInHeader, totals, isRefundByMonth, isTransferCfSf, useFinProposal,
}) {
  return (
    <TableHeaderRow className="ps-3 fs--2">
      <Col>
        <Row>
          {isRefundByMonth && (
          <Col>
            <Row>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'return_funding'}
                  highlighted={highlights.includes('return_funding')}
                  onToggleHighlight={() => onToggleHighlight('return_funding')}
                >
                  Вкл.
                </TableHeaderCell>
              </Col>
              <Col>
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'return_month'}
                  highlighted={highlights.includes('return_month')}
                  onToggleHighlight={() => onToggleHighlight('return_month')}
                >
                  {fields.return_month.label}
                </TableHeaderCell>
              </Col>
            </Row>
          </Col>
          )}
          {isTransferCfSf && (
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kfb_debt'}
              highlighted={highlights.includes('kfb_debt')}
              onToggleHighlight={() => onToggleHighlight('kfb_debt')}
            >
              {fields.kfb_debt.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kfb_credit'}
              highlighted={highlights.includes('kfb_credit')}
              onToggleHighlight={() => onToggleHighlight('kfb_credit')}
            >
              {fields.kfb_credit.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'acc3261'}
              highlighted={highlights.includes('acc3261')}
              onToggleHighlight={() => onToggleHighlight('acc3261')}
            >
              {fields.acc3261.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'acc3262'}
              highlighted={highlights.includes('acc3262')}
              onToggleHighlight={() => onToggleHighlight('acc3262')}
            >
              {fields.acc3262.label}
            </TableHeaderCell>
          </Col>
          )}
          <Col lg={3} className="p-0">
            {!disposerInHeader && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer'}
              highlighted={highlights.includes('disposer')}
              onToggleHighlight={() => onToggleHighlight('disposer')}
            >
              {fields.disposer.label}
            </TableHeaderCell>
            )}
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kbp7_item'}
              highlighted={highlights.includes('kbp7_item')}
              onToggleHighlight={() => onToggleHighlight('kbp7_item')}
            >
              {fields.kbp7_item.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kekv_item'}
              highlighted={highlights.includes('kekv_item')}
              onToggleHighlight={() => onToggleHighlight('kekv_item')}
            >
              {fields.kekv_item.label}
            </TableHeaderCell>
          </Col>
          <Col lg={3} className="p-0">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer_acc'}
              highlighted={highlights.includes('disposer_acc')}
              onToggleHighlight={() => onToggleHighlight('disposer_acc')}
            >
              {fields.disposer_acc.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'treasure_date'}
              highlighted={highlights.includes('treasure_date')}
              onToggleHighlight={() => onToggleHighlight('treasure_date')}
            >
              {fields.treasure_date.label}
            </TableHeaderCell>
          </Col>
          <Col lg={2} className="p-0">
            <TableHeaderCell
              title={totals.fin_commitment_amount}
              className="text-center text-xl-left"
              active={activeCol === 'fin_commitment_amount'}
              highlighted={highlights.includes('fin_commitment_amount')}
              onToggleHighlight={() => onToggleHighlight('fin_commitment_amount')}
            >
              {fields.fin_commitment_amount.label}
            </TableHeaderCell>
            <TableHeaderCell
              title={totals.balance}
              className="text-center text-xl-left"
              active={activeCol === 'balance'}
              highlighted={highlights.includes('balance')}
              onToggleHighlight={() => onToggleHighlight('balance')}
            >
              {fields.balance.label}
            </TableHeaderCell>
            <TableHeaderCell
              title={totals.amount}
              className="text-center text-xl-left"
              active={activeCol === 'amount'}
              highlighted={highlights.includes('amount')}
              onToggleHighlight={() => onToggleHighlight('amount')}
            >
              {fields.amount.label}
            </TableHeaderCell>
          </Col>
          <Col className="p-0">
            {useFinProposal && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finproposal'}
              highlighted={highlights.includes('finproposal')}
              onToggleHighlight={() => onToggleHighlight('finproposal')}
            >
              {fields.finproposal.label}
            </TableHeaderCell>
            )}
            {!useFinProposal && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'finrequest'}
              highlighted={highlights.includes('finrequest')}
              onToggleHighlight={() => onToggleHighlight('finrequest')}
            >
              {fields.finrequest.label}
            </TableHeaderCell>
            )}
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'remark_template'}
              highlighted={highlights.includes('remark_template')}
              onToggleHighlight={() => onToggleHighlight('remark_template')}
            >
              {fields.remark_template.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'remark_note'}
              highlighted={highlights.includes('remark_note')}
              onToggleHighlight={() => onToggleHighlight('remark_note')}
            >
              {fields.remark_note.label}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlight: PropTypes.func.isRequired,
  fields: fieldsPropTypes.isRequired,
  disposerInHeader: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    fin_commitment_amount: PropTypes.number,
    balance: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
  isRefundByMonth: PropTypes.bool,
  isTransferCfSf: PropTypes.bool,
  useFinProposal: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
  isRefundByMonth: false,
  isTransferCfSf: false,
};

export default Header;
