/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import PrintButton from '../lister/printButton';
import DBFButton from '../lister/DBFButton';
import DocEditorCommandPanel from '../../../newEditor/commandPanels/document';

function APMenu({ id, ...rest }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <DocEditorCommandPanel
      id={id}
      {...rest}
    >
      <ButtonGroup>
        <PrintButton selectedRows={selectedRows} />
        <DBFButton selectedRows={selectedRows} />
      </ButtonGroup>
    </DocEditorCommandPanel>
  );
}

APMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default APMenu;
