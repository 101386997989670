import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { CPButton } from '../../components/bootStrap/buttons/styles';

const GridTemplate = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
      height: 100vh;
      width: 100vw;
      z-index: 2147483645;
      top: 0;
      left: 0;
  `;

const DivModal = styled.div`
    background: black;
    opacity: 60%;
    border: none;
`;

const Footer = styled(DivModal)`
      flex-grow: 1;
      `;

const RightArea = styled(DivModal)`
      grid-area: rightArea;
      flex-grow: 1;
      `;

const BorderLeft = styled.div`
    width: 6px;
    opacity: 100%;
    margin-left: -4px;
    &:hover{
     cursor: col-resize;
     };
`;

const BorderRight = styled(BorderLeft)`
  margin-right: -4px;
`;

const BorderTop = styled.div`
    opacity: 100%;
    min-width: 100%;
    height: 6px;
    margin-top: -4px;
     &:hover{
     cursor: row-resize;
     };
`;

const BorderBottom = styled(BorderTop)`
  margin-bottom: -4px;
`;

const Header = styled(DivModal)`
      min-height: ${({ y1 }) => y1}px;
      min-width: 100vw;
      `;

// &:hover{
//       cursor: ${({ init }) => {
//   console.log('init', init);
//   return (init ? 'row-resize' : 'auto')
// }};
//       };

const LeftArea = styled(DivModal)`
      width: ${({ x1 }) => x1}px;`;

const Row = styled.div`
     display: flex;
     width: 100vw;
      `;

const AvatarArea = styled(DivModal)`
    background: none;
    width: ${({ x1, x2 }) => (x2 - x1)}px;
    height: ${({ y1, y2 }) => (y2 - y1)}px;
    opacity: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 3px dashed  red;
    border-radius: 5px;
    `;

const BorderContainer = styled(AvatarArea)`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    border: none;
`;

function GetScreen({
  doAvatar, ratio, canvasHeight,
}) {
  // Встановлюємо початковий розмір скрина у 2 рази більший від розміру аватарки
  const [avatarArea, setAvatarArea] = useState({
    x1: 150,
    y1: 280,
    x2: Math.round(canvasHeight * 2 * ratio) + 150,
    y2: Math.round(canvasHeight * 2) + 280,
  });

  const mouseDown = useRef(
    {
      init: false,
      border: '',
    },
  );

  const mouseDownHandler = (pos) => {
    mouseDown.current = {
      init: true,
      border: pos,
    };
  };

  const mouseUpHandler = (e) => {
    const { init, border } = mouseDown.current;

    if (init) {
      const {
        x1, x2, y1, y2,
      } = avatarArea;

      switch (border) {
        case 'top':
          setAvatarArea((oldParam) => ({
            ...oldParam,
            x2: Math.round((y2 - e.clientY) * ratio) + x1,
            y1: e.clientY,
          }));
          break;
        case 'left':
          setAvatarArea((oldParam) => ({
            ...oldParam,
            x1: e.clientX,
            y2: Math.round((x2 - e.clientX) / ratio) + y1,
          }));
          break;
        case 'right':
          setAvatarArea((oldParam) => ({
            ...oldParam,
            x2: e.clientX,
            y2: Math.round((e.clientX - x1) / ratio) + y1,
          }));
          break;
        case 'bottom':
          setAvatarArea((oldParam) => ({
            ...oldParam,
            y2: e.clientY,
            x2: Math.round((e.clientY - y1) * ratio) + x1,
          }));
          break;
        default:
          break;
      }

      mouseDown.current = {
        init: false,
        border: '',
      };
    }
  };

  return (
    <GridTemplate
      onMouseUp={(e) => mouseUpHandler(e)}
    >
      <Header
        y1={avatarArea.y1}
        init={mouseDown.current.init}
      />
      <Row>
        <LeftArea
          x1={avatarArea.x1}
        />
        <AvatarArea
          {...avatarArea}
        >
          <BorderTop
            onMouseDown={(e) => mouseDownHandler('top', e)}
          />
          <BorderContainer>
            <BorderLeft
              onMouseDown={(e) => mouseDownHandler('left', e)}
            />
            <div style={{ flexGrow: 0 }}>
              <CPButton
                onClick={() => doAvatar({
                  x1: avatarArea.x1,
                  y1: avatarArea.y1,
                  ratio,
                  canvasHeight,
                  avatarHeight: (avatarArea.y2 - avatarArea.y1),
                })}
                content="Натисніть для збереження"
                icon={faCamera}
              />
            </div>
            <BorderRight
              onMouseDown={(e) => mouseDownHandler('right', e)}
            />
          </BorderContainer>

          <BorderBottom
            onMouseDown={(e) => mouseDownHandler('bottom', e)}
          />
        </AvatarArea>
        <RightArea />
      </Row>
      <Footer />

    </GridTemplate>

  );
}

GetScreen.propTypes = {
  doAvatar: PropTypes.func.isRequired,
  ratio: PropTypes.number,
  canvasHeight: PropTypes.number,
};

GetScreen.defaultProps = {
  ratio: 1,
  canvasHeight: 200,
};

export default GetScreen;
