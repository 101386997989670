import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import EstimatePrintModal from '../lister/estimatePrintModalLister';
import MixIndicatorsSFPrintModal from '../lister/mixIndicatorsSFPrintModalLister';

function PrintSubMenu({ id }) {
  const selectedRows = useMemo(() => [id], [id]);
  return (
    <Dropdown>
      <Dropdown.Toggle variant="info" size="sm">
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Показати ще ...
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <PrintModalPlanOfUse selectedRows={selectedRows} /> */}
        <EstimatePrintModal />
        <MixIndicatorsSFPrintModal selectedRows={selectedRows} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

PrintSubMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PrintSubMenu;
