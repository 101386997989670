import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/forecastAdd8';

const meta = md.tables.incomes;
function TPHeader({
  activeCol,
  highlights,
  onToggleHighlght, activeYear,
}) {
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col
        xl={12}
        sm={12}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={6} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KBPCode'}
              highlighted={highlights.includes('KBPCode')}
              onToggleHighlght={() => onToggleHighlght('KBPCode')}
            >
              {meta.columns.KBPCode.label}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'KBP'}
              highlighted={highlights.includes('KBP')}
              onToggleHighlght={() => onToggleHighlght('KBP')}
            >
              {meta.columns.KBP.label}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesGF_Report'}
              highlighted={highlights.includes('incomesGF_Report')}
              onToggleHighlght={() => onToggleHighlght('incomesGF_Report')}
            >
              {`${activeYear - 2} (звіт) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesSF_Report'}
              highlighted={highlights.includes('incomesSF_Report')}
              onToggleHighlght={() => onToggleHighlght('incomesSF_Report')}
            >
              {`${activeYear - 2} (звіт) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesGF_Approved'}
              highlighted={highlights.includes('incomesGF_Approved')}
              onToggleHighlght={() => onToggleHighlght('incomesGF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesSF_Approved'}
              highlighted={highlights.includes('incomesSF_Approved')}
              onToggleHighlght={() => onToggleHighlght('incomesSF_Approved')}
            >
              {`${activeYear - 1} (затверджено) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesGF_Plan'}
              highlighted={highlights.includes('incomesGF_Plan')}
              onToggleHighlght={() => onToggleHighlght('incomesGF_Plan')}
            >
              {`${activeYear} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesSF_Plan'}
              highlighted={highlights.includes('incomesSF_Plan')}
              onToggleHighlght={() => onToggleHighlght('incomesSF_Plan')}
            >
              {`${activeYear} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesGF_Plan1'}
              highlighted={highlights.includes('incomesGF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('incomesGF_Plan1')}
            >
              {`${activeYear + 1} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesSF_Plan1'}
              highlighted={highlights.includes('incomesSF_Plan1')}
              onToggleHighlght={() => onToggleHighlght('incomesSF_Plan1')}
            >
              {`${activeYear + 1} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesGF_Plan2'}
              highlighted={highlights.includes('incomesGF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('incomesGF_Plan2')}
            >
              {`${activeYear + 2} (план) Загальний фонд`}
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'incomesSF_Plan2'}
              highlighted={highlights.includes('incomesSF_Plan2')}
              onToggleHighlght={() => onToggleHighlght('incomesSF_Plan2')}
            >
              {`${activeYear + 2} (план) Спеціальний фонд`}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  ///
  activeYear: PropTypes.number,

};

TPHeader.defaultProps = {
  activeCol: '',
  /// /
  activeYear: 0,
};

export default memo(TPHeader);
