import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/localDebt';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'TABL6';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG1S" highlighted={highlights.includes('T6RXXXXG1S')}>
              <ItemPicker
                value={row[tableMD.columns.T6RXXXXG1S.name]}
                modelType="cat"
                modelName="kdbClassifier"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG1S.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG3" highlighted={highlights.includes('T6RXXXXG3')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG3.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG3.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG4" highlighted={highlights.includes('T6RXXXXG4')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG4.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG4.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG5" highlighted={highlights.includes('T6RXXXXG5')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG5.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG5.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG6" highlighted={highlights.includes('T6RXXXXG6')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG6.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG6.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG7" highlighted={highlights.includes('T6RXXXXG7')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG7.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG7.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG8" highlighted={highlights.includes('T6RXXXXG8')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG8.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG8.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG9" highlighted={highlights.includes('T6RXXXXG9')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG9.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG9.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG10" highlighted={highlights.includes('T6RXXXXG10')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG10.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG10.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T6RXXXXG11" highlighted={highlights.includes('T6RXXXXG11')}>
              <NumberInput
                value={row[tableMD.columns.T6RXXXXG11.name]}
                precision={3}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T6RXXXXG11.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T6RXXXXG1S.name]: referencePropType,
    [tableMD.columns.T6RXXXXG3.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG4.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG5.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG6.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG7.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG8.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG9.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG10.name]: PropTypes.number,
    [tableMD.columns.T6RXXXXG11.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
