import BudgetElChTPEditor from './budgetElChTP/editor';
import BudgetElChTPLister from './budgetElChTP/lister';
import CsuElChTPEditor from './csuElChTP/editor';
import CsuElChTPLister from './csuElChTP/lister';
import TypesRepairElChTPEditor from './typesRepairElChTP/editor';
import TypesRepairElChTPLister from './typesRepairElChTP/lister';
import AddPropClassifiersChTPLister from './addPropClassifiersChTP/lister';
import AddPropClassifiersChTPEditor from './addPropClassifiersChTP/editor';

const ChTPComponents = {
  budgetElChTP: {
    editor: BudgetElChTPEditor,
    lister: BudgetElChTPLister,
  },
  csuElChTP: {
    editor: CsuElChTPEditor,
    lister: CsuElChTPLister,
  },
  typesRepairElChTP: {
    editor: TypesRepairElChTPEditor,
    lister: TypesRepairElChTPLister,
  },
  addPropClassifiersChTP: {
    editor: AddPropClassifiersChTPEditor,
    lister: AddPropClassifiersChTPLister,
  },
};

export default {
  ChTPComponents,
  BudgetElChTPEditor,
  BudgetElChTPLister,
  CsuElChTPEditor,
  CsuElChTPLister,
  TypesRepairElChTPEditor,
  TypesRepairElChTPLister,
  AddPropClassifiersChTPEditor,
  AddPropClassifiersChTPLister,
};

export {
  ChTPComponents,
  BudgetElChTPEditor,
  BudgetElChTPLister,
  CsuElChTPEditor,
  CsuElChTPLister,
  TypesRepairElChTPEditor,
  TypesRepairElChTPLister,
  AddPropClassifiersChTPEditor,
  AddPropClassifiersChTPLister,
};
