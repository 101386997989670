import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  anyLink: { label: 'Об\'єкт', name: 'Объект' },
  anyLinkName: { label: 'Найменування', name: 'Наименование' },
  uid: { label: 'UID', name: 'имяФайлаИГУИД' },
  flagIsFile: { label: 'Вивантажується до файлу', name: 'ПишетсяВФайл' },
  typeFile: { label: 'Тип документа, що завантажується', name: 'ТипЗагружаемогоДок' },
};

/**
 * @const
 */
const definition = {
  name: 'attachedFiles',
  backendName: 'ПрикрепленныеФайлы',
  frontend: 'dp/attachedFiles',
  label: 'Додані файли',
  columns,

};

export default definition;
