import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';

const GroupItem = styled(ListGroup.Item)`
  :hover {
    color: var(--bs-white) !important;
    background-color: var(--bs-primary) !important;
  }
`;

export function DataList({
  data, onSelect, activeItem, className, style,
}) {
  const ref = useRef();
  const oldActiveItem = useRef(null);
  useEffect(
    () => {
      if (ref.current && activeItem !== null) {
        const el = ref.current.querySelector('.active');
        const t1 = el.getBoundingClientRect().top;
        const b1 = el.getBoundingClientRect().bottom;
        const t0 = ref.current.getBoundingClientRect().top;
        const h0 = ref.current.getBoundingClientRect().height;
        const s0 = ref.current.scrollTop;
        if (oldActiveItem.current === null || oldActiveItem.current < activeItem) {
          // привязываемся к нижней точке
          if ((b1 - t0 + s0) > (h0 + s0)) {
            ref.current.scrollTo({ top: b1 - t0 - h0 + s0, behavior: 'smooth' });
          }
        } else {
          // привязываемся к верхней точке
          // eslint-disable-next-line no-lonely-if
          if ((t1 - t0) < 0) {
            ref.current.scrollTo({ top: t1 - t0 + s0, behavior: 'smooth' });
          }
        }
        oldActiveItem.current = activeItem;
      }
    },
    [activeItem],
  );
  const cName = useMemo(
    () => `${className} scrollbar border mt-1`,
    [className],
  );
  return (
    <ListGroup className={cName} ref={ref} style={style} variant="flush">
      <div style={{ maxHeight: '200px' }}>
        {data.map((row, idx) => (
          <GroupItem
            key={row.id}
            onClick={(e) => onSelect(e, row)}
            active={idx === activeItem}
            action
          >
            {row.search_repr || row.repr}
          </GroupItem>
        ))}
      </div>
    </ListGroup>
  );
}

DataList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
    search_repr: PropTypes.string,
  })),
  onSelect: PropTypes.func,
  activeItem: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

DataList.defaultProps = {
  data: [],
  // eslint-disable-next-line
  onSelect: () => null,
  activeItem: null,
  className: '',
  style: {},
};

export default DataList;
