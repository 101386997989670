import PropTypes from 'prop-types';
import {
  ButtonGroup, ButtonToolbar, Dropdown, Modal, Navbar,
} from 'react-bootstrap';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import React, {
  Suspense, useEffect, useMemo, useState,
} from 'react';
import { CPButton } from '../../../../../../components/bootStrap/buttons';
import IconButton from '../../../../../../components/blanks/common/IconButton';
import LowerLevelLoading from './lowerLevelLoading';

const clearTypes = {
  true: 'Заповнити з даних розпорядника нижнього рівня',
  false: 'Доповнити з даних розпорядника нижнього рівня',
};

function TPCommandPanel({
  data, items, onDraft, tableActions, fields,
}) {
  const [show, setShow] = useState(false);
  const [clear, setClear] = useState('true');
  const [docsToReceive, setDocsToReceive] = useState([]);

  const overrideFilter = useMemo(
    () => ({
      authority: data.authority,
      year: data.year,
      kbp7: data.kbp7,
    }),
    [data.authority, data.year, data.kbp7],
  );

  useEffect(
    () => {
      const blockedDocs = items.filter((r) => r.blocked).map((obj) => obj.document.id);
      setDocsToReceive(blockedDocs);
    },
    [items],
  );

  const defaultFilter = useMemo(() => ({
    begin_date: `${data.year}-01-01`,
    end_date: data.doc_date,
    received: false,
    deleted: false,
  }), [data.year, data.doc_date]);

  return (
    <Navbar className="bg-white border-bottom rounded-top">
      <ButtonToolbar className="w-100">
        <ButtonGroup>
          <IconButton
            variant="falcon-default"
            size="sm"
            className="ms-sm-1"
            onClick={() => {
              if (docsToReceive.length === items.length) {
                setDocsToReceive([]);
                tableActions.onUnapproveAllDocs();
              } else {
                const docs = items.map((v) => v.id);
                setDocsToReceive(docs);
                tableActions.onApproveAllDocs();
              }
            }}
            icon={docsToReceive.length === items.length ? faSquareCheck : faSquare}
            title={docsToReceive.length === items.length ? 'Зняти ознаку "Отримано" для всіх' : 'Встановити ознаку "Отримано" для всіх'}
          />
          <Dropdown as={ButtonGroup}>
            <CPButton
              onClick={() => setShow(true)}
              content={clearTypes[clear]}
              icon={faFileImport}
            />
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" />
            <Dropdown.Menu>
              {Object.keys(clearTypes).map((item) => (
                <Dropdown.Item key={item} onClick={() => setClear(item)}>
                  {clearTypes[item]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          { data && (
          <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Оберіть документи для завантаження</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Suspense fallback={<div>Загрузка...</div>}>
                <LowerLevelLoading
                  overrideFilter={overrideFilter}
                  defaultFilter={defaultFilter}
                  docDate={data.doc_date}
                  fields={fields}
                  onLoadHandler={(docs, loadingTables) => {
                    onDraft(
                      data,
                      {
                        load_level_down_documents: {
                          documents: docs,
                          clear: clear === 'true',
                          loading_tables: loadingTables,
                        },
                      },
                    );
                    setShow(false);
                  }}
                />
              </Suspense>
            </Modal.Body>
          </Modal>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </Navbar>
  );
}

TPCommandPanel.propTypes = {
  data: PropTypes.shape([]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDraft: PropTypes.func.isRequired,
  tableActions: PropTypes.shape({
    onApproveAllDocs: PropTypes.func.isRequired,
    onUnapproveAllDocs: PropTypes.func.isRequired,
  }).isRequired,
  fields: PropTypes.shape({}),
};

TPCommandPanel.defaultProps = {
  fields: null,
};

export default TPCommandPanel;
