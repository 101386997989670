import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ItemPicker,
  NumberInput, TextInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/expensesOnHealth';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

import { referencePropType } from '../../../../../newEditor/propTypes';

const tablename = 'TP2610';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, highlights, FI,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );
  const CSUFIlter = useMemo(
    () => [{ fieldName: 'parent', value: FI }],
    [FI],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG2S" highlighted={highlights.includes('T1RXXXXG2S')}>
              <TextInput
                value={row[tableMD.columns.T1RXXXXG2S.name]}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG2S.name]: v })}
                readOnly
                rows={1}
                className={classNames('fw-bold', { 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG1S" highlighted={highlights.includes('T1RXXXXG1S')}>
              <ItemPicker
                value={row[tableMD.columns.T1RXXXXG1S.name]}
                modelType="cat"
                modelName="csu"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG1S.name]: v })}
                readOnly
                filter={CSUFIlter}
                noHierarchy
                className={classNames('fw-bold', { 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG27" highlighted={highlights.includes('T1RXXXXG27')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG27.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG27.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG27.name] === row[tableMD.columns.T1RXXXXG28.name], 'bg-warning': row[tableMD.columns.T1RXXXXG27.name] !== row[tableMD.columns.T1RXXXXG28.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG28" highlighted={highlights.includes('T1RXXXXG28')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG28.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG28.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG27.name] === row[tableMD.columns.T1RXXXXG28.name], 'bg-warning': row[tableMD.columns.T1RXXXXG27.name] !== row[tableMD.columns.T1RXXXXG28.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG31" highlighted={highlights.includes('T1RXXXXG31')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG31.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG31.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG31.name] === row[tableMD.columns.T1RXXXXG32.name], 'bg-warning': row[tableMD.columns.T1RXXXXG31.name] !== row[tableMD.columns.T1RXXXXG32.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG32" highlighted={highlights.includes('T1RXXXXG32')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG32.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG32.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG31.name] === row[tableMD.columns.T1RXXXXG32.name], 'bg-warning': row[tableMD.columns.T1RXXXXG31.name] !== row[tableMD.columns.T1RXXXXG32.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG33" highlighted={highlights.includes('T1RXXXXG33')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG33.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG33.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG33.name] === row[tableMD.columns.T1RXXXXG34.name], 'bg-warning': row[tableMD.columns.T1RXXXXG33.name] !== row[tableMD.columns.T1RXXXXG34.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG34" highlighted={highlights.includes('T1RXXXXG34')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG34.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG34.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG33.name] === row[tableMD.columns.T1RXXXXG34.name], 'bg-warning': row[tableMD.columns.T1RXXXXG33.name] !== row[tableMD.columns.T1RXXXXG34.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="T1RXXXXG37" highlighted={highlights.includes('T1RXXXXG37')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG37.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG37.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG37.name] === row[tableMD.columns.T1RXXXXG38.name], 'bg-warning': row[tableMD.columns.T1RXXXXG37.name] !== row[tableMD.columns.T1RXXXXG38.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
            <TableRowCell column="T1RXXXXG38" highlighted={highlights.includes('T1RXXXXG38')}>
              <NumberInput
                value={row[tableMD.columns.T1RXXXXG38.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.T1RXXXXG38.name]: v })}
                readOnly
                className={classNames('fw-bold', { 'bg-success': row[tableMD.columns.T1RXXXXG37.name] === row[tableMD.columns.T1RXXXXG38.name], 'bg-warning': row[tableMD.columns.T1RXXXXG37.name] !== row[tableMD.columns.T1RXXXXG38.name], 'bg-danger': row[tableMD.columns.noDataPresent.name] === true })}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.T1RXXXXG1S.name]: referencePropType,
    [tableMD.columns.T1RXXXXG2S.name]: PropTypes.string,
    [tableMD.columns.T1RXXXXG38.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG37.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG34.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG33.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG32.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG31.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG28.name]: PropTypes.number,
    [tableMD.columns.T1RXXXXG27.name]: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  FI: referencePropType,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  FI: null,
};

export default memo(TPRow);
