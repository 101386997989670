import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FO: { label: 'ТекущийФинансовыйОрган', name: 'ТекущийФинансовыйОрган' },
  Budget: { label: 'ТекущийБюджет', name: 'ТекущийБюджет' },
};

/**
 * @const
 */
const definition = {
  name: 'SelfTesting',
  backendName: '',
  frontend: 'dp/selfTesting',
  label: 'Тестування',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
