import {
  defaultColumns,
  defaultOrder,
  reqListerColumns,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = defaultColumns;

export const bfnGroupTypes = {
  label: 'Тип груп закладу дошкільної освіти',
  name: 'bfnGroupTypes',
  backendName: 'bfnGroupTypes',
  defaultOrder,
  columns,
  frontend: 'cat/bfnGroupTypes',
  listColumns: [reqListerColumns],
  minfinOnly: true,
};

export default bfnGroupTypes;
