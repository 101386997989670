import React, {
  useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import FilterView from './filterView';
import AvailableFields from '../available';
import NavButtons from '../navButtons';

function DCSFilter({
  filters,
  availables,
}) {
  const onDragEnd = useCallback(
    ({
      destination, draggableId, source,
    }) => {
      if (destination) {
        if (destination.droppableId === 'DC.Filter' && source.droppableId === 'DC.AvailableFields') {
          filters.onAdd(draggableId, destination.index);
        } else if (destination.droppableId === 'DC.AvailableFields' && source.droppableId === 'DC.Filter') {
          filters.onDelete(draggableId);
        }
      }
    },
    [filters],
  );
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Row noGutters className="overflow-hidden" style={{ height: '50vh' }}>
        <Col sm={4} className="d-flex align-items-center h-100">
          <AvailableFields
            items={availables.items}
            activeItem={availables.activeItem}
            selectHandler={availables.onActivate}
            choiceHandler={filters.onAdd}
            toggleHandler={availables.onToggle}
            openedItems={availables.openedItems}
          />
          <NavButtons
            canRight={!!availables.activeItem}
            canLeft={!!filters.activeItem}
            onRight={() => filters.onAdd(availables.activeItem)}
            onLeft={() => filters.onDelete(filters.activeItem)}
          />
        </Col>
        <Col className="h-100">
          <FilterView
            items={filters.items}
            selectHandler={filters.onActivate}
            toggleUseHandler={filters.onToggleUse}
            deleteHandler={filters.onDelete}
            filterChoice={filters.filterChoice}
            activeFilter={filters.activeItem}
            onChangeComparisson={filters.onChangeComparisson}
            onChangeValue={filters.onChangeValue}
          />
        </Col>
      </Row>
    </DragDropContext>
  );
}

DCSFilter.propTypes = {
  filters: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    filterChoice: PropTypes.shape({}), // параметры выбора
    onActivate: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    onToggleUse: PropTypes.func.isRequired,
    onChangeComparisson: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }).isRequired,
  availables: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onActivate: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    onToggle: PropTypes.func.isRequired, // Раскрыть / свернуть
    openedItems: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

};

export default memo(DCSFilter);
