import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { tablePropType } from '../hooks';

function SettingItem({
  setting, active, onRenameSetting, onClick,
}) {
  return (
    <ListGroupItem
      key={setting.key}
      active={active}
      onClick={onClick}
    >
      <TableStingInput
        controlId={`input-table-${setting.key}`}
        value={setting.name}
        readOnly={setting.name === 'main'}
        onChange={(e, v) => onRenameSetting(setting.name, v)}
      />
    </ListGroupItem>
  );
}

SettingItem.propTypes = {
  setting: tablePropType.isRequired,
  active: PropTypes.bool,
  onRenameSetting: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

SettingItem.defaultProps = {
  active: false,
};

export default SettingItem;
