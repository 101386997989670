import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, InputGroup, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { moneysStingOptions } from '../../../../../../constants/common';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/assignmentPlanning';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const tableMD = md.tables.yearKFB;

const QUARTERS = [...Array(4)].map((_, i) => i);

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, onSR,
  onlyYear, inBR, FI,
}) {
  const FIOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onSumChange = useCallback(
    (e, month, isBR, value) => {
      const colName = tableMD.columns[`Sum${isBR ? 'BR' : ''}${month}`].name;
      const totColName = tableMD.columns[`Sum${isBR ? 'BR' : ''}`].name;
      const tot = [...Array(12)].reduce(
        (R, _, i) => R + (row[tableMD.columns[`Sum${isBR ? 'BR' : ''}${i + 1}`].name] || 0),
        value - (row[colName] || 0),
      );
      onRowChange(e, {
        [colName]: value,
        [totColName]: tot,
      });
    },
    [onRowChange, row],
  );

  const onKFBChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.elementKFB.name]: v });
      await onSR('CHANGE_KFB_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );
  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={3}
        sm={12}
      >
        <TableRowCell column="elementKFB" highlighted={highlights.includes('elementKFB')}>
          <ItemPicker
            value={row[tableMD.columns.elementKFB.name]}
            modelType="cat"
            modelName="elementKFB"
            onChange={onKFBChange}
            filter={FIOwnerFilter}
            readOnly={readOnly}
            prepend={(

              <InputGroup.Text>
                {row[tableMD.columns.KFBCode.name]}
              </InputGroup.Text>

            )}
          />
        </TableRowCell>
      </Col>
      <Col xl={9} sm={12}>
        <Row xl={onlyYear ? 1 : 5} noGutters>
          {!onlyYear && QUARTERS.map((q) => (
            <Col md={3} key={q}>
              {[...Array(3)].map((_, m) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={m} sm={inBR ? 1 : 1} noGutters>
                  <Col as={TableRowCell} column={`Sum${q * 3 + m + 1}`} highlighted={highlights.includes(`Sum${q * 3 + m + 1}`)}>
                    <NumberInput
                      value={row[tableMD.columns[`Sum${q * 3 + m + 1}`].name]}
                      precision={2}
                      onChange={(e, v) => onSumChange(e, q * 3 + m + 1, false, v)}
                      readOnly={readOnly}
                    />
                  </Col>
                  {inBR && (
                    <Col as={TableRowCell} column={`SumBR${q * 3 + m + 1}`} highlighted={highlights.includes(`SumBR${q * 3 + m + 1}`)}>
                      <NumberInput
                        value={row[tableMD.columns[`SumBR${q * 3 + m + 1}`].name]}
                        precision={2}
                        // className="bg-light"
                        inputClassName="font-italic"
                        onChange={(e, v) => onSumChange(e, q * 3 + m + 1, true, v)}
                        readOnly={readOnly}
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </Col>
          ))}
          <Col md={12} className=" d-flex justify-content-center flex-row flex-xl-column">
            {onlyYear ? (
              <TableRowCell column="Sum1" highlighted={highlights.includes('Sum1')}>
                <NumberInput
                  value={row[tableMD.columns.Sum1.name]}
                  precision={2}
                  onChange={(e, v) => onSumChange(e, 1, false, v)}
                  readOnly={readOnly}
                />
              </TableRowCell>
            ) : (
              <div className="font-weight-bold w-100 text-right text-md-center">
                {row[tableMD.columns.Sum.name] && row[tableMD.columns.Sum.name].toLocaleString('uk', moneysStingOptions)}
              </div>
            )}
            {inBR && (
              <>
                {onlyYear ? (
                  <TableRowCell column="SumBR1" highlighted={highlights.includes('SumBR1')}>
                    <NumberInput
                      value={row[tableMD.columns.SumBR1.name]}
                      precision={2}
                      inputClassName="font-italic"
                      onChange={(e, v) => onSumChange(e, 1, true, v)}
                      readOnly={readOnly}
                    />
                  </TableRowCell>
                ) : (
                  <div className="font-weight-bold w-100 text-right text-md-center font-italic">
                    {row[tableMD.columns.SumBR.name] && row[tableMD.columns.SumBR.name].toLocaleString('uk', moneysStingOptions)}
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.elementKFB.name]: referencePropType,
    [tableMD.columns.KFBCode.name]: PropTypes.string,
    [tableMD.columns.Sum.name]: PropTypes.number,
    [tableMD.columns.SumBR.name]: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [tableMD.columns[`Sum${i + 1}`].name]: PropTypes.number,
      [tableMD.columns[`SumBR${i + 1}`].name]: PropTypes.number,
    }), {}),
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
  onlyYear: PropTypes.bool.isRequired,
  inBR: PropTypes.bool.isRequired,
  FI: referencePropType.isRequired,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
