import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Map } from 'immutable';
import { compose } from 'redux';
import BasicEditor, { mapState } from '../../dpEditor/basic';
import { dpEditor as actions } from '../../../actions/index';

// import Fields from '../../field';
import { CommandPanelButton } from '../../../components/button';

class changeToAssignPlanRevEditor extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    // headerForm: PropTypes.instanceOf(Map),
    // onOK: PropTypes.func,
    // isLoading: PropTypes.bool,
  };

  static defaultProps = {
    dispatch: () => {},
    // headerForm: null,
    // onOK: null,
    // isLoading: false,
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(actions.processServerReq('CLOSING'));
  }

  render() {
    return (
      <div>
        <CommandPanelButton content="Друк" color="blue" icon="print" />
        <CommandPanelButton content="Закрити" icon="close" />
      </div>
    );
  }
}
const enchance = compose(
  connect(mapState),
  BasicEditor(
    {
      type: 'dp',
      name: 'changeToAssignPlanRev',
    },
    {
      menu: () => null, // or yourHOC(BasicMenu)
      // tables, // Табличные части
    },
  ),
  // wasModified,
  // hasLoader,
);

export default enchance(changeToAssignPlanRevEditor);
