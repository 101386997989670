import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  FO: { label: 'ТекущийФинансовыйОрган', name: 'ТекущийФинансовыйОрган' },
  Budget: { label: 'ТекущийБюджет', name: 'ТекущийБюджет' },

};

/**
 * @const
 */
const definition = {
  name: 'publicDocumentsInLogica',
  backendName: '',
  frontend: 'dp/publicDocumentsInLogica',
  label: 'Опубліковані в "LOGIСA" документи',
  columns,
  // tables,
  resultColumns: { result: 'Результат' },
};

export default definition;
