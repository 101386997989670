import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'react-bootstrap';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const kvkProps = tableHooks.useStringInputProps('kvk', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const krkProps = tableHooks.useStringInputProps('krk', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kpkProps = tableHooks.useStringInputProps('kpk', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kekvProps = tableHooks.useStringInputProps('kekv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const trProps = tableHooks.useStringInputProps('tr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kfProps = tableHooks.useStringInputProps('kf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const kpProps = tableHooks.useNumberInputProps('kp', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const vkProps = tableHooks.useNumberInputProps('vk', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const vknpProps = tableHooks.useNumberInputProps('vknp', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const zknrProps = tableHooks.useNumberInputProps('zknr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kvProps = tableHooks.useNumberInputProps('kv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kvnpProps = tableHooks.useNumberInputProps('kvnp', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const zkrrProps = tableHooks.useNumberInputProps('zkrr', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const zfzProps = tableHooks.useNumberInputProps('zfz', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <>
      <Col
        md={4}
      >
        <Row noGutters>
          <Col md={2}>
            <TabelRowCell column="kvk" highlighted={highlights.includes('kvk')}>
              <TableControls.StringInput {...kvkProps} />
            </TabelRowCell>
          </Col>
          <Col md={2}>
            <TabelRowCell column="krk" highlighted={highlights.includes('krk')}>
              <TableControls.StringInput {...krkProps} />
            </TabelRowCell>
          </Col>
          <Col md={2}>
            <TabelRowCell column="kpk" highlighted={highlights.includes('kpk')}>
              <TableControls.StringInput {...kpkProps} />
            </TabelRowCell>
          </Col>
          <Col md={2}>
            <TabelRowCell column="kekv" highlighted={highlights.includes('kekv')}>
              <TableControls.StringInput {...kekvProps} />
            </TabelRowCell>
          </Col>
          <Col md={2}>
            <TabelRowCell column="tr" highlighted={highlights.includes('tr')}>
              <TableControls.StringInput {...trProps} />
            </TabelRowCell>
          </Col>
          <Col md={2}>
            <TabelRowCell column="kf" highlighted={highlights.includes('kf')}>
              <TableControls.StringInput {...kfProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
      <Col md={8}>
        <Row noGutters md={8}>
          <Col>
            <TabelRowCell column="kp" highlighted={highlights.includes('kp')}>
              <TableControls.NumberInput {...kpProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="vk" highlighted={highlights.includes('vk')}>
              <TableControls.NumberInput {...vkProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="vknp" highlighted={highlights.includes('vknp')}>
              <TableControls.NumberInput {...vknpProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="zknr" highlighted={highlights.includes('zknr')}>
              <TableControls.NumberInput {...zknrProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="kv" highlighted={highlights.includes('kv')}>
              <TableControls.NumberInput {...kvProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="kvnp" highlighted={highlights.includes('kvnp')}>
              <TableControls.NumberInput {...kvnpProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="zkrr" highlighted={highlights.includes('zkrr')}>
              <TableControls.NumberInput {...zkrrProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="zfz" highlighted={highlights.includes('zfz')}>
              <TableControls.NumberInput {...zfzProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>

    </>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
