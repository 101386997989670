import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R18G3: { label: 'R01G3', name: 'R76G3' },
  R18G4: { label: 'R01G3', name: 'R76G4' },
  R18G5: { label: 'R01G3', name: 'R76G5' },
  R18G6: { label: 'R01G3', name: 'R76G6' },
  R18G7: { label: 'R01G3', name: 'R76G7' },
  R29G3: { label: 'R01G3', name: 'R77G3' },
  R29G4: { label: 'R01G3', name: 'R77G4' },
  R29G5: { label: 'R01G3', name: 'R77G5' },
  R29G6: { label: 'R01G3', name: 'R77G6' },
  R29G7: { label: 'R01G3', name: 'R77G7' },
  R30G3: { label: 'R01G3', name: 'R78G3' },
  R30G4: { label: 'R01G3', name: 'R78G4' },
  R30G5: { label: 'R01G3', name: 'R78G5' },
  R30G6: { label: 'R01G3', name: 'R78G6' },
  R30G7: { label: 'R01G3', name: 'R78G7' },
  R28G3: { label: 'R01G3', name: 'R79G3' },
  R28G4: { label: 'R01G3', name: 'R79G4' },
  R28G5: { label: 'R01G3', name: 'R79G5' },
  R28G6: { label: 'R01G3', name: 'R79G6' },
  R28G7: { label: 'R01G3', name: 'R79G7' },
  R80G3: { label: 'R01G3', name: 'R80G3' },
  R80G4: { label: 'R01G3', name: 'R80G4' },
  R80G5: { label: 'R01G3', name: 'R80G5' },
  R80G6: { label: 'R01G3', name: 'R80G6' },
  R80G7: { label: 'R01G3', name: 'R80G7' },
  R81G3: { label: 'R01G3', name: 'R81G3' },
  R81G4: { label: 'R01G3', name: 'R81G4' },
  R81G5: { label: 'R01G3', name: 'R81G5' },
  R81G6: { label: 'R01G3', name: 'R81G6' },
  R81G7: { label: 'R01G3', name: 'R81G7' },
  R82G3: { label: 'R01G3', name: 'R82G3' },
  R82G4: { label: 'R01G3', name: 'R82G4' },
  R82G5: { label: 'R01G3', name: 'R82G5' },
  R82G6: { label: 'R01G3', name: 'R82G6' },
  R82G7: { label: 'R01G3', name: 'R82G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd4Printing',
  backendName: 'ПечатьПрогнозДод4',
  label: 'Показники місцевого боргу (Додаток 4)',
  frontend: 'dp/forecastAdd4Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
