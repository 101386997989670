import React, {
  memo, useCallback, useMemo,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/prepareForecastAdd4';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { referencePropType } from '../../../../../newEditor/propTypes';
import { comparisonTypes, selectionMode } from '../../../../../../constants/meta/common';

const tablename = 'tar';

const tableMD = md.tables[tablename];

function TPRow({
  row, active, rowIndex, onChange, activeCol, readOnly, highlights, usePCMCatalog,
}) {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const modelName = usePCMCatalog ? 'indicators' : 'indicatorsForTarget';

  const indicatorsFilter = useMemo(() => [
    { fieldName: 'Владелец', value: row[tableMD.columns.task.name] },
    {
      comparisonType: comparisonTypes.equal,
      value: false,
    },
  ], [row]);

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="tar" highlighted={highlights.includes('tar')}>
              <ItemPicker
                value={row[tableMD.columns.tar.name]}
                modelType="cat"
                modelName="objectivesStatePolicy"
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.tar.name]: v })}
                readOnly={readOnly}
                choiceSettings={selectionMode.items}
                required
              />
            </TableRowCell>
          </Col>
          {usePCMCatalog && (
            <Col className="border-right">
              <TableRowCell column="task" highlighted={highlights.includes('task')}>
                <ItemPicker
                  value={row[tableMD.columns.task.name]}
                  modelType="cat"
                  modelName="tasks"
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.task.name]: v })}
                  readOnly={readOnly}
                  choiceSettings={selectionMode.items}
                  required
                />
              </TableRowCell>
            </Col>
          )}
          {usePCMCatalog ? (
            <Col className="border-right">
              <TableRowCell column="indicator" highlighted={highlights.includes('indicator')}>
                <ItemPicker
                  value={row[tableMD.columns.indicator.name]}
                  modelType="cat"
                  modelName={modelName}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.indicator.name]: v })}
                  readOnly={readOnly}
                  filter={indicatorsFilter}
                  choiceSettings={selectionMode.items}
                  required
                />
              </TableRowCell>
            </Col>
          ) : (
            <Col className="border-right">
              <TableRowCell column="indicator" highlighted={highlights.includes('indicator')}>
                <ItemPicker
                  value={row[tableMD.columns.indicator.name]}
                  modelType="cat"
                  modelName={modelName}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.indicator.name]: v })}
                  readOnly={readOnly}
                  choiceSettings={selectionMode.items}
                  required
                />
              </TableRowCell>
            </Col>
          )}
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf0" highlighted={highlights.includes('zf0')}>
              <NumberInput
                value={row[tableMD.columns.zf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf0" highlighted={highlights.includes('sf0')}>
              <NumberInput
                value={row[tableMD.columns.sf0.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf0.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf1" highlighted={highlights.includes('zf1')}>
              <NumberInput
                value={row[tableMD.columns.zf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf1" highlighted={highlights.includes('sf1')}>
              <NumberInput
                value={row[tableMD.columns.sf1.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf1.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
        <Row sm={1} className="w-100" noGutters>
          <Col className="border-right">
            <TableRowCell column="zf2" highlighted={highlights.includes('zf2')}>
              <NumberInput
                value={row[tableMD.columns.zf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.zf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
          <Col className="border-right">
            <TableRowCell column="sf2" highlighted={highlights.includes('sf2')}>
              <NumberInput
                value={row[tableMD.columns.sf2.name]}
                precision={2}
                onChange={(e, v) => onRowChange(e, { [tableMD.columns.sf2.name]: v })}
                readOnly={readOnly}
              />
            </TableRowCell>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
}

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.sf2.name]: PropTypes.number,
    [tableMD.columns.zf2.name]: PropTypes.number,
    [tableMD.columns.zf1.name]: PropTypes.number,
    [tableMD.columns.sf1.name]: PropTypes.number,
    [tableMD.columns.zf0.name]: PropTypes.number,
    [tableMD.columns.sf0.name]: PropTypes.number,
    [tableMD.columns.tar.name]: referencePropType,
    [tableMD.columns.task.name]: referencePropType,
    [tableMD.columns.indicator.name]: referencePropType,
  }).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  usePCMCatalog: PropTypes.bool,
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  usePCMCatalog: false,
};

export default memo(TPRow);
