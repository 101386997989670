// eslint-disable-next-line import/no-cycle
import ShortKekvLister from './lister';
import ShortKekvSelector from './selector';

const shortkekv = {
  lister: ShortKekvLister,
  selector: ShortKekvSelector,
};

export default shortkekv;
