import { baseReportColumns } from './_base';

/**
 * @const
 */
const columns = {
  ...baseReportColumns,
  Document: { label: 'Документ', name: 'ДокументСсылка' },
  FO: { label: 'Фінансовий орган', name: 'ФинансовыйОрган' },
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  SignVariant: { label: 'Варіант підпису', name: 'ВариантПодписи' },
  boss: { label: 'Керівние', name: 'Руководитель' },
  fin: { label: 'Фінансист', name: 'Финансист' },
  proj: { label: 'Варіант прогнозу для відправки в ЛОГІКА', name: 'Прогноз' },
  R01G3: { label: 'R01G3', name: 'R58G3' },
  R01G4: { label: 'R01G3', name: 'R58G4' },
  R01G5: { label: 'R01G3', name: 'R58G5' },
  R01G6: { label: 'R01G3', name: 'R58G6' },
  R01G7: { label: 'R01G3', name: 'R58G7' },
  R02G3: { label: 'R01G3', name: 'R59G3' },
  R02G4: { label: 'R01G3', name: 'R59G4' },
  R02G5: { label: 'R01G3', name: 'R59G5' },
  R02G6: { label: 'R01G3', name: 'R59G6' },
  R02G7: { label: 'R01G3', name: 'R59G7' },
  R03G3: { label: 'R01G3', name: 'R60G3' },
  R03G4: { label: 'R01G3', name: 'R60G4' },
  R03G5: { label: 'R01G3', name: 'R60G5' },
  R03G6: { label: 'R01G3', name: 'R60G6' },
  R03G7: { label: 'R01G3', name: 'R60G7' },
  R04G3: { label: 'R01G3', name: 'R61G3' },
  R04G4: { label: 'R01G3', name: 'R61G4' },
  R04G5: { label: 'R01G3', name: 'R61G5' },
  R04G6: { label: 'R01G3', name: 'R61G6' },
  R04G7: { label: 'R01G3', name: 'R61G7' },
  R05G3: { label: 'R01G3', name: 'R62G3' },
  R05G4: { label: 'R01G3', name: 'R62G4' },
  R05G5: { label: 'R01G3', name: 'R62G5' },
  R05G6: { label: 'R01G3', name: 'R62G6' },
  R05G7: { label: 'R01G3', name: 'R62G7' },
  R06G3: { label: 'R01G3', name: 'R63G3' },
  R06G4: { label: 'R01G3', name: 'R63G4' },
  R06G5: { label: 'R01G3', name: 'R63G5' },
  R06G6: { label: 'R01G3', name: 'R63G6' },
  R06G7: { label: 'R01G3', name: 'R63G7' },
  R07G3: { label: 'R01G3', name: 'R64G3' },
  R07G4: { label: 'R01G3', name: 'R64G4' },
  R07G5: { label: 'R01G3', name: 'R64G5' },
  R07G6: { label: 'R01G3', name: 'R64G6' },
  R07G7: { label: 'R01G3', name: 'R64G7' },
  R08G3: { label: 'R01G3', name: 'R65G3' },
  R08G4: { label: 'R01G3', name: 'R65G4' },
  R08G5: { label: 'R01G3', name: 'R65G5' },
  R08G6: { label: 'R01G3', name: 'R65G6' },
  R08G7: { label: 'R01G3', name: 'R65G7' },
  R09G3: { label: 'R01G3', name: 'R66G3' },
  R09G4: { label: 'R01G3', name: 'R66G4' },
  R09G5: { label: 'R01G3', name: 'R66G5' },
  R09G6: { label: 'R01G3', name: 'R66G6' },
  R09G7: { label: 'R01G3', name: 'R66G7' },
  R10G3: { label: 'R01G3', name: 'R67G3' },
  R10G4: { label: 'R01G3', name: 'R67G4' },
  R10G5: { label: 'R01G3', name: 'R67G5' },
  R10G6: { label: 'R01G3', name: 'R67G6' },
  R10G7: { label: 'R01G3', name: 'R67G7' },
  R11G3: { label: 'R01G3', name: 'R68G3' },
  R11G4: { label: 'R01G3', name: 'R68G4' },
  R11G5: { label: 'R01G3', name: 'R68G5' },
  R11G6: { label: 'R01G3', name: 'R68G6' },
  R11G7: { label: 'R01G3', name: 'R68G7' },
  R12G3: { label: 'R01G3', name: 'R69G3' },
  R12G4: { label: 'R01G3', name: 'R69G4' },
  R12G5: { label: 'R01G3', name: 'R69G5' },
  R12G6: { label: 'R01G3', name: 'R69G6' },
  R12G7: { label: 'R01G3', name: 'R69G7' },
  R13G3: { label: 'R01G3', name: 'R70G3' },
  R13G4: { label: 'R01G3', name: 'R70G4' },
  R13G5: { label: 'R01G3', name: 'R70G5' },
  R13G6: { label: 'R01G3', name: 'R70G6' },
  R13G7: { label: 'R01G3', name: 'R70G7' },
  R14G3: { label: 'R01G3', name: 'R71G3' },
  R14G4: { label: 'R01G3', name: 'R71G4' },
  R14G5: { label: 'R01G3', name: 'R71G5' },
  R14G6: { label: 'R01G3', name: 'R71G6' },
  R14G7: { label: 'R01G3', name: 'R71G7' },
  R15G3: { label: 'R01G3', name: 'R72G3' },
  R15G4: { label: 'R01G3', name: 'R72G4' },
  R15G5: { label: 'R01G3', name: 'R72G5' },
  R15G6: { label: 'R01G3', name: 'R72G6' },
  R15G7: { label: 'R01G3', name: 'R72G7' },
  R16G3: { label: 'R01G3', name: 'R73G3' },
  R16G4: { label: 'R01G3', name: 'R73G4' },
  R16G5: { label: 'R01G3', name: 'R73G5' },
  R16G6: { label: 'R01G3', name: 'R73G6' },
  R16G7: { label: 'R01G3', name: 'R73G7' },
  R17G3: { label: 'R01G3', name: 'R74G3' },
  R17G4: { label: 'R01G3', name: 'R74G4' },
  R17G5: { label: 'R01G3', name: 'R74G5' },
  R17G6: { label: 'R01G3', name: 'R74G6' },
  R17G7: { label: 'R01G3', name: 'R74G7' },
  R18G3: { label: 'R01G3', name: 'R75G3' },
  R18G4: { label: 'R01G3', name: 'R75G4' },
  R18G5: { label: 'R01G3', name: 'R75G5' },
  R18G6: { label: 'R01G3', name: 'R75G6' },
  R18G7: { label: 'R01G3', name: 'R75G7' },
};

/**
 * @const
 */
const definition = {
  name: 'forecastAdd3Printing',
  backendName: 'ПечатьПрогнозДод3',
  label: 'Показники фінансування бюджету (Додаток 3)',
  frontend: 'dp/forecastAdd3Printing',
  columns,
  resultColumns: { result: 'Результат' },
};

export default definition;
