import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

function Header({
  activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow>
      <Col>
        <Row noGutters>
          <Col lg={6}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'normative_act'}
              highlighted={highlights.includes('normative_act')}
              onToggleHighlight={() => onToggleHighlight('normative_act')}
            >
              Нормативний акт
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'law_item'}
              highlighted={highlights.includes('law_item')}
              onToggleHighlight={() => onToggleHighlight('law_item')}
            >
              Перелік відповідних статей (пунктів) приведеного документа
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'kekv'}
              highlighted={highlights.includes('kekv')}
              onToggleHighlight={() => onToggleHighlight('kekv')}
            >
              КЕКВ
            </TableHeaderCell>
          </Col>

          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_limit'}
              highlighted={highlights.includes('amount_limit')}
              onToggleHighlight={() => onToggleHighlight('amount_limit')}
            >
              Граничний обсяг
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_required'}
              highlighted={highlights.includes('amount_required')}
              onToggleHighlight={() => onToggleHighlight('amount_required')}
            >
              Необхідна сума
            </TableHeaderCell>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_unsecured'}
              highlighted={highlights.includes('amount_unsecured')}
              onToggleHighlight={() => onToggleHighlight('amount_unsecured')}
            >
              Не забезпечені видатки
            </TableHeaderCell>
          </Col>

          <Col lg={3}>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'action_description'}
              highlighted={highlights.includes('action_description')}
              onToggleHighlight={() => onToggleHighlight('action_description')}
            >
              Необхідні заходи
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
