import base from './base';

/**
 * @const
 */

const columns = {
  ...base.columns,
  objectBuilding: { label: 'Об\'єкт будівництва', name: 'ОбъектСтроительства', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: false },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: false },
  author: { label: 'Автор', name: 'Автор', visible: true },
  kbp: { label: 'КБП', name: 'КБП', visible: true },
  finSource: { label: 'Джерело фінансування', name: 'ИсточникиФинансирования', visible: false },
  year: { label: 'Рік', name: 'Год', visible: true },
  isApproved: { label: 'Затверджений', name: 'ДокументУтвержден', visible: true },
  beginningDate: { label: 'Дата початку будівництва (реконструкції)', name: 'ДатаНачалаСтроительства', visible: false },
  endingDate: { label: 'Дата закінчення будівництва (реконструкції)', name: 'ДатаОкончанияСтроительства', visible: false },
  sumEstimates: { label: 'Кошторисна вартість згідно з ПКД', name: 'Кошторис', visible: false },
  totalSum: { label: 'Всього', name: 'СуммаВсего', visible: false },
  yearSum: { label: 'в т. ч.  поточний рік', name: 'СуммаГод', visible: false },
  nextYearSum: { label: 'Інвестиційна пропозиція на поточний рік', name: 'СуммаСледующийГод', visible: false },
  nextYearCriticalSum: { label: 'Граничний обсяг видатків на поточний рік', name: 'СуммаСледующийГодГраница', visible: false },
  nextYearAddSum: { label: 'Додаткова потреба на поточний рік', name: 'СуммаСледующийГодДополнительно', visible: false },
  predictionSum1: { label: 'СуммаПрогноз1', name: 'СуммаПрогноз1', visible: false },
  predictionSum2: { label: 'СуммаПрогноз2', name: 'СуммаПрогноз2', visible: false },
  deadlines: { label: 'Сроки видачі та затвердження ПКД', name: 'Сроки', visible: false },
  predictionCriticalSum1: { label: 'СуммаПрогноз1Гран', name: 'СуммаПрогноз1Гран', visible: false },
  predictionCriticalSum2: { label: 'СуммаПрогноз2Гран', name: 'СуммаПрогноз2Гран', visible: false },
  predictionAddSum1: { label: 'СуммаПрогноз1Дополнительно', name: 'СуммаПрогноз1Дополнительно', visible: false },
  predictionAddSum2: { label: 'СуммаПрогноз2Дополнительно', name: 'СуммаПрогноз2Дополнительно', visible: false },
  flClosedDoc: { label: 'Документ закрито', name: 'флЗакрытыйДок', visible: false },
  projectVariant: { label: 'Варіант проекту', name: 'ВариантПроекта', visible: false },
  isOdessaMode: { label: 'РежимОдесса', name: 'РежимОдесса', visible: false },
};

/**
 * @const
 */
const tables = {
  general: {
    name: 'Основной',
    label: 'Основной',
    columns: {
      objBuilding: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      finSource: { label: 'Джерело фінансування', name: 'ИсточникиФинансирования' },
      estimateSum: { label: 'Кошторисна вартість', name: 'Кошторис' },
      executionSum: { label: 'всього', name: 'ИсполнениеВсего' },
      deadlines: { label: 'Сроки видачі та затвердження ПКД', name: 'Сроки' },
      contractor: { label: 'Генпідрядна організація', name: 'ПодрядчикТекст' },
      designer: { label: 'Проектна організація', name: 'ПроектантТекст' },
      beginningDate: { label: 'Дата початку будівництва (реконструкції)', name: 'ДатаНачалаСтроительства' },
      endingDate: { label: 'Дата закінчення будівництва (реконструкції)', name: 'ДатаОкончанияСтроительства' },
      yearSum: { label: 'в т. ч.  поточний рік', name: 'СуммаГод' },
      nextYearSum: { label: 'Програма на наступний  рік', name: 'СуммаСледующийГод' },
      totalSum: { label: 'Всього', name: 'СуммаВсего' },
      nextYearCriticalSum: { label: 'Граничний обсяг видатків на поточний рік', name: 'СуммаСледующийГодГраница' },
      nextYearAddSum: { label: 'Додаткова потреба на поточний рік', name: 'СуммаСледующийГодДополнительно' },
      predictionSum1: { label: 'СуммаПрогноз1', name: 'СуммаПрогноз1' },
      predictionSum2: { label: 'СуммаПрогноз2', name: 'СуммаПрогноз2' },
    },
  },
  pvr: {
    name: 'ПВР',
    label: 'ПВР',
    columns: {
      PVR: { label: 'ПВР', name: 'ПВР' },
      sum: { label: 'Всього', name: 'Сумма' },
      yearSum: { label: 'У т.ч. минулий рік', name: 'СуммаГод' },
      finSource: { label: 'Джерело фінансування (капітальні вкладення)', name: 'Источник' },
      nextYearSum: { label: 'Інвестиційна пропозиція на поточний рік', name: 'СуммаСледующийГод' },
      estimateSum: { label: 'Кошторисна вартість', name: 'Кошторис' },
      nextYearCriticalSum: { label: 'Граничний обсяг видатків на поточний рік', name: 'СуммаСледующийГодГраница' },
      nextYearAddSum: { label: 'Додаткова потреба на поточний рік', name: 'СуммаСледующийГодДополнительно' },
    },
  },
  power: {
    name: 'Мощности',
    label: 'Потужність',
    columns: {
      power: { label: 'Потужність', name: 'Мощность' },
      units: { label: 'Одиниця виміру', name: 'ЕдИзм' },
      sum: { label: 'Всього', name: 'Сумма' },
    },
  },
  buildingState: {
    name: 'СостояниеСтроительства',
    label: 'Стан будівництва',
    columns: {
      year: { label: 'Рік', name: 'Год' },
      rate: { label: 'Відсоток готовності', name: 'ПроцентГотовности' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Документ "Інвестиційна пропозиція по об\'єктам будівництва/ремонту"',
  name: 'investmentProposalObjects',
  backendName: 'ОбъектыСтроительстваПропозиция',
  columns,
  frontend: 'doc/investmentProposalObjects',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'kbp',
      'objectBuilding',
      'budget',
      'FI',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
