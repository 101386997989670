import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: 'HmftfJrn-TU',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  year: { label: 'Рік прогнозування', name: 'ГодПрогноз', visible: true },
};

/**
 * @const
 */
const tables = {
  incomes: {
    name: 'Доходы',
    label: 'Надходження бюджету розвитку',
    columns: {
      incomesGF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыЗФ_Отчет' },
      incomesGF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыЗФ_Утверждено' },
      incomesGF_Plan: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План' },
      incomesGF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План1' },
      incomesGF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыЗФ_План2' },
      incomesSF_Report: { label: '20__ рік  (звіт)', name: 'ДоходыСФ_Отчет' },
      incomesSF_Approved: { label: '20__ рік  (затверджено)', name: 'ДоходыСФ_Утверждено' },
      incomesSF_Plan: { label: '20__ рік  (план)', name: 'ДоходыСФ_План' },
      incomesSF_Plan1: { label: '20__ рік  (план)', name: 'ДоходыСФ_План1' },
      incomesSF_Plan2: { label: '20__ рік  (план)', name: 'ДоходыСФ_План2' },
      indicator: { label: 'Найменування показника', name: 'Показатель' },
      indicatorNum: { label: 'Номер показника', name: 'ПоказательНомер' },
      indicatorGroup: { label: 'Показатель группирующий', name: 'ПоказательГруппирующий' },
    },
  },
  expenses: {
    name: 'Расходы',
    label: 'Витрати бюджету розвитку',
    columns: {
      expensesGF_Report: { label: '20__ рік  (звіт)', name: 'РасходыЗФ_Отчет' },
      expensesGF_Approved: { label: '20__ рік  (затверджено)', name: 'РасходыЗФ_Утверждено' },
      expensesGF_Plan: { label: '20__ рік  (план)', name: 'РасходыЗФ_План' },
      expensesGF_Plan1: { label: '20__ рік  (план)', name: 'РасходыЗФ_План1' },
      expensesGF_Plan2: { label: '20__ рік  (план)', name: 'РасходыЗФ_План2' },
      expensesSF_Report: { label: '20__ рік  (звіт)', name: 'РасходыСФ_Отчет' },
      expensesSF_Approved: { label: '20__ рік  (затверджено)', name: 'РасходыСФ_Утверждено' },
      expensesSF_Plan: { label: '20__ рік  (план)', name: 'РасходыСФ_План' },
      expensesSF_Plan1: { label: '20__ рік  (план)', name: 'РасходыСФ_План1' },
      expensesSF_Plan2: { label: '20__ рік  (план)', name: 'РасходыСФ_План2' },
      indicator: { label: 'Найменування показника', name: 'Показатель' },
      indicatorNum: { label: 'Номер показника', name: 'ПоказательНомер' },
      indicatorGroup: { label: 'Показатель группирующий', name: 'ПоказательГруппирующий' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Показники бюджету розвитку  (Додаток 9)',
  name: 'forecastAdd9',
  backendName: 'ПрогнозДод9',
  columns,
  frontend: 'doc/forecastAdd9',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'year',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
