import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import { DocumentBody, Footer } from '../../../../components/Containers/docs';
import useEditor from '../../../newEditor/hook/editor';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import useEditorParams from '../../../newEditor/hook/params';
import DisposersMapRows from './tp/rowSet';
import DBFButton from '../DBFButton';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    // eslint-disable-next-line no-unused-vars
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.disposersMap.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const kbp7Props = editorHooks.useItemInputProps('kbp7', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantProps = editorHooks.useItemInputProps('authority_sign_variant', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const kbp7Filter = useMemo(
    () => ({ budget: data.budget ? data.budget.id : null }),
    [data.budget],
  );
  const signVariantFilter = useMemo(
    () => ({ disposer: data.authority ? data.authority.id : null }),
    [data.authority],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          backendURL={meta.doc.disposersMap.backendURL}
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          id={id}
          repr={data.repr}
        >
          <DBFButton instanceId={data.id} />
        </DocCommandPanel>
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid className="p-0">
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...kbp7Props} filter={kbp7Filter} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...signVariantProps} filter={signVariantFilter} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="rowset">
          {fields && fields.rows && (
            <Tab eventKey="rowset" title={fields.rows.label}>
              <TabContainer>
                <DisposersMapRows
                  fields={fields}
                  data={data}
                  onChange={actions.onChange}
                  onDraft={actions.onDraft}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>

  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};
export default Editor;
