import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown, faArrowUp, faTimes, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {
  ListGroup, Modal, ModalBody, ModalTitle, Overlay, Popover,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { CPButton } from '../../../../components/bootStrap/buttons/styles';

function GroupEditor({
  ctxMenuopened, ctxMenu, editDialog,
  onCloseCtxMenu,
  onDeleteItem, onEditItem,
  onCloseEditDialog, subItems,
  onRemoveSubGroup, onUpSubgroup, onDownSubgroup,
}) {
  return (
    <>
      <Overlay
        placement="bottom-start"
        show={ctxMenuopened}
        target={ctxMenu.node}
        onHide={onCloseCtxMenu}
        rootClose
      >
        <Popover id="popover-contained">
          <Popover.Body className="p-0">
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => {
                  onEditItem(ctxMenu.node.dataset.name);
                  onCloseCtxMenu();
                }}
              >
                <FontAwesomeIcon icon={faEdit} className="me-1" />
                Редагувати
              </ListGroup.Item>
              <ListGroup.Item
                action
                variant="danger"
                onClick={() => {
                  onDeleteItem(ctxMenu.node.dataset.name);
                  onCloseCtxMenu();
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="me-1" />
                Видалити
              </ListGroup.Item>
            </ListGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Modal show={editDialog.opened} onHide={onCloseEditDialog}>
        <ModalHeader>
          <ModalTitle>Редагування группування</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            {subItems.map((item, i) => (
              <ListGroup.Item action key={item.name} className="d-flex">
                <span className="flex-fill">
                  {item.label}
                </span>
                <div>
                  <CPButton
                    disabled={i === 0}
                    onClick={() => onUpSubgroup(i)}
                    icon={faArrowUp}
                  />
                  <CPButton
                    disabled={i === subItems.length - 1}
                    onClick={() => onDownSubgroup(i)}
                    icon={faArrowDown}
                  />
                  <CPButton
                    variant="warning"
                    disabled={subItems.length === 1}
                    onClick={() => onRemoveSubGroup(item.name)}
                    icon={faTimes}
                  />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </ModalBody>
      </Modal>
    </>
  );
}

GroupEditor.propTypes = {
  ctxMenuopened: PropTypes.bool.isRequired,
  ctxMenu: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    node: PropTypes.node,
  }).isRequired,
  onCloseCtxMenu: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  editDialog: PropTypes.shape({
    opened: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  onCloseEditDialog: PropTypes.func.isRequired,
  subItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    errored: PropTypes.bool,
    useHierarchy: PropTypes.bool,
    canHierarchy: PropTypes.bool,
  })).isRequired,
  onRemoveSubGroup: PropTypes.func.isRequired,
  onUpSubgroup: PropTypes.func.isRequired,
  onDownSubgroup: PropTypes.func.isRequired,
};

export default GroupEditor;
