import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EditorHOC, { mapState, getRouterEditor } from '../basic';
import md, { proposalDeputies as def } from '../../../constants/meta/infoRegs/proposalDeputies';
import { editor as actions, editor as editorActions } from '../../../actions';
import { catEditorMenu as BasicMenu } from '../../../components/editorMenu';
import GetTextField from '../../field/TextInput';
import TextAreaField from '../../field/TextArea';
import {
  StyledGreyLabel,
  StyledLabel,
  SubText,
  ThreeRow,
  MyContainerFormWithNColumns,
  TwoColumnsGridContainer,
  TwoColGridContainer,
} from '../../../components/Form/styledForm';

import SelectorField from '../../field/stdSelectorField';
import DateInput from '../../../components/fields/dates';
import { DivStyled } from '../../field/selectorField/item/styles';
import GetNumberField from '../../field/NumberInput';
import { SumInputStyled } from '../../../components/styledTable/styledTable';

const TextField255 = GetTextField(255);
const YearInput = GetNumberField(4, 0, false);

function ProposalDeputiesEditor({ headerForm, dispatch, sessionOptions }) {
  const headerUpdater = (...path) => (e, value) => {
    dispatch(actions.changeField(['headerForm', ...path], value));
  };
  return (
    <div>
      <TwoColumnsGridContainer>
        {sessionOptions.get('is_admin', false) ? (
          <div>
            <StyledLabel>{md.columns.budget.label}</StyledLabel>
            <SelectorField
              value={headerForm.get('budget', new Map())}
              modelType="cat"
              modelName="budget"
              onChange={(e, value) => {
                dispatch(actions.changeField(['headerForm', 'budget'], value));
              }}
            />
          </div>
        ) : (
          <StyledGreyLabel>
            {md.columns.budget.label}
            :
            {' '}
            <SubText>
              {' '}
              {headerForm.getIn(['budget', 'repr'], '')}
            </SubText>
          </StyledGreyLabel>
        )}
        <TwoColGridContainer>
          <div>
            <StyledLabel>{md.columns.inputDate.label}</StyledLabel>
            <DateInput value={headerForm.get('inputDate', null)} onChange={headerUpdater('inputDate')} />
          </div>
          <div>
            <StyledLabel>{md.columns.year.label}</StyledLabel>
            <DivStyled>
              <YearInput
                value={headerForm.get('year', 5)}
                onChange={headerUpdater('year')}
                disableCalculator
              />
            </DivStyled>
          </div>
        </TwoColGridContainer>
      </TwoColumnsGridContainer>
      <TwoColumnsGridContainer>
        <div>
          <StyledLabel>{def.columns.deputies.label}</StyledLabel>
          <SelectorField
            value={headerForm.get('deputies', new Map())}
            modelType="cat"
            modelName="deputies"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'deputies'], value));
            }}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.kfk.label}</StyledLabel>
          <SelectorField
            value={headerForm.get('kfk', new Map())}
            modelType="cat"
            modelName="kfkvkClassifier"
            onChange={(e, value) => {
              dispatch(actions.changeField(['headerForm', 'kfk'], value));
            }}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.name.label}</StyledLabel>
          <TextField255
            background
            border
            radius
            textLeft
            value={headerForm.get('name', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'name'], value))}
          />
        </div>
      </TwoColumnsGridContainer>

      <MyContainerFormWithNColumns columns={3}>
        <div>
          <StyledLabel>{def.columns.proposalFull.label}</StyledLabel>
          <TextAreaField
            rows="10"
            value={headerForm.get('proposalFull', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'proposalFull'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.diagnosis.label}</StyledLabel>
          <TextAreaField
            rows="10"
            value={headerForm.get('diagnosis', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'diagnosis'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.resolution.label}</StyledLabel>
          <TextAreaField
            rows="10"
            value={headerForm.get('resolution', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'resolution'], value))}
          />
        </div>

        <div>
          <StyledLabel>{def.columns.SumDep.label}</StyledLabel>
          <SumInputStyled
            background
            border
            radius
            value={headerForm.get('SumDep', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'SumDep'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.SumFin.label}</StyledLabel>
          <SumInputStyled
            background
            border
            radius
            value={headerForm.get('SumFin', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'SumFin'], value))}
          />
        </div>
        <div>
          <StyledLabel>{def.columns.SumPK.label}</StyledLabel>
          <SumInputStyled
            background
            border
            radius
            value={headerForm.get('SumPK', '')}
            onChange={(e, value) => dispatch(editorActions.changeField(['headerForm', 'SumPK'], value))}
          />
        </div>
      </MyContainerFormWithNColumns>
      <ThreeRow />
    </div>
  );
}

ProposalDeputiesEditor.propTypes = {
  headerForm: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionOptions: PropTypes.instanceOf(Map),
};

ProposalDeputiesEditor.defaultProps = {
  sessionOptions: new Map(),
};

const enhance = compose(
  connect(mapState),
  EditorHOC(
    {
      type: 'infoRegs',
      name: 'proposalDeputies',
    },
    {
      menu: BasicMenu,
    },
  ),
);

export const StdEditor = enhance(ProposalDeputiesEditor);

export default getRouterEditor(def)(StdEditor);
