import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function DateTimeLabel({ value, displayFormat, className }) {
  const dValue = useMemo(
    () => new Date(value),
    [value],
  );
  const displayValue = useMemo(
    () => {
      if (!value) return '';
      switch (displayFormat) {
        case 'short':
          return dValue.toLocaleDateString('uk', {
            day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit',
          });
        case 'long':
          return dValue.toLocaleDateString('uk', {
            day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit',
          });
        default:
          return 'unsipported format';
      }
    },
    [dValue, displayFormat, value],
  );
  return (<span className={className}>{displayValue}</span>);
}

DateTimeLabel.propTypes = {
  value: PropTypes.string,
  displayFormat: PropTypes.oneOf(['short', 'long']),
  className: PropTypes.string,
};

DateTimeLabel.defaultProps = {
  value: null,
  displayFormat: 'short',
  className: '',
};

export default DateTimeLabel;
