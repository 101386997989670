import ListerFactory from '../../../newLister';
import { InfoRegTableListerFactory } from '../../../newLister/tableComponent';

const KBPElementRelationLister = ListerFactory({
  modelType: 'infoRegs',
  modelName: 'kbpElemRelation',
  viewType: 'lister',
})(InfoRegTableListerFactory({
  // CommandPanel,
}));

export default KBPElementRelationLister;
