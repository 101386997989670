import attachedFilesEditor from './attachedFiles';
import proposalDeputiesEditor from './proposalDeputies/editor';
import proposalDeputiesLister from './proposalDeputies/lister';

import kbpElemRelationEditor from './kbpElemRelation/editor';
import kbpElemRelationLister from './kbpElemRelation/lister';
import kbp7ElemRelationEditor from './kbp7ElemRelation/editor';
import kbp7ElemRelationLister from './kbp7ElemRelation/lister';
import kfkvkElemRelationEditor from './kfkvkElemRelation/editor';
import kfkvkElemRelationLister from './kfkvkElemRelation/lister';
import kbp7ElemBetweenEditor from './kbp7ElemBetween/editor';
import kbp7ElemBetweenLister from './kbp7ElemBetween/lister';

const infoRegsComponents = {
  attachedFiles: {
    editor: attachedFilesEditor,
    lister: () => null,
  },
  proposalDeputies: {
    editor: proposalDeputiesEditor,
    lister: proposalDeputiesLister,
  },
  kbpElemRelation: {
    editor: kbpElemRelationEditor,
    lister: kbpElemRelationLister,
  },
  kbp7ElemRelation: {
    editor: kbp7ElemRelationEditor,
    lister: kbp7ElemRelationLister,
  },
  kfkvkElemRelation: {
    editor: kfkvkElemRelationEditor,
    lister: kfkvkElemRelationLister,
  },
  kbp7ElemBetween: {
    editor: kbp7ElemBetweenEditor,
    lister: kbp7ElemBetweenLister,
  },
};

export default {
  infoRegsComponents,
  attachedFilesEditor,
  proposalDeputiesEditor,
  proposalDeputiesLister,

  kbpElemRelationEditor,
  kbpElemRelationLister,
  kbp7ElemRelationEditor,
  kbp7ElemRelationLister,
  kfkvkElemRelationEditor,
  kfkvkElemRelationLister,
  kbp7ElemBetweenEditor,
  kbp7ElemBetweenLister,
};

export {
  infoRegsComponents,
  attachedFilesEditor,
  proposalDeputiesEditor,
  proposalDeputiesLister,

  kbpElemRelationEditor,
  kbpElemRelationLister,
  kbp7ElemRelationEditor,
  kbp7ElemRelationLister,
  kfkvkElemRelationEditor,
  kfkvkElemRelationLister,
  kbp7ElemBetweenEditor,
  kbp7ElemBetweenLister,

};
