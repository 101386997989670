import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import GridTemplate from './GridTemplate';
import { DivCell, InputCodeStyled } from '../../../../../components/styledTable/styledTable';
import { changeField } from '../../../../../actions/dpEditor';
import Fields from '../../../../field';

const TABLENAME = 'tvAcc';

const RowComponent = ({
  row, rowId, dispatch, FI,
  ...props
}) => (
  <GridTemplate {...props}>
    <DivCell areaName="selected">
      <Fields.CheckboxField
        value={row.get('selected', false)}
        onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'selected'], v))}
      />
    </DivCell>
    <DivCell areaName="codeS">
      <InputCodeStyled
        noBackground
        noBorder
        value={row.get('codeS', '')}
        readOnly
      />
    </DivCell>
    <DivCell areaName="nameS">
      <InputCodeStyled
        noBackground
        noBorder
        value={row.get('nameS', '')}
        readOnly
      />
    </DivCell>
  </GridTemplate>
);

RowComponent.propTypes = {
  row: PropTypes.instanceOf(Map).isRequired,
  rowId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  FI: PropTypes.instanceOf(Map).isRequired,
};

export default RowComponent;
