import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Nav, Container, Row, Card, Col, Alert,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import api from '../../../api/req';
import { CiatAppContext } from '../../../providers';
import { EditorControls } from '../../basicEditor/editorControls';
import IconButton from '../../../components/blanks/common/IconButton';

function UpdateGroup({
  onClose, groupId, onRefresh, onSetLoading, onSetErr, allRoles,
}) {
  const { auth } = useContext(CiatAppContext);
  const [group, setGroup] = useState(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [nonFieldErrors, setNonFieldErrors] = useState(null);

  const onLoadStart = useCallback(() => {
    onSetLoading(true);
    onSetErr(null);
  }, [onSetErr, onSetLoading]);

  const onLoadEnd = useCallback(() => {
    onSetLoading(false);
  }, [onSetLoading]);
  const onError = useCallback((e) => onSetErr(e), [onSetErr]);

  useEffect(() => {
    const loader = async () => {
      if (!groupId) {
        return {
          rolepriorityforgroup_set: [],
          name: 'Нова група',
        };
      }
      const r = await api.get(`/api/auth/permissiongroup/${groupId}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setGroup(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, groupId, onError, onLoadEnd, onLoadStart]);

  const updateGroup = () => {
    onSetErr(null);
    setNonFieldErrors(null);
    setFieldErrors(null);

    const loader = async () => {
      let r = null;
      if (groupId) {
        r = await api.put(`/api/auth/permissiongroup/${groupId}/`, auth, group);
      } else {
        r = await api.post('/api/auth/permissiongroup/', auth, group);
      }
      if (r.status === 400) {
        const { non_field_errors: nfe, ...errors } = await r.json();
        setFieldErrors(errors);
        setNonFieldErrors(nfe);
        throw new Error('Виникла помилка при заповненні');
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onRefresh();
        onClose();
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  };
  const displayRoles = useMemo(
    () => {
      if (!group) return [];
      const checkedRoles = group.rolepriorityforgroup_set.reduce((R, role) => ({
        ...R,
        [role.role.id]: role,
      }), {});
      return allRoles.map((role) => ({
        ...role,
        checked: role.id in checkedRoles,
        priority: role.id in checkedRoles ? checkedRoles[role.id].priority : null,
      }));
    },
    [allRoles, group],
  );
  const checkAll = useCallback(
    (value) => {
      setGroup((o) => ({
        ...o,
        rolepriorityforgroup_set: value ? allRoles.map((role, idx) => ({
          role,
          priority: idx + 1,
        })) : [],
      }));
      setCheckedAll(!checkedAll);
    },
    [allRoles, checkedAll],
  );
  const onRoleCheck = useCallback(
    (id, v) => {
      if (v) {
        setGroup((o) => {
          const maxPriority = o.rolepriorityforgroup_set.length
            ? Math.max(...o.rolepriorityforgroup_set.map((r) => r.priority))
            : 0;
          const role = allRoles.filter((r) => r.id === id).reduce((R, r) => r, null);
          return ({
            ...o,
            rolepriorityforgroup_set: [
              ...o.rolepriorityforgroup_set,
              { role, priority: maxPriority + 1 },
            ],
          });
        });
      } else {
        setGroup((o) => ({
          ...o,
          rolepriorityforgroup_set: o.rolepriorityforgroup_set.filter((r) => r.role.id !== id),
        }));
      }
    },
    [allRoles],
  );

  const handlePriority = useCallback(
    (id, v) => setGroup((o) => ({
      ...o,
      rolepriorityforgroup_set: o.rolepriorityforgroup_set.map((r) => (r.role.id === id ? {
        ...r,
        priority: v,
      } : r)),
    })),
    [],
  );

  return group && (
    <>
      {nonFieldErrors && (
      <Alert
        dismissible={() => setNonFieldErrors(null)}
        onClose={() => setNonFieldErrors(null)}
        variant="danger"
      >
        <Alert.Heading>
          Помилки заповнення даних:
        </Alert.Heading>
        <ul>
          {nonFieldErrors.map((nfe, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>{nfe.message || nfe}</li>
          ))}
        </ul>
      </Alert>
      )}
      <EditorControls.StringInput
        label="Назва групи"
        value={group.name}
        onChange={(e, v) => setGroup((o) => ({ ...o, name: v }))}
        errors={fieldErrors?.name}
      />
      <EditorControls.TextInput
        label="Опис групи"
        value={group.description}
        onChange={(e, v) => setGroup((o) => ({ ...o, description: v }))}
      />
      <Nav className="justify-content-end">
        <h5 className="text-primary my-2">Ролі:</h5>
        <IconButton
          className="m-auto me-1"
          onClick={() => checkAll(!checkedAll)}
          icon={checkedAll ? faSquare : faCheckSquare}
          size="sm"
          title={checkedAll ? 'Виключити всі' : 'Включити всі'}
          variant={checkedAll ? 'falcon-warning' : 'falcon-primary'}
        />
        {/* <Button className="me-2" onClick={() => checkAll(true)}> */}
        {/*  <FontAwesomeIcon className="me-2" icon={faCheckSquare} size="sm" /> */}
        {/*  Включити всі */}
        {/* </Button> */}
        {/* <Button variant="outline-primary" onClick={() => checkAll(false)}> */}
        {/*  <FontAwesomeIcon icon={faSquare} className="me-2" size="sm" /> */}
        {/*  Виключити всі */}
        {/* </Button> */}
      </Nav>
      <Container fluid className="mt-2">
        <Row cols={6}>
          {displayRoles.map((el) => (
            <Col key={el.id} className="mt-2">
              <Card className="h-100">
                <Card.Header>
                  <Card.Title className="d-flex gap-2">
                    <EditorControls.CheckboxInput
                      label={el.name}
                      value={el.checked}
                      onChange={(e, v) => onRoleCheck(el.id, v)}
                    />
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <p className="text-muted">{el.description}</p>
                  <EditorControls.NumberInput
                    label="Пріорітет"
                    value={el.priority}
                    onChange={(e, v) => handlePriority(el.id, v)}
                    required
                    disabled={!el.checked}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="d-flex gap-2">
        <Button className="mt-2 me-2" variant="outline-success" onClick={updateGroup}>Зберегти</Button>
        <Button className="mt-2" variant="outline-danger" onClick={() => onClose()}>
          Закрити
        </Button>
      </div>
    </>
  );
}

UpdateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.number,
  onRefresh: PropTypes.func.isRequired,
  onSetLoading: PropTypes.func.isRequired,
  onSetErr: PropTypes.func.isRequired,
  allRoles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

UpdateGroup.defaultProps = {
  groupId: null,
  allRoles: [],
};

export default UpdateGroup;
