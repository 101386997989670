import PropTypes from 'prop-types';
import React from 'react';
import {
  Tabs, Tab,
} from 'react-bootstrap';
import GeneralTP from './tabs';
import md from '../../../../constants/meta/documents/treasuryStatementCosts';

function TreasuryStatementCostsTabs({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
    </Tabs>
  );
}

TreasuryStatementCostsTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default TreasuryStatementCostsTabs;
