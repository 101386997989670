import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputField } from '../styledInputField/inputField';
import checkIcon from '../../assets/icons/im.svg';

export const StyleCheckbox = styled.input`
    display:none;
    +label:before{
      ${({ checked }) => (checked ? `background:url("${checkIcon}") 1px 2px no-repeat white` : 'background: white')};
      content: "";
      display: inline-block;
      margin: -5px 4px 0 0;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      border: 1px solid #a9a5a5;
      border-radius: 2px;
      box-sizing: inherit;
      }    
`;

export const LabelCheck = styled.label`
    line-height: 20px;
    font-family: Roboto,sans-serif;
    font-size: 14px;
`;

function CheckBoxStyled({
  isFocusCell, value: initValue, disabled, children, label, onChange,
}) {
  const refInput = useRef(null);
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    if (isFocusCell) refInput.current.focus();
  });

  return (
    <InputField
      disabled={disabled}
      onClick={(e) => {
        onChange(e, !value);
        setValue(!value);
      }}
    >
      <StyleCheckbox
        type="checkbox"
        ref={refInput}
        checked={value}
        readOnly
      />
      <LabelCheck>{label || children}</LabelCheck>
    </InputField>
  );
  // }
}

CheckBoxStyled.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  isFocusCell: PropTypes.bool,
  label: PropTypes.node,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

CheckBoxStyled.defaultProps = {
  value: false,
  isFocusCell: false,
  disabled: false,
  label: null,
  children: null,
  onChange: () => null,
};

export default CheckBoxStyled;
