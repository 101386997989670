import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/common';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function TPFooter({
  totals,
  totalsP,
  activeCol,
  highlights,
}) {
  return (
    <TableHeaderRow noGutters className="px-12">
      <Col
        lg={6}
        className="px-1 text-center"
      >
        РАЗОМ
      </Col>
      <Col className="d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'sum'}
          highlighted={highlights.includes('sum')}
        >
          {totals.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active={activeCol === 'prepaid'}
          highlighted={highlights.includes('prepaid')}
        >
          {totalsP.toLocaleString('uk', moneysStingOptions)}
        </TableHeaderCell>
      </Col>
      <Col lg={4}>
        {' '}
      </Col>
    </TableHeaderRow>
  );
}

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.number,
  totalsP: PropTypes.number,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
  totals: 0,
  totalsP: 0,
};

export default memo(TPFooter);
