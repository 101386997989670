import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ФинансовыйОрган' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },
};

const definition = {
  label: 'Аналіз Паспортів бюджетних програм',
  name: 'PassportReportAnalysis',
  columns,
  frontend: 'rep/PassportReportAnalysis',
  backendName: 'АнализПаспортовОтчетовВыполнения',
  resultColumns: baseResultColumns,
};

export default definition;
