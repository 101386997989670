/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Nav } from 'react-bootstrap';
import { StyledButtonToolbar } from '../../../newEditor/commandPanels/styles';
import {
  GoToOldVersionButton, RedoButton,
  SaveButton,
  UndoButton,
} from '../../../newEditor/commandPanels/buttons';

const APMenu = forwardRef(({
  children, actions, permissions, changed, loading,
}, ref) => (
  <Nav ref={ref} className="border rounded bg-white sticky-top">
    <StyledButtonToolbar className="me-auto" size="sm">
      <ButtonGroup size="sm">
        <SaveButton
          onClick={actions.onSaveWithoutExit}
          disabled={!changed || !permissions.canSave}
        />
      </ButtonGroup>
      <ButtonGroup size="sm">
        <UndoButton
          onClick={actions.onUndo}
          disabled={!permissions.canUndo || !changed || loading}
        />
        <RedoButton
          onClick={actions.onRedo}
          disabled={!permissions.canRedo || !changed || loading}
        />
      </ButtonGroup>
      {children}
    </StyledButtonToolbar>
    <ButtonGroup size="sm">
      <GoToOldVersionButton
        onGoToOldVersion={actions.onGoToOldVersion}
        onSaveNGoToOldVersion={actions.onSaveNGoToOldVersion}
        disabled={loading}
        isChanged={changed}
        loading={loading}
      />
    </ButtonGroup>
  </Nav>
));

APMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  actions: PropTypes.shape({
    onExecuteNExit: PropTypes.func.isRequired,
    onSaveWithoutExit: PropTypes.func.isRequired,
    onExecute: PropTypes.func.isRequired,
    onUnexecute: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onGoToOldVersion: PropTypes.func.isRequired,
    onSaveNGoToOldVersion: PropTypes.func.isRequired,
  }).isRequired,
  changed: PropTypes.bool,
  loading: PropTypes.bool,
  permissions: PropTypes.shape({
    canClose: PropTypes.bool,
    canSave: PropTypes.bool,
    canExecute: PropTypes.bool,
    canUnexecute: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
  }).isRequired,
};

APMenu.defaultProps = {
  children: null,
  changed: false,
  loading: false,
};

export default APMenu;
