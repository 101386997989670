import React, { forwardRef, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
// eslint-disable-next-line import/no-cycle
import { CatListerCommandPanel } from '../../../../newLister/tableComponent/commandpanel';
import ListerContext from '../../../../newLister/context';
// eslint-disable-next-line import/no-cycle
import { DateInput } from '../../../../../components/Controls';

const ClassifierCP = forwardRef((props, ref) => {
  const {
    activeDate, onChangeActiveDate, showInactive, onChangeShowInactive,
  } = useContext(ListerContext);
  return (
    <CatListerCommandPanel
      ref={ref}
      prepend={(
        <DateInput
          prepend="Актуально на"
          value={activeDate}
          onChange={onChangeActiveDate}
        />
      )}
      append={(
        <Dropdown.Item onClick={(e) => onChangeShowInactive(e, !showInactive)}>
          <FontAwesomeIcon icon={showInactive ? faCheck : faSquare} className="me-2" />
          {showInactive ? (
            <span>Приховати недіючі коди</span>
          ) : (
            <span>Показати як діючі, так і недіючі коди</span>
          )}
        </Dropdown.Item>
      )}
    />
  );
});

export default ClassifierCP;
