import { baseReportColumns } from './_base';

const columns = {
  ...baseReportColumns,
  Budget: { label: 'Бюджет', name: 'Бюджет' },
  FO: { label: 'Розп. вищ.рівня', name: 'ФинансовыйОрган' },
  Folder: { label: 'Папка', name: 'ВыбПуть' },
  Date1: { label: 'Період з', name: 'Дата1' },
  Date2: { label: 'по', name: 'Дата2' },
  fKind: { label: 'Планування', name: 'фВид' },
  fQuart: { label: 'Номер кварталу', name: 'фКварталЧисло' },
  f866: { label: 'Кодовая сторінка 866', name: 'фл866' },
  UploadInside: { label: 'Виватажувати внутрішні', name: 'флВнутренняя' },
  fKVK3: { label: 'КВК 3-и знаки', name: 'флКВК3' },
  fSvod: { label: 'Зведений бюджет', name: 'флСводныйБюджет' },
  fUtv: { label: 'Ознака плану', name: 'флУтв' },
  fMonth: { label: 'Номер місяця', name: 'фМесяцЧисло' },
  fType: { label: 'Вид даних', name: 'фТип' },
  PCM: { label: 'За класифікаторами', name: 'ПЦМ' },
  OneSumm: { label: 'БР однією сумою', name: 'ОднаСуммаПоБР' },
  FileAddress: { label: 'АдресФайла', name: 'АдресФайла' },
  CodeBudget: { label: 'КодБюджета', name: 'КодБюджета' },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  SignVariant: { label: 'Варіант підпису (для відправки в ЛОГІКА)', name: 'ВариантПодписи' },
  wSighn: { label: 'Враховувати знаки', name: 'флУчитыватьЗнаки' },
  no250: { label: 'Не вивантажувати власні нажходження', name: 'неВыгружать250еКоды' },
  noSF: { label: 'Не вивантажувати асигнування за рахунок власних надходжень', name: 'НеВыгружатьАссигнованияЗаСчетСпецфонда' },
};

/**
 * @const
 */
const definition = {
  name: 'exportPlanToDBF',
  backendName: 'ЭкспортПланаАсигнДБФ',
  frontend: 'dp/exportPlanToDBF',
  label: 'Формування файлу для ГФУ',
  columns,
};

export default definition;
