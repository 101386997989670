const frontendURL = '/cat/genderage/';
const backendURL = '/api/references/refgenderage/';
const name = 'Гендерний вік';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
