import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { getComponentByType, getTableHookByType } from './tableControls';
import TabelRowCell from '../tablePart/tabkeRowCell';

function RoxIdx({ rowIndex }) {
  return (
    <Badge bg="" text="secondary" className="fs--2">
      {rowIndex + 1}
    </Badge>
  );
}

RoxIdx.propTypes = {
  rowIndex: PropTypes.number.isRequired,
};

function Cell({
  active, activeCol, cellProps, fields, rowIndex, row, onChangeRow, errors, highlighted,
}) {
  const { props, name } = cellProps;
  const { type } = 'type' in cellProps ? cellProps : fields[name];

  if (type === 'numericRow') {
    return (
      <div style={{ margin: 'auto', height: '100%' }}>
        <RoxIdx
          rowIndex={rowIndex}
          {...props}
        />
      </div>
    );
  }

  const { afterChangeRow, beforeChangeRow } = cellProps;
  const onChangeCell = (e, rIdx, partData) => {
    if (beforeChangeRow) {
      beforeChangeRow({
        e, rIdx, row, onChangeRow, partData,
      });
    }

    const res = onChangeRow(e, rIdx, partData);

    if (afterChangeRow) {
      afterChangeRow({
        e, rIdx, row, onChangeRow, partData,
      });
    }
    return res;
  };

  const typeHook = 'typeHook' in cellProps ? cellProps.typeHook : type;

  const tableHook = getTableHookByType(typeHook);

  const controlProps = tableHook(
    cellProps.name,
    row,
    fields,
    errors,
    onChangeCell,
    rowIndex,
  );

  const Control = getComponentByType(type);
  return (
    <TabelRowCell
      column={cellProps.name}
      highlighted={highlighted}
      active={active}
      activeCol={activeCol}
    >
      <Control
        {...controlProps}
        {...cellProps.props}
      />
    </TabelRowCell>
  );
}

Cell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.shape([]).isRequired,
  fields: PropTypes.shape({}).isRequired,
  onChangeRow: PropTypes.func.isRequired,
  cellProps: PropTypes.shape({
    props: PropTypes.shape({}),
    name: PropTypes.string,
    typeHook: PropTypes.string,
    afterChangeRow: PropTypes.func,
    beforeChangeRow: PropTypes.func,
  }).isRequired,
  errors: PropTypes.shape({}),
  highlighted: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  activeCol: PropTypes.string,
};
Cell.defaultProps = {
  errors: null,
  activeCol: null,
};

export default memo(Cell);
