import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  date: { label: 'На дату', name: 'НаДату' },
};

const definition = {
  label: 'Аналіз доходів (у розрізі бюджетів)',
  name: 'RegionRevenueFactAnalysis',
  columns,
  frontend: 'rep/RegionRevenueFactAnalysis',
  backendName: 'АнализДоходовОбласногоБюджета',
  resultColumns: baseResultColumns,
};

export default definition;
