import React from 'react';
import PropTypes from 'prop-types';
import useLowerDocs from '../../../../../newEditor/hook/lowerDocs';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import LevelDownDocs from '../../../../common/levelDownDocs';

const TABLENAME = 'finorderproposal_set';
function LowerDocs({
  data, actions, readOnly,
}) {
  const tableData = data[TABLENAME] || [];
  const {
    tableActions,
  } = useLowerDocs({
    tableName: TABLENAME,
    onChange: actions.onChange,
    docName: 'findocument',
    approvedName: 'approved',
  });
  return (
    <LevelDownDocs
      tableData={tableData}
      approvedKey="approved"
      tableActions={tableActions}
      documentKey="findocument"
      amountKey="amount"
      readOnly={readOnly}
      commentKey="comment"
      descriptionKey="description"
      authorityKey="authority"
      authorKey="author"
    />
  );
}

LowerDocs.propTypes = {
  data: PropTypes.shape({
    [TABLENAME]: PropTypes.arrayOf(PropTypes.shape({
      findocument: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
        url: PropTypes.string,
        author: foreignPropType,
        authority: foreignPropType,
        amount_total: PropTypes.number,
        description: PropTypes.string,
        comment: PropTypes.string,
      }).isRequired,
      approve: PropTypes.bool,
    })),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
};

LowerDocs.defaultProps = {
  readOnly: false,
};

export default LowerDocs;
