const frontendURL = '/cat/kvk3/';
const backendURL = '/api/references/refkvk3/';
const name = 'Класифікація закладів (Мінфін)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
