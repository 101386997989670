import { Map, List } from 'immutable';
import action from '../constants/actions/rerportEditor';

/**
 * @func
 * @param {Immutable.Map} state
 * @param {object} action
 * @param {string} action.type
 * @param {any} action.payload
 * @param {array} action.payload.[path]
 * @param {any} action.payload.[value]
 * @param {any} action.payload.[items]
 * @return {Immutable.Map}
 */
const makeReducer = (model) => (state = new Map({}), { type, payload = {} }) => {
  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */

  if (
    (!type.endsWith('@reportEditor'))
        || (payload.model !== model)
  ) {
    // Не наш узел
    return state;
  }

  const updaterCreateTableRow = (table) => table.push(new Map());

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */
  const updaterDeleteTableRow = (table) => table.filter((row, key) => !payload.items.has(key));

  /**
   * @function
   * @param {Immutable.List} table
   * @return {Immutable.List}
   */

  const updaterCopyTableRow = (table) => payload.items.reduce(
    /**
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @return {Immutable.List}
       */
    (r, v) => r.push(v),
    table,
  );

  const updaterSwapDownTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next row
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k + 1, v),
    updaterDeleteTableRow(table),
  );

  const updaterSwapUpTableRow = (table) => payload.items.reduce(
    /**
       * @desc Move all items to next row
       * @param {Immutable.List} r
       * @param {Immutable.Map} v
       * @param {number} k
       * @return {Immutable.List}
       */
    (r, v, k) => r.insert(k - 1, v),
    updaterDeleteTableRow(table),
  );

  switch (type) {
    case action.INIT:
      return new Map({})
        .set('modelType', payload.type)
        .set('modelName', payload.model)
        .set('headerForm', new Map())
        .set('tables', new Map())
        .set('result', new Map())
        .set('dataCompositionSettings', new Map())
        .set('isProcessing', true)
        .set('isChanged', false)
        .set('isValidated', false)
        .set('hasError', false)
        .set('errorMsg', null)
        .set('disabledFields', new Map())
        .set('autoExecute', !!payload.autoExecute)
        .set('decryption', new Map({ opened: false })); // Расшифорвка
    case action.GENERATE:
      return state.set('autoExecute', false);
    case action.CHANGE_PROPERTY:
      return state.set('isChanged', true).setIn(payload.path, payload.value);
    case action.SR_CHANGE_PROPERTY:
      return state.setIn(payload.path, payload.value);
    case action.TABLE_ROW_SET_ACTIVE:
      return state
        .setIn(payload.path, payload.value);
    case action.TABLE_ROW_ADD:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.name}`, new List(), updaterCreateTableRow);
    case action.TABLE_ROW_COPY:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.name}`, new List(), updaterCopyTableRow);
    case action.TABLE_ROW_REMOVE:
      return state
        .set('isChanged', true)
        .update(`tables/${payload.name}`, new List(), updaterDeleteTableRow);
    case action.TABLE_ROW_SWAP:
      return state
        .set('isChanged', true)
        .update(
          `tables/${payload.name}`,
          new List(),
          payload.reverseDirection
            ? updaterSwapUpTableRow
            : updaterSwapDownTableRow,
        );
    case action.TABLE_FILL:
      return state
        .set('isChanged', true)
        .set(
          `tables/${payload.name}`,
          payload.items,
        );
    case action.SR_START:
      return state.set('isProcessing', true)
        .set('hasError', false);
    case action.SR_DONE:
      return payload.tables
        .keySeq()
        .reduce(
          (r, table) => r.set(`tables/${table}`, payload.tables.get(table)),
          state
            .set('isProcessing', false)
            .set('headerForm', payload.headerForm || null)
            .set('result', payload.result || null)
            .set('dataCompositionSettings', payload.dataCompositionSettings || null)
          ,
        );
    case action.SR_ERR:
      return state
        .set('hasError', true)
        .set('isProcessing', false)
        .set('errorMsg', payload.errorMsg);
    case action.CANCEL_REQUEST:
      return state.set('isProcessing', false);
    case action.DECRYPTION_LOADED:
      return state
        .set('isProcessing', false)
        .set('decryption', new Map({
          result: payload.decryption,
          opened: true,
          DCS: payload.DCS,
        }));
    case action.DECRYPTION_CLOSED:
      return state
        .set('decryption', new Map({ opened: false })); // Расшифорвка
    default:
      return state;
  }
};

const createReportReducer = (modelType, o, _form = 'reportEditor') => Object.keys(o)
  .reduce((r, key) => ({
    ...r,
    [`${modelType}/${key}/${_form}`]: makeReducer(key),
  }), {});

export default createReportReducer;
