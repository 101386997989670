const frontendURL = '/cat/bfnGroupFullness/';
const backendURL = '/api/bfn/refgroupfullness/';
const name = 'Наповненість груп закладу дошкільної освіти';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
