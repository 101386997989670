import base from './base';

const columns = {
  ...base.columns,
  Fond: { label: 'Фонд', name: 'Фонд', visible: false },
  Budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  RNo: { label: 'Рег.номер', name: 'РегНомер', visible: true },
  PlaningMode: { label: 'Вид планування', name: 'ВидПлана', visible: false },
  FondObject: { label: 'Фонд', name: 'ЭлементФонд', visible: false },
  FIcode: { label: 'р/р', name: 'РСФинансовыйОрган.Код', visible: true },
  FIAccount: { label: 'Рахунок розп.вищ.р. (з:)', name: 'РСФинансовыйОрган', visible: true },
  ImportAllNotes: {
    label: 'Не зводити примітку (в розпорядженні / розподілі)',
    name: 'ИмпортВсехПримечаний',
    visible: false,
  },
  WithoutResidueControl: {
    label: 'Не перевірять залишки фінансування',
    name: 'БезКонтроляОстатков',
    visible: false,
  },
  CSU: { label: 'Розпорядник', name: 'Распорядитель', visible: true },
  CSUAccount: { label: 'Рахунок розп./отр. (на:)', name: 'РСРаспорядителя', visible: false },
  ToHold: { label: 'Провести без залишків', name: 'ПровестиЕслиНетДенегНаСчету', visible: false },
  Sum: {
    label: 'Сума документа', name: 'СуммаДокумента', visible: true, type: 'moneys',
  },
  Author: { label: 'Автор', name: 'Автор', visible: true },
  AuthorChanges: { label: 'Автор змін', name: 'АвторИзм', visible: true },
  Tags: { label: 'Опис', name: 'АвтПримечание', visible: true },
  Note: { label: 'Примітка', name: 'Примечание', visible: true },
  BankDate: { label: 'Дата проведення ДКУ', name: 'ДатаКазна', visible: true },
  DateFill: { label: 'Дата заповнення', name: 'ДатаЗаполненияТч', visible: false },
};

const tables = {
  general: {
    name: 'Основной',
    label: 'Загальний',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      KVKCode: { label: 'КВК', name: 'КодКВК' },
      KPKVCode: { label: 'КПКВ ', name: 'КодКПКВ' },
      KEKVCode: { label: 'КЕКВ', name: 'КодКЕКВ' },
      CSU: { label: 'Розпорядник', name: 'Распорядитель' },
      elementKFK: { label: 'Елемент КПКВ', name: 'ЭлементКФК' },
      elementKEKV: { label: 'Елемент КЕКВ', name: 'ЭлементКЕКВ' },
      BuildObgect: { label: 'Об\'єкт будівництва/ремонту', name: 'ОбъектСтроительства' },
      TypeRepair: { label: 'Вид ремонту', name: 'ВидРемонтаРеквизит' },
      CSUAccount: { label: 'Рахунок розпорядника', name: 'РСРаспорядителя' },
      CSUAccountNo: { label: '№ рахунка', name: 'НомерРСРаспорядителя' },
      // CSUcode: { label: 'Ном.рахунка', name: 'РСРаспорядителя.Код' },
      Balance: { label: 'Залишок  асигнувань', name: 'Ост' },
      Sum: { label: 'Сума касових видатків', name: 'Сумма' },
      SumPayment: { label: 'Сума оплати', name: 'СуммаОплата' },
    },
  },
  filledFromDocument: {
    name: 'ЗаполненоИзДок',
    label: 'Заповнено з док.',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      IsApproved: { label: 'Затверджено', name: 'ДокументУтвержден' },
      data: { label: 'Дата', name: 'ДатаДокумента' },
      number: { label: 'Номер', name: 'ДокументНомер' },
      document: { label: 'Документ', name: 'Документ' },
      sum: { label: 'Сума', name: 'СуммаДокумента' },
      Tags: { label: 'Опис', name: 'АвтПримечание' },
      Author: { label: 'Автор', name: 'ДокументАвтор' },
    },
  },
};

const definition = {
  label: 'Фінансування (внесення даних по касових видатках)',
  name: 'financingCashbox',
  backendName: 'ФинансированиеКаса',
  columns,
  frontend: 'doc/financingCashbox',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'FIcode',
      'FIAccount',
      'RNo',
      'Sum',
      'CSU',
      'Author',
      'AuthorChanges',
      'Tags',
      'Note',
      'BankDate',
      'Budget',
      'FI',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  useListLazyLoad: true,
};

export default definition;
