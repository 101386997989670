/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import TableContainer from '../../../../../newEditor/tablePart/tableContainer';

function PlanningRows({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights, pinAllowed,
  } = useTablePart({
    data, onChange, tableName: 'creditprojectrow_set', readOnlyFields, errors, fields,
  });

  const totals = useMemo(
    () => tableData.reduce(
      (Res, r) => ({
        refund_dev_fund: Res.refund_dev_fund + (r.refund_dev_fund || 0),
        refund_spec_fund: Res.refund_spec_fund + (r.refund_spec_fund || 0),
        refund_zag_fund: Res.refund_zag_fund + (r.refund_zag_fund || 0),
        subm_dev_fund: Res.subm_dev_fund + (r.subm_dev_fund || 0),
        subm_spec_fund: Res.subm_spec_fund + (r.subm_spec_fund || 0),
        subm_zag_fund: Res.subm_zag_fund + (r.subm_zag_fund || 0),
      }),
      {
        refund_dev_fund: 0,
        refund_spec_fund: 0,
        refund_zag_fund: 0,
        subm_dev_fund: 0,
        subm_spec_fund: 0,
        subm_zag_fund: 0,
      },
    ),
    [tableData],
  );

  const { disposer_in_header: disposerInHeader, authority, budget } = data;

  return (
    <TableContainer
      pinHeader={pinHeader}
      pinAllowed={pinAllowed}
      onAllowPin={tableActions.onAllowPin}
    >
      <TableHeader pinHeader={pinHeader} pinAllowed={pinAllowed}>
        <TPCommandPanel
          pinHeader={pinHeader}
          pinAllowed={pinAllowed}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
          disposerInHeader={disposerInHeader}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
            onMoveRow={tableActions.onMoveRow}
            tableName="creditprojectrow_set"
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              disposerInHeader={disposerInHeader}
              authorityId={authority ? authority.id : null}
              budgetId={budget ? budget.id : null}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </TableContainer>
  );
}

PlanningRows.propTypes = {
  data: PropTypes.shape({
    disposer_in_header: PropTypes.bool,
    creditprojectrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    authority: foreignPropType,
    budget: foreignPropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    creditprojectrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    creditprojectrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    creditprojectrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
