import { baseReportColumns, baseResultColumns } from './_base';

const columns = {
  ...baseReportColumns,
  budget: { label: 'Бюджет', name: 'ТекущийБюджет' },
  FO: { label: 'Розп. вищ. рівня', name: 'ТекущийФинансовыйОгран' },
  period: { label: 'Період звіту', name: 'ПериодОтчета' },

};

const definition = {
  label: 'Звіт за казначейською випискою (зріз за останніми рухами по рахунках)',
  name: 'TreasureReportStatementCut',
  columns,
  frontend: 'rep/TreasureReportStatementCut',
  backendName: 'КазнВыпискаСрез',
  resultColumns: baseResultColumns,
};

export default definition;
