import React, {
  useMemo, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Nav, Row, Col, TabContainer, TabContent, TabPane, Container,
} from 'react-bootstrap';
import KBPElementRelationLister from '../../../infoRegs/kbpElemRelation/lister';
import KBP7ElementRelationLister from '../../../infoRegs/kbp7ElemRelation/lister';
import KFKVKElementRelationLister from '../../../infoRegs/kfkvkElemRelation/lister';
import KBPElementBetweenLister from '../../../infoRegs/kbp7ElemBetween/lister';
import { infoRegElementTOKBPRelation } from '../../../../constants/meta/infoRegs/kbpElemRelation';

function ClassifiersRelation({ elementId }) {
  const filter = useMemo(
    () => [{
      fieldName: infoRegElementTOKBPRelation.columns.element.name,
      value: elementId,
    },
    ],
    [elementId],
  );
  if (elementId === 'create') return null;
  return (
    <TabContainer defaultActiveKey="KBP">
      <Row noGutters>
        <Col sm={2} className="me-3">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="KBP">
                За КБП
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="KBP7">
                За КПКВ
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="KFKVK">
                За КФК
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="BetweenElements">
                З елементом розп. вищого рівня
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col _sm={9}>
          <TabContent>
            <TabPane eventKey="KBP">
              <Container className="min-vh-100 border-left" fluid>
                {elementId && (
                  <KBPElementRelationLister filter={filter} />
                )}
              </Container>
            </TabPane>
            <TabPane eventKey="KBP7">
              <Container className="min-vh-100 border-left" fluid>
                {elementId && (
                  <KBP7ElementRelationLister filter={filter} />
                )}
              </Container>
            </TabPane>
            <TabPane eventKey="KFKVK">
              <Container className="min-vh-100 border-left" fluid>
                {elementId && (
                  <KFKVKElementRelationLister filter={filter} />
                )}
              </Container>
            </TabPane>
            <TabPane eventKey="BetweenElements">
              <Container className="min-vh-100 border-left" fluid>
                {elementId && (
                  <KBPElementBetweenLister filter={filter} />
                )}
              </Container>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </TabContainer>
  );
}

ClassifiersRelation.propTypes = {
  elementId: PropTypes.string,
};

ClassifiersRelation.defaultProps = {
  elementId: null,
};

export default memo(ClassifiersRelation);
