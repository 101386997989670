import { List, Map } from 'immutable';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { editorActions } from '../constants/actions/editor';
import api from '../api/req';
import { fromSrvMapper, resultMapper, toSrvMapper } from '../common/editorMappers';
import { saveModes } from '../constants/meta/common';
import { dellComponentFromWindowsManager, sendUpdateSignal } from './windowsManager';
import meta from '../constants/meta';

const filterActiveItem = (v) => v.get('IS_ACTIVE', false);

const getStorePath = (modelType, modelName, id) => [`${modelType}/${modelName}/editor`, id];

export const registerWarnings = (modelType, modelName, id) => (key, warnings) => (dispatch) => {
  dispatch({
    type: editorActions.REGISTER_WARNINGS,
    payload: {
      warningsKey: key,
      items: warnings,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const clearWarnings = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: editorActions.CLEAR_WARNINGS,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

/* LOADING ACTIONS */
export const loadDone = (modelType, modelName, id) => (promiseResponse) => (dispatch) => {
  const modelDef = meta[modelType][modelName];
  const response = promiseResponse;

  const item2 = fromSrvMapper(modelDef, response);
  const item = {
    headerForm: item2.headerForm
      .merge(new Map(resultMapper(modelDef, response))),
    tables: item2.tables,
  };

  dispatch(clearWarnings(modelType, modelName, id)());

  dispatch({
    type: editorActions.LOAD_DONE,
    payload: {
      item,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

const loadError = (modelType, modelName, id) => (e) => (dispatch) => {
  dispatch({
    type: editorActions.LOAD_ERR,
    payload: {
      e,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

/**
 * @param modelType {string}
 * @param modelName {string}
 * @param id {string}
 * @return {function(func)}
 */
export const load = (modelType = '', modelName = '', id = '') => () => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const node = store.getIn(getStorePath(modelType, modelName, id), new Map());

  const initParams = node.get('initParams');

  const modelDef = meta[modelType][modelName];

  dispatch({
    type: editorActions.LOAD_START,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });

  api
    .post$(`${modelType}/${modelDef.backendName}/${id}`, initParams)
    .then((d) => {
      if (d.ok) {
        try {
          d.json()
            .then((data) => {
              dispatch(loadDone(modelType, modelName, id)(data));
            })
            .catch((e) => console.log('error pasrsing json ', e));
        } catch (e) {
          console.log('loadError');
          dispatch(loadError(modelType, modelName, id)(e.message));
        }
      } else {
        console.log('else error');
        d.text()
          .then((t) => dispatch(loadError(modelType, modelName, id)(t)))
          .catch((e) => dispatch(loadError(modelType, modelName, id)(e.message)));
      }
    })
    .catch((e) => {
      console.error('LOAD_START error ', e);
      dispatch(loadError(modelType, modelName, id)(e));
    });
};

export const loadAttachedFiles = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: editorActions.PROCESS_ATTACHED_FILES,
    payload:
      {
        isLoadingAttachedFiles: false,
        AttachedFiles: [],
        type: modelType,
        model: modelName,
        id,
      },
  });

  api.post$(`${modelType}/${meta[modelType][modelName].backendName}/${id}/attachedFiles`).then((r) => {
    if (r.ok) {
      r.json().then((d) => {
        const AttachedFiles = d.reduce((R, f) => R.push(new Map({
          content: f.content,
          type: f.type,
          fileName: f.filename,
          description: f.description,
        })), new List());

        dispatch({
          type: editorActions.PROCESS_ATTACHED_FILES,
          payload:
            {
              isLoadingAttachedFiles: true,
              AttachedFiles,
              type: modelType,
              model: modelName,
              id,
            },
        });
      });
    } else {
      console.error(r.statusText);
    }
  });
};

export const upLoadAttachedFiles = (modelType, modelName, id) => (files) => (dispatch) => {
  dispatch({
    type: editorActions.PROCESS_ADD_FILES,
    payload:
      {
        isLoadingAttachedFiles: false,
        type: modelType,
        model: modelName,
        id,
      },
  });

  const modelDef = meta[modelType][modelName];

  files.filter((f) => !f.done).forEach(async (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const dataFile = {
        id,
        modelType,
        modelName: modelDef.backendName,
        fileName: file.file.name,
        task: file.task,
        file: reader.result,
      };

      api.post$(`${modelType}/${modelDef.backendName}/${id}/addedFiles/`, dataFile).then(async (r) => {
        if (r.ok) {
          const res = await r.json();
          files[res.task].done = res.done;

          dispatch(loadAttachedFiles(modelType, modelName, id)());

          dispatch({
            type: editorActions.PROCESS_ADD_FILES,
            payload:
              {
                isLoadingAttachedFiles: true,
                type: modelType,
                model: modelName,
                id,
              },
          });
        }
      });
    };

    if (file.file) {
      reader.readAsDataURL(file.file);
    }
  });
};

export const deleteAttachedFile = (modelType, modelName, id) => (fileName) => (dispatch) => {
  dispatch({
    type: editorActions.DELETE_ATTACHED_FILES,
    payload:
      {
        isLoadingAttachedFiles: false,
        type: modelType,
        model: modelName,
        id,
      },
  });

  const modelDef = meta[modelType][modelName];

  const dataFile = {
    id,
    modelType,
    modelName: modelDef.backendName,
    fileName,
  };

  api.post$(`${modelType}/${modelDef.backendName}/${id}/deleteFiles/`, dataFile).then(async (r) => {
    if (r.ok) {
      dispatch(loadAttachedFiles(modelType, modelName, id)());

      dispatch({
        type: editorActions.DELETE_ATTACHED_FILES,
        payload:
          {
            isLoadingAttachedFiles: true,
            type: modelType,
            model: modelName,
            id,
          },
      });
    }
  });
};

/* INIT ACTION */

export const init = (modelType, modelName, id) => (initParams = null) => (dispatch) => {
  dispatch({
    type: editorActions.INIT,
    payload: {
      type: modelType,
      model: modelName,
      id,
      initParams,
    },
  });
};

/* LOCK ACTION */

const serverLockDone = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: editorActions.LOCK_ERR,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

const serverLockError = (modelType, modelName, id) => (e) => (dispatch) => {
  console.error(e);
  dispatch({
    type: '',
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const serverLock = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: '',
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
  fetch()
    .then((resp) => {
      if (resp.ok) {
        dispatch(serverLockDone(modelType, modelName, id)(resp.json()));
      } else {
        dispatch(serverLockError(modelType, modelName, id)('Server Error'));
      }
    })
    .catch(() => {
      dispatch(serverLockError(modelType, modelName, id)('Network Error'));
    });
};

/* UNLOCK ACTIONS */

const serverUnlockDoneHandler = () => () => () => {
};

const serverUnlockError = () => () => () => {
};

export const serverUnlock = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: '',
    payload: {},
  });

  // Херня какая-то
  fetch()
    .then((resp) => {
      if (resp.ok) {
        dispatch(serverUnlockDoneHandler(modelType, modelName, id)());
      } else {
        dispatch(serverUnlockError(modelType, modelName, id)('Server error'));
      }
    })
    .catch(() => {
      dispatch(serverUnlockError(modelType, modelName, id)('Network Error'));
    });
};

/* @@@@@@@@@@@@@@@@@ SAVE HANDLER @@@@@@@@@@@@@@@@@@ */
export const saveDone = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: editorActions.SAVE_DONE,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
  dispatch(sendUpdateSignal(meta[modelType][modelName].frontend));
};

export const saveError = (modelType, modelName, id) => (e) => (dispatch) => {
  dispatch({
    type: editorActions.SAVE_ERROR,
    payload: {
      e,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

const newSaveStartHandler = (
  modelType,
  modelName,
  id,
) => (
  exitOnSave,
  onClose,
  onSave,
  savemode,
) => (
  dispatch,
  getState,
) => {
  const store = getState();
  const item = store.getIn(getStorePath(modelType, modelName, id), new Map());

  const cMeta = meta[modelType][modelName];

  dispatch({
    type: editorActions.SAVE_START,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });

  const itemData = toSrvMapper(cMeta, item);

  api
    .put$(`${modelType}/${cMeta.backendName}/${id}`, { item: { ...itemData }, savemode })
    .then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          dispatch(saveDone(modelType, modelName, id)(d));
          if (exitOnSave) {
            if (onClose) onClose();
            dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${id}/`));
          } else {
            if (onSave) {
              onSave(d.id);
            }
            if (id.toUpperCase() === 'CREATE') dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${id}/`));
          }
        });
      } else {
        r.json().then((d) => {
          // eslint-disable-next-line no-underscore-dangle
          if (d._Error) {
            // eslint-disable-next-line no-underscore-dangle
            dispatch(saveError(modelType, modelName, id)(d._Error));
          } else {
            dispatch(saveError(modelType, modelName, id)(`Server Error ${r.status} ${r.statusText}`));
          }
        });
      }
    })
    .catch((e) => {
      dispatch(saveError(modelType, modelName, id)(e));
    });
};
export const newSave = (modelType, modelName, id) => (params = {}) => {
  const {
    exitOnSave, onClose, onSave, saveMode,
  } = params;
  return newSaveStartHandler(modelType, modelName, id)(
    exitOnSave || false,
    onClose || null,
    onSave || null,
    saveMode || saveModes.Write,
  );
};

export const addFiles = (modelType, modelName, id) => () => (dispatch) => {
  dispatch({
    type: editorActions.TURNING_ATTACHED_FILES,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const changeField = (modelType, modelName, id) => (path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {bool}
   */
  const isLocked = getState().getIn([...getStorePath(modelType, modelName, id), 'isLocked'], false);

  path.unshift(id);

  if (isLocked) {
    dispatch(serverLock(modelType, modelName, id)());
  }
  dispatch({
    type: editorActions.CHANGE_PROPERTY,
    payload: {
      path,
      value,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const serverRequestError = (modelType, modelName, id) => (e) => (dispatch) => {
  dispatch({
    type: editorActions.SR_ERR,
    payload: {
      hasError: true,
      errorMsg: e,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const serverReqDone = (
  modelType,
  modelName,
  id,
) => (promiseResponse) => async (dispatch) => {
  const modelDef = meta[modelType][modelName];

  const response = await promiseResponse;

  const item2 = fromSrvMapper(modelDef, response);
  const item = {
    headerForm: item2.headerForm
      .merge(new Map(resultMapper(modelDef, response))),
    tables: item2.tables,
  };

  dispatch({
    type: editorActions.SR_DONE,
    payload: {
      item,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const processServerReq = (modelType, modelName, id) => (
  method,
  extra = {},
  selector = () => ({}),
) => (dispatch, getState) => {
  const store = getState();

  const node = store.getIn(getStorePath(modelType, modelName, id), new Map());

  const modelDef = meta[modelType][modelName];

  dispatch({
    type: editorActions.SR_START,
    payload: {
      type: modelType,
      model: modelName,
      id,
    },
  });

  const itemData = toSrvMapper(modelDef, node);

  const selectorResult = selector(getState());

  api
    .post$(`${modelType}/${modelDef.backendName}/SR`, {
      method,
      item: itemData,
      ...extra,
      ...selectorResult,
    })
    .then((d) => {
      dispatch(serverReqDone(modelType, modelName, id)(d.json()));
    })
    .catch((e) => {
      dispatch(serverRequestError(modelType, modelName, id)(e));
    });
};

export const activateTableRow = (
  modelType,
  modelName,
  id,
) => (tableName, rowId, isMutiple = false) => (dispatch, getState) => {
  const store = getState();

  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  if (isMutiple) {
    dispatch({
      type: editorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [id, `tables/${tableName}`, rowId, 'IS_ACTIVE'],
        value: !table.getIn([rowId, 'IS_ACTIVE'], false),
        type: modelType,
        model: modelName,
        id,
      },
    });
  } else {
    dispatch({
      type: editorActions.TABLE_ROW_SET_ACTIVE,
      payload: {
        path: [id, `tables/${tableName}`],
        value: table.map((row, key) => row.set('IS_ACTIVE', key === rowId)),
        type: modelType,
        model: modelName,
        id,
      },
    });
  }
};

export const addTableRow = (modelType, modelName, id) => (tableName) => (dispatch) => {
  dispatch({
    type: editorActions.TABLE_ROW_ADD,
    payload: {
      tableName,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const removeTableRow = (modelType, modelName, id) => (tableName) => (dispatch, getState) => {
  const store = getState();

  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_REMOVE,
    payload: {
      name: tableName,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const copyTableRow = (modelType, modelName, id) => (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();
  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  const activeSeq = table.toMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_COPY,
    payload: {
      name: tableName,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const swapUpTableRow = (modelType, modelName, id) => (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  const activeSeq = table
    .toOrderedMap()
    .filter(filterActiveItem)
    .reverse();

  dispatch({
    type: editorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: true,
      items: activeSeq.reverse(),
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const swapDownTableRow = (
  modelType,
  modelName,
  id,
) => (tableName) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  const activeSeq = table.toOrderedMap().filter(filterActiveItem);

  dispatch({
    type: editorActions.TABLE_ROW_SWAP,
    payload: {
      name: tableName,
      reverseDirection: false,
      items: activeSeq,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const fillTable = (
  modelType,
  modelName,
  id,
) => (tableName, path, value) => (dispatch, getState) => {
  /**
   * @const
   * @type {Immutable.Map}
   */
  const store = getState();

  const table = store.getIn([...getStorePath(modelType, modelName, id), `tables/${tableName}`], new List());

  const newTable = table.map((row) => row.setIn(path, value));

  dispatch({
    type: editorActions.TABLE_FILL,
    payload: {
      name: tableName,
      items: newTable,
      type: modelType,
      model: modelName,
      id,
    },
  });
};

export const close = (modelType, modelName) => () => () => {
  const navigate = useNavigate();
  const cMeta = meta[modelType][modelName];
  console.error('This action is deprecated!!!');
  navigate(`/${cMeta.frontend}/`);
};

export default {
  addTableRow,
  changeField,
  load,
  loadDone,
  init,
  processServerReq,
  activateTableRow,
  removeTableRow,
  copyTableRow,
  swapUpTableRow,
  swapDownTableRow,
  fillTable,
  registerWarnings,
  clearWarnings,
};

/**
 *
 * @param modelType
 * @param modelName
 * @param id
 * @returns {{
 *  init: function({}),
 *  load: function(),
 *  loadDone: function(),
 *  registerWarnings: function(key: string, warnings: string[]),
 *  clearWarnings: function(),
 *  processServerReq: function(method: string, extra: {}, selector: function),
 *  changeField: function(path: string[], value: *),
 *
 *  activateTableRow: function(tableName: string, rowId: string, isMutiple: boolean),
 *  addTableRow: function(tableName: string),
 *  removeTableRow: function(tableName: string),
 *  swapDownTableRow: function(tableName: string),
 *  swapUpTableRow: function(tableName: string),
 *  copyTableRow: function(tableName: string),
 *  fillTable: function(tableName: string, path: string[], value: *),
 *  loadAttachedFiles: function(),
 *  }}
 */

const validateParams = (modelType, modelName, id) => {
  if (!['cat', 'doc'].includes(modelType)) {
    throw new Error(`modelType могут быть только "doc" или "cat". Передано "${modelType}"`);
  }
  if (!Object.keys(meta[modelType]).includes(modelName)) {
    throw new Error(`modelName могут быть только ${Object.keys(meta[modelType])}. Передано "${modelName}"`);
  }

  if (!id) {
    throw new Error('id не может быть пустым');
  }
};

export const useEditorActions = (modelType, modelName, id) => useMemo(
  () => {
    validateParams(modelType, modelName, id);
    return ({
      addTableRow: addTableRow(modelType, modelName, id),
      changeField: changeField(modelType, modelName, id),
      load: load(modelType, modelName, id),
      loadDone: loadDone(modelType, modelName, id),
      save: newSave(modelType, modelName, id),
      init: init(modelType, modelName, id),
      processServerReq: processServerReq(modelType, modelName, id),
      activateTableRow: activateTableRow(modelType, modelName, id),
      removeTableRow: removeTableRow(modelType, modelName, id),
      copyTableRow: copyTableRow(modelType, modelName, id),
      swapUpTableRow: swapUpTableRow(modelType, modelName, id),
      swapDownTableRow: swapDownTableRow(modelType, modelName, id),
      fillTable: fillTable(modelType, modelName, id),
      registerWarnings: registerWarnings(modelType, modelName, id),
      clearWarnings: clearWarnings(modelType, modelName, id),
      loadAttachedFiles: loadAttachedFiles(modelType, modelName, id),
      addFiles: addFiles(modelType, modelName, id),
    });
  },
  [id, modelName, modelType],
);

/**
 * Получить путь к store текущего элемента редактора
 * @param modelType {string}
 * @param modelName {string}
 * @param id {string}
 * @returns {[string, string]}
 */
export const useStorePath = (modelType, modelName, id) => useMemo(
  () => {
    validateParams(modelType, modelName, id);
    return getStorePath(modelType, modelName, id);
  },
  [id, modelName, modelType],
);
