import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import styled from 'styled-components';
import getBaseTP from '../../../../components/tableParts/index';
import storePathParam from '../../../../common/storePathParam';
import Fields from '../../../field';
import md from '../../../../constants/meta/index';
import { changeField } from '../../../../actions/reportEditor';
import {
  DivHeader,
  DivCell,
  InputCodeStyled,
  BasedGrid,
} from '../../../../components/styledTable/styledTable';

const TABLENAME = 'tpDoc';

const coldef = md.rep.TreasureReports.tables.tpDoc.columns;

const GridDiv = styled(BasedGrid)`
  width: 100%;
  grid-template-areas:  "mark list";
  grid-template-columns: 15% 85%;
  grid-auto-columns: 1fr;
`;

function HeaderComponent() {
  return (
    <GridDiv>
      <DivHeader areaName="mark">{coldef.mark.label}</DivHeader>
      <DivHeader areaName="list">{coldef.list.label}</DivHeader>
    </GridDiv>
  );
}

class RowComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.instanceOf(Map).isRequired,
    rowId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      row, rowId, dispatch,
    } = this.props;
    return (
      <GridDiv>
        <DivCell areaName="mark">
          <Fields.CheckboxField
            value={row.get('mark', false)}
            onChange={(e, v) => dispatch(changeField([`tables/${TABLENAME}`, rowId, 'mark'], v))}
          />
        </DivCell>
        <DivCell areaName="list">
          <InputCodeStyled
            noBackground
            noBorder
            value={row.get('list', '')}
            readOnly
          />
        </DivCell>
      </GridDiv>
    );
  }
}

const mapState = (state) => ({
  data: state.getIn([storePathParam(state).nodeRep, `tables/${TABLENAME}`], new List()),
  actionsHistory: state.getIn(
    [storePathParam(state).nodeRep, storePathParam(state).id, `tables/${TABLENAME}/history`],
    new List(),
  ),
});

const TableData = getBaseTP(
  TABLENAME,
  RowComponent,
  HeaderComponent,
);

export default connect(mapState)(TableData);
