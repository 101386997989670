// eslint-disable-next-line import/no-cycle
import DocLister from './lister';
import DocSelector from './selector';

const allocationPlanChanges = {
  lister: DocLister,
  selector: DocSelector,
};

export default allocationPlanChanges;
