import React, { useMemo } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getRouterEditor } from '../../../editor/basic2';
import { csuPartners as md } from '../../../../constants/meta/catalogs/csuPartners';
import { partnerBankAccounts as accMD } from '../../../../constants/meta/catalogs/partnerBankAccounts';
import { modelType, modelName } from '../def';
import CSUPartners from './editor';
import { useEditor, useInitParams } from '../../../newEditor';
import EditorHeader from '../../../newEditor/header';
import EditorWrapper from '../../../../components/bootstrap_components/editor';
import CatEditorCommandPanel from '../../../newEditor/commandPanels/catalog';
import CSUPartnerAccountsLister from '../../partnerBankAccounts/lister/lister';

function CSUPartnersEditor({
  id,
  search,
  onClose,
  onSave,
}) {
  const {
    reason,
    copyFrom,
    defaults,
  } = useInitParams(search);

  const {
    data,
    loading,
    err,
    changed,
    permissions,
    actions,
  } = useEditor({
    modelType,
    modelName,
    id,
    reason,
    copyFrom,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
    defaults,
  });
  const accListerFilter = useMemo(
    () => ([{
      fieldName: accMD.columns.owner.name,
      value: id === 'create' ? null : id,
    }]),
    [id],
  );
  return (
    <>
      <EditorHeader
        onSaveNClose={actions.onSaveNExit}
        isChanged={changed}
        onClose={actions.onClose}
        header={data.repr}
      />
      <CatEditorCommandPanel
        id={id}
        changed={changed}
        onClose={onClose}
        permissions={permissions}
        actions={actions}
      />
      <Tabs defaultActiveKey="editor" className="border-bottom-0">
        <Tab title="Редагування" eventKey="editor">
          <EditorWrapper err={err} loading={loading} onErrorDismis={actions.onErr}>

            <Card.Header>
              <CSUPartners data={data} actions={actions} permissions={permissions} />
            </Card.Header>
          </EditorWrapper>
        </Tab>
        <Tab title="Рахунки" eventKey="accounts" className="border px-3 py-1 rounded">
          <CSUPartnerAccountsLister filter={accListerFilter} />
        </Tab>
      </Tabs>
    </>
  );
}

CSUPartnersEditor.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

CSUPartnersEditor.defaultProps = {
  search: '',
  onClose: null,
  onSave: null,
};

export { CSUPartnersEditor as StdEditor };

export default getRouterEditor(md)(CSUPartnersEditor);
